import React from 'react';

import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { colors } from '@zf/utils/src/color';

type Props = {
  unresolvedIssues: number;
};

const UnresolvedIssuesBadge = (props: Props) => {
  const { unresolvedIssues } = props;

  return (
    <NewStatusBadge
      color={colors[unresolvedIssues > 0 ? 'orange-600' : 'green-600']}
      type="numeric"
      status_={String(unresolvedIssues)}
    />
  );
};

export default UnresolvedIssuesBadge;
