import classNames from 'classnames';
import React, { useMemo } from 'react';

import { generateColor } from '@zf/utils/src/color';

import { ICON_COLORS, ICONS } from '../../../constants/icons';
import { SimpleTooltip } from '../../Components';
import css from './icon.module.scss';

export type IconSize = 'normal' | 'large';

export type IconPosition = 'left' | 'right';

type IconPropsType = {
  name: string;
  color?: boolean | string;
  style?: React.CSSProperties;
  className?: string;

  // Tooltip
  id?: string;
  title?: string;
};

export default function Icon(props: IconPropsType) {
  const { name = '', style = {}, className, id, title, color = false } = props;

  const iconName = ICONS[name ? name.toLowerCase() : ''] || name;

  const styleObject: React.CSSProperties = useMemo(() => {
    const styleObj = {
      ...style
    };

    if (color) {
      if (typeof color !== 'string') {
        const col = ICON_COLORS[iconName?.toLowerCase() || ''] || generateColor(iconName);
        styleObj['color'] = col;
      } else {
        styleObj['color'] = color;
      }
    }

    return styleObj;
  }, [style, color, iconName]);

  const icon = (
    <span
      style={styleObject}
      className={classNames('icon', `icon-${iconName.toLowerCase()}`, css['icon-style'], className)}
    />
  );

  return id && title ? (
    <SimpleTooltip id={`${id}-tip`} tooltip={title} renderInGateway={false}>
      {icon}
    </SimpleTooltip>
  ) : (
    icon
  );
}
