import React from 'react';

import { useAppContext } from '../../../app-context';
import { Paragraph } from '../../../design-system/Foundation';
import { Progress } from './Progress';
import css from './reset-card.module.scss';

type Props = {
  orgId: string;
  shortCode: string | undefined;
  setIsResetting: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ResetCard(props: Props) {
  const { orgId, shortCode, setIsResetting } = props;
  const { i18n } = useAppContext();

  const [progress, setProgress] = React.useState<React.ReactNode | undefined>();

  let timeout: NodeJS.Timeout;

  React.useEffect(() => {
    timeout = setTimeout(() => {
      // Making sure backend event has been processed
      setProgress(<Progress orgId={orgId} setIsResetting={setIsResetting} />);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={css['reset-card']}>
      <div className={css['icon-text']}>
        <img
          src="https://cdn.zerofriction.co/shared/assets/loading-organisation.gif"
          alt="resetting"
          title="resetting"
          width="75"
          height="75"
        />
        <Paragraph>
          {i18n.getTranslation('tenant.resetting', { organisation: shortCode ? shortCode.toUpperCase() : '' })}
        </Paragraph>
      </div>

      {progress}
    </div>
  );
}
