import React from 'react';

import ErrorView from '../../../../../../components/Error/error-view';
import useActivities from '../../hooks/useActivities';
import MeterMultiValue from './MeterMultiValue';

export default function MeasurementWiz() {
  const { task, validateMeasurements } = useActivities();

  if (!task) return null;

  const measurementValidation = validateMeasurements();

  return (
    <div>
      {measurementValidation.allErrors.length > 0 ? <ErrorView errors={measurementValidation.allErrors} /> : null}

      <MeterMultiValue measurementDetails={task.measurementDetails} />
    </div>
  );
}
