import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { productItemPeriodicityType } from '@zf/api-types/enums';
import { BillingItemType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import AddBillingItemsDialog from '../../../../components/billing-items-dialog/AddBillingItemsDialog';
import MoreActionsMenu from '../../../../components/Menus/MoreActionsMenu';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import css from './product-billing-items.module.scss';

const ProductBillingItemActions = () => {
  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { isPanelViewMode, productsStore, togglePanelView } = productConfigStore;
  const {
    selectedProduct,
    calculationTypes,
    taxCodes,
    customEntityPropertyTypes,
    billingItemsDialogStore,
    showInactiveBillingItems,
    updateProductBillingItems,
    toggleInactiveBillingItems
  } = productsStore;
  const { updatingForEntity } = billingItemsDialogStore;
  const { clickRef, onSubmit } = useDialog();
  const isAddMode = selectedProduct && selectedProduct.billingItems.length === 0;
  const actions = [];

  if (isAddMode !== undefined) {
    actions.push(
      <Fragment key="top-action">
        <DropdownAction
          id="add-billing-item"
          key="add-billing-item"
          dialogLocation="normal-big"
          dialogContent={
            <AddBillingItemsDialog
              useCase="product"
              ref={clickRef}
              calculationTypes={calculationTypes}
              customEntityPropertyTypes={customEntityPropertyTypes}
              taxCodes={taxCodes}
              generateNewEntityBillingItem={(billingItem: BillingItemType) => {
                return {
                  billingItemId: billingItem.id,
                  order: billingItemsDialogStore.updatingForEntity.billingItems.length + 1,
                  periodicityParameters: {
                    type: productItemPeriodicityType.regular
                  }
                };
              }}
              onSubmit={() => updateProductBillingItems(updatingForEntity)}
              // @ts-ignore
              billingItemsDialogStore={billingItemsDialogStore}
            />
          }
          onSubmit={onSubmit}
          buttonPositive={getTranslation('general.save')}
          icon={isAddMode ? 'plus' : 'sign'}
          dialogTitle={getTranslation(`general.${isAddMode ? 'add' : 'edit'}`)}
        />
        <HorizontalDivider className={css['billing-item-horizontal-divider']} />
      </Fragment>
    );
  }

  if (isPanelViewMode) {
    actions.push(
      <DropdownAction id="column-view" key="column-view" actionType="direct" icon="columns" onClick={togglePanelView}>
        {getTranslation('general.column_view')}
      </DropdownAction>
    );
  } else {
    actions.push(
      <DropdownAction id="grid-view" key="grid-view" icon="panel" actionType="direct" onClick={togglePanelView}>
        {getTranslation('general.panel_view')}
      </DropdownAction>
    );
  }

  if (!isAddMode)
    actions.push(
      <DropdownAction
        id="show-active-billing-items"
        key="show-active-billing-items"
        icon={showInactiveBillingItems ? 'eye-crossed' : 'eye'}
        actionType="direct"
        onClick={toggleInactiveBillingItems}
      >
        {getTranslation(`general.${showInactiveBillingItems ? 'hide_inactive' : 'show_inactive'}`)}
      </DropdownAction>
    );

  return <MoreActionsMenu dotsOrientation="vertical" id="more-actions-add-billing-item" actions={actions} />;
};

export default observer(ProductBillingItemActions);
