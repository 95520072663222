import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { Card, CardBody } from '@zf/stella-react/src/atoms/Card';

import { ErrorBoundary } from '../../../../../../components/Error';
import FilterHeader from './FilterHeader';
import css from './incoming-transaction-details.module.scss';
import IncomingMutationsTableSection from './IncomingMutationsTableSection';

type Props = {
  incomingBankingTransaction: IncomingBankingTransactionType;
  timeStamp: string;
  refresh: () => void;
};

export default function MutationsCard(props: Props) {
  const { incomingBankingTransaction, refresh } = props;

  return (
    <Card id="mutations-card" width="4" className={css['card']}>
      <CardBody className={css['content']}>
        <FilterHeader incomingBankingTransactionId={incomingBankingTransaction.id} />
        <ErrorBoundary>
          <IncomingMutationsTableSection incomingBankingTransaction={incomingBankingTransaction} refresh={refresh} />
        </ErrorBoundary>
      </CardBody>
    </Card>
  );
}
