import clone from 'clone';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { ConfigState } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';
import { METHODS, sendRequest } from '../../utils/request';
import { useAppContext } from '../app-context';
import { ActionTypes, ApplicationState, ConfigType, useTracked } from '../app-state';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';

export type UseConfigReturnType<T> = {
  currentConfig: ConfigState<T>;
  isFetching: boolean;
  setCurrentConfig: (value: Partial<ConfigState<T>>) => void;
  sort: (sortby: string, sortDirection: SortDirection | '') => void;
};

const emptyConfig: ConfigState<any> = { sortableFields: {}, responseData: [] };

export default function useConfig<T>(configType: ConfigType, endpoint: string): UseConfigReturnType<T> {
  const [state, dispatch]: [ApplicationState, React.Dispatch<ActionTypes>] = useTracked();
  const { tenantReducer, i18n } = useAppContext();

  const current: ConfigState<T> | undefined = state.config.get(configType);

  async function fetchData<E>(sortDirection?: Record<string, SortDirection | ''>): Promise<E> {
    const keys = Object.keys(sortDirection || []);
    return (
      await sendRequest<E>({
        request: {
          method: METHODS.GET,
          endpoint: endpoint,
          query: {
            orderBy:
              keys.length > 0
                ? keys.reduce((acc, key) => {
                    if (sortDirection?.[key]) {
                      acc += `${sortDirection[key] === 'ASC' ? '' : '-'}${key}`;
                    }
                    return acc;
                  }, '')
                : ''
          }
        },
        donotCache: true,
        tenantReducer: tenantReducer,
        lang: i18n.lang
      })
    ).data;
  }

  React.useEffect(() => {
    fetchData<PagedResponseType<T>>().then((data) => {
      const sortableFields = data.sortableFields.reduce((obj: Record<string, SortDirection | ''>, key: string) => {
        obj[key.toLowerCase()] = '';
        return obj;
      }, {});

      const cloned = clone(state.config);
      const toUpdate = cloned.get(configType);

      if (!toUpdate || (toUpdate && JSON.stringify(toUpdate.responseData) !== JSON.stringify(data.results))) {
        cloned.set(configType, {
          sortableFields,
          responseData: [...data.results]
        });

        dispatch({ type: 'update_config', newConfig: cloned });
      }
    });
  }, []);

  const sort = (sortby: string, sortDirection: SortDirection | '') => {
    const newSortDirections = clone(current?.sortableFields);

    if (newSortDirections) {
      newSortDirections[sortby] = sortDirection;
      fetchData<PagedResponseType<T>>(newSortDirections).then((data) => {
        const cloned = clone(state.config);
        const toUpdate = cloned.get(configType);

        if (toUpdate) {
          toUpdate.responseData = [...data.results];
          toUpdate.sortableFields = { ...newSortDirections };
          cloned.set(configType, toUpdate);
          dispatch({ type: 'update_config', newConfig: cloned });
        }
      });
    }
  };

  const setCurrentConfig = (value: Partial<ConfigState<T>>) => {
    const cloned = clone(state.config);
    const toUpdate = cloned.get(configType);

    if (toUpdate) {
      cloned.set(configType, { ...toUpdate, ...value });

      dispatch({ type: 'update_config', newConfig: cloned });
    }
  };

  if (!current) {
    return {
      currentConfig: emptyConfig,
      isFetching: true,
      setCurrentConfig: () => {
        // do nothing.
      },
      sort
    };
  }

  return { currentConfig: current, isFetching: false, setCurrentConfig, sort };
}
