import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import style from './table-no-data.module.scss';

export default function TableNoData() {
  const { i18n } = useAppContext();

  return (
    <div className={style['table-no-data']}>
      <Heading headingType="h1" style="bold">
        {i18n.getTranslation('errors.no_data')}
      </Heading>
      <Paragraph>{i18n.getTranslation('errors.no_data_long')}</Paragraph>
      <img
        src="https://cdn.zerofriction.co/shared/assets/no_data_found.svg"
        alt={i18n.getTranslation('errors.no_data')}
        title={i18n.getTranslation('errors.no_data')}
      />
    </div>
  );
}
