import Interweave from 'interweave';
import moment from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { MeterType } from '@zf/api-types/master-data/meter';
import { AggregatedConsumptionsByMeterType } from '@zf/api-types/metering/consumptions';
import useValidatePeriod from '@zf/hooks/src/useValidatePeriod';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ProgressBar } from '@zf/stella-react/src/atoms/ProgressBar';
import Center from '@zf/stella-react/src/helpers/Center';
import { splitArrayIntoChunksOfLength } from '@zf/utils/src/array';
import { MAX_DATE, MIN_DATE, DISPLAY_DATE_FORMAT } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import { EnumReturnValue } from '../../../../app-context/hooks/use-enum';
import { LangReturnValue } from '../../../../app-context/hooks/use-lang';
import { exportArrayToExcel } from '../../../../components/Button/Excel/ExportToExcel';
import DateRangePicker from '../../../../components/input/DateRangePicker';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { METHODS, sendRequest } from '../../../../utils/request';
import css from '../export-dialog.module.scss';
import { getPeriodicFileNameFormat } from '../../../../utils/exports';

type Props = {
  selectedRows?: { __meterEntity: MeterType }[];
  meter?: MeterType;
};

const mapConsumptions = (
  consumptions: AggregatedConsumptionsByMeterType[],
  i18n: LangReturnValue,
  enumReducer: EnumReturnValue
) => {
  const exportArray = [];

  exportArray.push([
    i18n.getTranslation('meter.meter_tag'),
    i18n.getTranslation('metering_list.labels.serial'),
    i18n.getTranslation('meter.utility_type'),
    i18n.getTranslation('general.total'),
    i18n.getTranslation('meter.unit_of_measure'),
    i18n.getTranslation('meter.time_of_use'),
    i18n.getTranslation('invoice.period_start'),
    i18n.getTranslation('invoice.period_end'),
    i18n.getTranslation('property_groups.id'),
    i18n.getTranslation('property_groups.name')
  ]);

  consumptions.forEach((cons) => {
    exportArray.push([
      //meterTag value goes below
      cons.meterTag,
      cons.meterSerialNumber,
      enumReducer.getTranslation('utilityType', cons.utilityType),
      cons.total,
      enumReducer.getTranslation('unitOfMeasure', cons.unitOfMeasure),
      cons.timeOfUse,
      moment(cons.periodStartDate).format(DISPLAY_DATE_FORMAT),
      moment(cons.periodEndDate).format(DISPLAY_DATE_FORMAT),
      cons.propertyGroupId,
      cons.propertyGroupName
    ]);
  });

  return exportArray;
};

const ExportMeterConsumptionsDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { meter } = props;
  let { selectedRows = [] } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const meterIds: string[] = [];

  const [progress, setProgress] = React.useState(0);
  const [isMapping, setIsMapping] = React.useState(false);

  const { dates, setDates } = useValidatePeriod();

  if (progress === 0) {
    // When on meter detail page
    if (selectedRows.length === 0 && !!meter) {
      selectedRows = [{ __meterEntity: meter }];
    }

    selectedRows.forEach((r) => {
      meterIds.push(r.__meterEntity.id);
    });
  }

  // API query accepts only 300 ids per call
  const splittedMeterIds = splitArrayIntoChunksOfLength(meterIds, 35);

  let chunksDone = 0;

  const fetchData = async (query: Record<string, any>, aggregatedConsumptions: AggregatedConsumptionsByMeterType[]) => {
    let continuationToken = '';
    let totalRecords = 0;

    while (continuationToken !== null) {
      const result = await sendRequest<PagedResponseType<AggregatedConsumptionsByMeterType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/me/Consumptions/export/sum',
          query: query
        },
        customHeaders: {
          continuationToken: continuationToken
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      });

      // Only execute this the first time
      if (totalRecords === 0) totalRecords = result.data.totalRecords;

      aggregatedConsumptions.push(...result.data.results);

      // Avoid divide by 0
      if (totalRecords > 0) {
        setProgress((chunksDone / splittedMeterIds.length) * 100);
      }

      continuationToken = result.data.nextPageToken;
      chunksDone++;
    }
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const aggregatedConsumptions: AggregatedConsumptionsByMeterType[] = [];

        if (selectedRows.length === 0) {
          // Export all
          await fetchData(
            {
              startDateTime: dates.startDateTime.toISOString(),
              endDateTime: dates.endDateTime.toISOString()
            },
            aggregatedConsumptions
          );
        } else {
          // We call the API for each chunk of 300 ids
          await Promise.all(
            splittedMeterIds.map(async (idsChunk) => {
              await fetchData(
                {
                  meterIds: idsChunk,
                  startDateTime: dates.startDateTime.toISOString(),
                  endDateTime: dates.endDateTime.toISOString()
                },
                aggregatedConsumptions
              );
            })
          );
        }

        setIsMapping(true);
        const mappedConsumptions = mapConsumptions(aggregatedConsumptions, i18n, enumReducer);
        setIsMapping(false);

        const tags = getPeriodicFileNameFormat(dates.startDateTime, dates.endDateTime);

        await exportArrayToExcel(
          i18n.getTranslation('meter.meter')[0] +
            '-' +
            i18n.getTranslation('meter.consumptions') +
            `-${tags.isStartTagTranslatable ? i18n.getTranslation(tags.fileNameStartTag) : tags.fileNameStartTag}`,
          () => mappedConsumptions,
          tags.fileNameEndTag
        );
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.meter.export_consumptions_fail'),
          error: e
        });
      }
    }
  }));

  let paragraphText = i18n.getTranslation('actions.meter.export_consumptions_paragraph');

  if (isMapping) {
    paragraphText = i18n.getTranslation('actions.meter.export_consumptions_busy_mapping');
  } else if (progress > 0) {
    paragraphText = i18n.getTranslation('actions.meter.export_consumptions_busy_fetching');
  }

  return (
    <>
      <Paragraph>
        <Interweave content={paragraphText} />
      </Paragraph>
      {progress > 0 ? (
        <Center type="both" className={css['progress-bar']}>
          <ProgressBar size="large" progress={progress} showLabel />
        </Center>
      ) : (
        <InputContainer className={css['input-container']}>
          <DateRangePicker
            id="export-period"
            startDate={dates.startDateTime}
            endDate={dates.endDateTime}
            setDates={(dates_) =>
              setDates({
                startDateTime: dates_[0] ? dates_[0] : moment(MIN_DATE),
                endDateTime: dates_[1] ? dates_[1] : moment(MAX_DATE)
              })
            }
          />
        </InputContainer>
      )}
    </>
  );
});

export default ExportMeterConsumptionsDialog;
