import { Observer } from 'mobx-react';
import React from 'react';

import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context';
import { useStore } from '../../hooks/useStore';
import BankDetailsCardContent from './BankDetailsCardContent';

export default function BankDetailsCard() {
  const { i18n } = useAppContext();
  const { customerStore } = useStore();

  return (
    <Observer>
      {() =>
        customerStore.bankAccountsIsLoading ? (
          <Card id="banking-details-card">
            <CardHeader>{i18n.getTranslation('cards.banking_details.title')}</CardHeader>
            <CardBody>
              <Center type="both">
                <Spinner size="medium" />
              </Center>
            </CardBody>
          </Card>
        ) : customerStore.bankDetailsForm ? (
          <BankDetailsCardContent />
        ) : null
      }
    </Observer>
  );
}
