import { formatDecimal, roundNumber } from '@zf/utils/src/number';
import React from 'react';
import css from './custom-tooltip.module.scss';

type TooltipProps = {
  payload?: PayloadType[];
  active?: boolean;
  locale: string;
};

type NestedPayloadType = {
  name: string;
  XLabel: string;
  color: string;
};

type PayloadType = {
  color: string;
  dataKey: string;
  fill: string;
  formatter?: string;
  name: string;
  payload: NestedPayloadType;
  radius: number[];
  type?: string;
  unit?: string;
  value?: number;
};

const CustomTooltip = (props: TooltipProps) => {
  const { active, payload, locale } = props;

  if (active && payload) {
    const xLabel = payload[0].payload.name ? payload[0].payload.name : payload[0].payload.XLabel;

    return (
      <div className={css['tooltip']}>
        <p>{xLabel}</p>
        <div className={css['tooltip-values']}>
          {payload.map((pl, index) => {
            if (typeof pl.unit === 'undefined' || pl.unit.trim().toLowerCase() === 'none') {
              pl.unit = '';
            }

            const formattedValue = formatDecimal(roundNumber(pl.value || 0), locale);

            return (
              <div key={`${xLabel}-${index}`} style={{ color: pl.fill ? pl.fill : pl.payload.color, height: '0rem' }}>
                {pl.unit === '€'
                  ? `${pl.name} : ${pl.unit} ${formattedValue}`
                  : `${pl.name} :  ${formattedValue} ${pl.unit}`}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
