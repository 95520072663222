import React from 'react';

import { dataType } from '@zf/api-types/enums';
import { ErrorCode } from '@zf/api-types/general';
import { onlyUniqueObjects } from '@zf/utils/src/array';
import { formatDate, formatDateTime } from '@zf/utils/src/date';

import css from './errors-tip.module.scss';

type Props = {
  errors: ErrorCode[];
};

export default function ErrorsTip(props: Props) {
  let { errors } = props;

  // Backend may return duplicate messages
  errors = onlyUniqueObjects(errors);

  return (
    <ul className={css['error-list']}>
      {errors.map((e, index) => {
        if (e.messageValues) {
          const substitutedMessage = e.messageValues?.reduce((message, valueObj, index) => {
            const placeholder = '${' + index + '}';
            let value = '';
            switch (valueObj.dataType) {
              case dataType.text: {
                value = valueObj.value;
                break;
              }
              case dataType.enumeration: {
                value = valueObj.value;
                break;
              }
              case dataType.number: {
                value = valueObj.value.toString();
                break;
              }
              case dataType.date: {
                value = formatDate(valueObj.value);
                break;
              }
              case dataType.dateandtime: {
                value = formatDateTime(valueObj.value);
                break;
              }
            }
            return message.replace(placeholder, value);
          }, e.message);
          return <li key={`${substitutedMessage}-${index}`}>{substitutedMessage}</li>;
        } else {
          return <li key={`${e.message}-${index}`}>{e.message}</li>;
        }
      })}
    </ul>
  );
}
