import React, { Suspense } from 'react';
import { GatewayDest } from 'react-gateway';

import { Router } from '@reach/router';

import { AppProvider } from '../app-context/app-state';
import { ErrorBoundary } from '../components/Error';
import { Loader } from '../components/Loader';
import { NotificationCenter } from '../design-system/Components';
import { DialogCenter } from '../design-system/ComponentSets';
import Route from './route';
import TenantRouter from './TenantRouter';

export default function AppRouter() {
  const centers = React.useMemo(() => {
    return (
      <>
        <NotificationCenter defaultTimeout={5000} />
        <DialogCenter />
      </>
    );
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <AppProvider>
          {/**
           * We use React-gateway instead of React portals to render our tooltip at the root
           *  because ReactDOMServer.renderToString can't handle portals
           */}
          <GatewayDest name="tooltip" />

          {centers}
          <Router>
            <Route Component={TenantRouter} default />
            <Route Component={TenantRouter} path="/:tenantName/*" />
          </Router>
        </AppProvider>
      </Suspense>
    </ErrorBoundary>
  );
}
