import React from 'react';

import useActivities from './hooks/useActivities';
import TaskColumnsContainer from './TaskColumnsContainer';

export default function TaskDetail() {
  const { task } = useActivities();

  if (!task) return null;

  return <TaskColumnsContainer task={task} />;
}
