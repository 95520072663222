import React, { useState } from 'react';

import { PaymentReferenceParametersType, TransactionType } from '@zf/api-types/transactions';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../../app-context/app-context';
import SettlementDetailsTable from '../../../../../../../cards/SettlementDetails/SettlementDetailsTable';
import { BaseButton } from '../../../../../../../design-system/Components';
import { Icon, Paragraph } from '../../../../../../../design-system/Foundation';
import { getValutaColor } from '../../../../../../../utils/money';
import css from './transaction-details.module.scss';

type Props = {
  transaction: TransactionType;
  isOpen?: boolean;
};

export default function TransactionDetails(props: Props) {
  const { transaction, isOpen = false } = props;
  const { i18n, enumReducer } = useAppContext();

  const [open, setOpen] = useState(isOpen);

  const castedDetails = transaction.referenceDetails?.parameters as PaymentReferenceParametersType;

  return (
    <div className={css['transaction-details']}>
      <BaseButton id={`open-${transaction.id}`} className={css['arrow']} onClick={() => setOpen(!open)}>
        <Icon name={open ? 'caret-down' : 'caret-right'} />
      </BaseButton>

      <div className={css['top-part-table-wrapper']}>
        <div className={css['top-part']}>
          {transaction.transactionType && (
            <div>
              <Label>{i18n.getTranslation('general.type')}</Label>
              <Paragraph>{enumReducer.getTranslation('transactionType', transaction.transactionType)}</Paragraph>
            </div>
          )}
          <div>
            <Label>{i18n.getTranslation('payments.payment_date')}</Label>
            <Paragraph>{formatDate(castedDetails.paymentDateTime)}</Paragraph>
          </div>
          <div>
            <Label>{i18n.getTranslation('general.amount_valuta')}</Label>
            <Paragraph>{formatMoney(transaction.transactionAmount, i18n.culture)}</Paragraph>
          </div>
          <div>
            <Label>{i18n.getTranslation('general.open')}</Label>
            <Paragraph color={getValutaColor(transaction.transactionOpenAmount)}>
              {formatMoney(transaction.transactionOpenAmount, i18n.culture)}
            </Paragraph>
          </div>
        </div>

        {open && <SettlementDetailsTable selectedTransaction={transaction} dynamicTableHeight />}
      </div>
    </div>
  );
}
