import React, { ReactNode } from 'react';
import css from './wizard-container.module.scss';

type Props = {
  children: ReactNode;
  breadCrumb: ReactNode;
};

export default function WizardContainer(props: Props) {
  const { children, breadCrumb } = props;

  return (
    <>
      {breadCrumb}
      <div className={css['wizard-container']}>{children}</div>
    </>
  );
}
