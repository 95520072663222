import React from 'react';

import { ServiceLocationRowType } from '@zf/api-types/master-data/servicelocation';
import useDialog from '@zf/hooks/src/useDialog';

import ExportServiceConsumptionsDialog from '../../../actions/location/serviceconsumptions-export/export-service-consumptions-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../design-system/ComponentSets';

export default function ExportServiceConsumptionsListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('location');
  const { clickRef, onSubmit } = useDialog();

  const selectedRows: ServiceLocationRowType[] = myCurrent.listPage.selectedRows;

  return (
    <DropdownAction
      id="location.consumptions_export"
      dialogTitle={i18n.getTranslation(`actions.meter.export_consumptions`)}
      icon="exel"
      dialogContent={<ExportServiceConsumptionsDialog ref={clickRef} selectedRows={selectedRows} />}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('export_jobs.export')}
    />
  );
}
