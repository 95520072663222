import IconParagraph from 'components/Icon/IconParagraph';
import { observer } from 'mobx-react';
import React from 'react';

import { billingCompletenessStatus } from '@zf/api-types/enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import AlertCircle from '@zf/stella-react/src/atoms/Info/AlertCircle';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { formatPeriodWMonth } from '@zf/utils/src/date';

import { ICON_COLORS } from '../../../../../../constants/icons';
import { useStore } from '../../../../../../hooks/useStore';
import ErrorsTip from './ErrorsTip';
import css from './right-header.module.scss';

const RightHeader = () => {
  const { contractStore, applicationStore } = useStore();
  const { selectedCompleteness, productForSelectedCompleteness, allSelectedCompletenessErrors } =
    contractStore.billingCompletenessStore;

  return (
    <FlexElements className={css['right-header']} justifyContent="space-between" alignItems="center">
      <IconParagraph iconType="date" bold>{`${formatPeriodWMonth(
        selectedCompleteness.periodStartDateTime,
        selectedCompleteness.periodEndDateTime
      )} (${productForSelectedCompleteness?.productName || ' - '})`}</IconParagraph>

      <FlexElements alignItems="center">
        {selectedCompleteness.status === billingCompletenessStatus.inputmissing &&
          allSelectedCompletenessErrors.length > 0 && (
            <AlertCircle>
              <ErrorsTip errors={allSelectedCompletenessErrors} />
            </AlertCircle>
          )}
        <NewStatusBadge
          color={ICON_COLORS[selectedCompleteness.status]}
          status_={applicationStore.getEnumTranslation('billingCompletenessStatus', selectedCompleteness.status)}
        />
      </FlexElements>
    </FlexElements>
  );
};

export default observer(RightHeader);
