import React, { useContext } from 'react';

import { TransactionsContext } from '../../Context/Context';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { Button, Link } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatMoney } from '@zf/utils/src/number';
import { useAppContext } from 'app-context';
import { LockedInfo, TransactionStatus, TransactionTypeLink, settlementState } from '../Components';
import { DetailLine, InfoBanner, InfoGrid } from 'design-system/ComponentSets';
import { formatDate, formatPeriod, today } from '@zf/utils/src/date';
import css from './transaction-detail-card.module.scss';
import moment from 'moment';
import { colors } from '@zf/utils/src/color';
import InfoGridColumn from 'design-system/ComponentSets/InfoGrid/InfoGridColumn';
import TransactionsListUnSettled from './TransactionsListUnSettled';
import RefundDialog from '../../TransactionsCard/RefundDialog';
import ConfirmationActionButton from 'components/Button/ConfirmationActionButton';
import useDialog from '@zf/hooks/src/useDialog';
import * as enums from '@zf/api-types/enums';
import { TransactionType } from '@zf/api-types/transactions';
import Interweave from 'interweave';

type Props = {
  customer: CustomerType;
};

/**
 * @description show banking file link
 * @link https://dev.azure.com/0friction/ZF%20Heat/_workitems/edit/17117
 */
const showBankingFile = ({
  incomingBankingTransactionId,
  outgoingBankingTransactionId,
  transactionType,
  incomingMutationId
}: TransactionType): { id: string | null; url: string | null } => {
  const defaultValue = {
    id: null,
    url: null
  };

  /**
   * @description Adjust balance
   */
  if (incomingMutationId === null) {
    return defaultValue;
  }

  const { invoice, payment, reversal, collectionfee, writeoff } = enums.transactionType;

  return transactionType
    ? {
        [invoice]: defaultValue,
        [payment]: {
          id: outgoingBankingTransactionId ? outgoingBankingTransactionId : incomingBankingTransactionId,
          url: outgoingBankingTransactionId
            ? 'payments/outgoing-banking-transactions'
            : 'payments/incoming-banking-transactions'
        },
        [reversal]: {
          id: incomingBankingTransactionId === null ? outgoingBankingTransactionId : incomingBankingTransactionId,
          url: incomingBankingTransactionId
            ? 'payments/incoming-banking-transactions'
            : outgoingBankingTransactionId
            ? 'payments/outgoing-banking-transactions'
            : null
        },
        [collectionfee]: defaultValue,
        [writeoff]: defaultValue
      }[transactionType]
    : defaultValue;
};

const TransactionDetailCard = (props: Props) => {
  const { customer } = props;
  const ctx = useContext(TransactionsContext);
  const { closeSidePanel } = ctx;
  const { applicationStore } = useStore();
  const { culture, getTranslation, rootUrl } = applicationStore;
  const { validationRef, onSubmit, clickRef } = useDialog();

  const { enumReducer } = useAppContext();

  const transaction = ctx.getSidePanelData();

  if (!transaction) return null;
  const { url, id } = showBankingFile(transaction);

  return (
    <Card id="transaction.detailcard" width="4" className={css['card']} role="dialog">
      <CardHeader
        extraRight={
          <>
            <TransactionStatus transaction={transaction} />
            <Button
              icon="cross"
              id="transaction.detailcard.remove"
              onClick={closeSidePanel}
              type="text"
              danger
              noSidePadding
            />
          </>
        }
      >
        <div className={css['settlement-header']}>
          <TransactionTypeLink transaction={transaction} enumReducer={enumReducer} getTranslation={getTranslation} />

          {url && id && (
            <Link icon="maximize" id={`${id}`} url={`${rootUrl}/${url}/${id}`}>
              {getTranslation('payments.show-bank-file')}
            </Link>
          )}
        </div>
      </CardHeader>

      <CardBody className={css['card-body']}>
        <div className={css['info-grid']}>
          <InfoGrid nCols={2} type="grey">
            <InfoGridColumn>
              <div className={css['cash-position']}>
                {<Paragraph>{getTranslation('customer.transactions.to-settle')}</Paragraph>}
                <div className={css['icon-amount']}>
                  <Paragraph bold color={settlementState(transaction) === 'settled' ? 'green-600' : ''}>
                    {formatMoney(transaction?.transactionOpenAmount, culture)}
                  </Paragraph>
                </div>
                {transaction?.transactionOpenAmount < 0 && (
                  <ConfirmationActionButton
                    id="transaction.refund"
                    dialogTitle={getTranslation('payments.refund')}
                    size="small"
                    type="secondary"
                    icon="transaction-outgoing"
                    showBtnIcon
                    dialogContent={
                      <RefundDialog validationRef={validationRef} transaction={transaction} ref={clickRef} />
                    }
                    onSubmit={onSubmit}
                    validationRef={validationRef}
                    buttonPositive={getTranslation('payments.refund')}
                    disabled={transaction?.awaitingBankConfirmation}
                  ></ConfirmationActionButton>
                )}
              </div>
            </InfoGridColumn>
            <InfoGridColumn>
              <div className={css['cash-position']}>
                {<Paragraph>{getTranslation('general.total')}</Paragraph>}
                <div className={css['icon-amount']}>
                  <Paragraph bold color="silver-600">
                    {formatMoney(transaction?.transactionAmount, culture)}
                  </Paragraph>
                </div>
              </div>
            </InfoGridColumn>
          </InfoGrid>
        </div>
        {transaction?.awaitingBankConfirmation && (
          <div className={css['locked-info']}>
            <LockedInfo
              awaitingBankConfirmationTransactionIdentification={
                transaction.awaitingBankConfirmationTransactionIdentification
              }
              awaitingBankConfirmationTransactionId={transaction.awaitingBankConfirmationTransactionId}
              awaitingBankConfirmationTransactionType={transaction.awaitingBankConfirmationTransactionType}
            />
          </div>
        )}
        {settlementState(transaction) === 'reversed' && transaction.reversalReason && (
          <InfoBanner
            info={
              <Interweave
                content={getTranslation('actions.payments.reversal_info', {
                  type: `${
                    transaction.reversalReason === enums.paymentReversalReason.manualreverse
                      ? getTranslation('customer.transactions.manual_reversal')
                      : getTranslation('customer.transactions.automatic_reversal')
                  }`,
                  reason: ` - ${
                    transaction.reversalReason === enums.paymentReversalReason.manualreverse
                      ? transaction.reversalAdditionalInformation
                      : enumReducer.getTranslation('paymentReversalReason', String(transaction.reversalReason))
                  }`
                })}
              />
            }
            color="orange"
            bold
          />
        )}

        <CardBody fixedHeight type="indexTable">
          <TransactionsListUnSettled customer={customer} settledTransaction={transaction} />
        </CardBody>

        {transaction.transactionType &&
          [enums.transactionType.invoice, enums.transactionType.collectionfee, enums.transactionType.writeoff].includes(
            transaction.transactionType
          ) && (
            <div className={css['info-grid']}>
              <InfoGrid nCols={2}>
                {/**
                 * @description for type invoice
                 */}
                {transaction.transactionType === enums.transactionType.invoice && (
                  <DetailLine label={getTranslation('general.period')}>
                    <Paragraph>
                      {formatPeriod(
                        transaction.referenceDetails?.parameters?.periodStartDateTime,
                        transaction.referenceDetails?.parameters?.periodEndDateTime
                      )}
                    </Paragraph>
                  </DetailLine>
                )}
                {transaction.transactionDueDateTime && transaction.transactionType === enums.transactionType.invoice && (
                  <DetailLine label={getTranslation('invoice.due_date')}>
                    <Paragraph
                      color={
                        moment(transaction.transactionDueDateTime).isSameOrBefore(today()) ? colors['red-400'] : ''
                      }
                    >
                      {formatDate(transaction.transactionDueDateTime)}
                    </Paragraph>
                  </DetailLine>
                )}
                {/**
                 * @description for type collection fee/ write off
                 */}
                {transaction.transactionType &&
                  [enums.transactionType.collectionfee, enums.transactionType.writeoff].includes(
                    transaction.transactionType
                  ) && (
                    <DetailLine label={getTranslation('collection_flows.collection_flow')}>
                      <Paragraph>{transaction.referenceDetails?.parameters?.type}</Paragraph>
                    </DetailLine>
                  )}
                {transaction.transactionType &&
                  transaction.transactionDueDateTime &&
                  [enums.transactionType.collectionfee, enums.transactionType.writeoff].includes(
                    transaction.transactionType
                  ) && (
                    <DetailLine label={getTranslation('collection_case.step')}>
                      <Paragraph>{transaction.referenceDetails?.parameters?.stepName}</Paragraph>
                    </DetailLine>
                  )}
              </InfoGrid>
            </div>
          )}
      </CardBody>
    </Card>
  );
};

export default TransactionDetailCard;
