import { observer } from 'mobx-react';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { pluginType } from '@zf/api-types/enums';
import { ProductAttachmentType, ProductType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import Button from '../../../../../components/Button/Button';
import CheckBox from '../../../../../components/input/CheckBox';
import InputField from '../../../../../components/input/InputField';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { dialog } from '../../../../../events/dialog-events';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import css from './add-edit-product-dialog.module.scss';
import ProductAttachment from './ProductAttachment';
import ProductAttachmentsDialog from './ProductAttachmentsDialog';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;

  // UC Edit
  product?: ProductType;
};

export type LocalProductType = {
  name: string;
  invoiceUpfront: boolean;
  attachments: ProductAttachmentType[];
  hasPortal: boolean;
};

const AddEditProductDialog = forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { product, validationRef } = props;

  const { productConfigStore, configStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { productsStore } = productConfigStore;
  const { cultureTable, addProduct, updateProduct } = productsStore;
  const { isPluginEnabled } = configStore;

  const { clickRef, validationRef: attachmentValidationRef, onSubmit } = useDialog();

  const [localProduct, setLocalProduct] = useState<LocalProductType>(
    product
      ? { ...product, hasPortal: false }
      : {
          name: '',
          invoiceUpfront: false,
          attachments: [],
          hasPortal: false
        }
  );

  const setLocalProductValue = (value: Partial<LocalProductType>) => {
    setLocalProduct({ ...localProduct, ...value });
  };

  // Check if this organisation has a customer portal
  useEffect(() => {
    configStore.configService.getConfiguredPlugins().then(() => {
      setLocalProductValue({
        hasPortal: isPluginEnabled(pluginType.enduserportal)
      });
    });
  }, []);

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!localProduct.name);
    }
  };

  useEffect(() => {
    validate();
  }, [localProduct]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (product) {
          // Update
          await updateProduct(product, localProduct);
        } else {
          // Add
          await addProduct(localProduct);
        }

        notify.success({
          content: getTranslation(`product_config.${product ? 'edit' : 'add'}_product_success`)
        });
      } catch (e) {
        notify.error({
          content: getTranslation(`product_config.${product ? 'edit' : 'add'}_product_fail`),
          error: e
        });
      }
    }
  }));

  // Sort validity periods, newest first
  const sortFunction = (a: ProductAttachmentType, b: ProductAttachmentType) =>
    moment(b.validUntil).unix() - moment(a.validUntil).unix();

  const addLocalProductAttachment = (newAttachment: ProductAttachmentType) => {
    setLocalProductValue({
      attachments: [...localProduct.attachments, newAttachment].sort(sortFunction)
    });
  };

  const updateLocalProductAttachment = (index: number, newAttachment: ProductAttachmentType) => {
    const attachmentsClone = [...localProduct.attachments];
    attachmentsClone[index] = newAttachment;
    setLocalProductValue({
      attachments: attachmentsClone.sort(sortFunction)
    });
  };

  const deleteLocalProductAttachment = (index: number) => {
    const attachmentsClone = [...localProduct.attachments];
    attachmentsClone.splice(index, 1);
    setLocalProductValue({
      attachments: attachmentsClone.sort(sortFunction)
    });
  };

  const openProductAttchmentsDialog = () => {
    dialog.normal({
      title: getTranslation('entity_attachment.add_dialog_title'),
      icon: 'plus',
      content: cultureTable && (
        <ProductAttachmentsDialog
          ref={clickRef}
          cultureTable={cultureTable}
          validationRef={attachmentValidationRef}
          hasPortal={localProduct.hasPortal}
          addLocalProductAttachment={addLocalProductAttachment}
        />
      ),
      buttonPositive: getTranslation('general.add'),
      ref: attachmentValidationRef,
      stackOrder: 1,
      onSubmit
    });
  };

  return (
    <>
      <Heading headingType="h3" style="bold">
        {getTranslation('product_config.add_product_paragraph')}
      </Heading>
      <InputContainer>
        <InputField
          id="name"
          onChange={(name) => setLocalProductValue({ name })}
          value={localProduct.name}
          placeholder={getTranslation('general.name')}
          error={!localProduct.name}
        />
        <br />
        <CheckBox
          id="upFront"
          checked={localProduct.invoiceUpfront}
          onChange={(val) => setLocalProductValue({ invoiceUpfront: val })}
          labelPosition="right"
        >
          {getTranslation('product_config.check_invoiceupfront')}
        </CheckBox>
      </InputContainer>

      <HorizontalDivider />

      <div className={css['heading-plus-icon']}>
        <Heading
          headingType="h3"
          style="bold"
          className={css['attachments']}
          coachMark={getTranslation('product_config.attachments_info')}
        >
          {getTranslation('communication.attachments')}
        </Heading>

        <Button
          id="add-attachment-icon"
          className={css['plus-icon']}
          type="text"
          size="small"
          icon="plus"
          onClick={openProductAttchmentsDialog}
        />
      </div>

      {localProduct.attachments.map((pa, index) => {
        const defaultCulture = cultureTable.defaultCulture;
        const defaultFileName = pa.attachmentFiles.find((aFile) => {
          return aFile.culture === defaultCulture;
        });

        return (
          <ProductAttachment
            key={`${pa.productAttachmentId}-${index}`}
            defaultFileName={defaultFileName}
            cultureTable={cultureTable}
            productAttachment={pa}
            updateLocalProductAttachment={(newAttachment: ProductAttachmentType) =>
              updateLocalProductAttachment(index, newAttachment)
            }
            deleteLocalProductAttachment={() => deleteLocalProductAttachment(index)}
            hasPortal={localProduct.hasPortal}
          />
        );
      })}

      <Button id="add-attachment" icon="plus" type="text" onClick={openProductAttchmentsDialog}>
        {getTranslation('entity_attachment.add_dialog_title')}
      </Button>
    </>
  );
});

export default observer(AddEditProductDialog);
