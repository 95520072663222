import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import ShowDetailsDialog from '../../../../../../actions/incoming-mutation/ShowDetailsDialog';
import ConfirmationActionButton from '../../../../../../components/Button/ConfirmationActionButton';
import DataGridCardHeaderSection from '../../../../../../components/DataGridCard/DataGridCardHeaderSection';
import DateRangePicker from '../../../../../../components/input/DateRangePicker';
import { useStore } from '../../../../../../hooks/useStore';
import css from './filter-header.module.scss';
import { useIncomingMutationsFilter } from './hooks/useIncomingMutationsFilter';
import MutationsCoachMark from './MutationsCoachMark';
import SearchBar from '../../../../../../components/input/SearchBar';

type Props = {
  incomingBankingTransactionId: string;
};

const FilterHeader = (props: Props) => {
  const { incomingBankingTransactionId } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { queryParams, filterTags, filters, setDates, setFlexSearch } = useIncomingMutationsFilter();
  const { clickRef } = useDialog();

  const { transactionDateStart, transactionDateEnd, flexSearch } = queryParams;

  return (
    <DataGridCardHeaderSection className={css['header-section']}>
      <Heading
        headingType="h3"
        style="bold"
        coachMarkTitle={getTranslation('general.types')}
        coachMark={<MutationsCoachMark />}
      >
        {getTranslation('mutations.mutations')}
      </Heading>
      <div className={css['filters']}>
        {filterTags}
        {filters}
        <DateRangePicker
          id="query-period"
          startDate={transactionDateStart}
          endDate={transactionDateEnd}
          setDates={setDates}
        />
        <SearchBar
          id="query-search"
          onChange={setFlexSearch}
          value={flexSearch}
          placeholder={getTranslation('general.search')}
          debounceTime={500}
        />
        <ConfirmationActionButton
          id="incoming_mutation.show_details"
          dialogTitle={getTranslation('incoming_banking_transactions.mutation_details')}
          dialogContent={
            <ShowDetailsDialog incomingBankingTransactionId={incomingBankingTransactionId} ref={clickRef} />
          }
          onSubmit={() => {}}
          icon="info-circle"
          showBtnIcon={false}
          type="text"
          isDismissDialog
        />
      </div>
    </DataGridCardHeaderSection>
  );
};

export default observer(FilterHeader);
