import { queryParam } from 'hooks/useCreateRequest';

import { PagedResponseType } from '@zf/api-types/api';
import { LocalAddressType } from '@zf/api-types/general';
import { UpdateManagementRelation } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import { createHeader, METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';

export default class ServiceLocationService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/md/servicelocations', applicationStore);
  }

  updateExternalId = async (location: ServiceLocationType, externalId: string) => {
    return (
      await this.applicationStore.sendRequest<ServiceLocationType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${location.id}/updateexternalid`,
          data: {
            externalId
          }
        },
        customHeaders: createHeader({
          'If-Match': location._etag
        })
      })
    ).data;
  };

  updateManagementDetails = async (location: ServiceLocationType, managementRelations: UpdateManagementRelation[]) => {
    return (
      await this.applicationStore.sendRequest<ServiceLocationType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${location.id}/updatemanagementdetails`,
          data: {
            managementRelations
          }
        },
        customHeaders: createHeader({
          'If-Match': location._etag
        })
      })
    ).data;
  };

  updateProduct = async (location: ServiceLocationType, productId: string) => {
    return (
      await this.applicationStore.sendRequest<ServiceLocationType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${location.id}/updateproduct`,
          data: {
            productId
          }
        },
        customHeaders: createHeader({
          'If-Match': location._etag
        })
      })
    ).data;
  };

  searchLocationForAddress = async (customerAddress: LocalAddressType) => {
    const number =
      customerAddress.streetNumber +
      (customerAddress.streetNumberAddition ? '%20' + customerAddress.streetNumberAddition : '');

    const endpoint = `${this.endpoint}?flexSearch=${customerAddress.streetName}%20${number}%20${customerAddress.postalCode}%20${customerAddress.city}%20${customerAddress.country}&quickFilter=all`;

    return (
      await this.applicationStore.sendRequest<PagedResponseType<ServiceLocationType>>({
        request: {
          method: METHODS.GET,
          endpoint
        }
      })
    ).data.results[0];
  };

  getLocationsForQuery = async (query: queryParam) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<ServiceLocationType>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint,
          query
        }
      })
    ).data.results;
  };
}
