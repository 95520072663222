import React, { useState } from 'react';

import { collectionStepLevel, templateUsecase } from '@zf/api-types/enums';
import { SimpleValidatorReturnType } from '@zf/hooks/src/useSimpleValidator';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Label } from '@zf/stella-react/src/atoms/Label';

import useCultureTable from '../../../../app-context/hooks/use-culture-table';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../../hooks/useStore';
import { StepValidatorType } from './step-wizard';
import css from './template-section.module.scss';
import TemplatePreview from './TemplatePreview';

type Props = {
  stepValidatorTools: SimpleValidatorReturnType<StepValidatorType>;
  stepNumber: string | number;
};

export default function TemplateSection(props: Props) {
  const { stepValidatorTools, stepNumber } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const [dropdownTemplates] = useState<DropdownValuesType<collectionStepLevel>[]>(
    getEnum<collectionStepLevel>('collectionStepLevel')
  );

  const { cultureTable } = useCultureTable();

  if (!cultureTable) return null;

  const { values, setValue } = stepValidatorTools;

  return (
    <div>
      <Heading headingType="h3" style="bold">
        {getTranslation('collection_flows.choose_template')}
      </Heading>

      <InputContainer>
        <SimpleDropdown
          id={`scenario.template-dropdown-${stepNumber}`}
          onChange={(val) => setValue({ communicationLevel: val[0] })}
          values={dropdownTemplates}
          placeholder={getTranslation('communication.template')}
          selectedValues={values.communicationLevel ? [values.communicationLevel] : []}
          error={!values.communicationLevel}
        />
      </InputContainer>

      {values.communicationLevel && (
        <div className={css['previews']}>
          <div>
            <Label>{`${getTranslation('communication.email')} ${getTranslation(
              'general.preview'
            ).toLowerCase()}`}</Label>
            <TemplatePreview
              communicationLevel={values.communicationLevel}
              previewCulture={cultureTable.defaultCulture}
              templateUsecase={templateUsecase.email}
            />
          </div>

          <div>
            <Label>{`${getTranslation('communication.pdf')} ${getTranslation('general.preview').toLowerCase()}`}</Label>
            <TemplatePreview
              communicationLevel={values.communicationLevel}
              previewCulture={cultureTable.defaultCulture}
              templateUsecase={templateUsecase.pdf}
            />
          </div>
        </div>
      )}
    </div>
  );
}
