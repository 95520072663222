import { observer } from 'mobx-react';
import React from 'react';

import { advanceFrequency, invoiceFrequency } from '@zf/api-types/enums';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import ProductAutoFill from '../../../components/Autofills/ProductAutoFill';
import PaymentTermsDropdown, {
  PaymentTermDropdownProps
} from '../../../components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../hooks/useStore';
import { CreatePropertyType, STEP_NAMES } from './property-group-wizard';
import css from './property-group-wizard.module.scss';

type Props = {
  values: CreatePropertyType;
  setValue: (value: Partial<CreatePropertyType>, initialApiSet?: boolean, backup?: boolean) => void;
  handleFocus: (step: string) => () => void;
};

const WizardPTDropdown = WizardInputWrapper<PaymentTermDropdownProps>(PaymentTermsDropdown);
const WizardNumberDropdown = WizardInputWrapper<SimpleDropdownProps<number>>(SimpleDropdown);
const WizardAdvanceFreqDropdown = WizardInputWrapper<SimpleDropdownProps<advanceFrequency>>(SimpleDropdown);
const WizardInvoiceFreqDropdown = WizardInputWrapper<SimpleDropdownProps<invoiceFrequency>>(SimpleDropdown);

const ContractDefaultsSection = (props: Props) => {
  const { values, setValue, handleFocus } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[1]} />
        {getTranslation('property_groups.default_contract')}
      </WizardHeader>
      <Heading headingType="h3" style="bold">
        {getTranslation('property_groups.default_contract_title')}
      </Heading>
      <InputContainer>
        <WizardAdvanceFreqDropdown
          id="dropdown-advance"
          onChange={(val) => setValue({ advanceFrequency: val[0] })}
          values={getEnum('advanceFrequency')}
          selectedValues={[values.advanceFrequency || ('' as advanceFrequency)]}
          placeholder={getTranslation('property_groups.advanceFrequency')}
          onFocus={handleFocus(STEP_NAMES[1])}
        />
        <WizardInvoiceFreqDropdown
          id="dropdown-invoiceFrequency"
          onChange={(val) => {
            if (values.invoiceFrequency !== invoiceFrequency.yearly && !!values.invoiceMonth) {
              setValue({ invoiceFrequency: val[0], invoiceMonth: 0 });
            } else {
              setValue({ invoiceFrequency: val[0] });
            }
          }}
          values={getEnum('invoiceFrequency')}
          selectedValues={[values.invoiceFrequency || ('' as invoiceFrequency)]}
          placeholder={getTranslation('property_groups.invoiceFrequency')}
          onFocus={handleFocus(STEP_NAMES[1])}
        />
        <div className={css['invoice-moment']}>
          <WizardNumberDropdown
            id="invoiceday"
            selectedValues={[values.invoiceDay]}
            placeholder={getTranslation('property_groups.invoice_day')}
            values={[...Array(31).keys()].map((v) => {
              const val = v + 1;
              return {
                value: val,
                text: val.toString()
              };
            })}
            onChange={(val) => setValue({ invoiceDay: val[0] })}
            clear
            onFocus={handleFocus(STEP_NAMES[1])}
          />
          {(values.invoiceFrequency === invoiceFrequency.yearly || !values.invoiceFrequency) && (
            <>
              <div className={css['divider']}>/</div>
              <WizardNumberDropdown
                id="invoicemonth"
                selectedValues={[values.invoiceMonth]}
                placeholder={getTranslation('property_groups.invoice_month')}
                values={[...Array(12).keys()].map((v) => {
                  const val = v + 1;
                  return {
                    value: val,
                    text: val.toString()
                  };
                })}
                onChange={(val) => setValue({ invoiceMonth: val[0] })}
                clear
                onFocus={handleFocus(STEP_NAMES[1])}
              />
            </>
          )}
        </div>

        <FlexElements alignItems="center">
          <ProductAutoFill
            id="dropdown-product"
            onChange={(val) => setValue({ productId: val[0]?.id || null })}
            selectedValues={[values.productId || '']}
            onFocus={handleFocus(STEP_NAMES[1])}
          />
        </FlexElements>

        <WizardPTDropdown
          id="dropdown-terms"
          onChange={(val) => setValue({ paymentTermsId: val[0]?.id || null })}
          selectedValue={values.paymentTermsId || ''}
          onFocus={handleFocus(STEP_NAMES[1])}
          showDefault={false}
        />
      </InputContainer>
    </WizardSection>
  );
};

export default observer(ContractDefaultsSection);
