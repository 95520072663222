import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactElement, useRef, useState } from 'react';

import { colors } from '@zf/utils/src/color';

import { Actions, Trail } from '../';
import { Icon, Paragraph } from '../../Foundation';
import { ActionsProps } from '../Actions/Actions';
import css from './tree-item.module.scss';

export type TreeItemProps = ActionsProps & {
  text: string;
  indentLevel: number;
  children?: ReactElement<TreeItemProps> | ReactElement<TreeItemProps>[];
  info?: string;
  extraInfo?: string;
  icon?: string;
  hasDot?: boolean;
  dotColor?: string;
  selected?: boolean;
  disabled?: boolean;
  extraRight?: React.ReactNode[];
  allowedActions: treeCrud[];
};

export enum treeCrud {
  create = 'create',
  update = 'update',
  delete = 'delete'
}

const TreeItem = (props: TreeItemProps) => {
  const {
    // ActionsProps
    id,
    addFunc,
    editFunc,
    deleteFunc,
    otherActions,
    allowedActions,

    // TreeItemProps
    text,
    indentLevel,
    children,
    dotColor,
    info,
    extraInfo,
    icon = 'file',
    hasDot = false,
    selected = false,
    disabled,
    extraRight
  } = props;

  const [isSelected, setIsSelected] = useState(selected);
  const ref = useRef<HTMLDivElement>(null);
  const trailRef = useRef<HTMLDivElement>(null);

  // This handles the background color change without the need of an array based parent component (and lots of rerenders)
  const handleClick = () => {
    const treeItemElements = document.querySelectorAll('[id^="tree-item"]');
    const trailElements = document.querySelectorAll('[id^="trail"]');

    treeItemElements.forEach((el) => {
      el.classList.remove(css['tree-item-dark']);
    });

    trailElements.forEach((trailEl) => {
      trailEl.classList.remove(css['trail-dark']);
    });

    if (ref.current) {
      ref.current.classList.add(css['tree-item-dark']);
    }

    if (trailRef.current) {
      trailRef.current.classList.add(css['trail-dark']);
    }

    setIsSelected(!isSelected);
  };

  const onAdd = () => {
    setIsSelected(true);
    if (addFunc) addFunc();
  };

  return (
    <div className={css['wrapper']}>
      <div id={`tree-item-${id}`} ref={ref} className={css['trail-and-tree-item']}>
        {!!indentLevel && (
          <Trail
            id={id}
            ref={trailRef}
            className={!!extraInfo ? css['extend-height-trail'] : undefined}
            indentLevel={indentLevel}
          />
        )}

        <div className={classNames(css['tree-item'], { [css['extend-height-tree-item']]: !!extraInfo })}>
          <div className={css['tree-item-top']}>
            <div className={css['tree-item-top-left']} onClick={handleClick}>
              {!disabled ? <Icon name={isSelected ? 'caret-down' : 'caret-right'} /> : <span />}
              <Icon name={icon} />
              <Paragraph>{text}</Paragraph>
              {hasDot && (
                <Icon
                  name="dot"
                  color={
                    //@ts-ignore
                    colors[dotColor || 'green-600']
                  }
                />
              )}
            </div>
            <div className={css['tree-item-top-right']}>
              {extraRight && extraRight.length > 0 && <div className={css['custom-actions']}>{extraRight}</div>}
              <div className={css['tree-item-standard-actions']}>
                <Actions
                  id={id}
                  addFunc={allowedActions.includes(treeCrud.create) ? onAdd : undefined}
                  editFunc={allowedActions.includes(treeCrud.update) ? editFunc : undefined}
                  deleteFunc={allowedActions.includes(treeCrud.delete) ? deleteFunc : undefined}
                  otherActions={otherActions}
                />
              </div>
            </div>
          </div>

          <div className={css['tree-item-bottom']}>{extraInfo && <Paragraph>{extraInfo}</Paragraph>}</div>
        </div>

        {info && (
          <div className={css['info']}>
            <Paragraph>{info}</Paragraph>
          </div>
        )}
      </div>

      <div className={css['children']}>{isSelected && children}</div>
    </div>
  );
};

export default observer(TreeItem);
