import React from 'react';

import { Card, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import Button from '../../../components/Button/Button';
import { SuspenseCardBody } from '../../../components/suspense';
import { notify } from '../../../events/notification-events';
import CostComponentValuesBody from './cost-component-values-body';
import { ValidatorTypeV } from './cost-components';

type Props = {
  selectedCostName: string;
  selectedCostId: string;
  valuesV: ValidatorTypeV;
  selectedCostComponent: number;
  selectedCostComponentId: string;
  selectedCostComponentValue: number;
  setValuesV: (value: Partial<ValidatorTypeV>, initialApiSet?: boolean, backup?: boolean) => void;
  backupValuesV: () => void;
};

export default function CostComponentValues(props: Props) {
  const { selectedCostName, selectedCostId, selectedCostComponent, setValuesV, valuesV } = props;

  const { i18n } = useAppContext();

  const addCostComponentValue = () => {
    const clonedArray = [...valuesV.costComponentValues];
    clonedArray.push({
      costComponentId: selectedCostId,
      value: 0,
      startDateTime: MIN_DATE,
      endDateTime: MAX_DATE
    });
    setValuesV({
      costComponentValues: clonedArray,
      selectedCostComponentValue: clonedArray.length - 1
    });
  };

  return (
    <Card id="cost-component-values-card" width="2">
      <CardHeader
        extraRight={
          <Button
            id="costcomponent_values.add"
            type="text"
            icon="plus"
            onClick={
              !selectedCostId
                ? () => {
                    notify.warning({
                      content: i18n.getTranslation('cost_component.save_component_first')
                    });
                  }
                : addCostComponentValue
            }
          >
            {i18n.getTranslation('general.add')}
          </Button>
        }
      >
        {selectedCostComponent === -1
          ? i18n.getTranslation('cost_component_value.costComponentValues')
          : i18n.getTranslation('cost_component_value.costComponentValues_selected', {
              name: selectedCostName
            })}
      </CardHeader>
      {selectedCostComponent >= 0 && !!selectedCostId ? (
        <SuspenseCardBody>
          <CostComponentValuesBody {...props} />
        </SuspenseCardBody>
      ) : (
        <CardEmptyBody
          image={
            <img
              src="https://cdn.zerofriction.co/shared/assets/emptyStates/cost-allocation2.png"
              alt="cost-allocation"
              title="cost-allocation"
              width="70"
              height="70"
            />
          }
          title={i18n.getTranslation('cost_component_value.noCostComponentValues')}
        />
      )}
    </Card>
  );
}
