import { Paragraph } from 'design-system/Foundation';
import EstimatedInvoiceAccuracy from 'features/contract/src/detail-page/detail/components/accuracy/EstimatedInvoiceAccuracy';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { BillingRelationType } from '@zf/api-types/billing-relation';
import { BillingCompletenessInsightResponseType } from '@zf/api-types/billing/billing-completeness';
import { advanceFrequency } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { ContractType } from '@zf/api-types/master-data/contract';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Label } from '@zf/stella-react/src/atoms/Label';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { formatMoney } from '@zf/utils/src/number';

import NewAdvanceAmount from './NewAdvanceAmount';

export type PropsType = {
  invoice: InvoiceType;
  contract: ContractType;
  billingRelation: BillingRelationType;
  billingInsights: BillingCompletenessInsightResponseType;
};

const AdvanceCalculationDetails = (props: PropsType) => {
  const { invoice, contract, billingRelation, billingInsights } = props;

  const { applicationStore, invoiceStore } = useStore();
  const { culture, getTranslation } = applicationStore;
  const { organizationCfg } = invoiceStore;

  if (!invoice.advanceCalculationDetails) return null;

  const { oldAdvanceAmountInclVAT, nextInvoiceAmountInclVat, nextInvoiceAmountAccuracy } =
    invoice.advanceCalculationDetails;

  return (
    <>
      <CardItem width="12">
        <HorizontalDivider />
      </CardItem>

      {billingRelation.advanceDetails.advanceFrequency !== advanceFrequency.none && (
        <>
          <CardItem width="6">
            <Label>{getTranslation('invoice.current_advance_amount')}</Label>
          </CardItem>
          <CardItem width="6">
            <Paragraph textAlign="right">{formatMoney(oldAdvanceAmountInclVAT, culture)}</Paragraph>
          </CardItem>

          <NewAdvanceAmount invoice={invoice} />
        </>
      )}

      {nextInvoiceAmountInclVat &&
        nextInvoiceAmountAccuracy &&
        organizationCfg?.features.contractInvoiceEstimationEnabled && (
          <>
            <CardItem width="6">
              <Label>{getTranslation('invoice.next_invoice_amount')}</Label>
            </CardItem>
            <CardItem width="6" justifyContent="end">
              <FlexElements>
                <Paragraph textAlign="right">{formatMoney(nextInvoiceAmountInclVat, culture)}</Paragraph>
                <EstimatedInvoiceAccuracy
                  id="invoice.estimation.accuracy"
                  invoiceId={billingInsights.estimatedInvoiceId}
                  contract={contract}
                  accuracy={nextInvoiceAmountAccuracy}
                  size="small"
                  usage="detail"
                />
              </FlexElements>
            </CardItem>
          </>
        )}
    </>
  );
};

export default observer(AdvanceCalculationDetails);
