import clone from 'clone';
import React from 'react';

import { communicationType, culture, invoiceType } from '@zf/api-types/enums';
import { CultureTableType } from '@zf/api-types/language';
import {
  CommunicationPreferencesType,
  CustomerType,
  InvoiceCommunicationPreferenceType
} from '@zf/api-types/master-data/customer';
import useValidator from '@zf/hooks/src/useValidator';
import { CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context';
import CollectionFlowsDropdown from '../../components/Dropdown/collection-flows-dropdown/CollectionFlowsDropdown';
import CheckBox from '../../components/input/CheckBox';
import Select from '../../components/input/Select';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { SuspenseCard } from '../../components/suspense';
import { notify } from '../../events/notification-events';
import { getSelectedValue, setSelectedValue } from '../../utils/communicationPrefs';
import { createHeader, METHODS, sendRequest } from '../../utils/request';
import css from './customer-comm-prefs-card.module.scss';

export type PropsType = {
  customer: CustomerType;
  cultureTable: CultureTableType;
};

export type ComPreferenceType = communicationType | 'custom';

export type CommPrefsValidatorType = {
  communicationPreferences: CommunicationPreferencesType;
  deviatingLanguage: boolean | null;
  deviatingCommunicationType: boolean;
  commPreference: ComPreferenceType;
};

const InlineLanguageDropdown = InlineInputField<SimpleDropdownProps<culture>>(SimpleDropdown);
const InlineCommTypeDropdown = InlineInputField<SimpleDropdownProps<ComPreferenceType>>(SimpleDropdown);

export default function CommunicationPreferencesCardContent(props: PropsType) {
  const { customer, cultureTable } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const [communicationTypes] = React.useState(enumReducer.getEnum<communicationType>('communicationType'));

  const [invoiceTypes] = React.useState(enumReducer.getEnum<invoiceType>('invoiceType'));

  const getPreference = (invoiceCommunicationPreferences: InvoiceCommunicationPreferenceType[]) => {
    let ret: ComPreferenceType = 'custom';

    const commTypes = invoiceCommunicationPreferences.map((type) => {
      return type.communicationType;
    });

    if (
      commTypes.every((type) => {
        return type === commTypes[0];
      }) &&
      invoiceTypes.length === commTypes.length
    ) {
      ret = commTypes[0] as ComPreferenceType;
    }

    return ret;
  };

  const { values, isDirty, setValue, restoreValues, submitFactory } = useValidator({
    initialValues: {
      communicationPreferences: customer.communicationPreferences,
      deviatingLanguage:
        customer.communicationPreferences.culture &&
        customer.communicationPreferences.culture !== cultureTable.defaultCulture,
      deviatingCommunicationType:
        customer.communicationPreferences.invoiceCommunicationPreferences.length > 0 ||
        !!customer.communicationPreferences.collectionFlowId,
      commPreference: getPreference(customer.communicationPreferences.invoiceCommunicationPreferences)
    },
    refreshTrigger: customer.id
  });

  const updatePreferences = (pref: ComPreferenceType) => {
    const clonedValues = clone(values);

    if (pref === 'custom') {
      clonedValues.communicationPreferences.invoiceCommunicationPreferences = [];
    }

    if (clonedValues.deviatingCommunicationType && pref !== 'custom') {
      clonedValues.communicationPreferences.invoiceCommunicationPreferences = [
        {
          invoiceType: invoiceType.advance,
          communicationType: pref
        },
        {
          invoiceType: invoiceType.invoice,
          communicationType: pref
        },
        {
          invoiceType: invoiceType.endnote,
          communicationType: pref
        },
        {
          invoiceType: invoiceType.creditnote,
          communicationType: pref
        },
        {
          invoiceType: invoiceType.incidentalnote,
          communicationType: pref
        },
        {
          invoiceType: invoiceType.correctionnote,
          communicationType: pref
        }
      ];
    } else if (
      clonedValues.deviatingCommunicationType &&
      pref === 'custom' &&
      clonedValues.communicationPreferences.invoiceCommunicationPreferences.length === 0
    ) {
      clonedValues.communicationPreferences.invoiceCommunicationPreferences = [];
    } else if (!clonedValues.deviatingCommunicationType) {
      clonedValues.communicationPreferences.invoiceCommunicationPreferences = [];
    }

    clonedValues.commPreference = pref;

    setValue(clonedValues);
  };

  const handleSave = submitFactory(async () => {
    const comPreferences = values.communicationPreferences;

    if (!values.deviatingCommunicationType) comPreferences.invoiceCommunicationPreferences = [];

    try {
      const updatedCustomer = (
        await sendRequest<CustomerType>({
          request: {
            method: METHODS.POST,
            endpoint: `/md/customers/${customer.id}/communicationpreferences`,
            data: {
              communicationPreferences: values.communicationPreferences
            }
          },
          customHeaders: createHeader({
            'If-Match': customer._etag
          }),
          tenantReducer,
          lang: i18n.lang
        })
      ).data;

      notify.success({
        content: i18n.getTranslation('communication.update_success')
      });

      setValue(
        {
          ...clone(values),
          communicationPreferences: updatedCustomer.communicationPreferences
        },
        false,
        true
      );
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('communication.update_fail'),
        error: e
      });
    }
  });

  const handleCancel = async () => {
    setValue({
      commPreference: getPreference(customer.communicationPreferences.invoiceCommunicationPreferences),
      deviatingLanguage:
        customer.communicationPreferences.culture &&
        customer.communicationPreferences.culture !== cultureTable.defaultCulture,
      deviatingCommunicationType: customer.communicationPreferences.invoiceCommunicationPreferences.length > 0
    });
    restoreValues();
  };

  const setLanguagePreference = (culture_: culture | null) => {
    const commPrefsClone = clone(values.communicationPreferences);
    commPrefsClone.culture = culture_;
    setValue({ communicationPreferences: commPrefsClone });
  };

  const setDefaultFlow = (flowId: string) => {
    const commPrefsClone = clone(values.communicationPreferences);
    commPrefsClone.collectionFlowId = flowId;
    setValue({ communicationPreferences: commPrefsClone });
  };

  return (
    <SuspenseCard id="communication-preferences-card">
      <CardHeader
        primaryText={isDirty ? i18n.getTranslation('general.save') : undefined}
        onPrimaryClick={handleSave}
        secondaryText={isDirty ? i18n.getTranslation('general.cancel') : undefined}
        onSecondaryClick={handleCancel}
      >
        {i18n.getTranslation('customer.communication_preferences')}
      </CardHeader>
      <CardBody className={css['card-body']} fixedHeight>
        {/* Language */}
        <>
          <div className={css['check-wrapper']}>
            <CheckBox
              id="customer.update_language"
              checked={values.deviatingLanguage ? values.deviatingLanguage : false}
              onChange={(val) => {
                if (!val) {
                  // If checkbox was checked and is unchecked, reset deviation
                  setLanguagePreference(null);
                }
                setValue({ deviatingLanguage: val });
              }}
            >
              {i18n.getTranslation('customer.deviating_lang')}
            </CheckBox>
          </div>
          {values.deviatingLanguage && (
            <InputContainer>
              <InlineLanguageDropdown
                id="dropdown-culture"
                onChange={(val) => setLanguagePreference(val[0])}
                values={cultureTable.supportedCultures.map((c) => {
                  return {
                    value: c,
                    text:
                      c === cultureTable.defaultCulture
                        ? `${enumReducer.getTranslation('culture', c)} (${i18n.getTranslation('general.default')})`
                        : enumReducer.getTranslation('culture', c)
                  };
                })}
                selectedValues={[
                  values.communicationPreferences.culture ? values.communicationPreferences.culture : ('' as culture)
                ]}
              />
            </InputContainer>
          )}
        </>

        <div className={css['check-wrapper']}>
          <CheckBox
            id="customer.update_communicationprefs"
            checked={values.deviatingCommunicationType}
            onChange={(val) => setValue({ deviatingCommunicationType: val })}
          >
            {i18n.getTranslation('customer.deviating_communication')}
          </CheckBox>
        </div>
        {values.deviatingCommunicationType && (
          <>
            <Center type="horizontal">
              <Select
                id="customer.communication_type"
                className={css['type-select']}
                type="small"
                onChange={(val) => updatePreferences(val[0] as ComPreferenceType)}
                selectedValues={[values.commPreference]}
                values={[
                  {
                    icon: 'at',
                    value: communicationType.email,
                    text: enumReducer.getTranslation('communicationType', communicationType.email)
                  },
                  {
                    icon: 'enveloppe',
                    value: communicationType.manual,
                    text: enumReducer.getTranslation('communicationType', communicationType.manual)
                  },
                  {
                    icon: 'skip',
                    value: communicationType.skipifsdd,
                    text: enumReducer.getTranslation('communicationType', communicationType.skipifsdd)
                  },
                  {
                    icon: 'shuffle',
                    value: 'custom',
                    text: i18n.getTranslation('customer.custom')
                  }
                ]}
              />
            </Center>
            {values.commPreference === 'custom' && (
              <div className={css['prefs-grid']}>
                {invoiceTypes.map((type) => {
                  return (
                    <CardItem width="6" key={`dropdown-${type.value}`}>
                      <div className={css['prefs-row-grid']}>
                        <Label>{type.text}</Label>
                        <InlineCommTypeDropdown
                          id={`customer.scenario_type.index-${type.value}`}
                          onChange={(val) => setSelectedValue(type.value, val[0], values, setValue)}
                          values={communicationTypes}
                          selectedValues={getSelectedValue(type.value, values) as ComPreferenceType[]}
                        />
                      </div>
                    </CardItem>
                  );
                })}
              </div>
            )}

            <CardItem width="6">
              <HorizontalDivider />
            </CardItem>
            <CardItem width="6">
              <div className={css['prefs-row-grid']}>
                <Label>{i18n.getTranslation('collection_flows.collection_flow')}</Label>
                <CollectionFlowsDropdown
                  id="collection-flow"
                  onChange={(flow) => setDefaultFlow(flow[0] ? flow[0].id : '')}
                  selectedValue={
                    values.communicationPreferences.collectionFlowId
                      ? values.communicationPreferences.collectionFlowId
                      : ''
                  }
                  isInline
                />
              </div>
            </CardItem>
          </>
        )}
      </CardBody>
    </SuspenseCard>
  );
}
