import React from 'react';

import { SocialTariffExportRowType, SocialTariffExportType } from '@zf/api-types/regionals/regionals';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import useListPage from '../../../../app-context/hooks/use-listpage';
import { Icon, Spinner } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import css from '../social-tariff.module.scss';

const useSocialTariffListPage = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const processRecord = (tariff: SocialTariffExportType): SocialTariffExportRowType => {
    return {
      __id: tariff.id,
      __etag: tariff._etag,
      __entity: tariff,
      fileName: (
        <>
          {tariff.generated ? (
            tariff.filename
          ) : (
            <div className={css['file-generating']}>
              <Spinner size="xsmall" centered />{' '}
              <Paragraph>{getTranslation('country_specifics.file_generation')}</Paragraph>
            </div>
          )}
        </>
      ),
      creationDate: formatDate(tariff.createdDateTime),
      onlyMutations: <>{tariff.onlyMutations && <Icon color={colors['green-600']} name="check" />}</>,
      generated: (
        <>{tariff.generated ? <Icon color={colors['green-600']} name="check" /> : <Spinner size="xsmall" />}</>
      ),
      startDateTime: formatDate(tariff.startDateTime),
      endDateTime: formatDate(tariff.endDateTime)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    setSelectedIds,
    setStopIndex,
    handleSort
  } = useListPage<SocialTariffExportType, SocialTariffExportRowType>({
    endpoint: '/reg/socialtariff/exports',
    processRecord,
    domain: 'country_specifics'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useSocialTariffListPage;
