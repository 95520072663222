import classNames from 'classnames';
import React, { memo } from 'react';

import Button from '@zf/heat-erp/src/components/Button/Button';

import { Heading } from '../Heading';
import css from './card-header.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  primaryText?: string;
  secondaryText?: string;
  isErrors?: boolean;
  filters?: JSX.Element;
  tags?: JSX.Element[];
  extraRight?: React.ReactNode;
  extraLeft?: React.ReactElement;
  warning?: boolean;
  extra?: JSX.Element;
  primaryOnly?: boolean;
  noHTag?: boolean;
  onSecondaryClick?: Function;
  onPrimaryClick?: Function;
};

const  CardHeader=(props: Props)=> {
  const {
    children,
    className,
    primaryText,
    secondaryText,
    isErrors,
    extraLeft,
    filters,
    tags,
    extraRight,
    warning = false,
    noHTag = false,
    onPrimaryClick,
    onSecondaryClick
  } = props;

  return (
    <div className={classNames(css['card-header'], { [css['filter-and-tags']]: !!filters && !!tags }, className)}>
      <div className={classNames(css['card-header-extra-left'], { [css['extra-left-padding']]: !!extraLeft })}>
        {extraLeft}
      </div>

      <div className={css['card-header-heading']}>
        {noHTag ? (
          <>{children}</>
        ) : (
          <Heading headingType="h3" className={classNames({ [css['header-warning']]: warning })}>
            {children}
          </Heading>
        )}
      </div>

      {filters && <div className={css['card-header-filters']}>{filters}</div>}

      {tags && <div className={css['card-header-tags']}>{tags}</div>}

      {primaryText && onPrimaryClick && extraRight && (
        <div className={css['card-header-extra-right']}>{extraRight}</div>
      )}

      <div className={css['card-header-extra-right']}>
        {secondaryText && onSecondaryClick && (
          <Button
            submitType="submit"
            id="secondary-button"
            onClick={onSecondaryClick}
            type="text"
            size="small"
            className={css['card-header-button']}
          >
            {secondaryText}
          </Button>
        )}
      </div>

      <div className={css['card-header-primary']}>
        {primaryText && onPrimaryClick ? (
          <Button
            submitType="submit"
            id="primary-button"
            onClick={onPrimaryClick}
            type="primary"
            size="small"
            className={css['card-header-button']}
            disabled={isErrors}
          >
            {primaryText}
          </Button>
        ) : (
          !!extraRight && extraRight
        )}
      </div>
    </div>
  );
}

export default memo(CardHeader)
