import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { propertyGroupType } from '@zf/api-types/enums';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import CountryInput, { CountryInputProps } from '../../../../components/input/country-input';
import { InlineInputFieldInput } from '../../../../components/input/InputField';
import Select from '../../../../components/input/Select';
import { BaseButton } from '../../../../design-system/Components';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { formatAddress } from '../../../../utils/address';
import { createHeader, METHODS } from '../../../../utils/request';
import css from './details-card.module.scss';

type Props = {
  propertyGroup: PropertyGroupType;
  setPropertyGroup: Dispatch<SetStateAction<PropertyGroupType | null>>;
};

type UpdatePropertyDetailType = {
  type: propertyGroupType;
  name: string;
  streetName: string;
  streetNumber: string;
  streetNumberAddition?: string;
  postalCode: string;
  paymentTermsId?: string;
  city: string;
  country: string;
};

const InlineCountryInput = InlineInputField<CountryInputProps>(CountryInput);

const PropertyGroupDetailsCard = (props: Props) => {
  const { propertyGroup, setPropertyGroup } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation, sendRequest } = applicationStore;
  const { authReducer } = useAppContext();
  const { hasPermission } = authReducer;

  const [selectValues] = useState(
    getEnum<propertyGroupType>('propertyGroupType').map((pgt) => {
      return {
        icon: pgt.value,
        value: pgt.value,
        text: pgt.text
      };
    })
  );

  const [isAddressEditing, setIsAddressEditing] = useState(false);

  const { values, isDirty, setValue, backupValues, restoreValues, submitFactory } =
    useValidator<UpdatePropertyDetailType>({
      initialValues: {
        type: propertyGroup.type,
        name: propertyGroup.name,
        streetName: propertyGroup.address.streetName,
        streetNumber: propertyGroup.address.streetNumber,
        streetNumberAddition: propertyGroup.address.streetNumberAddition,
        postalCode: propertyGroup.address.postalCode,
        city: propertyGroup.address.city,
        country: propertyGroup.address.country
      },
      refreshTrigger: propertyGroup.id
    });

  const handleSave = submitFactory(async () => {
    try {
      const response = await sendRequest<PropertyGroupType>({
        request: {
          method: METHODS.POST,
          endpoint: `/md/propertyGroups/${propertyGroup.id}`,
          data: {
            type: values.type,
            name: values.name,
            address: {
              streetName: values.streetName,
              streetNumber: values.streetNumber,
              streetNumberAddition: values.streetNumberAddition,
              postalCode: values.postalCode,
              city: values.city,
              country: values.country
            }
          }
        },
        customHeaders: createHeader({
          'If-Match': propertyGroup._etag
        })
      });

      notify.success({
        content: getTranslation('property_groups.update_succes')
      });

      backupValues();
      setIsAddressEditing(false);
      setPropertyGroup(response.data);
    } catch (e) {
      notify.error({
        content: getTranslation('property_groups.update_failed'),
        error: e
      });
    }
  });

  const handleCancel = () => {
    setIsAddressEditing(false);
    restoreValues();
  };

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      handleCancel();
    } else if (event.key === 'Enter' && isDirty) {
      handleSave();
      if (document.activeElement) {
        const elem = document.activeElement as HTMLElement;
        elem.blur();
      }
    }
  };

  const formattedAddress = formatAddress(propertyGroup.address, getEnumTranslation);

  const renderAddress = () => {
    return isAddressEditing ? (
      <div className={css['invoice-address']}>
        <div className={css['street-name']}>
          <InlineInputFieldInput
            id="detail-streetname"
            onKeyDown={keyHandler}
            value={values.streetName}
            onChange={(streetName) =>
              setValue({
                streetName
              })
            }
            placeholder={getTranslation('location.street_name')}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </div>
        <InlineInputFieldInput
          id="detail-streetNumber"
          onKeyDown={keyHandler}
          value={values.streetNumber}
          onChange={(streetNumber) =>
            setValue({
              streetNumber
            })
          }
          placeholder={getTranslation('location.streetnr')}
        />
        <InlineInputFieldInput
          id="detail-streetNumberAddition"
          onKeyDown={keyHandler}
          value={values.streetNumberAddition}
          onChange={(streetNumberAddition) =>
            setValue({
              streetNumberAddition
            })
          }
          placeholder={getTranslation('location.streetnraddition')}
        />
        <InlineInputFieldInput
          id="detail-postalCode"
          onKeyDown={keyHandler}
          value={values.postalCode}
          onChange={(postalCode) =>
            setValue({
              postalCode
            })
          }
          placeholder={getTranslation('location.postal')}
        />
        <InlineInputFieldInput
          id="detail-city"
          onKeyDown={keyHandler}
          value={values.city}
          onChange={(city) =>
            setValue({
              city
            })
          }
          placeholder={getTranslation('location.city')}
        />
        <InlineCountryInput
          id="detail-country"
          selectedValues={[values.country]}
          onChange={(val) =>
            setValue({
              country: val[0]
            })
          }
          placeholder={getTranslation('location.country')}
          error={!values.country}
          clear={false}
          organisationScoped
        />
      </div>
    ) : (
      <BaseButton id="address-edit-trigger" onClick={() => setIsAddressEditing(true)} className={css['address-input']}>
        <Paragraph>{formattedAddress}</Paragraph>
      </BaseButton>
    );
  };

  return (
    <Card id="property-group-details-card">
      <CardHeader
        primaryText={isDirty ? getTranslation('general.save') : undefined}
        onPrimaryClick={handleSave}
        secondaryText={isDirty || isAddressEditing ? getTranslation('general.cancel') : undefined}
        onSecondaryClick={handleCancel}
      >
        {getTranslation('general.details')}
      </CardHeader>
      <CardBody type="grid">
        <CardItem width="5">
          <Label>{getTranslation('general.name')}</Label>
        </CardItem>
        <CardItem width="7">
          <InlineInputFieldInput
            id="propertygroup.name"
            onKeyDown={keyHandler}
            value={values.name}
            onChange={(name) =>
              setValue({
                name
              })
            }
            error={!values.name}
          />
        </CardItem>

        <CardItem width="12" className={css['relative']}>
          <Label>{getTranslation('general.type')}</Label>
          <div className={css['type']}>
            <Select
              id="type"
              onChange={(val) => setValue({ type: val[0] })}
              selectedValues={[values.type]}
              values={selectValues}
            />
          </div>
        </CardItem>

        <CardItem width="5">
          <Label>{getTranslation('property_groups.address_list')}</Label>
        </CardItem>
        <CardItem width="7">
          {hasPermission('propertygroup.update_details') ? renderAddress() : <Paragraph>{formattedAddress}</Paragraph>}
        </CardItem>
      </CardBody>
    </Card>
  );
};

export default observer(PropertyGroupDetailsCard);
