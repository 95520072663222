import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';

import { RowTypeUnbilled } from '../hooks/useUnbilled';

type updateGivenRowsType = (
  updatedRecords: BillingCompletenessType[],
  deletedRecords?: Partial<RowTypeUnbilled>[] | undefined
) => Promise<RowTypeUnbilled[]>;

export type UnbilledState = {
  currentId: string;
  firstRefresh: boolean;
  actionTriggered: boolean;
  timeStamp: number | undefined;
  timeOut: number | undefined;
  selectedItems: string[];
  rows: RowTypeUnbilled[] | undefined;
  updateGivenRows: updateGivenRowsType | undefined;
};

type Action = { type: 'SET_STATE'; newState: Partial<UnbilledState> };

const initialState: UnbilledState = {
  currentId: '',
  firstRefresh: false,
  actionTriggered: false,
  timeStamp: undefined,
  timeOut: undefined,
  selectedItems: [],
  rows: undefined,
  updateGivenRows: undefined
};

const reducer = (state: UnbilledState, action: Action): UnbilledState => {
  return {
    ...state,
    ...action.newState
  };
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider: UnbilledProvider, useTracked, useUpdate: useDispatch } = createContainer(useValue);
