import classNames from 'classnames';
import Interweave from 'interweave';
import React from 'react';

import css from './subtext.module.scss';

export type ParagraphProps = {
  children: string;
  bold?: boolean;
};

export default function Subtext(props: ParagraphProps) {
  const { children, bold = false } = props;

  return (
    <Interweave
      className={classNames(css['subtext'], {
        [css['text-bold']]: bold
      })}
      content={children || typeof children === 'number' ? children : '-'}
    />
  );
}
