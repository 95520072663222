import React from 'react';

export default function useSubmitFactory() {
  const [isLocked, setIsLocked] = React.useState(false);

  const submitFactory = (callback: Function) => {
    return async () => {
      if (isLocked) return;

      setIsLocked(true);
      await callback();
      setIsLocked(false);
    };
  };

  return {
    isLocked,
    submitFactory
  };
}
