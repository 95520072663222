import React from 'react';

import { OutputChannelType } from '@zf/api-types/property-metering-configuration';
import useDialog from '@zf/hooks/src/useDialog';

import AddOutputChannelDialog from '../../../../../../actions/property-group/add-output-channel-dialog';
import { useAppContext } from '../../../../../../app-context';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import { dialog } from '../../../../../../events/dialog-events';
import useMeteringData from '../../hooks/use-metering-data';
import CalculationOutput from './calculation-output';

type Props = {
  subTitle?: string;
  isDirty?: boolean;
  enableAdd?: boolean;
  enableDelete?: boolean;
  outputChannels: OutputChannelType[];
};

export default function CalculationOutputs(props: Props) {
  const { outputChannels, isDirty, subTitle = '', enableAdd = false, enableDelete = false } = props;
  const { i18n, authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { propertyMeteringConfiguration, setPropertyMeteringConfiguration } = useMeteringData();

  const openAddDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.metering.add_calculation_header'),
      icon: 'plus',
      content: (
        <AddOutputChannelDialog
          ref={clickRef}
          propertyMeteringConfiguration={propertyMeteringConfiguration}
          validationRef={validationRef}
          setPropertyMeteringConfiguration={setPropertyMeteringConfiguration}
        />
      ),
      buttonPositive: i18n.getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      title={i18n.getTranslation('property_groups.tabs.metering.calc_outputs')}
      subTitleBelow={subTitle}
      add={enableAdd && hasPermission('property_group_metering_config.update') ? openAddDialog : undefined}
      borderRight
    >
      {outputChannels.length > 0 ? (
        outputChannels.map((oChann) => {
          return (
            <CalculationOutput
              key={oChann.id}
              outputChannel={oChann}
              isDirty={isDirty ? isDirty : false}
              enableDelete={enableDelete}
            />
          );
        })
      ) : (
        <EmptyColumn
          image="meter"
          title="Calculation outputs"
          text={i18n.getTranslation('property_groups.tabs.metering.calc_outputs_empty_state')}
        />
      )}
    </ConfigColumn>
  );
}
