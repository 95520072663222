import React from 'react';

import { LocalisedErrorType } from '@zf/api-types/general';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DANGER } from '../../../../constants/color';

export default function useMutations() {
  const renderErrors = (errors: LocalisedErrorType[]) => {
    if (errors[0] && errors.length === 1) {
      return (
        <Paragraph color={DANGER} bold>
          {errors[0].message}
        </Paragraph>
      );
    }
    //minus 1 because there is already an error rendered
    return <Paragraph color={DANGER} bold>{`+${errors.length - 1}`}</Paragraph>;
  };

  return { renderErrors };
}
