import React from 'react';

import { invoiceStatus, invoiceType } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import DeleteInvoiceDialog from '../dialogs/delete-dialog';

type Props = {
  refreshCounts: () => void;
};

export default function DeleteInvoiceListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows, setDeletedRows } = useUpdateListPage<InvoiceType>('invoice');
  const { clickRef, onSubmit } = useDialog();

  const countDelete = selectedRows.filter(
    (invoice) => invoice.__entity.status === invoiceStatus.created && !invoice.__entity.creditedByInvoiceId
  ).length;

  const countCredit = selectedRows.filter(
    (invoice) =>
      invoice.__entity.status !== invoiceStatus.created &&
      invoice.__entity.type !== invoiceType.creditnote &&
      !invoice.__entity.creditedByInvoiceId
  ).length;

  const disabled =
    selectedRows.length === 0 || (countDelete > 0 && countCredit > 0) || (countDelete === 0 && countCredit === 0);

  const credit = countCredit > countDelete;

  return (
    <DropdownAction
      id="invoice.delete"
      icon={credit ? 'credit-note' : 'trashcan'}
      dialogTitle={i18n.getTranslation(`actions.${credit ? 'credit' : 'delete'}`)}
      dialogContent={
        <DeleteInvoiceDialog
          onComplete={credit ? setUpdatedRows : setDeletedRows}
          ref={clickRef}
          selectedRows={selectedRows}
          credit={credit}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      dialogType={credit ? 'default' : 'danger'}
      buttonPositive={i18n.getTranslation(`actions.${credit ? 'credit' : 'delete'}`)}
    />
  );
}
