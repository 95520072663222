import React from 'react';

import { invoiceStatus, invoiceType, paymentMethod, paymentProcessStatus, exportStatus } from '@zf/api-types/enums';
import { InvoiceOverviewCountType } from '@zf/api-types/invoice';
import { removeFromList } from '@zf/utils/src/array';

import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { ArrayParam, StringParam } from 'use-query-params';

const initialInvoiceOverviewCount: InvoiceOverviewCountType = {
  all: 0,
  requireAttention: 0,
  readyForPaymentCollection: 0,
  toApprove: 0,
  toSend: 0,
  unpaid: 0,
  overdue: 0,
  toExport: 0
};

export type QueryParam = {
  invoiceStatus?: invoiceStatus[];
  invoiceType?: invoiceType[];
  paymentMethod?: paymentMethod[];
  paymentProcessStatus?: paymentProcessStatus[];
  startDateTime?: string;
  endDateTime?: string;
  periodStartDateTime?: string;
  periodEndDateTime?: string;
  propertyGroupId: string;
  propertyGroupName: string;
  customerGroupId: string;
  customerGroupName: string;
  customerId: string;
  customerName: string;
  periodEndDateTimeEnd?: string;
  periodEndDateTimeBegin?: string;
  exportStatus?: exportStatus[];
};

const initialQueryParams = {
  startDateTime: undefined,
  endDateTime: undefined,
  periodStartDateTime: undefined,
  periodEndDateTime: undefined,
  periodEndDateTimeBegin: undefined,
  periodEndDateTimeEnd: undefined,
  propertyGroupId: '',
  propertyGroupName: '',
  customerGroupId: '',
  customerGroupName: '',
  customerId: '',
  customerName: ''
};

const paramTypes = {
  propertyGroupId: StringParam,
  propertyGroupName: StringParam,
  customerGroupId: StringParam,
  customerGroupName: StringParam,
  invoiceStatus: ArrayParam,
  invoiceType: ArrayParam,
  paymentMethod: ArrayParam,
  exportStatus: ArrayParam,
  paymentProcessStatus: ArrayParam,
  startDateTime: StringParam,
  endDateTime: StringParam,
  periodStartDateTime: StringParam,
  periodEndDateTime: StringParam,
  periodEndDateTimeBegin: StringParam,
  periodEndDateTimeEnd: StringParam,
  customerId: StringParam,
  customerName: StringParam
};

const useInvoiceFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    setQueryParams,
    clearQueryParams
  } = useFilter<QueryParam>({
    endpoint: '/bill/invoices/overviewcount',
    initialCount: initialInvoiceOverviewCount,
    domain: 'invoice',
    initialQueryParams,
    search,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.startDateTime !== undefined || queryParams.endDateTime !== undefined) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.startDateTime, queryParams.endDateTime]}
        removeFilter={() => setQueryParams({ startDateTime: undefined, endDateTime: undefined })}
        key="invoice-date"
      />
    );
  }

  if (queryParams.periodStartDateTime !== undefined || queryParams.periodEndDateTime !== undefined) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.periodStartDateTime, queryParams.periodEndDateTime]}
        removeFilter={() => setQueryParams({ periodStartDateTime: undefined, periodEndDateTime: undefined })}
        key="invoice-period"
      />
    );
  }

  if (queryParams.periodEndDateTimeBegin !== undefined || queryParams.periodEndDateTimeEnd !== undefined) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.periodEndDateTimeBegin, queryParams.periodEndDateTimeEnd]}
        removeFilter={() => setQueryParams({ periodEndDateTimeEnd: undefined, periodEndDateTimeBegin: undefined })}
        key="invoice-period-date-time"
      />
    );
  }

  if (queryParams.invoiceStatus && queryParams.invoiceStatus.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="invoiceStatus"
        value={queryParams.invoiceStatus[0]}
        removeFilter={() => setQueryParams({ invoiceStatus: [] as invoiceStatus[] })}
        key="invoice-status"
      />
    );
  }

  if (queryParams.propertyGroupId && queryParams.propertyGroupName) {
    tags.push(
      <FilterTag
        value={queryParams.propertyGroupName}
        removeFilter={() => setQueryParams({ propertyGroupId: '', propertyGroupName: '' })}
        key="property-group"
      />
    );
  }
  if (queryParams.customerGroupId && queryParams.customerGroupName) {
    tags.push(
      <FilterTag
        value={queryParams.customerGroupName}
        removeFilter={() => setQueryParams({ customerGroupId: '', customerGroupName: '' })}
        key="customer-group"
      />
    );
  }

  if (queryParams.customerId && queryParams.customerName) {
    tags.push(
      <FilterTag
        value={queryParams.customerName}
        removeFilter={() => setQueryParams({ customerId: '', customerName: '' })}
        key="property-group"
      />
    );
  }

  if (queryParams.invoiceType) {
    tags.push(
      queryParams.invoiceType.map((it) => {
        return (
          <FilterTag
            type="enum"
            enumType="invoiceType"
            value={it}
            removeFilter={() => setQueryParams({ invoiceType: removeFromList(it, queryParams.invoiceType) })}
            key={`invoice-type-${it}`}
          />
        );
      })
    );
  }
  if (queryParams.paymentMethod && queryParams.paymentMethod.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="paymentMethod"
        value={queryParams.paymentMethod[0]}
        removeFilter={() => setQueryParams({ paymentMethod: [] as paymentMethod[] })}
        key="payment-method"
      />
    );
  }

  if (queryParams.exportStatus && queryParams.exportStatus.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="exportStatus"
        value={queryParams.exportStatus[0]}
        removeFilter={() => setQueryParams({ exportStatus: [] as exportStatus[] })}
        key="export-status"
      />
    );
  }

  if (queryParams.paymentProcessStatus && queryParams.paymentProcessStatus.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="paymentProcessStatus"
        value={queryParams.paymentProcessStatus[0]}
        removeFilter={() => setQueryParams({ paymentProcessStatus: [] as paymentProcessStatus[] })}
        key="payment-process-status"
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    setQueryParams,
    clearQueryParams,
    refreshCounts: refresh,
    quickFilter,
    overviewCount,
    searchValue,
    queryParams,
    filterTags
  };
};

export default useInvoiceFilter;
