import clone from 'clone';
import { useCallback } from 'react';

import { managementRelationType, utilityType } from '@zf/api-types/enums';
import { PropertyGroupForecastingConfiguration } from '@zf/api-types/forecasting/propertygroup-forecasting-cfg';
import {
  CommunicationConfigurationType,
  PropertyGroupAttributeType,
  PropertyGroupType
} from '@zf/api-types/master-data/property-group';
import {
  CreatePropertyGroupBillingConfigurationType,
  PropertyGroupBillingConfigurationType
} from '@zf/api-types/property-group-billing-configuration';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { deepEqual } from '@zf/utils/src/object';

import { useAppContext } from '../../../../../app-context/app-context';
import { notify } from '../../../../../events/notification-events';
import { METHODS, sendRequest } from '../../../../../utils/request';
import { useTracked } from '../context/settings-context';

type IntersectionType = OrganizationConfigType | CommunicationConfigurationType;

export default function usePropertygroupSettings() {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer } = useAppContext();

  const setCommConfigValue = useCallback(
    (value: Partial<CommunicationConfigurationType>) => {
      dispatch({ type: 'SET_COMM_CONFIG_VALUE', value });
    },
    [dispatch]
  );

  const resetCommConfigValue = useCallback(
    (key: keyof IntersectionType) => {
      if (state.communicationConfiguration && state.organisationConfig) {
        const cloned: any = clone(state.communicationConfiguration);
        cloned[key] = clone(state.organisationConfig[key]);
        dispatch({ type: 'SET_COMM_CONFIG_VALUE', value: cloned });
      }
    },
    [state.communicationConfiguration, state.organisationConfig, dispatch]
  );

  const resetAddress = useCallback(() => {
    if (state.communicationConfiguration && state.organisationConfig) {
      const newCommConfigValues = clone(state.communicationConfiguration);
      newCommConfigValues.address = clone(state.organisationConfig.address);
      setCommConfigValue(newCommConfigValues);
    }
  }, [state.communicationConfiguration, state.organisationConfig, dispatch]);

  const setBillingConfigValue = useCallback(
    (value: Partial<PropertyGroupBillingConfigurationType>) => {
      dispatch({ type: 'SET_BILLING_CONFIG_VALUE', value });
    },
    [dispatch]
  );

  const setFctConfigValue = useCallback(
    (utilityType: utilityType, consumerGroupId: string, consumerGroupCode: string) => {
      dispatch({ type: 'SET_FCT_CONFIG_VALUE', utilityType, consumerGroupId, consumerGroupCode });
    },
    [dispatch]
  );

  const setSelectedUtilityTypes = useCallback(
    (value: utilityType[]) => {
      dispatch({ type: 'SET_SELECTED_UTILITY_TYPES', value });
    },
    [dispatch]
  );

  const setOwner = useCallback(
    (ownerId: string) => {
      dispatch({ type: 'SET_OWNER', ownerId });
    },
    [dispatch]
  );

  const setManager = useCallback(
    (managerId: string) => {
      dispatch({ type: 'SET_MANAGER', managerId });
    },
    [dispatch]
  );

  const resetAll = useCallback(() => {
    dispatch({ type: 'RESET_ALL' });
  }, [dispatch]);

  const backupValues = useCallback(
    (
      managementRelationsSuccess: boolean,
      billingConfigSuccess: boolean,
      commConfigSuccess: boolean,
      fctConfigSuccess: boolean
    ) => {
      dispatch({
        type: 'BACKUP',
        managementRelationsSuccess,
        billingConfigSuccess,
        commConfigSuccess,
        fctConfigSuccess
      });
    },
    [dispatch]
  );

  const restore = useCallback(() => {
    dispatch({ type: 'RESTORE' });
  }, [dispatch]);

  const handleSave = async () => {
    dispatch({ type: 'SET_LOCKED', isLocked: true });

    // Control vars for backup, only backup successfull changes
    let managementRelationsSuccess = false;
    let billingConfigSuccess = false;
    let fctConfigSuccess = false;
    let commConfigSuccess = false;

    try {
      // Update management relations
      if (state.managerId !== state.backup.managerId || state.ownerId !== state.backup.ownerId) {
        let managementRelations = [];

        if (state.managerId) {
          managementRelations.push({
            customerId: state.managerId,
            managementRelationType: managementRelationType.propertymanager
          });
        }

        if (state.ownerId) {
          managementRelations.push({ customerId: state.ownerId, managementRelationType: managementRelationType.owner });
        }

        await sendRequest<PropertyGroupType>({
          request: {
            method: METHODS.POST,
            endpoint: `/md/propertyGroups/${state.propertyGroup?.id}/managementrelations`,
            data: {
              managementRelations
            }
          },

          tenantReducer,
          lang: i18n.lang
        });

        managementRelationsSuccess = true;
      }

      // Update billing config
      if (
        state.propertyGroupBillingConfig &&
        state.backup.propertyGroupBillingConfig &&
        !deepEqual(state.propertyGroupBillingConfig, state.backup.propertyGroupBillingConfig)
      ) {
        const {
          propertyGroup = {
            id: '',
            name: ''
          },
          advanceFrequency = null,
          invoiceDay = 0,
          invoiceMonth = 0,
          invoiceFrequency = null,
          productId = null,
          paymentTermsId = null,
          companyBankAccountId = null
        } = state.propertyGroupBillingConfig;

        const apiFriendlyValues: CreatePropertyGroupBillingConfigurationType = {
          propertyGroup: propertyGroup as PropertyGroupAttributeType,
          advanceFrequency,
          invoiceDay,
          invoiceMonth,
          invoiceFrequency,
          productId,
          paymentTermsId,
          companyBankAccountId
        };

        await sendRequest<PropertyGroupBillingConfigurationType>({
          request: {
            method: METHODS.POST,
            endpoint: '/bill/PropertyGroupBillingConfiguration',
            data: apiFriendlyValues
          },
          tenantReducer,
          lang: i18n.lang
        });

        billingConfigSuccess = true;
      }

      // Update communication preferences
      if (
        state.communicationConfiguration &&
        state.backup.communicationConfiguration &&
        !deepEqual(state.communicationConfiguration, state.backup.communicationConfiguration)
      ) {
        await sendRequest<CommunicationConfigurationType>({
          request: {
            method: METHODS.POST,
            endpoint: `/md/PropertyGroups/${state.propertyGroup?.id}/communicationconfiguration`,
            data: {
              companyName: state.communicationConfiguration.companyName,
              vatAccountNumber: state.communicationConfiguration.vatAccountNumber,
              companyAccountNumber: state.communicationConfiguration.companyAccountNumber,
              logo: state.communicationConfiguration.logo,
              logoCdnUrl: state.communicationConfiguration.logoCdnUrl,
              primaryColor: state.communicationConfiguration.primaryColor,
              secondaryColor: state.communicationConfiguration.secondaryColor,
              address: state.communicationConfiguration.address,
              contactDetails: {
                emailAddress: state.communicationConfiguration.contactDetails?.emailAddress || '',
                telephone: state.communicationConfiguration.contactDetails?.telephone || '',
                website: state.communicationConfiguration.contactDetails?.website || '',
                telephoneInterruptions: state.communicationConfiguration.contactDetails?.telephoneInterruptions || ''
              }
            }
          },
          tenantReducer,
          lang: i18n.lang
        });

        commConfigSuccess = true;
      }

      // Update consumption groups
      if (
        state.propertyGroupForecastingConfig &&
        state.propertyGroupForecastingConfig.consumerGroups &&
        state.backup.propertyGroupForecastingConfig &&
        (!deepEqual(state.propertyGroupForecastingConfig, state.backup.propertyGroupForecastingConfig) ||
          !deepEqual(state.selectedUtilityTypes, state.backup.selectedUtilityTypes))
      ) {
        let apiFriendlyValues = {} as Record<utilityType, string>;
        let utilityTypes: utilityType[] = [];

        Object.keys(state.propertyGroupForecastingConfig.consumerGroups).forEach((utility) => {
          if (state.selectedUtilityTypes.includes(utility as utilityType)) {
            const id = state.propertyGroupForecastingConfig?.consumerGroups?.[utility as utilityType].id;
            if (id) {
              apiFriendlyValues[utility as utilityType] = id;
              utilityTypes.push(utility as utilityType);
            }
          }
        });

        await sendRequest<PropertyGroupForecastingConfiguration>({
          request: {
            method: METHODS.POST,
            endpoint: `/fct/PropertyGroupForecastingConfiguration/${state.propertyGroup?.id}/consumergroups`,
            data: { consumerGroups: apiFriendlyValues }
          },
          tenantReducer,
          lang: i18n.lang
        });

        fctConfigSuccess = true;
        setSelectedUtilityTypes(utilityTypes);
      }
      // isLocked: false is covered inside backup
      backupValues(managementRelationsSuccess, billingConfigSuccess, commConfigSuccess, fctConfigSuccess);

      notify.success({
        content: i18n.getTranslation('property_groups.update_settings_success')
      });
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.update_settings_fail'),
        error: e
      });

      dispatch({ type: 'SET_LOCKED', isLocked: false });
    }
  };

  const showResetAll = () => {
    // Objects aren't 1/1 comparable which is not so nice
    if (state.communicationConfiguration && state.organisationConfig) {
      return (
        (state.communicationConfiguration.address &&
          !deepEqual(state.communicationConfiguration.address, state.organisationConfig?.address)) ||
        (state.communicationConfiguration.contactDetails &&
          !deepEqual(state.communicationConfiguration.contactDetails, state.organisationConfig.contactDetails)) ||
        state.communicationConfiguration.companyAccountNumber !== state.organisationConfig.companyAccountNumber ||
        state.communicationConfiguration.vatAccountNumber !== state.organisationConfig.vatAccountNumber ||
        state.communicationConfiguration.logo !== state.organisationConfig.logo ||
        state.communicationConfiguration.primaryColor !== state.organisationConfig.primaryColor ||
        state.communicationConfiguration.secondaryColor !== state.organisationConfig.secondaryColor
      );
    }

    return false;
  };

  return {
    propertygroup: state.propertyGroup,
    communicationConfiguration: state.communicationConfiguration,
    propertyGroupBillingConfig: state.propertyGroupBillingConfig,
    ownerId: state.ownerId,
    managerId: state.managerId,
    isDirty: state.isDirty,
    isLocked: state.isLocked,
    organisationConfig: state.organisationConfig,
    availableUtilityTypes: state.availableUtilityTypes,
    propertyGroupForecastingConfig: state.propertyGroupForecastingConfig,
    selectedUtilityTypes: state.selectedUtilityTypes,
    setCommConfigValue,
    resetCommConfigValue,
    resetAddress,
    setBillingConfigValue,
    setFctConfigValue,
    setSelectedUtilityTypes,
    setOwner,
    setManager,
    handleSave,
    resetAll,
    backupValues,
    restore,
    showResetAll
  };
}
