import clone from 'clone';

import { EmailType } from '@zf/api-types/general';

import BaseFormVjf, { Fields } from '../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../events/notification-events';
import CommunicationStore from '../stores/CommunicationStore';

enum communicationParametersFormFields {
  enabled = 'enabled',
  isSandboxed = 'isSandboxed',
  sandboxRecipientEmail = 'sandboxRecipientEmail',
  senderEmail = 'senderEmail',
  replyTo = 'replyTo',
  domainName = 'domainName'
}

type CommunicationParametersFieldTypes = {
  enabled: boolean;
  isSandboxed: boolean;
  sandboxRecipientEmail: string;
  senderEmail: EmailType;
  replyTo: EmailType;
  domainName: string;
};

class CommunicationParametersForm extends BaseFormVjf<CommunicationParametersFieldTypes> {
  private communicationStore: CommunicationStore;

  constructor(communicationStore: CommunicationStore, initialValues: CommunicationParametersFieldTypes) {
    super(initialValues);

    this.communicationStore = communicationStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: communicationParametersFormFields.enabled,
          label: 'enabled',
          type: 'checkbox'
        },
        {
          name: communicationParametersFormFields.isSandboxed,
          label: 'isSandboxed',
          type: 'checkbox'
        },
        {
          name: communicationParametersFormFields.sandboxRecipientEmail,
          label: 'sandboxRecipientEmail'
        },
        {
          name: communicationParametersFormFields.senderEmail,
          label: 'senderEmail',
          fields: [
            {
              name: 'name',
              label: 'name'
            },
            {
              name: 'address',
              label: 'address'
            }
          ]
        },
        {
          name: communicationParametersFormFields.replyTo,
          label: 'senderEmail',
          fields: [
            {
              name: 'name',
              label: 'name'
            },
            {
              name: 'address',
              label: 'address'
            }
          ]
        },
        { name: communicationParametersFormFields.domainName, label: 'domainName', validators: [this.isRequired] }
      ]
    };
  }

  save = async () => {
    try {
      const { communicationService, rootStore } = this.communicationStore;
      const { domainAuthParameters, initCommunicationParametersForm } = this.communicationStore.settingsStore;

      let newCommunicationParams = clone(await rootStore.configStore.configService.getCommunicationParameters());
      let newDomainAuthParams = clone(domainAuthParameters);

      const {
        enabled: enabledBackup,
        isSandboxed: isSandboxedBackup,
        sandboxRecipientEmail: sandboxRecipientEmailBackup,
        senderEmail: senderEmailBackup,
        replyTo: replyTobackup,
        domainName: domainNameBackup
      } = this._backup;
      const { enabled, isSandboxed, sandboxRecipientEmail, senderEmail, replyTo, domainName } = this._values;

      const domainIsDirty = domainName !== domainNameBackup;

      const communicationParamsAreDirty =
        enabled !== enabledBackup ||
        isSandboxed !== isSandboxedBackup ||
        sandboxRecipientEmail !== sandboxRecipientEmailBackup ||
        senderEmail !== senderEmailBackup ||
        replyTo !== replyTobackup;

      if (domainIsDirty) {
        newDomainAuthParams = await communicationService.saveDomain(domainName);
      }

      if (communicationParamsAreDirty) {
        newCommunicationParams = await communicationService.updateCommunicationParameters({
          enabled,
          isSandboxed,
          sandboxRecipientEmail,
          senderEmail: this.fieldIsEmpty('senderEmail') ? null : senderEmail,
          replyTo: this.fieldIsEmpty('replyTo') ? null : replyTo
        });
      }

      initCommunicationParametersForm(newCommunicationParams, newDomainAuthParams);
    } catch (e) {
      notify.error({
        content: this.communicationStore.rootStore.applicationStore.getTranslation('communication.update_general_fail'),
        error: e
      });
    }
  };
}

export default CommunicationParametersForm;
