import { MultiValue } from 'design-system/ComponentSets';
import React from 'react';

import {
  dataFrequency,
  direction,
  incrementationType,
  meteringType,
  unitOfMeasure,
  utilityType
} from '@zf/api-types/enums';
import { ChannelTemplateType } from '@zf/api-types/master-data/meter';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../app-context';
import { initialChannel } from '../../features/devices/meter/wizard/ChannelsSection';
import InputField, { InputFieldProps } from '../input/InputField';
import SimpleDropdown from '../Lang/SimpleDropdown';
import css from './style.module.scss';

type Props = {
  title: string;
  initialValues: ChannelTemplateType[];
  onChange: (channels: ChannelTemplateType[]) => void;
  onFocus?: (step: string) => void;
};

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);
const WizardDataFreqDropdown = WizardInputWrapper<SimpleDropdownProps<dataFrequency>>(SimpleDropdown);
const WizardIncrTypeDropdown = WizardInputWrapper<SimpleDropdownProps<incrementationType>>(SimpleDropdown);
const WizardMeteringTypeDropdown = WizardInputWrapper<SimpleDropdownProps<meteringType>>(SimpleDropdown);
const WizardUtilityTypeDropdown = WizardInputWrapper<SimpleDropdownProps<utilityType>>(SimpleDropdown);
const WizardDirectionDropdown = WizardInputWrapper<SimpleDropdownProps<direction>>(SimpleDropdown);
const WizardUoMDropdown = WizardInputWrapper<SimpleDropdownProps<unitOfMeasure>>(SimpleDropdown);

export default function ChannelMultiValue(props: Props) {
  const { title, initialValues, onChange, onFocus } = props;
  const { i18n, enumReducer } = useAppContext();

  return (
    <MultiValue
      id="channel-multivalue"
      title={title}
      initialValues={initialValues}
      intialNodeValue={initialChannel}
      onChange={onChange}
      enableCopyNode
    >
      {({ index, value, dispatchValue }) => {
        return (
          <div className={css['channel-input-wrapper']}>
            <WizardInputField
              id={`description-${index}`}
              onChange={(val) => dispatchValue({ description: val })}
              value={value.description}
              placeholder={i18n.getTranslation('general.description')}
              className={css['input']}
              onFocus={onFocus}
              error={!value.description}
            />
            <WizardDataFreqDropdown
              id={`dataFrequency-${index}`}
              onChange={(val) => dispatchValue({ dataFrequency: val[0] })}
              placeholder={i18n.getTranslation('meter.frequency')}
              className={css['input']}
              onFocus={onFocus}
              values={enumReducer.getEnum('dataFrequency')}
              selectedValues={[value.dataFrequency]}
              error={!value.dataFrequency}
            />
            <WizardIncrTypeDropdown
              id={`incrementationType-${index}`}
              onChange={(val) => dispatchValue({ incrementationType: val[0] })}
              placeholder={i18n.getTranslation('meter.incrementation')}
              className={css['input']}
              onFocus={onFocus}
              values={enumReducer
                .getEnum<incrementationType>('incrementationType')
                .filter((type) => type.value !== incrementationType.delta)}
              selectedValues={[value.incrementationType]}
              error={!value.incrementationType}
            />
            <WizardMeteringTypeDropdown
              id={`meteringType-${index}`}
              onChange={(val) => dispatchValue({ meteringType: val[0] })}
              placeholder={i18n.getTranslation('meter.metering_method')}
              className={css['input']}
              onFocus={onFocus}
              values={enumReducer.getEnum('meteringType')}
              selectedValues={[value.meteringType]}
            />
            <WizardUtilityTypeDropdown
              id={`utilityType-${index}`}
              onChange={(val) => dispatchValue({ utilityType: val[0] })}
              placeholder={i18n.getTranslation('meter.utility_type')}
              className={css['input']}
              onFocus={onFocus}
              values={enumReducer.getEnum('utilityType')}
              selectedValues={[value.utilityType]}
            />
            <WizardDirectionDropdown
              id={`direction-${index}`}
              onChange={(val) => dispatchValue({ direction: val[0] })}
              placeholder={i18n.getTranslation('meter.direction')}
              className={css['input']}
              onFocus={onFocus}
              values={enumReducer.getEnum('direction')}
              selectedValues={[value.direction]}
              error={!value.direction}
            />
            <WizardUoMDropdown
              id={`unitOfMeasure-${index}`}
              onChange={(val) => dispatchValue({ unitOfMeasure: val[0] })}
              placeholder={i18n.getTranslation('meter.unit_of_measure')}
              className={css['input']}
              onFocus={onFocus}
              values={enumReducer.getEnum('unitOfMeasure', false)}
              selectedValues={[value.unitOfMeasure]}
            />
            <WizardInputField
              id={`timeOfUse-${index}`}
              onChange={(val) => dispatchValue({ timeOfUse: val })}
              value={value.timeOfUse}
              placeholder={i18n.getTranslation('meter.time_of_use')}
              className={css['input']}
              onFocus={onFocus}
            />
            <WizardInputField
              id={`relationExternalReference-${index}`}
              onChange={(val) => dispatchValue({ relationExternalReference: val })}
              value={value.relationExternalReference}
              placeholder={i18n.getTranslation('general.reference')}
            />
          </div>
        );
      }}
    </MultiValue>
  );
}
