import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import useCustomEntityPropertyTypes from '../../../app-context/hooks/useCustomEntityPropertyTypes';
import ListPage from '../../../components/listPage';
import { useStore } from '../../../hooks/useStore';
import LocationsFilterBar from './locations-filter-bar';
import LocationsList from './locations-list';

type Props = {
  location: Location;
};

export default function Locations(props: Props) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('location.serviced_locations'));

  const { customEntityPropertyTypes, extraColumns, extraColumnTitles } = useCustomEntityPropertyTypes(
    entitySubjectType.servicelocation
  );

  if (!customEntityPropertyTypes) return null;

  return (
    <ListPage
      entitySubjectType={entitySubjectType.servicelocation}
      subjectType={getTranslation('nav.locations')}
      topBar={<LocationsFilterBar search={props.location.search} extraColumnTitles={extraColumnTitles} />}
      list={<LocationsList customEntityPropertyTypes={customEntityPropertyTypes} extraColumns={extraColumns} />}
    />
  );
}
