import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { billingItemTariffCalculationType, tierCalculationMethod } from '@zf/api-types/enums';
import { tariffCalculationType } from '@zf/api-types/local-enums';
import { BillingItemType } from '@zf/api-types/product';
import { formatMoney } from '@zf/utils/src/number';

import SimpleDropdown from '../../../../../components/Lang/SimpleDropdown';
import { RadioInput } from '../../../../../design-system/Components';
import { SettingDescription } from '../../../../../design-system/ComponentSets';
import Description from '../../../../../design-system/ComponentSets/SettingDescription/Description';
import { useStore } from '../../../../../hooks/useStore';
import TariffCalculationWarning from '../../../billing-items/dialogs/add-edit-dialog/TariffCalculationWarning';
import css from './add-edit-tariff-dialog.module.scss';
import { TariffValidatorType } from './AddEditTariffDialog';
import { CalculationParameters } from './calculation-parameters/calculation-parameters';

type Props = {
  values: TariffValidatorType;
  backup: TariffValidatorType;
  selectedBillingItem: BillingItemType | undefined;
  setValue: (value: Partial<TariffValidatorType>) => void;
  setIsError: ((error: boolean) => void) | undefined;
};

const TariffCalculation = (props: Props) => {
  const { values, backup, selectedBillingItem, setValue, setIsError } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation } = applicationStore;

  const [radioBtnValues] = useState([
    {
      description: getTranslation('billing_tariff.radio_tiered'),
      title: getEnumTranslation('billingItemTariffCalculationType', billingItemTariffCalculationType.tiered),
      value: billingItemTariffCalculationType.tiered
    },
    {
      description: getTranslation('billing_tariff.radio_volume'),
      title: getEnumTranslation('billingItemTariffCalculationType', billingItemTariffCalculationType.volume),
      value: billingItemTariffCalculationType.volume
    },
    {
      description: getTranslation('billing_tariff.radio_stairstep'),
      title: getEnumTranslation('billingItemTariffCalculationType', billingItemTariffCalculationType.stairstep),
      value: billingItemTariffCalculationType.stairstep
    }
  ]);

  const renderCoachmarks = (calcType: billingItemTariffCalculationType) => {
    const tier2 = formatMoney(1, applicationStore.culture);
    const tier1 = formatMoney(2, applicationStore.culture);

    switch (calcType) {
      case billingItemTariffCalculationType.tiered: {
        const unitPrice = formatMoney(25, applicationStore.culture);
        return getTranslation('coachmark.tariffs.tiered', { tier1, tier2, unitPrice });
      }
      case billingItemTariffCalculationType.stairstep: {
        const unitPrice = formatMoney(1, applicationStore.culture);
        return getTranslation('coachmark.tariffs.stairstep', { tier1, tier2, unitPrice });
      }
      case billingItemTariffCalculationType.volume: {
        const unitPrice = formatMoney(15, applicationStore.culture);
        return getTranslation('coachmark.tariffs.volume', { tier1, tier2, unitPrice });
      }
      default:
        return undefined;
    }
  };

  return (
    <div
      className={values.tariffCalculationType !== tariffCalculationType.unitprice ? css['calculation-type'] : undefined}
    >
      {values.tariffCalculationType === tariffCalculationType.tieredprice && (
        <div className={css['calculation-type-input-container']}>
          <SettingDescription
            title={getTranslation('billing_items.tier_calc_method')}
            description={getTranslation('billing_items.tier_calc_method_descr')}
            setting={
              <SimpleDropdown
                id="tier-calculation-method"
                onChange={(val) => setValue({ tierCalculationMethod: val[0] })}
                values={getEnum<tierCalculationMethod>('tierCalculationMethod')}
                selectedValues={[values.tierCalculationMethod]}
              />
            }
          />

          {
            // Only render this when editing and tierCalculationMethod is touched
            selectedBillingItem && backup.tierCalculationMethod !== values.tierCalculationMethod && (
              <TariffCalculationWarning
                tierCalcMethod={values.tierCalculationMethod}
                selectedBillingItem={selectedBillingItem}
                setIsError={setIsError}
              />
            )
          }

          {radioBtnValues.map((radio) => {
            return (
              <div className={css['calculation-type-radio-button']} key={radio.title}>
                <RadioInput
                  name="calculation-type"
                  checked={values.calculationParameters.type === radio.value}
                  value={radio.value}
                  onChange={(val) =>
                    setValue({
                      calculationParameters: {
                        ...values.calculationParameters,
                        type: val as billingItemTariffCalculationType
                      }
                    })
                  }
                >
                  <Description
                    fullWidth
                    info={renderCoachmarks(radio.value)}
                    title={radio.title}
                    description={radio.description}
                  />
                </RadioInput>
              </div>
            );
          })}
        </div>
      )}

      <CalculationParameters values={values} setValue={setValue} />
    </div>
  );
};

export default observer(TariffCalculation);
