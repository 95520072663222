import React, { useMemo, useReducer } from 'react';

import { ExternalChannelType } from '@zf/api-types/master-data/meter';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useDialog from '@zf/hooks/src/useDialog';
import { Card, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import RecalculateDialog from '../../../../../actions/meters/recalculate-dialog';
import { useAppContext } from '../../../../../app-context';
import ConfirmationActionButton from '../../../../../components/Button/ConfirmationActionButton';
import FilterInputs from '../../../../../components/Filters/filter-inputs';
import { Icon } from 'design-system/Foundation';
import CheckBox from '../../../../../components/input/CheckBox';
import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { SuspenseCardBody } from '../../../../../components/suspense';
import { useStore } from '../../../../../hooks/useStore';
import { getActiveChannels } from '../../../../../utils/meter';
import css from './channel-card.module.scss';
import ChannelTags from './ChannelTags';
import { formatPeriod } from '@zf/utils/src/date';
import MeterIssueIndicator from 'design-system/Components/MeterIssueIndicator/MeterIssueIndicator';

type Props = {
  meterId: string;
  channels: ExternalChannelType[];
};

function NoChannels() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody icon={<Icon name="channels" />} title={i18n.getTranslation('meter.no_channels')}>
      {i18n.getTranslation('meter.no_channels_desc')}
    </CardEmptyBody>
  );
}

type State = {
  selectedId: string;
  onlyActive: boolean;
};

export default function ChannelCard(props: Props) {
  const { channels, meterId } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    selectedId: '',
    onlyActive: true
  });

  const channsToMap = useMemo(() => {
    return state.onlyActive ? getActiveChannels(channels) : channels;
  }, [state.onlyActive]);

  const rows = channsToMap.map((c) => {
    return {
      //Remember add correct condition: 'issues/error' must be present
      //response used currently: md/meters/id
      isUnhealthy: c.hasIssue ? (
        <MeterIssueIndicator
          usage="channel"
          id={meterId}
          tooltipId={c.externalIdentifier}
          tooltipExtra={c.issuesCount}
        />
      ) : null,
      __id: c.externalIdentifier,
      externalIdentifier: (
        <div data-tip={c.externalIdentifier.substr(c.externalIdentifier.indexOf(':') + 1)} data-for="channel-table-tip">
          {c.description}
        </div>
      ),
      dataFrequency: getEnumTranslation('dataFrequency', c.dataFrequency),
      meteringType: getEnumTranslation('meteringType', c.meteringType),
      utilityType: getEnumTranslation('utilityType', c.utilityType),
      direction: getEnumTranslation('direction', c.direction),
      incrementationType: getEnumTranslation('incrementationType', c.incrementationType),
      timeOfUse: c.timeOfUse,
      unitOfMeasure: getEnumTranslation('unitOfMeasure', c.unitOfMeasure),
      relationExternalReference: c.relationExternalReference,
      period: formatPeriod(c.startDateTime, c.endDateTime)
    };
  });

  const removeAllFilters = () => {
    setState({
      onlyActive: true
    });
  };

  return (
    <Card id="channels-card" width="3">
      <CardHeader
        extraRight={
          <ConfirmationActionButton
            id="mandate.add"
            dialogTitle={getTranslation('general.recalculate')}
            size="small"
            type="text"
            dialogContent={
              <RecalculateDialog ref={clickRef} extrenalChannId={state.selectedId} validationRef={validationRef} />
            }
            onSubmit={onSubmit}
            validationRef={validationRef}
            buttonPositive={getTranslation('general.recalculate')}
            disabled={!state.selectedId}
          />
        }
        filters={
          <FilterInputs
            filterInputs={[
              <CheckBox
                id="only-active"
                key="only-active"
                checked={state.onlyActive}
                onChange={(val) => setState({ onlyActive: val })}
              >
                {getTranslation('meter.only_active_channels')}
              </CheckBox>
            ]}
            removeAllFilters={removeAllFilters}
          />
        }
        tags={[
          <ChannelTags
            key="channel-tags"
            onlyActive={state.onlyActive}
            removeFilter={() => setState({ onlyActive: false })}
          />
        ]}
      >
        {getTranslation('meter.channels')}
      </CardHeader>
      <SuspenseCardBody>
        <div className={css['channel-table']}>
          <DynamicVirtualTable
            id="channel-table"
            tooltipId="channel-table-tip"
            rows={rows}
            singleSelect
            totalAmountOfRows={channels.length}
            selectedRows={state.selectedId ? [state.selectedId] : []}
            onSelectRow={(ids) => setState({ selectedId: ids[0] })}
            NoDataOverlay={NoChannels}
            columns={[
              {
                width: 40,
                label: '',
                dataKey: 'isUnhealthy'
              },
              {
                width: 200,
                label: getTranslation('general.description'),
                dataKey: 'externalIdentifier'
              },
              {
                width: 170,
                label: getTranslation('location.dataFrequency'),
                dataKey: 'dataFrequency'
              },
              {
                width: 140,
                label: getTranslation('location.meteringType'),
                dataKey: 'meteringType'
              },
              {
                width: 150,
                label: getTranslation('location.utilityType'),
                dataKey: 'utilityType'
              },
              {
                width: 170,
                label: getTranslation('location.direction'),
                dataKey: 'direction'
              },
              {
                width: 170,
                label: getTranslation('meter.incrementation'),
                dataKey: 'incrementationType'
              },
              {
                width: 100,
                label: getTranslation('meter.time_of_use'),
                dataKey: 'timeOfUse'
              },
              {
                width: 150,
                label: getTranslation('location.unitOfMeasure'),
                dataKey: 'unitOfMeasure'
              },
              {
                width: 150,
                label: getTranslation('general.reference'),
                dataKey: 'relationExternalReference'
              },
              {
                width: 150,
                label: getTranslation('general.period'),
                dataKey: 'period'
              }
            ]}
          />
        </div>
      </SuspenseCardBody>
    </Card>
  );
}
