import React from 'react';

import {
  CollectionFlowStepType,
  TieredPercentageCollectionChargeParametersType
} from '@zf/api-types/collection-cycles';
import { collectionChargeType } from '@zf/api-types/enums';
import { GetTaxCodeResponse } from '@zf/api-types/tax-codes';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../app-context/app-context';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import { formatTaxCode } from '../../../utils/taxCode';
import css from './collection-step-tooltip.module.scss';

import { colors } from '@zf/utils/src/color';

type Props = {
  step: CollectionFlowStepType;
};

type ChargeParameterPropertyType =
  | 'type'
  | 'taxCodeId'
  | 'amountExclVAT'
  | 'from'
  | 'minAmount'
  | 'maxAmount'
  | 'percentage';

export default function CollectionStepTooltip(props: Props) {
  const { step } = props;
  const { i18n } = useAppContext();

  const taxCodesResponse = useSuspenseSingleAPI<GetTaxCodeResponse>({
    request: {
      endpoint: '/cfg/taxcodes'
    }
  });

  if (!taxCodesResponse.result) return <Spinner size="small" />;

  const taxCodes = taxCodesResponse.result.data.results;

  const parameters = step.chargeParameters;

  const formatValue = (key: ChargeParameterPropertyType, value: any) => {
    switch (key) {
      case 'percentage':
        return `${value}%`;
      case 'from':
      case 'amountExclVAT':
      case 'minAmount':
      case 'maxAmount':
        return formatMoney(value, i18n.culture);
      case 'taxCodeId': {
        const taxCode = taxCodes.find((tc) => {
          return tc.id === value;
        });

        return taxCode ? formatTaxCode(taxCode) : i18n.getTranslation('general.none');
      }
    }
  };

  const renderTiers = () => {
    if (step.chargeType === collectionChargeType.tieredPercentageOfOpenAmount) {
      const params = step.chargeParameters as TieredPercentageCollectionChargeParametersType;
      return params.tiers.map((tier, index) => {
        return (
          <div className={css['tier']} key={`${JSON.stringify(tier)}-${index}`}>
            <Heading headingType="h5" style="bold" color={colors['silver-700']}>{`${i18n.getTranslation(
              'collection_flows.tier'
            )} #${index + 1}`}</Heading>
            <div className={css['info-row']}>
              <Label>{i18n.getTranslation('collection_flows.from')}</Label>
              <Paragraph>{formatValue('from', tier.from)}</Paragraph>
            </div>
            <div className={css['info-row']}>
              <Label>{i18n.getTranslation('collection_flows.minAmount')}</Label>
              <Paragraph>{formatValue('minAmount', tier.minAmount)}</Paragraph>
            </div>
            <div className={css['info-row']}>
              <Label>{i18n.getTranslation('collection_flows.maxAmount')}</Label>
              <Paragraph>{formatValue('maxAmount', tier.maxAmount)}</Paragraph>
            </div>
            <div className={css['info-row']}>
              <Label>{i18n.getTranslation('collection_flows.percentage')}</Label>
              <Paragraph>{formatValue('percentage', tier.percentage)}</Paragraph>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className={css['wrapper']}>
      <Heading headingType="h3" style="bold">
        {`${i18n.getTranslation('collection_flows.charge_parameters')}:`}
      </Heading>
      <br />
      {parameters &&
        Object.keys(parameters).map((key, index) => {
          // @ts-ignore
          const value = parameters[key];

          return key !== 'type' && key !== 'tiers' ? (
            <div className={css['info-row']} key={`${step.name}-${key}-${index}`}>
              <Label>{i18n.getTranslation(`collection_flows.${key}`)}</Label>
              <Paragraph>{formatValue(key as ChargeParameterPropertyType, value)}</Paragraph>
            </div>
          ) : null;
        })}

      {renderTiers()}
    </div>
  );
}
