import clone from 'clone';
import moment from 'moment';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { BillingAnalyticsType, InsightsPerMonthType } from '@zf/api-types/analytics/billing';
import { invoiceType } from '@zf/api-types/enums';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import css from '@zf/stella-react/src/atoms/Chart/chart-style.module.scss';
import CustomLegend, { LegendValuesType } from '@zf/stella-react/src/atoms/Chart/CustomLegend';
import CustomTooltip from '@zf/stella-react/src/atoms/Chart/CustomTooltip';
import CustomXAxisTick from '@zf/stella-react/src/atoms/Chart/CustomXAxisTick';
import CustomYAxisTick from '@zf/stella-react/src/atoms/Chart/CustomYAxisTick';

import { useAppContext } from '../../app-context';
import useCurrent from '../../app-context/hooks/use-current';
import { colors } from '@zf/utils/src/color';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

type Props = {
  billingInsights: BillingAnalyticsType;
  propertyGroupId?: string;
  setActiveTab?: (tabId: string) => void;
};

export default function InvoicesChartCard(props: Props) {
  const { propertyGroupId, billingInsights, setActiveTab } = props;
  const { i18n } = useAppContext();
  const { myCurrent: myCurrentCompleteness, setMyCurrent: setMyCurrentCompleteness } = useCurrent('completeness');

  billingInsights.billingDataInsights.insightsPerMonth.sort(
    (a, b) => moment(a.endOfMonth).valueOf() - moment(b.endOfMonth).valueOf()
  );

  const insightsPerMonthSorted = billingInsights.billingDataInsights.insightsPerMonth;

  const chartData = insightsPerMonthSorted.map((ipm, index) => {
    return {
      name:
        index !== 0 || insightsPerMonthSorted.length < 7
          ? moment(ipm.endOfMonth).format('MMM')
          : i18n.getTranslation('dashboard.charts.moreThansixMonths'),
      missingInvoices: ipm.missingInvoices,
      missingAdvances: ipm.missingAdvances,
      startOfMonth:
        index !== 0 || insightsPerMonthSorted.length < 7
          ? moment(ipm.startOfMonth)
          : i18n.getTranslation('dashboard.charts.moreThansixMonths'),
      endOfMonth:
        index !== 0 || insightsPerMonthSorted.length < 7
          ? moment(ipm.endOfMonth)
          : i18n.getTranslation('dashboard.charts.moreThansixMonths')
    };
  });

  const legendValues: LegendValuesType = insightsPerMonthSorted.reduce(
    (acc, ipm) => {
      acc.missingAdvances += ipm.missingAdvances;
      acc.missingInvoices += ipm.missingInvoices;

      return acc;
    },
    {
      missingInvoices: 0,
      missingAdvances: 0
    }
  );

  const setCompletenessStatus = (type: invoiceType, data: InsightsPerMonthType, index: number) => {
    setTimeout(() => {
      let currentClone = clone(myCurrentCompleteness);
      if (index !== 0 || insightsPerMonthSorted.length < 7) {
        currentClone.filter.queryParams = {
          periodEndDateTime: type === invoiceType.advance ? moment(data.endOfMonth).toISOString() : MAX_DATE,
          periodStartDateTime: type === invoiceType.advance ? moment(data.startOfMonth).toISOString() : MIN_DATE,
          invoicingCheckpointStartDateTime:
            type !== invoiceType.advance ? moment(data.startOfMonth).toISOString() : MIN_DATE,
          invoicingCheckpointEndDateTime:
            type !== invoiceType.advance ? moment(data.endOfMonth).toISOString() : MIN_DATE,
          propertyGroupId: propertyGroupId,
          onlyOpen: true,
          invoiceType: null,
          quickfilter: type === invoiceType.advance ? 'missingadvances' : null
        };
        setMyCurrentCompleteness(currentClone);
      } else {
        currentClone.filter.queryParams = {
          periodEndDateTime:
            type === invoiceType.advance ? moment().subtract(6, 'M').startOf('M').toISOString() : MAX_DATE,
          periodStartDateTime: MIN_DATE,
          invoicingCheckpointStartDateTime: MIN_DATE,
          invoicingCheckpointEndDateTime:
            type !== invoiceType.advance ? moment(insightsPerMonthSorted[1].startOfMonth).toISOString() : MIN_DATE,
          propertyGroupId: propertyGroupId,
          onlyOpen: true,
          invoiceType: null,
          quickfilter: type === invoiceType.advance ? 'missingadvances' : null
        };
        setMyCurrentCompleteness(currentClone);
      }
    }, 500);
  };

  return (
    <BarChartCard subject="billing" title={i18n.getTranslation('dashboard.general_tab.missing')}>
      <div className={css['chart-container-wrapper']}>
        <ResponsiveContainer className={css['chart']}>
          <BarChart data={chartData} margin={{ top: 60, bottom: 0 }}>
            <CartesianGrid strokeOpacity={0.5} strokeDasharray="1 2" />
            <XAxis axisLine={false} tickLine={false} height={5} dataKey="name" tick={<CustomXAxisTick />} />
            <YAxis tick={<CustomYAxisTick locale={i18n.culture} />} axisLine={false} tickLine={false} />
            <ReferenceLine y={0} stroke={colors['silver-700']} strokeWidth={0.5} />
            <Tooltip content={<CustomTooltip locale={i18n.culture} />} cursor={{ fill: colors['blue-50'] }} />
            <Bar
              name={i18n.getTranslation('dashboard.general_tab.created_invoices')}
              dataKey="missingInvoices"
              stackId="a"
              fill={colors['blue-600']}
              radius={[3, 3, 0, 0]}
              onClick={(data, index) => {
                setCompletenessStatus(invoiceType.invoice, data, index);
                if (setActiveTab) setActiveTab('billing_overview');
              }}
              cursor="pointer"
            />
            <Bar
              name={i18n.getTranslation('dashboard.general_tab.created_advances')}
              dataKey="missingAdvances"
              stackId="b"
              fill={colors['yellow-600']}
              radius={[3, 3, 0, 0]}
              onClick={(data, index) => {
                setCompletenessStatus(invoiceType.advance, data, index);
                if (setActiveTab) setActiveTab('billing_overview');
              }}
              cursor="pointer"
            />
            <Legend
              content={<CustomLegend legendValues={legendValues} locale={i18n.culture} />}
              wrapperStyle={{ top: 0, left: 40 }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </BarChartCard>
  );
}
