import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction } from 'react';

import { managementRelationType } from '@zf/api-types/enums';
import { ManagementRelation, UpdateManagementRelation } from '@zf/api-types/master-data/property-group';
import { ProductReferenceType, ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { SuspenseCardBody } from '../../../../components/suspense';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import LocationDetailCardBody from './location-detail-card-body';

export type ValidatorValueType = {
  owner: string;
  manager: string;
  externalId: string;
  product: ProductReferenceType | null;
};

type Props = {
  location: ServiceLocationType;
  setLocation: Dispatch<SetStateAction<ServiceLocationType | null>>;
};

const LocationDetailCard = (props: Props) => {
  const { location, setLocation } = props;

  const { applicationStore, serviceLocationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { updateExternalId, updateManagementDetails, updateProduct } = serviceLocationStore.serviceLocationService;

  const owner: ManagementRelation | undefined = location.managementRelations.find(
    (mr) => mr.managementRelationType === 'owner'
  );
  const manager: ManagementRelation | undefined = location.managementRelations.find(
    (mr) => mr.managementRelationType === 'propertymanager'
  );

  const { values, isDirty, setValue, submitFactory, restoreValues, backupValues } = useValidator<ValidatorValueType>({
    initialValues: {
      owner: owner?.customerId || '',
      manager: manager?.customerId || '',
      externalId: location.externalId,
      product: location.product
    },
    refreshTrigger: location.id
  });

  const handleSave = submitFactory(async () => {
    try {
      let response: ServiceLocationType | null = null;

      if (values.externalId !== location.externalId) {
        response = await updateExternalId(location, values.externalId);
      }

      if (
        (!owner && values.owner) ||
        (owner && owner.customerId !== values.owner) ||
        (!manager && values.manager) ||
        (manager && manager.customerId !== values.manager)
      ) {
        const managementRelations: UpdateManagementRelation[] = [];
        if (values.owner) {
          managementRelations.push({
            customerId: values.owner,
            managementRelationType: managementRelationType.owner
          });
        }
        if (values.manager) {
          managementRelations.push({
            customerId: values.manager,
            managementRelationType: managementRelationType.propertymanager
          });
        }

        response = await updateManagementDetails(location, managementRelations);
      }

      if (
        (location.product && values.product && location.product.productId !== values.product.productId) ||
        (!location.product && values.product)
      ) {
        response = await updateProduct(location, values.product.productId);
      }

      notify.success({
        content: getTranslation('actions.location.update')
      });

      backupValues();
      if (response) setLocation(response);
    } catch (e) {
      notify.error({
        content: getTranslation('actions.location.update_failed'),
        error: e
      });
    }
  });

  const handleCancel = () => restoreValues();

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      handleCancel();
    } else if (event.key === 'Enter') {
      handleSave();
      if (document.activeElement) {
        const elem = document.activeElement as HTMLElement;
        elem.blur();
      }
    }
  };

  return (
    <Card id="location-details-card">
      <CardHeader
        primaryText={isDirty ? getTranslation('general.save') : undefined}
        onPrimaryClick={handleSave}
        secondaryText={isDirty ? getTranslation('general.cancel') : undefined}
        onSecondaryClick={restoreValues}
      >
        {getTranslation('general.details')}
      </CardHeader>
      <SuspenseCardBody type="grid">
        <LocationDetailCardBody {...props} values={values} setValue={setValue} keyHandler={keyHandler} />
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(LocationDetailCard);
