import classNames from 'classnames';
import React from 'react';

import Center from '@zf/stella-react/src/helpers/Center';

import css from './crumb-header.module.scss';

type Props = {
  breadcrumb: React.ReactNode;
};

export default function CrumbHeader(props: Props) {
  const { breadcrumb } = props;

  return (
    <div className={classNames(css['crumb-header'])}>
      <div className={css['crumb-wrapper']}>
        <Center type="vertical">{breadcrumb}</Center>
      </div>
    </div>
  );
}
