import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import { ConfigHelp } from '../../../../components/CoachMarks';
import ConfigColumn from '../../../../components/Column/ConfigColumn';
import { dialog } from '../../../../events/dialog-events';
import { useStore } from '../../../../hooks/useStore';
import css from '../products-tab.module.scss';
import AddEditProductDialog from './dialogs/AddEditProductDialog';
import ProductFilters from './ProductFilters';
import ProductsColumnContent from './ProductsColumnContent';

type Props = {
  productName?: string;
  billingItemId?: string;
};

const ProductsColumn = (props: Props) => {
  const { productConfigStore, applicationStore } = useStore();
  const { billingItemId, productName } = props;
  const { getTranslation } = applicationStore;
  const { onlyShowTariffs, productsStore } = productConfigStore;
  const { products_, cultureTable_, initProducts } = productsStore;

  useEffect(() => {
    initProducts(productName, billingItemId);
  }, [billingItemId]);

  const { clickRef, validationRef, onSubmit } = useDialog();

  const openAddDialog = () => {
    dialog.right({
      title: getTranslation('product_config.add_product'),
      icon: 'plus',
      content: <AddEditProductDialog validationRef={validationRef} ref={clickRef} />,
      buttonPositive: getTranslation('general.add'),
      stackOrder: 2,
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      className={onlyShowTariffs ? css['hidden'] : undefined}
      title={getTranslation('product_config.products')}
      add={openAddDialog}
      extraRight={products_ && <ProductFilters productName={props.productName} />}
      coachMark={
        <ConfigHelp
          title={getTranslation('coachmark.products.title')}
          content={[getTranslation('coachmark.products.paragraph')]}
        />
      }
    >
      <AsyncVarSpinner variables={[products_, cultureTable_]}>
        <ProductsColumnContent billingItemId={billingItemId} />
      </AsyncVarSpinner>
    </ConfigColumn>
  );
};

export default observer(ProductsColumn);
