import React from 'react';

import { useAppContext } from '../../app-context/app-context';
import { ButtonProps } from '../../design-system/Components/Button/Button';
import Button from '../Button/Button';

export default function EditIcon(props: ButtonProps) {
  const { i18n } = useAppContext();

  return <Button {...props} icon="sign" type="text" size="small" tooltipText={i18n.getTranslation('general.edit')} />;
}
