import React from 'react';

import { ExportJobType } from '../../../../../api-types/export-job';
import { useAppContext } from '../../../app-context';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  exportJob: ExportJobType;
};

export default function DownloadExportJobListItem(props: Props) {
  const { exportJob } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { downloadSingleFile } = useDownload();

  return (
    <DropdownAction
      id="export_jobs.download"
      icon="download-cloud"
      actionType="direct"
      onClick={() => downloadSingleFile(`/logs/ExportJob/${exportJob.id}`, tenantReducer)}
    >
      {i18n.getTranslation('actions.export_jobs.download')}
    </DropdownAction>
  );
}
