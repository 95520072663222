import React from 'react';

import { useAppContext } from '../../../../../app-context';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../../../components/BreadCrumb';
import { CrumbHeader } from '../../../../../components/Header';
import { isEmptyObject } from '@zf/utils/src/object';
import useOutgoingBankingTransactionDetail from '../hooks/useOutgoingBankingTransactionDetail';
import BatchMutationTabContainer from './OutgoingBankingTransactionTabContainer';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

export default function OutgoingBankingTransaction(props: Props) {
  const { navigate, urlParams } = props;
  const [query] = useQueryParams({
    fromPayments: BooleanParam
  });
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { outgoingBankingTransaction, status, navigator, setOutgoingBankingTransaction, refresh } =
    useOutgoingBankingTransactionDetail(urlParams.id);
  const { rootUrl } = tenantReducer.state;

  if (!outgoingBankingTransaction || isEmptyObject(outgoingBankingTransaction)) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              icon="list"
              text={i18n.getTranslation('outgoing_banking_transactions.outgoing_banking_transaction')}
              url={`${rootUrl}/payments/outgoing-banking-transactions`}
            />
            <CrumbDivider />
            <Crumb
              icon="batch"
              text={
                Object.keys(outgoingBankingTransaction).length > 0
                  ? `${enumReducer.getTranslation(
                      'outgoingBankingTransactionType',
                      outgoingBankingTransaction.type
                    )}: ${outgoingBankingTransaction.identification}`
                  : ''
              }
            />
          </BreadCrumb>
        }
      />
      <BatchMutationTabContainer
        outgoingBankingTransaction={outgoingBankingTransaction}
        domain={query.fromPayments ? routedEntitySubjectType.payment : undefined}
        status={status}
        navigator={navigator}
        setOutgoingBankingTransaction={setOutgoingBankingTransaction}
        navigate={navigate}
        refresh={refresh}
      />
    </>
  );
}
