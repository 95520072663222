import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import React from 'react';

type Props = {
  label: string;
};

export default function EmptyRow(props: Props) {
  const { label } = props;

  return (
    <>
      <CardItem width="6">
        <Label>{label}</Label>
      </CardItem>
      <CardItem width="6" />
    </>
  );
}
