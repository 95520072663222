import React from 'react';

import { UserRolesType } from '@zf/api-types/auth';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { UserAvatar } from '../Avatar';
import { deleteAdmin, updateOrganisationUsers } from './apiFunctions';
import css from './delete-user-dialog.module.scss';

type Props = {
  type: 'admin' | 'user';
  userRole: UserRolesType;
  userRoles: UserRolesType[];
  adminIds: string[];
  onComplete: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { type, userRole, userRoles, adminIds, onComplete } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { tenant } = tenantReducer.state;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (type === 'admin') {
          await deleteAdmin(adminIds, userRole, tenant, i18n.lang);
        } else {
          // API call to update the organisation users
          await Promise.all(
            userRole.organizationRoles.map((or) =>
              updateOrganisationUsers(
                userRoles,
                or.organizationId,
                or.roleId,
                userRole.userDetails.id,
                'delete',
                tenant,
                i18n.lang
              )
            )
          );
        }

        onComplete();

        notify.success({
          content: i18n.getTranslation(`user.remove_${type}_success`)
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`user.remove_${type}_fail`),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>{i18n.getTranslation(`user.remove_${type}_paragraph`)}</Paragraph>
      <br />
      <UserAvatar>{userRole.userDetails.userName || userRole.userDetails.email}</UserAvatar>
      <br />
      <Center>
        <div className={css['user-name']}>{userRole.userDetails.userName}</div>
      </Center>
    </>
  );
});
