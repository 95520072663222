import React from 'react';
import Customers from 'features/customer/list-page/Customers';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import CustomerGroupCard from './CustomerGroupCard';
import { CustomerGroupDetail } from '@zf/api-types/master-data/customer';
import { Settings } from './Settings';
import css from './cards-container.module.scss';
type Props = {
  location: Location;
  customerGroupDetail: CustomerGroupDetail;
  setCustomerGroupName: Function;
  refresh: Function;
  timeStamp?: string;
};

export default function CustomersInGroupList(props: Props) {
  const { location, customerGroupDetail, setCustomerGroupName, refresh, timeStamp } = props;
  return (
    <CardsContainer className={css['cards-container']}>
      <Customers
        location={location}
        withListPage={false}
        queryParam={{ customerGroupId: customerGroupDetail.id, timeStamp: timeStamp ? timeStamp : '' }}
        onComplete={refresh}
      />
      <Settings
        settings={customerGroupDetail.settings}
        customerGroupId={customerGroupDetail.id}
        hasCustomers={customerGroupDetail.amountOfCustomers > 0}
      />
      <CustomerGroupCard
        customerGroupId={customerGroupDetail.id}
        customerGroupName={customerGroupDetail.name}
        setCustomerGroupName={setCustomerGroupName}
      />
    </CardsContainer>
  );
}
