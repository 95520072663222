import { observer } from 'mobx-react';
import React from 'react';

import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';

import { Link } from '../../../design-system/Components';
import { SettingDescription } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

const InvoicingCardBody = () => {
  const { integrationStore, applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { portalSettingsForm } = integrationStore.customerPortalStore;

  if (!portalSettingsForm) return null;

  return (
    <>
      <SettingDescription
        title={getTranslation('portal_config.visualize_invoices')}
        description={getTranslation('portal_config.visualize_invoices_desc')}
        setting={
          <ZFToggle
            onChange={(val) => portalSettingsForm._set('hideInvoiceSection', val)}
            checked={portalSettingsForm._get('hideInvoiceSection')}
          />
        }
      />
      <SettingDescription
        title={getTranslation('portal_config.vis_invoice_payment_status')}
        description={getTranslation('portal_config.vis_invoice_payment_status_desc')}
        setting={
          <Link id="payment-status" bold url={`${rootUrl}/configuration/billing`}>
            {getTranslation('portal_config.to_invoicing')}
          </Link>
        }
      />
    </>
  );
};

export default observer(InvoicingCardBody);
