import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Card } from '@zf/stella-react/src/atoms/Card';
import OverflowParagraph from '@zf/stella-react/src/atoms/Paragraph/OverflowParagraph';

import { useStore } from '../../../hooks/useStore';
import ConsumptionGroupCardBody from './containers/consumption-groups-card-body';
import ConsumptionGroupsCardHeader from './containers/consumption-groups-card-header';

const BuiltInCard = () => {
  const { meterConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { sortableFields, isLoading, entities, initialentities, sort, loadEntities } =
    meterConfigStore.builtInApiService;

  useEffect(() => {
    if (!initialentities) {
      loadEntities();
    }
  }, []);

  const readOnlyRows = entities?.map((entity, index) => {
    return {
      code: entity.code,
      description: (
        <OverflowParagraph id={`builtin-description-tip-${index}`} usedInTable tooltipFor="consumption-group-table">
          {entity.description}
        </OverflowParagraph>
      ),
      utilityType: entity.utilityType,
      unitOfMeasure: entity.unitOfMeasure,
      amount: entity.estimatedAnnualVolume?.toString()
    };
  });

  return (
    <Card width="3" id="built-in-groups-card">
      <ConsumptionGroupsCardHeader title={getTranslation('built_in_groups')} />
      <ConsumptionGroupCardBody
        emptyState={getTranslation('consumption_groups.builtin_emptystate')}
        isLoading={isLoading}
        sort={sort}
        sortableFields={sortableFields}
        rows={readOnlyRows}
      />
    </Card>
  );
};

export default observer(BuiltInCard);
