import { observer } from 'mobx-react';
import React from 'react';

import { advanceFrequency } from '@zf/api-types/enums';
import { Card, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { SuspenseCardBody } from '../../../../../../components/suspense';
import { useStore } from '../../../../../../hooks/useStore';
import AdvanceInfo from './AdvanceInfo';

const BillingDetailsNoRelation = () => {
  const { contractStore, applicationStore } = useStore();

  const { getTranslation, getEnumTranslation } = applicationStore;
  const { selectedContract } = contractStore;
  const contract = selectedContract.contract;

  return (
    <Card id="billing-details-card" width="1">
      <CardHeader>{getTranslation('contracts.billing_details')}</CardHeader>
      <SuspenseCardBody type="grid">
        <CardItem width="6">
          <Label>{getTranslation('contracts.advance_freq')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right">
            {getEnumTranslation('advanceFrequency', contract.billingDetails.advanceFrequency)}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.invoice_freq')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right">
            {contract.billingDetails.invoiceFrequency
              ? getEnumTranslation('invoiceFrequency', contract.billingDetails.invoiceFrequency)
              : getTranslation('general.none')}
          </Paragraph>
        </CardItem>

        {contract.billingDetails.advanceFrequency !== advanceFrequency.none && <AdvanceInfo />}
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(BillingDetailsNoRelation);
