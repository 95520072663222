import React from 'react';

import {
  OutgoingBankingTransactionRowType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteOutgoingBankingTransaction } from './outgoing-banking-transactions';

type Props = {
  // List page
  selectedRows?: OutgoingBankingTransactionRowType[];
  setDeletedRows?: (
    updatedRows: OutgoingBankingTransactionType[],
    deletedRows: Partial<OutgoingBankingTransactionType>[]
  ) => void;
  refreshCounts?: () => void;

  // Detail page
  bankingTransaction?: OutgoingBankingTransactionType;
  onComplete?: () => void;
};

const DeleteOutgoingBankingTransactionsDialog = React.forwardRef(
  (props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
    const { selectedRows, bankingTransaction, setDeletedRows, onComplete, refreshCounts } = props;
    const { i18n, tenantReducer } = useAppContext();

    const isMulti = selectedRows && selectedRows.length > 1;

    React.useImperativeHandle(ref, () => ({
      async onClick() {
        try {
          if (bankingTransaction && onComplete) {
            await deleteOutgoingBankingTransaction(bankingTransaction.id, tenantReducer, i18n.lang);

            onComplete();
          } else if (selectedRows && setDeletedRows && refreshCounts) {
            const deletedBankingTransactions: OutgoingBankingTransactionType[] = [];
            await Promise.all(
              selectedRows.map((r) => {
                deletedBankingTransactions.push(r.__entity);
                return deleteOutgoingBankingTransaction(r.__entity.id, tenantReducer, i18n.lang);
              })
            );

            setDeletedRows([], deletedBankingTransactions);
            refreshCounts();
          }

          notify.success({
            content: i18n.getTranslation(`actions.banking_transaction.delete_success${isMulti ? '_multi' : ''}`)
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation(`actions.banking_transaction.delete_fail${isMulti ? '_multi' : ''}`),
            error: e
          });
        }
      }
    }));

    return (
      <Paragraph>
        {i18n.getTranslation(`actions.banking_transaction.delete_paragraph${isMulti ? '_multi' : ''}`, {
          amount: selectedRows ? selectedRows.length : 0
        })}
      </Paragraph>
    );
  }
);

export default DeleteOutgoingBankingTransactionsDialog;
