import { observer } from 'mobx-react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import CheckBox from '../../../../../components/input/CheckBox';
import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../hooks/useStore';
import css from './components/translations-inputfield.module.scss';

type Props = {
  rowIndex: number;
  isCrossTemplate: boolean;
};

const ResetDialog = forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { rowIndex, isCrossTemplate } = props;
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { resetSelectedTranslation } = communicationStore.translationStore;

  const [checked, setChecked] = useState(false);

  useImperativeHandle(ref, () => ({
    async onClick() {
      await resetSelectedTranslation(rowIndex, checked);
    }
  }));

  return (
    <>
      <Paragraph>{getTranslation('communication.translation_reset_confirmation')}</Paragraph>
      {isCrossTemplate && (
        <CheckBox className={css['reset-translation']} id="reset-translation" checked={checked} onChange={setChecked}>
          <Label color={colors['silver-700']}>
            {getTranslation('communication.translation_reset_confirmation_checkbox')}
          </Label>
        </CheckBox>
      )}
    </>
  );
});

export default observer(ResetDialog);
