import { Paragraph } from 'design-system/Foundation';
import { observer } from 'mobx-react';
import React from 'react';

import { RequiredQuantityLocationGrouped } from '@zf/api-types/billing/billing-completeness';
import AlertCircle from '@zf/stella-react/src/atoms/Info/AlertCircle';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { formatPeriod } from '@zf/utils/src/date';

import { useStore } from '../../../../../../hooks/useStore';
import ErrorsTip from './ErrorsTip';
import css from './lrq-grouped-extra-left.module.scss';

type Props = {
  lrq: RequiredQuantityLocationGrouped;
};

const LocationRQGroupedExtraLeft = (props: Props) => {
  const { lrq } = props;

  const { contractStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { selectedCompleteness, getAddressForLocationRequiredQuantity, getPeriodExtremaForRequiredQuantityGrouped } =
    contractStore.billingCompletenessStore;

  const errors = lrq.requiredQuantities?.flatMap((rq) => rq.errors) || [];

  const extrema = getPeriodExtremaForRequiredQuantityGrouped(lrq);
  const { min, max } = extrema;

  return (
    <FlexElements justifyContent="space-between" alignItems="center">
      <Paragraph bold>{getAddressForLocationRequiredQuantity(lrq.serviceLocationId) || ''}</Paragraph>
      <div>
        {min.isAfter(selectedCompleteness.periodStartDateTime) && (
          <Paragraph className={css['deviating-period']}>
            {getTranslation('completeness.billed_only_for', {
              period: formatPeriod(min, selectedCompleteness.periodEndDateTime)
            })}
          </Paragraph>
        )}
        {max.isBefore(selectedCompleteness.periodEndDateTime) && (
          <Paragraph className={css['deviating-period']}>
            {getTranslation('completeness.billed_only_for', {
              period: formatPeriod(selectedCompleteness.periodStartDateTime, max)
            })}
          </Paragraph>
        )}
        {errors.length > 0 ? (
          <AlertCircle>
            <ErrorsTip errors={errors} />
          </AlertCircle>
        ) : undefined}
      </div>
    </FlexElements>
  );
};

export default observer(LocationRQGroupedExtraLeft);
