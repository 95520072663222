import moment, { Moment } from 'moment';
import React from 'react';

import { AttachmentType } from '@zf/api-types/attachment';
import { entitySubjectType } from '@zf/api-types/enums';
import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { IncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { AddMeasurementRequestType } from '@zf/api-types/master-data/meter';
import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { createFormData } from '@zf/auth/src/utils';
import useSimpleValidator from '@zf/hooks/src/useSimpleValidator';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { formatPeriod, isMaxDate, isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import DateRangePicker from '../../components/input/DateRangePicker';
import InputField from '../../components/input/InputField';
import MoneyInput from '../../components/input/MoneyInput';
import { MappedFilesType } from '../../components/input/UploadInput';
import DatePicker, { DatePickerProps } from '../../components/Lang/DatePicker';
import Dropdown from '../../components/Lang/Dropdown';
import { notify } from '../../events/notification-events';
import { submitAttachment } from '../attachments/attachments';
import css from './add-incoming-invoice-dialog.module.scss';
import {
  addIncomingInvoiceComponentsToBillingPeriod,
  IncomingInvoiceRequestType,
  submitIncomingInvoice
} from './incoming-invoice';
import IncomingInvoiceAttachment from './incoming-invoice-attachment';
import IncomingInvoiceComponentMultiValue, { NodeValueType } from './incoming-invoice-component-multivalue';

type Props = {
  propertyGroupId: string;
  billingPeriods: PropertyGroupBillingPeriodType[];
  components: IncomingInvoiceComponentType[];
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  incomingInvoice?: IncomingInvoiceType;
  selectedBillingPeriod?: PropertyGroupBillingPeriodType;
  matchedPeriod?: PropertyGroupBillingPeriodType | null;
  attachment?: AttachmentType;
  updateBillingPeriodsInState: (billingPeriods: PropertyGroupBillingPeriodType[]) => void;
  addIncomingInvoiceToState?: (incomingInvoice: IncomingInvoiceType) => void;
  setSelectedBillingPeriod?: (period: PropertyGroupBillingPeriodType) => void;
  updateIncomingInvoice?: (invoice: IncomingInvoiceType) => void;
};

type ValidatorType = {
  invoiceNumber: string;
  invoiceDate: Moment;
  description: string;
  startDate: Moment | null;
  endDate: Moment | null;
  totalAmountExclVat: number;
  totalAmountInclVat: number;
  attachmentId: string;
  file: MappedFilesType | undefined;
  measurements: AddMeasurementRequestType[];
  billingPeriod: PropertyGroupBillingPeriodType | null;
  components: NodeValueType[];
};

const InlineDatePicker = InlineInputField<DatePickerProps>(DatePicker);

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const {
    validationRef,
    billingPeriods,
    propertyGroupId,
    components,
    incomingInvoice,
    selectedBillingPeriod,
    matchedPeriod,
    attachment,
    addIncomingInvoiceToState,
    updateBillingPeriodsInState,
    setSelectedBillingPeriod,
    updateIncomingInvoice
  } = props;
  const { i18n, tenantReducer } = useAppContext();

  let initialComponents: NodeValueType[] | undefined;

  if (matchedPeriod && incomingInvoice) {
    // Edit
    initialComponents = matchedPeriod.incomingInvoiceComponents.map((iicDetails) => {
      const matchingComponent = components.find((c) => {
        return c.id === iicDetails.incomingInvoiceComponentId;
      });

      const matchingValue = iicDetails.values.find((v_) => {
        return v_.incomingInvoiceId === incomingInvoice.id;
      });

      return {
        component: matchingComponent ? matchingComponent : null,
        value: matchingValue ? matchingValue.value : 0
      };
    });
  }

  let initialFile: any;

  if (attachment) {
    initialFile = {
      internalFilePath: attachment.internalFilePath,
      fileName: attachment.fileName,
      state: 'none'
    };
  }

  const initialValues = incomingInvoice
    ? {
        invoiceNumber: incomingInvoice.invoiceNumber,
        invoiceDate: moment(incomingInvoice.invoiceDate),
        description: incomingInvoice.description,
        startDate: moment(incomingInvoice.startDate),
        endDate: moment(incomingInvoice.endDate),
        totalAmountExclVat: incomingInvoice.totalAmountExclVat,
        totalAmountInclVat: incomingInvoice.totalAmountInclVat,
        attachmentId: incomingInvoice.attachmentId,
        file: initialFile,
        measurements: [],
        billingPeriod: matchedPeriod ? matchedPeriod : null,
        components: initialComponents as NodeValueType[]
      }
    : {
        invoiceNumber: '',
        invoiceDate: moment(MIN_DATE),
        description: '',
        startDate: moment(MIN_DATE),
        endDate: moment(MAX_DATE),
        totalAmountExclVat: 0,
        totalAmountInclVat: 0,
        attachmentId: '',
        file: undefined,
        measurements: [],
        billingPeriod: null,
        components: []
      };

  const { values, errors, setValue } = useSimpleValidator<ValidatorType>({
    initialValues: initialValues,
    validate: () => {
      const feedback: boolean[] = [];

      feedback.push(!values.invoiceNumber);
      feedback.push(!values.invoiceDate || isMinDate(values.invoiceDate));
      feedback.push(values.totalAmountExclVat <= 0);
      feedback.push(values.totalAmountInclVat <= 0);
      feedback.push(!values.billingPeriod);
      feedback.push(!values.startDate || isMinDate(values.startDate));
      feedback.push(!values.endDate || isMaxDate(values.endDate));

      if (values.billingPeriod) {
        values.components.forEach((c) => {
          feedback.push(!c.component);
          feedback.push(!c.value);
        });
      }

      return feedback;
    }
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(errors.includes(true));
    }
  };

  React.useEffect(() => {
    validate();
  }, [values, errors]);

  React.useEffect(() => {
    // Use case billing periods tab
    if (selectedBillingPeriod) {
      setValue({ billingPeriod: selectedBillingPeriod });
    }
  }, []);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        // Post incoming invoice
        const apiFriendlyValues: IncomingInvoiceRequestType = {
          propertyGroupId: propertyGroupId,
          invoiceNumber: values.invoiceNumber,
          invoiceDate: values.invoiceDate,
          description: values.description,
          startDate: values.startDate ? values.startDate.toISOString() : MIN_DATE,
          endDate: values.endDate ? values.endDate.toISOString() : MAX_DATE,
          totalAmountExclVat: values.totalAmountExclVat,
          totalAmountInclVat: values.totalAmountInclVat
        };

        const newInvoice = await submitIncomingInvoice(
          apiFriendlyValues,
          tenantReducer,
          i18n.lang,
          incomingInvoice?.id || ''
        );

        if (incomingInvoice && updateIncomingInvoice) {
          // Edit
          updateIncomingInvoice(newInvoice);
        } else if (addIncomingInvoiceToState) {
          // Add
          addIncomingInvoiceToState(newInvoice);

          // Post attachment
          if (values.file) {
            try {
              const formData = createFormData({
                Description: values.description,
                File: values.file.file
              });

              await submitAttachment(
                formData,
                entitySubjectType.incomingInvoice,
                newInvoice.id,
                tenantReducer,
                i18n.lang
              );
            } catch (e) {
              notify.error({
                content: i18n.getTranslation('attachment.upload_failed'),
                error: e
              });
            }
          }
        }

        // Link components to billing period
        if (values.components.length > 0 && values.billingPeriod) {
          const updatedBillingPeriods = await Promise.all(
            values.components.map(async (c) => {
              const apiFriendlyValues_ = {
                incomingInvoiceComponentId: c.component ? c.component.id : '',
                value: c.value,
                incomingInvoiceId: newInvoice.id
              };

              return addIncomingInvoiceComponentsToBillingPeriod(
                apiFriendlyValues_,
                propertyGroupId,
                values.billingPeriod ? values.billingPeriod.id : '',
                tenantReducer,
                i18n.lang
              );
            })
          );

          if (selectedBillingPeriod) {
            const updatedPeriod = updatedBillingPeriods.find((p) => {
              return p.id === selectedBillingPeriod.id;
            });
            if (updatedPeriod && setSelectedBillingPeriod) setSelectedBillingPeriod(updatedPeriod);
          }

          updateBillingPeriodsInState(updatedBillingPeriods);
        }

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.add_incoming_invoice_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.add_incoming_invoice_fail'),
          error: e
        });
      }
    }
  }));

  const dropdownValues = billingPeriods.map((bp) => {
    return {
      text: formatPeriod(bp.startDate, bp.endDate),
      value: bp
    };
  });

  const setInvoiceComponents = (newComponents: NodeValueType[]) => {
    setValue({ components: newComponents });
  };

  const renderBillingPeriod = () => {
    if (selectedBillingPeriod) {
      // UC billing periods tab
      return (
        <InputField
          id="billing-period"
          value={values.billingPeriod ? formatPeriod(values.billingPeriod.startDate, values.billingPeriod.endDate) : ''}
          onChange={() => {}}
          placeholder={i18n.getTranslation('property_groups.tabs.billing.billing_period')}
          disabled
        />
      );
    } else {
      // UC incoming invoices
      return (
        <Dropdown
          id="periods-dropdown"
          selectedValues={[values.billingPeriod ? values.billingPeriod.id : '']}
          values={dropdownValues}
          onChange={(val) => setValue({ billingPeriod: val[0] })}
          placeholder={i18n.getTranslation('property_groups.tabs.billing.billing_period')}
          error={!values.billingPeriod}
        />
      );
    }
  };

  const attachmentInvoice = React.useMemo(() => {
    return (
      <IncomingInvoiceAttachment
        file={values.file}
        setFile={(file: any) => setValue({ file: file })}
        incomingInvoiceId={incomingInvoice ? incomingInvoice.id : ''}
      />
    );
  }, [values.file]);

  return (
    <div className={css['content-wrapper']}>
      {attachmentInvoice}
      <div>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('property_groups.invoice_moment')}
        </Heading>
        <InputContainer>
          <InlineDatePicker
            id="invoice-date"
            onChange={(value: Moment) => setValue({ invoiceDate: value })}
            value={values.invoiceDate}
            placeholder={i18n.getTranslation('property_groups.invoice_moment')}
            error={!values.invoiceDate || isMinDate(values.invoiceDate)}
          />
        </InputContainer>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('property_groups.tabs.billing.heading_inv_nr')}
        </Heading>
        <InputContainer>
          <InputField
            id="invoice-number"
            value={values.invoiceNumber}
            onChange={(val) => setValue({ invoiceNumber: val })}
            placeholder={i18n.getTranslation('invoice.invoice_num')}
            error={!values.invoiceNumber}
          />
          <InputField
            id="description"
            value={values.description}
            onChange={(val) => setValue({ description: val })}
            placeholder={i18n.getTranslation('general.description')}
          />
        </InputContainer>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('property_groups.tabs.billing.heading_inv_period')}
        </Heading>
        <InputContainer>
          <DateRangePicker
            id="invoice-period"
            startDate={values.startDate}
            endDate={values.endDate}
            setDates={(dates) => setValue({ startDate: dates[0], endDate: dates[1] })}
            error={!values.startDate || isMinDate(values.startDate) || !values.endDate || isMaxDate(values.endDate)}
          />
        </InputContainer>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('property_groups.tabs.billing.heading_amounts')}
        </Heading>
        <InputContainer>
          <MoneyInput
            id="amount-excl-vat"
            onChange={(val) => setValue({ totalAmountExclVat: val })}
            value={values.totalAmountExclVat}
            placeholder={i18n.getTranslation('invoice.total_excl_vat')}
            error={values.totalAmountExclVat <= 0}
          />
          <MoneyInput
            id="amount-incl-vat"
            onChange={(val) => setValue({ totalAmountInclVat: val })}
            value={values.totalAmountInclVat}
            placeholder={i18n.getTranslation('invoice.total_incl_vat')}
            error={values.totalAmountInclVat <= 0}
          />
        </InputContainer>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('property_groups.tabs.billing.billing_period_header')}
        </Heading>
        <InputContainer>{renderBillingPeriod()}</InputContainer>

        {values.billingPeriod && (
          <IncomingInvoiceComponentMultiValue
            components={components}
            initialComponents={initialComponents}
            setInvoiceComponents={setInvoiceComponents}
          />
        )}
      </div>
    </div>
  );
});
