import React from 'react';

import { formulaFunctionInputParameterReferenceType } from '@zf/api-types/enums';
import {
  CreateFormulaFunctionType,
  FormulaFunctionInputParameterType,
  FunctionType
} from '@zf/api-types/property-group-billing-configuration';
import {
  FormulaFunctionExceptionType,
  FormulaValidationResultFunctionValidationError
} from '@zf/api-types/property-metering-configuration';
import { generateColor } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context/app-context';
import css from './formula-builder.module.scss';
import FormulaFunction from './formula-function';
import EmptyColumn from '../../../../components/Column/EmptyColumn';

type Props = {
  functions: FunctionType[];
  formulaFunctions: CreateFormulaFunctionType[];
  runtimeParameters: string[];
  functionValidationErrors: FormulaValidationResultFunctionValidationError[];
  calculationErrors: FormulaFunctionExceptionType[];
  removeFormulaFunction: (index: number) => void;
  setFormulaFunctionValue: (
    formulaFunctionIndex: number,
    inputParameterIndex: number,
    key: keyof FormulaFunctionInputParameterType,
    value: FormulaFunctionInputParameterType[keyof FormulaFunctionInputParameterType]
  ) => void;
  setReferenceType: (
    formulaFunctionIndex: number,
    inputParameterIndex: number,
    type: formulaFunctionInputParameterReferenceType
  ) => void;
  setOutputName: (formulaFunctionIndex: number, newName: string) => void;
};

export default function Formula(props: Props) {
  const {
    formulaFunctions,
    functions,
    runtimeParameters,
    functionValidationErrors,
    calculationErrors,
    removeFormulaFunction,
    setFormulaFunctionValue,
    setReferenceType,
    setOutputName
  } = props;
  const { i18n } = useAppContext();

  const outputParameterNames = formulaFunctions.map((ff) => {
    return ff.outputParameterName;
  });

  return (
    <div className={css['formula-functions-list']}>
      {formulaFunctions.length > 0 ? (
        formulaFunctions.map((ff, index) => {
          const validationError = functionValidationErrors.find((fve) => {
            return fve.functionPosition === index;
          });

          const calculationError = calculationErrors.find((ce) => {
            return ce.functionPosition === index;
          });

          let category = '';

          if (!ff.functionCategory) {
            // Get corresponding function to find the category
            const correspondingfunction = functions.find((f) => {
              return f.functionType === ff.functionType;
            });

            if (correspondingfunction) {
              category = correspondingfunction.functionCategory;
            }
          } else {
            category = ff.functionCategory;
          }

          return (
            <FormulaFunction
              index={index}
              key={`${ff.functionType}-${index}`}
              color={generateColor(category)}
              formulaFunction={ff}
              validationError={validationError}
              calculationError={calculationError}
              outputParameterNames={outputParameterNames}
              runtimeParameters={runtimeParameters}
              removeFormulaFunction={removeFormulaFunction}
              setFormulaFunctionValue={setFormulaFunctionValue}
              setReferenceType={setReferenceType}
              setOutputName={setOutputName}
            />
          );
        })
      ) : (
        <div className={css['empty-formula-wrapper']}>
          <EmptyColumn
            image="cost-allocation2"
            title="Formula builder"
            text={i18n.getTranslation('property_groups.tabs.billing.formula_builder_empty_state_no_formula')}
          />
        </div>
      )}
    </div>
  );
}
