import React from 'react';

import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../../app-context';
import CustomerAutoFill, { CustomerAutofillProps } from '../../../../components/Autofills/CustomerAutoFill';

type Props = {
  ownerId: string;
  managerId: string;
  setOwner: (ownerId: string) => void;
  setManager: (managerId: string) => void;
};

const InlineCustomerAutoFill = InlineInputField<CustomerAutofillProps>(CustomerAutoFill);

export default function DefaultRelationsCard(props: Props) {
  const { ownerId, managerId, setOwner, setManager } = props;
  const { i18n } = useAppContext();

  return (
    <Card id="default-relations-card">
      <CardHeader>{i18n.getTranslation('property_groups.default_relations')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{i18n.getTranslation('property_groups.default_owner')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineCustomerAutoFill
            id="propertygroup.owner"
            onChange={(value) => setOwner(value[0] ? value[0].id : '')}
            selectedValues={[ownerId]}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{i18n.getTranslation('property_groups.default_manager')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineCustomerAutoFill
            id="propertygroup.manager"
            onChange={(value) => setManager(value[0] ? value[0].id : '')}
            selectedValues={[managerId]}
          />
        </CardItem>
      </CardBody>
    </Card>
  );
}
