import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { BillingItemType } from '@zf/api-types/product';
import { PropertyGroupCalculationConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import useDialog from '@zf/hooks/src/useDialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import AddAllocationGroupsDialog from '../../../../../../actions/property-group/add-allocation-group-dialog';
import DeleteAllocationGroupDialog from '../../../../../../actions/property-group/delete-allocation-group-dialog';
import { useAppContext } from '../../../../../../app-context';
import AddBillingItemsDialog from '../../../../../../components/billing-items-dialog/AddBillingItemsDialog';
import Button from '../../../../../../components/Button/Button';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import { DeleteIcon } from '../../../../../../components/Icon';
import EditIcon from '../../../../../../components/Icon/EditIcon';
import MoreActionsMenu from '../../../../../../components/Menus/MoreActionsMenu';
import { DropdownAction } from '../../../../../../design-system/ComponentSets';
import { dialog } from '../../../../../../events/dialog-events';
import { useStore } from '../../../../../../hooks/useStore';
import useBillingData from '../../hooks/use-billing-data';
import BillingItem from './billing-item';
import css from './cost-allocations.module.scss';

type Props = {
  allocationGroup: PropertyGroupCalculationConfigurationType;
  groups: PropertyGroupCalculationConfigurationType[];
  isLast: boolean;
  addAllocationGroupsToState: (allocationGroups: PropertyGroupCalculationConfigurationType[] | null) => void;
};

const AllocationGroup = (props: Props) => {
  const { groups, allocationGroup, isLast, addAllocationGroupsToState } = props;

  const { applicationStore, propertyGroupStore } = useStore();
  const { getTranslation } = applicationStore;
  const { billingItemsDialogStore } = propertyGroupStore;
  const { updatingForEntity } = billingItemsDialogStore;

  const { authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const { clickRef, validationRef, onSubmit } = useDialog();
  const {
    propertyGroupBillingConfiguration,
    taxCodes,
    calculationTypes,
    customEntityPropertyTypes,
    updateAllocationGroupBillingItems,
    submitAllocationGroup
  } = useBillingData();

  useEffect(() => {
    billingItemsDialogStore.setUpdatingForEntity(allocationGroup);
  }, [allocationGroup]);

  if (!propertyGroupBillingConfiguration) return null;

  const openAddGroupDialog = (isGroupAddMode: boolean) => {
    dialog.normalBig({
      title: getTranslation(`property_groups.tabs.billing.${isGroupAddMode ? 'add' : 'edit'}_group`),
      icon: isGroupAddMode ? 'plus' : 'sign',
      content: (
        <AddAllocationGroupsDialog
          ref={clickRef}
          validationRef={validationRef}
          propertyGroupBillingConfiguration={propertyGroupBillingConfiguration}
          groups={groups}
          allocationGroup={isGroupAddMode ? undefined : allocationGroup}
          addAllocationGroupsToState={addAllocationGroupsToState}
          submitAllocationGroup={submitAllocationGroup}
        />
      ),
      buttonPositive: getTranslation(`general.${isGroupAddMode ? 'add' : 'update'}`),
      ref: validationRef,
      onSubmit,
      withPadding: false
    });
  };

  const openDeleteGroupDialog = () => {
    dialog.normal({
      title: getTranslation('property_groups.tabs.billing.delete_group_heading'),
      icon: 'trashcan',
      content: (
        <DeleteAllocationGroupDialog
          ref={clickRef}
          toDelete={allocationGroup}
          groups={groups}
          addAllocationGroupsToState={addAllocationGroupsToState}
          submitAllocationGroup={submitAllocationGroup}
        />
      ),
      buttonPositive: getTranslation('general.remove'),
      ref: validationRef,
      onSubmit,
      type: 'danger'
    });
  };

  const isItemAddMode = allocationGroup.billingItems.length === 0;

  const openAddBillingItemDialog = () => {
    dialog.normalBig({
      title: getTranslation(`product_config.${isItemAddMode ? 'add' : 'edit'}_items`),
      icon: isItemAddMode ? 'plus' : 'sign',
      content: (
        <AddBillingItemsDialog
          useCase="allocationGroup"
          ref={clickRef}
          calculationTypes={calculationTypes}
          customEntityPropertyTypes={customEntityPropertyTypes}
          taxCodes={taxCodes}
          generateNewEntityBillingItem={(billingItem: BillingItemType) => {
            return {
              billingItemId: billingItem.id,
              deviatingTariffCalculation: false
            };
          }}
          onSubmit={() => updateAllocationGroupBillingItems(updatingForEntity)}
          showExtraInfo={false}
          // @ts-ignore
          billingItemsDialogStore={billingItemsDialogStore}
        />
      ),
      buttonPositive: getTranslation(`general.${isItemAddMode ? 'add' : 'update'}`),
      withPadding: false,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      className={css['group-column']}
      bodyClassName={css['column-body']}
      title={allocationGroup.name}
      borderStyle={isLast ? 'dashed' : 'solid'}
      addLocation="border"
      subTitleBelow={getTranslation('property_groups.tabs.billing.amount_locations', {
        amount: allocationGroup.serviceLocationIds.length
      })}
      add={
        isLast && hasPermission('property_group_billing_config.cost_allocation_add')
          ? () => openAddGroupDialog(true)
          : undefined
      }
      addTooltipText={getTranslation('property_groups.tabs.billing.add_alloc_group')}
      extraRight={
        <>
          <EditIcon
            id="property_group_billing_config.cost_allocation_update"
            className={css['icon']}
            onClick={() => openAddGroupDialog(false)}
          />
          <DeleteIcon
            id="property_group_billing_config.cost_allocation_delete"
            className={css['icon']}
            onClick={openDeleteGroupDialog}
          />
        </>
      }
    >
      <div>
        {allocationGroup.billingItems.length === 0 && (
          <Button
            id="property_group_billing_config.add_edit_billing_item"
            className={css['big-add-btn']}
            icon={isItemAddMode ? 'plus' : 'sign'}
            type="secondary"
            onClick={openAddBillingItemDialog}
          >
            {getTranslation(`property_groups.tabs.billing.${isItemAddMode ? 'add' : 'edit'}_billing_item`)}
          </Button>
        )}
        {allocationGroup.billingItems.length > 0 ? (
          <div className={css['billing-item']}>
            <div className={css['billing-item-actions']}>
              <MoreActionsMenu
                id={`more-actions-${allocationGroup.id}`}
                actions={[
                  <DropdownAction
                    id="property_group_billing_config.add_edit_billing_item"
                    key="property_group_billing_config.add_edit_billing_item"
                    icon={isItemAddMode ? 'plus' : 'sign'}
                    actionType="direct"
                    onClick={openAddBillingItemDialog}
                  >
                    {getTranslation(`property_groups.tabs.billing.${isItemAddMode ? 'add' : 'edit'}_billing_item`)}
                  </DropdownAction>
                ]}
              />
            </div>
            <Heading headingType="h3" style="bold">
              {getTranslation('billing_items.billing_items')}
            </Heading>
            {allocationGroup.billingItems.map((b) => (
              <BillingItem key={b.billingItemId} billingItem={b} />
            ))}
          </div>
        ) : (
          <EmptyColumn
            image="attributes2"
            title="Billing items"
            text={getTranslation('property_groups.tabs.billing.no_billing_items')}
          />
        )}
      </div>
    </ConfigColumn>
  );
};

export default observer(AllocationGroup);
