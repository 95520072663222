import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from '../../../../../hooks/useStore';
import InputField from '../../../../input/InputField';
import SingleUploadInput from '../../../../input/SingleUploadInput';
import { AddEditType } from '../../../Dialog/types';
import FilesStore from '../../logic/FilesStore';

type Props<T> = {
  filesStore: FilesStore<T>;
  useCase: AddEditType;
};

const SingleFile = <T,>(props: Props<T>) => {
  const { filesStore, useCase } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { attachmentFiles } = filesStore.addEditEntityAttachmentForm._values;
  let singleFile = attachmentFiles.find((f) => {
    const { fileName } = f.attachmentFileForm._values;
    return !!fileName;
  });

  if (!singleFile) singleFile = attachmentFiles[0];
  const { fileName, file } = singleFile?.attachmentFileForm._values;
  const isEdit = useCase === 'edit';

  return (
    <>
      <InputField
        id="file"
        onChange={(localisedFileName) => singleFile?.attachmentFileForm._set('fileName', localisedFileName)}
        value={fileName}
        placeholder={getTranslation('entity_attachment.file_name')}
        singleError={singleFile.attachmentFileForm._error('fileName')}
        error={!fileName}
        disabled={isEdit}
      />

      <SingleUploadInput
        file={file}
        setFile={(file) => singleFile?.attachmentFileForm._set('file', file)}
        // @ts-ignore - 'undefined' is replaced by 'value' in mobx-react-form?
        error={!file || file === 'value'}
        mayReupload={false}
        disabled={isEdit}
      />
    </>
  );
};

export default observer(SingleFile);
