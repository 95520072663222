import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import CopyIcon from '../../../../components/Icon/CopyIcon';
import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { ICON_COLORS } from '../../../../constants/icons';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import css from './dns-records-table.module.scss';

const DnsRecordsTable = observer(() => {
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { domainAuthParameters_ } = communicationStore.settingsStore;

  const [columns] = useState([
    {
      width: 250,
      label: getTranslation('communication.host'),
      dataKey: 'host'
    },
    {
      width: 150,
      label: '',
      dataKey: 'copy_1'
    },
    {
      width: 400,
      label: getTranslation('general.value'),
      dataKey: 'value'
    },
    {
      width: 150,
      label: '',
      dataKey: 'copy_2'
    },
    {
      width: 100,
      label: getTranslation('general.type'),
      dataKey: 'type'
    },
    {
      width: 100,
      label: getTranslation('general.status'),
      dataKey: 'status'
    }
  ]);

  const fallbackCopyTextToClipboard = (text: string, entity: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        notify.info({
          content: getTranslation('communication.copy_info', { entity })
        });
      }
    } catch (e) {
      notify.error({
        content: getTranslation('communication.copy_failed'),
        error: e
      });
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = (row: number, entity: string) => {
    let element;

    if (entity === 'host') {
      /* Get the text field */
      element = document.getElementById(`host-${row}`);
    } else {
      element = document.getElementById(`value-${row}`);
    }

    const copyText = element && element.textContent ? element.textContent : '';

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(copyText, entity);
      return;
    }
    navigator.clipboard.writeText(copyText).then(
      () => {
        notify.info({
          content: getTranslation('communication.copy_info', { entity })
        });
      },
      (e) => {
        notify.error({
          content: getTranslation('communication.copy_failed'),
          error: e
        });
      }
    );
  };

  const DNSRows = domainAuthParameters_
    ? domainAuthParameters_.records.map((record, index) => {
        return {
          __id: `${record.key}-${index}`,
          host: <Paragraph id={`host-${index}`}>{record.key}</Paragraph>,
          copy_1: (
            <CopyIcon
              id={`copy-${record.value}-${index}`}
              tooltipFor="dns-records-table-tip"
              onClick={() => copyTextToClipboard(index, 'host')}
            />
          ),
          value: <Paragraph id={`value-${index}`}>{record.value}</Paragraph>,
          copy_2: (
            <CopyIcon
              id={`copy-${record.type}-${index}`}
              tooltipFor="dns-records-table-tip"
              onClick={() => copyTextToClipboard(index, 'value')}
            />
          ),
          type: record.type.toUpperCase(),
          status: (
            <NewStatusBadge
              color={ICON_COLORS[record.status.toLowerCase()]}
              status_={getEnumTranslation('dnsRecordStatus', record.status)}
              type="bare"
            />
          )
        };
      })
    : [];

  return (
    <CardItem width="12">
      <div className={css['dns-records-table']}>
        <DynamicVirtualTable
          id="dns-records-table"
          tooltipId="dns-records-table-tip"
          rows={DNSRows}
          columns={columns}
          noSelect
        />
      </div>
    </CardItem>
  );
});

export default DnsRecordsTable;
