import CountryInput from 'components/input/country-input';
import InputField from 'components/input/InputField';
import { Button } from 'design-system/Components';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import { observer } from 'mobx-react-lite';
import React, { useReducer } from 'react';

import { AddressType, LocalAddressType } from '@zf/api-types/general';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';

import css from './invoice-adress.module.scss';

type InvoiceAddressUC = 'new_contractor' | 'old_contractor';

type Props = {
  id: string;
  useCase: InvoiceAddressUC;
  contractorDisplayName: string;
  invoiceAddress: AddressType;

  // new_contractor only
  selectedLocation?: ServiceLocationType;

  setInvoiceAddressValue: (val: Partial<LocalAddressType>) => void;
  handleFocus?: () => void;
};

type State = {
  showEditor: boolean;
  isPrefilled: boolean;
  oldAddress: LocalAddressType | undefined;
};

const InvoiceAddress = (props: Props) => {
  const { id, useCase, contractorDisplayName, invoiceAddress, selectedLocation, setInvoiceAddressValue, handleFocus } =
    props;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    showEditor: false,
    isPrefilled: false,
    oldAddress: undefined
  });

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div className={css['invoice-address']}>
      <FlexElements justifyContent="space-between">
        <Paragraph bold>
          <Interweave
            content={getTranslation(`contracts.wizard.edit_invoice_address_${useCase}`, {
              contractor: contractorDisplayName,
              textColor: colors['silver-600']
            })}
          />
        </Paragraph>

        <Button
          id={`${id}-edit`}
          icon={state.showEditor ? 'cross' : 'sign'}
          type="text"
          onClick={() => setState({ showEditor: !state.showEditor })}
          danger={state.showEditor}
          noSidePadding
        >
          {state.showEditor ? '' : getTranslation('general.edit')}
        </Button>
      </FlexElements>

      {state.showEditor && (
        <>
          {useCase === 'new_contractor' && selectedLocation && (
            <Button
              id={`${id}-prefill-location-address`}
              className={css['prefill-btn']}
              type="text"
              onClick={() => {
                if (state.isPrefilled && state.oldAddress) {
                  setState({ isPrefilled: false, oldAddress: undefined });
                  setInvoiceAddressValue(state.oldAddress);
                } else {
                  setState({ isPrefilled: true, oldAddress: invoiceAddress });
                  setInvoiceAddressValue({
                    ...selectedLocation.address
                  });
                }
              }}
              noSidePadding
            >
              {state.isPrefilled
                ? getTranslation('contracts.wizard.prefill_revert')
                : getTranslation('contracts.wizard.prefill_with', {
                    address: selectedLocation.address.localizedDisplay || ''
                  })}
            </Button>
          )}

          <div className={css['invoice-address-inputs']}>
            <InputField
              id={`contracts.${id}-street`}
              className={css['street']}
              onChange={(streetName) => setInvoiceAddressValue({ streetName })}
              value={invoiceAddress.streetName}
              placeholder={getTranslation('location.street')}
              error={!invoiceAddress.streetName}
              onFocus={handleFocus}
            />
            <InputField
              id={`contracts.${id}-streetNr`}
              onChange={(streetNumber) => setInvoiceAddressValue({ streetNumber })}
              value={invoiceAddress.streetNumber}
              placeholder={getTranslation('customer.streetnr')}
              error={!invoiceAddress.streetNumber}
              onFocus={handleFocus}
            />
            <InputField
              id={`contracts.${id}-streetNrAdd`}
              onChange={(streetNumberAddition) => setInvoiceAddressValue({ streetNumberAddition })}
              value={invoiceAddress.streetNumberAddition}
              placeholder={getTranslation('location.streetnraddition')}
              onFocus={handleFocus}
            />
            <InputField
              id={`contracts.${id}-postalCode`}
              onChange={(postalCode) => setInvoiceAddressValue({ postalCode })}
              value={invoiceAddress.postalCode}
              placeholder={getTranslation('location.postal')}
              error={!invoiceAddress.postalCode}
              onFocus={handleFocus}
            />
            <InputField
              id={`contracts.${id}-city`}
              onChange={(city) => setInvoiceAddressValue({ city })}
              value={invoiceAddress.city}
              placeholder={getTranslation('location.city')}
              error={!invoiceAddress.city}
              onFocus={handleFocus}
            />
            <CountryInput
              id={`contracts.${id}-country`}
              className={css['country']}
              onChange={(val) => setInvoiceAddressValue({ country: val[0] })}
              selectedValues={[invoiceAddress.country]}
              placeholder={getTranslation('location.country')}
              error={!invoiceAddress.country}
              onFocus={handleFocus}
              organisationScoped
            />
          </div>
        </>
      )}
    </div>
  );
};

export default observer(InvoiceAddress);
