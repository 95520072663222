import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import SubTabs from '@zf/stella-react/src/atoms/Tabs/SubTabs/SubTabs';

import SuspenseSpinner from '../../../../components/suspense/suspense-spinner';
import { useStore } from '../../../../hooks/useStore';
import { BillingProvider } from './billing-context/context';
import useBillingData from './hooks/use-billing-data';
import useFetchBillingData from './hooks/use-fetch-billing-data';
import BillingPeriodsTab from './tabs/billing-periods/billing-periods-tab';
import CostAllocations from './tabs/cost-allocations/cost-allocations';
import CostCalculatorTab from './tabs/cost-calculator/cost-calculator-tab';
import Dashboard from './tabs/dashboard/dashboard';
import IncomingInvoicesTab from './tabs/incoming-invoices/incoming-invoices-tab';

type Props = {
  propertyGroup: PropertyGroupType;
};

function BillingTab(props: Props) {
  const { propertyGroup } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  useFetchBillingData(props.propertyGroup.id);
  const { propertyGroupBillingConfiguration, setPropertyGroupBillingConfiguration } = useBillingData();

  const subtabs: Record<string, JSX.Element> = {};

  subtabs[getTranslation('property_groups.tabs.billing.dashboard')] = <Dashboard {...props} />;
  subtabs[getTranslation('property_groups.tabs.billing.cost_allocations')] = (
    <SuspenseSpinner>
      <CostAllocations
        propertyGroup={propertyGroup}
        propertyGroupBillingConfiguration={propertyGroupBillingConfiguration}
        setPropertyGroupBillingConfiguration={setPropertyGroupBillingConfiguration}
      />
    </SuspenseSpinner>
  );

  if (propertyGroupBillingConfiguration && propertyGroupBillingConfiguration.costAllocationEnabled) {
    subtabs[getTranslation('property_groups.tabs.billing.incoming_invoices')] = (
      <SuspenseSpinner>
        <IncomingInvoicesTab {...props} />
      </SuspenseSpinner>
    );
    subtabs[getTranslation('property_groups.tabs.billing.cost_calculator')] = (
      <SuspenseSpinner>
        <CostCalculatorTab />
      </SuspenseSpinner>
    );
    subtabs[getTranslation('property_groups.tabs.billing.billing_periods')] = (
      <SuspenseSpinner>
        <BillingPeriodsTab />
      </SuspenseSpinner>
    );
  }

  return <SubTabs subTabs={subtabs} defaultTab={getTranslation('property_groups.tabs.billing.dashboard')} />;
}

export default function BillingTabWrapper(props: Props) {
  return (
    <BillingProvider>
      <BillingTab {...props} />
    </BillingProvider>
  );
}
