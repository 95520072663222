import React from 'react';

import { PropertyMeteringConfigurationType } from '@zf/api-types/property-metering-configuration';

import useSingleAPI from '../../../../../hooks/useSingleAPI';
import { useTracked } from '../metering-context/context';

export default function useFetchMeteringData(propertyGroupId: string) {
  const [state, dispatch] = useTracked();

  const meteringConfigResponse = useSingleAPI<PropertyMeteringConfigurationType>({
    request: {
      endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyGroupId}`
    }
  });

  React.useEffect(() => {
    if (meteringConfigResponse.result && !(meteringConfigResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_PROPERTY_METERING_CONFIG', config: meteringConfigResponse.result.data, isFetch: true });
    }
  }, [meteringConfigResponse.result]);

  return {
    isLoading: state.didFetch.length < 1
  };
}
