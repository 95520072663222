import React from 'react';
import StellaHistoryPicker from '@zf/stella-react/src/atoms/Date/StellaHistoryPicker';
import { HistoryPickerProps } from '@zf/stella-react/src/atoms/Date/StellaHistoryPicker';
import { useAppContext } from '../../app-context/app-context';

export default function HistoryPicker(props: HistoryPickerProps) {
  const { i18n } = useAppContext();

  return (
    <StellaHistoryPicker
      lastWeekLabel={i18n.getTranslation('historypicker.last_week')}
      lastMonthLabel={i18n.getTranslation('historypicker.last_month')}
      lastYearLabel={i18n.getTranslation('historypicker.last_year')}
      {...props}
    />
  );
}
