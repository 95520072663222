import { observer } from 'mobx-react';
import React from 'react';

import {
  OutgoingBankingTransactionRowType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import {
  exportOutgoingBankingTransactionPaymentRefundFormatType,
  outgoingBankingTransactionStatus,
  outgoingBankingTransactionType
} from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import DownloadOutgoingBankingTransactionDialog from '../../../actions/outgoing-banking-transactions/DownloadOutgoingBankingTransactionDialog';
import useCurrent from '../../../app-context/hooks/use-current';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';

type Props = {
  refresh: () => void;
};

const DownloadOutgoingBankingTransactionListPageButton = (props: Props) => {
  const { refresh } = props;
  const { applicationStore, outgoingBankingTransactionsStore } = useStore();
  const { getTranslation } = applicationStore;
  const { downloadOutgoingBankingTransaction } = outgoingBankingTransactionsStore.outgoingBankingTransactionsService;
  const { myCurrent } = useCurrent('outgoing_banking_transaction');
  const selectedRows: OutgoingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const selectedIds = myCurrent.listPage.selectedRows.map((r) => r.__id);
  const { processDownloadedData, downloadFiles } = useDownload();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.some((r) => {
      return (
        r.__entity.status === outgoingBankingTransactionStatus.created ||
        r.__entity.status === outgoingBankingTransactionStatus.resolveissues ||
        (r.__entity.migrated && !r.__entity.internalFilePath)
      );
    });

  let outgoingTransaction: OutgoingBankingTransactionType | undefined = undefined;

  if (selectedRows[0]) {
    outgoingTransaction = selectedRows[0].__entity;
  }

  const fileFormatsResponse = useSuspenseSingleAPI<exportOutgoingBankingTransactionPaymentRefundFormatType[]>({
    request: {
      endpoint: `/bill/integration/export/outgoingbankingtransaction/${
        outgoingTransaction?.type === outgoingBankingTransactionType.paymentrequests
          ? 'paymentrequest'
          : 'paymentrefund'
      }/enabledFormats`
    }
  });

  if (!outgoingTransaction || !fileFormatsResponse.result) return null;

  const fileformats = fileFormatsResponse.result.data;

  return selectedIds.length > 1 ? (
    <DropdownAction
      id="outgoing_banking_transaction.download"
      icon="download-cloud"
      actionType="direct"
      onClick={async () => {
        try {
          downloadFiles(
            selectedIds,
            '/bill/integration/export/outgoingbankingtransaction/bulk',
            '',
            'outgoingBankingTransactionIds'
          );
          refresh();
        } catch (e) {
          notify.error({
            content: getTranslation('actions.payments.download_failed'),
            error: e
          });
        }
      }}
      disabled={disabled}
    >
      {getTranslation('actions.download')}
    </DropdownAction>
  ) : fileformats.length === 1 ? (
    <DropdownAction
      id="outgoing_banking_transaction.download"
      icon="download-cloud"
      actionType="direct"
      onClick={async () => {
        try {
          const res = await downloadOutgoingBankingTransaction(
            outgoingTransaction as OutgoingBankingTransactionType,
            fileFormatsResponse.result?.data[0] as exportOutgoingBankingTransactionPaymentRefundFormatType
          );
          processDownloadedData(res);
          refresh();
        } catch (e) {
          notify.error({
            content: getTranslation('actions.payments.download_failed'),
            error: e
          });
        }
      }}
      disabled={disabled}
    >
      {getTranslation('actions.download')}
    </DropdownAction>
  ) : (
    <DropdownAction
      id="outgoing_banking_transaction.download"
      icon="download-cloud"
      dialogTitle={getTranslation('actions.download')}
      dialogContent={
        <DownloadOutgoingBankingTransactionDialog
          {...props}
          ref={clickRef}
          validationRef={validationRef}
          outgoingBankingTransaction={outgoingTransaction}
        />
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      disabled={disabled}
      buttonPositive={getTranslation('actions.download')}
    />
  );
};

export default observer(DownloadOutgoingBankingTransactionListPageButton);
