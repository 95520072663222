import { makeObservable } from 'mobx';

import { CollectionCaseType } from '@zf/api-types/collection-case';

import CollectionCaseService from '../../../app-context/services/billing/CollectionCaseService';
import RootStore from '../../../app-context/stores';
import BaseStore from '../../../app-context/stores/BaseStore';

export default class CollectionCaseStore extends BaseStore<CollectionCaseType> {
  public rootStore: RootStore;

  public collectionCaseService: CollectionCaseService;

  constructor(rootStore: RootStore) {
    super();

    this.collectionCaseService = new CollectionCaseService(rootStore.applicationStore);

    makeObservable(this, {});
  }
}
