import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { ExternalChannelType, MeterType } from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import useDialog from '@zf/hooks/src/useDialog';

import InstallMeterDialog from '../../../../../../actions/property-group/install-meter-dialog';
import { useAppContext } from '../../../../../../app-context';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import { dialog } from '../../../../../../events/dialog-events';
import { channelsAreEqual, getActiveChannels } from '../../../../../../utils/meter';
import Meter from './Meter';
import css from './meter-column.module.scss';

type Props = {
  meterType_: meterType;
  meters: MeterType[];
  propertyGroup: PropertyGroupType;
  addMetersToState: (newMeters: MeterType[]) => void;
};

export default function MeterColumn(props: Props) {
  const { meterType_, meters, propertyGroup, addMetersToState } = props;
  const { i18n, authReducer } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { hasPermission } = authReducer;

  const openAddDialog = () => {
    dialog.right({
      title: i18n.getTranslation(`property_groups.tabs.metering.add_${meterType_}`),
      icon: 'plus',
      content: (
        <InstallMeterDialog
          ref={clickRef}
          validationRef={validationRef}
          propertyGroup={propertyGroup}
          meterType_={meterType_}
          addMetersToState={addMetersToState}
        />
      ),
      buttonPositive: i18n.getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      className={css['meter-column']}
      title={i18n.getTranslation(`property_groups.tabs.metering.${meterType_}_meters`)}
      add={hasPermission('meter.install') ? openAddDialog : undefined}
      borderRight={meterType_ !== meterType.individual}
    >
      {meters.length > 0 ? (
        <div>
          {meters.map((m) => {
            const verifiedChannels: ExternalChannelType[] = [];

            return getActiveChannels(m.channels).reduce((acc: React.ReactNode[], chann, index) => {
              // No duplicates
              if (
                !verifiedChannels.find((channel) => {
                  return channelsAreEqual(chann, channel);
                })
              ) {
                verifiedChannels.push(chann);
                acc.push(<Meter key={`${m.id}-${chann.id}-${index}`} index={index} meter={m} channel={chann} />);
              }

              return acc;
            }, []);
          })}
        </div>
      ) : (
        <EmptyColumn
          image="meter"
          title={i18n.getTranslation(`property_groups.tabs.metering.${meterType_}_meters`)}
          text={i18n.getTranslation(`property_groups.tabs.metering.no_${meterType_}_meters`)}
        />
      )}
    </ConfigColumn>
  );
}
