import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import RejectDialog from '../../../actions/tasks/reject-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  task: MoveRequestType;
  setTask: (updatedTask: MoveRequestType) => void;
};

export default function RejectListItem(props: Props) {
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const rejectText = i18n.getTranslation('general.reject');

  return (
    <DropdownAction
      id="tasks.reject"
      icon="reject"
      dialogTitle={rejectText}
      dialogContent={<RejectDialog {...props} ref={clickRef} />}
      onSubmit={onSubmit}
      buttonPositive={rejectText}
    />
  );
}
