import { OptionalChannelType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import clone from 'clone';
import FloatInput from 'components/input/FloatInput';
import SimpleDropdown from 'components/Lang/SimpleDropdown';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { getDropDownTimeVals } from 'utils/meter';

import { dataFrequency } from '@zf/api-types/enums';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { colors } from '@zf/utils/src/color';
import { HOURS, MINUTESMAP } from '@zf/utils/src/date';

import css from './optional-measurement-channel.module.scss';

type Props = {
  optionalMeasurementIndex: number;
  optionalChannelIndex: number;
  optionalChannel: OptionalChannelType;
};

const OptionalMeasurementChannel = (props: Props) => {
  const { optionalChannelIndex, optionalMeasurementIndex, optionalChannel } = props;

  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { values, setValue } = contractStore.moveInWizardBaseStore.regularMoveInWizardStore.regularMoveInWizardValues;

  let currentFreq = optionalChannel.dataFrequency;

  const hoursDisabled = currentFreq === dataFrequency.p1d || currentFreq === dataFrequency.na;
  const minutesDisabled =
    currentFreq === dataFrequency.pt1h || currentFreq === dataFrequency.p1d || currentFreq === dataFrequency.na;
  const minutes = MINUTESMAP.get(currentFreq);

  const setOptionalMeasurement = (meterIndex: number, channelIndex: number, value: Partial<OptionalChannelType>) => {
    const measurementsClone = clone(values.optionalMeasurements);

    measurementsClone[meterIndex].optionalChannels[channelIndex] = {
      ...measurementsClone[meterIndex].optionalChannels[channelIndex],
      ...value
    };

    setValue({
      optionalMeasurements: measurementsClone
    });
  };

  return (
    <div className={css['measurement']}>
      <Label color={colors['silver-700']}>{optionalChannel.description}</Label>
      <InputContainer>
        {!(hoursDisabled && minutesDisabled) && (
          <>
            {!hoursDisabled && (
              <SimpleDropdown
                id={`contracts.hours-dropdown-${optionalMeasurementIndex}-${optionalChannelIndex}`}
                selectedValues={[optionalChannel.hour]}
                values={getDropDownTimeVals(HOURS, currentFreq)}
                onChange={(val) =>
                  setOptionalMeasurement(optionalMeasurementIndex, optionalChannelIndex, { hour: val[0] })
                }
                placeholder={getTranslation('meter.hour')}
              />
            )}

            {!minutesDisabled && (
              <SimpleDropdown
                id={`contracts.minutes-dropdown-${optionalMeasurementIndex}-${optionalChannelIndex}`}
                selectedValues={[optionalChannel.minute]}
                values={minutes ? getDropDownTimeVals(minutes, currentFreq) : []}
                onChange={(val) =>
                  setOptionalMeasurement(optionalMeasurementIndex, optionalChannelIndex, { minute: val[0] })
                }
                placeholder={getTranslation('meter.minute')}
              />
            )}
          </>
        )}
        <FloatInput
          id={`contracts.value-measurement-${optionalMeasurementIndex}-${optionalChannelIndex}`}
          onChange={(val) => setOptionalMeasurement(optionalMeasurementIndex, optionalChannelIndex, { value: val })}
          value={optionalChannel.value}
          placeholder={getTranslation('general.value')}
        />
      </InputContainer>
    </div>
  );
};

export default observer(OptionalMeasurementChannel);
