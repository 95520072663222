import { observer } from 'mobx-react';
import React, { Fragment, ReactNode } from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import SubTitle from '@zf/stella-react/src/atoms/Tabs/SubTitle';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import { NavigatorType } from '../../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../../components/RouteWrapper';
import GenericTabContainer from '../../../../components/Tab/GenericTabContainer';
import { Link } from '../../../../design-system/Components';
import { useStore } from '../../../../hooks/useStore';
import Route from '../../../route';
import MeterDetail from '../../meter/detail-page/detail/MeterDetail';
import BalanceTab from './balance/BalanceTab';

type Props = {
  meter: MeterType;
  navigator: NavigatorType;
  timeStamp: string;
  activeState?: MeterStatusHistoryType;
  navigate: (href: string) => void;
  refresh: () => void;
  setMeter: React.Dispatch<React.SetStateAction<MeterType | null>>;
};

const PrepaymentDeviceTabContainer = (props: Props) => {
  const { meter, navigator, timeStamp, activeState, refresh, setMeter, navigate } = props;
  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  useBrowserTitle(`${getTranslation('prepayment_device.prepayment_device')} - ${meter.serialNumber}`);

  const tabItems: TabItemType[] = [
    {
      id: 'detail',
      title: getTranslation('nav_detail.detail'),
      isDefault: true
    },
    {
      id: 'balance',
      title: getTranslation('prepayment_device.balance')
    }
  ];

  const routes = (
    <RouteWrapper default>
      <Route
        path="detail"
        Component={MeterDetail}
        timeStamp={timeStamp}
        prePayment
        meter={meter}
        activeState={activeState}
        setMeter={setMeter}
        refresh={refresh}
        navigate={navigate}
        default
      />
      <Route path="balance" Component={BalanceTab} meter={meter} />
    </RouteWrapper>
  );

  let subTitle: ReactNode = '';

  if (meter.parentMeterRelation) {
    const titleBody = [
      <Fragment key="linked_to">
        {getTranslation('meter.linked_to')}
        <Link
          id={`meter-${meter.parentMeterRelation.meterId}`}
          url={`${rootUrl}/devices/prepayment-devices/${meter.parentMeterRelation.meterId}`}
          sub
        >
          {meter.parentMeterRelation.serialNumber}
        </Link>
        {`(ref: ${meter.parentMeterRelation.externalReference})`}
      </Fragment>
    ];

    if (meter.propertyGroup) {
      titleBody.push(
        <Fragment key="installed_on">
          {`, ${getTranslation('meter.installed_on')} `}
          <Link
            id={`property-${meter.parentMeterRelation.meterId}`}
            url={`${rootUrl}/property-groups/${meter.propertyGroup.id}`}
            sub
          >
            {meter.propertyGroup.name}
          </Link>
        </Fragment>
      );
    }

    subTitle = <SubTitle>{titleBody}</SubTitle>;
  } else if (meter.propertyGroup) {
    subTitle = (
      <SubTitle>
        {getTranslation('meter.installed_on')}
        <Link
          id={`property-${meter.propertyGroup.id}`}
          url={`${rootUrl}/property-groups/${meter.propertyGroup.id}`}
          sub
        >
          {meter.propertyGroup.name}
        </Link>
      </SubTitle>
    );
  }

  return (
    <GenericTabContainer
      entityId={`${getTranslation('prepayment_device.prepayment_device')}: ${meter.serialNumber}`}
      entityType={routedEntitySubjectType.prepayment}
      navigate={navigate}
      navigator={navigator}
      tabItems={tabItems}
      routes={routes}
      iconName={meter.parentMeterRelation ? 'meter-linked' : 'meter2'}
      subTitle={subTitle}
    />
  );
};

export default observer(PrepaymentDeviceTabContainer);
