import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import DeleteProductDialog from '../dialogs/DeleteProductDialog';

type Props = {
  index: number;
  productId: string;
};

export default function DeleteListItem(props: Props) {
  const { index, productId } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id={`${index}-delete`}
      dialogTitle={getTranslation('product_config.delete_product')}
      icon="trashcan"
      dialogContent={<DeleteProductDialog ref={clickRef} index={index} productId={productId} />}
      onSubmit={onSubmit}
      buttonPositive={getTranslation('general.delete')}
      validationRef={validationRef}
      dialogType="danger"
    />
  );
}
