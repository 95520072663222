import { DropdownValuesType } from './StellaDropdown';

export const getShownValues = <T>(values: DropdownValuesType<T>[] = [], searchValue = '') => {
  return values
    .map((value, i) => {
      return { value, index: i };
    })
    .filter(({ value }) =>
      value?.text
        ?.toLowerCase()
        .includes(values.find((val) => val.text === searchValue) ? '' : searchValue?.toLowerCase())
    );
};
