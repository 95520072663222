import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { TransActionsForCustomerType } from '@zf/api-types/transactions';
import { CardBody } from '@zf/stella-react/src/atoms/Card';
import { useStore } from '../../hooks/useStore';
import css from './cash-position-body.module.scss';
import PrePaymentDevices from './containers/pre-payment-devices';
import CashPosition from './containers/cash-position';
import { MeterType } from '@zf/api-types/master-data/meter';

type Props = {
  customerId: string;
  timestampRefresh: string;
  isPrepayment: boolean;
  ShowMoreBtn: JSX.Element
};

const CashPositionBody = (props: Props) => {
  const { customerId, timestampRefresh, isPrepayment, ShowMoreBtn } = props;
  const { customerStore } = useStore();
  const [transactions, setTransactions] = useState<TransActionsForCustomerType>();
  const [prepaidMeters, setPrepaidMeters] = useState<MeterType[]>();

  useEffect(() => {
    if (isPrepayment) {
      customerStore.customerService.getMetersForCustomer(customerId).then((res) => {
        setPrepaidMeters(res.results);
      });
    } else {
      customerStore.customerService.getTransactions(customerId, timestampRefresh).then((res) => {
        setTransactions(res);
      });
    }
  }, [customerId, timestampRefresh]);

  return (
    <CardBody className={css['card-body']}>
      {isPrepayment ? <PrePaymentDevices meters={prepaidMeters} /> : <CashPosition transactions={transactions} ShowMoreBtn={ShowMoreBtn}/>}
    </CardBody>
  );
};

export default observer(CashPositionBody);
