import React from 'react';

import DynamicVirtualTableNew from 'components/Lang/dynamic-virtual-table-new/DynamicVirtualTable';
import { TableError, TableLoading } from '../../../../../components/placeholder';
import { useStore } from '../../../../../hooks/useStore';
import useTransactionsListPage from './hooks/useTransactionsListPage';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { EmptyStateSettled, settlementState } from './Components';

type Props = {
  customer: CustomerType;
};

export default function TransactionsListPage(props: Props) {
  const { customer } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const {
    rows,
    loading,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useTransactionsListPage(customer);

  return (
    <DynamicVirtualTableNew
      cursorPointer
      id="settlement-table"
      tooltipId="settlement-table-id"
      onRowsRendered={({ stopIndex, selectAllBusy }: any) => setStopIndex(stopIndex, selectAllBusy)}
      rows={[
        ...rows
          .map((row) => ({
            ...row,
            settled: settlementState(row.__entity) === 'settled',
            reversed: settlementState(row.__entity) === 'reversed',
            matched: row.__entity?.settlementDetails.settledTransactionsWithAmounts.map(
              ({ transactionId }) => transactionId
            )
          }))
          .sort(function (x, y) {
            if (sortBy.length > 0) {
              return 0;
            }
            return x.reversed === y.reversed ? 0 : x.reversed ? 1 : -1;
          })
          .sort(function (x, y) {
            if (sortBy.length > 0) {
              return 0;
            }
            return x.settled === y.settled ? 0 : x.settled ? 1 : -1;
          })
      ]}
      loading={loading}
      error={error}
      NoDataOverlay={() => <EmptyStateSettled />}
      LoadingOverlay={TableLoading}
      ErrorOverlay={TableError}
      selectedRows={selectedIds}
      onSelectRow={setSelectedIds}
      singleSort
      sortableFields={['TransactionAmount', 'CreatedDateTime']}
      sort={handleSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      totalAmountOfRows={rows.length}
      refresh={refresh}
      timeStamp={timeStamp}
      localTimeStamp={localTimeStamp}
      showCheckBoxes={false}
      singleSelect
      columns={[
        {
          width: 1,
          label: '',
          dataKey: 'id'
        },
        {
          width: 250,
          label: getTranslation('general.type'),
          dataKey: 'type',
          noClick: false
        },
        {
          width: 200,
          label: getTranslation('customer.transactions.to-settle-total'),
          dataKey: 'TransactionAmount'
        },
        {
          width: 150,
          label: getTranslation('general.date'),
          dataKey: 'CreatedDateTime'
        },
        {
          width: 150,
          label: getTranslation('general.status'),
          dataKey: 'status'
        }
      ]}
    />
  );
}
