import { observer } from 'mobx-react';
import React from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { ExportJobType } from '@zf/api-types/export-job';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import { NavigatorType } from '../../../app-context/hooks/use-selection-navigator';
import GenericTabContainer from '../../../components/Tab/GenericTabContainer';
import { useStore } from '../../../hooks/useStore';
import Route from '../../route';
import ExportJobDetail from './export-job-detail';
import ExportJobActions from './ExportJobActions';

type Props = {
  navigator: NavigatorType;
  status: JSX.Element;
  exportJob: ExportJobType;
  navigate: (url: string) => void;
};

const ExportJobTabContainer = (props: Props) => {
  const { exportJob, status, navigate, navigator } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(`${getTranslation('export_jobs.export_job')} - ${exportJob.identification}`);

  return (
    <GenericTabContainer
      actions={<ExportJobActions exportJob={exportJob} />}
      navigator={navigator}
      entityId={exportJob.identification}
      status={status}
      entityType={routedEntitySubjectType.exportjob}
      navigate={navigate}
      iconName="export-job"
      tabItems={[
        {
          id: 'detail',
          title: getTranslation('nav_detail.detail'),
          isDefault: true
        }
      ]}
      routes={<Route Component={ExportJobDetail} path="detail" exportJob={exportJob} default />}
    />
  );
};

export default observer(ExportJobTabContainer);
