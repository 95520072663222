import { PagedResponseType } from '@zf/api-types/api';
import {
  CommunicationParameterType,
  DomainAuthenticationParametersType,
  UpdateCommunicationParametersRequestType
} from '@zf/api-types/communication';
import {
  ApiUpdateTranslationRequest,
  enumTranslationType,
  ScenarioTranslation,
  ScenarioTranslationList,
  UpdateEnumSymbolTranslationRequest
} from '@zf/api-types/config/Communication/templates';
import {
  EntitySubjectSubtype,
  EntitySubjectUnionType,
  LocalTemplateAttachmentType,
  ResetTranslationRequest,
  ScenarioIntersectionType,
  TemplateAttachment,
  UpdateScenarioRequestType
} from '@zf/api-types/config/scenarios_new';
import { culture, documentOutputFormat, templateUsecase } from '@zf/api-types/enums';
import { FileUploadType } from '@zf/api-types/general';
import { CultureTableType, UpdateCultureTableRequestType } from '@zf/api-types/language';
import { createFormData } from '@zf/auth/src/utils';

import { EnumKeyType } from '../../../app-context/hooks/use-enum';
import { sendRequestParamsType } from '../../../app-context/stores/domain/app-store-types';
import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { notify } from '../../../events/notification-events';
import { createHeader, METHODS } from '../../../utils/request';
import CommunicationStore from '../stores/CommunicationStore';
import { enumFilterState } from '../stores/TranslationStore';

export default class CommunicationService {
  private communicationStore: CommunicationStore;
  private applicationStore: ApplicationStore;

  constructor(communicationStore: CommunicationStore, applicationStore: ApplicationStore) {
    this.communicationStore = communicationStore;
    this.applicationStore = applicationStore;
  }

  updateCommunicationParameters = async (updatedParams: Partial<UpdateCommunicationParametersRequestType>) => {
    return (
      await this.applicationStore.sendRequest<CommunicationParameterType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/Parameters/communication',
          data: updatedParams
        }
      })
    ).data;
  };

  getDomainAuthParameters = async () => {
    return (
      await this.applicationStore.sendRequest<DomainAuthenticationParametersType | null>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/Parameters/communication/domainauthentication'
        }
      })
    ).data;
  };

  saveDomain = async (domainName: string) => {
    return (
      await this.applicationStore.sendRequest<DomainAuthenticationParametersType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/Parameters/communication/domainauthentication',
          data: {
            domainName
          }
        }
      })
    ).data;
  };

  getEnumTranslations = async (filter?: enumFilterState) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<enumTranslationType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/enumTranslations',
          query: {
            translationStatus: filter?.translationStatus,
            enumType: filter?.group
          }
        }
      })
    ).data.results;
  };

  verifyDomain = async () => {
    try {
      const verificationResponse = await this.applicationStore.sendRequest<DomainAuthenticationParametersType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/Parameters/communication/domainauthentication/verify'
        }
      });

      this.communicationStore.settingsStore.setDomainAuthParameters(verificationResponse.data);

      notify.success({
        content: this.applicationStore.getTranslation('communication.verify_dns_success')
      });
    } catch (e) {
      notify.error({
        content: this.applicationStore.getTranslation('communication.verify_dns_fail'),
        error: e
      });
    }
  };

  sendTestMail = async () => {
    try {
      const receiverEmailAddress = this.applicationStore.userStore.user.email;

      await this.applicationStore.sendRequest<CommunicationParameterType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/Parameters/communication/test',
          query: {
            receiverEmailAddress
          }
        }
      });

      notify.success({
        content: this.applicationStore.getTranslation('communication.mail_success', {
          receiverEmailAddress
        })
      });
    } catch (e) {
      notify.error({
        content: this.applicationStore.getTranslation('communication.mail_failed'),
        error: e
      });
    }
  };

  updateCultureTable = async (newTable: UpdateCultureTableRequestType) => {
    return (
      await this.applicationStore.sendRequest<CultureTableType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/cultureTable',
          data: newTable
        }
      })
    ).data;
  };

  getScenario = async (entitySubjectType: EntitySubjectUnionType, entitySubjectSubtype: EntitySubjectSubtype) => {
    try {
      return (
        await this.applicationStore.sendRequest<ScenarioIntersectionType>({
          request: {
            method: METHODS.GET,
            endpoint: `/cfg/Scenarios/${entitySubjectType}/${entitySubjectSubtype}`
          }
        })
      ).data;
    } catch (e) {
      notify.error({
        content: this.applicationStore.getTranslation('communication.scenario_fetch_fail'),
        error: e
      });
    }
  };

  updateScenario = async (
    selectedEntitySubjectType: EntitySubjectUnionType,
    selectedEntitySubjectSubtype: EntitySubjectSubtype,
    updatedScenario: UpdateScenarioRequestType
  ) => {
    return (
      await this.applicationStore.sendRequest<ScenarioIntersectionType>({
        request: {
          method: METHODS.PUT,
          endpoint: `/cfg/scenarios/${selectedEntitySubjectType}/${selectedEntitySubjectSubtype}`,
          data: { ...updatedScenario }
        }
      })
    ).data;
  };

  getDummyData = async (
    templateobjecttype: EntitySubjectUnionType,
    culture: culture,
    templateObjectSubType?: EntitySubjectSubtype
  ) => {
    return (
      await this.applicationStore.sendRequest<Blob>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/templates/dummyobjects/${templateobjecttype}/${culture}`,
          query: {
            param: templateObjectSubType
          }
        }
      })
    ).data;
  };

  getTemplatePreview = async (
    entitySubjectType: EntitySubjectUnionType,
    entitySubjectSubType: EntitySubjectSubtype,
    culture: culture,
    templateUseCase: templateUsecase,
    outFormat: documentOutputFormat,
    wrapLabels?: boolean,
    showLabels?: boolean
  ) => {
    try {
      const templateDataObject = await this.getDummyData(entitySubjectType, culture, entitySubjectSubType);

      const isHTML = outFormat === documentOutputFormat.html;

      const params: sendRequestParamsType = {
        request: {
          method: METHODS.POST,
          endpoint: isHTML
            ? `/cfg/templates/${entitySubjectType}/${entitySubjectSubType}/${templateUseCase}/${culture}/html`
            : `/cfg/templates/${entitySubjectType}/${entitySubjectSubType}/pdf/${culture}/preview`,
          query: isHTML
            ? {
                wrapLabels,
                showLabels
              }
            : {},
          data: templateDataObject
        }
      };

      if (!isHTML) {
        params.responseType = 'arraybuffer';
        params.customHeaders = createHeader({
          Accept: 'application/octet-stream'
        });
      }

      return (await this.applicationStore.sendRequest<string | ArrayBuffer>(params, true)).data;
    } catch (e) {
      notify.error({
        content: this.applicationStore.getTranslation('communication.preview_failed'),
        error: e
      });
    }
  };

  downloadPreview = async (
    selectedEntitySubjectType: EntitySubjectUnionType,
    selectedEntitySubjectSubtype: EntitySubjectSubtype,
    templateUseCase: templateUsecase
  ) => {
    return (
      await this.applicationStore.sendRequest<any>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/templates/${selectedEntitySubjectType}/${selectedEntitySubjectSubtype}/${templateUseCase}`
        }
      })
    ).data;
  };

  sendTestEmail = async (
    selectedEntitySubjectType: EntitySubjectUnionType,
    selectedEntitySubjectSubtype: EntitySubjectSubtype,
    emailReceiver: string,
    culture: string
  ) => {
    try {
      const dummy = await this.getDummyData(
        selectedEntitySubjectType,
        culture as culture,
        selectedEntitySubjectSubtype
      );

      await this.applicationStore.sendRequest({
        request: {
          method: METHODS.POST,
          endpoint: `/cfg/templates/${selectedEntitySubjectType}/${selectedEntitySubjectSubtype}/email/${culture}/send`,
          data: dummy,
          query: { emailReceiver }
        }
      });

      notify.success({
        content: this.applicationStore.getTranslation('communication.mail_success', {
          emailReceiver
        })
      });
    } catch (error) {
      notify.error({
        content: this.applicationStore.getTranslation('communication.mail_failed'),
        error
      });
    }
  };

  uploadCustomScenarioTemplate = async (
    selectedEntitySubjectType: EntitySubjectUnionType,
    selectedEntitySubjectSubtype: EntitySubjectSubtype,
    templateUseCase: templateUsecase,
    file: FileUploadType | undefined
  ) => {
    if (file) {
      const formData = createFormData({
        File: file.file
      });

      return (
        await this.applicationStore.sendRequest<ScenarioIntersectionType>({
          request: {
            method: METHODS.PUT,
            endpoint: `/cfg/templates/${selectedEntitySubjectType}/${selectedEntitySubjectSubtype}/${templateUseCase}`,
            data: formData
          }
        })
      ).data;
    }
  };

  updateScenarioTranslations = async (translationName: string, apiValue: ApiUpdateTranslationRequest) => {
    return (
      await this.applicationStore.sendRequest<ScenarioTranslation>({
        request: {
          method: METHODS.POST,
          endpoint: `/cfg/scenarioTranslations/${translationName}/update`,
          data: apiValue
        }
      })
    ).data;
  };

  resetScenarioTranslation = async (translationName: string, apiValues: ResetTranslationRequest) => {
    try {
      const result = (
        await this.applicationStore.sendRequest<ScenarioTranslation>({
          request: {
            method: METHODS.POST,
            endpoint: `/cfg/scenarioTranslations/${translationName}/reset`,
            data: apiValues
          }
        })
      ).data;
      notify.success({
        content: this.applicationStore.getTranslation('communication.translation_reset_success')
      });

      return result;
    } catch (error) {
      notify.error({
        content: this.applicationStore.getTranslation('communication.translation_reset_failed'),
        error
      });
    }
  };

  removeCustomScenarioTemplate = async (
    selectedEntitySubjectType: EntitySubjectUnionType,
    selectedEntitySubjectSubtype: EntitySubjectSubtype,
    templateUseCase: templateUsecase
  ) => {
    return (
      await this.applicationStore.sendRequest<boolean>({
        request: {
          method: METHODS.DELETE,
          endpoint: `/cfg/templates/${selectedEntitySubjectType}/${selectedEntitySubjectSubtype}/${templateUseCase}`
        }
      })
    ).data;
  };

  getTranslationScenario = async (
    entitySubjectType: EntitySubjectUnionType,
    entitySubjectSubType: string,
    useCase: string
  ) => {
    return (
      await this.applicationStore.sendRequest<ScenarioTranslationList>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/scenarioTranslations/${entitySubjectType}/${entitySubjectSubType}/${useCase}`
        }
      })
    ).data;
  };

  postAttachment = async (fileData: LocalTemplateAttachmentType) => {
    return (
      await this.applicationStore.sendRequest<TemplateAttachment>({
        request: {
          method: METHODS.POST,
          endpoint: `/att/attachments/organizationscenarios/${this.applicationStore.tenantReducer.organization?.organizationId}`,
          data: createFormData({
            File: fileData.file
          })
        }
      })
    ).data;
  };

  deleteAttachment = async (file: LocalTemplateAttachmentType) => {
    return (
      await this.applicationStore.sendRequest<TemplateAttachment>({
        request: {
          method: METHODS.DELETE,
          endpoint: `/att/attachments/organizationscenarios/${this.applicationStore.tenantReducer.organization?.organizationId}/${file.id}`
        }
      })
    ).data;
  };

  updateEnumTranslation = async (enumType: EnumKeyType, symbol: string, body: UpdateEnumSymbolTranslationRequest) => {
    return (
      await this.applicationStore.sendRequest<enumTranslationType>({
        request: {
          method: METHODS.PUT,
          endpoint: `/cfg/enumTranslations/${enumType}/symbols/${symbol}`,
          data: { translations: body }
        }
      })
    ).data;
  };

  resetEnumTranslation = async (enumType: EnumKeyType, symbol: string) => {
    return (
      await this.applicationStore.sendRequest<enumTranslationType>({
        request: {
          method: METHODS.POST,
          endpoint: `/cfg/enumTranslations/${enumType}/symbols/${symbol}/reset`
        }
      })
    ).data;
  };

  postAttachmentsForScenario = async (
    currentAttachments: LocalTemplateAttachmentType[],
    previousAttachments: LocalTemplateAttachmentType[]
  ) => {
    const attachmentsAlreadyPosted: LocalTemplateAttachmentType[] = [];
    const attachmentsToPost: LocalTemplateAttachmentType[] = [];

    // Get deleted attachments, if any
    const deleted = previousAttachments.filter((att) => {
      return !currentAttachments.some((bAtt) => {
        return att.internalFilePath === bAtt.internalFilePath;
      });
    });

    // Delete these, if any
    await Promise.all(deleted.map(async (att) => this.deleteAttachment(att)));

    currentAttachments.forEach((a) => {
      if (a.internalFilePath) {
        attachmentsAlreadyPosted.push(a);
      } else {
        attachmentsToPost.push(a);
      }
    });

    // This array is filled with the responses of the attachment API
    const newAttachments = await Promise.all(
      attachmentsToPost.map(async (att) => {
        const res = await this.postAttachment(att);
        return {
          id: res.id,
          internalFilePath: res.internalFilePath,
          fileName: res.fileName,
          state: 'none'
        };
      })
    );

    return [...attachmentsAlreadyPosted, ...newAttachments];
  };
}
