import Interweave from 'interweave';
import React from 'react';

import { meteringType, unitOfMeasure, utilityType } from '@zf/api-types/enums';
import { PropertyMeteringConfigurationType } from '@zf/api-types/property-metering-configuration';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import InputField from '../../components/input/InputField';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';
import css from './add-output-channel-dialog.module.scss';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  propertyMeteringConfiguration: PropertyMeteringConfigurationType | undefined;
  setPropertyMeteringConfiguration: (newConfig: PropertyMeteringConfigurationType) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, propertyMeteringConfiguration, setPropertyMeteringConfiguration } = props;

  const { i18n, tenantReducer } = useAppContext();

  const [name, setName] = React.useState('');

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!name);
    }
  };

  React.useEffect(() => {
    validate();
  }, [name]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (propertyMeteringConfiguration) {
          const result = (
            await sendRequest<PropertyMeteringConfigurationType>({
              request: {
                method: METHODS.POST,
                endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyMeteringConfiguration.propertyGroup?.id}`,
                data: {
                  outputChannels: [
                    ...propertyMeteringConfiguration.outputChannels,
                    {
                      name: name,
                      formula: null,
                      validationResult: null,
                      unitOfMeasure: unitOfMeasure.none,
                      meteringType: meteringType.none,
                      utilityType: utilityType.none
                    }
                  ]
                }
              },
              tenantReducer,
              lang: i18n.lang
            })
          ).data;

          setPropertyMeteringConfiguration(result);

          notify.success({
            content: i18n.getTranslation('property_groups.tabs.metering.add_channel_success', {
              outputChannel: name
            })
          });
        } else {
          throw new Error(
            i18n.getTranslation('property_groups.tabs.metering.add_channel_fail', {
              outputChannel: name
            })
          );
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.metering.add_channel_fail', {
            outputChannel: name
          }),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>
        <Interweave
          content={i18n.getTranslation('property_groups.tabs.metering.add_calculation_paragraph', {
            outputChannel: name
          })}
        />
      </Paragraph>
      <InputContainer className={css['property-groups-output-name-container']}>
        <InputField
          id="name"
          value={name}
          onChange={setName}
          placeholder={i18n.getTranslation('property_groups.tabs.metering.output_name')}
          error={!name}
        />
      </InputContainer>
    </>
  );
});
