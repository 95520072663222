import classNames from 'classnames';
import Interweave from 'interweave';
import React, { CSSProperties } from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../app-context';
import { Button } from '../../design-system/Components';
import css from './column-header.module.scss';

type Props = {
  title?: string;
  subTitleBelow?: string;
  coachMark?: JSX.Element;
  className?: string;
  style?: CSSProperties;
  extraRight?: JSX.Element;
  disabled: boolean;
  add?: () => void;
};

export default function ColumnHeader(props: Props) {
  const { title, subTitleBelow, className, coachMark, extraRight, disabled, style = {}, add } = props;

  const { i18n } = useAppContext();

  return (
    <>
      <div className={classNames(css['column-header'], className)} style={style}>
        <div className={css['title-coachmark']}>
          <div className={css['title']}>
            <Heading headingType="h3">{title}</Heading>
          </div>
          <div>{coachMark}</div>
        </div>

        <div className={css['extras']}>
          {!!extraRight && extraRight}
          {add && (
            <Button
              id={`${title}-add-btn`}
              icon="plus"
              type="text"
              tooltipText={i18n.getTranslation('general.add')}
              onClick={add}
              disabled={disabled}
            />
          )}
        </div>
      </div>

      <div className={css['subtitle-below']}>
        <Interweave content={subTitleBelow} />
      </div>
    </>
  );
}
