import { AgingReportOverviewCountType, AgingReportTransactionType, AgingReportType } from '@zf/api-types/aging-report';
import { PagedResponseType } from '@zf/api-types/api';
import { MIN_DATE } from '@zf/utils/src/date';
import BaseService from '../../../../app-context/services/BaseService';
import { METHODS } from '../../../../utils/request';
import { StateValues } from '../exports/dialogs/export-dialog';
import AgingReportStore from '../stores/AgingReportStore';

class AgingReportService extends BaseService {
  public agingReportStore: AgingReportStore;
  constructor(agingReportStore: AgingReportStore) {
    super('/bill/custAging', agingReportStore.rootStore.applicationStore);
  }

  getTransactionForCustomer = async (customerId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<AgingReportTransactionType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/custAging/${customerId}`
        }
      })
    ).data.results;
  };

  getCustAgingOverviewCounts = async (propertyGroupId: string, flexSearch: string) => {
    return (
      await this.applicationStore.sendRequest<AgingReportOverviewCountType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/custAging/overviewcount`,
          query: {
            propertyGroupId,
            flexSearch
          }
        }
      })
    ).data;
  };

  getCustomerReport = async (filters: StateValues, continuationToken?: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<AgingReportType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/custAging`,
          query: {
            hideCredits: !filters.creditAmounts,
            propertyGroupId: filters.propertyGroupId
          }
        },
        customHeaders: continuationToken ? { continuationToken } : undefined
      })
    ).data;
  };

  getTransactionReport = async (filters: StateValues, continuationToken?: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<AgingReportTransactionType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/custAging/detailed`,
          query: {
            hideCredits: !filters.creditAmounts,
            propertyGroupId: filters.propertyGroupId,
            referenceDate: filters.date === MIN_DATE ? null : filters.date
          }
        },
        customHeaders: continuationToken ? { continuationToken } : undefined
      })
    ).data;
  };
}

export default AgingReportService;
