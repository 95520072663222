import classNames from 'classnames';
import clone from 'clone';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import {
  dataFrequency,
  direction,
  incrementationType,
  meteringType,
  meterType,
  unitOfMeasure,
  utilityType
} from '@zf/api-types/enums';
import {
  AddMeasurementRequestType,
  MeterRowType,
  MeterType,
  UpdateMeterRequestType
} from '@zf/api-types/master-data/meter';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { onlyUnique } from '@zf/utils/src/array';
import { betweenDates, isMinDate, MIN_DATE, startOfDay } from '@zf/utils/src/date';

import DatePicker from '../../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import { formatActiveMeterText } from '../../../utils/meter';
import css from '../../style.module.scss';
import ReconfigureMultiValue from './reconfigure-multivalue';

type Props = {
  onComplete?: React.Dispatch<React.SetStateAction<MeterType>>;
  selectedRows?: MeterRowType[];
  meter?: MeterType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

export type NodeValueType = {
  meterId: string;
  meterName: string;
  mutationDateTime: Moment;
  description?: string | null;
  dataFrequency?: dataFrequency;
  incrementationType?: incrementationType;
  meteringType?: meteringType;
  utilityType?: utilityType;
  direction?: direction;
  unitOfMeasure?: unitOfMeasure;
  timeOfUse?: string;
  addingMeasurement: boolean;
  value: string;
  channelId?: string;
  relationExternalReference: string;
  hour: string;
  minute: string;
};

const ReconfigureDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { selectedRows, meter, onComplete, validationRef } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addMeasurementMeter } = meterStore.measurementService;
  const { updateMeterConfig } = meterStore.deviceService;

  const [today] = useState(startOfDay(moment()));
  const [mutationDateTime, setMutationDateTime] = useState(moment(MIN_DATE));
  const [meterIds, setMeterIds] = useState<string[]>([]);

  const mapChannels = (m: MeterType) => {
    const res: NodeValueType[] = [];

    if (m.channels.length > 0) {
      m.channels.forEach((chann) => {
        // Filter out closed channels
        if (betweenDates(moment(chann.startDateTime), moment(chann.endDateTime), today)) {
          res.push({
            meterId: m.id,
            meterName: formatActiveMeterText(m),
            mutationDateTime,
            description: chann.description,
            dataFrequency: chann.dataFrequency,
            incrementationType: chann.incrementationType,
            meteringType: chann.meteringType,
            utilityType: chann.utilityType,
            direction: chann.direction,
            unitOfMeasure: chann.unitOfMeasure,
            timeOfUse: chann.timeOfUse,
            addingMeasurement: false,
            value: '0',
            channelId: chann.externalIdentifier,
            relationExternalReference: chann.relationExternalReference,
            hour: '0',
            minute: '0'
          });
        }
      });
    } else {
      res.push({
        meterId: m.id,
        meterName: formatActiveMeterText(m),
        mutationDateTime,
        addingMeasurement: false,
        relationExternalReference: '',
        value: '0',
        hour: '0',
        minute: '0'
      });
    }

    return res;
  };

  const createInitialValues = () => {
    let initialValues: NodeValueType[] = [];

    if (selectedRows) {
      selectedRows.forEach((row) => {
        const nodeValue = mapChannels(row.__meterEntity);
        initialValues.push(...nodeValue);
      });
    } else if (meter) {
      initialValues = mapChannels(meter);
    }

    return initialValues;
  };

  const [nodeValues, setNodeValues] = useState<NodeValueType[]>(createInitialValues());

  const checkNodeValues = () => {
    const validNodes = nodeValues.filter((nodeVal) => {
      return (
        (nodeVal.value || nodeVal.value === '0') &&
        nodeVal.description &&
        nodeVal.dataFrequency &&
        nodeVal.incrementationType &&
        nodeVal.direction
      );
    });

    return validNodes.length === nodeValues.length;
  };

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!checkNodeValues() || isMinDate(mutationDateTime) || nodeValues.length === 0);
    }
  };

  useEffect(() => {
    validate();
  }, [mutationDateTime, nodeValues]);

  useEffect(() => {
    const ids = nodeValues.map((val) => val.meterId);
    setMeterIds(ids.filter(onlyUnique));
  }, [nodeValues]);

  /**
   *  There might be a better way to do this
   */
  const nodeValsToTemplates = () => {
    const results: UpdateMeterRequestType[] = [];

    for (const meterId of meterIds) {
      const channels = nodeValues.filter((val) => val.meterId === meterId);

      const channelTemplates = channels.map((chann) => {
        return {
          description: chann.description || '',
          dataFrequency: chann.dataFrequency || dataFrequency.na,
          incrementationType: chann.incrementationType || incrementationType.na,
          meteringType: chann.meteringType || meteringType.none,
          utilityType: chann.utilityType || utilityType.none,
          direction: chann.direction || direction.na,
          unitOfMeasure: chann.unitOfMeasure || unitOfMeasure.none,
          timeOfUse: chann.timeOfUse || '',
          externalIdentifier: '',
          relationExternalReference: chann.relationExternalReference || ''
        };
      });

      results.push({
        meterId: meterId,
        meterType: meter?.meterType || ('individual' as meterType),
        channelTemplates
      });
    }

    return results;
  };

  const nodeValsToMeasurements = (meterId: string) => {
    const result: AddMeasurementRequestType[] = [];

    nodeValues.forEach((value) => {
      if (value.addingMeasurement && value.meterId === meterId) {
        result.push({
          endDateTime: mutationDateTime.toISOString(),
          value: parseFloat(value.value),
          channelId: value.channelId || '',
          hour: value.hour,
          minute: value.minute,
          meter: { id: value.meterId } as MeterType
        });
      }
    });

    return result;
  };

  const addMeasurements = (updatedMeter: MeterType) => {
    // Add measurements
    const measurements = nodeValsToMeasurements(updatedMeter.id);

    let matchingMeter: MeterType | undefined = meter;

    if (selectedRows) {
      const match = selectedRows.find((m) => {
        return m.__meterEntity.serialNumber === updatedMeter.serialNumber;
      });

      if (match) {
        matchingMeter = match.__meterEntity;
      }
    }

    if (measurements && measurements.length > 0) {
      Promise.all(
        measurements.map((measurement) => {
          // Filter out the updated channel, we need its id to post the measurement
          const updatedChannel = updatedMeter.channels.find(
            (chann) =>
              matchingMeter && !matchingMeter.channels.some((c) => c.externalIdentifier === chann.externalIdentifier)
          );

          if (updatedChannel) {
            // Override channelId with the new one
            measurement.channelId = updatedChannel.externalIdentifier;
          }

          measurement.endDateTime = clone(mutationDateTime.add(1, 'days')).toISOString();

          return addMeasurementMeter(measurement)
            .then(() => {
              notify.success({
                content: getTranslation('actions.meter.add_measurement_success')
              });
            })
            .catch((error) => {
              notify.error({
                content: getTranslation('actions.meter.add_measurement_error'),
                error
              });
            });
        })
      );
    }
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      // Update meter(s)
      const channelTemplates = nodeValsToTemplates();

      await Promise.all(
        channelTemplates.map((template) => {
          return updateMeterConfig(template.meterId, template.channelTemplates, mutationDateTime, template.meterType)
            .then((updatedMeter) => {
              addMeasurements(updatedMeter);

              if (onComplete) {
                onComplete(updatedMeter);
              }
              notify.success({
                content: getTranslation('actions.meter.update_success')
              });
            })
            .catch((error) => {
              // Collecting errors in array and checking afterwards doesn't want to work (array evaluated just now), array length is always 0
              notify.error({
                content: getTranslation('actions.meter.update_failed'),
                error
              });
            });
        })
      );
    }
  }));

  return (
    <div className={css['multivalue-wrapper']}>
      <Paragraph>{getTranslation('actions.meter.reconfigure_date')}</Paragraph>

      <div className={classNames(css['margin-top'], css['mutation-date'])}>
        <DatePicker
          id="mutation-date-time"
          onChange={(val) => setMutationDateTime(val)}
          value={mutationDateTime}
          placeholder={getTranslation('actions.meter.reconfigure_date_label')}
          error={isMinDate(mutationDateTime)}
          clearText={getTranslation('datepicker.clear')}
        />
      </div>

      <div className={css['margin-top']}>
        <ReconfigureMultiValue
          meter={meter}
          mutationDateTime={mutationDateTime}
          nodeValues={nodeValues}
          setNodeValues={setNodeValues}
        />
      </div>
    </div>
  );
});

export default observer(ReconfigureDialog);
