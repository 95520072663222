import React from 'react';

import { RegenerateInvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import RegenerateDialog from '../dialogs/regenerate-dialog';

type Props = {
  invoiceId: string;
};

export default function RegenerateListItem(props: Props) {
  const { invoiceId } = props;

  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();
  const { invoiceStore } = useStore();

  const invoiceToHandle: RegenerateInvoiceType = {
    __id: invoiceId
  };

  if (invoiceStore.pageActionPermissions.mayRegenerate) {
    return (
      <DropdownAction
        id="invoice.regenerate"
        icon="regenerate"
        dialogTitle={i18n.getTranslation('actions.regenerate')}
        dialogContent={<RegenerateDialog invoiceToHandle={invoiceToHandle} ref={clickRef} />}
        onSubmit={onSubmit}
        buttonPositive={i18n.getTranslation('actions.regenerate')}
      />
    );
  }

  return null;
}
