import clone from 'clone';

import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { UpdateIncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import {
  CalculationConfigurationBillingItemType,
  PropertyGroupBillingConfigurationType,
  PropertyGroupCalculationConfigurationType,
  RequiredIncomingInvoiceComponentsType
} from '@zf/api-types/property-group-billing-configuration';
import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';

import { useAppContext } from '../../../../../app-context';
import { METHODS, sendRequest } from '../../../../../utils/request';
import { useTracked } from '../billing-context/context';

export type PropertyGroupBillingConfigurationRequestType = {
  requiredIncomingInvoiceComponents: RequiredIncomingInvoiceComponentsType[];
  calculationConfigurations: PropertyGroupCalculationConfigurationType[];
};

export default function useBillingData() {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer } = useAppContext();

  // Set actions
  const setSelectedBillingItem = (item: CalculationConfigurationBillingItemType) => {
    dispatch({ type: 'SET_SELECTED_BILLING_ITEM', billingItem: item });
  };

  const setSelectedBillingPeriod = (period: PropertyGroupBillingPeriodType) => {
    dispatch({ type: 'SET_SELECTED_BILLING_PERIOD', period: period });
  };

  const setPropertyGroupBillingConfiguration = (newConfig: PropertyGroupBillingConfigurationType) => {
    dispatch({ type: 'SET_PROPERTY_BILLING_CONFIG', config: newConfig, isFetch: false });
  };

  const setBillingPeriods = (billingPeriods: PropertyGroupBillingPeriodType[]) => {
    dispatch({ type: 'SET_BILL_PERIODS', billingPeriods: billingPeriods, isFetch: false });
  };

  const setSelectedBillingItemFormulaType = (type: 'quantity' | 'price') => {
    dispatch({ type: 'SET_SELECTED_BILLING_ITEM_FORMULA_TYPE', selectedBillingItemFormulaType: type });
  };

  const addIncomingInvoiceToState = (incomingInvoice: IncomingInvoiceType) => {
    dispatch({
      type: 'SET_INC_INVOICES',
      incomingInvoices: [...state.incomingInvoices, incomingInvoice],
      isFetch: false
    });
  };

  const updateBillingPeriodsInState = (billingPeriods: PropertyGroupBillingPeriodType[]) => {
    // Only replace the updated ones
    const filtered = state.billingPeriods.filter((bp) => {
      return !billingPeriods.some((period) => {
        return period.id === bp.id;
      });
    });

    dispatch({
      type: 'SET_BILL_PERIODS',
      billingPeriods: [...filtered, ...billingPeriods],
      isFetch: false
    });
  };

  const updateBillingPeriod = (period: PropertyGroupBillingPeriodType) => {
    dispatch({
      type: 'UPDATE_BILLING_PERIOD',
      period: period
    });
    dispatch({
      type: 'SET_SELECTED_BILLING_PERIOD',
      period: period
    });
  };

  const updateIncomingInvoice = (invoice: IncomingInvoiceType) => {
    dispatch({
      type: 'UPDATE_INC_INVOICE',
      incomingInvoice: invoice
    });
  };

  const updateIncomingInvoiceComponents = (components: UpdateIncomingInvoiceComponentType[]) => {
    if (state.propertyGroupBillingConfiguration) {
      const configClone = clone(state.propertyGroupBillingConfiguration);
      configClone.requiredIncomingInvoiceComponents = components;

      dispatch({
        type: 'SET_PROPERTY_BILLING_CONFIG',
        config: configClone,
        isFetch: false
      });
    }
  };

  const deleteBillingPeriod = (id: string) => {
    dispatch({
      type: 'DELETE_BILLING_PERIOD',
      id: id
    });
    dispatch({
      type: 'SET_SELECTED_BILLING_PERIOD',
      period: undefined
    });
  };

  const deleteIncomingInvoice = (id: string) => {
    dispatch({
      type: 'DELETE_INC_INVOICE',
      id: id
    });
    dispatch({
      type: 'SET_SELECTED_INC_INVOICE',
      id: ''
    });
  };

  const setSelectedInvoice = (id: string) => {
    dispatch({
      type: 'SET_SELECTED_INC_INVOICE',
      id: id
    });
  };

  const submitAllocationGroup = async (apiFriendlyValues: PropertyGroupBillingConfigurationRequestType) => {
    return (
      await sendRequest<PropertyGroupBillingConfigurationType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/PropertyGroupBillingConfiguration/${state.propertyGroupBillingConfiguration?.propertyGroup?.id}/costallocation`,
          data: apiFriendlyValues
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  const addAllocationGroupsToState = (allocationGroups: PropertyGroupCalculationConfigurationType[] | null) => {
    if (state.propertyGroupBillingConfiguration && allocationGroups) {
      const billingConfigClone = clone(state.propertyGroupBillingConfiguration);
      billingConfigClone.calculationConfigurations = [...allocationGroups];
      setPropertyGroupBillingConfiguration(billingConfigClone);
    }
  };

  const updateAllocationGroupBillingItems = async (
    updatedAllocationGroup: PropertyGroupCalculationConfigurationType
  ) => {
    if (state.propertyGroupBillingConfiguration) {
      const backupGroup = state.propertyGroupBillingConfiguration.calculationConfigurations?.find(
        (g) => g.id === updatedAllocationGroup.id
      );
      let billingItems_: CalculationConfigurationBillingItemType[] = [];

      if (backupGroup) {
        updatedAllocationGroup.billingItems.forEach((item) => {
          const foundItem = backupGroup.billingItems.find((b) => {
            return b.billingItemId === item.billingItemId;
          });

          if (foundItem) {
            // Existing on group
            billingItems_.push({
              billingItemId: foundItem.billingItemId,
              deviatingTariffCalculation: foundItem.deviatingTariffCalculation,
              tariffFormula: foundItem.tariffFormula
            });
          } else {
            // New items
            billingItems_.push({
              billingItemId: item.billingItemId,
              deviatingTariffCalculation: false,
              tariffFormula: null
            });
          }
        });

        const groupsClone = clone(state.propertyGroupBillingConfiguration.calculationConfigurations) || [];

        const indexToReplace = groupsClone.findIndex((g) => g.name === updatedAllocationGroup.name);

        groupsClone[indexToReplace] = {
          id: updatedAllocationGroup.id,
          name: updatedAllocationGroup.name,
          serviceLocationIds: updatedAllocationGroup.serviceLocationIds,
          billingItems: billingItems_
        };

        const apiFriendlyValues = {
          requiredIncomingInvoiceComponents: [],
          calculationConfigurations: groupsClone
        };

        const updatedBillingConfig = await submitAllocationGroup(apiFriendlyValues);
        addAllocationGroupsToState(updatedBillingConfig.calculationConfigurations);
      }
    }
  };

  return {
    propertyGroupBillingConfiguration: state.propertyGroupBillingConfiguration,
    propertyMeteringConfiguration: state.propertyMeteringConfiguration,
    billingItems: state.billingItems,
    taxCodes: state.taxCodes,
    consumptionUnitTypes: state.consumptionUnitTypes,
    customEntityPropertyTypes: state.customEntityPropertyTypes,
    calculationTypes: state.calculationTypes,
    billingPeriods: state.billingPeriods,
    incomingInvoices: state.incomingInvoices,
    incomingInvoiceComponents: state.incomingInvoiceComponents,
    selectedBillingItem: state.selectedBillingItem,
    selectedBillingItemFormulaType: state.selectedBillingItemFormulaType,
    selectedBillingPeriod: state.selectedBillingPeriod,
    selectedIncomingInvoice: state.selectedIncomingInvoice,
    setSelectedBillingItem,
    setSelectedBillingItemFormulaType,
    setPropertyGroupBillingConfiguration,
    setBillingPeriods,
    setSelectedBillingPeriod,
    addIncomingInvoiceToState,
    updateBillingPeriodsInState,
    setSelectedInvoice,
    updateBillingPeriod,
    deleteBillingPeriod,
    deleteIncomingInvoice,
    updateIncomingInvoiceComponents,
    updateIncomingInvoice,
    updateAllocationGroupBillingItems,
    addAllocationGroupsToState,
    submitAllocationGroup
  };
}
