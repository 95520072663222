import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { convertToMoment } from '@zf/utils/src/date';

import DateRangePicker from '../../../../../components/input/DateRangePicker';
import { TabSlider } from '../../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../../design-system/Components/TabSlider/Tab';
import { useStore } from '../../../../../hooks/useStore';
import { balanceDataType } from '../../stores/BalanceStore';

const BalanceCardFilters = () => {
  const { applicationStore, prepaymentDeviceStore } = useStore();
  const { getTranslation } = applicationStore;
  const { filterStore, dataType, setDataType } = prepaymentDeviceStore.balanceStore;
  const { queryParams, setQueryParam } = filterStore;

  const [tabItems] = useState<SelectionTabItemType<any>[]>([
    { id: balanceDataType.topUp, icon: 'coin', title: getTranslation('prepayment_device.top_ups') },
    {
      id: balanceDataType.balance,
      icon: 'ajust-balance',
      title: getTranslation('prepayment_device.balance')
    }
  ]);

  return (
    <>
      <TabSlider
        id="data-type"
        tabItems={tabItems}
        selectedTabItem={dataType}
        setSelectedItem={(id) => setDataType(id as balanceDataType)}
      />
      <DateRangePicker
        id="balance-period"
        startDate={convertToMoment(queryParams.startDateTime)}
        endDate={convertToMoment(queryParams.endDateTime)}
        setDates={(dates) =>
          setQueryParam({ startDateTime: dates[0]?.toISOString(), endDateTime: dates[1]?.toISOString() })
        }
        isWizardRangePicker
      />
    </>
  );
};

export default observer(BalanceCardFilters);
