import React from 'react';

import { PaymentRowType, PaymentType, RevertPaymentType } from '@zf/api-types/payments';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import MultiLineInput from '../../components/input/MultiLineInput';
import { notify } from '../../events/notification-events';
import css from './manually-reverse-dialog.module.scss';
import { revertPayment } from './payments';

type Props = {
  onComplete: (updatedRows: PaymentType[]) => void;
  selectedRows?: Array<PaymentRowType>;
  paymentToHandle?: RevertPaymentType;
  refreshCounts?: () => void;
};

const ManuallyReverseDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, paymentToHandle, refreshCounts } = props;
  const { i18n, tenantReducer } = useAppContext();

  const [reversalAdditionalInformation, setReversalAdditionalInformation] = React.useState('');

  let paymentssToHandle: Array<RevertPaymentType> = [];

  if (selectedRows) {
    paymentssToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (paymentToHandle) {
    paymentssToHandle = [paymentToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (paymentssToHandle.length > 0) {
        try {
          const newPayments = await Promise.all(
            paymentssToHandle.map((payment) =>
              revertPayment(payment, reversalAdditionalInformation, tenantReducer, i18n.lang)
            )
          );

          onComplete(newPayments);
          if (refreshCounts) refreshCounts();

          notify.success({
            content: i18n.getTranslation('actions.payments.revert_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.payments.revert_fail'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <>
      <Paragraph>{i18n.getTranslation('actions.payments.reversal_info_heading')}</Paragraph>
      <InputContainer className={css['reverse-style']}>
        <MultiLineInput
          id="payment.reverse"
          value={reversalAdditionalInformation}
          onChange={setReversalAdditionalInformation}
          placeholder={i18n.getTranslation('payments.reversal_reason')}
        />
      </InputContainer>
    </>
  );
});

export default ManuallyReverseDialog;
