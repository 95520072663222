import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import useTimeout from '@zf/hooks/src/useTimeout';
import useTransition from '@zf/hooks/src/useTransition';

import { Button, Link } from '../';
import { Icon } from '../../Foundation';
import css from './notification.module.scss';

export type NotificationType = 'info' | 'success' | 'warning' | 'error';

export type NotificationPropsType = {
  children: React.ReactNode;
  type: NotificationType;
  timeout?: number;
  onClose: () => void;

  // Extras
  linkText?: string;
  linkUrl?: string;
  buttonIcon?: string;
  buttonText?: string;
  icon?: string;
  onButtonClick?: Function;
};

type PlayState = 'running' | 'paused';

const iconMapping: Record<NotificationType, string> = {
  info: 'info-circle',
  success: 'info-circle',
  warning: 'alert-circle',
  error: 'alert-circle'
};

export default function Notification(props: NotificationPropsType) {
  const {
    children,
    timeout,
    linkText,
    linkUrl,
    buttonIcon,
    buttonText,
    onButtonClick,
    icon,
    type = 'info',
    onClose
  } = props;

  const [playState, setPlayState] = useState<PlayState>('running');
  const [toggle, setToggle] = useState(true);

  const { toggledStyle } = useTransition({
    styleEnter: css['enter'],
    styleExit: css['exit'],
    toggle: toggle
  });

  const closeNotification = () => {
    setToggle(false);
    setTimeout(() => onClose(), 500);
  };

  const { pause, resume } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    timeout !== 0 ? useTimeout(() => closeNotification(), timeout) : { pause: () => {}, resume: () => {} };

  useEffect(() => {
    playState === 'running' ? resume() : pause();
  }, [playState]);

  const handleMouseEnter = () => {
    setPlayState('paused');
  };

  const handleMouseLeave = () => {
    setPlayState('running');
  };

  return (
    <div
      id="notification"
      className={classNames(css['notification-container'], toggledStyle, {
        [css['notification-info']]: type === 'info',
        [css['notification-success']]: type === 'success',
        [css['notification-warning']]: type === 'warning',
        [css['notification-error']]: type === 'error'
      })}
      onClick={closeNotification}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={css['notification']}>
        <Icon className={css['notification-icon']} name={icon || iconMapping[type]} />
        <div>{children}</div>
        {linkUrl && (
          <Link id={`link-${linkUrl}`} url={linkUrl} newTab>
            {linkText}
          </Link>
        )}
        {onButtonClick && (
          <Button id="notification-action" type="text" size="small" icon={buttonIcon} onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}
