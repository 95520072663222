import React from 'react';

import { invoiceStatus } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import SendInvoiceDialog from '../dialogs/resend-dialog';

export default function SendInvoiceListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<InvoiceType>('invoice');
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.find((invoice) => invoice.__entity.status !== invoiceStatus.generated) !== undefined;

  return (
    <DropdownAction
      id="invoice.sendinvoice"
      icon="paper-plane"
      dialogTitle={i18n.getTranslation('actions.resend')}
      dialogContent={<SendInvoiceDialog selectedRows={selectedRows} ref={clickRef} onComplete={setUpdatedRows} />}
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('communication.send')}
    />
  );
}
