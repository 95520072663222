import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { EntitySubjectSubtype, EntitySubjectUnionType } from '@zf/api-types/config/scenarios_new';
import useDialog from '@zf/hooks/src/useDialog';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import Button from '../../../../../components/Button/Button';
import { dialog } from '../../../../../events/dialog-events';
import useSingleAPI from '../../../../../hooks/useSingleAPI';
import { useStore } from '../../../../../hooks/useStore';
import ResetDialog from './ResetDialog';
import css from './translation-options.module.scss';

type Props = {
  rowIndex: number;
};

const TranslationOptions = (props: Props) => {
  const { rowIndex } = props;
  const { applicationStore, communicationStore } = useStore();
  const { isDisabled, updateAllTranslations, setUpdateAllTranslations, selectedTranslation, translationIsDirty } =
    communicationStore.translationStore;
  const { entitySubjectTypeMap } = communicationStore.templatesStore;
  const { onSubmit, clickRef } = useDialog();

  useEffect(() => {
    setUpdateAllTranslations(false);
  }, []);

  type BeingUsedInType = {
    entitySubjectType: EntitySubjectUnionType;
    entitySubjectSubType: EntitySubjectSubtype;
  };

  const translationBeingUsed = useSingleAPI<PagedResponseType<BeingUsedInType>>({
    request: {
      endpoint: `/cfg/scenarioTranslations/${selectedTranslation.name}/getscenarios`
    }
  });

  const beingUsedIn = translationBeingUsed.result?.data.results || [];

  const openResetDialog = (rowIndex: number) => {
    dialog.normal({
      stackOrder: 1,
      title: applicationStore.getTranslation('general.reset'),
      icon: 'reset',
      content: <ResetDialog ref={clickRef} rowIndex={rowIndex} isCrossTemplate={beingUsedIn.length > 0} />,
      buttonPositive: applicationStore.getTranslation('general.reset'),
      type: 'danger',
      onSubmit
    });
  };

  return (
    <>
      {beingUsedIn.length > 0 && (
        <CheckBox id="write_off_fees" checked={updateAllTranslations} onChange={setUpdateAllTranslations}>
          <Label color={colors['silver-700']}>
            {applicationStore.getTranslation('communication.use_this_translation')}
          </Label>
          <Paragraph color={colors['silver-700']}>
            <>
              {applicationStore.getTranslation('communication.use_this_translation_in')} <br />
              {beingUsedIn.map((e) =>
                entitySubjectTypeMap[e.entitySubjectType].map((i) =>
                  i.value === e.entitySubjectSubType ? `.${i.text}` : ''
                )
              )}
            </>
          </Paragraph>
        </CheckBox>
      )}

      <Button
        id="icon-button-resetToDefault"
        className={css['reset-btn']}
        type="text"
        onClick={() => openResetDialog(rowIndex)}
        icon="reset"
        disabled={isDisabled || translationIsDirty}
      >
        {applicationStore.getTranslation('communication.reset_default_translation')}
      </Button>
    </>
  );
};

export default observer(TranslationOptions);
