import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';

import { useAppContext } from '../../../app-context/app-context';
import { TabSlider } from '../../../design-system/Components';

type Props = {
  isCompareMode: boolean;
  groupByPeriod: aggregationFrequency;
  setGroupByPeriod: (newGroupBy: string) => void;
};

export default function GroupByTabs(props: Props) {
  const { isCompareMode, groupByPeriod, setGroupByPeriod } = props;
  const { i18n } = useAppContext();

  return (
    <TabSlider
      id="group-by"
      tabItems={
        isCompareMode
          ? [
              { id: aggregationFrequency.yearly, title: i18n.getTranslation('measurement.year') },
              { id: aggregationFrequency.monthly, title: i18n.getTranslation('measurement.month') }
            ]
          : [
              { id: aggregationFrequency.none, title: i18n.getTranslation('general.all') },
              { id: aggregationFrequency.yearly, title: i18n.getTranslation('measurement.year') },
              { id: aggregationFrequency.monthly, title: i18n.getTranslation('measurement.month') },
              { id: aggregationFrequency.daily, title: i18n.getTranslation('measurement.day') }
            ]
      }
      selectedTabItem={groupByPeriod}
      setSelectedItem={setGroupByPeriod}
    />
  );
}
