import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ParameterTypesType } from '@zf/api-types/general';
import { BillingItemType } from '@zf/api-types/product';
import { TaxCodeType } from '@zf/api-types/tax-codes';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { WrappableVirtualizedList } from '../../design-system/ComponentSets';
import VirtualBillingItem from '../../features/product-config/shared/billing-item/VirtualBillingItem';
import { useStore } from '../../hooks/useStore';
import css from './add-billing-items-dialog.module.scss';

type Props<B> = {
  billingItems: BillingItemType[];
  listedBillingItems: B[];
  calculationTypes: ParameterTypesType[];
  customEntityPropertyTypes: CustomEntityPropertyType[];
  taxCodes: TaxCodeType[];
  updateSelection: (billingItem: BillingItemType, newItem: B) => void;
  generateNewEntityBillingItem: (billingItem: BillingItemType) => B;
};

const NoBillingItems = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/attributes2.png"
          alt="location"
          title="location"
          width="70"
          height="70"
        />
      }
      title={getTranslation('billing_items.empty_state_title')}
    >
      {getTranslation('billing_items.empty_state_descr')}
    </CardEmptyBody>
  );
};

export default function DialogBillingItems<B extends { billingItemId: string }>(props: Props<B>) {
  const {
    billingItems,
    listedBillingItems,
    calculationTypes,
    customEntityPropertyTypes,
    taxCodes,
    updateSelection,
    generateNewEntityBillingItem
  } = props;

  if (billingItems.length === 0) return <NoBillingItems />;

  const items = billingItems.map((b, index) => {
    return {
      key: `${b.id}-${index}`,
      index,
      billingItem: b,
      taxCodes,
      customEntityPropertyTypes,
      calculationTypes,
      onClick: () => updateSelection(b, generateNewEntityBillingItem(b)),
      isSelected: listedBillingItems.some((pbi) => pbi.billingItemId === b.id),
      showTariffs: false,
      showRadioBtn: true
    };
  });

  return (
    <div className={css['items-grid']}>
      <WrappableVirtualizedList items={items} ItemComponent={VirtualBillingItem} columnWidth={400} />
    </div>
  );
}
