import React from 'react';

import Icon from './Icon';
import css from './card-header-icon.module.scss';

type Props = {
  type: string;
};

export default function CardHeaderIcon(props: Props) {
  const { type } = props;

  return <Icon className={css['card-header-icon']} type={type} />;
}
