import { ICON_COLORS } from 'constants/icons';
import { Paragraph } from 'design-system/Foundation';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { getServiceStatusFromContractedService } from 'utils/serviceState';

import { utilityType } from '@zf/api-types/enums';
import { ContractServiceType } from '@zf/api-types/forecasting';
import { ContractServiceLocation } from '@zf/api-types/master-data/contract';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { groupBy, onlyUniqueObjects } from '@zf/utils/src/array';
import { formatPeriod } from '@zf/utils/src/date';

import { Icon } from '../../components/Icon';
import { Link } from '../../design-system/Components';
import { useStore } from '../../hooks/useStore';
import YearlyEstimates from '../Services/YearlyEstimates';
import css from './active-service.module.scss';

type Props = {
  serviceLocation: ContractServiceLocation;
  contractServicesForLocation: ContractServiceType[];
  closeDialog?: () => void | undefined;
};

const ActiveService = (props: Props) => {
  const { serviceLocation, contractServicesForLocation, closeDialog } = props;
  const { applicationStore } = useStore();
  const { rootUrl } = applicationStore;
  const { getEnumTranslation } = applicationStore;

  const contractServicesGroupedByUtility: Record<utilityType, ContractServiceType[]> = groupBy(
    onlyUniqueObjects(contractServicesForLocation),
    'utilityType'
  );

  return (
    <Fragment>
      <CardItem width="12">
        <Link
          id={`link-${serviceLocation.id}`}
          className={css['address']}
          url={`${rootUrl}/locations/${serviceLocation.id}`}
          onNavigate={closeDialog || undefined}
          bold
        >
          {serviceLocation.address?.localizedDisplay}
        </Link>
      </CardItem>

      <FlexElements flexDirection="column" gap="16">
        {serviceLocation.services?.map((s, index) => {
          if (!s.utilityType) return null;

          const matchingContractServices = contractServicesGroupedByUtility[s.utilityType];
          const status = getServiceStatusFromContractedService(s);

          return (
            <Fragment key={`service-location-${index}`}>
              <CardItem width="12" key={index}>
                <FlexElements className={css['full-width']} gap="12" alignItems="flex-start">
                  <div className={css['icon-utilityType']}>
                    <Icon
                      id={s.utilityType}
                      title={getEnumTranslation('utilityType', s.utilityType)}
                      type={s.utilityType}
                      color
                    />
                  </div>

                  <div className={css['full-width']}>
                    <FlexElements justifyContent="space-between" alignItems="flex-start">
                      <div>
                        <Paragraph textAlign="left" bold>
                          {getEnumTranslation('utilityType', s.utilityType)}
                        </Paragraph>
                        <div className={css['service-period']}>{formatPeriod(s.startDateTime, s.endDateTime)}</div>
                      </div>

                      <NewStatusBadge
                        status_={getEnumTranslation('serviceStatus', status)}
                        color={ICON_COLORS[status]}
                      />
                    </FlexElements>

                    {matchingContractServices && (
                      <YearlyEstimates matchingContractServices={matchingContractServices} />
                    )}
                  </div>
                </FlexElements>
              </CardItem>
            </Fragment>
          );
        })}
      </FlexElements>
    </Fragment>
  );
};

export default observer(ActiveService);
