import React from 'react';

import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../../app-context';
import useCurrent from '../../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../../design-system/ComponentSets';
import BlockContractDialog from '../../dialogs/BlockDialog';

export default function BlockUnblockListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('contract');
  const selectedRows: ContractRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<ContractType>('contract');
  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    !!selectedRows.find((contract) => !contract || (contract && contract.__currentContractStatus !== 'signed'));

  return (
    <DropdownAction
      id="contract.block"
      icon="lock"
      dialogTitle={i18n.getTranslation('contracts.block_unblock_title')}
      dialogContent={
        <BlockContractDialog
          setUpdatedRows={setUpdatedRows}
          selectedRows={selectedRows}
          ref={clickRef}
          validationRef={validationRef}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      dialogType="danger"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.confirm')}
    />
  );
}
