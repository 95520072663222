import classNames from 'classnames';
import React, { forwardRef, useRef } from 'react';

import css from './card.module.scss';

export type CardWidthType = '1' | '2' | '3' | '4';

type Props = {
  id?: string;
  children?: React.ReactNode;
  width?: CardWidthType;
  className?: string;
  type?: 'transparent' | 'dark' | 'default';
  border?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  dataTestId?: string;
  role?: string;
};

export default forwardRef(function Card(props: Props, ref) {

  const {
    id,
    children,
    width = '1',
    className,
    type = 'default',
    border = true,
    disabled = false,
    style,
    dataTestId,
    role,
  } = props;

  const card = (
    <div
      id={id}
      //@ts-ignore
      ref={ref}
      className={classNames(className, css['card'], css[`card-${width}`], {
        [css[type]]: type !== 'default',
        [css['border']]: border
      })}
      style={style}
      data-testid={dataTestId}
      role={role}
    >
      {children}
    </div>
  );

  return disabled ? (
    <div className={classNames(css['overlay-wrapper'], css[`card-${width}`])}>
      <div className={classNames(css['overlay'], css[`card-${width}`])} />
      {card}
    </div>
  ) : (
    card
  );
})