import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { BaseLink } from '../';
import { Icon, Paragraph } from '../../Foundation';
import { TextAlignment } from '../../Foundation/Paragraph/Paragraph';
import css from './link.module.scss';

export type LinkProps = {
  id?: string;
  dataTestId?: string;
  url: string;
  children: ReactNode;
  className?: string;
  bold?: boolean;
  sub?: boolean;
  /**
   * Name of the icon
   */
  icon?: string;
  iconColor?: boolean | string;
  /**
   * Open page in new tab
   */
  newTab?: boolean;
  /**
   * In case we reuse components containing links but don't want to navigate from there
   */
  noNavigation?: boolean;
  /**
   * Function to execute while navigating e.g. state update
   */
  onNavigate?: Function;
  alignEmptyDash?: TextAlignment;
};

export default function Link(props: LinkProps) {
  const {
    id,
    url,
    children,
    icon,
    className,
    iconColor,
    alignEmptyDash = 'right',
    bold = false,
    sub = false,
    newTab = false,
    noNavigation = false,
    onNavigate,
    dataTestId
  } = props;

  if (noNavigation) {
    return <Paragraph id={id}>{children}</Paragraph>;
  }

  const link = children ? (
    <BaseLink
      id={id}
      className={classNames(css['link'], { [css['bold']]: bold, [css['sub']]: sub }, className)}
      url={url}
      newTab={newTab}
      onNavigate={onNavigate}
      dataTestId={dataTestId}
    >
      {children}
    </BaseLink>
  ) : (
    <Paragraph textAlign={alignEmptyDash} id={id}>
      {children}
    </Paragraph>
  );

  return icon ? (
    <div className={classNames(css['icon-link'], { [css['sub']]: sub })} id={id}>
      {children && <Icon name={icon} color={iconColor} />}
      {link}
    </div>
  ) : (
    link
  );
}
