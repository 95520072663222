import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { ScenarioTranslation } from '@zf/api-types/config/Communication/templates';
import { translationStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';
import Label from '@zf/stella-react/src/atoms/Label/Label';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';
import { colors, convertHexToRGBA } from '@zf/utils/src/color';
import { getRelativePos } from '@zf/utils/src/object';

import DynamicIndexTable from '../../../../../components/Lang/DynamicIndexTable';
import { dialog } from '../../../../../events/dialog-events';
import { useStore } from '../../../../../hooks/useStore';
import UpdateTemplateTranslationsDialog from '../../scenario/dialogs/update-template-translations-dialog';
import TemplateLanguageIcon from './TemplateLanguageIcon';

const TranslationTable = () => {
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { translations, translationsAreLoading, setSelectedTranslation } = communicationStore.translationStore;

  const { onSubmit, clickRef } = useDialog();

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 2,
      dataKey: 'name',
      width: 800
    },
    {
      flexWidth: 2,
      dataKey: 'languageAction',
      width: 200
    }
  ]);

  const openTranslationsDialog = (rowIndex: number, scenarioTranslation: ScenarioTranslation) => {
    dialog.right({
      stackOrder: 2,
      title: `Translation for ${scenarioTranslation.name}`,
      content: <UpdateTemplateTranslationsDialog ref={clickRef} rowIndex={rowIndex} />,
      buttonPositive: getTranslation('general.save'),
      onSubmit
    });
  };

  const setSelectedRow = useCallback(
    (rowIndex: number) => {
      if (translations) {
        setSelectedTranslation(translations[rowIndex]);
        openTranslationsDialog(rowIndex, translations[rowIndex]);
      }
    },
    [translations]
  );

  const assetRows =
    translations?.map((asset: ScenarioTranslation, index: number) => {
      return {
        name: <Label>{asset.name}</Label>,
        languageAction: (
          <TemplateLanguageIcon
            status={asset.status}
            id={`asset.update_language.index-${asset.name}`}
            tooltipFor="assets-table"
          />
        )
      };
    }) || [];

  const handleHover = (e: number) => {
    if (translations) {
      const iframe = document.getElementById('email-preview') as HTMLIFrameElement | null;
      const content = iframe?.contentWindow || null;
      const elmnt = content?.document;

      const htmlSelector = elmnt?.getElementsByClassName(translations[e].name);

      translations.forEach((e) => {
        const selector = elmnt?.querySelectorAll(`.${e.name}`) as NodeListOf<HTMLElement>;

        selector?.forEach((i) => {
          i.style.backgroundColor = 'transparent';
        });
      });

      if (htmlSelector && iframe) {
        const arr = Array.from(htmlSelector) as HTMLElement[];
        if (translations[e].status === translationStatus.translated) {
          handleScrollPosition(arr, content, iframe, colors['blue-600']);
        } else if (translations[e].status === translationStatus.missingtranslation) {
          handleScrollPosition(arr, content, iframe, colors['red-600']);
        } else {
          handleScrollPosition(arr, content, iframe, colors['green-600']);
        }
      }
    }
  };

  const handleScrollPosition = (
    arr: HTMLElement[],
    content: Window | null,
    iframe: HTMLIFrameElement,
    color: string
  ) => {
    const selectors: HTMLElement[] = [];
    arr.forEach((selector) => {
      selectors.push(selector);
      selector.style.backgroundColor = convertHexToRGBA(color, 40);
      content?.scrollTo({ left: 0, top: 0 });
      content?.scrollTo({ left: 0, top: getRelativePos(iframe, selectors[0]).top });
    });
  };

  return (
    <DynamicIndexTable
      tooltipId="assets-table"
      rows={assetRows}
      columns={tableColumns}
      scrollToIndex={communicationStore.templatesStore.selectedTranslationIndex}
      selectedRow={communicationStore.templatesStore.selectedTranslationIndex}
      setSelectedRow={setSelectedRow}
      onRowMouseOver={handleHover}
      isLoading={translationsAreLoading}
      cursorPointer
      disableHeader
    />
  );
};

export default observer(TranslationTable);
