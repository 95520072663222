import React, { ChangeEvent, ReactNode } from 'react';

import { Subtext } from '../../Foundation';
import css from './radio-input.module.scss';

export type RadioInputProps = {
  onChange: (val: string) => void;
  value: string;
  name: string; // Should be unique across a component, be carefull with enums using the same values!
  checked: boolean;
  children?: ReactNode;
  description?: string;
};

export default function RadioInput(props: RadioInputProps) {
  const { onChange, value, name, children, description, checked } = props;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  return (
    <label className={css['radio-input']}>
      <input onChange={onChangeHandler} type="radio" name={name} value={value} checked={checked} />
      {!!children && (
        <div className={css['text-wrapper']}>
          <div className={css['text']}>{children}</div>
          {!!description && <Subtext>{description}</Subtext>}
        </div>
      )}
    </label>
  );
}
