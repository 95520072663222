import clone from 'clone';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import moment from 'moment';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import {
  billingItemConditionType,
  billingItemTariffCalculationType,
  customEntityPropertyValueType,
  paymentMethod,
  tariffType,
  tierCalculationMethod
} from '@zf/api-types/enums';
import { ParameterTypesType } from '@zf/api-types/general';
import { CultureTableType } from '@zf/api-types/language';
import { booleanOptions, tariffCalculationType } from '@zf/api-types/local-enums';
import {
  BillingItemType,
  BillingTariffNodeType,
  BillingTariffRequestType,
  BillingTariffType,
  ConsumptionUnitType,
  CustomEntityPropertyTypeTariffConditionTypeParameters,
  CustomEntityPropertyTypeTariffConditionTypeParametersDTO,
  ProductAttachmentFileType,
  ProductBillingItemType,
  ProductType,
  TariffConditionTypeParametersDTO,
  TieredTariffCalculationTypeParametersType,
  UnitPriceTariffCalculationTypeParametersType
} from '@zf/api-types/product';
import { TaxCodeType } from '@zf/api-types/tax-codes';
import { betweenDates, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import { formatExpression, formatMoney } from '@zf/utils/src/number';
import { deepEqual } from '@zf/utils/src/object';

import BillingItemsDialogStore from '../../../components/billing-items-dialog/stores/BillingItemsDialogStore';
import { notify } from '../../../events/notification-events';
import { toNumber } from '../../../utils/graph';
import { getBillingItemIsActive } from '../../../utils/product';
import { LocalProductType } from '../products/product-column/dialogs/AddEditProductDialog';
import { TariffValidatorType } from '../shared/tariff-column/dialogs/AddEditTariffDialog';
import TreeNode, { actions } from '../shared/tariff-column/models/TreeNode';
import ProductConfigStore from './ProductConfigStore';

export type CustomEntityConditionNodeType = {
  value: any;
  apiFriendlyValues: TariffConditionTypeParametersDTO;
};

export default class ProductStore {
  private productConfigStore: ProductConfigStore;
  public billingItemsDialogStore: BillingItemsDialogStore<ProductType, ProductBillingItemType>;

  public products_: ProductType[] | undefined;
  public selectedProduct: ProductType | undefined;
  public productBillingItemsBackup_: BillingItemType[] | undefined;
  public productBillingItems_: BillingItemType[] | undefined;
  public selectedBillingItem: BillingItemType | undefined;
  public tariffs_: BillingTariffType[] | undefined;
  public showInactiveBillingItems = true;
  public isValidTree: boolean = false;

  public cultureTable_: CultureTableType | undefined;
  public taxCodes_: TaxCodeType[] | undefined;
  public calculationTypes_: ParameterTypesType[] | undefined;
  public consumptionUnitTypes_: ConsumptionUnitType[] | undefined;
  public customEntityPropertyTypes_: CustomEntityPropertyType[] | undefined;
  public condition: TariffConditionTypeParametersDTO | undefined;
  public treeData: TreeNode[] = [];
  public treeDateBackup: TreeNode[] = [];
  public initialCondition = {
    value: {
      id: billingItemConditionType.paymentmethod,
      type: billingItemConditionType.paymentmethod,
      value: paymentMethod
    },
    text: ''
  };

  constructor(productConfigStore: ProductConfigStore) {
    this.productConfigStore = productConfigStore;
    this.billingItemsDialogStore = new BillingItemsDialogStore(this.productConfigStore.rootStore);
    this.initialCondition.text = this.productConfigStore.rootStore.applicationStore.getTranslation(
      'billing_tariff.default_condition'
    );

    makeAutoObservable(this, {
      products_: observable,
      selectedProduct: observable,
      productBillingItemsBackup_: observable,
      productBillingItems_: observable,
      selectedBillingItem: observable,
      cultureTable_: observable,
      taxCodes_: observable,
      calculationTypes_: observable,
      tariffs_: observable,
      consumptionUnitTypes_: observable,
      showInactiveBillingItems: observable,
      treeData: observable,
      getTree: observable,
      condition: observable,
      isValidTree: observable,

      tierCalcMethod: computed,
      activeTariff: computed,

      setCondition: action,
      setIsValidTree: action,
      initProducts: action,
      initProductBillingItems: action,
      setSelectedProduct: action,
      getValidPeriodsCount: action,
      setSelectedBillingItem: action,
      addProduct: action,
      updateProduct: action,
      updateProductInList: action,
      deleteProduct: action,
      updateProductBillingItems: action,
      initTariffs: action,
      postAttachments: action,
      addTariff: action,
      updateTariff: action,
      updateTariffInList: action,
      deleteTariff: action,
      deleteTariffInlist: action,
      resetTariffs: action,
      setProducts: action,
      getActiveBillingItemsCount: action,
      filterActiveBillingItems: action,
      toggleInactiveBillingItems: action,
      getTariffTreeByPeriod: action,
      switchToEditor: action,
      getTariffCalculationType: action,
      updateProductBillingItemInList: action,
      notifyTreeSaveFailed: action
    });
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get products() {
    return this.products_ as ProductType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get productBillingItems() {
    return this.productBillingItems_ as BillingItemType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get tariffs() {
    return this.tariffs_ as BillingTariffType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get taxCodes() {
    return this.taxCodes_ as TaxCodeType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get calculationTypes() {
    return this.calculationTypes_ as ParameterTypesType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get consumptionUnitTypes() {
    return this.consumptionUnitTypes_ as ConsumptionUnitType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get customEntityPropertyTypes() {
    return this.customEntityPropertyTypes_ as CustomEntityPropertyType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get cultureTable() {
    return this.cultureTable_ as CultureTableType;
  }

  get tierCalcMethod() {
    return this.selectedBillingItem?.tierCalculationMethod;
  }

  get activeTariff() {
    return this.tariffs_?.find((t) => betweenDates(t.startDateTime || MIN_DATE, t.endDateTime || MAX_DATE, moment()));
  }

  initProducts = async (productName?: string, billingItemId?: string) => {
    this.products_ = await this.productConfigStore.productConfigService.getProducts(productName);
    this.cultureTable_ = await this.productConfigStore.rootStore.configStore.configService.getCultureTable();

    // When route parameter is given select this product
    if (productName) {
      const product = this.products_.find((p) => p.name.toLowerCase() === productName.toLowerCase());
      this.setSelectedProduct(product);
      this.initProductBillingItems(product?.id || '', billingItemId);
    }
  };

  setProducts = (newProducts: ProductType[]) => {
    this.products_ = newProducts;
  };

  setCondition = (treeNode: TreeNode, value?: TariffConditionTypeParametersDTO) => {
    this.condition = value;
    treeNode.options = [''];
    if (treeNode.nodes.length > 0) {
      treeNode.options = treeNode.nodes.map((n) => n.name);
    } else {
      treeNode.options = [''];
    }
  };

  switchToEditor = (nodeId: string, billingId: string, productId: string) => {
    this.productConfigStore.setOnlyShowTariffs(true, billingId, productId);
    this.getTariffTreeByPeriod(nodeId);
    this.setIsValidTree(true);
  };

  setIsValidTree = (value: boolean) => {
    this.isValidTree = value;
  };

  filterActiveBillingItems = (productBillingItems: BillingItemType[]) => {
    if (this.selectedProduct) {
      return productBillingItems.filter((pbi) => getBillingItemIsActive(pbi, this.selectedProduct?.billingItems));
    }
  };

  initProductBillingItems = async (productId: string, billingItemId?: string) => {
    const productItems = await this.productConfigStore.productConfigService.getBillingItemsForSelectedProduct(
      productId
    );
    this.productBillingItemsBackup_ = [...productItems];
    this.productBillingItems_ = this.showInactiveBillingItems
      ? [...productItems]
      : this.filterActiveBillingItems(productItems);

    if (billingItemId) {
      const selectedBillingItem = this.productBillingItems.find((b) => b.id === billingItemId);
      this.setSelectedBillingItem(selectedBillingItem);
      this.productBillingItems.sort((a, b) => (b === selectedBillingItem ? 0 : b ? -1 : 1));
    }

    this.taxCodes_ = await this.productConfigStore.rootStore.configStore.configService.getTaxCodes();
    this.calculationTypes_ = await this.productConfigStore.rootStore.configStore.configService.getCalculationTypes();
    this.consumptionUnitTypes_ =
      await this.productConfigStore.rootStore.configStore.configService.getConsumptionUnitTypes();
    this.customEntityPropertyTypes_ =
      await this.productConfigStore.rootStore.configStore.configService.getCustomEntityPropertyTypes();
  };

  initTariffs = async (billingItemId: string, selectedProductId?: string) => {
    const newTariffs = await this.productConfigStore.productConfigService.getTariffsForSelectedBillingItem(
      billingItemId
    );
    await this.getTree(billingItemId, selectedProductId);
    this.tariffs_ = newTariffs;
  };

  toggleInactiveBillingItems = () => {
    this.showInactiveBillingItems = !this.showInactiveBillingItems;

    if (this.productBillingItemsBackup_ && this.selectedProduct) {
      if (!this.showInactiveBillingItems) {
        this.productBillingItems_ = this.filterActiveBillingItems(this.productBillingItemsBackup_);
      } else {
        this.productBillingItems_ = this.productBillingItemsBackup_;
      }
    }
  };

  postAttachments = (productId: string, localProduct: LocalProductType) => {
    return localProduct.attachments.map(async (attachment) => {
      const oldAttachmentFiles: ProductAttachmentFileType[] = [];

      const promises = attachment.attachmentFiles.reduce(
        (acc: Promise<ProductAttachmentFileType>[], att: ProductAttachmentFileType) => {
          const fileIsNotEmpty = att.fileName && att.internalFileName;

          // Only submit new attachments which aren't empty
          if (!att.attachmentId && !!att.file && fileIsNotEmpty) {
            acc.push(this.productConfigStore.productConfigService.postAttachment(productId, att));
          } else if (fileIsNotEmpty) {
            oldAttachmentFiles.push(att);
          }
          return acc;
        },
        []
      );

      const results = await Promise.all(promises);

      return {
        ...attachment,
        attachmentFiles: [...oldAttachmentFiles, ...results]
      };
    }, []);
  };

  addProduct = async (localProduct: LocalProductType) => {
    if (this.products_) {
      const newProduct = await this.productConfigStore.productConfigService.addProduct(
        localProduct.name,
        localProduct.invoiceUpfront
      );
      const newAttachments = await Promise.all(this.postAttachments(newProduct.id, localProduct));
      const updatedProduct = await this.productConfigStore.productConfigService.updateProduct({
        ...newProduct,
        attachments: newAttachments
      });

      // Add to top of list
      this.products_ = [updatedProduct, ...this.products_];
    }
  };

  updateProductInList = (updatedProduct: ProductType) => {
    if (this.products_) {
      const indexToUpdate = this.products_.findIndex((p) => p.id === updatedProduct.id);

      if (indexToUpdate !== -1) {
        this.products_[indexToUpdate] = updatedProduct;
        this.setSelectedProduct(updatedProduct);
      }
    }
  };

  updateProduct = async (product: ProductType, localProduct: LocalProductType) => {
    if (this.products_) {
      const updatedAttachments = await Promise.all(this.postAttachments(product.id, localProduct));
      const updatedProduct = await this.productConfigStore.productConfigService.updateProduct({
        ...product,
        ...localProduct,
        attachments: updatedAttachments
      });

      this.updateProductInList(updatedProduct);
    }
  };

  updateProductBillingItems = async (product: ProductType) => {
    // Creating the order
    const billingItems = product.billingItems.map((item, index) => {
      return {
        ...item,
        billingItemId: item.billingItemId,
        order: index + 2, // start with 1
        periodicityParameters: item.periodicityParameters
      };
    });

    const updatedProduct = await this.productConfigStore.productConfigService.updateProduct({
      ...product,
      billingItems
    });

    this.updateProductInList(updatedProduct);
  };

  updateProductBillingItemInList = (updatedItem: BillingItemType) => {
    if (this.productBillingItems_) {
      const cloned = [...this.productBillingItems_];
      const indexToUpdate = cloned.findIndex((b) => b.id === updatedItem.id);

      if (indexToUpdate !== -1) {
        cloned[indexToUpdate] = { ...updatedItem };
      }

      this.productBillingItems_ = cloned;
      this.setSelectedBillingItem(updatedItem);
    }
  };

  addTariffToList = (newTariff: BillingTariffType) => {
    if (this.tariffs_) {
      // Add to top of list
      this.tariffs_ = [newTariff, ...this.tariffs_];
    }
  };

  addTariff = async (tariffToAdd: BillingTariffRequestType) => {
    return this.productConfigStore.productConfigService.addTariff(tariffToAdd);
  };

  resetTariffs = () => {
    this.tariffs_ = undefined;
  };

  updateTariffInList = (updatedTariff: BillingTariffType) => {
    if (this.tariffs_) {
      const indexToUpdate = this.tariffs_.findIndex((t) => t.id === updatedTariff.id);

      if (indexToUpdate !== -1) {
        this.tariffs_[indexToUpdate] = { ...updatedTariff };
      }
    }
  };

  updateTariff = async (id: string, tariffToUpdate: BillingTariffRequestType) => {
    return this.productConfigStore.productConfigService.updateTariff(id, tariffToUpdate);
  };

  deleteProduct = async (index: number, productId: string) => {
    if (this.products_) {
      await this.productConfigStore.productConfigService.deleteProduct(productId);
      this.products_.splice(index, 1);
      this.selectedProduct = undefined;
    }
  };

  deleteTariff = async (tariffId: string) => {
    await this.productConfigStore.productConfigService.deleteTariff(tariffId);
  };

  deleteTariffInlist = (index: number) => {
    if (this.tariffs_) {
      this.tariffs_.splice(index, 1);
    }
  };

  setSelectedProduct = (product: ProductType | undefined, billingItemId?: string) => {
    this.selectedProduct = product;
    this.billingItemsDialogStore.setUpdatingForEntity(product);
    if (product) {
      this.initProductBillingItems(product.id, billingItemId);
    }

    // Reset other columns
    this.selectedBillingItem = undefined;
    this.tariffs_ = undefined;
  };

  setSelectedBillingItem = (billingItem?: BillingItemType) => {
    this.selectedBillingItem = billingItem;
  };

  getValidPeriodsCount = (productIndex: number) => {
    if (this.products_) {
      return this.products_[productIndex].attachments.filter((pa) =>
        moment().isBetween(pa.validFrom, moment(pa.validUntil).add(1, 'day'), undefined, '[]')
      ).length;
    }

    return 0;
  };

  getActiveBillingItemsCount = (productIndex: number) => {
    if (this.products_) {
      return this.products_[productIndex].billingItems.filter((b) =>
        moment().isBetween(b.startDateTime, moment(b.endDateTime).add(1, 'day'), undefined, '[]')
      ).length;
    }

    return 0;
  };

  conditionToApiModel = (condition: TariffConditionTypeParametersDTO, treeNode: TreeNode) => {
    const conditionParametersFriendlyValues: CustomEntityConditionNodeType[] = [];
    if (condition.id === billingItemConditionType.paymentmethod) {
      Object.values(paymentMethod).forEach((p) => {
        const value = {
          apiFriendlyValues: {
            type: billingItemConditionType.paymentmethod,
            id: condition.id,
            value: p
          },
          value: p
        };
        conditionParametersFriendlyValues.push(value);
      });
    } else {
      const castedCondition = condition as CustomEntityPropertyTypeTariffConditionTypeParameters;

      let apiFriendlyValuesCustomEntityProperties: CustomEntityPropertyTypeTariffConditionTypeParametersDTO = {
        type: billingItemConditionType.customentityproperty,
        id: condition.id,
        customEntityPropertyTypeId: condition.id,
        valueBoolean: null,
        valueDecimal: null,
        valueNumber: null,
        valueString: null
      };

      if (castedCondition.valueType.type === customEntityPropertyValueType.boolean) {
        Object.values(booleanOptions).forEach((b) => {
          apiFriendlyValuesCustomEntityProperties = {
            ...apiFriendlyValuesCustomEntityProperties,
            valueBoolean: b === booleanOptions.yes
          };
          this.generateValuesForCustomEntityProperties(
            b,
            apiFriendlyValuesCustomEntityProperties,
            conditionParametersFriendlyValues
          );
        });
      } else if (castedCondition.valueType.type === customEntityPropertyValueType.string) {
        treeNode.options.forEach((o) => {
          apiFriendlyValuesCustomEntityProperties = {
            ...apiFriendlyValuesCustomEntityProperties,
            valueString: o.toString()
          };
          this.generateValuesForCustomEntityProperties(
            o,
            apiFriendlyValuesCustomEntityProperties,
            conditionParametersFriendlyValues
          );
        });
      } else if (castedCondition.valueType.type === customEntityPropertyValueType.number) {
        treeNode.options.forEach((o) => {
          apiFriendlyValuesCustomEntityProperties = {
            ...apiFriendlyValuesCustomEntityProperties,
            valueNumber: toNumber(o)
          };
          this.generateValuesForCustomEntityProperties(
            o,
            apiFriendlyValuesCustomEntityProperties,
            conditionParametersFriendlyValues
          );
        });
      } else if (castedCondition.valueType.type === customEntityPropertyValueType.decimal) {
        treeNode.options.forEach((o) => {
          apiFriendlyValuesCustomEntityProperties = {
            ...apiFriendlyValuesCustomEntityProperties,
            valueDecimal: toNumber(o)
          };
          this.generateValuesForCustomEntityProperties(
            o,
            apiFriendlyValuesCustomEntityProperties,
            conditionParametersFriendlyValues
          );
        });
      }
    }

    return conditionParametersFriendlyValues;
  };

  generateValuesForCustomEntityProperties = (
    option: any,
    apiFriendlyValuesCustomEntityProperties: CustomEntityPropertyTypeTariffConditionTypeParametersDTO,
    conditionParametersFriendlyValues: CustomEntityConditionNodeType[]
  ) => {
    const value: CustomEntityConditionNodeType = {
      value: option,
      apiFriendlyValues: apiFriendlyValuesCustomEntityProperties
    };
    conditionParametersFriendlyValues.push(value);
  };

  //backend ducktape
  getApiFriendlyValues = (
    node: TreeNode,
    selectedBillingItemId: string,
    selectedProductId: string,
    promises: BillingTariffRequestType[]
  ) => {
    if (node.tariff != null) {
      const paths = this.search(node.id, this.treeData[0]);
      //generate conditions
      const conditions: TariffConditionTypeParametersDTO[] = [];
      if (paths) {
        paths[0].forEach((p: TreeNode) => {
          if (p.condition) {
            conditions.push(p.condition);
          }
        });

        //generate apiFriendlyValues
        const apiFriendlyValues: BillingTariffRequestType = {
          billingItemId: selectedBillingItemId,
          calculationParameters: node.tariff.calculationParameters,
          conditionParameters: conditions,
          productId: selectedProductId,
          //paths[0][1 ] is a period node
          startDateTime: paths[0][1].periodStart,
          endDateTime: paths[0][1].periodEnd
        };

        promises.push(apiFriendlyValues);
      }
    }

    if (node.nodes != null) {
      node.nodes.forEach(async (n) => {
        this.getApiFriendlyValues(n, selectedBillingItemId, selectedProductId, promises);
      });
    }
  };

  getTariffTreeByPeriod = (id: string) => {
    const filteredTree = this.treeData[0].nodes.filter((period) => {
      return period.id === id;
    });

    this.treeData[0].nodes = filteredTree;
  };

  search = (id: string, tree: TreeNode) => {
    function loop(path: TreeNode[], node: TreeNode): TreeNode[][] | undefined {
      if (node)
        return node.id === id
          ? [path]
          : //@ts-ignore
            [].concat(...node.nodes.map((child: TreeNode) => loop([...path, node], child)));
    }
    return loop([], tree);
  };

  getTariffCalculationType = (type: billingItemTariffCalculationType) => {
    return type === billingItemTariffCalculationType.unitprice
      ? tariffCalculationType.unitprice
      : tariffCalculationType.tieredprice;
  };

  //this only supports 1 level
  //this should also be recursive in the future. but backend doesn't support this yet. so 1 level is hardcoded
  //backend ducktape
  getTree = async (selectedBillingItem: string, selectedProductId?: string, showOnlyActive?: boolean) => {
    this.treeData = [];
    const tree = await this.productConfigStore.productConfigService.getTariffTree(
      selectedBillingItem,
      showOnlyActive ? true : false,
      selectedProductId
    );

    const rootNode = new TreeNode('Root', [], [], tariffType.tariffperiod, this.tierCalcMethod, false);
    this.treeData.push(rootNode);

    tree.activityPeriodNodes.forEach(async (p, index) => {
      rootNode.addTariffPeriodNode(
        p.activityPeriod.startDateTime,
        p.activityPeriod.endDateTime,
        this.tierCalcMethod,
        p.currentlyActive,
        !p.productId
      );

      let conditionNode: TreeNode | null = null;
      const optionNodes: TreeNode[] = [];
      let customEntity: CustomEntityPropertyTypeTariffConditionTypeParameters | null = null;

      //buffer the condition on top
      if (p.nodes.length > 0) {
        if (p.nodes[0].condition?.customEntityPropertyTypeId) {
          customEntity = (await this.productConfigStore.productConfigService.getCustomEntityPropertyType(
            p.nodes[0].condition.customEntityPropertyTypeId
          )) as CustomEntityPropertyTypeTariffConditionTypeParameters;
        }
      }

      
      await Promise.all(
        p.nodes.map(async (n) => {
          if (n.condition) {
            if (customEntity) {
              conditionNode = new TreeNode(
                customEntity.name,
                optionNodes,
                [actions.editcondition, actions.deletecondition],
                tariffType.conditions,
                this.tierCalcMethod,
                false,
                'condition'
              );
              this.addCondition(optionNodes, n, conditionNode, customEntity);
            } else {
              //paymentmethod condition
              conditionNode = new TreeNode(
                this.productConfigStore.rootStore.applicationStore.getTranslation('customer.payment_method'),
                optionNodes,
                [actions.editcondition, actions.deletecondition],
                tariffType.conditions,
                this.tierCalcMethod,
                false,
                'condition'
              );
              this.addCondition(optionNodes, n, conditionNode, this.initialCondition.value);
            }
          } else {
            //add a tariff without a condition
            this.addTariffToNode(rootNode.nodes[index], {
              ...n.billingTariffs[0],
              id: n.billingTariffs[0].id,
              tariffType: tariffType.tariffcalculation,
              calculationParameters: clone(n.billingTariffs[0].calculationParameters),
              tariffCalculationType: this.getTariffCalculationType(n.billingTariffs[0].calculationParameters.type),
              tierCalculationMethod: this.tierCalcMethod || tierCalculationMethod.invoiceperiod
            });
          }
        })
      ).then(() => {
        if (conditionNode) {
          rootNode.nodes[index].addNode(conditionNode);
        }
      });

      this.treeDateBackup = clone(this.treeData);
    });
  };

  //backend ducktape
  addCondition = (
    optionNodes: TreeNode[],
    node: BillingTariffNodeType,
    conditionNode: TreeNode,
    conditon: TariffConditionTypeParametersDTO
  ) => {
    //adding the options first
    Object.keys(node.condition).forEach((key) => {
      if (key.includes('value')) {
        if (node.condition[key] != null) {
          conditionNode.addOption(node.condition[key]);
        }
      }
    });

    //mapping the right name to the options
    const condition = this.conditionToApiModel(conditon, conditionNode);

    let value = '';
    value = condition.find((c) => {
      //@ts-ignore
      delete c.apiFriendlyValues.id;
      return deepEqual(c.apiFriendlyValues, node.condition);
    })?.value;

    //adding the option node to the tree
    const optionNode = new TreeNode(
      value?.toString().toUpperCase(),
      [],
      [actions.addcondition, actions.addtariff],
      tariffType.conditions,
      this.tierCalcMethod,
      false,
      'dot'
    );
    optionNode.addCondition(node.condition);
    optionNodes.push(optionNode);
    conditionNode.setConditionGroup(conditon);
    conditionNode?.addNode(optionNode);
    this.addTariffToNode(optionNode, {
      ...node.billingTariffs[0],
      id: node.billingTariffs[0].id,
      tariffType: tariffType.tariffcalculation,
      calculationParameters: clone(node.billingTariffs[0].calculationParameters),
      tariffCalculationType: this.getTariffCalculationType(node.billingTariffs[0].calculationParameters.type),
      tierCalculationMethod: this.selectedBillingItem?.tierCalculationMethod || tierCalculationMethod.invoiceperiod
    });
  };

  //backend ducktape
  addTariffToNode = (optionNode: TreeNode, tariff: TariffValidatorType, parentNode?: TreeNode, index?: number) => {
    let tariffValueNodes: TreeNode[] = [];
    const calculationType = tariff.calculationParameters.type;

    if (calculationType === billingItemTariffCalculationType.unitprice) {
      const params = tariff.calculationParameters as UnitPriceTariffCalculationTypeParametersType;
      this.createOrUpdateTariff(
        optionNode,
        calculationType,
        tariffValueNodes,
        tariff,
        index,
        parentNode,
        params.formulaBased
          ? formatExpression(params.expression, this.productConfigStore.rootStore.applicationStore.culture)
          : formatMoney(params.unitTariff, this.productConfigStore.rootStore.applicationStore.culture)
      );
    } else {
      const params = tariff.calculationParameters as TieredTariffCalculationTypeParametersType;
      params.slices.forEach((s) => {
        tariffValueNodes.push(
          new TreeNode(
            s.from.toString(),
            [],
            [],
            tariffType.tariffcalculation,
            tariff.tierCalculationMethod,
            true,
            'dot',
            s.formulaBased
              ? formatExpression(s.expression, this.productConfigStore.rootStore.applicationStore.culture)
              : formatMoney(s.unitTariff, this.productConfigStore.rootStore.applicationStore.culture)
          )
        );
      });

      this.createOrUpdateTariff(optionNode, calculationType, tariffValueNodes, tariff, index, parentNode);
    }
  };

  createOrUpdateTariff = (
    optionNode: TreeNode,
    calculationType: billingItemTariffCalculationType,
    tariffValueNodes: TreeNode[],
    tariff: TariffValidatorType,
    index?: number,
    parentNode?: TreeNode,
    extraInfo?: string
  ) => {
    const node = new TreeNode(
      this.productConfigStore.rootStore.applicationStore.getEnumTranslation(
        'billingItemTariffCalculationType',
        calculationType
      ),
      tariffValueNodes,
      [actions.edittariff, actions.deletetariff],
      tariffType.tariffcalculation,
      tariff.tierCalculationMethod,
      true,
      'coin',
      extraInfo
    );

    if (parentNode) {
      optionNode.updateNode(index || 0, node, parentNode);
    } else {
      optionNode.addNode(node);
    }

    node.addTariff(tariff);
  };

  validateTariffTree = (node: TreeNode) => {
    const validTreePaths: boolean[] = [];
    this.traverseTree(node, validTreePaths);
    return !validTreePaths.includes(false);
  };

  traverseTree = (node: TreeNode, validTreePaths: boolean[]) => {
    if (node.nodes.length !== 0) {
      node.nodes.forEach((n) => this.traverseTree(n, validTreePaths));
    } else if (node !== undefined) {
      const paths: TreeNode[][] | undefined = this.search(node.id, this.treeData[0]);
      let validPath: boolean = false;

      //check if end element has a tariff
      if (node.tariff != null) {
        validPath = true;
      }

      //check if inbetween steps have a tariff
      if (paths) {
        paths[0].forEach((path) => {
          if (path.tariff != null) {
            validPath = true;
          }
        });

        validTreePaths.push(validPath);
      }
    }
  };

  getTariffsInPeriod = async (node: TreeNode, tariffs: TariffValidatorType[]) => {
    if (node.tariff != null) {
      tariffs.push(node.tariff);
    }

    if (node.nodes.length !== 0) {
      node.nodes.forEach((n) => this.getTariffsInPeriod(n, tariffs));
    }
  };

  deleteTariffPeriod = async (nodeId: string) => {
    const promises = this.treeDateBackup[0]?.nodes.map((periodNode) => {
      const promises: Promise<void>[] = [];

      if (periodNode.id === nodeId) {
        const tariffsToDelete: TariffValidatorType[] = [];
        this.getTariffsInPeriod(periodNode, tariffsToDelete);

        for (const tariff of tariffsToDelete) {
          promises.push(this.deleteTariff(tariff.id || ''));
        }
      }

      return promises;
    });

    return Promise.all(promises?.flat() || []);
  };

  notifyTreeSaveFailed = (error?: any) => {
    notify.error({
      content: this.productConfigStore.rootStore.applicationStore.getTranslation(`billing_tariff.edit_tariff_fail`),
      error
    });
  };

  saveTree = async (selectedBillingItem: BillingItemType | undefined, node: TreeNode, selectedProductId: string) => {
    try {
      if (selectedBillingItem) {
        // If tierCalculationMethod changed, update billing item
        const updatedItem = await this.productConfigStore.productConfigService.updateBillingItem(
          selectedBillingItem.id,
          {
            name: selectedBillingItem.name,
            description: selectedBillingItem.description,
            personTaxCodeId: selectedBillingItem.personTaxCodeId,
            organisationTaxCodeId: selectedBillingItem.organisationTaxCodeId,
            tierCalculationMethod: node.tierCalculationMethod || tierCalculationMethod.invoiceperiod,
            calculationParameters: selectedBillingItem.calculationParameters
          }
        );

        this.updateProductBillingItemInList(updatedItem);

        const promises: BillingTariffRequestType[] = [];
        //delete all tariffs first
        await this.deleteTariffPeriod(node.id);

        //save all the tariffs that are being used inside the tree
        this.getApiFriendlyValues(this.treeData[0], selectedBillingItem.id, selectedProductId, promises);

        for (const promise of promises) {
          await this.addTariff(promise);
        }

        await this.productConfigStore.setOnlyShowTariffs(false, selectedBillingItem.id, selectedProductId);

        notify.success({
          content: this.productConfigStore.rootStore.applicationStore.getTranslation(
            `billing_tariff.edit_tariff_success`
          )
        });
      } else {
        this.notifyTreeSaveFailed();
      }
    } catch (error) {
      this.notifyTreeSaveFailed(error);
    }
  };
}
