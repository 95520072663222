import clone from 'clone';
import React, { useEffect } from 'react';

import { TaxCodeType, UpdateTaxCodeType } from '@zf/api-types/tax-codes';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import { ConfigHelp } from '../../../components/CoachMarks';
import CommitSection from '../../../components/config/commit-section';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import { notify } from '../../../events/notification-events';
import usePeriodValidator from '../../../hooks/usePeriodValidator';
import { useStore } from '../../../hooks/useStore';
import { RequestType } from '../../../types/Request';
import { createHeader, METHODS, sendRequest } from '../../../utils/request';
import TaxRatesCard from './tax-rates-card';
//import { NumberParam } from 'serialize-query-params';
import { StringParam, useQueryParam } from 'use-query-params';

type Props = {
  config: UseConfigReturnType<UpdateTaxCodeType[]>;
  useTracked: () => [ValidatorCtxState<UpdateTaxCodeType[]>, React.Dispatch<ValidatorCtxAction<UpdateTaxCodeType[]>>];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function TaxCodesContent(props: Props) {
  const { config } = props;
  const { sort } = config;

  const { configStore } = useStore();

  const { i18n, tenantReducer } = useAppContext();

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 1,
      label: i18n.getTranslation('general.name'),
      dataKey: 'name'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const { values, backup, isDirty, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator<UpdateTaxCodeType[]>(props.useTracked);

  const [entityId] = useQueryParam('entityId', StringParam);

  useEffect(() => {
    entityId && setSelectedIndex(values.findIndex(({ id }) => entityId === id));
  }, [values]);

  const { addEntity, deleteEntity, backupValues } = useContextCRUD(props.useTracked);

  const { checkForOverlapAndGaps, notifyForOverlapOrGaps } = usePeriodValidator();

  const addTaxCode = () => {
    addEntity({ name: '', rates: [] });
  };

  const setTaxCodes = (
    index: number,
    value: UpdateTaxCodeType[keyof UpdateTaxCodeType],
    dataKey: keyof UpdateTaxCodeType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdateTaxCodeType[]
    });
  };

  const taxCodeRows = values.map((taxCode, index) => {
    return {
      name: (
        <InlineInputFieldInput
          id={`taxcode.name.index-${index}`}
          value={taxCode.name}
          onChange={(val) => setTaxCodes(index, val, 'name')}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!taxCode.name}
          error={!taxCode.name}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`taxcode.delete.index-${index}`}
          tooltipFor="tax-codes-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  const submitTaxCode = async (val: UpdateTaxCodeType, index: number) => {
    try {
      const apiFriendlyValues: UpdateTaxCodeType = {
        name: val.name,
        rates: val.rates
      };

      let request: RequestType | undefined = undefined;

      if (val.id && val.delete) {
        request = {
          method: METHODS.DELETE,
          endpoint: `/cfg/TaxCodes/${val.id}`
        };
      } else if (!val.id) {
        request = {
          method: METHODS.POST,
          endpoint: '/cfg/taxCodes',
          data: {
            ...apiFriendlyValues
          }
        };
      } else {
        const initialTaxCode = backup.find((initialTC) => initialTC.id === val.id);
        if (JSON.stringify(initialTaxCode) !== JSON.stringify(val)) {
          request = {
            method: METHODS.POST,
            endpoint: `/cfg/taxCodes/${val.id}`,
            data: {
              ...apiFriendlyValues
            }
          };
        }
      }

      if (request) {
        const newTaxCode = (
          await sendRequest<TaxCodeType>({
            request,
            customHeaders: createHeader({
              'If-Match': val._etag
            }),
            tenantReducer,
            lang: i18n.lang
          })
        ).data;

        if (!val.delete) {
          return newTaxCode;
        }
      }
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('tax_code.error_update'),
        error: e
      });
      setValue({ isLocked: false });
      return false;
    }
  };

  const submitTaxcodes = async (taxCodesToHandle: UpdateTaxCodeType[]) => {
    const newCodes = await Promise.all(taxCodesToHandle.map((val, index) => submitTaxCode(val, index)));

    const valuesClone = clone(values);

    newCodes.forEach((code) => {
      if (code && typeof code !== 'undefined') {
        const indexToUpdate = values.findIndex((c) => {
          return c.name === code.name;
        });

        valuesClone[indexToUpdate] = code;
      }
    });

    // IsLocked: false is included in backup
    backupValues(valuesClone);

    notify.success({
      content: i18n.getTranslation('tax_code.success_update')
    });

    // Errors are catched inside submitTaxCode()
  };

  const handleSave = async () => {
    setValue({ isLocked: true });

    const taxCodesToHandle = clone(values);

    backup.forEach((initialTaxCode) => {
      if (!values.find((taxCode) => initialTaxCode.id === taxCode.id)) {
        initialTaxCode.delete = true;
        taxCodesToHandle.push(initialTaxCode);
      }
    });

    const validCodes = taxCodesToHandle.reduce((acc: boolean[], code) => {
      if (!code.delete) {
        const result = checkForOverlapAndGaps(code.rates);
        notifyForOverlapOrGaps(result, code.name);
        acc.push(!result.overlap && !result.gap);
      }

      return acc;
    }, []);

    if (!validCodes.includes(false)) {
      await submitTaxcodes(taxCodesToHandle);
      setValue({ isLocked: false, isDirty: false, selectedIndex: selectedIndex });
    } else {
      setValue({ isLocked: false, isDirty: true });
    }

    configStore.setRefetchTaxCodes(true);
  };

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleSave} isDirty={isDirty} />
      <CardsContainer>
        <Card id="tax-codes-card">
          <CardHeader
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.tax_codes.title')}
                content={[i18n.getTranslation('coachmark.tax_codes.paragraph')]}
              />
            }
            extraRight={
              <Button id="taxcode.add" type="text" icon="plus" onClick={addTaxCode}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('tax_code.tax_codes')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="tax-codes-table"
              rows={taxCodeRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
            />
          </CardBody>
        </Card>
        <TaxRatesCard values={values} selectedTaxCodeIndex={selectedIndex} setValue={setValue} />
      </CardsContainer>
    </>
  );
}
