import clone from 'clone';
import React from 'react';

import { AddressType, LocalAddressType } from '@zf/api-types/general';
import { CommunicationConfigurationType } from '@zf/api-types/master-data/property-group';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { deepEqual } from '@zf/utils/src/object';

import { useAppContext } from '../../../../app-context/app-context';
import ResetAllButton from '../../../../components/Button/ResetAllButton/ResetAllButton';
import CountryInput from '../../../../components/input/country-input';
import { InlineInputFieldInput } from '../../../../components/input/InputField';

type Props = {
  communicationConfiguration: Partial<CommunicationConfigurationType>;
  defaultAddress: LocalAddressType | null;
  setCommConfigValue: (value: Partial<CommunicationConfigurationType>) => void;
  resetAddress: () => void;
};

export default function AddressCard(props: Props) {
  const { communicationConfiguration, defaultAddress, setCommConfigValue, resetAddress } = props;
  const { i18n } = useAppContext();

  if (!communicationConfiguration.address) {
    communicationConfiguration.address = {
      streetName: '',
      streetNumber: '',
      streetNumberAddition: '',
      postalCode: '',
      city: '',
      country: ''
    };
  }

  const mayReset = !deepEqual(defaultAddress, communicationConfiguration.address);

  const setAddressValue = (value: Partial<AddressType>) => {
    // @ts-ignore
    setCommConfigValue({ address: { ...clone(communicationConfiguration.address), ...value } });
  };

  return (
    <Card id="address-card" width="1">
      <CardHeader>{i18n.getTranslation('location.address')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{i18n.getTranslation('location.country')}</Label>
        </CardItem>
        <CardItem width="6">
          <CountryInput
            id="propertygroup.country"
            onChange={(value) => setAddressValue({ country: value[0] })}
            selectedValues={[communicationConfiguration.address.country]}
            error={!communicationConfiguration.address.country}
            organisationScoped
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('location.city')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="propertygroup.city"
            onChange={(value) => setAddressValue({ city: value })}
            value={communicationConfiguration.address.city}
            error={!communicationConfiguration.address.city}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('location.postal')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="propertygroup.postal"
            onChange={(value) => setAddressValue({ postalCode: value })}
            value={communicationConfiguration.address.postalCode}
            error={!communicationConfiguration.address.postalCode}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('location.street')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="propertygroup.street"
            onChange={(value) => setAddressValue({ streetName: value })}
            value={communicationConfiguration.address.streetName}
            error={!communicationConfiguration.address.streetName}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('location.streetnr')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="propertygroup.street_number"
            onChange={(value) => setAddressValue({ streetNumber: value })}
            value={communicationConfiguration.address.streetNumber}
            error={!communicationConfiguration.address.streetNumber}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('location.streetnraddition')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="propertygroup.street_number_addition"
            onChange={(value) => setAddressValue({ streetNumberAddition: value })}
            value={communicationConfiguration.address.streetNumberAddition}
          />
        </CardItem>

        {resetAddress && mayReset && (
          <CardItem width="12" justifyContent="end">
            <ResetAllButton
              id="propertygroup.reset_address"
              title={i18n.getTranslation('location.reset_address')}
              onClick={resetAddress}
            />
          </CardItem>
        )}
      </CardBody>
    </Card>
  );
}
