import React from 'react';

import { customerType, paymentMethod } from '@zf/api-types/enums';
import { CustomerOverviewCountType } from '@zf/api-types/master-data/customer';

import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { ArrayParam, StringParam } from 'use-query-params';

const initialCustomerOverviewCount: CustomerOverviewCountType = {
  all: 0,
  requireAttention: 0,
  supplied: 0,
  unsupplied: 0
};

type QueryParam = {
  propertyGroupId: string;
  propertyGroupName: string;
  customerGroupName: string;
  customerGroupId: string;
  customerType?: customerType[];
  defaultPaymentMethod?: paymentMethod[];
};

const initialQueryParams = {
  propertyGroupId: '',
  propertyGroupName: '',
  customerGroupName: '',
  customerGroupId: ''
};

const paramTypes = {
  propertyGroupId: StringParam,
  propertyGroupName: StringParam,
  customerGroupName: StringParam,
  customerGroupId: StringParam,
  defaultPaymentMethod: ArrayParam,
  customerType: ArrayParam
};

const useCustomerFilter = (search?: string) => {
  const { onFilter, onSearch, overviewCount, quickFilter, searchValue, queryParams, setQueryParams, clearQueryParams } =
    useFilter<QueryParam>({
      endpoint: '/md/customers/overviewcount',
      initialCount: initialCustomerOverviewCount,
      domain: 'customer',
      search: search,
      initialQueryParams,
      paramTypes
    });

  const tags: React.ReactNode[] = [];

  if (queryParams.propertyGroupId) {
    tags.push(
      <FilterTag
        value={queryParams.propertyGroupName}
        removeFilter={() => setQueryParams({ propertyGroupId: '', propertyGroupName: '' })}
        key="property-group"
      />
    );
  }

  if (queryParams.customerGroupName && queryParams.customerGroupId) {
    tags.push(
      <FilterTag
        value={queryParams.customerGroupName}
        removeFilter={() => setQueryParams({ customerGroupName: '', customerGroupId: '' })}
        key="customer-group"
      />
    );
  }

  if (queryParams.customerType && queryParams.customerType.length > 0) {
    tags.push(
      <FilterTag
        key="customer-type"
        type="enum"
        enumType="customerType"
        value={queryParams.customerType[0]}
        removeFilter={() => setQueryParams({ customerType: [] as customerType[] })}
      />
    );
  }

  if (queryParams.defaultPaymentMethod && queryParams.defaultPaymentMethod.length > 0) {
    tags.push(
      <FilterTag
        key="default-payment-method"
        type="enum"
        enumType="paymentMethod"
        value={queryParams.defaultPaymentMethod[0]}
        removeFilter={() => setQueryParams({ defaultPaymentMethod: [] as paymentMethod[] })}
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    setQueryParams,
    clearQueryParams,
    queryParams,
    filterTags,
    quickFilter,
    overviewCount,
    searchValue
  };
};

export default useCustomerFilter;
