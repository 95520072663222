import { makeObservable } from 'mobx';

import RootStore from '../../../../../app-context/stores';
import OutgoingBankingTransactionsService from '../services/OutgoingBankingTransactionsService';

export default class OutgoingBankingTransactionsStore {
  public rootStore: RootStore;
  public outgoingBankingTransactionsService: OutgoingBankingTransactionsService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.outgoingBankingTransactionsService = new OutgoingBankingTransactionsService(this, rootStore.applicationStore);

    makeObservable(this, {});
  }
}
