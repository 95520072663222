import React from 'react';

export default function ZFSpinner() {
  return (
    <img
      src="https://cdn.zerofriction.co/shared/assets/loading.gif"
      alt="Zero Friction Spinner"
      title="Zero Friction Spinner"
      width="100rem"
      height="100rem"
    />
  );
}
