import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';

import { SuspenseCardBody } from '../../components/suspense';
import CommentCardFooter from './CommentCardFooter';
import Comments from './Comments';

type Props = {
  subjectType: entitySubjectType;
  subjectId: string;
};

export default function CommentCardBody(props: Props) {
  const { subjectType, subjectId } = props;

  return (
    <>
      <SuspenseCardBody fixedHeight>
        <Comments subjectType={subjectType} subjectId={subjectId} />
      </SuspenseCardBody>
      <CommentCardFooter subjectType={subjectType} subjectId={subjectId} />
    </>
  );
}
