import React from 'react';

import { contactType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';

import { STEP_NAMES } from '../CustomerWizard';
import css from './communication-section.module.scss';
import ContactDetail from './ContactDetail';

export type Props<T> = {
  values: T;
  setValue: (value: Partial<T>) => void;
  handleFocus?: (step: string) => () => void;
};
export type ContactValue = { contactType: string; value: string };

export default function PrimaryContactDetails<T extends { primaryContactDetails: ContactEntryType[] }>(
  props: Props<T>
) {
  const { values, setValue, handleFocus } = props;

  const setPrimaryContactDetail = (cType: contactType | 'description', val: Partial<ContactEntryType>) => {
    const indexToUpdate = values.primaryContactDetails.findIndex((pcd) => pcd.contactType === cType);

    if (indexToUpdate !== -1) {
      const cloned = [...values.primaryContactDetails];
      cloned[indexToUpdate] = { ...cloned[indexToUpdate], ...val };
      // @ts-ignore
      setValue({ primaryContactDetails: cloned });
    }
  };

  return (
    <div className={css['primary-contact-details']}>
      {values.primaryContactDetails.map((pcd, index) => {
        return (
          <ContactDetail
            id={`primary-${pcd.contactType}-${index}`}
            key={`primary-${pcd.contactType}-${index}`}
            contactType_={pcd.contactType}
            value={pcd.value || ''}
            descriptionValue={pcd.description || ''}
            setContactDetailValue={setPrimaryContactDetail}
            onFocus={() => {
              if (handleFocus) handleFocus(STEP_NAMES[2]);
            }}
            mayAddDescription
          />
        );
      })}
    </div>
  );
}
