import ConfirmationActionButton from 'components/Button/ConfirmationActionButton';
import React from 'react';
import { EmbeddedListPageDialogContent } from './EmbeddedListPageDialogContent';
import css from './embedded-list-page-dialog.module.scss';
import { MeteringIssueError, MutingRuleTimePeriod } from '@zf/api-types/enums';
import { Paragraph } from 'design-system/Foundation';

type Props = {
  btnLabel: string;
  title: string;
  muteFromWithNumericValue: number;
  muteUntilWithNumericValue: number;
  errorType: MeteringIssueError;
  message?: string;
  timePeriod: MutingRuleTimePeriod;
  inactiveLink?: boolean;
  userId: string;
  includeActive: boolean;
};

const SimpleEmbeddedListPageDialog = (props: Props) => {
  const {
    btnLabel,
    title,
    muteFromWithNumericValue,
    muteUntilWithNumericValue,
    errorType,
    message,
    timePeriod,
    inactiveLink,
    userId,
    includeActive
  } = props;

  return inactiveLink ? (
    <Paragraph bold>{btnLabel}</Paragraph>
  ) : (
    <ConfirmationActionButton
      className={css['dialog-button']}
      id="show-embedded-list-page"
      isDismissDialog
      icon="meter"
      type="text"
      dialogTitle={title}
      actionTitle={<>{btnLabel}</>}
      onSubmit={() => {}}
      dialogContent={
        <EmbeddedListPageDialogContent
          muteFromWithNumericValue={muteFromWithNumericValue}
          muteUntilWithNumericValue={muteUntilWithNumericValue}
          errorType={errorType}
          message={message}
          timePeriod={timePeriod}
          userId={userId}
          includeActive={includeActive}
        />
      }
    />
  );
};

export default SimpleEmbeddedListPageDialog;
