import { KnownTenantsResponse } from '@zf/api-types/auth';

import { METHODS } from '../../utils/request';
import ApplicationStore from '../stores/domain/ApplicationStore';
import BaseService from './BaseService';

export default class TenantService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/tenant/', applicationStore);
  }
  getKnownTenants = async () => {
    return (
      await this.applicationStore.sendRequest<KnownTenantsResponse>({
        request: {
          method: METHODS.GET,
          endpoint: '/tenant/known'
        }
      })
    ).data;
  };
}
