import classNames from 'classnames';
import Interweave from 'interweave';
import React from 'react';

import StepLink from './StepLink';
import css from './wizard-nav.module.scss';

type Props = {
  header: React.ReactNode;
  content?: React.ReactNode;
  active?: boolean;
  subStep?: boolean;
  name: string;
  feedback: Array<React.ReactNode>;
  error?: boolean;
  onTrigger: Function;
};

export default function WizardNavItem(props: Props) {
  const { header, active, name, feedback, subStep, error, onTrigger } = props;

  return !error || (error && feedback && feedback.length > 0) ? (
    <li
      className={classNames(css['wizard-nav-list-item'], {
        [css['substep']]: subStep
      })}
    >
      <div id={name} onClick={(e) => onTrigger()}>
        <StepLink name={name}>
          <div
            className={classNames(css['wizard-nav-progress'], {
              [css['wizard-nav-progress-active']]: active && !error,
              [css['wizard-nav-progress-error']]: error
            })}
          >
            {header}
          </div>
          <ul className={css['wizard-nav-feedback']}>
            {feedback &&
              feedback.map((value, index) => {
                let feedBackValue = value;

                if (typeof value === 'string') {
                  feedBackValue = <Interweave content={value} />;
                }

                return (
                  <li key={index} className={css['wizard-nav-feedback-item']}>
                    {feedBackValue}
                  </li>
                );
              })}
          </ul>
        </StepLink>
      </div>
    </li>
  ) : null;
}
