import { observer } from 'mobx-react';
import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';

import { useStore } from '../../../../../hooks/useStore';
import { balanceDataType } from '../../stores/BalanceStore';
import BalanceGraph from './BalanceGraph';
import TopUpTable from './TopUpTable';

type Props = {
  prepaymentDevice: MeterType;
};

const DataSection = (props: Props) => {
  const { prepaymentDevice } = props;

  const { prepaymentDeviceStore } = useStore();
  const { dataType } = prepaymentDeviceStore.balanceStore;

  return dataType === balanceDataType.topUp ? (
    <TopUpTable prepaymentDevice={prepaymentDevice} />
  ) : (
    <BalanceGraph prepaymentDevice={prepaymentDevice} />
  );
};

export default observer(DataSection);
