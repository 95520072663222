import React from 'react';
import { entitySubjectType } from '@zf/api-types/enums';
import useBrowserTitle from 'app-context/hooks/use-browser-title';
import ListPage from 'components/listPage';
import { useStore } from 'hooks/useStore';
import CustomerGroupFilterBar from './CustomerGroupFilterBar';
import CustomerGroupList from './CustomerGroupList';

type Props = {
  location: Location;
};

function CustomerGroupListPage(props: Props) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('customer_groups.customer_groups'));
  return (
    <ListPage
      entitySubjectType={entitySubjectType.customergroup}
      subjectType={getTranslation('customer_groups.customer_groups')}
      topBar={<CustomerGroupFilterBar search={props.location.search} />}
      list={<CustomerGroupList />}
    />
  );
}

export default React.memo(CustomerGroupListPage);
