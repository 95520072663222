import React from 'react';

import { ExternalMeasurementImportJobType, ExternalMeasurementRowType, ImportJobType } from '@zf/api-types/import-job';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { useAppContext } from '../../../../app-context';
import { Icon } from '../../../../components/Icon';
import Dropdown from '../../../../components/Lang/Dropdown';
import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { SuspenseCardBody } from '../../../../components/suspense';
import useCreateRequest from '../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../hooks/useInfiniAPI';
import css from './import-job-values.module.scss';
import NoExternalMeasurements from './no-external-measurements';

export type Props = {
  importJob: ImportJobType;
  filter?: string;
};

const InlineInputDrowdown = InlineInputField<
  DropdownProps<{
    id: string;
  }>
>(Dropdown);

function ValuesBody(props: Props) {
  const { importJob, filter = '' } = props;
  const { i18n, tenantReducer } = useAppContext();
  const [columns] = React.useState([
    {
      width: 30,
      label: '',
      dataKey: 'error'
    },
    {
      width: 150,
      label: i18n.getTranslation('import_jobs.serial_number'),
      dataKey: 'meterSerialNumber'
    },
    {
      width: 110,
      label: i18n.getTranslation('import_jobs.channel_identifier'),
      dataKey: 'externalChannelIdentifier'
    },
    {
      width: 150,
      label: i18n.getTranslation('general.reference'),
      dataKey: 'externalReference'
    },
    {
      width: 110,
      label: i18n.getTranslation('general.value'),
      dataKey: 'value'
    },
    {
      width: 180,
      label: i18n.getTranslation('general.end_date'),
      dataKey: 'endDateTime'
    },
    {
      width: 150,
      label: i18n.getTranslation('import_jobs.metering_type'),
      dataKey: 'meteringType'
    },
    {
      width: 110,
      label: i18n.getTranslation('import_jobs.direction'),
      dataKey: 'direction'
    },
    {
      width: 110,
      label: i18n.getTranslation('meter.unit_of_measure'),
      dataKey: 'unitOfMeasure'
    },
    {
      width: 80,
      label: i18n.getTranslation('meter.time_of_use'),
      dataKey: 'timeOfUse'
    },
    {
      width: 180,
      label: i18n.getTranslation('meter.utility_type'),
      dataKey: 'utilityType'
    },
    {
      width: 80,
      label: i18n.getTranslation('import_jobs.linked'),
      dataKey: 'linked_icon'
    },
    {
      width: 80,
      label: i18n.getTranslation('import_jobs.context_valid'),
      dataKey: 'context_icon'
    },
    {
      width: 100,
      label: i18n.getTranslation('import_jobs.processed'),
      dataKey: 'processed_icon'
    },
    {
      width: 300,
      label: i18n.getTranslation('import_jobs.processed_on'),
      dataKey: 'processedOn'
    }
  ]);

  const { request, setSelectedIds, handleSort, sortBy, sortDirection } = useCreateRequest(
    `/logs/ImportJob/${importJob.id}/externalmeasurements`,
    { quickFilter: filter }
  );

  const processRecord = (measurement: ExternalMeasurementImportJobType): ExternalMeasurementRowType => {
    return {
      __id: measurement.id,
      error:
        measurement.errorCodes.length > 0 ? (
          <div
            data-tip={createTooltipContent(
              <ul className={css['error-codes']}>
                {measurement.errorCodes.map((value, index) => {
                  return (
                    <li key={`${value.key}-${index}`} className={css['error-code']}>
                      {value.message}
                    </li>
                  );
                })}
              </ul>
            )}
            data-for="external-measurements-table-tip"
          >
            <Icon type="exclamation" color />
          </div>
        ) : (
          ''
        ),
      ...measurement,
      context_icon: measurement.contextValid ? <Icon type="check" color /> : <Icon type="cancel" color />,
      linked_icon: measurement.linked ? <Icon type="check" color /> : <Icon type="cancel" color />,
      processed_icon: measurement.processed ? <Icon type="check" color /> : <Icon type="cancel" color />
    };
  };

  const { loading, error, rows, setStopIndex, sortableFields } = useInfiniAPI<
    ExternalMeasurementImportJobType,
    ExternalMeasurementRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord,
    onSelectRow: setSelectedIds
  });

  return (
    <DynamicVirtualTable
      id="external-measurements-table"
      tooltipId="external-measurements-table-tip"
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      rows={rows}
      loading={loading}
      error={error}
      NoDataOverlay={NoExternalMeasurements}
      noSelect
      sortableFields={sortableFields}
      sort={handleSort}
      sortBy={sortBy}
      singleSort
      sortDirection={sortDirection}
      columns={columns}
    />
  );
}

export default function ImportJobValues(props: Props) {
  const { i18n } = useAppContext();
  const [filter, setFilter] = React.useState('');
  const [dropdownValues] = React.useState([
    {
      text: i18n.getTranslation('import_jobs.unlinked'),
      value: { id: 'unlinked' }
    },
    {
      text: i18n.getTranslation('import_jobs.linked'),
      value: { id: 'linked' }
    },
    {
      text: i18n.getTranslation('import_jobs.invalid'),
      value: { id: 'contextinvalid' }
    },
    {
      text: i18n.getTranslation('import_jobs.processed'),
      value: { id: 'processed' }
    }
  ]);

  return (
    <Card id="external-measurement-card" className={css['card-measurements']}>
      <CardHeader
        extraRight={
          <InlineInputDrowdown
            id="filter-state"
            className={css['dropdown']}
            placeholder={i18n.getTranslation('import_jobs.state_filter')}
            values={dropdownValues}
            selectedValues={[filter]}
            onChange={(val) => setFilter(val[0] ? val[0].id : '')}
            clear
          />
        }
      >
        {i18n.getTranslation('meter.measurements')}
      </CardHeader>
      <SuspenseCardBody className={css['measurements']} noMaxHeight>
        <ValuesBody {...props} filter={filter} />
      </SuspenseCardBody>
    </Card>
  );
}
