import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import useSelectionNavigator from '../../../../app-context/hooks/use-selection-navigator';
import { entityTypes } from '../../../../app-context/stores/ui-stores/UiStore';
import { useStore } from '../../../../hooks/useStore';
import ContractDetailHeader from './containers/ContractDetailHeader';
import ContractTabContainer from './containers/ContractTabContainer';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

const Contract = observer((props: Props) => {
  const { urlParams, navigate } = props;
  const { contractStore } = useStore();
  const navigator = useSelectionNavigator(urlParams.contractId, entityTypes.contract);

  useEffect(() => {
    // Fetch entity when id changes
    contractStore.loadContractDetailData(urlParams.contractId);
  }, [urlParams.contractId]);

  useEffect(() => {
    // Clear entity when you leave the detail page
    return () => contractStore.onLeaveDetailPage();
  }, []);

  return (
    <AsyncVarSpinner variables={[contractStore.selectedContract_]}>
      <ContractDetailHeader />
      <ContractTabContainer
        key={contractStore.selectedContract_?.contract.id}
        navigator={navigator}
        navigate={navigate}
      />
    </AsyncVarSpinner>
  );
});

export default Contract;
