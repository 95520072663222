import classNames from 'classnames';
import React from 'react';

import css from './card-item.module.scss';

type Props = {
  children?: React.ReactNode | null;
  width: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
  justifyContent?: 'left' | 'end' | 'center';
  style?: React.CSSProperties;
  className?: string;
};

export default function CardItem(props: Props) {
  const { children, width, className = 'cardItem', justifyContent } = props;
  let { style } = props;

  if (justifyContent) {
    style = { ...style, justifyContent: justifyContent };
  }

  return (
    <div className={classNames(className, css['card-item'], css[`card-item-${width}`])} style={style}>
      {children}
    </div>
  );
}
