import React from 'react';

import { CollectionFlowType, CreateCollectionFlowType } from '@zf/api-types/collection-cycles';
import useValidator from '@zf/hooks/src/useValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../../../app-context';
import { InlineInputFieldInput } from '../../../../components/input/InputField';
import { InlineNumberInput } from '../../../../components/input/NumberInput';
import { notify } from '../../../../events/notification-events';
import { updateCollectionCycle } from '../collection-api-functions';
import css from './edit-cycle-dialog.module.scss';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  flow: CreateCollectionFlowType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  updateFlow: (flow: CollectionFlowType) => void;
};

export type ValidatorType = {
  name: string;
  daysAfterInvoiceDue: number;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { flow, validationRef, updateFlow } = props;
  const { i18n, tenantReducer } = useAppContext();

  const { values, setValue } = useValidator<CreateCollectionFlowType>({
    initialValues: {
      name: flow.name,
      daysAfterInvoiceDue: flow.daysAfterInvoiceDue
    },
    validate: () => {
      return [];
    }
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!values.name || !values.daysAfterInvoiceDue);
    }
  };

  React.useEffect(() => {
    validate();
  }, [values]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const apiFriendlyValues: CreateCollectionFlowType = {
          id: flow.id,
          name: values.name,
          daysAfterInvoiceDue: values.daysAfterInvoiceDue,
          stepIds: flow.stepIds
        };

        const newCycle = await updateCollectionCycle(apiFriendlyValues, tenantReducer, i18n.lang);
        // Update state
        updateFlow(newCycle);

        notify.success({
          content: i18n.getTranslation('collection_flows.edit_flow_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('collection_flows.edit_flow_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['wrapper']}>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('collection_flows.choose_flow_name')}
      </Heading>
      <div className={css['input-wrapper']}>
        <InlineInputFieldInput
          id="cycle-name"
          value={values.name}
          onChange={(val) => setValue({ name: val })}
          placeholder={i18n.getTranslation('collection_flows.flow_name')}
          error={!values.name}
        />
      </div>

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('collection_flows.choose_trigger_delay')}
      </Heading>
      <div className={css['input-wrapper']}>
        <InlineNumberInput
          id="days-after-invoice-due"
          value={values.daysAfterInvoiceDue}
          onChange={(val) => setValue({ daysAfterInvoiceDue: val })}
          placeholder={i18n.getTranslation('collection_flows.days_after_due_date')}
          error={!values.daysAfterInvoiceDue}
        />
      </div>
    </div>
  );
});
