import { TabSlider } from 'design-system/Components';
import { SelectionTabItemType } from 'design-system/Components/TabSlider/Tab';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { UseFormReset, UseFormSetValue } from 'react-hook-form';

import { meteringIssueStatus } from '@zf/api-types/enums';

import { useMeterIssuesContext } from '../Context/Context';
import { Query } from './types';

type Props = {
  setValue: UseFormSetValue<Partial<Query>>;
  reset: UseFormReset<Partial<Query>>;
  overviewCount: Record<string, number>;
};

const LeftFilters: FC<Props> = ({ setValue, overviewCount, reset }) => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const ctx = useMeterIssuesContext();
  const { quickFilter } = ctx.store.filter;

  const tabItems: SelectionTabItemType<{ all: number; unresolved: number }>[] = [
    {
      id: 'unresolved',
      count: overviewCount.unresolved,
      title: getEnumTranslation('meteringIssueStatus', meteringIssueStatus.unresolved)
    },
    { id: 'all', count: overviewCount.all, title: getTranslation('general.all') }
  ];

  return (
    <div>
      <TabSlider
        id="meter-issues-quick-filter"
        tabItems={tabItems}
        // @ts-ignore
        selectedTabItem={quickFilter}
        setSelectedItem={(itemId) => {
          //Prevent changes in ctx store when unnecessary
          if (ctx.store.filter.quickFilter !== itemId) {
            reset({ statuses: [] });
            setValue('quickFilter', itemId);
          }
        }}
      />
    </div>
  );
};

export default observer(LeftFilters);
