import Interweave from 'interweave';
import React from 'react';

import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { ExclamationBadge } from '@zf/stella-react/src/atoms/Badge';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';

type Props = {
  propertyGroupId: string;
  onComplete: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { propertyGroupId, onComplete } = props;
  const { i18n, tenantReducer } = useAppContext();

  const enableAllocation = async () => {
    await sendRequest<PropertyGroupBillingConfigurationType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroupId}/costallocation/enable`
      },
      tenantReducer,
      lang: i18n.lang
    });
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await enableAllocation();
        onComplete();

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.enable_allocation_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.enable_allocation_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>
        <Interweave
          content={i18n.getTranslation('property_groups.tabs.billing.enable_allocations_paragraph')}
        ></Interweave>
      </Paragraph>
      <br />
      <Center type="both">
        <ExclamationBadge title={i18n.getTranslation('property_groups.tabs.billing.enable_warning')} type="warning" />
      </Center>
    </>
  );
});
