import { CollectionCaseType } from '@zf/api-types/collection-case';
import { uiCulture } from '@zf/api-types/enums';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { createHeader, METHODS, sendRequest } from '../../utils/request';
import { MarkClosedRequestType } from './close-collection-case-dialog';

export async function markCollectionCaseClosed(
  apiFriendlyValues: MarkClosedRequestType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<CollectionCaseType> {
  return (
    await sendRequest<CollectionCaseType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/CollectionCases/${apiFriendlyValues.id}/markclosed`,
        data: apiFriendlyValues
      },
      customHeaders: createHeader({
        'If-Match': apiFriendlyValues._etag
      }),
      tenantReducer: tenantReducer,
      lang
    })
  ).data;
}

export async function processCollection(
  collCaseId: string,
  date: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<CollectionCaseType> {
  return (
    await sendRequest<CollectionCaseType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/CollectionCases/${collCaseId}/process`,
        query: {
          dateTimeOffset: date
        }
      },
      tenantReducer: tenantReducer,
      lang
    })
  ).data;
}
