import clone from 'clone';
import moment, { Moment } from 'moment';

import { RateType, TaxCodeType, UpdateTaxCodeType } from '@zf/api-types/tax-codes';
import { betweenDates } from '@zf/utils/src/date';
import { roundNumber } from '@zf/utils/src/number';

export const getTaxCodesForInvoiceDate = (taxCodes_: TaxCodeType[], invoiceDate_: Moment) => {
  const codes: TaxCodeType[] = [];

  taxCodes_.forEach((taxCode) => {
    taxCode.rates.forEach((rate) => {
      if (betweenDates(rate.startDateTime, rate.endDateTime, invoiceDate_)) {
        codes.push(taxCode);
      }
    });
  });

  return codes;
};

export const getCurrentRate = (rates: RateType[], startDateTime?: Moment) => {
  // StartDate should be defined when more then one rate
  if (rates.length > 1 && startDateTime) {
    return rates.find((r) => moment(startDateTime).isBetween(r.startDateTime, r.endDateTime, undefined, '[)'));
  }

  if (rates.length > 0) return rates[0];

  return undefined;
};

export const formatTaxCode = (taxCode?: TaxCodeType | UpdateTaxCodeType, startDateTime?: Moment) => {
  if (!taxCode) return '';

  const currentRate = getCurrentRate(taxCode.rates, startDateTime);

  if (!currentRate) return `${taxCode.name} - ??`;

  return `${taxCode.name} - ${roundNumber(currentRate.rate * 100)}%`;
};

export const formatCorrectedTaxCode = (taxCode?: TaxCodeType | UpdateTaxCodeType) => {
  if (!taxCode) return '';

  const currentRate = getCurrentRate(taxCode.rates);

  if (!currentRate) return `${taxCode.name} - ??`;

  return `${taxCode.name} - ${currentRate.rate}%`;
};

export const correctRates = (t: TaxCodeType) => {
  const taxCodeClone = clone(t);

  const ratesClone = clone(t.rates);
  ratesClone.forEach((r) => {
    r.rate = r.rate * 100;
  });

  taxCodeClone.rates = ratesClone;

  return taxCodeClone;
};
