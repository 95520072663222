import React from 'react';

import { paymentMethod } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import { dialog } from '../../../events/dialog-events';
import { useStore } from '../../../hooks/useStore';
import AddMandateDialog from '../dialogs/AddMandateDialog';

export default function useBankAccounts() {
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { customerStore, applicationStore } = useStore();

  const saveBankingDetails = async () => {
    if (
      customerStore.defaultBankAccount?.activeMandate === null &&
      customerStore.bankDetailsForm._get('paymentMethod') === paymentMethod.sdd
    ) {
      dialog.normal({
        title: applicationStore.getTranslation('mandates.add_mandate'),
        icon: 'plus',
        content: (
          <AddMandateDialog
            ref={clickRef}
            defaultBankAccount={customerStore.defaultBankAccount}
            validationRef={validationRef}
          />
        ),
        buttonPositive: applicationStore.getTranslation('general.add'),
        ref: validationRef,
        onSubmit
      });
    } else {
      customerStore.bankDetailsForm._submit();
    }
  };

  return {
    saveBankingDetails
  };
}
