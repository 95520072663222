import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { ConfigHelp } from '../../../components/CoachMarks';
import { CardBody } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import AdvanceAmountCardBody from './AdvanceAmountCardBody';

const AdvanceAmountCard = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="advance-amount-card" width="3">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={getTranslation('contracts.advance_amount')}
            content={[getTranslation('coachmark.advance_amount')]}
          />
        }
      >
        {getTranslation('contracts.advance_amount')}
      </CardHeader>
      <CardBody>
        <AdvanceAmountCardBody />
      </CardBody>
    </Card>
  );
};

export default observer(AdvanceAmountCard);
