import React from 'react';

import { CollectionCaseType } from '@zf/api-types/collection-case';

import CloseCollectionCaseListItem from '../../../components/list-items/collection-cases/CloseCollectionCaseListItem';
import ProcessCollectionCaseListItem from '../../../components/list-items/collection-cases/ProcessCollectionCaseListItem';

type Props = {
  collectionCase: CollectionCaseType;
  refresh: () => void;
};

const CollectionCaseActions = (props: Props) => {
  const { collectionCase, refresh } = props;

  return (
    <>
      <CloseCollectionCaseListItem collectionCase={collectionCase} refresh={refresh} />
      <ProcessCollectionCaseListItem collectionCase={collectionCase} refresh={refresh} />
    </>
  );
};

export default CollectionCaseActions;
