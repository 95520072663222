import classNames from 'classnames';
import React, { createElement, CSSProperties, memo, ReactNode } from 'react';

import { CoachMark } from '@zf/heat-erp/src/design-system/Components';

import css from './heading.module.scss';

type Props = {
  children?: ReactNode;
  style?: 'regular' | 'light' | 'semibold' | 'bold';
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  cssStyle?: CSSProperties;
  className?: string;
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
  noMarginBottom?: boolean;
  coachMarkTitle?: string;
  coachMark?: ReactNode;
  uppercase?: boolean;
};

const Heading = memo((props: Props) => {
  const {
    className,
    headingType = 'h1',
    style = 'regular',
    coachMark,
    color,
    textAlign,
    coachMarkTitle = '',
    noMarginBottom = false,
    uppercase = false,
    cssStyle = {}
  } = props;
  const { children } = props;

  if (color) {
    cssStyle.color = color;
  }

  if (noMarginBottom) {
    cssStyle.marginBottom = '0rem';
  }

  if (textAlign) {
    cssStyle.textAlign = textAlign;
  }

  if (uppercase) {
    cssStyle.textTransform = 'uppercase';
  }

  const heading = createElement(
    headingType,
    {
      className: classNames(css['heading'], { [css['with-info']]: !!coachMark }, css[style], className),
      style: cssStyle
    },
    children
  );

  return coachMark ? (
    <div className={css['heading-info-wrapper']}>
      {heading}
      <CoachMark title={coachMarkTitle}>{coachMark}</CoachMark>
    </div>
  ) : (
    heading
  );
});

export default Heading;
