import React from 'react';

import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import { useAppContext } from '../../../app-context/app-context';

export default function usePaymentTabs() {
  const { i18n } = useAppContext();

  const [tabItems] = React.useState<TabItemType[]>([
    {
      id: 'incoming-banking-transactions',
      title: i18n.getTranslation('incoming_banking_transactions.incoming')
    },
    {
      id: 'outgoing-banking-transactions',
      title: i18n.getTranslation('outgoing_banking_transactions.outgoing')
    },
    {
      id: 'payments',
      title: i18n.getTranslation('payments.payments')
    }
  ]);

  return tabItems;
}
