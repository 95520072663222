import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import {
  exportOutgoingBankingTransactionPaymentRefundFormatType,
  outgoingBankingTransactionType
} from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import DownloadOutgoingBankingTransactionDialog from '../../../actions/outgoing-banking-transactions/DownloadOutgoingBankingTransactionDialog';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  refresh: () => void;
};

export default function DownloadDetailButton(props: Props) {
  const { outgoingBankingTransaction, refresh } = props;
  const { applicationStore, outgoingBankingTransactionsStore } = useStore();
  const { getTranslation } = applicationStore;
  const { downloadOutgoingBankingTransaction } = outgoingBankingTransactionsStore.outgoingBankingTransactionsService;
  const { processDownloadedData } = useDownload();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const fileFormatsResponse = useSuspenseSingleAPI<exportOutgoingBankingTransactionPaymentRefundFormatType[]>({
    request: {
      endpoint: `/bill/integration/export/outgoingbankingtransaction/${
        outgoingBankingTransaction?.type === outgoingBankingTransactionType.paymentrequests
          ? 'paymentrequest'
          : 'paymentrefund'
      }/enabledFormats`
    }
  });

  if (!fileFormatsResponse.result) return null;

  const fileFormats = fileFormatsResponse.result.data;

  return fileFormats.length === 1 ? (
    <DropdownAction
      id="outgoing_banking_transaction.download"
      icon="download-cloud"
      actionType="direct"
      onClick={async () => {
        try {
          const res = await downloadOutgoingBankingTransaction(
            outgoingBankingTransaction,
            fileFormats[0] as exportOutgoingBankingTransactionPaymentRefundFormatType
          );

          processDownloadedData(res);
          refresh();
        } catch (e) {
          notify.error({
            content: getTranslation('actions.payments.download_failed'),
            error: e
          });
        }
      }}
    >
      {getTranslation('actions.download')}
    </DropdownAction>
  ) : (
    <DropdownAction
      id="outgoing_banking_transaction.download"
      icon="download-cloud"
      dialogTitle={getTranslation('actions.download')}
      dialogContent={
        <DownloadOutgoingBankingTransactionDialog
          {...props}
          ref={clickRef}
          validationRef={validationRef}
          outgoingBankingTransaction={outgoingBankingTransaction}
        />
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={getTranslation('actions.download')}
    />
  );
}
