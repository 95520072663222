import React from 'react';

import { contractStatus } from '@zf/api-types/enums';
import { ContractRowType } from '@zf/api-types/master-data/contract';
import { ServiceLocationRowType } from '@zf/api-types/master-data/servicelocation';
import useDialog from '@zf/hooks/src/useDialog';

import RecalculateEavDialog from '../../../actions/location/RecalculateEavDialog';
import { DomainType } from '../../../app-context/app-state';
import useCurrent from '../../../app-context/hooks/use-current';
import ForecastingService from '../../../app-context/services/ForecastingService';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  entity: DomainType;
  forecastingService: ForecastingService;
};

export default function RecalculateEavListPageButton(props: Props) {
  const { entity, forecastingService } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { myCurrent } = useCurrent(entity);
  const { onSubmit, clickRef } = useDialog();

  let selectedRows = [];
  let selectedIds: string[] = [];
  let disabled = false;

  switch (entity) {
    case 'contract':
      selectedRows = myCurrent.listPage.selectedRows as ContractRowType[];
      disabled = !selectedRows.every(
        (c) =>
          c.__currentContractStatus === contractStatus.signed || c.__currentContractStatus === contractStatus.terminated
      );

      selectedIds = selectedRows.reduce((acc: string[], row: ContractRowType) => {
        acc.push(row.__id);
        return acc;
      }, []);

      break;
    case 'location':
      selectedRows = myCurrent.listPage.selectedRows as ServiceLocationRowType[];
      selectedIds = selectedRows.reduce((acc: string[], row: ServiceLocationRowType) => {
        acc.push(row.__id);
        return acc;
      }, []);
      break;
  }

  return (
    <DropdownAction
      id="recalculate_eav"
      dialogTitle={getTranslation('actions.recalculate_eav')}
      icon="calculator"
      dialogContent={
        <RecalculateEavDialog
          ref={clickRef}
          selectedRows={selectedIds}
          entity={entity}
          forecastingService={forecastingService}
        />
      }
      disabled={selectedRows.length === 0 || disabled}
      onSubmit={onSubmit}
      buttonPositive={getTranslation('general.recalculate')}
    />
  );
}
