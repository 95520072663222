import { action, makeObservable, observable } from 'mobx';

import { SortDirection, SortStateType } from '@zf/stella-react/src/atoms/Table';

import { RequestType } from '../../../../types/Request';

export default class CreateRequestService {
  public selectedIds: string[] = [];
  public sortBy: string[] = [];
  public sortDirection: Record<string, SortDirection> = {};

  constructor() {
    makeObservable(this, {
      selectedIds: observable,
      sortBy: observable,
      sortDirection: observable,

      handleSort: action,
      createRequest: action,
      setSelectedIds: action,
      resetStore: action
    });
  }

  handleSort = (sortParams: SortStateType) => {
    const { sortBy, sortDirection } = sortParams;
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  };

  setSelectedIds = (newIds: string[]) => {
    this.selectedIds = newIds;
  };

  createRequest = (request: RequestType) => {
    const queryParams = request.query;
    const keys = Object.keys(this.sortDirection);

    const query = {
      ...queryParams,
      orderBy:
        keys.length > 0
          ? keys.reduce((acc, key) => {
              if (this.sortDirection[key]) {
                acc += `${this.sortDirection[key] === 'ASC' ? '' : '-'}${key}`;
              }

              return acc;
            }, '')
          : queryParams
          ? queryParams.orderBy
          : ''
    };

    return { ...request, query };
  };

  resetStore = () => {
    this.selectedIds = [];
    this.sortBy = [];
    this.sortDirection = {};
  };
}
