import classNames from 'classnames';
import React from 'react';

import css from './dashboard-column.module.scss';

type Props = {
  children: JSX.Element[];
  className?: string;
  withBorder?: boolean;
};

export default function DashboardColumn(props: Props) {
  const { children, className, withBorder = false } = props;

  return (
    <div className={classNames(css['dashboard-column'], { [css['with-border']]: withBorder }, className)}>
      {children}
    </div>
  );
}
