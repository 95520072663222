import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { advanceFrequency, invoiceFrequency } from '@zf/api-types/enums';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { Card, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';

import ProductAutoFill, { ProductAutofillProps } from '../../../../components/Autofills/ProductAutoFill';
import BankAccountsDropdown from '../../../../components/Dropdown/bankaccounts-dropdown/BankAccountsDropdown';
import PaymentTermsDropdown from '../../../../components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';
import { SuspenseCardBody } from '../../../../components/suspense';
import { useStore } from '../../../../hooks/useStore';
import css from './invoicing-defaults-card.module.scss';

type Props = {
  propertyGroupBillingConfig: Partial<PropertyGroupBillingConfigurationType>;
  setBillingConfigValue: (value: Partial<PropertyGroupBillingConfigurationType>) => void;
};

const InlineNumberDropdown = InlineInputField<SimpleDropdownProps<number>>(SimpleDropdown);
const InlineAdvanceFreqDropdown = InlineInputField<SimpleDropdownProps<advanceFrequency>>(SimpleDropdown);
const InlineInvoiceFreqDropdown = InlineInputField<SimpleDropdownProps<invoiceFrequency>>(SimpleDropdown);
const InlineProductAutoFill = InlineInputField<ProductAutofillProps>(ProductAutoFill);

const InvoicingDefaultsCard = (props: Props) => {
  const { propertyGroupBillingConfig, setBillingConfigValue } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const frequencyIsYearly = propertyGroupBillingConfig.invoiceFrequency === invoiceFrequency.yearly;

  return (
    <Card id="invoicing-defaults-card">
      <CardHeader>{getTranslation('property_groups.invoicing_defaults')}</CardHeader>
      <SuspenseCardBody type="grid">
        <CardItem width="6">
          <Label>{getTranslation('property_groups.advanceFrequency')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineAdvanceFreqDropdown
            id="propertygroup.advance_frequency"
            onChange={(val) => setBillingConfigValue({ advanceFrequency: val[0] })}
            values={getEnum('advanceFrequency')}
            selectedValues={[propertyGroupBillingConfig.advanceFrequency || ('' as advanceFrequency)]}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('property_groups.invoiceFrequency')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInvoiceFreqDropdown
            id="propertygroup.invoice_frequency"
            onChange={(val) => setBillingConfigValue({ invoiceFrequency: val[0] })}
            values={getEnum('invoiceFrequency')}
            selectedValues={[propertyGroupBillingConfig.invoiceFrequency || ('' as invoiceFrequency)]}
          />
        </CardItem>

        <CardItem width="6">
          <Label>
            {frequencyIsYearly
              ? getTranslation('property_groups.invoice_moment')
              : getTranslation('property_groups.invoice_day')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <div className={classNames(css[frequencyIsYearly ? 'invoice-moment' : 'default-invoice'])}>
            <InlineNumberDropdown
              id="propertygroup.invoiceday"
              selectedValues={propertyGroupBillingConfig.invoiceDay ? [propertyGroupBillingConfig.invoiceDay] : []}
              values={[...Array(31).keys()].map((v) => {
                const val = v + 1;
                return {
                  value: val,
                  text: val.toString()
                };
              })}
              onChange={(val) => setBillingConfigValue({ invoiceDay: val[0] })}
              clear
            />
            {frequencyIsYearly && (
              <>
                <div className={css['divider']}>/</div>
                <InlineNumberDropdown
                  id="propertygroup.invoicemonth"
                  selectedValues={
                    propertyGroupBillingConfig.invoiceMonth ? [propertyGroupBillingConfig.invoiceMonth] : []
                  }
                  values={[...Array(12).keys()].map((v) => {
                    const val = v + 1;
                    return {
                      value: val,
                      text: val.toString()
                    };
                  })}
                  onChange={(val) => setBillingConfigValue({ invoiceMonth: val[0] })}
                  clear
                />
              </>
            )}
          </div>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('property_groups.product')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineProductAutoFill
            id="propertygroup.product"
            onChange={(val) => setBillingConfigValue({ productId: val[0]?.id || null })}
            selectedValues={[propertyGroupBillingConfig?.productId || '']}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('payment_terms.payment_term')}</Label>
        </CardItem>
        <CardItem width="6">
          <PaymentTermsDropdown
            id="propertygroup.payment_term"
            onChange={(val) => setBillingConfigValue({ paymentTermsId: val[0]?.id || null })}
            selectedValue={propertyGroupBillingConfig.paymentTermsId || ''}
            showDefault={false}
            isInline
          />
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('organization.bank_account')}</Label>
        </CardItem>
        <CardItem width="6">
          <BankAccountsDropdown
            id="propertygroup.bank_account"
            onChange={(val) => setBillingConfigValue({ companyBankAccountId: val[0]?.id || null })}
            selectedValue={propertyGroupBillingConfig.companyBankAccountId || ''}
            isInline
          />
        </CardItem>
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(InvoicingDefaultsCard);
