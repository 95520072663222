import classNames from 'classnames';
import React from 'react';

import ReactResizeDetector from 'react-resize-detector';

import css from '../dropdown.module.scss';
import { DropdownValuesType } from '../StellaDropdown';
import SimpleDropdownBody from './SimpleDropdownBody';

export type SimpleDropdownProps<T> = {
  id: string;
  placeholder?: string;
  values: DropdownValuesType<T>[];
  selectedValues: T[];
  disabled?: boolean;
  multiSelect?: boolean;
  searchPlaceholder?: string;
  clear?: boolean;
  filterText?: string;
  detailsText?: string;
  clearText?: string;
  emptyText?: string;
  conditionMessage?: string;
  error?: boolean;
  errors?: {};
  singleError?: string;
  className?: string;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  loading?: boolean;
  extraLeftKey?: string;
  extraRight?: React.ReactNode;
  darkMode?: boolean;
  selectSingleItemByDefault?: boolean;
  onFocus?: (step: string) => void;
  onClear?: () => void;
  onChange: (values: Array<T>) => void;
};

export default function StellaSimpleDropdown<T>(props: SimpleDropdownProps<T>) {
  const { style, className } = props;

  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleOpen = React.useCallback(() => setShowTooltip(true), [setShowTooltip]);

  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width = 0, targetRef }) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <div
            ref={targetRef as React.RefObject<HTMLDivElement> | undefined}
            className={classNames(css['dropdown-container'], className)}
            style={style}
            role="tooltip"
          >
            <SimpleDropdownBody
              {...props}
              dropdownWidth={width}
              showTooltip={showTooltip}
              setShowTooltip={setShowTooltip}
              handleOpen={handleOpen}
              onFocus={handleOpen}
            />
          </div>
        );
      }}
    </ReactResizeDetector>
  );
}
