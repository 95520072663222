import ProductAutoFill from 'components/Autofills/ProductAutoFill';
import InputField, { InputFieldProps } from 'components/input/InputField';
import { Info, SettingDescription } from 'design-system/ComponentSets';
import { Spinner } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { invoiceFrequency } from '@zf/api-types/enums';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';

import RequiredEstimationsSection from './RequiredEstimationsSection';

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);

const ContractDataForm = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { regularMoveInWizardStore } = contractStore.moveInWizardBaseStore;
  const { regularMoveInWizardValues, locationSectionStore, organizationConfig, consumerGroups } =
    regularMoveInWizardStore;
  const { wizardCalcNextInvoiceDate } = locationSectionStore;
  const { values, setValue } = regularMoveInWizardValues;

  if (!consumerGroups || !organizationConfig) return <Spinner centered />;

  const handleCustomProdConfigChange = (isCustom: boolean) => {
    const nextInvoiceDate = wizardCalcNextInvoiceDate(values.supplyStartDate, values.invoiceFrequency);

    setValue({
      hasCustomProductConfig: isCustom,
      usePropertyGroupProduct: true,
      invoiceFrequency: invoiceFrequency.yearly,
      nextInvoiceDate
    });
  };

  const showProductDropdown =
    !values.usePropertyGroupProduct || (values.hasCustomProductConfig && values.usePropertyGroupProduct);

  return (
    <>
      {((typeof values.contractNumber !== 'undefined' && organizationConfig.manuallySetContractNumber) ||
        organizationConfig.migrationMode) && (
        <SettingDescription
          title={getTranslation('contracts.wizard.number_title')}
          description={getTranslation('contracts.wizard.number_descr')}
          setting={
            <WizardInputField
              id="contracts.contractId"
              onChange={(contractNumber) => setValue({ contractNumber })}
              value={values.contractNumber}
              placeholder={getTranslation('contracts.contract_id')}
              error={!values.contractNumber}
            />
          }
        />
      )}

      <SettingDescription
        title={getTranslation('contracts.wizard.product_title')}
        description={getTranslation('contracts.wizard.product_descr')}
        setting={
          showProductDropdown && (
            <ProductAutoFill
              id="contracts.product"
              onChange={(value) => {
                setValue({
                  productId: value[0]?.id || '',
                  invoiceUpfront: value[0] ? value[0].invoiceUpfront : false
                });
              }}
              selectedValues={[values.productId]}
              error={!values.productId}
            />
          )
        }
        isMandatory
      />

      {values.usePropertyGroupProduct && (
        <>
          {!values.hasCustomProductConfig && <Info>{getTranslation('contracts.wizard.custom_prod_config_info')}</Info>}
          <CheckBox
            id="contracts.deviatingProdCfg"
            checked={values.hasCustomProductConfig}
            onChange={handleCustomProdConfigChange}
          >
            {getTranslation('contracts.wizard.custom_prod_config_checkbox')}
          </CheckBox>
        </>
      )}

      <SettingDescription
        title={getTranslation('contracts.wizard.consumption_group_title')}
        description={getTranslation('contracts.wizard.consumption_group_descr')}
        setting={<div />}
        fullWidth
        isMandatory
      />

      <RequiredEstimationsSection consumerGroups={consumerGroups} />

      <SettingDescription
        title={getTranslation('contracts.wizard.external_ref_title')}
        description={getTranslation('contracts.wizard.external_ref_descr')}
        setting={
          <WizardInputField
            id="contracts.externalId"
            onChange={(externalContractReference) => setValue({ externalContractReference })}
            value={values.externalContractReference}
            placeholder={getTranslation('contracts.wizard.external_ref_title')}
          />
        }
      />
    </>
  );
};

export default observer(ContractDataForm);
