import clone from 'clone';
import React from 'react';

import {
  IncomingInvoiceComponentType,
  UpdateIncomingInvoiceComponentType
} from '@zf/api-types/incoming-invoice-component';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';

import { useAppContext } from '../../../../../../app-context/app-context';
import Button from '../../../../../../components/Button/Button';
import { DeleteIcon } from '../../../../../../components/Icon';
import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import { notify } from '../../../../../../events/notification-events';
import { METHODS, sendRequest } from '../../../../../../utils/request';
import useBillingData from '../../hooks/use-billing-data';
import css from './incoming-invoices-tab.module.scss';

type Props = {
  propertyGroupId: string;
  components: IncomingInvoiceComponentType[];
  billingConfig: PropertyGroupBillingConfigurationType;
};

type ValidatorType = {
  components: UpdateIncomingInvoiceComponentType[];
};

export default function IncomingInvoiceComponentCard(props: Props) {
  const { propertyGroupId, components, billingConfig } = props;

  const { i18n, tenantReducer, authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const { updateIncomingInvoiceComponents } = useBillingData();

  const [selectedRow, setSelectedRow] = React.useState(-1);

  const configuredComponents = billingConfig.requiredIncomingInvoiceComponents
    ? components.filter((c) => billingConfig.requiredIncomingInvoiceComponents?.some((riic) => riic.id === c.id))
    : [];

  const { values, isDirty, submitFactory, setValue, restoreValues } = useValidator<ValidatorType>({
    initialValues: { components: clone(configuredComponents) }
  });

  const handleSave = submitFactory(async () => {
    try {
      const apiFriendlyValues = {
        requiredIncomingInvoiceComponents: values.components.map((c) => {
          return { id: c.id, optional: false };
        }),
        calculationConfigurations: billingConfig.calculationConfigurations
      };

      await sendRequest<PropertyGroupBillingConfigurationType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroupId}/costallocation`,
          data: apiFriendlyValues
        },
        tenantReducer,
        lang: i18n.lang
      });

      notify.success({
        content: i18n.getTranslation('cost_allocation_config.update_invoice_componets_success')
      });

      setValue(values, false, true);
      updateIncomingInvoiceComponents(values.components);
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('cost_allocation_config.update_invoice_componets_fail'),
        error: e
      });
    }
  });

  const handleCancel = () => {
    setSelectedRow(-1);
    restoreValues();
  };

  const addComponent = () => {
    setSelectedRow(values.components.length);
    setValue({
      components: [...values.components, { id: '', name: '', description: '', optional: false }]
    });
  };

  const deleteComponent = (index: number) => {
    const clonedArray = [...values.components];
    clonedArray.splice(index, 1);

    setSelectedRow(-1);

    setValue({
      components: clonedArray
    });
  };

  const dropdownValues = components.map((c) => {
    return {
      text: c.name,
      value: c.name
    };
  });

  const setComponent = (name: string, index: number) => {
    const match = components.find((c) => {
      return c.name === name;
    });
    if (match) {
      const componentsClone = clone(values.components);
      componentsClone[index] = match;
      setValue({ components: componentsClone });
    }
  };

  const rows = values.components.map((component, index) => {
    return {
      name: (
        <SimpleDropdown
          id={`incoming_invoice_component.name.index-${index}`}
          onChange={(val) => setComponent(val[0], index)}
          values={dropdownValues}
          selectedValues={[component.name]}
          error={!component.name}
          clear
        />
      ),
      description: (
        <Paragraph id={`incoming_invoice_component.description.index-${index}`}>{component.description}</Paragraph>
      ),
      deleteAction: (
        <DeleteIcon
          id={`property_group_billing_config.update_required_components.index-${index}`}
          tooltipFor="incoming-invoice-components-table"
          onClick={() => deleteComponent(index)}
        />
      )
    };
  });

  const isAuthenticated = hasPermission('property_group_billing_config.update_required_components');

  return (
    <Card id="incoming-invoice-components" className={css['incoming-invoice-components']} width="1">
      <CardHeader
        primaryText={isAuthenticated && isDirty ? i18n.getTranslation('general.save') : ''}
        onPrimaryClick={isDirty ? handleSave : undefined}
        secondaryText={isDirty ? i18n.getTranslation('general.cancel') : undefined}
        onSecondaryClick={handleCancel}
        extraRight={
          !isDirty && (
            <Button
              id="property_group_billing_config.update_required_components"
              type="text"
              icon="plus"
              onClick={addComponent}
            >
              {i18n.getTranslation('general.add')}
            </Button>
          )
        }
      >
        {i18n.getTranslation('cost_allocation_config.incoming_invoice_components')}
      </CardHeader>
      <CardBody>
        <DeprecatedStaticTable
          tooltipId="incoming-invoice-components-table"
          rows={rows}
          onRowSelect={setSelectedRow}
          selectedIndex={selectedRow}
        >
          <DeprecatedStaticColumn flexWidth="1" dataKey="name" label={i18n.getTranslation('general.name')} />
          <DeprecatedStaticColumn
            flexWidth="1"
            dataKey="description"
            label={i18n.getTranslation('general.description')}
          />
          <DeprecatedStaticColumn dataKey="deleteAction" showOnHover />
        </DeprecatedStaticTable>
      </CardBody>
    </Card>
  );
}
