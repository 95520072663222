import moment, { Moment } from 'moment';
import React from 'react';

import { navigate } from '@reach/router';
import { meterType } from '@zf/api-types/enums';
import { ChannelTemplateType, CreateMeterRequestType, MeterType } from '@zf/api-types/master-data/meter';
import { ModelPagedResultType } from '@zf/api-types/model';
import useValidator from '@zf/hooks/src/useValidator';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import useWizardAPIErrors from '../../../../app-context/hooks/useWizardAPIErrors';
import WizardSubmitButton from '../../../../components/Button/WizardSubmitButton';
import { notify } from '../../../../events/notification-events';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import { extendMultiValue } from '../../../../utils/arrays';
import { METHODS, sendRequest } from '../../../../utils/request';
import ChannelsSection from './ChannelsSection';
import EssentialsSection from './EssentialsSection';

export type Props = {
  id?: string; // This wizard is used in multiValues => unique id's required
  index?: number; // This wizard is used in multiValues => unique id's required
  startingIndex?: number;
  meterType_?: meterType; // Used for prefilled meter type
  feedback: string[][];
  onFocusStep: (step: string) => void;
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>;
  setNewMeter?: (meter: MeterType) => void;
  setCreateNewMeter?: (value: boolean) => void;
};

export type CreateMeterType = {
  serialNumber: string;
  meterTag: string;
  meterType: meterType;
  modelId: string;
  mutationDateTime: Moment;
  channels: ChannelTemplateType[];
};

export const STEP_NAMES = ['meter-essentials', 'meter-channels', 'meter-errors'];

export default function MeterWizard(props: Props) {
  const {
    id,
    index,
    meterType_,
    feedback,
    onFocusStep,
    setNewMeter,
    setFeedback,
    startingIndex = 0,
    setCreateNewMeter
  } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const { setApiErrors, handleApiErrors } = useWizardAPIErrors(startingIndex + 2, setFeedback);

  const { values, errors, setValue, submitFactory } = useValidator<CreateMeterType>({
    initialValues: {
      serialNumber: '',
      meterTag: '',
      meterType: meterType_ || ('' as meterType),
      modelId: '',
      mutationDateTime: moment(MIN_DATE),
      channels: []
    },
    validate: () => {
      const feedback: string[][] = [];

      if (
        !values.serialNumber ||
        !values.meterType ||
        !values.modelId ||
        !values.mutationDateTime ||
        isMinDate(values.mutationDateTime)
      ) {
        extendMultiValue(feedback, startingIndex + 0, i18n.getTranslation('meter.validation.essentials'));
      }

      if (!values.channels || (values.channels && values.channels.length === 0)) {
        extendMultiValue(feedback, startingIndex + 1, i18n.getTranslation('meter.validation.channels'));
      }

      if (values.channels.length !== 0) {
        values.channels.forEach((chann) => {
          if (!chann.description) {
            extendMultiValue(feedback, startingIndex + 1, i18n.getTranslation('meter.validation.description'));
          }
          if (!chann.dataFrequency) {
            extendMultiValue(feedback, startingIndex + 1, i18n.getTranslation('meter.validation.dataFrequency'));
          }
          if (!chann.incrementationType) {
            extendMultiValue(feedback, startingIndex + 1, i18n.getTranslation('meter.validation.incrementationType'));
          }
          if (!chann.direction) {
            extendMultiValue(feedback, startingIndex + 1, i18n.getTranslation('meter.validation.direction'));
          }
        });
      }

      handleApiErrors(feedback, []);

      return { feedback };
    }
  });

  const response = useSuspenseSingleAPI<ModelPagedResultType>({
    request: {
      endpoint: '/cfg/models/'
    }
  });

  if (!response.result) return null;

  const models = response.result.data.results;

  const handleFocus = (step: string) => {
    return () => onFocusStep(step);
  };

  const handleSubmit = submitFactory(async () => {
    const apiFriendlyValues: CreateMeterRequestType = {
      serialNumber: values.serialNumber,
      meterTag: values.meterTag,
      meterType: values.meterType,
      modelId: values.modelId,
      mutationDateTime: values.mutationDateTime.toISOString(),
      channels: values.channels.map((channel) => {
        return {
          dataFrequency: channel.dataFrequency,
          meteringType: channel.meteringType,
          utilityType: channel.utilityType,
          direction: channel.direction,
          incrementationType: channel.incrementationType,
          timeOfUse: channel.timeOfUse,
          unitOfMeasure: channel.unitOfMeasure,
          externalIdentifier: channel.externalIdentifier,
          description: channel.description,
          relationExternalReference: channel.relationExternalReference
        };
      })
    };

    try {
      const res = (
        await sendRequest<MeterType>({
          request: {
            method: METHODS.POST,
            endpoint: '/md/meters',
            data: apiFriendlyValues
          },
          tenantReducer,
          lang: i18n.lang
        })
      ).data;

      notify.success({ content: i18n.getTranslation('meter.notify_created') });
      if (setCreateNewMeter) {
        setCreateNewMeter(false);
      }
      setNewMeter ? setNewMeter(res) : navigate(`${rootUrl}/devices/meters/${res.id}`);
    } catch (e) {
      //@ts-ignore
      const apiErrors_ = e.data && e.data.errors ? e.data.errors : [];
      handleApiErrors([], apiErrors_);
      setApiErrors(apiErrors_);
    }
  });

  return (
    <div id={id}>
      <EssentialsSection index={index} models={models} values={values} setValue={setValue} handleFocus={handleFocus} />
      <ChannelsSection index={index} models={models} values={values} setValue={setValue} handleFocus={handleFocus} />
      <WizardSubmitButton
        id="meter-submit"
        onClick={handleSubmit}
        disabled={errors.feedback.length > 0 && feedback.length > 0}
      >
        {i18n.getTranslation('general.submit')}
      </WizardSubmitButton>
    </div>
  );
}
