import React from 'react';

import { PaymentParametersType } from '@zf/api-types/parameters';
import { PaymentTermType } from '@zf/api-types/product';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { PagedResponseType } from '../../../../../api-types/api';
import { useAppContext } from '../../../app-context/app-context';
import useSingleAPI from '../../../hooks/useSingleAPI';
import Dropdown from '../../Lang/Dropdown';

export type PaymentTermDropdownProps = {
  id: string;
  isInline?: boolean;
  selectedValue?: string;
  showDefault?: boolean;
  onFocus?: (step: string) => void;
  onChange: (values: PaymentTermType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<PaymentTermType>>(Dropdown);

export default function PaymentTermsDropdown(props: PaymentTermDropdownProps) {
  const { id, selectedValue = '', showDefault = true, isInline = false, onFocus, onChange } = props;
  const { i18n, enumReducer } = useAppContext();

  const paymentParameters = useSingleAPI<PaymentParametersType>({
    request: {
      endpoint: '/cfg/Parameters/payment'
    }
  });

  const paymentTermResponse = useSingleAPI<PagedResponseType<PaymentTermType>>({
    request: {
      endpoint: '/cfg/PaymentTerms'
    }
  });

  if (
    !paymentParameters ||
    !paymentParameters.result ||
    paymentParameters.result instanceof Promise ||
    !paymentTermResponse ||
    !paymentTermResponse.result ||
    paymentTermResponse.result instanceof Promise
  ) {
    return isInline ? (
      <InlineDropdown id={id} onChange={onChange} values={[]} selectedValues={[selectedValue]} onFocus={onFocus} />
    ) : (
      <Dropdown
        id={id}
        onChange={onChange}
        placeholder={i18n.getTranslation('payment_terms.payment_term')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    );
  }

  const defaultId = paymentParameters.result.data.paymentTermsId || '';
  const selectedValues = showDefault ? [selectedValue || defaultId] : [selectedValue || ''];

  const dropdownTerms = paymentTermResponse.result.data.results.map((term) => {
    return {
      value: term,
      text: `${term.name} - ${term.amount} ${enumReducer
        .getTranslation('paymentTermsFrequency', term.paymentTermsFrequency)
        .toLowerCase()} ${term.id === defaultId ? `(${i18n.getTranslation('general.default')})` : ''}`
    };
  });

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={dropdownTerms}
      selectedValues={selectedValues}
      onFocus={onFocus}
    />
  ) : (
    <Dropdown
      id={id}
      onChange={onChange}
      placeholder={i18n.getTranslation('payment_terms.payment_term')}
      values={dropdownTerms}
      selectedValues={selectedValues}
      onFocus={onFocus}
    />
  );
}
