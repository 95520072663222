import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { invoiceStatus } from '@zf/api-types/enums';

import InvoicePreviewCreated from './InvoicePreviewCreated';
import InvoicePreviewPDF from './InvoicePreviewPDF';
import NoInvoicePreview from './NoInvoicePreview';

const InvoicePreview = () => {
  const { invoiceStore } = useStore();

  const { invoice } = invoiceStore.selectedInvoice;

  if (invoice.attachmentId) {
    return <InvoicePreviewPDF invoice={invoice} />;
  } else if (invoice.status === invoiceStatus.generated) {
    return <NoInvoicePreview />;
  }

  return <InvoicePreviewCreated invoice={invoice} />;
};

export default observer(InvoicePreview);
