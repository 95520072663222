import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import React from 'react';

import { AggregatedDetailType } from '@zf/api-types/api';

import DetailLine from '../DetailLine/DetailLine';
import InfoGrid from '../InfoGrid/InfoGrid';
import css from './info-grid-footer.module.scss';

type Props = {
  showSidePanel: boolean;
  aggregateDetails: AggregatedDetailType[];
};

const InfoGridFooter = (props: Props) => {
  const { showSidePanel, aggregateDetails } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      {aggregateDetails.length > 0 && (
        <InfoGrid nCols={3} className={css[`${showSidePanel ? 'info-grid-footer' : 'info-grid-footer-margin-right'}`]}>
          {aggregateDetails.map((i) => {
            if (i.code && i.code.split('.').length >= 2) {
              let label = i.code.split('.')[1];

              if (i.value !== 0) {
                return (
                  <DetailLine key={label} label={getTranslation(`meter_issues.error_${label}`)}>
                    <Paragraph>{i.value}</Paragraph>
                  </DetailLine>
                );
              }
            }
            return null;
          })}
        </InfoGrid>
      )}
    </>
  );
};

export default InfoGridFooter;
