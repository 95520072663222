import classNames from 'classnames';
import React from 'react';

type IconPropsType = {
  id?: string;
  name: string;
  style?: React.CSSProperties;
  className?: string;
  title?: string;
  tooltipFor?: string;
};

export default function TableIcon(props: IconPropsType) {
  const { name, className, title, style, tooltipFor, ...otherProps } = props;
  const iconClassName = `icon-${name ? name.toLowerCase() : ''}`;

  return (
    <div data-for={tooltipFor} data-tip={title}>
      <span {...otherProps} style={style} className={classNames('icon', iconClassName, className)} />
    </div>
  );
}
