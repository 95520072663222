import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { MeterPropertiesPrepayment, MeterType, PrepaymentDeviceRowType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import useListPage from '../../../../../app-context/hooks/use-listpage';
import EmptyLink from '../../../../../components/Link/EmptyLink';
import { Paragraph } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import { DefaultPrepaymentDeviceQuery } from './usePrepaymentDeviceFilter';

const usePrepaymentDeviceListPage = (models: ModelType[]) => {
  const { applicationStore } = useStore();
  const { rootUrl, culture } = applicationStore;

  const processRecord = (meter: MeterType): PrepaymentDeviceRowType => {
    const model = models.find((m) => m.id === meter.modelId);
    const properties = meter.meterProperties as MeterPropertiesPrepayment | null;

    return {
      __id: meter.id,
      __meterEntity: meter,
      serialNumber: (
        <EmptyLink
          id={`${routedEntitySubjectType.meter}-${meter.id}`}
          url={`${rootUrl}/devices/prepayment-devices/${meter.id}`}
        >
          {meter.serialNumber}
        </EmptyLink>
      ),
      model: <Paragraph>{`${model?.brandName} - ${model?.name}`}</Paragraph>,
      lastTopUpDate: formatDate(properties?.lastTopUpDate),
      configuredEmergencyCredit: formatMoney(properties?.configuredEmergencyCredit, culture),
      debt: (
        <Paragraph color={properties && properties?.debt > 0 ? colors['red-600'] : undefined}>
          {formatMoney(properties?.debt, culture)}
        </Paragraph>
      ),
      currentBalance: formatMoney(properties?.currentBalance, culture)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<MeterType, PrepaymentDeviceRowType, DefaultPrepaymentDeviceQuery>({
    endpoint: `/md/meters`,
    processRecord,
    domain: 'prepayment_device',
    defaultQueryParams: { meterType: meterType.prepayment }
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default usePrepaymentDeviceListPage;
