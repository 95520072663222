import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React from 'react';

import ClosedDropdown from '@zf/stella-react/src/atoms/Dropdown/ClosedDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';
import { formatPeriod, isMaxDate, isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context/app-context';
import { notify } from '../../events/notification-events';
import DatePicker, { DatePickerProps } from '../Lang/DatePicker';
import AccessControl from '../Permissions/AccessControl';
import css from './date-range-picker-label.module.scss';

export type Props = {
  id: string;
  label: string;
  className?: string;
  error?: boolean;
  placeholderStart?: string;
  placeholderEnd?: string;
  startDate: Moment | null;
  endDate: Moment | null;
  clearStart?: boolean;
  startOfDay_?: boolean;
  onFocus?: (step: string) => void;
  isWizardRangePicker?: boolean;
  setDates: (dates: Array<Moment | null | undefined>) => void;
  darkMode?: boolean;
  renderTopLvl?: boolean;
};

const InlineInputFieldDatePicker = InlineInputField<DatePickerProps>(DatePicker);

export default function DateRangePickerLabel(props: Props) {
  const { i18n } = useAppContext();
  const {
    id,
    label,
    className,
    startDate,
    setDates,
    endDate,
    placeholderStart = i18n.getTranslation('general.start'),
    placeholderEnd = i18n.getTranslation('general.end'),
    error = false,
    darkMode = false,
    onFocus
  } = props;

  const warnEndIsBeforeStart = () => {
    notify.warning({
      content: i18n.getTranslation('datepicker.end_before_start')
    });
  };

  const onChangeStart = (newStartDate: Moment) => {
    if (endDate && newStartDate.isAfter(endDate)) {
      warnEndIsBeforeStart();
    } else {
      setDates([newStartDate, !endDate || (endDate && isMaxDate(endDate)) ? moment(MAX_DATE) : endDate]);
    }
  };

  const onChangeEnd = (newEndDate: Moment) => {
    if (startDate && newEndDate.isBefore(startDate)) {
      warnEndIsBeforeStart();
    } else {
      setDates([!startDate || (startDate && isMinDate(startDate)) ? moment(MIN_DATE) : startDate, newEndDate]);
    }
  };

  const renderDatePickers = () => {
    return (
      <TooltipTrigger
        tooltip={
          <>
            <div className={css['label']}>{label}</div>
            <div className={css['date-range']}>
              <InlineInputFieldDatePicker
                {...props}
                id={`${id}-start`}
                onChange={(val) => onChangeStart(val)}
                value={startDate}
                placeholder={placeholderStart}
                onFocus={onFocus}
                error={error}
                darkMode={darkMode}
                hideLabel
                renderTopLvl={false}
                clear={startDate !== null && !isMinDate(startDate)}
              />
              <InlineInputFieldDatePicker
                {...props}
                id={`${id}-end`}
                onChange={(val) => onChangeEnd(val)}
                value={endDate}
                placeholder={placeholderEnd}
                onFocus={onFocus}
                error={error}
                darkMode={darkMode}
                hideLabel
                maxDate
                renderTopLvl={false}
                clear={endDate !== null && !isMaxDate(endDate)}
              />
            </div>
          </>
        }
        placement="bottom-start"
        trigger="click"
      >
        <ClosedDropdown label={label} />
      </TooltipTrigger>
    );
  };

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{formatPeriod(startDate, endDate)}</Paragraph>}>
      <div id={id} className={classNames(css['date-range-picker-label'], className)}>
        <div className={css['period-filter']}>{renderDatePickers()}</div>
      </div>
    </AccessControl>
  );
}
