import moment, { Moment } from 'moment';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import { attachmentVisibility } from '@zf/api-types/enums';
import { CultureTableType } from '@zf/api-types/language';
import { ProductAttachmentFileType, ProductAttachmentType } from '@zf/api-types/product';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { isMaxDate, isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import Translations from '../../../../../components/units/EntityAttachments/ui/dialogs/Translations';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { createEmptyAttachmentFile } from '../../../../../utils/product';
import css from './product-attachments-dialog.module.scss';
import Options from '../../../../../components/units/EntityAttachments/ui/dialogs/Options';

type Props = {
  cultureTable: CultureTableType;
  validationRef: MutableRefObject<ValidationRef | undefined>;
  hasPortal: boolean;
  addLocalProductAttachment?: (newAttachment: ProductAttachmentType) => void;

  // edit mode
  productAttachment?: ProductAttachmentType;
  updateLocalProductAttachment?: (newAttachment: ProductAttachmentType) => void;
};

export type State = {
  productAttachmentId: string;
  includeInWelcomeEmail: boolean;
  visibility: attachmentVisibility;
  approvalRequired: boolean;
  validFrom: Moment | null;
  validUntil: Moment | null;
  attachmentFiles: ProductAttachmentFileType[];
};

const ProductAttachmentsDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const {
    productAttachment,
    cultureTable,
    validationRef,
    hasPortal,
    addLocalProductAttachment,
    updateLocalProductAttachment
  } = props;

  const initialAttachmentFiles: ProductAttachmentFileType[] = productAttachment
    ? cultureTable.supportedCultures.map((sc) => {
        const matchingAttachmentFile = productAttachment.attachmentFiles.find((af) => af.culture === sc);

        return matchingAttachmentFile
          ? {
              ...matchingAttachmentFile,
              file: {
                id: matchingAttachmentFile.attachmentId,
                internalFileName: matchingAttachmentFile.internalFileName,
                file: matchingAttachmentFile.file?.file || undefined,
                state: 'none'
              },
              isDefault: matchingAttachmentFile.culture === cultureTable.defaultCulture
            }
          : createEmptyAttachmentFile(sc, sc === cultureTable.defaultCulture);
      })
    : cultureTable.supportedCultures.map((sc) => {
        return createEmptyAttachmentFile(sc, sc === cultureTable.defaultCulture);
      });

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    productAttachmentId: productAttachment?.productAttachmentId || '',
    includeInWelcomeEmail: productAttachment?.includeInWelcomeEmail || false,
    visibility: productAttachment && hasPortal ? productAttachment.visibility : attachmentVisibility.portal,
    approvalRequired: productAttachment && hasPortal ? productAttachment.approvalRequired : false,
    validFrom: productAttachment ? moment(productAttachment.validFrom) : moment(),
    validUntil: moment(productAttachment ? productAttachment.validUntil : MAX_DATE),
    attachmentFiles: initialAttachmentFiles
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      const newAttachment = {
        productAttachmentId: '',
        includeInWelcomeEmail: state.includeInWelcomeEmail,
        visibility: state.visibility,
        approvalRequired: state.approvalRequired,
        validFrom: state.validFrom ? state.validFrom.toISOString() : MIN_DATE,
        validUntil: state.validUntil ? state.validUntil.toISOString() : MAX_DATE,
        attachmentFiles: state.attachmentFiles
      };

      if (addLocalProductAttachment) {
        addLocalProductAttachment(newAttachment);
      }

      if (updateLocalProductAttachment) {
        updateLocalProductAttachment(newAttachment);
      }
    }
  }));

  const setAttachmentValue = (index: number, val: Partial<ProductAttachmentFileType>) => {
    const clonedAttachments = [...state.attachmentFiles];
    clonedAttachments[index] = { ...clonedAttachments[index], ...val };
    setState({ attachmentFiles: clonedAttachments });
  };

  const validate = () => {
    if (validationRef && validationRef.current) {
      const translationsInvalid = state.attachmentFiles.some((af) => {
        const defaultIsInvalid = af.isDefault && (!af.fileName || !af.file);
        const extraIsInvalid = !af.isDefault && !af.fileName && !!af.file;

        return defaultIsInvalid || extraIsInvalid;
      });

      validationRef.current.setIsError(
        translationsInvalid ||
          (!!state.validFrom && isMinDate(state.validFrom) && !!state.validUntil && isMaxDate(state.validUntil))
      );
    }
  };

  useEffect(() => {
    validate();
  }, [state]);

  return (
    <div className={css['dialog-wrapper']}>
      <Translations
        isDisabled={productAttachment !== undefined}
        attachmentFiles={state.attachmentFiles}
        setAttachmentValue={setAttachmentValue}
      />

      <HorizontalDivider className={css['divider']} />

      <Options hasPortal={hasPortal} state={state} setState={setState} />
    </div>
  );
});

export default ProductAttachmentsDialog;
