import React from 'react';

import { IncomingInvoiceComponentDetailsType } from '@zf/api-types/property-group-billing-period';
import useDialog from '@zf/hooks/src/useDialog';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import ConfigCard from '@zf/stella-react/src/atoms/Card/ConfigCard';
import LabelItemGrid from '@zf/stella-react/src/atoms/Card/LabelItemGrid';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatMoney } from '@zf/utils/src/number';

import DeleteIncomingCosts from '../../../../../../actions/property-group/delete-incoming-costs';
import { useAppContext } from '../../../../../../app-context/app-context';
import { DeleteIcon } from '../../../../../../components/Icon';
import MoreActionsMenu from '../../../../../../components/Menus/MoreActionsMenu';
import { DropdownAction } from '../../../../../../design-system/ComponentSets';
import { dialog } from '../../../../../../events/dialog-events';
import useBillingData from '../../hooks/use-billing-data';
import css from './incoming-cost.module.scss';

type Props = {
  index1: number;
  index2: number;
  incomingInvoiceComponentDetails: IncomingInvoiceComponentDetailsType;
};

export default function IncomingCost(props: Props) {
  const { index1, index2, incomingInvoiceComponentDetails } = props;
  const { i18n } = useAppContext();
  const {
    incomingInvoiceComponents,
    incomingInvoices,
    propertyGroupBillingConfiguration,
    selectedBillingPeriod,
    updateBillingPeriod
  } = useBillingData();

  const { clickRef, onSubmit } = useDialog();

  const [showDetails, setShowDetails] = React.useState(false);

  if (!propertyGroupBillingConfiguration || !propertyGroupBillingConfiguration.propertyGroup) return null;

  const matchedIncomingInvoiceComponent = incomingInvoiceComponents.find((iic) => {
    return iic.id === incomingInvoiceComponentDetails.incomingInvoiceComponentId;
  });

  let total = 0;

  incomingInvoiceComponentDetails.values.forEach((v) => {
    total += v.value;
  });

  const openDeleteDialog = (valueId: string) => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.billing.delete_incoming_cost_value'),
      icon: 'trashcan',
      content: (
        <DeleteIncomingCosts
          ref={clickRef}
          propertyGroupId={
            propertyGroupBillingConfiguration && propertyGroupBillingConfiguration.propertyGroup
              ? propertyGroupBillingConfiguration.propertyGroup.id
              : ''
          }
          billingPeriod={selectedBillingPeriod}
          incomingInvoiceCostComponentValueId={valueId}
          updateBillingPeriod={updateBillingPeriod}
        />
      ),
      buttonPositive: i18n.getTranslation('general.delete'),
      type: 'danger',
      onSubmit: onSubmit
    });
  };

  const details = incomingInvoiceComponentDetails.values.reduce((acc: React.ReactNode[], value, index_) => {
    if (value.incomingInvoiceId) {
      const matchedIncomingInvoice = incomingInvoices.find((ii) => {
        return ii.id === value.incomingInvoiceId;
      });

      if (matchedIncomingInvoice) {
        acc.push(
          <React.Fragment key={`value-${index_}`}>
            <CardItem width="6">
              <div className={css['inv-number']}> {matchedIncomingInvoice.invoiceNumber}</div>
            </CardItem>
            <CardItem width="6">
              <Paragraph>{formatMoney(value.value, i18n.culture)}</Paragraph>
              <DeleteIcon
                id={`delete-${index_}`}
                className={css['delete-icon']}
                onClick={() => openDeleteDialog(value.id)}
              />
            </CardItem>
          </React.Fragment>
        );
      } else {
        total -= value.value;
      }
    }
    return acc;
  }, []);

  return matchedIncomingInvoiceComponent ? (
    <ConfigCard id="incoming-cost-card" className={css['incoming-cost']} hoverable={false}>
      <Heading headingType="h3" style="bold" color={colors['graphite']}>
        {matchedIncomingInvoiceComponent.name}
      </Heading>

      {details.length > 0 && (
        <div className={css['actions']}>
          <MoreActionsMenu
            id="more-actions"
            actions={[
              <DropdownAction
                id={`show-more-${index1}-${index2}`}
                icon="calculator"
                actionType="direct"
                onClick={() => setShowDetails(!showDetails)}
              >
                {i18n.getTranslation(`general.${showDetails ? 'hide_details' : 'show_more'}`)}
              </DropdownAction>
            ]}
          />
        </div>
      )}

      <LabelItemGrid>
        {showDetails && details}

        <CardItem width="6">
          <Label color={colors['graphite']} bold>
            {i18n.getTranslation('general.total')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph className={css['total']} color={colors['graphite']} bold>
            {formatMoney(total, i18n.culture)}
          </Paragraph>
        </CardItem>
      </LabelItemGrid>
    </ConfigCard>
  ) : null;
}
