import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { BillingItemType, BillingTariffRequestType, BillingTariffType, ProductType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';
import { colors } from '@zf/utils/src/color';

import EmptyColumn from '../../../../components/Column/EmptyColumn';
import CommitSection from '../../../../components/config/commit-section';
import { Tree } from '../../../../design-system/ComponentSets';
import { Paragraph, Spinner } from '../../../../design-system/Foundation';
import { dialog } from '../../../../events/dialog-events';
import { useStore } from '../../../../hooks/useStore';
import css from './billing-tariff.module.scss';
import AddEditTariffDialog from './dialogs/AddEditTariffDialog';
import TreeNode from './models/TreeNode';
import TraverseTree from './tree/TraverseTree';

export type EnrichedBillingTariffType = BillingTariffType & {
  isToday: boolean;
  useCase: 'products' | 'billingItems';
};

type Props = {
  tariffs: BillingTariffType[];
  showActionsOnDefault?: boolean;
  selectedBillingItem: BillingItemType | undefined;
  selectedProduct: ProductType | undefined;
  isProductPage: boolean;
  addTariff: (tariffToAdd: BillingTariffRequestType) => Promise<BillingTariffType>;
  addTariffToList: (newTariff: BillingTariffType) => void;
  updateTariff: (id: string, tariffToUpdate: BillingTariffRequestType) => Promise<BillingTariffType>;
  updateTariffInList: (updatedTariff: BillingTariffType) => void;
  deleteTariff: (tariffId: string) => Promise<void>;
  deleteTariffInlist: (index: number) => void;
  initTariffs: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

const BillingTariffsContent = (props: Props) => {
  const {
    selectedBillingItem,
    tariffs,
    selectedProduct,
    isProductPage,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    initTariffs
  } = props;

  const { clickRef, validationRef, onSubmit } = useDialog();

  const { applicationStore, productConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { onlyShowTariffs, setOnlyShowTariffs } = productConfigStore;
  const { saveTree, treeData, isValidTree, setIsValidTree, validateTariffTree } = productConfigStore.productsStore;

  // Why don't you detect this change?
  useEffect(() => {
    if (onlyShowTariffs && treeData.length > 0) {
      setIsValidTree(validateTariffTree(treeData[0]));
    }
  }, [JSON.stringify(treeData)]);

  const addTariffPeriodDialog = (treeNode: TreeNode) => {
    dialog.normal({
      title: getTranslation('billing_tariff.tariff_add'),
      icon: 'plus',
      content: (
        <AddEditTariffDialog
          canAddInstantly={false}
          treeNode={treeNode}
          ref={clickRef}
          validationRef={validationRef}
          tariffs={tariffs || []}
          selectedBillingItem={selectedBillingItem}
          selectedProduct={selectedProduct}
          isProductPage={isProductPage}
          addTariff={addTariff}
          initTariffs={initTariffs}
          addTariffToList={addTariffToList}
          updateTariff={updateTariff}
          updateTariffInList={updateTariffInList}
        />
      ),
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <>
      {treeData && treeData.length > 0 ? (
        treeData[0].nodes.length > 0 ? (
          treeData.map((t, index) => (
            <Tree key={`${t.id}-${index}`}>
              {t.nodes.map((n, nodeIndex) => (
                <div className={css['tree-wrapper']} key={`${n.name}-${index}-${nodeIndex}`}>
                  {onlyShowTariffs && (
                    <>
                      <CommitSection
                        handleCancel={() =>
                          setOnlyShowTariffs(false, selectedBillingItem?.id || '', selectedProduct?.id || '')
                        }
                        secondary={getTranslation('general.go_back')}
                        primary={getTranslation('general.save_close')}
                        handleSave={() => saveTree(selectedBillingItem, n, selectedProduct?.id || '')}
                        isDirty={isValidTree}
                      />
                      {!isValidTree && (
                        <Paragraph bold color={colors['red-600']}>
                          {getTranslation('billing_tariff.save_warning')}
                        </Paragraph>
                      )}
                    </>
                  )}
                  <TraverseTree
                    isProductPage={isProductPage}
                    index={index}
                    tariffs_={tariffs || []}
                    selectedBillingItem={selectedBillingItem}
                    selectedProduct={selectedProduct}
                    addNode={() => addTariffPeriodDialog(n)}
                    addTariff={addTariff}
                    initTariffs={initTariffs}
                    addTariffToList={addTariffToList}
                    updateNode={() => {
                      /*No action at root level */
                    }}
                    updateTariff={updateTariff}
                    updateTariffInList={updateTariffInList}
                    removeNode={() => {
                      /*No action at root level*/
                    }}
                    tree={n}
                  />
                </div>
              ))}
            </Tree>
          ))
        ) : (
          <EmptyColumn
            image="attributes2"
            title="Billing items"
            text={getTranslation(
              `billing_tariff.${
                selectedBillingItem && tariffs.length === 0 ? 'no_tariffs_yet' : 'no_billing_tariff_templates'
              }`
            )}
          />
        )
      ) : (
        <Spinner centered size="medium" />
      )}
    </>
  );
};

export default observer(BillingTariffsContent);
