import React from 'react';

import TabList from '../TabsList/TabList';
import css from './subtabs.module.scss';

export type SubTabsType = Record<string, JSX.Element>;

type Props = {
  subTabs: SubTabsType;
  defaultTab: string;
};

export default function SubTabs(props: Props) {
  const { subTabs, defaultTab } = props;
  const [selectedSubTab, setSelectedSubTab] = React.useState<string>(defaultTab);

  const activeTabComponent = subTabs[selectedSubTab];

  return (
    <>
      <div className={css['subtabs']}>
        <TabList selectedItem={selectedSubTab} tabItems={Object.keys(subTabs)} setSelectedItem={setSelectedSubTab} />
      </div>

      {activeTabComponent}
    </>
  );
}
