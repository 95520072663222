import React from 'react';
import { CustomerGroupDetail } from '@zf/api-types/master-data/customer';
import useDetail from '../../../../app-context/hooks/use-detail';

const useCustomerGroupDetail = (entityId: string) => {
  const { entity: initialCustomerGroup, navigator } = useDetail<CustomerGroupDetail>({
    entityId,
    endpoint: '/md/customerGroups',
    domain: 'customersgroup'
  });

  const [customerGroupDetail, setCustomerGroupDetail] = React.useState(initialCustomerGroup);

  React.useEffect(() => {
    if (initialCustomerGroup) setCustomerGroupDetail(initialCustomerGroup);
  }, [initialCustomerGroup]);

  return { customerGroupDetail, navigator, setCustomerGroupDetail };
};

export default useCustomerGroupDetail;
