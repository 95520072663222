import React from 'react';

import { ExportJobType } from '@zf/api-types/export-job';
import { FileFormatType } from '@zf/api-types/file-format';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { PagedResponseType } from '@zf/api-types/api';
import { ExportDataProviderType } from '@zf/api-types/data-provider';
import { useAppContext } from '../../../app-context';
import FormatDetailGenerator from '../../../cards/FileFormats/format-detail-generator';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';

type Props = {
  exportJob: ExportJobType;
};

export default function ExportJobFileFormatCardBody(props: Props) {
  const { exportJob } = props;
  const { i18n } = useAppContext();

  const dataProviderResponse = useSuspenseSingleAPI<ExportDataProviderType>({
    request: {
      endpoint: `/cfg/DataExports/${exportJob.dataExportId}`
    }
  });

  const fileFormatsResponse = useSuspenseSingleAPI<PagedResponseType<FileFormatType>>({
    request: {
      endpoint: '/cfg/DataExportFileFormats',
      query: { showDeleted: true }
    }
  });

  if (!dataProviderResponse.result || !fileFormatsResponse.result) return null;

  const dataProvider = dataProviderResponse.result.data;

  const fileformat: FileFormatType | undefined = fileFormatsResponse.result.data.results.find(
    (fm) => fm.id === dataProvider.fileFormatId
  );

  if (!fileformat) return null;

  return (
    <>
      <CardItem width="6">
        <Label>{i18n.getTranslation('general.name')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{fileformat.name}</Paragraph>
      </CardItem>
      <FormatDetailGenerator format={fileformat.settings.format} />
    </>
  );
}
