import { observer } from 'mobx-react';
import React from 'react';

import { culture } from '@zf/api-types/enums';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';

import { DeleteIcon } from '../../../../components/Icon';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../../hooks/useStore';
import css from './languages-card-body.module.scss';
//import { RadioInput } from 'design-system/Components';
import { Label } from '@zf/stella-react/src/atoms/Label';

const InlineCultureDropdown = InlineInputField<SimpleDropdownProps<culture>>(SimpleDropdown);

const LanguageCardBody = observer(() => {
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation, getEnumTranslation, getEnum } = applicationStore;
  const {
    cultureTableBackup,
    localCultureTable,
    selectedRowIndex,
    setSelectedRowIndex,
    setLanguage,
    deleteLanguage
    //setDefaultLanguage
  } = communicationStore.settingsStore;
  const supportedCultures = localCultureTable?.supportedCultures || [];

  const culturesDropdown = getEnum<culture>('culture').filter((c) => {
    return !cultureTableBackup?.supportedCultures.includes(c.value);
  });

  const languageRows = supportedCultures.map((c, index) => {
    const cultureIsDefault = c === localCultureTable?.defaultCulture;
    const cultureIsNew = !cultureTableBackup?.supportedCultures.includes(c);

    return {
      language: !cultureIsNew ? (
        cultureIsDefault ? (
          <div className={css['culture-default-container']}>
            <Paragraph bold>{getEnumTranslation('culture', c)}</Paragraph>
            <Label bold>({getTranslation('general.default')})</Label>
          </div>
        ) : (
          getEnumTranslation('culture', c)
        )
      ) : (
        <InlineCultureDropdown
          id={`dropdown-culture-${index}`}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={cultureIsNew && c === ('' as culture)}
          onChange={(val) => setLanguage(index, val[0])}
          values={culturesDropdown}
          selectedValues={[c]}
          error={!c}
        />
      ),

      //Once used to change default language at the org level

      // default: cultureIsDefault ? (
      //   <RadioInput
      //     name="defaultCulture"
      //     value={c}
      //     checked={localCultureTable?.defaultCulture === c}
      //     onChange={() => setDefaultLanguage(c)}
      //   />
      // ) : (
      //   <RadioInput
      //     name="defaultCulture"
      //     value={c}
      //     checked={localCultureTable?.defaultCulture === c}
      //     onChange={() => setDefaultLanguage(c)}
      //   />
      // ),
      deleteAction: cultureIsDefault ? (
        <div className={css['spacer']} /> // prevents deleting the default + fixes spacing of checkbox
      ) : (
        <DeleteIcon id={`delete-${index}`} tooltipFor="language-table" onClick={() => deleteLanguage(index)} />
      )
    };
  });

  return (
    <DeprecatedStaticTable
      rows={languageRows}
      tooltipId="language-table"
      onRowSelect={setSelectedRowIndex}
      selectedIndex={selectedRowIndex}
    >
      <DeprecatedStaticColumn flexWidth="1" dataKey="language" label={getTranslation('general.language')} />
      {/* <DeprecatedStaticColumn minWidth="3.3rem" dataKey="default" label={getTranslation('general.default')} /> */}
      <DeprecatedStaticColumn minWidth="3.3rem" dataKey="deleteAction" showOnHover />
    </DeprecatedStaticTable>
  );
});

export default LanguageCardBody;
