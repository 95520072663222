import React from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import css from './tooltip-trigger.module.scss';

type Props = {
  tooltip: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  trigger?: 'click' | 'right-click' | 'hover' | 'focus';
  width?: number;
  showTooltip?: boolean;
  disabled?: boolean;
  offset?: [number, number];
  renderTopLvl?: boolean;
  setShowTooltip?: (value: boolean) => void;
};

export default function TooltipTrigger(props: Props) {
  const {
    tooltip,
    children,
    className,
    width,
    offset,
    showTooltip,
    disabled = false,
    renderTopLvl = true,
    placement = 'auto',
    trigger = 'hover',
    setShowTooltip
  } = props;

  const onVisChange = React.useCallback(
    (visible_: boolean) => {
      if (disabled) return;
      if (setShowTooltip) setShowTooltip(visible_);
    },
    [disabled]
  );

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement,
    trigger,
    interactive: trigger !== 'click' && trigger !== 'right-click',
    visible: disabled ? false : showTooltip,
    offset: offset ? offset : undefined,
    onVisibleChange: setShowTooltip ? onVisChange : undefined
  });

  let style: React.CSSProperties = {};

  if (width) {
    style.width = width;
  }

  let tip = (
    <div ref={setTooltipRef} {...getTooltipProps({ className: css['tooltip-trigger'] })}>
      {tooltip}
    </div>
  );

  if (renderTopLvl) {
    tip = ReactDOM.createPortal(tip, document.body);
  }

  return (
    <>
      <div className={className} style={style} ref={setTriggerRef}>
        {children}
      </div>

      {visible && tip}
    </>
  );
}
