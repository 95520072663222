import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { culture } from '@zf/api-types/enums';
import { FileUploadType } from '@zf/api-types/general';
import Flag from '@zf/stella-react/src/atoms/Flag/Flag';

import { Button } from '../../../../../design-system/Components';
import { Paragraph } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import { getCultureFlag } from '../../../../../utils/lang';
import { createEmptyAttachmentFile } from '../../../../../utils/product';
import { InlineInputFieldInput } from '../../../../input/InputField';
import SingleUploadInput from '../../../../input/SingleUploadInput';
import css from './translations.module.scss';

export type AttachmentFileType = {
  culture: culture;
  fileName: string;
  internalFileName: string;
  attachmentId: string;
  file?: FileUploadType;
  isDefault?: boolean;
};

type Props = {
  index: number;
  culture: culture;
  correspondingAttachment: AttachmentFileType;
  isDisabled?: boolean;
  setAttachmentValue: (index: number, val: Partial<AttachmentFileType>) => void;
};

const Translation = (props: Props) => {
  const { index, culture, correspondingAttachment, isDisabled = false, setAttachmentValue } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const [expanded, setExpanded] = useState(
    !!correspondingAttachment.attachmentId || !!correspondingAttachment.internalFileName
  );

  return (
    <div className={classNames(css['attachment'], { [css['expanded']]: expanded })}>
      {expanded ? (
        <>
          <div className={css['inputs-wrapper']}>
            <Flag size="small" url={getCultureFlag(culture)} />
            <Paragraph bold>{getEnumTranslation('culture', culture)}</Paragraph>
            <InlineInputFieldInput
              disabled={isDisabled}
              id={`${culture}-name-${index}`}
              onChange={(value) => setAttachmentValue(index, { fileName: value })}
              value={correspondingAttachment?.fileName || ''}
              placeholder={getTranslation('attachment.file_name')}
              error={
                !correspondingAttachment.fileName &&
                !!correspondingAttachment.file &&
                // @ts-ignore
                correspondingAttachment.file !== 'value'
              }
              darkMode
            />
            <Button
              id={`remove-${index}-${culture}`}
              disabled={isDisabled}
              size="small"
              type="text"
              icon="cross"
              onClick={() => {
                setExpanded(!expanded);
                setAttachmentValue(index, createEmptyAttachmentFile(correspondingAttachment.culture, false));
              }}
            />
          </div>
          <SingleUploadInput
            disabled={isDisabled}
            error={
              correspondingAttachment.fileName.length > 0 &&
              // @ts-ignore
              (correspondingAttachment.file === 'value' || correspondingAttachment.file === '')
            }
            file={correspondingAttachment.file}
            setFile={(file) => setAttachmentValue(index, { file, internalFileName: file?.fileName })}
          />
        </>
      ) : (
        <div className={css['minimized']}>
          <div>
            <Flag size="small" url={getCultureFlag(culture)} />
            <Paragraph bold>{getEnumTranslation('culture', culture)}</Paragraph>
          </div>

          <Button
            disabled={isDisabled}
            id={`add-${index}-${culture}`}
            size="small"
            type="text"
            icon="plus"
            onClick={() => setExpanded(!expanded)}
          >
            {getTranslation('general.add')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(Translation);
