import moment from 'moment';
import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionStatus, outgoingBankingTransactionType } from '@zf/api-types/enums';
import useValidator from '@zf/hooks/src/useValidator';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate, isMinDate } from '@zf/utils/src/date';
import { spacing } from '@zf/utils/src/global';
import { formatMoney } from '@zf/utils/src/number';

import Button from '../../../../../../components/Button/Button';
import DatePicker, { DatePickerProps } from '../../../../../../components/Lang/DatePicker';
import { DetailLine, InfoGrid } from '../../../../../../design-system/ComponentSets';
import { notify } from '../../../../../../events/notification-events';
import { useStore } from '../../../../../../hooks/useStore';
import { METHODS } from '../../../../../../utils/request';
import css from './outgoing-transaction-details.module.scss';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  setOutgoingBankingTransaction: React.Dispatch<React.SetStateAction<OutgoingBankingTransactionType | null>>;
};

const InlineDatePicker = InlineInputField<DatePickerProps>(DatePicker);

export default function OutgoingBankingTransactionDetailsCard(props: Props) {
  const { outgoingBankingTransaction, setOutgoingBankingTransaction } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation, sendRequest } = applicationStore;

  const collectionDateIsEditable =
    outgoingBankingTransaction.status === outgoingBankingTransactionStatus.dateexpired ||
    outgoingBankingTransaction.status === outgoingBankingTransactionStatus.readytosend;

  const { values, isDirty, setValue, restoreValues, submitFactory } = useValidator({
    initialValues: {
      collectionDate: outgoingBankingTransaction.mutationDateTime
    }
  });

  const handleSave = submitFactory(async () => {
    try {
      const updatedOutgoingTransaction = (
        await sendRequest<OutgoingBankingTransactionType>({
          request: {
            method: METHODS.POST,
            endpoint: `/bill/OutgoingBankingTransactions/${outgoingBankingTransaction.id}/collectiondate`,
            data: {
              collectionDate: values.collectionDate
            }
          }
        })
      ).data;

      setValue(values, false, true);
      setOutgoingBankingTransaction(updatedOutgoingTransaction);

      notify.success({
        content: getTranslation('actions.banking_transaction.update_collection_date_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('actions.banking_transaction.update_collection_date_fail'),
        error: e
      });
    }
  });

  return (
    <div className={css['info-grid']}>
      <InfoGrid nCols={2}>
        <DetailLine label={getTranslation('banking_transaction.created_date')}>
          <Paragraph>{formatDate(outgoingBankingTransaction.createdDateTime)}</Paragraph>
        </DetailLine>

        <DetailLine label={getTranslation('general.amount_valuta')}>
          <Paragraph>{formatMoney(outgoingBankingTransaction.totalAmount, culture)}</Paragraph>
        </DetailLine>

        <DetailLine label={getTranslation('banking_transaction.account')} lineHeight={spacing['spacing-40']}>
          <Paragraph>
            {outgoingBankingTransaction.companyBankAccount.accountHolder
              ? `${outgoingBankingTransaction.companyBankAccount.accountHolder} - ${outgoingBankingTransaction.companyBankAccount.iban}`
              : outgoingBankingTransaction.companyBankAccount.iban}
          </Paragraph>
        </DetailLine>

        <DetailLine
          label={
            outgoingBankingTransaction.type === outgoingBankingTransactionType.paymentrequests
              ? getTranslation('invoice.collection_date')
              : getTranslation('outgoing_banking_transactions.refund_date')
          }
          lineHeight={spacing['spacing-40']}
        >
          {collectionDateIsEditable ? (
            <InlineDatePicker
              id="collection-date"
              className={css['collection-date-picker']}
              onChange={(newdate) => setValue({ collectionDate: newdate.toISOString() })}
              value={moment(values.collectionDate)}
              error={!values.collectionDate || isMinDate(values.collectionDate)}
              darkMode
            />
          ) : (
            <Paragraph>{formatDate(values.collectionDate)}</Paragraph>
          )}

          {collectionDateIsEditable && (
            <div className={css['buttons']}>
              {isDirty && (
                <Button id="btn-cancel" onClick={restoreValues} type="text" size="small">
                  {getTranslation('general.cancel')}
                </Button>
              )}

              <Button id="btn-save" onClick={handleSave} type="primary" size="small" disabled={!isDirty}>
                {getTranslation('general.save')}
              </Button>
            </div>
          )}
        </DetailLine>
      </InfoGrid>
    </div>
  );
}
