import { action, makeAutoObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { tariffType, tierCalculationMethod } from '@zf/api-types/enums';
import { TariffConditionTypeParametersDTO } from '@zf/api-types/product';
import { formatPeriod, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { TariffValidatorType } from '../dialogs/AddEditTariffDialog';
import { EditTariffPeriodType } from '../dialogs/components/EditTariffPeriod';

export enum actions {
  addcondition = 'addcondition',
  deletecondition = 'deletecondition',
  addtariff = 'addtariff',
  editcondition = 'editcondition',
  edittariff = 'edittariff',
  edittariffperiod = 'edittariffperiod',
  deleteperiod = 'deleteperiod',
  deletetariff = 'deletetariff'
}

export default class TreeNode {
  public id: string = uuidv4();
  public name: string;
  public nodes: TreeNode[];
  public condition: TariffConditionTypeParametersDTO | undefined;
  public allowedActions: actions[];
  public type: tariffType;
  public tierCalculationMethod: tierCalculationMethod | undefined;
  public isNew: boolean;
  public conditionGroup: TariffConditionTypeParametersDTO;
  public options: any[] = [];
  public periodStart: string;
  public periodEnd: string;
  public initialPeriodStart: string;
  public initialPeriodEnd: string;
  public isActive: boolean;
  public isDefault: boolean;
  public tariff?: TariffValidatorType;
  public icon?: string;
  public info?: string;

  constructor(
    name: string,
    nodes: TreeNode[],
    allowedActions: actions[],
    type: tariffType,
    tierCalculationMethod: tierCalculationMethod | undefined,
    isNew: boolean,
    icon?: string,
    info?: string
  ) {
    this.name = name;
    this.nodes = nodes;
    this.icon = icon;
    this.allowedActions = allowedActions;
    this.info = info;
    this.type = type;
    this.tierCalculationMethod = tierCalculationMethod;
    this.isNew = isNew;

    makeAutoObservable(this, {
      name: observable,
      nodes: observable,
      condition: observable,
      conditionGroup: observable,
      options: observable,
      periodEnd: observable,
      periodStart: observable,
      isActive: observable,
      isDefault: observable,

      addOption: action,
      updateOption: action,
      removeOption: action,
      addNode: action,
      updateNode: action,
      deleteNode: action,
      addCondition: action,
      addTariff: action,
      setConditionGroup: action,
      setTariffPeriodDates: action,
      setToInitialOptions: action,
      setTierCalculationMethod: action
    });
  }

  setIsDefault = (productId?: string) => {
    this.isDefault = true;
    if (productId) this.isDefault = false;
  };

  addNode = (newNode: TreeNode) => {
    this.nodes.push(newNode);
  };

  setConditionGroup = (group: TariffConditionTypeParametersDTO) => {
    this.conditionGroup = group;
  };

  addCondition = (value: TariffConditionTypeParametersDTO) => {
    this.condition = value;
  };

  setToInitialOptions = () => {
    this.options = [''];
  };

  addTariff = (value: TariffValidatorType) => {
    this.tariff = value;
  };

  updateNode = (nodeToUpdate: number, updatedNode: TreeNode, parentNode: TreeNode) => {
    if (nodeToUpdate >= 0) {
      parentNode.nodes[nodeToUpdate] = updatedNode;
    }
  };

  addOption = (value: any) => {
    this.options.push(value);
  };

  removeOption = (index: number) => {
    this.options.splice(index, 1);
  };

  updateOption = (index: number, value: any) => {
    this.options[index] = value;
  };

  addTariffPeriodNode = (
    startDate: string,
    endDate: string,
    tierCalculationMethod: tierCalculationMethod | undefined,
    isActive: boolean,
    isDefault: boolean
  ) => {
    const periodNode = new TreeNode(
      formatPeriod(startDate, endDate),
      [],
      [actions.edittariffperiod, actions.deleteperiod, actions.addcondition, actions.addtariff],
      tariffType.tariffperiod,
      tierCalculationMethod,
      false,
      'date'
    );

    periodNode.isActive = isActive;
    periodNode.isDefault = isDefault;
    periodNode.periodStart = startDate;
    periodNode.periodEnd = endDate;
    periodNode.initialPeriodStart = startDate;
    periodNode.initialPeriodEnd = endDate;

    this.addNode(periodNode);

    return periodNode;
  };

  setTariffPeriodDates = (values: Partial<EditTariffPeriodType>) => {
    this.periodStart = values.startDateTime || MIN_DATE;
    this.periodEnd = values.endDateTime || MAX_DATE;
    this.name = formatPeriod(this.periodStart, this.periodEnd);
  };

  setTierCalculationMethod = (tierCalcMethod: tierCalculationMethod) => {
    this.tierCalculationMethod = tierCalcMethod;
  };

  deleteNode = (nodeToDelete: number, parentNode: TreeNode) => {
    if (nodeToDelete >= 0) {
      switch (parentNode.nodes[nodeToDelete].type) {
        case tariffType.conditions:
          parentNode.condition = undefined;
          break;
        case tariffType.tariffcalculation:
          parentNode.tariff = undefined;
          break;
      }
      parentNode.nodes.splice(nodeToDelete, 1);
    }
  };
}
