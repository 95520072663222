import {
  IncomingBankingTransactionBankAccountInsights,
  IncomingBankingTransactionInsightsResponse,
  IncomingBankingTransactionsExportResponse
} from '@zf/api-types/billing/incoming-banking-transaction';

import BaseService from '../../../../../app-context/services/BaseService';
import ApplicationStore from '../../../../../app-context/stores/domain/ApplicationStore';
import { METHODS } from '../../../../../utils/request';
import IncomingBankingTransactionsStore from '../stores/IncomingBankingTransactionsStore';

export default class IncomingBankingTransactionsService extends BaseService {
  private incomingBankingTransactionsStore: IncomingBankingTransactionsStore;

  constructor(incomingBankingTransactionsStore: IncomingBankingTransactionsStore, applicationStore: ApplicationStore) {
    super('/bill/IncomingBankingTransactions/', applicationStore);
    this.incomingBankingTransactionsStore = incomingBankingTransactionsStore;
  }

  getIncomingBankingTransactionBankAccountInsights = async () => {
    return (
      await this.applicationStore.sendRequest<IncomingBankingTransactionBankAccountInsights>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}insights/lastupdated`
        }
      })
    ).data;
  };

  getIncomingBankingTransactionInsights = async (incomingBankingTransactionId: string) => {
    return (
      await this.applicationStore.sendRequest<IncomingBankingTransactionInsightsResponse>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/incomingbankingtransactions/${incomingBankingTransactionId}/insights`
        }
      })
    ).data;
  };

  getIncomingBankingTransactionsExport = async (incomingBankingTransactionIds: string[]) => {
    return (
      await this.applicationStore.sendRequest<IncomingBankingTransactionsExportResponse[]>({
        request: {
          method: METHODS.POST,
          endpoint: '/bill/incomingbankingtransactions/export',
          data: {
            incomingBankingTransactionIds
          }
        }
      })
    ).data;
  };
}
