import clone from 'clone';
import React from 'react';

import { navigate } from '@reach/router';
import { AuthRoleResponse, UserRolesType } from '@zf/api-types/auth';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useDialog from '@zf/hooks/src/useDialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../app-context/app-context';
import Button from '../../components/Button/Button';
import ConfigHelp from '../../components/CoachMarks/config-help';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import AddEditUserDialog from '../../components/User/AddEditUserDialog';
import UserCard from '../../components/User/UserCard';
import { dialog } from '../../events/dialog-events';
import css from './user-manager.module.scss';
import SearchBar from '../../components/input/SearchBar';

type Props = {
  userRoles: UserRolesType[];
  roles: AuthRoleResponse[];
  refresh: () => void;
};

type State = {
  selectedOrganisation: string;
  searchValue: string;
  admins: UserRolesType[];
  users: UserRolesType[];
};

type SetState = {
  selectedOrganisation?: string;
  searchValue?: string;
  admins?: UserRolesType[];
  users?: UserRolesType[];
};

const sortByName = (a: UserRolesType, b: UserRolesType) => {
  if (!a.userDetails.userName || !b.userDetails.userName) return 0;

  return a.userDetails.userName < b.userDetails.userName.toLowerCase()
    ? -1
    : a.userDetails.userName.toLowerCase() > b.userDetails.userName.toLowerCase()
    ? 1
    : 0;
};

export const getAdmins = (userRoles: UserRolesType[]) => {
  return userRoles
    .filter((ur) => !!ur.tenantRoleId && ur.tenantRoleId !== '00000000-0000-0000-0000-000000000000')
    .sort(sortByName);
};

const getUsers = (userRoles: UserRolesType[]) => {
  return userRoles
    .filter((ur) => !ur.tenantRoleId || ur.tenantRoleId === '00000000-0000-0000-0000-000000000000')
    .sort(sortByName);
};

export default function UserManagerContent(props: Props) {
  const { userRoles, roles, refresh } = props;
  const { i18n, tenantReducer, authReducer } = useAppContext();
  const { tenant, tenantAuth } = tenantReducer.state;
  const { isSuperUser } = authReducer;

  const stateReducer = createStateReducer<State, SetState>();

  const [state, setState] = React.useReducer(stateReducer, {
    selectedOrganisation: '',
    searchValue: '',
    admins: getAdmins(userRoles),
    users: getUsers(userRoles)
  });

  const { clickRef, validationRef, onSubmit } = useDialog();

  const updateUserRoles = (newUserRoles: UserRolesType[]) => {
    const newAdmins = getAdmins(newUserRoles);
    const newUsers = getUsers(newUserRoles);

    setState({ admins: newAdmins, users: newUsers });
  };

  React.useEffect(() => {
    const noFilter = !state.searchValue && !state.selectedOrganisation;
    let newUserRoles = clone(userRoles);

    if (!noFilter) {
      // User search
      newUserRoles = newUserRoles.filter((ur) => {
        const orgCheck = state.selectedOrganisation
          ? ur.organizationRoles.some((or) => or.organizationId === state.selectedOrganisation)
          : true;

        const searchCheck = Object.values(ur.userDetails).some((val) => {
          if (val) {
            const toMatch = val.toString().toLowerCase();
            return toMatch.includes(state.searchValue.toLowerCase());
          }
          return false;
        });

        return (orgCheck || ur.tenantRoleId) && searchCheck;
      });
    }

    updateUserRoles(newUserRoles);
  }, [state.searchValue, state.selectedOrganisation, userRoles, roles]);

  const dropdownValues = tenantAuth
    ? tenantAuth.organizations.map((o) => {
        return {
          value: o.organizationId,
          text: o.description
        };
      })
    : [];

  const openAddDialog = (type: 'user' | 'admin') => {
    dialog.normal({
      title: i18n.getTranslation(`user.add_heading_${type}`),
      icon: 'customer',
      content: (
        <AddEditUserDialog
          ref={clickRef}
          validationRef={validationRef}
          roles={roles}
          userRoles={userRoles}
          type={type}
          onComplete={refresh}
        />
      ),
      buttonPositive: i18n.getTranslation('general.add'),
      onSubmit,
      ref: validationRef
    });
  };

  return (
    <div className={css['user-manager-wrapper']}>
      <div className={css['back-btn']}>
        <Button id="btn-back" icon="angle-left" type="text" onClick={() => navigate(`/${tenant?.name}`)}>
          {i18n.getTranslation('user.back')}
        </Button>
      </div>

      <div className={css['user-manager']}>
        <InputContainer className={css['user-manager-input-container']}>
          <SearchBar
            id="user-searchbar"
            className={css['input']}
            onChange={(val) => setState({ searchValue: val })}
            value={state.searchValue}
            placeholder={i18n.getTranslation('user.search_placeholder')}
            debounceTime={500}
          />
          <SimpleDropdown
            id="dropdown"
            className={css['input']}
            onChange={(val) => setState({ selectedOrganisation: val[0] })}
            values={dropdownValues}
            selectedValues={[state.selectedOrganisation]}
            placeholder={i18n.getTranslation('user.search_organisation')}
            clear
          />
        </InputContainer>

        <div className={css['user-cards-grid']}>
          <div className={css['admins']}>
            <div className={css['heading-wrapper']}>
              <Heading headingType="h3">{i18n.getTranslation('user.admins')}</Heading>
              <ConfigHelp
                className={css['coachmark']}
                title={i18n.getTranslation('user.admins_users')}
                content={[i18n.getTranslation('coachmark.users.paragraph')]}
              />
            </div>

            {state.admins.map((ur, index) => {
              return (
                <UserCard
                  index={index}
                  key={`user-${index}`}
                  type="admin"
                  userRole={ur}
                  roles={roles}
                  userRoles={state.admins}
                  refresh={refresh}
                />
              );
            })}
          </div>
          <div className={css['users-wrapper']}>
            <Heading className={css['heading']} headingType="h3">
              {i18n.getTranslation('user.users')}
            </Heading>
            <div className={css['users']}>
              {state.users.map((ur, index) => {
                return (
                  <UserCard
                    index={index}
                    key={`user-${index}`}
                    type="user"
                    userRole={ur}
                    roles={roles}
                    userRoles={state.users}
                    refresh={refresh}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={css['footer']}>
        {isSuperUser() && (
          <>
            <Button id="admin.add" icon="plus" type="secondary" onClick={() => openAddDialog('admin')}>
              {i18n.getTranslation('user.add_admin')}
            </Button>
            <Button id="user.add" icon="plus" type="secondary" onClick={() => openAddDialog('user')}>
              {i18n.getTranslation('user.add_user')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
