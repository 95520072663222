import { action, computed, configure, makeObservable, observable } from 'mobx';

import {
  ConfiguredPluginType,
  PluginTriggerResponse,
  PluginTriggerResponsePayload
} from '@zf/api-types/integration/plugin';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { SortStateType } from '@zf/stella-react/src/atoms/Table';

import RootStore from '../..';
import CreateRequestService from '../../../../components/units/List/logic/createRequestService';
import InfiniAPIService from '../../../../components/units/List/logic/infiniAPIService';
import BaseStore from '../../BaseStore';
import IntegrationStore from '../IntegrationStore';
import { pluginType } from '@zf/api-types/enums';

export type PluginTriggerRowType = {
  __id: string;
  __entity: PluginTriggerResponse;
  plugin: string | undefined;
  type: string;
  scheduled: string;
  triggered: string;
  payload: JSX.Element;
  attempts: number;
  logging: JSX.Element;
  status: JSX.Element;
};

export type DateRange = {
  startDate: string | null;
  endDate: string | null;
};

export type FilterType = {
  configuredPlugin: string;
  triggerFilter: DateRange;
  scheduledFilter: DateRange;
};

configure({ enforceActions: 'never' });

export default class IntegrationLoggingStore extends BaseStore<PluginTriggerResponse> {
  public rootStore: RootStore;
  public integrationStore: IntegrationStore;

  public createRequestService: CreateRequestService;
  public infiniApiService_: InfiniAPIService<PluginTriggerResponse, PluginTriggerRowType> | undefined;

  public selectedPlugin_: PluginTriggerResponse | undefined;
  public plugins: ConfiguredPluginType[];
  public selectedIds: string[] = [];
  public filter: FilterType = {
    configuredPlugin: '',
    triggerFilter: {
      startDate: null,
      endDate: null
    },
    scheduledFilter: {
      startDate: null,
      endDate: null
    }
  };
  public isFetchingLoggingItems: boolean = false;
  public subjectType: string;
  public subjectId: string;
  public dropdownValues: DropdownValuesType<ConfiguredPluginType>[] = [];
  public selectedPluginDetail: PluginTriggerResponsePayload | undefined;
  public interval: number;

  constructor(rootStore: RootStore, integrationStore: IntegrationStore) {
    super();
    this.rootStore = rootStore;
    this.integrationStore = integrationStore;

    this.createRequestService = new CreateRequestService();

    makeObservable(this, {
      selectedPlugin_: observable,
      isFetchingLoggingItems: observable,
      subjectType: observable,
      selectedPluginDetail: observable,
      infiniApiService_: observable,
      dropdownValues: observable,
      subjectId: observable,
      filter: observable,
      plugins: observable,
      selectedIds: observable,

      infiniApiService: computed,

      setSubject: action,
      setInfiniAPIService: action,
      resetFilter: action,
      setFilters: action,
      setSelectedIds: action,
      handleSort: action,
      setSelectedPluginDetail: action,
      setIsFetching: action,
      fetchRows: action,
      migrate: action
    });
  }

  get infiniApiService() {
    return this.infiniApiService_ as InfiniAPIService<PluginTriggerResponse, PluginTriggerRowType>;
  }

  get selectedPlugin() {
    return this.selectedPlugin_ as PluginTriggerResponse;
  }

  setSelectedIds = (selectedIds: string[]) => {
    this.selectedIds = selectedIds;
    this.selectedPlugin_ = this.infiniApiService.rows.find((e) => {
      return e.__id === selectedIds[0];
    })?.__entity;
  };

  setSubject = (subjectId: string, subjectType: string) => {
    this.subjectId = subjectId;
    this.subjectType = subjectType;
  };

  retrigger = async (logId: string) => {
    await this.integrationStore.pluginTriggerService.retriggerIntegrationLogging(
      this.selectedPlugin.entitySubjectType,
      this.selectedPlugin.entitySubjectId,
      logId
    );
    await this.fetchRows();
  };

  resetFilter = () => {
    this.setFilters({
      triggerFilter: {
        startDate: null,
        endDate: null
      },
      scheduledFilter: {
        startDate: null,
        endDate: null
      }
    });
  };

  migrate = async (logId: string, plugin: pluginType) => {
    await this.integrationStore.customPluginActionsService.migration(logId, plugin);
    await this.fetchRows();
  };

  setSelectedPluginDetail = async (logId: string, subjectType: string, subjectId: string) => {
    this.subjectType = subjectType;
    this.subjectId = subjectId;
    this.selectedPluginDetail = await this.integrationStore.pluginTriggerService.getIntegrationLogging(
      subjectType,
      subjectId,
      logId
    );
  };

  renderPluginName = (pluginId: string) => {
    const plugin = this.plugins.find((e) => {
      return e.id === pluginId;
    });
    return this.rootStore.applicationStore.getEnumTranslation('pluginType', plugin ? plugin.pluginType : '');
  };

  setFilters = async (newFilter: Partial<FilterType>) => {
    this.filter = { ...this.filter, ...newFilter };
    await this.fetchRows();
  };

  handleSort = async (sortParams: SortStateType) => {
    this.createRequestService.handleSort(sortParams);
    await this.fetchRows();
  };

  setIsFetching = (fetching: boolean) => {
    this.isFetchingLoggingItems = fetching;
  };

  setInfiniAPIService = async (
    processRecord: (record: PluginTriggerResponse) => PluginTriggerRowType,
    refresh: boolean = true
  ) => {
    this.plugins = await this.integrationStore.configuredPluginsService.getConfiguredPlugins();
    this.dropdownValues = this.plugins.map((e) => {
      return {
        value: e,
        text: this.rootStore.applicationStore.getEnumTranslation('pluginType', e.pluginType)
      };
    });

    this.infiniApiService_ = new InfiniAPIService(this.rootStore, processRecord);

    await this.fetchRows();

    if (refresh) {
      this.interval = window.setInterval(() => {
        if (!this.isFetchingLoggingItems) {
          this.integrationStore.pluginTriggerService
            .getIntegrationLogItems(
              this.subjectType,
              this.subjectId,
              this.filter,
              this.createRequestService.sortDirection
            )
            .then((r) => {
              this.infiniApiService.handleFetchRows(r);
            });
        }
      }, 7000);
    }
  };

  fetchRows = async () => {
    this.setIsFetching(true);
    const request = await this.createRequestService.createRequest({
      endpoint: `/int/plugintrigger/${this.subjectType}/${this.subjectId}`,
      query: {
        scheduledAtFromUtc: this.filter.scheduledFilter.startDate,
        scheduledAtToUtc: this.filter.scheduledFilter.endDate,
        triggeredFromUtc: this.filter.triggerFilter.startDate,
        triggeredToUtc: this.filter.triggerFilter.endDate,
        configuredPluginId: this.filter.configuredPlugin
      }
    });

    await this.infiniApiService.fetchRows(request);
    this.setIsFetching(false);
  };
}
