import React from 'react';

type Props = {
  image: string;
  title: string;
  width?: number;
  height?: number;
};

export default function EmptyStateImg(props: Props) {
  const { image, title, width = 70, height = 70 } = props;

  return (
    <img
      src={`https://cdn.zerofriction.co/shared/assets/emptyStates/${image}.png`}
      alt={title}
      title={title}
      width={width}
      height={height}
    />
  );
}
