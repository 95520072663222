import clone from 'clone';
import React from 'react';

import { BillingItemType } from '@zf/api-types/product';
import { PropertyGroupCalculationConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { OutputChannelType } from '@zf/api-types/property-metering-configuration';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import LabelItemGrid from '@zf/stella-react/src/atoms/Card/LabelItemGrid';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import { colors } from '@zf/utils/src/color';
import { formatPeriod } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../app-context';
import { DANGER } from '../../constants/color';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';
import css from './calculate-prices-dialog.module.scss';

type LocalOutputChannelType = {
  name: string;
  isFinished: boolean;
  errors: any[];
};

type State = {
  outChannels_: LocalOutputChannelType[];
  pricesFinished: boolean;
  isCompleted: boolean;
  selectedBillingPeriod: PropertyGroupBillingPeriodType;
  priceErrors: any[];
};

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  selectedBillingPeriod: PropertyGroupBillingPeriodType;
  propertyGroupId: string;
  outputChannels: OutputChannelType[];
  billingItems: BillingItemType[];
  calculationConfigurations: PropertyGroupCalculationConfigurationType[];
  updateBillingPeriod: (period: PropertyGroupBillingPeriodType) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const {
    validationRef,
    selectedBillingPeriod,
    propertyGroupId,
    outputChannels,
    calculationConfigurations,
    billingItems,
    updateBillingPeriod
  } = props;

  const { i18n, tenantReducer } = useAppContext();

  const initialOutChannels_ = outputChannels.map((oChann) => {
    return {
      name: oChann.name,
      isFinished: false,
      errors: []
    };
  });

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    outChannels_: initialOutChannels_,
    pricesFinished: false,
    isCompleted: false,
    selectedBillingPeriod: selectedBillingPeriod,
    priceErrors: []
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!values.isCompleted);
    }
  };

  React.useEffect(() => {
    validate();
  }, [values.isCompleted]);

  const updateLocalChannels = (index: number, errors?: any[]) => {
    const channelsClone = clone(values.outChannels_);
    channelsClone[index].isFinished = true;
    channelsClone[index].errors = errors ? errors : [];
    setValue({ outChannels_: channelsClone });
  };

  const calculateForChannel = async (outputChannelId: string, index: number) => {
    try {
      await sendRequest({
        request: {
          method: METHODS.POST,
          endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyGroupId}/${outputChannelId}/calculate`,
          data: {
            startDateTime: selectedBillingPeriod.startDate,
            endDateTime: selectedBillingPeriod.endDate,
            simulationOnly: false
          }
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      });

      updateLocalChannels(index);
    } catch (e) {
      updateLocalChannels(index, [e]);
      throw e;
    }
  };

  const calculate = async () => {
    try {
      // Calculate channels output for selected billing period
      const promises = outputChannels.reduce((acc: Promise<void>[], oChann, index) => {
        if (oChann.id) {
          acc.push(calculateForChannel(oChann.id, index));
        }

        return acc;
      }, []);

      await Promise.all(promises);

      try {
        // Calculate prices
        const updatedBillingPeriod = (
          await sendRequest<PropertyGroupBillingPeriodType>({
            request: {
              method: METHODS.POST,
              endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}/${selectedBillingPeriod.id}/calculateformulae`
            },
            tenantReducer,
            lang: i18n.lang
          })
        ).data;

        updateBillingPeriod(updatedBillingPeriod);
        setValue({
          selectedBillingPeriod: updatedBillingPeriod,
          isCompleted: true,
          pricesFinished: true
        });
      } catch (error) {
        setValue({ priceErrors: [...values.priceErrors, error] });
        throw error;
      }

      notify.success({
        content: i18n.getTranslation('property_groups.tabs.billing.calculation_success')
      });
    } catch (e) {
      setValue({ isCompleted: true, pricesFinished: true });
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.calculation_fail'),
        error: e
      });
    }
  };

  React.useEffect(() => {
    // Start calculation on mount
    calculate();
  }, []);

  React.useImperativeHandle(ref, () => ({
    async onClick() {}
  }));

  const renderCalculationConfigurations = () => {
    if (values.pricesFinished) {
      if (values.priceErrors.length > 0) {
        return (
          <>
            <CardItem width="6">
              <Label color={colors['silver-700']}>
                {i18n.getTranslation('property_groups.tabs.billing.something_went_wrong')}
              </Label>
            </CardItem>
            <CardItem className={css['property-groups-errors-card']} width="6">
              <Paragraph color={DANGER} bold>
                {i18n.getTranslation('errors.errors')}
              </Paragraph>
            </CardItem>
          </>
        );
      }
      if (values.selectedBillingPeriod.calculationConfigurations.length > 0) {
        return values.selectedBillingPeriod.calculationConfigurations.map((groupDetails, index) => {
          const matchedGroup = calculationConfigurations.find((c) => {
            return c.id === groupDetails.calculationConfigurationId;
          });

          return matchedGroup ? (
            <React.Fragment key={`${matchedGroup.name}-${index}`}>
              <CardItem width="12">
                <Label color={colors['graphite']} bold>
                  {matchedGroup.name}
                </Label>
              </CardItem>
              {matchedGroup.billingItems.map((item, index2) => {
                const matchedItem = billingItems.find((b) => {
                  return b.id === item.billingItemId;
                });

                const tariff = groupDetails.billingItems[index2].deviatingTariffs[0];

                return matchedItem ? (
                  <React.Fragment key={`${item.billingItemId}-${index}-${index2}`}>
                    <CardItem width="6">
                      <Label color={colors['silver-700']}>{matchedItem.name}</Label>
                    </CardItem>
                    <CardItem width="6">
                      <Paragraph color={colors['graphite']} bold>
                        {tariff ? formatMoney(tariff.tariff, i18n.culture) : i18n.getTranslation('general.default')}
                      </Paragraph>
                    </CardItem>
                  </React.Fragment>
                ) : null;
              })}
              <CardItem width="12" />
            </React.Fragment>
          ) : null;
        });
      }

      return null;
    } else {
      return (
        <>
          <CardItem width="6">
            <Label color={colors['silver-700']}>{i18n.getTranslation('general.calculating')}</Label>
          </CardItem>
          <CardItem width="6" className={css['property-groups-errors-card']}>
            <Spinner size="small" />
          </CardItem>
        </>
      );
    }
  };

  return (
    <>
      <Paragraph>{i18n.getTranslation('property_groups.tabs.billing.calc_prices_paragraph')}</Paragraph>
      <Paragraph>{formatPeriod(selectedBillingPeriod.startDate, selectedBillingPeriod.endDate)}</Paragraph>

      <div className={css['wrapper']}>
        <Heading headingType="h3" style="bold">{`1. ${i18n.getTranslation(
          'property_groups.tabs.billing.calc_consumptions'
        )}`}</Heading>
        <HorizontalDivider />
        <LabelItemGrid>
          {values.outChannels_.length > 0 ? (
            values.outChannels_.map((chann, index) => {
              return (
                <React.Fragment key={`${chann.name}-${index}`}>
                  <CardItem width="6">
                    <Label>{chann.name}</Label>
                  </CardItem>
                  <CardItem width="6" className={css['property-groups-errors-card']}>
                    {chann.isFinished ? (
                      chann.errors.length > 0 ? (
                        <Paragraph color={DANGER} bold>
                          {i18n.getTranslation('errors.errors')}
                        </Paragraph>
                      ) : (
                        <Paragraph color={colors['graphite']} bold>
                          {i18n.getTranslation('general.finished')}
                        </Paragraph>
                      )
                    ) : (
                      <Spinner size="small" />
                    )}
                  </CardItem>
                </React.Fragment>
              );
            })
          ) : (
            <>
              <CardItem width="12">
                <Paragraph>{i18n.getTranslation('property_groups.tabs.billing.no_consumptions_to_calc')}</Paragraph>
              </CardItem>
            </>
          )}
        </LabelItemGrid>

        <Heading headingType="h3" style="bold">{`2. ${i18n.getTranslation(
          'property_groups.tabs.billing.calc_prices'
        )}`}</Heading>
        <HorizontalDivider />
        <LabelItemGrid>{renderCalculationConfigurations()}</LabelItemGrid>
      </div>
    </>
  );
});
