import { EntitySubjectUnionType } from '@zf/api-types/config/scenarios_new';
import { entitySubjectType, scenarioGroupingType } from '@zf/api-types/enums';
import { colors } from '@zf/utils/src/color';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import IconParagraph from '../../../../components/Icon/IconParagraph';
import { useStore } from '../../../../hooks/useStore';
import css from './scenario-trees.module.scss';
import ScenarioTree from './ScenarioTree';

const ScenarioTrees = () => {
  const { applicationStore, communicationStore } = useStore();
  const { getEnumTranslation } = applicationStore;
  const { entitySubjectTypes, selectedEntitySubjectType, initEntitySubjectMap } = communicationStore.templatesStore;

  useEffect(() => {
    initEntitySubjectMap();
  }, []);

  //iconmapping
  const [iconMap] = useState({
    [entitySubjectType.invoice]: 'invoice',
    [entitySubjectType.collectioncase]: 'dunning',
    [entitySubjectType.contract]: 'contract',
    [entitySubjectType.moverequest]: 'task',
    [entitySubjectType.billingrelation]: 'customer'
  });

  //enum mapping to right translation
  const [subjectTypeMap] = useState({
    [entitySubjectType.invoice]: scenarioGroupingType.invoice,
    [entitySubjectType.collectioncase]: scenarioGroupingType.collectioncase,
    [entitySubjectType.contract]: scenarioGroupingType.contract,
    [entitySubjectType.moverequest]: scenarioGroupingType.moverequest,
    [entitySubjectType.billingrelation]: scenarioGroupingType.personalinformation
  });

  const openOnInit = (type: EntitySubjectUnionType) => {
    return selectedEntitySubjectType === type;
  };

  return (
    <div className={css['trees-wrapper']}>
      {entitySubjectTypes.map((est, index) => {
        return (
          <ScenarioTree
            key={`${est}-${index}`}
            title={
              <IconParagraph iconType={iconMap[est]} color={colors['graphite']}>
                {getEnumTranslation('scenarioGroupingType', subjectTypeMap[est])}
              </IconParagraph>
            }
            entitySubjectType={est}
            openOnInit={openOnInit(est)}
          />
        );
      })}
    </div>
  );
};

export default observer(ScenarioTrees);
