import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus, invoiceType } from '@zf/api-types/enums';

import UnbilledExport from '../../../components/Button/Excel/UnbilledExport';
import CreateAdvanceListItem from '../../../components/list-items/completeness/CreateAdvanceListItem';
import IntermediateInvoiceListItem from '../../../components/list-items/completeness/IntermediateInvoiceListItem';
import ManuallyCloseListItem from '../../../components/list-items/completeness/ManuallyCloseListItem';
import RecalculateCreateListItem from '../../../components/list-items/completeness/RecalculateCreateListItem';
import ReopenListItem from '../../../components/list-items/completeness/ReopenListItem';
import { checkAdvanceButton, checkIntermediateInvoiceButton, checkInvoiceButton } from '../../../utils/completeness';
import { UnbilledState, useTracked } from '../context/unbilled-context';

export type RowTypeUnbilled = {
  __id: string;
  __entity: BillingCompletenessType;
  contractId: JSX.Element;
  missingAdvances: JSX.Element;
  PeriodStartDateTime: JSX.Element;
  status: JSX.Element;
  invoiceType: React.ReactNode;
  overdue: JSX.Element;
  customer: JSX.Element;
  missingFor: JSX.Element;
};

type Props = {
  untilDateTime?: string;
};

export default function useUnbilledActions(props: Props) {
  const { untilDateTime } = props;
  const [state, dispatch] = useTracked();

  const setState = (newState: Partial<UnbilledState>) => {
    dispatch({
      type: 'SET_STATE',
      newState: newState
    });
  };

  const toReturn = React.useMemo(() => {
    const createOrRecalculate: BillingCompletenessType[] = [];
    const createAdvances: BillingCompletenessType[] = [];
    const intermediates: BillingCompletenessType[] = [];
    const manuallyClosed: BillingCompletenessType[] = [];
    const toReopen: BillingCompletenessType[] = [];
    const toBillWPrevTariffs: BillingCompletenessType[] = [];

    const listItems: JSX.Element[] = [];

    if (state.rows) {
      state.rows.forEach(async (r) => {
        if (r.__entity && state.selectedItems.includes(r.__entity.id)) {
          if (!checkInvoiceButton(r.__entity)) {
            createOrRecalculate.push(r.__entity);
          }
          if (checkAdvanceButton(r.__entity)) {
            createAdvances.push(r.__entity);
          }
          if (checkIntermediateInvoiceButton(r.__entity)) {
            intermediates.push(r.__entity);
          }
          if (
            r.__entity.status === billingCompletenessStatus.manuallycorrected ||
            r.__entity.status === billingCompletenessStatus.inputmissing
          ) {
            manuallyClosed.push(r.__entity);
          }
          if (r.__entity.status === billingCompletenessStatus.manuallyclosed) {
            toReopen.push(r.__entity);
          }
          if (r.__entity.invoiceType === invoiceType.endnote) {
            toBillWPrevTariffs.push(r.__entity);
          }
        }
      });
    }

    if (state.updateGivenRows) {
      const updateGivenRows = state.updateGivenRows;

      listItems.push(
        <RecalculateCreateListItem
          key="recalculate-or-create"
          completenesses={createOrRecalculate}
          setState={setState}
          updateGivenRows={updateGivenRows}
        />,
        <CreateAdvanceListItem
          key="create-advance"
          completenesses={createAdvances}
          untilDateTime={untilDateTime}
          setState={setState}
          updateGivenRows={updateGivenRows}
        />,
        <IntermediateInvoiceListItem
          key="intermediate-invoice"
          completenesses={intermediates}
          setState={setState}
          updateGivenRows={updateGivenRows}
        />,
        <ManuallyCloseListItem
          key="manually-close"
          completenesses={manuallyClosed}
          setState={setState}
          updateGivenRows={updateGivenRows}
        />,
        <ReopenListItem
          key="to-reopen"
          completenesses={toReopen}
          setState={setState}
          updateGivenRows={updateGivenRows}
        />,
        <UnbilledExport key="export" rows={state.rows || []} selectedItems={state.selectedItems} />
      );
    }

    return { listItems, selectedItems: state.selectedItems };
  }, [state.selectedItems]);

  return toReturn;
}
