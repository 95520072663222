import { observer } from 'mobx-react';
import React, { forwardRef, useImperativeHandle } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useStore } from '../../../../../hooks/useStore';
import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';

const DiscardDialog = forwardRef((props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { applicationStore, communicationStore } = useStore();
  const {
    templateForm_,
    setSubjectTypes,
    selectedEntitySubjectSubtypeDiscard,
    selectedEntitySubjectTypeDiscard,
    setDiscardChanges
  } = communicationStore.templatesStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      setDiscardChanges(false);
      templateForm_?._reset();
      setSubjectTypes(selectedEntitySubjectTypeDiscard, selectedEntitySubjectSubtypeDiscard);
    }
  }));

  return <Paragraph>{applicationStore.getTranslation('general.discard_changes')}</Paragraph>;
});

export default observer(DiscardDialog);
