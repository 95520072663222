import moment from 'moment';
import React from 'react';

import { ImportDataProviderType } from '@zf/api-types/data-provider';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { FileFormatType } from '@zf/api-types/file-format';
import { ImportJobRowType, ImportJobType } from '@zf/api-types/import-job';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { ProgressBar } from '@zf/stella-react/src/atoms/ProgressBar';
import { formatDateTime } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useListPage from '../../../app-context/hooks/use-listpage';
import { Icon } from '../../../components/Icon';
import EmptyLink from '../../../components/Link/EmptyLink';
import { ICON_COLORS } from '../../../constants/icons';
import css from './import-job-list-page.module.scss';

const useImportJobListPage = (dataProviders: ImportDataProviderType[], fileFormats: FileFormatType[]) => {
  const { tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const processRecord = (importJob: ImportJobType): ImportJobRowType => {
    const dataProvider: ImportDataProviderType | undefined = dataProviders.find(
      (dp) => dp.id === importJob.dataImportId
    );

    const fileFormat: FileFormatType | undefined = fileFormats.find((fm) => fm.id === importJob.fileFormatId);

    let totalBucketsStored = importJob.totalBucketsStored;

    if (importJob.bucketsStored) {
      totalBucketsStored = importJob.bucketsStored.length;
    }

    return {
      __id: importJob.id,
      __etag: importJob._etag,
      __finished: moment(importJob.finishDate).year() !== 1,
      __internalFilePath: importJob.internalFilePath,
      __importJobEntity: importJob,
      Source: importJob.source,
      FileName: (
        <EmptyLink
          id={`${routedEntitySubjectType.importjob}-${importJob.id}`}
          url={`${rootUrl}/import-jobs/${importJob.id}`}
        >
          {importJob.fileName}
        </EmptyLink>
      ),
      communicationType: enumReducer.getTranslation('dataImportCommunicationType', importJob.communicationType),
      status: (
        <StatusBadge color={ICON_COLORS[importJob.status]} type="bare">
          {enumReducer.getTranslation('importJobStatus', importJob.status)}
        </StatusBadge>
      ),
      progress: (
        <ProgressBar
          size="small"
          showLabel={false}
          progress={(totalBucketsStored / importJob.totalBucketsToStore) * 100}
        />
      ),
      FinishDate:
        moment(importJob.finishDate).year() === 1 ? (
          <Icon className={css['vertical-style']} type="notfinished" color />
        ) : (
          <Icon className={css['vertical-style']} type="finished" color />
        ),
      importType: enumReducer.getTranslation('importCategoryType', importJob.importType),
      amountOfValuesParsed: importJob.amountOfValuesParsed,
      totalBucketsStored,
      dataprovider: dataProvider?.name || '',
      fileformat: fileFormat?.name || '',
      createdDateTime: formatDateTime(importJob.createdDateTime)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<ImportJobType, ImportJobRowType>({ endpoint: '/logs/importjob', processRecord, domain: 'importjob' });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useImportJobListPage;
