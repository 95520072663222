import { observer } from 'mobx-react-lite';
import React from 'react';

import { AddressFormType, AddressNestedFormType } from '@zf/api-types/general';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../app-context';
import BaseFormVjf from '../../app-context/stores/forms/BaseFormVjf';
import CountryInput, { CountryInputProps } from '../../components/input/country-input';
import InputField, { InputFieldProps } from '../../components/input/InputField';
import { useStore } from '../../hooks/useStore';
import { BaseButton } from '../Components';
import { Paragraph } from '../Foundation';
import css from './adresspicker.module.scss';

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineCountryInput = InlineInputField<CountryInputProps>(CountryInput);

type Props<F> = {
  values: AddressFormType;
  form: F;
};

const AddressInput = <F extends BaseFormVjf<AddressNestedFormType>>(props: Props<F>) => {
  const { values, form } = props;
  const { authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      form._reset();
    } else if (event.key === 'Enter') {
      const elem = document.activeElement as HTMLElement;
      if (elem) elem.blur();
    }
  };

  return (
    <>
      <CardItem width="8" justifyContent="end">
        {hasPermission('customer.update_invoiceaddress') ? (
          <>
            {values.isEditingAddress ? (
              <div className={css['invoice-address']}>
                <div className={css['street-name']}>
                  <InlineInputFieldInput
                    onKeyDown={keyHandler}
                    value={values.streetName}
                    onChange={(val) => form._setNested('supplierAddress', 'streetName', val)}
                    placeholder={getTranslation('organization.streetName')}
                    id="customer-detail-streetname"
                    error={!values.streetName}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                </div>
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={values.streetNumber}
                  onChange={(val) => form._setNested('supplierAddress', 'streetNumber', val)}
                  placeholder={getTranslation('organization.streetNumber')}
                  id="customer-detail-streetNumber"
                  error={!values.streetNumber}
                />
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={values.streetNumberAddition}
                  onChange={(val) => form._setNested('supplierAddress', 'streetNumberAddition', val)}
                  placeholder={getTranslation('organization.streetNumberAddition')}
                  id="customer-detail-streetNumberAddition"
                />
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={values.postalCode}
                  onChange={(val) => form._setNested('supplierAddress', 'postalCode', val)}
                  placeholder={getTranslation('country_specifics.supplier_address')}
                  id="customer-detail-postalCode"
                  error={!values.postalCode}
                />
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={values.city}
                  onChange={(val) => form._setNested('supplierAddress', 'city', val)}
                  placeholder={getTranslation('location.city')}
                  id="customer-detail-city"
                  error={!values.city}
                />
                <InlineCountryInput
                  selectedValues={[values.country]}
                  onChange={(val) => form._setNested('supplierAddress', 'country', val[0])}
                  placeholder={getTranslation('location.country')}
                  id="customer-detail-country"
                  error={!values.country}
                />
              </div>
            ) : (
              <BaseButton
                id="address-edit-trigger"
                onClick={() => form._setNested('supplierAddress', 'isEditingAddress', true)}
                className={css['address-input']}
              >
                <Paragraph textAlign="right">
                  {`${values?.streetName} ${values?.streetNumber} ${values?.streetNumberAddition}`}
                  <br />
                  {values?.postalCode} {values?.city} ({values?.country?.toUpperCase()})
                </Paragraph>
              </BaseButton>
            )}
          </>
        ) : (
          <Paragraph textAlign="right">
            {`${values.streetName} ${values.streetNumber}-${values.streetNumberAddition}`}
            <br />
            {values.postalCode} {values.city} ({values.country.toUpperCase()})
          </Paragraph>
        )}
      </CardItem>
    </>
  );
};

export default observer(AddressInput);
