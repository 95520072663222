import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { ModelType } from '@zf/api-types/model';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';
import { isMinDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import DatePicker, { DatePickerProps } from '../../../../components/Lang/DatePicker';
import Dropdown from '../../../../components/Lang/Dropdown';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';
import { CreateMeterType, STEP_NAMES } from './MeterWizard';

export type Props = {
  index?: number;
  models: ModelType[];
  values: CreateMeterType;
  setValue: (value: Partial<CreateMeterType>, initialApiSet?: boolean, backup_?: boolean) => void;
  handleFocus: (step: string) => () => void;
};

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);
const WizardDropdown = WizardInputWrapper<DropdownProps<ModelType>>(Dropdown);
const WizardSimpleDropdown = WizardInputWrapper<SimpleDropdownProps<meterType>>(SimpleDropdown);
const WizardDatePicker = WizardInputWrapper<DatePickerProps>(DatePicker);

export default function EssentialsSection(props: Props) {
  const { index, models, values, setValue, handleFocus } = props;
  const { i18n, enumReducer } = useAppContext();

  const modelsDropdown = models.map((m) => ({
    value: m,
    text: `${m.brandName}-${m.name}`
  }));

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[0]} />
        {i18n.getTranslation('meter.steps.essentials')}
      </WizardHeader>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('meter.essentials')}
      </Heading>
      <InputContainer>
        <WizardInputField
          id={`serialNumber-${index}`}
          onChange={(value) => setValue({ serialNumber: value })}
          value={values.serialNumber}
          placeholder={i18n.getTranslation('meter.serial')}
          error={!values.serialNumber}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardDropdown
          id={`modelId-${index}`}
          onChange={(value) => setValue({ modelId: value[0] ? value[0].id : '' })}
          placeholder={i18n.getTranslation('meter.model')}
          values={modelsDropdown}
          selectedValues={[values.modelId]}
          error={!values.modelId}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardSimpleDropdown
          id={`meterType-${index}`}
          onChange={(val) => setValue({ meterType: val[0] })}
          placeholder={i18n.getTranslation('meter.meter_type')}
          values={enumReducer.getEnum<meterType>('meterType')}
          selectedValues={[values.meterType]}
          error={!values.meterType}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardDatePicker
          id={`mutationDateTime-${index}`}
          value={values.mutationDateTime}
          placeholder={i18n.getTranslation('general.creation_date')}
          onChange={(value) => setValue({ mutationDateTime: value })}
          error={!values.mutationDateTime || isMinDate(values.mutationDateTime)}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
      </InputContainer>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('meter.essentials_tag')}
      </Heading>
      <InputContainer>
        <WizardInputField
          id={`tag-${index}`}
          onChange={(value) => setValue({ meterTag: value })}
          value={values.meterTag}
          placeholder={i18n.getTranslation('meter.meter_tag')}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
      </InputContainer>
    </WizardSection>
  );
}
