import PropTypes from 'prop-types';
import React from 'react';

import css from './outside-component-clickdet.module.scss';

type Props = {
  shouldTrigger: boolean;
  onMouseEnter?: () => void;
  onClick?: () => void;
  children: React.ReactNode;
  action: Function;
};

function useOutsideComponentClickDetector(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  shouldTrigger: boolean,
  action: Function
): void {
  /**
   * Execute action function if clicked on outside of element
   */
  function handleClickOutside(event: MouseEvent) {
    if (shouldTrigger) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        action();
      }
    }
  }

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * Component that executes the action function if you click outside of it
 */
function OutsideComponentClickDetector(props: Props) {
  const { shouldTrigger, children, action, onMouseEnter = () => {}, onClick = () => {} } = props;

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  useOutsideComponentClickDetector(wrapperRef, shouldTrigger, action);

  return (
    <div
      className={css['outside-component-clickdet']}
      onClick={onClick}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
      onMouseEnter={onMouseEnter}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
}

OutsideComponentClickDetector.propTypes = {
  children: PropTypes.element.isRequired
};

export default OutsideComponentClickDetector;
