import React from 'react';
import Toggle from 'react-switch';

import { colors } from '@zf/utils/src/color';

import ActionDescription from '../ActionDescription/ActionDescription';

type Props = {
  checked: boolean;
  title?: string;
  description?: string;
  disabled?: boolean;
  onChange: (val: boolean) => void;
};

const ZFToggle = (props: Props) => {
  const { title, description, checked, disabled, onChange } = props;

  const toggle = (
    <div>
      <Toggle
        onChange={onChange}
        checked={checked}
        handleDiameter={18}
        checkedIcon={false}
        uncheckedIcon={false}
        height={24}
        width={40}
        disabled={disabled}
        onColor={colors['blue-600']}
        offColor={colors['silver-400']}
      />
    </div>
  );

  return title && description ? <ActionDescription title={title} description={description} action={toggle} /> : toggle;
};

export default ZFToggle;
