import React from 'react';

import { PaymentRowType } from '@zf/api-types/payments';
import { formatDateForExport } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import ExportToExcel from './ExportToExcel';

export default function PaymentExport() {
  const { i18n, enumReducer } = useAppContext();
  const { myCurrent } = useCurrent('payment');
  const selectedRows: PaymentRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = (): Array<Array<string>> => {
    const exportArray = new Array(selectedRows.length + 1);

    exportArray[0] = [
      i18n.getTranslation('banking_transaction.transaction_identification'),
      i18n.getTranslation('general.type'),
      i18n.getTranslation('payments.is_reversed'),
      i18n.getTranslation('payments.payment_date'),
      i18n.getTranslation('payments_list.labels.customer_bank_account'),
      i18n.getTranslation('general.amount_valuta'),
      i18n.getTranslation('payments_list.labels.paymentReference'),
      i18n.getTranslation('customer.customer_id'),
      i18n.getTranslation('customer.customer')
    ];

    for (let i = 1; i < exportArray.length; i++) {
      exportArray[i] = new Array(exportArray[0].length);
      const selectedRow = selectedRows[i - 1];
      const payment = selectedRow.__paymentEntity;

      //Position X gets Entity Y
      if (selectedRow && Object.keys(selectedRow).length > 1) {
        exportArray[i][0] =
          payment.incomingBankingTransactionIdentification ||
          payment.outgoingBankingTransactionIdentification ||
          i18n.getTranslation('general.empty');
        exportArray[i][1] = enumReducer.getTranslation('paymentType', payment.type);
        exportArray[i][2] = payment.isReversed ? i18n.getTranslation('general.yes') : i18n.getTranslation('general.no');
        exportArray[i][3] = formatDateForExport(payment.paymentDateTime);
        exportArray[i][4] = payment.customerBankAccount;
        exportArray[i][5] = payment.amount;
        exportArray[i][6] = payment.paymentReference;
        exportArray[i][7] = payment.debtor.customerAccountNumber;
        exportArray[i][8] = payment.debtor.displayName;
      }
    }

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      key="paymentExport"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={i18n.getTranslation('export_excel.export_payments')}
      ws_name={i18n.getTranslation('payments.payment')}
    />
  );
}
