import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { EnumReturnValue } from '../../app-context/hooks/use-enum';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;

  // Detail page
  task?: MoveRequestType;
  setTask?: (updatedTask: MoveRequestType) => void;

  // List page
  selectedRows?: TaskRowType[];
  setUpdatedRows?: (updatedRows: MoveRequestType[]) => void;
  refreshCounts?: () => void;
};

const getDropdownValues = (enumReducer: EnumReturnValue): DropdownValuesType<moveRequestStatus>[] => {
  return [
    {
      value: moveRequestStatus.pendingatcustomer,
      text: enumReducer.getTranslation('moveRequestStatus', moveRequestStatus.pendingatcustomer)
    },
    {
      value: moveRequestStatus.pendingatsupplier,
      text: enumReducer.getTranslation('moveRequestStatus', moveRequestStatus.pendingatsupplier)
    }
  ];
};

const SetWaitingDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { task, selectedRows, validationRef, setTask, setUpdatedRows, refreshCounts } = props;
  const [newStatus, setNewStatus] = useState<moveRequestStatus | ''>('');
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const [dropdownValues] = useState(getDropdownValues(enumReducer));

  const setWaiting = async (taskId: string) => {
    return (
      await sendRequest<MoveRequestType>({
        request: {
          method: METHODS.POST,
          endpoint: `/md/MoveRequests/${taskId}/setpending`,
          data: {
            newStatus: newStatus
          }
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!newStatus);
    }
  };

  useEffect(() => {
    validate();
  }, [newStatus]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      const status = enumReducer.getTranslation('moveRequestStatus', newStatus);

      try {
        if (task && setTask) {
          const updatedTask = await setWaiting(task.id);
          setTask(updatedTask);
        } else if (selectedRows && setUpdatedRows && refreshCounts) {
          const updatedTasks = await Promise.all(selectedRows.map((r) => setWaiting(r.__entity.id)));
          setUpdatedRows(updatedTasks);
          refreshCounts();
        }

        notify.success({
          content: i18n.getTranslation('tasks.change_status_success', {
            newStatus: status
          })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tasks.change_status_fail', {
            newStatus: status
          }),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>{i18n.getTranslation('tasks.set_waiting_paragraph')}</Paragraph>
      <InputContainer>
        <SimpleDropdown
          id="waiting-reason"
          onChange={(val) => setNewStatus(val[0])}
          placeholder={i18n.getTranslation('tasks.waiting_status')}
          values={dropdownValues}
          selectedValues={[newStatus]}
          error={!newStatus}
        />
      </InputContainer>
    </>
  );
});

export default SetWaitingDialog;
