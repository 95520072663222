import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { MeterStatusHistoryType } from '@zf/api-types/master-data/meter';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { formatDate, formatPeriod } from '@zf/utils/src/date';

import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { ICON_COLORS } from '../../../../../constants/icons';
import { useStore } from '../../../../../hooks/useStore';
import MeterAddress from './MeterAddress';

type Props = {
  meterType_: meterType;
  statusHistory: MeterStatusHistoryType[];
};

const HistoryCard = (props: Props) => {
  const { statusHistory, meterType_ } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  statusHistory.reverse();

  const rows = statusHistory.map((status) => {
    return {
      __id: `${status.meterStatus}-${formatDate(status.startDateTime)}`,
      status: (
        <NewStatusBadge
          color={ICON_COLORS[status.meterStatus]}
          status_={getEnumTranslation('meterStatus', status.meterStatus)}
        />
      ),
      address: (
        <MeterAddress
          id={`${status.startDateTime}-${status.meterStatus}`}
          tooltipFor="history-table-id"
          activeState={status}
        />
      ),
      meterType: getEnumTranslation('meterType', meterType_),
      period: formatPeriod(status.startDateTime, status.endDateTime)
    };
  });

  return (
    <Card id="history-card" width="2">
      <CardHeader>{getTranslation('meter.status_history')}</CardHeader>
      <CardBody type="table">
        <DynamicVirtualTable
          id="history-table"
          tooltipId="history-table-id"
          rows={rows}
          rowHeight={45}
          noSelect
          totalAmountOfRows={rows.length}
          columns={[
            {
              width: 140,
              label: getTranslation('general.status'),
              dataKey: 'status'
            },
            {
              width: 300,
              label: getTranslation('location.address'),
              dataKey: 'address'
            },

            {
              width: 110,
              label: getTranslation('meter.meter_type'),
              dataKey: 'meterType'
            },
            {
              width: 110,
              label: getTranslation('meter.period'),
              dataKey: 'period'
            }
          ]}
        />
      </CardBody>
    </Card>
  );
};

export default HistoryCard;
