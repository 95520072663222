import moment from 'moment';
import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context';
import useDownload from '../../../../app-context/hooks/use-download';
import Button from '../../../../components/Button/Button';
import IconParagraph from '../../../../components/Icon/IconParagraph';
import css from './attachment-signatures.module.scss';

type Props = {
  task: MoveRequestType;
};

export default function AttachmentSignatures(props: Props) {
  const { task } = props;
  const { i18n, tenantReducer } = useAppContext();

  const { downloadSingleFile } = useDownload();

  if (
    task.attachmentSignatures.length === 0 ||
    (task.attachmentSignatures.length === 1 && task.attachmentSignatures[0].signDate === null)
  )
    return null;

  return (
    <div className={css['attachment-signatures']}>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('tasks.signatures')}
      </Heading>
      {task.attachmentSignatures.map((as, index) => {
        if (as.signDate != null) {
          return (
            <div className={css['attachment-signature']} key={as.attachmentFileId}>
              {as.errorCode ? (
                <IconParagraph iconType="pdf" className={css['error']} bold>
                  {i18n.getTranslation('errors.ATTACHMENT_FILE_DOES_NOT_EXIST')}
                </IconParagraph>
              ) : (
                <Button
                  id={`${as.attachmentFileId}-${index}`}
                  icon="pdf"
                  type="text"
                  onClick={() =>
                    downloadSingleFile(
                      as.entityAttachmentGroupId
                        ? `/att/EntityAttachmentGroups/download`
                        : `/att/attachments/${as.attachmentFileId}`,
                      tenantReducer,
                      as.entityAttachmentGroupId
                        ? {
                            entityAttachmentGroupIds: [as.entityAttachmentGroupId],
                            culture: i18n.lang
                          }
                        : null
                    )
                  }
                >
                  {as.attachmentFileName || ''}
                </Button>
              )}

              <Paragraph color={colors['silver-600']}>
                {i18n.getTranslation('tasks.accepted_date', {
                  date: moment(as.signDate).format('DD/MM/YYYY hh:mm')
                })}
              </Paragraph>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}
