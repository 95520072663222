import { ICON_COLORS } from 'constants/icons';
import { Icon } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MeteringIssueError, MeteringIssueStatus } from '@zf/api-types/enums';
import { colors } from '@zf/utils/src/color';

import Link from '../Link/Link';
import SimpleTooltip from '../Tooltip/SimpleTooltip/SimpleTooltip';
import css from './meter-issue-indicator.module.scss';

type MeterIssueIndicatorProps = {
  id: string;
  usage: 'meter' | 'channel' | 'measurement';
  tooltipId: string;
  issueStatus?: MeteringIssueStatus;
  issueError?: MeteringIssueError;
  tooltipExtra?: string | number;
};

const MeterIssueIndicator = (props: MeterIssueIndicatorProps) => {
  const { id, usage, tooltipId, issueError, issueStatus, tooltipExtra } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation, rootUrl } = applicationStore;
  const { organisationCfg } = meterStore;

  if (organisationCfg?.features.meteringValidationEnabled) {
    if (usage !== 'measurement') {
      return (
        <SimpleTooltip
          className={css['tooltip-content']}
          id={`meter-issue-tip-${tooltipId}`}
          tooltip={`${getTranslation('meter_issues.show_issues')} (${tooltipExtra})`}
        >
          <Link className={css['issues-link']} id={`meter-${id}`} url={`${rootUrl}/devices/meters/${id}/issues`}>
            <Icon name="alert-circle" color={colors['orange-600']} />
          </Link>
        </SimpleTooltip>
      );
    } else {
      return (
        <SimpleTooltip
          className={css['tooltip-content']}
          id={`meter-issue-tip-${tooltipId}`}
          tooltip={`${getTranslation(`meter_issues.error_${issueError}`) || ''} (${
            getTranslation(`meter_issues.${issueStatus}_issue`) || ''
          })`}
        >
          <Link className={css['issues-link']} id={`meter-${id}`} url={`${rootUrl}/devices/meters/${id}/issues`}>
            <Icon name="alert-circle" color={ICON_COLORS[issueStatus || colors['orange-600']]} />
          </Link>
        </SimpleTooltip>
      );
    }
  }

  return null;
};

export default observer(MeterIssueIndicator);
