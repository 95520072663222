import moment, { Moment } from 'moment';

import { dataFrequency, meterStatus, unitOfMeasure, utilityType } from '@zf/api-types/enums';
import { AddressType } from '@zf/api-types/general';
import { ExternalChannelType, MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import { convertHexToRGBA } from '@zf/utils/src/color';
import { betweenDates } from '@zf/utils/src/date';

import { LangReturnValue } from '../app-context/hooks/use-lang';
import { ICON_COLORS } from '../constants/icons';

export const uomSets = [
  [unitOfMeasure.wh, unitOfMeasure.kwh, unitOfMeasure.mwh, unitOfMeasure.gwh],
  [unitOfMeasure.j, unitOfMeasure.kj, unitOfMeasure.mj, unitOfMeasure.gj],
  [unitOfMeasure.litre, unitOfMeasure.m3, unitOfMeasure.m3_h],
  [unitOfMeasure.celcius, unitOfMeasure.k],
  [unitOfMeasure.day, unitOfMeasure.month, unitOfMeasure.year],
  [unitOfMeasure.none],
  [unitOfMeasure.units]
];

export const getDateTimeClass = (hoursDisabled: boolean, minutesDisabled: boolean) => {
  let dateTimeClass: string;

  if (!hoursDisabled && !minutesDisabled) {
    dateTimeClass = 'date-hour-minute';
  } else if (minutesDisabled && !hoursDisabled) {
    dateTimeClass = 'date-hour-no-minute';
  } else {
    dateTimeClass = 'date-only';
  }

  return dateTimeClass;
};

export const getDropDownTimeVals = (vals: string[], dataFrequency_: dataFrequency) => {
  if (dataFrequency_ !== dataFrequency.na) {
    return vals.map((v) => {
      return {
        value: v,
        text: v.length === 1 ? `0${v}` : v
      };
    });
  } else {
    return [];
  }
};

export const formatMeterText = (m: MeterType, address: AddressType) => {
  return `${m.serialNumber} - ${address.localizedDisplay}`;
};

export const formatActiveMeterText = (m: MeterType | null | undefined) => {
  if (!m) return '';

  const activeState: MeterStatusHistoryType | undefined = m.statusHistory.find((status) =>
    betweenDates(status.startDateTime, status.endDateTime, moment())
  );

  if (activeState && activeState.installedAtAddress) {
    const address = activeState.installedAtAddress;
    return `${m.serialNumber} - ${address.localizedDisplay}`;
  } else {
    return m.serialNumber;
  }
};

export const getCurrentInstalledMeterStatus = (meter: MeterType) => {
  const today = moment();

  return meter.statusHistory.find(
    (status) =>
      betweenDates(status.startDateTime, status.endDateTime, today) && status.meterStatus === meterStatus.installed
  );
};

export const getCurrentMeterStatus = (meter: MeterType, i18n: LangReturnValue) => {
  if (meter.statusHistory.length === 0) {
    throw new Error(i18n.getTranslation('errors.meter_no_status'));
  }
  return meter.statusHistory[meter.statusHistory.length - 1];
};

export const getActiveChannels = (channels: ExternalChannelType[], refDate?: Moment | string) => {
  if (!refDate) {
    refDate = moment();
  } else if (typeof refDate === 'string') {
    refDate = moment(refDate);
  }

  return channels.filter((chann) => {
    // @ts-ignore, refDate is always Moment here
    return refDate.isBetween(chann.startDateTime, chann.endDateTime, undefined, '[)');
  });
};

export const channelsAreEqual = (chann1: ExternalChannelType, chann2: ExternalChannelType) => {
  // Checks equality ignoring the dates
  return (
    chann1.dataFrequency === chann2.dataFrequency &&
    chann1.description === chann2.description &&
    chann1.direction === chann2.direction &&
    chann1.incrementationType === chann2.incrementationType &&
    chann1.meteringType === chann2.meteringType &&
    chann1.timeOfUse === chann2.timeOfUse &&
    chann1.unitOfMeasure === chann2.unitOfMeasure &&
    chann1.utilityType === chann2.utilityType
  );
};

export function getColorByUtilityType(utilityType: utilityType, isForecast = false) {
  const asString = utilityType as string;

  if (isForecast) {
    return convertHexToRGBA(ICON_COLORS[asString], 40);
  }

  return ICON_COLORS[asString];
}

export const formatMeterAddress = (activeState: MeterStatusHistoryType | undefined) => {
  if (!activeState) return '';

  const address = activeState.installedAtAddress;

  // Property group address is optional
  if (!address && activeState.propertyGroup) {
    return activeState.propertyGroup.name;
  }

  return address?.localizedDisplay || '';
};
