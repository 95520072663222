import { Moment } from 'moment';

import { PagedResponseType } from '@zf/api-types/api';
import { ChannelTemplateType, InstallMeterRequestType, MeterType } from '@zf/api-types/master-data/meter';

import { METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';

/**
 * "/md/Meters" should be renamed to "/md/Devices" in the future (backend change needed)
 */
export default class DeviceService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/md/Meters', applicationStore);
  }

  getMeters = async (query?: any) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeterType>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint,
          query
        }
      })
    ).data.results;
  };

  getMeterById = async (meterId: string) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/${meterId}`
        }
      })
    ).data;
  };

  getSubmeters = async (parentSerialNumber: string) => {
    return (
      await this.applicationStore.sendRequest<MeterType[]>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint,
          data: { parentSerialNumber }
        }
      })
    ).data;
  };

  installMeter = async (apiFriendlyValues: InstallMeterRequestType, meterId: string) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${meterId}/install`,
          data: apiFriendlyValues
        }
      })
    ).data;
  };

  uninstallMeter = async (meterId: string, removeDate: Moment) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${meterId}/uninstall`,
          data: {
            removeDate: removeDate.toISOString()
          }
        }
      })
    ).data;
  };

  unlinkMeter = async (meterId: string) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${meterId}/updateparentrelation`,
          data: {
            meterId: null,
            externalReference: null
          }
        }
      })
    ).data;
  };

  updateMeterTag = async (meterTag: string, meterId: string) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${meterId}/metertag`,
          data: { meterTag }
        }
      })
    ).data;
  };

  updateMeterConfig = async (
    meterId: string,
    channeltemplates: ChannelTemplateType[],
    mutationDateTime: Moment,
    meterType: string
  ) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${meterId}`,
          data: {
            mutationDateTime: mutationDateTime.toISOString(),
            channelTemplates: channeltemplates,
            meterType: meterType
          }
        }
      })
    ).data;
  };

  updateParentRelation = async (meterId: string, parentId: string, externalReference: string) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${meterId}/updateparentrelation`,
          data: {
            meterId: parentId,
            externalReference
          }
        }
      })
    ).data;
  };
}
