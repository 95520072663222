import { observer } from 'mobx-react';
import React from 'react';

import { templateUsecase } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';
import ActionDescription from '@zf/stella-react/src/atoms/ActionDescription/ActionDescription';
import { CardItem } from '@zf/stella-react/src/atoms/Card';

import Button from '../../../../../components/Button/Button';
import { dialog } from '../../../../../events/dialog-events';
import { useStore } from '../../../../../hooks/useStore';
import AddCustomHtmlDialog from '../dialogs/add-custom-html-dialog';

type Props = {
  templateUsecase: templateUsecase;
};

const AddCustomHTML = (props: Props) => {
  const { templateUsecase } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const openCustomHtmlDialog = () => {
    dialog.normal({
      title: getTranslation('communication.upload_custom_html'),
      content: <AddCustomHtmlDialog templateUseCase={templateUsecase} ref={clickRef} validationRef={validationRef} />,
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <CardItem width="12">
      <ActionDescription
        title={getTranslation('communication.add_custom_html')}
        description={getTranslation('communication.add_custom_html_descr')}
        helpUrl="https://support.zerofriction.co/hc/nl/articles/5598639419537-Een-html-bestand-uploaden-voor-uw-pdf-en-e-mailsjablonen-en-vertalingen-aanpassen"
        action={
          <Button id={`add-custom-html-${templateUsecase}`} type="text" onClick={openCustomHtmlDialog} icon="plus">
            {getTranslation('general.add')}
          </Button>
        }
      />
    </CardItem>
  );
};

export default observer(AddCustomHTML);
