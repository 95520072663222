import React from 'react';

import { OverviewType } from '@zf/api-types/import-job';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import css from './error-card.module.scss';

import { useAppContext } from '../../../../app-context';

type Props = {
  overview: OverviewType;
};

export default function ErrorsCard(props: Props) {
  const { overview } = props;
  const { i18n } = useAppContext();

  return (
    <Card id="errors-card" width="2">
      <CardHeader>{i18n.getTranslation('errors.errors')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{i18n.getTranslation('import_jobs.processing_errors')}</Label>
        </CardItem>
        <CardItem width="6">
          {overview.processingErrors.length > 0 ? (
            <div>
              {overview.processingErrors.map((error) => {
                return (
                  <Paragraph
                    key={error.localisedError.key}
                  >{`${error.localisedError.message} x ${error.count}`}</Paragraph>
                );
              })}
            </div>
          ) : (
            <Paragraph>{i18n.getTranslation('general.none')}</Paragraph>
          )}
        </CardItem>
        <CardItem width="6">
          <Label className={css['import-jobs-validation-error']}>
            {i18n.getTranslation('import_jobs.validation_errors')}
          </Label>
        </CardItem>
        <CardItem width="6">
          {overview.validationErrors.length > 0 ? (
            <div>
              {overview.validationErrors.map((error) => {
                return (
                  <Paragraph
                    key={error.localisedError.key}
                  >{`${error.localisedError.message} x ${error.count}`}</Paragraph>
                );
              })}
            </div>
          ) : (
            <Paragraph>{i18n.getTranslation('general.none')}</Paragraph>
          )}
        </CardItem>
      </CardBody>
    </Card>
  );
}
