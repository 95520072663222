import clone from 'clone';
import queryString from 'query-string';
import React from 'react';

import { queryParam } from '../../hooks/useCreateRequest';
import { isEmptyObject } from '@zf/utils/src/object';
import { CurrentState, DomainType, FilterType } from '../app-state';
import useCurrent from './use-current';

type Result<T> = {
  setQueryParams: (params: Partial<T>) => void;
  clearQueryParams: () => void;
  queryParams: T;
};

export default function useQueryParam<T extends object>(
  domain: DomainType,
  initialParam?: T,
  search?: string
): Result<T> {
  const { myCurrent, setMyCurrent } = useCurrent(domain);

  React.useEffect(() => {
    const receivedQueryParams = search ? queryString.parse(search) : {};
    const updatedInitialParams: any = clone(initialParam);
    let quickFilter = 'all';
    let searchValue = '';

    if (receivedQueryParams && !isEmptyObject(receivedQueryParams)) {
      for (const key of Object.keys(receivedQueryParams)) {
        if (key === 'quickFilter') {
          quickFilter = receivedQueryParams[key] ? (receivedQueryParams[key] as string) : 'all';
        } else if (key === 'searchValue') {
          searchValue = receivedQueryParams[key] ? (receivedQueryParams[key] as string) : '';
        } else if (key === 'invoiceStatus' && updatedInitialParams) {
          updatedInitialParams[key] = [receivedQueryParams[key]];
        } else if (updatedInitialParams) {
          updatedInitialParams[key] = receivedQueryParams[key];
        }
      }
    }

    if (updatedInitialParams && !isEmptyObject(updatedInitialParams)) {
      const newParams: queryParam = { ...myCurrent.filter.queryParams, ...updatedInitialParams };
      const newFilter: FilterType = { ...myCurrent.filter, quickFilter, search: searchValue, queryParams: newParams };
      const newCurrent: CurrentState = { ...myCurrent, filter: newFilter };
      setMyCurrent(newCurrent);
    }
  }, []);

  const setQueryParams = (params: Partial<T>) => {
    const newParams: queryParam = { ...myCurrent.filter.queryParams, ...params };
    const newFilter: FilterType = { ...myCurrent.filter, queryParams: newParams };
    const newCurrent: CurrentState = { ...myCurrent, filter: newFilter };
    setMyCurrent(newCurrent);
  };

  const clearQueryParams = () => {
    const newParams: queryParam = initialParam ? initialParam : {};
    const newFilter: FilterType = { ...myCurrent.filter, queryParams: newParams };
    const newCurrent: CurrentState = { ...myCurrent, filter: newFilter };
    setMyCurrent(newCurrent);
  };

  return {
    setQueryParams,
    clearQueryParams,
    queryParams: myCurrent.filter.queryParams as T
  };
}
