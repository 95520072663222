import { useEffect } from 'react';

import { useAppContext } from '../';
import { SharedEntityProperties } from '../../../../api-types/entity';
import { SortStateType } from '../../hooks/useCreateRequest';
import useInfiniAPI, { RowTypeBase } from '../../hooks/useInfiniAPI';
import useListPageCreateRequest from '../../hooks/useListPageCreateRequest';
import useLocalRefresh from '../../hooks/useLocalRefresh';
import { getRowsForIds } from '../../utils/action';
import { DispatchListPageState, DomainType } from '../app-state';
import useCurrent from './use-current';

type Props<E, R, Q> = {
  domain: DomainType;
  endpoint: string;
  defaultQueryParams?: Q;
  mock?: boolean;
  processRecord: (value: E) => R;
};

const useListPage = <E extends SharedEntityProperties, R extends RowTypeBase, Q = {}>(props: Props<E, R, Q>) => {
  const { processRecord, domain, endpoint, defaultQueryParams = {} as Q, mock = false } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { myCurrent, setMyCurrent } = useCurrent(domain);

  const { request, timeStamp } = useListPageCreateRequest<Q>(
    endpoint,
    myCurrent.filter,
    myCurrent.listPage.sort,
    defaultQueryParams
  );

  const { timeStamp: localTimeStamp, refresh: localRefresh } = useLocalRefresh();

  const updateListPage = (action: DispatchListPageState) => {
    const newListPage = { ...myCurrent.listPage, ...action };
    const newCurrent = { ...myCurrent, listPage: newListPage };
    setMyCurrent(newCurrent);
  };

  const setSelectedIds = (ids: string[]) =>
    updateListPage({ selectedIds: ids, selectedRows: getRowsForIds(rows, ids) });

  const {
    loading,
    error,
    rows,
    setStopIndex,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    sortableFields,
    updateGivenRows
  } = useInfiniAPI<E, R>({
    request,
    tenantReducer,
    lang: i18n.lang,
    mock,
    processRecord,
    onSelectRow: setSelectedIds
  });

  useEffect(() => {
    if (myCurrent.listPage.updatedRows.length > 0) {
      updateGivenRows(myCurrent.listPage.updatedRows, []);
      localRefresh();
    }

    if (myCurrent.listPage.deletedRows.length > 0) {
      updateGivenRows([], myCurrent.listPage.deletedRows);
      localRefresh();
    }
  }, [myCurrent.listPage.updatedRows, myCurrent.listPage.deletedRows]);

  useEffect(() => {
    if (!selectAllBusy) {
      if (rows.length > 0 || rows.length === 0) {
        const allIds = rows.reduce((acc: string[], row: R) => {
          if (row.__id) acc.push(row.__id);
          return acc;
        }, []);

        // Check if there are ids in selectedIds which aren't in allIds anymore
        const newSelection = myCurrent.listPage.selectedIds.reduce((acc: string[], id: string) => {
          if (allIds.includes(id)) acc.push(id);
          return acc;
        }, []);

        // If we found ids
        if (newSelection.length !== myCurrent.listPage.selectedIds.length) {
          updateListPage({
            allIds,
            selectedIds: newSelection,
            selectedRows: getRowsForIds(rows, newSelection)
          });
        } else if (rows.length > 0) {
          updateListPage({
            allIds,
            selectedRows: getRowsForIds(rows, newSelection)
          });
        }
        localRefresh();
      }
    }
  }, [rows, selectAllBusy]);

  const handleSort = (sortParams: SortStateType) => {
    const { sortBy, sortDirection } = sortParams;
    updateListPage({ sort: { sortBy, sortDirection } });
  };

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds: myCurrent.listPage.selectedIds,
    sortBy: myCurrent.listPage.sort.sortBy,
    sortDirection: myCurrent.listPage.sort.sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    handleSort,
    setSelectedIds,
    setStopIndex,
    timeStamp,
    localTimeStamp
  };
};

export default useListPage;
