import React from 'react';

type Props = {
  name: string;
  children?: JSX.Element;
};

const StepAnchor = React.memo((props: Props) => {
  const stepName = 'wizard-step-' + props.name;

  return (
    <a id={stepName} href="/#">
      {props.children}
    </a>
  );
});

export default StepAnchor;
