import React from 'react';
import css from './referral-card.module.scss';
import BaseLink from 'design-system/Components/Link/BaseLink';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import Paragraph from 'design-system/Foundation/Paragraph/Paragraph';
import { colors } from '@zf/utils/src/color';
import IconParagraph from 'components/Icon/IconParagraph';
import NewStatusBadge from '@zf/stella-react/src/atoms/Badge/NewStatusBadge';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';

type Props = {
  title: string;
  extraInfo?: string;
  extraInfoSuffix?: string;
  status?: string | number;
  url: string;
};

const ReferralCard = (props: Props) => {
  const { applicationStore } = useStore();
  const { rootUrl } = applicationStore;

  const { title, extraInfo, extraInfoSuffix, status, url } = props;

  //Color Logic case 1
  // Certain words mean 'bad', 'warning', 'good'

  //Color Logic case 2
  // 0 is bad and any value higher is good

  //Color Logic case 3
  //0 is good and any value higher is bad(warning)

  let color = colors['green-600'];

  if (typeof status === 'number' && status > 0) {
    color = colors['orange-600'];
  } else if (typeof status === 'number' && status <= 0) {
    color = colors['green-600'];
  }

  return (
    <BaseLink id="referral-card-link" className={css['card-dash']} url={`${rootUrl}${url}`}>
      <div>
        <Heading headingType="h3">{title}</Heading>

        {extraInfo && <Paragraph color={colors['silver-400']}>{`${extraInfo} ${extraInfoSuffix || ''}`}</Paragraph>}
      </div>

      <IconParagraph className={css['chevron-paragraph']} iconType="chevron-right" iconPosition="right">
        {status !== '' && (
          <NewStatusBadge
            color={color}
            type={typeof status === 'string' ? 'normal' : 'numeric'}
            status_={String(status)}
          />
        )}
      </IconParagraph>
    </BaseLink>
  );
};

export default observer(ReferralCard);
