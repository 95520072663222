import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import useDialog from '@zf/hooks/src/useDialog';

import ManuallyConfirmOutgoingBankingTransactionDialog from '../../../actions/outgoing-banking-transactions/ManuallyConfirmOutgoingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  bankingTransaction: OutgoingBankingTransactionType;
  setOutgoingBankingTransaction: React.Dispatch<React.SetStateAction<OutgoingBankingTransactionType | null>>;
};

export default function ManuallyConfirmDetailButton(props: Props) {
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="outgoing_banking_transaction.manually_confirm"
      icon="check"
      dialogTitle={i18n.getTranslation('actions.banking_transaction.manually_confirm')}
      dialogContent={<ManuallyConfirmOutgoingBankingTransactionDialog {...props} ref={clickRef} />}
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.banking_transaction.overwrite')}
    />
  );
}
