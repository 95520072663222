import React from 'react';
import ReactJson from 'react-json-view';

import css from './style.module.scss';

type Props = {
  json: JSON;
};

export default function JSONDialog(props: Props) {
  const { json } = props;

  return (
    <div className={css['size']}>
      <ReactJson src={json} />
    </div>
  );
}
