import ContractService from 'app-context/services/master-data/ContractService';
import MoveInWizardBaseStore from 'app-context/stores/master-data/contracts/wizard/move-in/MoveInWizardBaseStore';
import MoveOutWizardBaseStore from 'app-context/stores/master-data/contracts/wizard/move-out/MoveOutWizardBaseStore';
import { notify } from 'events/notification-events';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';

import { UserDetails } from '@zf/api-types/auth';
import { BillingRelationType } from '@zf/api-types/billing-relation';
import { BillingCompletenessInsightResponseType } from '@zf/api-types/billing/billing-completeness';
import { EntityAttachment } from '@zf/api-types/entity-attachments/entity-attachments';
import { advanceAmountChangedBy, contractStatus, entitySubjectType } from '@zf/api-types/enums';
import {
  BlockUnblockContractType,
  ChangeProductContractType,
  ContractedService,
  ContractedServiceEstimatedConsumption,
  ContractServiceLocation,
  ContractType,
  ProductPeriodReference
} from '@zf/api-types/master-data/contract';
import { MeterType } from '@zf/api-types/master-data/meter';
import { onlyUniqueObjects } from '@zf/utils/src/array';

import RootStore from '../../..';
import { EntityAttachmentSpecificFeatures } from '../../../../../components/units/EntityAttachments/logic/entity-attachments';
import EntityAttachmentBase from '../../../../../components/units/EntityAttachments/logic/EntityAttachmentBase';
import FilesStore from '../../../../../components/units/EntityAttachments/logic/FilesStore';
import ContractDetailsForm, {
  ContractDetailFieldTypes
} from '../../../../../features/contract/forms/ContractDetailsForm';
import Contract from '../../../../../features/contract/models/ContractModel';
import EstimatedInvoiceService from '../../../../services/billing/EstimatedInvoiceService';
import ForecastingService from '../../../../services/ForecastingService';
import BaseStore from '../../../BaseStore';
import BillingCompletenessStore from './billing-insights/BillingCompletenessStore';

export enum contractActionPermissions {
  mayBlockUnblock = 'mayBlockUnblock',
  mayDeleteContract = 'mayDeleteContract',
  maySignContract = 'maySignContract',
  mayTerminateContract = 'mayTerminateContract',
  mayRecalculateEav = 'mayRecalculateEav'
}

export default class ContractStore
  extends BaseStore<ContractType>
  implements EntityAttachmentSpecificFeatures<EntityAttachmentBase>
{
  public rootStore: RootStore;
  public filesStore: FilesStore<EntityAttachment>;

  // Wizard
  public moveInWizardBaseStore: MoveInWizardBaseStore;
  public moveOutWizardBaseStore: MoveOutWizardBaseStore;

  // Detail page
  public billingCompletenessStore: BillingCompletenessStore;

  public billingRelationUser: UserDetails | null;

  public contractApiService: ContractService;
  public forecastingService: ForecastingService;
  public estimatedInvoiceService: EstimatedInvoiceService;

  public pageActionPermissions: Record<contractActionPermissions, boolean>;
  public selectedContract_: Contract | null;
  public billingRelation_: BillingRelationType | null;
  public billingInsights_: BillingCompletenessInsightResponseType | null;
  public prepaymentDevice: MeterType | null | undefined;

  public contractDetailsForm_: ContractDetailsForm | null;
  public entityAttachmentFeatures: EntityAttachmentBase = new EntityAttachmentBase();

  constructor(rootStore: RootStore) {
    super();
    Object.assign(this, new EntityAttachmentBase());
    this.rootStore = rootStore;
    this.filesStore = new FilesStore(
      this.rootStore,
      entitySubjectType.contract,
      this.entityAttachmentFeatures.setPermissions
    );

    this.moveInWizardBaseStore = new MoveInWizardBaseStore(this, rootStore.applicationStore);
    this.moveOutWizardBaseStore = new MoveOutWizardBaseStore(this, rootStore.applicationStore);

    this.billingCompletenessStore = new BillingCompletenessStore(rootStore);

    this.contractApiService = new ContractService(this, rootStore.applicationStore);
    this.forecastingService = new ForecastingService(rootStore.applicationStore);
    this.estimatedInvoiceService = new EstimatedInvoiceService(rootStore.applicationStore);

    this.entityAttachmentFeatures.setFileStore(this.filesStore);
    this.setExistingEntity = this.setExistingContract;

    makeObservable(this, {
      // Wizard
      moveInWizardBaseStore: observable,
      moveOutWizardBaseStore: observable,

      // Detail page
      billingCompletenessStore: observable,

      selectedContract_: observable,
      billingRelation_: observable,
      billingInsights_: observable,
      pageActionPermissions: observable,
      contractDetailsForm_: observable,
      prepaymentDevice: observable,

      selectedContract: computed,
      billingRelation: computed,
      billingInsights: computed,

      setNewContract: action,
      setExistingContract: action,
      setContracDetailsForm: action,
      loadContractDetailData: action,
      signContract: action,
      generateActionPermissions: action,
      deleteContract: action,
      terminateContract: action,
      blockUnBlock: action,
      resetBillingDetails: action,
      onLeaveDetailPage: action,
      setBillingData: action,
      setBillingRelation: action,
      setPrepaymentDevice: action,
      getChangedBy: action
    });
  }

  setContracDetailsForm = (contractValues: ContractDetailFieldTypes) => {
    this.contractDetailsForm_ = new ContractDetailsForm(
      {
        paymentTerm: contractValues.paymentTerm,
        externalReferenceId: contractValues.externalReferenceId
      },
      this
    );
  };

  generateTitle = (constract: ContractType) => {
    return `${this.rootStore.applicationStore.getTranslation('contracts.contract')} - ${constract.contractNumber}`;
  };

  loadContractDetailData = async (id: string) => {
    this.resetBillingDetails();
    const result = await this.contractApiService.getContractForId(id);
    this.setNewContract(result);

    this.setContracDetailsForm({
      paymentTerm: result.paymentTermsId,
      externalReferenceId: result.externalContractReference
    });

    this.rootStore.uiStore.setBrowserTitle(this.generateTitle(result));
  };

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get selectedContract() {
    return this.selectedContract_ as Contract;
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get billingRelation() {
    return this.billingRelation_ as BillingRelationType;
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get billingInsights() {
    return this.billingInsights_ as BillingCompletenessInsightResponseType | null;
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get contractDetailsForm() {
    return this.contractDetailsForm_ as ContractDetailsForm;
  }

  setBillingRelation = (newBillingRelation: BillingRelationType | null) => {
    this.billingRelation_ = newBillingRelation;
  };

  setBillingData = (
    newBillingRelation: BillingRelationType | null,
    newInsights: BillingCompletenessInsightResponseType | null,
    newBillingRelationUser: UserDetails | null
  ) => {
    this.setBillingRelation(newBillingRelation);
    this.billingInsights_ = newInsights;
    this.billingRelationUser = newBillingRelationUser;
  };

  setNewContract = (contract: ContractType | null) => {
    runInAction(async () => {
      if (contract) {
        this.selectedContract_ = new Contract(this.rootStore);
        this.selectedContract_.contract = contract;
        await this.refresh();
      }
    });
  };

  setPrepaymentDevice = (meter: MeterType | null | undefined) => {
    this.prepaymentDevice = meter;
  };

  refresh = async () => {
    const contract = this.selectedContract_?.contract;

    if (contract) {
      this.generateActionPermissions(contract);

      // If a billing relation may exist
      if (contract.currentContractStatus !== contractStatus.draft) {
        await this.contractApiService
          .getBillingRelation(contract.id, contract.contractor.customerId)
          .then(async (billingRelationResult) => {
            if (billingRelationResult) {
              const billingInsightsResult = await this.contractApiService.billingCompleteness(billingRelationResult.id);
              const billingRelationUser = await this.rootStore.applicationStore.userStore.userAuthService.getUserForId(
                billingRelationResult.userId
              );
              this.setBillingData(billingRelationResult, billingInsightsResult, billingRelationUser);
            }
          })
          .catch(({ status }) => {
            this.billingInsights_ = null;
            this.billingRelation_ = null;

            if (status === 404) {
              setTimeout(() => this.refresh(), 5000);
            }
          });
      }
    }
  };

  setExistingContract = (contract: ContractType | null) => {
    if (contract && this.selectedContract_) {
      this.selectedContract_.contract = contract;
      this.refresh();
    }
  };

  generateActionPermissions = (contract: ContractType) => {
    this.pageActionPermissions = {
      mayRecalculateEav:
        contract.currentContractStatus === contractStatus.signed ||
        contract.currentContractStatus === contractStatus.terminated,
      maySignContract: contract.currentContractStatus === contractStatus.draft,
      mayDeleteContract: contract.currentContractStatus === contractStatus.draft || !contract.blockedForDeletion,
      mayTerminateContract: contract.currentContractStatus !== contractStatus.cancelled,
      mayBlockUnblock:
        contract.currentContractStatus === contractStatus.signed ||
        contract.currentContractStatus === contractStatus.terminated
    };
  };

  resetBillingDetails = () => {
    this.billingRelation_ = null;
    this.billingInsights_ = null;
    this.billingRelationUser = null;
  };

  onLeaveDetailPage = () => {
    this.resetBillingDetails();
    this.selectedContract_ = null;
    this.prepaymentDevice = undefined;
    this.filesStore.resetStore();
  };

  updateContractDetails = async (externalContractReference: string, paymentTerm: string) => {
    if (this.selectedContract.contract.externalContractReference !== externalContractReference) {
      await this.contractApiService.updateExternalContractRef(
        this.selectedContract.contract,
        externalContractReference
      );
    }
    await this.contractApiService.updatePaymentTerm(this.selectedContract.contract, paymentTerm);
    this.executeAction(this.contractApiService.getContractForId(this.selectedContract.contract.id), true);
  };

  signContract = (
    value: ContractType,
    mutationDateTime: string,
    keepExistingInvoiceOnEndDate = false,
    isDetailPage = false
  ) => {
    return this.executeAction(
      this.contractApiService.signContract(value, mutationDateTime, keepExistingInvoiceOnEndDate),
      isDetailPage
    );
  };

  deleteContract = async (id: string) => {
    await this.contractApiService.deleteEntity(id);
  };

  terminateContract = (
    contract: ContractType,
    mutationDateTime: string,
    keepExistingInvoiceOnEndDate: boolean,
    isDetailPage = false
  ) => {
    return this.executeAction(
      this.contractApiService.terminateContract(contract, mutationDateTime, keepExistingInvoiceOnEndDate),
      isDetailPage
    );
  };

  updateAdvanceAmount = async (contract: ContractType, advanceAmount: number) => {
    const { getTranslation } = this.rootStore.applicationStore;

    try {
      const { getBillingRelation, changeBillingRelationAdvanceAmount } = this.contractApiService;

      // Extra lame calls to update advance amount when changed
      const billingRelation = await getBillingRelation(contract.id, contract.contractor.customerId);

      if (billingRelation) {
        await changeBillingRelationAdvanceAmount(billingRelation.id, advanceAmount, false);
      }
    } catch (error) {
      notify.error({
        content: getTranslation('actions.contract.change_advance_amount_failed'),
        error
      });
    }
  };

  changeProduct = (value: ChangeProductContractType, isDetailPage = false) => {
    return this.executeAction(this.contractApiService.changeProduct(value), isDetailPage);
  };

  blockUnBlock = (value: BlockUnblockContractType, comment: string, isDetailPage = false) => {
    return this.executeAction(this.contractApiService.blockUnblockContract(value, comment), isDetailPage);
  };

  getChangedBy = (billingRelation: BillingRelationType) => {
    const { getEnumTranslation } = this.rootStore.applicationStore;
    let changedBy = getEnumTranslation('advanceAmountChangedBy', billingRelation.advanceDetails.changedBy || '');

    if (billingRelation.advanceDetails.changedBy === advanceAmountChangedBy.customerservicerepresentative) {
      if (!!this.billingRelationUser) {
        changedBy = this.billingRelationUser.userName || this.billingRelationUser.email;
      }
    }

    return changedBy;
  };

  /**
   * Utils
   */
  serviceIsInFuture = (s: ContractedService) => moment().isBefore(s.startDateTime);

  serviceEndsEarly = (s: ContractedService, contractEndDate: string) => moment(contractEndDate).isAfter(s.endDateTime);

  getFutureContractedLocations = (serviceLocations: ContractServiceLocation[]) => {
    return serviceLocations.filter((sl) => sl.services?.some(this.serviceIsInFuture));
  };

  getEarlyEndingLocations = (serviceLocations: ContractServiceLocation[], contractEndDate: string) => {
    return serviceLocations.filter((sl) => sl.services?.some((s) => this.serviceEndsEarly(s, contractEndDate)));
  };

  getFutureContractedLocationsPerAddedDate = (serviceLocations: ContractServiceLocation[]) => {
    const futureLocationsPerAddedDate = new Map<string, ContractServiceLocation[]>();

    serviceLocations.forEach((sl) => {
      const futureService = sl.services?.find(this.serviceIsInFuture);

      if (futureService) {
        const entry = futureLocationsPerAddedDate.get(futureService.startDateTime);

        futureLocationsPerAddedDate.set(futureService.startDateTime, entry ? [...entry, sl] : [sl]);
      }
    });

    return futureLocationsPerAddedDate;
  };

  getEarlyEndingContractedLocationsPerRemovedDate = (
    serviceLocations: ContractServiceLocation[],
    contractEndDate: string
  ) => {
    const removedLocationsPerAddedDate = new Map<string, ContractServiceLocation[]>();

    serviceLocations.forEach((sl) => {
      const earlyEndingService = sl.services?.find((s) => this.serviceEndsEarly(s, contractEndDate));

      if (earlyEndingService) {
        const entry = removedLocationsPerAddedDate.get(earlyEndingService.endDateTime);

        removedLocationsPerAddedDate.set(earlyEndingService.endDateTime, entry ? [...entry, sl] : [sl]);
      }
    });

    return removedLocationsPerAddedDate;
  };

  getRightProduct = (
    products: ProductPeriodReference[],
    contract: ContractType
  ): ProductPeriodReference | undefined => {
    if (
      contract.currentContractStatus !== contractStatus.terminated &&
      contract.currentContractStatus !== contractStatus.cancelled &&
      !moment().isBefore(contract.supplyStartDate) &&
      !moment().isAfter(contract.supplyEndDate)
    ) {
      return products.find((product) =>
        moment().isBetween(moment(product.startDateTime), moment(product.endDateTime), undefined, '[]')
      );
    } else {
      return products[products.length - 1];
    }
  };

  getProductText = (contract: ContractType) => {
    const products = contract.billingDetails.products;
    const product = this.getRightProduct(products, contract);
    const { getTranslation } = this.rootStore.applicationStore;

    if (contract.usePropertyGroupProduct) {
      return getTranslation('contracts.prod_prop_lvl');
    } else if (product) {
      return product.productName;
    }

    return '';
  };

  getEstimatedConsumptionsForSelectedContract = (contract: ContractType): ContractedServiceEstimatedConsumption[] => {
    return onlyUniqueObjects(
      contract.serviceLocations
        .reduce((acc: ContractedServiceEstimatedConsumption[][], sl) => {
          if (sl.services) {
            acc.push(sl.services.flatMap((s) => s.estimatedConsumptions || []));
          }

          return acc;
        }, [])
        .flat()
    );
  };
}
