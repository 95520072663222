import clone from 'clone';
import queryString from 'query-string';
import { useEffect } from 'react';
import { QueryParamConfigMap, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { CustomerType } from '@zf/api-types/master-data/customer';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { deepEqual, isEmptyObject } from '@zf/utils/src/object';

import useSingleAPI from '../../hooks/useSingleAPI';
import { CurrentState, DomainType, FilterType, initialCurrent } from '../app-state';
import useCurrent from './use-current';
import useRefresh from './use-refresh';

type OverviewCountType = Record<string, number>;

type Props<T, Q> = {
  endpoint: string;
  initialCount: OverviewCountType;
  domain: DomainType;
  search?: string;
  defaultQueryParams?: Q; // params unimpacted by UI
  initialQueryParams?: T;
  defaultQuickFilter?: string;
  paramTypes: QueryParamConfigMap;
};

type Result<T> = {
  onFilter: (quickFilter: string) => void;
  customer?: CustomerType;
  propertyGroup?: PropertyGroupType;
  onSearch: (search: string) => void;
  refresh: () => void;
  setQueryParams: (params: Partial<T>) => void;
  clearQueryParams: () => void;
  quickFilter: string;
  overviewCount: OverviewCountType;
  searchValue: string;
  timeStamp: string;
  queryParams: T;
};

export type ExternalDataQueryParamsType = {
  propertyGroupId?: string;
  propertyGroupName?: string;
  customerId?: string;
  customerName?: string;
};

export default function useFilter<T, Q = {}>(props: Props<T, Q>): Result<T> {
  const {
    endpoint,
    domain,
    search,
    defaultQueryParams = {},
    initialQueryParams = {},
    defaultQuickFilter = 'all',
    paramTypes
  } = props;

  const [query, setQuery] = useQueryParams(paramTypes);
  const [quickFilter, setQuickFilter] = useQueryParams({
    quickFilter: withDefault(StringParam, defaultQuickFilter),
    searchValue: StringParam
  });
  const { myCurrent, setMyCurrent } = useCurrent(domain);
  const { timeStamp, refresh } = useRefresh();

  useEffect(() => {
    // When not initialized yet
    if (deepEqual(myCurrent, initialCurrent)) {
      // Initialize filter part of myCurrent
      const receivedQueryParams = search ? queryString.parse(search) : {};
      const updatedInitialParams: any = clone(initialQueryParams);

      if (receivedQueryParams && !isEmptyObject(receivedQueryParams)) {
        for (const key of Object.keys(receivedQueryParams)) {
          if (key === 'quickFilter') {
            setQuickFilter({ quickFilter: receivedQueryParams[key] ? (receivedQueryParams[key] as string) : 'all' });
          } else if (key === 'searchValue') {
            setQuickFilter({ searchValue: receivedQueryParams[key] ? (receivedQueryParams[key] as string) : '' });
          } else if (key === 'invoiceStatus' && updatedInitialParams) {
            updatedInitialParams[key] = [receivedQueryParams[key]];
          } else if (updatedInitialParams) {
            updatedInitialParams[key] = receivedQueryParams[key];
          }
        }
      }

      if (updatedInitialParams && !isEmptyObject(updatedInitialParams)) {
        const newParams: T = { ...myCurrent.filter.queryParams, ...updatedInitialParams };
        const newFilter = {
          ...myCurrent.filter,
          quickFilter: quickFilter.quickFilter as string,
          search: quickFilter.searchValue as string,
          queryParams: newParams
        };
        const newCurrent: CurrentState = { ...myCurrent, filter: newFilter as any };
        setQuery(newParams as any);
        setMyCurrent(newCurrent);
      }
    }
  }, []);

  //entity filters that need external data to work, this will look for customer external data and propertygroup externaldata
  //if a property is present in the query params with  external data, it will automagically seek and perform the response and replace the queryparam with the correct data
  //when adding a new filter based on an entity, you will need to make changes in here. No changes need to be made in the listpages.

  const customer = useSingleAPI<CustomerType>({
    request: {
      endpoint: `/md/customers/${query['customerId']}`
    },
    mayExecute: query['customerId'] ? true : false
  });

  const propertyGroup = useSingleAPI<PropertyGroupType>({
    request: {
      endpoint: `/md/propertygroups/${query['propertyGroupId']}`
    },
    mayExecute: query['propertyGroupId'] ? true : false
  });

  useEffect(() => {
    const clonedInitialQueryParams = clone(initialQueryParams);
    const ref = clonedInitialQueryParams as ExternalDataQueryParamsType;
    //mapping of the result
    if (propertyGroup.result) {
      ref['propertyGroupId'] = propertyGroup.result?.data.id;
      ref['propertyGroupName'] = propertyGroup.result?.data.name;
    }

    if (customer.result) {
      ref['customerId'] = customer.result?.data.id;
      ref['customerName'] = customer.result?.data.lastName;
    }

    if (initialQueryParams) {
      if (customer.result || propertyGroup.result) {
        const newParams = { ...query, ...ref };
        setQueryParams(newParams as T);
      }
    }
  }, [customer.result, propertyGroup.result]);

  const overviewCountResponse = useSingleAPI<OverviewCountType>({
    request: {
      endpoint: endpoint,
      query: {
        ...defaultQueryParams,
        timeStamp,
        flexSearch: quickFilter.searchValue,
        ...(query as T),
        quickFilter: quickFilter.quickFilter
      }
    }
  });

  const filterAction = (quickFilter: string) => {
    const newFilter: FilterType = { ...myCurrent.filter, quickFilter };
    const newCurrent: CurrentState = {
      ...myCurrent,
      filter: newFilter,
      listPage: { ...myCurrent.listPage }
    };
    setQuickFilter({ quickFilter });
    setMyCurrent(newCurrent);
  };

  useEffect(() => {
    if (overviewCountResponse.result && !(overviewCountResponse.result instanceof Promise)) {
      const newFilter: FilterType = {
        ...myCurrent.filter,
        quickFilter: quickFilter.quickFilter as string,
        search: quickFilter.searchValue as string,
        queryParams: query
      };
      const newCurrent: CurrentState = { ...myCurrent, filter: newFilter };
      setMyCurrent({
        ...newCurrent,
        listPage: { ...myCurrent.listPage, overviewCounts: { ...overviewCountResponse.result.data } }
      });
    }
  }, [overviewCountResponse.result]);

  const searchAction = (searchVal: string) => {
    const newFilter: FilterType = { ...myCurrent.filter, search: searchVal };
    const newCurrent: CurrentState = { ...myCurrent, filter: newFilter };
    setQuickFilter({ searchValue: searchVal });
    setMyCurrent(newCurrent);
  };

  const setQueryParams = (params: Partial<T>) => {
    const newParams = { ...myCurrent.filter.queryParams, ...params };
    setQuery(newParams);
  };

  const setCurrentParams = (params: Partial<T>) => {
    const newParams = { ...myCurrent.filter.queryParams, ...params };
    const newFilter: FilterType = { ...myCurrent.filter, queryParams: newParams };
    const newCurrent: CurrentState = { ...myCurrent, filter: newFilter };
    setMyCurrent(newCurrent);
  };

  useEffect(() => {
    setCurrentParams(query as T);
  }, [query]);

  const clearQueryParams = () => {
    const newParams = initialQueryParams ? initialQueryParams : {};
    const stateParams: any = {};

    //mapping cause use params requires object definition to work
    Object.keys(query).forEach((e) => {
      stateParams[e] = undefined;
    });
    setQuery(stateParams);
    const newFilter: FilterType = { ...myCurrent.filter, queryParams: newParams };
    const newCurrent: CurrentState = { ...myCurrent, filter: newFilter };
    setMyCurrent(newCurrent);
  };

  return {
    onFilter: filterAction,
    onSearch: searchAction,
    refresh,
    setQueryParams,
    clearQueryParams,
    quickFilter: quickFilter.quickFilter as string,
    overviewCount: myCurrent.listPage.overviewCounts,
    searchValue: quickFilter.searchValue as string,
    timeStamp,
    queryParams: query as T
  };
}
