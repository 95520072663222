import { observer } from 'mobx-react';
import React from 'react';

import { propertyGroupType } from '@zf/api-types/enums';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';
import TwoColumnSection from '@zf/stella-react/src/atoms/Wizard/TwoColumnSection';

import BankAccountsDropdown, {
  BankAccountsDropdownProps
} from '../../../components/Dropdown/bankaccounts-dropdown/BankAccountsDropdown';
import CountryInput, { CountryInputProps } from '../../../components/input/country-input';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import Select from '../../../components/input/Select';
import { useStore } from '../../../hooks/useStore';
import { CreatePropertyType, STEP_NAMES } from './property-group-wizard';

type Props = {
  values: CreatePropertyType;
  setValue: (value: Partial<CreatePropertyType>, initialApiSet?: boolean, backup?: boolean) => void;
  handleFocus: (step: string) => () => void;
};

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);
const WizardCountryPicker = WizardInputWrapper<CountryInputProps>(CountryInput);
const WizardIbanDropdown = WizardInputWrapper<BankAccountsDropdownProps>(BankAccountsDropdown);

const EssentialsSection = (props: Props) => {
  const { values, setValue, handleFocus } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[0]} />
        {getTranslation('property_groups.essentials')}
      </WizardHeader>

      <TwoColumnSection>
        <div>
          <Heading headingType="h3" style="bold">
            {getTranslation('property_groups.name_title')}
          </Heading>
          <InputContainer>
            <WizardInputField
              id="name"
              onChange={(name) => setValue({ name })}
              value={values.name}
              placeholder={getTranslation('general.name')}
              error={!values.name}
              onFocus={handleFocus(STEP_NAMES[0])}
            />
          </InputContainer>
        </div>
        <div>
          <Heading headingType="h3" style="bold">
            {getTranslation('property_groups.type_heading')}
          </Heading>
          <InputContainer>
            <Select
              id="type"
              onChange={(val) => setValue({ type: val[0] })}
              selectedValues={[values.type]}
              values={getEnum<propertyGroupType>('propertyGroupType').map((pgt) => {
                return {
                  icon: pgt.value,
                  value: pgt.value,
                  text: pgt.text
                };
              })}
            />
          </InputContainer>
        </div>
      </TwoColumnSection>

      <Heading headingType="h3" style="bold">
        {getTranslation('property_groups.address')}
      </Heading>
      <InputContainer>
        <WizardInputField
          id="street"
          onChange={(streetName) => setValue({ streetName })}
          value={values.streetName}
          placeholder={getTranslation('location.street_name')}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="streetNr"
          onChange={(streetNumber) => setValue({ streetNumber })}
          value={values.streetNumber}
          placeholder={getTranslation('location.streetnr')}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="streetNrAdd"
          onChange={(streetNumberAddition) => setValue({ streetNumberAddition })}
          value={values.streetNumberAddition}
          placeholder={getTranslation('location.streetnraddition')}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="postalCode"
          onChange={(postalCode) => setValue({ postalCode })}
          value={values.postalCode}
          placeholder={getTranslation('location.postal')}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="city"
          onChange={(city) => setValue({ city })}
          value={values.city}
          placeholder={getTranslation('location.city')}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardCountryPicker
          id="country"
          onChange={(value) => setValue({ country: value[0] })}
          selectedValues={[values.country]}
          placeholder={getTranslation('location.country')}
          onFocus={handleFocus(STEP_NAMES[0])}
          error={!values.country}
          organisationScoped
        />
      </InputContainer>

      <Heading headingType="h3" style="bold">
        {getTranslation('property_groups.bank_account_heading')}
      </Heading>
      <InputContainer>
        <WizardIbanDropdown
          id="org-bankaccount"
          onChange={(val) => setValue({ companyBankAccountId: val[0]?.id || null })}
          selectedValue={values.companyBankAccountId || ''}
        />
      </InputContainer>
    </WizardSection>
  );
};

export default observer(EssentialsSection);
