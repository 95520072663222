import moment from 'moment';
import React from 'react';

import CalendarHeader from '../../Calendar/calendar-header';
import YearContainer from '../../Calendar/year-container';
import { Heading } from '../../Heading';
import TooltipTrigger from '../../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './year-input.module.scss';

type Props = {
  id: string;
  selectedYear: number;
  initYearRange?: number[];
  onSelect: (year: number) => void;
};

function reducer(state: number[], action: number[]) {
  if (Array.isArray(action) && action.length === 2) {
    return action;
  }
  return state;
}

export default function YearInput(props: Props) {
  const { id, onSelect, selectedYear } = props;
  let { initYearRange } = props;

  if (!initYearRange) {
    initYearRange = [moment().subtract(7.5, 'years').year(), moment().add(7.5, 'years').year()];
  }

  const [[startYear, endYear], setYearRange] = React.useReducer(reducer, initYearRange);

  const onNext = () => {
    setYearRange([startYear + 15, endYear + 15]);
  };

  const onPrevious = () => {
    setYearRange([startYear - 15, endYear - 15]);
  };

  const scrollCalendar = (event: React.WheelEvent) => {
    if (event.deltaY > 0) {
      onNext();
    } else if (event.deltaY) {
      onPrevious();
    }
  };

  const selectYear = (year: number) => {
    onSelect(year);
  };

  return (
    <div id={id}>
      <TooltipTrigger
        tooltip={
          <div onWheel={scrollCalendar} style={{ width: '35rem' }}>
            <CalendarHeader onPrevious={onPrevious} onNext={onNext}>
              <Heading headingType="h3" className={css['title']}>{selectedYear}</Heading>
            </CalendarHeader>
            <YearContainer
              onSelect={(year: number) => selectYear(year)}
              selectedYear={selectedYear}
              startYear={startYear}
              endYear={endYear}
            />
          </div>
        }
        placement="bottom-end"
        trigger="click"
      >
        <Heading headingType="h3" className={css['year-input']}>
          {selectedYear}
        </Heading>
      </TooltipTrigger>
    </div>
  );
}
