import moment from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { ImportDataProviderType } from '@zf/api-types/data-provider';
import { importJobStatus } from '@zf/api-types/enums';
import { ImportJobType } from '@zf/api-types/import-job';
import { Card, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ProgressBar } from '@zf/stella-react/src/atoms/ProgressBar';

import { useAppContext } from '../../../../app-context';
import { Icon } from '../../../../components/Icon';
import { SuspenseCardBody } from '../../../../components/suspense';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';

export type PropsType = {
  importJob: ImportJobType;
};

function CardContent(props: PropsType) {
  const { importJob } = props;
  const { i18n, enumReducer } = useAppContext();

  const providersResponse = useSuspenseSingleAPI<PagedResponseType<ImportDataProviderType>>({
    request: {
      endpoint: '/cfg/DataImports',
      query: { showDeleted: true }
    }
  });
  if (!providersResponse.result) return null;

  const dataprovider: ImportDataProviderType | undefined = providersResponse.result.data.results.find(
    (dp) => dp.id === importJob.dataProviderId
  );

  let progress: React.ReactNode;

  let totalBucketsStored = importJob.totalBucketsStored;

  if (importJob.bucketsStored) {
    totalBucketsStored = importJob.bucketsStored.length;
  }

  if (importJob.status === importJobStatus.storing) {
    progress = (
      <ProgressBar
        size="small"
        showLabel={false}
        progress={(totalBucketsStored / importJob.totalBucketsToStore) * 100}
      />
    );
  } else if (importJob.status === importJobStatus.processing) {
    progress = (
      <ProgressBar
        size="small"
        showLabel={false}
        progress={(totalBucketsStored / importJob.totalBucketsToProcess) * 100}
      />
    );
  }

  return (
    <>
      <CardItem width="6">
        <Label>{i18n.getTranslation('import_jobs.communication_type')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">
          {enumReducer.getTranslation('dataImportCommunicationType', importJob.communicationType)}
        </Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('import_jobs.file_name')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{importJob.fileName}</Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('import_jobs.source')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{importJob.source}</Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('import_jobs.data_provider')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" deleted={dataprovider?.disabled || false}>
          {dataprovider?.name || ''}
        </Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('general.status')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{enumReducer.getTranslation('importJobStatus', importJob.status)}</Paragraph>
      </CardItem>
      {progress && (
        <>
          <CardItem width="6">
            <Label>{i18n.getTranslation('import_jobs.progress')}</Label>
          </CardItem>
          <CardItem width="6" justifyContent="end">
            {progress}
          </CardItem>
        </>
      )}

      <CardItem width="6">
        <Label>{i18n.getTranslation('import_jobs.finished')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">
          {moment(importJob.finishDate).year() === 1 ? (
            <Icon type="notfinished" color />
          ) : (
            <Icon type="finished" color />
          )}
        </Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('import_jobs.values_parsed')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{importJob.amountOfValuesParsed}</Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('import_jobs.import_type')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">
          {enumReducer.getTranslation('importCategoryType', importJob.importType)}
        </Paragraph>
      </CardItem>
    </>
  );
}

export default function ImportJobDetailCard(props: PropsType) {
  const { i18n } = useAppContext();

  return (
    <Card id="import-job-details">
      <CardHeader>{i18n.getTranslation('general.details')}</CardHeader>
      <SuspenseCardBody type="grid">
        <CardContent {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
