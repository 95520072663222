import { uiCulture } from '@zf/api-types/enums';
import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';

export async function lockBillingPeriod(
  propertyGroupId: string,
  periodId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<PropertyGroupBillingPeriodType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}/${periodId}/lock`
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function triggerBilling(
  propertyGroupId: string,
  periodId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<PropertyGroupBillingPeriodType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}/${periodId}/triggerbilling`
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteBillingPeriod(
  propertyGroupId: string,
  periodId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}/${periodId}`
      },
      tenantReducer,
      lang
    })
  ).data;
}
