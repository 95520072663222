import { useAppContext } from 'app-context';
import PaymentTermsDropdown from 'components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';
import MoneyInput from 'components/input/MoneyInput';
import DatePicker, { DatePickerProps } from 'components/Lang/DatePicker';
import { Disabled, RadioInput, TabSlider } from 'design-system/Components';
import { Info, SettingDescription } from 'design-system/ComponentSets';
import { Spinner } from 'design-system/Foundation';
import { CheckBox } from 'design-system/Inputs';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useRef, useEffect, useState } from 'react';
import { calcNextInvoiceDate } from 'utils/contract';

import { advanceFrequency, contractBillingMethod, countryCode, invoiceFrequency } from '@zf/api-types/enums';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import css from './billing-form.module.scss';
import { notify } from 'events/notification-events';

const WizardDatePicker = WizardInputWrapper<DatePickerProps>(DatePicker);

const BillingForm = () => {
  const { applicationStore, contractStore, propertyGroupStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation } = applicationStore;
  const { regularMoveInWizardValues, organizationConfig, paymentTerms, locationSectionStore } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { values, setValue } = regularMoveInWizardValues;
  const { selectedLocation, selectedLocations } = locationSectionStore;
  const { getFirstInvoiceEndDateTime } = propertyGroupStore.propertyGroupBillingConfigurationService;

  const { authReducer } = useAppContext();
  const { isAdmin, isSuperUser } = authReducer;
  const showAdminInputs = isAdmin() || isSuperUser();

  const invoiceFrequencyValues = useRef(getEnum<invoiceFrequency>('invoiceFrequency'));
  const advanceFrequencyValues = useRef(getEnum<advanceFrequency>('advanceFrequency'));
  const tabItems = useRef([
    {
      id: contractBillingMethod.credit,
      icon: 'credit-card',
      title: getEnumTranslation('contractBillingMethod', contractBillingMethod.credit)
    },
    {
      id: contractBillingMethod.prepayment,
      icon: 'revert',
      title: getEnumTranslation('contractBillingMethod', contractBillingMethod.prepayment)
    }
  ]);

  const [suggestedFirstInvoiceDate, setSuggestedFirstInvoiceDate] = useState<any>();

  useEffect(() => {
    if (selectedLocation && values.invoiceFrequency && values.supplyStartDate) {
      Promise.all([getFirstInvoiceEndDateTime([selectedLocation.id], values.invoiceFrequency, values.supplyStartDate)])
        .then((res) => moment(res[0].firstInvoiceEndDateTime))
        .then((res) => setSuggestedFirstInvoiceDate(res))
        .catch((error) => {
          notify.error({
            content: getTranslation('contracts.wizard.fail_next_invoice_date_title'),
            error
          });
        });
    } else if (selectedLocations && values.invoiceFrequency && values.supplyStartDate) {
      const selectedLocationsIds = Array.from(selectedLocations.values()).flatMap((l) => l.id);
      Promise.all([getFirstInvoiceEndDateTime(selectedLocationsIds, values.invoiceFrequency, values.supplyStartDate)])
        .then((res) => moment(res[0].firstInvoiceEndDateTime))
        .then((res) => setSuggestedFirstInvoiceDate(res))
        .catch((error) => {
          notify.error({
            content: getTranslation('contracts.wizard.fail_next_invoice_date_title'),
            error
          });
        });
    }
  }, [selectedLocation, selectedLocations, values.invoiceFrequency]);

  if (!paymentTerms || !organizationConfig) return <Spinner centered />;

  return (
    <>
      {organizationConfig?.address?.country === countryCode.gbr && (
        <TabSlider
          id="billing-method"
          tabItems={tabItems.current}
          selectedTabItem={values.billingMethod}
          // @ts-ignore
          setSelectedItem={(billingMethod) => setValue({ billingMethod })}
          fullWidth
        />
      )}

      {values.billingMethod === contractBillingMethod.credit && (
        <>
          {!values.hasCustomProductConfig && values.usePropertyGroupProduct && (
            <Info>{getTranslation('contracts.wizard.invoice_settings_disabled_info')}</Info>
          )}
          <>
            <Disabled disabled={!values.hasCustomProductConfig && values.usePropertyGroupProduct}>
              <SettingDescription
                title={getTranslation('contracts.wizard.invoice_frequency_title')}
                description={getTranslation('contracts.wizard.invoice_frequency_descr')}
                setting={
                  <FlexElements gap="24">
                    {invoiceFrequencyValues.current.map((freq) => (
                      <RadioInput
                        key={freq.value}
                        onChange={(option) => {
                          const castedFrequency = option as invoiceFrequency;

                          setValue({
                            invoiceFrequency: castedFrequency,
                            // Ignore the suggestions as an onChange here means overwriting the suggestions
                            nextInvoiceDate: calcNextInvoiceDate(values.supplyStartDate, null, null, castedFrequency)
                          });
                        }}
                        value={freq.value}
                        name={`invoice-freq-${freq.text}`}
                        checked={values.invoiceFrequency === freq.value}
                      >
                        {freq.text}
                      </RadioInput>
                    ))}
                  </FlexElements>
                }
                isMandatory
              />
            </Disabled>

            <div className={css['invoice-specs']}>
              {showAdminInputs && typeof values.firstInvoiceStartDateTime !== 'undefined' && (
                <Disabled disabled={!values.hasCustomProductConfig && values.usePropertyGroupProduct}>
                  <SettingDescription
                    title={getTranslation('contracts.wizard.first_invoice_start_date_title')}
                    description={getTranslation('contracts.wizard.first_invoice_start_date_descr')}
                    setting={
                      <DatePicker
                        id="contracts.first-invoice-start-date"
                        onChange={(val) => setValue({ firstInvoiceStartDateTime: val.toISOString() })}
                        value={moment(values.firstInvoiceStartDateTime)}
                        placeholder={getTranslation('contracts.wizard.first_invoice_start_date_title')}
                      />
                    }
                  />
                </Disabled>
              )}

              <Disabled disabled={!values.hasCustomProductConfig && values.usePropertyGroupProduct}>
                <SettingDescription
                  title={getTranslation('contracts.wizard.next_invoice_date_title')}
                  description={getTranslation('contracts.wizard.next_invoice_date_descr')}
                  setting={
                    <WizardDatePicker
                      id="contracts.nextInvoiceDate"
                      value={suggestedFirstInvoiceDate ? suggestedFirstInvoiceDate : values.nextInvoiceDate || null}
                      placeholder={getTranslation('contracts.wizard.next_invoice_date_title')}
                      onChange={(nextInvoiceDate) => setValue({ nextInvoiceDate })}
                    />
                  }
                />
              </Disabled>
            </div>
          </>

          {!values.invoiceUpfront && (
            <>
              <SettingDescription
                title={getTranslation('contracts.wizard.advance_frequency_title')}
                description={getTranslation('contracts.wizard.advance_frequency_descr')}
                setting={
                  <FlexElements gap="24">
                    {advanceFrequencyValues.current.map((freq) => (
                      <RadioInput
                        key={freq.value}
                        onChange={(option) => {
                          const castedOption = option as advanceFrequency;
                          if (castedOption === advanceFrequency.none) {
                            setValue({ advanceFrequency: castedOption, advanceAmount: 0 });
                          } else {
                            setValue({ advanceFrequency: castedOption });
                          }
                        }}
                        value={freq.value}
                        name={`advance-freq-${freq.text}`}
                        checked={values.advanceFrequency === freq.value}
                      >
                        {freq.text}
                      </RadioInput>
                    ))}
                  </FlexElements>
                }
                isMandatory
              />

              <Disabled disabled={!values.advanceFrequency || values.advanceFrequency === advanceFrequency.none}>
                <SettingDescription
                  title={getTranslation('contracts.advance_amount')}
                  description={getTranslation('contracts.advance_amount_descr')}
                  setting={
                    <MoneyInput
                      id="contracts.advanceAmount"
                      onChange={(advanceAmount) => setValue({ advanceAmount })}
                      value={typeof values.advanceAmount !== 'undefined' ? values.advanceAmount : null}
                      placeholder={getTranslation('contracts.advance_amount')}
                      error={
                        values.advanceFrequency !== null &&
                        values.advanceFrequency !== advanceFrequency.none &&
                        (typeof values.advanceAmount === 'undefined' || isNaN(values.advanceAmount))
                      }
                    />
                  }
                  indentLvl={1}
                  isMandatory={values.advanceFrequency !== advanceFrequency.none}
                />
              </Disabled>

              {typeof values.expectAdvancesToBeImportedPeriodically !== 'undefined' && showAdminInputs && (
                <CheckBox
                  id="contracts.expect_advances_imported_periodically"
                  className={css['dont-generate-adv-checkbox']}
                  title={getTranslation('contracts.wizard.expect_advances_imported_periodically_title')}
                  description={getTranslation('contracts.wizard.expect_advances_imported_periodically_descr')}
                  checked={values.expectAdvancesToBeImportedPeriodically}
                  onChange={(val) => setValue({ expectAdvancesToBeImportedPeriodically: val })}
                />
              )}
            </>
          )}

          <HorizontalDivider />

          {(values.hasCustomProductConfig || !values.usePropertyGroupProduct) && (
            <SettingDescription
              title={getTranslation('contracts.wizard.payment_term_title')}
              description={getTranslation('contracts.wizard.payment_term_descr')}
              setting={
                <PaymentTermsDropdown
                  id="contracts.paymentTermsId"
                  onChange={(value) => setValue({ paymentTermsId: value[0]?.id || '' })}
                  selectedValue={values.paymentTermsId}
                />
              }
              isMandatory
            />
          )}
        </>
      )}
    </>
  );
};

export default observer(BillingForm);
