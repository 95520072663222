import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { Wizard, WizardNav } from '@zf/stella-react/src/atoms/Wizard';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import WizardContainer from '../../../components/Wizard/WizardContainer';
import PropertyWizard, { STEP_NAMES } from './property-group-wizard';

const AddPropertyGroup = () => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  useBrowserTitle(getTranslation('property_groups.add_group'));

  const [focusedStep, setFocusedStep] = React.useState(STEP_NAMES[0]);
  const [feedback, setFeedback] = React.useState<string[][]>([]);

  return (
    <WizardContainer
      icon="propertygroup-add"
      text={getTranslation('wizard.breadcrumb', {
        subject: getEnumTranslation('entitySubjectType', entitySubjectType.propertygroup)
      })}
    >
      <Wizard>
        <PropertyWizard feedback={feedback} setFeedback={setFeedback} onFocusStep={setFocusedStep} />
      </Wizard>
      <WizardNav
        steps={[
          {
            header: getTranslation('property_groups.essentials'),
            name: STEP_NAMES[0],
            feedback: feedback[0]
          },
          {
            header: getTranslation('property_groups.contract_defaults'),
            name: STEP_NAMES[1],
            feedback: feedback[1]
          },
          {
            header: getTranslation('property_groups.relations'),
            name: STEP_NAMES[2],
            feedback: feedback[2]
          },
          {
            header: getTranslation('property_groups.locations'),
            name: STEP_NAMES[3],
            feedback: feedback[3]
          },
          {
            header: getTranslation('errors.more_problems'),
            name: STEP_NAMES[4],
            feedback: feedback[4],
            error: true
          }
        ]}
        activeStep={focusedStep}
        onNavigate={setFocusedStep}
      />
    </WizardContainer>
  );
};

export default observer(AddPropertyGroup);
