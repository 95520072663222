import React from 'react';

import { CustomTransactionRowType, IncomingMutationType } from '@zf/api-types/billing/incoming-mutations';
import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { OutgoingMutationType } from '@zf/api-types/billing/outgoing-mutations';
import { incomingMutationType, outgoingBankingTransactionType } from '@zf/api-types/enums';
import { TransactionType } from '@zf/api-types/transactions';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';
import { ICON_COLORS } from '../../constants/icons';
import { ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { getMainDetail, getOpenAmountColor } from '../../utils/transaction';
import { MatchDialogState } from './ManualMatchDialog';
import css from './ManualMatchDialog.module.scss';
import MultiSelectMatchTable from './MultiSelectMatchTable';
import SingleSelectMatchTable from './SingleSelectMatchTable';

type Props = {
  incomingMutation: IncomingMutationType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  dispatch: React.Dispatch<Partial<MatchDialogState>>;
  state: MatchDialogState;
};

const ManualMatchingContent = (props: Props) => {
  const { incomingMutation, validationRef, state, dispatch } = props;

  const [typeOutgoing] = React.useState(
    incomingMutation.type === incomingMutationType.paymentrequestreversal ||
      incomingMutation.type === incomingMutationType.paymentrequesttransactionconfirmation ||
      incomingMutation.type === incomingMutationType.paymentrequestmutationconfirmation
      ? outgoingBankingTransactionType.paymentrequests
      : outgoingBankingTransactionType.paymentrefunds
  );

  const validate = () => {
    if (validationRef.current) {
      if (!state.isBooked) {
        validationRef.current.setIsError(true);
      } else {
        validationRef.current.setIsError(false);
      }
    }
  };

  React.useEffect(() => {
    validate();
  }, [state.isBooked]);

  const { i18n, enumReducer } = useAppContext();

  const columnsTransactions = [
    {
      width: 30,
      dataKey: 'awaitingBankConfirmation'
    },
    {
      width: 150,
      label: i18n.getTranslation('invoice.invoice_date'),
      dataKey: 'transactionDateTime',
      noClick: true
    },
    {
      width: 120,
      label: i18n.getTranslation('invoice.due_date'),
      dataKey: 'dueDate'
    },
    {
      width: 120,
      label: i18n.getTranslation('incoming_mutations.incoming_matching_type'),
      dataKey: 'type'
    },
    {
      width: 140,
      label: i18n.getTranslation('general.detail'),
      dataKey: 'detail'
    },
    {
      width: 120,
      label: i18n.getTranslation('general.amount_valuta'),
      dataKey: 'openAmount'
    }
  ];

  const processRecordTransactions = (transaction: TransactionType): CustomTransactionRowType => {
    return {
      __id: transaction.id,
      __entity: transaction,
      amount: transaction.transactionAmount.toString(),
      openAmount: (
        <Paragraph color={getOpenAmountColor(transaction)}>
          {formatMoney(Math.abs(transaction.transactionOpenAmount), i18n.culture)}
        </Paragraph>
      ),
      transactionDateTime: formatDate(transaction.transactionDateTime),
      dueDate: <Paragraph>{formatDate(transaction.transactionDueDateTime)}</Paragraph>,
      reversed: transaction.reversed ? <Icon type="cancel" /> : '',
      type: transaction.transactionType
        ? enumReducer.getTranslation('transactionType', transaction.transactionType)
        : '',
      detail: getMainDetail(transaction.referenceDetails),
      awaitingBankConfirmation: transaction.awaitingBankConfirmation && (
        <div
          data-tip={createTooltipContent(
            <>{i18n.getTranslation('incoming_mutations.incoming_matching_bankConfirmation')}</>
          )}
          data-for="matching-table-transactions"
        >
          <Icon type="exclamation" color />
        </div>
      )
    };
  };

  const renderEmptyState = (emptyState: string) => {
    return (
      <CardEmptyBody
        image={
          <img
            src="https://cdn.zerofriction.co/shared/assets/emptyStates/transaction.png"
            alt="transaction"
            title="transaction"
            width="70"
            height="70"
          />
        }
        title={emptyState}
      ></CardEmptyBody>
    );
  };

  const renderEmptyStateNoCustomerFound = (emptyState: string, emptyStateDesc: string) => {
    return (
      <CardEmptyBody
        image={
          <img
            src="https://cdn.zerofriction.co/shared/assets/emptyStates/transaction.png"
            alt="transaction"
            title="transaction"
            width="70"
            height="70"
          />
        }
        title={emptyState}
      >
        {emptyStateDesc}
      </CardEmptyBody>
    );
  };

  switch (incomingMutation.type) {
    // Bank transfers
    case incomingMutationType.incomingtransfer:
    case incomingMutationType.outgoingtransfer: {
      return (
        <>
          {state.customerId ? (
            <InputContainer className={css['selection-incomingBankingTransaction']} grid={false}>
              <Label>{i18n.getTranslation('incoming_mutations.incoming_matching_selectTransaction')}:</Label>
              <MultiSelectMatchTable
                dispatch={dispatch}
                processRecord={processRecordTransactions}
                columns={columnsTransactions}
                quickFilter={incomingMutation.type === incomingMutationType.outgoingtransfer ? 'torefund' : 'topay'}
                requestEndpoint={`/bill/Transactions/c/${state.customerId}`}
                incomingMutation={incomingMutation}
                state={state}
                emptyState={() =>
                  renderEmptyState(i18n.getTranslation('incoming_mutations.incoming_matching_noTransactions'))
                }
              />
            </InputContainer>
          ) : (
            renderEmptyStateNoCustomerFound(
              i18n.getTranslation('incoming_mutations.incoming_matching_noTransactions'),
              i18n.getTranslation('incoming_mutations.incoming_matching_noTransactions_desc')
            )
          )}
        </>
      );
    }

    // Bank confirmations
    case incomingMutationType.refundtransactionconfirmation:
    case incomingMutationType.paymentrequesttransactionconfirmation: {
      const processRecord = (transaction: OutgoingBankingTransactionType) => {
        return {
          __id: transaction.id,
          __entity: transaction,
          __etag: transaction._etag,
          amount: formatMoney(transaction.totalAmount, i18n.culture),
          date: formatDate(transaction.mutationDateTime),
          identification: transaction.identification,
          type: enumReducer.getTranslation('outgoingBankingTransactionType', transaction.type),
          status: (
            <div className={css['status']}>
              <StatusBadge color={ICON_COLORS[transaction.status]} type="bare">
                {enumReducer.getTranslation('outgoingBankingTransactionStatus', transaction.status)}
              </StatusBadge>
            </div>
          )
        };
      };

      const type = i18n.getTranslation(`incoming_mutations.${typeOutgoing}`).toLowerCase();

      return (
        <>
          <InputContainer className={css['selection-incomingBankingTransaction']} grid={false}>
            <Label>{`${i18n.getTranslation('incoming_mutations.incoming_matching_select')} ${type}`}:</Label>
            <SingleSelectMatchTable
              dispatch={dispatch}
              processRecord={processRecord}
              columns={[
                {
                  width: 180,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_id'),
                  dataKey: 'identification'
                },
                {
                  width: 180,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_mutationDate'),
                  dataKey: 'date'
                },
                {
                  width: 140,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_totalAmount'),
                  dataKey: 'amount'
                },
                {
                  width: 180,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_status'),
                  dataKey: 'status'
                }
              ]}
              singleSelect
              outgoingBankingTransactionType={typeOutgoing}
              quickFilter="toconfirm"
              requestEndpoint="/bill/OutgoingBankingTransactions/"
              incomingMutation={incomingMutation}
              state={state}
              emptyState={() => {
                const type_ = i18n.getTranslation(`incoming_mutations.${typeOutgoing}`).toLowerCase();
                return renderEmptyState(`${i18n.getTranslation('general.no')} ${type_}`);
              }}
            />
          </InputContainer>
        </>
      );
    }

    // Reversals & Mutation confirmations
    case incomingMutationType.refundreversal:
    case incomingMutationType.paymentrequestreversal:
    case incomingMutationType.paymentrequestmutationconfirmation:
    case incomingMutationType.refundmutationconfirmation: {
      const processRecordOutgoingMutation = (mutation: OutgoingMutationType) => {
        return {
          __id: mutation.id,
          __entity: mutation,
          __etag: mutation._etag,
          creationDate: formatDate(mutation.createdDateTime),
          iban: mutation.customerBankAccount.iban,
          amount: formatMoney(mutation.amount, i18n.culture),
          type: enumReducer.getTranslation('outgoingMutationType', mutation.referenceDetails.referenceType),
          status: (
            <div className={css['status']}>
              <StatusBadge color={ICON_COLORS[mutation.status]} type="bare">
                {enumReducer.getTranslation('outgoingBankingTransactionStatus', mutation.status)}
              </StatusBadge>
            </div>
          )
        };
      };

      return (
        <>
          <InputContainer className={css['selection-incomingBankingTransaction']} grid={false}>
            <Label>
              {`${i18n.getTranslation('incoming_mutations.incoming_matching_select_an')} ${i18n.getTranslation(
                'outgoing_mutations.outgoing_mutation'
              )}`}
            </Label>
            <SingleSelectMatchTable
              dispatch={dispatch}
              processRecord={processRecordOutgoingMutation}
              columns={[
                {
                  width: 180,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_type'),
                  dataKey: 'type'
                },
                {
                  width: 180,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_iban'),
                  dataKey: 'iban'
                },
                {
                  width: 150,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_amount'),
                  dataKey: 'amount'
                },
                {
                  width: 180,
                  label: i18n.getTranslation('incoming_mutations.incoming_matching_status'),
                  dataKey: 'status'
                }
              ]}
              singleSelect
              requestEndpoint={`/bill/OutgoingMutations/search/${typeOutgoing}`}
              incomingMutation={incomingMutation}
              state={state}
              emptyState={() =>
                renderEmptyState(
                  `${i18n.getTranslation('general.no')} ${i18n.getTranslation('outgoing_mutations.outgoing_mutations')}`
                )
              }
            />
          </InputContainer>
        </>
      );
    }
  }

  return null;
};

export default ManualMatchingContent;
