import React from 'react';

import { invoiceStatus, paymentProcessStatus } from '@zf/api-types/enums';
import { InvoiceRowType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import AssignToTransactionDialog from '../dialogs/assign-to-transaction-dialog';

type Props = {
  refresh: () => void;
};

export default function AddSelectedToBankingTransactionListPageButton(props: Props) {
  const { refresh } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { clickRef, validationRef, onSubmit } = useDialog();

  let disabled = true;

  if (selectedRows) {
    disabled = !selectedRows.some((r) => {
      return (
        (r.__entity.status === invoiceStatus.approved ||
          r.__entity.status === invoiceStatus.generated ||
          r.__entity.status === invoiceStatus.settled) &&
        (r.__entity.paymentDetails.currentPaymentStatus === paymentProcessStatus.readyforpaymentcollection ||
          r.__entity.paymentDetails.currentPaymentStatus === paymentProcessStatus.waitingforpayment ||
          r.__entity.paymentDetails.currentPaymentStatus === paymentProcessStatus.paymentrejected)
      );
    });
  }

  return (
    <DropdownAction
      id="outgoing_banking_transaction.assign_invoice"
      icon="assign"
      dialogTitle={i18n.getTranslation(
        `actions.invoice.${selectedRows.length > 1 ? 'add_all_ready' : 'add_to_transaction'}`
      )}
      dialogContent={
        <AssignToTransactionDialog
          ref={clickRef}
          validationRef={validationRef}
          selectedRows={selectedRows}
          refresh={refresh}
        />
      }
      validationRef={validationRef}
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('general.add')}
    />
  );
}
