import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../app-context';
import { UnbilledState } from '../../../cards/Unbilled/context/unbilled-context';
import CreateInvoiceDialog from '../../../cards/Unbilled/dialogs/CreateInvoiceDialog';
import RecalculateDialog from '../../../cards/Unbilled/dialogs/RecalculateDialog';
import { RowTypeDetails } from '../../../cards/Unbilled/hooks/useUnbilled';
import { RowTypeUnbilled } from '../../../cards/Unbilled/hooks/useUnbilledActions';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  completenesses: BillingCompletenessType[];
  setState: React.Dispatch<Partial<UnbilledState>>;
  updateGivenRows: (
    updatedRecords: BillingCompletenessType[],
    deletedRecords?: (RowTypeDetails | RowTypeUnbilled)[]
  ) => Promise<RowTypeUnbilled[]>;
};

export default function RecalculateCreateListItem(props: Props) {
  const { completenesses, setState, updateGivenRows } = props;
  const { i18n } = useAppContext();

  const { clickRef, onSubmit } = useDialog();

  let closedCount = 0;
  let openCount = 0;

  completenesses.forEach((c) => {
    c.status === billingCompletenessStatus.closed ? closedCount++ : openCount++;
  });

  const showRecalculate = closedCount >= openCount;

  return (
    <DropdownAction
      id="invoice.create_recalculate"
      icon="invoice"
      dialogTitle={
        showRecalculate ? i18n.getTranslation('general.recalculate') : i18n.getTranslation('invoice.generate_invoice')
      }
      dialogContent={
        showRecalculate ? (
          <RecalculateDialog
            ref={clickRef}
            completenesses={completenesses}
            updateGivenRows={updateGivenRows}
            setState={setState}
          />
        ) : (
          <CreateInvoiceDialog
            ref={clickRef}
            completenesses={completenesses}
            updateGivenRows={updateGivenRows}
            setState={setState}
          />
        )
      }
      disabled={completenesses.length === 0}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation(`invoice.${showRecalculate ? 'recalculate' : 'create'}`)}
    />
  );
}
