import clone from 'clone';
import React from 'react';

import { deepEqual } from '@zf/utils/src/object';

import { createStateReducer } from './stateReducer';

export type UseValidatorReturnType<V> = {
  values: V;
  backup: V;
  errors: ValidatorErrorType;
  isLocked: boolean;
  isDirty: boolean;
  backupValues: () => void;
  restoreValues: () => void;
  setValue: (value: Partial<V>, initialApiSet?: boolean | undefined, backup_?: boolean | undefined) => void;
  submitFactory: (callback: Function) => () => Promise<void>;
  dispatchFlag: React.Dispatch<Partial<Flags>>;
};

export type FeedBackType = Record<number, string[]>;

export type ValidatorErrorType = {
  feedback: FeedBackType[];
};

type Flags = {
  isDirty: boolean;
  isLocked: boolean;
};

type DispatchFlags = Partial<Flags>;

export type Params<V> = {
  initialValues: V;
  locked?: boolean;
  refreshTrigger?: any; // Unique value that triggers a state reset on detail pages
  validate?: (values: V) => any;
};

export default function useValidator<V>(params: Params<V>): UseValidatorReturnType<V> {
  const { initialValues, refreshTrigger, validate, locked = false } = params;

  type DispatchType = Partial<V>;

  const [backup, setBackup] = React.useState<V>(clone(initialValues));

  const flagsReducer = createStateReducer<Flags, DispatchFlags>();
  const [{ isDirty, isLocked }, dispatchFlag] = React.useReducer(flagsReducer, {
    isDirty: false,
    isLocked: locked
  });

  const valuesReducer = createStateReducer<V, DispatchType>();
  const [values, updateValues] = React.useReducer(valuesReducer, initialValues);
  const [errors, setErrors] = React.useState<ValidatorErrorType>({ feedback: [] as FeedBackType[] });

  const setValue = (value: DispatchType, initialApiSet?: boolean, backup_?: boolean) => {
    // Block mutations when submitting
    if (isLocked) return;

    if (!initialApiSet && !deepEqual({ ...values, ...value }, backup)) {
      dispatchFlag({ isDirty: true });
    }
    updateValues(value);

    if (backup_) {
      setBackup({ ...clone(values), ...clone(value) });
      dispatchFlag({ isDirty: false });
    }
  };

  React.useEffect(() => {
    // This fixes the sticky values when using navigator in detail pages
    if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
      setValue(clone(initialValues), false, true);
    }
  }, [JSON.stringify(initialValues), refreshTrigger]);

  React.useEffect(() => {
    if (validate) {
      setErrors(validate(values));
    }
  }, [JSON.stringify(values)]);

  const backupValues = () => {
    setBackup(clone(values));
    dispatchFlag({ isDirty: false });
  };

  const restoreValues = () => {
    updateValues(clone(backup));
    dispatchFlag({ isDirty: false });
  };

  const submitFactory = (callback: Function) => {
    return async () => {
      if (isLocked || !isDirty) return;

      dispatchFlag({ isLocked: true });

      await callback({ values, errors });

      dispatchFlag({ isLocked: false });
    };
  };

  return {
    values,
    backup,
    errors,
    isLocked,
    isDirty,
    backupValues,
    restoreValues,
    setValue,
    submitFactory,
    dispatchFlag
  };
}
