import React from 'react';

import YearInput from '@zf/stella-react/src/atoms/Date/DateInput/year-input';

import RemoveIconButton from '../../Icon/RemoveIconButton';
import css from './compare-picker.module.scss';

type Props = {
  id: string;
  selectedYear: number;
  setCompareYears: (year: number) => void;
  removeComparisonYear: () => void;
};

export default function CompareYearPicker(props: Props) {
  const { id, selectedYear, setCompareYears, removeComparisonYear } = props;

  return (
    <div>
      <YearInput id={id} selectedYear={selectedYear} onSelect={setCompareYears} />
      <RemoveIconButton id={`${id}-delete`} className={css['remove-icon']} onClick={removeComparisonYear} />
    </div>
  );
}
