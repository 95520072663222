import { ContractReferenceType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';
import { colors } from '@zf/utils/src/color';
import React from 'react';
import { BaseButton, Link } from '../../../../design-system/Components';
import { Icon } from '../../../../design-system/Foundation';
import Paragraph from '../../../../design-system/Foundation/Paragraph/Paragraph';
import { dialog } from '../../../../events/dialog-events';
import { useStore } from '../../../../hooks/useStore';
import MultipleOriginDialog from './dialogs/MultipleOriginDialog';
import css from './files-table.module.scss';

export type Props = {
  originurl: string;
  contractUrl: string;
  origin: string;
  originDisplayName: string;
  contractDisplayName: string;
  contracts: ContractReferenceType[];
};

const OriginColumn = (props: Props) => {
  const { applicationStore } = useStore();
  const { onSubmit, validationRef, clickRef } = useDialog();
  const { getTranslation } = applicationStore;
  const { origin, originDisplayName, contractDisplayName, contractUrl, originurl, contracts } = props;

  const openMultipleOriginDialog = () => {
    dialog.normal({
      title: getTranslation('entity_attachment.contracts_with_file'),
      icon: 'info-circle',
      content: <MultipleOriginDialog contracts={contracts} ref={clickRef} validationRef={validationRef} />,
      ref: validationRef,
      onSubmit,
      isDismissDialog: true
    });
  };
  return (
    <div className={css['origin-wrapper']}>
      <Paragraph>{origin}</Paragraph>
      {originDisplayName && contractDisplayName && (
        <div className={css['origin-column-extra-info']}>
          <Link id={originurl} className={css['origin-column-extra-info']} url={originurl}>
            {originDisplayName}
          </Link>
          <Paragraph color={colors['silver-600']}> &gt;</Paragraph>
          {contractDisplayName === getTranslation('entity_attachment.multiple') ? (
            <BaseButton
              id="origin.multiple"
              className={css['origin-column-extra-info']}
              onClick={openMultipleOriginDialog}
              type="text"
            >
              <Icon className={css['origin-multiple-button-icon']} name="contract" />
              {getTranslation('entity_attachment.multiple')}
            </BaseButton>
          ) : (
            <Link id={contractUrl} className={css['origin-column-extra-info']} icon="contract" url={contractUrl}>
              {contractDisplayName}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default OriginColumn;
