import classNames from 'classnames';
import React from 'react';

import { FileUploadType } from '@zf/api-types/general';
import { Icon } from '@zf/stella-react/src/atoms/Icon';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

import { BaseButton } from '../../design-system/Components';
import css from './file.module.scss';
import { MappedFilesType } from './UploadInput';

export type FileState = 'loading' | 'error' | 'success' | 'none';

type Props = {
  children: React.ReactNode;
  file: MappedFilesType | FileUploadType;
  canDelete?: boolean;
  download: () => void;
  onCancel?: () => void;
};

export default function File(props: Props) {
  const { children, file, download, onCancel, canDelete = true } = props;

  let icon = null;

  const state = file.state || '';

  switch (state) {
    case 'loading':
      icon = <Spinner size="xsmall" />;
      break;
    case 'error':
      icon = <Icon name="cancel" />;
      break;
    case 'success':
      icon = <Icon name="check" />;
      break;
    case 'none':
      icon = <Icon name="cancel" />;
      break;
    default:
      icon = <Icon name="cancel" />;
      break;
  }

  return (
    <div className={css['file']}>
      <div className={css['file-icon']}>
        <Icon name="attachment" />
      </div>
      <BaseButton id="download" onClick={download} className={css['file-name']}>
        {children}
      </BaseButton>
      {icon && onCancel && state === 'none' && canDelete && (
        <BaseButton id="cancel" onClick={onCancel} className={classNames(css['file-validation-icon'], css['none'])}>
          {icon}
        </BaseButton>
      )}
      {icon && state !== 'none' && (
        <div
          className={classNames(css['file-validation-icon'], {
            [css['is-valid']]: state === 'success',
            [css['is-invalid']]: state === 'error'
          })}
        >
          {icon}
        </div>
      )}
    </div>
  );
}
