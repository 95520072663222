import React from 'react';

import { contactType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';

import css from './communication-section.module.scss';
import ContactDetail from './ContactDetail';

type Props = {
  index: number;
  value: {
    contactDetails: ContactEntryType[];
  };
  dispatchValue: (
    value: Partial<{
      contactDetails: ContactEntryType[];
    }>
  ) => void;
  onFocus?: () => void;
};

export default function ContactDetailsMultiValueItem(props: Props) {
  const { index, value, dispatchValue, onFocus } = props;

  const setContactDetailValue = (cType: contactType | 'description', val: Partial<ContactEntryType>) => {
    if (cType === 'description') {
      const updated = value.contactDetails.map((cd) => {
        return { ...cd, description: val.value };
      });

      dispatchValue({ contactDetails: updated });
    } else {
      const indexToUpdate = value.contactDetails.findIndex((pcd) => pcd.contactType === cType);

      if (indexToUpdate !== -1) {
        const cloned = [...value.contactDetails];
        cloned[indexToUpdate] = { ...cloned[indexToUpdate], ...val };
        dispatchValue({ contactDetails: cloned });
      }
    }
  };

  return (
    <div className={css['multivalue-item']}>
      <ContactDetail
        id={`secondary-detail-description-${index}`}
        key={`secondary-detail-description-${index}`}
        contactType_="description"
        value={
          value.contactDetails[0] && value.contactDetails[0].description ? value.contactDetails[0].description : ''
        }
        setContactDetailValue={setContactDetailValue}
        onFocus={onFocus}
      />

      {value.contactDetails.map((cd, index2) => {
        return (
          <ContactDetail
            id={`secondary-detail-${cd.contactType}-${index}-${index2}`}
            key={`secondary-detail-${cd.contactType}-${index}-${index2}`}
            contactType_={cd.contactType}
            value={cd.value}
            descriptionValue={cd.description}
            setContactDetailValue={setContactDetailValue}
            onFocus={onFocus}
          />
        );
      })}
    </div>
  );
}
