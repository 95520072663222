import React from 'react';

import { FileUploadType } from '@zf/api-types/general';

import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';
import Dropzone from '../Lang/Dropzone';
import { FileState } from './File';
import FileNamePlaceHolder from './FileNamePlaceHolder';

export type FileUploadItem =
  | {
      file: File;
      state: FileState;
    }
  | undefined;

type Props = {
  file: FileUploadType | undefined;
  extensions?: string[];
  mayReupload?: boolean;
  iconType?: string;
  className?: string;
  heading?: string;
  error?: boolean;
  disabled?: boolean;
  hideDropzone?: boolean;
  setFile: (file?: FileUploadType) => void;
};

export default function SingleUploadInput(props: Props) {
  const {
    file,
    iconType,
    heading,
    className,
    extensions,
    mayReupload = true,
    error = false,
    disabled = false,
    hideDropzone = false,
    setFile
  } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const extensionIsValid = (fileName: string) => {
    if (extensions) {
      const parts = fileName.split('.');
      return extensions.includes(parts[parts.length - 1]);
    }
    return true;
  };

  const handleSelectFile = (files: File[]) => {
    if (extensionIsValid(files[0].name)) {
      setFile({
        file: files[0],
        internalFilePath: '',
        fileName: files[0].name,
        internalFileName: files[0].name,
        state: 'none'
      });
    } else {
      notify.warning({
        content: getTranslation('dropzone.unsupported_filetype')
      });
    }
  };

  const handleDropzoneTrigger = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';

    if (extensions) {
      let acceptString = '';
      extensions.forEach((e) => {
        acceptString += `.${e},`;
      });
      inputElement.accept = acceptString;
    }

    inputElement.addEventListener('change', function () {
      if (!this.files) return;
      handleSelectFile([...this.files]);
    });

    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <div className={className}>
      {file && (file.fileName || file.internalFileName) && (
        <FileNamePlaceHolder file={file} setFile={setFile} disabled={disabled} />
      )}

      {
        // @ts-ignore - 'undefined' is replaced by 'value' in mobx-react-form?
        (mayReupload || !file || file === 'value') && !disabled && !hideDropzone && (
          <div>
            <Dropzone
              id="dropzone"
              iconType={iconType}
              onTrigger={handleDropzoneTrigger}
              onDrop={handleSelectFile}
              heading={heading || getTranslation('dropzone.heading_single')}
              error={error}
            />
          </div>
        )
      }
    </div>
  );
}
