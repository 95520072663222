import clone from 'clone';
import React from 'react';

import { UpdateModelType } from '@zf/api-types/model';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import CommitSection from '../../../components/config/commit-section';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import ChannelTemplates from './channel-templates';

type Props = {
  config: UseConfigReturnType<UpdateModelType[]>;
  useTracked: () => [ValidatorCtxState<UpdateModelType[]>, React.Dispatch<ValidatorCtxAction<UpdateModelType[]>>];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

const createApiFriendlyValues = (model: UpdateModelType) => {
  return {
    name: model.name,
    description: model.description,
    brandName: model.brandName,
    channelTemplates: model.channelTemplates
  };
};

export default function ModelsContent(props: Props) {
  const { config } = props;
  const { sort } = config;
  const { i18n } = useAppContext();

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.model'),
      dataKey: 'model'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.brand'),
      dataKey: 'brand'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('general.description'),
      dataKey: 'description'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const { values, isDirty, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator(props.useTracked);

  const { addEntity, deleteEntity, handleSave } = useContextCRUD(props.useTracked);

  const addModel = () => {
    addEntity({ name: '', description: '', brandName: '', channelTemplates: [] });
  };

  const handleModelsSave = async () => {
    return handleSave(
      createApiFriendlyValues,
      'name',
      '/cfg/models',
      i18n.getTranslation('meter_config.success_update'),
      i18n.getTranslation('meter_config.error_update')
    );
  };

  const setModel = (index: number, value: UpdateModelType[keyof UpdateModelType], dataKey: keyof UpdateModelType) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdateModelType[]
    });
  };

  const modelRows = values.map((model, index) => {
    try {
      const duplicateIndex = values.findIndex(
        (m, searchIndex) =>
          m.name.toLowerCase() === model.name.toLowerCase() &&
          m.brandName.toLowerCase() === model.brandName.toLowerCase() &&
          index !== searchIndex
      );
      const isDuplicate = duplicateIndex !== -1;

      return {
        model: (
          <InlineInputFieldInput
            id={`model.model.index-${index}`}
            value={model.name}
            onChange={(val) => setModel(index, val, 'name')}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={!model.name}
            error={!model.name || isDuplicate}
            singleError={isDuplicate ? i18n.getTranslation('meter_config.is_duplicate') : ''}
          />
        ),
        brand: (
          <InlineInputFieldInput
            id={`model.brand.index-${index}`}
            value={model.brandName}
            onChange={(val) => setModel(index, val, 'brandName')}
            error={!model.brandName || isDuplicate}
            singleError={isDuplicate ? i18n.getTranslation('meter_config.is_duplicate') : ''}
          />
        ),
        description: (
          <InlineInputFieldInput
            id={`model.description.index-${index}`}
            value={model.description}
            onChange={(val) => setModel(index, val, 'description')}
          />
        ),
        deleteAction: (
          <DeleteIcon
            id={`model.delete.index-${index}`}
            tooltipFor="models-table"
            onClick={() => deleteEntity(index)}
          />
        )
      };
    } catch (e) {
      return {};
    }
  });

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleModelsSave} isDirty={isDirty} />
      <CardsContainer>
        <Card width="3" id="models-card">
          <CardHeader
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.models.title')}
                content={[i18n.getTranslation('coachmark.models.paragraph')]}
              />
            }
            extraRight={
              <Button id="model.add" type="text" icon="plus" onClick={addModel}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('meter_config.models')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="models-table"
              rows={modelRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sortableFields={config.currentConfig?.sortableFields}
              sort={sort}
            />
          </CardBody>
        </Card>

        <ChannelTemplates values={values} selectedIndex={selectedIndex} setValue={setValue} />
      </CardsContainer>
    </>
  );
}
