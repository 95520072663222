import React from 'react';

import { ExternalChannelType, MeasurementRowType, MeterType } from '@zf/api-types/master-data/meter';

import { useAppContext } from '../../../../../../app-context';
import DeleteMeasurementDetailButton from '../../../../../../components/Button/Meter/DeleteMeasurementDetailButton';
import EditMeasurementDetailButton from '../../../../../../components/Button/Meter/EditMeasurementDetailButton';
import DataGridActions from '../../../../../../components/DataGridCard/DataGridActions';
import DataGridCardHeaderSection from '../../../../../../components/DataGridCard/DataGridCardHeaderSection';
import { ICON_COLORS, ICONS } from '../../../../../../constants/icons';
import { TabSlider } from '../../../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../../../design-system/Components/TabSlider/Tab';
import css from './list-channel-selection.module.scss';

type Props = {
  channels: ExternalChannelType[];
  meter: MeterType;
  selectedRows: MeasurementRowType[];
  consumptionChannels: ExternalChannelType[];
  selectedListModeChannel: string;
  outputType: string;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedListModeChannel: (channelId: string) => void;
};

export default function ListChannelSelection(props: Props) {
  const {
    selectedRows,
    channels,
    consumptionChannels,
    selectedListModeChannel,
    outputType,
    setSelectedIds,
    setSelectedListModeChannel
  } = props;
  const { enumReducer } = useAppContext();

  const channsToMap = outputType === 'measurements' ? channels : consumptionChannels;

  return (
    <DataGridCardHeaderSection>
      <TabSlider
        id="channel-tabs"
        type="white"
        className={css['channel-tabs']}
        tabItems={
          channsToMap.map((chann) => {
            return {
              id: chann.externalIdentifier,
              title: chann.description
                ? `${chann.description} (${enumReducer.getTranslation('unitOfMeasure', chann.unitOfMeasure)})`
                : '',
              icon: ICONS[chann.utilityType],
              selectedColor: ICON_COLORS[chann.utilityType]
            };
          }) as SelectionTabItemType<Record<string, any>>[]
        }
        selectedTabItem={selectedListModeChannel}
        setSelectedItem={setSelectedListModeChannel}
      />

      {outputType === 'measurements' && (
        <DataGridActions>
          <EditMeasurementDetailButton {...props} />
          <DeleteMeasurementDetailButton selectedRows={selectedRows} setSelectedIds={setSelectedIds} />
        </DataGridActions>
      )}
    </DataGridCardHeaderSection>
  );
}
