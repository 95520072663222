import React, { ReactNode, useState, MouseEvent, useCallback } from 'react';
import classNames from 'classnames';

import css from './tile.module.scss';

type Props = {
  children: ReactNode;
  hoverable?: boolean;
};

export default function Tile(props: Props) {
  const { children, hoverable = false } = props;
  const [isHoverable, setHoverable] = useState<boolean>(hoverable);

  /**
   * @description fire when mouse hover the tile
   * @param event
   */
  const onMouseOver = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHoverable(true);
  }, []);

  /**
   * @description fire when mouse leave the tile
   * @param event
   */
  const onMouseLeave = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHoverable(false);
  }, []);

  return (
    <div
      className={classNames(css['tile-body'], { [css['hoverable']]: isHoverable || hoverable })}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
}
