import React from 'react';
import { Bar, BarChart, ResponsiveContainer } from 'recharts';
import { Margin } from 'recharts/types/util/types';

import css from './chart-style.module.scss';
import CustomBar from './CustomBar';
import { ChartDataType, IntervalType } from './types';
import { ZFChartLayout } from './ZFChartLayout';

type Props = {
  subject: string;
  XdataKey: string;
  YdataKey: string;
  chartData: ChartDataType[];
  height?: string;
  margins?: Margin;
  unit?: string;
  interval?: IntervalType;
  locale: string;
  onClick?: (barData: any) => void;
};

const SingleBarChart = React.memo((props: Props) => {
  const {
    subject,
    chartData,
    XdataKey,
    YdataKey,
    interval,
    height = '35rem',
    margins,
    locale,
    unit = '',
    onClick
  } = props;

  const customBar = <CustomBar />;
  const layout = ZFChartLayout(subject, XdataKey, locale, interval, undefined, true);

  return (
    <div style={{ height: height }}>
      <ResponsiveContainer className={css['chart']}>
        <BarChart data={chartData} onClick={onClick} margin={margins ? margins : undefined}>
          {layout}
          <Bar shape={customBar} dataKey={YdataKey} radius={[5, 5, 0, 0]} unit={unit} cursor="pointer" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
});

export default SingleBarChart;
