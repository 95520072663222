import React from 'react';

import { DomainNotificationType } from '@zf/api-types/general';
import { convertHexToRGBA } from '@zf/utils/src/color';

import { DANGER } from '../../constants/color';
import { getErrorText } from '../../utils/errors';
import css from './error-view.module.scss';

type Props = {
  errors: DomainNotificationType[];
};

export default function ErrorView(props: Props) {
  const { errors } = props;
  const [bgColor] = React.useState(convertHexToRGBA(DANGER, 20));

  return (
    <ul className={css['error-view']} style={{ color: DANGER, backgroundColor: bgColor }}>
      {errors.map((e, index) => {
        return <li key={index}>{getErrorText(e)}</li>;
      })}
    </ul>
  );
}
