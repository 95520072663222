import React from 'react';

import { ImportJobItemsType } from '@zf/api-types/import-job';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import css from './error-dialog.module.scss';

type Props = {
  item: ImportJobItemsType;
  errorType: 'error' | 'propertyError';
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { item, errorType } = props;

  React.useImperativeHandle(ref, () => ({
    async onClick() {}
  }));

  return errorType === 'propertyError' ? (
    <div className={css['property-error-grid']}>
      {item.propertyErrors.map((propertyError, index) => {
        return (
          <React.Fragment key={index}>
            <Label className={css['property-label']}> {`${propertyError.propertyName}:`}</Label>
            <div>
              {propertyError.errors.map((e, index2) => {
                return <Paragraph key={`${e.key}-${index}-${index2}`}>{`- ${e.message}`}</Paragraph>;
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  ) : (
    <div className={css['errors']}>
      {item.errors.map((e) => {
        return <Paragraph key={e.key}>{`- ${e.message}`}</Paragraph>;
      })}
    </div>
  );
});
