import React, { useMemo } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../../components/placeholder';
import { useStore } from '../../../../hooks/useStore';
import useContractListPage from '../../hooks/useContractListPage';

type Props = {
  customEntityPropertyTypes: CustomEntityPropertyType[];
  extraColumns: ColumnType[];
};

export default function ContractsList(props: Props) {
  const { customEntityPropertyTypes, extraColumns } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    handleSort,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    setStopIndex,
    setSelectedIds,
    localTimeStamp,
    timeStamp
  } = useContractListPage(customEntityPropertyTypes);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="contracts-table"
        tooltipId="contracts-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        singleSort
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sortableFields={sortableFields}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={[
          {
            width: 200,
            label: getTranslation('contracts.list.contract_number'),
            dataKey: 'ContractNumber',
            noClick: true
          },
          {
            width: 320,
            label: getTranslation('contracts.contractor'),
            dataKey: 'contractor'
          },
          {
            width: 250,
            label: getTranslation('contracts.list.product'),
            dataKey: 'product'
          },
          {
            width: 250,
            label: getTranslation('contracts.list.supply_date'),
            dataKey: 'SupplyEndDate'
          },
          {
            width: 100,
            label: getTranslation('general.status'),
            dataKey: 'CurrentContractStatus'
          },
          {
            width: 350,
            label: getTranslation('location.serviced_locations'),
            dataKey: 'locations'
          },
          {
            width: 150,
            label: getTranslation('contracts.service'),
            dataKey: 'services'
          },
          {
            width: 180,
            label: getTranslation('contracts.list.external'),
            dataKey: 'externalReference'
          },
          ...extraColumns
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
}
