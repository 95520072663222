import { customerType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import clone from 'clone';
import { useEffect } from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { DomainNotificationType } from '@zf/api-types/general';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { ContractDetailsType, MeasurementDetailsType, MoveRequestType } from '@zf/api-types/master-data/move-request';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import useCultureTable from '../../../../../app-context/hooks/use-culture-table';
import { CustomerDetailsBaseUnionType } from '../../../../customer/wizard/CustomerWizard';
import { ActivityStatusType } from '../Activity';
import { ExistingEntitiesType, SelectedActivityType, useTracked } from '../activity-context/context';
import { BillingDataType } from '../wizards/contract-wiz-forms/BillingForm';
import { ContractDataType } from '../wizards/contract-wiz-forms/ContractDataForm';

export default function useActivities() {
  const [state, dispatch] = useTracked();

  const { cultureTable } = useCultureTable();

  const setDeviatingLanguage = (isDeviating: boolean) => {
    dispatch({ type: 'SET_DEVIATING_LANG', isDeviating });
  };

  useEffect(() => {
    if (cultureTable && state.task) {
      setDeviatingLanguage(state.task.customerDetails.culture !== cultureTable.defaultCulture);
    }
  }, [cultureTable, state.task]);

  const validateMeasurements = () => {
    if (state.task) {
      const allErrors: DomainNotificationType[] = [];

      state.task.measurementDetails.forEach((detail) => {
        allErrors.push(...detail.errors);
      });

      return {
        allErrors,
        isComplete: state.task.status === moveRequestStatus.processed
      };
    } else {
      return {
        allErrors: [],
        isComplete: false
      };
    }
  };

  const getSelectedContract = (contractDetails: ContractDetailsType) => {
    if (contractDetails.internalContractId) {
      return contractDetails.internalContractId;
    } else if (contractDetails.externalContractId) {
      return contractDetails.externalContractId;
    }
    return '';
  };

  const getActivityStatus = (activity: SelectedActivityType): ActivityStatusType => {
    if (state.task) {
      switch (activity) {
        case 'confirm_contract': {
          if (state.task.contractDetails.errors.length > 0) {
            return 'error';
          }
          if (state.task.contractDetails.internalContractId && state.task.contractDetails.createdOrLinked) {
            return 'complete';
          }
          return 'new';
        }

        case 'confirm_measurements': {
          const measurementValidation = validateMeasurements();

          if (measurementValidation.allErrors.length > 0) {
            return 'error';
          }

          if (measurementValidation.isComplete) {
            return 'complete';
          }

          return 'new';
        }

        case 'confirm_location': {
          if (state.task.serviceLocationDetails.errors.length > 0) {
            return 'error';
          }
          if (state.task.serviceLocationDetails.internalId && state.task.serviceLocationDetails.linked) {
            return 'complete';
          }
          return 'new';
        }

        case 'confirm_customer': {
          if (state.task.customerDetails.errors.length > 0) {
            return 'error';
          }
          if (state.task.customerDetails.internalId && state.task.customerDetails.createdOrLinked) {
            return 'complete';
          }
          return 'new';
        }

        default:
          return 'new';
      }
    }

    return 'new';
  };

  const setSelectedActivity = (newSelected: SelectedActivityType) => {
    dispatch({
      type: 'SET_SELECTED_ACTIVITY',
      newSelectedActivity: newSelected
    });
  };

  const setTask = (updatedTask: MoveRequestType, updateInitial?: boolean, initialCustomerType?: customerType) => {
    dispatch({
      type: 'SET_TASK',
      updatedTask,
      updateInitial,
      initialCustomerType
    });
  };

  const setTaskField = (value: MoveRequestType[keyof MoveRequestType], key: keyof MoveRequestType) => {
    const taskClone = clone(state.task) as Record<string, any>;
    taskClone[key] = value;
    setTask(taskClone as MoveRequestType);
  };

  const setCustomer = (newCustomer: CustomerType | null) => {
    const taskClone = clone(state.task);
    if (taskClone && newCustomer) {
      taskClone.customerDetails.internalId = newCustomer.id;
      setTask(taskClone);
      dispatch({ type: 'SET_CUSTOMER', customer: newCustomer });
    }
  };

  const setCustomerType = (customerType_: customerType) => {
    dispatch({ type: 'SET_CUSTOMER_TYPE', customerType: customerType_ });
  };

  const setCustomerDetail = (value: Partial<CustomerDetailsBaseUnionType>) => {
    if (state.task) {
      const taskClone = clone(state.task);

      // @ts-ignore
      taskClone.customerDetails = { ...taskClone.customerDetails, ...value };

      if (value['customerGroupId']) {
        // @ts-ignore
        taskClone.customerDetails = {
          ...taskClone.customerDetails,
          ...{
            customerGroup: {
              id: value.customerGroupId
            }
          }
        };
      }
      setTask(taskClone);
    }
  };

  const setLocation = (newLocation: ServiceLocationType | null) => {
    const taskClone = clone(state.task);
    if (taskClone && newLocation) {
      taskClone.serviceLocationDetails.internalId = newLocation.id;
      taskClone.serviceLocationDetails.address = newLocation.address;
      taskClone.serviceLocationDetails.services = newLocation.services;
      setTask(taskClone);
    }
  };

  const setMeasurementDetails = (measurementDetails: MeasurementDetailsType[]) => {
    const taskClone = clone(state.task) as Record<string, any>;
    taskClone.measurementDetails = measurementDetails;
    setTask(taskClone as MoveRequestType);
  };

  const setContractData = (val: Partial<ContractDetailsType & ContractDataType & BillingDataType>) => {
    const taskClone = clone(state.task);
    if (taskClone) {
      taskClone.contractDetails = {
        ...clone(taskClone.contractDetails),
        ...val
      };
      setTask(taskClone);
    }
  };

  const setExistingEntities = (entities: ExistingEntitiesType) => {
    // If the contract product config deviates from the property's tick the checkbox
    if (state.task && state.existingEntities.existingPropertyGroupBillingConfig) {
      if (
        state.existingEntities.existingPropertyGroupBillingConfig.invoiceFrequency !==
          state.task.contractDetails.invoiceFrequency ||
        state.existingEntities.existingPropertyGroupBillingConfig.paymentTermsId !==
          state.task.contractDetails.paymentTermsId ||
        state.existingEntities.existingPropertyGroupBillingConfig.productId !== state.task.contractDetails.productId ||
        state.existingEntities.existingPropertyGroupBillingConfig.advanceFrequency !==
          state.task.contractDetails.advanceFrequency
      ) {
        dispatch({ type: 'SET_CUSTOM_PROD_CONFIG', isCustom: true });
      }
    }
    dispatch({ type: 'SET_EXISTING_ENTITIES', entities: entities });
  };

  const setHasCustomProductConfig = (isCustom: boolean) => {
    dispatch({ type: 'SET_CUSTOM_PROD_CONFIG', isCustom: isCustom });
  };

  return {
    task: state.task,
    initialTask: state.initialTask,
    selectedActivity: state.selectedActivity,
    customer: state.customer,
    deviatingLanguage: state.deviatingLanguage,
    existingEntities: state.existingEntities,
    existingCustomer: state.existingEntities.existingCustomer,
    existingLocation: state.existingEntities.existingLocation,
    existingPropertyGroupBillingConfig: state.existingEntities.existingPropertyGroupBillingConfig,
    existingMeters: state.existingEntities.existingMeters,
    existingContract: state.existingEntities.existingContract,
    locationIsDerived: state.existingEntities.locationIsDerived,
    isLoadingExistingEntities: state.isLoadingExistingEntities,
    internalMeterIds: state.internalMeterIds,
    hasCustomProductConfig: state.hasCustomProductConfig,
    customerType_: state.customerType,
    initialCustomerType: state.initialCustomerType,
    validateMeasurements,
    getActivityStatus,
    setExistingEntities,
    setSelectedActivity,
    setDeviatingLanguage,
    setTask,
    setTaskField,
    setCustomer,
    setLocation,
    setCustomerDetail,
    setMeasurementDetails,
    setContractData,
    setHasCustomProductConfig,
    setCustomerType,
    getSelectedContract
  };
}
