import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';

const CustomerPortalLink = () => {
  const { applicationStore, integrationStore } = useStore();
  const { portalUrl } = integrationStore.customerPortalStore;
  const { getTranslation } = applicationStore;

  return (
    <Card id="portal-config-card" width="3">
      <CardHeader
        extraRight={
          <Link id={`link-${portalUrl}`} url={`${portalUrl}`} newTab bold>
            {portalUrl}
          </Link>
        }
      >
        {getTranslation('portal.portal')}
      </CardHeader>
    </Card>
  );
};

export default observer(CustomerPortalLink);
