import React from 'react';

import { DataProviderTypesType, UpdateExportDataProviderType } from '@zf/api-types/data-provider';
import { FileFormatType } from '@zf/api-types/file-format';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import DataExportsContent from './data-exports-content';

type Props = {
  config: UseConfigReturnType<UpdateExportDataProviderType[]>;
  dataTypes: DataProviderTypesType[];
  fileformats: FileFormatType[];
};

export default function DataExports(props: Props) {
  const { config, fileformats } = props;
  const { Provider, useTracked } = useCreateContext<UpdateExportDataProviderType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <DataExportsContent {...props} fileformats={fileformats} useTracked={useTracked} />
    </Provider>
  );
}
