import { observer } from 'mobx-react';
import React from 'react';

import {
  AttributeReceivingPeriodType,
  RequiredConsumptionUnitQuantityType
} from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';
import { BillingItemType, ConsumptionUnitType } from '@zf/api-types/product';
import Collapsable from '@zf/stella-react/src/atoms/Collapsable/Collapsable';
import AlertCircle from '@zf/stella-react/src/atoms/Info/AlertCircle';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';
import { formatDate } from '@zf/utils/src/date';

import { Info } from '../../../../../../../design-system/ComponentSets';
import { Link } from '../../../../../../../design-system/Components';
import { useStore } from '../../../../../../../hooks/useStore';
import useSuspenseSingleAPI from '../../../../../../../hooks/useSuspenseSingleAPI';
import ErrorsTip from '../ErrorsTip';
import css from './required-attribute.module.scss';
import ValueCompleteness from './ValueCompleteness';

type Props = {
  billingItem: BillingItemType;
  productName: string;
  requiredQuantity: RequiredConsumptionUnitQuantityType;
  defaultClosed?: boolean;
};

const RequiredConsumptionUnit = observer((props: Props) => {
  const { billingItem, requiredQuantity, defaultClosed = false, productName } = props;

  const { applicationStore, contractStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { selectedCompleteness, getCorrespondingReceivingPeriods, getRequiredQuantityCompleteness } =
    contractStore.billingCompletenessStore;

  const isInFuture = selectedCompleteness.status === billingCompletenessStatus.waiting;

  const { title, color } = getRequiredQuantityCompleteness(requiredQuantity);

  const consumptionUnitResponse = useSuspenseSingleAPI<ConsumptionUnitType>({
    request: {
      endpoint: `/cfg/ConsumptionUnitTypes/${requiredQuantity.consumptionUnitTypeId}`
    },
    mayExecute: !isInFuture || selectedCompleteness.status !== billingCompletenessStatus.previousnotclosed
  });

  const consumptionUnit = consumptionUnitResponse.result?.data;

  return (
    <Collapsable
      title={title}
      titleColor={color}
      extraLeft={
        <div className={css['extra-left']}>
          <Link
            id={`billing-item-${billingItem.id}`}
            className={css['item-name']}
            url={`${rootUrl}/configuration/product/products?billingItemId=${
              billingItem.id
            }&productName=${encodeURIComponent(productName)}`}
            bold
          >
            {billingItem.name}
          </Link>
          {!requiredQuantity.complete && requiredQuantity.errors.length > 0 && (
            <AlertCircle>
              <ErrorsTip errors={requiredQuantity.errors} />
            </AlertCircle>
          )}
        </div>
      }
      defaultClosed={defaultClosed}
    >
      {isInFuture ? (
        <Info className={css['info']}>
          {getTranslation('invoice.info_in_future', {
            invoicingCheckpointDateTime: formatDate(selectedCompleteness.invoicingCheckpointDateTime)
          })}
        </Info>
      ) : selectedCompleteness.status === billingCompletenessStatus.previousnotclosed ? (
        <Info className={css['info']}>
          {getTranslation('invoice.info_prev_not_closed', {
            invoicingCheckpointDateTime: formatDate(selectedCompleteness.invoicingCheckpointDateTime)
          })}
        </Info>
      ) : (
        <AsyncVarSpinner variables={[consumptionUnit]}>
          <div className={css['asking-periods']}>
            {requiredQuantity.askingPeriods.map((ap, apIndex) => {
              const correspondingReceivingPeriods = getCorrespondingReceivingPeriods(
                ap,
                requiredQuantity.receivingPeriods
              ) as AttributeReceivingPeriodType[];

              return (
                <ValueCompleteness
                  key={`${ap.startDateTime}-${ap.endDateTime}-${apIndex}`}
                  apIndex={apIndex}
                  valueName={consumptionUnit?.name || ''}
                  askingPeriod={ap}
                  correspondingReceivingPeriods={correspondingReceivingPeriods}
                />
              );
            })}
          </div>
        </AsyncVarSpinner>
      )}
    </Collapsable>
  );
});

export default RequiredConsumptionUnit;
