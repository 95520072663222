import useDialog from '@zf/hooks/src/useDialog';
import ConfirmationActionButton from 'components/Button/ConfirmationActionButton';
import { useStore } from 'hooks/useStore';
import React from 'react';
import { Icon } from '@zf/heat-erp/src/components/Icon';
import { colors } from '@zf/utils/src/color';
import { Button } from 'design-system/Components';
import Dialog from './Dialog';

export default function RemoveSettingDialog({
  submitFunction,
  hasCustomers
}: {
  submitFunction: Function;
  customerGroupId: string;
  hasCustomers: boolean;
}) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { clickRef, onSubmit } = useDialog();

  return (
    <>
      {hasCustomers && (
        <ConfirmationActionButton
          id="customer.groups.remove.settings"
          type="text"
          dialogTitle={getTranslation('customer_groups.settings.removed')}
          dialogContent={
            <Dialog
              ref={clickRef}
              info={getTranslation('customer_groups.settings.desc')}
              successMsg={getTranslation('customer_groups.settings.removed_success')}
              errorMsg={getTranslation('customer_groups.settings.removed_fail')}
              submitFunction={() => submitFunction(null)}
            />
          }
          onSubmit={onSubmit}
          dialogType="danger"
          size="small"
          dialogLocation="normal"
          buttonPositive={getTranslation('general.remove')}
        >
          <Icon type="cross" color={colors['silver-600']} />
        </ConfirmationActionButton>
      )}
      {!hasCustomers && (
        <Button id="customer.groups.remove.settings" onClick={() => submitFunction(null)} type="text" icon="cross" />
      )}
    </>
  );
}
