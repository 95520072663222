import React from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import SignContractDialog from '../../dialogs/sign-dialog';

type Props = {
  contract: ContractType;
};

export default function SignContractListItem(props: Props) {
  const { contract } = props;

  const { clickRef, onSubmit } = useDialog();
  const { contractStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  if (contractStore.pageActionPermissions.maySignContract) {
    return (
      <DropdownAction
        id="contract.sign"
        icon="sign"
        dialogTitle={getTranslation('actions.sign')}
        dialogContent={<SignContractDialog ref={clickRef} contract={contract} />}
        onSubmit={onSubmit}
        buttonPositive={getTranslation('actions.sign')}
      />
    );
  }

  return null;
}
