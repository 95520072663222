import { action, makeAutoObservable, observable } from 'mobx';

import { pluginType } from '@zf/api-types/enums';
import { TwikeyConfigurationInfo, TwikeyVerificationOptions } from '@zf/api-types/integration/twikey';

import RootStore from '../../../app-context/stores';
import AutomaticPaymentsForm from '../parameters/automatic-payments/logic/AutomaticPaymentsForm';
import PaymentsConfigService from '../services/PaymentsConfigService';

export default class PaymentConfigStore {
  public rootStore: RootStore;

  public _twikeyConfigurationInfo: TwikeyConfigurationInfo | undefined;
  public _automaticPaymentsForm: AutomaticPaymentsForm | undefined;
  public paymentsConfigService: PaymentsConfigService;
  public twikeyEnabled = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.paymentsConfigService = new PaymentsConfigService(this.rootStore.applicationStore);

    makeAutoObservable(this, {
      twikeyEnabled: observable,
      _twikeyConfigurationInfo: observable,
      _automaticPaymentsForm: observable,

      initPaymentConfig: action,
      updateTwikeyConfigurationInfo: action
    });
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get twikeyConfigurationInfo() {
    return this._twikeyConfigurationInfo as TwikeyConfigurationInfo;
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get automaticPaymentsForm() {
    return this._automaticPaymentsForm as AutomaticPaymentsForm;
  }

  initPaymentConfig = async () => {
    const { configService, isPluginEnabled } = this.rootStore.configStore;
    await configService.getConfiguredPlugins();
    this.twikeyEnabled = isPluginEnabled(pluginType.twikey);

    const twikeyConfigurationInfoResponse = await this.paymentsConfigService.getTwikeyConfigurationInfo();
    this._twikeyConfigurationInfo = twikeyConfigurationInfoResponse;

    if (twikeyConfigurationInfoResponse) {
      this._automaticPaymentsForm = new AutomaticPaymentsForm(this, {
        ...twikeyConfigurationInfoResponse.verificationOptions
      });
    }
  };

  updateTwikeyConfigurationInfo = async (verificationOptions: TwikeyVerificationOptions) => {
    if (this.twikeyConfigurationInfo) {
      const updated = await this.paymentsConfigService.updateTwikeyConfigurationInfo(verificationOptions);
      this.twikeyConfigurationInfo.verificationOptions = updated;
      this._automaticPaymentsForm = new AutomaticPaymentsForm(this, { ...updated });
    }
  };

  resetStore = () => {
    this.twikeyEnabled = false;
    this._twikeyConfigurationInfo = undefined;
    this._automaticPaymentsForm = undefined;
  };
}
