import { observer } from 'mobx-react';
import React from 'react';

import RightHeader from './RightHeader';
import RightBody from './RightBody';
import RightFooter from './RightFooter';

const RightPart = observer(() => {
  return (
    <>
      <RightHeader />
      <RightBody />
      <RightFooter />
    </>
  );
});

export default RightPart;
