import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../../../app-context';
import { Link } from '../../../../design-system/Components';
import css from './left-column.module.scss';
import LabelItemGrid from '@zf/stella-react/src/atoms/Card/LabelItemGrid';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatPeriod } from '@zf/utils/src/date';
import { CurrentContractDetailsType } from '@zf/api-types/master-data/move-request';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { Paragraph } from 'design-system/Foundation';

type Props = {
  contractDetails: CurrentContractDetailsType;
  label: string;
};

export default function Contract({ contractDetails, label }: Props) {
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  return (
    <div className={css['contract']}>
      <Heading headingType="h3">{label}</Heading>
      <CardItem width="6" />
      <LabelItemGrid>
        <CardItem width="6">
          <Label>{i18n.getTranslation('contracts.contract')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>
            {contractDetails && (
              <Link
                id={`${routedEntitySubjectType.tasks}-${contractDetails.contractId}`}
                url={`${rootUrl}/contracts/${contractDetails?.contractId}`}
                icon="contract"
                noNavigation={typeof contractDetails?.contractId === 'undefined' ? true : false}
              >
                {`${contractDetails?.contractNumber} - ${contractDetails?.customerDisplayName}`}
              </Link>
            )}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('general.period')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>
            {formatPeriod(contractDetails?.contractSupplyStartDate, contractDetails?.contractSupplyEndDate)}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('customer.customer')}</Label>
        </CardItem>
        <CardItem width="6">
          <Link
            id={`${routedEntitySubjectType.tasks}-${contractDetails?.contractId}`}
            url={`${rootUrl}/contracts/${contractDetails?.contractId}`}
            icon="customer"
            noNavigation={typeof contractDetails?.contractId === 'undefined' ? true : false}
          >
            <Paragraph>{contractDetails?.customerDisplayName}</Paragraph>
          </Link>
        </CardItem>
      </LabelItemGrid>
    </div>
  );
}
