import clone from 'clone';
import { useEffect } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { utilityType } from '@zf/api-types/enums';
import { PropertyGroupForecastingConfiguration } from '@zf/api-types/forecasting/propertygroup-forecasting-cfg';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { useAppContext } from '../../../../../app-context/app-context';
import { METHODS, sendRequest } from '../../../../../utils/request';
import { useTracked } from '../context/settings-context';

export default function useFetchPropertygroupSettings(propertyGroup: PropertyGroupType) {
  const dispatch = useTracked()[1];
  const { i18n, tenantReducer } = useAppContext();

  const getOrganisationConfig = async () => {
    return (
      await sendRequest<OrganizationConfigType>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/Organizations/${tenantReducer.state.organization?.organizationId}`
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  const getAvailableUtilityTypes = async () => {
    return (
      await sendRequest<PagedResponseType<utilityType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/fct/ConsumerGroups/availableutilitytypes'
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data.results;
  };

  const getPropertyGroupBillingConfig = async () => {
    return (
      await sendRequest<PropertyGroupBillingConfigurationType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroup.id}`
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  const getPropertyGroupForecastingConfig = async () => {
    return (
      await sendRequest<PropertyGroupForecastingConfiguration>({
        request: {
          method: METHODS.GET,
          endpoint: `/fct/PropertyGroupForecastingConfiguration/${propertyGroup.id}`
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  useEffect(() => {
    Promise.all([
      getOrganisationConfig(),
      getAvailableUtilityTypes(),
      getPropertyGroupBillingConfig(),
      getPropertyGroupForecastingConfig()
    ]).then((res) => {
      const orgConfig = res?.[0];
      const availableUtilityTypes = res?.[1];
      const billingConfig = res?.[2];
      const fctConfig = res?.[3];

      // If not customized all fields will be null, we overwrite with organisation defaults here
      const propertyClone = clone(propertyGroup);
      const commConfig = propertyClone.communicationConfiguration;

      const initialCommConfig = {
        ...propertyClone.communicationConfiguration,
        contactDetails: {
          emailAddress: commConfig.contactDetails.emailAddress || orgConfig.contactDetails.emailAddress,
          telephone: commConfig.contactDetails.telephone || orgConfig.contactDetails.telephone,
          website: commConfig.contactDetails.website || orgConfig.contactDetails.website,
          support: commConfig.contactDetails.support || orgConfig.contactDetails.support,
          telephoneInterruptions:
            commConfig.contactDetails.telephoneInterruptions || orgConfig.contactDetails.telephoneInterruptions
        },
        vatAccountNumber: commConfig.vatAccountNumber || orgConfig.vatAccountNumber,
        companyAccountNumber: commConfig.companyAccountNumber || orgConfig.companyAccountNumber,
        logo: commConfig.logo || orgConfig.logo,
        primaryColor: commConfig.primaryColor || orgConfig.primaryColor,
        secondaryColor: commConfig.secondaryColor || orgConfig.secondaryColor,
        address: commConfig.address || orgConfig.address
      };

      propertyClone.communicationConfiguration = initialCommConfig;

      dispatch({
        type: 'INIT_STATE',
        billingConfig,
        propertyGroup: propertyClone,
        availableUtilityTypes,
        organisationConfig: orgConfig,
        propertyGroupForecastingConfig: fctConfig
      });
    });
  }, [propertyGroup.id]);
}
