import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatPeriod, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import moment from 'moment';
import React from 'react';
import CheckBox from '../../../../../components/input/CheckBox';
import DateRangePicker from '../../../../../components/input/DateRangePicker';
import { useStore } from '../../../../../hooks/useStore';
import css from '../../social-tariff.module.scss';
import { State } from '../create-social-tariff-dialog';

type Props = {
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const CustomerPortfolio = (props: Props) => {
  const { state, setState } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div>
      <Label className={css['label-export-tariff-range']}>
        {getTranslation('country_specifics.choose_a_range')} {formatPeriod(state.startDateTime, state.endDateTime)}
      </Label>
      <DateRangePicker
        id="export-social-tariff"
        startDate={state.startDateTime}
        endDate={state.endDateTime}
        setDates={(dates_) =>
          setState({
            startDateTime: dates_[0] || moment(MIN_DATE),
            endDateTime: dates_[1] || moment(MAX_DATE)
          })
        }
      />
      <CheckBox
        className={css['social-tariff-is-moved']}
        id="2"
        checked={state.isMovedCustomer}
        onChange={(isMovedCustomer) => setState({ isMovedCustomer })}
      >
        {getTranslation('country_specifics.moved_customers')}
      </CheckBox>
    </div>
  );
};

export default CustomerPortfolio;
