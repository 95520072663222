import { observer } from 'mobx-react';
import moment from 'moment';
//Add generic action that can handle all listpages.
import React, { useCallback } from 'react';

import { downloadBlob, s2ab } from '@zf/utils/src/download';

import { ButtonType } from '../../../design-system/Components/Button/BaseButton';
import { ButtonSize } from '../../../design-system/Components/Button/Button';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import Button from '../Button';
import { isMinDate } from '@zf/utils/src/date';

//Documentation: https://docs.sheetjs.com

type Props = {
  id: string;
  ws_name: string;
  disabled?: boolean;
  title?: string;
  icon?: string;
  size?: ButtonSize;
  type?: ButtonType;
  buttonType?: 'list' | 'normal';
  toExportArray: () => string[][] | Promise<string[][] | undefined>;
};

/**
 * Creates a downloadable Excel file given a nested array (string[ ][ ])
 * @param ws_name_ prefix for the exported Excel file's name
 * @param arrayMapFunction_ Nested array to be converted to columns/rows. Ex. [['Name']['John']]
 * @param date suffix for the exported Excel file's name
 */
export const exportArrayToExcel = async (
  ws_name_: string,
  arrayMapFunction_: () => string[][] | Promise<string[][] | undefined>,
  date?: string
) => {
  const XLSX = await import('xlsx');

  //Create new Excel Workbook
  const new_workbook = XLSX.utils.book_new();
  new_workbook.Props = {
    Title: ws_name_,
    Subject: ws_name_,
    Author: 'Zero Friction',
    Company: 'Zero Friction',
    CreatedDate: new Date()
  };

  //add a new sheet
  new_workbook.SheetNames.push(ws_name_);

  // Promise.resolve handles both regular array or promise
  await Promise.resolve(arrayMapFunction_()).then((exportArray) => {
    if (exportArray) {
      const ws = XLSX.utils.aoa_to_sheet(exportArray);
      new_workbook.Sheets[ws_name_] = ws;

      let formattedDate = '';

      formattedDate = moment(isMinDate(date) ? undefined : date).format('DDMMYY');

      //convert to a downloadable file
      const wbout = XLSX.write(new_workbook, { bookType: 'xlsx', type: 'binary' });
      downloadBlob(
        ws_name_ + '-' + formattedDate + '.xlsx',
        new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
      );
    }
  });
};

const ExportToExcel = (props: Props) => {
  const { ws_name, id, size, icon = 'exel', type, disabled = false, buttonType = 'list', toExportArray } = props;
  let { title } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  title = title || getTranslation('actions.export');

  const exportAction = useCallback(() => {
    return exportArrayToExcel(ws_name, toExportArray);
  }, [ws_name, toExportArray]);

  return buttonType === 'list' ? (
    <DropdownAction id={id} icon={icon} actionType="direct" onClick={exportAction} disabled={disabled}>
      {title}
    </DropdownAction>
  ) : (
    <Button id={id} size={size} icon={icon} type={type} onClick={exportAction} disabled={disabled}>
      {title}
    </Button>
  );
};

export default observer(ExportToExcel);
