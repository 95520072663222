import { Icon, Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { METHODS } from 'utils/request';

import { CostComponent, EstimatedInvoiceLine } from '@zf/api-types/billing/estimated-invoices';
import { colors } from '@zf/utils/src/color';

import css from '../../accuracy-indicator.module.scss';
import { Links } from '../../Links';
import { UPDATE } from '../../types';

const CostComponents: React.FC<{
  estimatedInvoiceLine: EstimatedInvoiceLine;
  productName?: string;
  onClose?: Function;
}> = ({ estimatedInvoiceLine, productName, onClose }) => {
  const { applicationStore } = useStore();
  const [cost, setCost] = useState<Array<CostComponent>>([]);
  const { getTranslation, sendRequest } = applicationStore;

  const getCostComponentById = async (id: string) => {
    return (
      await sendRequest<CostComponent>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/CostComponents/${id}`
        }
      })
    ).data;
  };

  useEffect(() => {
    estimatedInvoiceLine?.costComponentAccuracies?.forEach(async (cca) => {
      if (cca.outDated) {
        var data = await getCostComponentById(cca.entityId);
        setCost((prevState) => [...prevState, data]);
      }
    });
  }, [estimatedInvoiceLine?.costComponentAccuracies]);

  return (
    <>
      {typeof estimatedInvoiceLine?.costComponentAccuracies !== 'undefined' &&
        estimatedInvoiceLine?.costComponentAccuracies.length >= 0 && (
          <div className={css['checkmark-line-outdated']}>
            <Icon name="cross" color={colors['red-600']} />
            <Paragraph className={css['checkmark-text']}>
              {estimatedInvoiceLine.costComponentAccuracies.length === 0
                ? getTranslation('contracts.estimation_accuracy_using_outdated_tariffs')
                : getTranslation('contracts.estimation_accuracy_using_outdated_cost_components')}
            </Paragraph>

            <Paragraph className={css['checkmark-link']}>
              <Links
                item={UPDATE.TARIFF}
                formData={{
                  entityId: estimatedInvoiceLine.billingTariffAccuracy.entityId,
                  billingItemId: estimatedInvoiceLine.billingItemReference.billingItemId,
                  productName
                }}
                entityName={'configuration'}
                entityId={'product'}
                extraUrl={estimatedInvoiceLine.billingItemReference.billingItemId}
                onNavigate={onClose}
              ></Links>
            </Paragraph>

            {cost?.map(({ name }, key) => {
              return (
                <Paragraph key={key} className={css['checkmark-cost-component']}>
                  <li>{name}</li>
                </Paragraph>
              );
            })}
          </div>
        )}
    </>
  );
};

export default observer(CostComponents);
