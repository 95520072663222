import React from 'react';

import ZFRouter from '../../../components/Router/ZFRouter';
import Route from '../../route';
import IncomingBankingTransaction from '../banking-transactions/incoming-banking-transactions/detail-page/IncomingBankingTransaction';
import IncomingBankingTransactionListPage from '../banking-transactions/incoming-banking-transactions/list-page/IncomingBankingTransactionListPage';
import OutgoingBankingTransaction from '../banking-transactions/outgoing-banking-transactions/detail-page/OutgoingBankingTransaction';
import OutgoingBankingTransactionListPage from '../banking-transactions/outgoing-banking-transactions/list-page/outgoing-banking-transaction-listpage';
import PaymentListPage from './PaymentListPage';

export default function Payments() {
  return (
    <ZFRouter>
      <Route path="incoming-banking-transactions" Component={IncomingBankingTransactionListPage} />
      <Route path="incoming-banking-transactions/:id/*" Component={IncomingBankingTransaction} />
      <Route path="outgoing-banking-transactions" Component={OutgoingBankingTransactionListPage} />
      <Route path="outgoing-banking-transactions/:id/*" Component={OutgoingBankingTransaction} />
      <Route path="payments" Component={PaymentListPage} default />
    </ZFRouter>
  );
}
