import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { ServiceLocationType, ServiceType } from '@zf/api-types/master-data/servicelocation';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { betweenDates } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import { Link } from '../../../../design-system/Components';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import { getServiceStatus } from '../../../../utils/serviceState';
import css from './customer-for-meter.module.scss';

type Props = {
  serviceLocationId: string;
};

export default function CustomerForMeter(props: Props) {
  const { serviceLocationId } = props;
  const { i18n, tenantReducer } = useAppContext();

  const response = useSuspenseSingleAPI<ServiceLocationType>({
    request: {
      endpoint: '/md/servicelocations',
      selector: `/${serviceLocationId}`
    }
  });

  if (!response) return null;

  let location: ServiceLocationType;

  if (response.result && !(response.result instanceof Promise)) location = response.result.data;
  else return null;

  const servicesSupplied = location.services.filter((service) => {
    const activeStatus = getServiceStatus(service.statusHistory);
    return activeStatus && activeStatus.serviceStatus === 'supplied';
  });

  const today = moment();
  const handledCustomers: Array<string> = [];

  const customers: Array<React.ReactNode> = servicesSupplied.reduce(
    (acc: Array<React.ReactNode>, service: ServiceType) => {
      const contract = service.contracts.find(
        (con) =>
          betweenDates(con.supplyStartDateTime, con.supplyEndDateTime, today) && con.currentContractStatus === 'signed'
      );

      if (contract && !handledCustomers.includes(contract.contractorId)) {
        handledCustomers.push(contract.contractorId);
        acc.push(
          <Link
            id={`customer-${contract.contractorId}`}
            key={contract.contractorId}
            url={`${tenantReducer.state.rootUrl}/customers/${contract.contractorId}`}
          >
            {contract.contractorDisplayName}
          </Link>
        );
      }

      return acc;
    },
    []
  );

  const areCustomers = customers.length > 0;

  return (
    <div className={classNames({ [css['greyed']]: !areCustomers })}>
      {areCustomers ? customers : <Paragraph>{i18n.getTranslation('meter.no_customer')}</Paragraph>}
    </div>
  );
}
