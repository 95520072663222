import { entitySubjectType } from '@zf/api-types/enums';
import React from 'react';
import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import ListPage from '../../../components/listPage';
import { useStore } from '../../../hooks/useStore';
import CountrySpecificsFilterBar from './social-tariff-filter-bar';
import CountrySpecificList from './social-tariff-list';

type Props = {
  location: Location;
};

const SocialTariffListPage = (props: Props) => {
  useBrowserTitle('Country-specifics');
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <ListPage
      entitySubjectType={entitySubjectType.socialtariffexport}
      subjectType={getTranslation('country_specifics.country_specifics')}
      topBar={<CountrySpecificsFilterBar search={props.location.search} />}
      list={<CountrySpecificList />}
    />
  );
};

export default SocialTariffListPage;
