import React, { forwardRef, Ref, useState } from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';

import { useAppContext } from '../../app-context';
import { TabSlider } from '../../design-system/Components';
import { SelectionTabItemType } from '../../design-system/Components/TabSlider/Tab';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { SilderOptions } from '../outgoing-banking-transactions/AddOutgoingBankingTransactionDialog';
import css from './entry-dialog.module.scss';
import InvoiceEntry from './InvoiceEntry';
import ManualEntry from './ManualEntry';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refresh: () => void;
};

const EntryDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { i18n } = useAppContext();

  const [entryType, setEntryType] = useState<keyof SilderOptions>('invoice');

  const [tabItems] = useState<SelectionTabItemType<SilderOptions>[]>([
    { id: 'invoice', title: i18n.getTranslation('invoice.invoice') },
    {
      id: 'manual',
      title: i18n.getTranslation(`actions.outgoing_mutation.manual_entry_${props.outgoingBankingTransaction.type}`)
    }
  ]);

  return (
    <>
      <TabSlider
        id="entry-type"
        className={css['tabs']}
        tabItems={tabItems}
        selectedTabItem={entryType}
        setSelectedItem={setEntryType}
      />
      {entryType === 'invoice' ? <InvoiceEntry {...props} ref={ref} /> : <ManualEntry {...props} ref={ref} />}
    </>
  );
});

export default EntryDialog;
