import { Button, EmptyState, Link } from 'design-system/Components';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';

import { MeterType, MutingRuleType } from '@zf/api-types/master-data/meter';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatDate } from '@zf/utils/src/date';

import css from './meter-issues-card-body.module.scss';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { CreateMutingRuleListPageButton } from 'components/Button/Meter/CreateMutingRuleListPageButton';
import { notify } from 'events/notification-events';
import { MutingRules } from './MutingRules';
import { UseCase } from './UseCase';

type Props = {
  meter: MeterType;
  issues: MeteringIssue[];
  refresh?: () => void;
};

const MeterIssuesCardBody = (props: Props) => {
  const { meter, issues, refresh } = props;

  const { applicationStore, meterStore } = useStore();
  const { mutingRuleService } = meterStore;
  const { getMutingRulesByMeterId, cancelMutingRuleByMutingRuleId } = mutingRuleService;
  const { rootUrl, getTranslation } = applicationStore;

  const [state, setState] = useState<{ expiredRulesVisible: boolean }>({
    expiredRulesVisible: false
  });

  const [activeMutingRules, setActiveMutingRuleState] = useState<{
    activeMutingRules: MutingRuleType[];
  }>({
    activeMutingRules: []
  });

  const [expiredMutingRules, setExpiredMutingRuleState] = useState<{
    expiredMutingRules: MutingRuleType[];
  }>({
    expiredMutingRules: []
  });

  const getMutingRules = () => {
    getMutingRulesByMeterId(meter.id, true, false).then(({ results }) => {
      setActiveMutingRuleState({ activeMutingRules: results });
    });

    getMutingRulesByMeterId(meter.id, false, true).then(({ results }) => {
      setExpiredMutingRuleState({ expiredMutingRules: results });
    });
  };

  useEffect(() => {
    getMutingRules();
  }, []);

  const onCancel = async <T,>(params: T, useCase: UseCase) => {
    //@ts-ignore
    const { id } = params;

    try {
      if (!id) {
        throw getTranslation('meter_issues.rule.cancel_fail');
      }
      if (useCase === 'issue-card') await cancelMutingRuleByMutingRuleId(id);
      getMutingRules();
      refresh && refresh();

      notify.success({
        content: getTranslation('meter_issues.rule.cancel_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('meter_issues.rule.cancel_fail'),
        error: e
      });
    }
  };

  return (
    <>
      <CardItem width="6">
        <Label color="black" bold>
          {getTranslation('meter_issues.recent_issues')}
        </Label>
      </CardItem>

      {meter.hasIssue && (
        <CardItem width="6" justifyContent="end">
          <Paragraph className={css['link-container']}>
            <Link
              id="meter-issues-link"
              url={`${rootUrl}/devices/meters/${meter.id}/issues`}
              className={css['show-issues-link']}
            >
              {getTranslation('meter_issues.show_issues')}
            </Link>
          </Paragraph>
        </CardItem>
      )}

      {meter.hasIssue ? (
        issues.slice(0, 4).map((issue) => (
          <Fragment key={issue.id}>
            <CardItem width="8">
              <Paragraph>{getTranslation(`meter_issues.error_${issue.error}`) || ''}</Paragraph>
            </CardItem>
            <CardItem width="4" justifyContent="end">
              <Paragraph className={css['date-container']}>{formatDate(issue.noticedDateTime)}</Paragraph>
            </CardItem>
          </Fragment>
        ))
      ) : (
        <CardItem width="12">
          <EmptyState
            imgName="meter"
            title={getTranslation('meter_issues.no_issues')}
            description={getTranslation('meter_issues.meter_no_issues')}
            horizontal
          />
        </CardItem>
      )}

      {/* THE CODE BELOW WILL BE USED FOR MUTING RULES EVENTUALLY */}

      <CardItem width="12">
        <HorizontalDivider />
      </CardItem>

      <CardItem width="8">
        <Label color="black" bold>
          {getTranslation('meter_issues.muting_rules', { count: activeMutingRules?.activeMutingRules?.length || 0 })}
        </Label>
      </CardItem>

      <CardItem width="4" justifyContent="end">
        <Label color="black" bold>
          <CreateMutingRuleListPageButton
            useCase="issue-card"
            meters={[meter]}
            refresh={() => {
              getMutingRules();
              refresh && refresh();
            }}
          />
        </Label>
      </CardItem>

      <MutingRules
        mutingRules={activeMutingRules.activeMutingRules}
        expired={false}
        onCancel={onCancel}
        useCase="issue-card"
      />

      {state.expiredRulesVisible && (
        <CardItem width="6">
          <Label color="black" bold>
            {getTranslation('meter_issues.expired_rules')}
          </Label>
        </CardItem>
      )}
      <CardItem width={state.expiredRulesVisible ? '6' : '12'} justifyContent="center">
        <Button
          id="toggle-expired"
          className={css['toggle-expired-rules-btn']}
          onClick={() => {
            setState({ expiredRulesVisible: !state.expiredRulesVisible });
          }}
          icon={state.expiredRulesVisible ? 'eye-crossed' : 'eye-open'}
          type="text"
        >
          {state.expiredRulesVisible
            ? getTranslation('meter_issues.hide_expired_rules')
            : getTranslation('meter_issues.show_expired_rules')}
        </Button>
      </CardItem>

      {state.expiredRulesVisible && (
        <MutingRules
          mutingRules={expiredMutingRules.expiredMutingRules}
          expired={true}
          onCancel={onCancel}
          useCase="issue-card"
        />
      )}
    </>
  );
};

export default observer(MeterIssuesCardBody);
