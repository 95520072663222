import classNames from 'classnames';
import React from 'react';

import Icon from '../Icon/Icon';
import css from './simpleTree.module.scss';

type Props = {
  children: React.ReactNode;
  title: string | React.ReactNode;
  className?: string;
  openedStyle?: string;
  clickeableContent?: boolean;
  isOpen?: boolean;
  openOnInit?: boolean;
  arrowType?: 'filled' | 'open';
  style?: React.CSSProperties;
};

export default function SimpleTree(props: Props) {
  const {
    children,
    title,
    className,
    openedStyle,
    clickeableContent = true,
    openOnInit = false,
    arrowType = 'open',
    isOpen = false,
    style = {}
  } = props;

  const [open, setOpen] = React.useState(openOnInit);

  // External control of setState
  React.useEffect(() => {
    if (!openOnInit) setOpen(isOpen);
  }, [isOpen]);

  let hideArrow = false;

  if (Array.isArray(children)) {
    hideArrow = children.length === 0;
  }

  let iconName: string;

  if (open) {
    iconName = arrowType === 'open' ? 'chevron-up' : 'caret-up';
  } else {
    iconName = arrowType === 'open' ? 'chevron-down' : 'caret-down';
  }

  return (
    <ul className={classNames(css['tree'], open ? openedStyle : undefined, className)} style={style}>
      <li
        className={classNames(css['tree-item'], { [css['pointer']]: !hideArrow, [css['margin-and-border']]: open })}
        onClick={() => (hideArrow ? {} : !isOpen ? setOpen(!open) : {})}
        role="presentation"
        onKeyDown={() => {}}
      >
        {!clickeableContent && title}
        {!hideArrow && <Icon name={iconName} />}
      </li>
      {open && children}
    </ul>
  );
}
