import clone from 'clone';
import React from 'react';

import { culture } from '@zf/api-types/enums';
import { DefinedTranslatedFieldType } from '@zf/api-types/language';
import Flag from '@zf/stella-react/src/atoms/Flag/Flag';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import Label from '@zf/stella-react/src/atoms/Label/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import useCultureTable from '../../app-context/hooks/use-culture-table';
import InputField, { InputFieldProps } from '../../components/input/InputField';
import MultiLineInput from '../../components/input/MultiLineInput';
import { getCultureFlag } from '../../utils/lang';
import css from './update-translations-dialog.module.scss';

type Props = {
  field: string;
  entity: any;
  translatedFields: DefinedTranslatedFieldType;
  setTranslatedFields: React.Dispatch<React.SetStateAction<DefinedTranslatedFieldType>>;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

const FieldTranslations = (props: Props) => {
  const { i18n, enumReducer } = useAppContext();
  const { field, entity, translatedFields, setTranslatedFields } = props;

  const { cultureTable } = useCultureTable();

  if (!cultureTable) return null;

  const setTranslation = (field: string, culture_: culture, translation: string) => {
    const entityTranslationsClone = clone(translatedFields);
    if (!entityTranslationsClone[field]) {
      entityTranslationsClone[field] = {};
    }
    entityTranslationsClone[field][culture_] = translation;
    setTranslatedFields(entityTranslationsClone);
  };

  return (
    <>
      {cultureTable.supportedCultures.map((c, index2) => {
        const languageText = enumReducer.getTranslation('culture', c);

        if (c !== cultureTable.defaultCulture) {
          const multiLineProps = {
            id: `translation-${field}-${c}-${index2}`,
            onChange: (translation: string) => setTranslation(field, c, translation),
            value: translatedFields[field] ? translatedFields[field][c] : ''
          };

          return (
            <React.Fragment key={`translation-${index2}`}>
              <div className={css['language-header']}>
                <Flag url={getCultureFlag(c)}></Flag>
                <Label className={css['translation-label']}>{languageText}</Label>
              </div>
              {field === 'Contents' ? (
                <MultiLineInput {...multiLineProps} />
              ) : (
                <InlineInputFieldInput {...multiLineProps} />
              )}
              <br />
            </React.Fragment>
          );
        } else {
          return (
            <div key={`translation-${index2}`} className={css['default-translation']}>
              <div className={css['language-header']}>
                <Flag url={getCultureFlag(cultureTable.defaultCulture)} />
                <Label className={css['translation-label']}>{`${languageText} ${i18n.getTranslation(
                  'translated_fields.default'
                )}`}</Label>
              </div>
              <Paragraph>{entity[field.toLowerCase()]}</Paragraph>
              <br />
            </div>
          );
        }
      })}
    </>
  );
};

export default FieldTranslations;
