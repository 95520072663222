import MeasurementService from 'app-context/services/metering/MeasurementService';
import MeteringIssueService from 'app-context/services/metering/MeteringIssueService';
import TopUpService from 'app-context/services/metering/TopUpService';
import OrganisationService from 'components/organization/logic/OrganisationService';
import { notify } from 'events/notification-events';
import { action, makeObservable, observable } from 'mobx';

import { MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import RootStore from '../';
import DeviceService from '../../services/master-data/DeviceService';
import BaseStore from '../BaseStore';
import MeteringIssueStore from '../metering/metering-issues/MeteringIssueStore';
import MutingRuleService from 'app-context/services/metering/MutingRuleService';

export default class MeterStore extends BaseStore<MeterType> {
  public rootStore: RootStore;
  public meteringIssueStore: MeteringIssueStore;

  public deviceService: DeviceService;
  public measurementService: MeasurementService;
  public topUpService: TopUpService;
  public meteringIssueService: MeteringIssueService;
  public mutingRuleService: MutingRuleService;
  public organisationService: OrganisationService;

  public organisationCfg: OrganizationConfigType | undefined;
  public models: ModelType[] | undefined;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    this.meteringIssueStore = new MeteringIssueStore(rootStore);

    this.deviceService = new DeviceService(rootStore.applicationStore);
    this.measurementService = new MeasurementService(rootStore.applicationStore);
    this.topUpService = new TopUpService(rootStore.applicationStore);
    this.meteringIssueService = new MeteringIssueService(rootStore.applicationStore);
    this.mutingRuleService = new MutingRuleService(rootStore.applicationStore)
    this.organisationService = new OrganisationService(rootStore.applicationStore);

    makeObservable(this, {
      meteringIssueStore: observable,
      organisationCfg: observable,
      models: observable,

      setCfg: action,
      initMeterStore: action
    });
  }

  setCfg = (cfg: OrganizationConfigType, models: ModelType[]) => {
    this.organisationCfg = cfg;
    this.models = models;
  };

  initMeterStore = async () => {
    if (!this.organisationCfg || !this.models) {
      const { tenantReducer, getTranslation } = this.rootStore.applicationStore;
      const { getOrganisationConfig } = this.organisationService;
      const { getMeterModels } = this.rootStore.configStore.configService;

      Promise.all([
        getOrganisationConfig(tenantReducer.organization?.organizationId || '', tenantReducer.tenant?.id || ''),
        getMeterModels()
      ])
        .then((res) => this.setCfg(...res))
        .catch((error) =>
          notify.error({
            content: getTranslation('general.get_cfg_fail'),
            error
          })
        );
    }
  };
}
