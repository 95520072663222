import { action, makeObservable, observable } from 'mobx';

export default class FilterStore<Q> {
  public queryParams: Q;

  constructor(queryParams: Q) {
    this.queryParams = queryParams;

    makeObservable(this, {
      queryParams: observable,

      setQueryParam: action,
      resetAll: action
    });
  }

  setQueryParam = (val: Partial<Q>) => {
    this.queryParams = { ...this.queryParams, ...val };
  };

  resetAll = () => {
    Object.keys(this.queryParams).forEach((key) => {
      // @ts-ignore
      this.queryParams[key] = undefined;
      // Above we mutated in a way which doesn't trigger the observable, so creating a new object here
      this.queryParams = { ...this.queryParams };
    });
  };
}
