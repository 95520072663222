import React from 'react';

import { CollectionCaseRowType, CollectionCaseType } from '@zf/api-types/collection-case';
import { writeOffHandlingType } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import CheckBox from '../../components/input/CheckBox';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { markCollectionCaseClosed } from './collection-case';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  collectionCase?: CollectionCaseType;
  selectedRows?: CollectionCaseRowType[];
  onComplete?: (updatedRows: CollectionCaseType[]) => void;
  refreshCounts?: () => void;
};

type StateValues = {
  writeOffFees: boolean;
  writeOffHandlingType: writeOffHandlingType;
};

export type MarkClosedRequestType = StateValues & {
  id: string;
  _etag: string;
};

type DispatchValues = Partial<StateValues>;

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, collectionCase, selectedRows, onComplete, refreshCounts } = props;
  const stateReducer = createStateReducer<StateValues, DispatchValues>();
  const [state, dispatch] = React.useReducer(stateReducer, {
    writeOffFees: false,
    writeOffHandlingType: '' as writeOffHandlingType
  });

  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(state.writeOffFees && !state.writeOffHandlingType);
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(state)]);

  let casesToHandle: CollectionCaseType[] = [];
  if (selectedRows) {
    casesToHandle = selectedRows.map((row) => row.__entity);
  } else if (collectionCase) {
    casesToHandle = [collectionCase];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newCases = await Promise.all(
          casesToHandle.map((c) => {
            const apiFriendlyValues: MarkClosedRequestType = {
              id: c.id,
              writeOffFees: state.writeOffFees,
              writeOffHandlingType: state.writeOffHandlingType,
              _etag: c._etag
            };
            return markCollectionCaseClosed(apiFriendlyValues, tenantReducer, i18n.lang);
          })
        );

        if (onComplete) onComplete(newCases);
        if (refreshCounts) refreshCounts();

        notify.success({
          content: i18n.getTranslation('actions.collection_case.mark_closed_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.collection_case.mark_closed_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>
        {casesToHandle.length > 1
          ? i18n.getTranslation('actions.collection_case.mark_closed_paragraph_multi')
          : i18n.getTranslation('actions.collection_case.mark_closed_paragraph', {
              caseName: casesToHandle[0].workflowName
            })}
      </Paragraph>
      <InputContainer>
        <CheckBox id="write_off_fees" checked={state.writeOffFees} onChange={(val) => dispatch({ writeOffFees: val })}>
          {i18n.getTranslation('actions.collection_case.writeoff_fees')}
        </CheckBox>
        <SimpleDropdown
          id="parameters.collection_writeoff_handling"
          onChange={(val) => dispatch({ writeOffHandlingType: val[0] })}
          values={enumReducer.getEnum<writeOffHandlingType>('writeOffHandlingType')}
          selectedValues={[state.writeOffHandlingType]}
          placeholder={i18n.getTranslation('parameters.collectionWriteOffHandling')}
          error={state.writeOffFees && !state.writeOffHandlingType}
          clear
        />
      </InputContainer>
    </>
  );
});
