import React from 'react';

import StellaMultiLineInput, {
  MultilineInputProps
} from '@zf/stella-react/src/atoms/InputField/stella-multi-line-input';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import AccessControl from '../Permissions/AccessControl';

export default function MultiLineInput(props: MultilineInputProps) {
  const { id, value } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{value}</Paragraph>}>
      <StellaMultiLineInput {...props} />
    </AccessControl>
  );
}
