import { observer } from 'mobx-react';
import React from 'react';

import { formatDateForExport } from '@zf/utils/src/date';

import useCurrent from '../../../app-context/hooks/use-current';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import ExportToExcel from './ExportToExcel';

type Props = {
  outgoingBankingTransactionId?: string;
};

const OutgoingBankingTransactionExport = (props: Props) => {
  const { applicationStore, outgoingBankingTransactionsStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { getOutgoingBankingTransactionsExport } = outgoingBankingTransactionsStore.outgoingBankingTransactionsService;
  const { myCurrent } = useCurrent('outgoing_banking_transaction');
  const selectedIds = props.outgoingBankingTransactionId
    ? [props.outgoingBankingTransactionId]
    : myCurrent.listPage.selectedRows.map((r) => r.__id);

  const getExportArray = async () => {
    try {
      const exportArray: any[][] = [];

      exportArray.push([
        getTranslation('banking_transaction.identification'),
        getTranslation('banking_transaction.transaction_type'),
        getTranslation('banking_transaction.transaction_status'),
        getTranslation('banking_transaction.collection_date'),
        getTranslation('banking_transaction.transaction_total_amount'),

        getTranslation('payments.company_bank_account_holder'),
        getTranslation('payments.company_bank_account_iban'),

        getTranslation('banking_transaction.mutation_type'),
        getTranslation('banking_transaction.mutation_status'),
        getTranslation('incoming_banking_transactions.mutation_amount'),
        getTranslation('outgoing_mutations.descr'),

        getTranslation('customer.customer_id'),
        getTranslation('customer.customer_name'),
        getTranslation('payments.customer_iban'),

        getTranslation('property_groups.name'),

        getTranslation('invoice.invoiceNum'),
        getTranslation('invoice.invoice_date'),
        getTranslation('invoice.invoice_due'),
        getTranslation('invoice.amount'),
        getTranslation('invoice.invoicetype'),
        getTranslation('invoice.period_start'),
        getTranslation('invoice.period_end')
      ]);

      const excelData = await getOutgoingBankingTransactionsExport(selectedIds);

      exportArray.push(
        ...excelData.map((entry) => {
          const values = Object.values(entry);

          values[1] = getEnumTranslation('outgoingBankingTransactionType', values[1] as string);
          values[2] = getEnumTranslation('outgoingBankingTransactionStatus', values[2] as string);
          values[3] = formatDateForExport(values[3] as string);
          values[7] = getEnumTranslation('outgoingMutationType', values[7] as string);
          values[8] = getEnumTranslation('outgoingMutationStatus', values[8] as string);
          //@ts-ignore
          values[14] = values[14].map((pg) => pg.name).join(',') || '';
          values[16] = formatDateForExport(values[16] as string);
          values[17] = formatDateForExport(values[17] as string);
          values[18] = values[9] as string;
          values[19] = getEnumTranslation('invoiceType', values[19] as string);
          values[20] = formatDateForExport(values[20] as string);
          values[21] = formatDateForExport(values[21] as string);

          return values;
        })
      );

      return exportArray;
    } catch (error) {
      notify.error({
        content: getTranslation('actions.export_excel_fail'),
        error
      });
    }
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      key="outgoing-banking-transaction-export"
      disabled={selectedIds.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_outgoing_banking_transactions')}
      ws_name={getTranslation('outgoing_banking_transactions.outgoing_banking_transactions')}
    />
  );
};

export default observer(OutgoingBankingTransactionExport);
