import React from 'react';
import { VariableSizeList } from 'react-window';

import { useScaleTranslator } from '@zf/hooks/src/useScaleTranslator';

function dynamicRowHeightCacheReducer(
  state: { [key: string]: number },
  action:
    | {
        type: 'update';
        index: number | string;
        height: number;
      }
    | {
        type: 'invalidate';
        index: number | string;
      }
    | {
        type: 'clear';
      }
    | {
        type: 'force_refresh';
      }
) {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        __lastUpdate: Date.now(),
        [action.index]: action.height
      };
    case 'invalidate': {
      const stateClone = { ...state };
      delete stateClone[action.index];
      return stateClone;
    }
    case 'clear':
      return {
        __lastUpdate: Date.now()
      };
    case 'force_refresh':
      return {
        ...state,
        __lastUpdate: Date.now()
      };
  }

  // Unknown action...
  return state;
}

const TABLE_REFRESH_DEBOUNCE_TIMEOUT = 100;

export default function useDynamicRowHeightCache<T>(rows: T[], dynamicRowHeight: boolean, clearOnChange: boolean) {
  const listRef = React.useRef<VariableSizeList>(null);
  const [dynamicRowHeightCache, dispatchDynamicHeight] = React.useReducer(dynamicRowHeightCacheReducer, {
    __lastUpdate: Date.now()
  });
  const scaleTranslator = useScaleTranslator();

  React.useEffect(() => {
    if (!dynamicRowHeight) return;

    if (clearOnChange) {
      dispatchDynamicHeight({ type: 'clear' });
    } else {
      dispatchDynamicHeight({ type: 'force_refresh' });
    }
  }, [rows]);

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
      }
    }, TABLE_REFRESH_DEBOUNCE_TIMEOUT);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [scaleTranslator]);

  React.useEffect(() => {
    if (!dynamicRowHeight || !listRef.current) return;

    const timeout = window.setTimeout(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
      }
    }, TABLE_REFRESH_DEBOUNCE_TIMEOUT);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [dynamicRowHeightCache.__lastUpdate]);

  return {
    listRef,
    scaleTranslator,
    dynamicRowHeightCache,
    dispatchDynamicHeight
  };
}
