import { PagedResponseType } from '@zf/api-types/api';
import {
  AddCustomerBankAccountRequestType,
  CustomerBankAccountType,
  UpdateCustomerBillingDetailsRequestType
} from '@zf/api-types/billing/customer-bank-account';
import { LocalAddressType } from '@zf/api-types/general';
import { meterTypeGroup } from '@zf/api-types/local-enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { MeterType } from '@zf/api-types/master-data/meter';
import { PaymentParametersType } from '@zf/api-types/parameters';
import { PaymentTermType } from '@zf/api-types/product';
import { TransActionsForCustomerType, TransactionType } from '@zf/api-types/transactions';

import BaseService from '../../../app-context/services/BaseService';
import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { METHODS } from '../../../utils/request';
import { CustomerGroupType } from '@zf/api-types/master-data/customer-group';

export default class CustomerService extends BaseService {
  constructor(endpoint: string, applicationStore: ApplicationStore) {
    super(endpoint, applicationStore);
  }

  getBankAccounts = async (customerId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<CustomerBankAccountType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/CustomerBankAccounts/c/${customerId}`
        }
      })
    ).data;
  };

  getCustomerGroups = async () => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<CustomerGroupType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/md/customerGroups'
        }
      })
    ).data;
  };

  getMetersForCustomer = async (customerId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeterType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/md/meters`,
          query: { customerId, meterTypeGroup: meterTypeGroup.prepayment }
        }
      })
    ).data;
  };

  getTransactions = async (customerId: string, timestampRefresh: string) => {
    return (
      await this.applicationStore.sendRequest<TransActionsForCustomerType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/Transactions/c/${customerId}/latest`,
          query: { timestamp: timestampRefresh }
        }
      })
    ).data;
  };

  getTransactionsForExport = async (customerId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<TransactionType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/Transactions`,
          query: { customeruuid: customerId }
        }
      })
    ).data;
  };

  getCustomerById = async (customerId: string) => {
    return (
      await this.applicationStore.sendRequest<CustomerType>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${customerId}`
        }
      })
    ).data;
  };

  paymentTerms = async () => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<PaymentTermType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/PaymentTerms'
        }
      })
    ).data;
  };

  paymentParameters = async () => {
    return (
      await this.applicationStore.sendRequest<PaymentParametersType>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/Parameters/payment'
        }
      })
    ).data;
  };

  changeCustomerBankingDetails = async (
    newDetails: UpdateCustomerBillingDetailsRequestType,
    customerId: string | undefined
  ) => {
    return (
      await this.applicationStore.sendRequest<CustomerType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${customerId}/billingdetails`,
          data: newDetails
        }
      })
    ).data;
  };

  updateCustomerBankAccount = async (id: string, updatedAccount: AddCustomerBankAccountRequestType) => {
    return (
      await this.applicationStore.sendRequest<CustomerBankAccountType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/CustomerBankAccounts/${id}/update`,
          data: updatedAccount
        }
      })
    ).data;
  };

  addBankAccountRequest = async (apiFriendlyValues: AddCustomerBankAccountRequestType, customerId: string) => {
    return (
      await this.applicationStore.sendRequest<CustomerBankAccountType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/CustomerBankAccounts/c/${customerId}/add`,
          data: apiFriendlyValues
        }
      })
    ).data;
  };

  updateBankAccountRequest = async (apiFriendlyValues: AddCustomerBankAccountRequestType, bankAccountId: string) => {
    return (
      await this.applicationStore.sendRequest<CustomerBankAccountType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/CustomerBankAccounts/${bankAccountId}/update`,
          data: apiFriendlyValues
        }
      })
    ).data;
  };

  deleteBankAccountRequest = async (customerBankAccountId: string) => {
    return (
      await this.applicationStore.sendRequest<CustomerBankAccountType>({
        request: {
          method: METHODS.DELETE,
          endpoint: `/bill/CustomerBankAccounts/${customerBankAccountId}/delete`
        }
      })
    ).data;
  };

  updateContractorInvoiceAddress = async (contractorId: string, newAddress: LocalAddressType) => {
    await this.applicationStore.sendRequest<CustomerType>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/Customers/${contractorId}/invoiceaddress`,
        data: newAddress
      }
    });
  };
}
