import ResultsCounter from '@zf/heat-erp/src/components/Dropdown/ResultsCounter';
import classNames from 'classnames';
import React from 'react';

import { Paragraph } from '../../Paragraph';
import { getShownValues } from '../dropdown';
import css from '../dropdown.module.scss';
import { DropdownValuesType } from '../StellaDropdown';

export type shownValueType<T> = { value: DropdownValuesType<T>; index: number };

type Props<T> = {
  values: DropdownValuesType<T>[];
  selectedValues: T[];
  focusedIndex: number | undefined;
  emptyText: string;
  searchValue: string | undefined;
  viewType: 'search' | 'dropdown';
  setFocusedIndex: (value: number | undefined) => void;
  onClickItem: (i: number) => void;
};

//
export default function SimpleDropdownList<T>(props: Props<T>) {
  const { values, viewType, selectedValues, focusedIndex, emptyText, searchValue, setFocusedIndex, onClickItem } =
    props;

  // We need this to avoid random scrolling when hovering with our cursor
  const [mouseOver, setMouseOver] = React.useState(false);

  let shownValues: shownValueType<T>[] = [];

  if (values) {
    if (viewType === 'search') {
      // Filtering is already done in API call
      shownValues = values.map((value, i) => {
        return { value, index: i };
      });
    } else {
      shownValues = getShownValues<T>(values, searchValue);
    }
  }

  React.useEffect(() => {
    if (!mouseOver && focusedIndex) {
      const listItem = document.getElementById(`listItem-${focusedIndex}`);
      if (listItem) listItem.scrollIntoView(true);
    }
  }, [focusedIndex]);

  return (
    //eslint disable-next-line jsx-a11y/no-static-element-interactions
    <div>
      <ResultsCounter shownValues={shownValues} focusedIndex={focusedIndex} emptyText={emptyText} />
      <div
        className={css['dropdown-items']}
        onFocus={() => {}}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        {shownValues && shownValues.length > 0 ? (
          shownValues.map(({ value, index }, j) => {
            const isSelected = selectedValues.includes(value.value);

            return (
              <div
                id={`listItem-${j}`}
                testing-value={value.value}
                className={classNames(css['dropdown-item'], {
                  [css['selected']]: isSelected,
                  [css['focus']]: j === focusedIndex
                })}
                onKeyDown={() => {}}
                onMouseOver={() => setFocusedIndex(j)}
                onFocus={() => setFocusedIndex(j)}
                key={index}
              >
                <label htmlFor={value.text} id={'label' + index} className={classNames(css['dropdown-label'])}>
                  <input
                    id={value.text}
                    className={classNames(css['dropdown-input'])}
                    role="option"
                    tabIndex={-1}
                    aria-selected={isSelected}
                    type="radio"
                    value={value.value as any}
                    onClick={() => onClickItem(index)}
                  />

                  {value.text}
                </label>
              </div>
            );
          })
        ) : (
          <div className={css['dropdown-item']}>
            <Paragraph>{emptyText}</Paragraph>
          </div>
        )}
      </div>
    </div>
  );
}
