import { PagedResponseType } from '@zf/api-types/api';
import { CollectionCaseType } from '@zf/api-types/collection-case';
import { exportStatus, paymentProcessStatus } from '@zf/api-types/enums';
import { CreateExportJobType } from '@zf/api-types/export-job';
import {
  ApproveInvoiceType,
  ChangePaymentDetailsRequest,
  DeleteOrCreditInvoiceType,
  InvoiceRowType,
  InvoiceType,
  InvoiceUBLValidationErrorResponseType,
  MarkAsPaidInvoiceType,
  MarkAsSentInvoiceType,
  SendInvoiceType
} from '@zf/api-types/invoice';
import { TransactionType } from '@zf/api-types/transactions';

import BaseService from '../../../app-context/services/BaseService';
import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { createHeader, METHODS } from '../../../utils/request';
import InvoiceStore from '../stores/InvoiceStore';

export default class InvoiceApiService extends BaseService {
  private invoiceStore: InvoiceStore;

  constructor(invoiceStore: InvoiceStore, applicationStore: ApplicationStore) {
    super('/bill/invoices/', applicationStore);

    this.invoiceStore = invoiceStore;
  }

  approveInvoice = async (value: ApproveInvoiceType, invoiceDate: string) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: value.id ? `${this.endpoint}${value.id}/approve` : `${this.endpoint}/all/approve`,
          data: {
            invoiceDate
          }
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  deleteInvoice = async (value: DeleteOrCreditInvoiceType) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.DELETE,
          endpoint: `${this.endpoint}/${value.id}`
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  creditInvoice = async (value: DeleteOrCreditInvoiceType) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${value.id}/credit`
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  sendInvoice = async (value: SendInvoiceType) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${value.id}/send`
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  sendInvoicePostal = async (value: MarkAsSentInvoiceType) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${value.id}/sendviapostal`
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  markAsPaidInvoice = async (value: MarkAsPaidInvoiceType, paidAmount: number) => {
    const remainingAmount = paidAmount || value.remainingAmount;

    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${value.id}/markpaid`,
          data: { paidAmount: remainingAmount }
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  markAsSentInvoice = async (value: MarkAsSentInvoiceType) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${value.id}/marksent`
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  regenerateInvoice = async (value: string) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${value}/regenerate`
        }
      })
    ).data;
  };

  getInvoiceById = async (invoiceId: string) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${invoiceId}`
        }
      })
    ).data;
  };

  getTransactionById = async (transactionId: string) => {
    return (
      await this.applicationStore.sendRequest<TransactionType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/Transactions/${transactionId}`
        }
      })
    ).data;
  };

  getInvoiceForTransaction = async (transactionId: string) => {
    const transaction = await this.getTransactionById(transactionId);

    if (!transaction?.referenceDetails?.transactionReferenceId) return null;

    return this.getInvoiceById(transaction.referenceDetails.transactionReferenceId);
  };

  changePaymentMethod = async (apiFriendlyValues: ChangePaymentDetailsRequest) => {
    const { id } = this.invoiceStore.selectedInvoice.invoice;

    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${id}/changepaymentdetails`,
          data: apiFriendlyValues
        }
      })
    ).data;
  };

  changeAdvanceAmount = async (newAdvanceAmountInclVAT: number) => {
    const { id } = this.invoiceStore.selectedInvoice.invoice;

    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${id}/changenewadvanceamount`,
          data: { newAdvanceAmountInclVAT }
        }
      })
    ).data;
  };

  changeExportStatus = async (invoiceId: string, exportStatus: exportStatus) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${invoiceId}/changeexportstatus`,
          data: { exportStatus }
        }
      })
    ).data;
  };

  bulkExportStatus = async (invoiceuuids: Array<string>, exportStatus: exportStatus) => {
    return (
      await this.applicationStore.sendRequest<InvoiceType[]>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}bulk/changeexportstatus`,
          data: { exportStatus, invoiceuuids }
        }
      })
    ).data;
  };

  assignToCase = async (invoice: InvoiceType, flowId: string) => {
    return (
      await this.applicationStore.sendRequest<CollectionCaseType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/CollectionCases/invoice/${invoice.id}/submit`,
          query: {
            workflowId: flowId
          }
        },
        customHeaders: createHeader({
          'If-Match': invoice._etag
        })
      })
    ).data;
  };

  exportInvoice = async (selectedRows: InvoiceRowType[]) => {
    const exportData: CreateExportJobType = {
      exportCategoryType: 'invoice',
      items: selectedRows.map((row) => {
        return { entitySubjectSubType: row.__entity.type, entitySubjectId: row.__id };
      })
    };

    return (
      await this.applicationStore.sendRequest<InvoiceType>({
        request: {
          method: METHODS.POST,
          endpoint: '/logs/exportjob/enqueue',
          data: exportData
        }
      })
    ).data;
  };

  getInvoicesForCustomerAndContract = async (customerId: string, contractId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<InvoiceType>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}c/${customerId}/${contractId}`
        }
      })
    ).data.results;
  };

  getLastPaidInvoiceForCustomer = async (customerId: string) => {
    const invoices = (
      await this.applicationStore.sendRequest<PagedResponseType<InvoiceType>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint,
          query: {
            customerId,
            orderBy: '-PeriodEndDateTime'
          }
        }
      })
    ).data.results;

    const lastPaidInvoice = invoices.find(
      (i) => this.invoiceStore.getPaymentStatus(i).status === paymentProcessStatus.paid
    );

    return lastPaidInvoice;
  };

  ///// UBL INVOICE EXPORT
  ///1
  formatUBLSingle = async (invoiceId: string, markedAsExported: boolean) => {
    return await this.applicationStore.sendRequest<string>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/Integration/export/invoice/ublformat/single`,
        data: {
          invoiceId: invoiceId,
          markedAsExported: markedAsExported
        }
      }
    });
  };
  ///2
  formatUBLBulk = async (invoiceIds: string[], markedAsExported: boolean) => {
    return await this.applicationStore.sendRequest<string>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/Integration/export/invoice/ublformat/bulk`,
        data: {
          invoiceIds: invoiceIds,
          markedAsExported: markedAsExported
        }
      }
    });
  };
  ///3
  formatUBLValidate = async (invoiceIds: string[]) => {
    return await this.applicationStore.sendRequest<InvoiceUBLValidationErrorResponseType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/Integration/export/invoice/ublformat/validate`,
        data: {
          invoiceIds: invoiceIds
        }
      }
    });
  };
}
