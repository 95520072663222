import React from 'react';

import { ZFErrorType } from '@zf/api-types/general';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../app-context';
import Button from '../Button/Button';
import css from './error-body.module.scss';

export type PropsType = {
  error: ZFErrorType | null;
  showButton: boolean;
};

export default function ErrorBody(props: PropsType) {
  const { error, showButton } = props;
  const { i18n } = useAppContext();

  return (
    <div>
      <Heading headingType="h5" className={css['error-spacing']}>
        {error ? error.message : i18n.getTranslation('errors.error_occured')}
      </Heading>

      {showButton && (
        <Button
          id="return-btn"
          onClick={() => {
            window.location.href = '/';
          }}
        >
          {i18n.getTranslation('errors.return')}
        </Button>
      )}
    </div>
  );
}
