import React from 'react';

import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { useAppContext } from '../../app-context';
import useSingleAPI from '../../hooks/useSingleAPI';
import SimpleDropdown from '../Lang/SimpleDropdown';
import { CountryInputProps } from './country-input';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

type Props = {
  countries: DropdownValuesType<string>[];
};

export default function CountryInputRequest(props: Props & CountryInputProps) {
  const {
    id,
    placeholder,
    selectedValues,
    disabled = false,
    multiSelect = false,
    clear = true,
    countries,
    error,
    className,
    onChange,
    onFocus,
    ...otherProps
  } = props;

  const { tenantReducer } = useAppContext();
  const { organization } = tenantReducer.state;

  const responseOrg = useSingleAPI<OrganizationConfigType>({
    request: {
      endpoint: '/cfg/Organizations/',
      selector: organization?.organizationId
    }
  });

  React.useEffect(() => {
    if (
      (selectedValues.length === 0 || (selectedValues.length === 1 && selectedValues[0] === '')) &&
      responseOrg.result &&
      !(responseOrg.result instanceof Promise) &&
      responseOrg.result.data &&
      responseOrg.result.data.address
    ) {
      const orgCountry = responseOrg.result.data.address.country;
      if (orgCountry) {
        const countryEnumIndex = countries.findIndex((country) => country.value === orgCountry);
        if (countryEnumIndex > -1) {
          onChange([orgCountry]);
        }
      }
    }
  }, [responseOrg.result]);

  return (
    <SimpleDropdown
      id={id}
      placeholder={placeholder}
      selectedValues={selectedValues}
      values={countries}
      disabled={disabled}
      multiSelect={multiSelect}
      clear={clear}
      error={error}
      className={className}
      onChange={onChange}
      onFocus={onFocus}
      {...otherProps}
    />
  );
}
