import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../app-context';
import LocationAutoFill from '../../../components/Autofills/LocationAutoFill';
import PropertyGroupAutoFill from '../../../components/Autofills/PropertyGroupAutoFill';
import Select from '../../../components/input/Select';
import { SelectValueType } from '../../../design-system/ComponentSets/Select/Select';
import { InstallMeterValidatorValuesType } from './InstallMeterWizard';

type Props = {
  values: InstallMeterValidatorValuesType;
  didSubmitBefore: boolean;
  onFocusStep: (step: string) => void;
  setValue: (
    value: Partial<InstallMeterValidatorValuesType>,
    initialApiSet?: boolean | undefined,
    backup?: boolean | undefined
  ) => void;
};

type LocationOrPropertyType = 'location' | 'property_group';

export default function MeterLocationSection(props: Props) {
  const { onFocusStep, setValue, values, didSubmitBefore } = props;
  const { i18n } = useAppContext();

  const handleFocus = () => onFocusStep('locations');

  const renderAutoFill = (locationType: LocationOrPropertyType) => {
    if (locationType === 'location') {
      return (
        <LocationAutoFill
          id="select-location"
          key="select-location"
          initialValue={values.location?.address.localizedDisplay || ''}
          onChange={(value) => setValue({ location: value[0] })}
          selectedValues={[values.location ? values.location.id : '']}
          onFocus={handleFocus}
          error={!values.location}
        />
      );
    } else {
      return (
        <PropertyGroupAutoFill
          id="select-property-group"
          key="select-property-group"
          initialValue={values.propertyGroup ? values.propertyGroup.name : ''}
          onChange={(value) => setValue({ propertyGroup: value[0] })}
          selectedValues={[values.propertyGroup ? values.propertyGroup.id : '']}
          onFocus={handleFocus}
          error={!values.propertyGroup}
        />
      );
    }
  };

  const selectValues: SelectValueType<LocationOrPropertyType>[] = [
    {
      icon: 'location',
      value: 'location' as LocationOrPropertyType,
      text: i18n.getTranslation('install_meter.wizard.location')
    },
    {
      icon: 'propertygroup',
      value: 'property_group' as LocationOrPropertyType,
      text: i18n.getTranslation('property_groups.property_group')
    }
  ];

  const renderLocationSection = () => {
    return (
      <React.Fragment key="selectLocationFragment">
        <Heading headingType="h3">{i18n.getTranslation('install_meter.wizard.meter_install_where')}</Heading>
        <br />
        <InputContainer grid={false}>
          <Select
            id="location-type"
            onChange={(val) => setValue({ locationType: val[0] })}
            selectedValues={[values.locationType]}
            values={selectValues}
          />
        </InputContainer>
        {renderAutoFill(values.locationType)}
      </React.Fragment>
    );
  };

  return (
    <WizardSection disabled={didSubmitBefore}>
      <WizardHeader>{i18n.getTranslation('install_meter.steps.location')}</WizardHeader>
      {renderLocationSection()}
    </WizardSection>
  );
}
