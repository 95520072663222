import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { isMaxDate, isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import DateRangePicker from '../../../../../../components/input/DateRangePicker';
import { useStore } from '../../../../../../hooks/useStore';
import css from './edit-tariff-period.module.scss';

export type EditTariffPeriodType = {
  startDateTime: string;
  endDateTime: string;
};

type Props = {
  values: EditTariffPeriodType;
  showErrors?: boolean;
  setValue: (value: Partial<EditTariffPeriodType>) => void;
};

const EditTariffPeriod = (props: Props) => {
  const { values, showErrors = false, setValue } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div className={css['tariff-period']}>
      <Heading headingType="h3">{getTranslation('billing_tariff.tariff_period')}</Heading>
      <DateRangePicker
        id="tariff-period"
        startDate={moment(values.startDateTime)}
        endDate={moment(values.endDateTime)}
        setDates={(dates) =>
          setValue({
            startDateTime: dates[0]?.toISOString() || MIN_DATE,
            endDateTime: dates[1]?.toISOString() || MAX_DATE
          })
        }
        error={
          !moment(values.startDateTime).isBefore(values.endDateTime) ||
          (showErrors && (isMinDate(values.startDateTime) || isMaxDate(values.endDateTime)))
        }
      />
    </div>
  );
};

export default observer(EditTariffPeriod);
