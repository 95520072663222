import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useReducer, useState } from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { TabSlider } from '../../../../design-system/Components';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import css from './change-customer-group-dialog.module.scss';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import CustomerGroupsAutoFill from '../AutoFills/CustomerGroupsAutoFill';
import { CustomerGroupType } from '@zf/api-types/master-data/customer-group';
import InputField from 'components/input/InputField';
import { METHODS } from 'utils/request';
import { useAppContext } from 'app-context';
import {
  addCustomersToGroupBulk,
  addCustomersToGroupSingle
} from '../../../customer/list-page/AddCustomersToGroup.API';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { CustomerRowType } from '@zf/api-types/master-data/customer';
import { InfoBanner } from 'design-system/ComponentSets';
import Interweave from 'interweave';
import Customer from 'features/customer/models/Customer';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  onComplete?: Function;
  customer?: Customer;
  selectedRows?: CustomerRowType[];
  withCustomerList?: boolean;
};

type ActionType = 'existing' | 'new';

export type CustomerGroupState = {
  type: string;
  targetCustomerGroupId: string;
  customerGroupName: string;
  isDuplicateGroupName: boolean;
  originGroupHasSettings: boolean;
  targetGroupHasSettings: boolean;
  originCustomerGroupId: string | undefined;
};

const ChangeCustomerGroupDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { validationRef, onComplete, customer, withCustomerList, selectedRows } = props;
  const { applicationStore } = useStore();
  const { tenantReducer, i18n } = useAppContext();
  const { getTranslation, sendRequest } = applicationStore;
  const [customerGroups, setCustomerGroups] = useState<DropdownValuesType<CustomerGroupType>[]>();

  const customerId = customer?.customer.id;

  const stateReducer = createStateReducer<CustomerGroupState, Partial<CustomerGroupState>>();
  const [state, setState] = useReducer(stateReducer, {
    type: 'existing',
    targetCustomerGroupId: '',
    customerGroupName: '',
    isDuplicateGroupName: false,
    originGroupHasSettings: false,
    targetGroupHasSettings: false,
    originCustomerGroupId: customer?.customer?.customerGroup ? customer.customer.customerGroup.id : undefined
  });

  const [tabItems] = useState([
    {
      id: 'existing',
      title: getTranslation('customer_groups.existing_group'),
      disabled: false
    },
    {
      id: 'new',
      title: getTranslation('customer_groups.new_group'),
      disabled: false
    }
  ]);

  const checkGroupNameExits = async (customerGroupName: string) => {
    return (
      customerGroups?.findIndex(({ text }) => {
        return text.toLowerCase() === customerGroupName.toLowerCase();
      }) !== -1
    );
  };

  const checkHasSettings = (settingsObj: {} | undefined) => {
    //if all the settings available are null that means there are no settings
    if (settingsObj)
      if (Object.values(settingsObj).every((setting) => setting === null)) {
        return false;
      } else {
        return true;
      }
  };

  const getOriginCustomerGroupSettings = (originId: string | undefined) => {
    //No customer group = no customer group settings
    if (originId) {
      // get the details of the selected customer's customerGroup
      // by comparing its id agains the list of customer groups

      const customerGroupDetails = customerGroups?.find((cgD) => {
        return cgD.value.id === originId;
      });

      return customerGroupDetails?.value.settings;
    }
  };

  const checkBatchHasSettings = () => {
    //If we're in batch mode
    if (!customer && selectedRows && selectedRows.length > 0) {
      //Look through all the selected row's customerGroup id's, and return them (if any)
      const idsWithGroups = selectedRows.map((row) => {
        if (row.__customerEntity.customerGroup?.id != null) {
          return row.__customerEntity.customerGroup.id;
        }
        return undefined;
      });

      // Get those id's 'settingsObject' AND check if those have settings

      const batchHasSettings = idsWithGroups.map((id) => {
        return checkHasSettings(getOriginCustomerGroupSettings(id));
      });

      //if any of them HAS settings, the state is updated
      if (batchHasSettings.includes(true)) setState({ originGroupHasSettings: true });
    }
  };

  const validate = () => {
    if (validationRef && validationRef.current) {
      validationRef.current.setIsError(
        (!state.customerGroupName && !state.targetCustomerGroupId) || state.isDuplicateGroupName
      );
    }
  };

  async function batchAPI(arr: string[], customerGroupId: string) {
    let init = 0;

    if (customerGroupId)
      for (let i = 0; i < arr.length / 200; i++) {
        return await addCustomersToGroupBulk(arr.slice(init, (init += 200)), customerGroupId, tenantReducer, i18n.lang);
      }
  }

  const setValues = (values: DropdownValuesType<CustomerGroupType>[]) => {
    setCustomerGroups(values);
  };

  useEffect(() => {
    validate();
  }, [state]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (state.type === 'new') {
          const customerGroup = await sendRequest<CustomerGroupType>({
            request: {
              method: METHODS.POST,
              endpoint: '/md/customergroups',
              data: {
                name: state.customerGroupName
              }
            }
          });

          if (customerGroup.data.id && onComplete) {
            notify.success({
              content: getTranslation('customer_groups.add_customer_group_success')
            });

            /**
             * @description via customer detail page
             */
            if (customerId) {
              const response = await addCustomersToGroupSingle(
                customerGroup.data.id,
                customerId,
                tenantReducer,
                i18n.lang
              );
              response &&
                notify.success({
                  content: getTranslation('customer_groups.add_customer_to_group_success')
                });
            }

            /**
             * @description via customer list page
             */
            if (withCustomerList && selectedRows && selectedRows?.length > 0) {
              if (selectedRows.length === 1) {
                const response = await addCustomersToGroupSingle(
                  customerGroup.data.id,
                  selectedRows[0].__id,
                  tenantReducer,
                  i18n.lang
                );

                response &&
                  notify.success({
                    content: getTranslation('customer_groups.add_customer_to_group_success')
                  });
              } else {
                const response = await batchAPI(
                  selectedRows.map(({ __id }: { __id: string }) => __id),
                  customerGroup.data.id
                );

                response &&
                  notify.success({
                    content: getTranslation('customer_groups.add_customers_to_group_success')
                  });
              }
            }

            /**
             * @description refresh page finally
             */
            onComplete();
          }
        }

        if (state.type === 'existing' && state.targetCustomerGroupId && onComplete) {
          /**
           * @description via customer detail page
           */
          if (customerId) {
            const response = await addCustomersToGroupSingle(
              state.targetCustomerGroupId,
              customerId,
              tenantReducer,
              i18n.lang
            );
            response &&
              notify.success({
                content: getTranslation('customer_groups.add_customer_to_group_success')
              });
          }

          /**
           * @description via customer list page
           */
          if (withCustomerList && selectedRows && selectedRows?.length > 0) {
            if (selectedRows.length === 1) {
              const response = await addCustomersToGroupSingle(
                state.targetCustomerGroupId,
                selectedRows[0].__id,
                tenantReducer,
                i18n.lang
              );

              response &&
                notify.success({
                  content: getTranslation('customer_groups.add_customer_to_group_success')
                });
            } else {
              const response = await batchAPI(
                selectedRows.map(({ __id }: { __id: string }) => __id),
                state.targetCustomerGroupId
              );

              response &&
                notify.success({
                  content: getTranslation('customer_groups.add_customers_to_group_success')
                });
            }
          }

          /**
           * @description refresh page finally
           */
          onComplete();
        }
      } catch (e) {
        notify.error({
          content: getTranslation('customer_groups.add_customer_to_group_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <div className={css['input-wrapper']}>
        <TabSlider
          id="group-type"
          tabItems={tabItems}
          selectedTabItem={state.type}
          setSelectedItem={(val) => setState({ type: val as ActionType })}
        />
      </div>
      <InputContainer>
        {state.type === 'existing' && (
          <CustomerGroupsAutoFill
            id="customer-groups"
            onChange={(val) => {
              setState({
                targetCustomerGroupId: val[0].id,
                targetGroupHasSettings: checkHasSettings(val[0].settings),
                originGroupHasSettings: checkHasSettings(getOriginCustomerGroupSettings(state.originCustomerGroupId))
              });

              checkBatchHasSettings();
            }}
            selectedValues={[state.targetCustomerGroupId]}
            setState={setState}
            setValues={setValues}
          />
        )}

        {state.type === 'new' && (
          <>
            <InputField
              id="customer-group-name"
              onChange={async (val) => {
                setState({ customerGroupName: val });
                setState({
                  isDuplicateGroupName: await checkGroupNameExits(val)
                });
              }}
              value={state.customerGroupName}
              placeholder={getTranslation('general.name')}
              error={!state.customerGroupName || state.isDuplicateGroupName}
              className={css['add-customer-group-input']}
            />

            {state.isDuplicateGroupName && (
              <Paragraph textAlign="left" color={colors['red-600']}>
                {getTranslation('customer_groups.group_name_already_exists')}
              </Paragraph>
            )}
          </>
        )}
      </InputContainer>

      {
        // (state.type !== 'new') ||
        (state.originGroupHasSettings || state.targetGroupHasSettings) && (
          <div className={css['wrapper']}>
            <InfoBanner
              color="blue"
              info={getTranslation('customer_groups.customer_this_action_might_change_contract_settings')}
              quickTipId="customer_groups.removed.tooltip"
              quickTipHTML={
                <Interweave
                  content={getTranslation('customer_groups.customer_this_action_might_change_contract_settings_tip')}
                />
              }
            />
          </div>
        )
      }
    </>
  );
});

export default observer(ChangeCustomerGroupDialog);
