import React from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../app-context';
import CustomerAutoFill from '../../../components/Autofills/CustomerAutoFill';
import { STEP_NAMES, CreatePropertyType } from './property-group-wizard';

type Props = {
  owner: CustomerType | null;
  manager: CustomerType | null;
  handleFocus: (step: string) => () => void;
  setValue: (
    value: Partial<CreatePropertyType>,
    initialApiSet?: boolean | undefined,
    backup?: boolean | undefined
  ) => void;
};

export default function RelationsSection(props: Props) {
  const { owner, manager, handleFocus, setValue } = props;
  const { i18n } = useAppContext();

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[2]} />
        {i18n.getTranslation('property_groups.relations')}
      </WizardHeader>
      <InputContainer>
        <CustomerAutoFill
          id="owner"
          key="owner"
          onChange={(value) => setValue({ owner: value[0] })}
          selectedValues={[owner ? owner.id : '']}
          placeholder={i18n.getTranslation('property_groups.search_owner')}
          onFocus={handleFocus(STEP_NAMES[2])}
        />
        <CustomerAutoFill
          id="manager"
          key="manager"
          onChange={(value) => setValue({ manager: value[0] })}
          selectedValues={[manager ? manager.id : '']}
          placeholder={i18n.getTranslation('property_groups.search_manager')}
          onFocus={handleFocus(STEP_NAMES[2])}
        />
      </InputContainer>
    </WizardSection>
  );
}
