import React from 'react';

import { CollectionCaseType } from '@zf/api-types/collection-case';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../app-context/app-context';
import useDetail from '../../../app-context/hooks/use-detail';
import { ICON_COLORS } from '../../../constants/icons';

const useCollectionCaseDetail = (entityId: string) => {
  const { entity: collectionCase, navigator } = useDetail<CollectionCaseType>({
    entityId,
    endpoint: '/bill/CollectionCases',
    domain: 'collection_case'
  });
  const { enumReducer } = useAppContext();

  const caseStatus = collectionCase ? collectionCase.status : '';

  const status = (
    <NewStatusBadge
      color={caseStatus ? ICON_COLORS[caseStatus] : ''}
      status_={caseStatus ? ` ${enumReducer.getTranslation('collectionFlowStatus', caseStatus)} ` : ''}
    />
  );

  return { collectionCase, status, navigator };
};

export default useCollectionCaseDetail;
