import BaseFormVjf, { Fields } from '../../../app-context/stores/forms/BaseFormVjf';

export enum testEmailFormFields {
  emailReceiver = 'emailReceiver'
}

interface TestEmailFormFieldTypes {
  emailReceiver: string;
}

class TestEmailForm extends BaseFormVjf<TestEmailFormFieldTypes> {
  constructor(initialValues: TestEmailFormFieldTypes) {
    super(initialValues);
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: testEmailFormFields.emailReceiver,
          label: 'emailReceiver',
          validators: [this.isRequired, this.isEmail]
        }
      ]
    };
  }
}

export default TestEmailForm;
