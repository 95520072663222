import React from 'react';
import { Text } from 'recharts';

import { colors } from '@zf/utils/src/color';

type PayloadType = {
  coordinate: number;
  index: number;
  isShow: boolean;
  offset: number;
  tickCoord: number;
  value: string;
};

type Props = {
  x?: number;
  y?: number;
  payload?: PayloadType;
};

export default function CustomXAxisTick(props: Props) {
  const { x, y, payload } = props;

  return (
    <Text x={x} y={y} style={{ fill: colors['silver-700'] }} width={20} textAnchor="middle" verticalAnchor="start">
      {payload ? payload.value : ''}
    </Text>
  );
}
