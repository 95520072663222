import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { OutgoingBankingTransactionProvider } from '../context/outgoing-transaction-detail-context';
import MutationsCard from './MutationsCard';
import OutgoingBankingTransactionDetailsCard from './OutgoingBankingTransactionDetailsCard';
import css from './outgoing-transaction-details.module.scss';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  timeStamp: string;
  setOutgoingBankingTransaction: React.Dispatch<React.SetStateAction<OutgoingBankingTransactionType | null>>;
  refresh: () => void;
};

export default function OutgoingBankingTransactionDetails(props: Props) {
  const { outgoingBankingTransaction, setOutgoingBankingTransaction } = props;

  return (
    <CardsContainer className={css['outgoing-bakking-transaction-cards-container']}>
      <OutgoingBankingTransactionProvider>
        <OutgoingBankingTransactionDetailsCard
          outgoingBankingTransaction={outgoingBankingTransaction}
          setOutgoingBankingTransaction={setOutgoingBankingTransaction}
        />
        <MutationsCard {...props} />
      </OutgoingBankingTransactionProvider>
    </CardsContainer>
  );
}
