import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';

import { InlineInputFieldInput } from '../../../../components/input/InputField';
import { dialog } from '../../../../events/dialog-events';
import { useStore } from '../../../../hooks/useStore';
import EnableDocumentGenerationDialog from './dialogs/EnableDocGenerationDialog';

const CommunicationPreferencesCardBody = () => {
  const { applicationStore, communicationStore } = useStore();
  const { communicationParametersForm } = communicationStore.settingsStore;
  const { getTranslation } = applicationStore;
  const { clickRef, onSubmit } = useDialog();

  const enabled = communicationParametersForm._get('enabled');
  const isSandboxed = communicationParametersForm._get('isSandboxed');
  const sandboxRecipientEmail = communicationParametersForm._get('sandboxRecipientEmail');

  const openEnableDocumentGenerationDialog = (enabled: boolean) => {
    const translation = applicationStore.getTranslation(`general.${enabled ? 'enable' : 'disable'}`);

    dialog.normal({
      title: `${translation}?`,
      type: 'danger',
      icon: 'info-circle',
      content: <EnableDocumentGenerationDialog ref={clickRef} enabled={enabled} />,
      buttonPositive: translation,
      onSubmit
    });
  };

  return (
    <>
      <ZFToggle
        title={getTranslation('communication.enable')}
        description={getTranslation('communication.info_enable')}
        checked={enabled}
        onChange={(val) => openEnableDocumentGenerationDialog(val)}
      />

      {enabled && (
        <>
          <ZFToggle
            title={getTranslation('communication.enable_test_mode')}
            description={getTranslation('communication.info_enable_test_mode')}
            checked={isSandboxed}
            onChange={(val) => {
              communicationParametersForm._set('isSandboxed', val);

              // Only validate email when sandboxed
              communicationParametersForm._setRules('sandboxRecipientEmail', val ? 'required|string' : '');
              communicationParametersForm._setValidators(
                'sandboxRecipientEmail',
                val ? [communicationParametersForm.isEmail] : null
              );
            }}
          />

          <InlineInputFieldInput
            id="test-email"
            placeholder={getTranslation('communication.mail_placeholder')}
            value={communicationParametersForm._get('sandboxRecipientEmail')}
            onChange={(val) => communicationParametersForm._set('sandboxRecipientEmail', val)}
            error={
              isSandboxed && (!sandboxRecipientEmail || !!communicationParametersForm._error('sandboxRecipientEmail'))
            }
            singleError={isSandboxed ? communicationParametersForm._error('sandboxRecipientEmail') : ''}
            disabled={!isSandboxed}
          />
        </>
      )}
    </>
  );
};

export default observer(CommunicationPreferencesCardBody);
