import { Configuration, EndSessionRequest, RedirectRequest } from '@azure/msal-browser';

export const MSAL_AUTH_ENDPOINT = process.env.REACT_APP_MSAL_AUTH_ENDPOINT;
export const MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID || '';
export const MSAL_SIGNUP_SIGNIN_POLICY_ID = process.env.REACT_APP_SIGNUP_SIGNIN_POLICY_ID;
export const MSAL_KNOWN_AUTHORITIES = process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES;
export const MSAL_CODE_CHALLENGE = process.env.REACT_APP_MSAL_CODE_CHALLENGE;
export const MSAL_SCOPE = process.env.REACT_APP_MSAL_SCOPE;

export const AUTH_BASE_URL = process.env.REACT_APP_IDENTITY_ENDPOINT;
export const IDENTITY_CLIENT_ID = process.env.REACT_APP_IDENTITY_CLIENT_ID || '';

if (!AUTH_BASE_URL) {
  throw new Error('No IDENTITY_ENDPOINT environment variable present!');
}

// MSAL configuration
export const loginRequest: RedirectRequest = {
  scopes: MSAL_SCOPE ? [MSAL_SCOPE] : []
};

export const logoutRequest: EndSessionRequest = {
  postLogoutRedirectUri: 'https://login.microsoftonline.com/common/oauth2/logout'
};

export const MsalConfiguration: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: MSAL_AUTH_ENDPOINT,
    redirectUri: `${window.location.origin}`,
    knownAuthorities: MSAL_KNOWN_AUTHORITIES ? [MSAL_KNOWN_AUTHORITIES] : [],
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};
