import React from 'react';

import { CardHeader } from '@zf/stella-react/src/atoms/Card';

import ConfigHelp from '../CoachMarks/config-help';

export type PropsType = {
  coachMarkTitle?: string;
  coachMarkContent?: Array<string>;
  children: React.ReactNode;
  className?: string;
  primaryText?: string;
  secondaryText?: string;
  isLoading?: boolean;
  isErrors?: boolean;
  extraRight?: React.ReactElement;
  noHTag?: boolean;
  usePopup?: boolean;
  onSecondaryClick?: () => void;
  onPrimaryClick?: () => void;
};

export default function CardHeaderWarning(props: PropsType) {
  const { coachMarkTitle = null, coachMarkContent, children, usePopup, ...otherProps } = props;

  let coachMark: JSX.Element;

  coachMarkTitle
    ? (coachMark = <ConfigHelp title={coachMarkTitle} content={coachMarkContent} usePopup={usePopup} warning />)
    : (coachMark = <></>);

  return (
    <CardHeader warning extraLeft={coachMark} {...otherProps}>
      {children}
    </CardHeader>
  );
}
