import { observer } from 'mobx-react';
import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';

import InstallMeterListItem from '../../../components/list-items/locations/InstallMeterListItem';
import AddLocationToPropertyGroupListItem from '../../../components/list-items/property-groups/AddLocationToPropertyGroupListItem';
import DeletePropertyGroupListItem from '../../../components/list-items/property-groups/DeletePropertyGroupListItem';
import FilesActions from '../../../components/units/EntityAttachments/ui/FilesActions';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { useStore } from '../../../hooks/useStore';
import { attachmentType } from '../../../components/units/EntityAttachments/logic/FilesStore';
import { entitySubjectType } from '@zf/api-types/enums';

type Props = {
  propertyGroup: PropertyGroupType;
  refresh: () => void;
};

const PropertyGroupActions = (props: Props) => {
  const { propertyGroup, refresh } = props;
  const { propertyGroupStore } = useStore();
  const { filesStore } = propertyGroupStore;
  const { attachmentState, setAttachmentState, listFiles } = propertyGroupStore.entityAttachmentFeatures;

  return (
    <>
      <FilesActions
        state={attachmentState}
        setState={setAttachmentState}
        listFiles={listFiles}
        culture={filesStore.culture}
        attachmentType={attachmentType.entityattachmentgroups}
        filesStore={filesStore}
        subjectType={entitySubjectType.propertygroup}
        enableTranslations
      >
        <AddLocationToPropertyGroupListItem propertyGroup={propertyGroup} refresh={refresh} />
        <InstallMeterListItem propertyGroupId={propertyGroup.id} type="forPropertyGroup" />
        <DeletePropertyGroupListItem propertyGroup={propertyGroup} />

        <HorizontalDivider />
      </FilesActions>
    </>
  );
};

export default observer(PropertyGroupActions);
