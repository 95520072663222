import classNames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { getTooltipContent } from '@zf/utils/src/tooltip';

import { StaticColumnProps } from './deprecated-static-column';
import css from './deprecated-table.module.scss';

type Props = {
  children: (ReactElement<StaticColumnProps> | null)[] | ReactElement<StaticColumnProps>;
  rows: Record<string, ReactNode>[];
  onRowSelect?: (index: number) => void;
  tooltipId?: string;
  selectedIndex?: number;
  showHeader?: boolean;
  rowClassName?: string;
  accent?: boolean;
  className?: string;
  cellPadding?: string;
};

export default function StaticTable(props: Props) {
  const {
    tooltipId,
    children,
    rows,
    onRowSelect,
    showHeader = true,
    rowClassName,
    selectedIndex,
    accent,
    className,
    cellPadding
  } = props;

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [lockSelect, setLockSelect] = useState(false);

  const handleMouseEnter = (rowIndex: number) => {
    return () => setHoveredIndex(rowIndex);
  };

  const handleSelectRow = (rowIndex: number) => {
    return () => {
      if (onRowSelect && !lockSelect) onRowSelect(rowIndex);
    };
  };

  const table = (
    //eslint disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames(css['table'], css['static-table'], { [css['accent']]: accent }, className)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {showHeader && (
        <div className={classNames(css['static-table-header'], { [css['accent']]: accent })}>
          {Array.isArray(children) ? (
            children.map((child, childIndex) => {
              if (!child) return null;

              return (
                <div
                  className={css['table-item-datafield']}
                  style={{
                    flex: child.props.flexWidth,
                    minWidth: child.props.minWidth,
                    paddingRight: cellPadding ? cellPadding : '2rem'
                  }}
                  key={childIndex}
                >
                  <div>{child.props.label}</div>
                </div>
              );
            })
          ) : (
            <div
              className={css['table-item-datafield']}
              style={{
                flex: children.props.flexWidth,
                minWidth: children.props.minWidth,
                paddingRight: cellPadding ? cellPadding : '2rem'
              }}
            >
              {children.props.label}
            </div>
          )}
        </div>
      )}

      <div id="table-content" className={css['static-table-content']}>
        {rows.map((row, i) => {
          return (
            <div
              id={`table-row-${i}`}
              className={classNames(css['table-item'], css['static-table-item'], rowClassName, {
                [css['hover']]: hoveredIndex === i || selectedIndex === i,
                [css['accent']]: accent
              })}
              key={i}
              onMouseEnter={handleMouseEnter(i)}
              onClick={handleSelectRow(i)}
            >
              {Array.isArray(children) ? (
                children.map((child, childIndex) => {
                  if (!child) return null;

                  return (
                    <div
                      key={childIndex}
                      className={classNames(css['table-item-datafield'], {
                        [css['hidden']]: child.props.showOnHover && hoveredIndex !== i && selectedIndex !== i
                      })}
                      style={{
                        flex: child.props.flexWidth,
                        minWidth: child.props.minWidth,
                        paddingRight: cellPadding ? cellPadding : '2rem'
                      }}
                      onMouseOver={() => {
                        if (child.props.showOnHover) setLockSelect(true);
                      }}
                      onMouseLeave={() => {
                        if (child.props.showOnHover) setLockSelect(false);
                      }}
                    >
                      {row[child.props.dataKey]}
                    </div>
                  );
                })
              ) : (
                <div
                  className={css['table-item-datafield']}
                  style={{
                    flex: children.props.flexWidth,
                    minWidth: children.props.minWidth,
                    paddingRight: cellPadding ? cellPadding : '2rem'
                  }}
                >
                  {row[children.props.dataKey]}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      {table}
      {rows.length > 0 && tooltipId && (
        <ReactTooltip
          id={tooltipId}
          className={css['simple-tooltip']}
          type="light"
          place="left"
          getContent={getTooltipContent}
        />
      )}
    </>
  );
}
