import { observer } from 'mobx-react';
import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { Paragraph } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import css from './add-edit-tariff-dialog.module.scss';

const TariffCondition = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div className={css['conditions-info']}>
      <Heading headingType="h3">{getTranslation('billing_tariff.conditions')}</Heading>
      <Label>{getTranslation('billing_tariff.conditions_info_1')}</Label>
      <Paragraph>{getTranslation('billing_tariff.conditions_info_2')}</Paragraph>
    </div>
  );
};

export default observer(TariffCondition);
