import { observer } from 'mobx-react';
import React from 'react';

import { importCategoryType } from '@zf/api-types/enums';
import { ImportJobType, OverviewPerEntityType } from '@zf/api-types/import-job';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { NavigatorType } from '../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../components/RouteWrapper';
import { SuspenseSpinner } from '../../../components/suspense';
import GenericTabContainer from '../../../components/Tab/GenericTabContainer';
import { Icon } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import Route from '../../route';
import ImportJobDetail from './detail/import-job-detail';
import ImportJobActions from './ImportJobActions';
import ImportJobValues from './measurements/import-job-values';
import ProcessingDetailsTab from './processing-details/processing-details-tab';

export type ImportJobTabContainerProps = {
  importJob: ImportJobType;
  status: JSX.Element;
  navigator: NavigatorType;
  overviewPerEntity?: OverviewPerEntityType;
  navigate: (url: string) => void;
};

const ImportJobTabContainer = (props: ImportJobTabContainerProps) => {
  const { importJob, status, navigator, overviewPerEntity = {}, navigate } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { refresh } = useRefresh();

  useBrowserTitle(
    `${getTranslation('import_jobs.import_job')} - ${importJob.fileName ? importJob.fileName : importJob.id}`
  );

  let itemTabs: TabItemType[] = [];
  let routeWrapper: React.ReactNode;

  switch (importJob.importType) {
    case importCategoryType.metering:
      itemTabs = [
        {
          id: 'detail',
          title: getTranslation('nav_detail.detail'),
          isDefault: true
        },
        {
          id: 'measurements',
          title: getTranslation('meter.measurements'),
          isScrollable: false
        }
      ];
      routeWrapper = (
        <RouteWrapper default>
          <Route Component={ImportJobDetail} path="detail" navigate={navigate} importJob={importJob} default />
          <Route Component={ImportJobValues} path="measurements" importJob={importJob} />
        </RouteWrapper>
      );
      break;

    case importCategoryType.migration: {
      itemTabs = [
        {
          id: 'detail',
          title: getTranslation('nav_detail.detail'),
          isDefault: true
        }
      ];

      const routes: JSX.Element[] = [
        <Route
          key="detail"
          Component={ImportJobDetail}
          path="detail"
          navigate={navigate}
          importJob={importJob}
          default
        />
      ];

      Object.keys(overviewPerEntity).forEach((entity) => {
        itemTabs.push({
          id: entity,
          title: getEnumTranslation('entitySubjectType', entity) || entity,
          icon:
            Object.keys(overviewPerEntity[entity].processingErrors).length > 0 ||
            Object.keys(overviewPerEntity[entity].validationErrors).length > 0 ? (
              <Icon name="exclamation" color />
            ) : undefined
        });

        const Tab = () => (
          <SuspenseSpinner>
            <ProcessingDetailsTab importJob={importJob} itemType={entity} overview={overviewPerEntity[entity]} />
          </SuspenseSpinner>
        );

        routes.push(<Route key={entity} Component={Tab} path={entity} />);
      });

      routeWrapper = <RouteWrapper default>{routes}</RouteWrapper>;
      break;
    }
  }

  return (
    <GenericTabContainer
      actions={<ImportJobActions importJob={importJob} refresh={refresh} />}
      entityId={importJob.fileName}
      status={status}
      entityType={routedEntitySubjectType.importjob}
      navigate={navigate}
      navigator={navigator}
      tabItems={itemTabs}
      iconName="import-job"
      routes={routeWrapper}
    />
  );
};

export default observer(ImportJobTabContainer);
