import React from 'react';

import { FixedCollectionChargeParametersType } from '@zf/api-types/collection-cycles';

import { useAppContext } from '../../../../../app-context/app-context';
import MoneyInput from '../../../../../components/input/MoneyInput';

type Props = {
  stepNumber: string | number;
  chargeParams: FixedCollectionChargeParametersType;
  setChargeParametersValue: (key: any, value: any) => void;
};

export default function FixedCollectionChargeParameters(props: Props) {
  const { stepNumber, chargeParams, setChargeParametersValue } = props;
  const { i18n } = useAppContext();

  return (
    <MoneyInput
      id={`amountExclVAT-${stepNumber}`}
      value={chargeParams.amountExclVAT}
      onChange={(val) => setChargeParametersValue('amountExclVAT', val)}
      placeholder={i18n.getTranslation('collection_flows.amountExclVAT')}
      error={!chargeParams.amountExclVAT}
    />
  );
}
