import clone from 'clone';

import { CreateAuthUserType, OrgUserType, TenantType, UserAuthUserType, UserRolesType } from '@zf/api-types/auth';

import { AUTH_BASE_URL } from '../../constants/authentication';
import { createHeader, METHODS, sendRequest } from '../../utils/request';
import { uiCulture } from '@zf/api-types/enums';

export const addTenantUser = async (
  apiFriendlyValues: CreateAuthUserType,
  tenant: TenantType | null,
  lang: uiCulture
) => {
  return (
    await sendRequest<string>({
      request: {
        method: METHODS.POST,
        endpoint: `${AUTH_BASE_URL}/api/UserAuth/newuser`,
        data: apiFriendlyValues
      },
      customHeaders: createHeader({
        tenant: tenant?.id
      }),
      lang
    })
  ).data;
};

export const updateAdmins = async (ids: string[], tenant: TenantType | null, lang: uiCulture) => {
  return (
    await sendRequest<any>({
      request: {
        method: METHODS.POST,
        endpoint: `${AUTH_BASE_URL}/api/UserAuth/t/${tenant?.id}/admins`,
        data: { userIds: ids }
      },
      customHeaders: createHeader({
        tenant: tenant?.id
      }),
      lang
    })
  ).data;
};

export const updateOrgUsers = async (
  orgUsers: OrgUserType[],
  orgId: string,
  tenant: TenantType | null,
  lang: uiCulture
) => {
  return (
    await sendRequest<UserAuthUserType>({
      request: {
        method: METHODS.POST,
        endpoint: `${AUTH_BASE_URL}/api/UserAuth/o/${orgId}/users`,
        data: { users: orgUsers }
      },
      customHeaders: createHeader({
        tenant: tenant?.id,
        organization: orgId
      }),
      lang
    })
  ).data;
};

export const getAPIOrgUsers = (orgId: string, userRoles: UserRolesType[]) => {
  const apiRoles: OrgUserType[] = [];

  userRoles.forEach((ur) => {
    ur.organizationRoles.forEach((or) => {
      if (or.organizationId === orgId) {
        apiRoles.push({
          userId: ur.userDetails.id,
          roleId: or.roleId
        });
      }
    });
  });

  return apiRoles;
};

export const updateOrganisationUsers = async (
  userRoles: UserRolesType[],
  organizationId: string,
  roleId: string,
  userId: string,
  useCase: 'delete' | 'add' | 'update',
  tenant: TenantType | null,
  lang: uiCulture
) => {
  // We now have the organisationRole that needs to change but we need all roles for a given organisation for the api call
  if (!organizationId) return null;
  const orgUsers = getAPIOrgUsers(organizationId, userRoles);

  // Change the found organisationRoles according to use case and given data
  if (useCase === 'delete') {
    const index = orgUsers.findIndex((ou) => ou.userId === userId);

    orgUsers.splice(index, 1);
  } else if (useCase === 'add') {
    // add
    const alreadyInOrgUsers = orgUsers.some((ou) => ou.userId === userId);

    if (!alreadyInOrgUsers) {
      orgUsers.push({
        userId,
        roleId
      });
    }
  } else {
    // Update
    const roleToUpdate = orgUsers.find((ou) => ou.userId === userId);

    if (roleToUpdate) {
      roleToUpdate.roleId = roleId;
    }
  }

  return updateOrgUsers(orgUsers, organizationId, tenant, lang);
};

export const addAdmin = async (adminIds: string[], userId: string, tenant: TenantType | null, lang: uiCulture) => {
  await updateAdmins([...adminIds, userId], tenant, lang);
};

export const deleteAdmin = async (
  adminIds: string[],
  userRole: UserRolesType,
  tenant: TenantType | null,
  lang: uiCulture
) => {
  const index = adminIds.findIndex((id) => {
    return id === userRole.userDetails.id;
  });

  const idsClone = clone(adminIds);
  idsClone.splice(index, 1);

  await updateAdmins(idsClone, tenant, lang);
};
