import MeasurementService from 'app-context/services/metering/MeasurementService';
import { action, makeObservable, observable } from 'mobx';

import { MeterType } from '@zf/api-types/master-data/meter';

import RootStore from '../../../../app-context/stores';
import BaseStore from '../../../../app-context/stores/BaseStore';
import BalanceStore from './BalanceStore';

export default class PrepaymentDeviceStore extends BaseStore<MeterType> {
  public rootStore: RootStore;
  public balanceStore: BalanceStore;

  public measurementService: MeasurementService;

  constructor(rootStore: RootStore) {
    super();

    this.rootStore = rootStore;
    this.balanceStore = new BalanceStore(this);

    this.measurementService = new MeasurementService(rootStore.applicationStore);

    makeObservable(this, {
      balanceStore: observable,

      resetStore: action
    });
  }

  resetStore = () => this.balanceStore.resetStore();
}
