import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../../../app-context';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';

type Props = {
  meter: MeterType;
};

export default function ModelDetails(props: Props) {
  const { meter } = props;
  const { i18n } = useAppContext();

  const response = useSuspenseSingleAPI<ModelType>({
    request: {
      endpoint: '/cfg/models/',
      selector: meter.modelId
    }
  });

  if (!response.result)
    return (
      <CardItem width="12">
        <Center>
          <Spinner size="xsmall" />
        </Center>
      </CardItem>
    );

  const model = response.result.data;

  return (
    <>
      <CardItem width="6">
        <Label>{i18n.getTranslation('meter.brand')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{model ? model.brandName : undefined}</Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('meter.model')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{model ? model.name : undefined}</Paragraph>
      </CardItem>
    </>
  );
}
