import WizardSubmitButton from 'components/Button/WizardSubmitButton';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import BillingSection from './billing-section/BillingSection';
import ContractDataSection from './contract-data-section/ContractDataSection';
import CustomerSection from './customer-section/CustomerSection';
import LocationsSection from './location-section/LocationSection';
import OptionalMeasurementSection from './optional-msmt-section/OptionalMeasurementSection';

export type Props = {
  customerId?: string;
};

const RegularMoveInWizard = (props: Props) => {
  const { customerId } = props;

  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { regularMoveInWizardStore } = contractStore.moveInWizardBaseStore;
  const { regularMoveInWizardNavigator, initWizard, handleSubmit } = regularMoveInWizardStore;
  const { hasErrors } = regularMoveInWizardNavigator;

  useEffect(() => {
    initWizard(customerId);
  }, []);

  return (
    <>
      <LocationsSection />

      <CustomerSection />

      <ContractDataSection />

      <BillingSection />

      <OptionalMeasurementSection />

      <WizardSubmitButton id="contract-submit" onClick={handleSubmit} disabled={hasErrors}>
        {getTranslation('contracts.wizard.create_contract')}
      </WizardSubmitButton>
    </>
  );
};

export default observer(RegularMoveInWizard);
