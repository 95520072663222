import React from 'react';

import { AttachmentType } from '@zf/api-types/attachment';
import Center from '@zf/stella-react/src/helpers/Center';

import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../utils/request';
import css from './incoming-invoice-attachment.module.scss';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

type Props = {
  file: any;
  attachment: AttachmentType | undefined;
};

export default function IncomingInvoicePreview(props: Props) {
  const { file, attachment } = props;

  const blobResponse = useSuspenseSingleAPI<BlobPart>(
    {
      responseType: 'arraybuffer',
      request: {
        method: METHODS.POST,
        endpoint: `/att/attachments/${attachment?.id}`
      },
      Accept: 'application/octet-stream',
      mayExecute: !!attachment
    },
    true
  );

  if (attachment && !blobResponse.result)
    return (
      <div className={css['preview']}>
        <Center type="both">
          <Spinner size="medium" />
        </Center>
      </div>
    );

  let blob: Blob;

  if (blobResponse.result && !file.file) {
    blob = new Blob([blobResponse.result.data], { type: 'application/pdf' });
  } else {
    blob = new Blob([file.file], { type: 'application/pdf' });
  }

  const blob_url = URL.createObjectURL(blob);

  return (
    <embed id="pdf_preview" src={`${blob_url}#page=1&view=FitH`} className={css['preview']} type="application/pdf" />
  );
}
