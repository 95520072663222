import { useEffect, useMemo, useState } from 'react';

import { formatDecimal, getDecimalSeparator, parseFloatLocalised } from '@zf/utils/src/number';

export default function useFloatInput(
  value: number | null | undefined,
  locale: string,
  onChange: (value: number) => void
) {
  const [textValue, setTextValue] = useState(formatDecimal(value, locale));
  const decimalSeparator = useMemo(() => {
    return getDecimalSeparator(locale);
  }, [locale]);

  const decimalRe = RegExp(`\\${decimalSeparator}\\d*`, 'g');

  const handleChange = (val: string) => {
    const floatValue = parseFloatLocalised(val, locale);

    // Exception for the '-' sign for negative numbers
    if (val === '-') {
      setTextValue(val);
    } else {
      let formatted = formatDecimal(floatValue, locale);

      const decimalPart = decimalRe.exec(val);

      if (decimalPart) {
        formatted = `${formatDecimal(Math.trunc(floatValue), locale)}${decimalPart}`;
      }

      onChange(floatValue);
      setTextValue(formatted);
    }
  };

  useEffect(() => {
    setTextValue(formatDecimal(value, locale));
  }, [value]);

  return {
    textValue,
    setTextValue,
    handleChange
  };
}
