import React from 'react';

import { useAppContext } from '../../../app-context';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../components/BreadCrumb';
import { CrumbHeader } from '../../../components/Header';
import { isEmptyObject } from '@zf/utils/src/object';
import useCollectionCaseDetail from '../hooks/useCollectionCaseDetail';
import CollectionCaseTabContainer from './CollectionCaseTabContainer';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

export default function CollectionCase(props: Props) {
  const { navigate, urlParams } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { collectionCase, status, navigator } = useCollectionCaseDetail(urlParams.collectionCaseId);

  if (!collectionCase || isEmptyObject(collectionCase)) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              icon="list"
              text={i18n.getTranslation('nav.collection_cases')}
              url={`${tenantReducer.state.rootUrl}/collection-cases`}
            />
            <CrumbDivider />
            <Crumb
              icon="dunning"
              text={
                Object.keys(collectionCase).length > 0
                  ? `${collectionCase.sequenceNum} - ${collectionCase.debtor.displayName} (${collectionCase.workflowName})`
                  : ''
              }
            />
          </BreadCrumb>
        }
      />
      <CollectionCaseTabContainer collCase={collectionCase} status={status} navigator={navigator} navigate={navigate} />
    </>
  );
}
