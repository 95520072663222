import React from 'react';

import { navigate, Router } from '@reach/router';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';
import Center from '@zf/stella-react/src/helpers/Center';

import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { ErrorBoundary } from '../../components/Error';
import { CrumbHeader } from '../../components/Header';
import { Loader } from '../../components/Loader';
import { SuspenseSpinner } from '../../components/suspense';
import { useStore } from '../../hooks/useStore';
import Route from '../route';
import CustomEntityPropertiesTab from './custom-entity-properties/custom-entity-properties-tab';
import GeneralTab from './general/general-tab';
import NumberSequenceTab from './number-sequences/number-sequence-tab';
import OrganizationTab from './organisation/organization-tab';
import BillingTab from './parameters/BillingTab';

type Props = {
  location: Location;
};

export default function SettingsConfig(props: Props) {
  const { location } = props;
  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const setActiveTab = (tabId: string) => {
    navigate(`${rootUrl}/configuration/${tabId}`);
  };

  const splitUrl = location.href.split('/');

  const generalTab = () => (
    <SuspenseSpinner>
      <GeneralTab />
    </SuspenseSpinner>
  );

  const organizationTab = () => (
    <SuspenseSpinner>
      <OrganizationTab />
    </SuspenseSpinner>
  );

  const numberSequenceTab = () => (
    <SuspenseSpinner>
      <NumberSequenceTab />
    </SuspenseSpinner>
  );

  const customEntityPropertiesTab = () => (
    <SuspenseSpinner>
      <CustomEntityPropertiesTab />
    </SuspenseSpinner>
  );

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="cog" text={getTranslation('settings.settings_config')} />
          </BreadCrumb>
        }
      />

      <TabContainer
        onSelectTab={setActiveTab}
        entityId={getTranslation('nav.configuration')}
        selectedTab={splitUrl[splitUrl.length - 1]}
        tabItems={[
          {
            id: 'general',
            title: getTranslation('settings.general'),
            isDefault: true
          },
          {
            id: 'organisation',
            title: getTranslation('settings.organization')
          },
          {
            id: 'billing',
            title: getTranslation('settings.billing')
          },
          {
            id: 'number_sequences',
            title: getTranslation('settings.number_sequences')
          },
          {
            id: 'custom_entity_properties',
            title: getTranslation('settings.custom_entity_properties')
          }
        ]}
      >
        <ErrorBoundary>
          <React.Suspense
            fallback={
              <Center type="both">
                <Loader />
              </Center>
            }
          >
            <Router>
              <Route Component={generalTab} path="general" default />
              <Route Component={organizationTab} path="organisation" />
              <Route Component={BillingTab} path="billing" />
              <Route Component={numberSequenceTab} path="number_sequences" />
              <Route Component={customEntityPropertiesTab} path="custom_entity_properties" />
            </Router>
          </React.Suspense>
        </ErrorBoundary>
      </TabContainer>
    </>
  );
}
