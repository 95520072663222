import React, { useMemo, useState } from 'react';

import { useAppContext } from '../../../app-context';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import useTasksListPage from '../hooks/useTasksListPage';

export default function TasksList() {
  const { i18n } = useAppContext();
  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useTasksListPage();

  const [columns] = useState([
    {
      width: 500,
      label: i18n.getTranslation('general.name'),
      dataKey: 'name',
      noClick: true
    },
    {
      width: 250,
      label: i18n.getTranslation('location.serviced_location'),
      dataKey: 'ServiceLocationDetails.Address.LocalizedDisplay'
    },
    {
      width: 250,
      label: i18n.getTranslation('tasks.creation_date'),
      dataKey: 'CreatedDateTime'
    },
    {
      width: 250,
      label: i18n.getTranslation('tasks.move_date'),
      dataKey: 'MutationDateTime'
    },
    {
      width: 250,
      label: i18n.getTranslation('tasks.current_contract'),
      dataKey: 'currentContract'
    },
    {
      width: 250,
      label: i18n.getTranslation('tasks.owner'),
      dataKey: 'owner'
    },
    {
      width: 100,
      label: i18n.getTranslation('general.status'),
      dataKey: 'status'
    }
  ]);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="tasks-table"
        tooltipId="tasks-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={columns}
        dynamicRowHeight
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, i18n.lang]);
}
