import React from 'react';

import css from './dropdown-details.module.scss';

type Props = {
  children: React.ReactNode;
};

export default function DropDownDetails(props: Props) {
  const { children } = props;

  return <div className={css['details-grid']}>{children}</div>;
}
