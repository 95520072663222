import React from 'react';

import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../../app-context';
import useCurrent from '../../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../../design-system/ComponentSets';
import SignContractDialog from '../../dialogs/sign-dialog';

type Props = {
  refreshCounts: () => void;
};

export default function SignContractListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('contract');
  const selectedRows: ContractRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<ContractType>('contract');
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    !!selectedRows.find((contract) => !contract || (contract && contract.__currentContractStatus !== 'draft'));

  return (
    <DropdownAction
      id="contract.sign"
      icon="sign"
      dialogTitle={i18n.getTranslation('actions.sign')}
      dialogContent={
        <SignContractDialog
          ref={clickRef}
          selectedRows={selectedRows}
          refreshCounts={refreshCounts}
          onComplete={setUpdatedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('actions.sign')}
    />
  );
}
