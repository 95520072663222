import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import React from 'react';

import { PropertyGroupAttributeType } from '@zf/api-types/master-data/property-group';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import PropertyGroupAutoFill from '../../../../components/Autofills/PropertyGroupAutoFill';
import { STEP_NAMES } from './InvoiceWizard';

export type Props = {
  selectedPropertyGroup: PropertyGroupAttributeType | null;
  setPropertyGroup: (propertyGroup: PropertyGroupAttributeType, companyBankAccountId: string | null) => void;
};

export default function PropertyGroupSection(props: Props) {
  const { selectedPropertyGroup, setPropertyGroup } = props;

  const { propertyGroupStore, applicationStore } = useStore();
  const { getPropertyGroupBillingConfiguration } = propertyGroupStore.propertyGroupService;
  const { getTranslation } = applicationStore;

  const deriveCompanyBankAccountId = async (propertyGroup: PropertyGroupAttributeType) => {
    let companyBankAccountId: string | null = null;

    if (propertyGroup.id) {
      try {
        companyBankAccountId = (await getPropertyGroupBillingConfiguration(propertyGroup.id)).companyBankAccountId;
      } catch (error) {
        notify.error({
          content: getTranslation('invoice.wizard.derive_iban_fail'),
          error
        });
      }
    }

    return companyBankAccountId;
  };

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[2]} />
        {getTranslation('invoice.steps.property_group')}
      </WizardHeader>
      <Heading headingType="h3" style="bold">
        {getTranslation('invoice.wizard.property_group')}
      </Heading>
      <InputContainer>
        <PropertyGroupAutoFill
          id="property-group"
          onChange={async (val) => {
            if (val[0]) {
              const propertyGroupRef: PropertyGroupAttributeType = {
                id: val[0].id,
                name: val[0].name
              };
              const companyBankAccountId = await deriveCompanyBankAccountId(propertyGroupRef);
              setPropertyGroup(propertyGroupRef, companyBankAccountId);
            }
          }}
          selectedValues={[selectedPropertyGroup?.id || '']}
          error={!selectedPropertyGroup}
        />
      </InputContainer>
    </WizardSection>
  );
}
