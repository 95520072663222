import React from 'react';

import { ResponseType } from '@zf/api-types/api';
import { MeterPaginatedResult } from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationPaginatedResult } from '@zf/api-types/master-data/servicelocation';

import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import InstallMeter from './InstallMeter';

type UrlParams = {
  meterOrlocationOrpropertyID: string;
  org: string;
  path: string;
  tenantName: string;
  uri: string;
  useCase: string;
};

type Props = {
  urlParams: UrlParams;
};

export default function UrlParamsResponseInterface(props: Props) {
  const { urlParams = { useCase: '', meterOrlocationOrpropertyID: '' } } = props;

  let res: any;

  if (urlParams.meterOrlocationOrpropertyID !== 'no_location') {
    if (urlParams.useCase === 'forLocation') {
      // Get the current location
      // eslint-disable-next-line react-hooks/rules-of-hooks
      res = useSuspenseSingleAPI<ServiceLocationPaginatedResult>({
        request: {
          endpoint: `/md/ServiceLocations/${urlParams.meterOrlocationOrpropertyID}`
        }
      });
    } else if (urlParams.useCase === 'forMeter') {
      // Get the current meter
      // eslint-disable-next-line react-hooks/rules-of-hooks
      res = useSuspenseSingleAPI<MeterPaginatedResult>({
        request: {
          endpoint: `/md/Meters/${urlParams.meterOrlocationOrpropertyID}`
        }
      });
    } else if (urlParams.useCase === 'forPropertyGroup') {
      // Get the locations for the given property
      // eslint-disable-next-line react-hooks/rules-of-hooks
      res = useSuspenseSingleAPI<ResponseType<PropertyGroupType>>({
        request: {
          endpoint: `/md/PropertyGroups/${urlParams.meterOrlocationOrpropertyID}`
        }
      });
    }
  }

  if (res && !res.result) return null;

  const renderInstallMeter = () => {
    if (urlParams.meterOrlocationOrpropertyID === 'no_location') {
      return <InstallMeter meter={undefined} location={undefined} />;
    } else {
      if (urlParams.useCase === 'forLocation') {
        return <InstallMeter meter={undefined} location={res.result.data} />;
      } else if (urlParams.useCase === 'forMeter') {
        return <InstallMeter meter={res.result.data} location={undefined} />;
      } else {
        return <InstallMeter propertyGroup={res.result.data} location={undefined} />;
      }
    }
  };

  return renderInstallMeter();
}
