import moment from 'moment';
import React from 'react';

import { AddMeasurementRequestType, MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import MeasurementDialog from '../../../../../../actions/meters/add-measurement/measurement-dialog';
import { useAppContext } from '../../../../../../app-context';
import ConfirmationActionButton from '../../../../../../components/Button/ConfirmationActionButton';
import { SuspenseSpinner } from '../../../../../../components/suspense';
import css from './add-measurement-btn.module.scss';

type Props = {
  index: number;
  meter: MeterType;
};

export default function AddMeasurementBtn(props: Props) {
  const { index, meter } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const toHandle: AddMeasurementRequestType = {
    meter: meter,
    endDateTime: moment().toISOString(),
    value: undefined,
    channelId: '',
    hour: '0',
    minute: '0'
  };

  return (
    <ConfirmationActionButton
      id={`meter.add_measurement.index-${index}`}
      className={css['btn-style']}
      icon="measurement-add"
      type="text"
      dialogTitle={i18n.getTranslation('actions.add_measurement')}
      dialogContent={
        <SuspenseSpinner size="xsmall">
          <MeasurementDialog meterToHandle={toHandle} ref={clickRef} validationRef={validationRef} singleMeasurement />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.add_measurement')}
    />
  );
}
