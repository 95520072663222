import React, { CSSProperties, ReactNode, useMemo, useState } from 'react';

import IconParagraph from '../../../components/Icon/IconParagraph';
import { useStore } from '../../../hooks/useStore';
import { Button } from '../../Components';
import css from './collapsable-info-banner.module.scss';

type Props = {
  infoText: string;
  btnText: string;
  children: ReactNode;
  color?: string;
};

export default function CollapsableInfoBanner(props: Props) {
  const { infoText, btnText, children, color } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [showInfo, setShowInfo] = useState(false);

  const style: CSSProperties = useMemo(() => {
    return { borderColor: color };
  }, [color]);

  return (
    <div className={css['collapsable-info-banner']} style={style}>
      <div className={css['collapsable-info-banner-header']}>
        <IconParagraph iconType="alert-circle" color={color} bold>
          {infoText}
        </IconParagraph>
        <Button id="more-info" onClick={() => setShowInfo(!showInfo)} type="text">
          {!showInfo ? btnText : getTranslation('general.hide')}
        </Button>
      </div>
      {showInfo && children}
    </div>
  );
}
