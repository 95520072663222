import clone from 'clone';
import React from 'react';

import { invoiceType, paymentDelayEnum } from '@zf/api-types/enums';
import { UpdatePaymentDelayType } from '@zf/api-types/payments';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import Button from '../../../components/Button/Button';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import { DeleteIcon } from '../../../components/Icon';
import { InlineNumberInput } from '../../../components/input/NumberInput';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import SimpleDropdown, { InlineInvoiceTypeDropdown } from '../../../components/Lang/SimpleDropdown';
import { notify } from '../../../events/notification-events';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';

type Props = {
  paymentDelays: UpdatePaymentDelayType[];
  backup: UpdatePaymentDelayType[];
  selectedDelayIndex: number;
  scrollToDelayIndex: number;
  isDirty: boolean;
  isLoading: boolean;
  addEntity: (newEntity: UpdatePaymentDelayType) => void;
  sort: (sortby: string, sortDirection: SortDirection | '') => void;
  deleteEntity: (index: number) => void;
  setDelayValue: <V>(value: Partial<V>) => void;
  setSelectedDelayIndex: (index: number) => void;
};

const InlinePaymentDelayDropdown = InlineInputField<SimpleDropdownProps<paymentDelayEnum>>(SimpleDropdown);

export default function PaymentDelays(props: Props) {
  const {
    paymentDelays,
    backup,
    selectedDelayIndex,
    scrollToDelayIndex,
    sort,
    isDirty,
    isLoading,
    addEntity,
    deleteEntity,
    setDelayValue,
    setSelectedDelayIndex
  } = props;
  const { i18n, enumReducer } = useAppContext();

  const selectableInvoiceTypes = React.useMemo(
    () =>
      enumReducer.getEnum<invoiceType>('invoiceType').filter((type) => {
        return !backup.some((delay) => {
          return delay.invoiceType === type.value;
        });
      }),
    [enumReducer, backup]
  );

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 1,
      label: i18n.getTranslation('invoice.invoicetype'),
      dataKey: 'invoiceType'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('payment_delay.delay_type'),
      dataKey: 'delayType'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('payment_delay.value'),
      dataKey: 'value'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const addPaymentDelay = () => {
    addEntity({
      invoiceType: '' as invoiceType,
      paymentDelay: paymentDelayEnum.delaynumberofdays,
      value: undefined
    });
  };

  const setPaymentDelay = (index: number, value: Partial<UpdatePaymentDelayType>) => {
    const clonedArray = clone(paymentDelays);
    clonedArray[index] = { ...clonedArray[index], ...value };

    setDelayValue({
      values: clonedArray
    });
  };

  const delayRows = paymentDelays.map((paymentDelay, index) => {
    return {
      invoiceType: paymentDelay.id ? (
        <Paragraph>{enumReducer.getTranslation('invoiceType', paymentDelay.invoiceType || '')}</Paragraph>
      ) : (
        <InlineInvoiceTypeDropdown
          id={`invoiceType.index-${index}`}
          values={selectableInvoiceTypes}
          selectedValues={[paymentDelay.invoiceType || ('' as invoiceType)]}
          onChange={(val) => {
            if (
              !paymentDelays.some((delay) => {
                return delay.invoiceType === val[0];
              })
            ) {
              setPaymentDelay(index, { invoiceType: val[0] });
            } else {
              notify.warning({
                content: i18n.getTranslation('parameters.invoice_type_warning')
              });
            }
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!paymentDelay.invoiceType}
          error={!paymentDelay.invoiceType}
        />
      ),
      delayType: (
        <InlinePaymentDelayDropdown
          id={`delayType.index-${index}`}
          values={enumReducer.getEnum('paymentDelay')}
          selectedValues={[paymentDelay.paymentDelay || ('' as paymentDelayEnum)]}
          onChange={(val) => setPaymentDelay(index, { paymentDelay: val[0] })}
          error={!paymentDelay.paymentDelay}
        />
      ),
      value: (
        <InlineNumberInput
          id={`values.index-${index}`}
          value={paymentDelay.value}
          onChange={(val) => setPaymentDelay(index, { value: val })}
          error={!paymentDelay.value}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`payment-delay.delete.index-${index}`}
          tooltipFor="payment-delays-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  return (
    <Card id="payment-delay-card" width="2">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={i18n.getTranslation('coachmark.payment_delay.title')}
            content={[i18n.getTranslation('coachmark.payment_delay.paragraph')]}
          />
        }
        extraRight={
          <Button id="payment_delay.add" type="text" icon="plus" onClick={addPaymentDelay}>
            {i18n.getTranslation('general.add')}
          </Button>
        }
      >
        {i18n.getTranslation('parameters.payment_delay')}
      </CardHeader>
      <CardBody type="indexTable" fixedHeight>
        <DynamicIndexTable
          tooltipId="payment-delays-table"
          rows={delayRows}
          columns={tableColumns}
          selectedRow={selectedDelayIndex}
          scrollToIndex={scrollToDelayIndex}
          isDirty={isDirty}
          isLoading={isLoading}
          setSelectedRow={setSelectedDelayIndex}
          sort={sort}
        />
      </CardBody>
    </Card>
  );
}
