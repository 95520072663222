import React from 'react';

import { navigate } from '@reach/router';
import { DeleteImportJobType, ImportJobType } from '@zf/api-types/import-job';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteImportJobDialog from '../../../actions/import-jobs/delete-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  importJob: ImportJobType;
};

export default function DeleteImportJobListItem(props: Props) {
  const { importJob } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const importJobToHandle: DeleteImportJobType = {
    etag: importJob._etag,
    id: importJob.id
  };

  const onComplete = () => {
    navigate(`${tenantReducer.state.rootUrl}/import-jobs`);
  };

  return (
    <DropdownAction
      id="import_jobs.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={
        <DeleteImportJobDialog ref={clickRef} onComplete={() => onComplete()} importJobToHandle={importJobToHandle} />
      }
      onSubmit={onSubmit}
      dialogType="danger"
      buttonPositive={i18n.getTranslation('general.delete')}
    />
  );
}
