import { observer } from 'mobx-react';
import React from 'react';

import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteTaskDialog from '../../../actions/tasks/delete-task-dialog';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  refreshCounts: () => void;
};

const DeleteSocialTariffListPageButton = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { myCurrent } = useCurrent('moverequest');
  const selectedRows: TaskRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<MoveRequestType>('moverequest');
  const { clickRef, onSubmit } = useDialog();

  const deleteText = getTranslation('general.delete');

  return (
    <DropdownAction
      id="tasks.delete"
      icon="trashcan"
      dialogTitle={deleteText}
      dialogContent={
        <DeleteTaskDialog {...props} ref={clickRef} selectedRows={selectedRows} setDeletedRows={setDeletedRows} />
      }
      onSubmit={onSubmit}
      buttonPositive={deleteText}
      disabled={selectedRows.length === 0}
      dialogType="danger"
    />
  );
};

export default observer(DeleteSocialTariffListPageButton);
