import React from 'react';

import { propertyGroupType } from '@zf/api-types/enums';

import { useAppContext } from '../../../app-context';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../components/BreadCrumb';
import { CrumbHeader } from '../../../components/Header';
import usePropertyGroupDetail from '../hooks/usePropertyGroupDetail';
import PropertyTabContainer from './PropertyGroupTabContainer';

type Props = {
  urlParams: { [key: string]: string };
  location: Location;
  navigate: (url: string) => void;
};

export default function PropertyGroup(props: Props) {
  const { urlParams = { propertyGroupID: '' }, navigate } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { propertyGroup, navigator, setPropertyGroup, refresh } = usePropertyGroupDetail(urlParams.propertyGroupID);
  const { rootUrl } = tenantReducer.state;

  if (!propertyGroup) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              icon="list"
              text={i18n.getTranslation('property_groups.property_groups')}
              url={`${rootUrl}/property-groups`}
            />
            <CrumbDivider />
            <Crumb
              icon={propertyGroup.type === propertyGroupType.building ? 'building' : 'project'}
              text={Object.keys(propertyGroup).length > 0 ? propertyGroup.name : ''}
            />
          </BreadCrumb>
        }
      />
      <PropertyTabContainer
        propertyGroup={propertyGroup}
        navigator={navigator}
        setPropertyGroup={setPropertyGroup}
        navigate={navigate}
        refresh={refresh}
      />
    </>
  );
}
