import React from 'react';

import { collectionStepLevel, culture, templateUsecase } from '@zf/api-types/enums';

import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../../../utils/request';
import css from './loader.module.scss';

type Props = {
  communicationLevel: collectionStepLevel;
  dummyObject: object | undefined;
  previewCulture: culture;
  templateUsecase: templateUsecase;
};

export default function HTMLLoader(props: Props) {
  const { communicationLevel, dummyObject, templateUsecase, previewCulture } = props;

  const response = useSuspenseSingleAPI<string>(
    {
      request: {
        method: METHODS.POST,
        endpoint: `/cfg/templates/collectioncase/${communicationLevel}/${templateUsecase}/${previewCulture}/html`,
        query: {
          showLabels: false,
          wrapLabels: false
        },
        data: dummyObject
      }
    },
    true
  );

  if (!response.result) return null;

  return (
    <div className={css['wrap']}>
      <iframe title="email-preview" id="email-preview" className={css['frame']} srcDoc={response.result.data} />
    </div>
  );
}
