import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import { useAppContext } from '../../../../../../app-context';

export default function NoMetersOverlay() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/meter.png"
          alt="meter"
          title="meter"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('property_groups.no_meters')}
    >
      {i18n.getTranslation('property_groups.no_meters_desc')}
    </CardEmptyBody>
  );
}
