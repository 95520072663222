import React from 'react';

import { exportStatus } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import GenericDialog from '../../../../components/units/Dialog/GenericDialog';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  invoice: InvoiceType;
};

export default function MarkAsNotExportedListItem(props: Props) {
  const { invoice } = props;
  const { applicationStore, invoiceStore } = useStore();
  const { getTranslation } = applicationStore;
  const { invoiceApiService, pageActionPermissions, setNewInvoice } = invoiceStore;
  const { changeExportStatus } = invoiceApiService;
  const { clickRef, onSubmit } = useDialog();

  const submitFunction = async () => {
    const newInvoice = await changeExportStatus(invoice.id, exportStatus.notexported);
    setNewInvoice(newInvoice);
  };

  if (pageActionPermissions.mayMarkAsNotExported) {
    return (
      <DropdownAction
        id="invoice.change_export_status"
        icon="stop"
        dialogTitle={getTranslation('invoice.mark_as_not_exported')}
        dialogContent={
          <GenericDialog
            ref={clickRef}
            info={getTranslation('invoice.mark_as_not_exported_info')}
            successMsg={getTranslation('invoice.mark_as_not_exported_success')}
            errorMsg={getTranslation('invoice.mark_as_not_exported_fail')}
            submitFunction={submitFunction}
          />
        }
        onSubmit={onSubmit}
        buttonPositive={getTranslation('general.ok')}
      />
    );
  }

  return null;
}
