import clone from 'clone';
import React from 'react';

import { NumberSequenceType, UpdateNumberSequenceType } from '@zf/api-types/settings-config';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import useDialog from '@zf/hooks/src/useDialog';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import { ConfigHelp } from '../../../components/CoachMarks';
import CommitSection from '../../../components/config/commit-section';
import PreviewIcon from '../../../components/Icon/PreviewIcon';
import { InlineFloatInput } from '../../../components/input/FloatInput';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import { dialog } from '../../../events/dialog-events';
import { notify } from '../../../events/notification-events';
import { RequestType } from '../../../types/Request';
import { processNumberSequence, segmentsToApiFriendlySegments } from '../../../utils/number-sequence';
import { createHeader, METHODS, sendRequest } from '../../../utils/request';
import PreviewDialog from './preview-dialog';
import SegmentsCard from './segments-card';

type Props = {
  config: UseConfigReturnType<UpdateNumberSequenceType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdateNumberSequenceType[]>,
    React.Dispatch<ValidatorCtxAction<UpdateNumberSequenceType[]>>
  ];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function NumberSequencesContent(props: Props) {
  const { config } = props;
  const { sort } = config;
  const { i18n, enumReducer, tenantReducer, authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const { clickRef, onSubmit } = useDialog();

  const [isLoading, setIsLoading] = React.useState(false);

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 2,
      label: i18n.getTranslation('number_sequence.entity'),
      dataKey: 'entity'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('number_sequence.next_value'),
      dataKey: 'nextValue'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('number_sequence.format_string'),
      dataKey: 'formatString'
    },
    {
      width: 50,
      dataKey: 'previewAction'
    }
  ]);

  const { values, backup, isDirty, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator<UpdateNumberSequenceType[]>(props.useTracked);

  const { backupValues } = useContextCRUD(props.useTracked);

  const handleSave = async () => {
    setValue({ isLocked: true });
    try {
      const numberSequencesToHandle = clone(values);

      await Promise.all(
        numberSequencesToHandle.map(async (numberSeq, index) => {
          const apiFriendlyValues: UpdateNumberSequenceType = {
            formatString: numberSeq.formatString,
            lastValue: numberSeq.lastValue,
            referenceEntity: numberSeq.referenceEntity,
            segments: segmentsToApiFriendlySegments(numberSeq)
          };

          let request: RequestType | undefined = undefined;

          // Update
          const initialNs = backup.find((initial) => initial.id === numberSeq.id);
          if (JSON.stringify(initialNs) !== JSON.stringify(numberSeq)) {
            request = {
              method: METHODS.POST,
              endpoint: `/cfg/NumberSequences/${numberSeq.id}`,
              data: {
                ...apiFriendlyValues
              }
            };
          }

          if (request) {
            const newData = await sendRequest<NumberSequenceType>({
              request,
              customHeaders: createHeader({
                'If-Match': numberSeq._etag
              }),
              tenantReducer,
              lang: i18n.lang
            });

            if (!numberSeq.delete) {
              const newSequence = processNumberSequence(newData.data);
              const valuesClone = clone(values);
              valuesClone[index] = newSequence;
              setValue(valuesClone);
            }
          }
        })
      );

      notify.success({
        content: i18n.getTranslation('number_sequence.success_update')
      });

      backupValues();
    } catch (e) {
      setValue({ isLocked: false });

      notify.error({
        content: i18n.getTranslation('number_sequence.error_update'),
        error: e
      });
    }
  };

  const openPreviewDialog = async (numberSequence: UpdateNumberSequenceType) => {
    setIsLoading(true);
    let previewString = '';

    try {
      previewString = (
        await sendRequest<string>({
          request: {
            method: METHODS.POST,
            endpoint: `/cfg/NumberSequences/${numberSequence.id}/preview`,
            data: {
              lastValue: numberSequence.lastValue,
              segments: segmentsToApiFriendlySegments(numberSequence),
              formatString: numberSequence.formatString,
              referenceEntity: numberSequence.referenceEntity
            }
          },
          tenantReducer,
          lang: i18n.lang
        })
      ).data;
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notify.error({
        content: i18n.getTranslation('number_sequence.preview_fail'),
        error: e
      });
    }

    dialog.normal({
      title: i18n.getTranslation('number_sequence.preview_num_seq'),
      icon: 'segment',
      content: <PreviewDialog ref={clickRef} previewString={previewString} />,
      onSubmit,
      isDismissDialog: true
    });
  };

  const setNumberSequences = (
    index: number,
    value: UpdateNumberSequenceType[keyof UpdateNumberSequenceType],
    dataKey: keyof UpdateNumberSequenceType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value;

    setValue({
      values: clonedArray as UpdateNumberSequenceType[]
    });
  };

  const numberSequenceRows = values.map((numberSequence, index: number) => {
    return {
      entity: (
        <Paragraph>
          {enumReducer.getTranslation('numberSequenceReferenceEntity', numberSequence.referenceEntity)}
        </Paragraph>
      ),
      nextValue: (
        <InlineFloatInput
          id={`numbersequences.next_value.index-${index}`}
          value={numberSequence.lastValue}
          onChange={(val) => setNumberSequences(index, val, 'lastValue')}
          error={!numberSequence.lastValue}
        />
      ),
      formatString: (
        <InlineInputFieldInput
          id={`numbersequences.formatstring.index-${index}`}
          value={numberSequence.formatString}
          onChange={(val) => setNumberSequences(index, val, 'formatString')}
          error={!numberSequence.formatString}
        />
      ),
      previewAction:
        isLoading && index === selectedIndex ? (
          <Spinner size="xsmall" />
        ) : (
          <PreviewIcon
            id={`preview-icon-${index}`}
            tooltipFor="number-sequence-table"
            onClick={() => openPreviewDialog(numberSequence)}
          />
        )
    };
  });

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleSave} isDirty={isDirty} />
      <CardsContainer>
        <Card width="2" id="number-sequence-card">
          <CardHeader
            primaryText={
              hasPermission('numbersequences.add_segment_type') ? i18n.getTranslation('general.add') : undefined
            }
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.numbersequence.title')}
                content={[i18n.getTranslation('coachmark.numbersequence.paragraph')]}
              />
            }
          >
            {i18n.getTranslation('number_sequence.number_sequences')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="number-sequence-table"
              rows={numberSequenceRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
            />
          </CardBody>
        </Card>
        <SegmentsCard values={values} selectedNumberSequence={selectedIndex} setValue={setValue} />
      </CardsContainer>
    </>
  );
}
