import moment, { Moment } from 'moment';

import { dataFrequency } from '@zf/api-types/enums';
import { PeriodType } from '@zf/api-types/general';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';

export const MIN_DATE = '0001-01-01T00:00:00.000Z';
export const MAX_DATE = '9999-12-31T23:59:59.9999999Z';

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const DISPLAY_DATE_PARAM_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_MONTH_FORMAT = 'DD MMM YYYY';
export const DISPLAY_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const MAX_DATE_YEAR = 9000;

export const HOURS: string[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

export const MINUTESMAP: Map<dataFrequency, string[]> = new Map([
  [
    dataFrequency.pt1m,
    [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '40',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '50',
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '57',
      '58',
      '59'
    ]
  ],
  [dataFrequency.pt5m, ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']],
  [dataFrequency.pt15m, ['0', '15', '30', '45']],
  [dataFrequency.pt30m, ['0', '30']],
  [dataFrequency.pt1h, ['0']],
  [dataFrequency.p1d, ['0']],
  [dataFrequency.na, ['0']]
]);

/**
 * Calculates the Moment difference in milliseconds
 * @param date1
 * @param date2
 * @returns date difference in milliseconds
 */
export const dateDifference = (date1: Moment, date2: Moment) => {
  return Math.abs(date1.unix() - date2.unix());
};

/**
 * Formats a Moment or date iso string to the format used by ZF, e.g. 01/01/2023
 * @param date
 * @returns formatted string
 */
export function formatDate(date: Moment | string | undefined | null) {
  if (date === undefined || date === null) {
    return '...';
  } else if (moment(date).year() > MAX_DATE_YEAR) {
    return '...';
  } else if (moment(date).format(DISPLAY_DATE_FORMAT) === '01/01/0001') {
    return '...';
  } else {
    return moment(date).local().format(DISPLAY_DATE_FORMAT);
  }
}

/**
 * Formats a Moment or date iso string to the format used by ZF for Excel exports
 * @param date
 * @returns formatted string
 */
export function formatDateForExport(date: Moment | string | undefined | null) {
  if (date === undefined || date === null) {
    return '';
  } else if (moment(date).year() > MAX_DATE_YEAR) {
    return '...';
  } else if (moment(date).format(DISPLAY_DATE_FORMAT) === '01/01/0001') {
    return '';
  } else {
    return moment(date).local().format(DISPLAY_DATE_FORMAT);
  }
}

/**
 * Gets the start of the day (midnight) from a Moment / date iso string
 * @param date
 * @returns Moment
 */
export function startOfDay(date: Moment | string | undefined | null) {
  if (date === null) return moment(MIN_DATE);

  if (typeof date === 'undefined') return moment(MIN_DATE);

  if (typeof date === 'string') {
    date = moment(date);
  }

  return date.local().startOf('day');
}

/**
 * Gets the start of the current day (midnight)
 * @returns Moment
 */
export const today = () => startOfDay(moment());

/**
 * Formats a Moment or date iso string to the format used by ZF, e.g. 01 jan. 2023
 * @param date
 * @returns formatted string
 */
export function formatDateWMonth(date: Moment | string | null | undefined) {
  if (date === null || typeof date === 'undefined') return '...';

  if (isMaxDate(date)) {
    return '...';
  } else if (isMinDate(date)) {
    return '...';
  } else {
    return `${moment(date).local().format(DISPLAY_DATE_MONTH_FORMAT)}`;
  }
}

/**
 * Formats a Moment or date iso string to the format used by ZF, e.g. 01/01/2023 01:20:17
 * @param date
 * @returns formatted string
 */
export function formatDateTime(date: Moment | string | null | undefined) {
  if (date === null || typeof date === 'undefined') return '...';

  if (isMaxDate(date)) {
    return '...';
  } else if (isMinDate(date)) {
    return '...';
  } else {
    return `${moment(date).format(DISPLAY_TIME_FORMAT)}`;
  }
}

/**
 * Formats a Moment or date iso string to the format used by ZF for Excel exports, e.g. 01/01/2023 01:20:17
 * @param date
 * @returns formatted string
 */
export function formatDateTimeForExport(date: Moment | string | null | undefined) {
  if (date === null || typeof date === 'undefined') return '';

  if (isMaxDate(date)) {
    return '';
  } else if (isMinDate(date)) {
    return '';
  } else {
    return `${moment(date).format(DISPLAY_TIME_FORMAT)}`;
  }
}

/**
 * Formats a period (2 Moment or date iso strings) to the format used by ZF, e.g. 01/01/2023-31/01/2023
 * @param startDate
 * @param endDate
 * @returns formatted string
 */
export function formatPeriod(
  startDate: Moment | string | null | undefined,
  endDate: Moment | string | null | undefined
) {
  if (formatDate(startDate) === '' || formatDate(endDate) === '') {
    return '';
  } else {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }
}

/**
 * Formats a period (2 Moment or date iso strings) to the format used by ZF, e.g. 01 jan. 2023 - 31 jan. 2023
 * @param startDate
 * @param endDate
 * @returns formatted string
 */
export function formatPeriodWMonth(
  startDate: Moment | string | null | undefined,
  endDate: Moment | string | null | undefined
) {
  if (formatDate(startDate) === '' || formatDate(endDate) === '') {
    return '';
  } else {
    return `${formatDateWMonth(startDate)} - ${formatDateWMonth(endDate)}`;
  }
}

/**
 * Converts a number of days into #months and #days
 * @param number days 
 * @returns #months and #days in given days
 */
export const daysToMonthsAndDays = (days: number) => {
  const months = Math.floor(days / 30);
  const rest = days % 30;
  return { months: months, days: rest };
};

/**
 * Checks if a Moment or date iso string equals the backend minimum date
 * @param date 
 * @returns boolean
 */
export const isMinDate = (date: string | Moment | undefined | null | number) => {
  if (!date) return false;

  if (typeof date === 'string' || typeof date === 'number') {
    return moment(date).year() === 1;
  } else {
    return date.year() === 1;
  }
};

/**
 * Checks if a Moment or date iso string equals the backend maximum date
 * @param date 
 * @returns boolean
 */
export const isMaxDate = (date: string | Moment | undefined | null | number) => {
  if (!date) return false;

  if (typeof date === 'string' || typeof date === 'number') {
    return moment(date).year() === 10000;
  } else {
    return date.year() === 10000;
  }
};

/**
 * Checks if a Moment or date iso string is either a max or min date
 * @param date 
 * @returns boolean
 */
export const isDefaultDate = (date: string | Moment | undefined | number) => {
  return isMinDate(date) || isMaxDate(date);
};

/**
 * Checks if a Moment or date iso string lies between the given start/end date 
 * @param start 
 * @param end 
 * @param comparer the date to check
 * @returns boolean
 */
export function betweenDates(start: Moment | string, end: Moment | string, comparer: Moment | string) {
  if (typeof comparer === 'string') {
    comparer = moment(comparer);
  }

  return comparer.isSameOrAfter(start) && comparer.isBefore(end);
}

/**
 * Sort periods oldest first (only works for custom type)
 * @param array periods 
 * @returns sorted array
 */
export const sortPeriods = (periods: PeriodType[]) => {
  return periods.sort((previous, current) => {
    if (previous.startDate.isBefore(current.startDate)) {
      return -1;
    }

    if (previous.startDate.isSame(current.startDate)) {
      return 0;
    }

    return 1;
  });
};

/**
 * Converts a date iso string to Moment
 * @param date 
 * @returns Moment
 */
export const convertToMoment = (date: null | string | Moment | undefined | Date) => {
  if (date === null || typeof date === 'undefined') return null;
  else return moment(date);
};

/**
 * Converts a Moment to date iso string
 * @param date 
 * @returns iso string
 */
export const convertMomentToISOString = (date: null | Moment | undefined) => {
  if (date === null || typeof date === 'undefined') return null;
  else return date.toISOString();
};

/**
 * Sorts an array of objects on date based on the attribute key
 * @param array an array of objects containing a date
 * @param dateKey the attribute name where the date is in an object
 * @param order ascending or descending
 * @returns sorted array
 */
export function sortByDate<T>(array: T[], dateKey: keyof T, order: SortDirection) {
  if (order === 'ASC') return array.sort((a, b) => moment(a[dateKey]).unix() - moment(b[dateKey]).unix());
  return array.sort((a, b) => moment(b[dateKey]).unix() - moment(a[dateKey]).unix());
}
