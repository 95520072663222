import { observer } from 'mobx-react-lite';
import React from 'react';

import { ConsumptionGroupType } from '@zf/api-types/metering/consumptions';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import CommitSection from '../../../components/config/commit-section';
import { useStore } from '../../../hooks/useStore';
import BuiltInCard from './built-in-group-card';
import CustomGroupCard from './custom-group-card';

const ConsumptionGroupsTab = () => {
  const { meterConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { customCrudService } = meterConfigStore;
  const { _isDirty, reset, handleSave } = customCrudService;

  const createApiFriendlyValues = (model: ConsumptionGroupType) => {
    return {
      code: model.code,
      description: model.description,
      utilityType: model.utilityType,
      unitOfMeasure: model.unitOfMeasure,
      estimatedAnnualVolume: model.estimatedAnnualVolume
    };
  };

  return (
    <>
      <CommitSection
        handleCancel={reset}
        handleSave={() =>
          handleSave(
            createApiFriendlyValues,
            'code',
            getTranslation('consumption_groups.success'),
            getTranslation('consumption_groups.failed')
          )
        }
        isDirty={_isDirty}
      />
      <CardsContainer>
        <CustomGroupCard />
        <BuiltInCard />
      </CardsContainer>
    </>
  );
};

export default observer(ConsumptionGroupsTab);
