import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { ExportJobType, IntermediateExportFormatType } from '@zf/api-types/export-job';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../app-context';
import { Icon } from '../../../components/Icon';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { dialog } from '../../../events/dialog-events';
import useSingleAPI from '../../../hooks/useSingleAPI';
import JSONDialog from './json-dialog';
import css from './style.module.scss';
import Button from '../../../components/Button/Button';

type Props = {
  exportJob: ExportJobType;
};

type EntityRowType = {
  __id: string;
  displayName: React.ReactNode;
  subjectType: React.ReactNode;
};

function NoInternalPayments() {
  const { i18n } = useAppContext();

  return <CardEmptyBody icon={<Icon type="invoice" />} title={i18n.getTranslation('export_jobs.no_content')} />;
}

function Content(props: Props) {
  const { exportJob } = props;
  const { i18n } = useAppContext();

  const [selectedFile, setSelectedFile] = React.useState<IntermediateExportFormatType>();

  const intermediateDataResponse = useSingleAPI<PagedResponseType<IntermediateExportFormatType>>({
    request: {
      endpoint: `/logs/ExportJob/${exportJob.id}/data`
    }
  });

  React.useEffect(() => {
    if (exportJob) {
      setSelectedFile(undefined);
    }
  }, [exportJob.id]);

  if (!intermediateDataResponse.result || intermediateDataResponse.result instanceof Promise) return null;

  const intermediateData = intermediateDataResponse.result.data.results;

  const filesDropdownValues = intermediateData.map((file) => {
    return {
      text: file.name,
      value: file
    };
  });

  let rows: EntityRowType[] = [];

  if (selectedFile) {
    rows = selectedFile.entities.map((entity) => {
      return {
        __id: entity.id,
        displayName: entity.displayName,
        subjectType: entity.subjectType
      };
    });
  }

  const columns = [
    {
      width: 200,
      label: i18n.getTranslation('general.name'),
      dataKey: 'displayName'
    },
    {
      width: 200,
      label: i18n.getTranslation('export_jobs.subject'),
      dataKey: 'subjectType'
    }
  ];

  const openDialog = () => {
    dialog.right({
      title: i18n.getTranslation('export_jobs.json_dialog_title', { fileName: selectedFile ? selectedFile.name : '' }),
      icon: 'invoice',
      content: <JSONDialog json={selectedFile ? selectedFile.content : JSON.parse('')} />,
      onSubmit: () => {
        // close dialog
      },
      isDismissDialog: true
    });
  };

  return (
    <>
      <div className={css['content-card']}>
        <SimpleDropdown
          id="dropdown-files"
          onChange={(val) => setSelectedFile(val[0])}
          values={filesDropdownValues}
          selectedValues={selectedFile ? [selectedFile] : []}
          placeholder={i18n.getTranslation('export_jobs.file_dropdown_placeholder')}
          clear
        />
        <Button type="primary" size="small" id="btn-show-json" onClick={openDialog} disabled={!selectedFile}>
          {i18n.getTranslation('actions.export_jobs.show_content')}
        </Button>
      </div>
      <div className={css['content-table']}>
        <DynamicVirtualTable
          id="entities-table"
          rows={rows}
          NoDataOverlay={NoInternalPayments}
          noSelect
          columns={columns}
        />
      </div>
    </>
  );
}

export default function ContentListCard(props: Props) {
  const { i18n } = useAppContext();

  return (
    <Card id="export-jobs-content-card" width="1">
      <CardHeader>{i18n.getTranslation('export_jobs.content')}</CardHeader>
      <CardBody className={css['export-jobs-content-card-body']}>
        <Content {...props} />
      </CardBody>
    </Card>
  );
}
