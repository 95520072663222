import { MoneyInputProps } from '@zf/stella-react/src/atoms/InputField/stella-money-input';
import React from 'react';
import { roundNumber } from '../../utils/number';

type Props = {
  roundNumberIndicator?: number;
};

export default function UnitPriceWrapper(Input: (props: MoneyInputProps) => JSX.Element) {
  return function (props: MoneyInputProps & Props) {
    const { onChange, value, roundNumberIndicator = 2 } = props;

    const roundOnBlur = () => {
      if (value) onChange(roundNumber(value, roundNumberIndicator));
    };

    return <div>{React.createElement(Input, { ...props, onBlur: roundOnBlur })}</div>;
  };
}
