import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useReducer } from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { Button } from '../../Components';
import { ValidationRef } from './Dialog';

type Props = {
  buttonPositive: string;
  buttonNegative: string;
  isDismissDialog: boolean;
  danger: boolean;
  onSubmit: () => Promise<void> | void;
  onCancel: () => void;
};

type DialogState = {
  loading: boolean;
  isValidationError: boolean;
  positiveButtonText: string;
};

export default forwardRef((props: Props, ref: React.Ref<ValidationRef | undefined>) => {
  const { buttonPositive, buttonNegative, isDismissDialog, danger = false, onCancel, onSubmit } = props;

  const stateReducer = createStateReducer<DialogState, Partial<DialogState>>();
  const [state, setState] = useReducer(stateReducer, {
    loading: false,
    isValidationError: false,
    positiveButtonText: buttonPositive
  });

  useImperativeHandle(ref, () => ({
    setIsError(error: boolean) {
      setState({ isValidationError: error });
    },
    setButtonPositive(text: string) {
      setState({ positiveButtonText: text });
    },
    onCancel() {
      onCancel();
    }
  }));

  const cancel = useCallback(() => {
    if (!state.loading) onCancel();
  }, [state.loading, onCancel]);

  const submit = useCallback(async () => {
    setState({ loading: true });
    await onSubmit();
    setState({ loading: false });
    cancel();
  }, [setState, cancel, onSubmit]);

  useEffect(() => {
    const keyListener = async (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        cancel();
      } else if (event.key === 'Enter' && !state.isValidationError) {
        await submit();
      }
    };

    document.addEventListener('keydown', keyListener, false);

    return () => document.removeEventListener('keydown', keyListener, false);
  }, [state.isValidationError, cancel, submit]);

  return (
    <>
      {!isDismissDialog && (
        <Button id="cancel" onClick={cancel} type="text" danger>
          {buttonNegative}
        </Button>
      )}

      <Button id="submit" danger={danger} onClick={() => submit()} disabled={state.isValidationError}>
        {state.positiveButtonText}
      </Button>
    </>
  );
});
