import { observer } from 'mobx-react';
import React from 'react';

import { formatDateForExport } from '@zf/utils/src/date';

import useCurrent from '../../../app-context/hooks/use-current';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import ExportToExcel from './ExportToExcel';
import { formatTransactionAmount } from '../../../utils/transaction';

type Props = {
  incomingBankingTransactionId?: string;
};

const IncomingBankingTransactionExport = (props: Props) => {
  const { applicationStore, incomingBankingTransactionsStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { getIncomingBankingTransactionsExport } = incomingBankingTransactionsStore.incomingBankingTransactionsService;
  const { myCurrent } = useCurrent('incoming_banking_transaction');
  const selectedIds = props.incomingBankingTransactionId
    ? [props.incomingBankingTransactionId]
    : myCurrent.listPage.selectedRows.map((r) => r.__id);

  const getExportArray = async () => {
    try {
      const exportArray: any[][] = [];

      exportArray.push([
        getTranslation('banking_transaction.identification'),
        getTranslation('incoming_banking_transactions.opening_balance_date'),
        getTranslation('incoming_banking_transactions.closing_balance_date'),
        getTranslation('banking_transaction.transaction_status'),

        getTranslation('payments.company_bank_account_holder'),
        getTranslation('payments.company_bank_account_iban'),

        getTranslation('banking_transaction.mutation_type'),
        getTranslation('incoming_mutations.incoming_matching_mutationDate'),
        getTranslation('incoming_banking_transactions.mutation_amount'),
        getTranslation('banking_transaction.mutation_status'),
        getTranslation('banking_transaction.mutation_ref'),
        getTranslation('incoming_mutations.incoming_matching_account_holder'),
        getTranslation('incoming_mutations.incoming_matching_iban'),

        getTranslation('customer.customer_id'),
        getTranslation('customer.customer_name'),

        getTranslation('outgoing_banking_transactions.outgoing_banking_transaction_id'),
        getTranslation('outgoing_mutations.matching_outgoing_mutation_id'),
        getTranslation('outgoing_mutations.matching_outgoing_mutation_descr'),
        getTranslation('outgoing_mutations.is_manual_match')
      ]);

      const excelData = await getIncomingBankingTransactionsExport(selectedIds);

      exportArray.push(
        ...excelData.map((entry) => {
          const values = Object.values(entry);

          values[1] = formatDateForExport(values[1] as string);
          values[2] = formatDateForExport(values[2] as string);
          values[3] = getEnumTranslation('incomingBankingTransactionStatus', values[3] as string);
          values[6] = getEnumTranslation('incomingMutationType', values[6] as string);
          values[7] = formatDateForExport(values[7] as string);
          values[8] = formatTransactionAmount(values[8] as number, entry.mutationType);
          values[9] = getEnumTranslation('incomingMutationStatus', values[9] as string);
          values[18] = getTranslation(`general.${values[18] ? 'yes' : 'no'}`);

          return values;
        })
      );

      return exportArray;
    } catch (error) {
      notify.error({
        content: getTranslation('actions.export_excel_fail'),
        error
      });
    }
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      key="incoming-banking-transaction-export"
      disabled={selectedIds.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_incoming_banking_transactions')}
      ws_name={getTranslation('incoming_banking_transactions.incoming_banking_transactions')}
    />
  );
};

export default observer(IncomingBankingTransactionExport);
