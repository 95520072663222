import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { incomingBankingTransactionStatus } from '@zf/api-types/enums';

import DeleteDetailButton from '../../../../../components/list-items/incoming-banking-transactions/DeleteDetailButton';
import DownloadDetailButton from '../../../../../components/list-items/incoming-banking-transactions/DownloadDetailButton';
import ProcessDetailButton from '../../../../../components/list-items/incoming-banking-transactions/ProcessDetailButton';
import IncomingBankingTransactionExport from '../../../../../components/Button/Excel/IncomingBankingTransactionExport';

type Props = {
  incomingBankingTransaction: IncomingBankingTransactionType;
  setIncomingBankingTransaction: React.Dispatch<React.SetStateAction<IncomingBankingTransactionType | null>>;
};

const IncomingBankingTransactionActions = (props: Props) => {
  const { incomingBankingTransaction, setIncomingBankingTransaction } = props;

  return (
    <>
      <DownloadDetailButton incomingTransaction={incomingBankingTransaction} />
      {incomingBankingTransaction.status === incomingBankingTransactionStatus.readytoprocess && (
        <ProcessDetailButton
          bankingTransaction={incomingBankingTransaction}
          setIncomingBankingTransaction={setIncomingBankingTransaction}
        />
      )}
      <IncomingBankingTransactionExport incomingBankingTransactionId={incomingBankingTransaction.id} />
      {(incomingBankingTransaction.status === incomingBankingTransactionStatus.closed ||
        incomingBankingTransaction.status === incomingBankingTransactionStatus.readytoprocess ||
        incomingBankingTransaction.status === incomingBankingTransactionStatus.resolveissues) && (
        <DeleteDetailButton bankingTransaction={incomingBankingTransaction} />
      )}
    </>
  );
};

export default IncomingBankingTransactionActions;
