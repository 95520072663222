import { observer } from 'mobx-react';
import React from 'react';

import { CollectionStepReferenceParametersType } from '@zf/api-types/transactions';

import { CoachMark } from '../../../design-system/Components';
import { Paragraph } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import css from './parameters-tooltip.module.scss';

type Props = {
  parameters: CollectionStepReferenceParametersType;
};

const CollectionStepReferenceParameters = (props: Props) => {
  const { parameters } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <CoachMark>
      <div className={css['info-tooltip']}>
        <Paragraph bold>{getTranslation('collection_case.collection_case')}</Paragraph>
        <Paragraph>{parameters.workflowName}</Paragraph>
        <br />
        <Paragraph bold>{getTranslation('collection_case.step')}</Paragraph>
        <Paragraph>{parameters.stepName}</Paragraph>
      </div>
    </CoachMark>
  );
};

export default observer(CollectionStepReferenceParameters);
