import React from 'react';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { useAppContext } from '../../../../app-context';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import { ValidatorValueType } from './CustomerGroupCard';

type Props = {
  values: ValidatorValueType;
  setValue: (value: Partial<ValidatorValueType>, initialApiSet?: boolean, backup?: boolean) => void;
  keyHandler: (event: React.KeyboardEvent<HTMLElement>) => void;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function CustomerGroupCardBody(props: Props) {
  const { values, setValue, keyHandler } = props;
  const { i18n } = useAppContext();

  return (
    <>
      <CardItem width="6">
        <Label>{i18n.getTranslation('customer_groups.customer_group_name')}</Label>
      </CardItem>
      <CardItem width="6">
        <InlineInputFieldInput
          id="customerGroups.name"
          onKeyDown={keyHandler}
          value={values.name}
          onChange={(name: string) =>
            setValue({
              name
            })
          }
        />
      </CardItem>
    </>
  );
}
