import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../../../components/placeholder';
import { useStore } from '../../../../../hooks/useStore';
import useIncomingBankingTransactionsListPage from '../hooks/useIncomingBankingTransactionsListPage';

const IncomingBankingTransactionsList = () => {
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    refresh,
    localTimeStamp,
    timeStamp,
    selectAllBusy: selectAllBusy_,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useIncomingBankingTransactionsListPage();

  const [columns] = useState<ColumnType[]>([
    {
      width: 500,
      label: getTranslation('general.id'),
      dataKey: 'identification',
      noClick: true
    },
    {
      width: 300,
      label: getTranslation('general.period'),
      dataKey: 'period'
    },
    {
      width: 140,
      label: getTranslation('incoming_banking_transactions.number_of_bank_transfers'),
      dataKey: 'numberOfBankTransfers',
      contentAlignRight: true
    },
    {
      width: 140,
      label: getTranslation('incoming_banking_transactions.reversals'),
      dataKey: 'numberOfReversals',
      contentAlignRight: true
    },
    {
      width: 170,
      label: getTranslation('incoming_banking_transactions.confirmations'),
      dataKey: 'numberOfConfirmations',
      contentAlignRight: true
    },
    {
      width: 240,
      label: getTranslation('banking_transaction.org_bank_account'),
      dataKey: 'companyBankAccount'
    },
    {
      width: 200,
      label: getTranslation('general.created'),
      dataKey: 'created'
    },
    {
      width: 200,
      label: getTranslation('general.status'),
      dataKey: 'status'
    }
  ]);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="incoming-banking-transactions-table"
        tooltipId="incoming-banking-transactions-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        selectedRows={selectedIds}
        sortableFields={sortableFields}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        onSelectRow={setSelectedIds}
        sort={handleSort}
        columns={columns}
        singleSort
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
};

export default observer(IncomingBankingTransactionsList);
