import { action, computed, makeObservable, observable } from 'mobx';

import RootStore from '../';
import { NavigatorType } from '../../hooks/use-selection-navigator';

export enum entityTypes {
  invoice = 'invoice',
  contract = 'contract'
}

class UiStore {
  public rootStore: RootStore;
  public navigator: NavigatorType;
  public entityType: string;

  constructor(rootstore: RootStore) {
    this.rootStore = rootstore;

    makeObservable(this, {
      prefix: computed,
      navigator: observable,
      entityType: observable,
      setEntity: action
    });
  }

  setEntity(navigator: NavigatorType, entityType: string) {
    this.navigator = navigator;
    this.entityType = entityType;
  }

  get prefix() {
    return process.env.REACT_APP_NODE_ENV === 'staging'
      ? `STAG - ${this.rootStore.applicationStore.tenantReducer.organization?.shortCode}`
      : this.rootStore.applicationStore.tenantReducer.organization?.shortCode;
  }

  setBrowserTitle(title: string) {
    document.title = `${this.prefix} - ${title}`;
  }
}

export default UiStore;
