import React from 'react';

import { ImportJobType, TriggerImportJobType } from '@zf/api-types/import-job';
import useDialog from '@zf/hooks/src/useDialog';

import ValidateImportJobDialog from '../../../actions/import-jobs/validate-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  onComplete: () => void;
  importJob: ImportJobType;
};

export default function ValidateImportJobsListItem(props: Props) {
  const { importJob, onComplete } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const importJobToHandle: TriggerImportJobType = {
    etag: importJob._etag,
    id: importJob.id
  };

  return (
    <DropdownAction
      id="import_jobs.validate"
      icon="check"
      dialogTitle={i18n.getTranslation('actions.validate')}
      dialogContent={
        <ValidateImportJobDialog ref={clickRef} onComplete={onComplete} importJobToHandle={importJobToHandle} />
      }
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('actions.validate')}
    />
  );
}
