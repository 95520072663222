import ExportToExcel from 'components/Button/Excel/ExportToExcel';
import { useStore } from 'hooks/useStore';
import React from 'react';
import { getMeteringIssuesExportArray } from 'utils/metering-issues';

import { MeterType } from '@zf/api-types/master-data/meter';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { notify } from 'events/notification-events';

type Props = {
  issues: MeteringIssue[];
  meter?: MeterType;
};

export const ExportIssuesListPageButton = (props: Props) => {
  const { issues } = props;

  const { applicationStore } = useStore();
  const { getTranslation, userStore } = applicationStore;
  const { getUserById } = userStore;

  Promise.all(
    issues.map((i) => {
      return getUserById(i.resolution?.details?.resolvedByUserId);
    })
  ).catch((error) => {
    notify.error({
      content: getTranslation('meter_issues.get_issues_fail'),
      error
    });
  });

  return (
    <ExportToExcel
      id="btn-export-excel"
      buttonType={'normal'}
      key="metering-issue-export"
      disabled={issues.length === 0}
      toExportArray={() => getMeteringIssuesExportArray(issues, applicationStore)}
      title={getTranslation('export_excel.export_metering_issues')}
      icon="export"
      ws_name={getTranslation('meter_issues.metering_issues')}
    />
  );
};
