import { CommentType } from '@zf/api-types/comment';
import { entitySubjectType } from '@zf/api-types/enums';
import { action, makeObservable, observable } from 'mobx';

import RootStore from '..';
import { notify } from '../../../events/notification-events';
import CommentsService from '../../services/activity/CommentsService';

export default class ActivityStore {
  public rootStore: RootStore;

  public comments: CommentType[] | undefined;

  public commentsService: CommentsService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.commentsService = new CommentsService(rootStore.applicationStore);

    makeObservable(this, {
      comments: observable,

      setComments: action,
      initComments: action,
      addComment: action,
      deleteComment: action
    });
  }

  setComments = (newComments: CommentType[] | undefined) => {
    this.comments = newComments;
  };

  initComments = async (subjectType: entitySubjectType, subjectId: string) => {
    this.setComments(await this.commentsService.getComments(subjectType, subjectId));
  };

  addComment = async (subjectType: entitySubjectType, subjectId: string, memo: string) => {
    if (this.comments) {
      try {
        const res = await this.commentsService.addComment(subjectType, subjectId, memo);
        this.comments.push(res);
      } catch (error) {
        notify.error({
          content: this.rootStore.applicationStore.getTranslation('comments.add_fail'),
          error
        });
      }
    }
  };

  deleteComment = async (subjectType: entitySubjectType, subjectId: string, index: number) => {
    if (this.comments) {
      try {
        const toDelete = this.comments[index];

        if (toDelete) {
          await this.commentsService.deleteComment(subjectType, subjectId, toDelete.id);
          this.comments.splice(index, 1);
        }
      } catch (error) {
        notify.error({
          content: this.rootStore.applicationStore.getTranslation('comments.delete_fail'),
          error
        });
      }
    }
  };
}
