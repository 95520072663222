import clone from 'clone';
import { action, computed, makeObservable, observable } from 'mobx';

import { enumTranslationType, ScenarioTranslation } from '@zf/api-types/config/Communication/templates';
import { EntitySubjectSubtype, EntitySubjectUnionType } from '@zf/api-types/config/scenarios_new';
import { culture, documentOutputFormat, templateUsecase, translationStatus } from '@zf/api-types/enums';
import { CultureTableType } from '@zf/api-types/language';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';
import { onlyUnique } from '@zf/utils/src/array';
import { deepEqual } from '@zf/utils/src/object';

import { notify } from '../../../events/notification-events';
import GlobalEnumerationsForm from '../forms/GlobalEnumerationsForm';
import CommunicationStore from './CommunicationStore';

type FilterType = {
  entitySubjectType: EntitySubjectUnionType;
  entitySubjectSubType: EntitySubjectSubtype;
  culture: culture;
  templateUseCase: templateUsecase;
  wrapLabels?: boolean;
  showLabels?: boolean;
};

export type enumFilterState = {
  translationStatus: translationStatus[];
  group: string;
  searchValue: string;
};

export type translationFilterState = {
  searchValue?: string;
  translationStatus?: translationStatus[];
};

export type ParamsType = {
  type: EntitySubjectUnionType;
  subType: EntitySubjectSubtype;
  useCase: templateUsecase;
};

export type EnumTranslationRowType = {
  reset?: React.ReactNode;
  translation?: React.ReactNode;
} & Partial<Record<culture, React.ReactNode>>;

export default class TranslationStore {
  private communicationStore: CommunicationStore;

  public tableColumns: ColumnType[] = [];
  public rows: EnumTranslationRowType[] = [];
  public selectedRow = -1;
  public translations: ScenarioTranslation[] | undefined;
  public selectedTranslation: ScenarioTranslation;
  public initialTranslations: ScenarioTranslation[] | undefined;
  public params: ParamsType;
  public selectedTranslationStatusses: string[] = [];
  public enumTranslations: enumTranslationType[] | undefined;
  public translationsAreLoading = true;
  public enumTranslationsAreLoading = true;
  public updateAllTranslations = false;
  public localCultureTable: CultureTableType | undefined;
  public html?: string;
  public enumerationsForm: GlobalEnumerationsForm;
  public initialState: ScenarioTranslation;
  public initialStateEnum: enumTranslationType[];
  public searchValue: string;
  public previewFilters: Partial<FilterType> = {
    wrapLabels: true,
    showLabels: false
  };
  public enumFilter: enumFilterState = {
    group: '',
    translationStatus: [],
    searchValue: ''
  };
  public translationFilterState: translationFilterState = {
    searchValue: '',
    translationStatus: []
  };

  constructor(communicationStore: CommunicationStore) {
    this.communicationStore = communicationStore;

    makeObservable(this, {
      previewFilters: observable,
      tableColumns: observable,
      searchValue: observable,
      rows: observable,
      translations: observable,
      selectedTranslation: observable,
      params: observable,
      selectedTranslationStatusses: observable,
      enumTranslations: observable,
      updateAllTranslations: observable,
      translationsAreLoading: observable,
      enumTranslationsAreLoading: observable,
      initialState: observable,
      html: observable,
      enumFilter: observable,
      initialStateEnum: observable,
      translationFilterState: observable,
      selectedRow: observable,

      initTemplateTranslations: action,
      generateColumns: action,
      getTranslations: action,
      setSelectedTranslation: action,
      setSearchValue: action,
      setTranslation: action,
      setFilters: action,
      resetEnumTranslation: action,
      getEnumTranslations: action,
      resetSelectedTranslation: action,
      setTranslationsAreLoading: action,
      setEnumTranslationsAreLoading: action,
      setEnumTranslation: action,
      initTranslationsForm: action,
      sethtml: action,
      setEnumFilters: action,
      setTranslationFilter: action,
      setSelectedRow: action,
      setSelectedTranslationByName: action,

      enumIsDirty: computed,
      isDisabled: computed,
      translationIsDirty: computed
    });
  }

  initTranslationsForm = (selectedTranslation: ScenarioTranslation) => {
    this.initialState = clone(selectedTranslation);
  };

  get translationIsDirty() {
    if (this.selectedTranslation && this.initialState) {
      return !deepEqual(this.selectedTranslation.translations, this.initialState.translations);
    }

    return false;
  }

  get isDisabled() {
    if (this.initialState && this.selectedTranslation) {
      return (
        this.selectedTranslation.status === translationStatus.defaulttranslation &&
        deepEqual(this.selectedTranslation.translations, this.initialState.translations)
      );
    }

    return false;
  }

  get enumIsDirty() {
    return this.enumTranslations?.some((e) => e.form?._isDirty);
  }

  get dropdownValues() {
    const values = this.initialStateEnum
      ?.map((e) => {
        return e.enumType;
      })
      .filter(onlyUnique);

    return values?.map((e) => {
      return {
        value: {
          id: e
        },
        text: e
      };
    });
  }

  sethtml = (html: string) => {
    this.html = html;
  };

  setEnumFilters = async (newFilters: Partial<enumFilterState>) => {
    this.enumFilter = { ...this.enumFilter, ...newFilters };
    await this.getEnumTranslations(this.enumFilter);
    let dirtyArray: enumTranslationType[] = [];
    this.enumTranslations?.forEach((t) => {
      Object.keys(t.translations).forEach((key) => {
        const translation = t.translations[key as culture];
        if (translation.includes(this.enumFilter.searchValue)) {
          dirtyArray.push(t);
        }
      });
    });

    this.enumTranslations = dirtyArray;
  };

  getTranslations = async (type: EntitySubjectUnionType, subType: EntitySubjectSubtype, useCase: string) => {
    this.setTranslationsAreLoading(true);
    this.translations = (
      await this.communicationStore.communicationService.getTranslationScenario(type, subType, useCase)
    ).scenarioTranslations;
    this.initialTranslations = clone(this.translations);
    this.setTranslationsAreLoading(false);
  };

  setTranslationsAreLoading = (val: boolean) => {
    this.translationsAreLoading = val;
  };

  setEnumTranslation = (key: culture, translation: enumTranslationType, value: string) => {
    translation.translations[key] = value;
  };

  setEnumTranslationsAreLoading = (val: boolean) => {
    this.enumTranslationsAreLoading = val;
  };

  setSelectedRow = (newIndex: number) => {
    this.selectedRow = newIndex;
  };

  generateColumns = (newEnumTranslations: enumTranslationType[]) => {
    let newTableColumns = [];

    const { getEnumTranslation } = this.communicationStore.rootStore.applicationStore;

    if (newEnumTranslations.length > 0) {
      const reference = newEnumTranslations[0].translations;

      newTableColumns.push({
        flexWidth: 1,
        label: this.communicationStore.rootStore.applicationStore.getTranslation('general.group'),
        dataKey: 'translation'
      });

      Object.keys(reference).forEach((key) => {
        newTableColumns.push({
          flexWidth: 1,
          label: getEnumTranslation('culture', key),
          dataKey: key
        });
      });
    }

    newTableColumns.push({
      flexWidth: 1,
      dataKey: 'reset'
    });

    this.tableColumns = newTableColumns;
  };

  getEnumTranslations = async (filter?: enumFilterState, initialState?: boolean) => {
    this.setEnumTranslationsAreLoading(true);
    let newEnumTranslations = await this.communicationStore.communicationService.getEnumTranslations(filter);
    if (initialState) {
      this.enumFilter = { group: '', translationStatus: [], searchValue: '' };
      this.initialStateEnum = await this.communicationStore.communicationService.getEnumTranslations();
    }

    this.generateColumns(newEnumTranslations);

    newEnumTranslations = newEnumTranslations.map((translation) => {
      translation.form = new GlobalEnumerationsForm(this.communicationStore, translation);

      Object.keys(translation.translations).forEach((key) => {
        translation.form.add({
          name: key,
          label: key,
          value: translation.translations[key as culture]
        });
      });

      return translation;
    });

    this.enumTranslations = newEnumTranslations;
    this.setEnumTranslationsAreLoading(false);
  };

  setCultureTable = (cultureTable: CultureTableType) => {
    this.localCultureTable = cultureTable;
  };

  initTemplateTranslations = async (urlParams: ParamsType) => {
    const cultureTable = await this.communicationStore.rootStore.configStore.configService.getCultureTable();
    this.setCultureTable(cultureTable);

    this.getTranslations(urlParams.type, urlParams.subType, urlParams.useCase);

    this.setTranslationFilter({
      searchValue: '',
      translationStatus: []
    });

    this.setFilters({
      entitySubjectType: urlParams.type,
      entitySubjectSubType: urlParams.subType,
      culture: cultureTable.defaultCulture,
      templateUseCase: urlParams.useCase
    });
  };

  setSelectedTranslation = (translation: ScenarioTranslation) => {
    this.selectedTranslation = translation;
  };

  setSelectedTranslationByName = (name: string) => {
    if (this.translations) {
      const translation = this.translations.findIndex((e) => e.name === name);
      if (translation) {
        this.communicationStore.templatesStore.selectedTranslationIndex = translation;
      }
    }
  };

  setTranslation = (key: culture, content: string) => {
    this.selectedTranslation.translations[key] = content;
  };

  setTranslationFilter = (newFilters: translationFilterState) => {
    this.translationFilterState = { ...this.translationFilterState, ...newFilters };
    if (this.initialTranslations) {
      let dirtyArray: ScenarioTranslation[] = [];
      this.translations = this.initialTranslations;

      this.translations?.forEach((e) => {
        if (e.name.toLowerCase().includes(this.translationFilterState.searchValue?.toLowerCase() || '')) {
          dirtyArray.push(e);
        }
      });

      this.translations = dirtyArray.filter((e) => {
        if (this.translationFilterState.translationStatus) {
          if (this.translationFilterState.translationStatus.length > 0) {
            return this.translationFilterState.translationStatus?.includes(e.status);
          } else {
            return onlyUnique;
          }
        } else {
          return onlyUnique;
        }
      });
    } else {
      this.translations = this.initialTranslations;
    }
  };

  setSearchValue = (value: string) => {
    const pattern = new RegExp(value, 'gi');
    if (this.initialTranslations && value) {
      this.searchValue = value;
      const dirtyArray = [];
      for (const translation of this.initialTranslations) {
        if (pattern.test(translation.name)) {
          dirtyArray.push(translation);

          // const searchIndex = this.translations.findIndex((e) => deepEqual(e, translation));
          this.translations = dirtyArray.filter(onlyUnique);
        }
      }
    } else {
      this.translations = this.initialTranslations;
    }
  };

  resetEnumTranslation = async (model: enumTranslationType, form: GlobalEnumerationsForm, index: number) => {
    try {
      const result = await this.communicationStore.communicationService.resetEnumTranslation(
        model.enumType,
        model.symbol
      );
      model = result;

      form = new GlobalEnumerationsForm(this.communicationStore, model);
      Object.keys(model.translations).forEach((key) => {
        form.add({
          name: key,
          label: key,
          value: model.translations[key as culture]
        });
      });

      if (this.enumTranslations) {
        this.enumTranslations[index].form = form;
      }
    } catch (e) {
      notify.error({
        content: this.communicationStore.rootStore.applicationStore.getTranslation('invoice.validation.reset_failed'),
        error: e
      });
    }
  };

  setUpdateAllTranslations = (val: boolean) => {
    this.updateAllTranslations = val;
  };

  setFilters = async (newFilters: Partial<FilterType>) => {
    this.previewFilters = { ...this.previewFilters, ...newFilters };
    await this.getHtml();
  };

  getHtml = async () => {
    const { entitySubjectType, entitySubjectSubType, culture, templateUseCase, wrapLabels, showLabels } =
      this.previewFilters;

    if (entitySubjectType && entitySubjectSubType && culture && templateUseCase) {
      this.html = (await this.communicationStore.communicationService.getTemplatePreview(
        entitySubjectType,
        entitySubjectSubType,
        culture,
        templateUseCase,
        documentOutputFormat.html,
        wrapLabels,
        showLabels
      )) as string;

      return this.html;
    }

    return undefined;
  };

  handleTranlationStateUpdate = (rowIndex: number, updatedTranslation: ScenarioTranslation) => {
    if (this.translations) {
      this.translations[rowIndex] = updatedTranslation;
    }

    this.setSelectedTranslation(updatedTranslation);

    // Trigger a refetch for the HTML by creating a new object from existing
    this.setFilters({ ...this.previewFilters });
  };

  updateScenarioTranslations = async (rowIndex: number) => {
    const updatedScenarioTranslation = await this.communicationStore.communicationService.updateScenarioTranslations(
      this.selectedTranslation.name,
      {
        updateAllScenarios: this.updateAllTranslations,
        entitySubjectType: this.previewFilters.entitySubjectType,
        entitySubjectSubType: this.previewFilters.entitySubjectSubType,
        translations: this.selectedTranslation.translations
      }
    );

    this.handleTranlationStateUpdate(rowIndex, updatedScenarioTranslation);
  };

  resetSelectedTranslation = async (rowIndex: number, resetAll: boolean) => {
    const resettedTranslation = await this.communicationStore.communicationService.resetScenarioTranslation(
      this.selectedTranslation.name,
      {
        entitySubjectType: this.communicationStore.templatesStore.selectedEntitySubjectType,
        entitySubjectSubType: this.communicationStore.templatesStore.selectedEntitySubjectSubtype,
        updateAllScenarios: resetAll
      }
    );

    if (resettedTranslation) {
      this.initTranslationsForm(resettedTranslation);
      this.handleTranlationStateUpdate(rowIndex, resettedTranslation);
    }
  };
}
