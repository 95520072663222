import React from 'react';

import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../app-context/app-context';
import Button from '../../components/Button/Button';
import ResetIcon from '../../components/Icon/ResetIcon';
import ColorInput from '../../components/input/ColorInput';
import { BaseButton } from '../../design-system/Components';
import { IntersectionType } from '../../features/property-group/detail-page/settings/context/settings-context';
import css from './extras-card.module.scss';

export type ExtrasType = {
  logo: string | null;
  primaryColor: string | null;
  secondaryColor: string | null;
};

type Props = {
  extras: Partial<ExtrasType>;
  organisationConfig?: OrganizationConfigType;
  setExtrasValue: (value: Partial<ExtrasType>) => void;
  resetCommConfigValue?: (key: keyof IntersectionType) => void;
};

export default function ExtraCard(props: Props) {
  const { extras, organisationConfig, setExtrasValue, resetCommConfigValue } = props;
  const { i18n } = useAppContext();

  const handleImageSelect = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.multiple = false;

    inputElement.addEventListener('change', function () {
      if (!this.files) return;
      const reader = new FileReader();
      reader.readAsDataURL(this.files[0]);
      reader.onload = () => {
        setExtrasValue({ logo: reader.result ? reader.result.toString() : undefined });
      };
    });
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <Card id="extra-card" width="1">
      <CardHeader>{i18n.getTranslation('organization.extra')}</CardHeader>
      <CardBody type="grid" noMaxHeight>
        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.primary_color')}</Label>
        </CardItem>
        <CardItem width="6">
          <ColorInput
            id="propertygroup.primary_color"
            value={extras.primaryColor}
            onChange={(val) => setExtrasValue({ primaryColor: val })}
            reset={resetCommConfigValue ? () => resetCommConfigValue('primaryColor') : undefined}
            resetValue={organisationConfig?.primaryColor}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.secondary_color')}</Label>
        </CardItem>
        <CardItem width="6">
          <ColorInput
            id="propertygroup.secondary_color"
            value={extras.secondaryColor}
            onChange={(val) => setExtrasValue({ secondaryColor: val })}
            reset={resetCommConfigValue ? () => resetCommConfigValue('secondaryColor') : undefined}
            resetValue={organisationConfig?.secondaryColor}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.logo')}</Label>
        </CardItem>
        <CardItem width="6">
          {!extras.logo ? (
            <Button id="propertygroup.logo" onClick={handleImageSelect} type="primary" size="small">
              {i18n.getTranslation('organization.select_logo')}
            </Button>
          ) : (
            <div className={css['select-logo']}>
              <BaseButton id="propertygroup.select-logo" onClick={handleImageSelect}>
                <img className={css['image']} src={extras.logo} alt="logo" />
              </BaseButton>
              {resetCommConfigValue && extras.logo !== organisationConfig?.logo && (
                <ResetIcon id="propertygroup.reset_logo" onClick={() => resetCommConfigValue('logo')} />
              )}
            </div>
          )}
        </CardItem>
      </CardBody>
    </Card>
  );
}
