import React from 'react';

import { SocialTariffExportType } from '@zf/api-types/regionals/regionals';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import CreateSocialTariffDialog from '../dialogs/create-social-tariff-dialog';

type Props = {
  refresh: () => void;
};

export default function CreateSocialTariffListPageButton(props: Props) {
  const { i18n } = useAppContext();
  const { refresh } = props;
  const { setUpdatedRows } = useUpdateListPage<SocialTariffExportType>('country_specifics');
  const { clickRef, validationRef, onSubmit } = useDialog();

  const createText = i18n.getTranslation('actions.create');

  return (
    <DropdownAction
      id="country_specifics.social_tarif.create"
      icon="plus"
      dialogTitle={createText}
      dialogContent={
        <CreateSocialTariffDialog
          validationRef={validationRef}
          ref={clickRef}
          setUpdatedRows={setUpdatedRows}
          refresh={refresh}
        />
      }
      validationRef={validationRef}
      onSubmit={onSubmit}
      buttonPositive={createText}
    />
  );
}
