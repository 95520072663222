import { action, computed, makeObservable, observable } from 'mobx';

export type StepValidationType = Record<string, { isValid: boolean; validationMessage: string }>;

export default class WizardNavigator<S extends string> {
  public activeStep: S | undefined;
  public stepValidations: Record<S, StepValidationType>;

  constructor(defaultActiveStep: S) {
    this.stepValidations = {} as Record<S, StepValidationType>;
    this.activeStep = defaultActiveStep;

    makeObservable(this, {
      activeStep: observable,
      stepValidations: observable,

      hasErrors: computed,

      setActiveStep: action,
      setStepValidation: action,
      reset: action
    });
  }

  get hasErrors() {
    return Object.values<StepValidationType>(this.stepValidations).some((validation) =>
      Object.values(validation).some((v) => !v.isValid)
    );
  }

  setActiveStep = (step: S) => {
    this.activeStep = step;
  };

  setStepValidation = (step: S, field: string, isValid: boolean, validationMessage: string) => {
    const stepValidationToUpdate = this.stepValidations[step] as StepValidationType;

    // Init entry if it doesn't exist yet
    if (!stepValidationToUpdate) {
      const stepValidation = {} as StepValidationType;
      stepValidation[field] = { isValid, validationMessage };
      this.stepValidations[step] = stepValidation;
    } else {
      stepValidationToUpdate[field] = { isValid, validationMessage };
    }
  };

  reset = () => {
    this.stepValidations = {} as Record<S, StepValidationType>;
    this.activeStep = undefined;
  };
}
