import classNames from 'classnames';
import React from 'react';

import { Icon } from '../Icon';
import css from './config-card.module.scss';

type Props = {
  id: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  buttons?: JSX.Element;
  hoverable?: boolean;
  isActive?: boolean;
  showRadioBtn?: boolean;
  action?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export default function ConfigCard(props: Props) {
  const {
    id,
    children,
    buttons,
    className,
    style = {},
    hoverable = true,
    isActive = false,
    showRadioBtn = false,
    action = () => {}
  } = props;

  return (
    <div
      id={id}
      className={classNames(
        css['config-card'],
        {
          [css['config-card-hover']]: hoverable,
          [css['active']]: isActive
        },
        className
      )}
      style={style}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Tab') action();
      }}
      onClick={action}
    >
      {children}
      {buttons}
      {isActive && showRadioBtn && <Icon name="radio-btn" className={css['radio-btn']} />}
    </div>
  );
}
