import classNames from 'classnames';
import React from 'react';

import useFocus from '@zf/hooks/src/useFocus';
import eventChain from '@zf/utils/src/eventChain';

import css from './multi-line-input.module.scss';

export type MultilineInputProps = {
  value?: string;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  style?: React.CSSProperties;
  hideLabel?: boolean;
  success?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  rows?: number;
  resize?: boolean;
  selectAllOnFocus?: boolean;
  onFocus?: () => void;
  onClick?: () => void;
  onBlur?: () => void;
  onSelect?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onChange: (value: string) => void;
};

export default function StellaMultiLineInput(props: MultilineInputProps) {
  const {
    placeholder,
    id,
    className,
    hideLabel,
    success,
    error,
    style = {},
    value = '',
    selectAllOnFocus = false,
    disabled = false,
    readonly = false,
    autoFocus = false,
    rows = 5,
    resize = true,
    onChange,
    onFocus,
    onBlur,
    onSelect,
    onKeyDown = () => {},
    ...otherProps
  } = props;

  const [focus, setFocus] = React.useState(autoFocus);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  useFocus(focus, inputRef, selectAllOnFocus);

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Escape') {
      handleBlur();
    }

    onKeyDown(e);
  };

  return (
    <div
      className={classNames(
        css['multi-line-input'],
        {
          [css['disabled']]: disabled,
          [css['focused']]: focus,
          [css['error']]: error,
          [css['success']]: success
        },
        className
      )}
      style={style}
    >
      {placeholder && (
        <label
          htmlFor={id}
          className={classNames(css['label'], {
            [css['hidden-label']]: !!hideLabel
          })}
        >
          {placeholder}
        </label>
      )}
      <textarea
        value={value ? value : ''}
        onChange={handleChange}
        id={id}
        disabled={disabled}
        readOnly={readonly}
        className={css['textarea']}
        key={`${id}-multi-line-input`}
        ref={inputRef}
        onFocus={eventChain(handleFocus, onFocus)}
        onBlur={eventChain(handleBlur, onBlur)}
        onSelect={onSelect}
        onKeyDown={handleKeyDown}
        rows={rows}
        style={{ resize: resize ? 'initial' : 'none' }}
        {...otherProps}
      />
    </div>
  );
}
