import React from 'react';

import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';

import { useAppContext } from '../../app-context';
import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { CrumbHeader } from '../../components/Header';
import ZFRouter from '../../components/Router/ZFRouter';
import { SuspenseSpinner } from '../../components/suspense';
import Route from '../route';
import DataProviderTab from './data-providers/data-provider-tab';
import FileFormatsTab from './file-formats/file-formats-tab';

type Props = {
  navigate: (href: string) => void;
};

export default function ImportJobsConfig(props: Props) {
  const { navigate } = props;

  const { i18n } = useAppContext();

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  const splitUrl = window.location.href.split('/');

  const fileFormatsTab = () => (
    <SuspenseSpinner>
      <FileFormatsTab />
    </SuspenseSpinner>
  );

  const dataExportTab = () => (
    <SuspenseSpinner>
      <DataProviderTab />
    </SuspenseSpinner>
  );

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="cog" text={i18n.getTranslation('import_jobs_config.import_jobs')} />
          </BreadCrumb>
        }
      />
      <TabContainer
        onSelectTab={setActiveTab}
        entityId={i18n.getTranslation('nav.import-jobs')}
        selectedTab={splitUrl[splitUrl.length - 1]}
        tabItems={[
          {
            id: 'file_formats',
            title: i18n.getTranslation('file_format.file_formats'),
            isDefault: true
          },
          {
            id: 'data_providers',
            title: i18n.getTranslation('data_provider.data_providers')
          }
        ]}
      >
        <ZFRouter>
          <Route Component={fileFormatsTab} path="file_formats" default />
          <Route Component={dataExportTab} path="data_providers" />
        </ZFRouter>
      </TabContainer>
    </>
  );
}
