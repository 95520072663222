import { observer } from 'mobx-react';
import React from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import SubTitle from '@zf/stella-react/src/atoms/Tabs/SubTitle';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import { NavigatorType } from '../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../components/RouteWrapper';
import GenericTabContainer from '../../../components/Tab/GenericTabContainer';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import Route from '../../route';
import ServiceConsumptions from './consumptions/ServiceConsumptions';
import LocationDetail from './detail/LocationDetail';
import LocationActions from './LocationActions';

type Props = {
  location: ServiceLocationType;
  status: JSX.Element;
  navigator: NavigatorType;
  timeStamp: string;
  navigate: (url: string) => void;
  setLocation: React.Dispatch<React.SetStateAction<ServiceLocationType | null>>;
};

const LocationTabContainer = (props: Props) => {
  const { location: serviceLocation, status, navigator, timeStamp, navigate, setLocation } = props;
  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const formattedAddress = serviceLocation.address.localizedDisplay || '';

  useBrowserTitle(`${getTranslation('location.serviced_location')} - ${formattedAddress}`);

  let subTitle: React.ReactNode = '';

  if (serviceLocation.propertyGroup) {
    subTitle = (
      <SubTitle>
        {getTranslation('location.part_of')}
        <Link
          id={`property-${serviceLocation.propertyGroup.id}`}
          url={`${rootUrl}/property-groups/${serviceLocation.propertyGroup.id}`}
          sub
        >
          {serviceLocation.propertyGroup.name}
        </Link>
      </SubTitle>
    );
  }

  return (
    <GenericTabContainer
      actions={<LocationActions serviceLocation={serviceLocation} />}
      navigator={navigator}
      entityId={formattedAddress}
      status={status}
      entityType={routedEntitySubjectType.servicelocation}
      navigate={navigate}
      iconName="location"
      subTitle={subTitle}
      tabItems={[
        {
          id: 'detail',
          title: getTranslation('nav_detail.detail'),
          isDefault: true
        },
        {
          id: 'consumption_values',
          title: getTranslation('nav_detail.consumption_values')
        }
      ]}
      routes={
        <RouteWrapper default>
          <Route
            Component={LocationDetail}
            path="detail"
            serviceLocation={serviceLocation}
            setLocation={setLocation}
            default
          />
          <Route
            Component={ServiceConsumptions}
            path="consumption_values"
            serviceLocation={serviceLocation}
            timeStamp={timeStamp}
          />
        </RouteWrapper>
      }
    />
  );
};

export default observer(LocationTabContainer);
