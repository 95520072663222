import React from 'react';

import { ImportJobOverviewCountType } from '@zf/api-types/import-job';

import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import ImportJobTabContainer, { ImportJobTabContainerProps } from './ImportJobTabContainer';

export default function ProcessingDetails(props: ImportJobTabContainerProps) {
  const { importJob, status, navigator, navigate } = props;

  const overviewPerEntityResponse = useSuspenseSingleAPI<ImportJobOverviewCountType>({
    request: {
      endpoint: `/logs/ImportJob/${importJob.id}/overviewcount`
    }
  });

  if (!overviewPerEntityResponse.result) return null;

  const overviewPerEntity = overviewPerEntityResponse.result.data.overviewPerEntity;

  return (
    <ImportJobTabContainer
      importJob={importJob}
      status={status}
      navigator={navigator}
      overviewPerEntity={overviewPerEntity}
      navigate={navigate}
    />
  );
}
