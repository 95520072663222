import { observer } from 'mobx-react';
import React, { useEffect, useReducer } from 'react';

import { BankAccountType } from '@zf/api-types/general';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { formatIBAN } from '@zf/utils/src/iban';

import { useStore } from '../../../hooks/useStore';
import Dropdown from '../../Lang/Dropdown';

export type BankAccountsDropdownProps = {
  id: string;
  isInline?: boolean;
  selectedValue?: string;
  showDefault?: boolean;
  error?: boolean;
  renderTopLvl?: boolean;
  onFocus?: (step: string) => void;
  onChange: (values: BankAccountType[]) => void;
};

type State = {
  accounts: BankAccountType[] | undefined;
  defaultAccount: BankAccountType | undefined;
};

const InlineDropdown = InlineInputField<DropdownProps<BankAccountType>>(Dropdown);

const BankAccountsDropdown = (props: BankAccountsDropdownProps) => {
  const {
    id,
    selectedValue = '',
    isInline = false,
    error = false,
    showDefault = true,
    renderTopLvl = true,
    onFocus,
    onChange
  } = props;

  const { configStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getBankAccounts } = configStore.configService;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    accounts: undefined,
    defaultAccount: undefined
  });

  useEffect(() => {
    getBankAccounts().then((res) => {
      const defaultAccount = res.find((acc) => acc.isDefault);
      setState({
        accounts: res,
        defaultAccount: defaultAccount
      });
      if (!selectedValue && defaultAccount && showDefault) {
        onChange([defaultAccount]);
      }
    });
  }, []);

  if (!state.accounts) {
    return isInline ? (
      <InlineDropdown
        id={id}
        onChange={onChange}
        values={[]}
        selectedValues={[selectedValue]}
        renderTopLvl={renderTopLvl}
        onFocus={onFocus}
      />
    ) : (
      <Dropdown
        id={id}
        onChange={onChange}
        placeholder={getTranslation('organization.bank_account')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    );
  }

  const defaultId = state.defaultAccount?.id || '';

  const dropdownValues =
    state.accounts?.map((acc) => {
      return {
        value: acc,
        text: `${formatIBAN(acc.iban)} - ${acc.accountHolder} ${
          acc.id === defaultId ? ` - (${getTranslation('general.default')})` : ''
        }`
      };
    }) || [];

  const selectedValues = showDefault ? [selectedValue || defaultId] : [selectedValue || ''];

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={dropdownValues}
      selectedValues={selectedValues}
      onFocus={onFocus}
      error={error}
      renderTopLvl={renderTopLvl}
    />
  ) : (
    <Dropdown
      id={id}
      onChange={onChange}
      placeholder={getTranslation('organization.bank_account')}
      values={dropdownValues}
      selectedValues={selectedValues}
      onFocus={onFocus}
      error={error}
      renderTopLvl={renderTopLvl}
    />
  );
};

export default observer(BankAccountsDropdown);
