import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { utilityType } from '@zf/api-types/enums';
import { ConsumerGroupType } from '@zf/api-types/forecasting';
import { DropdownProps, DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useStore } from '../../../../hooks/useStore';
import Dropdown from '../../../../components/Lang/Dropdown';

type Props = {
  id: string;
  utilityType: utilityType;
  configuredConsumerGroupId: string | undefined;
  setFctConfigValue: (utilityType: utilityType, consumerGroupId: string, consumerGroupCode: string) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<ConsumerGroupType>>(Dropdown);

const ConsumptionGroupDropdown = (props: Props) => {
  const { id, utilityType, configuredConsumerGroupId, setFctConfigValue } = props;
  const { propertyGroupStore } = useStore();
  const { getConsumerGroupsForUtilityType } = propertyGroupStore.propertyGroupService;

  const [dropdownGroups, setDropdownGroups] = useState<DropdownValuesType<ConsumerGroupType>[]>([]);

  useEffect(() => {
    getConsumerGroupsForUtilityType(utilityType).then((groups) =>
      setDropdownGroups(
        groups.map((g) => {
          return {
            value: g,
            text: g.code
          };
        })
      )
    );
  }, []);

  return (
    <InlineDropdown
      id={id}
      onChange={(val) => setFctConfigValue(utilityType, val[0]?.id, val[0]?.code)}
      values={dropdownGroups}
      selectedValues={[configuredConsumerGroupId || '']}
      error={!configuredConsumerGroupId}
    />
  );
};

export default observer(ConsumptionGroupDropdown);
