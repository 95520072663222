import React from 'react';

import {
  CalculationConfigurationBillingItemType,
  PropertyGroupCalculationConfigurationType
} from '@zf/api-types/property-group-billing-configuration';

import { useAppContext } from '../../../../../../app-context';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import AllocationGroupTree from '../allocation-group-tree';

type Props = {
  isDirty: boolean;
  calculationConfigurations: PropertyGroupCalculationConfigurationType[];
  setSelectedBillingItem: (item: CalculationConfigurationBillingItemType) => void;
};

export default function BillingItems(props: Props) {
  const { i18n } = useAppContext();
  const { calculationConfigurations, isDirty } = props;

  return (
    <ConfigColumn
      title={i18n.getTranslation('billing_items.billing_items')}
      subTitleBelow={i18n.getTranslation('property_groups.tabs.billing.cost_calc_billing_item_subtitle')}
      borderRight
    >
      {calculationConfigurations.length > 0 ? (
        calculationConfigurations.map((cc, index) => {
          return (
            <AllocationGroupTree
              key={`${cc.name}-${index}`}
              calculationConfiguration={cc}
              index={index}
              isDirty={isDirty}
            />
          );
        })
      ) : (
        <EmptyColumn
          image="attributes2"
          title="Billing items"
          text={i18n.getTranslation('property_groups.tabs.billing.cost_calc_billing_items_empty_state')}
        />
      )}
    </ConfigColumn>
  );
}
