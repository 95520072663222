import React from 'react';

import { ImportJobRowType } from '@zf/api-types/import-job';
import useDialog from '@zf/hooks/src/useDialog';

import ValidateImportJobDialog from '../../../actions/import-jobs/validate-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  onComplete: () => void;
};

export default function ValidateImportJobsListPageButton(props: Props) {
  const { onComplete } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('importjob');
  const selectedRows: ImportJobRowType[] = myCurrent.listPage.selectedRows;
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    !!selectedRows.find((r) => {
      return r.__importJobEntity.importType !== 'migration';
    }) || selectedRows.length === 0;

  return (
    <DropdownAction
      id="import_jobs.validate"
      icon="check"
      dialogTitle={i18n.getTranslation('actions.validate')}
      dialogContent={<ValidateImportJobDialog onComplete={onComplete} ref={clickRef} selectedRows={selectedRows} />}
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('actions.validate')}
    />
  );
}
