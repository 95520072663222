import moment from 'moment';

import { aggregationFrequency, unitOfMeasure } from '@zf/api-types/enums';

import { formatDateTime } from '@zf/utils/src/date';

export type GroupByViewType = Exclude<aggregationFrequency, 'free'>;

export const EMPTY_Y_AXIS = 'empty';
export const LEFT_Y_AXIS = 'left';
export const RIGHT_Y_AXIS = 'right';

export const formatLabel = (lbl: string, groupByPeriod: aggregationFrequency) => {
  switch (groupByPeriod) {
    case aggregationFrequency.yearly:
      return `${moment(lbl).year()}`;
    case aggregationFrequency.monthly:
      return moment(lbl).format('MMM/YYYY');
    case aggregationFrequency.none:
      return formatDateTime(lbl);
    case aggregationFrequency.daily:
      return moment(lbl).format('D MMM');
  }
};

export const determineAxisId = (uom: string, description: string, type: string): string => {
  switch (uom) {
    case 'gj':
    case 'mj':
    case 'm3':
    case 'k':
    case 'kwh':
    case 'wh':
    case 'mwh':
    case 'gwh':
      return `${description}-${LEFT_Y_AXIS}-${type}`;

    case 'm3_h':
    case 'j':
      return `${description}-${RIGHT_Y_AXIS}-${type}`;

    default:
      return `${description}-${EMPTY_Y_AXIS}-${type}`;
  }
};

export const sameUnit = (first: unitOfMeasure, second: unitOfMeasure) => {
  if (first == null || second == null) return false;
  if (first === second) return true;
  if (
    (first === unitOfMeasure.j ||
      first === unitOfMeasure.kj ||
      first === unitOfMeasure.gj ||
      first === unitOfMeasure.mj) &&
    (second === unitOfMeasure.j ||
      second === unitOfMeasure.kj ||
      second === unitOfMeasure.gj ||
      second === unitOfMeasure.mj)
  )
    return true;
  if (
    (first === unitOfMeasure.wh ||
      first === unitOfMeasure.kwh ||
      first === unitOfMeasure.mwh ||
      first === unitOfMeasure.gwh) &&
    (second === unitOfMeasure.wh ||
      second === unitOfMeasure.kwh ||
      second === unitOfMeasure.mwh ||
      second === unitOfMeasure.gwh)
  )
    return true;
  if (
    (first === unitOfMeasure.litre || first === unitOfMeasure.m3) &&
    (second === unitOfMeasure.litre || second === unitOfMeasure.m3)
  )
    return true;
  if (
    (first === unitOfMeasure.day || first === unitOfMeasure.month || first === unitOfMeasure.year) &&
    (second === unitOfMeasure.day || second === unitOfMeasure.month || second === unitOfMeasure.year)
  )
    return true;
  return false;
};

export const formatXAxisDate = (type: aggregationFrequency, date: string) => {
  switch (type) {
    case aggregationFrequency.yearly:
      return `${moment(date).year()}`;
    case aggregationFrequency.monthly:
      return moment(date).format('MMM/YYYY');
    case aggregationFrequency.daily:
    case aggregationFrequency.none:
      return moment(date).format('DD/MMM/YYYY');

    default:
      return `${moment(date).year()}`;
  }
};

export const formatXAxisDateShort = (type: aggregationFrequency, date: string) => {
  switch (type) {
    case aggregationFrequency.yearly:
      return `${moment(date).year()}`;
    case aggregationFrequency.monthly:
      return moment(date).format("MM/'YY");
    case aggregationFrequency.daily:
    case aggregationFrequency.none:
      return moment(date).format("DD/MM/'YY");
    default:
      return `${moment(date).year()}`;
  }
};

export const toNumber = (s: string | number | undefined) => {
  if (s && typeof s === 'string') {
    return parseFloat(s);
  } else if (typeof s === 'number') {
    return s;
  }
  return 0;
};

export function sortLongestRangeFirst<T extends { rawData: any[] }>(a: T, b: T) {
  if (b.rawData.length < a.rawData.length) {
    return -1;
  }
  if (b.rawData.length > a.rawData.length) {
    return 1;
  }
  return 0;
}

export const parseGraphTime = (val: string, format: string) => moment(new Date(val)).format(format);
