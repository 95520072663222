import { observer } from 'mobx-react';
import React from 'react';

import { Label } from '@zf/stella-react/src/atoms/Label';
import Center from '@zf/stella-react/src/helpers/Center';
import { colors } from '@zf/utils/src/color';
import { formatDate, isMinDate } from '@zf/utils/src/date';

import CheckBox from '../../../../../../../components/input/CheckBox';
import DatePicker from '../../../../../../../components/Lang/DatePicker';
import { Paragraph } from '../../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../../hooks/useStore';
import { InvoicePeriodState } from './AdjustInvoicePeriodDialog';
import css from './intermediate-invoice-dialog.module.scss';

type Props = {
  state: InvoicePeriodState;
  hasDifferentProductInFuture: boolean;
  setState: React.Dispatch<Partial<InvoicePeriodState>>;
};

const InputsSection = (props: Props) => {
  const { state, hasDifferentProductInFuture, setState } = props;
  const { contractStore, applicationStore } = useStore();
  const { latestOpenCompleteness, selectedCompleteness } = contractStore.billingCompletenessStore;
  const { getTranslation } = applicationStore;

  const getNewPeriodStartDate = () => {
    if (state.type === 'shorten') {
      return selectedCompleteness.periodStartDateTime;
    } else {
      return state.fromFirstOpenPeriod
        ? latestOpenCompleteness?.periodStartDateTime
        : latestOpenCompleteness?.periodEndDateTime;
    }
  };

  return (
    <>
      {state.type === 'lengthen' && latestOpenCompleteness && (
        <>
          {!state.fromFirstOpenPeriod && (
            <>
              <Center>
                <Label>{getTranslation('invoice.current_open_period')}</Label>
              </Center>

              <div className={css['period-wrapper']}>
                <Paragraph>{formatDate(latestOpenCompleteness.periodStartDateTime)}</Paragraph>
                <Paragraph>-</Paragraph>
                <Paragraph>{formatDate(latestOpenCompleteness.periodEndDateTime)}</Paragraph>
              </div>
            </>
          )}

          <Center>
            <Label>{getTranslation(`invoice.${state.fromFirstOpenPeriod ? 'new' : 'next'}_open_period`)}</Label>
          </Center>
        </>
      )}

      {state.type === 'lengthen' && hasDifferentProductInFuture ? (
        <div className={css['period-wrapper']}>
          <Paragraph>{formatDate(getNewPeriodStartDate())}</Paragraph>
          <Paragraph>-</Paragraph>

          <div className={css['date-wrapper']}>
            <DatePicker
              id="end-date"
              value={state.endDateTime}
              onChange={(endDateTime) => setState({ endDateTime })}
              placeholder={getTranslation('general.end_date')}
              error={!state.endDateTime || isMinDate(state.endDateTime)}
            />
            {
              <Paragraph color={colors['red-600']}>
                {getTranslation('contracts.billing_action_adjust_invoice_period_overlapping_product_switch')}
              </Paragraph>
            }
          </div>
        </div>
      ) : (
        <div className={css['period-wrapper']}>
          <Paragraph>{formatDate(getNewPeriodStartDate())}</Paragraph>
          <Paragraph>-</Paragraph>

          <DatePicker
            id="end-date"
            value={state.endDateTime}
            onChange={(endDateTime) => setState({ endDateTime })}
            placeholder={getTranslation('general.end_date')}
            error={
              !state.endDateTime ||
              isMinDate(state.endDateTime) ||
              (state.type === 'shorten' &&
                !(!state.endDateTime || isMinDate(state.endDateTime)) &&
                (state.endDateTime.isAfter(selectedCompleteness.periodEndDateTime) ||
                  state.endDateTime.isBefore(selectedCompleteness.periodStartDateTime)))
            }
          />
        </div>
      )}

      {state.type === 'lengthen' && (
        <div className={css['input-wrapper']}>
          <CheckBox
            id="fromFirstOpenPeriod"
            checked={state.fromFirstOpenPeriod}
            onChange={(fromFirstOpenPeriod) => setState({ fromFirstOpenPeriod })}
          >
            {getTranslation('invoice.from_first_open_period')}
          </CheckBox>
        </div>
      )}
    </>
  );
};

export default observer(InputsSection);
