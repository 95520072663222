import React from 'react';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';
import { Controller, Control } from 'react-hook-form';
import { CustomerGroupSettings } from '@zf/api-types/master-data/customer';

export const Toggle = ({
  control,
  name,
  submitFunction,
  defaultValue
}: {
  control: Control<CustomerGroupSettings>;
  name: keyof CustomerGroupSettings;
  submitFunction: Function;
  defaultValue: boolean;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <ZFToggle
          onChange={(val: boolean) => {
            onChange(val);
            submitFunction(val);
          }}
          checked={value === null ? false : value}
        />
      )}
    />
  );
};
