import { observer } from 'mobx-react';
import React from 'react';

import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import { Disabled } from '../../../../../design-system/Components';
import { AddEditType } from '../../../Dialog/types';
import { AttachmentState } from '../../logic/AttachmentGroupBase';
import FilesStore from '../../logic/FilesStore';
import Options from './Options';
import { AttachmentFileType } from './Translation';
import Translations from './Translations';
import css from './translations.module.scss';

type Props<T> = {
  filesStore: FilesStore<T>;
  useCase: AddEditType;
  setState?: React.Dispatch<Partial<AttachmentState>>;
  state?: AttachmentState;
  portalEnabled: boolean;
};

const AttachmentsAdapter = <T,>(props: Props<T>) => {
  const { filesStore, setState, state, useCase, portalEnabled } = props;
  const { addEditEntityAttachmentForm } = filesStore;
  const { attachmentFiles } = addEditEntityAttachmentForm._values;

  const compatibleAttachmentFiles = attachmentFiles.map((af) => {
    return {
      ...af.attachmentFileForm._values
    };
  });

  const setAttachmentValue = (index: number, val: Partial<AttachmentFileType>) => {
    const matchingForm = attachmentFiles[index]?.attachmentFileForm;

    if (matchingForm) {
      Object.keys(val).forEach((key) => {
        // @ts-ignore
        matchingForm._set(key, val[key]);
      });
    }
  };

  return (
    <div className={css['dialog-wrapper']}>
      <Disabled disabled={useCase === 'edit'}>
        <Translations attachmentFiles={compatibleAttachmentFiles} setAttachmentValue={setAttachmentValue} />
      </Disabled>
      <HorizontalDivider className={css['divider']} />
      {/*Rework this bit to pass mobx state to dialog content because right now this isn't possible
      right now propertygroups can only contain options so it can be coded this way. */}
      {state && setState && (
        <Options
          alwaysShowInPortal
          hasPortal={portalEnabled}
          state={filesStore.rootStore.propertyGroupStore.entityAttachmentFeatures.attachmentState}
          setState={filesStore.rootStore.propertyGroupStore.entityAttachmentFeatures.setAttachmentState}
        />
      )}
    </div>
  );
};

export default observer(AttachmentsAdapter);
