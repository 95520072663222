import { Paragraph } from 'design-system/Foundation';
import { observer } from 'mobx-react';
import React from 'react';

import { invoiceStatus } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatMoney } from '@zf/utils/src/number';

import MoneyInput from '../../../../../../../../components/input/MoneyInput';
import { QuickTip } from '../../../../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../../../../hooks/useStore';
import NewAdvanceAmountTip from './NewAdvanceAmountTip';
import css from './related-details-card-body.module.scss';

export type PropsType = {
  invoice: InvoiceType;
};

const NewAdvanceAmount = (props: PropsType) => {
  const { invoice } = props;

  const { applicationStore, invoiceStore } = useStore();
  const { culture, getTranslation } = applicationStore;
  const { relatedDetailsForm } = invoiceStore;

  if (!relatedDetailsForm || !invoice.advanceCalculationDetails) return null;

  const { newAdvanceAmount } = relatedDetailsForm._values;

  return (
    <>
      <CardItem width="6">
        <div className={css['new-adv-amount']}>
          <Label>{getTranslation('contracts.new_advance_amount')}</Label>
          <QuickTip
            id="new-adv-amount-tip"
            interweave={<NewAdvanceAmountTip advanceCalculationDetails={invoice.advanceCalculationDetails} />}
          />
        </div>
      </CardItem>
      <CardItem width="6">
        {invoice.status !== invoiceStatus.created ? (
          <Paragraph textAlign="right">{formatMoney(newAdvanceAmount, culture)}</Paragraph>
        ) : (
          <MoneyInput
            id="new-advance-amount"
            onChange={(val) => relatedDetailsForm._set('newAdvanceAmount', val)}
            value={newAdvanceAmount}
            error={!!relatedDetailsForm._error('newAdvanceAmount')}
            singleError={relatedDetailsForm._error('newAdvanceAmount')}
          />
        )}
      </CardItem>
    </>
  );
};

export default observer(NewAdvanceAmount);
