import React from 'react';

import { AggregatedDetailType } from '@zf/api-types/api';
import { splitArrayIntoChunksOfLength } from '@zf/utils/src/array';
import { formatDecimal } from '@zf/utils/src/number';
import { capitalizeFirstWord } from '@zf/utils/src/string';

import { useAppContext } from '../../app-context';
import css from './basic-stats.module.scss';

type Props = {
  aggregateDetails: AggregatedDetailType[];
  uom: string;
};

export default function BasicStats(props: Props) {
  const { aggregateDetails, uom } = props;
  const { i18n } = useAppContext();

  const splittedAggDetails = splitArrayIntoChunksOfLength(aggregateDetails, 2);

  return (
    <div className={css['basic-stats']}>
      {splittedAggDetails.map((aDetailChunk, index1) => {
        return (
          <div key={index1} className={css['stat-column']}>
            {aDetailChunk.map((aDetail) => {
              if (typeof aDetail.value === 'number') {
                aDetail.value = formatDecimal(aDetail.value, i18n.culture);
              }

              return aDetail.value !== null ? (
                <React.Fragment key={aDetail.code}>
                  <div>{capitalizeFirstWord(aDetail.translation)}</div>
                  <div>{`${aDetail.value} ${uom}`}</div>
                </React.Fragment>
              ) : null;
            })}
          </div>
        );
      })}
    </div>
  );
}
