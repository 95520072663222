import React from 'react';

import ClickableIcon from '../Icon/clickable-icon';
import StellaInputField from './StellaInputField';

export type PasswordInputProps = {
  value: string | undefined;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  hideLabel?: boolean;
  error?: boolean;
  className?: string;
  onChange: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onClick?: () => void;
  onBlur?: () => void;
};

export default React.forwardRef(
  (
    props: PasswordInputProps,
    ref: React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null | undefined
  ) => {
    const { value, id, placeholder, disabled, readonly, hideLabel, error, className, onChange, onFocus, onBlur } =
      props;

    const [visible, setVisible] = React.useState(false);

    const changeVisibility = React.useCallback(() => setVisible(!visible), [visible, setVisible]);

    const handleBlur = () => {
      if (onBlur) onBlur();
      if (visible) changeVisibility();
    };

    const iconType = visible ? 'eye-crossed' : 'eye-open';

    return (
      <StellaInputField
        type={visible ? '' : 'password'}
        onChange={onChange}
        value={value}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        readonly={readonly}
        onFocus={onFocus}
        onBlur={handleBlur}
        hideLabel={hideLabel}
        error={error}
        ref={ref}
        iconRight={<ClickableIcon name={iconType} ariaLabel="view-password" onTrigger={changeVisibility} skipTab />}
        className={className}
      />
    );
  }
);
