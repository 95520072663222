import React, { useState } from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import { colors } from '@zf/utils/src/color';

import IconParagraph from '../../../../../components/Icon/IconParagraph';
import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { Button } from '../../../../../design-system/Components';
import { useStore } from '../../../../../hooks/useStore';
import { State } from './change-product-dialog';
import css from './change-product-dialog.module.scss';
import useCreditedInvoicesTable from './hooks/useCreditedInvoicesTable';

type Props = {
  contracts: ContractType[];
  date: string;
  showInvoiceCredited: boolean;
  setState: React.Dispatch<Partial<State>>;
};

const CreditedInvoices = (props: Props) => {
  const { contracts, date, showInvoiceCredited, setState } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [columns] = useState([
    {
      width: 160,
      label: getTranslation('invoice.invoiceNum'),
      dataKey: 'invoiceNum',
      noClick: true
    },
    {
      width: 250,
      label: getTranslation('general.period'),
      dataKey: 'invoicePeriod'
    },
    {
      width: 150,
      label: getTranslation('contracts.product'),
      dataKey: 'product'
    }
  ]);

  const { rows, isLoading } = useCreditedInvoicesTable(contracts, date);

  return (
    <div className={css['credited-invoices']}>
      {!isLoading && (
        <div className={css['credited-invoices-wrapper']}>
          <IconParagraph iconType="alert-circle" color={colors['orange-600']}>
            {rows.length} {getTranslation('contracts.invoices.credited')}
          </IconParagraph>
          <Button
            className={css['credited-invoices-show-more']}
            id={`change-credited-invoices-show-more`}
            onClick={() => setState({ showInvoiceCredited: !showInvoiceCredited })}
            type="text"
            size="small"
          >
            {rows.length > 0
              ? showInvoiceCredited
                ? getTranslation('contracts.hide_all')
                : getTranslation('contracts.show_all')
              : ''}
          </Button>
        </div>
      )}

      {showInvoiceCredited && rows.length > 0 && (
        <div className={css['credited-invoice-table']}>
          <DynamicVirtualTable
            id="credited-invoices-table"
            tooltipId="credited-invoices-table-tip"
            rows={rows}
            totalAmountOfRows={rows.length}
            columns={columns}
            loading={isLoading}
            singleSort
            noSelect
          />
        </div>
      )}
    </div>
  );
};

export default CreditedInvoices;
