import clone from 'clone';
import React from 'react';

import { FilterType } from '@zf/api-types/master-data/meter';

import useSingleAPI from '../../../../../hooks/useSingleAPI';
import { useTracked } from '../context/consumptions-context';

export default function useFetchFilterTypes(locationId: string, timeStamp: string) {
  const [state, dispatch] = useTracked();

  const filterTypesResponse = useSingleAPI<FilterType[]>({
    request: {
      endpoint: `/me/ServiceConsumptions/${locationId}/types`
    }
  });

  React.useEffect(() => {
    if (filterTypesResponse.result && !(filterTypesResponse.result instanceof Promise)) {
      const filterTypes = clone(filterTypesResponse.result.data);

      filterTypes.forEach((ft) => {
        ft.externalIdentifier = ft.utilityType + ft.unitOfMeasure;
      });

      let initialSelectedFilterTypeIds: string[] = [];

      if (filterTypes.length > 0) {
        initialSelectedFilterTypeIds = [filterTypes[0].utilityType + filterTypes[0].unitOfMeasure];
      }

      dispatch({
        type: 'UPDATE',
        newState: {
          ...state,
          filterTypes,
          filterTypesAreLoading: false,
          timeStamp,
          locationId,
          selectedFilterTypeIds: initialSelectedFilterTypeIds
        }
      });
    }
  }, [filterTypesResponse.result]);
}
