import React from 'react';

import { CommunicationConfigurationType } from '@zf/api-types/master-data/property-group';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../../app-context/app-context';
import InputField from '../../../../components/input/InputField';
import { IntersectionType } from './context/settings-context';

type Props = {
  communicationConfiguration: Partial<CommunicationConfigurationType>;
  organisationConfig: OrganizationConfigType;
  setCommConfigValue: (value: Partial<CommunicationConfigurationType>) => void;
  resetCommConfigValue: (key: keyof IntersectionType) => void;
};

export default function GeneralCard(props: Props) {
  const { communicationConfiguration, organisationConfig, setCommConfigValue, resetCommConfigValue } = props;
  const { i18n } = useAppContext();

  return (
    <Card id="general-card" width="1">
      <CardHeader>{i18n.getTranslation('general.general')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{i18n.getTranslation('tenant.company_name')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="propertygroup.company_name"
            onChange={(value) => setCommConfigValue({ companyName: value })}
            value={communicationConfiguration.companyName}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.vatAccountNumber')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="propertygroup.vat_number"
            onChange={(value) => setCommConfigValue({ vatAccountNumber: value })}
            value={communicationConfiguration.vatAccountNumber}
            resetValue={organisationConfig.vatAccountNumber}
            error={!communicationConfiguration.vatAccountNumber}
            reset={() => resetCommConfigValue('vatAccountNumber')}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.companyAccountNumber')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="propertygroup.organisation_number"
            onChange={(value) => setCommConfigValue({ companyAccountNumber: value })}
            value={communicationConfiguration.companyAccountNumber}
            resetValue={organisationConfig.companyAccountNumber}
            error={!communicationConfiguration.companyAccountNumber}
            reset={() => resetCommConfigValue('companyAccountNumber')}
          />
        </CardItem>
      </CardBody>
    </Card>
  );
}
