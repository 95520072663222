import { observer } from 'mobx-react';
import React from 'react';

import { TopUpType } from '@zf/api-types/metering/top-up';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { Icon, Paragraph } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import css from './balance-card.module.scss';

type Props = {
  topUp: TopUpType;
};

const TopUp = (props: Props) => {
  const { topUp } = props;
  const { applicationStore } = useStore();
  const { getTranslation, culture } = applicationStore;

  return (
    <div className={css['top-up']}>
      <Icon name="coin" />
      <div>
        <Paragraph color={colors['silver-600']} className={css['top-up-date']}>
          {formatDate(topUp.processedDateTimeOffset)}
        </Paragraph>
        <Paragraph bold className={css['top-up-value']}>
          {getTranslation('prepayment_device.topups')}: {formatMoney(topUp.amountPaid, culture)}
        </Paragraph>
      </div>
    </div>
  );
};

export default observer(TopUp);
