import React from 'react';

import { InvoiceType } from '@zf/api-types/invoice';
import DropDownDetails from '@zf/stella-react/src/atoms/Dropdown/DropDownDetails';
import { formatPeriod } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../app-context/app-context';
import { ICONS } from '../../constants/icons';
import { Link } from '../../design-system/Components';
import IconLabel from '../Icon/IconLabel';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Props = {
  className?: string;
};

export default function InvoiceAutoFill(props: Props & CustomAutoFillProps<InvoiceType>) {
  const {
    id,
    selectedValues,
    initialValue,
    query,
    queryField,
    excludedIds,
    onChange,
    onFocus,
    customProcessAutoFillItem
  } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const processAutoFillItem = React.useCallback((i: InvoiceType) => {
    return {
      value: i,
      text: `${i.invoiceNum ? i.invoiceNum : i18n.getTranslation('general.empty')} - ${i.debtor.displayName}`
    };
  }, []);

  const autoFillTools = useAutoFill({
    endpoint: '/bill/invoices',
    processRecord: customProcessAutoFillItem ? customProcessAutoFillItem : processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField,
    excludedIds: excludedIds
  });

  const { focusedIndex, shownValues, values } = autoFillTools;

  const invoice: InvoiceType | undefined =
    typeof focusedIndex !== 'undefined' && shownValues[focusedIndex]
      ? shownValues[focusedIndex].value.value
      : undefined;

  const details = invoice && (
    <DropDownDetails>
      <>
        <div>
          <IconLabel labelText={i18n.getTranslation('customer.customer')} iconType="customer" />
          <Link
            id={`customer-${invoice.debtor.customerId}`}
            url={`${rootUrl}/customers/${invoice.debtor.customerId}`}
            newTab
          >
            {invoice.debtor.displayName}
          </Link>
        </div>

        <div>
          <IconLabel labelText={i18n.getTranslation('contracts.contract')} iconType="contract" />
          <Link id={`contract-${invoice.contractId}`} url={`${rootUrl}/contracts/${invoice.contractId}`} newTab>
            {invoice.contractNumber}
          </Link>
        </div>

        <div>
          <IconLabel labelText={i18n.getTranslation('invoice.invoice_period')} iconType="clock" />
          {formatPeriod(invoice.periodStartDateTime, invoice.periodEndDateTime)}
        </div>

        <div>
          <IconLabel labelText={i18n.getTranslation('general.status')} iconType={ICONS[invoice.status]} />
          {enumReducer.getTranslation('invoiceStatus', invoice.status)}
        </div>

        <div>
          <IconLabel labelText={i18n.getTranslation('invoice.open_amount')} iconType="money" />
          {formatMoney(invoice.remainingInvoiceAmount, i18n.culture)}
        </div>
      </>
    </DropDownDetails>
  );

  return (
    <SearchAutoFill
      {...props}
      id={id}
      details={details}
      values={values}
      selectedValues={selectedValues}
      placeholder={i18n.getTranslation('invoices_list.filter.search_placeholder')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
