import React from 'react';

import { navigate } from '@reach/router';
import { DeletePropertyGroupType, PropertyGroupType } from '@zf/api-types/master-data/property-group';
import useDialog from '@zf/hooks/src/useDialog';

import DeletePropertyDialog from '../../../actions/property-group/delete-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  propertyGroup: PropertyGroupType;
};

export default function DeletePropertyGroupListItem(props: Props) {
  const { propertyGroup } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const propertyGroupToHandle: DeletePropertyGroupType = {
    etag: propertyGroup._etag,
    id: propertyGroup.id
  };

  const onCompleteHandle = () => {
    navigate(`${tenantReducer.state.rootUrl}/properties`);
  };

  return (
    <DropdownAction
      id="propertygroup.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={
        <DeletePropertyDialog
          onComplete={() => onCompleteHandle()}
          ref={clickRef}
          propertyGroupToHandle={propertyGroupToHandle}
        />
      }
      onSubmit={onSubmit}
      dialogType="danger"
      buttonPositive={i18n.getTranslation('general.delete')}
    />
  );
}
