import { observer } from 'mobx-react-lite';
import React from 'react';

import { IncomingBankingTransactionsOverviewCountType } from '@zf/api-types/billing/incoming-banking-transaction';

import useRefresh from '../../../../../app-context/hooks/use-refresh';
import IncomingBankingTransactionExport from '../../../../../components/Button/Excel/IncomingBankingTransactionExport';
import DeleteIncomingBankingTransactionListPageButton from '../../../../../components/Button/IncomingBankingTransaction/DeleteIncomingBankingTransactionListPageButton';
import DownloadIncomingBankingTransactionListPageButton from '../../../../../components/Button/IncomingBankingTransaction/DownloadIncomingBankingTransactionListPageButton';
import ProcessIncomingBankingTransactionListPageButton from '../../../../../components/Button/IncomingBankingTransaction/ProcessIncomingBankingTransactionListPageButton';
import UploadIncomingBankingTransactionListPageButton from '../../../../../components/Button/IncomingBankingTransaction/UploadIncomingBankingTransactionListPageButton';
import FilterBar from '../../../../../components/Filters/FilterBar';
import { useStore } from '../../../../../hooks/useStore';
import usePaymentTabs from '../../../hooks/usePaymentTabs';
import useIncomingBankingTransactionFilter from '../hooks/useIncomingBankingTransactionFilter';
import IncomingBankingTransactionListPageInfo from './IncomingBankingTransactionListPageInfo';
import IncomingBankingTransactionsFilterInputs from './IncomingBankingTransactionsFilterInputs';
import IncomingBankingTransactionsQuickFilters from './IncomingBankingTransactionsQuickFilters';

type Props = {
  search: string;
};

const IncomingBankingTransactionsFilterBar = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const {
    overviewCount,
    quickFilter,
    searchValue,
    filterTags,
    queryParams,
    onFilter,
    setQueryParams,
    onSearch,
    clearQueryParams,
    refreshCounts
  } = useIncomingBankingTransactionFilter(props.search);

  const { refresh } = useRefresh();

  const tabItems = usePaymentTabs();

  return (
    <FilterBar
      refreshPage={refresh}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      tabItems={tabItems}
      defaultTab="payments"
      actions={[
        <DownloadIncomingBankingTransactionListPageButton key="download" />,
        <UploadIncomingBankingTransactionListPageButton key="upload" refresh={refresh} />,
        <ProcessIncomingBankingTransactionListPageButton key="process" refreshCounts={refreshCounts} />,
        <IncomingBankingTransactionExport key="export" />,
        <DeleteIncomingBankingTransactionListPageButton key="delete" refreshCounts={refreshCounts} />
      ]}
      infoGrid={<IncomingBankingTransactionListPageInfo />}
      filterButtons={
        <IncomingBankingTransactionsQuickFilters
          overviewCount={overviewCount as IncomingBankingTransactionsOverviewCountType}
          quickFilter={quickFilter}
          onFilter={onFilter}
        />
      }
      filterInputs={
        <IncomingBankingTransactionsFilterInputs queryParams={queryParams} setQueryParams={setQueryParams} />
      }
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('banking_transaction.search')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
};

export default observer(IncomingBankingTransactionsFilterBar);
