import React from 'react';

import { paymentReversalReason } from '@zf/api-types/enums';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context/app-context';
import { LangReturnValue } from '../../app-context/hooks/use-lang';
import css from './style.module.scss';

export type Props = {
  reversalIntel: ReversalIntelType;
  i18n: LangReturnValue;
};

type ReversalIntelType = {
  paymentReversalReason?: paymentReversalReason;
  reversalAdditionalInformation: string | null;
};

export default function ReversalTooltip(props: Props) {
  const { i18n, reversalIntel } = props;
  const { enumReducer } = useAppContext();

  return (
    <div className={css['reversal-wrapper']}>
      <div className={css['row']}>
        <Label>{i18n.getTranslation('payments.reversal_reason')}:</Label>
        <Paragraph>
          {reversalIntel.paymentReversalReason
            ? enumReducer.getTranslation('paymentReversalReason', reversalIntel.paymentReversalReason)
            : ''}
        </Paragraph>
      </div>
      <div className={css['row']}>
        <Label>{i18n.getTranslation('payments.reversal_additional')}:</Label>
        <Paragraph>{reversalIntel.reversalAdditionalInformation}</Paragraph>
      </div>
    </div>
  );
}
