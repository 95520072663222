import React from 'react';

import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteAllExportJobs } from './export-job';

type Props = {
  onComplete: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete } = props;
  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteAllExportJobs(tenantReducer, i18n.lang);

        onComplete();

        notify.success({
          content: i18n.getTranslation('actions.export_jobs.delete_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.export_jobs.delete_failed'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation('actions.export_jobs.delete_all')}</Paragraph>;
});
