import React from 'react';
import StellaCheckBox, { CheckBoxProps } from '@zf/stella-react/src/atoms/CheckBox/StellaCheckBox';
import AccessControl from '../Permissions/AccessControl';

export default function CheckBox(props: CheckBoxProps) {
  const { id } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<StellaCheckBox {...props} disabled />}>
      <StellaCheckBox {...props} />
    </AccessControl>
  );
}
