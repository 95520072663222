import { useEffect, useReducer, useState } from 'react';

import { AggregatedDetailType, PagedResponseType } from '@zf/api-types/api';
import { uiCulture } from '@zf/api-types/enums';
import { ZFErrorType } from '@zf/api-types/general';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { SharedEntityProperties } from '../../../api-types/entity';
import { TenantReturnValue } from '../app-context/hooks/use-tenant-reducer';
import { RequestHeadersType, RequestType } from '../types/Request';
import { synchronouslyMapArray } from '../utils/arrays';
import logError from '../utils/handleError';
import { createHeader, generateCancelToken, sendRequest } from '../utils/request';

type Params<E, R> = {
  request: RequestType | null;
  pageSize?: number;
  tenantReducer: TenantReturnValue;
  lang: uiCulture;
  mock?: boolean;
  processRecord: (record: E) => R;
  onSelectRow?: (rowIds: string[]) => void;
  refreshKey?:string
};

export type RowTypeBase = {
  __id: string;
  id?: string;
  groupName?:string;
  items?: any;
  actionPerformed?: boolean;
};

export type Result<E, R> = {
  loading: boolean;
  error: ZFErrorType | null;
  rows: R[];
  totalAmountOfRows: number;
  selectAllBusy: boolean;
  refresh: boolean;
  sortableFields: string[];
  aggregateDetails: AggregatedDetailType[];
  setStopIndex: (stopIndex: number, selectAllBusy?: boolean | undefined) => void;
  updateGivenRows: (updatedRecords: E[], deletedRecords?: Partial<R>[] | undefined) => Promise<R[]>;
};

type StateValues<R> = {
  loading: boolean;
  error: ZFErrorType | null;
  rows: R[];
  lastShownItem: number;
  lastFetchedItem: number;
  hasNextPage: boolean;
  nextPageToken: string | null;
  totalRows: number;
  selectAllBusy: boolean;
  request: RequestType | null;
  refresh: boolean;
  sortableFields: string[];
  aggregateDetails: AggregatedDetailType[] | null;
};

const ROWS_TO_BUFFER = 200;

function fetchPage<E>(
  request: RequestType,
  headers: RequestHeadersType,
  lang: uiCulture,
  mock: boolean,
  nextPageToken?: string | null
) {
  const { endpoint, selector, query = {} } = request;

  if (nextPageToken) {
    headers = { ...headers, continuationToken: nextPageToken };
  }

  const source = generateCancelToken();
  let resultPromise = null;
  resultPromise = sendRequest<E>(
    {
      request: {
        endpoint,
        selector,
        query
      },
      customHeaders: headers,
      lang
    },
    false,
    mock
  );

  return { resultPromise, source };
}

function updateRows<R>(rows: R[], startIndex: number, resultSet: R[]) {
  const rowsClone = [...rows];

  for (let i = 0; i < resultSet.length; i++) {
    rowsClone[startIndex + i] = resultSet[i];
  }

  return rowsClone;
}

const initialState = {
  loading: true,
  error: null,
  rows: [],
  lastShownItem: 0,
  lastFetchedItem: 0,
  hasNextPage: true,
  nextPageToken: null,
  totalRows: 0,
  selectAllBusy: false,
  request: null,
  refresh: false,
  sortableFields: [],
  aggregateDetails: []
};

export default function useInfiniAPI<E extends SharedEntityProperties, R extends RowTypeBase>({
  request,
  tenantReducer,
  lang,
  mock = false,
  processRecord,
  onSelectRow,
  refreshKey
}: Params<E, R>): Result<E, R> {
  const stateReducer = createStateReducer<StateValues<R>, Partial<StateValues<R>>>();
  const [state, updateState] = useReducer(stateReducer, {
    ...initialState
  });
  const [locked, setLocked] = useState(false);

  const { tenant, organization } = tenantReducer.state;

  const setStopIndex = (stopIndex: number, selectAllBusy?: boolean) => {
    if (stopIndex > state.lastShownItem) {
      updateState({ lastShownItem: stopIndex, selectAllBusy });
    }
  };

  useEffect(() => {
    if (!request || !request.endpoint) {
      updateState({
        rows: []
      });

      return;
    }

    setLocked(true);

    updateState({
      loading: true,
      hasNextPage: false,
      nextPageToken: null,
      refresh: true,
      error: null
    });

    let orgId: string | undefined = '';

    if (organization) {
      orgId = organization.organizationId || organization.id;
    }

    const { resultPromise, source } = fetchPage<PagedResponseType<E>>(
      request,
      createHeader({
        organization: orgId,
        tenant: tenant?.id,
        timestamp: request.timeStamp
      }),
      lang,
      mock
    );

    const fetch = async () => {
      try {
        let result = null;
        try {
          result = await resultPromise;
        } catch (error) {
          //@ts-ignore
          logError(error);
          throw error;
        }

        if (!result) return;

        const data = result.data;
        const count = data.totalRecords;

        updateState({
          rows: updateRows([], 0, synchronouslyMapArray(data.results, processRecord)),
          hasNextPage: data.hasNextPage,
          nextPageToken: data.nextPageToken,
          lastFetchedItem: data.results.length,
          loading: false,
          totalRows: count,
          request: request,
          refresh: false,
          sortableFields: data.sortableFields,
          aggregateDetails: data.aggregateDetails
        });
      } catch (e) {
        // @ts-ignore
        updateState({ error: e, loading: false });

        if (process.env.NODE_ENV !== 'production') {
          // @ts-ignore
          logError(e);
        }
      }
    };

    fetch();

    setLocked(false);

    return () => source.cancel('Request canceled.');
  }, [JSON.stringify(request), tenant, organization, refreshKey]);

  useEffect(() => {
    if (!request || state.loading) return;
    if (!state.hasNextPage || !state.nextPageToken) return;
    if (state.lastFetchedItem >= state.lastShownItem + ROWS_TO_BUFFER) return;

    const fetchPages = async () => {
      if (locked) return;
      setLocked(true);
      const { resultPromise } = fetchPage<PagedResponseType<E>>(
        request,
        createHeader({
          organization: organization?.organizationId,
          tenant: tenant?.id,
          timestamp: request.timeStamp
        }),
        lang,
        mock,
        state.nextPageToken
      );

      try {
        if (JSON.stringify(request) !== JSON.stringify(state.request)) return;
        let result;
        try {
          result = await resultPromise;
        } catch (error) {
          //@ts-ignore
          logError(error);
          throw error;
        }
        if (!result) return;

        const { data } = result;
        const lastFetchedItem = state.lastFetchedItem + data.results.length;
        const mappedArray = synchronouslyMapArray(data.results, processRecord);
        if (lastFetchedItem !== state.totalRows) {
          const loadingRow: any = { __id: 'LOADING' };
          mappedArray.push(loadingRow);
        }

        if (JSON.stringify(request) !== JSON.stringify(state.request)) return;
        updateState({
          rows: updateRows(state.rows, state.lastFetchedItem, mappedArray),
          hasNextPage: data.hasNextPage,
          nextPageToken: data.nextPageToken,
          lastFetchedItem
        });
        setLocked(false);
      } catch (e) {
        // @ts-ignore
        updateState({ error: e, loading: false });

        if (process.env.NODE_ENV !== 'production') {
          // @ts-ignore
          logError(e);
        }
      }
    };

    fetchPages();
  }, [state.loading, state.lastShownItem, state.nextPageToken, locked]);

  useEffect(() => {
    if (onSelectRow && state.selectAllBusy && state.rows.length === state.totalRows) {
      const ids = state.rows.reduce((acc: string[], row: R) => {
        if (row.__id) {
          acc.push(row.__id);
        }

        return acc;
      }, []);

      onSelectRow(ids);
      updateState({ selectAllBusy: false });
    }
  }, [state.rows, state.selectAllBusy]);

  const updateGivenRows = async (updatedRecords: E[], deletedRecords?: Partial<R>[]) => {
    const rowsClone = [...state.rows];

    if (updatedRecords.length > 0) {
      const newRows = synchronouslyMapArray(updatedRecords, processRecord);

      for (const record of updatedRecords) {
        const modifiedId = record.id;
        const currentIndex = rowsClone.findIndex((row) => row.__id === modifiedId);
        const newIndex = newRows.findIndex((row) => row.__id === modifiedId);

        rowsClone[currentIndex] = newRows[newIndex];
        rowsClone[currentIndex]['actionPerformed'] = true;
      }
    }

    if (deletedRecords && deletedRecords.length > 0) {
      for (const deletedRecord of deletedRecords) {
        const modifiedId = deletedRecord.id;
        const currentIndex = rowsClone.findIndex((row) => row.__id === modifiedId);
        rowsClone.splice(currentIndex, 1);
      }

      if (onSelectRow) {
        onSelectRow([]);
      }
    }

    updateState({
      ...state,
      rows: rowsClone
    });

    return rowsClone;
  };

  return {
    loading: state.loading,
    error: state.error,
    rows: state.rows,
    setStopIndex,
    updateGivenRows,
    totalAmountOfRows: state.totalRows,
    selectAllBusy: state.selectAllBusy,
    refresh: state.refresh,
    sortableFields: state.sortableFields,
    aggregateDetails: state.aggregateDetails || []
  };
}
