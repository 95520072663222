import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardBody } from '@zf/stella-react/src/atoms/Card';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import { Spinner } from '../../../../design-system/Foundation';
import DataGridCardHeaderSection from '../../../DataGridCard/DataGridCardHeaderSection';
import FilesStore, { attachmentType } from '../logic/FilesStore';
import FilesFilterSection from './FilesFilterSection';
import FilesTable from './FilesTable';
import { AttachmentGenericBaseType } from './Files';
import { EntityAttachmentFile } from '@zf/api-types/entity-attachments/entity-attachments';

type Props<T> = {
  filesStore: FilesStore<T>;
  specificCols?: ColumnType[];
  customerId?: string;
  includeContractFilter?: boolean;
  attachmentType: attachmentType;
  processSpecificCols?: (entityAttachment: T) => Record<string, React.ReactNode>;
  listFiles: (attachment?: T) => EntityAttachmentFile[];
};

const FilesCard = <T extends AttachmentGenericBaseType>(props: Props<T>) => {
  const {
    filesStore,
    specificCols,
    customerId,
    includeContractFilter,
    processSpecificCols,
    listFiles,
    attachmentType
  } = props;
  const { culture, quickFilterStore } = filesStore;

  return (
    <Card id="files-card" width="4">
      <CardBody>
        <DataGridCardHeaderSection>
          <FilesFilterSection
            filesStore={filesStore}
            customerId={customerId}
            includeContractFilter={includeContractFilter}
          />
        </DataGridCardHeaderSection>
        {culture && quickFilterStore ? (
          <FilesTable
            listFiles={listFiles}
            attachmentType={attachmentType}
            filesStore={filesStore}
            quickFilterStore={quickFilterStore}
            specificCols={specificCols}
            processSpecificCols={processSpecificCols}
          />
        ) : (
          <Spinner centered />
        )}
      </CardBody>
    </Card>
  );
};

export default observer(FilesCard);
