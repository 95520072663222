import { observer } from 'mobx-react';
import React from 'react';

import { utilityType } from '@zf/api-types/enums';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { ICON_COLORS } from '../../constants/icons';
import { Icon } from '../../design-system/Foundation';
import { useStore } from '../../hooks/useStore';
import useUUID from 'hooks/useUUID';

type Props = {
  utilityTypes: utilityType[];
};

const TableServices = (props: Props) => {
  const { utilityTypes } = props;

  const { applicationStore } = useStore();
  const { getEnumTranslation } = applicationStore;

  const ids = useUUID(utilityTypes.length);

  return (
    <FlexElements>
      {utilityTypes.sort().map((ut, index) => {
        const id = ids[index];

        return (
          <Icon key={id} id={id} name={ut} color={ICON_COLORS[ut]} title={getEnumTranslation('utilityType', ut)} />
        );
      })}
    </FlexElements>
  );
};

export default observer(TableServices);
