import React from 'react';

import {
  PropertyGroupBillingConfigurationType,
  PropertyGroupCalculationConfigurationType
} from '@zf/api-types/property-group-billing-configuration';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { PropertyGroupBillingConfigurationRequestType } from '../../features/property-group/detail-page/billing/hooks/use-billing-data';

type Props = {
  toDelete: PropertyGroupCalculationConfigurationType;
  groups: PropertyGroupCalculationConfigurationType[];
  addAllocationGroupsToState: (allocationGroups: PropertyGroupCalculationConfigurationType[] | null) => void;
  submitAllocationGroup: (
    apiFriendlyValues: PropertyGroupBillingConfigurationRequestType
  ) => Promise<PropertyGroupBillingConfigurationType>;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { toDelete, groups, addAllocationGroupsToState, submitAllocationGroup } = props;

  const { i18n } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newGroups = groups.filter((g) => {
          return g.name !== toDelete.name;
        });

        const apiFriendlyValues = {
          requiredIncomingInvoiceComponents: [],
          calculationConfigurations: newGroups
        };

        const updatedBillingConfig = await submitAllocationGroup(apiFriendlyValues);

        addAllocationGroupsToState(updatedBillingConfig.calculationConfigurations);

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_group_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_group_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation('property_groups.tabs.billing.delete_group_paragraph', { name: toDelete.name })}
    </Paragraph>
  );
});
