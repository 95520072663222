import clone from 'clone';
import React from 'react';

import { paymentReversalReason } from '@zf/api-types/enums';
import { TransactionType } from '@zf/api-types/transactions';

import { useAppContext } from '../../app-context/app-context';
import { notify } from '../../events/notification-events';
import { getRowsForIds } from '../../utils/action';
import { SettleState } from './settle';

type LocalTransactionType = TransactionType & { originalOpenAmount?: number };

export type LocalTransactionRowType = {
  __id: string;
  __entity: LocalTransactionType;
  amount: JSX.Element;
  openAmount: JSX.Element;
  invoiceNum: string;
  transactionDateTime: React.ReactNode;
  dueDate: JSX.Element;
  reversed: string | JSX.Element;
  reversalReason: paymentReversalReason;
};

export default function useSettle(
  state: SettleState,
  setState: React.Dispatch<Partial<SettleState>>,
  rows: LocalTransactionRowType[],
  updateGivenRows: (
    updatedRecords: TransactionType[],
    deletedRecords?: Partial<LocalTransactionRowType>[]
  ) => Promise<any[]>,
  updateReferences?: (rowIds: string[]) => void
) {
  const { i18n } = useAppContext();

  const handleSelectionChange = (rowIds: string[]) => {
    const deselectedIds = state.selectedIds.filter((id) => {
      return !rowIds.includes(id);
    });

    const isDeselection = deselectedIds.length > 0;

    if (state.remainingAmount === 0 && !isDeselection) {
      // No remaining amount left
      notify.warning({
        content: i18n.getTranslation('actions.payments.remaining_below_zero')
      });
    } else {
      let remaining = state.remainingAmount;
      const updatedRows: LocalTransactionType[] = [];

      // When deselecting
      if (isDeselection) {
        const selectedRows = getRowsForIds(rows, deselectedIds);

        // When only the last one remains
        if (rowIds.length === 1) {
          const rowToUpdate = rows.find((r) => {
            return r.__entity.id === rowIds[0];
          });

          if (rowToUpdate) {
            const entityToUpdate = clone(rowToUpdate.__entity);
            if (entityToUpdate.originalOpenAmount) {
              remaining = state.amount;

              if (entityToUpdate.transactionOpenAmount !== 0) {
                if (Math.abs(remaining) > Math.abs(entityToUpdate.originalOpenAmount)) {
                  entityToUpdate.transactionOpenAmount = 0;
                  remaining = Math.abs(remaining) - Math.abs(entityToUpdate.originalOpenAmount);
                } else {
                  entityToUpdate.transactionOpenAmount =
                    Math.abs(remaining) - Math.abs(entityToUpdate.originalOpenAmount);
                  remaining = 0;
                }
              } else {
                remaining = Math.abs(remaining) - Math.abs(entityToUpdate.originalOpenAmount);
              }

              updatedRows.push(entityToUpdate);
            }
          }
        }

        // Reset open amounts to initial and remove original
        updatedRows.push(
          ...selectedRows.map((sr) => {
            const updatedTransaction = clone(sr.__entity);

            // This is defined when deselecting but TS wants this check
            if (updatedTransaction.originalOpenAmount) {
              if (rowIds.length !== 1) {
                if (updatedTransaction.transactionOpenAmount === 0) {
                  remaining = Math.abs(remaining) + Math.abs(updatedTransaction.originalOpenAmount);
                } else {
                  remaining =
                    Math.abs(remaining) +
                    (Math.abs(updatedTransaction.originalOpenAmount) -
                      Math.abs(updatedTransaction.transactionOpenAmount));
                }
              }

              updatedTransaction.transactionOpenAmount = updatedTransaction.originalOpenAmount;
              delete updatedTransaction.originalOpenAmount;
            }

            return updatedTransaction;
          })
        );
      } else {
        // When selecting
        const selectedRows = getRowsForIds(rows, rowIds);

        // Update open amounts to settled & init originalOpenAmount
        updatedRows.push(
          ...selectedRows.map((sr) => {
            const updatedTransaction = clone(sr.__entity);

            if (!updatedTransaction.originalOpenAmount) {
              updatedTransaction.originalOpenAmount = updatedTransaction.transactionOpenAmount;

              if (Math.abs(remaining) > Math.abs(updatedTransaction.originalOpenAmount)) {
                updatedTransaction.transactionOpenAmount = 0;
                remaining = Math.abs(remaining) - Math.abs(updatedTransaction.originalOpenAmount);
              } else {
                updatedTransaction.transactionOpenAmount =
                  Math.abs(remaining) - Math.abs(updatedTransaction.originalOpenAmount);
                remaining = 0;
              }
            }

            return updatedTransaction;
          })
        );
      }

      setState({ remainingAmount: state.amount > 0 ? remaining : -remaining, selectedIds: rowIds });
      updateGivenRows(updatedRows);
      if (updateReferences) updateReferences(rowIds);
    }
  };

  return { handleSelectionChange };
}
