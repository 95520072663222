import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

// Fills in a guiding message in a dropdown in case of an empty list
export const checkDropdownValues = <T>(array: DropdownValuesType<T>[], message: string) => {
  if (array.length === 0) {
    return [
      {
        value: {} as T, // an empty string fills in the text below as selected value so empty object is used
        text: message
      }
    ];
  } else {
    return array;
  }
};
