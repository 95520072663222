import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { TransactionCard, CommentCard, ContactCard, ContractCard, FeedCard } from '../../../../cards';
import BankDetailsCard from '../../../../cards/BankDetails/BankDetailsCard';
import CommunicationPreferencesCard from '../../../../cards/CommunicationPreferences/CommunicationPreferencesCard';
import CustomEntityPropertiesCard from '../../../../cards/CustomEntityProperties/CustomEntityPropertiesCard';
import IntegrationLoggingCard from '../../../../cards/Integration/IntegrationLoggingCard';
import InvoiceCard from '../../../../cards/InvoiceCard/InvoiceCard';
import css from './customer-details-card.module.scss';
import CustomerDetailsCard from './CustomerDetailsCard';

type Props = {
  customer: CustomerType;
  timeStamp: string;
  setActiveTab: (tabName: string) => void;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType | null>>;
};

export default function CustomerDetail(props: Props) {
  const { customer, timeStamp, setActiveTab, setCustomer } = props;

  return (
    <>
      <CardsContainer className={css['customer-card-container']}>
        <CustomerDetailsCard customer={customer} setCustomer={setCustomer} />
        <TransactionCard customer={customer} timestampRefresh={timeStamp} setActiveTab={setActiveTab} />
        <ContractCard customerId={customer.id} />
        <ContactCard customer={customer} setCustomer={setCustomer} />
        <BankDetailsCard key={customer.id} />
        <CommunicationPreferencesCard customer={customer} />
        <InvoiceCard
          endpoint="/bill/invoices"
          queryParams={{
            customerId: customer.id,
            refreshTrigger: timeStamp
          }}
        />
        <CommentCard subjectType={entitySubjectType.customer} subjectId={customer.id} />
        <FeedCard subjectType={entitySubjectType.customer} subjectId={customer.id} />
        <CustomEntityPropertiesCard
          entityType="Customers"
          entity={customer}
          entitySubjectType={entitySubjectType.customer}
        />
        <IntegrationLoggingCard subjectId={customer.id} subjectType="Customer" />
      </CardsContainer>
    </>
  );
}
