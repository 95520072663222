import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';

type Props = {
  toUnlink: MeterType[];
  refresh: () => void;
};

const UnlinkDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { toUnlink, refresh } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { unlinkMeter } = meterStore.deviceService;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await Promise.all(toUnlink.map((m) => unlinkMeter(m.id)));

        refresh();

        notify.success({
          content: getTranslation('actions.meter.unlink_success')
        });
      } catch (e) {
        notify.success({
          content: getTranslation(`actions.meter.unlink_fail${toUnlink.length > 1 ? 's' : ''}`),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{getTranslation('actions.meter.unlink_paragraph')}</Paragraph>;
});

export default observer(UnlinkDialog);
