import useCurrent from 'app-context/hooks/use-current';
import LocationSectionStore from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import ListPage from 'components/listPage';
import { DialogClickRef, ValidationRef } from 'design-system/ComponentSets/Dialog/Dialog';
import LocationsFilterBar from 'features/location/list-page/locations-filter-bar';
import LocationsList from 'features/location/list-page/locations-list';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle } from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { ServiceLocationRowType } from '@zf/api-types/master-data/servicelocation';

import css from './add-locations-dialog.module.scss';

type Props = {
  locationSectionStore: LocationSectionStore;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const AddLocationsDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { locationSectionStore, validationRef } = props;

  const { myCurrent } = useCurrent('location');
  const selectedLocationRows = myCurrent.listPage.selectedRows as ServiceLocationRowType[];

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { embeddedLocationListPageQuery, addToSelectedLocations } = locationSectionStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      addToSelectedLocations(selectedLocationRows.map((r) => [r.__id, r.__entity]));
    }
  }));

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(selectedLocationRows.length === 0);
    }
  };

  useEffect(() => {
    validate();
  }, [selectedLocationRows.length]);

  return (
    <div className={css['listpage-wrapper']}>
      <ListPage
        entitySubjectType={entitySubjectType.servicelocation}
        subjectType={getTranslation('nav.locations')}
        topBar={<LocationsFilterBar showActions={false} showFilterInputs={false} showQuickFilters={false} />}
        list={<LocationsList defaultQueryParams={embeddedLocationListPageQuery} noNavigation />}
        isEmbedded
      />
    </div>
  );
});

export default observer(AddLocationsDialog);
