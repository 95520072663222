import clone from 'clone';
import { Disabled, RadioInput } from 'design-system/Components';
import { Description, SettingDescription } from 'design-system/ComponentSets';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { AdvanceRecalculationType, Level } from '@zf/api-types/enums';
import { AdvanceCalculationParameters } from '@zf/api-types/parameters';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { useStore } from '../../../hooks/useStore';
import css from './advance-calculation-params.module.scss';
import { BillingCfgValues } from './BillingTabBody';
import MarginInputs from './MarginInputs';

type Props = {
  values: BillingCfgValues;
  contractInvoiceEstimationEnabled: boolean;
  setValue: (value: Partial<BillingCfgValues>) => void;
};

const AdvanceCalculationParams = (props: Props) => {
  const { values, contractInvoiceEstimationEnabled, setValue } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation } = applicationStore;

  const accuracyValues = useRef(getEnum<Level>('level'));

  const setAdvanceCalculationParam = (value: Partial<AdvanceCalculationParameters>) => {
    let copy = clone(values);
    copy.advanceCalculationParameters = { ...copy.advanceCalculationParameters, ...value };
    setValue(copy);
  };

  const automaticAdvanceCalculationEnabled =
    values.advanceCalculationParameters.recalculationType !== AdvanceRecalculationType.none;

  const margin = (
    <SettingDescription
      className={css['margin']}
      title={getTranslation('billing_parameters.margin_title')}
      description={getTranslation('billing_parameters.margin_descr')}
      setting={
        <MarginInputs
          advanceCalculationParameters={values.advanceCalculationParameters}
          automaticAdvanceCalculationEnabled={automaticAdvanceCalculationEnabled}
          setAdvanceCalculationParam={setAdvanceCalculationParam}
        />
      }
      indentLvl={contractInvoiceEstimationEnabled ? 0 : 1}
    />
  );

  return (
    <>
      <SettingDescription
        title={getTranslation('billing_parameters.auto_advance_amount_calc_title')}
        description={getTranslation(
          contractInvoiceEstimationEnabled
            ? 'billing_parameters.auto_advance_amount_calc_descr'
            : 'billing_parameters.auto_advance_amount_calc_disabled_descr'
        )}
        setting={
          <ZFToggle
            onChange={(val) =>
              setAdvanceCalculationParam({
                recalculationType: val ? AdvanceRecalculationType.usingpreviousinvoice : AdvanceRecalculationType.none
              })
            }
            checked={automaticAdvanceCalculationEnabled}
          />
        }
      />

      {contractInvoiceEstimationEnabled ? (
        <Disabled disabled={!automaticAdvanceCalculationEnabled}>
          <div className={css['options']}>
            <FlexElements flexDirection="column" gap="16">
              <RadioInput
                name="calculation-type-usingpreviousinvoice"
                checked={
                  values.advanceCalculationParameters.recalculationType ===
                  AdvanceRecalculationType.usingpreviousinvoice
                }
                value={AdvanceRecalculationType.usingpreviousinvoice}
                onChange={() =>
                  setAdvanceCalculationParam({ recalculationType: AdvanceRecalculationType.usingpreviousinvoice })
                }
              >
                <Description
                  title={getEnumTranslation('advanceRecalculationType', AdvanceRecalculationType.usingpreviousinvoice)}
                  description={getTranslation('billing_parameters.using_previous_invoice')}
                  fullWidth
                />
              </RadioInput>

              <RadioInput
                name="calculation-type-usingestimatedinvoice"
                checked={
                  values.advanceCalculationParameters.recalculationType ===
                  AdvanceRecalculationType.usingestimatedinvoice
                }
                value={AdvanceRecalculationType.usingestimatedinvoice}
                onChange={() =>
                  setAdvanceCalculationParam({ recalculationType: AdvanceRecalculationType.usingestimatedinvoice })
                }
              >
                <Description
                  title={getEnumTranslation('advanceRecalculationType', AdvanceRecalculationType.usingestimatedinvoice)}
                  description={getTranslation('billing_parameters.using_estimated_invoice')}
                  fullWidth
                />
              </RadioInput>

              <Disabled
                disabled={
                  values.advanceCalculationParameters.recalculationType !==
                  AdvanceRecalculationType.usingestimatedinvoice
                }
              >
                <SettingDescription
                  title={getTranslation('billing_parameters.accuracy_title')}
                  description={getTranslation('billing_parameters.accuracy_descr')}
                  setting={
                    <FlexElements gap="24">
                      {accuracyValues.current.map((val) => (
                        <RadioInput
                          key={val.value}
                          onChange={(option) => setAdvanceCalculationParam({ minimumAccuracy: option as Level })}
                          value={val.value}
                          name={`accuracy-${val.text}`}
                          checked={values.advanceCalculationParameters.minimumAccuracy === val.value}
                        >
                          {val.text}
                        </RadioInput>
                      ))}
                    </FlexElements>
                  }
                  indentLvl={1}
                />
              </Disabled>

              {margin}
            </FlexElements>
          </div>
        </Disabled>
      ) : (
        <Disabled disabled={!automaticAdvanceCalculationEnabled}>{margin}</Disabled>
      )}
    </>
  );
};

export default observer(AdvanceCalculationParams);
