import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import css from './remove-customer-group-dialog.module.scss';
import { useAppContext } from 'app-context';
import {
  addCustomersToGroupBulk,
  addCustomersToGroupSingle
} from '../../../customer/list-page/AddCustomersToGroup.API';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { CustomerRowType } from '@zf/api-types/master-data/customer';
import Interweave from 'interweave';
import { InfoBanner } from 'design-system/ComponentSets';
import { CustomerGroupType } from '@zf/api-types/master-data/customer-group';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  onComplete?: Function;
  selectedRows?: CustomerRowType[];
  withCustomerList?: boolean;
};

export type CustomerGroupState = {};

const ChangeCustomerGroupDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { onComplete, withCustomerList, selectedRows } = props;
  const { applicationStore, customerStore } = useStore();
  const { tenantReducer, i18n } = useAppContext();
  const { getTranslation } = applicationStore;
  const { customerService } = customerStore;
  const { getCustomerGroups } = customerService;

  const customers = selectedRows && selectedRows.filter(({ customerGroup }) => customerGroup !== ' ').length;
  const count = customers ? customers : 0;
  const [customerGroups, setCustomerGroups] = useState<CustomerGroupType[]>();
  const [state, setState] = useState({
    originGroupHasSettings: false
  });

  useEffect(() => {
    getCustomerGroups()
      .then((res) => {
        setCustomerGroups(res.results);
        //if any of them HAS settings, the state is updated
      })
      .then(() => {
        checkBatchHasSettings();
      });
  }, []);

  const checkHasSettings = (settingsObj: {} | undefined) => {
    //if all the settings available are null that means there are no settings

    if (settingsObj)
      if (Object.values(settingsObj).every((setting) => setting === null)) {
        return false;
      } else {
        return true;
      }
  };

  const getOriginCustomerGroupSettings = (originId: string | undefined) => {
    //No customer group = no customer group settings
    if (originId && customerGroups) {
      // get the details of the selected customer's customerGroup
      // by comparing its id agains the list of customer groups
      const customerGroupDetails = customerGroups.find((cgD) => {
        return cgD.id === originId;
      });
      if (customerGroupDetails) {
        return customerGroupDetails.settings;
      }
    }
  };

  const checkBatchHasSettings = () => {
    //If we're in batch mode
    if (selectedRows && selectedRows.length > 0) {
      //Look through all the selected row's customerGroup id's, and return them (if any)
      const idsWithGroups = selectedRows.map((row) => {
        if (row.__customerEntity.customerGroup?.id != null) {
          return row.__customerEntity.customerGroup.id;
        }
        return undefined;
      });

      // Get those id's 'settingsObject' AND check if those have settings

      const batchHasSettings = idsWithGroups.map((id) => {
        return checkHasSettings(getOriginCustomerGroupSettings(id));
      });

      if (batchHasSettings.includes(true)) {
        setState({ originGroupHasSettings: true });
      }
    }
  };

  async function batchAPI(arr: string[], customerGroupId: string) {
    let init = 0;

    if (customerGroupId === '')
      for (let i = 0; i < arr.length / 200; i++) {
        return await addCustomersToGroupBulk(arr.slice(init, (init += 200)), customerGroupId, tenantReducer, i18n.lang);
      }
  }

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        /**
         * @description via customer list page
         */
        if (withCustomerList && selectedRows && selectedRows?.length > 0) {
          if (selectedRows.length === 1) {
            const response = await addCustomersToGroupSingle('', selectedRows[0].__id, tenantReducer, i18n.lang);

            response &&
              notify.success({
                content: getTranslation('customer_groups.remove_customer_from_customer_group_success')
              });
          } else {
            const response = await batchAPI(
              selectedRows.map(({ __id }: { __id: string }) => __id),
              ''
            );

            response &&
              notify.success({
                content: getTranslation('customer_groups.remove_customer_from_customer_group_success')
              });
          }
        }

        /**
         * @description refresh page finally
         */
        onComplete && onComplete();
      } catch (e) {
        notify.error({
          content: getTranslation('customer_groups.remove_customer_from_customer_group_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>
        <Interweave
          content={getTranslation('customer_groups.customers_will_be_removed_from_this_group', {
            count
          })}
        ></Interweave>
      </Paragraph>
      {state.originGroupHasSettings && (
        <div className={css['wrapper']}>
          <InfoBanner
            color="blue"
            info={getTranslation('customer_groups.customer_this_action_might_change_contract_settings')}
            quickTipId="customer_groups.removed.tooltip"
            quickTipHTML={
              <Interweave
                content={getTranslation('customer_groups.customer_this_action_might_change_contract_settings_tip')}
              />
            }
          />
        </div>
      )}
    </>
  );
});

export default observer(ChangeCustomerGroupDialog);
