import { AddressNestedFormType } from '@zf/api-types/general';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { observer } from 'mobx-react';
import React from 'react';
import BaseFormVjf from '../../../../../app-context/stores/forms/BaseFormVjf';
import { InlineInputFieldInput } from '../../../../../components/input/InputField';
import AddressInput from '../../../../../design-system/AdressPicker/AddressInput';
import { useStore } from '../../../../../hooks/useStore';

const ContactInformationCard = () => {
  const { countrySpecificStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { socialTariffStore } = countrySpecificStore;
  const { socialTariffConfigForm } = socialTariffStore;

  return (
    <Card width="1">
      <CardHeader>{getTranslation('country_specifics.contact_info')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="4">
          <Label>{getTranslation('customer.first_name')}</Label>
        </CardItem>
        <CardItem width="8">
          <InlineInputFieldInput
            id="firstname"
            onChange={(val) => socialTariffConfigForm._set('firstname', val)}
            type="text"
            value={socialTariffConfigForm._get('firstname')}
            singleError={socialTariffConfigForm._error('firstname')}
            error={!!socialTariffConfigForm._error('firstname')}
            placeholder="John"
          />
        </CardItem>
        <CardItem width="4">
          <Label>{getTranslation('customer.last_name')}</Label>
        </CardItem>
        <CardItem width="8">
          <InlineInputFieldInput
            id="lastname"
            onChange={(val) => socialTariffConfigForm._set('lastname', val)}
            type="text"
            value={socialTariffConfigForm._get('lastname')}
            singleError={socialTariffConfigForm._error('lastname')}
            error={!!socialTariffConfigForm._error('lastname')}
            placeholder="Doe"
          />
        </CardItem>
        <CardItem width="4">
          <Label>{getTranslation('organization.email_address')}</Label>
        </CardItem>
        <CardItem width="8">
          <InlineInputFieldInput
            id="email"
            onChange={(val) => socialTariffConfigForm._set('email', val)}
            type="text"
            value={socialTariffConfigForm._get('email')}
            singleError={socialTariffConfigForm._error('email')}
            error={!!socialTariffConfigForm._error('email')}
            placeholder="Joh.doe@gmail.com"
          />
        </CardItem>
        <CardItem width="4">
          <Label>{getTranslation('country_specifics.fax')}</Label>
        </CardItem>
        <CardItem width="8">
          <InlineInputFieldInput
            id="fax"
            onChange={(val) => socialTariffConfigForm._set('fax', val)}
            type="text"
            value={socialTariffConfigForm._get('fax')}
            singleError={socialTariffConfigForm._error('fax')}
            error={!!socialTariffConfigForm._error('fax')}
          />
        </CardItem>
        <CardItem width="4">
          <Label>{getTranslation('organization.telephone')}</Label>
        </CardItem>
        <CardItem width="8">
          <InlineInputFieldInput
            id="telephonenumber"
            onChange={(val) => socialTariffConfigForm._set('telephonenumber', val)}
            singleError={socialTariffConfigForm._error('telephonenumber')}
            error={!!socialTariffConfigForm._error('telephonenumber')}
            type="text"
            value={socialTariffConfigForm._get('telephonenumber')}
            placeholder="+32"
          />
        </CardItem>
        <CardItem width="4">
          <Label>{getTranslation('country_specifics.supplier_address')}</Label>
        </CardItem>
        <AddressInput
          values={socialTariffConfigForm._get('supplierAddress')}
          //I know this is pretty ugly
          form={socialTariffConfigForm as unknown as BaseFormVjf<AddressNestedFormType>}
        />
      </CardBody>
    </Card>
  );
};

export default observer(ContactInformationCard);
