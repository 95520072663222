import { computed, makeObservable, observable } from 'mobx';

import { ContractType } from '../../../../../api-types/master-data/contract';
import RootStore from '../../../app-context/stores';

class Contract {
  public contract: ContractType;
  public rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      crumbText: computed,
      contract: observable
    });
  }

  get crumbText() {
    return Object.keys(this.contract).length > 0 ? this.contract.contractNumber : '';
  }
}

export default Contract;
