import React from 'react';

import { IncomingMutationRowType } from '@zf/api-types/billing/incoming-mutations';
import { incomingMutationStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import ResetDialog from '../../../../../../../actions/incoming-mutation/ResetDialog';
import { useAppContext } from '../../../../../../../app-context';
import ConfirmationActionButton from '../../../../../../../components/Button/ConfirmationActionButton';

type Props = {
  selectedRows: IncomingMutationRowType[];
  refresh: () => void;
};

export default function ResetDetailButton(props: Props) {
  const { selectedRows, refresh } = props;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const isDisabled = selectedRows.some((r) => {
    return (
      r.__entity.status !== incomingMutationStatus.readytoprocess &&
      r.__entity.status !== incomingMutationStatus.ignored
    );
  });

  return (
    <ConfirmationActionButton
      id="incoming_mutation.reset"
      icon="regenerate"
      dialogTitle={i18n.getTranslation('actions.incoming_mutation.reset')}
      dialogContent={<ResetDialog ref={clickRef} selectedRows={selectedRows} refresh={refresh} />}
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0 || isDisabled}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.incoming_mutation.reset')}
      type="text"
      dialogType="danger"
    />
  );
}
