import React from 'react';

import CollectionFlows from './collection-flows';
import useCollectionCycles from './hooks/useCollectionCycles';

export default function CollectionCyclesTab() {
  const collectionCyclesTools = useCollectionCycles();

  return !collectionCyclesTools.isLoading ? <CollectionFlows collectionCyclesTools={collectionCyclesTools} /> : null;
}
