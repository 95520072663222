export const colors: Record<string, any> = {
  'silver-600': '#8c98ab',
  'silver-700': '#6d7c93',
  'silver-400': '#bfcbdc',
  'silver-200': '#e8eef4',
  'silver-50': '#f1f8fa',

  'blue-600': '#1477ff',
  'blue-700': '#0058d1',
  'blue-400': '#71adff',
  'blue-200': '#d5e6ff',
  'blue-50': '#f3f7fe',

  'green-600': '#33c85d',
  'green-700': '#29a34b',
  'green-400': '#7adf97',
  'green-200': '#d5f6de',
  'green-50': '#f2fcf5',

  'yellow-600': '#ffdb5b',
  'yellow-700': '#dbb632',
  'yellow-400': '#fbe7a2',
  'yellow-200': '#fff6d6',
  'yellow-50': '#fffbef',

  'red-600': '#fd4949',
  'red-700': '#c72929',
  'red-400': '#ff8080',
  'red-200': '#ffe3e3',
  'red-50': '#fef7f7',

  'orange-600': '#ff993c',
  'orange-700': '#d87f2d',
  'orange-400': '#ffbd81',
  'orange-200': '#ffe8d3',
  'orange-50': '#fff8f1',

  'white-200': '#ffffff',
  graphite: '#32333a'
};

// These colors are unrelated to the ones above
const COLOR_LIST = [
  '#4894ff',
  '#446888',
  '#4AB7F6',
  '#9FAEBC',
  '#D1E0EF',
  '#ED3366',
  '#ea4435',
  '#FF9057',
  '#ffc108',
  '#FEC56D',
  '#4AD991',
  '#90E7BB',
  '#C580DF',
  '#F0C7FF',
  '#5B6BBF',
  '#FF4081'
];

/**
 * Picks a color from the design system based on a string
 * @param string value 
 * @returns hex color string
 */
export function generateColor(value: string | undefined | null) {
  if (value) {
    let selectedColor = 0;
    if (value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        selectedColor += value.charCodeAt(i);
      }
      selectedColor = selectedColor % COLOR_LIST.length;
    }

    return COLOR_LIST[selectedColor];
  }

  return colors['silver-600'];
}

/**
 * Converts a hex color string to an rgba css string
 * @param string hex 
 * @param number opacity 
 * @returns rgba css string
 */
export const convertHexToRGBA = (hex: string, opacity: number) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};
