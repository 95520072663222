import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../app-context';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import { closeManually } from '../apiFunctions';
import { RowTypeDetails, SetState } from '../hooks/useUnbilled';
import { RowTypeUnbilled } from '../hooks/useUnbilledActions';

type Props = {
  currentCompleteness?: BillingCompletenessType;
  completenesses?: BillingCompletenessType[];
  setCurrentCompleteness?: React.Dispatch<React.SetStateAction<BillingCompletenessType | undefined>>;
  updateGivenRows: (
    updatedRecords: BillingCompletenessType[],
    deletedRecords?: (RowTypeDetails | RowTypeUnbilled)[]
  ) => Promise<RowTypeUnbilled[]>;
  setState: React.Dispatch<SetState>;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { currentCompleteness, completenesses, setCurrentCompleteness, updateGivenRows, setState } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { contractStore } = useStore();

  let timeOut: number;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (currentCompleteness) {
        try {
          const updatedCompletness = await closeManually(currentCompleteness.id, tenantReducer, i18n.lang);

          if (setCurrentCompleteness) setCurrentCompleteness(updatedCompletness);

          setState({ actionTriggered: true, firstRefresh: true });

          timeOut = window.setTimeout(
            () => setState({ timeStamp: Date.now(), currentId: updatedCompletness.id, firstRefresh: false }),
            5000
          );

          updateGivenRows([updatedCompletness]);
          await contractStore.refresh();

          notify.success({
            content: i18n.getTranslation('completeness.close_manually_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('completeness.close_manually_fail'),
            error: e
          });
        }
      } else if (completenesses) {
        try {
          const updatedCompletnesses = await Promise.all(
            completenesses.map((c) => closeManually(c.id, tenantReducer, i18n.lang))
          );

          setState({ actionTriggered: true, firstRefresh: true });

          timeOut = window.setTimeout(() => setState({ timeStamp: Date.now(), firstRefresh: false }), 5000);

          updateGivenRows(updatedCompletnesses);

          notify.success({
            content: i18n.getTranslation('completeness.close_manually_multi_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('completeness.close_manually_multi_fail'),
            error: e
          });
        }
      }
    }
  }));

  React.useEffect(() => {
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <Paragraph>
      {i18n.getTranslation(`completeness.close_manually${completenesses ? '_multi' : ''}_paragraph`)}
    </Paragraph>
  );
});
