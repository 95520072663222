import { Observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';
import { ErrorBoundary } from '../../components/Error';
import { dialog } from '../../events/dialog-events';
import { bankDetailFormFields } from '../../features/customer/forms/BankDetailsForm';
import { useStore } from '../../hooks/useStore';
import BankDetailsCardContentBody from './BankDetailsCardContentBody';
import EditBankAccountsDialog from './dialogs/edit-bank-accounts/EditBankAccountsDialog';
import useBankAccounts from './hooks/useBankAccounts';
import Button from '../../components/Button/Button';

export default function BankDetailsCardContent() {
  const { i18n, authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const { clickRef, validationRef } = useDialog();
  const { customerStore } = useStore();

  const hasAuth = hasPermission('customer.update_billingdetails') && hasPermission('customer.default_bank_account');

  const { saveBankingDetails } = useBankAccounts();

  const openEditBankAccountsDialog = async () => {
    await customerStore.loadBankAccounts(customerStore.selectedCustomer.customer.id);

    dialog.normal({
      title: i18n.getTranslation('customer.edit_bank_accounts'),
      icon: 'sign',
      content: customerStore.selectedCustomer && (
        <EditBankAccountsDialog
          ref={clickRef}
          validationRef={validationRef}
          customer={customerStore.selectedCustomer.customer}
          defaultPaymentMethod={customerStore.bankDetailsForm.$(bankDetailFormFields.paymentMethod).value}
        />
      ),
      buttonPositive: i18n.getTranslation('general.ok'),
      ref: validationRef,
      onSubmit: () => {
        customerStore.loadCustomerDetailData(customerStore.selectedCustomer.customer.id);
      },
      onCancel: () => {
        customerStore.loadCustomerDetailData(customerStore.selectedCustomer.customer.id);
      }
    });
  };

  return (
    <Observer>
      {() => (
        <Card id="banking-details-card">
          <CardHeader
            primaryText={
              customerStore.bankDetailsForm._isDirty && hasAuth ? i18n.getTranslation('general.save') : undefined
            }
            onPrimaryClick={() => saveBankingDetails()}
            secondaryText={customerStore.bankDetailsForm.isDirty ? i18n.getTranslation('general.cancel') : undefined}
            onSecondaryClick={() => customerStore.bankDetailsForm._reset()}
            extraRight={
              !customerStore.bankDetailsForm.isDirty &&
              hasAuth && (
                <Button id="customer.default_bank_account" icon="sign" onClick={openEditBankAccountsDialog} type="text">
                  {i18n.getTranslation('general.edit')}
                </Button>
              )
            }
          >
            {i18n.getTranslation('cards.banking_details.title')}
          </CardHeader>
          <CardBody>
            <ErrorBoundary>
              <BankDetailsCardContentBody />
            </ErrorBoundary>
          </CardBody>
        </Card>
      )}
    </Observer>
  );
}
