import WizardSubmitButton from 'components/Button/WizardSubmitButton';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

const MoveOutWizard = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { moveOutWizardNavigator, handleSubmit } = contractStore.moveOutWizardBaseStore.moveOutWizardStore;
  const { hasErrors } = moveOutWizardNavigator;

  return (
    <FlexElements gap="32">
      <WizardSubmitButton
        id="contracts.terminate-and-move-in"
        onClick={() => handleSubmit(true)}
        disabled={hasErrors}
        type="secondary"
      >
        {getTranslation('contracts.wizard.terminate.terminate_and_move_in')}
      </WizardSubmitButton>
      <WizardSubmitButton id="contracts.terminate" onClick={handleSubmit} disabled={hasErrors}>
        {getTranslation('contracts.wizard.terminate.terminate_contract')}
      </WizardSubmitButton>
    </FlexElements>
  );
};

export default observer(MoveOutWizard);
