import { ListPageStore } from './types';

export const createListPageStore = (
  queryParams: Record<string, any>,
  defaultQuickFilter: string | null | undefined,
  overviewCounts: Record<string, number>
): ListPageStore => {
  return {
    filter: {
      queryParams,
      search: undefined,
      quickFilter: defaultQuickFilter
    },
    listPage: {
      sort: {
        sortDirection: {},
        sortBy: []
      },
      overviewCounts,
      selectedIds: [],
      selectedRows: [],
      deletedRows: [],
      updatedRows: [],
      allIds: [],
      rows: [],
      showSidePanel: false,
      aggregateDetails: []
    }
  };
};
