export const EVERY_FOUR_CHARS = /(.{4})(?!$)/g;

export const hexColorRe = new RegExp('^#?([0-9a-f]{6}|[0-9a-f]{3})$');

// Any GUID
export const guidRe = /([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})/g;

// A GUID followed by anything (URL paths)
export const guidPathRe = new RegExp('[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}.*');

/**
 * Checks if the given string is a GUID
 * @param string toTest 
 * @returns boolean 
 */
export const isGuid = (toTest: string) => guidRe.test(toTest);

/**
 * Joins 2 regex strings
 * @param regex1 
 * @param regex2 
 * @returns new regex
 */
export const concatRegex = (regex1: RegExp, regex2: RegExp) => {
  const flags = (regex1.flags + regex2.flags)
    .split('')
    .sort()
    .join('')
    .replace(/(.)(?=.*\1)/g, '');

  return new RegExp(regex1.source + regex2.source, flags);
};

// Any ISO 8601 date iso string
export const dateIsoRe = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/g;
export const isDateIsoString = (toTest: string) => dateIsoRe.test(toTest);
