import React from 'react';

import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { colors } from '@zf/utils/src/color';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useRefresh from '../../../app-context/hooks/use-refresh';
import PropertyGroupAutoFill, { PropertyGroupAutofillProps } from '../../../components/Autofills/PropertyGroupAutoFill';
import CloseCollectionCaseListPageButton from '../../../components/Button/CollectionCase/CloseCollectionCaseListPageButton';
import ProcessCollectionCaseListPageButton from '../../../components/Button/CollectionCase/ProcessCollectionCaseListPageButton';
import CollectionCaseExport from '../../../components/Button/Excel/CollectionCaseExport';
import FlowsDropdown from '../../../components/Dropdown/flows-dropdown/FlowsDropdown';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import DateRangePicker from '../../../components/input/DateRangePicker';
import MoneyInput from '../../../components/input/MoneyInput';
import useCollectionCaseFilter from '../hooks/useCollectionCaseFilter';
import CustomerGroupAutoFill, { CustomerGroupAutofillProps } from 'components/Autofills/CustomerGroupAutoFill';

type Props = {
  search?: string;
  extraColumnTitles: string[];
};

const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);
const InlineCustomerGroupAutoFill = InlineInputField<CustomerGroupAutofillProps>(CustomerGroupAutoFill);

const CollectionCaseFilterBar = (props: Props) => {
  const { extraColumnTitles } = props;
  const {
    overviewCount,
    quickFilter,
    searchValue,
    filterTags,
    queryParams,
    onFilter,
    onSearch,
    refreshCounts,
    clearQueryParams,
    setQueryParams
  } = useCollectionCaseFilter(props.search);
  const { refresh } = useRefresh();
  const { i18n } = useAppContext();

  return (
    <FilterBar
      actions={[
        <CloseCollectionCaseListPageButton key="mark-as-closed-action" refreshCounts={refreshCounts} />,
        <ProcessCollectionCaseListPageButton key="process-collection-case" refreshCounts={refreshCounts} />,
        <CollectionCaseExport key="export" extraColumnTitles={extraColumnTitles} />
      ]}
      subject={i18n.getTranslation('collection_case.collection_cases')}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      refreshPage={refresh}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="dunning" />}
          count={overviewCount.all}
          title={i18n.getTranslation('collection_case.list.filter.all')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          key="active"
          id="active"
          onTrigger={() => onFilter('active')}
          icon={<Icon type="dunning" />}
          count={overviewCount.active}
          title={i18n.getTranslation('collection_case.list.filter.active')}
          activeColor={colors['green-600']}
          active={quickFilter === 'active'}
        />,
        <FilterButton
          id="requiresmanualintervention"
          key="requiresmanualintervention"
          onTrigger={() => onFilter('requiresmanualintervention')}
          icon={<Icon type="dunning" />}
          count={overviewCount.requiresManualIntervention}
          title={i18n.getTranslation('collection_case.list.filter.requiresmanualintervention')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'requiresmanualintervention'}
        />,
        <FilterButton
          id="closed"
          key="closed"
          onTrigger={() => onFilter('closed')}
          icon={<Icon type="dunning" />}
          count={overviewCount.closed}
          title={i18n.getTranslation('collection_case.list.filter.closed')}
          activeColor={colors['orange-600']}
          active={quickFilter === 'closed'}
        />
      ]}
      filterInputs={[
        <Label key="label-property-group">{i18n.getTranslation('property_groups.property_group')}</Label>,
        <InlinePropertyGroupAutoFill
          id="by_property_group"
          key="by_property_group"
          onChange={(val) =>
            setQueryParams({
              propertyGroupId: val[0]?.id || '',
              propertyGroupName: val[0]?.name || ''
            })
          }
          selectedValues={[queryParams.propertyGroupId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,
        <Label key="label-customer-group">{i18n.getTranslation('customer_groups.customer_group')}</Label>,
        <InlineCustomerGroupAutoFill
          id="by_customer_group"
          key="by_customer_group"
          onChange={(val) =>
            setQueryParams({
              customerGroupName: val[0] ? val[0].name : '',
              customerGroupId: val[0] ? val[0].id : ''
            })
          }
          selectedValues={[queryParams.customerGroupId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,
        <ListDivider key="divider-0" />,
        <Label key="label-flow">{i18n.getTranslation('collection_case.list.filter.by_flow')}</Label>,
        <FlowsDropdown
          id="by_flow"
          key="by_flow"
          selectedValue={queryParams.workflowId ? queryParams.workflowId : ''}
          onChange={(val) =>
            setQueryParams({
              workflowId: val[0] ? val[0].id : '',
              workFlowName: val[0] ? val[0].name : ''
            })
          }
          isInline
          renderTopLvl={false}
        />,
        <Label key="label-invoice-date">{i18n.getTranslation('collection_case.list.filter.open_amount_lower')}</Label>,
        <MoneyInput
          id="open-amount-lower-limit"
          key="open-amount-lower-limit"
          value={queryParams.openAmountLowerLimit ? queryParams.openAmountLowerLimit : null}
          onChange={(val) => {
            setQueryParams({ openAmountLowerLimit: isNaN(val) ? 0 : val });
          }}
        />,
        <Label key="label-period">{i18n.getTranslation('collection_case.list.filter.next_action_due')}</Label>,
        <DateRangePicker
          id="next-action-due-filter"
          key="next-action-due-filter"
          startDate={convertToMoment(queryParams.nextActionDueFrom)}
          endDate={convertToMoment(queryParams.nextActionDueTo)}
          setDates={(dates) =>
            setQueryParams({
              nextActionDueFrom: convertMomentToISOString(dates[0]) as string,
              nextActionDueTo: convertMomentToISOString(dates[1]) as string
            })
          }
          renderTopLvl={false}
        />
      ]}
      onSearchChange={(searchVal) => onSearch(searchVal)}
      searchPlaceHolder={i18n.getTranslation('collection_case.list.filter.search')}
      searchValue={searchValue}
      searchDebounce={500}
    />
  );
};

export default CollectionCaseFilterBar;
