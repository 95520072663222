import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import InputContainer from '@zf/stella-react/src/atoms/InputContainer/InputContainer';

import MeterAutoFill from '../../../components/Autofills/MeterAutoFill';
import InputField from '../../../components/input/InputField';
import { DialogClickRef, ValidationRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import css from './link-meter-dialog.module.scss';

type Props = {
  meter: MeterType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  linkChild?: boolean;
  setMeter: React.Dispatch<React.SetStateAction<MeterType | null>>;
  refresh?: () => void;
};

const LinkMeterDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { meter, validationRef, linkChild = false, setMeter, refresh } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { updateParentRelation } = meterStore.deviceService;

  const [meterId, setMeterId] = React.useState('');
  const [externalRef, setExternalRef] = React.useState('');

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!meterId || !externalRef);
    }
  };

  React.useEffect(() => {
    validate();
  }, [meterId, externalRef]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        let updatedMeter: MeterType | null = null;
        if (linkChild) {
          updatedMeter = await updateParentRelation(meterId, meter.id, externalRef);
        } else {
          updatedMeter = await updateParentRelation(meter.id, meterId, externalRef);
        }

        if (!linkChild) {
          setMeter(updatedMeter);
        }

        if (refresh) {
          refresh();
        }

        notify.success({
          content: getTranslation('actions.meter.link_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('actions.meter.link_success'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['link-meter-dialog-wrapper']}>
      <InputContainer grid={false} className={css['inputs']}>
        <Heading headingType="h3" style="bold" noMarginBottom>
          {getTranslation(`actions.meter.link_${linkChild ? 'submeter' : 'child'}_paragraph`)}
        </Heading>
        <MeterAutoFill
          id="search-meter"
          onChange={(val) => setMeterId(val[0] ? val[0].id : '')}
          selectedValues={[meterId]}
          excludedIds={[meter.id]}
          placeholder={getTranslation('actions.meter.search_placeholder')}
          error={!meterId}
        />
        <Heading headingType="h3" style="bold" noMarginBottom>
          {getTranslation('actions.meter.add_ref')}
        </Heading>
        <InputField
          id="relationExternalReference"
          onChange={setExternalRef}
          value={externalRef}
          placeholder={getTranslation('actions.meter.add_ref_ex')}
          error={!externalRef}
        />
      </InputContainer>
    </div>
  );
});

export default observer(LinkMeterDialog);
