import React from 'react';

import { incomingBankingTransactionFormatType } from '@zf/api-types/enums';
import { FileUploadType } from '@zf/api-types/general';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../app-context';
import SingleUploadInput from '../../components/input/SingleUploadInput';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { notify } from '../../events/notification-events';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import { uploadIncomingBankingTransaction } from './incoming-banking-transactions';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refresh: () => void;
};

type State = {
  formatType: incomingBankingTransactionFormatType;
  file: FileUploadType | undefined;
};

const UploadIncomingBankingTransactionDialog = React.forwardRef(
  (props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
    const { validationRef, refresh } = props;
    const { i18n, tenantReducer, enumReducer } = useAppContext();

    const stateReducer = createStateReducer<State, Partial<State>>();
    const [state, setState] = React.useReducer(stateReducer, {
      formatType: '' as incomingBankingTransactionFormatType,
      file: undefined
    });

    const fileFormatsResponse = useSuspenseSingleAPI<incomingBankingTransactionFormatType[]>({
      request: {
        endpoint: '/bill/integration/import/incomingbankingtransaction/enabledFormats'
      }
    });

    React.useImperativeHandle(ref, () => ({
      async onClick() {
        try {
          if (state.file && state.file.file && state.formatType) {
            await uploadIncomingBankingTransaction(state.file.file, state.formatType, tenantReducer, i18n.lang);

            refresh();

            notify.success({
              content: i18n.getTranslation('general.upload_success')
            });
          }
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('general.upload_fail'),
            error: e
          });
        }
      }
    }));

    const setFile = React.useCallback(
      (newFile: FileUploadType | undefined) => {
        setState({ file: newFile });
      },
      [setState]
    );

    const validate = () => {
      if (validationRef && validationRef.current) {
        validationRef.current.setIsError(!state.formatType || !state.file);
      }
    };

    React.useEffect(() => {
      validate();
    }, [state]);

    if (!fileFormatsResponse.result) return null;

    const dropdownFormats = fileFormatsResponse.result.data.map((f) => {
      return {
        value: f,
        text: enumReducer.getTranslation('incomingBankingTransactionFormatType', f)
      };
    });

    return (
      <>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('actions.banking_transaction.format_type_heading')}
        </Heading>
        <InputContainer>
          <SimpleDropdown
            id="format_type"
            onChange={(val) => setState({ formatType: val[0] })}
            values={dropdownFormats}
            selectedValues={[state.formatType]}
            error={!state.formatType}
          />
          <SingleUploadInput file={state.file} setFile={setFile} mayReupload={false} />
        </InputContainer>
      </>
    );
  }
);

export default UploadIncomingBankingTransactionDialog;
