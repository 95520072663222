import Interweave from 'interweave';
import moment from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { utilityType } from '@zf/api-types/enums';
import { AggregatedServiceConsumptionsByServiceLocationType } from '@zf/api-types/metering/consumptions';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useValidatePeriod from '@zf/hooks/src/useValidatePeriod';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ProgressBar } from '@zf/stella-react/src/atoms/ProgressBar';
import Center from '@zf/stella-react/src/helpers/Center';
import { groupBy, splitArrayIntoChunksOfLength } from '@zf/utils/src/array';
import { MAX_DATE, MIN_DATE, DISPLAY_DATE_FORMAT } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import { EnumReturnValue } from '../../../app-context/hooks/use-enum';
import { LangReturnValue } from '../../../app-context/hooks/use-lang';
import { exportArrayToExcel } from '../../../components/Button/Excel/ExportToExcel';
import CheckBox from '../../../components/input/CheckBox';
import DateRangePicker from '../../../components/input/DateRangePicker';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import { METHODS, sendRequest } from '../../../utils/request';
import css from '../export-dialog.module.scss';
import { getPeriodicFileNameFormat } from '../../../utils/exports';

type Props = {
  selectedRows?: { __id: string }[];
  serviceLocationId?: string;
};

type State = {
  progress: number;
  isMapping: boolean;
  sumByUtilityType: boolean;
};

const mapConsumptions = (
  consumptions: AggregatedServiceConsumptionsByServiceLocationType[],
  sumByUtilityType: boolean,
  i18n: LangReturnValue,
  enumReducer: EnumReturnValue
) => {
  const exportArray = [];

  if (!sumByUtilityType) {
    exportArray.push([
      i18n.getTranslation('location.street_name'),
      i18n.getTranslation('location.streetnr'),
      i18n.getTranslation('location.streetnraddition'),
      i18n.getTranslation('location.postal'),
      i18n.getTranslation('location.city'),
      i18n.getTranslation('location.country'),
      i18n.getTranslation('meter.utility_type'),
      i18n.getTranslation('general.total'),
      i18n.getTranslation('meter.unit_of_measure'),
      i18n.getTranslation('meter.time_of_use'),
      i18n.getTranslation('invoice.period_start'),
      i18n.getTranslation('invoice.period_end')
    ]);
  } else {
    exportArray.push([
      i18n.getTranslation('meter.utility_type'),
      i18n.getTranslation('general.total'),
      i18n.getTranslation('meter.unit_of_measure'),
      i18n.getTranslation('meter.time_of_use'),
      i18n.getTranslation('invoice.period_start'),
      i18n.getTranslation('invoice.period_end')
    ]);
  }

  consumptions.forEach((cons) => {
    if (!sumByUtilityType) {
      exportArray.push([
        cons.serviceLocationAddress.streetName,
        cons.serviceLocationAddress.streetNumber,
        cons.serviceLocationAddress.streetNumberAddition,
        cons.serviceLocationAddress.postalCode,
        cons.serviceLocationAddress.city,
        cons.serviceLocationAddress.country.toUpperCase(),
        enumReducer.getTranslation('utilityType', cons.utilityType),
        cons.total,
        enumReducer.getTranslation('unitOfMeasure', cons.unitOfMeasure),
        cons.timeOfUse,
        moment(cons.periodStartDate).format(DISPLAY_DATE_FORMAT),
        moment(cons.periodEndDate).format(DISPLAY_DATE_FORMAT)
      ]);
    } else {
      exportArray.push([
        enumReducer.getTranslation('utilityType', cons.utilityType),
        cons.total,
        enumReducer.getTranslation('unitOfMeasure', cons.unitOfMeasure),
        cons.timeOfUse,
        moment(cons.periodStartDate).format(DISPLAY_DATE_FORMAT),
        moment(cons.periodEndDate).format(DISPLAY_DATE_FORMAT)
      ]);
    }
  });

  return exportArray;
};

const ExportServiceConsumptionsDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { serviceLocationId } = props;
  let { selectedRows = [] } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const locationIds: string[] = [];

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = React.useReducer(stateReducer, {
    progress: 0,
    isMapping: false,
    sumByUtilityType: false
  });

  const { dates, setDates } = useValidatePeriod();

  if (state.progress === 0) {
    // When on detail page
    if (selectedRows.length === 0 && !!serviceLocationId) {
      selectedRows = [{ __id: serviceLocationId }];
    }

    selectedRows.forEach((r) => {
      locationIds.push(r.__id);
    });
  }

  // API query accepts only 1000 ids per call
  const splittedLocationIds = splitArrayIntoChunksOfLength(locationIds, 500);

  const fetchData = async (
    data: Record<string, any>,
    aggregatedConsumptions: AggregatedServiceConsumptionsByServiceLocationType[]
  ) => {
    let continuationToken = '';
    let totalRecords = 0;
    let alreadyFetched = 0;

    while (continuationToken !== null) {
      const result = await sendRequest<PagedResponseType<AggregatedServiceConsumptionsByServiceLocationType>>({
        request: {
          method: METHODS.POST,
          endpoint: '/me/ServiceConsumptions/export/sum',
          data
        },
        customHeaders: {
          continuationToken: continuationToken
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      });

      // Only execute this the first time
      if (totalRecords === 0) totalRecords = result.data.totalRecords;

      aggregatedConsumptions.push(...result.data.results);

      // Avoid divide by 0
      if (totalRecords > 0) {
        alreadyFetched += result.data.results.length;
        const newProgress = ((alreadyFetched / totalRecords) * 100) / splittedLocationIds.length;
        setState({ progress: newProgress });
      }

      continuationToken = result.data.nextPageToken;
    }
  };

  const sumSlices = (aggregatedConsumptions: AggregatedServiceConsumptionsByServiceLocationType[]) => {
    const groupedByUtilityType: Record<utilityType, AggregatedServiceConsumptionsByServiceLocationType[]> = groupBy(
      aggregatedConsumptions,
      'utilityType'
    );

    const result: AggregatedServiceConsumptionsByServiceLocationType[] = [];

    Object.keys(groupedByUtilityType).forEach((utility) => {
      const groupedByUom = groupBy(groupedByUtilityType[utility as utilityType], 'unitOfMeasure');

      Object.keys(groupedByUom).forEach((uom) => {
        const groupedByTou = groupBy(groupedByUom[uom], 'timeOfUse');

        Object.keys(groupedByTou).forEach((tou) => {
          const arr = groupedByTou[tou];

          let sum = 0;

          arr.forEach((entry: AggregatedServiceConsumptionsByServiceLocationType) => {
            sum += entry.total;
          });

          const ref = arr[0];

          if (ref) {
            ref.total = sum;
          }

          result.push(ref);
        });
      });
    });

    return result;
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        let aggregatedConsumptions: AggregatedServiceConsumptionsByServiceLocationType[] = [];

        if (selectedRows.length === 0) {
          // Export all
          await fetchData(
            {
              startDateTime: dates.startDateTime.toISOString(),
              endDateTime: dates.endDateTime.toISOString(),
              sumByUtilityType: state.sumByUtilityType
            },
            aggregatedConsumptions
          );
        } else {
          // We call the API for each chunk of 1000 ids
          await Promise.all(
            splittedLocationIds.map(async (idsChunk) => {
              await fetchData(
                {
                  serviceLocationIds: idsChunk,
                  startDateTime: dates.startDateTime.toISOString(),
                  endDateTime: dates.endDateTime.toISOString(),
                  sumByUtilityType: state.sumByUtilityType
                },
                aggregatedConsumptions
              );
            })
          );
        }

        if (state.sumByUtilityType) {
          aggregatedConsumptions = sumSlices(aggregatedConsumptions);
        }

        setState({ isMapping: true });
        const mappedConsumptions = mapConsumptions(aggregatedConsumptions, state.sumByUtilityType, i18n, enumReducer);
        setState({ isMapping: false });

        const tags = getPeriodicFileNameFormat(dates.startDateTime, dates.endDateTime);

        //THE FUNCTION
        await exportArrayToExcel(
          i18n.getTranslation('location.serviced_locations')[0] +
            '-' +
            i18n.getTranslation('meter.consumptions') +
            `-${tags.isStartTagTranslatable ? i18n.getTranslation(tags.fileNameStartTag) : tags.fileNameStartTag}`,
          () => mappedConsumptions,
          tags.fileNameEndTag
        );
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.meter.export_consumptions_fail'),
          error: e
        });
      }
    }
  }));

  let paragraphText = i18n.getTranslation('actions.meter.export_consumptions_paragraph');

  if (state.isMapping) {
    paragraphText = i18n.getTranslation('actions.meter.export_consumptions_busy_mapping');
  } else if (state.progress > 0) {
    paragraphText = i18n.getTranslation('actions.meter.export_consumptions_busy_fetching');
  }

  return (
    <>
      <Paragraph>
        <Interweave content={paragraphText} />
      </Paragraph>
      {state.progress > 0 ? (
        <Center type="both" className={css['progress-bar']}>
          <ProgressBar size="large" progress={state.progress} showLabel />
        </Center>
      ) : (
        <InputContainer className={css['input-container']}>
          <DateRangePicker
            id="export-period"
            startDate={dates.startDateTime}
            endDate={dates.endDateTime}
            setDates={(dates_) =>
              setDates({
                startDateTime: dates_[0] ? dates_[0] : moment(MIN_DATE),
                endDateTime: dates_[1] ? dates_[1] : moment(MAX_DATE)
              })
            }
          />
          <br />
          <CheckBox
            id="group-by-utilitytype"
            checked={state.sumByUtilityType}
            onChange={(val) => setState({ sumByUtilityType: val })}
          >
            {i18n.getTranslation('actions.location.sum_by_utility_type')}
          </CheckBox>
        </InputContainer>
      )}
    </>
  );
});

export default ExportServiceConsumptionsDialog;
