import React from 'react';

import { PropertyGroupCalculationConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { Tree } from '@zf/stella-react/src/atoms/Tree';

import useBillingData from '../hooks/use-billing-data';
import css from './allocation-group-tree.module.scss';
import TreeBillingItem from './cost-calculator/tree-billing-item';

type Props = {
  index: number;
  calculationConfiguration: PropertyGroupCalculationConfigurationType;
  isDirty?: boolean;
};

export default function AllocationGroupTree(props: Props) {
  const { index, calculationConfiguration, isDirty = false } = props;
  const { setSelectedBillingItem } = useBillingData();

  return (
    <Tree
      id={`${calculationConfiguration.name}-${index}`}
      className={css['group-tree']}
      title={calculationConfiguration.name}
      arrowType="filled"
      openOnInit
    >
      {calculationConfiguration.billingItems.map((item) => {
        return (
          <TreeBillingItem
            key={item.billingItemId}
            item={item}
            isDirty={isDirty}
            setSelectedBillingItem={setSelectedBillingItem}
          />
        );
      })}
    </Tree>
  );
}
