import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { Wizard, WizardNav } from '@zf/stella-react/src/atoms/Wizard';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import WizardContainer from '../../../components/Wizard/WizardContainer';
import { STEP_NAMES } from './CustomerWizard';
import OrgAndScenRespInterface from './OrgAndScenRespInterface';

const AddCustomer = () => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  useBrowserTitle(getTranslation('customer.add_customer'));

  const [focusedStep, setFocusedStep] = React.useState(STEP_NAMES[0]);
  const [feedback, setFeedback] = React.useState<string[][]>([]);

  return (
    <WizardContainer
      icon="customer-add"
      text={getTranslation('wizard.breadcrumb', {
        subject: getEnumTranslation('entitySubjectType', entitySubjectType.customer)
      })}
    >
      <Wizard>
        <OrgAndScenRespInterface feedback={feedback} onFocusStep={setFocusedStep} setFeedback={setFeedback} />
      </Wizard>
      <WizardNav
        steps={[
          {
            header: getTranslation('customer.steps.essentials'),
            name: STEP_NAMES[0],
            feedback: feedback[0]
          },
          {
            header: getTranslation('customer.steps.banking'),
            name: STEP_NAMES[1],
            feedback: feedback[1] || null
          },
          {
            header: getTranslation('customer.steps.communication'),
            name: STEP_NAMES[2],
            feedback: feedback[2]
          },
          {
            header: getTranslation('errors.more_problems'),
            name: STEP_NAMES[3],
            feedback: feedback[3],
            error: true
          }
        ]}
        activeStep={focusedStep}
        onNavigate={setFocusedStep}
      />
    </WizardContainer>
  );
};

export default observer(AddCustomer);
