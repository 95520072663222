import moment from 'moment';
import React from 'react';

import { TotalOfInAndOutgoingInvoiceType } from '@zf/api-types/analytics/billing';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import SingleBarChart from '@zf/stella-react/src/atoms/Chart/SingleBarChart';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../app-context';
import { roundNumber } from '@zf/utils/src/number';
import css from './dashboard.module.scss';

import { colors } from '@zf/utils/src/color';

type Props = {
  incomingOutgoingData: TotalOfInAndOutgoingInvoiceType[];
};

export default function RevenueChartCard(props: Props) {
  const { incomingOutgoingData } = props;
  const { i18n } = useAppContext();

  let chartData: ChartDataType[] = [];
  let totalLast6Months = 0;

  if (incomingOutgoingData.length > 0) {
    // Latest 6 months
    const sliced = incomingOutgoingData.slice(Math.max(incomingOutgoingData.length - 6, 0));

    chartData = sliced.map((iod) => {
      const myMomentObject = moment(iod.yearAndMonth, 'YYYY-MM');
      const formatted = myMomentObject.format('MMM');

      return {
        month: formatted,
        Amount: roundNumber(iod.totalOutgoingInvoices),
        color: colors['blue-400']
      };
    });

    chartData[chartData.length - 1].color = colors['blue-600'];

    chartData.forEach((cd) => {
      totalLast6Months += cd.Amount as number;
    });
  }

  const hasData = chartData.length > 0;

  return (
    <BarChartCard
      subject="revenue"
      title={i18n.getTranslation('property_groups.tabs.billing.revenue')}
      extraRight={
        <Paragraph className={css['revenue-chart-extra-right-paragraph']} bold>
          {i18n.getTranslation('property_groups.tabs.billing.total_latest_months', {
            amount: formatMoney(totalLast6Months, i18n.culture)
          })}
        </Paragraph>
      }
    >
      {hasData ? (
        <SingleBarChart
          subject="revenue"
          chartData={chartData}
          margins={{ top: 10, left: 20 }}
          XdataKey="month"
          YdataKey="Amount"
          unit="€"
          locale={i18n.culture}
        />
      ) : (
        <EmptyChartState title={i18n.getTranslation('property_groups.tabs.billing.no_revenue_yet')} />
      )}
    </BarChartCard>
  );
}
