import { moveRequestType, uiCulture } from '@zf/api-types/enums';
import { ContractType } from '@zf/api-types/master-data/contract';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { MeterType } from '@zf/api-types/master-data/meter';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';

import { TenantReturnValue } from '../../../../../app-context/hooks/use-tenant-reducer';
import handleError from '../../../../../utils/handleError';
import { METHODS, sendRequest } from '../../../../../utils/request';

export default async function getExistingEntities(
  task: MoveRequestType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture,
  getMeterById: (meterId: string) => Promise<MeterType>
) {
  let existingCustomer: CustomerType | undefined = undefined;
  let existingLocation: ServiceLocationType | undefined = undefined;
  let existingPropertyGroupBillingConfig: PropertyGroupBillingConfigurationType | undefined = undefined;
  let existingMeters: MeterType[] | undefined = undefined;
  let existingContract: ContractType | undefined = undefined;
  let locationIsDerived = false;

  const internalMeterIds = task.measurementDetails.reduce((acc: string[], md) => {
    if (md.internalMeterId) acc.push(md.internalMeterId);
    return acc;
  }, []);

  if (task.customerDetails.internalId) {
    try {
      existingCustomer = (
        await sendRequest<CustomerType>({
          request: {
            method: METHODS.GET,
            endpoint: `/md/Customers/${task.customerDetails.internalId}`
          },
          tenantReducer,
          lang
        })
      ).data;
    } catch (e) {
      //@ts-ignore
      handleError(e);
    }
  }

  if (internalMeterIds.length > 0) {
    try {
      existingMeters = await Promise.all(internalMeterIds.map((id) => getMeterById(id)));
    } catch (e) {
      //@ts-ignore
      handleError(e);
    }
  }

  if (task.contractDetails.internalContractId) {
    try {
      existingContract = (
        await sendRequest<ContractType>({
          request: {
            method: METHODS.GET,
            endpoint: `/md/Contracts/${task.contractDetails.internalContractId}`
          },
          tenantReducer,
          lang
        })
      ).data;
    } catch (e) {
      //@ts-ignore
      handleError(e);
    }
  }

  if (task.serviceLocationDetails.internalId) {
    try {
      existingLocation = (
        await sendRequest<ServiceLocationType>({
          request: {
            method: METHODS.GET,
            endpoint: `/md/ServiceLocations/${task.serviceLocationDetails.internalId}`
          },
          tenantReducer,
          lang
        })
      ).data;

      if (existingLocation.propertyGroup) {
        existingPropertyGroupBillingConfig = (
          await sendRequest<PropertyGroupBillingConfigurationType>({
            request: {
              method: METHODS.GET,
              endpoint: `/bill/PropertyGroupBillingConfiguration/${existingLocation.propertyGroup.id}`
            },
            tenantReducer,
            lang
          })
        ).data;
      }
    } catch (e) {
      //@ts-ignore
      handleError(e);
    }
  }
  // Move-out: derive the location from the terminated contract
  else if (task.moveRequestType === moveRequestType.moveout && task.contractDetails.externalContractId) {
    try {
      const moveOutContract = (
        await sendRequest<ContractType>({
          request: {
            method: METHODS.GET,
            endpoint: `/md/Contracts/${task.contractDetails.externalContractId}`
          },
          tenantReducer,
          lang
        })
      ).data;

      // To be changed when we support multiple locations/contract here
      const contractLocationId = moveOutContract.serviceLocations[0] && moveOutContract.serviceLocations[0].id;

      if (contractLocationId) {
        existingLocation = (
          await sendRequest<ServiceLocationType>({
            request: {
              method: METHODS.GET,
              endpoint: `/md/ServiceLocations/${contractLocationId}`
            },
            tenantReducer,
            lang
          })
        ).data;

        locationIsDerived = true;
      }
    } catch (e) {
      //@ts-ignore
      handleError(e);
    }
  }

  return {
    existingCustomer,
    existingLocation,
    existingPropertyGroupBillingConfig,
    existingMeters,
    existingContract,
    locationIsDerived
  };
}
