import classNames from 'classnames';
import React from 'react';

import { BaseButton, TooltipTrigger } from '../';
import { SelectValueType } from '../../ComponentSets/Select/Select';
import { Icon } from '../../Foundation';
import css from './selection-button.module.scss';

export type SelectionButtonType = 'normal' | 'small';

export type SelectionButtonProps<T> = {
  selected: boolean;
  value: SelectValueType<T>;
  className?: string;
  type?: SelectionButtonType;
  icon?: string;
  disabled?: boolean;
  onSelect: React.MouseEventHandler<HTMLButtonElement>;
};

export default function SelectionButton<T>(props: SelectionButtonProps<T>) {
  const { className, icon, selected, value, type = 'normal', disabled = false, onSelect } = props;

  const iconComponent = icon ? <Icon name={icon} /> : null;

  const button = (
    <BaseButton
      id={`select-item-${value.value}`}
      className={classNames(
        css['selection-button'],
        css[type],
        { [css['selected']]: selected },
        { [css['disabled']]: disabled },
        { [css['selected-disabled']]: selected && disabled },
        className
      )}
      onClick={onSelect}
    >
      {iconComponent}
      {type === 'normal' && value.text}
    </BaseButton>
  );

  return type === 'small' ? <TooltipTrigger tooltip={value.text}>{button}</TooltipTrigger> : button;
}
