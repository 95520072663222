import React from 'react';
import classNames from 'classnames';

import css from './card-footer.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function CardFooter(props: Props) {
  const { children, className } = props;

  return <div className={classNames(css['card-footer'], className)}>{children}</div>;
}
