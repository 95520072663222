import clone from 'clone';
import { Info } from 'design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { billingCalculationType, billingScope, entitySubjectType, unitOfMeasure } from '@zf/api-types/enums';
import { CustomEntityPropertyCalculationTypeParameters } from '@zf/api-types/product';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import EntityPropertiesDropdown from '../../../../../../components/Dropdown/entity-properties-dropdown/EntityPropertiesDropdown';
import { BillingItemValidatorType } from '../AddEditBillingItemDialog';
import css from './custom-entity-property-parameters.module.scss';
import SharedParameters from './SharedParameters';

type Props = {
  calcParams: Map<billingCalculationType, CustomEntityPropertyCalculationTypeParameters>;
  uomDropDown: DropdownValuesType<unitOfMeasure>[];
  customEntityPropertyType: CustomEntityPropertyType | undefined;
  setParametersValue: (
    key: billingCalculationType,
    value: Partial<CustomEntityPropertyCalculationTypeParameters>
  ) => void;
  setValue: (value: Partial<BillingItemValidatorType>) => void;
};

const CustomEntityPropertyParameters = (props: Props) => {
  const { calcParams, customEntityPropertyType, setValue } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const parameters = calcParams.get(billingCalculationType.customentityproperty);
  const showContractLvlOption =
    customEntityPropertyType?.entitySubjectType !== entitySubjectType.meter &&
    customEntityPropertyType?.entitySubjectType !== entitySubjectType.servicelocation;

  const setCustomEntityProperty = (val: CustomEntityPropertyType[]) => {
    const clonedCalcParams = clone(calcParams);
    const entryToUpdate = clonedCalcParams.get(billingCalculationType.customentityproperty);

    if (entryToUpdate) {
      let scope = entryToUpdate.scope;

      if (val[0]?.entitySubjectType === entitySubjectType.servicelocation) {
        scope = billingScope.location;
      }

      clonedCalcParams.set(billingCalculationType.customentityproperty, {
        ...entryToUpdate,
        scope,
        customEntityPropertyTypeId: val[0]?.id || ''
      });
      setValue({ calculationParameters: clonedCalcParams, customEntityPropertyType: val[0] });
    }
  };

  return (
    <>
      <FlexElements className={css['custom-entity-property']} justifyContent="space-between" alignItems="center">
        <Paragraph textAlign="left">{getTranslation('settings.custom_entity_property')}</Paragraph>

        <EntityPropertiesDropdown
          id="custom-entity-property"
          className={css['custom-entity-property-dropdown']}
          selectedValue={parameters?.customEntityPropertyTypeId}
          onChange={setCustomEntityProperty}
          error={!parameters?.customEntityPropertyTypeId}
          hideCollectionCaseProps
        />
      </FlexElements>

      {customEntityPropertyType?.entitySubjectType === entitySubjectType.meter && (
        <Info>{getTranslation('billing_items.cep_meter_msg')}</Info>
      )}
      {customEntityPropertyType?.entitySubjectType === entitySubjectType.servicelocation && (
        <Info>{getTranslation('billing_items.cep_servicelocation_msg')}</Info>
      )}

      <SharedParameters
        billingCalculationType={billingCalculationType.customentityproperty}
        showContractLvlOption={showContractLvlOption}
        {...props}
      />
    </>
  );
};

export default observer(CustomEntityPropertyParameters);
