import React, { useEffect, useState } from 'react';

import { InvoiceType } from '@zf/api-types/invoice';
import { ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { Button } from '../../../../../../design-system/Components';
import { Spinner } from '../../../../../../design-system/Foundation';
import { dialog } from '../../../../../../events/dialog-events';
import { useStore } from '../../../../../../hooks/useStore';
import ChangeAdvanceAmountDialog from '../../../dialogs/change-advance-amount/change-advance-amount-dialog';
import css from './advanceamount-btn.module.scss';

type Props = {
  formattedAmount: string;
  contract: ContractType;
};

const AdvanceAmountButton = (props: Props) => {
  const { formattedAmount, contract } = props;
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getInvoicesForContract } = contractStore.contractApiService;
  const { onSubmit, validationRef, clickRef } = useDialog();

  const [overrulingInvoice, setOverrulingInvoice] = useState<InvoiceType | null | undefined>(null);

  useEffect(() => {
    getInvoicesForContract().then((invoices) =>
      setOverrulingInvoice(invoices.find((inv) => inv.advanceCalculationDetails?.newAdvanceAmountInclVAT !== null))
    );
  }, [contract.id]);

  if (overrulingInvoice === null) return <Spinner size="xsmall" />;

  const openChangeAdvanceAmountDialog = () => {
    dialog.normal({
      title: getTranslation('contracts.advance_amount_change'),
      icon: 'sign',
      content: (
        <ChangeAdvanceAmountDialog
          validationRef={validationRef}
          ref={clickRef}
          contract={contract}
          overrulingInvoice={overrulingInvoice}
        />
      ),
      buttonPositive: getTranslation('general.save'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <Button
      id="change-advance-amount-button"
      className={css['change-advance-amount-button']}
      onClick={openChangeAdvanceAmountDialog}
      iconPosition="right"
      icon="sign"
      type="text"
    >
      {formattedAmount}
    </Button>
  );
};

export default AdvanceAmountButton;
