import React from 'react';

import { ExportJobRowType, ExportJobType } from '@zf/api-types/export-job';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { APIExportJobType } from '../../../../api-types/export-job';
import { useAppContext } from '../../app-context';
import useRefresh from '../../app-context/hooks/use-refresh';
import { notify } from '../../events/notification-events';
import { transferExportJob } from './export-job';

type Props = {
  onComplete?: (updatedRows: ExportJobType[]) => void;
  selectedRows?: Array<ExportJobRowType>;
  exportJobToHandle?: APIExportJobType;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, exportJobToHandle, refreshCounts } = props;
  const { refresh } = useRefresh();
  const { i18n, tenantReducer } = useAppContext();

  let exportJobsToHandle: Array<APIExportJobType> = [];

  if (selectedRows) {
    exportJobsToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (exportJobToHandle) {
    exportJobsToHandle = [exportJobToHandle];
  }

  const multipleExportJobs = exportJobsToHandle.length > 1;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newExportJobs: Array<ExportJobType> = await Promise.all(
          exportJobsToHandle.map((exportJob) => transferExportJob(exportJob, tenantReducer, i18n.lang))
        );

        if (onComplete) {
          onComplete(newExportJobs);
          if (refreshCounts) refreshCounts();
        } else {
          refresh();
        }

        notify.success({
          content: multipleExportJobs
            ? i18n.getTranslation('actions.export_jobs.transfers_success')
            : i18n.getTranslation('actions.export_jobs.transfer_success')
        });
      } catch (e) {
        notify.error({
          content: multipleExportJobs
            ? i18n.getTranslation('actions.export_jobs.transfers_failed')
            : i18n.getTranslation('actions.export_jobs.transfer_failed'),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      {multipleExportJobs
        ? i18n.getTranslation('actions.export_jobs.transfer_heading_multi')
        : i18n.getTranslation('actions.export_jobs.transfer_heading')}
    </Paragraph>
  );
});
