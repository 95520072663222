import React from 'react';

import {
  ContractReferenceParametersType,
  EntityAttachment,
  ProductReferenceParametersType,
  PropertyGroupReferenceParametersType
} from '@zf/api-types/entity-attachments/entity-attachments';
import { entitySubjectType } from '@zf/api-types/enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import { EnumKeyType } from '../../../../app-context/hooks/use-enum';

export default function useSpecificFileColumns(
  rootUrl: string,
  getTranslation: (key: string, params?: Record<string, string | number>) => any,
  getEnumTranslation: (enumKey: EnumKeyType, symbol: string) => string
) {
  const translateOrigin = (entitySubject: entitySubjectType) => {
    return getEnumTranslation('entitySubjectType', entitySubject || getTranslation('general.unknown'));
  };

  const getOrigin = (entityAttachment: EntityAttachment) => {
    switch (entityAttachment.origin) {
      case entitySubjectType.customer:
        return {
          originUrl: `${rootUrl}/customers/${entityAttachment.customerId}`,
          origin: translateOrigin(entitySubjectType.customer),
          contractUrl: '',
          originDisplayName: '',
          contract: ''
        };
      case entitySubjectType.contract:
        const paramsContract = entityAttachment.parameters as ContractReferenceParametersType;
        return {
          originUrl: `${rootUrl}/contracts/${paramsContract.contract?.contractId}`,
          origin: `${translateOrigin(entitySubjectType.contract)}`,
          originDisplayName: paramsContract.contract.contractNumber,
          contractUrl: '',
          contract: ''
        };
      case entitySubjectType.entityattachmentgroup:
        const paramsPropertyGroup = entityAttachment.parameters as PropertyGroupReferenceParametersType;
        return {
          originUrl: `${rootUrl}/property-groups/${paramsPropertyGroup.entitySubjectId}`,
          origin: `${translateOrigin(entitySubjectType.propertygroup)}`,
          contract: `${
            paramsPropertyGroup.contracts.length === 1
              ? paramsPropertyGroup.contracts[0].contractNumber
              : paramsPropertyGroup.contracts.length > 1
              ? getTranslation('entity_attachment.multiple')
              : getTranslation('general.unknown')
          }`,
          originDisplayName: paramsPropertyGroup.entitySubjectDisplayName,
          contractUrl:
            paramsPropertyGroup.contracts.length === 1
              ? `${rootUrl}/contracts/${paramsPropertyGroup.contracts[0].contractId}`
              : '',
          contracts: paramsPropertyGroup.contracts
        };
      case entitySubjectType.product:
        const paramsProduct = entityAttachment.parameters as ProductReferenceParametersType;
        return {
          originUrl: `${rootUrl}/configuration/product?productName=${paramsProduct.product?.productName}`,
          origin: `${translateOrigin(entitySubjectType.product)}`,
          originDisplayName: paramsProduct.product.productName,
          contract: `${paramsProduct.contract.contractNumber}`,
          contractUrl: `${rootUrl}/contracts/${paramsProduct.contract.contractId}`,
          contracts: [paramsProduct.contract]
        };

      default:
        return {
          originUrl: '',
          origin: '',
          contractUrl: '',
          originDisplayName: '',
          contract: '',
          contracts: []
        };
    }
  };

  const getSeen = (entityAttachment: EntityAttachment) => {
    const isWaiting = !entityAttachment.viewedDateTime;

    return isWaiting ? (
      '-'
    ) : (
      <NewStatusBadge status_={formatDate(entityAttachment.viewedDateTime)} color={colors['green-600']} type="bare" />
    );
  };

  const getSigned = (entityAttachment: EntityAttachment) => {
    if (entityAttachment.origin === entitySubjectType.product) {
      const params = entityAttachment.parameters as ProductReferenceParametersType;
      const isWaiting = !params.signedFile?.signedDateTime;

      return isWaiting ? (
        '-'
      ) : (
        <NewStatusBadge status_={formatDate(params.signedFile?.signedDateTime)} color={colors['green-600']} />
      );
    } else if (entityAttachment.origin === entitySubjectType.entityattachmentgroup) {
      const params = entityAttachment.parameters as PropertyGroupReferenceParametersType;

      let statusBadgeText: string | null = null;

      if (params.contracts.length > 0) {
        if (params.contracts.length === 1) {
          if (params.contracts[0].signedFile) {
            statusBadgeText = formatDate(params.contracts[0].signedFile);
          }
        } else if (params.contracts.some((c) => !!c.signedFile)) {
          statusBadgeText = getTranslation('entity_attachment.multiple');
        }
      }

      return statusBadgeText === null ? '-' : <NewStatusBadge status_={statusBadgeText} color={colors['green-600']} />;
    }
  };

  return {
    getOrigin,
    getSeen,
    getSigned
  };
}
