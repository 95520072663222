import clone from 'clone';
import React from 'react';

import { TierType } from '@zf/api-types/collection-cycles';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../../../../../app-context';
import FloatInput from '../../../../../../components/input/FloatInput';
import MoneyInput from '../../../../../../components/input/MoneyInput';
import css from './tier.module.scss';

type Props = {
  index: number;
  value: TierType;
  dispatchValue: (value: Partial<TierType>) => void;
};

export default function Tier(props: Props) {
  const { index, value: chargeParams, dispatchValue } = props;
  const { i18n } = useAppContext();

  const setChargeParametersValue = (key: any, value: any) => {
    const chargeParametersClone = clone(chargeParams) as Record<string, any>;
    chargeParametersClone[key] = value;
    dispatchValue(chargeParametersClone);
  };

  return (
    <>
      <Heading headingType="h3" style="bold">
        {`${i18n.getTranslation('collection_flows.tier')} #${index + 1}`}
      </Heading>
      <InputContainer className={css['inputs']}>
        <MoneyInput
          id={`from-${index}`}
          value={chargeParams.from}
          onChange={(val) => setChargeParametersValue('from', val)}
          placeholder={i18n.getTranslation('collection_flows.from')}
          error={!chargeParams.from}
        />
        <MoneyInput
          id={`minAmount-${index}`}
          value={chargeParams.minAmount ? chargeParams.minAmount : null}
          onChange={(val) => setChargeParametersValue('minAmount', val)}
          placeholder={i18n.getTranslation('collection_flows.minAmount')}
          error={!chargeParams.minAmount}
        />
        <MoneyInput
          id={`maxAmount-${index}`}
          value={chargeParams.maxAmount ? chargeParams.maxAmount : null}
          onChange={(val) => setChargeParametersValue('maxAmount', val)}
          placeholder={i18n.getTranslation('collection_flows.maxAmount')}
          error={!chargeParams.maxAmount}
        />
        <FloatInput
          id={`percentage-${index}`}
          value={chargeParams.percentage}
          onChange={(val) => setChargeParametersValue('percentage', val)}
          placeholder={i18n.getTranslation('collection_flows.percentage')}
          postfix="%"
          error={!chargeParams.percentage}
        />
      </InputContainer>
    </>
  );
}
