import { useRef, useState, useLayoutEffect, MutableRefObject } from 'react';

type DimensionsType = {
  width: number;
  height: number;
};

export function useElementDimensions<T extends HTMLElement>(): [MutableRefObject<T | null>, DimensionsType] {
  const ref = useRef<T>(null);

  const [dimensions, setDimensions] = useState<DimensionsType>({
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions({ width: ref.current.clientWidth, height: ref.current.clientHeight });
    }
  }, [ref.current]);

  return [ref, dimensions];
}
