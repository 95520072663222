import { observer } from 'mobx-react';
import React from 'react';

import {
  ConsumptionAskingPeriodType,
  ConsumptionReceivingPeriodType,
  RequiredConsumptionQuantityType
} from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';
import { BillingItemType } from '@zf/api-types/product';
import Collapsable from '@zf/stella-react/src/atoms/Collapsable/Collapsable';
import AlertCircle from '@zf/stella-react/src/atoms/Info/AlertCircle';
import { formatDate } from '@zf/utils/src/date';

import { ICON_COLORS } from '../../../../../../../constants/icons';
import { Link } from '../../../../../../../design-system/Components';
import { Info } from '../../../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../../../hooks/useStore';
import ErrorsTip from '../ErrorsTip';
import MeterCompleteness from './MeterCompleteness';
import css from './required-consumption.module.scss';

type Props = {
  billingItem: BillingItemType;
  productName: string;
  requiredQuantity: RequiredConsumptionQuantityType;
  defaultClosed: boolean;
};

const RequiredConsumption = observer((props: Props) => {
  const { billingItem, requiredQuantity, defaultClosed, productName } = props;

  const { contractStore, applicationStore } = useStore();
  const { getTranslation, rootUrl } = applicationStore;
  const {
    selectedCompleteness,
    getCorrespondingReceivingPeriods,
    getRequiredQuantityCompleteness,
    getAllErrorsForConsumptionRequiredQuantity
  } = contractStore.billingCompletenessStore;

  const errors = getAllErrorsForConsumptionRequiredQuantity(requiredQuantity);
  const { title, color } = getRequiredQuantityCompleteness(requiredQuantity);

  return (
    <Collapsable
      title={title}
      titleColor={color}
      extraLeft={
        <div className={css['extra-left']}>
          <Link
            id={`billing-item-${billingItem.id}`}
            className={css['billing-item']}
            url={`${rootUrl}/configuration/product/products?billingItemId=${
              billingItem.id
            }&productName=${encodeURIComponent(productName)}`}
            icon={requiredQuantity.utilityType}
            iconColor={ICON_COLORS[requiredQuantity.utilityType]}
          >
            {billingItem.name}
          </Link>
          <div>
            {!requiredQuantity.complete && errors.length > 0 && (
              <AlertCircle>
                <ErrorsTip errors={errors} />
              </AlertCircle>
            )}
          </div>
        </div>
      }
      defaultClosed={defaultClosed}
    >
      {selectedCompleteness.status === billingCompletenessStatus.waiting ? (
        <Info className={css['info']}>
          {getTranslation('invoice.info_in_future', {
            invoicingCheckpointDateTime: formatDate(selectedCompleteness.invoicingCheckpointDateTime)
          })}
        </Info>
      ) : selectedCompleteness.status === billingCompletenessStatus.previousnotclosed ? (
        <Info className={css['info']}>
          {getTranslation('invoice.info_prev_not_closed', {
            invoicingCheckpointDateTime: formatDate(selectedCompleteness.invoicingCheckpointDateTime)
          })}
        </Info>
      ) : (
        <div className={css['meter-completeness']}>
          {requiredQuantity.askingPeriods.map((ap, apIndex) => {
            const askingPeriod = ap as ConsumptionAskingPeriodType;
            const correspondingReceivingPeriods = getCorrespondingReceivingPeriods(
              askingPeriod,
              requiredQuantity.receivingPeriods
            );

            if (correspondingReceivingPeriods.length > 0) {
              return correspondingReceivingPeriods.map((crp, crpIndex) => {
                return (
                  <MeterCompleteness
                    key={`${ap.startDateTime}-${ap.endDateTime}-${apIndex}-${crp.startDateTime}-${crp.endDateTime}-${crpIndex}`}
                    apIndex={apIndex}
                    crpIndex={crpIndex}
                    askingPeriod={askingPeriod}
                    locationId={requiredQuantity.serviceLocationId}
                    isComplete={requiredQuantity.complete}
                    correspondingReceivingPeriod={crp as ConsumptionReceivingPeriodType}
                  />
                );
              });
            } else {
              return (
                <MeterCompleteness
                  key={`${ap.startDateTime}-${ap.endDateTime}-${apIndex}`}
                  apIndex={apIndex}
                  askingPeriod={askingPeriod}
                  isComplete={requiredQuantity.complete}
                  locationId={requiredQuantity.serviceLocationId}
                />
              );
            }
          })}
        </div>
      )}
    </Collapsable>
  );
});

export default RequiredConsumption;
