import { action, makeObservable, observable } from 'mobx';
import validator from 'validator';

import {
  AddCustomerBankAccountRequestType,
  CustomerBankAccountType
} from '@zf/api-types/billing/customer-bank-account';
import { mandateType, paymentMethod } from '@zf/api-types/enums';

import { store } from '../../../app-context/storeContext';
import BaseFormVjf, { FieldPropertiesType, Fields } from '../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../events/notification-events';
import CustomerStore from '../stores/CustomerStore';

export enum bankDetailFormFields {
  paymentMethod = 'paymentMethod',
  paymentTerm = 'paymentTerm',
  defaultBankAccount = 'defaultBankAccount'
}

interface BankDetailFieldTypes {
  paymentMethod?: paymentMethod;
  paymentTerm?: string;
  defaultBankAccount?: string;
}

class BankDetailsForm extends BaseFormVjf<BankDetailFieldTypes> {
  public isMandateDialog: boolean;
  public customerStore: CustomerStore;

  constructor(customerStore: CustomerStore, initialValues: BankDetailFieldTypes) {
    super(initialValues);
    this.customerStore = customerStore;

    makeObservable(this, {
      saveBankingDetails: action,
      setMandateDialog: action,
      isMandateDialog: observable
    });

    this._observers([
      {
        path: 'defaultBankAccount',
        key: 'value',
        call: ({ field }: FieldPropertiesType<BankDetailsForm>) => {
          this.setDefaultObjectObserver(field.value);
        }
      }
    ]);
  }

  setDefaultObjectObserver(bankAccountId: string) {
    const currentDefaultIndex = this.customerStore.bankAccounts.results.findIndex((acc) => {
      return acc.isDefault;
    });
    const newDefaultIndex = this.customerStore.bankAccounts.results.findIndex((acc) => {
      return acc.id === bankAccountId;
    });

    if (this.customerStore.bankAccounts.results[currentDefaultIndex]) {
      this.customerStore.bankAccounts.results[currentDefaultIndex].isDefault = false;
    }
    if (this.customerStore.bankAccounts.results[newDefaultIndex]) {
      this.customerStore.bankAccounts.results[newDefaultIndex].isDefault = true;
    }
  }

  setup(): Fields {
    return {
      fields: [
        {
          label: 'Payment method',
          name: bankDetailFormFields.paymentMethod,
          validators: [this.isRequired]
        },
        {
          label: 'Payment term',
          name: bankDetailFormFields.paymentTerm,
          validators: [this.isRequired]
        },
        {
          label: 'Default bank account',
          name: bankDetailFormFields.defaultBankAccount,
          validators: [this.defaultBankAccountRequired]
        }
      ]
    };
  }

  defaultBankAccountRequired({ field, form }: FieldPropertiesType<any>) {
    let isValid: boolean;
    if (form._get('paymentMethod') === paymentMethod.sdd) {
      isValid = field.value !== 'value' && !validator.isEmpty(field.value);
    } else {
      isValid = true;
    }
    return [isValid, store.applicationStore.getTranslation('mandates.mandate_required')];
  }

  setMandateDialog(isdialog: boolean) {
    this.isMandateDialog = isdialog;
  }

  async saveBankingDetails() {
    const promises: Promise<any>[] = [];
    promises.push(
      this.customerStore.customerService.changeCustomerBankingDetails(
        {
          paymentTermsId: this.$(bankDetailFormFields.paymentTerm).value,
          defaultPaymentMethod: this.$(bankDetailFormFields.paymentMethod).value
        },
        this.customerStore.selectedCustomer.customer.id
      )
    );

    let isCustomerBankAccountChanged = false;
    const defaultBankAccount = this.customerStore.bankAccounts.results.find((e) => {
      return e.id === this._get('defaultBankAccount');
    });

    if (defaultBankAccount) {
      const apifriendlyValues: AddCustomerBankAccountRequestType = {
        iban: defaultBankAccount.iban,
        activeMandate: defaultBankAccount.activeMandate
          ? defaultBankAccount.activeMandate
          : this._get('paymentMethod') === paymentMethod.sdd
          ? {
              type: mandateType.core,
              signedDateTime: defaultBankAccount.mandateForm?._getNested('mandate', 'signedDateTime') as string
            }
          : null,
        bic: defaultBankAccount.bic,
        isDefault: defaultBankAccount.isDefault
      };

      if (!defaultBankAccount.activeMandate || this._isDirty) {
        isCustomerBankAccountChanged = true;
        const customerAcc = this.customerStore.customerService.updateCustomerBankAccount(
          defaultBankAccount.id,
          apifriendlyValues
        );
        promises.push(customerAcc);
      }
    }
    await Promise.all(promises)
      .then((res) => {
        if (isCustomerBankAccountChanged && defaultBankAccount) {
          const customerAcc = res[1] as CustomerBankAccountType;
          defaultBankAccount.activeMandate = customerAcc.activeMandate;
        }
        //backup
        this.customerStore.initBankDetails(new BankDetailsForm(this.customerStore, this._values));
        notify.success({
          content: store.applicationStore.getTranslation('actions.customer.bank_update')
        });
      })
      .catch((e) => {
        notify.error({
          content: store.applicationStore.getTranslation('actions.customer.bank_update_fail'),
          error: e
        });
      });
  }

  onSuccess() {
    this.saveBankingDetails();
  }

  hooks() {
    return {
      onSuccess: this.onSuccess
    };
  }
}

export default BankDetailsForm;
