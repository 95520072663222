import React from 'react';

import { Spinner } from './';

type Props<T> = {
  variables: Array<T | null | undefined>;
  children: React.ReactNode;
};

export default function AsyncVarSpinner<T>(props: Props<T>) {
  const { variables, children } = props;

  const allDefined = variables.every((v) => {
    return v !== null && typeof v !== 'undefined';
  });

  return allDefined ? <>{children}</> : <Spinner centered />;
}
