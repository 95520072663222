import clone from 'clone';
import React from 'react';

import { PaymentAnalyticsType } from '@zf/api-types/analytics/payments';
import { paymentProcessStatus } from '@zf/api-types/enums';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import { BaseLink } from '../../../../design-system/Components';
import css from './todo-card.module.scss';

type Props = {
  data: PaymentAnalyticsType;
  generateIconParagraph: (text: string) => JSX.Element;
};

export default function TodoPayments(props: Props) {
  const { data, generateIconParagraph } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const { myCurrent, setMyCurrent } = useCurrent('invoice');

  const setPaymentStatus = React.useCallback((type: paymentProcessStatus[]) => {
    const currentClone = clone(myCurrent);
    currentClone.filter.queryParams = { paymentProcessStatus: type };
    setMyCurrent(currentClone);
  }, []);

  return (
    <>
      <BaseLink
        id="todo-ready-to-send"
        className={css['todo-item']}
        url={`${rootUrl}/payments/outgoing-banking-transactions?quickFilter=readytosend`}
      >
        <Label>{i18n.getTranslation('dashboard.general_tab.outgoing_transactions_ready')}</Label>
        {generateIconParagraph(
          i18n.getTranslation('dashboard.general_tab.amount_transactions', {
            amount: data.outgoingBankingTransactionsReadyToSend
          })
        )}
      </BaseLink>

      <BaseLink
        id="todo-ready"
        className={css['todo-item']}
        url={`${rootUrl}/payments/incoming-banking-transactions?quickFilter=ready`}
      >
        <Label>{i18n.getTranslation('dashboard.general_tab.incoming_transactions_ready')}</Label>
        {generateIconParagraph(
          i18n.getTranslation('dashboard.general_tab.amount_transactions', {
            amount: data.incomingBankingTransactionsReadyToProcess
          })
        )}
      </BaseLink>

      <BaseLink
        id="todo-to-confirm"
        className={css['todo-item']}
        url={`${rootUrl}/payments/outgoing-banking-transactions?quickFilter=toconfirm`}
      >
        <Label>{i18n.getTranslation('dashboard.general_tab.outgoing_transactions_awaiting_confirmation')}</Label>
        {generateIconParagraph(
          i18n.getTranslation('dashboard.general_tab.amount_transactions', {
            amount: data.outgoingBankingTransactionsAwaitingBankConfirmation
          })
        )}
      </BaseLink>

      <BaseLink
        id="todo-outgoing-invalid"
        className={css['todo-item']}
        url={`${rootUrl}/payments/outgoing-banking-transactions?quickFilter=resolveissues`}
      >
        <Label>{i18n.getTranslation('dashboard.general_tab.outgoing_transactions_invalid')}</Label>
        {generateIconParagraph(
          i18n.getTranslation('dashboard.general_tab.amount_transactions', {
            amount: data.outgoingBankingTransactionsInvalid
          })
        )}
      </BaseLink>

      <BaseLink
        id="todo-incoming-invalid"
        className={css['todo-item']}
        url={`${rootUrl}/payments/incoming-banking-transactions?quickFilter=resolveissues`}
      >
        <Label>{i18n.getTranslation('dashboard.general_tab.incoming_transactions_invalid')}</Label>
        {generateIconParagraph(
          i18n.getTranslation('dashboard.general_tab.amount_transactions', {
            amount: data.incomingBankingTransactionsInvalid
          })
        )}
      </BaseLink>

      <BaseLink
        id="todo-awaiting-action"
        className={css['todo-item']}
        url={`${rootUrl}/collection-cases?quickFilter=requiresmanualintervention`}
      >
        <Label>{i18n.getTranslation('dashboard.general_tab.collections_awaiting_action')}</Label>
        {generateIconParagraph(
          i18n.getTranslation('dashboard.general_tab.amount_collections', {
            amount: data.collectionCasesAwaitingManualAction
          })
        )}
      </BaseLink>

      <BaseLink
        id="todo-ready-for-collection"
        className={css['todo-item']}
        url={`${rootUrl}/invoices?quickFilter=readyforpaymentcollection`}
        onNavigate={() => setPaymentStatus([paymentProcessStatus.readyforpaymentcollection])}
      >
        <Label>{i18n.getTranslation('dashboard.general_tab.invoices_ready_for_collection')}</Label>
        {generateIconParagraph(
          i18n.getTranslation('dashboard.general_tab.amount_invoices', {
            amount: data.invoicesReadyForPaymentCollection
          })
        )}
      </BaseLink>
    </>
  );
}
