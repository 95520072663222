import React, { ReactNode } from 'react';

import { StellaWizardContainer } from '@zf/stella-react/src/atoms/Wizard';
import { capitalizeFirstWord } from '@zf/utils/src/string';

import { WizardContainer as DesignSystemWizardContainer } from '../../design-system/Components';

import { BreadCrumb, Crumb } from '../BreadCrumb';
import CrumbHeader from '../Header/CrumbHeader/CrumbHeader';

type Props = {
  children: ReactNode;
  icon: string;
  text: string;
  isDesignSystem?: boolean; // To be removed when wizard layout is completely design system
};

export default function WizardContainer(props: Props) {
  const { children, icon, text, isDesignSystem = false } = props;

  const crumb = (
    <CrumbHeader
      breadcrumb={
        <BreadCrumb>
          <Crumb icon={icon} text={capitalizeFirstWord(text)} />
        </BreadCrumb>
      }
    />
  );

  return isDesignSystem ? (
    <DesignSystemWizardContainer breadCrumb={crumb}>{children}</DesignSystemWizardContainer>
  ) : (
    <StellaWizardContainer breadCrumb={crumb}>{children}</StellaWizardContainer>
  );
}
