import { useEffect } from 'react';

import { useAppContext } from '../app-context';

export default function useBrowserTitle(title: string) {
  const { tenantReducer } = useAppContext();
  const { organization } = tenantReducer.state;

  let prefix = 'Zero Friction - ERP';

  if (organization) {
    prefix =
      process.env.REACT_APP_NODE_ENV === 'staging' ? `STAG - ${organization.shortCode}` : organization?.shortCode || '';
  }

  useEffect(() => {
    document.title = `${prefix} - ${title}`;
  }, []);
}
