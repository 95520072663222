import React from 'react';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { useAppContext } from '../../app-context/app-context';
import { createHeader, METHODS, sendRequest } from '../../utils/request';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { notify } from '../../events/notification-events';

type Props = {
  values: OrganizationConfigType;
  setValue: (
    value: Partial<OrganizationConfigType>,
    initialApiSet?: boolean | undefined,
    backup_?: boolean | undefined
  ) => void;
};

const ResetOrganisationDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { values, setValue } = props;
  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const organizationResponse = await sendRequest<OrganizationConfigType>({
          request: {
            method: METHODS.POST,
            endpoint: `/cfg/Organizations/${values.id}/initialize`
          },
          customHeaders: createHeader({
            'If-Match': values._etag
          }),
          tenantReducer,
          lang: i18n.lang
        });

        notify.success({
          content: i18n.getTranslation('organization.initialize_update')
        });

        setValue({ ...organizationResponse.data }, false, true);
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('organization.error_initialize'),
          error: e
        });
      }
    }
  }));

  return <Paragraph textAlign="center">{i18n.getTranslation('organization.reset_org')}</Paragraph>;
});

export default ResetOrganisationDialog;
