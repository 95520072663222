import {
  IbanConfirmationStatus,
  IncomingBankingTransactionType
} from '@zf/api-types/billing/incoming-banking-transaction';
import { incomingBankingTransactionFormatType, uiCulture } from '@zf/api-types/enums';
import { createFormData } from '@zf/auth/src/utils';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';

export const uploadIncomingBankingTransaction = async (
  file: File,
  formatType: incomingBankingTransactionFormatType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<File>({
      request: {
        method: METHODS.POST,
        endpoint: '/bill/integration/import/incomingbankingtransaction',
        data: createFormData({
          FormatType: formatType,
          FormFile: file
        })
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const processIncomingBankingTransaction = async (
  incomingBankingTransactionId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<IncomingBankingTransactionType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/IncomingBankingTransactions/${incomingBankingTransactionId}/process`
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const UpdateIbanConfirmation = async (
  incomingBankingTransactionId: string,
  customerId: string,
  iban: string,
  confirm: boolean,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<IncomingBankingTransactionType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/IncomingBankingTransactions/${incomingBankingTransactionId}/UpdateIbanConfirmation`,
        data: {
          customerId,
          iban,
          confirm
        }
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const getIbanConfirmation = async (
  incomingMutationId: string,
  customerId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<IbanConfirmationStatus>({
      request: {
        method: METHODS.GET,
        endpoint: `/bill/IncomingMutations/${incomingMutationId}/ibanstatus/calculate`,
        query: {
          customerId
        }
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const deleteIncomingBankingTransaction = async (
  bankingTransactionId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/bill/IncomingBankingTransactions/${bankingTransactionId}`
      },
      tenantReducer,
      lang
    })
  ).data;
};
