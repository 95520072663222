import React from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import Introduction from '@zf/stella-react/src/atoms/Introduction/Introduction';

import { useAppContext } from '../../../app-context';

export default function GeneralTab() {
  const { i18n } = useAppContext();

  return (
    <>
      <CardsContainer>
        <Introduction
          width="2"
          headerText={i18n.getTranslation('organization.general_tab.introduction_header')}
          introductionText={i18n.getTranslation('organization.general_tab.introduction_text')}
        />
      </CardsContainer>
    </>
  );
}
