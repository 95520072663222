import { Moment } from 'moment';

import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';
import { uiCulture } from '@zf/api-types/enums';

export type IncomingInvoiceRequestType = {
  propertyGroupId: string;
  invoiceNumber: string;
  invoiceDate: Moment;
  description: string;
  startDate: string;
  endDate: string;
  totalAmountExclVat: number;
  totalAmountInclVat: number;
};

export type IncomingInvoiceComponentsToBillingPeriodRequestType = {
  incomingInvoiceComponentId: string;
  value: number;
  incomingInvoiceId: string;
};

export async function submitIncomingInvoice(
  apiFriendlyValues: IncomingInvoiceRequestType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture,
  invoiceId?: string
) {
  return (
    await sendRequest<IncomingInvoiceType>({
      request: {
        method: METHODS.POST,
        endpoint: invoiceId ? `/bill/IncomingInvoices/${invoiceId}` : '/bill/IncomingInvoices',
        data: apiFriendlyValues
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function addIncomingInvoiceComponentsToBillingPeriod(
  apiFriendlyValues: IncomingInvoiceComponentsToBillingPeriodRequestType,
  propertyGroupId: string,
  billingPeriodId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<PropertyGroupBillingPeriodType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}/${billingPeriodId}/IncomingInvoiceCostComponentValues`,
        data: apiFriendlyValues
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteIncomingInvoice(id: string, tenantReducer: TenantReturnValue, lang: uiCulture) {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/bill/IncomingInvoices/${id}`
      },
      tenantReducer,
      lang
    })
  ).data;
}
