import React from 'react';

import { CostComponentPagedResultType } from '@zf/api-types/product';

import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import CostComponents from './cost-components';

export default function CostComponentsTab() {
  const response = useSuspenseSingleAPI<CostComponentPagedResultType>(
    {
      request: {
        endpoint: '/cfg/CostComponents'
      }
    },
    false,
    false
  );

  if (!response.result) return null;

  return <CostComponents costComponents={response.result.data.results} />;
}
