import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import UploadImportJobDialog from '../../../actions/import-jobs/UploadImportJobDialog';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import { SuspenseSpinner } from '../../suspense';

type Props = {
  refreshCounts: () => void;
};

export default function UploadImportJobsListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { refresh } = useRefresh();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="import_jobs.add"
      icon="upload-cloud"
      dialogTitle={getTranslation('general.upload')}
      dialogContent={
        <SuspenseSpinner>
          <UploadImportJobDialog
            onComplete={refresh}
            ref={clickRef}
            validationRef={validationRef}
            refreshCounts={refreshCounts}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={getTranslation('actions.upload_import_job')}
    />
  );
}
