import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { AdvancePeriodType } from '@zf/api-types/billing/billing-completeness';
import { customerType, invoiceType } from '@zf/api-types/enums';
import { RateType } from '@zf/api-types/tax-codes';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatPeriodWMonth } from '@zf/utils/src/date';
import { formatMoney, roundNumber } from '@zf/utils/src/number';

import IconParagraph from '../../../../../../components/Icon/IconParagraph';
import { DANGER, SUCCESS } from '../../../../../../constants/color';
import { ICON_COLORS } from '../../../../../../constants/icons';
import { useStore } from '../../../../../../hooks/useStore';
import css from './advance-tooltip.module.scss';

export type AdvanceIconType =
  | ''
  | 'sent'
  | 'inFuture'
  | 'automaticallyDeleted'
  | 'manuallyDeleted'
  | 'check'
  | 'questionmark'
  | 'created'
  | 'generated'
  | 'advSkipped';

type Props = {
  advancePeriod: AdvancePeriodType;
  statusTranslation: string;
  advanceIconType: AdvanceIconType;
};

const paymentStatus: string[] = ['sent', 'generated', 'check'];

const Advance = observer((props: Props) => {
  const { advancePeriod, advanceIconType, statusTranslation } = props;
  const { configStore, contractStore, applicationStore } = useStore();

  const { getTranslation, getEnumTranslation, culture } = applicationStore;

  const { customerType: customerType_ } = contractStore.billingRelation.debtor;
  const { _billingParameters, _taxCodes } = configStore;

  const currentTaxCodeId =
    customerType_ === customerType.person
      ? _billingParameters?.defaultTaxCodeForPersonAdvances
      : _billingParameters?.defaultTaxCodeForOrganisationAdvances;

  let currentTaxRate = _taxCodes?.reduce((acc: RateType[], tc) => {
    if (tc.id === currentTaxCodeId) {
      const currentRate = tc.rates.find((tr) => {
        return (
          moment(tr.startDateTime).isSameOrBefore(advancePeriod.startDateTime) &&
          moment(tr.endDateTime).isSameOrAfter(advancePeriod.endDateTime)
        );
      });

      if (currentRate) {
        acc.push(currentRate);
      }
    }
    return acc;
  }, [])[0];

  // When no match we have 2 rates for the advance period => use the first that overlaps
  if (!currentTaxRate) {
    currentTaxRate = _taxCodes?.reduce((acc: RateType[], tc) => {
      if (tc.id === currentTaxCodeId) {
        const currentRate = tc.rates.find((tr) => {
          return !(
            (moment(tr.startDateTime).isBefore(advancePeriod.startDateTime) &&
              moment(tr.endDateTime).isBefore(advancePeriod.startDateTime)) ||
            (moment(tr.startDateTime).isAfter(advancePeriod.endDateTime) &&
              moment(tr.endDateTime).isAfter(advancePeriod.endDateTime))
          );
        });

        if (currentRate) {
          acc.push(currentRate);
        }
      }
      return acc;
    }, [])[0];
  }

  return (
    <div className={css['advance-info']}>
      <Heading headingType="h3" style="bold">
        {`${getEnumTranslation('invoiceType', invoiceType.advance)}\u00A0`}
        <span className={classNames({ [css['empty']]: !advancePeriod.invoiceNum })}>
          {advancePeriod.invoiceNum || getTranslation('general.empty')}
        </span>
      </Heading>
      <Paragraph>{formatPeriodWMonth(advancePeriod.startDateTime, advancePeriod.endDateTime)}</Paragraph>
      <div className={css['amount']}>
        <Label bold>{getTranslation('general.amount_valuta')}</Label>

        <Paragraph className={css['advance-info-tax-rate']} bold>
          {currentTaxRate &&
            formatMoney(roundNumber(advancePeriod.advanceAmountExclVAT * (1 + currentTaxRate.rate)), culture)}
        </Paragraph>
      </div>
      <div className={css['status-wrapper']}>
        {advanceIconType !== 'check' && (
          <IconParagraph iconType={advanceIconType} color={ICON_COLORS[advanceIconType]}>
            {statusTranslation}
          </IconParagraph>
        )}
        {paymentStatus.includes(advanceIconType) && (
          <IconParagraph
            iconType={advancePeriod.paid ? 'check' : 'cancel'}
            color={advancePeriod.paid ? SUCCESS : DANGER}
          >
            {getTranslation(`invoice.${advancePeriod.paid ? 'paid' : 'unpaid'}`)}
          </IconParagraph>
        )}
      </div>
    </div>
  );
});

export default Advance;
