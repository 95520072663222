import React from 'react';

import AccessibleDivButton from '../Button/AccessibleDivButton';
import Icon from './Icon';

type IconPropsType = {
  id?: string;
  name: string;
  ariaLabel?: string;
  style?: React.CSSProperties;
  className?: string;
  onTrigger: () => void;
  title?: string;
  skipTab?: boolean;
};

/**
 * Deprecated, to be removed when we redesign the input fields
 */
export default function ClickableIcon(props: IconPropsType) {
  const { id, onTrigger, ariaLabel, skipTab = false, ...otherProps } = props;

  return (
    <AccessibleDivButton id={id} onTrigger={onTrigger} ariaLabel={ariaLabel} skipTab={skipTab}>
      <Icon id={`icon-${id}`} {...otherProps} />
    </AccessibleDivButton>
  );
}
