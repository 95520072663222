import { observer } from 'mobx-react';
import React from 'react';

import { MeterPropertiesPrepayment } from '@zf/api-types/master-data/meter';
import { colors } from '@zf/utils/src/color';
import { formatMoney } from '@zf/utils/src/number';

import { Paragraph } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import css from './balance-card.module.scss';

type Props = {
  meterProperties: MeterPropertiesPrepayment;
};

const BalanceCardHeader = (props: Props) => {
  const { meterProperties } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;
  return (
    <div className={css['balance']}>
      <div className={css['balance-label-wrapper']}>
        <Paragraph color={colors['silver-600']} className={css['balance-label']}>
          {getTranslation('prepayment_device.current_balance')}
        </Paragraph>
        <Paragraph bold textAlign="right">
          {formatMoney(meterProperties.currentBalance, culture)}
        </Paragraph>
      </div>
      <div className={css['balance-label-wrapper']}>
        <Paragraph color={colors['silver-600']} className={css['balance-label']}>
          {getTranslation('prepayment_device.emergency')}
        </Paragraph>
        <Paragraph bold color={colors['silver-600']} textAlign="right">{`${formatMoney(
          meterProperties.configuredEmergencyCredit,
          culture
        )}/${formatMoney(meterProperties.configuredEmergencyCredit, culture)}`}</Paragraph>
      </div>
      <div className={css['balance-label-wrapper']}>
        <Paragraph color={colors['silver-600']} className={css['balance-label']}>
          {getTranslation('prepayment_device.debt')}
        </Paragraph>
        <Paragraph textAlign="right">{formatMoney(0, culture)}</Paragraph>
      </div>
    </div>
  );
};

export default observer(BalanceCardHeader);
