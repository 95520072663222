import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Router } from '@reach/router';
import { BillingParametersType } from '@zf/api-types/parameters';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';
import Center from '@zf/stella-react/src/helpers/Center';

import { ErrorBoundary } from '../../components/Error';
import { Loader } from '../../components/Loader';
import SuspenseSpinner from '../../components/suspense/suspense-spinner';
import { useStore } from '../../hooks/useStore';
import Route from '../route';
import BillingOverviewTab from './billing-overview/billing-overview-tab';
import CustomerAgingTab from './customer-aging/customer-aging-tab';
import GeneralTab from './general/GeneralTab';

type Props = {
  location: Location;
  navigate: (url: string) => void;
};

const DashboardTabContainer = (props: Props) => {
  const { location, navigate } = props;
  const { applicationStore, configStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { getBillingParameters } = configStore.configService;

  const [billingParameters, setBillingParameters] = useState<BillingParametersType>();

  useEffect(() => {
    getBillingParameters().then((params) => setBillingParameters(params));
  }, []);

  if (!billingParameters)
    return (
      <Center type="both">
        <Loader />
      </Center>
    );

  const endOfMonth = moment().endOf('month').toISOString();

  const setActiveTab = (tabId: string) => navigate(`${rootUrl}/${tabId}`);

  const splitUrl = location.href.split('/');

  const customerAgingTab = () => (
    <SuspenseSpinner>
      <CustomerAgingTab />
    </SuspenseSpinner>
  );

  const tabItems = [
    {
      id: 'general',
      title: getTranslation('dashboard.general'),
      isDefault: true
    },
    {
      id: 'billing_overview',
      title: getTranslation('dashboard.billing_overview')
    }
  ];

  if (billingParameters && !billingParameters.skipPayment) {
    tabItems.push({
      id: 'customer_aging',
      title: getTranslation('dashboard.customer_aging.customer_aging')
    });
  }

  return (
    <TabContainer
      id="dashboard"
      onSelectTab={setActiveTab}
      entityId={getTranslation('nav.dashboard')}
      selectedTab={splitUrl[splitUrl.length - 1]}
      tabItems={tabItems}
    >
      <ErrorBoundary>
        <Router>
          <Route
            Component={GeneralTab}
            path="general"
            paymentsActive={!billingParameters.skipPayment}
            endOfMonth={endOfMonth}
            setActiveTab={setActiveTab}
            default
          />
          <Route Component={BillingOverviewTab} path="billing_overview" />
          <Route Component={customerAgingTab} path="customer_aging" />
        </Router>
      </ErrorBoundary>
    </TabContainer>
  );
};

export default observer(DashboardTabContainer);
