import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import Center from '@zf/stella-react/src/helpers/Center';

import AddBillingPeriodDialog from '../../../../../../actions/property-group/add-billing-period-dialog';
import { useAppContext } from '../../../../../../app-context';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { ZFSpinner } from '../../../../../../components/Loader';
import { dialog } from '../../../../../../events/dialog-events';
import useBillingData from '../../hooks/use-billing-data';
import useBillingPeriods from '../../hooks/useBillingPeriods';
import { useBillingPeriodsFilter } from '../../hooks/useBillingPeriodsFilter';
import NoBillingPeriods from './no-billing-periods';

type Props = {
  propertyGroupId: string;
};

export type BillingInsightsQueryParams = {
  billingRelationId: string;
  orderBy: string;
};

export default function BillingPeriods(props: Props) {
  const { propertyGroupId } = props;

  const { i18n, authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const { setSelectedBillingPeriod, setBillingPeriods, billingPeriods } = useBillingData();
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { queryParams, filterTags, filters } = useBillingPeriodsFilter();

  const {
    selectedIds,
    loading,
    error,
    rows,
    sortableFields,
    sortBy,
    sortDirection,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useBillingPeriods(propertyGroupId, queryParams);

  const openAddDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.billing.add_billing_period_header'),
      icon: 'plus',
      content: (
        <AddBillingPeriodDialog
          ref={clickRef}
          validationRef={validationRef}
          propertyGroupId={propertyGroupId}
          billingPeriods={billingPeriods}
          setBillingPeriods={setBillingPeriods}
        />
      ),
      buttonPositive: i18n.getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      title={i18n.getTranslation('property_groups.tabs.billing.billing_periods')}
      subTitleBelow={i18n.getTranslation('property_groups.tabs.billing.select_billing_period')}
      add={hasPermission('billing_periods.add') ? openAddDialog : undefined}
      extraRight={
        <>
          {filterTags}
          {filters}
        </>
      }
      borderStyle="dashed"
    >
      {rows.length > 0 ? (
        <DynamicVirtualTable
          id="billing-periods-table"
          tooltipId="billing-periods-table-tip"
          rows={rows}
          error={error}
          onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
          onSelectRow={(newIds) => {
            setSelectedIds(newIds);
            const selectedRow = rows.find((r) => {
              return r.__billingPeriodEntity.id === newIds[0];
            });
            if (selectedRow) setSelectedBillingPeriod(selectedRow.__billingPeriodEntity);
          }}
          selectedRows={[selectedIds[0]]}
          rowHeight={45}
          loading={loading}
          NoDataOverlay={NoBillingPeriods}
          totalAmountOfRows={rows.length}
          sortBy={sortBy}
          sortDirection={sortDirection}
          sortableFields={sortableFields}
          singleSelect
          invert
          showCheckBoxes={false}
          sort={handleSort}
          cursorPointer
          columns={[
            {
              width: 150,
              label: i18n.getTranslation('general.period'),
              dataKey: 'period'
            },
            {
              width: 30,
              label: '',
              dataKey: 'dash'
            },
            {
              width: 140,
              label: '',
              dataKey: 'p_end'
            },
            {
              width: 140,
              label: '',
              dataKey: 'status'
            },
            {
              width: 20,
              label: '',
              dataKey: 'delete'
            }
          ]}
        />
      ) : loading ? (
        <Center type="both">
          <ZFSpinner />
        </Center>
      ) : (
        <EmptyColumn
          image="attributes2"
          title={i18n.getTranslation('property_groups.tabs.billing.no_billing_periods')}
          text={i18n.getTranslation('property_groups.tabs.billing.no_billing_periods_descr')}
        />
      )}
    </ConfigColumn>
  );
}
