import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { deleteCustomerFromCustomerGroupsBulk, deleteCustomerFromCustomerGroupsSingle } from './Delete.API';
import { useAppContext } from 'app-context';

const CustomerDeleteDialog = forwardRef(
  (
    props: { onComplete?: Function; selectedIds: Array<string>; customerGroupId: string },
    ref: Ref<DialogClickRef | undefined>
  ) => {
    const { applicationStore } = useStore();
    const { selectedIds, customerGroupId, onComplete } = props;
    const { getTranslation } = applicationStore;
    const { i18n, tenantReducer } = useAppContext();

    async function batchAPI(arr: string[]) {
      let init = 0;
      for (let i = 0; i < arr.length / 200; i++) {
        return await deleteCustomerFromCustomerGroupsBulk(
          arr.slice(init, (init += 200)),
          customerGroupId,
          tenantReducer,
          i18n.lang
        );
      }
    }

    useImperativeHandle(ref, () => ({
      async onClick() {
        try {
          if (onComplete) {
            if (selectedIds.length === 1) {
              await deleteCustomerFromCustomerGroupsSingle(selectedIds[0], customerGroupId, tenantReducer, i18n.lang);
            } else {
              await batchAPI(selectedIds);
            }
            onComplete();
            notify.success({
              content: i18n.getTranslation('customer_groups.remove_customer_from_customer_group_success')
            });
          }
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('customer_groups.remove_customer_from_customer_group_fail'),
            error: e
          });
        }
      }
    }));

    return <Paragraph>{getTranslation('customer_groups.delete_customer_from_group')}</Paragraph>;
  }
);

export default observer(CustomerDeleteDialog);
