import classNames from 'classnames';
import React from 'react';

import css from './label-item-grid.module.scss';

type Props = {
  children: React.ReactNode;
  withPadding?: boolean;
  className?: string;
};

export default function LabelItemGrid(props: Props) {
  const { children, className, withPadding = true } = props;

  return (
    <div className={classNames(css['label-item-grid'], { [css['with-padding']]: withPadding }, className)}>
      {children}
    </div>
  );
}
