import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import ReconfigureMeterDialog from '../../../actions/meters/reconfigure/reconfigure-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  meter: MeterType;
  onComplete?: React.Dispatch<React.SetStateAction<MeterType>>;
};

export default function ReconfigMeterListItem(props: Props) {
  const { meter, onComplete } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="meter.update"
      icon="settings"
      dialogTitle={i18n.getTranslation('actions.meter.reconfigure')}
      dialogContent={
        <ReconfigureMeterDialog meter={meter} onComplete={onComplete} ref={clickRef} validationRef={validationRef} />
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.reconfigure_meter')}
      dialogLocation="right"
    />
  );
}
