import React from 'react';
import { CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';

import { colors } from '@zf/utils/src/color';

import CustomTooltip from './CustomTooltip';
import CustomXAxisTick from './CustomXAxisTick';
import CustomYAxisTick from './CustomYAxisTick';
import { IntervalType } from './types';

export const ZFChartLayout = (
  subject: string,
  XdataKey: string,
  locale: string,
  labelInterval?: IntervalType,
  yDomain?: AxisDomain | undefined,
  centeredLabels = false,
  xAxisTickFormatter?: (value: string) => string,
  showXTicks = true
) => {
  let tick: JSX.Element | boolean | undefined = xAxisTickFormatter ? undefined : <CustomXAxisTick />;

  if (!showXTicks) {
    tick = false;
  }

  return [
    <CartesianGrid key={`${subject}-grid`} strokeOpacity={0.5} strokeDasharray="1 2" />,
    <XAxis
      key={`${subject}-x`}
      axisLine={false}
      tickLine={false}
      dataKey={XdataKey}
      tick={tick}
      tickFormatter={xAxisTickFormatter}
      interval={labelInterval}
      padding={{ left: 0, right: centeredLabels ? 0 : 10 }}
    />,
    <YAxis
      key={`${subject}-y`}
      tick={<CustomYAxisTick locale={locale} />}
      domain={yDomain ? yDomain : [0, 'auto']}
      axisLine={false}
      tickLine={false}
    />,
    <ReferenceLine key={`${subject}-y-0`} y={0} stroke={colors['silver-700']} strokeWidth={0.5} />,
    <Tooltip
      key={`${subject}-tooltip`}
      content={<CustomTooltip locale={locale} />}
      cursor={{ fill: colors['blue-50'] }}
    />
  ];
};
