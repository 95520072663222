import moment from 'moment';
import React from 'react';

import { Reference } from '@zf/api-types/payments';
import { TransactionType } from '@zf/api-types/transactions';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import { colors } from '@zf/utils/src/color';
import { formatDate, today } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import useCreateRequest from '../../hooks/useCreateRequest';
import useInfiniAPI from '../../hooks/useInfiniAPI';
import { getMainDetail, getOpenAmountColor } from '../../utils/transaction';
import NoTransactions from './no-transactions';
import { SettleState } from './settle';
import css from './style.module.scss';
import useSettle, { LocalTransactionRowType } from './useSettle';

type Props = {
  customerId: string;
  state: SettleState;
  setState: React.Dispatch<Partial<SettleState>>;
  setReferences?: (val: Reference[]) => void; // Add payment dialog
  setTransactions?: (val: TransactionType[]) => void; // Settle dialog
};

export default function SettleTable(props: Props) {
  const { customerId, state, setState, setReferences, setTransactions } = props;
  const { i18n, tenantReducer } = useAppContext();

  const [columns] = React.useState<ColumnType[]>([
    {
      width: 120,
      label: i18n.getTranslation('general.date'),
      dataKey: 'transactionDateTime'
    },
    {
      width: 120,
      label: i18n.getTranslation('cards.cash_position.transactionDueDateTime'),
      dataKey: 'dueDate'
    },
    {
      width: 100,
      label: i18n.getTranslation('general.amount'),
      dataKey: 'amount'
    },
    {
      width: 120,
      label: i18n.getTranslation('invoice.open_amount'),
      dataKey: 'openAmount',
      headerAlignRight: true
    },
    {
      width: 140,
      label: i18n.getTranslation('cards.transactions.invoiceNum'),
      dataKey: 'invoiceNum'
    },
    {
      width: 100,
      label: i18n.getTranslation('cards.transactions.cancelled'),
      dataKey: 'cancelled'
    },
    {
      width: 140,
      label: i18n.getTranslation('cards.transactions.cancelled_reason'),
      dataKey: 'cancelledReason'
    }
  ]);

  const { request } = useCreateRequest(`/bill/transactions/c/${customerId}`, {
    quickFilter: state.amount >= 0 ? 'topay' : 'torefund',
    orderBy: 'TransactionDateTime',
    refreshTrigger: state.refreshTrigger // For the edge case in the useEffect below
  });

  const processRecord = React.useCallback((transaction: TransactionType) => {
    return {
      __id: transaction.id,
      __entity: transaction,
      amount: (
        <Paragraph className={transaction.transactionAmount > 0 ? css['left'] : css['right']}>
          {formatMoney(Math.abs(transaction.transactionAmount), i18n.culture)}
        </Paragraph>
      ),
      openAmount: (
        <Paragraph color={getOpenAmountColor(transaction)}>
          {formatMoney(Math.abs(transaction.transactionOpenAmount), i18n.culture)}
        </Paragraph>
      ),
      invoiceNum: getMainDetail(transaction.referenceDetails),
      transactionDateTime: formatDate(transaction.transactionDateTime),
      dueDate: (
        <Paragraph color={moment(transaction.transactionDueDateTime).isSameOrBefore(today()) ? colors['red-400'] : ''}>
          {formatDate(transaction.transactionDueDateTime)}
        </Paragraph>
      ),
      reversed: transaction.reversed ? <Icon type="cancel" /> : '',
      reversalReason: transaction.reversalReason
    };
  }, []);

  const { loading, error, rows, setStopIndex, updateGivenRows } = useInfiniAPI<
    TransactionType,
    LocalTransactionRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  const updateReferences = (rowIds: string[]) => {
    // Add payment dialog
    if (setReferences) {
      const references = rowIds.reduce((acc: Reference[], id) => {
        const rowOfInterest = rows.find((row) => row.__id === id);

        if (rowOfInterest) {
          const transaction = rowOfInterest.__entity;

          acc.push({
            referenceId: transaction.referenceDetails?.transactionReferenceId || '',
            referenceType: transaction.referenceDetails?.transactionReferenceType || null,
            transactionId: transaction.id
          });
        }

        return acc;
      }, []);

      setReferences(references);
    }

    // Settle dialog
    if (setTransactions) {
      const transactions = rowIds.reduce((acc: TransactionType[], id) => {
        const rowOfInterest = rows.find((row) => row.__id === id);

        if (rowOfInterest) {
          acc.push(rowOfInterest.__entity);
        }

        return acc;
      }, []);

      setTransactions(transactions);
    }
  };

  const { handleSelectionChange } = useSettle(state, setState, rows, updateGivenRows, updateReferences);

  React.useEffect(() => {
    // when we change the payment amount, reset our state & rows
    if (state.selectedIds.length > 0 && !loading) {
      setState({
        selectedIds: [],
        remainingAmount: state.amount,
        refreshTrigger: ((Date.now() / 1000) | 0).toString()
      });
    }
  }, [state.amount, loading]);

  return (
    <div className={css['table-wrapper']}>
      <div className={css['table-content']}>
        <DynamicVirtualTable
          id="transactions-table"
          tooltipId="transactions-table-tip"
          rows={rows}
          onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
          onSelectRow={handleSelectionChange}
          selectedRows={state.selectedIds}
          totalAmountOfRows={rows.length}
          loading={loading}
          error={error}
          NoDataOverlay={NoTransactions}
          columns={columns}
        />
      </div>
    </div>
  );
}
