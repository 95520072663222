import React from 'react';

import { IncomingMutationRowType } from '@zf/api-types/billing/incoming-mutations';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { resetMatch } from './incoming-mutations';

type Props = {
  selectedRows: IncomingMutationRowType[];
  refresh: () => void;
};

const ResetDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, refresh } = props;
  const { i18n, tenantReducer } = useAppContext();

  const postfix = selectedRows.length > 1 ? '_multi' : '';

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await Promise.all(
          selectedRows.map((r) => {
            return resetMatch(r.__entity.id, tenantReducer, i18n.lang);
          })
        );

        refresh();

        notify.success({
          content: i18n.getTranslation(`actions.incoming_mutation.reset_success${postfix}`)
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`actions.incoming_mutation.reset_fail${postfix}`),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation(`actions.incoming_mutation.reset_paragraph${postfix}`)}</Paragraph>;
});

export default ResetDialog;
