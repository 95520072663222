import React from 'react';

import useTimeout from '@zf/hooks/src/useTimeout';

const DEFAULT_TIMEOUT = 75;

type Props = {
  timeout?: number;
  spinner: JSX.Element;
};

export default function TimeoutSpinner(props: Props) {
  const { timeout = DEFAULT_TIMEOUT, spinner } = props;

  const [timedout, setTimedout] = React.useState(false);
  const { resume } = useTimeout(() => {
    setTimedout(true);
  }, timeout);

  React.useEffect(() => {
    resume();
  }, []);

  if (timedout) {
    return spinner;
  }

  return <div />;
}
