import { uiCulture } from '@zf/api-types/enums';
import { DeleteImportJobType, ImportJobType, TriggerImportJobType } from '@zf/api-types/import-job';
import { writeToCSVFile } from '@zf/utils/src/download';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { createHeader, METHODS, sendRequest } from '../../utils/request';

export async function deleteImportJob(
  value: DeleteImportJobType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<ImportJobType> {
  return (
    await sendRequest<ImportJobType>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/logs/ImportJob/${value.id}`
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteAllImportJobs(tenantReducer: TenantReturnValue, lang: uiCulture) {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: '/logs/ImportJob/all'
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function triggerImportJob(
  value: TriggerImportJobType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<ImportJobType> {
  return (
    await sendRequest<ImportJobType>({
      request: {
        method: METHODS.POST,
        endpoint: `/logs/ImportJob/${value.id}/process`
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function processAllImportJobs(
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<ImportJobType[]> {
  return (
    await sendRequest<ImportJobType[]>({
      request: {
        method: METHODS.POST,
        endpoint: `/logs/ImportJob/processall`
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function validateImportJob(
  importJob: TriggerImportJobType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<ImportJobType> {
  return (
    await sendRequest<ImportJobType>({
      request: {
        method: METHODS.POST,
        endpoint: `/logs/ImportJob/${importJob.id}/validate`
      },
      customHeaders: createHeader({
        'If-Match': importJob.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function getExample(fileFormatId: string, tenantReducer: TenantReturnValue, lang: uiCulture) {
  const result = await sendRequest<string>(
    {
      request: {
        method: METHODS.GET,
        endpoint: `/logs/ImportJob/format/${fileFormatId}/example`
      },
      tenantReducer,
      lang
    },
    true
  );

  const fileName = result.headers['content-disposition'].split(';')[2].split("''")[1];

  writeToCSVFile(result.data, fileName);
}
