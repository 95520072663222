import PropertyGroupBillingConfigurationService from 'app-context/services/billing/PropertyGroupBillingConfigurationService';
import { action, makeObservable, observable } from 'mobx';

import { EntityAttachmentGroup } from '@zf/api-types/entity-attachments/entity-attachments';
import { entitySubjectType } from '@zf/api-types/enums';
import {
  CalculationConfigurationBillingItemType,
  PropertyGroupCalculationConfigurationType
} from '@zf/api-types/property-group-billing-configuration';

import RootStore from '../..';
import BillingItemsDialogStore from '../../../../components/billing-items-dialog/stores/BillingItemsDialogStore';
import AttachmentGroupBase from '../../../../components/units/EntityAttachments/logic/AttachmentGroupBase';
import { EntityAttachmentSpecificFeatures } from '../../../../components/units/EntityAttachments/logic/entity-attachments';
import FilesStore from '../../../../components/units/EntityAttachments/logic/FilesStore';
import PropertyGroupService from '../../../../features/property-group/services/PropertyGroupService';

export default class PropertyGroupStore implements EntityAttachmentSpecificFeatures<AttachmentGroupBase> {
  private rootStore: RootStore;
  public filesStore: FilesStore<EntityAttachmentGroup>;
  public entityAttachmentFeatures: AttachmentGroupBase = new AttachmentGroupBase();

  public propertyGroupService: PropertyGroupService;
  public propertyGroupBillingConfigurationService: PropertyGroupBillingConfigurationService;

  public billingItemsDialogStore: BillingItemsDialogStore<
    PropertyGroupCalculationConfigurationType,
    CalculationConfigurationBillingItemType
  >;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.billingItemsDialogStore = new BillingItemsDialogStore(this.rootStore);
    this.filesStore = new FilesStore(
      this.rootStore,
      entitySubjectType.propertygroup,
      this.entityAttachmentFeatures.setPermissions
    );

    this.propertyGroupService = new PropertyGroupService(this.rootStore.applicationStore);
    this.propertyGroupBillingConfigurationService = new PropertyGroupBillingConfigurationService(
      this.rootStore.applicationStore
    );

    this.entityAttachmentFeatures.setFileStore(this.filesStore);

    makeObservable(this, {
      billingItemsDialogStore: observable,
      filesStore: observable,

      onLeaveDetailPage: action
    });
  }

  onLeaveDetailPage = () => {
    this.filesStore.resetStore();
  };
}
