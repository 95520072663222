import React from 'react';

import { CustomerBankAccountType } from '@zf/api-types/billing/customer-bank-account';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { confirmIban } from '../../../actions/customers/customer';
import { useAppContext } from '../../../app-context';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';

type Props = {
  customerBankAccountId: string;
  onConfirmComplete: (updatedAccount: CustomerBankAccountType, bankAccounts: CustomerBankAccountType[]) => void;
};

const BankDetailsDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { customerBankAccountId, onConfirmComplete } = props;

  const { i18n, tenantReducer } = useAppContext();

  const { customerStore } = useStore();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const updatedAccount = await confirmIban(customerBankAccountId, tenantReducer, i18n.lang);

        onConfirmComplete(updatedAccount, customerStore.bankAccounts.results);

        notify.success({
          content: i18n.getTranslation('customer.valid_confirmed_iban')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('customer.invalid_confirmed_iban'),
          error: e
        });
      }
    }
  }));

  return <Paragraph textAlign="center">{i18n.getTranslation('customer.confirm_iban_text')}</Paragraph>;
});

export default BankDetailsDialog;
