import { locationSelection } from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import { regularMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { WizardStep } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

const RegularMoveInWizardNavigation = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { regularMoveInWizardNavigator, locationSectionStore } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { activeStep, stepValidations, setActiveStep } = regularMoveInWizardNavigator;
  const { selectedLocation, locationSelectionType } = locationSectionStore;

  return (
    <>
      <WizardStep
        headerId={regularMoveInWizardSteps.movein}
        text={getTranslation('contracts.steps.move_in')}
        isActive={activeStep === regularMoveInWizardSteps.movein}
        stepValidation={stepValidations[regularMoveInWizardSteps.movein]}
        setIsActive={() => setActiveStep(regularMoveInWizardSteps.movein)}
      />
      <WizardStep
        headerId={regularMoveInWizardSteps.locations}
        text={getTranslation('contracts.steps.location')}
        isActive={activeStep === regularMoveInWizardSteps.locations}
        stepValidation={stepValidations[regularMoveInWizardSteps.locations]}
        setIsActive={() => setActiveStep(regularMoveInWizardSteps.locations)}
      />
      <WizardStep
        headerId={regularMoveInWizardSteps.customer}
        text={getTranslation('contracts.steps.customer')}
        isActive={activeStep === regularMoveInWizardSteps.customer}
        stepValidation={stepValidations[regularMoveInWizardSteps.customer]}
        setIsActive={() => setActiveStep(regularMoveInWizardSteps.customer)}
      />
      <WizardStep
        headerId={regularMoveInWizardSteps.contractdata}
        text={getTranslation('contracts.steps.contract_data')}
        isActive={activeStep === regularMoveInWizardSteps.contractdata}
        stepValidation={stepValidations[regularMoveInWizardSteps.contractdata]}
        setIsActive={() => setActiveStep(regularMoveInWizardSteps.contractdata)}
      />
      <WizardStep
        headerId={regularMoveInWizardSteps.billing}
        text={getTranslation('contracts.steps.billing', { stepNum: 5 })}
        isActive={activeStep === regularMoveInWizardSteps.billing}
        stepValidation={stepValidations[regularMoveInWizardSteps.billing]}
        setIsActive={() => setActiveStep(regularMoveInWizardSteps.billing)}
      />

      {
        // If location section is multiselect don't render this section
        locationSelectionType !== locationSelection.multiple && selectedLocation && (
          <WizardStep
            headerId={regularMoveInWizardSteps.optionalmeasurement}
            text={getTranslation('contracts.steps.opt_measurement')}
            isActive={activeStep === regularMoveInWizardSteps.optionalmeasurement}
            stepValidation={stepValidations[regularMoveInWizardSteps.optionalmeasurement]}
            setIsActive={() => setActiveStep(regularMoveInWizardSteps.optionalmeasurement)}
          />
        )
      }
    </>
  );
};

export default observer(RegularMoveInWizardNavigation);
