import clone from 'clone';
import moment from 'moment';
import React from 'react';

import { CostComponentValuesPagedResultType, UpdateCostComponentValueType } from '@zf/api-types/product';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { FloatInputFieldProps } from '@zf/stella-react/src/atoms/InputField/stella-float-input';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';
import { isMinDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import { DeleteIcon } from '../../../components/Icon';
import DateRangePicker from '../../../components/input/DateRangePicker';
import FloatInput from '../../../components/input/FloatInput';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import { ValidatorTypeV } from './cost-components';

type Props = {
  selectedCostId: string;
  valuesV: ValidatorTypeV;
  selectedCostComponentId: string;
  selectedCostComponentValue: number;
  setValuesV: (value: Partial<ValidatorTypeV>, initialApiSet?: boolean, backup?: boolean) => void;
  backupValuesV: () => void;
};

type CostComponentValueRowType = {
  value: JSX.Element;
  validPeriod: JSX.Element;
  deleteAction: JSX.Element;
};

const InlineInputFieldFloat = InlineInputField<FloatInputFieldProps>(FloatInput);

export default function CostComponentValuesBody(props: Props) {
  const { selectedCostComponentId, selectedCostComponentValue, setValuesV, valuesV, selectedCostId, backupValuesV } =
    props;

  const { i18n } = useAppContext();

  const response = useSuspenseSingleAPI<CostComponentValuesPagedResultType>({
    request: {
      endpoint: `/cfg/CostComponentValues/c/${selectedCostComponentId}`,
      query: { orderBy: '-EndDateTime' }
    }
  });

  React.useEffect(() => {
    if (response.result) {
      const cloned = clone(response.result.data.results);
      setValuesV({ costComponentValues: cloned });
    }
  }, [response.result]);

  React.useEffect(() => {
    // backupValuesV needs to be executed after setValuesV in the useEffect above
    if (
      response.result &&
      JSON.stringify(response.result.data.results) === JSON.stringify(valuesV.costComponentValues)
    ) {
      backupValuesV();
    }
  }, [valuesV.costComponentValues]);

  const setSelectedCostComponentValue = React.useCallback(
    (index: number) => {
      setValuesV({
        selectedCostComponentValue: index
      });
    },
    [setValuesV]
  );

  if (!response.result) return null;

  const setCostComponentValue = (index: number, value: Partial<UpdateCostComponentValueType>) => {
    const clonedArray = [...valuesV.costComponentValues];
    clonedArray[index] = { ...clonedArray[index], ...value };
    setValuesV({
      costComponentValues: clonedArray
    });
  };

  const deleteCostComponentValue = (index: number) => {
    const clonedArray = [...valuesV.costComponentValues];
    clonedArray.splice(index, 1);

    setValuesV({
      costComponentValues: clonedArray,
      selectedCostComponentValue: -1
    });
  };

  let costComponentValueRows: Array<CostComponentValueRowType | undefined> = [];

  if (valuesV.costComponentValues) {
    costComponentValueRows = valuesV.costComponentValues.reduce(
      (acc: Array<CostComponentValueRowType | undefined>, costComponentValue: UpdateCostComponentValueType, index) => {
        if (costComponentValue.costComponentId === selectedCostId || !costComponentValue.costComponentId) {
          acc.push({
            value: (
              <InlineInputFieldFloat
                id={`costcomponent_values.value.index-${index}`}
                value={costComponentValue.value}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!costComponentValue.value}
                onChange={(val) => setCostComponentValue(index, { value: val })}
                error={!costComponentValue.value}
              />
            ),
            validPeriod: (
              <DateRangePicker
                id={`costcomponent_values.period.index-${index}`}
                startDate={costComponentValue.startDateTime ? moment(costComponentValue.startDateTime) : null}
                endDate={costComponentValue.endDateTime ? moment(costComponentValue.endDateTime) : null}
                setDates={(dates) =>
                  setCostComponentValue(index, {
                    startDateTime: dates[0]?.toISOString(),
                    endDateTime: dates[1]?.toISOString()
                  })
                }
                error={!costComponentValue.startDateTime || isMinDate(costComponentValue.startDateTime)}
              />
            ),
            deleteAction: (
              <DeleteIcon
                id={`costcomponent_values.delete.index-${index}`}
                tooltipFor="cost-component-values-table"
                onClick={() => deleteCostComponentValue(index)}
              />
            )
          });
        }

        return acc;
      },
      []
    );
  }

  return (
    <DeprecatedStaticTable
      tooltipId="cost-component-values-table"
      rows={costComponentValueRows.filter((e) => e !== undefined) as Record<string, React.ReactNode>[]}
      onRowSelect={setSelectedCostComponentValue}
      selectedIndex={selectedCostComponentValue}
    >
      <DeprecatedStaticColumn flexWidth="1" dataKey="value" label={i18n.getTranslation('general.value')} />
      <DeprecatedStaticColumn
        flexWidth="1"
        dataKey="validPeriod"
        label={i18n.getTranslation('cost_component_value.valid_period')}
      />
      <DeprecatedStaticColumn dataKey="deleteAction" showOnHover />
    </DeprecatedStaticTable>
  );
}
