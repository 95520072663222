import React from 'react';

import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import useDialog from '@zf/hooks/src/useDialog';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatDateWMonth, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import DeleteBillingPeriodDialog from '../../../../../actions/property-group/delete-billing-period-dialog';
import { useAppContext } from '../../../../../app-context/app-context';
import DeleteIcon from '../../../../../components/Icon/DeleteIcon';
import IconParagraph from '../../../../../components/Icon/IconParagraph';
import { dialog } from '../../../../../events/dialog-events';
import useCreateRequest from '../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../hooks/useInfiniAPI';
import useLocalRefresh from '../../../../../hooks/useLocalRefresh';
import useBillingData from './use-billing-data';
import { BillingPeriodQueryParams } from './useBillingPeriodsFilter';

type BillingPeriodRowType = {
  __id: string;
  __billingPeriodEntity: PropertyGroupBillingPeriodType;
  period: JSX.Element;
  dash: JSX.Element;
  p_end: React.ReactNode;
};

export default function useBillingPeriods(propertyGroupId: string, queryParams: BillingPeriodQueryParams) {
  const { i18n, tenantReducer } = useAppContext();
  const { timeStamp, refresh } = useLocalRefresh();
  const { clickRef, onSubmit } = useDialog();
  const { billingPeriods, deleteBillingPeriod: deleteBillingPeriodInState } = useBillingData();

  React.useEffect(() => {
    refresh();
  }, [billingPeriods]);

  const { request, selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = useCreateRequest(
    `/bill/PropertyGroupBillingPeriods/${propertyGroupId}`,
    {
      startDate: queryParams.periodStart ? queryParams.periodStart.toISOString() : MIN_DATE,
      endDate: queryParams.periodEnd ? queryParams.periodEnd.toISOString() : MAX_DATE,
      timeStamp: timeStamp
    }
  );

  const openDeleteDialog = (period: PropertyGroupBillingPeriodType) => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.billing.delete_billing_period_header'),
      icon: 'trashcan',
      content: (
        <DeleteBillingPeriodDialog
          ref={clickRef}
          propertyGroupId={propertyGroupId}
          billingPeriod={period}
          deleteBillingPeriodInState={deleteBillingPeriodInState}
        />
      ),
      buttonPositive: i18n.getTranslation('general.delete'),
      onSubmit,
      type: 'danger'
    });
  };

  const processRecord = (period: PropertyGroupBillingPeriodType) => {
    const startDate = formatDateWMonth(period.startDate);
    return {
      __id: period.id,
      __billingPeriodEntity: period,
      period: (
        <IconParagraph tooltipFor="billing-periods-table-tip" title={startDate} iconType="date" usedInTable>
          {startDate}
        </IconParagraph>
      ),
      dash: <Paragraph>-</Paragraph>,
      p_end: formatDateWMonth(period.endDate),
      status: period.locked ? (
        <NewStatusBadge
          color={colors['blue-600']}
          status_={i18n.getTranslation('property_groups.tabs.billing.locked')}
        />
      ) : null,
      delete: (
        <DeleteIcon
          id={`billing_periods.delete.index-${period.id}`}
          tooltipFor="billing-periods-table-tip"
          onClick={() => openDeleteDialog(period)}
        />
      )
    };
  };

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<
    PropertyGroupBillingPeriodType,
    BillingPeriodRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  return {
    selectedIds,
    loading,
    error,
    rows,
    sortableFields,
    sortBy,
    sortDirection,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
}
