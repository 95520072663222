import { zfStringify } from '@zf/utils/src/json';
import clone from 'clone';
import { useEffect, useReducer, useState } from 'react';

import { createStateReducer } from './stateReducer';

export type Params<V> = {
  initialValues: V;
  validate?: (values: V) => any;
};

export type SimpleValidatorReturnType<V> = {
  values: V;
  backup: V;
  errors: boolean[];
  setValue: (value: Partial<V>) => void;
};

export default function useSimpleValidator<V>(params: Params<V>): SimpleValidatorReturnType<V> {
  const { initialValues, validate } = params;

  const valuesReducer = createStateReducer<V, Partial<V>>();
  const [values, updateValues] = useReducer(valuesReducer, initialValues);
  const [backup] = useState<V>(clone(initialValues));
  const [errors, setErrors] = useState<boolean[]>([]);

  const setValue = (value: Partial<V>) => {
    updateValues(value);
  };

  useEffect(() => {
    // This fixes the sticky values when using navigator in detail pages
    if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
      setValue(clone(initialValues));
    }
  }, [JSON.stringify(initialValues)]);

  useEffect(() => {
    if (validate) {
      setErrors(validate(values));
    }
  }, [JSON.stringify(values)]);

  return {
    values,
    backup,
    errors,
    setValue
  };
}
