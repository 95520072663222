import React, { useEffect } from 'react';

import { customerType } from '@zf/api-types/enums';
import { isEmptyObject } from '@zf/utils/src/object';

import { BreadCrumb, Crumb, CrumbDivider } from '../../../components/BreadCrumb';
import { CrumbHeader } from '../../../components/Header';
import { useStore } from '../../../hooks/useStore';
import useCustomerDetail from '../hooks/useCustomerDetail';
import CustomerTabContainer from './CustomerTabContainer';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

export default function Customer(props: Props) {
  const { navigate, urlParams } = props;
  const { customer, status, navigator, setCustomer } = useCustomerDetail(urlParams.customerID);
  const { customerStore, applicationStore } = useStore();

  const { rootUrl, getTranslation } = applicationStore;

  useEffect(() => {
    //mount component when id of entity changes
    customerStore.loadCustomerDetailData(urlParams.customerID);
  }, [customerStore, urlParams.customerID]);

  useEffect(() => {
    // Clear entity when you leave the detail page
    return () => customerStore.onLeaveDetailPage();
  }, []);

  if (!customer || isEmptyObject(customer)) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="list" text={getTranslation('customer.customers')} url={`${rootUrl}/customers`} />
            <CrumbDivider />
            <Crumb
              icon={customer.customerType === customerType.person ? 'customer' : 'organisation'}
              text={Object.keys(customer).length > 0 ? `${customer.accountNumber} - ${customer.shortDisplayName}` : ''}
            />
          </BreadCrumb>
        }
      />
      <CustomerTabContainer
        customer={customer}
        status={status}
        navigator={navigator}
        setCustomer={setCustomer}
        navigate={navigate}
        key={customer._etag}
      />
    </>
  );
}
