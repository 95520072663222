import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { EntitySubjectSubtype, EntitySubjectUnionType } from '@zf/api-types/config/scenarios_new';
import { culture, templateUsecase } from '@zf/api-types/enums';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../../app-context';
import useDownload from '../../../../app-context/hooks/use-download';
import Button from '../../../../components/Button/Button';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../../hooks/useStore';
import UpdateCustomHtml from '../scenario/components/UpdateCustomHtml';
import css from './preview-header.module.scss';

const InlineCultureDropdown = InlineInputField<SimpleDropdownProps<culture>>(SimpleDropdown);

type Props = {
  entitySubjectType: EntitySubjectUnionType;
  entitySubjectSubType: EntitySubjectSubtype;
  useCase: templateUsecase;
};

const PreviewHeader = (props: Props) => {
  const { tenantReducer } = useAppContext();
  const { entitySubjectType, entitySubjectSubType, useCase } = props;
  const { communicationStore, applicationStore } = useStore();
  const { translationStore, communicationService } = communicationStore;
  const { getEnum, getTranslation } = applicationStore;
  const { previewFilters, localCultureTable, setFilters } = translationStore;
  const { entitySubjectTypeMap, initEntitySubjectMap, initTemplatesTab, selectedScenario } =
    communicationStore.templatesStore;
  const { downloadSingleFile } = useDownload();

  const culturesDropdown = getEnum<culture>('culture').filter((c) => {
    return localCultureTable?.supportedCultures.includes(c.value);
  });

  useEffect(() => {
    initEntitySubjectMap();
    initTemplatesTab(entitySubjectType, entitySubjectSubType);
  }, []);

  const heading: number = entitySubjectTypeMap[entitySubjectType]?.findIndex((e) => e.value === entitySubjectSubType);

  let fileName = undefined;

  if (useCase === templateUsecase.pdf) {
    fileName = selectedScenario?.pdfTemplate?.customTemplateFileName
      ? selectedScenario?.pdfTemplate?.customTemplateFileName
      : selectedScenario?.pdfTemplate?.fileName;
  } else {
    fileName = selectedScenario?.emailTemplate?.customTemplateFileName
      ? selectedScenario?.emailTemplate?.customTemplateFileName
      : selectedScenario?.emailTemplate?.fileName;
  }

  return (
    <>
      <div className={css['pdf-preview-header-options']}>
        <Heading style="bold">{heading >= 0 && entitySubjectTypeMap[entitySubjectType][heading].text}</Heading>
        <UpdateCustomHtml templateUsecase={useCase} fileName={fileName} />
      </div>

      <div className={css['pdf-preview-text-labels']}>
        {/*<Label>{getTranslation('communication.show_text_labels')}</Label>
        <ZFToggle checked={previewFilters.showLabels || false} onChange={(val) => setFilters({ showLabels: val })} />
         */}

        <InlineCultureDropdown
          id={`dropdown-culture-pdf-email`}
          onChange={(val) => setFilters({ culture: val[0] })}
          values={culturesDropdown}
          selectedValues={[previewFilters?.culture || ('' as culture)]}
          darkMode
        />

        {previewFilters.templateUseCase === templateUsecase.pdf && (
          <Button
            id="pdf-download"
            type="secondary"
            icon="download"
            onClick={async () => {
              const { entitySubjectType, entitySubjectSubType, templateUseCase, culture } = previewFilters;

              if (entitySubjectType && culture) {
                const dummy = await communicationService.getDummyData(entitySubjectType, culture, entitySubjectSubType);

                await downloadSingleFile(
                  `/cfg/templates/${entitySubjectType}/${entitySubjectSubType}/${templateUseCase}/${culture}/preview`,
                  tenantReducer,
                  dummy
                );
              }
            }}
          >
            {getTranslation('communication.pdf')}
          </Button>
        )}

        <Button
          id="email-download"
          type="secondary"
          icon="download"
          onClick={async () => {
            const { entitySubjectType, entitySubjectSubType, templateUseCase, culture } = previewFilters;

            if (entitySubjectType && culture) {
              const dummy = await communicationService.getDummyData(entitySubjectType, culture, entitySubjectSubType);

              await downloadSingleFile(
                `/cfg/templates/${entitySubjectType}/${entitySubjectSubType}/${templateUseCase}/download`,
                tenantReducer,
                dummy
              );
            }
          }}
        >
          {getTranslation('communication.html')}
        </Button>
      </div>
    </>
  );
};

export default observer(PreviewHeader);
