import { TenantAuthResponse, UserDetails } from '@zf/api-types/auth';

import { METHODS } from '../../utils/request';
import ApplicationStore from '../stores/domain/ApplicationStore';
import BaseService from './BaseService';

export default class UserAuthService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/UserAuth/', applicationStore);
  }

  getUserForId = async (userId: string | null) => {
    if (!userId) return null;

    return (
      await this.applicationStore.sendRequest<UserDetails | null>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}users/${userId}`
        }
      })
    ).data;
  };

  getUserRoles = async (tenantId: string) => {
    return (
      await this.applicationStore.sendRequest<TenantAuthResponse>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}t/${tenantId}`
        }
      })
    ).data;
  };

  getJWTToken = async () => {
    return (
      await this.applicationStore.sendRequest<string>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}zdsso`
        }
      })
    ).data;
  };
}
