import Interweave from 'interweave';
import React from 'react';

import { advanceFrequency, invoiceFrequency } from '@zf/api-types/enums';
import { DomainNotificationType } from '@zf/api-types/general';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { NotificationError } from '../../design-system/Components';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { formatCustomerName } from '../../utils/customer';
import { METHODS, sendRequest } from '../../utils/request';
import { process } from './tasks';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';

type Props = {
  task: MoveRequestType;
  setTask: (updatedTask: MoveRequestType) => void;
  existingPropertyGroupBillingConfig: PropertyGroupBillingConfigurationType | undefined;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { task, existingPropertyGroupBillingConfig, setTask } = props;

  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const taskType = enumReducer.getTranslation('moveRequestType', task.moveRequestType);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const contractDetails = task.contractDetails;

        const apiFriendlyValues = {
          mutationDateTime: task.mutationDateTime,
          estimations: contractDetails.estimations,
          externalContractReference: contractDetails.externalContractId,
          productId: contractDetails.productId,
          invoiceFrequency: task.contractDetails.invoiceFrequency
            ? task.contractDetails.invoiceFrequency
            : existingPropertyGroupBillingConfig && existingPropertyGroupBillingConfig.invoiceFrequency
            ? existingPropertyGroupBillingConfig.invoiceFrequency
            : invoiceFrequency.yearly,
          advanceFrequency: task.contractDetails.advanceFrequency
            ? task.contractDetails.advanceFrequency
            : existingPropertyGroupBillingConfig && existingPropertyGroupBillingConfig.advanceFrequency
            ? existingPropertyGroupBillingConfig.advanceFrequency
            : advanceFrequency.monthly,
          advanceAmount: contractDetails.advanceAmount,
          usePropertyGroupProduct: contractDetails.usePropertyGroupProduct,
          paymentTermsId: contractDetails.paymentTermsId,
          contractNumber: contractDetails.contractNumber
        };

        await sendRequest<MoveRequestType>({
          request: {
            method: METHODS.POST,
            endpoint: `/md/MoveRequests/${task.id}/updatedetails`,
            data: apiFriendlyValues
          },
          tenantReducer: tenantReducer,
          lang: i18n.lang
        });
        const updatedTask = await process(task.id, tenantReducer, i18n.lang);
        const errors: DomainNotificationType[] = [];
        if (updatedTask.customerDetails.errors.length > 0) {
          errors.push(...updatedTask.customerDetails.errors);
        }
        if (updatedTask.serviceLocationDetails.errors.length > 0) {
          errors.push(...updatedTask.serviceLocationDetails.errors);
        }
        if (updatedTask.contractDetails.errors.length > 0) {
          errors.push(...updatedTask.contractDetails.errors);
        }

        updatedTask.measurementDetails.forEach((detail) => {
          errors.push(...detail.errors);
        });

        setTask(updatedTask);
        if (errors.length > 0) {
          notify.error({
            content: (
              <>
                <Paragraph>
                  {i18n.getTranslation('tasks.process_fail', {
                    taskType: taskType
                  })}
                </Paragraph>
                <NotificationError errors={errors} />
              </>
            )
          });
        } else {
          notify.success({
            content: i18n.getTranslation('tasks.process_success', {
              taskType: taskType
            })
          });
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tasks.process_fail', {
            taskType: taskType
          }),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={i18n.getTranslation('tasks.process_paragraph', {
          taskType: taskType.toLowerCase(),
          customerName: task.customerDetails.lastName
            ? formatCustomerName(task.customerDetails.lastName, task.customerDetails.firstName)
            : i18n.getTranslation('tasks.existing_customer')
        })}
      />
    </Paragraph>
  );
});
