import React from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { UnbilledProvider } from '../../../cards/Unbilled/context/unbilled-context';
import UnbilledListCard from '../../../cards/Unbilled/unbilled-list-card';

export default function BillingOverviewTab() {
  return (
    <CardsContainer>
      <UnbilledProvider>
        <UnbilledListCard useCase="dashboard" />
      </UnbilledProvider>
    </CardsContainer>
  );
}
