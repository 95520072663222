import classNames from 'classnames';
import React from 'react';

import { SimpleTooltip } from '@zf/heat-erp/src/design-system/Components';

type IconPropsType = {
  id?: string;
  name: string;
  style?: React.CSSProperties;
  className?: string;
  title?: string;
};

export default React.memo(function Icon(props: IconPropsType) {
  const { id, name, className, title, style, ...otherProps } = props;
  const iconClassName = `icon-${name ? name.toLowerCase() : ''}`;

  const icon = <span {...otherProps} style={style} className={classNames('icon', iconClassName, className)} />;

  if (title && id) {
    return (
      <SimpleTooltip id={id} tooltip={title}>
        {icon}
      </SimpleTooltip>
    );
  }

  return icon;
});
