import InfoGridFooter from 'design-system/ComponentSets/InfoGridFooter/InfoGridFooter';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { MeterType } from '@zf/api-types/master-data/meter';

import ListPageNew from '../../../../../components/listPageV2';
import { MeterIssuesContext, useMeterIssuesContext } from '../Context/Context';
import DetailsIssueList from './DetailsIssueList';
import IssuesFilterBar from './IssuesFilterBar';
import IssueSidePanel from './side-panels/IssueSidePanel';

type Props = {
  meter: MeterType;
  refresh: () => void;
};

const MeterIssuesTab = (props: Props) => {
  const { meter, refresh } = props;

  const {
    store: { listPage, filter },
    setFilter
  } = useMeterIssuesContext();

  return (
    <ListPageNew
      entitySubjectType={entitySubjectType.meteringissue}
      topBar={<IssuesFilterBar meter={meter} />}
      list={
        <DetailsIssueList
          meter={meter}
          filter={filter}
          setFilter={(quickFilter: string) => setFilter({ ...filter, quickFilter })}
        />
      }
      infoGrid={<InfoGridFooter showSidePanel={listPage.showSidePanel} aggregateDetails={listPage.aggregateDetails} />}
      sidePanel={<IssueSidePanel meter={meter} refresh={refresh} />}
      embeddedInDetailPage
      context={MeterIssuesContext}
    />
  );
};

export default MeterIssuesTab;
