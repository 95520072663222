import moment from 'moment';

import { InvoiceType } from '@zf/api-types/invoice';
import { colors } from '@zf/utils/src/color';

import { LangReturnValue } from '../app-context/hooks/use-lang';

export const isOverdue = (invoice: InvoiceType) => {
  return moment().isAfter(moment(invoice.dueDate)) && invoice.remainingInvoiceAmount > 0;
};

export const calculateNewAmounts = (quantity: number, unitPrice: number, taxRate?: number) => {
  let exclVAT = 0;
  let inclVAT: number | undefined = 0;

  // Invoice without quantity
  if (quantity === 1 && unitPrice !== 0) {
    exclVAT = unitPrice;
    if (typeof taxRate === 'undefined') {
      inclVAT = undefined;
    } else if (taxRate !== 0) {
      inclVAT = exclVAT + exclVAT * taxRate;
    } else {
      inclVAT = exclVAT;
    }
  }
  // Invoice with quantity
  else if (typeof taxRate === 'undefined') {
    inclVAT = undefined;
  } else {
    exclVAT = quantity * unitPrice;
    inclVAT = exclVAT + exclVAT * taxRate;
  }

  return { exclVAT, inclVAT };
};

export const getInvoiceOverdueColor = (amountOfDays: number) => {
  let color = '';

  if (amountOfDays < 0) {
    color = colors['silver-400'];
  } else if (amountOfDays < 10) {
    color = colors['green-600'];
  } else if (amountOfDays < 30) {
    color = colors['orange-600'];
  } else {
    color = colors['red-600'];
  }

  return color;
};

export const getInvoiceOverdueDescription = (i18n: LangReturnValue, amountOfDays: number) => {
  return amountOfDays > 0
    ? i18n.getTranslation('invoice.overdue_descr_neg', { amountOfDays })
    : i18n.getTranslation('invoice.overdue_descr_pos', {
        amountOfDays: -1 * amountOfDays
      });
};

export const getBillingInsightsOverdueDescription = (i18n: LangReturnValue, amountOfDays: number) => {
  return amountOfDays > 0
    ? i18n.getTranslation('analytics.overdue_description', { amountOfDays })
    : i18n.getTranslation('analytics.create-soon_description', {
        amountOfDays
      });
};
