import { EstimatedInvoice } from '@zf/api-types/billing/estimated-invoices';

import { METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';

export default class EstimatedInvoiceService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/bill/EstimatedInvoices/', applicationStore);
  }

  getEstimatedInvoiceForId = async (id: string) => {
    return (
      await this.applicationStore.sendRequest<EstimatedInvoice>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${id}`
        }
      })
    ).data;
  };

  getEstimatedInvoiceForBillingCompletenessId = async (id: string) => {
    return (
      await this.applicationStore.sendRequest<EstimatedInvoice>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}bc/${id}`
        }
      })
    ).data;
  };
}
