import React, { useEffect } from 'react';

import { AgingReportTransactionType, AgingReportType } from '@zf/api-types/aging-report';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../app-context/app-context';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { colors } from '@zf/utils/src/color';
import { useStore } from '../../../hooks/useStore';
import BucketContent from './BucketContent';
import { formatDate } from '@zf/utils/src/date';
import { observer } from 'mobx-react';
import { Paragraph } from '../../../design-system/Foundation';
import { transactionType } from '@zf/api-types/enums';
import css from './aging-report-card.module.scss';
import Link from '../../../design-system/Components/Link/Link';

const NoTransactions = () => {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/transaction.png"
          alt="aging report"
          title="aging report"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('dashboard.customer_aging.no_overdue_transactions')}
    />
  );
};

type Props = {
  report: AgingReportType | undefined;
};

export type CustomerAgingTransactionsRowType = {
  __id: string;
  invoiceDate: string;
  dueDate: string;
  collectionFlow: JSX.Element;
  invoiceNum: JSX.Element;
  openCredits: JSX.Element;
  current: JSX.Element;
  '0-30': JSX.Element;
  '31-60': JSX.Element;
  '61-90': JSX.Element;
  moreThan90: JSX.Element;
};

const OverdueTransactionsCard = (props: Props) => {
  const { report } = props;
  const { agingReportStore, applicationStore } = useStore();
  const { getTranslation, rootUrl, getEnumTranslation } = applicationStore;

  const renderTransactionUrl = (transaction: AgingReportTransactionType): string => {
    switch (transaction.transactionType) {
      case transactionType.invoice: {
        return `${rootUrl}/invoices/${transaction.transactionReferenceId}`;
      }
      default: {
        return `${rootUrl}/customers/${report?.debtor.customerId}/transactions`;
      }
    }
  };

  const processRecord = (transaction: AgingReportTransactionType): CustomerAgingTransactionsRowType => {
    return {
      __id: transaction.transactionId,
      invoiceDate: formatDate(transaction.transactionDate),
      dueDate: formatDate(transaction.transactionDueDate),
      invoiceNum: (
        <div>
          <Link id={`reference-number-${transaction.referenceNumber}`} bold url={renderTransactionUrl(transaction)}>
            {transaction.referenceNumber}
          </Link>
          <Paragraph color={colors['silver-600']}>
            {getEnumTranslation('transactionType', transaction.transactionType)}
          </Paragraph>
        </div>
      ),
      collectionFlow: (
        <Link
          id={`collection-flow-${transaction.referenceNumber}`}
          alignEmptyDash="left"
          className={css['collection-flow']}
          bold
          url={`${rootUrl}/collection-cases/${transaction.collectionCaseDetails?.collectionCaseId}`}
        >
          {transaction.collectionCaseDetails?.workflowName}
        </Link>
      ),
      openCredits: <BucketContent amount={transaction.openCredit} />,
      current: <BucketContent color={colors['blue-600']} amount={transaction.totalOpenNotOverdue} />,
      '0-30': <BucketContent color={colors['yellow-600']} amount={transaction.totalOverdue30D} />,
      '31-60': <BucketContent color={colors['orange-600']} amount={transaction.totalOverdue60D} />,
      '61-90': <BucketContent color={colors['red-600']} amount={transaction.totalOverdue90D} />,
      moreThan90: <BucketContent color={colors['red-700']} amount={transaction.totalOverdueOver90D} />
    };
  };

  useEffect(() => {
    agingReportStore.setInfiniAPIService(processRecord, report?.debtor.customerId);
  }, [report]);

  return (
    <Card id="aging-report-card" width="4">
      <CardHeader primaryText={getTranslation('cards.shared.show_more')}>
        {report
          ? getTranslation('dashboard.customer_aging.overdue_transactions_for', {
              debtor: report.debtor.displayName
            })
          : getTranslation('dashboard.customer_aging.overdue_transactions')}
      </CardHeader>
      <CardBody fixedHeight>
        <DynamicVirtualTable
          id="aging-reports-table-details"
          tooltipId="aging-reports-table-details-tip"
          rowHeight={60}
          rows={agingReportStore.infiniApiService?.rows || []}
          loading={agingReportStore.transactionsLoading}
          NoDataOverlay={NoTransactions}
          noSelect
          columns={[
            {
              width: 180,
              label: getTranslation('dashboard.customer_aging.overdue_transaction'),
              dataKey: 'invoiceNum'
            },
            {
              width: 180,
              label: getTranslation('transactions.transaction_date'),
              dataKey: 'invoiceDate'
            },
            {
              width: 180,
              label: getTranslation('transactions.transaction_due'),
              dataKey: 'dueDate'
            },
            {
              width: 180,
              label: getTranslation('collection_flows.collection_flow'),
              dataKey: 'collectionFlow'
            },
            {
              width: 180,
              label: getTranslation('dashboard.customer_aging.open_credit'),
              dataKey: 'openCredits'
            },
            {
              width: 130,
              label: getTranslation('dashboard.customer_aging.current'),
              dataKey: 'current',
              color: colors['blue-600']
            },
            {
              width: 130,
              label: '< 30d',
              dataKey: '0-30',
              color: colors['yellow-600']
            },
            {
              width: 130,
              label: '31-60d',
              dataKey: '31-60',
              color: colors['orange-600']
            },
            {
              width: 130,
              label: '61-90d',
              dataKey: '61-90',
              color: colors['red-600']
            },
            {
              width: 130,
              label: '> 90d',
              dataKey: 'moreThan90',
              color: colors['red-700']
            }
          ]}
        />
      </CardBody>
    </Card>
  );
};

export default observer(OverdueTransactionsCard);
