import Interweave from 'interweave';
import React from 'react';

import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatPeriod } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteBillingPeriod } from './billing-periods';

type Props = {
  propertyGroupId: string;
  billingPeriod: PropertyGroupBillingPeriodType;
  deleteBillingPeriodInState: (id: string) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { propertyGroupId, billingPeriod, deleteBillingPeriodInState } = props;

  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteBillingPeriod(propertyGroupId, billingPeriod.id, tenantReducer, i18n.lang);

        deleteBillingPeriodInState(billingPeriod.id);

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_billing_period_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_billing_period_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={i18n.getTranslation('property_groups.tabs.billing.delete_billing_period_paragraph', {
          period: formatPeriod(billingPeriod.startDate, billingPeriod.endDate)
        })}
      />
    </Paragraph>
  );
});
