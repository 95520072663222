import { observer } from 'mobx-react';
import React from 'react';

import { ProductType } from '@zf/api-types/product';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import LabelItemGrid from '@zf/stella-react/src/atoms/Card/LabelItemGrid';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import useTranslations from '../../../../../app-context/hooks/use-translations';
import { Icon } from '../../../../../components/Icon';
import LanguageIcon from '../../../../../components/Icon/LanguageIcon';
import MoreActionsMenu from '../../../../../components/Menus/MoreActionsMenu';
import { useStore } from '../../../../../hooks/useStore';
import DeleteListItem from '../product-actions/DeleteListItem';
import EditListItem from '../product-actions/EditListItem';
import { ProductProps } from './Product';
import css from './product.module.scss';

const ProductBody = (props: ProductProps) => {
  const { index: _index, product } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { cultureTable, updateProductInList, getValidPeriodsCount, getActiveBillingItemsCount } =
    productConfigStore.productsStore;

  const { openTranslationsDialog } = useTranslations<ProductType>('Products');

  const onCompleteLangUpdate = (index: number, updated: ProductType) => updateProductInList(updated);

  return (
    <>
      <div className={css['heading']}>
        <Heading headingType="h3" style="bold">
          {product.name}
        </Heading>

        <LanguageIcon
          id={`${product.name}-translations`}
          localisationLevel_={product.localisationLevel}
          hasMultipleLanguages={cultureTable.supportedCultures.length > 1}
          action={() => openTranslationsDialog(_index, product, onCompleteLangUpdate)}
        />

        <MoreActionsMenu
          id={`more-actions-${product.id}`}
          className={css['actions']}
          actions={[
            <EditListItem key={`edit-${product.id}`} product={product} />,
            <DeleteListItem index={_index} key={`delete-${product.id}`} productId={product.id} />
          ]}
        />
      </div>

      <LabelItemGrid className={css['info-grid']}>
        <CardItem width="6">
          <Label>{getTranslation('billing_items.active_billing_items')}</Label>
        </CardItem>
        <CardItem width="6">
          <div className={css['counts']}>
            <Paragraph>{getActiveBillingItemsCount(_index)}</Paragraph>
            <Paragraph color={colors['silver-600']}>{`(${product.billingItems.length})`}</Paragraph>
          </div>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('product_config.invoiceupfront')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>
            <Icon className={css['icon']} type={product.invoiceUpfront ? 'check' : 'cancel'} color />
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('product_config.active_attachments')}</Label>
        </CardItem>
        <CardItem width="6">
          <div className={css['counts']}>
            <Paragraph>{getValidPeriodsCount(_index)}</Paragraph>
            <Paragraph color={colors['silver-600']}>{`(${product.attachments.length})`}</Paragraph>
          </div>
        </CardItem>
      </LabelItemGrid>
    </>
  );
};

export default observer(ProductBody);
