import { customerType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import NewOrExistingCustomer from 'features/contract/src/wizard/move-in/regular-move-in-wizard/customer-section/NewOrExistingCustomer';
import moment from 'moment';
import React from 'react';

import { moveRequestType } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { useAppContext } from '../../../../../../app-context';
import ErrorView from '../../../../../../components/Error/error-view';
import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import CustomerBankingDetails, {
  BankingDetailsBaseType
} from '../../../../../customer/wizard/banking-details-section/CustomerBankingDetails';
import { getName } from '../../../../../customer/wizard/CustomerWizard';
import CustomerEssentials from '../../../../../customer/wizard/essentials-section/CustomerEssentials';
import useActivities from '../../hooks/useActivities';
import Contactdetails from './Contactdetails';

type Props = {
  existingCustomer?: CustomerType;
};

export default function CustomerWiz(props: Props) {
  const { existingCustomer } = props;
  const { task, initialTask, customer, customerType_, setCustomer, setCustomerDetail, setCustomerType } =
    useActivities();
  const { tenantReducer } = useAppContext();

  const responseOrg = useSuspenseSingleAPI<OrganizationConfigType>({
    request: {
      endpoint: '/cfg/Organizations/',
      selector: tenantReducer.state.organization?.organizationId
    }
  });

  if (!task || !responseOrg.result) return null;

  const orgConfig = responseOrg.result.data;

  const customerDetails = task.customerDetails;

  const customerEssentials =
    existingCustomer && customerType_ === customerType.existingcustomer
      ? {
          accountNumber: existingCustomer.accountNumber,
          salutation: existingCustomer.salutation,
          initials: existingCustomer.initials,
          firstName: existingCustomer.firstName,
          lastName: existingCustomer.lastName,
          birthDate: moment(existingCustomer.birthDate),
          invoiceAddress: existingCustomer.invoiceAddress,
          customerType: existingCustomer.customerType,
          companyName: existingCustomer.companyName,
          organizationNumber: existingCustomer.organizationNumber,
          vatNumber: existingCustomer.vatNumber || null,
          ssinCountry: existingCustomer.ssinCountry,
          ssin: existingCustomer.ssin,
          customerGroupId: existingCustomer.customerGroup?.id || null
        }
      : {
          accountNumber: customerDetails.accountNumber,
          salutation: customerDetails.salutation,
          initials: customerDetails.initials,
          firstName: customerDetails.firstName,
          lastName: customerDetails.lastName,
          birthDate: moment(customerDetails.birthDate),
          invoiceAddress: customerDetails.invoiceAddress,
          customerType: customerDetails.customerType,
          companyName: customerDetails.companyName,
          organizationNumber: customerDetails.organizationNumber,
          vatNumber: customerDetails.vatNumber,
          ssinCountry: customerDetails.ssinCountry,
          ssin: customerDetails.ssin,
          customerGroupId: customerDetails.customerGroupId
        };

  const bankingDetails = existingCustomer
    ? {
        usedForPaymentMethod: existingCustomer.defaultPaymentMethod,
        iban: existingCustomer.bankAccountIbans[0]?.iban || '',
        paymentTermsId: null
      }
    : {
        usedForPaymentMethod: customerDetails.defaultPaymentMethod,
        iban: customerDetails.bankAccount || '',
        paymentTermsId: null
      };

  const customerName = getName(customerDetails);

  const setBankingDetail = (
    value: BankingDetailsBaseType[keyof BankingDetailsBaseType],
    key: keyof BankingDetailsBaseType
  ) => {
    let renamedValue = {} as BankingDetailsBaseType;

    if (key === 'iban') {
      // @ts-ignore
      key = 'bankAccount';
    } else {
      // @ts-ignore
      key = 'defaultPaymentMethod';
    }

    // @ts-ignore
    renamedValue[key] = value;

    setCustomerDetail(renamedValue);
  };

  const newCustomer = (
    <>
      <CustomerEssentials
        values={customerEssentials}
        orgConfig={orgConfig}
        customerName={customerName}
        setCustomerEssential={setCustomerDetail}
      />

      <CustomerBankingDetails
        values={bankingDetails}
        customerName={customerName}
        setBankingDetail={setBankingDetail}
        showPaymentTerms={false}
      />

      <Contactdetails customerDetails={customerDetails} />
    </>
  );

  const showTabslider = initialTask?.moveRequestType !== moveRequestType.moveout;

  return (
    <div>
      {customerDetails.errors.length > 0 ? <ErrorView errors={customerDetails.errors} /> : null}
      {customerDetails.internalId && existingCustomer ? (
        <NewOrExistingCustomer
          newCustomer={newCustomer}
          customer={existingCustomer}
          setCustomer={setCustomer}
          customerType_={customerType_}
          setCustomerType={setCustomerType}
          showTabslider={showTabslider}
        />
      ) : (
        <NewOrExistingCustomer
          newCustomer={newCustomer}
          customer={customer}
          setCustomer={setCustomer}
          customerType_={customerType_}
          setCustomerType={setCustomerType}
          showTabslider={showTabslider}
        />
      )}
    </div>
  );
}
