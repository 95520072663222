import React from 'react';

import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import useBillingData from '../../hooks/use-billing-data';
import AllocationGroups from './allocation-groups';
import BillingPeriods from './billing-periods';
import IncomingCosts from './incoming-costs';
import css from './billing-periods-tab.module.scss';

export default function BillingPeriodsTab() {
  const { propertyGroupBillingConfiguration, selectedBillingPeriod } = useBillingData();

  return (
    <ColumnsContainer className={css['columns-wrapper']} extraPadding={false}>
      {propertyGroupBillingConfiguration && propertyGroupBillingConfiguration.propertyGroup && (
        <BillingPeriods propertyGroupId={propertyGroupBillingConfiguration.propertyGroup.id} />
      )}
      <IncomingCosts />
      {selectedBillingPeriod && <AllocationGroups selectedBillingPeriod={selectedBillingPeriod} />}
    </ColumnsContainer>
  );
}
