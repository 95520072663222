import React from 'react';

import { UpdateConsumptionUnitTypeType } from '@zf/api-types/master-data/consumption-unit';

import useConfig from '../../../app-context/hooks/use-config';
import ConsumptionUnitTypes from './consumption-unit-types';

export default function ConsumptionUnitTypeTab() {
  const config = useConfig<UpdateConsumptionUnitTypeType[]>('consumptionUnitTypes', '/cfg/ConsumptionUnitTypes');

  return <ConsumptionUnitTypes config={config} />;
}
