import { IncomingMutationType, ManualMatchIncomingMutationRequest } from '@zf/api-types/billing/incoming-mutations';
import { uiCulture } from '@zf/api-types/enums';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';

export const deleteIncomingMutation = async (
  incomingMutationIds: string[],
  incomingBankingTransactionId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  await sendRequest<boolean>({
    request: {
      method: METHODS.POST,
      endpoint: `/bill/IncomingBankingTransactions/${incomingBankingTransactionId}/mutations/ignore`,
      data: {
        incomingMutationIds
      }
    },
    tenantReducer,
    lang
  });
};

export const resetMatch = async (incomingMutationId: string, tenantReducer: TenantReturnValue, lang: uiCulture) => {
  await sendRequest<IncomingMutationType>({
    request: {
      method: METHODS.POST,
      endpoint: `/bill/IncomingMutations/${incomingMutationId}/reset`
    },
    tenantReducer,
    lang
  });
};

export const processIncomingMutation = async (
  incomingMutationId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  await sendRequest<IncomingMutationType>({
    request: {
      method: METHODS.POST,
      endpoint: `/bill/IncomingMutations/${incomingMutationId}/process`
    },
    tenantReducer,
    lang
  });
};

export const manualMatchIncomingMutation = async (
  incomingMutationId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture,
  body: ManualMatchIncomingMutationRequest
) => {
  await sendRequest<IncomingMutationType>({
    request: {
      method: METHODS.POST,
      endpoint: `/bill/IncomingMutations/${incomingMutationId}/manualmatch`,
      data: body
    },
    tenantReducer,
    lang
  });
};
