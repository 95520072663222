import { observer } from 'mobx-react';
import React from 'react';

import { MeterPropertiesPrepayment } from '@zf/api-types/master-data/meter';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { colors } from '@zf/utils/src/color';
import { formatMoney } from '@zf/utils/src/number';

import { Paragraph } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  properties: MeterPropertiesPrepayment;
};

const BalanceCardHeading = (props: Props) => {
  const { properties } = props;

  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const remainingCredit = properties.configuredEmergencyCredit - properties.debt;
  const outOfCredit = remainingCredit < 0;

  return (
    <>
      <div>
        <Heading headingType="h3" style="bold">
          {getTranslation('prepayment_device.current_balance')}
        </Heading>
        <Heading headingType="h3" style="bold">
          {formatMoney(properties.currentBalance, culture)}
        </Heading>
      </div>

      {outOfCredit ? (
        <div>
          <Paragraph>{getTranslation('prepayment_device.emergency_credit')}</Paragraph>
          <Paragraph textAlign="right">
            {`${formatMoney(remainingCredit, culture)}/${formatMoney(properties.configuredEmergencyCredit, culture)}`}
          </Paragraph>
        </div>
      ) : (
        <div>
          <Paragraph color={colors['red-600']}>{getTranslation('prepayment_device.debt')}</Paragraph>
          <Paragraph color={colors['red-600']} textAlign="right">
            {formatMoney(properties.debt, culture)}
          </Paragraph>
        </div>
      )}
    </>
  );
};

export default observer(BalanceCardHeading);
