import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import { useStore } from '../../../../../../hooks/useStore';
import BillingInsightsContent from './BillingInsightsContent';

const BillingInsights = observer(() => {
  const { contractStore } = useStore();

  const billingRelation = contractStore.billingRelation_;
  const contract = contractStore.selectedContract_;

  useEffect(() => {
    return () => contractStore.billingCompletenessStore.reset();
  }, []);

  return (
    <AsyncVarSpinner variables={[contract, billingRelation]}>
      <BillingInsightsContent
        contract={contractStore.selectedContract.contract}
        billingRelation={contractStore.billingRelation}
      />
    </AsyncVarSpinner>
  );
});

export default BillingInsights;
