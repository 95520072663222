import { Moment } from 'moment';
import React, { useState } from 'react';

import { navigate } from '@reach/router';
import {
  communicationType,
  contactType,
  countryCode,
  customerType,
  invoiceType,
  paymentMethod
} from '@zf/api-types/enums';
import { ContactEntryType, LocalAddressType } from '@zf/api-types/general';
import { CultureTableType } from '@zf/api-types/language';
import {
  CommunicationPreferencesType,
  CreateCustomerRequestType,
  CustomerType
} from '@zf/api-types/master-data/customer';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import useValidator from '@zf/hooks/src/useValidator';
import { MIN_DATE } from '@zf/utils/src/date';
import { isValidIBAN } from '@zf/utils/src/iban';

import { useAppContext } from '../../../app-context';
import useWizardAPIErrors from '../../../app-context/hooks/useWizardAPIErrors';
import WizardSubmitButton from '../../../components/Button/WizardSubmitButton';
import { notify } from '../../../events/notification-events';
import { extendMultiValue } from '../../../utils/arrays';
import { initialInvoiceCommunicationPrefs } from '../../../utils/communicationPrefs';
import { validateEmail } from '../../../utils/email';
import { METHODS, sendRequest } from '../../../utils/request';
import { ContactDetailsBaseType } from '../../tasks/detail-page/detail/wizards/customer/Contactdetails';
import BankingDetailsSection from './banking-details-section/BankingDetailsSection';
import { BankingDetailsBaseType } from './banking-details-section/CustomerBankingDetails';
import CommunicationSection from './communication-section/CommunicationSection';
import { CustomerEssentialsBaseType } from './essentials-section/CustomerEssentials';
import EssentialsSection from './essentials-section/EssentialsSection';

export type Props = {
  feedback: string[][];
  orgConfig: OrganizationConfigType;
  cultureTable: CultureTableType;
  setNewCustomer?: (customer: CustomerType) => void;
  onFocusStep: React.Dispatch<React.SetStateAction<string>>;
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>;
};

export type CustomerDetailsBaseUnionType = CustomerEssentialsBaseType & BankingDetailsBaseType & ContactDetailsBaseType;

export type CreateCustomerType = {
  accountNumber: string;
  firstName: string;
  lastName: string;
  salutation: string;
  initials: string;
  birthDate: Moment | null;
  customerType: customerType;
  customerGroupId: string;
  companyName: string;
  organizationNumber: string;
  vatNumber: string;
  invoiceAddress: LocalAddressType;
  iban: string;
  usedForPaymentMethod: paymentMethod;
  paymentTermsId: string;
  ssin: string | null;
  ssinCountry: countryCode | null;
  primaryContactDetails: ContactEntryType[];
  secondaryContactDetails: ContactEntryType[];
  deviatingCommunicationType: boolean;
  commPreference: communicationType | 'custom';
  communicationPreferences: CommunicationPreferencesType;
};

type NameBaseType = {
  customerType: customerType | null;
  firstName: string;
  lastName: string;
  companyName: string | null;
};

export const STEP_NAMES = ['customer-essentials', 'customer-banking', 'customer-contact', 'customer-errors'];

export function getName<T extends NameBaseType>(values: T) {
  let name = '';

  if (values.customerType === 'person') {
    name = '_______ _______';

    if (values.firstName && values.lastName) {
      name = `${values.firstName} ${values.lastName}`;
    } else if (values.lastName) {
      name = `_______ ${values.lastName}`;
    } else if (values.firstName) {
      name = `${values.firstName} _______`;
    }
  } else {
    name = '_________';

    if (values.companyName) name = values.companyName;
  }

  return { name };
}

export default function CustomerWizard(props: Props) {
  const { onFocusStep, setNewCustomer, setFeedback, feedback, orgConfig, cultureTable } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const [contactTypes] = useState(enumReducer.getEnum<contactType>('contactType'));

  const startingIndex = setNewCustomer ? 11 : 0;

  const { setApiErrors, handleApiErrors } = useWizardAPIErrors(startingIndex + 3, setFeedback);

  const { values, errors, setValue, submitFactory } = useValidator<CreateCustomerType>({
    initialValues: {
      accountNumber: '',
      firstName: '',
      lastName: '',
      salutation: '',
      initials: '',
      birthDate: null,
      customerType: customerType.person,
      customerGroupId: '',
      companyName: '',
      organizationNumber: '',
      vatNumber: '',
      invoiceAddress: {
        streetName: '',
        streetNumber: '',
        city: '',
        postalCode: '',
        country: '',
        streetNumberAddition: ''
      },
      ssinCountry: null,
      ssin: '',
      primaryContactDetails: contactTypes.map((ct) => {
        return { contactType: ct.value, value: '', description: '', primaryForType: true };
      }),
      secondaryContactDetails: [],
      iban: '',
      usedForPaymentMethod: paymentMethod.sdd,
      paymentTermsId: '',

      deviatingCommunicationType: false,
      commPreference: communicationType.none,
      communicationPreferences: {
        culture: cultureTable.defaultCulture,
        invoiceCommunicationPreferences: initialInvoiceCommunicationPrefs
      }
    },
    validate: () => {
      const feedback: string[][] = [];

      if (orgConfig.manuallySetCustomerNumber && !values.accountNumber) {
        extendMultiValue(feedback, startingIndex + 0, i18n.getTranslation('contracts.validation.contract_id'));
      }

      if (values.customerType === 'person' && !values.lastName) {
        extendMultiValue(feedback, startingIndex + 0, i18n.getTranslation('customer.validation.name'));
      }

      if (values.customerType === 'organization' && !values.companyName) {
        extendMultiValue(feedback, startingIndex + 0, i18n.getTranslation('customer.validation.organization'));
      }

      if (
        !values.invoiceAddress.streetName ||
        !values.invoiceAddress.streetNumber ||
        !values.invoiceAddress.city ||
        !values.invoiceAddress.postalCode ||
        !values.invoiceAddress.country
      ) {
        extendMultiValue(feedback, startingIndex + 0, i18n.getTranslation('customer.validation.address'));
      }

      if (values.usedForPaymentMethod === 'sdd') {
        if (!isValidIBAN(values.iban)) {
          extendMultiValue(feedback, startingIndex + 1, i18n.getTranslation('customer.validation.iban'));
        }
      }

      const primaryEmailsValid = values.primaryContactDetails.reduce((acc: boolean[], detail) => {
        if (detail.contactType === 'email' && !!detail.value) {
          acc.push(validateEmail(detail.value));
        }

        return acc;
      }, []);

      const secondaryEmailsValid = values.secondaryContactDetails.reduce((acc: boolean[], detail) => {
        if (detail.contactType === 'email' && !!detail.value) {
          acc.push(validateEmail(detail.value));
        }

        return acc;
      }, []);

      if (primaryEmailsValid.includes(false) || secondaryEmailsValid.includes(false)) {
        extendMultiValue(feedback, startingIndex + 2, i18n.getTranslation('customer.validation.invalid_email'));
      }

      if (values.primaryContactDetails.length === 0) {
        extendMultiValue(feedback, startingIndex + 2, i18n.getTranslation('customer.validation.contact'));
      }

      handleApiErrors(feedback, []);
      return { feedback };
    }
  });

  const handleFocus = (step: string) => {
    return () => onFocusStep(step);
  };

  const handleSubmit = submitFactory(async () => {
    const comPreferences = values.communicationPreferences;

    if (!values.deviatingCommunicationType) comPreferences.invoiceCommunicationPreferences = [];
    if (values.deviatingCommunicationType && values.commPreference !== 'custom') {
      comPreferences.invoiceCommunicationPreferences = [
        {
          invoiceType: invoiceType.advance,
          communicationType: values.commPreference
        },
        {
          invoiceType: invoiceType.invoice,
          communicationType: values.commPreference
        },
        {
          invoiceType: invoiceType.endnote,
          communicationType: values.commPreference
        },
        {
          invoiceType: invoiceType.creditnote,
          communicationType: values.commPreference
        },
        {
          invoiceType: invoiceType.incidentalnote,
          communicationType: values.commPreference
        },
        {
          invoiceType: invoiceType.correctionnote,
          communicationType: values.commPreference
        }
      ];
    }

    const contactDetails = [...values.primaryContactDetails, ...values.secondaryContactDetails].filter((scd) => {
      return !!scd.value;
    });

    const bankAccounts = () => {
      if (values.iban) {
        return [
          {
            accountHolder:
              values.customerType === 'organization' ? values.companyName : `${values.firstName} ${values.lastName}`,
            iban: values.iban,
            usedForPaymentMethod: values.usedForPaymentMethod,
            addedFromIncomingBankingTransaction: false
          }
        ];
      } else {
        return [];
      }
    };

    const apiFriendlyValues: CreateCustomerRequestType = {
      accountNum: values.accountNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      salutation: values.salutation,
      initials: values.initials,
      birthDate: values.birthDate ? values.birthDate.toISOString() : MIN_DATE,
      companyName: values.customerType === customerType.organization ? values.companyName : '',
      organizationNumber: values.customerType === customerType.organization ? values.organizationNumber : '',
      vatNumber: values.customerType === customerType.organization ? values.vatNumber : '',
      invoiceAddress: values.invoiceAddress,
      customerType: values.customerType,
      defaultPaymentMethod: values.usedForPaymentMethod,
      bankAccounts: bankAccounts(),
      contactDetails: contactDetails,
      communicationPreferences: comPreferences,
      paymentTermsId: values.paymentTermsId,
      ssin: values.customerType === customerType.person ? values.ssin : '',
      ssinCountry: values.customerType === customerType.person ? values.ssinCountry : null,
      customerGroupId: values.customerGroupId
    };

    try {
      const response = (
        await sendRequest<CustomerType>({
          request: {
            method: METHODS.POST,
            endpoint: '/md/customers',
            data: apiFriendlyValues
          },
          tenantReducer,
          lang: i18n.lang
        })
      ).data;

      notify.success({
        content: i18n.getTranslation('customer.notify_created')
      });

      setNewCustomer ? setNewCustomer(response) : navigate(`${rootUrl}/customers/${response.id}`);
    } catch (e) {
      //@ts-ignore
      const apiErrors_ = e.data && e.data.errors ? e.data.errors : [];
      handleApiErrors([], apiErrors_);
      setApiErrors(apiErrors_);
    }
  });

  // @ts-ignore
  const setCustomerEssential = (val: Partial<CustomerEssentialsBaseType>) => setValue(val);

  const setBankingDetail = (
    val: BankingDetailsBaseType[keyof BankingDetailsBaseType],
    key: keyof BankingDetailsBaseType
  ) => {
    const objectToSet = {} as Record<string, any>;
    objectToSet[key] = val;
    setValue(objectToSet);
  };

  const customerName = getName(values);

  return (
    <>
      <EssentialsSection
        orgConfig={orgConfig}
        values={values}
        customerName={customerName}
        setCustomerEssential={setCustomerEssential}
        onFocusStep={onFocusStep}
      />
      <BankingDetailsSection
        values={values}
        customerName={customerName}
        setBankingDetail={setBankingDetail}
        handleFocus={handleFocus}
      />
      <CommunicationSection
        values={values}
        cultureTable={cultureTable}
        customerName={customerName}
        setValue={setValue}
        handleFocus={handleFocus}
      />
      <WizardSubmitButton
        id="customer-submit"
        onClick={handleSubmit}
        disabled={errors.feedback.length > 0 && feedback.length > 0}
      >
        {i18n.getTranslation('general.submit')}
      </WizardSubmitButton>
    </>
  );
}
