import moment from 'moment';
import React from 'react';

import { ExportJobType } from '@zf/api-types/export-job';
import { ImportJobType } from '@zf/api-types/import-job';
import { CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';
import { formatDateTime } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import { SuspenseCard } from '../../components/suspense';

export type PropsType = {
  job: ImportJobType | ExportJobType;
};

export default function MessagesCard(props: PropsType) {
  const { job } = props;
  const { i18n, enumReducer } = useAppContext();

  job.messages.sort((a, b) => moment(b.loggingDateTime).unix() - moment(a.loggingDateTime).unix());

  return (
    <SuspenseCard id="messages-card" width="2">
      <CardHeader>{i18n.getTranslation('jobs_shared.messages')}</CardHeader>
      <CardBody type="table" fixedHeight>
        <DeprecatedStaticTable
          rows={job.messages.map((message) => {
            return {
              type: enumReducer.getTranslation('messageType', message.type),
              message: message.message,
              loggingDateTime: formatDateTime(message.loggingDateTime)
            };
          })}
          showHeader={true}
        >
          <DeprecatedStaticColumn flexWidth="1" label={i18n.getTranslation('general.type')} dataKey="type" />
          <DeprecatedStaticColumn flexWidth="3" label={i18n.getTranslation('jobs_shared.message')} dataKey="message" />
          <DeprecatedStaticColumn
            flexWidth="1"
            label={i18n.getTranslation('jobs_shared.moment')}
            dataKey="loggingDateTime"
          />
        </DeprecatedStaticTable>
      </CardBody>
    </SuspenseCard>
  );
}
