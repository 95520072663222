import React from 'react';

import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

export type PropsType = {
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  children: React.ReactNode;
};

export default function SuspenseSpinner(props: PropsType) {
  const { children, size } = props;

  return <React.Suspense fallback={<Spinner size={size || 'small'} />}>{children}</React.Suspense>;
}
