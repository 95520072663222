import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { CardFooter } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { Icon } from '../../components/Icon';
import InputField, { InputFieldProps } from '../../components/input/InputField';
import { useStore } from '../../hooks/useStore';
import css from './comment-card.module.scss';

export type PropsType = {
  subjectType: entitySubjectType;
  subjectId: string;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

const CommentCardFooter = (props: PropsType) => {
  const { subjectType, subjectId } = props;

  const { applicationStore, activityStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addComment } = activityStore;

  const [memo, setMemo] = useState<string>('');

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && memo) {
      await addComment(subjectType, subjectId, memo);

      setMemo('');
    }
  };

  return (
    <CardFooter className={css['comment-card-footer']}>
      <div className={css['comment-input']}>
        <Icon type="textballoon" className={css['comment-icon']} />
        <InlineInputFieldInput
          id="comment.add"
          onChange={setMemo}
          onKeyDown={handleKeyDown}
          type="text"
          value={memo}
          placeholder={getTranslation('comments.write')}
          className={css['comment-input-field']}
        />
      </div>
    </CardFooter>
  );
};

export default observer(CommentCardFooter);
