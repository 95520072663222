import { observer } from 'mobx-react';
import React from 'react';

import { culture } from '@zf/api-types/enums';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import FilesStore from '../../logic/FilesStore';

type Props<T> = {
  filesStore: FilesStore<T>;
  culture?: culture;
};

const DownloadEntityAttachmentListItem = <T,>(props: Props<T>) => {
  const { filesStore, culture } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { actionPermissions, downloadEntityAttachment } = filesStore;

  if (!actionPermissions.mayDownloadEntityAttachment) return null;

  return (
    <DropdownAction
      id="entity_attachment.download"
      key="entity_attachment.download"
      actionType="direct"
      icon="download"
      onClick={() => downloadEntityAttachment(culture)}
    >
      {getTranslation('general.download')}
    </DropdownAction>
  );
};

export default observer(DownloadEntityAttachmentListItem);
