import React from 'react';

import { paymentReversalReason } from '@zf/api-types/enums';
import { PaymentRowType, PaymentType } from '@zf/api-types/payments';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { Provider, useAppContext } from '../../../app-context';
import useListPage from '../../../app-context/hooks/use-listpage';
import Debtor from '../../../components/Debtor/Debtor';
import { Icon } from '../../../components/Icon';
import ReversalTooltip from '../../../components/ToolTip/reversal-tooltip';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';

const usePaymentListPage = () => {
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { applicationStore } = useStore();
  const { rootUrl } = tenantReducer.state;

  const getTransactionIdentification = React.useCallback((value: PaymentType) => {
    if (value.incomingBankingTransactionIdentification) {
      return (
        <Link
          id={`incoming-${value.incomingBankingTransactionId}`}
          url={`${rootUrl}/payments/incoming-banking-transactions/${value.incomingBankingTransactionId}?fromPayments=1`}
        >
          {value.incomingBankingTransactionIdentification}
        </Link>
      );
    } else if (value.outgoingBankingTransactionId) {
      return (
        <Link
          id={`outgoing-${value.outgoingBankingTransactionId}`}
          url={`${rootUrl}/payments/outgoing-banking-transactions/${value.outgoingBankingTransactionId}?fromPayments=1`}
        >
          {value.outgoingBankingTransactionIdentification}
        </Link>
      );
    }

    return <Label>-</Label>;
  }, []);

  const processRecord = (value: PaymentType): PaymentRowType => {
    return {
      __id: value.id,
      __etag: value._etag,
      __paymentEntity: value,
      transactionIdentification: getTransactionIdentification(value),
      type: enumReducer.getTranslation('paymentType', value.type),
      isReversed: value.isReversed && (
        <div
          data-tip={createTooltipContent(
            <Provider>
              <ReversalTooltip
                i18n={i18n}
                reversalIntel={{
                  paymentReversalReason: applicationStore.getEnumTranslation(
                    'paymentReversalReason',
                    value.reversalDetails?.reversalReason
                  ) as paymentReversalReason | undefined,
                  reversalAdditionalInformation: value.reversalDetails?.reversalAdditionalInformation
                }}
              />
            </Provider>
          )}
          data-for="payments-table-tip"
        >
          <Icon type="exclamation" color={colors['red-600']} />
        </div>
      ),
      paymentDateTime: formatDate(value.paymentDateTime),
      customerIBAN: value.customerBankAccount,
      amount: formatMoney(value.amount, i18n.culture),
      paymentReference: value.paymentReference,
      customer: <Debtor debtor={value.debtor} />
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<PaymentType, PaymentRowType>({
    endpoint: '/bill/payments',
    processRecord,
    domain: 'payment'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default usePaymentListPage;
