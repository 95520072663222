/**
 * @deprecated, use DownloadService instead
 */
export function download(fileName: string, content: string) {
  const linkElement = document.createElement('a');
  linkElement.href = content;
  linkElement.target = '_blank';

  linkElement.download = fileName;

  linkElement.dispatchEvent(new MouseEvent('click'));
}

/**
 * @deprecated, use DownloadService instead
 */
export function downloadBlob(fileName: string, blob: Blob) {
  download(fileName, URL.createObjectURL(blob));
}

export function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
}

// Don't try to use xlsx package to write CSV, ';' not supported and hell to replace
export function writeToCSVFile(toWrite: string, fileName: string) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(toWrite);
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  hiddenElement.click();
}
