import React from 'react';
import { StringParam } from 'serialize-query-params';

import { ImportJobsOverviewCountType } from '@zf/api-types/import-job';

import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';

const initialImportJobOverviewCount: ImportJobsOverviewCountType = {
  all: 0,
  completed: 0,
  ongoing: 0,
  requireAttention: 0,
  stored: 0
};

type QueryParam = {
  dataImportId?: string;
  dataImportName?: string;
};

const paramTypes = {
  dataImportId: StringParam,
  dataImportName: StringParam
};

const useImportJobFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    clearQueryParams,
    setQueryParams
  } = useFilter<QueryParam>({
    endpoint: '/logs/ImportJob/overviewcount',
    initialCount: initialImportJobOverviewCount,
    domain: 'importjob',
    search,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.dataImportId) {
    tags.push(
      <FilterTag
        key="data-import-type"
        value={queryParams.dataImportName}
        removeFilter={() => setQueryParams({ dataImportId: '', dataImportName: '' })}
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    queryParams,
    quickFilter,
    overviewCount,
    searchValue,
    filterTags,
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams
  };
};

export default useImportJobFilter;
