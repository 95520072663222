import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IncomingBankingTransactionBankAccountInsights } from '@zf/api-types/billing/incoming-banking-transaction';
import { pluginType, statusCode } from '@zf/api-types/enums';
import { formatDate, isDefaultDate } from '@zf/utils/src/date';
import { formatIBAN } from '@zf/utils/src/iban';

import IconParagraph from '../../../../../components/Icon/IconParagraph';
import { DANGER, SUCCESS, WARNING } from '../../../../../constants/color';
import { DetailLine, InfoGrid } from '../../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import css from './incoming-banking-transactions-list-info.module.scss';

const IncomingBankingTransactionListPageInfo = () => {
  const { applicationStore, incomingBankingTransactionsStore, configStore } = useStore();
  const { getTranslation } = applicationStore;
  const { configService, isPluginEnabled } = configStore;
  const { getIncomingBankingTransactionBankAccountInsights } =
    incomingBankingTransactionsStore.incomingBankingTransactionsService;

  const [insights, setInsights] = useState<IncomingBankingTransactionBankAccountInsights>();

  useEffect(() => {
    if (!insights) {
      Promise.all([configService.getConfiguredPlugins(), getIncomingBankingTransactionBankAccountInsights()]).then(
        (res) => {
          if (isPluginEnabled(pluginType.twikey)) {
            setInsights(res[1]);
          }
        }
      );
    }
  }, []);

  if (!insights) return null;

  return (
    <div className={css['infogrid-wrapper']}>
      <Paragraph bold>{getTranslation('general.last_update')}</Paragraph>
      <InfoGrid>
        {insights.companyBankAccounts.map((cba, index) => {
          const id = `${cba.iban}-${index}`;
          const formattedIban = formatIBAN(cba.iban);
          const formattedDate = formatDate(cba.lastFetchedDateTime);

          if (cba.pluginRunStatus === statusCode.failed) {
            return (
              <DetailLine key={id} label={formattedIban} color={DANGER}>
                <IconParagraph
                  id={id}
                  iconType="info-circle"
                  iconPosition="right"
                  title={getTranslation('incoming_banking_transactions.twikey_failed_since', { date: formattedDate })}
                  color={DANGER}
                >
                  {formattedDate}
                </IconParagraph>
              </DetailLine>
            );
          }

          if (cba.pluginRunStatus === statusCode.inactive) {
            return (
              <DetailLine key={id} label={formattedIban}>
                <IconParagraph
                  id={id}
                  iconType="info-circle"
                  iconPosition="right"
                  title={getTranslation('incoming_banking_transactions.twikey_not_configured')}
                  color={WARNING}
                  bold
                >
                  {getTranslation('general.manual')}
                </IconParagraph>
              </DetailLine>
            );
          }

          return (
            <DetailLine key={id} label={formattedIban}>
              <Paragraph color={!isDefaultDate(cba.lastFetchedDateTime) ? SUCCESS : ''}>{formattedDate}</Paragraph>
            </DetailLine>
          );
        })}
      </InfoGrid>
    </div>
  );
};

export default observer(IncomingBankingTransactionListPageInfo);
