import { CustomerType } from '@zf/api-types/master-data/customer';

import RootStore from '../../../app-context/stores';

class Customer {
  public customer: CustomerType;
  public rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  get title() {
    return `${this.rootStore.applicationStore.getTranslation('customer.customer')} - ${this.customer.accountNumber} - ${
      this.customer.shortDisplayName
    }`;
  }
}

export default Customer;
