import { Button } from 'design-system/Components';
import { dialog } from 'events/dialog-events';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import AsyncCardSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncCardSpinner';

import ForecastingService from '../../app-context/services/ForecastingService';
import { useStore } from '../../hooks/useStore';
import AllLocationsDialog from './AllLocationsDialog';
import MultiLocationCardBody from './MultiLocationCardBody';
import css from './service-location-card.module.scss';
import SingleLocationCardBody from './SingleLocationCardBody';

type Props = {
  forecastingService: ForecastingService;
  currentContract: ContractType | undefined;
};

const ServicedLocationCard = (props: Props) => {
  const { currentContract, forecastingService } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { eavs, isRecalculating, getEavsAndCheckEstimationInProgress, reset } = forecastingService;

  const { clickRef, validationRef, onSubmit } = useDialog();

  useEffect(() => {
    getEavsAndCheckEstimationInProgress(currentContract?.id);

    // Clear interval & reset eavs when navigating
    return () => reset();
  }, [currentContract?.id, isRecalculating]);

  const cardTitle = getTranslation('cards.serviced_location.title');
  const serviceLocations = currentContract?.serviceLocations || [];
  const contractServices = eavs?.contractServices || [];
  const isMulti = serviceLocations.length > 1;

  const openAllLocationsDialog = () => {
    dialog.normal({
      title: getTranslation('location.all_locations'),
      content: (
        <AllLocationsDialog
          ref={clickRef}
          validationRef={validationRef}
          serviceLocations={serviceLocations}
          contractServices={contractServices}
        />
      ),
      isDismissDialog: true,
      ref: validationRef,
      onSubmit
    });
  };

  const multiBtn = (
    <Button id="all-locations" className={css['all-locations-btn']} type="text" onClick={openAllLocationsDialog}>
      {getTranslation('location.all_locations')}
    </Button>
  );

  if (eavs === null || (eavs && serviceLocations.length === 0))
    return (
      <Card id="service-card" width="1">
        <CardHeader extraRight={isMulti && multiBtn}>{cardTitle}</CardHeader>
        <CardEmptyBody
          image={
            <img
              src="https://cdn.zerofriction.co/shared/assets/emptyStates/services2.png"
              alt="service"
              title="service"
              width="70"
              height="70"
            />
          }
          title={getTranslation('cards.service.no_services')}
        >
          {getTranslation('cards.service.no_services_description')}
        </CardEmptyBody>
      </Card>
    );

  return (
    <AsyncCardSpinner title={cardTitle} variables={[isRecalculating ? undefined : true]}>
      <Card id="serviced-locations-card" role="dialog" className="card">
        <CardHeader extraRight={isMulti && multiBtn}>{cardTitle}</CardHeader>
        <CardBody>
          {isMulti ? (
            <MultiLocationCardBody serviceLocations={serviceLocations} />
          ) : (
            <SingleLocationCardBody serviceLocation={serviceLocations[0]} contractServices={contractServices} />
          )}
        </CardBody>
      </Card>
    </AsyncCardSpinner>
  );
};

export default observer(ServicedLocationCard);
