import React from 'react';

import { UpdateBankAccountType } from '@zf/api-types/general';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import BankaccountsContent from './bankaccounts-content';

type Props = {
  config: UseConfigReturnType<UpdateBankAccountType[]>;
};

export default function Bankaccounts(props: Props) {
  const { config } = props;
  const { Provider, useTracked } = useCreateContext<UpdateBankAccountType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <BankaccountsContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
