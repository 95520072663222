import { observer } from 'mobx-react';
import React from 'react';

import { ImportJobType } from '@zf/api-types/import-job';
import useDialog from '@zf/hooks/src/useDialog';

import ProcessAllImportJobDialog from '../../../actions/import-jobs/ProcessAllDialog';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  refreshCounts: () => void;
};

const ProcessAllListPageButton = (props: Props) => {
  const { refreshCounts } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { setUpdatedRows } = useUpdateListPage<ImportJobType>('importjob');
  const { clickRef, onSubmit } = useDialog();

  const translation = getTranslation('general.process_all');

  return (
    <DropdownAction
      id="import_jobs.process_all"
      icon="export"
      dialogTitle={translation}
      dialogContent={
        <ProcessAllImportJobDialog ref={clickRef} onComplete={setUpdatedRows} refreshCounts={refreshCounts} />
      }
      onSubmit={onSubmit}
      buttonPositive={translation}
    />
  );
};

export default observer(ProcessAllListPageButton);
