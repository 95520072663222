import { customerType } from '@zf/api-types/enums';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';

import { EnumKeyType } from '../app-context/hooks/use-enum';
import { formatCustomerName } from './customer';

export const getTaskText = (
  getTranslation: (key: string, params?: Record<string, string | number>) => any,
  getEnumTranslation: (enumKey: EnumKeyType, symbol: string) => string,
  task: MoveRequestType
) => {
  let name: string | null = '';

  //initial name
  if (task.customerDetails.firstName || task.customerDetails.lastName) {
    name = formatCustomerName(task.customerDetails.lastName, task.customerDetails.firstName);
  } else if (task.customerDetails.customerType === customerType.organization) {
    name = task.customerDetails.companyName;
  }

  return name
    ? `${getTranslation('tasks.task_for', {
        taskType: getEnumTranslation('moveRequestType', task.moveRequestType),
        name: name
      })}`
    : getTranslation(`tasks.${task.moveRequestType}_for_existing`);
};
