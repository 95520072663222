import { METHODS } from 'utils/request';

import { PagedResponseType, ResponseType } from '@zf/api-types/api';

import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';
import {
  MutingRuleRequestType,
  MutingRuleDTO,
  MutingRuleAggregatorDTO,
  MutingRuleType
} from '@zf/api-types/master-data/meter';
import { MeteringIssueError, MutingRuleTimePeriod } from '@zf/api-types/enums';

export default class MutingRuleService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/me/MutingRules', applicationStore);
  }

  ///1
  createMutingRule = async (
    errorType: MutingRuleRequestType['errorType'],
    message: string | null,
    timePeriod: MutingRuleRequestType['timePeriod'],
    meterIds: string[]
  ) => {
    return (
      await this.applicationStore.sendRequest<ResponseType<MutingRuleAggregatorDTO>>({
        request: {
          method: METHODS.POST,
          endpoint: this.endpoint,
          data: { meterIds, errorType, timePeriod, message }
        }
      })
    ).data;
  };

  ///2
  getAllMutingRules = async (includeActive?: boolean, includeExpired?: boolean) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MutingRuleAggregatorDTO>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/getall`,
          query: {
            includeActive,
            includeExpired
          }
        }
      })
    ).data.results;
  };
  ///3
  getMutingRulesByMeterId = async (meterId: string, includeActive?: boolean, includeExpired?: boolean) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MutingRuleType>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/getall/${meterId}`,
          query: {
            includeActive,
            includeExpired
          }
        }
      })
    ).data;
  };
  ///4
  getMutingRulesByMutingRuleId = async (mutingRuleId: string) => {
    return (
      await this.applicationStore.sendRequest<MutingRuleType>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/${mutingRuleId}`
        }
      })
    ).data;
  };
  ///5
  getMetersIdsUnderRule = async (
    muteFromWithNumericValue: number,
    muteUntilWithNumericValue: number,
    errorType: MeteringIssueError,
    message: string,
    timePeriod: MutingRuleTimePeriod,
    userId: string
  ) => {
    return (
      await this.applicationStore.sendRequest<string[]>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/getmeters`,
          query: {
            muteFromWithNumericValue,
            muteUntilWithNumericValue,
            errorType,
            message,
            timePeriod,
            userId
          }
        }
      })
    ).data;
  };
  ///6
  getAllRelated = async (errorType: MeteringIssueError, meterIds: string[]) => {
    return (
      await this.applicationStore.sendRequest<MutingRuleDTO[]>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/getallrelated`,
          query: {
            errorType,
            meterIds
          }
        }
      })
    ).data;
  };
  ///7
  getCount = async (includeActive?: boolean, includeExpired?: boolean) => {
    return (
      await this.applicationStore.sendRequest<number>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/getcount`,
          query: {
            includeActive,
            includeExpired
          }
        }
      })
    ).data;
  };
  ///8 PUT
  cancelMutingRuleByMutingRuleId = async (mutingRuleId: string) => {
    return (
      await this.applicationStore.sendRequest<boolean>({
        request: {
          method: METHODS.PUT,
          endpoint: `${this.endpoint}/cancel/${mutingRuleId}`
        }
      })
    ).data;
  };
  ///9 PUT (has request body)
  cancelMutingRulesByValues = async (
    errorType: MeteringIssueError,
    timePeriod: MutingRuleTimePeriod,
    meterIds: string[],
    message?: string,
    muteFrom?: string
  ) => {
    return (
      await this.applicationStore.sendRequest<boolean>({
        request: {
          method: METHODS.PUT,
          endpoint: `${this.endpoint}/cancel`,
          data: {
            errorType,
            timePeriod,
            meterIds,
            message,
            muteFrom
          }
        }
      })
    ).data;
  };
}
