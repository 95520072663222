import React from 'react';

import { invoiceStatus, invoiceType, paymentMethod, paymentProcessStatus, exportStatus } from '@zf/api-types/enums';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import CustomerAutoFill, { CustomerAutofillProps } from '../../../../components/Autofills/CustomerAutoFill';
import PropertyGroupAutoFill, {
  PropertyGroupAutofillProps
} from '../../../../components/Autofills/PropertyGroupAutoFill';
import DateRangePicker from '../../../../components/input/DateRangePicker';
import EnumFilterSelect from '../../../../components/input/EnumFilterSelect';
import HistoryPicker from '../../../../components/Lang/HistoryPicker';
import { QueryParam } from '../../hooks/useInvoiceFilter';
import CustomerGroupAutoFill, { CustomerGroupAutofillProps } from 'components/Autofills/CustomerGroupAutoFill';

type Props = {
  queryParams: QueryParam;
  setQueryParams: (params: Partial<QueryParam>) => void;
};

const InlineCustomerAutoFill = InlineInputField<CustomerAutofillProps>(CustomerAutoFill);
const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);
const InlineCustomerGroupAutoFill = InlineInputField<CustomerGroupAutofillProps>(CustomerGroupAutoFill);

export default function InvoiceFilterInputs(props: Props) {
  const { queryParams, setQueryParams } = props;
  const { i18n, enumReducer } = useAppContext();

  return (
    <>
      <Label key="label-property-group">{i18n.getTranslation('property_groups.property_group')}</Label>
      <InlinePropertyGroupAutoFill
        id="by_property_group"
        onChange={(val) =>
          setQueryParams({
            propertyGroupId: val[0]?.id || '',
            propertyGroupName: val[0]?.name || ''
          })
        }
        selectedValues={[queryParams.propertyGroupId || '']}
        renderTopLvl={false}
        showDetails={false}
      />
      <Label key="label-customer-group">{i18n.getTranslation('customer_groups.customer_group')}</Label>
      <InlineCustomerGroupAutoFill
        id="by_customer_group"
        key="by_customer_group"
        onChange={(val) =>
          setQueryParams({
            customerGroupName: val[0] ? val[0].name : '',
            customerGroupId: val[0] ? val[0].id : ''
          })
        }
        selectedValues={[queryParams.customerGroupId || '']}
        renderTopLvl={false}
        showDetails={false}
      />
      <Label key="label-customer">{i18n.getTranslation('customer.customer')}</Label>
      <InlineCustomerAutoFill
        id="by_customer"
        onChange={(val) =>
          setQueryParams({
            customerId: val[0]?.id || '',
            customerName: val[0] ? val[0].firstName + val[0].lastName : ''
          })
        }
        selectedValues={[queryParams.customerId || '']}
        renderTopLvl={false}
        showDetails={false}
      />
      <ListDivider />
      <EnumFilterSelect
        id="invoice-type-filter"
        title={i18n.getTranslation('general.by_type')}
        options={enumReducer.getEnum<invoiceType>('invoiceType')}
        values={queryParams.invoiceType || []}
        setValue={(invoiceType) => setQueryParams({ invoiceType })}
        multiSelect
      />
      <EnumFilterSelect
        id="invoice-status-filter"
        title={i18n.getTranslation('invoice.by_invoice_status')}
        options={enumReducer.getEnum<invoiceStatus>('invoiceStatus')}
        values={queryParams.invoiceStatus || []}
        setValue={(invoiceStatus) => setQueryParams({ invoiceStatus })}
      />
      <EnumFilterSelect
        id="payment-method-filter"
        title={i18n.getTranslation('general.by_payment_method')}
        options={enumReducer.getEnum<paymentMethod>('paymentMethod')}
        values={queryParams.paymentMethod || []}
        setValue={(paymentMethod) => setQueryParams({ paymentMethod })}
      />
      <EnumFilterSelect
        id="invoice-export-status"
        title={i18n.getTranslation('invoice.by_export_status')}
        options={enumReducer.getEnum<exportStatus>('exportStatus')}
        values={queryParams.exportStatus || []}
        setValue={(exportStatus_) => {
          if (exportStatus_[0] === exportStatus.notexported) {
            setQueryParams({ exportStatus: exportStatus_, invoiceStatus: [invoiceStatus.generated] });
          } else {
            setQueryParams({ exportStatus: exportStatus_ });
          }
        }}
      />
      <EnumFilterSelect
        id="payment-process-status-filter"
        title={i18n.getTranslation('payments.by_process_status')}
        options={enumReducer.getEnum<paymentProcessStatus>('paymentProcessStatus')}
        values={queryParams.paymentProcessStatus || []}
        setValue={(paymentProcessStatus) => setQueryParams({ paymentProcessStatus })}
      />
      <ListDivider />
      <Label>{i18n.getTranslation('invoice.by_invoice_date')}</Label>
      <HistoryPicker
        id="history-picker"
        setHistoryDates={(dates) =>
          setQueryParams({
            startDateTime: convertMomentToISOString(dates[0]) as string,
            endDateTime: convertMomentToISOString(dates[1]) as string
          })
        }
        historyDates={[convertToMoment(queryParams.startDateTime), convertToMoment(queryParams.endDateTime)]}
      />
      <DateRangePicker
        id="invoice-date-filter"
        startDate={convertToMoment(queryParams.startDateTime)}
        endDate={convertToMoment(queryParams.endDateTime)}
        setDates={(dates) =>
          setQueryParams({
            startDateTime: convertMomentToISOString(dates[0]) as string,
            endDateTime: convertMomentToISOString(dates[1]) as string
          })
        }
        renderTopLvl={false}
      />
      <Label>{i18n.getTranslation('invoice.invoice_period')}</Label>
      <DateRangePicker
        id="invoice-period-filter"
        startDate={convertToMoment(queryParams.periodStartDateTime)}
        endDate={convertToMoment(queryParams.periodEndDateTime)}
        setDates={(dates) =>
          setQueryParams({
            periodStartDateTime: convertMomentToISOString(dates[0]) as string,
            periodEndDateTime: convertMomentToISOString(dates[1]) as string
          })
        }
        placeholderStart={i18n.getTranslation('invoice.start')}
        placeholderEnd={i18n.getTranslation('invoice.end')}
        renderTopLvl={false}
      />
    </>
  );
}
