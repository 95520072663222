import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { CustomerBankAccountType } from '@zf/api-types/billing/customer-bank-account';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context/app-context';
import IconParagraph from '../../components/Icon/IconParagraph';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { DANGER } from '../../constants/color';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';

type Props = {
  customerId: string;
  iban: string;
  setIban: (val: string[]) => void;
};

export default function BankAccountRequestSection(props: Props) {
  const { customerId, iban, setIban } = props;
  const { i18n } = useAppContext();

  const accountsResponse = useSuspenseSingleAPI<PagedResponseType<CustomerBankAccountType>>({
    request: {
      endpoint: `/bill/CustomerBankAccounts/c/${customerId}`
    }
  });

  if (!accountsResponse.result) return null;

  const customerBankAccounts = accountsResponse.result.data.results.reduce(
    (acc: DropdownValuesType<string>[], account) => {
      if (account.activeMandate) {
        acc.push({ value: account.iban, text: account.iban });
      }

      return acc;
    },
    []
  );

  return customerBankAccounts.length > 0 ? (
    <SimpleDropdown
      id="iban"
      onChange={setIban}
      values={customerBankAccounts}
      selectedValues={[iban]}
      error={!iban}
      clear
    />
  ) : (
    <Center type="horizontal">
      <IconParagraph iconType="alert-circle" color={DANGER}>
        {i18n.getTranslation('actions.outgoing_mutation.no_mandates')}
      </IconParagraph>
    </Center>
  );
}
