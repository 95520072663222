import React from 'react';

import {
  IncomingBankingTransactionRowType,
  IncomingBankingTransactionType
} from '@zf/api-types/billing/incoming-banking-transaction';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteIncomingBankingTransaction } from './incoming-banking-transactions';

type Props = {
  // List page
  selectedRows?: IncomingBankingTransactionRowType[];
  setDeletedRows?: (
    updatedRows: IncomingBankingTransactionType[],
    deletedRows: Partial<IncomingBankingTransactionType>[]
  ) => void;

  // Detail page
  bankingTransaction?: IncomingBankingTransactionType;
  onComplete?: () => void;
};

const DeleteIncomingBankingTransactionsDialog = React.forwardRef(
  (props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
    const { selectedRows, bankingTransaction, setDeletedRows, onComplete } = props;
    const { i18n, tenantReducer } = useAppContext();

    const isMulti = selectedRows && selectedRows.length > 1;

    React.useImperativeHandle(ref, () => ({
      async onClick() {
        try {
          if (bankingTransaction && onComplete) {
            await deleteIncomingBankingTransaction(bankingTransaction.id, tenantReducer, i18n.lang);

            onComplete();
          } else if (selectedRows && setDeletedRows) {
            const deletedBankingTransactions: IncomingBankingTransactionType[] = [];
            await Promise.all(
              selectedRows.map((r) => {
                deletedBankingTransactions.push(r.__entity);
                return deleteIncomingBankingTransaction(r.__entity.id, tenantReducer, i18n.lang);
              })
            );

            setDeletedRows([], deletedBankingTransactions);
          }

          notify.success({
            content: i18n.getTranslation(`actions.banking_transaction.delete_success${isMulti ? '_multi' : ''}`)
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation(`actions.banking_transaction.delete_fail${isMulti ? '_multi' : ''}`),
            error: e
          });
        }
      }
    }));

    return (
      <Paragraph>
        {i18n.getTranslation(`actions.banking_transaction.delete_paragraph${isMulti ? '_multi' : ''}`, {
          amount: selectedRows ? selectedRows.length : 0
        })}
      </Paragraph>
    );
  }
);

export default DeleteIncomingBankingTransactionsDialog;
