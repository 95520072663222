import clone from 'clone';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { navigate } from '@reach/router';
import { managementRelationType, utilityType } from '@zf/api-types/enums';
import { LocalAddressType } from '@zf/api-types/general';
import { AddMeasurementRequestType, ExternalChannelType } from '@zf/api-types/master-data/meter';
import { PropertyGroupType, UpdateManagementRelation } from '@zf/api-types/master-data/property-group';
import { CreateServiceLocationRequestType, ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import useValidator from '@zf/hooks/src/useValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';
import { startOfDay } from '@zf/utils/src/date';

import { updateServiceLocations } from '../../../actions/property-group/property-group';
import { useAppContext } from '../../../app-context';
import useWizardAPIErrors from '../../../app-context/hooks/useWizardAPIErrors';
import WizardSubmitButton from '../../../components/Button/WizardSubmitButton';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import Select from '../../../components/input/Select';
import { notify } from '../../../events/notification-events';
import { extendMultiValue } from '../../../utils/arrays';
import { METHODS, sendRequest } from '../../../utils/request';
import InstallMeterSection from '../../meter-install/wizard/InstallMeterSection';
import { InstallationType } from '../../meter-install/wizard/InstallMeterWizard';
import { useSubmitFunctions } from '../../meter-install/wizard/useSubmitFunctions';
import AddressSection from './AddressSection';
import DefaultConfigSection from './DefaultConfigSection';
import css from './location-wizard.module.scss';

export type Props = {
  createNewMeter?: boolean;
  customerAddress?: LocalAddressType | null;
  feedback: string[][];
  onFocusStep: Dispatch<SetStateAction<string>>;
  setNewLocation?: (location: ServiceLocationType | null) => void;
  setFeedback: Dispatch<SetStateAction<string[][]>>;
  setCreateNewMeter?: Dispatch<SetStateAction<boolean>>;
};

export type ServiceValueType = {
  utilityType: utilityType;
  externalIdentifier: string;
};

export type CreateLocationType = {
  location: ServiceLocationType | null;
  streetName: string;
  streetNumber: string;
  streetNumberAddition: string;
  postalCode: string;
  city: string;
  country: string;
  defaultProductId: string;
  productId: string;
  services: Array<ServiceValueType>;
  installations: InstallationType[];
  projectId: string;
  externalId: string;
  propertyGroup?: PropertyGroupType;
  ownerId: string;
  managerId: string;
  locationType: 'location' | 'property';
  didSubmitBefore: boolean;
  areMetersValid: boolean;
  areMeasurementsValid: boolean;
  numberInstallSuccess: number;
  numberMeasurementsSuccess: number;
  installSuccesses: boolean[];
  measurementSuccesses: boolean[];
};

export const STEP_NAMES = [
  'location-address',
  'default-config',
  'location-services',
  'meters',
  'location-errors',
  'meter-essentials',
  'meter-channels',
  'meter-errors'
];

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);

const LocationWizard = (props: Props) => {
  const { feedback, createNewMeter, customerAddress, onFocusStep, setNewLocation, setFeedback, setCreateNewMeter } =
    props;

  const { i18n, enumReducer, tenantReducer } = useAppContext();

  const { applicationStore, meterStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { installMeter, updateParentRelation } = meterStore.deviceService;
  const { addMeasurementMeter } = meterStore.measurementService;

  const [today] = useState(startOfDay(moment()));

  const startingIndex = setNewLocation ? 16 : 0;

  const { apiErrors, setApiErrors, handleApiErrors } = useWizardAPIErrors(startingIndex + 2, setFeedback);
  const { submitInstallationsAndMeasurements } = useSubmitFunctions(
    addMeasurementMeter,
    installMeter,
    updateParentRelation,
    setApiErrors,
    handleApiErrors
  );

  const { values, errors, setValue, submitFactory } = useValidator<CreateLocationType>({
    initialValues: {
      location: null,
      streetName: customerAddress ? customerAddress.streetName : '',
      streetNumber: customerAddress ? customerAddress.streetNumber : '',
      streetNumberAddition: customerAddress
        ? customerAddress.streetNumberAddition
          ? customerAddress.streetNumberAddition
          : ''
        : '',
      postalCode: customerAddress ? customerAddress.postalCode : '',
      city: customerAddress ? customerAddress.city : '',
      country: customerAddress ? customerAddress.country : '',
      defaultProductId: '',
      productId: '',
      services: [],
      installations: [
        {
          meter: null,
          mutationDateTime: clone(today),
          serviceLocationId: '',
          propertyGroupId: '',
          addressInstalled: null,
          channelTemplates: [] as ExternalChannelType[],
          measurements: [] as AddMeasurementRequestType[]
        }
      ],
      projectId: '',
      externalId: '',
      propertyGroup: undefined,
      ownerId: '',
      managerId: '',
      locationType: 'location',
      didSubmitBefore: false,
      areMetersValid: false,
      areMeasurementsValid: false,
      numberInstallSuccess: 0,
      numberMeasurementsSuccess: 0,
      installSuccesses: [],
      measurementSuccesses: []
    },
    validate: () => {
      const feedback: string[][] = [];

      if (!values.streetName || !values.streetNumber || !values.city || !values.postalCode || !values.country) {
        extendMultiValue(feedback, startingIndex + 0, getTranslation('location.validation.address'));
      }

      if (!values.services || (values.services && values.services.length === 0)) {
        extendMultiValue(feedback, startingIndex + 2, getTranslation('location.validation.services'));
      }

      // Only check this if installations are added
      if (values.installations.length > 0 && values.installations[0].meter !== null) {
        values.installations.forEach((installation) => {
          if (installation.meter === null) {
            extendMultiValue(feedback, startingIndex + 3, getTranslation('install_meter.validation.meter'));
          }

          if (installation.measurements && installation.measurements.length > 0) {
            installation.measurements.forEach((measurement) => {
              if (!measurement.channelId) {
                extendMultiValue(feedback, startingIndex + 3, getTranslation('install_meter.validation.channel_id'));
              }
              if (typeof measurement.value === 'undefined') {
                extendMultiValue(feedback, startingIndex + 3, getTranslation('install_meter.validation.channel_value'));
              }
              if (measurement.endDateTime) {
                if (moment(measurement.endDateTime).isBefore(installation.mutationDateTime)) {
                  extendMultiValue(feedback, startingIndex + 3, getTranslation('install_meter.validation.date'));
                }
              }
            });
          }
        });
      }

      handleApiErrors(feedback, []);

      return { feedback };
    }
  });

  const installingMeters = values.installations[0] && !!values.installations[0].meter;

  useEffect(() => {
    if (values.location && values.location.id && installingMeters) {
      submitInstallationsAndMeasurements(values, setValue).catch((e) => {
        notify.error({
          content: getTranslation('install_meter.install_meter_fail'),
          error: e
        });
      });
    }
  }, [values.location]);

  useEffect(() => {
    // If installations are submitted
    if (installingMeters) {
      // Notify measurements
      if (values.numberMeasurementsSuccess > 0) {
        notify.success({
          content:
            values.numberMeasurementsSuccess > 1
              ? getTranslation('actions.meter.add_measurements_success')
              : getTranslation('actions.meter.add_measurement_success')
        });
      }
      if (values.areMetersValid && values.areMeasurementsValid && apiErrors.length === 0 && values.location) {
        setNewLocation ? setNewLocation(values.location) : navigate(`${rootUrl}/locations/${values.location.id}`);
      }
    } else {
      if (apiErrors.length === 0 && values.location !== null) {
        setNewLocation ? setNewLocation(values.location) : navigate(`${rootUrl}/locations/${values.location.id}`);
      }
    }
  }, [apiErrors, values.location, values.areMeasurementsValid, values.numberInstallSuccess]);

  const handleFocus = (step: string) => {
    return () => onFocusStep(step);
  };

  const dispatchServiceValue = (uType: utilityType) => {
    if (values.services.find((x) => x.utilityType === uType)) {
      const valueClone = values.services.filter((x) => x.utilityType !== uType);

      setValue({ services: valueClone });
    } else {
      const selectedService = {
        utilityType: uType,
        externalIdentifier: ''
      };

      setValue({ services: [...values.services, selectedService] });
    }
  };

  const dispatchServiceIdentifier = (utility: utilityType, identifier: string) => {
    const selectedService = {
      utilityType: utility,
      externalIdentifier: identifier
    };

    const indexOf = values.services.findIndex((x) => x.utilityType === utility);
    const valueClone = values.services;
    valueClone[indexOf] = selectedService;

    setValue({ services: valueClone });
  };

  const handleSubmit = submitFactory(async () => {
    let response = values.location;
    setApiErrors([]);

    const managementRelations: Array<UpdateManagementRelation> = [];

    if (values.ownerId) {
      managementRelations.push({
        customerId: values.ownerId,
        managementRelationType: managementRelationType.owner
      });
    }

    if (values.managerId) {
      managementRelations.push({
        customerId: values.managerId,
        managementRelationType: managementRelationType.propertymanager
      });
    }

    try {
      if (!response) {
        const apiFriendlyValues: CreateServiceLocationRequestType = {
          address: {
            streetName: values.streetName,
            streetNumber: values.streetNumber,
            streetNumberAddition: values.streetNumberAddition,
            postalCode: values.postalCode,
            city: values.city,
            country: values.country
          },
          externalId: values.externalId,
          productId: values.productId,
          services: values.services.map((service) => {
            return {
              utilityType: service.utilityType,
              externalIdentifier: service.externalIdentifier
            };
          }),
          managementRelations
        };

        response = (
          await sendRequest<ServiceLocationType>({
            request: {
              method: METHODS.POST,
              endpoint: '/md/serviceLocations',
              data: apiFriendlyValues
            },
            tenantReducer,
            lang: i18n.lang
          })
        ).data;

        if (response && values.propertyGroup) {
          await updateServiceLocations(
            [...values.propertyGroup.serviceLocationIds, response.id],
            values.propertyGroup.id,
            tenantReducer,
            i18n.lang
          );
        }

        setValue({ location: response });

        notify.success({
          content: i18n.getTranslation('location.notify_location_created')
        });

        // Only navigate when this wizard is not embedded, else pass the new location
        setNewLocation ? setNewLocation(response) : navigate(`${rootUrl}/locations/${response.id}`);
      }
    } catch (e) {
      //@ts-ignore
      const apiErrors_ = e.data && e.data.errors ? e.data.errors : [];
      handleApiErrors([], apiErrors_);
      setApiErrors(apiErrors_);
    }
  });

  const setMeasurementSuccesses = (successes: boolean[]) => {
    setValue({ measurementSuccesses: successes });
  };

  const getLocation = () => {
    let location = '______ _______';
    if (
      (values.streetName || values.streetNumber || values.streetNumberAddition) &&
      !values.postalCode &&
      !values.city &&
      !values.country
    ) {
      location = `'${values.streetName} ${values.streetNumber}${
        values.streetNumberAddition ? ' ' + values.streetNumberAddition : ''
      }, _______'`;
    } else if (
      (values.postalCode || values.city || values.country) &&
      !values.streetName &&
      !values.streetNumber &&
      !values.streetNumberAddition
    ) {
      location = `'_______, ${values.city} ${values.postalCode} ${values.country.toUpperCase()}'`;
    } else if (
      (values.postalCode || values.city || values.country) &&
      (values.streetName || values.streetNumber || values.streetNumberAddition)
    ) {
      location = `'${values.streetName} ${values.streetNumber}${
        values.streetNumberAddition ? ' ' + values.streetNumberAddition : ''
      }, ${values.city} ${values.postalCode}, ${values.country ? values.country.toUpperCase() : ''}'`;
    }

    return { location };
  };

  return (
    <>
      <AddressSection values={values} setValue={setValue} handleFocus={handleFocus} />

      <DefaultConfigSection values={values} setValue={setValue} handleFocus={handleFocus} />

      <WizardSection disabled={!!values.location}>
        <WizardHeader>
          <StepAnchor name={STEP_NAMES[2]} />
          {i18n.getTranslation('location.steps.services')}
        </WizardHeader>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('location.eligible_services', getLocation())}
        </Heading>
        <InputContainer>
          {enumReducer
            .getEnum<utilityType>('utilityType')
            .filter((service) => service.value !== 'none')
            .map((service) => {
              const foundService = values.services.find((x) => x.utilityType === service.value);
              const serviceText = enumReducer.getTranslation('utilityType', service.value);

              return (
                <div
                  id={`ServiceTypeDiv-${service.value}`}
                  key={`ServiceTypeDiv-${service.value}`}
                  className={css['type-input-wrapper']}
                >
                  <Select
                    id={`ServiceType-${service.value}`}
                    type="small"
                    onChange={() => dispatchServiceValue(service.value)}
                    selectedValues={[foundService ? service.value : ('' as utilityType)]}
                    values={[
                      {
                        icon: service.value,
                        value: service.value,
                        text: serviceText
                      }
                    ]}
                  />
                  {foundService && (
                    <WizardInputField
                      key={`ExternalIdentifier-${service.value}`}
                      id={`ExternalIdentifier-${service.value}`}
                      onChange={(value) => dispatchServiceIdentifier(service.value, value)}
                      value={foundService ? foundService.externalIdentifier : ''}
                      placeholder={i18n.getTranslation('location.external_identifier_service', {
                        value: serviceText.toLowerCase()
                      })}
                      className={css['input']}
                      onFocus={handleFocus(STEP_NAMES[2])}
                    />
                  )}
                </div>
              );
            })}
        </InputContainer>
      </WizardSection>
      {setCreateNewMeter && createNewMeter !== undefined && (
        <WizardSection>
          <WizardHeader>
            <StepAnchor name={STEP_NAMES[3]} />
            {i18n.getTranslation('location.steps.meters')}
          </WizardHeader>
          <InstallMeterSection
            key="meterSection"
            createNewMeter={createNewMeter}
            locationType="location"
            measurementSuccesses={values.measurementSuccesses}
            setCreateNewMeter={setCreateNewMeter}
            setMeasurementSuccesses={setMeasurementSuccesses}
            onFocusStep={onFocusStep}
            setInstallations={(installations) => setValue({ installations })}
            setFeedback={setFeedback}
          />
        </WizardSection>
      )}

      <WizardSubmitButton
        id="location-submit"
        onClick={handleSubmit}
        disabled={errors.feedback.length > 0 && feedback.length > 0}
      >
        {i18n.getTranslation('general.submit')}
      </WizardSubmitButton>
    </>
  );
};

export default observer(LocationWizard);
