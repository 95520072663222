import { NavigatorType } from 'app-context/hooks/use-selection-navigator';
import CustomersInGroupList from 'features/customer/customer-group/list-page/CustomersInGroupList';
import { observer } from 'mobx-react';
import React from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { CustomerGroupDetail } from '@zf/api-types/master-data/customer';

import useRefresh from '../../../../app-context/hooks/use-refresh';
import RouteWrapper from '../../../../components/RouteWrapper';
import GenericTabContainer from '../../../../components/Tab/GenericTabContainer';
import { useStore } from '../../../../hooks/useStore';
import Route from '../../../route';
import CustomerGroupsActions from './detail/CustomerGroupsActions';

type Props = {
  customerGroupDetail: CustomerGroupDetail;
  setCustomerGroupDetail: React.Dispatch<React.SetStateAction<CustomerGroupDetail | null>>;
  navigate: (url: string) => void;
  urlParams?: { [key: string]: string };
  location: Location;
  navigator?: NavigatorType;
};

const CustomerGroupDetailTabContainer = (props: Props) => {
  const { navigate, customerGroupDetail, location, navigator, setCustomerGroupDetail } = props;
  const { refresh, timeStamp } = useRefresh();

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const tabItems = [
    {
      id: 'detail',
      title: getTranslation('nav_detail.detail'),
      isDefault: true
    }
  ];

  const setCustomerGroupName = (name: string) => {
    setCustomerGroupDetail({ ...customerGroupDetail, name });
  };

  return (
    <GenericTabContainer
      entityId={customerGroupDetail.name}
      entityType={routedEntitySubjectType.customergroups}
      iconName={'customer-group'}
      navigate={navigate}
      tabItems={tabItems}
      navigator={navigator}
      actions={
        <CustomerGroupsActions refresh={refresh} location={location} customerGroupDetail={customerGroupDetail} />
      }
      routes={
        <RouteWrapper default>
          <Route
            Component={CustomersInGroupList}
            path="detail"
            default
            timeStamp={timeStamp}
            setCustomerGroupName={setCustomerGroupName}
            customerGroupDetail={customerGroupDetail}
          />
        </RouteWrapper>
      }
    />
  );
};

export default observer(CustomerGroupDetailTabContainer);
