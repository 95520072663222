import React from 'react';
import { MutingRuleAggregatorDTO, MutingRuleType } from '@zf/api-types/master-data/meter';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import MutingRule from 'design-system/Components/MutingRule/MutingRule';
import { EmptyState } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { UseCase } from './UseCase';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import css from './muting-rules.module.scss';

export const MutingRules = ({
  mutingRules,
  expired,
  onCancel,
  useCase
}: {
  mutingRules: MutingRuleType[] | MutingRuleAggregatorDTO[];
  expired: boolean;
  useCase: UseCase;
  onCancel: <T>(params: T, useCase: UseCase) => void;
}) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      {mutingRules?.length > 0 ? (
        <>
          {mutingRules.map((data) => (
            <>
              <CardItem width="12" key={data.id}>
                <MutingRule useCase={useCase} expired={expired} {...data} onCancel={() => onCancel(data, useCase)} />
              </CardItem>
              <CardItem width="12" className={css[useCase === 'rule-dialog' ? 'divider' : '']}>
                <HorizontalDivider />
              </CardItem>
            </>
          ))}
        </>
      ) : (
        <CardItem width="12">
          <EmptyState
            imgName="activity"
            title={getTranslation('meter_issues.no_muting_rules')}
            description={getTranslation('meter_issues.no_muting_rules_desc')}
            horizontal
          />
        </CardItem>
      )}
    </>
  );
};
