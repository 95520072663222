import moment from 'moment';
import { useContext, useEffect } from 'react';

import { useAppContext } from '../';
import { SharedEntityProperties } from '../../../../api-types/entity';
import { SortStateType } from '../../hooks/useCreateRequest';
import useInfiniAPI, { RowTypeBase } from '../../hooks/useInfiniAPI';
import useListPageCreateRequest from '../../hooks/useListPageCreateRequest';
import useLocalRefresh from '../../hooks/useLocalRefresh';
import { getRowsForIds } from '../../utils/action';
import { DispatchListPageState } from '../app-state';

type Props<C, E, R, Q> = {
  context: C;
  endpoint: string;
  defaultQueryParams?: Q;
  mock?: boolean;
  processRecord: (value: E) => R;
};

const useListPageContext = <C, E extends SharedEntityProperties, R extends RowTypeBase, Q = {}>(
  props: Props<C, E, R, Q>
) => {
  const { processRecord, endpoint, defaultQueryParams = {} as Q, mock = false, context } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { store, setListPage, setFilter } = useContext(context as any) as any;

  const { request, timeStamp } = useListPageCreateRequest<Q>(
    endpoint,
    { queryParams: { ...defaultQueryParams }, ...store.filter }, //TODO in Technical Debt
    store.listPage.sort,
    defaultQueryParams
  );

  const { timeStamp: localTimeStamp, refresh: localRefresh } = useLocalRefresh();

  const updateListPage = (action: DispatchListPageState) => {
    const newListPage = { ...store.listPage, ...action };
    const newCurrent = { ...store, listPage: newListPage };
    setListPage(newCurrent.listPage);
  };

  const setSelectedIds = (ids: string[]) => {
    const allIds = rows.reduce((acc: string[], row: R) => {
      if (row.__id) acc.push(row.__id);
      return acc;
    }, []);

    const selectedRows = getRowsForIds(rows, ids);

    updateListPage({
      selectedIds: ids,
      selectedRows,
      rows,
      activatedRows: store.listPage.activatedRows,
      showSidePanel: ids.length > 0,
      showOnActivate: false,
      aggregateDetails,
      allIds
    });
  };

  const {
    loading,
    error,
    rows,
    setStopIndex,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    sortableFields,
    updateGivenRows,
    aggregateDetails
  } = useInfiniAPI<E, R>({
    request,
    tenantReducer,
    lang: i18n.lang,
    mock,
    processRecord,
    onSelectRow: setSelectedIds,
    refreshKey: store.listPage?.state?.refreshKey
  });

  useEffect(() => {
    const { listPage, filter } = store;
    if (listPage.updatedRows && listPage.updatedRows.length > 0) {
      updateGivenRows(listPage.updatedRows, []).then((newRows) => {
        setListPage({ ...listPage, rows: newRows, updatedRows: [], activatedRows: store.listPage.activatedRows }); // Reset to block the effect
        setFilter({
          ...filter,
          queryParams: { ...filter.queryParams, refreshTimestamp: moment().toISOString() }
        }); // Refresh overviewCounts
      });
    }

    if (listPage.deletedRows && listPage.deletedRows.length > 0) {
      updateGivenRows([], listPage.deletedRows).then((newRows) => {
        setListPage({ ...listPage, rows: newRows, deleted: [] }); // Reset to block the effect
        setFilter({
          ...filter,
          queryParams: { ...filter.queryParams, refreshTimestamp: moment().toISOString() }
        }); // Refresh overviewCounts
      });
    }
  }, [store.listPage.updatedRows, store.listPage.deletedRows]);

  useEffect(() => {
    if (!selectAllBusy) {
      if (rows.length > 0 || rows.length === 0) {
        const allIds = rows.reduce((acc: string[], row: R) => {
          if (row.__id) acc.push(row.__id);
          return acc;
        }, []);

        // Check if there are ids in selectedIds which aren't in allIds anymore
        const newSelection = store.listPage.selectedIds.reduce((acc: string[], id: string) => {
          if (allIds.includes(id)) acc.push(id);
          return acc;
        }, []);
        // If we found ids
        if (newSelection.length !== store.listPage.selectedIds.length) {
          updateListPage({
            allIds,
            rows,
            aggregateDetails,
            selectedIds: newSelection,
            selectedRows: getRowsForIds(rows, newSelection),
            activatedRows: store.listPage.activatedRows
          });
        } else if (rows.length > 0) {
          updateListPage({
            allIds,
            rows,
            aggregateDetails,
            selectedRows: getRowsForIds(rows, newSelection),
            activatedRows: store.listPage.activatedRows
          });
        }
        localRefresh();
      }
    }
  }, [rows, selectAllBusy]);

  const handleSort = (sortParams: SortStateType) => {
    const { sortBy, sortDirection } = sortParams;
    updateListPage({ sort: { sortBy, sortDirection } });
  };

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds: store.listPage.selectedIds,
    activatedRows: store.listPage.activatedRows,
    sortBy: store.listPage.sort.sortBy,
    sortDirection: store.listPage.sort.sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    handleSort,
    setSelectedIds,
    setStopIndex,
    timeStamp,
    localTimeStamp,
    aggregateDetails
  };
};

export default useListPageContext;
