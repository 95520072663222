import { moveOutType } from 'app-context/stores/master-data/contracts/wizard/move-out/MoveOutWizardBaseStore';
import { moveOutWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-out/MoveOutWizardStore';
import { removeLocationsWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-out/RemoveLocationsWizardStore';
import { TabSlider, WizardSectionHeader } from 'design-system/Components';
import { SelectionTabItemType } from 'design-system/Components/TabSlider/Tab';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import RemoveLocations from '../../remove-locations-contract-wizard/RemoveLocations';
import TerminateContract from '../../terminate-contract-wizard/TerminateContract';
import css from './move-out-section.module.scss';

/**
 * This part is shared in the terminate contract flow and the remove locations from existing contract flow
 */
const MoveOutSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    moveOutType: moveOutType_,
    moveOutWizardStore,
    removeLocationsWizardStore,
    setMoveOutType
  } = contractStore.moveOutWizardBaseStore;
  const { moveOutWizardNavigator } = moveOutWizardStore;
  const { removeLocationsWizardNavigator } = removeLocationsWizardStore;

  const tabItems = useRef<SelectionTabItemType<any>[]>([
    {
      id: moveOutType.terminatecontract,
      icon: 'cross',
      title: getTranslation('contracts.wizard.terminate.terminate_contract')
    },
    {
      id: moveOutType.removelocations,
      icon: 'locations',
      title: getTranslation('contracts.wizard.terminate.remove_locations')
    }
  ]);

  const isTerminate = moveOutType_ === moveOutType.terminatecontract;

  const handleSetActiveStep = () => {
    if (isTerminate) {
      moveOutWizardNavigator.setActiveStep(moveOutWizardSteps.moveout);
    } else {
      removeLocationsWizardNavigator.setActiveStep(removeLocationsWizardSteps.moveout);
    }
  };

  return (
    <WizardSection id="move-out-section" setActiveStep={handleSetActiveStep}>
      <WizardSectionHeader
        id={moveOutWizardSteps.moveout}
        text={getTranslation('contracts.wizard.terminate.steps.move_out')}
      />

      <TabSlider
        id="move-out-type"
        className={css['move-out-type']}
        tabItems={tabItems.current}
        selectedTabItem={moveOutType_}
        setSelectedItem={(moveOutType_) => setMoveOutType(moveOutType_ as moveOutType)}
        textAlign="left"
        fullWidth
      />

      {isTerminate ? <TerminateContract /> : <RemoveLocations />}
    </WizardSection>
  );
};

export default observer(MoveOutSection);
