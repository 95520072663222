import React from 'react';

import { invoiceFrequency } from '@zf/api-types/enums';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import DropDownDetails from '@zf/stella-react/src/atoms/Dropdown/DropDownDetails';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context/app-context';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import { formatAddress } from '../../utils/address';
import IconLabel from '../Icon/IconLabel';
import { Link } from '../../design-system/Components';

type Props = {
  group: PropertyGroupType;
};

export default function PropertyGroupDropDownDetails(props: Props) {
  const { group } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const response = useSuspenseSingleAPI<PropertyGroupBillingConfigurationType>({
    request: {
      endpoint: `/bill/PropertyGroupBillingConfiguration/${group.id}`
    }
  });

  if (!response.result) return null;

  const billingConfig = response.result.data;

  return (
    <DropDownDetails>
      <div>
        <IconLabel labelText={group.name} iconType="propertygroup" />
        <Link id={`property-${group.id}`} url={`${rootUrl}/property-groups/${group.id}`} newTab>
          {formatAddress(group.address, enumReducer.getTranslation)}
        </Link>
      </div>

      <div>
        <IconLabel labelText={i18n.getTranslation('property_groups.amountOfServiceLocations')} iconType="location" />
        <Paragraph>{group.serviceLocationIds.length}</Paragraph>
      </div>

      {billingConfig.advanceFrequency && (
        <div>
          <Label>{i18n.getTranslation('property_groups.advanceFrequency')}</Label>
          <Paragraph>{enumReducer.getTranslation('advanceFrequency', billingConfig.advanceFrequency)}</Paragraph>
        </div>
      )}

      {billingConfig.invoiceFrequency && (
        <>
          <div>
            <Label>{i18n.getTranslation('property_groups.invoiceFrequency')}</Label>
            <Paragraph>{enumReducer.getTranslation('invoiceFrequency', billingConfig.invoiceFrequency)}</Paragraph>
          </div>
          {billingConfig.invoiceFrequency === invoiceFrequency.yearly ? (
            <div>
              <Label>{i18n.getTranslation('property_groups.invoice_day_month')}</Label>
              <Paragraph>{`${billingConfig.invoiceDay} / ${billingConfig.invoiceMonth}`}</Paragraph>
            </div>
          ) : (
            <div>
              <Label>{i18n.getTranslation('property_groups.invoice_day')}</Label>
              <Paragraph>{billingConfig.invoiceDay}</Paragraph>
            </div>
          )}
        </>
      )}
    </DropDownDetails>
  );
}
