import { observer } from 'mobx-react';
import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';

import useBrowserTitle from '../../../../../app-context/hooks/use-browser-title';
import { NavigatorType } from '../../../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../../../components/RouteWrapper';
import GenericTabContainer from '../../../../../components/Tab/GenericTabContainer';
import { useStore } from '../../../../../hooks/useStore';
import Route from '../../../../route';
import IncomingBankingTransactionDetails from './detail/IncomingBankingTransactionDetails';
import IncomingBankingTransactionActions from './IncomingBankingTransactionActions';

type Props = {
  incomingBankingTransaction: IncomingBankingTransactionType;
  status: JSX.Element;
  navigator: NavigatorType;
  domain?: routedEntitySubjectType;
  setIncomingBankingTransaction: React.Dispatch<React.SetStateAction<IncomingBankingTransactionType | null>>;
  navigate: (url: string) => void;
  refresh: () => void;
};

const IncomingBankingTransactionTabContainer = (props: Props) => {
  const { status, incomingBankingTransaction, navigator, domain, navigate, setIncomingBankingTransaction, refresh } =
    props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const title = `${getTranslation('incoming_banking_transactions.bank_statement')}: ${
    incomingBankingTransaction.identification
  }`;

  useBrowserTitle(`${getTranslation('incoming_banking_transactions.incoming_banking_transaction')} - ${title}`);

  return (
    <GenericTabContainer
      actions={
        <IncomingBankingTransactionActions
          incomingBankingTransaction={incomingBankingTransaction}
          setIncomingBankingTransaction={setIncomingBankingTransaction}
        />
      }
      navigator={navigator}
      entityId={title}
      subTitle={incomingBankingTransaction.fileName}
      status={status}
      entityType={domain ? domain : routedEntitySubjectType.incomingbankingtransaction}
      iconName="batch"
      navigate={navigate}
      tabItems={[
        {
          id: 'details',
          title: getTranslation('nav_detail.detail'),
          isDefault: true
        }
      ]}
      routes={
        <RouteWrapper default>
          <Route
            Component={IncomingBankingTransactionDetails}
            path="details"
            incomingBankingTransaction={incomingBankingTransaction}
            refresh={refresh}
            default
          />
        </RouteWrapper>
      }
    />
  );
};

export default observer(IncomingBankingTransactionTabContainer);
