import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import { useStore } from '../../../hooks/useStore';
import BillingTariffs from '../shared/tariff-column/BillingTariffs';
import css from './billing-items-tab.module.scss';
import BillingItems from './BillingItems';

export type Props = {
  billingItemName?: string;
};
const BillingItemsTab = (props: Props) => {
  const { productConfigStore } = useStore();
  const { isPanelViewMode, onlyShowTariffs, billingItemsStore } = productConfigStore;
  const {
    tariffs_,
    selectedBillingItem,
    initTariffs,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    deleteTariff,
    deleteTariffInlist
  } = billingItemsStore;

  return (
    <ColumnsContainer className={classNames(css['billing-items-config'], { [css['full-space']]: isPanelViewMode })}>
      {!onlyShowTariffs && <BillingItems />}
      {!isPanelViewMode && (
        <BillingTariffs
          isProductPage={false}
          tariffs_={tariffs_}
          selectedBillingItem={selectedBillingItem}
          initTariffs={initTariffs}
          showActionsOnDefault
          addTariff={addTariff}
          updateTariff={updateTariff}
          addTariffToList={addTariffToList}
          updateTariffInList={updateTariffInList}
          deleteTariff={deleteTariff}
          deleteTariffInlist={deleteTariffInlist}
        />
      )}
    </ColumnsContainer>
  );
};

export default observer(BillingItemsTab);
