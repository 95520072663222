import React from 'react';

import { contactType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatPhoneNumber } from '@zf/utils/src/number';

import { Link } from '../../design-system/Components';
import css from './contact-detail.module.scss';

type Props = {
  contactDetail: ContactEntryType;
};

export default function ContactDetail(props: Props) {
  const { contactDetail } = props;

  if (
    contactDetail.contactType === contactType.mobiletelephone ||
    contactDetail.contactType === contactType.telephone
  ) {
    const formattedNumber = formatPhoneNumber(contactDetail.value);
    return <Paragraph>{formattedNumber || contactDetail.value}</Paragraph>;
  }

  if (contactDetail.contactType === contactType.website) {
    // Anchor doesn't go to external page when no http prefix
    let url = contactDetail.value;

    if (!contactDetail.value.startsWith('http')) {
      url = 'http://' + contactDetail.value;
    }

    return (
      <Link id={`contact-detail-${contactDetail.value}`} className={css['link-text']} url={url} newTab>
        {contactDetail.value}
      </Link>
    );
  }

  return <Paragraph className={css['contact-detail']}>{contactDetail.value}</Paragraph>;
}
