import React, { useState } from 'react';

import DropdownBodyWithoutInput from '@zf/stella-react/src/atoms/Dropdown/DropdownBodyWithoutInput';

import css from './base-bare-dropdown.module.scss';
import { DropdownGenericBaseType, DropdownProps } from './types';

export default function BaseDropdownLabel<T extends DropdownGenericBaseType>(props: DropdownProps<T>) {
  const { label, details, dropdownTools, style, colorized = false, setDropdownTools = () => {} } = props;

  // State variables which are only used by regular dropdowns (without details), otherwise dropdownTools is used
  const [showTooltip, setShowTooltip] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(undefined);

  const handleOpen = () => (dropdownTools ? setDropdownTools({ showTooltip: true }) : setShowTooltip(true));

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div className={css['dropdown-container']} style={style} onFocus={handleOpen} role="tooltip">
      <DropdownBodyWithoutInput
        {...props}
        label={label}
        details={details}
        showTooltip={dropdownTools ? dropdownTools.showTooltip : showTooltip}
        searchValue={dropdownTools?.searchValue || searchValue}
        focusedIndex={dropdownTools ? dropdownTools.focusedIndex : focusedIndex}
        setSearchValue={(val) => (dropdownTools ? setDropdownTools({ searchValue: val }) : setSearchValue(val))}
        setShowTooltip={(val) => (dropdownTools ? setDropdownTools({ showTooltip: val }) : setShowTooltip(val))}
        setFocusedIndex={(newIndex) =>
          dropdownTools ? setDropdownTools({ focusedIndex: newIndex }) : setFocusedIndex(newIndex)
        }
        handleOpen={handleOpen}
        colorized={colorized}
      />
    </div>
  );
}
