import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import EditTariffPeriodDialog from '../dialogs/EditTariffPeriodDialog';
import TreeNode from '../models/TreeNode';

type Props = {
  treeNode: TreeNode;
};

function EditTariffPeriodListItem(props: Props) {
  const { treeNode } = props;
  const { applicationStore } = useStore();

  const { getTranslation } = applicationStore;
  const { onSubmit, clickRef, validationRef } = useDialog();

  return (
    <DropdownAction
      id="tariff.change-tariff-period"
      icon="sign"
      dialogTitle={getTranslation('general.edit')}
      dialogContent={<EditTariffPeriodDialog treeNode={treeNode} ref={clickRef} />}
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={getTranslation('general.edit')}
    />
  );
}

export default observer(EditTariffPeriodListItem);
