import React from 'react';

import { AdvancePeriodType } from '@zf/api-types/billing/billing-completeness';

import { useStore } from '../../hooks/useStore';
import Advance from './advance';
import css from './advances.module.scss';

type Props = {
  advancePeriods: AdvancePeriodType[];
  tooltipFor: string;
};

export default React.memo(function Advances(props: Props) {
  const { advancePeriods, tooltipFor } = props;

  const { contractStore } = useStore();
  const { currentAdvancesCount, totalAdvancesCount } =
    contractStore.billingCompletenessStore.getAdvancesCompleteness(advancePeriods);

  return (
    <div className={css['color-boxes']}>
      <div className={css['progress']}>{`${currentAdvancesCount}/${totalAdvancesCount}`}</div>
      {advancePeriods.map((ap, index) => {
        return <Advance key={index} ap={ap} tooltipFor={tooltipFor} />;
      })}
    </div>
  );
});
