import { observer } from 'mobx-react';
import React from 'react';

import {
  IncomingMutationType,
  IncomingPaymentRequestMutationConfirmationDetailsType
} from '@zf/api-types/billing/incoming-mutations';
import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { Link } from '../../../../../../../design-system/Components';
import { DetailLine, InfoGrid } from '../../../../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../../hooks/useStore';

type Props = {
  incomingMutation: IncomingMutationType;
};

const RequestMutationConfirmationDetails = (props: Props) => {
  const { incomingMutation } = props;

  const { applicationStore } = useStore();
  const { culture, rootUrl, getTranslation, getEnumTranslation } = applicationStore;

  const castedDetails = incomingMutation.details as IncomingPaymentRequestMutationConfirmationDetailsType;

  const type = getEnumTranslation('outgoingBankingTransactionType', outgoingBankingTransactionType.paymentrequests);

  return (
    <InfoGrid type="grey">
      <DetailLine label={getTranslation('incoming_mutations.reference')}>
        {castedDetails.matchingOutgoingBankingTransactionIdentification ? (
          <Link
            id={`outgoing-${castedDetails.matchingOutgoingBankingTransactionId}`}
            url={`${rootUrl}/payments/outgoing-banking-transactions/${castedDetails.matchingOutgoingBankingTransactionId}`}
          >
            {`${type}: ${castedDetails.matchingOutgoingBankingTransactionIdentification}`}
          </Link>
        ) : (
          <Paragraph>{''}</Paragraph>
        )}
      </DetailLine>
      <DetailLine label={getTranslation('incoming_mutations.incoming_matching_account_holder')}>
        <Paragraph>{castedDetails.accountHolder}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('banking_transaction.bank_account')}>
        <Paragraph>{castedDetails.iban}</Paragraph>
      </DetailLine>

      <DetailLine label={getTranslation('banking_transaction.mutationDate')}>
        <Paragraph>{formatDate(incomingMutation.transactionDate)}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('general.amount_valuta')}>
        <Paragraph>{formatMoney(incomingMutation.amount, culture)}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('general.description')}>
        <Paragraph>{castedDetails.description}</Paragraph>
      </DetailLine>
    </InfoGrid>
  );
};

export default observer(RequestMutationConfirmationDetails);
