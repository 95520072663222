import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { OutgoingMutationRowType } from '@zf/api-types/billing/outgoing-mutations';
import { outgoingBankingTransactionStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import EntryDialog from '../../../../../../../actions/outgoing-mutation/EntryDialog';
import { useAppContext } from '../../../../../../../app-context';
import ConfirmationActionButton from '../../../../../../../components/Button/ConfirmationActionButton';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  selectedRows: OutgoingMutationRowType[];
  refresh: () => void;
};

export default function AddEntryDetailButton(props: Props) {
  const { selectedRows, outgoingBankingTransaction, refresh } = props;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const isDisabled =
    outgoingBankingTransaction.status === outgoingBankingTransactionStatus.toconfirmbybank ||
    outgoingBankingTransaction.status === outgoingBankingTransactionStatus.closed;

  return (
    <ConfirmationActionButton
      id="outgoing_banking_transaction.add_entry"
      icon="plus"
      dialogTitle={i18n.getTranslation('actions.outgoing_mutation.add_entry')}
      dialogContent={
        <EntryDialog
          ref={clickRef}
          validationRef={validationRef}
          outgoingBankingTransaction={outgoingBankingTransaction}
          refresh={refresh}
        />
      }
      onSubmit={onSubmit}
      disabled={selectedRows.length > 0 || isDisabled}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.add')}
    />
  );
}
