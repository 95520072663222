import { BareDropdown } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { PropertyGroupReference } from '@zf/api-types/master-data/property-group';

import { Query } from '../../list-page/types';

const PropertyGroupsFilter = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<Partial<Query>>;
  setValue: UseFormSetValue<Partial<Query>>;
}) => {
  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;

  const { getPropertygroupsWithIssues } = meterStore.meteringIssueService;

  const [properyGroupsValues, setPropertyGroupsValues] = useState<PropertyGroupReference[]>([]);

  useEffect(() => {
    getPropertygroupsWithIssues().then((res) => {
      setPropertyGroupsValues(res.results);
    });
  }, []);

  return (
    <BareDropdown
      id="dropdown-propertyGroupId"
      label={getTranslation('property_groups.property_group')}
      selectedValues={getValues('propertyGroupIds') as any}
      values={properyGroupsValues.map(({ id, name }: any) => ({ id, text: name, value: id }))}
      onChange={(val) => {
        const propertyGroupsNames = properyGroupsValues.reduce((acc: string[], pg) => {
          if (val.includes(pg.id)) {
            acc.push(pg.name);
          }

          return acc;
        }, []);
        const propertyGroupsIds = properyGroupsValues.reduce((acc: string[], pg) => {
          if (val.includes(pg.id)) {
            acc.push(pg.id);
          }

          return acc;
        }, []);

        setValue('propertyGroupIds', propertyGroupsIds);
        setValue('propertyGroupNames', propertyGroupsNames);
      }}
      isMultipleSelected
    />
  );
};

export default PropertyGroupsFilter;
