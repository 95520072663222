import React, { KeyboardEvent, useState } from 'react';

import { List } from '@zf/stella-react/src/atoms/List';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import MoreActionsIcon from '../Icon/MoreActionsIcon';

type DotsOrientation = 'vertical' | 'horizontal';

type Props = {
  id: string;
  actions: JSX.Element[];
  dotsOrientation?: DotsOrientation;
  className?: string;
};

export default function MoreActionsMenu(props: Props) {
  const { id, actions, className, dotsOrientation = 'horizontal' } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      setShowTooltip(false);
    }
  };

  return (
    <div
      className={className}
      onClick={() => setShowTooltip(false)}
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown}
    >
      <TooltipTrigger
        tooltip={<List>{actions}</List>}
        placement="bottom-end"
        trigger="click"
        showTooltip={showTooltip}
        setShowTooltip={setShowTooltip}
      >
        <MoreActionsIcon
          id={id}
          dotsVertical={dotsOrientation === 'vertical'}
          onClick={() => setShowTooltip(!showTooltip)}
        />
      </TooltipTrigger>
    </div>
  );
}
