import React from 'react';

import { useAppContext } from '../../../../app-context';
import ChannelSelectionSection from '../../../../components/graph/filters/ChannelSelectionSection';
import { ICON_COLORS, ICONS } from '../../../../constants/icons';
import useServiceConsumptions from './hooks/useServiceConsumptions';

export default function ConsumptionsChannelSelection() {
  const { enumReducer } = useAppContext();

  const { filterTypes, selectedFilterTypeIds, setSelectedFilterTypeIds } = useServiceConsumptions();

  const tabItems = filterTypes.map((type) => {
    return {
      id: type.utilityType + type.unitOfMeasure,
      title: `${enumReducer.getTranslation('utilityType', type.utilityType)} (${type.unitOfMeasure.toUpperCase()})`,
      icon: ICONS[type.utilityType],
      selectedColor: ICON_COLORS[type.utilityType]
    };
  });

  return (
    <ChannelSelectionSection
      id="channel-selection"
      tabItems={tabItems}
      selectedGraphChannels={selectedFilterTypeIds}
      setSelectedGraphChannel={setSelectedFilterTypeIds}
    />
  );
}
