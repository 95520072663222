import { OptionalMeasurementType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { formatActiveMeterText } from 'utils/meter';

import { MeterType } from '@zf/api-types/master-data/meter';
import { Label } from '@zf/stella-react/src/atoms/Label';

import OptionalMeasurementChannel from './OptionalMeasurementChannel';

type Props = {
  index: number;
  meter: MeterType;
  optionalMeasurement: OptionalMeasurementType;
};

const OptionalMeasurement = (props: Props) => {
  const { index, optionalMeasurement, meter } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      <Label>{`${getTranslation('meter.meter')}: ${formatActiveMeterText(meter)}`}</Label>
      {optionalMeasurement.optionalChannels.map((oChann, index2) => {
        return (
          <OptionalMeasurementChannel
            key={`${oChann.externalChannelIdentifier}-${index}`}
            optionalMeasurementIndex={index}
            optionalChannelIndex={index2}
            optionalChannel={oChann}
          />
        );
      })}
    </>
  );
};

export default observer(OptionalMeasurement);
