import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { customerType, invoiceType, paymentMethod } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatDate, isMinDate, MIN_DATE } from '@zf/utils/src/date';

import DatePicker from '../../../../../../../../components/Lang/DatePicker';
import SimpleDropdown from '../../../../../../../../components/Lang/SimpleDropdown';
import { Link } from '../../../../../../../../design-system/Components';
import { Paragraph } from '../../../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../../../hooks/useStore';
import AdvanceCalculationDetails from './AdvanceCalculationDetails';

export type PropsType = {
  invoice: InvoiceType;
};

const RelatedDetailsCardBody = (props: PropsType) => {
  const { invoice } = props;

  const { applicationStore, invoiceStore } = useStore();
  const { rootUrl, getTranslation, getEnumTranslation, getEnum } = applicationStore;
  const { relatedDetailsForm, billingParameters, contract, billingRelation, billingInsights, getPaymentStatus } =
    invoiceStore;

  if (!relatedDetailsForm) return null;

  const { newPaymentMethod, collectionDate } = relatedDetailsForm._values;

  return (
    <>
      <CardItem width="6">
        <Label>{getTranslation('contracts.contract')}</Label>
      </CardItem>
      <CardItem width="6" justifyContent="end">
        <Link
          id={`contract-${invoice.contractId}`}
          url={`${rootUrl}/contracts/${invoice.contractId}`}
          icon="contract"
          bold
        >
          {invoice.contractNumber}
        </Link>
      </CardItem>
      <CardItem width="6">
        <Label>{getTranslation('invoice.contractor')}</Label>
      </CardItem>
      <CardItem width="6" justifyContent="end">
        <Link
          id={`customer-${invoice.debtor.customerId}`}
          url={`${rootUrl}/customers/${invoice.debtor.customerId}`}
          icon={invoice.debtor.customerType === customerType.person ? 'customer' : 'organisation'}
          bold
        >
          {`${invoice.debtor.customerAccountNumber} - ${invoice.debtor.displayName}`}
        </Link>
      </CardItem>

      <CardItem width="12">
        <HorizontalDivider />
      </CardItem>

      <CardItem width="6">
        <Label>{getTranslation('invoice.payment_method')}</Label>
      </CardItem>
      <CardItem justifyContent="end" width="6">
        {relatedDetailsForm.paymentMethodDisabled ? (
          <Paragraph textAlign="right">{getEnumTranslation('paymentMethod', newPaymentMethod)}</Paragraph>
        ) : (
          <SimpleDropdown
            id="invoice.payment_method"
            onChange={(val) => {
              if (invoice.remainingInvoiceAmount > 0 && val[0] === paymentMethod.sct) {
                relatedDetailsForm._set('collectionDate', MIN_DATE);
                relatedDetailsForm._set('newPaymentMethod', val[0]);
              } else {
                relatedDetailsForm._set('newPaymentMethod', val[0]);
              }
            }}
            values={getEnum<paymentMethod>('paymentMethod')}
            selectedValues={[newPaymentMethod]}
            error={!!relatedDetailsForm._error('newPaymentMethod')}
            singleError={relatedDetailsForm._error('newPaymentMethod')}
          />
        )}
      </CardItem>
      <CardItem width="6">
        <Label>{applicationStore.getTranslation('invoices_list.labels.payment_status')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{getPaymentStatus(invoice).translation}</Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{applicationStore.getTranslation('invoice.payment_reference')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{invoice.paymentDetails.paymentReference}</Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{applicationStore.getTranslation('invoice.collection_date')}</Label>
      </CardItem>
      <CardItem width="6" justifyContent="end">
        {relatedDetailsForm.showCollectionDate ? (
          <DatePicker
            id="invoice.update_collectionDate"
            onChange={(val) => relatedDetailsForm._set('collectionDate', val.toISOString())}
            value={moment(collectionDate)}
            error={!isMinDate(collectionDate) && !moment(collectionDate).isAfter(moment().add(2, 'days'))}
          />
        ) : (
          <Paragraph textAlign="right">{formatDate(collectionDate)}</Paragraph>
        )}
      </CardItem>

      {billingParameters?.enableInvoiceCollection && invoice.collectionDetails && (
        <>
          <CardItem width="6">
            <Label>{getTranslation('collection_case.collection_case')}</Label>
          </CardItem>
          <CardItem justifyContent="end" width="6">
            <Link
              id={`collection-case-${invoice.collectionDetails.collectionCaseId}`}
              url={`${rootUrl}/collection-cases/${invoice.collectionDetails.collectionCaseId}`}
            >
              {invoice.collectionDetails.closed
                ? getTranslation('collection_case.closed')
                : invoice.collectionDetails.workflowName}
            </Link>
          </CardItem>

          <CardItem width="6">
            <Label>{getTranslation('invoice.times_retried')}</Label>
          </CardItem>
          <CardItem width="6">
            <Paragraph textAlign="right">{invoice.paymentDetails.amountOfTimesRetried}</Paragraph>
          </CardItem>

          {invoice.collectionDetails.nextStepName && (
            <>
              <CardItem width="6">
                <Label>{getTranslation('invoices_list.labels.previous_step')}</Label>
              </CardItem>
              <CardItem width="6">
                {invoice.collectionDetails && (
                  <Paragraph textAlign="right">{invoice.collectionDetails.previousStepName}</Paragraph>
                )}
              </CardItem>

              <CardItem width="6">
                <Label>{getTranslation('invoices_list.labels.next_step')}</Label>
              </CardItem>
              <CardItem width="6">
                {invoice.collectionDetails && (
                  <Paragraph textAlign="right">{invoice.collectionDetails.nextStepName}</Paragraph>
                )}
              </CardItem>
            </>
          )}
        </>
      )}

      {invoice.type === invoiceType.invoice &&
        invoice.advanceCalculationDetails &&
        contract &&
        billingRelation &&
        billingInsights && (
          <AdvanceCalculationDetails
            invoice={invoice}
            contract={contract}
            billingRelation={billingRelation}
            billingInsights={billingInsights}
          />
        )}
    </>
  );
};

export default observer(RelatedDetailsCardBody);
