import React from 'react';

import { DomainNotificationType } from '@zf/api-types/general';
import { groupBy, removeDuplicates } from '@zf/utils/src/array';

import { extendMultiValue } from '../../../utils/arrays';
import { getErrorText } from '../../../utils/errors';
import css from './notification-error.module.scss';

type Props = {
  errors: DomainNotificationType[];
};

export default function NotificationError(props: Props) {
  const { errors } = props;

  const feedback: string[][] = [];

  // Fluentvalidation (error per field)
  const groupedByValue = groupBy(errors, 'value');

  Object.keys(groupedByValue).forEach((value) => {
    let groupedErrors: DomainNotificationType[] = groupedByValue[value];

    groupedErrors = removeDuplicates(groupedErrors, 'message');

    groupedErrors.forEach((d) => {
      extendMultiValue(feedback, 0, getErrorText(d));
    });
  });

  return (
    <div className={css['list']}>
      <ul>
        {feedback.map((e) => {
          return e.map((a, index) => {
            return <li key={index + a}>{a}</li>;
          });
        })}
      </ul>
    </div>
  );
}
