import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import SetWaitingDialog from '../../../actions/tasks/SetWaitingDialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  task: MoveRequestType;
  setTask: (updatedTask: MoveRequestType) => void;
};

export default function SetWaitingListItem(props: Props) {
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="tasks.setwaiting"
      icon="clock"
      dialogTitle={i18n.getTranslation('tasks.set_waiting')}
      dialogContent={<SetWaitingDialog {...props} ref={clickRef} validationRef={validationRef} />}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('general.submit')}
      validationRef={validationRef}
    />
  );
}
