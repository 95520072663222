import clone from 'clone';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { navigate } from '@reach/router';
import { LocalAddressType } from '@zf/api-types/general';
import { AddMeasurementRequestType, ExternalChannelType, MeterType } from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType, ServiceType } from '@zf/api-types/master-data/servicelocation';
import useValidator from '@zf/hooks/src/useValidator';
import { StepAnchor } from '@zf/stella-react/src/atoms/Wizard';
import { isMinDate, startOfDay } from '@zf/utils/src/date';

import useWizardAPIErrors from '../../../app-context/hooks/useWizardAPIErrors';
import WizardSubmitButton from '../../../components/Button/WizardSubmitButton';
import { notify } from '../../../events/notification-events';
import { extendMultiValue } from '../../../utils/arrays';
import MeterLocationSection from './MeterLocationSection';
import MeterSection from './MeterSection';
import { useSubmitFunctions } from './useSubmitFunctions';

export type Props = {
  feedback: string[][];
  createNewMeter: boolean;
  meter?: MeterType;
  location?: ServiceLocationType;
  propertyGroup?: PropertyGroupType;
  setFeedback: Dispatch<SetStateAction<string[][]>>;
  setCreateNewMeter: Dispatch<SetStateAction<boolean>>;
  setCreateNewMeasurement: Dispatch<SetStateAction<boolean>>;
  onFocusStep: Dispatch<SetStateAction<string>>;
};

export type InstallationType = {
  meter: MeterType | null;
  mutationDateTime: Moment;
  serviceLocationId: string;
  propertyGroupId: string;
  addressInstalled: LocalAddressType | null;
  channelTemplates: ExternalChannelType[];
  measurements: AddMeasurementRequestType[];
};

export type InstallMeterValidatorValuesType = {
  locationType: 'location' | 'property_group';
  location?: ServiceLocationType | null;
  installations: InstallationType[];
  propertyGroup?: PropertyGroupType;
  services?: ServiceType[];
  didSubmitBefore: boolean;
  areMetersValid: boolean;
  areMeasurementsValid: boolean;
  numberInstallSuccess: number;
  numberMeasurementsSuccess: number;
  installSuccesses: boolean[];
  measurementSuccesses: boolean[];
};

export const STEP_NAMES = [
  'location',
  'select_meter',
  'meter-essentials',
  'meter-channels',
  'meter-errors',
  'add_measurement',
  'add_measurement_errors',
  'install_errors'
];

const ERROR_POSITION = 7;

const InstallMeterWizard = (props: Props) => {
  const {
    feedback,
    meter = null,
    createNewMeter,
    location,
    propertyGroup,
    onFocusStep,
    setFeedback,
    setCreateNewMeter,
    setCreateNewMeasurement
  } = props;

  const { applicationStore, meterStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { installMeter, updateParentRelation } = meterStore.deviceService;
  const { addMeasurementMeter } = meterStore.measurementService;

  const { apiErrors, setApiErrors, handleApiErrors } = useWizardAPIErrors(2, setFeedback);

  const { submitInstallationsAndMeasurements } = useSubmitFunctions(
    addMeasurementMeter,
    installMeter,
    updateParentRelation,
    setApiErrors,
    handleApiErrors
  );

  const [today] = useState(startOfDay(moment()));

  const { values, errors, setValue, submitFactory } = useValidator<InstallMeterValidatorValuesType>({
    initialValues: {
      locationType: propertyGroup ? 'property_group' : 'location',
      location: location ? location : undefined,
      installations: [
        {
          meter: meter,
          mutationDateTime: clone(today),
          serviceLocationId: location ? location.id : '',
          propertyGroupId: propertyGroup ? propertyGroup.id : '',
          addressInstalled: propertyGroup ? propertyGroup.address : location ? location.address : null,
          channelTemplates: [] as ExternalChannelType[],
          measurements: [] as AddMeasurementRequestType[]
        }
      ],
      propertyGroup: propertyGroup ? propertyGroup : undefined,
      didSubmitBefore: false,
      areMetersValid: false,
      areMeasurementsValid: false,
      numberInstallSuccess: 0,
      numberMeasurementsSuccess: 0,
      installSuccesses: [],
      measurementSuccesses: []
    },
    validate: () => {
      const feedback: string[][] = [];

      if (values.locationType === 'property_group' && !values.propertyGroup) {
        extendMultiValue(feedback, 0, getTranslation('install_meter.validation.property'));
      }

      if (values.locationType === 'location' && !values.location) {
        extendMultiValue(feedback, 0, getTranslation('install_meter.validation.location'));
      }

      if (values.installations.length === 0) {
        extendMultiValue(feedback, 1, getTranslation('install_meter.validation.meter'));
      }

      values.installations.forEach((installation) => {
        if (installation.meter === null) {
          extendMultiValue(feedback, 1, getTranslation('install_meter.validation.meter'));
        }

        if (installation.measurements && installation.measurements.length > 0) {
          installation.measurements.forEach((measurement) => {
            if (!measurement.channelId) {
              extendMultiValue(feedback, 2, getTranslation('install_meter.validation.channel_id'));
            }
            if (typeof measurement.value === 'undefined') {
              extendMultiValue(feedback, 2, getTranslation('install_meter.validation.channel_value'));
            }
            if (measurement.endDateTime) {
              if (
                startOfDay(moment(measurement.endDateTime)).isBefore(
                  clone(installation.mutationDateTime).subtract(1, 'days')
                )
              ) {
                extendMultiValue(feedback, 2, getTranslation('install_meter.validation.date'));
              }
            }
          });
        }

        if (isMinDate(installation.mutationDateTime)) {
          extendMultiValue(feedback, 1, getTranslation('install_meter.validation.mutation_date'));
        }
      });

      if (apiErrors.length > 0) {
        const fdbk: string[][] = [...feedback];

        apiErrors.forEach((error) => {
          extendMultiValue(feedback, ERROR_POSITION, error.message);
        });

        setFeedback(fdbk);
      }

      handleApiErrors(feedback, []);

      return { feedback };
    }
  });

  const checkIfMeasurements = () => {
    let result = false;

    values.installations.forEach((i) => {
      if (i.measurements.length !== 0) {
        result = true;
      }
    });

    return result;
  };

  useEffect(() => {
    if (
      values.areMetersValid &&
      values.areMeasurementsValid &&
      ((errors.feedback.length === 0 && feedback.length === 0) ||
        (errors.feedback.length > 0 && feedback.length === 0)) &&
      (values.location || values.propertyGroup)
    ) {
      // Notify measurements
      if (checkIfMeasurements()) {
        notify.success({
          content:
            values.numberMeasurementsSuccess > 1
              ? getTranslation('actions.meter.add_measurements_success')
              : getTranslation('actions.meter.add_measurement_success')
        });
      }

      if (values.location) {
        navigate(`${rootUrl}/locations/${values.location.id}`);
      } else if (values.propertyGroup) {
        navigate(`${rootUrl}/property-groups/${values.propertyGroup.id}`);
      }
    }
  }, [values.areMeasurementsValid, values.numberInstallSuccess]);

  const handleSubmit = submitFactory(async () => {
    submitInstallationsAndMeasurements(values, setValue);
  });

  return (
    <>
      {/* Location section */}
      <StepAnchor name={STEP_NAMES[0]} />
      <MeterLocationSection
        values={values}
        didSubmitBefore={values.didSubmitBefore}
        onFocusStep={onFocusStep}
        setValue={setValue}
      />

      {/* Select meter section */}
      <>
        <StepAnchor name={STEP_NAMES[1]} />
        <MeterSection
          propertyGroup={propertyGroup}
          locationType={values.locationType}
          meter={meter ? meter : null}
          didSubmitBefore={values.didSubmitBefore && apiErrors.length === 0}
          createNewMeter={createNewMeter}
          measurementSuccesses={values.measurementSuccesses} // We will use this to enable/disable Multivalue nodes
          onFocusStep={onFocusStep}
          setValue={setValue}
          setFeedback={setFeedback}
          setInstallations={(installations: InstallationType[]) => setValue({ installations })}
          setCreateNewMeter={setCreateNewMeter}
          setCreateNewMeasurement={setCreateNewMeasurement}
        />
      </>

      {/* if all meter wizards are submited then show this submit button*/}
      <WizardSubmitButton
        id="install-meter-submit"
        onClick={handleSubmit}
        disabled={(errors.feedback.length > 0 && feedback.length > 0) || createNewMeter}
      >
        {getTranslation('general.submit')}
      </WizardSubmitButton>
    </>
  );
};

export default observer(InstallMeterWizard);
