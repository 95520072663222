import React, { useMemo } from 'react';
import DynamicVirtualTable from 'components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { useStore } from 'hooks/useStore';
import useCustomerGroupListPage from '../hooks/useCustomerGroupListPage';
import { TableError, TableLoading, TableNoData } from 'components/placeholder';

function CustomerGroupList() {
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    selectAllBusy: selectAllBusy_,
    totalAmountOfRows,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useCustomerGroupListPage();

  const [columns] = React.useState([
    {
      width: 800,
      label: getTranslation('general.name'),
      dataKey: 'name',
      noClick: true
    },
    {
      width: 300,
      label: `#${getTranslation('customer.customers')}`,
      dataKey: 'amountOfCustomers'
    }
  ]);
  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="customer-groups-table"
        tooltipId="customer-groups-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={columns}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
}

export default CustomerGroupList;
