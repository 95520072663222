import moment from 'moment';

import { AddMeasurementRequestType, ExternalChannelType, MeasurementPair } from '@zf/api-types/master-data/meter';

export const setPreviousDate = (measurementPair: MeasurementPair | null | undefined) => {
  if (measurementPair && measurementPair.previousMeasurement) {
    if (measurementPair.previousMeasurement.endDateTime !== null) {
      return moment(measurementPair.previousMeasurement.endDateTime);
    }
  }
};
export const setNextDate = (measurementPair: MeasurementPair | null | undefined) => {
  if (measurementPair && measurementPair.nextMeasurement) {
    if (measurementPair.nextMeasurement.endDateTime !== null) {
      return moment(measurementPair.nextMeasurement.endDateTime);
    }
  }
};

export const setChannel = (value: AddMeasurementRequestType) => {
  let channel: ExternalChannelType | undefined;
  if (value.meter) {
    channel = value.meter.channels.find((chan) => {
      return chan.externalIdentifier === value.channelId;
    });

    return channel;
  }
};
