import { colors } from '@zf/utils/src/color';
import { formatMoney } from '@zf/utils/src/number';
import React from 'react';
import { Paragraph } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';

type Props = {
  amount: number;
  transactionAmount?: number;
  color?: string;
};

const BucketContent = (props: Props) => {
  const { amount, transactionAmount = 0, color } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { culture } = applicationStore;

  return (
    <div>
      {amount !== 0 && <Paragraph color={color}>{formatMoney(amount, culture)}</Paragraph>}
      {transactionAmount > 0 && (
        <Paragraph color={colors['silver-600']}>
          {transactionAmount} {getTranslation('general.trans')}
        </Paragraph>
      )}
    </div>
  );
};

export default BucketContent;
