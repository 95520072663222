import React from 'react';

import { PaymentAnalyticsType } from '@zf/api-types/analytics/payments';

import MissingCashChart from '../cards/MissingCashChart';
import TodoCard from '../cards/TodoCard';
import DashboardColumn from './DashboardColumn';

type Props = {
  paymentInsights: PaymentAnalyticsType;
  setActiveTab: (tabId: string) => void;
};

export default function Collections(props: Props) {
  const { paymentInsights, setActiveTab } = props;

  return (
    <DashboardColumn>
      <MissingCashChart paymentInsights={paymentInsights} />
      <TodoCard subject="collections" dataObject={paymentInsights} setActiveTab={setActiveTab} />
    </DashboardColumn>
  );
}
