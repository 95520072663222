import React from 'react';

import { MeterPropertiesPrepayment, MeterType } from '@zf/api-types/master-data/meter';
import DataGridCardTab from '@zf/stella-react/src/atoms/Tabs/GraphTab/DataGridCardTab';

import DataGridCardHeaderSection from '../../../../../components/DataGridCard/DataGridCardHeaderSection';
import css from './balance-tab.module.scss';
import BalanceCardFilters from './BalanceCardFilters';
import BalanceCardHeading from './BalanceCardHeading';
import DataSection from './DataSection';

type Props = {
  meter: MeterType;
};

const BalanceTab = (props: Props) => {
  const { meter } = props;

  const properties = meter.meterProperties as MeterPropertiesPrepayment | null;

  return (
    <DataGridCardTab cardId="current-balance-card">
      {properties && (
        <DataGridCardHeaderSection>
          <div className={css['heading']}>
            <BalanceCardHeading properties={properties} />
          </div>
        </DataGridCardHeaderSection>
      )}

      <DataGridCardHeaderSection>
        <div className={css['filters']}>
          <BalanceCardFilters />
        </div>
      </DataGridCardHeaderSection>

      <DataSection prepaymentDevice={meter} />
    </DataGridCardTab>
  );
};

export default BalanceTab;
