import React from 'react';

import { CollectionFlowStepType, CollectionFlowType } from '@zf/api-types/collection-cycles';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../app-context/app-context';
import Button from '../../../components/Button/Button';
import { dialog } from '../../../events/dialog-events';
import AddEditStepDialog from './add-edit-steps-dialog/add-edit-step-dialog';
import CollectionFlowStep from './collection-flow-step';

type Props = {
  flowIndex: number;
  flow: CollectionFlowType;
  steps: CollectionFlowStepType[];
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  updateStep: (step: CollectionFlowStepType) => void;
  removeStep: (stepId: string, flowIndex: number) => void;
};

export default function CollectionFlowSteps(props: Props) {
  const { flowIndex, flow, steps, addStep, updateStep, removeStep } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const openDialog = (useCase: 'add' | 'edit', stepNumber?: number) => {
    dialog.right({
      title:
        useCase === 'add'
          ? i18n.getTranslation('collection_flows.add_step_number', {
              stepNumber: steps.length + 1
            })
          : i18n.getTranslation('collection_flows.edit_step_number', {
              stepNumber: stepNumber || ''
            }),
      content: (
        <AddEditStepDialog
          ref={clickRef}
          useCase={useCase}
          stepNumber={stepNumber || steps.length + 1}
          step={stepNumber ? steps[stepNumber - 1] : undefined}
          flowIndex={flowIndex}
          flow={flow}
          validationRef={validationRef}
          addStep={addStep}
          updateStep={updateStep}
        />
      ),
      buttonPositive: i18n.getTranslation(`general.${useCase === 'add' ? 'add' : 'edit'}`),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <>
      {steps.map((step, index) => {
        return (
          <CollectionFlowStep
            key={step.id}
            step={step}
            stepNumber={index + 1}
            flowIndex={flowIndex}
            openDialog={openDialog}
            removeStep={removeStep}
          />
        );
      })}

      <Button id="add-step" key="add-step" type="secondary" icon="plus" onClick={() => openDialog('add')}>
        {i18n.getTranslation('collection_flows.add_step')}
      </Button>
    </>
  );
}
