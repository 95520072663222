import React from 'react';

import { IncomingBankingTransactionsOverviewCountType } from '@zf/api-types/billing/incoming-banking-transaction';

import useFilter from '../../../../../app-context/hooks/use-filter';
import FilterTag from '../../../../../components/Filters/filter-tag';
import FilterTags from '../../../../../components/Filters/filter-tags';
import { StringParam } from 'serialize-query-params';

const initialBatchesOverviewCount: IncomingBankingTransactionsOverviewCountType = {
  all: 0,
  ready: 0,
  closed: 0,
  attention: 0,
  resolveIssues: 0
};

export type QueryParam = {
  mutationDateTimeStart?: string;
  mutationDateTimeEnd?: string;
  companyBankAccountIBAN: string;
  companyBankAccountId: string;
};

const initialQueryParams = {
  mutationDateTimeStart: undefined,
  mutationDateTimeEnd: undefined,
  companyBankAccountIBAN: '',
  companyBankAccountId: ''
};

const paramTypes = {
  mutationDateTimeStart: StringParam,
  mutationDateTimeEnd: StringParam,
  companyBankAccountIBAN: StringParam,
  companyBankAccountId: StringParam
};

const useIncomingBankingTransactionFilter = (search?: string) => {
  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams
  } = useFilter<QueryParam>({
    endpoint: '/bill/incomingbankingtransactions/overviewcount',
    initialCount: initialBatchesOverviewCount,
    domain: 'incoming_banking_transaction',
    search,
    initialQueryParams,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.mutationDateTimeStart !== undefined || queryParams.mutationDateTimeEnd !== undefined) {
    tags.push(
      <FilterTag
        key="period"
        type="period"
        value={[queryParams.mutationDateTimeStart, queryParams.mutationDateTimeEnd]}
        removeFilter={() => setQueryParams({ mutationDateTimeStart: undefined, mutationDateTimeEnd: undefined })}
      />
    );
  }

  if (queryParams.companyBankAccountIBAN && queryParams.companyBankAccountId) {
    tags.push(
      <FilterTag
        key="iban"
        value={queryParams.companyBankAccountIBAN}
        removeFilter={() => setQueryParams({ companyBankAccountIBAN: '', companyBankAccountId: '' })}
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    filterTags,
    quickFilter,
    overviewCount,
    searchValue,
    queryParams,
    refreshCounts: refresh,
    onFilter,
    onSearch,
    setQueryParams,
    clearQueryParams
  };
};

export default useIncomingBankingTransactionFilter;
