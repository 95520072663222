import { observer } from 'mobx-react';
import React from 'react';

import { Router } from '@reach/router';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';
import Center from '@zf/stella-react/src/helpers/Center';

import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { ErrorBoundary } from '../../components/Error';
import { CrumbHeader } from '../../components/Header';
import { Loader } from '../../components/Loader';
import RouteWrapper from '../../components/RouteWrapper';
import { SuspenseSpinner } from '../../components/suspense';
import { useStore } from '../../hooks/useStore';
import Route from '../route';
import BillingItemsTab from './billing-items/BillingItemsTab';
import ConsumptionUnitTypeTab from './consumption-unit-types/consumption-unit-type-tab';
import CostComponentsTab from './cost-components/cost-components-tab';
import ProductsTab from './products/ProductsTab';
import TaxCodeTab from './tax-codes/tax-code-tab';

type Props = {
  location: Location;
  navigate: (href: string) => void;
  urlParams: Record<string, string>;
};

const ProductConfig = (props: Props) => {
  const { navigate, location } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  const splitUrl = location.href.split('/');

  const costComponentsTab = () => (
    <SuspenseSpinner>
      <CostComponentsTab />
    </SuspenseSpinner>
  );

  const consumptionUnitTypeTab = () => (
    <SuspenseSpinner>
      <ConsumptionUnitTypeTab />
    </SuspenseSpinner>
  );

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="cog" text={getTranslation('product_config.product_config')} />
          </BreadCrumb>
        }
      />
      <TabContainer
        onSelectTab={setActiveTab}
        entityId={getTranslation('nav.products')}
        selectedTab={splitUrl[splitUrl.length - 1].split('?')?.[0]}
        tabItems={[
          {
            id: 'products',
            title: getTranslation('product_config.products'),
            isDefault: true
          },
          {
            id: 'billing_items',
            title: getTranslation('billing_items.billing_items')
          },
          {
            id: 'input_variables',
            title: getTranslation('product_config.input_variables')
          },
          {
            id: 'tax_codes',
            title: getTranslation('tax_code.tax_codes')
          },
          {
            id: 'consumption_unit',
            title: getTranslation('consumption_unit.consumption_units')
          }
        ]}
      >
        <ErrorBoundary>
          <React.Suspense
            fallback={
              <Center type="both">
                <Loader />
              </Center>
            }
          >
            <Router>
              <RouteWrapper default>
                <Route Component={ProductsTab} path="products" default />
                <Route Component={BillingItemsTab} path="billing_items" />
                <Route Component={costComponentsTab} path="input_variables" />
                <Route Component={TaxCodeTab} path="tax_codes" />
                <Route Component={consumptionUnitTypeTab} path="consumption_unit" />
              </RouteWrapper>
            </Router>
          </React.Suspense>
        </ErrorBoundary>
      </TabContainer>
    </>
  );
};

export default observer(ProductConfig);
