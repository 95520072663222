import React from 'react';

import { InvoiceType } from '@zf/api-types/invoice';

import { useAppContext } from '../../../../app-context';
import useDownload from '../../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  invoice: InvoiceType;
};

export default function DownloadInvoiceListItem(props: Props) {
  const { invoice } = props;

  const { i18n } = useAppContext();
  const { downloadFiles } = useDownload();
  const { invoiceStore } = useStore();

  if (invoiceStore.pageActionPermissions.mayDownload) {
    return (
      <DropdownAction
        id="invoice.download"
        icon="pdf"
        actionType="direct"
        onClick={() =>
          downloadFiles([invoice.attachmentId], '/att/attachments/downloadzip', '/att/attachments/', 'attachmentIds')
        }
      >
        {i18n.getTranslation('actions.download')}
      </DropdownAction>
    );
  }

  return null;
}
