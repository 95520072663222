import React from 'react';

import { navigate } from '@reach/router';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { isGuid } from '@zf/utils/src/regex';

import { useAppContext } from '../app-context';
import { DomainType, ListPageState } from '../app-state';
import useCurrent from './use-current';

export type NavigatorType = {
  next: () => void;
  previous: () => void;
  currentId: string;
  totalItems: number;
  currentPosition: number;
};

const routeMap: Record<DomainType, string> = {
  contract: 'contracts',
  customer: 'customers',
  customersgroup: 'customers/customer-groups',
  payment: 'payments/payments',
  invoice: 'invoices',
  importjob: 'import-jobs',
  exportjob: 'export-jobs',
  location: 'locations',
  propertygroup: 'property-groups',
  meter: 'devices/meters',
  'meter-issues': 'devices/issues',
  prepayment_device: 'devices/prepayment-devices',
  collection_case: 'collection-cases',
  moverequest: 'tasks',
  completeness: '',
  'import-processing': '',
  outgoing_banking_transaction: 'payments/outgoing-banking-transactions',
  incoming_banking_transaction: 'payments/incoming-banking-transactions',
  country_specifics: 'country-specifics'
};

type State = {
  allIds: string[];
  currentId: string;
  currentPosition: number;
  totalItems: number;
  otherIds?: string[];
};

const getAllIds = (listPageState: ListPageState) => {
  let allIds: string[] = [];

  if (listPageState.selectedRows.length > 0) {
    allIds = listPageState.selectedRows.map((r) => r.__id);
  } else if (listPageState.allIds) {
    allIds = listPageState.allIds;
  }

  return allIds;
};

const initPosition = (allIds: string[], routeId: string): number => {
  if (routeId) {
    const currentIndex = allIds.findIndex((id) => id === routeId);
    if (currentIndex > -1) return currentIndex + 1;
  }

  return 0;
};

const initTotalItems = (listPageState: ListPageState): number => {
  if (listPageState.selectedRows.length > 0) {
    return listPageState.selectedRows.length;
  } else if (listPageState.allIds.length < 300) {
    return listPageState.allIds.length;
  } else {
    return listPageState.overviewCounts['all'];
  }
};

const useSelectionNavigator = (routeId: string, domain: DomainType) => {
  const { myCurrent } = useCurrent(domain);
  const { tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;
  const listPage = myCurrent.listPage;

  let currentTab = '';
  const currentPathParts = window.location.href.split('/');
  const lastPart = currentPathParts[currentPathParts.length - 1];

  if (!isGuid(lastPart)) {
    currentTab = lastPart;
  }

  const allIds = getAllIds(listPage);

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [selection, setSelection] = React.useReducer(stateReducer, {
    allIds: allIds,
    currentId: routeId,
    currentPosition: initPosition(allIds, routeId),
    totalItems: initTotalItems(listPage),
    otherIds: undefined
  });

  React.useEffect(() => {
    if (selection.currentId !== routeId) setSelection({ currentId: routeId });
  }, [routeId]);

  const shiftNavigate = (shift: (pos: number) => number) => {
    const currentIndex = selection.allIds.findIndex((id) => id === selection.currentId);
    const newCurrentId = selection.allIds[shift(currentIndex)];

    let url = `${rootUrl}/${routeMap[domain]}/${newCurrentId}`;
    if (selection.otherIds) {
      selection.otherIds.forEach((id) => {
        url += `/${id}`;
      });
    }

    if (currentTab) {
      url += `/${currentTab}`;
    }

    if (newCurrentId) {
      navigate(url);
      setSelection({
        currentId: newCurrentId,
        currentPosition: shift(selection.currentPosition)
      });
    }
  };

  const next = () => {
    const shift = (pos: number) => {
      return pos + 1;
    };
    shiftNavigate(shift);
  };

  const previous = () => {
    const shift = (pos: number) => {
      return pos - 1;
    };
    shiftNavigate(shift);
  };

  return {
    next,
    previous,
    setSelection,
    currentId: selection.currentId,
    currentPosition: selection.currentPosition,
    totalItems: selection.totalItems
  };
};

export default useSelectionNavigator;
