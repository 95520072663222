import { observer } from 'mobx-react';
import React from 'react';

import { tariffType } from '@zf/api-types/enums';
import { BillingItemType, BillingTariffRequestType, BillingTariffType, ProductType } from '@zf/api-types/product';
import { colors } from '@zf/utils/src/color';

import { TreeItem } from '../../../../../design-system/ComponentSets';
import { Icon } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import TreeNode from '../models/TreeNode';
import useRenderTreeActions from './hooks/renderTreeActions';

type Props = {
  tree: TreeNode;
  index: number;
  level?: number;
  selectedBillingItem?: BillingItemType;
  selectedProduct?: ProductType;
  tariffs_: BillingTariffType[];
  isProductPage: boolean;
  addTariff: (tariffToAdd: BillingTariffRequestType) => Promise<BillingTariffType>;
  addTariffToList: (newTariff: BillingTariffType) => void;
  updateTariff: (id: string, tariffToUpdate: BillingTariffRequestType) => Promise<BillingTariffType>;
  updateTariffInList: (updatedTariff: BillingTariffType) => void;
  addNode: (parentTree: TreeNode) => void;
  removeNode: (parentTree: TreeNode, index: number) => void;
  updateNode: (parentTree: TreeNode, index: number) => void;
  initTariffs: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

const TraverseTree = (props: Props) => {
  const {
    tree,
    level = 0,
    addNode,
    removeNode,
    updateNode,
    index,
    selectedBillingItem,
    selectedProduct,
    tariffs_,
    isProductPage,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    initTariffs
  } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { moreActions, crudActions, tariffTypeActions, openConditionsDialog, opentariffDialog } = useRenderTreeActions({
    isProductPage,
    tree,
    selectedBillingItem,
    selectedProduct,
    tariffs_,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    initTariffs
  });

  if (!tree) return null;

  return (
    <TreeItem
      allowedActions={crudActions}
      icon={tree.icon}
      key={`${tree.id}-${index}`}
      info={tree.info}
      id={tree.id}
      addFunc={addNode}
      editFunc={() => updateNode(tree, index)}
      deleteFunc={() => removeNode(tree, index)}
      text={tree.name}
      selected={true}
      indentLevel={level + 1}
      disabled={tree.nodes.length === 0}
      extraRight={[
        ...tariffTypeActions,
        tree.isActive ? <Icon key={`${tree.id}-${index}-icon`} name="dot" color={colors['green-600']} /> : null
      ]}
      otherActions={!tree.isDefault || !isProductPage ? moreActions : []}
      extraInfo={tree.isDefault ? getTranslation('general.default') : ''}
    >
      {tree.nodes.map((n, nestedIndex) => (
        <div key={`${tree.id}-${index}-${n.name}-${nestedIndex}`}>
          <TraverseTree
            isProductPage={isProductPage}
            tariffs_={tariffs_ || []}
            selectedBillingItem={selectedBillingItem}
            selectedProduct={selectedProduct}
            addTariff={addTariff}
            addTariffToList={addTariffToList}
            updateTariff={updateTariff}
            updateTariffInList={updateTariffInList}
            initTariffs={initTariffs}
            addNode={n.addNode}
            removeNode={(treeItem, index) => treeItem.deleteNode(index, tree)}
            updateNode={(treeItem, index) => {
              if (n.type === tariffType.conditions) {
                openConditionsDialog(n, tree, index);
              } else if (n.type === tariffType.tariffcalculation) {
                opentariffDialog(n, tree, index);
              }
            }}
            index={nestedIndex}
            level={level + 1}
            tree={n}
          />
        </div>
      ))}
    </TreeItem>
  );
};

export default observer(TraverseTree);
