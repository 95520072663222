import { MultiValue } from 'design-system/ComponentSets';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { forwardRef, Fragment, MutableRefObject, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import {
  AddEntryToOutgoingBankingTransactionRequest,
  OutgoingBankingTransactionRequestType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import BankAccountsDropdown from '../../components/Dropdown/bankaccounts-dropdown/BankAccountsDropdown';
import Select from '../../components/input/Select';
import DatePicker from '../../components/Lang/DatePicker';
import { TabSlider } from '../../design-system/Components';
import { SelectionTabItemType } from '../../design-system/Components/TabSlider/Tab';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';
import css from './add-outgoing-banking-transaction-dialog.module.scss';
import AddOutgoingBankingTransactionWizard from './AddOutgoingBankingTransactionWizard';

type Props = {
  refresh: () => void;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

export type SilderOptions = {
  invoice: number;
  manual: number;
};

export type StateOutgoingBankingTransaction = {
  date: Moment | null;
  type: string;
  entries: AddEntryToOutgoingBankingTransactionRequest[];
  tabItems: SelectionTabItemType<SilderOptions>[];
  companyBankAccount: string | null;
};

const AddOutgoingBankingTransactionDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { refresh, validationRef } = props;
  const { applicationStore, outgoingBankingTransactionsStore } = useStore();
  const { getTranslation } = applicationStore;
  const { createOutgoingBankingTransaction } = outgoingBankingTransactionsStore.outgoingBankingTransactionsService;

  const stateReducer = createStateReducer<StateOutgoingBankingTransaction, Partial<StateOutgoingBankingTransaction>>();
  const [state, dispatch] = useReducer(stateReducer, {
    type: outgoingBankingTransactionType.paymentrequests,
    date: null,
    entries: [
      {
        invoiceId: '',
        amount: 0,
        paymentReference: undefined,
        iban: null,
        customerId: null,
        entryType: ''
      } as AddEntryToOutgoingBankingTransactionRequest
    ],
    tabItems: [
      { id: 'invoice', title: getTranslation('invoice.invoice') },
      {
        id: 'manual',
        title: getTranslation('outgoing_banking_transactions.payment_refunds')
      }
    ],
    companyBankAccount: ''
  });

  useEffect(() => {
    dispatch({
      tabItems: [
        { id: 'invoice', title: getTranslation('invoice.invoice') },
        {
          id: 'manual',
          title:
            state.type === outgoingBankingTransactionType.paymentrefunds
              ? getTranslation('outgoing_banking_transactions.payment_refunds')
              : getTranslation('actions.outgoing_mutation.manual_entry_paymentrequests')
        }
      ]
    });
  }, [state.type]);

  const setDate = (date: Moment) => {
    dispatch({ date });

    if (moment().add(2, 'd').isAfter(date.toISOString())) {
      notify.warning({
        content: getTranslation('outgoing_banking_transactions.date_validation')
      });
    } else {
      dispatch({ date });
    }
  };

  useEffect(() => {
    if (state)
      if (validationRef.current) {
        if (
          !state.date ||
          !state.type ||
          !state.companyBankAccount ||
          moment().add(2, 'd').isAfter(state.date?.toISOString())
        ) {
          validationRef.current.setIsError(true);
        } else {
          if (state.entries.length > 0) {
            state.entries.forEach((e) => {
              if (e.invoiceId) {
                validationRef.current?.setIsError(false);
              } else {
                validationRef.current?.setIsError(
                  e.amount === 0 || isNaN(e.amount) || !e.customerId || !e.paymentReference || !e.iban
                );
              }
            });
          } else {
            validationRef.current.setIsError(false);
          }
        }
      }
  }, [state]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const outgoingBankingtransaction: OutgoingBankingTransactionRequestType = {
          outgoingBankingTransactionType: state.type,
          collectionDate: state.date?.toISOString() as string | null,
          entries: state.entries,
          companyBankAccountId: state.companyBankAccount
        };

        await createOutgoingBankingTransaction(outgoingBankingtransaction);

        refresh();

        notify.success({
          content: getTranslation('outgoing_banking_transactions.succes_creation')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('outgoing_banking_transactions.failed_creation'),
          error: e
        });
      }
      //call
      refresh();
    }
  }));

  const selectOptions = [
    {
      text: getTranslation(
        `outgoing_banking_transactions.paymentrequest_${outgoingBankingTransactionType.paymentrequests}`
      ),
      value: outgoingBankingTransactionType.paymentrequests
    },
    {
      text: getTranslation(
        `outgoing_banking_transactions.paymentrefund_${outgoingBankingTransactionType.paymentrefunds}`
      ),
      value: outgoingBankingTransactionType.paymentrefunds
    }
  ];

  return (
    <>
      <InputContainer grid>
        <Select
          id="customerType"
          onChange={(val) => dispatch({ type: val[0] })}
          selectedValues={[state.type]}
          values={selectOptions.map((ct) => {
            return {
              icon: 'payment',
              value: ct.value,
              text: ct.text
            };
          })}
        />
        <DatePicker
          placeholder={
            state.type === outgoingBankingTransactionType.paymentrefunds
              ? getTranslation('outgoing_banking_transactions.refund_date')
              : getTranslation('outgoing_banking_transactions.collection_date')
          }
          id="addbankingTrans.collection-date"
          onChange={(value) => setDate(value)}
          value={state.date}
          error={moment().add(2, 'd').isAfter(state.date?.toISOString())}
        />

        <BankAccountsDropdown
          id="invoice.bank_account"
          onChange={(val) => dispatch({ companyBankAccount: val[0]?.id || '' })}
          selectedValue={state.companyBankAccount || ''}
          showDefault={false}
          error={!state.companyBankAccount}
        />
      </InputContainer>

      {state.type ? (
        <MultiValue
          id="mutations"
          title={getTranslation('actions.outgoing_mutation.add_mutations')}
          initialValues={state.entries}
          intialNodeValue={
            {
              invoiceId: undefined,
              amount: 0,
              paymentReference: '',
              iban: '',
              customerId: ''
            } as AddEntryToOutgoingBankingTransactionRequest
          }
          onChange={(value) => dispatch({ entries: value })}
        >
          {({ index, value, dispatchValue }) => {
            const setEntryType = (entryType: string) => {
              dispatchValue({
                invoiceId: '',
                amount: 0,
                paymentReference: '',
                iban: '',
                customerId: '',
                entryType
              });
            };

            return (
              <Fragment key={`${index}-${value.invoiceId}`}>
                <TabSlider
                  id={`selection-outgoing-banking-transaction-${index}`}
                  className={css['entry-type']}
                  tabItems={state.tabItems}
                  selectedTabItem={(value.entryType as keyof SilderOptions) || 'invoice'}
                  setSelectedItem={setEntryType}
                />
                <AddOutgoingBankingTransactionWizard
                  index={index}
                  entryType={value.entryType}
                  state={state}
                  dispatchValue={dispatchValue}
                  value={value}
                  validationRef={validationRef}
                />
              </Fragment>
            );
          }}
        </MultiValue>
      ) : null}
    </>
  );
});

export default observer(AddOutgoingBankingTransactionDialog);
