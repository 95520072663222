import Interweave from 'interweave';
import React from 'react';

import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { reject } from './tasks';

type Props = {
  // Detail page
  task?: MoveRequestType;
  setTask?: (updatedTask: MoveRequestType) => void;

  // List page
  selectedRows?: TaskRowType[];
  setUpdatedRows?: (updatedRows: MoveRequestType[]) => void;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { task, selectedRows, setTask, setUpdatedRows, refreshCounts } = props;

  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (task && setTask) {
          const updatedTask = await reject(task.id, tenantReducer, i18n.lang);
          setTask(updatedTask);
        } else if (selectedRows && setUpdatedRows && refreshCounts) {
          const updatedTasks = await Promise.all(
            selectedRows.map((r) => reject(r.__entity.id, tenantReducer, i18n.lang))
          );
          setUpdatedRows(updatedTasks);
          refreshCounts();
        }

        notify.success({
          content: i18n.getTranslation('tasks.reject_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tasks.reject_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={
          task ? i18n.getTranslation('tasks.reject_paragraph') : i18n.getTranslation('tasks.reject_multi_paragraph')
        }
      />
    </Paragraph>
  );
});
