import React from 'react';

import useRefresh from '../../../app-context/hooks/use-refresh';
import FilterBar from '../../../components/Filters/FilterBar';
import useCountrySpecificTabs from '../hooks/useCountrySpecificTabs';
import CreateListPageButton from './actions/CreateSocialTariffListPageButton';
import DownloadListPageButton from './actions/DownloadSocicalTariffListPageButton';
import DeleteListPageButton from './actions/DeleteSocialTariffListPageButton';

type Props = {
  search?: string;
};

const SocialTariffFilterBar = (props: Props) => {
  const { refresh } = useRefresh();
  const tabItems = useCountrySpecificTabs();

  return (
    <FilterBar
      actions={[
        <CreateListPageButton key="create" refresh={refresh} />,
        <DownloadListPageButton key="download" />,
        <DeleteListPageButton key="delete" />
      ]}
      tabItems={tabItems}
      defaultTab="social-tariffs"
      refreshPage={refresh}
    />
  );
};

export default SocialTariffFilterBar;
