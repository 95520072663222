import { observer } from 'mobx-react';
import React from 'react';

import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { SuspenseCardBody } from '../../../../components/suspense';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import CustomerGroupCardBody from './CustomerGroupCardBody';
import { updateCustomerGroupName } from './Update.API';
import { useAppContext } from 'app-context';

export type ValidatorValueType = {
  name: string;
};

type Props = {
  customerGroupId: string;
  customerGroupName: string;
  setCustomerGroupName: Function;
};

const CustomerGroupCard = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { i18n, tenantReducer } = useAppContext();
  const { customerGroupId, customerGroupName, setCustomerGroupName } = props;

  const { values, isDirty, setValue, submitFactory, restoreValues, backupValues } = useValidator<ValidatorValueType>({
    initialValues: {
      name: customerGroupName
    },
    refreshTrigger: customerGroupId
  });

  const handleSave = submitFactory(async () => {
    try {
      const response = await updateCustomerGroupName(values.name, customerGroupId, tenantReducer, i18n.lang);

      if (response) {
        notify.success({
          content: getTranslation('customer_groups.update_customer_group_success')
        });

        setCustomerGroupName(values.name);
      }

      backupValues();
    } catch (e) {
      notify.error({
        content: getTranslation('customer_groups.update_customer_group_fail'),
        error: e
      });
    }
  });

  const handleCancel = () => restoreValues();

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      handleCancel();
    } else if (event.key === 'Enter') {
      handleSave();
      if (document.activeElement) {
        const elem = document.activeElement as HTMLElement;
        elem.blur();
      }
    }
  };

  return (
    <Card id="customer-group-details-card" width="1" className="card">
      <CardHeader
        primaryText={isDirty ? getTranslation('general.save') : undefined}
        onPrimaryClick={handleSave}
        secondaryText={isDirty ? getTranslation('general.cancel') : undefined}
        onSecondaryClick={restoreValues}
      >
        {getTranslation('general.details')}
      </CardHeader>
      <SuspenseCardBody type="grid">
        <CustomerGroupCardBody {...props} values={values} setValue={setValue} keyHandler={keyHandler} />
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(CustomerGroupCard);
