import clone from 'clone';
import { MultiValue } from 'design-system/ComponentSets';
import { observer } from 'mobx-react';
import React from 'react';

import { BillingTariffInputType, SliceType } from '@zf/api-types/product';

import { useStore } from '../../../../../../hooks/useStore';
import Slice from './slice';

type Props = {
  initialSlices: SliceType[] | undefined;
  setParametersValue: (key: any, value: any) => void;
};

const initialValue: SliceType = {
  from: 0,
  formulaBased: false,
  inputParameters: [] as BillingTariffInputType[],
  unitTariff: 0,
  expression: ''
};

const StairStepCalculationParameters = (props: Props) => {
  const { initialSlices, setParametersValue } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <MultiValue
      id="stair-step-tiers"
      title={getTranslation('billing_tariff.add_tier')}
      initialValues={initialSlices || [{ ...initialValue }]}
      intialNodeValue={clone(initialValue)}
      onChange={(slices) => setParametersValue('slices', slices)}
    >
      {({ index, value, dispatchValue }) => {
        return <Slice key={index} index={index} value={value} dispatchValue={dispatchValue} />;
      }}
    </MultiValue>
  );
};

export default observer(StairStepCalculationParameters);
