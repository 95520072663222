import { entitySubjectType } from '@zf/api-types/enums';
import React from 'react';

import { useAppContext } from '../../../../app-context/app-context';
import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import ListPage from '../../../../components/listPage';
import InvoiceList from './invoice-list';
import InvoicesFilterBar from './invoices-filter-bar';

type Props = {
  location: Location;
};

export default function Invoices(props: Props) {
  const { i18n } = useAppContext();
  useBrowserTitle(i18n.getTranslation('invoice.invoices'));

  return (
    <ListPage
      entitySubjectType={entitySubjectType.invoice}
      subjectType={i18n.getTranslation('invoice.invoices')}
      topBar={<InvoicesFilterBar search={props.location.search} />}
      list={<InvoiceList />}
    />
  );
}
