import React from 'react';

import { CustomerBankAccountType } from '@zf/api-types/billing/customer-bank-account';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatPeriod } from '@zf/utils/src/date';
import { formatIBAN } from '@zf/utils/src/iban';

import { useAppContext } from '../../../app-context';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import css from './history-dialog.module.scss';

type Props = {
  bankAccount: CustomerBankAccountType;
};

const HistoryDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { bankAccount } = props;
  const { i18n } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {}
  }));

  return (
    <div className={css['history']}>
      {bankAccount.customerMandates.map((mandate) => {
        return (
          <React.Fragment key={mandate.number}>
            <div>
              <div>
                <Label>{i18n.getTranslation('general.period')}</Label>
                <Paragraph>{formatPeriod(mandate.signedDateTime, mandate.cancelledDateTime)}</Paragraph>
              </div>

              <div>
                <Label>{i18n.getTranslation('mandates.iban')}</Label>
                <Paragraph>{formatIBAN(bankAccount.iban)}</Paragraph>
              </div>

              <div>
                <Label>{i18n.getTranslation('mandates.number')}</Label>
                <Paragraph>{mandate.number}</Paragraph>
              </div>
            </div>
            <HorizontalDivider />
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default HistoryDialog;
