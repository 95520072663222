import classNames from 'classnames';
import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';
import { CompareMonthType } from '@zf/stella-react/src/atoms/Date/DateInput/month-input';

import { useAppContext } from '../../../app-context/app-context';
import Button from '../../Button/Button';
import DataGridCardHeaderSection from '../../DataGridCard/DataGridCardHeaderSection';
import { Icon } from '../../Icon';
import RemoveIconButton from '../../Icon/RemoveIconButton';
import CompareMonthPicker from '../compare/CompareMonthPicker';
import CompareYearPicker from '../compare/CompareYearPicker';
import css from './graph-compare-filters.module.scss';

type Props = {
  groupByPeriod: aggregationFrequency;
  compareYears: number[];
  compareMonths: CompareMonthType[];
  setCompareYears: (index: number, newYear: number) => void;
  addComparisonYear: () => void;
  removeComparisonYear: (index: number) => void;
  setCompareMonths: (index: number, newMonth: CompareMonthType) => void;
  addComparisonMonth: () => void;
  removeComparisonMonth: (index: number) => void;
  toggleCompareMode: () => void;
};

export default function GraphCompareFilters(props: Props) {
  const {
    groupByPeriod,
    compareYears,
    compareMonths,
    setCompareYears,
    addComparisonYear,
    removeComparisonYear,
    setCompareMonths,
    addComparisonMonth,
    removeComparisonMonth,
    toggleCompareMode
  } = props;

  const { i18n } = useAppContext();

  return (
    <DataGridCardHeaderSection className={css['graph-compare-filters-wrapper']}>
      <div className={css['graph-compare-filters']}>
        {groupByPeriod === aggregationFrequency.yearly
          ? compareYears.map((year, index) => {
              return (
                <React.Fragment key={`${year}-${index}`}>
                  <CompareYearPicker
                    id={`compare-year-${index}`}
                    selectedYear={year}
                    setCompareYears={(val) => setCompareYears(index, val)}
                    removeComparisonYear={() => removeComparisonYear(index)}
                  />

                  {index !== compareYears.length - 1 && (
                    <Icon type="compare" className={classNames(css['icon'], css['icon-compare'])} />
                  )}
                </React.Fragment>
              );
            })
          : compareMonths.map((month, index) => {
              return (
                <React.Fragment key={`${month.month}-${index}`}>
                  <CompareMonthPicker
                    id={`compare-month-${index}`}
                    selectedMonth={compareMonths[index]}
                    setCompareMonths={(val) => setCompareMonths(index, val)}
                    removeComparisonMonth={() => removeComparisonMonth(index)}
                  />

                  {index !== compareMonths.length - 1 && (
                    <Icon type="compare" className={classNames(css['icon'], css['icon-compare'])} />
                  )}
                </React.Fragment>
              );
            })}

        {groupByPeriod === aggregationFrequency.yearly ? (
          <Button id="add-year" type="text" size="small" icon="plus" onClick={addComparisonYear}>
            {i18n.getTranslation('measurement.add_compare_year')}
          </Button>
        ) : (
          <Button id="add-month" type="text" size="small" icon="plus" onClick={addComparisonMonth}>
            {i18n.getTranslation('measurement.add_compare_month')}
          </Button>
        )}
      </div>
      <RemoveIconButton
        id="cancel"
        className={classNames(css['icon'], css['icon-quit-compare'])}
        onClick={toggleCompareMode}
      />
    </DataGridCardHeaderSection>
  );
}
