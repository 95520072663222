import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import css from './year-container.module.scss';

type Props = {
  selectedYear?: number;
  startYear: number;
  endYear: number;
  onSelect: (year: number) => void;
};

export default function YearContainer(props: Props) {
  const { selectedYear, onSelect, startYear, endYear } = props;

  const years = new Array(endYear - startYear + 1).fill(0).map((val, i) => startYear + i);

  return (
    <div className={classNames(css['year-container'], css['calendar-container'])}>
      {years.map((year) => {
        return (
          <BaseButton
            id={`year-${year}`}
            key={`year-${year}`}
            className={classNames(css['calendar-item'], css['year'], {
              [css['selected']]: year === selectedYear
            })}
            onClick={() => onSelect(year)}
          >
            {year}
          </BaseButton>
        );
      })}
    </div>
  );
}
