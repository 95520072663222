import React, { useMemo } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import { useAppContext } from '../../../app-context';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import useCollectionCaseListPage from '../hooks/useCollectionCaseListPage';

type Props = {
  customEntityPropertyTypes: CustomEntityPropertyType[];
  extraColumns: ColumnType[];
};

export default function CollectionCaseList(props: Props) {
  const { customEntityPropertyTypes, extraColumns } = props;
  const { i18n } = useAppContext();
  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    setStopIndex,
    setSelectedIds,
    handleSort
  } = useCollectionCaseListPage(customEntityPropertyTypes);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="collection-cases-table"
        tooltipId="collection-cases-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        sortableFields={sortableFields}
        error={error}
        selectedRows={selectedIds}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        onSelectRow={setSelectedIds}
        sort={handleSort}
        singleSort
        columns={[
          {
            width: 40,
            label: '',
            dataKey: 'actionRequired'
          },
          {
            width: 180,
            label: i18n.getTranslation('collection_case.labels.collection_id'),
            dataKey: 'collectionId',
            noClick: true
          },
          {
            width: 180,
            label: i18n.getTranslation('collection_case.labels.workflow_name'),
            dataKey: 'workflowName'
          },
          {
            width: 250,
            label: i18n.getTranslation('collection_case.labels.debtor'),
            dataKey: 'debtor'
          },
          {
            width: 180,
            label: i18n.getTranslation('general.status'),
            dataKey: 'status'
          },
          {
            width: 220,
            label: i18n.getTranslation('collection_case.labels.last_step'),
            dataKey: 'lastStep'
          },
          {
            width: 220,
            label: i18n.getTranslation('collection_case.labels.next_step'),
            dataKey: 'nextStep'
          },
          {
            width: 220,
            label: i18n.getTranslation('collection_case.labels.payment_reference'),
            dataKey: 'paymentReference'
          },
          {
            width: 200,
            label: i18n.getTranslation('collection_case.labels.open_amount_incl_vat'),
            dataKey: 'openAmountInclVAT'
          },
          {
            width: 180,
            label: i18n.getTranslation('collection_case.labels.next_action_due'),
            dataKey: 'nextActionDue'
          },
          ...extraColumns
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, i18n.lang]);
}
