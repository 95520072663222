import React from 'react';

import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';

type Props = {
  propertyGroupId: string;
  billingPeriod: PropertyGroupBillingPeriodType | undefined;
  incomingInvoiceCostComponentValueId: string;
  updateBillingPeriod: (period: PropertyGroupBillingPeriodType) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { propertyGroupId, billingPeriod, incomingInvoiceCostComponentValueId, updateBillingPeriod } = props;

  const { i18n, tenantReducer } = useAppContext();

  const deleteIncomingInvoiceCostComponentValueId = async (valueId: string) => {
    const updatedBillingPeriod = (
      await sendRequest<PropertyGroupBillingPeriodType>({
        request: {
          method: METHODS.DELETE,
          endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}/${billingPeriod?.id}/IncomingInvoiceCostComponentValues/${valueId}`
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;

    return updatedBillingPeriod;
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const updatedBillingPeriod = await deleteIncomingInvoiceCostComponentValueId(
          incomingInvoiceCostComponentValueId
        );

        updateBillingPeriod(updatedBillingPeriod);

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_incoming_cost_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_incoming_cost_fail'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation('property_groups.tabs.billing.delete_incoming_cost_paragraph')}</Paragraph>;
});
