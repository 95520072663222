import React from 'react';

import { TenantOrganisationType } from '@zf/api-types/auth';
import { guidPathRe } from '@zf/utils/src/regex';

import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  currPath: string;
  organisation: TenantOrganisationType;
  clickItem: (url: string) => void;
};

export default function OrganisationDropdownListItem(props: Props) {
  const { organisation, clickItem } = props;
  let { currPath } = props;
  const { tenantReducer } = useAppContext();
  const { tenant } = tenantReducer.state;

  const foundIdInPath = guidPathRe.test(currPath);

  if (foundIdInPath) {
    currPath = currPath.replace(guidPathRe, '');
  }

  return (
    <DropdownAction
      id={`org-${organisation.shortCode}`}
      actionType="direct"
      onClick={() => clickItem(`/${tenant?.name}/${organisation.shortCode}/${currPath}`)}
    >
      {organisation.shortCode}
    </DropdownAction>
  );
}
