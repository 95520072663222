import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import { Label } from '@zf/stella-react/src/atoms/Label';

import Button from '../../../../../components/Button/Button';
import InputField from '../../../../../components/input/InputField';
import { TabSlider } from '../../../../../design-system/Components';
import { dialog } from '../../../../../events/dialog-events';
import { useStore } from '../../../../../hooks/useStore';
import SendTestEmailDialog from '../dialogs/send-test-email-dialog';
import css from './sending-details-card-body.module.scss';

const SendingDetailsCardBody = () => {
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { templatesStore } = communicationStore;
  const { templateForm, selectedEntitySubjectType, selectedScenario } = templatesStore;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const tabItems = useMemo(() => {
    return selectedScenario?.availableCommunicationTypes?.map((ct) => {
      return {
        id: ct,
        title: getEnumTranslation('communicationType', ct)
      };
    });
  }, [selectedEntitySubjectType]);

  const openSendTestEmailDialog = () => {
    dialog.normal({
      title: getTranslation('communication.send_test_mail'),
      icon: 'enveloppe',
      content: <SendTestEmailDialog ref={clickRef} validationRef={validationRef} />,
      buttonPositive: getTranslation('communication.send'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <>
      <div>
        <Label className={css['communication-label-spacer']}>
          {getTranslation('communication.default_sending_method')}
        </Label>
        <TabSlider
          id="sending-method"
          tabItems={tabItems}
          selectedTabItem={templateForm._get('defaultCommunicationType')}
          setSelectedItem={(val) => templateForm._set('defaultCommunicationType', val as string)}
        />
      </div>

      <div className={css['subject-wrapper']}>
        <Label className={css['communication-label-spacer']}>{getTranslation('communication.subject')}</Label>
        <InputField
          id="communication-subject"
          value={templateForm._get('subject')}
          onChange={(val) => templateForm._set('subject', val)}
          error={!!templateForm._error('subject')}
          singleError={templateForm._error('subject')}
          hideLabel
        />
      </div>

      <div>
        <div className={css['spacer']} />
        <Button id="send-test-btn" className={css['send-test-btn']} type="primary" onClick={openSendTestEmailDialog}>
          {getTranslation('communication.send_test_mail')}
        </Button>
      </div>
    </>
  );
};

export default observer(SendingDetailsCardBody);
