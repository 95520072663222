import { Moment } from 'moment';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';

import StellaDatePicker from '@zf/stella-react/src/atoms/Date/stella-date-picker';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context/app-context';
import { notify } from '../../events/notification-events';
import AccessControl from '../Permissions/AccessControl';

export type DatePickerProps = {
  clearText?: string;
  value: Moment | null;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  hideLabel?: boolean;
  error?: boolean;
  singleError?: string;
  errors?: {};
  iconRight?: ReactNode;
  className?: string;
  clear?: boolean;
  maxDate?: boolean;
  darkMode?: boolean;
  startOfDay_?: boolean;
  renderTopLvl?: boolean;
  onFocus?: (step: string) => void;
  onClick?: () => void;
  onBlur?: () => void;
  setClearCalendar?: Dispatch<SetStateAction<boolean>>;
  onChange: (value: Moment) => void;
};

export default function DatePicker(props: DatePickerProps) {
  const { id, onChange, value, clearText, className, placeholder, error, errors, startOfDay_ } = props;
  const { i18n } = useAppContext();

  const [warnings] = useState([
    i18n.getTranslation('datepicker.day_invalid'),
    i18n.getTranslation('datepicker.month_invalid'),
    i18n.getTranslation('datepicker.year_invalid')
  ]);

  const warnUser = useCallback((content: ReactNode) => {
    notify.warning({
      content: (
        <>
          <Paragraph>{i18n.getTranslation('general.invalid_input')}</Paragraph>
          {content}
        </>
      )
    });
  }, []);

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{value ? formatDate(value) : ''}</Paragraph>}>
      <StellaDatePicker
        {...props}
        id={id}
        className={className}
        onChange={onChange}
        warnUser={warnUser}
        value={value}
        placeholder={placeholder}
        error={error}
        errors={errors}
        warnings={warnings}
        startOfDay_={startOfDay_}
        clearText={clearText || i18n.getTranslation('datepicker.clear')}
        culture={i18n.lang}
      />
    </AccessControl>
  );
}

export const InlineDatePicker = InlineInputField<DatePickerProps>(DatePicker);
