import { MultiValue } from 'design-system/ComponentSets';
import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { meterType } from '@zf/api-types/enums';
import { LocalAddressType } from '@zf/api-types/general';
import {
  AddMeasurementRequestType,
  ExternalChannelType,
  MeterRelation,
  MeterType
} from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import useSessionStorage from '@zf/hooks/src/useSessionStorage';
import { MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import { InstallationType } from '../wizard/InstallMeterWizard';
import MeterToInstall from './MeterToInstall';

export type InstallMeterMultiValueType = {
  meter: MeterType | null;
  endDateTime: Moment;
  mutationDateTime: Moment;
  serviceLocationId: string;
  propertyGroupId: string;
  addressInstalled: LocalAddressType | null;
  channelTemplates: ExternalChannelType[];
  measurements: AddMeasurementRequestType[];
  channelId: string;
  hour?: string;
  minute?: string;
  value?: number;
};

export type InstallMeterSectionMultiValueProps = {
  locationType: 'location' | 'property_group';
  createNewMeter: boolean;
  meter?: MeterType | null;
  location?: ServiceLocationType;
  propertyGroup?: PropertyGroupType;
  measurementSuccesses?: boolean[];
  meterType_?: meterType;
  setCreateNewMeter: (val: boolean) => void;
  setInstallations: (installations: InstallationType[]) => void;
  setMeasurementSuccesses?: (successes: boolean[]) => void;
  handleFocus?: () => void;
  setFeedback?: Dispatch<SetStateAction<string[][]>>;
  setCreateNewMeasurement?: Dispatch<SetStateAction<boolean>>;
  onFocusStep?: Dispatch<SetStateAction<string>>;
};

export default function InstallMeterMultiValue(props: InstallMeterSectionMultiValueProps) {
  const {
    locationType,
    location,
    propertyGroup,
    measurementSuccesses,
    meterType_,
    meter = null,
    setInstallations,
    setMeasurementSuccesses,
    setCreateNewMeter
  } = props;
  const { i18n } = useAppContext();

  const { getItem, removeItem } = useSessionStorage();

  const submeters = getItem('submeters', [] as MeterType[]);
  const parentMeterRelation = getItem<MeterRelation | null>('parentMeterRelation', null);

  const initialNodeValue: InstallMeterMultiValueType = {
    meter,
    mutationDateTime: moment(MIN_DATE),
    endDateTime: moment(MIN_DATE),
    serviceLocationId: location?.id || '',
    propertyGroupId: propertyGroup?.id || '',
    addressInstalled: propertyGroup?.address || location?.address || null,
    channelTemplates: [] as ExternalChannelType[],
    measurements: [] as AddMeasurementRequestType[],
    channelId: ''
  };

  useEffect(() => {
    // In case we navigate without completing the wizard, prevents sticky storage
    return () => {
      removeItem('submeters');
    };
  }, []);

  return (
    <MultiValue
      id="meter-multi-value"
      title={i18n.getTranslation('install_meter.wizard.meters_which', {
        locationType: i18n.getTranslation(`install_meter.wizard.${locationType}`)
      })}
      initialValues={[initialNodeValue]}
      intialNodeValue={initialNodeValue}
      onChange={(values) => {
        setInstallations(values);
        if (values.length === 0) setCreateNewMeter(false);
      }}
      disabledNodes={measurementSuccesses || undefined}
      enableAddNode={meter || submeters.length > 0 || !!parentMeterRelation ? false : true}
      setDisabledNodes={(val) => {
        if (setMeasurementSuccesses) setMeasurementSuccesses(val);
      }}
    >
      {({ index, value, dispatchValue }) => {
        return (
          <MeterToInstall
            index={index}
            meterType_={meterType_}
            value={value}
            dispatchValue={dispatchValue}
            {...props}
          />
        );
      }}
    </MultiValue>
  );
}
