import { observer } from 'mobx-react';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { culture } from '@zf/api-types/enums';

import css from '../../../../../actions/configuration/update-translations-dialog.module.scss';
import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import TranslationInputField from './components/TranslationInputField';
import TranslationOptions from './TranslationOptions';

type Props = {
  rowIndex: number;
};

const UpdateTemplateTranslationsDialog = forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { rowIndex } = props;
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { selectedTranslation, translationIsDirty, updateScenarioTranslations, initTranslationsForm } =
    communicationStore.translationStore;

  useEffect(() => {
    initTranslationsForm(selectedTranslation);
  }, []);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (translationIsDirty) {
          await updateScenarioTranslations(rowIndex);
        }

        notify.success({
          content: getTranslation('communication.update_scenariotranslation_success', {
            translationName: selectedTranslation.name
          })
        });
      } catch (error) {
        notify.error({
          content: getTranslation('communication.update_scenariotranslation_fail', {
            translationName: selectedTranslation.name
          }),
          error
        });
      }
    }
  }));

  return (
    <>
      <div className={css['translations-grid']}>
        <div key={'translations-block'}>
          <div className={css['field-translations']}>
            <br />
            {Object.keys(selectedTranslation.translations).map((key) => {
              const casted = key as culture;
              const translation = selectedTranslation.translations[casted];

              return <TranslationInputField key={key} culture={casted} translation={translation} />;
            })}
          </div>
        </div>
      </div>
      <div className={css['translation-options']}>
        <TranslationOptions rowIndex={rowIndex} />
      </div>
    </>
  );
});

export default observer(UpdateTemplateTranslationsDialog);
