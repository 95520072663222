import React from 'react';

import StellaPasswordInput, { PasswordInputProps } from '@zf/stella-react/src/atoms/InputField/stella-password-input';

import AccessControl from '../Permissions/AccessControl';

export default function PasswordInput(props: PasswordInputProps) {
  const { id } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<StellaPasswordInput {...props} readonly />}>
      <StellaPasswordInput {...props} />
    </AccessControl>
  );
}
