import { notify } from 'events/notification-events';
import { observer } from 'mobx-react';
import React, { Dispatch, Fragment, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { MeteringIssueStatus } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import SubTitle from '@zf/stella-react/src/atoms/Tabs/SubTitle';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import { NavigatorType } from '../../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../../components/RouteWrapper';
import GenericTabContainer from '../../../../components/Tab/GenericTabContainer';
import { Link } from '../../../../design-system/Components';
import { useStore } from '../../../../hooks/useStore';
import Route from '../../../route';
import MeterDetail from './detail/MeterDetail';
import MeterIssuesTab from './issues/MeterIssuesTab';
import MeterActions from './MeterActions';
import Output from './output/Output';
import UnresolvedIssuesBadge from './UnresolvedIssuesBadge';
import { MeterIssuesContext, MeterIssuesProvider } from './Context/Context';

type Props = {
  meter: MeterType;
  status: JSX.Element;
  activeState?: MeterStatusHistoryType;
  navigator: NavigatorType;
  timeStamp: string;
  refresh: () => void;
  navigate: (href: string) => void;
  setMeter: Dispatch<SetStateAction<MeterType | null>>;
};

const MeterTabContainer = (props: Props) => {
  const { meter, status, activeState, navigator, timeStamp, refresh, navigate, setMeter } = props;

  const { applicationStore, meterStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { organisationCfg, meteringIssueService } = meterStore;
  const { getMeterIssues, getMeterIssuesOverviewCounts } = meteringIssueService;

  const [issueState, setIssueState] = useState<{
    issues: MeteringIssue[];
    unresolvedIssues: MeteringIssue[];
    issuesCount: number;
    unresolvedIssuesCount: number;
  }>();

  //GET METERING ISSUES for Export, Tab & MeterIssuesCard
  //GET OVERVIEW COUNT FOR THE BADGES
  //only if the feature flag is eneabled
  useEffect(() => {
    if (organisationCfg?.features.meteringValidationEnabled) {
      Promise.all([getMeterIssues({ meterIds: [meter.id] }), getMeterIssuesOverviewCounts({ meterIds: [meter.id] })])
        .then((res) => {
          setIssueState({
            issues: res[0].results,
            unresolvedIssues: res[0].results.filter((i) => i.status === MeteringIssueStatus.unresolved),
            issuesCount: res[1].all,
            unresolvedIssuesCount: res[1].unresolved
          });
        })
        .catch((error) => {
          notify.error({
            content: getTranslation('meter_issues.get_issues_fail'),
            error
          });
        });
    }
  }, [meter.id, timeStamp]);

  useBrowserTitle(`${getTranslation('meter.meter')} - ${meter.serialNumber}`);

  let tabItems: TabItemType[] = [];

  if (organisationCfg?.features.meteringValidationEnabled) {
    tabItems = [
      {
        id: 'detail',
        title: getTranslation('nav_detail.detail'),
        isDefault: true
      },
      {
        id: 'output',
        title: getTranslation('meter.output')
      },
      {
        id: 'issues',
        title: getTranslation('meter_issues.issues'),
        badge: issueState ? <UnresolvedIssuesBadge unresolvedIssues={issueState.unresolvedIssuesCount} /> : undefined,
        hasListPage: true
      }
    ];
  } else {
    tabItems = [
      {
        id: 'detail',
        title: getTranslation('nav_detail.detail'),
        isDefault: true
      },
      {
        id: 'output',
        title: getTranslation('meter.output')
      }
    ];
  }

  const routes = (
    <RouteWrapper default>
      <Route
        path="detail"
        Component={MeterDetail}
        timeStamp={timeStamp}
        meter={meter}
        activeState={activeState}
        issues={issueState?.unresolvedIssues}
        issuesCount={issueState?.unresolvedIssuesCount}
        setMeter={setMeter}
        refresh={refresh}
        navigate={navigate}
        default
      />
      <Route path="output" Component={Output} meter={meter} timeStamp={timeStamp} />
      <Route path="issues" Component={MeterIssuesTab} meter={meter} refresh={refresh} />
    </RouteWrapper>
  );

  let subTitle: ReactNode = '';

  if (meter.parentMeterRelation) {
    const titleBody = [
      <Fragment key="linked_to">
        {getTranslation('meter.linked_to')}
        <Link
          id={`meter-${meter.parentMeterRelation.meterId}`}
          url={`${rootUrl}/devices/meters/${meter.parentMeterRelation.meterId}`}
          sub
        >
          {meter.parentMeterRelation.serialNumber}
        </Link>
        {`(ref: ${meter.parentMeterRelation.externalReference})`}
      </Fragment>
    ];

    if (meter.propertyGroup) {
      titleBody.push(
        <Fragment key="installed_on">
          {`, ${getTranslation('meter.installed_on')} `}
          <Link
            id={`property-${meter.propertyGroup.id}`}
            url={`${rootUrl}/property-groups/${meter.propertyGroup.id}`}
            sub
          >
            {meter.propertyGroup.name}
          </Link>
        </Fragment>
      );
    }

    subTitle = <SubTitle>{titleBody}</SubTitle>;
  } else if (meter.propertyGroup) {
    subTitle = (
      <SubTitle>
        {getTranslation('meter.installed_on')}
        <Link
          id={`property-${meter.propertyGroup.id}`}
          url={`${rootUrl}/property-groups/${meter.propertyGroup.id}`}
          sub
        >
          {meter.propertyGroup.name}
        </Link>
      </SubTitle>
    );
  }

  return (
    <MeterIssuesProvider>
      <MeterIssuesContext.Consumer>
        {({ store: { filter, listPage }, setFilter }) => (
          <GenericTabContainer
            actions={<MeterActions meter={meter} activeState={activeState} setMeter={setMeter} refresh={refresh} />}
            entityId={meter.serialNumber}
            status={status}
            entityType={routedEntitySubjectType.meter}
            navigate={navigate}
            navigator={navigator}
            tabItems={tabItems}
            routes={routes}
            iconName={meter.parentMeterRelation ? 'meter-linked' : 'meter'}
            subTitle={subTitle}
          />
        )}
      </MeterIssuesContext.Consumer>
    </MeterIssuesProvider>
  );
};

export default observer(MeterTabContainer);
