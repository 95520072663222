import { observer } from 'mobx-react';
import React from 'react';

import { BreadCrumb, Crumb, CrumbDivider } from '../../../../../components/BreadCrumb';
import { CrumbHeader } from '../../../../../components/Header';
import { useStore } from '../../../../../hooks/useStore';

const ContractDetailHeader = observer(() => {
  const { contractStore, applicationStore } = useStore();

  return (
    <CrumbHeader
      breadcrumb={
        <BreadCrumb>
          <Crumb
            icon="list"
            text={applicationStore.getTranslation('contracts.contracts')}
            url={`${applicationStore.rootUrl}/contracts`}
          />
          <CrumbDivider />
          <Crumb icon="contract" text={contractStore.selectedContract.crumbText} />
        </BreadCrumb>
      }
    />
  );
});

export default ContractDetailHeader;
