import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle } from 'react';

import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';

import FlowsDropdown from '../../../../components/Dropdown/flows-dropdown/FlowsDropdown';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import css from './assign-to-case-dialog.module.scss';

type Props = {
  validationRef: MutableRefObject<ValidationRef | undefined>;
  onComplete?: () => void;
  selectedRows?: InvoiceRowType[];
  invoice?: InvoiceType;
};

const AssignToCaseDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, validationRef } = props;
  const { applicationStore, invoiceStore } = useStore();
  const { getTranslation } = applicationStore;

  useEffect(() => {
    invoiceStore.initAssignToCaseForm({
      flowId: ''
    });
  }, []);

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!!invoiceStore.assignToCaseForm._error('flowId'));
    }
  };

  useEffect(() => {
    autorun(() => {
      validate();
    });
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (selectedRows && onComplete) {
        try {
          await Promise.all(
            selectedRows.map((r) =>
              invoiceStore.assignToCase(r.__entity, invoiceStore.assignToCaseForm._get('flowId') || '', false)
            )
          );

          onComplete();

          notify.success({
            content: getTranslation('actions.invoice.assign_to_case_success')
          });
        } catch (error) {
          notify.error({
            content: getTranslation('actions.invoice.assign_to_case_fail'),
            error
          });
        }
      } else {
        invoiceStore.assignToCaseForm.submit();
      }
    }
  }));

  return invoiceStore.assignToCaseForm ? (
    <>
      <Paragraph>
        {getTranslation(
          `actions.invoice.${
            selectedRows && selectedRows.length > 1 ? 'assign_to_case_paragraph_multi' : 'assign_to_case_paragraph'
          }`
        )}
      </Paragraph>
      <Center>
        <div className={css['input']}>
          <FlowsDropdown
            id="collection-flow"
            selectedValue={invoiceStore.assignToCaseForm._get('flowId') || ''}
            onChange={(val) => invoiceStore.assignToCaseForm._set('flowId', val[0]?.id || '')}
            error={!!invoiceStore.assignToCaseForm._error('flowId')}
            selectSingleItemByDefault
          />
        </div>
      </Center>
    </>
  ) : null;
});

export default observer(AssignToCaseDialog);
