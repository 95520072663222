import React from 'react';
import classNames from 'classnames';

import css from './center.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  type?: 'horizontal' | 'vertical' | 'both';
  style?: React.CSSProperties;
};

export default function Center(props: Props) {
  const { children, className, type = 'horizontal' } = props;

  return (
    <div
      className={classNames(
        css['center'],
        {
          [css['horizontal']]: type === 'horizontal',
          [css['vertical']]: type === 'vertical',
          [css['both']]: type === 'both'
        },
        className
      )}
    >
      {children}
    </div>
  );
}
