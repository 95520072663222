import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../app-context';
import { UnbilledState } from '../../../cards/Unbilled/context/unbilled-context';
import CloseManuallyDialog from '../../../cards/Unbilled/dialogs/CloseManuallyDialog';
import { RowTypeDetails } from '../../../cards/Unbilled/hooks/useUnbilled';
import { RowTypeUnbilled } from '../../../cards/Unbilled/hooks/useUnbilledActions';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  completenesses: BillingCompletenessType[];
  setState: React.Dispatch<Partial<UnbilledState>>;
  updateGivenRows: (
    updatedRecords: BillingCompletenessType[],
    deletedRecords?: (RowTypeDetails | RowTypeUnbilled)[]
  ) => Promise<RowTypeUnbilled[]>;
};

export default function ManuallyCloseListItem(props: Props) {
  const { completenesses, setState, updateGivenRows } = props;
  const { i18n } = useAppContext();

  const { clickRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="completeness.manually_close"
      dialogTitle={i18n.getTranslation('completeness.close_manually')}
      icon="cancel"
      dialogContent={
        <CloseManuallyDialog
          ref={clickRef}
          completenesses={completenesses}
          updateGivenRows={updateGivenRows}
          setState={setState}
        />
      }
      disabled={completenesses.length === 0}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('general.close')}
    />
  );
}
