import { AdvanceAmountLimitType, pluginType } from '@zf/api-types/enums';
import { AdvanceLimit, BillingSettings } from '@zf/api-types/integration/plugin';

import { notify } from '../../../../../events/notification-events';
import BaseFormVjf, { Fields } from '../../../forms/BaseFormVjf';
import IntegrationStore from '../../IntegrationStore';

export enum portalSettingsFields {
  rangeMin = 'rangeMin',
  rangeMax = 'rangeMax',
  type = 'type',
  allowCustomerToChangeAdvanceAmount = 'allowCustomerToChangeAdvanceAmount',
  hideInvoiceSection = 'hideInvoiceSection'
}

type FormDataType = AdvanceLimit & BillingSettings;

export default class PortalSettingsForm extends BaseFormVjf<FormDataType> {
  private integrationStore: IntegrationStore;

  constructor(initialValues: FormDataType, integrationStore: IntegrationStore) {
    super(initialValues);
    this.integrationStore = integrationStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: portalSettingsFields.type,
          label: 'type',
          validators: [this.isRequired]
        },
        {
          name: portalSettingsFields.rangeMin,
          label: 'rangeMin',
          validators: [this.validateNumeric]
        },
        {
          name: portalSettingsFields.rangeMax,
          label: 'rangeMax',
          validators: [this.validateNumeric]
        },
        {
          name: portalSettingsFields.allowCustomerToChangeAdvanceAmount,
          label: 'allowCustomerToChangeAdvanceAmount'
        },
        {
          name: portalSettingsFields.hideInvoiceSection,
          label: 'hideInvoiceSection'
        }
      ]
    };
  }

  save = async () => {
    const { getTranslation } = this.integrationStore.rootStore.applicationStore;
    const { postConfigCommandForPlugin } = this.integrationStore.customPluginActionsService;
    const { commandName, commandType } = this.integrationStore.customerPortalStore;

    try {
      let rangeMin = this._values.rangeMin;
      let rangeMax = this._values.rangeMax;

      // Decimal percentage conversion
      if (this._values.type === AdvanceAmountLimitType.percentage) {
        rangeMin = rangeMin / 100;
        rangeMax = rangeMax / 100;
      }

      await postConfigCommandForPlugin(pluginType.enduserportal, commandName, {
        _type: commandType,
        $type: "EndUserPortalPlugin.UpdateSettingsConfigCommand",
        advanceChangeLimit: {
          type: this._values.type,
          rangeMin,
          rangeMax
        },
        billingSettings: {
          allowCustomerToChangeAdvanceAmount: this._values.allowCustomerToChangeAdvanceAmount,
          hideInvoiceSection: !this._values.hideInvoiceSection
        }
      });

      this.integrationStore.customerPortalStore.portalSettingsForm = new PortalSettingsForm(
        {
          ...this._values
        },
        this.integrationStore
      );

      notify.success({
        content: getTranslation('portal_config.update_portal_settings_success')
      });
    } catch (error) {
      notify.error({
        content: getTranslation('portal_config.update_portal_settings_fail'),
        error
      });
    }
  };

  hooks() {
    return {
      onSuccess(form: PortalSettingsForm) {
        form.save();
      }
    };
  }
}
