import React, { useMemo } from 'react';

import { Link } from '@zf/heat-erp/src/design-system/Components';
import { colors } from '@zf/utils/src/color';

import { Paragraph } from '../Paragraph';
import css from './action-description.module.scss';

type Props = {
  title: string;
  description: string;
  action: React.ReactNode;
  helpUrl?: string;
};

export default function ActionDescription(props: Props) {
  const { title, description, action, helpUrl } = props;

  const descriptivePart = useMemo(() => {
    return (
      <div className={css['info']}>
        <Paragraph color={colors['graphite']}>{title}</Paragraph>
        {helpUrl ? (
          <Link id={`help-link-${helpUrl}`} url={helpUrl} newTab>
            {description}
          </Link>
        ) : (
          <Paragraph>{description}</Paragraph>
        )}
      </div>
    );
  }, [title, description, helpUrl]);

  return (
    <div className={css['action-description']}>
      {descriptivePart}
      <div>{action}</div>
    </div>
  );
}
