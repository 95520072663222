import moment from 'moment';
import React, {
  Dispatch,
  forwardRef,
  MutableRefObject,
  Ref,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { ZFErrorType } from '@zf/api-types/general';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';
import { isMinDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import DatePicker from '../../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import { createOrRecalculateInvoice } from '../apiFunctions';
import { RowTypeDetails, SetState } from '../hooks/useUnbilled';
import { RowTypeUnbilled } from '../hooks/useUnbilledActions';
import css from './create-invoice-dialog.module.scss';

type Props = {
  currentCompleteness?: BillingCompletenessType;
  untilDateTime?: string;
  completenesses?: BillingCompletenessType[];
  validationRef: MutableRefObject<ValidationRef | undefined>;
  setCurrentCompleteness?: Dispatch<SetStateAction<BillingCompletenessType | undefined>>;
  updateGivenRows: (
    updatedRecords: BillingCompletenessType[],
    deletedRecords?: (RowTypeDetails | RowTypeUnbilled)[]
  ) => Promise<RowTypeUnbilled[]>;
  setState: Dispatch<SetState>;
};

export default forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const {
    currentCompleteness,
    untilDateTime,
    completenesses,
    validationRef,
    setCurrentCompleteness,
    updateGivenRows,
    setState
  } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { contractStore } = useStore();

  const [advanceUntilDateTime, setAdvanceUntilDateTime] = useState(untilDateTime ? moment(untilDateTime) : moment());

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(isMinDate(advanceUntilDateTime));
    }
  };

  const notifySuccess = () => {
    notify.success({
      content: i18n.getTranslation('invoice.advance_successful')
    });
  };

  const notifyFail = (e: ZFErrorType) => {
    notify.error({
      content: i18n.getTranslation('invoice.advance_fail'),
      error: e
    });
  };

  useEffect(() => {
    validate();
  }, [advanceUntilDateTime]);

  let timeOut: number;

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (currentCompleteness) {
        try {
          const updatedCompletness = await createOrRecalculateInvoice(
            true,
            false,
            advanceUntilDateTime,
            currentCompleteness.id,
            tenantReducer,
            i18n.lang
          );

          setState({ actionTriggered: true, firstRefresh: true });

          if (setCurrentCompleteness) setCurrentCompleteness(updatedCompletness);

          timeOut = window.setTimeout(
            () => setState({ timeStamp: Date.now(), currentId: updatedCompletness.id, firstRefresh: false }),
            5000
          );
          updateGivenRows([updatedCompletness]);
          await contractStore.refresh();

          notifySuccess();
        } catch (e) {
          // @ts-ignore
          notifyFail(e);
        }
      } else if (completenesses) {
        try {
          const updatedCompletnesses = await Promise.all(
            completenesses.map((c) =>
              createOrRecalculateInvoice(true, false, advanceUntilDateTime, c.id, tenantReducer, i18n.lang)
            )
          );

          setState({ actionTriggered: true, firstRefresh: true });

          timeOut = window.setTimeout(() => setState({ timeStamp: Date.now(), firstRefresh: false }), 5000);

          updateGivenRows(updatedCompletnesses);

          notifySuccess();
        } catch (e) {
          // @ts-ignore
          notifyFail(e);
        }
      }
    }
  }));

  useEffect(() => {
    return () => clearTimeout(timeOut);
  }, []);

  return (
    <>
      <Paragraph>{i18n.getTranslation('invoice.generate_adv_desc')}</Paragraph>
      <InputContainer grid={false}>
        <Center>
          <DatePicker
            id="advance-until-date"
            className={css['advance-until']}
            placeholder={i18n.getTranslation('invoice.advance_until')}
            onChange={setAdvanceUntilDateTime}
            value={advanceUntilDateTime}
          />
        </Center>
      </InputContainer>
    </>
  );
});
