import { removeLocationsWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-out/RemoveLocationsWizardStore';
import { WizardSectionHeader } from 'design-system/Components';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import css from './remove-locations-section.module.scss';
import RemoveLocationsTable from './RemoveLocationsTable';

const RemoveLocationsSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { removeLocationsWizardNavigator } = contractStore.moveOutWizardBaseStore.removeLocationsWizardStore;
  const { setActiveStep } = removeLocationsWizardNavigator;

  return (
    <WizardSection id="locations-section" setActiveStep={() => setActiveStep(removeLocationsWizardSteps.locations)}>
      <WizardSectionHeader
        id={removeLocationsWizardSteps.locations}
        text={getTranslation('contracts.steps.location')}
      />

      <Paragraph className={css['select-locations']} bold>
        {getTranslation('contracts.wizard.terminate.select_locations')}
      </Paragraph>
      <RemoveLocationsTable />
    </WizardSection>
  );
};

export default observer(RemoveLocationsSection);
