import moment from 'moment';

import { contractStatus } from '@zf/api-types/enums';
import { ServiceLocationType, ServiceType, ShortContractType } from '@zf/api-types/master-data/servicelocation';
import { betweenDates, sortByDate } from '@zf/utils/src/date';

export const processAutoFillLocation = (location: ServiceLocationType) => {
  return { value: location, text: location.address.localizedDisplay };
};

export const getLatestContractForLocation = (location: ServiceLocationType) => {
  const contracts = location.services.flatMap((s) => s.contracts);

  return sortByDate(contracts, 'supplyEndDateTime', 'DESC')?.[0];
};

export const getCurrentContract = (service: ServiceType) => {
  const today = moment();
  let statusMap = {} as Record<contractStatus, ShortContractType>;

  service.contracts.forEach((c) => {
    if (betweenDates(c.supplyStartDateTime, c.supplyEndDateTime, today)) {
      statusMap[c.currentContractStatus] = c;
    }
  });

  let currentContract: ShortContractType | undefined = undefined;

  if (statusMap[contractStatus.signed]) {
    currentContract = statusMap[contractStatus.signed];
  } else if (statusMap[contractStatus.draft]) {
    currentContract = statusMap[contractStatus.draft];
  } else if (statusMap[contractStatus.terminated]) {
    currentContract = statusMap[contractStatus.terminated];
  } else {
    currentContract = statusMap[contractStatus.cancelled];
  }

  return currentContract;
};
