import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionStatus } from '@zf/api-types/enums';

import OutgoingBankingTransactionExport from '../../../../../components/Button/Excel/OutgoingBankingTransactionExport';
import CancelDetailButton from '../../../../../components/list-items/outgoing-banking-transactions/CancelDetailButton';
import DeleteDetailButton from '../../../../../components/list-items/outgoing-banking-transactions/DeleteDetailButton';
import DownloadDetailButton from '../../../../../components/list-items/outgoing-banking-transactions/DownloadDetailButton';
import ManuallyConfirmDetailButton from '../../../../../components/list-items/outgoing-banking-transactions/ManuallyConfirmDetailButton';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  setOutgoingBankingTransaction: React.Dispatch<React.SetStateAction<OutgoingBankingTransactionType | null>>;
  refresh: () => void;
};

const OutgoingBankingTransactionActions = (props: Props) => {
  const { outgoingBankingTransaction, setOutgoingBankingTransaction, refresh } = props;

  return (
    <>
      {outgoingBankingTransaction.status === outgoingBankingTransactionStatus.toconfirmbybank && (
        <ManuallyConfirmDetailButton
          bankingTransaction={outgoingBankingTransaction}
          setOutgoingBankingTransaction={setOutgoingBankingTransaction}
        />
      )}

      {((outgoingBankingTransaction.status !== outgoingBankingTransactionStatus.created &&
        outgoingBankingTransaction.status !== outgoingBankingTransactionStatus.resolveissues) ||
        (outgoingBankingTransaction.status !== outgoingBankingTransactionStatus.created &&
          outgoingBankingTransaction.status !== outgoingBankingTransactionStatus.resolveissues &&
          outgoingBankingTransaction.migrated &&
          !outgoingBankingTransaction.internalFilePath)) && (
        <DownloadDetailButton outgoingBankingTransaction={outgoingBankingTransaction} refresh={refresh} />
      )}

      {outgoingBankingTransaction.status === outgoingBankingTransactionStatus.toconfirmbybank && (
        <CancelDetailButton
          bankingTransaction={outgoingBankingTransaction}
          setOutgoingBankingTransaction={setOutgoingBankingTransaction}
        />
      )}

      <OutgoingBankingTransactionExport outgoingBankingTransactionId={outgoingBankingTransaction.id} />

      {outgoingBankingTransaction.status !== outgoingBankingTransactionStatus.closed &&
        outgoingBankingTransaction.status !== outgoingBankingTransactionStatus.toconfirmbybank &&
        outgoingBankingTransaction.status !== outgoingBankingTransactionStatus.cancelled && (
          <DeleteDetailButton bankingTransaction={outgoingBankingTransaction} />
        )}
    </>
  );
};

export default OutgoingBankingTransactionActions;
