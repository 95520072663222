import React from 'react';

import { TemplateAttachment } from '@zf/api-types/config/scenarios_new';
import { communicationType } from '@zf/api-types/enums';
import { SimpleValidatorReturnType } from '@zf/hooks/src/useSimpleValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import InputField from '../../../../components/input/InputField';
import { ScenarioUploadInput } from '../../../../components/input/ScenarioUploadInput';
import Select from '../../../../components/input/Select';
import { Info } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import { StepValidatorType } from './step-wizard';
import css from './step-wizard.module.scss';
import TemplateSection from './TemplateSection';

type Props = {
  stepValidatorTools: SimpleValidatorReturnType<StepValidatorType>;
  stepNumber: string | number;
};

export default function CommunicationSection(props: Props) {
  const { stepValidatorTools, stepNumber } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const { values, setValue } = stepValidatorTools;

  return (
    <>
      <Heading headingType="h3" style="bold">
        {getTranslation('collection_flows.communication')}
      </Heading>
      <InputContainer>
        <Select
          id={`communication_type-${stepNumber}`}
          onChange={(val) => setValue({ communicationType: val[0] })}
          selectedValues={[values.communicationType]}
          values={[
            {
              icon: 'at',
              value: communicationType.email,
              text: getEnumTranslation('communicationType', communicationType.email)
            },
            {
              icon: 'enveloppe',
              value: communicationType.manual,
              text: getEnumTranslation('communicationType', communicationType.manual)
            }
          ]}
        />
      </InputContainer>

      {values.communicationType === communicationType.manual && (
        <Info className={css['info-paragraph']}>{getTranslation('collection_flows.communication_info')}</Info>
      )}

      <Heading headingType="h3" style="bold">
        {getTranslation('collection_flows.subject_heading')}
      </Heading>
      <InputContainer>
        <InputField
          id={`subject-${stepNumber}`}
          value={values.communicationSubject}
          onChange={(val) => setValue({ communicationSubject: val })}
          placeholder={getTranslation('collection_flows.subject')}
          error={!values.communicationSubject}
        />
      </InputContainer>

      <TemplateSection stepValidatorTools={stepValidatorTools} stepNumber={stepNumber} />

      <Heading headingType="h3" style="bold">
        {getTranslation('attachment.attachments')}
      </Heading>
      <ScenarioUploadInput
        id={`scenario.attachment-${stepNumber}`}
        files={values.communicationAttachments || []}
        setFiles={(files) => setValue({ communicationAttachments: files as TemplateAttachment[] })}
        viewAsColumns
      />
    </>
  );
}
