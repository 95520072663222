import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { contactType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import useSimpleValidator from '@zf/hooks/src/useSimpleValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import ContactDetailsMultiValue from '../../features/customer/wizard/communication-section/ContactDetailsMultiValue';
import PrimaryContactDetails from '../../features/customer/wizard/communication-section/PrimaryContactDetails';
import { validateEmail } from '../../utils/email';
import { METHODS, sendRequest } from '../../utils/request';
import css from './contact-card.module.scss';
import { ContactValidatorType } from './ContactCard';

type Props = {
  customerId: string;
  primaries: ContactEntryType[];
  secondaries: ContactEntryType[];
  groupedByDescription: Record<string, ContactEntryType[]>;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType | null>>;
};

const EditContactDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { customerId, primaries, secondaries, groupedByDescription, validationRef, setCustomer } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const [contactTypes] = useState(enumReducer.getEnum<contactType>('contactType'));

  const generatePrimaries = () => {
    let initialPrimaries = [...primaries];

    contactTypes.forEach((ct) => {
      if (!initialPrimaries.find((p) => p.contactType === ct.value)) {
        initialPrimaries.push({
          contactType: ct.value,
          value: '',
          description: '',
          primaryForType: true
        });
      }
    });

    return initialPrimaries;
  };

  const { values, errors, setValue } = useSimpleValidator<ContactValidatorType>({
    initialValues: {
      primaryContactDetails: generatePrimaries(),
      secondaryContactDetails: [...secondaries]
    },
    validate: () => {
      const feedback: boolean[] = [];

      const primaryEmailsValid = values.primaryContactDetails.reduce((acc: boolean[], detail) => {
        if (detail.contactType === contactType.email && !!detail.value) {
          acc.push(validateEmail(detail.value));
        }

        return acc;
      }, []);

      const secondaryEmailsValid = values.secondaryContactDetails.reduce((acc: boolean[], detail) => {
        if (detail.contactType === contactType.email && !!detail.value) {
          acc.push(validateEmail(detail.value));
        }

        return acc;
      }, []);

      feedback.push(primaryEmailsValid.includes(false) || secondaryEmailsValid.includes(false));
      feedback.push(values.primaryContactDetails.length === 0);

      return feedback;
    }
  });

  const validate = () => {
    if (validationRef.current) validationRef.current.setIsError(errors.includes(true));
  };

  useEffect(() => {
    validate();
  }, [errors]);

  const updateContactDetails = async () => {
    const contactDetails = [...values.primaryContactDetails, ...values.secondaryContactDetails].filter(
      (cd) => cd.value !== ''
    );

    return (
      await sendRequest<CustomerType>({
        request: {
          method: METHODS.POST,
          endpoint: `/md/Customers/${customerId}/contactdetails`,
          data: {
            contactEntries: contactDetails
          }
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newCustomer = await updateContactDetails();
        setCustomer(newCustomer);

        notify.success({
          content: i18n.getTranslation('customer.edit_contact_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('customer.edit_contact_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['dialog-wrapper']}>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('customer.primary_contact_details')}
      </Heading>
      <PrimaryContactDetails values={values} setValue={setValue} />
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('customer.extra_contact_details_heading')}
      </Heading>
      <ContactDetailsMultiValue groupedByDescription={groupedByDescription} setValue={setValue} />
    </div>
  );
});

export default EditContactDialog;
