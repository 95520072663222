import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../../../app-context';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { ICON_COLORS } from '../../../../../../constants/icons';
import useCreateRequest from '../../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../../hooks/useInfiniAPI';
import { getCurrentMeterStatus } from '../../../../../../utils/meter';
import DeviceLink from '../../../../../devices/shared/DeviceLink';
import NoMetersOverlay from './NoMetersOverlay';
import MeterIssueIndicator from 'design-system/Components/MeterIssueIndicator/MeterIssueIndicator';

type Props = {
  propertyGroupId: string;
  timeStamp: string;
  models: ModelType[];
};

type MeterRowType = {
  isUnhealthy: JSX.Element | null;
  __id: string;
  serialNumber: JSX.Element;
  status: JSX.Element | undefined;
  brand: string;
  model: string;
  lastTimeReceivedData: string;
  measurementsUntil: string;
  mutationDate: string | undefined;
  meterType: string;
};

export default function MeterTable(props: Props) {
  const { propertyGroupId, models } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const [columns] = React.useState([
    {
      width: 40,
      label: '',
      dataKey: 'isUnhealthy'
    },
    {
      width: 180,
      label: i18n.getTranslation('meter.meter'),
      dataKey: 'serialNumber'
    },
    {
      width: 120,
      label: i18n.getTranslation('general.status'),
      dataKey: 'status'
    },
    {
      width: 140,
      label: i18n.getTranslation('meter.brand'),
      dataKey: 'brand'
    },
    {
      width: 200,
      label: i18n.getTranslation('meter.model'),
      dataKey: 'model'
    },
    {
      width: 200,
      label: i18n.getTranslation('metering_list.labels.last_received'),
      dataKey: 'lastTimeReceivedData'
    },
    {
      width: 200,
      label: i18n.getTranslation('metering_list.labels.measurementsUntil'),
      dataKey: 'measurementsUntil'
    },
    {
      width: 170,
      label: i18n.getTranslation('meter.mutation_date'),
      dataKey: 'mutationDate'
    },
    {
      width: 110,
      label: i18n.getTranslation('meter.meter_type'),
      dataKey: 'meterType'
    }
  ]);

  const { request, setSelectedIds, handleSort, sortBy, sortDirection } = useCreateRequest('/md/meters', {
    propertyGroupIds: [propertyGroupId]
  });

  const processRecord = (meter: MeterType): MeterRowType => {
    const currentStatus = getCurrentMeterStatus(meter, i18n);

    const matchedModel = models.find((m) => {
      return m.id === meter.modelId;
    });

    return {
      isUnhealthy: meter.hasIssue ? (
        <MeterIssueIndicator
          usage="meter"
          id={meter.id}
          tooltipId={meter.serialNumber}
          tooltipExtra={meter.issuesCount}
        />
      ) : null,
      __id: meter.id,
      serialNumber: <DeviceLink device={meter} />,
      status: <NewStatusBadge color={ICON_COLORS[currentStatus.meterStatus]} status_={currentStatus.meterStatus} />,
      brand: matchedModel ? matchedModel.brandName : '',
      model: matchedModel ? matchedModel.name : '',
      lastTimeReceivedData: meter.lastTimeReceivedData
        ? formatDate(meter.lastTimeReceivedData)
        : i18n.getTranslation('metering_list.never'),
      measurementsUntil: meter.measurementsUntil
        ? formatDate(meter.measurementsUntil)
        : i18n.getTranslation('metering_list.none'),
      mutationDate: formatDate(currentStatus.startDateTime),
      meterType: enumReducer.getTranslation('meterType', meter.meterType)
    };
  };

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<MeterType, MeterRowType>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord,
    onSelectRow: setSelectedIds
  });

  return (
    <DynamicVirtualTable
      id="meters-table"
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      rows={rows}
      loading={loading}
      error={error}
      NoDataOverlay={NoMetersOverlay}
      noSelect
      sortableFields={sortableFields}
      sort={handleSort}
      sortBy={sortBy}
      singleSort
      sortDirection={sortDirection}
      totalAmountOfRows={rows.length}
      columns={columns}
    />
  );
}
