import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { EntitySubjectUnionType } from '@zf/api-types/config/scenarios_new';
import { TreeItem } from '@zf/stella-react/src/atoms/Tree';
import SimpleTree from '@zf/stella-react/src/atoms/Tree/SimpleTree';

import { useStore } from '../../../../hooks/useStore';
import css from './scenario-tree.module.scss';

type Props = {
  title: JSX.Element;
  entitySubjectType: EntitySubjectUnionType;
  openOnInit?: boolean;
};

const ScenarioTree = (props: Props) => {
  const { title, entitySubjectType, openOnInit = false } = props;

  const { communicationStore } = useStore();
  const { entitySubjectTypeMap, selectedEntitySubjectSubtype, setSubjectTypes } = communicationStore.templatesStore;

  const subtypes = entitySubjectTypeMap[entitySubjectType];

  return (
    <div className={css['tree-wrapper']}>
      <SimpleTree
        className={css['tree']}
        openedStyle={css['tree-selected']}
        title={title}
        arrowType="filled"
        clickeableContent={false}
        openOnInit={openOnInit}
      >
        {subtypes.map((type) => {
          return (
            <TreeItem
              id={`item-${type.value}`}
              key={`item-${type.value}`}
              onClick={() => setSubjectTypes(entitySubjectType, type.value)}
              className={classNames(css['tree-item'], {
                [css['tree-item-selected']]: type.value === selectedEntitySubjectSubtype
              })}
            >
              {type.text}
            </TreeItem>
          );
        })}
      </SimpleTree>
    </div>
  );
};

export default observer(ScenarioTree);
