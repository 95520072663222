import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { navigate } from '@reach/router';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { List } from '@zf/stella-react/src/atoms/List';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { useAppContext } from '../../../app-context';
import useInsights from '../../../app-context/hooks/use-insights';
import { useStore } from '../../../hooks/useStore';
import Button from '../../Button/Button';
import { Icon } from '../../Icon';
import Loader from '../../Lang/Loader';
import css from './organisation-dropdown.module.scss';
import OrganisationDropdownListItem from './OrganisationDropdownListItem';

type Props = {
  currPath: string;
};

const OrganisationDropdown = (props: Props) => {
  const { currPath } = props;
  const { tenantReducer } = useAppContext();
  const { state } = tenantReducer;
  const { tenant, organization, tenantAuth } = state;
  const { insights, getAllOrganisationInsights, setInsights } = useInsights();

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [showTooltip, setShowTooltip] = useState(false);
  const [listItems, setListItems] = useState<JSX.Element[]>([]);

  const clickItem = (url: string) => {
    // Change URL
    navigate(url);

    // Hide dropdown
    setShowTooltip(false);
  };

  useEffect(() => {
    if (showTooltip && !insights) {
      getAllOrganisationInsights().then((res) => {
        if (res) setInsights(res);
      });
    }
  }, [showTooltip]);

  useEffect(() => {
    if (insights && insights.length > 0) {
      const items = tenantAuth?.organizations.map((org) => {
        return (
          <OrganisationDropdownListItem
            key={`${org.organizationId}`}
            currPath={currPath}
            organisation={org}
            clickItem={clickItem}
          />
        );
      });
      if (items) setListItems(items);
    }
  }, [insights, currPath]);

  if (!organization) return null;

  const dropdownContent = (
    <List className={css['list']}>
      {insights
        ? listItems
        : tenantAuth && (
            <Loader
              text={getTranslation('organization.loading', {
                amount: tenantAuth.organizations.length
              })}
            />
          )}
      <Button id="overview" key="overview" type="text" onClick={() => clickItem(`/${tenant?.name}`)}>
        {getTranslation('general.goto_overview')}
      </Button>
    </List>
  );

  return (
    <TooltipTrigger
      tooltip={dropdownContent}
      placement="bottom-end"
      trigger="click"
      setShowTooltip={setShowTooltip}
      showTooltip={showTooltip}
    >
      <div
        className={css['wrapper']}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => setShowTooltip(!showTooltip)}
      >
        <Label className={css['org-label']}>{organization.shortCode}</Label>
        <Icon className={css['down-icon']} type="chevron-down" />
      </div>
    </TooltipTrigger>
  );
};

export default observer(OrganisationDropdown);
