import 'react-virtualized/styles.css';

import React from 'react';
import { TableCellProps } from 'react-virtualized';
import css from './stella-dynamic-index-table.module.scss';

// This is a custom cell rendered, we need this to render components instead of strings
const cellRenderer = ({ cellData }: TableCellProps) => {
  if (!cellData) return null;

  if (React.isValidElement(cellData)) {
    return <div className={css['cell-wrapper']}>{cellData}</div>;
  }

  return <div className={css['cell-wrapper']}>{String(cellData)}</div>;
};

export default cellRenderer;
