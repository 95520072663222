import React from 'react';

import { ContactEntryType } from '@zf/api-types/general';
import { CustomerType } from '@zf/api-types/master-data/customer';
import useDialog from '@zf/hooks/src/useDialog';
import { Card, CardBody, CardEmptyBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import LabelItemGrid from '@zf/stella-react/src/atoms/Card/LabelItemGrid';
import { CoachMark } from '@zf/stella-react/src/atoms/CoachMark';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import EmptyStateImg from '@zf/stella-react/src/atoms/Image/EmptyState/EmptyStateImg';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';
import { groupBy } from '@zf/utils/src/array';

import { useAppContext } from '../../app-context';
import Button from '../../components/Button/Button';
import IconLabel from '../../components/Icon/IconLabel';
import { dialog } from '../../events/dialog-events';
import css from './contact-card.module.scss';
import ContactDetail from './ContactDetail';
import EditContactDialog from './EditContactDialog';

type Props = {
  customer: CustomerType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType | null>>;
};

export type ContactValidatorType = {
  primaryContactDetails: ContactEntryType[];
  secondaryContactDetails: ContactEntryType[];
};

export type GroupedDescriptionType = Record<string, ContactEntryType[]>;

export default function ContactCard(props: Props) {
  const { customer, setCustomer } = props;
  const { i18n, enumReducer } = useAppContext();

  const primaries: ContactEntryType[] = [];
  const secondaries: ContactEntryType[] = [];

  customer.contactDetails.forEach((detail) => {
    detail.primaryForType ? primaries.push(detail) : secondaries.push(detail);
  });

  const groupedByDescription: GroupedDescriptionType = groupBy(secondaries, 'description');
  const groupedByDescriptionKeys = Object.keys(groupedByDescription);

  const { clickRef, validationRef, onSubmit } = useDialog();

  const openDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('customer.edit_contact'),
      icon: 'sign',
      content: (
        <EditContactDialog
          ref={clickRef}
          customerId={customer.id}
          validationRef={validationRef}
          primaries={primaries}
          secondaries={secondaries}
          groupedByDescription={groupedByDescription}
          setCustomer={setCustomer}
        />
      ),
      buttonPositive: i18n.getTranslation('general.edit'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <Card id="contact-card">
      <CardHeader
        extraRight={
          <Button id="customer.update_contactdetails" icon="sign" type="text" onClick={openDialog}>
            {i18n.getTranslation('general.edit')}
          </Button>
        }
      >
        {i18n.getTranslation('customer.contact')}
      </CardHeader>
      {customer.contactDetails.length > 0 ? (
        <CardBody className={css['card-body']}>
          <div className={css['section']}>
            <Label>{i18n.getTranslation('customer.primary_contact_details')}</Label>
            <br />
            <LabelItemGrid withPadding={false}>
              {primaries.map((pcd) => {
                return (
                  <React.Fragment key={`primary-${pcd.contactType}`}>
                    <CardItem width={pcd.description ? '4' : '6'}>
                      <IconLabel
                        iconType={pcd.contactType}
                        labelText={enumReducer.getTranslation('contactType', pcd.contactType)}
                        color
                      />
                    </CardItem>
                    {pcd.description && (
                      <CardItem width={pcd.description ? '2' : '1'}>
                        <TooltipTrigger
                          tooltip={<Paragraph className={css['description']}>{pcd.description}</Paragraph>}
                          trigger="hover"
                        >
                          <CoachMark usePopup={false} size="small" info />
                        </TooltipTrigger>
                      </CardItem>
                    )}
                    <CardItem width="6" justifyContent="end">
                      <ContactDetail contactDetail={pcd} />
                    </CardItem>
                  </React.Fragment>
                );
              })}
            </LabelItemGrid>
          </div>

          {groupedByDescriptionKeys.length > 0 && (
            <>
              <Heading headingType="h3" style="bold">
                {i18n.getTranslation('customer.secondary_contact_details')}
              </Heading>
              {groupedByDescriptionKeys.map((key, index) => {
                const details = groupedByDescription[key];

                return (
                  <div key={`secondary-${key}-${index}`} className={css['section']}>
                    <Label>{key}</Label>

                    <LabelItemGrid withPadding={false}>
                      {details.map((detail, detailIndex) => {
                        return (
                          <React.Fragment key={`${detail.contactType}-${detailIndex}`}>
                            <CardItem width="6">
                              <IconLabel
                                iconType={detail.contactType}
                                labelText={enumReducer.getTranslation('contactType', detail.contactType)}
                              />
                            </CardItem>
                            <CardItem width="6" justifyContent="end">
                              <ContactDetail contactDetail={detail} />
                            </CardItem>
                          </React.Fragment>
                        );
                      })}
                    </LabelItemGrid>
                  </div>
                );
              })}
            </>
          )}
        </CardBody>
      ) : (
        <CardEmptyBody
          icon={<EmptyStateImg title="contact" image="contact" />}
          title={i18n.getTranslation('customer.no_contacts')}
        >
          {i18n.getTranslation('customer.no_contacts_description')}
        </CardEmptyBody>
      )}
    </Card>
  );
}
