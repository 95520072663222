import { TwikeyConfigurationInfo, TwikeyVerificationOptions } from '@zf/api-types/integration/twikey';
import BaseService from '../../../app-context/services/BaseService';

import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { METHODS } from '../../../utils/request';

export default class PaymentsConfigService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('TODO', applicationStore);
  }

  getTwikeyConfigurationInfo = async () => {
    return (
      await this.applicationStore.sendRequest<TwikeyConfigurationInfo>({
        request: {
          method: METHODS.GET,
          endpoint: `/int/Twikey/config`
        }
      })
    ).data;
  };

  updateTwikeyConfigurationInfo = async (verificationOptions: TwikeyVerificationOptions) => {
    return (
      await this.applicationStore.sendRequest<TwikeyVerificationOptions>({
        request: {
          method: METHODS.POST,
          endpoint: `TODO`,
          data: verificationOptions
        }
      })
    ).data;
  };
}
