import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import { supplyDateType } from 'features/contract/src/wizard/shared/SupplyDateInputs/SupplyDateInputs';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { getAdvanceAmountFromContract } from 'utils/contract';

import { BillingRelationType } from '@zf/api-types/billing-relation';
import { contractStatus } from '@zf/api-types/enums';
import { ContractType } from '@zf/api-types/master-data/contract';

import ContractStore from '../../detail-page/ContractStore';
import MoveOutWizardStore from './MoveOutWizardStore';
import RemoveLocationsWizardStore from './RemoveLocationsWizardStore';

export enum moveOutType {
  terminatecontract = 'terminatecontract',
  removelocations = 'removelocations'
}

export default class MoveOutWizardBaseStore {
  public contractStore: ContractStore;

  // A store for each of our move out wizard types
  public moveOutWizardStore: MoveOutWizardStore;
  public removeLocationsWizardStore: RemoveLocationsWizardStore;

  public moveOutType = moveOutType.terminatecontract;
  public contract: ContractType | undefined;

  constructor(contractStore: ContractStore, applicationStore: ApplicationStore) {
    this.contractStore = contractStore;

    this.moveOutWizardStore = new MoveOutWizardStore(this, applicationStore);
    this.removeLocationsWizardStore = new RemoveLocationsWizardStore(this, applicationStore);

    makeObservable(this, {
      moveOutWizardStore: observable,
      removeLocationsWizardStore: observable,
      moveOutType: observable,
      contract: observable,

      setMoveOutType: action,
      setContract: action,
      initMoveOutWizards: action,
      resetStore: action
    });
  }

  setMoveOutType = (moveOutType: moveOutType) => {
    this.moveOutType = moveOutType;
  };

  setContract = (contract: ContractType) => {
    this.contract = contract;

    // Initialize contract based values in both wizards
    this.moveOutWizardStore.moveOutWizardValues.setValue(
      {
        oldContractorInvoiceAddress: contract.billingDetails.invoiceAddress
      },
      true
    );

    if (contract.currentContractStatus === contractStatus.draft) {
      this.removeLocationsWizardStore.removeLocationsWizardValues.setValue({
        supplyDateType_: supplyDateType.startofcontract,
        supplyDate: moment(contract.supplyStartDate)
      });
    }
  };

  initMoveOutWizards = (contract: ContractType, billingRelation: BillingRelationType | null) => {
    const { setValue: setRemoveWizardValue } = this.removeLocationsWizardStore.removeLocationsWizardValues;
    const { setValue: setMoveOutValue } = this.moveOutWizardStore.moveOutWizardValues;

    // Shared
    this.setContract(contract);

    // RemoveLocationsWizard
    setRemoveWizardValue(
      {
        advanceAmount: getAdvanceAmountFromContract(billingRelation, contract),
        removableLocations: contract.serviceLocations.filter((l) =>
          // Only show active locations
          l.services?.some((s) => moment().isBetween(s.startDateTime, s.endDateTime, undefined, '[]'))
        )
      },
      true
    );

    // MoveOutWizard
    setMoveOutValue(
      {
        optionalMeasurements: contract.serviceLocations.map((l) => {
          return {
            meter: null,
            channelId: '',
            endDateTime: '',
            value: undefined,
            hour: undefined,
            minute: undefined
          };
        })
      },
      true
    );
  };

  /**
   * General
   */
  resetStore = () => {
    this.moveOutWizardStore.resetStore();
    this.removeLocationsWizardStore.resetStore();
    this.moveOutType = moveOutType.terminatecontract;
    this.contract = undefined;
  };
}
