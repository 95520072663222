import React from 'react';

import { TenantAuthResponse, TenantOrganisationType, TenantType } from '@zf/api-types/auth';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

export type State = {
  organization: TenantOrganisationType | null;
  tenant: TenantType | null;
  tenantAuth: TenantAuthResponse | null;
  rootUrl: string;
};

export type Dispatch = Partial<State>;

export type TenantReturnValue = {
  state: State & Dispatch;
  dispatch: React.Dispatch<Dispatch>;
};

export default function useTenantReducer(): TenantReturnValue {
  const stateReducer = createStateReducer<State, Dispatch>();
  const [state, dispatch] = React.useReducer(stateReducer, {
    organization: null,
    tenant: null,
    rootUrl: '/',
    tenantAuth: null
  });

  React.useEffect(() => {
    if (!state.organization || !state.tenant) return;

    dispatch({
      rootUrl: `/${state.tenant.name}/${
        state.organization.shortCode ? state.organization.shortCode : state.organization.code
      }`
    });
  }, [state.organization, state.tenant]);

  return {
    state,
    dispatch
  };
}
