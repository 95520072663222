import classNames from 'classnames';
import React, { ReactNode } from 'react';

import css from './side-panel.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

export default function SidePanel(props: Props) {
  const { children, className } = props;

  return <div className={classNames(css['side-panel'], className)}>{children}</div>;
}
