import { attachmentVisibility } from '@zf/api-types/enums';
import clone from 'clone';

import BaseFormVjf, { Fields } from '../../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../../events/notification-events';
import { AddEditType } from '../../Dialog/types';
import { AttachmentState } from './AttachmentGroupBase';
import FilesStore, { attachmentType } from './FilesStore';
import { LocalEntityAttachmentFile } from '../logic/AttachmentFileForm';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

export enum formFields {
  attachmentFiles = 'attachmentFiles',
  visibleInPortal = 'visibleInPortal',
  productAttachmentId = 'productAttachmentId',
  includeInWelcomeMail = 'includeInWelcomeMail',
  approvalRequired = 'approvalRequired',
  validFrom = 'validFrom',
  validUntil = 'validUntil'
}

export type AddEditEntityAttachmentFormFieldTypes = {
  attachmentFiles: LocalEntityAttachmentFile[];
  visibleInPortal: boolean;
};

export default class AddEditEntityAttachmentForm<T> extends BaseFormVjf<AddEditEntityAttachmentFormFieldTypes> {
  public filesStore: FilesStore<T>;

  constructor(filesStore: FilesStore<T>, initialValues: AddEditEntityAttachmentFormFieldTypes) {
    super(initialValues);
    this.filesStore = filesStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: formFields.attachmentFiles,
          label: 'attachmentFiles'
        },
        {
          name: formFields.visibleInPortal,
          label: 'visibleInPortal'
        }
      ]
    };
  }

  save = async (useCase: AddEditType, attType: attachmentType, optionsState?: AttachmentState) => {
    const {
      rootStore,
      addEntityAttachment,
      updateEntityAttachment,
      addEntityAttachmentGroups,
      updateEntityAttachmentGroups
    } = this.filesStore;
    const { culture, getTranslation } = rootStore.applicationStore;

    const { attachmentFiles, visibleInPortal } = this._values;

    try {
      if (attType === attachmentType.entityattachments) {
        if (useCase === 'add') {
          await addEntityAttachment({
            file: attachmentFiles[0].attachmentFileForm._get('file')?.file,
            localisedFileName: attachmentFiles[0].attachmentFileForm._get('fileName'),
            visibility: visibleInPortal ? attachmentVisibility.portal : attachmentVisibility.internal,
            culture: rootStore.customerStore.selectedCustomerCulturePref || culture
          });
        } else {
          await updateEntityAttachment(visibleInPortal ? attachmentVisibility.portal : attachmentVisibility.internal);
        }
      } else if (optionsState) {
        const files = attachmentFiles.reduce((acc: Record<string, File | string | undefined>[], att) => {
          const { file, culture, fileName } = att.attachmentFileForm._values;
          if (file && typeof file.file !== 'undefined') {
            acc.push({ ['file:' + culture]: file.file });
            acc.push({ ['localisedfilename:' + culture]: fileName });
          }
          return acc;
        }, []);

        let request = {
          approvalRequired: optionsState.approvalRequired,
          includeInWelcomeEmail: optionsState.includeInWelcomeEmail,
          validFrom: optionsState.validFrom?.toISOString() || MIN_DATE,
          validUntil: optionsState.validUntil?.toISOString() || MAX_DATE,
          visibility: optionsState.visibility
        };

        const baseRequest = clone(request);

        for (const file of files) {
          request = { ...request, ...file };
        }

        if (useCase === 'add') {
          await addEntityAttachmentGroups(request);
        } else {
          await updateEntityAttachmentGroups(baseRequest, optionsState.productAttachmentId);
        }
      }

      this._reset();

      notify.success({
        content: getTranslation(`entity_attachment.${useCase}_success`)
      });
    } catch (e) {
      notify.error({
        content: getTranslation(`entity_attachment.${useCase}_fail`),
        error: e
      });
    }
  };
}
