import React from 'react';
import { SortDirectionType } from 'react-virtualized';

import Icon from '../../Icon/Icon';
import css from './stella-sort-indicator.module.scss';

type Props = {
  sortDirection: SortDirectionType | '';
};

export default function StellaSortIndicator(props: Props) {
  const { sortDirection } = props;

  if (!sortDirection) {
    return <Icon className={css['icon']} name="sort" />;
  }

  return <Icon className={css['icon']} name={sortDirection === 'ASC' ? 'sort-up' : 'sort-down'} />;
}
