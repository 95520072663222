import { observer } from 'mobx-react';
import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { billingCalculationType, billingItemTariffCalculationType } from '@zf/api-types/enums';
import { ParameterTypesType } from '@zf/api-types/general';
import { BillingItemType, CustomEntityPropertyCalculationTypeParameters, ProductType } from '@zf/api-types/product';
import { TaxCodeType, UpdateTaxCodeType } from '@zf/api-types/tax-codes';

import { DetailLine } from '../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import { formatTaxCode } from '../../../../utils/taxCode';
import TextGenerator from '../../products/billing-items-column/TextGenerator';
import css from './billing-item-body.module.scss';
import BillingItemTariffs from './BillingItemTariffs';

type Props = {
  billingItem: BillingItemType;
  taxCodes: TaxCodeType[];
  customEntityPropertyTypes: CustomEntityPropertyType[];
  calculationTypes: ParameterTypesType[];
  showTariffs?: boolean;
  // Products tab
  product?: ProductType;
  // Billing items tab
  showActions?: boolean;
  initTariffs?: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

const BillingItemBody = (props: Props) => {
  const {
    product,
    billingItem,
    taxCodes,
    customEntityPropertyTypes,
    calculationTypes,
    showActions = false,
    showTariffs = true,
    initTariffs
  } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { isPanelViewMode, productsStore } = productConfigStore;
  const { activeTariff, tariffs_ } = productsStore;
  const matchingProductItem = product?.billingItems.find((b) => b.billingItemId === billingItem.id);

  let taxCodeOrg: UpdateTaxCodeType | undefined = undefined;
  let taxCodePerson: UpdateTaxCodeType | undefined = undefined;

  for (let tc of taxCodes) {
    if (taxCodeOrg && taxCodePerson) break;

    if (tc.id === billingItem.organisationTaxCodeId) {
      taxCodeOrg = tc;
    }

    if (tc.id === billingItem.personTaxCodeId) {
      taxCodePerson = tc;
    }
  }

  const renderCustomEntityProperty = () => {
    if (billingItem.calculationParameters.type === billingCalculationType.customentityproperty) {
      const params = billingItem.calculationParameters as CustomEntityPropertyCalculationTypeParameters;

      const toFind = customEntityPropertyTypes.find((type) => {
        return type.id === params.customEntityPropertyTypeId;
      });

      return (
        <>
          <DetailLine label={getTranslation('settings.custom_entity_property')}>
            <Paragraph>{toFind?.name}</Paragraph>
          </DetailLine>

          <DetailLine label={getTranslation('billing_items.unitOfMeasure')}>
            <Paragraph>{getEnumTranslation('unitOfMeasure', params.unitOfMeasure)}</Paragraph>
          </DetailLine>
        </>
      );
    }
  };

  const renderTierCalculationMethod = () => {
    const detailLine = (
      <DetailLine label={getTranslation('billing_items.tier_calc_method')}>
        <Paragraph>{getEnumTranslation('tierCalculationMethod', billingItem.tierCalculationMethod)}</Paragraph>
      </DetailLine>
    );

    // Tab discriminator
    if (showActions) {
      return detailLine;
    } else if (
      !!product &&
      !!tariffs_ &&
      tariffs_.length !== 0 &&
      activeTariff?.calculationParameters.type !== billingItemTariffCalculationType.unitprice
    ) {
      return detailLine;
    }

    return null;
  };

  return (
    <>
      {isPanelViewMode && showTariffs ? (
        <BillingItemTariffs
          initTariffs={initTariffs}
          billingItemId={billingItem.id}
          selectedProduct={product}
          selectedBillingItem={billingItem}
          showActions={showActions}
          isProductPage={!!product}
        />
      ) : (
        <div className={css['billing-item-body']}>
          <DetailLine label={getTranslation('billing_items.billing_calculation_type')}>
            <Paragraph>
              {getEnumTranslation('billingCalculationType', billingItem.calculationParameters.type)}
            </Paragraph>
          </DetailLine>

          {renderTierCalculationMethod()}

          {renderCustomEntityProperty()}

          <TextGenerator parameters={billingItem.calculationParameters} parameterTypes={calculationTypes} />

          <DetailLine label={getTranslation('billing_items.organisation_tax')}>
            <Paragraph>{formatTaxCode(taxCodeOrg)}</Paragraph>
          </DetailLine>

          <DetailLine label={getTranslation('billing_items.private_customer_tax')}>
            <Paragraph>{formatTaxCode(taxCodePerson)}</Paragraph>
          </DetailLine>

          {matchingProductItem && (
            <>
              <DetailLine label={getTranslation('billing_items.periodicity')}>
                <Paragraph>
                  {getEnumTranslation('productItemPeriodicityType', matchingProductItem.periodicityParameters.type)}
                </Paragraph>
              </DetailLine>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default observer(BillingItemBody);
