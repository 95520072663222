import React from 'react';

import { navigate } from '@reach/router';
import { MeterType } from '@zf/api-types/master-data/meter';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  propertyGroupId?: string;
  location?: ServiceLocationType;
  meter?: MeterType;
  type: string;
};

export default function InstallMeterListItem(props: Props) {
  const { propertyGroupId, location, meter, type } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { tenant, organization } = tenantReducer.state;

  const rootUrl = `/${tenant?.name}/${organization?.shortCode}`;

  const createActionListItem = () => {
    if (location) {
      return (
        <DropdownAction
          id="meter.install"
          icon="meter"
          actionType="direct"
          onClick={() => navigate(`${rootUrl}/devices/install/${type}/${location.id}`)}
        >
          {i18n.getTranslation('actions.install_meter_location')}
        </DropdownAction>
      );
    } else if (meter) {
      return (
        <DropdownAction
          id="meter.install"
          icon="meter"
          actionType="direct"
          onClick={() => navigate(`${rootUrl}/devices/install/${type}/${meter.id}`)}
        >
          {i18n.getTranslation('actions.install_meter_meter')}
        </DropdownAction>
      );
    } else {
      return (
        <DropdownAction
          id="meter.install"
          icon="meter"
          actionType="direct"
          onClick={() => navigate(`${rootUrl}/devices/install/${type}/${propertyGroupId}`)}
        >
          {i18n.getTranslation('actions.install_meter_property')}
        </DropdownAction>
      );
    }
  };

  return createActionListItem();
}
