import Interweave from 'interweave';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { BillingItemType, BillingTariffType, ProductType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

import { Button } from '../../../../design-system/Components';
import { dialog } from '../../../../events/dialog-events';
import { useStore } from '../../../../hooks/useStore';
import { METHODS } from '../../../../utils/request';
import BillingTariff from '../tariff-column/BillingTariff';
import AddEditTariffDialog from '../tariff-column/dialogs/AddEditTariffDialog';
import css from './billing-item-tariffs.module.scss';

type Props = {
  billingItemId: string;
  selectedProduct?: ProductType;
  selectedBillingItem: BillingItemType;
  isProductPage: boolean;
  showActions?: boolean;
  initTariffs?: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

const BillingItemTariffs = (props: Props) => {
  const {
    billingItemId,
    selectedProduct,
    selectedBillingItem,
    isProductPage,
    showActions = false,
    initTariffs
  } = props;
  const { productConfigStore, applicationStore } = useStore();
  const { onSubmit, validationRef, clickRef } = useDialog();
  const { getTranslation, sendRequest } = applicationStore;
  const { productConfigService, filterOnlyUniqueTariffPeriod } = productConfigStore;
  const { addTariff, updateTariff, deleteTariff } = productConfigService;

  const [tariffs, setTariffs] = useState<BillingTariffType[] | undefined>(undefined);

  const addTariffToList = (newTariff: BillingTariffType) => {
    if (tariffs) {
      setTariffs([{ ...newTariff, productId: selectedProduct?.id || '' }, ...tariffs]);
    }
  };

  const updateTariffInList = (updatedTariff: BillingTariffType) => {
    if (tariffs) {
      const indexToUpdate = tariffs.findIndex((t) => t.id === updatedTariff.id);

      if (indexToUpdate !== -1) {
        const cloned = [...tariffs];
        cloned[indexToUpdate] = updatedTariff;
        setTariffs(cloned);
      }
    }
  };

  const deleteTariffInList = (index: number) => {
    if (tariffs) {
      const cloned = [...tariffs];
      cloned.splice(index, 1);
      setTariffs(cloned);
    }
  };

  const opentariffDialog = () => {
    dialog.normal({
      title: getTranslation('billing_tariff.tariff_add'),
      icon: 'plus',
      content: (
        <AddEditTariffDialog
          ref={clickRef}
          validationRef={validationRef}
          tariffs={tariffs || []}
          selectedBillingItem={selectedBillingItem}
          selectedProduct={selectedProduct}
          isProductPage={isProductPage}
          addTariff={addTariff}
          addTariffToList={addTariffToList}
          updateTariff={updateTariff}
          initTariffs={initTariffs}
          updateTariffInList={updateTariffInList}
          canAddInstantly
        />
      ),
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  useEffect(() => {
    sendRequest<PagedResponseType<BillingTariffType>>({
      request: {
        method: METHODS.GET,
        endpoint: '/cfg/BillingTariffs',
        query: {
          billingItemId,
          orderBy: '-StartDateTime'
        }
      }
    }).then((res) =>
      setTariffs(
        filterOnlyUniqueTariffPeriod(
          res.data.results.filter((t) => {
            if (selectedProduct) {
              return t.productId === selectedProduct.id || !t.productId;
            }
            return !t.productId;
          })
        )
      )
    );
  }, [selectedProduct, billingItemId]);

  return !tariffs ? (
    <Spinner size="small" centered />
  ) : (
    <>
      <div className={css['add-tariff']}>
        <Heading headingType="h4" style="bold">
          {getTranslation('product_config.tariffs')}
        </Heading>
        <Button
          id={`${billingItemId}-tariff-add-btn`}
          icon="plus"
          type="text"
          tooltipText={getTranslation('general.add')}
          onClick={opentariffDialog}
        />
      </div>
      <div className={css['tariffs']}>
        {tariffs.length > 0 ? (
          tariffs.map((t, index) => {
            return (
              <BillingTariff
                key={`${t.id}-${index}`}
                index={index}
                tariff={t}
                tariffs={tariffs || []}
                selectedBillingItem={selectedBillingItem}
                addTariff={addTariff}
                addTariffToList={addTariffToList}
                initTariffs={initTariffs}
                updateTariff={updateTariff}
                updateTariffInList={updateTariffInList}
                deleteTariff={deleteTariff}
                deleteTariffInlist={deleteTariffInList}
                showActionsOnDefault={showActions}
                isProductPage={isProductPage}
              />
            );
          })
        ) : (
          <Paragraph className={css['no-tariffs-yet']}>
            <Interweave content={getTranslation('billing_tariff.no_tariffs_yet')} />
          </Paragraph>
        )}
      </div>
    </>
  );
};

export default observer(BillingItemTariffs);
