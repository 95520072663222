import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { AddressType } from '@zf/api-types/general';

import MeterCardBodyContent from './MeterCardBodyContent';

type Props = {
  serviceLocationId: string;
  address: AddressType;
};

const MeterCardBody = (props: Props) => {
  const { meterStore } = useStore();
  const { models, initMeterStore } = meterStore;

  useEffect(() => {
    // This card depends on the meterStore's configuration
    initMeterStore();
  }, []);

  if (!models) return null;

  return <MeterCardBodyContent {...props} models={models} />;
};

export default observer(MeterCardBody);
