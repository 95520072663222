import { uiCulture } from '@zf/api-types/enums';
import { TenantReturnValue } from '../../../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../../../utils/request';

export async function updateCustomerGroupName(
  name: string,
  customerGroupId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/CustomerGroups/${customerGroupId}/updatename`,
        data: { name }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function updateAutomaticSettlement(
  automaticSettlement: null | boolean,
  customerGroupId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/CustomerGroups/${customerGroupId}/settings/automaticsettlement`,
        data: { automaticSettlement }
      },
      tenantReducer,
      lang
    })
  ).data;
}
