import clone from 'clone';
import { observer } from 'mobx-react';
import React from 'react';

import { billingCalculationType, unitOfMeasure } from '@zf/api-types/enums';
import {
  BillingCalculationTypeParametersType,
  ConsumptionCalculationTypeParametersType,
  CustomEntityPropertyCalculationTypeParameters,
  SubscriptionCalculationTypeParametersType
} from '@zf/api-types/product';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import { useStore } from '../../../../../../hooks/useStore';
import css from '../add-edit-billing-item-dialog.module.scss';
import { BillingItemValidatorType } from '../AddEditBillingItemDialog';
import ConsumptionParameters from './ConsumptionParameters';
import CustomEntityPropertyParameters from './CustomEntityPropertyParameters';
import { SubscriptionParameters } from './SubscriptionParameters';

type Props = {
  values: BillingItemValidatorType;
  setValue: (value: Partial<BillingItemValidatorType>) => void;
};

const CalculationParameters = (props: Props) => {
  const { values, setValue } = props;
  const { applicationStore } = useStore();
  const { getEnumTranslation } = applicationStore;

  const setParametersValue = (key: billingCalculationType, value: Partial<BillingCalculationTypeParametersType>) => {
    const clonedCalcParams = clone(values.calculationParameters);
    const entryToUpdate = clonedCalcParams.get(key);

    if (entryToUpdate) {
      clonedCalcParams.set(key, { ...entryToUpdate, ...value });
      setValue({ calculationParameters: clonedCalcParams });
    }
  };

  const uomDropDown: DropdownValuesType<unitOfMeasure>[] = values.supportedUoms.map((uom) => {
    return {
      text: getEnumTranslation('unitOfMeasure', uom),
      value: uom
    };
  });

  const generateParameters = () => {
    switch (values.billingCalculationType) {
      case billingCalculationType.consumption: {
        return (
          <ConsumptionParameters
            uomDropDown={uomDropDown}
            calcParams={
              values.calculationParameters as Map<billingCalculationType, ConsumptionCalculationTypeParametersType>
            }
            setValue={setValue}
            setParametersValue={setParametersValue}
          />
        );
      }
      case billingCalculationType.subscription: {
        return (
          <SubscriptionParameters
            uomDropDown={uomDropDown}
            calcParams={
              values.calculationParameters as Map<billingCalculationType, SubscriptionCalculationTypeParametersType>
            }
            setValue={setValue}
            setParametersValue={setParametersValue}
          />
        );
      }
      case billingCalculationType.customentityproperty: {
        return (
          <CustomEntityPropertyParameters
            uomDropDown={uomDropDown}
            customEntityPropertyType={values.customEntityPropertyType}
            calcParams={
              values.calculationParameters as Map<billingCalculationType, CustomEntityPropertyCalculationTypeParameters>
            }
            setValue={setValue}
            setParametersValue={setParametersValue}
          />
        );
      }
    }
  };

  return <div className={css['parameters']}>{generateParameters()}</div>;
};

export default observer(CalculationParameters);
