import classNames from 'classnames';
import React from 'react';

import { formatDateWMonth } from '@zf/utils/src/date';

import Paragraph from './Paragraph';
import css from './period-paragraph.module.scss';

type Props = {
  id?: string;
  startDate: string;
  endDate: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export default React.memo(function PeriodParagraph(props: Props) {
  const { startDate, endDate, className, style, id } = props;

  return (
    <div id={id} className={classNames(css['period-paragraph'], className)} style={style}>
      <Paragraph>{formatDateWMonth(startDate)}</Paragraph>
      <Paragraph>-</Paragraph>
      <Paragraph>{formatDateWMonth(endDate)}</Paragraph>
    </div>
  );
});
