import classNames from 'classnames';
import React from 'react';

import { formatDate } from '@zf/utils/src/date';

import { EnumKeyType } from '../../app-context/hooks/use-enum';
import { useStore } from '../../hooks/useStore';
import Button from '../Button/Button';
import css from './filter-tags.module.scss';

export type Props = {
  className?: string;
  type?: 'date' | 'period' | 'enum' | 'boolean' | 'array';
  prefix?: string; // used in case of boolean type
  enumType?: string;
  label?: string;
  value: any;
  style?: React.CSSProperties;
  removeFilter?: () => void;
};

export default function FilterTag(props: Props) {
  const { className, style, type, prefix, enumType, value, removeFilter } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const renderTag = () => {
    switch (type) {
      case 'date':
        return formatDate(value);
      case 'period':
        return `${formatDate(value[0])} - ${formatDate(value[1])}`;
      case 'enum':
        return enumType ? getEnumTranslation(enumType as EnumKeyType, value) : '';
      case 'boolean':
        return `${prefix}: ${getTranslation(`general.${value ? 'yes' : 'no'}`)}`;
      case 'array':
        if (Array.isArray(value)) {
          if (enumType) {
            const translated = value.map((v) => getEnumTranslation(enumType as EnumKeyType, v));
            return translated.join(', ');
          } else {
            return value.join(', ');
          }
        }

        return '';
      default:
        return value;
    }
  };

  return (
    <div className={classNames(css['tag'], className)} style={style}>
      <div>{renderTag()}</div>
      {removeFilter && (
        <Button id={new Date().valueOf().toString()} icon="cross" type="text" size="small" onClick={removeFilter} />
      )}
    </div>
  );
}
