import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { AttachmentType } from '@zf/api-types/attachment';
import { entitySubjectType } from '@zf/api-types/enums';
import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { IncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { PropertyGroupBillingPeriodPagedResponseType } from '@zf/api-types/property-group-billing-period';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import ConfigCard from '@zf/stella-react/src/atoms/Card/ConfigCard';
import LabelItemGrid from '@zf/stella-react/src/atoms/Card/LabelItemGrid';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../app-context/app-context';
import useDownload from '../../../../../../app-context/hooks/use-download';
import DownloadIcon from '../../../../../../components/Icon/DownloadIcon';
import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { formatDate, formatPeriod } from '@zf/utils/src/date';
import css from './incoming-invoices-tab.module.scss';

import { colors } from '@zf/utils/src/color';

type Props = {
  invoice: IncomingInvoiceType;
  components: IncomingInvoiceComponentType[];
  propertyGroupId: string;
};

export default function IncomingInvoiceDetails(props: Props) {
  const { invoice, components, propertyGroupId } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { downloadSingleFile } = useDownload();

  const [isLoading, setIsloading] = React.useState(false);

  const periodsResponse = useSuspenseSingleAPI<PropertyGroupBillingPeriodPagedResponseType>({
    request: {
      endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}`,
      query: {
        incomingInvoiceId: invoice.id
      }
    }
  });

  const attachmentResponse = useSuspenseSingleAPI<PagedResponseType<AttachmentType>>({
    request: {
      endpoint: `/att/attachments/${entitySubjectType.incomingInvoice}/${invoice.id}`
    }
  });

  if (!periodsResponse.result || !attachmentResponse.result) return null;

  const attachment = attachmentResponse.result.data.results[0] ? attachmentResponse.result.data.results[0] : undefined;
  const periods = periodsResponse.result.data.results;
  const values = periods[0] ? periods[0].incomingInvoiceComponents : [];

  return (
    <ConfigCard id="incoming-invoices" className={css['incoming-invoice']} hoverable={false}>
      <Heading headingType="h3" style="bold" color={colors['graphite']}>
        {invoice.invoiceNumber}
      </Heading>
      <Paragraph className={css['period']}>{formatPeriod(invoice.startDate, invoice.endDate)}</Paragraph>

      {attachment && (
        <div className={css['download-icon']}>
          {isLoading ? (
            <Spinner size="small" />
          ) : (
            <DownloadIcon
              id={`download-${invoice.invoiceNumber}`}
              onClick={async () => {
                setIsloading(true);
                await downloadSingleFile(`attachments/${attachment.id}`, tenantReducer);
                setIsloading(false);
              }}
            />
          )}
        </div>
      )}

      <LabelItemGrid>
        <CardItem width="6">
          <Label color={colors['silver-700']} bold>
            {i18n.getTranslation('property_groups.invoice_moment')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph bold>{formatDate(invoice.invoiceDate)}</Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label color={colors['silver-700']} bold>
            {i18n.getTranslation('general.description')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph bold>{invoice.description}</Paragraph>
        </CardItem>

        {values.map((v) => {
          const matchingComponent = components.find((c) => {
            return c.id === v.incomingInvoiceComponentId;
          });

          const matchingValue = v.values.find((v_) => {
            return v_.incomingInvoiceId === invoice.id;
          });

          return matchingComponent && matchingValue ? (
            <React.Fragment key={`${invoice.id}-${matchingComponent.id}`}>
              <CardItem width="6">
                <Label>{matchingComponent.description}</Label>
              </CardItem>
              <CardItem width="6">
                <Paragraph>{formatMoney(matchingValue.value, i18n.culture)}</Paragraph>
              </CardItem>
            </React.Fragment>
          ) : null;
        })}

        <CardItem width="6">
          <Label color={colors['silver-700']} bold>
            {i18n.getTranslation('invoice.total_excl_vat')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph bold>{formatMoney(invoice.totalAmountExclVat, i18n.culture)}</Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label color={colors['silver-700']} bold>
            {i18n.getTranslation('invoice.total_incl_vat')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph bold>{formatMoney(invoice.totalAmountInclVat, i18n.culture)}</Paragraph>
        </CardItem>
      </LabelItemGrid>
    </ConfigCard>
  );
}
