import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { BillingRelationType } from '@zf/api-types/billing-relation';
import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { ContractType } from '@zf/api-types/master-data/contract';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import { formatDateWMonth } from '@zf/utils/src/date';

import IconParagraph from '../../../../../../components/Icon/IconParagraph';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { ICON_COLORS } from '../../../../../../constants/icons';
import Paragraph from '../../../../../../design-system/Foundation/Paragraph/Paragraph';
import { useStore } from '../../../../../../hooks/useStore';
import NoInvoicePeriods from '../empty-table-overlays/NoInvoicePeriods';
import RightPart from '../right-part/RightPart';
import css from './billing-insights.module.scss';
import TableHeader from './TableHeader';

type Props = {
  contract: ContractType;
  billingRelation: BillingRelationType;
};

const BillingInsightsContent = observer((props: Props) => {
  const { contract, billingRelation } = props;
  const { contractStore, applicationStore } = useStore();

  const {
    createRequestService,
    selectedCompleteness_,
    infiniAPIService,
    filters,
    setSelectedCompleteness,
    loadBillingCompletenesses,
    getAdvancesCompleteness
  } = contractStore.billingCompletenessStore;

  const { selectedIds, sortBy, sortDirection, handleSort } = createRequestService;

  const processRecord = (billingCompleteness: BillingCompletenessType) => {
    const { currentAdvancesCount, totalAdvancesCount, advanceCompleteness } = getAdvancesCompleteness(
      billingCompleteness.advancePeriods
    );

    const currentProduct = contract.billingDetails.products.find((p) => p.productId === billingCompleteness.productId);

    const startDate = formatDateWMonth(billingCompleteness.periodStartDateTime);

    return {
      __id: billingCompleteness.id,
      __entity: billingCompleteness,
      period: (
        <IconParagraph tooltipFor="invoice-period-table-tip" title={startDate} iconType="date" usedInTable>
          {startDate}
        </IconParagraph>
      ),
      dash: '-',
      p_end: formatDateWMonth(billingCompleteness.periodEndDateTime),
      advances:
        billingCompleteness.advancePeriods.length > 0 ? (
          <Paragraph
            className={classNames(css['completeness'], {
              [css['completeness-25']]: advanceCompleteness < 50,
              [css['completeness-50']]: advanceCompleteness >= 50,
              [css['completeness-100']]: advanceCompleteness === 100
            })}
          >{`${currentAdvancesCount}/${totalAdvancesCount}`}</Paragraph>
        ) : (
          applicationStore.getTranslation('general.na')
        ),
      activeProduct: currentProduct?.productName || '-',
      status: (
        <NewStatusBadge
          status_={applicationStore.getEnumTranslation('billingCompletenessStatus', billingCompleteness.status)}
          color={ICON_COLORS[billingCompleteness.status]}
        />
      )
    };
  };

  useEffect(() => {
    loadBillingCompletenesses(processRecord, billingRelation.id);
  }, [filters, sortBy]);

  if (!infiniAPIService) return <Spinner centered />;

  const { loading, error, sortableFields, rows } = infiniAPIService;

  return (
    <div className={css['wrapper']}>
      <div className={css['left']}>
        <TableHeader />
        <DynamicVirtualTable
          id="invoice-period-table"
          rows={rows}
          selectedRows={selectedIds}
          totalAmountOfRows={rows.length}
          sortBy={sortBy}
          sortDirection={sortDirection}
          sortableFields={sortableFields}
          loading={loading}
          error={error}
          showCheckBoxes={false}
          singleSelect
          invert
          NoDataOverlay={NoInvoicePeriods}
          onSelectRow={setSelectedCompleteness}
          sort={handleSort}
          noDeselect
          columns={[
            {
              width: 150,
              label: applicationStore.getTranslation('general.period'),
              dataKey: 'period'
            },
            {
              width: 30,
              label: '',
              dataKey: 'dash'
            },
            {
              width: 140,
              label: '',
              dataKey: 'p_end'
            },
            {
              width: 120,
              label: applicationStore.getTranslation('analytics.advances'),
              dataKey: 'advances'
            },
            {
              width: 200,
              label: applicationStore.getTranslation('contracts.current_product'),
              dataKey: 'activeProduct'
            },
            {
              width: 219,
              label: applicationStore.getTranslation('general.status'),
              dataKey: 'status'
            }
          ]}
        />
      </div>
      <div className={css['right']}>{selectedCompleteness_ && <RightPart />}</div>
    </div>
  );
});

export default BillingInsightsContent;
