import { observer } from 'mobx-react-lite';
import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ParameterTypesType } from '@zf/api-types/general';
import { CultureTableType } from '@zf/api-types/language';
import { BillingItemType, ProductType } from '@zf/api-types/product';
import { TaxCodeType } from '@zf/api-types/tax-codes';
import ConfigCard from '@zf/stella-react/src/atoms/Card/ConfigCard';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import css from './billing-item.module.scss';
import BillingItemActions from './BillingItemActions';
import BillingItemBody from './BillingItemBody';
import BillingItemHeader from './BillingItemHeader';

export type BillingItemProps = {
  index: number;
  billingItem: BillingItemType;
  taxCodes: TaxCodeType[];
  calculationTypes: ParameterTypesType[];
  customEntityPropertyTypes: CustomEntityPropertyType[];
  isSelected: boolean;
  showTariffs?: boolean;
  // Products tab
  isActive?: boolean;
  product?: ProductType;
  // Billing items tab
  showActions?: boolean;
  showRadioBtn?: boolean;
  onClick?: Function;
  // Used for translations
  cultureTable_?: CultureTableType;
  updateBillingItemInList?: (updatedItem: BillingItemType) => void;
  initTariffs?: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

const BillingItem = (props: BillingItemProps) => {
  const {
    index,
    billingItem,
    product,
    taxCodes,
    customEntityPropertyTypes,
    calculationTypes,
    cultureTable_,
    showTariffs = true,
    isSelected = false,
    showActions = false,
    showRadioBtn = false,
    isActive,
    onClick,
    initTariffs,
    updateBillingItemInList
  } = props;

  return (
    <div>
      <ConfigCard
        id={billingItem.id}
        className={css['billing-item']}
        action={onClick ? () => onClick() : undefined}
        isActive={isSelected}
        buttons={showActions ? <BillingItemActions index={index} billingItem={billingItem} /> : undefined}
        showRadioBtn={showRadioBtn}
      >
        <div className={css['header']}>
          <BillingItemHeader
            index={index}
            product={product}
            isActive={isActive}
            billingItem={billingItem}
            cultureTable_={cultureTable_}
            updateBillingItemInList={updateBillingItemInList}
          />
        </div>

        <HorizontalDivider />

        <BillingItemBody
          initTariffs={initTariffs}
          product={product}
          billingItem={billingItem}
          taxCodes={taxCodes}
          customEntityPropertyTypes={customEntityPropertyTypes}
          calculationTypes={calculationTypes}
          showTariffs={showTariffs}
          showActions={showActions}
        />
      </ConfigCard>
    </div>
  );
};

export default observer(BillingItem);
