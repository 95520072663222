import { MultiValue } from 'design-system/ComponentSets';
import React from 'react';

import { IncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../app-context/app-context';
import MoneyInput from '../../components/input/MoneyInput';
import Dropdown from '../../components/Lang/Dropdown';

type Props = {
  components: IncomingInvoiceComponentType[];
  initialComponents?: NodeValueType[];
  setInvoiceComponents: (newComponents: NodeValueType[]) => void;
};

export type NodeValueType = {
  component: IncomingInvoiceComponentType | null;
  value: number;
};

export default function IncomingInvoiceComponentMultiValue(props: Props) {
  const { components, initialComponents, setInvoiceComponents } = props;
  const { i18n } = useAppContext();

  const initialValue: NodeValueType = {
    component: null,
    value: 0
  };

  const dropdownValues = components.map((c) => {
    return {
      text: c.name,
      value: c
    };
  });

  return (
    <MultiValue
      id="invoice-components"
      title={i18n.getTranslation('cost_allocation_config.incoming_invoice_components_add')}
      initialValues={initialComponents || [initialValue]}
      intialNodeValue={initialValue}
      onChange={setInvoiceComponents}
    >
      {({ index, value, dispatchValue }) => {
        return (
          <InputContainer>
            <Dropdown
              id={`component-dropdown-${index}`}
              key={`component-dropdown-${index}`}
              selectedValues={[value.component?.id || '']}
              values={dropdownValues}
              onChange={(val) => dispatchValue({ component: val[0] })}
              placeholder={i18n.getTranslation('property_groups.tabs.billing.invoice_component')}
              error={!value.component}
            />
            <MoneyInput
              id={`value-${index}`}
              key={`value-${index}`}
              value={value.value}
              onChange={(value) => dispatchValue({ value })}
              placeholder={i18n.getTranslation('general.value')}
              error={!value.value}
            />
          </InputContainer>
        );
      }}
    </MultiValue>
  );
}
