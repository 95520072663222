import React from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { Link } from '../../../../../design-system/Components';
import { useStore } from '../../../../../hooks/useStore';
import css from './collection-sending-details.module.scss';

export default function CollectionSendingDetails() {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div className={css['collection-sending-details']}>
      <Paragraph>{`${getTranslation('communication.collection_sending_options')}`}</Paragraph>
      <Link id="payments" url="payments">{`> ${getTranslation('payments.payments')}`}</Link>
      <Link id="collection-flows" url="payments/collection_flows">{`> ${getTranslation('collection_flows.collection_flows')}`}</Link>
      <Paragraph>{`> ${getTranslation('communication.edit_add_step')}`}</Paragraph>
    </div>
  );
}
