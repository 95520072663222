import React from 'react';

import {
  IncomingBankingTransactionRowType,
  IncomingBankingTransactionType
} from '@zf/api-types/billing/incoming-banking-transaction';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { processIncomingBankingTransaction } from './incoming-banking-transactions';

type Props = {
  // List page
  selectedRows?: IncomingBankingTransactionRowType[];
  setUpdatedRows?: (updatedRows: IncomingBankingTransactionType[]) => void;
  refreshCounts?: () => void;

  // Detail page
  bankingTransaction?: IncomingBankingTransactionType;
  setIncomingBankingTransaction?: React.Dispatch<React.SetStateAction<IncomingBankingTransactionType | null>>;
};

const ProcessIncomingBankingTransactionDialog = React.forwardRef(
  (props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
    const { selectedRows, bankingTransaction, setUpdatedRows, refreshCounts, setIncomingBankingTransaction } = props;
    const { i18n, tenantReducer } = useAppContext();

    const isMulti = selectedRows && selectedRows.length > 1;

    React.useImperativeHandle(ref, () => ({
      async onClick() {
        try {
          if (bankingTransaction && setIncomingBankingTransaction) {
            const updatedTransaction = await processIncomingBankingTransaction(
              bankingTransaction.id,
              tenantReducer,
              i18n.lang
            );

            setIncomingBankingTransaction(updatedTransaction);
          } else if (selectedRows && setUpdatedRows && refreshCounts) {
            const updated = await Promise.all(
              selectedRows.map((r) => {
                return processIncomingBankingTransaction(r.__entity.id, tenantReducer, i18n.lang);
              })
            );

            setUpdatedRows(updated);
            refreshCounts();
          }

          notify.success({
            content: i18n.getTranslation(`actions.banking_transaction.process_success${isMulti ? '_multi' : ''}`)
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation(`actions.banking_transaction.process_fail${isMulti ? '_multi' : ''}`),
            error: e
          });
        }
      }
    }));

    return (
      <Paragraph>
        {i18n.getTranslation(`actions.banking_transaction.process_paragraph${isMulti ? '_multi' : ''}`, {
          amount: selectedRows ? selectedRows.length : 0
        })}
      </Paragraph>
    );
  }
);

export default ProcessIncomingBankingTransactionDialog;
