import React from 'react';

import { collectionStepLevel, culture, templateUsecase } from '@zf/api-types/enums';

import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../../../utils/request';
import { ErrorBoundary } from '../../../../components/Error';
import SuspenseSpinner from '../../../../components/suspense/suspense-spinner';
import HTMLLoader from './HTMLLoader';

type Props = {
  communicationLevel: collectionStepLevel;
  previewCulture: culture;
  templateUsecase: templateUsecase;
};

export default function TemplatePreview(props: Props) {
  const { communicationLevel, previewCulture, templateUsecase } = props;

  const dummyResponse = useSuspenseSingleAPI<Blob>({
    request: {
      method: METHODS.GET,
      endpoint: `/cfg/Templates/dummyobjects/collectioncase/${previewCulture}`
    }
  });

  if (!dummyResponse.result) return null;

  return (
    <div>
      <SuspenseSpinner size="small">
        {dummyResponse && communicationLevel && (
          <ErrorBoundary cardError>
            <HTMLLoader
              communicationLevel={communicationLevel}
              dummyObject={dummyResponse.result?.data}
              previewCulture={previewCulture}
              templateUsecase={templateUsecase}
            />
          </ErrorBoundary>
        )}
      </SuspenseSpinner>
    </div>
  );
}
