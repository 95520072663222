import React from 'react';

import { ImportJobType } from '@zf/api-types/import-job';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import { useAppContext } from '../../../../app-context';
import FilterInputs from '../../../../components/Filters/filter-inputs';
import CheckBox from '../../../../components/input/CheckBox';
import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { SuspenseCardBody } from '../../../../components/suspense';
import useProcessingDetails from './hooks/useProcessingDetails';
import useProcessingFilter from './hooks/useProcessingFilter';

export type TableCardProps = {
  importJob: ImportJobType;
  itemType: string;
};

export default function TableCard(props: TableCardProps) {
  const { importJob, itemType } = props;
  const { i18n } = useAppContext();

  const { onlyErrors, filterTags, setOnlyErrors } = useProcessingFilter();
  const { rows, loading } = useProcessingDetails(importJob, itemType, onlyErrors);

  const colHeaders: ColumnType[] = [
    {
      width: 200,
      label: i18n.getTranslation('import_jobs.stage'),
      dataKey: 'stage'
    }
  ];

  if (rows[0]) {
    Object.keys(rows[0].__entity.entity).forEach((property) => {
      colHeaders.push({
        width: 200,
        label: property,
        dataKey: property
      });
    });
  }

  colHeaders.push(
    {
      width: 200,
      label: i18n.getTranslation('errors.errors'),
      dataKey: 'errors'
    },
    {
      width: 200,
      label: i18n.getTranslation('import_jobs.property_errors'),
      dataKey: 'propertyErrors'
    }
  );

  return (
    <Card id="processing-details" width="4">
      <CardHeader
        filters={
          <FilterInputs
            filterInputs={[
              <CheckBox
                id="only-errors"
                key="only-errors"
                checked={onlyErrors}
                onChange={() => setOnlyErrors(!onlyErrors)}
              >
                {i18n.getTranslation('import_jobs.only_error')}
              </CheckBox>
            ]}
            removeAllFilters={() => setOnlyErrors(false)}
          />
        }
        tags={filterTags}
      >
        {i18n.getTranslation('import_jobs.details_per_item', { itemType })}
      </CardHeader>
      <SuspenseCardBody type="table">
        <DynamicVirtualTable
          id="invoice-period-table"
          tooltipId="invoice-period-table-tip"
          rows={rows}
          totalAmountOfRows={rows.length}
          columns={colHeaders}
          loading={loading}
          noSelect
        />
      </SuspenseCardBody>
    </Card>
  );
}
