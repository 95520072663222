import React from 'react';

import { customerType, paymentMethod } from '@zf/api-types/enums';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { colors } from '@zf/utils/src/color';

import useRefresh from '../../../app-context/hooks/use-refresh';
import PropertyGroupAutoFill, { PropertyGroupAutofillProps } from '../../../components/Autofills/PropertyGroupAutoFill';
import CustomerExport from '../../../components/Button/Excel/CustomerExport';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import EnumFilterSelect from '../../../components/input/EnumFilterSelect';
import { useStore } from '../../../hooks/useStore';
import useCustomerFilter from '../hooks/useCustomerFilter';
import useCustomerTabs from '../hooks/useCustomerTabs';
import CustomerGroupAutoFill, { CustomerGroupAutofillProps } from 'components/Autofills/CustomerGroupAutoFill';
import ChangeCustomerGroupListItem from './Actions/ChangeCustomerGroupListItem';
import RemoveCustomerGroupListItem from './Actions/RemoveCustomerGroupListItem';

type Props = {
  search?: string;
  extraColumnTitles: string[];
  withActions?: boolean;
  subject?: string;
};

const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);
const InlineCustomerGroupAutoFill = InlineInputField<CustomerGroupAutofillProps>(CustomerGroupAutoFill);

const CustomersFilterBar = (props: Props) => {
  const { extraColumnTitles, withActions = true, subject } = props;
  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    filterTags,
    setQueryParams,
    clearQueryParams,
    onFilter,
    onSearch
  } = useCustomerFilter(props.search);
  const { refresh } = useRefresh();
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const tabItems = useCustomerTabs();

  return (
    <FilterBar
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      subject={subject}
      tabItems={tabItems}
      defaultTab="customers"
      withActions={withActions}
      actions={[
        <CustomerExport key="customer-excel-export" extraColumnTitles={extraColumnTitles} />,
        <ChangeCustomerGroupListItem key="customer-change-customer-group" onComplete={refresh} />,
        <RemoveCustomerGroupListItem key="customer-remove-customer-group" onComplete={refresh} />
      ]}
      refreshPage={refresh}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="customer" />}
          count={overviewCount.all}
          title={getTranslation('customer_list.filter.all_customers')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          key="supplied"
          id="supplied"
          onTrigger={() => onFilter('supplied')}
          icon={<Icon type="customer" />}
          count={overviewCount.supplied}
          title={getTranslation('customer_list.filter.supplied_customers')}
          activeColor={colors['green-600']}
          active={quickFilter === 'supplied'}
        />,
        <FilterButton
          id="unsupplied"
          key="unsupplied"
          onTrigger={() => onFilter('unsupplied')}
          icon={<Icon type="customer" />}
          count={overviewCount.unsupplied}
          title={getTranslation('customer_list.filter.unsupplied_customers')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'unsupplied'}
        />
      ]}
      filterInputs={[
        <Label key="label-property-group">{getTranslation('property_groups.property_group')}</Label>,
        <InlinePropertyGroupAutoFill
          id="by_property_group"
          key="by_property_group"
          onChange={(val) =>
            setQueryParams({
              propertyGroupId: val[0] ? val[0].id : '',
              propertyGroupName: val[0] ? val[0].name : ''
            })
          }
          selectedValues={[queryParams.propertyGroupId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,
        <Label key="label-customer-group">{getTranslation('customer_groups.customer_group')}</Label>,
        <InlineCustomerGroupAutoFill
          id="by_customer_group"
          key="by_customer_group"
          onChange={(val) =>
            setQueryParams({
              customerGroupName: val[0] ? val[0].name : '',
              customerGroupId: val[0] ? val[0].id : ''
            })
          }
          selectedValues={[queryParams.customerGroupId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,
        <ListDivider key="divider-0" />,
        <EnumFilterSelect
          id="customer-type-filter"
          key="customer-type-filter"
          title={getTranslation('customer.customer_type')}
          options={getEnum<customerType>('customerType')}
          values={queryParams.customerType ? queryParams.customerType : []}
          setValue={(type) => setQueryParams({ customerType: type })}
        />,
        <EnumFilterSelect
          id="default-payment-method"
          key="default-payment-method"
          title={getTranslation('customer.default_payment_method')}
          options={getEnum<paymentMethod>('paymentMethod')}
          values={queryParams.defaultPaymentMethod ? queryParams.defaultPaymentMethod : []}
          setValue={(method) => setQueryParams({ defaultPaymentMethod: method })}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('customer_list.filter.search')}
      searchValue={searchValue}
      searchDebounce={500}
    />
  );
};

export default CustomersFilterBar;
