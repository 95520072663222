import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../../../app-context';
import useDetail from '../../../../../app-context/hooks/use-detail';
import { ICON_COLORS } from '../../../../../constants/icons';

const useOutgoingBankingTransactionDetail = (entityId: string) => {
  const {
    entity: initialOutgoingBankingTransaction,
    navigator,
    refresh
  } = useDetail<OutgoingBankingTransactionType>({
    entityId,
    endpoint: '/bill/outgoingbankingtransactions',
    domain: 'outgoing_banking_transaction'
  });

  const { enumReducer } = useAppContext();

  const [outgoingBankingTransaction, setOutgoingBankingTransaction] = React.useState(initialOutgoingBankingTransaction);

  React.useEffect(() => {
    if (initialOutgoingBankingTransaction) setOutgoingBankingTransaction(initialOutgoingBankingTransaction);
  }, [initialOutgoingBankingTransaction]);

  const status = outgoingBankingTransaction ? (
    <NewStatusBadge
      color={ICON_COLORS[outgoingBankingTransaction.status]}
      status_={` ${enumReducer.getTranslation(
        'outgoingBankingTransactionStatus',
        outgoingBankingTransaction.status
      )} `}
    />
  ) : (
    <></>
  );

  return { outgoingBankingTransaction, status, navigator, setOutgoingBankingTransaction, refresh };
};

export default useOutgoingBankingTransactionDetail;
