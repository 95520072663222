import { EnumType } from 'app-context/hooks/use-enum';
import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { billingCalculationType, billingScope, unitOfMeasure, utilityType } from '@zf/api-types/enums';
import {
  BillingCalculationTypeParametersType,
  BillingItemType,
  ConsumptionCalculationTypeParametersType,
  CustomEntityPropertyCalculationTypeParameters,
  SubscriptionCalculationTypeParametersType
} from '@zf/api-types/product';

export type CalculationTypeParametersEnrichment = {
  onlyForSpecificService?: boolean;
};

export type EnrichedBillingCalculationTypeParameters = BillingCalculationTypeParametersType &
  CalculationTypeParametersEnrichment;

export default class BillingItemsDialogStore {
  private applicationStore: ApplicationStore;
  public uomsAreLoading = false;

  constructor(applicationStore: ApplicationStore) {
    this.applicationStore = applicationStore;

    makeAutoObservable(this, {
      uomsAreLoading: observable,

      services: computed,

      initCalculationParameters: action,
      setUoMsAreLoading: action
    });
  }

  get services() {
    return this.applicationStore.getEnum<utilityType>('utilityType').filter((ut) => ut.value !== utilityType.none);
  }

  setUoMsAreLoading = (val: boolean) => {
    this.uomsAreLoading = val;
  };

  initCalculationParameters = (calculationTypes: EnumType<billingCalculationType>[], billingItem?: BillingItemType) => {
    const typesMap = new Map<billingCalculationType, EnrichedBillingCalculationTypeParameters>();

    calculationTypes.forEach((ct) => {
      switch (ct.value) {
        case billingCalculationType.consumption: {
          typesMap.set(
            ct.value,
            billingItem
              ? { ...billingItem.calculationParameters }
              : ({
                  type: ct.value
                } as ConsumptionCalculationTypeParametersType)
          );

          break;
        }
        case billingCalculationType.subscription: {
          const predefinedCalcParams = billingItem?.calculationParameters as
            | SubscriptionCalculationTypeParametersType
            | undefined;

          typesMap.set(
            ct.value,
            predefinedCalcParams
              ? ({
                  type: ct.value,
                  unitOfMeasure: predefinedCalcParams.unitOfMeasure,
                  scope: predefinedCalcParams.scope,
                  utilityType: predefinedCalcParams.utilityType,
                  onlyForSpecificService:
                    predefinedCalcParams.scope === billingScope.location && !!predefinedCalcParams.utilityType
                } as SubscriptionCalculationTypeParametersType & { onlyForSpecificService: boolean })
              : ({
                  type: ct.value,
                  unitOfMeasure: unitOfMeasure.year,
                  scope: billingScope.location,
                  onlyForSpecificService: true
                } as SubscriptionCalculationTypeParametersType & { onlyForSpecificService: boolean })
          );

          break;
        }
        case billingCalculationType.customentityproperty:
          const predefinedCalcParams = billingItem?.calculationParameters as
            | CustomEntityPropertyCalculationTypeParameters
            | undefined;

          typesMap.set(
            ct.value,
            predefinedCalcParams
              ? ({
                  type: ct.value,
                  customEntityPropertyTypeId: predefinedCalcParams.customEntityPropertyTypeId,
                  unitOfMeasure: predefinedCalcParams.unitOfMeasure,
                  scope: predefinedCalcParams.scope,
                  utilityType: predefinedCalcParams.utilityType,
                  onlyForSpecificService:
                    predefinedCalcParams.scope === billingScope.location && !!predefinedCalcParams.utilityType
                } as CustomEntityPropertyCalculationTypeParameters & { onlyForSpecificService: boolean })
              : ({
                  type: ct.value,
                  unitOfMeasure: unitOfMeasure.year,
                  scope: billingScope.location,
                  onlyForSpecificService: false
                } as CustomEntityPropertyCalculationTypeParameters & { onlyForSpecificService: boolean })
          );

          break;
      }
    });

    return typesMap;
  };
}
