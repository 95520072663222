import { ValidationRef } from '../design-system/ComponentSets/Dialog/Dialog';
import EventEmitter from './event-emitter';

export type DialogLocationsType = 'normal' | 'normal-big' | 'right';
export type DialogType = 'default' | 'danger';

export type DialogProps = {
  title: string;
  icon?: string;
  content: React.ReactNode | string;
  buttonPositive?: string;
  buttonNegative?: string;
  inFormId?: string;
  onSubmit: () => Promise<void> | void;
  onCancel?: () => void;
  location?: DialogLocationsType;
  type?: DialogType;
  ref?: React.MutableRefObject<ValidationRef | undefined>;
  isDismissDialog?: boolean;
  status?: React.ReactNode;
  extraRight?: JSX.Element;
  withPadding?: boolean;
  stackOrder?: number;
};

export type DialogOptions = {
  id: string;
  locationType?: DialogLocationsType;
};

export type DialogEmitValue = {
  dialog: DialogProps;
  options: DialogOptions;
};

export const ACTION = {
  SHOW: 'dialog.show',
  REMOVE: 'dialog.remove'
};

const eventEmitter = new EventEmitter<DialogEmitValue>();

export function on(key: string, callback: (value: DialogEmitValue) => void): void {
  return eventEmitter.on(key, callback);
}

export function removeListener(key: string, callback: (value: DialogEmitValue) => void) {
  return eventEmitter.removeListener(key, callback);
}

export async function emitDialog(key: string, value: DialogEmitValue) {
  eventEmitter.emit(key, value);
}

export const generateDialogId = () => Date.now().toString();

const dialogFactory = (locationType: DialogLocationsType) => {
  return (dialog_: DialogProps) => {
    emitDialog(ACTION.SHOW, {
      dialog: dialog_,
      options: {
        id: generateDialogId(),
        locationType
      }
    });
  };
};

export const dialog = {
  normal: dialogFactory('normal'),
  normalBig: dialogFactory('normal-big'),
  right: dialogFactory('right')
};
