import React, { CSSProperties, Dispatch, ReactNode, useState } from 'react';

import { useElementDimensions } from '@zf/hooks/src/useElementDimensions';

import css from './dropdown.module.scss';
import DropdownBody from './DropdownBody';

export type DropdownGenericBaseType = {
  id: string;
};

export type DropdownValuesType<T> = {
  value: T;
  text: string;
  checked?: boolean
};

export type DropdownTools<T> = {
  showTooltip: boolean;
  searchValue: string;
  focusedIndex: number | undefined;
  values: DropdownValuesType<T>[];
  selectedValue: DropdownValuesType<T> | undefined;
};

export type SetDropdownTools<T> = Partial<DropdownTools<T>>;

export type DropdownProps<T> = {
  id: string;
  dropdownTools?: DropdownTools<T>;
  details?: JSX.Element;
  placeholder?: string;
  values: DropdownValuesType<T>[];
  isMultipleSelected?: boolean;
  selectedValues: string[];
  disabled?: boolean;
  searchPlaceholder?: string;
  clear?: boolean;
  filterText?: string;
  detailsText?: string;
  clearText?: string;
  emptyText?: string;
  conditionMessage?: string;
  error?: boolean;
  className?: string;
  style?: CSSProperties;
  autoFocus?: boolean;
  loading?: boolean;
  extraLeftKey?: string;
  hideLabel?: boolean;
  resetValue?: any;
  renderTopLvl?: boolean;
  selectSingleItemByDefault?: boolean;
  extraRight?: (value: T) => ReactNode;
  onFocus?: (step: string) => void;
  onClear?: () => void;
  reset?: () => void;
  onChange: (values: T[]) => void;
  setDropdownTools?: Dispatch<SetDropdownTools<T>>;
};

export default function StellaDropdown<T extends DropdownGenericBaseType>(props: DropdownProps<T>) {
  const { details, dropdownTools, style, setDropdownTools = () => {} } = props;
  const [ref, sizes] = useElementDimensions<HTMLDivElement>();

  // State variables which are only used by regular dropdowns (without details), otherwise dropdownTools is used
  const [showTooltip, setShowTooltip] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(undefined);

  const handleOpen = () => (dropdownTools ? setDropdownTools({ showTooltip: true }) : setShowTooltip(true));

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div ref={ref} className={css['dropdown-container']} style={style} onFocus={handleOpen} role="tooltip">
      <DropdownBody
        {...props}
        details={details}
        dropdownWidth={sizes.width}
        showTooltip={dropdownTools ? dropdownTools.showTooltip : showTooltip}
        searchValue={dropdownTools?.searchValue || searchValue}
        focusedIndex={dropdownTools ? dropdownTools.focusedIndex : focusedIndex}
        setSearchValue={(val) => (dropdownTools ? setDropdownTools({ searchValue: val }) : setSearchValue(val))}
        setShowTooltip={(val) => (dropdownTools ? setDropdownTools({ showTooltip: val }) : setShowTooltip(val))}
        setFocusedIndex={(newIndex) =>
          dropdownTools ? setDropdownTools({ focusedIndex: newIndex }) : setFocusedIndex(newIndex)
        }
        handleOpen={handleOpen}
      />
    </div>
  );
}
