import { uiCulture } from '@zf/api-types/enums';
import { TenantReturnValue } from '../../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../../utils/request';

export async function addCustomersToGroupBulk(
  customerIds: Array<string>,
  customerGroupId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/Customers/bulk/customergroup`,
        data: { customerGroupId, customerIds }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function addCustomersToGroupSingle(
  customerGroupId: string,
  customeruuid: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/Customers/${customeruuid}/customergroup`,
        data: { customerGroupId }
      },
      tenantReducer,
      lang
    })
  ).data;
}
