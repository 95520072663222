import { Button, SidePanel } from 'design-system/Components';
import { Paragraph } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';

import { MeteringIssueStatus } from '@zf/api-types/enums';
import { MeterType } from '@zf/api-types/master-data/meter';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { groupBy } from '@zf/utils/src/array';
import { colors } from '@zf/utils/src/color';

import { useMeterIssuesContext } from '../../../Context/Context';
import { State } from '../../types';
import { IssueActionType } from '../IssueSidePanel';
import ResolveActions from '../shared/ResolveActions';
import { MultiLineInputWithHooksForm } from '../single-issue/UnresolvedPanelBody';
import css from './resolve-multi-issue-panel.module.scss';

type Props = {
  issues: MeteringIssue[];
  meter: MeterType;
  refresh: () => void;
};

const ResolveMultiIssuePanel = (props: Props) => {
  const { issues, refresh, meter } = props;

  const isUnresolved = issues.every(({ status }) => status === MeteringIssueStatus.unresolved);
  const hasUniqueIssueType =
    [
      ...new Set(
        issues.map((i) => {
          return i.error;
        })
      )
    ].length === 1;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { meteringIssueService } = meterStore;
  const { resolveMeterIssues, ignoreMeterIssues } = meteringIssueService;

  const ctx = useMeterIssuesContext();
  const { updateRows, closeSidePanel } = ctx;

  const groupedIssues: Record<string, MeteringIssue[]> = groupBy(issues, 'error');

  const { handleSubmit, control } = useForm<Pick<State, 'message'>>({
    defaultValues: {
      message: ''
    }
  });

  const onSubmit = async (type: IssueActionType) => {
    const issueIds = issues.map(({ id }) => id);

    handleSubmit(async (data) => {
      if (type === 'resolve') {
        resolveMeterIssues(issueIds, data.message)
          .then((res) => {
            updateRows(res);
            refresh();
            notify.success({ content: getTranslation('meter_issues.issues_solved') });
          })
          .catch((error) => notify.error({ content: getTranslation('meter_issues.issues_solved_fail'), error }));
      } else if (type === 'ignore') {
        ignoreMeterIssues(issueIds, data.message)
          .then((res) => {
            updateRows(res);
            refresh();
            notify.info({ content: getTranslation('meter_issues.issues_ignored') });
          })
          .catch((error) => notify.error({ content: getTranslation('meter_issues.issues_ignored_fail'), error }));
      } else if (type === 'ignore-and-mute') {
        updateRows(issues);
        refresh();
      }
    })();
  };

  return (
    <SidePanel>
      <FlexElements className={css['multi-issue-panel-body']} flexDirection="column" justifyContent="space-between">
        <div>
          <FlexElements justifyContent="space-between">
            <Heading headingType="h3">
              {getTranslation('meter_issues.issues_selected', { amount: issues.length })}
            </Heading>
            <Button id="issues.close_panel" icon="cross" onClick={closeSidePanel} type="text" danger noSidePadding />
          </FlexElements>

          <FlexElements className={css['issues']} flexDirection="column" gap="24">
            {Object.keys(groupedIssues).map((key) => {
              const issues = groupedIssues[key];

              return (
                <div key={key}>
                  <Paragraph color={colors['silver-600']} bold>
                    {getEnumTranslation('meteringIssueError', key)}
                  </Paragraph>
                  <Paragraph>{issues.length}</Paragraph>
                </div>
              );
            })}
          </FlexElements>
        </div>

        <div>
          {isUnresolved && <MultiLineInputWithHooksForm control={control} name="message" />}

          <ResolveActions
            issues={issues}
            onSubmit={onSubmit}
            isUnresolved={isUnresolved}
            hasUniqueIssueType={hasUniqueIssueType}
            meters={[meter]}
            refresh={refresh}
          />
        </div>
      </FlexElements>
    </SidePanel>
  );
};

export default observer(ResolveMultiIssuePanel);
