import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';

import { queryParam } from '../../../hooks/useCreateRequest';
import MetersDropdownContent from './MetersDropdownContent';
import MetersQueryDropdown from './MetersQueryDropdown';

type MetersDropdownProps = {
  id?: string;
  meters?: MeterType[];
  queryParams?: queryParam;
  isInline?: boolean;
  selectedValue: string;
  error?: boolean;
  onFocus?: (step: string) => void;
  onChange: (values: MeterType[]) => void;
};

export default function MetersDropdown(props: MetersDropdownProps) {
  const { meters } = props;

  return meters ? (
    <MetersDropdownContent {...props} meters={meters ? meters : []} />
  ) : (
    <MetersQueryDropdown {...props} />
  );
}
