import useFilter from '../../../../app-context/hooks/use-filter';

const initialCustomerOverviewCount = {
  all: 0,
  requireAttention: 0
};

type QueryParam = {};

const paramTypes = {};

const useCustomerGroupFilter = (search?: string) => {
  const { onFilter, onSearch, overviewCount, quickFilter, searchValue, queryParams, setQueryParams, clearQueryParams } =
    useFilter<QueryParam>({
      endpoint: '/md/CustomerGroups/overviewcount',
      initialCount: initialCustomerOverviewCount,
      domain: 'customersgroup',
      search: search,
      paramTypes
    });

  return {
    onFilter,
    onSearch,
    setQueryParams,
    clearQueryParams,
    queryParams,
    quickFilter,
    overviewCount,
    searchValue
  };
};

export default useCustomerGroupFilter;
