import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import ResolveWaitingDialog from '../../../actions/tasks/ResolveWaitingDialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  task: MoveRequestType;
  setTask: (updatedTask: MoveRequestType) => void;
};

export default function ResolveWaitingListItem(props: Props) {
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="tasks.setnew" // Resolve waiting is calling the setnew API
      icon="reset"
      dialogTitle={i18n.getTranslation('tasks.resolve_waiting')}
      dialogContent={<ResolveWaitingDialog {...props} ref={clickRef} validationRef={validationRef} />}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('general.submit')}
      validationRef={validationRef}
    />
  );
}
