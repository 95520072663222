import React from 'react';

import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardEmptyBody from '../../Card/CardEmptyBody';
import CardsContainer from '../../Card/CardsContainer';
import Icon from '../../Icon/Icon';
import css from './graph-tab.module.scss';

export type Props = {
  cardId: string;
  children: React.ReactNode;
  hasData?: boolean;
  noDataIcon?: string;
  noDataTitle?: string;
  noDataMsg?: string;
};

export default function DataGridCardTab(props: Props) {
  const { cardId, children, hasData = true, noDataIcon = '', noDataTitle = '', noDataMsg = '' } = props;

  return (
    <CardsContainer>
      <Card id={cardId} width="4" className={css['card']}>
        {hasData ? (
          <CardBody className={css['content']}>{children}</CardBody>
        ) : (
          <CardEmptyBody className={css['empty-body']} icon={<Icon name={noDataIcon} />} title={noDataTitle}>
            {noDataMsg}
          </CardEmptyBody>
        )}
      </Card>
    </CardsContainer>
  );
}
