import React from 'react';

import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import { InlineInputFieldInput } from '../../../../components/input/InputField';
import { Link } from '../../../../design-system/Components';
import CustomerForMeter from './customer-for-meter';
import MeterAddress from './detail/MeterAddress';
import ModelDetails from './ModelDetails';
import css from './meter-detail-body-card.module.scss';

type Props = {
  meter?: MeterType;
  meterTag: string;
  activeState: MeterStatusHistoryType | undefined;
  setMeterTag: (newTag: string) => void;
};

export default function MeterDetailBodyCard(props: Props) {
  const { meter, meterTag, activeState, setMeterTag } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  return (
    <>
      <CardItem width="6">
        <Label>{i18n.getTranslation('meter.serial')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">{meter?.serialNumber}</Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('meter.meter_tag')}</Label>
      </CardItem>
      <CardItem justifyContent="end" width="6">
        <InlineInputFieldInput id="meter.meter_tag" onChange={(newTag) => setMeterTag(newTag)} value={meterTag} />
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('general.status')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">
          {activeState ? enumReducer.getTranslation('meterStatus', activeState.meterStatus) : ''}
        </Paragraph>
      </CardItem>

      {meter?.modelId && <ModelDetails meter={meter} />}

      {activeState && activeState.serviceLocationId && (
        <>
          <CardItem width="6">
            <Label>{i18n.getTranslation('customer.customer')}</Label>
          </CardItem>
          <CardItem justifyContent="end" width="6">
            <CustomerForMeter serviceLocationId={activeState.serviceLocationId} />
          </CardItem>
        </>
      )}
      {activeState && activeState.propertyGroup && (
        <>
          <CardItem width="6">
            <Label>{i18n.getTranslation('property_groups.property_group')}</Label>
          </CardItem>
          <CardItem width="6">
            <Link
              id={`property-${activeState.propertyGroup.id}`}
              url={`${rootUrl}/property-groups/${activeState.propertyGroup.id}`}
            >
              {activeState.propertyGroup.name}
            </Link>
          </CardItem>
        </>
      )}
      <CardItem width="6">
        <Label>{i18n.getTranslation('location.address')}</Label>
      </CardItem>
      <CardItem className={css['address']} justifyContent="end" width="6">
        <MeterAddress id="meter-address" activeState={activeState} />
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('meter.meter_type')}</Label>
      </CardItem>
      <CardItem width="6">
        {meter && <Paragraph textAlign="right">{enumReducer.getTranslation('meterType', meter.meterType)}</Paragraph>}
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('metering_list.labels.last_received')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">
          {meter?.lastTimeReceivedData
            ? formatDate(meter.lastTimeReceivedData)
            : i18n.getTranslation('metering_list.never')}
        </Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{i18n.getTranslation('metering_list.labels.measurementsUntil')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right">
          {meter?.measurementsUntil ? formatDate(meter.measurementsUntil) : i18n.getTranslation('metering_list.none')}
        </Paragraph>
      </CardItem>
    </>
  );
}
