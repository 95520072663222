import React from 'react';

import { invoiceStatus } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { formatDate, formatPeriod } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../app-context';
import DaysOverdueBadge from '../../../components/analytics/days-overdue-badge';
import EmptyLink from '../../../components/Link/EmptyLink';
import { Link } from '../../../design-system/Components';
import { isOverdue } from '../../../utils/invoice';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

export type InvoiceRowsType = {
  __id: string;
  invoiceNum: JSX.Element;
  contractNumber: '' | JSX.Element | null;
  totalAmountInclVAT: string;
  remainingInvoiceAmount: string;
  type: string;
  dueDate: JSX.Element;
  periodStartDateTime: string;
};

export default function useInvoiceCard() {
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const [columns] = React.useState<ColumnType[]>([
    {
      width: 180,
      label: i18n.getTranslation('invoice.invoice'),
      dataKey: 'invoiceNum'
    },
    {
      width: 140,
      label: i18n.getTranslation('general.type'),
      dataKey: 'type'
    },
    {
      width: 140,
      label: i18n.getTranslation('contracts.contract'),
      dataKey: 'contractNumber'
    },
    {
      width: 240,
      label: i18n.getTranslation('general.period'),
      dataKey: 'periodStartDateTime'
    },
    {
      width: 200,
      label: i18n.getTranslation('invoice.due_date'),
      dataKey: 'invoiceDate'
    },
    {
      width: 140,
      label: i18n.getTranslation('general.amount'),
      dataKey: 'totalAmountInclVAT',
      contentAlignRight: true
    },
    {
      width: 110,
      label: i18n.getTranslation('invoice.remaining_amount'),
      dataKey: 'remainingInvoiceAmount'
    }
  ]);

  const processRecord = (invoice: InvoiceType): InvoiceRowsType => {
    return {
      __id: invoice.id,
      invoiceNum: (
        <EmptyLink id={`link-${invoice.id}`} url={`${rootUrl}/invoices/${invoice.id}`} icon="invoice">
          {invoice.invoiceNum}
        </EmptyLink>
      ),
      contractNumber: invoice.contractId && (
        <Link id={`link-${invoice.contractNumber}`} url={`${rootUrl}/contracts/${invoice.contractId}`} icon="contract">
          {invoice.contractNumber}
        </Link>
      ),
      totalAmountInclVAT: formatMoney(invoice.totalAmountInclVAT, i18n.culture),
      remainingInvoiceAmount: formatMoney(invoice.remainingInvoiceAmount, i18n.culture),
      type: enumReducer.getTranslation('invoiceType', invoice.type),
      dueDate: (
        <FlexElements>
          <Paragraph>{formatDate(invoice.dueDate)}</Paragraph>
          {invoice.remainingInvoiceAmount > 0 && invoice.status !== invoiceStatus.created && isOverdue(invoice) && (
            <DaysOverdueBadge tooltipFor="invoices-table-tip" referenceDate={invoice.dueDate} type="invoice" />
          )}
        </FlexElements>
      ),
      periodStartDateTime: formatPeriod(invoice.periodStartDateTime, invoice.periodEndDateTime)
    };
  };

  return { columns, processRecord };
}
