import clone from 'clone';
import React, { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';

import {
  CollectionChargeParametersType,
  CollectionFlowStepType,
  CollectionFlowType
} from '@zf/api-types/collection-cycles';
import { TemplateAttachment } from '@zf/api-types/config/scenarios_new';
import { collectionChargeType, collectionStepLevel, collectionStepType, communicationType } from '@zf/api-types/enums';
import useSimpleValidator, { SimpleValidatorReturnType } from '@zf/hooks/src/useSimpleValidator';

import { useAppContext } from '../../../../app-context';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { generateApiFriendlyValues, generateStepInitialValues, validateStepFields } from '../add-cycle-dialog/add-step';
import { addCollectionFlowStep, updateCollectionCycle, updateCollectionFlowStep } from '../collection-api-functions';
import StepWizard from '../step-wizard/step-wizard';

type Props = {
  useCase: 'add' | 'edit';
  stepNumber: number | undefined;
  step?: CollectionFlowStepType;
  flowIndex: number;
  flow: CollectionFlowType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  updateStep: (step: CollectionFlowStepType) => void;
};

export type StepValidatorType = {
  id?: string;
  stepName: string;
  triggerDays?: number;
  months: number;
  days: number;
  stepType: collectionStepType;
  chargeType: collectionChargeType;
  communicationType: communicationType;
  chargeParameters: CollectionChargeParametersType;
  communicationSubject: string;
  communicationLevel: collectionStepLevel;
  communicationAttachments: TemplateAttachment[];
};

const AddEditStepDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { useCase, stepNumber = 0, step, flow, flowIndex, validationRef, addStep, updateStep } = props;
  const { i18n, tenantReducer } = useAppContext();

  let initialValues: StepValidatorType;

  if (step && useCase === 'edit') {
    initialValues = {
      id: step.id,
      stepName: step.name,
      triggerDays: step.triggerDays,
      months: Math.floor(step.triggerDays / 30),
      days: step.triggerDays % 30,
      stepType: step.stepType,
      chargeType: step.chargeType,
      communicationType: step.communicationType,
      chargeParameters: step.chargeParameters,
      communicationSubject: step.communicationSubject || '',
      communicationLevel: step.communicationLevel || '',
      communicationAttachments: step.communicationAttachments || []
    };
  } else {
    initialValues = generateStepInitialValues();
  }

  const stepValidatorTools: SimpleValidatorReturnType<StepValidatorType> = useSimpleValidator<StepValidatorType>({
    initialValues: clone(initialValues),
    validate: () => validateStepFields(stepValidatorTools.values)
  });

  const validate = () => {
    if (validationRef.current) {
      const hasError = stepValidatorTools.errors.includes(true);
      validationRef.current.setIsError(hasError);
    }
  };

  useEffect(() => {
    validate();
  }, [stepValidatorTools.errors]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const apiFriendlyValues = generateApiFriendlyValues(stepValidatorTools.values);
        const newStep =
          useCase === 'add'
            ? await addCollectionFlowStep(apiFriendlyValues, tenantReducer, i18n.lang)
            : await updateCollectionFlowStep(apiFriendlyValues, tenantReducer, i18n.lang);

        // Add only
        if (!flow.stepIds.includes(newStep.id)) {
          const flowClone = clone(flow);
          flowClone.stepIds.push(newStep.id);
          await updateCollectionCycle(flowClone, tenantReducer, i18n.lang);
        }

        // Update state
        useCase === 'add' ? addStep(newStep, flowIndex) : updateStep(newStep);

        notify.success({
          content: i18n.getTranslation(`collection_flows.${useCase === 'add' ? 'add' : 'edit'}_step_success`, {
            stepNumber: stepNumber
          })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`collection_flows.${useCase === 'add' ? 'add' : 'edit'}_step_fail`, {
            stepNumber: stepNumber
          }),
          error: e
        });
      }
    }
  }));

  return (
    <StepWizard
      flowIndex={flowIndex}
      stepNumber={stepNumber}
      stepValidatorTools={stepValidatorTools}
      step={step}
      addStep={addStep}
    />
  );
});

export default AddEditStepDialog;
