import React from 'react';

import { ImportJobRowType, ImportJobType, TriggerImportJobType } from '@zf/api-types/import-job';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { triggerImportJob } from './import-job';

type Props = {
  onComplete?: (updatedRows: ImportJobType[]) => void;
  selectedRows?: Array<ImportJobRowType>;
  importJobToHandle?: TriggerImportJobType;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, importJobToHandle, refreshCounts } = props;
  const { i18n, tenantReducer } = useAppContext();

  let importJobsToHandle: Array<TriggerImportJobType> = [];

  if (selectedRows) {
    importJobsToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (importJobToHandle) {
    importJobsToHandle = [importJobToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (importJobsToHandle.length > 0) {
        try {
          const newImportJobs = await Promise.all(
            importJobsToHandle.map((importJob) => triggerImportJob(importJob, tenantReducer, i18n.lang))
          );

          if (onComplete) onComplete(newImportJobs);
          if (refreshCounts) refreshCounts();
          notify.success({
            content: i18n.getTranslation('actions.import_jobs.trigger_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.import_jobs.trigger_failed'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation('actions.import_jobs.trigger', { amount: importJobsToHandle.length.toString() })}
    </Paragraph>
  );
});
