import React from 'react';

import css from './inline-input-field.module.scss';

export type PropsType = {
  children?: React.ReactNode;
  className?: string;
  readOnly?: boolean;
  hideLabel?: boolean;
  errors?: any;
};

export default function InlineInputField<P>(Input: (props: P) => JSX.Element) {
  return function (props: PropsType & P) {
    const { children } = props;

    return (
      <div className={css['input-wrapper']}>{React.createElement(Input, { ...props, hideLabel: true }, children)}</div>
    );
  };
}
