import React from 'react';

import { MeterRowType } from '@zf/api-types/master-data/meter';

import useCurrent from '../../../app-context/hooks/use-current';
import { useStore } from '../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import ExportToExcel from './ExportToExcel';

type Props = {
  extraColumnTitles: string[];
};

export default function MeterExport(props: Props) {
  const { extraColumnTitles } = props;

  const { myCurrent } = useCurrent('meter');

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const selectedRows: MeterRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = () => {
    const exportArray: string[][] = [];

    exportArray.push([
      getTranslation('metering_list.labels.serial'),
      getTranslation('meter.brand'),
      getTranslation('meter.model'),
      getTranslation('meter.meter_tag'),
      getTranslation('general.status'),
      getTranslation('general.type'),
      getTranslation('metering_list.labels.last_received'),
      getTranslation('metering_list.labels.measurementsUntil'),
      getTranslation('location.street_name'),
      getTranslation('location.streetnr'),
      getTranslation('location.streetnraddition'),
      getTranslation('location.postal'),
      getTranslation('location.city'),
      getTranslation('location.country'),
      getTranslation('property_groups.name'),
      getTranslation('property_groups.property_group_id'),
      ...extraColumnTitles
    ]);

    selectedRows.forEach((r) => {
      const meter = r.__meterEntity;

      const installedAddress =
        r.__activeState && r.__activeState.installedAtAddress
          ? r.__activeState.installedAtAddress
          : {
              streetName: '',
              streetNumber: '',
              streetNumberAddition: '',
              postalCode: '',
              city: '',
              country: ''
            };

      const extraCustomEntityColumns = getExtraCustomEntityColumns(
        meter.customProperties,
        r.__customEntityPropertyTypes,
        getTranslation
      );

      const extraCustomEntityColumnValues = Object.keys(extraCustomEntityColumns).map((key) => {
        return extraCustomEntityColumns[key];
      });

      exportArray.push([
        meter.serialNumber,
        r.brand,
        r.model,
        meter.meterTag,
        r.__activeState ? getEnumTranslation('meterStatus', r.__activeState.meterStatus) : '',
        r.meterType,
        r.lastTimeReceivedData,
        r.measurementsUntil,
        installedAddress.streetName,
        installedAddress.streetNumber,
        installedAddress.streetNumberAddition || '',
        installedAddress.postalCode,
        installedAddress.city,
        installedAddress.country.toUpperCase(),
        meter.propertyGroup?.name || '',
        meter.propertyGroup?.id || '',
        ...extraCustomEntityColumnValues
      ]);
    });

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      key="meterExport"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_meters')}
      ws_name={getTranslation('meter.meters')}
    />
  );
}
