import moment from 'moment';
import React, { Fragment, ReactNode } from 'react';

import { managementRelationType, serviceStatus } from '@zf/api-types/enums';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType, ShortContractType } from '@zf/api-types/master-data/servicelocation';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { removeDuplicates } from '@zf/utils/src/array';
import { betweenDates } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import useRefresh from '../../../../app-context/hooks/use-refresh';
import DeleteLocationOfPropertyCardButton from '../../../../components/Button/Property-group/DeleteLocationOfPropertyCardButton';
import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import TableServices from '../../../../components/Services/TableServices';
import { Link } from '../../../../design-system/Components';
import useCreateRequest from '../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../hooks/useInfiniAPI';
import { getServiceStatus } from '../../../../utils/serviceState';
import css from './locations-card.module.scss';

type Props = {
  propertyGroup: PropertyGroupType;
};

export type LocationRowType = {
  __id: string;
  __locationEntity: ServiceLocationType;
  DisplayAddress: React.ReactNode;
  services: React.ReactNode;
  customers: React.ReactNode;
  manager: React.ReactNode;
  owner: React.ReactNode;
  contracts: React.ReactNode;
};

function NoLocations() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/location.png"
          alt="location"
          title="location"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('property_groups.no_locations')}
    >
      {i18n.getTranslation('property_groups.no_locations_description')}
    </CardEmptyBody>
  );
}

export default function LocationsCard(props: Props) {
  const { propertyGroup } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;
  const { refresh, timeStamp } = useRefresh();

  const today = moment();

  const { request, sortBy, sortDirection, selectedIds, setSelectedIds, handleSort } = useCreateRequest(
    '/md/servicelocations',
    {
      propertyGroupId: propertyGroup.id,
      timeStamp: timeStamp
    }
  );

  const processRecord = (value: ServiceLocationType): LocationRowType => {
    const servicesSupplied = value.services.filter((service) => {
      const activeStatus = getServiceStatus(service.statusHistory);
      return activeStatus && activeStatus.serviceStatus === serviceStatus.supplied;
    });

    const owner = value.managementRelations.find((mr) => mr.managementRelationType === managementRelationType.owner);
    const manager = value.managementRelations.find(
      (mr) => mr.managementRelationType === managementRelationType.propertymanager
    );

    const contracts: ShortContractType[] = [];

    let suppliedContracts = servicesSupplied.reduce((acc: ShortContractType[], service) => {
      const match = service.contracts.find((contract) =>
        betweenDates(contract.supplyStartDateTime, contract.supplyEndDateTime, today)
      );

      if (match) {
        acc.push(match);
      }

      return acc;
    }, []);

    suppliedContracts = removeDuplicates(suppliedContracts, 'contractId');

    return {
      __id: value.id,
      __locationEntity: value,
      DisplayAddress: (
        <Link id={`location-${value.id}`} url={`${rootUrl}/locations/${value.id}`}>
          {value.address.localizedDisplay}
        </Link>
      ),
      services: <TableServices utilityTypes={servicesSupplied.map((s) => s.utilityType)} />,
      customers: (
        <div className={css['customers']}>
          {servicesSupplied.reduce((acc: ReactNode[], service, index) => {
            const contract = service.contracts.find((c) =>
              betweenDates(c.supplyStartDateTime, c.supplyEndDateTime, today)
            );

            if (
              contract &&
              !contracts.some((c) => {
                return c.contractorDisplayName === contract.contractorDisplayName;
              })
            ) {
              contracts.push(contract);

              acc.push(
                <Fragment key={`${contract.contractorDisplayName}-${index}`}>
                  <Link
                    id={`customer-${contract.contractorId}`}
                    url={`${rootUrl}/customers/${contract.contractorId}`}
                  >{`${contract.contractorAcountNumber} - ${contract.contractorDisplayName}`}</Link>
                </Fragment>
              );
            }

            return acc;
          }, [])}
        </div>
      ),
      owner: owner ? (
        <Link
          id={`owner-${owner.customerId}`}
          url={`${rootUrl}/customers/${owner.customerId}`}
        >{`${owner.accountNumber} - ${owner.shortDisplayName}`}</Link>
      ) : (
        ''
      ),
      manager: manager ? (
        <Link
          id={`manager-${manager.customerId}`}
          url={`${rootUrl}/customers/${manager.customerId}`}
        >{`${manager.accountNumber} - ${manager.shortDisplayName}`}</Link>
      ) : (
        ''
      ),
      contracts: suppliedContracts.map((c) => {
        return (
          <Link
            id={`contract-${c.contractId}`}
            key={`contract-${c.contractId}`}
            className={css['contract-link']}
            url={`${rootUrl}/contracts/${c.contractId}`}
          >
            {c.contractNumber}
          </Link>
        );
      })
    };
  };

  const { loading, error, rows, setStopIndex, updateGivenRows, sortableFields } = useInfiniAPI<
    ServiceLocationType,
    LocationRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  return (
    <Card id="locations-card" width="3">
      <CardHeader
        extraRight={
          <DeleteLocationOfPropertyCardButton
            propertyGroup={propertyGroup}
            rows={rows}
            disabled={selectedIds.length === 0}
            rowsToDelete={selectedIds}
            setSelectedIds={setSelectedIds}
            setPropertyGroup={refresh}
            onComplete={updateGivenRows}
          />
        }
      >
        {i18n.getTranslation('property_groups.locations_card_header')}
      </CardHeader>
      <CardBody className={css['locations-card-body']}>
        <DynamicVirtualTable
          id="locations-table"
          tooltipId="locations-table-tip"
          dynamicRowHeight
          onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
          rows={rows}
          loading={loading}
          error={error}
          NoDataOverlay={NoLocations}
          singleSort
          sortableFields={sortableFields}
          sort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          selectedRows={selectedIds}
          onSelectRow={setSelectedIds}
          totalAmountOfRows={rows.length}
          columns={[
            {
              width: 350,
              label: i18n.getTranslation('property_groups.address_list'),
              dataKey: 'DisplayAddress',
              sortable: true
            },
            {
              width: 100,
              label: i18n.getTranslation('location.services'),
              dataKey: 'services'
            },
            {
              width: 250,
              label: i18n.getTranslation('property_groups.customer'),
              dataKey: 'customers'
            },
            {
              width: 200,
              label: i18n.getTranslation('property_groups.manager'),
              dataKey: 'manager'
            },
            {
              width: 200,
              label: i18n.getTranslation('property_groups.owner'),
              dataKey: 'owner'
            },
            {
              width: 120,
              label: i18n.getTranslation('contracts.contracts'),
              dataKey: 'contracts'
            }
          ]}
        />
      </CardBody>
    </Card>
  );
}
