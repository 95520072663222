import { observer } from 'mobx-react';
import React from 'react';

import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { StepAnchor, WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useStore } from '../../../../hooks/useStore';
import { STEP_NAMES } from '../../../meter-install/wizard/InstallMeterWizard';
import { CreateCustomerType } from '../CustomerWizard';
import CustomerEssentials, { CustomerEssentialsBaseType } from './CustomerEssentials';

export type Props = {
  values: CreateCustomerType;
  orgConfig: OrganizationConfigType;
  customerName: { name: string };
  onFocusStep: React.Dispatch<React.SetStateAction<string>>;
  setCustomerEssential: (val: Partial<CustomerEssentialsBaseType>) => void;
};

const EssentialsSection = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <WizardSection id="essentials-section">
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[0]} />
        {getTranslation('customer.steps.essentials')}
      </WizardHeader>
      <CustomerEssentials {...props} />
    </WizardSection>
  );
};

export default observer(EssentialsSection);
