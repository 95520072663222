import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { templateUsecase } from '@zf/api-types/enums';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import RadioButton from '@zf/stella-react/src/atoms/RadioButton/RadioButton';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';

import NumberInput from '../../../../../components/input/NumberInput';
import { useStore } from '../../../../../hooks/useStore';
import AddCustomHTML from '../components/AddCustomHTML';
import CustomTranslations from '../components/CustomTranslations';
import ExistingCustomHTML from '../components/ExistingCustomHTML';
import css from './pdf-card-body.module.scss';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { notify } from '../../../../../events/notification-events';
import useDebounce from '@zf/hooks/src/useDebounce';

const PdfCardBody = () => {
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { templateForm, selectedScenario } = communicationStore.templatesStore;
  const pdfTemplate = selectedScenario?.pdfTemplate;

  const [radioBtnValues] = useState([
    { text: getTranslation('general.left'), value: 'left' },
    { text: getTranslation('general.right'), value: 'right' }
  ]);

  const [faceValueMarginTop, setFaceValueMarginTop] = React.useState(templateForm._get('marginTop'));
  const [faceValueMargin, setFaceValueMargin] = React.useState(templateForm._get('margin'));
  const [debounce] = React.useState(500);

  React.useEffect(() => {
    setFaceValueMarginTop(templateForm._get('marginTop'));
    setFaceValueMargin(templateForm._get('margin'));
  }, [templateForm._get('marginTop'), templateForm._get('margin')]);

  const setDebounceCallback = useDebounce(debounce);

  const onKeyPressMarginTop = (value: number) => {
    setFaceValueMarginTop(value);

    setDebounceCallback(() => {
      onChangeMarginTop(value);
    });
  };

  const onChangeMarginTop = (val: number) => {
    if (val < 27) {
      templateForm._set('marginTop', 27);
      notify.warning({
        content: getTranslation('communication.info_top_position')
      });
    } else {
      templateForm._set('marginTop', val);
    }
  };

  const onKeyPressMargin = (value: number) => {
    setFaceValueMargin(value);

    setDebounceCallback(() => {
      onChangeMargin(value);
    });
  };

  const onChangeMargin = (val: number) => {
    if (val < 5) {
      templateForm._set('margin', 5);
      notify.warning({
        content: getTranslation('communication.info_enveloppe_position')
      });
    } else {
      templateForm._set('margin', val);
    }
  };

  return (
    <>
      <CustomTranslations templateUsecase_={templateUsecase.pdf} />

      <CardItem width="12">
        <HorizontalDivider />
      </CardItem>

      {pdfTemplate?.useBuildIn && (
        <>
          {typeof pdfTemplate?.costAllocation === 'boolean' && (
            <CardItem width="12">
              <ZFToggle
                onChange={(val) => templateForm._set('costAllocation', val)}
                checked={templateForm._get('costAllocation')}
                title={getTranslation('communication.cost_allocation')}
                description={getTranslation('communication.info_cost_allocation')}
              />
            </CardItem>
          )}
          {typeof pdfTemplate?.showCountry === 'boolean' && (
            <CardItem width="12">
              <ZFToggle
                onChange={(val) => templateForm._set('showCountry', val)}
                checked={templateForm._get('showCountry')}
                title={getTranslation('communication.show_country')}
                description={getTranslation('communication.info_show_country')}
              />
            </CardItem>
          )}
          {typeof pdfTemplate?.showBalance === 'boolean' && (
            <CardItem width="12">
              <ZFToggle
                onChange={(val) => templateForm._set('showBalance', val)}
                checked={templateForm._get('showBalance')}
                title={getTranslation('communication.show_balance')}
                description={getTranslation('communication.info_show_balance')}
              />
            </CardItem>
          )}
          {typeof pdfTemplate?.showVatSpecs === 'boolean' && (
            <CardItem width="12">
              <ZFToggle
                onChange={(val) => templateForm._set('showVatSpecs', val)}
                checked={templateForm._get('showVatSpecs')}
                title={getTranslation('communication.show_btw_specs')}
                description={getTranslation('communication.info_show_btw_specs')}
              />
            </CardItem>
          )}

          {(typeof pdfTemplate?.costAllocation === 'boolean' ||
            typeof pdfTemplate?.showBalance === 'boolean' ||
            typeof pdfTemplate?.showCountry === 'boolean' ||
            typeof pdfTemplate?.showVatSpecs === 'boolean') && (
            <CardItem width="12">
              <HorizontalDivider />
            </CardItem>
          )}

          {pdfTemplate?.envelopeSettings && (
            <>
              <CardItem width="12">
                <Paragraph>{getTranslation('communication.envelope_position')}</Paragraph>
              </CardItem>
              <CardItem width="10">
                <RadioButton
                  id="envelope-margin"
                  values={radioBtnValues}
                  value={templateForm._get('marginPosition')}
                  onChange={(val) => templateForm._set('marginPosition', val)}
                />
              </CardItem>
              <CardItem width="2" className={css['margin-value']}>
                <NumberInput
                  min="5"
                  id="margin-value"
                  value={debounce ? faceValueMargin : templateForm._get('margin')}
                  onChange={debounce ? onKeyPressMargin : onChangeMargin}
                  postfix={getTranslation('communication.mm')}
                  hideLabel
                />
              </CardItem>

              <CardItem width="10">
                <Paragraph>{getTranslation('communication.envelope_position_top')}</Paragraph>
              </CardItem>
              <CardItem width="2" className={css['margin-value']}>
                <NumberInput
                  min="27"
                  id="margin-value"
                  value={debounce ? faceValueMarginTop : templateForm._get('marginTop')}
                  onChange={debounce ? onKeyPressMarginTop : onChangeMarginTop}
                  postfix={getTranslation('communication.mm')}
                  hideLabel
                />
              </CardItem>

              <CardItem width="12">
                <HorizontalDivider />
              </CardItem>
            </>
          )}
        </>
      )}

      {pdfTemplate?.useBuildIn ? (
        <AddCustomHTML templateUsecase={templateUsecase.pdf} />
      ) : (
        <ExistingCustomHTML fileName={pdfTemplate?.customTemplateFileName} templateUsecase={templateUsecase.pdf} />
      )}
    </>
  );
};

export default observer(PdfCardBody);
