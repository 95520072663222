import clone from 'clone';
import React from 'react';

import { UpdateConsumptionUnitTypeType } from '@zf/api-types/master-data/consumption-unit';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';

type Props = {
  config: UseConfigReturnType<UpdateConsumptionUnitTypeType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdateConsumptionUnitTypeType[]>,
    React.Dispatch<ValidatorCtxAction<UpdateConsumptionUnitTypeType[]>>
  ];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function ConsumptionUnitTypesContent(props: Props) {
  const { config } = props;
  const { sort } = config;
  const { i18n } = useAppContext();

  const tableColumns: ColumnType[] = [
    {
      label: i18n.getTranslation('general.name'),
      dataKey: 'name',
      flexWidth: 2
    },
    {
      label: i18n.getTranslation('general.description'),
      dataKey: 'description',
      flexWidth: 2
    }
  ];

  const { values, isDirty, selectedIndex, setValue, setSelectedIndex } = useContextValidator<
    UpdateConsumptionUnitTypeType[]
  >(props.useTracked);

  const setType = (
    index: number,
    value: UpdateConsumptionUnitTypeType[keyof UpdateConsumptionUnitTypeType],
    dataKey: keyof UpdateConsumptionUnitTypeType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdateConsumptionUnitTypeType[]
    });
  };

  const typeRows = values.map((type: UpdateConsumptionUnitTypeType, index: number) => {
    return {
      name: (
        <InlineInputFieldInput
          id={`type.name.index-${index}`}
          value={type.name}
          onChange={(val) => setType(index, val, 'name')}
          error={!type.name}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!type.id}
          disabled
        />
      ),
      description: (
        <InlineInputFieldInput
          id={`type.description.index-${index}`}
          value={type.description}
          onChange={(val) => setType(index, val, 'description')}
          error={!type.description}
          disabled
        />
      )
    };
  });

  return (
    <>
      <CardsContainer>
        <Card width="2" id="assets-card">
          <CardHeader
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.consumptionUnitTypes.title')}
                content={[i18n.getTranslation('coachmark.consumptionUnitTypes.paragraph')]}
              />
            }
          >
            {i18n.getTranslation('consumption_unit.consumption_units_depr')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="consumption-units-table"
              rows={typeRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
            />
          </CardBody>
        </Card>
      </CardsContainer>
    </>
  );
}
