import { observer } from 'mobx-react';
import React from 'react';

import { translationStatus } from '@zf/api-types/enums';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import FilterInputs from '../../../../components/Filters/filter-inputs';
import EnumFilterSelect from '../../../../components/input/EnumFilterSelect';
import { useStore } from '../../../../hooks/useStore';
import css from './translation-filters.module.scss';
import SearchBar, { SearchBarProps } from '../../../../components/input/SearchBar';

const InlineInputFieldSearchBar = InlineInputField<SearchBarProps>(SearchBar);

const TranslationFilters = () => {
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;
  const { setTranslationFilter, translationFilterState } = communicationStore.translationStore;

  return (
    <>
      <div className={css['filter']}>
        <FilterInputs
          filterInputs={[
            <EnumFilterSelect
              multiSelect
              id="completeness-status-filter"
              key="completeness-status-filter"
              title={getTranslation('general.status')}
              options={getEnum<translationStatus>('translationStatus')}
              values={translationFilterState.translationStatus || []}
              setValue={(value) => setTranslationFilter({ translationStatus: value as translationStatus[] })}
            />
          ]}
          removeAllFilters={() => {
            setTranslationFilter({ searchValue: '', translationStatus: [] });
          }}
        />
      </div>
      <InlineInputFieldSearchBar
        id="filter-searchbar"
        className={css['search-bar']}
        onChange={(value) => setTranslationFilter({ searchValue: value })}
        value={translationFilterState.searchValue}
        placeholder={getTranslation('general.label')}
        debounceTime={300}
      />
    </>
  );
};

export default observer(TranslationFilters);
