import React from 'react';

import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../../app-context';
import useCurrent from '../../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../../design-system/ComponentSets';
import DeleteContractDialog from '../../dialogs/delete-dialog';

type Props = {
  refreshCounts: () => void;
};

export default function DeleteContractListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('contract');
  const selectedRows: ContractRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<ContractType>('contract');
  const { clickRef, onSubmit } = useDialog();

  let isDisabled = false;

  if (
    selectedRows.length === 1 &&
    (selectedRows[0].__contractEntity.currentContractStatus === 'draft' ||
      selectedRows[0].__contractEntity.currentContractStatus === 'signed')
  ) {
    if (selectedRows[0].__contractEntity.blockedForDeletion) {
      isDisabled = true;
    }
  }

  return (
    <DropdownAction
      id="contract.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={
        <DeleteContractDialog
          onComplete={setDeletedRows}
          selectedRows={selectedRows}
          ref={clickRef}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      dialogType="danger"
      disabled={selectedRows.length === 0 || isDisabled}
      buttonPositive={i18n.getTranslation('general.delete')}
    />
  );
}
