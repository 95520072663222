import React from 'react';

import { DEFAULT_CULTURE } from '@zf/utils/src/internationalisation';
import { formatMoneyDigits, getCurrencySymbol } from '@zf/utils/src/number';

import StellaInputField from './StellaInputField';
import useFloatInput from './useFloatInput';

export type MoneyInputProps = {
  value: number | null | undefined;
  id: string;
  locale?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  hideLabel?: boolean;
  singleError?: string;
  suffix?: string;
  suffixPosition?: 'left' | 'right'
  error?: boolean;
  iconRight?: React.ReactNode;
  className?: string;
  autoFocus?: boolean;
  onChange: (value: number) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (step: string) => void;
  onClick?: () => void;
  onBlur?: () => void;
};

export default function StellaMoneyInput(props: MoneyInputProps) {
  const {
    autoFocus = false,
    value,
    id,
    locale = DEFAULT_CULTURE,
    placeholder,
    disabled,
    readonly,
    hideLabel,
    suffix = getCurrencySymbol(locale),
    suffixPosition = 'left',
    error,
    singleError,
    iconRight,
    className,
    onFocus,
    onClick,
    onBlur,
    onChange
  } = props;

  const { textValue, setTextValue, handleChange } = useFloatInput(value, locale, onChange);

  const handleBlur = () => {
    if (onBlur) onBlur();
    setTextValue(formatMoneyDigits(value, locale));
  };

  return (
    <StellaInputField
      onChange={handleChange}
      prefix={suffixPosition === "left" ? suffix : undefined}
      postfix={suffixPosition === "right" ? suffix : undefined}
      value={textValue}
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      readonly={readonly}
      onFocus={onFocus}
      onClick={onClick}
      onBlur={handleBlur}
      hideLabel={hideLabel}
      singleError={singleError}
      error={error}
      iconRight={iconRight}
      className={className}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      selectAllOnFocus
    />
  );
}
