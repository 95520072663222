import { PagedResponseType } from '@zf/api-types/api';
import { BillingRelationType } from '@zf/api-types/billing-relation';
import { BillingCompletenessInsightResponseType } from '@zf/api-types/billing/billing-completeness';
import { advanceAmountChangedBy, unitOfMeasure } from '@zf/api-types/enums';
import {
  ConsumerGroupType,
  GetServiceLocationMatchingConsumerGroupsRequest,
  GetServiceLocationMatchingConsumerGroupsResponse
} from '@zf/api-types/forecasting';
import { InvoicePaginatedResult } from '@zf/api-types/invoice';
import {
  AddLocationsToContractRequestType,
  BlockUnblockContractType,
  ChangeProductContractType,
  ChangeProductForContractsType,
  ContractType,
  CreateContractRequestType,
  RemoveLocationsFromContractRequestType
} from '@zf/api-types/master-data/contract';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import BaseService from '../../../app-context/services/BaseService';
import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { notify } from '../../../events/notification-events';
import { getLatestContractForLocation } from '../../../utils/location';
import { createHeader, METHODS } from '../../../utils/request';
import ContractStore from '../../../app-context/stores/master-data/contracts/detail-page/ContractStore';

export default class ContractService extends BaseService {
  private contractStore: ContractStore;

  constructor(contractStore: ContractStore, applicationStore: ApplicationStore) {
    super('/md/Contracts/', applicationStore);

    this.contractStore = contractStore;
  }

  createContract = async (values: CreateContractRequestType) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.POST,
          endpoint: this.endpoint,
          data: values
        }
      })
    ).data;
  };

  addLocationsToContract = async (contractId: string, values: AddLocationsToContractRequestType) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.PUT,
          endpoint: `${this.endpoint}${contractId}/services/add`,
          data: values
        }
      })
    ).data;
  };

  removeLocationsFromContract = async (contractId: string, values: RemoveLocationsFromContractRequestType) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.PUT,
          endpoint: `${this.endpoint}${contractId}/services/remove`,
          data: values
        }
      })
    ).data;
  };

  signContract = async (contract: ContractType, mutationDateTime: string, keepExistingInvoiceOnEndDate = false) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${contract.id}/sign`,
          data: { mutationDateTime, keepExistingInvoiceOnEndDate }
        },
        customHeaders: createHeader({
          'If-Match': contract._etag
        })
      })
    ).data;
  };

  updateExternalContractRef = async (contract: ContractType, externalContractReference: string) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${contract.id}/updateexternalcontractreference`,
          data: {
            externalContractReference: externalContractReference
          }
        },
        customHeaders: createHeader({
          'If-Match': contract._etag
        })
      })
    ).data;
  };

  updatePaymentTerm = async (contract: ContractType, paymentTerm: string) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${contract.id}/updatebillingdetails`,
          data: {
            paymentTermsId: paymentTerm
          }
        },
        customHeaders: createHeader({
          'If-Match': contract._etag
        })
      })
    ).data;
  };

  billingCompleteness = async (id: string) => {
    return (
      await this.applicationStore.sendRequest<BillingCompletenessInsightResponseType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/BillingCompletenesses/${id}/insights/`
        }
      })
    ).data;
  };

  getBillingRelation = async (contractId: string, customerId: string) => {
    return (
      await this.applicationStore.sendRequest<BillingRelationType | null>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/BillingRelations/${customerId}/${contractId}`
        }
      })
    ).data;
  };

  terminateContract = async (
    contract: ContractType,
    mutationDateTime: string,
    keepExistingInvoiceOnEndDate: boolean
  ) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${contract.id}/terminate`,
          data: { mutationDateTime, keepExistingInvoiceOnEndDate }
        },
        customHeaders: createHeader({
          'If-Match': contract._etag
        })
      })
    ).data;
  };

  changeProduct = async (value: ChangeProductContractType) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${value.contract.id}/updateproduct`,
          data: { productId: value.productId, startDate: value.startDate }
        },
        customHeaders: createHeader({
          'If-Match': value.etag
        })
      })
    ).data;
  };

  changeProductForContracts = async (apiValues: ChangeProductForContractsType) => {
    return (
      await this.applicationStore.sendRequest<ContractType[]>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/updateproduct`,
          data: apiValues
        }
      })
    ).data;
  };

  blockUnblockContract = async (value: BlockUnblockContractType, comment: string) => {
    const billingRelationResult = (
      await this.applicationStore.sendRequest<BillingRelationType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/BillingRelations/${value.contract.contractor.customerId}/${value.contract.id}`
        },
        donotCache: true
      })
    ).data;

    const apiType = value.contract.billingDetails.blocked ? 'unblock' : 'block';

    await this.applicationStore.sendRequest<ContractType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/BillingRelations/b/${billingRelationResult.id}/${apiType}`,
        data: { comment }
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      })
    });

    const contractObject = value.contract;
    contractObject.billingDetails.blocked = !contractObject.billingDetails.blocked;

    return contractObject;
  };

  getContractForId = async (id: string) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${id}`
        }
      })
    ).data;
  };

  changeBillingRelationAdvanceAmount = async (
    billingRelationId: string,
    newAdvanceAmount: number,
    sendConfirmationEmail: boolean
  ) => {
    return (
      await this.applicationStore.sendRequest<BillingRelationType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/BillingRelations/b/${billingRelationId}/changeadvanceamount`,
          data: {
            newAdvanceAmount,
            changedBy: advanceAmountChangedBy.customerservicerepresentative,
            sendConfirmationEmail
          }
        }
      })
    ).data;
  };

  changeContractualAdvanceAmount = async (contract: ContractType, newAdvanceAmount: number) => {
    return (
      await this.applicationStore.sendRequest<ContractType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${contract.id}/changeadvanceamount`,
          data: {
            newAdvanceAmount
          }
        },
        customHeaders: createHeader({
          'If-Match': contract._etag
        })
      })
    ).data;
  };

  getInvoicesForContract = async () => {
    const { id, contractor } = this.contractStore.selectedContract.contract;
    return (
      await this.applicationStore.sendRequest<InvoicePaginatedResult>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/invoices/c/${contractor.customerId}/${id}`
        }
      })
    ).data.results;
  };

  getConsumerGroups = async () => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<ConsumerGroupType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/fct/ConsumerGroups'
        }
      })
    ).data.results;
  };

  getMatchingConGroups = async (data: GetServiceLocationMatchingConsumerGroupsRequest) => {
    return (
      await this.applicationStore.sendRequest<GetServiceLocationMatchingConsumerGroupsResponse>({
        request: {
          method: METHODS.POST,
          endpoint: '/fct/consumerGroups/servicelocation/matchingconsumergroups',
          data
        }
      })
    ).data;
  };

  getConsumerGroup = async (id: string | undefined, unitOfMeasure_: unitOfMeasure) => {
    return (
      await this.applicationStore.sendRequest<ConsumerGroupType>({
        request: {
          method: METHODS.GET,
          endpoint: `/fct/ConsumerGroups/${id}/${unitOfMeasure_}`
        }
      })
    ).data;
  };

  getPreviousAdvanceAmount = async (location: ServiceLocationType) => {
    const contract = getLatestContractForLocation(location);

    let oldAdvanceAmount: number | undefined = undefined;

    if (contract) {
      try {
        const relation = await this.applicationStore.sendRequest<BillingRelationType>({
          request: {
            method: METHODS.GET,
            endpoint: `/bill/BillingRelations/${contract.contractorId}/${contract.contractId}`
          }
        });

        oldAdvanceAmount = relation.data.advanceDetails.advanceAmount;
      } catch (error) {
        notify.error({
          content: this.applicationStore.getTranslation('contracts.wizard.get_prev_contract_fail'),
          error
        });
      }
    }

    return oldAdvanceAmount;
  };
}
