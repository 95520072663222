import {
  collectionStepLevel,
  communicationType,
  contractScenarioType,
  entitySubjectType,
  envelopePosition,
  invoiceScenarioType,
  moveRequestScenarioType,
  billingRelationScenarioType,
  templateObjectType,
  translationStatus
} from '../enums';

export enum FileState {
  loading = 'loading',
  error = 'error',
  success = 'success',
  none = 'none'
}

export type TemplateAttachment = {
  id: string;
  internalFilePath: string;
  fileName: string;
};

export type LocalTemplateAttachmentType = Partial<TemplateAttachment> & {
  file?: File;
  state?: FileState;
  deleted?: boolean;
};

export type EntitySubjectUnionType =
  | entitySubjectType.invoice
  | entitySubjectType.collectioncase
  | entitySubjectType.contract
  | entitySubjectType.moverequest
  | entitySubjectType.billingrelation;

export type EntitySubjectSubtype =
  | invoiceScenarioType
  | collectionStepLevel
  | contractScenarioType
  | moveRequestScenarioType
  | billingRelationScenarioType;

export type ScenarioUnionType =
  | AdvanceScenario
  | CorrectionNoteScenario
  | CreditNoteScenario
  | EndNoteScenario
  | IncidentalNoteScenario
  | InvoiceScenario
  | CollectionScenario
  | ReminderScenario
  | ContractTerminationScenario
  | ContractWelcomeScenario
  | AcknowledgeMoveInScenario
  | AcknowledgeMoveOutScenario
  | RejectMoveInScenario
  | RejectMoveOutScenario;

export type ScenarioIntersectionType = AdvanceScenario &
  CorrectionNoteScenario &
  CreditNoteScenario &
  EndNoteScenario &
  IncidentalNoteScenario &
  InvoiceScenario &
  CollectionScenario &
  ReminderScenario &
  ContractTerminationScenario &
  ContractWelcomeScenario &
  AcknowledgeMoveInScenario &
  AcknowledgeMoveOutScenario &
  RejectMoveInScenario &
  RejectMoveOutScenario;

export type EmailTemplateIntersectionType = AdvanceEmailTemplate &
  CorrectionNoteEmailTemplate &
  CreditNoteEmailTemplate &
  EndNoteEmailTemplate &
  IncidentalNoteEmailTemplate &
  InvoiceEmailTemplate &
  CollectionEmailTemplate &
  ReminderEmailTemplate &
  ContractTerminationEmailTemplate &
  ContractWelcomeEmailTemplate &
  AcknowledgeMoveInEmailTemplate &
  AcknowledgeMoveOutEmailTemplate &
  RejectMoveInEmailTemplate &
  RejectMoveOutEmailTemplate &
  AdvanceAmountChangedScenario;

export type PDFTemplateIntersectionType = AdvancePdfTemplate &
  CorrectionNotePdfTemplate &
  CreditNotePdfTemplate &
  EndNotePdfTemplate &
  IncidentalNotePdfTemplate &
  InvoicePdfTemplate &
  CollectionPdfTemplate &
  ReminderPdfTemplate;

export type UpdateScenarioRequestType = {
  pdfTemplate?: Partial<PDFTemplateIntersectionType>;
  emailTemplate?: Partial<EmailTemplateIntersectionType>;
  defaultCommunicationType?: communicationType;
};

/**
 * Interfaces
 */
export interface BaseTemplate {
  fileName: string;
  useBuildIn: boolean;
  customTemplateInternalFilePath: string | null;
  customTemplateFileName: string | null;
  translationStatus: translationStatus;
}

export type EnvelopeSettings = {
  marginPosition: envelopePosition;
  margin: number;
  marginTop: number;
};

export interface BaseScenario {
  defaultCommunicationType: communicationType;
  assetListId: string;
  dataType: templateObjectType;
  entitySubjectType: entitySubjectType;
  availableCommunicationTypes: communicationType[];
}

export interface BasePdfTemplate extends BaseTemplate {
  envelopeSettings: EnvelopeSettings;
}

export interface BaseEmailTemplate extends BaseTemplate {
  subject: string;
  attachments: TemplateAttachment[];
}

/**
 * Scenarios
 */

// Invoices
export interface AdvancePdfTemplate extends BasePdfTemplate {
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
}

export interface AdvanceEmailTemplate extends BaseEmailTemplate {}

export interface AdvanceScenario extends BaseScenario {
  pdfTemplate: AdvancePdfTemplate;
  emailTemplate: AdvanceEmailTemplate;
}

//

export interface CorrectionNotePdfTemplate extends BasePdfTemplate {
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
}

export interface CorrectionNoteEmailTemplate extends BaseEmailTemplate {}

export interface CorrectionNoteScenario extends BaseScenario {
  pdfTemplate: CorrectionNotePdfTemplate;
  emailTemplate: CorrectionNoteEmailTemplate;
}

//

export interface CreditNotePdfTemplate extends BasePdfTemplate {
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
}

export interface CreditNoteEmailTemplate extends BaseEmailTemplate {}

export interface CreditNoteScenario extends BaseScenario {
  pdfTemplate: CreditNotePdfTemplate;
  emailTemplate: CreditNoteEmailTemplate;
}

//

export interface EndNotePdfTemplate extends BasePdfTemplate {
  costAllocation: boolean;
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
}

export interface EndNotePdfTemplate extends BasePdfTemplate {
  costAllocation: boolean;
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
}

export interface EndNoteEmailTemplate extends BaseEmailTemplate {}

export interface EndNoteScenario extends BaseScenario {
  pdfTemplate: EndNotePdfTemplate;
  emailTemplate: EndNoteEmailTemplate;
}

//

export interface IncidentalNotePdfTemplate extends BasePdfTemplate {
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
}

export interface IncidentalNoteEmailTemplate extends BaseEmailTemplate {}

export interface IncidentalNoteScenario extends BaseScenario {
  pdfTemplate: IncidentalNotePdfTemplate;
  emailTemplate: IncidentalNoteEmailTemplate;
}

//

export interface InvoicePdfTemplate extends BasePdfTemplate {
  costAllocation: boolean;
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
}

export interface InvoiceEmailTemplate extends BaseEmailTemplate {}

export interface InvoiceScenario extends BaseScenario {
  pdfTemplate: InvoicePdfTemplate;
  emailTemplate: InvoiceEmailTemplate;
}

// Move request
export interface AcknowledgeMoveInEmailTemplate extends BaseEmailTemplate {}

export interface AcknowledgeMoveInScenario extends BaseScenario {
  emailTemplate: AcknowledgeMoveInEmailTemplate;
}

//

export interface AcknowledgeMoveOutEmailTemplate extends BaseEmailTemplate {}

export interface AcknowledgeMoveOutScenario extends BaseScenario {
  emailTemplate: AcknowledgeMoveOutEmailTemplate;
}

//

export interface RejectMoveInEmailTemplate extends BaseEmailTemplate {}

export interface RejectMoveInScenario extends BaseScenario {
  emailTemplate: RejectMoveInEmailTemplate;
}

//

export interface RejectMoveOutEmailTemplate extends BaseEmailTemplate {}

export interface RejectMoveOutScenario extends BaseScenario {
  emailTemplate: RejectMoveOutEmailTemplate;
}

// Collection cases
export interface CollectionPdfTemplate extends BasePdfTemplate {
  showCountry: boolean;
}

export interface CollectionEmailTemplate extends BaseEmailTemplate {}

export interface CollectionScenario extends BaseScenario {
  pdfTemplate: CollectionPdfTemplate;
  emailTemplate: CollectionEmailTemplate;
}

//

export interface NoticeOfDefaultPdfTemplate extends BasePdfTemplate {
  showCountry: boolean;
}

export interface NoticeOfDefaultEmailTemplate extends BaseEmailTemplate {}

export interface NoticeOfDefaultScenario extends BaseScenario {
  pdfTemplate: NoticeOfDefaultPdfTemplate;
  emailTemplate: NoticeOfDefaultEmailTemplate;
}

//

export interface ReminderPdfTemplate extends BasePdfTemplate {
  showCountry: boolean;
}

export interface ReminderEmailTemplate extends BaseEmailTemplate {}

export interface ReminderScenario extends BaseScenario {
  pdfTemplate: ReminderPdfTemplate;
  emailTemplate: ReminderEmailTemplate;
}

// Contract
export interface ContractTerminationEmailTemplate extends BaseEmailTemplate {}

export interface ContractTerminationScenario extends BaseScenario {
  emailTemplate: ContractTerminationEmailTemplate;
}

//

export interface ContractWelcomeEmailTemplate extends BaseEmailTemplate {}

export interface ContractWelcomeScenario extends BaseScenario {
  emailTemplate: ContractWelcomeEmailTemplate;
}

export type ResetTranslationRequest = {
  updateAllScenarios: boolean;
  entitySubjectType: EntitySubjectUnionType;
  entitySubjectSubType: EntitySubjectSubtype;
};

// Move request
export interface AdvanceAmountChangedEmailTemplate extends BaseEmailTemplate {}

export interface AdvanceAmountChangedScenario extends BaseScenario {
  emailTemplate: AdvanceAmountChangedEmailTemplate;
}
