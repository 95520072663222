import React from 'react';

import { Avatar } from '@zf/stella-react/src/atoms/Avatar';
import { generateColor } from '@zf/utils/src/color';

type Props = {
  children: string;
  type?: string;
  circle?: boolean;
  className?: string;
  style?: Record<string, any>;
  size?: string;
  isClickable?: boolean;
};

export default function GeneratedColorAvatar(props: Props) {
  const { children, ...otherProps } = props;

  return (
    <Avatar {...otherProps} color={generateColor(children)}>
      {children}
    </Avatar>
  );
}
