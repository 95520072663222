import React from 'react';

import { ContactDetailType } from '@zf/api-types/settings-config';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../app-context/app-context';
import InputField from '../../components/input/InputField';

type Props = {
  contactDetails: ContactDetailType | undefined;
  defaultContactDetails?: ContactDetailType;
  setContactDetailsValue: (value: Partial<ContactDetailType>) => void;
  resetContactDetailsValue?: (key: keyof ContactDetailType) => void;
};

export default function ContactDetailsCard(props: Props) {
  const { defaultContactDetails, setContactDetailsValue, resetContactDetailsValue } = props;
  let { contactDetails } = props;
  const { i18n } = useAppContext();

  if (!contactDetails) {
    contactDetails = { emailAddress: '', telephone: '', website: '', telephoneInterruptions: '', support: '' };
  }

  return (
    <Card id="contact-details-card" width="1">
      <CardHeader>{i18n.getTranslation('organization.contactDetails')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.email_address')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="propertygroup.email"
            onChange={(val) => setContactDetailsValue({ emailAddress: val })}
            value={contactDetails.emailAddress}
            resetValue={defaultContactDetails?.emailAddress}
            reset={resetContactDetailsValue ? () => resetContactDetailsValue('emailAddress') : undefined}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.telephone')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="propertygroup.telephone"
            onChange={(val) => setContactDetailsValue({ telephone: val })}
            value={contactDetails.telephone}
            resetValue={defaultContactDetails?.telephone}
            reset={resetContactDetailsValue ? () => resetContactDetailsValue('telephone') : undefined}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.website')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="propertygroup.website"
            onChange={(val) => setContactDetailsValue({ website: val })}
            value={contactDetails.website}
            resetValue={defaultContactDetails?.website}
            reset={resetContactDetailsValue ? () => resetContactDetailsValue('website') : undefined}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.support')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="support-website"
            onChange={(val) => setContactDetailsValue({ support: val })}
            value={contactDetails.support}
            resetValue={contactDetails.support}
            reset={resetContactDetailsValue ? () => resetContactDetailsValue('support') : undefined}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.telephoneInterruptions')}</Label>
        </CardItem>
        <CardItem width="6">
          <InputField
            id="propertygroup.interruptions"
            onChange={(val) => setContactDetailsValue({ telephoneInterruptions: val })}
            value={contactDetails.telephoneInterruptions}
            resetValue={defaultContactDetails?.telephoneInterruptions}
            reset={resetContactDetailsValue ? () => resetContactDetailsValue('telephoneInterruptions') : undefined}
          />
        </CardItem>
      </CardBody>
    </Card>
  );
}
