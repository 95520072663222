import clone from 'clone';
import React from 'react';

import { BankAccountType, UpdateBankAccountType } from '@zf/api-types/general';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import CommitSection from '../../../components/config/commit-section';
import { DeleteIcon } from '../../../components/Icon';
import CheckBox from '../../../components/input/CheckBox';
import IBANInput from '../../../components/input/IBANInput';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import { notify } from '../../../events/notification-events';
import { RequestType } from '../../../types/Request';
import { createHeader, METHODS, sendRequest } from '../../../utils/request';

type Props = {
  config: UseConfigReturnType<UpdateBankAccountType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdateBankAccountType[]>,
    React.Dispatch<ValidatorCtxAction<UpdateBankAccountType[]>>
  ];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineInputFieldIBAN = InlineInputField<InputFieldProps>(IBANInput);

export default function BankaccountsContent(props: Props) {
  const { config } = props;
  const { sort } = config;
  const { i18n, tenantReducer } = useAppContext();

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 2,
      label: i18n.getTranslation('organization.bank_account_config.account_holder'),
      dataKey: 'accountHolder'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('organization.bank_account_config.iban'),
      dataKey: 'iban'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('organization.bank_account_config.bic'),
      dataKey: 'bic'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('organization.bank_account_config.sepa_creditor_id'),
      dataKey: 'sepaCreditorId'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('general.default'),
      dataKey: 'isDefault'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const { values, backup, isDirty, isLocked, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator<UpdateBankAccountType[]>(props.useTracked);

  const { addEntity, deleteEntity, backupValues } = useContextCRUD(props.useTracked);

  const setDefault = (index: number, value: boolean) => {
    const currentDefaultIndex = values.findIndex((b) => {
      return b.isDefault;
    });

    const valuesClone = clone(values);

    if (currentDefaultIndex !== -1) {
      valuesClone[currentDefaultIndex].isDefault = false;
    }

    valuesClone[index].isDefault = value;

    setValue({ values: valuesClone });
  };

  const addAccount = () => {
    addEntity({
      accountHolder: '',
      iban: '',
      bic: '',
      sepaCreditorId: '',
      isDefault: values.length === 0
    });
  };

  const deleteAccount = (index: number) => {
    if (values[index].isDefault) {
      notify.warning({
        content: i18n.getTranslation('organization.bank_account_config.default_delete_warning')
      });
    } else {
      deleteEntity(index);
    }
  };

  const createApiFriendlyValues = React.useCallback((account: UpdateBankAccountType) => {
    return {
      accountHolder: account.accountHolder,
      bic: account.bic,
      iban: account.iban,
      sepaCreditorId: account.sepaCreditorId,
      isDefault: account.isDefault
    };
  }, []);

  const submitBankAccount = async (
    account: UpdateBankAccountType,
    indexesAffected: number[],
    newEntities: UpdateBankAccountType[]
  ) => {
    let request: RequestType | undefined = undefined;

    const apiFriendlyValues = createApiFriendlyValues(account);

    // Delete
    if (account.id && account.delete) {
      request = {
        method: METHODS.DELETE,
        endpoint: `/cfg/BankAccounts/${account.id}`
      };
    } else if (!account.id) {
      // Create
      request = {
        method: METHODS.POST,
        endpoint: '/cfg/BankAccounts',
        data: {
          ...apiFriendlyValues
        }
      };
    } else {
      // Update
      const initialEntity = backup.find((initial) => initial.id === account.id);

      if (JSON.stringify(initialEntity) !== JSON.stringify(account))
        request = {
          method: METHODS.POST,
          endpoint: `/cfg/BankAccounts/${account.id}`,
          data: {
            ...apiFriendlyValues
          }
        };
    }

    if (request) {
      const newEntity = (
        await sendRequest<BankAccountType>({
          request,
          customHeaders: createHeader({
            'If-Match': account._etag
          }),
          tenantReducer,
          lang: i18n.lang
        })
      ).data;

      if (!account.delete) {
        const indexToReplace = values.findIndex((v) => {
          if (account.id) {
            return v.id === account.id;
          } else {
            return v.iban === account.iban;
          }
        });

        indexesAffected.push(indexToReplace);
        newEntities.push(newEntity);
      }
    }
  };

  const saveEntities = async () => {
    try {
      if (isLocked || !isDirty) return;

      setValue({ isLocked: true });

      // Separate default account from the rest
      const entitiesToHandle = clone(values).filter((acc) => {
        return !acc.isDefault;
      });

      backup.forEach((initialEntity) => {
        if (!values.find((e) => initialEntity.id === e.id)) {
          initialEntity.delete = true;
          entitiesToHandle.push(initialEntity);
        }
      });

      const indexesAffected: number[] = [];
      const newEntities: UpdateBankAccountType[] = [];

      const defaultAccount = values.find((acc) => {
        return acc.isDefault;
      });

      // Default account has to be sent first
      if (defaultAccount) {
        await submitBankAccount(defaultAccount, indexesAffected, newEntities);
      }

      await Promise.all(
        entitiesToHandle.map(async (account) => {
          return submitBankAccount(account, indexesAffected, newEntities);
        })
      );

      notify.success({
        content: i18n.getTranslation('organization.bank_account_config.success_update')
      });

      return { indexesAffected, newEntities };
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('organization.bank_account_config.error_update'),
        error: e
      });
      setValue({ isLocked: false });
    }
  };

  const handleSave = async () => {
    const updateInfo = await saveEntities();

    const valuesClone = clone(values);

    if (updateInfo) {
      const { indexesAffected, newEntities } = updateInfo;

      indexesAffected.forEach((i, entityIndex) => {
        valuesClone[i] = newEntities[entityIndex];
      });
    }

    // IsLocked: false is included in backup
    backupValues(valuesClone);
  };

  const setBankAccount = (
    index: number,
    value: UpdateBankAccountType[keyof UpdateBankAccountType],
    dataKey: keyof UpdateBankAccountType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdateBankAccountType[]
    });
  };

  const bankAccountRows = values.map((account, index) => {
    return {
      accountHolder: (
        <InlineInputFieldInput
          id={`bankaccount.holder.index-${index}`}
          value={account.accountHolder}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!account.accountHolder}
          onChange={(val) => setBankAccount(index, val, 'accountHolder')}
          error={!account.accountHolder}
        />
      ),
      bic: (
        <InlineInputFieldInput
          id={`bankaccount.bic.index-${index}`}
          value={account.bic}
          onChange={(val) => setBankAccount(index, val, 'bic')}
          error={!account.bic}
        />
      ),
      iban: account.id ? (
        account.iban
      ) : (
        <InlineInputFieldIBAN
          id={`bankaccount.iban.index-${index}`}
          value={account.iban}
          onChange={(val) => setBankAccount(index, val, 'iban')}
          error={!account.iban}
        />
      ),
      sepaCreditorId: (
        <InlineInputFieldInput
          id={`bankaccount.sepa_id.index-${index}`}
          value={account.sepaCreditorId}
          onChange={(val) => setBankAccount(index, val, 'sepaCreditorId')}
          error={!account.sepaCreditorId}
        />
      ),
      isDefault: (
        <CheckBox
          id={`bankaccount.is_default.index-${index}`}
          checked={account.isDefault}
          onChange={(val) => setDefault(index, val)}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`bankaccount.delete.index-${index}`}
          tooltipFor="bank-accounts-table"
          onClick={() => deleteAccount(index)}
        />
      )
    };
  });

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleSave} isDirty={isDirty} />
      <CardsContainer>
        <Card id="bank-accounts-card" width="3">
          <CardHeader
            extraRight={
              <Button id="bankaccount.add" type="text" icon="plus" onClick={addAccount}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('organization.bank_accounts')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="bank-accounts-table"
              rows={bankAccountRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
            />
          </CardBody>
        </Card>
      </CardsContainer>
    </>
  );
}
