import Interweave from 'interweave';
import React, { ReactNode, useEffect, useReducer } from 'react';

import { isEmptyObject } from '@zf/utils/src/object';
import { capitalizeFirstWord } from '@zf/utils/src/string';

import * as notificationEvents from '../../../events/notification-events';
import Notification, { NotificationType } from './Notification';
import css from './style.module.scss';
import { NotificationContent, NotificationObjectType } from './types/notifications';

export type Props = {
  defaultTimeout: number;
};

type NotificationValueType = {
  content: NotificationContent;
  options: {
    id: string;
    timeout?: number;
    onClose: () => void;
    type: NotificationType;
  };
};

type State = {
  [id: string]: NotificationValueType;
};

type Action = {
  action: 'delete' | 'add';
  notificationId: string;
  notification?: NotificationValueType;
};

const notificationReducer = (state: State, action: Action) => {
  const stateClone = { ...state };

  switch (action.action) {
    case 'delete':
      delete stateClone[action.notificationId];
      break;
    case 'add':
      if (!action.notification) throw new Error('Add action in notificationReducer requires a notification!');

      stateClone[action.notificationId] = action.notification;
      break;
  }

  return stateClone;
};

//** TODO in future refactor:
// move the state of this component to app context (because this is component state and not nicely accessible)
// get the id of a notification doing "const id = notify.success(......" (factory function)
// use this id to update the corresponding notification in context
// criterium used to auto close a notification is timeout !== 0

export default function NotificationCenter(props: Props) {
  const { defaultTimeout } = props;

  const [notifications, dispatch]: [State, (action: Action) => void] = useReducer(notificationReducer, {});

  useEffect(() => {
    const removeNotification = (id: string) => {
      dispatch({
        action: 'delete',
        notificationId: id
      });
    };

    const createNotification = ({ options, content }: NotificationObjectType) => {
      dispatch({
        action: 'add',
        notificationId: options.id,
        notification: {
          content,
          options: {
            id: options.id,
            type: options.type,
            onClose: () => removeNotification(options.id),
            timeout: options.timeout !== undefined ? options.timeout : defaultTimeout
          }
        }
      });
    };

    notificationEvents.on(notificationEvents.ACTION.SHOW, createNotification);

    return () => {
      notificationEvents.removeListener(notificationEvents.ACTION.SHOW, createNotification);
    };
  }, [true]);

  if (isEmptyObject(notifications)) return null;

  const renderContent = (value: ReactNode) => {
    if (typeof value === 'string') {
      return <Interweave content={capitalizeFirstWord(value)} />;
    } else {
      return value;
    }
  };

  return (
    <div className={css['notification-center']}>
      {Object.values(notifications).map((value: NotificationValueType) => {
        renderContent(value.content?.content);
        return (
          <Notification {...value.options} {...value.content} key={value.options.id}>
            {value.content ? renderContent(value.content.content) : ''}
          </Notification>
        );
      })}
    </div>
  );
}
