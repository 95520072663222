import React from 'react';

import Center from '@zf/stella-react/src/helpers/Center';

import { Loader } from './';

export default function SuspenseFallback() {
  return (
    <Center type="both">
      <Loader />
    </Center>
  );
}
