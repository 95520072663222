import React from 'react';

import { ImportInsightsType } from '@zf/api-types/analytics/metering';
import { PagedResponseType } from '@zf/api-types/api';
import { importJobStatus } from '@zf/api-types/enums';
import { FileFormatType } from '@zf/api-types/file-format';
import { Label } from '@zf/stella-react/src/atoms/Label';
import IconLabel from '@zf/stella-react/src/atoms/Label/StellaIconLabel';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Tree, TreeItem } from '@zf/stella-react/src/atoms/Tree';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import { DANGER } from '../../../../constants/color';
import { BaseLink } from '../../../../design-system/Components';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import css from './tree-style.module.scss';

type Props = {
  importInsights: ImportInsightsType[];
  fileFormats: FileFormatType[];
  setFileFormats: React.Dispatch<React.SetStateAction<FileFormatType[]>>;
};

export default function ImportCardBody(props: Props) {
  const { fileFormats, setFileFormats } = props;
  const { importInsights } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const fileFormatsResponse = useSuspenseSingleAPI<PagedResponseType<FileFormatType>>({
    request: {
      endpoint: '/cfg/DataImportFileFormats'
    }
  });

  React.useEffect(() => {
    if (fileFormatsResponse.result) {
      const filteredFormats = fileFormatsResponse.result.data.results;

      setFileFormats(filteredFormats);
    }
  }, [fileFormatsResponse.result]);

  if (!fileFormatsResponse.result) return null;

  const failed = importInsights.reduce((acc: number, insight: ImportInsightsType) => {
    acc += insight.failed;
    return acc;
  }, 0);

  const extraRight =
    importInsights.length === 0 ? (
      <BaseLink id="configure-import" className={css['configure']} url={`${rootUrl}/configuration/import-jobs`}>
        {i18n.getTranslation('general.configure')}
      </BaseLink>
    ) : (
      <Paragraph className={css['failed-paragraph']}>
        {i18n.getTranslation('dashboard.general_tab.amount_failed', {
          amount: failed
        })}
      </Paragraph>
    );

  const labelColor = colors['graphite'];

  return (
    <Tree
      id={'import-insights-tree'}
      title={
        <>
          <IconLabel
            className={css['title']}
            labelText={i18n.getTranslation('import_jobs.import')}
            iconType="import-job"
            color={colors['graphite']}
          />
          {extraRight}
        </>
      }
      className={css['tree']}
      clickeableContent={false}
      arrowType="filled"
      openOnInit={importInsights.length > 0}
    >
      {importInsights.map((insight, index) => {
        const format = fileFormats.find((f) => {
          return f.id === insight.dataImportId;
        });

        const treeTitle = format ? format.name : '';

        return (
          <Tree
            id={`import-insight-${index}`}
            key={`import-insight-${index}`}
            title={<Label className={css['insights-title']}>{treeTitle}</Label>}
            className={css['tree-nested']}
            clickeableContent={false}
            arrowType="filled"
            openOnInit={index === 0}
          >
            <TreeItem id={`last-time-received-${index}`} className={css['tree-item']} onClick={() => {}}>
              <Label>{i18n.getTranslation('meter.last-time-received')}</Label>
              <Paragraph>{formatDate(insight.lastTimeReceived)}</Paragraph>
            </TreeItem>

            <TreeItem id={`failed-${index}`} className={css['tree-item']} onClick={() => {}}>
              <IconLabel
                iconType="exclamation"
                labelText={enumReducer.getTranslation('importJobStatus', importJobStatus.failed)}
                color={DANGER}
              />
              <Paragraph>{insight.failed}</Paragraph>
            </TreeItem>

            <TreeItem id={`invalid-${index}`} className={css['tree-item']} onClick={() => {}}>
              <IconLabel
                iconType="invalid"
                labelText={enumReducer.getTranslation('importJobStatus', importJobStatus.invalid)}
                color={labelColor}
              />
              <Paragraph>{insight.invalid}</Paragraph>
            </TreeItem>

            <TreeItem id={`unprocessed-${index}`} className={css['tree-item']} onClick={() => {}}>
              <IconLabel
                iconType="unprocessed"
                labelText={i18n.getTranslation('import_jobs.unprocessed')}
                color={labelColor}
              />
              <Paragraph>{insight.unprocessed}</Paragraph>
            </TreeItem>
          </Tree>
        );
      })}
    </Tree>
  );
}
