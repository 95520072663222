import React from 'react';

import { contactType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';

import { useAppContext } from '../../../../app-context';
import { Icon } from '../../../../components/Icon';
import InputField from '../../../../components/input/InputField';
import { validateEmail } from '../../../../utils/email';
import css from './communication-section.module.scss';

export type Props = {
  id: string;
  contactType_: contactType | 'description';
  value: string;
  className?: string;
  descriptionValue?: string;
  mayAddDescription?: boolean;
  setContactDetailValue: (cType: contactType | 'description', val: Partial<ContactEntryType>) => void;
  onFocus?: () => void;
};

export default function ContactDetail(props: Props) {
  const {
    id,
    className,
    contactType_,
    value,
    descriptionValue,
    mayAddDescription = false,
    setContactDetailValue,
    onFocus
  } = props;
  const { i18n, enumReducer } = useAppContext();

  return (
    <div className={className}>
      <div className={css['icon-input']}>
        <Icon type={contactType_} />
        <InputField
          id={`${id}-value-input`}
          onChange={(value) => setContactDetailValue(contactType_, { value })}
          value={value}
          placeholder={enumReducer.getTranslation('contactType', contactType_)}
          onFocus={onFocus}
          error={!!value && contactType_ === contactType.email && !validateEmail(value)}
        />
      </div>
      {mayAddDescription && (
        <div className={css['optional-description']}>
          <InputField
            id={`${id}-description-input`}
            onChange={(description) => setContactDetailValue(contactType_, { description })}
            value={descriptionValue}
            placeholder={i18n.getTranslation('customer.descr_optional')}
            onFocus={onFocus}
            lightMode
          />
        </div>
      )}
    </div>
  );
}
