import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import useDialog from '@zf/hooks/src/useDialog';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import Introduction from '@zf/stella-react/src/atoms/Introduction/Introduction';

import AddEditIncomingInvoiceDialog from '../../../../../../actions/property-group/add-edit-incoming-invoice-dialog';
import { useAppContext } from '../../../../../../app-context/app-context';
import ConfirmationActionButton from '../../../../../../components/Button/ConfirmationActionButton';
import useBillingData from '../../hooks/use-billing-data';
import IncomingInvoiceComponentCard from './incoming-invoice-component-card';
import IncomingInvoices from './incoming-invoices';
import css from './incoming-invoices-tab.module.scss';

type Props = {
  propertyGroup: PropertyGroupType;
};

export default function IncomingInvoicesTab(props: Props) {
  const { propertyGroup } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const {
    incomingInvoices,
    incomingInvoiceComponents,
    propertyGroupBillingConfiguration,
    billingPeriods,
    selectedIncomingInvoice,
    setSelectedInvoice,
    addIncomingInvoiceToState,
    updateBillingPeriodsInState
  } = useBillingData();

  if (!propertyGroupBillingConfiguration || !propertyGroupBillingConfiguration.propertyGroup) return null;

  const componentsOnThisPropertyGroup = incomingInvoiceComponents.filter((iic) =>
    propertyGroupBillingConfiguration.requiredIncomingInvoiceComponents?.some((riic) => riic.id === iic.id)
  );

  return (
    <>
      <CardsContainer>
        <Introduction
          width="2"
          className={css['intro']}
          headerText={i18n.getTranslation('property_groups.tabs.billing.intro_heading')}
          introductionText={i18n.getTranslation('property_groups.tabs.billing.intro_descr')}
        >
          <ConfirmationActionButton
            id="incoming_invoice.add"
            dialogTitle={i18n.getTranslation('property_groups.tabs.billing.add_incoming_invoice')}
            dialogLocation="right"
            icon="plus"
            dialogContent={
              <AddEditIncomingInvoiceDialog
                propertyGroupId={propertyGroup.id}
                billingPeriods={billingPeriods.filter((bp) => {
                  return !bp.locked;
                })}
                components={componentsOnThisPropertyGroup}
                ref={clickRef}
                validationRef={validationRef}
                addIncomingInvoiceToState={addIncomingInvoiceToState}
                updateBillingPeriodsInState={updateBillingPeriodsInState}
              />
            }
            onSubmit={onSubmit}
            buttonPositive={i18n.getTranslation('general.add')}
            validationRef={validationRef}
          />
        </Introduction>

        <IncomingInvoiceComponentCard
          propertyGroupId={propertyGroup.id}
          components={incomingInvoiceComponents}
          billingConfig={propertyGroupBillingConfiguration}
        />

        <br />
      </CardsContainer>
      {propertyGroupBillingConfiguration && (
        <IncomingInvoices
          {...props}
          propertyGroupId={propertyGroupBillingConfiguration.propertyGroup.id}
          invoices={incomingInvoices}
          components={componentsOnThisPropertyGroup}
          billingPeriods={billingPeriods}
          selectedInvoice={selectedIncomingInvoice}
          setSelectedInvoice={setSelectedInvoice}
        />
      )}
    </>
  );
}
