import DatePicker, { DatePickerProps } from 'components/Lang/DatePicker';
import { Disabled, RadioInput } from 'design-system/Components';
import { Description } from 'design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { contractStatus } from '@zf/api-types/enums';
import { ContractType } from '@zf/api-types/master-data/contract';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatDate, formatPeriod, isMinDate } from '@zf/utils/src/date';

import BillingPeriodStartOption from './BillingPeriodStartOption';
import css from './supply-date-inputs.module.scss';

export enum supplyDateType {
  startofcontract = 'startofcontract',
  billingperiodstart = 'billingperiodstart',
  specific = 'specific'
}

export type SupplyDateInputValues = {
  supplyDateType_: supplyDateType;
  supplyDate: Moment | null;
  billingCompleteness: BillingCompletenessType | undefined;
};

type Props = {
  title: string;
  description: string;
  contract: ContractType | null | undefined;
  supplyDateType_: supplyDateType;
  supplyDate: Moment | null;
  billingCompleteness: BillingCompletenessType | undefined;
  setValue: (val: Partial<SupplyDateInputValues>) => void;
};

const InlineDatePicker = InlineInputField<DatePickerProps>(DatePicker);

const SupplyDateInputs = (props: Props) => {
  const { title, description, contract, supplyDateType_, supplyDate, billingCompleteness, setValue } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  if (!contract) return null;

  const setStartDateType = (supplyDateType_: supplyDateType, option: string) => {
    const castedOption = option as supplyDateType;

    switch (supplyDateType_) {
      case supplyDateType.startofcontract:
        setValue({
          supplyDateType_: castedOption,
          supplyDate: moment(contract.supplyStartDate),
          billingCompleteness: undefined
        });
        break;
      case supplyDateType.billingperiodstart:
        setValue({
          supplyDateType_: castedOption,
          supplyDate: null
        });
        break;
      default:
        setValue({
          supplyDateType_: castedOption,
          supplyDate: null,
          billingCompleteness: undefined
        });
        break;
    }
  };

  const contractIsDraft = contract?.currentContractStatus === contractStatus.draft;

  return (
    <>
      <Disabled disabled={contractIsDraft}>
        <FlexElements className={css['supply-date-wrapper']} flexDirection="column" gap="16">
          <Description title={title} description={description} />
          <RadioInput
            onChange={(option) => setStartDateType(supplyDateType.startofcontract, option)}
            value={supplyDateType.startofcontract}
            name={supplyDateType.startofcontract}
            checked={supplyDateType_ === supplyDateType.startofcontract}
          >
            {getTranslation('contracts.start_contract_wizard', {
              startDate: formatDate(contract?.supplyStartDate)
            })}
          </RadioInput>

          {contract && contract.currentContractStatus !== contractStatus.draft && (
            <BillingPeriodStartOption
              contract={contract}
              supplyDateType_={supplyDateType_}
              billingCompleteness={billingCompleteness}
              setStartDateType={setStartDateType}
              setValue={setValue}
            />
          )}

          <RadioInput
            onChange={(option) => setStartDateType(supplyDateType.specific, option)}
            value={supplyDateType.specific}
            name={supplyDateType.specific}
            checked={supplyDateType_ === supplyDateType.specific}
          >
            {getTranslation('contracts.specific_date')}
          </RadioInput>
        </FlexElements>
      </Disabled>

      {supplyDateType_ === supplyDateType.specific && (
        <Disabled disabled={contractIsDraft}>
          <div className={css['custom-date']}>
            <InlineDatePicker
              id="contracts.custom-date"
              onChange={(supplyDate) => setValue({ supplyDate })}
              value={supplyDate}
              placeholder={getTranslation('general.date')}
              error={supplyDateType_ === supplyDateType.specific && (isMinDate(supplyDate) || supplyDate === null)}
            />
            {!supplyDate?.isBetween(contract.supplyStartDate, contract.supplyEndDate, undefined, '[]') && (
              <Paragraph color={colors['red-600']}>
                {getTranslation('contracts.wizard.choose_date_within_supply_period', {
                  supplyPeriod: formatPeriod(contract.supplyStartDate, contract.supplyEndDate)
                })}
              </Paragraph>
            )}
          </div>
        </Disabled>
      )}
    </>
  );
};

export default observer(SupplyDateInputs);
