import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { utilityType } from '@zf/api-types/enums';
import { PropertyGroupForecastingConfiguration } from '@zf/api-types/forecasting/propertygroup-forecasting-cfg';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { Select } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import ConsumptionGroupDropdown from './ConsumptionGroupDropdown';
import css from './consumption-card.module.scss';

type Props = {
  propertyGroupForecastingConfig: Partial<PropertyGroupForecastingConfiguration>;
  availableUtilityTypes: utilityType[];
  selectedUtilityTypes: utilityType[];
  setFctConfigValue: (utilityType: utilityType, consumerGroupId: string, consumerGroupCode: string) => void;
  setSelectedUtilityTypes: (value: utilityType[]) => void;
};

const ConsumptionGroupCard = (props: Props) => {
  const {
    availableUtilityTypes,
    propertyGroupForecastingConfig,
    selectedUtilityTypes,
    setFctConfigValue,
    setSelectedUtilityTypes
  } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const utilityTypes = availableUtilityTypes.map((u) => {
    return {
      icon: u,
      value: u,
      text: getEnumTranslation('utilityType', u)
    };
  });

  return (
    <Card id="consumer-groups-card" width="1">
      <CardHeader>{getTranslation('property_groups.consumer_groups')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="12" justifyContent="center">
          <Select
            id="property-group-utility-types"
            className={css['utility-types']}
            type="small"
            onChange={setSelectedUtilityTypes}
            selectedValues={selectedUtilityTypes}
            values={utilityTypes}
            mayDeselect
          />
        </CardItem>
        {selectedUtilityTypes.map((u, index) => {
          const configuredConsumerGroupId = propertyGroupForecastingConfig?.consumerGroups?.[u]?.id;

          return (
            <Fragment key={`${u}-${index}`}>
              <CardItem width="6">
                <Label>{getEnumTranslation('utilityType', u)}</Label>
              </CardItem>
              <CardItem width="6">
                <ConsumptionGroupDropdown
                  id={`${u}-${index}`}
                  utilityType={u}
                  configuredConsumerGroupId={configuredConsumerGroupId}
                  setFctConfigValue={setFctConfigValue}
                />
              </CardItem>
            </Fragment>
          );
        })}
      </CardBody>
    </Card>
  );
};

export default observer(ConsumptionGroupCard);
