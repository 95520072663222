import React from 'react';

import { InvoiceType } from '../../../../api-types/invoice';
import { useAppContext } from '../../app-context';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import useCreateRequest, { queryParam } from '../../hooks/useCreateRequest';
import useInfiniAPI from '../../hooks/useInfiniAPI';
import useInvoiceCard, { InvoiceRowsType } from './hooks/useInvoiceCard';
import NoInvoicesOverlay from './NoInvoicesOverlay';

type Props = {
  endpoint: string;
  queryParams: queryParam;
};

export default function InvoiceCardTable(props: Props) {
  const { endpoint, queryParams } = props;

  const { i18n, tenantReducer } = useAppContext();

  const { columns, processRecord } = useInvoiceCard();

  const { request, setSelectedIds, handleSort, sortBy, sortDirection } = useCreateRequest(endpoint, {
    orderBy: '-InvoiceDate',
    ...queryParams
  });

  const { loading, error, rows, setStopIndex, sortableFields } = useInfiniAPI<InvoiceType, InvoiceRowsType>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord,
    onSelectRow: setSelectedIds
  });
  return (
    <DynamicVirtualTable
      id="invoices-table"
      tooltipId="invoices-table-tip"
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      rows={rows}
      loading={loading}
      error={error}
      rowHeight={40}
      NoDataOverlay={NoInvoicesOverlay}
      noSelect
      sortableFields={sortableFields}
      sort={handleSort}
      sortBy={sortBy}
      singleSort
      sortDirection={sortDirection}
      totalAmountOfRows={rows.length}
      columns={columns}
    />
  );
}
