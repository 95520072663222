import useBrowserTitle from 'app-context/hooks/use-browser-title';
import useCurrent from 'app-context/hooks/use-current';
import { moveInType } from 'app-context/stores/master-data/contracts/wizard/move-in/MoveInWizardBaseStore';
import WizardContainer from 'components/Wizard/WizardContainer';
import { WizardNav } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { Wizard } from '@zf/stella-react/src/atoms/Wizard';

import AddLocationsContractWizard from './add-locations-move-in-wizard/AddLocationsContractWizard';
import AddLocationsMoveInWizardNavigation from './add-locations-move-in-wizard/AddLocationsMoveInWizardNavigation';
import RegularMoveInWizard from './regular-move-in-wizard/RegularMoveInWizard';
import RegularMoveInWizardNavigation from './regular-move-in-wizard/RegularMoveInWizardNavigation';
import MoveInSection from './shared/move-in-section/MoveInSection';

type Props = {
  urlParams: Record<string, string>;
};

const MoveInWizardBase = (props: Props) => {
  const { urlParams = { customerId: '' } } = props;
  const { applicationStore, contractStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { moveInType: moveInType_, resetStore } = contractStore.moveInWizardBaseStore;

  useBrowserTitle(getTranslation('actions.create_contract'));

  const { resetCurrent } = useCurrent('location');

  const isNewContract = moveInType_ === moveInType.newcontract;

  useEffect(() => {
    return () => {
      // Reset MobX wizard store
      resetStore();
      // Reset location listpage state
      resetCurrent();
    };
  }, []);

  return (
    <WizardContainer
      icon="contract-add"
      text={getTranslation('wizard.breadcrumb', {
        subject: getEnumTranslation('entitySubjectType', entitySubjectType.contract)
      })}
      isDesignSystem
    >
      <Wizard>
        <MoveInSection />

        {isNewContract ? (
          <RegularMoveInWizard {...props} customerId={urlParams.customerId} />
        ) : (
          <AddLocationsContractWizard />
        )}
      </Wizard>

      <WizardNav>
        {isNewContract ? <RegularMoveInWizardNavigation /> : <AddLocationsMoveInWizardNavigation />}
      </WizardNav>
    </WizardContainer>
  );
};

export default observer(MoveInWizardBase);
