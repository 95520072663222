import { action, makeObservable, observable } from 'mobx';

export default class WizardValues<V> {
  public values: V; // These are the current wizard values when filling in the forms
  public backup: V; // This can be any intermediate state when filling in the wizard inputs and submitting fields before the "final submit"
  public initialValues: V; // These are the values on wizard creation

  constructor(initialValues: V) {
    this.values = initialValues;
    this.backup = initialValues;
    this.initialValues = initialValues;

    makeObservable(this, {
      values: observable,
      backup: observable,
      initialValues: observable,

      backupValues: action,
      setValue: action,
      reset: action
    });
  }

  backupValues = () => {
    this.backup = { ...this.values };
  };

  setValue = (val: Partial<V>, backupValues?: boolean) => {
    // Don't use spread as we dont want a new object, component should only listen to attribute changes
    Object.keys(val).forEach((key) => {
      const castedKey = key as keyof V;
      this.values[castedKey] = val[castedKey] as V[keyof V];
    });

    if (backupValues) {
      this.backupValues();
    }
  };

  reset = () => {
    this.values = { ...this.initialValues };
  };
}
