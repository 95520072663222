import clone from 'clone';
import { MultiValue } from 'design-system/ComponentSets';
import React from 'react';

import { TieredPercentageCollectionChargeParametersType, TierType } from '@zf/api-types/collection-cycles';

import { useAppContext } from '../../../../../../app-context/app-context';
import Tier from './tier';
import css from './tiers-multivalue.module.scss';

type Props = {
  chargeParams: TieredPercentageCollectionChargeParametersType;
  setTieredChargeParameters: (parameterArray: TierType[]) => void;
};

const initialValues: TierType = {
  from: 0,
  minAmount: 0,
  maxAmount: 0,
  percentage: 0
};

export default function TiersMultivalue(props: Props) {
  const { setTieredChargeParameters } = props;
  let { chargeParams } = props;
  const { i18n } = useAppContext();

  const initiallyNull = !chargeParams || (chargeParams.tiers && chargeParams.tiers.length === 0);

  if (initiallyNull) {
    chargeParams = {
      type: 'TieredPercentageCollectionChargeParametersRequestDTO',
      taxCodeId: '',
      tiers: [clone(initialValues)]
    };
  }

  return (
    <MultiValue
      id="add-step-multi-value"
      className={css['tiers-multivalue']}
      title={i18n.getTranslation('collection_flows.add_tiers')}
      initialValues={!initiallyNull ? chargeParams.tiers : [clone(initialValues)]}
      intialNodeValue={clone(initialValues)}
      onChange={setTieredChargeParameters}
      withBorder
    >
      {({ index, value, dispatchValue }) => {
        return <Tier {...props} index={index} key={`tier-${index}`} value={value} dispatchValue={dispatchValue} />;
      }}
    </MultiValue>
  );
}
