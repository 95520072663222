import moment from 'moment';
import React from 'react';

import {
  ConsumptionAskingPeriodType,
  ConsumptionReceivingPeriodType,
  MeasurementDetailsType
} from '@zf/api-types/billing/billing-completeness';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatDateWMonth } from '@zf/utils/src/date';
import { formatDecimal } from '@zf/utils/src/number';

import IconParagraph from '../../../../../../../components/Icon/IconParagraph';
import { Info } from '../../../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../../../hooks/useStore';
import AddMissingMeasurementButton from './AddMissingMeasurementButton';
import css from './meter-completeness.module.scss';

type Props = {
  apIndex: number;
  locationId: string;
  askingPeriod: ConsumptionAskingPeriodType;
  isComplete: boolean;
  crpIndex?: number;
  correspondingReceivingPeriod?: ConsumptionReceivingPeriodType;
};

export default function MeterCompleteness(props: Props) {
  const { apIndex, crpIndex, isComplete, locationId, askingPeriod, correspondingReceivingPeriod } = props;

  const { applicationStore, contractStore } = useStore();
  const { culture, getEnumTranslation, getTranslation } = applicationStore;
  const { getMatchingMeasurement, deriveMeterSpecs } = contractStore.billingCompletenessStore;

  let msmtForAskingStart: MeasurementDetailsType | null = null;
  let msmtForAskingEnd: MeasurementDetailsType | null = null;

  msmtForAskingStart = getMatchingMeasurement('start', askingPeriod, correspondingReceivingPeriod);
  msmtForAskingEnd = getMatchingMeasurement('end', askingPeriod, correspondingReceivingPeriod);

  const askingStartIsMissing = !msmtForAskingStart && askingPeriod.requiresMeasurementOnStart && !isComplete;
  const askingEndIsMissing = !msmtForAskingEnd && askingPeriod.requiresMeasurementOnEnd && !isComplete;

  const hasMissingMeasurement = askingStartIsMissing || askingEndIsMissing;

  const optionalStartMissing = !msmtForAskingStart && !askingPeriod.requiresMeasurementOnStart;
  const optionalEndMissing = !msmtForAskingEnd && !askingPeriod.requiresMeasurementOnEnd;

  const { meterSerialNr, externalChannelIdentifier } = deriveMeterSpecs(askingPeriod, correspondingReceivingPeriod);

  return (
    <div className={css['meter-completeness']}>
      <IconParagraph iconType="meter" color={hasMissingMeasurement ? colors['red-600'] : undefined}>
        {meterSerialNr}
      </IconParagraph>

      <div className={css['dates-and-msmts']}>
        <div className={css['date-msmt']}>
          {(!(!msmtForAskingStart && !askingStartIsMissing) || optionalStartMissing) && (
            <Paragraph color={askingStartIsMissing ? colors['red-600'] : undefined}>
              {`${formatDateWMonth(askingPeriod.startDateTime)}:`}
            </Paragraph>
          )}

          {msmtForAskingStart && (
            <Paragraph color={colors['green-600']}>{`${formatDateWMonth(
              msmtForAskingStart.endDateTime
            )} (${formatDecimal(msmtForAskingStart.value, culture)} ${getEnumTranslation(
              'unitOfMeasure',
              msmtForAskingStart.unitOfMeasure
            )})`}</Paragraph>
          )}

          {askingStartIsMissing && (
            <AddMissingMeasurementButton
              id={`missing-start-${apIndex}-${crpIndex}`}
              locationId={locationId}
              meterSerialNr={meterSerialNr}
              channelId={externalChannelIdentifier}
              measurementDate={askingPeriod.startDateTime}
            />
          )}

          {optionalStartMissing && (
            <AddMissingMeasurementButton
              id={`optional-start-${apIndex}-${crpIndex}`}
              locationId={locationId}
              meterSerialNr={meterSerialNr}
              channelId={externalChannelIdentifier}
              measurementDate={askingPeriod.startDateTime}
              isOptional
            />
          )}
        </div>

        <div className={css['date-msmt']}>
          {(!(!msmtForAskingEnd && !askingEndIsMissing) || optionalEndMissing) && (
            <Paragraph color={askingEndIsMissing ? colors['red-600'] : undefined}>
              {`${formatDateWMonth(askingPeriod.endDateTime)}:`}
            </Paragraph>
          )}

          {msmtForAskingEnd && (
            <Paragraph color={colors['green-600']}>{`${formatDateWMonth(msmtForAskingEnd.endDateTime)} (${formatDecimal(
              msmtForAskingEnd.value,
              culture
            )} ${getEnumTranslation('unitOfMeasure', msmtForAskingEnd.unitOfMeasure)})`}</Paragraph>
          )}

          {askingEndIsMissing && (
            <AddMissingMeasurementButton
              id={`missing-end-${apIndex}-${crpIndex}`}
              locationId={locationId}
              meterSerialNr={meterSerialNr}
              channelId={externalChannelIdentifier}
              measurementDate={askingPeriod.endDateTime}
            />
          )}

          {optionalEndMissing && (
            <AddMissingMeasurementButton
              id={`optionel-end-${apIndex}-${crpIndex}`}
              locationId={locationId}
              meterSerialNr={meterSerialNr}
              channelId={externalChannelIdentifier}
              measurementDate={askingPeriod.endDateTime}
              isOptional
            />
          )}
        </div>

        {askingPeriod.allowDeviatingEnd &&
          correspondingReceivingPeriod?.latestMeasurement &&
          moment(correspondingReceivingPeriod.latestMeasurement.endDateTime).isBetween(
            askingPeriod.minEndDateTime,
            askingPeriod.maxEndDateTime,
            undefined,
            '[]'
          ) && <Info>{getTranslation('invoice.info_deviating')}</Info>}
      </div>
    </div>
  );
}
