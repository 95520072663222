import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import AddPaymentDialog from '../../../actions/payments/add-payment-dialog';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  onComplete: () => void;
};

export default function AddPaymentToCustomerListItem(props: Props) {
  const { onComplete } = props;
  const { applicationStore, customerStore } = useStore();
  const { pageActionPermissions, selectedCustomer } = customerStore;
  const { clickRef, validationRef, onSubmit } = useDialog();

  if (!pageActionPermissions.mayAddPayment) return null;

  const customer = selectedCustomer.customer;
  const { getTranslation } = applicationStore;

  return (
    <DropdownAction
      id="payment.add"
      icon="ajust-balance"
      dialogTitle={getTranslation('actions.add_payment')}
      dialogContent={
        <AddPaymentDialog onComplete={onComplete} ref={clickRef} customer={customer} validationRef={validationRef} />
      }
      onSubmit={onSubmit}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={getTranslation('actions.adjust')}
    />
  );
}
