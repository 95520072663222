import React from 'react';

import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';
import { useStore } from '../../../hooks/useStore';

export default function useCountrySpecificTabs() {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [tabItems] = React.useState<TabItemType[]>([
    {
      id: 'social-tariffs',
      title: getTranslation('country_specifics.social_tariff')
    }
  ]);

  return tabItems;
}
