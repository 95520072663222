import React from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { useAppContext } from '../../../app-context';
import useCultureTable from '../../../app-context/hooks/use-culture-table';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import CustomerWizard from './CustomerWizard';

export type Props = {
  feedback: string[][];
  setNewCustomer?: (customer: CustomerType) => void;
  onFocusStep: React.Dispatch<React.SetStateAction<string>>;
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>;
};

export default function OrgAndScenRespInterface(props: Props) {
  const { tenantReducer } = useAppContext();

  const { organization } = tenantReducer.state;

  const { cultureTable } = useCultureTable();

  const responseOrg = useSuspenseSingleAPI<OrganizationConfigType>({
    request: {
      endpoint: '/cfg/Organizations/',
      selector: organization?.organizationId
    }
  });

  if (!cultureTable || !responseOrg.result) return null;

  const orgConfig = responseOrg.result.data;

  return <CustomerWizard {...props} orgConfig={orgConfig} cultureTable={cultureTable} />;
}
