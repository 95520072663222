import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { entitySubjectType } from '@zf/api-types/enums';
import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import useCustomEntityPropertyTypes from '../../../app-context/hooks/useCustomEntityPropertyTypes';
import ListPage from '../../../components/listPage';
import Embedded from '../../../components/listPage/Embedded';
import { useStore } from '../../../hooks/useStore';
import CustomersFilterBar from './customers-filter-bar';
import CustomersList from './customers-list';
import CustomersListInGroup from '../customer-group/list-page/CustomersListInGroup';
import { DialogClickRef } from 'design-system/ComponentSets/Dialog/Dialog';
import { addCustomersToGroupBulk, addCustomersToGroupSingle } from './AddCustomersToGroup.API';
import { useAppContext } from 'app-context';
import { notify } from 'events/notification-events';
import css from './customers.module.scss';
import { InfoBanner } from 'design-system/ComponentSets';
import Interweave from 'interweave';

type Props = {
  location: Location;
  withListPage?: boolean;
  withActions?: boolean;
  queryParam: { customerGroupId: string; timeStamp: string };
  onComplete: Function;
  timeStamp?: string;
};

export default forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { applicationStore } = useStore();
  const { withListPage = true, queryParam, withActions = true, onComplete } = props;

  const { getTranslation } = applicationStore;
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const { i18n, tenantReducer } = useAppContext();
  const [searchValue, setSearchChange] = useState('');
  async function batchAPI(arr: string[]) {
    let init = 0;

    if (queryParam?.customerGroupId)
      for (let i = 0; i < arr.length / 200; i++) {
        return await addCustomersToGroupBulk(
          arr.slice(init, (init += 200)),
          queryParam?.customerGroupId,
          tenantReducer,
          i18n.lang
        );
      }
  }

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        let response;
        if (onComplete && queryParam?.customerGroupId) {
          if (selectedIds.length === 1) {
            response = await addCustomersToGroupSingle(
              queryParam?.customerGroupId,
              selectedIds[0],
              tenantReducer,
              i18n.lang
            );
          } else {
            response = await batchAPI(selectedIds);
          }
          onComplete();
        }
        if (response) {
          notify.success({
            content: getTranslation('customer_groups.add_customers_to_group_success')
          });
        }
      } catch (e) {
        notify.error({
          content: getTranslation('customer_groups.add_customers_to_group_fail'),
          error: e
        });
      }
    }
  }));

  const setSelectedCount = (ids: Array<string>) => setSelectedIds(ids);

  useBrowserTitle(
    typeof queryParam !== 'undefined' && queryParam.hasOwnProperty('customerGroupId') && queryParam.customerGroupId
      ? getTranslation('customer_groups.customer_group')
      : getTranslation('customer.customers')
  );

  const { customEntityPropertyTypes, extraColumns, extraColumnTitles } = useCustomEntityPropertyTypes(
    entitySubjectType.customer
  );

  const onSearchChange = (searchValue: string) => {
    setSearchChange(searchValue);
  };

  if (!customEntityPropertyTypes) return null;

  return (
    <>
      {withListPage && withActions && (
        <ListPage
          entitySubjectType={entitySubjectType.customer}
          subjectType={getTranslation('customer.customers')}
          topBar={<CustomersFilterBar search={props.location.search} extraColumnTitles={extraColumnTitles} />}
          list={
            <CustomersList
              customEntityPropertyTypes={customEntityPropertyTypes}
              extraColumns={extraColumns}
              queryParams={{ customerGroupId: '' }}
            />
          }
        />
      )}

      {/**
       * @description add customers to customer group
       */}
      {withListPage && !withActions && (
        <div className={css['listpage-wrapper']}>
          <ListPage
            entitySubjectType={entitySubjectType.customer}
            subjectType={getTranslation('customer.customers')}
            topBar={
              <CustomersFilterBar
                search={props.location.search}
                extraColumnTitles={extraColumnTitles}
                withActions={withActions}
                subject={getTranslation('customer.customers')}
              />
            }
            list={
              <CustomersList
                customEntityPropertyTypes={customEntityPropertyTypes}
                extraColumns={extraColumns}
                setSelectedCount={setSelectedCount}
                queryParams={{ customerGroupId: '' }}
                isEmbedded
              />
            }
            isEmbedded
            infoGrid={
              <>
                {selectedIds.length === 1 && (
                  <InfoBanner
                    color="blue"
                    info={getTranslation('customer_groups.customer_this_action_might_change_contract_settings')}
                    quickTipId="customer_groups.removed.tooltip"
                    quickTipHTML={
                      <Interweave
                        content={getTranslation(
                          'customer_groups.customer_this_action_might_change_contract_settings_tip'
                        )}
                      />
                    }
                  />
                )}

                {selectedIds.length > 1 && (
                  <InfoBanner
                    color="blue"
                    info={getTranslation('customer_groups.customers_this_action_might_change_contract_settings')}
                    quickTipId="customer_groups.removed.tooltip"
                    quickTipHTML={
                      <Interweave
                        content={getTranslation(
                          'customer_groups.customers_this_action_might_change_contract_settings_tip'
                        )}
                      />
                    }
                  />
                )}
              </>
            }
          />
        </div>
      )}

      {withListPage === false && queryParam?.customerGroupId && (
        <Embedded
          entitySubjectType={entitySubjectType.customer}
          subjectType={getTranslation('customer.customers')}
          list={
            <CustomersListInGroup
              setSelectedCount={setSelectedCount}
              queryParam={{
                customerGroupId: queryParam?.customerGroupId,
                searchValue,
                timeStamp: queryParam?.timeStamp
              }}
            />
          }
          selectedIds={selectedIds}
          onSearchChange={onSearchChange}
          queryParams={{
            customerGroupId: queryParam.customerGroupId || '',
            searchValue
          }}
        />
      )}
    </>
  );
});
