import moment from 'moment';
import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';

import { OutputCtxState, useTracked } from '../../../context/output-context';
import useQueryParams from '../../../shared-hooks/useQueryParams';

export default function useRegularGraphFilters() {
  const [state, dispatch] = useTracked();

  const { startDateTime, endDateTime, groupByPeriod, setDates } = useQueryParams();

  const setState = (newState: Partial<OutputCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const setSelectedGraphChannel = (channId: string) => {
    if (state.selectedGraphChannels.includes(channId)) {
      const newSelection = state.selectedGraphChannels.filter((id) => {
        return id !== channId;
      });

      setState({ selectedGraphChannels: newSelection });
    } else {
      setState({ selectedGraphChannels: [...state.selectedGraphChannels, channId] });
    }
  };

  const setYears = (newYears: number[]) => {
    setDates([moment([newYears[0]]).startOf('year'), moment([newYears[1]]).endOf('year')]);
  };

  React.useEffect(() => {
    // When switching from none to grouped, round the dates on year
    if (groupByPeriod !== aggregationFrequency.none && groupByPeriod !== aggregationFrequency.daily) {
      setDates([moment(startDateTime).startOf('year'), moment(endDateTime).endOf('year')]);
    }

    // When not none, untick measurements
    if (groupByPeriod !== aggregationFrequency.none) {
      setState({ graphHeaderSelection: ['consumptions'] });
    }
  }, [groupByPeriod]);

  return {
    measurementChannels: state.channels,
    consumptionsChannels: state.consumptionChannels,
    setSelectedGraphChannel,
    setYears
  };
}
