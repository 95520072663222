import React, { useEffect, useState } from 'react';

import { countryCode } from '@zf/api-types/enums';
import { PrepaymentParametersType } from '@zf/api-types/parameters';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';

import FloatInput from '../../../components/input/FloatInput';
import MoneyInput from '../../../components/input/MoneyInput';
import { CardBody, SettingDescription } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import css from './parameters.module.scss';

type Props = {
  value: PrepaymentParametersType;
  setValue: (value: Partial<PrepaymentParametersType>) => void;
};

const PrePaymentBillingParameters = (props: Props) => {
  const { setValue, value } = props;

  const { applicationStore, organisationStore } = useStore();
  const { tenantReducer, getTranslation } = applicationStore;
  const { getOrganisationConfig } = organisationStore.organisationService;

  const [organisationCfg, setOrganisationCfg] = useState<OrganizationConfigType | null>();

  useEffect(() => {
    getOrganisationConfig(tenantReducer.organization?.organizationId || '', tenantReducer.tenant?.id || '').then(
      (res) => setOrganisationCfg(res)
    );
  }, []);

  if (!organisationCfg || organisationCfg?.address?.country !== countryCode.gbr) return null;

  return (
    <Card id="prepayment-billing-card" width="2">
      <CardHeader>{getTranslation('prepayment_config.prepaid_billing')}</CardHeader>
      <CardBody>
        <SettingDescription
          className={css['prepaid-billing-setting']}
          title={getTranslation('prepayment_device.emergency_credit')}
          description={getTranslation('prepayment_config.emergency_credit_info')}
          setting={
            <MoneyInput
              id="prepaid-emergency-credit"
              onChange={(val) => setValue({ emergencyCredit: val })}
              value={value.emergencyCredit}
              error={!value.emergencyCredit}
            />
          }
        />
        <div className={css['prepaid-billing-setting']}>
          <SettingDescription
            title={getTranslation('prepayment_config.deduction')}
            description={getTranslation('prepayment_config.deduction_info')}
            setting={
              <ZFToggle onChange={(val) => setValue({ enableDeduction: val })} checked={value.enableDeduction} />
            }
          />
          <SettingDescription
            title={getTranslation('prepayment_config.deduction_rate')}
            description={getTranslation('prepayment_config.deduction_rate_info')}
            setting={
              <FloatInput
                id="prepaid-deduction-rate"
                value={value.deductionRate}
                onChange={(val) => setValue({ deductionRate: val })}
                error={!value.deductionRate}
                postfix="%"
              />
            }
            indentLvl={1}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PrePaymentBillingParameters;
