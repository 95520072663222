import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { BillingParametersType } from '@zf/api-types/parameters';
import { Wizard, WizardNav } from '@zf/stella-react/src/atoms/Wizard';
import { WizardNavStep } from '@zf/stella-react/src/atoms/Wizard/WizardNav';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import WizardContainer from '../../../../components/Wizard/WizardContainer';
import { METHODS } from '../../../../utils/request';
import InvoiceWizard, { STEP_NAMES } from './InvoiceWizard';

type Props = {
  urlParams: { [key: string]: string };
};

const AddInvoice = (props: Props) => {
  const { urlParams = { customerId: '' } } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation, sendRequest } = applicationStore;

  useBrowserTitle(getTranslation('invoice.create_invoice'));

  const bankAccountRequired = React.useRef(true);

  const [focusedStep, setFocusedStep] = React.useState(STEP_NAMES[0]);
  const [feedback, setFeedback] = React.useState<string[][]>([]);
  const [isContractChecked, setIsContractChecked] = React.useState<boolean>(true);
  const [customer, setCustomer] = React.useState<CustomerType | null>(null);

  if (urlParams.customerId && customer === null) {
    sendRequest<CustomerType>({
      request: {
        method: METHODS.GET,
        endpoint: `/md/customers/${urlParams.customerId}`
      }
    }).then((result) => {
      if (result.data) setCustomer(result.data);
    });
  }

  React.useEffect(() => {
    sendRequest<BillingParametersType>({
      request: {
        method: METHODS.GET,
        endpoint: '/cfg/Parameters/billing'
      }
    }).then((result) => {
      if (result.data) {
        bankAccountRequired.current = !result.data.skipPayment;
      }
    });
  }, []);

  const steps: WizardNavStep[] = [
    {
      header: getTranslation('invoice.steps.select_customer'),
      name: STEP_NAMES[0],
      feedback: feedback[0]
    },
    {
      header: getTranslation('invoice.steps.invoice_contract'),
      name: STEP_NAMES[1],
      feedback: feedback[1]
    }
  ];

  const generateOtherSteps = () => {
    if (bankAccountRequired.current) {
      return [
        {
          header: getTranslation('invoice.steps.invoice_type'),
          name: STEP_NAMES[3],
          feedback: feedback[3]
        },
        {
          header: getTranslation('invoice.steps.period_description'),
          name: STEP_NAMES[4],
          feedback: feedback[4]
        },
        {
          header: getTranslation('invoice.steps.bank_account'),
          name: STEP_NAMES[5],
          feedback: feedback[5]
        },
        {
          header: getTranslation('invoice.steps.invoice_lines'),
          name: STEP_NAMES[6],
          feedback: feedback[6]
        },
        {
          header: getTranslation('errors.more_problems'),
          name: STEP_NAMES[7],
          feedback: feedback[7],
          error: true
        }
      ];
    } else {
      return [
        {
          header: getTranslation('invoice.steps.invoice_type'),
          name: STEP_NAMES[3],
          feedback: feedback[3]
        },
        {
          header: getTranslation('invoice.steps.period_description'),
          name: STEP_NAMES[4],
          feedback: feedback[4]
        },
        {
          header: getTranslation('invoice.steps.invoice_lines'),
          name: STEP_NAMES[6],
          feedback: feedback[6]
        },
        {
          header: getTranslation('errors.more_problems'),
          name: STEP_NAMES[7],
          feedback: feedback[7],
          error: true
        }
      ];
    }
  };

  if (customer && !isContractChecked) {
    steps.push(
      {
        header: getTranslation('invoice.steps.property_group'),
        name: STEP_NAMES[2],
        feedback: feedback[2]
      },
      ...generateOtherSteps()
    );
  } else {
    steps.push(...generateOtherSteps());
  }

  return (
    <WizardContainer
      icon="invoice-add"
      text={getTranslation('wizard.breadcrumb', {
        subject: getEnumTranslation('entitySubjectType', entitySubjectType.invoice)
      })}
    >
      <Wizard>
        <InvoiceWizard
          customer={customer}
          feedback={feedback}
          isContractChecked={isContractChecked}
          bankAccountRequired={bankAccountRequired.current}
          setIsContractChecked={setIsContractChecked}
          onFocusStep={setFocusedStep}
          setFeedback={setFeedback}
          setCustomer={setCustomer}
        />
      </Wizard>
      <WizardNav steps={steps} activeStep={focusedStep} onNavigate={setFocusedStep} />
    </WizardContainer>
  );
};

export default observer(AddInvoice);
