import { observer } from 'mobx-react';
import React from 'react';

import { invoiceStatus, sentStatus } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatDate, formatPeriod } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';
import { capitalize } from '@zf/utils/src/string';

import { ErrorBoundary } from '../../../../../../../components/Error';
import { DANGER } from '../../../../../../../constants/color';
import { Link } from '../../../../../../../design-system/Components';
import { useStore } from '../../../../../../../hooks/useStore';
import { isOverdue } from '../../../../../../../utils/invoice';
import EmptyRow from '../../../components/empty-row';
import QueriedInvoice from '../../../components/queried-invoice';
import css from './invoice-details-card-body.module.scss';

export type PropsType = {
  invoice: InvoiceType;
};

const InvoiceDetailsCardBody = (props: PropsType) => {
  const { invoice } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation, getEnumTranslation } = applicationStore;

  const overdue = isOverdue(invoice);

  return (
    <>
      <CardItem width="6">
        <Label>{getTranslation('general.type')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" dataTestId="general.type">
          {getEnumTranslation('invoiceType', invoice.type)}
        </Paragraph>
      </CardItem>

      <CardItem width="6">
        <Label>{getTranslation('invoice.date')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" dataTestId="invoice.date">
          {formatDate(invoice.invoiceDate)}
        </Paragraph>
      </CardItem>

      <CardItem width="6">
        <Label>{getTranslation('invoice.due_date')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" color={overdue ? DANGER : undefined} dataTestId="invoice.due_date">
          {formatDate(invoice.dueDate)}
        </Paragraph>
      </CardItem>

      <CardItem width="6">
        <Label>{getTranslation('general.period')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" dataTestId="general.period">
          {formatPeriod(invoice.periodStartDateTime, invoice.periodEndDateTime)}
        </Paragraph>
      </CardItem>

      <CardItem width="6">
        <Label>{getTranslation('invoice.external_ref')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" dataTestId="invoice.external_ref">
          {invoice.externalReference}
        </Paragraph>
      </CardItem>

      {invoice.status === invoiceStatus.generationfailed || invoice.sent === sentStatus.failed ? (
        <>
          <CardItem width="6">
            <Label>{getTranslation('invoice.failure_reason')}</Label>
          </CardItem>
          <CardItem width="6">
            <Paragraph textAlign="right" className={css['invoice-failure-reason']} dataTestId="invoice.failure_reason">
              {invoice.failureReasonCode?.message}
            </Paragraph>
          </CardItem>
        </>
      ) : null}

      <CardItem width="6">
        <Label>{getTranslation('invoice.sentstatus')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph
          dataTestId="invoice.sentstatus"
          textAlign="right"
          color={
            invoice.sent === sentStatus.failed
              ? colors['red-600']
              : invoice.sent === sentStatus.printing
              ? colors['orange-600']
              : ''
          }
        >
          {invoice.sent ? getEnumTranslation('sentStatus', invoice.sent) : ''}
        </Paragraph>
      </CardItem>

      {invoice.creditedByInvoiceId && (
        <ErrorBoundary cardError>
          <React.Suspense fallback={<EmptyRow label={getTranslation('invoice.credit_by')}></EmptyRow>}>
            <QueriedInvoice invoiceId={invoice.creditedByInvoiceId} label={getTranslation('invoice.credit_by')} />
          </React.Suspense>
        </ErrorBoundary>
      )}

      {invoice.creditedInvoiceId && (
        <ErrorBoundary cardError>
          <React.Suspense fallback={<EmptyRow label={getTranslation('invoice.credited')}></EmptyRow>}>
            <QueriedInvoice invoiceId={invoice.creditedInvoiceId} label={getTranslation('invoice.credited')} />
          </React.Suspense>
        </ErrorBoundary>
      )}

      {invoice.contractId && invoice.product && (
        <>
          <CardItem width="6">
            <Label>{getTranslation('invoice.product')}</Label>
          </CardItem>
          <CardItem width="6" justifyContent="end">
            <Link
              dataTestId="invoice.product"
              id={`product-${invoice.product.productName}`}
              url={`${applicationStore.rootUrl}/configuration/product?productName=${capitalize(
                invoice.product.productName
              )}`}
            >
              {capitalize(invoice.product.productName)}
            </Link>
          </CardItem>
        </>
      )}

      <CardItem width="6">
        <Label>{getTranslation('general.amount')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" dataTestId="general.amount">
          {formatMoney(invoice.totalAmountInclVAT, culture)}
        </Paragraph>
      </CardItem>

      <CardItem width="6">
        <Label>{getTranslation('invoice.remaining_amount')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" dataTestId="invoice.remaining_amount">
          {formatMoney(invoice.remainingInvoiceAmount, culture)}
        </Paragraph>
      </CardItem>
      <CardItem width="6">
        <Label>{getTranslation('invoice.export_status')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph textAlign="right" dataTestId="invoice.export_status">
          {getEnumTranslation('exportStatus', invoice.exportStatus)}{' '}
        </Paragraph>
      </CardItem>
    </>
  );
};

export default observer(InvoiceDetailsCardBody);
