import moment, { Moment } from 'moment';
import React from 'react';

import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { isMaxDate, isMinDate, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import DateRangePicker from '../../components/input/DateRangePicker';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';
import css from './add-billing-period-dialog.module.scss';

type Props = {
  propertyGroupId: string;
  billingPeriods: PropertyGroupBillingPeriodType[];
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  setBillingPeriods: (billingPeriods: PropertyGroupBillingPeriodType[]) => void;
};

type PeriodsState = {
  periodStart: Moment | null;
  periodEnd: Moment | null;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, propertyGroupId, billingPeriods, setBillingPeriods } = props;

  const initialStartDate = billingPeriods[billingPeriods.length - 1]
    ? billingPeriods[billingPeriods.length - 1].endDate
    : MIN_DATE;

  const stateReducer = createStateReducer<PeriodsState, Partial<PeriodsState>>();
  const [periodsState, setPeriodsState] = React.useReducer(stateReducer, {
    periodStart: moment(initialStartDate),
    periodEnd: null
  });

  const { i18n, tenantReducer } = useAppContext();

  const checkDates = () => {
    return (
      !periodsState.periodStart ||
      !periodsState.periodEnd ||
      isMinDate(periodsState.periodStart) ||
      isMaxDate(periodsState.periodEnd)
    );
  };

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(checkDates());
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(periodsState)]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newPeriod = (
          await sendRequest<PropertyGroupBillingPeriodType>({
            request: {
              method: METHODS.POST,
              endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}`,
              data: {
                startDateTime: periodsState.periodStart,
                endDateTime: periodsState.periodEnd
              }
            },
            tenantReducer,
            lang: i18n.lang
          })
        ).data;

        setBillingPeriods([...billingPeriods, newPeriod]);

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.add_billing_period_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.add_billing_period_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['property-groups-container']}>
      <Paragraph>{i18n.getTranslation('property_groups.tabs.billing.choose_start_end')}</Paragraph>
      <div className={css['inputs-wrapper']}>
        <InputContainer className={css['input-container']}>
          <DateRangePicker
            id="invoice-period"
            startDate={periodsState.periodStart}
            endDate={periodsState.periodEnd}
            setDates={(dates) => setPeriodsState({ periodStart: dates[0], periodEnd: dates[1] })}
            error={checkDates()}
          />
        </InputContainer>
      </div>
    </div>
  );
});
