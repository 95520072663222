import clone from 'clone';
import Interweave from 'interweave';
import React from 'react';

import { DefinedTranslatedFieldType } from '@zf/api-types/language';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';
import FieldTranslations from './field-translations';
import css from './update-translations-dialog.module.scss';

type Props = {
  index: number;
  entity: any;
  endPointName: string;
  onCompleteLangUpdate: (index: number, updated: any) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { index, entity, endPointName, onCompleteLangUpdate } = props;
  const { i18n, tenantReducer } = useAppContext();

  const [translatedFields, setTranslatedFields] = React.useState<DefinedTranslatedFieldType>(
    entity.translatedFields || {}
  );

  React.useImperativeHandle(ref, () => ({
    // API call update translations
    async onClick() {
      try {
        const apiFriendlyValues = clone(translatedFields);

        // Filter out empty string translations
        Object.keys(apiFriendlyValues).forEach((field) => {
          Object.keys(apiFriendlyValues[field]).forEach((lang) => {
            if (!apiFriendlyValues[field][lang]) {
              delete apiFriendlyValues[field][lang];
            }
          });
        });

        const newEntity = (
          await sendRequest<any>({
            request: {
              method: METHODS.POST,
              endpoint: `/cfg/${endPointName}/${entity.id}/translations`,
              data: { translatedFields: apiFriendlyValues }
            },
            tenantReducer,
            lang: i18n.lang
          })
        ).data;

        onCompleteLangUpdate(index, newEntity);

        notify.success({
          content: i18n.getTranslation('translated_fields.update_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('translated_fields.update_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['translations-grid']}>
      {entity.localisedFields.map((field: string, i: number) => {
        const headingText = i18n.getTranslation('translated_fields.translations_for', {
          field: i18n.getTranslation(`translated_fields.${field.toLowerCase()}`)
        });

        return (
          <React.Fragment key={`translations-block-${field}-${i}`}>
            <Heading headingType="h3">
              <Interweave content={headingText}></Interweave>
            </Heading>
            <FieldTranslations
              field={field}
              entity={entity}
              translatedFields={translatedFields}
              setTranslatedFields={setTranslatedFields}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
});
