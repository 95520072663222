import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { Wizard, WizardNav } from '@zf/stella-react/src/atoms/Wizard';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import WizardContainer from '../../../components/Wizard/WizardContainer';
import LocationWizard, { STEP_NAMES } from './LocationWizard';

const AddLocation = () => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  useBrowserTitle(getTranslation('location.add_location'));

  const [focusedStep, setFocusedStep] = React.useState(STEP_NAMES[0]);
  const [feedback, setFeedback] = React.useState<string[][]>([]);
  // used for choosing between existing or new meter
  const [createNewMeter, setCreateNewMeter] = React.useState(false);

  const meterSteps = createNewMeter
    ? [
        {
          header: getTranslation('meter.steps.essentials_location'),
          name: STEP_NAMES[4],
          feedback: feedback[12],
          subStep: true
        },
        {
          header: getTranslation('meter.steps.channels_location'),
          name: STEP_NAMES[5],
          feedback: feedback[13],
          subStep: true
        },
        {
          header: getTranslation('errors.more_problems'),
          name: STEP_NAMES[6],
          feedback: feedback[14],
          error: true,
          subStep: true
        }
      ]
    : [];

  return (
    <WizardContainer
      icon="servicelocation-add"
      text={getTranslation('wizard.breadcrumb', {
        subject: getEnumTranslation('entitySubjectType', entitySubjectType.servicelocation)
      })}
    >
      <Wizard>
        <LocationWizard
          onFocusStep={setFocusedStep}
          feedback={feedback}
          setFeedback={setFeedback}
          createNewMeter={createNewMeter}
          setCreateNewMeter={setCreateNewMeter}
        />
      </Wizard>
      <WizardNav
        steps={[
          {
            header: getTranslation('location.steps.address'),
            name: STEP_NAMES[0],
            feedback: feedback[0]
          },
          {
            header: getTranslation('location.steps.default_config'),
            name: STEP_NAMES[1],
            feedback: feedback[1]
          },
          {
            header: getTranslation('location.steps.services'),
            name: STEP_NAMES[2],
            feedback: feedback[2]
          },
          {
            header: getTranslation('location.steps.meters'),
            name: STEP_NAMES[3],
            feedback: feedback[3]
          },
          ...meterSteps,
          {
            header: getTranslation('errors.more_problems'),
            name: STEP_NAMES[7],
            feedback: feedback[7],
            error: true
          }
        ]}
        activeStep={focusedStep}
        onNavigate={setFocusedStep}
      />
    </WizardContainer>
  );
};

export default observer(AddLocation);
