import ListPageNew from 'components/listPageV2';
import InfoGridFooter from 'design-system/ComponentSets/InfoGridFooter/InfoGridFooter';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import { useStore } from '../../../../hooks/useStore';
import { IssuesContext, IssuesProvider } from '../Context/Context';
import IssuesFilterBar from './IssuesFilterBar';
import IssuesList from './IssuesList';
import IssueSidePanel from './side-panels/IssueSidePanel';

const IssuesListPage = () => {
  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { refetchOverviewCounts } = meterStore.meteringIssueStore;

  const subjectTitle = getTranslation('meter.issues');

  useBrowserTitle(subjectTitle);

  return (
    <IssuesProvider>
      <IssuesContext.Consumer>
        {({ store: { filter, listPage } }) => (
          <ListPageNew
            entitySubjectType={entitySubjectType.meteringissue}
            topBar={<IssuesFilterBar />}
            list={<IssuesList isGrouped={filter.queryParams.groupBy?.[0] === 'meter'} />}
            infoGrid={
              <InfoGridFooter showSidePanel={listPage.showSidePanel} aggregateDetails={listPage.aggregateDetails} />
            }
            sidePanel={
              <IssueSidePanel isGrouped={filter.queryParams.groupBy?.[0] === 'meter'} refresh={refetchOverviewCounts} />
            }
            context={IssuesContext}
          />
        )}
      </IssuesContext.Consumer>
    </IssuesProvider>
  );
};

export default observer(IssuesListPage);
