import { makeObservable } from 'mobx';

import RootStore from '../../../app-context/stores';
import ImportJobService from '../services/ImportJobService';

export default class ImportJobStore {
  public rootStore: RootStore;
  public importJobService: ImportJobService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.importJobService = new ImportJobService(this, rootStore.applicationStore);

    makeObservable(this, {});
  }
}
