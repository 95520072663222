import classNames from 'classnames';
import clone from 'clone';
import { Moment } from 'moment';
import React, { useState } from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import Calendar from '../Calendar/calendar';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './date-picker.module.scss';

type Props = {
  culture: string;
  clearText?: string;
  value: Moment | null;
  disabled?: boolean;
  clear?: boolean;
  renderTopLvl: boolean;
  handleClear: () => void;
  onChange: (value: Moment) => void;
};

export default function DatePickerTooltip(props: Props) {
  const { clear, clearText, disabled, culture, value, renderTopLvl, handleClear, onChange } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCalendarChange = (selectedDates: Moment[]) => {
    if (selectedDates.length > 0) {
      onChange(clone(selectedDates[0]));
      setShowTooltip(false);
    }
  };

  return (
    <TooltipTrigger
      tooltip={
        <div className={css['calendar-wrapper']}>
          <Calendar
            culture={culture}
            selectedDates={value ? [value] : undefined}
            onSelect={handleCalendarChange}
            initFocusedDate={value ? clone(value) : undefined}
          />
          {clear && (
            <BaseButton id="clear-date" className={css['picker-clear']} onClick={handleClear}>
              <Paragraph>{clearText}</Paragraph>
            </BaseButton>
          )}
        </div>
      }
      trigger="click"
      placement="bottom-end"
      disabled={disabled}
      renderTopLvl={renderTopLvl}
      showTooltip={showTooltip}
      setShowTooltip={setShowTooltip}
    >
      <Icon name="date" className={classNames({ [css['date-picker']]: !disabled })} />
    </TooltipTrigger>
  );
}
