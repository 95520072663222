import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { propertyGroupType } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { NavigatorType } from '../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../components/RouteWrapper';
import GenericTabContainer from '../../../components/Tab/GenericTabContainer';
import { attachmentType } from '../../../components/units/EntityAttachments/logic/FilesStore';
import { useStore } from '../../../hooks/useStore';
import { formatAddress } from '../../../utils/address';
import Route from '../../route';
import BillingTabWrapper from './billing/billing-tab-wrapper';
import PropertyDetail from './detail/property-group-detail';
import PropertyGroupAttachments from './files/PropertyGroupAttachments';
import MeteringTab from './metering/metering-tab-wrapper';
import PropertyGroupActions from './PropertyGroupActions';
import SettingsTab from './settings/SettingsTab';

type Props = {
  propertyGroup: PropertyGroupType;
  navigator: NavigatorType;
  navigate: (url: string) => void;
  setPropertyGroup: React.Dispatch<React.SetStateAction<PropertyGroupType | null>>;
  refresh: () => void;
};

const PropertyGroupTabContainer = (props: Props) => {
  const { propertyGroup, navigator, navigate, setPropertyGroup, refresh: refreshProperty } = props;
  const { timeStamp, refresh } = useRefresh();

  const { applicationStore, propertyGroupStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { onLeaveDetailPage, filesStore } = propertyGroupStore;
  const { initFiles } = filesStore;

  const [tabItems] = useState([
    {
      id: 'detail',
      title: getTranslation('nav_detail.detail'),
      isDefault: true
    },
    {
      id: 'metering_overview',
      title: getTranslation('nav_detail.metering')
    },
    {
      id: 'billing_overview',
      title: getTranslation('nav_detail.billing')
    },
    {
      id: 'files',
      title: getTranslation('attachment.files')
    },
    {
      id: 'settings',
      title: getTranslation('nav.settings')
    }
  ]);

  useBrowserTitle(
    `${getTranslation('property_groups.property_group')} - ${propertyGroup.name} - ${formatAddress(
      propertyGroup.address,
      getEnumTranslation
    )}`
  );

  useEffect(() => {
    initFiles(propertyGroup.id, attachmentType.entityattachmentgroups, undefined, {
      entitySubjectDisplayName: propertyGroup.name
    });
    return () => onLeaveDetailPage();
  }, []);

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  return (
    <GenericTabContainer
      actions={<PropertyGroupActions propertyGroup={propertyGroup} refresh={refresh} />}
      entityId={propertyGroup.name}
      entityType={routedEntitySubjectType.propertygroup}
      navigate={navigate}
      navigator={navigator}
      iconName={propertyGroup.type === propertyGroupType.building ? 'building' : 'project'}
      tabItems={tabItems}
      routes={
        <RouteWrapper default>
          <Route
            Component={PropertyDetail}
            path="detail"
            timeStamp={timeStamp}
            propertyGroup={propertyGroup}
            setActiveTab={setActiveTab}
            setPropertyGroup={setPropertyGroup}
            default
          />
          <Route Component={MeteringTab} path="metering_overview" propertyGroup={propertyGroup} timeStamp={timeStamp} />
          <Route Component={BillingTabWrapper} path="billing_overview" propertyGroup={propertyGroup} />
          <Route Component={PropertyGroupAttachments} path="files" entityId={propertyGroup.id} />
          <Route
            Component={SettingsTab}
            path="settings"
            propertyGroup={propertyGroup}
            refreshProperty={refreshProperty}
          />
        </RouteWrapper>
      }
    />
  );
};

export default observer(PropertyGroupTabContainer);
