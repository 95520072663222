import moment from 'moment';

import {
  CreatePaymentType,
  DeletePaymentType,
  LinkPaymentType,
  PaymentType,
  ProcessPaymentType,
  RevertPaymentType
} from '@zf/api-types/payments';
import { TransactionType } from '@zf/api-types/transactions';
import { startOfDay } from '@zf/utils/src/date';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { createHeader, METHODS, sendRequest } from '../../utils/request';
import { uiCulture } from '@zf/api-types/enums';

export async function createPayment(
  value: CreatePaymentType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<PaymentType> {
  return (
    await sendRequest<PaymentType>({
      request: {
        method: METHODS.POST,
        endpoint: '/bill/payments',
        data: value
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function processPayment(
  value: ProcessPaymentType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<PaymentType> {
  return (
    await sendRequest<PaymentType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/payments/${value.id}/process`
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function revertPayment(
  value: RevertPaymentType,
  reversalAdditionalInformation: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<PaymentType> {
  return (
    await sendRequest<PaymentType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/payments/${value.id}/reverse`,
        data: {
          reversalAdditionalInformation: reversalAdditionalInformation
        }
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function linkManualReference(
  value: LinkPaymentType,
  customerId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<PaymentType> {
  return (
    await sendRequest<PaymentType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/payments/${value.id}/linkmanualreference`,
        data: { customerId, manualReferences: value.manualReferences }
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function settleTransaction(
  transaction: TransactionType,
  settleWithTransactions: Array<TransactionType>,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<Array<TransactionType>> {
  return (
    await sendRequest<Array<TransactionType>>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/transactions/${transaction.id}/settle`,
        data: { transactionIds: settleWithTransactions.map((t) => t.id) }
      },
      customHeaders: createHeader({
        'If-Match': transaction._etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function settleSingleTransaction(
  selectedTransaction: TransactionType,
  settlementTransactionId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<Array<TransactionType>> {
  return (
    await sendRequest<Array<TransactionType>>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/transactions/${selectedTransaction.id}/settle/${settlementTransactionId}`
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function undoTransaction(transaction: TransactionType, tenantReducer: TenantReturnValue, lang: uiCulture) {
  return (
    await sendRequest<TransactionType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/transactions/${transaction.id}/undo`
      },
      customHeaders: createHeader({
        'If-Match': transaction._etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}
export async function undoSingleTransaction(
  selectedTransaction: TransactionType,
  settlementTransactionId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<TransactionType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/transactions/${selectedTransaction.id}/undo/${settlementTransactionId}`
      },
      customHeaders: createHeader({
        'If-Match': selectedTransaction._etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function undoTransactionVoid(
  transaction: TransactionType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  await sendRequest<TransactionType>({
    request: {
      method: METHODS.POST,
      endpoint: `/bill/transactions/${transaction.id}/undo`
    },
    customHeaders: createHeader({
      'If-Match': transaction._etag
    }),
    tenantReducer,
    lang
  });
}

export async function refundTransaction(
  transaction: TransactionType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture,
  message: string,
  iban: string,
  daysDelayed: number
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/transactions/${transaction.id}/refund`,
        data: {
          refundDateTime: startOfDay(moment().add(daysDelayed, 'days')).toISOString(),
          remittanceMessage: message,
          iban: iban
        }
      },
      customHeaders: createHeader({
        'If-Match': transaction._etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function deletePayment(
  value: DeletePaymentType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/bill/payments/${value.id}`
      },
      tenantReducer,
      lang
    })
  ).data;
}
