import React from 'react';

import { navigate } from '@reach/router';
import { ServiceLocationRowType } from '@zf/api-types/master-data/servicelocation';

import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../design-system/ComponentSets';

export default function InstallMeterListPageButton() {
  const { i18n, tenantReducer } = useAppContext();
  const { tenant, organization } = tenantReducer.state;
  const { myCurrent } = useCurrent('location');

  const selectedRows: ServiceLocationRowType[] = myCurrent.listPage.selectedRows;

  let disabled = true;

  if (selectedRows[0]) disabled = selectedRows.length > 1;

  const rootUrl = `/${tenant?.name}/${organization?.shortCode}`;

  return (
    <DropdownAction
      id="meter.install"
      icon="meter"
      actionType="direct"
      disabled={disabled}
      onClick={() => navigate(`${rootUrl}/devices/install/forLocation/${selectedRows[0].__id}`)}
    >
      {i18n.getTranslation('actions.install_meter')}
    </DropdownAction>
  );
}
