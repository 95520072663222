import React from 'react';

import { Icon as StellaIcon } from '@zf/stella-react/src/atoms/Icon';
import StellaTableIcon from '@zf/stella-react/src/atoms/Icon/TableIcon';
import { generateColor } from '@zf/utils/src/color';

import { ICON_COLORS, ICONS } from '../../constants/icons';

type IconPropsType = {
  id?: string;
  type?: string;
  color?: boolean | string;
  style?: React.CSSProperties;
  className?: string;
  title?: string;
  usedInTable?: boolean;
  tooltipFor?: string;
};

export default function Icon(props: IconPropsType) {
  const {
    type = '',
    style = {},
    className,
    title,
    tooltipFor,
    color = false,
    usedInTable = false,
    ...otherProps
  } = props;

  const iconName = ICONS[type ? type.toLowerCase() : ''] || type;

  const styleObject: React.CSSProperties = React.useMemo(() => {
    const styleObj = {
      ...style
    };

    if (color) {
      if (typeof color !== 'string') {
        const col = ICON_COLORS[type ? type.toLowerCase() : ''] || generateColor(type);
        styleObj['color'] = col;
      } else {
        styleObj['color'] = color;
      }
    }

    return styleObj;
  }, [style, color, type]);

  return usedInTable ? (
    <StellaTableIcon
      name={iconName}
      style={styleObject}
      tooltipFor={tooltipFor}
      title={title}
      className={className}
      {...otherProps}
    />
  ) : (
    <StellaIcon name={iconName} style={styleObject} title={title} className={className} {...otherProps} />
  );
}
