import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { BillingItemType, BillingTariffRequestType, BillingTariffType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { colors } from '@zf/utils/src/color';

import ConfigButtons from '../../../../components/Button/ConfigButtons';
import ActivePeriod from '../../../../components/Date/ActivePeriod';
import IconParagraph from '../../../../components/Icon/IconParagraph';
import { Paragraph } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import css from './billing-tariff.module.scss';
import CalculationTypeParameters from './CalculationTypeParameters';
import AddEditTariffDialog from './dialogs/AddEditTariffDialog';
import DeleteTariffDialog from './dialogs/DeleteTariffDialog';

type Props = {
  index: number;
  tariff: BillingTariffType;
  tariffs: BillingTariffType[];
  selectedBillingItem: BillingItemType | undefined;
  isProductPage: boolean;
  showActionsOnDefault?: boolean;
  addTariff: (tariffToAdd: BillingTariffRequestType) => Promise<BillingTariffType>;
  addTariffToList: (newTariff: BillingTariffType) => void;
  updateTariff: (id: string, tariffToUpdate: BillingTariffRequestType) => Promise<BillingTariffType>;
  updateTariffInList: (updatedTariff: BillingTariffType) => void;
  setOnlyShowTariffs?: React.Dispatch<React.SetStateAction<boolean>>;
  deleteTariff: (tariffId: string) => Promise<void>;
  deleteTariffInlist: (index: number) => void;
  initTariffs?: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

const BillingTariff = (props: Props) => {
  const {
    tariff,
    tariffs,
    selectedBillingItem,
    showActionsOnDefault,
    index,
    isProductPage,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    deleteTariff,
    deleteTariffInlist,
    initTariffs
  } = props;

  const { validationRef } = useDialog();

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { isPanelViewMode, setOnlyShowTariffs } = productConfigStore;
  const { productsStore } = productConfigStore;
  const { selectedProduct } = productsStore;

  const isDefault = !tariff.productId;
  const isActive = moment().isBetween(tariff.startDateTime, tariff.endDateTime, 's', '[]');

  return (
    <div className={css['wrapper']}>
      <div className={css['flex-div']}>
        <ActivePeriod startDateTime={tariff.startDateTime} endDateTime={tariff.endDateTime} isActive={isActive} />

        {tariff.conditionParameters.length === 0 && (
          <div>
            <CalculationTypeParameters tariff={tariff} />
          </div>
        )}
      </div>

      {tariff.conditionParameters.length > 0 && (
        <IconParagraph iconType="condition" color={colors['silver-600']}>
          {getTranslation('billing_tariff.tariff_condition')}
        </IconParagraph>
      )}

      <HorizontalDivider />

      {isDefault && !showActionsOnDefault ? (
        <Paragraph className={css['default-tariff']}>{getTranslation('general.default')}</Paragraph>
      ) : (
        tariff.conditionParameters.length === 0 && (
          <ConfigButtons
            entityId={tariff.id}
            editTitle={getTranslation('general.edit')}
            editDialogPosition="right"
            editDialog={
              <AddEditTariffDialog
                validationRef={validationRef}
                tariff={tariff}
                tariffs={tariffs}
                selectedBillingItem={selectedBillingItem}
                isProductPage={isProductPage}
                addTariff={addTariff}
                updateTariff={updateTariff}
                addTariffToList={addTariffToList}
                updateTariffInList={updateTariffInList}
                initTariffs={initTariffs}
                canAddInstantly
              />
            }
            editExtraAction={() => {
              if (setOnlyShowTariffs && !isPanelViewMode)
                setOnlyShowTariffs(true, selectedBillingItem?.id || '', selectedProduct?.id || '');
            }}
            editCancel={() => {
              if (setOnlyShowTariffs)
                setOnlyShowTariffs(false, selectedBillingItem?.id || '', selectedProduct?.id || '');
            }}
            deleteTitle={getTranslation('billing_tariff.delete_tariff')}
            deleteDialog={
              <DeleteTariffDialog
                index={index}
                tariffId={tariff.id}
                deleteTariff={deleteTariff}
                deleteTariffInlist={deleteTariffInlist}
              />
            }
          />
        )
      )}
    </div>
  );
};

export default observer(BillingTariff);
