import { useEffect, useRef } from 'react';
import { getShownValues } from '../dropdown';

import { DropdownGenericBaseType, DropdownValuesType } from '../StellaDropdown';
import clone from 'clone';

type Props<T> = {
  id: string;
  values: DropdownValuesType<T>[];
  focusedIndex: number | undefined;
  searchValue: string | undefined;
  selectedValues: any[];
  selectSingleItemByDefault: boolean;
  onChange: (values: T[]) => void;
  setShowTooltip: (val: boolean) => void;
  setFocusedIndex: (val: number) => void;
  setSearchValue: (val: string) => void;
  isMultipleSelected?: boolean;
  idBased?: boolean;
};

export default function useDropdownFunctions<T>(props: Props<T>) {
  const {
    idBased,
    id,
    values,
    selectedValues,
    focusedIndex,
    searchValue,
    selectSingleItemByDefault,
    onChange,
    setShowTooltip,
    setFocusedIndex,
    setSearchValue,
    isMultipleSelected
  } = props;

  const selectedSingleItemByDefault = useRef(false);

  const onClickItem = (i: number | undefined) => {
    if (values && typeof i !== 'undefined') {
      document.getElementById(id)?.focus();
      if (isMultipleSelected) {
        if (selectedValues.includes(values[i].value)) {
          const newValues = selectedValues.filter((v) => v !== values[i].value);
          onChange(newValues);
        } else if (!selectedValues.includes(values[i].value)) {
          const valuesclone = clone(selectedValues);
          valuesclone.push(values[i].value);
          onChange(valuesclone);
        }
      } else {
        setShowTooltip(false);
        onChange([values[i].value]);
      }
    }
  };

  const onBlur = () => {
    if (values) {
      const shownValues = getShownValues(values, searchValue);
      if (shownValues.length === 1 && values.length !== 1 && focusedIndex) {
        onClickItem(focusedIndex <= shownValues.length - 1 ? shownValues[focusedIndex].index : shownValues[0].index);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (values) {
      let selectedIndex: number | undefined = undefined;

      if (selectedValues.length > 0 && typeof focusedIndex === 'undefined') {
        selectedIndex = values.findIndex((v) => {
          if (idBased) {
            const castedValue = v.value as T & DropdownGenericBaseType;
            return castedValue.id === selectedValues[0];
          } else {
            return v.value === selectedValues[0];
          }
        });
      }

      switch (e.key) {
        case 'Enter':
          if (typeof focusedIndex !== 'undefined') onClickItem(getShownValues(values, searchValue)[focusedIndex].index);
          break;
        case 'Tab':
          if (typeof selectedIndex !== 'undefined' && selectedIndex !== -1) {
            onClickItem(selectedIndex);
          } else {
            if (typeof focusedIndex !== 'undefined') {
              const shownValue = getShownValues(values, searchValue)[focusedIndex];
              if (shownValue) {
                onClickItem(shownValue.index);
              } else {
                onClickItem(0);
              }
            } else {
              onClickItem(0);
            }
          }
          e.preventDefault();
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          e.preventDefault();

          if (typeof focusedIndex === 'undefined') {
            if (typeof selectedIndex !== 'undefined' && selectedIndex !== -1) {
              if (e.key === 'ArrowDown') {
                setFocusedIndex(Math.min(selectedIndex + 1, getShownValues(values, searchValue).length - 1));
              } else {
                setFocusedIndex(Math.max(selectedIndex - 1, 0));
              }
            } else {
              setFocusedIndex(0);
            }
          } else {
            if (e.key === 'ArrowDown') {
              setFocusedIndex(Math.min(focusedIndex + 1, getShownValues(values, searchValue).length - 1));
            } else {
              setFocusedIndex(Math.max(focusedIndex - 1, 0));
            }
          }
      }
    }
  };

  useEffect(() => {
    if (values) {
      let newSearchValue = '';

      if (idBased) {
        if (selectedValues && selectedValues.length > 0) {
          // If we are working with id's in selectedValues
          const selectionMatch = values.find((v) => {
            const castedValue = v.value as T & DropdownGenericBaseType;
            return castedValue.id === selectedValues[0];
          });
          newSearchValue = selectionMatch?.text || '';
        }

        if (newSearchValue || !selectedValues[0]) setSearchValue(newSearchValue);
      } else {
        if (selectedValues && selectedValues.length === 1) {
          // If we are working with id's in selectedValues
          const selectionMatch = values.find((v) => v.value === selectedValues[0]);
          newSearchValue = selectionMatch?.text || '';
        } else {
          newSearchValue = selectedValues.reduce((newText, selection) => {
            return newText + ', ' + selection;
          }, '');
        }
        setSearchValue(newSearchValue);
      }
    }
  }, [selectedValues]);

  // If the dropdown only has one item, select this item by default
  useEffect(() => {
    if (selectSingleItemByDefault && !selectedSingleItemByDefault.current) {
      if (values.length === 1) {
        onChange([values[0].value]);
        selectedSingleItemByDefault.current = true;
      }
    }
  }, [values]);

  return { onClickItem, onBlur, handleKeyDown, getShownValues };
}
