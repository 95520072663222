import React from 'react';

import { UpdateTaxCodeType } from '@zf/api-types/tax-codes';

import useConfig from '../../../app-context/hooks/use-config';
import TaxCodes from './tax-codes';

export default function TaxCodeTab() {
  const config = useConfig<UpdateTaxCodeType[]>('taxCodes', '/cfg/TaxCodes');

  return <TaxCodes config={config} />;
}
