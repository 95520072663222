import { observer } from 'mobx-react';
import React from 'react';

import { InvoiceReferenceParametersType, TransactionReferenceDetailsType } from '@zf/api-types/transactions';
import { formatDate } from '@zf/utils/src/date';

import { CoachMark } from '../../../design-system/Components';
import { Paragraph } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import css from './parameters-tooltip.module.scss';

type Props = {
  referenceDetails: TransactionReferenceDetailsType;
};

const InvoiceReferenceParameters = (props: Props) => {
  const { referenceDetails } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const params = referenceDetails.parameters as InvoiceReferenceParametersType;

  return (
    <CoachMark>
      <div className={css['info-tooltip']}>
        <Paragraph bold>{getTranslation('invoice.invoice_num')}</Paragraph>
        <Paragraph>{params.invoiceNum}</Paragraph>
        <br />
        <Paragraph bold>{getTranslation('cards.cash_position.invoice_start_date')}</Paragraph>
        <Paragraph>{formatDate(params.periodStartDateTime)}</Paragraph>
        <br />
        <Paragraph bold>{getTranslation('cards.cash_position.invoice_end_date')}</Paragraph>
        <Paragraph>{formatDate(params.periodEndDateTime)}</Paragraph>
      </div>
    </CoachMark>
  );
};

export default observer(InvoiceReferenceParameters);
