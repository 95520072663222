import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';

import { useMeterIssuesContext } from '../../Context/Context';
import ResolveMultiIssuePanel from './multi-issue/ResolveMultiIssuePanel';
import ResolveSingleIssuePanel from './single-issue/ResolveSingleIssuePanel';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';

export type IssueActionType = 'resolve' | 'ignore' | 'export' | 'ignore-and-mute';

type Props = {
  meter: MeterType;
  refresh: () => void;
};

const IssueSidePanel = (props: Props) => {
  const { meter, refresh } = props;

  const ctx = useMeterIssuesContext();
  const { getSidePanelData } = ctx;

  const issues = getSidePanelData() as MeteringIssue[];

  if (issues.length === 0) return null;

  return issues.length === 1 ? (
    <ResolveSingleIssuePanel issue={issues[0]} refresh={refresh} />
  ) : (
    <ResolveMultiIssuePanel issues={issues} meter={meter} refresh={refresh} />
  );
};

export default IssueSidePanel;
