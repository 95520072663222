import React from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { groupBy } from '@zf/utils/src/array';
import { formatPeriod } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import Dropdown from '../../../Lang/Dropdown';
import { SuspenseSpinner } from '../../../suspense';
import { UseDropdownReturnType } from '../../useDropdown';
import ContractDropdownDetails from './ContractDropdownDetails';
import { ContractsDropdownProps } from './ContractsDropdown';

type Props = {
  contracts: ContractType[];
  dropdownToolsObject: UseDropdownReturnType<ContractType>;
};

const InlineDropdown = InlineInputField<DropdownProps<ContractType>>(Dropdown);

export default function ContractsDropdownContent(props: ContractsDropdownProps & Props) {
  const {
    id,
    contracts,
    dropdownToolsObject,
    selectedValues,
    placeholder,
    error,
    isInline = false,
    onFocus,
    onChange
  } = props;
  const { i18n, enumReducer } = useAppContext();

  const groupedByStatus = groupBy(contracts, 'currentContractStatus');

  // Order by status, signed first
  const signed: ContractType[] = [];
  const others: ContractType[] = [];

  Object.keys(groupedByStatus).forEach((key) => {
    if (key !== 'signed') {
      signed.push(...groupedByStatus[key]);
    } else {
      others.push(...groupedByStatus[key]);
    }
  });

  const sortedContracts = [...others, ...signed];

  const contractsDropdown = sortedContracts.map((con) => {
    return {
      value: con,
      text: `${i18n.getTranslation('invoice.contractnum')}: ${con.contractNumber} - ${i18n.getTranslation(
        'general.period'
      )}: ${formatPeriod(con.supplyStartDate, con.supplyEndDate)} [ ${enumReducer.getTranslation(
        'contractStatus',
        con.currentContractStatus
      )} ]`
    };
  });

  const { tools, selectedValue, setDropdownTools } = dropdownToolsObject;

  React.useEffect(() => {
    setDropdownTools({ values: contractsDropdown });
  }, []);

  const details = React.useMemo(() => {
    return (
      selectedValue && (
        <SuspenseSpinner>
          <ContractDropdownDetails contract={selectedValue.value} />
        </SuspenseSpinner>
      )
    );
  }, [selectedValue]);

  return isInline ? (
    <InlineDropdown
      {...props}
      id={id ? id : 'contracts-dropdown'}
      details={details}
      values={contractsDropdown}
      selectedValues={selectedValues}
      dropdownTools={tools}
      error={error}
      onChange={onChange}
      onFocus={onFocus}
      setDropdownTools={setDropdownTools}
      clear
    />
  ) : (
    <Dropdown
      {...props}
      id={id ? id : 'contracts-dropdown'}
      details={details}
      placeholder={placeholder ? placeholder : i18n.getTranslation('contracts.contracts')}
      values={contractsDropdown}
      selectedValues={selectedValues}
      dropdownTools={tools}
      error={error}
      onChange={onChange}
      setDropdownTools={setDropdownTools}
      onFocus={onFocus}
      clear
    />
  );
}
