import React from 'react';

import { CultureTableType } from '@zf/api-types/language';
import { ProductAttachmentType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../../../app-context';
import { DropdownAction } from '../../../../../../design-system/ComponentSets';
import ProductAttachmentsDialog from '../ProductAttachmentsDialog';

type Props = {
  productAttachment: ProductAttachmentType;
  cultureTable: CultureTableType;
  hasPortal: boolean;
  updateLocalProductAttachment: (newAttachment: ProductAttachmentType) => void;
};

export default function EditListItem(props: Props) {
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="edit-product-attachment"
      dialogTitle={i18n.getTranslation('general.edit')}
      icon="sign"
      dialogContent={<ProductAttachmentsDialog ref={clickRef} validationRef={validationRef} {...props} />}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('general.edit')}
      validationRef={validationRef}
    />
  );
}
