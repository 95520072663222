import moment from 'moment';
import React from 'react';

import CalendarHeader from '../../Calendar/calendar-header';
import MonthContainer from '../../Calendar/month-container';
import { Heading } from '../../Heading';
import TooltipTrigger from '../../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './year-input.module.scss';

export type CompareMonthType = {
  year: number;
  month: number;
};

type Props = {
  id: string;
  selectedMonth: CompareMonthType;
  onSelect: (month: CompareMonthType) => void;
};

export default function MonthInput(props: Props) {
  const { id, onSelect, selectedMonth } = props;

  const onNext = () => {
    onSelect({ year: selectedMonth.year + 1, month: selectedMonth.month });
  };

  const onPrevious = () => {
    onSelect({ year: selectedMonth.year - 1, month: selectedMonth.month });
  };

  const scrollCalendar = (event: React.WheelEvent) => {
    if (event.deltaY > 0) {
      onNext();
    } else if (event.deltaY) {
      onPrevious();
    }
  };

  const selectMonth = (month: number) => {
    onSelect({ year: selectedMonth.year, month: month });
  };

  return (
    <div id={id}>
      <TooltipTrigger
        tooltip={
          <div onWheel={scrollCalendar} style={{ width: '35rem' }} className={css['calendar']}>
            <CalendarHeader onPrevious={onPrevious} onNext={onNext}>
              <Heading headingType="h2" className={css['calendar-header']}>
                {selectedMonth.year}
              </Heading>
            </CalendarHeader>
            <MonthContainer onSelect={selectMonth} selectedMonth={selectedMonth.month} />
          </div>
        }
        placement="bottom-end"
        trigger="click"
      >
        <Heading headingType="h3" className={css['year-input']}>
          {`${moment().month(selectedMonth.month).format('MMMM')} - ${selectedMonth.year}`}
        </Heading>
      </TooltipTrigger>
    </div>
  );
}
