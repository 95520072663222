import React from 'react';

import { ZFErrorType } from '@zf/api-types/general';
import CardEmptyBody from '@zf/stella-react/src/atoms/Card/CardEmptyBody';

import { useAppContext } from '../../app-context';
import { getErrorTitle } from './error-boundary-class';

type Props = {
  error: ZFErrorType;
  valueContent: JSX.Element[];
  errorImage: JSX.Element;
};

export default function CardError(props: Props) {
  const { error, valueContent, errorImage } = props;
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody title={getErrorTitle(error, i18n)} image={errorImage}>
      {valueContent}
    </CardEmptyBody>
  );
}
