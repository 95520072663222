import { MeteringIssueError } from '@zf/api-types/enums';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import React from 'react';

export const CancelMutingRuleDialogContent = ({ errorType }: { errorType: string }) => {
  const {
    applicationStore: { getTranslation }
  } = useStore();

  return (
    <Paragraph>
      <Interweave content={getTranslation('meter_issues.rule_cancel_desc', { errorType })} />
    </Paragraph>
  );
};
