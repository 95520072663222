import React from 'react';

import { UpdatePaymentTermType } from '@zf/api-types/product';

import useConfig from '../../../app-context/hooks/use-config';
import PaymentTerms from './payment-terms';

export default function PaymentTermsTab() {
  const config = useConfig<UpdatePaymentTermType[]>('paymentTerms', '/cfg/PaymentTerms');

  return <PaymentTerms config={config} />;
}
