import React, { useMemo } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ModelType } from '@zf/api-types/model';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../../components/placeholder';
import { useStore } from '../../../../hooks/useStore';
import useMeterListPage from '../hooks/useMeterListPage';

type Props = {
  models: ModelType[];
  customEntityPropertyTypes: CustomEntityPropertyType[];
  extraColumns: ColumnType[];
  meterIds?: string[];
  showCheckBoxes?: boolean;
};

export default function MeterList(props: Props) {
  const { models, customEntityPropertyTypes, extraColumns, meterIds, showCheckBoxes } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;
  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useMeterListPage(models, customEntityPropertyTypes, meterIds);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="meters-table"
        tooltipId="meters-table-id"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        showCheckBoxes={showCheckBoxes}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={[
          {
            width: 40,
            label: '',
            dataKey: 'isUnhealthy'
          },
          {
            width: 200,
            label: getTranslation('metering_list.labels.serial'),
            dataKey: 'serialNumber',
            noClick: true
          },
          {
            width: 150,
            label: getTranslation('meter.brand'),
            dataKey: 'brand'
          },
          {
            width: 150,
            label: getTranslation('meter.model'),
            dataKey: 'model'
          },
          {
            width: 150,
            label: getTranslation('general.status'),
            dataKey: 'state'
          },
          {
            width: 150,
            label: getTranslation('general.type'),
            dataKey: 'meterType'
          },
          {
            width: 200,
            label: getTranslation('metering_list.labels.last_received'),
            dataKey: 'lastTimeReceivedData'
          },
          {
            width: 200,
            label: getTranslation('metering_list.labels.measurementsUntil'),
            dataKey: 'measurementsUntil'
          },
          {
            width: 300,
            label: getTranslation('metering_list.labels.installed_address'),
            dataKey: 'installedAddress'
          },
          ...extraColumns
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
}
