import React from 'react';

import { ErrorBoundary } from '../components/Error';
import { Loader } from '../components/Loader';
import { SuspenseSpinner } from '../components/suspense';
import { useAppContext } from '../app-context/app-context';

type UrlParamsType = { [key: string]: string };

export type RouteRenderParams = {
  location: Location;
  navigate: (href: string) => void;
  urlParams: UrlParamsType;
  baseUri: string;
};

export type PropsType = {
  Component: (props: any) => JSX.Element | null;
  default?: boolean;
  path?: string;
  location?: Location;
  navigate?: (href: string) => void;
  baseUri?: string;
  spinnerType?: 'zf' | 'spinner';
  [key: string]: any;
};

export default function Route(props: PropsType) {
  const { Component, location = window.location, spinnerType = 'zf', navigate = () => {}, ...otherProps } = props;
  const { i18n } = useAppContext();
  const urlParams: UrlParamsType = {};

  for (const key of Object.keys(otherProps)) {
    if (typeof otherProps[key] === 'string') {
      urlParams[key] = otherProps[key];
    }
  }

  React.useEffect(() => {
    const splitted = location.href.split('/');

    switch (splitted.length) {
      case 4: {
        if (!splitted[3]) {
          document.title = `${i18n.getTranslation('general.zero_friction_erp')} - ${i18n.getTranslation(
            'tenant.tenant_overview'
          )}`;
        } else {
          document.title = `${splitted[3]} - ${i18n.getTranslation('tenant.organisations')}`;
        }
        break;
      }
      case 5:
        document.title = `${splitted[4]} - ${i18n.getTranslation('nav.dashboard')}`;
        break;
      case 6:
        if (splitted[5] === 'configuration')
          document.title = `${splitted[4]} - ${i18n.getTranslation('nav.configuration')}`;
        break;
    }
  }, [location]);

  let route: JSX.Element | null = null;

  if (spinnerType === 'zf') {
    route = (
      <React.Suspense fallback={<Loader />}>
        <Component location={location} navigate={navigate} urlParams={urlParams} {...props} />
      </React.Suspense>
    );
  } else {
    route = (
      <SuspenseSpinner size="large">
        <Component location={location} navigate={navigate} urlParams={urlParams} {...props} />
      </SuspenseSpinner>
    );
  }

  return <ErrorBoundary>{route}</ErrorBoundary>;
}
