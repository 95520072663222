import { MultiValue } from 'design-system/ComponentSets';
import React from 'react';

import { contactType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';

import { useAppContext } from '../../../../app-context';
import ContactDetailsMultiValueItem from './ContactDetailsMultiValueItem';

type Props<T> = {
  groupedByDescription?: Record<string, ContactEntryType[]>;
  setValue: (value: Partial<T>) => void;
  onFocus?: () => void;
};

export default function ContactDetailsMultiValue<T extends { secondaryContactDetails: ContactEntryType[] }>(
  props: Props<T>
) {
  const { groupedByDescription, onFocus, setValue } = props;

  const { i18n, enumReducer } = useAppContext();

  const contactTypes = enumReducer.getEnum<contactType>('contactType');

  let initialValues: { contactDetails: ContactEntryType[] }[] = [];

  if (groupedByDescription) {
    let groupedWithAllCtypes: Record<string, ContactEntryType[]> = {};

    Object.keys(groupedByDescription).forEach((key) => {
      const entry = groupedByDescription[key];

      const missing = contactTypes.filter((ct) => !entry.some((e) => e.contactType === ct.value));

      if (missing.length === 0) {
        return (groupedWithAllCtypes[key] = entry);
      } else {
        return (groupedWithAllCtypes[key] = [
          ...entry,
          ...missing.map((m) => {
            return {
              contactType: m.value,
              value: '',
              description: key,
              primaryForType: false
            };
          })
        ]);
      }
    });

    initialValues = Object.keys(groupedWithAllCtypes).map((key) => {
      const details = [...groupedWithAllCtypes[key]];
      return {
        contactDetails: details
      };
    });
  }

  return (
    <MultiValue
      id="contact-details"
      title={i18n.getTranslation('customer.add_extra_contact')}
      initialValues={initialValues}
      intialNodeValue={{
        contactDetails: contactTypes.map((ct) => {
          return { contactType: ct.value, value: '', description: '', primaryForType: false };
        })
      }}
      onChange={(secondaryContactDetails) => {
        const newVals = secondaryContactDetails.reduce((acc: ContactEntryType[], scd) => {
          acc.push(...scd.contactDetails);

          return acc;
        }, []);

        // @ts-ignore
        setValue({ secondaryContactDetails: newVals });
      }}
      oneEntryRequired={false}
    >
      {({ value, index, dispatchValue }) => {
        return (
          <ContactDetailsMultiValueItem index={index} value={value} onFocus={onFocus} dispatchValue={dispatchValue} />
        );
      }}
    </MultiValue>
  );
}
