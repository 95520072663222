import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import Description from '../../ComponentSets/SettingDescription/Description';
import RadioInput from './RadioInput';

type Props = {
  onChange: (val: string) => void;
  value: string;
  checked: boolean;
  description?: string;
  title: string;
  helpUrl?: string;
  fullWidth?: boolean;
};

export default function RadioInputWithDescription(props: Props) {
  const { onChange, value, description, checked, title, helpUrl, fullWidth = false } = props;

  return (
    <FlexElements>
      <RadioInput onChange={onChange} value={value} name={''} checked={checked} />
      <Description description={description || ''} title={title} helpUrl={helpUrl} fullWidth={fullWidth} />
    </FlexElements>
  );
}
