import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { BlockUnblockContractType, ContractRowType, ContractType } from '@zf/api-types/master-data/contract';

import css from '../../../../actions/style.module.scss';
import InputField from '../../../../components/input/InputField';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../../../design-system/Foundation';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  setUpdatedRows?: (updatedRows: ContractType[]) => void;
  selectedRows?: Array<ContractRowType>;
  contractToHandle?: BlockUnblockContractType;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const BlockDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { setUpdatedRows, selectedRows, contractToHandle, validationRef } = props;
  const { contractStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [comment, setComment] = useState('');

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!comment);
    }
  };

  useEffect(() => {
    validate();
  }, [comment]);

  let contractsToHandle: Array<BlockUnblockContractType> = [];

  if (selectedRows) {
    contractsToHandle = selectedRows.map((row) => {
      return {
        contract: row.__contractEntity,
        etag: row.__contractEntity._etag
      };
    });
  } else if (contractToHandle) {
    contractsToHandle = [contractToHandle];
  }

  useImperativeHandle(ref, () => ({
    async onClick() {
      //validation
      if (!comment) {
        notify.warning({
          content: getTranslation('contracts.block_comment_mandatory')
        });
        return;
      }

      if (contractsToHandle.length > 0) {
        try {
          const newContracts = await Promise.all(
            contractsToHandle.map((contract) => contractStore.blockUnBlock(contract, comment, true))
          );

          if (setUpdatedRows) setUpdatedRows(newContracts);

          notify.success({
            content: getTranslation('contracts.block_success')
          });
        } catch (e) {
          notify.error({
            content: getTranslation('contracts.block_failure'),
            error: e
          });
        }
      }
    }
  }));

  const information = {
    toBeBlocked: contractsToHandle.filter((row) => !row.contract.billingDetails.blocked).length,
    toBeUnblocked: contractsToHandle.filter((row) => row.contract.billingDetails.blocked).length
  };

  return (
    <>
      <Paragraph>
        {contractToHandle
          ? getTranslation('contracts.block_unblock_single', {
              blockUnblock: contractToHandle.contract.billingDetails.blocked
                ? getTranslation('contracts.unblock')
                : getTranslation('contracts.block')
            })
          : getTranslation('contracts.block_comment_mandatory', information)}
      </Paragraph>
      <div className={css['input-wrapper']}>
        <InputField
          id="comment"
          onChange={(val) => setComment(val)}
          value={comment}
          placeholder={getTranslation('contracts.comment')}
          error={!comment}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </div>
    </>
  );
});

export default observer(BlockDialog);
