import classNames from 'classnames';
import React from 'react';

import { colors } from '@zf/utils/src/color';

import { CoachMark, Link } from '../../Components';
import { Paragraph } from '../../Foundation';
import css from './description.module.scss';

export type DescriptionProps = {
  title: string;
  description: string;
  info?: string;
  helpUrl?: string;
  fullWidth?: boolean;
  isMandatory?: boolean;
};

const Description = (props: DescriptionProps) => {
  const { title, description, helpUrl, info, fullWidth = false, isMandatory = false } = props;

  return (
    <div className={classNames(css['info'], { [css['width-limited']]: !fullWidth })}>
      <div className={css['info-wrapper']}>
        <Paragraph className={css['info-wrapper-title']} color={colors['graphite']}>
          {title}
        </Paragraph>
        {isMandatory && <span className={css['mandatory-indicator']}>*</span>}
        {info ? <CoachMark>{info}</CoachMark> : <Paragraph>&nbsp;</Paragraph>}
      </div>
      {helpUrl ? (
        <Link id={`link-${helpUrl}`} url={helpUrl} newTab>
          {description}
        </Link>
      ) : (
        description && <Paragraph>{description}</Paragraph>
      )}
    </div>
  );
};

export default Description;
