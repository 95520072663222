import React from 'react';

import { MasterDataAnalyticsType } from '@zf/api-types/analytics/masterdata';

import TodoCard from '../cards/TodoCard';
import DashboardColumn from './DashboardColumn';
import css from './master-data.module.scss';

type Props = {
  masterDataInsights: MasterDataAnalyticsType;
  setActiveTab: (tabId: string) => void;
};

export default function MasterData(props: Props) {
  const { masterDataInsights } = props;

  return (
    <DashboardColumn className={css['master-data']}>
      {[<TodoCard key="master-data" subject="master-data" dataObject={masterDataInsights} />]}
    </DashboardColumn>
  );
}
