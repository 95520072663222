import classNames from 'classnames';
import React from 'react';

import useFocus from '@zf/hooks/src/useFocus';
import eventChain from '@zf/utils/src/eventChain';
import { inputGuidGenerator } from '@zf/utils/src/general';

import CoachMark from '../CoachMark/CoachMark';
import { Paragraph } from '../Paragraph';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './input-field.module.scss';

export type StellaInputFieldProps = {
  id: string;
  value?: string | null;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  prefix?: string;
  errors?: {};
  info?: string;
  min?: string;
  max?: string;
  singleError?: string;
  postfix?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  style?: React.CSSProperties;
  hideArrows?: boolean;
  hideLabel?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  darkMode?: boolean;
  centerValue?: boolean;
  lightMode?: boolean;
  maxLength?: number;
  tabIndex?: number;
  selectAllOnFocus?: boolean;
  rightIconAction?: () => void;
  onFocus?: (step: string) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onBlur?: () => void;
  onSelect?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (value: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default React.forwardRef(
  (
    props: StellaInputFieldProps,
    ref: string | ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined
  ) => {
    const {
      id,
      className,
      placeholder,
      info,
      value = '',
      type = 'text',
      disabled = false,
      readonly = false,
      autoFocus = false,
      darkMode = false,
      lightMode = false,
      error = false,
      singleError = null,
      centerValue,
      errors,
      selectAllOnFocus = false,
      maxLength,
      hideArrows = false,
      style = {},
      prefix,
      postfix,
      iconLeft,
      iconRight,
      hideLabel,
      min,
      max,
      onChange,
      onFocus,
      onBlur,
      onSelect,
      onClick,
      rightIconAction,
      onKeyDown = () => {},
      onKeyUp = () => {}
    } = props;

    const [focus, setFocus] = React.useState(autoFocus);
    const inputRef = React.useRef<HTMLInputElement>(null);
    useFocus(focus, inputRef, selectAllOnFocus);

    // Get the index of this inputfield-container in the array of all tabbable elements
    const keyboardfocusableElements = Array.prototype.slice.call(
      document.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')
    );

    const index = keyboardfocusableElements.findIndex((e) => {
      return e.id === `tab-div-${id}`;
    });

    const handleFocus = () => setFocus(true);
    const handleBlur = () => setFocus(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };

    const inputState = error ? 'error' : 'success';

    const hiddenArrows = hideArrows ? 'hidden-arrows' : '';

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.shiftKey && e.key === 'Tab') {
        // When shift-tab is pressed focus the previous element in the tabbable elements array
        if (keyboardfocusableElements[index - 1]) {
          keyboardfocusableElements[index - 1].focus();
        }
      } else if (e.key === 'Escape') {
        handleBlur();
      }
      onKeyDown(e);
    };

    const items = [];

    if (iconLeft) {
      items.push(
        <div key="inputfield-icon-left" className={classNames(css['input-icon'], css['input-icon-left'])}>
          {iconLeft}
        </div>
      );
    }

    if (prefix) {
      items.push(
        <div key="inputfield-prefix" className={classNames(css['suffix'], css['prefix'])}>
          {prefix}&nbsp;
        </div>
      );
    }

    items.push(
      <input
        min={min}
        max={max}
        maxLength={maxLength}
        id={id}
        key={`${id}-inputfield`}
        ref={inputRef}
        className={classNames(css['input-field'], className, css[hiddenArrows], { [css['center-input']]: centerValue })}
        name={inputGuidGenerator()} // This attribute fixed the autoComplete
        autoComplete="nope"
        autoCorrect="off"
        autoCapitalize="none"
        value={value || ''}
        type={type}
        disabled={disabled}
        readOnly={readonly}
        tabIndex={disabled ? -1 : 0}
        onKeyUp={onKeyUp}
        onChange={handleChange}
        onFocus={eventChain(handleFocus, onFocus)}
        onBlur={eventChain(handleBlur, onBlur)}
        onSelect={onSelect}
        onKeyDown={handleKeyDown}
        onClick={onClick}
      />
    );

    if (iconRight) {
      items.push(
        <div
          key="inputfield-icon-right"
          className={
            disabled
              ? classNames(css['input-icon-disabled'], css['input-icon-right-disabled'])
              : classNames(css['input-icon'], css['input-icon-right'])
          }
          onClick={() => {
            if (rightIconAction) rightIconAction();
          }}
          role="button"
          onKeyDown={() => {
            // Do nothing
          }}
          tabIndex={-1}
        >
          {iconRight}
        </div>
      );
    }

    if (postfix) {
      items.push(
        <div key="inputfield-postfix" className={classNames(css['suffix'], css['postfix'])}>
          &nbsp;{postfix}
        </div>
      );
    }

    const generateErrors = () => {
      if (singleError) {
        return <Paragraph>{singleError}</Paragraph>;
      } else {
        for (const property in errors) {
          return (
            <Paragraph>
              {/* @ts-ignore */}
              {errors[property]}
            </Paragraph>
          );
        }
      }
    };

    return (
      <div
        className={classNames(
          css['inputfield-wrapper'],
          {
            [css['border-bottom']]: !disabled,
            [css['disabled']]: disabled,
            [css['focused']]: focus,
            [css['light-mode']]: lightMode,
            [css['dark-mode']]: darkMode
          },
          css[inputState],
          className
        )}
        style={style}
        ref={ref}
      >
        {placeholder && (
          <label
            htmlFor={id}
            className={classNames(css['inputfield-label'], {
              [css['inputfield-label-with-prefix']]: !!prefix && (value === undefined || value === ''),
              [css['inputfield-floating-label']]: focus || (value !== undefined && value !== ''),
              [css['inputfield-floating-label-prefix']]: !!prefix && focus,
              [css['inputfield-label-icon-offset']]: !!iconLeft,
              [css['inputfield-label-hidden']]: !!hideLabel
            })}
          >
            {placeholder}
          </label>
        )}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}

        <div
          id={`tab-div-${id}`}
          className={classNames(css['inputfield-container'], {
            [css['focused']]: focus
          })}
        >
          {items}
          {error && singleError && (
            <TooltipTrigger tooltip={<div className={css['tooltip']}>{generateErrors()}</div>} trigger="hover">
              <CoachMark className={css['validation-popup']} usePopup={false} size="small" warning />
            </TooltipTrigger>
          )}
          {info && (
            <TooltipTrigger tooltip={<Paragraph>{info}</Paragraph>} trigger="hover">
              <CoachMark className={css['validation-popup']} usePopup={false} size="small" info />
            </TooltipTrigger>
          )}
        </div>
      </div>
    );
  }
);
