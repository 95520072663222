import React from 'react';

import { MeasurementRowType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteMeasurementDialogContent from '../../../actions/meters/add-measurement/delete-measurement-dialog';
import { useAppContext } from '../../../app-context';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { SuspenseSpinner } from '../../suspense';
import ConfirmationActionButton from '../ConfirmationActionButton';
import css from './add-measurement-detail-btn.module.scss';

type Props = {
  selectedRows: MeasurementRowType[];
  setSelectedIds: (list: string[]) => void;
};

export default function DeleteMeasurementDetailButton(props: Props) {
  const { selectedRows, setSelectedIds } = props;
  const { refresh } = useRefresh();
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const measurementIds = selectedRows.map((r) => {
    return r.__measurementEntity.id;
  });

  return (
    <ConfirmationActionButton
      id="meter.delete_measurement"
      className={css['btn-style']}
      icon="cancel"
      dialogTitle={i18n.getTranslation('actions.delete_measurement')}
      dialogContent={
        <SuspenseSpinner size="xsmall">
          <DeleteMeasurementDialogContent
            measurements={measurementIds}
            extChannelId={selectedRows[0] ? selectedRows[0].__measurementEntity.externalChannelIdentifier : ''}
            ref={clickRef}
            onComplete={() => {
              refresh();
              setSelectedIds([]);
            }}
            validationRef={validationRef}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.delete')}
      dialogType="danger"
    />
  );
}
