import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import EmptyColumn from '../../../../components/Column/EmptyColumn';
import { WrappableVirtualizedList } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import { getBillingItemIsActive } from '../../../../utils/product';
import VirtualBillingItem from '../../shared/billing-item/VirtualBillingItem';
import { StringParam, useQueryParams } from 'use-query-params';

const ProductBillingItemsContent = () => {
  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { isPanelViewMode, productsStore } = productConfigStore;
  const {
    productBillingItems,
    selectedBillingItem,
    selectedProduct,
    taxCodes,
    customEntityPropertyTypes,
    calculationTypes,
    setSelectedBillingItem,
    initTariffs
  } = productsStore;

  /**
   * @description use query param to focus this billing item
   */

  const [query] = useQueryParams({
    billingItemId: StringParam
  });

  useEffect(() => {
    query.billingItemId && setSelectedBillingItem(productBillingItems.find((b) => b.id === query.billingItemId));
  }, []);

  const items = productBillingItems.map((b, index) => {
    const isActive = getBillingItemIsActive(b, selectedProduct?.billingItems);

    return {
      initTariffs,
      key: `${b.id}-${index}`,
      index,
      product: selectedProduct,
      isActive,
      billingItem: b,
      taxCodes,
      customEntityPropertyTypes,
      calculationTypes,
      onClick: () => setSelectedBillingItem(b),
      isSelected: selectedBillingItem?.id === b.id
    };
  });

  return productBillingItems.length > 0 ? (
    isPanelViewMode ? (
      <WrappableVirtualizedList key={items.length} items={items} ItemComponent={VirtualBillingItem} columnWidth={400} />
    ) : (
      <WrappableVirtualizedList key={items.length} items={items} ItemComponent={VirtualBillingItem} columnCount={1} />
    )
  ) : (
    <EmptyColumn
      image="attributes2"
      title={getTranslation('billing_items.billing_items')}
      text={getTranslation(
        `billing_items.${productBillingItems.length === 0 ? 'no_items_yet' : 'no_billing_item_templates'}`
      )}
    />
  );
};

export default observer(ProductBillingItemsContent);
