import React from 'react';

import { exportJobStatus } from '@zf/api-types/enums';
import { ExportJobType } from '@zf/api-types/export-job';

import DeleteExportJobListItem from '../../../components/list-items/export-jobs/DeleteExportJobListItem';
import DownloadExportJobListItem from '../../../components/list-items/export-jobs/DownloadExportJobListItem';
import GenerateExportJobListItem from '../../../components/list-items/export-jobs/GenerateExportJobListItem';
import TransferExportJobListItem from '../../../components/list-items/export-jobs/TransferExportJobListItem';

type Props = {
  exportJob: ExportJobType;
};

const ExportJobActions = (props: Props) => {
  const { exportJob } = props;

  return (
    <>
      {exportJob.status !== exportJobStatus.generated &&
        exportJob.status !== exportJobStatus.generating &&
        exportJob.status !== exportJobStatus.transferred && <GenerateExportJobListItem exportJob={exportJob} />}
      {(exportJob.status === exportJobStatus.generated || exportJob.status === exportJobStatus.transferred) && (
        <>
          <DownloadExportJobListItem exportJob={exportJob} />
          <TransferExportJobListItem exportJob={exportJob} />
        </>
      )}
      <DeleteExportJobListItem exportJob={exportJob} />
    </>
  );
};

export default ExportJobActions;
