import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { CollectionFlowType } from '@zf/api-types/collection-cycles';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../app-context/app-context';
import useSingleAPI from '../../../hooks/useSingleAPI';
import Dropdown from '../../Lang/Dropdown';

export type FlowsDropdownProps = {
  id: string;
  isInline?: boolean;
  selectedValue: string;
  error?: boolean;
  singleError?: string;
  renderTopLvl?: boolean;
  selectSingleItemByDefault?: boolean;
  onFocus?: (step: string) => void;
  onChange: (values: CollectionFlowType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<CollectionFlowType>>(Dropdown);

export default function FlowsDropdown(props: FlowsDropdownProps) {
  const { id, error, selectSingleItemByDefault, isInline = false, renderTopLvl = true, onFocus, onChange } = props;
  const { selectedValue } = props;
  const { i18n } = useAppContext();

  const flowsResponse = useSingleAPI<PagedResponseType<CollectionFlowType>>({
    request: {
      endpoint: '/cfg/CollectionFlows'
    }
  });

  if (!flowsResponse || !flowsResponse.result || flowsResponse.result instanceof Promise) {
    return isInline ? (
      <InlineDropdown
        id={id}
        onChange={onChange}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    ) : (
      <Dropdown
        id={id}
        onChange={onChange}
        placeholder={i18n.getTranslation('collection_flows.collection_flow')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    );
  }

  const dropdownValues = flowsResponse.result.data.results.map((flow) => {
    return {
      value: flow,
      text: flow.name
    };
  });

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={dropdownValues}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      error={error}
      renderTopLvl={renderTopLvl}
      selectSingleItemByDefault={selectSingleItemByDefault}
      clear
    />
  ) : (
    <Dropdown
      id={id}
      onChange={onChange}
      placeholder={i18n.getTranslation('collection_flows.collection_flow')}
      values={dropdownValues}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      error={error}
      renderTopLvl={renderTopLvl}
      selectSingleItemByDefault={selectSingleItemByDefault}
      clear
    />
  );
}
