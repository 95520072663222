import { culture } from '@zf/api-types/enums';
import { FileUploadType } from '@zf/api-types/general';

import BaseFormVjf, { Fields } from '../../../../app-context/stores/forms/BaseFormVjf';

export type LocalEntityAttachmentFile = {
  attachmentFileForm: AttachmentFileForm;
};

export enum formFields {
  attachmentId = 'attachmentId',
  file = 'file',
  culture = 'culture',
  fileName = 'fileName',
  internalFileName = 'internalFileName',
  isDefault = 'isDefault'
}

export type AttachmentFileFormFieldTypes = {
  attachmentId: string;
  file: FileUploadType | undefined;
  culture: culture;
  fileName: string;
  internalFileName: string;
  isDefault: boolean;
};

export default class AttachmentFileForm extends BaseFormVjf<AttachmentFileFormFieldTypes> {
  constructor(initialValues: AttachmentFileFormFieldTypes) {
    super(initialValues);
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: formFields.attachmentId,
          label: 'attachmentId'
        },
        {
          name: formFields.file,
          label: 'file',
          validators: [this.isRequired]
        },
        {
          name: formFields.culture,
          label: 'culture'
        },
        {
          name: formFields.fileName,
          label: 'fileName',
          validators: [this.isRequired]
        },
        {
          name: formFields.internalFileName,
          label: 'internalFileName'
        },
        {
          name: formFields.isDefault,
          label: 'isDefault'
        }
      ]
    };
  }
}
