import React from 'react';

import { BillingAnalyticsType } from '@zf/api-types/analytics/billing';

import CreatedInvoicesChart from '../../../../cards/InvoicesChartCard/CreatedInvoicesChart';
import MissingInvoicesChart from '../../../../cards/InvoicesChartCard/MissingInvoicesChart';
import ExportCard from '../cards/ExportCard';
import ImportCard from '../cards/ImportCard';
import TodoCard from '../cards/TodoCard';
import css from './dashboard-column.module.scss';
import DashboardColumn from './DashboardColumn';

type Props = {
  billingInsights: BillingAnalyticsType;
  setActiveTab: (tabId: string) => void;
};

export default function BillingAnalytics(props: Props) {
  const { billingInsights, setActiveTab } = props;

  return (
    <DashboardColumn withBorder>
      <div className={css['split-invoice-chart']}>
        <CreatedInvoicesChart billingInsights={billingInsights} setActiveTab={setActiveTab} />
        <MissingInvoicesChart billingInsights={billingInsights} setActiveTab={setActiveTab} />
      </div>
      <TodoCard subject="billing" dataObject={billingInsights} setActiveTab={setActiveTab} />
      <ImportCard subject="billing" importInsights={billingInsights.importInsights} />
      <ExportCard subject="billing" exportInsights={billingInsights.exportInsights} />
    </DashboardColumn>
  );
}
