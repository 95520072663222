import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import { ImportJobType } from '@zf/api-types/import-job';

import { useAppContext } from '../../app-context';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../design-system/Foundation';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';
import { processAllImportJobs } from './import-job';

type Props = {
  onComplete?: (updatedRows: ImportJobType[]) => void;
  refreshCounts?: () => void;
};

const ProcessAllDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { onComplete, refreshCounts } = props;
  const { tenantReducer } = useAppContext();
  const { applicationStore } = useStore();
  const { userStore, getTranslation } = applicationStore;
  const { lang } = userStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newImportJobs = await processAllImportJobs(tenantReducer, lang);
        if (onComplete) onComplete(newImportJobs);
        if (refreshCounts) refreshCounts();
        notify.success({
          content: getTranslation('actions.import_jobs.process_all_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('actions.import_jobs.process_all_failed'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{getTranslation('actions.import_jobs.process_all_description')}</Paragraph>;
});

export default observer(ProcessAllDialog);
