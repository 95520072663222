type EventHandler = (...args: Array<any>) => void;

export default function eventChain(...eventHandlers: Array<EventHandler | undefined>) {
  return (...params: Array<any>) => {
    for (const eventHandler of eventHandlers) {
      if (eventHandler && typeof eventHandler === 'function') {
        eventHandler(...params);
      } else if (eventHandler) {
        // eslint-disable-next-line no-console
        console.warn('Tried to call a none function from eventChain.');
      }
    }
  };
}
