import React, { useEffect, useState } from 'react';
import ConfirmationActionButton from '../ConfirmationActionButton';
import { ShowMutingRulesDialog } from './ShowMutingRulesDialog';
import { useStore } from 'hooks/useStore';
import useRefresh from 'app-context/hooks/use-refresh';

export const ShowMutingRulesListPageButton = () => {
  const { applicationStore, meterStore } = useStore();
  const [count, setCount] = useState<number>(0);
  const { mutingRuleService } = meterStore;
  const { getCount } = mutingRuleService;
  const { getTranslation } = applicationStore;
  const { refresh, timeStamp } = useRefresh();

  useEffect(() => {
    getCount(true, false).then((data) => {
      setCount(data);
    });
  }, [timeStamp]);

  return (
    <ConfirmationActionButton
      id="show-muting-rules-dialog"
      dialogTitle={getTranslation('meter_issues.show_muting_rules')}
      icon="alert-x"
      dialogContent={<ShowMutingRulesDialog refresh={refresh} />}
      onSubmit={() => {}}
      dialogLocation="right"
      type="text"
      isDismissDialog
    >
      {getTranslation('meter_issues.show_muting_rules_with_count', { count })}
    </ConfirmationActionButton>
  );
};
