import { makeObservable, observable } from 'mobx';
import RootStore from '../../../app-context/stores';
import CountrySpecificService from '../services/CountrySpecificService';
import SocialTariffStore from './SocialTariffStore';

export default class CountrySpecificStore {
  public rootStore: RootStore;
  public socialTariffStore: SocialTariffStore;
  public countrySpecificService: CountrySpecificService;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.socialTariffStore = new SocialTariffStore(this);
    this.countrySpecificService = new CountrySpecificService(rootStore.applicationStore);
    makeObservable(this, {
      socialTariffStore: observable
    });
  }
}
