import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';

export default function NoTransactions() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody icon={<Icon type="money" />} title={i18n.getTranslation('payments.no_transactions')}>
      {i18n.getTranslation('payments.no_transactions_desc')}
    </CardEmptyBody>
  );
}
