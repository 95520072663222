import { action, autorun, makeObservable, observable } from 'mobx';

import { AggregatedDetailType, PagedResponseType } from '@zf/api-types/api';
import { SharedEntityProperties } from '@zf/api-types/entity';
import { ZFErrorType } from '@zf/api-types/general';
import { roundToLower100 } from '@zf/utils/src/number';

import RootStore from '../../../../app-context/stores';
import { sendRequestParamsType } from '../../../../app-context/stores/domain/app-store-types';
import { RequestType } from '../../../../types/Request';
import { synchronouslyMapArray } from '../../../../utils/arrays';
import { createHeader } from '../../../../utils/request';

export type RowTypeBase = {
  __id: string;
  id?: string;
};

export type Result<E, R> = {
  loading: boolean;
  error: ZFErrorType | null;
  rows: R[];
  totalAmountOfRows: number;
  sortableFields: string[];
  aggregateDetails: AggregatedDetailType[];
  updateGivenRows: (updatedRecords: E[], deletedRecords?: Partial<R>[]) => Promise<void>;
};

export default class InfiniAPIService<E extends SharedEntityProperties, R extends RowTypeBase> {
  private rootStore: RootStore;

  private processRecord: (record: E) => R;
  private fetchCounts: (() => Promise<void>) | undefined;

  private ROWS_TO_BUFFER = 200;

  // We keep this to handle next page fetches, which should be handled inside this class
  public sendRequestParams: sendRequestParamsType | undefined;

  public nextPageToken = '';
  public lastShownItem = 0;
  public lastFetchedItem = 0;
  public isFetchingNextPage = false;

  public rows: R[] = [];
  public loading = true;
  public error: ZFErrorType | null = null;
  public totalAmountOfRows = 0;

  public sortableFields: string[] = [];
  public aggregateDetails: AggregatedDetailType[] = [];

  constructor(rootStore: RootStore, processRecord: (record: E) => R, fetchCounts?: () => Promise<void>) {
    this.rootStore = rootStore;

    this.processRecord = processRecord;
    this.fetchCounts = fetchCounts;

    makeObservable(this, {
      sendRequestParams: observable,

      loading: observable,
      error: observable,
      totalAmountOfRows: observable,
      sortableFields: observable,
      aggregateDetails: observable,
      rows: observable,

      nextPageToken: observable,
      lastShownItem: observable,
      lastFetchedItem: observable,
      isFetchingNextPage: observable,

      fetchRows: action,
      fetchNextPage: action,
      updateGivenRows: action,
      handleFetchRows: action,
      handleFetchNextPage: action,
      setLoading: action,
      setStopIndex: action,
      setIsFetchingNextPage: action,
      setRecordProcessor: action
    });

    autorun(() => {
      const scrolledFarEnoughToFetchNextPage =
        roundToLower100(this.lastShownItem + this.ROWS_TO_BUFFER) >= this.lastFetchedItem;

      if (this.nextPageToken && scrolledFarEnoughToFetchNextPage && !this.isFetchingNextPage) {
        this.fetchNextPage();
      }
    });
  }

  setLoading = (val: boolean) => {
    this.loading = val;
  };

  handleFetchRows = (data: PagedResponseType<E>) => {
    this.rows = synchronouslyMapArray(data.results, this.processRecord);
    this.sortableFields = data.sortableFields;
    this.totalAmountOfRows = data.totalRecords;
    this.aggregateDetails = data.aggregateDetails;
    this.loading = false;

    // Scrolling variables
    this.nextPageToken = data.nextPageToken;
    this.lastFetchedItem = data.results.length;
  };

  fetchRows = async (request: RequestType) => {
    try {
      this.setLoading(true);

      const { endpoint, selector, query = {} } = request;

      const customHeaders = createHeader({
        timestamp: request.timeStamp
      });

      const newSendRequestParams = {
        request: {
          endpoint,
          selector,
          query
        },
        customHeaders,
        lang: this.rootStore.applicationStore.userStore.lang
      };

      // Keep it for next page fetch
      this.sendRequestParams = newSendRequestParams;

      this.handleFetchRows(
        (await this.rootStore.applicationStore.sendRequest<PagedResponseType<E>>(newSendRequestParams, false)).data
      );
    } catch (e) {
      this.error = e;

      this.setLoading(false);
    }
  };

  setRecordProcessor = (processRecord_: (record: E) => R) => {
    this.processRecord = processRecord_;
  };

  setIsFetchingNextPage = (val: boolean) => {
    this.isFetchingNextPage = val;
  };

  handleFetchNextPage = (data: PagedResponseType<E>) => {
    this.rows = [...this.rows, ...synchronouslyMapArray(data.results, this.processRecord)];
    this.aggregateDetails = data.aggregateDetails;

    // Scrolling variables
    this.nextPageToken = data.nextPageToken;
    this.lastFetchedItem = this.lastFetchedItem + data.results.length;
  };

  fetchNextPage = async () => {
    try {
      if (this.sendRequestParams && this.nextPageToken) {
        this.setIsFetchingNextPage(true);

        const { customHeaders } = this.sendRequestParams;

        const headers = { ...customHeaders, continuationToken: this.nextPageToken };

        this.handleFetchNextPage(
          (
            await this.rootStore.applicationStore.sendRequest<PagedResponseType<E>>(
              { ...this.sendRequestParams, customHeaders: headers },
              false
            )
          ).data
        );

        this.setIsFetchingNextPage(false);
      }
    } catch (e) {
      this.error = e;
      this.setIsFetchingNextPage(false);
    }
  };

  updateGivenRows = (deletedIds?: string[], setSelectedIds?: (newIds: string[]) => void) => {
    // Delete
    if (deletedIds && deletedIds.length > 0 && setSelectedIds) {
      let rowsClone = [...this.rows];

      for (const id of deletedIds) {
        const currentIndex = rowsClone.findIndex((row) => row.__id === id);
        rowsClone.splice(currentIndex, 1);
        this.totalAmountOfRows = this.totalAmountOfRows - 1;
      }

      this.rows = rowsClone;
      setSelectedIds([]);
    } else if (this.sendRequestParams) {
      // Add & update should trigger refetch to match any selected filters
      this.fetchRows(this.sendRequestParams?.request);
    }

    if (this.fetchCounts) {
      this.fetchCounts();
    }
  };

  setStopIndex = (stopIndex: number) => {
    if (stopIndex > this.lastShownItem) {
      this.lastShownItem = stopIndex;
    }
  };
}
