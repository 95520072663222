import React from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatPeriod } from '@zf/utils/src/date';

import css from './active-period.module.scss';
import Icon from '../Icon/Icon';

type Props = {
  startDateTime: string;
  endDateTime: string;
  isActive: boolean;
};

const ActivePeriod = (props: Props) => {
  const { isActive = false, startDateTime, endDateTime } = props;

  return (
    <div className={css['active-period']}>
      <Icon type="dot" color={colors[isActive ? 'green-600' : 'silver-400']} />
      <div>
        <Paragraph color={colors['graphite']}>{formatPeriod(startDateTime, endDateTime)}</Paragraph>
      </div>
    </div>
  );
};

export default ActivePeriod;
