import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { FeedType } from '@zf/api-types/feed';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { FeedItem } from '../../components/feed';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { useStore } from '../../hooks/useStore';

type Props = {
  feedItems: FeedType[];
};

const FeedCardBodyContent = (props: Props) => {
  const { feedItems } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [columns] = useState([
    {
      width: 400,
      label: '',
      dataKey: 'feedItem'
    }
  ]);

  const rows = feedItems.map((f) => {
    return {
      __id: f.id,
      feedItem: <FeedItem key={f.id} feedItem={f} />
    };
  });

  return feedItems.length === 0 ? (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/activity.png"
          alt="activity"
          title="activity"
          width="70"
          height="70"
        />
      }
      title={getTranslation('feed.no_activity_feed')}
    >
      {getTranslation('feed.no_activity_feed_descritpion')}
    </CardEmptyBody>
  ) : (
    <DynamicVirtualTable
      id="feed-table"
      tooltipId="feed-table-tip"
      rowHeight={68}
      rows={rows}
      columns={columns}
      hideHeader
      noSelect
    />
  );
};

export default observer(FeedCardBodyContent);
