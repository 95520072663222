import clone from 'clone';
import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { LocationRowType } from '../../features/property-group/detail-page/detail/locations-card';
import { updateServiceLocations } from './property-group';

type Props = {
  rowsToDelete: string[];
  rows: LocationRowType[];
  propertyGroup: PropertyGroupType;
  propertyEtag: string;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  setPropertyGroup: React.Dispatch<React.SetStateAction<PropertyGroupType | null>>;
  onComplete: (
    updatedRecords: ServiceLocationType[],
    deletedRecords?: LocationRowType[] | undefined
  ) => Promise<LocationRowType[]>;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { rowsToDelete, rows, propertyGroup, setPropertyGroup, setSelectedIds, onComplete } = props;

  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (rowsToDelete.length > 0) {
        try {
          const newRows = rows.filter((row) => {
            return !rowsToDelete.includes(row.__id);
          });

          const newLocationIds = newRows.map((row) => {
            return row.__id;
          });

          await updateServiceLocations(newLocationIds, propertyGroup.id, tenantReducer, i18n.lang);

          if (onComplete) {
            const deletedRecords = rows
              .filter((row) => {
                return rowsToDelete.includes(row.__id);
              })
              .map((record) => {
                return record.__locationEntity;
              });

            // Update property state
            const groupClone = clone(propertyGroup);
            groupClone.serviceLocationIds = newLocationIds;
            if (setPropertyGroup) setPropertyGroup(groupClone);

            onComplete([], deletedRecords as any);
            setSelectedIds([]);
          }

          notify.success({
            content: i18n.getTranslation('actions.property_group.delete_location_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.property_group.delete_location_failed'),
            error: e
          });
        }
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation('actions.property_group.delete_location_property')}</Paragraph>;
});
