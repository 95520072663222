import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';
import Center from '@zf/stella-react/src/helpers/Center';

import CompareButton from '../../../../components/graph/compare/CompareButton';
import GraphDateFilters from '../../../../components/graph/filters/GraphDateFilters';
import GroupByTabs from '../../../../components/graph/filters/GroupByTabs';
import css from './graph-header.module.scss';
import useServiceConsumptions from './hooks/useServiceConsumptions';
import DataGridCardHeaderSection from '../../../../components/DataGridCard/DataGridCardHeaderSection';

export default function GrapHeader() {
  const {
    groupByPeriod,
    startDateTime,
    endDateTime,
    isCompareMode,
    toggleCompareMode,
    setGroupByPeriod,
    setDates,
    setYears
  } = useServiceConsumptions();

  return (
    <DataGridCardHeaderSection>
      <div className={css['time-filters']}>
        <GroupByTabs isCompareMode={isCompareMode} groupByPeriod={groupByPeriod} setGroupByPeriod={setGroupByPeriod} />

        {!isCompareMode && (
          <Center type="vertical">
            <div className={css['line']} />
          </Center>
        )}

        {!isCompareMode && (
          <GraphDateFilters
            groupByPeriod={groupByPeriod}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            setDates={setDates}
            setYears={setYears}
          />
        )}
      </div>

      {!isCompareMode &&
        groupByPeriod !== aggregationFrequency.none &&
        groupByPeriod !== aggregationFrequency.daily && <CompareButton toggleCompareMode={toggleCompareMode} />}
    </DataGridCardHeaderSection>
  );
}
