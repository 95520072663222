import React from 'react';

import { ExportJobType } from '@zf/api-types/export-job';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../app-context';
import { SuspenseCardBody } from '../../../components/suspense';
import ExportJobFileFormatCardBody from './export-job-file-format-card-body';

type Props = {
  exportJob: ExportJobType;
};

export default function ExportJobFileFormatCard(props: Props) {
  const { i18n } = useAppContext();

  return (
    <Card id="file-format-card">
      <CardHeader>{i18n.getTranslation('import_jobs.file_format')}</CardHeader>
      <SuspenseCardBody type="grid">
        <ExportJobFileFormatCardBody {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
