import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../../app-context/app-context';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import BasicStats from '../../../../../../components/listPage/BasicStats';
import useValuesTable from './hooks/useValuesTable';
import ListChannelSelection from './ListChannelSelection';
import css from './values-table.module.scss';

type Props = {
  meter: MeterType;
  timeStamp: string;
};

const NoMeasurements = () => {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/graph.png"
          alt="graph"
          title="graph"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('meter.no_measurements')}
    />
  );
};

const NoConsumptions = () => {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/graph.png"
          alt="graph"
          title="graph"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('meter.no_consumptions')}
    >
      {i18n.getTranslation('meter.no_consumptions_desc')}
    </CardEmptyBody>
  );
};

export default function ValuesTable(props: Props) {
  const { i18n } = useAppContext();

  const {
    channels,
    consumptionChannels,
    outputType,
    selectedListModeChannel,
    selectedIds,
    sortBy,
    sortDirection,
    sortableFields,
    loading,
    error,
    rows,
    aggregateDetails,
    setSelectedIds,
    handleSort,
    setStopIndex,
    setSelectedListModeChannel
  } = useValuesTable(props.timeStamp, props.meter);

  const selectedRows = rows.filter((r) => {
    return selectedIds.includes(r.__id);
  });

  const columns = React.useMemo(
    () => [
      {
        width: 40,
        label: '',
        dataKey: 'isUnhealthy'
      },
      {
        width: 230,
        label: i18n.getTranslation('import_jobs.channel_identifier'),
        dataKey: 'externalChannelIdentifier'
      },
      {
        width: 200,
        label: i18n.getTranslation('general.value'),
        dataKey: 'value'
      },
      {
        width: 200,
        label: i18n.getTranslation('meter.unit_of_measure'),
        dataKey: 'unitOfMeasure'
      },
      {
        width: 300,
        label: i18n.getTranslation(outputType === 'consumptions' ? 'general.end_date' : 'meter.reading_date'),
        dataKey: 'endDateTime'
      },
      {
        width: 180,
        label: i18n.getTranslation('meter.utility_type'),
        dataKey: 'utilityType'
      },
      {
        width: 180,
        label: i18n.getTranslation('import_jobs.metering_type'),
        dataKey: 'meteringType'
      },
      {
        width: 200,
        label: i18n.getTranslation('import_jobs.direction'),
        dataKey: 'direction'
      },
      {
        width: 120,
        label: i18n.getTranslation('meter.time_of_use'),
        dataKey: 'timeOfUse'
      }
    ],
    [outputType]
  );

  let uom = '';

  if (rows.length > 0) {
    uom = rows[0].unitOfMeasure;
  }

  return (
    <>
      <ListChannelSelection
        meter={props.meter}
        selectedRows={selectedRows}
        channels={channels}
        consumptionChannels={consumptionChannels}
        selectedListModeChannel={selectedListModeChannel}
        outputType={outputType}
        setSelectedIds={setSelectedIds}
        setSelectedListModeChannel={setSelectedListModeChannel}
      />
      <>
        <div className={css[`values-table-${outputType}`]}>
          <DynamicVirtualTable
            id="measurements-table"
            tooltipId="measurements-table-tip"
            onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
            rowHeight={30}
            rows={rows}
            onSelectRow={setSelectedIds}
            selectedRows={selectedIds}
            loading={loading}
            error={error}
            noSelect={outputType === 'consumptions'}
            NoDataOverlay={outputType === 'measurements' ? NoMeasurements : NoConsumptions}
            totalAmountOfRows={rows.length}
            sortableFields={sortableFields}
            sort={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            columns={columns}
            singleSort
          />
        </div>
        {outputType === 'consumptions' && aggregateDetails.length > 0 && (
          <BasicStats aggregateDetails={aggregateDetails} uom={uom} />
        )}
      </>
    </>
  );
}
