import { locationSelection } from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import { regularMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { TabSlider, WizardSectionHeader } from 'design-system/Components';
import { SelectionTabItemType } from 'design-system/Components/TabSlider/Tab';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import MultipleLocations from '../../shared/location-section/MultipleLocations';
import css from './location-section.module.scss';
import SingleLocation from './SingleLocation';

const LocationsSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { locationSectionStore, regularMoveInWizardNavigator } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { locationSelectionType, setLocationSelectionType } = locationSectionStore;
  const { setActiveStep } = regularMoveInWizardNavigator;

  const tabItems = useRef<SelectionTabItemType<any>[]>([
    { id: locationSelection.single, icon: 'location', title: getTranslation('general.single') },
    {
      id: locationSelection.multiple,
      icon: 'locations',
      title: getTranslation('general.multiple')
    }
  ]);

  return (
    <WizardSection id="location-section" setActiveStep={() => setActiveStep(regularMoveInWizardSteps.locations)}>
      <WizardSectionHeader id={regularMoveInWizardSteps.locations} text={getTranslation('contracts.steps.location')} />

      <div className={css['location-section']}>
        <TabSlider
          id="location-selection-type"
          tabItems={tabItems.current}
          selectedTabItem={locationSelectionType}
          setSelectedItem={(locationSelectionType) =>
            setLocationSelectionType(locationSelectionType as locationSelection)
          }
          textAlign="left"
          fullWidth
        />

        {locationSelectionType === locationSelection.single ? (
          <SingleLocation />
        ) : (
          <MultipleLocations
            title={getTranslation('location.serviced_locations')}
            locationSectionStore={locationSectionStore}
          />
        )}
      </div>
    </WizardSection>
  );
};

export default observer(LocationsSection);
