import React from 'react';

import { formulaFunctionInputParameterReferenceType } from '@zf/api-types/enums';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';

type Props = {
  index: number;
  inputParameterIndex: number;
  outputParameterNames: string[];
  runtimeParameters: string[];
  referenceType: formulaFunctionInputParameterReferenceType;
  value: string;
  updateReferencetypeParameters: (
    inputParameterIndex: number,
    type: formulaFunctionInputParameterReferenceType,
    value: string | number
  ) => void;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function FormulaFunctionParameterValue(props: Props) {
  const {
    index,
    inputParameterIndex,
    outputParameterNames,
    runtimeParameters,
    referenceType,
    value,
    updateReferencetypeParameters
  } = props;

  if (referenceType === formulaFunctionInputParameterReferenceType.formulafunctionoutput) {
    const dropdownValues = outputParameterNames.reduce((acc: DropdownValuesType<string>[], parameter) => {
      // A new formula function has an empty output name
      if (parameter) {
        acc.push({
          text: parameter,
          value: parameter
        });
      }

      return acc;
    }, []);

    return (
      <SimpleDropdown
        id={`value-${index}-${inputParameterIndex}`}
        onChange={(val) => updateReferencetypeParameters(inputParameterIndex, referenceType, val[0])}
        values={dropdownValues}
        selectedValues={[value]}
        darkMode
      />
    );
  } else if (referenceType === formulaFunctionInputParameterReferenceType.runtimeparameter) {
    const dropdownValues = runtimeParameters.map((rp) => {
      return {
        text: rp,
        value: rp
      };
    });

    return (
      <SimpleDropdown
        id={`value-${index}-${inputParameterIndex}`}
        onChange={(val) => updateReferencetypeParameters(inputParameterIndex, referenceType, val[0])}
        values={dropdownValues}
        selectedValues={[value]}
        darkMode
      />
    );
  } else {
    // fixed
    return (
      <InlineInputFieldInput
        id={`value-${index}-${inputParameterIndex}`}
        onChange={(val) => updateReferencetypeParameters(inputParameterIndex, referenceType, val)}
        value={value}
        darkMode
      />
    );
  }
}
