import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import UninstallMeterDialog from '../../../actions/meters/uninstall-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';

type Props = {
  meter: MeterType;
  setMeter: React.Dispatch<React.SetStateAction<MeterType | null>>;
  refresh: () => void;
};

export default function UninstallMeterListItem(props: Props) {
  const { meter, setMeter, refresh } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const submetersResponse = useSuspenseSingleAPI<PagedResponseType<MeterType>>({
    request: {
      endpoint: '/md/Meters',
      query: { parentSerialNumber: meter.serialNumber }
    }
  });

  if (!submetersResponse.result) return null;

  return (
    <DropdownAction
      id="meter.uninstall"
      icon="cross"
      dialogTitle={i18n.getTranslation('actions.meter.uninstall_header')}
      dialogContent={
        <UninstallMeterDialog
          meter={meter}
          submeters={submetersResponse.result.data.results}
          ref={clickRef}
          validationRef={validationRef}
          setMeter={setMeter}
          refresh={refresh}
        />
      }
      onSubmit={onSubmit}
      dialogType="danger"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.uninstall_meter')}
    />
  );
}
