import classNames from 'classnames';
import clone from 'clone';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { incrementationType, meterStatus } from '@zf/api-types/enums';
import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import {
  AddMeasurementRequestType,
  AddMeasurementRowType,
  MeterPaginatedResult,
  MeterType
} from '@zf/api-types/master-data/meter';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { betweenDates, MIN_DATE } from '@zf/utils/src/date';

import DatePicker from '../../../../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import { METHODS } from '../../../../../utils/request';
import MeasurementRows from './measurement-rows';
import css from './terminate-style.module.scss';

type Props = {
  selectedRows: ContractRowType[];
  validationRef: MutableRefObject<ValidationRef | undefined>;
  setUpdatedRows: (updatedRows: ContractType[]) => void;
  refreshCounts: () => void;
};

const TerminateContractDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { setUpdatedRows, selectedRows, validationRef, refreshCounts } = props;

  const [mutationDateTime, setMutationDateTime] = useState<Moment>(moment(MIN_DATE));
  const [measurementRows, setMeasurementRows] = useState<AddMeasurementRowType[]>([]);
  const [hasMeasurement, setHasMeasurement] = useState(false);

  const { applicationStore, contractStore, meterStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;
  const { terminateContract } = contractStore;
  const { addMeasurementMeter } = meterStore.measurementService;

  const dateIsValid = JSON.stringify(mutationDateTime) !== JSON.stringify(moment(MIN_DATE));

  const validate = () => {
    if (validationRef.current) {
      let measurementsAreValid = true;

      if (hasMeasurement) {
        measurementsAreValid = !measurementRows.some((msmtr) =>
          msmtr.channels.some((chann) => typeof chann.value !== 'number' || isNaN(chann.value))
        );
      }

      validationRef.current.setIsError(!dateIsValid || !measurementsAreValid);
    }
  };

  // Get the meters installed on the locations associated with the selected contracts
  const filterMetersForLocations = (meters: MeterType[]) => {
    const results: AddMeasurementRowType[] = [];

    for (const meter of meters) {
      meter.statusHistory.forEach((history) => {
        if (
          betweenDates(history.startDateTime, history.endDateTime, mutationDateTime) &&
          history.meterStatus === meterStatus.installed
        ) {
          if (meter.channels.find((chan) => chan.incrementationType !== incrementationType.na)) {
            results.push({
              location: {
                serviceLocationId: history.serviceLocationId,
                serviceLocationAddress: history.installedAtAddress
              },
              meter: meter,
              channels: meter.channels
                .filter((chan) => chan.incrementationType !== incrementationType.na)
                .map((chan) => {
                  return { channel: chan, value: '' };
                })
            });
          }
        }
      });
    }

    return results;
  };

  const getMetersForContracts = async () => {
    const sluuids = selectedRows.flatMap((r) => r.__contractEntity.serviceLocations.map((l) => l.id || ''));

    return (
      await sendRequest<MeterPaginatedResult>({
        request: {
          method: METHODS.GET,
          query: { sluuids },
          endpoint: '/md/Meters'
        }
      })
    ).data.results;
  };

  useEffect(() => {
    validate();
  }, [mutationDateTime, hasMeasurement, measurementRows]);

  useEffect(() => {
    getMetersForContracts()
      .then((meterResponse) => setMeasurementRows(filterMetersForLocations(meterResponse)))
      .catch((error) => {
        notify.error({
          content: getTranslation('actions.contract.terminate.failed_getting_msmt_info'),
          error
        });
      });
  }, [mutationDateTime]);

  const createMeasurementObjects = () => {
    const result: AddMeasurementRequestType[] = [];

    measurementRows.forEach((row) => {
      row.channels.forEach((chann) => {
        // Only push filled in measurements
        if (chann.value) {
          result.push({
            meter: row.meter,
            endDateTime: clone(mutationDateTime).toISOString(),
            value: parseFloat(chann.value.toString()),
            channelId: chann.channel.externalIdentifier
          });
        }
      });
    });

    return result;
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      const contractsToHandle = selectedRows.map((row) => row.__contractEntity);

      try {
        const newContracts = await Promise.all(
          contractsToHandle.map((c) => terminateContract(c, mutationDateTime.toISOString(), false))
        );

        notify.success({
          content: getTranslation('actions.contract.terminate_success')
        });

        setUpdatedRows(newContracts);
        refreshCounts();
      } catch (error) {
        notify.error({
          content: getTranslation('actions.contract.terminate_failed'),
          error
        });
      }

      const measurements = createMeasurementObjects();

      if (measurements.length > 0) {
        try {
          await Promise.all(measurements.map((m) => addMeasurementMeter(m)));

          notify.success({
            content:
              measurements.length > 1
                ? getTranslation('actions.meter.add_measurements_success')
                : getTranslation('actions.meter.add_measurement_success')
          });
        } catch (error) {
          notify.error({
            content: getTranslation('actions.meter.add_measurement_error'),
            error
          });
        }
      }
    }
  }));

  return (
    <>
      <div className={classNames(css['input-wrapper2'])}>
        <Heading headingType="h5">{getTranslation('actions.contract.terminate')}</Heading>
        <InputContainer>
          <DatePicker
            id="mutation-date-time"
            onChange={(val) => setMutationDateTime(val)}
            value={mutationDateTime}
            placeholder={getTranslation('actions.contract.termination_date')}
            error={!dateIsValid}
          />
        </InputContainer>
      </div>

      <MeasurementRows
        measurementRows={measurementRows}
        hasMeasurement={hasMeasurement}
        mutationDateTime={mutationDateTime}
        setMeasurementRows={setMeasurementRows}
        setHasMeasurement={setHasMeasurement}
      />
    </>
  );
});

export default observer(TerminateContractDialog);
