import classNames from 'classnames';
import React from 'react';

import css from './spinner.module.scss';

export type SpinnerSize = 'xsmall' | 'small' | 'medium' | 'large';

type Props = {
  className?: string;
  inverted?: boolean;
  size?: SpinnerSize;
  centered?: boolean;
};

export default function Spinner(props: Props) {
  const { className, size = 'medium', inverted = false, centered = false } = props;

  const spinner = (
    <object
      className={classNames(
        css['spinner'],
        css[`spinner-${size}`],
        {
          [css['spinner-inverted']]: inverted
        },
        className
      )}
      aria-label="spinner"
    />
  );

  return centered ? <div className={css['centered']}>{spinner}</div> : spinner;
}
