import { observer } from 'mobx-react';
import React, { Dispatch, useCallback, useEffect } from 'react';
import { CustomerGroupType } from '@zf/api-types/master-data/customer-group';
import { useStore } from '../../../../hooks/useStore';
import SearchAutoFill, { CustomAutoFillProps } from 'components/input/SearchAutoFill';
import useAutoFill from 'components/input/useAutoFill';
import { CustomerGroupState } from '../Dialogs/ChangeCustomerGroupDialog';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

type Props = {
  className?: string;
  setState: Dispatch<Partial<CustomerGroupState>>;
  setValues: (values: DropdownValuesType<CustomerGroupType>[]) => void;
};

export type ProductAutofillProps = Props & CustomAutoFillProps<CustomerGroupType>;

const CustomerGroupsAutoFill = (props: ProductAutofillProps) => {
  const { id, selectedValues, initialValue, query, queryField, setState, onChange, onFocus, setValues } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const processAutoFillItem = useCallback((p: CustomerGroupType) => {
    return { value: p, text: p.name };
  }, []);

  const autoFillTools = useAutoFill({
    endpoint: '/md/customerGroups',
    processRecord: processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, values } = autoFillTools;

  /**
   * @description if list have only one customer group id, select that default.
   */

  const selectDefaultSelectedValues = () => {
    setState({ targetCustomerGroupId: values[0].value.id });
  };

  useEffect(() => {
    values.length === 1 && selectDefaultSelectedValues();
    setValues(values);
  }, [values]);

  return (
    <SearchAutoFill
      {...props}
      id={id}
      values={values}
      selectedValues={selectedValues}
      placeholder={getTranslation('customer_groups.select_a_customer_group')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export default observer(CustomerGroupsAutoFill);
