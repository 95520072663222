import clone from 'clone';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { managementRelationType, utilityType } from '@zf/api-types/enums';
import { PropertyGroupForecastingConfiguration } from '@zf/api-types/forecasting/propertygroup-forecasting-cfg';
import { CommunicationConfigurationType, PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

export type IntersectionType = OrganizationConfigType | CommunicationConfigurationType;

type ValuesType = {
  propertyGroup: PropertyGroupType | null;
  communicationConfiguration: Partial<CommunicationConfigurationType> | null;
  ownerId: string;
  managerId: string;
  availableUtilityTypes: utilityType[];
  propertyGroupBillingConfig: Partial<PropertyGroupBillingConfigurationType> | null;
  selectedUtilityTypes: utilityType[];
  propertyGroupForecastingConfig: Partial<PropertyGroupForecastingConfiguration> | null;
};

type State = ValuesType & {
  backup: ValuesType;
  isDirty: boolean;
  isLocked: boolean;
  organisationConfig: OrganizationConfigType | null;
};

type Action =
  | {
      type: 'INIT_STATE';
      billingConfig: PropertyGroupBillingConfigurationType;
      propertyGroup: PropertyGroupType;
      availableUtilityTypes: utilityType[];
      propertyGroupForecastingConfig: PropertyGroupForecastingConfiguration;
      organisationConfig: OrganizationConfigType;
    }
  | {
      type: 'SET_BILLING_CONFIG_VALUE';
      value: Partial<PropertyGroupBillingConfigurationType>;
    }
  | {
      type: 'SET_FCT_CONFIG_VALUE';
      utilityType: utilityType;
      consumerGroupId: string;
      consumerGroupCode: string;
    }
  | {
      type: 'SET_SELECTED_UTILITY_TYPES';
      value: utilityType[];
    }
  | {
      type: 'SET_COMM_CONFIG_VALUE';
      value: Partial<CommunicationConfigurationType>;
    }
  | {
      type: 'SET_OWNER';
      ownerId: string;
    }
  | {
      type: 'SET_MANAGER';
      managerId: string;
    }
  | {
      type: 'SET_LOCKED';
      isLocked: boolean;
    }
  | { type: 'RESET_ALL' }
  | {
      type: 'BACKUP';
      managementRelationsSuccess: boolean;
      billingConfigSuccess: boolean;
      commConfigSuccess: boolean;
      fctConfigSuccess: boolean;
    }
  | { type: 'RESTORE' };

const initialValues = {
  propertyGroup: null,
  communicationConfiguration: null,
  ownerId: '',
  managerId: '',
  availableUtilityTypes: [],
  propertyGroupBillingConfig: null,
  selectedUtilityTypes: [],
  propertyGroupForecastingConfig: null
};

const initialState: State = {
  ...initialValues,
  backup: clone(initialValues),
  isDirty: false,
  isLocked: false,
  organisationConfig: null
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INIT_STATE': {
      const owner = action.propertyGroup.defaultManagementRelations.find(
        (mr) => mr.managementRelationType === managementRelationType.owner
      );
      const manager = action.propertyGroup.defaultManagementRelations.find(
        (mr) => mr.managementRelationType === managementRelationType.propertymanager
      );

      const initializedValues = {
        propertyGroup: clone(action.propertyGroup),
        communicationConfiguration: clone(action.propertyGroup.communicationConfiguration),
        ownerId: owner?.customerId || '',
        managerId: manager?.customerId || '',
        availableUtilityTypes: action.availableUtilityTypes,
        propertyGroupBillingConfig: action.billingConfig,
        propertyGroupForecastingConfig: action.propertyGroupForecastingConfig,
        selectedUtilityTypes: Object.keys(action.propertyGroupForecastingConfig.consumerGroups) as utilityType[],
        organisationConfig: action.organisationConfig
      };

      return {
        ...state,
        ...initializedValues,
        backup: clone(initializedValues)
      };
    }

    case 'SET_COMM_CONFIG_VALUE': {
      return {
        ...state,
        isDirty: true,
        communicationConfiguration: { ...state.communicationConfiguration, ...action.value }
      };
    }

    case 'SET_BILLING_CONFIG_VALUE': {
      return {
        ...state,
        isDirty: true,
        propertyGroupBillingConfig: { ...state.propertyGroupBillingConfig, ...action.value }
      };
    }

    case 'SET_FCT_CONFIG_VALUE': {
      const cloned = clone(state.propertyGroupForecastingConfig);

      if (cloned?.consumerGroups) {
        cloned.consumerGroups[action.utilityType] = {
          id: action.consumerGroupId,
          code: action.consumerGroupCode
        };

        return {
          ...state,
          isDirty: true,
          propertyGroupForecastingConfig: cloned
        };
      }

      return state;
    }

    case 'SET_SELECTED_UTILITY_TYPES': {
      return { ...state, selectedUtilityTypes: action.value, isDirty: true };
    }

    case 'SET_OWNER': {
      return {
        ...state,
        isDirty: true,
        ownerId: action.ownerId
      };
    }

    case 'SET_MANAGER': {
      return {
        ...state,
        isDirty: true,
        managerId: action.managerId
      };
    }

    case 'SET_LOCKED': {
      return {
        ...state,
        isLocked: action.isLocked
      };
    }

    case 'RESET_ALL': {
      if (!state.communicationConfiguration || !state.organisationConfig) return { ...state };

      const newCommConfig = clone(state.communicationConfiguration);

      newCommConfig.address = clone(state.organisationConfig.address);
      newCommConfig.companyAccountNumber = state.organisationConfig.companyAccountNumber;
      newCommConfig.contactDetails = clone(state.organisationConfig.contactDetails);
      newCommConfig.logo = state.organisationConfig.logo;
      newCommConfig.primaryColor = state.organisationConfig.primaryColor;
      newCommConfig.secondaryColor = state.organisationConfig.secondaryColor;
      newCommConfig.vatAccountNumber = state.organisationConfig.vatAccountNumber;

      return {
        ...state,
        isDirty: true,
        communicationConfiguration: newCommConfig
      };
    }

    case 'BACKUP': {
      let newBackup: ValuesType = {
        ...clone(state.backup),
        propertyGroup: clone(state.propertyGroup)
      };

      if (action.managementRelationsSuccess) {
        newBackup = { ...newBackup, ownerId: state.ownerId, managerId: state.managerId };
      }

      if (action.billingConfigSuccess) {
        newBackup = { ...newBackup, propertyGroupBillingConfig: clone(state.propertyGroupBillingConfig) };
      }

      if (action.commConfigSuccess) {
        newBackup = { ...newBackup, communicationConfiguration: clone(state.communicationConfiguration) };
      }

      if (action.fctConfigSuccess) {
        newBackup = { ...newBackup, propertyGroupForecastingConfig: clone(state.propertyGroupForecastingConfig) };
      }

      return {
        ...state,
        propertyGroup: clone(state.propertyGroup),
        backup: newBackup,
        isDirty: false,
        isLocked: false
      };
    }

    case 'RESTORE':
      return {
        ...state,
        propertyGroup: clone(state.backup.propertyGroup),
        communicationConfiguration: clone(state.backup.communicationConfiguration),
        ownerId: state.backup.ownerId,
        managerId: state.backup.managerId,
        propertyGroupBillingConfig: clone(state.backup.propertyGroupBillingConfig),
        propertyGroupForecastingConfig: clone(state.backup.propertyGroupForecastingConfig),
        selectedUtilityTypes: Object.keys(
          state.backup.propertyGroupForecastingConfig?.consumerGroups || {}
        ) as utilityType[],
        isDirty: false
      };

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider: SettingsProvider, useTracked, useUpdate: useDispatch } = createContainer(useValue);
