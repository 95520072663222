import clone from 'clone';
import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import useDialog from '@zf/hooks/src/useDialog';
import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import EnableCostAllocationsDialog from '../../../../../../actions/property-group/enable-cost-allocations-dialog';
import ConfirmationActionButton from '../../../../../../components/Button/ConfirmationActionButton';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import { useStore } from '../../../../../../hooks/useStore';
import AllocationGroups from './allocation-groups';
import css from './cost-allocations.module.scss';

type Props = {
  propertyGroup: PropertyGroupType;
  propertyGroupBillingConfiguration: PropertyGroupBillingConfigurationType | undefined;
  setPropertyGroupBillingConfiguration: (newConfig: PropertyGroupBillingConfigurationType) => void;
};

export default function CostAllocations(props: Props) {
  const { propertyGroup, propertyGroupBillingConfiguration, setPropertyGroupBillingConfiguration } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { clickRef, onSubmit } = useDialog();

  const setCostAllocationsEnabled = (enabled: boolean) => {
    if (propertyGroupBillingConfiguration) {
      const billingConfigClone = clone(propertyGroupBillingConfiguration);
      billingConfigClone.costAllocationEnabled = enabled;
      setPropertyGroupBillingConfiguration(billingConfigClone);
    }
  };

  return propertyGroupBillingConfiguration ? (
    <ColumnsContainer className={css['cost-allocation-tab']} extraPadding={false}>
      {propertyGroupBillingConfiguration.costAllocationEnabled ? (
        <AllocationGroups groups={propertyGroupBillingConfiguration.calculationConfigurations || []} />
      ) : (
        <EmptyColumn
          image="cost-allocation1"
          title="Allocation groups"
          text={getTranslation('property_groups.tabs.billing.cost_allocation_disabled')}
          extra={
            <ConfirmationActionButton
              id="property_group_billing_config.cost_allocation_enable"
              dialogTitle={getTranslation('property_groups.tabs.billing.enable_cost_allocations')}
              dialogContent={
                <EnableCostAllocationsDialog
                  ref={clickRef}
                  propertyGroupId={propertyGroup.id}
                  onComplete={() => setCostAllocationsEnabled(true)}
                />
              }
              buttonPositive={getTranslation('general.enable')}
              onSubmit={onSubmit}
            />
          }
        />
      )}
    </ColumnsContainer>
  ) : null;
}
