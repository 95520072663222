import ResultsCounter from '@zf/heat-erp/src/components/Dropdown/ResultsCounter';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Paragraph } from '../Paragraph';
import { getShownValues } from './dropdown';
import css from './dropdown.module.scss';
import { DropdownGenericBaseType, DropdownValuesType } from './StellaDropdown';

export type shownValueType<T> = { value: DropdownValuesType<T>; index: number };

type Props<T> = {
  values: DropdownValuesType<T>[];
  selectedValues: string[];
  focusedIndex: number | undefined;
  emptyText: string;
  searchValue: string | undefined;
  viewType: 'search' | 'dropdown';
  totalAmountOfRecords?: number;
  setFocusedIndex: (value: number | undefined) => void;
  onClickItem: (i: number) => void;
  extraRight?: (value: T) => React.ReactNode;
};

export default function DropdownList<T extends DropdownGenericBaseType>(props: Props<T>) {
  const {
    values,
    viewType,
    selectedValues,
    focusedIndex,
    emptyText,
    searchValue,
    totalAmountOfRecords,
    extraRight,
    setFocusedIndex,
    onClickItem
  } = props;

  // We need this to avoid random scrolling when hovering with our cursor
  const [mouseOver, setMouseOver] = useState(false);

  let shownValues: shownValueType<T>[] = [];

  if (values) {
    if (viewType === 'search') {
      // Filtering is already done in API call
      shownValues = values.map((value, i) => {
        return { value, index: i };
      });
    } else {
      shownValues = getShownValues<T>(values, searchValue);
    }
  }

  useEffect(() => {
    if (!mouseOver && focusedIndex) {
      const listItem = document.getElementById(`listItem-${focusedIndex}`);
      if (listItem) listItem.scrollIntoView(true);
    }
  }, [focusedIndex]);

  return (
    <div>
      <ResultsCounter
        shownValues={shownValues}
        totalAmountOfRecords={totalAmountOfRecords}
        focusedIndex={focusedIndex}
        emptyText={emptyText}
      />
      <div
        className={css['dropdown-items']}
        role="presentation"
        onFocus={() => {}}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        {shownValues && shownValues.length > 0 ? (
          shownValues.map(({ value, index }, j) => {
            const isSelected = selectedValues.includes(value.value.id);

            return (
              <div
                id={`listItem-${j}`}
                testing-value={value.value}
                className={classNames(css['dropdown-item'], css['dropdown-item-non-semantic'], {
                  [css['selected']]: isSelected,
                  [css['focus']]: j === focusedIndex
                })}
                onClick={() => onClickItem(index)}
                key={index}
                role="option"
                tabIndex={-1}
                aria-selected={isSelected}
                onKeyDown={() => {}}
                onMouseOver={() => setFocusedIndex(j)}
                onFocus={() => setFocusedIndex(j)}
              >
                <div>{value.text}</div>
                <div>{extraRight && extraRight(value.value)}</div>
              </div>
            );
          })
        ) : (
          <div className={css['dropdown-item']}>
            <Paragraph>{emptyText}</Paragraph>
          </div>
        )}
      </div>
    </div>
  );
}
