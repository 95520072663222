import { observer } from 'mobx-react-lite';
import React from 'react';

import { AgingReportTransactionType } from '@zf/api-types/aging-report';
import useDialog from '@zf/hooks/src/useDialog';
import { formatDateForExport } from '@zf/utils/src/date';

import { exportArrayToExcel } from '../../../../components/Button/Excel/ExportToExcel';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import { State } from '../hooks/useCustomerAgingFilter';
import ExportDialog, { StateValues } from './dialogs/export-dialog';

type Props = {
  filterState: State;
};

const TransactionExport = (props: Props) => {
  const { applicationStore, agingReportStore } = useStore();
  const { filterState } = props;
  const { getTranslation } = applicationStore;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const fetchAllRows = async (
    array: AgingReportTransactionType[],
    filters: StateValues,
    continuationToken?: string
  ) => {
    await agingReportStore.agingReportService.getTransactionReport(filters, continuationToken).then(async (res) => {
      if (!res.nextPageToken) {
        array.push(...res.results);
      } else if (res.nextPageToken) {
        await fetchAllRows(array, filters, res.nextPageToken);
        array.push(...res.results);
      }
    });
  };

  const getExportArray = async (filters: StateValues) => {
    const exportArray = [];

    // Headers
    exportArray.push([
      getTranslation('customer.customer_id'),
      getTranslation('dashboard.customer_aging.overdue_transaction'),
      getTranslation('customer.customer'),
      getTranslation('dashboard.customer_aging.reference_number'),
      getTranslation('transactions.transaction_date'),
      getTranslation('cards.cash_position.transactionDueDateTime'),
      getTranslation('property_groups.property_group'),
      getTranslation('collection_flows.collection_flow'),
      getTranslation('dashboard.customer_aging.collection_step'),
      getTranslation('dashboard.customer_aging.open_credit'),
      getTranslation('dashboard.customer_aging.current_not_overdue'),
      '0-30',
      '31-60',
      '61-90',
      '>90'
    ]);

    // Data
    let selectedRows: AgingReportTransactionType[] = [];

    await fetchAllRows(selectedRows, filters);

    selectedRows.forEach((r) => {
      exportArray.push([
        r.customerAccountNumber,
        r.transactionType,
        r.displayName,
        r.referenceNumber,
        formatDateForExport(r.transactionDate),
        formatDateForExport(r.transactionDueDate),
        r.propertyGroups?.map((p) => p?.name).join(','),
        r.collectionCaseDetails?.workflowName,
        r.collectionCaseDetails?.nextStepName,
        r.openCredit,
        r.totalOpenNotOverdue,
        r.totalOverdue30D,
        r.totalOverdue60D,
        r.totalOverdue90D,
        r.totalOverdueOver90D
      ]);
    });

    return exportArray;
  };

  const arrayToExcel = async (filters: StateValues) => {
    await exportArrayToExcel('Transactions', async () => await getExportArray(filters), filters.date);
  };

  return (
    <DropdownAction
      dialogTitle={getTranslation('general.export_csv')}
      dialogContent={
        <ExportDialog
          filterState={filterState}
          showDate
          extraInfo={getTranslation('dashboard.customer_aging.export_transactions')}
          propertyGroupExtraInfo={getTranslation('dashboard.customer_aging.property_group_extra_info_transactions')}
          exportToExcel={arrayToExcel}
          validationRef={validationRef}
          ref={clickRef}
        />
      }
      id="aging_reports_export_transactions"
      icon="payment"
      actionType="dialog"
      onSubmit={onSubmit}
      buttonPositive={getTranslation('general.export')}
    >
      {getTranslation('dashboard.customer_aging.overdue_transactions')}
    </DropdownAction>
  );
};

export default observer(TransactionExport);
