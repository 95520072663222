import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import EmptyStateImg from '@zf/stella-react/src/atoms/Image/EmptyState/EmptyStateImg';

import InvoicePreviewWrapper from './InvoicePreviewWrapper';
import css from './no-invoice-preview.module.scss';

const NoInvoicePreview = () => {
  const { applicationStore } = useStore();

  const { getTranslation } = applicationStore;

  return (
    <InvoicePreviewWrapper>
      <CardEmptyBody
        className={css['no-pdf']}
        image={<EmptyStateImg image="invoices" title={getTranslation('invoice.no_preview')} />}
        title={getTranslation('invoice.no_pdf_preview')}
      />
    </InvoicePreviewWrapper>
  );
};

export default observer(NoInvoicePreview);
