import React from 'react';

import { ExistingEntitiesType, SelectedActivityType } from './activity-context/context';
import ContractWiz from './wizards/ContractWiz';
import CustomerWiz from './wizards/customer/CustomerWiz';
import LocationWiz from './wizards/LocationWiz';
import MeasurementWiz from './wizards/measurements/MeasurementWiz';

type Props = {
  selectedActivity: SelectedActivityType;
  existingEntities: ExistingEntitiesType;
};

export default function SelectedWizard(props: Props) {
  const { selectedActivity, existingEntities } = props;

  switch (selectedActivity) {
    case 'confirm_customer': {
      return <CustomerWiz existingCustomer={existingEntities.existingCustomer} />;
    }
    case 'confirm_location': {
      return <LocationWiz />;
    }
    case 'confirm_measurements': {
      return <MeasurementWiz />;
    }
    case 'confirm_contract': {
      return <ContractWiz />;
    }
    default:
      return null;
  }
}
