import DateRangePickerLabel from 'components/input/DateRangePickerLabel';
import { useStore } from 'hooks/useStore';
import React, { FC } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import MeterFilter from '../Components/Filter/MeterFilter';
import PropertyGroupsFilter from '../Components/Filter/PropertygroupsFilter';
import { Query } from './types';
import css from './right-filters.module.scss';

type Props = {
  getValues: UseFormGetValues<Partial<Query>>;
  setValue: UseFormSetValue<Partial<Query>>;
};

const RightFilters: FC<Props> = ({ getValues, setValue }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div className={css['right-filter-bare-dropdowns']}>
      <PropertyGroupsFilter getValues={getValues} setValue={setValue} />

      <MeterFilter getValues={getValues} setValue={setValue} />

      {/* <MeterModelFilter getValues={getValues} setValue={setValue} /> */}

      <DateRangePickerLabel
        id="detected-between"
        startDate={convertToMoment(getValues('noticedStartDateTime'))}
        endDate={convertToMoment(getValues('noticedEndDateTime'))}
        label={getTranslation('meter_issues.detected_between')}
        clearStart={true}
        setDates={(dates) => {
          if (
            convertMomentToISOString(dates[1]) === '9999-12-31T23:59:59.999Z' &&
            convertMomentToISOString(dates[0]) === '0001-01-01T00:00:00.000Z'
          ) {
            setValue('noticedEndDateTime', undefined);
            setValue('noticedStartDateTime', undefined);
          } else {
            setValue('noticedStartDateTime', convertMomentToISOString(dates[0]));
            setValue('noticedEndDateTime', convertMomentToISOString(dates[1]));
          }
        }}
      />
    </div>
  );
};

export default RightFilters;
