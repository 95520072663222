import { unitOfMeasure } from '@zf/api-types/enums';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import { EnumKeyType } from '../app-context/hooks/use-enum';

export function getTimeUoms(
  getEnum: <unitOfMeasure>(name: EnumKeyType, capitalize?: boolean) => DropdownValuesType<unitOfMeasure>[]
) {
  return getEnum<unitOfMeasure>('unitOfMeasure', false).filter(
    (uom) => uom.value === unitOfMeasure.day || uom.value === unitOfMeasure.month || uom.value === unitOfMeasure.year
  );
}
