import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Dropzone from '../Lang/Dropzone';
import File from './File';
import FileList from './FileList';
import css from './upload-input.module.scss';

type PropsFileUploadItem = {
  id: string;
  canDelete?: boolean;
  viewAsColumns?: boolean;
  files: MappedFilesType[];
  setFiles: (newFiles: MappedFilesType[]) => void;
};

export type MappedFilesType = {
  file: File;
  state: string;
};

const UploadInput = observer((props: PropsFileUploadItem) => {
  const { id, files, viewAsColumns = false, canDelete = true, setFiles } = props;

  const handleSelectFile = (selectedFiles: File[]) => {
    const mappedSelectedFiles = selectedFiles.map((file) => {
      return {
        file,
        state: 'none'
      };
    });
    setFiles([...files, ...mappedSelectedFiles]);
  };

  const removeFile = (index: number) => {
    const cloneArray = [...files];
    cloneArray.splice(index, 1);
    setFiles(cloneArray);
  };

  const handleDropzoneTrigger = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;

    inputElement.addEventListener('change', function () {
      if (!this.files) return;
      const toHandle: File[] = [...this.files];
      handleSelectFile(toHandle);
    });
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <div className={viewAsColumns ? css['wrapper'] : undefined}>
      <div>
        <Dropzone
          id={id}
          className={css['upload-dropzone']}
          onTrigger={handleDropzoneTrigger}
          onDrop={handleSelectFile}
        />
      </div>
      <div className={classNames(css['file-list'], viewAsColumns ? css['file-list-column'] : undefined)}>
        <FileList>
          {files.map((file, i: number) => {
            const fileName = file.file.name;
            return (
              <File key={i} file={file} onCancel={() => removeFile(i)} download={() => {}} canDelete={canDelete}>
                {fileName}
              </File>
            );
          })}
        </FileList>
      </div>
    </div>
  );
});

export default UploadInput;
