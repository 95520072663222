import React from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { DataExportType, ExportJobRowType, ExportJobType } from '@zf/api-types/export-job';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDateTime } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useListPage from '../../../app-context/hooks/use-listpage';
import EmptyLink from '../../../components/Link/EmptyLink';
import { ICON_COLORS } from '../../../constants/icons';

const useExportJobsListPage = (dataExports: DataExportType[]) => {
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const processRecord = (exportJob: ExportJobType): ExportJobRowType => {
    const dataExport: DataExportType | undefined = dataExports.find((dE) => dE.id === exportJob.dataExportId);

    return {
      __id: exportJob.id,
      __etag: exportJob._etag,
      __exportJobEntity: exportJob,
      identification: (
        <EmptyLink
          id={`${routedEntitySubjectType.exportjob}-${exportJob.id}`}
          url={`${rootUrl}/export-jobs/${exportJob.id}`}
        >
          {exportJob.identification}
        </EmptyLink>
      ),
      communicationType: enumReducer.getTranslation('dataExportCommunicationType', exportJob.communicationType),
      exportType: exportJob.exportType ? i18n.getTranslation(`exportType.${exportJob.exportType}`) : '-',
      status: (
        <StatusBadge color={ICON_COLORS[exportJob.status]} type="bare">
          {enumReducer.getTranslation('exportJobStatus', exportJob.status)}
        </StatusBadge>
      ),
      createdDateTime: formatDateTime(exportJob.createdDateTime),
      dataExportName: dataExport ? dataExport.name : ''
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    handleSort,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    setSelectedIds,
    setStopIndex,
    localTimeStamp,
    timeStamp
  } = useListPage<ExportJobType, ExportJobRowType>({ endpoint: '/logs/exportjob', processRecord, domain: 'exportjob' });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    handleSort,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    setSelectedIds,
    setStopIndex,
    localTimeStamp,
    timeStamp
  };
};

export default useExportJobsListPage;
