import { InfoBanner } from 'design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { serviceIsActive } from 'utils/serviceState';

import { utilityType } from '@zf/api-types/enums';
import { ContractedService, ContractServiceLocation } from '@zf/api-types/master-data/contract';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { groupBy } from '@zf/utils/src/array';

import css from './multi-location-card-body.module.scss';
import ServiceCounter from './ServiceCounter';

type Props = {
  serviceLocations: ContractServiceLocation[];
};

const MultiLocationCardBody = (props: Props) => {
  const { serviceLocations } = props;

  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { selectedContract, getFutureContractedLocations, getEarlyEndingLocations } = contractStore;

  const allActiveServices = serviceLocations.flatMap((l) => l.services?.filter((s) => serviceIsActive(s)) || []);
  const groupedByUtilityType: Record<utilityType, ContractedService[]> = groupBy(allActiveServices, 'utilityType');
  const futureLocations = getFutureContractedLocations(serviceLocations);
  const earlyEndingLocations = getEarlyEndingLocations(serviceLocations, selectedContract.contract.supplyEndDate);

  return (
    <>
      {futureLocations.length !== 0 && (
        <InfoBanner
          info={getTranslation('contracts.added_or_removed_locations', {
            amount: futureLocations.length + earlyEndingLocations.length
          })}
          quickTipId="contract.location-changes"
          quickTipText={getTranslation('contracts.added_or_removed_locations_quicktip')}
          color="blue"
          bold
        />
      )}

      <div className={css['multi-locations-body']}>
        <Paragraph bold>{getTranslation('location.amount_locations', { amount: serviceLocations.length })}</Paragraph>
        <FlexElements flexDirection="column" gap="8">
          {Object.keys(groupedByUtilityType).map((key) => {
            const utilityType = key as utilityType;
            const entries = groupedByUtilityType[utilityType];

            return <ServiceCounter key={utilityType} utilityType={utilityType} count={entries.length} />;
          })}
        </FlexElements>
      </div>
    </>
  );
};

export default observer(MultiLocationCardBody);
