import { observer } from 'mobx-react';
import React from 'react';

import { colors } from '@zf/utils/src/color';

import { Info } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import css from './customer-lang-pref-info.module.scss';

const CustomerLangPrefInfo = () => {
  const { applicationStore, customerStore } = useStore();
  const { selectedCustomerCulturePref } = customerStore;
  const { getTranslation, getEnumTranslation } = applicationStore;

  if (!selectedCustomerCulturePref) return null;

  return (
    <Info className={css['info-lang']} color={colors['silver-600']}>
      {getTranslation('entity_attachment.lang_for_customer', {
        culture: getEnumTranslation('culture', selectedCustomerCulturePref)
      })}
    </Info>
  );
};

export default observer(CustomerLangPrefInfo);
