import classNames from 'classnames';
import React, { ReactNode } from 'react';

import useWindowSize from '@zf/hooks/src/useWindowSize';

import DetailPageHeader from '../Heading/DetailPageHeader';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './tab-style.module.scss';
import TabButton from './TabButton';
import { TabItemType } from './types';

type Props = {
  id?: string;
  entityId: string;
  selectedTab: string;
  onSelectTab: (tabId: string) => void;
  children: ReactNode;
  tabItems: Array<TabItemType>;
  status?: JSX.Element;
  isScrollable?: boolean;
  actions?: ReactNode;
  className?: string;
  isDirty?: boolean;
  isLoading?: boolean;
  navigateComponent?: JSX.Element | null;
  noScroll?: boolean;
  iconName?: string;
  subTitle?: ReactNode;
  subTitle2?: ReactNode;
  actionsText?: string;
};

export default function TabContainer(props: Props) {
  const {
    id,
    isScrollable = true,
    actions,
    entityId,
    status,
    selectedTab,
    onSelectTab,
    children,
    tabItems = [],
    className,
    noScroll = false,
    navigateComponent,
    iconName,
    subTitle,
    subTitle2,
    actionsText,
  } = props;

  const [width] = useWindowSize();
  const [maxChildrenAllowed, setmaxChildrenAllowed] = React.useState(0);

  // Correct for queryparams
  const selectedTabName = selectedTab.split('?')?.[0] || selectedTab;

  const hasOneSubtitle = subTitle !== subTitle2 ? true : false;
  const hasTwoSubtitles = subTitle && subTitle2 ? true : false;

  // Used for css tab highlight
  const selected = tabItems.find((t) => t.id === selectedTabName);

  if (selected && typeof selected.isScrollable === 'undefined') selected.isScrollable = true;

  const overflow = (notInDropdown: number) => {
    return (
      <>
        {tabItems.map((tabItem, index) => {
          if (index < notInDropdown) {
            return (
              <TabButton
                key={index}
                type="detailpage"
                tabItem={tabItem}
                selectedTabId={selectedTabName}
                selected={!!selected}
                onSelectTab={onSelectTab}
              />
            );
          }
        })}
        <TooltipTrigger
          tooltip={
            <div className={css['tooltip-content']}>
              {tabItems.map((tabItem, index) => {
                if (index >= notInDropdown) {
                  return (
                    <TabButton
                      key={index}
                      type="detailpage"
                      tabItem={tabItem}
                      selectedTabId={selectedTabName}
                      selected={!!selected}
                      onSelectTab={onSelectTab}
                    />
                  );
                }
              })}
            </div>
          }
          trigger="click"
        >
          <div className={classNames(css['title'], css['overflow-button'])}>&middot;&middot;&middot;</div>
        </TooltipTrigger>
      </>
    );
  };

  const noOverflow = () => {
    return (
      <>
        {tabItems.map((tabItem, index) => {
          return (
            <TabButton
              key={index}
              type="detailpage"
              tabItem={tabItem}
              selectedTabId={selectedTabName}
              selected={!!selected}
              onSelectTab={onSelectTab}
            />
          );
        })}
      </>
    );
  };

  React.useEffect(() => {
    const containerWidth = width / 1.5;
    const firstChildWidth = 200;
    const newMaxChildrenAllowed = Math.floor(containerWidth / firstChildWidth);

    setmaxChildrenAllowed(newMaxChildrenAllowed);
  }, [width]);

  return (
    <div
      id={id}
      className={classNames(
        css[`tab-container`],
        {
          [css['tab-container-subtitle']]: hasOneSubtitle && !selected?.hasListPage,
          [css['tab-container-subtitles']]: hasTwoSubtitles && !selected?.hasListPage
        },
        className
      )}
    >
      <div className={css['tab-header-wrapper']}>
        <div className={css['header-wrapper']}>
          <DetailPageHeader
            actionsText={actionsText}
            title={entityId}
            actions={actions}
            status={status}
            iconName={iconName}
            subTitle={subTitle}
            subTitle2={subTitle2}
          />
        </div>

        <div className={css['header-tabs-row']}>
          <div className={css['tab-items']}>
            {tabItems.length <= maxChildrenAllowed ? noOverflow() : overflow(maxChildrenAllowed)}
            {navigateComponent}
          </div>
        </div>
      </div>

      <div
        className={classNames(css['tab-content'], {
          [css['tab-content-noscroll']]: !isScrollable || noScroll || (selected && !selected.isScrollable)
        })}
      >
        {children}
      </div>
    </div>
  );
}
