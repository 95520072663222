import { useState } from 'react';
import { ListPage, ListPageFilter, ListPageStore } from '../../../../../components/listPageV2/types';
import { TransactionType } from '@zf/api-types/transactions';

export function useListPageProvider(store: ListPageStore) {
  const [listPageStore, setListPageStore] = useState(store);

  const { listPage, filter } = listPageStore;

  const setFilter = (newFilter: Partial<ListPageFilter>) =>
    setListPageStore({
      ...listPageStore,
      filter: { ...filter, ...newFilter },
      // Close side panel when changing filters
      listPage: {
        ...listPage,
        selectedIds: [],
        selectedRows: [],
        activatedRows: [],
        showSidePanel: false
      }
    });

  const setListPage = (newListPage: Partial<ListPage>) =>
    setListPageStore({ ...listPageStore, listPage: { ...listPage, ...newListPage } });

  const updateRows = (updatedRowEntities: TransactionType[]) =>
    setListPage({ ...listPage, updatedRows: updatedRowEntities });

  const getSidePanelData = (): TransactionType => {
    return listPage.selectedRows[0]?.__entity;
  };

  const closeSidePanel = () =>
    setListPage({
      ...listPage,
      selectedIds: [],
      selectedRows: [],
      showSidePanel: false,
      activatedRows: [],
      showOnActivate: false
    });

  return {
    store: { filter, listPage },
    getSidePanelData,
    setListPageStore,
    setFilter,
    setListPage,
    updateRows,
    closeSidePanel
  };
}
