import { CancelTokenSource } from 'axios';
import clone from 'clone';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { ZFErrorType } from '../../../api-types/general';
import { useAppContext } from '../app-context';
import { RequestHeadersType, RequestType } from '../types/Request';
import { createHeader, generateCancelToken, RequestResultType, sendRequest } from '../utils/request';
import { uiCulture } from '@zf/api-types/enums';

export type useRequestParams = {
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
  request: RequestType;
  updateInterval?: number;
  Accept?: string;
  tenant?: string;
  organization?: string;
  mayExecute?: boolean;
};

type ReturnType<T> = {
  result: RequestResultType<T> | null;
  error: ZFErrorType | null;
};

type StateType<T> = {
  request?: RequestType;
  result: RequestResultType<T> | null;
  error: ZFErrorType | null;
  continueFetching: string | null;
  pagedResponse: boolean;
  pageCycleFinished: boolean;
};

function fetchPage<E>(
  request: RequestType,
  headers: RequestHeadersType,
  source: CancelTokenSource,
  lang: uiCulture,
  useCaching: boolean,
  nextPageToken?: string | null,
  hasOneDataLevel?: boolean,
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream' | undefined,
  mock = false
) {
  if (nextPageToken) {
    headers = { ...headers, continuationToken: nextPageToken };
  }

  return sendRequest<E>(
    {
      request: request,
      customHeaders: headers,
      responseType,
      lang,
      donotCache: !useCaching
    },
    hasOneDataLevel,
    mock
  );
}

export default function useSingleAPI<T>(
  params: useRequestParams,
  hasOneDataLevel?: boolean,
  useCaching = true,
  mock = false
): ReturnType<T> {
  const { tenantReducer, i18n } = useAppContext();
  const { lang } = i18n;
  const { organization, tenant } = tenantReducer.state;
  const stateReducer = createStateReducer<StateType<T>, Partial<StateType<T>>>();
  const [state, dispatchState] = React.useReducer(stateReducer, {
    result: null,
    error: null,
    continueFetching: null,
    pagedResponse: false,
    pageCycleFinished: false
  });

  const executeFetch = async (source: CancelTokenSource, nextPageToken?: string | null) => {
    if (typeof params.mayExecute === 'undefined' || params.mayExecute === true) {
      let orgId: string | undefined = '';

      if (params.organization) {
        orgId = params.organization;
      } else if (organization) {
        orgId = organization.organizationId ? organization.organizationId : organization.id;
      }

      return fetchPage<T>(
        params.request,
        createHeader({
          organization: orgId,
          tenant: params.tenant ? params.tenant : tenant?.id,
          timestamp: params.request.timeStamp,
          Accept: params.Accept
        }),
        source,
        lang,
        useCaching,
        nextPageToken,
        hasOneDataLevel,
        params.responseType,
        mock
      );
    }

    return null;
  };

  React.useEffect(() => {
    let timeout: null | number = null;
    const source = generateCancelToken();

    const req = async () => {
      try {
        if (state.pagedResponse && !state.continueFetching && useCaching) return;
        const result = await executeFetch(source, state.continueFetching);

        let resultData;

        if (result) resultData = result.data as Record<string, any>;

        // We have a results array (PagedResponseType) when hasNextPage exists
        if (result && resultData && typeof resultData.hasNextPage === 'boolean') {
          resultData = resultData as PagedResponseType<T>;
          let resultClone;
          let accumalator: any[];
          if (state.result && state.pagedResponse) {
            resultClone = clone(state.result) as Record<string, any>;

            const clonedDataCasted = resultClone.data as PagedResponseType<T>;
            accumalator = [...clonedDataCasted.results];
          } else {
            resultClone = result;
            accumalator = [];
          }

          accumalator.push(...resultData.results);
          resultClone.data.results = accumalator;

          // If another fetch is needed
          if (resultData.hasNextPage) {
            dispatchState({
              result: resultClone as RequestResultType<T>,
              continueFetching: resultData.nextPageToken,
              pagedResponse: true
            });
          } else {
            dispatchState({ result: resultClone as RequestResultType<T>, continueFetching: null });
          }
        } else {
          // Only one entity is returned
          dispatchState({ result: result });
        }

        if (params.updateInterval) {
          timeout = window.setTimeout(req, params.updateInterval);
        }
      } catch (error) {
        // @ts-ignore
        dispatchState({ error: error, result: null });
      }
    };

    req();

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }

      source.cancel(`Request: ${params.request.endpoint} cancelled.`);
    };
  }, [JSON.stringify(params), state.continueFetching, tenantReducer.state]);

  return {
    result: state.result,
    error: state.error
  };
}
