import React, { useEffect, useState } from 'react';

import { ConfiguredPluginType, PluginTriggerResponse } from '@zf/api-types/integration/plugin';
import useDialog from '@zf/hooks/src/useDialog';
import NewStatusBadge from '@zf/stella-react/src/atoms/Badge/NewStatusBadge';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import { DateRange } from '../../../app-context/stores/integration/logging/IntegrationLoggingStore';
import Button from '../../../components/Button/Button';
import DateRangePicker from '../../../components/input/DateRangePicker';
import { dialog } from '../../../events/dialog-events';
import JSONDialog from '../../../features/export-jobs/detail-page/json-dialog';
import { useStore } from '../../../hooks/useStore';
import LoggingDialog from '../dialogs/LoggingDialog';
import css from '../integration-logging.module.scss';

export type StateValues = {
  searchValue: string;
  triggerFilter: DateRange;
  scheduledFilter: DateRange;
  plugins: ConfiguredPluginType[];
  configuredPlugin: string;
  selectedIds: string[];
};

const useIntegrationLogging = (subjectType: string, subjectId: string, refresh: boolean) => {
  const { integrationStore, applicationStore } = useStore();
  const { integrationLoggingStore } = integrationStore;
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { clickRef } = useDialog();
  const { authReducer } = useAppContext();
  const { isSuperUser } = authReducer;

  useEffect(() => {
    if (isSuperUser()) {
      integrationLoggingStore.setInfiniAPIService(processRecord, refresh);
      integrationLoggingStore.setSubject(subjectId, subjectType);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(integrationLoggingStore.interval);
      //store.integrationLoggingStore = new IntegrationLoggingStore(store);
    };
  }, []);

  const [columns] = useState([
    {
      width: 180,
      label: getTranslation('general.plugin'),
      dataKey: 'plugin'
    },
    {
      width: 180,
      label: getTranslation('general.type'),
      dataKey: 'type'
    },
    {
      width: 280,
      label: getTranslation('general.scheduled'),
      dataKey: 'scheduled'
    },
    {
      width: 280,
      label: getTranslation('general.triggered'),
      dataKey: 'triggered'
    },
    {
      width: 100,
      label: getTranslation('general.payload'),
      dataKey: 'payload'
    },
    {
      width: 100,
      label: getTranslation('general.attempts'),
      dataKey: 'attempts'
    },
    {
      width: 100,
      label: getTranslation('general.logging'),
      dataKey: 'logging'
    },
    {
      width: 180,
      label: getTranslation('general.status'),
      dataKey: 'status'
    }
  ]);

  const statusErroneous = (
    <NewStatusBadge status_={getTranslation('cards.integration_logging.erroneous')} color={colors['red-600']} />
  );

  const statusFinished = (
    <NewStatusBadge status_={getTranslation('cards.integration_logging.finished')} color={colors['green-600']} />
  );

  const statusNotYetStarted = (
    <NewStatusBadge status_={getTranslation('cards.integration_logging.not_yet_started')} color={colors['blue-600']} />
  );

  const renderStatus = (plugin: PluginTriggerResponse) => {
    if (plugin.erroneous) {
      return statusErroneous;
    } else if (plugin.finished) {
      return statusFinished;
    }

    return statusNotYetStarted;
  };

  const openJsonDialog = async (id: string, subjectType: string, subjectId: string) => {
    await integrationLoggingStore.setSelectedPluginDetail(id, subjectType, subjectId);
    dialog.right({
      title: getTranslation('general.payload'),
      content: (
        <JSONDialog
          json={
            integrationLoggingStore.selectedPluginDetail
              ? integrationLoggingStore.selectedPluginDetail.payload
              : JSON.parse('')
          }
        />
      ),
      onSubmit: () => {
        // close dialog
      },
      isDismissDialog: true
    });
  };

  const openLoggingDialog = async (id: string, subjectType: string, subjectId: string) => {
    await integrationLoggingStore.setSelectedPluginDetail(id, subjectType, subjectId);
    dialog.right({
      title: getTranslation('general.logging'),
      content: <LoggingDialog ref={clickRef} />,
      onSubmit: () => {
        // close dialog
      },
      isDismissDialog: true
    });
  };

  const processRecord = (plugin: PluginTriggerResponse) => {
    return {
      __id: plugin.id,
      __entity: plugin,
      plugin: integrationLoggingStore.renderPluginName(plugin.configuredPluginId),
      type: getEnumTranslation('pluginTriggerDataType', plugin.type),
      scheduled: plugin.scheduled,
      triggered: plugin.triggered === '0001-01-01T00:00:00' ? '-' : plugin.triggered,
      payload: (
        <Button
          id={`${plugin.id}-json`}
          className={css['no-shadow']}
          icon="code"
          type="text"
          size="small"
          onClick={() => openJsonDialog(plugin.id, plugin.entitySubjectType, plugin.entitySubjectId)}
        />
      ),
      attempts: plugin.attempts,
      logging: (
        <Button
          id={`${plugin.id}-log`}
          className={css['no-shadow']}
          icon="code"
          type="text"
          size="small"
          onClick={() => openLoggingDialog(plugin.id, plugin.entitySubjectType, plugin.entitySubjectId)}
        />
      ),
      status: renderStatus(plugin)
    };
  };

  const filterInputs = [
    <React.Fragment key="filters">
      <Paragraph className={css['spacing']} bold>
        {getTranslation('general.triggered')}
      </Paragraph>
      <DateRangePicker
        id="next-action-due-filter"
        startDate={convertToMoment(integrationLoggingStore.filter.triggerFilter.startDate)}
        endDate={convertToMoment(integrationLoggingStore.filter.triggerFilter.endDate)}
        setDates={(dates) => {
          integrationLoggingStore.setFilters({
            triggerFilter: {
              startDate: convertMomentToISOString(dates[0]),
              endDate: convertMomentToISOString(dates[1])
            }
          });
        }}
        renderTopLvl={false}
      />
      <Paragraph className={css['spacing']} bold>
        {getTranslation('general.scheduled')}
      </Paragraph>
      <DateRangePicker
        id="next-action-due-filter"
        startDate={convertToMoment(integrationLoggingStore.filter.scheduledFilter.startDate)}
        endDate={convertToMoment(integrationLoggingStore.filter.scheduledFilter.endDate)}
        setDates={(dates) => {
          integrationLoggingStore.setFilters({
            scheduledFilter: {
              startDate: convertMomentToISOString(dates[0]),
              endDate: convertMomentToISOString(dates[1])
            }
          });
        }}
        renderTopLvl={false}
      />
    </React.Fragment>
  ];

  return {
    columns,
    filterInputs,
    loading: integrationLoggingStore.infiniApiService_?.loading,
    error: integrationLoggingStore.infiniApiService_?.error,
    rows: integrationLoggingStore.infiniApiService_?.rows,
    sortableFields: integrationLoggingStore.infiniApiService_?.sortableFields,
    sortBy: integrationLoggingStore.createRequestService.sortBy,
    sortDirection: integrationLoggingStore.createRequestService.sortDirection,
    dropdownFormats: integrationLoggingStore.dropdownValues,
    totalAmountOfRows: integrationLoggingStore.infiniApiService_?.totalAmountOfRows,
    updateGivenRows: integrationLoggingStore.infiniApiService_?.updateGivenRows,
    handleSort: integrationLoggingStore.handleSort,
    resetFilter: integrationLoggingStore.resetFilter
  };
};

export default useIntegrationLogging;
