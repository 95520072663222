import { observer } from 'mobx-react';
import React from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useStore } from '../../hooks/useStore';
import css from './no-tenants.module.scss';

const NoTenants = ({ state }: { state: string }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div>
      <div className={css['no-tenants']}>
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/envelope.png"
          alt="no tenants"
          title="no tenants"
        />
        <Paragraph>{getTranslation(`tenant.${state}`)}</Paragraph>
        <Paragraph>{getTranslation('tenant.questions')}</Paragraph>
      </div>
      <div className={css['contact-info']}>
        <Paragraph>{getTranslation('general.zerofriction')}</Paragraph>
        <Paragraph>{getTranslation('general.zerofriction_tel')}</Paragraph>
        <Paragraph>{getTranslation('general.zerofriction_mail')}</Paragraph>
      </div>
    </div>
  );
};

export default observer(NoTenants);
