import moment from 'moment';
import React from 'react';

import { DataProviderPropertiesType, ParametersType } from '@zf/api-types/data-provider';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { TimeZoneProps } from '@zf/stella-react/src/atoms/Dropdown/stella-time-zone-dropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { FloatInputFieldProps } from '@zf/stella-react/src/atoms/InputField/stella-float-input';
import { PasswordInputProps } from '@zf/stella-react/src/atoms/InputField/stella-password-input';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { EnumKeyType } from '../../app-context/hooks/use-enum';
import TimeZoneDropDown from '../Dropdown/TimeZoneDropdown';
import FloatInput from '../input/FloatInput';
import InputField, { InputFieldProps } from '../input/InputField';
import PasswordInput from '../input/PasswordInput';
import DatePicker, { DatePickerProps } from '../Lang/DatePicker';
import SimpleDropdown from '../Lang/SimpleDropdown';
import css from './input-generator.module.scss';

type Props = {
  property: DataProviderPropertiesType;
  value: any;
  parameterIndex?: number;
  propertyIndex: number;
  placeholder?: string;
  setParameters: (value: ParametersType[keyof ParametersType], dataKey: keyof ParametersType) => void;
};

let InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
let InlineInputFloatInput = InlineInputField<FloatInputFieldProps>(FloatInput);
let InlineInputFieldPassword = InlineInputField<PasswordInputProps>(PasswordInput);
let InlineEnumDropdown = InlineInputField<SimpleDropdownProps<string>>(SimpleDropdown);
let InlineTimeZoneDropdown = InlineInputField<TimeZoneProps>(TimeZoneDropDown);
let InlineInputFieldDatePicker = InlineInputField<DatePickerProps>(DatePicker);

export default function InputGenerator(props: Props) {
  const { property, value, parameterIndex, propertyIndex, placeholder, setParameters } = props;
  const { enumReducer, tenantReducer } = useAppContext();
  const { tenant } = tenantReducer.state;

  if (placeholder) {
    InlineInputFieldInput = InputField;
    InlineInputFloatInput = FloatInput;
    InlineInputFieldPassword = PasswordInput;
    InlineEnumDropdown = SimpleDropdown;
    InlineTimeZoneDropdown = TimeZoneDropDown;
    InlineInputFieldDatePicker = DatePicker;
  }

  let input: JSX.Element;

  if (property.readOnly) {
    if (property.name === 'emailAddressKey') {
      input = (
        <Paragraph key={`paragraph-field-${parameterIndex}-${propertyIndex}`}>{`${tenant?.name.replace(
          ' ',
          ''
        )}.${value}${
          process.env.NODE_ENV === 'production'
            ? '@meteringdata.zerofriction.co'
            : '@meteringdata-local.zerofriction.co'
        }`}</Paragraph>
      );
    } else {
      input = (
        <Paragraph className={css['input-generator-text']} key={`paragraph-field-${parameterIndex}-${propertyIndex}`}>
          {value}
        </Paragraph>
      );
    }
  } else {
    if (property.name === 'timeZone') {
      input = (
        <InlineTimeZoneDropdown
          id={`${property.name}.timezone.index-${parameterIndex}-${propertyIndex}`}
          key={`${property.name}.timezone.index-${parameterIndex}-${propertyIndex}`}
          selectedValues={[value as string]}
          error={!value && property.required}
          onChange={(val) => setParameters(val[0], property.name)}
        />
      );
    } else if (property.name === 'password') {
      input = (
        <InlineInputFieldPassword
          id={`${property.name}.password.index-${parameterIndex}-${propertyIndex}`}
          key={`${property.name}.password.index-${parameterIndex}-${propertyIndex}`}
          value={value as string}
          error={!value && property.required}
          onChange={(val: string) => setParameters(val, property.name)}
        />
      );
    } else if (property.propertyType.startsWith('enum:')) {
      input = (
        <InlineEnumDropdown
          id={`${property.name}.dropdown.index-${parameterIndex}-${propertyIndex}`}
          key={`${property.name}.dropdown.index-${parameterIndex}-${propertyIndex}`}
          values={enumReducer.getEnum<string>(property.propertyType.split(':')[1] as EnumKeyType)}
          selectedValues={[value as string]}
          error={!value && property.required}
          onChange={(val) => setParameters(val[0], property.name)}
          placeholder={placeholder}
        />
      );
    } else {
      switch (property.propertyType) {
        case 'integer':
        case 'number':
          input = (
            <InlineInputFloatInput
              id={`${property.name}.number.index-${parameterIndex}-${propertyIndex}`}
              key={`${property.name}.number.index-${parameterIndex}-${propertyIndex}`}
              value={value as number}
              error={!value && property.required}
              onChange={(val) => setParameters(val, property.name)}
              placeholder={placeholder}
              postfix={property.name === 'percentage' ? '%' : ''}
            />
          );
          break;
        case 'boolean':
          input = (
            <CheckBox
              id={`${property.name}.checkbox.index-${parameterIndex}-${propertyIndex}`}
              key={`${property.name}.checkbox.index-${parameterIndex}-${propertyIndex}`}
              checked={value as boolean}
              onChange={(val) => setParameters(val, property.name)}
            />
          );
          break;
        case 'datetime':
          input = (
            <InlineInputFieldDatePicker
              id={`${property.name}.date.index-${parameterIndex}-${propertyIndex}`}
              key={`${property.name}.date.index-${parameterIndex}-${propertyIndex}`}
              value={value ? moment(value as string) : null}
              onChange={(val) => setParameters(val.toISOString(), property.name)}
              error={!value && property.required}
              placeholder={placeholder}
            />
          );
          break;
        case 'string':
        default:
          input = (
            <InlineInputFieldInput
              id={`${property.name}.string.index-${parameterIndex}-${propertyIndex}`}
              key={`${property.name}.string.index-${parameterIndex}-${propertyIndex}`}
              value={value as string}
              error={!value && property.required}
              onChange={(val) => setParameters(val, property.name)}
              placeholder={placeholder}
            />
          );
          break;
      }
    }
  }

  return input;
}
