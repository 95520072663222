import React from 'react';

import { navigate } from '@reach/router';
import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteTask } from './tasks';

type Props = {
  // Detail page
  task?: MoveRequestType;

  // List page
  selectedRows?: TaskRowType[];
  setDeletedRows?: (updatedRows: MoveRequestType[], deletedRows: Partial<MoveRequestType>[]) => void;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { task, selectedRows, setDeletedRows, refreshCounts } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (task) {
          await deleteTask(task.id, tenantReducer, i18n.lang);
          navigate(`${rootUrl}/tasks`);
        } else if (selectedRows && setDeletedRows && refreshCounts) {
          await Promise.all(selectedRows.map((r) => deleteTask(r.__entity.id, tenantReducer, i18n.lang)));
          setDeletedRows(
            [],
            selectedRows.map((r) => {
              return r.__entity;
            })
          );
          refreshCounts();
        }

        notify.success({
          content: i18n.getTranslation('tasks.delete_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tasks.delete_fail'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation(task ? 'tasks.delete_paragraph' : 'tasks.delete_paragraph_multi')}</Paragraph>;
});
