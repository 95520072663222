import React from 'react';

import { colors } from '@zf/utils/src/color';

import { Paragraph } from '../../Foundation';
import css from './empty-state.module.scss';

type Props = {
  imgName: string;
  title: string;
  description: string | JSX.Element;
  horizontal?: boolean;
};

export default function EmptyState(props: Props) {
  const { imgName, title, description, horizontal } = props;

  return (
    <div className={css[`empty-state${horizontal ? '-horizontal' : '-vertical'}`]}>
      <img
        src={`https://cdn.zerofriction.co/shared/assets/emptyStates/${imgName}.png`}
        alt={imgName}
        width="80"
        height="80"
      />
      <div className={css['text-part']}>
        <Paragraph color={colors['silver-700']} bold>
          {title}
        </Paragraph>
        <Paragraph color={colors['silver-600']}>{description}</Paragraph>
      </div>
    </div>
  );
}
