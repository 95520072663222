import React from 'react';
import FilterBar from 'components/Filters/FilterBar';
import { useStore } from 'hooks/useStore';
import useCustomerTabs from 'features/customer/hooks/useCustomerTabs';
import useRefresh from 'app-context/hooks/use-refresh';
import useCustomerGroupsFilter from '../hooks/useCustomerGroupFilter';
import AddCustomerGroupListPageButton from '../actions/list-page/AddCustomerGroupListPageButton';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import { Icon } from '../../../../components/Icon';
import { colors } from '@zf/utils/src/color';

type Props = {
  search: string;
};

const CustomerGroupFilterBar = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { searchValue, onSearch, overviewCount, onFilter } = useCustomerGroupsFilter(props.search);
  const { refresh } = useRefresh();
  const tabItems = useCustomerTabs();
  return (
    <FilterBar
      actions={[<AddCustomerGroupListPageButton key="action-button.customerGroupList" />]}
      tabItems={tabItems}
      defaultTab="customer-groups"
      refreshPage={refresh}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="customer" />}
          count={overviewCount.all}
          title={getTranslation('customer_groups.filter.all_customer_groups')}
          activeColor={colors['blue-400']}
          active={true}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('customer_groups.search')}
      searchValue={searchValue}
      searchDebounce={500}
    />
  );
};

export default CustomerGroupFilterBar;
