import classNames from 'classnames';
import React from 'react';

import { SimpleTooltip } from '@zf/heat-erp/src/design-system/Components';
import { colors } from '@zf/utils/src/color';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import Paragraph from './Paragraph';
import css from './paragraph.module.scss';

type Props = {
  id: string;
  tooltipFor?: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  deleted?: boolean;
  bold?: boolean;
  tabIndex?: number;
  color?: string;
  usedInTable?: boolean;
};

export default React.memo(function OverflowParagraph(props: Props) {
  const {
    id,
    children,
    className,
    color,
    tooltipFor,
    bold = false,
    deleted = false,
    usedInTable = false,
    tabIndex = -1
  } = props;
  const { style = {} } = props;

  const [hasOverflow, setHasOverflow] = React.useState(false);

  React.useEffect(() => {
    const paragraph = document.getElementById(id);
    if (paragraph) {
      setHasOverflow(paragraph.scrollWidth > paragraph.offsetWidth);
    }
  }, []);

  const styleObject = React.useMemo(() => {
    const result = { ...style };

    if (color) {
      result['color'] = color;
    } else {
      result['color'] = colors['graphite'];
    }

    return result;
  }, [style, color]);

  const renderTooltip = () => {
    if (usedInTable) {
      return (
        <div
          // @ts-ignore
          data-tip={createTooltipContent(children)}
          data-for={tooltipFor}
          className={css['overflow']}
        >
          <Paragraph className={className}>{children}</Paragraph>
        </div>
      );
    }

    return (
      <SimpleTooltip id={id} tooltip={children}>
        <div className={css['overflow']}>
          <Paragraph className={className}>{children}</Paragraph>
        </div>
      </SimpleTooltip>
    );
  };

  return hasOverflow ? (
    renderTooltip()
  ) : (
    <p
      id={id}
      style={styleObject}
      className={classNames(css['paragraph'], className, {
        [css['deleted']]: deleted,
        [css['text-bold']]: bold
      })}
      tabIndex={tabIndex}
    >
      {children ? children : '-'}
    </p>
  );
});
