import classNames from 'classnames';
import React from 'react';

import { DialogType } from '../../../events/dialog-events';
import { Icon } from '../../Foundation';
import css from './dialog-header.module.scss';

type Props = {
  title: string;
  type: DialogType;
  icon: string;
  extraRight: React.ReactNode;
};

export default function DialogHeader(props: Props) {
  const { title, type, icon, extraRight } = props;

  return (
    <>
      <div className={classNames(css['dialog-header'], css[type])}>
        {icon && <Icon name={icon} />}
        <h2>{title}</h2>
      </div>
      {extraRight}
    </>
  );
}
