import { customerType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import CustomerAutoFill from 'components/Autofills/CustomerAutoFill';
import { TabSlider } from 'design-system/Components';
import { SelectionTabItemType } from 'design-system/Components/TabSlider/Tab';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction, useRef } from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

type Props = {
  newCustomer?: JSX.Element;
  onFocusStep?: Dispatch<SetStateAction<string>>;
  showTabslider?: boolean;

  // Autofill
  customer: CustomerType | null;
  setCustomer: (customer: CustomerType | null) => void;

  // TabSlider
  customerType_: customerType;
  setCustomerType: (value: customerType) => void;
};

const NewOrExistingCustomer = (props: Props) => {
  const {
    newCustomer,
    customer,
    customerType_,
    setCustomer,
    onFocusStep,
    setCustomerType,
    showTabslider = true
  } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const tabItems = useRef<SelectionTabItemType<any>[]>([
    { id: customerType.newcustomer, icon: 'plus', title: getTranslation('general.new') },
    {
      id: customerType.existingcustomer,
      icon: 'customer',
      title: getTranslation('general.existing')
    }
  ]);

  const handleFocus = () => {
    if (onFocusStep) onFocusStep('customer');
  };

  return (
    <FlexElements flexDirection="column" gap="24">
      {showTabslider && (
        <TabSlider
          id="customer-type"
          tabItems={tabItems.current}
          selectedTabItem={customerType_}
          setSelectedItem={(item) => setCustomerType(item as customerType)}
          textAlign="left"
          fullWidth
        />
      )}

      {customerType_ === customerType.newcustomer ? (
        newCustomer
      ) : (
        <CustomerAutoFill
          id="contracts.searchCustomer"
          initialValue={customer?.shortDisplayName || ''}
          onChange={(value) => setCustomer(value[0])}
          selectedValues={[customer?.id || '']}
          placeholder={getTranslation('contracts.wizard.search_customer')}
          onFocus={handleFocus}
          error={!customer}
        />
      )}
    </FlexElements>
  );
};

export default observer(NewOrExistingCustomer);
