import DateRangePicker from 'components/input/DateRangePicker';
import { SettingDescription } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import React from 'react';
import { calcNextInvoiceDate } from 'utils/contract';

import { isMaxDate, isMinDate } from '@zf/utils/src/date';

const NewContract = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { locationSectionStore, regularMoveInWizardValues } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { values, setValue } = regularMoveInWizardValues;
  const { invoiceFrequency, supplyStartDate, supplyEndDate } = values;
  const { invoiceBillingConfigurationSuggestions } = locationSectionStore;

  const setSupplyPeriod = (dates: (Moment | null | undefined)[]) => {
    if (isMinDate(dates[0])) {
      // To correctly calculate next invoice date
      dates[0] = null;
    }

    if (isMaxDate(dates[1])) {
      // Backend doesn't like max date for this UC
      dates[1] = null;
    }

    setValue({
      supplyStartDate: dates[0],
      supplyEndDate: dates[1],
      nextInvoiceDate: calcNextInvoiceDate(
        dates[0],
        invoiceBillingConfigurationSuggestions?.invoiceMonth || null,
        invoiceBillingConfigurationSuggestions?.invoiceDay || null,
        invoiceFrequency
      )
    });
  };

  return (
    <>
      <SettingDescription
        title={getTranslation('contracts.wizard.period_title')}
        description={getTranslation('contracts.wizard.period_descr')}
        setting={<div />}
        isMandatory
      />
      <DateRangePicker
        id="contracts.supplyPeriod"
        startDate={supplyStartDate}
        endDate={supplyEndDate}
        setDates={setSupplyPeriod}
        isWizardRangePicker
        clearStart={false}
        error={isMinDate(supplyStartDate) || supplyStartDate === null || typeof supplyStartDate === 'undefined'}
      />
    </>
  );
};

export default observer(NewContract);
