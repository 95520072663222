import { createListPageStore } from 'features/devices/issues/Context/createListPageStore';
import { ListPageContext, ListPageStore } from 'features/devices/issues/Context/types';
import { useListPageProvider } from 'features/devices/issues/Context/useListPageProvider';
import React, { createContext, FC, ReactNode, useContext } from 'react';

import { MeteringIssue } from '@zf/api-types/metering/metering-issues';

const store = createListPageStore({}, 'unresolved', {
  all: 0,
  unresolved: 0
});

export const IssuesContext = createContext<ListPageContext>({
  store,
  getSidePanelData: () => [],
  setFilter: (filters: ListPageStore['filter']) => {},
  setListPage: (list: ListPageStore['listPage']) => {},
  updateRows: (updatedRowEntities: MeteringIssue[]) => {},
  goToPreviousRow: (currentRowId: string) => {},
  goToNextRow: (currentRowId: string) => {},
  closeSidePanel: () => {},
  setListPageStore: (value: React.SetStateAction<ListPageStore>) => {}
});

export const useIssuesContext = () => useContext(IssuesContext);

export const IssuesProvider: FC<ReactNode> = ({ children }) => {
  const listPageState = useListPageProvider(store);

  return <IssuesContext.Provider value={listPageState}>{children}</IssuesContext.Provider>;
};
