import { BillingTariffType } from '@zf/api-types/product';
import { action, makeAutoObservable, observable } from 'mobx';

import RootStore from '../../../app-context/stores';
import ProductConfigService from '../services/ProductConfigService';
import BillingItemsStore from './BillingitemsStore';
import ProductStore from './ProductStore';

export default class ProductConfigStore {
  public rootStore: RootStore;
  public productsStore: ProductStore;
  public billingItemsStore: BillingItemsStore;

  public isPanelViewMode = false;
  public onlyShowTariffs = false;
  public showInactiveTariffs = false;
  public productConfigService: ProductConfigService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.productConfigService = new ProductConfigService(rootStore.applicationStore);
    this.productsStore = new ProductStore(this);
    this.billingItemsStore = new BillingItemsStore(this);

    makeAutoObservable(this, {
      isPanelViewMode: observable,
      onlyShowTariffs: observable,
      showInactiveTariffs: observable,

      togglePanelView: action,
      setOnlyShowTariffs: action,
      toggleInactiveTariffs: action,
      filterOnlyUniqueTariffPeriod: action
    });
  }

  togglePanelView = () => {
    this.isPanelViewMode = !this.isPanelViewMode;
    // This avoids inconsistency between viewmodes as we only keep tariffs for 1 item in state
    this.productsStore.resetTariffs();
  };

  setOnlyShowTariffs = async (val: boolean, billingItemId: string, productId: string) => {
    this.onlyShowTariffs = val;
    if (!val && billingItemId) {
      await this.productsStore.getTree(billingItemId, productId);
    }
  };

  toggleInactiveTariffs = () => {
    this.showInactiveTariffs = !this.showInactiveTariffs;
  };

  filterOnlyUniqueTariffPeriod = (tariffs: BillingTariffType[] | undefined) => {
    return tariffs?.filter((tariff, index) => {
      return (
        tariffs.map((t) => t.startDateTime).indexOf(tariff.startDateTime) === index &&
        tariffs.map((t) => t.endDateTime).indexOf(tariff.endDateTime) === index
      );
    });
  };
}
