import React from 'react';

type Props = {
  children: React.ReactNode;
  path: string;
};

export default function Layout(props: Props) {
  const { children } = props;

  return <React.Fragment>{children}</React.Fragment>;
}
