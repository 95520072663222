import React from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { ErrorBoundary } from '../../../../../../components/Error';
import MeteringCharts from './metering-charts';
import MetersCard from './meters-card';
import css from './dashboard.module.scss';

type Props = {
  propertyGroupId: string;
  timeStamp: string;
};

export default function Dashboard(props: Props) {
  return (
    <CardsContainer className={css['dashboard-card-container']}>
      <ErrorBoundary>
        <MeteringCharts {...props} />
      </ErrorBoundary>
      <MetersCard {...props} />
    </CardsContainer>
  );
}
