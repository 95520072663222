import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import MeterTable from './meter-table';

type Props = {
  propertyGroupId: string;
  timeStamp: string;
};

const MetersCardBody = (props: Props) => {
  const { meterStore } = useStore();
  const { models, organisationCfg, initMeterStore } = meterStore;

  useEffect(() => {
    // This card depends on the meterStore's configuration
    initMeterStore();
  }, []);

  if (!models || !organisationCfg) return null;

  return <MeterTable {...props} models={models} />;
};

export default observer(MetersCardBody);
