import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../../app-context';

export default function NoBillingPeriods() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/billing-periods.png"
          alt="No billing periods"
          title="No billing periods"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('contracts.no_invoice_periods')}
    >
      {i18n.getTranslation('contracts.no_invoice_periods_descr')}
    </CardEmptyBody>
  );
}
