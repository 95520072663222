import { observer } from 'mobx-react';
import React from 'react';

import { EstimatedInvoiceLine } from '@zf/api-types/billing/estimated-invoices';
import { formatPeriod } from '@zf/utils/src/date';
import { formatMoney, formatQuantity, formatUnitPrice } from '@zf/utils/src/number';

import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import EstimatedInvoiceAccuracy from './accuracy/EstimatedInvoiceAccuracy';
import css from './estimated-line.module.scss';

type Props = {
  estimatedInvoiceLine: EstimatedInvoiceLine;
  productName?: string;
};

const EstimatedLine = (props: Props) => {
  const { estimatedInvoiceLine, productName } = props;
  const { applicationStore } = useStore();
  const { culture, getEnumTranslation } = applicationStore;

  return (
    <div className={css['row']}>
      <div className={css['col-accuracy']}>
        <Paragraph className={css['invoice-line-date']}>
          {formatPeriod(estimatedInvoiceLine.startDateTime, estimatedInvoiceLine.endDateTime)}
        </Paragraph>
        {/* Passing the whole estimatedInvoiceLine to gather all data */}
        <EstimatedInvoiceAccuracy
          id="passed-down-id"
          accuracy={estimatedInvoiceLine.accuracy}
          productName={productName}
          estimatedInvoiceLine={estimatedInvoiceLine}
          size="small"
          usage="dialog-line"
        />
      </div>

      {/* RAW QUANTITY LOGIC */}
      {estimatedInvoiceLine.calculationType !== 'consumption' && estimatedInvoiceLine.rawQuantity ? (
        <Paragraph>{formatQuantity(estimatedInvoiceLine.rawQuantity, culture)}</Paragraph>
      ) : (
        <Paragraph>{formatQuantity(estimatedInvoiceLine.quantity, culture)}</Paragraph>
      )}

      <Paragraph>{`${formatUnitPrice(estimatedInvoiceLine.unitPrice, culture)}/${getEnumTranslation(
        'unitOfMeasure',
        estimatedInvoiceLine.unitOfMeasure
      )}`}</Paragraph>
      <Paragraph>{formatMoney(estimatedInvoiceLine.amountInclVAT, culture)}</Paragraph>
    </div>
  );
};

export default observer(EstimatedLine);
