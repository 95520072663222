import { observer } from 'mobx-react';
import React, { Dispatch } from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import useDialog from '@zf/hooks/src/useDialog';

import { UnbilledState } from '../../../cards/Unbilled/context/unbilled-context';
import CreateAdvanceDialog from '../../../cards/Unbilled/dialogs/CreateAdvanceDialog';
import { RowTypeDetails } from '../../../cards/Unbilled/hooks/useUnbilled';
import { RowTypeUnbilled } from '../../../cards/Unbilled/hooks/useUnbilledActions';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  completenesses: BillingCompletenessType[];
  untilDateTime?: string;
  setState: Dispatch<Partial<UnbilledState>>;
  updateGivenRows: (
    updatedRecords: BillingCompletenessType[],
    deletedRecords?: (RowTypeDetails | RowTypeUnbilled)[]
  ) => Promise<RowTypeUnbilled[]>;
};

const CreateAdvanceListItem = (props: Props) => {
  const { completenesses, untilDateTime, setState, updateGivenRows } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="invoice.create_advance"
      dialogTitle={getTranslation('invoice.generate_advance')}
      icon="invoice"
      dialogContent={
        <CreateAdvanceDialog
          ref={clickRef}
          untilDateTime={untilDateTime}
          completenesses={completenesses}
          validationRef={validationRef}
          updateGivenRows={updateGivenRows}
          setState={setState}
        />
      }
      disabled={completenesses.length === 0}
      onSubmit={onSubmit}
      buttonPositive={getTranslation('invoice.generate')}
      validationRef={validationRef}
    />
  );
};

export default observer(CreateAdvanceListItem);
