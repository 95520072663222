import React from 'react';

import { BillingRelationType } from '@zf/api-types/billing-relation';
import { ContractType } from '@zf/api-types/master-data/contract';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  contract: ContractType;
  mayTerminateContract: boolean;

  // Depends on list or detail page
  billingRelation?: BillingRelationType | null;
};

export default function TerminateContractListItem(props: Props) {
  const { contract, mayTerminateContract, billingRelation = null } = props;

  const { contractStore, applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { moveOutWizardBaseStore } = contractStore;
  const { initMoveOutWizards } = moveOutWizardBaseStore;

  if (mayTerminateContract) {
    return (
      <DropdownAction
        id="contract.terminate"
        icon="move-out"
        actionType="link"
        url={`${rootUrl}/contracts/terminate`}
        onNavigate={() => initMoveOutWizards(contract, billingRelation)}
      >
        {getTranslation('actions.terminate')}
      </DropdownAction>
    );
  }

  return null;
}
