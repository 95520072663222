import clone from 'clone';
import React from 'react';

import { TenantOrganisationType, UpdatedOrganisationType } from '@zf/api-types/auth';

import { useAppContext } from '../../../../app-context';
import { AUTH_BASE_URL } from '../../../../constants/authentication';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../../../design-system/Foundation';
import { notify } from '../../../../events/notification-events';
import { createHeader, METHODS, sendRequest } from '../../../../utils/request';

type Props = {
  orgIndex: number;
  setIsResetting: React.Dispatch<React.SetStateAction<boolean>>;
  currentOrganisation: TenantOrganisationType | undefined;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { orgIndex, setIsResetting, currentOrganisation } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { tenant } = tenantReducer.state;

  const id = currentOrganisation?.organizationId ? currentOrganisation?.organizationId : currentOrganisation?.id;

  const clearOrganisation = async () => {
    return (
      await sendRequest<UpdatedOrganisationType>({
        request: {
          method: METHODS.POST,
          endpoint: `${AUTH_BASE_URL}/api/Organization/${id}/clear`
        },
        customHeaders: createHeader({
          tenant: tenant?.id,
          organization: id
        }),
        lang: i18n.lang
      })
    ).data;
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const clearedOrganisation = await clearOrganisation();

        const tenantAuthClone = clone(tenantReducer.state.tenantAuth);
        if (tenantAuthClone) tenantAuthClone.organizations[orgIndex] = clearedOrganisation;
        tenantReducer.dispatch({ tenantAuth: tenantAuthClone });

        setIsResetting(true);

        notify.success({
          content: i18n.getTranslation('tenant.clear_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tenant.clear_fail'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation('tenant.clear_heading')}</Paragraph>;
});
