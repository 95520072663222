import classNames from 'classnames';
import React, { forwardRef, useCallback, useMemo } from 'react';

import { useStore } from '@zf/heat-erp/src/hooks/useStore';
import useTransition from '@zf/hooks/src/useTransition';
import { GLOBAL_VARS } from '@zf/utils/src/global';

import { DialogLocationsType, DialogType } from '../../../events/dialog-events';
import css from './dialog.module.scss';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';

export type DialogClickRef = {
  onClick: () => Promise<void>;
};

export type ValidationRef = {
  setIsError: (error: boolean) => void;
  setButtonPositive: (text: string) => void;
  onCancel: () => void;
};

export type DialogProps = {
  title: string;
  content: React.ReactNode;
  icon?: string;
  buttonPositive?: string;
  buttonNegative?: string;
  isDismissDialog?: boolean;
  type?: DialogType;
  locationType?: DialogLocationsType;
  ref?: React.MutableRefObject<ValidationRef | undefined>;
  extraRight?: JSX.Element;
  withPadding?: boolean;
  stackOrder?: number; // Used when nesting dialogs, highest nr. => lowest in stack
  onSubmit: () => Promise<void> | void;
  onCancel: () => void;
};

export default forwardRef((props: DialogProps, ref: React.Ref<ValidationRef | undefined>) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const {
    title,
    content,
    icon = '',
    buttonPositive = getTranslation('general.close'),
    buttonNegative = getTranslation('general.cancel'),
    type = 'default',
    locationType = 'normal',
    isDismissDialog = false,
    withPadding = true,
    extraRight,
    stackOrder = 0,
    onSubmit,
    onCancel
  } = props;

  const { toggledStyle } = useTransition({
    styleEnter: classNames(css['toggled']),
    toggle: true
  });

  const styles = useMemo(() => {
    return {
      popupStyle: {
        zIndex: parseInt(GLOBAL_VARS['popup-z-index']) - stackOrder
      },
      overlayStyle: {
        zIndex: parseInt(GLOBAL_VARS['popup-z-index']) - stackOrder - 1
      }
    };
  }, [stackOrder]);

  const onOutsideClick = useCallback(() => {
    if (isDismissDialog) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <>
      <div className={classNames(css['popup'], css[locationType])} style={styles.popupStyle}>
        <div className={classNames(css['modal'], { [css['full-height']]: locationType === 'right' }, toggledStyle)}>
          <div className={css['header']}>
            <DialogHeader type={type} title={title} icon={icon} extraRight={extraRight} />
          </div>

          <div
            className={classNames(css[`popup-content-${locationType}`], {
              [css['padding']]: withPadding
            })}
          >
            {content}
          </div>

          <div className={css['dialog-footer']}>
            <DialogFooter
              ref={ref}
              buttonPositive={buttonPositive}
              buttonNegative={buttonNegative}
              isDismissDialog={isDismissDialog}
              danger={type === 'danger'}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>

      <div onClick={onOutsideClick} className={classNames(css['overlay'], toggledStyle)} style={styles.overlayStyle} />
    </>
  );
});
