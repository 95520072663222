import React from 'react';

import { InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import AssignToTransactionDialog from '../dialogs/assign-to-transaction-dialog';

type Props = {
  invoice: InvoiceType;
};

export default function AssignToTransactionListItem(props: Props) {
  const { invoice } = props;

  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { invoiceStore } = useStore();

  if (invoiceStore.pageActionPermissions.mayAssignToTransaction) {
    return (
      <DropdownAction
        id="outgoing_banking_transaction.assign_invoice"
        icon="assign"
        validationRef={validationRef}
        dialogTitle={i18n.getTranslation('actions.invoice.add_to_transaction')}
        dialogContent={<AssignToTransactionDialog ref={clickRef} validationRef={validationRef} invoice={invoice} />}
        onSubmit={onSubmit}
        buttonPositive={i18n.getTranslation('general.add')}
      />
    );
  }

  return null;
}
