import {
  CollectionFlowStepType,
  CollectionFlowType,
  CreateCollectionFlowType,
  UpdateCollectionFlowStepType
} from '@zf/api-types/collection-cycles';
import { LocalTemplateAttachmentType, TemplateAttachment } from '@zf/api-types/config/scenarios_new';
import { uiCulture } from '@zf/api-types/enums';
import { createFormData } from '@zf/auth/src/utils';

import { TenantReturnValue } from '../../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../../utils/request';

export async function addCollectionCycle(
  newCycle: CreateCollectionFlowType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<CollectionFlowType> {
  return (
    await sendRequest<CollectionFlowType>({
      request: {
        method: METHODS.POST,
        endpoint: '/cfg/CollectionFlows/',
        data: newCycle
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteCollectionFlow(
  flowId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/cfg/CollectionFlows/${flowId}`
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function updateCollectionCycle(
  newCycle: CreateCollectionFlowType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<CollectionFlowType> {
  return (
    await sendRequest<CollectionFlowType>({
      request: {
        method: METHODS.POST,
        endpoint: `/cfg/CollectionFlows/${newCycle.id}`,
        data: newCycle
      },
      tenantReducer,
      lang
    })
  ).data;
}

const postAttachment = async (
  fileData: LocalTemplateAttachmentType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  const orgId = tenantReducer.state.organization ? tenantReducer.state.organization.organizationId : '';

  return (
    await sendRequest<TemplateAttachment>({
      request: {
        method: METHODS.POST,
        endpoint: `/att/attachments/organizationscenarios/${orgId}`,
        data: createFormData({
          File: fileData.file
        })
      },
      tenantReducer,
      lang
    })
  ).data;
};

const postAttachments = async (
  newStep: UpdateCollectionFlowStepType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  const oldAttachments: LocalTemplateAttachmentType[] = [];

  const promises = newStep.communicationAttachments
    ? newStep.communicationAttachments.reduce(
        (acc: Promise<TemplateAttachment>[], att: LocalTemplateAttachmentType) => {
          // Only submit new attachments
          if (!att.id) {
            acc.push(postAttachment(att, tenantReducer, lang));
          } else {
            oldAttachments.push(att);
          }
          return acc;
        },
        []
      )
    : [];

  const results = await Promise.all(promises);

  return [...oldAttachments, ...results];
};

export async function addCollectionFlowStep(
  newStep: UpdateCollectionFlowStepType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<CollectionFlowStepType> {
  // Post attachments first if any
  if (newStep.communicationAttachments && newStep.communicationAttachments.length > 0) {
    const apiFriendlyAttachments = await postAttachments(newStep, tenantReducer, lang);
    newStep.communicationAttachments = apiFriendlyAttachments as TemplateAttachment[];
  }

  if (newStep.chargeParameters) {
    // @ts-ignore - type has to come first
    newStep.chargeParameters = { type: newStep.chargeParameters.type, ...newStep.chargeParameters };
  }

  return (
    await sendRequest<CollectionFlowStepType>({
      request: {
        method: METHODS.POST,
        endpoint: '/cfg/CollectionSteps/',
        data: newStep
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function updateCollectionFlowStep(
  newStep: UpdateCollectionFlowStepType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<CollectionFlowStepType> {
  // Post attachments first if any
  if (newStep.communicationAttachments && newStep.communicationAttachments.length > 0) {
    const apiFriendlyAttachments = await postAttachments(newStep, tenantReducer, lang);
    newStep.communicationAttachments = apiFriendlyAttachments as TemplateAttachment[];
  }

  if (newStep.chargeParameters) {
    // @ts-ignore - type has to come first
    newStep.chargeParameters = { type: newStep.chargeParameters.type, ...newStep.chargeParameters };
  }

  return (
    await sendRequest<CollectionFlowStepType>({
      request: {
        method: METHODS.POST,
        endpoint: `/cfg/CollectionSteps/${newStep.id}`,
        data: newStep
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteCollectionStep(
  stepId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/cfg/CollectionSteps/${stepId}`
      },
      tenantReducer,
      lang
    })
  ).data;
}
