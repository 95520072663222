import React from 'react';

import { CollectionCaseRowType, CollectionCaseType } from '@zf/api-types/collection-case';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { processCollection } from './collection-case';
import DatePicker from '../../components/Lang/DatePicker';
import { MIN_DATE } from '@zf/utils/src/date';
import moment from 'moment';
import css from './process-collection-case-dialog.module.scss';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  collectionCase?: CollectionCaseType;
  selectedRows?: CollectionCaseRowType[];
  onComplete?: (updatedRows: CollectionCaseType[]) => void;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { collectionCase, selectedRows, onComplete, refreshCounts } = props;

  const { i18n, tenantReducer } = useAppContext();
  const [date, setDate] = React.useState(moment(MIN_DATE));

  let casesToHandle: CollectionCaseType[] = [];
  if (selectedRows) {
    casesToHandle = selectedRows.map((row) => row.__entity);
  } else if (collectionCase) {
    casesToHandle = [collectionCase];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newCases = await Promise.all(
          casesToHandle.map((c) => {
            return processCollection(c.id, date.toISOString(), tenantReducer, i18n.lang);
          })
        );

        if (onComplete) onComplete(newCases);
        if (refreshCounts) refreshCounts();

        notify.success({
          content:
            casesToHandle.length === 1
              ? `${i18n.getTranslation('collection_flows.collection_flow')} ${i18n.getTranslation(
                  'actions.collection_case.process_succesfull'
                )}`
              : `${i18n.getTranslation('collection_flows.collection_flows')} ${i18n.getTranslation(
                  'actions.collection_case.process_succesfull'
                )}`
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.collection_case.process_failed'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph className={css['collection-case-process-info']}>
        {i18n.getTranslation('actions.collection_case.process_text')}
      </Paragraph>
      <InputContainer>
        <DatePicker
          id="start-date"
          onChange={(value) => setDate(value)}
          value={date}
          placeholder={i18n.getTranslation('general.date')}
        />
      </InputContainer>
    </>
  );
});
