import React from 'react';

import { InvoiceRowType, InvoiceType, RegenerateInvoiceType } from '@zf/api-types/invoice';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  onComplete?: (updatedRows: InvoiceType[]) => void;
  selectedRows?: Array<InvoiceRowType>;
  invoiceToHandle?: InvoiceRowType | RegenerateInvoiceType;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, invoiceToHandle } = props;
  const { i18n } = useAppContext();
  const { invoiceStore } = useStore();

  let invoicesToHandle: Array<string> = [];

  if (selectedRows) {
    for (let i = 0; i < selectedRows.length; i++) {
      invoicesToHandle.push(selectedRows[i].__id);
    }
  } else if (invoiceToHandle) {
    invoicesToHandle = [invoiceToHandle.__id];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newInvoices: Array<InvoiceType> = await Promise.all(
          invoicesToHandle.map((invoice) => invoiceStore.regenerateInvoices(invoice, !selectedRows))
        );
        if (onComplete) onComplete(newInvoices);

        notify.success({
          content: i18n.getTranslation('actions.invoice.regenerate_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.invoice.regenerate_failed'),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation('actions.invoice.regenerate_detail', {
        amount: invoicesToHandle.length.toString()
      })}
    </Paragraph>
  );
});
