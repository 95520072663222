import { colors } from '@zf/utils/src/color';
import React from 'react';
import BarStack from './BarStack';
import StackedBarChart from './StackedBarChart';
import css from '../aging-report-card.module.scss';
import { AgingReportOverviewCountType } from '@zf/api-types/aging-report';
import { useStore } from '../../../../hooks/useStore';
import { State } from '../hooks/useCustomerAgingFilter';
import { custAgingBucketType } from '@zf/api-types/enums';

type Props = {
  overviewCounts: AgingReportOverviewCountType;
  filter: State;
  setFilter: React.Dispatch<Partial<State>>;
};

const AgingReportCard = (props: Props) => {
  const { overviewCounts, filter, setFilter } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const calculatePercentage = (overviewCount: number) => {
    if (overviewCounts) {
      return (overviewCount / overviewCounts?.totalOpen) * 100;
    } else {
      return 0;
    }
  };

  const selectFilter = (daysOutstanding: custAgingBucketType) => {
    if (filter.daysOutstanding.includes(daysOutstanding)) {
      setFilter({ daysOutstanding: filter.daysOutstanding.filter((item) => item !== daysOutstanding) });
    } else {
      setFilter({ daysOutstanding: [...filter.daysOutstanding, daysOutstanding] });
    }
  };

  return (
    <div className={css['customer-aging-chart']}>
      {filter.creditAmounts && (
        <div className={css['customer-aging-chart-open-credits']}>
          <StackedBarChart>
            <>
              <BarStack
                label={getTranslation('dashboard.customer_aging.open_credits')}
                percentage={100}
                price={overviewCounts?.openCredits}
                color={colors['green-600']}
              />
            </>
          </StackedBarChart>
        </div>
      )}
      <div className={css['customer-aging-chart-buckets']}>
        <StackedBarChart>
          <>
            <BarStack
              label={getTranslation('dashboard.customer_aging.current')}
              percentage={calculatePercentage(overviewCounts.totalOpenNotOverdue)}
              price={overviewCounts?.totalOpenNotOverdue}
              color={colors['blue-600']}
              onClick={() => selectFilter(custAgingBucketType.anyopenexcloverdue)}
              isSelected={filter.daysOutstanding.includes(custAgingBucketType.anyopenexcloverdue)}
            />
            <BarStack
              label="<30d"
              percentage={calculatePercentage(overviewCounts.totalOverdue30D)}
              price={overviewCounts.totalOverdue30D}
              color={colors['yellow-600']}
              onClick={() => selectFilter(custAgingBucketType.anyoverdue30)}
              isSelected={filter.daysOutstanding.includes(custAgingBucketType.anyoverdue30)}
            />
            <BarStack
              label="31-60d"
              percentage={calculatePercentage(overviewCounts.totalOverdue60D)}
              price={overviewCounts.totalOverdue60D}
              color={colors['orange-600']}
              onClick={() => selectFilter(custAgingBucketType.anyoverdue60)}
              isSelected={filter.daysOutstanding.includes(custAgingBucketType.anyoverdue60)}
            />
            <BarStack
              label="61-90d"
              percentage={calculatePercentage(overviewCounts.totalOverdue90D)}
              price={overviewCounts.totalOverdue90D}
              color={colors['red-600']}
              onClick={() => selectFilter(custAgingBucketType.anyoverdue90)}
              isSelected={filter.daysOutstanding.includes(custAgingBucketType.anyoverdue90)}
            />
            <BarStack
              label=">90d"
              percentage={calculatePercentage(overviewCounts.totalOverdueOver90D)}
              price={overviewCounts.totalOverdueOver90D}
              color={colors['red-700']}
              onClick={() => selectFilter(custAgingBucketType.anyoverduemorethan90)}
              isSelected={filter.daysOutstanding.includes(custAgingBucketType.anyoverduemorethan90)}
            />
          </>
        </StackedBarChart>
      </div>

      <div className={css['customer-aging-total-info']}>
        <StackedBarChart>
          <>
            <BarStack
              label={getTranslation('dashboard.customer_aging.collection_costs_chart')}
              percentage={100}
              price={overviewCounts?.totalCollectionCostsOpen}
              color={colors['silver-600']}
            />
            <BarStack
              label={getTranslation('incoming_mutations.incoming_matching_totalAmount')}
              percentage={100}
              price={overviewCounts?.totalOpen}
              color={colors['graphite']}
            />
          </>
        </StackedBarChart>
      </div>
    </div>
  );
};

export default AgingReportCard;
