import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../../app-context';
import SuspenseCardBody from '../../../../../../components/suspense/SuspenseCardBody';
import MetersCardBody from './meters-card-body';
import css from './dashboard.module.scss';

type Props = {
  propertyGroupId: string;
  timeStamp: string;
};

export default function MetersCard(props: Props) {
  const { i18n } = useAppContext();

  return (
    <Card id="meters-card" width="4">
      <CardHeader>{i18n.getTranslation('meter.meters')}</CardHeader>
      <SuspenseCardBody className={css['meters-card-body']} fixedHeight>
        <MetersCardBody {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
