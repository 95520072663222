import React from 'react';

import MoreActionsMenu from '../../components/Menus/MoreActionsMenu';
import useUnbilledActions from './hooks/useUnbilledActions';
import css from './unbilled-list.module.scss';

type Props = {
  untilDateTime?: string;
};

export default function Actions(props: Props) {
  const { untilDateTime } = props;
  const { listItems, selectedItems } = useUnbilledActions({ untilDateTime });

  if (selectedItems.length === 0) return null;

  return <MoreActionsMenu id="more-actions" className={css['more-actions']} actions={listItems} />;
}
