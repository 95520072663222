import { Observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { AddCustomerBankAccountActiveMandateRequestType } from '@zf/api-types/billing/customer-bank-account';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context';
import DatePicker from '../../../../components/Lang/DatePicker';
import MandateForm from '../../../../features/customer/forms/MandateForm';
import css from './new-mandate.module.scss';

type Props = {
  bankAccountIndex: number;
  mandate: AddCustomerBankAccountActiveMandateRequestType;
  mandateForm: MandateForm | undefined;
};

export default function NewMandate(props: Props) {
  const { bankAccountIndex, mandateForm } = props;
  const { i18n } = useAppContext();

  return (
    <Observer>
      {() => (
        <div className={css['mandate']}>
          <Label color={colors['silver-700']}>{i18n.getTranslation('mandates.mandate')}</Label>
          <DatePicker
            onChange={(val) => mandateForm?._setNested('mandate', 'signedDateTime', val.toISOString())}
            value={moment(mandateForm?._getNested('mandate', 'signedDateTime'))}
            id={`signed-date-${bankAccountIndex}`}
            error={!!mandateForm?._error('mandate')}
            singleError={mandateForm?._error('mandate')}
            placeholder={i18n.getTranslation('mandates.sign-date')}
            darkMode
          />
        </div>
      )}
    </Observer>
  );
}
