import { useEffect, useReducer } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { entitySubjectType } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import { sortByDate } from '@zf/utils/src/date';

import { useStore } from '../../hooks/useStore';

type State = {
  customEntityPropertyTypes: CustomEntityPropertyType[] | undefined;
  extraColumns: ColumnType[];
  extraColumnTitles: string[];
};

export default function useCustomEntityPropertyTypes(entitySubjectType: entitySubjectType) {
  const { configStore } = useStore();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    customEntityPropertyTypes: undefined,
    extraColumns: [],
    extraColumnTitles: []
  });

  useEffect(() => {
    configStore.configService.getCustomEntityPropertyTypes().then((customEntityPropertyTypes) => {
      const filtered = customEntityPropertyTypes.filter((cep) => cep.entitySubjectType === entitySubjectType);

      // Most recent property to the right
      const sorted = sortByDate(filtered, 'createdDateTime', 'ASC');

      let extraColumns: ColumnType[] = [];
      let extraColumnTitles: string[] = [];

      sorted.forEach((fp) => {
        extraColumns.push({
          width: 140,
          label: fp.name,
          dataKey: fp.name
        });

        extraColumnTitles.push(fp.name);
      });

      setState({
        customEntityPropertyTypes: sorted,
        extraColumns,
        extraColumnTitles
      });
    });
  }, []);

  return {
    customEntityPropertyTypes: state.customEntityPropertyTypes,
    extraColumns: state.extraColumns,
    extraColumnTitles: state.extraColumnTitles
  };
}
