import { pluginType } from '@zf/api-types/enums';
import { ConfiguredPluginType } from '@zf/api-types/integration/plugin';

import RootStore from '../../stores';
import IntegrationStore from '../../stores/integration/IntegrationStore';
import SingletonService from '../SingletonService';

export type ConfigRequestsBaseType = {
  commandName: string;
  pluginType: pluginType;
  _type: string;
};

export default class ConfiguredPluginsService extends SingletonService {
  public integrationStore: IntegrationStore;

  constructor(rootStore: RootStore, integrationStore: IntegrationStore) {
    super(rootStore);
    this.integrationStore = integrationStore;
  }

  getConfiguredPlugins = async () => {
    return this.getPagedVariable<ConfiguredPluginType>(
      '/int/ConfiguredPlugins',
      this.integrationStore._configuredPlugins,
      this.integrationStore.setConfiguredPlugins
    );
  };
}
