import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import { useAppContext } from '../../../../app-context/app-context';
import Activities from './Activities';
import getExistingEntities from './hooks/getExistingEntities';
import useActivities from './hooks/useActivities';
import LeftColumn from './LeftColumn';
import css from './task-detail.module.scss';
import WizardColumn from './WizardColumn';

type Props = {
  task: MoveRequestType;
};

const TaskColumnsContainer = (props: Props) => {
  const { task } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { internalMeterIds, setExistingEntities } = useActivities();

  const { meterStore } = useStore();
  const { getMeterById } = meterStore.deviceService;

  const fetch = async () => {
    if (task) {
      const res = await getExistingEntities(task, tenantReducer, i18n.lang, getMeterById);
      setExistingEntities(res);
    }
  };

  React.useEffect(() => {
    fetch();
  }, [
    task.id,
    task.customerDetails.internalId,
    task.serviceLocationDetails.internalId,
    task.contractDetails.internalContractId,
    internalMeterIds
  ]);

  return (
    <ColumnsContainer className={css['column-container']} extraPadding={false}>
      <LeftColumn {...props} />
      <Activities />
      <WizardColumn />
    </ColumnsContainer>
  );
};

export default observer(TaskColumnsContainer);
