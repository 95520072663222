import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import {
  billingItemConditionType,
  customEntityPropertyValueType,
  paymentMethod,
  tariffType
} from '@zf/api-types/enums';
import { booleanOptions } from '@zf/api-types/local-enums';
import {
  CustomEntityPropertyTypeTariffConditionTypeParameters,
  CustomEntityPropertyTypeTariffConditionTypeParametersDTO,
  TariffConditionTypeParametersDTO
} from '@zf/api-types/product';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import Dropdown from '../../../../../../components/Lang/Dropdown';
import { Button } from '../../../../../../design-system/Components';
import { DialogClickRef } from '../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph, Spinner } from '../../../../../../design-system/Foundation';
import useSingleAPI from '../../../../../../hooks/useSingleAPI';
import { useStore } from '../../../../../../hooks/useStore';
import TreeNode, { actions } from '../../models/TreeNode';
import css from './add-condition-dialog.module.scss';
import CrudOptionList from './components/CrudOptionList';
import StaticOptionList from './components/StaticOptionList';

type Props = {
  treeNode: TreeNode;
  parentNode?: TreeNode;
  index?: number;
};

const AddConditionsDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { treeNode, parentNode, index } = props;

  const { applicationStore, productConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { setCondition, conditionToApiModel, condition, initialCondition } = productConfigStore.productsStore;

  useEffect(() => {
    if (treeNode.conditionGroup) {
      setCondition(treeNode, treeNode.conditionGroup);
    } else {
      setCondition(treeNode, initialCondition.value);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (condition) {
        const apiModel = conditionToApiModel(condition, treeNode);
        if (treeNode) {
          const conditionValueNodes = apiModel.map((c) => {
            const node = new TreeNode(
              c.value.toString().toUpperCase(),
              [],
              [actions.addcondition, actions.addtariff],
              tariffType.conditions,
              undefined,
              true,
              'dot'
            );
            node.addCondition(c.apiFriendlyValues);
            return node;
          });

          if (condition.id === billingItemConditionType.paymentmethod) {
            addConditionToPeriod(conditionValueNodes, 'Payment method');
          } else {
            const customEntityApiModel = apiModel[0]
              .apiFriendlyValues as CustomEntityPropertyTypeTariffConditionTypeParametersDTO;
            const customEntity = await productConfigStore.productConfigService.getCustomEntityPropertyType(
              customEntityApiModel.id
            );
            addConditionToPeriod(conditionValueNodes, customEntity.name);
          }
        }
      }
    }
  }));

  const addConditionToPeriod = (conditionValueNodes: TreeNode[], description: string) => {
    if (condition) {
      const node = new TreeNode(
        description,
        conditionValueNodes,
        [actions.editcondition, actions.deletecondition],
        tariffType.conditions,
        undefined,
        treeNode.isNew,
        'condition'
      );

      node.setConditionGroup(condition);

      //update logic
      if (index !== undefined && parentNode && parentNode.nodes.length > 0) {
        treeNode?.updateNode(index, node, parentNode);
      } else {
        //add logic
        treeNode?.addNode(node);
      }
    }
  };

  const customEntityPropertyTypesResponse = useSingleAPI<PagedResponseType<CustomEntityPropertyType>>({
    request: {
      endpoint: '/cfg/CustomEntityPropertyTypes'
    }
  });

  if (!customEntityPropertyTypesResponse.result) return <Spinner centered />;

  const customEntityProperties = customEntityPropertyTypesResponse.result.data.results;

  const dropdownValues = customEntityProperties.reduce(
    (conditions: DropdownValuesType<TariffConditionTypeParametersDTO>[], condition) => {
      if (condition.valueType.type !== customEntityPropertyValueType.datetime) {
        conditions.push({
          value: {
            type: billingItemConditionType.customentityproperty,
            ...condition
          } as TariffConditionTypeParametersDTO,
          text: condition.name
        });
      }
      return conditions;
    },
    []
  );

  const changeCustomEntityProperty = (val: TariffConditionTypeParametersDTO[]) => {
    if (val[0]) {
      setCondition(treeNode, val[0]);
      treeNode.setToInitialOptions();
    }
  };

  dropdownValues.unshift(initialCondition);

  const renderConditionContent = (condition: TariffConditionTypeParametersDTO) => {
    const value = condition as CustomEntityPropertyTypeTariffConditionTypeParameters;
    const optionsParagraph = <Paragraph textAlign="left">{getTranslation('general.options')}</Paragraph>;

    if (value.id === billingItemConditionType.paymentmethod) {
      return (
        <>
          {optionsParagraph}
          <StaticOptionList options={Object.values(paymentMethod)} />
        </>
      );
    } else if (
      value.valueType.type === customEntityPropertyValueType.string ||
      value.valueType.type === customEntityPropertyValueType.decimal ||
      value.valueType.type === customEntityPropertyValueType.number
    ) {
      return (
        <>
          <div className={css['conditions-add-header']}>
            {optionsParagraph}
            <Button
              id="conditions-add-option"
              className={css['conditions-add-option']}
              icon="plus"
              type="text"
              onClick={treeNode.addOption}
            >
              {getTranslation('general.add')}
            </Button>
          </div>
          <CrudOptionList
            inputType={value.valueType.type}
            options={treeNode.options}
            removeOption={treeNode.removeOption}
            updateOption={treeNode.updateOption}
          />
        </>
      );
    } else if (value.valueType.type === customEntityPropertyValueType.boolean) {
      return (
        <>
          {optionsParagraph}
          <StaticOptionList options={Object.values(booleanOptions)} />
        </>
      );
    }
  };

  return (
    <>
      <Dropdown
        id="condition-add"
        onChange={(val) => changeCustomEntityProperty(val)}
        values={dropdownValues}
        selectedValues={[condition?.id || '']}
      />

      <div className={css['conditions-add-options']}>
        <div className={css['conditions-add-options-wrapper']} />
        <div className={css['conditions-add-options-content']}>{condition && renderConditionContent(condition)}</div>
      </div>
    </>
  );
});

export default observer(AddConditionsDialog);
