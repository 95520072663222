import clone from 'clone';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  AdvanceCalculationParameters,
  BillingParametersType,
  PrepaymentParametersType,
  UpdateBillingParametersType
} from '@zf/api-types/parameters';
import useValidator from '@zf/hooks/src/useValidator';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import CommitSection from '../../../components/config/commit-section';
import { notify } from '../../../events/notification-events';
import { createHeader, METHODS } from '../../../utils/request';
import CreditBilling from './CreditBilling';
import PrePaymentBillingParameters from './prepayment-billing-parameters';

type Props = {
  billingParameters: BillingParametersType;
  contractInvoiceEstimationEnabled: boolean;
};

export type BillingCfgValues = BillingParametersType;

const BillingTabBody = (props: Props) => {
  const { billingParameters, contractInvoiceEstimationEnabled } = props;

  const { applicationStore, configStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;
  const { resetBillingParameters } = configStore;

  const { values, isDirty, setValue, restoreValues, submitFactory } = useValidator<BillingCfgValues>({
    initialValues: {
      ...clone(billingParameters),
      prepaymentParameters: {
        ...billingParameters.prepaymentParameters,
        deductionRate: billingParameters?.prepaymentParameters?.deductionRate
          ? billingParameters?.prepaymentParameters?.deductionRate * 100
          : undefined
      },
      advanceCalculationParameters: {
        ...billingParameters.advanceCalculationParameters,
        advanceLimitLowerThreshold: billingParameters.advanceCalculationParameters.advanceLimitLowerThreshold * 100,
        advanceLimitUpperThreshold: billingParameters.advanceCalculationParameters.advanceLimitUpperThreshold * 100
      }
    }
  });

  const handleSave = submitFactory(async () => {
    try {
      if (JSON.stringify(values) !== JSON.stringify(billingParameters)) {
        const apiFriendlyAdvanceCalculationParameters: AdvanceCalculationParameters = {
          ...values.advanceCalculationParameters,
          advanceLimitLowerThreshold: values.advanceCalculationParameters.advanceLimitLowerThreshold / 100,
          advanceLimitUpperThreshold: values.advanceCalculationParameters.advanceLimitUpperThreshold / 100
        };

        const apiFriendlyValues: UpdateBillingParametersType = {
          ...values,
          prepaymentParameters: {
            ...values.prepaymentParameters,
            deductionRate: values?.prepaymentParameters?.deductionRate
              ? values?.prepaymentParameters?.deductionRate / 100
              : undefined
          },
          advanceCalculationParameters: apiFriendlyAdvanceCalculationParameters
        };

        const updatedParameters = (
          await sendRequest<BillingParametersType>({
            request: {
              method: METHODS.POST,
              endpoint: '/cfg/Parameters/billing',
              data: apiFriendlyValues
            },
            customHeaders: createHeader({ 'If-Match': billingParameters._etag })
          })
        ).data;

        const updatedValues = {
          ...updatedParameters,
          prepaymentParameters: {
            ...updatedParameters.prepaymentParameters,
            deductionRate: updatedParameters?.prepaymentParameters?.deductionRate
              ? updatedParameters.prepaymentParameters.deductionRate * 100
              : 0
          },
          advanceCalculationParameters: {
            ...updatedParameters.advanceCalculationParameters,
            advanceLimitLowerThreshold: updatedParameters.advanceCalculationParameters.advanceLimitLowerThreshold * 100,
            advanceLimitUpperThreshold: updatedParameters.advanceCalculationParameters.advanceLimitUpperThreshold * 100
          }
        };

        setValue(updatedValues, false, true);
        resetBillingParameters();

        notify.success({
          content: getTranslation('parameters.success_update')
        });
      }
    } catch (error) {
      notify.error({
        content: getTranslation('parameters.error_update'),
        error
      });
    }
  });

  const handleCancel = () => restoreValues();

  const setObjectPrePaymentValue = (value: Partial<PrepaymentParametersType>) => {
    let copy = clone(values);
    copy.prepaymentParameters = { ...copy.prepaymentParameters, ...value };
    setValue(copy);
  };

  return (
    <>
      <CommitSection handleCancel={handleCancel} handleSave={handleSave} isDirty={isDirty} />
      <CardsContainer>
        <CreditBilling
          values={values}
          contractInvoiceEstimationEnabled={contractInvoiceEstimationEnabled}
          setValue={setValue}
        />
        <PrePaymentBillingParameters value={values.prepaymentParameters} setValue={setObjectPrePaymentValue} />
      </CardsContainer>
    </>
  );
};

export default observer(BillingTabBody);
