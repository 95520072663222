import moment from 'moment';
import React from 'react';

import { CollectionCaseStepType, CollectionCaseType } from '@zf/api-types/collection-case';
import { collectionFlowStatus, collectionStepStatus, collectionStepType } from '@zf/api-types/enums';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { daysToMonthsAndDays, MIN_DATE } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../app-context/app-context';
import useDownload from '../../../../../app-context/hooks/use-download';
import DownloadIcon from '../../../../../components/Icon/DownloadIcon';
import IconParagraph from '../../../../../components/Icon/IconParagraph';
import PreviewIcon from '../../../../../components/Icon/PreviewIcon';
import CloseStepListItem from '../../../../../components/list-items/collection-cases/CloseStepListItem';
import ExecuteStepListItem from '../../../../../components/list-items/collection-cases/ExecuteStepListItem';
import RetryStepListItem from '../../../../../components/list-items/collection-cases/RetryStepListItem';
import SendViaMailListItem from '../../../../../components/list-items/collection-cases/SendViaMailListItem';
import SendViaPostalListItem from '../../../../../components/list-items/collection-cases/SendViaPostalListItem';
import SkipStepListItem from '../../../../../components/list-items/collection-cases/SkipStepListItem';
import MoreActionsMenu from '../../../../../components/Menus/MoreActionsMenu';
import { SUCCESS } from '../../../../../constants/color';
import { SimpleTooltip } from '../../../../../design-system/Components';
import { Spinner } from '../../../../../design-system/Foundation';
import { notify } from '../../../../../events/notification-events';
import { createHeader, METHODS, sendRequest } from '../../../../../utils/request';
import css from './timeline-item.module.scss';

type Props = {
  collCase: CollectionCaseType;
  step: CollectionCaseStepType;
  stepNumber: number;
  dateText: string;
  refresh: () => void;
};

export default function TimelineItem(props: Props) {
  const { collCase, step, stepNumber, dateText, refresh } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { downloadFiles } = useDownload();

  const monthsAndDays = daysToMonthsAndDays(step.triggerDays);

  const preview = async () => {
    try {
      const previewResponse = (
        await sendRequest<ArrayBuffer>(
          {
            responseType: 'arraybuffer',
            request: {
              method: METHODS.POST,
              endpoint: `/bill/CollectionCases/${collCase.id}/${step.id}/previewpdf`
            },
            customHeaders: createHeader({
              Accept: 'application/octet-stream'
            }),
            tenantReducer,
            lang: i18n.lang
          },
          true
        )
      ).data;

      const pdf = new Blob([previewResponse], { type: 'application/pdf' });
      const blob_url = URL.createObjectURL(pdf);

      if (blob_url) window.open(blob_url);
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('collection_case.preview_fail'),
        error: e
      });
    }
  };

  const listItems: JSX.Element[] = [];

  const actionProps = {
    collectionCase: collCase,
    step,
    refresh
  };

  const maySend =
    step.status === collectionStepStatus.waitingformanualsend ||
    step.status === collectionStepStatus.closed ||
    step.status === collectionStepStatus.failed;

  if (step.supportsExternalPrinting && maySend) {
    listItems.push(<SendViaPostalListItem key="send-via-post" {...actionProps} />);
  }

  if (maySend) {
    listItems.push(<SendViaMailListItem key="send-via-email" {...actionProps} />);
  }

  if (step.status === collectionStepStatus.pending) {
    listItems.push(<ExecuteStepListItem key="execute" {...actionProps} />);
  }

  if (step.status !== collectionStepStatus.closed) {
    listItems.push(<SkipStepListItem key="skip" {...actionProps} />);
  }

  if (step.status === collectionStepStatus.closed || step.status === collectionStepStatus.failed) {
    listItems.push(<RetryStepListItem key="retry" {...actionProps} />);
  }

  listItems.push(<CloseStepListItem key="close" {...actionProps} />);

  const isActive = collCase.nextActionDue === step.executeAt && collCase.status === collectionFlowStatus.active;

  let borderColor = colors['blue-200'];

  if (step.status === collectionStepStatus.waitingformanualsend || step.status === collectionStepStatus.failed) {
    borderColor = colors['red-600'];
  } else if (
    step.status === collectionStepStatus.generatingdocuments ||
    step.status === collectionStepStatus.inprogress
  ) {
    borderColor = colors['yellow-600'];
  } else if (isActive && !collCase.manualInterventionRequired) {
    borderColor = colors['blue-600'];
  } else if (step.status === collectionStepStatus.skipped) {
    borderColor = colors['orange-600'];
  } else if (!moment(step.executedAt).isSame(MIN_DATE)) {
    borderColor = SUCCESS;
  }

  const renderPreviewOrDownload = () => {
    if (step.status === collectionStepStatus.printing) {
      return (
        <SimpleTooltip id="timeline-item-tip" tooltip={i18n.getTranslation('invoice.printing_progress')}>
          <Spinner size="xsmall" />
        </SimpleTooltip>
      );
    }

    if (step.documentId) {
      return (
        <div>
          <DownloadIcon
            id={`download-${stepNumber}`}
            onClick={async () =>
              await downloadFiles(
                [step.documentId || ''],
                '/att/attachments/downloadzip',
                '/att/attachments/',
                'attachmentIds'
              )
            }
          />
        </div>
      );
    }
    if (step.stepType !== collectionStepType.manualIntervention) {
      return <PreviewIcon id={`preview-${stepNumber}`} onClick={preview} />;
    }
  };

  return (
    <div className={css['timeline-item']}>
      <div className={css['white-part']}>
        {`+ ${
          monthsAndDays.months > 0
            ? i18n.getTranslation('collection_flows.amount_months', {
                months: monthsAndDays.months
              })
            : ''
        } ${
          monthsAndDays.days > 0
            ? i18n.getTranslation('collection_flows.amount_days', {
                days: monthsAndDays.days
              })
            : ''
        }`}
      </div>
      <div
        className={css['grey-part']}
        style={{
          borderColor
        }}
      >
        <div className={css['left']}>
          <Paragraph bold>{`${stepNumber}. ${step.name}`}</Paragraph>
          <Paragraph>
            {step.status === collectionStepStatus.skipped
              ? enumReducer.getTranslation('collectionStepStatus', collectionStepStatus.skipped)
              : dateText}
          </Paragraph>
          {(step.status === collectionStepStatus.waitingformanualsend || step.status === collectionStepStatus.failed) &&
            step.failureReasonCode && (
              <IconParagraph iconType="alert-circle" color={colors['red-600']}>
                {step.failureReasonCode.message}
              </IconParagraph>
            )}
        </div>
        <div className={css['middle']}>
          <Label color={colors['silver-700']}>{i18n.getTranslation('collection_case.consequences')}:</Label>
          {step.charges ? (
            <>
              <Paragraph>{`${i18n.getTranslation('collection_case.vat_amount')}: ${formatMoney(
                step.charges.stepVATAmount,
                i18n.culture
              )}`}</Paragraph>
              <Paragraph>
                {`${i18n.getTranslation('collection_case.charge_incl_vat')}: ${formatMoney(
                  step.charges.stepChargeAmountInclVAT,
                  i18n.culture
                )}`}
              </Paragraph>
            </>
          ) : (
            <Paragraph>{i18n.getTranslation('general.none')}</Paragraph>
          )}
        </div>
        <div className={css['right']}>
          <div id="timeline-item-tip" className={css['actions-grid']}>
            {renderPreviewOrDownload()}
            {collCase.status === collectionFlowStatus.active && (
              <MoreActionsMenu id={`more-actions-${step.id}`} className={css['actions']} actions={listItems} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
