import React from 'react';

import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { useAppContext } from '../../../app-context';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import Organization from './organization';

export default function OrganizationTab() {
  const { tenantReducer } = useAppContext();
  const { organization } = tenantReducer.state;

  const response = useSuspenseSingleAPI<OrganizationConfigType>(
    {
      request: {
        endpoint: '/cfg/Organizations/',
        selector: organization?.organizationId
      }
    },
    false,
    false
  );

  if (!response.result) return null;

  return <Organization organizationConfig={response.result.data} />;
}
