import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { countryCode } from '@zf/api-types/enums';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { BaseLink } from '../../../design-system/Components';
import { Icon } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';

export type OrgCardProps = {
  organisationId: string;
  tenantId: string;
  baseUrl: string;
  isResetting: boolean;
};

const CountrySpecificLink = (props: OrgCardProps) => {
  const { organisationId, tenantId, baseUrl, isResetting } = props;

  const { applicationStore, organisationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getOrganisationConfig } = organisationStore.organisationService;

  const [organizationConfig, setOrganizationConfig] = useState<OrganizationConfigType>();

  useEffect(() => {
    if (!isResetting) {
      getOrganisationConfig(organisationId, tenantId).then((cfg) => setOrganizationConfig(cfg));
    }
  }, [isResetting]);

  if (!organizationConfig) return null;

  return (
    <>
      {organizationConfig.address?.country === countryCode.bel && (
        <BaseLink id={`link-country-specifics-${organisationId}`} url={`${baseUrl}/country-specifics/social-tariffs`}>
          <Icon
            id={`country-specifics-${organisationId}`}
            name="language"
            title={getTranslation('country_specifics.country_specifics')}
          />
        </BaseLink>
      )}
    </>
  );
};

export default observer(CountrySpecificLink);
