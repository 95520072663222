import React, { useMemo } from 'react';
import classNames from 'classnames';

import css from './card-body.module.scss';

export type CardBodyType = 'default' | 'grid' | 'table' | 'indexTable' | 'detail-line';

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  type?: CardBodyType;
  height?: string;
  noMaxHeight?: boolean;
  fixedHeight?: boolean;
  error?: boolean;
};

export default function CardBody(props: Props) {
  const { children, className, type = 'default', noMaxHeight = true, error = false, fixedHeight, height } = props;
  let { style = {} } = props;

  style = useMemo(() => {
    if (height) return { height };
    return {};
  }, [height]);

  return (
    <div
      className={classNames(
        css['cardbody'],
        {
          [css['cardbody-grid']]: type === 'grid',
          [css['cardbody-table']]: type === 'table',
          [css['cardbody-index-table']]: type === 'indexTable',
          [css['max-height']]: !noMaxHeight,
          [css['fixed-height']]: fixedHeight || type === 'table',
          [css['detail-line']]: type === 'detail-line',
          [css['error']]: error
        },
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}
