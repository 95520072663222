import clone from 'clone';
import { observer } from 'mobx-react';
import React from 'react';

import { SliceType } from '@zf/api-types/product';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import FormulaBuilder from '../../../../../../components/formula-builder/formula-builder';
import FloatInput from '../../../../../../components/input/FloatInput';
import MoneyInput from '../../../../../../components/input/MoneyInput';
import { Description } from '../../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../../hooks/useStore';
import css from '../add-edit-tariff-dialog.module.scss';

type Props = {
  index: number;
  value: SliceType;
  dispatchValue: (value: Partial<SliceType>) => void;
};

const Slice = (props: Props) => {
  const { index, value, dispatchValue } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const toggleIsFormula = (formulaBased: boolean) => {
    const parametersClone = clone(value) as Record<string, any>;

    if (formulaBased) {
      // Reset unit tariff
      parametersClone.unitTariff = 0;
    } else {
      // Reset expression and input parameters
      parametersClone.expression = '';
      parametersClone.inputParameters = [];
    }

    parametersClone.formulaBased = formulaBased;

    dispatchValue(parametersClone);
  };

  return (
    <div>
      <Heading headingType="h3" style="bold">
        {getTranslation('billing_tariff.tier_count', { number: index + 1 })}
      </Heading>
      <InputContainer className={css['tier-inputs']}>
        <FloatInput
          id={`from-tariff-${index}`}
          value={value.from}
          onChange={(val) => dispatchValue({ from: val })}
          placeholder={getTranslation('billing_tariff.starting_from')}
          error={!value.from && value.from !== 0}
        />
        {!value.formulaBased && (
          <MoneyInput
            id={`unit-tariff-${index}`}
            value={value.unitTariff}
            onChange={(val) => dispatchValue({ unitTariff: val })}
            placeholder={getTranslation('billing_tariff.unitTariff')}
            error={typeof value.unitTariff === 'undefined'}
          />
        )}
      </InputContainer>

      <CheckBox id={`is-formula-${index}`} checked={value.formulaBased} onChange={toggleIsFormula}>
        <Description
          title={getTranslation('billing_tariff.is_formula')}
          description="Add a formula to calculate the unit price"
        />
      </CheckBox>

      {value.formulaBased && (
        <div className={css['spinner-parent']}>
          <Heading headingType="h3" style="bold">
            {getTranslation('billing_tariff.formula_builder_heading')}
          </Heading>
          <FormulaBuilder index={index} expression={value.expression} dispatchValue={dispatchValue} />
        </div>
      )}
    </div>
  );
};

export default observer(Slice);
