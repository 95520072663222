import React from 'react';

import { PropertyGroupCalculationConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import useDialog from '@zf/hooks/src/useDialog';

import AddAllocationGroupsDialog from '../../../../../../actions/property-group/add-allocation-group-dialog';
import ConfirmationActionButton from '../../../../../../components/Button/ConfirmationActionButton';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import { useStore } from '../../../../../../hooks/useStore';
import useBillingData from '../../hooks/use-billing-data';
import AllocationGroup from './allocation-group';

type Props = {
  groups: PropertyGroupCalculationConfigurationType[];
};

export default function AllocationGroups(props: Props) {
  const { groups } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { propertyGroupBillingConfiguration, addAllocationGroupsToState, submitAllocationGroup } = useBillingData();

  if (!propertyGroupBillingConfiguration) return null;

  return groups.length > 0 ? (
    <>
      {groups.map((g, index) => {
        return (
          <AllocationGroup
            key={`${g.name}-${index}`}
            groups={groups}
            allocationGroup={g}
            isLast={index === groups.length - 1}
            addAllocationGroupsToState={addAllocationGroupsToState}
          />
        );
      })}
    </>
  ) : (
    <ConfigColumn borderStyle="dashed" addLocation="border">
      <EmptyColumn
        image="location-group"
        title="Allocation groups"
        text={getTranslation('property_groups.tabs.billing.allocation_group_info')}
        extra={
          <ConfirmationActionButton
            id="property_group_billing_config.cost_allocation_add"
            icon="plus"
            dialogTitle={getTranslation('property_groups.tabs.billing.add_first')}
            dialogContent={
              <AddAllocationGroupsDialog
                ref={clickRef}
                validationRef={validationRef}
                propertyGroupBillingConfiguration={propertyGroupBillingConfiguration}
                groups={groups}
                addAllocationGroupsToState={addAllocationGroupsToState}
                submitAllocationGroup={submitAllocationGroup}
              />
            }
            dialogLocation="normal-big"
            validationRef={validationRef}
            buttonPositive={getTranslation('general.add')}
            onSubmit={onSubmit}
          />
        }
      />
    </ConfigColumn>
  );
}
