import React from 'react';

import { useAppContext } from '../../../../../app-context';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../../../components/BreadCrumb';
import { CrumbHeader } from '../../../../../components/Header';
import { isEmptyObject } from '@zf/utils/src/object';
import useIncomingBankingTransactionDetail from '../hooks/useIncomingBankingTransactionDetail';
import IncomingBankingTransactionTabContainer from './IncomingBankingTransactionTabContainer';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

export default function IncomingBankingTransaction(props: Props) {
  const { navigate, urlParams } = props;
  const { i18n, tenantReducer } = useAppContext();
  const [query] = useQueryParams({
    fromPayments: BooleanParam
  });
  const { incomingBankingTransaction, status, navigator, setIncomingBankingTransaction, refresh } =
    useIncomingBankingTransactionDetail(urlParams.id);
  const { rootUrl } = tenantReducer.state;

  if (!incomingBankingTransaction || isEmptyObject(incomingBankingTransaction)) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              icon="list"
              text={i18n.getTranslation('incoming_banking_transactions.incoming_banking_transaction')}
              url={`${rootUrl}/payments/incoming-banking-transactions`}
            />
            <CrumbDivider />
            <Crumb
              icon="batch"
              text={
                Object.keys(incomingBankingTransaction).length > 0
                  ? `${i18n.getTranslation('incoming_banking_transactions.bank_statement')}: ${
                      incomingBankingTransaction.identification
                    }`
                  : ''
              }
            />
          </BreadCrumb>
        }
      />
      <IncomingBankingTransactionTabContainer
        incomingBankingTransaction={incomingBankingTransaction}
        status={status}
        domain={query.fromPayments ? routedEntitySubjectType.payment : undefined}
        navigator={navigator}
        setIncomingBankingTransaction={setIncomingBankingTransaction}
        navigate={navigate}
        refresh={refresh}
      />
    </>
  );
}
