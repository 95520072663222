import { useAppContext } from 'app-context/app-context';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import { colors } from '@zf/utils/src/color';
import { formatPeriod } from '@zf/utils/src/date';

import { Icon } from '../../components/Icon';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import TableServices from '../../components/Services/TableServices';
import { Link } from '../../design-system/Components';
import useCreateRequest from '../../hooks/useCreateRequest';
import useInfiniAPI from '../../hooks/useInfiniAPI';
import { getUniqueServicesForContract } from '../../utils/contract';
import NoContractsOverlay from './NoContractsOverlay';

type Props = {
  serviceLocationId: string;
};

type ContractRowType = {
  __id: string;
  contract: JSX.Element;
  customer: JSX.Element;
  supply: string;
  product: JSX.Element;
  services: JSX.Element;
  state: JSX.Element;
};

const ContractsServiceLocationBody = (props: Props) => {
  const { serviceLocationId } = props;

  const { tenantReducer } = useAppContext();

  const { contractStore, applicationStore } = useStore();
  const { rootUrl, userStore, getTranslation, getEnumTranslation } = applicationStore;
  const { getProductText } = contractStore;
  const { lang } = userStore;

  const tableColumns = useRef<ColumnType[]>([
    {
      width: 150,
      label: getTranslation('contracts.contract'),
      dataKey: 'contract'
    },
    {
      width: 250,
      label: getTranslation('customer.customer'),
      dataKey: 'customer'
    },
    {
      width: 200,
      label: getTranslation('contracts.list.product'),
      dataKey: 'product'
    },
    {
      width: 100,
      label: getTranslation('location.services'),
      dataKey: 'services'
    },
    {
      width: 230,
      label: getTranslation('general.period'),
      dataKey: 'supply'
    },
    {
      width: 80,
      label: getTranslation('general.status'),
      dataKey: 'state'
    }
  ]);

  const { request, setSelectedIds, handleSort, sortBy, sortDirection } = useCreateRequest('/md/contracts', {
    serviceLocationId
  });

  const processRecord = (contract: ContractType) => {
    const customerType = getEnumTranslation('customerType', contract.contractor.customerType);

    return {
      __id: contract.id,
      contract: (
        <Link id={`link-${contract.contractNumber}`} url={`${rootUrl}/contracts/${contract.id}`} icon="contract">
          {contract.contractNumber}
        </Link>
      ),
      customer: (
        <Link
          id={`link-${contract.contractor.shortDisplayName}`}
          url={`${rootUrl}/customers/${contract.contractor.customerId}`}
          icon={customerType}
        >
          {`${contract.contractor.accountNumber} - ${contract.contractor.shortDisplayName}`}
        </Link>
      ),
      supply: formatPeriod(contract.supplyStartDate, contract.supplyEndDate),
      product: (
        <Paragraph color={colors[contract.usePropertyGroupProduct ? 'silver-600' : 'silver-700']}>
          {getProductText(contract)}
        </Paragraph>
      ),
      services: <TableServices utilityTypes={getUniqueServicesForContract(contract)} />,
      state: (
        <Icon
          tooltipFor="contracts-table-tip"
          title={getEnumTranslation('contractStatus', contract.currentContractStatus)}
          type={contract.currentContractStatus}
          color
          usedInTable
        />
      )
    };
  };

  const { loading, error, rows, setStopIndex, sortableFields } = useInfiniAPI<ContractType, ContractRowType>({
    request,
    tenantReducer,
    lang,
    processRecord,
    onSelectRow: setSelectedIds
  });

  return (
    <DynamicVirtualTable
      id="contracts-table"
      tooltipId="contracts-table-tip"
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      rows={rows}
      loading={loading}
      error={error}
      NoDataOverlay={NoContractsOverlay}
      noSelect
      sortableFields={sortableFields}
      sort={handleSort}
      sortBy={sortBy}
      singleSort
      sortDirection={sortDirection}
      totalAmountOfRows={rows.length}
      columns={tableColumns.current}
    />
  );
};

export default observer(ContractsServiceLocationBody);
