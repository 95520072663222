import { MeteringAnalyticsType } from '@zf/api-types/analytics/metering';
import React from 'react';

import DataReceivedCard from '../../../../../../cards/DataReceivedChartCard/DataReceivedChartCard';
import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../../../../../utils/request';
import MeteringUntilChartCard from './metering-until-chart-card';

type Props = {
  propertyGroupId: string;
};

export default function MeteringCharts(props: Props) {
  const response = useSuspenseSingleAPI<MeteringAnalyticsType>({
    request: {
      method: METHODS.GET,
      endpoint: '/insights/Metering',
      query: {
        propertyGroupId: props.propertyGroupId
      }
    }
  });

  if (!response.result) return null;

  const meteringAnalytics = response.result.data;

  return (
    <>
      <DataReceivedCard {...props} meteringAnalytics={meteringAnalytics} />
      <MeteringUntilChartCard {...props} meteringAnalytics={meteringAnalytics} />
    </>
  );
}
