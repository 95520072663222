import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import AsyncCardSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncCardSpinner';

import { useStore } from '../../../../hooks/useStore';
import EmailConfiguartionCardBody from './EmailConfiguartionCardBody';

const EmailConfiguration = () => {
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { communicationParametersForm_ } = communicationStore.settingsStore;

  const communicationDisabled = !communicationParametersForm_?._get('enabled');

  return (
    <AsyncCardSpinner
      title={getTranslation('communication.email_configuration')}
      variables={[communicationParametersForm_]}
      width="3"
    >
      <Card width="3" id="communication-config-settings-languages" disabled={communicationDisabled}>
        <CardHeader>{getTranslation('communication.email_configuration')}</CardHeader>
        <CardBody type="grid" noMaxHeight>
          <EmailConfiguartionCardBody />
        </CardBody>
      </Card>
    </AsyncCardSpinner>
  );
};

export default observer(EmailConfiguration);
