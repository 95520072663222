import { navigate } from '@reach/router';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Button from '../../../../components/Button/Button';

import { useStore } from '../../../../hooks/useStore';
import { ParamsType } from '../../stores/TranslationStore';
import PreviewHeader from './PreviewHeader';
import css from './template-translations.module.scss';
import TemplateTranslationsPreview from './TemplateTranslationsPreview';
import TranslationsCard from './translations-card';

type Props = {
  urlParams: ParamsType;
};

const TemplateTranslationsTab = (props: Props) => {
  const { urlParams } = props;
  const { communicationStore, applicationStore } = useStore();
  const { initTemplateTranslations } = communicationStore.translationStore;

  useEffect(() => {
    initTemplateTranslations(urlParams);
  }, []);

  return (
    <div className={css['pdf-translations-wrapper']}>
      <div className={css['pdf-preview-header']}>
        <PreviewHeader
          entitySubjectType={urlParams.type}
          entitySubjectSubType={urlParams.subType}
          useCase={urlParams.useCase}
        />
      </div>

      <div className={css['pdf-preview-example']}>
        <TemplateTranslationsPreview />
      </div>

      <div className={css['translations-close-preview']}>
        <Button id="close" onClick={() => navigate(`${applicationStore.rootUrl}/configuration/communication`)}>
          {applicationStore.getTranslation('communication.close_editor')}
        </Button>
      </div>

      <div className={css['change-translations']}>
        <TranslationsCard />
      </div>
    </div>
  );
};

export default observer(TemplateTranslationsTab);
