import React from 'react';

import css from './radio-btn.module.scss';

type RadioButtonValuesType = {
  text: string;
  value: string;
};

type Props = {
  id: string;
  values: RadioButtonValuesType[];
  value: string;
  onChange: (val: string) => void;
};

export default function RadioButton(props: Props) {
  const { id, values, value, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={css['radio-btn']}>
      {values.map((v, index) => {
        const enrichedId = `${id}-${index}`;

        return (
          <div className={css['radio-btn-align']} key={enrichedId}>
            <input
              type="radio"
              id={enrichedId}
              name={enrichedId}
              value={v.value}
              onChange={handleChange}
              checked={v.value === value}
            />
            <label htmlFor={enrichedId}>{v.text}</label>
          </div>
        );
      })}
    </div>
  );
}
