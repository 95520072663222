import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { useStore } from '../../../hooks/useStore';
import css from './error-boundary.module.scss';

type Props = {
  children: React.ReactNode;
};

/**
 * Wrap this around CardContainer to catch Mobx store errors
 */
const ErrorBoundary = (props: Props) => {
  const { children } = props;
  const { errorStore } = useStore();
  const { errors, reset } = errorStore;

  let error: undefined | Error;

  if (errors.length > 0) {
    error = errors[0];
  }

  useEffect(() => {
    return () => {
      // Remove errors after viewing them
      reset();
    };
  }, []);

  return (
    <>
      {error ? (
        <>
          <div className={css['error-msg']}>{error?.message}</div>
          <img
            className={css['error-image']}
            src="https://cdn.zerofriction.co/shared/assets/general_error.svg"
            alt="error"
          />
        </>
      ) : (
        children
      )}
    </>
  );
};

export default observer(ErrorBoundary);
