import Customers from 'features/customer/list-page/Customers';
import React from 'react';

import { CustomerGroupDetail } from '@zf/api-types/master-data/customer';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../../app-context';
import { DropdownAction } from '../../../../../design-system/ComponentSets';
import DeleteCustomerGroupDialog from '../../dialogs/DeleteCustomerGroupDialog';

type Props = {
  refresh: () => void;
  location: Location;
  customerGroupDetail: CustomerGroupDetail;
  refreshCounts?: number;
};

export default function CustomerGroupsActions(props: Props) {
  const { location, customerGroupDetail, refresh } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <>
      <DropdownAction
        id="customer-groups.add-customer"
        icon="plus"
        dialogTitle={i18n.getTranslation('customer_groups.add_customers')}
        dialogContent={
          <Customers
            withListPage
            withActions={false}
            location={location}
            ref={clickRef}
            queryParam={{ customerGroupId: customerGroupDetail.id, timeStamp: '' }}
            onComplete={refresh}
          />
        }
        dialogLocation="normal-big"
        onSubmit={onSubmit}
        validationRef={validationRef}
        buttonPositive={i18n.getTranslation('customer.add_customer')}
      >
        {i18n.getTranslation('customer_groups.add_customers')}
      </DropdownAction>

      <DropdownAction
        id="customer-groups.delete"
        icon="trashcan"
        dialogTitle={i18n.getTranslation('general.delete')}
        dialogContent={
          <DeleteCustomerGroupDialog
            {...props}
            ref={clickRef}
            queryParam={{ customerGroupId: customerGroupDetail.id }}
            hasCustomers={customerGroupDetail.amountOfCustomers > 0}
            withSettings={customerGroupDetail.settings.automaticSettlement !== null}
          />
        }
        onSubmit={onSubmit}
        dialogType="danger"
        buttonPositive={i18n.getTranslation('general.delete')}
      />
    </>
  );
}
