import moment from 'moment';
import React, { useCallback, useEffect } from 'react';

import { BillingCompletenessType, RequiredConsumptionQuantityType } from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus, utilityType } from '@zf/api-types/enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import PeriodParagraph from '@zf/stella-react/src/atoms/Paragraph/PeriodParagraph';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../../app-context';
import DaysOverdueBadge from '../../../components/analytics/days-overdue-badge';
import TableServices from '../../../components/Services/TableServices';
import { ICON_COLORS } from '../../../constants/icons';
import { Link } from '../../../design-system/Components';
import { Paragraph } from '../../../design-system/Foundation';
import useCreateRequest from '../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../hooks/useInfiniAPI';
import { getAllRequiredQuantitiesForCompleteness, isRefreshStatus } from '../../../utils/completeness';
import Advances from '../advances';
import { UnbilledState, useTracked } from '../context/unbilled-context';
import { UnbilledListQueryParams } from '../unbilled-list-card';

export type State = {
  currentId: string;
  firstRefresh: boolean;
  actionTriggered: boolean;
  timeStamp: number | undefined;
  timeOut: number | undefined;
  selectedItems: string[];
};

export type SetState = Partial<State>;

export type RowTypeDetails = {
  __id: string;
  __entity: BillingCompletenessType;
  period: JSX.Element;
  dash: JSX.Element;
  p_end: JSX.Element;
  advances: React.ReactNode;
  status: JSX.Element;
};

export type RowTypeUnbilled = {
  __id: string;
  __entity: BillingCompletenessType;
  contractId: JSX.Element;
  missingAdvances: JSX.Element;
  PeriodStartDateTime: JSX.Element;
  status: JSX.Element;
  invoiceType: React.ReactNode;
  overdue: JSX.Element;
  customer: JSX.Element;
  missingFor: JSX.Element;
};

const checkMissingInputs = (requiredQuantities: RequiredConsumptionQuantityType[]) => {
  const utilityTypes = requiredQuantities.reduce((acc: utilityType[], rc: RequiredConsumptionQuantityType) => {
    // Check if incomplete
    const isInComplete = rc.askingPeriods.some((ap) => !ap.complete);

    // If incomplete, add an icon based on the combination's utility type to our result
    if (isInComplete && !acc.includes(rc.utilityType)) {
      acc.push(rc.utilityType);
    }

    return acc;
  }, []);

  return <TableServices utilityTypes={utilityTypes} />;
};

const buildQuery = (queryParams?: UnbilledListQueryParams, timeStamp?: number) => {
  let query = {} as UnbilledListQueryParams;

  if (queryParams) {
    let onlyOpen = false;

    const periodEndDate = moment(queryParams.periodEndDateTime);
    if (typeof queryParams.onlyOpen !== 'undefined') onlyOpen = queryParams.onlyOpen as boolean;

    query = {
      onlyOpen,
      timestamp: timeStamp,
      status: queryParams.selectedStatus ? queryParams.selectedStatus[0] : ('' as billingCompletenessStatus),
      periodEndDateTime: periodEndDate.toISOString(),
      periodStartDateTime: queryParams.periodStartDateTime,
      flexSearch: queryParams.searchValue || '',
      invoicingCheckpointEndDateTime: queryParams.invoicingCheckpointEndDateTime,
      invoicingCheckpointStartDateTime: queryParams.invoicingCheckpointStartDateTime,
      invoiceType: queryParams.invoiceType,
      quickfilter: queryParams.quickfilter
    } as UnbilledListQueryParams;

    if (queryParams.propertyGroupId) {
      query.propertyGroupId = queryParams.propertyGroupId;
    }
  }

  return query;
};

export default function useUnbilled(queryParams?: UnbilledListQueryParams) {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const { request, sortBy, sortDirection, handleSort } = useCreateRequest(
    '/bill/BillingCompletenesses',
    buildQuery(queryParams, state.timeStamp)
  );

  const setState = useCallback((newState: Partial<UnbilledState>) => {
    dispatch({
      type: 'SET_STATE',
      newState: newState
    });
  }, []);

  const processRecord = (completeness: BillingCompletenessType): RowTypeUnbilled => {
    return {
      __id: completeness.id,
      __entity: completeness,
      contractId: (
        <Link
          id={`contract-${completeness.contractNum}`}
          url={`${rootUrl}/contracts/${completeness.contractId}`}
          icon={completeness.blocked ? 'lock' : undefined}
        >
          {completeness.contractNum}
        </Link>
      ),
      customer: (
        <Link
          id={`customer-${completeness.debtor.customerId}`}
          url={`${rootUrl}/customers/${completeness.debtor.customerId}`}
        >
          {`${completeness.debtor.customerAccountNumber} - ${completeness.debtor.displayName}`}
        </Link>
      ),
      invoiceType: enumReducer.getTranslation('invoiceType', completeness.invoiceType),
      PeriodStartDateTime: (
        <PeriodParagraph startDate={completeness.periodStartDateTime} endDate={completeness.periodEndDateTime} />
      ),
      overdue: (
        <Center type="both">
          <DaysOverdueBadge tooltipFor="unbilled-table-tip" referenceDate={completeness.periodEndDateTime} />
        </Center>
      ),
      missingAdvances:
        completeness.advancePeriods.length > 0 ? (
          <Advances
            key={completeness.id}
            tooltipFor="unbilled-table-tip"
            advancePeriods={completeness.advancePeriods}
          />
        ) : (
          <Paragraph>{i18n.getTranslation('general.na')}</Paragraph>
        ),
      missingFor: (
        <>
          {checkMissingInputs(
            getAllRequiredQuantitiesForCompleteness(completeness) as RequiredConsumptionQuantityType[]
          )}
        </>
      ),
      status: (
        <NewStatusBadge
          status_={enumReducer.getTranslation('billingCompletenessStatus', completeness.status)}
          color={ICON_COLORS[completeness.status]}
        />
      )
    };
  };

  const { rows, sortableFields, loading, totalAmountOfRows, error, updateGivenRows, setStopIndex } = useInfiniAPI<
    BillingCompletenessType,
    RowTypeUnbilled
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  useEffect(() => {
    handleSort({
      sortBy: ['PeriodEndDateTime'],
      sortDirection: {
        PeriodEndDateTime: 'ASC'
      }
    });

    return () => {
      clearTimeout(state.timeOut);
    };
  }, []);

  useEffect(() => {
    if (state.timeStamp && request) {
      request.timeStamp = state.timeStamp;
      if (state.timeOut) {
        clearTimeout(state.timeOut);
      }

      const timeOut = window.setTimeout(() => setState({ timeStamp: Date.now() }), 5000);

      setState({ timeOut: timeOut });
    }
  }, [state.timeStamp]);

  useEffect(() => {
    const hasToRefresh = rows.some((r) => {
      if (r.__entity) {
        // Only check the selected items, not the entire list
        if (state.selectedItems.includes(r.__entity.id) && isRefreshStatus(r.__entity.status)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    if (!hasToRefresh) {
      clearTimeout(state.timeOut);
      setState({ timeStamp: undefined, timeOut: undefined });
    }
  }, [rows, state.currentId]);

  useEffect(() => {
    setState({ rows: rows, updateGivenRows: updateGivenRows });
  }, [rows, updateGivenRows]);

  return {
    isRefreshing: state.timeStamp || state.firstRefresh,
    timeOut: state.timeOut,
    timeStamp: state.timeStamp,
    selectedItems: state.selectedItems,
    currentId: state.currentId,
    actionTriggered: state.actionTriggered,
    sortBy,
    sortDirection,
    sortableFields,
    rows,
    error,
    loading,
    totalAmountOfRows,
    handleSort,
    setStopIndex,
    updateGivenRows,
    setState
  };
}
