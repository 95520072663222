import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../../../../app-context';
import { SuspenseCardBody } from '../../../../../../../../components/suspense';
import { dialog } from '../../../../../../../../events/dialog-events';
import { useStore } from '../../../../../../../../hooks/useStore';
import ChangePaymentMethodDialog from '../../dialogs/ChangePaymentMethodDialog';
import RelatedDetailsCardBody from './RelatedDetailsCardBody';

export type PropsType = {
  invoice: InvoiceType;
};

const RelatedDetailsCard = (props: PropsType) => {
  const { invoice } = props;

  const { applicationStore, invoiceStore } = useStore();
  const { getTranslation } = applicationStore;
  const { relatedDetailsForm, initRelatedDetailsForm } = invoiceStore;

  const { authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const actionsArePermitted = relatedDetailsForm?.isDirty && hasPermission('invoice.update_payment_method');
  const { clickRef, onSubmit } = useDialog();

  useEffect(() => {
    initRelatedDetailsForm(invoice);
  }, [invoice.id]);

  if (!relatedDetailsForm) return null;

  const openDialog = () => {
    if (relatedDetailsForm) {
      dialog.normal({
        title: getTranslation('invoice.payment_method_changed'),
        icon: 'bank',
        content: (
          <ChangePaymentMethodDialog ref={clickRef} invoiceId={invoice.id} relatedDetailsForm={relatedDetailsForm} />
        ),
        buttonPositive: getTranslation('general.submit'),
        onSubmit,
        onCancel: relatedDetailsForm._reset
      });
    }
  };

  const handleSubmit = async () => {
    if (relatedDetailsForm.dialogEnabled) {
      openDialog();
    } else {
      await relatedDetailsForm.save();
    }
  };

  return (
    <Card id="invoice-details-card">
      <CardHeader
        primaryText={actionsArePermitted ? getTranslation('general.save') : undefined}
        onPrimaryClick={handleSubmit}
        secondaryText={actionsArePermitted ? getTranslation('general.cancel') : undefined}
        onSecondaryClick={relatedDetailsForm._reset}
      >
        {getTranslation('invoice.related_details')}
      </CardHeader>
      <SuspenseCardBody type="grid" noMaxHeight>
        <RelatedDetailsCardBody invoice={invoice} />
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(RelatedDetailsCard);
