import Interweave from 'interweave';
import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import { navigate } from '@reach/router';
import {
  AdvanceAmountLimitType,
  billingRelationScenarioType,
  communicationType,
  entitySubjectType,
  invoiceStatus,
  invoiceType,
  pluginType
} from '@zf/api-types/enums';
import { AdvanceLimit } from '@zf/api-types/integration/plugin';
import { InvoiceType } from '@zf/api-types/invoice';
import { ContractType } from '@zf/api-types/master-data/contract';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { CoachMark } from '@zf/stella-react/src/atoms/CoachMark';
import { colors } from '@zf/utils/src/color';
import { formatDate, isMinDate } from '@zf/utils/src/date';
import { formatMoney, roundNumberToDecimals } from '@zf/utils/src/number';

import { actionQueryType } from '../../../../../app-context/services/integration/CustomPluginActionsService';
import IconParagraph from '../../../../../components/Icon/IconParagraph';
import MoneyInput from '../../../../../components/input/MoneyInput';
import { Button, TooltipTrigger } from '../../../../../design-system/Components';
import { Info } from '../../../../../design-system/ComponentSets';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import Paragraph from '../../../../../design-system/Foundation/Paragraph/Paragraph';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import css from './change-advance-amount-dialog.module.scss';

type Props = {
  contract: ContractType;
  overrulingInvoice: InvoiceType | undefined;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

type VisualRange = {
  rangeMin: number;
  rangeMax: number;
};

type State = {
  sendEmail: boolean;
  allowCustomerToChangeAdvanceAmount: boolean;
  newAdvAmount: number | undefined;
  portalIsActive: boolean;
  visualRange: VisualRange | undefined;
  advanceLimit: AdvanceLimit | undefined;
  canSendEmail: boolean;
};

const ChangeAdvanceAmountDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { contract, overrulingInvoice, validationRef } = props;
  const { applicationStore, contractStore, configStore, integrationStore, communicationStore } = useStore();
  const { rootUrl, culture, getTranslation } = applicationStore;

  const {
    billingRelation_,
    billingInsights_,
    contractApiService,
    setBillingRelation,
    setExistingContract,
    getChangedBy
  } = contractStore;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    sendEmail: false,
    allowCustomerToChangeAdvanceAmount: false,
    newAdvAmount: undefined,
    visualRange: undefined,
    portalIsActive: false,
    advanceLimit: undefined,
    canSendEmail: false
  });

  const inputIsNotValid = () => {
    return (
      typeof state.newAdvAmount !== 'number' ||
      isNaN(state.newAdvAmount) ||
      state.newAdvAmount ===
        (billingRelation_?.advanceDetails.advanceAmount || contract.billingDetails.contractualAdvanceAmount)
    );
  };

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(inputIsNotValid());
    }
  };

  const getVisualRange = (advanceAmount: number, advanceLimit: AdvanceLimit) => {
    if (advanceLimit.type === AdvanceAmountLimitType.percentage) {
      return {
        rangeMin: roundNumberToDecimals(advanceAmount * advanceLimit.rangeMin, 2),
        rangeMax: roundNumberToDecimals(advanceAmount * advanceLimit.rangeMax, 2)
      };
    } else {
      return {
        rangeMin: advanceLimit.rangeMin,
        rangeMax: advanceLimit.rangeMax
      };
    }
  };

  useEffect(() => {
    validate();
  }, [state.newAdvAmount, state.visualRange]);

  useEffect(() => {
    let initializedState: Partial<State> = {};

    Promise.all([
      configStore.configService.getConfiguredPlugins(),
      communicationStore.communicationService.getScenario(
        entitySubjectType.billingrelation,
        billingRelationScenarioType.advanceamountchanged
      )
    ])
      .then((results) => {
        initializedState.portalIsActive = results[0]?.some(
          (p) => p.pluginType === pluginType.enduserportal && p.enabled
        );

        initializedState.canSendEmail =
          !!billingRelation_ && results[1]?.defaultCommunicationType !== communicationType.none;

        initializedState.allowCustomerToChangeAdvanceAmount =
          results[0]?.[0]?.settings.billingSettings.allowCustomerToChangeAdvanceAmount;
      })
      .then(async () => {
        if (initializedState.portalIsActive) {
          return (
            await integrationStore.customPluginActionsService.getCustomPluginActionsForPluginAndQuery<{
              data: AdvanceLimit;
            }>(pluginType.enduserportal, actionQueryType.getadvancelimit)
          ).data;
        }
      })
      .then((advanceLimit) => {
        if (advanceLimit) {
          initializedState.advanceLimit = advanceLimit;
          initializedState.visualRange = getVisualRange(
            billingRelation_?.advanceDetails.advanceAmount || contract.billingDetails.contractualAdvanceAmount,
            advanceLimit
          );
        }

        setState(initializedState);
      });
  }, []);

  useImperativeHandle(ref, () => ({
    async onClick() {
      const apiFriendlyAdvAmount = state.newAdvAmount || 0;

      try {
        if (billingRelation_) {
          const updatedBillingRelation = await contractApiService.changeBillingRelationAdvanceAmount(
            billingRelation_.id,
            apiFriendlyAdvAmount,
            state.sendEmail
          );
          setBillingRelation(updatedBillingRelation);
        } else {
          const updatedContract = await contractApiService.changeContractualAdvanceAmount(
            contract,
            apiFriendlyAdvAmount
          );
          setExistingContract(updatedContract);
        }

        notify.success({
          content: getTranslation('contracts.change_advance_amount_success', {
            advanceAmount: formatMoney(apiFriendlyAdvAmount, culture)
          })
        });
      } catch (e) {
        notify.error({
          content: getTranslation('contracts.change_advance_amount_failed'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['change-advance-amount-dialog']}>
      <Paragraph>
        {`${getTranslation('contracts.current_advance_amount')}: `}
        <strong>
          {formatMoney(
            billingRelation_?.advanceDetails.advanceAmount || contract.billingDetails.contractualAdvanceAmount,
            culture
          )}
        </strong>
      </Paragraph>
      {billingRelation_ &&
        !isMinDate(billingRelation_.advanceDetails.changedDate) &&
        billingRelation_.advanceDetails.changedBy && (
          <Paragraph className={css['change-advance-amount-changed-by']} color={colors['silver-600']}>
            {getTranslation('contracts.last_change', {
              changedDate: formatDate(billingRelation_.advanceDetails.changedDate),
              changedBy: getChangedBy(billingRelation_)
            })}
          </Paragraph>
        )}

      {overrulingInvoice &&
        overrulingInvoice.type === invoiceType.invoice &&
        overrulingInvoice.status === invoiceStatus.created && (
          <>
            <div className={css['warning']}>
              <IconParagraph iconType="alert-circle">
                {getTranslation('contracts.advance_amount_warning1')}
              </IconParagraph>
              <Button
                id="navigate-overruling-invoice"
                className={css['overruling-invoice-btn']}
                type="text"
                onClick={() => {
                  navigate(`${rootUrl}/invoices/${overrulingInvoice.id}`);
                  if (validationRef.current) {
                    validationRef.current.onCancel();
                  }
                }}
              >
                {getTranslation('invoice.draft_invoice')}
              </Button>
            </div>
            <Paragraph color={colors['orange-600']}> {getTranslation('contracts.advance_amount_warning2')}</Paragraph>
          </>
        )}

      <div className={css['change-advance-amount-input']}>
        <MoneyInput
          id="contract.advanceAmount"
          placeholder={getTranslation('contracts.new_advance_amount')}
          value={state.newAdvAmount}
          onChange={(newAdvAmount) => {
            if (state.advanceLimit && state.portalIsActive) {
              setState({
                newAdvAmount,
                visualRange: getVisualRange(newAdvAmount, state.advanceLimit)
              });
            } else {
              setState({
                newAdvAmount
              });
            }
          }}
          error={inputIsNotValid()}
        />
        {state.portalIsActive && state.allowCustomerToChangeAdvanceAmount && (
          <div className={css['change-advance-amount-limit']}>
            <Paragraph
              className={css['change-advance-amount-limit-paragraph']}
              textAlign="left"
              color={colors['silver-600']}
            >
              {getTranslation('contracts.portal_advance_limit', {
                min: formatMoney(state.visualRange?.rangeMin, culture),
                max: formatMoney(state.visualRange?.rangeMax, culture)
              })}
            </Paragraph>
            <TooltipTrigger
              tooltip={
                <Paragraph>
                  <Interweave content={getTranslation('contract.advance_amount_limit_extra')} />
                </Paragraph>
              }
            >
              <CoachMark className={css['info']} usePopup={false} size="small" info />
            </TooltipTrigger>
          </div>
        )}
      </div>

      {state.canSendEmail && (
        <CheckBox id="sendEmail" checked={state.sendEmail} onChange={() => setState({ sendEmail: !state.sendEmail })}>
          {getTranslation('contracts.advance_amount_email_confirmation')}
        </CheckBox>
      )}

      {billingInsights_?.hasOpenAdvancePeriodsWithDifferentTaxRate && (
        <Info>{getTranslation('actions.contract.change_advance_amount_warning')}</Info>
      )}
    </div>
  );
});

export default observer(ChangeAdvanceAmountDialog);
