import { observer } from 'mobx-react';
import React from 'react';

import { templateUsecase, translationStatus } from '@zf/api-types/enums';
import ActionDescription from '@zf/stella-react/src/atoms/ActionDescription/ActionDescription';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { colors } from '@zf/utils/src/color';

import { ICON_COLORS } from '../../../../../constants/icons';
import { useStore } from '../../../../../hooks/useStore';
import css from './custom-translations.module.scss';

type Props = {
  templateUsecase_: templateUsecase;
};

const CustomTranslations = (props: Props) => {
  const { templateUsecase_ } = props;
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { selectedScenario } = communicationStore.templatesStore;

  let template =
    templateUsecase_ === templateUsecase.pdf ? selectedScenario?.pdfTemplate : selectedScenario?.emailTemplate;

  const descriptionTitle = getTranslation('communication.custom_translations');
  const description = getTranslation('communication.custom_translations_descr');

  if (!template) return null;

  return (
    <CardItem width="12">
      <ActionDescription
        title={descriptionTitle}
        description={description}
        action={
          <div className={css['status-and-edit']}>
            {!template.translationStatus || template.translationStatus === translationStatus.defaulttranslation ? (
              <NewStatusBadge
                status_={getEnumTranslation('translationStatus', template.translationStatus)}
                color={colors['silver-700']}
              />
            ) : (
              <NewStatusBadge
                status_={getEnumTranslation('translationStatus', template.translationStatus)}
                color={ICON_COLORS[template.translationStatus]}
              />
            )}
          </div>
        }
      />
    </CardItem>
  );
};

export default observer(CustomTranslations);
