import { MultiValue } from 'design-system/ComponentSets';
import React from 'react';

import { unitOfMeasure, utilityType } from '@zf/api-types/enums';
import { ContractType } from '@zf/api-types/master-data/contract';
import { AdjacentMeasurementPairs, MeterType } from '@zf/api-types/master-data/meter';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../../../../../app-context/app-context';
import useSingleAPI from '../../../../../../hooks/useSingleAPI';
import Measurement from './Measurement';
import { MeterNodeValueType } from './MeterMultiValue';

type Props = {
  meterIndex: number;
  meterNodeValue: MeterNodeValueType;
  existingMeter?: MeterType;
  existingContract: ContractType | undefined;
  mutationDateTime: string;
  dispatchValue: (value: Partial<MeterNodeValueType>) => void;
};

export default function MeasurementMultiValue(props: Props) {
  const { meterIndex, existingMeter, meterNodeValue, existingContract, mutationDateTime, dispatchValue } = props;
  const { i18n } = useAppContext();

  const measurementsResponse = useSingleAPI<AdjacentMeasurementPairs>({
    request: {
      endpoint: `/me/Measurements/m/${existingMeter?.id}`,
      query: { measurementDate: mutationDateTime }
    },
    mayExecute: !!existingMeter
  });

  if (existingMeter && (!measurementsResponse.result || measurementsResponse.result instanceof Promise))
    return (
      <Center type="both">
        <Spinner size="medium" />
      </Center>
    );

  const pairs = measurementsResponse.result ? measurementsResponse.result.data.pairs : undefined;

  return (
    <MultiValue
      id="measurements"
      title={i18n.getTranslation('meter.measurements')}
      initialValues={meterNodeValue.measurements}
      intialNodeValue={{
        internalMeterId: meterNodeValue.internalMeterId || '',
        externalChannelIdentifier: '',
        meterSerialNumber: '',
        utilityType: '' as utilityType,
        unitOfMeasure: '' as unitOfMeasure,
        timeOfUse: '',
        value: 0,
        errors: []
      }}
      onChange={(measurements) => dispatchValue({ measurements })}
      withBorder
    >
      {({ value, index: measurementIndex, dispatchValue: dispatchValue_ }) => {
        return (
          <Measurement
            meterIndex={meterIndex}
            existingMeter={existingMeter}
            pairs={pairs}
            existingContract={existingContract}
            mutationDateTime={mutationDateTime}
            measurementIndex={measurementIndex}
            measurement={value}
            dispatchValue={dispatchValue_}
          />
        );
      }}
    </MultiValue>
  );
}
