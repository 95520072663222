import 'react-virtualized/styles.css';

import classNames from 'classnames';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import ReactTooltip from 'react-tooltip';
import { Column, SortDirection, SortDirectionType, Table, TableHeaderProps } from 'react-virtualized';

import { NotifierType } from '@zf/heat-erp/src/design-system/Components/Toast/types/notifications';
import { getTooltipContent, rebuildTooltip } from '@zf/utils/src/tooltip';

import Center from '../../../helpers/Center';
import Spinner from '../../Spinner/Spinner';
import css from './stella-dynamic-index-table.module.scss';
import StellaSortIndicator from './StellaSortIndicator';
import cellRenderer from './TableCell';
import { headerRowRenderer } from './TableHeader';
import TableRow from './TableRow';

const DEFAULT_WIDTH = 100;

export type SortDirectionMap = { [key: string]: SortDirectionType | '' };

export type ConfigState<T> =
  | {
      sortableFields: Record<string, '' | SortDirectionType>;
      responseData: T;
    }
  | undefined;

export type ColumnType = {
  dataKey: string;
  width?: number;
  label?: string;
  flexWidth?: number;
};

export type StellaDynamicIndexTableProps<T> = {
  rows: Record<string, React.ReactNode>[];
  columns: ColumnType[];
  isDirty?: boolean;
  tooltipId?: string;
  selectedRow?: number;
  scrollToIndex?: number;
  currentConfig?: ConfigState<T>;
  notifier?: NotifierType;
  warning?: string;
  isLoading?: boolean;
  setSelectedRow?: (index: number) => void;
  onRowMouseOver?: (index: number) => void;
  onRowMouseOut?: (index: number) => void;
  setCurrentConfig?: (value: Partial<ConfigState<any>>) => void;
  sort?: (sortby: string, sortDirection: SortDirectionType | '') => void;
  cursorPointer?: boolean;
  disableHeader?: boolean;
  sortableFields?: Record<string, SortDirectionType | ''>;
};

export default React.memo(function StellaDynamicIndexTable<T>(props: StellaDynamicIndexTableProps<T>) {
  const {
    rows,
    sortableFields = {},
    columns,
    tooltipId,
    selectedRow,
    scrollToIndex,
    isDirty,
    isLoading = false,
    disableHeader = false,
    setSelectedRow,
    onRowMouseOver,
    sort,
    onRowMouseOut,
    cursorPointer
  } = props;

  const handleSort = React.useCallback(
    (sortby: string, sortDirection: '' | SortDirectionType) => {
      let newDirection: SortDirectionType | '' = '';


      switch (sortDirection) {
        case 'ASC':
          newDirection = 'DESC';
          break;
        case 'DESC':
          break;
        case '':
          newDirection = 'ASC';
          break;
      }
      
      if (sort) sort(sortby, newDirection);
    },
    [sort, sortableFields, isDirty]
  );

  const sortByLowerCase = Object.keys(sortableFields).map((key) => {
    return key.charAt(0).toLowerCase() + key.substring(1);
  });

  // This is a custom header single cell
  const headerRenderer = ({ dataKey, label }: TableHeaderProps) => {
    const key = dataKey.toLowerCase()
    const showSortIndicator = sortByLowerCase.includes(key);

    return (
      <div
        onClick={() => {
          if (sort) handleSort(key, sortableFields?.[key]);
        }}
        className={classNames(css['cell-wrapper'], showSortIndicator && css['sort-trigger'])}
      >
        <div>{label}</div>
        {showSortIndicator && (
          <div
            role="button"
            className={css['header-cell']}
            tabIndex={0}
            onKeyDown={() => {
              // Do nothing
            }}
          >
            {sort && <StellaSortIndicator sortDirection={sortableFields[key]} />}
          </div>
        )}
      </div>
    );
  };

  const rowGetter = React.useCallback(
    ({ index }: { index: number }) => {
      return rows[index];
    },
    [rows]
  );

  const _rowRenderer = React.useCallback(
    // Can't add to TableRowProps, not sure why, hence the any
    (props_: any) =>
      TableRow({
        ...props_,
        selectedRow,
        setSelectedRow,
        onRowMouseOver,
        onRowMouseOut,
        cursorPointer
      }),
    [selectedRow, cursorPointer, setSelectedRow, onRowMouseOver, onRowMouseOut]
  );

  const noRowsRenderer = React.useCallback(() => {
    return isLoading ? (
      <Center type="both">
        <Spinner size="medium" />
      </Center>
    ) : null;
  }, [isLoading]);

  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width = 0, height = 0, targetRef }) => {
        return (
          <div className={css['table-wrapper']} ref={targetRef as React.RefObject<HTMLDivElement> | undefined}>
            <Table
              headerRowRenderer={headerRowRenderer}
              rowRenderer={_rowRenderer}
              width={width}
              height={height}
              headerHeight={20}
              rowHeight={50}
              rowCount={rows.length}
              rowGetter={rowGetter}
              noRowsRenderer={noRowsRenderer}
              scrollToIndex={scrollToIndex}
              onScroll={rebuildTooltip}
              disableHeader={disableHeader}
            >
              {columns.map((c, index) => {
                return (
                  <Column
                    key={`${c.label}-${index}`}
                    label={c.label}
                    dataKey={c.dataKey}
                    flexGrow={c.flexWidth}
                    width={c.width || DEFAULT_WIDTH}
                    cellRenderer={cellRenderer}
                    headerRenderer={headerRenderer}
                  />
                );
              })}
            </Table>
            {rows.length > 0 && tooltipId && (
              <ReactTooltip
                id={tooltipId}
                className={css['simple-tooltip']}
                type="light"
                place="left"
                getContent={getTooltipContent}
              />
            )}
          </div>
        );
      }}
    </ReactResizeDetector>
  );
});
