import React from 'react';

import DSSelect, { SelectProps } from '../../design-system/ComponentSets/Select/Select';
import AccessControl from '../Permissions/AccessControl';

export default function Select<T>(props: SelectProps<T>) {
  const { id } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<DSSelect {...props} disabled />}>
      <DSSelect {...props} />
    </AccessControl>
  );
}
