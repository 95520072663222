import classNames from 'classnames';
import React from 'react';

import { Paragraph } from '@zf/heat-erp/src/design-system/Foundation';
import { colors } from '@zf/utils/src/color';

import Droppeable from '../DragAndDrop/droppeable';
import { Icon } from '../Icon';
import css from './dropzone.module.scss';

export type DropzoneProps = {
  id: string;
  heading?: React.ReactNode;
  content?: React.ReactNode;
  className?: string;
  iconType?: string;
  error?: boolean;
  onDrop: (files: File[]) => void;
  onTrigger: () => void;
};

export default function StellaDropzone(props: DropzoneProps) {
  const {
    heading = 'Drag and Drop files here',
    content = 'click to browse files',
    iconType = 'attachment',
    className,
    error = false,
    onDrop,
    onTrigger,
    id
  } = props;

  return (
    <Droppeable
      id={id}
      className={classNames(className, { [css['error']]: error })}
      onDrop={(e: React.DragEvent<HTMLSpanElement>) => onDrop([...e.dataTransfer.files])}
      onTrigger={onTrigger}
    >
      {({ isDragging }) => {
        return (
          <div
            className={classNames(
              css['dropzone'],
              {
                [css['dropzone-dragged']]: isDragging
              },
              className
            )}
          >
            <Icon name={iconType} />
            <div className={css['text']}>
              <Paragraph color={colors['silver-600']} bold>
                {heading}
              </Paragraph>
              <Paragraph className={css['content']} color={colors['silver-600']}>
                {content}
              </Paragraph>
            </div>
          </div>
        );
      }}
    </Droppeable>
  );
}
