import SearchBar from 'components/input/SearchBar';
import DynamicVirtualTable from 'components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import TableServices from 'components/Services/TableServices';
import { InfoBanner } from 'design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { getUniqueServicesForContract } from 'utils/contract';

import { formatDate } from '@zf/utils/src/date';

import css from './remove-locations-table.module.scss';

const RemoveLocationsTable = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { contract } = contractStore.moveOutWizardBaseStore;
  const { removeLocationsWizardValues, locationSearch, selectedIds, setLocationSearch, setSelectedIds } =
    contractStore.moveOutWizardBaseStore.removeLocationsWizardStore;
  const { supplyDate, removableLocations } = removeLocationsWizardValues.values;

  const columns = useRef([
    {
      width: 700,
      label: getTranslation('location.address'),
      dataKey: 'address'
    },
    {
      width: 100,
      label: getTranslation('location.services'),
      dataKey: 'services'
    }
  ]);

  if (!contract) return null;

  let filteredLocations = [...removableLocations];

  if (locationSearch) {
    filteredLocations = filteredLocations.filter((l) =>
      l.address?.localizedDisplay?.toLowerCase().includes(locationSearch.toLowerCase())
    );
  }

  const rows = filteredLocations.map((l) => {
    return {
      __id: l.id,
      address: l.address?.localizedDisplay,
      services: <TableServices utilityTypes={getUniqueServicesForContract(contract)} />
    };
  });

  const contractIsEmpty = selectedIds.length === removableLocations.length;

  return (
    <>
      <SearchBar
        id="contracts.locations-searchbar"
        onChange={setLocationSearch}
        value={locationSearch}
        placeholder={getTranslation('location.search')}
        debounceTime={500}
      />

      <div className={css['table-wrapper']}>
        <DynamicVirtualTable
          id="locations-table"
          rows={rows}
          totalAmountOfRows={rows.length}
          selectedRows={selectedIds}
          columns={columns.current}
          onSelectRow={setSelectedIds}
        />
      </div>

      {contractIsEmpty && (
        <InfoBanner
          info={getTranslation('contracts.wizard.terminate.no_locations_warning')}
          color="orange"
          roundedCorners
        />
      )}

      {!contractIsEmpty && (
        <Paragraph className={css['locations-summary']}>
          <Interweave
            content={getTranslation('contracts.wizard.terminate.locations_summary', {
              amount: selectedIds.length,
              contractNumber: contract.contractNumber,
              supplyDate: formatDate(supplyDate)
            })}
          />
        </Paragraph>
      )}
    </>
  );
};

export default observer(RemoveLocationsTable);
