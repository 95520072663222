import classNames from 'classnames';
import React, { Children } from 'react';

import css from './info-grid.module.scss';
import InfoGridColumn from './InfoGridColumn';

type InfoGridType = 'grey' | 'transparent';

type Props = {
  children: React.ReactNode;
  className?: string;
  type?: InfoGridType;
  nCols?: number;
};

export default function InfoGrid(props: Props) {
  const { type = 'transparent', children, className } = props;
  let { nCols = 3 } = props;

  let dict: Record<number, React.ReactNode[]> = {};

  const childrenArray = Children.toArray(children);

  if (nCols === 3 && childrenArray.length < 3) {
    nCols = childrenArray.length;
  }

  for (let i = 0; i < nCols; i++) {
    dict[i] = [];
  }

  childrenArray.forEach((dl, index) => {
    dict[index % nCols].push(dl);
  });

  return (
    <div className={classNames(css['info-grid'], { [css['grey']]: type === 'grey' }, className)}>
      {Object.keys(dict).map((k, index) => (
        <InfoGridColumn key={`${k}-${index}`}>
          {
            // @ts-ignore
            dict[k]
          }
        </InfoGridColumn>
      ))}
    </div>
  );
}
