import React from 'react';
import classNames from 'classnames';

import css from './wizard-input-wrapper.module.scss';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  errorValue?: string;
  value?: any;
  multiSelect?: boolean;
};

export default function WizardInputWrapper<P>(Input: (props: P) => JSX.Element) {
  return function (props: Props & P) {
    const { children, className, style, errorValue } = props;

    return (
      <div className={classNames(className, css['wizard-input-wrapper'])} style={style}>
        {React.createElement(
          Input,
          {
            ...props
          },
          children
        )}
        {errorValue && <div className={css['wizard-input-indicator-container']}>{errorValue}</div>}
      </div>
    );
  };
}
