import React from 'react';

import { IncomingAndOutgoingDataType } from '@zf/api-types/analytics/billing';

import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../../../../../utils/request';
import IncomingOutgoingChartCard from './incoming-outgoing-chart-card';
import RevenueChartCard from './revenue-chart-card';

type Props = {
  propertyGroupId: string;
};

export default function IncomingOutgoingCharts(props: Props) {
  const { propertyGroupId } = props;

  const response = useSuspenseSingleAPI<IncomingAndOutgoingDataType>({
    request: {
      method: METHODS.GET,
      endpoint: '/insights/Billing/costallocation',
      query: {
        propertyGroupId: propertyGroupId
      }
    }
  });

  if (!response.result) return null;

  const incomingOutgoingData = response.result.data.inAndOutgoingInvoices;

  return (
    <>
      <RevenueChartCard incomingOutgoingData={incomingOutgoingData} />
      <IncomingOutgoingChartCard incomingOutgoingData={incomingOutgoingData} />
    </>
  );
}
