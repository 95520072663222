import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { navigate } from '@reach/router';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';
import { colors } from '@zf/utils/src/color';
import { sortByDate } from '@zf/utils/src/date';

import TransparentButton from '../../../../../../components/Button/TransparentButton';
import { ICON_COLORS, ICONS } from '../../../../../../constants/icons';
import { useStore } from '../../../../../../hooks/useStore';
import { generateAdvanceBtnTitle, getAdvanceStatus } from '../../../../../../utils/completeness';
import { AdvanceIconType } from './Advance';
import css from './advances.module.scss';
import AdvanceTooltip from './AdvanceTooltip';

const Advances = observer(() => {
  const { contractStore, applicationStore } = useStore();
  const { selectedCompleteness } = contractStore.billingCompletenessStore;
  const { rootUrl, getTranslation } = applicationStore;

  return (
    <div className={css['advance-btns']}>
      {sortByDate([...selectedCompleteness.advancePeriods], 'endDateTime', 'ASC').map((ap, index) => {
        const advanceStatus = getAdvanceStatus(ap);
        const status = getAdvanceStatus(ap);
        const statusTransl = getTranslation(`invoice.${status}`);

        return (
          <div key={`${ap.invoiceId}-${index}`}>
            <TooltipTrigger
              tooltip={
                <AdvanceTooltip
                  advancePeriod={ap}
                  statusTranslation={statusTransl}
                  advanceIconType={ICONS[advanceStatus] as AdvanceIconType}
                />
              }
              placement="bottom-start"
            >
              <TransparentButton
                id={`${ap.invoiceId}-${index}`}
                className={classNames({
                  [css['deleted']]: ap.automaticallyDeleted || ap.manuallyDeleted
                })}
                size="small"
                color={advanceStatus !== 'inFuture' ? ICON_COLORS[advanceStatus] : colors['blue-200']}
                title={generateAdvanceBtnTitle(selectedCompleteness.advancePeriods.length, ap, index)}
                iconType={
                  advanceStatus === 'inFuture' ||
                  advanceStatus === 'manuallyDeleted' ||
                  advanceStatus === 'automaticallyDeleted'
                    ? ''
                    : ICONS[advanceStatus]
                }
                onTrigger={ap.invoiceId ? () => navigate(`${rootUrl}/invoices/${ap.invoiceId}`) : undefined}
              />
            </TooltipTrigger>
          </div>
        );
      })}
    </div>
  );
});

export default Advances;
