import { observer } from 'mobx-react';
import React from 'react';

import FilesStore from '../logic/FilesStore';
import css from './files-filter-section.module.scss';
import Filters from './Filters';
import OverviewCounts from './OverviewCounts';

type Props<T> = {
  filesStore: FilesStore<T>;
  customerId?: string;
  includeContractFilter?: boolean;
};

const FilesFilterSection = <T,>(props: Props<T>) => {
  const { filesStore, customerId, includeContractFilter } = props;

  const { quickFilterStore } = filesStore;

  if (!quickFilterStore) return null;

  return (
    <div className={css['filter-section']}>
      <OverviewCounts customerSpecific={!!customerId} quickFilterStore={quickFilterStore} filesStore={filesStore} />
      <div className={css['filters']}>
        <Filters filesStore={filesStore} customerId={customerId} includeContractFilter={includeContractFilter} />
      </div>
    </div>
  );
};

export default observer(FilesFilterSection);
