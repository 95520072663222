import './App.scss';
import { detect } from 'detect-browser';
import React, { Suspense } from 'react';
import { GatewayProvider } from 'react-gateway';
import { Consumer, Provider } from './app-context';
import ErrorBoundaryClass from './components/Error/error-boundary-class';
import { Loader } from './components/Loader';
import AppRouter from './features/AppRouter';
import IeDetect from './IeDetect';
import { useMsal } from '@azure/msal-react';
import BootInterCom from 'BootInterCom';

export default function App(_props: any) {
  const { instance } = useMsal();

  if (!instance.getActiveAccount()) {
    return (
      <div className="root-div">
        <Loader />
      </div>
    );
  }

  const browser = detect();

  if (browser && browser.name === 'ie') {
    return <IeDetect />;
  }

  return (
    <div className="root-div">
      <ErrorBoundaryClass>
        <Suspense fallback={<Loader />}>
          <GatewayProvider>
            <Provider>
              <BootInterCom>
                <Consumer>
                  {(val) => {
                    if (!val) return null;

                    return <AppRouter />;
                  }}
                </Consumer>
              </BootInterCom>
            </Provider>
          </GatewayProvider>
        </Suspense>
      </ErrorBoundaryClass>
    </div>
  );
}
