import {
  invoiceStatus,
  paymentMethod,
  paymentMethod as paymentMethod_,
  paymentProcessStatus
} from '@zf/api-types/enums';
import { computed, makeObservable } from 'mobx';

import BaseFormVjf, { Fields } from '../../../../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../../../../events/notification-events';
import InvoiceStore from '../../../../stores/InvoiceStore';

export enum formFields {
  newPaymentMethod = 'newPaymentMethod',
  collectionDate = 'collectionDate',
  newAdvanceAmount = 'newAdvanceAmount'
}

export type RelatedDetailsValues = {
  newPaymentMethod: paymentMethod_;
  collectionDate: string;
  newAdvanceAmount: number;
};

export default class RelatedDetailsForm extends BaseFormVjf<RelatedDetailsValues> {
  private invoiceStore: InvoiceStore;

  constructor(initialValues: RelatedDetailsValues, invoiceStore: InvoiceStore) {
    super(initialValues);
    this.invoiceStore = invoiceStore;

    makeObservable(this, {
      paymentMethodDisabled: computed,
      showCollectionDate: computed,
      dialogEnabled: computed
    });
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: formFields.newPaymentMethod,
          label: 'newPaymentMethod',
          validators: [this.isRequired]
        },
        {
          name: formFields.collectionDate,
          label: 'collectionDate'
        },
        {
          name: formFields.newAdvanceAmount,
          label: 'newAdvanceAmount',
          validators: [this.validateMoney]
        }
      ]
    };
  }

  save = async () => {
    const { getTranslation } = this.invoiceStore.rootStore.applicationStore;
    const { changePaymentMethod, changeAdvanceAmount } = this.invoiceStore.invoiceApiService;

    try {
      if (this.invoiceStore.relatedDetailsForm) {
        const { _values, _backup } = this.invoiceStore.relatedDetailsForm;

        const {
          newPaymentMethod: oldPaymentMethod,
          collectionDate: oldCollectionDate,
          newAdvanceAmount: oldAdvanceAmountInclVAT
        } = _backup;

        const { newPaymentMethod, collectionDate, newAdvanceAmount } = _values;

        let responseCollectionDate = collectionDate;

        if (oldPaymentMethod !== newPaymentMethod || oldCollectionDate !== collectionDate) {
          const res = await changePaymentMethod({ newPaymentMethod, collectionDate });
          responseCollectionDate = res.paymentDetails.directDebitCollectionDate;
        }

        if (oldAdvanceAmountInclVAT !== newAdvanceAmount) {
          await changeAdvanceAmount(newAdvanceAmount);
        }

        // Checking if the remaining code should run is covered by isDirty at a higher level
        this.invoiceStore.relatedDetailsForm = new RelatedDetailsForm(
          {
            ..._values,
            collectionDate: responseCollectionDate // Exception here because backend may change it
          },
          this.invoiceStore
        );

        notify.success({
          content: getTranslation('actions.invoice.update_related_details_success')
        });
      } else {
        notify.error({
          content: getTranslation('actions.invoice.update_related_details_fail')
        });
      }
    } catch (e) {
      notify.error({
        content: getTranslation('actions.invoice.update_related_details_fail'),
        error: e
      });
    }
  };

  get paymentMethodDisabled() {
    const { status, paymentDetails, remainingInvoiceAmount } = this.invoiceStore.selectedInvoice.invoice;

    return (
      status === invoiceStatus.settled ||
      paymentDetails.currentPaymentStatus === paymentProcessStatus.pendingpaymentexport ||
      paymentDetails.currentPaymentStatus === paymentProcessStatus.paid ||
      (remainingInvoiceAmount < 0 && paymentDetails.paymentMethod === paymentMethod.sct)
    );
  }

  get showCollectionDate() {
    const { status, paymentDetails, remainingInvoiceAmount } = this.invoiceStore.selectedInvoice.invoice;

    if (!this.invoiceStore.relatedDetailsForm) return false;

    const { _values } = this.invoiceStore.relatedDetailsForm;

    const statusIsValid =
      (paymentDetails.currentPaymentStatus === paymentProcessStatus.readyforpaymentcollection ||
        paymentDetails.currentPaymentStatus === paymentProcessStatus.waitingforpayment) &&
      status !== invoiceStatus.created &&
      status !== invoiceStatus.settled;

    if (remainingInvoiceAmount < 0) {
      return statusIsValid;
    } else {
      return _values.newPaymentMethod === paymentMethod.sdd;
    }
  }

  get dialogEnabled() {
    const { status, paymentDetails, remainingInvoiceAmount } = this.invoiceStore.selectedInvoice.invoice;

    if (!this.invoiceStore.relatedDetailsForm) return false;

    const { _values, _backup, _isDirty } = this.invoiceStore.relatedDetailsForm;

    return (
      _isDirty &&
      (status === invoiceStatus.settled || status === invoiceStatus.approved || status === invoiceStatus.generated) &&
      !(
        paymentDetails.paymentMethod === paymentMethod.sdd &&
        _values.newPaymentMethod === paymentMethod.sct &&
        remainingInvoiceAmount > 0
      ) &&
      _backup.newPaymentMethod !== _values.newPaymentMethod
    );
  }
}
