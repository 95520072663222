import { observer } from 'mobx-react';
import React from 'react';

import Flag from '@zf/stella-react/src/atoms/Flag/Flag';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import IconParagraph from '../../../../../components/Icon/IconParagraph';
import { InlineInputFieldInput } from '../../../../../components/input/InputField';
import SingleUploadInput from '../../../../../components/input/SingleUploadInput';
import { useStore } from '../../../../../hooks/useStore';
import { getCultureFlag } from '../../../../../utils/lang';
import Translation, { AttachmentFileType } from './Translation';
import css from './translations.module.scss';

type Props = {
  attachmentFiles: AttachmentFileType[];
  isDisabled?: boolean;
  setAttachmentValue: (index: number, val: Partial<AttachmentFileType>) => void;
};

const Translations = (props: Props) => {
  const { attachmentFiles, setAttachmentValue, isDisabled = false } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const defaultCultureIndex = attachmentFiles.findIndex((af) => af.isDefault);

  return (
    <>
      {defaultCultureIndex !== -1 && (
        <div className={css['default']}>
          <Heading headingType="h3" style="bold" textAlign="left">
            {getTranslation('entity_attachment.entity_attachment')}
          </Heading>
          <div className={css['inputs-wrapper']}>
            <Flag
              size="small"
              key={defaultCultureIndex}
              url={getCultureFlag(attachmentFiles[defaultCultureIndex]?.culture)}
            />
            <Paragraph bold>{getEnumTranslation('culture', attachmentFiles[defaultCultureIndex]?.culture)}</Paragraph>
            <InlineInputFieldInput
              id="default-culture-name"
              onChange={(fileName) => setAttachmentValue(defaultCultureIndex, { fileName })}
              value={attachmentFiles[defaultCultureIndex]?.fileName || ''}
              placeholder={getTranslation('attachment.file_name')}
              error={attachmentFiles[defaultCultureIndex]?.fileName ? false : true}
              darkMode
              disabled={isDisabled}
            />
          </div>
          <SingleUploadInput
            file={attachmentFiles[defaultCultureIndex].file}
            setFile={(file) => setAttachmentValue(defaultCultureIndex, { file, internalFileName: file?.fileName })}
            error={!attachmentFiles[defaultCultureIndex]?.file}
            hideDropzone={isDisabled}
          />
        </div>
      )}

      {attachmentFiles.length > 1 && (
        <>
          <Heading headingType="h4" style="bold" textAlign="left">
            {getTranslation('product_config.translations')}
          </Heading>
          <IconParagraph className={css['info']} iconType="alert-circle" color={colors['blue-600']}>
            {getTranslation('entity_attachment.translations_info')}
          </IconParagraph>
          <div>
            {attachmentFiles.map((af, index) => {
              // We already render the default one above
              if (af.isDefault) return null;

              return (
                <Translation
                  isDisabled={isDisabled}
                  key={`${af.attachmentId}-${index}`}
                  index={index}
                  culture={af.culture}
                  correspondingAttachment={af}
                  setAttachmentValue={setAttachmentValue}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default observer(Translations);
