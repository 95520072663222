import React from 'react';

import { ServiceLocationOverviewCountType } from '@zf/api-types/master-data/servicelocation';

import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { StringParam } from 'serialize-query-params';

const initialLocationOverviewCount: ServiceLocationOverviewCountType = {
  all: 0,
  requireAttention: 0,
  supplied: 0,
  unsupplied: 0
};

type QueryParam = {
  propertyGroupId: string;
  propertyGroupName: string;
  customerId: string;
  customerName: string;
};

const paramTypes = {
  propertyGroupId: StringParam,
  propertyGroupName: StringParam,
  customerId: StringParam,
  customerName: StringParam
};

const useLocationFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams
  } = useFilter<QueryParam>({
    endpoint: '/md/servicelocations/overviewcount',
    initialCount: initialLocationOverviewCount,
    domain: 'location',
    search,
    initialQueryParams: {
      propertyGroupId: '',
      propertyGroupName: '',
      customerId: '',
      customerName: ''
    },
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.propertyGroupId && queryParams.propertyGroupName) {
    tags.push(
      <FilterTag
        value={queryParams.propertyGroupName}
        removeFilter={() => setQueryParams({ propertyGroupId: '', propertyGroupName: '' })}
        key="property-group"
      />
    );
  }

  if (queryParams.customerId && queryParams.customerName) {
    tags.push(
      <FilterTag
        value={queryParams.customerName}
        removeFilter={() => setQueryParams({ customerId: '', customerName: '' })}
        key="property-group"
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams,
    quickFilter,
    overviewCount,
    searchValue,
    filterTags,
    queryParams
  };
};

export default useLocationFilter;
