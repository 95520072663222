import React from 'react';

import { MeterRowType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import ReconfigureMeterDialog from '../../../actions/meters/reconfigure/reconfigure-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { SuspenseSpinner } from '../../suspense';

export default function ReconfigMeterListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('meter');
  const { clickRef, validationRef, onSubmit } = useDialog();
  const selectedRows: MeterRowType[] = myCurrent.listPage.selectedRows;

  const disabled = selectedRows.length === 0;

  const actionText = i18n.getTranslation('actions.meter.reconfigure');

  return (
    <DropdownAction
      id="meter.update"
      icon="cog"
      dialogTitle={actionText}
      dialogContent={
        <SuspenseSpinner>
          <ReconfigureMeterDialog selectedRows={selectedRows} ref={clickRef} validationRef={validationRef} />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      disabled={disabled}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.reconfigure_meter')}
    />
  );
}
