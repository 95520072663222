import React from 'react';

import useSingleAPI from '../../hooks/useSingleAPI';
import { isEmptyObject } from '@zf/utils/src/object';
import { DomainType } from '../app-state';
import useRefresh from './use-refresh';
import useSelectionNavigator from './use-selection-navigator';

type Props = {
  entityId: string;
  endpoint: string;
  domain: DomainType;
  mock?: boolean;
};

export default function useDetail<T extends object>(props: Props) {
  const { entityId, endpoint, domain, mock = false } = props;
  const { timeStamp, refresh } = useRefresh();
  const navigator = useSelectionNavigator(entityId, domain);
  const [entity, setEntity] = React.useState<T | null>(null);

  const response = useSingleAPI<T>(
    {
      request: {
        endpoint: endpoint,
        selector: `/${navigator.currentId}`,
        query: {
          timeStamp: timeStamp
        }
      }
    },
    false,
    false,
    mock
  );

  if (response.error) throw response.error;
  if (response.result instanceof Promise && isEmptyObject(entity)) throw response.result;

  React.useEffect(() => {
    if (response.result && !(response.result instanceof Promise)) {
      setEntity(response.result.data);
    }
  }, [response.result]);

  return { entity, navigator, timeStamp, refresh };
}
