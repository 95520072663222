import { action, makeObservable, observable } from 'mobx';

import { EstimatedYearlyConsumptionType } from '@zf/api-types/forecasting';

import { METHODS } from '../../utils/request';
import ApplicationStore from '../stores/domain/ApplicationStore';
import BaseService from './BaseService';

export default class ForecastingService extends BaseService {
  public timerId: number | undefined = undefined;
  public eavs: EstimatedYearlyConsumptionType | undefined | null;
  public isRecalculating = false;

  constructor(applicationStore: ApplicationStore) {
    super('/fct/', applicationStore);

    makeObservable(this, {
      timerId: observable,
      eavs: observable,
      isRecalculating: observable,

      reset: action,
      setEavs: action,
      setIsRecalculating: action,
      setTimerId: action,
      getEavsAndCheckEstimationInProgress: action
    });
  }

  getEavsForContract = async (contractId: string) => {
    return (
      await this.applicationStore.sendRequest<EstimatedYearlyConsumptionType>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}estimablecontracts/${contractId}`
        }
      })
    ).data;
  };

  recalculateLocationEav = async (locationId: string) => {
    return (
      await this.applicationStore.sendRequest<void>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}estimablecontracts/servicelocations/${locationId}/eav/currentandnextyear/estimate`
        }
      })
    ).data;
  };

  recalculateContractEav = async (contractId: string) => {
    return (
      await this.applicationStore.sendRequest<void>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}estimablecontracts/${contractId}/eav/currentandnextyear/estimate`
        }
      })
    ).data;
  };

  /**
   * Util functions
   */
  setEavs = (newEavs: EstimatedYearlyConsumptionType | undefined | null) => {
    this.eavs = newEavs;
  };

  setIsRecalculating = (val: boolean) => {
    this.isRecalculating = val;
  };

  setTimerId = (id: number | undefined) => {
    this.timerId = id;
  };

  getEavsAndCheckEstimationInProgress = async (contractId: string | undefined) => {
    if (contractId) {
      const eavsRes = await this.getEavsForContract(contractId);
      this.setEavs(eavsRes);

      if (eavsRes.isEstimationInProgress && typeof this.timerId === 'undefined') {
        this.timerId = window.setInterval(async () => {
          const eavsRes = await this.getEavsForContract(contractId);
          this.setEavs(eavsRes);

          if (!eavsRes.isEstimationInProgress) {
            clearInterval(this.timerId);
            this.setTimerId(undefined);
            this.setIsRecalculating(false);
          }
        }, 2000);
      }
    } else {
      this.setEavs(null);
    }
  };

  reset = () => {
    this.eavs = undefined;
    clearInterval(this.timerId);
    this.setTimerId(undefined);
  };
}
