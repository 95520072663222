import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { WrappableVirtualizedList } from '../../design-system/ComponentSets';
import { Spinner } from '../../design-system/Foundation';
import { useStore } from '../../hooks/useStore';
import VirtualComment from './VirtualComment';

export type PropsType = {
  subjectType: entitySubjectType;
  subjectId: string;
};

const Comments = (props: PropsType) => {
  const { subjectType, subjectId } = props;

  const { applicationStore, activityStore } = useStore();
  const { getTranslation } = applicationStore;
  const { comments, initComments, deleteComment } = activityStore;

  useEffect(() => {
    initComments(subjectType, subjectId);
  }, [subjectType, subjectId]);

  const handleDeleteComment = async (index: number) => {
    await deleteComment(subjectType, subjectId, index);
  };

  if (!comments) {
    return <Spinner centered />;
  }

  const items = comments.map((c, index) => {
    return {
      key: `${c.id}-${index}`,
      index,
      comment: c,
      onDelete: () => handleDeleteComment(index)
    };
  });

  return comments.length === 0 ? (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/comments.png"
          alt="comments"
          title="comments"
          width="70"
          height="70"
        />
      }
      title={getTranslation('comments.no_comments')}
    >
      {getTranslation('comments.no_comments_description')}
    </CardEmptyBody>
  ) : (
    <WrappableVirtualizedList items={items} ItemComponent={VirtualComment} columnCount={1} columnGutter={16} />
  );
};

export default observer(Comments);
