import { observer } from 'mobx-react';
import React from 'react';

import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import StellaFloatInput, { FloatInputFieldProps } from '@zf/stella-react/src/atoms/InputField/stella-float-input';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDecimal } from '@zf/utils/src/number';

import { useStore } from '../../hooks/useStore';
import AccessControl from '../Permissions/AccessControl';

const FloatInput = (props: FloatInputFieldProps) => {
  const { value, id } = props;
  const { applicationStore } = useStore();
  const { culture } = applicationStore;

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{value ? formatDecimal(value, culture) : ''}</Paragraph>}>
      <StellaFloatInput {...props} locale={culture} />
    </AccessControl>
  );
};

export default observer(FloatInput);

export const InlineFloatInput = InlineInputField<FloatInputFieldProps>(FloatInput);
