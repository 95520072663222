import { regularMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { WizardSectionHeader } from 'design-system/Components';
import { InfoBanner } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import css from './contract-data-section.module.scss';
import ContractDataForm from './ContractDataForm';

const ContractDataSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { regularMoveInWizardStore } = contractStore.moveInWizardBaseStore;
  const { suggestionMsgColorMap, locationSectionStore, regularMoveInWizardNavigator } = regularMoveInWizardStore;
  const { invoiceProductConfigurationSuggestions } = locationSectionStore;
  const { setActiveStep } = regularMoveInWizardNavigator;

  const infoMessage = invoiceProductConfigurationSuggestions?.message?.message;
  const msgKey = invoiceProductConfigurationSuggestions?.message?.key;

  return (
    <WizardSection
      id="contract-data-section"
      setActiveStep={() => setActiveStep(regularMoveInWizardSteps.contractdata)}
    >
      <WizardSectionHeader
        id={regularMoveInWizardSteps.contractdata}
        text={getTranslation('contracts.steps.contract_data')}
      />

      {infoMessage && msgKey && (
        <InfoBanner
          className={css['info-message']}
          info={infoMessage}
          color={suggestionMsgColorMap[msgKey]}
          roundedCorners
        />
      )}

      <ContractDataForm />
    </WizardSection>
  );
};

export default observer(ContractDataSection);
