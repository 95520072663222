import { RegionalRegulationsConfigurationType } from '@zf/api-types/regionals/regionals';
import { action, makeObservable, observable } from 'mobx';
import SocialTariffConfigForm from '../config/forms/SocialTariffConfigForm';
import CountrySpecificStore from './CountrySpecificStore';

class SocialTariffStore {
  public countrySpecificStore: CountrySpecificStore;
  public socialTariffConfigForm: SocialTariffConfigForm;
  public regionalParams: RegionalRegulationsConfigurationType;
  constructor(countrySpecificStore: CountrySpecificStore) {
    this.countrySpecificStore = countrySpecificStore;
    makeObservable(this, {
      initSocialTariffForm: action,
      socialTariffConfigForm: observable,
      regionalParams: observable
    });
  }

  initRegionalParams = async () => {
    this.regionalParams = await this.countrySpecificStore.countrySpecificService.getRegionalRegulationParams();
    this.initSocialTariffForm(this.regionalParams);
  };

  initSocialTariffForm = (regionalParams: RegionalRegulationsConfigurationType) => {
    const socialParameters = regionalParams.socialTariffParameters;
    const address = socialParameters.supplierAddress;
    this.socialTariffConfigForm = new SocialTariffConfigForm(
      {
        kbo: socialParameters.kboNumber,
        gln: socialParameters.glnId,
        socialTariffIndicator: socialParameters?.socialTariffCustomEntityPropertyId,
        visibilityGovernment: socialParameters.sharePropsWithGovernmentCustomEntityPropertyId,
        firstname: socialParameters.contactFirstName,
        lastname: socialParameters.contactLastName,
        fax: socialParameters.contactFaxNumber,
        telephonenumber: socialParameters.contactTelephoneNumber,
        email: socialParameters.contactEmailAddress,
        supplierAddress: {
          isEditingAddress: false,
          city: address?.city,
          postalCode: address?.postalCode,
          streetName: address?.streetName,
          streetNumber: address?.streetNumber,
          streetNumberAddition: address?.streetNumberAddition,
          country: address?.country
        },
        bimCustomer: socialParameters.bimCustomEntityPropertyId
      },
      this
    );
  };
}

export default SocialTariffStore;
