import React from 'react';

import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../app-context';

type Props = {
  type?: outgoingBankingTransactionType;
};

export default function NoMutations(props: Props) {
  const { type } = props;
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/batches.png"
          alt="graph"
          title="graph"
          width="70"
          height="70"
        />
      }
      title={
        type ? i18n.getTranslation(`mutations.no_mutations_${type}`) : i18n.getTranslation('mutations.no_mutations')
      }
    >
      {type
        ? i18n.getTranslation(`mutations.no_mutations_${type}_descr`)
        : i18n.getTranslation('mutations.no_mutations_descr')}
    </CardEmptyBody>
  );
}
