import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import DynamicVirtualTableNew from '../../../../components/Lang/dynamic-virtual-table-new/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../../components/placeholder';
import { useStore } from '../../../../hooks/useStore';
import useMeterIssuesListPage from '../hooks/useMeterIssuesListPage';

const IssuesList = ({ isGrouped }: { isGrouped: boolean }) => {
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useMeterIssuesListPage(isGrouped);

  return useMemo(() => {
    return (
      <DynamicVirtualTableNew
        id="meters-table"
        tooltipId="meters-table-id"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        singleSelect={isGrouped}
        columns={
          isGrouped
            ? [
                {
                  width: 250,
                  label: getTranslation('meter.meter'),
                  dataKey: 'meter'
                },
                {
                  width: 250,
                  label: getTranslation('meter_issues.issues'),
                  dataKey: 'itemCount'
                },
                {
                  width: 250,
                  label: getTranslation('meter_issues.firstItem'),
                  dataKey: 'firstIssueDate'
                },
                {
                  width: 250,
                  label: getTranslation('meter_issues.latestItem'),
                  dataKey: 'lastIssueDate'
                }
              ]
            : [
                {
                  width: 250,
                  label: getTranslation('meter_issues.issue'),
                  dataKey: 'issue'
                },
                {
                  width: 250,
                  label: getTranslation('meter_issues.export_detected_on'),
                  dataKey: 'noticedDateTime'
                },
                {
                  width: 250,
                  label: getTranslation('general.status'),
                  dataKey: 'status'
                },
                {
                  width: 250,
                  label: getTranslation('general.detail'),
                  dataKey: 'detail'
                }
              ]
        }
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture, isGrouped]);
};

export default observer(IssuesList);
