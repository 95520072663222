import { locationSelection } from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import { regularMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { WizardSectionHeader } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import OptionalMeasurementSectionBody from './OptionalMeasurementSectionBody';

const OptionalMeasurementSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { locationSectionStore, regularMoveInWizardNavigator } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { selectedLocation, locationSelectionType } = locationSectionStore;
  const { setActiveStep } = regularMoveInWizardNavigator;

  // If location section is multiselect don't render this section
  if (locationSelectionType !== locationSelection.single || !selectedLocation) {
    return null;
  }

  return (
    <WizardSection
      id="optional-measurement-section"
      setActiveStep={() => setActiveStep(regularMoveInWizardSteps.optionalmeasurement)}
    >
      <WizardSectionHeader
        id={regularMoveInWizardSteps.optionalmeasurement}
        text={getTranslation('contracts.steps.opt_measurement')}
      />

      <OptionalMeasurementSectionBody />
    </WizardSection>
  );
};

export default observer(OptionalMeasurementSection);
