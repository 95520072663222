import clone from 'clone';
import { observer } from 'mobx-react';
import React, { Dispatch } from 'react';

import { PaymentParametersType, UpdatePaymentParametersType } from '@zf/api-types/parameters';
import { UpdatePaymentDelayType } from '@zf/api-types/payments';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import useValidator from '@zf/hooks/src/useValidator';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import CommitSection from '../../../components/config/commit-section';
import { ErrorBoundary } from '../../../design-system/Components';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import { createHeader, METHODS } from '../../../utils/request';
import AutomaticPaymentsCard from './automatic-payments/ui/AutomaticPaymentsCard';
import PaymentDelays from './payment-delays';
import PaymentParameters from './payment-parameters';
import PaymentRetries from './payment-retries';

type Props = {
  paymentParameters: PaymentParametersType;
  paymentDelaysConfig: UseConfigReturnType<UpdatePaymentDelayType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdatePaymentDelayType[]>,
    Dispatch<ValidatorCtxAction<UpdatePaymentDelayType[]>>
  ];
};

const createDelayApiFriendlyValues = (paymentDelay: UpdatePaymentDelayType) => {
  return {
    ...paymentDelay
  };
};

const ParametersContent = (props: Props) => {
  const { paymentParameters, paymentDelaysConfig, useTracked } = props;
  const { sort } = paymentDelaysConfig;

  const { applicationStore, paymentConfigStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;
  const { automaticPaymentsForm } = paymentConfigStore;
  /*   const {
    _isDirty: automaticPaymentsIsDirty,
    save: handleAutomaticPaymentsSave,
    _reset: restoreAutomaticPayments
  } = automaticPaymentsForm; */

  const {
    values: parametersValues,
    setValue: setParametersValue,
    restoreValues: restoreParametersValues,
    isDirty: parametersAreDirty,
    submitFactory
  } = useValidator<UpdatePaymentParametersType>({
    initialValues: clone(paymentParameters)
  });

  const handleSaveParameters = submitFactory(async () => {
    try {
      if (JSON.stringify(parametersValues) !== JSON.stringify(paymentParameters)) {
        const paymentParametersResponse = await sendRequest<PaymentParametersType>({
          request: {
            method: METHODS.POST,
            endpoint: '/cfg/Parameters/payment',
            data: {
              refundPaymentDelayInDays: parametersValues.refundPaymentDelayInDays,
              paymentTermsId: parametersValues.paymentTermsId,
              retryParameters: parametersValues.retryParameters,
              defaultCollectionFlowId: parametersValues.defaultCollectionFlowId,
              onlyUseInvoiceNumberInPaymentReferences: parametersValues.onlyUseInvoiceNumberInPaymentReferences
            }
          },
          customHeaders: createHeader({
            'If-Match': paymentParameters._etag
          })
        });

        setParametersValue(clone(paymentParametersResponse.data), false, true);

        notify.success({
          content: getTranslation('parameters.success_update')
        });
      }
    } catch (e) {
      notify.error({
        content: getTranslation('parameters.error_update'),
        error: e
      });
    }
  });

  const {
    values: delayValues,
    backup: delayBackup,
    isDirty: delaysAreDirty,
    selectedIndex: selectedDelayIndex,
    scrollToIndex: scrollToDelayIndex,
    setValue: setDelayValue,
    restoreValues: restoreDelayValues,
    setSelectedIndex: setSelectedDelayIndex
  } = useContextValidator<UpdatePaymentDelayType[]>(useTracked);

  const { addEntity, deleteEntity, handleSave } = useContextCRUD(useTracked);

  const handleDelaysSave = async () => {
    return handleSave(
      createDelayApiFriendlyValues,
      'invoiceType',
      '/cfg/paymentdelays',
      getTranslation('parameters.delays_update_success'),
      getTranslation('parameters.delays_update_fail')
    );
  };

  return (
    <>
      <CommitSection
        handleCancel={() => {
          if (parametersAreDirty) {
            restoreParametersValues();
          }
          if (delaysAreDirty) {
            restoreDelayValues();
          }
          /*           if (automaticPaymentsIsDirty) {
            restoreAutomaticPayments();
          } */
        }}
        handleSave={async () => {
          if (parametersAreDirty) {
            await handleSaveParameters();
          }
          if (delaysAreDirty) {
            await handleDelaysSave();
          }
          /*           if (automaticPaymentsIsDirty) {
            await handleAutomaticPaymentsSave();
          } */
        }}
        isDirty={
          parametersAreDirty || delaysAreDirty
          //  || automaticPaymentsIsDirty
        }
      />
      <ErrorBoundary>
        <CardsContainer>
          <PaymentParameters paymentParameters={parametersValues} setValue={setParametersValue} />
          <AutomaticPaymentsCard />
          <PaymentRetries paymentRetries={parametersValues.retryParameters} setValue={setParametersValue} />
          <PaymentDelays
            paymentDelays={delayValues}
            backup={delayBackup}
            selectedDelayIndex={selectedDelayIndex}
            scrollToDelayIndex={scrollToDelayIndex}
            isDirty={delaysAreDirty}
            isLoading={paymentDelaysConfig.isFetching}
            sort={sort}
            addEntity={addEntity}
            deleteEntity={deleteEntity}
            setDelayValue={setDelayValue}
            setSelectedDelayIndex={setSelectedDelayIndex}
          />
        </CardsContainer>
      </ErrorBoundary>
    </>
  );
};

export default observer(ParametersContent);
