import useListPage from 'app-context/hooks/use-listpage';
import { CustomerGroupType, CustomerGroupRowType } from '@zf/api-types/master-data/customer-group';
import EmptyLink from 'components/Link/EmptyLink';
import { useAppContext } from 'app-context';
import React from 'react';

const useCustomerGroupListPage = () => {
  const { tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const processRecord = (value: CustomerGroupType): any => {
    return {
      __id: value.id,
      __etag: value._etag,
      name: (
        <EmptyLink id={`customer-group`} url={`${rootUrl}/customers/customer-groups/${value.id}`}>
          {value.name}
        </EmptyLink>
      ),
      amountOfCustomers: value.amountOfCustomers
    };
  };
  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<CustomerGroupType, CustomerGroupRowType>({
    endpoint: '/md/customergroups',
    processRecord,
    domain: 'customersgroup'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useCustomerGroupListPage;
