import { makeObservable } from 'mobx';

import RootStore from '../../../app-context/stores';
import OrganisationService from './OrganisationService';

export default class OrganisationStore {
  public rootStore: RootStore;

  public organisationService: OrganisationService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.organisationService = new OrganisationService(rootStore.applicationStore);

    makeObservable(this, {});
  }
}
