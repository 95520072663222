import { InlineFloatInput } from 'components/input/FloatInput';
import { Paragraph, Subtext } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import useUUID from 'hooks/useUUID';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Area } from 'recharts';

import { AdjacentMeasurementPairs } from '@zf/api-types/master-data/meter';
import { MeasurementMeteringIssueDetails } from '@zf/api-types/metering/metering-issues';
import ZFAreaChart from '@zf/stella-react/src/atoms/Chart/ZFAreaChart';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import { State } from '../../types';
import css from './resolve-single-issue-graph.module.scss';
import useDebounce from '@zf/hooks/src/useDebounce';
import { formatDecimal } from '@zf/utils/src/number';

type Props = {
  issueDetails: MeasurementMeteringIssueDetails;
  referenceMeasurements: AdjacentMeasurementPairs;
  control: Control<Pick<State, 'message' | 'correctedMeasurementValue'>, any>;
  runMsmtValidation: (val: number) => Promise<void>;
};

const InlineFloatInputWithHooksForm = ({
  control,
  name,
  index,
  d,
  issueDetails,
  onHandleChange
}: {
  control: Control<any>;
  name: string;
  index: number;
  d: any;
  issueDetails: MeasurementMeteringIssueDetails;
  onHandleChange?: (val: number) => void; // Pass any custom function when changing; rules={{ validate: { someFunc } }} doesn't work together with yup!
}) => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const setDebounceCallback = useDebounce(500);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <InlineFloatInput
          id={`issue-value-${index}`}
          onChange={(val) => {
            if (onHandleChange) {
              setDebounceCallback(() => onHandleChange(val));
            }

            onChange(val);
          }}
          value={value}
          placeholder={getTranslation('general.value')}
          //SYNC Validation is still pending for this one?
          error={typeof d.value !== 'number' || isNaN(d.value) || d.value < 0}
          //
          postfix={getEnumTranslation('unitOfMeasure', issueDetails.unitOfMeasure)}
        />
      )}
    />
  );
};

const ResolveSingleIssueGraph = (props: Props) => {
  const { issueDetails, referenceMeasurements, control, runMsmtValidation } = props;

  const { applicationStore } = useStore();
  const { culture, getTranslation, getEnumTranslation } = applicationStore;

  const matchingPair = referenceMeasurements.pairs.find(
    (p) => p.externalChannelId === issueDetails.externalChannelIdentifier
  );

  const translation = getTranslation('general.value');

  // Adding previous
  const chartData = matchingPair?.previousMeasurements
    ?.map((msmt) => {
      return {
        value: msmt.value,
        time: parseInt(moment(msmt.endDateTime).format('x')),
        endDateTime: msmt.endDateTime
      };
    })
    .reverse();

  // Adding bad measurement
  chartData?.push({
    value: issueDetails.value,
    time: parseInt(moment(issueDetails.endDateTime).format('x')),
    endDateTime: issueDetails.endDateTime
  });

  // Adding next
  chartData?.push(
    ...(matchingPair?.nextMeasurements?.map((msmt) => {
      return {
        value: msmt.value,
        time: parseInt(moment(msmt.endDateTime).format('x')),
        endDateTime: msmt.endDateTime
      };
    }) || [])
  );

  const ids = useUUID(chartData?.length);

  return (
    <>
      <ZFAreaChart
        subject={translation}
        chartData={chartData || []}
        XdataKey="time"
        lines={[
          <Fragment key="area-chart">
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop stopColor={colors['red-600']} stopOpacity={0.6} />
                <stop offset="1" stopColor={colors['red-600']} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              name={translation}
              type="linear"
              dataKey="value"
              stroke={colors['red-600']}
              strokeWidth={2}
              fill={'url(#gradient)'}
              fillOpacity={1}
              unit={getEnumTranslation('unitOfMeasure', issueDetails.unitOfMeasure)}
            />
          </Fragment>
        ]}
        locale={culture}
        showLegend={false}
        showXTicks={false}
      />

      <FlexElements className={css['reference-measurements']} justifyContent="space-between">
        {chartData?.map((d, index) => {
          return (
            <FlexElements key={ids[index]} flexDirection="column">
              <Subtext>{formatDate(d.endDateTime)}</Subtext>
              {d.endDateTime === issueDetails.endDateTime ? (
                <InlineFloatInputWithHooksForm
                  d={d}
                  name="correctedMeasurementValue"
                  index={index}
                  issueDetails={issueDetails}
                  control={control}
                  onHandleChange={runMsmtValidation}
                />
              ) : (
                <Paragraph textAlign="center">{formatDecimal(d.value, culture)}</Paragraph>
              )}
            </FlexElements>
          );
        })}
      </FlexElements>
    </>
  );
};

export default observer(ResolveSingleIssueGraph);
