import React, { useEffect } from 'react';

import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../app-context/app-context';
import useDetail from '../../../app-context/hooks/use-detail';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { ICON_COLORS } from '../../../constants/icons';
import { getAllServicesState } from '../../../utils/serviceState';

const useLocationDetail = (id: string) => {
  const { entity: initialLocation, navigator } = useDetail<ServiceLocationType>({
    entityId: id,
    endpoint: '/md/servicelocations',
    domain: 'location'
  });
  const { enumReducer } = useAppContext();
  const { timeStamp, refresh } = useRefresh();

  const [location, setLocation] = React.useState(initialLocation);

  useEffect(() => {
    if (initialLocation) setLocation(initialLocation);
  }, [initialLocation]);

  const status = location ? getAllServicesState(location.services) : '';

  const statusComponent = (
    <NewStatusBadge
      color={ICON_COLORS[status]}
      status_={` ${enumReducer.getTranslation('serviceStatus', status)} `}
    />
  );

  return { location, status: statusComponent, navigator, timeStamp, setLocation, refresh };
};

export default useLocationDetail;
