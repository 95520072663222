import classNames from 'classnames';
import Interweave from 'interweave';
import React from 'react';

import { colors } from '@zf/utils/src/color';

import IconParagraph from '../../../components/Icon/IconParagraph';
import { ParagraphProps } from '../../Foundation/Paragraph/Paragraph';
import css from './info.module.scss';

type Props = {
  children: string;
  className?: string;
  color?: string;
};

export default function Info(props: ParagraphProps & Props) {
  const { children, className, color = colors['blue-600'] } = props;

  return (
    <IconParagraph className={classNames(css['info'], className)} iconType="info-circle" color={color}>
      <Interweave content={children} />
    </IconParagraph>
  );
}
