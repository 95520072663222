/**
 * Enums not returned by /api/enumerations
 */
export enum configPage {
  settings = 'settings',
  products = 'products',
  payments = 'payments',
  costallocations = 'costallocations',
  metering = 'metering',
  importjobs = 'importjobs',
  exportjobs = 'exportjobs',
  communication = 'communication',
  integrationlogging = 'integrationlogging',
  customerportal = 'customerportal',
  countryspecifics = 'countryspecifics'
}

export enum routedEntitySubjectType {
  product = 'product',
  servicelocation = 'servicelocation',
  propertygroup = 'propertygroup',
  customer = 'customer',
  customergroups = 'customergroups',
  contract = 'contract',
  invoice = 'invoice',
  payment = 'payment',
  meter = 'meter',
  prepayment = 'prepayment',
  importjob = 'importjob',
  exportjob = 'exportjob',
  collectioncase = 'collectioncase',
  tasks = 'tasks',
  outgoingbankingtransaction = 'outgoingbankingtransaction',
  incomingbankingtransaction = 'incomingbankingtransaction'
}

export enum tariffCalculationType {
  unitprice = 'unitprice',
  tieredprice = 'tieredprice'
}

export enum booleanOptions {
  yes = 'yes',
  no = 'no'
}

export enum meterTypeGroup {
  traditional = 'traditional',
  prepayment = 'prepayment'
}

export enum systemMessageLevel {
  information = 'information',
  warning = 'warning'
}
