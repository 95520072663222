import { MultiValue } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { InvoiceLineTemplateType } from '@zf/api-types/invoice';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { BillingItemType } from '@zf/api-types/product';
import { TaxCodeType } from '@zf/api-types/tax-codes';

import InvoiceLine from './InvoiceLine';
import { initialInvoiceLine } from './InvoiceWizard';

type Props = {
  showLocationDropdown: boolean;
  title: string;
  taxCodes: TaxCodeType[];
  billingItems: BillingItemType[];
  invoiceDate: Moment;
  customer: CustomerType | null;
  onChange: (lines: InvoiceLineTemplateType[]) => void;
  onFocus: (step: string) => void;
};

export default function InvoiceLineMultiValue(props: Props) {
  const { showLocationDropdown, title, taxCodes, billingItems, customer, invoiceDate, onFocus, onChange } = props;

  const { serviceLocationStore } = useStore();
  const { getLocationsForQuery } = serviceLocationStore.serviceLocationService;

  const [servicedLocations, setServicedLocations] = useState<ServiceLocationType[]>();

  useEffect(() => {
    if (customer?.id) {
      getLocationsForQuery({ customerId: customer.id }).then((res) => setServicedLocations(res));
    }
  }, [customer?.id]);

  return (
    <MultiValue
      id="invoice-lines"
      title={title}
      initialValues={[initialInvoiceLine]}
      intialNodeValue={initialInvoiceLine}
      onChange={onChange}
    >
      {({ index, value, dispatchValue }) => {
        return (
          <InvoiceLine
            index={index}
            value={value}
            onFocus={onFocus}
            dispatchValue={dispatchValue}
            title={title}
            taxCodes={taxCodes}
            customer={customer}
            servicedLocations={servicedLocations || []}
            billingItems={billingItems}
            invoiceDate={invoiceDate}
            showLocationDropdown={showLocationDropdown}
          />
        );
      }}
    </MultiValue>
  );
}
