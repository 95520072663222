import classNames from 'classnames';
import React from 'react';

import css from './tree-item.module.scss';

type Props = {
  id: string;
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export default function TreeItem(props: Props) {
  const { children, className, id, onClick } = props;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li id={id} className={classNames(css['tree-item'], className)} onClick={onClick} onKeyDown={() => {}}>
      {children}
    </li>
  );
}
