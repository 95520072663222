import moment from 'moment';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { navigate } from '@reach/router';
import { BillingAnalyticsType, InsightsPerMonthType } from '@zf/api-types/analytics/billing';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import css from '@zf/stella-react/src/atoms/Chart/chart-style.module.scss';
import CustomLegend, { LegendValuesType } from '@zf/stella-react/src/atoms/Chart/CustomLegend';
import CustomTooltip from '@zf/stella-react/src/atoms/Chart/CustomTooltip';
import CustomXAxisTick from '@zf/stella-react/src/atoms/Chart/CustomXAxisTick';
import CustomYAxisTick from '@zf/stella-react/src/atoms/Chart/CustomYAxisTick';

import { useAppContext } from '../../app-context';
import { colors } from '@zf/utils/src/color';
import { invoiceType } from '@zf/api-types/enums';

type Props = {
  billingInsights: BillingAnalyticsType;
  propertyGroupId?: string;
  setActiveTab?: (tabId: string) => void;
};

export default function CreatedInvoicesChartCard(props: Props) {
  const { billingInsights } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  billingInsights.billingDataInsights.insightsPerMonth.sort(
    (a, b) => moment(a.endOfMonth).valueOf() - moment(b.endOfMonth).valueOf()
  );

  const insightsPerMonthSorted = billingInsights.billingDataInsights.insightsPerMonth;

  const chartData = insightsPerMonthSorted
    .map((ipm) => {
      return {
        name: moment(ipm.endOfMonth).format('MMM'),
        createdInvoices: ipm.createdInvoices,
        createdAdvances: ipm.createdAdvances,
        startOfMonth: moment(ipm.startOfMonth),
        endOfMonth: moment(ipm.endOfMonth)
      };
    })
    .splice(1);

  const legendValues: LegendValuesType = insightsPerMonthSorted.reduce(
    (acc, ipm) => {
      acc.createdAdvances += ipm.createdAdvances;
      acc.createdInvoices += ipm.createdInvoices;

      return acc;
    },
    {
      createdInvoices: 0,
      createdAdvances: 0
    }
  );

  const navigateToDesiredQuery = (data: InsightsPerMonthType, index: number, types: string[]) => {
    if (index !== 0 || insightsPerMonthSorted.length < 7) {
      navigate(
        `${rootUrl}/invoices/?${types
          .map((e) => {
            return `&invoiceType=${e}`;
          })
          .join('')}&periodEndDateTimeEnd=${moment(data.endOfMonth).toISOString()}&periodEndDateTimeBegin=${moment(
          data.startOfMonth
        ).toISOString()}`
      );
    } else {
      navigate(
        `${rootUrl}/invoices/?${types
          .map((e) => {
            return `&invoiceType=${e}`;
          })
          .join('')}&periodEndDateTimeEnd=${moment(insightsPerMonthSorted[1].startOfMonth).toISOString()}`
      );
    }
  };

  return (
    <BarChartCard subject="billing" title={i18n.getTranslation('dashboard.general_tab.invoices_title')}>
      <div className={css['chart-container-wrapper']}>
        <ResponsiveContainer className={css['chart']}>
          <BarChart data={chartData} margin={{ top: 60, bottom: 0 }}>
            <CartesianGrid strokeOpacity={0.5} strokeDasharray="1 2" />
            <XAxis axisLine={false} tickLine={false} height={5} dataKey="name" tick={<CustomXAxisTick />} />
            <YAxis tick={<CustomYAxisTick locale={i18n.culture} />} axisLine={false} tickLine={false} />
            <ReferenceLine y={0} stroke={colors['silver-700']} strokeWidth={0.5} />
            <Tooltip content={<CustomTooltip locale={i18n.culture} />} cursor={{ fill: colors['blue-50'] }} />
            <Bar
              name={i18n.getTranslation('dashboard.general_tab.created_invoices')}
              dataKey="createdInvoices"
              stackId="a"
              fill={colors['blue-600']}
              radius={[2, 2, 0, 0]}
              onClick={(data, index) => {
                navigateToDesiredQuery(data, index, [
                  invoiceType.correctionnote,
                  invoiceType.creditnote,
                  invoiceType.endnote,
                  invoiceType.incidentalnote,
                  invoiceType.invoice
                ]);
              }}
              cursor="pointer"
            />
            <Bar
              name={i18n.getTranslation('dashboard.general_tab.created_advances')}
              dataKey="createdAdvances"
              stackId="b"
              fill={colors['yellow-600']}
              radius={[2, 2, 0, 0]}
              onClick={(data, index) => {
                navigateToDesiredQuery(data, index, [invoiceType.advance]);
              }}
              cursor="pointer"
            />
            <Legend
              content={<CustomLegend legendValues={legendValues} locale={i18n.culture} />}
              wrapperStyle={{ top: 0, left: 40 }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </BarChartCard>
  );
}
