import React from 'react';

import { paymentType } from '@zf/api-types/enums';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { colors } from '@zf/utils/src/color';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useRefresh from '../../../app-context/hooks/use-refresh';
import CustomerAutoFill, { CustomerAutofillProps } from '../../../components/Autofills/CustomerAutoFill';
import PaymentExport from '../../../components/Button/Excel/PaymentExport';
import ManuallyReversePaymentListPageButton from '../../../components/Button/Payment/ManuallyReversePaymentListPageButton';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import DateRangePicker from '../../../components/input/DateRangePicker';
import EnumFilterSelect from '../../../components/input/EnumFilterSelect';
import HistoryPicker from '../../../components/Lang/HistoryPicker';
import usePaymentFilter from '../hooks/usePaymentFilter';
import usePaymentTabs from '../hooks/usePaymentTabs';

type Props = {
  search: string;
};

const InlineCustomerAutoFill = InlineInputField<CustomerAutofillProps>(CustomerAutoFill);

export default function PaymentsFilterBar(props: Props) {
  const { i18n, enumReducer } = useAppContext();
  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    filterTags,
    onFilter,
    onSearch,
    setQueryParams,
    clearQueryParams,
    refreshCounts
  } = usePaymentFilter(props.search);
  const { refresh } = useRefresh();

  const tabItems = usePaymentTabs();

  return (
    <FilterBar
      refreshPage={refresh}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      tabItems={tabItems}
      defaultTab="payments"
      actions={[
        <ManuallyReversePaymentListPageButton key="reverse" refreshCounts={refreshCounts} />,
        <PaymentExport key="paymentExport" />
      ]}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="payment" />}
          count={overviewCount.all}
          title={i18n.getTranslation('payments_list.filter.all')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          id="reversed"
          key="reversed"
          onTrigger={() => onFilter('reversed')}
          icon={<Icon type="payment" />}
          count={overviewCount.reversed}
          title={i18n.getTranslation('payments_list.filter.reversed')}
          activeColor={colors['orange-600']}
          active={quickFilter === 'reversed'}
        />
      ]}
      filterInputs={[
        <Label key="label-customer">{i18n.getTranslation('customer.customer')}</Label>,
        <InlineCustomerAutoFill
          id="customer-filter"
          key="customer-filter"
          onChange={(val) =>
            setQueryParams({
              customerId: val[0] ? val[0].id : '',
              customerName: val[0] ? val[0].shortDisplayName : ''
            })
          }
          selectedValues={[queryParams.customerId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,
        <ListDivider key="divider-0" />,
        <EnumFilterSelect
          id="payment-type-filter"
          key="payment-type-filter"
          title={i18n.getTranslation('payments.payment_type')}
          options={enumReducer.getEnum<paymentType>('paymentType')}
          values={queryParams.paymentType ? queryParams.paymentType : []}
          setValue={(value) => setQueryParams({ paymentType: value })}
        />,
        <ListDivider key="divider-1" />,
        <Label key="label-period">{i18n.getTranslation('payments.payment_date')}</Label>,
        <HistoryPicker
          id="history-picker"
          key="history-picker"
          setHistoryDates={(dates) =>
            setQueryParams({
              startDateTime: convertMomentToISOString(dates[0]) as string,
              endDateTime: convertMomentToISOString(dates[1]) as string
            })
          }
          historyDates={[convertToMoment(queryParams.startDateTime), convertToMoment(queryParams.endDateTime)]}
        />,
        <DateRangePicker
          id="period-filter"
          key="period-filter"
          startDate={convertToMoment(queryParams.startDateTime)}
          endDate={convertToMoment(queryParams.endDateTime)}
          setDates={(dates) =>
            setQueryParams({
              startDateTime: convertMomentToISOString(dates[0]) as string,
              endDateTime: convertMomentToISOString(dates[1]) as string
            })
          }
          renderTopLvl={false}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={i18n.getTranslation('payments_list.filter.search')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
}
