import { METHODS } from 'utils/request';

import { PagedResponseType, ResponseType } from '@zf/api-types/api';
import {
  MeteringIssue,
  MeteringIssueGrouped,
  MeteringIssueOverviewCount
} from '@zf/api-types/metering/metering-issues';

import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';
import { MeteringIssueStatus } from '@zf/api-types/enums';
import { PropertyGroupReference } from '@zf/api-types/master-data/property-group';
import { MeterReference } from '@zf/api-types/master-data/meter';

export default class MeteringIssueService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/me/MeteringIssues', applicationStore);
  }

  getMeterIssues = async (queryParams: {
    meterIds?: string[];
    statuses?: MeteringIssueStatus[];
    limit?: number;
    quickFilter?: MeteringIssueStatus | string | null | undefined;
  }) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeteringIssue>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint,
          query: queryParams
        }
      })
    ).data;
  };

  getMeterIssuesOverviewCounts = async (queryParams?: { meterIds?: string[] }) => {
    return (
      await this.applicationStore.sendRequest<Record<MeteringIssueOverviewCount, number>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/overviewcount`,
          query: queryParams
        }
      })
    ).data;
  };

  getMeterIssuesGrouped = async (queryParams?: { meterIds?: string[]; groupBy: string; quickFilter?: string }) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeteringIssueGrouped>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/grouped`,
          query: queryParams
        }
      })
    ).data;
  };

  resolveMeterIssue = async (issueId: string, message: string | null, value: number | null) => {
    return (
      await this.applicationStore.sendRequest<MeteringIssue>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${issueId}/resolve`,
          data: { message, value }
        }
      })
    ).data;
  };

  resolveMeterIssues = async (issueIds: string[], message: string | null) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeteringIssue>>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/bulk/resolve`,
          data: { issueIds, message }
        }
      })
    ).data.results;
  };

  ignoreMeterIssue = async (issueId: string, message: string | null) => {
    return (
      await this.applicationStore.sendRequest<MeteringIssue>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${issueId}/ignore`,
          data: { message }
        }
      })
    ).data;
  };

  ignoreMeterIssues = async (issueIds: string[], message: string | null) => {

    return (
      await this.applicationStore.sendRequest<MeteringIssue[]>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/bulk/ignore`,
          data: { issueIds, message }
        }
      })
    ).data;
  };

  getPropertygroupsWithIssues = async () => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<PropertyGroupReference>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/propertygroups`
        }
      })
    ).data;
  };

  getMetersWithIssues = async () => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeterReference>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/meters`
        }
      })
    ).data;
  };
}
