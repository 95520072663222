import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  index: number;
  tariffId: string;
  deleteTariff: (tariffId: string) => Promise<void>;
  deleteTariffInlist: (index: number) => void;
};

const DeleteTariffDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { index, tariffId, deleteTariff, deleteTariffInlist } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteTariff(tariffId);
        deleteTariffInlist(index);

        notify.success({
          content: getTranslation('billing_tariff.delete_tariff_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('billing_tariff.delete_tariff_fail'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{getTranslation('billing_tariff.delete_tariff_paragraph')}</Paragraph>;
});

export default observer(DeleteTariffDialog);
