import React from 'react';
import classNames from 'classnames';

import css from './list.module.scss';

export type PropsType = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export default function List(props: PropsType) {
  const { children, className, onClick } = props;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ul className={classNames(css['list'], className)} onClick={onClick} onKeyDown={() => {}}>
      {children}
    </ul>
  );
}
