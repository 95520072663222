import { RenderComponentProps, useMasonry, usePositioner, useResizeObserver } from 'masonic';
import React, { ComponentType } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { useScroller } from './hooks/useScroller';
import css from './wrappable-virtualized-list.module.scss';

type Props<T> = {
  items: T[];
  ItemComponent: ComponentType<RenderComponentProps<T>>;
  columnWidth?: number;
  columnCount?: number;
  columnGutter?: number;
};

/**
 * Important note: make sure no 'height: fit-content' style is applied because this breaks virtualization!
 */
export default function WrappableVirtualizedList<T>(props: Props<T>) {
  const { items, ItemComponent, columnWidth = 100, columnGutter = 24, columnCount } = props;

  const { width = 0, height = 0, ref } = useResizeDetector();

  const { scrollTop, isScrolling } = useScroller(ref);
  const positioner = usePositioner({ width, columnWidth, columnCount: columnCount || undefined, columnGutter }, [
    items.length
  ]);
  const resizeObserver = useResizeObserver(positioner);

  return (
    <div className={css['masonic-wrapper']} ref={ref}>
      {useMasonry({
        positioner,
        resizeObserver,
        items,
        height,
        scrollTop,
        isScrolling,
        overscanBy: 2,
        render: ItemComponent
      })}
    </div>
  );
}
