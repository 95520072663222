import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { OutgoingMutationRowType } from '@zf/api-types/billing/outgoing-mutations';
import { outgoingBankingTransactionStatus, outgoingMutationType } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import ManualEntry from '../../../../../../../actions/outgoing-mutation/ManualEntry';
import { useAppContext } from '../../../../../../../app-context';
import ConfirmationActionButton from '../../../../../../../components/Button/ConfirmationActionButton';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  selectedRows: OutgoingMutationRowType[];
  refresh: () => void;
};

export default function EditEntryDetailButton(props: Props) {
  const { outgoingBankingTransaction, selectedRows, refresh } = props;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const selectedMutation = selectedRows[0] ? selectedRows[0].__entity : undefined;

  const isDisabled =
    outgoingBankingTransaction.status === outgoingBankingTransactionStatus.toconfirmbybank ||
    outgoingBankingTransaction.status === outgoingBankingTransactionStatus.closed;

  if (selectedMutation) {
    selectedMutation.amount = Math.abs(selectedMutation.amount);
  }

  return (
    <ConfirmationActionButton
      id="outgoing_mutations.edit_entry"
      icon="sign"
      dialogTitle={i18n.getTranslation('actions.outgoing_mutation.edit_entry')}
      dialogContent={
        <ManualEntry
          ref={clickRef}
          validationRef={validationRef}
          outgoingBankingTransaction={outgoingBankingTransaction}
          entry={selectedMutation}
          refresh={refresh}
        />
      }
      onSubmit={onSubmit}
      disabled={
        selectedRows.length === 0 ||
        selectedRows.length > 1 ||
        (selectedMutation && selectedMutation.referenceDetails.referenceType !== outgoingMutationType.customer) ||
        isDisabled
      }
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.save')}
    />
  );
}
