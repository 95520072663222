import moment from 'moment';
import React from 'react';

import { navigate } from '@reach/router';
import { MeteringAnalyticsType } from '@zf/api-types/analytics/metering';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import SingleBarChart from '@zf/stella-react/src/atoms/Chart/SingleBarChart';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import { colors } from '@zf/utils/src/color';
import { MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';

type Props = {
  height?: string;
  meteringAnalytics: MeteringAnalyticsType;
};

type BarDataType = {
  activePayload: { payload: PayloadType }[];
};

type PayloadType = {
  range: string;
};

export default function DataReceivedChartCard(props: Props) {
  const { meteringAnalytics, height } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const meterDataInsights = meteringAnalytics.meterDataInsights;

  const chartData: ChartDataType[] = [
    {
      name: i18n.getTranslation('dashboard.charts.longerThanTwoMonths'),
      Amount: meterDataInsights.longerThanTwoMonths,
      color: colors['blue-400'],
      range: 'longerThanTwoMonths'
    },
    {
      name: i18n.getTranslation('dashboard.charts.lastTwoMonths'),
      Amount: meterDataInsights.lastTwoMonths,
      color: colors['blue-400'],
      range: 'lastTwoMonths'
    },
    {
      name: i18n.getTranslation('dashboard.charts.lastMonth'),
      Amount: meterDataInsights.lastMonth,
      color: colors['blue-400'],
      range: 'lastMonth'
    },
    {
      name: i18n.getTranslation('dashboard.charts.lastTwoWeeks'),
      Amount: meterDataInsights.lastTwoWeeks,
      color: colors['blue-600'],
      range: 'lastTwoWeeks'
    }
  ];

  const clickBar = (barData: BarDataType) => {
    if (barData.activePayload) {
      let minDate = MIN_DATE;
      let maxDate = moment().toISOString();
      switch (barData.activePayload[0].payload.range) {
        case 'lastTwoWeeks':
          minDate = meterDataInsights.usedDateLastTwoWeeks;
          break;
        case 'lastMonth':
          minDate = meterDataInsights.usedDateLastMonth;
          maxDate = meterDataInsights.usedDateLastTwoWeeks;
          break;
        case 'lastTwoMonths':
          minDate = meterDataInsights.usedDateLastTwoMonths;
          maxDate = meterDataInsights.usedDateLastMonth;
          break;
        case 'longerThanTwoMonths':
          maxDate = meterDataInsights.usedDateLastTwoMonths;
          break;

        default:
          break;
      }
      navigate(`${rootUrl}/devices/meters?receivedMeteringDataStart=${minDate}&receivedMeteringDataEnd=${maxDate}`);
    }
  };

  const hasData = !(
    meterDataInsights.lastMonth === 0 &&
    meterDataInsights.lastTwoMonths === 0 &&
    meterDataInsights.lastTwoWeeks === 0 &&
    meterDataInsights.longerThanTwoMonths === 0
  );

  return (
    <BarChartCard subject="metering" title={i18n.getTranslation('meter.last-time-received')}>
      {hasData ? (
        <SingleBarChart
          height={height}
          subject="metering"
          chartData={chartData}
          YdataKey="Amount"
          XdataKey="name"
          onClick={clickBar}
          locale={i18n.culture}
        />
      ) : (
        <EmptyChartState title={i18n.getTranslation('dashboard.general_tab.no_metering_yet')} />
      )}
    </BarChartCard>
  );
}
