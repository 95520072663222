import React from 'react';
import { Bar } from 'recharts';

import { utilityType } from '@zf/api-types/enums';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { AverageServiceConsumptionPerServiceLocationType } from '@zf/api-types/service-consumption';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import ZFBarChart from '@zf/stella-react/src/atoms/Chart/ZFBarChart';
import { groupBy } from '@zf/utils/src/array';

import { useAppContext } from '../../../../../../app-context';
import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import { ICON_COLORS } from '../../../../../../constants/icons';
import css from './consumptions.module.scss';

type Props = {
  consumptions: AverageServiceConsumptionPerServiceLocationType[];
  locations: ServiceLocationType[];
};

export default function YearlyConsumptionChartCard(props: Props) {
  const { consumptions, locations } = props;
  const { i18n, enumReducer } = useAppContext();

  let initialLocation = '';

  if (locations.length > 0) {
    initialLocation = locations[0].id;
  }

  const [selectedLocation, setSelectedLocation] = React.useState(initialLocation);

  const filteredForSelectedLocation = consumptions.filter((c) => {
    return c.serviceLocationId === selectedLocation;
  });

  const groupedByPeriod: Record<string, AverageServiceConsumptionPerServiceLocationType[]> = groupBy(
    filteredForSelectedLocation,
    'period'
  );
  const groupedByUtilityType: Record<utilityType, AverageServiceConsumptionPerServiceLocationType[]> = groupBy(
    filteredForSelectedLocation,
    'utilityType'
  );

  const chartData: ChartDataType[] = Object.keys(groupedByPeriod).map((key) => {
    const value = groupedByPeriod[key];
    const acc = {} as Record<string, any>;

    value.forEach((v) => {
      acc[v.utilityType] = v.averagePerYear;
      acc.year = v.period.toString();
    });

    return acc;
  });

  const bars = Object.keys(groupedByUtilityType).map((key) => {
    const firstItem = groupedByUtilityType[key as utilityType][0];
    const uom = firstItem ? firstItem.unitOfMeasure : '';

    return (
      <Bar
        key={key}
        name={enumReducer.getTranslation('utilityType', key)}
        dataKey={key}
        fill={ICON_COLORS[key]}
        radius={[3, 3, 0, 0]}
        unit={enumReducer.getTranslation('unitOfMeasure', uom)}
      />
    );
  });

  const dropDownValues = locations.map((l) => {
    return {
      text: l.address?.localizedDisplay || '',
      value: l.id
    };
  });

  return (
    <BarChartCard
      subject="location_utility_type_consumptions"
      title={i18n.getTranslation('property_groups.tabs.metering.average_consumption')}
      width="2"
      height="40rem"
      extraRight={
        <div className={css['consumption-chart-extra-right ']}>
          <SimpleDropdown
            id="yearly-consumption-select-location"
            onChange={(val) => setSelectedLocation(val[0])}
            values={dropDownValues}
            selectedValues={[selectedLocation]}
          />
        </div>
      }
    >
      {chartData.length > 0 ? (
        <ZFBarChart
          height="36rem"
          subject="location_utility_type_consumptions"
          chartData={chartData}
          XdataKey="year"
          bars={bars}
          locale={i18n.culture}
        />
      ) : (
        <EmptyChartState title={i18n.getTranslation('property_groups.tabs.metering.no_consumption_data_yet')} />
      )}
    </BarChartCard>
  );
}
