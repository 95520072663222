import { countryCode } from '@zf/api-types/enums';

import { onlyDigits } from './number';

export const formatCustomerName = (lastName: string, firstName?: string) => {
  let res = lastName;

  if (firstName) {
    res = `${firstName} ${lastName}`;
  }

  return res;
};

export const validateIdentificationNumber = (number: any, code: countryCode) => {
  // Be sure to have something to test

  switch (code) {
    case countryCode.bel: {
      if (!number) {
        return false;
      }
      // Only take the first 11 characters
      number = number.replace(/\D/g, '').substr(0, 11);

      // Test if we have well a 11-digit string
      if (!/\d{11}/.test(number)) {
        return false;
      }

      // Numbers we're going to check against
      let to_control = number.substr(0, 9);

      // Verification number
      const control = number.substr(9, 2);

      // Date parsing
      const date = number.substr(0, 6);
      const date_year = parseInt(date.substr(0, 2), 10);
      const date_month = parseInt(date.substr(2, 2), 10);
      const date_day = parseInt(date.substr(4, 2), 10);
      const today_year = parseInt(new Date().getFullYear().toString().substr(2, 2), 10);

      // Simple date check
      if (date_month < 1 || date_month > 12 || date_day < 1 || date_day > 31) {
        return false;
      }

      // First check for people born before or on 1999
      const expectedChecksum = 97 - (to_control % 97);

      if (expectedChecksum === parseInt(control)) {
        return true;
      }

      // Add '2' before if date after 1st Jan 2000
      if (date_year < today_year) {
        to_control = '2' + to_control.toString();
      }

      // Modulo calculation
      to_control = parseInt(to_control, 10);
      let modulo: number = 97 - (to_control % 97);
      modulo = Math.floor(modulo);
      let moduleToString: string = modulo < 10 ? '0' + modulo : '' + modulo;

      // Final test
      return control === moduleToString;
    }
    case countryCode.nld: {
      let returnValue = false;
      if (!number || number?.length === 0) {
        return true;
      }
      if (number === '00000000000' || number.length !== 9) {
        return false;
      }
      const values = number.split('');
      const [a, b, c, d, e, f, g, h, i] = values.map((char: string) => parseInt(char, 10));
      let result = 0;

      result = 9 * a + 8 * b + 7 * c + 6 * d + 5 * e + 4 * f + 3 * g + 2 * h + -1 * i;
      returnValue = result > 0 && result % 11 === 0;

      return returnValue;
    }
  }
};

export const formatSocialSecurityNumberBel = (number: string | null) => {
  if (!number) return '';

  const onlyDigits = number.replace(/\D/g, '');
  const date = onlyDigits.substr(0, 6);
  const date_year = date.substr(0, 2);
  const date_month = date.substr(2, 2);
  const date_day = date.substr(4, 2);
  const id = onlyDigits.substr(6, 3);
  const gender = onlyDigits.substr(9, 2);

  return `${date_year && `${date_year}.`}${date_month && `${date_month}.`}${date_day && `${date_day}-`}${
    id && `${id}.`
  }${gender && `${gender}`}`;
};

export const formatSsin = (ssin: string | null, countryCode_: countryCode | null) => {
  if (!countryCode_) return ssin;

  if (countryCode_ === countryCode.bel) {
    return formatSocialSecurityNumberBel(ssin);
  }

  if (countryCode_ === countryCode.nld) {
    return onlyDigits(ssin);
  }

  return ssin;
};
