import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { IncomingMutationType } from '@zf/api-types/billing/incoming-mutations';
import { TransactionType } from '@zf/api-types/transactions';

import useSuspenseSingleAPI from '../../../../../../../hooks/useSuspenseSingleAPI';
import TransactionDetails from './TransactionDetails';

type Props = {
  incomingMutation: IncomingMutationType;
};

export default function TransactionDetailsWrapper(props: Props) {
  const { incomingMutation } = props;

  const response = useSuspenseSingleAPI<PagedResponseType<TransactionType>>({
    request: {
      endpoint: `/bill/Transactions/incomingMutation/${incomingMutation.id}`
    }
  });

  if (!response.result) return null;

  const transactions = response.result.data.results;

  return (
    <>
      {transactions.map((t, index) => {
        return <TransactionDetails key={`${t.id}-${index}`} transaction={t} isOpen={index === 0} />;
      })}
    </>
  );
}
