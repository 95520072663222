import moment from 'moment';

import { serviceStatus } from '@zf/api-types/enums';
import { ContractedService } from '@zf/api-types/master-data/contract';
import { ServiceType, StatusType } from '@zf/api-types/master-data/servicelocation';
import { betweenDates } from '@zf/utils/src/date';

export function getServiceStatus(statusHistory: StatusType[]) {
  const today = moment();
  const activeStatus = statusHistory.find((value) => betweenDates(value.startDateTime, value.endDateTime, today));

  return activeStatus || null;
}

export const serviceIsActive = (s: ContractedService) => {
  return moment().isBetween(s.startDateTime, s.endDateTime, undefined, '[]');
};

export const getServiceStatusFromContractedService = (s: ContractedService) => {
  return serviceIsActive(s) ? serviceStatus.supplied : serviceStatus.unsupplied;
};

export function getAllServicesState(services: ServiceType[]) {
  const suppliedStatusses = services.reduce((acc: serviceStatus[], value: ServiceType) => {
    const activeStatus = getServiceStatus(value.statusHistory);

    if (activeStatus) {
      acc.push(activeStatus.serviceStatus);
    }

    return acc;
  }, []);

  let supplied = serviceStatus.unsupplied;

  if (suppliedStatusses.includes(serviceStatus.supplied)) {
    supplied = serviceStatus.supplied;
  } else if (suppliedStatusses.includes(serviceStatus.requested)) {
    supplied = serviceStatus.requested;
  } else if (suppliedStatusses.includes(serviceStatus.removed)) {
    supplied = serviceStatus.removed;
  }

  return supplied;
}
