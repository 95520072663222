import { action, makeAutoObservable, observable } from 'mobx';

import { BillingItemType } from '@zf/api-types/product';

import RootStore from '../../../app-context/stores';

export default class BillingItemsDialogStore<
  E extends { billingItems: B[]; name: string },
  B extends { billingItemId: string }
> {
  public rootStore: RootStore;

  public billingItems_: BillingItemType[] | undefined;
  public billingItemsBackup_: BillingItemType[] | undefined;
  public updatingForEntity_: E | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      billingItems_: observable,
      billingItemsBackup_: observable,
      updatingForEntity_: observable,

      setUpdatingForEntity: action,
      setUpdatingForEntityBillingItems: action,
      setBillingItems: action,
      resetBillingItems: action
    });
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get billingItems() {
    return this.billingItems_ as BillingItemType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get updatingForEntity() {
    return this.updatingForEntity_ as E;
  }

  setBillingItems = (newItems: BillingItemType[]) => {
    if (this.updatingForEntity_) {
      // Place selected items first in UI
      const selected: BillingItemType[] = [];
      const others: BillingItemType[] = [];

      newItems.forEach((b) => {
        if (this.updatingForEntity_?.billingItems.some((e) => e.billingItemId === b.id)) {
          selected.push(b);
        } else {
          others.push(b);
        }
      });

      this.billingItems_ = [...selected, ...others];
    }
  };

  resetBillingItems = async () => {
    this.billingItems_ = this.billingItemsBackup_;
  };

  setUpdatingForEntity = (e: E | undefined) => {
    this.updatingForEntity_ = e;
  };

  setUpdatingForEntityBillingItems = (newItems: B[]) => {
    if (this.updatingForEntity_) {
      this.updatingForEntity_.billingItems = newItems;
    }
  };

  updateSelection = (billingItem: BillingItemType, newEntityBillingItem: B) => {
    if (this.updatingForEntity_) {
      const cloned = [...this.updatingForEntity_.billingItems];
      const foundIndex = cloned.findIndex((pbi) => pbi.billingItemId === billingItem.id);

      if (foundIndex !== -1) {
        // Deselect
        cloned.splice(foundIndex, 1);
      } else {
        // Select
        cloned.push(newEntityBillingItem);
      }

      this.updatingForEntity_.billingItems = cloned;
    }
  };
}
