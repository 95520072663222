import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import css from './filter-button.module.scss';

type Props = {
  id: string;
  icon: React.ReactNode;
  title: string;
  count: number | string;
  onTrigger: () => void;
  activeColor: string;
  active: boolean;
};

export default function FilterButton(props: Props) {
  const { id, icon, title, count, activeColor, onTrigger, active } = props;

  const iconStyle = active
    ? {
        marginRight: '0rem'
      }
    : {
        marginRight: '1.5rem',
        color: activeColor
      };
  const buttonStyle = active
    ? {
        borderLeft: '.1rem solid #E1E6EB',
        borderTop: '.1rem solid #E1E6EB',
        borderRight: '.1rem solid #E1E6EB',
        color: activeColor,
        display: 'block',
        borderBottom: '.1rem solid white',
        padding: '1rem 1.6rem'
      }
    : {
        border: '.1rem solid #E1E6EB',
        borderStyle: 'solid',
        borderBottom: '.1rem solid #E1E6EB',
        alignItems: 'center',
        padding: '1rem 1.6rem'
      };

  return (
    <BaseButton id={id} style={buttonStyle} onClick={onTrigger} className={css['filter-button']}>
      <div className={css['icon-container']} style={iconStyle}>
        <div className={css['icon']}>{icon}</div>
        <div className={css['count']}>{count}</div>
      </div>
      <React.Fragment>{title}</React.Fragment>
    </BaseButton>
  );
}
