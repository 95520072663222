import React from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';

import IntegrationLoggingCard from '../../cards/Integration/IntegrationLoggingCard';
import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { CrumbHeader } from '../../components/Header';
import ZFRouter from '../../components/Router/ZFRouter';
import { SuspenseSpinner } from '../../components/suspense';
import { useStore } from '../../hooks/useStore';
import Route from '../route';
import css from './integration-logging-config.module.scss';

type Props = {
  location: Location;
  navigate: (href: string) => void;
};

export default function IntegrationLoggingConfig(props: Props) {
  const { navigate, location } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  const splitUrl = location.href.split('/');

  const integrationLoggingTab = () => (
    <SuspenseSpinner>
      <CardsContainer className={css['incoming-invoice-component']}>
        <IntegrationLoggingCard subjectType="Configurationentities" subjectId="" />
      </CardsContainer>
    </SuspenseSpinner>
  );

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="cog" text={getTranslation('nav.integration_logging')} />
          </BreadCrumb>
        }
      />
      <TabContainer
        onSelectTab={setActiveTab}
        entityId={getTranslation('nav.integration_logging')}
        selectedTab={splitUrl[splitUrl.length - 1]}
        tabItems={[
          {
            title: getTranslation('nav.integration_logging'),
            id: 'incoming-invoice-components',
            isDefault: true
          }
        ]}
      >
        <ZFRouter>
          <Route Component={integrationLoggingTab} path="incoming-invoice-components" default />
        </ZFRouter>
      </TabContainer>
    </>
  );
}
