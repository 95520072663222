import clone from 'clone';

import { formatDate } from '@zf/utils/src/date';
import { isDateIsoString } from '@zf/utils/src/regex';

export const convertAnyDates = <T extends object>(object: T) => {
  let cloned = clone(object);

  Object.keys(cloned).forEach((key) => {
    const castedKey = key as keyof T;
    let entry = cloned[castedKey] as unknown as string;

    if (isDateIsoString(entry)) {
      cloned[castedKey] = formatDate(entry) as unknown as T[keyof T];
    }
  });

  return cloned;
};
