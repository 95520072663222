import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { IncomingMutationRowType } from '@zf/api-types/billing/incoming-mutations';
import { incomingMutationStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteDialog from '../../../../../../../actions/incoming-mutation/DeleteDialog';
import { useAppContext } from '../../../../../../../app-context';
import ConfirmationActionButton from '../../../../../../../components/Button/ConfirmationActionButton';

type Props = {
  selectedRows: IncomingMutationRowType[];
  refresh: () => void;
  incomingBankingTransaction: IncomingBankingTransactionType;
};

export default function IgnoreDetailButton(props: Props) {
  const { selectedRows } = props;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const isDisabled =
    selectedRows.some((r) => {
      return (
        r.__entity.status === incomingMutationStatus.created ||
        r.__entity.status === incomingMutationStatus.ignored ||
        r.__entity.status === incomingMutationStatus.processed
      );
    }) || selectedRows.length === 0;

  return (
    <ConfirmationActionButton
      id="incoming_mutation.ignore"
      icon="ignore"
      dialogTitle={i18n.getTranslation('actions.incoming_mutation.ignore')}
      dialogContent={<DeleteDialog {...props} ref={clickRef} />}
      onSubmit={onSubmit}
      disabled={isDisabled}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.incoming_mutation.ignore')}
      type="text"
      dialogType="danger"
    />
  );
}
