import classNames from 'classnames';
import React from 'react';

import { CalculationConfigurationBillingItemType } from '@zf/api-types/property-group-billing-configuration';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Tree, TreeItem } from '@zf/stella-react/src/atoms/Tree';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../app-context';
import { Icon } from '../../../../../../components/Icon';
import { ICON_COLORS } from '../../../../../../constants/icons';
import { notify } from '../../../../../../events/notification-events';
import useBillingData from '../../hooks/use-billing-data';
import useBillingItemDetails from '../../hooks/useBillingItemDetails';
import css from './tree-billing-item.module.scss';

type Props = {
  isDirty: boolean;
  item: CalculationConfigurationBillingItemType;
  isClickable?: boolean;
  tariff?: string | number;
  setSelectedBillingItem?: (item: CalculationConfigurationBillingItemType) => void;
};

export default function TreeBillingItem(props: Props) {
  const { i18n, enumReducer } = useAppContext();
  const { item, isDirty, tariff, isClickable = true, setSelectedBillingItem } = props;

  const { billingItemMatch, itemName, utilityType } = useBillingItemDetails(item.billingItemId);
  const { selectedBillingItem, selectedBillingItemFormulaType, setSelectedBillingItemFormulaType } = useBillingData();

  const icon = utilityType ? utilityType : 'money';
  const isSelected = selectedBillingItem && selectedBillingItem.billingItemId === item.billingItemId;

  return (
    <Tree
      id={item.billingItemId}
      className={classNames(css['billing-item-tree'], {
        [css['selected']]: isSelected
      })}
      title={itemName}
      arrowType="open"
      onClick={
        isClickable
          ? () => {
              if (!isDirty && setSelectedBillingItem) {
                setSelectedBillingItem(item);
              } else {
                notify.warning({
                  content: i18n.getTranslation('property_groups.tabs.billing.validate_first')
                });
              }
            }
          : undefined
      }
      icon={<Icon className={css['item-icon']} type={icon} color={ICON_COLORS[icon]} />}
      openOnInit
    >
      <TreeItem
        id={item.billingItemId}
        className={classNames(css['p-q'], {
          [css['selected_p_q']]: isSelected && selectedBillingItemFormulaType === 'quantity'
        })}
        onClick={
          isClickable
            ? () => {
                //setSelectedBillingItemFormulaType('quantity')
              }
            : undefined
        }
      >
        <div className={css['row-light']}>
          <Label bold>{i18n.getTranslation('property_groups.tabs.billing.q')}</Label>
          <Paragraph>
            {billingItemMatch
              ? enumReducer.getTranslation('billingCalculationType', billingItemMatch.calculationParameters.type)
              : ''}
          </Paragraph>
        </div>
      </TreeItem>
      <TreeItem
        id={item.billingItemId}
        className={classNames(css['p-q'], {
          [css['selected_p_q']]: isSelected && selectedBillingItemFormulaType === 'price'
        })}
        onClick={isClickable ? () => setSelectedBillingItemFormulaType('price') : undefined}
      >
        <div className={css['row-dark']}>
          <Label bold>{i18n.getTranslation('property_groups.tabs.billing.p')}</Label>
          <Paragraph bold={item.deviatingTariffCalculation}>
            {item.deviatingTariffCalculation
              ? i18n.getTranslation('property_groups.tabs.billing.formula')
              : i18n.getTranslation('general.default')}
          </Paragraph>
          {tariff && (
            <Paragraph className={css['tariff']}>
              {typeof tariff === 'number' ? formatMoney(tariff, i18n.culture) : tariff}
            </Paragraph>
          )}
        </div>
      </TreeItem>
    </Tree>
  );
}
