import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { Icon } from 'components/Icon';
import { RadioInput } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { MuteRule } from './create-muting-rule-dialog';
import TextArea from 'components/input/TextArea';
import { Paragraph } from 'design-system/Foundation';
import Interweave from 'interweave';
import { colors } from '@zf/utils/src/color';

export const MultiLineInputWithHooksForm = ({ control, name }: { control: Control<any>; name: string }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TextArea
          iconLeft={<Icon type="textballoon" />}
          id="ignore.message"
          onChange={onChange}
          value={value}
          placeholder={getTranslation('meter_issues.mute.let_people_know')}
        />
      )}
    />
  );
};
export const RadioInputWithHookForm = <T,>({
  control,
  value,
  name,
  label
}: {
  control: Control<MuteRule, any>;
  value: string;
  name: T;
  label: string;
}) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <FlexElements>
      <Controller
        // @ts-ignore-error
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <RadioInput {...field} value={value} checked={field.value === value}>
              {getTranslation(label)}
            </RadioInput>
          );
        }}
      />
    </FlexElements>
  );
};

export const FormField: React.FC<{ label: string; optionalLabel?: string }> = ({ children, label, optionalLabel }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <FlexElements flexDirection="column">
      <FlexElements flexDirection="row" gap="4">
        <div>
          <Paragraph bold textAlign="left">
            {getTranslation(label)}
          </Paragraph>
        </div>
        <div>
          {optionalLabel && (
            <Paragraph color={colors['silver-600']}>
              <Interweave content={getTranslation(optionalLabel)} />
            </Paragraph>
          )}
        </div>
      </FlexElements>
      {children}
    </FlexElements>
  );
};

export const SummaryField = ({ summary, params }: { summary: string; params: Record<string, string | number> }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <FlexElements justifyContent="space-between" flexDirection="column">
      <Paragraph>
        <Interweave content={getTranslation(summary, params)} />
      </Paragraph>
    </FlexElements>
  );
};
