import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteLocationDialog from '../../../actions/property-group/delete-locations-dialog';
import { useAppContext } from '../../../app-context';
import { LocationRowType } from '../../../features/property-group/detail-page/detail/locations-card';
import ConfirmationActionButton from '../ConfirmationActionButton';

type Props = {
  disabled: boolean;
  rowsToDelete: string[];
  rows: LocationRowType[];
  propertyGroup: PropertyGroupType;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  setPropertyGroup: React.Dispatch<React.SetStateAction<PropertyGroupType | null>>;
  onComplete: (
    updatedRecords: ServiceLocationType[],
    deletedRecords?: LocationRowType[] | undefined
  ) => Promise<LocationRowType[]>;
};

export default function DeleteLocationOfPropertyCardButton(props: Props) {
  const { rowsToDelete, disabled, rows, propertyGroup, onComplete, setPropertyGroup, setSelectedIds } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  return (
    <ConfirmationActionButton
      id="propertygroup.delete_locations"
      icon="trashcan"
      type="text"
      size="small"
      dialogTitle={i18n.getTranslation('general.remove')}
      dialogContent={
        <DeleteLocationDialog
          ref={clickRef}
          rows={rows}
          propertyGroup={propertyGroup}
          propertyEtag={propertyGroup._etag}
          rowsToDelete={rowsToDelete}
          onComplete={onComplete}
          setPropertyGroup={setPropertyGroup}
          setSelectedIds={setSelectedIds}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('general.ok')}
      dialogType="danger"
    />
  );
}
