import moment, { Moment } from 'moment';
import React from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { isMaxDate, isMinDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../../app-context';
import FilterInputs from '../../../../../components/Filters/filter-inputs';
import FilterTag from '../../../../../components/Filters/filter-tag';
import FilterTags from '../../../../../components/Filters/filter-tags';
import DateRangePicker from '../../../../../components/input/DateRangePicker';
import { convertToMoment, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import usePaymentFilter from '../../../../payment/hooks/usePaymentFilter';

export type BillingPeriodQueryParams = {
  periodStart: Moment | null;
  periodEnd: Moment | null;
};

const initialQueryParams = {
  periodStart: moment(MIN_DATE),
  periodEnd: moment(MAX_DATE)
};

export const useBillingPeriodsFilter = () => {
  const stateReducer = createStateReducer<BillingPeriodQueryParams, Partial<BillingPeriodQueryParams>>();
  const [values, setValue] = React.useReducer(stateReducer, initialQueryParams);

  const { i18n } = useAppContext();

  const tags: React.ReactNode[] = [];

  if (
    (values.periodStart !== null && !isMinDate(values.periodStart)) ||
    (values.periodEnd !== null && !isMaxDate(values.periodEnd))
  ) {
    tags.push(
      <FilterTag
        type="period"
        value={[values.periodStart, values.periodEnd]}
        removeFilter={() => setValue({ periodStart: null, periodEnd: null })}
        key="period"
      />
    );
  }

  const filterTags = <FilterTags key="filter-tags" tags={tags} />;

  const filters = (
    <FilterInputs
      filterInputs={[
        <Heading headingType="h4" key="label-period">
          {i18n.getTranslation('property_groups.tabs.billing.filter_by_period')}
        </Heading>,
        <DateRangePicker
          id="period-filter"
          key="period-filter"
          startDate={convertToMoment(values.periodStart)}
          endDate={convertToMoment(values.periodEnd)}
          setDates={(dates) =>
            setValue({
              periodStart: dates[0],
              periodEnd: dates[1]
            })
          }
        />
      ]}
      removeAllFilters={() => setValue(initialQueryParams)}
    />
  );

  return {
    filters,
    filterTags,
    queryParams: values
  };
};

export default usePaymentFilter;
