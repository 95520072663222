import { observer } from 'mobx-react';
import React from 'react';

import { customerType } from '@zf/api-types/enums';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { colors } from '@zf/utils/src/color';

import useRefresh from '../../../../app-context/hooks/use-refresh';
import CustomerAutoFill, { CustomerAutofillProps } from '../../../../components/Autofills/CustomerAutoFill';
import ProductAutoFill, { ProductAutofillProps } from '../../../../components/Autofills/ProductAutoFill';
import PropertyGroupAutoFill, {
  PropertyGroupAutofillProps
} from '../../../../components/Autofills/PropertyGroupAutoFill';
import ContractExport from '../../../../components/Button/Excel/ContractExport';
import RecalculateEavListPageButton from '../../../../components/Button/Location/RecalculateEavListPageButton';
import FilterBar from '../../../../components/Filters/FilterBar';
import { Icon } from '../../../../components/Icon';
import EnumFilterSelect from '../../../../components/input/EnumFilterSelect';
import { useStore } from '../../../../hooks/useStore';
import useContractFilter from '../../hooks/useContractFilter';
import BlockUnblockListPageButton from '../actions/list-page/BlockUnblockListPageButton';
import ChangeProductListPageButton from '../actions/list-page/ChangeProductListPageButton';
import DeleteContractListPageButton from '../actions/list-page/DeleteContractListPageButton';
import SignContractListPageButton from '../actions/list-page/SignContractListPageButton';
import TerminateContractListPageButton from '../actions/list-page/TerminateContractListPageButton';
import CustomerGroupAutoFill, { CustomerGroupAutofillProps } from 'components/Autofills/CustomerGroupAutoFill';

type Props = {
  search?: string;
  extraColumnTitles: string[];
};

const InlineCustomerAutoFill = InlineInputField<CustomerAutofillProps>(CustomerAutoFill);
const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);
const InlineProductAutoFill = InlineInputField<ProductAutofillProps>(ProductAutoFill);
const InlineCustomerGroupAutoFill = InlineInputField<CustomerGroupAutofillProps>(CustomerGroupAutoFill);

const ContractsFilterBar = (props: Props) => {
  const { extraColumnTitles } = props;
  const {
    searchValue,
    overviewCount,
    quickFilter,
    filterTags,
    queryParams,
    onSearch,
    refreshCounts,
    onFilter,
    clearQueryParams,
    setQueryParams
  } = useContractFilter(props.search);
  const { refresh } = useRefresh();
  const { applicationStore, contractStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;
  const { forecastingService } = contractStore;

  return (
    <FilterBar
      subject={getTranslation('contracts.contracts')}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      refreshPage={refresh}
      actions={[
        <SignContractListPageButton key="signAction" refreshCounts={refreshCounts} />,
        <DeleteContractListPageButton key="deleteAction" refreshCounts={refreshCounts} />,
        <TerminateContractListPageButton key="terminateAction" refreshCounts={refreshCounts} />,
        <BlockUnblockListPageButton key="blockUnblock" />,
        <ChangeProductListPageButton key="changeProductAction" refreshCounts={refreshCounts} />,
        <ContractExport key="contractExport" extraColumnTitles={extraColumnTitles} />,
        <RecalculateEavListPageButton key="recalculateEav" entity="contract" forecastingService={forecastingService} />
      ]}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="contract" />}
          count={overviewCount.all}
          title={getTranslation('contracts.list.all_contracts')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          id="ongoing"
          key="ongoing"
          onTrigger={() => onFilter('ongoing')}
          icon={<Icon type="contract" />}
          count={overviewCount.ongoing}
          title={getTranslation('contracts.list.active_contracts')}
          activeColor={colors['green-600']}
          active={quickFilter === 'ongoing'}
        />,
        <FilterButton
          id="draft"
          key="draft"
          onTrigger={() => onFilter('draft')}
          icon={<Icon type="contract" />}
          count={overviewCount.draft}
          title={getTranslation('contracts.list.to_sign')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'draft'}
        />,
        <FilterButton
          id="blocked"
          key="blocked"
          onTrigger={() => onFilter('blocked')}
          icon={<Icon type="contract" />}
          count={overviewCount.blocked}
          title={getTranslation('contracts.list.blocked')}
          activeColor={colors['orange-600']}
          active={quickFilter === 'blocked'}
        />
      ]}
      filterInputs={[
        <Label key="label-property-group">{getTranslation('property_groups.property_group')}</Label>,
        <InlinePropertyGroupAutoFill
          id="by_property_group"
          key="by_property_group"
          onChange={(val) =>
            setQueryParams({
              propertyGroupId: val[0]?.id || '',
              propertyGroupName: val[0]?.name || ''
            })
          }
          selectedValues={[queryParams.propertyGroupId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,
        <Label key="label-customer-group">{getTranslation('customer_groups.customer_group')}</Label>,
        <InlineCustomerGroupAutoFill
          id="by_customer_group"
          key="by_customer_group"
          onChange={(val) =>
            setQueryParams({
              customerGroupName: val[0] ? val[0].name : '',
              customerGroupId: val[0] ? val[0].id : ''
            })
          }
          selectedValues={[queryParams.customerGroupId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,

        <Label key="label-customer">{getTranslation('customer.customer')}</Label>,
        <InlineCustomerAutoFill
          id="by_customer"
          key="by_customer"
          onChange={(val) =>
            setQueryParams({
              customerId: val[0]?.id || '',
              customerName: val[0] ? val[0].firstName + val[0].lastName : ''
            })
          }
          selectedValues={[queryParams.customerId || '']}
          renderTopLvl={false}
          showDetails={false}
        />,
        <Label key="label-product">{getTranslation('product_config.product')}</Label>,
        <InlineProductAutoFill
          id="by_product"
          key="by_product"
          onChange={(val) =>
            setQueryParams({
              productId: val[0]?.id || '',
              productName: val[0]?.name || ''
            })
          }
          selectedValues={[queryParams?.productId || '']}
          renderTopLvl={false}
        />,
        <ListDivider key="divider-0" />,
        <EnumFilterSelect
          id="customer-type"
          key="customer-type"
          title={getTranslation('customer.customer_type')}
          options={getEnum<customerType>('customerType')}
          values={queryParams.customerType || []}
          setValue={(value) => setQueryParams({ customerType: value })}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('contracts.list.search_placeholder')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
};

export default observer(ContractsFilterBar);
