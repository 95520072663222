import classNames from 'classnames';
import React from 'react';

import { colors } from '@zf/utils/src/color';

import { BaseButton } from '../../design-system/Components';
import css from './toggle.module.scss';

type Props = {
  id: string;
  className?: string;
  isChecked?: boolean;
  optionLeft: string;
  optionRight: string;
  action: () => void;
};

export default function Toggle(props: Props) {
  const { id, className, optionLeft, optionRight, isChecked = false, action } = props;

  const firstUpdate = React.useRef(true);

  // Do not animate on initial render
  let showAnimation = false;

  if (firstUpdate.current) {
    firstUpdate.current = false;
  } else {
    showAnimation = true;
  }

  // Each toggle needs a unique id!!!
  return (
    <BaseButton id={id} className={className} onClick={action}>
      <div className={css['toggle']}>
        <div style={isChecked ? { color: colors['silver-700'] } : undefined}>{optionLeft}</div>
        {
          //eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        }
        <label htmlFor={id} className={css['switch']}>
          <span className={css['slider']}></span>
          <div
            className={classNames({
              [css['dot']]: !showAnimation,
              [css['dot-left']]: showAnimation && isChecked,
              [css['dot-right']]: showAnimation && !isChecked
            })}
          />
        </label>
        <div style={!isChecked ? { color: colors['silver-700'] } : undefined}>{optionRight}</div>
      </div>
    </BaseButton>
  );
}
