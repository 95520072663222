// Identity enums
export enum roleNameType {
  readonly = 'readonly',
  admin = 'admin',
  user = 'user'
}

// Other enums
export enum advanceFrequency {
  none = 'none',
  monthly = 'monthly',
  quarterly = 'quarterly',
  halfyearly = 'halfyearly'
}

export enum productItemPeriodicityType {
  regular = 'regular',
  onmovein = 'onmovein',
  onmoveout = 'onmoveout',
  fixedamount = 'fixedamount',
  fixedduration = 'fixedduration'
}

export enum attributeType {
  meterserialnumber = 'meterserialnumber',
  externalidentifier = 'externalidentifier',
  meteringtype = 'meteringtype',
  direction = 'direction',
  parentmeterserialnumber = 'parentmeterserialnumber',
  unitofmeasure = 'unitofmeasure',
  timeofuse = 'timeofuse',
  utilitytype = 'utilitytype'
}

export enum bankStatementCreditDebit {
  unknown = 'unknown',
  debit = 'debit',
  credit = 'credit'
}

export enum bankTransactionType {
  unknown = 'unknown',
  sddreversal = 'sddreversal',
  sctreceived = 'sctreceived'
}

export enum billingCalculationStreamStatus {
  open = 'open',
  finished = 'finished'
}

export enum billingCalculationType {
  consumption = 'consumption',
  subscription = 'subscription',
  attribute = 'attribute',
  consumptionunit = 'consumptionunit',
  customentityproperty = 'customentityproperty'
}

export enum billingCompletenessStatus {
  waiting = 'waiting',
  gatheringinput = 'gatheringinput',
  inputmissing = 'inputmissing',
  pendingcalculation = 'pendingcalculation',
  calculating = 'calculating',
  closed = 'closed',
  previousnotclosed = 'previousnotclosed',
  manuallycorrected = 'manuallycorrected',
  waitingforapproval = 'waitingforapproval',
  manuallyclosed = 'manuallyclosed',
  waitingfornextestimation = 'waitingfornextestimation'
}

export enum billingItemLevel {
  contract = 'contract',
  service = 'service'
}

export enum billingTariffInputType {
  none = 'none',
  fixed = 'fixed',
  costcomponent = 'costcomponent'
}

export enum calculatedLineStatus {
  created = 'created',
  transferred = 'transferred',
  approved = 'approved'
}

export enum calculationFormulaComponentType {
  propertyquota = 'propertyquota',
  quota = 'quota',
  propertyconsumption = 'propertyconsumption',
  serviceconsumption = 'serviceconsumption'
}

export enum communicationDocumentType {
  maindocument = 'maindocument',
  additionaldocument = 'additionaldocument',
  emailbody = 'emailbody'
}

export enum communicationType {
  none = 'none',
  email = 'email',
  manual = 'manual',
  skipifsdd = 'skipifsdd',
  custom = 'customs' // local
}

export enum consumerGroupOrigin {
  manual = 'manual',
  systemgenerated = 'systemgenerated'
}

export enum contactType {
  email = 'email',
  telephone = 'telephone',
  mobiletelephone = 'mobiletelephone',
  website = 'website'
}

export enum contractStatus {
  draft = 'draft',
  signed = 'signed',
  cancelled = 'cancelled',
  terminated = 'terminated'
}

export enum countryCode {
  alb = 'alb',
  and = 'and',
  aut = 'aut',
  bel = 'bel',
  bgr = 'bgr',
  bih = 'bih',
  blr = 'blr',
  bra = 'bra',
  cyp = 'cyp',
  cze = 'cze',
  deu = 'deu',
  dnk = 'dnk',
  esp = 'esp',
  est = 'est',
  fin = 'fin',
  fra = 'fra',
  gbr = 'gbr',
  grc = 'grc',
  hun = 'hun',
  irl = 'irl',
  isl = 'isl',
  ita = 'ita',
  lie = 'lie',
  ltu = 'ltu',
  lux = 'lux',
  lva = 'lva',
  mco = 'mco',
  mda = 'mda',
  mkd = 'mkd',
  mlt = 'mlt',
  mne = 'mne',
  nld = 'nld',
  nor = 'nor',
  pol = 'pol',
  prt = 'prt',
  rou = 'rou',
  rus = 'rus',
  sgp = 'sgp',
  smr = 'smr',
  srb = 'srb',
  svk = 'svk',
  svn = 'svn',
  swe = 'swe',
  tur = 'tur',
  ukr = 'ukr',
  usa = 'usa'
}

export enum customerType {
  person = 'person',
  organization = 'organization'
}

export enum dataExportCommunicationType {
  manualdownload = 'manualdownload',
  ftp = 'ftp',
  sftp = 'sftp',
  ftps = 'ftps'
}

export enum dataExportInvoiceFormatType {
  lord = 'lord',
  bdo = 'bdo',
  sapisu = 'sapisu'
}

export enum dataFrequency {
  pt1m = 'pt1m',
  pt5m = 'pt5m',
  pt15m = 'pt15m',
  pt30m = 'pt30m',
  pt1h = 'pt1h',
  p1d = 'p1d',
  na = 'na'
}

export enum dataImportCommunicationType {
  sftp = 'sftp',
  ftp = 'ftp',
  ftps = 'ftps',
  inboundemail = 'inboundemail',
  manualupload = 'manualupload',
  configuredPlugin = 'configuredPlugin'
}

export enum dataImportMeteringFormatType {
  elvaco_1104 = 'elvaco_1104',
  elvaco_1112 = 'elvaco_1112',
  customCsv = 'customCsv',
  kamstrupready_v1 = 'kamstrupready_v1',
  izarcenter = 'izarcenter',
  flamco = 'flamco',
  cadis = 'cadis',
  cadislora = 'cadislora',
  climaways = 'climaways',
  equinox_sinapsi = 'equinox_sinapsi',
  siemens_synco = 'siemens_synco',
  ycongouda = 'ycongouda',
  none = 'none'
}

export enum dataImportMigrationFormatType {
  zf_bulkimport = 'zf_bulkimport'
}

export enum dnsRecordMeaning {
  mailcname = 'mailcname',
  mx = 'mx',
  spf = 'spf',
  dkim1 = 'dkim1',
  dkim2 = 'dkim2'
}

export enum dnsRecordStatus {
  unverified = 'unverified',
  valid = 'valid',
  error = 'error'
}

export enum documentOutputFormat {
  pdf = 'pdf',
  html = 'html'
}

export enum domainProblemDataType {
  generalproductissue = 'generalproductissue',
  generalbillingitemissue = 'generalbillingitemissue',
  generalcompanybankaccountissue = 'generalcompanybankaccountissue',
  message = 'message',
  generalexportjobissue = 'generalexportjobissue',
  generaltemplateissue = 'generaltemplateissue',
  serviceconsumptioncalculationissue = 'serviceconsumptioncalculationissue',
  billingcalculationissue = 'billingcalculationissue',
  mandateissue = 'mandateissue',
  tariffcalculationissue = 'tariffcalculationissue',
  daterangeissue = 'daterangeissue'
}

export enum entityAttributeType {
  capacity = 'capacity',
  quota = 'quota',
  surface = 'surface',
  occupants = 'occupants',
  propertyquota = 'propertyquota'
}

export enum entitySubjectType {
  customer = 'customer',
  contract = 'contract',
  meter = 'meter',
  servicelocation = 'servicelocation',
  invoice = 'invoice',
  importjob = 'importjob',
  propertygroup = 'propertygroup',
  customermandate = 'customermandate',
  organisationscenario = 'organisationscenario',
  payment = 'payment',
  communicationentry = 'communicationentry',
  exportjob = 'exportjob',
  dataexport = 'dataexport',
  organisation = 'organisation',
  dataimport = 'dataimport',
  entityattribute = 'entityattribute',
  asset = 'asset',
  bankaccount = 'bankaccount',
  billingitem = 'billingitem',
  billingtariff = 'billingtariff',
  costcomponent = 'costcomponent',
  costcomponentvalue = 'costcomponentvalue',
  model = 'model',
  numbersequence = 'numbersequence',
  billingparameters = 'billingparameters',
  product = 'product',
  projectlocation = 'projectlocation',
  taxcode = 'taxcode',
  template = 'template',
  billingrelation = 'billingrelation',
  transaction = 'transaction',
  dataimportfileformat = 'dataimportfileformat',
  dataexportfileformat = 'dataexportfileformat',
  tenant = 'tenant',
  consumption = 'consumption',
  attachment = 'attachment',
  exportjobline = 'exportjobline',
  collectioncase = 'collectioncase',
  consumptionunittype = 'consumptionunittype',
  incomingInvoice = 'incominginvoice',
  moverequest = 'moverequest',
  outgoingbankingtransaction = 'outgoingbankingtransaction',
  incomingbankingtransaction = 'incomingbankingtransaction',
  incomingmutation = 'incomingmutation',
  outgoingmutation = 'outgoingmutation',
  personalinformation = 'personalinformation',
  customerbankaccount = 'customerbankaccount',
  pluginmanualtrigger = 'pluginmanualtrigger',
  billingcustomer = 'billingcustomer',
  enumsymboltranslation = 'enumsymboltranslation',
  scenariotranslation = 'scenariotranslation',
  syntheticloadprofile = 'syntheticloadprofile',
  consumergroup = 'consumergroup',
  estimablecontract = 'estimablecontract',
  plugininboundwebhooktrigger = 'plugininboundwebhooktrigger',
  pluginmappingentry = 'pluginmappingentry',
  eavestimationrun = 'eavestimationrun',
  customentitypropertytype = 'customentitypropertytype',
  customentityproperty = 'customentityproperty',
  entityAttachment = 'entityAttachment',
  entityattachmentgroup = 'entityattachmentgroup',
  propertygroupforecastingconfiguration = 'propertygroupforecastingconfiguration',
  estimatedinvoice = 'estimatedinvoice',
  socialtariffexport = 'socialtariffexport',
  customergroup = 'customergroup',
  meteringissue = 'meteringissue'
}

export enum executionStatus {
  unknown = 'unknown',
  uploaded = 'uploaded',
  ignored = 'ignored',
  processed = 'processed',
  failed = 'failed',
  processedwitherrors = 'processedwitherrors',
  readallcontents = 'readallcontents',
  processing = 'processing'
}

export enum exportSettingsCategoryType {
  paymentrequest = 'paymentrequest',
  paymentrefund = 'paymentrefund',
  invoice = 'invoice'
}

export enum exportJobLineStatus {
  created = 'created',
  gatheringdata = 'gatheringdata',
  ready = 'ready'
}

export enum exportJobStatus {
  created = 'created',
  generating = 'generating',
  generated = 'generated',
  transferred = 'transferred',
  failed = 'failed',
  gatheringdata = 'gatheringdata',
  ready = 'ready',
  gathereddata = 'gathereddata',
  transferring = 'transferring'
}

export enum fileSizeType {
  b = 'b',
  kb = 'kb',
  mb = 'mb',
  gb = 'gb'
}

export enum formulaStreamType {
  totalaggregatedconsumption = 'totalaggregatedconsumption',
  totalchildconsumption = 'totalchildconsumption',
  totalcommunalconsumption = 'totalcommunalconsumption'
}

export enum ftpProtocol {
  none = 'none',
  ssl2 = 'ssl2',
  ssl3 = 'ssl3',
  tls = 'tls',
  default = 'default',
  tls11 = 'tls11',
  tls12 = 'tls12'
}

export enum importCategoryType {
  metering = 'metering',
  migration = 'migration'
}

export enum importContractInvoiceDateHandling {
  donothing = 'donothing',
  recalculateinvoicedates = 'recalculateinvoicedates',
  nextinvoicedateminusfrequency = 'nextinvoicedateminusfrequency'
}

export enum importJobEntityIssueStage {
  validation = 'validation',
  processing = 'processing'
}

export enum importJobEntityType {
  externalmeasurement = 'externalmeasurement',
  banktransaction = 'banktransaction',
  property = 'property',
  servicelocation = 'servicelocation',
  meter = 'meter',
  customer = 'customer',
  customermandate = 'customermandate',
  contract = 'contract',
  payment = 'payment',
  invoice = 'invoice',
  attachment = 'attachment',
  entityattribute = 'entityattribute',
  contractedservice = 'contractedservice',
  invoiceline = 'invoiceline',
  billingcompleteness = 'billingcompleteness',
  meterchannel = 'meterchannel',
  service = 'service',
  communicationpreferences = 'communicationpreferences',
  propertygroupbillingconfiguration = 'propertygroupbillingconfiguration'
}

export enum importJobStatus {
  created = 'created',
  reading = 'reading',
  storing = 'storing',
  stored = 'stored',
  processing = 'processing',
  processed = 'processed',
  failed = 'failed',
  validating = 'validating',
  validated = 'validated',
  invalid = 'invalid',
  postprocessing = 'postprocessing',
  finished = 'finished'
}

export enum incrementationType {
  delta = 'delta',
  cumul = 'cumul',
  na = 'na'
}

export enum invoiceFrequency {
  monthly = 'monthly',
  quarterly = 'quarterly',
  halfyearly = 'halfyearly',
  yearly = 'yearly'
}

export enum invoiceLineType {
  advance = 'advance',
  previouslyadvanced = 'previouslyadvanced',
  charge = 'charge',
  vatpennydifference = 'vatpennydifference'
}

export enum invoiceStatus {
  created = 'created',
  approved = 'approved',
  settled = 'settled',
  generated = 'generated',
  generationfailed = 'generationfailed'
}

export enum invoiceType {
  advance = 'advance',
  invoice = 'invoice',
  endnote = 'endnote',
  creditnote = 'creditnote',
  incidentalnote = 'incidentalnote',
  correctionnote = 'correctionnote'
}

export enum languageEnum {
  nl = 'nl',
  fr = 'fr',
  en = 'en'
}

export enum localisationLevel {
  none = 'none',
  partial = 'partial',
  all = 'all'
}

export enum managementRelationType {
  owner = 'owner',
  propertymanager = 'propertymanager'
}

export enum mandateStatus {
  requested = 'requested',
  cancelled = 'cancelled',
  signed = 'signed'
}

export enum mandateType {
  core = 'core',
  b2b = 'b2b'
}

export enum messageType {
  informational = 'informational',
  warning = 'warning',
  error = 'error'
}

export enum meteringType {
  none = 'none',
  energy = 'energy',
  volume = 'volume',
  volumeflow = 'volumeflow',
  flowtemp = 'flowtemp',
  returntemp = 'returntemp',
  difftemp = 'difftemp',
  balance = 'balance'
}

export enum meterStatus {
  requested = 'requested',
  installed = 'installed',
  removed = 'removed'
}

export enum meterType {
  individual = 'individual',
  root = 'root',
  aggregating = 'aggregating',
  communal = 'communal',
  prepayment = 'prepayment'
}

export enum meteringIssueError {
  consumptionnegative = 'consumptionnegative'
}

export enum meteringIssueStatus {
  unresolved = 'unresolved',
  ignored = 'ignored',
  resolved = 'resolved',
  muted = 'muted'
}
export enum meteringIssueResolutionType {
  manual = 'manual',
  automatic = 'automatic'
}

export enum numberSequenceReferenceEntity {
  customer = 'customer',
  contract = 'contract',
  invoice = 'invoice',
  mandate = 'mandate',
  exportjob = 'exportjob',
  collectioncase = 'collectioncase',
  batchmutation = 'batchmutation'
}

export enum numberSequenceSegmentType {
  constant = 'constant',
  year = 'year',
  invoicetype = 'invoicetype',
  date = 'date'
}

export enum paymentDelayEnum {
  delayuntilfixeddayofmonth = 'delayuntilfixeddayofmonth',
  delaynumberofdays = 'delaynumberofdays'
}

export enum paymentMethod {
  sct = 'sct',
  sdd = 'sdd'
}

export enum paymentRetryAction {
  daysafterreversal = 'daysafterreversal',
  retryonfixedday = 'retryonfixedday'
}

export enum paymentReversalReason {
  manualreverse = 'manualreverse',
  unpaiddirectdebit = 'unpaiddirectdebit',
  reversalsepadirectdebit = 'reversalsepadirectdebit',
  rejectdomesticdirectdebit = 'rejectdomesticdirectdebit',
  unknownbankaccount = 'unknownbankaccount',
  terminatedbankaccount = 'terminatedbankaccount',
  blockedbankaccount = 'blockedbankaccount',
  unsupportedbankaccount = 'unsupportedbankaccount',
  invalidsequencetype = 'invalidsequencetype',
  insufficientfunds = 'insufficientfunds',
  invalidmandate = 'invalidmandate',
  debtordeceased = 'debtordeceased',
  refusalbydebtor = 'refusalbydebtor',
  refusalotherreason = 'refusalotherreason',
  maximumamountperiodreached = 'maximumamountperiodreached'
}

export enum paymentReversalReasonCode {
  ac01 = 'ac01',
  ac04 = 'ac04',
  ac06 = 'ac06',
  ag02 = 'ag02',
  am04 = 'am04',
  md01 = 'md01',
  md06 = 'md06',
  md07 = 'md07',
  ms02 = 'ms02',
  ms03 = 'ms03',
  sl01 = 'sl01'
}

export enum paymentTermsFrequency {
  day = 'day',
  week = 'week',
  month = 'month'
}

export enum problemLevel {
  error = 'error',
  warning = 'warning'
}

export enum problemResolutionStatus {
  unresolved = 'unresolved',
  manualresolution = 'manualresolution',
  automaticresolution = 'automaticresolution'
}

export enum propertyType {
  value = 'value',
  array = 'array',
  object = 'object',
  xmlattribute = 'xmlattribute'
}

export enum scheduledJobRecurrence {
  none = 'none',
  pt5m = 'pt5m',
  pt1h = 'pt1h',
  pt1d = 'pt1d'
}

export enum scheduledJobType {
  billingadvancerun = 'billingadvancerun',
  meterdataimport = 'meterdataimport'
}

export enum sentStatus {
  notsent = 'notsent',
  skipped = 'skipped',
  sent = 'sent',
  printing = 'printing',
  failed = 'failed'
}

export enum serviceStatus {
  requested = 'requested',
  supplied = 'supplied',
  unsupplied = 'unsupplied',
  removed = 'removed'
}

export enum templateAssetType {
  css = 'css',
  headertemplate = 'headertemplate',
  footertemplate = 'footertemplate',
  image = 'image',
  constant = 'constant'
}

export enum templateObjectType {
  invoice = 'invoice',
  collectioncase = 'collectioncase',
  moverequest = 'moverequest',
  contract = 'contract'
}

export enum templateUsecase {
  pdf = 'pdf',
  email = 'email'
}

export enum transactionReferenceType {
  payment = 'payment',
  invoice = 'invoice',
  collectionstep = 'collectionstep'
}

export enum transactionType {
  invoice = 'invoice',
  payment = 'payment',
  reversal = 'reversal',
  collectionfee = 'collectionfee',
  writeoff = 'writeoff'
}

export enum unitOfMeasure {
  none = 'none',
  kwh = 'kwh',
  mwh = 'mwh',
  gwh = 'gwh',
  celcius = 'celcius',
  j = 'j',
  kj = 'kj',
  mj = 'mj',
  gj = 'gj',
  m3 = 'm3',
  units = 'units',
  day = 'day',
  month = 'month',
  year = 'year',
  litre = 'litre',
  wh = 'wh',
  m3_h = 'm3_h',
  k = 'k'
}

export enum direction {
  offtake = 'offtake',
  injection = 'injection',
  na = 'na'
}

export enum utilityType {
  none = 'none',
  heat = 'heat',
  cooling = 'cooling',
  hottapwater = 'hottapwater',
  water = 'water',
  electricity = 'electricity',
  gas = 'gas',
  rainwater = 'rainwater',
  services = 'services'
}

export enum valueProviderType {
  servicelocationidfrominvoicelines = 'servicelocationidfrominvoicelines',
  attachmentidfrominvoice = 'attachmentidfrominvoice',
  companybankaccountidfrompayment = 'companybankaccountidfrompayment',
  contractidfrominvoice = 'contractidfrominvoice',
  servicelocationidfrominvoicelinesfallbacktocontractservicelocationid = 'servicelocationidfrominvoicelinesfallbacktocontractservicelocationid',
  customeridfrominvoice = 'customeridfrominvoice'
}

export enum collectionChargeType {
  none = 'none',
  fixed = 'fixed',
  percentageOfOpenAmount = 'percentageofopenamount',
  tieredPercentageOfOpenAmount = 'tieredpercentageofopenamount'
}

export enum collectionStepType {
  communication = 'communication',
  manualIntervention = 'manualintervention'
}

export enum collectionFlowStatus {
  active = 'active',
  manuallyclosed = 'manuallyclosed',
  automaticallyclosed = 'automaticallyclosed'
}

export enum collectionStepStatus {
  pending = 'pending',
  waitingformanualsend = 'waitingformanualsend',
  closed = 'closed',
  inprogress = 'inprogress',
  skipped = 'skipped',
  generatingdocuments = 'generatingdocuments',
  failed = 'failed',
  printing = 'printing'
}

export enum writeOffHandlingType {
  nowriteoff = 'nowriteoff',
  openamount = 'openamount',
  chargeamount = 'chargeamount'
}

export enum propertyGroupType {
  building = 'building',
  project = 'project'
}

export enum custAgingBucketType {
  anyopenexcloverdue = 'anyopenexcloverdue',
  anyoverdue = 'anyoverdue',
  anyoverdue30 = 'anyoverdue30',
  anyoverdue60 = 'anyoverdue60',
  anyoverdue90 = 'anyoverdue90',
  anyoverduemorethan90 = 'anyoverduemorethan90'
}

export enum billingItemTariffCalculationType {
  unitprice = 'unitprice',
  tiered = 'tiered', // ratepertier
  volume = 'volume',
  stairstep = 'stairstep'
}

export enum billingItemConditionType {
  paymentmethod = 'tariffconditiontypeparametersdto.paymentmethod',
  customentityproperty = 'tariffconditiontypeparametersdto.customentitypropertytype'
}

export enum formulaFunctionInputParameterReferenceType {
  fixed = 'fixed',
  runtimeparameter = 'runtimeparameter',
  formulafunctionoutput = 'formulafunctionoutput'
}

export enum tariffType {
  tariffcalculation = 'tariffcalculation',
  conditions = 'conditions',
  tariffperiod = 'tariffperiod'
}

export enum formulaFunctionType {
  getmetersofservicelocationslinkedtopropertygroup = 'getmetersofservicelocationslinkedtopropertygroup',
  getmatchingchannelsformeters = 'getmatchingchannelsformeters',
  getserviceconsumptionsforexternalchannels = 'getserviceconsumptionsforexternalchannels',
  aggregateconsumptions = 'aggregateconsumptions',
  subtractconsumptions = 'subtractconsumptions',
  getmeterconsumptionsforexternalchannels = 'getmeterconsumptionsforexternalchannels',
  gettotaloutputchannelconsumption = 'gettotaloutputchannelconsumption',
  gettotalmonthssuppliedforproperty = 'gettotalmonthssuppliedforproperty',
  dividevalues = 'dividevalues',
  multiplyvalues = 'multiplyvalues',
  addvalues = 'addvalues',
  subtractvalues = 'subtractvalues',
  gettotalincominginvoicecomponentvalue = 'gettotalincominginvoicecomponentvalue',
  aggregatetotalconsumptions = 'aggregatetotalconsumptions',
  applycorrectionfactortoconsumptions = 'applycorrectionfactortoconsumptions',
  getmeterslinkedtopropertygroup = 'getmeterslinkedtopropertygroup'
}

export enum formulaType {
  calculatedconsumption = 'calculatedconsumption',
  calculatedtariffs = 'calculatedtariffs'
}

export enum propertyGroupBillingPeriodTariffStatus {
  nocalculatedtariffsrequired = 'nocalculatedtariffsrequired',
  calculatedtariffsmissing = 'calculatedtariffsmissing',
  calculatedtariffsavailable = 'calculatedtariffsavailable'
}

export enum MutingRuleTimePeriod {
  day = 'day',
  week = 'week',
  month = 'month'
}

export enum formulaFunctionCategory {
  billing = 'billing',
  consumptions = 'consumptions',
  contracts = 'contracts',
  math = 'math',
  meterConfig = 'meterConfig',
  meters = 'meters'
}

export enum aggregationFrequency {
  none = 'none',
  daily = 'daily',
  monthly = 'monthly',
  yearly = 'yearly'
}

export enum moveRequestStatus {
  new = 'new',
  pendingatcustomer = 'pendingatcustomer',
  pendingatsupplier = 'pendingatsupplier',
  processed = 'processed',
  rejected = 'rejected'
}

export enum moveRequestType {
  movein = 'movein',
  moveout = 'moveout'
}

// Identity
export enum tenantDatabaseStorageType {
  classic = 'classic',
  databasepertenant = 'databasepertenant'
}

export enum tenantFileStorageType {
  classic = 'classic'
}

export enum invoicingType {
  none = 'none',
  default = 'default',
  organization = 'organization'
}

export enum category {
  saas = 'saas',
  bpo = 'bpo'
}

export enum outgoingBankingTransactionType {
  paymentrequests = 'paymentrequests',
  paymentrefunds = 'paymentrefunds'
}

export enum outgoingBankingTransactionStatus {
  created = 'created',
  closed = 'closed',
  toconfirmbybank = 'toconfirmbybank',
  readytosend = 'readytosend',
  resolveissues = 'resolveissues',
  dateexpired = 'dateexpired',
  partiallyclosed = 'partiallyclosed',
  cancelled = 'cancelled'
}

export enum incomingBankingTransactionStatus {
  created = 'created',
  processing = 'processing',
  matching = 'matching',
  resolveissues = 'resolveissues',
  readytoprocess = 'readytoprocess',
  closed = 'closed'
}

export enum mutationStatus {
  created = 'created',
  ignored = 'ignored',
  processed = 'processed'
}

export enum outgoingMutationType {
  invoice = 'invoice',
  customer = 'customer'
}

export enum paymentProcessStatus {
  readyforpaymentcollection = 'readyforpaymentcollection',
  pendingpaymentexport = 'pendingpaymentexport',
  pendingpaymentcollectionconfirmation = 'pendingpaymentcollectionconfirmation',
  paid = 'paid',
  paymentrejected = 'paymentrejected',
  waitingforpayment = 'waitingforpayment'
}

// Only use this enum for language change on user avatar
export enum uiCulture {
  'en' = 'en',
  'nl' = 'nl',
  'fr' = 'fr',
  'fi' = 'fi',
  'de' = 'de',
  'es' = 'es'
}

// This enum is used for application language only, not formatting, currency or config languages
export enum culture {
  'en' = 'en',
  'nl-BE' = 'nl-BE',
  'nl-NL' = 'nl-NL',
  'fr-BE' = 'fr-BE',
  'fr-FR' = 'fr-FR',
  'fi' = 'fi',
  'de' = 'de',
  'es' = 'es'
}

export enum paymentType {
  incomingtransfer = 'incomingtransfer',
  outgoingtransfer = 'outgoingtransfer',
  directdebit = 'directdebit'
}

export enum incomingMutationStatus {
  created = 'created',
  ignored = 'ignored',
  resolveissues = 'resolveissues',
  readytoprocess = 'readytoprocess',
  processing = 'processing',
  processed = 'processed',
  failed = 'failed',
  matched = 'matched'
}

export enum ibanConfirmationStatus {
  Known = 'known',
  Unknown = 'unknown',
  UnknownFirst = 'unknownfirst',
  UnknownSecond = 'unknownsecond',
  Unspecified = 'unspecified'
}

export enum outgoingMutationStatus {
  created = 'created',
  readytosend = 'readytosend',
  resolveissues = 'resolveissues',
  toconfirmbybank = 'toconfirmbybank',
  closed = 'closed',
  reversed = 'reversed',
  failedtoclose = 'failedtoclose',
  failedtoreverse = 'failedtoreverse'
}

export enum incomingMutationType {
  incomingtransfer = 'incomingtransfer',
  outgoingtransfer = 'outgoingtransfer',
  paymentrequestreversal = 'paymentrequestreversal',
  refundreversal = 'refundreversal',
  unknown = 'unknown',
  paymentrequesttransactionconfirmation = 'paymentrequesttransactionconfirmation',
  paymentrequestmutationconfirmation = 'paymentrequestmutationconfirmation',
  refundtransactionconfirmation = 'refundtransactionconfirmation',
  refundmutationconfirmation = 'refundmutationconfirmation',
  requestconfirmation = 'requestconfirmation',
  refundconfirmation = 'refundconfirmation'
}

export enum incomingMutationPaymentMethod {
  sddconfirmations = 'sddconfirmations',
  reversals = 'reversals',
  sct = 'sct',
  unknown = 'unknown'
}

export enum paymentRefundOutgoingBankTransactionFormatType {
  pain_001001003 = 'pain_001001003'
}

export enum paymentRequestOutgoingBankTransactionFormatType {
  pain_008001002 = 'pain_008001002'
}

export enum incomingBankingTransactionFormatType {
  camt053_001_02 = 'camt053_001_02'
}

export enum paymentReversalType {
  manualreversal = 'manualreversal',
  bankreversal = 'bankreversal'
}

export enum exportOutgoingBankingTransactionPaymentRefundFormatType {
  pain_001001003 = 'pain_001001003'
}

export enum inputMissingReason {
  unknown = 'unknown',
  missingadvances = 'missingadvances',
  missingreceivingperiods = 'missingreceivingperiods',
  missingtariffs = 'missingtariffs',
  invoicecreationfailed = 'invoicecreationfailed',
  invoicelinecalculationfailed = 'invoicelinecalculationfailed',
  receivingperiodsstarttoolate = 'receivingperiodsstarttoolate',
  nomeasurementonstart = 'nomeasurementonstart',
  nomeasurementonend = 'nomeasurementonend'
}

export enum pluginTriggerDataType {
  advanceamountupdated = 'advanceamountupdated',
  consumptionprocessed = 'consumptionprocessed',
  contractremoved = 'contractremoved',
  contractsigned = 'contractsigned',
  contractterminated = 'contractterminated',
  cultureupdated = 'cultureupdated',
  customercreated = 'customercreated',
  customerdetailschanged = 'customerdetailschanged',
  customerservicelocationupdated = 'customerservicelocationupdated',
  invoiceavailable = 'invoiceavailable',
  invoicepaymentdetailschanged = 'invoicepaymentdetailschanged',
  servicelocationupdated = 'servicelocationupdated',
  measurementsstored = 'measurementsstored',
  meterinstalled = 'meterinstalled',
  meterreconfigured = 'meterreconfigured',
  meteruninstalled = 'meteruninstalled',
  productcreated = 'productcreated',
  productupdated = 'productupdated',
  servicelocationcreated = 'servicelocationcreated',
  entityattachmentsforcustomerchanged = 'entityattachmentsforcustomerchanged'
}

export enum integrationLoggingStatus {
  finished = 'finished',
  erroneous = 'erroneous',
  notyetstarted = 'notyetstarted'
}

export enum integrationLoggingType {
  createcustomer = 'createcustomer',
  createservicedlocations = 'createservicedlocations'
}

export enum pluginType {
  enduserportal = 'enduserportal',
  httprequestwithpreviousresponse = 'httprequestwithpreviousresponse',
  brunatawms = 'brunatawms',
  zendeskcrm = 'zendeskcrm',
  freshdeskcrm = 'freshdeskcrm',
  fortesmetering = 'fortesmetering',
  pingendocumentprinting = 'pingendocumentprinting',
  zerofrictiondemometering = 'zerofrictiondemometering',
  twikey = 'twikey',
  paytbillingplugin = "paytbillingplugin",
  bergerlevraultplugin = "bergerlevrault"
}

export enum tenantStatus {
  active = 'active',
  deleting = 'deleting',
  archived = 'archived'
}

export enum invoiceScenarioType {
  advance = 'advance',
  correctionnote = 'correctionnote',
  creditnote = 'creditnote',
  endnote = 'endnote',
  incidentalnote = 'incidentalnote',
  invoice = 'invoice'
}

export enum collectionStepLevel {
  collection = 'collection',
  reminder = 'reminder',
  noticeofdefault = 'noticeofdefault'
}

export enum contractScenarioType {
  welcome = 'welcome',
  termination = 'termination'
}

export enum moveRequestScenarioType {
  acknowledgemovein = 'acknowledgemovein',
  rejectmovein = 'rejectmovein',
  acknowledgemoveout = 'acknowledgemoveout',
  rejectmoveout = 'rejectmoveout'
}

export enum billingRelationScenarioType {
  advanceamountchanged = 'advanceamountchanged'
}

export enum envelopePosition {
  left = 'left',
  right = 'right'
}

export enum translationStatus {
  translated = 'translated',
  missingtranslation = 'missingtranslation',
  defaulttranslation = 'defaulttranslation'
}

export enum estimatedAnnualVolumeOrigin {
  manual = 'manual',
  frompreviousyear = 'frompreviousyear',
  fromconsumergroup = 'fromconsumergroup',
  forecast = 'forecast'
}

export enum updateContractProductParameter {
  fromcontractstartdate = 'fromcontractstartdate',
  fromfirstopenperiod = 'fromfirstopenperiod',
  billingperiodstartdate = 'billingperiodstartdate', // Only local
  fromsupplieddate = 'fromsupplieddate'
}

export enum advanceAmountChangedBy {
  systeminvoicecalculation = 'systeminvoicecalculation',
  customer = 'customer',
  customerservicerepresentative = 'customerservicerepresentative'
}

export enum scenarioGroupingType {
  personalinformation = 'personalinformation',
  moverequest = 'moverequest',
  contract = 'contract',
  collectioncase = 'collectioncase',
  invoice = 'invoice'
}

export enum customEntityPropertyValueType {
  string = 'BasePropertyValueTypeDTO.String',
  number = 'BasePropertyValueTypeDTO.Number',
  decimal = 'BasePropertyValueTypeDTO.Decimal',
  boolean = 'BasePropertyValueTypeDTO.Boolean',
  datetime = 'BasePropertyValueTypeDTO.DateTime'
}

export enum dataType {
  text = 'text',
  number = 'number',
  date = 'date',
  enumeration = 'enumeration',
  dateandtime = 'dateandtime'
}

export enum AdvanceAmountLimitType {
  fixed = 'fixed',
  percentage = 'percentage'
}

export enum attachmentVisibility {
  internal = 'internal',
  portal = 'portal',
  portalandmovein = 'portalandmovein'
}

export enum consumerGroupSuggestion {
  frompropertygroup = 'frompropertygroup',
  originmanual = 'originmanual',
  originsystemgenerated = 'originsystemgenerated'
}

export enum onlyMutationsOptions {
  completecustomerportfolio = 'completecustomerportfolio',
  protectedcustomer = 'protectedcustomer'
}

export enum quarters {
  q1 = 'q1',
  q2 = 'q2',
  q3 = 'q3',
  q4 = 'q4'
}

export enum statusCode {
  active = 'active',
  inactive = 'inactive',
  failed = 'failed'
}

export enum twikeyPluginStatus {
  active = 'active',
  failed = 'failed'
}

export enum contractBillingMethod {
  credit = 'credit',
  prepayment = 'prepayment'
}

export enum topupOrigin {
  paypoint = 'paypoint',
  wiretransfer = 'wiretransfer',
  stripe = 'stripe'
}

export enum exportStatus {
  notexported = 'notexported',
  exported = 'exported'
}

export enum tierCalculationMethod {
  invoiceperiod = 'invoiceperiod',
  tariffperiod = 'tariffperiod'
}

export enum billingScope {
  contract = 'contract',
  location = 'location'
}

export enum MeteringIssueError {
  consumptionnegative = 'consumptionnegative',
  consumptiontoohigh = 'consumptiontoohigh',
  consumptiontoolow = 'consumptiontoolow',
  consumptionzerotoolong = 'consumptionzerotoolong',
  measurementfrequencytoohigh = 'measurementfrequencytoohigh',
  measurementfrequencytoolow = 'measurementfrequencytoolow'
}

export enum MeteringIssueStatus {
  unresolved = 'unresolved',
  ignored = 'ignored',
  resolved = 'resolved',
  muted = 'muted'
}

export enum MeteringIssueResolutionReason {
  newmeasurementimportjob = 'newmeasurementimportjob',
  measurementdeleted = 'measurementdeleted',
  newmeasurementmanuallyadded = 'newmeasurementmanuallyadded',
  newmeasurementmoverequest = 'newmeasurementmoverequest'
}

export enum MeteringIssueResolutionType {
  manual = 'manual',
  automatic = 'automatic'
}

export enum Level {
  low = 'low',
  moderate = 'moderate',
  high = 'high'
}

export enum AdvanceCalculationFallbackReason {
  na = 'na',
  deviationtoolow = 'deviationtoolow',
  accuracytoolow = 'accuracytoolow',
  estimationfailed = 'estimationfailed',
  estimationfailedanddeviationtoolow = 'estimationfailedanddeviationtoolow',
  manuallyoverridden = 'manuallyoverridden'
}

export enum AdvanceRecalculationType {
  none = 'none',
  usingpreviousinvoice = 'usingpreviousinvoice',
  usingestimatedinvoice = 'usingestimatedinvoice'
}
