import classNames from 'classnames';
import React, { Context, ReactNode, useContext } from 'react';

import { entitySubjectType } from '@zf/api-types/enums';

import { ErrorBoundary } from '../Error';
import { SuspenseSpinner } from '../suspense';
import css from './list-pageV2.module.scss';

type Props<T> = {
  entitySubjectType: entitySubjectType;
  topBar: ReactNode;
  list: ReactNode;
  sidePanel?: JSX.Element | null;
  infoGrid?: ReactNode;
  embeddedInDetailPage?: boolean;
  context: Context<T>;
  half?: boolean;
};

export default function ListPageNew<T extends { store: any }>(props: Props<T>) {
  const {
    entitySubjectType,
    half = false,
    topBar,
    list,
    sidePanel,
    infoGrid,
    context,
    embeddedInDetailPage = false
  } = props;

  const { showSidePanel, showOnActivate } = useContext(context).store.listPage;

  const sidePanelActivate = showSidePanel || showOnActivate;

  return (
    <div id={`${entitySubjectType}-list-page`} className={css['listpage-wrapper']}>
      <div className={classNames(css['list-page'], { [css['embedded-in-detail-page']]: embeddedInDetailPage })}>
        <div className={css['top-bar']}>
          <ErrorBoundary>
            <SuspenseSpinner>{topBar}</SuspenseSpinner>
          </ErrorBoundary>
        </div>

        <div className={css['list']}>
          <div
            className={classNames(css[half ? 'table-50' : 'table'], { [css['table-full-width']]: !sidePanelActivate })}
          >
            <ErrorBoundary>
              <SuspenseSpinner>
                {list}
                {infoGrid || null}
              </SuspenseSpinner>
            </ErrorBoundary>
          </div>

          {sidePanelActivate && (
            <div className={css[half ? 'side-panel-50' : 'side-panel']}>
              <ErrorBoundary>
                <SuspenseSpinner>{sidePanel}</SuspenseSpinner>
              </ErrorBoundary>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
