import React from 'react';

import { CollectionCaseRowType } from '@zf/api-types/collection-case';
import { formatDateForExport } from '@zf/utils/src/date';

import useCurrent from '../../../app-context/hooks/use-current';
import { useStore } from '../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import ExportToExcel from './ExportToExcel';

type Props = {
  extraColumnTitles: string[];
};

export default function CollectionCaseExport(props: Props) {
  const { extraColumnTitles } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { myCurrent } = useCurrent('collection_case');
  const selectedRows: CollectionCaseRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = (): string[][] => {
    const exportArray = [];

    exportArray.push([
      getTranslation('collection_case.labels.collection_id'),
      getTranslation('collection_case.labels.workflow_name'),
      getTranslation('collection_case.labels.debtor'),
      getTranslation('collection_case.labels.manual_intervention_required'),
      getTranslation('general.status'),
      getTranslation('collection_case.labels.last_step'),
      getTranslation('collection_case.labels.next_step'),
      getTranslation('collection_case.labels.payment_reference'),
      getTranslation('collection_case.labels.open_amount_incl_vat'),
      getTranslation('collection_case.labels.next_action_due'),
      getTranslation('property_groups.name'),
      getTranslation('customer_groups.customer_group'),
      ...extraColumnTitles
    ]);

    selectedRows.forEach((r) => {
      const collectionCase = r.__entity;

      const extraCustomEntityColumns = getExtraCustomEntityColumns(
        collectionCase.customProperties,
        r.__customEntityPropertyTypes,
        getTranslation
      );

      const extraCustomEntityColumnValues = Object.keys(extraCustomEntityColumns).map(
        (key) => extraCustomEntityColumns[key]
      );

      const propertyGroupNames = collectionCase.propertyGroups?.map((pg) => pg.name) || [];

      exportArray.push([
        collectionCase.sequenceNum,
        collectionCase.workflowName,
        collectionCase.debtor.displayName,
        getTranslation(`general.${collectionCase.manualInterventionRequired ? 'yes' : 'no'}`),
        getEnumTranslation('collectionFlowStatus', collectionCase.status),
        collectionCase.lastStep?.name || '',
        collectionCase.nextStep?.name || '',
        collectionCase.paymentReference,
        collectionCase.totalOpenAmountInclVAT,
        formatDateForExport(collectionCase.nextActionDue),
        //collectionCase.propertyGroup?.name || '',
        propertyGroupNames.join(','),
        collectionCase.debtor.customerGroup?.name || '',
        ...extraCustomEntityColumnValues
      ]);
    });

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_collection_cases')}
      ws_name={getTranslation('collection_case.collection_cases')}
    />
  );
}
