import { observer } from 'mobx-react-lite';
import React from 'react';

import FilterInputs from '../../../components/Filters/filter-inputs';
import FilterTags from '../../../components/Filters/filter-tags';
import ColumnViewIcon from '../../../components/Icon/ColumnViewIcon';
import PanelIcon from '../../../components/Icon/PanelIcon';
import { useStore } from '../../../hooks/useStore';
import css from './billing-items-tab.module.scss';

type Props = {
  tags: React.ReactNode[];
  filterInputs: JSX.Element[];
  resetFilters: () => void;
};

const BillingItemsFilters = (props: Props) => {
  const { tags, filterInputs, resetFilters } = props;

  const { productConfigStore } = useStore();
  const { isPanelViewMode, togglePanelView } = productConfigStore;

  return (
    <div className={css['items-filter']}>
      <FilterTags tags={tags} />
      {isPanelViewMode ? (
        <ColumnViewIcon id="billing-items" className={css['panel-icon']} onClick={togglePanelView} />
      ) : (
        <PanelIcon id="billing-items" className={css['panel-icon']} onClick={togglePanelView} />
      )}
      <FilterInputs filterInputs={filterInputs} removeAllFilters={resetFilters} />
    </div>
  );
};

export default observer(BillingItemsFilters);
