import moment, { Moment } from 'moment';

import { aggregationFrequency } from '@zf/api-types/enums';
import { OutputQueryParamsType } from '@zf/api-types/graph';

import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import { OutputCtxState, useTracked } from '../context/output-context';

export default function useQueryParams() {
  const [state, dispatch] = useTracked();

  const setState = (newState: Partial<OutputCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const setQueryParams = (newParams: Partial<OutputQueryParamsType>) => {
    setState({ queryParams: { ...state.queryParams, ...newParams } });
  };

  const setGroupByPeriod = (newGroupBy: string) => {
    setQueryParams({ groupByPeriod: newGroupBy as aggregationFrequency });
  };

  const setDates = (newDates: Array<Moment | null | undefined>) => {
    setQueryParams({
      startDateTime: newDates[0] ? newDates[0] : moment(MIN_DATE),
      endDateTime: newDates[1] ? newDates[1] : moment(MAX_DATE)
    });
  };

  return {
    queryParams: state.queryParams,
    groupByPeriod: state.queryParams.groupByPeriod,
    startDateTime: state.queryParams.startDateTime,
    endDateTime: state.queryParams.endDateTime,
    setGroupByPeriod,
    setDates
  };
}
