import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import { Icon } from '../Icon';
import css from './checkbox.module.scss';

export type CheckBoxProps = {
  id: string;
  checked: boolean | undefined;
  className?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  preventDefault?: boolean;
  disabled?: boolean;
  labelPosition?: 'left' | 'right';
  onChange?: (checked: boolean) => void;
};

export default function StellaCheckBox(props: CheckBoxProps) {
  const {
    id,
    className,
    labelClassName,
    checked = false,
    children,
    disabled = false,
    preventDefault = false,
    labelPosition = 'right',
    onChange
  } = props;

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | undefined>) => {
    if (onChange) onChange(e.target.checked);
  };

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventDefault ? e.preventDefault() : e.stopPropagation;
    },
    [preventDefault]
  );

  const handleTrigger = React.useCallback(() => {
    if (!disabled && onChange) {
      onChange(!checked);
    }
  }, [disabled, checked, onChange]);

  const styleObject: React.CSSProperties = React.useMemo(() => {
    return labelPosition === 'left' ? { paddingRight: '1rem' } : { paddingLeft: '1rem' };
  }, [labelPosition]);

  const label = children ? (
    <BaseButton id={`label-${id}`} className={css['label-text']} style={styleObject} onClick={handleTrigger}>
      {children}
    </BaseButton>
  ) : null;

  return (
    <div
      role="checkbox"
      className={classNames(css['checkbox-container'], className)}
      onClick={handleClick}
      tabIndex={0}
      aria-checked={true}
      onKeyDown={() => {}}
    >
      {labelPosition === 'left' && label}
      <input
        type="checkbox"
        className={classNames(
          {
            [css['has-label']]: !!children
          },
          css['checkbox']
        )}
        onChange={onChangeHandler}
        id={id}
        checked={checked}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className={classNames(css['styled-checkbox'], { [css['styled-checkbox-disabled']]: disabled }, labelClassName)}
      >
        <Icon name="check" />
      </label>
      {labelPosition === 'right' && label}
    </div>
  );
}
