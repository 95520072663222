import clone from 'clone';
import React from 'react';

import { exportSettingsCategoryType } from '@zf/api-types/enums';
import {
  APISettingsType,
  FileFormatSettingsFormatType,
  FileFormatSettingsType,
  UpdateFileFormatType
} from '@zf/api-types/file-format';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import { EnumReturnValue } from '../../../app-context/hooks/use-enum';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import CommitSection from '../../../components/config/commit-section';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import ParameterGenerator from '../../../components/parameter-generator/parameter-generator';
import { notify } from '../../../events/notification-events';
import { checkDropdownValues } from '../../../utils/dropdown';
import { METHODS, sendRequest } from '../../../utils/request';

type Props = {
  config: UseConfigReturnType<UpdateFileFormatType[]>;
  settings: APISettingsType[];
  useTracked: () => [
    ValidatorCtxState<UpdateFileFormatType[]>,
    React.Dispatch<ValidatorCtxAction<UpdateFileFormatType[]>>
  ];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineInputFieldDropdown = InlineInputField<SimpleDropdownProps<string>>(SimpleDropdown);

const getFormatTranslation = (enumReducer: EnumReturnValue, format: string) => {
  const formatLowerCase = format.toLowerCase();
  return enumReducer.getTranslation('dataExportInvoiceFormatType', formatLowerCase);
};

const createApiFriendlyValues = (format: UpdateFileFormatType) => {
  return {
    name: format.name,
    description: format.description,
    settings: format.settings
  };
};

export default function FileFormatsContent(props: Props) {
  const { settings, config } = props;
  const { sort } = config;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const [settingsDropdown] = React.useState(
    settings.map((setting) => {
      return {
        value: setting.classType,
        text: setting.displayName
          ? setting.displayName
          : enumReducer.getTranslation('exportSettingsCategoryType', setting.classType.toLowerCase())
      };
    })
  );

  const [formatType, setFormatType] = React.useState<APISettingsType[]>([]);

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 2,
      label: i18n.getTranslation('general.name'),
      dataKey: 'name'
    },
    {
      flexWidth: 3,
      label: i18n.getTranslation('general.description'),
      dataKey: 'description'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('file_format.settings'),
      dataKey: 'settings'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('file_format.formats'),
      dataKey: 'format'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const { values, backup, isDirty, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator<UpdateFileFormatType[]>(props.useTracked);

  const { addEntity, deleteEntity, handleSave } = useContextCRUD(props.useTracked);

  const addFileFormat = () => {
    if (backup.length < values.length) {
      notify.warning({
        content: i18n.getTranslation('file_format.add_warning')
      });
    } else {
      addEntity({
        name: '',
        description: '',
        settings: { type: '' as exportSettingsCategoryType, format: { type: '' } }
      });
    }
  };

  const checkMandatoryFields = () => {
    const hasWarning = values.some((v) => {
      return !v.settings.type || !v.settings.format.type;
    });

    if (hasWarning) {
      notify.warning({
        content: i18n.getTranslation('errors.config.mandatory_fields_warning')
      });
    }

    return hasWarning;
  };

  const handleFormatsSave = async () => {
    const hasWarning = checkMandatoryFields();

    if (!hasWarning) {
      return handleSave(
        createApiFriendlyValues,
        'name',
        '/cfg/DataExportFileFormats',
        i18n.getTranslation('file_format.success_update'),
        i18n.getTranslation('file_format.error_update')
      );
    }
  };

  if (!values) return null;

  const setFileFormats = (
    index: number,
    value: UpdateFileFormatType[keyof UpdateFileFormatType],
    dataKey: keyof UpdateFileFormatType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdateFileFormatType[]
    });
  };

  const getFormatType = async (settingType: string) => {
    if (settingType) {
      const settingIndex = settings.findIndex(
        (setting) => setting.classType.toLowerCase() === settingType.toLowerCase()
      );

      const result = (
        await sendRequest<APISettingsType[]>({
          request: {
            method: METHODS.GET,
            endpoint: `/cfg/DataExportFileFormats/settings/${
              settings[settingIndex].properties[0].propertyType.split(':')[1]
            }/formats`
          },
          tenantReducer,
          lang: i18n.lang
        })
      ).data;

      setFormatType(result);
    }
  };

  const setSettings = (
    value: FileFormatSettingsType[keyof FileFormatSettingsType],
    dataKey: keyof FileFormatSettingsType
  ) => {
    const clonedArray = clone(values) as Record<string, any>;
    clonedArray[selectedIndex].settings[dataKey] = value;
    setValue({
      values: clonedArray as UpdateFileFormatType[]
    });
  };

  const formatsDropDown = () => {
    const dropdownValues = formatType.map((ft) => {
      return {
        value: ft.classType,
        text: ft.displayName ? ft.displayName : getFormatTranslation(enumReducer, ft.classType)
      };
    });

    return checkDropdownValues(dropdownValues, i18n.getTranslation('file_format.setting_first'));
  };

  const setFormats = (
    value: FileFormatSettingsFormatType[keyof FileFormatSettingsFormatType],
    dataKey: keyof FileFormatSettingsFormatType
  ) => {
    const clonedArray = clone(values) as Record<string, any>;
    clonedArray[selectedIndex].settings.format[dataKey] = value;
    setValue({
      values: clonedArray as UpdateFileFormatType[]
    });
  };

  const fileFormatRows = values.map((fileFormat, index) => {
    return {
      name: (
        <InlineInputFieldInput
          id={`export_fileformat.name.index-${index}`}
          value={fileFormat.name}
          onChange={(val) => setFileFormats(index, val, 'name')}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!fileFormat.name}
          error={!fileFormat.name}
        />
      ),
      description: (
        <InlineInputFieldInput
          id={`export_fileformat.description.index-${index}`}
          value={fileFormat.description}
          onChange={(val) => setFileFormats(index, val, 'description')}
        />
      ),
      settings:
        fileFormat.settings.format.type && fileFormat.settings.type ? (
          <Paragraph>
            {enumReducer.getTranslation('exportSettingsCategoryType', fileFormat.settings.type.toLowerCase())}
          </Paragraph>
        ) : (
          <InlineInputFieldDropdown
            id={`export_fileformat.settings.index-${index}`}
            values={settingsDropdown}
            selectedValues={[fileFormat.settings.type]}
            onChange={(val) => {
              getFormatType(val[0]);
              setSettings(val[0] as exportSettingsCategoryType, 'type');
            }}
            onClear={() => setFormatType([])}
            error={!fileFormat.settings.type}
          />
        ),
      format: fileFormat.settings.format.type ? (
        <Paragraph>{getFormatTranslation(enumReducer, fileFormat.settings.format.type)}</Paragraph>
      ) : (
        <InlineInputFieldDropdown
          id={`export_fileformat.formats.index-${index}`}
          values={formatsDropDown()}
          selectedValues={[fileFormat.settings.format.type]}
          onChange={(val) => {
            if (typeof val[0] === 'string') setFormats(val[0], 'type');
          }}
          error={!fileFormat.settings.format.type}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`export_fileformat.delete.index-${index}`}
          tooltipFor="file-formats-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  const dataType = formatType.find(
    (fType) =>
      values[selectedIndex] &&
      fType.classType.toLowerCase() === values[selectedIndex].settings.format.type.toLowerCase()
  );

  const parameters = (
    <ParameterGenerator
      key="generator"
      tooltipFor="file-formats-table"
      selection={selectedIndex}
      parameters={values[selectedIndex] ? clone(values[selectedIndex].settings.format) : undefined}
      genericType={dataType}
      setParameters={setFormats}
    />
  );

  const handleSelectRow = (index: number) => {
    setSelectedIndex(index);

    if (values[index] && values[index].settings.type) getFormatType(values[index].settings.type);
  };

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleFormatsSave} isDirty={isDirty} />
      <CardsContainer>
        <Card width="3" id="file-formats-card">
          <CardHeader
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.file_format.title')}
                content={[i18n.getTranslation('coachmark.file_format.paragraph')]}
              />
            }
            extraRight={
              <Button id="export_fileformat.add" type="text" icon="plus" onClick={addFileFormat}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('file_format.file_formats')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="file-formats-table"
              rows={fileFormatRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              sort={sort}
              setSelectedRow={handleSelectRow}
            />
          </CardBody>
        </Card>
        {parameters}
      </CardsContainer>
    </>
  );
}
