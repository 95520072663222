import React from 'react';

import { contractStatus } from '@zf/api-types/enums';
import { ContractType } from '@zf/api-types/master-data/contract';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatMoney } from '@zf/utils/src/number';

import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import AdvanceAmountButton from './AdvanceAmountButton';

type Props = {
  advanceAmount: number;
  contract: ContractType;
  contractStatus_: contractStatus;
};

const AdvanceAmountCardItem = (props: Props) => {
  const { advanceAmount, contract, contractStatus_ } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const formattedAmount = formatMoney(advanceAmount, culture);

  return (
    <>
      <CardItem width="6">
        <Label>{getTranslation('contracts.advance_amount')}</Label>
      </CardItem>
      <CardItem width="6" justifyContent="end">
        {contractStatus_ !== contractStatus.terminated && contractStatus_ !== contractStatus.cancelled ? (
          <AdvanceAmountButton formattedAmount={formattedAmount} contract={contract} />
        ) : (
          <Paragraph textAlign="right">{formattedAmount}</Paragraph>
        )}
      </CardItem>
    </>
  );
};

export default AdvanceAmountCardItem;
