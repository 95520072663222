import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from '../../../../hooks/useStore';
import BillingTariffs from '../../shared/tariff-column/BillingTariffs';

const ProductTariffs = () => {
  const { productConfigStore } = useStore();
  const { isPanelViewMode, productsStore } = productConfigStore;
  const {
    tariffs_,
    selectedBillingItem,
    selectedProduct,
    initTariffs,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    deleteTariff,
    deleteTariffInlist
  } = productsStore;

  return (
    <>
      {!isPanelViewMode && (
        <BillingTariffs
          isProductPage
          tariffs_={tariffs_}
          selectedBillingItem={selectedBillingItem}
          selectedProduct={selectedProduct}
          initTariffs={initTariffs}
          addTariff={addTariff}
          addTariffToList={addTariffToList}
          updateTariff={updateTariff}
          updateTariffInList={updateTariffInList}
          deleteTariff={deleteTariff}
          deleteTariffInlist={deleteTariffInlist}
        />
      )}
    </>
  );
};

export default observer(ProductTariffs);
