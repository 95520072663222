import { OrganisationOverviewCountType } from '@zf/api-types/analytics/analytics';
import { BillingParametersType } from '@zf/api-types/parameters';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { METHODS, createHeader } from '../../../utils/request';
import { UpdatedOrganisationType } from '@zf/api-types/auth';
import { AUTH_BASE_URL } from 'constants/authentication';

export default class OrganisationService {
  private applicationStore: ApplicationStore;

  constructor(applicationStore: ApplicationStore) {
    this.applicationStore = applicationStore;
  }

  getBillingParameters = async (organisationId: string, tenantId: string) => {
    return (
      await this.applicationStore.sendRequest<BillingParametersType>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/Parameters/billing'
        },
        customHeaders: {
          'zf-ouuid': organisationId,
          'zf-tuuid': tenantId
        }
      })
    ).data;
  };

  getOrganisationInsights = async (organisationId: string, tenantId: string, mutationDateTime: string) => {
    return (
      await this.applicationStore.sendRequest<OrganisationOverviewCountType>({
        request: {
          method: METHODS.GET,
          endpoint: '/insights/organisations',
          query: {
            mutationDateTime
          }
        },
        customHeaders: {
          'zf-ouuid': organisationId,
          'zf-tuuid': tenantId
        }
      })
    ).data;
  };

  getOrganisationConfig = async (organisationId: string, tenantId: string) => {
    return (
      await this.applicationStore.sendRequest<OrganizationConfigType>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/Organizations/${organisationId}`
        },
        customHeaders: {
          'zf-ouuid': organisationId,
          'zf-tuuid': tenantId
        }
      })
    ).data;
  };

  deleteOrganisation = async (organisationId: string, tenantId: string) => {
    return (
      await this.applicationStore.sendRequest<UpdatedOrganisationType>({
        request: {
          method: METHODS.DELETE,
          endpoint: `${AUTH_BASE_URL}/api/Organization/${organisationId}`
        },
        customHeaders: createHeader({
          tenant: tenantId,
          organization: organisationId
        })
      })
    ).data;
  };
}
