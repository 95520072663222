import React from 'react';

import { UpdateCustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';

import useConfig from '../../../app-context/hooks/use-config';
import CustomEntityProperties from './custom-entity-properties';

export default function CustomEntityPropertiesTab() {
  const config = useConfig<UpdateCustomEntityPropertyType[]>(
    'customEntityPropertyTypes',
    '/cfg/CustomEntityPropertyTypes'
  );

  return <CustomEntityProperties config={config} />;
}
