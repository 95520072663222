import React from 'react';

import { navigate } from '@reach/router';
import { ContractType, DeleteContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import DeleteContractDialog from '../../dialogs/delete-dialog';

type Props = {
  contract: ContractType;
};

export default function DeleteContractListItem(props: Props) {
  const { contract } = props;
  const { clickRef, onSubmit } = useDialog();
  const { contractStore, applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const contractToHandle: DeleteContractType = {
    etag: contract._etag,
    id: contract.id
  };

  const onComplete = () => {
    navigate(`${rootUrl}/contracts`);
  };

  if (contractStore.pageActionPermissions.mayDeleteContract) {
    return (
      <DropdownAction
        id="contract.delete"
        icon="trashcan"
        dialogTitle={getTranslation('contracts.delete')}
        dialogContent={
          <DeleteContractDialog onComplete={onComplete} ref={clickRef} contractToHandle={contractToHandle} />
        }
        onSubmit={onSubmit}
        buttonPositive={getTranslation('general.delete')}
        dialogType="danger"
      />
    );
  }

  return null;
}
