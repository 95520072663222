import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle, useReducer } from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import TreeNode from '../models/TreeNode';
import EditTariffPeriod from './components/EditTariffPeriod';

export type EditPeriodType = {
  startDateTime: string;
  endDateTime: string;
};

type Props = {
  treeNode: TreeNode;
};

const EditTariffPeriodDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { periodStart, periodEnd, setTariffPeriodDates } = props.treeNode;
  const stateReducer = createStateReducer<EditPeriodType, Partial<EditPeriodType>>();
  const [state, setState] = useReducer(stateReducer, {
    startDateTime: periodStart,
    endDateTime: periodEnd
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      setTariffPeriodDates({ startDateTime: state.startDateTime, endDateTime: state.endDateTime });
    }
  }));

  return (
    <EditTariffPeriod
      values={{ startDateTime: state.startDateTime, endDateTime: state.endDateTime }}
      setValue={({ startDateTime, endDateTime }) => setState({ startDateTime, endDateTime })}
    />
  );
});

export default observer(EditTariffPeriodDialog);
