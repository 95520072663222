import useSingleAPI, { useRequestParams } from './useSingleAPI';

export type ReturnType<T> = {
  result: T | null;
  error: null;
};

export default function useSuspenseSingleAPI<T>(
  params: useRequestParams,
  hasOneDataLevel?: boolean,
  useCaching = true
) {
  const response = useSingleAPI<T>(params, hasOneDataLevel, useCaching);

  if (response.error) throw response.error;

  if (response.result instanceof Promise) {
    throw response.result;
  }

  return {
    result: response.result,
    error: response.error
  };
}
