import React from 'react';
import classNames from 'classnames';

import css from './drag-and-drop.module.scss';

type Props = {
  className?: string;
  children: (childProps: { isDragging: boolean }) => React.ReactNode;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
};

export default function Draggeable(props: Props) {
  const { className, onDragStart, onDragEnd, children } = props;

  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(true);
    onDragStart(e);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
    onDragEnd(e);
  };

  return (
    <div
      id="draggeable"
      className={classNames(
        css['draggeable'],
        {
          [css['draggeable-dragged']]: isDragging
        },
        className
      )}
      draggable
      role="button"
      aria-label="Select and drag item"
      tabIndex={0}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children({
        isDragging
      })}
    </div>
  );
}
