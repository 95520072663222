import clone from 'clone';
import { Dispatch } from 'react';

import { ActionTypes, ApplicationState, CurrentState, DomainType, initialCurrent, useTracked } from '../app-state';

const getMyCurrent = (current: Map<DomainType, CurrentState>, domain: DomainType): CurrentState => {
  let myCurrent: CurrentState | undefined;

  if (current.has(domain)) {
    myCurrent = current.get(domain);
    if (!myCurrent) myCurrent = initialCurrent;
  } else {
    myCurrent = initialCurrent;
  }

  return myCurrent;
};

const useCurrent = (domain: DomainType) => {
  const [state, dispatch]: [ApplicationState, Dispatch<ActionTypes>] = useTracked();

  const setMyCurrent = (myCurrent: CurrentState) => {
    const current = clone(state.current);
    current.set(domain, myCurrent);

    dispatch({ type: 'update', update: { current } });
  };

  const resetCurrent = () => {
    dispatch({ type: 'reset', domain });
  };

  return { myCurrent: getMyCurrent(state.current, domain), setMyCurrent, resetCurrent };
};

export default useCurrent;
