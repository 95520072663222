import { observer } from 'mobx-react';
import React from 'react';

import { IncomingMutationType, IncomingUnknownDetails } from '@zf/api-types/billing/incoming-mutations';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { DetailLine, InfoGrid } from '../../../../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../../hooks/useStore';

type Props = {
  incomingMutation: IncomingMutationType;
};

const UnknownDetails = (props: Props) => {
  const { incomingMutation } = props;

  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const castedDetails = incomingMutation.details as IncomingUnknownDetails;

  return (
    <InfoGrid type="grey">
      <DetailLine label={getTranslation('incoming_mutations.incoming_matching_account_holder')}>
        <Paragraph>{castedDetails.accountHolder}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('banking_transaction.bank_account')}>
        <Paragraph>{castedDetails.iban}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('banking_transaction.mutationDate')}>
        <Paragraph>{formatDate(incomingMutation.transactionDate)}</Paragraph>
      </DetailLine>

      <DetailLine label={getTranslation('general.amount_valuta')}>
        <Paragraph>{formatMoney(incomingMutation.amount, culture)}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('incoming_mutations.familyCode')}>
        <Paragraph>{castedDetails.familyCode}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('incoming_mutations.subFamilyCode')}>
        <Paragraph>{castedDetails.subFamilyCode}</Paragraph>
      </DetailLine>

      <DetailLine label={getTranslation('incoming_mutations.domain')}>
        <Paragraph>{castedDetails.domain}</Paragraph>
      </DetailLine>
      <DetailLine label={getTranslation('general.description')}>
        <Paragraph>{castedDetails.description}</Paragraph>
      </DetailLine>
    </InfoGrid>
  );
};

export default observer(UnknownDetails);
