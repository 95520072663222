import React, { createContext, FC, ReactNode, useContext } from 'react';

import { MeteringIssue } from '@zf/api-types/metering/metering-issues';

import { createListPageStore } from '../../../issues/Context/createListPageStore';
import { ListPageContext, ListPageStore } from '../../../issues/Context/types';
import { useListPageProvider } from '../../../issues/Context/useListPageProvider';

const store = createListPageStore({}, 'unresolved', {
  all: 0,
  unresolved: 0
});

export const MeterIssuesContext = createContext<ListPageContext>({
  store,
  getSidePanelData: () => [],
  setFilter: (filters: ListPageStore['filter']) => {},
  setListPage: (list: ListPageStore['listPage']) => {},
  updateRows: (updatedRowEntities: MeteringIssue[]) => {},
  goToPreviousRow: (currentRowId: string) => {},
  goToNextRow: (currentRowId: string) => {},
  closeSidePanel: () => {},
  setListPageStore: (value: React.SetStateAction<ListPageStore>) => {}
});

export const useMeterIssuesContext = () => useContext(MeterIssuesContext);

export const MeterIssuesProvider: FC<ReactNode> = ({ children }) => {
  const listPageState = useListPageProvider(store);

  return <MeterIssuesContext.Provider value={listPageState}>{children}</MeterIssuesContext.Provider>;
};
