import classNames from 'classnames';
import React from 'react';

import { ActionButton } from '@zf/heat-erp/src/design-system/Components';

import { Icon } from '../Icon';
import css from './detail-page-header.module.scss';

type Props = {
  title: string;
  actionsText?: string;
  status?: JSX.Element;
  iconName?: string;
  subTitle?: React.ReactNode;
  subTitle2?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
};

export default function DetailPageHeader(props: Props) {
  const { title, status, actions, iconName, subTitle, subTitle2, actionsText = 'Actions', className } = props;

  return (
    <div className={classNames(css[subTitle ? 'header-w-subtitle' : 'header'], className)}>
      <div className={css['main-header']}>
        {iconName && (
          <div className={css['title-icon']}>
            <Icon name={iconName} />
          </div>
        )}

        <h1>{title}</h1>

        {status && <div className={css['status']}>{status}</div>}

        {actions && (
          <div className={css['actions']}>
            <ActionButton icon="actions" actions={actions}>
              {actionsText}
            </ActionButton>
          </div>
        )}
      </div>

      <div className={css['sub-header']}>{subTitle}</div>
      <div className={css['sub-header']}>{subTitle2}</div>
    </div>
  );
}
