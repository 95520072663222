import { useStore } from 'hooks/useStore';
import useUser from 'hooks/useUser';
import Interweave from 'interweave';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AdvanceCalculationFallbackReason, AdvanceRecalculationType } from '@zf/api-types/enums';
import { AdvanceCalculationDetails } from '@zf/api-types/invoice';

export type Props = {
  advanceCalculationDetails: AdvanceCalculationDetails;
};

const NewAdvanceAmountTip = (props: Props) => {
  const { advanceCalculationDetails } = props;

  const { applicationStore } = useStore();
  const { userStore, getTranslation } = applicationStore;

  const { recalculationType, fallbackReason, changedByUserId } = advanceCalculationDetails;

  const user = useUser(changedByUserId, userStore);

  const didFallback = fallbackReason !== AdvanceCalculationFallbackReason.na && fallbackReason !== null;

  const calcTypeMap: Record<AdvanceRecalculationType, string> = {
    [AdvanceRecalculationType.none]: getTranslation('invoice.estimation.tip_none'),
    [AdvanceRecalculationType.usingpreviousinvoice]: getTranslation('invoice.estimation.tip_usingpreviousinvoice'),
    [AdvanceRecalculationType.usingestimatedinvoice]: getTranslation('invoice.estimation.tip_usingestimatedinvoice')
  };

  const fallBackReasonMap: Record<AdvanceCalculationFallbackReason, string> = {
    [AdvanceCalculationFallbackReason.na]: '', // Not used
    [AdvanceCalculationFallbackReason.deviationtoolow]: getTranslation('invoice.estimation.tip_deviationtoolow'),
    [AdvanceCalculationFallbackReason.accuracytoolow]: getTranslation('invoice.estimation.tip_accuracytoolow'),
    [AdvanceCalculationFallbackReason.estimationfailed]: getTranslation('invoice.estimation.tip_estimationfailed'),
    [AdvanceCalculationFallbackReason.estimationfailedanddeviationtoolow]: getTranslation(
      'invoice.estimation.tip_estimationfailedanddeviationtoolow'
    ),
    [AdvanceCalculationFallbackReason.manuallyoverridden]: getTranslation('invoice.estimation.tip_manuallyoverridden', {
      user
    })
  };

  return <Interweave content={didFallback ? fallBackReasonMap[fallbackReason] : calcTypeMap[recalculationType]} />;
};

export default observer(NewAdvanceAmountTip);
