import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { ErrorBoundary } from '../../../../../../components/Error';
import TotalConsumptionChartCard from './total-consumption-chart-card';
import YearlyConsumptionCharts from './yearly-consumption-charts';
import css from './consumptions.module.scss';

type Props = {
  propertyGroup: PropertyGroupType;
};

export default function Consumptions(props: Props) {
  return (
    <CardsContainer className={css['consumptions-card-container']}>
      <ErrorBoundary cardError>
        <TotalConsumptionChartCard {...props} />
      </ErrorBoundary>

      <YearlyConsumptionCharts {...props} />
    </CardsContainer>
  );
}
