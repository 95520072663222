import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import {
  exportOutgoingBankingTransactionPaymentRefundFormatType,
  outgoingBankingTransactionType
} from '@zf/api-types/enums';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import useDownload from '../../app-context/hooks/use-download';
import { EnumKeyType } from '../../app-context/hooks/use-enum';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  validationRef: MutableRefObject<ValidationRef | undefined>;
  refresh: () => void;
};

const DownloadOutgoingBankingTransactionDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { applicationStore, outgoingBankingTransactionsStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { downloadOutgoingBankingTransaction } = outgoingBankingTransactionsStore.outgoingBankingTransactionsService;
  const { outgoingBankingTransaction, validationRef, refresh } = props;

  const { processDownloadedData } = useDownload();

  const [formatType, setFormatType] = useState<exportOutgoingBankingTransactionPaymentRefundFormatType>(
    '' as exportOutgoingBankingTransactionPaymentRefundFormatType
  );

  const fileFormatsResponse = useSuspenseSingleAPI<exportOutgoingBankingTransactionPaymentRefundFormatType[]>({
    request: {
      endpoint: `/bill/integration/export/outgoingbankingtransaction/${
        outgoingBankingTransaction.type === outgoingBankingTransactionType.paymentrequests
          ? 'paymentrequest'
          : 'paymentrefund'
      }/enabledFormats`
    }
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const res = await downloadOutgoingBankingTransaction(outgoingBankingTransaction, formatType);
        processDownloadedData(res);
        refresh();
      } catch (e) {
        notify.error({
          content: getTranslation('general.download_fail'),
          error: e
        });
      }
    }
  }));

  const validate = () => {
    if (validationRef && validationRef.current) {
      validationRef.current.setIsError(!formatType);
    }
  };

  useEffect(() => {
    validate();
  }, [formatType]);

  if (!fileFormatsResponse.result) return null;

  const dropdownFormats = fileFormatsResponse.result.data.map((f) => {
    const enumKey = `exportOutgoingBankingTransactionPayment${
      outgoingBankingTransaction.type === outgoingBankingTransactionType.paymentrequests ? 'Request' : 'Refund'
    }FormatType`;

    return {
      value: f,
      text: getEnumTranslation(enumKey as EnumKeyType, f)
    };
  });

  return (
    <>
      <Heading headingType="h3" style="bold">
        {getTranslation('actions.banking_transaction.format_type_heading')}
      </Heading>
      <InputContainer>
        <SimpleDropdown
          id="format_type"
          onChange={(val) => setFormatType(val[0])}
          values={dropdownFormats}
          selectedValues={[formatType]}
          error={!formatType}
        />
      </InputContainer>
    </>
  );
});

export default observer(DownloadOutgoingBankingTransactionDialog);
