import { observer } from 'mobx-react';
import React from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import CommitSection from '../../../components/config/commit-section';
import { useStore } from '../../../hooks/useStore';
import CommunicationPreferencesCard from './communication-preferences/CommunicationPreferencesCard';
import EmailConfigurationCard from './email-configuration/EmailConfigurationCard';
import LanguageCard from './languages/LanguageCard';

const SettingsTab = () => {
  const { communicationStore } = useStore();
  const { communicationParametersForm, languagesAreDirty, handleCancelSettings, handleSubmitSettings } =
    communicationStore.settingsStore;
  const { _isDirty } = communicationParametersForm;

  return (
    <>
      <CommitSection
        handleCancel={handleCancelSettings}
        handleSave={handleSubmitSettings}
        isDirty={_isDirty || languagesAreDirty}
      />
      <CardsContainer>
        <LanguageCard />
        <CommunicationPreferencesCard />
        <EmailConfigurationCard />
      </CardsContainer>
    </>
  );
};

export default observer(SettingsTab);
