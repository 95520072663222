import { removeLocationsWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-out/RemoveLocationsWizardStore';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import BillingSection from '../../shared/BillingSection/BillingSection';

/**
 * Adapter component
 */
const BillingSectionAdapter = () => {
  const { contractStore } = useStore();
  const { selectedIds, removeLocationsWizardValues, removeLocationsWizardNavigator, showBillingSection } =
    contractStore.moveOutWizardBaseStore.removeLocationsWizardStore;
  const { values, setValue } = removeLocationsWizardValues;
  const { advanceAmount } = values;
  const { setActiveStep } = removeLocationsWizardNavigator;

  return (
    <BillingSection
      showBillingSection={showBillingSection()}
      showInfo={selectedIds.length !== 0}
      advanceAmount={advanceAmount}
      setAdvanceAmount={(advanceAmount: number) => setValue({ advanceAmount })}
      setActiveStep={() => setActiveStep(removeLocationsWizardSteps.billing)}
    />
  );
};

export default observer(BillingSectionAdapter);
