import { observer } from 'mobx-react';
import React from 'react';

import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { colors } from '@zf/utils/src/color';

import useRefresh from '../../../app-context/hooks/use-refresh';
import DataExportImportAutoFill, {
  DataExportImportAutoFillProps
} from '../../../components/Autofills/DataExportImportAutoFill';
import ImportJobsExport from '../../../components/Button/Excel/ImportJobsExport';
import DeleteAllImportJobsAction from '../../../components/Button/ImportJobs/DeleteAllImportJobsListPageButton';
import DeleteImportJobAction from '../../../components/Button/ImportJobs/DeleteImportJobsListPageButton';
import DownloadImportJobAction from '../../../components/Button/ImportJobs/DownloadImportJobsListPageButton';
import TriggerImportJobAction from '../../../components/Button/ImportJobs/TriggerImportJobsListPageButton';
import UploadImportJobAction from '../../../components/Button/ImportJobs/UploadImportJobsListPageButton';
import ValidateImportJobAction from '../../../components/Button/ImportJobs/ValidateImportJobsListPageButton';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import { useStore } from '../../../hooks/useStore';
import useImportJobFilter from '../hooks/useImportJobFilter';
import ProcessAllAction from '../../../components/Button/ImportJobs/ProcessAllImportJobsListPageButton';

type Props = {
  search?: string;
};

const InlineDataImportAutofill = InlineInputField<DataExportImportAutoFillProps>(DataExportImportAutoFill);

const ImportJobsFilterBar = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    overviewCount,
    quickFilter,
    searchValue,
    filterTags,
    queryParams,
    refreshCounts,
    onSearch,
    onFilter,
    setQueryParams,
    clearQueryParams
  } = useImportJobFilter(props.search);
  const { refresh } = useRefresh();

  return (
    <FilterBar
      removeAllFilters={clearQueryParams}
      subject={getTranslation('nav.import-jobs')}
      refreshPage={refresh}
      tags={filterTags}
      actions={[
        <ProcessAllAction key="processAllImportJob" refreshCounts={refreshCounts} />,
        <TriggerImportJobAction key="triggerImportJob" refreshCounts={refreshCounts} />,
        <ValidateImportJobAction key="validateImportJob" onComplete={refresh} />,
        <UploadImportJobAction key="uploadImportJobs" refreshCounts={refreshCounts} />,
        <DeleteImportJobAction key="deleteImportJob" refreshCounts={refreshCounts} />,
        <DownloadImportJobAction key="downloadAction" />,
        <DeleteAllImportJobsAction key="deleteAllImportJobs" onComplete={refresh} />,
        <ImportJobsExport key="importJobsExport" />
      ]}
      filterButtons={[
        <FilterButton
          key="all"
          id="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="import-job" />}
          count={overviewCount.all}
          title={getTranslation('import_jobs.all_import_jobs')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          key="stored"
          id="stored"
          onTrigger={() => onFilter('stored')}
          icon={<Icon type="import-job" />}
          count={overviewCount.stored}
          title={getTranslation('import_jobs.stored')}
          activeColor={colors['blue-700']}
          active={quickFilter === 'stored'}
        />,
        <FilterButton
          key="completed"
          id="completed"
          onTrigger={() => onFilter('completed')}
          icon={<Icon type="import-job" />}
          count={overviewCount.completed}
          title={getTranslation('import_jobs.completed_import_jobs')}
          activeColor={colors['green-600']}
          active={quickFilter === 'completed'}
        />,
        <FilterButton
          key="ongoing"
          id="ongoing"
          onTrigger={() => onFilter('ongoing')}
          icon={<Icon type="import-job" />}
          count={overviewCount.ongoing}
          title={getTranslation('import_jobs.ongoing_import_jobs')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'ongoing'}
        />
      ]}
      filterInputs={[
        <Label key="label-search-data-import">{getTranslation('import_jobs.search_data_import')}</Label>,
        <InlineDataImportAutofill
          id="search-data-import"
          key="search-data-import"
          onChange={(value) =>
            setQueryParams({
              dataImportId: value[0]?.id || '',
              dataImportName: value[0]?.name || ''
            })
          }
          selectedValues={[queryParams?.dataImportId || '']}
          initialValue={queryParams?.dataImportName || ''}
          endpoint={'/cfg/DataImports'}
          renderTopLvl={false}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('import_jobs.search_placeholder')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
};

export default observer(ImportJobsFilterBar);
