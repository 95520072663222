import React from 'react';

import { IncomingInvoiceComponentDetailsType } from '@zf/api-types/property-group-billing-period';
import useDialog from '@zf/hooks/src/useDialog';
import { groupBy } from '@zf/utils/src/array';

import AddIncomingInvoiceDialog from '../../../../../../actions/property-group/add-edit-incoming-invoice-dialog';
import { useAppContext } from '../../../../../../app-context';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import { dialog } from '../../../../../../events/dialog-events';
import useBillingData from '../../hooks/use-billing-data';
import IncomingCost from './incoming-cost';

export default function IncomingCosts() {
  const { i18n, authReducer } = useAppContext();
  const { hasPermission } = authReducer;

  const {
    selectedBillingPeriod,
    incomingInvoiceComponents,
    billingPeriods,
    propertyGroupBillingConfiguration,
    addIncomingInvoiceToState,
    updateBillingPeriodsInState,
    setSelectedBillingPeriod
  } = useBillingData();

  const incomingCosts = selectedBillingPeriod ? selectedBillingPeriod.incomingInvoiceComponents : [];

  const groupedByIncomingInvoiceComponentId: Record<string, IncomingInvoiceComponentDetailsType[]> = groupBy(
    incomingCosts,
    'incomingInvoiceComponentId'
  );

  const { clickRef, validationRef, onSubmit } = useDialog();

  if (!propertyGroupBillingConfiguration) return null;

  const componentsOnThisPropertyGroup = incomingInvoiceComponents.filter((iic) =>
    propertyGroupBillingConfiguration.requiredIncomingInvoiceComponents?.some((riic) => riic.id === iic.id)
  );

  const openAddDialog = () => {
    dialog.right({
      title: i18n.getTranslation('property_groups.tabs.billing.add_incoming_invoice'),
      icon: 'plus',
      content: (
        <AddIncomingInvoiceDialog
          selectedBillingPeriod={selectedBillingPeriod}
          propertyGroupId={
            propertyGroupBillingConfiguration && propertyGroupBillingConfiguration.propertyGroup
              ? propertyGroupBillingConfiguration.propertyGroup.id
              : ''
          }
          billingPeriods={billingPeriods}
          components={componentsOnThisPropertyGroup}
          ref={clickRef}
          validationRef={validationRef}
          addIncomingInvoiceToState={addIncomingInvoiceToState}
          updateBillingPeriodsInState={updateBillingPeriodsInState}
          setSelectedBillingPeriod={setSelectedBillingPeriod}
        />
      ),
      buttonPositive: i18n.getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      title={i18n.getTranslation('property_groups.tabs.billing.incoming_costs')}
      add={hasPermission('incoming_invoice.add') ? openAddDialog : undefined}
      disabled={selectedBillingPeriod && selectedBillingPeriod.locked}
      borderStyle="dashed"
    >
      {Object.keys(groupedByIncomingInvoiceComponentId).length > 0 ? (
        Object.keys(groupedByIncomingInvoiceComponentId).map((key, index1) => {
          const incomingInvoiceComponentDetailsList = groupedByIncomingInvoiceComponentId[key];
          return incomingInvoiceComponentDetailsList.map((iicd, index2) => {
            return (
              <IncomingCost
                key={`${iicd.incomingInvoiceComponentId}-${index1}-${index2}`}
                index1={index1}
                index2={index2}
                incomingInvoiceComponentDetails={iicd}
              />
            );
          });
        })
      ) : (
        <EmptyColumn
          image="payment"
          title={i18n.getTranslation('property_groups.tabs.billing.no_incoming_costs')}
          text={i18n.getTranslation(
            `property_groups.tabs.billing.${
              selectedBillingPeriod ? 'no_incoming_costs_yet_descr' : 'no_incoming_costs_descr'
            }`
          )}
        />
      )}
    </ConfigColumn>
  );
}
