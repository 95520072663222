import classNames from 'classnames';
import React from 'react';

import { FileState, LocalTemplateAttachmentType } from '@zf/api-types/config/scenarios_new';

import { useAppContext } from '../../app-context/app-context';
import useDownload from '../../app-context/hooks/use-download';
import Dropzone from '../Lang/Dropzone';
import FileList from './FileList';
import ScenarioFile from './ScenarioFile';
import css from './upload-input.module.scss';

type PropsScenario = {
  id: string;
  canDelete?: boolean;
  viewAsColumns?: boolean;
  files: LocalTemplateAttachmentType[];
  setFiles: (files: LocalTemplateAttachmentType[]) => void;
};

export function ScenarioUploadInput(props: PropsScenario) {
  const { id, files, viewAsColumns = false, canDelete = true, setFiles } = props;

  const { tenantReducer } = useAppContext();
  const { downloadSingleFile } = useDownload();

  const handleSelectFile = (selectedFiles: File[]) => {
    const mappedSelectedFiles: LocalTemplateAttachmentType[] = selectedFiles.map((file) => {
      return {
        file,
        state: FileState.none,
        internalFilePath: '',
        fileName: file.name
      };
    });

    setFiles([...files, ...mappedSelectedFiles]);
  };

  const removeFile = (index: number) => {
    const cloneArray = [...files];
    cloneArray.splice(index, 1);
    setFiles(cloneArray);
  };

  const handleDropzoneTrigger = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;

    inputElement.addEventListener('change', function () {
      if (!this.files) return;
      const toHandle: File[] = [...this.files];
      handleSelectFile(toHandle);
    });
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <>
      <div className={viewAsColumns ? css['wrapper'] : css['scenario-upload-input']}>
        <div>
          <Dropzone
            id={id}
            className={css['upload-dropzone']}
            onTrigger={handleDropzoneTrigger}
            onDrop={handleSelectFile}
          />
        </div>
        <div className={classNames(css['file-list'], viewAsColumns ? css['file-list-column'] : undefined)}>
          <FileList>
            {files.map((file, i: number) => {
              const fileName = file.file ? file.file.name : file.fileName;
              return (
                <ScenarioFile
                  key={i}
                  file={file}
                  onCancel={() => removeFile(i)}
                  download={() => (file.id ? downloadSingleFile(`/att/attachments/${file.id}`, tenantReducer) : {})}
                  canDelete={canDelete}
                >
                  {fileName}
                </ScenarioFile>
              );
            })}
          </FileList>
        </div>
      </div>
    </>
  );
}
