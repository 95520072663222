import { useStore } from 'hooks/useStore';
import moment, { Moment } from 'moment';
import React from 'react';

import { navigate } from '@reach/router';
import { PagedResponseType } from '@zf/api-types/api';
import {
  contractStatus,
  invoiceLineType,
  invoiceStatus,
  invoiceType,
  paymentMethod,
  sentStatus,
  unitOfMeasure
} from '@zf/api-types/enums';
import { CreateInvoiceRequestType, InvoiceLineTemplateType, InvoiceType } from '@zf/api-types/invoice';
import { ContractType } from '@zf/api-types/master-data/contract';
import { CustomerContractType, CustomerType } from '@zf/api-types/master-data/customer';
import { PropertyGroupAttributeType } from '@zf/api-types/master-data/property-group';
import { BillingItemType } from '@zf/api-types/product';
import { GetTaxCodeResponse } from '@zf/api-types/tax-codes';
import useValidator from '@zf/hooks/src/useValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { MultilineInputProps } from '@zf/stella-react/src/atoms/InputField/stella-multi-line-input';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';
import { MAX_DATE, MIN_DATE, startOfDay } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import useWizardAPIErrors from '../../../../app-context/hooks/useWizardAPIErrors';
import CustomerAutoFill from '../../../../components/Autofills/CustomerAutoFill';
import WizardSubmitButton from '../../../../components/Button/WizardSubmitButton';
import ContractsDropdown from '../../../../components/Dropdown/contract-dropdown/wizard/ContractsDropdown';
import DateRangePicker from '../../../../components/input/DateRangePicker';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import MultiLineInput from '../../../../components/input/MultiLineInput';
import Select from '../../../../components/input/Select';
import DatePicker, { DatePickerProps } from '../../../../components/Lang/DatePicker';
import { notify } from '../../../../events/notification-events';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import { extendMultiValue } from '../../../../utils/arrays';
import { METHODS, sendRequest } from '../../../../utils/request';
import BankAccountSection from './BankAccountSection';
import InvoiceLineMultiValue from './invoice-line-multivalue';
import css from './invoice-wizard.module.scss';
import PropertyGroupSection from './PropertyGroupSection';

export type Props = {
  feedback: string[][];
  isContractChecked: boolean;
  bankAccountRequired: boolean;
  customer?: CustomerType | null;
  onFocusStep: (step: string) => void;
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType | null>>;
  setIsContractChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

export type CreateInvoiceType = {
  invoiceNum: string;
  status: string;
  invoiceDate: Moment;
  invoiceType: invoiceType;
  remainingInvoiceAmount: number;
  contract: ContractType | null;
  customer: CustomerType | null;
  paymentMethod: paymentMethod | '';
  periodStartDate: Moment | null;
  periodEndDate: Moment | null;
  externalReference: string;
  description: string;
  companyBankAccountId: string | null;
  lines: InvoiceLineTemplateType[];
  contracts: CustomerContractType[];
  showLocationDropdown: boolean;
  propertyGroup: PropertyGroupAttributeType | null;
};

export const STEP_NAMES = [
  'select_customer',
  'invoice_contract',
  'property_group',
  'invoice_type',
  'period_description',
  'bank_account',
  'invoice_lines',
  'invoice-errors'
];

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);
const WizardDatePicker = WizardInputWrapper<DatePickerProps>(DatePicker);
const WizardMultiLineInput = WizardInputWrapper<MultilineInputProps>(MultiLineInput);

export const initialInvoiceLine: InvoiceLineTemplateType = {
  lineType: invoiceLineType.charge,
  quantity: 1,
  unitPrice: 0,
  billingItem: null,
  amountInclVAT: 0,
  amountExclVAT: 0,
  taxCode: null,
  taxRate: 0,
  startDateTime: null,
  endDateTime: null,
  description: '',
  serviceLocationId: '',
  unitOfMeasure: unitOfMeasure.none
};

export default function InvoiceWizard(props: Props) {
  const {
    feedback,
    customer,
    bankAccountRequired,
    isContractChecked,
    onFocusStep,
    setFeedback,
    setCustomer,
    setIsContractChecked
  } = props;

  const { propertyGroupStore } = useStore();
  const { getInvoiceBillingConfigurationSuggestions } = propertyGroupStore.propertyGroupBillingConfigurationService;

  const { i18n, enumReducer, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const [invoiceDate] = React.useState(startOfDay(moment()));
  const [contractSelectValues] = React.useState([
    {
      icon: 'contract',
      value: true,
      text: i18n.getTranslation('invoice.wizard.invoice_contract_y')
    },
    {
      icon: 'chevron-double-down',
      value: false,
      text: i18n.getTranslation('invoice.wizard.invoice_contract_n')
    }
  ]);

  // Get taxCodes
  const taxCodesResponse = useSuspenseSingleAPI<GetTaxCodeResponse>({
    request: {
      endpoint: '/cfg/taxcodes'
    }
  });

  // Get billingItems
  const billingItemsResponse = useSuspenseSingleAPI<PagedResponseType<BillingItemType>>({
    request: {
      endpoint: '/cfg/BillingItems'
    }
  });

  const { setApiErrors, handleApiErrors } = useWizardAPIErrors(7, setFeedback);

  const { values, errors, setValue, submitFactory } = useValidator<CreateInvoiceType>({
    initialValues: {
      invoiceNum: '',
      status: invoiceStatus.created,
      invoiceDate: invoiceDate,
      invoiceType: invoiceType.incidentalnote,
      remainingInvoiceAmount: 0,
      contract: null,
      customer: customer || null,
      paymentMethod: '',
      periodStartDate: null,
      periodEndDate: null,
      description: '',
      externalReference: '',
      companyBankAccountId: null,
      lines: [initialInvoiceLine],
      contracts: [],
      showLocationDropdown: true,
      propertyGroup: null
    },
    validate: () => {
      const feedback: string[][] = [];

      if (!values.customer) {
        extendMultiValue(feedback, 0, i18n.getTranslation('invoice.validation.customer'));
      }

      if (isContractChecked) {
        if (!values.contract) {
          extendMultiValue(feedback, 1, i18n.getTranslation('invoice.validation.contract'));
        }
      }

      if (values.customer && !isContractChecked && !values.propertyGroup) {
        extendMultiValue(feedback, 3, i18n.getTranslation('invoice.validation.property_group'));
      }

      if (!values.invoiceDate) {
        extendMultiValue(feedback, 4, i18n.getTranslation('invoice.validation.invoiceDate'));
      }

      if (bankAccountRequired && !values.companyBankAccountId) {
        extendMultiValue(feedback, 5, i18n.getTranslation('invoice.validation.bank_account'));
      }

      if (values.lines.length === 0) {
        extendMultiValue(feedback, 6, i18n.getTranslation('invoice.validation.lines'));
      }

      values.lines.forEach((line: InvoiceLineTemplateType) => {
        if (
          line.unitPrice === 0 ||
          Number.isNaN(line.unitPrice) ||
          !line.quantity ||
          Number.isNaN(line.quantity) ||
          line.amountInclVAT === 0 ||
          Number.isNaN(line.amountInclVAT) ||
          line.amountExclVAT === 0 ||
          Number.isNaN(line.amountExclVAT) ||
          !line.description ||
          !line.taxCode
        ) {
          extendMultiValue(feedback, 6, i18n.getTranslation('invoice.validation.lines_valid'));
        }
      });

      handleApiErrors(feedback, []);

      return { feedback };
    }
  });

  React.useEffect(() => {
    if (customer) {
      const signedContracts = customer.contracts.filter((contract) => {
        return contract.currentContractStatus === contractStatus.signed;
      });
      setValue({
        customer: customer,
        paymentMethod: customer.defaultPaymentMethod,
        contracts: signedContracts,
        companyBankAccountId: values.companyBankAccountId // for some reason we need to set companyBankAccountId or it will be reset to null
      });
    }
  }, [customer]);

  const setCompanyBankAccountId = React.useCallback(
    (val: string | null) => {
      setValue({ companyBankAccountId: val });
    },
    [values.companyBankAccountId, setValue]
  );

  const setPropertyGroup = (propertyGroup: PropertyGroupAttributeType, companyBankAccountId: string | null) =>
    setValue({ propertyGroup, companyBankAccountId });

  if (!taxCodesResponse.result || !billingItemsResponse.result) return null;

  const taxCodes = taxCodesResponse.result.data.results;
  const billingItems = billingItemsResponse.result.data.results;

  const handleFocus = (step: string) => {
    return () => onFocusStep(step);
  };

  const formatInvoiceType = (type: string) => {
    return enumReducer.getTranslation('invoiceType', type).toLowerCase();
  };

  const getCurrentPropertyGroups = () => {
    // Set property groups based on use case
    let propertyGroups: PropertyGroupAttributeType[] | null = values.propertyGroup ? [values.propertyGroup] : null;

    if (isContractChecked) {
      propertyGroups = values.contract?.propertyGroups || null;
    }

    return propertyGroups;
  };

  const deriveCompanyBankAccountId = async (contract: ContractType | null) => {
    let companyBankAccountId: string | null = null;

    if (contract) {
      const locationIds = contract.serviceLocations.map((l) => l.id);

      try {
        companyBankAccountId = (await getInvoiceBillingConfigurationSuggestions(locationIds, contract.id))
          .companyBankAccountId;
      } catch (error) {
        notify.error({
          content: i18n.getTranslation('invoice.wizard.derive_iban_fail'),
          error
        });
      }
    }

    return companyBankAccountId;
  };

  const handleSubmit = submitFactory(async () => {
    const apiFriendlyValues: CreateInvoiceRequestType = {
      invoiceNum: '',
      status: invoiceStatus.created,
      invoiceDate: values.invoiceDate.toISOString(),
      invoiceType: values.invoiceType,
      remainingInvoiceAmount: null,
      totalInvoiceAmountExclVAT: null,
      contractId: values.contract?.id || null,
      contractNumber: values.contract?.contractNumber || null,
      customerId: values.customer?.id || null,
      paymentMethod: values.paymentMethod as paymentMethod,
      periodStartDateTime: values.periodStartDate !== null ? values.periodStartDate.toISOString() : MIN_DATE,
      periodEndDateTime: values.periodEndDate !== null ? values.periodEndDate.toISOString() : MIN_DATE,
      description: values.description,
      externalReference: values.externalReference,
      companyBankAccountId: values.companyBankAccountId,
      propertyGroups: getCurrentPropertyGroups(),
      creditedInvoiceId: null,
      paymentReference: null,
      dueDate: null,
      sent: sentStatus.notsent,
      lines: values.lines.map((line) => {
        const calcParameters = line.billingItem
          ? (line.billingItem.calculationParameters as Record<string, any>)
          : undefined;

        return {
          lineType: invoiceLineType.charge,
          description: line.description,
          amountExclVAT: line.amountExclVAT,
          amountInclVAT: line.amountInclVAT,
          quantity: line.quantity,
          unitPrice: line.unitPrice,
          startDateTime: line.startDateTime ? line.startDateTime.toISOString() : MIN_DATE,
          endDateTime: line.endDateTime ? line.endDateTime.toISOString() : MAX_DATE,
          taxCodeId: line.taxCode ? line.taxCode.id : '',
          billingItemId: line.billingItem ? line.billingItem.id : '',
          serviceLocationId: line.serviceLocationId || '',
          unitOfMeasure:
            calcParameters && calcParameters.unitOfMeasure ? calcParameters.unitOfMeasure : unitOfMeasure.none
        };
      })
    };

    try {
      const response = (
        await sendRequest<InvoiceType>({
          request: {
            method: METHODS.POST,
            endpoint: '/bill/Invoices',
            data: apiFriendlyValues
          },
          tenantReducer,
          lang: i18n.lang
        })
      ).data;

      notify.success({
        content: `${i18n.getTranslation('invoice.summary.new')} ${formatInvoiceType(
          response.type
        )} ${i18n.getTranslation('invoice.summary.created')}`
      });

      navigate(`${rootUrl}/invoices/${response.id}`);
    } catch (e) {
      //@ts-ignore
      const apiErrors_ = e.data && e.data.errors ? e.data.errors : [];
      handleApiErrors([], apiErrors_);
      setApiErrors(apiErrors_);
    }
  });

  const invoiceTypes = enumReducer
    .getEnum<invoiceType>('invoiceType')
    .filter((iType) => iType.value === invoiceType.incidentalnote || iType.value === invoiceType.correctionnote);

  return (
    <>
      <WizardSection>
        <WizardHeader>
          <StepAnchor name={STEP_NAMES[0]} />
          {i18n.getTranslation('invoice.steps.select_customer')}
        </WizardHeader>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('invoice.wizard.select_customer')}
        </Heading>
        <CustomerAutoFill
          id="searchCustomer"
          className={css['customer-select']}
          initialValue={customer ? customer.shortDisplayName : ''}
          onChange={(val) => {
            setValue({ customer: val[0] || null });
            setCustomer(val[0] || null);
          }}
          selectedValues={[values.customer ? values.customer.id : '']}
          placeholder={i18n.getTranslation('contracts.wizard.search_customer')}
          onFocus={handleFocus(STEP_NAMES[0])}
          error={!values.customer}
        />
      </WizardSection>

      <WizardSection>
        <WizardHeader>
          <StepAnchor name={STEP_NAMES[1]} />
          {i18n.getTranslation('invoice.steps.invoice_contract')}
        </WizardHeader>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('invoice.wizard.invoice_contract')}
        </Heading>

        <InputContainer grid={false}>
          <Select
            id="through_contract"
            onChange={(val) => setIsContractChecked(val[0])}
            selectedValues={[isContractChecked]}
            values={contractSelectValues}
          />
        </InputContainer>

        {isContractChecked && (
          <ContractsDropdown
            id="contractId"
            customerId={values.customer?.id || ''}
            className={css['contract-dropdown']}
            onChange={async (value) => {
              const contract = value[0] || null;
              const companyBankAccountId = await deriveCompanyBankAccountId(contract);
              setValue({ contract, companyBankAccountId });
            }}
            onFocus={handleFocus(STEP_NAMES[0])}
            placeholder={i18n.getTranslation('invoice.wizard.select_contract')}
            conditionMessage={
              !customer || !values.customer ? i18n.getTranslation('invoice.validation.no_customer_selected') : ''
            }
            emptyText={
              customer && values.customer && values.contracts.length === 0
                ? i18n.getTranslation('invoice.validation.customer_no_contracts')
                : ''
            }
            values={[]}
            selectedValues={values.contract ? [values.contract.id] : []}
            error={!isContractChecked && !values.contract}
            clear
          />
        )}
      </WizardSection>

      {values.customer && !isContractChecked && (
        <PropertyGroupSection selectedPropertyGroup={values.propertyGroup} setPropertyGroup={setPropertyGroup} />
      )}

      <WizardSection>
        <WizardHeader>
          <StepAnchor name={STEP_NAMES[3]} />
          {i18n.getTranslation('invoice.steps.invoice_type')}
        </WizardHeader>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('invoice.wizard.type')}
        </Heading>
        <InputContainer grid={false}>
          <Select
            id="invoiceType"
            onChange={(val) => setValue({ invoiceType: val[0] })}
            selectedValues={[values.invoiceType]}
            values={invoiceTypes.map((iType) => {
              return {
                icon: 'invoice',
                value: iType.value,
                text: iType.text
              };
            })}
          />
        </InputContainer>
      </WizardSection>

      <WizardSection>
        <WizardHeader>
          <StepAnchor name={STEP_NAMES[4]} />
          {i18n.getTranslation('invoice.steps.period_description')}
        </WizardHeader>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('invoice.wizard.invoice_date_heading')}
        </Heading>
        <InputContainer>
          <WizardDatePicker
            id="invoiceDate"
            onChange={(value) => setValue({ invoiceDate: value })}
            value={values.invoiceDate}
            placeholder={i18n.getTranslation('invoice.wizard.invoice_date')}
            onFocus={handleFocus(STEP_NAMES[3])}
            error={!values.invoiceDate}
          />
        </InputContainer>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('invoice.wizard.invoice_period')}
        </Heading>
        <InputContainer>
          <DateRangePicker
            id="invoice-period"
            startDate={values.periodStartDate}
            endDate={values.periodEndDate}
            setDates={(dates) => setValue({ periodStartDate: dates[0], periodEndDate: dates[1] })}
            onFocus={handleFocus(STEP_NAMES[4])}
            isWizardRangePicker
          />
        </InputContainer>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('invoice.wizard.external_ref')}
        </Heading>
        <InputContainer>
          <WizardInputField
            id="externalReference"
            onChange={(value) => setValue({ externalReference: value })}
            value={values.externalReference}
            placeholder={i18n.getTranslation('invoice.external_ref')}
            onFocus={handleFocus}
          />
        </InputContainer>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('general.description')}
        </Heading>
        <InputContainer grid={false}>
          <WizardMultiLineInput
            id="invoiceDescription"
            className={css['description']}
            placeholder={i18n.getTranslation('invoice.wizard.description_placeholder')}
            value={values.description}
            onChange={(value) => setValue({ description: value })}
            onFocus={handleFocus(STEP_NAMES[4])}
          />
        </InputContainer>
      </WizardSection>

      {bankAccountRequired && (
        <BankAccountSection
          companyBankAccountId={values.companyBankAccountId}
          setCompanyBankAccountId={setCompanyBankAccountId}
        />
      )}

      <WizardSection>
        <WizardHeader>
          <StepAnchor name={STEP_NAMES[6]} />
          {i18n.getTranslation('invoice.steps.invoice_lines')}
        </WizardHeader>
        <InvoiceLineMultiValue
          title={i18n.getTranslation('invoice.wizard.lines')}
          onChange={(lines) => setValue({ lines: lines })}
          onFocus={handleFocus(STEP_NAMES[6])}
          taxCodes={taxCodes}
          billingItems={billingItems}
          invoiceDate={values.invoiceDate}
          customer={values.customer}
          showLocationDropdown={values.showLocationDropdown}
        />
      </WizardSection>

      <WizardSubmitButton
        id="invoice-submit"
        onClick={handleSubmit}
        disabled={errors.feedback.length > 0 && feedback.length > 0}
      >
        {i18n.getTranslation('general.submit')}
      </WizardSubmitButton>
    </>
  );
}
