import React from 'react';

import { invoiceStatus } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import RegenerateDialog from '../dialogs/regenerate-dialog';

export default function RegenerateListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<InvoiceType>('invoice');
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    !!selectedRows.find((invoice) => {
      return (
        invoice.__entity.status !== invoiceStatus.generated &&
        invoice.__entity.status !== invoiceStatus.generationfailed
      );
    });

  return (
    <DropdownAction
      id="invoice.regenerate"
      icon="regenerate"
      dialogTitle={i18n.getTranslation('actions.regenerate')}
      dialogContent={<RegenerateDialog selectedRows={selectedRows} ref={clickRef} onComplete={setUpdatedRows} />}
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('actions.regenerate')}
    />
  );
}
