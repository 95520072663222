import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import FlexElements from '../Wrappers/FlexElements';
import css from './tab-button.module.scss';
import { TabItemType } from './types';

type Props = {
  type: 'detailpage' | 'listpage';
  tabItem: TabItemType;
  selectedTabId: string;
  selected: boolean;
  onSelectTab: (tabId: string) => void;
};

export default function TabButton(props: Props) {
  const { type, tabItem, selectedTabId, selected, onSelectTab } = props;

  const handleTabSelect = () => onSelectTab(tabItem.id);

  return (
    <BaseButton
      id={`${tabItem.id}-tab`}
      className={classNames(css[type], {
        [css['is-selected']]: tabItem.id === selectedTabId || (tabItem.isDefault && !selected)
      })}
      onClick={handleTabSelect}
    >
      <div className={css['title']}>
        <FlexElements alignItems='center'>
          {tabItem.icon}
          <span>{tabItem.title}</span>
          {tabItem.badge && tabItem.badge}
        </FlexElements>

        <div className={css['border']} />
      </div>
    </BaseButton>
  );
}
