import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useState } from 'react';

import { meterStatus } from '@zf/api-types/enums';
import { AddressType } from '@zf/api-types/general';
import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import { deepEqual } from '@zf/utils/src/object';

import { useAppContext } from '../../../../../app-context';
import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { ICON_COLORS } from '../../../../../constants/icons';
import useCreateRequest from '../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../hooks/useInfiniAPI';
import { useStore } from '../../../../../hooks/useStore';
import DeviceLink from '../../../../devices/shared/DeviceLink';
import NoMetersOverlay from './NoMetersOverlay';
import MeterIssueIndicator from 'design-system/Components/MeterIssueIndicator/MeterIssueIndicator';

type Props = {
  serviceLocationId: string;
  models: ModelType[];
  address: AddressType;
};

type MeterRowType = {
  isUnhealthy: JSX.Element | null;
  __id: string;
  serialNumber: JSX.Element;
  status: JSX.Element;
  installDate: string;
  removalDate: string;
  model: string | undefined;
  brand: string | undefined;
};

const MeterCardBodyContent = (props: Props) => {
  const { serviceLocationId, models, address } = props;

  const { applicationStore } = useStore();
  const { userStore, getTranslation, getEnumTranslation } = applicationStore;
  const { lang } = userStore;
  const { tenantReducer } = useAppContext();

  const today = moment();

  const [columns] = useState([
    {
      width: 40,
      label: '',
      dataKey: 'isUnhealthy'
    },
    {
      width: 150,
      label: getTranslation('meter.serial'),
      dataKey: 'serialNumber'
    },
    {
      width: 140,
      label: getTranslation('general.status'),
      dataKey: 'status'
    },
    {
      width: 160,
      label: getTranslation('meter.install_date'),
      dataKey: 'installDate'
    },
    {
      width: 180,
      label: getTranslation('meter.removal_date'),
      dataKey: 'removalDate'
    },
    {
      width: 140,
      label: getTranslation('meter.model'),
      dataKey: 'model'
    },
    {
      width: 120,
      label: getTranslation('meter.brand'),
      dataKey: 'brand'
    }
  ]);

  const { request, sortBy, sortDirection, setSelectedIds, handleSort } = useCreateRequest('/md/meters', {
    sluuids: serviceLocationId
  });

  const processRecord = (meter: MeterType): MeterRowType => {
    const model = models.find((m) => m.id === meter.modelId);

    const statussesForLocation = meter.statusHistory.filter(
      (s) => s.installedAtAddress && deepEqual(s.installedAtAddress, address)
    );
    const currentMatchingStatus: MeterStatusHistoryType | undefined =
      statussesForLocation[statussesForLocation.length - 1];

    const installDate = currentMatchingStatus?.startDateTime || MIN_DATE;
    const removalDate = currentMatchingStatus?.endDateTime || MAX_DATE;

    const status = today.isBetween(installDate, removalDate, undefined, '[)')
      ? meterStatus.installed
      : meterStatus.removed;

    return {
      //Remember add correct condition: 'issues' must be present
      //response used currently: md/meters
      isUnhealthy: meter.hasIssue ? (
        <MeterIssueIndicator
          usage="meter"
          id={meter.id}
          tooltipId={meter.serialNumber}
          tooltipExtra={meter.issuesCount}
        />
      ) : null,
      __id: meter.id,
      serialNumber: <DeviceLink device={meter} />,
      status: <NewStatusBadge status_={getEnumTranslation('meterStatus', status)} color={ICON_COLORS[status]} />,
      installDate: formatDate(installDate),
      removalDate: formatDate(removalDate),
      model: model && model.name,
      brand: model && model.brandName
    };
  };

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<MeterType, MeterRowType>({
    request,
    tenantReducer,
    lang,
    processRecord,
    onSelectRow: setSelectedIds
  });

  return (
    <DynamicVirtualTable
      id="meters-table"
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      rows={rows}
      loading={loading}
      error={error}
      NoDataOverlay={NoMetersOverlay}
      noSelect
      sortableFields={sortableFields}
      sort={handleSort}
      sortBy={sortBy}
      singleSort
      sortDirection={sortDirection}
      totalAmountOfRows={rows.length}
      columns={columns}
    />
  );
};

export default observer(MeterCardBodyContent);
