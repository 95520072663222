import React from 'react';
import css from './stacked-bar-chart.module.scss';

type Props = {
  children: JSX.Element;
};

const StackedBarChart = (props: Props) => {
  const { children } = props;
  return <div className={css['stacked-bar']}>{children}</div>;
};

export default StackedBarChart;
