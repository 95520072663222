import React from 'react';
import { Line } from 'recharts';

import { TotalOfInAndOutgoingInvoiceType } from '@zf/api-types/analytics/billing';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import ZFLineChart from '@zf/stella-react/src/atoms/Chart/ZFLineChart';
import { groupBySubstring } from '@zf/utils/src/array';
import { colors } from '@zf/utils/src/color';
import { roundNumber } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../app-context';

type Props = {
  incomingOutgoingData: TotalOfInAndOutgoingInvoiceType[];
};

export default function IncomingOutgoingChartCard(props: Props) {
  const { incomingOutgoingData } = props;
  const { i18n } = useAppContext();

  const groupedByYear = groupBySubstring(incomingOutgoingData, 'yearAndMonth', 0, 4);

  const chartData = Object.keys(groupedByYear).map((key) => {
    const entryData: TotalOfInAndOutgoingInvoiceType[] = groupedByYear[key];

    let totalIncoming = 0;
    let totalOutGoing = 0;

    entryData.forEach((d) => {
      totalIncoming += d.totalIncomingInvoices;
      totalOutGoing += d.totalOutgoingInvoices;
    });

    return {
      year: key,
      Incoming: roundNumber(totalIncoming),
      Outgoing: roundNumber(totalOutGoing)
    };
  });

  const lines = [
    <Line
      key="incoming"
      type="monotone"
      dataKey="Incoming"
      stroke={colors['blue-600']}
      fill={colors['blue-600']}
      unit="€"
    />,
    <Line
      key="outgoing"
      type="monotone"
      dataKey="Outgoing"
      stroke={colors['red-600']}
      fill={colors['red-600']}
      unit="€"
    />
  ];

  const hasData = chartData.length > 0;

  return (
    <BarChartCard
      height="40rem"
      subject="revenue"
      title={i18n.getTranslation('property_groups.tabs.billing.incoming_outgoing')}
    >
      {hasData ? (
        <ZFLineChart
          height="36rem"
          subject="revenue"
          chartData={chartData}
          lines={lines}
          XdataKey="year"
          locale={i18n.culture}
        />
      ) : (
        <EmptyChartState title={i18n.getTranslation('property_groups.tabs.billing.no_in_out_invoice_data_yet')} />
      )}
    </BarChartCard>
  );
}
