import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from '../../../../hooks/useStore';
import ApproveInvoiceListItem from '../../actions/detail/ApproveInvoiceListItem';
import AssignToCaseListItem from '../../actions/detail/AssignToCaseListItem';
import AssignToTransactionListItem from '../../actions/detail/AssignToTransactionListItem';
import DeleteInvoiceListItem from '../../actions/detail/DeleteInvoiceListItem';
import DownloadInvoiceListItem from '../../actions/detail/DownloadInvoiceListItem';
import MarkAsSentListItem from '../../actions/detail/MarkAsSentListItem';
import RegenerateListItem from '../../actions/detail/RegenerateListItem';
import SendInvoiceListItem from '../../actions/detail/SendInvoiceListItem';
import SendInvoicePostalListItem from '../../actions/detail/SendInvoicePostalListItem';
import MarkAsNotExportedListItem from '../../actions/detail/MarkAsNotExportedListItem';

const InvoiceActions = () => {
  const { invoiceStore } = useStore();
  const { selectedInvoice } = invoiceStore;
  const invoice = selectedInvoice.invoice;

  return (
    <>
      <DeleteInvoiceListItem invoice={invoice} />
      <ApproveInvoiceListItem invoice={invoice} />
      <RegenerateListItem invoiceId={invoice.id} />
      <DownloadInvoiceListItem invoice={invoice} />
      <MarkAsSentListItem />
      <AssignToCaseListItem invoice={invoice} />
      <AssignToTransactionListItem invoice={invoice} />
      <SendInvoiceListItem invoice={invoice} />
      <SendInvoicePostalListItem invoice={invoice} />
      <MarkAsNotExportedListItem invoice={invoice} />
    </>
  );
};

export default observer(InvoiceActions);
