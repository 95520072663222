import React from 'react';

import { TimeoutSpinner } from '@zf/stella-react/src/atoms/Spinner';
import Center from '@zf/stella-react/src/helpers/Center';

import { SpinnerWrapper } from '../../design-system/Foundation';
import ZFSpinner from './ZFSpinner';

type Props = {
  fullViewport?: boolean;
};

export default function Loader(props: Props) {
  const { fullViewport = true } = props;

  const loader = (
    <TimeoutSpinner
      spinner={
        <Center type="both">
          <ZFSpinner />
        </Center>
      }
    />
  );

  if (fullViewport) {
    return <SpinnerWrapper>{loader}</SpinnerWrapper>;
  }

  return loader;
}
