import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';

import { SettingsProvider } from './context/settings-context';
import SettingsTabContent from './SettingsTabContent';

type Props = {
  propertyGroup: PropertyGroupType;
  refreshProperty: () => void;
};

export default function SettingsTab(props: Props) {
  return (
    <SettingsProvider>
      <SettingsTabContent {...props} />
    </SettingsProvider>
  );
}
