import React from 'react';

import { ExportJobRowType, ExportJobType } from '@zf/api-types/export-job';
import useDialog from '@zf/hooks/src/useDialog';

import GenerateExportJobDialog from '../../../actions/export-jobs/generate-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function GenerateExportJobsListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { myCurrent } = useCurrent('exportjob');
  const selectedRows: ExportJobRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<ExportJobType>('exportjob');
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    (selectedRows &&
      !!selectedRows.find(
        (row) =>
          row.__exportJobEntity.status === 'generated' ||
          row.__exportJobEntity.status === 'generating' ||
          row.__exportJobEntity.status === 'transferred'
      ));

  return (
    <DropdownAction
      id="export_jobs.generate"
      icon="cog"
      dialogTitle={i18n.getTranslation('actions.generate_export_job')}
      dialogContent={
        <GenerateExportJobDialog
          onComplete={setUpdatedRows}
          ref={clickRef}
          selectedRows={selectedRows}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('actions.generate_export_job')}
    />
  );
}
