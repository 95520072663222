import React, { ReactNode } from 'react';

import css from './invoice-preview-wrapper.module.scss';

type Props = {
  children: ReactNode;
};

export default function InvoicePreviewWrapper(props: Props) {
  const { children } = props;

  return <div className={css['preview-wrapper']}>{children}</div>;
}
