import React from 'react';

import { FileFormatType } from '@zf/api-types/file-format';
import { Card, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { SuspenseCardBody } from '../../components/suspense';
import FormatDetailGenerator from './format-detail-generator';

export type Props = {
  fileformat: FileFormatType;
};

export default function FileFormatCard(props: Props) {
  const { fileformat } = props;
  const { i18n } = useAppContext();

  return (
    <Card id="file-format-card">
      <CardHeader>{i18n.getTranslation('import_jobs.file_format')}</CardHeader>
      <SuspenseCardBody type="grid">
        <CardItem width="6">
          <Label>{i18n.getTranslation('general.name')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right">{fileformat.name}</Paragraph>
        </CardItem>
        <FormatDetailGenerator format={fileformat.settings.format} />
      </SuspenseCardBody>
    </Card>
  );
}
