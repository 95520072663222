import { entitySubjectType } from '@zf/api-types/enums';
import React from 'react';

import { useAppContext } from '../../../../../app-context';
import useBrowserTitle from '../../../../../app-context/hooks/use-browser-title';
import ListPage from '../../../../../components/listPage';
import OutgoingBankingtransactionsFilterBar from './outgoing-banking-transactions-filter-bar';
import OutgoingBankingTransactionsListPage from './outgoing-banking-transactions-listpage';

type Props = {
  location: Location;
};

export default function OutgoingBankingTransactionListPage(props: Props) {
  const { i18n } = useAppContext();
  useBrowserTitle(i18n.getTranslation('outgoing_banking_transactions.outgoing_banking_transactions'));

  return (
    <ListPage
      entitySubjectType={entitySubjectType.outgoingbankingtransaction}
      subjectType={i18n.getTranslation('outgoing_banking_transactions.outgoing_banking_transactions')}
      topBar={<OutgoingBankingtransactionsFilterBar search={props.location.search} />}
      list={<OutgoingBankingTransactionsListPage />}
    />
  );
}
