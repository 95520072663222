import moment from 'moment';
import React from 'react';

import { FixedDurationPeriodicityParametersType, ProductBillingItemType } from '@zf/api-types/product';
import Draggeable from '@zf/stella-react/src/atoms/DragAndDrop/draggeable';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import IconParagraph from '../../../../components/Icon/IconParagraph';
import DatePicker from '../../../../components/Lang/DatePicker';
import { Spinner } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import useMatchingBillingItem from '../../shared/hooks/useMatchingBillingItem';
import PeriodicityParameters from '../product-column/product/periodicity-parameters';
import css from './list-billing-item.module.scss';

type Props = {
  productBillingItem: ProductBillingItemType;
  setPeriodicityParameter: (idToUpdate: string, val: Partial<FixedDurationPeriodicityParametersType>) => void;
  setProductBillingItemValue: (idToUpdate: string, val: Partial<ProductBillingItemType>) => void;
  onDragEnter: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
};

export default function ListBillingItem(props: Props) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    productBillingItem,
    setPeriodicityParameter,
    setProductBillingItemValue,
    onDragEnter,
    onDragStart,
    onDragEnd
  } = props;

  const matchingItem = useMatchingBillingItem(productBillingItem.billingItemId);

  // Date fields don't exist yet when an item hasn't been assigned to a product
  if (!productBillingItem.startDateTime) {
    productBillingItem.startDateTime = MIN_DATE;
  }

  if (!productBillingItem.endDateTime) {
    productBillingItem.endDateTime = MAX_DATE;
  }

  return (
    <Draggeable onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {() => {
        return (
          <div
            className={css['list-billing-item']}
            onDragEnter={onDragEnter}
            role="button"
            onKeyDown={() => onDragEnter}
            tabIndex={-1}
          >
            {matchingItem ? (
              <IconParagraph iconType="dots-drag">{matchingItem.name}</IconParagraph>
            ) : (
              <Spinner className={css['spinner']} size="xsmall" />
            )}

            <InputContainer>
              <PeriodicityParameters
                productBillingItem={productBillingItem}
                setPeriodicityParameter={setPeriodicityParameter}
              />
              <DatePicker
                id={`${productBillingItem.billingItemId}-startDate`}
                onChange={(date) =>
                  setProductBillingItemValue(productBillingItem.billingItemId, {
                    startDateTime: date.toISOString() || MIN_DATE
                  })
                }
                value={moment(productBillingItem.startDateTime)}
                placeholder={getTranslation('general.start_date')}
              />
              <DatePicker
                id={`${productBillingItem.billingItemId}-endDate`}
                onChange={(date) =>
                  setProductBillingItemValue(productBillingItem.billingItemId, {
                    endDateTime: date.toISOString() || MAX_DATE
                  })
                }
                value={moment(productBillingItem.endDateTime)}
                placeholder={getTranslation('general.end_date')}
              />
            </InputContainer>
          </div>
        );
      }}
    </Draggeable>
  );
}
