import { observer } from 'mobx-react';
import React from 'react';

import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import css from './billing-items-list-header.module.scss';

const BillingItemsListHeader = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div className={css['row']}>
      <Paragraph>{getTranslation('general.description')}</Paragraph>
      <Paragraph>{getTranslation('general.amount')}</Paragraph>
      <Paragraph>{getTranslation('invoice.unit_price')}</Paragraph>
      <Paragraph>{getTranslation('estimated_invoice.line_amount_inc_vat')}</Paragraph>
    </div>
  );
};

export default observer(BillingItemsListHeader);
