import React from 'react';

import { ProductType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import AddEditProductDialog from '../dialogs/AddEditProductDialog';

type Props = {
  product: ProductType;
};

export default function EditListItem(props: Props) {
  const { product } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="edit-product-attachment"
      dialogTitle={getTranslation('product_config.edit_product')}
      icon="sign"
      dialogContent={<AddEditProductDialog ref={clickRef} product={product} validationRef={validationRef} />}
      onSubmit={onSubmit}
      buttonPositive={getTranslation('general.save')}
      validationRef={validationRef}
      dialogLocation="right"
      stackOrder={2}
    />
  );
}
