import React from 'react';

import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import StellaTextAreaField, { StellaInputFieldProps } from '@zf/stella-react/src/atoms/InputField/StellaTextAreaField';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import ResetIcon from '../Icon/ResetIcon';
import AccessControl from '../Permissions/AccessControl';
import css from './inputfield.module.scss';

export type InputFieldProps = StellaInputFieldProps & {
  resetValue?: string | null;
  reset?: () => void;
};

export default function InputField(props: InputFieldProps) {
  const { id, value, resetValue, reset } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{value}</Paragraph>}>
      <>
        <StellaTextAreaField {...props} value={value ? value.toString() : ''} />
        {reset && resetValue !== value && (
          <div className={css['input-wrapper']}>
            <ResetIcon id={`${id}-reset`} onClick={reset} />
          </div>
        )}
      </>
    </AccessControl>
  );
}

export const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
