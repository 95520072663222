import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { useAppContext } from '../../../app-context/app-context';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';

export default function useDefaultTimeZone(selected: string) {
  const { tenantReducer } = useAppContext();
  const { organization } = tenantReducer.state;

  const response = useSuspenseSingleAPI<OrganizationConfigType>({
    request: {
      endpoint: `/cfg/Organizations/${organization?.organizationId}`
    },
    mayExecute: !selected
  });

  if (!response.result) return null;

  return response.result.data.timeZone;
}
