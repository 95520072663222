import {
  RegionalRegulationsConfigurationRequest,
  RegionalRegulationsConfigurationType
} from '@zf/api-types/regionals/regionals';

import BaseService from '../../../app-context/services/BaseService';
import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { METHODS } from '../../../utils/request';

export default class CountrySpecificService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/cfg/regionalregulations/', applicationStore);
  }

  getRegionalRegulationParams = async () => {
    const response = await this.applicationStore.sendRequest<RegionalRegulationsConfigurationType>({
      request: {
        method: METHODS.GET,
        endpoint: `${this.endpoint}${this.applicationStore.tenantReducer.tenant?.countryISOCode}`
      }
    });

    return response.data;
  };

  updateRegionalRegulationParams = async (data: RegionalRegulationsConfigurationRequest) => {
    const response = await this.applicationStore.sendRequest<RegionalRegulationsConfigurationType>({
      request: {
        method: METHODS.POST,
        endpoint: `${this.endpoint}${this.applicationStore.tenantReducer.tenant?.countryISOCode}`,
        data: { SocialTariffParameters: data }
      }
    });

    return response.data;
  };
}
