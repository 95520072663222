import classNames from 'classnames';
import React from 'react';

import Heading from '../Heading/Heading';
import { Paragraph } from '../Paragraph';
import css from './introduction.module.scss';

type Props = {
  headerText: string;
  introductionText: string;
  width?: '1' | '2' | '3';
  children?: React.ReactNode;
  className?: string;
};

export default function Introduction(props: Props) {
  const { headerText, introductionText, children, className, width = '1' } = props;

  return (
    <div className={classNames(css['introduction'], css[`card-${width}`], className)}>
      <div className={css['svg-wrapper']}>
        <div className={css['svg']} />
      </div>
      <div className={css['text']}>
        <Heading headingType="h3">{headerText}</Heading>
        <Paragraph>{introductionText}</Paragraph>
      </div>
      {children && <div className={css['children']}>{children}</div>}
    </div>
  );
}
