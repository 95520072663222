import React from 'react';

import { outgoingMutationStatus, outgoingMutationType } from '@zf/api-types/enums';

import { useAppContext } from '../../../../../../../app-context/app-context';
import FilterInputs from '../../../../../../../components/Filters/filter-inputs';
import FilterTag from '../../../../../../../components/Filters/filter-tag';
import FilterTags from '../../../../../../../components/Filters/filter-tags';
import EnumFilterSelect from '../../../../../../../components/input/EnumFilterSelect';
import { MutationQueryParamsType, useTracked } from '../../context/outgoing-transaction-detail-context';

export const useOutgoingMutationsFilter = () => {
  const [state, dispatch] = useTracked();

  const { i18n, enumReducer } = useAppContext();

  const setQueryParams = React.useCallback((newParams: Partial<MutationQueryParamsType>) => {
    dispatch({ type: 'UPDATE_QUERY_PARAMS', newParams });
  }, []);

  const resetQueryParams = React.useCallback(() => {
    dispatch({ type: 'RESET_QUERY_PARAMS' });
  }, []);

  const setFlexSearch = React.useCallback(
    (val: string) => {
      setQueryParams({ flexSearch: val });
    },
    [setQueryParams]
  );

  const tags: React.ReactNode[] = [];

  if (state.queryParams.status && state.queryParams.status.length > 0) {
    tags.push(
      <FilterTag
        key="status"
        enumType="outgoingMutationStatus"
        type="enum"
        value={state.queryParams.status[0]}
        removeFilter={() => setQueryParams({ status: [] as outgoingMutationStatus[] })}
      />
    );
  }

  if (state.queryParams.referenceType && state.queryParams.referenceType.length > 0) {
    tags.push(
      <FilterTag
        key="reference_type"
        enumType="outgoingMutationType"
        type="enum"
        value={state.queryParams.referenceType[0]}
        removeFilter={() => setQueryParams({ referenceType: [] as outgoingMutationType[] })}
      />
    );
  }

  const filterTags = <FilterTags key="filter-tags" tags={tags} />;

  const filters = (
    <FilterInputs
      filterInputs={[
        <EnumFilterSelect
          id="status"
          key="status"
          title={i18n.getTranslation('general.status')}
          options={enumReducer.getEnum<outgoingMutationStatus>('outgoingMutationStatus')}
          values={state.queryParams.status ? state.queryParams.status : []}
          setValue={(value) => setQueryParams({ status: value })}
        />,
        <EnumFilterSelect
          id="reference_type"
          key="reference_type"
          title={i18n.getTranslation('general.type')}
          options={enumReducer.getEnum<outgoingMutationType>('outgoingMutationType')}
          values={state.queryParams.referenceType ? state.queryParams.referenceType : []}
          setValue={(value) => setQueryParams({ referenceType: value })}
        />
      ]}
      removeAllFilters={resetQueryParams}
    />
  );

  return {
    filters,
    filterTags,
    queryParams: state.queryParams,
    setQueryParams,
    setFlexSearch,
    resetQueryParams
  };
};

export default useOutgoingMutationsFilter;
