import { PagedResponseType } from '@zf/api-types/api';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';

import ApplicationStore from '../../../../app-context/stores/domain/ApplicationStore';
import { METHODS } from '../../../../utils/request';

export default class CrudApiService<T> {
  public endPoint: string;
  public applicationStore: ApplicationStore;
  public query: any;
  constructor(endPoint: string, query: any, applicationStore: ApplicationStore) {
    this.endPoint = endPoint;
    this.applicationStore = applicationStore;
    this.query = query;
  }

  getAllEntities = async (sortDirection?: Record<string, SortDirection | ''>) => {
    const keys = Object.keys(sortDirection || []);
    return (
      await this.applicationStore.sendRequest<PagedResponseType<T>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endPoint,
          query: {
            ...this.query,
            orderBy:
              keys.length > 0
                ? keys.reduce((acc, key) => {
                    if (sortDirection?.[key]) {
                      acc += `${sortDirection[key] === 'ASC' ? '' : '-'}${key}`;
                    }
                    return acc;
                  }, '')
                : ''
          }
        }
      })
    ).data;
  };

  updateEntity = () => {};

  createEntity = () => {};

  deleteEntity = () => {};
}
