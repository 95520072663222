import { observer } from 'mobx-react';
import React from 'react';

import { AdvanceAmountLimitType } from '@zf/api-types/enums';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import FloatInput from '../../../components/input/FloatInput';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../hooks/useStore';
import css from './portal-cards.module.scss';

const RangeInputs = () => {
  const { integrationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { portalSettingsForm, rangeSymbol } = integrationStore.customerPortalStore;

  if (!portalSettingsForm) return null;

  const dropdownValues = [
    {
      value: AdvanceAmountLimitType.fixed,
      text: getTranslation('portal_config.fixed_value')
    },
    {
      value: AdvanceAmountLimitType.percentage,
      text: getTranslation('portal_config.percentage')
    }
  ];

  return (
    <>
      <div className={css['range']}>
        <FloatInput
          id="min-value"
          value={portalSettingsForm._get('rangeMin')}
          onChange={(min) => portalSettingsForm._set('rangeMin', min)}
          postfix={rangeSymbol}
          placeholder={getTranslation('general.minimum')}
          error={!!portalSettingsForm._error('rangeMin')}
          singleError={portalSettingsForm._error('rangeMin')}
        />
        <Paragraph className={css['range-spacer']}>-</Paragraph>
        <FloatInput
          id="max-value"
          value={portalSettingsForm._get('rangeMax')}
          onChange={(max) => portalSettingsForm._set('rangeMax', max)}
          postfix={rangeSymbol}
          placeholder={getTranslation('general.maximum')}
          error={!!portalSettingsForm._error('rangeMax')}
          singleError={portalSettingsForm._error('rangeMax')}
        />
      </div>

      <SimpleDropdown
        id="range-type"
        onChange={(val) => portalSettingsForm._set('type', val[0])}
        values={dropdownValues}
        selectedValues={[portalSettingsForm._get('type')]}
        placeholder={getTranslation('portal_config.select_range')}
      />
    </>
  );
};

export default observer(RangeInputs);
