import WizardSubmitButton from 'components/Button/WizardSubmitButton';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import BillingSectionAdapter from './BillingSectionAdapter';
import EditLocationSection from './EditLocationSection';

const AddLocationsMoveInWizard = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addLocationsMoveInWizardNavigator, handleSubmit } =
    contractStore.moveInWizardBaseStore.addLocationsMoveInWizardStore;
  const { hasErrors } = addLocationsMoveInWizardNavigator;

  return (
    <>
      <EditLocationSection />

      <BillingSectionAdapter />

      <WizardSubmitButton id="contract-submit" onClick={handleSubmit} disabled={hasErrors}>
        {getTranslation('contracts.wizard.add_locations')}
      </WizardSubmitButton>
    </>
  );
};

export default observer(AddLocationsMoveInWizard);
