import BaseFormVjf, { Fields } from '../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../events/notification-events';
import InvoiceStore from '../stores/InvoiceStore';

export enum assignToCaseFormFields {
  flowId = 'flowId'
}

export interface AssignToCaseFieldTypes {
  flowId?: string;
}

class AssignToCaseForm extends BaseFormVjf<AssignToCaseFieldTypes> {
  constructor(invoiceStore: InvoiceStore, initialValues: AssignToCaseFieldTypes) {
    super(initialValues, {
      showErrorsOnInit: true
    });
    this.invoiceStore = invoiceStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          label: 'Flow id',
          name: assignToCaseFormFields.flowId,
          validators: [this.isRequired],
          type: 'text'
        }
      ]
    };
  }

  submit = async () => {
    try {
      await this.invoiceStore.assignToCase(this.invoiceStore.selectedInvoice.invoice, this._get('flowId'), true);

      notify.success({
        content: this.invoiceStore.rootStore.applicationStore.getTranslation('actions.invoice.assign_to_case_success')
      });
    } catch (e) {
      notify.error({
        content: this.invoiceStore.rootStore.applicationStore.getTranslation('actions.invoice.assign_to_case_fail'),
        error: e
      });
    }
  };

  hooks() {
    return {
      onSuccess: this.submit
    };
  }
}

export default AssignToCaseForm;
