import React from 'react';

import { ContractPaginatedResult, ContractType } from '@zf/api-types/master-data/contract';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../../app-context';
import useSingleAPI from '../../../../hooks/useSingleAPI';
import Dropdown from '../../../Lang/Dropdown';
import { UseDropdownReturnType } from '../../useDropdown';
import { ContractsDropdownProps } from './ContractsDropdown';
import ContractsDropdownContent from './ContractsDropdownContent';

type Props = {
  dropdownToolsObject: UseDropdownReturnType<ContractType>;
};

const InlineDropdown = InlineInputField<DropdownProps<ContractType>>(Dropdown);

export default function ContractsQueryDropdown(props: Props & ContractsDropdownProps) {
  const {
    id,
    customerId,
    dropdownToolsObject,
    selectedValues,
    placeholder,
    isInline = false,
    onFocus,
    onChange
  } = props;
  const { i18n } = useAppContext();

  const contractsResponse = useSingleAPI<ContractPaginatedResult>({
    request: {
      endpoint: '/md/Contracts',
      query: {
        customerId: customerId
      }
    }
  });

  if (!contractsResponse || !contractsResponse.result || contractsResponse.result instanceof Promise) {
    return isInline ? (
      <InlineDropdown
        {...props}
        id={id ? id : 'contracts-dropdown'}
        dropdownTools={dropdownToolsObject.tools}
        setDropdownTools={dropdownToolsObject.setDropdownTools}
        selectedValues={selectedValues}
        onChange={onChange}
        onFocus={onFocus}
      />
    ) : (
      <Dropdown
        {...props}
        id={id ? id : 'contracts-dropdown'}
        dropdownTools={dropdownToolsObject.tools}
        setDropdownTools={dropdownToolsObject.setDropdownTools}
        placeholder={placeholder ? placeholder : i18n.getTranslation('contracts.contracts')}
        selectedValues={selectedValues}
        onChange={onChange}
        onFocus={onFocus}
      />
    );
  }

  return <ContractsDropdownContent {...props} contracts={contractsResponse.result.data.results} />;
}
