import React from 'react';

import StellaDynamicIndexTable, {
  ColumnType
} from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../app-context/app-context';
import { notify } from '../../events/notification-events';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';

type DynamicIndexTableProps = {
  rows: Record<string, React.ReactNode>[];
  columns: ColumnType[];
  isDirty?: boolean;
  tooltipId?: string;
  selectedRow?: number;
  scrollToIndex?: number;
  sortableFields?: Record<string, SortDirection | ''>;
  isLoading?: boolean;
  setSelectedRow?: (index: number) => void;
  onRowMouseOver?: (index: number) => void;
  onRowMouseOut?: (index: number) => void;
  sort?: (sortby: string, sortDirection: SortDirection | '') => void;
  cursorPointer?: boolean;
  disableHeader?: boolean;
};

export default function DynamicIndexTable(props: DynamicIndexTableProps) {
  const { i18n } = useAppContext();

  return (
    <StellaDynamicIndexTable {...props} warning={i18n.getTranslation('index_table.sort_warning')} notifier={notify} />
  );
}
