import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';

import ListPageNew from '../../../../../components/listPageV2';
import { TransactionsContext, TransactionsProvider } from '../Context/Context';
import TransactionsFilterBar from './TransactionsFilterBar';
import TransactionDetailCard from './side-panels/TransactionDetailCard';
import TransactionsListPage from './TransactionsListPage';
import { CustomerType } from '@zf/api-types/master-data/customer';

type Props = {
  customer: CustomerType;
};

const TransactionsTab = (props: Props) => {
  const { customer } = props;
  return (
    <TransactionsProvider>
      <ListPageNew
        entitySubjectType={entitySubjectType.transaction}
        topBar={<TransactionsFilterBar />}
        half
        list={<TransactionsListPage customer={customer} />}
        sidePanel={<TransactionDetailCard customer={customer} />}
        embeddedInDetailPage
        context={TransactionsContext}
      />
    </TransactionsProvider>
  );
};

export default TransactionsTab;
