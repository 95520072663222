import { uiCulture } from '@zf/api-types/enums';
import { TenantReturnValue } from '../../../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../../../utils/request';

export async function deleteCustomerFromCustomerGroupsBulk(
  CustomerIds: Array<string>,
  CustomerGroupId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/Customers/bulk/customergroup`,
        data: { CustomerGroupId: '', CustomerIds }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteCustomerFromCustomerGroupsSingle(
  customeruuid: string,
  customerGroupId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/Customers/${customeruuid}/customergroup`,
        data: { customerGroupId: '' }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteCustomerGroup(
  customerGroupId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/md/CustomerGroups/${customerGroupId}`
      },
      tenantReducer,
      lang
    })
  ).data;
}
