import { observer } from 'mobx-react';
import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';

import useBrowserTitle from '../../../../../app-context/hooks/use-browser-title';
import { NavigatorType } from '../../../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../../../components/RouteWrapper';
import GenericTabContainer from '../../../../../components/Tab/GenericTabContainer';
import { useStore } from '../../../../../hooks/useStore';
import Route from '../../../../route';
import OutgoingBankingTransactionDetails from './detail/OutgoingBankingTransactionDetails';
import OutgoingBankingTransactionActions from './OutgoingBankingTransactionActions';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  status: JSX.Element;
  domain?: routedEntitySubjectType;
  navigator: NavigatorType;
  setOutgoingBankingTransaction: React.Dispatch<React.SetStateAction<OutgoingBankingTransactionType | null>>;
  navigate: (url: string) => void;
  refresh: () => void;
};

const OutgoingBankingTransactionTabContainer = (props: Props) => {
  const { status, outgoingBankingTransaction, navigator, navigate, setOutgoingBankingTransaction, refresh, domain } =
    props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const title = `${getEnumTranslation('outgoingBankingTransactionType', outgoingBankingTransaction.type)}: ${
    outgoingBankingTransaction.identification
  }`;

  useBrowserTitle(`${getTranslation('outgoing_banking_transactions.outgoing_banking_transaction')} - ${title}`);

  return (
    <GenericTabContainer
      actions={
        <OutgoingBankingTransactionActions
          outgoingBankingTransaction={outgoingBankingTransaction}
          setOutgoingBankingTransaction={setOutgoingBankingTransaction}
          refresh={refresh}
        />
      }
      navigator={navigator}
      entityId={title}
      subTitle={outgoingBankingTransaction.fileName}
      status={status}
      entityType={domain ? domain : routedEntitySubjectType.outgoingbankingtransaction}
      iconName="batch"
      navigate={navigate}
      tabItems={[
        {
          id: 'details',
          title: getTranslation('nav_detail.detail'),
          isDefault: true
        }
      ]}
      routes={
        <RouteWrapper default>
          <Route
            Component={OutgoingBankingTransactionDetails}
            path="details"
            outgoingBankingTransaction={outgoingBankingTransaction}
            setOutgoingBankingTransaction={setOutgoingBankingTransaction}
            refresh={refresh}
            default
          />
        </RouteWrapper>
      }
    />
  );
};

export default observer(OutgoingBankingTransactionTabContainer);
