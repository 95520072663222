import { InfoBanner } from 'design-system/ComponentSets';
import { InfoBannerColor } from 'design-system/ComponentSets/InfoBanner/InfoBanner';
import { Spinner } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Control, Controller, FormState, useController } from 'react-hook-form';

import { AdjacentMeasurementPairs, MeterType } from '@zf/api-types/master-data/meter';
import { MeasurementMeteringIssueDetails, MeteringIssue } from '@zf/api-types/metering/metering-issues';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { State } from '../../types';
import { IssueActionType } from '../IssueSidePanel';
import ResolveActions from '../shared/ResolveActions';
import css from './resolve-single-issue-panel.module.scss';
import ResolveSingleIssueGraph from './ResolveSingleIssueGraph';
import InputField from 'components/input/InputField';
import { Icon } from 'components/Icon';

type Props = {
  infoBannerState: { color: InfoBannerColor; info: string; isValidating: boolean };
  issueDetails: MeasurementMeteringIssueDetails;
  referenceMeasurements: AdjacentMeasurementPairs;
  message: string;
  correctedMeasurementValue: number;
  control: Control<Pick<State, 'message' | 'correctedMeasurementValue'>, any>;
  issue: MeteringIssue;
  meters?: MeterType[];
  formState: FormState<Pick<State, 'message' | 'correctedMeasurementValue'>>;
  runMsmtValidation: (val: number) => Promise<void>;
  onSubmit: (type: IssueActionType) => Promise<void>;
  refresh?:() => void
};

export const MultiLineInputWithHooksForm = ({ control, name }: { control: Control<any>; name: string }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <InputField
          iconLeft={<Icon type="textballoon" />}
          id="issues.message"
          onChange={onChange}
          value={value}
          placeholder={getTranslation('meter_issues.message_placeholder')}
        />
      )}
    />
  );
};

const UnresolvedPanelBody = (props: Props) => {
  const { issueDetails, referenceMeasurements, control, infoBannerState, issue, runMsmtValidation, onSubmit, meters, refresh } =
    props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { field } = useController({ name: 'correctedMeasurementValue', control });
  const messageFormField = useController({ name: 'message', control });

  return (
    <>
      <FlexElements className={css['graph-wrapper']} flexDirection="column">
        <ResolveSingleIssueGraph
          issueDetails={issueDetails}
          referenceMeasurements={referenceMeasurements}
          control={control}
          runMsmtValidation={runMsmtValidation}
        />

        {infoBannerState && (
          <InfoBanner
            className={css['info-banner']}
            color={infoBannerState.color}
            info={getTranslation(infoBannerState.info)}
            extraRight={infoBannerState.isValidating && <Spinner size="small" />}
          />
        )}

        <HorizontalDivider />
      </FlexElements>
      <div>
        <MultiLineInputWithHooksForm control={control} name="message" />

        <ResolveActions
          isUnresolved={true}
          issues={[issue]}
          onSubmit={onSubmit}
          meters={meters}
          isValidating={infoBannerState.isValidating || Number.isNaN(field.value)}
          ignoreMessage={messageFormField.field.value}
          refresh={refresh}
        />
      </div>
    </>
  );
};

export default observer(UnresolvedPanelBody);
