import { PagedResponseType } from '@zf/api-types/api';
import {
  ConfiguredPluginType,
  PluginTriggerResponse,
  PluginTriggerResponsePayload
} from '@zf/api-types/integration/plugin';
import { SortDirection } from '@zf/stella-react/src/atoms/Table/types';

import { METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import IntegrationLoggingStore, { FilterType } from '../../stores/integration/logging/IntegrationLoggingStore';
import BaseService from '../BaseService';

export default class PluginTriggerService extends BaseService {
  public integrationLoggingStore: IntegrationLoggingStore;

  constructor(integrationLoggingStore: IntegrationLoggingStore, applicationStore: ApplicationStore) {
    super('/int/plugintrigger/', applicationStore);
    this.integrationLoggingStore = integrationLoggingStore;
  }

  retriggerIntegrationLogging = async (subjectType: string, subjectId: string, logId: string) => {
    return (
      await this.applicationStore.sendRequest<ConfiguredPluginType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${subjectType}/${subjectId}/${logId}/retrigger`
        }
      })
    ).data;
  };

  getIntegrationLogging = async (subjectType: string, subjectId: string, logId: string) => {
    const response = await this.applicationStore.sendRequest<PluginTriggerResponsePayload>({
      request: {
        method: METHODS.GET,
        endpoint: `${this.endpoint}${subjectType}/${subjectId}/${logId}`
      }
    });

    return response.data;
  };

  getIntegrationLogItems = async (
    subjectType: string,
    subjectId: string,
    filter: FilterType,
    sortDirection: Record<string, SortDirection>
  ) => {
    const keys = Object.keys(sortDirection);
    this.integrationLoggingStore.setIsFetching(true);
    const response = await this.applicationStore.sendRequest<PagedResponseType<PluginTriggerResponse>>({
      request: {
        method: METHODS.GET,
        endpoint: `${this.endpoint}${subjectType}/${subjectId}`,
        query: {
          scheduledAtFromUtc: filter.scheduledFilter.startDate,
          scheduledAtToUtc: filter.scheduledFilter.endDate,
          triggeredFromUtc: filter.triggerFilter.startDate,
          triggeredToUtc: filter.triggerFilter.endDate,
          configuredPluginId: filter.configuredPlugin,
          orderBy:
            keys.length > 0
              ? keys.reduce((acc, key) => {
                  if (sortDirection[key]) {
                    acc += `${sortDirection[key] === 'ASC' ? '' : '-'}${key}`;
                  }
                  return acc;
                }, '')
              : ''
        }
      }
    });
    this.integrationLoggingStore.setIsFetching(false);

    return response.data;
  };
}
