import React from 'react';
import classNames from 'classnames';

import File from './File';
import css from './file-list.module.scss';
import { useAppContext } from '../../app-context';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

type FileType = React.ReactElement<typeof File>;

type Props = {
  children: Array<FileType>;
  rows?: number;
};

export default function FileList(props: Props) {
  const { children, rows = 2 } = props;

  const { i18n } = useAppContext();

  return (
    <div className={classNames(css['file-list'], css[`file-list-rows-${rows.toString()}`])}>
      {children && children.length > 0 ? children : <Paragraph>{i18n.getTranslation('general.no_files')}</Paragraph>}
    </div>
  );
}
