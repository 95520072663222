import React from 'react';

import { useAppContext } from '../../app-context';
import { ButtonProps } from '../../design-system/Components/Button/Button';
import Button from '../Button/Button';

export default function ResetIcon(props: ButtonProps) {
  const { i18n } = useAppContext();

  return <Button {...props} icon="reset" type="text" size="small" tooltipText={i18n.getTranslation('general.reset')} />;
}
