import PropertyGroupLinks from 'components/PropertyGroupLinks/PropertyGroupLinks';
import Icon from 'design-system/Foundation/Icon/Icon';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';

import { CollectionCaseType } from '@zf/api-types/collection-case';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import SubTitle from '@zf/stella-react/src/atoms/Tabs/SubTitle';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { NavigatorType } from '../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../components/RouteWrapper';
import GenericTabContainer from '../../../components/Tab/GenericTabContainer';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import Route from '../../route';
import css from './collection-case-tab-container.module.scss';
import CollectionCaseActions from './CollectionCaseActions';
import CollectionCaseDetail from './detail/CollectionCaseDetail';

type Props = {
  collCase: CollectionCaseType;
  status: JSX.Element;
  navigator: NavigatorType;
  navigate: (url: string) => void;
};

const CollectionCaseTabContainer = (props: Props) => {
  const { navigate, status, collCase, navigator } = props;
  const { timeStamp, refresh } = useRefresh();
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(
    `${getTranslation('collection_case.collection_case')} - ${collCase.sequenceNum} - ${collCase.debtor.displayName} (${
      collCase.workflowName
    })`
  );

  let subTitle: ReactNode = '';
  let subTitle2: ReactNode = '';

  if (collCase.propertyGroups && collCase.propertyGroups.length > 0) {
    subTitle2 = (
      <SubTitle>
        {getTranslation('collection_case.applicable_to')}
        <PropertyGroupLinks propertyGroups={collCase.propertyGroups} />
      </SubTitle>
    );
  } else {
    subTitle2 = '';
  }

  if (collCase.debtor.customerGroup) {
    subTitle = (
      <SubTitle>
        {getTranslation('customer_groups.member_of')}
        {<Icon name="customer-group" className={css['sub-icon-cg']}></Icon>}
        <Link
          id={`customer-group-${collCase.debtor.customerGroup.id}`}
          className={css['cust_group_link']}
          url={`${applicationStore.rootUrl}/customers/customer-groups/${collCase.debtor.customerGroup.id}`}
          sub
        >
          {collCase.debtor.customerGroup.name}
        </Link>
      </SubTitle>
    );
  }

  return (
    <GenericTabContainer
      actions={<CollectionCaseActions collectionCase={collCase} refresh={refresh} />}
      navigator={navigator}
      entityId={`${collCase.sequenceNum} - ${collCase.debtor.displayName} (${collCase.workflowName})`}
      status={status}
      entityType={routedEntitySubjectType.collectioncase}
      navigate={navigate}
      iconName="dunning"
      subTitle={subTitle || subTitle2}
      subTitle2={subTitle2 && subTitle ? subTitle2 : ''}
      tabItems={[
        {
          id: 'detail',
          title: getTranslation('nav_detail.detail'),
          isDefault: true
        }
      ]}
      routes={
        <RouteWrapper default>
          <Route
            Component={CollectionCaseDetail}
            path="detail"
            collCase={collCase}
            timeStamp={timeStamp}
            refresh={refresh}
            default
          />
        </RouteWrapper>
      }
    />
  );
};

export default observer(CollectionCaseTabContainer);
