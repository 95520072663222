import classNames from 'classnames';
import React from 'react';

import { AskingPeriod, ReceivingPeriod } from '@zf/api-types/billing/billing-completeness';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatPeriodWMonth } from '@zf/utils/src/date';

import { useStore } from '../../../../../../../hooks/useStore';
import css from './value-completeness.module.scss';

type Props = {
  apIndex: number;
  valueName: string;
  askingPeriod: AskingPeriod;
  correspondingReceivingPeriods: (ReceivingPeriod & {
    value: number;
  })[];
};

export default function ValueCompleteness(props: Props) {
  const { apIndex, valueName, askingPeriod, correspondingReceivingPeriods } = props;

  const { contractStore } = useStore();
  const { getMatchingValue } = contractStore.billingCompletenessStore;

  let valueForAskingStart: number | null = null;
  let valueForAskingEnd: number | null = null;

  valueForAskingStart = getMatchingValue(askingPeriod.startDateTime, correspondingReceivingPeriods);
  valueForAskingEnd = getMatchingValue(askingPeriod.endDateTime, correspondingReceivingPeriods);

  const isMissing = valueForAskingEnd === null || valueForAskingStart === null;

  return (
    <div className={css['asking-period']}>
      <Paragraph>{valueName}</Paragraph>

      <div className={classNames(css['date-values'], { [css['gap']]: !isMissing })}>
        <Paragraph color={isMissing ? colors['red-600'] : undefined}>{`${formatPeriodWMonth(
          askingPeriod.startDateTime,
          askingPeriod.endDateTime
        )}${isMissing ? '' : ':'}`}</Paragraph>

        <div className={css['receiving-periods']}>
          {correspondingReceivingPeriods.map((rp, rpIndex) => {
            return (
              <div key={`${rp.startDateTime}-${rp.endDateTime}-${apIndex}-${rpIndex}`}>
                <Paragraph color={isMissing ? undefined : colors['green-600']}>{`${formatPeriodWMonth(
                  rp.startDateTime,
                  rp.endDateTime
                )} (${rp.value})`}</Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
