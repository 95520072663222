import moment from 'moment';
import React from 'react';
import { Bar } from 'recharts';

import { unitOfMeasure, utilityType } from '@zf/api-types/enums';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { AverageServiceConsumptionPerServiceLocationType } from '@zf/api-types/service-consumption';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import ZFBarChart from '@zf/stella-react/src/atoms/Chart/ZFBarChart';
import { groupBy } from '@zf/utils/src/array';
import { formatDecimal, roundNumber } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../app-context';
import UtilityTypeButton from '../../../../../../components/Button/utility-type-button';
import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import { ICON_COLORS } from '../../../../../../constants/icons';
import css from './average-consumption-chart-card.module.scss';

type Props = {
  consumptions: AverageServiceConsumptionPerServiceLocationType[];
  locations: ServiceLocationType[];
};

type State = {
  selectedUtilityType: utilityType;
  selectedUnitOfMeasure: unitOfMeasure;
  selectedYear: number;
};

type setValue = Partial<State>;

export default function AverageConsumptionChartCard(props: Props) {
  const { consumptions, locations } = props;
  const { i18n, enumReducer } = useAppContext();

  const groupedByYear: Record<number, AverageServiceConsumptionPerServiceLocationType[]> = groupBy(
    consumptions,
    'period'
  );

  const years = Object.keys(groupedByYear);
  const year = parseInt(years[years.length - 1]);

  const dropdownValues = years.map((y) => {
    return { text: y.toString(), value: y };
  });

  const stateReducer = createStateReducer<State, setValue>();
  const [state, setValue] = React.useReducer(stateReducer, {
    selectedUtilityType: utilityType.cooling,
    selectedUnitOfMeasure: unitOfMeasure.j,
    selectedYear: year || moment().get('year')
  });

  const consumptionsForSelectedYear = groupedByYear[state.selectedYear] ? groupedByYear[state.selectedYear] : [];

  let totalAllData = 0;
  let utilityTypeOccurrances = 0;

  consumptionsForSelectedYear.forEach((c) => {
    if (c.utilityType === state.selectedUtilityType) {
      totalAllData += c.averagePerYear;
      utilityTypeOccurrances++;
    }
  });

  const totalAvg = totalAllData / utilityTypeOccurrances || 0;

  const groupedByUtilityType: Record<utilityType, AverageServiceConsumptionPerServiceLocationType[]> = groupBy(
    consumptionsForSelectedYear,
    'utilityType'
  );

  React.useEffect(() => {
    if (Object.keys(groupedByUtilityType).length > 0) {
      const firstUtilityType = Object.keys(groupedByUtilityType)[0] as utilityType;
      const firstUoM = Object.values(groupedByUtilityType)[0][0].unitOfMeasure;

      if (firstUtilityType) {
        setValue({ selectedUtilityType: firstUtilityType, selectedUnitOfMeasure: firstUoM });
      }
    }
  }, []);

  let chartData: ChartDataType[] = [];

  if (groupedByUtilityType[state.selectedUtilityType]) {
    chartData = groupedByUtilityType[state.selectedUtilityType].map((item) => {
      const matchedLocation = locations.find((l) => l.id === item.serviceLocationId);

      return {
        location:
          matchedLocation && matchedLocation.address.localizedDisplay
            ? matchedLocation.address.localizedDisplay.split(',')[0]
            : '',
        averagePerYear: roundNumber(item.averagePerYear - totalAvg)
      };
    });
  }

  const isCrowded = chartData.length > 7;

  // Underscore prevents wrapping of spaces in string (crowdy addresses)
  if (!isCrowded) {
    chartData.forEach((cd) => {
      const casted = cd.location as string;
      cd.location = casted.replace(' ', '_');
    });
  }

  const updateState = (utilityType_: utilityType) => {
    const matchedConsumption = consumptionsForSelectedYear.find((c) => {
      return c.utilityType === utilityType_;
    });

    if (matchedConsumption) {
      setValue({ selectedUtilityType: utilityType_, selectedUnitOfMeasure: matchedConsumption.unitOfMeasure });
    }
  };

  const generateUtilityTypeBtns = () => {
    const types = consumptionsForSelectedYear.reduce((acc: utilityType[], c) => {
      if (!acc.includes(c.utilityType)) {
        acc.push(c.utilityType);
      }

      return acc;
    }, []);

    return types.map((t, index) => {
      return (
        <UtilityTypeButton
          key={`${t}-${index}`}
          utilityType_={t}
          onTrigger={() => updateState(t)}
          isActive={state.selectedUtilityType === t}
        />
      );
    });
  };

  return (
    <>
      <BarChartCard
        subject="consumption_per_location"
        title={i18n.getTranslation('property_groups.tabs.metering.avg_consumption_to_property_avg', {
          year: state.selectedYear,
          propertyAvg: `${formatDecimal(roundNumber(totalAvg), i18n.culture)} ${enumReducer.getTranslation(
            'unitOfMeasure',
            state.selectedUnitOfMeasure
          )}`
        })}
        width="4"
        height="35vh"
        extraRight={
          <div className={css['extra-right']}>
            <SimpleDropdown
              id="year-dropdown"
              onChange={(val) => setValue({ selectedYear: parseInt(val[0]) })}
              values={dropdownValues}
              selectedValues={[state.selectedYear.toString()]}
            />

            <div className={css['btns-wrapper']}>{generateUtilityTypeBtns()}</div>
          </div>
        }
      >
        {consumptionsForSelectedYear.length > 0 ? (
          <ZFBarChart
            subject="consumption_per_location"
            chartData={chartData}
            XdataKey="location"
            height="31vh"
            locale={i18n.culture}
            bars={[
              <Bar
                key="location"
                name={enumReducer.getTranslation('utilityType', state.selectedUtilityType)}
                dataKey="averagePerYear"
                fill={ICON_COLORS[state.selectedUtilityType]}
                radius={[3, 3, 0, 0]}
                unit={enumReducer.getTranslation('unitOfMeasure', state.selectedUnitOfMeasure)}
              />
            ]}
          />
        ) : (
          <EmptyChartState title={i18n.getTranslation('property_groups.tabs.metering.no_consumption_data_yet')} />
        )}
      </BarChartCard>
    </>
  );
}
