import TooltipTrigger from 'design-system/Components/Tooltip/TooltipTrigger/TooltipTrigger';
import React from 'react';

import { colors } from '@zf/utils/src/color';

import { Icon } from '../../Foundation';
import css from './quick-tip.module.scss';

type Props = {
  id: string;
  text?: string; // Use this when passing plain text
  interweave?: JSX.Element; // Use this when passing HTML styled text
  alert?: boolean;
};

/**
 * Component for showing extra information where little space is left or to warn users
 *
 * @component
 * @example
 * const id = 'account-error'
 * const text = 'Something went wrong'
 * return (
 *   <QuickTip id={id} text={text} alert />
 * )
 * @example
 * const id = 'account-info'
 * const text = 'Some extra info'
 * return (
 *   <QuickTip id={id} text={text} />
 * )
 * @example
 * const id = 'new-adv-amount-tip'
 * const interweave = <Interweave content={'My <u><b>custom HTML</b></u> text'} />
 * return (
 *  <QuickTip id={id} interweave={interweave} />
 * )
 */
const QuickTip = (props: Props) => {
  const { id, text, interweave, alert = false } = props;

  const name = alert ? 'alert-circle' : 'info-circle';
  const color = colors[alert ? 'red-600' : 'blue-600'];

  if (interweave) {
    return (
      <TooltipTrigger tooltip={interweave}>
        <Icon id={id} className={css['quick-tip']} name={name} color={color} />
      </TooltipTrigger>
    );
  }

  return <Icon id={id} className={css['quick-tip']} name={name} color={color} title={text} />;
};

export default QuickTip;
