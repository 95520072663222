import { SimpleTooltip } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import React from 'react';

export const Detail: React.FC<{ accuracyToolTipTitle: string; onClick?: Function }> = ({
  accuracyToolTipTitle,
  children,
  onClick
}) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const tooltip = `<b>${accuracyToolTipTitle}.</b> ${getTranslation('contracts.estimation_accuracy_show_calculation')}`;

  return (
    <SimpleTooltip id="invoice.estimation.accuracy.tip" renderInGateway={false} tooltip={tooltip} html={true}>
      <>{children}</>
    </SimpleTooltip>
  );
};
