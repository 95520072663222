import PropertyGroupLinks from 'components/PropertyGroupLinks/PropertyGroupLinks';
import { Icon } from 'design-system/Foundation';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';

import { contractStatus } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import SubTitle from '@zf/stella-react/src/atoms/Tabs/SubTitle';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import { NavigatorType } from '../../../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../../../components/RouteWrapper';
import GenericTabContainer from '../../../../../components/Tab/GenericTabContainer';
import { attachmentType } from '../../../../../components/units/EntityAttachments/logic/FilesStore';
import { ICON_COLORS } from '../../../../../constants/icons';
import { Link } from '../../../../../design-system/Components';
import { useStore } from '../../../../../hooks/useStore';
import Route from '../../../../route';
import BillingInsights from '../billing-insights/left-part/BillingInsights';
import ContractActions from '../ContractActions';
import ContractDetail from '../detail/ContractDetail';
import ContractAttachments from '../detail/files/ContractAttachments';
import css from './contract-tab-container.module.scss';

type Props = {
  navigator: NavigatorType;
  navigate: (url: string) => void;
};

const ContractTabContainer = (props: Props) => {
  const { navigator, navigate } = props;
  const { contractStore, applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { filesStore, selectedContract, prepaymentDevice } = contractStore;
  const { initFiles } = filesStore;

  const contract = selectedContract.contract;

  const setActiveTab = (tabId: string) => navigate(tabId);

  const routes = [<Route key="detail" Component={ContractDetail} path="detail" setActiveTab={setActiveTab} default />];

  const tabItems: TabItemType[] = [
    {
      id: 'detail',
      title: getTranslation('nav_detail.detail'),
      isDefault: true
    }
  ];

  if (
    contract.currentContractStatus !== contractStatus.draft &&
    contract.currentContractStatus !== contractStatus.cancelled
  ) {
    routes.push(<Route key="billing-insights" Component={BillingInsights} path="billing-insights" />);

    if (prepaymentDevice === null || prepaymentDevice === undefined) {
      tabItems.push({
        id: 'billing-insights',
        title: getTranslation('nav_detail.billing_insights'),
        isScrollable: false
      });
    }
  }

  routes.push(
    <Route
      key="files"
      Component={ContractAttachments}
      path="files"
      entityId={contract.id}
      customerId={contract.contractor.customerId}
    />
  );

  tabItems.push({
    id: 'files',
    title: getTranslation('attachment.files')
  });

  let subTitle: ReactNode = '';
  let subTitle2: ReactNode = '';

  if (contract.propertyGroups && contract.propertyGroups.length > 0) {
    subTitle2 = (
      <SubTitle>
        {getTranslation('location.part_of')}
        <PropertyGroupLinks propertyGroups={contract.propertyGroups} />
      </SubTitle>
    );
  }

  if (contract.contractor.customerGroup) {
    subTitle = (
      <SubTitle>
        {getTranslation('customer_groups.member_of')}
        {<Icon name="customer-group" className={css['sub-icon-cg']} />}
        <Link
          id={`customer-group-${contract.contractor.customerGroup.id}`}
          className={css['prop_group_link']}
          url={`${applicationStore.rootUrl}/customers/customer-groups/${contract.contractor.customerGroup.id}`}
          sub
        >
          {contract.contractor.customerGroup.name}
        </Link>
      </SubTitle>
    );
  }

  useEffect(() => {
    initFiles(contract.id, attachmentType.entityattachments, contract.contractor.customerId, {
      contractId: contract.id,
      contractNumber: contract.contractNumber
    });
  }, []);

  return (
    <GenericTabContainer
      actions={<ContractActions />}
      entityId={`${contract.contractNumber} - ${contract.contractor.shortDisplayName}`}
      status={
        <NewStatusBadge
          color={ICON_COLORS[contract.currentContractStatus]}
          status_={` ${getEnumTranslation('contractStatus', contract.currentContractStatus)} `}
        />
      }
      entityType={routedEntitySubjectType.contract}
      navigate={navigate}
      navigator={navigator}
      tabItems={tabItems}
      iconName="contract"
      subTitle={subTitle || subTitle2}
      subTitle2={subTitle2 && subTitle ? subTitle2 : ''}
      routes={<RouteWrapper default>{routes}</RouteWrapper>}
    />
  );
};

export default observer(ContractTabContainer);
