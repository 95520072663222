import { observer } from 'mobx-react';
import React from 'react';

import { customEntityPropertyValueType } from '@zf/api-types/enums';

import { DeleteIcon } from '../../../../../../../components/Icon';
import FloatInput from '../../../../../../../components/input/FloatInput';
import { InlineInputFieldInput } from '../../../../../../../components/input/InputField';
import NumberInput from '../../../../../../../components/input/NumberInput';
import { Trail } from '../../../../../../../design-system/ComponentSets';
import css from './crud-option-list.module.scss';

export type Props = {
  updateOption: (index: number, option: any) => void;
  removeOption: (index: number) => void;
  options: any[];
  inputType: customEntityPropertyValueType;
};

function CrudOptionList(props: Props) {
  const { updateOption, removeOption, options, inputType } = props;

  const renderInputField = (option: any, index: number) => {
    switch (inputType) {
      case customEntityPropertyValueType.string: {
        return (
          <InlineInputFieldInput
            id={`string-${index}`}
            value={option}
            onChange={(value) => updateOption(index, value as string)}
            hideLabel
          />
        );
      }
      case customEntityPropertyValueType.number: {
        return (
          <NumberInput
            min="0"
            id={`number-${index}`}
            value={option}
            onChange={(value) => updateOption(index, value)}
            hideLabel
          />
        );
      }
      case customEntityPropertyValueType.decimal: {
        return <FloatInput id={`decimal-${index}`} onChange={(value) => updateOption(index, value)} value={option} />;
      }
    }
  };

  return (
    <>
      {options.map((op, index) => (
        <div className={css['conditions-add-options-content-trail']} key={`content-trail-${index}`}>
          <Trail id={`option-trail-${index}`} indentLevel={1} />
          {renderInputField(op, index)}
          {index >= 1 && <DeleteIcon id={`option-delete-${index}`} onClick={() => removeOption(index)} />}
        </div>
      ))}
    </>
  );
}

export default observer(CrudOptionList);
