import BillingCompletenessService from 'app-context/services/billing/BillingCompletenessService';
import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

import {
  AdvancePeriodType,
  AskingPeriod,
  BillingCompletenessType,
  ConsumptionAskingPeriodType,
  ConsumptionReceivingPeriodType,
  MeasurementDetailsType,
  ReceivingPeriod,
  RequiredQuantityLocationGrouped,
  RequiredQuantityType
} from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';
import { ErrorCode, PeriodType } from '@zf/api-types/general';
import { ApproveInvoiceType, InvoiceType } from '@zf/api-types/invoice';
import { splitArrayIntoChunksOfLength } from '@zf/utils/src/array';
import { colors } from '@zf/utils/src/color';
import { isMaxDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import RootStore from '../../../..';
import CreateRequestService from '../../../../../../components/units/List/logic/createRequestService';
import InfiniAPIService from '../../../../../../components/units/List/logic/infiniAPIService';
import { RequestType } from '../../../../../../types/Request';
import BaseStore from '../../../../BaseStore';

export enum completenessActionPermissions {
  mayCloseManually = 'mayCloseManually',
  mayCreateAdvances = 'mayCreateAdvances',
  mayAdjustInvoicePeriod = 'mayAdjustInvoicePeriod',
  mayRecalculate = 'mayRecalculate',
  mayCreateInvoice = 'mayCreateInvoice',
  mayReopen = 'mayReopen',
  mayApprove = 'mayApprove'
}

export type CompletenessFiltersType = {
  periodStartDate: Moment | null;
  periodEndDate: Moment | null;
};

export type BillingCompletenessRowType = {
  __id: string;
  __entity: BillingCompletenessType;
  period: JSX.Element;
  dash: string;
  p_end: string;
  advances: string | JSX.Element;
  status: JSX.Element;
};

export default class BillingCompletenessStore extends BaseStore<BillingCompletenessType> {
  private rootStore: RootStore;

  public filters: CompletenessFiltersType = {
    periodStartDate: null,
    periodEndDate: null
  };
  public completenessApiService: BillingCompletenessService;
  public actionPermissions: Record<completenessActionPermissions, boolean>;
  public billingCompletenesses_: BillingCompletenessType[] | null;
  public selectedCompleteness_: BillingCompletenessType | null;
  public createRequestService: CreateRequestService;
  public infiniAPIService: InfiniAPIService<BillingCompletenessType, BillingCompletenessRowType> | undefined;
  public invoicesForCompleteness: InvoiceType[];
  public processRecord: (record: BillingCompletenessType) => BillingCompletenessRowType;
  public loadingAction: boolean = false;
  public requestBilling: RequestType;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    this.createRequestService = new CreateRequestService();
    this.completenessApiService = new BillingCompletenessService(
      '/bill/BillingCompletenesses/',
      rootStore.applicationStore
    );
    this.setExistingEntity = this.setExistingBillingCompleteness;

    makeObservable(this, {
      filters: observable,
      selectedCompleteness_: observable,
      actionPermissions: observable,
      createRequestService: observable,
      infiniAPIService: observable,
      loadingAction: observable,

      billingCompletenesses: computed,
      selectedCompleteness: computed,
      sortedCompletenesses: computed,
      nextCompleteness: computed,
      latestOpenCompleteness: computed,
      productPeriodReferences: computed,
      productForSelectedCompleteness: computed,
      allSelectedCompletenessErrors: computed,

      generateActionPermissions: action,
      loadBillingCompletenesses: action,
      setSelectedCompleteness: action,
      setFilters: action,
      setInfiniAPIService: action,
      selectOldestIncomplete: action,
      setLoadingAction: action,
      getAddressForLocationRequiredQuantity: action,
      getCorrespondingReceivingPeriods: action,
      getMatchingMeasurement: action,
      deriveMeterSpecs: action,
      getMissingPeriods: action,
      getMatchingValue: action,
      getRequiredQuantityCompleteness: action,
      manuallyClosed: action,
      createIntermediateInvoice: action,
      setSelectedCompleteness_: action,
      getAllErrorsForConsumptionRequiredQuantity: action,
      getAdvancesCompleteness: action,
      createOrRecalculate: action,
      reopen: action,
      checkInvoicePeriodLengthening: action,
      refreshTimeOutAsync: action,
      reset: action
    });
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get billingCompletenesses() {
    return this.billingCompletenesses_ as BillingCompletenessType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get selectedCompleteness() {
    return this.selectedCompleteness_ as BillingCompletenessType;
  }

  get sortedCompletenesses() {
    if (this.infiniAPIService) {
      const completenesses = this.infiniAPIService.rows.map((r) => r.__entity);
      return completenesses.sort(
        (b1, b2) => moment(b1.periodEndDateTime).valueOf() - moment(b2.periodEndDateTime).valueOf()
      );
    }

    return undefined;
  }

  get nextCompleteness() {
    if (this.sortedCompletenesses) {
      let matchingIndex = this.sortedCompletenesses.findIndex((b) => b.id === this.selectedCompleteness_?.id);

      return this.sortedCompletenesses?.[matchingIndex + 1];
    }

    return undefined;
  }

  get latestOpenCompleteness() {
    if (this.sortedCompletenesses) {
      return this.sortedCompletenesses.find(
        (b) => b?.status !== billingCompletenessStatus.closed && b?.status !== billingCompletenessStatus.manuallyclosed
      );
    }

    return undefined;
  }

  get productPeriodReferences() {
    return this.rootStore.contractStore.selectedContract_?.contract.billingDetails.products || [];
  }

  get productForSelectedCompleteness() {
    return this.productPeriodReferences.find((ppr) => ppr.productId === this.selectedCompleteness_?.productId);
  }

  get allSelectedCompletenessErrors() {
    let errors: ErrorCode[] = [];

    if (this.selectedCompleteness.inputMissingDetails) {
      errors = [
        {
          key: '',
          message: this.rootStore.applicationStore.getEnumTranslation(
            'inputMissingReason',
            this.selectedCompleteness.inputMissingDetails.reason
          )
        }
      ];
    }

    const { contractRequiredQuantities, locationRequiredQuantities } =
      this.selectedCompleteness.requiredQuantitiesGrouped;

    if (contractRequiredQuantities) {
      const contractErrors = contractRequiredQuantities.reduce((acc: ErrorCode[], crq) => {
        if (crq.requiredQuantities) {
          acc.push(...crq.requiredQuantities.flatMap((rq) => rq.errors));
        }

        return acc;
      }, []);

      errors.push(...contractErrors);
    }

    if (locationRequiredQuantities) {
      const contractErrors = locationRequiredQuantities.reduce((acc: ErrorCode[], lrq) => {
        if (lrq.requiredQuantities) {
          acc.push(...lrq.requiredQuantities.flatMap((rq) => rq.errors));
        }

        return acc;
      }, []);

      errors.push(...contractErrors);
    }

    return errors;
  }

  setFilters = (newFilters: Partial<CompletenessFiltersType>) => {
    this.filters = { ...this.filters, ...newFilters };
  };

  setSelectedCompleteness_ = (newSelected: BillingCompletenessType | null) => {
    this.selectedCompleteness_ = newSelected;
  };

  setInfiniAPIService = (
    newInfiniAPIService: InfiniAPIService<BillingCompletenessType, BillingCompletenessRowType>
  ) => {
    this.infiniAPIService = newInfiniAPIService;
  };

  setLoadingAction = (isLoading: boolean) => {
    this.loadingAction = isLoading;
  };

  setSelectedCompleteness = async (completenessIds: string[]) => {
    if (this.infiniAPIService) {
      const selected = this.infiniAPIService.rows.find((r) => {
        return r.__id === completenessIds[0];
      });
      this.createRequestService.setSelectedIds(completenessIds);
      if (selected) {
        this.generateActionPermissions(selected.__entity);
        this.invoicesForCompleteness = await this.completenessApiService.getInvoicesForCompleteness(selected.__entity);
      }
      this.setSelectedCompleteness_(selected?.__entity || null);
    }
  };

  generateActionPermissions = (completeness: BillingCompletenessType) => {
    this.actionPermissions = {
      mayCloseManually:
        completeness.status === billingCompletenessStatus.manuallycorrected ||
        completeness.status === billingCompletenessStatus.inputmissing ||
        completeness.status === billingCompletenessStatus.waiting,
      mayCreateAdvances: completeness.advancePeriods.findIndex((ap) => !ap.invoiceId) !== -1,
      mayAdjustInvoicePeriod:
        completeness.status === billingCompletenessStatus.waiting ||
        completeness.status === billingCompletenessStatus.previousnotclosed ||
        completeness.status === billingCompletenessStatus.manuallycorrected ||
        completeness.status === billingCompletenessStatus.inputmissing,
      mayRecalculate: completeness.status === billingCompletenessStatus.closed,
      mayCreateInvoice:
        completeness.status === billingCompletenessStatus.waiting ||
        completeness.status === billingCompletenessStatus.manuallycorrected ||
        completeness.status === billingCompletenessStatus.inputmissing,
      mayReopen: completeness.status === billingCompletenessStatus.manuallyclosed,
      mayApprove: completeness.status === billingCompletenessStatus.waitingforapproval
    };
  };

  manuallyClosed = async (completenessId: string) => {
    const result = await this.executeAction(this.completenessApiService.closeManually(completenessId), true);
    this.infiniAPIService?.updateGivenRows();
    this.generateActionPermissions(result);
    this.refreshTimeOutAsync();
  };

  createIntermediateInvoice = async (completenessId: string, endDate: Moment) => {
    const result = await this.executeAction(
      this.completenessApiService.billIntermediately(completenessId, endDate),
      true
    );
    this.infiniAPIService?.updateGivenRows();
    this.generateActionPermissions(result);
    this.refreshTimeOutAsync();
  };

  changeNextInvoiceEndDate = async (billingRelationId: string, fromFirstOpenPeriod: boolean, endDate: Moment) => {
    const result = await this.executeAction(
      this.completenessApiService.changeNextInvoiceEndDate(billingRelationId, fromFirstOpenPeriod, endDate),
      true
    );
    this.infiniAPIService?.updateGivenRows();
    this.generateActionPermissions(result);
    this.refreshTimeOutAsync();
  };

  approveInvoice = async (value: ApproveInvoiceType, date: string) => {
    await this.rootStore.invoiceStore.invoiceApiService.approveInvoice(value, date);
    this.refreshTimeOutAsync();
  };

  createOrRecalculate = async (
    completenessId: string,
    checkAdvances: boolean,
    recalculateIfNeeded: boolean,
    advanceUntilDateTime: Moment
  ) => {
    const result = await this.executeAction(
      this.completenessApiService.createOrRecalculate(
        completenessId,
        checkAdvances,
        recalculateIfNeeded,
        advanceUntilDateTime
      ),
      true
    );
    this.infiniAPIService?.updateGivenRows();
    this.generateActionPermissions(result);
    this.refreshTimeOutAsync();
  };

  reopen = async (completenessId: string) => {
    const result = await this.executeAction(this.completenessApiService.reopen(completenessId), true);
    this.infiniAPIService?.updateGivenRows();
    this.generateActionPermissions(result);
  };

  setExistingBillingCompleteness = (billingCompleteness: BillingCompletenessType) => {
    this.selectedCompleteness_ = billingCompleteness;
  };

  selectOldestIncomplete = () => {
    //if (this.infiniAPIService) {
    //  if (this.infiniAPIService.rows.length === 1 && this.infiniAPIService.rows[0]) {
    //    this.setSelectedCompleteness([this.infiniAPIService.rows[0].__id]);
    //  } else {
    //    const oldestIncomplete = [...this.infiniAPIService.rows].reverse().find((r) => {
    //      return r.__entity.status !== billingCompletenessStatus.closed;
    //    });
    //
    //    if (oldestIncomplete) {
    //      this.setSelectedCompleteness([oldestIncomplete.__id]);
    //    } else if (this.infiniAPIService.rows[0]) {
    //      this.setSelectedCompleteness([this.infiniAPIService.rows[0].__id]);
    //    }
    //  }
    //}
  };

  selectCompletenessLogic = () => {
    if (this.selectedCompleteness) {
      this.setSelectedCompleteness([this.selectedCompleteness.id]);
    } else {
      if (this.infiniAPIService && this.infiniAPIService.rows[0]) {
        this.setSelectedCompleteness([this.infiniAPIService.rows[0].__id]);
      }
    }
  };

  loadBillingCompletenesses = async (
    processRecord: (record: BillingCompletenessType) => BillingCompletenessRowType,
    billingRelationId: string
  ) => {
    this.processRecord = processRecord;

    this.requestBilling = await this.createRequestService.createRequest({
      endpoint: '/bill/BillingCompletenesses',
      query: {
        billingRelationId: billingRelationId,
        periodStartDateTime: this.filters.periodStartDate?.toISOString() || MIN_DATE,
        periodEndDateTime: this.filters.periodEndDate?.toISOString() || MAX_DATE,
        orderBy: '-PeriodEndDateTime'
      }
    });

    if (!this.infiniAPIService) {
      const infiniAPIService = new InfiniAPIService(this.rootStore, processRecord);
      this.setInfiniAPIService(infiniAPIService);
      await infiniAPIService.fetchRows(this.requestBilling);
      this.selectCompletenessLogic();
    } else {
      await this.infiniAPIService.fetchRows(this.requestBilling);
      this.selectCompletenessLogic();
    }
  };

  getAddressForLocationRequiredQuantity = (locationId: string | null | undefined) => {
    return this.rootStore.contractStore.selectedContract_?.contract.serviceLocations.find((l) => l.id === locationId)
      ?.address?.localizedDisplay;
  };

  getCorrespondingReceivingPeriods = (
    ap: AskingPeriod & { allowDeviatingEnd?: boolean; maxEndDateTime?: string },
    receivingPeriods: ReceivingPeriod[]
  ) => {
    const filteredRp = receivingPeriods.filter((rp) => {
      const askingStart = ap.startDateTime;
      let askingEnd = ap.endDateTime;

      if (ap.allowDeviatingEnd) {
        askingEnd = ap.maxEndDateTime || MAX_DATE;
      }

      const receivingStart = moment(rp.startDateTime);
      const receivingEnd = moment(rp.endDateTime);

      return receivingStart.isSameOrAfter(askingStart) && receivingEnd.isSameOrBefore(askingEnd);
    });

    return filteredRp;
  };

  getMatchingMeasurement = (
    type: 'start' | 'end',
    askingPeriod: ConsumptionAskingPeriodType,
    receivingPeriod: ConsumptionReceivingPeriodType | undefined
  ): MeasurementDetailsType | null => {
    let resMsmt: MeasurementDetailsType | null = null;

    if (receivingPeriod) {
      if (type === 'start') {
        if (
          receivingPeriod.earliestMeasurement &&
          receivingPeriod.earliestMeasurement.endDateTime === askingPeriod.startDateTime
        ) {
          resMsmt = receivingPeriod.earliestMeasurement;
        }
      } else {
        if (askingPeriod.allowDeviatingEnd) {
          if (
            receivingPeriod.latestMeasurement &&
            moment(receivingPeriod.latestMeasurement.endDateTime).isBetween(
              askingPeriod.minEndDateTime,
              askingPeriod.maxEndDateTime,
              undefined,
              '[]'
            )
          ) {
            resMsmt = receivingPeriod.latestMeasurement;
          }
        } else {
          if (
            receivingPeriod.latestMeasurement &&
            receivingPeriod.latestMeasurement.endDateTime === askingPeriod.endDateTime
          ) {
            resMsmt = receivingPeriod.latestMeasurement;
          }
        }
      }
    } else if (askingPeriod.foundMeter) {
      const foundMeter = askingPeriod.foundMeter;

      if (type === 'start') {
        if (
          foundMeter.foundStartMeasurement &&
          foundMeter.foundStartMeasurement.measurementDateTime === askingPeriod.startDateTime
        ) {
          // Map object for compatibility
          resMsmt = {
            meterSerialNumber: foundMeter.meterSerialNumber,
            value: foundMeter.foundStartMeasurement.value,
            endDateTime: foundMeter.foundStartMeasurement.measurementDateTime,
            timeZone: '',
            meteringType: foundMeter.meteringType,
            utilityType: foundMeter.utilityType,
            unitOfMeasure: foundMeter.unitOfMeasure,
            timeOfUse: foundMeter.timeOfUse
          };
        }
      } else {
        if (askingPeriod.allowDeviatingEnd) {
          if (
            foundMeter.foundEndMeasurement &&
            moment(foundMeter.foundEndMeasurement.measurementDateTime).isBetween(
              askingPeriod.minEndDateTime,
              askingPeriod.maxEndDateTime,
              undefined,
              '[]'
            )
          ) {
            // Map object for compatibility
            resMsmt = {
              meterSerialNumber: foundMeter.meterSerialNumber,
              value: foundMeter.foundEndMeasurement.value,
              endDateTime: foundMeter.foundEndMeasurement.measurementDateTime,
              timeZone: '',
              meteringType: foundMeter.meteringType,
              utilityType: foundMeter.utilityType,
              unitOfMeasure: foundMeter.unitOfMeasure,
              timeOfUse: foundMeter.timeOfUse
            };
          }
        } else {
          if (
            foundMeter.foundEndMeasurement &&
            foundMeter.foundEndMeasurement.measurementDateTime === askingPeriod.endDateTime
          ) {
            // Map object for compatibility
            resMsmt = {
              meterSerialNumber: foundMeter.meterSerialNumber,
              value: foundMeter.foundEndMeasurement.value,
              endDateTime: foundMeter.foundEndMeasurement.measurementDateTime,
              timeZone: '',
              meteringType: foundMeter.meteringType,
              utilityType: foundMeter.utilityType,
              unitOfMeasure: foundMeter.unitOfMeasure,
              timeOfUse: foundMeter.timeOfUse
            };
          }
        }
      }
    }

    return resMsmt;
  };

  getMatchingValue = (date: string, receivingPeriods: Array<ReceivingPeriod & { value: number }>): number | null => {
    let resValue: number | null = null;

    receivingPeriods.forEach((rp) => {
      if (rp.endDateTime === date) {
        resValue = rp.value;
      }
      if (rp.startDateTime === date) {
        resValue = rp.value;
      }
    });

    return resValue;
  };

  deriveMeterSpecs = (
    askingPeriod: ConsumptionAskingPeriodType,
    correspondingReceivingPeriod: ConsumptionReceivingPeriodType | undefined
  ) => {
    let meterSerialNr = '';
    let externalChannelIdentifier = '';

    if (correspondingReceivingPeriod) {
      meterSerialNr = correspondingReceivingPeriod.meterSerialNumber;
      externalChannelIdentifier = correspondingReceivingPeriod.externalChannelIdentifier;
    } else if (askingPeriod.foundMeter) {
      meterSerialNr = askingPeriod.foundMeter.meterSerialNumber;
      externalChannelIdentifier = askingPeriod.foundMeter.externalChannelIdentifier;
    }

    return { meterSerialNr, externalChannelIdentifier };
  };

  getMissingPeriods = (
    askingPeriods: ConsumptionAskingPeriodType[],
    receivingPeriods: ConsumptionReceivingPeriodType[]
  ) => {
    const periodDates = askingPeriods.reduce((acc: string[], ap) => {
      const correspondingReceivingPeriods = this.getCorrespondingReceivingPeriods(
        ap,
        receivingPeriods
      ) as ConsumptionReceivingPeriodType[];

      correspondingReceivingPeriods.forEach((rp) => {
        if (!rp.latestMeasurement && ap.requiresMeasurementOnEnd) {
          acc.push(rp.startDateTime);
        }

        if (!rp.earliestMeasurement && ap.requiresMeasurementOnStart) {
          acc.push(rp.endDateTime);
        }
      });

      if (correspondingReceivingPeriods.length === 0) {
        if (ap.requiresMeasurementOnStart) {
          acc.push(ap.startDateTime);
        }

        if (ap.requiresMeasurementOnEnd) {
          acc.push(ap.endDateTime);
        }
      }

      return acc;
    }, []);

    const splitted = splitArrayIntoChunksOfLength(periodDates, 2);

    const periods: PeriodType[] = splitted.map((dates) => {
      return {
        startDate: moment(dates[0]),
        endDate: moment(dates[1])
      };
    });

    return periods;
  };

  getAllErrorsForConsumptionRequiredQuantity = (requiredQuantity: RequiredQuantityType) => {
    const errors = [...requiredQuantity.errors];

    requiredQuantity.askingPeriods.forEach((ap) => {
      const casted = ap as ConsumptionAskingPeriodType;

      if (casted.inputMissingReason) {
        const reason = this.rootStore.applicationStore.getEnumTranslation(
          'inputMissingReason',
          casted.inputMissingReason
        );

        errors.push({
          key: '',
          message: reason
        });
      }
    });

    return errors;
  };

  getPeriodExtremaForRequiredQuantityGrouped = (locationRequiredQuantityGrouped: RequiredQuantityLocationGrouped) => {
    const allAskingDates =
      locationRequiredQuantityGrouped.requiredQuantities?.flatMap((rq) =>
        rq.askingPeriods.flat().flatMap((ap) => [moment(ap.startDateTime), moment(ap.endDateTime)])
      ) || [];

    return { min: moment.min(allAskingDates), max: moment.max(allAskingDates) };
  };

  getAdvancesCompleteness = (advancePeriods: AdvancePeriodType[]) => {
    let currentAdvancesCount = 0;
    let totalAdvancesCount = 0;

    advancePeriods.forEach((ap) => {
      if (ap.invoiceId) {
        currentAdvancesCount++;
      }

      // Do not count automaticallyDeleted
      if (!ap.automaticallyDeleted) {
        totalAdvancesCount++;
      }
    });

    const advanceCompleteness = (currentAdvancesCount / totalAdvancesCount) * 100;

    return { currentAdvancesCount, totalAdvancesCount, advanceCompleteness };
  };

  getRequiredQuantityCompleteness = (requiredQuantity: RequiredQuantityType) => {
    const getTranslation = this.rootStore.applicationStore.getTranslation;

    let title = getTranslation('general.na');
    let color = colors['graphite'];

    if (
      this.selectedCompleteness_ &&
      this.selectedCompleteness_.status !== billingCompletenessStatus.waiting &&
      this.selectedCompleteness_.status !== billingCompletenessStatus.previousnotclosed
    ) {
      if (requiredQuantity.complete) {
        title = getTranslation('contracts.complete');
        color = colors['green-600'];
      } else {
        title = getTranslation('contracts.incomplete');
        color = colors['red-600'];
      }
    }

    return { title, color };
  };

  checkInvoicePeriodLengthening = (periodEndDateTime: Moment | string) => {
    let lengthenNotPossible = false;
    let hasDifferentProductInFuture = false;

    // Ignore checks when no next invoice period
    if (!this.nextCompleteness) {
      return { lengthenNotPossible, hasDifferentProductInFuture };
    }

    hasDifferentProductInFuture =
      (!isMaxDate(periodEndDateTime) &&
        this.sortedCompletenesses?.some(
          (c) =>
            c.productId !== this.selectedCompleteness_?.productId &&
            moment(c.periodStartDateTime).isAfter(this.selectedCompleteness_?.periodStartDateTime) &&
            moment(periodEndDateTime).isAfter(c.periodStartDateTime)
        )) ||
      false;

    // Specific case where only showing an input validation error might be bad UX
    lengthenNotPossible = this.nextCompleteness?.productId !== this.selectedCompleteness_?.productId;

    return { lengthenNotPossible, hasDifferentProductInFuture };
  };

  reset = () => {
    this.filters = {
      periodStartDate: null,
      periodEndDate: null
    };
    this.actionPermissions = {} as Record<completenessActionPermissions, boolean>;
    this.billingCompletenesses_ = null;
    this.selectedCompleteness_ = null;
    this.infiniAPIService = undefined;
    this.invoicesForCompleteness = [];
  };

  refreshTimeOutAsync = () => {
    this.setLoadingAction(true);
    window.setInterval(async () => {
      if (this.selectedCompleteness_) {
        const result = await this.completenessApiService.getBillingCompleteness(
          this.selectedCompleteness_.billingRelationId,
          this.filters
        );

        const apiValue = result.find((e) => e.id === this.selectedCompleteness_?.id);

        if (apiValue?._etag !== this.selectedCompleteness_._etag) {
          await this.loadBillingCompletenesses(this.processRecord, this.selectedCompleteness_.billingRelationId);
        }
      }
      this.setLoadingAction(false);
    }, 3000);
  };
}
