import { notify } from 'events/notification-events';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { RequiredQuantityContractGrouped } from '@zf/api-types/billing/billing-completeness';
import Collapsable from '@zf/stella-react/src/atoms/Collapsable/Collapsable';
import AlertCircle from '@zf/stella-react/src/atoms/Info/AlertCircle';

import { SuspenseSpinner } from '../../../../../../components/suspense';
import { useStore } from '../../../../../../hooks/useStore';
import BillingItem from './BillingItem';
import ErrorsTip from './ErrorsTip';
import css from './right-body.module.scss';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

type Props = {
  requiredQuantityContractGrouped: RequiredQuantityContractGrouped;
};

const ContractRequiredQuantity = (props: Props) => {
  const { requiredQuantityContractGrouped } = props;

  const { contractStore, productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getBillingItemForId } = productConfigStore.productConfigService;
  const { selectedCompleteness, productForSelectedCompleteness } = contractStore.billingCompletenessStore;

  const [billingItemName, setBillingItemName] = useState('');

  const { billingItemId } = requiredQuantityContractGrouped;

  useEffect(() => {
    if (billingItemId) {
      try {
        getBillingItemForId(billingItemId).then((res) => setBillingItemName(res.name));
      } catch (error) {
        notify.error({
          content: getTranslation('billing_items.get_name_fail'),
          error
        });
      }
    }
  }, [billingItemId]);

  const errors = requiredQuantityContractGrouped.requiredQuantities?.flatMap((rq) => rq.errors) || [];

  return (
    <Collapsable
      className={css['collapsable']}
      title=" "
      defaultClosed={false}
      extraLeft={
        <FlexElements justifyContent="space-between" alignItems="center">
          <Paragraph bold>{billingItemName}</Paragraph>
          {errors.length > 0 ? (
            <AlertCircle>
              <ErrorsTip errors={errors} />
            </AlertCircle>
          ) : undefined}
        </FlexElements>
      }
    >
      <div className={css['billing-items']}>
        {requiredQuantityContractGrouped.requiredQuantities?.map((rq, index) => {
          return (
            <SuspenseSpinner key={rq.billingItemId}>
              <BillingItem
                productId={productForSelectedCompleteness?.productName || ''}
                requiredQuantity={rq}
                currentCompletenessStatus={selectedCompleteness.status}
                defaultClosed={index !== 0}
              />
            </SuspenseSpinner>
          );
        })}
      </div>
    </Collapsable>
  );
};

export default observer(ContractRequiredQuantity);
