import classNames from 'classnames';
import React from 'react';

import { Label } from '@zf/stella-react/src/atoms/Label';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { BaseLink } from '../../design-system/Components';
import Icon from '../Icon/Icon';
import css from './nav-list-item.module.scss';

export type PropsType = {
  id: string;
  title?: string;
  iconType: string;
  className?: string;
  active?: boolean;
  href: string;
  onClick?: () => void;
  classNameIcon?: string;
};

export default function NavListItem(props: PropsType) {
  const { id, title, iconType, active, href, className } = props;

  const listItemLink = (
    <li className={css['list-item']}>
      <BaseLink
        id={id}
        url={href}
        className={classNames(css['nav-list-item'], className, {
          [css['active']]: active
        })}
      >
        <Icon type={iconType} />
      </BaseLink>
    </li>
  );

  return title ? (
    <TooltipTrigger tooltip={<Label className={css['label']}>{title}</Label>} placement="right">
      {listItemLink}
    </TooltipTrigger>
  ) : (
    listItemLink
  );
}
