import { Paragraph } from 'design-system/Foundation';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { utilityType } from '@zf/api-types/enums';
import { ContractServiceType } from '@zf/api-types/forecasting';
import { ServiceType } from '@zf/api-types/master-data/servicelocation';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import Center from '@zf/stella-react/src/helpers/Center';
import { groupBy, onlyUniqueObjects } from '@zf/utils/src/array';
import { formatPeriod } from '@zf/utils/src/date';

import YearlyEstimates from '../../../../../cards/Services/YearlyEstimates';
import { Icon } from '../../../../../components/Icon';
import IconParagraph from '../../../../../components/Icon/IconParagraph';
import { ICON_COLORS } from '../../../../../constants/icons';
import { Link } from '../../../../../design-system/Components';
import { useStore } from '../../../../../hooks/useStore';
import { getCurrentContract } from '../../../../../utils/location';
import { getServiceStatus } from '../../../../../utils/serviceState';
import css from './active-service.module.scss';

type Props = {
  locationId: string;
  service: ServiceType;
};

const ActiveService = (props: Props) => {
  const { locationId, service } = props;
  const { applicationStore, serviceLocationStore } = useStore();
  const { getEavsForContract, eavs, setEavs } = serviceLocationStore.forecastingService;
  const { rootUrl, getTranslation, getEnumTranslation } = applicationStore;

  const currentContract = getCurrentContract(service);
  const activeStatus = getServiceStatus(service.statusHistory);

  useEffect(() => {
    if (currentContract && currentContract.contractId) {
      getEavsForContract(currentContract?.contractId).then((res) => setEavs(res));
    }
  }, []);

  const contractServicesGroupedByUtility: Record<utilityType, ContractServiceType[]> = groupBy(
    onlyUniqueObjects(eavs?.contractServices.filter((cs) => cs.serviceLocationId === locationId) || []),
    'utilityType'
  );

  return (
    <CardItem width="12">
      <div className={css['service-header']}>
        <div className={css['icon']}>
          <Center className={css['icon-utilitytype']}>
            <Icon
              id={service.utilityType}
              title={getEnumTranslation('utilityType', service.utilityType)}
              type={service.utilityType}
              color
            />
          </Center>
        </div>
        <div className={css['content']}>
          <div className={css['name']}>
            <Paragraph bold>{getEnumTranslation('utilityType', service.utilityType)}</Paragraph>
            <div className={css['status']}>
              <NewStatusBadge
                color={ICON_COLORS[activeStatus?.serviceStatus || '']}
                status_={getEnumTranslation('serviceStatus', activeStatus?.serviceStatus || '')}
              />
            </div>
            <div className={css['range']}>
              {currentContract && formatPeriod(currentContract.supplyStartDateTime, currentContract.supplyEndDateTime)}
            </div>
          </div>
          {currentContract ? (
            <>
              <FlexElements>
                <Label>{getTranslation('contracts.contract')}</Label>
                <IconParagraph
                  className={css['contract-icon-p']}
                  iconType={currentContract.currentContractStatus}
                  iconPosition="right"
                  color
                >
                  <Link
                    id={`contract-${currentContract.contractId}`}
                    url={`${rootUrl}/contracts/${currentContract.contractId}`}
                  >
                    {currentContract.contractNumber}
                  </Link>
                </IconParagraph>
              </FlexElements>

              <FlexElements>
                <Label>{getTranslation('cards.service.external_id')}</Label>
                <Paragraph className={css['link']} textAlign="right">
                  {service.externalIdentifier}
                </Paragraph>
              </FlexElements>

              <YearlyEstimates matchingContractServices={contractServicesGroupedByUtility[service.utilityType] || []} />
            </>
          ) : (
            <>
              <FlexElements>
                <Label>{getTranslation('contracts.contract')}</Label>
                <Paragraph textAlign="right">{getTranslation('cards.service.no_contracts')}</Paragraph>
              </FlexElements>
              <FlexElements>
                <Label>{getTranslation('cards.service.external_id')}</Label>
                <Paragraph className={css['link']} textAlign="right" id={service.utilityType}>
                  {service.externalIdentifier}
                </Paragraph>
              </FlexElements>
            </>
          )}
        </div>
      </div>
    </CardItem>
  );
};

export default observer(ActiveService);
