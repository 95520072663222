import { PagedResponseType } from '@zf/api-types/api';
import { utilityType } from '@zf/api-types/enums';
import { ConsumerGroupType } from '@zf/api-types/forecasting';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';

import BaseService from '../../../app-context/services/BaseService';
import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { METHODS } from '../../../utils/request';

export default class PropertyGroupService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/md/propertyGroups/', applicationStore);
  }

  getPropertygroups = async () => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<PropertyGroupType>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint
        }
      })
    ).data.results;
  };

  getConsumerGroupsForUtilityType = async (utilityType: utilityType) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<ConsumerGroupType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/fct/ConsumerGroups',
          query: {
            utilityType
          }
        }
      })
    ).data.results;
  };

  getPropertyGroupBillingConfiguration = async (propertyGroupId: string) => {
    return (
      await this.applicationStore.sendRequest<PropertyGroupBillingConfigurationType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroupId}`
        }
      })
    ).data;
  };
}
