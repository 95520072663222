import moment from 'moment';
import React from 'react';

import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { MAX_DATE_YEAR, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import {
  getBillingInsightsOverdueDescription,
  getInvoiceOverdueColor,
  getInvoiceOverdueDescription
} from '../../utils/invoice';
import css from './days-overdue-badge.module.scss';

type Props = {
  referenceDate: string;
  className?: string;
  type?: 'billing-insights' | 'invoice';
  tooltipFor: string;
};

export default React.memo(function DaysOverdueBadge(props: Props) {
  const { referenceDate, tooltipFor, type = 'billing-insights', className } = props;
  const { i18n } = useAppContext();

  const year = moment(referenceDate).year();

  if (year >= MAX_DATE_YEAR || year === moment(MIN_DATE).year()) return null;

  const amountOfDays = moment().diff(moment(referenceDate), 'days');

  const color = getInvoiceOverdueColor(amountOfDays);

  const getDescription = () => {
    if (type === 'invoice') {
      return getInvoiceOverdueDescription(i18n, amountOfDays);
    } else {
      return getBillingInsightsOverdueDescription(i18n, amountOfDays);
    }
  };

  return (
    <div data-tip={getDescription()} data-for={tooltipFor} className={className}>
      <StatusBadge className={css['days-overdue-badge']} type="bare" color={color}>
        {i18n.getTranslation('analytics.days', { amountOfDays })}
      </StatusBadge>
    </div>
  );
});
