import { AddressFormType } from '@zf/api-types/general';
import BaseFormVjf, { Fields } from '../../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../../events/notification-events';
import SocialTariffStore from '../../stores/SocialTariffStore';

export enum socialTariffConfigFormFields {
  gln = 'gln',
  kbo = 'kbo',
  socialTariffIndicator = 'socialtariffindicator',
  visibilitygovernment = 'visibilitygovernment',
  firstname = 'firstname',
  lastname = 'lastname',
  email = 'email',
  fax = 'fax',
  telephonenumber = 'telephonenumber',
  supplieraddress = 'supplieraddress',
  country = 'country',
  city = 'city',
  streetname = 'streetname',
  streetnumber = 'streetnumber',
  streetnumberaddition = 'streetnumberaddition',
  postalcode = 'postalcode',
  iseditingaddress = 'iseditingaddress',
  bimcustomer = 'bimcustomer'
}

export type SocialTariffFields = {
  gln: string;
  kbo: string;
  socialTariffIndicator: string;
  visibilityGovernment: string;
  firstname: string;
  lastname: string;
  email: string;
  fax: string;
  telephonenumber: string;
  supplierAddress: AddressFormType;
  bimCustomer: string;
};

class SocialTariffConfigForm extends BaseFormVjf<SocialTariffFields> {
  public socialTariffStore: SocialTariffStore;
  constructor(values: SocialTariffFields, socialTariffStore: SocialTariffStore) {
    super(values);
    this.socialTariffStore = socialTariffStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: socialTariffConfigFormFields.gln,
          label: 'gln',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.kbo,
          label: 'kbo',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.visibilitygovernment,
          label: 'visibilityGovernment',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.socialTariffIndicator,
          label: 'socialTariffIndicator',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.bimcustomer,
          label: 'bimCustomer',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.firstname,
          label: 'firstName',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.lastname,
          label: 'lastName',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.email,
          label: 'email',
          type: 'text',
          validators: [this.isRequired, this.isEmail]
        },
        {
          name: socialTariffConfigFormFields.fax,
          label: 'fax',
          type: 'text'
        },
        {
          name: socialTariffConfigFormFields.telephonenumber,
          label: 'telephoneNumber',
          type: 'text',
          validators: [this.isRequired]
        },
        {
          name: socialTariffConfigFormFields.supplieraddress,
          label: 'supplierAddress',
          fields: [
            {
              label: 'postalCode',
              name: socialTariffConfigFormFields.postalcode,
              type: 'text',
              validators: [this.isRequired]
            },
            {
              label: 'streetName',
              name: socialTariffConfigFormFields.streetname,
              type: 'text',
              validators: [this.isRequired]
            },
            {
              label: 'streetNumber',
              name: socialTariffConfigFormFields.streetnumber,
              type: 'text',
              validators: [this.isRequired]
            },
            {
              name: socialTariffConfigFormFields.iseditingaddress,
              label: 'isEditingAddress',
              type: 'checkbox'
            },
            {
              label: 'streetNumberAddition',
              name: socialTariffConfigFormFields.streetnumberaddition,
              type: 'text'
            },
            {
              label: 'country',
              name: socialTariffConfigFormFields.country,
              type: 'text',
              validators: [this.isRequired]
            },
            {
              label: 'city',
              name: socialTariffConfigFormFields.city,
              type: 'text',
              validators: [this.isRequired]
            }
          ]
        }
      ]
    };
  }

  submit = async () => {
    try {
      const {
        gln,
        kbo,
        visibilityGovernment,
        socialTariffIndicator,
        supplierAddress,
        email,
        fax,
        firstname,
        lastname,
        telephonenumber,
        bimCustomer
      } = this._values;
      const result =
        await this.socialTariffStore.countrySpecificStore.countrySpecificService.updateRegionalRegulationParams({
          glnId: gln,
          kboNumber: kbo,
          sharePropsWithGovernmentCustomEntityPropertyId: visibilityGovernment,
          socialTariffCustomEntityPropertyId: socialTariffIndicator,
          supplierAddress: {
            city: supplierAddress.city,
            streetNumber: supplierAddress.streetNumber,
            streetName: supplierAddress.streetName,
            streetNumberAddition: supplierAddress.streetNumberAddition,
            postalCode: supplierAddress.postalCode,
            country: supplierAddress.country
          },
          contactEmailAddress: email,
          contactFaxNumber: fax,
          contactFirstName: firstname,
          contactLastName: lastname,
          contactTelephoneNumber: telephonenumber,
          bimCustomEntityPropertyId: bimCustomer
        });

      this.socialTariffStore.initSocialTariffForm(result);

      notify.success({
        content: this.socialTariffStore.countrySpecificStore.rootStore.applicationStore.getTranslation(
          'country_specifics.reg_params_success'
        )
      });
    } catch (e) {
      notify.error({
        content: this.socialTariffStore.countrySpecificStore.rootStore.applicationStore.getTranslation(
          'country_specifics.reg_params_failed'
        ),
        error: e
      });
    }
  };

  hooks() {
    return {
      onSuccess: this.submit
    };
  }
}

export default SocialTariffConfigForm;
