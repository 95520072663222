import classNames from 'classnames';
import React from 'react';

import css from './shadow-container.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function ShadowContainer(props: Props) {
  const { children, className } = props;

  return <div className={classNames(css['shadow-container'], className)}>{children}</div>;
}
