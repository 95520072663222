import React from 'react';

import createContext from './create-context';
import useAuthorization, { useAuthorizationReturnType } from './hooks/use-authorization';
import useEnum, { EnumReturnValue } from './hooks/use-enum';
import useLang, { LangReturnValue } from './hooks/use-lang';
import useTenantReducer, { TenantReturnValue } from './hooks/use-tenant-reducer';

type ProviderProps = {
  children: React.ReactNode;
};

type ProviderValue = {
  i18n: LangReturnValue;
  enumReducer: EnumReturnValue;
  tenantReducer: TenantReturnValue;
  authReducer: useAuthorizationReturnType;
};

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export function Provider(props: ProviderProps) {
  const { children } = props;

  const tenantReducer = useTenantReducer();
  const i18n = useLang();
  const enumReducer = useEnum(i18n);
  const { authReducer, userAuthError } = useAuthorization(tenantReducer.state.tenant, tenantReducer.state.organization);

  if (userAuthError) throw userAuthError;

  return <ReactProvider value={{ i18n, enumReducer, tenantReducer, authReducer }}>{children}</ReactProvider>;
}

export const useAppContext = useContext;
export const Consumer = ReactConsumer;
