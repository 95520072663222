import React, { Dispatch, SetStateAction } from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';

import InstallMeterMultiValue from '../meter-multivalue/InstallMeterMultiValue';
import { InstallationType } from './InstallMeterWizard';

type Props = {
  locationType: 'location' | 'property_group';
  meter?: MeterType | null;
  measurementSuccesses: Array<boolean>;
  createNewMeter: boolean;
  propertyGroup?: PropertyGroupType;
  setInstallations: (installations: InstallationType[]) => void;
  setFeedback: Dispatch<SetStateAction<string[][]>>;
  setCreateNewMeter: Dispatch<SetStateAction<boolean>>;
  onFocusStep: Dispatch<SetStateAction<string>>;
  setCreateNewMeasurement?: Dispatch<SetStateAction<boolean>>;
  setMeasurementSuccesses: (successes: boolean[]) => void;
};

export default function InstallMeterSection(props: Props) {
  const { onFocusStep } = props;

  return <InstallMeterMultiValue {...props} handleFocus={() => onFocusStep('select_meter')} />;
}
