import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useState } from 'react';

import { BillingRelationType } from '@zf/api-types/billing-relation';
import { contractStatus } from '@zf/api-types/enums';
import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import useCurrent from '../../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../../design-system/ComponentSets';
import TerminateContractDialog from '../../dialogs/move-out/terminate-dialog';
import TerminateContractListItem from '../detail-page/TerminateContractListItem';

type Props = {
  refreshCounts: () => void;
};

export default function TerminateContractListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { myCurrent } = useCurrent('contract');
  const selectedRows: ContractRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<ContractType>('contract');
  const { clickRef, validationRef, onSubmit } = useDialog();

  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getBillingRelation } = contractStore.contractApiService;

  const [billingRelation, setBillingRelation] = useState<BillingRelationType | null>();

  useEffect(() => {
    if (selectedRows.length === 1) {
      const contract = selectedRows[0].__contractEntity;

      getBillingRelation(contract.id, contract.contractor.customerId)
        .then((res) => setBillingRelation(res))
        .catch((error) => {
          notify.error({
            content: getTranslation('contracts.get_billing_relation_fail'),
            error
          });
        });
    }
  }, [selectedRows.length]);

  // Use detail page move out wizard when only 1 row is selected
  if (selectedRows.length === 1) {
    const contract = selectedRows[0].__contractEntity;

    if (typeof billingRelation === 'undefined') return null;

    return (
      <TerminateContractListItem
        contract={contract}
        billingRelation={billingRelation}
        mayTerminateContract={contract.currentContractStatus !== contractStatus.cancelled}
      />
    );
  }

  const disabled =
    selectedRows.length === 0 ||
    !!selectedRows.find(
      (contract) => !contract || (contract && contract.__currentContractStatus !== contractStatus.signed)
    );

  return (
    <DropdownAction
      id="contract.terminate"
      icon="move-out2"
      dialogTitle={getTranslation('actions.terminate')}
      dialogContent={
        <TerminateContractDialog
          setUpdatedRows={setUpdatedRows}
          ref={clickRef}
          selectedRows={selectedRows}
          validationRef={validationRef}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      dialogType="danger"
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={getTranslation('actions.terminate')}
    />
  );
}
