import { useState, useLayoutEffect } from 'react';
import useWindowSize from './useWindowSize';

export function useScaleTranslator(): number {
  const [width] = useWindowSize();
  const [translateFactor, setTranslateFactor] = useState<number>(1);

  useLayoutEffect(() => {
    let currentValue;

    if (width <= 1440) {
      currentValue = 0.8;
    } else if (width > 1440 && width <= 1920) {
      currentValue = 0.9;
    } else {
      currentValue = 1;
    }

    setTranslateFactor(currentValue);
  }, [width]);

  return translateFactor;
}
