import React from 'react';

import { APISettingsType, UpdateFileFormatType } from '@zf/api-types/file-format';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import FileFormatsContent from './file-formats-content';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';

type Props = {
  config: UseConfigReturnType<UpdateFileFormatType[]>;
  settings: APISettingsType[];
};

export default function FileFormats(props: Props) {
  const { config, settings } = props;

  const { Provider, useTracked } = useCreateContext<UpdateFileFormatType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <FileFormatsContent {...props} settings={settings} useTracked={useTracked} />
    </Provider>
  );
}
