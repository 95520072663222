import React from 'react';
import classNames from 'classnames';

import css from '../formula-builder.module.scss';
import Draggeable from '@zf/stella-react/src/atoms/DragAndDrop/draggeable';

type Props = {
  operator: string;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  onClick: () => void;
};

export default function Operator(props: Props) {
  const { operator, onDragStart, onDragEnd, onClick } = props;

  return (
    <Draggeable onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {({ isDragging }) => {
        return (
          <div
            className={classNames(css['operator'], { [css['dragging']]: isDragging })}
            onClick={onClick}
            role="button"
            tabIndex={-1}
            onKeyDown={onClick}
          >
            {operator}
          </div>
        );
      }}
    </Draggeable>
  );
}
