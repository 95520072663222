import React from 'react';

import { MeteringAnalyticsType } from '@zf/api-types/analytics/metering';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import SingleBarChart from '@zf/stella-react/src/atoms/Chart/SingleBarChart';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';

import { useAppContext } from '../../../../../../app-context';

import { colors } from '@zf/utils/src/color';

type Props = {
  meteringAnalytics: MeteringAnalyticsType;
};

export default function MeteringUntilChartCard(props: Props) {
  const { meteringAnalytics } = props;
  const { i18n } = useAppContext();

  const meteringUntilInsights = meteringAnalytics.meteringUntilDataInsights;

  let hasData = false;
  let chartData: ChartDataType[] = [];

  if (meteringUntilInsights) {
    chartData = [
      {
        name: i18n.getTranslation('dashboard.charts.longerThanTwoMonths'),
        Amount: meteringUntilInsights.longerThanTwoMonths,
        color: colors['blue-400'],
        range: 'longerThanTwoMonths'
      },
      {
        name: i18n.getTranslation('dashboard.charts.lastTwoMonths'),
        Amount: meteringUntilInsights.lastTwoMonths,
        color: colors['blue-400'],
        range: 'lastTwoMonths'
      },
      {
        name: i18n.getTranslation('dashboard.charts.lastMonth'),
        Amount: meteringUntilInsights.lastMonth,
        color: colors['blue-400'],
        range: 'lastMonth'
      },
      {
        name: i18n.getTranslation('dashboard.charts.lastTwoWeeks'),
        Amount: meteringUntilInsights.lastTwoWeeks,
        color: colors['blue-600'],
        range: 'lastTwoWeeks'
      }
    ];

    hasData = !(
      meteringUntilInsights.lastMonth === 0 &&
      meteringUntilInsights.lastTwoMonths === 0 &&
      meteringUntilInsights.lastTwoWeeks === 0 &&
      meteringUntilInsights.longerThanTwoMonths === 0
    );
  }

  return (
    <BarChartCard subject="metering_until" title={i18n.getTranslation('property_groups.tabs.metering.metering_until')}>
      {hasData ? (
        <SingleBarChart
          subject="metering_until"
          chartData={chartData}
          YdataKey="Amount"
          XdataKey="name"
          locale={i18n.culture}
        />
      ) : (
        <EmptyChartState title={i18n.getTranslation('property_groups.tabs.metering.no_metering_yet')} />
      )}
    </BarChartCard>
  );
}
