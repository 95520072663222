import React from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import ResolveWaitingDialog from '../../../actions/tasks/ResolveWaitingDialog';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  refreshCounts: () => void;
};

export default function ResolveWaitingListPageButton(props: Props) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { myCurrent } = useCurrent('moverequest');
  const selectedRows: TaskRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<MoveRequestType>('moverequest');
  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    !!selectedRows.find(
      (r) =>
        r.__entity.status !== moveRequestStatus.pendingatcustomer &&
        r.__entity.status !== moveRequestStatus.pendingatsupplier
    );

  return (
    <DropdownAction
      id="tasks.setnew"
      icon="reset"
      dialogTitle={getTranslation('tasks.resolve_waiting')}
      dialogContent={
        <ResolveWaitingDialog
          {...props}
          ref={clickRef}
          validationRef={validationRef}
          selectedRows={selectedRows}
          setUpdatedRows={setUpdatedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      validationRef={validationRef}
      buttonPositive={getTranslation('general.submit')}
    />
  );
}
