import React from 'react';
import TagGroup from './tag-group';
import classNames from 'classnames';
import css from './filter-tags.module.scss';

export type Props = {
  tags: React.ReactNode[];
  className?: string;
};

export default function FilterTags(props: Props) {
  const { tags, className } = props;

  let groupedTags: Array<React.ReactNode | undefined> = [];
  let shownTags: Array<React.ReactNode | undefined> = [];

  if (tags.length > 3) {
    groupedTags = tags.slice(2);
    shownTags = tags.slice(0, 2);
  } else {
    shownTags = tags;
  }

  return (
    <div className={classNames(css['filter-tags'], className)}>
      {shownTags}
      {groupedTags.length > 0 && <TagGroup tags={groupedTags} />}
    </div>
  );
}
