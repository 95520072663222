import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { CostComponentType } from '@zf/api-types/product';
import Draggeable from '@zf/stella-react/src/atoms/DragAndDrop/draggeable';
import { colors, generateColor } from '@zf/utils/src/color';

import { Paragraph } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import { InlineFloatInput } from '../../input/FloatInput';
import css from '../formula-builder.module.scss';

type Props = {
  costComponent: Partial<CostComponentType>;
  fixedValue?: string | number;
  index: number;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  onClick: () => void;
  setFixedValue?: (val: string | number) => void;
};

const CostComponent = (props: Props) => {
  const { costComponent, index, fixedValue, onDragStart, onDragEnd, onClick, setFixedValue } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const isFixedValue = costComponent.id === 'fixed_value';

  const style = useMemo(() => {
    return { borderLeftColor: isFixedValue ? colors['blue-200'] : generateColor(costComponent.name) };
  }, [costComponent.name, isFixedValue]);

  const content =
    isFixedValue && setFixedValue ? (
      <InlineFloatInput
        id={`fixed-value-${index}`}
        value={fixedValue as number}
        placeholder={getTranslation('product_config.value_desc')}
        onChange={(val) => (setFixedValue ? setFixedValue(val) : {})}
      />
    ) : (
      <Paragraph>{costComponent.description}</Paragraph>
    );

  return (
    <div style={style} className={css['cost-component']}>
      <Draggeable onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {({ isDragging }) => {
          return (
            <div onClick={onClick} role="button" tabIndex={-1} onKeyDown={() => onClick}>
              <Paragraph className={css['heading-cost-component']}>{costComponent.name}</Paragraph>
            </div>
          );
        }}
      </Draggeable>
      {content}
    </div>
  );
};

export default observer(CostComponent);
