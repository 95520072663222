import React from 'react';

import { Level } from '@zf/api-types/enums';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { Icon } from '../../Foundation';
import css from './accuracy-indicator.module.scss';

export type AccuracyIndicatorProps = {
  accuracy: Level;
  size: 'small' | 'large';
};

const AccuracyIndicator = (props: AccuracyIndicatorProps) => {
  const { accuracy, size } = props;

  return (
    <FlexElements gap={size === 'small' ? '1' : '2'}>
      <Icon
        className={css[`ai-dot-${size}${accuracy === Level.low || Level.moderate || Level.high ? '-active' : ''}`]}
        name="dot"
      />
      <Icon
        className={css[`ai-dot-${size}${accuracy === Level.moderate || accuracy === Level.high ? '-active' : ''}`]}
        name="dot"
      />
      <Icon className={css[`ai-dot-${size}${accuracy === Level.high ? '-active' : ''}`]} name="dot" />
    </FlexElements>
  );
};

export default AccuracyIndicator;
