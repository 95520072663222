import React from 'react';
import classNames from 'classnames';

import css from './avatar.module.scss';

type Props = {
  children: React.ReactNode;
  type?: string;
  circle?: boolean;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: string;
  isClickable?: boolean;
};

export default function Avatar(props: Props) {
  const {
    children,
    className,
    circle = false,
    isClickable = false,
    type = 'text',
    color = null,
    style,
    size = 'medium'
  } = props;

  return React.createElement(
    type === 'image' ? 'img' : 'div',
    {
      className: classNames(
        css['avatar'],
        {
          [css['circle']]: circle,
          [css['text-avatar']]: type === 'text',
          [css['xsmall']]: size === 'xsmall',
          [css['small']]: size === 'small',
          [css['medium']]: size === 'medium',
          [css['large']]: size === 'large',
          [css['clickable']]: isClickable
        },
        className
      ),
      style: {
        backgroundColor: color,
        ...style
      }
    },
    children
  );
}
