import { SystemMessage } from '@zf/api-types/general';

export const replaceMessagePlaceholders = (msg: SystemMessage | null | undefined) => {
  if (!msg) return '';

  const inputString = msg.message || '';
  const messageValues = msg.messageValues || [];

  // @ts-ignore
  return inputString.replace(/{(\d+)}/g, (match, index: string) => {
    const valueIndex = parseInt(index, 10);

    if (valueIndex >= 0 && valueIndex < messageValues.length) {
      return messageValues[valueIndex].value;
    }

    // Return the original placeholder if index is out of range
    return match;
  });
};
