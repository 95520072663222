import React from 'react';
import { useAppContext } from '../../app-context';
import Button, { type ButtonProps } from '../../design-system/Components/Button/Button';

export default function RemoveIconButton(props: ButtonProps) {
  const { i18n } = useAppContext();

  return (
    <Button
      {...props}
      icon="cross"
      size="small"
      type="text"
      tooltipText={i18n.getTranslation('general.remove')}
      danger
    />
  );
}
