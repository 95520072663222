import React from 'react';

import { MeteringAnalyticsType } from '@zf/api-types/analytics/metering';

import DataReceivedChartCard from '../../../../cards/DataReceivedChartCard/DataReceivedChartCard';
import ExportCard from '../cards/ExportCard';
import ImportCard from '../cards/ImportCard';
import TodoCard from '../cards/TodoCard';
import DashboardColumn from './DashboardColumn';
import IssuesCard from '../cards/IssuesCard';

type Props = {
  meteringInsights: MeteringAnalyticsType;
  setActiveTab: (tabId: string) => void;
  issueCounts: { visibility: boolean };
};

export default function MeteringAnalytics(props: Props) {
  const { meteringInsights, setActiveTab, issueCounts } = props;
  const { visibility } = issueCounts;

  return (
    <DashboardColumn withBorder>
      <DataReceivedChartCard meteringAnalytics={meteringInsights} height="25rem" />
      {meteringInsights.meterIssuesInsights.amountOfUnresolvedIssues >= 0 && visibility ? (
        <IssuesCard issuesInsights={meteringInsights.meterIssuesInsights} />
      ) : (
        <></>
      )}
      <TodoCard subject="metering" dataObject={meteringInsights} setActiveTab={setActiveTab} />
      <ImportCard subject="metering" importInsights={meteringInsights.importInsights} />
      <ExportCard subject="metering" exportInsights={meteringInsights.exportInsights} />
    </DashboardColumn>
  );
}
