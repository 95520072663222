import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import {
  OutgoingMutationCustomerReferenceParametersType,
  OutgoingMutationInvoiceReferenceParametersType,
  OutgoingMutationRowType,
  OutgoingMutationType
} from '@zf/api-types/billing/outgoing-mutations';
import { outgoingMutationStatus, outgoingMutationType } from '@zf/api-types/enums';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatMoney } from '@zf/utils/src/number';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { useAppContext } from '../../../../../../../app-context';
import { ICON_COLORS } from '../../../../../../../constants/icons';
import { Link } from '../../../../../../../design-system/Components';
import useCreateRequest from '../../../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../../../hooks/useInfiniAPI';
import useLocalRefresh from '../../../../../../../hooks/useLocalRefresh';
import { METHODS, sendRequest } from '../../../../../../../utils/request';
import useMutations from '../../../../mutations-shared/useMutations';
import { OutgoingMutationTypeOverviewCountType, useTracked } from '../../context/outgoing-transaction-detail-context';
import css from './outgoing-mutations-table.module.scss';

export default function useOutgoingMutationsTable(outgoingBankingTransaction: OutgoingBankingTransactionType) {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;
  const { refresh, timeStamp } = useLocalRefresh();

  const { renderErrors } = useMutations();

  const fetchOverviewCounts = React.useCallback(async () => {
    return (
      await sendRequest<OutgoingMutationTypeOverviewCountType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/OutgoingBankingTransactions/${outgoingBankingTransaction.id}/mutations/overviewcount`,
          timeStamp: timeStamp
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  }, [outgoingBankingTransaction, timeStamp]);

  const setOverviewCounts = React.useCallback((newCounts: OutgoingMutationTypeOverviewCountType) => {
    dispatch({ type: 'SET_OVERVIEW_COUNTS', newCounts });
  }, []);

  const { request, selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = useCreateRequest(
    `/bill/OutgoingBankingTransactions/${outgoingBankingTransaction.id}/mutations`,
    {
      ...state.queryParams,
      timeStamp: timeStamp,
      refreshStatus: outgoingBankingTransaction.status // If status changes => refetch
    }
  );

  const renderType = React.useCallback((mutation: OutgoingMutationType) => {
    if (mutation.referenceDetails.referenceType === outgoingMutationType.invoice) {
      const castedParams = mutation.referenceDetails.parameters as OutgoingMutationInvoiceReferenceParametersType;
      return (
        <Link
          id={`invoice-${mutation.referenceDetails.referenceId}`}
          url={`${rootUrl}/invoices/${mutation.referenceDetails.referenceId}`}
        >
          {`${enumReducer.getTranslation('invoiceType', castedParams.invoiceType)} ${castedParams.invoiceNum}`}
        </Link>
      );
    } else {
      // Customer
      const castedParams = mutation.referenceDetails.parameters as OutgoingMutationCustomerReferenceParametersType;
      return (
        <Link
          id={`customer-${mutation.referenceDetails.referenceId}`}
          url={`${rootUrl}/customers/${mutation.referenceDetails.referenceId}`}
        >
          {castedParams.shortDisplayName}
        </Link>
      );
    }
  }, []);

  const renderStatus = React.useCallback((mutation: OutgoingMutationType) => {
    if (mutation.status === outgoingMutationStatus.resolveissues) {
      return mutation.errors.length === 1 ? (
        <StatusBadge className={css['status-single']} color={ICON_COLORS[mutation.status]} type="bare">
          {renderErrors(mutation.errors)}
        </StatusBadge>
      ) : (
        <div className={css['status']}>
          <StatusBadge color={ICON_COLORS[mutation.status]} type="bare">
            {enumReducer.getTranslation('outgoingBankingTransactionStatus', mutation.status)}
          </StatusBadge>
          <div
            // @ts-ignore
            data-tip={createTooltipContent(
              <>
                {mutation.errors.map((e, index) => {
                  return <div key={index}>{e.message}</div>;
                })}
              </>
            )}
            data-for="mutations-table-tip"
          >
            {renderErrors(mutation.errors)}
          </div>
        </div>
      );
    }

    return (
      <StatusBadge color={ICON_COLORS[mutation.status]} type="bare">
        {enumReducer.getTranslation('outgoingBankingTransactionStatus', mutation.status)}
      </StatusBadge>
    );
  }, []);

  const renderCustomerBankAccount = (mutation: OutgoingMutationType) => {
    if (mutation.referenceDetails.referenceType === outgoingMutationType.customer) {
      return (
        <>
          <Paragraph>
            <Link
              id={`customer-${mutation.referenceDetails.referenceId}`}
              url={`${rootUrl}/customers/${mutation.referenceDetails.referenceId}`}
            >
              {mutation.customerBankAccount?.iban}
            </Link>
          </Paragraph>
        </>
      );
    } else {
      const castedParams = mutation.referenceDetails.parameters as OutgoingMutationInvoiceReferenceParametersType;
      return (
        <>
          <Paragraph>
            <Link id={`customer-${castedParams.customerId}`} url={`${rootUrl}/customers/${castedParams.customerId}`}>
              {mutation.customerBankAccount?.iban}
            </Link>
          </Paragraph>
        </>
      );
    }
  };

  const processRecord = (mutation: OutgoingMutationType) => {
    return {
      __id: mutation.id,
      __entity: mutation,
      __etag: mutation._etag,
      type: renderType(mutation),
      customerBankAccount: renderCustomerBankAccount(mutation),
      amount: formatMoney(mutation.amount, i18n.culture),
      status: renderStatus(mutation)
    };
  };

  React.useEffect(() => {
    fetchOverviewCounts()
      .then((data) => {
        setOverviewCounts(data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [outgoingBankingTransaction, timeStamp]);

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<
    OutgoingMutationType,
    OutgoingMutationRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  return {
    selectedIds,
    sortBy,
    sortDirection,
    sortableFields,
    loading,
    error,
    rows,
    queryParams: state.queryParams,
    overviewCounts: state.overviewCounts,
    setSelectedIds,
    handleSort,
    setStopIndex,
    refresh
  };
}
