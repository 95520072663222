import React, { useEffect } from 'react';

import StellaInputField from '@zf/stella-react/src/atoms/InputField/StellaInputField';
import { DEFAULT_CULTURE } from '@zf/utils/src/internationalisation';
import { formatDecimal, formatDecimalForCEP } from '@zf/utils/src/number';

import useFloatInput from './useFloatInput';
import { culture } from '@zf/api-types/enums';

export type FloatInputFieldProps = {
  value: number | null | undefined;
  id: string;
  locale?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  hideLabel?: boolean;
  error?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  className?: string;
  postfix?: string;
  darkMode?: boolean;
  singleError?: string;
  isCEP?: boolean;
  onChange: (value: number) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onBlur?: () => void;
  onFocus?: (step: string) => void;
  format?: (value: number) => void;
};

export default function StellaFloatInput(props: FloatInputFieldProps) {
  const {
    value,
    id,
    locale = DEFAULT_CULTURE,
    placeholder,
    disabled,
    readonly,
    hideLabel,
    error,
    iconLeft,
    iconRight,
    className,
    postfix,
    singleError,
    darkMode = false,
    autoFocus = false,
    isCEP = false,
    onFocus,
    onClick,
    onBlur,
    format,
    onChange
  } = props;

  const { textValue, setTextValue, handleChange } = useFloatInput(value, locale, onChange);

  useEffect(() => {
    if (value && format) format(value);
  }, []);

  const handleBlur = () => {
    if (onBlur) onBlur();

    if (isCEP) {
      setTextValue(formatDecimalForCEP(value, locale));
    }
    else {
      setTextValue(formatDecimal(value, locale))
    }
  };

  return (
    <StellaInputField
      onChange={handleChange}
      value={textValue}
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      readonly={readonly}
      onFocus={onFocus}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      onClick={onClick}
      onBlur={handleBlur}
      hideLabel={hideLabel}
      error={error}
      singleError={singleError}
      iconLeft={iconLeft}
      iconRight={iconRight}
      className={className}
      postfix={postfix}
      darkMode={darkMode}
      selectAllOnFocus
    />
  );
}
