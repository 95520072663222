import { observer } from 'mobx-react';
import React from 'react';

import { BaseLink } from '../../../design-system/Components';
import { Icon } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import APIDependentLinks from './APIDependentLinks';
import CountrySpecificLink from './CountrySpecificLink';

export type OrgCardProps = {
  organisationId: string;
  tenantId: string;
  baseUrl: string;
  isResetting: boolean;
};

const OrgCardFooter = (props: OrgCardProps) => {
  const { organisationId, baseUrl } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      <BaseLink id={`link-location-${organisationId}`} url={`${baseUrl}/locations`}>
        <Icon id={`location-${organisationId}`} name="location" title={getTranslation('nav.locations')} />
      </BaseLink>
      <BaseLink id={`link-property-groups-${organisationId}`} url={`${baseUrl}/property-groups`}>
        <Icon
          id={`property-groups-${organisationId}`}
          name="property-groups"
          title={getTranslation('property_groups.property_groups')}
        />
      </BaseLink>
      <BaseLink id={`link-customer-${organisationId}`} url={`${baseUrl}/customers`}>
        <Icon id={`customer-${organisationId}`} name="customer" title={getTranslation('customer.customers')} />
      </BaseLink>
      <BaseLink id={`link-contract-${organisationId}`} url={`${baseUrl}/contracts`}>
        <Icon id={`contract-${organisationId}`} name="contract" title={getTranslation('contracts.contracts')} />
      </BaseLink>
      <BaseLink id={`link-invoice-${organisationId}`} url={`${baseUrl}/invoices`}>
        <Icon id={`invoice-${organisationId}`} name="invoice" title={getTranslation('invoice.invoices')} />
      </BaseLink>

      <APIDependentLinks {...props} />

      <BaseLink id={`link-metering-${organisationId}`} url={`${baseUrl}/devices/meters`}>
        <Icon id={`metering-${organisationId}`} name="meter" title={getTranslation('nav.devices')} />
      </BaseLink>
      <BaseLink id={`link-import-jobs-${organisationId}`} url={`${baseUrl}/import-jobs`}>
        <Icon id={`import-jobs-${organisationId}`} name="import-job" title={getTranslation('nav.import-jobs')} />
      </BaseLink>
      <BaseLink id={`link-export-jobs-${organisationId}`} url={`${baseUrl}/export-jobs`}>
        <Icon id={`export-jobs-${organisationId}`} name="export-job" title={getTranslation('nav.export-jobs')} />
      </BaseLink>
      <BaseLink id={`link-tasks-${organisationId}`} url={`${baseUrl}/tasks`}>
        <Icon id={`tasks-${organisationId}`} name="task" title={getTranslation('nav.tasks')} />
      </BaseLink>

      <CountrySpecificLink {...props} />
    </>
  );
};

export default observer(OrgCardFooter);
