import { Moment } from 'moment';
import React from 'react';

import {
  dataFrequency,
  direction,
  incrementationType,
  meteringType,
  unitOfMeasure,
  utilityType
} from '@zf/api-types/enums';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import InputSection from '@zf/stella-react/src/atoms/InputContainer/InputSection';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { useAppContext } from '../../../app-context';
import InputField from '../../../components/input/InputField';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import css from '../../style.module.scss';
import AddMeasurementPart from './AddMeasurementPart';
import { NodeValueType } from './reconfigure-dialog';

type Props = {
  mutationDateTime: Moment;
  value: NodeValueType;
  index: number;
  dispatchValue: (value: Partial<NodeValueType>) => void;
};

export default function ReconfigurationNode(props: Props) {
  const { dispatchValue, value, index } = props;

  const { i18n, enumReducer } = useAppContext();

  if (!value.dataFrequency) value.dataFrequency = dataFrequency.na;

  // Delta isn't implemented yet
  const incrementationTypes = enumReducer
    .getEnum<incrementationType>('incrementationType')
    .filter((type) => type.value !== 'delta');

  return (
    <InputContainer className={css['input-container-class']} hasSections>
      <InputSection>
        <TooltipTrigger tooltip={<Paragraph>{value.meterName}</Paragraph>}>
          <InputField
            id={`meter-name-${index}`}
            onChange={() => {
              // Do nothing
            }}
            value={value.meterName}
            placeholder={i18n.getTranslation('actions.meter.search_placeholder')}
            disabled
          />
        </TooltipTrigger>

        <InputField
          id={`description-${index}`}
          onChange={(val) => dispatchValue({ description: val })}
          value={value.description ? value.description : ''}
          placeholder={i18n.getTranslation('general.description')}
          error={!value.description}
        />
        <SimpleDropdown
          id={`dataFrequency-${index}`}
          onChange={(val) => dispatchValue({ dataFrequency: val[0] })}
          placeholder={i18n.getTranslation('meter.frequency')}
          values={enumReducer.getEnum<dataFrequency>('dataFrequency')}
          selectedValues={[value.dataFrequency]}
          error={!value.dataFrequency}
        />
        <SimpleDropdown
          id={`incrementationType-${index}`}
          onChange={(val) => dispatchValue({ incrementationType: val[0] })}
          placeholder={i18n.getTranslation('meter.incrementation')}
          values={incrementationTypes}
          selectedValues={[value.incrementationType]}
          error={!value.incrementationType}
        />
        <SimpleDropdown
          id={`meteringType-${index}`}
          onChange={(val) => dispatchValue({ meteringType: val[0] })}
          placeholder={i18n.getTranslation('meter.metering_method')}
          values={enumReducer.getEnum<meteringType>('meteringType')}
          selectedValues={[value.meteringType]}
        />
        <SimpleDropdown
          id={`utilityType-${index}`}
          onChange={(val) => dispatchValue({ utilityType: val[0] })}
          placeholder={i18n.getTranslation('meter.utility_type')}
          values={enumReducer.getEnum<utilityType>('utilityType')}
          selectedValues={[value.utilityType]}
        />
        <SimpleDropdown
          id={`direction-${index}`}
          onChange={(val) => dispatchValue({ direction: val[0] })}
          placeholder={i18n.getTranslation('meter.direction')}
          values={enumReducer.getEnum<direction>('direction')}
          selectedValues={[value.direction]}
          error={!value.direction}
        />
        <SimpleDropdown
          id={`unitOfMeasure-${index}`}
          onChange={(val) => dispatchValue({ unitOfMeasure: val[0] })}
          placeholder={i18n.getTranslation('meter.unit_of_measure')}
          values={enumReducer.getEnum<unitOfMeasure>('unitOfMeasure', false)}
          selectedValues={[value.unitOfMeasure]}
        />
        <InputField
          id={`timeOfUse-${index}`}
          onChange={(val) => dispatchValue({ timeOfUse: val })}
          value={value.timeOfUse}
          placeholder={i18n.getTranslation('meter.time_of_use')}
        />
        <InputField
          id={`relationExternalReference-${index}`}
          onChange={(val) => dispatchValue({ relationExternalReference: val })}
          value={value.relationExternalReference}
          placeholder={i18n.getTranslation('general.reference')}
        />
      </InputSection>

      <AddMeasurementPart {...props} />
    </InputContainer>
  );
}
