import React, { useEffect } from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';
import { SuspenseSpinner } from '../../../components/suspense';
import { CrumbHeader } from '../../../components/Header';
import { BreadCrumb, Crumb } from '../../../components/BreadCrumb';
import ZFRouter from '../../../components/Router/ZFRouter';
import Route from '../../route';
import { useStore } from '../../../hooks/useStore';
import GeneralCard from './social-tariff/cards/GeneralCard';
import ContactInformationCard from './social-tariff/cards/ContactInformationCard';
import CommitSection from '../../../components/config/commit-section';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';
import { observer } from 'mobx-react';

type Props = {
  location: Location;
  navigate: (href: string) => void;
};

const CountrySpecificsConfig = (props: Props) => {
  const { navigate, location } = props;
  const { countrySpecificStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { socialTariffStore } = countrySpecificStore;
  const { socialTariffConfigForm, initRegionalParams } = socialTariffStore;

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  useEffect(() => {
    initRegionalParams();
  }, []);

  const splitUrl = location.href.split('/');

  const SocialTariffTab = observer(() => (
    <SuspenseSpinner>
      <CommitSection
        handleCancel={() => socialTariffConfigForm?._reset()}
        handleSave={() => socialTariffConfigForm?._submit()}
        isDirty={socialTariffConfigForm?._isDirty}
      />
      <AsyncVarSpinner variables={[socialTariffConfigForm]}>
        <CardsContainer>
          <GeneralCard />
          <ContactInformationCard />
        </CardsContainer>
      </AsyncVarSpinner>
    </SuspenseSpinner>
  ));

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="cog" text={getTranslation('country_specifics.country_specifics')} />
          </BreadCrumb>
        }
      />
      <TabContainer
        onSelectTab={setActiveTab}
        entityId={getTranslation('country_specifics.country_specifics')}
        selectedTab={splitUrl[splitUrl.length - 1]}
        tabItems={[
          {
            title: getTranslation('country_specifics.social_tariff'),
            id: 'social-tariff',
            isDefault: true
          }
        ]}
      >
        <ZFRouter>
          <Route Component={SocialTariffTab} path="social-tariff" default />
        </ZFRouter>
      </TabContainer>
    </>
  );
};

export default observer(CountrySpecificsConfig);
