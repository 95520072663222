import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { ImportDataProviderType } from '@zf/api-types/data-provider';
import { FileFormatType } from '@zf/api-types/file-format';

import { useAppContext } from '../../../app-context';
import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import ListPage from '../../../components/listPage';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import ImportJobsFilterBar from './import-jobs-filter-bar';
import ImportJobsList from './import-jobs-list';
import { entitySubjectType } from '@zf/api-types/enums';

type Props = {
  location: Location;
};

export default function ImportJobs(props: Props) {
  const { i18n } = useAppContext();
  useBrowserTitle(i18n.getTranslation('import_jobs.import_jobs'));

  const providersResponse = useSuspenseSingleAPI<PagedResponseType<ImportDataProviderType>>({
    request: {
      endpoint: '/cfg/DataImports',
      query: { showDeleted: true }
    }
  });

  const fileFormatsResponse = useSuspenseSingleAPI<PagedResponseType<FileFormatType>>({
    request: {
      endpoint: '/cfg/DataImportFileFormats',
      query: { showDeleted: true }
    }
  });

  if (!providersResponse.result || !fileFormatsResponse.result) return null;

  return (
    <ListPage
      entitySubjectType={entitySubjectType.importjob}
      subjectType={i18n.getTranslation('import_jobs.import_jobs')}
      topBar={<ImportJobsFilterBar search={props.location.search} />}
      list={
        <ImportJobsList
          dataProviders={providersResponse.result.data.results}
          fileformats={fileFormatsResponse.result.data.results}
        />
      }
    />
  );
}
