import clone from 'clone';
import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import { CreateAuthUserType, OrganisationRoleType, AuthRoleResponse, UserRolesType } from '@zf/api-types/auth';
import { uiCulture } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useValidator from '@zf/hooks/src/useValidator';
import CultureCodeDropDown from '@zf/stella-react/src/atoms/Dropdown/culture-code-dropdown';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { getAdmins } from '../../features/organizations/UserManagerContent';
import { useStore } from '../../hooks/useStore';
import { validateEmail } from '../../utils/email';
import UserAutoFill from '../Autofills/UserAutoFill';
import { UserAvatar } from '../Avatar';
import InputField from '../input/InputField';
import Toggle from '../Slider/Toggle';
import css from './add-edit-user-dialog.module.scss';
import { addAdmin, addTenantUser, deleteAdmin, updateOrganisationUsers } from './apiFunctions';
import PermissionMultiValue from './permission-multivalue';

type Props = {
  type: 'admin' | 'user';
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  userRole?: UserRolesType;
  roles: AuthRoleResponse[];
  userRoles: UserRolesType[];
  onComplete: () => void;
};

type StateValues = {
  isNewUser: boolean;
  firstName: string;
  lastName: string;
  email: string;
  culture: uiCulture;
  searchEmail: string;
  searchedId: string;
};

type ValidatorType = {
  organisationRoles: OrganisationRoleType[];
  type: 'admin' | 'user';
};

const AddEditUserDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { userRole, userRoles, type, validationRef, roles, onComplete } = props;
  const { authReducer } = useAppContext();
  const { applicationStore } = useStore();
  const { userStore, tenantReducer, getTranslation, getEnum } = applicationStore;
  const { lang } = userStore;
  const { tenant } = tenantReducer;
  const { isSuperUser } = authReducer;

  const stateReducer = createStateReducer<StateValues, Partial<StateValues>>();
  const [state, setState] = useReducer(stateReducer, {
    isNewUser: false, // Used to switch between add & edit user usecase
    firstName: '',
    lastName: '',
    email: '',
    culture: uiCulture.en,
    searchEmail: '',
    searchedId: ''
  });

  const adminIds = getAdmins(userRoles).map((a) => a.userDetails.id);

  const { values, backup, setValue, submitFactory } = useValidator<ValidatorType>({
    initialValues: { organisationRoles: userRole ? clone(userRole.organizationRoles) : [], type }
  });

  const validate = () => {
    if (validationRef.current) {
      if (state.searchedId) {
        validationRef.current.setIsError(false);
      } else if (state.isNewUser) {
        validationRef.current.setIsError(
          !state.firstName || !state.lastName || !state.email || !validateEmail(state.email)
        );
      }
    }
  };

  useEffect(() => {
    if (!userRole) {
      setState({ isNewUser: true });
    }
  }, []);

  useEffect(() => {
    validate();
  }, [state]);

  const handleRolesSave = submitFactory(async () => {
    if (userRole) {
      const deletedOrgRoles: OrganisationRoleType[] = [];
      backup.organisationRoles.forEach((or) => {
        if (!values.organisationRoles.find((vor) => or.organizationId === vor.organizationId)) {
          deletedOrgRoles.push(or);
        }
      });

      await Promise.all(
        deletedOrgRoles.map((or) =>
          updateOrganisationUsers(
            userRoles,
            or.organizationId,
            or.roleId,
            userRole.userDetails.id,
            'delete',
            tenant,
            lang
          )
        )
      );

      const addedOrgRoles: OrganisationRoleType[] = [];
      values.organisationRoles.forEach((vor) => {
        if (!backup.organisationRoles.find((bor) => bor.organizationId === vor.organizationId)) {
          addedOrgRoles.push(vor);
        }
      });

      await Promise.all(
        addedOrgRoles.map((or) =>
          updateOrganisationUsers(userRoles, or.organizationId, or.roleId, userRole.userDetails.id, 'add', tenant, lang)
        )
      );

      const updatedOrgRoles: OrganisationRoleType[] = [];

      values.organisationRoles.forEach((or) => {
        const matchedBackupRole = backup.organisationRoles.find((bor) => bor.organizationId === or.organizationId);

        if (matchedBackupRole && or.roleId !== matchedBackupRole.roleId) {
          updatedOrgRoles.push(or);
        }
      });

      await Promise.all(
        updatedOrgRoles.map((or) =>
          updateOrganisationUsers(
            userRoles,
            or.organizationId,
            or.roleId,
            userRole.userDetails.id,
            'update',
            tenant,
            lang
          )
        )
      );
    }
  });

  const addUser = async () => {
    try {
      let newUserId = state.searchedId;

      if (!state.searchedId) {
        const apiFriendlyValues: CreateAuthUserType = {
          firstName: state.firstName,
          lastName: state.lastName,
          emailAddress: state.email,
          culture: state.culture
        };

        newUserId = await addTenantUser(apiFriendlyValues, tenant, lang);
      }

      if (values.type === 'admin') {
        // API call to add this new user as admin
        await addAdmin(adminIds, newUserId, tenant, lang);
      } // type === 'user'
      else {
        const addedOrgRoles: OrganisationRoleType[] = [];
        values.organisationRoles.forEach((vor) => {
          if (!backup.organisationRoles.find((bor) => bor.organizationId === vor.organizationId)) {
            addedOrgRoles.push(vor);
          }
        });

        // API call to update the organisation users
        await Promise.all(
          addedOrgRoles.map((or) =>
            updateOrganisationUsers(userRoles, or.organizationId, or.roleId, newUserId, 'add', tenant, lang)
          )
        );
      }

      onComplete();

      notify.success({
        content: getTranslation(`user.add_${values.type}_success`)
      });
    } catch (e) {
      notify.error({
        content: getTranslation(`user.add_${values.type}_fail`),
        error: e
      });
    }
  };

  const editUser = async () => {
    if (userRole) {
      try {
        if (backup.type !== values.type) {
          if (values.type === 'admin') {
            // A user was changed to admin
            await addAdmin(adminIds, userRole.userDetails.id, tenant, lang);
            // Cleanup the user roles in organisations
            await Promise.all(
              userRole.organizationRoles.map((or) =>
                updateOrganisationUsers(
                  userRoles,
                  or.organizationId,
                  or.roleId,
                  userRole.userDetails.id,
                  'delete',
                  tenant,
                  lang
                )
              )
            );
          } else {
            // An admin was changed to user
            await deleteAdmin(adminIds, userRole, tenant, lang);
            // API call to update the organisation users
            await Promise.all(
              values.organisationRoles.map((or) =>
                updateOrganisationUsers(
                  userRoles,
                  or.organizationId,
                  or.roleId,
                  userRole.userDetails.id,
                  'add',
                  tenant,
                  lang
                )
              )
            );
          }
        } else {
          // Only organisation roles were changed
          handleRolesSave();
        }

        onComplete();

        notify.success({
          content: getTranslation('user.edit_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('user.edit_fail'),
          error: e
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (state.isNewUser) {
        await addUser();
      } else {
        await editUser();
      }
    }
  }));

  return (
    <div className={css['add-edit-wrapper']}>
      {state.isNewUser && (
        <InputContainer className={css['inputs']}>
          {isSuperUser() && (
            <UserAutoFill
              id="search-user"
              onChange={(value) => setState({ searchedId: value[0]?.id || '' })}
              selectedValues={[state.searchedId]}
              placeholder={getTranslation('tenant.user_by_email')}
              queryField="userEmailAddress"
            />
          )}
          {!state.searchedId && (
            <>
              <InputField
                id="first-name"
                onChange={(val) => setState({ firstName: val })}
                value={state.firstName}
                placeholder={getTranslation('user.first_name')}
                error={!state.firstName}
              />
              <InputField
                id="last-name"
                onChange={(val) => setState({ lastName: val })}
                value={state.lastName}
                placeholder={getTranslation('user.last_name')}
                error={!state.lastName}
              />
              <InputField
                id="email"
                onChange={(val) => setState({ email: val })}
                value={state.email}
                placeholder={getTranslation('user.email')}
                error={!state.email || !validateEmail(state.email)}
              />

              <CultureCodeDropDown
                id="culture"
                onChange={(val) => setState({ culture: (val[0] as uiCulture) || '' })}
                selectedValues={[state.culture]}
                cultureCodes={getEnum<uiCulture>('uiCulture')}
                placeholder={getTranslation('user.language')}
                error={!state.culture}
              />
            </>
          )}
        </InputContainer>
      )}

      {userRole && (
        <>
          <UserAvatar>{userRole.userDetails.userName || userRole.userDetails.email}</UserAvatar>
          <br />
          <Center>
            <div className={css['user-name']}>{userRole.userDetails.userName}</div>
          </Center>
          <br />
          <Paragraph>{getTranslation('user.which_rights')}</Paragraph>
          {isSuperUser() && (
            <Center>
              <Toggle
                id="preview-mode-slider"
                className={css['toggle']}
                optionLeft={getTranslation('user.admin')}
                optionRight={getTranslation('user.user')}
                isChecked={values.type === 'admin'}
                action={() => setValue({ type: values.type === 'admin' ? 'user' : 'admin' })}
              />
            </Center>
          )}
        </>
      )}

      {values.type === 'user' && (
        <PermissionMultiValue
          initialValues={
            values.organisationRoles.length > 0
              ? values.organisationRoles
              : [
                  {
                    organizationId: '',
                    roleId: ''
                  }
                ]
          }
          roles={roles}
          onChange={(orgRoles) => setValue({ organisationRoles: orgRoles })}
        />
      )}

      {values.type === 'user' && values.organisationRoles.length === 0 && (
        <Paragraph className={css['warning-message']}>{getTranslation('user.no_roles_warning')}</Paragraph>
      )}
    </div>
  );
});

export default observer(AddEditUserDialog);
