import ApplicationStore from 'app-context/stores/domain/ApplicationStore';

import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { formatDateTimeForExport } from '@zf/utils/src/date';

export const getMeteringIssuesExportArray = (issues: MeteringIssue[], applicationStore: ApplicationStore) => {
  const { getTranslation, getEnumTranslation, userStore } = applicationStore;
  const { users } = userStore;

  const exportArray: string[][] = [];

  exportArray.push([
    //getTranslation('meter.meter_tag'), (Future plans to add metertag into the Issues API response?)
    getTranslation('metering_list.labels.serial'),
    getTranslation('meter_issues.export_property_group'),
    getTranslation('meter_issues.export_issue_type'),
    getTranslation('meter_issues.export_detected_on'),
    getTranslation('general.status'),
    getTranslation('meter_issues.export_issue_details'),
    getTranslation('meter_issues.export_resolution_datetime'),
    getTranslation('meter_issues.export_resolution_type'),
    getTranslation('meter_issues.export_resolved_by'),
    getTranslation('meter_issues.export_resolved_message')
  ]);

  const getFormattedIssueDetails = (issue: MeteringIssue) => {
    if (issue) {
      let detailEntries = Object.entries(issue.details);
      let resultArray = [];

      for (const [key, value] of detailEntries) {
        resultArray.push(key + ': ' + value);
      }

      resultArray = resultArray.slice(2);
      return resultArray.join(`, \n`);
    } else {
      return '';
    }
  };

  issues.forEach((issue) => {
    let userName: string | null | undefined = '';

    if (issue.resolution?.details?.resolvedByUserId) {
      userName = users.get(issue.resolution.details.resolvedByUserId)?.userName;
    }

    exportArray.push([
      //meter?.meterTag || '',  (See comment on ExportListPageButton.tsx)
      issue.meter?.serialNumber || '',
      issue.propertyGroup?.name || '',
      getEnumTranslation('meteringIssueError', issue.error),
      formatDateTimeForExport(issue.noticedDateTime),
      getEnumTranslation('meteringIssueStatus', issue.status),
      getFormattedIssueDetails(issue),
      formatDateTimeForExport(issue.resolution?.resolvedDateTime) || '',
      getEnumTranslation('meteringIssueResolutionType', issue.resolution?.type || ''),
      userName || '',
      issue.resolution?.details?.message
        ? issue.resolution?.details?.message
        : issue.resolution?.details?.resolvedReason
        ? issue.resolution?.details?.resolvedReason
        : ''
    ]);
  });

  return exportArray;
};
