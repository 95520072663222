import { entitySubjectType } from '@zf/api-types/enums';
import React from 'react';

import { useAppContext } from '../../../app-context/app-context';
import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import ListPage from '../../../components/listPage';
import TasksFilterBar from './tasks-filter-bar';
import TasksList from './tasks-list';

type Props = {
  location: Location;
};

export default function Tasks(props: Props) {
  const { i18n } = useAppContext();
  useBrowserTitle(i18n.getTranslation('tasks.tasks'));

  return (
    <ListPage
      entitySubjectType={entitySubjectType.moverequest}
      subjectType={i18n.getTranslation('nav.tasks')}
      topBar={<TasksFilterBar search={props.location.search} />}
      list={<TasksList />}
    />
  );
}
