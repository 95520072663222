import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import Button from '../../../../../../components/Button/Button';
import { dialog } from '../../../../../../events/dialog-events';
import { useStore } from '../../../../../../hooks/useStore';
import ApproveInvoiceDialog from './completeness-dialogs/ApproveInvoiceDialog';
import ClosePeriodDialog from './completeness-dialogs/ClosePeriodDialog';
import AdjustInvoicePeriodDialog from './completeness-dialogs/AdjustInvoicePeriodDialog';
import CreateInvoiceDialog from './completeness-dialogs/CreateInvoiceDialog';
import RecalculateDialog from './completeness-dialogs/RecalculateDialog';
import ReopenDialog from './completeness-dialogs/ReopenDialog';
import css from './right-footer.module.scss';

const RightFooter = observer(() => {
  const { contractStore, applicationStore } = useStore();
  const { selectedCompleteness, actionPermissions } = contractStore.billingCompletenessStore;

  const { clickRef, validationRef, onSubmit } = useDialog();

  const openClosePeriodDialog = () => {
    dialog.normal({
      title: applicationStore.getTranslation('contracts.billing_action_close_period'),
      content: <ClosePeriodDialog completeness={selectedCompleteness} ref={clickRef} />,
      buttonPositive: applicationStore.getTranslation('general.close'),
      type: 'danger',
      onSubmit
    });
  };

  const openCreateInvoiceDialog = () => {
    dialog.normal({
      title: applicationStore.getTranslation('invoice.create_invoice'),
      content: <CreateInvoiceDialog completeness={selectedCompleteness} ref={clickRef} />,
      buttonPositive: applicationStore.getTranslation('invoice.create'),
      onSubmit
    });
  };

  const openAdjustInvoicePeriodDialog = () => {
    dialog.normal({
      title: applicationStore.getTranslation('contracts.billing_action_adjust_invoice_period'),
      content: (
        <AdjustInvoicePeriodDialog completeness={selectedCompleteness} ref={clickRef} validationRef={validationRef} />
      ),
      buttonPositive: applicationStore.getTranslation('general.adjust'),
      ref: validationRef,
      onSubmit
    });
  };

  const openRecalculateDialog = () => {
    dialog.normal({
      title: applicationStore.getTranslation('general.recalculate'),
      content: <RecalculateDialog completeness={selectedCompleteness} ref={clickRef} />,
      buttonPositive: applicationStore.getTranslation('general.recalculate'),
      onSubmit
    });
  };

  const openReopenDialog = () => {
    dialog.normal({
      title: applicationStore.getTranslation('completeness.reopen'),
      content: <ReopenDialog completeness={selectedCompleteness} ref={clickRef} />,
      buttonPositive: applicationStore.getTranslation('completeness.reopen'),
      onSubmit
    });
  };

  const openApproveDialog = () => {
    dialog.normal({
      title: applicationStore.getTranslation('invoice.approve'),
      content: <ApproveInvoiceDialog ref={clickRef} completeness={selectedCompleteness} />,
      buttonPositive: applicationStore.getTranslation('invoice.approve'),
      onSubmit
    });
  };

  return (
    <div className={css['right-footer']}>
      {actionPermissions.mayCloseManually ? (
        <Button
          className={css['align-center']}
          id="update.billing.completeness.manuallyclose"
          type="text"
          onClick={openClosePeriodDialog}
          danger
        >
          {applicationStore.getTranslation('contracts.billing_action_close_period')}
        </Button>
      ) : (
        <div />
      )}

      <div className={classNames(css['align-center'], css['button-wrapper'])}>
        {actionPermissions.mayAdjustInvoicePeriod && (
          <Button id="completeness.intermediate_invoice" type="secondary" onClick={openAdjustInvoicePeriodDialog}>
            {applicationStore.getTranslation('contracts.billing_action_adjust_invoice_period')}
          </Button>
        )}

        {actionPermissions.mayCreateInvoice ? (
          <Button id="invoice.create" onClick={openCreateInvoiceDialog}>
            {applicationStore.getTranslation('invoice.create_invoice')}
          </Button>
        ) : actionPermissions.mayRecalculate ? (
          <Button id="completeness.recalculate" onClick={openRecalculateDialog}>
            {applicationStore.getTranslation('invoice.recalculate')}
          </Button>
        ) : actionPermissions.mayReopen ? (
          <Button id="completeness.reopen" onClick={openReopenDialog}>
            {applicationStore.getTranslation('completeness.reopen')}
          </Button>
        ) : actionPermissions.mayApprove ? (
          <Button id="invoice.approve" onClick={openApproveDialog}>
            {applicationStore.getTranslation('invoice.approve')}
          </Button>
        ) : null}
      </div>
    </div>
  );
});

export default RightFooter;
