import React from 'react';

import { DataExportPagedResponseType } from '@zf/api-types/export-job';

import { useAppContext } from '../../../app-context';
import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import ListPage from '../../../components/listPage';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import ExportJobsFilterBar from './export-jobs-filter-bar';
import ExportJobsList from './export-jobs-list';
import { entitySubjectType } from '@zf/api-types/enums';

type Props = {
  location: Location;
};

export default function ExportJobs(props: Props) {
  const { i18n } = useAppContext();
  useBrowserTitle(i18n.getTranslation('export_jobs.export_jobs'));

  const providersResponse = useSuspenseSingleAPI<DataExportPagedResponseType>({
    request: {
      endpoint: '/cfg/DataExports',
      query: { showDeleted: true }
    }
  });

  if (!providersResponse.result) return null;

  return (
    <ListPage
      entitySubjectType={entitySubjectType.exportjob}
      subjectType={i18n.getTranslation('export_jobs.export_jobs')}
      topBar={<ExportJobsFilterBar search={props.location.search} />}
      list={<ExportJobsList dataExports={providersResponse.result.data.results} />}
    />
  );
}
