import { observer } from 'mobx-react';
import moment from 'moment';
import React, { CSSProperties, useState } from 'react';

import { billingCompletenessStatus, invoiceType } from '@zf/api-types/enums';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { MAX_DATE } from '@zf/utils/src/date';

import PropertyGroupAutoFill, { PropertyGroupAutofillProps } from '../../components/Autofills/PropertyGroupAutoFill';
import FilterInputs from '../../components/Filters/filter-inputs';
import CheckBox from '../../components/input/CheckBox';
import DateRangePicker from '../../components/input/DateRangePicker';
import EnumFilterSelect from '../../components/input/EnumFilterSelect';
import { useStore } from '../../hooks/useStore';
import Actions from './actions';
import useCompletenessFilter from './hooks/useCompletenessFilter';
import UnbilledList from './unbilled-list';
import css from './unbilled-list.module.scss';
import SearchBar, { SearchBarProps } from '../../components/input/SearchBar';

const InlineInputFieldSearchBar = InlineInputField<SearchBarProps>(SearchBar);
const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);

export type UnbilledListQueryParams = {
  propertyGroupId?: string;
  propertyGroupName?: string;
  timestamp?: number;
  periodEndDateTime?: string;
  periodStartDateTime?: string;
  selectedStatus?: billingCompletenessStatus[];
  onlyOpen?: string | boolean;
  searchValue?: string;
  flexSearch?: string;
  invoicingCheckpointStartDateTime?: string;
  invoicingCheckpointEndDateTime?: string;
  invoiceType?: string;
  quickfilter: string;
};

type Props = {
  useCase: 'dashboard' | 'propertyGroup';
  propertyGroupId?: string;
};

const UnbilledListCard = (props: Props) => {
  const { useCase, propertyGroupId } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const [cardStyleObject] = useState<CSSProperties>({
    height: useCase === 'dashboard' ? '69.5vh' : '36vh'
  });

  const { queryParams, filterTags, setQueryParams, clearQueryParams } = useCompletenessFilter(propertyGroupId);

  return (
    <Card id="unbilled-card" width="4" style={cardStyleObject}>
      <CardHeader
        extraRight={
          <>
            <InlineInputFieldSearchBar
              id="filter-searchbar"
              className={css['search-bar']}
              onChange={(val) => setQueryParams({ searchValue: val })}
              value={queryParams.searchValue}
              debounceTime={400}
            />
            <Actions untilDateTime={queryParams.periodEndDateTime} />
          </>
        }
        filters={
          <FilterInputs
            filterInputs={[
              <Label key="label-property-group">{getTranslation('property_groups.property_group')}</Label>,
              <InlinePropertyGroupAutoFill
                id="propertygroup"
                key="propertygroup-filter"
                onChange={(value) => setQueryParams({ propertyGroupId: value[0].id, propertyGroupName: value[0].name })}
                selectedValues={[queryParams?.propertyGroupId || '']}
                placeholder={getTranslation('location.search_property_group')}
                renderTopLvl={false}
                showDetails={false}
              />,
              <ListDivider key="divider1" />,
              <EnumFilterSelect
                id="completeness-status-filter"
                key="completeness-status-filter"
                title={getTranslation('general.status')}
                options={getEnum<billingCompletenessStatus>('billingCompletenessStatus')}
                values={queryParams.selectedStatus || []}
                setValue={(value) => setQueryParams({ selectedStatus: value })}
              />,
              <EnumFilterSelect
                id="completeness-invoice-status-filter"
                key="completeness-invoice-status-filter"
                title={getTranslation('invoice.invoicetype')}
                options={getEnum<invoiceType>('invoiceType')}
                values={[queryParams.invoiceType]}
                setValue={(value) => setQueryParams({ invoiceType: value[0] })}
              />,
              <ListDivider key="divider2" />,
              <CheckBox
                id="only-open"
                key="only-open"
                checked={typeof queryParams.onlyOpen !== 'undefined' ? (queryParams.onlyOpen as boolean) : false}
                onChange={() => setQueryParams({ onlyOpen: !queryParams.onlyOpen })}
              >
                {getTranslation('analytics.only_open')}
              </CheckBox>,
              <ListDivider key="divider3" />,
              <Label key="lbl_invoice_period">{getTranslation('invoice.invoice_period')}</Label>,
              <DateRangePicker
                id="invoice-period"
                key="invoice-period"
                startDate={moment(queryParams.invoicingCheckpointStartDateTime)}
                endDate={moment(queryParams.invoicingCheckpointEndDateTime)}
                setDates={(dates_) =>
                  setQueryParams({
                    invoicingCheckpointStartDateTime: dates_[0]?.toISOString() || moment(MAX_DATE).toISOString(),
                    invoicingCheckpointEndDateTime: dates_[1]?.toISOString() || moment(MAX_DATE).toISOString()
                  })
                }
                renderTopLvl={false}
              />,
              <Label key="lbl_period">{getTranslation('general.period')}</Label>,
              <DateRangePicker
                id="period"
                key="period"
                startDate={moment(queryParams.periodStartDateTime)}
                endDate={moment(queryParams.periodEndDateTime)}
                setDates={(dates_) =>
                  setQueryParams({
                    periodStartDateTime: dates_[0]?.toISOString() || moment(MAX_DATE).toISOString(),
                    periodEndDateTime: dates_[1]?.toISOString() || moment(MAX_DATE).toISOString()
                  })
                }
                renderTopLvl={false}
              />
            ]}
            removeAllFilters={clearQueryParams}
          />
        }
        tags={[filterTags]}
      >
        {getTranslation('analytics.unbilled')}
      </CardHeader>
      <CardBody className={css['card-body']}>
        <UnbilledList queryParams={queryParams} />
      </CardBody>
    </Card>
  );
};

export default observer(UnbilledListCard);
