import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';
import useDidMountEffect from '@zf/hooks/src/useDidMountEffect';
import { startOfDay } from '@zf/utils/src/date';

import StellaCheckBox from '../CheckBox/StellaCheckBox';
import { Label } from '../Label';
import css from './history-picker.module.scss';

export type HistoryPickerProps = {
  id: string;
  lastWeekLabel?: string;
  lastMonthLabel?: string;
  lastYearLabel?: string;
  historyDates: (Moment | null)[];
  setHistoryDates: (dates: (Moment | null)[]) => void;
};

export default function StellaHistoryPicker(props: HistoryPickerProps) {
  const {
    id,
    historyDates,
    setHistoryDates,
    lastWeekLabel = 'Last week',
    lastMonthLabel = 'Last month',
    lastYearLabel = 'Last year'
  } = props;

  const [selection, setSelection] = useState([false, false, false]);

  const defaultSelection = [false, false, false];

  useDidMountEffect(() => {
    if (
      historyDates &&
      (!historyDates[0] || (historyDates[0] && historyDates[0].isSame(moment('0001-01-01T00:00:00.000Z')))) &&
      (!historyDates[1] || (historyDates[1] && historyDates[1].isSame(moment('9999-12-31T23:59:59.000Z'))))
    ) {
      setSelection(defaultSelection);
    }
  }, [JSON.stringify(historyDates)]);

  const updateSelection = (index: number) => {
    const newSelection = defaultSelection;

    if (selection[index] === true) {
      // Reset
      setSelection(newSelection);
      setHistoryDates([null, null]);
    } else {
      newSelection[index] = !newSelection[index];

      if (index === 0) {
        setHistoryDates([startOfDay(moment().subtract(1, 'weeks')), startOfDay(moment())]);
      } else if (index === 1) {
        setHistoryDates([startOfDay(moment().subtract(1, 'months')), startOfDay(moment())]);
      } else {
        setHistoryDates([startOfDay(moment().subtract(1, 'years')), startOfDay(moment())]);
      }
      setSelection(newSelection);
    }
  };

  return (
    <div id={id} className={css['history-picker']}>
      <BaseButton
        id="last-week"
        className={classNames(css['option'], { [css['selected']]: selection[0] })}
        onClick={() => updateSelection(0)}
      >
        <Label>{lastWeekLabel}</Label>
        <StellaCheckBox id="last-week-checkbox" className={css['checkbox']} checked={selection[0]} preventDefault />
      </BaseButton>
      <BaseButton
        id="last-month"
        className={classNames(css['option'], { [css['selected']]: selection[1] })}
        onClick={() => updateSelection(1)}
      >
        <Label>{lastMonthLabel}</Label>
        <StellaCheckBox
          id="last-month-checkbox"
          className={classNames(css['checkbox'], { [css['selected']]: selection[1] })}
          checked={selection[1]}
          preventDefault
        />
      </BaseButton>
      <BaseButton
        id="last-year"
        className={classNames(css['option'], { [css['selected']]: selection[2] })}
        onClick={() => updateSelection(2)}
      >
        <Label>{lastYearLabel}</Label>
        <StellaCheckBox id="last-year-checkbox" className={css['checkbox']} checked={selection[2]} preventDefault />
      </BaseButton>
    </div>
  );
}
