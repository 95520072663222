import useSuspenseSingleAPI from 'hooks/useSuspenseSingleAPI';
import React from 'react';
import { METHODS } from 'utils/request';

import { InvoiceType } from '@zf/api-types/invoice';

import InvoicePreviewLoader from './InvoicePreviewLoader';
import InvoicePreviewWrapper from './InvoicePreviewWrapper';

type Props = {
  invoice: InvoiceType;
};

export default function InvoicePreviewCreated(props: Props) {
  const { invoice } = props;

  const response = useSuspenseSingleAPI<ArrayBuffer>(
    {
      responseType: 'arraybuffer',
      request: {
        method: METHODS.POST,
        endpoint: `/bill/Invoices/${invoice.id}/previewpdf`
      },
      Accept: 'application/octet-stream'
    },
    true
  );

  if (!response.result) return <InvoicePreviewLoader />;

  const pdf = new Blob([response.result.data], { type: 'application/pdf' });
  const blob_url = URL.createObjectURL(pdf);

  return (
    <InvoicePreviewWrapper>
      <embed id="pdf_preview" src={blob_url ? `${blob_url}#page=1&view=FitH` : undefined} type="application/pdf" />
    </InvoicePreviewWrapper>
  );
}
