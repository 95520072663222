import { BareDropdown } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { MeterReference } from '@zf/api-types/master-data/meter';

import { Query } from '../../list-page/types';

const MeterFilter = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<Partial<Query>>;
  setValue: UseFormSetValue<Partial<Query>>;
}) => {
  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getMetersWithIssues } = meterStore.meteringIssueService;

  const [meters, setMeters] = useState<MeterReference[]>([]);

  useEffect(() => {
    getMetersWithIssues().then((meters) => setMeters(meters.results));
  }, []);

  return (
    <BareDropdown
      id="dropdown-meter"
      label={getTranslation('meter.meter')}
      selectedValues={getValues('meterIds') as any}
      values={meters.map(({ meterId, serialNumber }: any) => ({ meterId, text: serialNumber, value: meterId }))}
      onChange={(val) => {
        const meterNames = meters.reduce((acc: string[], m) => {
          if (val.includes(m.meterId)) {
            acc.push(m.serialNumber);
          }

          return acc;
        }, []);
        const meterIds = meters.reduce((acc: string[], m) => {
          if (val.includes(m.meterId)) {
            acc.push(m.meterId);
          }

          return acc;
        }, []);

        setValue('meterIds', meterIds);
        setValue('meterNames', meterNames);
      }}
      isMultipleSelected
    />
  );
};

export default MeterFilter;
