import React from 'react';

import { contactType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';
import { CustomerRowType } from '@zf/api-types/master-data/customer';

import useCurrent from '../../../app-context/hooks/use-current';
import { useStore } from '../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import ExportToExcel from './ExportToExcel';

type Props = {
  extraColumnTitles: string[];
};

export default function CustomerExport(props: Props) {
  const { extraColumnTitles } = props;

  const { myCurrent } = useCurrent('customer');

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const selectedRows: CustomerRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = () => {
    const exportArray: string[][] = [];

    exportArray.push([
      getTranslation('general.type'),
      getTranslation('customer_list.name'),
      getTranslation('customer.customer_id'),
      getTranslation('customer_list.customer_id'),
      getTranslation('location.street_name'),
      getTranslation('location.streetnr'),
      getTranslation('location.streetnraddition'),
      getTranslation('location.postal'),
      getTranslation('location.city'),
      getTranslation('location.country'),
      getTranslation('customer_list.email'),
      getTranslation('customer_list.phone'),
      getTranslation('customer_list.mobile'),
      getTranslation('customer_list.website'),
      getTranslation('customer.iban'),
      getTranslation('property_groups.name'),
      getTranslation('customer_groups.customer_group'),
      getTranslation('customer.ssin'),
      getTranslation('customer.payment_method'),
      getTranslation('customer.vat_number'),
      getTranslation('communication.communication_preferences'),
      getTranslation('general.language'),
      ...extraColumnTitles
    ]);

    selectedRows.forEach((r) => {
      const customer = r.__customerEntity;

      const contactDetailsMap: Record<contactType, string> = {
        email: '',
        telephone: '',
        website: '',
        mobiletelephone: ''
      };

      customer.contactDetails.forEach((contactDetail: ContactEntryType) => {
        if (contactDetail.primaryForType && contactDetail.contactType) {
          //@ts-ignore
          contactDetailsMap[contactDetail.contactType] = contactDetail.value;
        }
      });

      const bankAccount = customer.bankAccountIbans.find((account) => account.isDefault);

      const propertyGroupNames = customer.propertyGroups?.map((pg) => pg.name) || [];

      const extraCustomEntityColumns = getExtraCustomEntityColumns(
        customer.customProperties,
        r.__customEntityPropertyTypes,
        getTranslation
      );

      const extraCustomEntityColumnValues = Object.keys(extraCustomEntityColumns).map((key) => {
        return extraCustomEntityColumns[key];
      });

      exportArray.push([
        getEnumTranslation('customerType', customer.customerType),
        customer.shortDisplayName,
        customer.accountNumber,
        customer.id,
        customer.invoiceAddress.streetName,
        customer.invoiceAddress.streetNumber,
        customer.invoiceAddress.streetNumberAddition || '',
        customer.invoiceAddress.postalCode,
        customer.invoiceAddress.city,
        customer.invoiceAddress.country.toUpperCase(),
        contactDetailsMap.email,
        contactDetailsMap.telephone,
        contactDetailsMap.mobiletelephone,
        contactDetailsMap.website,
        bankAccount?.iban || '',
        propertyGroupNames.join(','),
        customer.customerGroup?.name,
        customer?.ssin || '',
        customer.defaultPaymentMethod,
        customer.vatNumber || '',
        customer.communicationPreferences.invoiceCommunicationPreferences
          .map((cP) => `${cP.invoiceType}:${cP.communicationType}`)
          .join(',') || 'default',
        customer.communicationPreferences.culture || '',

        ...extraCustomEntityColumnValues
      ]);
    });

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      key="customerExport"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_customers')}
      ws_name={getTranslation('customer.customers')}
    />
  );
}
