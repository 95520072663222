import React from 'react';

import StellaIBANInput from '@zf/stella-react/src/atoms/InputField/StellaIBANInput';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatIBAN } from '@zf/utils/src/iban';

import AccessControl from '../Permissions/AccessControl';
import { InputFieldProps } from './InputField';

export default function IBANInput(props: InputFieldProps) {
  const { id, value } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{value ? formatIBAN(value) : ''}</Paragraph>}>
      <StellaIBANInput {...props} />
    </AccessControl>
  );
}
