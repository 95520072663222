import moment, { Moment } from 'moment';
import React from 'react';
import { getDropDownTimeVals } from 'utils/meter';

import { dataFrequency } from '@zf/api-types/enums';
import { AddMeasurementRequestType, ExternalChannelType, MeterType } from '@zf/api-types/master-data/meter';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { groupBy } from '@zf/utils/src/array';
import { HOURS, MINUTESMAP } from '@zf/utils/src/date';

import css from '../../../actions/style.module.scss';
import { useAppContext } from '../../../app-context';
import MetersDropdown from '../../../components/Dropdown/meter-dropdown/MetersDropdown';
import FloatInput from '../../../components/input/FloatInput';
import DatePicker from '../../../components/Lang/DatePicker';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { InstallMeterMultiValueType } from './InstallMeterMultiValue';

type Props = {
  locationId?: string;
  meterIndex?: number;
  mutationDateTime: Moment;
  value: InstallMeterMultiValueType;
  measurementIndex: number;
  dispatchValue: (value: Partial<AddMeasurementRequestType>) => void;
};

export default function InstallWizardMeasurement(props: Props) {
  const { meterIndex = 0, value, measurementIndex, locationId, dispatchValue } = props;

  const { i18n } = useAppContext();

  const setMeter = (m: MeterType) => {
    dispatchValue({
      meter: m
    });
  };

  const availableChannels: DropdownValuesType<string>[] = [];

  if (value.meter) {
    const grouped: Record<string, ExternalChannelType[]> = groupBy(value.meter.channels, 'description');

    Object.keys(grouped).forEach((key) => {
      const channels = grouped[key];
      channels.sort((a, b) => moment(b.startDateTime).valueOf() - moment(a.startDateTime).valueOf());
      const latestChannVersion = channels[0];

      if (latestChannVersion) {
        availableChannels.push({
          value: latestChannVersion.externalIdentifier,
          text: latestChannVersion.description ? latestChannVersion.description : latestChannVersion.externalIdentifier
        });
      }
    });
  }

  const getCurrentFrequency = () => {
    let channel: ExternalChannelType | undefined;

    if (value.meter) {
      channel = value.meter.channels.find((chan) => {
        return chan.externalIdentifier === value.channelId;
      });
    }

    return channel ? channel.dataFrequency : dataFrequency.na;
  };

  const currentFreq = getCurrentFrequency();
  const hoursDisabled = currentFreq === dataFrequency.p1d || currentFreq === dataFrequency.na;
  const minutesDisabled =
    currentFreq === dataFrequency.pt1h || currentFreq === dataFrequency.p1d || currentFreq === dataFrequency.na;
  const minutes = MINUTESMAP.get(currentFreq);

  return (
    <div className={css['measurement-input-wrapper']}>
      {locationId && (
        <MetersDropdown
          id={`${meterIndex}-${measurementIndex}`}
          queryParams={{ sluuids: locationId }}
          selectedValue={value.meter ? value.meter.id : ''}
          onChange={(val) => setMeter(val[0])}
          error={!value.meter}
        />
      )}
      {availableChannels && availableChannels.length > 0 ? (
        <InputContainer>
          <SimpleDropdown
            id={`channel-dropdown-${meterIndex}-${measurementIndex}`}
            selectedValues={[value.channelId]}
            values={availableChannels.length > 0 ? availableChannels : []}
            onChange={(val) => dispatchValue({ channelId: val[0] })}
            placeholder={i18n.getTranslation('meter.channel')}
            error={!value.channelId}
          />
          <DatePicker
            id={`reading-date-${meterIndex}-${measurementIndex}`}
            onChange={(val) => {
              dispatchValue({ endDateTime: val.toString() });
            }}
            value={moment(value.endDateTime)}
            placeholder={i18n.getTranslation('meter.reading_date')}
          />
          {!(hoursDisabled && minutesDisabled) && (
            <>
              {!hoursDisabled && (
                <SimpleDropdown
                  id={`hours-dropdown-${meterIndex}-${measurementIndex}`}
                  selectedValues={[value.hour]}
                  values={getDropDownTimeVals(HOURS, currentFreq)}
                  onChange={(val) => dispatchValue({ hour: val[0] })}
                  placeholder={i18n.getTranslation('meter.hour')}
                />
              )}

              {!minutesDisabled && (
                <SimpleDropdown
                  id={`minutes-dropdown-${meterIndex}-${measurementIndex}`}
                  selectedValues={[value.minute]}
                  values={minutes ? getDropDownTimeVals(minutes, currentFreq) : []}
                  onChange={(val) => dispatchValue({ minute: val[0] })}
                  placeholder={i18n.getTranslation('meter.minute')}
                />
              )}
            </>
          )}
          <FloatInput
            id={`value-measurement-${meterIndex}-${measurementIndex}`}
            onChange={(val) => dispatchValue({ value: val })}
            value={value.value}
            placeholder={i18n.getTranslation('general.value')}
            error={typeof value.value === 'undefined'}
          />
        </InputContainer>
      ) : (
        <Paragraph>{i18n.getTranslation('meter.no_active_channels')}</Paragraph>
      )}
    </div>
  );
}
