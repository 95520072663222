import React from 'react';

import { SocialTariffExportRowType } from '@zf/api-types/regionals/regionals';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useDownload from '../../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { notify } from '../../../../events/notification-events';

export default function DownloadSocialTariffListPageButton() {
  const { i18n, tenantReducer } = useAppContext();
  const { downloadSingleFile } = useDownload();
  const { myCurrent } = useCurrent('country_specifics');
  const selectedRows: SocialTariffExportRowType[] = myCurrent.listPage.selectedRows;

  const disabled = selectedRows.length === 0 || !selectedRows.some((r) => r.__entity.generated);

  return (
    <DropdownAction
      id="social_tariff.download"
      icon="download-cloud"
      actionType="direct"
      onClick={() => {
        const selectedIds = selectedRows.map((row) => row.__entity.id);

        try {
          selectedIds.forEach((id) => {
            downloadSingleFile(`/reg/socialtariff/exports/${id}/download`, tenantReducer);
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.payments.download_failed'),
            error: e
          });
        }
      }}
      disabled={disabled}
    >
      {i18n.getTranslation('actions.download')}
    </DropdownAction>
  );
}
