import React from 'react';

import { PropertyGroupRowType, PropertyGroupType } from '@zf/api-types/master-data/property-group';
import useDialog from '@zf/hooks/src/useDialog';

import AddLocationDialog from '../../../actions/property-group/add-location-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  selectedRows?: Array<PropertyGroupRowType>;
  propertyGroup: PropertyGroupType;
  refresh: () => void;
};

export default function AddLocationToPropertyGroupListItem(props: Props) {
  const { propertyGroup, selectedRows, refresh } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="propertygroup.update_locations"
      icon="location"
      dialogTitle={i18n.getTranslation('actions.add_location_to_property')}
      dialogContent={
        <AddLocationDialog
          ref={clickRef}
          selectedRows={selectedRows}
          validationRef={validationRef}
          propertyGroup={propertyGroup}
          refresh={refresh}
        />
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      dialogLocation="right"
      buttonPositive={i18n.getTranslation('actions.add_location')}
    />
  );
}
