import { observer } from 'mobx-react';
import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';

import DateRangePicker from '../../../../../../components/input/DateRangePicker';
import { useStore } from '../../../../../../hooks/useStore';
import css from './table-header.module.scss';

const TableHeader = observer(() => {
  const { contractStore, applicationStore } = useStore();

  const { filters, setFilters } = contractStore.billingCompletenessStore;

  return (
    <div className={css['table-header']}>
      <Heading headingType="h2" style="bold">
        {applicationStore.getTranslation('contracts.choose_invoice_period')}
      </Heading>
      <DateRangePicker
        id="invoice-period"
        startDate={filters.periodStartDate}
        endDate={filters.periodEndDate}
        setDates={(dates) => setFilters({ periodStartDate: dates[0], periodEndDate: dates[1] })}
        darkMode
      />
    </div>
  );
});

export default TableHeader;
