import { observer } from 'mobx-react';
import React from 'react';

import { EntityAttachmentFile } from '@zf/api-types/entity-attachments/entity-attachments';
import { culture, entitySubjectType } from '@zf/api-types/enums';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import { AttachmentState } from '../logic/AttachmentGroupBase';
import FilesStore, { attachmentType } from '../logic/FilesStore';
import AddEditEntityAttachmentListItem from './actions/AddEditEntityAttachmentListItem';
import DeleteAttachmentListItem from './actions/DeleteAttachmentListItem';
import DownloadEntityAttachmentListItem from './actions/DownloadEntityAttachmentListItem';

type Props<T> = {
  children: React.ReactNode;
  filesStore: FilesStore<T>;
  culture?: culture;
  enableTranslations?: boolean;
  attachmentType: attachmentType;
  listFiles: (attachment?: T) => EntityAttachmentFile[];
  setState?: (value: Partial<AttachmentState>) => void;
  state?: AttachmentState;
  entityId?: string;
  subjectType: entitySubjectType;
};

/**
 * This component acts as a wrapper around the other detail page actions
 */

const FilesActions = <T,>(props: Props<T>) => {
  const {
    children,
    filesStore,
    culture,
    enableTranslations,
    subjectType,
    attachmentType,
    state,
    entityId,
    setState,
    listFiles
  } = props;
  const { createRequestService } = filesStore;
  const { selectedIds } = createRequestService;

  React.useEffect(() => {
    filesStore.setPermissions();
  }, []);

  return (
    <>
      <AddEditEntityAttachmentListItem
        subjectType={subjectType}
        setState={setState}
        listFiles={listFiles}
        entityId={entityId}
        state={state}
        attachmentType={attachmentType}
        filesStore={filesStore}
        useCase="edit"
        showLangPref={!!culture}
        enableTranslations={enableTranslations}
      />

      <DownloadEntityAttachmentListItem filesStore={filesStore} culture={culture} />
      <DeleteAttachmentListItem filesStore={filesStore} />

      {/* If one of the actions above is active, add divider */}
      {selectedIds.length > 0 && <HorizontalDivider />}

      {children}

      <AddEditEntityAttachmentListItem
        subjectType={subjectType}
        state={state}
        setState={setState}
        listFiles={listFiles}
        entityId={entityId}
        attachmentType={attachmentType}
        filesStore={filesStore}
        showLangPref={!!culture}
        useCase="add"
        enableTranslations={enableTranslations}
      />
    </>
  );
};

export default observer(FilesActions);
