import React from 'react';

import { ServiceLocationRowType, ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { ProductType } from '@zf/api-types/product';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import ProductAutoFill from '../../components/Autofills/ProductAutoFill';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { createHeader, METHODS, sendRequest } from '../../utils/request';

type Props = {
  selectedRows: ServiceLocationRowType[];
  setUpdatedRows: (updatedRows: ServiceLocationType[]) => void;
};

const ChangeProductDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, setUpdatedRows } = props;
  const { i18n, tenantReducer } = useAppContext();

  const [product, setProduct] = React.useState<ProductType | null>(null);

  const updateLocation = async (location: ServiceLocationType) => {
    const res = await sendRequest<ServiceLocationType>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/servicelocations/${location.id}/updateproduct`,
        data: {
          productId: product?.id
        }
      },
      customHeaders: createHeader({
        'If-Match': location._etag
      }),
      tenantReducer,
      lang: i18n.lang
    });

    return res.data;
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      await Promise.all(
        selectedRows.map((row) => {
          return updateLocation(row.__entity);
        })
      )
        .then((updatedRows) => {
          setUpdatedRows(updatedRows);

          notify.success({
            content: i18n.getTranslation(
              `actions.location.update_product_success${selectedRows.length > 1 ? '_multi' : ''}`
            )
          });
        })
        .catch((e) => {
          notify.error({
            content: i18n.getTranslation(
              `actions.location.update_product_fail${selectedRows.length > 1 ? '_multi' : ''}`
            ),
            error: e
          });
        });
    }
  }));

  return (
    <>
      <Paragraph>{i18n.getTranslation('actions.location.choose_product_paragraph')}</Paragraph>
      <InputContainer>
        <ProductAutoFill
          id="product"
          onChange={(val) => setProduct(val[0])}
          selectedValues={[product?.id || '']}
          error={!product}
        />
      </InputContainer>
    </>
  );
});

export default ChangeProductDialog;
