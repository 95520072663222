import clone from 'clone';
import React from 'react';

import {
  PropertyGroupBillingConfigurationType,
  PropertyGroupCalculationConfigurationType,
  RequiredIncomingInvoiceComponentsType
} from '@zf/api-types/property-group-billing-configuration';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import OverflowParagraph from '@zf/stella-react/src/atoms/Paragraph/OverflowParagraph';
import { RowType } from '@zf/stella-react/src/atoms/Table';

import { useAppContext } from '../../app-context';
import InputField from '../../components/input/InputField';
import SearchBar from '../../components/input/SearchBar';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../components/placeholder';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import SelectionDialogLayout from '../../design-system/ComponentSets/Dialog/layouts/SelectionDialogLayout';
import { notify } from '../../events/notification-events';
import { PropertyGroupBillingConfigurationRequestType } from '../../features/property-group/detail-page/billing/hooks/use-billing-data';
import css from './add-allocation-group-dialog.module.scss';
import useLocationList from './hooks/useLocationList';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  groups: PropertyGroupCalculationConfigurationType[];
  propertyGroupBillingConfiguration: PropertyGroupBillingConfigurationType;

  // Edit
  allocationGroup?: PropertyGroupCalculationConfigurationType;

  addAllocationGroupsToState: (allocationGroups: PropertyGroupCalculationConfigurationType[] | null) => void;
  submitAllocationGroup: (
    apiFriendlyValues: PropertyGroupBillingConfigurationRequestType
  ) => Promise<PropertyGroupBillingConfigurationType>;
};

type State = {
  id: string;
  groupName: string;
  searchValue: string;
};

const AddAllocationGroupsDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const {
    groups,
    validationRef,
    allocationGroup,
    propertyGroupBillingConfiguration,
    addAllocationGroupsToState,
    submitAllocationGroup
  } = props;

  const propertyGroupId = propertyGroupBillingConfiguration.propertyGroup?.id;

  const locationsToFilter: string[] = [];

  groups.forEach((g) => {
    locationsToFilter.push(...g.serviceLocationIds);
  });

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    id: '',
    groupName: allocationGroup ? allocationGroup.name : '',
    searchValue: ''
  });

  const queryParameters = {
    propertyGroupId: propertyGroupId
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    refresh,
    selectAllBusy: selectAllBusy_,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useLocationList(values.searchValue, 'add_allocation_group', queryParameters);

  // Order rows, selected first
  const selectedRows: RowType[] = [];
  const otherRows: RowType[] = [];

  rows.forEach((r) => {
    if (selectedIds.includes(r.__id)) {
      selectedRows.push(r);
    } else if (!locationsToFilter.includes(r.__id)) {
      otherRows.push(r);
    }
  });

  const { i18n } = useAppContext();

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(selectedIds.length === 0 || !values.groupName);
    }
  };

  React.useEffect(() => {
    if (allocationGroup) {
      setSelectedIds(allocationGroup.serviceLocationIds);
    }
  }, []);

  React.useEffect(() => {
    validate();
  }, [selectedIds, values.groupName]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        let apiFriendlyValues: {
          requiredIncomingInvoiceComponents: RequiredIncomingInvoiceComponentsType[];
          calculationConfigurations: PropertyGroupCalculationConfigurationType[];
        };

        if (allocationGroup) {
          // Edit
          const groupsClone = clone(groups);
          const indexToUpdate = groups.findIndex((g) => {
            return g.id === allocationGroup.id;
          });

          if (indexToUpdate !== -1) {
            groupsClone[indexToUpdate].name = values.groupName;
            groupsClone[indexToUpdate].serviceLocationIds = selectedIds;
          }

          apiFriendlyValues = {
            requiredIncomingInvoiceComponents:
              propertyGroupBillingConfiguration.requiredIncomingInvoiceComponents || [],
            calculationConfigurations: [...groupsClone]
          };
        } else {
          // Add
          apiFriendlyValues = {
            requiredIncomingInvoiceComponents:
              propertyGroupBillingConfiguration.requiredIncomingInvoiceComponents || [],
            calculationConfigurations: [
              ...groups,
              {
                id: values.id,
                name: values.groupName,
                serviceLocationIds: selectedIds,
                billingItems: []
              }
            ]
          };
        }

        const updatedBillingConfig = await submitAllocationGroup(apiFriendlyValues);

        addAllocationGroupsToState(updatedBillingConfig.calculationConfigurations);

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.add_allocation_group_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.add_allocation_group_fail'),
          error: e
        });
      }
    }
  }));

  const renderListItem = (id: string, index: number) => {
    const correspondingLocation = rows.find((r) => {
      return r.__id === id;
    });

    if (correspondingLocation) {
      return (
        <OverflowParagraph
          id={`${correspondingLocation.__id}-${index}`}
          key={`${correspondingLocation.__id}-${index}`}
          tooltipFor="locations-table-tip"
          className={css['locations-displayAdress']}
        >
          {correspondingLocation.DisplayAddress}
        </OverflowParagraph>
      );
    }
    return null;
  };

  return (
    <SelectionDialogLayout
      className={css['dialog-layout']}
      title={i18n.getTranslation('property_groups.tabs.billing.add_group_heading')}
      heading={i18n.getTranslation('location.serviced_locations')}
      selectionListHeading={i18n.getTranslation('property_groups.tabs.billing.selected_locations')}
      topInputs={
        <InputContainer className={css['inputs']}>
          <InputField
            id="group-name"
            onChange={(newName) => setValue({ groupName: newName })}
            value={values.groupName}
            placeholder={i18n.getTranslation('general.name')}
            error={!values.groupName}
            darkMode
          />
        </InputContainer>
      }
      filterInputs={
        <SearchBar
          id="filter-searchbar"
          className={css['searchbar']}
          onChange={(val) => setValue({ searchValue: val })}
          value={values.searchValue}
          placeholder={i18n.getTranslation('locations_list.filter.search_placeholder')}
          debounceTime={500}
          darkMode
        />
      }
      items={
        <DynamicVirtualTable
          id="locations-table"
          tooltipId="locations-table-tip"
          onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
          rows={[...selectedRows, ...otherRows]}
          loading={loading}
          error={error}
          selectedRows={selectedIds}
          sortableFields={sortableFields}
          sortBy={sortBy}
          sortDirection={sortDirection}
          totalAmountOfRows={totalAmountOfRows}
          selectAllBusy={selectAllBusy_}
          refresh={refresh}
          sort={handleSort}
          onSelectRow={setSelectedIds}
          NoDataOverlay={TableNoData}
          LoadingOverlay={TableLoading}
          ErrorOverlay={TableError}
          singleSort
          columns={[
            {
              width: 550,
              label: i18n.getTranslation('location.location'),
              dataKey: 'DisplayAddress',
              noClick: true
            },
            {
              width: 100,
              label: i18n.getTranslation('locations_list.labels.services'),
              dataKey: 'services'
            },
            {
              width: 200,
              label: i18n.getTranslation('locations_list.labels.supplied_services'),
              dataKey: 'suppliedServices'
            },
            {
              width: 550,
              label: i18n.getTranslation('customer.customers'),
              dataKey: 'customer'
            }
          ]}
        />
      }
      selectedItems={
        <div className={css['selected-list']}>
          {selectedIds.map((id, index) => {
            return renderListItem(id, index);
          })}
        </div>
      }
    />
  );
});

export default AddAllocationGroupsDialog;
