import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { Wizard, WizardNav } from '@zf/stella-react/src/atoms/Wizard';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import WizardContainer from '../../../../components/Wizard/WizardContainer';
import MeterWizard, { STEP_NAMES } from './MeterWizard';

const AddMeter = () => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  useBrowserTitle(getTranslation('meter.add_meter'));

  const [focusedStep, setFocusedStep] = React.useState(STEP_NAMES[0]);
  const [feedback, setFeedback] = React.useState<string[][]>([]);

  return (
    <WizardContainer
      icon="meter-add"
      text={getTranslation('wizard.breadcrumb', {
        subject: getEnumTranslation('entitySubjectType', entitySubjectType.meter)
      })}
    >
      <Wizard>
        <MeterWizard feedback={feedback} onFocusStep={setFocusedStep} setFeedback={setFeedback} />
      </Wizard>
      <WizardNav
        steps={[
          {
            header: getTranslation('meter.steps.essentials'),
            name: STEP_NAMES[0],
            feedback: feedback[0]
          },
          {
            header: getTranslation('meter.steps.channels'),
            name: STEP_NAMES[1],
            feedback: feedback[1]
          },
          {
            header: getTranslation('errors.more_problems'),
            name: STEP_NAMES[2],
            feedback: feedback[2],
            error: true
          }
        ]}
        activeStep={focusedStep}
        onNavigate={setFocusedStep}
      />
    </WizardContainer>
  );
};

export default observer(AddMeter);
