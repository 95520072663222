import useFilter from 'app-context/hooks/use-filter';
import FilterTag from 'components/Filters/filter-tag';
import FilterBarNew from 'components/Filters/FilterBarNew';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

import { MeterType } from '@zf/api-types/master-data/meter';

import { useStore } from '../../../../../hooks/useStore';
import { useMeterIssuesContext } from '../Context/Context';
import { ListPageContext } from '../../../issues/Context/types';
import LeftFilters from './LeftFilters';
import RightFilters from './RightFilters';
import { Query } from './types';

const IssuesFilterBar = ({ meter }: { meter: MeterType }) => {
  const {
    setFilter,
    store: { filter }
  } = useMeterIssuesContext();

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const paramTypes = {
    noticedStartDateTime: StringParam,
    noticedEndDateTime: StringParam,
    statuses: ArrayParam,
    quickFilter: StringParam,
    refreshTimestamp: StringParam
  };

  const [query, setQuery] = useQueryParams(paramTypes);

  /**
   * @description READ URL for queryParams, if undefined, fallback to defaults and set them to the form
   */
  //

  const { setValue, watch, getValues, reset } = useForm<Partial<Query>>({
    defaultValues: {
      noticedStartDateTime: query.noticedStartDateTime,
      noticedEndDateTime: query.noticedEndDateTime,
      statuses: [...new Set(query.statuses)],
      quickFilter: query.quickFilter || 'unresolved',
      refreshTimestamp: query.refreshTimestamp || undefined
    }
  });

  /**
   * @description align the filter context with whatever is on the useForm (ergo the URL)
   */
  useEffect(() => {
    /**
     * @description Time out to avoid weird overwrite
     */
    setTimeout(() => {
      setFilter({
        quickFilter: getValues('quickFilter'),
        queryParams: {
          noticedStartDateTime: getValues('noticedStartDateTime'),
          noticedEndDateTime: getValues('noticedEndDateTime'),
          statuses: getValues('statuses'),
          refreshTimestamp: getValues('refreshTimestamp')
        }
      });
    }, 100);
  }, []);

  /**
   * @description update query params as well as context when you are changing filter components
   */
  useEffect(() => {
    const subscription = watch((query) => {
      const { quickFilter, ...queryParams } = query;
      setFilter({
        queryParams,
        quickFilter
      });
      setQuery(query as any);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const initialOverviewCount = {
    all: 0,
    unresolved: 0
  };

  const { overviewCount } = useFilter<ListPageContext['store']['filter']['queryParams']>({
    endpoint: '/me/MeteringIssues/overviewcount',
    initialCount: initialOverviewCount,
    domain: 'meter-issues',
    search: '',
    initialQueryParams: filter.queryParams,
    defaultQueryParams: { meterIds: [meter.id] },
    paramTypes
  });

  const filterTags: JSX.Element[] = [];
  const statusesFilterTags: JSX.Element[] = [];

  if (typeof filter.queryParams.statuses?.length !== 'undefined' && filter.queryParams.statuses?.length > 0) {
    statusesFilterTags.sort();

    for (let i = 0; i < filter.queryParams.statuses.length; i++) {
      let status = filter.queryParams.statuses[i];

      filterTags.push(
        <FilterTag
          type="array"
          enumType="meteringIssueStatus"
          value={[status]}
          removeFilter={() => {
            setValue(
              'statuses',
              filter.queryParams.statuses.filter((s: any) => {
                return s !== status;
              })
            );
          }}
          key={`status.tag.${i}`}
          label={getTranslation('general.status')}
        />
      );
    }
  }

  if (filter.queryParams.noticedStartDateTime || filter.queryParams.noticedEndDateTime) {
    filterTags.push(
      <FilterTag
        type="period"
        value={[filter.queryParams.noticedStartDateTime, filter.queryParams.noticedEndDateTime]}
        removeFilter={() => {
          setValue('noticedStartDateTime', undefined);
          setValue('noticedEndDateTime', undefined);
        }}
        key="detected-between.tag"
        label={getTranslation('general.period')}
      />
    );
  }

  return (
    <form>
      <FilterBarNew
        tags={filterTags}
        defaultTab="meters"
        leftFilters={<LeftFilters reset={reset} setValue={setValue} overviewCount={overviewCount} />}
        rightFilters={<RightFilters setValue={setValue} getValues={getValues} />}
        searchPlaceHolder={getTranslation('meter.search_placeholder')}
        searchDebounce={500}
      />
    </form>
  );
};

export default observer(IssuesFilterBar);
