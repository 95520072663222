import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../app-context';

export default function NoExternalMeasurements() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/graph.png"
          alt="graph"
          title="graph"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('import_jobs.no_external_measurements')}
    >
      {i18n.getTranslation('import_jobs.no_external_measurements_desc')}
    </CardEmptyBody>
  );
}
