import React from 'react';

import { CollectionFlowStepType } from '@zf/api-types/collection-cycles';
import useDialog from '@zf/hooks/src/useDialog';
import Introduction from '@zf/stella-react/src/atoms/Introduction/Introduction';

import { useAppContext } from '../../../app-context';
import ConfirmationActionButton from '../../../components/Button/ConfirmationActionButton';
import { WrappableVirtualizedList } from '../../../design-system/ComponentSets';
import AddCycleDialog from './add-cycle-dialog/add-cycle-dialog';
import css from './collection-flows.module.scss';
import { UseCollectionCyclesReturnType } from './hooks/useCollectionCycles';
import VirtualCollectionFlow from './VirtualCollectionFlow';

type Props = {
  collectionCyclesTools: UseCollectionCyclesReturnType;
};

export default function CollectionFlows(props: Props) {
  const { collectionCyclesTools } = props;
  const {
    collectionFlows,
    collectionSteps,
    addFlow,
    updateFlow,
    removeFlow,
    addStep,
    updateStep,
    removeStep,
    setHookState
  } = collectionCyclesTools;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const items = collectionFlows.map((flow, index) => {
    const steps = flow.stepIds.reduce((acc: CollectionFlowStepType[], sId) => {
      const matchingStep = collectionSteps.find((s) => s.id === sId);

      if (matchingStep) acc.push(matchingStep);

      return acc;
    }, []);

    return {
      key: `${flow.id}-${index}`,
      flow,
      flowIndex: index,
      steps,
      addStep,
      updateStep,
      removeStep,
      updateFlow,
      removeFlow
    };
  });

  return (
    <>
      <ConfirmationActionButton
        id="add-cycle"
        className={css['add-btn']}
        dialogTitle={i18n.getTranslation('collection_flows.add_flow')}
        icon="plus"
        dialogContent={
          <AddCycleDialog
            ref={clickRef}
            validationRef={validationRef}
            collectionSteps={collectionSteps}
            addFlow={addFlow}
            addStep={addStep}
            setHookState={setHookState}
          />
        }
        onSubmit={onSubmit}
        buttonPositive={i18n.getTranslation('collection_flows.btn_add_flow')}
        dialogLocation="right"
        validationRef={validationRef}
      />

      <div className={css['wrapper']}>
        {collectionFlows.length === 0 ? (
          <Introduction
            className={css['intro']}
            headerText={i18n.getTranslation('collection_flows.intro_heading')}
            introductionText={i18n.getTranslation('collection_flows.intro_descr')}
          />
        ) : (
          <WrappableVirtualizedList
            key={items.length}
            items={items}
            ItemComponent={VirtualCollectionFlow}
            columnCount={1}
          />
        )}
      </div>
    </>
  );
}
