import { pluginType } from '@zf/api-types/enums';
import { ConfiguredPluginType } from '@zf/api-types/integration/plugin';

import { METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';

export type ConfigRequestsBaseType = {
  commandName: string;
  pluginType: pluginType;
  _type: string;
};

export enum actionQueryType {
  getadvancelimit = 'getadvancelimit',
  updatebillingsettings = 'updatebillingsettings'
}

export default class CustomPluginActionsService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/int/CustomPluginActions/', applicationStore);
  }

  migration = async (pluginId: string, plugin: pluginType) => {
    let PascalPlugin = '';

    switch (plugin) {
      case 'enduserportal':
        PascalPlugin = 'EndUserPortal';
        break;

      case 'freshdeskcrm':
        PascalPlugin = 'FreshdeskCRM';
        break;

      case 'zendeskcrm':
        PascalPlugin = 'ZendeskCRM';
        break;

      case 'paytbillingplugin':
        PascalPlugin = 'PaytBillingPlugin';
        break;

      default:
        break;
    }

    return (
      await this.applicationStore.sendRequest<ConfiguredPluginType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${pluginId}/command/Migration`,
          data: {
            _type: `${PascalPlugin ? PascalPlugin : plugin}.ExecuteMigrationCommand`
          }
        }
      })
    ).data;
  };

  getCustomPluginActionsForPluginAndQuery = async <T>(pluginType: pluginType, queryType: actionQueryType) => {
    return (
      await this.applicationStore.sendRequest<T>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${pluginType}/${queryType}`
        }
      })
    ).data;
  };

  getQueryRequestsForPlugin = async <T>(pluginType: pluginType) => {
    return (
      await this.applicationStore.sendRequest<T>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${pluginType}/query/requests`
        }
      })
    ).data;
  };

  getQueryResponsesForPlugin = async <T>(pluginType: pluginType) => {
    return (
      await this.applicationStore.sendRequest<T>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${pluginType}/query/responses`
        }
      })
    ).data;
  };

  getCommandRequestsForPlugin = async <T>(pluginType: pluginType) => {
    return (
      await this.applicationStore.sendRequest<T>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${pluginType}/command/requests`
        }
      })
    ).data;
  };

  getConfigRequestsForPlugin = async <T>(pluginType: pluginType) => {
    return (
      await this.applicationStore.sendRequest<(ConfigRequestsBaseType & T)[]>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${pluginType}/config/requests`
        }
      })
    ).data;
  };

  postConfigCommandForPlugin = async (pluginType: pluginType, commandName: string, apiFriendlyValues: any) => {
    return (
      await this.applicationStore.sendRequest<any>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${pluginType}/config/${commandName}`,
          data: apiFriendlyValues
        }
      })
    ).data;
  };
}
