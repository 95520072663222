import React from 'react';

import { GetTaxCodeResponse, TaxCodeType } from '@zf/api-types/tax-codes';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../app-context/app-context';
import useSingleAPI from '../../../hooks/useSingleAPI';
import { correctRates, formatCorrectedTaxCode } from '../../../utils/taxCode';
import Dropdown from '../../Lang/Dropdown';

export type PaymentTermDropdownProps = {
  id: string;
  isInline?: boolean;
  selectedValue?: string;
  onFocus?: (step: string) => void;
  onChange: (values: TaxCodeType[]) => void;
  placeholder?: string;
  error?: boolean;
};

const InlineDropdown = InlineInputField<DropdownProps<TaxCodeType>>(Dropdown);

export default function TaxCodeDropDown(props: PaymentTermDropdownProps) {
  const { id, selectedValue = '', isInline = false, onFocus, onChange, placeholder, error } = props;
  const { i18n } = useAppContext();

  const taxCodesResponse = useSingleAPI<GetTaxCodeResponse>({
    request: {
      endpoint: '/cfg/TaxCodes'
    }
  });

  if (!taxCodesResponse || !taxCodesResponse.result || taxCodesResponse.result instanceof Promise) {
    return isInline ? (
      <InlineDropdown id={id} onChange={onChange} values={[]} selectedValues={[selectedValue]} onFocus={onFocus} />
    ) : (
      <Dropdown
        id={id}
        onChange={onChange}
        placeholder={placeholder ? placeholder : i18n.getTranslation('billing_items.taxCode')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    );
  }

  const taxCodes = taxCodesResponse.result.data.results;

  const formatedTaxCodes = taxCodes.map((e) => {
    return correctRates(e);
  });

  const dropdownValues = formatedTaxCodes.map((value) => {
    return {
      value: value,
      text: `${formatCorrectedTaxCode(value)}`
    };
  });

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={dropdownValues}
      selectedValues={[selectedValue || '']}
      onFocus={onFocus}
      error={error}
    />
  ) : (
    <Dropdown
      id={id}
      onChange={onChange}
      placeholder={placeholder || i18n.getTranslation('billing_items.taxCode')}
      values={dropdownValues}
      selectedValues={[selectedValue || '']}
      onFocus={onFocus}
      error={error}
    />
  );
}
