import React from 'react';

import { dataImportCommunicationType } from '@zf/api-types/enums';
import { ImportJobRowType } from '@zf/api-types/import-job';

import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';

export default function DownloadImportJobsListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('importjob');
  const { downloadFiles } = useDownload();
  const selectedRows: ImportJobRowType[] = myCurrent.listPage.selectedRows;

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.find(
      (row) =>
        row.__internalFilePath === '' ||
        !row.__internalFilePath ||
        row.communicationType === dataImportCommunicationType.configuredPlugin
    ) !== undefined;

  const download = () => {
    const ids = selectedRows.map((row) => {
      return row.__id;
    });

    downloadFiles(ids, '/logs/ImportJob/downloadzip', '/logs/ImportJob/', 'importJobIds');
  };

  return (
    <DropdownAction
      id="import_jobs.download"
      icon="download-cloud"
      actionType="direct"
      onClick={() => download()}
      disabled={disabled}
    >
      {i18n.getTranslation('actions.download')}
    </DropdownAction>
  );
}
