import React from 'react';

import { APIExportJobType, ExportJobType } from '@zf/api-types/export-job';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteExportJobDialog from '../../../actions/export-jobs/delete-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  exportJob: ExportJobType;
};

export default function DeleteExportJobListItem(props: Props) {
  const { exportJob } = props;
  const { i18n } = useAppContext();

  const { clickRef, onSubmit } = useDialog();

  const exportJobToHandle: APIExportJobType = {
    etag: exportJob._etag,
    id: exportJob.id
  };

  return (
    <DropdownAction
      id="export_jobs.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={<DeleteExportJobDialog ref={clickRef} exportJobToHandle={exportJobToHandle} />}
      onSubmit={onSubmit}
      dialogType="danger"
      buttonPositive={i18n.getTranslation('general.delete')}
    />
  );
}
