import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { AdvancePeriodType } from '@zf/api-types/billing/billing-completeness';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

import { useStore } from '../../../../../../hooks/useStore';
import Advance, { AdvanceIconType } from './Advance';
import css from './advance-tooltip.module.scss';

type Props = {
  advancePeriod: AdvancePeriodType;
  statusTranslation: string;
  advanceIconType: AdvanceIconType;
};

const AdvanceTooltip = observer((props: Props) => {
  const { configStore } = useStore();
  const { configService, _taxCodes, _billingParameters } = configStore;

  useEffect(() => {
    configService.getTaxCodes().then(() => configService.getBillingParameters());
  }, []);

  return _taxCodes && _billingParameters ? (
    <Advance {...props} />
  ) : (
    <div className={css['spinner-wrapper']}>
      <Spinner size="small" />
    </div>
  );
});

export default AdvanceTooltip;
