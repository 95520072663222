import React from 'react';

type Props = {
  children: React.ReactNode;
  path?: string;
  default?: boolean;
};

export default function RouteWrapper(props: Props) {
  return <React.Fragment>{props.children}</React.Fragment>;
}
