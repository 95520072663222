import DateRangePickerLabel from 'components/input/DateRangePickerLabel';
import DropdownWithLabel from 'design-system/Components/Inputs/Dropdown/BareDropdown';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { DropdownGenericBaseType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import { useMeterIssuesContext } from '../Context/Context';
import { Query } from './types';
import css from '../../../issues/list-page/right-filters.module.scss';

type Props = {
  getValues: UseFormGetValues<Partial<Query>>;
  setValue: UseFormSetValue<Partial<Query>>;
};

const RightFilters: FC<Props> = ({ getValues, setValue }) => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const ctx = useMeterIssuesContext();
  const { quickFilter } = ctx.store.filter;

  return (
    <div className={css['right-filter-bare-dropdowns']}>
      <DateRangePickerLabel
        id="detected-between"
        startDate={convertToMoment(getValues('noticedStartDateTime'))}
        endDate={convertToMoment(getValues('noticedEndDateTime'))}
        label={getTranslation('meter_issues.detected_between')}
        setDates={(dates) => {
          if (
            convertMomentToISOString(dates[1]) === '9999-12-31T23:59:59.999Z' &&
            convertMomentToISOString(dates[0]) === '0001-01-01T00:00:00.000Z'
          ) {
            setValue('noticedEndDateTime', undefined);
            setValue('noticedStartDateTime', undefined);
          } else {
            setValue('noticedStartDateTime', convertMomentToISOString(dates[0]));
            setValue('noticedEndDateTime', convertMomentToISOString(dates[1]));
          }
        }}
      />
      {quickFilter === 'all' && (
        <DropdownWithLabel
          id="dropdown-status"
          label={getTranslation('general.status')}
          selectedValues={[...new Set<string>(getValues('statuses') as string[])]}
          values={getEnum<DropdownGenericBaseType>('meteringIssueStatus')}
          onChange={(val) => setValue('statuses', val as any)}
          placeholder={getTranslation('import_jobs.file_format')}
          isMultipleSelected
          colorized
        />
      )}
    </div>
  );
};

export default observer(RightFilters);
