import React from 'react';

export default function useFocus(
  focused: boolean,
  ref: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  selectAllOnFocus?: boolean
) {
  const blur = () => {
    if (ref && ref.current) {
      const activeElement = document.activeElement;
      if (activeElement && activeElement.isSameNode(ref.current)) {
        ref.current.blur();
      }
    }
  };

  const focus = () => {
    if (ref && ref.current) {
      const activeElement = document.activeElement;
      if (selectAllOnFocus) ref.current.select();
      if (activeElement && activeElement.isSameNode(ref.current)) return;
      ref.current.focus();
    }
  };

  React.useLayoutEffect(() => {
    if (focused) {
      focus();
    } else {
      blur();
    }

    return () => blur();
  }, [focused]);
}
