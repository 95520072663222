import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../../hooks/useStore';
import CollectionSendingDetails from './collection-sending-details';
import SendingDetailsCardBody from './sending-details-card-body';
import css from './sending-details-card.module.scss';

const SendingDetailsCard = () => {
  const { applicationStore, communicationStore } = useStore();
  const { selectedEntitySubjectType } = communicationStore.templatesStore;
  const { getTranslation } = applicationStore;

  return (
    <Card id="sending-options-card">
      <CardHeader>{getTranslation('communication.sending')}</CardHeader>
      <CardBody>
        {selectedEntitySubjectType === entitySubjectType.collectioncase ? (
          <CollectionSendingDetails />
        ) : (
          <div className={css['sending-options']}>
            <SendingDetailsCardBody />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default observer(SendingDetailsCard);
