import React from 'react';

import {
  billingCalculationType,
  customEntityPropertyValueType,
  dataFrequency,
  direction,
  entitySubjectType,
  incrementationType,
  invoiceType,
  meteringType,
  unitOfMeasure,
  utilityType
} from '@zf/api-types/enums';
import StellaSimpleDropdown, {
  SimpleDropdownProps
} from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../app-context/app-context';

export default function SimpleDropdown<T>(props: SimpleDropdownProps<T>) {
  const { id, selectedValues, values, clearText, emptyText, clear = false, onChange } = props;
  const { i18n } = useAppContext();

  return (
    <StellaSimpleDropdown
      {...props}
      id={id}
      values={values}
      selectedValues={selectedValues}
      clearText={clearText ? clearText : i18n.getTranslation('dropdown.clear')}
      emptyText={emptyText ? emptyText : i18n.getTranslation('dropdown.empty')}
      onChange={onChange}
      clear={clear}
    />
  );
}

export const InlineInvoiceTypeDropdown = InlineInputField<SimpleDropdownProps<invoiceType>>(SimpleDropdown);

export const InlineBillingCalcTypeDropdown =
  InlineInputField<SimpleDropdownProps<billingCalculationType>>(SimpleDropdown);

export const InlineDataFreqDropdown = InlineInputField<SimpleDropdownProps<dataFrequency>>(SimpleDropdown);
export const InlineIncrTypeDropdown = InlineInputField<SimpleDropdownProps<incrementationType>>(SimpleDropdown);
export const InlineMeteringTypeDropdown = InlineInputField<SimpleDropdownProps<meteringType>>(SimpleDropdown);
export const InlineUtilityTypeDropdown = InlineInputField<SimpleDropdownProps<utilityType>>(SimpleDropdown);
export const InlineDirectionDropdown = InlineInputField<SimpleDropdownProps<direction>>(SimpleDropdown);
export const InlineUoMDropdown = InlineInputField<SimpleDropdownProps<unitOfMeasure>>(SimpleDropdown);
export const EntitySubjectTypeDropdown = InlineInputField<SimpleDropdownProps<entitySubjectType>>(SimpleDropdown);
export const DataTypeDropdown = InlineInputField<SimpleDropdownProps<customEntityPropertyValueType>>(SimpleDropdown);
