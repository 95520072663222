import moment from 'moment';
import React from 'react';
import { Bar } from 'recharts';

import { aggregationFrequency, utilityType } from '@zf/api-types/enums';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { AggregatedServiceConsumptionsPerPeriodType } from '@zf/api-types/service-consumption';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import ZFBarChart from '@zf/stella-react/src/atoms/Chart/ZFBarChart';

import { useAppContext } from '../../../../../../app-context';
import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import { ICON_COLORS } from '../../../../../../constants/icons';
import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { groupBy } from '@zf/utils/src/array';
import { formatXAxisDateShort } from '../../../../../../utils/graph';
import { METHODS } from '../../../../../../utils/request';
import css from './consumptions.module.scss';

type Props = {
  propertyGroup: PropertyGroupType;
};

export default function TotalConsumptionChartCard(props: Props) {
  const { propertyGroup } = props;
  const { i18n, enumReducer } = useAppContext();

  const [groupByPeriod, setGroupByPeriod] = React.useState<aggregationFrequency>(aggregationFrequency.yearly);

  const response = useSuspenseSingleAPI<AggregatedServiceConsumptionsPerPeriodType[]>({
    request: {
      method: METHODS.POST,
      endpoint: '/me/ServiceConsumptions/aggregatedperperiod',
      data: {
        serviceLocationIds: propertyGroup.serviceLocationIds,
        groupByPeriod: groupByPeriod
      }
    }
  });

  if (!response.result) return null;

  const consumptions = response.result.data;

  const groupedByPeriod: Record<string, AggregatedServiceConsumptionsPerPeriodType[]> = groupBy(consumptions, 'period');
  const groupedByUtilityType: Record<utilityType, AggregatedServiceConsumptionsPerPeriodType[]> = groupBy(
    consumptions,
    'utilityType'
  );

  const chartData: ChartDataType[] = Object.keys(groupedByPeriod).map((key) => {
    const acc = {} as Record<utilityType, number>;

    const value = groupedByPeriod[key];

    value.forEach((v) => {
      acc[v.utilityType] = v.total;
    });

    const date = moment(key, 'YYYYMMDD');

    return { period: formatXAxisDateShort(groupByPeriod, date.toISOString()), ...acc };
  });

  const bars = Object.keys(groupedByUtilityType).map((key) => {
    const firstItem = groupedByUtilityType[key as utilityType][0];
    const uom = firstItem ? firstItem.unitOfMeasure : '';

    return (
      <Bar
        key={key}
        name={enumReducer.getTranslation('utilityType', key)}
        dataKey={key}
        fill={ICON_COLORS[key]}
        radius={[3, 3, 0, 0]}
        unit={enumReducer.getTranslation('unitOfMeasure', uom)}
      />
    );
  });

  const hasData = consumptions.length > 0;

  const aggregationFrequencies = enumReducer.getEnum<aggregationFrequency>('aggregationFrequency').filter((af) => {
    return af.value !== aggregationFrequency.none;
  });

  return (
    <BarChartCard
      subject="consumption"
      title={i18n.getTranslation('property_groups.tabs.metering.total_consumption')}
      width="2"
      className={css['consumption-chart']}
      extraRight={
        hasData && (
          <div className={css['consumption-chart-extra-right']}>
            <SimpleDropdown
              id="total-consumption-select-location"
              onChange={(val) => setGroupByPeriod(val[0])}
              values={aggregationFrequencies}
              selectedValues={[groupByPeriod]}
            />
          </div>
        )
      }
    >
      {hasData ? (
        <ZFBarChart
          subject="consumption"
          chartData={chartData}
          XdataKey="period"
          height="36rem"
          bars={bars}
          locale={i18n.culture}
          interval={groupByPeriod === aggregationFrequency.daily ? 30 : 'preserveStartEnd'}
        />
      ) : (
        <EmptyChartState title={i18n.getTranslation('property_groups.tabs.metering.no_consumption_data_yet')} />
      )}
    </BarChartCard>
  );
}
