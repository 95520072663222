import moment from 'moment';
import React from 'react';

import { customEntityPropertyValueType } from '@zf/api-types/enums';
import { isMaxDate, isMinDate } from '@zf/utils/src/date';

import { InlineFloatInput } from '../../components/input/FloatInput';
import { InlineInputFieldInput } from '../../components/input/InputField';
import { InlineNumberInput } from '../../components/input/NumberInput';
import DatePicker from '../../components/Lang/DatePicker';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { useStore } from '../../hooks/useStore';
import { CreateCustomEntityProperty } from './CustomEntityPropertiesCard';

type Props = {
  newCustomEntityProperty: CreateCustomEntityProperty;
  handleSave: () => void;
  setNewCustomProperty: (value: Partial<CreateCustomEntityProperty>) => void;
};

export default function NewValueInput(props: Props) {
  const { newCustomEntityProperty, setNewCustomProperty, handleSave } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  if (!newCustomEntityProperty.customEntityPropertyType) return null;

  const dataType = newCustomEntityProperty.customEntityPropertyType.valueType.type;

  if (dataType === customEntityPropertyValueType.number) {
    return (
      <InlineNumberInput
        id="entity_property.value_number"
        onChange={(valueNumber) => setNewCustomProperty({ valueNumber })}
        value={newCustomEntityProperty.valueNumber}
        onKeyDown={keyHandler}
        error={typeof newCustomEntityProperty.valueNumber !== 'number'}
      />
    );
  }

  if (dataType === customEntityPropertyValueType.decimal) {
    return (
      <InlineFloatInput
        id="entity_property.value_decimal"
        onChange={(valueDecimal) => setNewCustomProperty({ valueDecimal })}
        value={newCustomEntityProperty.valueDecimal}
        onKeyDown={keyHandler}
        error={typeof newCustomEntityProperty.valueDecimal !== 'number'}
        isCEP
      />
    );
  }

  if (dataType === customEntityPropertyValueType.boolean) {
    const dropdownValues = [
      { value: true, text: getTranslation('general.yes') },
      { value: false, text: getTranslation('general.no') }
    ];

    return (
      <SimpleDropdown
        id="entity_property.value_boolean"
        values={dropdownValues}
        selectedValues={[newCustomEntityProperty.valueBoolean]}
        onChange={(val) => setNewCustomProperty({ valueBoolean: val[0] || false })}
        error={typeof newCustomEntityProperty.valueBoolean !== 'boolean'}
      />
    );
  }

  if (dataType === customEntityPropertyValueType.datetime) {
    return (
      <DatePicker
        id="entity_property.value_dateTime"
        value={moment(newCustomEntityProperty.valueDateTime)}
        onChange={(val) => setNewCustomProperty({ valueDateTime: val.toISOString() })}
        error={isMinDate(newCustomEntityProperty.valueDateTime) || isMaxDate(newCustomEntityProperty.valueDateTime)}
      />
    );
  }

  return (
    <InlineInputFieldInput
      id="entity_property.value_string"
      onChange={(valueString) => setNewCustomProperty({ valueString })}
      value={newCustomEntityProperty.valueString}
      onKeyDown={keyHandler}
      error={!newCustomEntityProperty.valueString}
    />
  );
}
