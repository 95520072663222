import { observer } from 'mobx-react';
import React from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import CommitSection from '../../../components/config/commit-section';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';
import EnumTranslationCard from './cards/enumTranslationCard';

const TranslationsTab = () => {
  const { communicationStore, applicationStore } = useStore();
  const { translationStore } = communicationStore;

  const handleSave = async () => {
    try {
      await Promise.all(
        translationStore.enumTranslations?.reduce((acc: Promise<void>[], t) => {
          if (t.form._isDirty) {
            acc.push(t.form.save());
          }

          return acc;
        }, []) || []
      );

      translationStore.getEnumTranslations();

      notify.success({
        content: applicationStore.getTranslation('communication.update-enum-translation-success')
      });
    } catch (e) {
      notify.error({
        content: applicationStore.getTranslation('communication.update-enum-translation-failed'),
        error: e
      });
    }
  };

  const reset = () => {
    translationStore.enumTranslations?.forEach((e) => {
      if (e.form._isDirty) {
        e.form._reset();
      }
    });
  };

  return (
    <>
      <CommitSection handleCancel={reset} handleSave={handleSave} isDirty={translationStore.enumIsDirty} />
      <CardsContainer>
        <EnumTranslationCard />
      </CardsContainer>
    </>
  );
};

export default observer(TranslationsTab);
