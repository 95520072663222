import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../../app-context';
import BankAccountsDropdown from '../../../../components/Dropdown/bankaccounts-dropdown/BankAccountsDropdown';
import { STEP_NAMES } from './InvoiceWizard';

export type Props = {
  companyBankAccountId: string | null;
  setCompanyBankAccountId: (val: string | null) => void;
};

export default function BankAccountSection(props: Props) {
  const { companyBankAccountId, setCompanyBankAccountId } = props;
  const { i18n } = useAppContext();

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[5]} />
        {i18n.getTranslation('invoice.steps.bank_account')}
      </WizardHeader>

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('invoice.wizard.bank_account_heading')}
      </Heading>
      <InputContainer>
        <BankAccountsDropdown
          id="invoice.bank_account"
          onChange={(val) => setCompanyBankAccountId(val[0]?.id || '')}
          selectedValue={companyBankAccountId || ''}
          showDefault={false}
          error={!companyBankAccountId}
        />
      </InputContainer>
    </WizardSection>
  );
}
