import moment, { Moment } from 'moment';
import React from 'react';

import {
    AssignInvoicesToOutgoingBankingTransactionRequestType,
    OutgoingBankingTransactionRequestPreviewInsightsType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { METHODS, sendRequest } from '../../../../utils/request';
import AssignInsightsPreview from './assign-insights-preview';
import AssignToTransactionSection, {
    OutgoingTransactionType_
} from './assign-to-transaction-section';
import css from './css/assign-to-transaction-dialog.module.scss';
import InvalidInsights from './invalid-insights';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;

  // All
  type?: outgoingBankingTransactionType;

  // List page
  selectedRows?: InvoiceRowType[];
  refresh?: () => void;

  // Detail page
  invoice?: InvoiceType;
  onComplete?: () => void;
};

type State = {
  insights: OutgoingBankingTransactionRequestPreviewInsightsType | null;
  inValidInvoices: number;
  transactionType: OutgoingTransactionType_;
  collectionDate: Moment;
  overrideCollectionDate: boolean;
};

const AssignToTransactionDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, invoice, type, validationRef, onComplete, refresh } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { invoiceStore } = useStore();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = React.useReducer(stateReducer, {
    insights: null,
    inValidInvoices: 0,
    transactionType: 'existing',
    collectionDate: moment(MIN_DATE),
    overrideCollectionDate: false
  });

  let invoiceIds: string[] = [];

  if (selectedRows) {
    invoiceIds = selectedRows.map((r) => {
      return r.__entity.id;
    });
  } else if (invoice) {
    invoiceIds = [invoice.id];
  }

  const requestDataBody: AssignInvoicesToOutgoingBankingTransactionRequestType = {
    addToNewOutgoingBankingTransactions: state.transactionType === 'new',
    overriddenCollectionDate:
      state.collectionDate && state.overrideCollectionDate ? state.collectionDate.toISOString() : null,
    invoiceIds: invoiceIds,
    allApplicable: !selectedRows && !invoice
  };

  if (type) {
    requestDataBody.outgoingBankingTransactionType = type;
  }

  const getPreviewInsights = async () => {
    return (
      await sendRequest<OutgoingBankingTransactionRequestPreviewInsightsType>({
        request: {
          method: METHODS.POST,
          endpoint: '/bill/OutgoingBankingTransactions/preview',
          data: requestDataBody
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  const isListpageRegular = !!selectedRows && selectedRows.length > 1;
  const isListpageAll = !invoice && !selectedRows;

  React.useEffect(() => {
    getPreviewInsights()
      .then((data) => {
        let inValidInvoices = 0;

        if (isListpageRegular) {
          // If not multi the button will be disabled
          inValidInvoices = Math.abs(
            requestDataBody.invoiceIds.length - (data.requests.numberOfInvoices + data.refunds.numberOfInvoices)
          );
        }

        setState({ insights: data, inValidInvoices: inValidInvoices });
      })
      .catch((e) => {
        notify.error({
          content: i18n.getTranslation('actions.invoice.get_preview_insights_fail'),
          error: e
        });
      });
  }, []);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await invoiceStore.assignToOutgoingBankingTransaction(requestDataBody, invoice?.id, !selectedRows);
        if (refresh) refresh();
        if (onComplete) onComplete();

        notify.success({
          content: i18n.getTranslation(`actions.invoice.add_transaction_success${isListpageRegular ? '_multi' : ''}`)
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`actions.invoice.add_transaction_fail${isListpageRegular ? '_multi' : ''}`),
          error: e
        });
      }
    }
  }));

  const setTransactionType = React.useCallback(
    (newType: string) => {
      setState({
        transactionType: newType as OutgoingTransactionType_,
        collectionDate: moment(MIN_DATE),
        overrideCollectionDate: false
      });
    },
    [setState]
  );

  const setCollectionDate = React.useCallback((newDate: Moment) => {
    setState({ collectionDate: newDate });
  }, []);

  const setOverrideCollectionDate = React.useCallback((val: boolean) => {
    setState({ overrideCollectionDate: val });
  }, []);

  const validate = () => {
    if (validationRef && validationRef.current) {
      validationRef.current.setIsError(
        (isListpageRegular && state.inValidInvoices === requestDataBody.invoiceIds.length) ||
          (!!state.insights &&
            type === outgoingBankingTransactionType.paymentrequests &&
            state.insights.requests.totalAmount === 0) ||
          (!!state.insights &&
            type === outgoingBankingTransactionType.paymentrefunds &&
            state.insights.refunds.totalAmount === 0)
      );
      if (state.overrideCollectionDate) {
        validationRef.current.setIsError(isMinDate(state.collectionDate));
      }
    }
  };

  React.useEffect(() => {
    validate();
  }, [requestDataBody]);

  if (!state.insights) return null;

  const noneValid =
    (isListpageRegular && state.inValidInvoices === requestDataBody.invoiceIds.length) ||
    (type === outgoingBankingTransactionType.paymentrequests && state.insights.requests.totalAmount === 0) ||
    (type === outgoingBankingTransactionType.paymentrefunds && state.insights.refunds.totalAmount === 0);
  const isMulti = isListpageAll || isListpageRegular;

  return (
    <div className={css['assign-all-dialog']}>
      {!noneValid && (
        <AssignToTransactionSection
          selectedType={state.transactionType}
          collectionDate={state.collectionDate}
          overrideCollectionDate={state.overrideCollectionDate}
          setOverrideCollectionDate={setOverrideCollectionDate}
          setSelectedType={setTransactionType}
          setCollectionDate={setCollectionDate}
        />
      )}

      {isMulti && <AssignInsightsPreview requestDataBody={requestDataBody} insights={state.insights} />}

      <InvalidInsights
        noneValid={noneValid}
        inValidInvoices={state.inValidInvoices}
        requestDataBody={requestDataBody}
        insights={state.insights}
      />
    </div>
  );
});

export default AssignToTransactionDialog;
