import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from '../../../../hooks/useStore';
import EmailCard from './email/email-card';
import PdfCard from './pdf/pdf-card';
import css from './scenario.module.scss';
import SendingDetailsCard from './sending-options/sending-details-card';

const Scenario = () => {
  const { communicationStore } = useStore();
  const { selectedScenario } = communicationStore.templatesStore;

  return (
    <>
      <SendingDetailsCard />
      <div className={css['bottom-cards-container']}>
        {selectedScenario?.pdfTemplate && <PdfCard />}
        {selectedScenario?.emailTemplate && <EmailCard />}
      </div>
    </>
  );
};

export default observer(Scenario);
