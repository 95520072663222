import { observer } from 'mobx-react';
import React from 'react';

import { paymentMethod } from '@zf/api-types/enums';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';
import { isValidIBAN } from '@zf/utils/src/iban';

import PaymentTermsDropdown, {
  PaymentTermDropdownProps
} from '../../../../components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';
import IBANInput from '../../../../components/input/IBANInput';
import { InputFieldProps } from '../../../../components/input/InputField';
import Select from '../../../../components/input/Select';
import { useStore } from '../../../../hooks/useStore';
import css from '../customer-wizard.module.scss';
import { STEP_NAMES } from '../CustomerWizard';

export type BankingDetailsBaseType = {
  usedForPaymentMethod: paymentMethod | null;
  iban: string;
  paymentTermsId: string | null;
};

type Props<T> = {
  values: T;
  showPaymentTerms?: boolean;
  customerName: { name: string };
  setBankingDetail: (
    value: BankingDetailsBaseType[keyof BankingDetailsBaseType],
    key: keyof BankingDetailsBaseType
  ) => void;
  handleFocus?: (step: string) => () => void;
};

const WizardIBANInputField = WizardInputWrapper<InputFieldProps>(IBANInput);
const PaymentTermsDropdownField = WizardInputWrapper<PaymentTermDropdownProps>(PaymentTermsDropdown);

const CustomerBankingDetails = <T extends BankingDetailsBaseType>(props: Props<T>) => {
  const { values, showPaymentTerms = true, setBankingDetail, handleFocus, customerName } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation } = applicationStore;

  const focusHandle = () => {
    if (handleFocus) handleFocus(STEP_NAMES[1]);
  };

  return (
    <>
      <Heading headingType="h3" style="bold">
        {getTranslation('customer.banking_details_wizard', customerName)}
      </Heading>
      <div className={css['type-input-wrapper']}>
        <Select
          id="payment-method"
          type="small"
          onChange={(val) => setBankingDetail(val[0], 'usedForPaymentMethod')}
          selectedValues={[values.usedForPaymentMethod || paymentMethod.sct]}
          values={getEnum<paymentMethod>('paymentMethod').map((pm) => {
            return {
              icon: pm.value,
              value: pm.value,
              text: getEnumTranslation('paymentMethod', pm.value)
            };
          })}
        />
        <WizardIBANInputField
          id="iban"
          onChange={(newIban) => setBankingDetail(newIban, 'iban')}
          value={values.iban}
          placeholder={
            values.usedForPaymentMethod === paymentMethod.sdd
              ? getTranslation('customer.iban')
              : getTranslation('customer.iban_sct')
          }
          error={values.usedForPaymentMethod === paymentMethod.sdd ? !isValidIBAN(values.iban) : undefined}
          className={css['input']}
          onFocus={focusHandle}
        />
      </div>
      {showPaymentTerms && (
        <>
          <Heading headingType="h3" style="bold">
            {getTranslation('customer.payment_term_heading', customerName)}
          </Heading>
          <InputContainer>
            <PaymentTermsDropdownField
              id="payment-terms-dropdown"
              onChange={(val) => setBankingDetail(val[0]?.id || '', 'paymentTermsId')}
              selectedValue={values.paymentTermsId || ''}
            />
          </InputContainer>
        </>
      )}
    </>
  );
};

export default observer(CustomerBankingDetails);
