import classNames from 'classnames';
import Interweave from 'interweave';
import React from 'react';

import CoachMark from '../CoachMark/CoachMark';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './paragraph.module.scss';

export type ParagraphProps = {
  id?: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  deleted?: boolean;
  bold?: boolean;
  tabIndex?: number;
  color?: string;
  info?: string;
  textAlign?: 'left' | 'center' | 'right';
  dataTestId?:string
};

export default function Paragraph(props: ParagraphProps) {
  const { className, id, color, children, textAlign, info, bold = false, deleted = false, tabIndex = -1, dataTestId } = props;
  const { style = {} } = props;

  if (color) {
    style.color = color;
  }

  if (textAlign) {
    style.textAlign = textAlign;
  }

  const paragraph = (
    <p
      id={id}
      className={classNames(css['paragraph'], className, {
        [css['deleted']]: deleted,
        [css['text-bold']]: bold
      })}
      style={style}
      tabIndex={tabIndex}
      data-testid={dataTestId}
    >
      {children || typeof children === 'number' ? children : '-'}
    </p>
  );

  return info ? (
    <div className={css['info-wrapper']}>
      {paragraph}
      <TooltipTrigger
        tooltip={
          <Paragraph>
            <Interweave content={info} />
          </Paragraph>
        }
      >
        <CoachMark usePopup={false} size="small" info />
      </TooltipTrigger>
    </div>
  ) : (
    paragraph
  );
}
