import React, { useState } from 'react';

import { productItemPeriodicityType } from '@zf/api-types/enums';
import {
  FixedAmountPeriodicityParametersType,
  FixedDurationPeriodicityParametersType,
  ProductBillingItemType,
  ProductItemPeriodicityParametersType
} from '@zf/api-types/product';

import { useAppContext } from '../../../../../app-context';
import FloatInput from '../../../../../components/input/FloatInput';
import SimpleDropdown from '../../../../../components/Lang/SimpleDropdown';
import { getTimeUoms } from '../../../../../utils/enums';

type Props = {
  productBillingItem: ProductBillingItemType;
  setPeriodicityParameter: (idToUpdate: string, val: Partial<FixedDurationPeriodicityParametersType>) => void;
};

export default function PeriodicityParameters(props: Props) {
  const { productBillingItem, setPeriodicityParameter } = props;

  const { i18n, enumReducer } = useAppContext();

  const [timeUoms] = useState(getTimeUoms(enumReducer.getEnum));

  const renderType = (parameters: ProductItemPeriodicityParametersType) => {
    return (
      <SimpleDropdown
        id={`productItemPeriodicityType-${productBillingItem.billingItemId}`}
        onChange={(val) => setPeriodicityParameter(productBillingItem.billingItemId, { type: val[0] })}
        values={enumReducer.getEnum<productItemPeriodicityType>('productItemPeriodicityType')}
        selectedValues={[parameters.type]}
        placeholder={i18n.getTranslation('billing_items.periodicity')}
        darkMode
      />
    );
  };

  switch (productBillingItem.periodicityParameters.type) {
    case productItemPeriodicityType.fixedamount: {
      const parameters = productBillingItem.periodicityParameters as FixedAmountPeriodicityParametersType;
      return (
        <>
          {renderType(productBillingItem.periodicityParameters)}
          <FloatInput
            id={`amount-${productBillingItem.billingItemId}`}
            onChange={(val) => setPeriodicityParameter(productBillingItem.billingItemId, { amount: val })}
            value={parameters.amount}
            placeholder={i18n.getTranslation('general.quantity')}
            darkMode
          />
        </>
      );
    }

    case productItemPeriodicityType.fixedduration: {
      const parameters = productBillingItem.periodicityParameters as FixedDurationPeriodicityParametersType;
      return (
        <>
          {renderType(productBillingItem.periodicityParameters)}
          <FloatInput
            id={`amount-${productBillingItem.billingItemId}`}
            onChange={(val) => setPeriodicityParameter(productBillingItem.billingItemId, { amount: val })}
            value={parameters.amount}
            placeholder={i18n.getTranslation('general.quantity')}
            darkMode
          />
          <SimpleDropdown
            id={`frequency-${productBillingItem.billingItemId}`}
            onChange={(val) => setPeriodicityParameter(productBillingItem.billingItemId, { frequency: val[0] })}
            values={timeUoms}
            selectedValues={[parameters.frequency]}
            error={!parameters.frequency}
            placeholder={i18n.getTranslation('general.frequency')}
            darkMode
          />
        </>
      );
    }

    case productItemPeriodicityType.onmovein:
    case productItemPeriodicityType.onmoveout:
    case productItemPeriodicityType.regular: {
      return renderType(productBillingItem.periodicityParameters);
    }
  }
}
