import React from 'react';

import { ExportJobRowType, ExportJobType, GenerateExportJobType } from '@zf/api-types/export-job';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { generateExportJob } from './export-job';

type Props = {
  onComplete?: (updatedRows: ExportJobType[]) => void;
  selectedRows?: Array<ExportJobRowType>;
  exportJobToHandle?: GenerateExportJobType;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, exportJobToHandle, refreshCounts } = props;
  const { i18n, tenantReducer } = useAppContext();

  let exportJobsToHandle: Array<GenerateExportJobType> = [];

  if (selectedRows) {
    exportJobsToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (exportJobToHandle) {
    exportJobsToHandle = [exportJobToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newExportJobs = await Promise.all(
          exportJobsToHandle.map((importJob) => generateExportJob(importJob, tenantReducer, i18n.lang))
        );

        if (onComplete) onComplete(newExportJobs);
        if (refreshCounts) refreshCounts();

        notify.success({
          content: i18n.getTranslation('actions.export_jobs.generate_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.export_jobs.generate_failed'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation('actions.export_jobs.generate')}</Paragraph>;
});
