import moment from 'moment';
import React from 'react';

import { invoiceStatus, sentStatus } from '@zf/api-types/enums';
import { InvoiceRowType } from '@zf/api-types/invoice';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useDownload from '../../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../../design-system/ComponentSets';

export default function MergedPDFInvoiceListPageButton() {
  const { myCurrent } = useCurrent('invoice');
  const { tenantReducer } = useAppContext();
  const { downloadFiles, downloadSingleFile } = useDownload();
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;

  const { i18n } = useAppContext();

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.find(
      (invoice) => invoice.__entity.status !== invoiceStatus.generated && invoice.__entity.sent !== sentStatus.sent
    ) !== undefined;

  return (
    <DropdownAction
      id="invoice.downloadMergedPDF"
      icon="pdf"
      actionType="direct"
      onClick={() => {
        const selectedIds = selectedRows.map((row) => {
          return row.__entity.attachmentId;
        });

        if (selectedIds.length === 1) {
          downloadSingleFile(`/att/attachments/${selectedIds[0]}`, tenantReducer);
        } else {
          downloadFiles(
            selectedIds,
            '/att/attachments/downloadmergedpdf',
            '/att/attachments/',
            'attachmentIds',
            `invoices${moment().toISOString()}.pdf`
          );
        }
      }}
      disabled={disabled}
    >
      {i18n.getTranslation('actions.mergedPDF')}
    </DropdownAction>
  );
}
