import classNames from 'classnames';
import React from 'react';

import { InternalColumnType, RowProps, RowTypeBase } from '@zf/stella-react/src/atoms/Table';

import css from './fixed-virtual-table.module.scss';
import TableCell from './TableCell';

type Props<T> = RowProps & {
  id: string;
  index: number;
  rowData: T;
  isSelected: boolean;
  isActivated: boolean;
  isMatched: boolean;
  style: React.CSSProperties;
  renderedColumns: InternalColumnType[];
  invert: boolean;
  cursorPointer: boolean;
  scaleTranslator: number;
  dynamicRowHeight: boolean;
  leftIndent: number | undefined;
  hideHeader: boolean;
  tooltipId?: string;
  handleSelectRow: (rowIndex: number, event?: React.MouseEvent<HTMLDivElement, MouseEvent>, dataKey?: string) => void;
  handleActivateRow: (rowIndex: number, event?: React.MouseEvent<HTMLDivElement, MouseEvent>, dataKey?: string) => void;
};

export default function TableRow<T extends RowTypeBase>(props: Props<T>) {
  const {
    id,
    index,
    rowData,
    isSelected,
    isActivated,
    isMatched,
    calcMode,
    renderedColumns,
    scaleTranslator,
    dynamicRowHeight,
    style,
    leftIndent,
    hideHeader,
    invert = false,
    cursorPointer = false,
    tooltipId,
    handleSelectRow,
    handleActivateRow
  } = props;

  let indent = 0;

  return (
    <div
      className={classNames(css['virtual-table-row'], {
        [css['selected']]: isSelected,
        [css['activated']]: isActivated,
        [css['invert']]: invert,
        [css['action-performed']]: rowData.actionPerformed,
        [css['row-pointer']]: cursorPointer,
        [css['matched']]: isMatched
      })}
      role="row"
      style={style}
    >
      {renderedColumns.map((column, colIndex) => {
        const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
          handleSelectRow(index, e, column.dataKey);
          handleActivateRow(index, e, column.dataKey);
        };

        const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            handleSelectRow(index);
          }
        };

        // If text is right aligned, do this for header as well
        if (column.contentAlignRight) {
          column.headerAlignRight = true;
        }

        const styleObject: React.CSSProperties = {
          height: calcMode ? 'auto' : '100%',
          width: column.width * scaleTranslator,
          position: 'absolute',
          left: indent,
          textAlign: column.headerAlignRight ? 'right' : 'unset',
          paddingRight: column.headerAlignRight ? '1.5rem' : '0',
          paddingLeft: `${leftIndent}rem`
        };

        indent += column.width * scaleTranslator;

        return (
          <TableCell
            id={`${id}-${index}-${colIndex}`}
            tooltipId={tooltipId}
            key={`${id}-${index}-${colIndex}`}
            index={index}
            style={styleObject}
            hideHeader={hideHeader}
            dynamicRowHeight={dynamicRowHeight}
            column={column}
            rowData={rowData}
            isLoading={rowData.__id === 'LOADING '}
            isSelected={isSelected}
            onClick={onClick}
            onKeyDown={onKeyDown}
          />
        );
      })}
    </div>
  );
}
