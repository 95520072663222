import { BalanceQueryParams } from 'features/devices/prepayment-device/stores/BalanceStore';
import { METHODS } from 'utils/request';

import { PagedResponseType } from '@zf/api-types/api';
import { TopUpType } from '@zf/api-types/metering/top-up';

import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';

export default class TopUpService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/me/TopUps', applicationStore);
  }

  getTopUps = async (deviceId: string, query?: BalanceQueryParams) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<TopUpType>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint,
          query: {
            meterId: deviceId,
            ...query
          }
        }
      })
    ).data.results;
  };
}
