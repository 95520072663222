import { removeLocationsWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-out/RemoveLocationsWizardStore';
import { WizardStep } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

const RemoveLocationsWizardNavigation = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { removeLocationsWizardNavigator, showBillingSection } =
    contractStore.moveOutWizardBaseStore.removeLocationsWizardStore;
  const { activeStep, stepValidations, setActiveStep } = removeLocationsWizardNavigator;

  return (
    <>
      <WizardStep
        headerId={removeLocationsWizardSteps.moveout}
        text={getTranslation('contracts.wizard.terminate.steps.move_out')}
        isActive={activeStep === removeLocationsWizardSteps.moveout}
        stepValidation={stepValidations[removeLocationsWizardSteps.moveout]}
        setIsActive={() => setActiveStep(removeLocationsWizardSteps.moveout)}
      />
      <WizardStep
        headerId={removeLocationsWizardSteps.locations}
        text={getTranslation('contracts.steps.location')}
        isActive={activeStep === removeLocationsWizardSteps.locations}
        stepValidation={stepValidations[removeLocationsWizardSteps.locations]}
        setIsActive={() => setActiveStep(removeLocationsWizardSteps.locations)}
      />

      {showBillingSection() && (
        <WizardStep
          headerId={removeLocationsWizardSteps.billing}
          text={getTranslation('contracts.steps.billing', { stepNum: 3 })}
          isActive={activeStep === removeLocationsWizardSteps.billing}
          stepValidation={stepValidations[removeLocationsWizardSteps.billing]}
          setIsActive={() => setActiveStep(removeLocationsWizardSteps.billing)}
        />
      )}
    </>
  );
};

export default observer(RemoveLocationsWizardNavigation);
