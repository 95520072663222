import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { CardWidthType } from '@zf/stella-react/src/atoms/Card/Card';

import { useAppContext } from '../../app-context';
import { SuspenseCardBody } from '../../components/suspense';
import { queryParam } from '../../hooks/useCreateRequest';
import InvoiceCardTable from './InvoiceCardTable';

type Props = {
  endpoint: string;
  queryParams: queryParam;
  width?: CardWidthType;
};

export default function InvoiceCard(props: Props) {
  const { width = '3' } = props;
  const { i18n } = useAppContext();

  return (
    <Card id="invoice-card" width={width} className="card">
      <CardHeader>{i18n.getTranslation('invoice.invoices')}</CardHeader>
      <SuspenseCardBody type="table">{<InvoiceCardTable {...props} />}</SuspenseCardBody>
    </Card>
  );
}
