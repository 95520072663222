import React from 'react';

import { CollectionCaseType } from '@zf/api-types/collection-case';
import { entitySubjectType } from '@zf/api-types/enums';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { CommentCard, FeedCard } from '../../../../cards';
import CustomEntityPropertiesCard from '../../../../cards/CustomEntityProperties/CustomEntityPropertiesCard';
import IntegrationLoggingCard from '../../../../cards/Integration/IntegrationLoggingCard';
import InvoiceCard from '../../../../cards/InvoiceCard/InvoiceCard';
import css from './collection-case-detail.module.scss';
import CollectionCaseDetailsCard from './CollectionCaseDetailsCard';
import CollectionCaseTimeline from './timeline/CollectionCaseTimeline';

type Props = {
  collCase: CollectionCaseType;
  timeStamp: string;
  refresh: () => void;
};

export default function CollectionCaseDetail(props: Props) {
  const { collCase, timeStamp, refresh } = props;

  return (
    <CardsContainer className={css['collection-case-detail-card-container']}>
      <CollectionCaseDetailsCard collCase={collCase} />
      <CollectionCaseTimeline collCase={collCase} refresh={refresh} />
      <InvoiceCard
        endpoint="/bill/Invoices"
        queryParams={{
          collectionCaseId: collCase.id,
          refreshTrigger: timeStamp
        }}
      />
      <CommentCard subjectType={entitySubjectType.collectioncase} subjectId={collCase.id} />
      <FeedCard subjectType={entitySubjectType.collectioncase} timeStamp={timeStamp} subjectId={collCase.id} />
      <CustomEntityPropertiesCard
        entityType="CollectionCases"
        entity={collCase}
        entitySubjectType={entitySubjectType.collectioncase}
        microservice="bill"
      />
      <IntegrationLoggingCard subjectId={collCase.id} subjectType={'CollectionCase'} />
    </CardsContainer>
  );
}
