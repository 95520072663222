import React from 'react';

import { paymentType } from '@zf/api-types/enums';
import { PaymentsOverviewCountType } from '@zf/api-types/payments';

import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { StringParam } from 'serialize-query-params';

const initialPaymentOverviewCount: PaymentsOverviewCountType = {
  all: 0,
  reversed: 0
};

type QueryParam = {
  paymentType?: paymentType[];
  customerId: string;
  customerName: string;
  startDateTime?: string;
  endDateTime?: string;
};

const initialQueryParams = {
  startDateTime: undefined,
  endDateTime: undefined,
  customerId: '',
  customerName: ''
};

const paramTypes = {
  customerId: StringParam,
  customerName: StringParam,
  startDateTime: StringParam,
  endDateTime: StringParam
};

const usePaymentFilter = (search?: string) => {
  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams
  } = useFilter<QueryParam>({
    endpoint: '/bill/payments/overviewcount',
    initialCount: initialPaymentOverviewCount,
    domain: 'payment',
    search,
    initialQueryParams,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.customerId) {
    tags.push(
      <FilterTag
        value={queryParams.customerName}
        removeFilter={() => setQueryParams({ customerId: '', customerName: '' })}
        key="customer-id"
      />
    );
  }

  if (queryParams.paymentType && queryParams.paymentType.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="paymentType"
        value={queryParams.paymentType[0]}
        removeFilter={() => setQueryParams({ paymentType: [] as paymentType[] })}
        key="payment-type"
      />
    );
  }

  if (queryParams.startDateTime !== undefined || queryParams.endDateTime !== undefined) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.startDateTime, queryParams.endDateTime]}
        removeFilter={() => setQueryParams({ startDateTime: undefined, endDateTime: undefined })}
        key="invoice-date"
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    filterTags,
    quickFilter,
    overviewCount,
    searchValue,
    queryParams,
    refreshCounts: refresh,
    onFilter,
    onSearch,
    setQueryParams,
    clearQueryParams
  };
};

export default usePaymentFilter;
