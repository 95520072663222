import React, { ReactNode } from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import css from './wizard-nav.module.scss';

type Props = {
  children: ReactNode;
};

export default function WizardNav(props: Props) {
  const { children } = props;

  return (
    <FlexElements className={css['wizard-nav']} flexDirection="column" gap="24">
      {children}
    </FlexElements>
  );
}
