import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import UnlinkDialog from '../../../actions/meters/link-meter/unlink-dialog';
import { useAppContext } from '../../../app-context';
import ConfirmationActionButton from '../ConfirmationActionButton';

type Props = {
  toUnlink: MeterType[];
  refresh: () => void;
};

export default function DeleteSubmeterCardButton(props: Props) {
  const { toUnlink } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  return (
    <ConfirmationActionButton
      id="meter.remove_submeter"
      size="small"
      type="text"
      dialogTitle={i18n.getTranslation('actions.meter.unlink_title')}
      dialogContent={<UnlinkDialog {...props} ref={clickRef} />}
      onSubmit={onSubmit}
      disabled={toUnlink.length === 0}
      buttonPositive={i18n.getTranslation('actions.meter.unlink')}
      dialogType="danger"
    />
  );
}
