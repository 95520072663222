import { SettingDescription } from 'design-system/ComponentSets';
import { CheckBox } from 'design-system/Inputs';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { BaseMeteringValidationRule, MeteringValidationRuleType } from '@zf/api-types/meter-config';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';

import css from './validation-rules-card.module.scss';

type Props = {
  validationRules: BaseMeteringValidationRule[];
  setValidationRule: (type: MeteringValidationRuleType, val: Partial<BaseMeteringValidationRule>) => void;
};

const ValidationRulesCard = (props: Props) => {
  const { validationRules, setValidationRule } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const consumptionNegativeEnabled = validationRules.find(
    (r) => r.type === 'BaseMeteringValidationRuleDTO:ConsumptionNegative'
  )?.enabled;

  if (typeof consumptionNegativeEnabled === 'undefined') return null;

  return (
    <Card width="2" id="validation-rules-card">
      <CardHeader>{getTranslation('meter_config.validate_on')}</CardHeader>
      <CardBody className={css['validation-rule-card-body']}>
        <CardItem width="12" className={css['validation-rule-card-item']}>
          <SettingDescription
            className={css['validation-rule-setting-desc']}
            title={getTranslation('meter_config.validation_consumptionnegative')}
            description={getTranslation('meter_config.validation_consumptionnegative_desc')}
            setting={
              <CheckBox
                id="meter_config.consumptionnegative"
                onChange={(enabled) =>
                  setValidationRule('BaseMeteringValidationRuleDTO:ConsumptionNegative', { enabled })
                }
                checked={consumptionNegativeEnabled}
              />
            }
          />
        </CardItem>
      </CardBody>
    </Card>
  );
};

export default observer(ValidationRulesCard);
