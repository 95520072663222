import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Area } from 'recharts';
import { parseGraphTime } from 'utils/graph';

import { meteringType } from '@zf/api-types/enums';
import { MeterType } from '@zf/api-types/master-data/meter';
import ZFAreaChart from '@zf/stella-react/src/atoms/Chart/ZFAreaChart';
import { colors } from '@zf/utils/src/color';
import { DISPLAY_DATE_FORMAT } from '@zf/utils/src/date';

import { useStore } from '../../../../../hooks/useStore';
import NoData from './NoData';

type Props = {
  prepaymentDevice: MeterType;
};

const BalanceGraph = (props: Props) => {
  const { prepaymentDevice } = props;

  const { applicationStore, prepaymentDeviceStore } = useStore();
  const { culture } = applicationStore;
  const { dataType, chartData, filterStore, loadBalanceGraph } = prepaymentDeviceStore.balanceStore;

  useEffect(() => {
    const channelId = prepaymentDevice.channels.find(
      (chann) => chann.meteringType === meteringType.balance
    )?.externalIdentifier;

    if (channelId) {
      loadBalanceGraph(channelId);
    }
  }, [dataType, prepaymentDevice.id, filterStore.queryParams]);

  if (chartData?.length === 0) return <NoData />;

  return (
    <ZFAreaChart
      subject="balance"
      chartData={chartData || []}
      XdataKey="time"
      xAxisTickFormatter={(val) => parseGraphTime(val, DISPLAY_DATE_FORMAT)}
      lines={[
        <Area
          name="balance"
          key="balance"
          type="linear"
          dataKey="value"
          stroke={colors['blue-600']}
          strokeWidth={2}
          fill={colors['blue-400']}
          unit="£"
        />
      ]}
      locale={culture}
      showLegend={false}
    />
  );
};

export default observer(BalanceGraph);
