import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardBody } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../../hooks/useStore';
import PdfCardBody from './pdf-card-body';
import css from './pdf-card-body.module.scss';

import CardHeaderUseCase from '../components/CardHeaderUseCase';
import { templateUsecase } from '@zf/api-types/enums';

const PdfCard = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="pdf-card" className={css['pdf-card']}>
      <CardHeaderUseCase useCase={templateUsecase.pdf}>{getTranslation('communication.pdf')}</CardHeaderUseCase>
      <CardBody type="grid" noMaxHeight>
        <PdfCardBody />
      </CardBody>
    </Card>
  );
};

export default observer(PdfCard);
