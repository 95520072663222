import { observer } from 'mobx-react';
import React from 'react';

import { DebtorType } from '@zf/api-types/billing/debtor';
import { customerType } from '@zf/api-types/enums';

import { useStore } from '../../hooks/useStore';
import EmptyLink from '../Link/EmptyLink';

type Props = {
  debtor: DebtorType;
};

const Debtor = observer((props: Props) => {
  const { customerId, customerAccountNumber, customerType: customerType_, displayName } = props.debtor;
  const { applicationStore } = useStore();
  const { rootUrl } = applicationStore;

  return (
    <EmptyLink
      id={`customer-${customerId}`}
      url={`${rootUrl}/customers/${customerId}`}
      icon={customerType_ === customerType.person ? 'customer' : 'organisation'}
    >
      {customerAccountNumber && displayName && `${customerAccountNumber} - ${displayName}`}
    </EmptyLink>
  );
});

export default Debtor;
