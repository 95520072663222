import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { entitySubjectType } from '@zf/api-types/enums';
import { DropdownProps, DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useStore } from '../../../hooks/useStore';
import Dropdown from '../../Lang/Dropdown';

export type EntityPropertiesDropdownProps = {
  id: string;
  resetValue?: string;
  isInline?: boolean;
  selectedValue?: string;
  error?: boolean;
  renderTopLvl?: boolean;
  hideCollectionCaseProps?: boolean;
  className?: string;

  onFocus?: (step: string) => void;
  reset?: () => void;
  onChange: (values: CustomEntityPropertyType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<CustomEntityPropertyType>>(Dropdown);

const EntityPropertiesDropdown = (props: EntityPropertiesDropdownProps) => {
  const {
    id,
    resetValue,
    className,
    isInline = false,
    error,
    renderTopLvl = true,
    hideCollectionCaseProps = false,
    reset,
    onFocus,
    onChange
  } = props;
  const { selectedValue = '' } = props;

  const { applicationStore, configStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const [customEntityPropertyTypes, setCustomEntityPropertyTypes] = useState<CustomEntityPropertyType[]>();

  useEffect(() => {
    configStore.configService.getCustomEntityPropertyTypes().then((res) => setCustomEntityPropertyTypes(res));
  }, []);

  if (!customEntityPropertyTypes) {
    return isInline ? (
      <InlineDropdown
        id={id}
        className={className}
        onChange={onChange}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    ) : (
      <Dropdown
        id={id}
        className={className}
        onChange={onChange}
        placeholder={getTranslation('settings.custom_entity_property')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    );
  }

  const dropdownValues = customEntityPropertyTypes.reduce(
    (acc: DropdownValuesType<CustomEntityPropertyType>[], cep) => {
      const text = `${cep.name} (${getEnumTranslation('entitySubjectType', cep.entitySubjectType)})`;

      if (!hideCollectionCaseProps) {
        acc.push({
          value: cep,
          text
        });
      } else if (hideCollectionCaseProps && cep.entitySubjectType !== entitySubjectType.collectioncase) {
        acc.push({
          value: cep,
          text
        });
      }

      return acc;
    },
    []
  );

  return isInline ? (
    <InlineDropdown
      id={id}
      className={className}
      onChange={onChange}
      values={dropdownValues}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      resetValue={resetValue}
      reset={reset}
      error={error}
      renderTopLvl={renderTopLvl}
      clear
    />
  ) : (
    <Dropdown
      id={id}
      className={className}
      onChange={onChange}
      placeholder={getTranslation('settings.custom_entity_property')}
      values={dropdownValues}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      resetValue={resetValue}
      reset={reset}
      error={error}
      renderTopLvl={renderTopLvl}
      clear
    />
  );
};

export default observer(EntityPropertiesDropdown);
