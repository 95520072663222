import React from 'react';
import { Tile } from 'design-system/Components/Tile';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import Interweave from 'interweave';
import css from './settings.module.scss';
import { SettingDescription } from '../../../../../design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { useForm } from 'react-hook-form';
import { Toggle } from './Toggle';
import { CustomerGroupSettings } from '@zf/api-types/master-data/customer';
import AddSettingDialog from '../../dialogs/AddSettingDialog';
import RemoveSettingDialog from '../../dialogs/RemoveSettingDialog';
import { updateAutomaticSettlement } from '../Update.API';
import { useAppContext } from 'app-context';
import { notify } from 'events/notification-events';

type Props = {
  customerGroupId: string;
  hoverable?: boolean;
  settings: CustomerGroupSettings;
  hasCustomers: boolean;
};

export const Settings: React.FC<Props> = (props) => {
  const { settings, customerGroupId, hasCustomers } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { i18n, tenantReducer } = useAppContext();

  const { control, setValue, watch } = useForm<CustomerGroupSettings>({
    values: settings,
    defaultValues: settings
  });

  const submitFunction = async (flag: any) => {
    if (hasCustomers) {
      await updateAutomaticSettlement(flag, customerGroupId, tenantReducer, i18n.lang);
    } else {
      const response = await updateAutomaticSettlement(flag, customerGroupId, tenantReducer, i18n.lang);
      if (response) {
        notify.success({
          content: i18n.getTranslation('customer_groups.settings.removed_success')
        });
      } else {
        notify.success({
          content: i18n.getTranslation('customer_groups.settings.removed_fail')
        });
      }
    }

    notify.success({
      content: getTranslation('customer.automatic_settlement_on_off', {
        setting: flag ? getTranslation('general.on') : getTranslation('general.off')
      })
    });

    setValue('automaticSettlement', flag);
  };

  return (
    <Tile>
      <div className={css['header']}>
        <Heading headingType="h3" className={css['title']}>
          <Interweave content={getTranslation('customer_groups.settings.title')} />
        </Heading>
        <Paragraph textAlign="left" className={css['desc']}>
          {getTranslation('customer_groups.settings.title.desc')}
        </Paragraph>
      </div>
      <div className={css['line']}></div>
      <SettingDescription
        title={getTranslation('customer_groups.settings.auto_settlement')}
        description={getTranslation('customer_groups.settings.auto_settlement.desc')}
        setting={
          <>
            {watch('automaticSettlement') !== null && (
              <Toggle
                control={control}
                name="automaticSettlement"
                submitFunction={submitFunction}
                defaultValue={false}
              />
            )}

            {watch('automaticSettlement') === null && (
              <AddSettingDialog hasCustomers={hasCustomers} submitFunction={submitFunction} />
            )}

            {watch('automaticSettlement') !== null && (
              <RemoveSettingDialog
                customerGroupId={customerGroupId}
                hasCustomers={hasCustomers}
                submitFunction={submitFunction}
              />
            )}
          </>
        }
        indentLvl={1}
        className={css['settings']}
      />
    </Tile>
  );
};
