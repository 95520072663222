import useUser from 'hooks/useUser';
import Interweave from 'interweave';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { advanceAmountChangedBy } from '@zf/api-types/enums';
import { FeedType } from '@zf/api-types/feed';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import OverflowParagraph from '@zf/stella-react/src/atoms/Paragraph/OverflowParagraph';

import { Paragraph } from '../../design-system/Foundation';
import { useStore } from '../../hooks/useStore';
import { Icon } from '../Icon';
import css from './feed-item.module.scss';
import { formatDate } from '@zf/utils/src/date';

type Props = {
  feedItem: FeedType;
};

const FeedItem = (props: Props) => {
  const { feedItem } = props;

  const { applicationStore } = useStore();
  const { culture, userStore, getTranslation, getTranslationInAnyVersion, getEnumTranslation } = applicationStore;

  const secondaryUser = useUser(feedItem.userId, userStore);
  const castedChangedBy = feedItem.payLoad.changedBy as unknown as advanceAmountChangedBy | undefined;

  // getEnumTranslation can't handle undefined symbols
  const initialPrimaryUser = castedChangedBy
    ? getEnumTranslation('advanceAmountChangedBy', castedChangedBy)
    : undefined;

  const primaryUser =
    castedChangedBy === advanceAmountChangedBy.customerservicerepresentative ? secondaryUser : initialPrimaryUser;
  const userName = primaryUser || secondaryUser;

  const timeSince = moment(feedItem.createdDateTime).locale(culture).from(moment());
  const itemVersion = feedItem.payLoad.version?.toString() || '1';

  const getContent = () => {
    switch (true) {
      case feedItem.originatorSubjectType === 'mutingrule':
        if (feedItem.actionType === 'MutingRuleCreated' && typeof feedItem.payLoad.MuteUntil === 'string') {
          return (
            //@ts-ignore
            `${getTranslation(`meter_issues.error_${feedItem.payLoad.ErrorType}`)} ${getTranslationInAnyVersion(
              `feed.description`,
              itemVersion,
              feedItem
            )} ${formatDate(feedItem.payLoad.MuteUntil)}`
          );
        } else if (feedItem.actionType === 'MutingRuleCancelled') {
          return `${getTranslation(`meter_issues.error_${feedItem.payLoad.ErrorType}`)} ${getTranslationInAnyVersion(
            `feed.description`,
            itemVersion,
            feedItem
          )}`;
        } else {
          break;
        }
      case feedItem.originatorSubjectType === 'meteringissue':
        //@ts-ignore
        return getTranslation(`meter_issues.error_${feedItem.payLoad.MeteringIssueReference.Error}`);

      case feedItem.actionType === 'CustomerAutomaticSettlementUpdated':
        return getTranslationInAnyVersion(
          `feed.description`,
          itemVersion,
          feedItem.payLoad.AutomaticSettlement
            ? { actionType: 'CustomerAutomaticSettlementOff' }
            : { actionType: 'CustomerAutomaticSettlementOn' }
        );

      default:
        return getTranslationInAnyVersion(`feed.description`, itemVersion, feedItem);
    }
  };

  const getTitle = () => {
    switch (true) {
      case feedItem.actionType === 'CustomerAutomaticSettlementUpdated':
        return (
          getTranslation(`${feedItem.payLoad.AutomaticSettlement ? 'general.enabled' : 'general.disabled'}`) +
          getTranslation('customer.automatic_settlement')
        );

      default:
        return getTranslationInAnyVersion(`feed.title`, itemVersion, feedItem);
    }
  };

  return (
    <div className={css['feed-item']}>
      <div className={css['icon']}>
        <Icon type={feedItem.originatorSubjectType} color />
      </div>
      <div className={css['heading']}>
        <Heading headingType="h4" style="bold">
          {getTitle()}
        </Heading>
      </div>
      <OverflowParagraph id={new Date().valueOf().toString()} className={css['time']}>
        {getTranslation('feed.edited_by', { timeSince, userName })}
      </OverflowParagraph>
      <div className={css['content']}>
        <Paragraph>
          <Interweave content={getContent()}></Interweave>
        </Paragraph>
      </div>
    </div>
  );
};

export default observer(FeedItem);
