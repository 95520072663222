export enum spacing {
  'spacing-4' = '0.4rem',
  'spacing-8' = '0.8rem',
  'spacing-12' = '1.2rem',
  'spacing-16' = '1.6rem',
  'spacing-24' = '2.4rem',
  'spacing-32' = '3.2rem',
  'spacing-40' = '4rem',
  'spacing-48' = '4.8rem'
}

export const GLOBAL_VARS = {
  'large-box-shadow': '0 0.6rem 1.2rem rgba(0, 0, 0, 0.06), 0 1.2rem 2.4rem rgba(109, 124, 147, 0.12)',
  'medium-box-shadow': '0 0.4rem 0.8rem rgba(0, 0, 0, 0.04), 0 0.6rem 1.2rem rgba(109, 124, 147, 0.1)',
  'small-box-shadow': '0 0.2rem 4px rgba(0, 0, 0, 0.04), 0 0.4rem 0.8rem rgba(0, 0, 0, 0.06)',

  'input-popup-z-index': '99000',
  'highest-z-index': '2147483647',
  'popup-z-index': '2147483646',
  'stacked-first-z-index': '2147482998',
  'stacked-second-z-index': '2147482999',
  'disabled-z-index': '2147483644',
  'overlay-z-index': '98000',

  'border-radius-2': '0.2rem',
  'border-radius-4': '0.4rem',
  'border-radius-8': '0.8rem',
  'border-radius-16': '1.6rem',

  'spacing-1': '0.1rem',
  'spacing-2': '0.2rem',
  'spacing-4': '0.4rem',
  'spacing-8': '0.8rem',
  'spacing-12': '1.2rem',
  'spacing-16': '1.6rem',
  'spacing-24': '2.4rem',
  'spacing-32': '3.2rem',
  'spacing-40': '4rem',
  'spacing-48': '4.8rem',

  'border-one': '.1rem solid #E1E6EB',
  'border-two': '.2rem solid #E1E6EB'
};
