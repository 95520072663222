import { SidePanel } from 'design-system/Components';
import { DetailLine } from 'design-system/ComponentSets';
import { InfoBannerColor } from 'design-system/ComponentSets/InfoBanner/InfoBanner';
import { Paragraph, Spinner } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { MeteringIssueStatus } from '@zf/api-types/enums';
import { MeasurementMeteringIssueDetails, MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { useMeterIssuesContext } from '../../../Context/Context';
import { State } from '../../types';
import { IssueActionType } from '../IssueSidePanel';
import IgnoredPanelBody from './IgnoredPanelBody';
import css from './resolve-single-issue-panel.module.scss';
import ResolvedPanelBody from './ResolvedPanelBody';
import ResolveSingleIssuePanelHeader from './ResolveSingleIssuePanelHeader';
import UnresolvedPanelBody from './UnresolvedPanelBody';

type Props = {
  issue: MeteringIssue;
  refresh: () => void;
};

const schema = yup.object().shape({
  correctedMeasurementValue: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  message: yup.string()
});

const ResolveSingleIssuePanel = (props: Props) => {
  const { issue, refresh } = props;

  const { applicationStore, meterStore, configStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getMeterById } = meterStore.deviceService;
  const { getPreviousMeasurements, validateMeasurement } = meterStore.measurementService;
  const { getMeterModels } = configStore.configService;
  const { resolveMeterIssue, ignoreMeterIssue } = meterStore.meteringIssueService;

  const ctx = useMeterIssuesContext();
  const { updateRows } = ctx;

  const meterId = issue.meter?.meterId || '';
  const issueDetails = issue.details as MeasurementMeteringIssueDetails;

  const stateReducer = createStateReducer<Pick<State, 'meter' | 'models' | 'referenceMeasurements'>, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    meter: undefined,
    models: undefined,
    referenceMeasurements: undefined
  });

  const [infoBannerDetails, setInfoBannerDetails] = useState<{
    color: InfoBannerColor;
    info: string;
    isValidating: boolean;
  }>({
    color: 'blue',
    info: 'measurement_validation.validating',
    isValidating: false
  });

  const runMsmtValidation = async (val: number) => {
    /**
     * @description call validation API and update validation context with correct error message.
     */
    if (typeof val === 'number' && !isNaN(val) && meterId) {
      setInfoBannerDetails({
        color: 'blue',
        info: 'measurement_validation.validating',
        isValidating: true
      });

      const { isValid, error } = await validateMeasurement(
        moment(issueDetails.endDateTime),
        meterId,
        issueDetails.externalChannelIdentifier,
        val
      );

      if (isValid) {
        setInfoBannerDetails({
          color: 'green',
          info: 'measurement_validation.valid',
          isValidating: false
        });
      } else {
        setInfoBannerDetails({
          color: 'orange',
          info: `meter_issues.error_${error}`,
          isValidating: false
        });
      }
    } else {
      setInfoBannerDetails({
        color: 'orange',
        info: 'measurement_validation.invalid',
        isValidating: false
      });
    }
  };

  const defaultValues = {
    correctedMeasurementValue: issueDetails.value,
    message: ''
  };

  const { control, formState, handleSubmit, getValues, reset, trigger } = useForm<
    Pick<State, 'correctedMeasurementValue' | 'message'>
  >({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    // Reinit form when selecting another row
    reset(defaultValues);
    // Run validation on init
    runMsmtValidation(issueDetails.value);
  }, [issue.id]);

  useEffect(() => {
    if (meterId) {
      Promise.all([
        getMeterById(meterId),
        getMeterModels(),
        getPreviousMeasurements(meterId, moment(issueDetails.endDateTime), 3)
      ])
        .then((res) => setState({ meter: res[0], models: res[1], referenceMeasurements: res[2] }))
        .catch((error) => {
          notify.error({
            content: getTranslation('meter_issues.get_meter_details_fail'),
            error
          });
        })
        .finally(() => {
          if (issue.status === MeteringIssueStatus.unresolved) {
            trigger('correctedMeasurementValue');
          }
        });
    }
  }, [meterId, issueDetails.endDateTime]);

  const onSubmit = async (type: IssueActionType) => {
    handleSubmit(async (data) => {
      if (type === 'resolve') {
        await resolveMeterIssue(issue.id, data.message, data.correctedMeasurementValue)
          .then((res) => {
            updateRows([res]);
            refresh();
            notify.success({ content: getTranslation('meter_issues.issue_solved') });
          })
          .catch((error) => notify.error({ content: getTranslation('meter_issues.issue_solved_fail'), error }));
      } else if (type === 'ignore') {
        await ignoreMeterIssue(issue.id, data.message)
          .then((res) => {
            updateRows([res]);
            refresh();
            notify.info({ content: getTranslation('meter_issues.issue_ignored') });
          })
          .catch((error) => notify.error({ content: getTranslation('meter_issues.issue_ignored_fail'), error }));
      }
    })();
  };

  const model = state.models?.find((m) => m.id === state.meter?.modelId);
  const panelByStatus: Record<MeteringIssueStatus, JSX.Element | null> = {
    unresolved:
      state.referenceMeasurements && state.meter ? (
        <UnresolvedPanelBody
          infoBannerState={infoBannerDetails}
          issueDetails={issueDetails}
          referenceMeasurements={state.referenceMeasurements}
          message={getValues('message')}
          correctedMeasurementValue={getValues('correctedMeasurementValue')}
          issue={issue}
          control={control}
          formState={formState}
          runMsmtValidation={runMsmtValidation}
          onSubmit={onSubmit}
          meters={[state.meter]}
          refresh={refresh}
        />
      ) : null,
    resolved: (
      <ResolvedPanelBody
        issueDetails={issueDetails}
        resolution={issue.resolution}
        issue={issue}
        meters={state.meter ? [state.meter] : []}
        onSubmit={onSubmit}
      />
    ),
    ignored: (
      <IgnoredPanelBody
        issueDetails={issueDetails}
        resolution={issue.resolution}
        issue={issue}
        meters={state.meter ? [state.meter] : []}
        onSubmit={onSubmit}
      />
    ),
    muted: null // TODO
  };

  return (
    <SidePanel>
      {state.meter && model ? (
        <FlexElements
          className={css['side-panel-body']}
          justifyContent={issue.status !== MeteringIssueStatus.unresolved ? undefined : 'space-between'}
          flexDirection="column"
        >
          <div>
            <ResolveSingleIssuePanelHeader issueId={issue.id} errorType={issue.error} />

            <DetailLine label={getTranslation('meter.meter')}>
              <Paragraph>{state.meter.serialNumber}</Paragraph>
            </DetailLine>
            <DetailLine label={getTranslation('meter.model')}>
              <Paragraph>{model.name}</Paragraph>
            </DetailLine>
          </div>

          {panelByStatus[issue.status]}
        </FlexElements>
      ) : (
        <Spinner centered />
      )}
    </SidePanel>
  );
};

export default observer(ResolveSingleIssuePanel);
