import { observer } from 'mobx-react';
import React, { useEffect, useReducer } from 'react';

import { PaymentParametersType } from '@zf/api-types/parameters';
import { UpdatePaymentDelayType } from '@zf/api-types/payments';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import useConfig from '../../../app-context/hooks/use-config';
import { Spinner } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import Parameters from './parameters';
import css from './parameters-tab.module.scss';

type State = {
  paymentParameters: PaymentParametersType | undefined;
  isLoading: boolean;
};

const ParametersTab = () => {
  const { configStore, paymentConfigStore } = useStore();
  const { getPaymentParameters } = configStore.configService;
  const { initPaymentConfig, resetStore } = paymentConfigStore;

  const config = useConfig<UpdatePaymentDelayType[]>('paymentDelays', '/cfg/PaymentDelays');

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    paymentParameters: undefined,
    isLoading: true
  });

  useEffect(() => {
    Promise.all([getPaymentParameters(), initPaymentConfig()]).then((res) =>
      setState({ paymentParameters: res[0], isLoading: false })
    );

    return () => resetStore();
  }, []);

  if (state.isLoading || config.isFetching || !state.paymentParameters)
    return (
      <div className={css['parameters-tab']}>
        <Spinner centered />
      </div>
    );

  return <Parameters paymentParameters={state.paymentParameters} paymentDelaysConfig={config} />;
};

export default observer(ParametersTab);
