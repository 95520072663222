import React from 'react';

import { culture } from '@zf/api-types/enums';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../app-context/app-context';
import useSingleAPI from '../../../hooks/useSingleAPI';
import SimpleDropdown from '../../Lang/SimpleDropdown';

export type CultureDropdownProps = {
  id: string;
  isInline?: boolean;
  selectedValue: culture;
  placeholder?: string;
  onFocus?: (step: string) => void;
  onChange: (values: culture[]) => void;
};

const InlineDropdown = InlineInputField<SimpleDropdownProps<culture>>(SimpleDropdown);

export default function CultureDropdown(props: CultureDropdownProps) {
  const { id, selectedValue, isInline = false, placeholder, onFocus, onChange } = props;
  const { i18n, enumReducer } = useAppContext();

  const culturesResponse = useSingleAPI<culture[]>({
    request: {
      endpoint: '/cfg/CultureTable/allowedcultures'
    }
  });

  const label = placeholder ? placeholder : i18n.getTranslation('organization.cultureCode');

  if (!culturesResponse || !culturesResponse.result || culturesResponse.result instanceof Promise) {
    return isInline ? (
      <InlineDropdown id={id} onChange={onChange} values={[]} selectedValues={[selectedValue]} onFocus={onFocus} />
    ) : (
      <SimpleDropdown
        id={id}
        onChange={onChange}
        placeholder={label}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    );
  }

  const cultures = culturesResponse.result.data;

  const dropdownValues = cultures.map((c) => {
    return {
      value: c,
      text: enumReducer.getTranslation('culture', c)
    };
  });

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={dropdownValues}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
    />
  ) : (
    <SimpleDropdown
      id={id}
      onChange={onChange}
      placeholder={label}
      values={dropdownValues}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
    />
  );
}
