import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import RecalculateEavDialog from '../../../actions/location/RecalculateEavDialog';
import ForecastingService from '../../../app-context/services/ForecastingService';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  entityId: string;
  entity: 'location' | 'contract';
  enabled: boolean;
  forecastingService: ForecastingService;
};

export default function RecalculateEavListItem(props: Props) {
  const { forecastingService, entity, enabled, entityId } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { clickRef, onSubmit } = useDialog();

  if (enabled) {
    return (
      <DropdownAction
        id="contract.recalculate_eav"
        icon="calculator"
        dialogTitle={getTranslation('actions.recalculate_eav')}
        dialogContent={
          <RecalculateEavDialog
            ref={clickRef}
            selectedRows={[entityId]}
            entity={entity}
            forecastingService={forecastingService}
          />
        }
        onSubmit={onSubmit}
        buttonPositive={getTranslation('general.recalculate')}
      />
    );
  }

  return null;
}
