import clone from 'clone';
import React, { useEffect, useState } from 'react';

import { TenantOrganisationType } from '@zf/api-types/auth';

import { useAppContext } from '../../../../app-context';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../../../design-system/Foundation';
import { notify } from '../../../../events/notification-events';
import InputField from 'components/input/InputField';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { useStore } from 'hooks/useStore';

type Props = {
  orgIndex: number;
  orgCode: string | undefined;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  onComplete?: () => void;
  currentOrganisation: TenantOrganisationType | undefined;
};

const DeleteOrganisationDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { orgCode, orgIndex, validationRef, onComplete, currentOrganisation } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { tenant } = tenantReducer.state;

  const id = currentOrganisation?.organizationId ? currentOrganisation?.organizationId : currentOrganisation?.id;

  const [orgCodeConfirm, setOrgCodeConfirm] = useState('');
  const { organisationStore } = useStore();
  const { organisationService } = organisationStore;
  const { deleteOrganisation } = organisationService;

  useEffect(() => {
    validationRef.current?.setIsError(orgCodeConfirm !== orgCode);
  }, [orgCodeConfirm]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (id && tenant) await deleteOrganisation(id, tenant.id);

        const tenantAuthClone = clone(tenantReducer.state.tenantAuth);
        //Remove the deleted org from the clone
        if (tenantAuthClone?.organizations[orgIndex].shortCode === orgCode) {
          tenantAuthClone?.organizations.splice(orgIndex, 1);
        }

        tenantReducer.dispatch({ tenantAuth: tenantAuthClone });

        if (onComplete) onComplete();

        notify.success({
          content: i18n.getTranslation('tenant.delete_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tenant.delete_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <FlexElements flexDirection="column" gap="16">
      <Paragraph>{i18n.getTranslation('tenant.delete_heading')}</Paragraph>
      <InputField
        id="org-code-confirm-delete"
        value={orgCodeConfirm}
        onChange={(val) => setOrgCodeConfirm(val)}
        maxLength={3}
        error={orgCodeConfirm !== orgCode}
      />
      <Paragraph bold color="red">
        {i18n.getTranslation('tenant.delete_warning')}
      </Paragraph>
    </FlexElements>
  );
});

export default DeleteOrganisationDialog;
