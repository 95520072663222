import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { AverageServiceConsumptionPerServiceLocationType } from '@zf/api-types/service-consumption';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../../../../../utils/request';
import AverageConsumptionChartCard from './average-consumption-chart-card';
import YearlyConsumptionChartCard from './yearly-consumption-chart-card';

type Props = {
  propertyGroup: PropertyGroupType;
};

export default function YearlyConsumptionCharts(props: Props) {
  const { propertyGroup } = props;

  const locationsResponse = useSuspenseSingleAPI<PagedResponseType<ServiceLocationType>>({
    request: {
      method: METHODS.GET,
      endpoint: '/md/ServiceLocations',
      query: {
        propertyGroupId: propertyGroup.id
      }
    }
  });

  const consumptionsResponse = useSuspenseSingleAPI<AverageServiceConsumptionPerServiceLocationType[]>({
    request: {
      method: METHODS.POST,
      endpoint: '/me/ServiceConsumptions/yearlyaverage',
      data: {
        serviceLocationIds: propertyGroup.serviceLocationIds
      }
    }
  });

  if (!locationsResponse.result || !consumptionsResponse.result) return null;

  const consumptions = consumptionsResponse.result.data;
  const locations = locationsResponse.result.data.results;

  return (
    <>
      <AverageConsumptionChartCard consumptions={consumptions} locations={locations} />
      <YearlyConsumptionChartCard consumptions={consumptions} locations={locations} />
    </>
  );
}
