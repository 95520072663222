import { observer } from 'mobx-react';
import React from 'react';

import { countryCode } from '@zf/api-types/enums';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import { useStore } from '../../hooks/useStore';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import NavListItem from './nav-list-item';

type Props = {
  activePage: string;
};

const CountrySpecificLink = (props: Props) => {
  const { activePage } = props;

  const { applicationStore } = useStore();
  const { rootUrl, tenantReducer, getTranslation } = applicationStore;

  const organizationConfig = useSuspenseSingleAPI<OrganizationConfigType>(
    {
      request: {
        endpoint: '/cfg/Organizations/',
        selector: tenantReducer.organization?.organizationId
      }
    },
    false,
    false
  );

  if (!organizationConfig.result) return null;

  return (
    <>
      {organizationConfig.result.data?.address?.country === countryCode.bel && (
        <NavListItem
          id="nav-country-specifics"
          title={getTranslation('country_specifics.country_specifics')}
          href={`${rootUrl}/country-specifics/social-tariffs`}
          active={activePage === 'country-specifics'}
          iconType="language"
        />
      )}
    </>
  );
};

export default observer(CountrySpecificLink);
