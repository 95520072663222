import LocationSectionStore, {
  locationSelection
} from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import IconParagraph from 'components/Icon/IconParagraph';
import { ICONS } from 'constants/icons';
import { Button } from 'design-system/Components';
import { Select } from 'design-system/ComponentSets';
import { SelectValueType } from 'design-system/ComponentSets/Select/Select';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { utilityType } from '@zf/api-types/enums';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import css from './location-summary.module.scss';
import OldContractorInvoiceAddress from './OldContractorInvoiceAddress';

type Props = {
  locationSectionStore: LocationSectionStore;
  location: ServiceLocationType;
};

const LocationSummary = (props: Props) => {
  const { locationSectionStore, location } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation, getEnum } = applicationStore;
  const {
    locationSelectionType,
    getAlreadySuppliedServices,
    handleSelectServiceSingleLocation,
    handleSelectServiceInMap,
    removeSelectedLocation,
    removeLocationFromMap
  } = locationSectionStore;

  const selectedValues = location.services.map((s) => s.utilityType);

  const serviceSelectValues = getEnum<utilityType>('utilityType').reduce((acc: SelectValueType<utilityType>[], u) => {
    if (u.value !== utilityType.none) {
      acc.push({
        icon: ICONS[u.value],
        value: u.value,
        text: u.text
      });
    }

    return acc;
  }, []);

  const alreadySuppliedServices = getAlreadySuppliedServices(location, selectedValues);

  return (
    <div className={css['location-summary']}>
      <FlexElements justifyContent="space-between">
        <Heading headingType="h3">{location.address?.localizedDisplay || ''}</Heading>
        <Button
          id={`remove-location-${location.id}`}
          icon="cross"
          type="text"
          onClick={() => {
            if (locationSelectionType === locationSelection.single) {
              removeSelectedLocation();
            } else {
              removeLocationFromMap(location.id);
            }
          }}
          danger
        />
      </FlexElements>

      <Paragraph>{getTranslation('location.eligible_services')}</Paragraph>
      <Select
        id="contracts.services"
        className={css['services-select']}
        type="small"
        onChange={(services) => {
          locationSelectionType === locationSelection.single
            ? handleSelectServiceSingleLocation(services)
            : handleSelectServiceInMap(location.id, services);
        }}
        selectedValues={selectedValues}
        values={serviceSelectValues}
        mayDeselect
      />

      {alreadySuppliedServices.length !== 0 && <HorizontalDivider />}

      {alreadySuppliedServices.map((sups) => {
        return (
          <IconParagraph
            key={`${location.id}-${sups.contractId}-${sups.utilityType}`}
            className={css['service-warning']}
            iconType={ICONS[sups.utilityType]}
            color={colors['orange-600']}
          >
            {getTranslation('contracts.validation.already_supplied', {
              service: getEnumTranslation('utilityType', sups.utilityType),
              contractNumber: sups.contractNumber,
              contractor: sups.contractorDisplayName,
              startDate: formatDate(sups.supplyStartDateTime),
              endDate: formatDate(sups.supplyEndDateTime)
            })}
          </IconParagraph>
        );
      })}

      <OldContractorInvoiceAddress
        id={`invoice-address-old-contractor-${location.id}`}
        location={location}
        locationSectionStore={locationSectionStore}
      />
    </div>
  );
};

export default observer(LocationSummary);
