import React from 'react';

export default function useDebounce(debounceTime: number) {
  const [debounce, setDebounce] = React.useState<number | null>(null);

  return (callback: Function) => {
    if (debounce) {
      clearTimeout(debounce);
    }

    setDebounce(setTimeout(callback, debounceTime));
  };
}
