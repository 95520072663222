import React from 'react';

import { IncomingMutationType } from '@zf/api-types/billing/incoming-mutations';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import { NoDataOverlayType } from '@zf/stella-react/src/atoms/Table/table-overlay';

import { useAppContext } from '../../app-context';
import useCreateRequest from '../../hooks/useCreateRequest';
import useInfiniAPI from '../../hooks/useInfiniAPI';
import { getCustomerBankAccountFromIncomingMutation, getQueryStatus } from '../../utils/incoming-mutation';
import { MatchDialogState } from './ManualMatchDialog';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';

type Props = {
  incomingMutation: IncomingMutationType;
  requestEndpoint: string;
  columns: ColumnType[];
  processRecord: any;
  state: MatchDialogState;
  singleSelect?: boolean;
  quickFilter?: string;
  outgoingBankingTransactionType?: string;
  dispatch: React.Dispatch<Partial<MatchDialogState>>;
  emptyState?: NoDataOverlayType;
};

export default function SingleSelectMatchTable(props: Props) {
  const {
    quickFilter,
    processRecord,
    columns,
    outgoingBankingTransactionType,
    requestEndpoint,
    singleSelect,
    incomingMutation,
    state,
    emptyState,
    dispatch
  } = props;
  const { i18n, tenantReducer } = useAppContext();

  const { request, sortBy, sortDirection, handleSort } = useCreateRequest(requestEndpoint, {
    quickFilter: quickFilter,
    outgoingBankingTransactionType: outgoingBankingTransactionType,
    iban: getCustomerBankAccountFromIncomingMutation(incomingMutation),
    status: getQueryStatus(incomingMutation)
  });

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<any, any>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  return (
    <DynamicVirtualTable
      id="matching-table-transactions"
      rowHeight={40}
      loading={loading}
      singleSelect={singleSelect}
      error={error}
      rows={rows}
      totalAmountOfRows={rows.length}
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      onSelectRow={(rowIds) => dispatch({ selectedIds: rowIds })}
      selectedRows={state.selectedIds}
      NoDataOverlay={emptyState}
      columns={columns}
      sortBy={sortBy}
      sortDirection={sortDirection}
      sortableFields={sortableFields}
      sort={handleSort}
    />
  );
}
