import React from 'react';

import { Reference } from '@zf/api-types/payments';
import { TransactionType } from '@zf/api-types/transactions';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../app-context';
import { DANGER } from '../../constants/color';
import { SettleState } from './settle';
import SettleTable from './settle-table';
import css from './style.module.scss';

type Props = {
  customerId: string;
  state: SettleState;
  setState: React.Dispatch<Partial<SettleState>>;
  setReferences?: (val: Reference[]) => void; // Add payment dialog
  setTransactions?: (newTransactions: TransactionType[]) => void; // Settle dialog
};

export default function SettleHelper(props: Props) {
  const { state } = props;
  const { amount } = state;
  const { i18n } = useAppContext();

  const style: React.CSSProperties = React.useMemo(() => {
    return { textAlign: amount < 0 ? 'left' : 'right' };
  }, [amount]);

  return (
    <>
      <div className={css['add-margin-top']}>
        <Paragraph>{i18n.getTranslation('actions.payments.transactions_to_settle_with')}</Paragraph>
        <div className={css['payment-wrapper']}>
          <div className={css['payment-amount']}>
            <Label>{i18n.getTranslation('payments.open_amount')}</Label>
            <Label>{i18n.getTranslation('payments.remaining_amount')}</Label>
            <Paragraph style={style}>{formatMoney(Math.abs(amount), i18n.culture)}</Paragraph>
            <Paragraph color={DANGER}>
              {formatMoney(state.remainingAmount !== 0 ? -state.remainingAmount : 0, i18n.culture)}
            </Paragraph>
          </div>
        </div>
      </div>
      <SettleTable {...props} />
    </>
  );
}
