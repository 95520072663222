import moment, { Moment } from 'moment';
import React from 'react';

import {
  AddMeasurementRequestType,
  MeasurementRowType,
  MeasurementType,
  MeterType
} from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import MeasurementDialogContent from '../../../actions/meters/add-measurement/measurement-dialog';
import { useAppContext } from '../../../app-context';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { SuspenseSpinner } from '../../suspense';
import ConfirmationActionButton from '../ConfirmationActionButton';
import css from './add-measurement-detail-btn.module.scss';

type Props = {
  meter: MeterType;
  selectedRows: MeasurementRowType[];
  locationId?: string;
  measurementDate?: Moment;
};

export default function EditMeasurementDetailButton(props: Props) {
  const { meter, selectedRows, measurementDate, locationId } = props;
  const { refresh } = useRefresh();
  const { i18n } = useAppContext();

  let measurementsToHandle: AddMeasurementRequestType | undefined;
  let measurement: MeasurementType | undefined;

  if (selectedRows && selectedRows.length === 1) {
    const selectedRow = selectedRows[0];
    measurement = selectedRow.__measurementEntity;
    const date = moment(measurement.endDateTime);

    measurementsToHandle = {
      channelId: measurement.externalChannelIdentifier,
      endDateTime: date.toISOString(),
      value: measurement.value,
      hour: date.hour().toString(),
      minute: date.minute().toString(),
      meter: meter
    };
  } else if (measurementDate) {
    measurementsToHandle = {
      channelId: '',
      endDateTime: measurementDate.toISOString(),
      value: 0,
      hour: measurementDate.hour().toString(),
      minute: measurementDate.minute().toString(),
      meter: {} as MeterType
    };
  }

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <ConfirmationActionButton
      id="meter.add_measurement"
      className={css['btn-style']}
      icon="sign"
      dialogTitle={i18n.getTranslation('actions.edit_measurement')}
      dialogContent={
        <SuspenseSpinner size="xsmall">
          <MeasurementDialogContent
            useCase="edit"
            meterToHandle={measurementsToHandle}
            locationId={locationId}
            ref={clickRef}
            onComplete={refresh}
            validationRef={validationRef}
            measurement={measurement}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      disabled={selectedRows.length !== 1}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.edit_measurement')}
    />
  );
}
