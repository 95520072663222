import React from 'react';

import { useAppContext } from '../../../app-context';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import usePaymentListPage from '../hooks/usePaymentListPage';

export default function PaymentsListPage() {
  const { i18n } = useAppContext();

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = usePaymentListPage();

  return React.useMemo(() => {
    return (
      <DynamicVirtualTable
        id="payments-table"
        tooltipId="payments-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        sort={handleSort}
        sortableFields={sortableFields}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        singleSort
        columns={[
          {
            width: 240,
            label: i18n.getTranslation('banking_transaction.transaction_identification'),
            dataKey: 'transactionIdentification',
            noClick: true
          },
          {
            width: 240,
            label: i18n.getTranslation('general.type'),
            dataKey: 'type'
          },
          {
            width: 140,
            label: i18n.getTranslation('payments.is_reversed'),
            dataKey: 'isReversed'
          },
          {
            width: 150,
            label: i18n.getTranslation('payments.payment_date'),
            dataKey: 'paymentDateTime'
          },
          {
            width: 240,
            label: i18n.getTranslation('payments_list.labels.customer_iban'),
            dataKey: 'customerIBAN'
          },
          {
            width: 140,
            label: i18n.getTranslation('general.amount_valuta'),
            dataKey: 'amount',
            contentAlignRight: true
          },
          {
            width: 400,
            label: i18n.getTranslation('payments_list.labels.paymentReference'),
            dataKey: 'paymentReference'
          },
          {
            width: 300,
            label: i18n.getTranslation('customer.customer'),
            dataKey: 'customer'
          }
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, i18n.lang]);
}
