import React from 'react';

import { ImportJobRowType, ImportJobType, TriggerImportJobType } from '@zf/api-types/import-job';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { validateImportJob } from './import-job';

type Props = {
  selectedRows?: ImportJobRowType[];
  importJobToHandle?: TriggerImportJobType;
  onComplete?: (updatedRows?: ImportJobType[]) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, importJobToHandle, onComplete } = props;
  const { i18n, tenantReducer } = useAppContext();

  let importJobsToHandle: TriggerImportJobType[] = [];

  if (selectedRows) {
    importJobsToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (importJobToHandle) {
    importJobsToHandle = [importJobToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (importJobsToHandle.length > 0) {
        try {
          const newImportJobs: ImportJobType[] = await Promise.all(
            importJobsToHandle.map((importJob) => validateImportJob(importJob, tenantReducer, i18n.lang))
          );

          if (onComplete) onComplete(newImportJobs);

          notify.success({
            content: i18n.getTranslation('actions.import_jobs.validation_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.import_jobs.validation_failed'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation('actions.import_jobs.validate', { amount: importJobsToHandle.length.toString() })}
    </Paragraph>
  );
});
