import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { entitySubjectType } from '@zf/api-types/enums';
import { FeedType } from '@zf/api-types/feed';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import { useStore } from '../../hooks/useStore';
import { METHODS } from '../../utils/request';
import FeedCardBodyContent from './FeedCardBodyContent';

type Props = {
  subjectType: entitySubjectType;
  subjectId: string;
  timeStamp?: string;
};

const FeedCardBody = (props: Props) => {
  const { subjectType, subjectId, timeStamp = '' } = props;

  const { applicationStore } = useStore();
  const { sendRequest } = applicationStore;

  const [feedItems, setFeedItems] = useState<FeedType[] | undefined>();

  const getFeed = async () => {
    const res = await sendRequest<PagedResponseType<FeedType>>({
      request: {
        method: METHODS.GET,
        endpoint: `/activity/feed/${subjectType}/${subjectId}`
      },
      donotCache: true
    });

    setFeedItems(res.data.results);
  };

  useEffect(() => {
    //initial call
    getFeed();
    //call every 10 seconds
    const feedTimeout = setInterval(getFeed, 10000);
    return () => {
      clearInterval(feedTimeout);
    };
  }, [timeStamp, subjectId]);

  return (
    <AsyncVarSpinner variables={[feedItems]}>
      <FeedCardBodyContent feedItems={feedItems || []} />
    </AsyncVarSpinner>
  );
};

export default observer(FeedCardBody);
