import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AddressType } from '@zf/api-types/general';

import InvoiceAddress from '../../../shared/InvoiceAddress/InvoiceAddress';

type Props = {
  id: string;
};

const NewContractorInvoiceAddress = (props: Props) => {
  const { id } = props;

  const { contractStore } = useStore();
  const { locationSectionStore, regularMoveInWizardValues } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { selectedLocation } = locationSectionStore;
  const { values, setValue } = regularMoveInWizardValues;

  if (!values.contractor) return null;

  const handleUpdateNewContractorInvoiceAddress = (val: Partial<AddressType>) => {
    setValue({
      // @ts-ignore
      contractor: { ...values.contractor, invoiceAddress: { ...values.contractor?.invoiceAddress, ...val } }
    });
  };

  return (
    <InvoiceAddress
      id={id}
      useCase="new_contractor"
      contractorDisplayName={values.contractor.shortDisplayName}
      invoiceAddress={values.contractor.invoiceAddress}
      selectedLocation={selectedLocation}
      setInvoiceAddressValue={handleUpdateNewContractorInvoiceAddress}
    />
  );
};

export default observer(NewContractorInvoiceAddress);
