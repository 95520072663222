import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction } from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';

import SubmetersCardContent from './SubmetersCardContent';

type Props = {
  meter: MeterType;
  timeStamp: string;
  setMeter: Dispatch<SetStateAction<MeterType | null>>;
  refresh: () => void;
};

const SubmetersCard = (props: Props) => {
  const { meterStore } = useStore();
  const { models } = meterStore;

  if (!models) return null;

  return <SubmetersCardContent {...props} models={models} />;
};

export default observer(SubmetersCard);
