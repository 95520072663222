import classNames from 'classnames';
import React from 'react';

import css from './spinner.module.scss';

type Props = {
  inverted?: boolean;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  className?: string;
  centered?: boolean;
};

export default function Spinner(props: Props) {
  const { inverted = false, className, size = 'medium', centered = false } = props;

  return (
    <div
      className={classNames(css['spinner'], className, {
        [css['spinner-xsmall']]: size === 'xsmall',
        [css['spinner-small']]: size === 'small',
        [css['spinner-medium']]: size === 'medium',
        [css['spinner-large']]: size === 'large',
        [css['centered']]: centered,
        [css['spinner-inverted']]: inverted === true
      })}
    />
  );
}
