import React from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';

import DeleteTaskListItem from '../../../components/list-items/tasks/DeleteTaskListItem';
import RejectListItem from '../../../components/list-items/tasks/RejectListItem';
import ResolveWaitingListItem from '../../../components/list-items/tasks/ResolveWaitingListItem';
import SetWaitingListItem from '../../../components/list-items/tasks/SetWaitingListItem';

type Props = {
  task: MoveRequestType;
  setTask: (updatedTask: MoveRequestType) => void;
};

const TaskActions = (props: Props) => {
  const { task, setTask } = props;

  return (
    <>
      {task.status !== moveRequestStatus.rejected && task.status !== moveRequestStatus.processed && (
        <RejectListItem task={task} setTask={setTask} />
      )}

      {task.status === moveRequestStatus.new && <SetWaitingListItem task={task} setTask={setTask} />}

      {(task.status === moveRequestStatus.pendingatcustomer || task.status === moveRequestStatus.pendingatsupplier) && (
        <ResolveWaitingListItem task={task} setTask={setTask} />
      )}

      <DeleteTaskListItem task={task} />
    </>
  );
};

export default TaskActions;
