import classNames from 'classnames';
import React from 'react';

import { formulaFunctionInputParameterReferenceType } from '@zf/api-types/enums';
import {
  CreateFormulaFunctionType,
  FixedReferenceTypeParametersType,
  FormulaFunctionInputParameterType,
  FormulaFunctionOutputReferenceTypeParametersType,
  ReferenceTypeParametersType,
  RuntimeParameterReferenceTypeParametersType
} from '@zf/api-types/property-group-billing-configuration';
import {
  FormulaFunctionExceptionType,
  FormulaValidationResultFunctionValidationError
} from '@zf/api-types/property-metering-configuration';
import useDialog from '@zf/hooks/src/useDialog';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';
import { colors, convertHexToRGBA } from '@zf/utils/src/color';

import DeleteFormulaFunction from '../../../../actions/property-group/delete-formula-function';
import { useAppContext } from '../../../../app-context';
import { DeleteIcon } from '../../../../components/Icon';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';
import { dialog } from '../../../../events/dialog-events';
import css from './formula-builder.module.scss';
import FormulaFunctionParameterValue from './formula-function-parameter-value';

type Props = {
  index: number;
  color: string;
  formulaFunction: CreateFormulaFunctionType;
  outputParameterNames: string[];
  runtimeParameters: string[];
  validationError: FormulaValidationResultFunctionValidationError | undefined;
  calculationError: FormulaFunctionExceptionType | undefined;
  removeFormulaFunction: (index: number) => void;
  setFormulaFunctionValue: (
    formulaFunctionIndex: number,
    inputParameterIndex: number,
    key: keyof FormulaFunctionInputParameterType,
    value: FormulaFunctionInputParameterType[keyof FormulaFunctionInputParameterType]
  ) => void;
  setReferenceType: (
    formulaFunctionIndex: number,
    inputParameterIndex: number,
    type: formulaFunctionInputParameterReferenceType
  ) => void;
  setOutputName: (formulaFunctionIndex: number, newName: string) => void;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function FormulaFunction(props: Props) {
  const {
    index,
    color,
    validationError,
    calculationError,
    formulaFunction,
    outputParameterNames,
    runtimeParameters,
    removeFormulaFunction,
    setFormulaFunctionValue,
    setReferenceType,
    setOutputName
  } = props;
  const { i18n, enumReducer } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const bgColor = convertHexToRGBA(color, 20);

  const openDeleteDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.billing.delete_formula_function'),
      icon: 'trashcan',
      content: (
        <DeleteFormulaFunction
          ref={clickRef}
          formulaFunction={formulaFunction}
          deleteFunction={() => removeFormulaFunction(index)}
        />
      ),
      buttonPositive: i18n.getTranslation('general.delete'),
      type: 'danger',
      onSubmit
    });
  };

  const updateReferencetypeParameters = (
    inputParameterIndex: number,
    type: formulaFunctionInputParameterReferenceType,
    value: string | number
  ) => {
    const newReferenceTypeParameters: ReferenceTypeParametersType = {
      type: type
    };

    switch (type) {
      case formulaFunctionInputParameterReferenceType.fixed:
        {
          const casted = newReferenceTypeParameters as FixedReferenceTypeParametersType;
          casted.value = value;
        }
        break;
      case formulaFunctionInputParameterReferenceType.formulafunctionoutput:
        {
          const casted = newReferenceTypeParameters as FormulaFunctionOutputReferenceTypeParametersType;
          casted.outputParameterName = value as string;
        }
        break;
      case formulaFunctionInputParameterReferenceType.runtimeparameter:
        {
          const casted = newReferenceTypeParameters as RuntimeParameterReferenceTypeParametersType;
          casted.runtimeParameterName = value as string;
        }
        break;
    }

    setFormulaFunctionValue(index, inputParameterIndex, 'referenceTypeParameters', newReferenceTypeParameters);
  };

  const getValue = (
    type: formulaFunctionInputParameterReferenceType,
    referenceTypeParameters: ReferenceTypeParametersType
  ) => {
    switch (type) {
      case formulaFunctionInputParameterReferenceType.fixed: {
        const casted = referenceTypeParameters as FixedReferenceTypeParametersType;
        return casted.value ? casted.value.toString() : '';
      }
      case formulaFunctionInputParameterReferenceType.formulafunctionoutput: {
        const casted = referenceTypeParameters as FormulaFunctionOutputReferenceTypeParametersType;
        return casted.outputParameterName;
      }
      case formulaFunctionInputParameterReferenceType.runtimeparameter: {
        const casted = referenceTypeParameters as RuntimeParameterReferenceTypeParametersType;
        return casted.runtimeParameterName;
      }
    }
  };

  const rows = formulaFunction.inputParameters.map((parameter, inputParameterIndex) => {
    const value = getValue(parameter.referenceType, parameter.referenceTypeParameters);

    return {
      parameter: <Paragraph bold>{parameter.name}</Paragraph>,
      parameterType: (
        <SimpleDropdown
          id={`parameterType-${index}-${inputParameterIndex}`}
          onChange={(val) => setReferenceType(index, inputParameterIndex, val[0])}
          values={enumReducer.getEnum<formulaFunctionInputParameterReferenceType>(
            'formulaFunctionInputParameterReferenceType'
          )}
          selectedValues={[parameter.referenceType]}
          error={!parameter.referenceType}
          darkMode
        />
      ),
      value: (
        <FormulaFunctionParameterValue
          index={index}
          inputParameterIndex={inputParameterIndex}
          outputParameterNames={outputParameterNames}
          runtimeParameters={runtimeParameters}
          referenceType={parameter.referenceType}
          value={value}
          updateReferencetypeParameters={updateReferencetypeParameters}
        />
      )
    };
  });

  return (
    <div className={css['formula']}>
      <div
        className={classNames(css['header'], { [css['error']]: !!validationError || !!calculationError })}
        style={{ color: color, backgroundColor: bgColor }}
      >
        <Label color={colors['silver-700']} bold>{`${i18n.getTranslation(
          'property_groups.tabs.billing.function'
        )}:`}</Label>
        <div>
          <Paragraph color={color} bold>
            {enumReducer.getTranslation('formulaFunctionType', formulaFunction.functionType)}
          </Paragraph>
          {validationError && (
            <Paragraph
              color={colors['red-600']}
            >{`${validationError.value}: ${validationError.error.message}`}</Paragraph>
          )}
          {calculationError && <Paragraph color={colors['red-600']}>{`${calculationError.error.message}`}</Paragraph>}
        </div>

        <DeleteIcon id={`delete-formula-${index}`} style={{ color: color }} onClick={openDeleteDialog} />
      </div>
      <div className={css['parameters']} style={{ borderColor: color }}>
        <DeprecatedStaticTable rows={rows}>
          <DeprecatedStaticColumn
            flexWidth="1"
            dataKey="parameter"
            label={i18n.getTranslation('property_groups.tabs.billing.parameter')}
          />
          <DeprecatedStaticColumn
            flexWidth="1"
            dataKey="parameterType"
            label={i18n.getTranslation('property_groups.tabs.billing.parameter_type')}
          />
          <DeprecatedStaticColumn flexWidth="1" dataKey="value" label={i18n.getTranslation('general.value')} />
        </DeprecatedStaticTable>
      </div>
      <div className={css['output']} style={{ color: color, backgroundColor: bgColor }}>
        <Label color={colors['silver-700']} bold>
          {i18n.getTranslation('property_groups.tabs.billing.output_name')}
        </Label>
        <InlineInputFieldInput
          id={`output-name-${index}`}
          onChange={(val) => setOutputName(index, val)}
          value={formulaFunction.outputParameterName}
          placeholder={i18n.getTranslation('general.name')}
          error={!formulaFunction.outputParameterName}
        />
      </div>
    </div>
  );
}
