import RootStore from '../../../app-context/stores';
import CommunicationService from '../services/CommunicationService';
import SettingsStore from './SettingsStore';
import TemplatesStore from './TemplatesStore';
import TranslationStore from './TranslationStore';

class CommunicationStore {
  public rootStore: RootStore;
  public templatesStore: TemplatesStore;
  public settingsStore: SettingsStore;
  public translationStore: TranslationStore;

  public communicationService: CommunicationService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.communicationService = new CommunicationService(this, rootStore.applicationStore);
    this.templatesStore = new TemplatesStore(this);
    this.settingsStore = new SettingsStore(this);
    this.translationStore = new TranslationStore(this);
  }
}

export default CommunicationStore;
