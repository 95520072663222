import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';

export default function NoInvoicesOverlay() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/invoices.png"
          alt="invoice"
          title="invoice"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('invoice.no_invoices')}
    >
      {i18n.getTranslation('invoice.no_invoices_description')}
    </CardEmptyBody>
  );
}
