import { CustomerGroupType } from '@zf/api-types/master-data/customer-group';
import InputField from 'components/input/InputField';
import { DialogClickRef, ValidationRef } from 'design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import { observer } from 'mobx-react';
import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { METHODS } from 'utils/request';
import css from './add-customer-group-dialog.module.scss';
import { navigate } from '@reach/router';
import { useAppContext } from 'app-context';

type Props = {
  onComplete?: () => void;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

const AddCustomerGroupDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { applicationStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;
  const { validationRef, onComplete } = props;
  const { tenantReducer } = useAppContext();
  const { tenant, organization } = tenantReducer.state;
  const [state, setState] = useState({ name: '' });

  const validate = () => {
    if (validationRef && validationRef.current) {
      validationRef.current.setIsError(!state.name);
    }
  };

  React.useEffect(() => {
    validate();
  }, [state]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        // Call API
        const customerGroup = await sendRequest<CustomerGroupType>({
          request: {
            method: METHODS.POST,
            endpoint: '/md/customergroups',
            data: state
          }
        });

        if (customerGroup.data.id && onComplete) {
          onComplete();
          navigate(`/${tenant?.name}/${organization?.shortCode}/customers/customer-groups/${customerGroup.data.id}`);
        }

        notify.success({
          icon: 'plus',
          content: getTranslation('customer_groups.add_customer_group_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('customer_groups.add_customer_group_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph className={css['add-customer-group-dialog-desc']}>
        <Interweave content={getTranslation('customer_groups.add_customer_group_desc')}></Interweave>
      </Paragraph>
      <InputField
        id="customer-group-name"
        onChange={(val) => setState({ name: val })}
        value={state.name}
        placeholder={getTranslation('general.name')}
        error={!state.name}
        className={css['add-customer-group-input']}
      />
    </>
  );
});

export default observer(AddCustomerGroupDialog);
