import { MeterRowType, MeterType } from '@zf/api-types/master-data/meter';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import useDialog from '@zf/hooks/src/useDialog';
import { CreateMutingRuleDialogContent } from 'actions/meters/create-muting-rule/create-muting-rule-dialog';
import useCurrent from 'app-context/hooks/use-current';
import { SuspenseSpinner } from 'components/suspense';
import { DropdownAction } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import React from 'react';
import css from './add-measurement-detail-btn.module.scss';
import { colors } from '@zf/utils/src/color';

type Props = {
  meters?: MeterType[];
  issues?: MeteringIssue[];
  useCase: 'list-page' | 'detail-page' | 'issue-panel' | 'issue-card';
  withIgnore?: boolean;
  ignoreMessage?: string;
  refresh?: () => void;
};

export const CreateMutingRuleListPageButton = (props: Props) => {
  const { myCurrent } = useCurrent('meter');
  const selectedRows: MeterRowType[] = myCurrent.listPage.selectedRows;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { meters, issues, useCase, withIgnore, ignoreMessage, refresh } = props;
  let selectedMeters: MeterType[] = [];

  let dialogTitle = getTranslation('meter_issues.muted_create_rule_for_meters_dialog_title');
  let disabled: boolean | undefined = false;
  let icon: string | undefined = 'alert-x';
  let actionTitle = '';

  switch (useCase) {
    case 'list-page':
      disabled = selectedRows.length <= 0;
      selectedMeters = selectedRows.flatMap(({ __meterEntity }) => __meterEntity);
      dialogTitle = getTranslation('meter_issues.muted_create_rule_for_meters_dialog_title');
      actionTitle = getTranslation('meter_issues.create_a_muting_rule');

      break;
    case 'detail-page':
      disabled = meters && meters?.length <= 0;
      selectedMeters = meters ? meters : [];
      actionTitle = getTranslation('meter_issues.create_a_muting_rule');

      break;
    case 'issue-card':
      disabled = meters && meters?.length <= 0;
      dialogTitle = getTranslation('meter_issues.muted_create_rule_for_meters_dialog_title');
      selectedMeters = meters ? meters : [];
      icon = undefined;
      actionTitle = getTranslation('general.add');

      break;
    case 'issue-panel':
      disabled = issues && issues?.length <= 0;
      selectedMeters = meters ? meters : [];
      actionTitle = getTranslation('meter_issues.ignore_create_a_muting_rule');
      break;

    default:
      break;
  }

  return (
    <DropdownAction
      className={css[`${useCase === 'issue-card' ? 'no-padding' : ''}`]}
      id="meter.create_muting_rule"
      icon={icon}
      dialogTitle={dialogTitle}
      actionTitle={
        useCase === 'issue-panel' ? (
          <span style={{ color: colors['graphite'] }}>{actionTitle}</span>
        ) : (
          <>{actionTitle}</>
        )
      }
      type="text"
      dialogContent={
        <SuspenseSpinner size="small">
          <CreateMutingRuleDialogContent
            onComplete={refresh}
            selectedRows={selectedRows}
            ref={clickRef}
            validationRef={validationRef}
            meters={selectedMeters}
            withIgnore={withIgnore}
            issues={issues}
            ignoreMessage={ignoreMessage}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      disabled={disabled}
      validationRef={validationRef}
      buttonPositive={getTranslation('actions.create')}
    />
  );
};
