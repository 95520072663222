import { addLocationsMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/AddLocationsMoveInWizardStore';
import MoneyInput from 'components/input/MoneyInput';
import { WizardSectionHeader } from 'design-system/Components';
import { InfoBanner, SettingDescription } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import css from './billing-section.module.scss';

type Props = {
  showBillingSection: boolean;
  showInfo: boolean;
  advanceAmount: number | undefined;
  setAdvanceAmount: (amount: number) => void;
  setActiveStep: () => void;
};

const BillingSection = (props: Props) => {
  const { showBillingSection, showInfo, advanceAmount, setAdvanceAmount, setActiveStep } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  if (!showBillingSection) return null;

  return (
    <WizardSection id="billing-section" setActiveStep={setActiveStep}>
      <WizardSectionHeader
        id={addLocationsMoveInWizardSteps.billing}
        text={getTranslation('contracts.steps.billing', { stepNum: 3 })}
      />

      {showInfo && (
        <InfoBanner
          className={css['advance-info']}
          info={getTranslation('contracts.wizard.change_adv_amount_suggestion')}
          color="blue"
          roundedCorners
        />
      )}

      <SettingDescription
        title={getTranslation('contracts.advance_amount')}
        description={getTranslation('contracts.advance_amount_descr')}
        setting={
          <MoneyInput
            id="contracts.advanceAmount"
            onChange={setAdvanceAmount}
            value={advanceAmount}
            placeholder={getTranslation('contracts.advance_amount')}
            error={typeof advanceAmount === 'undefined' || isNaN(advanceAmount)}
          />
        }
      />
    </WizardSection>
  );
};

export default observer(BillingSection);
