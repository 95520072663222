import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { EntityAttachment } from '@zf/api-types/entity-attachments/entity-attachments';

import useSpecificFileColumns from '../../../../components/units/EntityAttachments/logic/useSpecificFileColumns';
import Files from '../../../../components/units/EntityAttachments/ui/Files';
import { useStore } from '../../../../hooks/useStore';
import { attachmentType } from '../../../../components/units/EntityAttachments/logic/FilesStore';
import OriginColumn from '../../../../components/units/EntityAttachments/ui/OriginColumn';

type Props = {
  entityId: string;
};

const CustomerAttachments = (props: Props) => {
  const { entityId } = props;
  const { applicationStore, customerStore } = useStore();
  const { filesStore } = customerStore;
  const { rootUrl, getTranslation, getEnumTranslation } = applicationStore;
  const { listFiles } = customerStore.entityAttachmentFeatures;

  const { getOrigin, getSeen, getSigned } = useSpecificFileColumns(rootUrl, getTranslation, getEnumTranslation);

  const [specificFileCols] = useState([
    {
      width: 120,
      label: getTranslation('entity_attachment.seen'),
      dataKey: 'seen'
    },
    {
      width: 130,
      label: getTranslation('entity_attachment.signed_date'),
      dataKey: 'signedDateTime'
    },
    {
      width: 300,
      label: getTranslation('entity_attachment.origin'),
      dataKey: 'origin',
      noClick: true
    }
  ]);

  const processSpecificCols = (entityAttachment: EntityAttachment) => {
    const { originUrl, origin, contract, contracts, contractUrl, originDisplayName } = getOrigin(entityAttachment);

    return {
      origin: originUrl && origin && (
        <OriginColumn
          contracts={contracts || []}
          origin={origin}
          contractUrl={contractUrl}
          contractDisplayName={contract}
          originurl={originUrl}
          originDisplayName={originDisplayName}
        />
      ),
      seen: getSeen(entityAttachment),
      signedDateTime: getSigned(entityAttachment)
    };
  };

  return (
    <Files
      listFiles={listFiles}
      attachmentType={attachmentType.entityattachments}
      customerId={entityId}
      filesStore={filesStore}
      entityId={entityId}
      specificCols={specificFileCols}
      includeContractFilter
      processSpecificCols={processSpecificCols}
    />
  );
};

export default observer(CustomerAttachments);
