import React from 'react';

import { Trail } from '../../../../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../../../../design-system/Foundation';
import css from './static-options-dialog.module.scss';

type Props = {
  options: string[];
};

const StaticOptionList = (props: Props) => {
  const { options } = props;

  return (
    <div className={css['conditions-add-options']}>
      {options.map((o, index) => (
        <div className={css[`conditions-add-options-item`]} key={`${o}-${index}`}>
          <Trail id={`option-trail-${index}`} indentLevel={1} />
          <Paragraph className={css['conditions-add-options-item-option']} textAlign="left">
            {o.toUpperCase()}
          </Paragraph>
        </div>
      ))}
    </div>
  );
};

export default StaticOptionList;
