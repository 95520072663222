import React from 'react';
import { ArrayParam, StringParam } from 'serialize-query-params';

import { meterType } from '@zf/api-types/enums';
import { meterTypeGroup } from '@zf/api-types/local-enums';
import { MeterOverviewCountType } from '@zf/api-types/master-data/meter';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import useFilter from '../../../../app-context/hooks/use-filter';
import FilterTag from '../../../../components/Filters/filter-tag';
import FilterTags from '../../../../components/Filters/filter-tags';

const initialMeterOverviewCount: MeterOverviewCountType = {
  all: 0,
  requireAttention: 0,
  inactive: 0,
  installed: 0
};

type QueryParam = {
  receivedMeteringDataStart?: string;
  receivedMeteringDataEnd?: string;
  propertyGroupIds: string[];
  meterType: meterType[];
  propertyGroupName: string;
};

const initialQueryParams = {
  receivedMeteringDataStart: undefined,
  receivedMeteringDataEnd: undefined,
  propertyGroupIds: [],
  meterType: [],
  propertyGroupName: ''
};

const paramTypes = {
  propertyGroupIds: ArrayParam,
  receivedMeteringDataStart: StringParam,
  receivedMeteringDataEnd: StringParam,
  meterType: ArrayParam,
  propertyGroupName: StringParam
};

const useMeterFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams
  } = useFilter<QueryParam>({
    endpoint: '/md/meters/overviewcount',
    initialCount: initialMeterOverviewCount,
    domain: 'meter',
    initialQueryParams,
    search,
    paramTypes,
    defaultQueryParams: { meterTypeGroup: meterTypeGroup.traditional }
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.propertyGroupIds && queryParams.propertyGroupIds.length > 0) {
    tags.push(
      <FilterTag
        value={queryParams.propertyGroupName}
        removeFilter={() => setQueryParams({ propertyGroupIds: [], propertyGroupName: '' })}
        key="property-group"
      />
    );
  }

  if (queryParams.meterType && queryParams.meterType.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="meterType"
        value={queryParams.meterType[0]}
        removeFilter={() => setQueryParams({ meterType: [] as meterType[] })}
        key="meter-type"
      />
    );
  }

  if (
    (queryParams.receivedMeteringDataStart !== MIN_DATE && queryParams.receivedMeteringDataStart !== undefined) ||
    (queryParams.receivedMeteringDataEnd !== MAX_DATE && queryParams.receivedMeteringDataEnd !== undefined)
  ) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.receivedMeteringDataStart, queryParams.receivedMeteringDataEnd]}
        removeFilter={() =>
          setQueryParams({ receivedMeteringDataStart: undefined, receivedMeteringDataEnd: undefined })
        }
        key="last-received-date"
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams,
    quickFilter,
    overviewCount,
    searchValue,
    queryParams,
    filterTags
  };
};

export default useMeterFilter;
