import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';

import { useAppContext } from '../../app-context/app-context';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';
import PropertyGroupDropDownDetails from './PropertyGroupDropDownDetails';

type Props = {
  showDetails?: boolean;
  className?: string;
};

const processAutoFillItem = (p: PropertyGroupType) => {
  return { value: p, text: `${p.name}` };
};

export type PropertyGroupAutofillProps = Props & CustomAutoFillProps<PropertyGroupType>;

export default function PropertyGroupAutoFill(props: PropertyGroupAutofillProps) {
  const { id, selectedValues, initialValue, query, queryField, showDetails = true, onChange, onFocus } = props;
  const { i18n } = useAppContext();

  const autoFillTools = useAutoFill({
    endpoint: '/md/propertyGroups',
    processRecord: processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, shownValues, values } = autoFillTools;

  const group: PropertyGroupType | undefined =
    typeof focusedIndex !== 'undefined' && shownValues[focusedIndex]
      ? shownValues[focusedIndex].value.value
      : undefined;

  const details = group && showDetails ? <PropertyGroupDropDownDetails group={group} /> : undefined;

  return (
    <SearchAutoFill
      {...props}
      id={id}
      details={details}
      values={values}
      selectedValues={selectedValues}
      placeholder={i18n.getTranslation('property_groups.search')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
