import { ICON_COLORS } from 'constants/icons';
import moment from 'moment';
import React, { Fragment } from 'react';

import { utilityType } from '@zf/api-types/enums';
import { ServiceLocationType, ServiceType, ShortContractType } from '@zf/api-types/master-data/servicelocation';
import DropDownDetails from '@zf/stella-react/src/atoms/Dropdown/DropDownDetails';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { betweenDates } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context/app-context';
import { Link } from '../../design-system/Components';
import { getServiceStatus } from '../../utils/serviceState';
import { Icon } from '../Icon';
import IconLabel from '../Icon/IconLabel';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';
import css from './location-autofill.module.scss';

type Props = {
  className?: string;
};

const processAutoFillItem = (location: ServiceLocationType) => {
  return { value: location, text: location.address.localizedDisplay || '' };
};

export default function LocationAutoFill(props: Props & CustomAutoFillProps<ServiceLocationType>) {
  const {
    id,
    selectedValues,
    initialValue,
    query,
    queryField,
    excludedIds,
    onChange,
    onFocus,
    customProcessAutoFillItem
  } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const autoFillTools = useAutoFill({
    endpoint: '/md/servicelocations',
    processRecord: customProcessAutoFillItem || processAutoFillItem,
    initialValue,
    selectedValues,
    excludedIds,
    query,
    queryField
  });

  const { focusedIndex, shownValues, values } = autoFillTools;

  const location: ServiceLocationType | undefined =
    typeof focusedIndex !== 'undefined' && shownValues[focusedIndex]
      ? shownValues[focusedIndex].value.value
      : undefined;

  let activeContracts: ShortContractType[] | undefined;
  let servicesSupplied: ServiceType[] | undefined;

  if (location) {
    servicesSupplied = location.services.filter((service) => {
      const activeStatus = getServiceStatus(service.statusHistory);
      return activeStatus && activeStatus.serviceStatus === 'supplied';
    });

    activeContracts = servicesSupplied.reduce((acc: ShortContractType[], service: ServiceType) => {
      const contract = service.contracts.find(
        (c) =>
          betweenDates(c.supplyStartDateTime, c.supplyEndDateTime, moment()) && c.currentContractStatus === 'signed'
      );

      if (contract && !acc.find((c) => c.contractId === contract.contractId)) {
        acc.push(contract);
      }

      return acc;
    }, []);
  }

  const none = i18n.getTranslation('general.none');

  const details = location && (
    <DropDownDetails>
      <>
        <div>
          <IconLabel labelText={i18n.getTranslation('location.address')} iconType="location" />
          <Link id={`location-${location.id}`} url={`${rootUrl}/locations/${location.id}`} newTab>
            {location.address.localizedDisplay}
          </Link>
        </div>

        {location.propertyGroup && (
          <div>
            <IconLabel labelText={i18n.getTranslation('property_groups.property_group')} iconType="propertygroup" />
            <Link
              id={`property-${location.propertyGroup.id}`}
              url={`${rootUrl}/property-groups/${location.propertyGroup.id}`}
              newTab
            >
              {location.propertyGroup.name}
            </Link>
          </div>
        )}

        {activeContracts && (
          <div>
            <IconLabel labelText={i18n.getTranslation('customer.customer')} iconType="customer" />
            {activeContracts.length > 0 ? (
              activeContracts.map((c, index) => {
                return (
                  <Fragment key={`${c.contractorId}-${c.contractorDisplayName}-${index}`}>
                    <Link id={`customer-${c.contractorId}`} url={`${rootUrl}/customers/${c.contractorId}`} newTab>
                      {c.contractorDisplayName}
                    </Link>
                    <br />
                  </Fragment>
                );
              })
            ) : (
              <Paragraph>-</Paragraph>
            )}
          </div>
        )}

        {activeContracts && (
          <div>
            <IconLabel labelText={i18n.getTranslation('contracts.contracts')} iconType="contract" />
            {activeContracts.length > 0 ? (
              activeContracts.map((c, index2) => {
                return (
                  <Fragment key={`${c.contractorId}-${c.contractNumber}-${index2}`}>
                    <Link id={`contract-${c.contractId}`} url={`${rootUrl}/contracts/${c.contractId}`} newTab>
                      {c.contractNumber}
                    </Link>
                    <br />
                  </Fragment>
                );
              })
            ) : (
              <Paragraph>-</Paragraph>
            )}
          </div>
        )}

        <div>
          <IconLabel
            labelText={i18n.getTranslation('locations_list.labels.supplied_services')}
            iconType="supplied-services"
          />
          <FlexElements>
            {servicesSupplied && servicesSupplied.length > 0
              ? servicesSupplied
                  .reduce((acc: utilityType[], service) => {
                    if (!acc.includes(service.utilityType)) {
                      acc.push(service.utilityType);
                    }

                    return acc;
                  }, [])
                  .map((utilityType_, i) => {
                    return (
                      <Icon
                        id={i.toString()}
                        key={i}
                        title={enumReducer.getTranslation('utilityType', utilityType_)}
                        type={utilityType_}
                        color={ICON_COLORS[utilityType_]}
                        className={css['utlity-type-icon']}
                      />
                    );
                  })
              : none}
          </FlexElements>
        </div>

        <div>
          <IconLabel labelText={i18n.getTranslation('location.external_id')} iconType="tag" />
          <Paragraph>{location?.externalId}</Paragraph>
        </div>
      </>
    </DropDownDetails>
  );

  return (
    <SearchAutoFill
      {...props}
      id={id}
      details={details}
      values={values}
      selectedValues={selectedValues}
      placeholder={i18n.getTranslation('location.search')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
