import clone from 'clone';
import Interweave from 'interweave';
import { observer } from 'mobx-react';
import React, { useImperativeHandle } from 'react';

import { templateUsecase, translationStatus } from '@zf/api-types/enums';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  templateUsecase_: templateUsecase;
};

const RemoveCustomTemplateDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { templateUsecase_ } = props;
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { templatesStore, communicationService } = communicationStore;
  const { selectedScenario, selectedEntitySubjectType, selectedEntitySubjectSubtype, setSelectedScenario } =
    templatesStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (selectedScenario) {
        try {
          await communicationService.removeCustomScenarioTemplate(
            selectedEntitySubjectType,
            selectedEntitySubjectSubtype,
            templateUsecase_
          );

          const newScenario = clone(selectedScenario);

          const overwrites = {
            customTemplateFileName: null,
            customTemplateInternalFilePath: null,
            fileName: '',
            useBuildIn: true,
            translationStatus: translationStatus.defaulttranslation
          };

          if (templateUsecase_ === templateUsecase.pdf) {
            newScenario.pdfTemplate = {
              ...newScenario.pdfTemplate,
              ...overwrites
            };
          } else {
            newScenario.emailTemplate = {
              ...newScenario.emailTemplate,
              ...overwrites
            };
          }

          setSelectedScenario(newScenario);

          notify.success({
            content: getTranslation('communication.remove_custom_template_success')
          });
        } catch (e) {
          notify.error({
            content: getTranslation('communication.remove_custom_template_fail'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={getTranslation('communication.remove_custom_template_descr', {
          fileName:
            (templateUsecase_ === templateUsecase.pdf
              ? selectedScenario?.pdfTemplate.fileName
              : selectedScenario?.emailTemplate.fileName) || ''
        })}
      />
    </Paragraph>
  );
});

export default observer(RemoveCustomTemplateDialog);
