import 'moment/locale/nl';

import clone from 'clone';
import moment, { Moment } from 'moment';
import React from 'react';

import Button from '@zf/heat-erp/src/components/Button/Button';

import CalendarHeader from './calendar-header';
import css from './calendar.module.scss';
import DaysContainer from './days-container';
import MonthContainer from './month-container';
import WeekDaysHeader from './weekdays-header';
import YearContainer from './year-container';

type Props = {
  culture: string;
  selectedDates?: Array<Moment> | null;
  initFocusedDate?: Moment | null;
  initYearRange?: Array<number>;
  range?: boolean;
  onSelect: (selectedDates: Moment[]) => void;
};

function reducer(state: number[], action: number[]) {
  if (Array.isArray(action) && action.length === 2) {
    return action;
  }
  return state;
}

export default function Calendar(props: Props) {
  const { culture, initFocusedDate, selectedDates, range = false, onSelect } = props;

  moment.locale(culture);

  let { initYearRange } = props;
  const today = moment();

  if (!initYearRange) {
    initYearRange = [moment().subtract(7.5, 'years').year(), moment().add(7.5, 'years').year()];
  }

  const [focusedDate, setFocusedDate] = React.useState(initFocusedDate || moment());
  const [activePane, setActivePane] = React.useState(0);
  const [[startYear, endYear], setYearRange] = React.useReducer(reducer, initYearRange);

  const updateMonth = (amount: number) => {
    const focusedDateClone = moment(focusedDate);
    focusedDateClone.add(amount, 'months');
    setFocusedDate(focusedDateClone);
  };

  const onNext = () => {
    if (activePane === 2) {
      setYearRange([startYear + 15, endYear + 15]);
    } else {
      updateMonth(1);
    }
  };

  const onPrevious = () => {
    if (activePane === 2) {
      setYearRange([startYear - 15, endYear - 15]);
    } else {
      updateMonth(-1);
    }
  };

  const scrollCalendar = (event: React.WheelEvent) => {
    if (event.deltaY > 0) {
      onNext();
    } else if (event.deltaY) {
      onPrevious();
    }
  };

  const selectDay = (day: number) => {
    const selectedDate = clone(focusedDate);

    selectedDate.date(day);

    let dateExists = -1;

    let selectedDatesClone: Moment[] = [];
    if (selectedDates) {
      selectedDatesClone = [...selectedDates];
    }

    if (!range) {
      if (selectedDates) {
        for (const date_ of selectedDates) {
          const date = clone(date_);

          if (date && date.format('MM-DD-YYYY') === selectedDate.format('MM-DD-YYYY')) {
            break;
          }
        }
      }

      return onSelect(selectedDate ? [selectedDate] : []);
    }

    if (selectedDatesClone) {
      for (let i = 0; i < selectedDatesClone.length; i++) {
        const date = selectedDatesClone[i];

        if (date && date.format('MM-DD-YYYY') === selectedDate.format('MM-DD-YYYY')) {
          dateExists = i;
          break;
        }
      }
    }

    if (selectedDates) {
      if (selectedDates[0] === undefined && selectedDates[1] === undefined) {
        onSelect([selectedDate, selectedDate]);
      } else if (selectedDates[0] === undefined && selectedDates[1]) {
        selectedDates[0] = selectedDate;
        return onSelect(selectedDates);
      } else if (selectedDates[0] && selectedDates[1] === undefined) {
        selectedDates[1] = selectedDate;
        return onSelect(selectedDates);
      }
    }

    if (dateExists > -1 && selectedDatesClone && selectedDatesClone[0] && selectedDatesClone[1]) {
      if (selectedDatesClone[0].valueOf() === selectedDatesClone[1].valueOf()) {
        selectedDatesClone.splice(dateExists, 1);
      }
      selectedDatesClone.splice(dateExists, 1);
    } else {
      selectedDatesClone = [];

      if (selectedDates) {
        if (selectedDates.length === 0) {
          selectedDatesClone = [selectedDate];
        } else if (selectedDates.length === 1) {
          if (selectedDates[0].valueOf() < selectedDate.valueOf()) {
            selectedDatesClone = [selectedDates[0], selectedDate];
          } else {
            selectedDatesClone = [selectedDate, selectedDates[0]];
          }
        } else if (selectedDates.length === 2 && selectedDates[0]) {
          if (selectedDate.valueOf() - selectedDates[0].valueOf() < 0) {
            selectedDatesClone = [selectedDate, selectedDates[1]];
          } else {
            selectedDatesClone = [selectedDates[0], selectedDate];
          }
        }
      }
    }

    onSelect(selectedDatesClone);
  };

  const onClickMonth = () => {
    setActivePane(1);
  };

  const onSelectMonth = (month: number) => {
    const focusedDateClone = moment(focusedDate);
    focusedDateClone.month(month);
    setFocusedDate(focusedDateClone);
    setActivePane(0);
  };

  const onClickYear = () => {
    setActivePane(2);
  };

  const onSelectYear = (year: number) => {
    const focusedDateClone = moment(focusedDate);
    focusedDateClone.year(year);
    setFocusedDate(focusedDateClone);
    setActivePane(1);
  };

  let title = (
    <div className={css['title']}>
      <Button id="show_months" type="text" onClick={onClickMonth}>
        {focusedDate.format('MMMM')}
      </Button>
      <Button id="show_years" type="text" onClick={onClickYear}>
        {focusedDate.format('YYYY')}
      </Button>
    </div>
  );

  let selectedPane = null;
  switch (activePane) {
    case 0: {
      let todayHighlight: number | undefined;
      if (today.year() === focusedDate.year() && today.month() === focusedDate.month()) {
        todayHighlight = today.date();
      }

      const selectedDays: number[] = [];
      if (selectedDates) {
        for (const date of selectedDates) {
          if (date && date.year() === focusedDate.year() && date.month() === focusedDate.month()) {
            selectedDays.push(date.date());
          }
        }
      }

      const rangeDays = [0, 0];

      if (range && selectedDates && selectedDates.length === 2 && selectedDates[0] && selectedDates[1]) {
        const selectedStart = parseInt(`${selectedDates[0].year()}${selectedDates[0].format('MM')}`);
        const selectedEnd = parseInt(`${selectedDates[1].year()}${selectedDates[1].format('MM')}`);
        const focused = parseInt(`${focusedDate.year()}${focusedDate.format('MM')}`);

        rangeDays[0] = selectedStart === focused ? selectedDates[0].date() : 0;
        if (selectedEnd === focused) {
          rangeDays[1] = selectedDates[1].date();
        } else {
          if (selectedStart <= focused && focused < selectedEnd) {
            rangeDays[1] = focusedDate.endOf('month').date() + 1;
          } else {
            rangeDays[1] = 0;
          }
        }
      }

      selectedPane = (
        <>
          <WeekDaysHeader />
          <DaysContainer
            selectedDays={selectedDays}
            today={todayHighlight}
            focusedDate={focusedDate}
            onSelect={selectDay}
            startRange={rangeDays[0]}
            stopRange={rangeDays[1]}
          />
        </>
      );
      break;
    }
    case 1:
      selectedPane = <MonthContainer onSelect={onSelectMonth} />;
      break;
    case 2:
      title = <div className={css['title']}>{`${startYear} - ${endYear}`}</div>;
      selectedPane = <YearContainer onSelect={onSelectYear} startYear={startYear} endYear={endYear} />;
      break;
  }

  return (
    <div className={css['calendar']} onWheel={scrollCalendar}>
      <CalendarHeader onPrevious={onPrevious} onNext={onNext}>
        {title}
      </CalendarHeader>
      {selectedPane}
    </div>
  );
}
