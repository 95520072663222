import React from 'react';

import { useAppContext } from '../../../app-context/app-context';
import { notify } from '../../../events/notification-events';
import { getExample } from '../import-job';

export default function useExampleDownload() {
  const { i18n, tenantReducer } = useAppContext();
  const [isLoading, setIsloading] = React.useState(false);

  const downloadExample = async (fileFormatId?: string) => {
    try {
      if (fileFormatId) {
        setIsloading(true);
        await getExample(fileFormatId, tenantReducer, i18n.lang);
        setIsloading(false);
      }
    } catch (e) {
      setIsloading(false);
      notify.error({
        content: i18n.getTranslation('file_format.download_failed'),
        error: e
      });
    }
  };

  return {
    isLoading,
    downloadExample
  };
}
