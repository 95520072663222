import clone from 'clone';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { AddMeasurementRequestType, MeasurementType, MeterRowType } from '@zf/api-types/master-data/meter';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { isMinDate, MIN_DATE, startOfDay } from '@zf/utils/src/date';

import DatePicker from '../../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import css from '../../style.module.scss';
import AddMeasurementMultiValue from './add-measurement-multivalue';

type Props = {
  useCase?: 'add' | 'edit';
  locationId?: string;
  selectedRows?: Array<MeterRowType>;
  meterToHandle?: AddMeasurementRequestType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  singleMeasurement?: boolean;
  measurement?: MeasurementType | undefined;
  onComplete?: () => void;
};

const MeasurementDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const {
    selectedRows,
    meterToHandle,
    locationId,
    validationRef,
    useCase = 'add',
    singleMeasurement = false,
    measurement,
    onComplete
  } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addMeasurementMeter } = meterStore.measurementService;

  const [mutationDateTime, setMutationDateTime] = useState(
    meterToHandle && meterToHandle.endDateTime ? startOfDay(moment(meterToHandle.endDateTime)) : moment(MIN_DATE)
  );

  let initialValues: AddMeasurementRequestType[] = [];

  if (selectedRows) {
    initialValues = selectedRows.map((row) => {
      return {
        meter: row.__meterEntity,
        endDateTime: mutationDateTime.toISOString(),
        value: undefined,
        channelId: '',
        hour: '0',
        minute: '0'
      };
    });
  } else if (meterToHandle) {
    initialValues = [meterToHandle];
  }

  const [measurements, setMeasurements] = useState(initialValues);
  const [isValidating, setIsValidating] = useState(false);

  const checkMeasurementValues = () => {
    const validMeasurements = measurements.filter((m) => (m.value || m.value === 0) && m.channelId);
    return validMeasurements.length === measurements.length;
  };

  const validate = () => {
    if (validationRef && validationRef.current) {
      validationRef.current.setIsError(!checkMeasurementValues() || isMinDate(mutationDateTime) || isValidating);
    }
  };

  const closeDialog = () => validationRef.current?.onCancel();

  useEffect(() => {
    validate();
  }, [mutationDateTime, measurements, isValidating]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (measurements && measurements.length > 0) {
        try {
          const errors = [];
          await Promise.all(
            measurements.map(async (measurement) => {
              measurement.endDateTime = clone(mutationDateTime).toISOString();
              return addMeasurementMeter(measurement).catch((error) => {
                errors.push(error);
                notify.error({
                  content: getTranslation('actions.meter.add_measurement_error'),
                  error
                });
              });
            })
          );

          if (onComplete) onComplete();

          if (errors.length === 0) {
            notify.success({
              content:
                measurements.length > 1
                  ? getTranslation(`actions.meter.${useCase}_measurements_success`)
                  : getTranslation(`actions.meter.${useCase}_measurement_success`)
            });
          }
        } catch (error) {
          notify.error({
            content: getTranslation(`actions.meter.${useCase}_measurement_error`),
            error
          });
        }
      }
    }
  }));

  return (
    <div className={css['multivalue-wrapper']}>
      <Paragraph>{getTranslation('actions.meter.add_measurement_date')}</Paragraph>
      <div className={css['margin-top']}>
        <DatePicker
          id="mutation-date-time"
          onChange={(val) => setMutationDateTime(val)}
          value={mutationDateTime}
          disabled={useCase === 'edit'}
          placeholder={getTranslation('actions.meter.measurement_date')}
          error={isMinDate(mutationDateTime)}
        />
      </div>

      <div className={css['margin-top']}>
        <AddMeasurementMultiValue
          titleText={getTranslation('actions.meter.add_measurement')}
          measurements={initialValues}
          mutationDateTime={mutationDateTime}
          locationId={locationId}
          singleMeasurement={singleMeasurement}
          isValidating={isValidating}
          setMeasurements={setMeasurements}
          useCase={useCase}
          setIsValidating={setIsValidating}
          closeDialog={closeDialog}
          measurement={measurement ? measurement : undefined}
        />
      </div>
    </div>
  );
});

export default observer(MeasurementDialog);
