import moment, { Moment } from 'moment';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';

import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import DateInput from './date-input';
import DatePickerTooltip from './DatePickerTooltip';

export type StellaDatePickerProps = {
  culture: string;
  warnings: string[];
  clearText?: string;
  value: Moment | null;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  hideLabel?: boolean;
  error?: boolean;
  singleError?: string;
  errors?: {};
  iconRight?: ReactNode;
  className?: string;
  clear?: boolean;
  maxDate?: boolean;
  darkMode?: boolean;
  startOfDay_?: boolean;
  renderTopLvl?: boolean;
  warnUser: (content: ReactNode) => void;
  onFocus?: (step: string) => void;
  onClick?: () => void;
  onBlur?: () => void;
  setClearCalendar?: Dispatch<SetStateAction<boolean>>;
  onChange: (value: Moment) => void;
};

export default function StellaDatePicker(props: StellaDatePickerProps) {
  const {
    onChange,
    culture,
    disabled = false,
    clear = true,
    maxDate = false,
    clearText = 'Clear',
    renderTopLvl = true,
    className
  } = props;
  let { value } = props;

  value?.locale(culture)

  if (
    value &&
    (value.format('DD/MM/YYYY') === '01/01/0001' ||
      value.format('DD/MM/YYYY') === '31/12/9999' ||
      value.format('DD/MM/YYYY') === '01/01/10000')
  ) {
    value = null;
  }

  const handleClear = useCallback(() => {
    onChange(moment(maxDate ? MAX_DATE : MIN_DATE));
  }, [onChange, maxDate]);

  return (
    <DateInput
      {...props}
      value={value}
      handleClear={handleClear}
      className={className}
      iconRight={
        <DatePickerTooltip
          culture={culture}
          value={value}
          clear={clear}
          clearText={clearText}
          disabled={disabled}
          renderTopLvl={renderTopLvl}
          handleClear={handleClear}
          onChange={onChange}
        />
      }
    />
  );
}
