import React from 'react';

import { CollectionCaseStepType, CollectionCaseType } from '@zf/api-types/collection-case';
import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import GenericDialog from '../../units/Dialog/GenericDialog';

type Props = {
  collectionCase: CollectionCaseType;
  step: CollectionCaseStepType;
  refresh: () => void;
};

export default function ExecuteStepListItem(props: Props) {
  const { collectionCase, step, refresh } = props;
  const { applicationStore, collectionCaseStore } = useStore();
  const { getTranslation } = applicationStore;
  const { executeCollectionStep } = collectionCaseStore.collectionCaseService;
  const { clickRef, onSubmit } = useDialog();

  const submitFunction = async () => {
    await executeCollectionStep(collectionCase.id, step.id);
    refresh();
  };

  return (
    <DropdownAction
      id="collection_case.execute_step"
      icon="check"
      dialogTitle={getTranslation('actions.collection_case.execute_step')}
      dialogContent={
        <GenericDialog
          ref={clickRef}
          info={getTranslation('actions.collection_case.execute_step_paragraph', { stepName: step.name })}
          successMsg={getTranslation('actions.collection_case.execute_step_success', { stepName: step.name })}
          errorMsg={getTranslation('actions.collection_case.execute_step_fail', { stepName: step.name })}
          submitFunction={submitFunction}
        />
      }
      onSubmit={onSubmit}
      buttonPositive={getTranslation('actions.collection_case.execute_step')}
    />
  );
}
