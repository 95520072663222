import React from 'react';

import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../app-context/app-context';
import CustomerAutoFill from '../../../components/Autofills/CustomerAutoFill';
import PropertyGroupAutoFill from '../../../components/Autofills/PropertyGroupAutoFill';
import ProductDropdown from '../../../components/Dropdown/product-dropdown/ProductDropdown';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import { METHODS, sendRequest } from '../../../utils/request';
import { CreateLocationType, STEP_NAMES } from './LocationWizard';

type Props = {
  values: CreateLocationType;
  setValue: (value: Partial<CreateLocationType>, initialApiSet?: boolean, backup_?: boolean) => void;
  handleFocus: (step: string) => () => void;
};

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);

export default function DefaultConfigSection(props: Props) {
  const { values, setValue, handleFocus } = props;

  const { i18n, tenantReducer } = useAppContext();

  const getPropertyGroupBillingConfig = (propertygroupId: string) => {
    return sendRequest<PropertyGroupBillingConfigurationType>({
      request: {
        method: METHODS.GET,
        endpoint: `/bill/PropertyGroupBillingConfiguration/${propertygroupId}`
      },
      tenantReducer,
      lang: i18n.lang
    });
  };

  // Inherit product from property by default
  React.useEffect(() => {
    if (values.propertyGroup) {
      getPropertyGroupBillingConfig(values.propertyGroup.id).then((billingConfig) => {
        setValue({
          productId: billingConfig.data.productId || '',
          defaultProductId: billingConfig.data.productId || ''
        });
      });
    }
  }, [values.propertyGroup]);

  return (
    <WizardSection disabled={!!values.location}>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[1]} />
        {i18n.getTranslation('location.steps.default_config')}
      </WizardHeader>

      <Heading headingType="h3" style="bold" coachMark={i18n.getTranslation('location.external_id_info')}>
        {i18n.getTranslation('location.heading_external_id')}
      </Heading>
      <InputContainer>
        <WizardInputField
          id="location-external-id"
          onChange={(value) => setValue({ externalId: value })}
          value={values.externalId}
          placeholder={i18n.getTranslation('location.external_id')}
          onFocus={handleFocus(STEP_NAMES[1])}
        />
      </InputContainer>

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('location.property_title')}
      </Heading>
      <InputContainer>
        <PropertyGroupAutoFill
          id="propertygroup"
          onChange={(value) => setValue({ propertyGroup: value[0] })}
          selectedValues={[values.propertyGroup ? values.propertyGroup.id : '']}
          placeholder={i18n.getTranslation('location.search_property_group')}
          onFocus={handleFocus(STEP_NAMES[1])}
        />
      </InputContainer>

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('location.select_product_title')}
      </Heading>
      <InputContainer>
        <ProductDropdown
          id="product"
          onChange={(val) => setValue({ productId: val[0] ? val[0].id : '' })}
          selectedValue={values.productId}
          resetValue={values.propertyGroup ? values.defaultProductId : undefined}
          reset={() => setValue({ productId: values.defaultProductId })}
        />
      </InputContainer>

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('location.managers_title')}
      </Heading>
      <InputContainer>
        <CustomerAutoFill
          id="owner"
          onChange={(value) => setValue({ ownerId: value[0] ? value[0].id : '' })}
          selectedValues={[values.ownerId]}
          placeholder={i18n.getTranslation('location.search_owner')}
          onFocus={handleFocus(STEP_NAMES[1])}
        />
        <CustomerAutoFill
          id="manager"
          onChange={(value) => setValue({ managerId: value[0] ? value[0].id : '' })}
          selectedValues={[values.managerId]}
          placeholder={i18n.getTranslation('location.search_manager')}
          onFocus={handleFocus(STEP_NAMES[1])}
        />
      </InputContainer>
    </WizardSection>
  );
}
