import React from 'react';

import { ButtonProps } from '../../design-system/Components/Button/Button';
import { useStore } from '../../hooks/useStore';
import Button from '../Button/Button';
import css from './more-actions-icon.module.scss';

type Props = ButtonProps & {
  dotsVertical?: boolean;
};

export default function MoreActionsIcon(props: Props) {
  const { dotsVertical = false } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Button
      {...props}
      type="text"
      size="small"
      className={css['more-actions-icon']}
      icon={dotsVertical ? 'ellipsis-v' : 'ellipsis-h'}
      tooltipText={getTranslation('actions.more_actions')}
    />
  );
}
