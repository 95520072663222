import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { UnbilledProvider } from '../../../../../../cards/Unbilled/context/unbilled-context';
import UnbilledListCard from '../../../../../../cards/Unbilled/unbilled-list-card';
import { ErrorBoundary } from '../../../../../../components/Error';
import { SuspenseSpinner } from '../../../../../../components/suspense';
import useBillingData from '../../hooks/use-billing-data';
import IncomingOutgoingCharts from './incoming-outgoing-charts';
import css from './dashboard.module.scss';
import InvoicesMissingChartCardWrapper from './invoices-missing-chart-card-wrapper';
import InvoicesCreatedChartCardWrapper from './invoices-created-chart-card-wrapper';

type Props = {
  propertyGroup: PropertyGroupType;
};

export default function Dashboard(props: Props) {
  const { propertyGroup } = props;

  const { propertyGroupBillingConfiguration } = useBillingData();

  return (
    <CardsContainer className={css['dashboard-container']}>
      <ErrorBoundary cardError>
        <SuspenseSpinner>
          <InvoicesCreatedChartCardWrapper propertyGroupId={propertyGroup.id} />
        </SuspenseSpinner>
      </ErrorBoundary>
      <ErrorBoundary cardError>
        <SuspenseSpinner>
          <InvoicesMissingChartCardWrapper propertyGroupId={propertyGroup.id} />
        </SuspenseSpinner>
      </ErrorBoundary>
      {propertyGroupBillingConfiguration && propertyGroupBillingConfiguration.costAllocationEnabled && (
        <IncomingOutgoingCharts propertyGroupId={propertyGroup.id} />
      )}

      <UnbilledProvider>
        <UnbilledListCard useCase="propertyGroup" propertyGroupId={propertyGroup.id} />
      </UnbilledProvider>
    </CardsContainer>
  );
}
