import classNames from 'classnames';
import React from 'react';

import css from './flag.module.scss';

export type FlagSize = 'small' | 'medium';

type Props = {
  url: string;
  size?: FlagSize;
};

export default function Flag(props: Props) {
  const { url, size = 'medium' } = props;

  return <img className={classNames(css['flag'], css[size])} src={url} />;
}
