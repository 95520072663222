import React from 'react';
import { Tooltip } from 'recharts';

import css from './zf-tooltip.module.scss';

export class ZFGraphTooltip extends Tooltip<(string | number)[], string> {
  render() {
    const el = super.render();

    return React.cloneElement(el, { className: css['tooltip'] });
  }
}
