import useListPageContext from 'app-context/hooks/use-listpage-context';
import EmptyLink from 'components/Link/EmptyLink';
import { Link } from 'design-system/Components';
import React from 'react';

import {
  MeasurementMeteringIssueDetails,
  MeteringIssue,
  MeteringIssueGrouped,
  MeteringIssueGroupedRowType,
  MeteringIssueRowType
} from '@zf/api-types/metering/metering-issues';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDate } from '@zf/utils/src/date';
import { formatDecimal } from '@zf/utils/src/number';

import { ICON_COLORS } from '../../../../constants/icons';
import { useStore } from '../../../../hooks/useStore';
import { IssuesContext } from '../Context/Context';
import css from './meter-issues-list-page.module.scss';

const useMeterIssuesListPage = (isGrouped: boolean) => {
  const { applicationStore } = useStore();
  const { rootUrl, getEnumTranslation, getTranslation, culture } = applicationStore;

  const processRecord = (issue: MeteringIssue): MeteringIssueRowType => {
    const details = issue.details as MeasurementMeteringIssueDetails;

    return {
      __id: issue.id,
      __entity: issue,
      issue: issue.meter && (
        <>
          <div className={css['issue-title']}>{getEnumTranslation('meteringIssueError', issue.error)}</div>
          <div className={css['issue-subtitle']}>
            {`${getTranslation('meter.meter')} `}
            <Link id={`link-${issue.meter.meterId}`} url={`${rootUrl}/devices/meters/${issue.meter.meterId}/issues`}>
              {issue.meter.serialNumber}
            </Link>
          </div>
        </>
      ),
      status: (
        <NewStatusBadge
          color={ICON_COLORS[issue.status]}
          status_={getEnumTranslation('meteringIssueStatus', issue.status)}
        />
      ),
      noticedDateTime: issue.noticedDateTime
        ? formatDate(issue.noticedDateTime)
        : getTranslation('metering_list.never'),
      detail:
        typeof details.value !== 'undefined' &&
        typeof details.unitOfMeasure !== 'undefined' &&
        `${formatDecimal(details.value, culture)} ${getEnumTranslation('unitOfMeasure', details.unitOfMeasure)}`
    };
  };

  const processRecordGrouped = (issue: MeteringIssueGrouped): MeteringIssueGroupedRowType => {
    return {
      __id: String(issue.meter.meterId),
      __entity: issue,
      meter: (
        <EmptyLink id={`${issue.id}-${issue.meter.meterId}`} url={`${rootUrl}/devices/meters/${issue.meter.meterId}`}>
          {issue.meter.serialNumber}
        </EmptyLink>
      ),
      itemCount: issue.issueCount,
      firstIssueDate: formatDate(issue.firstIssueDate),
      lastIssueDate: formatDate(issue.lastIssueDate)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPageContext<any, MeteringIssue & MeteringIssueGrouped, MeteringIssueRowType | MeteringIssueGroupedRowType>(
    {
      context: IssuesContext,
      endpoint: isGrouped ? '/me/meteringissues/grouped' : '/me/meteringissues',
      processRecord: isGrouped ? processRecordGrouped : processRecord,
      defaultQueryParams: { groupBy: isGrouped ? 'meter' : 'none' }
    }
  );

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useMeterIssuesListPage;
