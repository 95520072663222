import React from 'react';
import ZFButton, { ButtonProps } from '../../design-system/Components/Button/Button';

import AccessControl from '../Permissions/AccessControl';

export default function Button(props: ButtonProps) {
  const { id } = props;

  return (
    <AccessControl permissionsKey={id}>
      <ZFButton {...props} />
    </AccessControl>
  );
}
