import React from 'react';

import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import AccessControl from '../Permissions/AccessControl';
import StellaNumberInput, { NumberInputProps } from '@zf/stella-react/src/atoms/InputField/NumberInput';

export default function NumberInput(props: NumberInputProps) {
  const { id, value } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{value}</Paragraph>}>
      <StellaNumberInput {...props} />
    </AccessControl>
  );
}

export const InlineNumberInput = InlineInputField<NumberInputProps>(NumberInput);
