import { useEffect } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ParameterTypesType } from '@zf/api-types/general';
import { IncomingInvoicePagedResponseType } from '@zf/api-types/incoming-invoice';
import { IncomingInvoiceComponentPagedResponseType } from '@zf/api-types/incoming-invoice-component';
import { BillingItemPagedResultType, ConsumptionUnitType } from '@zf/api-types/product';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { PropertyGroupBillingPeriodPagedResponseType } from '@zf/api-types/property-group-billing-period';
import { PropertyMeteringConfigurationType } from '@zf/api-types/property-metering-configuration';
import { GetTaxCodeResponse } from '@zf/api-types/tax-codes';

import useSingleAPI from '../../../../../hooks/useSingleAPI';
import { useTracked } from '../billing-context/context';

export default function useFetchBillingData(propertyGroupId: string) {
  const [state, dispatch] = useTracked();

  const billingConfigResponse = useSingleAPI<PropertyGroupBillingConfigurationType>({
    request: {
      endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroupId}`
    }
  });

  const meteringConfigResponse = useSingleAPI<PropertyMeteringConfigurationType>({
    request: {
      endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyGroupId}`
    }
  });

  const billingItemsResponse = useSingleAPI<BillingItemPagedResultType>({
    request: {
      endpoint: '/cfg/BillingItems'
    }
  });

  const billingPeriodsResponse = useSingleAPI<PropertyGroupBillingPeriodPagedResponseType>({
    request: {
      endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}`
    }
  });

  const calculationTypesResponse = useSingleAPI<ParameterTypesType[]>({
    request: {
      endpoint: '/cfg/BillingItems/calculationtypes'
    }
  });

  const taxCodesResponse = useSingleAPI<GetTaxCodeResponse>({
    request: {
      endpoint: '/cfg/TaxCodes'
    }
  });

  const consumptionUnitTypesResponse = useSingleAPI<PagedResponseType<ConsumptionUnitType>>({
    request: {
      endpoint: '/cfg/ConsumptionUnitTypes'
    }
  });

  const customEntityPropertyTypesResponse = useSingleAPI<PagedResponseType<CustomEntityPropertyType>>({
    request: {
      endpoint: '/cfg/CustomEntityPropertyTypes'
    }
  });

  const incomingInvoicesResponse = useSingleAPI<IncomingInvoicePagedResponseType>({
    request: {
      endpoint: '/bill/IncomingInvoices',
      query: {
        propertyGroupId: propertyGroupId
      }
    }
  });

  const componentsResponse = useSingleAPI<IncomingInvoiceComponentPagedResponseType>({
    request: {
      endpoint: '/cfg/IncomingInvoiceComponents'
    }
  });

  useEffect(() => {
    if (billingConfigResponse.result && !(billingConfigResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_PROPERTY_BILLING_CONFIG', config: billingConfigResponse.result.data, isFetch: true });
    }
  }, [billingConfigResponse.result]);

  useEffect(() => {
    if (meteringConfigResponse.result && !(meteringConfigResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_PROPERTY_METERING_CONFIG', config: meteringConfigResponse.result.data, isFetch: true });
    }
  }, [meteringConfigResponse.result]);

  useEffect(() => {
    if (billingItemsResponse.result && !(billingItemsResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_BILLING_ITEMS', billingItems: billingItemsResponse.result.data.results });
    }
  }, [billingItemsResponse.result]);

  useEffect(() => {
    if (billingPeriodsResponse.result && !(billingPeriodsResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_BILL_PERIODS', billingPeriods: billingPeriodsResponse.result.data.results, isFetch: true });
    }
  }, [billingPeriodsResponse.result]);

  useEffect(() => {
    if (taxCodesResponse.result && !(taxCodesResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_TAX_CODES', taxCodes: taxCodesResponse.result.data.results });
    }
  }, [taxCodesResponse.result]);

  useEffect(() => {
    if (consumptionUnitTypesResponse.result && !(consumptionUnitTypesResponse.result instanceof Promise)) {
      dispatch({
        type: 'SET_CONSUMPTION_UNIT_TYPES',
        consumptionUnitTypes: consumptionUnitTypesResponse.result.data.results
      });
    }
  }, [consumptionUnitTypesResponse.result]);

  useEffect(() => {
    if (customEntityPropertyTypesResponse.result && !(customEntityPropertyTypesResponse.result instanceof Promise)) {
      dispatch({
        type: 'SET_CUSTOM_ENTITY_PROPERTY_TYPES',
        customEntityPropertyTypes: customEntityPropertyTypesResponse.result.data.results
      });
    }
  }, [customEntityPropertyTypesResponse.result]);

  useEffect(() => {
    if (calculationTypesResponse.result && !(calculationTypesResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_CALC_TYPES', calculationTypes: calculationTypesResponse.result.data });
    }
  }, [calculationTypesResponse.result]);

  useEffect(() => {
    if (incomingInvoicesResponse.result && !(incomingInvoicesResponse.result instanceof Promise)) {
      const results = incomingInvoicesResponse.result.data.results;
      dispatch({ type: 'SET_INC_INVOICES', incomingInvoices: results, isFetch: true });
      // Select by default the first one
      dispatch({ type: 'SET_SELECTED_INC_INVOICE', id: results[0] ? results[0].id : '' });
    }
  }, [incomingInvoicesResponse.result]);

  useEffect(() => {
    if (componentsResponse.result && !(componentsResponse.result instanceof Promise)) {
      dispatch({ type: 'SET_INC_INV_COMPONENTS', components: componentsResponse.result.data.results, isFetch: true });
    }
  }, [componentsResponse.result]);

  return {
    isLoading: state.didFetch.length < 10
  };
}
