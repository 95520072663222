import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import { useStore } from '../../hooks/useStore';
import CommunicationConfigHeader from './CommunicationConfigHeader';
import CommunicationConfigTabContainer from './CommunicationConfigTabContainer';

type Props = {
  location: Location;
  navigate: (href: string) => void;
};

const CommunicationConfig = observer((props: Props) => {
  const { communicationStore } = useStore();
  const { communicationParametersForm_, initCommunicationParameters } = communicationStore.settingsStore;

  useEffect(() => {
    initCommunicationParameters();
  }, []);

  return (
    <AsyncVarSpinner variables={[communicationParametersForm_]}>
      <CommunicationConfigHeader />
      <CommunicationConfigTabContainer {...props} />
    </AsyncVarSpinner>
  );
});

export default CommunicationConfig;
