import React, { useMemo } from 'react';

import { colors, convertHexToRGBA } from '@zf/utils/src/color';

import css from './new-status-badge.module.scss';

type Props = {
  status_: string;
  color?: string;
  type?: 'bare' | 'normal' | 'numeric' | 'dot';
};

export default function NewStatusBadge(props: Props) {
  const { status_, color = colors['blue-600'], type = 'normal' } = props;
  const bgColor = convertHexToRGBA(color, 20);


  const style: React.CSSProperties = useMemo(() => {
    if (type === 'bare') {
      return { color: `${color}`, padding: '0rem' };
    }
    else if (type === 'numeric'){
      return { backgroundColor: bgColor, color: `${color}`, padding:'0.2rem 1rem' }
    }

   
    return { backgroundColor: bgColor, color: `${color}` };
  }, [color, type]);

  return (
    <div className={css['badge']} style={style}>
      {status_}
    </div>
  );
}
