import moment, { Moment } from 'moment';
import React from 'react';
import { Line } from 'recharts';

import { aggregationFrequency } from '@zf/api-types/enums';
import { CalculatedConsumptionsType } from '@zf/api-types/property-metering-configuration';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Card } from '@zf/stella-react/src/atoms/Card';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import SingleBarChart from '@zf/stella-react/src/atoms/Chart/SingleBarChart';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import ZFLineChart from '@zf/stella-react/src/atoms/Chart/ZFLineChart';

import CardEmptyBody from '@zf/stella-react/src/atoms/Card/CardEmptyBody';
import { useAppContext } from '../../../../../../app-context/app-context';
import DateRangePicker from '../../../../../../components/input/DateRangePicker';
import Loader from '../../../../../../components/Lang/Loader';
import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import useSingleAPI from '../../../../../../hooks/useSingleAPI';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import { formatXAxisDateShort } from '../../../../../../utils/graph';
import { roundNumber } from '@zf/utils/src/number';
import useMeteringData from '../../hooks/use-metering-data';
import css from './calculation-output.module.scss';

import { colors } from '@zf/utils/src/color';

type Props = {
  propertyGroupId: string;
};

type State = {
  groupByPeriod: aggregationFrequency;
  startDateTime: Moment | null;
  endDateTime: Moment | null;
};

function EmptyGraphLoader() {
  const { i18n } = useAppContext();
  return (
    <Card id="loading-graph" className={css['loading-card']}>
      <CardEmptyBody>
        <Loader text={i18n.getTranslation('graph.loading')} />
      </CardEmptyBody>
    </Card>
  );
}

export default function OutputGraph(props: Props) {
  const { propertyGroupId } = props;
  const { i18n, enumReducer } = useAppContext();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    groupByPeriod: aggregationFrequency.monthly,
    startDateTime: null,
    endDateTime: null
  });

  const { selectedOutputChannel, propertyMeteringConfiguration } = useMeteringData();

  const response = useSingleAPI<CalculatedConsumptionsType[]>({
    request: {
      endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyGroupId}/calculatedconsumptions`,
      query: {
        groupByPeriod: values.groupByPeriod,
        outputChannelIds: selectedOutputChannel ? [selectedOutputChannel.id] : [],
        startDateTime: values.startDateTime ? values.startDateTime.toISOString() : MIN_DATE,
        endDateTime: values.endDateTime ? values.endDateTime.toISOString() : MAX_DATE
      }
    }
  });

  if (!response.result || response.result instanceof Promise) return <EmptyGraphLoader />;

  const consumptions = response.result.data;

  let chartData: ChartDataType[] = [];

  if (selectedOutputChannel) {
    const selectedConsumptions = consumptions.find((c) => {
      return c.outputChannelId === selectedOutputChannel.id;
    });

    if (selectedConsumptions) {
      selectedConsumptions.consumptions.sort(
        (a, b) => moment(a.endDateTime).valueOf() - moment(b.endDateTime).valueOf()
      );

      chartData = selectedConsumptions.consumptions.map((c) => {
        return {
          XLabel: formatXAxisDateShort(values.groupByPeriod, c.endDateTime),
          value: c.value < 0.01 ? c.value : roundNumber(c.value),
          color: colors['blue-400']
        };
      });
    }
  }

  const renderEmptyState = () => {
    if (selectedOutputChannel) {
      if (propertyMeteringConfiguration && propertyMeteringConfiguration.outputChannels.length > 0) {
        if (consumptions.length > 0) {
          return (
            <div className={css['loading-wrapper']}>
              <Loader className={css['loading']} text={i18n.getTranslation('graph.loading')} />
            </div>
          );
        } else {
          return <EmptyChartState title={i18n.getTranslation('property_groups.tabs.metering.no_consumptions_yet')} />;
        }
      }
    } else {
      return <EmptyChartState title={i18n.getTranslation('property_groups.tabs.metering.empty_state_select_chann')} />;
    }
  };

  let interval = 1;

  if (values.groupByPeriod === aggregationFrequency.none) {
    interval = chartData.length / 60;
  }

  return (
    <BarChartCard
      className={css['calculation-output-card']}
      height="65vh"
      subject="calculation-output"
      title={
        selectedOutputChannel
          ? `${selectedOutputChannel.name} (${enumReducer.getTranslation(
              'utilityType',
              selectedOutputChannel.utilityType
            )})`
          : ''
      }
      extraRight={
        <div className={css['filters']}>
          <DateRangePicker
            id="output-period"
            className={css['range-picker']}
            startDate={values.startDateTime}
            endDate={values.endDateTime}
            setDates={(dates) => setValue({ startDateTime: dates[0], endDateTime: dates[1] })}
          />
          <SimpleDropdown
            id="output-groupBy"
            onChange={(val) => setValue({ groupByPeriod: val[0] })}
            values={enumReducer.getEnum<aggregationFrequency>('aggregationFrequency')}
            selectedValues={[values.groupByPeriod]}
          />
        </div>
      }
    >
      {chartData.length > 0 ? (
        values.groupByPeriod !== aggregationFrequency.none ? (
          <SingleBarChart
            subject="calculation-output"
            margins={{ top: 10, left: 20 }}
            chartData={chartData}
            XdataKey="XLabel"
            YdataKey="value"
            interval={values.groupByPeriod === aggregationFrequency.daily ? 60 : 'preserveStartEnd'}
            unit={
              selectedOutputChannel
                ? enumReducer.getTranslation('unitOfMeasure', selectedOutputChannel.unitOfMeasure)
                : ''
            }
            height="60vh"
            locale={i18n.culture}
          />
        ) : (
          <ZFLineChart
            subject="calculation-output"
            chartData={chartData}
            XdataKey="XLabel"
            lines={[
              <Line
                key="calculation-output"
                type="monotone"
                dataKey="value"
                stroke={colors['blue-400']}
                fill={colors['blue-400']}
                unit={
                  selectedOutputChannel
                    ? enumReducer.getTranslation('unitOfMeasure', selectedOutputChannel.unitOfMeasure)
                    : ''
                }
              />
            ]}
            interval={interval}
            yDomain={['dataMin', 'dataMax']}
            height="60vh"
            showLegend={false}
            locale={i18n.culture}
          />
        )
      ) : (
        renderEmptyState()
      )}
    </BarChartCard>
  );
}
