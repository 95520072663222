import { useEffect, useState } from 'react';

import { BillingItemType } from '@zf/api-types/product';

import { useStore } from '../../../../hooks/useStore';

export default function useMatchingBillingItem(billingItemId: string) {
  const { productConfigStore } = useStore();
  const { productConfigService } = productConfigStore;

  const [matchingItem, setMatchingItem] = useState<BillingItemType | undefined>();

  useEffect(() => {
    productConfigService.getBillingItemForId(billingItemId).then((res) => setMatchingItem(res));
  }, [billingItemId]);

  return matchingItem;
}
