import classNames from 'classnames';
import React from 'react';

import { navigate } from '@reach/router';
import { AdvancePeriodType } from '@zf/api-types/billing/billing-completeness';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../app-context';
import { ICON_COLORS } from '../../constants/icons';
import { BaseButton } from '../../design-system/Components';
import { getAdvanceStatus } from '../../utils/completeness';
import css from './advances.module.scss';

type Props = {
  ap: AdvancePeriodType;
  tooltipFor: string;
};

export default function Advance(props: Props) {
  const {
    ap
    //  tooltipFor
  } = props;
  const {
    //i18n,
    tenantReducer
  } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  let greyColor = colors['blue-200'];

  //const advanceStatus = getAdvanceStatus(ap);
  const status = getAdvanceStatus(ap);
  // let statusTransl = i18n.getTranslation(`invoice.${status}`);

  if (status === 'automaticallyDeleted') {
    greyColor = colors['silver-400'];
    //  statusTransl = i18n.getTranslation('general.deleted');
  }

  const styleObject: React.CSSProperties = React.useMemo(() => {
    return {
      backgroundColor: status !== 'inFuture' && status !== 'automaticallyDeleted' ? ICON_COLORS[status] : greyColor
    };
  }, [status]);

  const handleTrigger = React.useCallback(
    ap.invoiceId ? () => navigate(`${rootUrl}/invoices/${ap.invoiceId}`) : () => {},
    [ap.invoiceId]
  );

  return (
    <div className={css['color-div-wrapper']}>
      <BaseButton
        id={ap.invoiceId}
        className={classNames(css['color-div'], {
          [css['no-cursor']]: !ap.invoiceId
        })}
        style={styleObject}
        onClick={handleTrigger}
      />
    </div>
  );
}
