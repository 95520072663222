import React, { useEffect, useState } from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../../../app-context';
import useDetail from '../../../../../app-context/hooks/use-detail';
import { ICON_COLORS } from '../../../../../constants/icons';

const useIncomingBankingTransactionDetail = (entityId: string) => {
  const {
    entity: initialIncomingBankingTransaction,
    navigator,
    refresh
  } = useDetail<IncomingBankingTransactionType>({
    entityId,
    endpoint: '/bill/incomingbankingtransactions',
    domain: 'incoming_banking_transaction'
  });

  const { enumReducer } = useAppContext();

  const [incomingBankingTransaction, setIncomingBankingTransaction] = useState(initialIncomingBankingTransaction);

  useEffect(() => {
    if (initialIncomingBankingTransaction) setIncomingBankingTransaction(initialIncomingBankingTransaction);
  }, [initialIncomingBankingTransaction]);

  const status = incomingBankingTransaction ? (
    <NewStatusBadge
      color={ICON_COLORS[incomingBankingTransaction.status]}
      status_={` ${enumReducer.getTranslation(
        'incomingBankingTransactionStatus',
        incomingBankingTransaction.status
      )} `}
    />
  ) : (
    <></>
  );

  return { incomingBankingTransaction, status, navigator, setIncomingBankingTransaction, refresh };
};

export default useIncomingBankingTransactionDetail;
