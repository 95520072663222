import { observer } from 'mobx-react';
import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../../hooks/useStore';
import { balanceDataType } from '../../stores/BalanceStore';

const NoData = () => {
  const { applicationStore, prepaymentDeviceStore } = useStore();
  const { getTranslation } = applicationStore;
  const { dataType } = prepaymentDeviceStore.balanceStore;

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/graph.png"
          alt="graph"
          title="graph"
          width="70"
          height="70"
        />
      }
      title={getTranslation(`prepayment_device.${dataType === balanceDataType.balance ? 'no_balance' : 'no_top_ups'}`)}
    />
  );
};

export default observer(NoData);
