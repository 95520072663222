import IconParagraph from 'components/Icon/IconParagraph';
import { TooltipTrigger } from 'design-system/Components';
import { Icon } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import moment from 'moment';
import React from 'react';

import { EstimatedInvoiceLine } from '@zf/api-types/billing/estimated-invoices';
import Heading from '@zf/stella-react/src/atoms/Heading/Heading';
import Paragraph from '@zf/stella-react/src/atoms/Paragraph/Paragraph';
import { colors } from '@zf/utils/src/color';
import { DISPLAY_DATE_PARAM_FORMAT } from '@zf/utils/src/date';

import css from '../accuracy-indicator.module.scss';
import { Links } from '../Links';
import { UPDATE } from '../types';
import CostComponents from './Components/CostComponents';

export const DialogLine: React.FC<{
  accuracyToolTipTitle: string;
  estimatedInvoiceLine?: EstimatedInvoiceLine;
  productName?: string;
  onClose?: Function;
}> = ({ accuracyToolTipTitle, estimatedInvoiceLine, productName, children, onClose }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const ConsumptionBasedEstimationParameters = () => {
    const params: {
      [key: string]: {
        label: string;
        value?: number;
        visibility: boolean;
      };
    } = {
      actual: {
        label: getTranslation('contracts.estimation_accuracy_current_consumption'),
        value: estimatedInvoiceLine?.consumptionPercentages.actual,
        visibility:
          typeof estimatedInvoiceLine?.consumptionPercentages.actual !== 'undefined' &&
          estimatedInvoiceLine.consumptionPercentages.actual >= 0.5
      },
      fromPreviousYear: {
        label: getTranslation('contracts.estimation_accuracy_last_years_consumption'),
        value: estimatedInvoiceLine?.consumptionPercentages.frompreviousyear,
        visibility:
          typeof estimatedInvoiceLine?.consumptionPercentages.frompreviousyear !== 'undefined' &&
          estimatedInvoiceLine.consumptionPercentages.frompreviousyear >= 0.5
      },
      fromConsumerGroup: {
        label: getTranslation('contracts.estimation_accuracy_consumer_eav'),
        value: estimatedInvoiceLine?.consumptionPercentages.fromconsumergroup,
        visibility:
          typeof estimatedInvoiceLine?.consumptionPercentages.fromconsumergroup !== 'undefined' &&
          estimatedInvoiceLine.consumptionPercentages.fromconsumergroup >= 0.5
      },
      manual: {
        label: getTranslation('contracts.estimation_accuracy_manual_eav'),
        value: estimatedInvoiceLine?.consumptionPercentages.manual,
        visibility:
          typeof estimatedInvoiceLine?.consumptionPercentages.manual !== 'undefined' &&
          estimatedInvoiceLine.consumptionPercentages.manual >= 0.5
      }
    };

    const keys = Object.values(params);

    return (
      <>
        {keys.map(
          ({ label, value, visibility }) =>
            visibility && (
              <div className={css['parameter-line']} key={label}>
                <Paragraph className={css['parameter-text']}>{label}</Paragraph>
                <Paragraph className={css['parameter-percentage']}>{value?.toFixed(0) + '%'}</Paragraph>
              </div>
            )
        )}
      </>
    );
  };

  return (
    <TooltipTrigger
      tooltip={
        <div className={css['tooltip-dialog-line']}>
          <Heading headingType="h3" className={css['title']}>
            {accuracyToolTipTitle}
          </Heading>

          {/* CONSUMPTION PARAMETERS */}
          {estimatedInvoiceLine?.calculationType === 'consumption' && (
            <div className={css['parameters']}>
              <ConsumptionBasedEstimationParameters />
              <hr />
            </div>
          )}

          {/* CHECKMARKS */}
          <div className={css['checkmarks']}>
            {/* TARIFF */}
            {estimatedInvoiceLine?.billingTariffAccuracy.outDated ? (
              <CostComponents estimatedInvoiceLine={estimatedInvoiceLine} onClose={onClose} productName={productName} />
            ) : (
              <div className={css['checkmark-line']}>
                <Icon name="check" color={colors['green-600']} />
                <Paragraph className={css['checkmark-text']}>
                  {getTranslation('contracts.estimation_accuracy_current_tariffs')}
                </Paragraph>
              </div>
            )}

            {/* TAX RATES */}
            {estimatedInvoiceLine?.taxAccuracy.outDated ? (
              <div className={css['checkmark-line-outdated']}>
                <Icon name="cross" color={colors['red-600']} />
                <Paragraph className={css['checkmark-text']}>
                  {getTranslation('contracts.estimation_accuracy_using_outdated_tax_rates')}
                </Paragraph>
                <Paragraph className={css['checkmark-link']}>
                  <Links
                    item={UPDATE.TAX_RATES}
                    formData={{
                      entityId: estimatedInvoiceLine.taxAccuracy.entityId
                    }}
                    entityName="configuration"
                    entityId="product"
                    extraUrl="tax_codes"
                    onNavigate={onClose}
                  >
                    {getTranslation('contracts.estimation_accuracy_update_tax_rates')}
                  </Links>
                </Paragraph>
              </div>
            ) : (
              <div className={css['checkmark-line']}>
                <Icon name="check" color={colors['green-600']} />
                <Paragraph className={css['checkmark-text']}>
                  {getTranslation('contracts.estimation_accuracy_current_tax_rates')}
                </Paragraph>
              </div>
            )}
            {/* CUSTOM ENTITY PROPERTIES */}
            {estimatedInvoiceLine?.calculationType === 'customentityproperty' ? (
              estimatedInvoiceLine?.customEntityPropertyAccuracy?.outDated ? (
                <div className={css['checkmark-line-outdated']}>
                  <Icon name="cross" color={colors['red-600']} />
                  <Paragraph className={css['checkmark-text']}>
                    {getTranslation('contracts.estimation_accuracy_using_outdated_property_value')}
                  </Paragraph>
                  <Paragraph className={css['checkmark-link']}>
                    <Links
                      item={UPDATE.CEP_VALUE}
                      formData={{
                        startDateTime: moment(
                          estimatedInvoiceLine.customEntityPropertyAccuracy.entityPeriod.startDateTime
                        ).format(DISPLAY_DATE_PARAM_FORMAT),
                        endDateTime: moment(
                          estimatedInvoiceLine.customEntityPropertyAccuracy.entityPeriod.endDateTime
                        ).format(DISPLAY_DATE_PARAM_FORMAT),
                        isFocus: 1,
                        customEntityPropertyTypeId: -1
                      }}
                      entityName={estimatedInvoiceLine.customEntityPropertyEntitySubjectType}
                      entityId={estimatedInvoiceLine.customEntityPropertyEntitySubjectId}
                      onNavigate={onClose}
                    >
                      {getTranslation('contracts.estimation_accuracy_update_property_value')}
                    </Links>
                  </Paragraph>
                </div>
              ) : (
                <div className={css['checkmark-line']}>
                  <Icon name="check" color={colors['green-600']} />
                  <Paragraph className={css['checkmark-text']}>
                    {getTranslation('contracts.estimation_accuracy_current_property_value')}
                  </Paragraph>
                </div>
              )
            ) : null}
            {/* ATTRIBUTE */}
            {estimatedInvoiceLine?.calculationType === 'attribute' ? (
              estimatedInvoiceLine?.attributeAccuracy?.outDated ? (
                <div className={css['checkmark-line-outdated']}>
                  <Icon name="cross" color={colors['red-600']} />
                  <Paragraph className={css['checkmark-text']}>
                    {getTranslation('contracts.estimation_accuracy_using_outdated_attribute_value')}
                  </Paragraph>
                  <Paragraph className={css['checkmark-link']}>
                    <Links
                      item={UPDATE.ATTRIBUTE}
                      formData={{
                        isFocus: 1,
                        attributeType: -1
                      }}
                      entityName="locations"
                      entityId={estimatedInvoiceLine.attributeAccuracy.entityId}
                      onNavigate={onClose}
                    >
                      {getTranslation('contracts.estimation_accuracy_update_attribute_value')}
                    </Links>
                  </Paragraph>
                </div>
              ) : (
                <div className={css['checkmark-line']}>
                  <Icon name="check" color={colors['green-600']} />
                  <Paragraph className={css['checkmark-text']}>
                    {getTranslation('contracts.estimation_accuracy_current_attribute_value')}
                  </Paragraph>
                </div>
              )
            ) : null}
            {/* CONSUMPTION UNIT */}
            {estimatedInvoiceLine?.calculationType === 'consumptionunit' ? (
              estimatedInvoiceLine?.consumptionUnitAccuracy?.outDated ? (
                <div className={css['checkmark-line-outdated']}>
                  <Icon name="cross" color={colors['red-600']} />
                  <Paragraph className={css['checkmark-text']}>
                    {getTranslation('contracts.estimation_accuracy_using_outdated_consumption_unit_value')}
                  </Paragraph>
                  <Paragraph className={css['checkmark-link']}>
                    <Links
                      item={UPDATE.CONSUMPTION_UNIT}
                      formData={{
                        isFocus: 1,
                        unitType: -1
                      }}
                      entityName="locations"
                      entityId={estimatedInvoiceLine.consumptionUnitAccuracy.entityId}
                      onNavigate={onClose}
                    >
                      {getTranslation('contracts.estimation_accuracy_update_consumption_unit_value')}
                    </Links>
                  </Paragraph>
                </div>
              ) : (
                <div className={css['checkmark-line']}>
                  <Icon name="check" color={colors['green-600']} />
                  <Paragraph className={css['checkmark-text']}>
                    {getTranslation('contracts.estimation_accuracy_current_consumption_unit_value')}
                  </Paragraph>
                </div>
              )
            ) : null}
          </div>

          {/* FOOTER */}
          {estimatedInvoiceLine?.calculationType === 'consumption' &&
            estimatedInvoiceLine.consumptionPercentages.actual &&
            estimatedInvoiceLine.consumptionPercentages.actual < 100 && (
              <IconParagraph iconType="info-circle" className={css['info-paragraph']}>
                {getTranslation('contracts.estimation_accuracy_will_improve')}
              </IconParagraph>
            )}
        </div>
      }
      placement="left"
      offset={[0, 0]}
    >
      {children}
    </TooltipTrigger>
  );
};
