import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import ChangeCustomerGroupDialog from '../Dialogs/ChangeCustomerGroupDialog';
import { CustomerType } from '@zf/api-types/master-data/customer';

type Props = { entityId?: string; onComplete: Function; customer: CustomerType };

export default function AddCustomerToGroupListItem({ onComplete, customer }: Props) {
  const { clickRef, onSubmit } = useDialog();
  const { applicationStore, customerStore } = useStore();
  const { selectedCustomer } = customerStore;
  const { validationRef } = useDialog();
  const { getTranslation } = applicationStore;

  return (
    <DropdownAction
      id="customer_groups.add"
      dialogTitle={
        !customer.customerGroup
          ? getTranslation('customer_groups.add_to_customer_group')
          : getTranslation('customer_groups.change_customer_group')
      }
      dialogContent={
        <ChangeCustomerGroupDialog
          ref={clickRef}
          validationRef={validationRef}
          customer={selectedCustomer}
          onComplete={onComplete}
        />
      }
      onSubmit={onSubmit}
      icon="customer-group"
      validationRef={validationRef}
      buttonPositive={applicationStore.getTranslation('general.add')}
    />
  );
}
