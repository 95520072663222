import validator from 'validator';
import { EVERY_FOUR_CHARS } from './regex';

/**
 * Formats a given IBAN
 * @param string iban 
 * @param string seperator 
 * @returns formatted string
 */
export function formatIBAN(iban?: string | null, seperator = ' ') {
  return iban
    ? iban
        .toUpperCase()
        .replace(/\s/g, '')
        .replace(EVERY_FOUR_CHARS, '$1' + seperator)
    : '';
}

/**
 * Checks if an IBAN is valid 
 * @param string iban 
 * @returns boolean
 */
export function isValidIBAN(iban?: string | null) {
  if (!iban) return false;
  return validator.isIBAN(iban);
}
