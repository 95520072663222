import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import css from './month-container.module.scss';

type Props = {
  selectedMonth?: number;
  onSelect: (month: number) => void;
};

export default function MonthContainer(props: Props) {
  const { selectedMonth, onSelect } = props;

  const date = moment();
  const months = new Array(12).fill(0).map((val, i) => i);

  return (
    <div className={classNames(css['calendar-container'], css['month-container'])}>
      {months.map((month, i) => {
        const formattedMonth = date.month(month).format('MMMM');

        return (
          <BaseButton
            id={`month-${i}`}
            key={`month-${i}`}
            className={classNames(css['calendar-item'], css['month'], {
              [css['selected']]: month === selectedMonth
            })}
            onClick={() => onSelect(month)}
          >
            {formattedMonth}
          </BaseButton>
        );
      })}
    </div>
  );
}
