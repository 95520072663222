import clone from 'clone';
import CustomerGroupAutoFill from 'components/Autofills/CustomerGroupAutoFill';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import React from 'react';

import { countryCode, customerType } from '@zf/api-types/enums';
import { LocalAddressType } from '@zf/api-types/general';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import InputDouble from '@zf/stella-react/src/atoms/InputContainer/InputDouble';
import InputSection from '@zf/stella-react/src/atoms/InputContainer/InputSection';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';
import { colors } from '@zf/utils/src/color';

import CountryInput, { CountryInputProps } from '../../../../components/input/country-input';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import Select from '../../../../components/input/Select';
import DatePicker from '../../../../components/Lang/DatePicker';
import Paragraph from '../../../../design-system/Foundation/Paragraph/Paragraph';
import { useStore } from '../../../../hooks/useStore';
import { formatSsin, validateIdentificationNumber } from '../../../../utils/customer';
import { STEP_NAMES } from '../../../meter-install/wizard/InstallMeterWizard';

export type CustomerEssentialsBaseType = {
  accountNumber: string;
  salutation: string;
  initials: string | null;
  firstName: string;
  lastName: string;
  birthDate: Moment | null;
  invoiceAddress: LocalAddressType | null;
  customerType: customerType | null;
  companyName: string | null;
  organizationNumber: string | null;
  vatNumber: string | null;
  ssin: string | null;
  ssinCountry: countryCode | null;
  customerGroupId: string | null;
};

export type NsiType = {
  nssiCountry: string;
  nssiNumber: number | null;
};

export type Props<T extends CustomerEssentialsBaseType> = {
  values: T;
  orgConfig: OrganizationConfigType;
  customerName: { name: string };
  setCustomerEssential: (value: Partial<CustomerEssentialsBaseType>) => void;
  onFocusStep?: React.Dispatch<React.SetStateAction<string>>;
};

const initialInvoiceAddress = {
  streetName: '',
  streetNumber: '',
  streetNumberAddition: '',
  postalCode: '',
  city: '',
  country: ''
};

const WizardCountryPicker = WizardInputWrapper<CountryInputProps>(CountryInput);
const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);

const CustomerEssentials = <T extends CustomerEssentialsBaseType>(props: Props<T>) => {
  const { values, orgConfig, customerName, setCustomerEssential, onFocusStep } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const handleFocus = (step: string) => {
    if (onFocusStep) return () => onFocusStep(step);
  };

  const setInvoiceAddressValue = (val: Partial<LocalAddressType>) => {
    let addressClone = clone(values.invoiceAddress);

    if (!addressClone) {
      addressClone = clone(initialInvoiceAddress);
    }

    setCustomerEssential({ invoiceAddress: { ...addressClone, ...val } });
  };

  return (
    <>
      {(orgConfig.manuallySetCustomerNumber || orgConfig.migrationMode) && (
        <>
          <Heading headingType="h3" style="bold">
            {getTranslation('customer.number_wizard', customerName)}
          </Heading>
          <InputContainer>
            <InputField
              id="customerId"
              onChange={(accountNumber) => setCustomerEssential({ accountNumber })}
              value={values.accountNumber}
              placeholder={getTranslation('customer.customer_id')}
              error={!values.accountNumber}
              onFocus={handleFocus(STEP_NAMES[0])}
            />
          </InputContainer>
        </>
      )}
      <Heading headingType="h3" style="bold">
        {getTranslation('customer.type_wizard')}
      </Heading>
      <InputContainer>
        <Select
          id="customerType"
          onChange={(val) => setCustomerEssential({ customerType: val[0] })}
          selectedValues={[values.customerType]}
          values={getEnum<customerType>('customerType').map((ct) => {
            return {
              icon: ct.value,
              value: ct.value,
              text: ct.text
            };
          })}
        />
      </InputContainer>
      {values.customerType === 'organization' && (
        <>
          <Heading headingType="h3" style="bold">
            {getTranslation('customer.org_information')}
          </Heading>
          <InputContainer>
            <InputField
              id="companyName"
              onChange={(companyName) => setCustomerEssential({ companyName })}
              value={values.companyName || ''}
              placeholder={getTranslation('customer.company_name')}
              error={!values.companyName}
              onFocus={handleFocus(STEP_NAMES[0])}
            />
            <InputField
              id="organizationNumber"
              onChange={(organizationNumber) => setCustomerEssential({ organizationNumber })}
              value={values.organizationNumber || ''}
              placeholder={getTranslation('customer.org_number')}
              onFocus={handleFocus(STEP_NAMES[0])}
            />
            <InputField
              id="vatNumber"
              onChange={(vatNumber) => setCustomerEssential({ vatNumber })}
              value={values.vatNumber || ''}
              placeholder={getTranslation('customer.vat_number')}
              onFocus={handleFocus(STEP_NAMES[0])}
            />
          </InputContainer>
        </>
      )}
      <Heading headingType="h3" style="bold">
        {values['customerType'] === 'organization'
          ? getTranslation('customer.name_organization_wizard')
          : getTranslation('customer.name_wizard')}
      </Heading>
      <InputContainer hasSections>
        <InputSection>
          <InputField
            id="salutation"
            onChange={(salutation) => setCustomerEssential({ salutation })}
            value={values.salutation}
            placeholder={getTranslation('customer.title')}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
          <InputField
            id="initials"
            onChange={(initials) => setCustomerEssential({ initials })}
            value={values.initials || ''}
            placeholder={getTranslation('customer.initials')}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
        </InputSection>
        <InputSection>
          <InputField
            id="firstName"
            onChange={(firstName) => setCustomerEssential({ firstName })}
            value={values.firstName}
            placeholder={getTranslation('customer.first_name')}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
          <InputField
            id="lastName"
            onChange={(lastName) => setCustomerEssential({ lastName })}
            value={values.lastName}
            placeholder={getTranslation('customer.last_name')}
            error={values.customerType === 'organization' ? false : !values.lastName}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
          <DatePicker
            id="birthDate"
            onChange={(birthDate) => setCustomerEssential({ birthDate })}
            value={values.birthDate || null}
            placeholder={getTranslation('customer.birth_date')}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
        </InputSection>
      </InputContainer>
      <Heading headingType="h3" style="bold">
        {getTranslation('customer.invoice_address_wizard')}
      </Heading>
      <InputContainer noDense hasSections>
        <InputSection>
          <InputField
            id="street"
            onChange={(streetName) => setInvoiceAddressValue({ streetName })}
            value={values.invoiceAddress?.streetName || ''}
            placeholder={getTranslation('location.street')}
            error={!values.invoiceAddress || (values.invoiceAddress && !values.invoiceAddress.streetName)}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
          <InputDouble>
            <InputField
              id="streetNr"
              onChange={(streetNumber) => setInvoiceAddressValue({ streetNumber })}
              value={values.invoiceAddress?.streetNumber || ''}
              placeholder={getTranslation('customer.streetnr')}
              error={!values.invoiceAddress || (values.invoiceAddress && !values.invoiceAddress.streetNumber)}
              onFocus={handleFocus(STEP_NAMES[0])}
            />
            <InputField
              id="streetNrAdd"
              onChange={(streetNumberAddition) => setInvoiceAddressValue({ streetNumberAddition })}
              value={values.invoiceAddress?.streetNumberAddition || ''}
              placeholder={getTranslation('location.streetnraddition')}
              onFocus={handleFocus(STEP_NAMES[0])}
            />
          </InputDouble>
        </InputSection>
        <InputSection>
          <InputField
            id="postalCode"
            onChange={(postalCode) => setInvoiceAddressValue({ postalCode })}
            value={values.invoiceAddress?.postalCode || ''}
            placeholder={getTranslation('location.postal')}
            error={!values.invoiceAddress || (values.invoiceAddress && !values.invoiceAddress.postalCode)}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
          <InputField
            id="city"
            onChange={(city) => setInvoiceAddressValue({ city })}
            value={values.invoiceAddress?.city || ''}
            placeholder={getTranslation('location.city')}
            error={!values.invoiceAddress || (values.invoiceAddress && !values.invoiceAddress.city)}
            onFocus={handleFocus(STEP_NAMES[0])}
          />
          <CountryInput
            id="country"
            onChange={(value) => setInvoiceAddressValue({ country: value[0] || '' })}
            selectedValues={[values.invoiceAddress?.country || '']}
            placeholder={getTranslation('location.country')}
            error={!values.invoiceAddress || (values.invoiceAddress && !values.invoiceAddress.country)}
            onFocus={handleFocus(STEP_NAMES[0])}
            organisationScoped
          />
        </InputSection>
      </InputContainer>
      {values.customerType === customerType.person && (
        <>
          <Heading headingType="h3" style="bold">
            {getTranslation('customer.ssin')}
          </Heading>
          <Paragraph color={colors['silver-600']}>{getTranslation('customer.ssin_extra_info')}</Paragraph>
          <InputContainer>
            <WizardCountryPicker
              id="country-ssin"
              onChange={(value) =>
                setCustomerEssential({
                  ssinCountry: value[0] as countryCode,
                  ssin: formatSsin(values.ssin, value[0] as countryCode)
                })
              }
              selectedValues={values.ssinCountry ? [values.ssinCountry] : []}
              placeholder={getTranslation('customer.country_of_residence')}
              onFocus={handleFocus(STEP_NAMES[0])}
              acceptedCountries={[countryCode.bel, countryCode.nld]}
            />
            {values.ssinCountry && (
              <WizardInputField
                id="social-security-number"
                onChange={(ssin) => setCustomerEssential({ ssin })}
                placeholder={
                  values.ssinCountry === countryCode.bel
                    ? getTranslation('customer.rno')
                    : getTranslation('customer.bsn')
                }
                maxLength={values.ssinCountry === countryCode.nld ? 9 : undefined}
                value={values.ssin || ''}
                onBlur={() => setCustomerEssential({ ssin: formatSsin(values.ssin, values.ssinCountry) })}
                error={!values.ssin || !validateIdentificationNumber(values.ssin, values.ssinCountry as countryCode)}
                onFocus={handleFocus(STEP_NAMES[0])}
              />
            )}
          </InputContainer>
        </>
      )}
      <>
        <Heading headingType="h3" style="bold">
          {getTranslation('customer_groups.customer_group')}
        </Heading>
        <Paragraph color={colors['silver-600']}>{getTranslation('customer_groups.wizard_extra_info')}</Paragraph>
        <InputContainer>
          <CustomerGroupAutoFill
            placeholder={getTranslation('customer_groups.select_a_customer_group')}
            id="customer-group"
            onChange={(customerGroup) =>
              customerGroup[0] && setCustomerEssential({ customerGroupId: customerGroup[0].id })
            }
            selectedValues={[values.customerGroupId ? values.customerGroupId : '']}
          />
        </InputContainer>
      </>
    </>
  );
};

export default observer(CustomerEssentials);
