import React from 'react';

import { DataProviderTypesType, ParametersType } from '@zf/api-types/data-provider';
import { Card, CardBody, CardEmptyBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../app-context';
import { Icon } from '../Icon';
import ArrayGenerator from './array-generator';
import InputGenerator from './input-generator';

type Props = {
  tooltipFor: string;
  genericType: DataProviderTypesType | undefined;
  setParameters: (value: ParametersType[keyof ParametersType], dataKey: keyof ParametersType) => void;
  parameters: Record<string, any> | undefined;
  id?: string;
  selection: number;
};

export default function ParameterGenerator(props: Props) {
  const { genericType, parameters, id, setParameters, selection, tooltipFor } = props;
  const { i18n } = useAppContext();

  const generateCardLine = (label: string, input: React.ReactNode, index: number) => {
    return (
      <React.Fragment key={`${index}-${label}`}>
        <CardItem width="6">
          <Label>{i18n.getTranslation(`data_provider.${label}`)}</Label>
        </CardItem>
        <CardItem width="6">{input}</CardItem>
      </React.Fragment>
    );
  };

  const cardWrapper = (content: React.ReactNode) => {
    return (
      <Card id="dataprovider-parameters-card" width="1">
        <CardHeader>{i18n.getTranslation('file_format.format_parameters')}</CardHeader>
        {content}
      </Card>
    );
  };

  if (selection === -1) {
    return (
      <>
        {cardWrapper(
          <CardEmptyBody icon={<Icon type="options" />} title={i18n.getTranslation('data_provider.no_selection')}>
            {i18n.getTranslation('data_provider.no_selection_description')}
          </CardEmptyBody>
        )}
      </>
    );
  } else if (!genericType || (genericType && genericType.properties.length === 0)) {
    return (
      <>
        {cardWrapper(
          <CardEmptyBody icon={<Icon type="options" />} title={i18n.getTranslation('data_provider.no_parameters')}>
            {i18n.getTranslation('data_provider.no_parameters_description')}
          </CardEmptyBody>
        )}
      </>
    );
  } else {
    return (
      <>
        {cardWrapper(
          <CardBody type="grid">
            {genericType.properties
              .filter((property) => !property.propertyType.startsWith('array'))
              .map((property, index) => {
                const value = parameters ? parameters[property.name] : undefined;

                if (!id && value === undefined) {
                  if (property.defaultValue) {
                    setParameters(property.defaultValue, property.name);
                  } else if (property.propertyType === 'string') {
                    // Set default empty string for string types
                    setParameters('', property.name);
                  }
                }

                const input = (
                  <InputGenerator
                    value={value}
                    propertyIndex={index}
                    property={property}
                    setParameters={setParameters}
                  />
                );

                return generateCardLine(property.name, input, index);
              })}
          </CardBody>
        )}
        {genericType.properties
          .filter((property) => property.propertyType.startsWith('array'))
          .map((property) => {
            return (
              <ArrayGenerator
                key={property.name}
                id={id}
                tooltipFor={tooltipFor}
                parentSelection={selection}
                property={property}
                parameters={parameters ? parameters[property.name] : []}
                setParameters={(values) => setParameters(values, property.name)}
              />
            );
          })}
      </>
    );
  }
}
