import React from 'react';

import { navigate } from '@reach/router';
import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteIncomingBankingTransactionsDialog from '../../../actions/incoming-banking-transactions/DeleteIncomingBankingTransactionsDialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  bankingTransaction: IncomingBankingTransactionType;
};

export default function DeleteDetailButton(props: Props) {
  const { i18n, tenantReducer } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const onComplete = () => {
    navigate(`${tenantReducer.state.rootUrl}/payments/incoming-banking-transactions`);
  };

  return (
    <DropdownAction
      id="incoming_banking_transaction.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={<DeleteIncomingBankingTransactionsDialog {...props} ref={clickRef} onComplete={onComplete} />}
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.delete')}
      dialogType="danger"
    />
  );
}
