import moment from 'moment';

import {
  CustomEntityProperty,
  CustomEntityPropertyType,
  CustomEntityPropertyValue
} from '@zf/api-types/config/custom-entity-property-types';
import { customEntityPropertyValueType } from '@zf/api-types/enums';
import { formatDate } from '@zf/utils/src/date';

export const getCustomEntityPropertyValue = (
  value: CustomEntityPropertyValue | undefined,
  matchingType: CustomEntityPropertyType,
  getTranslation: (key: string) => string
) => {
  if (!value) return '';

  switch (matchingType.valueType.type) {
    case customEntityPropertyValueType.boolean:
      return getTranslation(`general.${value.valueBoolean ? 'yes' : 'no'}`);
    case customEntityPropertyValueType.decimal:
      return value.valueDecimal;
    case customEntityPropertyValueType.number:
      return value.valueNumber;
    case customEntityPropertyValueType.datetime:
      return formatDate(value.valueDateTime);
    default:
      return value.valueString;
  }
};

export const getExtraCustomEntityColumns = (
  customProperties: CustomEntityProperty[],
  customEntityPropertyTypes: CustomEntityPropertyType[],
  getTranslation: (key: string) => string
) => {
  const today = moment();

  return customEntityPropertyTypes.reduce((acc: Record<string, string>, cept) => {
    const matchingCustomProperty = customProperties.find((cep) => cep.customEntityPropertyTypeId === cept.id);

    const valueActiveToday = matchingCustomProperty?.values.find((v) =>
      today.isBetween(v.startDateTime, v.endDateTime, undefined, '[]')
    );
    
    var value = getCustomEntityPropertyValue(valueActiveToday, cept, getTranslation);

    if (value == null)
    {
      value = "";
    }

    acc[cept.name] = value.toString();

    return acc;
  }, {});
};
