import React, { useEffect } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { InvoiceType } from '@zf/api-types/invoice';
import { ContractType } from '@zf/api-types/master-data/contract';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatPeriodWMonth } from '@zf/utils/src/date';

import { useAppContext } from '../../../../../../app-context';
import EmptyLink from '../../../../../../components/Link/EmptyLink';
import { METHODS, sendRequest } from '../../../../../../utils/request';

type State = {
  affectedInvoices: InvoiceType[];
  isLoading: boolean;
};

const useCreditedInvoicesTable = (contracts: ContractType[], date: string) => {
  const { i18n, tenantReducer } = useAppContext();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = React.useReducer(stateReducer, {
    affectedInvoices: [],
    isLoading: true
  });

  const getAffectedInvoices = async (contract: ContractType) => {
    return (
      await sendRequest<PagedResponseType<InvoiceType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/invoices/c/${contract.contractor.customerId}/${contract.id}/productchange`,
          query: {
            startDate: date
          }
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data.results;
  };

  useEffect(() => {
    Promise.all(contracts.map((c) => getAffectedInvoices(c))).then((res) => {
      setState({ affectedInvoices: res.flat(), isLoading: false });
    });
  }, [contracts]);

  const rows = state.affectedInvoices.map((invoice: InvoiceType) => {
    return {
      __id: invoice.id,
      __entity: invoice,
      __etag: invoice._etag,
      invoiceNum: (
        <EmptyLink id={`invoice-${invoice.id}`} url={`${tenantReducer.state.rootUrl}/invoices/${invoice.id}`} newTab>
          {invoice.invoiceNum}
        </EmptyLink>
      ),
      invoicePeriod: formatPeriodWMonth(invoice.periodStartDateTime, invoice.periodEndDateTime),
      product: <Paragraph textAlign="left">{invoice.product?.productName}</Paragraph>
    };
  });

  return {
    rows,
    isLoading: state.isLoading
  };
};

export default useCreditedInvoicesTable;
