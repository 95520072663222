import { Info } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useReducer } from 'react';

import { utilityType } from '@zf/api-types/enums';
import { ConsumerGroupType, GetServiceLocationMatchingConsumerGroupsType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption } from '@zf/api-types/master-data/contract';
import { RequiredEstimationsType } from '@zf/api-types/product';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { colors } from '@zf/utils/src/color';

import css from './required-estimation.module.scss';
import RequiredEstimationsSectionBody from './RequiredEstimationsSectionBody';

export type RequiredEstimationsDataType = {
  estimatedConsumptions: ContractedServiceEstimatedConsumption[];
  propertyBillingConfig: PropertyGroupBillingConfigurationType | null | undefined;
  productId: string;
};

type Props = {
  values: RequiredEstimationsDataType;
  consumerGroups: ConsumerGroupType[];
  utilityTypes: utilityType[];
  handleEstimatedConsumptionChange: (index: number, value: Partial<ContractedServiceEstimatedConsumption>) => void;
  initEstimatedConsumptions: (
    matchingGroups: GetServiceLocationMatchingConsumerGroupsType[][],
    requiredEstimations: RequiredEstimationsType[]
  ) => void;
};

type State = {
  utilityTypes: utilityType[] | undefined;
  requiredEstimations: RequiredEstimationsType[] | undefined;
};

export default function RequiredEstimationsSection(props: Props) {
  const { values, utilityTypes } = props;
  const { applicationStore, productConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getRequiredEstimationsForProduct } = productConfigStore.productConfigService;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    utilityTypes: undefined,
    requiredEstimations: undefined
  });

  //dear maintainer
  //
  //when we wrote this code, only we and god knew what it was
  //now only god knows!

  //so if you are trying to 'optimize' this (and failed)
  //please increment the following counter as a warning to the next guy

  //total_hours_wasted_here = 69 + 16

  useEffect(() => {
    if (values.productId) {
      getRequiredEstimationsForProduct(values.productId).then((requiredEstimations) => {
        if (requiredEstimations) {
          // We only need the requiredEstimations for the selected utilityTypes (move in wizard)
          const filteredEstimations = requiredEstimations.filter((re) => utilityTypes.includes(re.utilityType));

          setState({
            utilityTypes,
            requiredEstimations: filteredEstimations
          });
        } else {
          setState({
            utilityTypes: [],
            requiredEstimations: []
          });
        }
      });
    }
  }, [utilityTypes, values.productId]);

  if (!values.productId) {
    return <Info color={colors['silver-600']}>{getTranslation('contracts.wizard.consumption_groups_info')}</Info>;
  }

  if (!state.utilityTypes) return null;

  return (
    <div className={css['consumer-groups-section']}>
      {state.requiredEstimations?.length === 0 && (
        <Info color={colors['orange-600']}>{getTranslation('contracts.wizard.no_groups_found')}</Info>
      )}

      <RequiredEstimationsSectionBody {...props} requiredEstimations={state.requiredEstimations || []} />
    </div>
  );
}
