import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import AsyncCardSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncCardSpinner';

import { CommentCard, FeedCard, ServicedLocationCard } from '../../../../../cards';
import CustomEntityPropertiesCard from '../../../../../cards/CustomEntityProperties/CustomEntityPropertiesCard';
import IntegrationLoggingCard from '../../../../../cards/Integration/IntegrationLoggingCard';
import InvoiceCard from '../../../../../cards/InvoiceCard/InvoiceCard';
import { useStore } from '../../../../../hooks/useStore';
import BillingDetailsOrPrepayment from './BillingDetailsOrPrepayment';
import ContractBlocked from './cards/ContractBlocked';
import ContractDetailsCard from './cards/ContractDetailsCard';
import css from './contract-blocked.module.scss';

type Props = {
  setActiveTab: (tabId: string) => void;
};

const ContractDetail = observer((props: Props) => {
  const { contractStore, applicationStore } = useStore();
  const { selectedContract, billingRelation_, billingRelation, contractDetailsForm_, forecastingService } =
    contractStore;
  const { getTranslation } = applicationStore;

  const contract = selectedContract.contract;

  return (
    <CardsContainer className={css['contract-details-card-container']}>
      {contract.billingDetails.blocked && (
        <AsyncCardSpinner title={getTranslation('contracts.billing_block')} variables={[billingRelation_]}>
          <ContractBlocked billingRelation={billingRelation} />
        </AsyncCardSpinner>
      )}

      <AsyncCardSpinner title={getTranslation('general.details')} variables={[contractDetailsForm_]}>
        <ContractDetailsCard contract={contract} />
      </AsyncCardSpinner>

      <BillingDetailsOrPrepayment contract={contract} setActiveTab={props.setActiveTab} />

      <ServicedLocationCard forecastingService={forecastingService} currentContract={contract} />

      <InvoiceCard
        endpoint={`/bill/invoices/c/${contract.contractor.customerId}/${contract.id}`}
        queryParams={{
          refreshTrigger: JSON.stringify(contract.billingDetails.products)
        }}
      />

      <CommentCard subjectType={entitySubjectType.contract} subjectId={contract.id} />

      <FeedCard subjectType={entitySubjectType.contract} subjectId={contract.id} />

      <CustomEntityPropertiesCard
        entityType="Contracts"
        entity={contract}
        entitySubjectType={entitySubjectType.contract}
      />

      <IntegrationLoggingCard subjectId={contract.id} subjectType="Contract" />
    </CardsContainer>
  );
});

export default ContractDetail;
