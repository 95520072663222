import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { TopUpType } from '@zf/api-types/metering/top-up';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import { formatDateTime } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { useStore } from '../../../../../hooks/useStore';
import css from './balance-table.module.scss';
import NoData from './NoData';

type Props = {
  prepaymentDevice: MeterType;
};

const TopUpTable = (props: Props) => {
  const { prepaymentDevice } = props;

  const { applicationStore, prepaymentDeviceStore } = useStore();
  const { culture, getTranslation, getEnumTranslation } = applicationStore;
  const { filterStore, createRequestService, infiniAPIService, loadTopUpTable } = prepaymentDeviceStore.balanceStore;
  const { sortBy, sortDirection, handleSort } = createRequestService;

  const [topUpColumns] = useState<ColumnType[]>([
    {
      width: 300,
      label: getTranslation('general.date'),
      dataKey: 'processedDateTimeOffset'
    },
    {
      width: 300,
      label: getTranslation('prepayment_device.origin'),
      dataKey: 'origin'
    },
    {
      width: 300,
      label: getTranslation('general.amount_valuta'),
      dataKey: 'amountPaid',
      contentAlignRight: true
    }
  ]);

  const processRecord = (topUp: TopUpType) => {
    return {
      __id: topUp.id,
      __entity: topUp,
      processedDateTimeOffset: formatDateTime(topUp.processedDateTimeOffset),
      origin: getEnumTranslation('topupOrigin', topUp.origin),
      amountPaid: formatMoney(topUp.amountPaid, culture)
    };
  };

  // Fetch table data
  useEffect(() => {
    loadTopUpTable(processRecord, prepaymentDevice.id);
  }, [filterStore.queryParams, sortBy, prepaymentDevice.id]);

  if (!infiniAPIService)
    return (
      <div className={css['table-wrapper']}>
        <Spinner centered />
      </div>
    );

  const { loading, error, sortableFields, rows, totalAmountOfRows } = infiniAPIService;

  return (
    <div className={css['table-wrapper']}>
      <DynamicVirtualTable
        id="balance-table"
        tooltipId="balance-table-tip"
        rows={toJS(rows)}
        loading={loading}
        error={error}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sortableFields={sortableFields}
        NoDataOverlay={NoData}
        totalAmountOfRows={totalAmountOfRows}
        sort={handleSort}
        columns={topUpColumns}
        noSelect
      />
    </div>
  );
};

export default observer(TopUpTable);
