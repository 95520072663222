import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { ImportJobType } from '@zf/api-types/import-job';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context';
import { SuspenseCardBody } from '../../../../components/suspense';
import { DISABLED, INFO, SUCCESS, WARNING } from '../../../../constants/color';
import css from './pie-chart.module.scss';

export type PropsType = {
  importJob: ImportJobType;
};

function CardContent(props: PropsType) {
  const { importJob } = props;
  const { i18n } = useAppContext();

  const data = [
    {
      name: i18n.getTranslation('import_jobs.values_failed'),
      value: importJob.valuesFailed,
      color: WARNING
    },
    {
      name: i18n.getTranslation('import_jobs.values_succeeded'),
      value: importJob.valuesSucceeded,
      color: DISABLED
    },
    {
      name: i18n.getTranslation('import_jobs.values_valid'),
      value: importJob.validValues,
      color: SUCCESS
    },
    {
      name: i18n.getTranslation('import_jobs.values_invalid'),
      value: importJob.invalidValues,
      color: colors['blue-600']
    }
  ];

  const totalValuesData = [
    {
      name: i18n.getTranslation('import_jobs.amount_values_parsed'),
      value: importJob.amountOfValuesParsed
    }
  ];

  return (
    <ResponsiveContainer width="95%" height="95%" className={css['graph']}>
      <PieChart>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Pie data={totalValuesData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill={INFO} />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill={INFO}
          label
        >
          {data.map((entry, index) => (
            <Cell fill={entry.color} key={index} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default function ValuesSummaryPieChart(props: PropsType) {
  const { i18n } = useAppContext();

  return (
    <Card id="overview-pie-chart" className={css['import-job-values-card']}>
      <CardHeader>{i18n.getTranslation('import_jobs.values_summary')}</CardHeader>
      <SuspenseCardBody noMaxHeight>
        <CardContent {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
