import clone from 'clone';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { navigate } from '@reach/router';
import { InsightsPerCategoryType, PaymentAnalyticsType } from '@zf/api-types/analytics/payments';
import BarChartCard from '@zf/stella-react/src/atoms/Chart/BarChartCard';
import css from '@zf/stella-react/src/atoms/Chart/chart-style.module.scss';
import CustomLegend, { LegendValuesType } from '@zf/stella-react/src/atoms/Chart/CustomLegend';
import CustomTooltip from '@zf/stella-react/src/atoms/Chart/CustomTooltip';
import CustomXAxisTick from '@zf/stella-react/src/atoms/Chart/CustomXAxisTick';
import CustomYAxisTick from '@zf/stella-react/src/atoms/Chart/CustomYAxisTick';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import { colors } from '@zf/utils/src/color';
import { makePositive, roundNumber } from '@zf/utils/src/number';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';

type Props = {
  paymentInsights: PaymentAnalyticsType;
};

type ChartDataType = {
  XLabel: string;
  refunds: number;
  payments: number;
};

export default function MissingCashChart(props: Props) {
  const { paymentInsights } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { myCurrent, setMyCurrent } = useCurrent('payment');
  const { myCurrent: myCurrentInvoice, setMyCurrent: setMyCurrentInvoice } = useCurrent('invoice');
  const { rootUrl } = tenantReducer.state;

  const chartData = paymentInsights.paymentDataInsights.insightsPerCategory.reduce(
    (acc: ChartDataType[], ipc: InsightsPerCategoryType) => {
      const label = i18n.getTranslation(`dashboard.charts.${ipc.category}`);

      const index = acc.findIndex((cd) => {
        return cd.XLabel === label;
      });

      if (index === -1) {
        acc.push({
          XLabel: label,
          refunds: ipc.type === 'refund' ? roundNumber(makePositive(ipc.amount)) : 0,
          payments: ipc.type === 'payment' ? roundNumber(makePositive(ipc.amount)) : 0
        });
      } else {
        if (ipc.type === 'refund') {
          acc[index].refunds = roundNumber(makePositive(ipc.amount));
        } else {
          acc[index].payments = roundNumber(makePositive(ipc.amount));
        }
      }

      return acc;
    },
    []
  );

  const setFilters = (type: 'refunds' | 'payments') => {
    if (type === 'payments') {
      const currentClone = clone(myCurrentInvoice);
      currentClone.filter.queryParams = { invoiceType: ['invoice'] };
      setMyCurrentInvoice(currentClone);
    } else {
      const currentClone = clone(myCurrent);
      currentClone.filter.queryParams = {};
      setMyCurrent(currentClone);
    }
  };

  const hasData = paymentInsights.paymentDataInsights.insightsPerCategory.length > 0;

  const legendValues: LegendValuesType = paymentInsights.paymentDataInsights.insightsPerCategory.reduce(
    (acc, ipc) => {
      if (ipc.type === 'refund') {
        acc.refunds += ipc.amount;
      } else {
        acc.payments += ipc.amount;
      }

      return acc;
    },
    {
      refunds: 0,
      payments: 0
    }
  );

  return (
    <BarChartCard subject="billing" title={i18n.getTranslation('dashboard.general_tab.missing_cash')}>
      {hasData ? (
        <div className={css['chart-container-wrapper']}>
          <ResponsiveContainer className={css['chart']}>
            <BarChart data={chartData} margin={{ top: 40, bottom: 0 }}>
              <CartesianGrid strokeOpacity={0.5} strokeDasharray="1 2" />
              <XAxis axisLine={false} tickLine={false} height={15} dataKey="XLabel" tick={<CustomXAxisTick />} />
              <YAxis tick={<CustomYAxisTick locale={i18n.culture} />} axisLine={false} tickLine={false} />
              <ReferenceLine y={0} stroke={colors['silver-700']} strokeWidth={0.5} />
              <Tooltip content={<CustomTooltip locale={i18n.culture} />} cursor={{ fill: colors['blue-50'] }} />
              <Bar
                name={i18n.getTranslation('payments.refunds')}
                dataKey="refunds"
                fill={colors['blue-600']}
                radius={[3, 3, 0, 0]}
                onClick={() => {
                  setFilters('refunds');
                  navigate(`${rootUrl}/payments/?quickFilter=reversed`);
                }}
                cursor="pointer"
              />
              <Bar
                name={i18n.getTranslation('payments.payments')}
                dataKey="payments"
                fill={colors['yellow-600']}
                radius={[3, 3, 0, 0]}
                onClick={() => {
                  setFilters('payments');
                  navigate(`${rootUrl}/invoices/?quickFilter=unpaid`);
                }}
                cursor="pointer"
              />
              <Legend
                content={<CustomLegend legendValues={legendValues} locale={i18n.culture} />}
                wrapperStyle={{ top: 0, left: 40 }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <EmptyChartState title={i18n.getTranslation('dashboard.general_tab.no_payments_yet')} />
      )}
    </BarChartCard>
  );
}
