import React from 'react';

import { CollectionFlowStepType } from '@zf/api-types/collection-cycles';
import { collectionChargeType } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';
import { CoachMark } from '@zf/stella-react/src/atoms/CoachMark';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../app-context/app-context';
import { DeleteIcon, Icon } from '../../../components/Icon';
import EditIcon from '../../../components/Icon/EditIcon';
import { dialog } from '../../../events/dialog-events';
import CollectionStepTooltip from './collection-step-tooltip';
import css from './collection-step.module.scss';
import DeleteStepDialog from './delete-step-dialog';

type Props = {
  step: CollectionFlowStepType;
  stepNumber: number;
  flowIndex: number;
  openDialog: (useCase: 'add' | 'edit', stepNumber?: number) => void;
  removeStep: (stepId: string, flowIndex: number) => void;
};

export default function CollectionFlowStep(props: Props) {
  const { step, stepNumber, flowIndex, openDialog, removeStep } = props;
  const { i18n, enumReducer } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const openDeleteDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('collection_flows.delete_step_number', {
        stepNumber: stepNumber
      }),
      icon: 'trashcan',
      content: (
        <DeleteStepDialog
          ref={clickRef}
          stepNumber={stepNumber}
          stepId={step.id}
          flowIndex={flowIndex}
          removeStep={removeStep}
        />
      ),
      buttonPositive: i18n.getTranslation('general.delete'),
      type: 'danger',
      onSubmit: onSubmit
    });
  };

  return (
    <div className={css['wrapper1']}>
      <div className={css['wrapper2']}>
        <div className={css['collection-flow-step']}>
          <div className={css['header']}>
            <div className={css['header-and-info']}>
              <Label color={colors['graphite']} bold>{`${stepNumber}. ${step.name}`}</Label>
              {step.chargeType !== collectionChargeType.none && (
                <TooltipTrigger className={css['info']} tooltip={<CollectionStepTooltip step={step} />}>
                  <CoachMark info usePopup={false} size="small" />
                </TooltipTrigger>
              )}
            </div>

            <div className={css['actions']}>
              <EditIcon id={`edit-step-${stepNumber}`} onClick={() => openDialog('edit', stepNumber)} />
              <DeleteIcon id={`delete-step-${stepNumber}`} onClick={openDeleteDialog} />
            </div>
          </div>

          <Paragraph>
            {`${
              step.triggerDays
                ? i18n.getTranslation('collection_flows.amount_days', {
                    days: step.triggerDays
                  })
                : ''
            }`}
          </Paragraph>
          <div className={css['cost-communication']}>
            <div className={css['info-row']}>
              <Label>{i18n.getTranslation('collection_flows.charge_type')}</Label>
              <Paragraph>{enumReducer.getTranslation('collectionChargeType', step.chargeType)}</Paragraph>
            </div>
            <div className={css['info-row']}>
              <Label>{i18n.getTranslation('communication.communication')}</Label>
              <Paragraph>{enumReducer.getTranslation('communicationType', step.communicationType)}</Paragraph>
            </div>
          </div>
        </div>
        <Icon type="chevron-right" />
      </div>
    </div>
  );
}
