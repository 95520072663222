import React from 'react';

import { PropertyGroupRowType, PropertyGroupType } from '@zf/api-types/master-data/property-group';
import useDialog from '@zf/hooks/src/useDialog';

import AddLocationDialog from '../../../actions/property-group/add-location-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

export default function AddLocationToGroupListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('propertygroup');
  const { setUpdatedRows } = useUpdateListPage<PropertyGroupType>('propertygroup');

  const selectedRows: PropertyGroupRowType[] = myCurrent.listPage.selectedRows;

  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled = selectedRows.length !== 1;

  return (
    <DropdownAction
      id="propertygroup.update_locations"
      icon="location"
      dialogTitle={i18n.getTranslation('actions.add_location_to_property')}
      dialogContent={
        selectedRows[0] && (
          <AddLocationDialog
            ref={clickRef}
            validationRef={validationRef}
            propertyGroup={selectedRows[0].__propertyEntity}
            onComplete={setUpdatedRows}
          />
        )
      }
      disabled={disabled}
      onSubmit={onSubmit}
      validationRef={validationRef}
      dialogLocation="right"
      buttonPositive={i18n.getTranslation('actions.add_location')}
    />
  );
}
