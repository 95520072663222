import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import SupplyDateInputs from '../../shared/SupplyDateInputs/SupplyDateInputs';

const RemoveLocations = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { removeLocationsWizardStore, contract } = contractStore.moveOutWizardBaseStore;
  const { values, setValue } = removeLocationsWizardStore.removeLocationsWizardValues;
  const { supplyDate, supplyDateType_, billingCompleteness } = values;

  return (
    <SupplyDateInputs
      title={getTranslation('contracts.wizard.terminate.remove_date')}
      description={getTranslation('contracts.wizard.terminate.remove_date_descr')}
      contract={contract}
      supplyDateType_={supplyDateType_}
      supplyDate={supplyDate}
      billingCompleteness={billingCompleteness}
      setValue={setValue}
    />
  );
};

export default observer(RemoveLocations);
