import React from 'react';

import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import { ServiceConsumptionsProvider } from './context/consumptions-context';
import ServiceConsumptionsContent from './ServiceConsumptionsContent';

export type Props = {
  serviceLocation: ServiceLocationType;
  timeStamp: string;
};

export default function ServiceConsumptions(props: Props) {
  return (
    <ServiceConsumptionsProvider>
      <ServiceConsumptionsContent {...props} />
    </ServiceConsumptionsProvider>
  );
}
