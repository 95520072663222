import React from 'react';

import { ZFErrorType } from '@zf/api-types/general';

import { useAppContext } from '../../app-context';
import { getErrorTitle } from './error-boundary-class';
import css from './error-boundary-class.module.scss';

type Props = {
  error: ZFErrorType;
  valueContent: JSX.Element[];
  errorImage: JSX.Element;
};

export default function PageError(props: Props) {
  const { error, valueContent, errorImage } = props;

  const { i18n } = useAppContext();

  return (
    <>
      <div className={css['wrapper']}>
        <h1>{getErrorTitle(error, i18n)}</h1>
        {valueContent}
      </div>
      <div className={css['error-image-alternative']}>{errorImage}</div>
    </>
  );
}
