import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { IncomingMutationRowType } from '@zf/api-types/billing/incoming-mutations';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteIncomingMutation } from './incoming-mutations';

type Props = {
  selectedRows: IncomingMutationRowType[];
  refresh: () => void;
  incomingBankingTransaction: IncomingBankingTransactionType;
};

const DeleteDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, refresh, incomingBankingTransaction } = props;
  const { i18n, tenantReducer } = useAppContext();

  const isMulti = selectedRows.length > 1;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const selectedIds = selectedRows.map((r) => {
          return r.__id;
        });

        await deleteIncomingMutation(selectedIds, incomingBankingTransaction.id, tenantReducer, i18n.lang);

        refresh();

        notify.success({
          content: i18n.getTranslation(`actions.incoming_mutation.ignore_success${isMulti ? '_multi' : ''}`)
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`actions.incoming_mutation.ignore_fail${isMulti ? '_multi' : ''}`),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation(`actions.incoming_mutation.ignore_paragraph${isMulti ? '_multi' : ''}`, {
        amount: selectedRows.length
      })}
    </Paragraph>
  );
});

export default DeleteDialog;
