import React from 'react';

import { invoiceStatus, sentStatus } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import MarkAsSentDialog from '../dialogs/marksent-dialog';

export default function MarkAsSentListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<InvoiceType>('invoice');
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.find(
      (invoice) => invoice.__entity.status !== invoiceStatus.generated || invoice.__entity.sent === sentStatus.sent
    ) !== undefined;

  return (
    <DropdownAction
      id="invoice.marksent"
      icon="paper-plane"
      dialogTitle={i18n.getTranslation('actions.mark_sent')}
      dialogContent={<MarkAsSentDialog selectedRows={selectedRows} ref={clickRef} onComplete={setUpdatedRows} />}
      onSubmit={onSubmit}
      disabled={disabled}
    />
  );
}
