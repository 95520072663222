import useRefresh from 'app-context/hooks/use-refresh';
import { Button } from 'design-system/Components';
import Paragraph from 'design-system/Foundation/Paragraph/Paragraph';
import { dialog } from 'events/dialog-events';
import Customers from 'features/customer/list-page/Customers';
import { useStore } from 'hooks/useStore';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';
import css from './table-no-data.module.scss';

const NoDataOverlayWithAdd = (props: any) => {
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { queryParams } = props;
  const { refresh } = useRefresh();

  const openAddDialog = () => {
    dialog.normalBig({
      title: i18n.getTranslation('customer_groups.add_customers'),
      icon: 'plus',
      content: (
        <Customers
          withListPage
          withActions={false}
          location={window.location}
          ref={clickRef}
          onComplete={refresh}
          queryParam={{ ...queryParams }}
        />
      ),
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <div>
      <CardEmptyBody
        key="no-attributes"
        image={
          <img
            src="https://cdn.zerofriction.co/shared/assets/emptyStates/customers.png"
            alt="customers"
            title="customers"
            width="70"
            height="70"
          />
        }
        title={i18n.getTranslation('customer_groups.empty_customers')}
      >
        {!queryParams.searchValue && (
          <>
            <Paragraph className={css['no-customers-yet-label']}>
              {i18n.getTranslation('customer_groups.you_have_not_added_any_customers_yet')}
            </Paragraph>

            <div className={css['footer']}>
              <Button id="attachment.add" type="primary" icon="plus" onClick={() => openAddDialog()}>
                {i18n.getTranslation('customer_groups.add_customers')}
              </Button>
            </div>
          </>
        )}

        {queryParams.searchValue && (
          <>
            <Paragraph className={css['no-customers-yet-label']}>
              {i18n.getTranslation('customer_groups.no_customers')}
            </Paragraph>
          </>
        )}
      </CardEmptyBody>
    </div>
  );
};

export default NoDataOverlayWithAdd;
