import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer } from 'recharts';

import { aggregationFrequency } from '@zf/api-types/enums';
import { ConsumptionGraphType } from '@zf/api-types/graph';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import { groupBy } from '@zf/utils/src/array';
import { colors } from '@zf/utils/src/color';
import { formatDateTime } from '@zf/utils/src/date';
import { formatDecimal } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../../app-context/app-context';
import useAxis from '../../../../../../../app-context/hooks/use-axis';
import { ZFGraphTooltip } from '../../../../../../../components/graph/ZFGraphTooltip';
import { determineAxisId, sortLongestRangeFirst } from '../../../../../../../utils/graph';
import { getColorByUtilityType } from '../../../../../../../utils/meter';
import useGraph from '../../shared-hooks/useGraph';
import useQueryParams from '../../shared-hooks/useQueryParams';
import css from './graph.module.scss';
import useRegularGraph from './hooks/useRegularGraph';
import useRegularGraphFilters from './hooks/useRegularGraphFilters';

export type GraphProps = {
  domainStart: number;
  domainEnd: number;
};

export function Graph(props: GraphProps) {
  const { domainStart, domainEnd } = props;

  const { i18n, enumReducer } = useAppContext();

  moment.locale(i18n.lang);

  const { groupByPeriod } = useQueryParams();
  const { selectedGraphChannels, isLoading } = useGraph();
  const { consumptionsChannels } = useRegularGraphFilters();
  const { graphValues } = useRegularGraph();

  const selectedConsumptionChannels = consumptionsChannels.filter((cChann) => {
    return selectedGraphChannels.includes(cChann.externalIdentifier);
  });

  // This fixes the bar chart range scaling, first array length defines the range slicing
  graphValues.sort(sortLongestRangeFirst);

  const groupedByUom = groupBy(graphValues, 'uom');

  // (Same units for the compared values)
  const selectedUnits: string[] = Object.keys(groupedByUom).map((key) => {
    return groupedByUom[key][0].uom;
  });

  /**
   * Consumptions only
   */
  const getChartData = () => {
    if (isLoading) {
      return [];
    }

    // When switching from "none" groupByPeriod has changed but our new values are still awaited from the api,
    // the groupByPeriod on our value object can be used to prevent render blocking
    if (graphValues[0] && graphValues[0].groupByPeriod === aggregationFrequency.none) {
      return [];
    }

    // Don't allow measurements to be grouped!
    const graphVals = [...graphValues].filter((g) => {
      return g.type !== 'measurements';
    });

    const chartData: ChartDataType[] = [];

    if (graphVals.length > 0 && groupByPeriod !== aggregationFrequency.none) {
      // Go over our first set's data entries
      graphVals[0].rawData.forEach((e) => {
        const res = {} as any;

        res[graphVals[0].description] = e.value;

        chartData.push({ ...res, time: e.startDateTime });
      });

      // Add the rest, lenghts of the data entry arrays are the same, we can match the entry indexes
      graphVals.forEach((gv, index) => {
        // Skip the first one, this was done above
        if (index !== 0) {
          gv.rawData.forEach((e, dataEntryIndex) => {
            if (chartData[dataEntryIndex]) chartData[dataEntryIndex][gv.description] = e.value;
          });
        }
      });
    }

    return chartData;
  };

  const renderChartContent = () => {
    const charts: JSX.Element[] = [];

    if (!isLoading) {
      // Consumptions
      if (groupByPeriod !== aggregationFrequency.none) {
        for (const channel of selectedConsumptionChannels) {
          const key = channel.description ? channel.description : channel.externalIdentifier;
          const unit = enumReducer.getTranslation('unitOfMeasure', channel.unitOfMeasure);

          charts.push(
            <Bar
              key={key}
              dataKey={key}
              yAxisId={groupByPeriod}
              barSize={50}
              fill={getColorByUtilityType(channel.utilityType)}
              radius={[5, 5, 0, 0]}
              unit={` ${unit}`}
            />
          );
        }
      } else {
        // Measurements
        selectedUnits.forEach((uom) => {
          groupedByUom[uom].forEach((filter: ConsumptionGraphType) => {
            const unit = enumReducer.getTranslation('unitOfMeasure', filter.uom);

            charts.push(
              <Line
                key={determineAxisId(filter.uom, filter.description, filter.type)}
                yAxisId={uom}
                type="linear"
                dataKey="value"
                data={filter.rawData}
                name={filter.description}
                dot={false}
                unit={` ${unit}`}
                stroke={getColorByUtilityType(filter.utilityType)}
              />
            );
          });
        });
      }
    }

    return charts;
  };

  const axises = useAxis(groupByPeriod, domainStart, domainEnd, selectedUnits, 'time');

  const tooltipLabelFormatter = (value: string) => {
    switch (groupByPeriod) {
      case aggregationFrequency.yearly:
        return `${moment(value).year()}`;
      case aggregationFrequency.monthly:
        return moment(value).format('MMM/YYYY');
      case aggregationFrequency.none:
        return formatDateTime(value);
      case aggregationFrequency.daily:
        return moment(value).format('DD/MMM/YYYY');
    }
  };

  const valueFormatter = (value: string) => {
    return formatDecimal(parseFloat(value), i18n.culture);
  };

  return (
    <div key="graph-wrapper" className={classNames(css['graph-wrapper'])}>
      <ResponsiveContainer key="responsive-container" className={css['graph-container']} width="99%" height="100%">
        <ComposedChart key="composed-chart" data={getChartData()} margin={{ top: 10, left: 25, right: 25 }}>
          <CartesianGrid key="grid-lines" strokeDasharray="3 3" vertical={false} stroke={colors['silver-400']} />
          {axises}
          {renderChartContent()}
          <ZFGraphTooltip key="tooltip" labelFormatter={tooltipLabelFormatter} formatter={valueFormatter} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
