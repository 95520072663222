import clone from 'clone';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { UpdateCustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { customEntityPropertyValueType, entitySubjectType, unitOfMeasure } from '@zf/api-types/enums';
import { UpdateConsumptionUnitTypeType } from '@zf/api-types/master-data/consumption-unit';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useCultureTable from '../../../app-context/hooks/use-culture-table';
import useTranslations from '../../../app-context/hooks/use-translations';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import CommitSection from '../../../components/config/commit-section';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import LanguageIcon from '../../../components/Icon/LanguageIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import {
  DataTypeDropdown,
  EntitySubjectTypeDropdown,
  InlineUoMDropdown
} from '../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../hooks/useStore';

type Props = {
  config: UseConfigReturnType<UpdateCustomEntityPropertyType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdateCustomEntityPropertyType[]>,
    React.Dispatch<ValidatorCtxAction<UpdateCustomEntityPropertyType[]>>
  ];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

const getDTOType = (valueType: string | undefined) => {
  switch (valueType) {
    case 'string':
      return 'BasePropertyValueTypeDTO.String';
    case 'boolean':
      return 'BasePropertyValueTypeDTO.Boolean';
    case 'number':
      return 'BasePropertyValueTypeDTO.Number';
    case 'decimal':
      return 'BasePropertyValueTypeDTO.Decimal';
    case 'datetime':
      return 'BasePropertyValueTypeDTO.DateTime';
  }
};

const createApiFriendlyValues = (property: UpdateCustomEntityPropertyType) => {
  return {
    name: property.name,
    description: property.description,
    entitySubjectType: property.entitySubjectType,
    valueType: { ...property.valueType, type: getDTOType(property.valueType?.type) }
  };
};

const CustomEntityPropertiesContent = (props: Props) => {
  const { config } = props;
  const { sort } = config;
  const { configStore, applicationStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation } = applicationStore;

  const { hasMultipleLanguages } = useCultureTable();
  const { openTranslationsDialog } = useTranslations<UpdateCustomEntityPropertyType>('CustomEntityPropertyTypes');

  const [entitySubjectTypes] = useState(
    getEnum<entitySubjectType>('entitySubjectType').filter(
      (est) =>
        est.value === entitySubjectType.servicelocation ||
        est.value === entitySubjectType.propertygroup ||
        est.value === entitySubjectType.collectioncase ||
        est.value === entitySubjectType.contract ||
        est.value === entitySubjectType.customer ||
        est.value === entitySubjectType.meter
    )
  );

  const tableColumns: ColumnType[] = [
    {
      label: getTranslation('general.name'),
      dataKey: 'name',
      flexWidth: 2
    },
    {
      label: getTranslation('general.description'),
      dataKey: 'description',
      flexWidth: 2
    },
    {
      label: getTranslation('general.entity'),
      dataKey: 'entitySubjectType',
      flexWidth: 1
    },
    {
      label: getTranslation('general.data_type'),
      dataKey: 'type',
      flexWidth: 1
    },
    {
      label: getTranslation('parameters.unitOfMeasure'),
      dataKey: 'unitOfMeasure',
      flexWidth: 1
    }
  ];

  if (hasMultipleLanguages) {
    tableColumns.push({
      width: 50,
      dataKey: 'languageAction'
    });
  }

  tableColumns.push({
    width: 50,
    dataKey: 'deleteAction'
  });

  const { values, isDirty, selectedIndex, setValue, restoreValues, setSelectedIndex } = useContextValidator<
    UpdateCustomEntityPropertyType[]
  >(props.useTracked);

  const { addEntity, deleteEntity, handleSave, onCompleteLangUpdate } = useContextCRUD(props.useTracked);

  const addType = () => {
    addEntity({
      name: '',
      description: '',
      entitySubjectType: '' as entitySubjectType,
      valueType: { type: '' as customEntityPropertyValueType }
    });
  };

  const handleTypesSave = async () => {
    configStore.setRefetchCustomEntityPropertyTypes(true);

    return handleSave(
      createApiFriendlyValues,
      'name',
      '/cfg/CustomEntityPropertyTypes',
      getTranslation('custom_entity_properties.success_update'),
      getTranslation('custom_entity_properties.error_update')
    );
  };

  const setProperty = (index: number, value: Partial<UpdateCustomEntityPropertyType>) => {
    const clonedArray = clone(values);
    clonedArray[index] = { ...clonedArray[index], ...value };

    setValue({
      values: clonedArray as UpdateConsumptionUnitTypeType[]
    });
  };

  const typeRows = values.map((property: UpdateCustomEntityPropertyType, index: number) => {
    return {
      name: (
        <InlineInputFieldInput
          id={`property.name.index-${index}`}
          value={property.name}
          onChange={(name) => setProperty(index, { name })}
          error={!property.name}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!property.id}
        />
      ),
      description: (
        <InlineInputFieldInput
          id={`property.description.index-${index}`}
          value={property.description}
          onChange={(description) => setProperty(index, { description })}
          error={!property.description}
        />
      ),
      entitySubjectType: (
        <EntitySubjectTypeDropdown
          id={`property.entitySubjectType.index-${index}`}
          values={entitySubjectTypes}
          selectedValues={[property.entitySubjectType || ('' as entitySubjectType)]}
          onChange={(val) => setProperty(index, { entitySubjectType: val[0] || '' })}
          error={!property.entitySubjectType}
        />
      ),
      type: property.id ? (
        getEnumTranslation(
          'customEntityPropertyValueType',
          property.valueType?.type?.split('.')[1].toLowerCase() as customEntityPropertyValueType
        )
      ) : (
        <DataTypeDropdown
          id={`property.type.index-${index}`}
          values={getEnum('customEntityPropertyValueType')}
          selectedValues={[property.valueType ? property.valueType.type : ('' as customEntityPropertyValueType)]}
          onChange={(val) => {
            let newValue = { ...property.valueType, type: val[0] || '' };

            if (
              (val[0] === customEntityPropertyValueType.boolean || val[0] === customEntityPropertyValueType.string) &&
              typeof property.valueType?.unitOfMeasure !== 'undefined'
            ) {
              // UnitOfMeasure can still be there when changing type so we remove it
              delete newValue.unitOfMeasure;
            } else {
              newValue = { ...property.valueType, type: val[0] || '', unitOfMeasure: '' as unitOfMeasure };
            }

            setProperty(index, {
              valueType: newValue
            });
          }}
          error={!property.valueType?.type}
        />
      ),
      unitOfMeasure:
        (property.valueType?.type.toLowerCase() === 'decimal' ||
          property.valueType?.type.toLowerCase() === 'number' ||
          property.valueType?.type === customEntityPropertyValueType.decimal ||
          property.valueType?.type === customEntityPropertyValueType.number) &&
        typeof property.valueType?.unitOfMeasure !== 'undefined' ? (
          <InlineUoMDropdown
            id={`property.unitOfMeasure.index-${index}`}
            values={getEnum('unitOfMeasure', false)}
            selectedValues={[property.valueType ? property.valueType.unitOfMeasure : ('' as unitOfMeasure)]}
            onChange={(val) => {
              if (property.valueType) {
                setProperty(index, { valueType: { ...property.valueType, unitOfMeasure: val[0] || '' } });
              }
            }}
            error={!property.valueType?.unitOfMeasure}
          />
        ) : (
          getTranslation('general.na')
        ),
      languageAction: (
        <LanguageIcon
          id={`type.update_language.index-${index}`}
          tooltipFor="entity-properties-table"
          localisationLevel_={property.localisationLevel}
          hasMultipleLanguages={hasMultipleLanguages}
          action={() => openTranslationsDialog(index, property, onCompleteLangUpdate, 'big')}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`property.delete.index-${index}`}
          tooltipFor="entity-properties-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleTypesSave} isDirty={isDirty} />
      <CardsContainer>
        <Card width="3" id="entity-properties-card">
          <CardHeader
            extraRight={
              <Button id="custom_entity_properties.add" type="text" icon="plus" onClick={addType}>
                {getTranslation('general.add')}
              </Button>
            }
          >
            {getTranslation('settings.custom_entity_properties')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="entity-properties-table"
              rows={typeRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
            />
          </CardBody>
        </Card>
      </CardsContainer>
    </>
  );
};

export default observer(CustomEntityPropertiesContent);
