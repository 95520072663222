import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { EntityAttachmentGroup } from '@zf/api-types/entity-attachments/entity-attachments';
import Flag from '@zf/stella-react/src/atoms/Flag/Flag';

import { attachmentType } from '../../../../components/units/EntityAttachments/logic/FilesStore';
import Files from '../../../../components/units/EntityAttachments/ui/Files';
import { useStore } from '../../../../hooks/useStore';
import { getCultureFlag } from '../../../../utils/lang';
import css from './property-group-attachments.module.scss';

type Props = {
  entityId: string;
};

const PropertyGroupAttachments = (props: Props) => {
  const { entityId } = props;
  const { propertyGroupStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { filesStore } = propertyGroupStore;
  const { listFiles } = propertyGroupStore.entityAttachmentFeatures;

  const [specificFileCols] = useState([
    {
      width: 300,
      label: getTranslation('general.languages'),
      dataKey: 'languages'
    }
  ]);

  const processSpecificCols = (entityAttachment: EntityAttachmentGroup) => {
    return {
      languages: (
        <div className={css['attachment-flags']}>
          {entityAttachment.localisedAttachmentFiles.map((att, index) => {
            return (
              <Flag key={`${att.fileName}-${att.culture}-${index}`} size="small" url={getCultureFlag(att.culture)} />
            );
          })}
        </div>
      )
    };
  };

  return (
    <Files
      key={entityId}
      listFiles={listFiles}
      attachmentType={attachmentType.entityattachmentgroups}
      filesStore={filesStore}
      entityId={entityId}
      specificCols={specificFileCols}
      processSpecificCols={processSpecificCols}
    />
  );
};

export default observer(PropertyGroupAttachments);
