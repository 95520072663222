import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { MeterPropertiesPrepayment, MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import EmptyStateImg from '@zf/stella-react/src/atoms/Image/EmptyState/EmptyStateImg';
import { betweenDates } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { Link } from '../../../design-system/Components';
import { Paragraph } from '../../../design-system/Foundation';
import MeterAddress from '../../../features/devices/meter/detail-page/detail/MeterAddress';
import { useStore } from '../../../hooks/useStore';
import css from '../cash-position-body.module.scss';

type Props = {
  meters?: MeterType[];
};

const PrePaymentDevices = (props: Props) => {
  const { applicationStore, configStore } = useStore();
  const { rootUrl, culture, getTranslation } = applicationStore;
  const { getMeterModels } = configStore.configService;
  const { meters } = props;

  const [models, setModels] = useState<ModelType[]>([]);

  useEffect(() => {
    getMeterModels().then((res) => setModels(res));
  }, []);

  const [columns] = useState([
    {
      width: 250,
      label: getTranslation('general.device'),
      dataKey: 'device'
    },
    {
      width: 250,
      label: getTranslation('meter.brand'),
      dataKey: 'brand'
    },
    {
      width: 250,
      label: getTranslation('location.location'),
      dataKey: 'location'
    },
    {
      width: 250,
      label: getTranslation('prepayment_device.balance'),
      dataKey: 'balance',
      headerAlignRight: true
    }
  ]);

  const rows = meters?.map((m) => {
    const model = models.find((model) => model.id === m.modelId);

    const activeState = m.statusHistory.find((status) => {
      return (
        betweenDates(status.startDateTime, status.endDateTime, moment()) ||
        (moment(status.endDateTime).year() === 10000 && moment().isBefore(status.startDateTime)) // install date in future
      );
    });

    const meterProperties = m.meterProperties as MeterPropertiesPrepayment;

    return {
      __id: m.id,
      __entity: m,
      device: (
        <Link id={`link-${m.serialNumber}`} url={`${rootUrl}/devices/prepayment-devices/${m.id}`}>
          {m.serialNumber}
        </Link>
      ),
      brand: model?.brandName,
      location: <MeterAddress id={`${m.id}-address`} tooltipFor="meters-table-id" activeState={activeState} />,
      balance: (
        <Paragraph className={css['prepayment-device-balance']}>
          {formatMoney(meterProperties.currentBalance, culture)}
        </Paragraph>
      )
    };
  });

  if (rows?.length === 0) {
    return (
      <CardEmptyBody
        icon={<EmptyStateImg title="contact" image="meter" />}
        title={getTranslation('prepayment_device.prepayment_devices_empty')}
      >
        {getTranslation('meter.prepayment_devices_empty_info')}
      </CardEmptyBody>
    );
  } else {
    return (
      <DynamicVirtualTable
        id="prepayment-table"
        tooltipId="prepayment-table-tip"
        rowHeight={50}
        loading={!meters}
        rows={rows || []}
        columns={columns}
        noSelect
      />
    );
  }
};

export default observer(PrePaymentDevices);
