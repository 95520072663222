import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import ChangeCustomerGroupDialog from '../Dialogs/ChangeCustomerGroupDialog';
import { CustomerRowType } from '@zf/api-types/master-data/customer';
import useCurrent from 'app-context/hooks/use-current';

type Props = { onComplete: Function };

export default function ChangeCustomerGroupListItem({ onComplete }: Props) {
  const { clickRef, onSubmit } = useDialog();
  const { applicationStore } = useStore();
  const { validationRef } = useDialog();
  const { getTranslation } = applicationStore;
  const { myCurrent } = useCurrent('customer');

  const selectedRows: CustomerRowType[] = myCurrent.listPage.selectedRows;

  return (
    <DropdownAction
      id="customer_groups.add"
      dialogTitle={
        selectedRows.filter(({ customerGroup }) => customerGroup !== ' ').length === 0
          ? getTranslation('customer_groups.add_to_customer_group')
          : getTranslation('customer_groups.change_customer_group')
      }
      dialogContent={
        <ChangeCustomerGroupDialog
          ref={clickRef}
          validationRef={validationRef}
          selectedRows={selectedRows}
          onComplete={onComplete}
          withCustomerList
        />
      }
      onSubmit={onSubmit}
      icon="customer-group"
      validationRef={validationRef}
      disabled={selectedRows.length === 0}
      buttonPositive={applicationStore.getTranslation('general.add')}
    />
  );
}
