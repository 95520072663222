import { Paragraph } from 'design-system/Foundation';
import { Moment } from 'moment';
import React from 'react';

import { AddMeasurementRowType, MeasurementType, AdjacentMeasurementPairs } from '@zf/api-types/master-data/meter';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../../../../app-context';
import useSuspenseSingleAPI from '../../../../../hooks/useSuspenseSingleAPI';
import Channel from './channel';
import css from './terminate-style.module.scss';

type Props = {
  index: number;
  measurementRow: AddMeasurementRowType;
  hasMeasurement: boolean;
  mutationDateTime: Moment;
  measurementRows: AddMeasurementRowType[];
  setMeasurementValue: (index: number, channelIndex: number, val: number) => void;
};

export default function MeasurementRow(props: Props) {
  const { index, measurementRow, hasMeasurement, mutationDateTime, measurementRows, setMeasurementValue } = props;

  const { i18n } = useAppContext();

  const measurementsResponse = useSuspenseSingleAPI<AdjacentMeasurementPairs>({
    request: {
      endpoint: `/me/Measurements/m/${measurementRow.meter.id}`,
      query: { measurementDate: mutationDateTime.toISOString() }
    }
  });

  if (!measurementsResponse.result || !hasMeasurement) return null;

  const res = measurementsResponse.result.data;

  const channels = measurementRow.channels.map((chan, chanIndex) => {
    let matchingPrevious: MeasurementType | null = null;

    const matchedChannel = res.pairs.find((p) => p.externalChannelId === chan.channel.externalIdentifier);

    if (matchedChannel) {
      matchingPrevious = matchedChannel.previousMeasurement;
    }

    const valueToCheck = measurementRows[index].channels[chanIndex].value;

    return (
      <Channel
        index={index}
        key={`${index}-${chanIndex}`}
        chanIndex={chanIndex}
        channel={chan}
        valueToCheck={valueToCheck}
        matchingPrevious={matchingPrevious}
        setMeasurementValue={setMeasurementValue}
      />
    );
  });

  return (
    <div className={css['terminate-add-measurement']}>
      <div className={css['address-meter']}>
        {measurementRow.location.serviceLocationAddress?.localizedDisplay && (
          <Paragraph bold>{measurementRow.location.serviceLocationAddress.localizedDisplay}</Paragraph>
        )}
        <Paragraph bold>{`${i18n.getTranslation('meter.meter')}: ${measurementRow.meter.serialNumber}`}</Paragraph>
      </div>
      <br />
      <InputContainer className={css['terminate-add-measurement-input-container']}>{channels}</InputContainer>
    </div>
  );
}
