import React from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';

type State = {
  lastToggle: number;
  shown: boolean;
};

type Dispatch = {
  lastToggle?: number;
  shown?: boolean;
};

export default function usePopupTrigger() {
  const ref: React.RefObject<HTMLDivElement> = React.createRef();

  const reducer = createStateReducer<State, Dispatch>();
  const [{ lastToggle, shown }, dispatchShown] = React.useReducer(reducer, {
    lastToggle: 0,
    shown: false
  });

  const togglePopup = () => {
    dispatchShown({
      lastToggle: Date.now(),
      shown: !shown
    });
  };

  const toggleShowPopup = () => {
    dispatchShown({
      lastToggle: Date.now(),
      shown: true
    });
  };

  const toggleRemovePopup = () => {
    dispatchShown({
      lastToggle: Date.now(),
      shown: false
    });
  };

  React.useEffect(() => {
    if (lastToggle === 0) return;

    const eventListener = (e: MouseEvent) => {
      if (!ref || !ref.current) return;

      const container = ref.current;

      // User clicking next to the popup within 100ms doesn't count...
      if (Date.now() - lastToggle > 100 && shown) {
        const boundingRect = container.getBoundingClientRect();
        const popup =
          e.x > boundingRect.x &&
          e.x < boundingRect.x + boundingRect.width &&
          e.y > boundingRect.y &&
          e.y < boundingRect.y + boundingRect.height;

        if (!popup) {
          toggleRemovePopup();
        }
      }
    };

    window.addEventListener('click', eventListener);

    return () => window.removeEventListener('click', eventListener);
  }, [shown, ref]);

  return { showPopup: shown, popupRef: ref, togglePopup, toggleShowPopup, toggleRemovePopup };
}
