import moment, { Moment } from 'moment';
import { useLayoutEffect, useReducer, useState } from 'react';

import { createStateReducer } from './stateReducer';

type ReturnValue = {
  reset: () => void;
  pause: () => void;
  resume: () => void;
};

type State = {
  start: Moment | null;
  remaining: number;
  timerId: NodeJS.Timeout | undefined;
};

type SetState = { start?: Moment | null; remaining?: number; timerId?: NodeJS.Timeout };

export default function useTimeout(cb: () => void, timeoutDelayMs = 0): ReturnValue {
  const stateReducer = createStateReducer<State, SetState>();
  const [state, setState] = useReducer(stateReducer, {
    start: null,
    remaining: timeoutDelayMs,
    timerId: undefined
  });

  const [mode, setMode] = useState('paused');

  const pause = () => {
    setMode('paused');
  };

  const resume = () => {
    setMode('resume');
  };

  const reset = () => {
    setMode('reset');
  };

  useLayoutEffect(() => {
    let timerId = state.timerId;
    const remaining = state.remaining;
    let start = state.start;

    switch (mode) {
      case 'paused':
        if (timerId && state.start) {
          clearTimeout(timerId);
          timerId = undefined;
          start = null;
          setState({ start: start, timerId: timerId, remaining: state.remaining - moment().diff(state.start, 'ms') });
        }
        break;
      case 'reset':
        if (state.timerId) clearTimeout(state.timerId);
        timerId = undefined;
        start = null;
        setState({ start: start, timerId: timerId, remaining: timeoutDelayMs });
        break;
      case 'resume':
      default:
        timerId = setTimeout(cb, remaining);
        start = moment();
        setState({ start: start, timerId: timerId });
        break;
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [mode]);

  return {
    pause,
    resume,
    reset
  };
}
