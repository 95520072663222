import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { APIExportJobType, ExportJobType } from '../../../../../api-types/export-job';
import TransferJobDialog from '../../../actions/export-jobs/transfer-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  exportJob: ExportJobType;
};

export default function TransferExportJobListItem(props: Props) {
  const { exportJob } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const exportJobToHandle: APIExportJobType = {
    etag: exportJob._etag,
    id: exportJob.id
  };

  return (
    <DropdownAction
      id="export_jobs.transfer"
      icon="export"
      dialogTitle={i18n.getTranslation('actions.export_jobs.transfer')}
      dialogContent={<TransferJobDialog ref={clickRef} exportJobToHandle={exportJobToHandle} />}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('actions.export_jobs.transfer')}
    />
  );
}
