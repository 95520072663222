import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import useSelectionNavigator from '../../../../app-context/hooks/use-selection-navigator';
import { entityTypes } from '../../../../app-context/stores/ui-stores/UiStore';
import { useStore } from '../../../../hooks/useStore';
import InvoiceCrumbHeader from './components/invoice-header';
import InvoiceTabContainer from './containers/InvoiceTabContainer';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

const Invoice = observer((props: Props) => {
  const { urlParams, navigate } = props;
  const { invoiceStore } = useStore();
  const navigator = useSelectionNavigator(urlParams.invoiceID, entityTypes.invoice);

  useEffect(() => {
    // Fetch entity when id changes
    invoiceStore.loadInvoiceDetailData(urlParams.invoiceID);
  }, [urlParams.invoiceID]);

  useEffect(() => {
    // Clear entity when you leave the detail page
    return () => invoiceStore.setNewInvoice(null);
  }, []);

  return (
    <AsyncVarSpinner variables={[invoiceStore.selectedInvoice_]}>
      <InvoiceCrumbHeader />
      <InvoiceTabContainer key={invoiceStore.selectedInvoice_?.invoice.id} navigator={navigator} navigate={navigate} />
    </AsyncVarSpinner>
  );
});

export default Invoice;
