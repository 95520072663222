import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import ExportServiceConsumptionsDialog from '../../../actions/location/serviceconsumptions-export/export-service-consumptions-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  serviceLocationId: string;
};

export default function ExportServiceConsumptionsListItem(props: Props) {
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="location.consumptions_export"
      icon="exel"
      dialogTitle={i18n.getTranslation('actions.meter.export_consumptions')}
      dialogContent={<ExportServiceConsumptionsDialog ref={clickRef} {...props} />}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('export_jobs.export')}
    />
  );
}
