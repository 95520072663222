import React, { createContext, FC, ReactNode, useContext } from 'react';

import { createListPageStore } from './createListPageStore';
import { ListPageContext, ListPageStore } from '../../../../../components/listPageV2/types';
import { useListPageProvider } from './useListPageProvider';
import { TransactionType } from '@zf/api-types/transactions';

export const store = createListPageStore(
  {
    search: undefined,
    createdStartDate: undefined,
    createdEndDate: undefined
  },
  'all',
  {
    all: 0,
    toBeProcessed: 0
  },
  {
    refreshKey: null
  }
);

export const TransactionsContext = createContext<ListPageContext>({
  store,
  getSidePanelData: () => undefined,
  setFilter: (filters: ListPageStore['filter']) => {},
  setListPage: (list: ListPageStore['listPage']) => {},
  updateRows: (updatedRowEntities: TransactionType[]) => {},
  closeSidePanel: () => {}
});

export const useTransactionsContext = () => useContext(TransactionsContext);

export const useRefreshListPage = () => {
  const ctx = useContext(TransactionsContext);

  return {
    setRefresh: (refreshKey: string) => {
      return ctx.setListPage({ ...ctx.store.listPage, state: { refreshKey } });
    }
  };
};
export const TransactionsProvider: FC<ReactNode> = ({ children }) => {
  const listPageState = useListPageProvider(store);

  return <TransactionsContext.Provider value={listPageState}>{children}</TransactionsContext.Provider>;
};
