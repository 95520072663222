import moment from 'moment';
import React from 'react';

import { meterStatus } from '@zf/api-types/enums';
import { MeterType } from '@zf/api-types/master-data/meter';
import { ExclamationBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../app-context';
import { betweenDates, formatDate } from '@zf/utils/src/date';
import css from '../wizard/location-wizard.module.scss';

type Props = {
  meter: MeterType;
};

export default function MeterWarnings(props: Props) {
  const { meter } = props;

  const { i18n } = useAppContext();
  const today = moment();

  return (
    <>
      {meter.statusHistory
        .filter((status) => {
          return (
            betweenDates(status.startDateTime, status.endDateTime, today) &&
            status.meterStatus === meterStatus.installed
          );
        })
        .map((installedStatus, statusIndex) => {
          return (
            <div key={`${installedStatus.propertyGroup?.id}-${statusIndex}`} className={css['problem']}>
              <ExclamationBadge
                type="error"
                title={i18n.getTranslation(
                  'location.meter_error',
                  meter
                    ? {
                        meterType: meter.meterType,
                        serialNumber: meter.serialNumber,
                        modelId: meter.modelId,
                        lastTimeReceivedData: meter.lastTimeReceivedData,
                        discriminator: meter.discriminator,
                        organisationId: meter.organisationId,
                        id: meter.id
                      }
                    : {}
                )}
              >
                {i18n.getTranslation('meter.already_installed_warning', {
                  startDate: formatDate(installedStatus.startDateTime),
                  endDate: formatDate(installedStatus.endDateTime),
                  address: installedStatus.installedAtAddress?.localizedDisplay || ''
                })}
              </ExclamationBadge>
            </div>
          );
        })}
    </>
  );
}
