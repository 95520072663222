import { culture, uiCulture } from '@zf/api-types/enums';

export function templateStrings(str: string, params: Record<string, string | number>): string {
  try {
    // Convert parameter keys to lowercase
    const lowerParams: Record<string, string | number> = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    // Replace placeholders in a case-insensitive manner
    const replacedStr = str.replace(/\${(.*?)}/g, (match, p1) => {
      const key = p1.toLowerCase();
      return lowerParams.hasOwnProperty(key) ? lowerParams[key].toString() : match;
    });

    return replacedStr;
  } catch (e) {
    console.warn('Could not create templateString:', e.message);
    return str;
  }
}

export const cultureFlags: Record<string, string> = {
  en: 'https://zfcdnstorage.blob.core.windows.net/shared/assets/langIcons/en.svg',
  nl: 'https://zfcdnstorage.blob.core.windows.net/shared/assets/langIcons/nl.svg',
  be: 'https://zfcdnstorage.blob.core.windows.net/shared/assets/langIcons/be.svg',
  fr: 'https://zfcdnstorage.blob.core.windows.net/shared/assets/langIcons/fr.svg',
  fi: 'https://zfcdnstorage.blob.core.windows.net/shared/assets/langIcons/fi.svg',
  de: 'https://zfcdnstorage.blob.core.windows.net/shared/assets/langIcons/de.svg'
};

export const getCultureFlag = (culture_: culture) => {
  const code = getUiCulture(culture_);
  return cultureFlags[code];
};

// Only use this for getCultureFlag
export const getUiCulture = (culture_: any): uiCulture => {
  const fallbackLanguage = uiCulture.en;
  try {
    if (typeof culture_ == 'undefined' || culture_ === '' || culture_ === null) return fallbackLanguage; //fallback
    const splitted = culture_.split('-');
    if (!Array.isArray(splitted)) return fallbackLanguage; //fallback
    const code = splitted?.[0];
    if (typeof code === 'string' && code.length === 2) {
      return code as uiCulture;
    } else {
      return fallbackLanguage;
    }
  } catch (error) {
    return fallbackLanguage; //fallback
  }
};
