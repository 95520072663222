import React, { useEffect, useState } from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import MeasurementDialog from '../../../../../../../actions/meters/add-measurement/measurement-dialog';
import ConfirmationActionButton from '../../../../../../../components/Button/ConfirmationActionButton';
import { useStore } from '../../../../../../../hooks/useStore';
import css from './measurement-button.module.scss';

type Props = {
  id: string;
  meterSerialNr: string;
  locationId: string;
  channelId: string;
  measurementDate: string;
  isOptional?: boolean;
};

export default function AddMissingMeasurementButton(props: Props) {
  const { id, locationId, meterSerialNr, channelId, isOptional, measurementDate } = props;
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { refreshTimeOutAsync, completenessApiService } = contractStore.billingCompletenessStore;

  const [potentialMeter, setPotentialMeter] = useState<MeterType | null>(null);

  const { validationRef, clickRef, onSubmit } = useDialog();

  useEffect(() => {
    completenessApiService
      .getPotentialMeters(locationId)
      .then((meters) => {
        const match = meters.find((m) => m.serialNumber === meterSerialNr);
        setPotentialMeter(match || null);
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  }, []);

  const meterToHandle = {
    meter: potentialMeter,
    channelId: channelId,
    endDateTime: measurementDate,
    value: undefined,
    hour: '0',
    minute: '0'
  };

  const title = getTranslation(isOptional ? 'invoice.add_optional_msmt' : 'actions.add_measurement');

  return (
    <ConfirmationActionButton
      id={id}
      className={css['missing-msmt-btn']}
      icon="plus"
      type="text"
      dialogTitle={title}
      dialogContent={
        <MeasurementDialog
          locationId={locationId}
          ref={clickRef}
          validationRef={validationRef}
          meterToHandle={meterToHandle}
        />
      }
      onSubmit={async () => {
        await onSubmit();
        refreshTimeOutAsync();
      }}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={getTranslation('actions.add_measurement')}
    />
  );
}
