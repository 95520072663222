import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { Paragraph } from 'design-system/Foundation';
import React, { useEffect, useState } from 'react';
import css from './show-muting-rules-dialog.module.scss';
import Interweave from 'interweave';
import { Button, EmptyState } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { MutingRuleAggregatorDTO, MutingRuleDTO } from '@zf/api-types/master-data/meter';
import { MutingRules } from 'features/devices/meter/detail-page/detail/MutingRules';
import { notify } from 'events/notification-events';

export const ShowMutingRulesDialog = ({ refresh }: { refresh: () => void }) => {
  const { applicationStore, meterStore } = useStore();
  const { mutingRuleService } = meterStore;
  const { getAllMutingRules, cancelMutingRulesByValues, getMetersIdsUnderRule } = mutingRuleService;
  const { getTranslation, rootUrl } = applicationStore;

  const [state, setState] = useState({
    expiredRulesVisible: false
  });

  const [activeMutingRules, setActiveMutingRuleState] = useState<{
    activeMutingRules: MutingRuleAggregatorDTO[];
  }>({
    activeMutingRules: []
  });

  const [expiredMutingRules, setExpiredMutingRuleState] = useState<{
    expiredMutingRules: MutingRuleAggregatorDTO[];
  }>({
    expiredMutingRules: []
  });

  const getMutingRules = () => {
    getAllMutingRules(true, false).then((data) => {
      setActiveMutingRuleState({ activeMutingRules: data });
    });

    getAllMutingRules(false, true).then((data) => {
      setExpiredMutingRuleState({ expiredMutingRules: data });
    });
  };

  const onCancel = async <T,>(params: T) => {
    const { errorType, timePeriod, muteFromWithNumericValue, muteUntilWithNumericValue, message, userId } =
      params as unknown as MutingRuleDTO;

    try {
      getMetersIdsUnderRule(
        muteFromWithNumericValue,
        muteUntilWithNumericValue,
        errorType,
        message || '',
        timePeriod,
        userId
      )
        .then(async (meterIds) => {
          await cancelMutingRulesByValues(errorType, timePeriod, meterIds);
          getMutingRules();
          refresh();
          notify.success({
            content: getTranslation('meter_issues.rule.cancel_success')
          });
        })
        .catch((e) => {
          notify.error({
            content: getTranslation('meters_issues.meters.failed_to_load'),
            error: e
          });
        });
    } catch (e) {
      notify.error({
        content: getTranslation('meter_issues.rule.cancel_fail'),
        error: e
      });
    }
  };

  useEffect(() => {
    getMutingRules();
  }, []);

  return (
    <>
      <FlexElements flexDirection="column" justifyContent="space-between" className={css['section-header']}>
        <Paragraph className={css['muting-header']}>{getTranslation('meter_issues.active_rules')}</Paragraph>
        <Paragraph className={css['subtext']}>
          <Interweave content={getTranslation('meter_issues.active_rules_desc')} />
        </Paragraph>
      </FlexElements>

      {activeMutingRules.activeMutingRules.length > 0 ? (
        <div className={css['rule-list']}>
          <MutingRules
            mutingRules={activeMutingRules.activeMutingRules}
            expired={false}
            onCancel={onCancel}
            useCase="rule-dialog"
          />
        </div>
      ) : (
        <EmptyState
          imgName="activity"
          title={getTranslation('meter_issues.no_muting_rules')}
          description={
            <Interweave content={getTranslation('meter_issues.no_muting_rules_desc_with_link', { rootUrl: rootUrl })} />
          }
        />
      )}

      {/* <HorizontalDivider /> */}

      <FlexElements
        justifyContent={state.expiredRulesVisible ? 'space-between' : 'center'}
        className={css['section-header']}
      >
        {state.expiredRulesVisible && (
          <Paragraph className={css['expired-header']}>{getTranslation('meter_issues.expired_rules')}</Paragraph>
        )}
        <Button
          id="toggle-expired"
          className={css['toggle-expired-rules-btn']}
          onClick={() => {
            setState({ expiredRulesVisible: !state.expiredRulesVisible });
          }}
          icon={state.expiredRulesVisible ? 'eye-crossed' : 'eye-open'}
          type="text"
        >
          {state.expiredRulesVisible
            ? getTranslation('meter_issues.hide_expired_rules')
            : getTranslation('meter_issues.show_expired_rules')}
        </Button>
      </FlexElements>

      {state.expiredRulesVisible && (
        <MutingRules
          mutingRules={expiredMutingRules.expiredMutingRules}
          expired={true}
          onCancel={() => {}}
          useCase="rule-dialog"
        />
      )}
    </>
  );
};
