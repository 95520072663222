import { MultiValue } from 'design-system/ComponentSets';
import React from 'react';

import { AuthRoleResponse, OrganisationRoleType } from '@zf/api-types/auth';
import { capitalize } from '@zf/utils/src/string';

import { useAppContext } from '../../app-context';
import Dropdown from '../Lang/Dropdown';
import SimpleDropdown from '../Lang/SimpleDropdown';
import css from './permission-multivalue.module.scss';

type Props = {
  roles: AuthRoleResponse[] | undefined;
  initialValues: OrganisationRoleType[];
  onChange: (orgRoles: any) => void;
};

type NormalIdOrganisationType = {
  id: string;
  shortCode: string | undefined;
  description: string;
  status: number;
  roleId?: string | null;
};

export default function PermissionMultiValue(props: Props) {
  const { initialValues, roles = [], onChange } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { tenantAuth } = tenantReducer.state;

  const orgDropdownValues = tenantAuth
    ? tenantAuth.organizations.map((o) => {
        const converted: NormalIdOrganisationType = {
          id: o.organizationId || '',
          shortCode: o.shortCode,
          description: o.description,
          status: o.status,
          roleId: o.roleId
        };

        return {
          value: converted,
          text: converted.description
        };
      })
    : [];

  const rolesDropdown = roles.map((r) => {
    return {
      value: r.id,
      text: capitalize(r.name)
    };
  });

  return (
    <MultiValue
      id="permissions"
      className={css['permission-multi-value']}
      title={i18n.getTranslation('user.multivalue_title')}
      initialValues={initialValues}
      intialNodeValue={{
        organizationId: '',
        roleId: ''
      }}
      onChange={onChange}
    >
      {({ index, value, dispatchValue }) => {
        const org = tenantAuth?.organizations.find((o) => o.organizationId === value.organizationId);

        return (
          <div className={css['permission-value']} key={`permission-value-${index}`}>
            <Dropdown
              id={`dropdown-org-${index}`}
              className={css['input']}
              onChange={(val) => dispatchValue({ organizationId: val[0]?.id || '' })}
              values={orgDropdownValues}
              selectedValues={[org && org.organizationId ? org.organizationId : '']}
              extraLeftKey="shortCode"
              clear
            />
            <SimpleDropdown
              id={`dropdown-permissiontype-${index}`}
              className={css['input']}
              onChange={(val) => dispatchValue({ roleId: val[0] })}
              values={rolesDropdown}
              selectedValues={[value.roleId]}
              clear
            />
          </div>
        );
      }}
    </MultiValue>
  );
}
