import { observer } from 'mobx-react';
import React from 'react';

import { templateUsecase } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';
import { CardItem } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../app-context';
import useDownload from '../../../../../app-context/hooks/use-download';
import Button from '../../../../../components/Button/Button';
import { DeleteIcon } from '../../../../../components/Icon';
import EditIcon from '../../../../../components/Icon/EditIcon';
import { Link } from '../../../../../design-system/Components';
import { dialog } from '../../../../../events/dialog-events';
import { useStore } from '../../../../../hooks/useStore';
import AddCustomHtmlDialog from '../dialogs/add-custom-html-dialog';
import RemoveCustomTemplateDialog from '../dialogs/RemoveCustomTemplateDialog';
import css from './existing-custom-html.module.scss';

type Props = {
  fileName?: string | null;
  templateUsecase: templateUsecase;
};

const ExistingCustomHTML = (props: Props) => {
  const { fileName, templateUsecase } = props;

  const { tenantReducer } = useAppContext();
  const { applicationStore, communicationStore } = useStore();
  const { selectedEntitySubjectType, selectedEntitySubjectSubtype } = communicationStore.templatesStore;
  const { getTranslation } = applicationStore;

  const { clickRef, onSubmit, validationRef } = useDialog();
  const { downloadSingleFile } = useDownload();

  const openRemoveDialog = () => {
    dialog.normal({
      title: getTranslation('communication.remove_custom_html'),
      icon: 'trashcan',
      content: <RemoveCustomTemplateDialog ref={clickRef} templateUsecase_={templateUsecase} />,
      buttonPositive: getTranslation('general.remove'),
      type: 'danger',
      onSubmit
    });
  };

  const openCustomHtmlDialog = () => {
    dialog.normal({
      title: getTranslation('communication.upload_custom_html'),
      content: <AddCustomHtmlDialog templateUseCase={templateUsecase} ref={clickRef} validationRef={validationRef} />,
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <>
      <CardItem width="12">
        <div className={css['existing-custom-html']}>
          <Button
            id={`download-btn-${fileName}`}
            type="text"
            size="small"
            onClick={() =>
              downloadSingleFile(
                `/cfg/templates/${selectedEntitySubjectType}/${selectedEntitySubjectSubtype}/${templateUsecase}/download`,
                tenantReducer
              )
            }
          >
            {fileName}
          </Button>
          <div className={css['existing-html-action-container']}>
            <EditIcon id="update-custom-html" onClick={openCustomHtmlDialog} tooltipText="edit" />
            <DeleteIcon id="remove-custom-html" onClick={openRemoveDialog} tooltipText="remove" iconType="cross" />
          </div>
        </div>
      </CardItem>
      <CardItem width="12">
        <Link id="add-custom-html" url="https://support.zerofriction.co/hc/nl/articles/5598639419537-Een-html-bestand-uploaden-voor-uw-pdf-en-e-mailsjablonen-en-vertalingen-aanpassen">
          {getTranslation('communication.add_custom_html_descr')}
        </Link>
      </CardItem>
    </>
  );
};

export default observer(ExistingCustomHTML);
