import Interweave from 'interweave';
import moment, { Moment } from 'moment';
import React from 'react';

import { FlatConsumptionType } from '@zf/api-types/master-data/meter';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import DateRangePicker from '../../components/input/DateRangePicker';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';

type Props = {
  extrenalChannId: string;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

type State = {
  startDateTime: Moment | null;
  endDateTime: Moment | null;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { extrenalChannId, validationRef } = props;
  const { i18n, tenantReducer } = useAppContext();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = React.useReducer(stateReducer, {
    startDateTime: moment(MIN_DATE),
    endDateTime: moment(MAX_DATE)
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!state.startDateTime || !state.endDateTime);
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(state)]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await sendRequest<FlatConsumptionType>({
          request: {
            method: METHODS.POST,
            endpoint: `/me/Consumptions/${extrenalChannId}/recalculate`,
            query: {
              startDateTime: state.startDateTime?.toISOString(),
              endDateTime: state.endDateTime?.toISOString()
            }
          },
          tenantReducer: tenantReducer,
          lang: i18n.lang
        });

        notify.success({
          content: i18n.getTranslation('actions.meter.recalculate_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.meter.recalculate_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>
        <Interweave content={i18n.getTranslation('actions.meter.recalculate_paragraph')} />
      </Paragraph>
      <InputContainer>
        <DateRangePicker
          id="recalculate-period"
          startDate={state.startDateTime}
          endDate={state.endDateTime}
          setDates={(dates) => setState({ startDateTime: dates[0], endDateTime: dates[1] })}
          error={!state.startDateTime || !state.endDateTime}
        />
      </InputContainer>
    </>
  );
});
