import React from 'react';

import css from './wizard.module.scss';
import Center from '../../helpers/Center';

type Props = {
  children: React.ReactNode;
};

export default function WizardContainer(props: Props) {
  const { children } = props;

  return (
    <Center type="horizontal">
      <div className={css['wizard']}>{children}</div>
    </Center>
  );
}
