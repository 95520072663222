import { spacing } from '@zf/utils/src/global';
import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';

import { Paragraph } from '../../Foundation';
import css from './detail-line.module.scss';

type Props = {
  label: string;
  children: React.ReactNode;
  disabled?: boolean;
  color?: string;
  lineHeight?: spacing;
};

export default function DetailLine(props: Props) {
  const { label, children, color, lineHeight = spacing['spacing-24'], disabled = false } = props;

  // This lineHeight is only passed to the label but because of flexbox all is aligned properly
  const style: CSSProperties = useMemo(() => {
    return { lineHeight };
  }, [lineHeight]);

  return (
    <div className={classNames(css['detail-line'], { [css['disabled']]: disabled })}>
      <Paragraph textAlign="left" color={color} style={style}>
        {label}
      </Paragraph>
      {children}
    </div>
  );
}
