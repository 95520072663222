import { observer } from 'mobx-react';
import React from 'react';

import {
  AttributeReceivingPeriodType,
  RequiredAttributeQuantityType
} from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';
import { BillingItemType } from '@zf/api-types/product';
import Collapsable from '@zf/stella-react/src/atoms/Collapsable/Collapsable';
import AlertCircle from '@zf/stella-react/src/atoms/Info/AlertCircle';
import { formatDate } from '@zf/utils/src/date';

import { Link } from '../../../../../../../design-system/Components';
import { Info } from '../../../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../../../hooks/useStore';
import ErrorsTip from '../ErrorsTip';
import css from './required-attribute.module.scss';
import ValueCompleteness from './ValueCompleteness';

type Props = {
  billingItem: BillingItemType;
  productName: string;
  requiredQuantity: RequiredAttributeQuantityType;
  defaultClosed?: boolean;
};

const RequiredAttribute = observer((props: Props) => {
  const { billingItem, requiredQuantity, defaultClosed = false, productName } = props;

  const { applicationStore, contractStore } = useStore();
  const { rootUrl, getEnumTranslation, getTranslation } = applicationStore;
  const { selectedCompleteness, getCorrespondingReceivingPeriods, getRequiredQuantityCompleteness } =
    contractStore.billingCompletenessStore;

  const attributeType = getEnumTranslation('entityAttributeType', requiredQuantity.entityAttributeType);
  const { title, color } = getRequiredQuantityCompleteness(requiredQuantity);

  return (
    <Collapsable
      title={title}
      titleColor={color}
      extraLeft={
        <div className={css['extra-left']}>
          <Link
            id={`billing-item-${billingItem.id}`}
            className={css['item-name']}
            url={`${rootUrl}/configuration/product/products?billingItemId=${
              billingItem.id
            }&productName=${encodeURIComponent(productName)}`}
            bold
          >
            {billingItem.name}
          </Link>
          {!requiredQuantity.complete && requiredQuantity.errors.length > 0 && (
            <AlertCircle>
              <ErrorsTip errors={requiredQuantity.errors} />
            </AlertCircle>
          )}
        </div>
      }
      defaultClosed={defaultClosed}
    >
      {selectedCompleteness.status === billingCompletenessStatus.waiting ? (
        <Info className={css['info']}>
          {getTranslation('invoice.info_in_future', {
            invoicingCheckpointDateTime: formatDate(selectedCompleteness.invoicingCheckpointDateTime)
          })}
        </Info>
      ) : selectedCompleteness.status === billingCompletenessStatus.previousnotclosed ? (
        <Info className={css['info']}>
          {getTranslation('invoice.info_prev_not_closed', {
            invoicingCheckpointDateTime: formatDate(selectedCompleteness.invoicingCheckpointDateTime)
          })}
        </Info>
      ) : (
        <div className={css['asking-periods']}>
          {requiredQuantity.askingPeriods.map((ap, apIndex) => {
            const correspondingReceivingPeriods = getCorrespondingReceivingPeriods(
              ap,
              requiredQuantity.receivingPeriods
            ) as AttributeReceivingPeriodType[];

            return (
              <ValueCompleteness
                key={`${ap.startDateTime}-${ap.endDateTime}-${apIndex}`}
                apIndex={apIndex}
                valueName={attributeType}
                askingPeriod={ap}
                correspondingReceivingPeriods={correspondingReceivingPeriods}
              />
            );
          })}
        </div>
      )}
    </Collapsable>
  );
});

export default RequiredAttribute;
