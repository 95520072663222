import clone from 'clone';
import Interweave from 'interweave';
import React, { useState } from 'react';

import { FixedDurationPeriodicityParametersType, ProductBillingItemType } from '@zf/api-types/product';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context/app-context';
import ListBillingItem from './list-billing-item';
import css from './list-billing-item-list.module.scss';

type Props = {
  productBillingItems: ProductBillingItemType[];
  setProductBillingItems: (newItems: ProductBillingItemType[]) => void;
};

export default function ListBillingItemList(props: Props) {
  const { productBillingItems, setProductBillingItems } = props;
  const { i18n } = useAppContext();

  const [indexBeingDragged, setIndexBeingDragged] = useState<number | null>(null);

  const setProductBillingItemValue = (idToUpdate: string, val: Partial<ProductBillingItemType>) => {
    const selectedItemsClone = clone(productBillingItems);

    const indexToUpdate = productBillingItems.findIndex((si) => si.billingItemId === idToUpdate);

    if (indexToUpdate !== -1) {
      selectedItemsClone[indexToUpdate] = { ...selectedItemsClone[indexToUpdate], ...val };
      setProductBillingItems(selectedItemsClone);
    }
  };

  const setPeriodicityParameter = (idToUpdate: string, val: Partial<FixedDurationPeriodicityParametersType>) => {
    const selectedItemsClone = clone(productBillingItems);

    const indexToUpdate = productBillingItems.findIndex((si) => si.billingItemId === idToUpdate);

    if (indexToUpdate !== -1) {
      selectedItemsClone[indexToUpdate].periodicityParameters = {
        ...selectedItemsClone[indexToUpdate].periodicityParameters,
        ...val
      };
      setProductBillingItems(selectedItemsClone);
    }
  };

  const onDragEnter = (index: number) => {
    if (indexBeingDragged !== null && index !== indexBeingDragged) {
      const clone_ = [...productBillingItems];
      const repositionedVariable = productBillingItems[indexBeingDragged];
      repositionedVariable.order = index + 1;
      clone_.splice(indexBeingDragged, 1);
      clone_.splice(index, 0, repositionedVariable);
      setProductBillingItems(clone_);
      setIndexBeingDragged(index);
    }
  };

  return (
    <div className={css['items-list']}>
      <div className={css['items-list-heading']}>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('product_config.selected_items')}
        </Heading>
        <Paragraph>
          <Interweave content={i18n.getTranslation('product_config.selected_items_paragraph')} />
        </Paragraph>
      </div>

      <div className={css['list-wrapper']}>
        {productBillingItems.map((pbi, index) => {
          return (
            <ListBillingItem
              key={`${pbi.billingItemId}-${index}`}
              productBillingItem={pbi}
              setPeriodicityParameter={setPeriodicityParameter}
              setProductBillingItemValue={setProductBillingItemValue}
              onDragEnter={() => onDragEnter(index)}
              onDragStart={() => setIndexBeingDragged(index)}
              onDragEnd={() => setIndexBeingDragged(null)}
            />
          );
        })}
      </div>
    </div>
  );
}
