import React from 'react';

import { CardBody } from '@zf/stella-react/src/atoms/Card';
import { CardBodyType } from '@zf/stella-react/src/atoms/Card/CardBody';

import { ErrorBoundary } from '../Error';
import SuspenseSpinner from './suspense-spinner';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  type?: CardBodyType;
  noMaxHeight?: boolean;
  fixedHeight?: boolean;
};

export default function SuspenseCardBody(props: Props) {
  const { children, style, className, type = 'default', noMaxHeight, fixedHeight } = props;

  return (
    <CardBody style={style} className={className} type={type} noMaxHeight={noMaxHeight} fixedHeight={fixedHeight}>
      <ErrorBoundary cardError>
        <SuspenseSpinner>{children}</SuspenseSpinner>
      </ErrorBoundary>
    </CardBody>
  );
}
