import { custAgingBucketType } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';
import React from 'react';
import { Dispatch, useReducer } from 'react';
import PropertyGroupAutoFill, {
  PropertyGroupAutofillProps
} from '../../../../components/Autofills/PropertyGroupAutoFill';
import FilterTag from '../../../../components/Filters/filter-tag';
import EnumFilterSelect from '../../../../components/input/EnumFilterSelect';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import { Paragraph } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import css from '../aging-report-card.module.scss';

export type State = {
  search: string;
  propertyGroupId: string;
  propertyGroupName: string;
  creditAmounts: boolean;
  daysOutstanding: custAgingBucketType[];
};

type ReturnType = {
  filterState: State;
  filters: JSX.Element[];
  tags: JSX.Element[];
  setFilter: Dispatch<Partial<State>>;
  clearAllFilters: () => void;
};

const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);
const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

const useCustomerAgingFilter = (): ReturnType => {
  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    search: '',
    propertyGroupId: '',
    propertyGroupName: '',
    creditAmounts: true,
    daysOutstanding: []
  });
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const filters: JSX.Element[] = [
    <div key={'filter'} className={css['customer-aging-filter-content']}>
      <InlineInputFieldInput
        id="filter-search"
        onChange={(val) => setState({ search: val })}
        type="text"
        placeholder="Search..."
        value={state.search}
      />
      <ListDivider />
      <Label>{getTranslation('dashboard.customer_aging.show')}</Label>
      <div className={css['customer-aging-filter-content-toggle']}>
        <Paragraph>{getTranslation('dashboard.customer_aging.credit_amounts')}</Paragraph>
        <ZFToggle onChange={(val) => setState({ creditAmounts: val })} checked={state.creditAmounts} />
      </div>
      <Label>{getTranslation('dashboard.customer_aging.filter_on')}</Label>
      <EnumFilterSelect
        multiSelect
        id="days-outstanding-filter"
        key="days-outstanding-filter"
        title={getTranslation('dashboard.customer_aging.days_outstanding')}
        options={getEnum<custAgingBucketType>('custAgingBucketType')}
        values={state.daysOutstanding || []}
        setValue={(value) => setState({ daysOutstanding: value as custAgingBucketType[] })}
      />
      <ListDivider />
      <InlinePropertyGroupAutoFill
        id="propertyGroup"
        onChange={(value) => setState({ propertyGroupId: value[0]?.id, propertyGroupName: value[0]?.name })}
        selectedValues={[state.propertyGroupId ? state.propertyGroupId : '']}
        renderTopLvl={false}
      />
    </div>
  ];

  const tags: JSX.Element[] = [];

  if (state.creditAmounts) {
    tags.push(
      <FilterTag
        value={getTranslation('dashboard.customer_aging.credit_amounts')}
        removeFilter={() => setState({ creditAmounts: false })}
        key="credit amounts"
      />
    );
  }

  if (state.daysOutstanding && state.daysOutstanding.length > 0) {
    state.daysOutstanding.forEach((filter, index) => {
      tags.push(
        <FilterTag
          value={filter}
          enumType="custAgingBucketType"
          type="enum"
          removeFilter={() => {
            const updatedFilters = state.daysOutstanding.filter((f, i) => i !== index);
            setState({ daysOutstanding: updatedFilters });
          }}
          key="bucket type"
        />
      );
    });
  }

  if (state.search) {
    tags.push(<FilterTag value={state.search} removeFilter={() => setState({ search: '' })} key="search" />);
  }

  if (state.propertyGroupId) {
    tags.push(
      <FilterTag
        value={state.propertyGroupName}
        removeFilter={() => setState({ propertyGroupName: '', propertyGroupId: '' })}
        key="propertygroup"
      />
    );
  }

  const clearAllFilters = () => {
    setState({
      daysOutstanding: [],
      creditAmounts: true,
      propertyGroupId: '',
      propertyGroupName: ''
    });
  };

  return {
    filterState: state,
    filters,
    tags,
    clearAllFilters,
    setFilter: setState
  };
};

export default useCustomerAgingFilter;
