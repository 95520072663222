import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';

import DataGridActions from '../../../../../../components/DataGridCard/DataGridActions';
import DataGridCardHeaderSection from '../../../../../../components/DataGridCard/DataGridCardHeaderSection';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { DANGER } from '../../../../../../constants/color';
import { TabSlider } from '../../../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../../../design-system/Components/TabSlider/Tab';
import { useStore } from '../../../../../../hooks/useStore';
import NoMutations from '../../../mutations-shared/NoMutations';
import { IncomingMutationQuickFilterType } from '../context/incoming-banking-transaction-context';
import { useIncomingMutationsFilter } from './hooks/useIncomingMutationsFilter';
import useIncomingMutationsTable from './hooks/useIncomingMutationsTable';
import IgnoreDetailButton from './incoming-mutation-actions/IgnoreDetailButton';
import MatchDetailButton from './incoming-mutation-actions/MatchDetailButton';
import ResetDetailButton from './incoming-mutation-actions/UnmatchDetailButton';

type Props = {
  incomingBankingTransaction: IncomingBankingTransactionType;
  refresh: () => void;
};

const IncomingMutationsTableSection = (props: Props) => {
  const { incomingBankingTransaction, refresh: refreshDetailEntity } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { setQueryParams } = useIncomingMutationsFilter();

  const {
    selectedIds,
    sortBy,
    sortDirection,
    sortableFields,
    loading,
    error,
    rows,
    queryParams,
    overviewCounts,
    setSelectedIds,
    handleSort,
    setStopIndex,
    refresh: refreshTable
  } = useIncomingMutationsTable(incomingBankingTransaction);

  const tabItems = useMemo(() => {
    const tabs: SelectionTabItemType<Record<string, any>>[] = [
      { id: 'all', title: getTranslation('general.all'), count: overviewCounts.all },
      {
        id: 'banktransfers',
        title: getTranslation('incoming_mutations.bank_transfers'),
        count: overviewCounts.bankTransfers
      },
      {
        id: 'bankconfirmations',
        title: getTranslation('incoming_mutations.bank_confirmations'),
        count: overviewCounts.bankConfirmations
      },
      {
        id: 'reversals',
        title: getTranslation('incoming_mutations.reversals'),
        count: overviewCounts.reversals
      }
    ];

    if (overviewCounts.resolveIssues > 0) {
      tabs.push({
        id: 'resolveIssues',
        title: getTranslation('banking_transaction.issues'),
        count: overviewCounts.resolveIssues,
        color: DANGER
      });
    }

    return tabs;
  }, [overviewCounts]);

  const [columns] = useState([
    {
      width: 300,
      label: getTranslation('general.type'),
      dataKey: 'type',
      noClick: true
    },
    {
      width: 170,
      label: getTranslation('general.amount_valuta'),
      dataKey: 'amount'
    },
    {
      width: 300,
      label: getTranslation('general.status'),
      dataKey: 'status'
    },
    {
      width: 300,
      label: getTranslation('banking_transaction.transaction_date'),
      dataKey: 'transactionDate'
    },
    {
      width: 300,
      label: getTranslation('payments.customer_iban'),
      dataKey: 'customerBankAccount'
    },
    {
      width: 500,
      label: getTranslation('incoming_mutations.reference_description'),
      dataKey: 'description'
    }
  ]);

  const refreshAll = useCallback(() => {
    refreshDetailEntity();
    refreshTable();
  }, []);

  const setSelectedItem = useCallback((itemId: string) => {
    setQueryParams({ quickFilter: itemId.toLowerCase() as IncomingMutationQuickFilterType });
  }, []);

  const selectedRows = rows.filter((r) => selectedIds.includes(r.__id));

  return (
    <>
      <DataGridCardHeaderSection>
        <TabSlider
          id="linked-entities"
          tabItems={tabItems}
          selectedTabItem={queryParams.quickFilter}
          setSelectedItem={setSelectedItem}
        />
        <DataGridActions>
          <ResetDetailButton selectedRows={selectedRows} refresh={refreshAll} />
          <IgnoreDetailButton
            incomingBankingTransaction={incomingBankingTransaction}
            selectedRows={selectedRows}
            refresh={refreshAll}
          />
          <MatchDetailButton
            selectedRows={selectedRows}
            refresh={refreshAll}
            incomingBankingTransaction={incomingBankingTransaction}
          />
        </DataGridActions>
      </DataGridCardHeaderSection>

      <DynamicVirtualTable
        id="mutations-table"
        tooltipId="mutations-table-tip"
        onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
        rows={rows}
        onSelectRow={setSelectedIds}
        selectedRows={selectedIds}
        loading={loading}
        error={error}
        NoDataOverlay={NoMutations}
        totalAmountOfRows={rows.length}
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        columns={columns}
        singleSort
        dynamicRowHeight
      />
    </>
  );
};

export default observer(IncomingMutationsTableSection);
