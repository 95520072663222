import classNames from 'classnames';
import React from 'react';

import Draggeable from '@zf/stella-react/src/atoms/DragAndDrop/draggeable';
import Center from '@zf/stella-react/src/helpers/Center';
import { generateColor } from '@zf/utils/src/color';
import { formatDecimal } from '@zf/utils/src/number';

import { useAppContext } from '../../../app-context/app-context';
import { VariableType } from '../formula-builder';
import css from './variable.module.scss';

type Props = {
  variable: VariableType;
  text: string;
  className: string;
  fixedValue?: string | null;
  onDragEnter: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
};

export default function VariableComponent(props: Props) {
  const { variable, text, className, onDragEnter, onDragStart, onDragEnd } = props;
  const { i18n } = useAppContext();

  let style = {};

  if (variable.inputType !== 'operator') {
    style = { borderLeftColor: generateColor(text) };
  }

  return (
    <Draggeable onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {() => {
        return (
          <div
            onDragEnter={onDragEnter}
            style={style}
            className={classNames(css['variable'], className)}
            role="button"
            onKeyDown={() => onDragEnter}
            tabIndex={-1}
          >
            <Center type="both">
              {variable.fixedValue ? formatDecimal(parseFloat(variable.fixedValue), i18n.culture) : text}
            </Center>
          </div>
        );
      }}
    </Draggeable>
  );
}
