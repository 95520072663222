import React from 'react';

import { ExportJobType } from '@zf/api-types/export-job';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../app-context/app-context';
import useDetail from '../../../app-context/hooks/use-detail';
import { ICON_COLORS } from '../../../constants/icons';

const useExportJobDetail = (entityId: string) => {
  const { entity: exportJob, navigator } = useDetail<ExportJobType>({
    entityId: entityId,
    endpoint: '/logs/exportjob',
    domain: 'exportjob'
  });
  const { enumReducer } = useAppContext();

  const status = (
    <NewStatusBadge
      color={exportJob ? ICON_COLORS[exportJob.status] : ''}
      status_={exportJob ? ` ${enumReducer.getTranslation('exportJobStatus', exportJob.status)} ` : ''}
    />
  );

  return { exportJob, status, navigator };
};

export default useExportJobDetail;
