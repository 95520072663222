import { notify } from 'events/notification-events';
import React, { useEffect } from 'react';

import { ManagementRelation } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { ProductType } from '@zf/api-types/product';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import CustomerAutoFill, { CustomerAutofillProps } from '../../../../components/Autofills/CustomerAutoFill';
import ProductDropdown from '../../../../components/Dropdown/product-dropdown/ProductDropdown';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import { Link } from '../../../../design-system/Components';
import { METHODS, sendRequest } from '../../../../utils/request';
import { ValidatorValueType } from './location-detail-card';

type Props = {
  location: ServiceLocationType;
  values: ValidatorValueType;
  setValue: (value: Partial<ValidatorValueType>, initialApiSet?: boolean, backup?: boolean) => void;
  keyHandler: (event: React.KeyboardEvent<HTMLElement>) => void;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineCustomerAutoFill = InlineInputField<CustomerAutofillProps>(CustomerAutoFill);

export default function LocationDetailCardBody(props: Props) {
  const { location, values, setValue, keyHandler } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const [propertyGroupProduct, setPropertyGroupProduct] = React.useState<ProductType | null>(null);

  const notifyFetchFail = (error: Error) => {
    notify.error({
      content: i18n.getTranslation('general.get_cfg_fail'),
      error
    });
  };

  const getPropertyGroupProduct = (propertygroupId: string) => {
    return sendRequest<PropertyGroupBillingConfigurationType>({
      request: {
        method: METHODS.GET,
        endpoint: `/bill/PropertyGroupBillingConfiguration/${propertygroupId}`
      },
      tenantReducer,
      lang: i18n.lang
    })
      .then((billingConfig) => {
        if (billingConfig.data.productId) {
          return sendRequest<ProductType>({
            request: {
              method: METHODS.GET,
              endpoint: `/cfg/Products/${billingConfig.data.productId}`
            },
            tenantReducer,
            lang: i18n.lang
          });
        }
        return null;
      })
      .catch((error) => {
        notifyFetchFail(error);
        return null;
      });
  };

  useEffect(() => {
    if (location.propertyGroup && location.propertyGroup.id) {
      getPropertyGroupProduct(location.propertyGroup.id)
        .then((product) => {
          setPropertyGroupProduct(product ? product.data : null);
        })
        .catch((error) => notifyFetchFail(error));
    }
  }, [location.propertyGroup]);

  const owner: ManagementRelation | undefined = location.managementRelations.find(
    (mr) => mr.managementRelationType === 'owner'
  );
  const manager: ManagementRelation | undefined = location.managementRelations.find(
    (mr) => mr.managementRelationType === 'propertymanager'
  );

  const setProduct = (product: ProductType | null) => {
    setValue({ product: { productId: product ? product.id : '', productName: product ? product.name : '' } });
  };

  return (
    <>
      <CardItem width="6">
        <Label>{i18n.getTranslation('location.address')}</Label>
      </CardItem>
      <CardItem width="6">
        <Paragraph dataTestId="location.address">{location.address.localizedDisplay}</Paragraph>
      </CardItem>

      <CardItem width="6">
        <Label>{i18n.getTranslation('location.external_id')}</Label>
      </CardItem>
      <CardItem width="6">
        <InlineInputFieldInput
          id="location.update_externalid"
          onKeyDown={keyHandler}
          value={values.externalId}
          onChange={(val: string) =>
            setValue({
              externalId: val
            })
          }
        />
      </CardItem>

      {location.propertyGroup && (
        <>
          <CardItem width="6">
            <Label>{i18n.getTranslation('property_groups.property_group')}</Label>
          </CardItem>
          <CardItem width="6">
            <Link
              id={`property-${location.propertyGroup.id}`}
              url={`${rootUrl}/property-groups/${location.propertyGroup.id}`}
            >
              {location.propertyGroup.name}
            </Link>
          </CardItem>
        </>
      )}

      <CardItem width="6">
        <Label>{i18n.getTranslation('location.owner')}</Label>
      </CardItem>
      <CardItem width="6">
        <InlineCustomerAutoFill
          id="location.owner"
          initialValue={owner ? owner.shortDisplayName : ''}
          onChange={(value) => setValue({ owner: value[0] ? value[0].id : '' })}
          placeholder={i18n.getTranslation('location.search_owner')}
          selectedValues={[values.owner]}
        />
      </CardItem>

      <CardItem width="6">
        <Label>{i18n.getTranslation('location.manager')}</Label>
      </CardItem>
      <CardItem width="6">
        <InlineCustomerAutoFill
          id="location.manager"
          initialValue={manager ? manager.shortDisplayName : ''}
          onChange={(value) => setValue({ manager: value[0] ? value[0].id : '' })}
          placeholder={i18n.getTranslation('location.search_manager')}
          selectedValues={[values.manager]}
        />
      </CardItem>

      <CardItem width="6">
        <Label>{i18n.getTranslation('product_config.product')}</Label>
      </CardItem>
      <CardItem width="6">
        <ProductDropdown
          id="location.product-data"
          onChange={(val) => setProduct(val[0])}
          selectedValue={values.product ? values.product.productId : ''}
          resetValue={
            location.propertyGroup && location.propertyGroup.id && propertyGroupProduct
              ? propertyGroupProduct.id
              : undefined
          }
          reset={() => setProduct(propertyGroupProduct)}
          isInline
        />
      </CardItem>
    </>
  );
}
