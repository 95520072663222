import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';

const MigrateDialog = forwardRef((props, ref: Ref<DialogClickRef | undefined>) => {
  const { integrationStore, applicationStore } = useStore();
  const { dropdownValues, filter, migrate } = integrationStore.integrationLoggingStore;
  const { getTranslation } = applicationStore;

  const [plugin, setPlugin] = useState(dropdownValues[0].value);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await migrate(plugin.id, plugin.pluginType);
        notify.success({
          content: getTranslation('cards.integration_logging.migration_successfull')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('cards.integration_logging.migration_unsuccessfull'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>{applicationStore.getTranslation('cards.integration_logging.migrate_message')}</Paragraph>
      <SimpleDropdown
        id="plugin-type"
        onChange={(val) => setPlugin(val[0])}
        values={dropdownValues}
        selectedValues={[plugin]}
        placeholder={filter.configuredPlugin ? '' : applicationStore.getTranslation('general.plugin')}
      />
    </>
  );
});

export default observer(MigrateDialog);
