import classNames from 'classnames';
import React from 'react';

import { colors } from '@zf/utils/src/color';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import css from './exclamation-badge.module.scss';

type Props = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  type: 'warning' | 'error';
  upperCase?: boolean;
  withTooltip?: boolean;
};

const createHeaderStyle = (upperCase?: boolean, children?: React.ReactNode, withTooltip?: boolean) => {
  const headerStyle: React.CSSProperties = {};

  if (upperCase) {
    headerStyle['textTransform'] = 'uppercase';
  } else {
    headerStyle['textTransform'] = 'capitalize';
  }

  if (children && !withTooltip) {
    headerStyle['paddingBottom'] = '0.5rem';
  }

  return headerStyle;
};

export default function ExclamationBadge(props: Props) {
  const { children, title = '', type, upperCase = true, withTooltip = false, ...otherProps } = props;

  const headerStyle = React.useMemo(
    () => createHeaderStyle(upperCase, children, withTooltip),
    [upperCase, children, withTooltip]
  );

  const color = React.useMemo(() => (type === 'error' ? colors['red-600'] : colors['orange-600']), [type]);

  const errorContainerStyle: React.CSSProperties = React.useMemo(() => {
    return { color: color };
  }, [color]);

  const innerCircleStyle: React.CSSProperties = React.useMemo(() => {
    return { backgroundColor: color };
  }, [color]);

  const outerCircleStyle: React.CSSProperties = React.useMemo(() => {
    return { backgroundColor: `rgba(${color}, 0.2)` };
  }, [color]);

  const badgeContent = (
    <div className={css['error-container']} style={errorContainerStyle}>
      <div className={css['error-exclamation']}>
        <div className={css['error-inner-circle']} style={innerCircleStyle}>
          <Icon name="exclamation" />
        </div>
        <div className={css['error-outer-circle']} style={outerCircleStyle} />
      </div>

      <div
        className={classNames(css['error-content'], {
          [css['error-content-center']]: !children || withTooltip
        })}
      >
        <div className={css['error-header']} style={headerStyle}>
          {title}
        </div>
        {children && !withTooltip && (
          <div className={css['error-description']}>
            <div className={css['error-content']}>{children}</div>
          </div>
        )}
      </div>
    </div>
  );

  return withTooltip ? (
    <div data-tip={createTooltipContent(<Paragraph className={css['tooltip-text']}>{children}</Paragraph>)}>
      {badgeContent}
    </div>
  ) : (
    badgeContent
  );
}
