import React, { Fragment } from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';
import DropDownDetails from '@zf/stella-react/src/atoms/Dropdown/DropDownDetails';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context/app-context';
import { ICONS } from '../../constants/icons';
import { Link } from '../../design-system/Components';
import IconLabel from '../Icon/IconLabel';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Props = {
  showDetails?: boolean;
  className?: string;
};

export type CustomerAutofillProps = Props & CustomAutoFillProps<CustomerType>;

const processAutoFillItem = (c: CustomerType) => {
  return { value: c, text: `${c.accountNumber} - ${c.shortDisplayName}` };
};

export default function CustomerAutoFill(props: CustomerAutofillProps) {
  const {
    id,
    selectedValues,
    initialValue,
    query,
    queryField,
    placeholder,
    showDetails = true,
    onChange,
    onFocus
  } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const autoFillTools = useAutoFill({
    endpoint: '/md/customers',
    processRecord: processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, shownValues, values } = autoFillTools;

  const customer: CustomerType | undefined =
    typeof focusedIndex !== 'undefined' && shownValues[focusedIndex]
      ? shownValues[focusedIndex].value.value
      : undefined;

  const details =
    customer && showDetails ? (
      <DropDownDetails>
        <div>
          <IconLabel labelText={customer.shortDisplayName} iconType={ICONS[customer.customerType]} />
          <Link id={`customer-${customer.accountNumber}`} url={`${rootUrl}/customers/${customer.id}`} newTab>
            {customer.accountNumber}
          </Link>
        </div>
        {customer.customerGroup && (
          <div>
            <IconLabel labelText={i18n.getTranslation('customer_groups.customer_group')} iconType="customer-group" />
            <Paragraph>{customer.customerGroup ? customer.customerGroup.name : '-'}</Paragraph>
          </div>
        )}
        <div>
          <IconLabel labelText={i18n.getTranslation('location.address')} iconType="location" />
          <Paragraph>{customer.invoiceAddress.localizedDisplay}</Paragraph>
        </div>
        <div>
          {customer.contracts.length > 0 && (
            <>
              <IconLabel labelText={i18n.getTranslation('contracts.contracts')} iconType="contract" />
              {customer.contracts.map((con) => {
                return (
                  <Fragment key={con.contractId}>
                    <Link id={`contract-${con.contractId}`} url={`${rootUrl}/contracts/${con.contractId}`} newTab>
                      {con.contractNumber}
                    </Link>
                    <br />
                  </Fragment>
                );
              })}
            </>
          )}
        </div>
      </DropDownDetails>
    ) : undefined;

  return (
    <SearchAutoFill
      {...props}
      id={id}
      details={details}
      values={values}
      selectedValues={selectedValues}
      placeholder={placeholder ? placeholder : i18n.getTranslation('contracts.wizard.search_customer')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
