import MeteringIssueService from 'app-context/services/metering/MeteringIssueService';
import RootStore from 'app-context/stores';
import BaseStore from 'app-context/stores/BaseStore';
import { notify } from 'events/notification-events';
import { action, makeObservable, observable } from 'mobx';

import { MeteringIssue, MeteringIssueOverviewCount } from '@zf/api-types/metering/metering-issues';

export default class MeteringIssueStore extends BaseStore<MeteringIssue> {
  public rootStore: RootStore;

  public meteringIssueService: MeteringIssueService;

  /**
   * @description This variable respresents the counts without filtering
   */
  public overviewCounts: Record<MeteringIssueOverviewCount, number> | undefined;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;

    this.meteringIssueService = new MeteringIssueService(rootStore.applicationStore);

    makeObservable(this, {
      overviewCounts: observable,

      setOverviewCounts: action,
      initMeteringIssuesStore: action,
      refetchOverviewCounts: action
    });
  }

  setOverviewCounts = (overviewCounts: Record<MeteringIssueOverviewCount, number> | undefined) => {
    this.overviewCounts = overviewCounts;
  };

  initMeteringIssuesStore = async () => {
    const { getTranslation } = this.rootStore.applicationStore;
    const { getMeterIssuesOverviewCounts } = this.meteringIssueService;

    getMeterIssuesOverviewCounts()
      .then((res) => this.setOverviewCounts(res))
      .catch((error) => {
        notify.error({
          content: getTranslation('general.get_counts_fail'),
          error
        });
      });
  };

  refetchOverviewCounts = () => {
    this.initMeteringIssuesStore();
  };
}
