import React from 'react';

import { moveRequestType } from '@zf/api-types/enums';
import { TaskOverviewCountType } from '@zf/api-types/master-data/move-request';

import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { removeFromList } from '@zf/utils/src/array';
import { ArrayParam, StringParam } from 'serialize-query-params';

const initialOverviewCount: TaskOverviewCountType = {
  all: 0,
  toBeProcessed: 0,
  processed: 0,
  rejected: 0,
  requireAttention: 0
};

type QueryParam = {
  moveRequestType?: moveRequestType[];
  serviceLocationId?: string;
  serviceLocationName?: string | null;
};

const initialQueryParams = {
  moveRequestType: undefined,
  serviceLocationId: undefined,
  serviceLocationName: undefined
};

const paramTypes = {
  moveRequestType: ArrayParam,
  serviceLocationId: StringParam,
  serviceLocationName: StringParam
};

const useTasksFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams
  } = useFilter<QueryParam>({
    endpoint: '/md/MoveRequests/overviewcount',
    initialCount: initialOverviewCount,
    domain: 'moverequest',
    search: search,
    initialQueryParams,
    defaultQuickFilter: 'toBeProcessed',
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.moveRequestType) {
    tags.push(
      queryParams.moveRequestType.map((type) => {
        return (
          <FilterTag
            type="enum"
            enumType="moveRequestType"
            value={type}
            removeFilter={() => setQueryParams({ moveRequestType: removeFromList(type, queryParams.moveRequestType) })}
            key={`task-type-${type}`}
          />
        );
      })
    );
  }

  if (queryParams.serviceLocationId && queryParams.serviceLocationName) {
    tags.push(
      <FilterTag
        value={queryParams.serviceLocationName}
        removeFilter={() => setQueryParams({ serviceLocationId: undefined, serviceLocationName: undefined })}
        key={`task-location-${queryParams.serviceLocationId}`}
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams,
    quickFilter,
    overviewCount,
    searchValue,
    initialQueryParams,
    filterTags,
    queryParams
  };
};

export default useTasksFilter;
