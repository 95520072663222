import { observer } from 'mobx-react';
import React from 'react';

import { translationStatus } from '@zf/api-types/enums';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import FilterInputs from '../../../../components/Filters/filter-inputs';
import EnumFilterSelect from '../../../../components/input/EnumFilterSelect';
import Dropdown from '../../../../components/Lang/Dropdown';
import { useStore } from '../../../../hooks/useStore';
import css from './enum-translation-filters.module.scss';
import SearchBar, { SearchBarProps } from '../../../../components/input/SearchBar';

const InlineDropdown = InlineInputField<DropdownProps<any>>(Dropdown);
const InlineInputFieldSearchBar = InlineInputField<SearchBarProps>(SearchBar);

const EnumTranslationFilters = () => {
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getEnum } = applicationStore;
  const { dropdownValues, enumFilter, setEnumFilters } = communicationStore.translationStore;

  return (
    <>
      <div className={css['filter']}>
        <FilterInputs
          filterInputs={[
            <InlineDropdown
              id="filter-global-translations"
              key="filter-global-translations"
              onChange={(val) => {
                if (val[0]) {
                  setEnumFilters({ group: val[0].id });
                } else {
                  setEnumFilters({ group: '' });
                }
              }}
              values={dropdownValues || []}
              selectedValues={[enumFilter.group]}
              placeholder={applicationStore.getTranslation('general.group')}
              renderTopLvl={false}
            />,
            <EnumFilterSelect
              multiSelect
              id="completeness-status-filter"
              key="completeness-status-filter"
              title={getTranslation('general.status')}
              options={getEnum<translationStatus>('translationStatus')}
              values={enumFilter.translationStatus}
              setValue={(value) => setEnumFilters({ translationStatus: value as translationStatus[] })}
            />
          ]}
          removeAllFilters={() => setEnumFilters({ translationStatus: [], group: '' })}
        />
      </div>
      <InlineInputFieldSearchBar
        id="filter-searchbar"
        className={css['search-bar']}
        onChange={(value) => setEnumFilters({ searchValue: value })}
        value={enumFilter.searchValue}
        placeholder={getTranslation('product_config.translations')}
        debounceTime={300}
      />
    </>
  );
};

export default observer(EnumTranslationFilters);
