import { observer } from 'mobx-react';
import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { formatDate, isMinDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { DetailLine, InfoGrid, QuickTip } from '../../../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import css from './incoming-transaction-details.module.scss';

type Props = {
  incomingBankingTransaction: IncomingBankingTransactionType;
};

const IncomingBankingTransactionDetailsCard = (props: Props) => {
  const { incomingBankingTransaction } = props;

  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  return (
    <div className={css['info-grid']}>
      <InfoGrid nCols={2}>
        <DetailLine label={getTranslation('banking_transaction.created_date')}>
          <Paragraph>{formatDate(incomingBankingTransaction.createdDateTime)}</Paragraph>
        </DetailLine>

        <DetailLine label={getTranslation('banking_transaction.opening_balance')}>
          {!isMinDate(incomingBankingTransaction.openingBalanceDate) ? (
            <div className={css['balance-date']}>
              <Paragraph>{formatMoney(incomingBankingTransaction.openingBalance, culture) + ' - '}</Paragraph>
              <Paragraph>{formatDate(incomingBankingTransaction.openingBalanceDate)}</Paragraph>
            </div>
          ) : (
            <Paragraph>{formatMoney(incomingBankingTransaction.openingBalance, culture)}</Paragraph>
          )}
        </DetailLine>

        <DetailLine label={getTranslation('banking_transaction.account')}>
          <div className={css['account-error']}>
            <Paragraph>
              {incomingBankingTransaction.companyBankAccount.accountHolder
                ? `${incomingBankingTransaction.companyBankAccount.accountHolder} - ${incomingBankingTransaction.companyBankAccount.iban}`
                : incomingBankingTransaction.companyBankAccount.iban}
            </Paragraph>
            {incomingBankingTransaction.errors.length > 0 && (
              <QuickTip id="account-error" text={incomingBankingTransaction.errors[0]?.message} alert />
            )}
          </div>
        </DetailLine>

        <DetailLine label={getTranslation('banking_transaction.closing_balance')}>
          {!isMinDate(incomingBankingTransaction.closingBalanceDate) ? (
            <div className={css['balance-date']}>
              <Paragraph>{formatMoney(incomingBankingTransaction.closingBalance, culture) + ' - '}</Paragraph>
              <Paragraph>{formatDate(incomingBankingTransaction.closingBalanceDate)}</Paragraph>
            </div>
          ) : (
            <Paragraph>{formatMoney(incomingBankingTransaction.closingBalance, culture)}</Paragraph>
          )}
        </DetailLine>
      </InfoGrid>
    </div>
  );
};

export default observer(IncomingBankingTransactionDetailsCard);
