import React from 'react';

import { ImportDataProviderType } from '@zf/api-types/data-provider';
import { FileFormatType } from '@zf/api-types/file-format';

import { useAppContext } from '../../../app-context';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import useImportJobListPage from '../hooks/useImportJobListPage';

type Props = {
  dataProviders: ImportDataProviderType[];
  fileformats: FileFormatType[];
};

export default function ImportJobsList(props: Props) {
  const { dataProviders, fileformats } = props;
  const { i18n } = useAppContext();

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    handleSort,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    setStopIndex,
    setSelectedIds,
    localTimeStamp,
    timeStamp
  } = useImportJobListPage(dataProviders, fileformats);

  return React.useMemo(() => {
    return (
      <DynamicVirtualTable
        id="import-job-table"
        tooltipId="import-job-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={[
          {
            width: 350,
            label: i18n.getTranslation('import_jobs.file_name'),
            dataKey: 'FileName',
            noClick: true
          },
          {
            width: 250,
            label: i18n.getTranslation('import_jobs.source'),
            dataKey: 'Source'
          },
          {
            width: 200,
            label: i18n.getTranslation('import_jobs.created'),
            dataKey: 'createdDateTime'
          },
          {
            width: 140,
            label: i18n.getTranslation('import_jobs.values_parsed'),
            dataKey: 'amountOfValuesParsed'
          },
          {
            width: 160,
            label: i18n.getTranslation('import_jobs.total_buckets_stored'),
            dataKey: 'totalBucketsStored'
          },
          {
            width: 120,
            label: i18n.getTranslation('import_jobs.progress'),
            dataKey: 'progress'
          },
          {
            width: 120,
            label: i18n.getTranslation('general.status'),
            dataKey: 'status'
          },
          {
            width: 110,
            label: i18n.getTranslation('import_jobs.finished'),
            dataKey: 'FinishDate'
          },
          {
            width: 140,
            label: i18n.getTranslation('import_jobs.dataprovider'),
            dataKey: 'dataprovider'
          },
          {
            width: 180,
            label: i18n.getTranslation('import_jobs.communication'),
            dataKey: 'communicationType'
          },
          {
            width: 120,
            label: i18n.getTranslation('import_jobs.file_format'),
            dataKey: 'fileformat'
          },
          {
            width: 150,
            label: i18n.getTranslation('general.type'),
            dataKey: 'importType'
          }
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, i18n.lang]);
}
