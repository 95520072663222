import { uiCulture } from '@zf/api-types/enums';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';

export const process = async (taskId: string, tenantReducer: TenantReturnValue, lang: uiCulture) => {
  return (
    await sendRequest<MoveRequestType>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/MoveRequests/${taskId}/process`
      },
      tenantReducer: tenantReducer,
      lang
    })
  ).data;
};

export const reject = async (taskId: string, tenantReducer: TenantReturnValue, lang: uiCulture) => {
  return (
    await sendRequest<MoveRequestType>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/MoveRequests/${taskId}/reject`
      },
      tenantReducer: tenantReducer,
      lang
    })
  ).data;
};

export const deleteTask = async (taskId: string, tenantReducer: TenantReturnValue, lang: uiCulture) => {
  await sendRequest<MoveRequestType>({
    request: {
      method: METHODS.DELETE,
      endpoint: `/md/MoveRequests/${taskId}`
    },
    tenantReducer: tenantReducer,
    lang
  });
};
