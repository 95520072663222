import clone from 'clone';
import React, { useCallback, useEffect } from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

import { useAppContext } from '../../../app-context/app-context';
import useDetail from '../../../app-context/hooks/use-detail';
import { ICON_COLORS } from '../../../constants/icons';
import { formatSsin } from '../../../utils/customer';
import { useTracked } from '../detail-page/detail/activity-context/context';

const useTaskDetail = (entityId: string) => {
  const [state, dispatch] = useTracked();

  const { entity: initialTask, navigator } = useDetail<MoveRequestType>({
    entityId,
    endpoint: '/md/MoveRequests',
    domain: 'moverequest'
  });
  const { enumReducer } = useAppContext();

  const setTask = useCallback(
    (updatedTask: MoveRequestType, updateInitial?: boolean) => {
      dispatch({ type: 'SET_TASK', updatedTask, updateInitial });
    },
    [dispatch]
  );

  useEffect(() => {
    if (initialTask) {
      const cloneInitialTask = clone(initialTask);
      const { ssin, ssinCountry } = cloneInitialTask.customerDetails;

      if (ssin) {
        cloneInitialTask.customerDetails.ssin = formatSsin(ssin, ssinCountry);
      }

      setTask(cloneInitialTask, true);
    }
  }, [initialTask]);

  const status = state.task ? (
    <NewStatusBadge
      color={ICON_COLORS[state.task.status]}
      status_={`${enumReducer.getTranslation('moveRequestStatus', state.task.status)}`}
    />
  ) : (
    <Spinner size="xsmall" />
  );

  return { task: state.task, status, navigator, setTask };
};

export default useTaskDetail;
