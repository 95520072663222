import React, { useEffect, useState } from 'react';

import { ConsumerGroupType, GetServiceLocationMatchingConsumerGroupsType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption } from '@zf/api-types/master-data/contract';
import { RequiredEstimationsType } from '@zf/api-types/product';

import css from './required-estimation.module.scss';
import RequiredEstimation from './RequiredEstimation';
import { RequiredEstimationsDataType } from './RequiredEstimationsSection';
import { useStore } from 'hooks/useStore';

type Props = {
  values: RequiredEstimationsDataType;
  requiredEstimations: RequiredEstimationsType[];
  consumerGroups: ConsumerGroupType[];
  handleEstimatedConsumptionChange: (index: number, value: Partial<ContractedServiceEstimatedConsumption>) => void;
  initEstimatedConsumptions: (
    matchingGroups: GetServiceLocationMatchingConsumerGroupsType[][],
    requiredEstimations: RequiredEstimationsType[]
  ) => void;
};

export default function RequiredEstimationsSectionBody(props: Props) {
  const { values, requiredEstimations, consumerGroups, initEstimatedConsumptions, handleEstimatedConsumptionChange } =
    props;

  const [matchingGroups, setMatchingGroups] = useState<GetServiceLocationMatchingConsumerGroupsType[][]>([]);
  const { contractStore } = useStore();
  const { getMatchingConGroups } = contractStore.contractApiService;

  const getMatching = async (re: RequiredEstimationsType) => {
    const propertyGroupId = values.propertyBillingConfig?.propertyGroup?.id;

    const res = await getMatchingConGroups({
      productId: values.productId,
      propertyGroupIds: propertyGroupId ? [propertyGroupId] : [],
      utilityType: re.utilityType
    });

    return res.matchingConsumerGroups[re.unitOfMeasure];
  };

  useEffect(() => {
    Promise.all(requiredEstimations.map((re) => getMatching(re))).then((matching) => {
      setMatchingGroups(matching);
      initEstimatedConsumptions(matching, requiredEstimations);
    });
  }, [values.productId, consumerGroups, requiredEstimations.length]);

  // Wait for the initialization
  if (values.estimatedConsumptions.length === 0) return null;

  return (
    <div className={css['input-wrapper']}>
      {requiredEstimations.map((re, index) => {
        const estimatedConsumption = values.estimatedConsumptions[index];

        return (
          <RequiredEstimation
            key={`consumption-group-${index}`}
            index={index}
            requiredEstimation={re}
            matchingGroups={matchingGroups[index] || []}
            estimatedConsumption={estimatedConsumption}
            handleEstimatedConsumptionChange={handleEstimatedConsumptionChange}
          />
        );
      })}
    </div>
  );
}
