import { observer } from 'mobx-react';
import React from 'react';

import { OrgAvatar } from '../../components/Avatar';
import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { CrumbHeader } from '../../components/Header';
import { useStore } from '../../hooks/useStore';
import css from './dashboard.module.scss';
import DashboardTabContainer from './DashboardTabContainer';

type Props = {
  location: Location;
  navigate: (url: string) => void;
};

export type insightsSubjectType = 'metering' | 'billing' | 'collections' | 'master-data' | 'revenue';

const Dashboard = (props: Props) => {
  const { applicationStore } = useStore();
  const { tenantReducer, getTranslation } = applicationStore;
  const { organization } = tenantReducer;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              className={css['dashboard-crumb']}
              extraLeft={
                <OrgAvatar size="small">
                  {organization && organization.shortCode ? organization.shortCode : ''}
                </OrgAvatar>
              }
              text={getTranslation('dashboard.dashboards')}
            />
          </BreadCrumb>
        }
      />
      <DashboardTabContainer {...props} />
    </>
  );
};

export default observer(Dashboard);
