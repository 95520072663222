import { MeteringIssueStatus } from '@zf/api-types/enums';
import { Link } from 'design-system/Components';
import { Spinner } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

type Props = {
  measurementIndex: number;
  meterIndex: number;
  meterId: string;
  isEditing: boolean;
  isValidating: boolean;
  isInValid: boolean;
  issueStatus: MeteringIssueStatus | undefined;
  closeDialog: () => void | undefined;
};

const IssueLink = (props: Props) => {
  const { isInValid, isEditing, isValidating, measurementIndex, meterIndex, meterId, issueStatus, closeDialog } = props;

  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const quickFilter = issueStatus === MeteringIssueStatus.unresolved ? 'unresolved' : 'all';

  if (isValidating) return <Spinner size="small" />;

  return isInValid && isEditing ? (
    <Link
      id={`issue-link-${meterIndex}-${measurementIndex}`}
      url={`${rootUrl}/devices/meters/${meterId}/issues?quickFilter=${quickFilter}`}
      onNavigate={closeDialog}
    >
      {getTranslation('meter_issues.show_issue')}
    </Link>
  ) : null;
};

export default observer(IssueLink);
