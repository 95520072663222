import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import {
  invoiceStatus,
  outgoingBankingTransactionType,
  paymentMethod,
  paymentProcessStatus
} from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../app-context';
import InvoiceAutoFill from '../../components/Autofills/InvoiceAutoFill';
import IconParagraph from '../../components/Icon/IconParagraph';
import { notify } from '../../events/notification-events';
import css from './add-invoice-entry.module.scss';
import { addInvoiceEntry } from './outgoing-mutations';

import { colors } from '@zf/utils/src/color';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refresh: () => void;
};

const InvoiceEntry = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { outgoingBankingTransaction, validationRef, refresh } = props;
  const { i18n, tenantReducer } = useAppContext();

  const [invoice, setInvoice] = React.useState<InvoiceType | null>(null);
  const [invoiceQuery] = React.useState({
    invoiceStatus: [invoiceStatus.approved, invoiceStatus.generated, invoiceStatus.settled],
    paymentProcessStatus: [paymentProcessStatus.paymentrejected, paymentProcessStatus.readyforpaymentcollection],
    paymentMethod:
      outgoingBankingTransaction.type === outgoingBankingTransactionType.paymentrefunds
        ? paymentMethod.sct
        : paymentMethod.sdd
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(!invoice);
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(invoice)]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (invoice) {
          await addInvoiceEntry(outgoingBankingTransaction.id, invoice.id, tenantReducer, i18n.lang);

          refresh();

          notify.success({
            content: i18n.getTranslation('actions.outgoing_mutation.add_invoice_entry_success')
          });
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.outgoing_mutation.add_invoice_entry_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['wrapper']}>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('actions.outgoing_mutation.add_invoice_entry_heading')}
      </Heading>
      <div className={css['inputs']}>
        <InvoiceAutoFill
          id="invoice"
          onChange={(val) => setInvoice(val[0])}
          query={invoiceQuery}
          selectedValues={[invoice ? invoice.id : '']}
          error={!invoice}
        />
      </div>
      <IconParagraph iconType="alert-circle" color={colors['orange-600']}>
        {i18n.getTranslation(
          `actions.outgoing_mutation.collection_date_warning_${
            outgoingBankingTransaction.type === outgoingBankingTransactionType.paymentrequests ? 'request' : 'refund'
          }`
        )}
      </IconParagraph>
    </div>
  );
});

export default InvoiceEntry;
