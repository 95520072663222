import { ICON_COLORS } from 'constants/icons';
import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import {
  MeasurementMeteringIssueDetails,
  MeteringIssue,
  MeteringIssueRowType
} from '@zf/api-types/metering/metering-issues';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDate } from '@zf/utils/src/date';

import useListPageContext from '../../../../../app-context/hooks/use-listpage-context';
import { useStore } from '../../../../../hooks/useStore';
import { MeterIssuesContext } from '../Context/Context';
import css from '../../../../devices/issues/hooks/meter-issues-list-page.module.scss';
import { formatDecimal } from '@zf/utils/src/number';

const useMeterIssuesListPage = (meter: MeterType) => {
  const { applicationStore } = useStore();
  const { getEnumTranslation, getTranslation, culture } = applicationStore;

  const processRecord = (issue: MeteringIssue): MeteringIssueRowType => {
    const details = issue.details as MeasurementMeteringIssueDetails;

    return {
      __id: issue.id,
      __entity: issue,
      issue: issue.meter && (
        <>
          <div className={css['issue-title']}>{getEnumTranslation('meteringIssueError', issue.error)}</div>
        </>
      ),
      status: (
        <NewStatusBadge
          color={ICON_COLORS[issue.status]}
          status_={getEnumTranslation('meteringIssueStatus', issue.status)}
        />
      ),
      noticedDateTime: issue.noticedDateTime
        ? formatDate(issue.noticedDateTime)
        : getTranslation('metering_list.never'),
      detail:
        typeof details.value !== 'undefined' &&
        typeof details.unitOfMeasure !== 'undefined' &&
        `${formatDecimal(details.value, culture)} ${getEnumTranslation('unitOfMeasure', details.unitOfMeasure)}`
    };
  };
  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPageContext<any, MeteringIssue, MeteringIssueRowType>({
    context: MeterIssuesContext,
    endpoint: '/me/meteringissues',
    processRecord,
    defaultQueryParams: { meterIds: meter.id }
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useMeterIssuesListPage;
