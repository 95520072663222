import { useAppContext } from 'app-context';
import { ActionButton } from 'design-system/Components';
import React, { ReactNode } from 'react';

import ListPageHeader from '@zf/stella-react/src/atoms/Heading/ListPageHeader';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import css from './filterBarNew.module.scss';
import FiltersNew from './FiltersNew';

type FilterBarProps = {
  actions?: ReactNode[];
  actionsExtraLeft?: ReactNode;
  searchValue?: string;
  infoGrid?: ReactNode;
  filterInputsOverview?: ReactNode[];
  leftFilters?: ReactNode;
  rightFilters?: ReactNode;
  searchPlaceHolder?: string;
  searchDebounce?: number;
  tags?: ReactNode;
  subject?: string;
  defaultTab?: string;
  tabItems?: TabItemType[];
  onSearchChange?: (query: string) => void;
  removeFilter?: (index: number) => void;
  removeAllFilters?: () => void;
  withActions?: boolean;
};

export default function FilterBarNew(props: FilterBarProps) {
  const {
    actions,
    actionsExtraLeft,
    tabItems,
    defaultTab,
    subject,
    infoGrid,
    leftFilters,
    rightFilters,
    tags,
    removeAllFilters = () => {}
  } = props;
  const { i18n } = useAppContext();

  return (
    <>
      <div className={css['top-bar']}>
        <div className={css['filter-bar']}>
          {tabItems && (
            <ListPageHeader
              className={css['list-page-header']}
              title={subject}
              defaultTab={defaultTab}
              tabItems={tabItems}
            />
          )}
        </div>
        <div className={css['main-grid']}>
          <div className={css['right-actions']}>
            {actionsExtraLeft}
            {actions && actions.length > 1 && (
              <ActionButton icon="actions" actions={actions}>
                {i18n.getTranslation('actions.actions')}
              </ActionButton>
            )}
            {actions && actions.length === 1 && actions}
          </div>
        </div>
      </div>

      {infoGrid}
      <FiltersNew
        className={css['filter-dropdown-container']}
        leftFilters={leftFilters}
        rightFilters={rightFilters}
        removeAllFilters={removeAllFilters}
      />

      <div className={css['filter-tags']}>{tags || <div />}</div>
    </>
  );
}
