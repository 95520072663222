import { DomainNotificationType } from '@zf/api-types/general';
import { isGuid } from '@zf/utils/src/regex';

export const getErrorText = (e: DomainNotificationType) => {
  if (!e.value || (e.value && isGuid(e.value))) {
    return e.message;
  }

  if (e.value && e.value.includes(';')) {
    let result = `${e.message}:<br/>`;

    e.value.split(';').forEach((v) => {
      result += `${v}<br/>`;
    });

    return result;
  }

  let label = e.value;

  const splitted = e.value.split('.');
  const latest = splitted[splitted.length - 1];

  if (latest) {
    label = latest;
  }

  return e.value ? `${label}: ${e.message}` : `- ${e.message}`;
};
