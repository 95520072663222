import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';
import MoreActionsMenu from '../../Menus/MoreActionsMenu';
import ClearOrganisationDialog from './dialogs/ClearOrganisationDialog';
import css from './org-card.module.scss';
import DeleteOrganisationDialog from './dialogs/DeleteOrganisationDialog';
import useRefresh from 'app-context/hooks/use-refresh';

type Props = {
  orgCode: string | undefined;
  orgIndex: number;
  setIsResetting: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function OrgActions(props: Props) {
  const { orgCode, orgIndex, setIsResetting } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { clickRef, onSubmit, validationRef } = useDialog();
  const { refresh } = useRefresh();

  const currentOrganisation = tenantReducer.state.tenantAuth?.organizations[orgIndex];

  return (
    <MoreActionsMenu
      id={`more-actions-${orgIndex}`}
      className={css['actions']}
      actions={[
        <DropdownAction
          id={`clear-organisation-${orgIndex}`}
          key={`clear-organisation-${orgIndex}`}
          icon="cancel"
          dialogTitle={i18n.getTranslation('tenant.clear_organisation')}
          dialogContent={
            <ClearOrganisationDialog
              ref={clickRef}
              orgIndex={orgIndex}
              setIsResetting={setIsResetting}
              currentOrganisation={currentOrganisation}
            />
          }
          onSubmit={onSubmit}
          buttonPositive={i18n.getTranslation('general.clear')}
          dialogType="danger"
        />,
        <DropdownAction
          id={`delete-organisation-${orgIndex}`}
          key={`delete-organisation-${orgIndex}`}
          icon="trashcan"
          dialogTitle={i18n.getTranslation('tenant.delete_organisation')}
          dialogContent={
            <DeleteOrganisationDialog
              ref={clickRef}
              orgIndex={orgIndex}
              orgCode={orgCode}
              validationRef={validationRef}
              onComplete={refresh}
              currentOrganisation={currentOrganisation}
            />
          }
          onSubmit={onSubmit}
          buttonPositive={i18n.getTranslation('general.delete')}
          dialogType="danger"
          validationRef={validationRef}
        />
      ]}
    />
  );
}
