import moment, { Moment } from 'moment';
import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';
import { ServiceConsumptionCompareGraphValueType } from '@zf/api-types/graph';
import { ConsumptionFlatValueType, FilterType } from '@zf/api-types/master-data/meter';

import { useAppContext } from '../../../../../app-context';
import { METHODS, sendRequest } from '../../../../../utils/request';
import { useTracked } from '../context/consumptions-context';
import useServiceConsumptions from './useServiceConsumptions';

export default function useCompareGraph() {
  const [state] = useTracked();
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const {
    locationId,
    timeStamp,
    queryParams,
    isCompareMode,
    filterTypes,
    selectedFilterTypeIds,
    setState,
    setIsLoading
  } = useServiceConsumptions();

  const setCompareGraphValues = (newCompareValues: ServiceConsumptionCompareGraphValueType[]) => {
    setState({ compareGraphValues: newCompareValues, graphIsLoading: false });
  };

  // Used for grouping our data on the X-axis
  const getPeriodKey = (dateTime: string) => {
    const date = moment(dateTime);

    if (queryParams.groupByPeriod === aggregationFrequency.yearly) {
      return date.format('MMMM');
    } else {
      // Monthly
      return `${i18n.getTranslation('measurement.day')} ${date.date().toString()}`;
    }
  };

  const getCompareFlatValues = async (
    filterType: FilterType,
    startDateTime: Moment,
    endDateTime: Moment
  ): Promise<ServiceConsumptionCompareGraphValueType[]> => {
    // We want the bars of a frequency higher
    let groupByPeriod = aggregationFrequency.monthly;

    if (queryParams.groupByPeriod === aggregationFrequency.monthly) {
      groupByPeriod = aggregationFrequency.daily;
    }

    const result = await sendRequest<ConsumptionFlatValueType[]>({
      request: {
        method: METHODS.GET,
        endpoint: `/me/ServiceConsumptions/${locationId}/flat`,
        query: {
          groupByPeriod: groupByPeriod,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          utilityType: filterType.utilityType,
          unitOfMeasure: filterType.unitOfMeasure
        }
      },
      tenantReducer,
      lang: i18n.lang
    });

    return result.data.map((d) => {
      return {
        ...d,
        groupByPeriod: queryParams.groupByPeriod,
        externalIdentifier: filterType.utilityType + filterType.unitOfMeasure,
        description: enumReducer.getTranslation('utilityType', filterType.utilityType),
        utilityType: filterType.utilityType,
        uom: filterType.unitOfMeasure,
        groupByKey: getPeriodKey(d.startDateTime)
      };
    });
  };

  const getCompareConsumptions = async (selectedTypes: FilterType[], tmpStartDate: Moment, tmpEndDate: Moment) => {
    let resultConsumption: ServiceConsumptionCompareGraphValueType[][] = [];

    if (selectedTypes.length > 0) {
      resultConsumption = await Promise.all(
        selectedTypes.map((type) => getCompareFlatValues(type, tmpStartDate, tmpEndDate))
      );
    }

    return resultConsumption;
  };

  const getCompareGraphValues = async () => {
    setIsLoading(true);

    const selectedTypes = filterTypes.filter((ft) => {
      return selectedFilterTypeIds.includes(ft.utilityType + ft.unitOfMeasure);
    });

    const resultCompareConsumptions = await Promise.all(
      queryParams.groupByPeriod === aggregationFrequency.yearly
        ? state.compareYears.map((year) => {
            return getCompareConsumptions(
              selectedTypes,
              moment([year]).startOf('year'),
              moment([year]).add(1, 'year').startOf('year')
            );
          })
        : state.compareMonths.map((compareMonth) => {
            return getCompareConsumptions(
              selectedTypes,
              moment().year(compareMonth.year).month(compareMonth.month).startOf('month'),
              moment().year(compareMonth.year).month(compareMonth.month).add(1, 'month').startOf('month')
            );
          })
    );

    // Loading: false is done in graphValues setter
    setCompareGraphValues([...resultCompareConsumptions.flat().flat()]);
  };

  React.useEffect(() => {
    if (isCompareMode) {
      getCompareGraphValues();
    }
  }, [queryParams, isCompareMode, timeStamp, state.compareYears, state.compareMonths, state.selectedFilterTypeIds]);

  return {
    compareGraphValues: state.compareGraphValues
  };
}
