import { observer } from 'mobx-react';
import React from 'react';

import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

const AddInvoiceForCustomerListItem = () => {
  const { applicationStore, customerStore } = useStore();
  const { pageActionPermissions, selectedCustomer } = customerStore;

  if (!pageActionPermissions.mayAddInvoice) return null;

  const { rootUrl, getTranslation } = applicationStore;
  const customer = selectedCustomer.customer;

  return (
    <DropdownAction
      id="invoice.add"
      key="add-invoice-list-item"
      actionType="link"
      icon="invoice-add"
      url={`${rootUrl}/invoices/add/${customer.id}`}
    >
      {getTranslation('actions.create_invoice_for_customer')}
    </DropdownAction>
  );
};

export default observer(AddInvoiceForCustomerListItem);
