import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { Card, CardBody } from '@zf/stella-react/src/atoms/Card';

import { ErrorBoundary } from '../../../../../../components/Error';
import FilterHeader from './FilterHeader';
import css from './outgoing-transaction-details.module.scss';
import OutgoingMutationsTableSection from './OutgoingMutationsTableSection';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  timeStamp: string;
  refresh: () => void;
};

export default function MutationsCard(props: Props) {
  const { outgoingBankingTransaction, refresh } = props;

  return (
    <Card id="mutations-card" width="4" className={css['card']}>
      <CardBody className={css['content']}>
        <FilterHeader />
        <ErrorBoundary>
          <OutgoingMutationsTableSection outgoingBankingTransaction={outgoingBankingTransaction} refresh={refresh} />
        </ErrorBoundary>
      </CardBody>
    </Card>
  );
}
