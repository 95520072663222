import { regularMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { WizardSectionHeader } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import CustomerSectionBody from './CustomerSectionBody';

const CustomerSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { regularMoveInWizardStore } = contractStore.moveInWizardBaseStore;
  const { setActiveStep } = regularMoveInWizardStore.regularMoveInWizardNavigator;

  return (
    <WizardSection id="customer-section" setActiveStep={() => setActiveStep(regularMoveInWizardSteps.customer)}>
      <WizardSectionHeader id={regularMoveInWizardSteps.customer} text={getTranslation('contracts.steps.customer')} />

      <CustomerSectionBody />
    </WizardSection>
  );
};

export default observer(CustomerSection);
