import React from 'react';

import { useAppContext } from '../../app-context/app-context';
import { Link } from '../../design-system/Components';
import { LinkProps } from '../../design-system/Components/Link/Link';
import css from './empty-link.module.scss';

export default function EmptyLink(props: LinkProps) {
  const { id, url, children } = props;
  const { i18n } = useAppContext();

  if (!children) {
    return (
      <Link id={id} className={css['empty-link']} url={url}>
        {i18n.getTranslation('general.empty')}
      </Link>
    );
  }

  return <Link {...props} />;
}
