import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Paragraph } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import HeaderLogo from '../HeaderLogo';
import css from './header.module.scss';
import OrganisationDropdown from './OrganisationDropdown';
import UserActions from './UserActions';

type Props = {
  className?: string;
};

const Header = (props: Props) => {
  const { className } = props;
  const { applicationStore } = useStore();
  const { tenantReducer } = applicationStore;
  const { user } = applicationStore.userStore;
  const { setToggleLables, toggleLables } = applicationStore;
  const { tenant, organization } = tenantReducer;

  const splitted = window.location.pathname.split('/');
  const currPath = splitted.splice(3).join('/');
  const showLogo = splitted.length > 2 && splitted[2] !== 'user-manager';

  return (
    <div className={classNames(css['header'], className)}>
      {showLogo && (
        <div className={css['header-left']}>
          <HeaderLogo />
        </div>
      )}

      <div className={css[showLogo ? 'header-right-wrapper' : 'header-right-wrapper-no-logo']}>
        <div className={css['header-right']}>
          {(process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging') && (
            <>
              <div className={css['dev-info']}>
                <Paragraph>{`Tenant: ${tenant?.id}`}</Paragraph>
                <Paragraph>{`Organisation: ${organization?.organizationId}`}</Paragraph>
              </div>
              <ZFToggle
                checked={toggleLables}
                onChange={(val) => {
                  setToggleLables(val);
                  window.location.reload();
                }}
              />
            </>
          )}

          <OrganisationDropdown currPath={currPath} />

          {user && <UserActions />}
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
