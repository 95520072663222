import React from 'react';

import { UpdateNumberSequenceType } from '@zf/api-types/settings-config';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import { processNumberSequences } from '../../../utils/number-sequence';
import NumberSequencesContent from './number-sequences-content';

type Props = {
  config: UseConfigReturnType<UpdateNumberSequenceType[]>;
};

export type NumberSequenceValidatorType = {
  numberSequences: UpdateNumberSequenceType[];
};

export default function NumberSequences(props: Props) {
  const { config } = props;

  const { Provider, useTracked } = useCreateContext<UpdateNumberSequenceType[]>(
    config.currentConfig ? processNumberSequences(config.currentConfig.responseData) : []
  );

  return (
    <Provider>
      <NumberSequencesContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
