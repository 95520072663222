import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { Query } from './types';
import SearchBar, { SearchBarProps } from 'components/input/SearchBar';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

type Props = {
  getValues: UseFormGetValues<Query>;
  setValue: UseFormSetValue<Query>;
  overviewCount: Record<string, number>;
};

const InlineInputFieldSearchBar = InlineInputField<SearchBarProps>(SearchBar);

const LeftFilters: FC<Props> = ({ getValues, setValue, overviewCount }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div>
      <InlineInputFieldSearchBar
        id="filter-searchbar"
        onChange={(val) => setValue('flexSearch', val)}
        value={getValues('flexSearch') || ''}
        placeholder={getTranslation('customer.transactions.search')}
        debounceTime={500}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            //@ts-ignore
            setValue('flexSearch', e.target.value);
          }
        }}
      />
    </div>
  );
};

export default observer(LeftFilters);
