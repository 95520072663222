import clone from 'clone';
import React from 'react';

import { paymentTermsFrequency } from '@zf/api-types/enums';
import { UpdatePaymentTermType } from '@zf/api-types/product';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import CommitSection from '../../../components/config/commit-section';
import { DeleteIcon } from '../../../components/Icon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';

type Props = {
  config: UseConfigReturnType<UpdatePaymentTermType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdatePaymentTermType[]>,
    React.Dispatch<ValidatorCtxAction<UpdatePaymentTermType[]>>
  ];
};

const InlineInput = InlineInputField<InputFieldProps>(InputField);
const InlinePaymentTermFreqDropdown = InlineInputField<SimpleDropdownProps<paymentTermsFrequency>>(SimpleDropdown);

const createApiFriendlyValues = (term: UpdatePaymentTermType) => {
  return {
    name: term.name,
    amount: typeof term.amount === 'string' ? parseInt(term.amount) : term.amount,
    paymentTermsFrequency: term.paymentTermsFrequency
  };
};

export default function PaymentTermsContent(props: Props) {
  const { config } = props;
  const { sort } = config;
  const { i18n, enumReducer } = useAppContext();

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 2,
      label: i18n.getTranslation('general.name'),
      dataKey: 'name'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('payment_terms.frequency'),
      dataKey: 'frequency'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('general.amount'),
      dataKey: 'amount'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const { values, isDirty, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator<UpdatePaymentTermType[]>(props.useTracked);

  const { addEntity, deleteEntity, handleSave } = useContextCRUD(props.useTracked);

  const addPaymentTerm = () => {
    addEntity({ name: '', amount: 1, paymentTermsFrequency: '' as paymentTermsFrequency });
  };

  const handlePaymentTermsSave = async () => {
    return handleSave(
      createApiFriendlyValues,
      'name',
      '/cfg/PaymentTerms',
      i18n.getTranslation('payment_terms.update_success'),
      i18n.getTranslation('payment_terms.update_fail')
    );
  };

  const setTerm = (
    index: number,
    value: UpdatePaymentTermType[keyof UpdatePaymentTermType],
    dataKey: keyof UpdatePaymentTermType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdatePaymentTermType[]
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handlePaymentTermsSave();
    }
  };

  const paymentTermsRows = values.map((term, index) => {
    return {
      name: (
        <InlineInput
          id={`payment_term.name.index-${index}`}
          value={term.name}
          onChange={(val) => setTerm(index, val, 'name')}
          onKeyDown={handleKeyDown}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!term.name}
          error={!term.name}
        />
      ),
      frequency: (
        <InlinePaymentTermFreqDropdown
          id={`payment_term.frequency.index-${index}`}
          values={enumReducer.getEnum('paymentTermsFrequency')}
          selectedValues={[values[index].paymentTermsFrequency as paymentTermsFrequency]}
          onChange={(val) => setTerm(index, val[0], 'paymentTermsFrequency')}
        />
      ),
      amount: (
        <InlineInput
          id={`payment_term.amount.index-${index}`}
          value={term.amount.toString()}
          onChange={(val) => setTerm(index, val, 'amount')}
          onKeyDown={handleKeyDown}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`payment_term.delete.index-${index}`}
          tooltipFor="payment-terms-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handlePaymentTermsSave} isDirty={isDirty} />
      <CardsContainer>
        <Card width="2" id="payment-terms-card">
          <CardHeader
            extraRight={
              <Button id="payment_term.add" type="text" icon="plus" onClick={addPaymentTerm}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('payment_terms.payment_terms')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="payment-terms-table"
              rows={paymentTermsRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
            />
          </CardBody>
        </Card>
      </CardsContainer>
    </>
  );
}
