import { CustomerType } from '@zf/api-types/master-data/customer';
import { TransactionType } from '@zf/api-types/transactions';
import { formatDateForExport, formatDateTimeForExport } from '@zf/utils/src/date';
import ExportToExcel from 'components/Button/Excel/ExportToExcel';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useState } from 'react';
import * as enums from '@zf/api-types/enums';
import { METHODS, sendRequest } from 'utils/request';
import { PagedResponseType } from '@zf/api-types/api';
import { useAppContext } from 'app-context';

type Props = {
  customer: CustomerType;
};

const ExportCustomerTransactions = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  //Get all transactions
  const { customer } = props;
  const [transactions, setTransactions] = useState<TransactionType[]>();
  const { i18n, tenantReducer } = useAppContext();

  const getExportData = async () => {
    let continuationToken = '';
    const results: TransactionType[] = [];
    while (continuationToken !== null) {
      const result = await sendRequest<PagedResponseType<TransactionType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/bill/Transactions',
          query: { customeruuid: customer.id }
        },
        customHeaders: {
          continuationToken: continuationToken
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      });

      continuationToken = result.data.nextPageToken;

      results.push(...result.data.results);
    }

    setTransactions(results);
  };

  useEffect(() => {
    getExportData();
  }, []);

  const getTransactionsExportArray = (transactions: TransactionType[] | undefined) => {
    const exportArray = [];

    exportArray.push([
      'Type',
      'Detail',
      'Due date',
      'Amount',
      'Open amount',
      'Company bank account',
      'Reversed',
      'Created date'
    ]);

    transactions?.forEach((transaction) => {
      //Logic for defining the transaction's type 'title' and 'subtitle'
      let titles: { title: string; subtext: string | undefined } = {
        title: '',
        subtext: ''
      };

      switch (transaction.transactionType) {
        case enums.transactionType.invoice:
          titles = {
            title: getEnumTranslation('invoiceType', String(transaction.referenceDetails?.parameters?.invoiceType)),
            subtext: transaction.referenceDetails?.parameters?.invoiceNum
          };
          break;
        case enums.transactionType.payment:
          titles = {
            title:
              transaction?.incomingBankingTransactionId === null && transaction?.outgoingBankingTransactionId === null
                ? getTranslation('customer.transactions.title.adjust-balance')
                : transaction.referenceDetails?.parameters?.paymentType === enums.paymentType.incomingtransfer ||
                  transaction.referenceDetails?.parameters?.paymentType === enums.paymentType.directdebit
                ? getTranslation('customer.transactions.title.incoming-transaction')
                : getTranslation('customer.transactions.title.outgoing-transaction'),

            subtext: `${transaction.referenceDetails?.parameters?.paymentReference} ${
              transaction.referenceDetails?.parameters?.paymentMethod
                ? '(' + transaction.referenceDetails?.parameters?.paymentMethod.toUpperCase() + ')'
                : ''
            } `
          };
          break;
        case enums.transactionType.reversal:
          titles = {
            title: getEnumTranslation('transactionType', String(transaction.transactionType)),
            subtext: `${transaction.referenceDetails?.parameters?.paymentReference} ${
              transaction.referenceDetails?.parameters?.paymentMethod
                ? '(' + transaction.referenceDetails?.parameters?.paymentMethod + ')'
                : ''
            }`
          };
          break;
        case enums.transactionType.collectionfee:
          titles = {
            title: getEnumTranslation('transactionType', String(transaction.transactionType)),
            subtext: `${getTranslation('collection_case.step')}: ${transaction.referenceDetails?.parameters?.stepName}`
          };
          break;
        case enums.transactionType.writeoff:
          titles = {
            title: getEnumTranslation('transactionType', String(transaction.transactionType)),
            subtext: `${getTranslation('collection_case.step')}: ${transaction.referenceDetails?.parameters?.stepName}`
          };
          break;
        default: {
          return {
            icon: undefined,
            title: undefined,
            subtext: undefined,
            tooltip: undefined
          };
        }
      }

      exportArray.push([
        titles.title || '',
        titles.subtext || '',
        formatDateForExport(transaction.transactionDueDateTime) || '',
        transaction.transactionAmount,
        transaction.transactionOpenAmount,
        transaction.companyBankAccountId || 'NA',
        (transaction.reversed ? 'Yes' : 'No') || '',
        formatDateTimeForExport(transaction.createdDateTime) || ''
      ]);
    });

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      icon="export"
      disabled={false}
      toExportArray={() => getTransactionsExportArray(transactions)}
      title={getTranslation('customer.transactions.export_transactions')}
      ws_name={getTranslation('nav_detail.transactions')}
    />
  );
};

export default ExportCustomerTransactions;
