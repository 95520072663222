import classNames from 'classnames';
import React from 'react';

import css from './drag-and-drop.module.scss';

type Props = {
  id: string;
  className?: string;
  children: (childProps: { isDragging: boolean }) => React.ReactNode;
  onDrop: (e: React.DragEvent<HTMLSpanElement>) => void;
  onTrigger?: () => void;
};

export default function Droppeable(props: Props) {
  const { className, id, onDrop, onTrigger, children } = props;

  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLSpanElement>) => {
    e.preventDefault();

    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLSpanElement>) => {
    e.preventDefault();
    handleDragLeave();

    onDrop(e);
  };

  return (
    <span
      id={id}
      className={classNames(
        css['droppeable'],
        {
          [css['droppeable-dragged']]: isDragging
        },
        className
      )}
      role="button"
      aria-label="Droppeable container"
      tabIndex={0}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={onTrigger}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && onTrigger) {
          onTrigger();
        }
      }}
    >
      {children({
        isDragging
      })}
    </span>
  );
}
