import React from 'react';

import { DeleteOrCreditInvoiceType, InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  onComplete?: Function;
  selectedRows?: Array<InvoiceRowType>;
  invoiceToHandle?: DeleteOrCreditInvoiceType;
  credit: boolean;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, invoiceToHandle, credit, refreshCounts } = props;
  const { i18n } = useAppContext();
  const { invoiceStore } = useStore();

  let invoicesToHandle: Array<DeleteOrCreditInvoiceType> = [];

  if (selectedRows) {
    invoicesToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag,
        invoiceNum: row.__entity.invoiceNum
      };
    });
  } else if (invoiceToHandle) {
    invoicesToHandle = [invoiceToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (credit) {
          const newInvoices: InvoiceType[] = await Promise.all(
            invoicesToHandle.map((invoice) => invoiceStore.credit(invoice, !selectedRows))
          );
          if (onComplete) onComplete(newInvoices);
          if (refreshCounts) refreshCounts();
        } else {
          await Promise.all(invoicesToHandle.map((invoice) => invoiceStore.deleteInvoice(invoice, !selectedRows)));
          if (onComplete) onComplete([], invoicesToHandle);
          if (refreshCounts) refreshCounts();
        }

        notify.success({
          content: credit
            ? i18n.getTranslation('actions.invoice.credit_success')
            : i18n.getTranslation('actions.invoice.delete_success')
        });
      } catch (e) {
        notify.error({
          content: credit
            ? i18n.getTranslation('actions.invoice.credit_failed')
            : i18n.getTranslation('actions.invoice.delete_failed'),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      {credit
        ? i18n.getTranslation('actions.invoice.credit', { amount: invoicesToHandle.length.toString() })
        : i18n.getTranslation('actions.invoice.delete', { amount: invoicesToHandle.length.toString() })}
    </Paragraph>
  );
});
