import { ZFErrorType } from '@zf/api-types/general';
import { downloadBlob } from '@zf/utils/src/download';

import { notify } from '../../events/notification-events';
import { createHeader, METHODS, RequestResultType, sendRequest } from '../../utils/request';
import { useAppContext } from '../app-context';
import { TenantReturnValue } from './use-tenant-reducer';

export type ReturnValue = {
  downloadFiles: (
    ids: string[],
    zipEndpoint: string,
    singleFileEndpoint: string,
    idKey: string,
    fileName?: string
  ) => Promise<void>;
  downloadSingleFile: (endpoint: string, tenantReducer: TenantReturnValue, data?: any) => Promise<void>;
  processDownloadedData: (res: RequestResultType<BlobPart>) => void;
};

type BodyType = {
  [key: string]: string[] | string;
};

export default function useDownload(): ReturnValue {
  const { i18n, tenantReducer } = useAppContext();

  const notifyError = (e: ZFErrorType) => {
    notify.error({
      content: i18n.getTranslation('general.download_fail'),
      error: e
    });
  };

  const processDownloadedData = (res: RequestResultType<BlobPart>) => {
    const splittedByComma = res.headers['content-disposition'].split(';');
    let fileName = '';

    if (splittedByComma[1]) {
      const fileComponents = splittedByComma[1].split('=');
      if (fileComponents[1]) fileName = fileComponents[1].replace(/['"]+/g, '');
    }

    downloadBlob(fileName, new Blob([res.data], { type: res.headers['content-type'] }));
  };

  async function downloadSingleFile(endpoint: string, tenantReducer: TenantReturnValue, data?: any) {
    try {
      const res = await sendRequest<BlobPart>(
        {
          responseType: 'arraybuffer',
          request: {
            method: METHODS.POST,
            endpoint: endpoint,
            data: data ? data : null
          },
          customHeaders: createHeader({
            Accept: 'application/octet-stream'
          }),
          tenantReducer,
          lang: i18n.lang
        },
        true
      );

      processDownloadedData(res);
    } catch (e) {
      // @ts-ignore
      notifyError(e);
    }
  }

  async function downloadFiles(
    ids: Array<string>,
    zipEndpoint: string,
    singleFileEndpoint: string,
    idKey: string,
    fileName?: string
  ) {
    if (ids.length > 1) {
      try {
        const body: BodyType = {};
        body[idKey] = ids;

        if (fileName) body.resultingFileName = fileName;

        const res = await sendRequest<BlobPart>(
          {
            responseType: 'arraybuffer',
            request: {
              method: METHODS.POST,
              endpoint: zipEndpoint,
              data: body
            },
            customHeaders: createHeader({
              Accept: 'application/octet-stream'
            }),
            tenantReducer,
            lang: i18n.lang
          },
          true
        );

        fileName = fileName ? fileName : res.headers['content-disposition'].split(';')[2].split("''")[1];

        downloadBlob(fileName, new Blob([res.data], { type: res.headers['content-type'] }));
      } catch (e) {
        // @ts-ignore
        notifyError(e);
      }
    } else {
      downloadSingleFile(`${singleFileEndpoint}${ids[0]}`, tenantReducer);
    }
  }

  return { downloadFiles, downloadSingleFile, processDownloadedData };
}
