import clone from 'clone';

import { CommunicationPreferencesType, InvoiceCommunicationPreferenceType } from '@zf/api-types/master-data/customer';
import { communicationType, invoiceType } from '@zf/api-types/enums';

import {
  CommPrefsValidatorType,
  ComPreferenceType
} from '../cards/CommunicationPreferences/CommunicationPreferencesCardContent';
import { CreateCustomerType } from '../features/customer/wizard/CustomerWizard';

export const getSelectedValue = (invoiceType_: invoiceType, values: CreateCustomerType | CommPrefsValidatorType) => {
  let invoicePref = undefined;

  if (values && values.communicationPreferences) {
    invoicePref = values.communicationPreferences.invoiceCommunicationPreferences.find((pref) => {
      return pref.invoiceType === invoiceType_;
    });
  }

  return invoicePref ? [invoicePref.communicationType] : [''];
};

export const initialInvoiceCommunicationPrefs: InvoiceCommunicationPreferenceType[] = [
  {
    invoiceType: invoiceType.advance,
    communicationType: communicationType.none
  },
  {
    invoiceType: invoiceType.invoice,
    communicationType: communicationType.none
  },
  {
    invoiceType: invoiceType.endnote,
    communicationType: communicationType.none
  },
  {
    invoiceType: invoiceType.creditnote,
    communicationType: communicationType.none
  },
  {
    invoiceType: invoiceType.incidentalnote,
    communicationType: communicationType.none
  },
  {
    invoiceType: invoiceType.correctionnote,
    communicationType: communicationType.none
  }
];

export const setSelectedValue = (
  invoiceType_: invoiceType,
  commPreference: ComPreferenceType,
  values: CreateCustomerType | CommPrefsValidatorType,
  setValue: (
    value: Partial<{
      communicationPreferences: CommunicationPreferencesType;
      deviatingLanguage: boolean | null;
      deviatingCommunicationType: boolean;
      commPreference: ComPreferenceType;
    }>,
    initialApiSet?: boolean | undefined,
    backup?: boolean | undefined
  ) => void
) => {
  const invoicePrefsClone = clone(values.communicationPreferences);

  let hit = false;

  for (const pref of invoicePrefsClone.invoiceCommunicationPreferences) {
    if (pref.invoiceType === invoiceType_) {
      pref.communicationType = commPreference;
      hit = true;
    }
  }

  if (!hit) {
    invoicePrefsClone.invoiceCommunicationPreferences = [
      ...invoicePrefsClone.invoiceCommunicationPreferences,
      { invoiceType: invoiceType_, communicationType: commPreference }
    ];
  }

  setValue({ communicationPreferences: invoicePrefsClone });
};
