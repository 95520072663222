import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { useStore } from '../../../../hooks/useStore';
import SearchBar from '../../../input/SearchBar';
import FilesStore from '../logic/FilesStore';
import useFilesFilter from '../logic/useFilesFilter';

type Props<T> = {
  filesStore: FilesStore<T>;
  customerId?: string;
  includeContractFilter?: boolean;
};

const Filters = <T,>(props: Props<T>) => {
  const { filesStore, customerId, includeContractFilter } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { filterStore } = filesStore;
  const { queryParams, setQueryParam, resetAll } = filterStore;

  const { filterTags, filters } = useFilesFilter(
    queryParams,
    setQueryParam,
    resetAll,
    customerId,
    includeContractFilter
  );

  const searchBar = useMemo(() => {
    return (
      <SearchBar
        id="flexSearch"
        onChange={(flexSearch) => setQueryParam({ flexSearch })}
        value={queryParams.flexSearch}
        placeholder={getTranslation('general.search')}
        debounceTime={500}
      />
    );
  }, [queryParams.flexSearch, setQueryParam, getTranslation]);

  return (
    <>
      {filterTags}
      {filters}
      {searchBar}
    </>
  );
};

export default observer(Filters);
