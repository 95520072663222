import { addLocationsMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/AddLocationsMoveInWizardStore';
import { WizardStep } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

const AddLocationsMoveInWizardNavigation = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addLocationsMoveInWizardNavigator, showBillingSection } =
    contractStore.moveInWizardBaseStore.addLocationsMoveInWizardStore;
  const { activeStep, stepValidations, setActiveStep } = addLocationsMoveInWizardNavigator;

  return (
    <>
      <WizardStep
        headerId={addLocationsMoveInWizardSteps.movein}
        text={getTranslation('contracts.steps.move_in')}
        isActive={activeStep === addLocationsMoveInWizardSteps.movein}
        stepValidation={stepValidations[addLocationsMoveInWizardSteps.movein]}
        setIsActive={() => setActiveStep(addLocationsMoveInWizardSteps.movein)}
      />
      <WizardStep
        headerId={addLocationsMoveInWizardSteps.locations}
        text={getTranslation('contracts.steps.location')}
        isActive={activeStep === addLocationsMoveInWizardSteps.locations}
        stepValidation={stepValidations[addLocationsMoveInWizardSteps.locations]}
        setIsActive={() => setActiveStep(addLocationsMoveInWizardSteps.locations)}
      />

      {showBillingSection && (
        <WizardStep
          headerId={addLocationsMoveInWizardSteps.billing}
          text={getTranslation('contracts.steps.billing', { stepNum: 3 })}
          isActive={activeStep === addLocationsMoveInWizardSteps.billing}
          stepValidation={stepValidations[addLocationsMoveInWizardSteps.billing]}
          setIsActive={() => setActiveStep(addLocationsMoveInWizardSteps.billing)}
        />
      )}
    </>
  );
};

export default observer(AddLocationsMoveInWizardNavigation);
