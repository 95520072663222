import { observer } from 'mobx-react';
import React from 'react';

import { BreadCrumb, Crumb, CrumbDivider } from '../../../../components/BreadCrumb';
import { CrumbHeader } from '../../../../components/Header';
import { SuspenseSpinner } from '../../../../components/suspense';
import { useStore } from '../../../../hooks/useStore';
import useImportJobDetail from '../../hooks/useImportJobDetail';
import ImportJobTabContainer from '../ImportJobTabContainer';
import ProcessingDetails from '../processing-details';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

const ImportJob = (props: Props) => {
  const { urlParams = { importJobId: '' }, navigate } = props;

  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const { importJob, status, navigator } = useImportJobDetail(urlParams.importJobId);

  if (!importJob) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="list" text={getTranslation('import_jobs.import_jobs')} url={`${rootUrl}/import-jobs`} />
            <CrumbDivider />
            <Crumb icon="import-job" text={importJob.fileName} />
          </BreadCrumb>
        }
      />
      {importJob.importType === 'migration' ? (
        <SuspenseSpinner>
          <ProcessingDetails importJob={importJob} status={status} navigate={navigate} navigator={navigator} />
        </SuspenseSpinner>
      ) : (
        <ImportJobTabContainer importJob={importJob} status={status} navigate={navigate} navigator={navigator} />
      )}
    </>
  );
};

export default observer(ImportJob);
