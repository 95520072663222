import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { useAppContext } from '../../../app-context';
import { Paragraph } from '../../../design-system/Foundation';
import { ErrorBoundary } from '../../Error';
import css from './org-picker.module.scss';
import OrgCard from './OrgCard';
import OrgPickerFooter from './OrgPickerFooter';

export default function OrgPicker() {
  const { tenantReducer, authReducer, i18n } = useAppContext();
  const { tenant } = tenantReducer.state;
  const { isSuperUser, isAdmin } = authReducer;

  if (!tenantReducer.state.tenantAuth || !tenant) return null;

  const tenantAuth = tenantReducer.state.tenantAuth;
  const hasAuth = isSuperUser() || isAdmin();

  const mutationDateTime = moment().toISOString();

  return (
    <div className={classNames(css['org-picker'])}>
      {tenantAuth.organizations.length > 0 ? (
        <div className={classNames(css['org-grid'], { [css['org-grid-footer']]: hasAuth })}>
          {tenantAuth.organizations.map((item, key) => {
            return (
              <ErrorBoundary key={key}>
                <OrgCard index={key} tenant={tenant} organization={item} mutationDateTime={mutationDateTime} />
              </ErrorBoundary>
            );
          })}
        </div>
      ) : (
        <div className={css['no-organisations']}>
          <img
            src="https://cdn.zerofriction.co/shared/assets/emptyStates/organisation.png"
            alt="no organisations"
            title="no organisations"
          />
          <Paragraph>{i18n.getTranslation('tenant.no_organisations')}</Paragraph>
          <Paragraph>{i18n.getTranslation('tenant.no_organisations_info')}</Paragraph>
        </div>
      )}
      {hasAuth && <OrgPickerFooter />}
    </div>
  );
}
