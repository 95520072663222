import { useReducer } from 'react';

import { uiCulture } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { cookies, getCookie } from '@zf/utils/src/cookie';
import { DEFAULT_CULTURE } from '@zf/utils/src/internationalisation';

import { templateStrings } from '../../utils/lang';

type State = {
  languageFiles: Map<uiCulture, Record<string, string>> | undefined;
  languageData: Record<string, string> | undefined;
  lang: uiCulture;
  culture: string;
};

export type LangReturnValue = {
  lang: uiCulture;
  culture: string;
  getTranslation: (key: string, params?: Record<string, string | number>) => string;
  setLang: (newLang: uiCulture) => void;
  setCulture: (newCulture: string) => void;
};

/**
 * @deprecated, use applicationstore instead
 */
export default function useLang(): LangReturnValue {
  const getLangFiles = () => {
    const langFiles = new Map<uiCulture, Record<string, string>>();

    const context = require.context('lang', true, /.json$/);
    context.keys().forEach((key) => {
      if (key.charAt(0) === 'l') {
        return;
      }

      const fileName = key.replace('./', '');
      const resource = require(`lang/${fileName}`);
      const namespace = fileName.replace('.json', '') as uiCulture;
      langFiles.set(namespace, resource);
    });

    return langFiles;
  };

  const langFiles = getLangFiles();
  const language =
    (getCookie(cookies.userLanguage) as uiCulture) || (navigator.language.split('-')?.[0] as uiCulture) || uiCulture.en;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    languageFiles: langFiles,
    languageData: langFiles.get(language) as Record<string, string>,
    lang: language,
    culture: DEFAULT_CULTURE
  });

  const getLanguageData = (newLang: uiCulture) => {
    return { ...state.languageFiles?.get(newLang) };
  };

  const setLang = (newLang: uiCulture) => setState({ lang: newLang, languageData: getLanguageData(newLang) });

  const setCulture = (newCulture: string) => setState({ culture: newCulture });

  const getTranslation = (key: string, params?: Record<string, string | number>) => {
    // While the glob import is ongoing languageData is undefined
    if (!state.languageData) return '';

    let localised = state.languageData[key];

    if (sessionStorage.getItem('toggleLables') === 'true') return key;

    if (!localised) {
      // Check if language data doesn't exist in english
      const englishData = getLanguageData(uiCulture.en);

      if (!englishData[key]) {
        // If it doesn't exist throw error
        throw new Error(`Missing translation for ${key}`);
      } else {
        // Return the English version
        localised = englishData[key];
      }
    }

    if (params) localised = templateStrings(localised, params);

    return localised;
  };

  return { lang: state.lang, culture: state.culture, getTranslation, setLang, setCulture };
}
