import { PagedResponseType } from '@zf/api-types/api';

import { METHODS } from '../../utils/request';
import RootStore from '../stores';

export default class SingletonService {
  public rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // Generic function to support singleton pattern
  getSingleVariable = async <V>(endpoint: string, variable: V | undefined, setter: (newVariable: V) => void) => {
    try {
      if (!variable) {
        const res = (
          await this.rootStore.applicationStore.sendRequest<V>({
            request: {
              method: METHODS.GET,
              endpoint
            }
          })
        ).data;

        setter(res);

        return res;
      } else {
        return variable;
      }
    } catch (e) {
      this.rootStore.errorStore.pushError(e);
      return variable as V;
    }
  };

  // Generic function to support singleton pattern
  getPagedVariable = async <V>(endpoint: string, variable: V[] | undefined, setter: (newVariable: V[]) => void) => {
    try {
      if (!variable) {
        const res = (
          await this.rootStore.applicationStore.sendRequest<PagedResponseType<V>>({
            request: {
              method: METHODS.GET,
              endpoint
            }
          })
        ).data.results;

        setter(res);

        return res;
      } else {
        return variable;
      }
    } catch (e) {
      this.rootStore.errorStore.pushError(e);
      return variable as V[];
    }
  };
}
