import classNames from 'classnames';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';

import { Spinner } from '../../Foundation';
import css from './base-button.module.scss';

export type ButtonType = 'primary' | 'secondary' | 'text';
export type SubmitType = 'submit' | 'button';

export type Props = {
  id: string;
  children?: React.ReactNode;
  SubmitType?: SubmitType;
  className?: string;
  style?: React.CSSProperties;
  type?: ButtonType;
  disabled?: boolean;
  onClick: Function;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
};

export default function BaseButton(props: Props) {
  const {
    id,
    children,
    className,
    style,
    type = 'primary',
    SubmitType = 'button',
    disabled = false,
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const isMounted = useRef(true);

  const handleClick = async () => {
    if (!disabled && !isLoading) {
      setIsLoading(true);
      await onClick();
      // Prevents unmounted setState
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <button
      data-testid={id}
      id={id}
      className={classNames(css['base-button'], className)}
      style={style}
      type={SubmitType}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading ? <Spinner size="xsmall" inverted={type === 'primary'} /> : children}
    </button>
  );
}
