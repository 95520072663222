import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { dataFrequency } from '@zf/api-types/enums';
import {
  AddMeasurementRequestType,
  ExternalChannelType,
  MeasurementPair,
  MeasurementType,
  MeterType
} from '@zf/api-types/master-data/meter';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { MINUTESMAP } from '@zf/utils/src/date';

import MeterAutoFill from '../../../components/Autofills/MeterAutoFill';
import MetersDropdown from '../../../components/Dropdown/meter-dropdown/MetersDropdown';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { formatActiveMeterText } from '../../../utils/meter';
import css from './measurement.module.scss';
import MeasurementTimeLine from './MeasurementTimeLine';

type Props = {
  meter?: MeterType | null;
  locationId?: string;
  mutationDateTime: Moment;
  meterIndex?: number;
  value: AddMeasurementRequestType;
  measurementIndex: number;
  metersQuery: Record<string, any>;
  useCase?: 'add' | 'edit';
  isValidating?: boolean;
  measurement?: MeasurementType | undefined;
  validateChannel?: (channel: ExternalChannelType) => boolean;
  dispatchValue: (value: Partial<AddMeasurementRequestType>) => void;
  setIsValidating?: (isValidating: boolean) => void;
  closeDialog?: () => void | undefined;
};

const Measurement = (props: Props) => {
  const {
    dispatchValue,
    validateChannel,
    setIsValidating,
    closeDialog,
    meterIndex = 0,
    value,
    measurementIndex,
    meter,
    mutationDateTime,
    locationId,
    metersQuery,
    useCase,
    isValidating,
    measurement
  } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getPreviousMeasurements } = meterStore.measurementService;

  const [measurementPair, setMeasurementPair] = useState<MeasurementPair | undefined | null>();

  const checkWhereUsed = () => {
    if (locationId) {
      // If we are on a location details page, get meters for this location
      return (
        <MetersDropdown
          id={`${meterIndex}-${measurementIndex}`}
          queryParams={{ sluuids: locationId }}
          selectedValue={value.meter?.id || ''}
          onChange={(val) =>
            dispatchValue({
              meter: val[0]
            })
          }
          error={!value.meter}
        />
      );
    } else {
      const metertext = formatActiveMeterText(meter);

      return (
        <MeterAutoFill
          id={`searchMeter-${meterIndex}-${measurementIndex}`}
          onChange={(val) =>
            dispatchValue({
              meter: val[0]
            })
          }
          initialValue={metertext}
          selectedValues={[value.meter?.id || '']}
          placeholder={getTranslation('actions.meter.search_placeholder')}
          query={metersQuery}
          error={!value.meter}
          selectSingleByDefault
        />
      );
    }
  };

  const availableChannels: DropdownValuesType<string>[] = [];

  if (value.meter) {
    value.meter.channels.forEach((chan) => {
      if (mutationDateTime.isBetween(chan.startDateTime, chan.endDateTime, undefined, '[]')) {
        const toPush: DropdownValuesType<string> = {
          value: chan.externalIdentifier,
          text: chan.description || chan.externalIdentifier
        };

        if (validateChannel && validateChannel(chan)) {
          availableChannels.push(toPush);
        } else if (!validateChannel) {
          availableChannels.push(toPush);
        }
      }
    });
  }

  const getCurrentFrequency = () => {
    let channel: ExternalChannelType | undefined;

    if (value.meter) {
      channel = value.meter.channels.find((chan) => chan.externalIdentifier === value.channelId);
    }

    return channel?.dataFrequency || dataFrequency.na;
  };

  useEffect(() => {
    if (value.channelId && value.meter) {
      if (value.hour)
      {
        const date = moment(mutationDateTime).clone().hour(parseInt(value.hour));
      
          getPreviousMeasurements(value.meter.id, date).then((data) => {
            const pair = data.pairs.find((p) => p.externalChannelId === value.channelId);
            setMeasurementPair(pair || null);
          });
      }
      else
      {
        getPreviousMeasurements(value.meter.id, mutationDateTime).then((data) => {
          const pair = data.pairs.find((p) => p.externalChannelId === value.channelId);
          setMeasurementPair(pair || null);
        });
      }
    }
  }, [value.channelId, value.hour, mutationDateTime.toISOString(), value.meter]);

  const currentFreq = getCurrentFrequency();
  const hoursDisabled = currentFreq === dataFrequency.p1d || currentFreq === dataFrequency.na;
  const minutesDisabled =
    currentFreq === dataFrequency.pt1h || currentFreq === dataFrequency.p1d || currentFreq === dataFrequency.na;
  const minutes = MINUTESMAP.get(currentFreq);

  return (
    <div>
      <FlexElements gap="24">
        <div className={css['input-wrapper']}>{checkWhereUsed()}</div>

        <div className={css['input-wrapper']}>
          {availableChannels && availableChannels.length > 0 ? (
            <SimpleDropdown
              id={`channel-dropdown-${meterIndex}-${measurementIndex}`}
              selectedValues={[value.channelId]}
              values={availableChannels.length > 0 ? availableChannels : []}
              onChange={(val) => dispatchValue({ channelId: val[0] })}
              placeholder={getTranslation('meter.channel')}
              error={!value.channelId}
              selectSingleItemByDefault
            />
          ) : (
            <Paragraph className={css['paragraph']}>{getTranslation('meter.no_active_channels')}</Paragraph>
          )}
        </div>
      </FlexElements>

      {availableChannels && availableChannels.length > 0 && (
        <MeasurementTimeLine
          value={value}
          currentFreq={currentFreq}
          hoursDisabled={hoursDisabled}
          minutesDisabled={minutesDisabled}
          minutes={minutes}
          measurementPair={measurementPair}
          mutationDateTime={mutationDateTime}
          measurementIndex={measurementIndex}
          meterIndex={meterIndex}
          isValidating={isValidating}
          dispatchValue={dispatchValue}
          useCase={useCase}
          setIsValidating={setIsValidating}
          closeDialog={closeDialog}
          measurement={measurement}
        />
      )}
    </div>
  );
};

export default observer(Measurement);
