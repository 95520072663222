import moment, { Moment } from 'moment';
import React from 'react';

import { incomingMutationStatus, incomingMutationType } from '@zf/api-types/enums';

import { useAppContext } from '../../../../../../../app-context/app-context';
import FilterInputs from '../../../../../../../components/Filters/filter-inputs';
import FilterTag from '../../../../../../../components/Filters/filter-tag';
import FilterTags from '../../../../../../../components/Filters/filter-tags';
import EnumFilterSelect from '../../../../../../../components/input/EnumFilterSelect';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import useOutgoingMutationsFilter from '../../../../outgoing-banking-transactions/detail-page/detail/hooks/useOutgoingMutationsFilter';
import { IncomingMutationQueryParamsType, useTracked } from '../../context/incoming-banking-transaction-context';

export const useIncomingMutationsFilter = () => {
  const [state, dispatch] = useTracked();

  const { i18n, enumReducer } = useAppContext();

  const setQueryParams = React.useCallback((newParams: Partial<IncomingMutationQueryParamsType>) => {
    dispatch({ type: 'UPDATE_QUERY_PARAMS', newParams });
  }, []);

  const resetQueryParams = React.useCallback(() => {
    dispatch({ type: 'RESET_QUERY_PARAMS' });
  }, []);

  const setFlexSearch = React.useCallback(
    (val: string) => {
      setQueryParams({ flexSearch: val });
    },
    [setQueryParams]
  );

  const setDates = React.useCallback(
    (newDates: Array<Moment | null | undefined>) => {
      setQueryParams({
        transactionDateStart: newDates[0] ? newDates[0] : moment(MIN_DATE),
        transactionDateEnd: newDates[1] ? newDates[1] : moment(MAX_DATE)
      });
    },
    [setQueryParams]
  );

  const tags: React.ReactNode[] = [];

  if (state.queryParams.status && state.queryParams.status.length > 0) {
    tags.push(
      <FilterTag
        key="status"
        enumType="incomingMutationStatus"
        type="enum"
        value={state.queryParams.status[0]}
        removeFilter={() => setQueryParams({ status: [] as incomingMutationStatus[] })}
      />
    );
  }

  if (state.queryParams.incomingMutationType && state.queryParams.incomingMutationType.length > 0) {
    tags.push(
      <FilterTag
        key="type"
        enumType="incomingMutationType"
        type="enum"
        value={state.queryParams.incomingMutationType[0]}
        removeFilter={() => setQueryParams({ incomingMutationType: [] as incomingMutationType[] })}
      />
    );
  }

  const filterTags = <FilterTags key="filter-tags" tags={tags} />;

  const filters = (
    <FilterInputs
      filterInputs={[
        <EnumFilterSelect
          id="status"
          key="status"
          title={i18n.getTranslation('general.status')}
          options={enumReducer.getEnum<incomingMutationStatus>('incomingMutationStatus')}
          values={state.queryParams.status ? state.queryParams.status : []}
          setValue={(value) => setQueryParams({ status: value })}
        />,
        <EnumFilterSelect
          id="type"
          key="type"
          title={i18n.getTranslation('general.type')}
          options={enumReducer.getEnum<incomingMutationType>('incomingMutationType')}
          values={state.queryParams.incomingMutationType ? state.queryParams.incomingMutationType : []}
          setValue={(value) => setQueryParams({ incomingMutationType: value })}
        />
      ]}
      removeAllFilters={resetQueryParams}
    />
  );

  return {
    filters,
    filterTags,
    queryParams: state.queryParams,
    setQueryParams,
    setFlexSearch,
    setDates,
    resetQueryParams
  };
};

export default useOutgoingMutationsFilter;
