import parsePhoneNumber from 'libphonenumber-js';
import { getCurrency } from 'locale-currency';

const MIN_FRACTION_DIGITS = 0;
const MIN_FRACTION_DIGITS_VALUTA = 2;
const MIN_FRACTION_DIGITS_QUANTITY = 3;

const MAX_FRACTION_DIGITS = 15;

const anyDecimalRe = /\d+\.\d+/;

/**
 * Generates a random integer
 * @param number min 
 * @param number max 
 * @returns a random integer between given bounds
 */
export const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Rounds a decimal number to 2 decimals
 * @param number num 
 * @returns rounded number
 */
export const roundNumber = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

/**
 * Rounds a number to the closest 100 (e.g. 223 => 200)
 * @param number num 
 * @returns rounded number
 */
export const roundToLower100 = (num: number) => Math.floor(num / 100) * 100;

/**
 * Rounds a decimal number to a given amount of decimals
 * @param number number 
 * @param number decimalPlaces 
 * @returns rounded number
 */
export const roundNumberToDecimals = (number: number, decimalPlaces?: number) => {
  if (typeof decimalPlaces === 'undefined') {
    decimalPlaces = 2;
  }

  return Number(Math.round(Number(number + 'e' + decimalPlaces)) + 'e' + decimalPlaces * -1);
};

/**
 * Formats a phone number
 * @param string str_number 
 * @returns formated phone number
 */
export const formatPhoneNumber = (str_number: string) => {
  const phoneNumber = parsePhoneNumber(str_number);

  return phoneNumber?.formatInternational() || '';
};

/**
 * Formats a decimal number according to given locale
 * @param number number 
 * @param string locale 
 * @returns formatted number string
 */
export const formatDecimal = (number: number | null | undefined, locale: string) => {
  if (typeof number === 'undefined' || number === null || isNaN(number)) return '';

  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: MIN_FRACTION_DIGITS,
    maximumFractionDigits: MAX_FRACTION_DIGITS
  }).format(number);
};
/**
 * Formats a decimal number according to given locale
 * @param number number 
 * @param string locale 
 * @returns formatted number string
 */
export const formatDecimalForCEP = (number: number | null | undefined, locale: string) => {
  if (typeof number === 'undefined' || number === null || isNaN(number)) return '';

  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: MIN_FRACTION_DIGITS,
    maximumFractionDigits: 3
  }).format(number);
};

/**
 * Formats a decimal number according to the given locale's currency
 * @param number number 
 * @param string locale 
 * @returns formatted number string
 */
export const formatCurrency = (number: number | null | undefined, locale: string) => {
  if (typeof number === 'undefined' || number === null || isNaN(number)) return '';

  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

/**
 * Formats a given formula (e.g. 1117.155555+20000.111111) using 'formatDecimal()'
 * @param string expression 
 * @param string locale 
 * @returns formatted formula
 */
export const formatExpression = (expression: string, locale: string) => {
  const expressionPartsList = expression.split(/\s/g);

  const formattedPartsList = expressionPartsList.map((ep) => {
    if (anyDecimalRe.test(ep)) {
      return formatDecimal(parseFloat(ep), locale);
    }
    return ep.trim();
  });

  return formattedPartsList.join(' ');
};

/**
 * Gets the currency symbol for a given locale
 * @param string locale 
 * @returns currency symbol
 */
export const getCurrencySymbol = (locale: string) => {
  const currency = getCurrency(locale);

  if (!currency) return '';

  return (0).toLocaleString(locale, { style: 'currency', currency }).replace(/[\d\s,.]/g, '');
};

/**
 * Generates a number formatter for given configuration
 * @param number minimumFractionDigits 
 * @param number maximumFractionDigits 
 * @param string locale 
 * @returns Intl.NumberFormat instance
 */
export const getMoneyFormatter = (minimumFractionDigits: number, maximumFractionDigits: number, locale: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getCurrency(locale),
    minimumFractionDigits,
    maximumFractionDigits
  });
};

/**
 * Formats a decimal as valuta using the provided locale
 * @param number amount 
 * @param string locale 
 * @returns formatted amount
 */
export const formatMoney = (amount: number | null | undefined, locale: string) => {
  if (typeof amount === 'undefined' || amount === null || isNaN(amount)) return '';

  return getMoneyFormatter(MIN_FRACTION_DIGITS_VALUTA, MAX_FRACTION_DIGITS, locale).format(amount);
};

/**
 * Formats a decimal as valuta using the provided locale but drops the symbol
 * @param number amount 
 * @param string locale 
 * @returns formatted amount
 */
export const formatMoneyDigits = (amount: number | null | undefined, locale: string) => {
  if (typeof amount === 'undefined' || amount === null || isNaN(amount)) return '';

  const parts = getMoneyFormatter(MIN_FRACTION_DIGITS_VALUTA, MAX_FRACTION_DIGITS, locale).formatToParts(amount);

  let formattedNumber = '';

  for (const part of parts) {
    if (part.type !== 'currency') {
      formattedNumber += part.value;
    }
  }

  return formattedNumber.trim();
};

/**
 * @description to format number for quantiity
 * @param number amount
 * @param string locale
 * @returns 3 decimal pointed number
 */
export const formatQuantity = (amount: number | null | undefined, locale: string) => {
  if (typeof amount === 'undefined' || amount === null || isNaN(amount)) return '';

  const parts = getMoneyFormatter(MIN_FRACTION_DIGITS_QUANTITY, MIN_FRACTION_DIGITS_QUANTITY, locale).formatToParts(
    amount
  );

  let formattedNumber = '';

  for (const part of parts) {
    if (part.type !== 'currency') {
      formattedNumber += part.value;
    }
  }

  return formattedNumber.trim();
};

/**
 *
 * @param amount
 * @param locale
 * @returns
 */
export const formatUnitPrice = (amount: number | null | undefined, locale: string) => {
  if (typeof amount === 'undefined' || amount === null || isNaN(amount)) return '';

  return getMoneyFormatter(MIN_FRACTION_DIGITS_QUANTITY, MIN_FRACTION_DIGITS_QUANTITY, locale).format(amount);
};

/**
 * Returns the decimal separator for a given locale
 * @param string locale 
 */
export const getDecimalSeparator = (locale: string) => {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);

  const decimal = parts.find((d) => d.type === 'decimal');

  return decimal?.value;
};

/**
 * Converts a string to number according to given locale
 * @param string stringFloat 
 * @param string locale 
 * @returns the input string as number
 */
export const parseFloatLocalised = (stringFloat: string, locale: string) => {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);

  const group = parts.find((d) => d.type === 'group');
  const decimal = parts.find((d) => d.type === 'decimal');

  if (!group || !decimal) return NaN;

  const _group = new RegExp(`[${group.value}]`, 'g');
  const _decimal = new RegExp(`[${decimal.value}]`);

  // Clean any non numeric except for the separators and signs
  const filter = RegExp(`[^\\d${decimal.value}${group.value}\\-]`, 'g');
  const cleanedString = stringFloat.trim().replace(filter, '');

  const isNegative = cleanedString.startsWith('-');

  const baseString = cleanedString.replace(_group, '');

  const withoutSeparators = baseString.replace(_decimal, '');

  const parsed = baseString.replace(_decimal, '.');

  // JavaScript can't handle float conversion above 16 digits
  if (parsed) {
    if (withoutSeparators.length > 16) {
      return isNegative ? -Math.abs(+parsed.substring(0, parsed.length - 1)) : +parsed.substring(0, parsed.length - 1);
    } else {
      return isNegative ? -Math.abs(+parsed) : +parsed;
    }
  }

  return NaN;
};

/**
 * Abbreviates numbers from 1000 onwards
 * @param number num 
 * @param string locale 
 * @returns number formatted as string but shorter than digit notation
 */
export function nFormatter(num: number, locale: string) {
  if (num >= 1) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });

    return item
      ? formatDecimal(parseFloat((num / item.value).toFixed(1).replace(rx, '$1')), locale) + item.symbol
      : '0';
  }

  return formatDecimal(num, locale);
}

/**
 * Makes any number positive
 * @param number num 
 * @returns positive number
 */
export const makePositive = (num: number) => {
  if (num === -0) return 0;
  return num < 0 ? -1 * num : num;
};
