import { observer } from 'mobx-react';
import React from 'react';

import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import Button from '../../../../components/Button/Button';
import { InlineInputFieldInput } from '../../../../components/input/InputField';
import { useStore } from '../../../../hooks/useStore';

const DomainName = observer(() => {
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { settingsStore, communicationService } = communicationStore;
  const { communicationParametersForm, actionPermissions } = settingsStore;
  const { verifyDomain, sendTestMail } = communicationService;

  const domainName = communicationParametersForm._get('domainName');

  return (
    <>
      <CardItem width="12">
        <Label>{getTranslation('communication.domain_name')}</Label>
      </CardItem>
      <CardItem width="10">
        <InlineInputFieldInput
          id="domain-name"
          onChange={(val) => communicationParametersForm._set('domainName', val)}
          value={domainName}
          placeholder={getTranslation('communication.domain_name_placeholder')}
          error={!!communicationParametersForm._error('domainName')}
          singleError={communicationParametersForm._error('domainName')}
        />
      </CardItem>

      <CardItem width="2">
        {actionPermissions.mayVerify && (
          <Button id="verify" type="primary" onClick={verifyDomain}>
            {getTranslation('general.verify')}
          </Button>
        )}

        {actionPermissions.maySendTestMail && (
          <Button id="send_test_email" type="primary" onClick={sendTestMail}>
            {getTranslation('communication.send_test_mail')}
          </Button>
        )}
      </CardItem>
    </>
  );
});

export default DomainName;
