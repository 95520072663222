import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../app-context/app-context';
import { dialog } from '../../events/dialog-events';
import { DeleteIcon } from '../Icon';
import EditIcon from '../Icon/EditIcon';
import css from './config-buttons.module.scss';

type Props = {
  editTitle?: string;
  deleteTitle?: string;
  editDialogPosition?: 'normal' | 'right';
  editDialog?: React.ReactElement;
  deleteDialog?: React.ReactElement;
  entityId?: string;
  stackOrder?: number;
  editExtraAction?: (...args: any[]) => void;
  deleteExtraAction?: (...args: any[]) => void;
  editCancel?: (...args: any[]) => void;
  deleteCancel?: (...args: any[]) => void;
};

export default function ConfigButtons(props: Props) {
  const {
    editTitle,
    deleteTitle,
    entityId,
    stackOrder,
    editDialogPosition = 'normal',
    editExtraAction,
    deleteExtraAction,
    editCancel,
    deleteCancel
  } = props;
  let { editDialog, deleteDialog } = props;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  if (editDialog) editDialog = React.cloneElement(editDialog, { ref: clickRef, validationRef: validationRef });
  if (deleteDialog) deleteDialog = React.cloneElement(deleteDialog, { ref: clickRef });

  const openDeleteDialog = () => {
    dialog.normal({
      title: deleteTitle || '',
      icon: 'trashcan',
      content: deleteDialog,
      buttonPositive: i18n.getTranslation('general.delete'),
      type: 'danger',
      stackOrder: stackOrder,
      onSubmit,
      onCancel: deleteCancel
    });
  };

  const openEditDialog = () => {
    const dialogContent = {
      title: editTitle || '',
      icon: 'sign',
      content: editDialog,
      buttonPositive: i18n.getTranslation('general.edit'),
      stackOrder: stackOrder,
      ref: validationRef,
      onSubmit,
      onCancel: editCancel
    };

    if (editDialogPosition === 'normal') {
      dialog.normal(dialogContent);
    } else {
      dialog.right(dialogContent);
    }
  };

  return (
    <div className={css['icons']}>
      {editDialog && (
        <EditIcon
          id={`edit-${entityId}`}
          onClick={() => {
            if (editExtraAction) editExtraAction();
            openEditDialog();
          }}
        />
      )}
      {deleteDialog && (
        <DeleteIcon
          id={`delete-${entityId}`}
          onClick={() => {
            if (deleteExtraAction) {
              deleteExtraAction();
            }
            openDeleteDialog();
          }}
        />
      )}
    </div>
  );
}
