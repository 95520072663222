import moment from 'moment';
import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { serviceStatus } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import {
  ServiceLocationRowType,
  ServiceLocationType,
  ServiceType,
  ShortContractType
} from '@zf/api-types/master-data/servicelocation';
import { betweenDates } from '@zf/utils/src/date';

import useListPage from '../../../app-context/hooks/use-listpage';
import EmptyLink from '../../../components/Link/EmptyLink';
import TableServices from '../../../components/Services/TableServices';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import { getServiceStatus } from '../../../utils/serviceState';

const useLocationListPage = <Q,>(
  customEntityPropertyTypes: CustomEntityPropertyType[],
  defaultQueryParams?: Q,
  noNavigation = false
) => {
  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const processRecord = (value: ServiceLocationType): ServiceLocationRowType => {
    const servicesAll = value.services.filter((service) => getServiceStatus(service.statusHistory));

    const servicesSupplied = value.services.filter((service) => {
      const activeStatus = getServiceStatus(service.statusHistory);
      return activeStatus && activeStatus.serviceStatus === serviceStatus.supplied;
    });

    const today = moment();

    const shortContracts = servicesSupplied.reduce((acc: ShortContractType[], service: ServiceType) => {
      const contract = service.contracts.find(
        (c) => betweenDates(c.supplyStartDateTime, c.supplyEndDateTime, today) && c.currentContractStatus === 'signed'
      );

      if (contract && !acc.some((sc) => sc.contractorDisplayName === contract.contractorDisplayName)) {
        acc.push(contract);
      }

      return acc;
    }, []);

    let terminatedContracts: ShortContractType[] = [];

    value.services.forEach((e) => {
      return e.contracts.forEach((f) => {
        terminatedContracts.push(f);
      });
    });

    return {
      __id: value.id,
      __etag: value._etag,
      __entity: value,
      __shortContracts: shortContracts,
      __terminatedContracts: terminatedContracts,
      __customEntityPropertyTypes: customEntityPropertyTypes,
      DisplayAddress: (
        <EmptyLink
          id={`${routedEntitySubjectType.servicelocation}-${value.id}`}
          url={`${rootUrl}/locations/${value.id}`}
          noNavigation={noNavigation}
        >
          {value.address.localizedDisplay}
        </EmptyLink>
      ),
      propertyGroupName: value.propertyGroup && (
        <Link
          id={`property-${value.propertyGroup.id}`}
          url={`${rootUrl}/property-groups/${value.propertyGroup.id}`}
          noNavigation={noNavigation}
        >
          {value.propertyGroup.name}
        </Link>
      ),
      services: servicesAll.length,
      externalId: value.externalId,
      suppliedServices: <TableServices utilityTypes={servicesSupplied.map((s) => s.utilityType)} />,
      customer: shortContracts.map((sc) => {
        return (
          <Link
            id={`customer-${sc.contractorId}`}
            key={sc.contractorId}
            url={`${rootUrl}/customers/${sc.contractorId}`}
            noNavigation={noNavigation}
          >
            {sc.contractorDisplayName}
          </Link>
        );
      }),
      ...getExtraCustomEntityColumns(value.customProperties, customEntityPropertyTypes, getTranslation)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    localTimeStamp,
    timeStamp,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    setSelectedIds,
    setStopIndex,
    handleSort
  } = useListPage<ServiceLocationType, ServiceLocationRowType>({
    endpoint: '/md/servicelocations',
    processRecord,
    domain: 'location',
    defaultQueryParams
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useLocationListPage;
