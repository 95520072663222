import React from 'react';

import { formatDate } from '@zf/utils/src/date';

import useListPageContext from '../../../../../../app-context/hooks/use-listpage-context';
import { TransactionsContext } from '../../Context/Context';
import { TransactionType, TransactionsRowType } from '@zf/api-types/transactions';
import { useAppContext } from 'app-context';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { OpenTotalAmounts, TransactionStatus, TransactionTypeLink } from '../Components';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { Icon } from 'design-system/Foundation';

const useTransactionsListPage = (customer: CustomerType) => {
  const {
    enumReducer,
    i18n: { getTranslation }
  } = useAppContext();

  const processRecord = (transaction: TransactionType): TransactionsRowType => {
    return {
      __id: transaction.id,
      __entity: transaction,
      type: <TransactionTypeLink transaction={transaction} enumReducer={enumReducer} getTranslation={getTranslation} />,
      TransactionAmount: (
        <OpenTotalAmounts
          transaction={transaction}
          withStyle={transaction.transactionOpenAmount === 0 ? 'linethrough' : 'normal'}
        />
      ),
      CreatedDateTime: formatDate(transaction.createdDateTime),
      status: (
        <FlexElements>
          <TransactionStatus transaction={transaction} />{' '}
          {transaction.awaitingBankConfirmation ? <Icon name="lock" /> : null}
        </FlexElements>
      )
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    activatedRows,
    sortBy,
    sortDirection,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useListPageContext<any, TransactionType, TransactionsRowType>({
    context: TransactionsContext,
    endpoint: '/bill/transactions',
    processRecord,
    defaultQueryParams: { customeruuid: customer.id }
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    activatedRows,
    setSelectedIds,
    sortBy,
    sortDirection,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex
  };
};

export default useTransactionsListPage;
