import { entitySubjectType } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';

export type EntityRouteMapType = Record<entitySubjectType, string>;

declare global {
  interface Window {
    appInsights: { trackException: Function };
  }
}

declare global {
  interface Navigator {
    language: string;
    userLanguage: string;
  }
}

export const entityRouteMap = {
  customer: 'customers?&quickFilter=requireattention',
  contract: 'contracts?&quickFilter=requireattention',
  meter: 'metering?&quickFilter=requireattention',
  servicelocation: 'locations?&quickFilter=requireattention',
  invoice: 'invoices?&quickFilter=requireattention',
  importjob: 'import-jobs?&quickFilter=requireattention',
  propertygroup: 'property-groups?&quickFilter=requireattention',
  customermandate: 'customers?&quickFilter=requireattention',
  organisationscenario: 'configuration/communication/customization',
  payment: 'payments?&quickFilter=requireattention',
  communicationentry: 'configuration/communication/customization',
  exportjob: 'export-jobs?&quickFilter=requireattention',
  dataexport: 'configuration/export-jobs',
  organisation: 'configuration/organisation',
  dataimport: 'configuration/import-jobs',
  asset: 'configuration/assets',
  bankaccount: 'configuration/bank_accounts',
  billingitem: 'configuration/product/billing_items',
  billingtariff: 'configuration/product/billing_items',
  costcomponent: 'configuration/product/input_variables',
  costcomponentvalue: 'configuration/product/input_variables',
  model: 'configuration/meter',
  numbersequence: 'configuration/number_sequences',
  billingparameters: 'configuration/billing',
  product: 'configuration/product',
  projectlocation: 'configuration/location',
  taxcode: 'configuration/product/tax_codes',
  template: 'configuration/assets',
  billingrelation: 'contracts?&quickFilter=requireattention',
  transaction: 'customers?&quickFilter=requireattention',
  dataimportfileformat: 'configuration/import-jobs',
  dataexportfileformat: 'configuration/export-jobs',
  collectioncase: 'collection-cases',
  moverequest: 'tasks?&quickFilter=requireattention',
  consumptionunittype: 'locations?&quickFilter=requireattention',
  incominginvoice: 'property-groups?&quickFilter=requireattention',
  entityattribute: '', //not used in require attention atm, obsolete?
  tenant: '', //Do we need this in require attention?
  consumption: '', //No sufficient data (guid)
  attachment: '',
  exportjobline: '',
  languagetable: '',
  incomingmutation: '',
  outgoingmutation: '',
  outgoingbankingtransaction: 'payments/outgoing-banking-transactions?&quickFilter=requireattention',
  incomingbankingtransaction: 'payments/incoming-banking-transactions?&quickFilter=requireattention',
  personalinformation: 'configuration/communication',
  customerbankaccount: '',
  pluginmanualtrigger: '',
  billingcustomer: '',
  enumsymboltranslation: '',
  scenariotranslation: '',
  syntheticloadprofile: '',
  consumergroup: '',
  estimablecontract: '',
  plugininboundwebhooktrigger: '',
  pluginmappingentry: '',
  eavestimationrun: '',
  customentitypropertytype: '',
  customentityproperty: '',
  entityAttachment: '',
  entityattachmentgroup: ''
};

export const configEntities = [
  'organisationscenario',
  'communicationentry',
  'organisation',
  'dataprovider',
  'dataimport',
  'entityattribute',
  'asset',
  'bankaccount',
  'billingitem',
  'billingtariff',
  'costcomponent',
  'costcomponentvalue',
  'fileformat',
  'model',
  'numbersequence',
  'organization',
  'billingparameters',
  'product',
  'projectlocation',
  'organizationscenarios',
  'taxcode',
  'template',
  'billingrelation'
];

export const entityMap: Record<routedEntitySubjectType, string> = {
  product: 'configuration/product/products',
  servicelocation: 'locations',
  propertygroup: 'property-groups',
  customer: 'customers',
  customergroups: 'customergroups',
  contract: 'contracts',
  invoice: 'invoices',
  payment: 'payments',
  meter: 'devices/meters',
  prepayment: 'devices/prepayment-devices',
  importjob: 'import-jobs',
  exportjob: 'export-jobs',
  collectioncase: 'collection-cases',
  tasks: 'tasks',
  outgoingbankingtransaction: 'payments/outgoing-banking-transactions',
  incomingbankingtransaction: 'payments/incoming-banking-transactions'
};
