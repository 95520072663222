import clone from 'clone';
import React from 'react';

import { CreateFormulaFunctionType, FunctionType } from '@zf/api-types/property-group-billing-configuration';
import { PropertyMeteringConfigurationType } from '@zf/api-types/property-metering-configuration';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useDialog from '@zf/hooks/src/useDialog';
import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import CalculateDialog from '../../../../../../actions/property-group/calculate-dialog';
import { useAppContext } from '../../../../../../app-context/app-context';
import Button from '../../../../../../components/Button/Button';
import { dialog } from '../../../../../../events/dialog-events';
import { notify } from '../../../../../../events/notification-events';
import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { METHODS, sendRequest } from '../../../../../../utils/request';
import FormulaBuilder from '../../../formula-builder/formula-builder';
import useMeteringData from '../../hooks/use-metering-data';
import css from './calculation-config.module.scss';
import CalculationOutputs from './calculation-outputs';

type State = {
  formulaFunctions: CreateFormulaFunctionType[] | null;
  isDirty: boolean;
};

const runtimeParameters = ['StartDateTime', 'EndDateTime', 'PropertyGroup'];

export default function CalculationConfigTab() {
  const { i18n, tenantReducer } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();
  const {
    propertyMeteringConfiguration,
    selectedOutputChannel,
    calculationErrors,
    setPropertyMeteringConfiguration,
    setCalculationErrors
  } = useMeteringData();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    formulaFunctions: null,
    isDirty: false
  });

  const response = useSuspenseSingleAPI<FunctionType[]>({
    request: {
      endpoint: '/me/PropertyGroupMeteringConfiguration/formulas'
    }
  });

  React.useEffect(() => {
    if (selectedOutputChannel && selectedOutputChannel.formula) {
      const initialFormulaFunctions: CreateFormulaFunctionType[] = Object.keys(
        selectedOutputChannel.formula.functions
      ).reduce((acc: CreateFormulaFunctionType[], key) => {
        if (selectedOutputChannel.formula) {
          acc.push(selectedOutputChannel.formula.functions[parseInt(key)]);
        }
        return acc;
      }, []);

      setValue({ formulaFunctions: initialFormulaFunctions });
    } else {
      setValue({ formulaFunctions: [] });
    }
  }, [selectedOutputChannel]);

  if (!propertyMeteringConfiguration) return null;

  if (!response.result || values.formulaFunctions === null) return null;

  const functions = response.result.data;

  const submitFormula = async () => {
    try {
      if (selectedOutputChannel) {
        const apiFriendlyFunctions: Record<number, CreateFormulaFunctionType> = {};

        if (values.formulaFunctions) {
          values.formulaFunctions.forEach((ff, index) => {
            apiFriendlyFunctions[index] = ff;
          });
        }

        const configClone = clone(propertyMeteringConfiguration);

        let indexToUpdate = -1;

        const channelToUpdate = configClone.outputChannels.find((oChann, index) => {
          const match = oChann.name === selectedOutputChannel.name;
          if (match) {
            indexToUpdate = index;
          }
          return match;
        });

        if (channelToUpdate && indexToUpdate !== -1) {
          channelToUpdate.formula = { functions: apiFriendlyFunctions };

          const newConfig = (
            await sendRequest<PropertyMeteringConfigurationType>({
              request: {
                method: METHODS.POST,
                endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyMeteringConfiguration.propertyGroup?.id}`,
                data: { outputChannels: configClone.outputChannels }
              },
              tenantReducer: tenantReducer,
              lang: i18n.lang
            })
          ).data;

          setPropertyMeteringConfiguration(newConfig);

          notify.success({
            content: i18n.getTranslation('property_groups.tabs.billing.update_formula_success')
          });
        } else {
          throw new Error(i18n.getTranslation('property_groups.tabs.billing.update_formula_fail'));
        }
      } else {
        throw new Error(i18n.getTranslation('property_groups.tabs.billing.update_formula_fail'));
      }
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.update_formula_fail'),
        error: e
      });
    }
  };

  const validateFormula = async () => {
    try {
      const newConfig = (
        await sendRequest<PropertyMeteringConfigurationType>({
          request: {
            method: METHODS.POST,
            endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyMeteringConfiguration.propertyGroup?.id}/validate`
          },
          tenantReducer: tenantReducer,
          lang: i18n.lang
        })
      ).data;

      setPropertyMeteringConfiguration(newConfig);
      setValue({ isDirty: false });

      const errors: any[] = [];
      let hasError = false;

      newConfig.outputChannels.forEach((oChann) => {
        if (oChann.validationResult && !oChann.validationResult.valid) {
          hasError = true;
          oChann.validationResult.generalValidationErrors.forEach((e) => {
            errors.push(e);
          });
        }
      });

      if (hasError) {
        throw {
          data: {
            errors: errors
          }
        };
      }

      notify.success({
        content: i18n.getTranslation('property_groups.tabs.billing.validate_formula_success')
      });
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.validate_formula_fail'),
        error: e
      });
    }
  };

  const openCalculateDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.metering.calculate_header', {
        outputChannel: selectedOutputChannel ? selectedOutputChannel.name : ''
      }),
      icon: 'calculator',
      content: (
        <CalculateDialog
          ref={clickRef}
          propertyGroupId={propertyMeteringConfiguration ? propertyMeteringConfiguration.propertyGroup?.id : undefined}
          outputChannel={selectedOutputChannel}
          validationRef={validationRef}
          setCalculationErrors={setCalculationErrors}
        />
      ),
      buttonPositive: i18n.getTranslation('property_groups.tabs.metering.calculate'),
      ref: validationRef,
      onSubmit
    });
  };

  const calculateIsDisabled =
    values.formulaFunctions.length === 0 || values.isDirty || !propertyMeteringConfiguration.validated;

  return (
    <ColumnsContainer className={css['wrapper']} extraPadding={false}>
      <CalculationOutputs
        outputChannels={propertyMeteringConfiguration.outputChannels}
        isDirty={values.isDirty}
        subTitle={i18n.getTranslation('property_groups.tabs.metering.calc_outputs_subtitle')}
        enableAdd
        enableDelete
      />

      <FormulaBuilder
        actions={
          selectedOutputChannel ? (
            <>
              <Button
                id="property_group_metering_config.calculate"
                className={css['action-btn']}
                onClick={openCalculateDialog}
                disabled={calculateIsDisabled}
              >
                {i18n.getTranslation('property_groups.tabs.metering.calculate')}
              </Button>
              <Button
                id="property_group_metering_config.validate"
                className={css['action-btn']}
                onClick={async () => {
                  await submitFormula();
                  await validateFormula();
                }}
                disabled={!values.isDirty}
              >
                {i18n.getTranslation('general.validate')}
              </Button>
            </>
          ) : undefined
        }
        hasSelection={!!selectedOutputChannel}
        functionValidationErrors={selectedOutputChannel?.validationResult?.functionValidationErrors || []}
        calculationErrors={calculationErrors}
        itemName={selectedOutputChannel ? selectedOutputChannel.name : ''}
        functions={functions}
        formulaFunctions={values.formulaFunctions}
        runtimeParameters={runtimeParameters}
        emptyStateMessage={i18n.getTranslation('property_groups.tabs.metering.formula_builder_empty_state')}
        setFormulaFunctions={(val) => {
          setValue({ formulaFunctions: val, isDirty: true });
        }}
      />
    </ColumnsContainer>
  );
}
