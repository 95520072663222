import React from 'react';

import { UpdateModelType } from '@zf/api-types/model';

import Models from './models';
import useConfig from '../../../app-context/hooks/use-config';

export default function ModelsTab() {
  const config = useConfig<UpdateModelType[]>('models', '/cfg/models');

  return <Models config={config} />;
}
