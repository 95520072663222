import React from 'react';

import { AuthRoleResponse, UserRolesType } from '@zf/api-types/auth';

import { useAppContext } from '../../app-context/app-context';
import RouteWithHeader from '../../components/Header/RouteWithHeader';
import { AUTH_BASE_URL } from '../../constants/authentication';
import useLocalRefresh from '../../hooks/useLocalRefresh';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import UserManagerContent from './UserManagerContent';

export default function UserManager() {
  const { tenantReducer } = useAppContext();
  const { refresh, timeStamp } = useLocalRefresh();
  const { tenant } = tenantReducer.state;

  const userRolesResponse = useSuspenseSingleAPI<UserRolesType[]>({
    request: {
      endpoint: `${AUTH_BASE_URL}/api/UserAuth/t/${tenant?.id}/userroles`,
      timeStamp
    }
  });

  const tenantRoles = useSuspenseSingleAPI<AuthRoleResponse[]>({
    request: {
      endpoint: `${AUTH_BASE_URL}/api/UserAuth/t/${tenant?.id}/roles`,
      timeStamp
    }
  });

  if (!userRolesResponse.result || !tenantRoles.result) return null;

  const userRoles: UserRolesType[] = userRolesResponse.result.data;
  const roles: AuthRoleResponse[] = tenantRoles.result.data;

  return (
    <RouteWithHeader>
      <UserManagerContent userRoles={userRoles} roles={roles} refresh={refresh} />
    </RouteWithHeader>
  );
}
