import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';
import { isMinDate } from '@zf/utils/src/date';

import AddMeasurementMultiValue from '../../../actions/meters/add-measurement/add-measurement-multivalue';
import { useAppContext } from '../../../app-context';
import DatePicker, { DatePickerProps } from '../../../components/Lang/DatePicker';
import { MeterSummary } from '../../../components/summary';
import { SuspenseSpinner } from '../../../components/suspense';
import MeterAddToLocation from '../../location/wizard/MeterAddToLocation';
import { InstallMeterMultiValueType, InstallMeterSectionMultiValueProps } from './InstallMeterMultiValue';
import css from './meter-to-install.module.scss';
import MeterWarnings from './MeterWarnings';

type Props = InstallMeterSectionMultiValueProps & {
  locationType: 'location' | 'property_group';
  index: number;
  value: InstallMeterMultiValueType;
  meterType_?: meterType;
  dispatchValue: (value: Partial<InstallMeterMultiValueType>) => void;
};

const WizardDatePicker = WizardInputWrapper<DatePickerProps>(DatePicker);

export default function MeterToInstall(props: Props) {
  const {
    index,
    value,
    createNewMeter,
    meterType_,
    dispatchValue,
    setCreateNewMeter,
    setCreateNewMeasurement,
    handleFocus = () => {},
    onFocusStep = () => {},
    setFeedback = () => {}
  } = props;

  const { i18n } = useAppContext();

  return (
    <>
      {value.meter ? (
        <WizardSection className={css['meter-to-install-section']}>
          <SuspenseSpinner>
            <MeterSummary meter={value.meter} />
          </SuspenseSpinner>
          <InputContainer>
            <WizardDatePicker
              id={`mutationDateTime-${index}`}
              value={value.mutationDateTime}
              placeholder={i18n.getTranslation('location.mutation_datetime')}
              onChange={(val) => dispatchValue({ mutationDateTime: val })}
              onFocus={handleFocus}
              error={isMinDate(value.mutationDateTime)}
            />
          </InputContainer>
          <MeterWarnings meter={value.meter} />
          <br />

          <AddMeasurementMultiValue
            meterIndex={index}
            titleText={i18n.getTranslation('install_meter.wizard.measurements')}
            measurements={[]}
            setMeasurements={(measurements) => dispatchValue({ measurements })}
            meter={value.meter}
            mutationDateTime={value.mutationDateTime}
            setCreateNewMeasurement={setCreateNewMeasurement}
            oneEntryRequired={false}
            usedInWizard
          />
        </WizardSection>
      ) : (
        <MeterAddToLocation
          index={index}
          meter={value.meter}
          createNewMeter={createNewMeter}
          meterType_={meterType_}
          setFeedback={setFeedback}
          onFocusStep={onFocusStep}
          setCreateNewMeter={setCreateNewMeter}
          setMeter={(val) => {
            dispatchValue({
              meter: val
            });
            onFocusStep('select_meter');
          }}
          usedInInstallWiz
        />
      )}
    </>
  );
}
