import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { UserAvatar } from 'components/Avatar';
import { Comment } from 'design-system/ComponentSets';
import React from 'react';
import css from './muting-rule.module.scss';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import Button from '../Button/Button';
import { List } from '@zf/stella-react/src/atoms/List';
import { Icon } from 'components/Icon';
import { MeteringIssueError, MutingRuleTimePeriod } from '@zf/api-types/enums';
import useUser from 'hooks/useUser';
import { useStore } from 'hooks/useStore';
import CancelMutingRuleActionButton from './CancelMutingRuleActionButton';
import { formatPeriod } from '@zf/utils/src/date';
import SimpleEmbeddedListPageDialog from './SimpleEmbeddedListPageDialog';
import moment from 'moment';
import { observer } from 'mobx-react';

type Props = {
  useCase: 'rule-dialog' | 'issue-card';
  count?: number;
  errorType: MeteringIssueError;
  message?: string;
  expired: boolean;
  meterCount?: number;
  muteFrom?: string;
  muteFromWithNumericValue: number;
  muteUntilWithNumericValue: number;
  meterSerialNumber: string;
  muteUntil?: string;
  timePeriod: MutingRuleTimePeriod;
  userId: string;
  onCancel: () => void;
};

const MutingRule = (props: Props) => {
  const { applicationStore } = useStore();
  const { getEnumTranslation, userStore, getTranslation } = applicationStore;
  const {
    useCase,
    expired = false,
    errorType,
    message,
    meterCount,
    muteFromWithNumericValue,
    muteUntilWithNumericValue,
    meterSerialNumber,
    timePeriod,
    userId,
    onCancel
  } = props;

  const userName = useUser(userId, userStore);

  const muteFrom = moment(muteFromWithNumericValue, 'YYYYMMDD');
  const muteUntil = moment(muteUntilWithNumericValue, 'YYYYMMDD');

  return (
    <FlexElements flexDirection="column" className={css['muting-rule-wrapper']}>
      <FlexElements flexDirection="row" justifyContent="space-between" alignItems="center">
        <FlexElements flexDirection="column" gap="4">
          {/* Implement button to embedded meterList dialog (only available to active mutingRules)*/}
          <SimpleEmbeddedListPageDialog
            btnLabel={`${getEnumTranslation('meteringIssueError', errorType)} ${
              useCase === 'rule-dialog'
                ? ` - ( ${
                    meterCount === 1
                      ? meterSerialNumber
                      : getTranslation('dashboard.general_tab.meters', { amount: meterCount || '' })
                  } )`
                : ''
            }`}
            title={getTranslation('meter.meters')}
            muteFromWithNumericValue={muteFromWithNumericValue}
            muteUntilWithNumericValue={muteUntilWithNumericValue}
            errorType={errorType}
            message={message}
            timePeriod={timePeriod}
            inactiveLink={useCase === 'issue-card'}
            userId={userId}
            includeActive={!expired}
          />
          <Paragraph color={colors['silver-600']}>{formatPeriod(muteFrom, muteUntil)}</Paragraph>
        </FlexElements>
        <FlexElements flexDirection="row">
          <TooltipTrigger
            tooltip={<Paragraph>{getTranslation('meter_issues.muting_rule_created_by', { userName })}</Paragraph>}
          >
            <UserAvatar size="xsmall" children={userName} />
          </TooltipTrigger>
          {/* Dropdown */}
          {!expired && (
            <div role="button">
              <TooltipTrigger
                placement="bottom-start"
                trigger="click"
                tooltip={<List>{[<CancelMutingRuleActionButton errorType={errorType} onCancel={onCancel} />]}</List>}
              >
                <Button
                  id="issues.ignore.dummy"
                  style={{ alignItems: 'center', paddingRight: '0' }}
                  onClick={() => {}}
                  type="text"
                >
                  <Icon type="ellipsis-h"></Icon>
                </Button>
              </TooltipTrigger>
            </div>
          )}
        </FlexElements>
      </FlexElements>
      {message && (
        <FlexElements flexDirection="row" className={css['comment']}>
          <Comment comment={message} />
        </FlexElements>
      )}
    </FlexElements>
  );
};

export default observer(MutingRule);
