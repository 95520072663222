import moment from 'moment';
import React from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import LabelItemGrid from '@zf/stella-react/src/atoms/Card/LabelItemGrid';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context/app-context';
import DatePicker, { DatePickerProps } from '../../../../components/Lang/DatePicker';
import useActivities from './hooks/useActivities';
import css from './left-column.module.scss';
import { Link } from 'design-system/Components';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';

type Props = {
  task: MoveRequestType;
};

const InlineDatePicker = InlineInputField<DatePickerProps>(DatePicker);

export default function Details(props: Props) {
  const { task } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { setTaskField, getActivityStatus } = useActivities();
  const { rootUrl } = tenantReducer.state;

  const linkedLocation = (
    <>
      {task.contractDetails.serviceLocationsCount > 1 ? (
        <Paragraph>{task.contractDetails.serviceLocationsCount}</Paragraph>
      ) : (
        <Link
          id={`${routedEntitySubjectType.tasks}-${task.serviceLocationDetails.internalId}`}
          url={`${rootUrl}/locations/${task.serviceLocationDetails.internalId}`}
          icon="location"
        >
          {task.serviceLocationDetails?.address?.localizedDisplay}
        </Link>
      )}
    </>
  );

  return (
    <div className={css['details']}>
      <LabelItemGrid>
        <CardItem width="6">
          <Label>{i18n.getTranslation('tasks.creation_date')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>{formatDate(task.createdDateTime)}</Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation(`tasks.${task.moveRequestType}_date`)}</Label>
        </CardItem>
        <CardItem width="6">
          {getActivityStatus('confirm_contract') === 'complete' || task.status === moveRequestStatus.rejected ? (
            <Paragraph>{formatDate(task.mutationDateTime)}</Paragraph>
          ) : (
            <InlineDatePicker
              id="mutationDateTime"
              value={moment(task.mutationDateTime)}
              onChange={(val) => setTaskField(val.toISOString(), 'mutationDateTime')}
              darkMode
            />
          )}
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('location.location')}</Label>
        </CardItem>
        <CardItem width="6">{linkedLocation}</CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('tasks.owner')}</Label>
        </CardItem>
        <CardItem width="6">
          <Link
            id={`${routedEntitySubjectType.tasks}-${task.serviceLocationDetails.owner?.customerId}`}
            url={`${rootUrl}/customers/${task.serviceLocationDetails.owner?.customerId}`}
            icon="customer"
            noNavigation={typeof task.serviceLocationDetails.owner?.customerId === 'undefined' ? true : false}
          >
            <Paragraph>
              {task.serviceLocationDetails.owner ? task.serviceLocationDetails.owner.shortDisplayName : null}
            </Paragraph>
          </Link>
        </CardItem>
      </LabelItemGrid>
    </div>
  );
}
