import React from 'react';

import { PropertyGroupRowType } from '@zf/api-types/master-data/property-group';

import useCurrent from '../../../app-context/hooks/use-current';
import { useStore } from '../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import ExportToExcel from './ExportToExcel';

type Props = {
  extraColumnTitles: string[];
};

export default function PropertyExport(props: Props) {
  const { extraColumnTitles } = props;

  const { myCurrent } = useCurrent('propertygroup');

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const selectedRows: PropertyGroupRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = () => {
    const exportArray: string[][] = [];

    exportArray.push([
      getTranslation('property_groups.name'),
      getTranslation('property_groups.property_group_id'),
      getTranslation('general.type'),
      getTranslation('location.street_name'),
      getTranslation('location.streetnr'),
      getTranslation('location.streetnraddition'),
      getTranslation('location.postal'),
      getTranslation('location.city'),
      getTranslation('location.country'),
      getTranslation('property_groups.amountOfServiceLocations'),
      ...extraColumnTitles
    ]);

    selectedRows.forEach((r) => {
      const propertyGroup = r.__propertyEntity;

      const extraCustomEntityColumns = getExtraCustomEntityColumns(
        propertyGroup.customProperties,
        r.__customEntityPropertyTypes,
        getTranslation
      );

      const extraCustomEntityColumnValues = Object.keys(extraCustomEntityColumns).map((key) => {
        return extraCustomEntityColumns[key];
      });

      exportArray.push([
        propertyGroup.name,
        propertyGroup.id,
        getEnumTranslation('propertyGroupType', propertyGroup.type),
        propertyGroup.address.streetName,
        propertyGroup.address.streetNumber,
        propertyGroup.address.streetNumberAddition || '',
        propertyGroup.address.postalCode,
        propertyGroup.address.city,
        propertyGroup.address.country.toUpperCase(),
        propertyGroup.serviceLocationIds.length.toString(),
        ...extraCustomEntityColumnValues
      ]);
    });

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_property_groups')}
      ws_name={getTranslation('property_groups.property_group')}
    />
  );
}
