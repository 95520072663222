import { action, makeObservable, observable } from 'mobx';
import React from 'react';

import {
  CreateAttachmentGroupRequest,
  CreateCustomerAttachmentRequest,
  UpdateAttachmentGroupRequest
} from '@zf/api-types/entity-attachments/entity-attachments';
import { attachmentVisibility, culture, entitySubjectType, pluginType } from '@zf/api-types/enums';
import { RowTypeBase } from '@zf/stella-react/src/atoms/Table';

import RootStore from '../../../../app-context/stores';
import { AddEditType } from '../../Dialog/types';
import CreateRequestService from '../../List/logic/createRequestService';
import FilterStore from '../../List/logic/FilterStore';
import InfiniAPIService from '../../List/logic/infiniAPIService';
import QuickFilterStore from '../../List/logic/QuickFilterStore';
import AddEditEntityAttachmentForm from './AddEntityAttachmentForm';
import FilesService from './FilesService';
import { EntityAttachmentFile } from '@zf/api-types/entity-attachments/entity-attachments';
import AttachmentFileForm, { LocalEntityAttachmentFile } from '../logic/AttachmentFileForm';
import { CultureTableType } from '@zf/api-types/language';
import { SharedEntityProperties } from '@zf/api-types/entity';
import { AttachmentGenericBaseType } from '../ui/Files';

export enum filesActionPermissions {
  mayAddEntityAttachment = 'mayAddEntityAttachment',
  mayEditEntityAttachment = 'mayEditEntityAttachment',
  mayDeleteEntityAttachment = 'mayDeleteEntityAttachment',
  mayDownloadEntityAttachment = 'mayDownloadEntityAttachment'
}

export enum attachmentType {
  entityattachments = 'entityattachments',
  entityattachmentgroups = 'entityattachmentgroups'
}

export type EntityAttachmentRowBaseType<T> = RowTypeBase & {
  __entity: T;
  name: React.ReactNode;
  file: React.ReactNode;
  createdDateTime: React.ReactNode;
  visibility: React.ReactNode;
};

export type EntityAttachmentQueryParams = {
  flexSearch?: string;
  contractId?: string;
  contractNumber?: string;
  viewed?: boolean;
  visibilities?: attachmentVisibility[];
  createdStartDateTime?: string;
  createdEndDateTime?: string;
};

export type EntityAttachmentQuickFilters = {
  all: number;
  visibleForCustomer: number;
};

export default class FilesStore<T> {
  public rootStore: RootStore;
  public filterStore: FilterStore<EntityAttachmentQueryParams>;
  public quickFilterStore: QuickFilterStore<EntityAttachmentQuickFilters> | undefined;

  public actionPermissions: Record<filesActionPermissions, boolean> = {
    mayAddEntityAttachment: true,
    mayEditEntityAttachment: false,
    mayDeleteEntityAttachment: false,
    mayDownloadEntityAttachment: false
  };

  public entitySpecificVariables: any; // Specific vars needed for the API calls

  public selectedEntitySubjectType: entitySubjectType;
  public entityId = '';
  public customerId: string | undefined;
  public attachmentType: attachmentType;
  public portalEnabled = false;
  public culture: culture | undefined;
  public cultureTable: CultureTableType | undefined;
  public endpointUrl: string;

  public createRequestService: CreateRequestService;
  public infiniAPIService:
    | InfiniAPIService<T & SharedEntityProperties, EntityAttachmentRowBaseType<AttachmentGenericBaseType>>
    | undefined;
  public filesService: FilesService<T>;
  public setPermissions: () => void;

  public addEditEntityAttachmentForm: AddEditEntityAttachmentForm<T>;

  public isInitialized = false;

  constructor(rootStore: RootStore, selectedEntitySubjectType: entitySubjectType, setPermissions: () => void) {
    this.rootStore = rootStore;
    this.filterStore = new FilterStore({
      flexSearch: undefined,
      contractId: undefined,
      contractNumber: undefined,
      viewed: undefined,
      visibilities: undefined,
      createdStart: undefined,
      createdEnd: undefined
    } as EntityAttachmentQueryParams);

    this.selectedEntitySubjectType = selectedEntitySubjectType;

    this.createRequestService = new CreateRequestService();
    this.filesService = new FilesService(this);
    this.setPermissions = setPermissions;

    makeObservable(this, {
      filterStore: observable,
      quickFilterStore: observable,

      actionPermissions: observable,

      entitySpecificVariables: observable,

      selectedEntitySubjectType: observable,
      entityId: observable,
      customerId: observable,
      portalEnabled: observable,
      culture: observable,
      attachmentType: observable,
      endpointUrl: observable,

      createRequestService: observable,
      filesService: observable,
      infiniAPIService: observable,

      addEditEntityAttachmentForm: observable,

      isInitialized: observable,

      setEntityId: action,
      setInfiniAPIService: action,
      initAddEditForm: action,
      setAttachmentType: action,
      initFiles: action,
      loadFiles: action,
      downloadEntityAttachment: action,
      addEntityAttachment: action,
      updateEntityAttachment: action,
      deleteEntityAttachments: action,
      getEditedEntityAttachment: action,
      resetStore: action,
      fetchCounts: action,
      setCommunicationPreference: action,
      createEmptyAttachmentFile: action,
      setIsInitialized: action,
      setCustomerId: action,
      setEntitySpecificVariables: action,
      getAttachmentForCurrentCulture: action,
      addEntityAttachmentGroups: action
    });
  }

  setEntityId = (id: string) => {
    this.entityId = id;
  };

  setCulture = (newculture: culture | undefined) => {
    this.culture = newculture;
  };

  setCultureTable = (table: CultureTableType) => {
    this.cultureTable = table;
  };

  setAttachmentType = (type: attachmentType) => {
    this.attachmentType = type;
    if (type === attachmentType.entityattachments) {
      this.endpointUrl = '/att/EntityAttachments';
    } else {
      this.endpointUrl = `/att/EntityAttachmentGroups/${this.selectedEntitySubjectType}/${this.entityId}`;
    }

    this.filesService.setDownloadZipEndpoint(`${this.endpointUrl}/download`);
  };

  createEmptyAttachmentFile = (culture: culture, isDefault: boolean) => {
    return {
      attachmentFileForm: new AttachmentFileForm({
        attachmentId: '',
        file: undefined,
        culture,
        fileName: '',
        internalFileName: '',
        isDefault
      })
    };
  };

  setInfiniAPIService = (
    newInfiniAPIService: InfiniAPIService<
      T & SharedEntityProperties,
      EntityAttachmentRowBaseType<AttachmentGenericBaseType>
    >
  ) => {
    this.infiniAPIService = newInfiniAPIService;
  };

  getEditedEntityAttachment = () => {
    const { selectedIds } = this.createRequestService;
    return selectedIds.length > 0
      ? this.infiniAPIService?.rows.find((r) => r.__entity.id === selectedIds[0])?.__entity
      : undefined;
  };

  initAddEditForm = async (useCase: AddEditType, listFiles: (attachment?: T) => EntityAttachmentFile[]) => {
    const cultureTable = await this.rootStore.configStore.configService.getCultureTable();
    this.setCultureTable(cultureTable);
    const defaultCulture = this.culture || cultureTable.defaultCulture;

    if (useCase === 'add') {
      this.addEditEntityAttachmentForm = new AddEditEntityAttachmentForm(this, {
        attachmentFiles: this.sortDefaultsFirst(
          cultureTable.supportedCultures.map((sc) => this.createEmptyAttachmentFile(sc, sc === defaultCulture))
        ),
        visibleInPortal: false
      });

      listFiles();
    } else {
      const selectedEntityAttachment = this.getEditedEntityAttachment();

      if (selectedEntityAttachment) {
        let files = listFiles(selectedEntityAttachment as unknown as T);
        const mappedAttachments: LocalEntityAttachmentFile[] = this.sortDefaultsFirst(
          cultureTable.supportedCultures.map((sc, index) => {
            const file = files.find((att) => att.culture === sc);

            return file
              ? {
                  attachmentFileForm: new AttachmentFileForm({
                    attachmentId: index.toString(), // index is used as dummy id because attachmentId doesn't exist on EntityAttachmentFile,
                    file: {
                      fileName: file.localisedFileName.split('.')[0],
                      file: undefined,
                      state: 'none'
                    },
                    culture: sc,
                    fileName: file.localisedFileName.split('.')[0],
                    internalFileName: file.localisedFileName.split('.')[0],
                    isDefault: file.culture === cultureTable.defaultCulture
                  })
                }
              : this.createEmptyAttachmentFile(sc, sc === defaultCulture);
          })
        );

        this.addEditEntityAttachmentForm = new AddEditEntityAttachmentForm(this, {
          attachmentFiles: mappedAttachments,
          visibleInPortal: selectedEntityAttachment.visibility === attachmentVisibility.portal
        });
      }
    }
  };

  sortDefaultsFirst = (value: LocalEntityAttachmentFile[]) => {
    return value.sort((a, b) => {
      return Number(b.attachmentFileForm._get('isDefault')) - Number(a.attachmentFileForm._get('isDefault'));
    });
  };

  setCommunicationPreference = async (customerId?: string) => {
    const cultureTable = await this.rootStore.configStore.configService.getCultureTable();

    if (customerId) {
      try {
        // Get customers' culture preference
        const customer = await this.rootStore.customerStore.customerService.getCustomerById(customerId);

        if (!customer.communicationPreferences.culture) {
          this.setCulture(cultureTable.defaultCulture);
        } else {
          this.setCulture(customer.communicationPreferences.culture);
        }
      } catch (e) {
        this.rootStore.errorStore.pushError(e);
      }
    } else {
      this.setCulture(cultureTable.defaultCulture);
    }
  };

  setCustomerId = (id: string | undefined) => {
    this.customerId = id;
  };

  setIsInitialized = (val: boolean) => {
    this.isInitialized = val;
  };

  setEntitySpecificVariables = <T>(val: T) => {
    this.entitySpecificVariables = val;
  };

  initFiles = async <T>(entityId: string, type: attachmentType, customerId?: string, entitySpecificVariables?: T) => {
    const { configService, isPluginEnabled } = this.rootStore.configStore;

    this.setEntitySpecificVariables(entitySpecificVariables);
    this.setEntityId(entityId);
    this.setCustomerId(customerId);
    this.setAttachmentType(type);
    await this.setCommunicationPreference(customerId);

    await configService.getConfiguredPlugins();
    this.portalEnabled = isPluginEnabled(pluginType.enduserportal);

    this.quickFilterStore = new QuickFilterStore(this.rootStore, this.endpointUrl, 'all', {
      customerId: this.customerId,
      ...entitySpecificVariables
    });

    this.setIsInitialized(true);
  };

  fetchCounts = async () => {
    await this.quickFilterStore?.fetchCounts(this.filterStore.queryParams);
  };

  loadFiles = async (
    processRecord: (record: T & SharedEntityProperties) => EntityAttachmentRowBaseType<AttachmentGenericBaseType>
  ) => {
    await this.fetchCounts();

    const request = this.createRequestService.createRequest({
      endpoint: this.endpointUrl,
      query: {
        customerId: this.customerId,
        ...this.filterStore.queryParams,
        ...this.entitySpecificVariables,
        quickFilter: this.quickFilterStore?.activeQuickFilter
      }
    });

    if (!this.infiniAPIService) {
      const infiniAPIService = new InfiniAPIService(this.rootStore, processRecord, this.fetchCounts);
      this.setInfiniAPIService(infiniAPIService);
    }

    await this.infiniAPIService?.fetchRows(request);
  };

  downloadEntityAttachment = async (culture?: culture) => {
    const { selectedIds } = this.createRequestService;
    this.filesService.downloadFiles(
      selectedIds,
      this.attachmentType === attachmentType.entityattachments ? 'entityAttachmentIds' : 'entityAttachmentGroupIds',
      { culture }
    );
  };

  addEntityAttachment = async (apiFriendlyValues: CreateCustomerAttachmentRequest) => {
    await this.filesService.addEntityAttachment(apiFriendlyValues);
    this.infiniAPIService?.updateGivenRows();
  };

  addEntityAttachmentGroups = async (apiFriendlyValues: CreateAttachmentGroupRequest) => {
    await this.filesService.addEntityAttachmentGroups(apiFriendlyValues);
    this.infiniAPIService?.updateGivenRows();
  };

  updateEntityAttachmentGroups = async (apiFriendlyValues: UpdateAttachmentGroupRequest, id: string) => {
    await this.filesService.updateEntityAttachmentGroups(apiFriendlyValues, id);
    this.infiniAPIService?.updateGivenRows();
  };

  updateEntityAttachment = async (visibility: attachmentVisibility) => {
    const selectedEntityAttachment = this.getEditedEntityAttachment();

    if (selectedEntityAttachment) {
      await this.filesService.updateEntityAttachment(selectedEntityAttachment.id, visibility);
      this.infiniAPIService?.updateGivenRows();
    } else {
      throw new Error(this.rootStore.applicationStore.getTranslation('errors.couldnt_find_edited'));
    }
  };

  deleteEntityAttachments = async () => {
    const { selectedIds, setSelectedIds } = this.createRequestService;
    await Promise.all(selectedIds.map((id) => this.filesService.deleteEntityAttachment(id)));
    this.infiniAPIService?.updateGivenRows(selectedIds, setSelectedIds);
  };

  // Falltrough function for the shown language version of the file, (backend handles this for the "Download" action)
  getAttachmentForCurrentCulture = <T>(entityAttachment: T, listFiles: (attachment?: T) => EntityAttachmentFile[]) => {
    let files: EntityAttachmentFile[] = [];
    files = listFiles(entityAttachment);
    let attachmentForCurrentCulture = files.find((f) => f.culture === this.rootStore.applicationStore.culture);

    if (!attachmentForCurrentCulture) {
      // Customer pref
      attachmentForCurrentCulture = files.find((f) => f.culture === this.culture);
    }

    if (!attachmentForCurrentCulture) {
      // Fallback
      attachmentForCurrentCulture = files[0];
    }

    return attachmentForCurrentCulture;
  };

  resetStore = () => {
    this.entityId = '';
    this.entitySpecificVariables = undefined;
    this.addEditEntityAttachmentForm?._reset();
    this.filterStore.resetAll();
    this.createRequestService.resetStore();
    this.quickFilterStore = undefined;
    this.infiniAPIService = undefined;
  };
}
