import React from 'react';

import { PropertyGroupCalculationConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { PropertyGroupBillingPeriodCalculationConfigurationDetailsType } from '@zf/api-types/property-group-billing-period';
import { Tree } from '@zf/stella-react/src/atoms/Tree';

import { useAppContext } from '../../../../../app-context/app-context';
import useBillingData from '../hooks/use-billing-data';
import css from './allocation-group-tree.module.scss';
import TreeBillingItem from './cost-calculator/tree-billing-item';

type Props = {
  index: number;
  calculationConfigurationDetails: PropertyGroupBillingPeriodCalculationConfigurationDetailsType;
  isDirty?: boolean;
};

export default function AllocationGroupDetailsTree(props: Props) {
  const { index, calculationConfigurationDetails, isDirty = false } = props;

  const { i18n } = useAppContext();

  const { propertyGroupBillingConfiguration } = useBillingData();

  let matchingConfiguration: PropertyGroupCalculationConfigurationType | undefined;

  if (propertyGroupBillingConfiguration) {
    matchingConfiguration = propertyGroupBillingConfiguration.calculationConfigurations?.find(
      (c) => c.id === calculationConfigurationDetails.calculationConfigurationId
    );
  }

  return matchingConfiguration ? (
    <Tree
      id={`${matchingConfiguration.id}-${index}`}
      className={css['group-tree']}
      title={matchingConfiguration.name}
      arrowType="filled"
      openOnInit
    >
      {matchingConfiguration.billingItems &&
        matchingConfiguration.billingItems.map((item) => {
          const matchingItem = calculationConfigurationDetails.billingItems.find((b) => {
            return b.billingItemId === item.billingItemId;
          });

          let tariff: string | number = i18n.getTranslation('general.default');

          if (matchingItem && matchingItem.deviatingTariffs[0]) {
            tariff = matchingItem.deviatingTariffs[0].tariff;
          }

          return (
            <TreeBillingItem
              key={item.billingItemId}
              item={item}
              isDirty={isDirty}
              isClickable={false}
              tariff={tariff}
            />
          );
        })}
    </Tree>
  ) : null;
}
