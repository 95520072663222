import React from 'react';

import { NewTenantResponseType } from '@zf/api-types/auth';
import {
  category,
  culture,
  invoicingType,
  tenantDatabaseStorageType,
  tenantFileStorageType
} from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputDouble } from '@zf/stella-react/src/atoms/InputContainer';
import InputContainer from '@zf/stella-react/src/atoms/InputContainer/InputContainer';
import InputSection from '@zf/stella-react/src/atoms/InputContainer/InputSection';

import { useAppContext } from '../../app-context';
import CheckBox from '../../components/input/CheckBox';
import CountryInput from '../../components/input/country-input';
import InputField from '../../components/input/InputField';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { AUTH_BASE_URL } from '../../constants/authentication';
import { notify } from '../../events/notification-events';
import { formatAddress } from '../../utils/address';
import { METHODS, sendRequest } from '../../utils/request';
import css from './add-tenant-dialog.module.scss';
import { useStore } from '../../hooks/useStore';
import CultureDropdown from 'components/Dropdown/bankaccounts-dropdown/CultureDropdown';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refresh: () => void;
};

type StateValues = {
  companyName: string;
  name: string;
  streetName: string;
  streetNumber: string;
  streetNumberAddition?: string;
  postalCode: string;
  city: string;
  countryRegionCode: string;
  defaultLanguageCode: culture;
  organizationShortCode: string;
  organizationDescription: string;
  databaseStorageType: tenantDatabaseStorageType;
  fileStorageType: tenantFileStorageType;
  invoicingType: invoicingType;
  category: category;
  servicesDifferentlyInvoiced: boolean;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, refresh } = props;
  const { i18n, enumReducer } = useAppContext();
  const { applicationStore } = useStore();

  const stateReducer = createStateReducer<StateValues, Partial<StateValues>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    companyName: '',
    name: '',
    streetName: '',
    streetNumber: '',
    streetNumberAddition: '',
    postalCode: '',
    city: '',
    countryRegionCode: '',
    defaultLanguageCode: culture.en,
    databaseStorageType: tenantDatabaseStorageType.databasepertenant,
    fileStorageType: tenantFileStorageType.classic,
    organizationShortCode: '',
    organizationDescription: '',
    invoicingType: '' as invoicingType,
    category: '' as category,
    servicesDifferentlyInvoiced: false
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(
        !values.companyName ||
          !values.streetName ||
          !values.streetNumber ||
          !values.postalCode ||
          !values.city ||
          !values.countryRegionCode ||
          !values.defaultLanguageCode ||
          !values.name ||
          !values.organizationShortCode ||
          !values.organizationDescription ||
          !values.invoicingType ||
          !values.category
      );
    }
  };

  React.useEffect(() => {
    validate();
  }, [values]);

  const addTenant = async () => {
    const address = formatAddress(
      {
        streetName: values.streetName,
        streetNumber: values.streetNumber,
        streetNumberAddition: values.streetNumberAddition,
        postalCode: values.postalCode,
        city: values.city,
        country: values.countryRegionCode
      },
      enumReducer.getTranslation
    );

    return (
      await sendRequest<NewTenantResponseType>({
        request: {
          method: METHODS.POST,
          endpoint: `${AUTH_BASE_URL}/api/Tenant`,
          data: {
            companyName: values.companyName,
            invoiceAddress: address,
            name: values.name,
            countryRegionCode: values.countryRegionCode,
            organizations: [
              {
                shortCode: values.organizationShortCode,
                description: values.organizationDescription,
                defaultLanguageCode: values.defaultLanguageCode
              }
            ],
            databaseStorageType: values.databaseStorageType,
            fileStorageType: values.fileStorageType,
            invoicingType: values.invoicingType,
            category: values.category,
            servicesDifferentlyInvoiced: values.servicesDifferentlyInvoiced
          }
        },
        lang: i18n.lang
      })
    ).data;
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await addTenant();
        refresh();
        applicationStore.getTenants();

        notify.success({
          content: i18n.getTranslation('tenant.add_tenant_success', { name: values.companyName })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tenant.add_tenant_fail', { name: values.companyName }),
          error: e
        });
      }
    }
  }));

  const storageDropdownValues = [{ text: i18n.getTranslation('tenant.classic'), value: 'classic' }];

  const invoicingDropdownValues = [
    { text: i18n.getTranslation('general.none'), value: invoicingType.none },
    { text: i18n.getTranslation('general.default'), value: invoicingType.default },
    { text: i18n.getTranslation('organization.organization'), value: invoicingType.organization }
  ];

  const categoryDropdownValues = [
    { text: i18n.getTranslation('tenant.saas'), value: category.saas },
    { text: i18n.getTranslation('tenant.bpo'), value: category.bpo }
  ];

  const matchCountryWithLang = (countryCode: string) => {
    const defaultLanguageCode = {
      bel: culture['nl-BE'],
      fin: culture.fi,
      nld: culture['nl-NL'],
      aut: culture.de,
      deu: culture.de,
      che: culture.de,
      lie: culture.de,
      fra: culture['fr-FR'],
      mco: culture['fr-FR']
    }[countryCode];

    defaultLanguageCode ? setValue({ defaultLanguageCode }) : setValue({ defaultLanguageCode: culture.en });
  };

  return (
    <div className={css['two-column']}>
      <InputContainer className={css['inputs']} noDense>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('tenant.heading_1')}
        </Heading>
        <InputSection>
          <InputField
            id="company-name"
            onChange={(val) => setValue({ companyName: val })}
            value={values.companyName}
            placeholder={i18n.getTranslation('tenant.company_name')}
            error={!values.companyName}
          />
          <InputField
            id="name"
            onChange={(val) => setValue({ name: val })}
            value={values.name}
            placeholder={i18n.getTranslation('tenant.short_code')}
            error={!values.name}
          />
        </InputSection>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('tenant.heading_2')}
        </Heading>
        <InputSection>
          <InputField
            id="street"
            onChange={(value) => setValue({ streetName: value })}
            value={values.streetName}
            placeholder={i18n.getTranslation('location.street')}
            error={!values.streetName}
          />
          <InputDouble>
            <InputField
              id="streetNr"
              onChange={(value) => setValue({ streetNumber: value })}
              value={values.streetNumber}
              placeholder={i18n.getTranslation('customer.streetnr')}
              error={!values.streetNumber}
            />
            <InputField
              id="streetNrAdd"
              onChange={(value) => setValue({ streetNumberAddition: value })}
              value={values.streetNumberAddition}
              placeholder={i18n.getTranslation('location.streetnraddition')}
            />
          </InputDouble>
        </InputSection>
        <InputSection>
          <InputField
            id="postalCode"
            onChange={(value) => setValue({ postalCode: value })}
            value={values.postalCode}
            placeholder={i18n.getTranslation('location.postal')}
            error={!values.postalCode}
          />
          <InputField
            id="city"
            onChange={(value) => setValue({ city: value })}
            value={values.city}
            placeholder={i18n.getTranslation('location.city')}
            error={!values.city}
          />
          <CountryInput
            id="country-code"
            onChange={(val) => {
              setValue({ countryRegionCode: val[0] });
              matchCountryWithLang(val[0]);
            }}
            selectedValues={[values.countryRegionCode]}
            placeholder={i18n.getTranslation('location.country')}
            error={!values.countryRegionCode}
          />
        </InputSection>
      </InputContainer>

      <InputContainer className={css['first-org']}>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('tenant.heading_3')}
        </Heading>
        <InputSection>
          <SimpleDropdown
            id="databaseStorageType"
            onChange={(val) => setValue({ databaseStorageType: val[0] as tenantDatabaseStorageType })}
            values={storageDropdownValues}
            selectedValues={[values.databaseStorageType]}
            placeholder={i18n.getTranslation('tenant.database_storage_type')}
            disabled
          />
          <SimpleDropdown
            id="fileStorageType"
            onChange={(val) => setValue({ fileStorageType: val[0] as tenantFileStorageType })}
            values={storageDropdownValues}
            selectedValues={[values.fileStorageType]}
            placeholder={i18n.getTranslation('tenant.file_storage_type')}
            disabled
          />
        </InputSection>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('tenant.heading_4')}
        </Heading>
        <InputSection>
          <SimpleDropdown
            id="invoicing-type"
            onChange={(val) => setValue({ invoicingType: val[0] })}
            values={invoicingDropdownValues}
            selectedValues={[values.invoicingType]}
            placeholder={i18n.getTranslation('tenant.invoicing_type')}
            error={!values.invoicingType}
          />
          <SimpleDropdown
            id="category"
            onChange={(val) => setValue({ category: val[0] })}
            values={categoryDropdownValues}
            selectedValues={[values.category]}
            placeholder={i18n.getTranslation('tenant.category')}
            error={!values.category}
          />
          <CheckBox
            id="services-differently-invoiced"
            checked={values.servicesDifferentlyInvoiced}
            onChange={(val) => setValue({ servicesDifferentlyInvoiced: val })}
          >
            {i18n.getTranslation('tenant.services_differently_invoiced')}
          </CheckBox>
        </InputSection>

        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('tenant.heading_5')}
        </Heading>
        <InputSection>
          <InputField
            id="org-short-code"
            onChange={(shortCode) => setValue({ organizationShortCode: shortCode })}
            value={values.organizationShortCode}
            placeholder={i18n.getTranslation('tenant.short_code')}
            error={!values.organizationShortCode}
          />
          <InputField
            id="org-description"
            onChange={(description) => setValue({ organizationDescription: description })}
            value={values.organizationDescription}
            placeholder={i18n.getTranslation('general.description')}
            error={!values.organizationDescription}
          />
          <CultureDropdown
            id="org-default-language"
            onChange={(val) => setValue({ defaultLanguageCode: val[0] })}
            selectedValue={values.defaultLanguageCode}
            placeholder={i18n.getTranslation('general.default_language')}
          />
        </InputSection>
      </InputContainer>
    </div>
  );
});
