import React from 'react';

import {
  OutgoingBankingTransactionRowType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../app-context';
import useListPage from '../../../../../app-context/hooks/use-listpage';
import EmptyLink from '../../../../../components/Link/EmptyLink';
import { ICON_COLORS } from '../../../../../constants/icons';

const useOutgoingBankingTransactionsListPage = () => {
  const { i18n, enumReducer, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const processRecord = (value: OutgoingBankingTransactionType): OutgoingBankingTransactionRowType => {
    return {
      __id: value.id,
      __etag: value._etag,
      __entity: value,
      identification: (
        <EmptyLink
          id={`${routedEntitySubjectType.outgoingbankingtransaction}-${value.id}`}
          url={`${rootUrl}/payments/outgoing-banking-transactions/${value.id}`}
        >
          {value.identification}
        </EmptyLink>
      ),
      type: enumReducer.getTranslation('outgoingBankingTransactionType', value.type),
      mutationDateTime: formatDate(value.mutationDateTime),
      totalAmount: formatMoney(value.totalAmount, i18n.culture),
      companyBankAccount: value.companyBankAccount.iban,
      numberOfMutations: value.entityCounts.numberOfMutations,
      status: (
        <StatusBadge color={ICON_COLORS[value.status]} type="bare">
          {enumReducer.getTranslation('outgoingBankingTransactionStatus', value.status)}
        </StatusBadge>
      )
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<OutgoingBankingTransactionType, OutgoingBankingTransactionRowType>({
    endpoint: '/bill/outgoingbankingtransactions',
    processRecord,
    domain: 'outgoing_banking_transaction'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useOutgoingBankingTransactionsListPage;
