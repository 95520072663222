import { observer } from 'mobx-react';
import React from 'react';

import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import BankAccountsDropdown from '../../../../../components/Dropdown/bankaccounts-dropdown/BankAccountsDropdown';
import DateRangePicker from '../../../../../components/input/DateRangePicker';
import HistoryPicker from '../../../../../components/Lang/HistoryPicker';
import { useStore } from '../../../../../hooks/useStore';
import { QueryParam } from '../hooks/useIncomingBankingTransactionFilter';

type Props = {
  queryParams: QueryParam;
  setQueryParams: (params: Partial<QueryParam>) => void;
};

const IncomingBankingTransactionsFilterInputs = (props: Props) => {
  const { queryParams, setQueryParams } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      <Label>{getTranslation('banking_transaction.bank_account')}</Label>
      <BankAccountsDropdown
        id="bank_account_dropdown"
        onChange={(val) =>
          setQueryParams({
            companyBankAccountIBAN: val[0]?.iban || '',
            companyBankAccountId: val[0]?.id || ''
          })
        }
        selectedValue={queryParams.companyBankAccountId}
        isInline
        renderTopLvl={false}
      />
      <ListDivider />
      <Label>{getTranslation('general.period')}</Label>,
      <HistoryPicker
        id="history-picker"
        setHistoryDates={(dates) =>
          setQueryParams({
            mutationDateTimeStart: convertMomentToISOString(dates[0]) as string,
            mutationDateTimeEnd: convertMomentToISOString(dates[1]) as string
          })
        }
        historyDates={[
          convertToMoment(queryParams.mutationDateTimeStart),
          convertToMoment(queryParams.mutationDateTimeEnd)
        ]}
      />
      ,
      <DateRangePicker
        id="invoice-date-filter"
        startDate={convertToMoment(queryParams.mutationDateTimeStart)}
        endDate={convertToMoment(queryParams.mutationDateTimeEnd)}
        setDates={(dates) =>
          setQueryParams({
            mutationDateTimeStart: convertMomentToISOString(dates[0]) as string,
            mutationDateTimeEnd: convertMomentToISOString(dates[1]) as string
          })
        }
        renderTopLvl={false}
      />
    </>
  );
};

export default observer(IncomingBankingTransactionsFilterInputs);
