import classNames from 'classnames';
import React from 'react';

import useTransition from '@zf/hooks/src/useTransition';

import css from './popup.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  popupRef?: string | ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null;
  show: boolean;
};

export default function Popup(props: Props) {
  const { children, className, style, show, popupRef = null } = props;

  const { toggledStyle, showElement } = useTransition({
    styleEnter: css['enter'],
    styleExit: css['exit'],
    toggle: show
  });

  return (
    <>
      {showElement && (
        <div className={classNames(css['popup'], toggledStyle, className)} style={style} ref={popupRef}>
          {children}
        </div>
      )}
    </>
  );
}
