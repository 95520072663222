import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { FileFormatType } from '@zf/api-types/file-format';
import { ImportJobType } from '@zf/api-types/import-job';

import FileFormatCard from '../../../../cards/FileFormats/file-format-card';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';

export type Props = {
  importJob: ImportJobType;
};

export default function ImportJobFileFormatCard(props: Props) {
  const { importJob } = props;

  const fileFormatsResponse = useSuspenseSingleAPI<PagedResponseType<FileFormatType>>({
    request: {
      endpoint: '/cfg/DataImportFileFormats',
      query: { showDeleted: true }
    }
  });

  if (!fileFormatsResponse.result) return null;

  const fileformat: FileFormatType | undefined = fileFormatsResponse.result.data.results.find(
    (fm) => fm.id === importJob.fileFormatId
  );

  if (!fileformat) return null;

  return <FileFormatCard fileformat={fileformat} />;
}
