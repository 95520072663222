import { Dispatch, useEffect, useReducer, useState } from 'react';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import {
  AuthPermissionResponse,
  AuthRoleResponse,
  TenantAuthResponse,
  TenantOrganisationType,
  TenantType
} from '../../../../api-types/auth';
import { AUTH_BASE_URL } from '../../constants/authentication';
import permissionsMap from '../../permissions/permissions.json';
import logError from '../../utils/handleError';
import { sendRequest } from '../../utils/request';

type State = {
  userAuth: TenantAuthResponse | null;
  currentRole: AuthRoleResponse | null;
  isSuperAdmin: boolean;
};

export type useAuthorizationReturnType = {
  state: State;
  isSuperUser: () => boolean;
  isAdmin: () => boolean;
  isReadOnly: () => boolean;
  hasPermission: (permissionString: string) => boolean;
  dispatch: Dispatch<Partial<State>>;
};

export const scopeMap = {
  tenant: 0,
  organisation: 1
};

export type OperationType = 'all' | 'create' | 'update' | 'delete' | 'read';

export type OperationMapType = {
  all: number;
  create: number;
  update: number;
  delete: number;
  read: number;
};

export const operationMap: OperationMapType = {
  all: 0,
  create: 1,
  update: 2,
  delete: 3,
  read: 4
};

export default function useAuthorization(tenant: TenantType | null, organization: TenantOrganisationType | null) {
  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, dispatch] = useReducer(stateReducer, {
    userAuth: null,
    currentRole: null,
    isSuperAdmin: false
  });
  const [userAuthError, setUserAuthError] = useState<Promise<any> | Error | null>(null);

  useEffect(() => {
    if (!tenant) return;
    const getAuthInfo = async () => {
      try {
        const userAuthResponse = (
          await sendRequest<TenantAuthResponse>({
            request: {
              endpoint: `${AUTH_BASE_URL}/api/UserAuth/t/${tenant.id}`
            }
          })
        ).data;

        if (!userAuthResponse) {
          setUserAuthError(new Error('Invalid user auth!'));
          return;
        }

        dispatch({ userAuth: userAuthResponse });
      } catch (e) {
        // @ts-ignore
        logError(e);
      }

      setUserAuthError(null);
    };

    setUserAuthError(getAuthInfo());
  }, [tenant]);

  useEffect(() => {
    if (state.userAuth) {
      let activeRole: AuthRoleResponse | undefined;

      if (organization) {
        // We are at organisation level
        activeRole = state.userAuth.roles.find((r) => r.id === organization.roleId);
      } else {
        // We are at tenant level
        activeRole = state.userAuth.roles.find((r) => r.scope === scopeMap['tenant']);
      }

      dispatch({ currentRole: activeRole });
    }
  }, [organization, tenant]);

  const isSuperUser = () => state.isSuperAdmin;

  const isAdmin = () => {
    if (state.currentRole) {
      if (state.currentRole.name === 'admin') {
        return true;
      }
    } else {
      // No roleId for current organisation, check if tenant admin
      const activeRole = state.userAuth
        ? state.userAuth.roles.find((r) => {
            return r.scope === scopeMap['tenant'];
          })
        : undefined;
      return !!activeRole;
    }

    return false;
  };

  const isReadOnly = () => {
    if (state.currentRole) {
      return state.currentRole.permissions.every((p) => {
        return p.operationType === operationMap['read'];
      });
    }

    return false;
  };

  const getPermissions = (key: string) => {
    if (!key) return '';

    const keyParts = key.split('.');
    let part: any = permissionsMap;

    for (let i = 0; i < keyParts.length; i++) {
      const keyPart = keyParts[i];
      part = part[keyPart]; // How do you type each JSON key?
      if (!part) return '';
    }

    return typeof part === 'string' ? part : '';
  };

  const hasPermission = (permissionsKey: string) => {
    if (isSuperUser() || isAdmin()) {
      return true;
    } else {
      let split = permissionsKey.split('.');

      // Remove indexes which make inputs unique in table rows
      const end = split[split.length - 1];

      if (end.startsWith('index')) {
        split.splice(split.length - 1, 1);
      }

      const permissionString = getPermissions(split.join('.'));

      if (!permissionString) {
        return true;
      }

      split = permissionString.split('.');

      const operationType = split[0] as OperationType;
      const domain = split[1] || '*';
      const entity = split[2] || '*';
      const action = split[3] || '*';

      let foundPermission: AuthPermissionResponse | undefined;

      if (state.currentRole) {
        foundPermission = state.currentRole.permissions.find((p) => {
          return (
            (p.operationType === operationMap[operationType] || p.operationType === operationMap['all']) &&
            (p.domain === domain || p.domain === '*') &&
            (p.entity === entity || p.entity === '*') &&
            (p.action === action || p.action === '*')
          );
        });
      }

      return !!foundPermission;
    }
  };

  return { authReducer: { state, dispatch, isSuperUser, isAdmin, isReadOnly, hasPermission }, userAuthError };
}
