import clone from 'clone';
import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ContactDetailType } from '@zf/api-types/settings-config';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../app-context/app-context';
import ContactDetailsCard from '../../../../cards/Contacts/ContactDetailsCard';
import ExtraCard, { ExtrasType } from '../../../../cards/ExtrasCard/ExtraCard';
import ResetAllButton from '../../../../components/Button/ResetAllButton/ResetAllButton';
import CommitSection from '../../../../components/config/commit-section';
import AddressCard from './AddressCard';
import DefaultRelationsCard from './DefaultRelationsCard';
import GeneralCard from './GeneralCard';
import useFetchPropertygroupSettings from './hooks/useFetchPropertygroupSettings';
import usePropertygroupSettings from './hooks/usePropertygroupSettings';
import InvoicingDefaultsCard from './InvoicingDefaultsCard';
import css from './settings-tab-content.module.scss';
import ConsumptionGroupCard from './ConsumptionCard';

type Props = {
  propertyGroup: PropertyGroupType;
  refreshProperty: () => void;
};

export default function SettingsTabContent(props: Props) {
  const { refreshProperty } = props;
  const { authReducer } = useAppContext();
  const { hasPermission } = authReducer;
  const mayEdit =
    hasPermission('propertygroup.update_billingdetails') &&
    hasPermission('propertygroup.update_managementrelations') &&
    hasPermission('propertygroup.update_communication_config') &&
    hasPermission('propertygroup.consumption_groups');

  // Init ctx & fetch what we need
  useFetchPropertygroupSettings(props.propertyGroup);

  const {
    communicationConfiguration,
    propertyGroupBillingConfig,
    ownerId,
    managerId,
    isDirty,
    organisationConfig,
    propertyGroupForecastingConfig,
    availableUtilityTypes,
    selectedUtilityTypes,
    handleSave,
    setCommConfigValue,
    setSelectedUtilityTypes,
    resetCommConfigValue,
    resetAddress,
    setBillingConfigValue,
    setFctConfigValue,
    setOwner,
    setManager,
    restore,
    resetAll,
    showResetAll
  } = usePropertygroupSettings();

  // Check if all data is loaded before rendering everything
  if (
    !propertyGroupBillingConfig ||
    !propertyGroupForecastingConfig ||
    !communicationConfiguration ||
    !organisationConfig
  )
    return null;

  const setContactDetailsValue = (value: Partial<ContactDetailType>) => {
    // @ts-ignore
    setCommConfigValue({ contactDetails: { ...clone(communicationConfiguration.contactDetails), ...value } });
  };

  const resetContactDetailsValue = (key: keyof ContactDetailType) => {
    if (organisationConfig) {
      const cloned: any = clone(communicationConfiguration);
      cloned.contactDetails[key] = clone(organisationConfig.contactDetails[key]);
      setCommConfigValue(cloned);
    }
  };

  // Remove this when data model is updated (adapter stuff)
  const extras: Partial<ExtrasType> = {
    logo: communicationConfiguration.logo,
    primaryColor: communicationConfiguration.primaryColor,
    secondaryColor: communicationConfiguration.secondaryColor
  };

  const save = async () => {
    await handleSave();
    refreshProperty();
  };

  return (
    <>
      {mayEdit && <CommitSection handleCancel={restore} handleSave={save} isDirty={isDirty} />}
      <CardsContainer>
        <GeneralCard
          communicationConfiguration={communicationConfiguration}
          organisationConfig={organisationConfig}
          setCommConfigValue={setCommConfigValue}
          resetCommConfigValue={resetCommConfigValue}
        />
        <AddressCard
          communicationConfiguration={communicationConfiguration}
          defaultAddress={organisationConfig?.address}
          setCommConfigValue={setCommConfigValue}
          resetAddress={resetAddress}
        />
        <ContactDetailsCard
          contactDetails={communicationConfiguration.contactDetails}
          defaultContactDetails={organisationConfig.contactDetails}
          setContactDetailsValue={setContactDetailsValue}
          resetContactDetailsValue={resetContactDetailsValue}
        />
        <ExtraCard
          extras={extras}
          organisationConfig={organisationConfig}
          setExtrasValue={setCommConfigValue}
          //@ts-ignore
          resetCommConfigValue={resetCommConfigValue}
        />
        <DefaultRelationsCard ownerId={ownerId} managerId={managerId} setOwner={setOwner} setManager={setManager} />
        <InvoicingDefaultsCard
          propertyGroupBillingConfig={propertyGroupBillingConfig}
          setBillingConfigValue={setBillingConfigValue}
        />
        <ConsumptionGroupCard
          propertyGroupForecastingConfig={propertyGroupForecastingConfig}
          availableUtilityTypes={availableUtilityTypes}
          selectedUtilityTypes={selectedUtilityTypes}
          setFctConfigValue={setFctConfigValue}
          setSelectedUtilityTypes={setSelectedUtilityTypes}
        />
      </CardsContainer>
      {mayEdit && showResetAll() && (
        <ResetAllButton id="propertygroup.reset_all" className={css['reset']} onClick={resetAll} />
      )}
    </>
  );
}
