import React from 'react';

import { PropertyGroupRowType, PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../app-context';
import { TableError, TableLoading, TableNoData } from '../../components/placeholder';
import { notify } from '../../events/notification-events';
import css from './add-location-dialog.module.scss';
import useLocationList from './hooks/useLocationList';
import { updateServiceLocations } from './property-group';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import SearchBar from '../../components/input/SearchBar';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  propertyGroup: PropertyGroupType;
  selectedRows?: Array<PropertyGroupRowType>;
  onComplete?: (updatedRows: PropertyGroupType[]) => void;
  refresh?: () => void;
};

type State = {
  searchValue: string;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { propertyGroup, validationRef, selectedRows, refresh: refreshDetailPage, onComplete } = props;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValues] = React.useReducer(stateReducer, {
    searchValue: ''
  });

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    refresh,
    selectAllBusy: selectAllBusy_,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useLocationList(values.searchValue, 'add_location');

  React.useLayoutEffect(() => {
    setSelectedIds(propertyGroup.serviceLocationIds);
  }, []);

  const { i18n, tenantReducer } = useAppContext();

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(selectedIds.length === 0);
    }
  };

  React.useEffect(() => {
    validate();
  }, [selectedIds]);

  const groupId = propertyGroup ? propertyGroup.id : selectedRows ? selectedRows[0].__id : '';

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (selectedIds.length > 0) {
        try {
          const newPropertyGroup = await updateServiceLocations(selectedIds, groupId, tenantReducer, i18n.lang);

          if (refreshDetailPage) refreshDetailPage();
          if (onComplete) onComplete([newPropertyGroup]);

          notify.success({
            content: i18n.getTranslation('actions.property_group.property_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.property_group.location_failed'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <>
      <InputContainer>
        <SearchBar
          id="filter-searchbar"
          onChange={(val) => setValues({ searchValue: val })}
          value={values.searchValue}
          placeholder={i18n.getTranslation('locations_list.filter.search_placeholder')}
          debounceTime={500}
        />
      </InputContainer>

      <div className={css['table-wrapper']}>
        <DynamicVirtualTable
          id="locations-table"
          tooltipId="locations-table-tip"
          onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
          rows={rows}
          loading={loading}
          error={error}
          selectedRows={selectedIds}
          sortableFields={sortableFields}
          sortBy={sortBy}
          sortDirection={sortDirection}
          totalAmountOfRows={totalAmountOfRows}
          selectAllBusy={selectAllBusy_}
          refresh={refresh}
          sort={handleSort}
          onSelectRow={setSelectedIds}
          NoDataOverlay={TableNoData}
          LoadingOverlay={TableLoading}
          ErrorOverlay={TableError}
          singleSort
          columns={[
            {
              width: 550,
              label: i18n.getTranslation('location.location'),
              dataKey: 'DisplayAddress',
              noClick: true
            },
            {
              width: 100,
              label: i18n.getTranslation('locations_list.labels.services'),
              dataKey: 'services'
            }
          ]}
        />
      </div>
    </>
  );
});
