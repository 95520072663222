import classNames from 'classnames';
import { Paragraph } from 'design-system/Foundation';
import React, { CSSProperties, ReactNode, useMemo } from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors, convertHexToRGBA } from '@zf/utils/src/color';

import { QuickTip } from '../';
import css from './info-banner.module.scss';

export type InfoBannerColor = 'blue' | 'orange' | 'green';

type Props = {
  info: string | JSX.Element;
  color: InfoBannerColor;
  className?: string;
  extraRight?: ReactNode;
  quickTipText?: string;
  quickTipHTML?: JSX.Element;
  quickTipId?: string;
  roundedCorners?: boolean;
  bold?: boolean;
};

const switchColor = (color: InfoBannerColor) => {
  return {
    blue: colors['blue-600'],
    orange: colors['orange-600'],
    green: colors['green-600']
  }[color];
};

export default function InfoBanner(props: Props) {
  const {
    info,
    color,
    extraRight,
    quickTipId,
    quickTipText,
    roundedCorners,
    className,
    bold = false,
    quickTipHTML
  } = props;

  const colorHex = switchColor(color);

  const style: CSSProperties = useMemo(() => {
    const backgroundColor = convertHexToRGBA(colorHex, 5);
    return { backgroundColor, color: `${color}` };
  }, [colorHex]);

  return (
    <div className={classNames(css['info-banner'], { [css['rounded']]: roundedCorners }, className)} style={style}>
      <FlexElements>
        <Paragraph color={colorHex} bold={bold}>
          {info}
        </Paragraph>
        {quickTipId && quickTipText && <QuickTip id={quickTipId} text={quickTipText} />}
        {quickTipId && quickTipHTML && <QuickTip id={quickTipId} interweave={quickTipHTML} />}
      </FlexElements>

      {extraRight}
    </div>
  );
}

InfoBanner.propTypes = {
  quickTipId: function (props: Props) {
    if ((props.quickTipText || props.quickTipHTML) && !props.quickTipId) {
      return new Error('QuickTip component needs a quickTipId!');
    }
  }
};
