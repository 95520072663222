import React from 'react';

import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import { notify } from '../../../../events/notification-events';
import { deleteCustomerGroup } from './Delete.API';
import { navigate } from '@reach/router';
import Interweave from 'interweave';

type Props = {
  queryParam: { customerGroupId: string };
  hasCustomers?: boolean;
  withSettings?: boolean;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { queryParam, hasCustomers, withSettings } = props;
  const { customerGroupId } = queryParam;

  const { tenantReducer, i18n } = useAppContext();
  const { tenant, organization } = tenantReducer.state;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (customerGroupId) {
        try {
          await deleteCustomerGroup(customerGroupId, tenantReducer, i18n.lang);
          navigate(`/${tenant?.name}/${organization?.shortCode}/customers/customer-groups`);
          notify.success({
            content: i18n.getTranslation('customer_groups.delete_customer_group_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('customer_groups.delete_customer_group_fail'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <Paragraph>
      (
      <Interweave
        content={
          !(hasCustomers && withSettings)
            ? i18n.getTranslation('customer_groups.delete_customer_group')
            : i18n.getTranslation('customer_groups.delete_customer_group_with_customers_and_settings')
        }
      />
      )
    </Paragraph>
  );
});
