import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../app-context';
import { DANGER, SUCCESS } from '../../../constants/color';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  previewString: string;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { previewString } = props;
  const { i18n } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {}
  }));

  return (
    <>
      <Heading headingType="h3" style="bold">
        {`${i18n.getTranslation('number_sequence.result')}:`}
      </Heading>
      <Paragraph color={previewString ? SUCCESS : DANGER} bold>
        {previewString ? previewString : i18n.getTranslation('number_sequence.preview_fail')}
      </Paragraph>
    </>
  );
});
