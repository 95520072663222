import moment from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { contractStatus } from '@zf/api-types/enums';
import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { onlyUnique } from '@zf/utils/src/array';
import { betweenDates, startOfDay } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import KeepExistingInvoiceSection from '../../../../components/Contract/KeepExistingInvoiceSection';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { METHODS, sendRequest } from '../../../../utils/request';
import css from './sign-dialog.module.scss';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  // Listpage
  selectedRows?: Array<ContractRowType>;
  onComplete?: (updatedRows: ContractType[]) => void;
  refreshCounts?: () => void;

  // Detailpage
  contract?: ContractType;
  refresh?: () => void;
};

type State = {
  keepExistingInvoiceOnEndDate: boolean;
  previousContract: ContractType | undefined;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, contract, refreshCounts, onComplete, refresh } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { contractStore } = useStore();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = React.useReducer(stateReducer, {
    keepExistingInvoiceOnEndDate: false,
    previousContract: undefined
  });

  const mutationDateTime = startOfDay(moment());

  const getContractsForLocation = async (locationId: string) => {
    return (
      await sendRequest<PagedResponseType<ContractType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/md/Contracts',
          query: {
            serviceLocationId: locationId
          }
        },

        tenantReducer: tenantReducer,
        lang: i18n.lang
      })
    ).data.results;
  };

  const getEndNoteOfPreviousCustomer = (locationIds: string[]) => {
    if (locationIds.length > 0) {
      Promise.all(locationIds.map((id) => getContractsForLocation(id))).then((data) => {
        const contracts = data.flat().filter((c) => {
          return (
            c.currentContractStatus !== contractStatus.draft &&
            betweenDates(c.supplyStartDate, c.supplyEndDate, moment())
          );
        });

        if (contracts.length > 0) {
          setState({ previousContract: contracts[0] });
        }
      });
    }
  };

  React.useEffect(() => {
    if (contract) {
      const locationIds = contract.serviceLocations.map((l) => l.id).filter(onlyUnique);
      getEndNoteOfPreviousCustomer(locationIds);
    }
  }, []);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (selectedRows) {
        Promise.all(
          selectedRows.map((row) => {
            return contractStore.signContract(row.__contractEntity, mutationDateTime.toISOString());
          })
        )
          .then((newContracts) => {
            if (onComplete) onComplete(newContracts);

            if (refreshCounts) refreshCounts();

            notify.success({
              content: i18n.getTranslation('actions.contract.sign_success')
            });
          })
          .catch((e) => {
            notify.error({
              content: i18n.getTranslation('actions.contract.sign_failed'),
              error: e
            });
          });
      } else if (contract) {
        try {
          await contractStore.signContract(
            contract,
            mutationDateTime.toISOString(),
            state.keepExistingInvoiceOnEndDate,
            true
          );
          if (refresh) refresh();

          notify.success({
            content: i18n.getTranslation('actions.contract.sign_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.contract.sign_failed'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <>
      <Paragraph>
        {i18n.getTranslation('actions.contract.detail', { amount: selectedRows ? selectedRows.length.toString() : 1 })}
      </Paragraph>

      {state.previousContract && contract && (
        <KeepExistingInvoiceSection
          key="keep-existing-invoice"
          contractId={state.previousContract.id}
          customerId={state.previousContract.contractor.customerId}
          mutationDate={moment(contract.supplyStartDate)}
          keepExistingInvoiceOnEndDate={state.keepExistingInvoiceOnEndDate}
          setKeepExistingInvoiceOnEndDate={(val) => setState({ keepExistingInvoiceOnEndDate: val })}
        />
      )}

      {selectedRows && (
        <div className={css['warning']}>
          <div>{i18n.getTranslation('actions.contract.sign_warning_title')}</div>
          <Paragraph>{i18n.getTranslation('actions.contract.sign_warning_desc')}</Paragraph>
        </div>
      )}
    </>
  );
});
