import classNames from 'classnames';
import React from 'react';

import Header from './Header/Header';
import css from './route-with-header.module.scss';

type Props = {
  children: React.ReactNode;
};

export default function RouteWithHeader(props: Props) {
  const { children } = props;

  // NODE_ENV can't be overridden in 'Create React App' all custom env vars must have prefix 'REACT_APP_'
  return (
    <>
      <Header
        className={classNames({
          [css['green']]: process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging'
        })}
      />
      {children}
    </>
  );
}
