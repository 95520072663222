import React from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';

import useCultureTable from '../../app-context/hooks/use-culture-table';
import CommunicationPreferencesCardContent from './CommunicationPreferencesCardContent';

export type Props = {
  customer: CustomerType;
};

export default function CommunicationPreferencesCard(props: Props) {
  const { customer } = props;
  const { cultureTable } = useCultureTable();

  if (!cultureTable) return null;

  return <CommunicationPreferencesCardContent customer={customer} cultureTable={cultureTable} />;
}
