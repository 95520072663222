import React, { useEffect, useMemo } from 'react';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import { useStore } from '../../../hooks/useStore';
import useCustomerListPage from '../hooks/useCustomerListPage';

type Props = {
  customEntityPropertyTypes: CustomEntityPropertyType[];
  extraColumns: ColumnType[];
  setSelectedCount?: Function;
  queryParams?: any;
  withAddCustomer?: boolean;
  isEmbedded?: boolean;
};

export default function CustomersList(props: Props) {
  const { customEntityPropertyTypes, extraColumns, setSelectedCount, queryParams, isEmbedded = false } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useCustomerListPage(customEntityPropertyTypes, queryParams, isEmbedded);

  const columns: ColumnType[] = [
    {
      width: 400,
      label: getTranslation('customer_list.name'),
      dataKey: 'AccountNumber',
      noClick: true
    },
    {
      width: 500,
      label: getTranslation('customer.invoice_address'),
      dataKey: 'streetName'
    },
    {
      width: 250,
      label: getTranslation('customer.default_payment_method'),
      dataKey: 'defaultPaymentMethod'
    },
    {
      width: 250,
      label: getTranslation('customer_groups.customer_group'),
      dataKey: 'customerGroup'
    },
    {
      width: 250,
      label: getTranslation('settings.active_custom_properties'),
      dataKey: 'customEntityProperties'
    }
  ];

  columns.push(...extraColumns);

  columns.push(
    ...[
      {
        width: 300,
        label: '',
        dataKey: 'email'
      },
      {
        width: 200,
        label: '',
        dataKey: 'telephone'
      },
      {
        width: 200,
        label: '',
        dataKey: 'mobilePhone'
      },
      {
        width: 200,
        label: '',
        dataKey: 'website'
      }
    ]
  );

  useEffect(() => {
    setSelectedCount && setSelectedCount(selectedIds);
  }, [selectedIds]);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="customers-table"
        tooltipId="customers-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        sortableFields={sortableFields}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        singleSort
        columns={columns}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
}
