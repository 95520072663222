import React from 'react';

import {
  IncomingBankingTransactionRowType,
  IncomingBankingTransactionType
} from '@zf/api-types/billing/incoming-banking-transaction';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDate, formatPeriod } from '@zf/utils/src/date';

import { useAppContext } from '../../../../../app-context';
import useListPage from '../../../../../app-context/hooks/use-listpage';
import IconParagraph from '../../../../../components/Icon/IconParagraph';
import { ICON_COLORS } from '../../../../../constants/icons';
import { Link } from '../../../../../design-system/Components';

const useIncomingBankingTransactionsListPage = () => {
  const { i18n, enumReducer, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const processRecord = (value: IncomingBankingTransactionType) => {
    return {
      __id: value.id,
      __etag: value._etag,
      __entity: value,
      identification: (
        <Link
          id={`${routedEntitySubjectType.incomingbankingtransaction}-${value.id}`}
          url={`${rootUrl}/payments/incoming-banking-transactions/${value.id}`}
        >
          {value.identification}
        </Link>
      ),
      period: formatPeriod(value.openingBalanceDate, value.closingBalanceDate),
      numberOfBankTransfers: value.entityCounts.numberOfBankTransfers,
      numberOfReversals: value.entityCounts.numberOfReversals,
      numberOfConfirmations: value.entityCounts.numberOfConfirmations,
      companyBankAccount: value.companyBankAccount.iban,
      created: value.automaticallyCreated ? (
        <IconParagraph
          iconType="automatic"
          iconPosition="right"
          title={i18n.getTranslation('incoming_banking_transactions.created_automatically')}
          tooltipFor="incoming-banking-transactions-table-tip"
          usedInTable
        >
          {formatDate(value.fileCreationDateTime)}
        </IconParagraph>
      ) : (
        formatDate(value.fileCreationDateTime)
      ),
      status: (
        <NewStatusBadge
          color={ICON_COLORS[value.status]}
          status_={enumReducer.getTranslation('incomingBankingTransactionStatus', value.status)}
          type="bare"
        />
      )
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<IncomingBankingTransactionType, IncomingBankingTransactionRowType>({
    endpoint: '/bill/incomingbankingtransactions',
    processRecord,
    domain: 'incoming_banking_transaction'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useIncomingBankingTransactionsListPage;
