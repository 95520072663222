import React from 'react';

import css from './notification-error.module.scss';

type Props = {
  list: string[];
  info?: string;
  advice?: string;
};

export default function NotificationContentList(props: Props) {
  const { list, info, advice } = props;

  return (
    <div className={css['list']}>
      {info}
      <ul>
        {list.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
      {advice}
    </div>
  );
}
