import React from 'react';

import {
  DeletePropertyGroupType,
  PropertyGroupRowType,
  PropertyGroupType
} from '@zf/api-types/master-data/property-group';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteProperty } from './property-group';
import { navigate } from '@reach/router';

type Props = {
  onComplete?: (updatedRows: PropertyGroupType[], deletedRows: DeletePropertyGroupType[]) => void;
  refreshCounts?: () => void;
  selectedRows?: Array<PropertyGroupRowType>;
  propertyGroupToHandle?: DeletePropertyGroupType;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, refreshCounts, selectedRows, propertyGroupToHandle } = props;

  const { i18n, tenantReducer } = useAppContext();
  const { tenant, organization } = tenantReducer.state;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      let groupsToHandle: DeletePropertyGroupType[] = [];

      if (selectedRows) {
        groupsToHandle = selectedRows.map((row) => {
          return {
            id: row.__id,
            etag: row.__etag
          };
        });
      } else if (propertyGroupToHandle) {
        groupsToHandle = [propertyGroupToHandle];
      }

      if (groupsToHandle.length > 0) {
        try {
          await Promise.all(groupsToHandle.map((g) => deleteProperty(g, tenantReducer, i18n.lang)));

          if (onComplete) onComplete([], groupsToHandle);

          if (refreshCounts) refreshCounts();

          navigate(`/${tenant?.name}/${organization?.shortCode}/property-groups`);

          notify.success({
            content: i18n.getTranslation('actions.property_group.delete_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.property_group.delete_failed'),
            error: e
          });
        }
      }
    }
  }));

  return <Paragraph>{i18n.getTranslation('actions.property_group.delete_property')}</Paragraph>;
});
