import React from 'react';

import { ImportJobType } from '@zf/api-types/import-job';

import { useAppContext } from '../../../app-context';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  importJob: ImportJobType;
};

export default function DownloadImportJobsListItem(props: Props) {
  const { importJob } = props;
  const { i18n } = useAppContext();
  const { downloadFiles } = useDownload();

  const disabled = !importJob.internalFilePath;

  return (
    <DropdownAction
      id="btn-download-import-job"
      icon="download-cloud"
      actionType="direct"
      onClick={() => downloadFiles([importJob.id], '/logs/ImportJob/downloadzip', '/logs/ImportJob/', 'importJobIds')}
      disabled={disabled}
    >
      {i18n.getTranslation('actions.download')}
    </DropdownAction>
  );
}
