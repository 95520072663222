import WizardSubmitButton from 'components/Button/WizardSubmitButton';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import BillingSectionAdapter from './BillingSectionAdapter';
import RemoveLocationsSection from './RemoveLocationsSection';

const RemoveLocationsWizard = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { removeLocationsWizardNavigator, handleSubmit } =
    contractStore.moveOutWizardBaseStore.removeLocationsWizardStore;
  const { hasErrors } = removeLocationsWizardNavigator;

  return (
    <>
      <RemoveLocationsSection />

      <BillingSectionAdapter />

      <WizardSubmitButton id="contracts.remove-locations" onClick={handleSubmit} disabled={hasErrors}>
        {getTranslation('contracts.wizard.terminate.remove_locations')}
      </WizardSubmitButton>
    </>
  );
};

export default observer(RemoveLocationsWizard);
