import { action, makeAutoObservable, observable } from 'mobx';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ParameterTypesType } from '@zf/api-types/general';
import { CultureTableType } from '@zf/api-types/language';
import {
  BillingItemRequestType,
  BillingItemType,
  BillingTariffRequestType,
  BillingTariffType,
  ConsumptionUnitType
} from '@zf/api-types/product';
import { TaxCodeType } from '@zf/api-types/tax-codes';

import BillingItemsDialogStore from './BillingItemsDialogStore';
import ProductConfigStore from './ProductConfigStore';

export default class BillingItemsStore {
  private productConfigStore: ProductConfigStore;
  public billingItemsDialogStore: BillingItemsDialogStore;

  public selectedBillingItem: BillingItemType | undefined;

  public billingItems_: BillingItemType[] | undefined;
  public tariffs_: BillingTariffType[] | undefined;
  public cultureTable_: CultureTableType | undefined;
  public taxCodes_: TaxCodeType[] | undefined;
  public calculationTypes_: ParameterTypesType[] | undefined;
  public consumptionUnitTypes_: ConsumptionUnitType[] | undefined;
  public customEntityPropertyTypes_: CustomEntityPropertyType[] | undefined;

  constructor(productConfigStore: ProductConfigStore) {
    this.productConfigStore = productConfigStore;
    this.billingItemsDialogStore = new BillingItemsDialogStore(productConfigStore.rootStore.applicationStore);

    makeAutoObservable(this, {
      billingItems_: observable,
      tariffs_: observable,
      cultureTable_: observable,
      taxCodes_: observable,
      calculationTypes_: observable,
      consumptionUnitTypes_: observable,
      selectedBillingItem: observable,

      initBillingItems: action,
      setSelectedBillingItem: action,
      updateBillingItemInList: action,
      deleteBillingItem: action,
      initTariffs: action,
      addTariff: action,
      addTariffToList: action,
      updateTariff: action,
      updateTariffInList: action,
      deleteTariff: action,
      deleteTariffInlist: action,
      setBillingItems: action
    });
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get billingItems() {
    return this.billingItems_ as BillingItemType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get tariffs() {
    return this.tariffs_ as BillingTariffType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get taxCodes() {
    return this.taxCodes_ as TaxCodeType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get calculationTypes() {
    return this.calculationTypes_ as ParameterTypesType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get consumptionUnitTypes() {
    return this.consumptionUnitTypes_ as ConsumptionUnitType[];
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get customEntityPropertyTypes() {
    return this.customEntityPropertyTypes_ as CustomEntityPropertyType[];
  }

  initBillingItems = async () => {
    // The filter hook gets & sets the billing items
    this.cultureTable_ = await this.productConfigStore.rootStore.configStore.configService.getCultureTable();
    this.taxCodes_ = await this.productConfigStore.rootStore.configStore.configService.getTaxCodes();
    this.calculationTypes_ = await this.productConfigStore.rootStore.configStore.configService.getCalculationTypes();
    this.consumptionUnitTypes_ =
      await this.productConfigStore.rootStore.configStore.configService.getConsumptionUnitTypes();
    this.customEntityPropertyTypes_ =
      await this.productConfigStore.rootStore.configStore.configService.getCustomEntityPropertyTypes();
  };

  setBillingItems = (newItems: BillingItemType[]) => {
    this.billingItems_ = newItems;
  };

  setSelectedBillingItem = (newItem: BillingItemType | undefined) => {
    this.selectedBillingItem = newItem;
  };

  addBillingItem = async (newItem: BillingItemRequestType) => {
    if (this.billingItems_) {
      const res = await this.productConfigStore.productConfigService.addBillingItem(newItem);
      // Update local storage
      this.billingItems_ = [res, ...this.billingItems_];
    }
  };

  updateBillingItemInList = (updatedItem: BillingItemType) => {
    if (this.billingItems_) {
      const cloned = [...this.billingItems_];
      const indexToUpdate = cloned.findIndex((b) => b.id === updatedItem.id);

      if (indexToUpdate !== -1) {
        cloned[indexToUpdate] = { ...updatedItem };
      }

      this.billingItems_ = cloned;
    }
  };

  updateBillingItem = async (id: string, updatedItem: BillingItemRequestType) => {
    return this.productConfigStore.productConfigService.updateBillingItem(id, updatedItem);
  };

  deleteBillingItem = async (billingItemId: string) => {
    await this.productConfigStore.productConfigService.deleteBillingItem(billingItemId);
  };

  deleteBillingItemInList = (index: number) => {
    if (this.billingItems_) {
      const cloned = [...this.billingItems_];
      // Update local storage
      cloned.splice(index, 1);
      this.billingItems_ = cloned;
      this.selectedBillingItem = undefined;
    }
  };

  initTariffs = async (billingItemId: string) => {
    const newTariffs = await this.productConfigStore.productConfigService.getTariffsForSelectedBillingItem(
      billingItemId
    );
    await this.productConfigStore.productsStore.getTree(billingItemId);
    this.tariffs_ = newTariffs;
  };

  addTariffToList = (newTariff: BillingTariffType) => {
    if (this.tariffs_) {
      // Add to top of list
      this.tariffs_ = [newTariff, ...this.tariffs_];
    }
  };

  addTariff = async (tariffToAdd: BillingTariffRequestType) => {
    return this.productConfigStore.productConfigService.addTariff(tariffToAdd);
  };

  updateTariffInList = (updatedTariff: BillingTariffType) => {
    if (this.tariffs_) {
      const indexToUpdate = this.tariffs_.findIndex((t) => t.id === updatedTariff.id);

      if (indexToUpdate !== -1) {
        this.tariffs_[indexToUpdate] = { ...updatedTariff };
      }
    }
  };

  updateTariff = async (id: string, tariffToUpdate: BillingTariffRequestType) => {
    return this.productConfigStore.productConfigService.updateTariff(id, tariffToUpdate);
  };

  deleteTariff = async (tariffId: string) => {
    await this.productConfigStore.productConfigService.deleteTariff(tariffId);
  };

  deleteTariffInlist = (index: number) => {
    if (this.tariffs_) {
      this.tariffs_.splice(index, 1);
    }
  };
}
