import React from 'react';

import { BillingAnalyticsType } from '@zf/api-types/analytics/billing';

import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../../../../../utils/request';
import MissingInvoicesChart from '../../../../../../cards/InvoicesChartCard/MissingInvoicesChart';

type Props = {
  propertyGroupId: string;
};

export default function InvoicesMissingChartCardWrapper(props: Props) {
  const { propertyGroupId } = props;

  const response = useSuspenseSingleAPI<BillingAnalyticsType>({
    request: {
      method: METHODS.GET,
      endpoint: '/insights/Billing',
      query: {
        propertyGroupId: propertyGroupId
      }
    }
  });

  if (!response.result) return null;

  const billingInsights = response.result.data;

  return <MissingInvoicesChart billingInsights={billingInsights} />;
}
