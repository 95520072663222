import classNames from 'classnames';
import React from 'react';

import { colors } from '@zf/utils/src/color';

import css from './status-badge.module.scss';

type Props = {
  children?: React.ReactNode | null;
  type?: 'filled' | 'outlined' | 'bare' | 'filled-inverse';
  color?: string;
  bgColor?: string;
  style?: React.CSSProperties;
  className?: string;
};

export default function StatusBadge(props: Props) {
  const { children, className, type = 'filled', color = colors['blue-600'], style, bgColor, ...otherProps } = props;

  let internalStyle;
  if (type === 'filled') {
    internalStyle = {
      color: colors['white-200'],
      backgroundColor: color,
      borderColor: color
    };
  } else if (type === 'outlined') {
    internalStyle = {
      color: color,
      borderColor: color,
      backgroundColor: colors['white-200']
    };
  } else if (type === 'filled-inverse') {
    internalStyle = {
      color,
      borderColor: colors[`${color}-200`],
      backgroundColor: colors[`${color}-200`]
    };
  } else {
    internalStyle = {
      color: color
    };
  }

  internalStyle = { ...internalStyle, ...style };

  return (
    <div
      className={classNames(
        css['status-badge'],
        {
          [css['bare']]: type === 'bare'
        },
        className
      )}
      style={internalStyle}
    >
      {children}
    </div>
  );
}
