import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useAppContext } from '../../app-context';
import { useStore } from '../../hooks/useStore';
import { ConfigSideBar } from './';
import ActionItemList from './action-item';
import APIDependentLinks from './APIDependentLinks';
import CountrySpecificLink from './CountrySpecificLink';
import NavList from './nav-list';
import NavListItem from './nav-list-item';
import css from './side-bar.module.scss';

export type Props = {
  currPath: string;
};

export const WHITE_LIST_ACTION = ['add', 'install'];

const SideBar = (props: Props) => {
  const { currPath } = props;

  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const { authReducer } = useAppContext();
  const { isReadOnly, isSuperUser, isAdmin } = authReducer;

  const splitted = currPath.split('/');
  let activePage = splitted[0];
  const secondPath = splitted[1];

  let isConfiguration = false;

  if (activePage === 'configuration') {
    isConfiguration = true;
    activePage = secondPath;
  }

  let configUrl = `${rootUrl}/configuration`;

  if (splitted[0] === 'configuration') {
    configUrl = rootUrl;
  }

  return (
    <>
      <div className={css['side-bar']}>
        <div className={css['side-bar-content']}>
          <div className={classNames(css['side-bar-nav'])}>
            <NavList>
              <NavListItem
                id="nav-location"
                title={getTranslation('nav.locations')}
                href={`${rootUrl}/locations`}
                active={activePage === 'locations'}
                iconType="location"
              />
              <NavListItem
                id="nav-property-groups"
                title={getTranslation('property_groups.property_groups')}
                href={`${rootUrl}/property-groups`}
                active={activePage === 'property-groups'}
                iconType="property-groups"
              />
              <NavListItem
                id="nav-customer"
                title={getTranslation('customer.customers')}
                href={`${rootUrl}/customers`}
                active={activePage === 'customers'}
                iconType="customer"
              />
              <NavListItem
                id="nav-contract"
                title={getTranslation('contracts.contracts')}
                href={`${rootUrl}/contracts`}
                active={activePage === 'contracts'}
                iconType="contract"
              />
              <NavListItem
                id="nav-invoice"
                title={getTranslation('invoice.invoices')}
                href={`${rootUrl}/invoices`}
                active={activePage === 'invoices'}
                iconType="invoice"
              />

              <APIDependentLinks activePage={activePage} isConfiguration={isConfiguration} />

              <NavListItem
                id="nav-meter"
                title={getTranslation('nav.devices')}
                href={`${rootUrl}/devices/meters`}
                active={activePage === 'devices'}
                iconType="meter"
              />
              <NavListItem
                id="nav-import-jobs"
                title={getTranslation('nav.import-jobs')}
                href={`${rootUrl}/import-jobs`}
                active={activePage === 'import-jobs' && !isConfiguration}
                iconType="import-job"
              />
              <NavListItem
                id="nav-export-jobs"
                title={getTranslation('nav.export-jobs')}
                href={`${rootUrl}/export-jobs`}
                active={activePage === 'export-jobs' && !isConfiguration}
                iconType="export-job"
              />
              <NavListItem
                id="nav-tasks"
                title={getTranslation('nav.tasks')}
                href={`${rootUrl}/tasks`}
                active={activePage === 'tasks'}
                iconType="task"
              />

              <CountrySpecificLink activePage={activePage} />
            </NavList>
          </div>

          {!isReadOnly() && (
            <>
              <div tabIndex={0} role="button" className={classNames(css['side-bar-actions'])}>
                <ActionItemList />
              </div>
              <div className={css['side-bar-footer']}>
                {(isSuperUser() || isAdmin()) && (
                  <NavListItem
                    id="nav-config"
                    title={getTranslation('nav.configuration')}
                    active={isConfiguration}
                    iconType="config"
                    href={configUrl}
                  />
                )}
              </div>
              )
            </>
          )}
        </div>
      </div>

      {isConfiguration && <ConfigSideBar activePage={activePage} />}
    </>
  );
};

export default observer(SideBar);
