import React from 'react';

import { countryCode } from '@zf/api-types/enums';

import { useAppContext } from '../../app-context';
import SimpleDropdown from '../Lang/SimpleDropdown';
import CountryInputRequest from './country-input-request';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

export type CountryInputProps = {
  id: string;
  placeholder?: string;
  selectedValues: Array<string>;
  disabled?: boolean;
  multiSelect?: boolean;
  clear?: boolean;
  error?: boolean;
  className?: string;
  autoFocus?: boolean;
  organisationScoped?: boolean;
  acceptedCountries?: countryCode[];
  onFocus?: (step: string) => void;
  onChange: (values: string[]) => void;
};

export default function CountryInput(props: CountryInputProps) {
  const {
    id,
    placeholder,
    selectedValues,
    disabled = false,
    multiSelect = false,
    clear = true,
    organisationScoped = false,
    error,
    className,
    acceptedCountries,
    onChange,
    onFocus,
    ...otherProps
  } = props;

  const { enumReducer } = useAppContext();
  let countries: DropdownValuesType<countryCode>[] = [];

  if (acceptedCountries) {
    countries = enumReducer.getEnum<countryCode>('countryCode').filter((e) => acceptedCountries.includes(e.value));
  } else {
    countries = enumReducer.getEnum<countryCode>('countryCode');
  }

  return organisationScoped ? (
    <CountryInputRequest {...props} countries={countries} />
  ) : (
    <SimpleDropdown
      id={id}
      placeholder={placeholder}
      selectedValues={selectedValues}
      values={countries}
      disabled={disabled}
      multiSelect={multiSelect}
      clear={clear}
      error={error}
      className={className}
      onChange={onChange}
      onFocus={onFocus}
      {...otherProps}
    />
  );
}
