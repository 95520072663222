import LocationSectionStore from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import { Button } from 'design-system/Components';
import { dialog } from 'events/dialog-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import AddLocationsDialog from './AddLocationsDialog';

type Props = {
  locationSectionStore: LocationSectionStore;
};

const AddLocationsBtn = (props: Props) => {
  const { locationSectionStore } = props;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const openAddLocationsDialog = () => {
    dialog.normalBig({
      title: getTranslation('contracts.wizard.add_locations'),
      icon: 'plus',
      content: (
        <AddLocationsDialog ref={clickRef} validationRef={validationRef} locationSectionStore={locationSectionStore} />
      ),
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <Button id="add-locations" onClick={openAddLocationsDialog} icon="plus">
      {getTranslation('contracts.wizard.add_locations')}
    </Button>
  );
};

export default observer(AddLocationsBtn);
