import UserStore, { SYSTEM_USER } from 'app-context/stores/domain/UserStore';
import { useEffect } from 'react';

const useUser = (userId: string | null | undefined, userStore: UserStore) => {
  const { users, cacheMatchingUser } = userStore;

  useEffect(() => {
    if (userId) {
      cacheMatchingUser(userId);
    }
  }, []);

  const userName = users.get(userId || '')?.userName || SYSTEM_USER;

  return userName;
};

export default useUser;
