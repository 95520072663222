import React from 'react';

import { ImportJobRowType } from '@zf/api-types/import-job';

import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import ExportToExcel from './ExportToExcel';

export default function ImportJobsExport() {
  const { i18n, enumReducer } = useAppContext();
  const { myCurrent } = useCurrent('importjob');
  const selectedRows: ImportJobRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = (): string[][] => {
    const exportArray = new Array(selectedRows.length + 1);
    exportArray[0] = [
      i18n.getTranslation('import_jobs.source'),
      i18n.getTranslation('import_jobs.file_name'),
      i18n.getTranslation('import_jobs.created'),
      i18n.getTranslation('general.values'),
      i18n.getTranslation('general.status'),
      i18n.getTranslation('import_jobs.finished'),
      i18n.getTranslation('import_jobs.dataprovider'),
      i18n.getTranslation('import_jobs.communication'),
      i18n.getTranslation('import_jobs.file_format'),
      i18n.getTranslation('general.type')
    ];

    for (let i = 1; i < exportArray.length; i++) {
      exportArray[i] = new Array(exportArray[0].length);
      const selectedRow = selectedRows[i - 1];

      if (selectedRow && Object.keys(selectedRow).length > 1) {
        exportArray[i][0] = selectedRow.__importJobEntity.source;
        exportArray[i][1] = selectedRow.__importJobEntity.fileName;
        exportArray[i][2] = selectedRow.createdDateTime;
        exportArray[i][3] = selectedRow.amountOfValuesParsed;
        exportArray[i][4] = enumReducer.getTranslation('importJobStatus', selectedRow.__importJobEntity.status);
        exportArray[i][5] = selectedRow.__finished
          ? i18n.getTranslation('general.yes')
          : i18n.getTranslation('general.no');
        exportArray[i][6] = selectedRow.dataprovider ? selectedRow.dataprovider : '';
        exportArray[i][7] = selectedRow.communicationType;
        exportArray[i][8] = selectedRow.fileformat ? selectedRow.fileformat : '';
        exportArray[i][9] = selectedRow.importType;
      }
    }

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      key="importJobsExport"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={i18n.getTranslation('export_excel.export_import_jobs')}
      ws_name={i18n.getTranslation('import_jobs.import_jobs')}
    />
  );
}
