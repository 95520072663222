import { PagedResponseType } from '@zf/api-types/api';
import { CommunicationParameterType } from '@zf/api-types/communication';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ExportDataProviderType } from '@zf/api-types/data-provider';
import { APISettingsType } from '@zf/api-types/file-format';
import { BankAccountType, ParameterTypesType } from '@zf/api-types/general';
import { AdvanceLimit, ConfiguredPluginType } from '@zf/api-types/integration/plugin';
import { CultureTableType } from '@zf/api-types/language';
import { MeteringValidationParameters } from '@zf/api-types/meter-config';
import { ModelType } from '@zf/api-types/model';
import { BillingParametersType, PaymentParametersType } from '@zf/api-types/parameters';
import {
  BillingItemType,
  BillingTariffType,
  ConsumptionUnitType,
  PaymentTermType,
  ProductType
} from '@zf/api-types/product';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { TaxCodeType } from '@zf/api-types/tax-codes';

import { queryParam } from '../../hooks/useCreateRequest';
import { METHODS } from '../../utils/request';
import RootStore from '../stores';
import ConfigStore from '../stores/ConfigStore';
import SingletonService from './SingletonService';

export default class ConfigApiService extends SingletonService {
  public configStore: ConfigStore;

  constructor(rootStore: RootStore, configStore: ConfigStore) {
    super(rootStore);
    this.configStore = configStore;
  }

  getBankAccounts = async () => {
    return this.getPagedVariable<BankAccountType>(
      '/cfg/BankAccounts',
      this.configStore._bankAccounts,
      this.configStore.setBankAccounts
    );
  };

  getProducts = async () => {
    return this.getPagedVariable<ProductType>(
      '/cfg/Products',
      this.configStore._products,
      this.configStore.setProducts
    );
  };

  getBillingItems = async () => {
    return this.getPagedVariable<BillingItemType>(
      '/cfg/BillingItems',
      this.configStore._billingItems,
      this.configStore.setBillingItems
    );
  };

  getPaymentTerms = async () => {
    return this.getPagedVariable<PaymentTermType>(
      '/cfg/PaymentTerms',
      this.configStore._paymentTerms,
      this.configStore.setPaymentTerms
    );
  };

  getConfiguredPlugins = async () => {
    return this.getPagedVariable<ConfiguredPluginType>(
      '/int/ConfiguredPlugins',
      this.configStore._configuredPlugins,
      this.configStore.setConfiguredPlugins
    );
  };

  getMeterModels = async () => {
    return this.getPagedVariable<ModelType>(
      '/cfg/Models',
      this.configStore._meterModels,
      this.configStore.setMeterModels
    );
  };

  getExportFileFormats = async () => {
    return this.getPagedVariable<APISettingsType>(
      '/cfg/DataExportFileFormats',
      this.configStore._exportFileFormats,
      this.configStore.setExportFileFormats
    );
  };

  getDataExports = async () => {
    return this.getPagedVariable<ExportDataProviderType>(
      '/cfg/DataExports',
      this.configStore._dataExports,
      this.configStore.setDataExports
    );
  };

  getBillingParameters = async () => {
    return this.getSingleVariable<BillingParametersType>(
      '/cfg/Parameters/billing',
      this.configStore._billingParameters,
      this.configStore.setBillingParameters
    );
  };

  getCommunicationParameters = async () => {
    return this.getSingleVariable<CommunicationParameterType>(
      '/cfg/Parameters/communication',
      this.configStore._communicationParameters,
      this.configStore.setCommunicationParameters
    );
  };

  getMeteringValidationParameters = async () => {
    return this.getSingleVariable<MeteringValidationParameters>(
      '/cfg/Parameters/metering',
      this.configStore._meteringValidationParemeters,
      this.configStore.setMeteringValidationParameters
    );
  };

  getOrganisationConfig = async () => {
    return this.getSingleVariable<OrganizationConfigType>(
      '/cfg/Organizations',
      this.configStore._organisationConfig,
      this.configStore.setOrganisationConfig
    );
  };

  getCultureTable = async () => {
    return this.getSingleVariable<CultureTableType>(
      '/cfg/cultureTable',
      this.configStore._cultureTable,
      this.configStore.setCultureTable
    );
  };

  getAdvanceLimit = async () => {
    return this.getSingleVariable<AdvanceLimit>(
      '/int/EndUserPortalPlugin/GetAdvanceLimit',
      this.configStore._advanceLimit,
      this.configStore.setAdvanceLimit
    );
  };

  getCalculationTypes = async () => {
    return this.getSingleVariable<ParameterTypesType[]>(
      '/cfg/BillingItems/calculationtypes',
      this.configStore._calculationTypes,
      this.configStore.setCalculationTypes
    );
  };

  getPaymentParameters = async () => {
    return this.getSingleVariable<PaymentParametersType>(
      '/cfg/Parameters/payment',
      this.configStore._paymentParameters,
      this.configStore.setPaymentParameters
    );
  };

  getCustomEntityPropertyTypes = async () => {
    if (!this.configStore._customEntityPropertyTypes || this.configStore.refetchCustomEntityPropertyTypes) {
      const res = (
        await this.rootStore.applicationStore.sendRequest<PagedResponseType<CustomEntityPropertyType>>({
          request: {
            method: METHODS.GET,
            endpoint: '/cfg/CustomEntityPropertyTypes'
          }
        })
      ).data.results;

      this.configStore.setCustomEntityPropertyTypes(res);

      if (this.configStore.refetchCustomEntityPropertyTypes) {
        // We fetched again, reset this variable
        this.configStore.setRefetchCustomEntityPropertyTypes(false);
      }

      return res;
    } else {
      return this.configStore._customEntityPropertyTypes;
    }
  };

  getConsumptionUnitTypes = async () => {
    if (!this.configStore._consumptionUnitTypes || this.configStore.refetchConsumptionUnitTypes) {
      const res = (
        await this.rootStore.applicationStore.sendRequest<PagedResponseType<ConsumptionUnitType>>({
          request: {
            method: METHODS.GET,
            endpoint: '/cfg/ConsumptionUnitTypes'
          }
        })
      ).data;

      this.configStore.setConsumptionUnitTypes(res.results);

      if (this.configStore.refetchConsumptionUnitTypes) {
        // We fetched again, reset this variable
        this.configStore.setRefetchConsumptionUnitTypes(false);
      }

      return res.results;
    } else {
      return this.configStore._consumptionUnitTypes;
    }
  };

  getTaxCodes = async () => {
    if (!this.configStore._taxCodes || this.configStore.refetchTaxCodes) {
      const res = (
        await this.rootStore.applicationStore.sendRequest<PagedResponseType<TaxCodeType>>({
          request: {
            method: METHODS.GET,
            endpoint: '/cfg/Taxcodes'
          }
        })
      ).data;

      this.configStore.setTaxCodes(res.results);

      if (this.configStore.refetchTaxCodes) {
        // We fetched again, reset this variable
        this.configStore.setRefetchTaxCodes(false);
      }

      return res.results;
    } else {
      return this.configStore._taxCodes;
    }
  };

  // Non singleton API's

  getProductsForQuery = async (query: queryParam) => {
    return (
      await this.rootStore.applicationStore.sendRequest<PagedResponseType<ProductType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/Products',
          query
        }
      })
    ).data.results;
  };

  getTariffsForQuery = async (query: queryParam) => {
    return (
      await this.rootStore.applicationStore.sendRequest<PagedResponseType<BillingTariffType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/BillingTariffs',
          query
        }
      })
    ).data.results;
  };
}
