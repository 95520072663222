import Interweave from 'interweave';
import { observer } from 'mobx-react-lite';
import React, { forwardRef, useImperativeHandle } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ParameterTypesType } from '@zf/api-types/general';
import { BillingItemType, ProductBillingItemType } from '@zf/api-types/product';
import { CalculationConfigurationBillingItemType } from '@zf/api-types/property-group-billing-configuration';
import { TaxCodeType } from '@zf/api-types/tax-codes';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import { notify } from '../../events/notification-events';
import useBillingItemsFilter from '../../features/product-config/hooks/use-billing-items-filter';
import ListBillingItemList from '../../features/product-config/products/dialogs/list-billing-item-list';
import { useStore } from '../../hooks/useStore';
import FilterInputs from '../Filters/filter-inputs';
import FilterTags from '../Filters/filter-tags';
import css from './add-billing-items-dialog.module.scss';
import AllocationListItems from './AllocationListItems';
import DialogBillingItems from './DialogBillingItems';

export type BillingItemsDialogUseCase = 'allocationGroup' | 'product';

type Props<E, B> = {
  useCase: BillingItemsDialogUseCase;
  calculationTypes: ParameterTypesType[];
  customEntityPropertyTypes: CustomEntityPropertyType[];
  taxCodes: TaxCodeType[];
  onSubmit: Function;
  showExtraInfo?: boolean;
  generateNewEntityBillingItem: (billingItem: BillingItemType) => B;
  initTariffs?: (billingItemId: string, selectedProductId: string) => Promise<void>;
  // @ts-ignore
  billingItemsDialogStore: BillingItemsDialogStore<E, B>;
};

const AddBillingItemsDialog = forwardRef(function <
  E extends { billingItems: B[]; name: string },
  B extends { billingItemId: string }
>(props: Props<E, B>, ref: React.Ref<DialogClickRef | undefined>) {
  const {
    useCase,
    calculationTypes,
    customEntityPropertyTypes,
    taxCodes,
    billingItemsDialogStore,
    onSubmit,
    showExtraInfo = true,
    generateNewEntityBillingItem
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    billingItems_,
    billingItems,
    updatingForEntity,
    updateSelection,
    setUpdatingForEntityBillingItems,
    setBillingItems
  } = billingItemsDialogStore;

  const { filterInputs, tags, resetFilters } = useBillingItemsFilter(setBillingItems);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await onSubmit();

        notify.success({
          content: getTranslation('product_config.add_items_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('product_config.add_items_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph textAlign="center">
        <Interweave
          content={
            showExtraInfo
              ? `${getTranslation('product_config.add_items_paragraph', {
                  name: updatingForEntity.name
                })}, ${getTranslation('product_config.add_items_paragraph_next')}`
              : `${getTranslation('product_config.add_items_paragraph', {
                  name: updatingForEntity.name
                })}`
          }
        />
      </Paragraph>
      <div className={css['wrapper']}>
        <AsyncVarSpinner variables={[billingItems_]}>
          <div>
            <div className={css['items-header']}>
              <Heading headingType="h2" style="bold">
                {getTranslation('billing_items.billing_items')}
              </Heading>
              <>
                {billingItems_ && (
                  <div className={css['items-filter']}>
                    <FilterTags tags={tags} />
                    <FilterInputs filterInputs={filterInputs} removeAllFilters={resetFilters} />
                  </div>
                )}
              </>
            </div>

            <DialogBillingItems
              billingItems={billingItems}
              listedBillingItems={updatingForEntity.billingItems}
              calculationTypes={calculationTypes}
              customEntityPropertyTypes={customEntityPropertyTypes}
              taxCodes={taxCodes}
              generateNewEntityBillingItem={generateNewEntityBillingItem}
              // @ts-ignore
              updateSelection={updateSelection}
            />
          </div>

          {useCase === 'product' ? (
            <ListBillingItemList
              productBillingItems={updatingForEntity.billingItems as unknown as ProductBillingItemType[]}
              setProductBillingItems={
                setUpdatingForEntityBillingItems as unknown as (newItems: ProductBillingItemType[]) => void
              }
            />
          ) : (
            <AllocationListItems
              allocationBillingItems={
                updatingForEntity.billingItems as unknown as CalculationConfigurationBillingItemType[]
              }
              billingItems={billingItems}
            />
          )}
        </AsyncVarSpinner>
      </div>
    </>
  );
});

export default observer(AddBillingItemsDialog);
