import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { outgoingMutationStatus, outgoingMutationType } from '@zf/api-types/enums';

export type OutgoingMutationQuickFilterType = 'all' | 'invoices' | 'manualentries' | 'resolveissues';

export type MutationQueryParamsType = {
  quickFilter: OutgoingMutationQuickFilterType;
  referenceType: outgoingMutationType[];
  status: outgoingMutationStatus[];
  flexSearch: string;
};

export type OutgoingMutationTypeOverviewCountType = {
  all: number;
  invoices: number;
  manualEntries: number;
  resolveIssues: number;
};

export type OutgoingBankingTransactionCtxState = {
  queryParams: MutationQueryParamsType;
  overviewCounts: OutgoingMutationTypeOverviewCountType;
};

type Action =
  | { type: 'UPDATE'; newState: Partial<OutgoingBankingTransactionCtxState> }
  | { type: 'UPDATE_QUERY_PARAMS'; newParams: Partial<MutationQueryParamsType> }
  | { type: 'RESET_QUERY_PARAMS' }
  | { type: 'SET_OVERVIEW_COUNTS'; newCounts: OutgoingMutationTypeOverviewCountType };

const initialState: OutgoingBankingTransactionCtxState = {
  queryParams: {
    quickFilter: 'all',
    referenceType: [],
    status: [],
    flexSearch: ''
  },
  overviewCounts: {
    all: 0,
    invoices: 0,
    manualEntries: 0,
    resolveIssues: 0
  }
};

const reducer = (state: OutgoingBankingTransactionCtxState, action: Action): OutgoingBankingTransactionCtxState => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.newState };

    case 'UPDATE_QUERY_PARAMS':
      return { ...state, queryParams: { ...state.queryParams, ...action.newParams } };

    case 'RESET_QUERY_PARAMS':
      return {
        ...state,
        queryParams: {
          quickFilter: state.queryParams.quickFilter, // Skip this as we are only resetting the filters right of the header
          referenceType: [],
          status: [],
          flexSearch: ''
        }
      };

    case 'SET_OVERVIEW_COUNTS':
      return { ...state, overviewCounts: action.newCounts };

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider: OutgoingBankingTransactionProvider,
  useTracked,
  useUpdate: useDispatch
} = createContainer(useValue);
