import { addLocationsMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/AddLocationsMoveInWizardStore';
import { WizardSectionHeader } from 'design-system/Components';
import { InfoBanner } from 'design-system/ComponentSets';
import { Paragraph, UppercaseText } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { replaceMessagePlaceholders } from 'utils/systemMessage';

import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import SupplyDateInputs from '../../shared/SupplyDateInputs/SupplyDateInputs';
import MultipleLocations from '../shared/location-section/MultipleLocations';
import css from './edit-location-section.module.scss';

const EditLocationSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addLocationsMoveInWizardStore } = contractStore.moveInWizardBaseStore;
  const {
    locationSectionStore,
    addLocationsMoveInWizardValues,
    suggestionMsgColorMap,
    addLocationsMoveInWizardNavigator
  } = addLocationsMoveInWizardStore;
  const { values, setValue } = addLocationsMoveInWizardValues;
  const { existingContract, supplyDateType_, supplyDate, billingCompleteness } = values;
  const { invoiceProductConfigurationSuggestions, invoiceBillingConfigurationSuggestions } = locationSectionStore;
  const { setActiveStep } = addLocationsMoveInWizardNavigator;

  const sectionTitle = getTranslation('contracts.steps.location');

  // This component has a dependency on the selected contract for the current location count
  if (!existingContract) return null;

  const infoMessageProduct = replaceMessagePlaceholders(invoiceProductConfigurationSuggestions?.message);
  const msgKeyProduct = invoiceProductConfigurationSuggestions?.message?.key;

  const infoMessageBilling = invoiceBillingConfigurationSuggestions?.message?.message;
  const msgKeyBilling = invoiceBillingConfigurationSuggestions?.message?.key;

  return (
    <WizardSection id="location-section" setActiveStep={() => setActiveStep(addLocationsMoveInWizardSteps.locations)}>
      <WizardSectionHeader id={addLocationsMoveInWizardSteps.locations} text={sectionTitle} />

      <FlexElements>
        <UppercaseText>{getTranslation('contracts.current_locations')}</UppercaseText>
        <Paragraph textAlign="right">
          <Interweave
            content={getTranslation('contracts.wizard.n_locations_on_contract', {
              amount: existingContract.serviceLocations.length
            })}
          />
        </Paragraph>
      </FlexElements>

      <HorizontalDivider />

      <MultipleLocations
        title={getTranslation('contracts.new_locations')}
        locationSectionStore={locationSectionStore}
      />

      <FlexElements className={css['warnings']} flexDirection="column" gap="24">
        {msgKeyProduct && infoMessageProduct && (
          <InfoBanner info={infoMessageProduct} color={suggestionMsgColorMap[msgKeyProduct]} roundedCorners />
        )}

        {msgKeyBilling && infoMessageBilling && (
          <InfoBanner info={infoMessageBilling} color={suggestionMsgColorMap[msgKeyBilling]} roundedCorners />
        )}
      </FlexElements>

      <SupplyDateInputs
        title={getTranslation('general.start_date')}
        description={getTranslation('contracts.start_date_descr')}
        contract={existingContract}
        supplyDateType_={supplyDateType_}
        supplyDate={supplyDate}
        billingCompleteness={billingCompleteness}
        setValue={setValue}
      />
    </WizardSection>
  );
};

export default observer(EditLocationSection);
