import Dropdown from 'components/Lang/Dropdown';
import { RadioInput } from 'design-system/Components';
import { notify } from 'events/notification-events';
import CompletenessInvoiceStatus from 'features/contract/src/dialogs/change-product/CompletenessInvoiceStatus';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { ContractType } from '@zf/api-types/master-data/contract';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { formatDateWMonth } from '@zf/utils/src/date';

import css from './supply-date-inputs.module.scss';
import { SupplyDateInputValues, supplyDateType } from './SupplyDateInputs';

type Props = {
  contract: ContractType;
  supplyDateType_: supplyDateType;
  billingCompleteness: BillingCompletenessType | undefined;
  setStartDateType: (supplyDateType_: supplyDateType, option: string) => void;
  setValue: (val: Partial<SupplyDateInputValues>) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<BillingCompletenessType>>(Dropdown);

const BillingPeriodStartOption = (props: Props) => {
  const { contract, supplyDateType_, billingCompleteness, setStartDateType, setValue } = props;
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { billingCompletenessStore, contractApiService } = contractStore;
  const { getBillingRelation } = contractApiService;
  const { getBillingCompletenessesForBillingRelation } = billingCompletenessStore.completenessApiService;

  const [billingCompletenesses, setBillingCompletenesses] = useState<BillingCompletenessType[]>();

  useEffect(() => {
    // Lame treasure hunt
    getBillingRelation(contract.id, contract.contractor.customerId)
      .then((billingRelation) => {
        if (billingRelation) {
          getBillingCompletenessesForBillingRelation(billingRelation.id)
            .then((res) => setBillingCompletenesses(res))
            .catch((e) => {
              throw e;
            });
        }
      })
      .catch((error) => {
        notify.error({
          content: getTranslation('contracts.wizard.get_invoice_periods_fail'),
          error
        });
      });
  }, [contract.id]);

  const billingCompletenessDropDown = billingCompletenesses?.map((bc) => ({
    value: bc,
    text: formatDateWMonth(bc.periodStartDateTime)
  }));

  return (
    <>
      <RadioInput
        onChange={(option) => setStartDateType(supplyDateType.billingperiodstart, option)}
        value={supplyDateType.billingperiodstart}
        name={supplyDateType.billingperiodstart}
        checked={supplyDateType_ === supplyDateType.billingperiodstart}
      >
        {getTranslation('contracts.select_billing_period')}
      </RadioInput>

      {supplyDateType_ === supplyDateType.billingperiodstart && (
        <div className={css['invoice-period']}>
          <InlineDropdown
            id="contracts.dropdown-billing-period"
            selectedValues={[billingCompleteness?.id || '']}
            values={billingCompletenessDropDown || []}
            onChange={(val) => {
              setValue({
                supplyDate: val[0]?.periodStartDateTime ? moment(val[0]?.periodStartDateTime) : null,
                billingCompleteness: val[0]
              });
            }}
            placeholder={getTranslation('property_groups.tabs.billing.select_billing_period')}
            extraRight={(b) => <CompletenessInvoiceStatus billingCompleteness={b} />}
            error={!billingCompleteness}
          />
        </div>
      )}
    </>
  );
};

export default observer(BillingPeriodStartOption);
