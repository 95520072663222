import clone from 'clone';
import moment from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { CollectionCaseType } from '@zf/api-types/collection-case';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import useCurrent from '../../app-context/hooks/use-current';
import CardHeaderWarning from '../../components/card/card-header-warning';
import { Button, Link } from '../../design-system/Components';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import TransactionBody from './transaction-body';
import css from './cash-position-card.module.scss';
import { contractBillingMethod } from '@zf/api-types/enums';
import { useStore } from '../../hooks/useStore';
import { Paragraph } from 'design-system/Foundation';

type Props = {
  customer: CustomerType;
  timestampRefresh: string;
  setActiveTab: (tabName: string) => void;
};

export default function CashPositionCard(props: Props) {
  const { customer, setActiveTab } = props;
  const { applicationStore } = useStore();
  const { getTranslation, rootUrl } = applicationStore;
  const { myCurrent, setMyCurrent } = useCurrent('collection_case');
  const customerId = customer.id;
  const isPrepayment = customer.contracts.some((c) => c.contractBillingMethod === contractBillingMethod.prepayment);
  const isAutomaticSettlementOn = customer.billingSettings.automaticSettlementSetting.automaticSettlement;
  const autoSettlementLabel = (
    <Paragraph>
      {isAutomaticSettlementOn !== null &&
        getTranslation('customer.automatic_settlement_on_off', {
          setting: isAutomaticSettlementOn ? getTranslation('general.on') : getTranslation('general.off')
        })}

      {isAutomaticSettlementOn === null &&
        getTranslation('customer.automatic_settlement_on_off', {
          setting: getTranslation('general.on')
        })}
    </Paragraph>
  );

  const ShowMoreBtn = (
    <Button id="show_more" icon="eye" type="text" onClick={() => setActiveTab('transactions')}>
      {getTranslation('cards.shared.show_more')}
    </Button>
  );

  const setQueryParams = () => {
    const currentClone = clone(myCurrent);
    currentClone.filter.quickFilter = 'active';
    currentClone.filter.queryParams = {
      customerId: customerId,
      customerName: customer.shortDisplayName,
      nextActionDueFrom: null,
      nextActionDueTo: null
    };
    setMyCurrent(currentClone);
  };

  const renderHeader = (title: string) => {
    return <CardHeader extraRight={autoSettlementLabel}>{title}</CardHeader>;
  };

  const casesResponse = useSuspenseSingleAPI<PagedResponseType<CollectionCaseType>>({
    request: {
      endpoint: '/bill/CollectionCases',
      query: { customerId: customer.id, customerName: customer.shortDisplayName, quickFilter: 'active' }
    }
  });

  if (!casesResponse.result)
    return (
      <Card id="cash-position-card" width="2">
        {renderHeader('')}
        <CardBody type="table" fixedHeight />
      </Card>
    );

  const collCases = casesResponse.result.data.results;

  collCases.sort((a, b) => moment(a.createdDateTime).valueOf() - moment(b.createdDateTime).valueOf());
  const oldestActive = collCases[0];
  const activeCycle = oldestActive ? oldestActive.workflowName : '';

  return (
    <Card id="cash-position-card" width="2">
      {collCases.length === 0 ? (
        renderHeader(
          isPrepayment ? getTranslation('meter.prepayment_devices') : getTranslation('cards.cash_position.title')
        )
      ) : (
        <CardHeaderWarning usePopup={false} extraRight={autoSettlementLabel} noHTag>
          <div className={css['header']}>
            <Heading headingType="h3" style="bold">
              {getTranslation('cards.cash_position.title')}
            </Heading>
            {oldestActive && (
              <div className={css['active-case']}>
                {collCases.length > 1 ? (
                  <>
                    {` - ${getTranslation('collection_case.more_active_cases', { number: collCases.length })}`}
                    <Link
                      id="more-active-cases"
                      url={`${rootUrl}/collection-cases?quickFilter=active&customerName=${customer.shortDisplayName}&customerId=${customerId}`}
                      onNavigate={() => setQueryParams()}
                    >
                      {getTranslation('collection_case.go_to_list')}
                    </Link>
                  </>
                ) : (
                  <>
                    {` - ${getTranslation('collection_case.active_case')}: `}
                    <Link id="active-case" url={`${rootUrl}/collection-cases/${oldestActive.id}`}>
                      {activeCycle}
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
        </CardHeaderWarning>
      )}

      <TransactionBody {...props} isPrepayment={isPrepayment} customerId={customerId} ShowMoreBtn={ShowMoreBtn} />
    </Card>
  );
}
