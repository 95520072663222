import React from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatDate, formatPeriod } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useListPage from '../../../app-context/hooks/use-listpage';
import { ICON_COLORS } from '../../../constants/icons';
import { getTaskText } from '../../../utils/task';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Link } from 'design-system/Components';
import { Subtext } from 'design-system/Foundation';
import useDialog from '@zf/hooks/src/useDialog';
import { dialog } from 'events/dialog-events';
import Locations from './Locations';

const useTasksListPage = () => {
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const { clickRef, validationRef, onSubmit } = useDialog();

  const openAllLocationsDialog = async (task: MoveRequestType) => {
    const id = task.contractDetails.externalContractId || task.contractDetails.internalContractId;

    dialog.normal({
      title: i18n.getTranslation('location.all_locations'),
      content: <Locations id={id} clickRef={clickRef} validationRef={validationRef} />,
      isDismissDialog: true,
      ref: validationRef,
      onSubmit
    });
  };

  const LocationsBtn = ({ count, task }: { count: number; task: MoveRequestType }) => {
    return (
      <div
        id="all-locations"
        onClick={() => openAllLocationsDialog(task)}
        style={{ textAlign: 'left', textDecoration: 'underline', cursor: 'pointer' }}
      >
        {count}
      </div>
    );
  };

  const processRecord = (task: MoveRequestType): TaskRowType => {
    return {
      __id: task.id,
      __etag: task._etag,
      __entity: task,
      name: (
        <Link id={`${routedEntitySubjectType.tasks}-${task.id}`} url={`${rootUrl}/tasks/${task.id}`}>
          {getTaskText(i18n.getTranslation, enumReducer.getTranslation, task)}
        </Link>
      ),
      'ServiceLocationDetails.Address.LocalizedDisplay': (
        <>
          {task.contractDetails.serviceLocationsCount > 1 ? (
            <LocationsBtn count={task.contractDetails.serviceLocationsCount} task={task} />
          ) : (
            <Link
              id={`${routedEntitySubjectType.tasks}-${task.serviceLocationDetails.internalId}`}
              url={`${rootUrl}/locations/${task.serviceLocationDetails.internalId}`}
            >
              {task.serviceLocationDetails?.address?.localizedDisplay}
            </Link>
          )}
        </>
      ),

      CreatedDateTime: formatDate(task.createdDateTime),
      MutationDateTime: formatDate(task.mutationDateTime),
      currentContract: (
        <Link
          id={`${routedEntitySubjectType.tasks}-${task.serviceLocationDetails.currentContractDetails?.contractId}`}
          url={`${rootUrl}/contracts/${task.serviceLocationDetails.currentContractDetails?.contractId}`}
        >
          {task.serviceLocationDetails.currentContractDetails && (
            <>
              <Paragraph bold>
                {task.serviceLocationDetails.currentContractDetails.contractNumber}
                {' - '}
                {task.serviceLocationDetails.currentContractDetails.customerDisplayName}
              </Paragraph>
              <Subtext>
                {formatPeriod(
                  task.serviceLocationDetails.currentContractDetails.contractSupplyStartDate,
                  task.serviceLocationDetails.currentContractDetails.contractSupplyEndDate
                )}
              </Subtext>
            </>
          )}
        </Link>
      ),
      owner: (
        <Link
          id={`${routedEntitySubjectType.tasks}-${task.serviceLocationDetails.owner?.customerId}`}
          url={`${rootUrl}/customers/${task.serviceLocationDetails.owner?.customerId}`}
          icon="customer"
          noNavigation={task.serviceLocationDetails.owner === null ? true : false}
        >
          <Paragraph>
            {task.serviceLocationDetails.owner ? task.serviceLocationDetails.owner.shortDisplayName : null}
          </Paragraph>
        </Link>
      ),
      status: (
        <NewStatusBadge
          color={ICON_COLORS[task.status]}
          status_={enumReducer.getTranslation('moveRequestStatus', task.status)}
        />
      )
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    setSelectedIds,
    setStopIndex,
    handleSort
  } = useListPage<MoveRequestType, TaskRowType>({
    endpoint: '/md/MoveRequests',
    processRecord,
    domain: 'moverequest'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useTasksListPage;
