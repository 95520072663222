import { enumTranslationType, UpdateEnumSymbolTranslationRequest } from '@zf/api-types/config/Communication/templates';

import BaseFormVjf from '../../../app-context/stores/forms/BaseFormVjf';
import CommunicationStore from '../stores/CommunicationStore';

type GlobalEnumerationsFieldTypes = {};

class GlobalEnumerationsForm extends BaseFormVjf<GlobalEnumerationsFieldTypes> {
  public communicationStore: CommunicationStore;
  public translation: enumTranslationType;
  
  constructor(communicationStore: CommunicationStore, translation: enumTranslationType) {
    super({});
    this.communicationStore = communicationStore;
    this.translation = translation;
  }

  save = async () => {
    await this.communicationStore.communicationService.updateEnumTranslation(
      this.translation.enumType,
      this.translation.symbol,
      this._values as UpdateEnumSymbolTranslationRequest
    );

  };

  hooks() {
    return {
      onSuccess(form: GlobalEnumerationsForm) {
        form.save();
      }
    };
  }
}

export default GlobalEnumerationsForm;
