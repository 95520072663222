import React from 'react';
import classNames from 'classnames';

import css from './list-heading.module.scss';

export type PropsType = {
  children: React.ReactNode;
  className?: string;
};

export default function ListHeading(props: PropsType) {
  const { children, className } = props;

  return <li className={classNames(css['list-heading'], className)}>{children}</li>;
}
