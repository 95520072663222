import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

import { meterTypeGroup } from '@zf/api-types/local-enums';
import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import { ModelPagedResultType, ModelType } from '@zf/api-types/model';
import DropDownDetails from '@zf/stella-react/src/atoms/Dropdown/DropDownDetails';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { betweenDates, formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context/app-context';
import { Link } from '../../design-system/Components';
import MeterAddress from '../../features/devices/meter/detail-page/detail/MeterAddress';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import { formatActiveMeterText } from '../../utils/meter';
import IconLabel from '../Icon/IconLabel';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Props = {
  className?: string;
  selectSingleByDefault?: boolean;
};

const processAutoFillItem = (m: MeterType) => {
  return { value: m, text: formatActiveMeterText(m) };
};

export default function MeterAutoFill(props: Props & CustomAutoFillProps<MeterType>) {
  const {
    id,
    selectedValues,
    placeholder,
    initialValue,
    query,
    queryField,
    excludedIds,
    selectSingleByDefault = false,
    onChange,
    onFocus,
    customProcessAutoFillItem
  } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const mergedQuery = useMemo(() => {
    return { meterTypeGroup: meterTypeGroup.traditional, ...query };
  }, [query]);

  const autoFillTools = useAutoFill({
    endpoint: '/md/meters',
    processRecord: customProcessAutoFillItem || processAutoFillItem,
    initialValue,
    selectedValues,
    query: mergedQuery,
    queryField,
    excludedIds
  });

  const { focusedIndex, shownValues, values } = autoFillTools;

  const modelsResponse = useSuspenseSingleAPI<ModelPagedResultType>({
    request: {
      endpoint: '/cfg/models/'
    }
  });

  useEffect(() => {
    // Select first by default
    if (selectSingleByDefault && values.length === 1 && !selectedValues[0]) {
      onChange([values[0].value]);
    }
  }, [selectSingleByDefault, values]);

  if (!modelsResponse.result) return null;

  const models = modelsResponse.result.data.results;

  const meter: MeterType | undefined =
    typeof focusedIndex !== 'undefined' && shownValues[focusedIndex]
      ? shownValues[focusedIndex].value.value
      : undefined;

  let model: ModelType | undefined;
  let activeState: MeterStatusHistoryType | undefined;

  if (meter) {
    const today = moment();
    activeState = meter.statusHistory.find((status) => {
      return (
        betweenDates(status.startDateTime, status.endDateTime, today) ||
        (moment(status.endDateTime).year() === 10000 && today.isBefore(status.startDateTime)) // install date in future
      );
    });

    model = models.find((m) => m.id === meter.modelId);
  }

  const details = meter && (
    <DropDownDetails>
      <>
        <div>
          <IconLabel labelText={i18n.getTranslation('metering_list.labels.serial')} iconType="meter" />
          <Link id={`meter-${meter.id}`} url={`${rootUrl}/devices/${meter.id}`} newTab>
            {meter.serialNumber}
          </Link>
        </div>

        {model && (
          <div>
            <IconLabel
              labelText={`${i18n.getTranslation('meter.brand')} & ${i18n.getTranslation('meter.model').toLowerCase()}`}
              iconType="meter-info"
            />
            <Paragraph>{`${model.brandName} - ${model.name}`}</Paragraph>
          </div>
        )}

        <div>
          <IconLabel labelText={i18n.getTranslation('metering_list.labels.last_received')} iconType="clock" />
          <Paragraph>
            {meter.lastTimeReceivedData
              ? formatDate(meter.lastTimeReceivedData)
              : i18n.getTranslation('metering_list.never')}
          </Paragraph>
        </div>

        <div>
          <IconLabel labelText={i18n.getTranslation('metering_list.labels.measurementsUntil')} iconType="clock" />
          <Paragraph>
            {meter.measurementsUntil ? formatDate(meter.measurementsUntil) : i18n.getTranslation('metering_list.none')}
          </Paragraph>
        </div>

        {activeState && activeState.meterStatus === 'installed' && (
          <div>
            <Label>{i18n.getTranslation('metering_list.labels.installed_address')}</Label>
            <MeterAddress id={`${id}-address`} activeState={activeState} />
          </div>
        )}
      </>
    </DropDownDetails>
  );

  return (
    <SearchAutoFill
      {...props}
      id={id}
      details={details}
      values={values}
      selectedValues={selectedValues}
      placeholder={placeholder}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
