import 'react-virtualized/styles.css';

import classNames from 'classnames';
import React from 'react';
import { TableRowProps } from 'react-virtualized';

import css from './table-row.module.scss';

export type TableRowPropsType = TableRowProps & {
  cursorPointer: boolean;
  selectedRow?: number;
  setSelectedRow?: React.Dispatch<React.SetStateAction<number>>;
  onRowMouseOver?: React.Dispatch<React.SetStateAction<number>>;
  onRowMouseOut?: React.Dispatch<React.SetStateAction<number>>;
};

// This is a custom row rendered
export default function TableRow(props: TableRowPropsType) {
  const {
    className,
    index,
    columns,
    style,
    selectedRow,
    setSelectedRow,
    onRowMouseOver,
    onRowMouseOut,
    cursorPointer = false
  } = props;

  const handleClick = () => {
    if (setSelectedRow) setSelectedRow(index);
  };

  const handleHover = () => {
    if (onRowMouseOver) onRowMouseOver(index);
  };

  const handleHoverOut = () => {
    if (onRowMouseOut) onRowMouseOut(index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      handleClick();
    }
  };

  return (
    <div
      key={`row-${index}`}
      className={classNames(
        className,
        css['table-row'],
        css['table-row-white'],
        { [css['cursorPointer']]: cursorPointer },
        { [css['hover']]: index === selectedRow }
      )}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverOut}
      tabIndex={0}
      role="row"
      style={style}
    >
      {columns}
    </div>
  );
}
