import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import DeleteAllImportJobDialog from '../../../actions/import-jobs/delete-all-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  onComplete: () => void;
};

export default function DeleteAllImportJobsListPageButton(props: Props) {
  const { onComplete } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="import_jobs.delete_all"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('actions.import_jobs.delete_all_btn')}
      dialogContent={<DeleteAllImportJobDialog onComplete={onComplete} ref={clickRef} />}
      onSubmit={onSubmit}
      dialogType="danger"
      buttonPositive={i18n.getTranslation('general.delete')}
    />
  );
}
