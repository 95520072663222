import React from 'react';

import { IncomingMutationType } from '@zf/api-types/billing/incoming-mutations';
import { NoDataOverlayType } from '@zf/stella-react/src/atoms/Table/table-overlay';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../app-context';
import IconParagraph from '../../components/Icon/IconParagraph';
import { INFO, SUCCESS } from '../../constants/color';
import useCreateRequest from '../../hooks/useCreateRequest';
import useInfiniAPI from '../../hooks/useInfiniAPI';
import { getCustomerBankAccountFromIncomingMutation } from '../../utils/incoming-mutation';
import useSettle from '../payments/useSettle';
import { MatchDialogState } from './ManualMatchDialog';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import CheckBox from '../../components/input/CheckBox';
import css from './ManualMatchDialog.module.scss';

type Props = {
  incomingMutation: IncomingMutationType;
  requestEndpoint: string;
  columns: ColumnType[];
  processRecord: any;
  state: MatchDialogState;
  singleSelect?: boolean;
  quickFilter?: string;
  status?: string;
  outgoingBankingTransactionType?: string;
  dispatch: React.Dispatch<Partial<MatchDialogState>>;
  emptyState?: NoDataOverlayType;
};

export default function MultiSelectMatchTable(props: Props) {
  const {
    quickFilter,
    processRecord,
    columns,
    outgoingBankingTransactionType,
    requestEndpoint,
    singleSelect,
    status,
    incomingMutation,
    state,
    emptyState,
    dispatch
  } = props;
  const { i18n, tenantReducer } = useAppContext();

  const { request, sortBy, sortDirection, handleSort } = useCreateRequest(requestEndpoint, {
    quickFilter,
    outgoingBankingTransactionType,
    iban: getCustomerBankAccountFromIncomingMutation(incomingMutation),
    status,
    refreshTrigger: state.refreshTrigger // For the edge case in the useEffect below
  });

  const { loading, error, rows, sortableFields, updateGivenRows, setStopIndex } = useInfiniAPI<any, any>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  const { handleSelectionChange } = useSettle(
    {
      amount: state.amount,
      remainingAmount: state.remainingAmount,
      selectedIds: state.selectedIds
    },
    dispatch,
    rows,
    updateGivenRows
  );

  const infoColor = Math.abs(state.remainingAmount) !== 0 ? INFO : SUCCESS;

  React.useEffect(() => {
    // when we change the customer id, reset our state & rows
    if (state.selectedIds.length > 0 && !loading) {
      dispatch({
        selectedIds: [],
        remainingAmount: state.amount,
        refreshTrigger: ((Date.now() / 1000) | 0).toString()
      });
    }
  }, [state.customerId, loading]);

  return (
    <>
      <DynamicVirtualTable
        id="matching-table-transactions"
        tooltipId="matching-table-transactions"
        rowHeight={40}
        loading={loading}
        singleSelect={singleSelect}
        error={error}
        rows={rows}
        totalAmountOfRows={rows.length}
        onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
        onSelectRow={handleSelectionChange}
        selectedRows={state.selectedIds}
        NoDataOverlay={emptyState}
        columns={columns}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sortableFields={sortableFields}
        sort={handleSort}
      />
      <div className={css['manual-matching-extra-info']}>
        {state.selectedIds.length === 0 && (
          <CheckBox
            id="2"
            checked={state.isBooked}
            onChange={(e) => {
              dispatch({ isBooked: e, customerId: state.customerId });
            }}
          >
            {`${i18n.getTranslation('incoming_mutations.incoming_matching_bookAmount')} ${formatMoney(
              state.amount,
              i18n.culture
            )}`}
          </CheckBox>
        )}

        <IconParagraph iconType="info-circle" color={infoColor}>
          {i18n.getTranslation('payments.remaining_amount')}: {formatMoney(state.remainingAmount, i18n.culture)} <br />
          {state.remainingAmount !== 0 && `(${i18n.getTranslation('payments.remaining_amount_info')})`}
        </IconParagraph>
      </div>
    </>
  );
}
