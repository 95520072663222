import { observer } from 'mobx-react';
import React from 'react';

import { moveRequestStatus, moveRequestType } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import ProcessDialog from '../../../../actions/tasks/process-dialog';
import ConfirmationActionButton from '../../../../components/Button/ConfirmationActionButton';
import ConfigColumn from '../../../../components/Column/ConfigColumn';
import { INFO, SUCCESS } from '../../../../constants/color';
import { useStore } from '../../../../hooks/useStore';
import { formatCustomerName } from '../../../../utils/customer';
import Activity from './Activity';
import { ActivityCompletedType } from './activity-context/context';
import css from './activity.module.scss';
import ActivityCompleted from './ActivityCompleted';
import useActivities from './hooks/useActivities';

const Activities = () => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { clickRef, onSubmit } = useDialog();
  const {
    task,
    selectedActivity,
    existingCustomer,
    existingLocation,
    existingContract,
    existingPropertyGroupBillingConfig,
    getActivityStatus,
    setSelectedActivity,
    setTask,
    initialTask
  } = useActivities();

  if (!task) return null;

  const actionText = getTranslation('general.process');

  const customerName = existingCustomer
    ? existingCustomer.shortDisplayName
    : formatCustomerName(task.customerDetails.lastName, task.customerDetails.firstName);

  const measurementsStatus = getActivityStatus('confirm_measurements');

  const disableSave = JSON.stringify(task) !== JSON.stringify(initialTask);

  return (
    <ConfigColumn
      title={getTranslation('tasks.activity_timeline')}
      extraRight={
        <ConfirmationActionButton
          id="tasks.process"
          className={css['btn']}
          dialogTitle={getTranslation('tasks.process_title', {
            taskType: getEnumTranslation('moveRequestType', task.moveRequestType),
            customerName: customerName
          })}
          dialogContent={
            <ProcessDialog
              existingPropertyGroupBillingConfig={existingPropertyGroupBillingConfig}
              ref={clickRef}
              task={task}
              setTask={setTask}
            />
          }
          onSubmit={onSubmit}
          disabled={
            task.status === moveRequestStatus.rejected || task.status === moveRequestStatus.processed || disableSave
          }
          buttonPositive={actionText}
        >
          {actionText}
        </ConfirmationActionButton>
      }
    >
      <>
        {task.customerDetails.createdOrLinked && task.customerDetails.internalId && existingCustomer && (
          <ActivityCompleted
            activityType="customer_confirmed"
            color={SUCCESS}
            mutationDate={existingCustomer.createdDateTime}
          />
        )}

        {task.moveRequestType === moveRequestType.movein &&
          task.serviceLocationDetails.internalId &&
          task.serviceLocationDetails.linked &&
          existingLocation && (
            <ActivityCompleted
              activityType="location_confirmed"
              color={SUCCESS}
              mutationDate={existingLocation.createdDateTime}
            />
          )}

        {measurementsStatus === 'complete' && (
          <ActivityCompleted activityType="measurements_confirmed" color={SUCCESS} />
        )}

        {task.contractDetails.internalContractId && existingContract && task.contractDetails.createdOrLinked && (
          <ActivityCompleted
            activityType="contract_confirmed"
            color={SUCCESS}
            mutationDate={existingContract.createdDateTime}
          />
        )}

        <Activity
          isActive={selectedActivity === 'confirm_customer'}
          activity="confirm_customer"
          subject={getTranslation('tasks.confirm_customer')}
          status={getActivityStatus('confirm_customer')}
          onClick={() => setSelectedActivity('confirm_customer')}
        />

        {task.moveRequestType === moveRequestType.movein && (
          <Activity
            isActive={selectedActivity === 'confirm_location'}
            activity="confirm_location"
            subject={getTranslation('tasks.confirm_location')}
            status={getActivityStatus('confirm_location')}
            onClick={() => setSelectedActivity('confirm_location')}
          />
        )}

        <Activity
          isActive={selectedActivity === 'confirm_measurements'}
          activity="confirm_measurements"
          subject={getTranslation('tasks.confirm_measurements')}
          status={measurementsStatus}
          onClick={() => setSelectedActivity('confirm_measurements')}
        />

        <Activity
          isActive={selectedActivity === 'confirm_contract'}
          activity="confirm_contract"
          subject={getTranslation('tasks.confirm_contract')}
          status={getActivityStatus('confirm_contract')}
          onClick={() => setSelectedActivity('confirm_contract')}
        />

        {task.status === moveRequestStatus.processed && (
          <ActivityCompleted activityType={`${task.moveRequestType}_complete` as ActivityCompletedType} color={INFO} />
        )}
      </>
    </ConfigColumn>
  );
};

export default observer(Activities);
