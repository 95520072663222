import classNames from 'classnames';
import React from 'react';

import css from './wizard-section.module.scss';

type Props = {
  id?: string;
  children: React.ReactNode;
  disabled?: boolean;
  overlay?: JSX.Element;
  style?: React.CSSProperties;
  className?: string;

  // Make this mandatory when all wizard are ported
  setActiveStep?: () => void;
};

export default function WizardSection(props: Props) {
  const { children, disabled = false, overlay, style, id, className, setActiveStep } = props;

  return (
    <div id={id} className={classNames(css['wizard-section'], className)} style={style} onClick={setActiveStep}>
      {children}

      {disabled && <div className={css['overlay']}>{overlay}</div>}
    </div>
  );
}
