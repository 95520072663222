import React from 'react';

import {
  OutgoingBankingTransactionRowType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { cancelOutgoingBankingTransaction } from './outgoing-banking-transactions';

type Props = {
  // Detail page
  bankingTransaction?: OutgoingBankingTransactionType;
  setOutgoingBankingTransaction?: React.Dispatch<React.SetStateAction<OutgoingBankingTransactionType | null>>;

  // List page
  selectedRows?: OutgoingBankingTransactionRowType[];
  onComplete?: (updatedRows: OutgoingBankingTransactionType[]) => void;
};

const CancelOutgoingBankingTransactionDialog = React.forwardRef(
  (props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
    const { selectedRows, bankingTransaction, setOutgoingBankingTransaction, onComplete } = props;
    const { i18n, tenantReducer } = useAppContext();

    const isMulti = selectedRows && selectedRows.length > 1;

    React.useImperativeHandle(ref, () => ({
      async onClick() {
        try {
          if (selectedRows && onComplete) {
            const cancelledTransactions = await Promise.all(
              selectedRows.map((r) => cancelOutgoingBankingTransaction(r.__entity, tenantReducer, i18n.lang))
            );

            onComplete(cancelledTransactions);
          } else if (bankingTransaction && setOutgoingBankingTransaction) {
            const cancelledTransaction = await cancelOutgoingBankingTransaction(
              bankingTransaction,
              tenantReducer,
              i18n.lang
            );

            setOutgoingBankingTransaction(cancelledTransaction);
          }

          notify.success({
            content: i18n.getTranslation(`actions.banking_transaction.cancel_success${isMulti ? '_multi' : ''}`)
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation(`actions.banking_transaction.cancel_success${isMulti ? '_multi' : ''}`),
            error: e
          });
        }
      }
    }));

    return (
      <Paragraph>
        {i18n.getTranslation(`actions.banking_transaction.cancel_paragraph${isMulti ? '_multi' : ''}`, {
          amount: selectedRows ? selectedRows.length : 0
        })}
      </Paragraph>
    );
  }
);

export default CancelOutgoingBankingTransactionDialog;
