import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import Icon from '../Icon/Icon';
import css from './tree.module.scss';

type Props = {
  id: string;
  children: React.ReactNode;
  title: string | React.ReactNode;
  className?: string;
  openedStyle?: string;
  clickeableContent?: boolean;
  isOpen?: boolean;
  openOnInit?: boolean;
  arrowType?: 'filled' | 'open';
  icon?: JSX.Element;
  style?: React.CSSProperties;
  color?: string;
  onClick?: () => void;
};

export default function Tree(props: Props) {
  const {
    id,
    children,
    title,
    className,
    openedStyle,
    clickeableContent = true,
    openOnInit = false,
    arrowType = 'open',
    icon,
    color,
    isOpen = false,
    style = {},
    onClick = () => {}
  } = props;

  const [open, setOpen] = React.useState(openOnInit);

  // External control of setState
  React.useEffect(() => {
    if (!openOnInit) setOpen(isOpen);
  }, [isOpen]);

  let hideArrow = false;

  if (Array.isArray(children)) {
    hideArrow = children.length === 0;
  }

  let iconName: string;

  if (open) {
    iconName = arrowType === 'open' ? 'chevron-down' : 'caret-down';
  } else {
    iconName = arrowType === 'open' ? 'chevron-right' : 'caret-right';
  }

  return (
    <div id={id} className={classNames(css['tree-btn'], className)} onClick={onClick} style={style}>
      <ul className={classNames(css['tree'], open ? openedStyle : undefined)}>
        <li
          className={classNames(css['tree-item'], { [css['pointer']]: !hideArrow })}
          onClick={() => (hideArrow ? {} : !isOpen ? setOpen(!open) : {})}
          role="presentation"
          onKeyDown={() => {}}
        >
          {!hideArrow && (
            <BaseButton id={`btn-chevron-${id}`} onClick={() => setOpen(!open)} className={css['tree-title']}>
              {icon || <Icon name={iconName} className={css['tree-title-icon']} />}
              {clickeableContent && title}
            </BaseButton>
          )}
          {!clickeableContent && title}
        </li>
        {open && children}
      </ul>
    </div>
  );
}
