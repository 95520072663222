import React from 'react';

import Button from '@zf/heat-erp/src/components/Button/Button';

import { ButtonProps } from '../../design-system/Components/Button/Button';
import css from './wizard-submit-btn.module.scss';

export default function WizardSubmitButton(props: ButtonProps) {
  return <Button className={css['wizard-submit-btn']} {...props} />;
}
