import clone from 'clone';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import {
  advanceFrequency,
  contractBillingMethod,
  invoiceFrequency,
  moveRequestType,
  unitOfMeasure
} from '@zf/api-types/enums';
import { GetServiceLocationMatchingConsumerGroupsType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption } from '@zf/api-types/master-data/contract';
import { ProductType, RequiredEstimationsType } from '@zf/api-types/product';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import ContractsDropdown from '../../../../../components/Dropdown/contract-dropdown/wizard/ContractsDropdown';
import ErrorView from '../../../../../components/Error/error-view';
import { useStore } from '../../../../../hooks/useStore';
import { getMoveInProduct } from '../../../../../utils/contract';
import useActivities from '../hooks/useActivities';
import BillingForm, { BillingDataType } from './contract-wiz-forms/BillingForm';
import ContractDataForm, { ContractDataType } from './contract-wiz-forms/ContractDataForm';
import css from './contract-wiz.module.scss';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { notify } from 'events/notification-events';

const ContractWiz = () => {
  const { applicationStore, contractStore, organisationStore } = useStore();
  const { getTranslation, tenantReducer } = applicationStore;
  const { getPreviousAdvanceAmount } = contractStore.contractApiService;
  const { getOrganisationConfig } = organisationStore.organisationService;

  const {
    task,
    existingCustomer,
    existingLocation,
    existingPropertyGroupBillingConfig,
    hasCustomProductConfig,
    setHasCustomProductConfig,
    setContractData,
    getSelectedContract,
    setTask
  } = useActivities();

  const [organizationFeatureFlags, setOrganizationFeatureFlags] = useState<OrganizationConfigType>();

  useEffect(() => {
    getOrganisationConfig(tenantReducer.organization?.organizationId || '', tenantReducer.tenant?.id || '')
      .then((res) => {
        setOrganizationFeatureFlags(res);
      })
      .catch((error) => {
        notify.error({
          content: getTranslation('general.get_cfg_fail'),
          error
        });
      });
  }, []);

  useEffect(() => {
    // When a location is selected and saved and this location has an active contract, fill in the advance amount based on this contract's billingrelation
    if (existingLocation && task && !task.contractDetails.advanceAmount) {
      getPreviousAdvanceAmount(existingLocation).then((amount) => {
        const taskClone = clone(task);
        taskClone.contractDetails.advanceAmount = amount;
        if (existingLocation.product) {
          taskClone.contractDetails.productId = existingLocation.product.productId;
        }
        setTask(taskClone, true);
      });
    }
  }, [existingLocation]);

  if (!task) return null;

  const handleProductChange = (value: ProductType[]) => setContractData({ productId: value[0]?.id || '' });

  const handleEstimatedConsumptionChange = (index: number, value: Partial<ContractedServiceEstimatedConsumption>) => {
    const cloned = clone(task.contractDetails.estimations);
    cloned[index] = { ...cloned[index], ...value };
    setContractData({ estimations: cloned });
  };

  const handleExternalContractReferenceChange = (id: string) => setContractData({ externalContractId: id });

  const initEstimatedConsumptions = async (
    matchingGroups: GetServiceLocationMatchingConsumerGroupsType[][],
    requiredEstimations: RequiredEstimationsType[]
  ) => {
    const initialValues = requiredEstimations.map((re, index) => {
      const initialGroup = matchingGroups[index]?.[0]?.consumerGroup;
      return {
        consumerGroupId: initialGroup?.id || '',
        value: initialGroup?.estimatedAnnualVolume,
        unitOfMeasure: initialGroup?.unitOfMeasure || unitOfMeasure.none,
        meteringType: re.meteringType,
        utilityType: initialGroup?.utilityType,
        isManualEntry: false
      };
    });

    const taskClone = clone(task);
    taskClone.contractDetails.estimations = initialValues;
    setTask(taskClone, true);
  };

  const customerId = existingCustomer?.id || task.customerDetails?.internalId || '';
  const selectedContract = getSelectedContract(task.contractDetails);

  const contractDataValues: ContractDataType = {
    productId: task.contractDetails.productId || getMoveInProduct(existingPropertyGroupBillingConfig, existingLocation),
    externalContractReference: task.contractDetails.externalContractId,
    propertyBillingConfig: existingPropertyGroupBillingConfig,
    invoiceFrequency: task.contractDetails.invoiceFrequency
      ? task.contractDetails.invoiceFrequency
      : existingPropertyGroupBillingConfig && existingPropertyGroupBillingConfig.invoiceFrequency
      ? existingPropertyGroupBillingConfig.invoiceFrequency
      : invoiceFrequency.yearly,
    invoiceFrequency_control: null,
    usePropertyGroupProduct: existingPropertyGroupBillingConfig
      ? existingPropertyGroupBillingConfig.costAllocationEnabled
      : false,
    hasCustomProductConfig,
    estimatedConsumptions: task.contractDetails.estimations,
    selectedLocations: existingLocation ? [existingLocation] : [],
    contractNumber: task.contractDetails.contractNumber
  };

  const contractBillingValues: BillingDataType = {
    billingMethod: contractBillingMethod.credit,
    hasCustomProductConfig,
    usePropertyGroupProduct: existingPropertyGroupBillingConfig
      ? existingPropertyGroupBillingConfig.costAllocationEnabled
      : false,
    invoiceFrequency: task.contractDetails.invoiceFrequency
      ? task.contractDetails.invoiceFrequency
      : existingPropertyGroupBillingConfig && existingPropertyGroupBillingConfig.invoiceFrequency
      ? existingPropertyGroupBillingConfig.invoiceFrequency
      : invoiceFrequency.yearly,
    advanceFrequency: task.contractDetails.advanceFrequency
      ? task.contractDetails.advanceFrequency
      : existingPropertyGroupBillingConfig && existingPropertyGroupBillingConfig.advanceFrequency
      ? existingPropertyGroupBillingConfig.advanceFrequency
      : advanceFrequency.monthly,
    paymentTermsId: task.contractDetails.paymentTermsId || existingPropertyGroupBillingConfig?.paymentTermsId || '',
    propertyBillingConfig: existingPropertyGroupBillingConfig,
    advanceAmount: task.contractDetails.advanceAmount
  };

  return (
    <div>
      {task.contractDetails.errors.length > 0 ? <ErrorView errors={task.contractDetails.errors} /> : null}

      {task.moveRequestType === moveRequestType.movein ? (
        <>
          <ContractDataForm
            organizationConfig={organizationFeatureFlags}
            values={contractDataValues}
            utilityTypes={existingLocation?.services.map((s) => s.utilityType) || []}
            setContractData={setContractData}
            handleProductChange={handleProductChange}
            handleCustomProdConfigChange={setHasCustomProductConfig}
            handleEstimatedConsumptionChange={handleEstimatedConsumptionChange}
            handleExternalContractReferenceChange={handleExternalContractReferenceChange}
            initEstimatedConsumptions={initEstimatedConsumptions}
          />
          <BillingForm values={contractBillingValues} setBillingData={setContractData} />
        </>
      ) : (
        <>
          <Heading headingType="h3" style="bold">
            {getTranslation('tasks.select_move_out_contract')}
          </Heading>
          <div className={css['contracts-dropdown']}>
            <ContractsDropdown
              id="contract-to-move-out"
              customerId={customerId}
              onChange={(value) => setContractData({ internalContractId: value[0]?.id || '' })}
              values={[]}
              placeholder={getTranslation('invoice.wizard.select_contract')}
              selectedValues={[selectedContract]}
              error={!task.contractDetails.internalContractId && !task.contractDetails.externalContractId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default observer(ContractWiz);
