import moment, { Moment } from 'moment';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { incomingMutationStatus, incomingMutationType as incomingMutationType_ } from '@zf/api-types/enums';

import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

export type IncomingMutationQuickFilterType =
  | 'all'
  | 'bankConfirmations'
  | 'bankTransfers'
  | 'resolveIssues'
  | 'reversals';

export type IncomingMutationQueryParamsType = {
  quickFilter: IncomingMutationQuickFilterType;
  incomingMutationType: incomingMutationType_[];
  status: incomingMutationStatus[];
  transactionDateStart: Moment;
  transactionDateEnd: Moment;
  flexSearch: string;
};

export type IncomingMutationTypeOverviewCountType = {
  all: number;
  bankConfirmations: number;
  bankTransfers: number;
  reversals: number;
  resolveIssues: number;
};

export type IncomingBankingTransactionCtxState = {
  queryParams: IncomingMutationQueryParamsType;
  overviewCounts: IncomingMutationTypeOverviewCountType;
};

type Action =
  | { type: 'UPDATE'; newState: Partial<IncomingBankingTransactionCtxState> }
  | { type: 'UPDATE_QUERY_PARAMS'; newParams: Partial<IncomingMutationQueryParamsType> }
  | { type: 'RESET_QUERY_PARAMS' }
  | { type: 'SET_OVERVIEW_COUNTS'; newCounts: IncomingMutationTypeOverviewCountType };

const initialState: IncomingBankingTransactionCtxState = {
  queryParams: {
    quickFilter: 'all',
    incomingMutationType: [],
    status: [],
    transactionDateStart: moment(MIN_DATE),
    transactionDateEnd: moment(MAX_DATE),
    flexSearch: ''
  },
  overviewCounts: {
    all: 0,
    bankConfirmations: 0,
    bankTransfers: 0,
    resolveIssues: 0,
    reversals: 0
  }
};

const reducer = (state: IncomingBankingTransactionCtxState, action: Action): IncomingBankingTransactionCtxState => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.newState };

    case 'UPDATE_QUERY_PARAMS':
      return { ...state, queryParams: { ...state.queryParams, ...action.newParams } };

    case 'RESET_QUERY_PARAMS':
      return {
        ...state,
        queryParams: {
          quickFilter: state.queryParams.quickFilter, // Skip this as we are only resetting the filters right of the header
          incomingMutationType: [],
          status: [],
          transactionDateStart: moment(MIN_DATE),
          transactionDateEnd: moment(MAX_DATE),
          flexSearch: ''
        }
      };

    case 'SET_OVERVIEW_COUNTS':
      return { ...state, overviewCounts: action.newCounts };

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider: IncomingBankingTransactionProvider,
  useTracked,
  useUpdate: useDispatch
} = createContainer(useValue);
