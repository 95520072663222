import React from 'react';

import { OutgoingMutationRowType } from '@zf/api-types/billing/outgoing-mutations';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteOutgoingMutation } from './outgoing-mutations';

type Props = {
  selectedRows: OutgoingMutationRowType[];
  onComplete: () => void;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const DeleteDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, onComplete, setSelectedIds } = props;
  const { i18n, tenantReducer } = useAppContext();

  const isMulti = selectedRows.length > 1;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await Promise.all(
          selectedRows.map((r) => {
            return deleteOutgoingMutation(r.__entity.id, tenantReducer, i18n.lang);
          })
        );

        setSelectedIds([]);
        onComplete();

        notify.success({
          content: i18n.getTranslation(`actions.outgoing_mutation.remove_success${isMulti ? '_multi' : ''}`)
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`actions.outgoing_mutation.remove_fail${isMulti ? '_multi' : ''}`),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation(`actions.outgoing_mutation.remove_paragraph${isMulti ? '_multi' : ''}`, {
        amount: selectedRows.length
      })}
    </Paragraph>
  );
});

export default DeleteDialog;
