import React from 'react';

import { ValidatorCtxAction, ValidatorCtxState } from './useCreateContext';

export default function useContextValidator<T>(
  useTracked: () => [ValidatorCtxState<T>, React.Dispatch<ValidatorCtxAction<T>>]
) {
  const [state, dispatch] = useTracked();

  const setValue = React.useCallback(
    function <V>(value: Partial<V>) {
      if (state.isLocked) return;
      dispatch({ type: 'SET_VALUE', value: value });
    },
    [state.isLocked]
  );

  const restoreValues = React.useCallback(() => {
    dispatch({ type: 'RESTORE' });
  }, []);

  const setSearchValue = React.useCallback((searchValue: string) => {
    dispatch({ type: 'SET_SEARCH', searchValue: searchValue });
  }, []);

  const setSelectedIndex = React.useCallback((index: number) => {
    dispatch({ type: 'SET_SELECTED_INDEX', index: index });
  }, []);

  return {
    values: state.values,
    backup: state.backup,
    isLocked: state.isLocked,
    isDirty: state.isDirty,
    selectedIndex: state.selectedIndex,
    scrollToIndex: state.scrollToIndex,
    searchValue: state.searchValue,
    setValue,
    restoreValues,
    setSearchValue,
    setSelectedIndex
  };
}
