import clone from 'clone';
import React from 'react';

import {
  dataFrequency,
  direction,
  incrementationType,
  meteringType,
  unitOfMeasure,
  utilityType
} from '@zf/api-types/enums';
import { ChannelTemplateType, UpdateModelType } from '@zf/api-types/model';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context/app-context';
import Button from '../../../components/Button/Button';
import { ConfigHelp } from '../../../components/CoachMarks';
import { DeleteIcon, Icon } from '../../../components/Icon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import {
  InlineDataFreqDropdown,
  InlineDirectionDropdown,
  InlineIncrTypeDropdown,
  InlineMeteringTypeDropdown,
  InlineUoMDropdown,
  InlineUtilityTypeDropdown
} from '../../../components/Lang/SimpleDropdown';

type Props = {
  values: UpdateModelType[];
  selectedIndex: number;
  setValue: <V>(value: Partial<V>) => void;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function ChannelTemplates(props: Props) {
  const { values, selectedIndex, setValue } = props;
  const { i18n, enumReducer } = useAppContext();

  const [helpContent] = React.useState([
    i18n.getTranslation('coachmark.channel_templates.paragraph'),
    i18n.getTranslation('coachmark.channel_templates.frequency'),
    i18n.getTranslation('coachmark.channel_templates.incrementation'),
    i18n.getTranslation('coachmark.channel_templates.metering_method'),
    i18n.getTranslation('coachmark.channel_templates.utility_type'),
    i18n.getTranslation('coachmark.channel_templates.direction'),
    i18n.getTranslation('coachmark.channel_templates.uom'),
    i18n.getTranslation('coachmark.channel_templates.tou')
  ]);

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 2,
      label: i18n.getTranslation('general.description'),
      dataKey: 'description'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.frequency'),
      dataKey: 'frequency'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.incrementation'),
      dataKey: 'incrementation'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('meter.metering_method'),
      dataKey: 'meteringMethod'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.utility_type'),
      dataKey: 'utilityType'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.direction'),
      dataKey: 'direction'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.unit_of_measure'),
      dataKey: 'uom'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('meter.time_of_use'),
      dataKey: 'tou'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const [selectedChannelTemplate, setSelectedChannelTemplate] = React.useState(-1);
  const [scrollToIndex, setScrollToIndex] = React.useState(-1);

  const addChannelTemplate = () => {
    const clonedArray = clone(values);
    clonedArray[selectedIndex].channelTemplates.push({
      description: '',
      dataFrequency: '' as dataFrequency,
      incrementationType: '' as incrementationType,
      meteringType: '' as meteringType,
      direction: '' as direction,
      unitOfMeasure: '' as unitOfMeasure,
      timeOfUse: '',
      utilityType: '' as utilityType,
      externalIdentifier: ''
    });
    setValue({
      values: clonedArray
    });
    setScrollToIndex(clonedArray.length - 1);
  };

  const setChannelTemplate = (
    index: number,
    value: ChannelTemplateType[keyof ChannelTemplateType],
    dataKey: keyof ChannelTemplateType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[selectedIndex].channelTemplates[index][dataKey] = value;
    setValue({
      values: clonedArray as UpdateModelType[]
    });
  };

  const deleteChannelTemplate = (index: number) => {
    const clonedArray = clone(values);
    clonedArray[selectedIndex].channelTemplates.splice(index, 1);
    if (selectedChannelTemplate === index) setSelectedChannelTemplate(-1);
    setValue({
      values: clonedArray
    });
  };

  const channelRows =
    values[selectedIndex] && values[selectedIndex].channelTemplates
      ? values[selectedIndex].channelTemplates.map((channelTemplate, index) => {
          return {
            description: (
              <InlineInputFieldInput
                id={`model.chann_description.index-${index}`}
                onChange={(val) => setChannelTemplate(index, val, 'description')}
                value={channelTemplate.description}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!channelTemplate.dataFrequency}
              />
            ),
            frequency: (
              <InlineDataFreqDropdown
                id={`model.frequency.index-${index}`}
                onChange={(val) => setChannelTemplate(index, val[0], 'dataFrequency')}
                values={enumReducer.getEnum('dataFrequency')}
                selectedValues={[channelTemplate.dataFrequency]}
              />
            ),
            incrementation: (
              <InlineIncrTypeDropdown
                id={`model.incrementation.index-${index}`}
                onChange={(val) => setChannelTemplate(index, val[0], 'incrementationType')}
                values={enumReducer.getEnum<incrementationType>('incrementationType').filter((type) => {
                  return type.value !== incrementationType.delta;
                })}
                selectedValues={[channelTemplate.incrementationType]}
              />
            ),
            meteringMethod: (
              <InlineMeteringTypeDropdown
                id={`model.metering_method.index-${index}`}
                onChange={(val) => setChannelTemplate(index, val[0], 'meteringType')}
                values={enumReducer.getEnum<meteringType>('meteringType')}
                selectedValues={[channelTemplate.meteringType]}
              />
            ),
            utilityType: (
              <InlineUtilityTypeDropdown
                id={`model.utility_type.index-${index}`}
                onChange={(val) => setChannelTemplate(index, val[0], 'utilityType')}
                values={enumReducer.getEnum<utilityType>('utilityType')}
                selectedValues={[channelTemplate.utilityType]}
              />
            ),
            direction: (
              <InlineDirectionDropdown
                id={`model.direction.index-${index}`}
                onChange={(val) => setChannelTemplate(index, val[0], 'direction')}
                values={enumReducer.getEnum<direction>('direction')}
                selectedValues={[channelTemplate.direction]}
              />
            ),
            uom: (
              <InlineUoMDropdown
                id={`model.uom.index-${index}`}
                onChange={(val) => setChannelTemplate(index, val[0], 'unitOfMeasure')}
                values={enumReducer.getEnum<unitOfMeasure>('unitOfMeasure', false)}
                selectedValues={[channelTemplate.unitOfMeasure]}
              />
            ),
            tou: (
              <InlineInputFieldInput
                id={`model.tou.index-${index}`}
                value={channelTemplate.timeOfUse}
                onChange={(val) => setChannelTemplate(index, val, 'timeOfUse')}
              />
            ),
            deleteAction: (
              <DeleteIcon
                id={`model.delete.index-${index}`}
                tooltipFor="channels-table"
                onClick={() => deleteChannelTemplate(index)}
              />
            )
          };
        })
      : [];

  const modelSelected: UpdateModelType | undefined = values[selectedIndex];

  return (
    <Card width="3" id="channels-card">
      <CardHeader
        extraLeft={
          <ConfigHelp title={i18n.getTranslation('coachmark.channel_templates.title')} content={helpContent} />
        }
        extraRight={
          selectedIndex !== -1 && (
            <Button id="model.update" type="text" icon="plus" onClick={addChannelTemplate}>
              {i18n.getTranslation('general.add')}
            </Button>
          )
        }
      >
        {!modelSelected
          ? i18n.getTranslation('meter_config.channel_templates')
          : i18n.getTranslation('meter_config.channel_templates_selected', {
              brandName: modelSelected.brandName,
              name: modelSelected.name
            })}
      </CardHeader>
      {modelSelected ? (
        <CardBody type="indexTable" fixedHeight>
          <DynamicIndexTable
            tooltipId="channels-table"
            rows={channelRows}
            columns={tableColumns}
            selectedRow={selectedChannelTemplate}
            scrollToIndex={scrollToIndex}
            setSelectedRow={setSelectedChannelTemplate}
          />
        </CardBody>
      ) : (
        <CardEmptyBody
          icon={<Icon type="channels" />}
          title={i18n.getTranslation('meter_config.no_channel_templates')}
        />
      )}
    </Card>
  );
}
