import moment from 'moment';
import React from 'react';

import { contractStatus, serviceStatus } from '@zf/api-types/enums';
import { CustomerContractType, CustomerType } from '@zf/api-types/master-data/customer';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../app-context/app-context';
import useDetail from '../../../app-context/hooks/use-detail';
import { ICON_COLORS } from '../../../constants/icons';

const useCustomerDetail = (entityId: string) => {
  const { entity: initialCustomer, navigator } = useDetail<CustomerType>({
    entityId,
    endpoint: '/md/customers',
    domain: 'customer'
  });

  const { enumReducer } = useAppContext();

  const [customer, setCustomer] = React.useState(initialCustomer);

  React.useEffect(() => {
    if (initialCustomer) setCustomer(initialCustomer);
  }, [initialCustomer]);

  let supplied = '';

  if (customer) {
    const isSupplied = customer.contracts.some((contract: CustomerContractType) => {
      return (
        moment().isAfter(contract.supplyStartDateTime) &&
        moment().isBefore(contract.supplyEndDateTime) &&
        contract.currentContractStatus === contractStatus.signed
      );
    });

    supplied = isSupplied ? serviceStatus.supplied : serviceStatus.unsupplied;
  }

  const status = (
    <NewStatusBadge
      color={supplied ? ICON_COLORS[supplied] : ''}
      status_={` ${enumReducer.getTranslation('serviceStatus', supplied)} `}
    />
  );

  return { customer, status, navigator, setCustomer };
};

export default useCustomerDetail;
