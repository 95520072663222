import clone from 'clone';

import { CreateSegmentType, StringTupleType, UpdateNumberSequenceType } from '@zf/api-types/settings-config';

/**
 * !These tranformations of lookup values to array and vice versa cannot be removed
 * as the lookup values will not keep their order when using objects in CRUD operations!
 **/

export const processNumberSequence = (numberSequence: UpdateNumberSequenceType) => {
  const clonedSequence: UpdateNumberSequenceType = clone(numberSequence);

  if (numberSequence.segments) {
    const segments: CreateSegmentType[] = [];
    for (const segment of numberSequence.segments) {
      const cloneSegment: CreateSegmentType = clone(segment);
      if (segment.segmentLookupValues) {
        const segmentValues: (string | StringTupleType)[] = [];
        for (const key in segment.segmentLookupValues) {
          if (Object.prototype.hasOwnProperty.call(segment.segmentLookupValues, key)) {
            segmentValues.push([key, segment.segmentLookupValues[key] as any]);
          }
        }
        cloneSegment.segmentLookupValues = segmentValues;
      }
      segments.push(cloneSegment);
    }

    clonedSequence.segments = segments;
  } else {
    clonedSequence.segments = [];
  }

  return clonedSequence;
};

export const processNumberSequences = (numSequences: UpdateNumberSequenceType[]) => {
  const numberSequences: UpdateNumberSequenceType[] = [];

  for (const index of numSequences) {
    numberSequences.push(processNumberSequence(index));
  }

  return numberSequences;
};

export const segmentsToApiFriendlySegments = (numberSeq: UpdateNumberSequenceType) => {
  return numberSeq.segments
    ? numberSeq.segments.map((segment) => {
        const segmentLookupValues = {} as any;
        if (segment.segmentLookupValues) {
          for (const segmentLookupValue of segment.segmentLookupValues) {
            segmentLookupValues[segmentLookupValue[0]] = segmentLookupValue[1];
          }
        }

        return {
          segmentType: segment.segmentType,
          segmentLookupValues: segmentLookupValues
        };
      })
    : [];
};

export const createApiFriendlyValues = (numberSeq: UpdateNumberSequenceType) => {
  return {
    formatString: numberSeq.formatString,
    lastValue: numberSeq.lastValue,
    referenceEntity: numberSeq.referenceEntity,
    segments: segmentsToApiFriendlySegments(numberSeq)
  };
};
