import React from 'react';

import {
  AssignInvoicesToOutgoingBankingTransactionRequestType,
  OutgoingBankingTransactionRequestPreviewInsightsType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../app-context';
import css from './css/assign-insights-preview.module.scss';

type Props = {
  insights: OutgoingBankingTransactionRequestPreviewInsightsType;
  requestDataBody: AssignInvoicesToOutgoingBankingTransactionRequestType;
};

export default function AssignInsightsPreview(props: Props) {
  const { insights, requestDataBody } = props;
  const { i18n } = useAppContext();

  const renderInsights = () => {
    const requestInsights = insights.requests.numberOfInvoices > 0 && (
      <div>
        <Paragraph bold>
          {i18n.getTranslation('actions.invoice.request_insights', { amount: insights.requests.numberOfInvoices })}
        </Paragraph>
        <Paragraph>{formatMoney(insights.requests.totalAmount, i18n.culture)}</Paragraph>
      </div>
    );

    const refundInsights = insights.refunds.numberOfInvoices > 0 && (
      <div>
        <Paragraph bold>
          {i18n.getTranslation('actions.invoice.refund_insights', { amount: insights.refunds.numberOfInvoices })}
        </Paragraph>
        <Paragraph>{formatMoney(insights.refunds.totalAmount, i18n.culture)}</Paragraph>
      </div>
    );

    if (requestDataBody.outgoingBankingTransactionType) {
      if (requestDataBody.outgoingBankingTransactionType === outgoingBankingTransactionType.paymentrequests) {
        return requestInsights;
      } else {
        return refundInsights;
      }
    }

    return (
      <>
        {requestInsights}
        {refundInsights}
      </>
    );
  };

  return <div className={css['insights-preview']}>{renderInsights()}</div>;
}
