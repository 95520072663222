import React from 'react';

import { BillingItemType } from '@zf/api-types/product';
import { CalculationConfigurationBillingItemType } from '@zf/api-types/property-group-billing-configuration';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useStore } from '../../hooks/useStore';
import css from './allocation-list-items.module.scss';

type Props = { allocationBillingItems: CalculationConfigurationBillingItemType[]; billingItems: BillingItemType[] };

export default function AllocationListItems(props: Props) {
  const { allocationBillingItems, billingItems } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div className={css['allocation-list-items']}>
      <Heading headingType="h3">{getTranslation('product_config.selected_items')}</Heading>
      {allocationBillingItems.map((abi) => {
        const matchedItem = billingItems.find((b) => b.id === abi.billingItemId);

        return matchedItem ? (
          <Paragraph key={`${matchedItem.id}-${matchedItem.name}`}>{matchedItem.name}</Paragraph>
        ) : null;
      })}
    </div>
  );
}
