import { observer } from 'mobx-react';
import React from 'react';

import { culture } from '@zf/api-types/enums';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import ResetIcon from '../../../../components/Icon/ResetIcon';
import { InlineInputFieldInput } from '../../../../components/input/InputField';
import DynamicIndexTable from '../../../../components/Lang/DynamicIndexTable';
import { useStore } from '../../../../hooks/useStore';
import { EnumTranslationRowType } from '../../stores/TranslationStore';

const EnumTranslationCardBody = () => {
  const { communicationStore } = useStore();
  const {
    enumTranslations,
    tableColumns,
    enumTranslationsAreLoading,
    selectedRow,
    setSelectedRow,
    resetEnumTranslation
  } = communicationStore.translationStore;

  const rows =
    enumTranslations?.map((translation, index) => {
      let row: EnumTranslationRowType = {
        translation: (
          <Paragraph>
            {translation.enumType} ({translation.symbol})
          </Paragraph>
        )
      };

      Object.keys(translation.translations).forEach((key) => {
        const castedKey = key as culture;

        //@ts-ignore
        const value = translation.form._get(castedKey);
        //@ts-ignore
        const setValue = (val: string) => translation.form._set(castedKey, val);

        row[castedKey] = (
          <InlineInputFieldInput
            error={!value}
            id={`model.model.index-${castedKey}-${index}`}
            value={value}
            onChange={setValue}
          />
        );
      });

      row.reset = (
        <ResetIcon
          id={`model.delete.index-${index}`}
          tooltipFor="models-table"
          onClick={async () => await resetEnumTranslation(translation, translation.form, index)}
        />
      );

      return row;
    }) || [];

  return (
    <DynamicIndexTable
      tooltipId="models-table"
      rows={rows}
      columns={tableColumns}
      isLoading={enumTranslationsAreLoading}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
    />
  );
};

export default observer(EnumTranslationCardBody);
