import React from 'react';

import { ErrorDataType, ZFErrorType } from '@zf/api-types/general';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { NotificationError } from '../design-system/Components';
import {
  NotificationContent,
  NotificationObjectType,
  NotificationTypeType,
  NotifierType
} from '../design-system/Components/Toast/types/notifications';
import EventEmitter from './event-emitter';

export const ACTION = {
  SHOW: 'notify.show',
  REMOVE: 'notify.remove'
};

const eventEmitter = new EventEmitter<NotificationObjectType>();

export function on(key: string, callback: (value: NotificationObjectType) => void): void {
  return eventEmitter.on(key, callback);
}

export function removeListener(key: string, callback: (value: NotificationObjectType) => void) {
  return eventEmitter.removeListener(key, callback);
}

export async function emitNotification(key: string, value: NotificationObjectType) {
  eventEmitter.emit(key, value);
}

export const generateNotificationId = () => Date.now().toString();

const notifyFactory = (type: NotificationTypeType) => {
  return (content: NotificationContent) => {
    const id = generateNotificationId();

    if (content.error) {
      const error = content.error as ZFErrorType;
      const errorData = error.data as ErrorDataType;

      content.content = (
        <>
          <Paragraph>{content.content}</Paragraph>
          {errorData && errorData.errors ? <NotificationError errors={errorData.errors} /> : error.message}
        </>
      );
    }

    emitNotification(ACTION.SHOW, {
      content,
      options: {
        id: generateNotificationId(),
        type,
        timeout: content.isSticky || type === 'error' ? 0 : 5000
      }
    });

    return id;
  };
};

export const notify: NotifierType = {
  info: notifyFactory('info'),
  success: notifyFactory('success'),
  warning: notifyFactory('warning'),
  error: notifyFactory('error')
};
