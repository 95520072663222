import React from 'react';
import StellaLoader from '@zf/stella-react/src/atoms/Loader/StellaLoader';
import { useAppContext } from '../../app-context/app-context';

type Props = {
  text?: string;
  className?: string;
};

export default function Loader(props: Props) {
  const { text, className } = props;
  const { i18n } = useAppContext();

  return <StellaLoader className={className} text={text ? text : i18n.getTranslation('general.loading_items')} />;
}
