import React from 'react';
import classNames from 'classnames';

import css from './list-divider.module.scss';

export type PropsType = {
  className?: string;
  noMargin?: boolean;
};

export default function ListDivider(props: PropsType) {
  const { className, noMargin = false } = props;

  return <li className={classNames(css['list-divider'], { [css['no-margin']]: noMargin }, className)} />;
}
