const sharedKeys = [
  'createdDateTime',
  'id',
  '_etag',
  'ttl',
  '_cid',
  'discriminator',
  'canonicalSearch',
  'requireAttention',
  'hasErrors',
  'hasWarnings'
];

type BoundingRecType = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

/**
 * Searches an array of objects for the given text, this text can be inside any attribute
 * @param string searchText
 * @param array arrayOfObjects
 * @returns index of the first matching entry
 */
export function searchInObjectsArray<T extends Record<string, any>>(searchText: string, arrayOfObjects: T[]) {
  return arrayOfObjects.findIndex((object) => {
    return Object.keys(object).some((key) => {
      // Searching in hidden attributes may cause bad UX
      if (!sharedKeys.includes(key)) {
        const stringified = JSON.stringify(object[key]);
        return stringified.toLowerCase().includes(searchText.toLowerCase());
      }
      return false;
    });
  });
}

/**
 * Gets the relative position of a DOM element to its parent
 * @param parent
 * @param child
 * @returns relative offsets in px
 */
export const getRelativePos = (parent: HTMLElement, child: HTMLElement) => {
  var parentPos = parent.getBoundingClientRect(),
    childPos = child.getBoundingClientRect(),
    relativePos: BoundingRecType = {
      top: childPos.top - parentPos.top,
      right: childPos.right - parentPos.right,
      bottom: childPos.bottom - parentPos.bottom,
      left: childPos.left - parentPos.left
    };

  return relativePos;
};

/**
 * Checks if a variable is an object
 * @param object
 * @returns boolean
 */
export function isObject<T>(object: T) {
  return object !== null && typeof object === 'object';
}

// This function solves typing errors for Object.keys of null
const getKeys = <T extends Record<string, any> | null>(obj: T) => {
  if (obj === null) return [];

  return Object.keys(obj);
};

/**
 * Checks equality of 2 objects, not the object reference but the actual contents on all levels
 * @param object object1
 * @param object object2
 * @returns boolean
 */
export function deepEqual<T extends Record<string, any> | null>(object1: T, object2: T) {
  const keys1 = getKeys(object1);
  const keys2 = getKeys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  if (object1 !== null && object2 !== null) {
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);

      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * Checks if an object has any attributes
 * @param object obj
 * @returns boolean
 */
export function isEmptyObject<T extends object>(obj: T | null | undefined) {
  return obj === null || !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
}
