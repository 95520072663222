import React from 'react';

import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../app-context';
import { SuspenseCardBody } from '../../../../components/suspense';
import { addMarkerToMap, findCoordsForAddress, initMap } from './map-acces';
import css from './map-style.module.scss';

type Props = {
  locationData: ServiceLocationType;
};

export default function MapLocationCard(props: Props) {
  const { locationData } = props;
  const { i18n } = useAppContext();

  React.useEffect(() => {
    findCoordsForAddress(locationData.address).then((coordinates) => {
      const map = initMap('map', coordinates);
      addMarkerToMap(map, coordinates, locationData);
    });
  }, []);

  return (
    <Card id="map-card">
      <CardHeader>{i18n.getTranslation('cards.serviced_location.map')}</CardHeader>
      <SuspenseCardBody className={css['map-card-body']}>
        <div id="map" className={css['map']}></div>
      </SuspenseCardBody>
    </Card>
  );
}
