import clone from 'clone';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle } from 'react';

import { billingItemTariffCalculationType, tariffType, tierCalculationMethod } from '@zf/api-types/enums';
import { tariffCalculationType } from '@zf/api-types/local-enums';
import {
  BillingItemType,
  BillingTariffRequestType,
  BillingTariffType,
  ProductType,
  StairStepTariffCalculationTypeParametersType,
  TariffCalculationTypeParametersType,
  TariffConditionTypeParametersDTO,
  TieredTariffCalculationTypeParametersType,
  UnitPriceTariffCalculationTypeParametersType
} from '@zf/api-types/product';
import useSimpleValidator from '@zf/hooks/src/useSimpleValidator';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { betweenDates, isMaxDate, isMinDate, MAX_DATE, MIN_DATE, sortByDate, startOfDay } from '@zf/utils/src/date';

import { TabSlider } from '../../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../../design-system/Components/TabSlider/Tab';
import { SettingDescription } from '../../../../../design-system/ComponentSets';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import TreeNode from '../models/TreeNode';
import useRenderTreeActions from '../tree/hooks/renderTreeActions';
import css from './add-edit-tariff-dialog.module.scss';
import EditTariffPeriod from './components/EditTariffPeriod';
import TariffCalculation from './TariffCalculation';
import TariffCondition from './TariffCondition';

type Props = {
  tariffs: BillingTariffType[];
  selectedBillingItem: BillingItemType | undefined;
  validationRef: MutableRefObject<ValidationRef | undefined>;
  isProductPage: boolean;
  addTariff: (newTariff: BillingTariffRequestType) => Promise<BillingTariffType>;
  addTariffToList: (newTariff: BillingTariffType) => void;
  updateTariff: (id: string, tariffToUpdate: BillingTariffRequestType) => Promise<BillingTariffType>;
  updateTariffInList: (updatedTariff: BillingTariffType) => void;
  initTariffs?: (billingItemId: string, selectedProductId?: string) => Promise<void>;
  selectedProduct?: ProductType;
  tariff?: BillingTariffType;
  treeNode?: TreeNode;
  editOnlyTariff?: boolean;
  parentNode?: TreeNode;
  index?: number;
  canAddInstantly: boolean;
};

export type TariffValidatorType = {
  productId: string;
  id?: string;
  billingItemId: string;
  tariffType: tariffType;
  calculationParameters: TariffCalculationTypeParametersType;
  conditionParameters: TariffConditionTypeParametersDTO[];
  startDateTime: string | null;
  endDateTime: string | null;
  tariffCalculationType: tariffCalculationType;
  tierCalculationMethod: tierCalculationMethod;
};

const AddEditTariffDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const {
    index,
    tariff,
    validationRef,
    tariffs,
    selectedProduct,
    selectedBillingItem,
    isProductPage,
    canAddInstantly,
    editOnlyTariff = false,
    addTariff,
    updateTariff,
    updateTariffInList,
    addTariffToList,
    initTariffs
  } = props;

  let { treeNode, parentNode } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { productsStore, billingItemsStore, productConfigService, setOnlyShowTariffs } = productConfigStore;
  const { addTariffToNode, getTree, getTariffCalculationType, updateProductBillingItemInList } = productsStore;
  const { updateBillingItemInList } = billingItemsStore;
  const { updateBillingItem } = productConfigService;
  const { getTranslation } = applicationStore;

  let startDateTime = MIN_DATE;

  const toCheck = tariffs.filter((t) => {
    return (
      (!!selectedProduct && t.productId === selectedProduct.id) ||
      (!selectedProduct && (t.productId === null || !t.productId))
    );
  });

  // Sort tariffs by end date
  sortByDate(toCheck, 'endDateTime', 'DESC');

  // Use the latest end date as the start date of the new tariff
  if (toCheck[0] && toCheck[0].endDateTime !== MAX_DATE) {
    startDateTime = startOfDay(moment(toCheck[0].endDateTime)).toISOString();
  }

  const initializeValues = () => {
    const tierCalcMethod = selectedBillingItem?.tierCalculationMethod || tierCalculationMethod.invoiceperiod;

    if (tariff) {
      return {
        ...tariff,
        tariffType: tariffType.tariffcalculation,
        calculationParameters: clone(tariff.calculationParameters),
        conditionParameters: clone(tariff.conditionParameters),
        tariffCalculationType: getTariffCalculationType(tariff.calculationParameters.type),
        tierCalculationMethod: tierCalcMethod
      };
    } else if (treeNode?.tariff) {
      return {
        ...treeNode.tariff,
        tierCalculationMethod: tierCalcMethod
      };
    }

    return {
      productId: selectedProduct?.id || '',
      billingItemId: selectedBillingItem?.id || '',
      tariffType: tariffType.tariffcalculation,
      calculationParameters: {
        type: billingItemTariffCalculationType.unitprice,
        formulaBased: false,
        expression: ''
      } as TariffCalculationTypeParametersType,
      startDateTime,
      conditionParameters: [],
      endDateTime: MAX_DATE,
      tariffCalculationType: tariffCalculationType.unitprice,
      tierCalculationMethod: tierCalcMethod
    };
  };

  const { values, backup, errors, setValue } = useSimpleValidator<TariffValidatorType>({
    initialValues: initializeValues(),
    validate: () => {
      const feedback: boolean[] = [];

      feedback.push(!moment(values.startDateTime).isBefore(values.endDateTime));

      if (
        values.calculationParameters.type !== billingItemTariffCalculationType.unitprice &&
        values.tierCalculationMethod === tierCalculationMethod.tariffperiod
      ) {
        feedback.push(isMinDate(values.startDateTime) || isMaxDate(values.endDateTime));
      }

      if (values.tariffType === tariffType.tariffcalculation) {
        feedback.push(!values.calculationParameters.type || Object.keys(values.calculationParameters).length === 1);

        switch (values.calculationParameters.type) {
          case billingItemTariffCalculationType.unitprice:
            {
              const parameters = values.calculationParameters as UnitPriceTariffCalculationTypeParametersType;

              if (parameters.formulaBased) {
                feedback.push(!parameters.expression);
              } else {
                feedback.push(typeof parameters.unitTariff === 'undefined');
              }
            }
            break;
          case billingItemTariffCalculationType.volume:
          case billingItemTariffCalculationType.tiered:
            {
              const parameters = values.calculationParameters as TieredTariffCalculationTypeParametersType;
              if (parameters.slices) {
                parameters.slices.forEach((s) => {
                  if (s.formulaBased) {
                    feedback.push(!s.expression);
                  } else {
                    feedback.push(typeof s.unitTariff === 'undefined');
                  }
                });
              }
            }
            break;
          case billingItemTariffCalculationType.stairstep:
            {
              const parameters = values.calculationParameters as StairStepTariffCalculationTypeParametersType;
              if (parameters.slices) {
                parameters.slices.forEach((s) => {
                  if (s.formulaBased) {
                    feedback.push(!s.expression);
                  } else {
                    feedback.push(typeof s.unitTariff === 'undefined');
                  }
                });
              }
            }
            break;
        }
      }

      return feedback;
    }
  });

  const { openConditionsDialog } = useRenderTreeActions({
    isProductPage,
    tree: treeNode,
    selectedBillingItem: selectedBillingItem,
    addTariff,
    updateTariff,
    updateTariffInList,
    addTariffToList,
    startDate: values.startDateTime,
    endDate: values.endDateTime,
    tariffs_: tariffs,
    selectedProduct,
    initTariffs
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(errors.includes(true));
    }
  };

  useEffect(() => {
    validate();
  }, [errors]);

  const createApiFriendlyValues = () => {
    const parameters = clone(values.calculationParameters);
    parameters.type = values.calculationParameters.type;

    const params = parameters as any;

    if (
      (parameters.type === billingItemTariffCalculationType.tiered || billingItemTariffCalculationType.volume) &&
      params.slices
    ) {
      params.slices.forEach((s: any) => {
        s.from = parseFloat(s.from);
      });
    }

    return {
      productId: values.productId,
      billingItemId: values.billingItemId,
      calculationParameters: params,
      startDateTime: values.startDateTime,
      endDateTime: values.endDateTime,
      conditionParameters: values.conditionParameters
    };
  };

  const directApiCall = async () => {
    try {
      const valuesToSubmit = createApiFriendlyValues();

      // If tierCalculationMethod changed, update billing item
      if (selectedBillingItem && backup.tierCalculationMethod !== values.tierCalculationMethod) {
        const updatedItem = await updateBillingItem(selectedBillingItem.id, {
          name: selectedBillingItem.name,
          description: selectedBillingItem.description,
          personTaxCodeId: selectedBillingItem.personTaxCodeId,
          organisationTaxCodeId: selectedBillingItem.organisationTaxCodeId,
          tierCalculationMethod: values.tierCalculationMethod,
          calculationParameters: selectedBillingItem.calculationParameters
        });

        if (isProductPage) {
          updateProductBillingItemInList(updatedItem);
        } else {
          updateBillingItemInList(updatedItem);
        }
      }

      if (tariff) {
        const res = await updateTariff(tariff.id, valuesToSubmit);
        updateTariffInList(res);
      } else {
        const res = await addTariff(valuesToSubmit);
        addTariffToList(res);
      }

      await getTree(values.billingItemId, values.productId);

      notify.success({
        content: getTranslation(`billing_tariff.${tariff ? 'edit' : 'add'}_tariff_success`)
      });
    } catch (error) {
      notify.error({
        content: getTranslation(`billing_tariff.${tariff ? 'edit' : 'add'}_tariff_fail`),
        error
      });
    }
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (treeNode) {
        //edit period nodes
        if (!editOnlyTariff) {
          treeNode = treeNode.addTariffPeriodNode(
            values.startDateTime || MIN_DATE,
            values.endDateTime || MAX_DATE,
            selectedBillingItem?.tierCalculationMethod,
            betweenDates(values.startDateTime || MIN_DATE, values.endDateTime || MAX_DATE, moment()),
            false
          );
        }
        if (values.tariffType === tariffType.tariffcalculation) {
          parentNode?.setTierCalculationMethod(values.tierCalculationMethod);
          treeNode.setTierCalculationMethod(values.tierCalculationMethod);

          addTariffToNode(
            treeNode,
            {
              productId: values.productId,
              billingItemId: values.billingItemId,
              tariffType: tariffType.tariffcalculation,
              calculationParameters: clone(values.calculationParameters),
              conditionParameters: clone(values.conditionParameters),
              startDateTime: values.startDateTime,
              endDateTime: values.endDateTime,
              id: values.id,
              tariffCalculationType: getTariffCalculationType(values.calculationParameters.type),
              tierCalculationMethod: values.tierCalculationMethod
            },
            parentNode,
            index
          );
          if (canAddInstantly) {
            await directApiCall();
          }
        } else {
          setOnlyShowTariffs(true, values.billingItemId, values.productId);
          productsStore.getTariffTreeByPeriod(treeNode.id);
          if (treeNode) openConditionsDialog(treeNode);
        }
      } else {
        await directApiCall();
        if (setOnlyShowTariffs) setOnlyShowTariffs(false, values.billingItemId, values.productId);
      }
    }
  }));

  const tariffTypeTabItems: SelectionTabItemType<any>[] = [
    {
      id: tariffType.tariffcalculation,
      icon: 'coin',
      title: getTranslation('billing_tariff.tariffcalculation')
    },
    {
      id: tariffType.conditions,
      icon: 'condition',
      title: getTranslation('billing_tariff.tariff_condition')
    }
  ];

  const tariffCalculationTypeTabItems: SelectionTabItemType<any>[] = [
    { id: tariffCalculationType.unitprice, title: getTranslation('billing_tariff.unit_price') },
    { id: tariffCalculationType.tieredprice, title: getTranslation('billing_tariff.tiered_price') }
  ];

  return (
    <div className={css['add-tariff-wrapper']}>
      {!editOnlyTariff && (
        <>
          <EditTariffPeriod
            values={{ startDateTime: values.startDateTime || MIN_DATE, endDateTime: values.endDateTime || MAX_DATE }}
            setValue={(val) => setValue({ startDateTime: val.startDateTime, endDateTime: val.endDateTime })}
            showErrors={
              values.calculationParameters.type !== billingItemTariffCalculationType.unitprice &&
              values.tierCalculationMethod === tierCalculationMethod.tariffperiod
            }
          />

          <HorizontalDivider className={css['add-tariff-spacer']} />

          {treeNode && (
            <>
              <Heading headingType="h3">{getTranslation('billing_tariff.tariff')}</Heading>
              <TabSlider
                className={css['tariff-period-tab-slider']}
                id="tariff-type"
                tabItems={tariffTypeTabItems}
                selectedTabItem={values.tariffType}
                setSelectedItem={(val) => {
                  setValue({ tariffType: val as tariffType });
                  if (values.tariffType !== tariffType.conditions) {
                    validationRef.current?.setButtonPositive(getTranslation('billing_tariff.open_editor'));
                  } else {
                    validationRef.current?.setButtonPositive(getTranslation('general.add'));
                  }
                }}
              />
              {values.tariffType !== tariffType.conditions && (
                <SettingDescription
                  title={getTranslation('billing_tariff.calc_type')}
                  description={getTranslation('billing_tariff.calc_type_descr')}
                  setting={
                    <TabSlider
                      id="tariff-calculation-type"
                      tabItems={tariffCalculationTypeTabItems}
                      selectedTabItem={values.tariffCalculationType}
                      setSelectedItem={(val) => {
                        setValue({
                          tariffCalculationType: val as tariffCalculationType,
                          calculationParameters: {
                            ...values.calculationParameters,
                            type:
                              val === tariffCalculationType.unitprice
                                ? billingItemTariffCalculationType.unitprice
                                : billingItemTariffCalculationType.tiered
                          }
                        });
                      }}
                    />
                  }
                />
              )}
            </>
          )}
        </>
      )}

      {values.tariffType === tariffType.conditions ? (
        <TariffCondition />
      ) : (
        <TariffCalculation
          values={values}
          backup={backup}
          selectedBillingItem={selectedBillingItem}
          setValue={setValue}
          setIsError={validationRef.current?.setIsError}
        />
      )}
    </div>
  );
});

export default observer(AddEditTariffDialog);
