import moment from 'moment';
import React from 'react';

import { PeriodType, RangeType } from '@zf/api-types/general';
import { formatDate, sortPeriods } from '@zf/utils/src/date';

import { useAppContext } from '../app-context/app-context';
import { NotificationContentList } from '../design-system/Components';
import { notify } from '../events/notification-events';

type ValuesType = {
  startDateTime: string;
  endDateTime: string;
};

type OverlapOrGapsResultType = {
  overlap: boolean;
  gap: boolean;
  ranges: RangeType[];
  gaps: RangeType[];
};

const doesOverlap = (period1: PeriodType, period2: PeriodType) => {
  return period1.startDate.isBefore(period2.endDate) && period2.startDate.isBefore(period1.endDate);
};

const hasGap = (period1: PeriodType, period2: PeriodType) => {
  return period1.endDate.isBefore(period2.startDate);
};

export default function usePeriodValidator(advice?: string) {
  const { i18n } = useAppContext();

  const notifyForOverlapOrGaps = (overlapGapsValidation: OverlapOrGapsResultType, entityName?: string) => {
    // The intervals where overlap occurs
    const overlapStrings: string[] = [];

    overlapGapsValidation.ranges.forEach((range) => {
      overlapStrings.push(`from ${formatDate(range.from)} to ${formatDate(range.until)}`);
    });

    // The intervals between which there is a gap
    const gapStrings: string[] = [];

    overlapGapsValidation.gaps.forEach((gap) => {
      gapStrings.push(`from ${formatDate(gap.from)} to ${formatDate(gap.until)}`);
    });

    if (overlapGapsValidation.overlap) {
      notify.warning({
        content: (
          <NotificationContentList
            info={
              entityName
                ? i18n.getTranslation('cost_component_value.overlapping_period_info_with_name', { entityName })
                : i18n.getTranslation('cost_component_value.overlapping_period_info')
            }
            list={overlapStrings}
            advice={advice || i18n.getTranslation('cost_component_value.advice')}
          />
        )
      });
    }

    if (overlapGapsValidation.gap) {
      notify.warning({
        content: (
          <NotificationContentList
            info={
              entityName
                ? i18n.getTranslation('cost_component_value.gap_info_with_name', { entityName })
                : i18n.getTranslation('cost_component_value.gap_info')
            }
            list={gapStrings}
            advice={advice || i18n.getTranslation('cost_component_value.advice')}
          />
        )
      });
    }
  };

  const checkForOverlapAndGaps = (valuesToCheck: ValuesType[], id?: string) => {
    const periods: PeriodType[] = valuesToCheck.map((value) => {
      return { startDate: moment(value.startDateTime), endDate: moment(value.endDateTime) };
    });

    const sorted = sortPeriods(periods);

    const result = sorted.reduce(
      (res: OverlapOrGapsResultType, current, idx, arr) => {
        if (idx === 0) {
          return res;
        }

        const previous = arr[idx - 1];

        // check for any overlap
        const overlaps = doesOverlap(previous, current);
        // check for gaps
        const isGap = hasGap(previous, current);

        if (overlaps) {
          res.overlap = true;
          // store the specific ranges that overlap
          res.ranges.push({
            id: id,
            from: previous.startDate.isBefore(current.endDate) ? previous.startDate : current.endDate,
            until: previous.endDate.isAfter(current.startDate) ? current.startDate : previous.endDate
          });
        }

        if (isGap) {
          res.gap = true;
          // store the gaps
          res.gaps.push({
            id: id,
            from: previous.endDate.isBefore(current.startDate) ? previous.endDate : current.endDate,
            until: current.startDate.isAfter(previous.startDate) ? current.startDate : previous.startDate
          });
        }

        return res;

        // seed the reduce
      },
      { overlap: false, gap: false, ranges: [], gaps: [] }
    );

    return result;
  };

  return { checkForOverlapAndGaps, notifyForOverlapOrGaps };
}
