import clone from 'clone';
import { RadioInput, TabSlider } from 'design-system/Components';
import { SelectionTabItemType } from 'design-system/Components/TabSlider/Tab';
import { Paragraph, Spinner } from 'design-system/Foundation';
import { EnrichedBillingCalculationTypeParameters } from 'features/product-config/stores/BillingItemsDialogStore';
import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import {
  billingCalculationType,
  billingScope,
  meteringType,
  tierCalculationMethod,
  unitOfMeasure
} from '@zf/api-types/enums';
import {
  BillingCalculationTypeParametersType,
  BillingItemRequestType,
  BillingItemType,
  ConsumptionCalculationTypeParametersType,
  CustomEntityPropertyCalculationTypeParameters,
  SubscriptionCalculationTypeParametersType
} from '@zf/api-types/product';
import { UpdateTaxCodeType } from '@zf/api-types/tax-codes';
import useSimpleValidator from '@zf/hooks/src/useSimpleValidator';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import TaxCodeDropDown from '../../../../../components/Dropdown/taxcodes-dropdown/TaxCodeDropDown';
import InputField from '../../../../../components/input/InputField';
import { SettingDescription } from '../../../../../design-system/ComponentSets';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import css from './add-edit-billing-item-dialog.module.scss';
import CalculationParameters from './calculation-parameters/CalculationParameters';
import TariffCalculationWarning from './TariffCalculationWarning';

type Props = {
  billingItem?: BillingItemType;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

export type BillingItemValidatorType = {
  supportedUoms: unitOfMeasure[];
  name: string;
  description: string;
  personTaxCodeId: string | undefined;
  organisationTaxCodeId: string | undefined;
  tierCalculationMethod: tierCalculationMethod;
  billingCalculationType: billingCalculationType;
  calculationParameters: Map<billingCalculationType, EnrichedBillingCalculationTypeParameters>;

  // Only used for CEP for UI behavior to avoid conversion hassle in calculationParameters
  customEntityPropertyType: CustomEntityPropertyType | undefined;
};

const AddEditBillingItemDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { billingItem, validationRef } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation } = applicationStore;
  const { billingItemsStore } = productConfigStore;
  const { taxCodes, addBillingItem, updateBillingItem, updateBillingItemInList } = billingItemsStore;
  const { uomsAreLoading, setUoMsAreLoading, initCalculationParameters } = billingItemsStore.billingItemsDialogStore;

  const filteredCalculationTypes = useRef(
    getEnum<billingCalculationType>('billingCalculationType').filter(
      // To be removed when backend drops support
      (bct) => bct.value !== billingCalculationType.attribute && bct.value !== billingCalculationType.consumptionunit
    )
  );

  const calculationTypeTabItems = useRef<SelectionTabItemType<any>[]>(
    filteredCalculationTypes.current.map((ct) => {
      return { id: ct.value, title: getEnumTranslation('billingCalculationType', ct.value) };
    })
  );

  const { taxCodeOrganisation, taxCodePerson } = useMemo(() => {
    let taxCodeOrganisation: UpdateTaxCodeType | undefined;
    let taxCodePerson: UpdateTaxCodeType | undefined;

    if (billingItem) {
      taxCodeOrganisation = taxCodes.find((tc) => tc.id === billingItem.organisationTaxCodeId);
      taxCodePerson = taxCodes.find((tc) => tc.id === billingItem.personTaxCodeId);
    }

    return { taxCodeOrganisation, taxCodePerson };
  }, [taxCodes, billingItem]);

  const { values, backup, errors, setValue } = useSimpleValidator<BillingItemValidatorType>({
    initialValues: billingItem
      ? {
          ...billingItem,
          supportedUoms: [],
          billingCalculationType: billingItem.calculationParameters.type,
          personTaxCodeId: taxCodePerson?.id,
          organisationTaxCodeId: taxCodeOrganisation?.id,
          calculationParameters: initCalculationParameters(filteredCalculationTypes.current, billingItem),
          customEntityPropertyType: undefined
        }
      : {
          supportedUoms: [],
          name: '',
          description: '',
          organisationTaxCodeId: undefined,
          personTaxCodeId: undefined,
          billingCalculationType: billingCalculationType.consumption,
          tierCalculationMethod: tierCalculationMethod.invoiceperiod,
          calculationParameters: initCalculationParameters(filteredCalculationTypes.current),
          customEntityPropertyType: undefined
        },
    validate: () => {
      const feedback: boolean[] = [];

      feedback.push(!values.name || !values.description || !values.organisationTaxCodeId || !values.personTaxCodeId);

      switch (values.billingCalculationType) {
        case billingCalculationType.consumption:
          {
            const parameters = values.calculationParameters.get(billingCalculationType.consumption) as
              | ConsumptionCalculationTypeParametersType
              | undefined;

            feedback.push(!parameters?.meteringType || !parameters?.utilityType || !parameters?.unitOfMeasure);
          }
          break;

        case billingCalculationType.subscription:
          {
            const parameters = values.calculationParameters.get(billingCalculationType.subscription) as
              | (SubscriptionCalculationTypeParametersType & EnrichedBillingCalculationTypeParameters)
              | undefined;

            if (parameters && parameters.scope === billingScope.location && parameters.onlyForSpecificService) {
              feedback.push(!parameters?.utilityType);
            }
          }
          break;

        case billingCalculationType.customentityproperty:
          {
            const parameters = values.calculationParameters.get(billingCalculationType.customentityproperty) as
              | (CustomEntityPropertyCalculationTypeParameters & EnrichedBillingCalculationTypeParameters)
              | undefined;

            if (parameters) {
              feedback.push(!parameters.customEntityPropertyTypeId);

              if (parameters.scope === billingScope.location && parameters.onlyForSpecificService) {
                feedback.push(!parameters?.utilityType);
              }
            }
          }
          break;
      }

      return feedback;
    }
  });

  const initSupportedUoms = (billingCalculationType: billingCalculationType, meteringType?: meteringType) => {
    productConfigStore.productConfigService
      .getSupportedUomForBillingItem(billingCalculationType, meteringType)
      .then((supportedUoms) => setValue({ supportedUoms }));
  };

  useEffect(() => {
    if (billingItem) {
      const params = billingItem.calculationParameters as BillingCalculationTypeParametersType & {
        meteringType: meteringType;
      };
      initSupportedUoms(billingItem.calculationParameters.type, params.meteringType);
    } else {
      const params = values.calculationParameters.get(values.billingCalculationType) as
        | (BillingCalculationTypeParametersType & {
            meteringType: meteringType;
          })
        | undefined;

      if (params) {
        initSupportedUoms(values.billingCalculationType, params.meteringType);
      }
    }
  }, []);

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(errors.includes(true));
    }
  };

  useEffect(() => {
    validate();
  }, [errors]);

  const createApiFriendlyValues = (): BillingItemRequestType => {
    return {
      ...values,
      personTaxCodeId: values.personTaxCodeId || '',
      organisationTaxCodeId: values.organisationTaxCodeId || '',
      calculationParameters: {
        ...values.calculationParameters.get(values.billingCalculationType),
        type: values.billingCalculationType
      }
    };
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const apiFriendlyValues = createApiFriendlyValues();

        if (billingItem) {
          const res = await updateBillingItem(billingItem.id, apiFriendlyValues);
          updateBillingItemInList(res);
        } else {
          await addBillingItem(apiFriendlyValues);
        }

        notify.success({
          content: getTranslation(`billing_items.${billingItem ? 'edit' : 'add'}_item_success`)
        });
      } catch (e) {
        notify.error({
          content: getTranslation(`billing_items.${billingItem ? 'edit' : 'add'}_item_fail`),
          error: e
        });
      }
    }
  }));

  const onChangeBillingCalculationType = async (billingCalculationType_: billingCalculationType) => {
    const clonedCalcParams = clone(values.calculationParameters);
    const typeToUpdate = clonedCalcParams.get(billingCalculationType_);

    if (typeToUpdate) {
      setUoMsAreLoading(true);

      clonedCalcParams.set(billingCalculationType_, { ...typeToUpdate, type: billingCalculationType_ });

      try {
        let params: ConsumptionCalculationTypeParametersType | undefined = undefined;

        if (billingCalculationType_ === billingCalculationType.consumption) {
          params = clonedCalcParams.get(billingCalculationType.consumption) as ConsumptionCalculationTypeParametersType;
        }

        const supportedUoms = await productConfigStore.productConfigService.getSupportedUomForBillingItem(
          billingCalculationType_,
          params?.meteringType
        );

        setValue({
          billingCalculationType: billingCalculationType_,
          calculationParameters: clonedCalcParams,
          supportedUoms
        });

        setUoMsAreLoading(false);
      } catch (e) {
        throw e;
      }
    }
  };

  return (
    <div className={css['wrapper']}>
      <FlexElements gap="16">
        <InputField
          id="name"
          value={values.name}
          onChange={(name) => setValue({ name })}
          placeholder={getTranslation('general.name')}
          error={!values.name}
        />
        <InputField
          id="description"
          value={values.description}
          onChange={(description) => setValue({ description })}
          placeholder={getTranslation('general.description')}
          error={!values.description}
        />
      </FlexElements>

      <HorizontalDivider className={css['top-divider']} />

      <FlexElements gap="16">
        <TaxCodeDropDown
          id="tax-code-person"
          selectedValue={values.personTaxCodeId}
          placeholder={getTranslation('billing_items.private_customer_tax')}
          onChange={(val) => setValue({ personTaxCodeId: val[0]?.id || '' })}
          error={!values.personTaxCodeId}
        />
        <TaxCodeDropDown
          id="tax-code-organisation"
          placeholder={getTranslation('billing_items.organisation_tax')}
          selectedValue={values.organisationTaxCodeId}
          onChange={(val) => setValue({ organisationTaxCodeId: val[0]?.id || '' })}
          error={!values.organisationTaxCodeId}
        />
      </FlexElements>

      <HorizontalDivider className={css['divider']} />

      <FlexElements flexDirection="column" gap="8">
        <Paragraph textAlign="left" bold>
          {getTranslation('billing_items.billing_calculation_type')}
        </Paragraph>
        <TabSlider
          id="calculation-type"
          tabItems={calculationTypeTabItems.current}
          selectedTabItem={values.billingCalculationType}
          setSelectedItem={(val) => onChangeBillingCalculationType(val as billingCalculationType)}
          fullWidth
        />
      </FlexElements>

      {uomsAreLoading ? (
        <div className={css['loader-wrapper']}>
          <Spinner size="small" centered />
        </div>
      ) : (
        <CalculationParameters values={values} setValue={setValue} />
      )}

      <HorizontalDivider />

      <SettingDescription
        className={css['tier-calculation-period-description']}
        title={getTranslation('billing_items.tier_calc_method')}
        description={getTranslation('billing_items.tier_calc_method_descr')}
        setting={
          <FlexElements gap="24">
            {getEnum<tierCalculationMethod>('tierCalculationMethod').map((tcm) => (
              <RadioInput
                key={tcm.value}
                onChange={(option) => setValue({ tierCalculationMethod: option as tierCalculationMethod })}
                value={tcm.value}
                name={tcm.text}
                checked={values.tierCalculationMethod === tcm.value}
              >
                {tcm.text}
              </RadioInput>
            ))}
          </FlexElements>
        }
      />

      {
        // Only render this when editing and tierCalculationMethod is touched
        billingItem && backup.tierCalculationMethod !== values.tierCalculationMethod && (
          <TariffCalculationWarning
            tierCalcMethod={values.tierCalculationMethod}
            selectedBillingItem={billingItem}
            setIsError={validationRef.current?.setIsError}
          />
        )
      }
    </div>
  );
});

export default observer(AddEditBillingItemDialog);
