import { shownValueType } from '@zf/stella-react/src/atoms/Dropdown/DropdownList';
import Interweave from 'interweave';
import React from 'react';
import { Paragraph } from '../../design-system/Foundation';
import { useStore } from '../../hooks/useStore';

import css from './results-counter.module.scss';

type Props<T> = {
  focusedIndex: number | undefined;
  emptyText: string;
  shownValues?: shownValueType<T>[];
  entity?: string;
  totalAmountOfRecords?: number;
};

export default function ResultsCounter<T>(props: Props<T>) {
  const { entity = 'results', totalAmountOfRecords, emptyText, focusedIndex = 0 } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  let { shownValues } = props;

  if (!shownValues) return null;

  if (emptyText !== 'No matches' && shownValues.length === 0) {
    shownValues = [];
  }

  const counter = totalAmountOfRecords || shownValues.length;

  return (
    <div className={css['results-counter']}>
      <Paragraph className={css['paragraphs']}>
        <Interweave content={getTranslation('general.found', { entity, counter })} />
      </Paragraph>

      <Paragraph className={css['align-right']}>
        {shownValues.length > 0 ? focusedIndex + 1 : counter} {getTranslation('general.of')} {counter}
      </Paragraph>
    </div>
  );
}
