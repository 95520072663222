import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { DataProviderTypesType, UpdateExportDataProviderType } from '@zf/api-types/data-provider';
import { FileFormatType } from '@zf/api-types/file-format';

import useConfig from '../../../app-context/hooks/use-config';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import DataExports from './data-exports';

export default function DataExportTab() {
  const config = useConfig<UpdateExportDataProviderType[]>('dataExports', '/cfg/DataExports');

  const providerTypesResponse = useSuspenseSingleAPI<DataProviderTypesType[]>(
    {
      request: {
        endpoint: '/cfg/DataExports/types'
      }
    },
    false,
    false
  );

  const formatsResponse = useSuspenseSingleAPI<PagedResponseType<FileFormatType>>(
    {
      request: {
        endpoint: '/cfg/DataExportFileFormats'
      }
    },
    false,
    false
  );

  if (!providerTypesResponse.result || !formatsResponse.result) return null;

  return (
    <DataExports
      config={config}
      dataTypes={providerTypesResponse.result.data}
      fileformats={formatsResponse.result.data.results}
    />
  );
}
