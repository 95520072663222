import Interweave from 'interweave';
import moment from 'moment';
import React from 'react';

import { formatDate } from '@zf/utils/src/date';

import Paragraph from '../../../../../design-system/Foundation/Paragraph/Paragraph';
import { useStore } from '../../../../../hooks/useStore';
import css from './change-product-summary.module.scss';

type Props = {
  productId: string;
  productName: string;
  date: string;
};

const ChangeProductSummary = (props: Props) => {
  const { productId, productName, date } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const today = moment();
  const dateIsInPast = moment(date).isBefore(today);

  let productSummary = '';

  if (productId) {
    if (dateIsInPast) {
      productSummary = getTranslation('contracts.product_in_past', { newProduct: productName, date: formatDate(date) });
    } else {
      productSummary = getTranslation('contracts.product_in_future', {
        newProduct: productName,
        date: formatDate(date)
      });
    }
  } else {
    if (dateIsInPast) {
      productSummary = getTranslation('contracts.no_product_in_past', { date: formatDate(date) });
    } else {
      productSummary = getTranslation('contracts.no_product_in_future', { date: formatDate(date) });
    }
  }

  return (
    <Paragraph className={css['product-summary']}>
      <Interweave content={productSummary} />
    </Paragraph>
  );
};

export default ChangeProductSummary;
