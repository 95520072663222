import React from 'react';

import { ExternalChannelType, MeasurementRowType, MeasurementType, MeterType } from '@zf/api-types/master-data/meter';
import { formatDateTime } from '@zf/utils/src/date';
import { formatDecimal } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../../app-context/app-context';
import { Icon } from 'design-system/Foundation';
import useCreateRequest from '../../../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../../../hooks/useInfiniAPI';
import { getColorByUtilityType } from '../../../../../../../utils/meter';
import { OutputCtxState, useTracked } from '../../context/output-context';
import MeterIssueIndicator from 'design-system/Components/MeterIssueIndicator/MeterIssueIndicator';

export default function useValuesTable(timeStamp: string, meter: MeterType) {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const setState = (newState: Partial<OutputCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const { request, selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = useCreateRequest(
    `/me/${state.outputType}/${encodeURIComponent(state.selectedListModeChannel)}`,
    {
      ...state.queryParams,
      startDateTime: state.queryParams.startDateTime.toISOString(),
      endDateTime: state.queryParams.endDateTime.toISOString(),
      timeStamp: timeStamp,
      orderBy: '-EndDateTime'
    }
  );

  const processRecord = (measurement: MeasurementType) => {
    const channel: ExternalChannelType | undefined = state.channels.find(
      (chann) => chann.externalIdentifier === measurement.externalChannelIdentifier
    );
    const channelDescription = channel ? channel.description : ';';

    if (!channel) return { __id: new Date().valueOf().toString() } as MeasurementRowType;

    return {
      isUnhealthy: measurement.issue ? (
        <MeterIssueIndicator
          id={meter.id}
          usage="measurement"
          tooltipId={measurement.id}
          issueStatus={measurement.issue.status}
          issueError={measurement.issue.error}
        />
      ) : null,
      __id: `${measurement.externalChannelIdentifier}-${measurement.createdDateTime}`,
      __measurementEntity: measurement,
      dataFrequency: enumReducer.getTranslation('dataFrequency', channel.dataFrequency),
      incrementationType: enumReducer.getTranslation('incrementationType', channel.incrementationType),
      meteringType: enumReducer.getTranslation('meteringType', channel.meteringType),
      utilityType: channel.utilityType ? (
        <Icon
          id={`measurements-table-tip-${measurement.id}`}
          title={enumReducer.getTranslation('utilityType', channel.utilityType)}
          name={channel.utilityType}
          //usedInTable
          color={getColorByUtilityType(channel.utilityType)}
        />
      ) : (
        ''
      ),
      direction: enumReducer.getTranslation('direction', channel.direction),
      unitOfMeasure: enumReducer.getTranslation('unitOfMeasure', channel.unitOfMeasure),
      externalChannelIdentifier: (
        <div style={{ color: getColorByUtilityType(channel.utilityType) }}>{channelDescription}</div>
      ),
      userId: measurement.userId,
      value: formatDecimal(measurement.value, i18n.culture),
      endDateTime: formatDateTime(measurement.endDateTime),
      timeZone: measurement.timeZone,
      deleted: measurement.deleted,
      timeOfUse: channel.timeOfUse,
      _etag: measurement._etag
    };
  };

  const setSelectedListModeChannel = (channelId: string) => {
    setState({ selectedListModeChannel: channelId });
  };

  const { loading, error, rows, sortableFields, aggregateDetails, setStopIndex } = useInfiniAPI<
    MeasurementType,
    MeasurementRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  return {
    selectedIds,
    sortBy,
    sortDirection,
    sortableFields,
    loading,
    error,
    rows,
    aggregateDetails,
    channels: state.channels,
    consumptionChannels: state.consumptionChannels,
    outputType: state.outputType,
    selectedListModeChannel: state.selectedListModeChannel,
    setSelectedIds,
    handleSort,
    setStopIndex,
    setSelectedListModeChannel
  };
}
