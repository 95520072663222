import { unitOfMeasure, utilityType } from '@zf/api-types/enums';
import {
  ConsumptionCalculationTypeParametersType,
  SubscriptionCalculationTypeParametersType
} from '@zf/api-types/product';

import { useAppContext } from '../../../../../app-context/app-context';
import useBillingData from './use-billing-data';

export default function useBillingItemDetails(groupBillingItemId: string, isFormulaHeader = false) {
  const { i18n, enumReducer } = useAppContext();
  const { billingItems, selectedBillingItemFormulaType } = useBillingData();

  const billingItemMatch = billingItems.find((b) => b.id === groupBillingItemId);

  let utilityType_: utilityType | undefined = undefined;
  let unitOfMeasure_: unitOfMeasure | undefined = undefined;

  let itemName = '';

  if (billingItemMatch) {
    const calcParameters = billingItemMatch.calculationParameters as
      | ConsumptionCalculationTypeParametersType
      | SubscriptionCalculationTypeParametersType;
    utilityType_ = calcParameters.utilityType;
    unitOfMeasure_ = calcParameters.unitOfMeasure;
    itemName = billingItemMatch.name;

    if (unitOfMeasure_) {
      itemName += isFormulaHeader
        ? ` (${i18n.getTranslation(`property_groups.tabs.billing.${selectedBillingItemFormulaType}`)})`
        : ` (${enumReducer.getTranslation('unitOfMeasure', unitOfMeasure_)})`;
    }
  }

  return {
    billingItemMatch,
    itemName,
    utilityType: utilityType_,
    unitOfMeasure: unitOfMeasure_
  };
}
