import React from 'react';

import { Loader } from '../Loader';

type Props = {
  fullViewport?: boolean;
};

export default function TableLoading(props: Props) {
  return <Loader {...props} />;
}
