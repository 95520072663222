import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import SubTabs from '@zf/stella-react/src/atoms/Tabs/SubTabs/SubTabs';

import { useAppContext } from '../../../../app-context/app-context';
import useFetchMeteringData from './hooks/use-fetch-metering-data';
import { MeteringProvider } from './metering-context/context';
import CalculationConfigTab from './tabs/calculation-configuration/calculation-config-tab';
import CalculationOutputsTab from './tabs/calculation-outputs/calculation-outputs-tab';
import Consumptions from './tabs/consumptions/consumptions';
import Dashboard from './tabs/dashboard/dashboard';
import Meters from './tabs/meters/meters';

type Props = {
  propertyGroup: PropertyGroupType;
  timeStamp: string;
};

function MeteringTab(props: Props) {
  const { propertyGroup, timeStamp } = props;
  const { i18n } = useAppContext();
  useFetchMeteringData(propertyGroup.id);

  const subtabs: Record<string, JSX.Element> = {};
  subtabs[i18n.getTranslation('property_groups.tabs.metering.dashboard')] = (
    <Dashboard propertyGroupId={propertyGroup.id} timeStamp={timeStamp} />
  );
  subtabs[i18n.getTranslation('meter.meters')] = <Meters {...props} />;
  subtabs[i18n.getTranslation('meter.consumptions')] = <Consumptions propertyGroup={propertyGroup} />;
  subtabs[i18n.getTranslation('property_groups.tabs.metering.calculation_outputs')] = <CalculationOutputsTab />;
  subtabs[i18n.getTranslation('property_groups.tabs.metering.calculation_configuration')] = <CalculationConfigTab />;

  return <SubTabs subTabs={subtabs} defaultTab={i18n.getTranslation('property_groups.tabs.metering.dashboard')} />;
}

export default function MeteringTabWrapper(props: Props) {
  return (
    <MeteringProvider>
      <MeteringTab {...props} />
    </MeteringProvider>
  );
}
