import React from 'react';

import { IncomingMutationType, ManualMatchIncomingMutationRequest } from '@zf/api-types/billing/incoming-mutations';
import { incomingMutationType } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import IncomingMutationDetailsDialog from '../../features/payment/banking-transactions/incoming-banking-transactions/detail-page/detail/IncomingMutationDetailsDialog';
import { manualMatchIncomingMutation } from './incoming-mutations';
import ManualMatchingContent from './ManualMatchingContent';
import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import {
  UpdateIbanConfirmation,
  getIbanConfirmation
} from 'actions/incoming-banking-transactions/incoming-banking-transactions';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import CustomerAutoFill from 'components/Autofills/CustomerAutoFill';
import { IbanUnknown } from 'features/payment/banking-transactions/incoming-banking-transactions/detail-page/detail/IbanUnknown';

type Props = {
  incomingMutation: IncomingMutationType;
  incomingBankingTransaction: IncomingBankingTransactionType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refresh: () => void;
};

export type MatchDialogState = {
  selectedIds: string[];
  customerId: string;
  customerName: string;
  amount: number;
  remainingAmount: number;
  isBooked: boolean;
  refreshTrigger: string;
  saveIbanOnCustomer: boolean;
  ibanConfirmationStatus: string;
  isIbanConfirmedOnTransaction: boolean;
};

const ManualMatchDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { incomingMutation, validationRef, refresh } = props;
  const { i18n, tenantReducer } = useAppContext();
  const stateReducer = createStateReducer<MatchDialogState, Partial<MatchDialogState>>();
  const [state, dispatch] = React.useReducer(stateReducer, {
    selectedIds: [],
    customerId: incomingMutation.details.matchingCustomer.customerId,
    customerName: incomingMutation.details.matchingCustomer.shortDisplayName,
    amount: incomingMutation.amount,
    remainingAmount: incomingMutation.amount,
    isBooked: false,
    refreshTrigger: '',
    saveIbanOnCustomer: false,
    ibanConfirmationStatus: incomingMutation.details.ibanConfirmationStatus,
    isIbanConfirmedOnTransaction: incomingMutation.details.isIbanConfirmedOnTransaction
  });

  React.useEffect(() => {
    if (validationRef.current) {
      validationRef.current.setIsError(state.selectedIds.length <= 0);
    }
  }, [state.selectedIds]);

  React.useEffect(() => {
    const getIbanStatus = async () => {
      return await getIbanConfirmation(incomingMutation.id, state.customerId, tenantReducer, i18n.lang);
    };
    if (state.customerId)
      getIbanStatus().then(({ ibanConfirmationStatus, isIbanConfirmedOnTransaction }) => {
        dispatch({ ibanConfirmationStatus, isIbanConfirmedOnTransaction });
      });
  }, [state.customerId]);

  const getApiFriendlyValues = () => {
    switch (incomingMutation.type) {
      case incomingMutationType.incomingtransfer:
      case incomingMutationType.outgoingtransfer:
        return {
          outgoingBankingTransactionId: '',
          outgoingMutationId: '',
          matchingTransactionIds: state.selectedIds,
          customerId: state.isBooked ? state.customerId : ''
        };

      case incomingMutationType.refundtransactionconfirmation:
      case incomingMutationType.paymentrequesttransactionconfirmation:
        return {
          outgoingBankingTransactionId: state.selectedIds[0],
          outgoingMutationId: '',
          matchingTransactionIds: [],
          customerId: ''
        };

      case incomingMutationType.refundreversal:
      case incomingMutationType.paymentrequestreversal:
      case incomingMutationType.paymentrequestmutationconfirmation:
      case incomingMutationType.refundmutationconfirmation:
        return {
          outgoingBankingTransactionId: '',
          outgoingMutationId: state.selectedIds[0],
          matchingTransactionIds: [],
          customerId: ''
        };

      default:
        return {
          outgoingBankingTransactionId: '',
          outgoingMutationId: '',
          matchingTransactionIds: state.selectedIds,
          customerId: state.isBooked ? state.customerId : ''
        };
    }
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const data: ManualMatchIncomingMutationRequest = getApiFriendlyValues();

        await manualMatchIncomingMutation(incomingMutation.id, tenantReducer, i18n.lang, data);
        await UpdateIbanConfirmation(
          incomingMutation.incomingBankingTransactionId,
          state.customerId,
          incomingMutation.details.iban,
          state.isIbanConfirmedOnTransaction,
          tenantReducer,
          i18n.lang
        );

        refresh();

        notify.success({
          content: i18n.getTranslation('incoming_mutations.incoming_matching_valid')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('incoming_mutations.incoming_matching_invalid'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <IncomingMutationDetailsDialog
        incomingMutation={incomingMutation}
        dispatch={dispatch}
        state={state}
        validationRef={validationRef}
        refresh={refresh}
        matchScreen
      />

      <InputContainer grid={false}>
        <CustomerAutoFill
          id="customer"
          onChange={(value) => {
            dispatch({ customerId: value[0]?.id || '', customerName: value[0]?.shortDisplayName || '' });
          }}
          initialValue={state.customerName}
          selectedValues={[state.customerId || '']}
          placeholder={i18n.getTranslation('contracts.wizard.search_customer')}
        />
      </InputContainer>

      {/**
       * @description iban
       */}

      {(incomingMutation.type === incomingMutationType.incomingtransfer ||
        incomingMutation.type === incomingMutationType.outgoingtransfer ||
        incomingMutation.type === incomingMutationType.refundreversal ||
        incomingMutation.type === incomingMutationType.paymentrequestreversal) &&
        state.customerId && <IbanUnknown incomingMutation={incomingMutation} state={state} dispatch={dispatch} />}

      <ManualMatchingContent
        dispatch={dispatch}
        state={state}
        validationRef={validationRef}
        incomingMutation={incomingMutation}
      />
    </>
  );
});

export default ManualMatchDialog;
