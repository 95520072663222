import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { GatewayProvider } from 'react-gateway';

import StellaCheckBox from '@zf/stella-react/src/atoms/CheckBox/StellaCheckBox';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import { InternalColumnType, RowTypeBase } from '@zf/stella-react/src/atoms/Table';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { Provider } from '../../../app-context';
import css from './fixed-virtual-table.module.scss';

type Props<T> = {
  id: string;
  index: number;
  style: React.CSSProperties;
  hideHeader: boolean;
  dynamicRowHeight: boolean;
  column: InternalColumnType;
  rowData: T;
  isLoading: boolean;
  isSelected: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  tooltipId?: string;
};

export default function TableCell<T extends RowTypeBase>(props: Props<T>) {
  const {
    index,
    id,
    style,
    hideHeader,
    dynamicRowHeight,
    column,
    rowData,
    isLoading = false,
    isSelected = false,
    onClick,
    onKeyDown,
    tooltipId
  } = props;

  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const paragraph = document.getElementById(id);

    if (paragraph) {
      const hasOverflow = paragraph.scrollWidth > paragraph.offsetWidth;
      if (hasOverflow) {
        setHasOverflow(hasOverflow);
      }
    }
  }, []);

  let cellContent;

  if (column.dataKey === '__checkbox') {
    if (isLoading) {
      cellContent = <Spinner size="small" />;
    } else {
      cellContent = (
        <StellaCheckBox
          id={`${id}-table-checkbox-${index}`}
          checked={isSelected}
          preventDefault={true}
          className={classNames(css['virtual-table-checkbox'], css['virtual-table-checkbox-hide'])}
        />
      );
    }
  } else if (column.dataKey === '__empty') {
    // Sorting needs some margin to work here
    cellContent = (
      <div
        id={`empty-${index}`}
        className={classNames(css['virtual-table-checkbox'], css['virtual-table-checkbox-hide'])}
      />
    );
  } else {
    cellContent = rowData[column.dataKey];
  }

  return (
    <div
      className={classNames(css['virtual-table-data-cell'], css['virtual-table-cell'], {
        [css['no-header']]: hideHeader,
        [css['contentAlignRight']]: column.contentAlignRight
      })}
      style={style}
      role="button"
      aria-label={`Select row ${index}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      <div
        data-for={hasOverflow ? tooltipId : null}
        data-tip={
          hasOverflow
            ? createTooltipContent(
                <GatewayProvider>
                  <Provider>{cellContent}</Provider>
                </GatewayProvider>
              )
            : null
        }
        id={`cell-${index}`}
        className={classNames(css['virtual-table-cell-content'], {
          [css['fixed-height']]: !dynamicRowHeight
        })}
      >
        {isLoading ? (
          <Spinner size="xsmall" />
        ) : (
          <div id={id} className={css['overlay']}>
            {cellContent}
          </div>
        )}
      </div>
    </div>
  );
}
