import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { TenantOrganisationType } from '@zf/api-types/auth';

import { useAppContext } from '../app-context';
import RouteWithHeader from '../components/Header/RouteWithHeader';
import { Loader } from '../components/Loader';
import { useStore } from '../hooks/useStore';
import DashboardRouterContent from './DashboardRouterContent';

type Props = {
  org: string; // URL parameter
  location: Location;
  path?: string;
  default?: boolean;
};

const guidRe = new RegExp(`([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})`);

const DashboardRouter = (props: Props) => {
  const { org, location } = props;

  const { i18n, tenantReducer } = useAppContext();
  const { state, dispatch } = tenantReducer;
  const { tenant, tenantAuth } = state;

  const { applicationStore, organisationStore } = useStore();
  const { culture, setOrganization, setCulture } = applicationStore;
  const { organization } = applicationStore.tenantReducer;
  const { getOrganisationConfig } = organisationStore.organisationService;

  // In this effect we set all organisation specific data: organization, users, user & culture
  useEffect(() => {
    if (tenantAuth) {
      // Find matching organisation based on URL shortCode or guid ("org")
      let organizationMatch: TenantOrganisationType | undefined;

      if (guidRe.test(org)) {
        organizationMatch = tenantAuth.organizations.find((o) => {
          return o.organizationId === org;
        });
      } else {
        organizationMatch = tenantAuth.organizations.find((o) => {
          return o.shortCode?.toUpperCase() === org.toUpperCase();
        });
      }

      if (organizationMatch && organizationMatch.organizationId && tenant) {
        getOrganisationConfig(organizationMatch.organizationId, tenant.id).then((orgCfg) => {
          // Old context
          dispatch({ organization: organizationMatch });
          i18n.setCulture(orgCfg.culture);

          // MobX store
          organizationMatch && setOrganization(organizationMatch);
          setCulture(orgCfg.culture);
        });
      }
    }
  }, [org]);

  const splittedHref = location.href.split('/');

  // Only load the dashboard when culture & org state is defined
  if (!i18n.culture || !culture || !organization) return <Loader />;

  return (
    <RouteWithHeader>
      <DashboardRouterContent location={location} organization={organization} splittedHref={splittedHref} />
    </RouteWithHeader>
  );
};

export default observer(DashboardRouter);
