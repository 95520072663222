import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { FileFormatType } from '@zf/api-types/file-format';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import Button from '../../components/Button/Button';
import CheckBox from '../../components/input/CheckBox';
import UploadInput, { MappedFilesType } from '../../components/input/UploadInput';
import Dropdown from '../../components/Lang/Dropdown';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Spinner } from '../../design-system/Foundation';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import useExampleDownload from './hooks/use-example-download';
import css from './upload-dialog.module.scss';

type Props = {
  onComplete?: () => void;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refreshCounts?: () => void;
};

type State = {
  files: MappedFilesType[];
  fileFormat: FileFormatType | undefined;
  automaticProcessing: boolean;
};

const UploadImportJobDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { validationRef, onComplete, refreshCounts } = props;

  const { applicationStore, importJobStore } = useStore();
  const { getTranslation } = applicationStore;
  const { uploadImportJob } = importJobStore.importJobService;

  const { isLoading, downloadExample } = useExampleDownload();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    files: [],
    fileFormat: undefined,
    automaticProcessing: true
  });

  const validate = () => {
    if (validationRef.current) {
      const totalSize = state.files.reduce((acc: number, f) => (acc += f.file.size), 0);

      const fileIsToBig = totalSize > 28000000;

      if (fileIsToBig) {
        notify.warning({
          content: getTranslation('actions.import_jobs.file_size_warning')
        });
      }

      validationRef.current.setIsError(!state.fileFormat || state.files.length === 0 || fileIsToBig);
    }
  };

  useEffect(() => {
    validate();
  }, [state.fileFormat, state.files]);

  // Get fileformats
  const fileFormatsResponse = useSuspenseSingleAPI<PagedResponseType<FileFormatType>>({
    request: {
      endpoint: '/cfg/DataImportFileFormats'
    }
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (state.fileFormat) {
        const fileFormatId = state.fileFormat.id;

        try {
          if (state.fileFormat.settings.type === 'migration') {
            await uploadImportJob(state.files, fileFormatId, state.automaticProcessing);
          } else {
            await Promise.all(
              state.files.map((file) => uploadImportJob([file], fileFormatId, state.automaticProcessing))
            );
          }

          if (onComplete) onComplete();
          if (refreshCounts) refreshCounts();

          notify.success({
            content: getTranslation('actions.import_jobs.upload_success')
          });
        } catch (e) {
          notify.error({
            content: getTranslation('actions.import_jobs.upload_failed'),
            error: e
          });
        }
      }
    }
  }));

  if (!fileFormatsResponse.result)
    return (
      <div className={css['fileformat-container']}>
        <Spinner centered />
      </div>
    );

  const fileFormats = fileFormatsResponse.result.data.results;

  const fileFormatsDropdown = fileFormats.map((format) => ({
    value: format,
    text: format.name
  }));

  return (
    <div className={css['fileformat-container']}>
      <Dropdown
        id="dropdown-fileformat"
        selectedValues={[state.fileFormat?.id || '']}
        values={fileFormatsDropdown}
        onChange={(val) => setState({ fileFormat: val[0] })}
        placeholder={getTranslation('import_jobs.file_format')}
        error={!state.fileFormat}
      />
      {state.fileFormat && (
        <>
          {isLoading ? (
            <Spinner size="xsmall" />
          ) : (
            <Button id="file_formats.download" type="primary" onClick={() => downloadExample(state.fileFormat?.id)}>
              {getTranslation('actions.import_jobs.download_example')}
            </Button>
          )}
        </>
      )}
      <UploadInput id="upload-fileformat" files={state.files} setFiles={(files) => setState({ files })} />

      <CheckBox
        id="automatic-processing"
        checked={state.automaticProcessing}
        onChange={(val) => setState({ automaticProcessing: val })}
      >
        {getTranslation('data_provider.automaticProcessing')}
      </CheckBox>
    </div>
  );
});

export default observer(UploadImportJobDialog);
