import ContractLocationsColumn from 'components/Contract/ContractLocationsColumn/ContractLocationsColumn';
import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { customerType } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatPeriod } from '@zf/utils/src/date';

import useListPage from '../../../app-context/hooks/use-listpage';
import { Icon } from '../../../components/Icon';
import EmptyLink from '../../../components/Link/EmptyLink';
import TableServices from '../../../components/Services/TableServices';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import { getUniqueServicesForContract } from '../../../utils/contract';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import css from './contract-list-page.module.scss';

const useContractListPage = (customEntityPropertyTypes: CustomEntityPropertyType[]) => {
  const { applicationStore, contractStore } = useStore();
  const { rootUrl, getEnumTranslation, getTranslation } = applicationStore;
  const { getRightProduct } = contractStore;

  const processRecord = (value: ContractType): ContractRowType => {
    const iconName = 'customer';
    const product = getRightProduct(value.billingDetails.products, value);

    return {
      __id: value.id,
      __customerId: value.contractor.customerId,
      __etag: value._etag,
      __blocked: value.billingDetails.blocked,
      __contractEntity: value,
      __customEntityPropertyTypes: customEntityPropertyTypes,
      externalReference: value.externalContractReference,
      ContractNumber: (
        <EmptyLink id={`${routedEntitySubjectType.contract}-${value.id}`} url={`${rootUrl}/contracts/${value.id}`}>
          {value.contractNumber}
        </EmptyLink>
      ),
      contractor: (
        <Link
          id={`customer-${value.contractor.customerId}`}
          url={`${rootUrl}/customers/${value.contractor.customerId}`}
          icon={
            value.contractor.customerType && value.contractor.customerType === customerType.organization
              ? 'organisation'
              : iconName
          }
        >
          {`${value.contractor.accountNumber} - ${value.contractor.shortDisplayName}`}
        </Link>
      ),
      product: value.usePropertyGroupProduct ? (
        <Paragraph color={colors['silver-600']}>{getTranslation('contracts.prod_prop_lvl')}</Paragraph>
      ) : (
        product && <Paragraph id={`${value.id}-${product.productId}`}>{product.productName}</Paragraph>
      ),
      SupplyEndDate: formatPeriod(value.supplyStartDate, value.supplyEndDate),
      CurrentContractStatus: (
        <FlexElements>
          <Icon
            tooltipFor="contracts-table-tip"
            className={css['style-vertical-margin']}
            title={getEnumTranslation('contractStatus', value.currentContractStatus)}
            type={value.currentContractStatus}
            color
            usedInTable
          />
          {value.billingDetails.blocked && (
            <Icon
              tooltipFor="contracts-table-tip"
              className={css['style-vertical']}
              title={getTranslation('contracts.billing_block')}
              type="lock"
              color
              usedInTable
            />
          )}
        </FlexElements>
      ),
      __currentContractStatus: value.currentContractStatus,
      locations: <ContractLocationsColumn serviceLocations={value.serviceLocations} />,
      services: <TableServices utilityTypes={getUniqueServicesForContract(value)} />,
      ...getExtraCustomEntityColumns(value.customProperties, customEntityPropertyTypes, getTranslation)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<ContractType, ContractRowType>({
    endpoint: '/md/contracts',
    processRecord,
    domain: 'contract'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useContractListPage;
