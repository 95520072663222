import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus, updateContractProductParameter } from '@zf/api-types/enums';
import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { colors } from '@zf/utils/src/color';
import { formatDateWMonth, isMinDate, MIN_DATE } from '@zf/utils/src/date';

import DatePicker, { DatePickerProps } from '../../../../../components/Lang/DatePicker';
import Dropdown from '../../../../../components/Lang/Dropdown';
import RadioInput from '../../../../../design-system/Components/Inputs/RadioInput';
import { Spinner } from '../../../../../design-system/Foundation';
import Paragraph from '../../../../../design-system/Foundation/Paragraph/Paragraph';
import { useStore } from '../../../../../hooks/useStore';
import { State } from './change-product-dialog';
import css from './change-product-dialog.module.scss';
import CompletenessInvoiceStatus from './CompletenessInvoiceStatus';

type Props = {
  contract?: ContractType;
  selectedRows?: ContractRowType[];
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const InlineDatePicker = InlineInputField<DatePickerProps>(DatePicker);
const InlineDropdown = InlineInputField<DropdownProps<BillingCompletenessType>>(Dropdown);

const ChangeProductDatePicker = (props: Props) => {
  const { contract, selectedRows, state, setState } = props;
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { billingRelation_, billingCompletenessStore } = contractStore;

  const [billingCompletenesses, setBillingCompletenesses] = useState<BillingCompletenessType[] | undefined>();

  useEffect(() => {
    if (billingRelation_?.id) {
      billingCompletenessStore.completenessApiService
        .getBillingCompletenessesForBillingRelation(billingRelation_.id)
        .then((res) => {
          setBillingCompletenesses(res);
        });
    }
  }, [billingRelation_?.id]);

  if (contract && !billingCompletenesses) return <Spinner size="small" />;

  const billingCompletenessDropDown = billingCompletenesses?.map((bc) => ({
    value: bc,
    text: formatDateWMonth(bc.periodStartDateTime)
  }));

  const lastpaidinvoicePeriodOption = billingCompletenesses?.find(
    (bc) => bc.status === billingCompletenessStatus.closed || bc.status === billingCompletenessStatus.manuallyclosed
  )?.periodEndDateTime;

  return (
    <div className={css['date-selection-wrapper']}>
      <Paragraph className={css['change-products-title']} textAlign="left" bold>
        {getTranslation('contracts.choose_start')}
      </Paragraph>

      <div className={css['change-date-selection']}>
        <div className={css['change-date-selection-item']}>
          <RadioInput
            onChange={(option) => {
              setState(
                contract
                  ? {
                      date: {
                        date: contract.supplyStartDate,
                        changeProductOption: option as updateContractProductParameter
                      }
                    }
                  : {
                      date: {
                        ...state.date,
                        changeProductOption: option as updateContractProductParameter
                      }
                    }
              );
            }}
            value={updateContractProductParameter.fromcontractstartdate}
            name="change-date-selection"
            checked={state.date.changeProductOption === updateContractProductParameter.fromcontractstartdate}
          >
            <div className={css['flex-p']}>
              <Paragraph textAlign="left">{getTranslation('contracts.start_contract')}</Paragraph>
              {contract && (
                <Paragraph color={colors['silver-600']}>{`(${formatDateWMonth(contract.supplyStartDate)})`}</Paragraph>
              )}
            </div>
          </RadioInput>
        </div>

        {selectedRows || (contract && lastpaidinvoicePeriodOption) ? (
          <div className={css['change-date-selection-item']}>
            <RadioInput
              onChange={(option) =>
                setState(
                  lastpaidinvoicePeriodOption
                    ? {
                        date: {
                          date: lastpaidinvoicePeriodOption,
                          changeProductOption: option as updateContractProductParameter
                        }
                      }
                    : {
                        date: {
                          ...state.date,
                          changeProductOption: option as updateContractProductParameter
                        }
                      }
                )
              }
              value={updateContractProductParameter.fromfirstopenperiod}
              name="change-date-selection"
              checked={state.date.changeProductOption === updateContractProductParameter.fromfirstopenperiod}
            >
              <div className={css['flex-p']}>
                <Paragraph textAlign="left">{getTranslation('contracts.start_last_invoice_period')}</Paragraph>
                {contract && (
                  <Paragraph color={colors['silver-600']}>{`(${formatDateWMonth(
                    lastpaidinvoicePeriodOption
                  )})`}</Paragraph>
                )}
              </div>
            </RadioInput>
          </div>
        ) : null}

        {contract && (
          <>
            <div className={css['change-date-selection-item']}>
              <RadioInput
                onChange={(option) =>
                  setState({
                    date: {
                      date: MIN_DATE,
                      changeProductOption: option as updateContractProductParameter
                    },
                    billingCompleteness: undefined
                  })
                }
                value={updateContractProductParameter.billingperiodstartdate}
                name="change-date-selection"
                checked={state.date.changeProductOption === updateContractProductParameter.billingperiodstartdate}
              >
                <Paragraph textAlign="left">{getTranslation('contracts.select_billing_period')}</Paragraph>
              </RadioInput>
            </div>

            {state.date.changeProductOption === updateContractProductParameter.billingperiodstartdate && (
              <>
                <InlineDropdown
                  id="dropdown-billing-period"
                  selectedValues={[state.billingCompleteness ? state.billingCompleteness?.id : '']}
                  values={billingCompletenessDropDown || []}
                  onChange={(val) =>
                    setState({
                      billingCompleteness: val[0],
                      date: {
                        changeProductOption: updateContractProductParameter.billingperiodstartdate,
                        date: val[0]?.periodStartDateTime || MIN_DATE
                      }
                    })
                  }
                  placeholder={getTranslation('property_groups.tabs.billing.select_billing_period')}
                  extraRight={(b) => <CompletenessInvoiceStatus billingCompleteness={b} />}
                  error={
                    state.date.changeProductOption === updateContractProductParameter.billingperiodstartdate &&
                    !state.billingCompleteness?.id
                  }
                />
              </>
            )}
          </>
        )}

        <div className={css['change-date-selection-item']}>
          <RadioInput
            onChange={(option) =>
              setState({
                date: {
                  date: MIN_DATE,
                  changeProductOption: option as updateContractProductParameter
                }
              })
            }
            value={updateContractProductParameter.fromsupplieddate}
            name="change-date-selection"
            checked={state.date.changeProductOption === updateContractProductParameter.fromsupplieddate}
          >
            <Paragraph textAlign="left">{getTranslation('contracts.specific_date')}</Paragraph>
          </RadioInput>
        </div>

        {state.date.changeProductOption === updateContractProductParameter.fromsupplieddate && (
          <InlineDatePicker
            id="custom-date"
            onChange={(value) =>
              setState({
                date: {
                  changeProductOption: updateContractProductParameter.fromsupplieddate,
                  date: value.toISOString()
                }
              })
            }
            value={moment(state.date.date)}
            placeholder={getTranslation('general.date')}
            error={
              state.date.changeProductOption === updateContractProductParameter.fromsupplieddate &&
              (isMinDate(state.date.date) || !state.date.date)
            }
          />
        )}
      </div>
    </div>
  );
};

export default ChangeProductDatePicker;
