import {
  LocalTemplateAttachmentType,
  TemplateAttachment,
  UpdateScenarioRequestType
} from '@zf/api-types/config/scenarios_new';
import { communicationType, envelopePosition } from '@zf/api-types/enums';

import BaseFormVjf, { Fields } from '../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../events/notification-events';
import CommunicationStore from '../stores/CommunicationStore';

export enum templateFormFields {
  subject = 'subject',
  defaultCommunicationType = 'defaultCommunicationType',
  costAllocation = 'costAllocation',
  showCountry = 'showCountry',
  showBalance = 'showBalance',
  showVatSpecs = 'showVatSpecs',
  marginPosition = 'marginPosition',
  margin = 'margin',
  attachments = 'attachments',
  marginTop = 'marginTop'
}

interface TemplateFormFieldTypes {
  subject: string;
  defaultCommunicationType: string;
  costAllocation: boolean;
  showCountry: boolean;
  showBalance: boolean;
  showVatSpecs: boolean;
  marginPosition: string;
  margin: number;
  marginTop: number;
  attachments: LocalTemplateAttachmentType[];
}

class TemplateForm extends BaseFormVjf<TemplateFormFieldTypes> {
  public communicationStore: CommunicationStore;

  constructor(communicationStore: CommunicationStore, initialValues: TemplateFormFieldTypes) {
    super(initialValues);
    this.communicationStore = communicationStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: templateFormFields.subject,
          label: 'subject',
          validators: [this.isRequired]
        },
        {
          name: templateFormFields.defaultCommunicationType,
          label: 'defaultCommunicationType'
        },
        {
          name: templateFormFields.costAllocation,
          label: 'costAllocation',
          type: 'checkbox'
        },
        {
          name: templateFormFields.showCountry,
          label: 'showCountry',
          type: 'checkbox'
        },
        {
          name: templateFormFields.showBalance,
          label: 'showBalance',
          type: 'checkbox'
        },
        {
          name: templateFormFields.showVatSpecs,
          label: 'showVatSpecs',
          type: 'checkbox'
        },
        {
          name: templateFormFields.marginPosition,
          label: 'marginPosition'
        },
        {
          name: templateFormFields.margin,
          label: 'margin'
        },
        {
          name: templateFormFields.marginTop,
          label: 'marginTop'
        },
        {
          name: templateFormFields.attachments,
          label: 'attachments'
        }
      ]
    };
  }

  save = async () => {
    const { getTranslation } = this.communicationStore.rootStore.applicationStore;
    const { postAttachmentsForScenario, updateScenario } = this.communicationStore.communicationService;
    const { selectedEntitySubjectType, selectedEntitySubjectSubtype, initScenario } =
      this.communicationStore.templatesStore;

    try {
      const {
        subject,
        defaultCommunicationType,
        costAllocation,
        showCountry,
        showBalance,
        showVatSpecs,
        marginPosition,
        margin,
        marginTop,
        attachments
      } = this._values;

      // Post any attachments first
      const newAttachments = await postAttachmentsForScenario(attachments, this._backup.attachments);

      const apiFriendlyValues: UpdateScenarioRequestType = {
        pdfTemplate: {
          costAllocation,
          showCountry,
          showBalance,
          showVatSpecs,
          envelopeSettings: {
            marginPosition: marginPosition as envelopePosition,
            margin: margin || 0,
            marginTop: marginTop || 0
          }
        },
        emailTemplate: {
          subject,
          attachments: newAttachments as TemplateAttachment[]
        },
        defaultCommunicationType: defaultCommunicationType as communicationType
      };

      const newScenario = await updateScenario(
        selectedEntitySubjectType,
        selectedEntitySubjectSubtype,
        apiFriendlyValues
      );

      initScenario(newScenario);

      notify.success({
        content: getTranslation('communication.update_scenario_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('communication.update_scenario_fail'),
        error: e
      });
    }
  };

  hooks() {
    return {
      onSuccess(form: TemplateForm) {
        form.save();
      }
    };
  }
}

export default TemplateForm;
