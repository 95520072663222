import moment from 'moment';
import React from 'react';

import { CustomEntityPropertyValue } from '@zf/api-types/config/custom-entity-property-types';
import { customEntityPropertyValueType } from '@zf/api-types/enums';
import { isMaxDate, isMinDate } from '@zf/utils/src/date';

import { InlineFloatInput } from '../../components/input/FloatInput';
import { InlineInputFieldInput } from '../../components/input/InputField';
import { InlineNumberInput } from '../../components/input/NumberInput';
import DatePicker from '../../components/Lang/DatePicker';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { useStore } from '../../hooks/useStore';
import { AggregatedCustomEntityProperty } from './CustomEntityPropertiesCard';

type Props = {
  index: number;
  valueIndex: number;
  value: CustomEntityPropertyValue;
  entityProperty: AggregatedCustomEntityProperty;
  handleSave: () => void;
  setCustomProperty: (
    customPropertyIndex: number,
    valueIndex: number,
    value: Partial<CustomEntityPropertyValue>
  ) => void;
};

export default function TableValueInput(props: Props) {
  const { index, valueIndex, value, entityProperty, handleSave, setCustomProperty } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  if (!entityProperty.type) return null;

  const dataType = entityProperty.type.valueType.type;

  if (dataType === customEntityPropertyValueType.number) {
    return (
      <InlineNumberInput
        id={`entity_property.value_number.index-${index}-${valueIndex}`}
        onChange={(valueNumber) => setCustomProperty(index, valueIndex, { valueNumber })}
        value={value.valueNumber}
        onKeyDown={keyHandler}
        error={typeof value.valueNumber !== 'number'}
      />
    );
  }

  if (dataType === customEntityPropertyValueType.decimal) {
    return (
      <InlineFloatInput
        id={`entity_property.value_decimal.index-${index}-${valueIndex}`}
        onChange={(valueDecimal) => setCustomProperty(index, valueIndex, { valueDecimal })}
        value={value.valueDecimal}
        onKeyDown={keyHandler}
        error={typeof value.valueDecimal !== 'number'}
        isCEP
      />
    );
  }

  if (dataType === customEntityPropertyValueType.boolean) {
    const dropdownValues = [
      { value: true, text: getTranslation('general.yes') },
      { value: false, text: getTranslation('general.no') }
    ];

    return (
      <SimpleDropdown
        id={`entity_property.value_boolean.index-${index}-${valueIndex}`}
        values={dropdownValues}
        selectedValues={[value.valueBoolean]}
        onChange={(val) => setCustomProperty(index, valueIndex, { valueBoolean: val[0] || false })}
        error={typeof value.valueBoolean !== 'boolean'}
      />
    );
  }

  if (dataType === customEntityPropertyValueType.datetime) {
    return (
      <DatePicker
        id={`entity_property.value_dateTime.index-${index}-${valueIndex}`}
        value={moment(value.valueDateTime)}
        onChange={(val) => setCustomProperty(index, valueIndex, { valueDateTime: val.toISOString() })}
        error={isMinDate(value.valueDateTime) || isMaxDate(value.valueDateTime)}
      />
    );
  }

  return (
    <InlineInputFieldInput
      id={`entity_property.value_string.index-${index}-${valueIndex}`}
      onChange={(valueString) => setCustomProperty(index, valueIndex, { valueString })}
      value={value.valueString}
      onKeyDown={keyHandler}
      error={!value.valueString}
    />
  );
}
