import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import {
  FormulaFunctionExceptionType,
  OutputChannelType,
  PropertyMeteringConfigurationType
} from '@zf/api-types/property-metering-configuration';

type State = {
  propertyMeteringConfiguration: PropertyMeteringConfigurationType | undefined;
  calculationErrors: FormulaFunctionExceptionType[];
  selectedOutputChannel: OutputChannelType | undefined;
  didFetch: boolean[]; // Boolean array of our executed API calls
};

type Action =
  | { type: 'SET_PROPERTY_METERING_CONFIG'; config: PropertyMeteringConfigurationType; isFetch: boolean }
  | { type: 'SET_SELECTED_OUTPUT_CHANNEL'; channel: OutputChannelType }
  | { type: 'SET_CALC_ERRORS'; calculationErrors: FormulaFunctionExceptionType[] };

const initialState: State = {
  propertyMeteringConfiguration: undefined,
  selectedOutputChannel: undefined,
  calculationErrors: [],
  didFetch: []
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // Fetch actions
    case 'SET_PROPERTY_METERING_CONFIG': {
      return action.isFetch
        ? {
            ...state,
            propertyMeteringConfiguration: action.config,
            didFetch: [...state.didFetch, true]
          }
        : {
            ...state,
            propertyMeteringConfiguration: action.config,
            // Refresh selected channel (render backend validation)
            selectedOutputChannel: action.config.outputChannels.find(
              (oChann) => oChann.id === state.selectedOutputChannel?.id
            )
          };
    }

    // Set actions
    case 'SET_SELECTED_OUTPUT_CHANNEL':
      return {
        ...state,
        selectedOutputChannel: action.channel
      };

    case 'SET_CALC_ERRORS':
      return {
        ...state,
        calculationErrors: action.calculationErrors
      };

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider: MeteringProvider, useTracked, useUpdate: useDispatch } = createContainer(useValue);
