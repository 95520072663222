import classNames from 'classnames';
import React, { Suspense } from 'react';

import { Router } from '@reach/router';
import { TenantOrganisationType } from '@zf/api-types/auth';
import Center from '@zf/stella-react/src/helpers/Center';

import { ErrorBoundary } from '../components/Error';
import { Loader } from '../components/Loader';
import { Sidebar } from '../components/Sidebar';
import CollectionCase from './collection-case/detail-page/CollectionCase';
import CollectionCases from './collection-case/list-page/collection-cases';
import CommunicationConfig from './communication-config/CommunicationConfig';
import TemplateTranslationsTab from './communication-config/templates/template-translations/TemplateTranslationsTab';
import Contract from './contract/src/detail-page/Contract';
import Contracts from './contract/src/list-page/Contracts';
import MoveInWizardBase from './contract/src/wizard/move-in/MoveInWizardBase';
import MoveOutWizardBase from './contract/src/wizard/move-out/MoveOutWizardBase';
import CostAllocationConfig from './cost-allocation-config/CostAllocationConfig';
import CountrySpecificsConfig from './country-specifics/config/CountrySpecificsConfig';
import SocialTariffListPage from './country-specifics/social-tariffs/social-tariffs';
import CustomersCustomerGroups from './customer/list-page/CustomersCustomerGroups';
import css from './dashboard-router.module.scss';
import Dashboard from './dashboard/Dashboard';
import Devices from './devices/Devices';
import ExportJobsConfig from './export-jobs-config/export-jobs-config';
import ExportJob from './export-jobs/detail-page/export-job';
import ExportJobs from './export-jobs/list-page/export-jobs';
import ImportJobsConfig from './import-jobs-config/import-jobs-config';
import ImportJob from './import-jobs/detail-page/detail/import-job';
import ImportJobs from './import-jobs/list-page/import-jobs';
import IntegrationLoggingConfig from './integration-logging-config/IntegrationLoggingConfig';
import Invoice from './invoice/src/detail/Invoice';
import Invoices from './invoice/src/list-page/Invoices';
import AddInvoice from './invoice/src/wizard/AddInvoice';
import Layout from './Layout';
import LocationDetailPage from './location/detail-page/LocationDetailPage';
import Locations from './location/list-page/Locations';
import AddLocation from './location/wizard/AddLocation';
import MeterConfig from './meter-config/MeterConfig';
import Payments from './payment/list-page/Payments';
import PaymentsConfig from './payments-config/payments-config';
import PortalConfig from './portal-config/CustomerPortalConfig';
import ProductConfig from './product-config/ProductConfig';
import PropertyGroup from './property-group/detail-page/PropertyGroup';
import PropertyGroups from './property-group/list-page/property-groups';
import AddPropertyGroup from './property-group/wizard/add-property-group';
import Route from './route';
import SettingsConfig from './settings-config/settings-config';
import TaskWrapper from './tasks/detail-page/TaskWrapper';
import Tasks from './tasks/list-page/tasks';

type Props = {
  location: Location;
  organization: TenantOrganisationType;
  splittedHref: string[];
};

export default function DashboardRouterContent(props: Props) {
  const { location, splittedHref } = props;

  const currPath = location.pathname.split('/').splice(3).join('/');

  return (
    <div className={css['dashboard']}>
      <Sidebar currPath={currPath} />

      <div className={css['content-wrapper']}>
        <div
          className={classNames(css['container'], {
            [css['scrollable']]: splittedHref.some((s) => s.startsWith('add') || s.startsWith('install'))
          })}
        >
          <ErrorBoundary>
            <Suspense
              fallback={
                <Center type="both">
                  <Loader />
                </Center>
              }
            >
              <Router>
                <Layout path="/">
                  <Route Component={Dashboard} default />
                </Layout>
                <Layout path="locations">
                  <Route Component={Locations} default />
                  <Route Component={AddLocation} path="add" />
                  <Route Component={LocationDetailPage} path=":locationID/*" />
                </Layout>
                <Layout path="property-groups">
                  <Route Component={PropertyGroups} default />
                  <Route Component={AddPropertyGroup} path="add" />
                  <Route Component={PropertyGroup} path=":propertyGroupID/*" />
                </Layout>
                <Layout path="customers">
                  <Route Component={CustomersCustomerGroups} default />
                </Layout>
                <Layout path="devices">
                  <Route Component={Devices} default />
                </Layout>
                <Layout path="contracts">
                  <Route Component={Contracts} default />
                  <Route Component={Contract} path=":contractId/*" />
                  <Route Component={MoveInWizardBase} path="add" />
                  <Route Component={MoveInWizardBase} path="add/:customerId" />
                  <Route Component={MoveOutWizardBase} path="terminate" />
                </Layout>
                <Layout path="invoices">
                  <Route Component={Invoices} default />
                  <Route Component={AddInvoice} path="add" />
                  <Route Component={AddInvoice} path="add/:customerId" />
                  <Route Component={Invoice} path=":invoiceID/*" />
                </Layout>
                <Layout path="collection-cases">
                  <Route Component={CollectionCases} default />
                  <Route Component={CollectionCase} path=":collectionCaseId/*" />
                </Layout>
                <Layout path="payments">
                  <Route Component={Payments} default />
                </Layout>
                <Layout path="customers">
                  <Route Component={CustomersCustomerGroups} default />
                </Layout>
                <Layout path="import-jobs">
                  <Route Component={ImportJobs} default />
                  <Route Component={ImportJob} path=":importJobId/*" />
                </Layout>
                <Layout path="export-jobs">
                  <Route Component={ExportJobs} default />
                  <Route Component={ExportJob} path=":exportJobId/*" />
                </Layout>
                <Layout path="tasks">
                  <Route Component={Tasks} default />
                  <Route Component={TaskWrapper} path=":taskId/*" />
                </Layout>
                <Layout path="country-specifics/*">
                  <Route Component={SocialTariffListPage} default />
                </Layout>
                <Layout path="configuration">
                  <Route Component={SettingsConfig} default />
                  <Route Component={MeterConfig} path="meter/*" />
                  <Route Component={ProductConfig} path="product/*" />
                  <Route Component={PaymentsConfig} path="payments/*" />
                  <Route Component={CostAllocationConfig} path="cost-allocation/*" />
                  <Route Component={ImportJobsConfig} path="import-jobs/*" />
                  <Route Component={ExportJobsConfig} path="export-jobs/*" />
                  <Route Component={CommunicationConfig} path="communication/*" />
                  <Route Component={IntegrationLoggingConfig} path="integration-logging/*" />
                  <Route Component={PortalConfig} path="portal-config/*" />
                  <Route Component={CountrySpecificsConfig} path="country-specifics/*" />
                  <Route Component={TemplateTranslationsTab} path=":useCase/:type/:subType/*" />
                </Layout>
              </Router>
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
