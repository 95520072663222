import useDeviceTabs from 'features/devices/shared/hooks/useDeviceTabs';
import { observer } from 'mobx-react';
import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { colors } from '@zf/utils/src/color';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import useRefresh from '../../../../app-context/hooks/use-refresh';
import PropertyGroupAutoFill, {
  PropertyGroupAutofillProps
} from '../../../../components/Autofills/PropertyGroupAutoFill';
import MeterExport from '../../../../components/Button/Excel/MeterExport';
import AddMeasurementAction from '../../../../components/Button/Meter/AddMeasurementListPageButton';
import ExportMeasurementsListPageButton from '../../../../components/Button/Meter/ExportMeasurementsListPageButton';
import ExportMeterConsumptionsListPageButton from '../../../../components/Button/Meter/ExportMeterConsumptionsListPageButton';
import InstallMeterListPageButton from '../../../../components/Button/Meter/InstallMeterListPageButton';
import ReconfigMeterListPageButton from '../../../../components/Button/Meter/ReconfigMeterListPageButton';
import UninstallMeterListPageButton from '../../../../components/Button/Meter/UninstallMeterListPageButton';
import FilterBar from '../../../../components/Filters/FilterBar';
import { Icon } from '../../../../components/Icon';
import DateRangePicker from '../../../../components/input/DateRangePicker';
import EnumFilterSelect from '../../../../components/input/EnumFilterSelect';
import { useStore } from '../../../../hooks/useStore';
import useMeterFilter from '../hooks/useMeterFilter';
import { CreateMutingRuleListPageButton } from 'components/Button/Meter/CreateMutingRuleListPageButton';

type Props = {
  search?: string;
  extraColumnTitles: string[];
};

const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);

const MetersFilterBar = (props: Props) => {
  const { extraColumnTitles, search } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const tabItems = useDeviceTabs(applicationStore, meterStore);

  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    filterTags,
    onSearch,
    onFilter,
    setQueryParams,
    clearQueryParams,
    refreshCounts
  } = useMeterFilter(search);

  const { refresh } = useRefresh();

  return (
    <FilterBar
      refreshPage={refresh}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      tabItems={tabItems}
      defaultTab="meters"
      actions={[
        <AddMeasurementAction key="addMeasurement" />,
        <InstallMeterListPageButton key="installMeter" />,
        <UninstallMeterListPageButton key="uninstallMeter" refreshCounts={refreshCounts} />,
        <ReconfigMeterListPageButton key="reconfigureMeter" />,
        <CreateMutingRuleListPageButton useCase="list-page" key="createMutingRule"  refresh={refresh}/>,
        <ExportMeasurementsListPageButton key="measurementsExport" />,
        <ExportMeterConsumptionsListPageButton key="consumptionsExport" />,
        <MeterExport key="meterExport" extraColumnTitles={extraColumnTitles} />
      ]}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="meter" />}
          count={overviewCount.all}
          title={getTranslation('metering_list.filter.all_meters')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          id="installed"
          key="installed"
          onTrigger={() => onFilter('installed')}
          icon={<Icon type="meter" />}
          count={overviewCount.installed}
          title={getTranslation('metering_list.filter.installed_meters')}
          activeColor={colors['green-600']}
          active={quickFilter === 'installed'}
        />,
        <FilterButton
          id="inactive"
          key="inactive"
          onTrigger={() => onFilter('inactive')}
          icon={<Icon type="meter" />}
          count={overviewCount.inactive}
          title={getTranslation('metering_list.filter.inactive_meters')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'inactive'}
        />
      ]}
      filterInputs={[
        <Label key="label-property-group">{getTranslation('property_groups.property_group')}</Label>,
        <InlinePropertyGroupAutoFill
          id="by_property_group"
          key="by_property_group"
          onChange={(val) =>
            setQueryParams({
              propertyGroupIds: val[0] ? [val[0].id] : [],
              propertyGroupName: val[0]?.name || ''
            })
          }
          selectedValues={[
            queryParams.propertyGroupIds && queryParams.propertyGroupIds[0] ? queryParams.propertyGroupIds[0] : ''
          ]}
          renderTopLvl={false}
          showDetails={false}
        />,
        <ListDivider key="divider-0" />,
        <EnumFilterSelect
          id="meter-type-filter"
          key="meter-type-filter"
          title={getTranslation('general.type')}
          options={getEnum<meterType>('meterType')}
          values={queryParams.meterType || []}
          setValue={(value) => setQueryParams({ meterType: value })}
        />,
        <ListDivider key="divider-1" />,
        <Label key="label-period">{getTranslation('meter.last-time-received')}</Label>,
        <DateRangePicker
          id="period-filter"
          key="period-filter"
          startDate={convertToMoment(queryParams.receivedMeteringDataStart)}
          endDate={convertToMoment(queryParams.receivedMeteringDataEnd)}
          setDates={(dates) =>
            setQueryParams({
              receivedMeteringDataStart: convertMomentToISOString(dates[0]) as string,
              receivedMeteringDataEnd: convertMomentToISOString(dates[1]) as string
            })
          }
          renderTopLvl={false}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('meter.search_placeholder')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
};

export default observer(MetersFilterBar);
