import classNames from 'classnames';
import Interweave from 'interweave';
import React from 'react';

import { ErrorDataType, ZFErrorType } from '@zf/api-types/general';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { LangReturnValue } from '../../app-context/hooks/use-lang';
import logError from '../../utils/handleError';
import CardError from './CardError';
import css from './error-boundary-class.module.scss';
import PageError from './PageError';

export type ErrorProps = {
  children: JSX.Element;
  cardError?: boolean;
  rootUrl?: string;
};

type ErrorState = {
  error: ZFErrorType | null;
};

const ERROR_CODES = [400, 401, 403, 404, 405, 500];

export const getErrorTitle = (error: ZFErrorType, i18n: LangReturnValue) => {
  let title = '';

  if (error.status) {
    const statusTranslation = i18n.getTranslation(`errors.status.${error.status}`);
    title = `Status ${error.status} - ${statusTranslation}`;
  } else if (typeof error.data !== 'string' && error.data && error.data.title) {
    title = error.data.title;
  } else {
    title = i18n.getTranslation('errors.error_occured');
  }

  return title;
};

export default class ErrorBoundaryClass extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidCatch(error: ZFErrorType, info: any) {
    this.setState({
      error: error
    });
    logError(error, info);
  }

  render() {
    const { children, cardError } = this.props;
    const { error } = this.state;

    let errorImage = null;

    if (error) {
      let valueContent: JSX.Element[] = [];

      if (error.data) {
        const castedData = error.data as ErrorDataType;

        valueContent = castedData.errors
          ? castedData.errors.map((e, index) => {
              let content = `<h2>${e.message}:</h2>`;

              if (e.value) {
                if (e.value.includes(';')) {
                  const infoParts = e.value.split(';');
                  infoParts.forEach((ip) => {
                    content += `<b>${ip}</b><br/>`;
                  });
                } else {
                  const extraInfo = `: <b>${e.value}</b>`;
                  content += extraInfo;
                }
              }

              return (
                <Paragraph key={index}>
                  <Interweave content={content} />
                </Paragraph>
              );
            })
          : [];
      }

      if (error.status && ERROR_CODES.includes(error.status) && error.status !== 400) {
        const status = error.status.toString();

        errorImage = (
          <img
            className={classNames({ [css['error-image']]: cardError })}
            src={`https://cdn.zerofriction.co/shared/assets/errorCodes/${status}.svg`}
            alt={status}
            title={status}
          />
        );
      } else {
        errorImage = (
          <img
            className={classNames({ [css['error-image']]: cardError })}
            src={'https://cdn.zerofriction.co/shared/assets/general_error.svg'}
            alt="error"
            title="error"
          />
        );
      }

      return cardError ? (
        <CardError error={error} errorImage={errorImage} valueContent={valueContent} />
      ) : (
        <PageError error={error} errorImage={errorImage} valueContent={valueContent} />
      );
    }

    return children;
  }
}
