import { AttachmentType } from '@zf/api-types/attachment';
import { entitySubjectType, uiCulture } from '@zf/api-types/enums';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';

export async function submitAttachment(
  formData: FormData,
  subjectType: entitySubjectType,
  subjectId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<AttachmentType>({
      request: {
        method: METHODS.POST,
        endpoint: `/att/attachments/${subjectType}/${subjectId}`,
        data: formData
      },
      tenantReducer,
      lang
    })
  ).data;
}
