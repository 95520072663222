import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';

import { useAppContext } from '../../../../../../app-context';
import DataGridActions from '../../../../../../components/DataGridCard/DataGridActions';
import DataGridCardHeaderSection from '../../../../../../components/DataGridCard/DataGridCardHeaderSection';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { DANGER } from '../../../../../../constants/color';
import { TabSlider } from '../../../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../../../design-system/Components/TabSlider/Tab';
import NoMutations from '../../../mutations-shared/NoMutations';
import { OutgoingMutationQuickFilterType } from '../context/outgoing-transaction-detail-context';
import useOutgoingMutationsFilter from './hooks/useOutgoingMutationsFilter';
import useOutgoingMutationsTable from './hooks/useOutgoingMutationsTable';
import AddEntryDetailButton from './outgoing-mutation-actions/AddEntryDetailButton';
import DeleteDetailButton from './outgoing-mutation-actions/DeleteDetailButton';
import EditEntryDetailButton from './outgoing-mutation-actions/EditEntryDetailButton';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  refresh: () => void;
};

export default function OutgoingMutationsTableSection(props: Props) {
  const { outgoingBankingTransaction, refresh: refreshDetailEntity } = props;
  const { i18n } = useAppContext();

  const { setQueryParams } = useOutgoingMutationsFilter();

  const {
    selectedIds,
    sortBy,
    sortDirection,
    sortableFields,
    loading,
    error,
    rows,
    queryParams,
    overviewCounts,
    setSelectedIds,
    handleSort,
    setStopIndex,
    refresh: refreshTable
  } = useOutgoingMutationsTable(outgoingBankingTransaction);

  const tabItems = React.useMemo(() => {
    const tabs: SelectionTabItemType<Record<string, any>>[] = [
      { id: 'all', title: i18n.getTranslation('general.all'), count: overviewCounts.all },
      { id: 'invoices', title: i18n.getTranslation('invoice.invoices'), count: overviewCounts.invoices },
      {
        id: 'manualentries',
        title: i18n.getTranslation(`outgoing_mutations.manual_entries_${outgoingBankingTransaction.type}`),
        count: overviewCounts.manualEntries
      }
    ];

    if (overviewCounts.resolveIssues > 0) {
      tabs.push({
        id: 'resolveissues',
        title: i18n.getTranslation('banking_transaction.issues'),
        count: overviewCounts.resolveIssues,
        color: DANGER
      });
    }

    return tabs;
  }, [overviewCounts, outgoingBankingTransaction]);

  const [columns] = React.useState([
    {
      width: 400,
      label: i18n.getTranslation('general.type'),
      dataKey: 'type'
    },
    {
      width: 400,
      label: i18n.getTranslation('payments.customer_iban'),
      dataKey: 'customerBankAccount'
    },
    {
      width: 400,
      label: i18n.getTranslation('general.amount_valuta'),
      dataKey: 'amount'
    },
    {
      width: 400,
      label: i18n.getTranslation('general.status'),
      dataKey: 'status'
    }
  ]);

  const refreshAll = React.useCallback(() => {
    refreshDetailEntity();
    refreshTable();
  }, []);

  const setSelectedItem = React.useCallback((itemId: string) => {
    setQueryParams({ quickFilter: itemId.toLowerCase() as OutgoingMutationQuickFilterType });
  }, []);

  if (!outgoingBankingTransaction) return null;

  const selectedRows = rows.filter((r) => {
    return selectedIds.includes(r.__id);
  });

  const noItemsOverlay = () => {
    return <NoMutations type={outgoingBankingTransaction.type} />;
  };

  return (
    <>
      <DataGridCardHeaderSection>
        <TabSlider
          id="linked-entities"
          tabItems={tabItems}
          selectedTabItem={queryParams.quickFilter}
          setSelectedItem={setSelectedItem}
        />
        <DataGridActions>
          <AddEntryDetailButton
            outgoingBankingTransaction={outgoingBankingTransaction}
            selectedRows={selectedRows}
            refresh={refreshAll}
          />
          <EditEntryDetailButton
            outgoingBankingTransaction={outgoingBankingTransaction}
            selectedRows={selectedRows}
            refresh={refreshAll}
          />
          <DeleteDetailButton selectedRows={selectedRows} refresh={refreshAll} setSelectedIds={setSelectedIds} />
        </DataGridActions>
      </DataGridCardHeaderSection>

      <DynamicVirtualTable
        id="mutations-table"
        tooltipId="mutations-table-tip"
        onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
        rows={rows}
        onSelectRow={setSelectedIds}
        selectedRows={selectedIds}
        loading={loading}
        error={error}
        NoDataOverlay={noItemsOverlay}
        totalAmountOfRows={rows.length}
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        columns={columns}
        singleSort
      />
    </>
  );
}
