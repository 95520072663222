import { useAppContext } from 'app-context';
import clone from 'clone';
import { MultiValue } from 'design-system/ComponentSets';
import React from 'react';

import { CollectionFlowStepType } from '@zf/api-types/collection-cycles';

import { StepValidatorType } from '../step-wizard/step-wizard';
import AddStep, { generateStepInitialValues } from './add-step';

type Props = {
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  updateDialogSteps: (newStep: CollectionFlowStepType) => void;
  setStep: (step: StepValidatorType, index: number) => void;
};

export default function AddStepsMultiValue(props: Props) {
  const { setStep: setCycleStep, addStep, updateDialogSteps } = props;

  const { i18n } = useAppContext();

  const initialNodeValue: Partial<StepValidatorType> = generateStepInitialValues();

  return (
    <MultiValue
      id="add-step-multi-value"
      title={i18n.getTranslation('collection_flows.add_steps_for_flow')}
      initialValues={[clone(initialNodeValue)]}
      intialNodeValue={clone(initialNodeValue)}
      onChange={() => {}}
    >
      {({ index, dispatchValue }) => {
        return (
          <AddStep
            key={`stepwizard-${index}`}
            stepNumber={index + 1}
            setStep={(val) => dispatchValue(val)}
            addStep={addStep}
            setCycleStep={setCycleStep}
            updateDialogSteps={updateDialogSteps}
          />
        );
      }}
    </MultiValue>
  );
}
