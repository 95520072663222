import { observer } from 'mobx-react';
import React from 'react';

import StellaDropdown, {
  DropdownGenericBaseType,
  DropdownProps
} from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import { useStore } from '../../hooks/useStore';
import ResetIcon from '../Icon/ResetIcon';
import css from './dropdown.module.scss';

const Dropdown = <T extends DropdownGenericBaseType>(props: DropdownProps<T>) => {
  const {
    id,
    dropdownTools,
    details,
    selectedValues,
    values,
    clearText,
    emptyText,
    clear,
    resetValue,
    reset,
    setDropdownTools,
    onChange,
    onClear
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      <StellaDropdown
        {...props}
        id={id}
        dropdownTools={dropdownTools}
        details={details}
        values={values}
        selectedValues={selectedValues}
        clearText={clearText || getTranslation('dropdown.clear')}
        emptyText={emptyText || getTranslation('dropdown.empty')}
        onChange={onChange}
        setDropdownTools={setDropdownTools}
        onClear={onClear}
        clear={clear}
      />

      {reset && typeof resetValue !== 'undefined' && resetValue !== selectedValues[0] && (
        <div className={css['reset']}>
          <ResetIcon id={`${id}-reset`} onClick={reset} />
        </div>
      )}
    </>
  );
};

export default observer(Dropdown);
