import classNames from 'classnames';
import React from 'react';

import ConfirmationActionButton, {
  ConfirmationActionButtonProps
} from '../../../components/Button/ConfirmationActionButton';
import { Link } from '../../Components';
import Button, { ButtonProps } from '../../Components/Button/Button';
import { LinkProps } from '../../Components/Link/BaseLink';
import css from './dropdown-action.module.scss';

export type DropdownActionType = 'direct' | 'dialog' | 'link';

export type DropdownActionProps = {
  actionType?: DropdownActionType;
  disabled?: boolean;
  actionTitle?: JSX.Element | null;
};

export default function DropdownAction(
  props: DropdownActionProps & (Partial<ConfirmationActionButtonProps> | Partial<LinkProps>)
) {
  const { actionType = 'dialog', disabled, actionTitle } = props;

  let component: React.ReactNode = null;

  if (actionType === 'direct') {
    const castedProps = props as ButtonProps;
    component = <Button {...castedProps} />;
  } else if (actionType === 'dialog') {
    const castedProps = props as ConfirmationActionButtonProps;
    component = <ConfirmationActionButton {...castedProps} actionTitle={actionTitle} type="text" />;
  } else {
    const castedProps = props as LinkProps;
    component = <Link {...castedProps} />;
  }

  return (
    <li
      className={classNames(css['dropdown-action'], {
        [css['disabled']]: disabled,
        [css['max-width']]: actionType !== 'link'
      })}
    >
      {component}
    </li>
  );
}
