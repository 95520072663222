import React from 'react';

import { ContractRowType, ContractType, DeleteContractType } from '@zf/api-types/master-data/contract';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  selectedRows?: Array<ContractRowType>;
  contractToHandle?: DeleteContractType;
  onComplete?: (updatedRows: ContractType[], deletedRows: DeleteContractType[]) => void;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, contractToHandle, onComplete, refreshCounts } = props;
  const { i18n } = useAppContext();
  const { contractStore } = useStore();

  let showWarning = false;
  let filtered: ContractRowType[] | undefined;

  if (selectedRows && selectedRows.length > 1) {
    filtered = selectedRows.filter((contract) => {
      return contract.__currentContractStatus === 'draft' || !contract.__contractEntity.blockedForDeletion;
    });

    showWarning = filtered.length !== selectedRows.length;
  }

  let contractsToHandle: Array<DeleteContractType> = [];

  if (selectedRows && !showWarning) {
    contractsToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (filtered && showWarning) {
    contractsToHandle = filtered.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (contractToHandle) {
    contractsToHandle = [contractToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (contractsToHandle.length > 0) {
        try {
          await Promise.all(contractsToHandle.map((contract) => contractStore.deleteContract(contract.id)));
          if (onComplete) onComplete([], contractsToHandle);
          if (refreshCounts) refreshCounts();

          notify.success({
            content: i18n.getTranslation('actions.contract.delete_success')
          });

          if (showWarning) {
            notify.warning({
              content: i18n.getTranslation('actions.contract.delete_multi_warning')
            });
          }
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.contract.delete_failed'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation('actions.contract.delete', { amount: contractsToHandle.length.toString() })}
    </Paragraph>
  );
});
