import React from 'react';

import { contactType, culture } from '@zf/api-types/enums';
import { CustomerDetailsType } from '@zf/api-types/master-data/move-request';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../../../../app-context/app-context';
import useCultureTable from '../../../../../../app-context/hooks/use-culture-table';
import { Icon } from '../../../../../../components/Icon';
import InputField from '../../../../../../components/input/InputField';
import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import useActivities from '../../hooks/useActivities';
import css from './customer-wiz.module.scss';

export type ContactDetailsBaseType = {
  emailAddress: string;
  telephoneNumber: string;
  mobileTelephoneNumber: string;
  website: string;
  culture: culture | null;
};

type Props = {
  customerDetails: CustomerDetailsType;
};

const WizardLangDropdown = WizardInputWrapper<SimpleDropdownProps<culture>>(SimpleDropdown);

export default function Contactdetails(props: Props) {
  const { customerDetails } = props;
  const { deviatingLanguage, setDeviatingLanguage, setCustomerDetail } = useActivities();
  const { cultureTable } = useCultureTable();
  const { i18n, enumReducer } = useAppContext();

  if (!cultureTable) return null;

  return (
    <>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('tasks.contact_details_heading')}
      </Heading>
      <InputContainer grid={false}>
        <CheckBox
          id="deviating-lang"
          className={css['lang-checkbox']}
          checked={deviatingLanguage}
          onChange={setDeviatingLanguage}
        >
          {i18n.getTranslation('customer.deviating_lang')}
        </CheckBox>

        {deviatingLanguage && (
          <WizardLangDropdown
            id="dropdown-language"
            onChange={(val) => setCustomerDetail({ culture: val[0] })}
            values={cultureTable.supportedCultures.map((c) => {
              return {
                value: c,
                text:
                  c === cultureTable.defaultCulture
                    ? `${enumReducer.getTranslation('culture', c)} ${i18n.getTranslation('translated_fields.default')}`
                    : enumReducer.getTranslation('culture', c)
              };
            })}
            placeholder={i18n.getTranslation('general.language')}
            selectedValues={[customerDetails.culture ? customerDetails.culture : ('' as culture)]}
          />
        )}
      </InputContainer>
      <InputContainer>
        <div className={css['contact-detail']}>
          <Icon type={contactType.telephone} />
          <InputField
            id="contact-telephone"
            onChange={(telephoneNumber) => setCustomerDetail({ telephoneNumber })}
            value={customerDetails.telephoneNumber}
            placeholder={enumReducer.getTranslation('contactType', contactType.telephone)}
          />
        </div>

        <div className={css['contact-detail']}>
          <Icon type={contactType.mobiletelephone} />
          <InputField
            id="contact-mobile"
            onChange={(mobileTelephoneNumber) => setCustomerDetail({ mobileTelephoneNumber })}
            value={customerDetails.mobileTelephoneNumber}
            placeholder={enumReducer.getTranslation('contactType', contactType.mobiletelephone)}
          />
        </div>

        <div className={css['contact-detail']}>
          <Icon type={contactType.email} />
          <InputField
            id="contact-email"
            onChange={(emailAddress) => setCustomerDetail({ emailAddress })}
            value={customerDetails.emailAddress}
            placeholder={enumReducer.getTranslation('contactType', contactType.email)}
          />
        </div>
      </InputContainer>
    </>
  );
}
