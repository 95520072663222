import { BillingParametersType } from '@zf/api-types/parameters';

import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import useRefresh from './use-refresh';

export default function useBillingParameters() {
  // This triggers an unnecessary rerender, maybe try to use a separate ctx for refreshing as react tracked in our app-state doesn't cover it
  const { timeStamp } = useRefresh();

  const billingParameters = useSuspenseSingleAPI<BillingParametersType>({
    request: {
      endpoint: '/cfg/Parameters/billing',
      timeStamp: timeStamp
    }
  });

  if (!billingParameters.result) return null;

  return billingParameters.result.data;
}
