import React from 'react';

import { MeterPaginatedResult } from '@zf/api-types/master-data/meter';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { MeterType } from '@zf/api-types/master-data/meter';
import { useAppContext } from '../../../app-context/app-context';
import { queryParam } from '../../../hooks/useCreateRequest';
import useSingleAPI from '../../../hooks/useSingleAPI';
import Dropdown from '../../Lang/Dropdown';
import MetersDropdownContent from './MetersDropdownContent';

export type MetersQueryDropdownProps = {
  id?: string;
  queryParams?: queryParam;
  isInline?: boolean;
  selectedValue: string;
  onFocus?: (step: string) => void;
  onChange: (values: MeterType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<MeterType>>(Dropdown);

export default function MetersQueryDropdown(props: MetersQueryDropdownProps) {
  const { id, queryParams, isInline = false, selectedValue, onFocus, onChange } = props;
  const { i18n } = useAppContext();

  const metersResponse = useSingleAPI<MeterPaginatedResult>({
    request: {
      endpoint: '/md/meters',
      query: {
        ...queryParams
      }
    }
  });

  // Select first by default if only one result
  React.useEffect(() => {
    if (metersResponse.result && metersResponse.result.data.results.length === 1) {
      onChange([metersResponse.result.data.results[0]]);
    }
  }, [metersResponse.result]);

  if (!metersResponse || !metersResponse.result || metersResponse.result instanceof Promise) {
    return isInline ? (
      <InlineDropdown
        id={id ? id : 'meter-dropdown'}
        onChange={onChange}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    ) : (
      <Dropdown
        id={id ? id : 'meter-dropdown'}
        onChange={onChange}
        placeholder={i18n.getTranslation('meter.meters')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    );
  }

  return <MetersDropdownContent {...props} meters={metersResponse.result.data.results} />;
}
