import moment from 'moment';
import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { meterTypeGroup, routedEntitySubjectType } from '@zf/api-types/local-enums';
import { MeterRowType, MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { betweenDates, formatDate } from '@zf/utils/src/date';

import useListPage from '../../../../app-context/hooks/use-listpage';
import EmptyLink from '../../../../components/Link/EmptyLink';
import { ICON_COLORS } from '../../../../constants/icons';
import { useStore } from '../../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../../utils/custom-entity-properties';
import MeterAddress from '../detail-page/detail/MeterAddress';
import MeterIssueIndicator from 'design-system/Components/MeterIssueIndicator/MeterIssueIndicator';

const useMeterListPage = (
  models: ModelType[],
  customEntityPropertyTypes: CustomEntityPropertyType[],
  meterIds?: string[]
) => {
  const { applicationStore } = useStore();
  const { rootUrl, getEnumTranslation, getTranslation } = applicationStore;

  const today = moment();

  const processRecord = (meter: MeterType): MeterRowType => {
    const model = models.find((m) => m.id === meter.modelId);

    const activeState = meter.statusHistory.find((status) => {
      return (
        betweenDates(status.startDateTime, status.endDateTime, today) ||
        (moment(status.endDateTime).year() === 10000 && today.isBefore(status.startDateTime)) // install date in future
      );
    });

    return {
      //Remember add correct condition: 'issues' must be present
      //response used currently: md/meters
      isUnhealthy: meter.hasIssue ? (
        <MeterIssueIndicator
          usage="meter"
          id={meter.id}
          tooltipId={meter.serialNumber}
          tooltipExtra={meter.issuesCount}
        />
      ) : null,
      __id: meter.id,
      __meterStatus: activeState?.meterStatus || '',
      __channels: meter.channels,
      __etag: meter._etag,
      __meterEntity: meter,
      __activeState: activeState,
      __customEntityPropertyTypes: customEntityPropertyTypes,
      serialNumber: (
        <EmptyLink id={`${routedEntitySubjectType.meter}-${meter.id}`} url={`${rootUrl}/devices/meters/${meter.id}`}>
          {meter.serialNumber}
        </EmptyLink>
      ),
      brand: model?.brandName || '',
      model: model?.name || '',
      installedAddress: (
        <MeterAddress id={`${meter.id}-address`} tooltipFor="meters-table-id" activeState={activeState} />
      ),
      statusValue: activeState?.meterStatus || '',
      state: activeState ? (
        <StatusBadge color={ICON_COLORS[activeState.meterStatus]} type="bare">
          {getEnumTranslation('meterStatus', activeState.meterStatus)}
        </StatusBadge>
      ) : (
        <StatusBadge>{undefined}</StatusBadge>
      ),
      meterType: getEnumTranslation('meterType', meter.meterType),
      lastTimeReceivedData: meter.lastTimeReceivedData
        ? formatDate(meter.lastTimeReceivedData)
        : getTranslation('metering_list.never'),
      measurementsUntil: meter.measurementsUntil
        ? formatDate(meter.measurementsUntil)
        : getTranslation('metering_list.none'),
      ...getExtraCustomEntityColumns(meter.customProperties, customEntityPropertyTypes, getTranslation)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<MeterType, MeterRowType>({
    endpoint: '/md/meters',
    processRecord,
    domain: 'meter',
    defaultQueryParams: { meterTypeGroup: meterTypeGroup.traditional, meterIds: meterIds }
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useMeterListPage;
