import React from 'react';

import { dataImportCommunicationType, importCategoryType, importJobStatus } from '@zf/api-types/enums';
import { ImportJobType } from '@zf/api-types/import-job';

import DeleteImportJobListItem from '../../../components/list-items/import-jobs/DeleteImportJobListItem';
import DownloadImportJobsListItem from '../../../components/list-items/import-jobs/DownloadImportJobsListItem';
import TriggerImportJobListItem from '../../../components/list-items/import-jobs/TriggerImportJobListItem';
import ValidateImportJobsListItem from '../../../components/list-items/import-jobs/ValidateImportJobsListItem';

type Props = {
  importJob: ImportJobType;
  refresh: () => void;
};

const ImportJobActions = (props: Props) => {
  const { importJob, refresh } = props;

  return (
    <>
      {importJob.status !== importJobStatus.storing && importJob.status !== importJobStatus.processing && (
        <TriggerImportJobListItem importJob={importJob} onComplete={() => refresh()} />
      )}
      {importJob.importType === importCategoryType.migration && (
        <ValidateImportJobsListItem importJob={importJob} onComplete={() => refresh()} />
      )}
      {importJob.communicationType !== dataImportCommunicationType.configuredPlugin && (
        <DownloadImportJobsListItem importJob={importJob} />
      )}
      {importJob.status !== importJobStatus.processing &&
        importJob.status !== importJobStatus.reading &&
        importJob.status !== importJobStatus.storing &&
        importJob.status !== importJobStatus.validating &&
        importJob.status !== importJobStatus.postprocessing && <DeleteImportJobListItem importJob={importJob} />}
    </>
  );
};

export default ImportJobActions;
