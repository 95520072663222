import { Spinner, SpinnerWrapper } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useReducer } from 'react';

import { BillingParametersType } from '@zf/api-types/parameters';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import BillingTabBody from './BillingTabBody';

type State = {
  billingParameters: BillingParametersType | undefined;
  contractInvoiceEstimationEnabled: boolean | undefined;
};

const BillingTab = () => {
  const { applicationStore, organisationStore, configStore } = useStore();
  const { tenantReducer, getTranslation } = applicationStore;
  const { organization, tenant } = tenantReducer;
  const { getBillingParameters } = configStore.configService;
  const { getOrganisationConfig } = organisationStore.organisationService;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    billingParameters: undefined,
    contractInvoiceEstimationEnabled: undefined
  });

  useEffect(() => {
    Promise.all([getBillingParameters(), getOrganisationConfig(organization?.organizationId || '', tenant?.id || '')])
      .then((res) => {
        setState({
          billingParameters: res[0],
          contractInvoiceEstimationEnabled: res[1]?.features.contractInvoiceEstimationEnabled
        });
      })
      .catch((error) =>
        notify.error({
          content: getTranslation('general.cfg_load_fail'),
          error
        })
      );
  }, []);

  if (!state.billingParameters || typeof state.contractInvoiceEstimationEnabled === 'undefined')
    return (
      <SpinnerWrapper hasTabHeight>
        <Spinner centered />
      </SpinnerWrapper>
    );

  return (
    <BillingTabBody
      billingParameters={state.billingParameters}
      contractInvoiceEstimationEnabled={state.contractInvoiceEstimationEnabled}
    />
  );
};

export default observer(BillingTab);
