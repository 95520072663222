import { BareDropdown } from 'design-system/Components';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import React, { FC } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { Query } from '../../list-page/types';
import css from './groupby-filter.module.scss';

type Props = {
  getValues: UseFormGetValues<Partial<Query>>;
  setValue: UseFormSetValue<Partial<Query>>;
};

const GroupbyFilter: FC<Props> = ({ getValues, setValue }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const groupByDropdownValues = [
    { id: 'none', text: getTranslation('general.none'), value: 'none' } as any,
    { id: 'meter', text: getTranslation('meter.meter'), value: 'meter' } as any
  ];

  const selectedGroup = groupByDropdownValues.find((v) => v.value === getValues('groupBy')?.[0])?.text || '';

  return (
    <div className={css['groupby-filter']}>
      <Paragraph>{`${getTranslation('general.group_by')}:`}</Paragraph>
      <BareDropdown
        id="dropdown-groupBy"
        label={selectedGroup}
        selectedValues={getValues('groupBy') as string[]}
        values={groupByDropdownValues}
        onChange={(val: any) => setValue('groupBy', val)}
      />
    </div>
  );
};

export default GroupbyFilter;
