import React from 'react';

import { ImportInsightsType } from '@zf/api-types/analytics/metering';
import { FileFormatType } from '@zf/api-types/file-format';
import { Card, CardBody } from '@zf/stella-react/src/atoms/Card';

import { insightsSubjectType } from '../../Dashboard';
import ImportCardBody from './ImportCardBody';

type Props = {
  subject: insightsSubjectType;
  importInsights: ImportInsightsType[];
};

export default function ImportCard(props: Props) {
  const { subject } = props;

  const [fileFormats, setFileFormats] = React.useState<FileFormatType[]>([]);

  return (
    <Card id={`${subject}-import-card`}>
      <CardBody>
        <ImportCardBody {...props} fileFormats={fileFormats} setFileFormats={setFileFormats} />
      </CardBody>
    </Card>
  );
}
