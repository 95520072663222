import classNames from 'classnames';
import React, { memo } from 'react';

import css from './uppercase-text.module.scss';

export type Props = {
  children: string;
  className?: string;
};

const UppercaseText = (props: Props) => {
  const { className, children } = props;

  return <span className={classNames(css['uppercase-text'], className)}>{children}</span>;
};

export default memo(UppercaseText);
