import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { startOfDay } from '@zf/utils/src/date';

import css from '../../../../../../../actions/style.module.scss';
import DatePicker from '../../../../../../../components/Lang/DatePicker';
import { DialogClickRef } from '../../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../../../events/notification-events';
import { useStore } from '../../../../../../../hooks/useStore';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

type Props = {
  completeness: BillingCompletenessType;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { contractStore, applicationStore, organisationStore } = useStore();
  const { invoicesForCompleteness, approveInvoice } = contractStore.billingCompletenessStore;
  const [invoiceDate, setInvoiceDate] = React.useState(startOfDay(moment()));
  const { getOrganisationConfig } = organisationStore.organisationService;
  const { getTranslation, tenantReducer } = applicationStore;

  /// Change notify message depending on Pay Link feature flag

  const [organizationFeatureFlags, setOrganizationFeatureFlags] = useState<OrganizationConfigType>();

  useEffect(() => {
    if (tenantReducer.organization?.organizationId && tenantReducer.tenant?.id) {
      getOrganisationConfig(tenantReducer.organization?.organizationId, tenantReducer.tenant?.id)
        .then((res) => {
          setOrganizationFeatureFlags(res);
        })
        .catch((error) => {
          notify.error({
            content: getTranslation('general.get_cfg_fail'),
            error
          });
        });
    }
  }, []);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await approveInvoice(
          {
            id: invoicesForCompleteness[0].id,
            etag: invoicesForCompleteness[0]._etag
          },
          invoiceDate.toISOString()
        );

        if (organizationFeatureFlags?.features.easyPaymentLink) {
          notify.info({
            content: getTranslation('actions.invoice.approve_with_pay_link_multi')
          });
        } else {
          notify.success({
            content: getTranslation('actions.invoice.approve_success')
          });
        }
      } catch (e) {
        notify.error({
          content: getTranslation('actions.invoice.approve_failed'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>{applicationStore.getTranslation('actions.invoice.approve_all', { created: '' })}</Paragraph>
      <div className={css['input-wrapper']}>
        <DatePicker
          id="approve-date-time"
          onChange={(val) => setInvoiceDate(val)}
          value={invoiceDate}
          placeholder={applicationStore.getTranslation('actions.invoice.approve_date')}
          error={!invoiceDate}
        />
      </div>
    </>
  );
});
