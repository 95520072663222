import React from 'react';

import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import useDialog from '@zf/hooks/src/useDialog';

import CalculatePricesDialog from '../../../../../../actions/property-group/calculate-prices-dialog';
import CreateInvoicesDialog from '../../../../../../actions/property-group/create-invoices-dialog';
import { useAppContext } from '../../../../../../app-context';
import Button from '../../../../../../components/Button/Button';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import { dialog } from '../../../../../../events/dialog-events';
import useBillingData from '../../hooks/use-billing-data';
import AllocationGroupDetailsTree from '../allocation-group-details-tree';
import css from './billing-periods-tab.module.scss';

type Props = {
  selectedBillingPeriod: PropertyGroupBillingPeriodType;
};

export default function AllocationGroups(props: Props) {
  const { selectedBillingPeriod } = props;

  const { i18n } = useAppContext();
  const { validationRef, clickRef, onSubmit } = useDialog();
  const { propertyGroupBillingConfiguration, propertyMeteringConfiguration, billingItems, updateBillingPeriod } =
    useBillingData();

  if (!propertyGroupBillingConfiguration || !propertyGroupBillingConfiguration.propertyGroup) return null;

  const propertyGroupId = propertyGroupBillingConfiguration.propertyGroup.id;

  const openCalculateDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.billing.calc_prices'),
      icon: 'calculator',
      content: (
        <CalculatePricesDialog
          ref={clickRef}
          validationRef={validationRef}
          selectedBillingPeriod={selectedBillingPeriod}
          propertyGroupId={propertyGroupId}
          outputChannels={propertyMeteringConfiguration?.outputChannels || []}
          calculationConfigurations={propertyGroupBillingConfiguration?.calculationConfigurations || []}
          billingItems={billingItems}
          updateBillingPeriod={updateBillingPeriod}
        />
      ),
      onSubmit: onSubmit,
      ref: validationRef,
      isDismissDialog: true
    });
  };

  const openCreateInvoicesDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.billing.create_invoices'),
      icon: 'invoice',
      content: (
        <CreateInvoicesDialog
          ref={clickRef}
          propertyGroupId={propertyGroupId}
          selectedBillingPeriod={selectedBillingPeriod}
          updateBillingPeriod={updateBillingPeriod}
        />
      ),

      onSubmit: onSubmit,
      ref: validationRef
    });
  };

  return (
    <ConfigColumn
      title={i18n.getTranslation('property_groups.tabs.billing.allocated_to')}
      extraRight={
        <div className={css['actions']}>
          {selectedBillingPeriod.calculationConfigurations.length === 0 && (
            <Button id="billing_periods.calculate_formulas" onClick={openCalculateDialog}>
              {i18n.getTranslation('property_groups.tabs.billing.calc_prices')}
            </Button>
          )}
          <Button
            id="billing_periods.trigger_billing"
            onClick={openCreateInvoicesDialog}
            disabled={selectedBillingPeriod.calculationConfigurations.length === 0}
          >
            {i18n.getTranslation('property_groups.tabs.billing.create_invoices')}
          </Button>
        </div>
      }
      borderRight={false}
    >
      {selectedBillingPeriod.calculationConfigurations.length > 0 ? (
        selectedBillingPeriod.calculationConfigurations.map((cc, index) => {
          return (
            <AllocationGroupDetailsTree
              key={`${cc.calculationConfigurationId}-${index}`}
              calculationConfigurationDetails={cc}
              index={index}
            />
          );
        })
      ) : (
        <EmptyColumn
          image="location-group"
          title={i18n.getTranslation('property_groups.tabs.billing.allocated_to_empty')}
          text={i18n.getTranslation('property_groups.tabs.billing.allocated_to_empty_descr')}
        />
      )}
    </ConfigColumn>
  );
}
