import { action, makeAutoObservable, observable } from 'mobx';

import {
  EntitySubjectSubtype,
  EntitySubjectUnionType,
  ScenarioIntersectionType
} from '@zf/api-types/config/scenarios_new';
import {
  collectionStepLevel,
  contractScenarioType,
  documentOutputFormat,
  entitySubjectType,
  invoiceScenarioType,
  moveRequestScenarioType,
  billingRelationScenarioType,
  templateUsecase
} from '@zf/api-types/enums';

import { EnumType } from '../../../app-context/hooks/use-enum';
import CustomHTMLForm from '../forms/CustomHTMLForm';
import TemplateForm from '../forms/TemplateForm';
import TestEmailForm from '../forms/TestEmailForm';
import CommunicationStore from './CommunicationStore';

class TemplatesStore {
  private communicationStore: CommunicationStore;

  public testEmailForm: TestEmailForm | undefined;
  public customHTMLForm: CustomHTMLForm | undefined;

  public templateForm_: TemplateForm | undefined;
  public selectedTranslationIndex = -1;

  public entitySubjectTypeMap = {
    [entitySubjectType.invoice]: [] as EnumType<invoiceScenarioType>[],
    [entitySubjectType.collectioncase]: [] as EnumType<collectionStepLevel>[],
    [entitySubjectType.contract]: [] as EnumType<contractScenarioType>[],
    [entitySubjectType.moverequest]: [] as EnumType<moveRequestScenarioType>[],
    [entitySubjectType.billingrelation]: [] as EnumType<billingRelationScenarioType>[]
  };

  public entitySubjectTypes: EntitySubjectUnionType[] = [
    entitySubjectType.invoice,
    entitySubjectType.collectioncase,
    entitySubjectType.contract,
    entitySubjectType.moverequest,
    entitySubjectType.billingrelation
  ];

  public selectedEntitySubjectType: EntitySubjectUnionType = entitySubjectType.invoice;
  public selectedEntitySubjectSubtype: EntitySubjectSubtype = invoiceScenarioType.advance;
  public selectedEntitySubjectTypeDiscard: EntitySubjectUnionType = entitySubjectType.invoice;
  public selectedEntitySubjectSubtypeDiscard: EntitySubjectSubtype = invoiceScenarioType.advance;
  public discardChanges = false;

  public selectedScenario: ScenarioIntersectionType | undefined;

  constructor(communicationStore: CommunicationStore) {
    this.communicationStore = communicationStore;

    makeAutoObservable(this, {
      selectedTranslationIndex: observable,
      selectedEntitySubjectType: observable,
      selectedEntitySubjectSubtype: observable,
      selectedScenario: observable,
      testEmailForm: observable,
      entitySubjectTypeMap: observable,
      discardChanges: observable,

      initScenario: action,
      initTemplatesTab: action,
      initEntitySubjectMap: action,
      setTranslationIndex: action,
      setSubjectTypes: action,
      setSelectedScenario: action,
      initTestEmailForm: action,
      initCustomHTMLForm: action,
      openPreviewWindow: action,
      setDiscardChanges: action,
      onAddCustomHTMLComplete: action
    });
  }

  get templateForm() {
    return this.templateForm_ as TemplateForm;
  }

  initScenario = (newScenario: ScenarioIntersectionType | undefined) => {
    if (newScenario) {
      this.selectedScenario = newScenario;

      this.templateForm_ = new TemplateForm(this.communicationStore, {
        subject: newScenario.emailTemplate.subject,
        defaultCommunicationType: newScenario.defaultCommunicationType,
        costAllocation: newScenario?.pdfTemplate?.costAllocation,
        showCountry: newScenario?.pdfTemplate?.showCountry,
        showBalance: newScenario?.pdfTemplate?.showBalance,
        showVatSpecs: newScenario?.pdfTemplate?.showVatSpecs,
        marginPosition: newScenario?.pdfTemplate?.envelopeSettings?.marginPosition,
        margin: newScenario?.pdfTemplate?.envelopeSettings?.margin,
        marginTop: newScenario?.pdfTemplate?.envelopeSettings?.marginTop,
        attachments: newScenario.emailTemplate.attachments
      });
    }
  };

  initTemplatesTab = (type: EntitySubjectUnionType, subType: EntitySubjectSubtype) => {
    // Only reinitialize when all changes are saved
    this.selectedEntitySubjectSubtype = subType;
    this.selectedEntitySubjectType = type;
    if (!this.templateForm_?._isDirty) {
      this.communicationStore.communicationService
        .getScenario(type, subType)
        .then((newScenario) => this.initScenario(newScenario));
    }
  };

  initEntitySubjectMap = () => {
    const { getEnum } = this.communicationStore.rootStore.applicationStore;

    this.entitySubjectTypeMap = {
      [entitySubjectType.invoice]: getEnum<invoiceScenarioType>('invoiceScenarioType'),
      [entitySubjectType.collectioncase]: getEnum<collectionStepLevel>('collectionStepLevel'),
      [entitySubjectType.contract]: getEnum<contractScenarioType>('contractScenarioType'),
      [entitySubjectType.moverequest]: getEnum<moveRequestScenarioType>('moveRequestScenarioType'),
      [entitySubjectType.billingrelation]: getEnum<billingRelationScenarioType>('billingRelationScenarioType')
    };
  };

  setSubjectTypes = (newType: EntitySubjectUnionType, newSubtype: EntitySubjectSubtype) => {
    if (this.templateForm_?._isDirty) {
      this.discardChanges = true;
      this.selectedEntitySubjectTypeDiscard = newType;
      this.selectedEntitySubjectSubtypeDiscard = newSubtype;
    } else {
      this.discardChanges = false;
      this.selectedEntitySubjectType = newType;
      this.selectedEntitySubjectSubtype = newSubtype;
      this.templateForm_ = undefined;
      this.communicationStore.communicationService.getScenario(newType, newSubtype).then((newScenario) => {
        this.selectedScenario = newScenario;
        this.initScenario(newScenario);
      });
    }
  };

  setDiscardChanges = (discardChanges: boolean) => {
    this.discardChanges = discardChanges;
  };

  setSelectedScenario = (newScenario: ScenarioIntersectionType) => {
    this.selectedScenario = newScenario;
  };

  setTranslationIndex = (index: number) => {
    this.selectedTranslationIndex = index;
  };

  initTestEmailForm = () => {
    this.testEmailForm = new TestEmailForm({ emailReceiver: '' });
  };

  initCustomHTMLForm = () => {
    this.customHTMLForm = new CustomHTMLForm(this.communicationStore, { file: undefined });
  };

  openPreviewWindow = (previewResponse: string | ArrayBuffer | undefined, outFormat: documentOutputFormat) => {
    if (previewResponse) {
      if (outFormat === documentOutputFormat.html) {
        if (previewResponse) {
          const newWindow = window.open();
          if (newWindow) newWindow.document.write(previewResponse as string);
        }
      } else {
        const pdf = new Blob([previewResponse], { type: 'application/pdf' });
        const blob_url = URL.createObjectURL(pdf);
        if (blob_url) window.open(blob_url);
      }
    }
  };

  previewTemplate = async (templateUseCase: templateUsecase, outFormat: documentOutputFormat) => {
    const { communicationService, rootStore } = this.communicationStore;

    const cultureTable = await rootStore.configStore.configService.getCultureTable();

    const previewResponse = await communicationService.getTemplatePreview(
      this.selectedEntitySubjectType,
      this.selectedEntitySubjectSubtype,
      cultureTable.defaultCulture,
      templateUseCase,
      outFormat,
      false,
      false
    );

    this.openPreviewWindow(previewResponse, outFormat);
  };

  onAddCustomHTMLComplete = (response: ScenarioIntersectionType, templateUseCase_: templateUsecase) => {
    if (this.selectedScenario) {
      const templateKey = templateUseCase_ === templateUsecase.pdf ? 'pdfTemplate' : 'emailTemplate';

      this.selectedScenario[templateKey].fileName = response[templateKey].fileName;
      this.selectedScenario[templateKey].customTemplateFileName = response[templateKey].customTemplateFileName;
      this.selectedScenario[templateKey].customTemplateInternalFilePath =
        response[templateKey].customTemplateInternalFilePath;
      this.selectedScenario[templateKey].useBuildIn = response[templateKey].useBuildIn;
      this.selectedScenario[templateKey].translationStatus = response[templateKey].translationStatus;
    }
  };
}

export default TemplatesStore;
