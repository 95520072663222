import React from 'react';
import { useStore } from 'hooks/useStore';
import ReferralCard from 'design-system/ComponentSets/ReferralCard/ReferralCard';
import { IssuesInsightsType } from '@zf/api-types/analytics/metering';

type Props = {
  issuesInsights: IssuesInsightsType;
};

function IssuesCardBody(props: Props) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { issuesInsights } = props;

  return (
    <ReferralCard
      title={getTranslation('meter_issues.issues')}
      url="/devices/issues"
      extraInfo={getTranslation('meter_issues.impacted_meters', {
        meters: issuesInsights.amountOfMetersWithUnresolvedIssues
      })}
      status={issuesInsights.amountOfUnresolvedIssues}
    />
  );
}

export default IssuesCardBody;
