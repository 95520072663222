import Interweave from 'interweave';
import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { navigate } from '@reach/router';
import { entitySubjectType } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { capitalize } from '@zf/utils/src/string';

import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph, Spinner } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import { entityMap } from '../../../../../utils/general';

type Props = {
  entityId: string;
  entityType?: entitySubjectType | null;
};

const EditProductAttachmentDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { entityId, entityType } = props;
  const { applicationStore, productConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getProductForId } = productConfigStore.productConfigService;

  const [productName, setProductName] = useState<string>('');

  useEffect(() => {
    if (entityType === entitySubjectType.product) getProductForId(entityId).then((prod) => setProductName(prod.name));
  }, []);

  useImperativeHandle(ref, () => ({
    async onClick() {
      if (entityType === entitySubjectType.product) {
        await navigate(`${applicationStore.rootUrl}/configuration/product?productName=${capitalize(productName)}`);
      } else if (entityType) {
        await navigate(
          `${applicationStore.rootUrl}/${entityMap[entityType as unknown as routedEntitySubjectType]}/${entityId}/files`
        );
      }
    }
  }));

  if (entityType === entitySubjectType.product) {
    return productName ? (
      <Paragraph>
        <Interweave content={getTranslation('entity_attachment.edit_product_dialog_desc', { productName })} />
      </Paragraph>
    ) : (
      <Spinner size="small" centered />
    );
  } else {
    return (
      <Paragraph>
        <Interweave content={getTranslation('entity_attachment.edit_entity_dialog_desc')} />
      </Paragraph>
    );
  }
});

export default observer(EditProductAttachmentDialog);
