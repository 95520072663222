import { observer } from 'mobx-react';
import React from 'react';

import { PaymentReferenceParametersType } from '@zf/api-types/transactions';
import { formatDate } from '@zf/utils/src/date';

import { CoachMark } from '../../../design-system/Components';
import { Paragraph } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import css from './parameters-tooltip.module.scss';

type Props = {
  params: PaymentReferenceParametersType;
};

const PaymentReferenceParameters = (props: Props) => {
  const { params } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  return (
    <CoachMark>
      <div className={css['info-tooltip']}>
        <Paragraph bold>{getTranslation('cards.cash_position.payment_date')}</Paragraph>
        <Paragraph>{params.paymentDateTime ? formatDate(params.paymentDateTime) : ''}</Paragraph>
        <br />
        <Paragraph bold>{getTranslation('cards.cash_position.payment_type')}</Paragraph>
        <Paragraph>{getEnumTranslation('transactionReferenceType', params.type)}</Paragraph>
        <br />
        {params.paymentReference !== null ? (
          <>
            <Paragraph bold>{getTranslation('cards.cash_position.payment_reference')}</Paragraph>
            <Paragraph>{params.paymentReference}</Paragraph>
          </>
        ) : null}
      </div>
    </CoachMark>
  );
};

export default observer(PaymentReferenceParameters);
