import React from 'react';
import { ibanConfirmationStatus } from '@zf/api-types/enums';
import { useStore } from '../../../../../../hooks/useStore';
import IBANInfoBanner from 'actions/incoming-mutation/IBANInfoBanner/IBANInfoBanner';
import { useAppContext } from 'app-context';
import { IncomingMutationType } from '@zf/api-types/billing/incoming-mutations';
import { MatchDialogState } from 'actions/incoming-mutation/ManualMatchDialog';

type Props = {
  incomingMutation: IncomingMutationType;
  state: MatchDialogState;
  dispatch: React.Dispatch<Partial<MatchDialogState>>;
};

export const IbanUnknown = ({ incomingMutation, state, dispatch }: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { i18n } = useAppContext();

  return (
    <>
      {state.ibanConfirmationStatus === ibanConfirmationStatus.UnknownSecond && (
        <IBANInfoBanner
          info={getTranslation('customer.new_iban', { iban: incomingMutation.details.iban })}
          saveIbanOnCustomer={state.isIbanConfirmedOnTransaction}
          saveIbanTitle={getTranslation('customer.saved_iban_to_customer')}
          saveIbanDesc={getTranslation('customer.save_new_iban_on_customer_desc')}
          color="blue"
          setValue={async (isIbanConfirmedOnTransaction) => {
            dispatch({ isIbanConfirmedOnTransaction });
          }}
        />
      )}

      {state.ibanConfirmationStatus === ibanConfirmationStatus.UnknownFirst && (
        <IBANInfoBanner
          info={i18n.getTranslation('customer.new_iban_set_as_default', {
            customer: state.customerName,
            iban: incomingMutation.details.iban
          })}
          saveIbanOnCustomer={state.isIbanConfirmedOnTransaction}
          saveIbanTitle={i18n.getTranslation('customer.saved_iban_to_customer')}
          saveIbanDesc={i18n.getTranslation('customer.new_iban_set_as_default_desc')}
          color="orange"
          setValue={async (isIbanConfirmedOnTransaction) => {
            dispatch({ isIbanConfirmedOnTransaction });
          }}
        />
      )}
    </>
  );
};
