import clone from 'clone';
import React, { Dispatch, forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { CollectionFlowStepType, CollectionFlowType, CreateCollectionFlowType } from '@zf/api-types/collection-cycles';
import useSimpleValidator from '@zf/hooks/src/useSimpleValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../../../app-context';
import { InlineInputFieldInput } from '../../../../components/input/InputField';
import { InlineNumberInput } from '../../../../components/input/NumberInput';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { addCollectionCycle, addCollectionFlowStep } from '../collection-api-functions';
import { CollectionCyclesState } from '../hooks/useCollectionCycles';
import { StepValidatorType } from '../step-wizard/step-wizard';
import css from './add-cycle-dialog.module.scss';
import { generateApiFriendlyValues, validateStepFields } from './add-step';
import AddStepsMultiValue from './add-steps-multivalue';

type Props = {
  validationRef: MutableRefObject<ValidationRef | undefined>;
  collectionSteps: CollectionFlowStepType[];
  addFlow: (flow: CollectionFlowType, steps: CollectionFlowStepType[]) => void;
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  setHookState: Dispatch<Partial<CollectionCyclesState>>;
};

export type ValidatorType = {
  name: string;
  daysAfterInvoiceDue: number;
  steps: StepValidatorType[];
};

export default forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { validationRef, collectionSteps, addFlow, addStep, setHookState } = props;
  const { i18n, tenantReducer } = useAppContext();

  const [steps, setSteps] = useState(collectionSteps);

  const { values, setValue } = useSimpleValidator<ValidatorType>({
    initialValues: {
      name: '',
      daysAfterInvoiceDue: 0,
      steps: []
    }
  });

  const validate = () => {
    if (validationRef.current) {
      const stepsAreValid = values.steps.every((s) => {
        return !validateStepFields(s).includes(true);
      });

      if (values.name && values.daysAfterInvoiceDue && values.steps.length > 0 && stepsAreValid) {
        validationRef.current.setIsError(false);
      } else {
        validationRef.current.setIsError(true);
      }
    }
  };

  useEffect(() => {
    validate();
  }, [values]);

  const setStep = (step: StepValidatorType, index: number) => {
    const steps = values.steps.map((step) => Object.assign({}, step));
    const stepsClone = steps;

    stepsClone[index] = step;
    setValue({ steps: stepsClone });
  };

  const updateDialogSteps = (newStep: CollectionFlowStepType) => {
    const stepsClone = clone(steps);
    stepsClone.push(newStep);
    setSteps(stepsClone);
    setHookState({ collectionSteps: stepsClone });
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        // Submit our steps first
        const addedSteps = await Promise.all(
          values.steps.map((s) => {
            const friendlyValues = generateApiFriendlyValues(s);
            return addCollectionFlowStep(friendlyValues, tenantReducer, i18n.lang);
          })
        );

        // Submit new cycle
        const apiFriendlyValues: CreateCollectionFlowType = {
          name: values.name,
          daysAfterInvoiceDue: values.daysAfterInvoiceDue,
          stepIds: addedSteps.map((s) => s.id)
        };

        const newCycle = await addCollectionCycle(apiFriendlyValues, tenantReducer, i18n.lang);
        // Update state
        addFlow(newCycle, addedSteps);

        notify.success({
          content: i18n.getTranslation('collection_flows.add_flow_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('collection_flows.add_flow_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <div className={css['two-column']}>
        <div>
          <Heading headingType="h3" style="bold">
            {i18n.getTranslation('collection_flows.choose_flow_name')}
          </Heading>
          <InputContainer>
            <InlineInputFieldInput
              id="cycle-name"
              value={values.name}
              onChange={(val) => setValue({ name: val })}
              placeholder={i18n.getTranslation('collection_flows.flow_name')}
              error={!values.name}
            />
          </InputContainer>
        </div>

        <div>
          <Heading headingType="h3" style="bold">
            {i18n.getTranslation('collection_flows.choose_trigger_delay')}
          </Heading>
          <InputContainer>
            <InlineNumberInput
              id="days-after-invoice-due"
              value={values.daysAfterInvoiceDue}
              onChange={(val) => setValue({ daysAfterInvoiceDue: val })}
              placeholder={i18n.getTranslation('collection_flows.days_after_due_date')}
              error={!values.daysAfterInvoiceDue}
            />
          </InputContainer>
        </div>
      </div>

      <AddStepsMultiValue setStep={setStep} addStep={addStep} updateDialogSteps={updateDialogSteps} />
    </>
  );
});
