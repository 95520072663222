import Interweave from 'interweave';
import { Moment } from 'moment';
import React from 'react';

import {
  CalculationResultType,
  FormulaFunctionExceptionType,
  OutputChannelType
} from '@zf/api-types/property-metering-configuration';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { isMaxDate, isMinDate } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import DateRangePicker from '../../components/input/DateRangePicker';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';
import css from './calculate-dialog.module.scss';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  propertyGroupId: string | undefined;
  outputChannel: OutputChannelType | undefined;
  setCalculationErrors: (calculationErrors: FormulaFunctionExceptionType[]) => void;
};

type State = {
  startDateTime: Moment | null;
  endDateTime: Moment | null;
  simulationOnly: boolean;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, propertyGroupId, outputChannel, setCalculationErrors } = props;

  const { i18n, tenantReducer } = useAppContext();

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    startDateTime: null,
    endDateTime: null,
    simulationOnly: false
  });

  const isDateError = () => {
    return (
      values.startDateTime === null ||
      isMinDate(values.startDateTime) ||
      values.endDateTime === null ||
      isMaxDate(values.endDateTime)
    );
  };

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(isDateError());
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(values)]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (propertyGroupId && outputChannel) {
          const result = (
            await sendRequest<CalculationResultType>({
              request: {
                method: METHODS.POST,
                endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyGroupId}/${outputChannel.id}/calculate`,
                data: values
              },
              tenantReducer: tenantReducer,
              lang: i18n.lang
            })
          ).data;

          if (result.hasErrors) {
            setCalculationErrors(result.errors);
            throw new Error('');
          } else {
            notify.success({
              content: i18n.getTranslation('property_groups.tabs.metering.calculation_success', {
                outputChannel: outputChannel.name
              })
            });
          }
        } else {
          throw new Error(i18n.getTranslation('property_groups.tabs.metering.calculation_fail'));
        }
      } catch (e) {
        if (propertyGroupId && outputChannel) {
          notify.error({
            content: i18n.getTranslation('property_groups.tabs.metering.calculation_fail'),
            error: e
          });
        } else {
          throw new Error(i18n.getTranslation('property_groups.tabs.metering.calculation_fail'));
        }
      }
    }
  }));

  return (
    <>
      <Paragraph>
        <Interweave content={i18n.getTranslation('property_groups.tabs.metering.calculate_paragraph')} />{' '}
      </Paragraph>
      <div className={css['wrapper']}>
        <InputContainer>
          <DateRangePicker
            id="calculation-period"
            startDate={values.startDateTime}
            endDate={values.endDateTime}
            setDates={(dates) => setValue({ startDateTime: dates[0], endDateTime: dates[1] })}
            error={isDateError()}
          />
        </InputContainer>
        <CheckBox
          id="simulation-only"
          checked={values.simulationOnly}
          onChange={(val) => setValue({ simulationOnly: val })}
        >
          {i18n.getTranslation('property_groups.tabs.metering.simulation_only')}
        </CheckBox>
      </div>
    </>
  );
});
