import React from 'react';

import StellaInputField from './StellaInputField';

export type NumberInputProps = {
  id: string;
  value?: number;
  placeholder?: string;
  min?: string;
  info?: string;
  max?: string;
  disabled?: boolean;
  readonly?: boolean;
  hideArrows?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  singleError?: string;
  hideLabel?: boolean;
  postfix?: string;
  onChange: (value: number) => void;
  onFocus?: (step: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
};

export default function StellaNumberInput(props: NumberInputProps) {
  const {
    value = '',
    singleError,
    info,
    id,
    placeholder,
    disabled = false,
    readonly = false,
    autoFocus = false,
    hideArrows = false,
    error,
    onFocus,
    onKeyDown,
    onChange,
    hideLabel,
    postfix,
    min,
    max
  } = props;

  const handleOnChange = (val: string) => onChange(parseInt(val));

  return (
    <StellaInputField
      hideArrows={hideArrows}
      id={id}
      min={min}
      max={max}
      type="number"
      info={info}
      singleError={singleError}
      onChange={handleOnChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      value={value.toString()}
      placeholder={placeholder}
      disabled={disabled}
      readonly={readonly}
      autoFocus={autoFocus}
      error={error}
      hideLabel={hideLabel}
      postfix={postfix}
    />
  );
}
