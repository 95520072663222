import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { meterStatus } from '@zf/api-types/enums';
import { MeterRowType, MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import UninstallMeterDialog from '../../../actions/meters/uninstall-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import { SuspenseSpinner } from '../../suspense';

type Props = {
  refreshCounts: () => void;
};

export default function UninstallMeterListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('meter');
  const selectedRows: MeterRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<MeterType>('meter');
  const { clickRef, validationRef, onSubmit } = useDialog();

  const singleSelection = selectedRows.length === 1 ? selectedRows[0].__meterEntity : undefined;

  const disabled =
    selectedRows.find((meter) => meter.__meterStatus !== meterStatus.installed) !== undefined ||
    selectedRows.length === 0;

  const actionText = i18n.getTranslation('actions.meter.uninstall_header');

  const submetersResponse = useSuspenseSingleAPI<PagedResponseType<MeterType>>({
    request: {
      endpoint: '/md/Meters',
      query: { parentSerialNumber: singleSelection?.serialNumber }
    },
    mayExecute: !!singleSelection
  });

  if (singleSelection && !submetersResponse.result) return null;

  return (
    <DropdownAction
      id="meter.uninstall"
      icon="cross"
      dialogTitle={actionText}
      dialogContent={
        <SuspenseSpinner size="xsmall">
          <UninstallMeterDialog
            onComplete={setUpdatedRows}
            selectedRows={selectedRows}
            ref={clickRef}
            submeters={submetersResponse && submetersResponse.result ? submetersResponse.result.data.results : []}
            validationRef={validationRef}
            refreshCounts={refreshCounts}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      disabled={disabled}
      dialogType="danger"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.uninstall_meter')}
    />
  );
}
