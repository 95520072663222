import PropertyGroupLinks from 'components/PropertyGroupLinks/PropertyGroupLinks';
import { Icon } from 'design-system/Foundation';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import SubTitle from '@zf/stella-react/src/atoms/Tabs/SubTitle';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import { NavigatorType } from '../../../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../../../components/RouteWrapper';
import GenericTabContainer from '../../../../../components/Tab/GenericTabContainer';
import { ICON_COLORS } from '../../../../../constants/icons';
import { Link } from '../../../../../design-system/Components';
import { useStore } from '../../../../../hooks/useStore';
import Route from '../../../../route';
import InvoiceDetail from '../detail-page/InvoiceDetail';
import InvoiceActions from '../InvoiceActions';
import css from './invoice-tab-container.module.scss';

type Props = {
  navigator: NavigatorType;
  navigate: (url: string) => void;
};

const InvoiceTabContainer = (props: Props) => {
  const { navigator, navigate } = props;
  const { invoiceStore, applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const { selectedInvoice } = invoiceStore;

  const invoice = selectedInvoice.invoice;

  const tabItems: TabItemType[] = [
    {
      id: 'detail',
      title: getTranslation('nav_detail.detail'),
      isDefault: true
    }
  ];

  let subTitle: ReactNode = '';
  let subTitle2: ReactNode = '';

  if (invoice.propertyGroups && invoice.propertyGroups.length > 0) {
    subTitle2 = (
      <SubTitle>
        {getTranslation('invoice.applicable_to')}
        <PropertyGroupLinks propertyGroups={invoice.propertyGroups} />
      </SubTitle>
    );
  } else {
    subTitle2 = '';
  }

  if (invoice.debtor.customerGroup && invoice.debtor.customerGroup.id) {
    subTitle = (
      <SubTitle>
        {getTranslation('customer_groups.member_of')}
        {<Icon name="customer-group" className={css['sub-icon-cg']} />}
        <Link
          id={`customer-group-${invoice.debtor.customerGroup?.id}`}
          url={`${rootUrl}/customers/customer-groups/${invoice.debtor.customerGroup?.id}`}
          sub
        >
          {invoice.debtor.customerGroup?.name}
        </Link>
      </SubTitle>
    );
  } else {
    subTitle = '';
  }

  return (
    <GenericTabContainer
      status={
        <NewStatusBadge
          color={ICON_COLORS[invoice.status]}
          status_={` ${applicationStore.getEnumTranslation('invoiceStatus', invoice.status)} `}
        />
      }
      subTitle={subTitle || subTitle2}
      subTitle2={subTitle2 && subTitle ? subTitle2 : ''}
      actions={<InvoiceActions />}
      tabItems={tabItems}
      routes={
        <RouteWrapper default>
          <Route Component={InvoiceDetail} path="detail" default />
        </RouteWrapper>
      }
      entityId={invoice.invoiceNum}
      entityType={routedEntitySubjectType.invoice}
      navigate={navigate}
      navigator={navigator}
      iconName="invoice"
    />
  );
};

export default observer(InvoiceTabContainer);
