import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { BillingAnalyticsType } from '@zf/api-types/analytics/billing';
import { MasterDataAnalyticsType } from '@zf/api-types/analytics/masterdata';
import { MeteringAnalyticsType } from '@zf/api-types/analytics/metering';
import { PaymentAnalyticsType } from '@zf/api-types/analytics/payments';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { ErrorBoundary } from '../../../components/Error';
import { Spinner } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import { METHODS } from '../../../utils/request';
import BillingAnalytics from './columns/BillingAnalytics';
import Collections from './columns/Collections';
import MasterData from './columns/MasterData';
import MeteringAnalytics from './columns/MeteringAnalytics';
import css from './general-grid.module.scss';
import { notify } from 'events/notification-events';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

type Props = {
  paymentsActive: boolean;
  endOfMonth: string;
  setActiveTab: (tabId: string) => void;
};

const GeneralTab = (props: Props) => {
  const { paymentsActive, endOfMonth, setActiveTab } = props;
  const { applicationStore, organisationStore } = useStore();
  const { getTranslation, tenantReducer } = applicationStore;
  const { getOrganisationConfig } = organisationStore.organisationService;

  const [organizationFeatureFlags, setOrganizationFeatureFlags] = useState<OrganizationConfigType>();
  const [issueCounts, setIssueCounts] = useState({ visibility: false });

  useEffect(() => {
    getOrganisationConfig(tenantReducer.organization?.organizationId || '', tenantReducer.tenant?.id || '')
      .then((res) => {
        setOrganizationFeatureFlags(res);
      })
      .catch((error) => {
        notify.error({
          content: getTranslation('general.get_cfg_fail'),
          error
        });
      });
  }, []);

  useEffect(() => {
    if (organizationFeatureFlags?.features.meteringValidationEnabled) {
      setIssueCounts({
        visibility: organizationFeatureFlags.features.meteringValidationEnabled
      });
    } else {
      setIssueCounts({
        visibility: false
      });
    }
  }, [organizationFeatureFlags]);

  const meteringInsightsResponse = useSuspenseSingleAPI<MeteringAnalyticsType>({
    request: {
      method: METHODS.GET,
      endpoint: '/insights/Metering',
      query: {
        endOfMonth
      }
    }
  });

  const billingInsightsResponse = useSuspenseSingleAPI<BillingAnalyticsType>({
    request: {
      method: METHODS.GET,
      endpoint: '/insights/Billing',
      query: {
        endOfMonth
      }
    }
  });

  const paymentInsightsResponse = useSuspenseSingleAPI<PaymentAnalyticsType>({
    request: {
      method: METHODS.GET,
      endpoint: '/insights/Payment',
      query: {
        endOfMonth
      }
    }
  });

  const masterDataResponse = useSuspenseSingleAPI<MasterDataAnalyticsType>({
    request: {
      method: METHODS.GET,
      endpoint: '/insights/MasterData',
      query: {
        endOfMonth
      }
    }
  });

  if (
    !meteringInsightsResponse.result ||
    !billingInsightsResponse.result ||
    !paymentInsightsResponse.result ||
    !masterDataResponse.result ||
    !issueCounts
  )
    return (
      <div className={css['spinner-wrapper']}>
        <Spinner centered />
      </div>
    );

  const meteringInsights = meteringInsightsResponse.result.data;
  const billingInsights = billingInsightsResponse.result.data;
  const paymentInsights = paymentInsightsResponse.result.data;
  const masterDataInsights = masterDataResponse.result.data;

  return (
    <div className={classNames(css['general-grid'], { [css['four-columns']]: paymentsActive })}>
      <Heading headingType="h3">{getTranslation('dashboard.general_tab.metering')}</Heading>
      <Heading headingType="h3">{getTranslation('dashboard.general_tab.billing')}</Heading>
      {paymentsActive && <Heading headingType="h3">{getTranslation('dashboard.general_tab.payments')}</Heading>}
      <Heading headingType="h3">{getTranslation('dashboard.general_tab.master_data')}</Heading>
      <ErrorBoundary>
        <MeteringAnalytics meteringInsights={meteringInsights} setActiveTab={setActiveTab} issueCounts={issueCounts} />
      </ErrorBoundary>
      <ErrorBoundary>
        <BillingAnalytics billingInsights={billingInsights} setActiveTab={setActiveTab} />
      </ErrorBoundary>
      {paymentsActive && (
        <ErrorBoundary>
          <Collections paymentInsights={paymentInsights} setActiveTab={setActiveTab} />
        </ErrorBoundary>
      )}
      <MasterData masterDataInsights={masterDataInsights} setActiveTab={setActiveTab} />
    </div>
  );
};

export default observer(GeneralTab);
