export const inputGuidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };

  // HTML ids must start with a letter, hence 'i'
  return `i${S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()}`;
};

export const canUseDOM = () => {
  if (typeof window === 'undefined' || !window.document || !window.document.createElement) {
    return false;
  }

  return true;
};
