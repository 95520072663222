import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Card, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../app-context';
import DeleteSubmeterCardButton from '../../../../../components/Button/Meter/DeleteSubmeterCardButton';
import { Icon } from '../../../../../components/Icon';
import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { SuspenseCardBody } from '../../../../../components/suspense';
import { ICON_COLORS } from '../../../../../constants/icons';
import useCreateRequest from '../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../hooks/useInfiniAPI';
import { getCurrentMeterStatus } from '../../../../../utils/meter';
import DeviceLink from '../../../shared/DeviceLink';

type Props = {
  meter: MeterType;
  timeStamp: string;
  models: ModelType[];
  setMeter: React.Dispatch<React.SetStateAction<MeterType | null>>;
  refresh: () => void;
};

export type SubmeterRowType = {
  __id: string;
  __entity: MeterType;
  serialNumber: JSX.Element;
  status: JSX.Element;
  model: string;
  relation: string;
};

function NoSubmeters() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody icon={<Icon type="meter-linked" />} title={i18n.getTranslation('meter.no_submeters')}>
      {i18n.getTranslation('meter.no_submeters_desc')}
    </CardEmptyBody>
  );
}

export default function SubmetersCardContent(props: Props) {
  const { meter, models, timeStamp, refresh } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const [columns] = React.useState([
    {
      width: 280,
      label: i18n.getTranslation('meter.serial'),
      dataKey: 'serialNumber'
    },
    {
      width: 150,
      label: i18n.getTranslation('general.status'),
      dataKey: 'status'
    },
    {
      width: 180,
      label: i18n.getTranslation('meter.model'),
      dataKey: 'model'
    },
    {
      width: 150,
      label: i18n.getTranslation('general.reference'),
      dataKey: 'relation'
    }
  ]);

  const { request, setSelectedIds, handleSort, selectedIds, sortBy, sortDirection } = useCreateRequest('/md/Meters', {
    parentSerialNumber: meter.serialNumber,
    timeStamp: timeStamp
  });

  const processRecord = (m: MeterType) => {
    const activeStatus = getCurrentMeterStatus(m, i18n);
    const model = models.find((model_) => m.modelId === model_.id);

    return {
      __id: m.id,
      __entity: m,
      serialNumber: <DeviceLink device={m} />,
      status: (
        <NewStatusBadge
          color={ICON_COLORS[activeStatus.meterStatus]}
          status_={enumReducer.getTranslation('meterStatus', activeStatus.meterStatus)}
        />
      ),
      model: model ? model.name : '',
      relation: m.parentMeterRelation ? m.parentMeterRelation.externalReference : ''
    };
  };

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<MeterType, SubmeterRowType>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord,
    onSelectRow: setSelectedIds
  });

  return (
    <Card id="submeters-card" width="2">
      <CardHeader
        extraRight={
          <DeleteSubmeterCardButton
            toUnlink={rows.reduce((acc: MeterType[], r) => {
              if (selectedIds.includes(r.__id)) {
                acc.push(r.__entity);
              }

              return acc;
            }, [])}
            refresh={refresh}
          />
        }
      >
        {i18n.getTranslation('meter.submeters')}
      </CardHeader>
      <SuspenseCardBody type="table">
        <DynamicVirtualTable
          id="submeters-table"
          onSelectRow={setSelectedIds}
          NoDataOverlay={NoSubmeters}
          onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
          sort={handleSort}
          rowHeight={45}
          rows={rows}
          selectedRows={selectedIds}
          totalAmountOfRows={rows.length}
          loading={loading}
          error={error}
          sortableFields={sortableFields}
          sortBy={sortBy}
          singleSort
          sortDirection={sortDirection}
          columns={columns}
        />
      </SuspenseCardBody>
    </Card>
  );
}
