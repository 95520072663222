import React from 'react';

import { ImportJobItemsType, ImportJobType } from '@zf/api-types/import-job';
import OverflowParagraph from '@zf/stella-react/src/atoms/Paragraph/OverflowParagraph';

import { useAppContext } from '../../../../../app-context';
import Button from '../../../../../components/Button/Button';
import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { dialog } from '../../../../../events/dialog-events';
import useCreateRequest from '../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../hooks/useInfiniAPI';
import ErrorDialog from '../error-dialog';

type DetailsRowType = {
  errors: React.ReactNode;
  propertyErrors: React.ReactNode;
  __id: string;
  __entity: ImportJobItemsType;
  stage: React.ReactNode;
};

const useProcessingDetails = (importJob: ImportJobType, itemType: string, onlyErrors: boolean) => {
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const ref = React.useRef<DialogClickRef>();

  const { request } = useCreateRequest(`/logs/ImportJob/${importJob.id}/${itemType}/items`, {
    onlyErrors: onlyErrors
  });

  const openDialog = (item: ImportJobItemsType, errorType: 'error' | 'propertyError') => {
    dialog.normal({
      title:
        errorType === 'propertyError'
          ? i18n.getTranslation('import_jobs.property_errors')
          : i18n.getTranslation('errors.errors'),
      content: <ErrorDialog ref={ref} item={item} errorType={errorType} />,
      onSubmit: () => {
        // close dialog
      },
      isDismissDialog: true
    });
  };

  const processRecord = (item: ImportJobItemsType) => {
    const cols: Record<string, any> = {};

    const id = JSON.stringify(item);

    Object.keys(item.entity).forEach((property, index) => {
      cols[property] = (
        <OverflowParagraph
          id={`content-${index}-${item.entity[property]}`}
          tooltipFor="invoice-period-table-tip"
          usedInTable
        >
          {item.entity[property]}
        </OverflowParagraph>
      );
    });

    return {
      __id: id,
      __entity: item,
      stage: item.stage ? enumReducer.getTranslation('importJobEntityIssueStage', item.stage) : null,
      ...cols,
      errors:
        item.errors.length > 0 ? (
          <Button id="show_errors" onClick={() => openDialog(item, 'error')} size="small" type="primary">
            {i18n.getTranslation('import_jobs.show_errors')}
          </Button>
        ) : (
          i18n.getTranslation('general.none')
        ),
      propertyErrors:
        item.propertyErrors.length > 0 ? (
          <Button
            id="show_property_errors"
            onClick={() => openDialog(item, 'propertyError')}
            size="small"
            type="primary"
          >
            {i18n.getTranslation('import_jobs.show_errors')}
          </Button>
        ) : (
          i18n.getTranslation('general.none')
        )
    };
  };

  const { rows, loading } = useInfiniAPI<ImportJobItemsType, DetailsRowType>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord: processRecord
  });

  return {
    rows,
    loading
  };
};

export default useProcessingDetails;
