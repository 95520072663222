import { useAppContext } from '../../app-context/app-context';

export type Props = {
  permissionsKey?: string;
  children: JSX.Element;
  fallBack?: JSX.Element | null;
};

export default function AccessControl(props: Props) {
  const { permissionsKey, children, fallBack = null } = props;
  const { authReducer } = useAppContext();
  const { hasPermission } = authReducer;

  const isPermitted = permissionsKey ? hasPermission(permissionsKey) : true;

  return isPermitted ? children : fallBack;
}
