import { MultiValue } from 'design-system/ComponentSets';
import React, { useState } from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { MeasurementDetailsType } from '@zf/api-types/master-data/move-request';
import { groupBy } from '@zf/utils/src/array';
import { MIN_DATE, startOfDay } from '@zf/utils/src/date';

import { useAppContext } from '../../../../../../app-context';
import MeterAutoFill from '../../../../../../components/Autofills/MeterAutoFill';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import useActivities from '../../hooks/useActivities';
import css from './measurement.module.scss';
import MeasurementMultiValue from './MeasurementMultiValue';

type Props = {
  measurementDetails: MeasurementDetailsType[];
};

export type MeterNodeValueType = {
  internalMeterId: string;
  measurements: MeasurementDetailsType[];
};

const initialNodeValue: MeterNodeValueType = {
  internalMeterId: '',
  measurements: [] as MeasurementDetailsType[]
};

export default function MeterMultiValue(props: Props) {
  const { measurementDetails } = props;
  const { i18n } = useAppContext();
  const { task, existingLocation, existingMeters, existingContract, setMeasurementDetails, getActivityStatus } =
    useActivities();
  const [isEmpty, setIsEmpty] = useState(false);

  if (!task) return null;

  const groupedByMeter: Record<string, MeasurementDetailsType[]> = groupBy(measurementDetails, 'internalMeterId');

  const initialValues = Object.keys(groupedByMeter).map((key) => {
    if (existingMeters && groupedByMeter[key][0]) {
      const matchingMeter = existingMeters.find((m) => m.id === groupedByMeter[key][0].internalMeterId);

      if (matchingMeter) {
        groupedByMeter[key].forEach((mdt) => {
          const matchingChannel = matchingMeter.channels.find(
            (chann) => chann.externalIdentifier === mdt.externalChannelIdentifier
          );

          mdt.meterSerialNumber = matchingMeter.serialNumber;

          if (matchingChannel) {
            mdt.timeOfUse = matchingChannel.timeOfUse;
            mdt.unitOfMeasure = matchingChannel.unitOfMeasure;
            mdt.utilityType = matchingChannel.utilityType;
          }
        });

        return {
          internalMeterId: matchingMeter.id,
          measurements: groupedByMeter[key]
        };
      }
    }

    return {
      internalMeterId: groupedByMeter[key] && groupedByMeter[key][0] ? groupedByMeter[key][0].internalMeterId : '',
      measurements: groupedByMeter[key]
    };
  });

  const handleChange = (values: MeterNodeValueType[]) => {
    if (values.length === 0) {
      setIsEmpty(true);
    } else if (isEmpty) {
      setIsEmpty(false);
    }
    const measurements: MeasurementDetailsType[] = [];
    values.forEach((mnv) => {
      mnv.measurements.forEach((m) => {
        m.internalMeterId = mnv.internalMeterId;
      });

      measurements.push(...mnv.measurements);
    });

    setMeasurementDetails(measurements);
  };

  return (
    <>
      <MultiValue
        id="meters"
        title={i18n.getTranslation('meter.meter')}
        initialValues={initialValues}
        intialNodeValue={initialNodeValue}
        onChange={handleChange}
        oneEntryRequired={false}
      >
        {({ value, index: meterIndex, dispatchValue }) => {
          let existingMeter: MeterType | undefined = undefined;

          if (existingMeters) {
            existingMeter = existingMeters.find((em) => {
              if (value.measurements[0]) {
                return em.id === value.measurements[0].internalMeterId;
              }
              return undefined;
            });
          }

          return (
            <>
              <MeterAutoFill
                id={`select-meter-${meterIndex}`}
                className={css['meter-autofill']}
                onChange={(val) => dispatchValue({ internalMeterId: val[0]?.id || '' })}
                selectedValues={[value.internalMeterId]}
                placeholder={i18n.getTranslation('location.search_meter')}
                query={{
                  sluuids: [task.serviceLocationDetails.internalId || existingLocation?.id || '']
                }}
                error={!value.internalMeterId}
              />

              <div className={css['measurement-multiValue']}>
                <MeasurementMultiValue
                  meterIndex={meterIndex}
                  existingMeter={existingMeter}
                  existingContract={existingContract}
                  meterNodeValue={value}
                  mutationDateTime={startOfDay(task.mutationDateTime || MIN_DATE).toISOString()}
                  dispatchValue={dispatchValue}
                />
              </div>
            </>
          );
        }}
      </MultiValue>
      {isEmpty && (
        <EmptyColumn
          className={css['empty-state']}
          image="meter"
          title={i18n.getTranslation('meter.measurements')}
          text={
            getActivityStatus('confirm_measurements') === 'complete'
              ? i18n.getTranslation('tasks.no_measurements_empty_state_complete')
              : i18n.getTranslation('tasks.no_measurements_empty_state')
          }
        />
      )}
    </>
  );
}
