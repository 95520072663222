import Interweave from 'interweave';
import React from 'react';

import { CreateFormulaFunctionType } from '@zf/api-types/property-group-billing-configuration';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';

type Props = {
  formulaFunction: CreateFormulaFunctionType;
  deleteFunction: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { formulaFunction, deleteFunction } = props;

  const { i18n, enumReducer } = useAppContext();

  const functionName = enumReducer.getTranslation('formulaFunctionType', formulaFunction.functionType);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        deleteFunction();

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_formula_func_success', {
            functionName: functionName
          })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_formula_func_fail', {
            functionName: functionName
          }),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={i18n.getTranslation('property_groups.tabs.billing.delete_formula_function_paragraph', {
          functionName: functionName
        })}
      />
    </Paragraph>
  );
});
