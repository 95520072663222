import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ServiceType } from '@zf/api-types/master-data/servicelocation';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import AsyncCardSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncCardSpinner';

import { useStore } from '../../../../../hooks/useStore';
import { getCurrentContract } from '../../../../../utils/location';
import { getServiceStatus } from '../../../../../utils/serviceState';
import css from './service-card.module.scss';
import ServiceCardBody from './ServiceCardBody';

type Props = {
  locationId: string;
  services: ServiceType[];
};

const ServiceCard = (props: Props) => {
  const { locationId, services } = props;
  const { applicationStore, serviceLocationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { isRecalculating, getEavsAndCheckEstimationInProgress, reset } = serviceLocationStore.forecastingService;

  useEffect(() => {
    let id = '';
    services.forEach((s) => {
      const contractId = getCurrentContract(s)?.contractId;
      if (contractId) {
        id = contractId;
      }
    });

    getEavsAndCheckEstimationInProgress(id);

    // Clear interval & reset eavs when navigating
    return () => reset();
  }, [locationId, isRecalculating]);

  const cardTitle = getTranslation('cards.service.title');
  const activeServices = services.filter((s) => !!getServiceStatus(s.statusHistory));

  if (activeServices.length === 0)
    return (
      <Card id="service-card" width="1">
        <CardHeader>{cardTitle}</CardHeader>
        <CardEmptyBody
          image={
            <img
              src="https://cdn.zerofriction.co/shared/assets/emptyStates/services2.png"
              alt="service"
              title="service"
              width="70"
              height="70"
            />
          }
          title={getTranslation('cards.service.no_services')}
        >
          {getTranslation('cards.service.no_services_description')}
        </CardEmptyBody>
      </Card>
    );

  return (
    <AsyncCardSpinner title={cardTitle} variables={[isRecalculating ? undefined : true]}>
      <Card id="service-card" width="1">
        <CardHeader>{cardTitle}</CardHeader>
        <CardBody fixedHeight type="grid" className={css['service-card-content']}>
          <ServiceCardBody locationId={locationId} services={services} />
        </CardBody>
      </Card>
    </AsyncCardSpinner>
  );
};

export default observer(ServiceCard);
