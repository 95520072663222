import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';

import { useAppContext } from '../../../../app-context';
import Button from '../../../../components/Button/Button';
import ConfigColumn from '../../../../components/Column/ConfigColumn';
import { notify } from '../../../../events/notification-events';
import { METHODS, sendRequest } from '../../../../utils/request';
import AttachmentSignatures from './AttachmentSignatures';
import Comment from './Comment';
import Details from './Details';
import useActivities from './hooks/useActivities';
import Contract from './Contract';
import { moveRequestStatus, moveRequestType } from '@zf/api-types/enums';

type Props = {
  task: MoveRequestType;
};

export default function LeftColumn(props: Props) {
  const { task } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { initialTask, setTask } = useActivities();

  const disableSave = JSON.stringify(task.mutationDateTime) !== JSON.stringify(initialTask?.mutationDateTime);

  const handleSubmit = async () => {
    try {
      const updatedTask = (
        await sendRequest<MoveRequestType>({
          request: {
            method: METHODS.POST,
            endpoint: `/md/MoveRequests/${task.id}/updateMutationDateTime`,
            data: {
              mutationDateTime: task.mutationDateTime
            }
          },
          tenantReducer: tenantReducer,
          lang: i18n.lang
        })
      ).data;

      setTask({ mutationDateTime: updatedTask.mutationDateTime, ...task }, true);

      notify.success({
        content: i18n.getTranslation('tasks.update_contract_success')
      });
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('tasks.update_contract_fail'),
        error: e
      });
    }
  };

  return (
    <ConfigColumn
      title={enumReducer.getTranslation('moveRequestType', task.moveRequestType)}
      extraRight={
        <Button id="tasks.save-details" onClick={handleSubmit} disabled={!disableSave}>
          {i18n.getTranslation('general.save')}
        </Button>
      }
    >
      <Details task={task} />
      {task.customMessage && <Comment task={task} />}
      {task.moveRequestType === moveRequestType.moveout && (
        <Contract
          contractDetails={task.serviceLocationDetails.currentContractDetails}
          label={
            task.status === moveRequestStatus.processed
              ? i18n.getTranslation('tasks.previous_contract')
              : i18n.getTranslation('tasks.current_contract')
          }
        />
      )}

      {task.moveRequestType === moveRequestType.movein && (
        <>
          {task.status === moveRequestStatus.processed && (
            <Contract
              contractDetails={task.serviceLocationDetails.previousContractDetails}
              label={i18n.getTranslation('tasks.previous_contract')}
            />
          )}

          <Contract
            contractDetails={task.serviceLocationDetails.currentContractDetails}
            label={
              task.status === moveRequestStatus.processed
                ? i18n.getTranslation('contracts.wizard.new_contract')
                : i18n.getTranslation('tasks.current_contract')
            }
          />
        </>
      )}

      <AttachmentSignatures task={task} />
    </ConfigColumn>
  );
}
