import React from 'react';

import { InvoiceRowType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import useCurrent from '../../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import ExportDialog from '../dialogs/ExportDialog';

const ExportListPageButton = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { clickRef, onSubmit, validationRef } = useDialog();

  const disabled = !selectedRows || selectedRows.length === 0;
  const title = getTranslation('general.export');

  return (
    <DropdownAction
      id="invoice.export"
      icon="export"
      dialogTitle={title}
      dialogContent={<ExportDialog ref={clickRef} selectedRows={selectedRows} validationRef={validationRef} />}
      validationRef={validationRef}
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={title}
    />
  );
};

export default ExportListPageButton;
