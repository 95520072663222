import React from 'react';

import {
  OutgoingBankingTransactionRowType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import CancelOutgoingBankingTransactionDialog from '../../../actions/outgoing-banking-transactions/CancelOutgoingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function CancelOutgoingBankingTransactionListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('outgoing_banking_transaction');
  const selectedRows: OutgoingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<OutgoingBankingTransactionType>('outgoing_banking_transaction');
  const { clickRef, onSubmit } = useDialog();

  const onComplete = (updatedRows: OutgoingBankingTransactionType[]) => {
    setUpdatedRows(updatedRows);
    refreshCounts();
  };

  return (
    <DropdownAction
      id="outgoing_banking_transaction.cancel"
      icon="stop"
      dialogTitle={i18n.getTranslation('general.cancel')}
      dialogContent={
        <CancelOutgoingBankingTransactionDialog onComplete={onComplete} selectedRows={selectedRows} ref={clickRef} />
      }
      onSubmit={onSubmit}
      disabled={
        selectedRows.length === 0 ||
        selectedRows.some((r) => r.__entity.status !== outgoingBankingTransactionStatus.toconfirmbybank)
      }
      buttonPositive={i18n.getTranslation('general.cancel')}
    />
  );
}
