import classNames from 'classnames';
import { Moment } from 'moment';
import React, { Dispatch, SetStateAction } from 'react';

import { AddMeasurementRowType } from '@zf/api-types/master-data/meter';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../../../../app-context';
import { Icon } from '../../../../../components/Icon';
import MeasurementRow from './measurement-row';
import css from './terminate-style.module.scss';

type Props = {
  measurementRows: AddMeasurementRowType[];
  hasMeasurement: boolean;
  mutationDateTime: Moment;
  setMeasurementRows: Dispatch<SetStateAction<AddMeasurementRowType[]>>;
  setHasMeasurement: (val: boolean) => void;
};

export default function MeasurementRows(props: Props) {
  const { measurementRows, hasMeasurement, mutationDateTime, setMeasurementRows, setHasMeasurement } = props;
  const { i18n } = useAppContext();

  if (measurementRows.length === 0) return null;

  const setMeasurementValue = (index: number, channelIndex: number, val: number) => {
    const measurementsCopy = [...measurementRows];
    measurementsCopy[index].channels[channelIndex].value = val;
    setMeasurementRows(measurementsCopy);
  };

  const result = measurementRows.map((mr, index) => {
    return (
      <MeasurementRow
        index={index}
        key={`${mr.meter.id}-${index}`}
        measurementRow={mr}
        hasMeasurement={hasMeasurement}
        measurementRows={measurementRows}
        mutationDateTime={mutationDateTime}
        setMeasurementValue={setMeasurementValue}
      />
    );
  });

  return (
    <>
      <div className={css['wrapper']}>
        <div className={classNames(css['icon'])}>{<Icon type="measurement-add" />}</div>
        <Heading headingType="h2" className={css['popup-title']}>
          {i18n.getTranslation('actions.contract.optional_measurement')}
        </Heading>
      </div>
      <div className={css['move-in-wrapper']}>
        <CheckBox id="add-measurement-question" checked={hasMeasurement} onChange={setHasMeasurement}>
          {i18n.getTranslation('actions.contract.opt_measurement_desc')}
        </CheckBox>
      </div>
      {result}
    </>
  );
}
