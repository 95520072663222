import ContractAutoFill from 'components/Autofills/ContractAutoFill';
import TableServices from 'components/Services/TableServices';
import { ICON_COLORS } from 'constants/icons';
import { DetailLine, InfoGrid } from 'design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { getAdvanceAmountFromContract, getUniqueServicesForContract } from 'utils/contract';

import { contractStatus } from '@zf/api-types/enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatPeriod } from '@zf/utils/src/date';

import { supplyDateType } from '../../../shared/SupplyDateInputs/SupplyDateInputs';
import css from './existing-contract.module.scss';

const ExistingContract = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { moveInWizardBaseStore, contractApiService, getRightProduct, getProductText } = contractStore;
  const { getBillingRelation } = contractApiService;
  const { addLocationsMoveInWizardValues, locationSectionStore } = moveInWizardBaseStore.addLocationsMoveInWizardStore;
  const { values, setValue } = addLocationsMoveInWizardValues;
  const { setContractId, setEmbeddedLocationListPageQuery } = locationSectionStore;
  const { existingContract, supplyDate } = values;

  useEffect(() => {
    setEmbeddedLocationListPageQuery({
      excludeServiceLocationsSuppliedInContractId: existingContract?.id || '',
      referenceDateTime: supplyDate?.toISOString()
    });
  }, [supplyDate, existingContract?.id]);

  return (
    <>
      <ContractAutoFill
        id="contracts.existing-contract"
        className={css['contract-autofill']}
        onChange={(val) => {
          const selectedContract = val[0] || null;

          if (selectedContract) {
            const productId = getRightProduct(selectedContract.billingDetails.products, selectedContract)?.productId;

            getBillingRelation(selectedContract.id, selectedContract.contractor.customerId)
              .then((billingrelation) => {
                const advanceAmount = getAdvanceAmountFromContract(billingrelation, selectedContract);

                setValue(
                  selectedContract.currentContractStatus === contractStatus.draft
                    ? {
                        existingContract: selectedContract,
                        productId,
                        advanceAmount,
                        supplyDateType_: supplyDateType.startofcontract,
                        supplyDate: moment(selectedContract.supplyStartDate)
                      }
                    : {
                        existingContract: selectedContract,
                        productId,
                        advanceAmount,
                        supplyDateType_: supplyDateType.specific,
                        supplyDate: supplyDate
                      },
                  true
                );
                setContractId(selectedContract.id);
                setEmbeddedLocationListPageQuery({
                  excludeServiceLocationsSuppliedInContractId: selectedContract.id,
                  referenceDateTime: selectedContract.supplyStartDate
                });
              })
              .catch((error) => {
                notify.error({
                  content: getTranslation('contracts.get_billing_relation_fail'),
                  error
                });
              });
          } else {
            setValue({ existingContract: null, productId: undefined });
            setContractId(undefined);
          }
        }}
        selectedValues={[existingContract?.id || '']}
        error={!existingContract}
      />

      {existingContract && (
        <InfoGrid nCols={2} className={css['existing-contract-info']} type="grey">
          <DetailLine label={getTranslation('general.period')}>
            <Paragraph>{formatPeriod(existingContract.supplyStartDate, existingContract.supplyEndDate)}</Paragraph>
          </DetailLine>

          <DetailLine label={getTranslation('general.status')}>
            <NewStatusBadge
              status_={getEnumTranslation('contractStatus', existingContract.currentContractStatus)}
              color={ICON_COLORS[existingContract.currentContractStatus]}
              type="bare"
            />
          </DetailLine>

          <DetailLine label={getTranslation('contracts.service')}>
            <TableServices utilityTypes={getUniqueServicesForContract(existingContract)} />
          </DetailLine>

          <DetailLine label={getTranslation('product_config.product')}>
            <Paragraph>{getProductText(existingContract)}</Paragraph>
          </DetailLine>
        </InfoGrid>
      )}
    </>
  );
};

export default observer(ExistingContract);
