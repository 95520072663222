import Interweave from 'interweave';
import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import { DomainType } from '../../app-context/app-state';
import ForecastingService from '../../app-context/services/ForecastingService';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../design-system/Foundation';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';

type Props = {
  entity: DomainType;
  selectedRows: string[];
  forecastingService: ForecastingService;
};

const RecalculateEavDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { forecastingService, entity, selectedRows } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { recalculateContractEav, recalculateLocationEav, setIsRecalculating } = forecastingService;

  const save = async (recalcFunc: (id: string) => Promise<void>, selectedRows: string[]) => {
    if (selectedRows.length > 1) {
      await Promise.all(selectedRows.map((id) => recalcFunc(id)));
    } else if (selectedRows.length === 1) {
      await recalcFunc(selectedRows[0]);
      setIsRecalculating(true);
    }
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        switch (entity) {
          case 'location': {
            await save(recalculateLocationEav, selectedRows);
            break;
          }
          case 'contract': {
            await save(recalculateContractEav, selectedRows);
            break;
          }

          default: {
            //do nothing
          }
        }

        notify.info({
          icon: 'calculator',
          content: getTranslation('actions.recalculate_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('actions.recalculate_failed'),
          error: e
        });
      }
    }
  }));
  return (
    <Paragraph>
      <Interweave content={getTranslation('actions.recalculate_eav_desc')}></Interweave>
    </Paragraph>
  );
});

export default observer(RecalculateEavDialog);
