import React, { useEffect, useState } from 'react';

import { AgingReportRowType, AgingReportType } from '@zf/api-types/aging-report';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../app-context/app-context';
import { ConfigHelp } from '../../../components/CoachMarks';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { CreateRequestReturnType } from '../../../hooks/useCreateRequest';
import { Result } from '../../../hooks/useInfiniAPI';
import css from './aging-report-card.module.scss';
import { colors } from '@zf/utils/src/color';
import FilterInputs from '../../../components/Filters/filter-inputs';
import { useStore } from '../../../hooks/useStore';
import { State } from './hooks/useCustomerAgingFilter';
import ExportToExcel from './ExportToExcel';

const NoReports = () => {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/transaction.png"
          alt="aging report"
          title="aging report"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('dashboard.customer_aging.aging_report')}
    >
      {i18n.getTranslation('dashboard.customer_aging.no_reports_descr')}
    </CardEmptyBody>
  );
};

type Props = {
  requestTools: CreateRequestReturnType;
  rowTools: Result<AgingReportType, AgingReportRowType>;
  filters: JSX.Element[];
  tags: JSX.Element[];
  filterState: State;
  clearAllFilters: () => void;
};

export default function AgingReportCard(props: Props) {
  const { requestTools, rowTools, filters, filterState, tags, clearAllFilters } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = requestTools;
  const { loading, error, rows, sortableFields, totalAmountOfRows, setStopIndex } = rowTools;

  const [columns, setColumns] = useState([
    {
      width: 250,
      label: getTranslation('customer.customer'),
      dataKey: 'customer'
    },
    {
      width: 220,
      label: getTranslation('dashboard.customer_aging.oldest_open'),
      dataKey: 'oldestOpenInvoice'
    },
    {
      width: 180,
      label: getTranslation('dashboard.customer_aging.last_payment'),
      dataKey: 'lastPayment'
    },
    {
      width: 180,
      label: getTranslation('dashboard.customer_aging.open_credits'),
      dataKey: 'openCredits'
    },
    {
      width: 130,
      label: getTranslation('dashboard.customer_aging.current'),
      dataKey: 'current',
      color: colors['blue-600']
    },
    {
      width: 130,
      label: '< 30d',
      dataKey: '0-30',
      color: colors['yellow-600']
    },
    {
      width: 130,
      label: '31-60d',
      dataKey: '31-60',
      color: colors['orange-600']
    },
    {
      width: 130,
      label: '61-90d',
      dataKey: '61-90',
      color: colors['red-600']
    },
    {
      width: 130,
      label: '> 90d',
      dataKey: 'moreThan90',
      color: colors['red-700']
    },
    {
      width: 180,
      label: getTranslation('dashboard.customer_aging.collection_costs'),
      dataKey: 'collectionCosts'
    },
    {
      width: 130,
      label: getTranslation('dashboard.customer_aging.total_open_amount'),
      dataKey: 'totalOpen'
    }
  ]);

  useEffect(() => {
    const columnCredit = {
      width: 180,
      label: getTranslation('dashboard.customer_aging.open_credits'),
      dataKey: 'openCredits'
    };

    const columnsClone = [...columns];
    const index = columnsClone.findIndex((c) => c.dataKey === 'openCredits');

    if (filterState.creditAmounts) {
      if (index === -1) {
        columnsClone.splice(3, 0, columnCredit);
      }
    } else {
      columnsClone.splice(index, 1);
    }
    setColumns(columnsClone);
  }, [filterState.creditAmounts]);

  return (
    <Card id="aging-report-card" width="4">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={getTranslation('coachmark.aging_report.title')}
            content={[getTranslation('coachmark.aging_report.paragraph')]}
          />
        }
        filters={
          <FilterInputs
            className={css['aging-reports-filter']}
            filterInputs={filters}
            removeAllFilters={clearAllFilters}
          />
        }
        extraRight={<ExportToExcel filterState={filterState} />}
        tags={tags}
      >
        {getTranslation('dashboard.customer_aging.aging_report')}
      </CardHeader>
      <CardBody className={css['dashboard-customer-aging-card-content']} fixedHeight>
        <DynamicVirtualTable
          id="aging-reports-table"
          tooltipId="aging-reports-table-tip"
          rowHeight={60}
          onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
          rows={rows}
          sortBy={sortBy}
          sortDirection={sortDirection}
          showCheckBoxes={false}
          singleSort
          loading={loading}
          error={error}
          sortableFields={sortableFields}
          selectedRows={selectedIds}
          totalAmountOfRows={totalAmountOfRows}
          singleSelect
          sort={handleSort}
          NoDataOverlay={NoReports}
          onSelectRow={setSelectedIds}
          columns={columns}
        />
      </CardBody>
    </Card>
  );
}
