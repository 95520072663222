import classNames from 'classnames';
import React from 'react';

import { Paragraph } from '../Paragraph';
import css from './loader.module.scss';

export type LoaderProps = {
  text?: string;
  className?: string;
};

export default function StellaLoader(props: LoaderProps) {
  const { text = 'Loading items...', className } = props;

  return (
    <div className={css['loader-wrapper']}>
      <div className={classNames(css['loading-wrapper'], className)}>
        <div className={css['loader']} />
      </div>
      <Paragraph className={css['loading-text']}>{text}</Paragraph>
    </div>
  );
}
