import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React from 'react';

import { contractStatus } from '@zf/api-types/enums';
import { ContractType } from '@zf/api-types/master-data/contract';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';
import { capitalize } from '@zf/utils/src/string';

import PaymentTermsDropdown from '../../../../../../components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';
import { InlineInputFieldInput } from '../../../../../../components/input/InputField';
import { Link } from '../../../../../../design-system/Components';
import { useStore } from '../../../../../../hooks/useStore';

export type PropsType = {
  contract: ContractType;
};

const ContractDetailsCard = (props: PropsType) => {
  const { contract } = props;
  const { contractStore, applicationStore } = useStore();
  const { contractDetailsForm, getProductText } = contractStore;
  const { getTranslation } = applicationStore;

  const form = contractDetailsForm;
  const { _isDirty } = form;

  let signDate: Moment | null = null;

  for (const history of contract.statusHistory) {
    if (history.contractStatus === contractStatus.signed) {
      signDate = moment(history.mutationDateTime);
    }
  }

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      form._reset();
    } else if (event.key === 'Enter') {
      form._submit();
      if (document.activeElement) {
        const elem = document.activeElement as HTMLElement;
        elem.blur();
      }
    }
  };

  const productName = getProductText(contract);

  return (
    <Card id="contract-detail-card" role="dialog" className="card">
      <CardHeader
        primaryText={_isDirty ? getTranslation('general.save') : undefined}
        onPrimaryClick={() => form.save()}
        secondaryText={_isDirty ? getTranslation('general.cancel') : undefined}
        onSecondaryClick={() => form._reset()}
      >
        {getTranslation('general.details')}
      </CardHeader>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{getTranslation('contracts.contract_id')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right" id="contractNumber">
            {contract.contractNumber}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.contractor')}</Label>
        </CardItem>
        <CardItem width="6" justifyContent="end">
          <Link id="contractor" url={`${applicationStore.rootUrl}/customers/${contract.contractor.customerId}`} bold>
            {`${contract.contractor.accountNumber} - ${contract.contractor.shortDisplayName}`}
          </Link>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.current_product')}</Label>
        </CardItem>
        <CardItem width="6" justifyContent="end">
          {contract.usePropertyGroupProduct ? (
            <Link
              id="current_product"
              url={`${applicationStore.rootUrl}/property-groups/${contract.propertyGroups?.[0]?.id}`} // only one can be present in this case
              bold
            >
              {getTranslation('contracts.prod_prop_lvl')}
            </Link>
          ) : (
            <Link
              id="current_product"
              url={`${applicationStore.rootUrl}/configuration/product?productName=${capitalize(productName)}`}
              bold
            >
              {capitalize(productName)}
            </Link>
          )}
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.start')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right" id="supplyStartDate">
            {formatDate(contract.supplyStartDate)}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.end')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right" id="supplyEndDate">
            {formatDate(contract.supplyEndDate)}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.sign_date')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right" id="sign_date">
            {formatDate(signDate)}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('payment_terms.payment_term')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <PaymentTermsDropdown
            id="contract.payment_term"
            onChange={(val) => form._set('paymentTerm', val[0]?.id || '')}
            selectedValue={form._get('paymentTerm')}
            isInline
          />
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.external_ref')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <InlineInputFieldInput
            id="contract.update_extrenalreference"
            onKeyDown={keyHandler}
            value={form._get('externalReferenceId')}
            onChange={(val) => form._set('externalReferenceId', val)}
            error={!!form._error('externalReferenceId')}
            singleError={form._error('externalReferenceId')}
          />
        </CardItem>
      </CardBody>
    </Card>
  );
};

export default observer(ContractDetailsCard);
