import React, { memo } from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { CardBody, Link } from '../../../design-system/Components';
import { SettingDescription } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

const EmailCommunicationCard = () => {
  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  return (
    <Card id="portal-config-card" width="3">
      <CardHeader>{getTranslation('portal_config.email_communication')}</CardHeader>
      <CardBody>
        <SettingDescription
          title={getTranslation('portal_config.email_templates')}
          description={getTranslation('portal_config.email_templates_extra_info')}
          setting={
            <Link id="communication" bold url={`${rootUrl}/configuration/communication`}>
              {getTranslation('portal_config.email_templates_to')}
            </Link>
          }
        />
      </CardBody>
    </Card>
  );
};

export default memo(EmailCommunicationCard);
