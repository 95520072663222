import {
  IncomingMutationType,
  IncomingOutgoingTransferDetailsType,
  IncomingPaymentRequestMutationConfirmationDetailsType,
  IncomingPaymentRequestRefusalDetailsType,
  IncomingRefundMutationConfirmationDetailsType,
  IncomingRefundRefusalDetailsType,
  IncomingTransferDetailsType,
  IncomingUnknownDetails
} from '@zf/api-types/billing/incoming-mutations';
import { incomingMutationType } from '@zf/api-types/enums';

import { outgoingMutationStatus } from '@zf/api-types/enums';

export const getQueryStatus = (mutation: IncomingMutationType) => {
  if (
    mutation.type === incomingMutationType.paymentrequestmutationconfirmation ||
    mutation.type === incomingMutationType.refundmutationconfirmation
  ) {
    return outgoingMutationStatus.toconfirmbybank;
  } else {
    return [];
  }
};

export const getCustomerBankAccountFromIncomingMutation = (mutation: IncomingMutationType) => {
  switch (mutation.type) {
    case incomingMutationType.incomingtransfer: {
      const castedDetails = mutation.details as IncomingTransferDetailsType;
      return castedDetails.iban;
    }
    case incomingMutationType.outgoingtransfer: {
      const castedDetails = mutation.details as IncomingOutgoingTransferDetailsType;
      return castedDetails.iban;
    }
    case incomingMutationType.paymentrequestmutationconfirmation: {
      const castedDetails = mutation.details as IncomingPaymentRequestMutationConfirmationDetailsType;
      return castedDetails.iban;
    }
    case incomingMutationType.refundmutationconfirmation: {
      const castedDetails = mutation.details as IncomingRefundMutationConfirmationDetailsType;
      return castedDetails.iban;
    }
    case incomingMutationType.paymentrequestreversal: {
      const castedDetails = mutation.details as IncomingPaymentRequestRefusalDetailsType;
      return castedDetails.iban;
    }
    case incomingMutationType.refundreversal: {
      const castedDetails = mutation.details as IncomingRefundRefusalDetailsType;
      return castedDetails.iban;
    }
    case incomingMutationType.refundtransactionconfirmation:
    case incomingMutationType.paymentrequesttransactionconfirmation: {
      return '-';
    }
    case incomingMutationType.unknown: {
      const castedDetails = mutation.details as IncomingUnknownDetails;
      if (castedDetails.iban !== null) {
        return castedDetails.iban;
      } else {
        return '-';
      }
    }
  }
};
