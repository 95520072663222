import { observer } from 'mobx-react';
import React from 'react';

import { InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import AssignToCaseDialog from '../dialogs/assign-to-case-dialog';

type Props = {
  invoice: InvoiceType;
};

const AssignToCaseListItem = observer((props: Props) => {
  const { invoice } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { invoiceStore } = useStore();

  if (invoiceStore.pageActionPermissions.mayAssignToCase) {
    return (
      <DropdownAction
        id="collection_case.assign_to_case"
        icon="plus"
        dialogTitle={i18n.getTranslation('actions.invoice.assign_to_case')}
        dialogContent={<AssignToCaseDialog invoice={invoice} ref={clickRef} validationRef={validationRef} />}
        onSubmit={onSubmit}
        validationRef={validationRef}
        buttonPositive={i18n.getTranslation('actions.invoice.assign_to_case')}
      />
    );
  }

  return null;
});

export default AssignToCaseListItem;
