import React from 'react';

import { formulaFunctionCategory } from '@zf/api-types/enums';
import { FunctionType } from '@zf/api-types/property-group-billing-configuration';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Tree, TreeItem } from '@zf/stella-react/src/atoms/Tree';
import { groupBy } from '@zf/utils/src/array';

import { useAppContext } from '../../../../app-context';
import css from './formula-builder.module.scss';

type Props = {
  functions: FunctionType[];
  addFormulaFunction: (category: formulaFunctionCategory, function_: FunctionType) => void;
};

export default function Functions(props: Props) {
  const { functions, addFormulaFunction } = props;
  const { i18n, enumReducer } = useAppContext();

  const groupByCategory: Record<string, FunctionType[]> = groupBy(functions, 'functionCategory');

  return (
    <div className={css['formulas-div']}>
      <Heading headingType="h2" style="bold">
        {i18n.getTranslation('property_groups.tabs.billing.functions')}
      </Heading>
      <div className={css['formula-trees']}>
        {Object.keys(groupByCategory).map((key) => {
          const values = groupByCategory[key];

          return (
            <Tree
              id={key}
              key={key}
              style={{ paddingBottom: '1rem' }}
              title={enumReducer.getTranslation('formulaFunctionCategory', key)}
              arrowType="filled"
              openOnInit
            >
              {values.map((function_) => {
                return (
                  <TreeItem
                    id={function_.functionType}
                    key={function_.functionType}
                    className={css['function']}
                    onClick={() => addFormulaFunction(key as formulaFunctionCategory, function_)}
                  >
                    {enumReducer.getTranslation('formulaFunctionType', function_.functionType)}
                  </TreeItem>
                );
              })}
            </Tree>
          );
        })}
      </div>
    </div>
  );
}
