import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../hooks/useStore';
import css from '../integration-logging.module.scss';

const LoggingDialog = forwardRef((props, ref: Ref<DialogClickRef | undefined>) => {
  const { integrationStore, applicationStore } = useStore();
  const { selectedPluginDetail } = integrationStore.integrationLoggingStore;

  useImperativeHandle(ref, () => ({
    async onClick() {}
  }));

  return (
    <>
      {selectedPluginDetail?.logs.length ? (
        selectedPluginDetail.logs.map((log, index) => {
          return <Paragraph className={css['logging-list-item']} bold id={log}>{`${index + 1}) ${log}`}</Paragraph>;
        })
      ) : (
        <EmptyChartState title={applicationStore.getTranslation('cards.integration_logging.no_logs')} />
      )}
    </>
  );
});

export default observer(LoggingDialog);
