import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import { useStore } from '../../../hooks/useStore';

const RetriggerDialog = forwardRef((props, ref: Ref<DialogClickRef | undefined>) => {
  const { integrationStore, applicationStore } = useStore();
  const { selectedIds, retrigger } = integrationStore.integrationLoggingStore;
  const { getTranslation } = applicationStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await retrigger(selectedIds[0]);
        notify.success({
          content: getTranslation('cards.integration_logging.retrigger_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('cards.integration_logging.retrigger_failed'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{getTranslation('cards.integration_logging.retrigger')}</Paragraph>;
});

export default observer(RetriggerDialog);
