export type EventCallback<T> = (e: T) => void;

export default class EventEmitter<T> {
  eventListeners: Map<string, Set<(value: T) => void>>;

  constructor() {
    this.eventListeners = new Map();
  }

  _getCallbacks(key: string) {
    let eventCallbacks: Set<EventCallback<T>> = new Set();
    const currValue = this.eventListeners.get(key);
    if (currValue) {
      eventCallbacks = currValue;
    }
    return eventCallbacks;
  }

  on(key: string, callback: (value: T) => void) {
    const eventCallbacks = this._getCallbacks(key);
    eventCallbacks.add(callback);
    this.eventListeners.set(key, eventCallbacks);
  }

  emit(key: string, value: T) {
    const eventCallbacks = this.eventListeners.get(key);
    if (eventCallbacks) {
      for (const eventCallback of eventCallbacks) {
        eventCallback(value);
      }
    }
  }

  removeListener(key: string, callback: (value: T) => void) {
    const eventCallbacks = this._getCallbacks(key);
    eventCallbacks.delete(callback);
    this.eventListeners.set(key, eventCallbacks);
  }
}
