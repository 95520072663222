import { observer } from 'mobx-react';
import React, { useEffect, useReducer } from 'react';

import { tierCalculationMethod } from '@zf/api-types/enums';
import { BillingItemType, BillingTariffType, ProductType } from '@zf/api-types/product';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { colors } from '@zf/utils/src/color';

import { CollapsableInfoBanner } from '../../../../../design-system/ComponentSets';
import { Paragraph, Spinner } from '../../../../../design-system/Foundation';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import css from './tariff-calc-warning.module.scss';

type Props = {
  tierCalcMethod: tierCalculationMethod;
  selectedBillingItem: BillingItemType;
  setIsError: ((error: boolean) => void) | undefined;
};

type State = {
  affectedProducts: ProductType[] | undefined;
  invalidTariffs: BillingTariffType[] | undefined;
};

const TariffCalculationWarning = (props: Props) => {
  const { tierCalcMethod, selectedBillingItem, setIsError } = props;

  const { applicationStore, configStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getProductsForQuery, getTariffsForQuery } = configStore.configService;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    affectedProducts: undefined,
    invalidTariffs: undefined
  });

  useEffect(() => {
    if (selectedBillingItem) {
      let promises: Promise<any[]>[] = [getProductsForQuery({ billingItemId: selectedBillingItem?.id })];

      if (tierCalcMethod === tierCalculationMethod.tariffperiod) {
        promises.push(
          getTariffsForQuery({
            billingItemId: selectedBillingItem?.id,
            unboundedPeriod: true
          })
        );
      }

      Promise.all(promises)
        .then((res) => {
          const invalidTariffs: BillingTariffType[] | undefined = res?.[1];
          setState({ affectedProducts: res[0], invalidTariffs });

          if (setIsError && invalidTariffs && invalidTariffs.length !== 0) {
            setIsError(true);
          }
        })
        .catch((error) => {
          notify.error({
            content: getTranslation('billing_items.affected_products_fetch_fail'),
            error
          });
        });
    }
  }, [tierCalcMethod, selectedBillingItem]);

  if (!state.affectedProducts) return <Spinner size="small" />;

  if (state.affectedProducts.length === 0) return null;

  const affectedProducts = (
    <div className={css['affected-products']}>
      <Paragraph>{getTranslation('billing_items.affected_products_descr')}</Paragraph>
      <ul>
        {state.affectedProducts.map((ap) => (
          <li key={ap.id}>{ap.name}</li>
        ))}
      </ul>
    </div>
  );

  return !!state.invalidTariffs && state.invalidTariffs.length !== 0 ? (
    <CollapsableInfoBanner
      infoText={getTranslation('billing_tariff.calc_dates_warning')}
      btnText={getTranslation('billing_items.show_affected_products')}
      color={colors['red-600']}
    >
      {affectedProducts}
    </CollapsableInfoBanner>
  ) : (
    <CollapsableInfoBanner
      infoText={getTranslation('billing_items.tariff_calculation_warning', { amount: state.affectedProducts.length })}
      btnText={getTranslation('billing_items.show_affected_products')}
      color={colors['orange-600']}
    >
      {affectedProducts}
    </CollapsableInfoBanner>
  );
};

export default observer(TariffCalculationWarning);
