import { Comment } from 'design-system/ComponentSets';
import { Icon, Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  ManualMeteringIssueResolution,
  MeasurementMeteringIssueDetails,
  MeteringIssue,
  MeteringIssueResolution
} from '@zf/api-types/metering/metering-issues';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import { IssueActionType } from '../IssueSidePanel';
import ResolveActions from '../shared/ResolveActions';
import css from './resolved-panel-body.module.scss';

type Props = {
  issueDetails: MeasurementMeteringIssueDetails;
  resolution: MeteringIssueResolution;
  issue: MeteringIssue;
  onSubmit: (type: IssueActionType) => Promise<void>;
};

const ResolvedPanelBody = (props: Props) => {
  const { issueDetails, resolution, onSubmit, issue } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation, userStore } = applicationStore;
  const { users, cacheMatchingUser } = userStore;

  const resolutionDetails = resolution.details as ManualMeteringIssueResolution;

  const getUserById = (userId: string | undefined) => {
    if (userId) {
      cacheMatchingUser(userId);
      return users.get(userId)?.userName;
    } else {
      return 'System';
    }
  };

  let resolverUser = getUserById(resolutionDetails.resolvedByUserId);

  return (
    <FlexElements className={css['resolved-panel-body']} flexDirection="column" justifyContent="space-between">
      <div>
        <HorizontalDivider />

        {resolution.type === 'automatic' ? (
          <>
            <Paragraph className={css['resolved-by']} bold>
              {getTranslation('meter_issues.resolved_automatically', {
                date: formatDate(resolution.resolvedDateTime)
              })}
            </Paragraph>
            <Paragraph>{getTranslation(`meter_issues.resolution_${resolution.details.resolvedReason}`)}</Paragraph>
          </>
        ) : (
          <Paragraph className={css['resolved-by']} bold>
            {getTranslation('meter_issues.resolved_by', {
              resolver: resolverUser || '?',
              date: formatDate(resolution.resolvedDateTime)
            })}
          </Paragraph>
        )}

        <FlexElements className={css['before-after']} alignItems="center" justifyContent="space-between">
          <FlexElements flexDirection="column">
            <Paragraph color={colors['silver-700']} bold>
              {getTranslation('meter_issues.old_measurement')}
            </Paragraph>
            <Paragraph color={colors['orange-600']} bold>
              {`${issueDetails.value} ${getEnumTranslation('unitOfMeasure', issueDetails.unitOfMeasure)}`}
            </Paragraph>
          </FlexElements>

          <Icon color={colors['silver-600']} name="chevron-right" />

          <FlexElements flexDirection="column">
            <Paragraph color={colors['silver-700']} bold>
              {getTranslation('meter_issues.new_measurement')}
            </Paragraph>
            <Paragraph color={colors['green-600']} bold>
              {`${resolutionDetails.newValue} ${getEnumTranslation('unitOfMeasure', issueDetails.unitOfMeasure)}`}
            </Paragraph>
          </FlexElements>
        </FlexElements>

        {resolutionDetails.resolvedByUserId && resolutionDetails.message && (
          <Comment user={resolverUser || ''} comment={resolutionDetails.message} />
        )}
      </div>

      <ResolveActions onSubmit={onSubmit} issues={[issue]} isUnresolved={false} />
    </FlexElements>
  );
};

export default observer(ResolvedPanelBody);
