import classNames from 'classnames';
import Interweave from 'interweave';
import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { Paragraph } from '../../../Foundation';
import css from './selection-dialog-layout.module.scss';

type Props = {
  title: string;
  heading: string;
  selectionListHeading: string;
  items: JSX.Element;
  selectedItems: JSX.Element;
  filterTags?: JSX.Element;
  filterInputs?: JSX.Element;
  topInputs?: JSX.Element;
  className?: string;
};

export default function SelectionDialogLayout(props: Props) {
  const { title, heading, selectionListHeading, items, selectedItems, filterTags, filterInputs, topInputs, className } =
    props;

  return (
    <>
      <Paragraph>
        <Interweave content={title} />
      </Paragraph>

      <div className={classNames(css['wrapper'], { [css['with-top-inputs']]: !!topInputs }, className)}>
        {topInputs && (
          <>
            <div>{topInputs}</div>
            <div />
          </>
        )}

        <div className={css['items']}>
          <div className={css['items-header']}>
            <Heading headingType="h2" style="bold">
              {heading}
            </Heading>
            {filterInputs && (
              <div className={css['items-filter']}>
                {filterTags ? filterTags : <div />}
                {filterInputs}
              </div>
            )}
          </div>

          <div className={classNames(css['items-grid'], { [css['items-grid-with-top-inputs']]: !!topInputs })}>
            {items}
          </div>
        </div>
        <div className={css['items-list']}>
          <Heading headingType="h3" style="bold">
            {selectionListHeading}
          </Heading>
          {selectedItems}
        </div>
      </div>
    </>
  );
}
