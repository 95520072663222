/**
 * Used to filter null & undefined entries from array
 * @param value array element
 * @return boolean
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * Used to filter null & undefined entries from array
 * @param array arr
 * @return array without null & undefined
 */
export const removeNullsAndUndefined = <T>(arr: Array<T | null | undefined>): T[] => {
  return arr.filter(notEmpty);
};

/**
 * Used to split an array into new arrays of given length
 * @param array arr
 * @param number length
 * @return array of arrays with given length
 */
export const splitArrayIntoChunksOfLength = <T>(arr: T[], length: number) => {
  if (length === 0) {
    return [arr];
  }

  const chunks = [];
  let i = 0;

  while (i < arr.length) {
    chunks.push(arr.slice(i, (i += length)));
  }

  return chunks;
};

/**
 * Used to filter unique entries of primitive type from an array
 * @param value array element (primitive type)
 * @return boolean
 */
export function onlyUnique<T>(value: T, index: number, arr: T[]) {
  return arr.indexOf(value) === index;
}

/**
 * Used to filter unique objects from an array
 * @param array arr array of objects
 * @return filtered array
 */
export function onlyUniqueObjects<T>(arr: T[]) {
  return Array.from(new Set(arr.map((o) => JSON.stringify(o))), (s) => JSON.parse(s));
}

/**
 * Used to group a given array by an attribute of one of its entries
 * @param array arr
 * @param string key
 * @returns dictionary of arrays per unique key
 */
export const groupBy = (arr: any[], key: string) => {
  return arr.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function FilterArray<T>(value: string, array: T[], attribute: keyof T) {
  const pattern = new RegExp(value, 'gi');
  const dirtyArray = [];
  for (const item of array) {
    //@ts-ignore
    if (pattern.test(item[attribute] as string)) {
      dirtyArray.push(item);
      //const searchIndex = array.findIndex((e) => deepEqual(e,  item));
      return dirtyArray.filter(onlyUnique);
    }
  }
}

/**
 * Used to group a given array by a substring of an attribute of one of its entries
 * @param array arr
 * @param string key
 * @param number pos1 start position of substring
 * @param number pos2 end position of substring
 * @returns dictionary of arrays per unique key
 */
export const groupBySubstring = (arr: any[], key: string, pos1: number, pos2: number) => {
  return arr.reduce(function (rv, x) {
    (rv[x[key].substring(pos1, pos2)] = rv[x[key].substring(pos1, pos2)] || []).push(x);
    return rv;
  }, {});
};

/**
 * Used to group a given array by an attribute of an attribute of one of its entries (key is 2 levels deep)
 * @param array arr
 * @param string key1 attribute of the first level
 * @param string key2 attribute of the second level
 * @returns dictionary of arrays per unique key of the second level
 */
export const groupBySecondLevel = (arr: any[], key1: string, key2: string) => {
  return arr.reduce(function (rv, x) {
    (rv[x[key1][key2]] = rv[x[key1][key2]] || []).push(x);
    return rv;
  }, {});
};

/**
 * Counts the amount of unique entries in an array
 * @param array arr
 * @returns number of unique values
 */
export function getCountsOfUniqueValues<T>(arr: T[]) {
  return arr.reduce((acc: Map<T, number>, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
}

/**
 * Removes objects from an array with a duplicate property/key
 * @param array arr 
 * @param string key 
 * @returns an array with only unique values
 */
export function removeDuplicates<T>(arr: T[], key: keyof T) {
  return arr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[key]).indexOf(obj[key]) === pos;
  });
}

/**
 * Removes the given entry from an array
 * @param value an array entry
 * @param array arr 
 * @returns an array with the entry removed
 */
export const removeFromList = <T>(value: T, arr?: T[]) => {
  if (arr) {
    return arr.filter((item) => {
      return JSON.stringify(item) !== JSON.stringify(value);
    });
  } else return [];
};
