import classNames from 'classnames';
import Interweave from 'interweave';
import React, { useMemo } from 'react';

import CoachMark from '../CoachMark/CoachMark';
import { Paragraph } from '../Paragraph';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './label.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
  color?: string;
  style?: React.CSSProperties;
  info?: string;
};

export default function Label(props: Props) {
  const { children, info, className, bold = false, style = {}, color } = props;

  const enrichedStyle = useMemo(() => {
    return { ...style, color };
  }, [color]);

  return (
    <div className={css['label-wrapper']}>
      <span className={classNames(css['label'], { [css['text-bold']]: bold }, className)} style={enrichedStyle}>
        {children}
      </span>
      {info ? (
        <TooltipTrigger
          tooltip={
            <Paragraph className={css["label-tooltip-wrapper"]}>
              <Interweave content={info} />
            </Paragraph>
          }
        >
          <CoachMark className={css['info']} usePopup={false} size="small" info />
        </TooltipTrigger>
      ) : null}
    </div>
  );
}
