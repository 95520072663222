import clone from 'clone';
import { action, computed, makeObservable, observable } from 'mobx';

import { SortDirection } from '@zf/stella-react/src/atoms/Table/types';

import RootStore from '../../../../app-context/stores';
import { notify } from '../../../../events/notification-events';
import { RequestType } from '../../../../types/Request';
import { createHeader, METHODS } from '../../../../utils/request';
import CrudApiService from './CrudApiService';

class CrudService<T extends { id?: string; delete?: boolean; _etag?: string }> {
  public entities?: T[];
  public initialentities?: T[];
  public endPoint: string;
  public rootStore: RootStore;
  public isLoading: boolean;
  public autoFocus: boolean;
  public sortableFields: Record<string, SortDirection | ''> = {};
  private _crudApiService: CrudApiService<T>;
  private isMock: boolean;

  constructor(endPoint: string, rootStore: RootStore, query?: any, mockData?: T[]) {
    this.endPoint = endPoint;
    this.rootStore = rootStore;
    this.initialentities = mockData;
    this.entities = clone(mockData);
    this.isMock = mockData !== undefined;
    this._crudApiService = new CrudApiService(endPoint, query, rootStore.applicationStore);

    makeObservable(this, {
      entities: observable,
      initialentities: observable,
      loadEntities: action,
      isLoading: observable,
      addEntity: action,
      updateEntity: action,
      deleteEntity: action,
      sort: action,
      reset: action,
      _isDirty: computed
    });
  }

  get _isDirty(): boolean {
    if (this.initialentities && this.entities) {
      return JSON.stringify(this.initialentities) !== JSON.stringify(this.entities);
    }

    return false;
  }

  reset = () => {
    this.entities = clone(this.initialentities);
  };

  loadEntities = async () => {
    this.isLoading = true;
    //api related logic

    if (!this.isMock) {
      const result = await this._crudApiService.getAllEntities();

      this.entities = result.results;
      this.sortableFields = result.sortableFields.reduce((obj: Record<string, SortDirection | ''>, key: string) => {
        obj[key.toLowerCase()] = '';
        return obj;
      }, {});
    } else {
      this.entities = this.initialentities;
    }

    this.initialentities = clone(this.entities);
    this.isLoading = false;
  };

  sort = async (dataKey: string, sortDirection: SortDirection | '') => {
    if (this.sortableFields && !this._isDirty) {
      this.sortableFields[dataKey] = sortDirection;

      const results = (await this._crudApiService.getAllEntities(this.sortableFields)).results;

      if (!this.isMock) {
        this.entities = results;
        this.initialentities = results;
      }
    } else {
      notify.warning({
        content: this.rootStore.applicationStore.getTranslation('index_table.sort_warning')
      });
    }
  };

  addEntity = (entity: Partial<T>) => {
    this.entities?.unshift(entity as T);
  };

  updateEntity = (entity: Partial<T>, index: number) => {
    if (this.entities) {
      this.entities[index] = { ...this.entities[index], ...entity };
    }
  };

  deleteEntity = (index: number) => {
    this.entities?.splice(index, 1);
  };

  saveEntities = async (createApiFriendlyValues: (entity: T) => Record<string, any>, secondIdKey: keyof T) => {
    const entitiesToHandle = clone(this.entities);
    this.initialentities?.forEach((initialEntity) => {
      if (!this.entities?.find((e) => initialEntity.id === e.id)) {
        initialEntity.delete = true;
        entitiesToHandle?.push(initialEntity);
      }
    });

    const indexesAffected: number[] = [];
    const newEntities: T[] = [];

    if (!entitiesToHandle) return;

    await Promise.all(
      entitiesToHandle.map(async (entity) => {
        let request: RequestType | undefined = undefined;

        const apiFriendlyValues = createApiFriendlyValues(entity);

        // Delete
        if (entity.id && entity.delete) {
          request = {
            method: METHODS.DELETE,
            endpoint: `${this.endPoint}/${entity.id}`
          };
        } else if (!entity.id) {
          // Create
          request = {
            method: METHODS.POST,
            endpoint: this.endPoint,
            data: {
              ...apiFriendlyValues
            }
          };
        } else {
          // Update
          const initialEntity = this.initialentities?.find((initial) => initial.id === entity.id);

          if (JSON.stringify(initialEntity) !== JSON.stringify(entity))
            request = {
              method: METHODS.PUT,
              endpoint: `${this.endPoint}/${entity.id}`,
              data: {
                ...apiFriendlyValues
              }
            };
        }

        if (request) {
          const newEntity = (
            await this.rootStore.applicationStore.sendRequest<T>({
              request,
              customHeaders: createHeader({
                'If-Match': entity._etag
              })
            })
          ).data;

          if (!entity.delete && this.entities) {
            const indexToReplace = this.entities.findIndex((v) => {
              if (entity.id) {
                return v.id === entity.id;
              } else {
                return v[secondIdKey] === entity[secondIdKey];
              }
            });

            indexesAffected.push(indexToReplace);
            newEntities.push(newEntity);
          }
        }
      })
    );

    return { indexesAffected, newEntities };
  };

  handleSave = async (
    createApiFriendlyValues: (entity: T) => Record<string, any>,
    secondIdKey: keyof T,
    successMsg: string,
    failMsg: string
  ) => {
    try {
      const updateInfo = await this.saveEntities(createApiFriendlyValues, secondIdKey);

      const valuesClone = clone(this.entities);

      if (updateInfo && valuesClone) {
        const { indexesAffected, newEntities } = updateInfo;

        indexesAffected.forEach((i, entityIndex) => {
          valuesClone[i] = newEntities[entityIndex];
        });
      }

      notify.success({
        content: successMsg
      });

      this.initialentities = valuesClone;
      this.entities = valuesClone;
    } catch (e) {
      notify.error({
        content: failMsg,
        error: e
      });
    }
  };
}

export default CrudService;
