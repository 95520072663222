import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import DataGridCardTab from '@zf/stella-react/src/atoms/Tabs/GraphTab/DataGridCardTab';

import { useAppContext } from '../../../../../app-context/app-context';
import { ErrorBoundary } from '../../../../../components/Error';
import GraphCompareFilters from '../../../../../components/graph/filters/GraphCompareFilters';
import useCompareFilters from './graph/compare-mode/hooks/useCompareFilters';
import GraphOutputHeader from './graph/GraphOutputHeader';
import { OutputGraph } from './graph/OutputGraph';
import ListOutputHeader from './list-view/ListOutputHeader';
import ValuesTable from './list-view/ValuesTable';
import useGraph from './shared-hooks/useGraph';
import useOutput from './shared-hooks/useOutput';
import useQueryParams from './shared-hooks/useQueryParams';

export type Props = {
  meter: MeterType;
  timeStamp: string;
};

export default function OutputContent(props: Props) {
  const { meter, timeStamp } = props;
  const { i18n } = useAppContext();

  // Init channels in context
  const { channels, viewMode, isCompareMode, outputType, changeViewMode, setOutputType } = useOutput(timeStamp, meter);

  const { groupByPeriod } = useQueryParams();

  const { toggleCompareMode } = useGraph();

  const {
    compareYears,
    compareMonths,
    setCompareYears,
    addComparisonYear,
    removeComparisonYear,
    setCompareMonths,
    addComparisonMonth,
    removeComparisonMonth
  } = useCompareFilters();

  const isListMode = viewMode === 'list';

  const header = isListMode ? (
    <ListOutputHeader outputType={outputType} setOutputType={setOutputType} changeViewMode={changeViewMode} />
  ) : (
    <GraphOutputHeader changeViewMode={changeViewMode} />
  );
  const dataSection = isListMode ? <ValuesTable {...props} /> : <OutputGraph channels={channels} />;

  return (
    <DataGridCardTab
      cardId="output-card"
      hasData={channels.length > 0}
      noDataIcon="channels"
      noDataTitle={i18n.getTranslation('meter.no_channels')}
      noDataMsg={i18n.getTranslation('meter.no_channels_meter_values')}
    >
      {header}
      {isCompareMode && (
        <GraphCompareFilters
          groupByPeriod={groupByPeriod}
          compareYears={compareYears}
          compareMonths={compareMonths}
          setCompareYears={setCompareYears}
          addComparisonYear={addComparisonYear}
          removeComparisonYear={removeComparisonYear}
          setCompareMonths={setCompareMonths}
          addComparisonMonth={addComparisonMonth}
          removeComparisonMonth={removeComparisonMonth}
          toggleCompareMode={toggleCompareMode}
        />
      )}
      <ErrorBoundary>{dataSection}</ErrorBoundary>
    </DataGridCardTab>
  );
}
