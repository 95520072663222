import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { updateContractProductParameter } from '@zf/api-types/enums';
import { ContractRowType, ContractType, ProductPeriodReference } from '@zf/api-types/master-data/contract';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { colors } from '@zf/utils/src/color';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';

import ProductAutoFill from '../../../../../components/Autofills/ProductAutoFill';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import Paragraph from '../../../../../design-system/Foundation/Paragraph/Paragraph';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import ChangeProductDatePicker from './change-product-date-picker';
import css from './change-product-dialog.module.scss';
import ChangeProductSummary from './ChangeProductSummary';
import CreditedInvoices from './CreditedInvoices';
import ProductHistory from './ProductHistory';

type Props = {
  validationRef: MutableRefObject<ValidationRef | undefined>;

  // Detail page
  contract?: ContractType;

  // List page
  selectedRows?: ContractRowType[];
  setUpdatedRows?: (updatedRows: ContractType[]) => void;
  refreshCounts?: () => void;
};

export type DateOptionsType = {
  date: string;
  changeProductOption: updateContractProductParameter;
};

export type State = {
  date: DateOptionsType;
  productId: string;
  productName: string;
  showInvoiceCredited: boolean;
  billingCompleteness?: BillingCompletenessType;
};

const ChangeProductDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { contract, selectedRows, validationRef, setUpdatedRows, refreshCounts } = props;

  const { contractStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { contractApiService, changeProduct, getRightProduct } = contractStore;

  let product: ProductPeriodReference | undefined = undefined;

  if (contract) {
    product = getRightProduct(contract.billingDetails.products, contract);
  }

  let contracts: ContractType[] = [];

  if (selectedRows) {
    contracts = selectedRows.map((r) => r.__contractEntity);
  }

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    date: {
      date: MIN_DATE,
      changeProductOption: updateContractProductParameter.fromsupplieddate
    },
    productId: product?.productId || '',
    productName: product?.productName || '',
    showInvoiceCredited: true,
    billingCompleteness: undefined
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        // Detail page
        if (contract) {
          await changeProduct(
            {
              contract,
              etag: contract._etag,
              productId: state.productId,
              startDate: state.date.date
            },
            true
          );
        }
        // List page
        else if (selectedRows && setUpdatedRows && refreshCounts) {
          const baseValues = {
            contractIds: selectedRows.map((r) => r.__id),
            parameter: state.date.changeProductOption,
            productId: state.productId
          };

          const updatedContracts = await contractApiService.changeProductForContracts(
            state.date.changeProductOption === updateContractProductParameter.fromsupplieddate
              ? {
                  ...baseValues,
                  startDate: state.date.date
                }
              : baseValues
          );

          setUpdatedRows(updatedContracts);
          refreshCounts();
        }

        notify.success({
          content: getTranslation('contracts.product_change_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('contracts.product_change_failed'),
          error: e
        });
      }
    }
  }));

  const validate = () => {
    if (validationRef.current) {
      let isError = false;

      if (contract) {
        isError = !state.productId || state.date.date === MIN_DATE || product?.productId === state.productId;
      }

      if (selectedRows) {
        if (state.date.changeProductOption === updateContractProductParameter.fromsupplieddate) {
          isError = !state.productId || state.date.date === MIN_DATE;
        } else {
          isError = !state.productId;
        }
      }

      validationRef.current.setIsError(isError);
    }
  };

  useEffect(() => {
    validate();
  }, [state]);

  return (
    <div className={css['change-product-dialog-wrapper']}>
      <div className={css['change-product-dialog']}>
        {contract && <ProductHistory products={contract.billingDetails.products} />}

        <Paragraph className={css['select-product']} textAlign="left" bold>
          {getTranslation('contracts.select_product')}
        </Paragraph>

        <div className={css['product-dropdown']}>
          <ProductAutoFill
            id="product-change"
            onChange={(val) => setState({ productId: val[0]?.id || '', productName: val[0]?.name || '' })}
            selectedValues={[state.productId]}
            error={!state.productId || product?.productId === state.productId}
          />

          {product?.productId === state.productId && (
            <Paragraph textAlign="left" color={colors['red-600']}>
              {getTranslation('contracts.select_another_product')}
            </Paragraph>
          )}
        </div>
      </div>

      <ChangeProductDatePicker contract={contract} selectedRows={selectedRows} setState={setState} state={state} />

      {!isMinDate(state.date.date) && (
        <CreditedInvoices
          contracts={selectedRows ? contracts : contract ? [contract] : []}
          date={state.date.date}
          showInvoiceCredited={state.showInvoiceCredited}
          setState={setState}
        />
      )}

      {contract ||
      (selectedRows && state.date.changeProductOption === updateContractProductParameter.fromsupplieddate) ? (
        <ChangeProductSummary productId={state.productId} productName={state.productName} date={state.date.date} />
      ) : null}
    </div>
  );
});

export default observer(ChangeProductDialog);
