import { templateUsecase } from '@zf/api-types/enums';
import { FileUploadType } from '@zf/api-types/general';

import BaseFormVjf, { Fields } from '../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../events/notification-events';
import CommunicationStore from '../stores/CommunicationStore';

export enum customHTMLFormFields {
  file = 'file'
}

interface customHTMLFormFieldTypes {
  file: FileUploadType | undefined;
}

class CustomHTMLForm extends BaseFormVjf<customHTMLFormFieldTypes> {
  private communicationStore: CommunicationStore;

  constructor(communicationStore: CommunicationStore, initialValues: customHTMLFormFieldTypes) {
    super(initialValues);

    this.communicationStore = communicationStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: customHTMLFormFields.file,
          label: 'file',
          validators: [this.isRequired]
        }
      ]
    };
  }

  save = async (templateUseCase: templateUsecase) => {
    const { rootStore, communicationService, templatesStore } = this.communicationStore;
    const { selectedEntitySubjectType, selectedEntitySubjectSubtype, onAddCustomHTMLComplete } = templatesStore;
    const { getTranslation } = rootStore.applicationStore;

    try {
      const newScenario = await communicationService.uploadCustomScenarioTemplate(
        selectedEntitySubjectType,
        selectedEntitySubjectSubtype,
        templateUseCase,
        this._get('file')
      );

      if (newScenario) {
        onAddCustomHTMLComplete(newScenario, templateUseCase);
      }

      notify.success({
        content: getTranslation('general.upload_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('general.upload_fail'),
        error: e
      });
    }
  };
}

export default CustomHTMLForm;
