import { observer } from 'mobx-react-lite';
import React from 'react';

import { BillingItemType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';

import ConfigButtons from '../../../../components/Button/ConfigButtons';
import { useStore } from '../../../../hooks/useStore';
import AddEditBillingItemDialog from '../../billing-items/dialogs/add-edit-dialog/AddEditBillingItemDialog';
import DeleteBillingItemDialog from '../../billing-items/dialogs/DeleteBillingItemDialog';

type Props = {
  billingItem: BillingItemType;
  index: number;
};

const BillingItemActions = (props: Props) => {
  const { billingItem, index } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { validationRef } = useDialog();

  return (
    <ConfigButtons
      entityId={billingItem.id}
      editTitle={getTranslation('billing_items.edit_item')}
      editDialog={<AddEditBillingItemDialog validationRef={validationRef} billingItem={billingItem} />}
      deleteTitle={getTranslation('billing_items.delete_item')}
      deleteDialog={<DeleteBillingItemDialog index={index} billingItemId={billingItem.id} />}
    />
  );
};

export default observer(BillingItemActions);
