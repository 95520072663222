import React, { ReactNode } from 'react';

import ListPageHeader from '@zf/stella-react/src/atoms/Heading/ListPageHeader';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context';
import { ActionButton } from '../../design-system/Components';
import css from './filter-bar.module.scss';
import FilterInputs from './filter-inputs';
import QuickFilters from './QuickFilters';
import SearchBar, { SearchBarProps } from '../input/SearchBar';

type FilterBarProps = {
  actions: ReactNode[];
  searchValue?: string;
  filterButtons?: ReactNode;
  infoGrid?: ReactNode;
  filterInputsOverview?: ReactNode[];
  filterInputs?: ReactNode;
  searchPlaceHolder?: string;
  searchDebounce?: number;
  tags?: ReactNode;
  subject?: string;
  defaultTab?: string;
  tabItems?: TabItemType[];
  onSearchChange?: (query: string) => void;
  refreshPage: () => void;
  removeFilter?: (index: number) => void;
  removeAllFilters?: () => void;
  withActions?: boolean;
};

const InlineInputFieldSearchBar = InlineInputField<SearchBarProps>(SearchBar);

export default function FilterBar(props: FilterBarProps) {
  const {
    subject,
    filterButtons,
    infoGrid,
    searchPlaceHolder,
    filterInputs,
    searchDebounce,
    actions,
    searchValue,
    tags,
    defaultTab,
    tabItems,
    onSearchChange,
    refreshPage,
    removeAllFilters = () => {},
    withActions = true
  } = props;

  const { i18n } = useAppContext();

  const onSearchChangeWrapper = (searchVal: string) => {
    onSearchChange && onSearchChange(searchVal);
  };

  return (
    <>
      <div className={css['filter-bar']}>
        <ListPageHeader
          className={css['list-page-header']}
          title={subject}
          defaultTab={defaultTab}
          tabItems={tabItems}
        />
        <div className={css['main-grid']}>
          {tags || <div />}
          <div>
            <FilterInputs filterInputs={filterInputs} removeAllFilters={removeAllFilters} />
          </div>

          <div className={css['search-actions']}>
            {onSearchChange && (
              <InlineInputFieldSearchBar
                id="filter-searchbar"
                className={css['search-bar']}
                onChange={onSearchChangeWrapper}
                value={searchValue}
                placeholder={searchPlaceHolder}
                debounceTime={searchDebounce}
              />
            )}
            {actions.length > 0 && withActions === true && (
              <Center type="both">
                <ActionButton icon="actions" actions={actions}>
                  {i18n.getTranslation('actions.actions')}
                </ActionButton>
              </Center>
            )}
          </div>
        </div>
      </div>

      {infoGrid}

      {filterButtons && (
        <QuickFilters className={!!infoGrid ? css['less-margin'] : undefined} refreshPage={refreshPage}>
          {filterButtons}
        </QuickFilters>
      )}
    </>
  );
}
