import React from 'react';

import { ProductPagedResultType, ProductType } from '@zf/api-types/product';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../app-context/app-context';
import useSingleAPI from '../../../hooks/useSingleAPI';
import Dropdown from '../../Lang/Dropdown';

export type ProductDropdownProps = {
  id: string;
  resetValue?: string;
  isInline?: boolean;
  selectedValue?: string;
  error?: boolean;
  renderTopLvl?: boolean;

  onFocus?: (step: string) => void;
  reset?: () => void;
  onChange: (values: ProductType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<ProductType>>(Dropdown);

export default function ProductDropdown(props: ProductDropdownProps) {
  const { id, resetValue, isInline = false, error, renderTopLvl = true, reset, onFocus, onChange } = props;
  const { selectedValue = '' } = props;
  const { i18n } = useAppContext();

  const productsResponse = useSingleAPI<ProductPagedResultType>({
    request: {
      endpoint: '/cfg/products'
    }
  });

  if (!productsResponse || !productsResponse.result || productsResponse.result instanceof Promise) {
    return isInline ? (
      <InlineDropdown
        id={id}
        onChange={onChange}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    ) : (
      <Dropdown
        id={id}
        onChange={onChange}
        placeholder={i18n.getTranslation('contracts.wizard.product')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    );
  }

  const productsDropdown = productsResponse.result.data.results.map((product) => ({
    value: product,
    text: product.name
  }));

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={productsDropdown}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      resetValue={resetValue}
      reset={reset}
      error={error}
      renderTopLvl={renderTopLvl}
      clear
    />
  ) : (
    <Dropdown
      id={id}
      onChange={onChange}
      placeholder={i18n.getTranslation('contracts.wizard.product')}
      values={productsDropdown}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      resetValue={resetValue}
      reset={reset}
      error={error}
      renderTopLvl={renderTopLvl}
      clear
    />
  );
}
