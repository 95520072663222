import { Button, Link, SidePanel } from 'design-system/Components';
import { Paragraph } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useIssuesContext } from 'features/devices/issues/Context/Context';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { MeteringIssueGrouped } from '@zf/api-types/metering/metering-issues';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';

import css from './resolve-multi-issue-panel.module.scss';
import { AggregatedDetailType } from '@zf/api-types/api';

type Props = {
  issues: MeteringIssueGrouped[];
};

const ResolveGroupedIssuePanel = (props: Props) => {
  const { issues } = props;
  const groupOfIssues = issues[0];

  const { applicationStore, meterStore } = useStore();
  const { getTranslation, getEnumTranslation, rootUrl } = applicationStore;

  // Might be useful if 'grouped issues' API response is not updated
  const { meteringIssueService } = meterStore;
  const { getMeterIssues } = meteringIssueService;

  const ctx = useIssuesContext();
  const { closeSidePanel, store } = ctx;
  const { filter } = store;

  const [meterIssues, setMeterIssues] = useState<AggregatedDetailType[]>([]);

  useEffect(() => {
    if (groupOfIssues.meter?.meterId)
      getMeterIssues({ meterIds: [groupOfIssues.meter?.meterId], quickFilter: filter.quickFilter })
        .then((res) => {
          setMeterIssues(res.aggregateDetails);
        })
        .catch((error) => {
          notify.error({
            error,
            content: getTranslation('meter_issues.get_issues_fail')
          });
        });
  }, [issues]);

  return (
    <SidePanel>
      <FlexElements className={css['multi-issue-panel-body']} flexDirection="column" justifyContent="space-between">
        <div>
          <FlexElements justifyContent="space-between">
            <Heading headingType="h3">
              {getTranslation('meter_issues.itemCount', { amount: groupOfIssues.issueCount })}
            </Heading>
            <Button id="issues.close_panel" icon="cross" onClick={closeSidePanel} type="text" danger noSidePadding />
          </FlexElements>

          <FlexElements className={css['issues']} flexDirection="column" gap="24">
            {meterIssues.length > 0 ? (
              meterIssues.map((i, key) => {
                if (i && Number(i.value) > 0) {
                  return (
                    <div key={key}>
                      <Paragraph color={colors['silver-600']} bold>
                        {getEnumTranslation('meteringIssueError', i.code.split('.')[1])}
                      </Paragraph>
                      <Paragraph>{i.value}</Paragraph>
                    </div>
                  );
                }

                return null;
              })
            ) : (
              <Paragraph color={colors['silver-600']} bold>
                {getTranslation('meter_issues.meter_no_issues')}
              </Paragraph>
            )}
          </FlexElements>
        </div>

        <FlexElements justifyContent="center">
          <Link
            className={css['grouped-issue-panel-link']}
            url={`${rootUrl}/devices/meters/${issues[0].meter?.meterId}/issues?quickFilter=unresolved`}
            bold
          >
            {getTranslation('meter_issues.show_on_meter')}
          </Link>
        </FlexElements>
      </FlexElements>
    </SidePanel>
  );
};

export default observer(ResolveGroupedIssuePanel);
