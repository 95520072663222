import LocationSectionStore from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import { EmptyState } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import AddLocationsBtn from './AddLocationsBtn';

type Props = {
  locationSectionStore: LocationSectionStore;
};

const NoLocationsYet = (props: Props) => {
  const { locationSectionStore } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <FlexElements justifyContent="space-between" alignItems="center">
      <EmptyState
        imgName="location"
        title={getTranslation('contracts.wizard.no_locations_title')}
        description={getTranslation('contracts.wizard.no_locations_descr')}
      />
      <AddLocationsBtn locationSectionStore={locationSectionStore} />
    </FlexElements>
  );
};

export default observer(NoLocationsYet);
