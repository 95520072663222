import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { PrepaymentDeviceOverviewCountType } from '@zf/api-types/master-data/meter';

import useFilter from '../../../../../app-context/hooks/use-filter';
import FilterTags from '../../../../../components/Filters/filter-tags';

export type DefaultPrepaymentDeviceQuery = { meterType: meterType };

const initialPrepaymentDevicesOverviewCount: PrepaymentDeviceOverviewCountType = {
  all: 0,
  inactive: 0,
  installed: 0
};

type QueryParam = {};

const initialQueryParams = {};

const paramTypes = {};

const usePrepaymentDeviceFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams
  } = useFilter<QueryParam, DefaultPrepaymentDeviceQuery>({
    endpoint: '/md/meters/overviewcount',
    initialCount: initialPrepaymentDevicesOverviewCount,
    domain: 'prepayment_device',
    defaultQueryParams: { meterType: meterType.prepayment },
    initialQueryParams,
    search,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams,
    quickFilter,
    overviewCount,
    searchValue,
    queryParams,
    filterTags
  };
};

export default usePrepaymentDeviceFilter;
