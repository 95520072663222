import React, { useImperativeHandle } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  enabled: boolean;
};

const EnableDocumentGenerationDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { enabled } = props;

  const { applicationStore, communicationStore } = useStore();
  const { communicationParametersForm } = communicationStore.settingsStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      communicationParametersForm._set('enabled', enabled);
    }
  }));

  const translation = applicationStore.getTranslation(`general.${enabled ? 'enable' : 'disable'}`);

  return (
    <Paragraph>
      {applicationStore.getTranslation('communication.enable_all_paragraph', { enable: translation.toLowerCase() })}
    </Paragraph>
  );
});

export default EnableDocumentGenerationDialog;
