import React, { useEffect, useReducer } from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useDebounce from '@zf/hooks/src/useDebounce';
import { Label } from '@zf/stella-react/src/atoms/Label';

import FilterTag from '../../../components/Filters/filter-tag';
import InputField from '../../../components/input/InputField';
import { useStore } from '../../../hooks/useStore';
import css from './use-billing-items-filter.module.scss';

type State = {
  name: string;
};

export default function useProductsFilter(productName?: string) {
  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { setProducts } = productConfigStore.productsStore;

  let initialValue = '';

  // Avoids searching on the tab name when switching tabs
  if (productName !== 'products') {
    initialValue = productName || '';
  }

  const reducer = createStateReducer<State, Partial<State>>();
  const [filters, setFilters] = useReducer(reducer, {
    name: initialValue
  });

  const setDebounceCallback = useDebounce(200);

  useEffect(() => {
    setDebounceCallback(() => {
      productConfigStore.productConfigService.getProducts(filters.name).then((filtered) => {
        setProducts(filtered);
      });
    });
  }, [filters]);

  const filterInputs = [
    <Label key="lbl_name">{getTranslation('general.name')}</Label>,
    <InputField id="name-filter" key="name-filter" value={filters.name} onChange={(val) => setFilters({ name: val })} />
  ];

  const tags: React.ReactNode[] = [];

  if (filters.name) {
    tags.push(
      <FilterTag key="name" className={css['tag']} value={filters.name} removeFilter={() => setFilters({ name: '' })} />
    );
  }

  const resetFilters = () => {
    setFilters({ name: '' });
  };

  return {
    filterInputs,
    tags,
    resetFilters
  };
}
