import classNames from 'classnames';
import React from 'react';

import css from './data-grid-actions.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function DataGridActions(props: Props) {
  const { className, children } = props;

  return <div className={classNames(css['data-grid-actions'], className)}>{children}</div>;
}
