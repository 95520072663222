import { useEffect, useRef } from 'react';

export default function useDidMountEffect<T extends Array<any>>(func: Function, deps: T) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}
