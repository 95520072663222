import Interweave from 'interweave';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { MeasurementType, MeterType } from '@zf/api-types/master-data/meter';
import useValidatePeriod from '@zf/hooks/src/useValidatePeriod';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ProgressBar } from '@zf/stella-react/src/atoms/ProgressBar';
import Center from '@zf/stella-react/src/helpers/Center';
import { splitArrayIntoChunksOfLength } from '@zf/utils/src/array';
import { formatDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import { EnumReturnValue } from '../../../../app-context/hooks/use-enum';
import { LangReturnValue } from '../../../../app-context/hooks/use-lang';
import { exportArrayToExcel } from '../../../../components/Button/Excel/ExportToExcel';
import DateRangePicker from '../../../../components/input/DateRangePicker';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { getCurrentMeterStatus } from '../../../../utils/meter';
import { METHODS, sendRequest } from '../../../../utils/request';
import css from '../export-dialog.module.scss';
import { getPeriodicFileNameFormat } from '../../../../utils/exports';

type Props = {
  selectedRows?: { __meterEntity: MeterType }[];
  meter?: MeterType;
};

type FilteredMeterDataType = {
  propertyGroupId: string | undefined;
  propertyGroupName: string | undefined;
  locationId: string | undefined;
};

type FilteredMeterDataMap = Record<string, FilteredMeterDataType>;

const mapMeasurements = (
  measurements: MeasurementType[],
  filteredMeterData: FilteredMeterDataMap,
  i18n: LangReturnValue,
  enumReducer: EnumReturnValue
) => {
  const exportArray = [];

  exportArray.push([
    i18n.getTranslation('metering_list.labels.serial'),
    i18n.getTranslation('meter.meter_tag'),
    i18n.getTranslation('import_jobs.channel_identifier'),
    i18n.getTranslation('general.value'),
    i18n.getTranslation('meter.unit_of_measure'),
    i18n.getTranslation('meter.reading_date'),
    i18n.getTranslation('meter.utility_type'),
    i18n.getTranslation('import_jobs.metering_type'),
    i18n.getTranslation('import_jobs.direction'),
    i18n.getTranslation('meter.time_of_use'),
    i18n.getTranslation('property_groups.name'),
    i18n.getTranslation('property_groups.property_group_id'),
    i18n.getTranslation('location.location_id')
  ]);

  measurements.forEach((msmt) => {
    exportArray.push([
      //meterTag value goes below
      msmt.meterSerialNumber,
      msmt.meterTag,
      msmt.externalChannelIdentifier,
      msmt.value,
      enumReducer.getTranslation('unitOfMeasure', msmt.unitOfMeasure),
      formatDate(msmt.endDateTime),
      enumReducer.getTranslation('utilityType', msmt.utilityType),
      enumReducer.getTranslation('meteringType', msmt.meteringType),
      enumReducer.getTranslation('direction', msmt.direction),
      msmt.timeOfUse,
      filteredMeterData[msmt.meterSerialNumber].propertyGroupName,
      filteredMeterData[msmt.meterSerialNumber].propertyGroupId,
      filteredMeterData[msmt.meterSerialNumber].locationId
    ]);
  });

  return exportArray;
};

const ExportMeasurementsDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { meter } = props;
  let { selectedRows = [] } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const meterIds: string[] = [];
  const filteredMeterData = {} as FilteredMeterDataMap;

  const [progress, setProgress] = useState(0);
  const [isMapping, setIsMapping] = useState(false);

  const { dates, setDates } = useValidatePeriod();

  if (progress === 0) {
    // When on meter detail page
    if (selectedRows.length === 0 && !!meter) {
      selectedRows = [{ __meterEntity: meter }];
    }

    selectedRows.forEach((r) => {
      const currentStatus = getCurrentMeterStatus(r.__meterEntity, i18n);

      filteredMeterData[r.__meterEntity.serialNumber] = {
        propertyGroupId: r.__meterEntity.propertyGroup?.id || '',
        propertyGroupName: r.__meterEntity.propertyGroup?.name || '',
        locationId: currentStatus.serviceLocationId
      };

      meterIds.push(r.__meterEntity.id);
    });
  }

  // API query accepts only n ids per call
  const splittedMeterIds = splitArrayIntoChunksOfLength(meterIds, 35);

  const fetchData = async (query: Record<string, any>, measurements: MeasurementType[]) => {
    let continuationToken = '';

    while (continuationToken !== null) {
      const result = await sendRequest<PagedResponseType<MeasurementType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/me/Measurements/export/values',
          query
        },
        customHeaders: {
          continuationToken
        },
        tenantReducer,
        lang: i18n.lang
      });

      measurements.push(...result.data.results);

      continuationToken = result.data.nextPageToken;
    }
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const measurements: MeasurementType[] = [];
        let chunksDone = 0;

        // We call the API for each chunk of n ids
        await Promise.all(
          splittedMeterIds.map(async (idsChunk) => {
            await fetchData(
              {
                meterIds: idsChunk,
                startDateTime: dates.startDateTime.toISOString(),
                endDateTime: dates.endDateTime.toISOString(),
                orderBy: '-EndDateTime'
              },
              measurements
            );

            if (chunksDone > 0) {
              setProgress((chunksDone / splittedMeterIds.length) * 100);
            }

            chunksDone++;
          })
        );

        setIsMapping(true);
        const mappedMeasurements = mapMeasurements(measurements, filteredMeterData, i18n, enumReducer);
        setIsMapping(false);

        let tags = getPeriodicFileNameFormat(dates.startDateTime, dates.endDateTime);

        await exportArrayToExcel(
          i18n.getTranslation('meter.measurements') +
            `-${tags.isStartTagTranslatable ? i18n.getTranslation(tags.fileNameStartTag) : tags.fileNameStartTag}`,
          () => mappedMeasurements,
          tags.fileNameEndTag
        );
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.meter.export_measurements_fail'),
          error: e
        });
      }
    }
  }));

  let paragraphText = i18n.getTranslation('actions.meter.export_measurements_paragraph');

  if (isMapping) {
    paragraphText = i18n.getTranslation('actions.meter.export_measurements_busy_mapping');
  } else if (progress > 0) {
    paragraphText = i18n.getTranslation('actions.meter.export_measurements_busy_fetching');
  }

  return (
    <>
      <Paragraph>
        <Interweave content={paragraphText} />
      </Paragraph>
      {progress > 0 ? (
        <Center type="both" className={css['progress-bar']}>
          <ProgressBar size="large" progress={progress} showLabel />
        </Center>
      ) : (
        <InputContainer className={css['input-container']}>
          <DateRangePicker
            id="export-period"
            startDate={dates.startDateTime}
            endDate={dates.endDateTime}
            setDates={(dates_) =>
              setDates({
                startDateTime: dates_[0] || moment(MIN_DATE),
                endDateTime: dates_[1] || moment(MAX_DATE)
              })
            }
          />
        </InputContainer>
      )}
    </>
  );
});

export default observer(ExportMeasurementsDialog);
