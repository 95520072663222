import IconParagraph from 'components/Icon/IconParagraph';
import { ICON_COLORS, ICONS } from 'constants/icons';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { utilityType } from '@zf/api-types/enums';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

type Props = {
  utilityType: utilityType;
  count: number;
};

const ServiceCounter = (props: Props) => {
  const { utilityType, count } = props;

  const { applicationStore } = useStore();
  const { getEnumTranslation } = applicationStore;

  return (
    <FlexElements justifyContent="space-between">
      <IconParagraph iconType={ICONS[utilityType]} color={ICON_COLORS[utilityType]} bold>
        {getEnumTranslation('utilityType', utilityType)}
      </IconParagraph>
      <span>{count}</span>
    </FlexElements>
  );
};

export default observer(ServiceCounter);
