import IconParagraph from 'components/Icon/IconParagraph';
import EmptyLink from 'components/Link/EmptyLink';
import { NoDataOverlayWithAdd, TableLoading } from 'components/placeholder';
import { useStore } from 'hooks/useStore';
import React, { useEffect } from 'react';

import { contactType, customerType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { formatPhoneNumber } from '@zf/utils/src/number';

import { useAppContext } from '../../../../app-context';
import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import useCreateRequest from '../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../hooks/useInfiniAPI';

type Props = {
  queryParam: { customerGroupId?: string; searchValue?: string; timeStamp?: string };
  setSelectedCount: Function;
};

export default function CustomersListInGroup(props: Props) {
  const { i18n, tenantReducer } = useAppContext();
  const { queryParam, setSelectedCount } = props;
  const { customerGroupId, timeStamp } = queryParam;
  const { applicationStore } = useStore();
  const { rootUrl, getEnumTranslation, getTranslation } = applicationStore;

  const columns = [
    {
      width: 400,
      label: getTranslation('customer_list.name'),
      dataKey: 'AccountNumber',
      noClick: true
    },
    {
      width: 500,
      label: getTranslation('customer.invoice_address'),
      dataKey: 'streetName'
    },
    {
      width: 250,
      label: getTranslation('customer.default_payment_method'),
      dataKey: 'defaultPaymentMethod'
    },
    {
      width: 250,
      label: getTranslation('settings.active_custom_properties'),
      dataKey: 'customEntityProperties'
    }
  ];

  columns.push(
    ...[
      {
        width: 300,
        label: '',
        dataKey: 'email'
      },
      {
        width: 200,
        label: '',
        dataKey: 'telephone'
      },
      {
        width: 200,
        label: '',
        dataKey: 'mobilePhone'
      },
      {
        width: 200,
        label: '',
        dataKey: 'website'
      }
    ]
  );

  const { request, setSelectedIds, handleSort, sortBy, sortDirection, selectedIds } = useCreateRequest(
    '/md/customers',
    {
      flexSearch: queryParam.searchValue,
      customerGroupId,
      timeStamp
    }
  );

  useEffect(() => {
    setSelectedCount(selectedIds);
  }, [selectedIds]);

  const processRecord = (value: CustomerType) => {
    value.contactDetails.sort((cd1: ContactEntryType, cd2: ContactEntryType) => {
      // Sort by type
      if (cd1.contactType > cd2.contactType) return 1;
      else return -1;
    });

    const iconName = 'customer';

    return {
      __id: value.id,
      __etag: value._etag,
      __customerEntity: value,
      AccountNumber: (
        <EmptyLink
          id={`${routedEntitySubjectType.customer}-${value.id}`}
          url={`${rootUrl}/customers/${value.id}`}
          icon={value.customerType === customerType.organization ? 'organisation' : iconName}
        >
          {value.accountNumber && value.shortDisplayName && `${value.accountNumber} - ${value.shortDisplayName}`}
        </EmptyLink>
      ),
      streetName: value.invoiceAddress.localizedDisplay,
      defaultPaymentMethod: getEnumTranslation('paymentMethod', value.defaultPaymentMethod),
      email: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.email) {
          return (
            <div key={`contact-email-${value.id}-${e.value}-${index}`}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {e.value}
              </IconParagraph>
            </div>
          );
        }
        return null;
      }),
      telephone: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.telephone) {
          return (
            <div key={`contact-telephone-${e.value}-${e.value}-${index}`} data-tip={e.value}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {formatPhoneNumber(e.value)}
              </IconParagraph>
            </div>
          );
        }
        return null;
      }),
      mobilePhone: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.mobiletelephone) {
          return (
            <div key={`contact-mobile-${e.value}-${e.value}-${index}`} data-tip={e.value}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {formatPhoneNumber(e.value)}
              </IconParagraph>
            </div>
          );
        }
        return null;
      }),
      website: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.website) {
          return (
            <div key={`contact-website-${value.id}-${e.value}-${index}`} data-tip={value.id}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {e.value}
              </IconParagraph>
            </div>
          );
        }
        return null;
      })
    };
  };

  const { loading, error, rows, sortableFields, setStopIndex, selectAllBusy } = useInfiniAPI<any, any>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord,
    onSelectRow: setSelectedIds
  });

  return (
    <>
      <DynamicVirtualTable
        id="customersin-group-table"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        sortableFields={sortableFields}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        NoDataOverlay={() => <NoDataOverlayWithAdd queryParams={queryParam} />}
        LoadingOverlay={TableLoading}
        rowHeight={40}
        sort={handleSort}
        sortBy={sortBy}
        singleSort
        selectAllBusy={selectAllBusy}
        sortDirection={sortDirection}
        totalAmountOfRows={rows.length}
        columns={columns}
      />
    </>
  );
}
