import classNames from 'classnames';
import React from 'react';

import { Spinner } from '@zf/heat-erp/src/design-system/Foundation';

import css from './cards-container.module.scss';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  fullHeight?: boolean;
  style?: React.CSSProperties;
};

export default function CardsContainer(props: Props) {
  const { children, className, fullHeight = false, isLoading = false } = props;

  return isLoading ? (
    <div className={css['full-height']}>
      <Spinner centered />
    </div>
  ) : (
    <div className={classNames(css['cards-container'], { [css['full-height']]: fullHeight }, className)}>
      {children}
    </div>
  );
}
