import React from 'react';

import { IncomingMutationRowType } from '@zf/api-types/billing/incoming-mutations';
import { incomingMutationStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import ManualMatchDialog from '../../../../../../../actions/incoming-mutation/ManualMatchDialog';
import { useAppContext } from '../../../../../../../app-context';
import ConfirmationActionButton from '../../../../../../../components/Button/ConfirmationActionButton';
import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';

type Props = {
  selectedRows: IncomingMutationRowType[];
  incomingBankingTransaction: IncomingBankingTransactionType
  refresh: () => void;
};

export default function MatchDetailButton(props: Props) {
  const { selectedRows, refresh, incomingBankingTransaction } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const selectedRow = selectedRows[0];

  const isDisabled =
    selectedRows.length === 0 ||
    (selectedRow && selectedRow.__entity.status !== incomingMutationStatus.resolveissues) ||
    selectedRows.length > 1;

  return (
    <ConfirmationActionButton
      id="incoming_mutation.manual_match"
      icon="match"
      dialogTitle={i18n.getTranslation('actions.incoming_mutation.manual_match')}
      dialogContent={
        !isDisabled && (
          <ManualMatchDialog
            incomingMutation={selectedRow.__entity}
            validationRef={validationRef}
            ref={clickRef}
            refresh={refresh}
            incomingBankingTransaction={incomingBankingTransaction}
          />
        )
      }
      onSubmit={onSubmit}
      disabled={isDisabled}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.incoming_mutation.match')}
    />
  );
}
