import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import { InlineInputFieldInput } from '../../../../components/input/InputField';
import { useStore } from '../../../../hooks/useStore';

const EmailAddresses = observer(() => {
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { communicationParametersForm, dnsRecordsAreValid } = communicationStore.settingsStore;
  const [canType, setCanType] = useState(false);

  const handleChange = (val: string) => {
    if (canType) {
      communicationParametersForm._setNested(
        'senderEmail',
        'address',
        `${val}@${communicationParametersForm._get('domainName')}`
      );
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key !== '@') {
      setCanType(true);
    } else {
      setCanType(false);
    }
  };

  return (
    <>
      <CardItem width="12">
        <Label>{getTranslation('communication.sender_name')}</Label>
      </CardItem>
      <CardItem width="4">
        <InlineInputFieldInput
          id="sender-name"
          onChange={(val) => communicationParametersForm._setNested('senderEmail', 'name', val)}
          value={communicationParametersForm._getNested('senderEmail', 'name')}
          placeholder={getTranslation('communication.sender_name')}
        />
      </CardItem>
      <CardItem width="4">
        {dnsRecordsAreValid ? (
          <InlineInputFieldInput
            id="sender-address"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={communicationParametersForm._getNested('senderEmail', 'address').split('@')[0]}
            placeholder={getTranslation('communication.sender_address')}
            error={!!communicationParametersForm._nestedError('senderEmail', 'address')}
            singleError={communicationParametersForm._nestedError('senderEmail', 'address')}
            postfix={`@${communicationParametersForm._get('domainName')}`}
          />
        ) : (
          <Paragraph color={colors['red-600']}>{getTranslation('communication.verify_first')}</Paragraph>
        )}
      </CardItem>

      <CardItem width="12">
        <Label>{getTranslation('communication.reply_address')}</Label>
      </CardItem>
      <CardItem width="4">
        <InlineInputFieldInput
          id="reply-name"
          onChange={(val) => {
            communicationParametersForm._setNested('replyTo', 'name', val);

            // Only validate address when name is filled in
            communicationParametersForm._setValidatorsNested(
              'replyTo',
              'address',
              val ? [communicationParametersForm.isRequired, communicationParametersForm.isEmail] : null
            );
          }}
          value={communicationParametersForm._getNested('replyTo', 'name')}
          placeholder={getTranslation('communication.reply_name')}
        />
      </CardItem>
      <CardItem width="4">
        <InlineInputFieldInput
          id="reply-adress"
          onChange={(val) => communicationParametersForm._setNested('replyTo', 'address', val)}
          value={communicationParametersForm._getNested('replyTo', 'address')}
          placeholder={getTranslation('communication.reply_address')}
          error={!!communicationParametersForm._nestedError('replyTo', 'address')}
          singleError={communicationParametersForm._nestedError('replyTo', 'address')}
        />
      </CardItem>
    </>
  );
});

export default EmailAddresses;
