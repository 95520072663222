import React from 'react';

import { ServiceLocationRowType, ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import useDialog from '@zf/hooks/src/useDialog';

import ChangeProductDialog from '../../../actions/location/change-product-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

export default function ChangeProductListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('location');
  const { setUpdatedRows } = useUpdateListPage<ServiceLocationType>('location');
  const { clickRef, onSubmit } = useDialog();

  const selectedRows: ServiceLocationRowType[] = myCurrent.listPage.selectedRows;

  return (
    <DropdownAction
      id="location.product"
      dialogTitle={i18n.getTranslation('location.change_product')}
      icon="compare"
      dialogContent={<ChangeProductDialog ref={clickRef} selectedRows={selectedRows} setUpdatedRows={setUpdatedRows} />}
      disabled={selectedRows.length === 0}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('general.save')}
    />
  );
}
