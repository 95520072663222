import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { aggregationFrequency, unitOfMeasure, utilityType } from '@zf/api-types/enums';
import { ServiceConsumptionCompareGraphValueType } from '@zf/api-types/graph';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import { groupBy } from '@zf/utils/src/array';
import { colors, convertHexToRGBA } from '@zf/utils/src/color';

import { useAppContext } from '../../../../../app-context';
import { ZFGraphTooltip } from '../../../../../components/graph/ZFGraphTooltip';
import { getColorByUtilityType } from '../../../../../utils/meter';
import useCompareGraph from '../hooks/useCompareGraph';
import useServiceConsumptions from '../hooks/useServiceConsumptions';
import css from './compare-graph.module.scss';

type BarType = Record<string, any>;

type ExtraInfoType = {
  utilityType: utilityType;
  uom: unitOfMeasure;
};

export function CompareGraph() {
  const { i18n, enumReducer } = useAppContext();

  const { groupByPeriod, isLoading } = useServiceConsumptions();
  const { compareGraphValues } = useCompareGraph();

  moment.locale(i18n.lang);

  const groupedByStartDateTime: Record<string, ServiceConsumptionCompareGraphValueType[]> = groupBy(
    compareGraphValues,
    'groupByKey'
  );

  const getGroupByPeriodKey = (dateTime: string, description: string) => {
    // 23:00 on 31/12 is a new year in moment but not in our data, hence the 1 minute difference
    const date = moment(dateTime).subtract(1, 'minute');

    if (groupByPeriod === aggregationFrequency.yearly) {
      return `${date.year().toString()} ${description}`;
    } else {
      // Monthly
      return `${date.year().toString()} - ${date.format('MMMM')} - ${description}`;
    }
  };

  const chartData: ChartDataType[] = [];
  const groupByPeriodKeys: string[] = [];
  const extraInfo: ExtraInfoType[] = [];

  if (!isLoading) {
    Object.keys(groupedByStartDateTime).forEach((date) => {
      const dateSet = groupedByStartDateTime[date];

      const bar: BarType = {};

      dateSet.forEach((barEntry) => {
        const groupByPeriodKey = getGroupByPeriodKey(barEntry.endDateTime, barEntry.description);

        if (!groupByPeriodKeys.includes(groupByPeriodKey)) {
          groupByPeriodKeys.push(groupByPeriodKey);
          extraInfo.push({
            utilityType: barEntry.utilityType,
            uom: barEntry.uom
          });
        }

        bar.name = date;
        bar[groupByPeriodKey] = barEntry.value;
      });

      chartData.push(bar);
    });
  }

  const renderChartContent = () => {
    return !isLoading
      ? groupByPeriodKeys.map((key, index) => {
          const year = key.split(' ')[0];
          const yearInt = parseInt(year[year.length - 1]);

          // Scale to visible range
          const percentage = 10 * yearInt + 50;

          const fillColor = convertHexToRGBA(getColorByUtilityType(extraInfo[index].utilityType), percentage);

          const unit = enumReducer.getTranslation('unitOfMeasure', extraInfo[index].uom);

          return <Bar key={key} dataKey={key} unit={` ${unit}`} barSize={50} fill={fillColor} radius={[5, 5, 0, 0]} />;
        })
      : [];
  };

  return chartData.length > 0 ? (
    <div key="graph-wrapper" className={classNames(css['graph-wrapper'], css['compare-mode'])}>
      <ResponsiveContainer key="responsive-container" className={css['graph-container']} width="98%" height="100%">
        <ComposedChart key="composed-chart" data={chartData} margin={{ top: 10, left: 25, right: 25 }}>
          <CartesianGrid key="grid-lines" strokeDasharray="3 3" vertical={false} stroke={colors['silver-400']} />
          <XAxis dataKey="name" />
          <YAxis />
          {renderChartContent()}
          <ZFGraphTooltip />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  ) : null;
}
