import { observer } from 'mobx-react';
import React from 'react';

import { templateUsecase } from '@zf/api-types/enums';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { ScenarioUploadInput } from '../../../../../components/input/ScenarioUploadInput';
import { useStore } from '../../../../../hooks/useStore';
import AddCustomHTML from '../components/AddCustomHTML';
import CustomTranslations from '../components/CustomTranslations';
import ExistingCustomHTML from '../components/ExistingCustomHTML';

const EmailCardBody = () => {
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { selectedScenario, templateForm } = communicationStore.templatesStore;
  const emailTemplate = selectedScenario?.emailTemplate;

  return (
    <>
      <CustomTranslations templateUsecase_={templateUsecase.email} />

      <CardItem width="12">
        <HorizontalDivider />
      </CardItem>

      {emailTemplate?.attachments && (
        <>
          <CardItem width="12">
            <Label>{getTranslation('communication.add_attachment')}</Label>
          </CardItem>
          <CardItem width="12">
            <ScenarioUploadInput
              id="attachments"
              files={templateForm._get('attachments')}
              setFiles={(val) => templateForm._set('attachments', val)}
            />
          </CardItem>

          <CardItem width="12">
            <HorizontalDivider />
          </CardItem>
        </>
      )}

      {emailTemplate?.useBuildIn ? (
        <AddCustomHTML templateUsecase={templateUsecase.email} />
      ) : (
        <>
          <ExistingCustomHTML
            fileName={emailTemplate?.customTemplateFileName}
            templateUsecase={templateUsecase.email}
          />
        </>
      )}
    </>
  );
};

export default observer(EmailCardBody);
