import React from 'react';

export type StaticColumnProps = {
  flexWidth?: string;
  label?: string | null;
  dataKey: string;
  minWidth?: string;
  showOnHover?: boolean;
};

export default function StaticColumn(props: StaticColumnProps) {
  const { flexWidth, label = '', minWidth } = props;

  return (
    <div
      style={{
        flex: flexWidth,
        minWidth: minWidth ? minWidth : '0'
      }}
    >
      {label}
    </div>
  );
}
