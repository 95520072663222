import React from 'react';

import { invoiceStatus, paymentProcessStatus } from '@zf/api-types/enums';
import { InvoiceRowType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import AssignToCaseDialog from '../dialogs/assign-to-case-dialog';

type Props = {
  refresh: () => void;
};

export default function AssignToCaseListPageButton(props: Props) {
  const { refresh } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled = selectedRows.some((r) => {
    const { status, paymentDetails, remainingInvoiceAmount, collectionDetails } = r.__entity;

    return (
      status === invoiceStatus.created ||
      paymentDetails.currentPaymentStatus === paymentProcessStatus.paid ||
      remainingInvoiceAmount < 0 ||
      (!!collectionDetails && !collectionDetails.closed)
    );
  });

  return (
    <DropdownAction
      id="collection_case.assign_to_case"
      icon="plus"
      dialogTitle={i18n.getTranslation('actions.invoice.assign_to_case')}
      dialogContent={
        <AssignToCaseDialog
          ref={clickRef}
          selectedRows={selectedRows}
          validationRef={validationRef}
          onComplete={refresh}
        />
      }
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0 || disabled}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.invoice.assign_to_case')}
    />
  );
}
