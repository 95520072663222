import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';

export default function NoUnbilled() {
  const { i18n } = useAppContext();

  return <CardEmptyBody icon={<Icon type="invoice" />} title={i18n.getTranslation('analytics.no_unbilled')} />;
}
