import { regularMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { WizardSectionHeader } from 'design-system/Components';
import { InfoBanner } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import css from './billing-section.module.scss';
import BillingForm from './BillingForm';

const BillingSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { regularMoveInWizardStore } = contractStore.moveInWizardBaseStore;
  const { suggestionMsgColorMap, locationSectionStore, regularMoveInWizardNavigator } = regularMoveInWizardStore;
  const { invoiceBillingConfigurationSuggestions } = locationSectionStore;
  const { setActiveStep } = regularMoveInWizardNavigator;

  const infoMessage = invoiceBillingConfigurationSuggestions?.message?.message;
  const msgKey = invoiceBillingConfigurationSuggestions?.message?.key;

  return (
    <WizardSection id="billing-section" setActiveStep={() => setActiveStep(regularMoveInWizardSteps.billing)}>
      <WizardSectionHeader
        id={regularMoveInWizardSteps.billing}
        text={getTranslation('contracts.steps.billing', { stepNum: 5 })}
      />

      {infoMessage && msgKey && (
        <InfoBanner
          className={css['info-message']}
          info={infoMessage}
          color={suggestionMsgColorMap[msgKey]}
          roundedCorners
        />
      )}

      <BillingForm />
    </WizardSection>
  );
};

export default observer(BillingSection);
