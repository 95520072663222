import clone from 'clone';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { CollectionFlowStepType, CollectionFlowType } from '@zf/api-types/collection-cycles';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';

export type CollectionCyclesState = {
  collectionFlows: CollectionFlowType[];
  collectionSteps: CollectionFlowStepType[];
  isLoading: boolean;
};

type SetCollectionCyclesState = Partial<CollectionCyclesState>;

export type UseCollectionCyclesReturnType = {
  collectionFlows: CollectionFlowType[];
  collectionSteps: CollectionFlowStepType[];
  isLoading: boolean;
  addFlow: (flow: CollectionFlowType, steps: CollectionFlowStepType[]) => void;
  updateFlow: (flow: CollectionFlowType) => void;
  removeFlow: (flowId: string) => void;
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  updateStep: (step: CollectionFlowStepType) => void;
  removeStep: (stepId: string, flowIndex: number) => void;
  setHookState: React.Dispatch<Partial<CollectionCyclesState>>;
};

export default function useCollectionCycles(): UseCollectionCyclesReturnType {
  const stateReducer = createStateReducer<CollectionCyclesState, SetCollectionCyclesState>();
  const [state, setState] = React.useReducer(stateReducer, {
    collectionFlows: [],
    collectionSteps: [],
    isLoading: true
  });

  const collectionFlowsResponse = useSuspenseSingleAPI<PagedResponseType<CollectionFlowType>>({
    request: {
      endpoint: '/cfg/CollectionFlows/'
    }
  });

  const collectionStepsResponse = useSuspenseSingleAPI<PagedResponseType<CollectionFlowStepType>>({
    request: {
      endpoint: '/cfg/CollectionSteps/'
    }
  });

  React.useEffect(() => {
    if (collectionFlowsResponse.result) {
      setState({ collectionFlows: collectionFlowsResponse.result.data.results, isLoading: false });
    }
  }, [collectionFlowsResponse.result]);

  React.useEffect(() => {
    if (collectionStepsResponse.result) {
      setState({ collectionSteps: collectionStepsResponse.result.data.results });
    }
  }, [collectionStepsResponse.result]);

  // Flow CRUD
  const addFlow = (flow: CollectionFlowType, steps: CollectionFlowStepType[]) => {
    const flowsClone = clone(state.collectionFlows);
    flowsClone.push(flow);

    const stepsClone = clone(state.collectionSteps);
    stepsClone.push(...steps);

    setState({ collectionFlows: flowsClone, collectionSteps: stepsClone });
  };

  const updateFlow = (flow: CollectionFlowType) => {
    const flowsClone = clone(state.collectionFlows);
    const updatedIndex = flowsClone.findIndex((f) => {
      return f.id === flow.id;
    });
    flowsClone[updatedIndex] = flow;
    setState({ collectionFlows: flowsClone });
  };

  const removeFlow = (flowId: string) => {
    const flowsClone = clone(state.collectionFlows);
    const indexToRemove = flowsClone.findIndex((f) => {
      return f.id === flowId;
    });
    flowsClone.splice(indexToRemove, 1);
    setState({ collectionFlows: flowsClone });
  };

  // Steps CRUD
  // Used in add/edit step dialog
  const addStep = (step: CollectionFlowStepType, flowIndex?: number) => {
    // Update steps
    const stepsClone = clone(state.collectionSteps);
    stepsClone.push(step);

    if (typeof flowIndex !== 'undefined') {
      // Update flows
      const flowsClone = clone(state.collectionFlows);
      flowsClone[flowIndex].stepIds.push(step.id);

      setState({ collectionSteps: stepsClone, collectionFlows: flowsClone });
    } else {
      setState({ collectionSteps: stepsClone });
    }
  };

  const updateStep = (step: CollectionFlowStepType) => {
    const stepsClone = clone(state.collectionSteps);
    const indexToUpdate = stepsClone.findIndex((s) => {
      return s.id === step.id;
    });
    stepsClone[indexToUpdate] = step;
    setState({ collectionSteps: stepsClone });
  };

  const removeStep = (stepId: string, flowIndex: number) => {
    // Update steps
    const stepsClone = clone(state.collectionSteps);
    let indexToRemove = stepsClone.findIndex((s) => {
      return s.id === stepId;
    });
    stepsClone.splice(indexToRemove, 1);

    // Update flows
    const flowsClone = clone(state.collectionFlows);
    indexToRemove = flowsClone[flowIndex].stepIds.findIndex((id) => {
      return id === stepId;
    });
    flowsClone[flowIndex].stepIds.splice(indexToRemove, 1);

    setState({ collectionSteps: stepsClone, collectionFlows: flowsClone });
  };

  return {
    collectionFlows: state.collectionFlows,
    collectionSteps: state.collectionSteps,
    isLoading: state.isLoading,
    addFlow,
    updateFlow,
    removeFlow,
    addStep,
    updateStep,
    removeStep,
    setHookState: setState
  };
}
