import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../../../../app-context/app-context';
import LocationAutoFill from '../../../../../components/Autofills/LocationAutoFill';
import ErrorView from '../../../../../components/Error/error-view';
import useActivities from '../hooks/useActivities';
import css from './location-wiz.module.scss';

export default function LocationWiz() {
  const { i18n } = useAppContext();
  const { task, existingLocation, setLocation } = useActivities();

  if (!task) return null;

  return (
    <div>
      {task.serviceLocationDetails.errors.length > 0 ? <ErrorView errors={task.serviceLocationDetails.errors} /> : null}

      <Heading className={css['heading']} headingType="h3" style="bold">
        {i18n.getTranslation('tasks.new_address')}
      </Heading>
      <LocationAutoFill
        id="selectLocation"
        onChange={(val) => setLocation(val[0])}
        initialValue={
          existingLocation?.address?.localizedDisplay || task.serviceLocationDetails.address?.localizedDisplay || ''
        }
        selectedValues={[task.serviceLocationDetails.internalId]}
        placeholder={i18n.getTranslation('location.search')}
        error={!task.serviceLocationDetails.internalId}
      />
    </div>
  );
}
