import SearchBar from '@zf/heat-erp/src/components/input/SearchBar';
import classNames from 'classnames';
import React from 'react';

import { Icon } from '../Icon';
import StellaInputField from '../InputField/StellaInputField';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './dropdown.module.scss';
import DropdownContent from './DropdownContent';
import useDropdownFunctions from './hooks/useDropdownFunctions';
import { DropdownGenericBaseType, DropdownProps } from './StellaDropdown';

type BodyProps = {
  showTooltip: boolean;
  searchValue: string | undefined;
  focusedIndex: number | undefined;
  totalAmountOfRecords?: number;
  dropdownWidth?: number;
  viewType?: 'search' | 'dropdown';
  filters?: JSX.Element | null;
  details?: JSX.Element | null;
  selectSingleItemByDefault?: boolean;
  setSearchValue: (searchValue: string) => void;
  setShowTooltip: (value: boolean) => void;
  setFocusedIndex: (value: number | undefined) => void;
  handleOpen: () => void;
};

export default function DropdownBody<T extends DropdownGenericBaseType>(props: DropdownProps<T> & BodyProps) {
  const {
    id,
    placeholder,
    values,
    selectedValues,
    error,
    className,
    autoFocus,
    showTooltip,
    dropdownWidth,
    searchValue,
    filters,
    details,
    focusedIndex,
    totalAmountOfRecords,
    viewType = 'dropdown',
    renderTopLvl = true,
    disabled = false,
    hideLabel = false,
    selectSingleItemByDefault = false,
    extraRight,
    setFocusedIndex,
    onChange,
    onFocus,
    setShowTooltip,
    setSearchValue,
    handleOpen
  } = props;

  const { onClickItem, onBlur, handleKeyDown, getShownValues } = useDropdownFunctions({
    id,
    values,
    focusedIndex,
    searchValue,
    selectedValues,
    selectSingleItemByDefault,
    onChange,
    setShowTooltip,
    setFocusedIndex,
    setSearchValue,
    idBased: true
  });

  return (
    <TooltipTrigger
      tooltip={
        <DropdownContent
          {...props}
          totalAmountOfRecords={totalAmountOfRecords}
          filters={filters}
          details={details}
          extraRight={extraRight}
          searchValue={searchValue}
          focusedIndex={focusedIndex}
          viewType={viewType}
          setSearchValue={setSearchValue}
          setFocusedIndex={setFocusedIndex}
          onClickItem={onClickItem}
          getShownValues={getShownValues}
          handleKeyDown={handleKeyDown}
        />
      }
      placement="bottom-start"
      trigger="click"
      renderTopLvl={renderTopLvl}
      showTooltip={showTooltip}
      width={dropdownWidth}
      disabled={disabled}
    >
      {viewType === 'dropdown' ? (
        <>
          <StellaInputField
            id={id}
            className={classNames(css['dropdown'], className)}
            value={searchValue}
            placeholder={placeholder}
            disabled={disabled}
            error={error}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            iconRight={disabled ? null : <Icon name="caret-down" className={css['dropdown-icon']} />}
            hideLabel={hideLabel}
            onChange={setSearchValue}
            onClick={handleOpen}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </>
      ) : (
        <SearchBar
          id={id}
          value={searchValue}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          hideLabel={hideLabel}
          onChange={(val) => {
            setSearchValue(val);
            if (!val) onChange([]);
          }}
          onClick={handleOpen}
          onFocus={onFocus}
        />
      )}
    </TooltipTrigger>
  );
}
