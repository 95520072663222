import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import AsyncCardSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncCardSpinner';

import Button from '../../../../components/Button/Button';
import { useStore } from '../../../../hooks/useStore';
import LanguageCardBody from './LanguageCardBody';

const LanguageCard = observer(() => {
  const { applicationStore, communicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { localCultureTable, initLanguages, addLanguage } = communicationStore.settingsStore;

  useEffect(() => {
    initLanguages();
  }, []);

  return (
    <AsyncCardSpinner
      title={getTranslation('communication.language_card_header')}
      variables={[localCultureTable]}
      height="27rem"
    >
      <Card id="language-card" width="1">
        <CardHeader
          extraRight={
            <Button id="language.add" type="text" icon="plus" onClick={addLanguage}>
              {getTranslation('general.add')}
            </Button>
          }
        >
          {getTranslation('communication.language_card_header')}
        </CardHeader>
        <CardBody height="27rem">
          <LanguageCardBody />
        </CardBody>
      </Card>
    </AsyncCardSpinner>
  );
});

export default LanguageCard;
