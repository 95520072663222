import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { colors } from '@zf/utils/src/color';

import { useStore } from '../../../../../hooks/useStore';

type Props = {
  billingCompleteness: BillingCompletenessType;
};

const CompletenessInvoiceStatus = (props: Props) => {
  const { billingCompleteness } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  if (
    billingCompleteness.status === billingCompletenessStatus.closed ||
    billingCompleteness.status === billingCompletenessStatus.manuallyclosed
  ) {
    return (
      <NewStatusBadge status_={getTranslation('contract.invoices_will_be_credited')} color={colors['orange-600']} />
    );
  }

  return <NewStatusBadge status_={getTranslation('contract.no_invoice')} color={colors['silver-700']} />;
};

export default CompletenessInvoiceStatus;
