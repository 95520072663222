import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import CommitSection from '../../../components/config/commit-section';
import { dialog } from '../../../events/dialog-events';
import { useStore } from '../../../hooks/useStore';
import ScenarioTrees from './scenario-trees/ScenarioTrees';
import DiscardDialog from './scenario/dialogs/discardChanges';
import Scenario from './scenario/Scenario';
import css from './templates-tab.module.scss';

const TemplatesTab = () => {
  const { communicationStore, applicationStore } = useStore();
  const {
    templateForm_,
    initTemplatesTab,
    discardChanges,
    setDiscardChanges,
    selectedEntitySubjectType,
    selectedEntitySubjectSubtype
  } = communicationStore.templatesStore;
  const { onSubmit, clickRef } = useDialog();

  useEffect(() => initTemplatesTab(selectedEntitySubjectType, selectedEntitySubjectSubtype), []);

  useEffect(() => {
    if (discardChanges) {
      dialog.normal({
        title: applicationStore.getTranslation('general.discard'),
        icon: 'cross',
        content: <DiscardDialog ref={clickRef} />,
        type: 'danger',
        buttonPositive: applicationStore.getTranslation('general.discard'),
        onSubmit
      });
      setDiscardChanges(false);
    }
  }, [discardChanges]);

  return (
    <>
      <CommitSection
        handleCancel={templateForm_?._reset}
        handleSave={templateForm_?.save}
        isDirty={templateForm_?._isDirty}
      />
      <div className={css['split-view']}>
        <ScenarioTrees />

        <div className={css['cards-container']}>
          <AsyncVarSpinner variables={[templateForm_]}>
            <Scenario />
          </AsyncVarSpinner>
        </div>
      </div>
    </>
  );
};

export default observer(TemplatesTab);
