import React from 'react';

import { ExportInsightsType } from '@zf/api-types/analytics/metering';
import { PagedResponseType } from '@zf/api-types/api';
import { FileFormatType } from '@zf/api-types/file-format';
import { Label } from '@zf/stella-react/src/atoms/Label';
import IconLabel from '@zf/stella-react/src/atoms/Label/StellaIconLabel';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Tree, TreeItem } from '@zf/stella-react/src/atoms/Tree';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import { DANGER, SUCCESS } from '../../../../constants/color';
import { BaseLink } from '../../../../design-system/Components';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import css from './tree-style.module.scss';

type Props = {
  exportInsights: ExportInsightsType[];
  fileFormats: FileFormatType[];
  setFileFormats: React.Dispatch<React.SetStateAction<FileFormatType[]>>;
};

export default function ExportCardBody(props: Props) {
  const { fileFormats, setFileFormats } = props;
  const { exportInsights } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const fileFormatsResponse = useSuspenseSingleAPI<PagedResponseType<FileFormatType>>({
    request: {
      endpoint: '/cfg/DataExportFileFormats'
    }
  });

  React.useEffect(() => {
    if (fileFormatsResponse.result) {
      /*  const filteredFormats = fileFormatsResponse.result.data.results.filter(format => {
        return !!exportInsights.find(insight => {
          return insight.dataExportId === format.id;
        });
      }); */

      const filteredFormats = fileFormatsResponse.result.data.results;

      setFileFormats(filteredFormats);
    }
  }, [fileFormatsResponse.result]);

  if (!fileFormatsResponse.result) return null;

  /*   exportInsights = [
    {
      dataExportId: '456',
      lastTimeExported: '2020-04-09T21:59:59.999Z',
      waiting: 1,
      unprocessed: 7,
      failed: 8
    }
  ]; */

  const ready = exportInsights.reduce((acc: number, insight: ExportInsightsType) => {
    acc += insight.waiting;
    return acc;
  }, 0);

  const extraRight =
    exportInsights.length === 0 ? (
      <BaseLink id="configure-export" className={css['configure']} url={`${rootUrl}/configuration/export-jobs`}>
        {i18n.getTranslation('general.configure')}
      </BaseLink>
    ) : (
      <Paragraph className={css['ready-paragraph']}>
        {i18n.getTranslation('dashboard.general_tab.amount_ready', {
          amount: ready
        })}
      </Paragraph>
    );

  const labelColor = colors['silver-600'];

  return (
    <Tree
      id="export-insights-tree"
      title={
        <>
          <IconLabel
            className={css['title']}
            labelText={i18n.getTranslation('export_jobs.export')}
            iconType="export-job"
          />
          {extraRight}
        </>
      }
      className={css['tree']}
      clickeableContent={false}
      arrowType="filled"
      openOnInit={exportInsights.length > 0}
    >
      {exportInsights.map((insight, index) => {
        const format = fileFormats.find((f) => {
          return f.id === insight.dataExportId;
        });

        const treeTitle = format ? format.name : '';

        return (
          <Tree
            id={`export-insight-${index}`}
            key={`export-insight-${index}`}
            title={<Label className={css['insights-title']}>{treeTitle}</Label>}
            className={css['tree-nested']}
            clickeableContent={false}
            arrowType="filled"
            openOnInit={index === 0}
          >
            <TreeItem id={`last_sending_date-${index}`} className={css['tree-item']} onClick={() => {}}>
              <Label>{i18n.getTranslation('export_jobs.last_sending_date')}</Label>
              <Paragraph>{formatDate(insight.lastTimeExported)}</Paragraph>
            </TreeItem>

            <TreeItem id={`ready_for_export-${index}`} className={css['tree-item']} onClick={() => {}}>
              <IconLabel
                iconType="check"
                labelText={i18n.getTranslation('export_jobs.ready_for_export')}
                color={SUCCESS}
              />
              <Paragraph>{insight.waiting}</Paragraph>
            </TreeItem>

            <TreeItem id={`failed-${index}`} className={css['tree-item']} onClick={() => {}}>
              <IconLabel iconType="exclamation" labelText={i18n.getTranslation('export_jobs.failed')} color={DANGER} />
              <Paragraph>{insight.failed}</Paragraph>
            </TreeItem>

            <TreeItem id={`unprocessed-${index}`} className={css['tree-item']} onClick={() => {}}>
              <IconLabel
                iconType="unprocessed"
                labelText={i18n.getTranslation('export_jobs.unprocessed')}
                color={labelColor}
              />
              <Paragraph>{insight.unprocessed}</Paragraph>
            </TreeItem>
          </Tree>
        );
      })}
    </Tree>
  );
}
