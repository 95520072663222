import React, { useEffect } from 'react';

import {
  advanceAmountChangedBy,
  AdvanceAmountLimitType,
  advanceFrequency,
  aggregationFrequency,
  attributeType,
  bankStatementCreditDebit,
  bankTransactionType,
  billingCalculationStreamStatus,
  billingCalculationType,
  billingCompletenessStatus,
  billingItemLevel,
  billingTariffInputType,
  calculatedLineStatus,
  calculationFormulaComponentType,
  collectionFlowStatus,
  collectionStepLevel,
  collectionStepStatus,
  communicationDocumentType,
  communicationType,
  contactType,
  contractScenarioType,
  contractStatus,
  countryCode,
  culture,
  customEntityPropertyValueType,
  customerType,
  dataExportCommunicationType,
  dataExportInvoiceFormatType,
  dataFrequency,
  dataImportCommunicationType,
  dataImportMeteringFormatType,
  dataImportMigrationFormatType,
  direction,
  dnsRecordMeaning,
  dnsRecordStatus,
  documentOutputFormat,
  domainProblemDataType,
  entityAttributeType,
  entitySubjectType,
  envelopePosition,
  executionStatus,
  exportJobLineStatus,
  exportJobStatus,
  exportOutgoingBankingTransactionPaymentRefundFormatType,
  exportSettingsCategoryType,
  fileSizeType,
  formulaFunctionCategory,
  formulaFunctionInputParameterReferenceType,
  formulaFunctionType,
  formulaStreamType,
  formulaType,
  ftpProtocol,
  importCategoryType,
  importContractInvoiceDateHandling,
  importJobEntityIssueStage,
  importJobEntityType,
  importJobStatus,
  incomingBankingTransactionFormatType,
  incomingBankingTransactionStatus,
  incomingMutationStatus,
  incomingMutationType,
  incrementationType,
  inputMissingReason,
  invoiceFrequency,
  invoiceLineType,
  invoiceScenarioType,
  invoiceStatus,
  invoiceType,
  languageEnum,
  localisationLevel,
  managementRelationType,
  mandateStatus,
  mandateType,
  messageType,
  meteringType,
  meterStatus,
  meterType,
  moveRequestScenarioType,
  billingRelationScenarioType,
  moveRequestStatus,
  moveRequestType,
  mutationStatus,
  numberSequenceReferenceEntity,
  numberSequenceSegmentType,
  outgoingBankingTransactionStatus,
  outgoingBankingTransactionType,
  outgoingMutationStatus,
  outgoingMutationType,
  paymentDelayEnum,
  paymentMethod,
  paymentProcessStatus,
  paymentRefundOutgoingBankTransactionFormatType,
  paymentRequestOutgoingBankTransactionFormatType,
  paymentRetryAction,
  paymentReversalReason,
  paymentReversalReasonCode,
  paymentTermsFrequency,
  paymentType,
  pluginTriggerDataType,
  pluginType,
  problemLevel,
  problemResolutionStatus,
  productItemPeriodicityType,
  propertyGroupBillingPeriodTariffStatus,
  propertyType,
  scheduledJobRecurrence,
  scheduledJobType,
  sentStatus,
  serviceStatus,
  templateAssetType,
  templateObjectType,
  templateUsecase,
  transactionReferenceType,
  transactionType,
  translationStatus,
  unitOfMeasure,
  utilityType,
  valueProviderType,
  writeOffHandlingType,
  scenarioGroupingType,
  attachmentVisibility,
  tariffType,
  statusCode,
  twikeyPluginStatus,
  uiCulture,
  incomingMutationPaymentMethod,
  contractBillingMethod,
  consumerGroupSuggestion,
  topupOrigin,
  custAgingBucketType,
  exportStatus,
  tierCalculationMethod,
  billingScope,
  MeteringIssueStatus,
  MeteringIssueResolutionReason,
  MeteringIssueError,
  MeteringIssueResolutionType,
  Level,
  AdvanceCalculationFallbackReason,
  AdvanceRecalculationType
} from '@zf/api-types/enums';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { capitalizeFirstWord } from '@zf/utils/src/string';

import logError from '../../utils/handleError';
import { sendRequest } from '../../utils/request';
import { LangReturnValue } from './use-lang';

export type EnumKeyType =
  | 'advanceFrequency'
  | 'attributeType'
  | 'bankStatementCreditDebit'
  | 'bankTransactionType'
  | 'billingCalculationStreamStatus'
  | 'billingCalculationType'
  | 'billingCompletenessStatus'
  | 'billingItemLevel'
  | 'billingTariffInputType'
  | 'calculatedLineStatus'
  | 'calculationFormulaComponentType'
  | 'calculationFormulaParameter'
  | 'calculationTriggerType'
  | 'communicationDocumentType'
  | 'communicationType'
  | 'contactType'
  | 'contractStatus'
  | 'countryCode'
  | 'customerType'
  | 'dataExportCommunicationType'
  | 'dataExportInvoiceFormatType'
  | 'dataFrequency'
  | 'dataImportCommunicationType'
  | 'dataImportMeteringFormatType'
  | 'dataImportMigrationFormatType'
  | 'direction'
  | 'dnsRecordMeaning'
  | 'dnsRecordStatus'
  | 'documentOutputFormat'
  | 'domainProblemDataType'
  | 'entityAttributeType'
  | 'entitySubjectType'
  | 'executionStatus'
  | 'exportSettingsCategoryType'
  | 'exportJobLineStatus'
  | 'exportJobStatus'
  | 'fileSizeType'
  | 'formulaStreamType'
  | 'ftpProtocol'
  | 'importCategoryType'
  | 'importContractInvoiceDateHandling'
  | 'importJobEntityIssueStage'
  | 'importJobEntityType'
  | 'importJobStatus'
  | 'incrementationType'
  | 'invoiceFrequency'
  | 'invoiceLineType'
  | 'invoiceStatus'
  | 'invoiceType'
  | 'language'
  | 'localisationLevel'
  | 'managementRelationType'
  | 'mandateStatus'
  | 'mandateType'
  | 'messageType'
  | 'meteringType'
  | 'meterStatus'
  | 'meterType'
  | 'numberSequenceReferenceEntity'
  | 'numberSequenceSegmentType'
  | 'paymentDelay'
  | 'paymentMethod'
  | 'paymentRetryAction'
  | 'paymentReversalReason'
  | 'paymentReversalReasonCode'
  | 'paymentTermsFrequency'
  | 'problemLevel'
  | 'problemResolutionStatus'
  | 'propertyType'
  | 'scheduledJobRecurrence'
  | 'scheduledJobType'
  | 'sentStatus'
  | 'serviceStatus'
  | 'templateAssetType'
  | 'templateObjectType'
  | 'templateUsecase'
  | 'transactionReferenceType'
  | 'transactionType'
  | 'unitOfMeasure'
  | 'utilityType'
  | 'valueProviderType'
  | 'collectionStepType'
  | 'collectionChargeType'
  | 'writeOffHandlingType'
  | 'propertyGroupType'
  | 'billingItemTariffCalculationType'
  | 'formulaFunctionInputParameterReferenceType'
  | 'formulaType'
  | 'propertyGroupBillingPeriodTariffStatus'
  | 'formulaFunctionCategory'
  | 'aggregationFrequency'
  | 'productItemPeriodicityType'
  | 'moveRequestStatus'
  | 'moveRequestType'
  | 'formulaFunctionType'
  | 'collectionStepStatus'
  | 'collectionFlowStatus'
  | 'outgoingBankingTransactionType'
  | 'outgoingBankingTransactionStatus'
  | 'mutationStatus'
  | 'incomingMutationType'
  | 'outgoingMutationType'
  | 'paymentProcessStatus'
  | 'culture'
  | 'paymentType'
  | 'incomingMutationStatus'
  | 'outgoingMutationStatus'
  | 'incomingBankingTransactionStatus'
  | 'paymentRefundOutgoingBankTransactionFormatType'
  | 'paymentRequestOutgoingBankTransactionFormatType'
  | 'exportOutgoingBankingTransactionPaymentRefundFormatType'
  | 'incomingBankingTransactionFormatType'
  | 'inputMissingReason'
  | 'inputMissingReason'
  | 'pluginTriggerDataType'
  | 'pluginType'
  | 'invoiceScenarioType'
  | 'collectionStepLevel'
  | 'contractScenarioType'
  | 'moveRequestScenarioType'
  | 'billingRelationScenarioType'
  | 'scenarioGroupingType'
  | 'envelopePosition'
  | 'translationStatus'
  | 'advanceAmountChangedBy'
  | 'customEntityPropertyValueType'
  | 'advanceAmountLimitType'
  | 'attachmentVisibility'
  | 'tariffType'
  | 'statusCode'
  | 'twikeyPluginStatus'
  | 'uiCulture'
  | 'incomingMutationPaymentMethod'
  | 'contractBillingMethod'
  | 'topupOrigin'
  | 'custAgingBucketType'
  | 'exportStatus'
  | 'tierCalculationMethod'
  | 'accuracyEnum'
  | 'exportStatus'
  | 'systemMessageLevel'
  | 'billingScope'
  | 'meteringIssueResolutionType'
  | 'meteringIssueError'
  | 'meteringIssueStatus'
  | 'meteringIssueResolutionReason'
  | 'level'
  | 'advanceCalculationFallbackReason'
  | 'advanceRecalculationType';

export type ZFEnumType =
  | advanceFrequency
  | attributeType
  | bankStatementCreditDebit
  | bankTransactionType
  | billingCalculationStreamStatus
  | billingCalculationType
  | billingCompletenessStatus
  | billingItemLevel
  | billingTariffInputType
  | calculatedLineStatus
  | calculationFormulaComponentType
  | communicationDocumentType
  | communicationType
  | contactType
  | contractStatus
  | countryCode
  | customerType
  | dataExportCommunicationType
  | dataExportInvoiceFormatType
  | dataFrequency
  | dataImportCommunicationType
  | dataImportMeteringFormatType
  | dataImportMigrationFormatType
  | dnsRecordMeaning
  | dnsRecordStatus
  | documentOutputFormat
  | domainProblemDataType
  | entityAttributeType
  | entitySubjectType
  | executionStatus
  | exportSettingsCategoryType
  | exportJobLineStatus
  | exportJobStatus
  | fileSizeType
  | formulaStreamType
  | ftpProtocol
  | importCategoryType
  | importContractInvoiceDateHandling
  | importJobEntityIssueStage
  | importJobEntityType
  | importJobStatus
  | incrementationType
  | invoiceFrequency
  | invoiceLineType
  | invoiceStatus
  | invoiceType
  | languageEnum
  | localisationLevel
  | managementRelationType
  | mandateStatus
  | mandateType
  | messageType
  | meteringType
  | meterStatus
  | meterType
  | numberSequenceReferenceEntity
  | numberSequenceSegmentType
  | paymentDelayEnum
  | paymentMethod
  | paymentRetryAction
  | paymentReversalReason
  | paymentReversalReasonCode
  | paymentTermsFrequency
  | problemLevel
  | problemResolutionStatus
  | propertyType
  | scheduledJobRecurrence
  | scheduledJobType
  | sentStatus
  | serviceStatus
  | templateAssetType
  | templateObjectType
  | templateUsecase
  | transactionReferenceType
  | transactionType
  | unitOfMeasure
  | utilityType
  | valueProviderType
  | writeOffHandlingType
  | formulaFunctionInputParameterReferenceType
  | formulaFunctionType
  | formulaType
  | propertyGroupBillingPeriodTariffStatus
  | formulaFunctionCategory
  | aggregationFrequency
  | productItemPeriodicityType
  | moveRequestStatus
  | moveRequestType
  | collectionStepStatus
  | collectionFlowStatus
  | outgoingBankingTransactionType
  | outgoingBankingTransactionStatus
  | mutationStatus
  | incomingMutationType
  | outgoingMutationType
  | paymentProcessStatus
  | culture
  | paymentType
  | incomingMutationStatus
  | outgoingMutationStatus
  | incomingBankingTransactionStatus
  | paymentRefundOutgoingBankTransactionFormatType
  | paymentRequestOutgoingBankTransactionFormatType
  | exportOutgoingBankingTransactionPaymentRefundFormatType
  | incomingBankingTransactionFormatType
  | inputMissingReason
  | inputMissingReason
  | pluginTriggerDataType
  | pluginType
  | invoiceScenarioType
  | collectionStepLevel
  | contractScenarioType
  | moveRequestScenarioType
  | billingRelationScenarioType
  | scenarioGroupingType
  | envelopePosition
  | translationStatus
  | direction
  | advanceAmountChangedBy
  | customEntityPropertyValueType
  | AdvanceAmountLimitType
  | attachmentVisibility
  | tariffType
  | statusCode
  | twikeyPluginStatus
  | uiCulture
  | incomingMutationPaymentMethod
  | contractBillingMethod
  | consumerGroupSuggestion
  | topupOrigin
  | custAgingBucketType
  | exportStatus
  | tierCalculationMethod
  | billingScope
  | MeteringIssueResolutionType
  | MeteringIssueError
  | MeteringIssueStatus
  | MeteringIssueResolutionReason
  | Level
  | AdvanceCalculationFallbackReason
  | AdvanceRecalculationType;

export type EnumReturnValue = {
  getEnum: <T>(name: EnumKeyType, capitalize?: boolean) => DropdownValuesType<T>[];
  getTranslation(enumKey: EnumKeyType, symbol: string): string;
};

type EnumerationSymbolType = {
  symbol: string; // The enum entry
  label: string; // The translation
};

type EnumRequestType = {
  type: EnumKeyType;
  symbols: EnumerationSymbolType[];
};

export type EnumType<T> = DropdownValuesType<T>;

type EnumsType = Record<EnumKeyType, EnumerationSymbolType[]> | null;

/**
 * @deprecated, use applicationstore instead
 */
export default function useEnum(i18n: LangReturnValue) {
  const [enums, setEnums] = React.useState<EnumsType>(null);

  useEffect(() => {
    sendRequest<EnumRequestType[]>({
      request: {
        endpoint: '/sys/enumerations'
      },
      donotCache: true,
      lang: i18n.lang
    })
      .then((result) => {
        try {
          const res = result.data.reduce((acc: Record<string, EnumerationSymbolType[]>, val) => {
            acc[val.type] = val.symbols;
            return acc;
          }, {});

          setEnums(res);
        } catch (e) {
          // @ts-ignore
          logError(e);
        }
      })
      .catch((e) => {
        // @ts-ignore
        logError(e);
      });
  }, [i18n.lang]);

  function getEnum<T>(name: EnumKeyType, capitalize = true) {
    if (!enums) return [];

    const foundEnum = enums[name];
    if (!foundEnum) return [];

    const returnValue: EnumType<T>[] = foundEnum.map((val) => {
      return {
        value: val.symbol as any,
        text: capitalize ? capitalizeFirstWord(val.label) : val.label
      };
    });

    // Exceptions
    if (name === 'dataFrequency') {
      return returnValue.filter((e) => {
        const casted = e.value as unknown as dataFrequency;
        return (
          casted !== dataFrequency.pt1m &&
          casted !== dataFrequency.pt5m &&
          casted !== dataFrequency.pt15m &&
          casted !== dataFrequency.pt30m
        );
      });
    }

    return returnValue;
  }

  function getTranslation(enumKey: EnumKeyType, symbol: string) {
    if (!enums) return symbol;

    if (enumKey && (typeof symbol === 'undefined' || symbol === null || symbol === '')) {
      // eslint-disable-next-line
      console.error(`Tried to get an enum translation for ${enumKey} without an enum value`);
    }

    const found = enums[enumKey].find((e) => e.symbol === symbol);

    if (!found) {
      // eslint-disable-next-line
      console.error(`Translation not found for ${enumKey}: ${symbol}`);
    }

    const text = (found?.label || symbol)?.trim();

    if (enumKey === 'unitOfMeasure') {
      return text;
    }

    return capitalizeFirstWord(text);
  }

  return { getEnum, getTranslation };
}
