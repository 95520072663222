import classNames from 'classnames';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../app-context';
import Button from '../../components/Button/Button';
import { dialog } from '../../events/dialog-events';
import AddTenantDialog from './AddTenantDialog';
import css from './tenant-footer.module.scss';

type Props = {
  refresh: () => void;
};

export default function TenantFooter(props: Props) {
  const { refresh } = props;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const openAddDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('tenant.add_tenant'),
      icon: 'organisation',
      content: <AddTenantDialog validationRef={validationRef} ref={clickRef} refresh={refresh} />,
      buttonPositive: i18n.getTranslation('general.add'),
      onSubmit,
      ref: validationRef
    });
  };

  return (
    <div className={css['footer']}>
      <div className={classNames(css['center'])}>
        <Button id="tenant.add" icon="plus" type="secondary" onClick={openAddDialog}>
          {i18n.getTranslation('tenant.add_tenant')}
        </Button>
      </div>
    </div>
  );
}
