import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import { useStore } from '../../../hooks/useStore';
import ProductBillingItems from './billing-items-column/ProductBillingItems';
import ProductsColumn from './product-column/ProductsColumn';
import css from './products-tab.module.scss';
import ProductTariffs from './tariffs-column/ProductTariffs';
import { StringParam, useQueryParams } from 'use-query-params';

const ProductsTab = () => {
  const { productConfigStore } = useStore();
  const { isPanelViewMode } = productConfigStore;
  const [query] = useQueryParams({
    productName: StringParam,
    billingItemId: StringParam
  });

  return (
    <ColumnsContainer className={classNames(css['prod-config'], { [css['two-column']]: isPanelViewMode })}>
      <ProductsColumn productName={query.productName || ''} billingItemId={query.billingItemId || ''} />
      <ProductBillingItems />
      <ProductTariffs />
    </ColumnsContainer>
  );
};

export default observer(ProductsTab);
