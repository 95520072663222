import React from 'react';

import { OutgoingMutationRowType } from '@zf/api-types/billing/outgoing-mutations';
import { outgoingMutationStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteDialog from '../../../../../../../actions/outgoing-mutation/DeleteDialog';
import { useAppContext } from '../../../../../../../app-context';
import ConfirmationActionButton from '../../../../../../../components/Button/ConfirmationActionButton';

type Props = {
  selectedRows: OutgoingMutationRowType[];
  refresh: () => void;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function DeleteDetailButton(props: Props) {
  const { selectedRows, refresh } = props;
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  const isDisabled = selectedRows.some((r) => {
    return (
      r.__entity.status === outgoingMutationStatus.toconfirmbybank ||
      r.__entity.status === outgoingMutationStatus.closed ||
      r.__entity.status === outgoingMutationStatus.reversed ||
      r.__entity.status === outgoingMutationStatus.failedtoclose ||
      r.__entity.status === outgoingMutationStatus.failedtoreverse
    );
  });

  return (
    <ConfirmationActionButton
      id="outgoing_mutation.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={<DeleteDialog {...props} onComplete={refresh} ref={clickRef} />}
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0 || isDisabled}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.delete')}
      dialogType="danger"
    />
  );
}
