import { observer } from 'mobx-react';
import React from 'react';

import { AddressType } from '@zf/api-types/general';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { SuspenseCardBody } from '../../../../../components/suspense';
import { useStore } from '../../../../../hooks/useStore';
import css from './meter-card.module.scss';
import MeterCardBody from './MeterCardBody';

type Props = {
  serviceLocationId: string;
  address: AddressType;
};

const MeterCard = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="meter-card" width="2">
      <CardHeader>{getTranslation('meter.meters')}</CardHeader>
      <SuspenseCardBody className={css['meter-card-body']} fixedHeight>
        <MeterCardBody {...props} />
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(MeterCard);
