import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { ModelPagedResultType } from '@zf/api-types/model';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../app-context/app-context';
import useSingleAPI from '../../../hooks/useSingleAPI';
import Dropdown from '../../Lang/Dropdown';

type MetersDropdownProps = {
  id?: string;
  meters: MeterType[];
  isInline?: boolean;
  selectedValue: string;
  error?: boolean;
  onFocus?: (step: string) => void;
  onChange: (values: MeterType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<MeterType>>(Dropdown);

export default function MetersDropdownContent(props: MetersDropdownProps) {
  const { id, meters, isInline = false, selectedValue, error, onFocus, onChange } = props;

  const { i18n } = useAppContext();

  const modelsResponse = useSingleAPI<ModelPagedResultType>({
    request: {
      endpoint: '/cfg/models/'
    }
  });

  if (!modelsResponse || !modelsResponse.result || modelsResponse.result instanceof Promise) {
    return isInline ? (
      <InlineDropdown
        id={id ? id : 'meter-dropdown'}
        onChange={onChange}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    ) : (
      <Dropdown
        id={id ? id : 'meter-dropdown'}
        onChange={onChange}
        placeholder={i18n.getTranslation('meter.meters')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    );
  }

  const models = modelsResponse.result.data.results;

  const getModelForMeter = (meter: MeterType) => {
    return models.find((m) => {
      return m.id === meter.modelId;
    });
  };

  const dropdownMeters = meters.map((m) => {
    const model = getModelForMeter(m);
    const modelString = model ? ` -${model.brandName ? ` ${model.brandName}` : ''} ${model.name}` : '';

    return {
      value: m,
      text: `${m.serialNumber}${modelString}`
    };
  });

  return isInline ? (
    <InlineDropdown
      id={id ? id : 'meter-dropdown'}
      onChange={onChange}
      values={dropdownMeters}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      error={error}
      clear
    />
  ) : (
    <Dropdown
      id={id ? id : 'meter-dropdown'}
      onChange={onChange}
      placeholder={i18n.getTranslation('meter.meters')}
      values={dropdownMeters}
      selectedValues={[selectedValue]}
      onFocus={onFocus}
      error={error}
      clear
    />
  );
}
