import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { isMaxDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import Loader from '../../../../components/Lang/Loader';
import css from './consumptions-graph.module.scss';
import { Graph } from './regular-graph/graph';
import useServiceConsumptions from './hooks/useServiceConsumptions';
import { CompareGraph } from './compare-graph/CompareGraph';

export function ConsumptionsGraph() {
  const { i18n } = useAppContext();

  const { isLoading, isCompareMode, startDateTime, endDateTime } = useServiceConsumptions();

  const [fallbackDomainEnd] = React.useState(parseInt(moment().format('x')));

  const domainStart = parseInt(startDateTime.format('x'));
  const domainEnd = isMaxDate(endDateTime) ? fallbackDomainEnd : parseInt(endDateTime.format('x'));

  return (
    <div className={classNames(css['wrapper'], { [css['compare-mode']]: isCompareMode })}>
      {isLoading && (
        <div className={css['stackedDiv1']}>
          <Loader className={css['loader']} text={i18n.getTranslation('graph.loading')} />
        </div>
      )}
      <div className={classNames(css['stackedDiv2'], { [css['compare-mode']]: isCompareMode })}>
        {isCompareMode ? <CompareGraph /> : <Graph domainStart={domainStart} domainEnd={domainEnd} />}
      </div>
    </div>
  );
}
