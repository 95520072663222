import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { BillingItemType, BillingTariffRequestType, BillingTariffType, ProductType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';

import ConfigColumn from '../../../../components/Column/ConfigColumn';
import { dialog } from '../../../../events/dialog-events';
import { useStore } from '../../../../hooks/useStore';
import css from './billing-tariffs.module.scss';
import BillingTariffMoreActions from './BillingTariffMoreActions';
import AddEditTariffDialog from './dialogs/AddEditTariffDialog';
import { ConfigHelp } from '../../../../components/CoachMarks';
import TariffContent from './TariffContent';

type Props = {
  tariffs_: BillingTariffType[] | undefined;
  selectedBillingItem: BillingItemType | undefined;
  selectedProduct?: ProductType;
  showActionsOnDefault?: boolean;
  isProductPage: boolean;
  initTariffs: (billingItemId: string, selectedProductId?: string) => Promise<void>;
  addTariff: (tariffToAdd: BillingTariffRequestType) => Promise<BillingTariffType>;
  addTariffToList: (newTariff: BillingTariffType) => void;
  updateTariff: (id: string, tariffToUpdate: BillingTariffRequestType) => Promise<BillingTariffType>;
  updateTariffInList: (updatedTariff: BillingTariffType) => void;
  deleteTariff: (tariffId: string) => Promise<void>;
  deleteTariffInlist: (index: number) => void;
};

const BillingTariffs = (props: Props) => {
  const {
    tariffs_,
    selectedBillingItem,
    selectedProduct,
    showActionsOnDefault,
    isProductPage,
    initTariffs,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    deleteTariff,
    deleteTariffInlist
  } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { setOnlyShowTariffs, onlyShowTariffs, productsStore } = productConfigStore;
  const { treeData } = productsStore;

  const { clickRef, validationRef, onSubmit } = useDialog();

  useEffect(() => {
    if (selectedBillingItem) {
      initTariffs(selectedBillingItem.id, selectedProduct?.id);
    }
  }, [selectedBillingItem?.id]);

  useEffect(() => {
    return () => {
      setOnlyShowTariffs(false, selectedBillingItem?.id || '', selectedProduct?.id || '');
    };
  }, []);

  const openAddDialog = () => {
    dialog.normal({
      title: getTranslation('billing_tariff.tariff_period_add'),
      icon: 'plus',
      content: (
        <AddEditTariffDialog
          canAddInstantly
          treeNode={treeData[0]}
          editOnlyTariff={false}
          ref={clickRef}
          validationRef={validationRef}
          tariffs={tariffs_ || []}
          selectedBillingItem={selectedBillingItem}
          selectedProduct={selectedProduct}
          isProductPage={isProductPage}
          addTariff={addTariff}
          addTariffToList={addTariffToList}
          updateTariff={updateTariff}
          updateTariffInList={updateTariffInList}
          initTariffs={initTariffs}
        />
      ),
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      className={onlyShowTariffs ? css['full-width'] : ''}
      extraRight={
        selectedBillingItem && (
          <BillingTariffMoreActions
            billingItemId={selectedBillingItem.id}
            productId={selectedProduct?.id || ''}
            openTariffDialog={openAddDialog}
          />
        )
      }
      title={!onlyShowTariffs ? getTranslation('product_config.tariffs') : ''}
      coachMark={
        <ConfigHelp
          title={getTranslation('coachmark.tariffs.title')}
          content={[getTranslation('coachmark.tariffs.paragraph')]}
        />
      }
      disabled={!selectedBillingItem}
      borderRight={false}
    >
      <TariffContent
        isProductPage={isProductPage}
        tariffs_={tariffs_}
        deleteTariff={deleteTariff}
        deleteTariffInlist={deleteTariffInlist}
        showActionsOnDefault={showActionsOnDefault}
        selectedBillingItem={selectedBillingItem}
        selectedProduct={selectedProduct}
        addTariff={addTariff}
        addTariffToList={addTariffToList}
        updateTariff={updateTariff}
        updateTariffInList={updateTariffInList}
        initTariffs={initTariffs}
      />
    </ConfigColumn>
  );
};

export default observer(BillingTariffs);
