import clone from 'clone';
import { SettingDescription } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { billingCalculationType, meteringType, unitOfMeasure } from '@zf/api-types/enums';
import { ConsumptionCalculationTypeParametersType } from '@zf/api-types/product';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import { BillingItemValidatorType } from '../AddEditBillingItemDialog';
import css from './calculation-parameters.module.scss';

type Props = {
  calcParams: Map<billingCalculationType, ConsumptionCalculationTypeParametersType>;
  uomDropDown: DropdownValuesType<unitOfMeasure>[];
  setValue: (value: Partial<BillingItemValidatorType>) => void;
  setParametersValue: (key: billingCalculationType, value: Partial<ConsumptionCalculationTypeParametersType>) => void;
};

const ConsumptionParameters = (props: Props) => {
  const { calcParams, uomDropDown, setValue, setParametersValue } = props;
  const { applicationStore, productConfigStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;
  const { billingItemsStore } = productConfigStore;
  const { services, setUoMsAreLoading } = billingItemsStore.billingItemsDialogStore;

  const parameters = calcParams.get(billingCalculationType.consumption);

  const onChangeMeteringType = async (val: (meteringType | undefined)[]) => {
    const clonedCalcParams = clone(calcParams);
    const typeToUpdate = clonedCalcParams.get(billingCalculationType.consumption);

    if (typeToUpdate) {
      setUoMsAreLoading(true);

      try {
        const supportedUoms = await productConfigStore.productConfigService.getSupportedUomForBillingItem(
          billingCalculationType.consumption,
          val[0]
        );

        clonedCalcParams.set(billingCalculationType.consumption, {
          ...typeToUpdate,
          meteringType: val[0] as meteringType
        });

        setValue({
          calculationParameters: clonedCalcParams,
          supportedUoms
        });

        setUoMsAreLoading(false);
      } catch (e) {
        throw e;
      }
    }
  };

  return (
    <>
      <SettingDescription
        title={getTranslation('parameters.utilityType')}
        description={getTranslation('parameters.utilityType_descr')}
        setting={
          <SimpleDropdown
            id="utility-type"
            className={css['two-col-input-width']}
            onChange={(val) => setParametersValue(billingCalculationType.consumption, { utilityType: val[0] })}
            values={services}
            selectedValues={[parameters?.utilityType]}
            placeholder={getTranslation('parameters.utilityType')}
            error={!parameters?.utilityType}
            clear
          />
        }
        isMandatory
      />

      <SettingDescription
        title={getTranslation('parameters.meteringType')}
        description={getTranslation('parameters.meteringType_descr')}
        setting={
          <FlexElements className={css['two-col-input-width']} gap="16">
            <SimpleDropdown
              id="metering-type"
              onChange={onChangeMeteringType}
              values={getEnum<meteringType>('meteringType')}
              selectedValues={[parameters?.meteringType]}
              placeholder={getTranslation('parameters.meteringType')}
              error={!parameters?.meteringType}
              clear
            />
            <SimpleDropdown
              id="UoM"
              onChange={(val) => setParametersValue(billingCalculationType.consumption, { unitOfMeasure: val[0] })}
              values={uomDropDown}
              selectedValues={[parameters?.unitOfMeasure]}
              placeholder={getTranslation('parameters.unitOfMeasure')}
              error={parameters?.meteringType && !parameters?.unitOfMeasure}
              disabled={!parameters?.meteringType}
              clear
            />
          </FlexElements>
        }
        isMandatory
      />
    </>
  );
};

export default observer(ConsumptionParameters);
