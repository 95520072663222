import DateRangePickerLabel from 'components/input/DateRangePickerLabel';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import { Query } from './types';
import css from './right-filters.module.scss';
import { useTransactionsContext } from '../Context/Context';

type Props = {
  getValues: UseFormGetValues<Query>;
  setValue: UseFormSetValue<Query>;
};

const RightFilters: FC<Props> = ({ getValues, setValue }) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useTransactionsContext(); //TODO - change this behavior in technical debt

  return (
    <div className={css['right-filter-bare-dropdowns']}>
      <DateRangePickerLabel
        id="detected-between"
        startDate={convertToMoment(getValues('createdStartDate'))}
        endDate={convertToMoment(getValues('createdEndDate'))}
        label={getTranslation('transactions.transaction_date')}
        setDates={(dates) => {
          setValue('createdStartDate', convertMomentToISOString(dates[0]));
          setValue('createdEndDate', convertMomentToISOString(dates[1]));
        }}
      />
    </div>
  );
};

export default observer(RightFilters);
