import React, { useMemo } from 'react';

import { attachmentVisibility } from '@zf/api-types/enums';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { removeFromList } from '@zf/utils/src/array';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import { RadioInput } from '../../../../design-system/Components';
import { useStore } from '../../../../hooks/useStore';
import ContractsDropdown from '../../../Dropdown/contract-dropdown/regular/ContractsDropdown';
import FilterInputs from '../../../Filters/filter-inputs';
import FilterTag from '../../../Filters/filter-tag';
import FilterTags from '../../../Filters/filter-tags';
import DateRangePicker from '../../../input/DateRangePicker';
import EnumFilterSelect from '../../../input/EnumFilterSelect';
import { EntityAttachmentQueryParams } from './FilesStore';

export default function useFilesFilter(
  queryParams: EntityAttachmentQueryParams,
  setQueryParam: (val: Partial<EntityAttachmentQueryParams>) => void,
  resetAll: () => void,
  customerId = '',
  includeContractFilter = false
) {
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const tags: React.ReactNode[] = [];
  const filterInputs: React.ReactNode[] = [];

  const contractQueryParams = useMemo(() => {
    return { customerId };
  }, [customerId]);

  if (queryParams.contractId && queryParams.contractNumber) {
    tags.push(
      <FilterTag
        key="contract"
        value={queryParams.contractNumber}
        removeFilter={() => setQueryParam({ contractId: undefined, contractNumber: undefined })}
      />
    );
  }

  if (typeof queryParams.viewed !== 'undefined' && customerId) {
    tags.push(
      <FilterTag
        key="seen"
        type="boolean"
        prefix={getTranslation('entity_attachment.seen')}
        value={queryParams.viewed}
        removeFilter={() => setQueryParam({ viewed: undefined })}
      />
    );
  }

  if (queryParams.visibilities) {
    tags.push(
      queryParams.visibilities.map((v) => {
        return (
          <FilterTag
            key={`attachmentVisibility-${v}`}
            type="enum"
            enumType="attachmentVisibility"
            value={v}
            removeFilter={() => setQueryParam({ visibilities: removeFromList(v, queryParams.visibilities) })}
          />
        );
      })
    );
  }

  if (queryParams.createdStartDateTime || queryParams.createdEndDateTime) {
    tags.push(
      <FilterTag
        key="created-period"
        type="period"
        value={[queryParams.createdStartDateTime, queryParams.createdEndDateTime]}
        removeFilter={() => setQueryParam({ createdStartDateTime: undefined, createdEndDateTime: undefined })}
      />
    );
  }

  const filterTags = <FilterTags key="filter-tags" tags={tags} />;

  if (customerId && includeContractFilter) {
    filterInputs.push(
      <Label key="label-customer">{getTranslation('contracts.contract')}</Label>,
      <ContractsDropdown
        id="by_contract"
        key="by_contract"
        selectedValue={queryParams.contractId || ''}
        onChange={(val) =>
          setQueryParam({
            contractId: val[0]?.id,
            contractNumber: val[0]?.contractNumber
          })
        }
        queryParams={contractQueryParams}
        renderTopLvl={false}
        isInline
      />,

      <ListDivider key="divider-0" />
    );
  }

  if (customerId) {
    filterInputs.push(
      <Label key="label-seen">{getTranslation('entity_attachment.seen')}</Label>,
      <RadioInput
        key="radio-yes"
        onChange={(option) => setQueryParam({ viewed: option === 'yes' ? true : false })}
        value="yes"
        name="seen"
        checked={queryParams.viewed === true}
      >
        {getTranslation('general.yes')}
      </RadioInput>,
      <RadioInput
        key="radio-no"
        onChange={(option) => setQueryParam({ viewed: option === 'no' ? false : true })}
        value="no"
        name="seen"
        checked={queryParams.viewed === false}
      >
        {getTranslation('general.no')}
      </RadioInput>,

      <ListDivider key="divider-1" />
    );
  }

  filterInputs.push(
    <EnumFilterSelect
      id="attachmentVisibility"
      key="attachmentVisibility"
      title={getTranslation('entity_attachment.visibility')}
      options={getEnum<attachmentVisibility>('attachmentVisibility')}
      values={queryParams.visibilities || []}
      setValue={(visibilities) => setQueryParam({ visibilities })}
      multiSelect
    />,

    <ListDivider key="divider-2" />,

    <Label key="label-created-between">{getTranslation('entity_attachment.created_between')}</Label>,
    <DateRangePicker
      id="created-between"
      key="created-between"
      startDate={convertToMoment(queryParams.createdStartDateTime)}
      endDate={convertToMoment(queryParams.createdEndDateTime)}
      setDates={(dates) =>
        setQueryParam({
          createdStartDateTime: convertMomentToISOString(dates[0]) as string,
          createdEndDateTime: convertMomentToISOString(dates[1]) as string
        })
      }
      renderTopLvl={false}
    />
  );

  const filters = <FilterInputs filterInputs={filterInputs} removeAllFilters={resetAll} />;

  return {
    filters,
    filterTags
  };
}
