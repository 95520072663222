import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isOverdue } from 'utils/invoice';

import { navigate } from '@reach/router';
import { BillingRelationType } from '@zf/api-types/billing-relation';
import { BillingCompletenessInsightResponseType } from '@zf/api-types/billing/billing-completeness';
import { AssignInvoicesToOutgoingBankingTransactionRequestType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { exportStatus, invoiceStatus, paymentProcessStatus, pluginType, sentStatus } from '@zf/api-types/enums';
import {
  ApproveInvoiceType,
  DeleteOrCreditInvoiceType,
  InvoiceType,
  MarkAsPaidInvoiceType,
  MarkAsSentInvoiceType,
  SendInvoiceType
} from '@zf/api-types/invoice';
import { ContractType } from '@zf/api-types/master-data/contract';
import { BillingParametersType } from '@zf/api-types/parameters';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

import RootStore from '../../../app-context/stores';
import BaseStore from '../../../app-context/stores/BaseStore';
import AssignToCaseForm, { AssignToCaseFieldTypes } from '../forms/AssignToCaseForm';
import Invoice from '../models/InvoiceModel';
import InvoiceApiService from '../services/invoiceService';
import RelatedDetailsForm from '../src/detail/detail-page/logic/RelatedDetailsForm';

export enum invoiceActionPermissions {
  mayDelete = 'mayDelete',
  mayApprove = 'mayApprove',
  mayRegenerate = 'mayRegenerate',
  mayDownload = 'mayDownload',
  maySend = 'maySend',
  mayMarkAsSend = 'mayMarkAsSend',
  mayAssignToCase = 'mayAssignToCase',
  mayAssignToTransaction = 'mayAssignToTransaction',
  maySendPostal = 'maySendPostal',
  mayMarkAsNotExported = 'mayMarkAsNotExported'
}

export type LocalInvoiceStatus =
  | {
      status: sentStatus.skipped | sentStatus.sent;
      translation: string;
    }
  | {
      status: invoiceStatus;
      translation: string;
    };

export type LocalPaymentStatus = {
  status: string;
  translation: string;
};

export default class InvoiceStore extends BaseStore<InvoiceType> {
  public invoices: Invoice[];
  public invoiceApiService: InvoiceApiService;
  public rootStore: RootStore;
  public selectedInvoice_: Invoice | null;
  public selectedListPageInvoices: Invoice[];
  public pageActionPermissions: Record<invoiceActionPermissions, boolean>;

  // Contract related
  public contract: ContractType | undefined;
  public billingRelation: BillingRelationType | null | undefined;
  public billingInsights: BillingCompletenessInsightResponseType;

  // Cfg related
  public billingParameters: BillingParametersType | undefined;
  public organizationCfg: OrganizationConfigType | undefined;
  public enablePreview = false;

  // Forms
  public assignToCaseForm: AssignToCaseForm;
  public relatedDetailsForm: RelatedDetailsForm | undefined;

  constructor(rootStore: RootStore) {
    super();
    this.invoices = [];
    this.rootStore = rootStore;
    this.invoiceApiService = new InvoiceApiService(this, rootStore.applicationStore);
    this.setExistingEntity = this.setExistingInvoice;

    makeObservable(this, {
      invoices: observable,
      selectedInvoice_: observable,
      contract: observable,
      billingRelation: observable,
      billingInsights: observable,
      billingParameters: observable,
      organizationCfg: observable,
      assignToCaseForm: observable,
      relatedDetailsForm: observable,
      enablePreview: observable,

      addInvoice: action,
      setNewInvoice: action,
      setExistingInvoice: action,
      loadInvoiceDetailData: action,
      markAsSent: action,
      generateActionPermissions: action,
      setCfgRelatedData: action,
      setContractRelatedData: action,
      initAssignToCaseForm: action,
      generateTitle: action,
      initRelatedDetailsForm: action,

      // Utils
      getInvoiceStatus: action,
      getPaymentStatus: action,

      selectedInvoice: computed
    });
  }

  generateTitle = (invoice: InvoiceType) => {
    if (invoice.invoiceNum) {
      return `${this.rootStore.applicationStore.getTranslation('invoice.invoice')} - ${invoice.invoiceNum}`;
    } else {
      return `${this.rootStore.applicationStore.getTranslation('invoice.invoice')} - ${
        invoice.debtor.customerAccountNumber
      } - ${invoice.debtor.displayName}`;
    }
  };

  initRelatedDetailsForm = (invoice: InvoiceType) => {
    const { paymentDetails, advanceCalculationDetails } = invoice;

    this.relatedDetailsForm = new RelatedDetailsForm(
      {
        newPaymentMethod: paymentDetails.paymentMethod,
        collectionDate: paymentDetails.directDebitCollectionDate,
        newAdvanceAmount: advanceCalculationDetails?.newAdvanceAmountInclVAT || 0
      },
      this
    );
  };

  setCfgRelatedData = (
    billingParameters: BillingParametersType,
    enablePreview: boolean,
    organizationCfg: OrganizationConfigType
  ) => {
    this.billingParameters = billingParameters;
    this.organizationCfg = organizationCfg;
    this.enablePreview = enablePreview;
  };

  setContractRelatedData = (
    contract: ContractType,
    billingRelation: BillingRelationType | null,
    billingInsights: BillingCompletenessInsightResponseType
  ) => {
    this.contract = contract;
    this.billingRelation = billingRelation;
    this.billingInsights = billingInsights;
  };

  loadInvoiceDetailData = async (id: string) => {
    const { uiStore, configStore, contractStore, organisationStore } = this.rootStore;
    const { organization, tenant } = this.rootStore.applicationStore.tenantReducer;

    const invoice = await this.invoiceApiService.getInvoiceById(id);
    this.setNewInvoice(invoice);
    uiStore.setBrowserTitle(this.generateTitle(invoice));

    try {
      const cfgRes = await Promise.all([
        configStore.configService.getBillingParameters(),
        configStore.configService.getCommunicationParameters(),
        organisationStore.organisationService.getOrganisationConfig(
          organization?.organizationId || '',
          tenant?.id || ''
        )
      ]);

      this.setCfgRelatedData(cfgRes[0], cfgRes[1]?.enabled, cfgRes[2]);
    } catch (error) {
      throw error;
    }

    if (invoice.contractId) {
      const { getContractForId, getBillingRelation, billingCompleteness } = contractStore.contractApiService;

      const contract = await getContractForId(invoice.contractId);
      const billingRelation = await getBillingRelation(contract.id, contract.contractor.customerId);

      if (billingRelation) {
        const billingInsights = await billingCompleteness(billingRelation.id);

        this.setContractRelatedData(contract, billingRelation, billingInsights);
      }
    }
  };

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get selectedInvoice() {
    return this.selectedInvoice_ as Invoice;
  }

  generateActionPermissions = async () => {
    if (this.selectedInvoice_) {
      const invoice = this.selectedInvoice_.invoice;
      const configuredPlugins = await this.rootStore.integrationStore.configuredPluginsService.getConfiguredPlugins();
      const printingConfiguredPlugin = configuredPlugins.find(
        (e) => e.pluginType === pluginType.pingendocumentprinting
      );

      if (configuredPlugins)
        this.pageActionPermissions = {
          mayDelete: true,
          maySendPostal:
            invoice.supportsExternalPrinting &&
            invoice.status === invoiceStatus.generated &&
            invoice.sent !== sentStatus.printing &&
            printingConfiguredPlugin
              ? printingConfiguredPlugin.enabled
              : false,
          mayApprove: invoice.status === invoiceStatus.created,
          mayRegenerate:
            invoice.status === invoiceStatus.generated || invoice.status === invoiceStatus.generationfailed,
          mayDownload: invoice.status === invoiceStatus.generated,
          maySend: invoice.status === invoiceStatus.generated,
          mayMarkAsSend: invoice.status === invoiceStatus.generated && invoice.sent === sentStatus.notsent,
          mayAssignToCase:
            invoice.status !== invoiceStatus.created &&
            !!this.billingParameters &&
            this.billingParameters.enableInvoiceCollection &&
            invoice.remainingInvoiceAmount > 0 &&
            (invoice.collectionDetails === null || (!!invoice.collectionDetails && invoice.collectionDetails.closed)),
          mayAssignToTransaction:
            (invoice.status === invoiceStatus.approved ||
              invoice.status === invoiceStatus.generated ||
              invoice.status === invoiceStatus.settled) &&
            (invoice.paymentDetails.currentPaymentStatus === paymentProcessStatus.readyforpaymentcollection ||
              invoice.paymentDetails.currentPaymentStatus === paymentProcessStatus.paymentrejected),
          mayMarkAsNotExported: invoice.exportStatus === exportStatus.exported
        };
    }
  };

  setNewInvoice = (invoice: InvoiceType | null) => {
    if (invoice) {
      this.selectedInvoice_ = new Invoice(this.rootStore, invoice);
      this.generateActionPermissions();
    } else {
      this.selectedInvoice_ = null;
    }
  };

  setExistingInvoice = (invoice: InvoiceType | null) => {
    runInAction(() => {
      if (invoice && this.selectedInvoice_) {
        this.selectedInvoice_.invoice = invoice;
        this.generateActionPermissions();
      }
    });
  };

  addInvoice = (invoice: Invoice) => {
    this.invoices.push(invoice);
  };

  // Actions
  //////////

  markAsSent = async (markAsSentInvoice: MarkAsSentInvoiceType, isDetailPage = false) => {
    return this.executeAction(this.invoiceApiService.markAsSentInvoice(markAsSentInvoice), isDetailPage);
  };

  sendPostal = async (markAsSentInvoice: MarkAsSentInvoiceType, isDetailPage = false) => {
    return this.executeAction(this.invoiceApiService.sendInvoicePostal(markAsSentInvoice), isDetailPage);
  };
  sendInvoice = async (markAsSentInvoice: SendInvoiceType, isDetailPage = false) => {
    return this.executeAction(this.invoiceApiService.sendInvoice(markAsSentInvoice), isDetailPage);
  };

  regenerateInvoices = async (id: string, isDetailPage = false) => {
    return this.executeAction(this.invoiceApiService.regenerateInvoice(id), isDetailPage);
  };

  markAsPaid = async (value: MarkAsPaidInvoiceType, paidAmount: number, isDetailPage = false) => {
    return this.executeAction(this.invoiceApiService.markAsPaidInvoice(value, paidAmount), isDetailPage);
  };

  approveInvoice = async (value: ApproveInvoiceType, date: string, isDetailPage = false) => {
    return this.executeAction(this.invoiceApiService.approveInvoice(value, date), isDetailPage);
  };

  credit = async (invoice: DeleteOrCreditInvoiceType, isDetailPage = false) => {
    return this.executeAction(this.invoiceApiService.creditInvoice(invoice), isDetailPage);
  };

  assignToCase = async (value: InvoiceType, flowId: string, isDetailPage = false) => {
    await this.invoiceApiService.assignToCase(value, flowId);
    const result = await this.invoiceApiService.getInvoiceById(value.id);
    if (isDetailPage) {
      this.setExistingEntity(result);
    }
  };

  assignToOutgoingBankingTransaction = async (
    value: AssignInvoicesToOutgoingBankingTransactionRequestType,
    id: string | undefined,
    isDetailPage = false
  ) => {
    await this.rootStore.outgoingBankingTransactionsStore.outgoingBankingTransactionsService.assignToTransaction(value);
    if (id) {
      const result = await this.invoiceApiService.getInvoiceById(id);
      if (isDetailPage) {
        this.setExistingEntity(result);
      }
    }
  };

  deleteInvoice = async (invoice: DeleteOrCreditInvoiceType, isDetailPage = false) => {
    const result = await this.invoiceApiService.deleteInvoice(invoice);
    if (isDetailPage && this.selectedInvoice_) {
      if (this.selectedInvoice_.credit) {
        this.setExistingEntity(result);
      } else {
        navigate(`${this.rootStore.applicationStore.rootUrl}/invoices`);
      }
    }
    return result;
  };

  initAssignToCaseForm = (initialValues: AssignToCaseFieldTypes) => {
    this.assignToCaseForm = new AssignToCaseForm(this, initialValues);
  };

  // Utils
  getInvoiceStatus = (invoice: InvoiceType): LocalInvoiceStatus => {
    const { getEnumTranslation } = this.rootStore.applicationStore;

    if (invoice.sent === sentStatus.sent || invoice.sent === sentStatus.skipped) {
      return { status: invoice.sent, translation: getEnumTranslation('sentStatus', invoice.sent) };
    }

    return { status: invoice.status, translation: getEnumTranslation('invoiceStatus', invoice.status) };
  };

  getPaymentStatus = (invoice: InvoiceType): LocalPaymentStatus => {
    const { getTranslation, getEnumTranslation } = this.rootStore.applicationStore;

    const invoiceIsOverDue = isOverdue(invoice);

    if (invoice.status === invoiceStatus.created) {
      return { status: '', translation: '' };
    }

    if (invoiceIsOverDue) {
      return { status: 'overdue', translation: getTranslation('invoice.overdue') };
    }

    if (invoice.creditedByInvoiceId) {
      return { status: 'credited', translation: getTranslation('invoice.credited') };
    }

    return {
      status: invoice.paymentDetails.currentPaymentStatus,
      translation: getEnumTranslation('paymentProcessStatus', invoice.paymentDetails.currentPaymentStatus)
    };
  };
}
