import classNames from 'classnames';
import React from 'react';

import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import { Label } from '../Label';
import css from './barchart-card.module.scss';

type Props = {
  subject: string;
  title: string;
  children: React.ReactNode;
  height?: string;
  extraRight?: React.ReactNode;
  width?: '1' | '2' | '3' | '4';
  className?: string;
  style?: React.CSSProperties;
};

export default function BarChartCard(props: Props) {
  const { subject, className, height, children, extraRight, title, width = '1', style = {} } = props;

  return (
    <Card
      id={`bar-chart-${subject}`}
      className={classNames(css['bar-chart-card'], className)}
      width={width}
      style={style}
    >
      <div className={css['extra-right']}>{extraRight}</div>
      <CardBody className={css['cardbody']} style={height ? { height: height } : {}}>
        <Label>{title}</Label>
        {children}
      </CardBody>
    </Card>
  );
}
