import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { BaseButton } from '../';
import { Icon } from '../../Foundation';
import css from './tab-slider.module.scss';
import { TabSliderType } from './TabSlider';

export type SelectionTabItemType<Q> = {
  id: keyof Q;
  title: string;
  count?: number;
  icon?: string;
  color?: string;
  selectedColor?: string;
  disabled?: boolean;
};

type Props<Q> = {
  id: string;
  item: SelectionTabItemType<Q>;
  selectedTabItems: (keyof Q)[];
  type: TabSliderType;
  isMultiSelect: boolean;
  showCheckBoxes: boolean;
  selectedTabItem: keyof Q;
  textAlign?: 'left' | 'center';
  setSelectedItem: (itemId: keyof Q) => void;
  disabled?: boolean;
};

export default function Tab<Q>(props: Props<Q>) {
  const {
    id,
    item,
    type,
    showCheckBoxes,
    isMultiSelect,
    selectedTabItem,
    selectedTabItems,
    textAlign,
    setSelectedItem,
    disabled = false
  } = props;

  const isSelected = useMemo(() => {
    if (isMultiSelect) {
      return selectedTabItems.includes(item.id);
    }
    return item.id === selectedTabItem;
  }, [isMultiSelect, selectedTabItems, item.id, selectedTabItem]);

  const style = useMemo(() => {
    let styleObject: React.CSSProperties = {};

    if (isSelected) {
      styleObject = {
        color: item.selectedColor,
        borderBottomColor: item.selectedColor
      };
    }

    if (item.color) {
      styleObject.color = item.color;
    }

    return styleObject;
  }, [isSelected, item]);

  const handleClick = useCallback(() => setSelectedItem(item.id), [item.id, setSelectedItem]);
  return (
    <BaseButton
      id={id}
      className={classNames(css['tab-item'], css[`${type}-item`], {
        [css['selected']]: isSelected,
        [css['left-align']]: textAlign === 'left'
      })}
      style={style}
      onClick={handleClick}
      disabled={disabled}
    >
      {showCheckBoxes && (
        <input
          type="checkbox"
          id={`${id}-checkbox`}
          className={css['checkbox']}
          checked={isSelected}
          onChange={() => {
            // Do nothing
          }}
        />
      )}
      {item.icon && <Icon name={item.icon} />}
      <span>{typeof item.count !== 'undefined' && `${item.count} `}</span>
      {item.title}
    </BaseButton>
  );
}
