import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
// import { ZendeskAPI } from 'react-zendesk';

import { Router } from '@reach/router';

import { useAppContext } from '../app-context';
import { Loader } from '../components/Loader';
import { notify } from '../events/notification-events';
import { useStore } from '../hooks/useStore';
import DashboardRouter from './DashboardRouter';
import Organizations from './organizations/Organizations';
import UserManager from './organizations/UserManager';
import Route from './route';

const OrganizationRouter = () => {
  const { tenantReducer } = useAppContext();
  const { tenant, tenantAuth } = tenantReducer.state;

  const { applicationStore } = useStore();
  const { userStore, getTranslation } = applicationStore;
  const { userAuthService } = userStore;

  useEffect(() => {
    if (tenant) {
      userAuthService
        .getUserRoles(tenant.id)
        .then((tenantAuth) => tenantReducer.dispatch({ tenantAuth }))
        .catch((error) => {
          notify.error({
            content: getTranslation('tenant.get_roles_fail'),
            error
          });
        });
    }
  }, [tenant?.id]);

  if (!tenantAuth) return <Loader />;

  return (
    <Router>
      <Route Component={Organizations} default />
      <Route Component={UserManager} path="user-manager" />
      <Route Component={DashboardRouter} path=":org/*" />
    </Router>
  );
};

export default observer(OrganizationRouter);
