import React from 'react';

import MonthInput, { CompareMonthType } from '@zf/stella-react/src/atoms/Date/DateInput/month-input';

import RemoveIconButton from '../../Icon/RemoveIconButton';
import css from './compare-picker.module.scss';

type Props = {
  id: string;
  selectedMonth: CompareMonthType;
  setCompareMonths: (month: CompareMonthType) => void;
  removeComparisonMonth: () => void;
};

export default function CompareMonthPicker(props: Props) {
  const { id, selectedMonth, setCompareMonths, removeComparisonMonth } = props;

  return (
    <div>
      <MonthInput id={id} selectedMonth={selectedMonth} onSelect={setCompareMonths} />
      <RemoveIconButton id={`${id}-delete`} className={css['remove-icon']} onClick={removeComparisonMonth} />
    </div>
  );
}
