import axios, { Axios, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, Method, ResponseType } from 'axios';
import { loginRequest } from 'constants/authentication';
import { instance as MSAL } from 'PublicClientMsal';
import { METHODS, RequestResultType, TwoLvlRequestResultType } from 'utils/request';

import { AccountInfo, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';

import { TenantReducer } from './app-store-types';

interface IAxios {
  method: Method;
  url: string;
  customHeaders: any;
  responseType?: ResponseType;
  data?: object | null;
  hasOneDataLevel?: boolean;
  lang?: string;
  tenantReducer?: TenantReducer;
}

type ZFResponseType<T> = RequestResultType<T> | TwoLvlRequestResultType<T> | null;

export class API<T> extends Axios {
  private instance: AxiosInstance;
  private config: AxiosRequestConfig = {
    method: METHODS.GET
  };
  private headers: AxiosRequestHeaders;
  private tenantReducer?: TenantReducer;
  private hasOneDataLevel?: boolean;
  private account: AccountInfo | null = MSAL.getActiveAccount();
  private accessTokenRequest: SilentRequest = {
    scopes: loginRequest.scopes
  };

  private result: ZFResponseType<T> = null;

  constructor({ method, url, customHeaders, responseType, data, hasOneDataLevel, lang, tenantReducer }: IAxios) {
    super();

    if (responseType) {
      this.config.responseType = responseType;
    }

    this.config.method = method;
    this.config.url = url;
    this.config.data = data;
    this.hasOneDataLevel = hasOneDataLevel;
    this.tenantReducer = tenantReducer;
    this.result = null;
    this.instance = axios.create();

    this.headers = {
      Accept: 'application/json'
    };

    if (lang) {
      this.headers = {
        'Accept-Language': lang,
        ...this.headers
      };
    }

    // If we pass the tenant reducer we add the tenant & organisation headers
    if (this.tenantReducer) {
      if (this.tenantReducer.organization) {
        this.headers = {
          'zf-ouuid': this.tenantReducer.organization.organizationId || '',
          'zf-tuuid': this.tenantReducer.tenant?.id || '',
          ...this.headers
        };
      } else {
        this.headers = {
          'zf-tuuid': this.tenantReducer.tenant?.id || '',
          ...this.headers
        };
      }
    }

    /**
     * @description set custom headers
     */
    this.headers = { ...this.headers, ...customHeaders };

    /**
     * @description retrieve token silently
     */
    this.instance.interceptors.request.use(
      async (config) => {
        /**
         * @description set headers
         */
        config.headers = this.headers;

        if (this.account) {
          const accessTokenRequest = { ...this.accessTokenRequest, account: this.account };
          try {
            const accessTokenResponse = await MSAL.acquireTokenSilent(accessTokenRequest);
            if (config && config.headers) {
              config.headers['Authorization'] = `Bearer ${accessTokenResponse.accessToken}`;
            }
            return config;
          } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
            if (error instanceof InteractionRequiredAuthError) {
              MSAL.acquireTokenRedirect(accessTokenRequest);
            }
          }
        }
      },
      async (err) => {
        return Promise.reject(err);
      }
    );

    this.instance.interceptors.response.use(
      async (response) => response,
      async (error) => {
        // Only customize 400 errors, otherwise use default behavior
        if (error.response?.status !== 400) {
          return Promise.reject(error);
        }

        if (error.response && error.response.data) {
          if (error.response.data instanceof ArrayBuffer) {
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(error.response.data);

            const decoded_ = JSON.parse(enc.decode(arr));

            return Promise.reject({ data: decoded_ });
          } else {
            return Promise.reject(error.response);
          }
        }

        return Promise.reject({ key: 'request_could_not_be_sent' });
      }
    );
  }

  async execute(): Promise<RequestResultType<T>> {
    try {
      this.result = (await this.instance(this.config)) as unknown as ZFResponseType<T>;

      if (this.hasOneDataLevel) {
        const oneLvlResult = this.result;

        return {
          data: oneLvlResult?.data,
          headers: oneLvlResult?.headers
        } as RequestResultType<T>;
      } else {
        const twoLvlResult = this.result as TwoLvlRequestResultType<T>;

        return { data: twoLvlResult.data.data, headers: twoLvlResult.headers };
      }
    } catch (e) {
      throw e;
    }
  }
}
