import { observer } from 'mobx-react';
import React from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import ShadowContainer from '@zf/stella-react/src/atoms/Card/ShadowContainer';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { colors } from '@zf/utils/src/color';

import { SuspenseSpinner } from '../../../../components/suspense';
import { ICON_COLORS } from '../../../../constants/icons';
import { Paragraph } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import { ActivityStatusType } from './Activity';
import { ExistingEntitiesType, SelectedActivityType } from './activity-context/context';
import useSaveButton from './hooks/useSaveButton';
import SelectedWizard from './SelectedWizard';
import css from './right-part.module.scss';

type Props = {
  selectedActivity: SelectedActivityType;
  existingEntities: ExistingEntitiesType;
  task: MoveRequestType;
  getActivityStatus: (activity: SelectedActivityType) => ActivityStatusType;
};

const RightPart = (props: Props) => {
  const { selectedActivity, existingEntities, task, getActivityStatus } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const saveButton = useSaveButton(selectedActivity, existingEntities);

  const status = getActivityStatus(selectedActivity);

  const isEditable = status !== 'complete' && task.status !== moveRequestStatus.rejected;

  return (
    <ShadowContainer>
      <div className={css['header']}>
        <div>
          <Paragraph color={colors['silver-600']}>{getTranslation('tasks.default_task')}</Paragraph>
          <Heading headingType="h3">{selectedActivity ? getTranslation(`tasks.${selectedActivity}`) : ''}</Heading>
        </div>

        <div className={css['status-and-save-btn']}>
          <NewStatusBadge color={ICON_COLORS[status]} status_={getTranslation(`tasks.${status}`)} />
          {isEditable && saveButton}
        </div>
      </div>

      <HorizontalDivider />

      <SuspenseSpinner>
        <div className={css['wizard-wrapper']}>
          {!isEditable && <div className={css['overlay']} />}
          <div className={css['wizard']}>
            <SelectedWizard selectedActivity={selectedActivity} existingEntities={existingEntities} />
          </div>
        </div>
      </SuspenseSpinner>
    </ShadowContainer>
  );
};

export default observer(RightPart);
