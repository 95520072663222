import React from 'react';

import { UpdatePaymentTermType } from '@zf/api-types/product';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import PaymentTermsContent from './payment-terms-content';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';

type Props = {
  config: UseConfigReturnType<UpdatePaymentTermType[]>;
};

export default function PaymentTerms(props: Props) {
  const { config } = props;

  const { Provider, useTracked } = useCreateContext<UpdatePaymentTermType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <PaymentTermsContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
