import classNames from 'classnames';
import React from 'react';

import { Icon } from '@zf/stella-react/src/atoms/Icon';
import { List } from '@zf/stella-react/src/atoms/List';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { useAppContext } from '../../app-context/app-context';
import { BaseButton } from '../../design-system/Components';
import IconParagraph from '../Icon/IconParagraph';
import css from './filter-bar.module.scss';

type FilterBarProps = {
  filterInputs?: React.ReactNode;
  className?: string;
  removeAllFilters: () => void;
};

export default function FilterInputs(props: FilterBarProps) {
  const { filterInputs, className, removeAllFilters } = props;

  const { i18n } = useAppContext();

  return (
    <div>
      {filterInputs && (
        <TooltipTrigger
          tooltip={
            <>
              <List className={classNames(css['filter-inputs'], className)}>{filterInputs}</List>
              <BaseButton id="clear-all" key="clear-all" className={css['clear-all']} onClick={removeAllFilters}>
                <IconParagraph iconType="clear-filter">{i18n.getTranslation('filter_inputs.clear_all')}</IconParagraph>
              </BaseButton>
            </>
          }
          trigger="click"
          placement="bottom-start"
          renderTopLvl={false}
        >
          <Icon name="filter" className={css['filter-icon']} />
        </TooltipTrigger>
      )}
    </div>
  );
}
