import React from 'react';

import { navigate } from '@reach/router';
import { templateUsecase } from '@zf/api-types/enums';
import { CardHeader } from '@zf/stella-react/src/atoms/Card';

import Button from '../../../../../components/Button/Button';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  useCase: templateUsecase;
  children: React.ReactNode;
};

const CardHeaderUseCase = (props: Props) => {
  const { applicationStore, communicationStore } = useStore();
  const { useCase, children } = props;
  const { getTranslation } = applicationStore;
  const { selectedEntitySubjectSubtype, selectedEntitySubjectType } = communicationStore.templatesStore;

  const handleNavigate = () =>
    navigate(
      `${applicationStore.rootUrl}/configuration/${useCase}/${selectedEntitySubjectType}/${selectedEntitySubjectSubtype}`
    );

  return (
    <CardHeader
      extraRight={
        <Button id="translation-button" type="text" size="small" onClick={handleNavigate} icon="sign">
          {getTranslation('general.edit')}
        </Button>
      }
    >
      {children}
    </CardHeader>
  );
};

export default CardHeaderUseCase;
