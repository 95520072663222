import React from 'react';
import { getAllRequiredQuantitiesForCompleteness } from 'utils/completeness';

import { RequiredConsumptionQuantityType } from '@zf/api-types/billing/billing-completeness';
import { utilityType } from '@zf/api-types/enums';
import { formatDateForExport, formatPeriod } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import { RowTypeUnbilled } from '../../../cards/Unbilled/hooks/useUnbilledActions';
import ExportToExcel from './ExportToExcel';

type Props = {
  rows: RowTypeUnbilled[];
  selectedItems: string[];
};

const getMissingForUtilityTypes = (requiredQuantities: RequiredConsumptionQuantityType[]) => {
  const utilityTypes: utilityType[] = [];

  return requiredQuantities.reduce((acc: utilityType[], rc: RequiredConsumptionQuantityType) => {
    // Check if incomplete
    const isInComplete = rc.askingPeriods.some((ap) => {
      return !ap.complete;
    });

    // If incomplete, add an icon based on the combination's utility type to our result
    if (isInComplete && !utilityTypes.includes(rc.utilityType)) {
      utilityTypes.push(rc.utilityType);
      acc.push(rc.utilityType);
    }

    return acc;
  }, []);
};

export default function UnbilledExport(props: Props) {
  const { rows, selectedItems } = props;
  const { i18n, enumReducer } = useAppContext();

  const selectedRows = rows.filter((r) => {
    return selectedItems.includes(r.__id);
  });

  const getExportArray = () => {
    const exportArray = new Array(selectedRows.length + 1);

    exportArray[0] = [
      i18n.getTranslation('contracts.list.contract_number'),
      i18n.getTranslation('customer.customer'),
      i18n.getTranslation('invoice.invoicetype'),
      i18n.getTranslation('general.period'),
      i18n.getTranslation('invoice.invoice_due'),
      i18n.getTranslation('contracts.total_advances'),
      i18n.getTranslation('contracts.paid_advances'),
      i18n.getTranslation('analytics.missing_for'),
      i18n.getTranslation('general.status')
    ];

    for (let i = 1; i < exportArray.length; i++) {
      exportArray[i] = new Array(exportArray[0].length);
      const selectedRow = selectedRows[i - 1];
      const selectedEntity = selectedRow.__entity;

      //Op positie X zet Entity Y
      if (selectedRow && Object.keys(selectedRow).length > 1) {
        exportArray[i][0] = selectedEntity.contractNum;
        exportArray[i][1] = selectedEntity.debtor.displayName;
        exportArray[i][2] = selectedRow.invoiceType;
        exportArray[i][3] = formatPeriod(selectedEntity.periodStartDateTime, selectedEntity.periodEndDateTime);
        exportArray[i][4] = formatDateForExport(selectedEntity.periodEndDateTime);
        exportArray[i][5] = selectedEntity.advancePeriods.length;
        let advances = '';

        selectedEntity.advancePeriods.forEach((ap, index) => {
          if (ap.invoiceNum && ap.paid) {
            if (index !== 0) {
              advances += `,${ap.invoiceNum}`;
            } else {
              advances += ap.invoiceNum;
            }
          }
        });
        exportArray[i][6] = advances;

        const utilityTypes = getMissingForUtilityTypes(
          getAllRequiredQuantitiesForCompleteness(selectedEntity) as RequiredConsumptionQuantityType[]
        );
        const translatedUtilities = utilityTypes.map((ut) => enumReducer.getTranslation('utilityType', ut));
        exportArray[i][7] = translatedUtilities.join(',');

        exportArray[i][8] = enumReducer.getTranslation('billingCompletenessStatus', selectedEntity.status);
      }
    }

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      ws_name={i18n.getTranslation('analytics.unbilled')}
    />
  );
}
