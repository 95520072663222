import moment from 'moment';
import React from 'react';
import { Label as RechartLabel, XAxis, YAxis } from 'recharts';

import { aggregationFrequency } from '@zf/api-types/enums';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { nFormatter } from '@zf/utils/src/number';

import { useAppContext } from '../app-context';

export default function useAxis(
  groupByPeriod: aggregationFrequency,
  domainStart: number,
  domainEnd: number,
  selectedUnits: string[],
  XdataKey: string
) {
  const { i18n, enumReducer } = useAppContext();

  const xAxisTickFormatter = (value: string) => {
    switch (groupByPeriod) {
      case aggregationFrequency.yearly:
        return `${moment(value).year()}`;
      case aggregationFrequency.monthly:
        return moment(value).format('MMM/YYYY');
      case aggregationFrequency.none:
        return formatDate(value);
      case aggregationFrequency.daily:
        return moment(value).format('DD/MMM/YYYY');
    }
  };

  const getXAxis = () => {
    switch (groupByPeriod) {
      case aggregationFrequency.yearly:
      case aggregationFrequency.monthly:
        return (
          <XAxis
            key="year-month-xaxis"
            dataKey={XdataKey}
            interval={0}
            domain={[domainStart, parseInt(moment(domainEnd).add(1, 'year').format('x'))]}
            tickFormatter={xAxisTickFormatter}
            padding={{ left: 40, right: 40 }}
            stroke={colors['silver-600']}
            tickSize={8}
            height={45}
          />
        );
      case aggregationFrequency.daily:
        return (
          <XAxis
            key="day-month-xaxis"
            dataKey={XdataKey}
            interval={30}
            domain={[domainStart, domainEnd]}
            tickFormatter={xAxisTickFormatter}
            padding={{ left: 40, right: 40 }}
            stroke={colors['silver-600']}
            tickSize={8}
            height={45}
          />
        );
      case aggregationFrequency.none:
        return (
          <XAxis
            key="free-month-xaxis"
            dataKey={XdataKey}
            type="number"
            allowDuplicatedCategory={false}
            domain={[domainStart, domainEnd]}
            tickFormatter={xAxisTickFormatter}
            padding={{ left: 40, right: 40 }}
            stroke={colors['silver-600']}
            tickSize={8}
            height={45}
          />
        );
    }
  };

  const yAxisTickFormatter = (value: number) => {
    return nFormatter(value, i18n.culture);
  };

  const getYAxis = () => {
    const yAxises: Array<React.ReactElement> = [];

    if (groupByPeriod !== aggregationFrequency.none) {
      yAxises.push(
        <YAxis width={40} key={groupByPeriod} yAxisId={groupByPeriod} tickSize={0} axisLine={false}>
          <RechartLabel />
        </YAxis>
      );
    } else {
      let axis: React.ReactElement;

      selectedUnits.forEach((uom) => {
        axis = (
          <YAxis
            width={100}
            key={uom}
            yAxisId={uom}
            tickFormatter={yAxisTickFormatter}
            tickSize={0}
            axisLine={false}
            label={{
              value: enumReducer.getTranslation('unitOfMeasure', uom),
              position: 'insideLeft',
              angle: -90,
              dx: 7,
              style: {
                color: colors['silver-700'],
                textAnchor: 'middle',
                fontWeight: 'bold'
              }
            }}
          />
        );

        yAxises.push(axis);
      });

      return yAxises;
    }
  };

  return [getXAxis(), getYAxis()];
}
