import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { Wizard, WizardNav } from '@zf/stella-react/src/atoms/Wizard';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import WizardContainer from '../../../components/Wizard/WizardContainer';
import InstallMeterWizard, { STEP_NAMES } from './InstallMeterWizard';

type Props = {
  meter?: MeterType;
  location?: ServiceLocationType;
  propertyGroup?: PropertyGroupType;
};

const InstallMeter = (props: Props) => {
  const { location, meter, propertyGroup } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('meter.install_meter'));

  const [focusedStep, setFocusedStep] = React.useState(STEP_NAMES[0]);
  const [feedback, setFeedback] = React.useState<string[][]>([]);
  // used for showing the extra steps on the right
  const [createNewMeter, setCreateNewMeter] = React.useState(false);
  const [createNewMeasurement, setCreateNewMeasurement] = React.useState(false);

  const meterSteps = createNewMeter
    ? [
        {
          header: getTranslation('install_meter.steps.meter_essentials'),
          name: STEP_NAMES[2],
          feedback: feedback[12],
          subStep: true
        },
        {
          header: getTranslation('install_meter.steps.meter_channels'),
          name: STEP_NAMES[3],
          feedback: feedback[13],
          subStep: true
        },
        {
          header: getTranslation('errors.more_problems'),
          name: STEP_NAMES[4],
          feedback: feedback[14],
          error: true,
          subStep: true
        }
      ]
    : [];

  const measurementSteps = createNewMeasurement
    ? [
        {
          header: createNewMeter
            ? getTranslation('install_meter.steps.add_measurement')
            : getTranslation('install_meter.steps.add_measurement_existing_m'),
          name: STEP_NAMES[5],
          feedback: feedback[2],
          subStep: true
        },
        {
          header: getTranslation('errors.more_problems'),
          name: STEP_NAMES[6],
          feedback: feedback[7],
          error: true,
          subStep: true
        }
      ]
    : [];

  return (
    <WizardContainer icon="meter-add" text={getTranslation('install_meter.wizard.install')}>
      <Wizard>
        <InstallMeterWizard
          feedback={feedback}
          propertyGroup={propertyGroup} // Going there from property
          location={location} // Going there from service location
          meter={meter} // Going there from meter
          createNewMeter={createNewMeter}
          onFocusStep={setFocusedStep}
          setFeedback={setFeedback}
          setCreateNewMeter={setCreateNewMeter}
          setCreateNewMeasurement={setCreateNewMeasurement}
        />
      </Wizard>
      <WizardNav
        steps={[
          {
            header: getTranslation('install_meter.steps.location'),
            name: STEP_NAMES[0],
            feedback: feedback[0]
          },
          {
            header: getTranslation('install_meter.steps.select_meter'),
            name: STEP_NAMES[1],
            feedback: feedback[1],
            subSteps: [STEP_NAMES[2], STEP_NAMES[3], STEP_NAMES[4]]
          },
          ...meterSteps,
          ...measurementSteps,
          {
            header: getTranslation('errors.more_problems'),
            name: STEP_NAMES[7],
            feedback: feedback[7],
            error: true
          }
        ]}
        activeStep={focusedStep}
        onNavigate={setFocusedStep}
      />
    </WizardContainer>
  );
};

export default observer(InstallMeter);
