import React from 'react';

import { CommentType } from '@zf/api-types/comment';
import { entitySubjectType } from '@zf/api-types/enums';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../hooks/useStore';
import CommentCardBody from './CommentCardBody';

type Props = {
  subjectType: entitySubjectType;
  subjectId: string;
  updateInterval?: number;
};

export type StateType = {
  comments: CommentType[];
  replyText: string;
};

export default function CommentCard(props: Props) {
  const { applicationStore } = useStore();

  return (
    <Card id="comment-card" className='card' role='dialog'>
      <CardHeader>{applicationStore.getTranslation('comments.comments')}</CardHeader>
      <CommentCardBody {...props} />
    </Card>
  );
}
