import classNames from 'classnames';
import React from 'react';

import { colors } from '@zf/utils/src/color';

import { Paragraph } from '../Paragraph';
import css from './card-empty-body.module.scss';

type Props = {
  className?: string;
  image?: React.ReactNode;
  icon?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  minHeight?: boolean;
  error?: boolean;
};

export default function CardEmptyBody(props: Props) {
  const { className, image, icon, title, children, error = false, minHeight = true } = props;

  return (
    <div className={classNames(css['wrapper'], { [css['min-height']]: minHeight, [css['error']]: error }, className)}>
      <div className={classNames(css['emptycard-container'], { [css['with-padding']]: !minHeight })}>
        <div className={classNames(css['emptycard-image'])}>{image || icon}</div>
        <div className={classNames(css['emptycard-title'])}>
          <Paragraph color={colors['silver-700']} bold>
            {title}
          </Paragraph>
        </div>
        <div className={classNames(css['emptycard-description'])}>
          {typeof children !== 'string' ? children : <Paragraph color={colors['silver-600']}>{children}</Paragraph>}
        </div>
      </div>
    </div>
  );
}
