import { action, makeObservable } from 'mobx';

export default class BaseStore<T>{
  public setExistingEntity: (entity: T) => void;

  constructor() {
    makeObservable(this, {
      executeAction: action
    });
  }

  async executeAction(actionResult: Promise<T>, isDetailPageAction: boolean) {
    const result = await actionResult;

    if (isDetailPageAction) {
      this.setExistingEntity(result);
    }
    return result;
  }
}
