import React from 'react';

import { GeneratedColorAvatar } from '.';

type Props = {
  children: string;
  size?: string;
};

export default function OrgAvatar(props: Props) {
  const { children, size = 'large' } = props;

  return <GeneratedColorAvatar size={size}>{children}</GeneratedColorAvatar>;
}
