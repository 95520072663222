import { entitySubjectType } from '@zf/api-types/enums';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { BreadCrumb, Crumb } from '../BreadCrumb';
import { ErrorBoundary } from '../Error';
import { CrumbHeader } from '../Header';
import { SuspenseSpinner } from '../suspense';
import css from './list-page.module.scss';

type Props = {
  entitySubjectType: entitySubjectType;
  topBar: ReactNode;
  list: ReactNode;
  subjectType: string;
  infoGrid?: ReactNode;
  isEmbedded?: boolean;
};

export default function ListPage(props: Props) {
  const { entitySubjectType, topBar, list, subjectType, infoGrid, isEmbedded = false } = props;

  return (
    <>
      {!isEmbedded && (
        <CrumbHeader
          breadcrumb={
            <BreadCrumb>
              <Crumb icon="list" text={subjectType} />
            </BreadCrumb>
          }
        />
      )}

      <div
        id={`${entitySubjectType}-list-page`}
        className={classNames(css[`${isEmbedded ? 'list-page-embedded' : 'list-page'}`])}
      >
        <div className={css['top-bar']}>
          <ErrorBoundary>
            <SuspenseSpinner>{topBar}</SuspenseSpinner>
          </ErrorBoundary>
        </div>

        {(!isEmbedded && infoGrid) || null}

        <div className={css['list']}>
          <ErrorBoundary>
            <SuspenseSpinner>{list}</SuspenseSpinner>
          </ErrorBoundary>
        </div>

        {(isEmbedded && infoGrid) || null}
      </div>
    </>
  );
}
