import { InfoBanner } from 'design-system/ComponentSets';
import { Spinner } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { meterStatus } from '@zf/api-types/enums';
import { ExternalChannelType } from '@zf/api-types/master-data/meter';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { groupBy } from '@zf/utils/src/array';

import css from './optional-measurement-section-body.module.scss';
import OptionalMeasurement from './OptionalMeasurement';

const OptionalMeasurementSectionBody = () => {
  const { applicationStore, meterStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getMeters } = meterStore.deviceService;
  const { locationSectionStore, regularMoveInWizardValues } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { selectedLocation } = locationSectionStore;
  const { values, setValue } = regularMoveInWizardValues;

  useEffect(() => {
    if (selectedLocation) {
      getMeters({
        sluuids: [selectedLocation.id],
        quickFilter: meterStatus.installed
      })
        .then((results) => {
          const initialMeasurements = results.map((meter) => {
            const groupedChannels = groupBy(meter.channels, 'utilityType');

            // Channels can have multiple versions (history) we only want the current version
            const mostRecentChannels = Object.keys(groupedChannels).reduce((acc: ExternalChannelType[], key) => {
              const arr = groupedChannels[key];
              acc.push(arr[arr.length - 1]);
              return acc;
            }, []);

            return {
              meterId: meter.id,
              optionalChannels: mostRecentChannels.map((chann) => {
                return {
                  hour: '0',
                  minute: '0',
                  value: undefined,
                  uom: chann.unitOfMeasure,
                  dataFrequency: chann.dataFrequency,
                  externalChannelIdentifier: chann.externalIdentifier,
                  description: chann.description
                };
              })
            };
          });

          setValue({ optionalMeasurements: initialMeasurements, meters: results });
        })
        .catch((error) => {
          notify.error({
            content: getTranslation('contracts.wizard.get_meters_fail'),
            error
          });
        });
    }
  }, [selectedLocation]);

  if (!values.meters) {
    return <Spinner centered />;
  }

  if (values.meters.length === 0) {
    return <InfoBanner info={getTranslation('contracts.wizard.no_meters_found')} color="orange" roundedCorners />;
  }

  return (
    <>
      <CheckBox
        id="contracts.add-msmt"
        className={css['checkbox']}
        checked={values.hasOptionalMeasurement}
        onChange={(hasOptionalMeasurement) => setValue({ hasOptionalMeasurement })}
      >
        {getTranslation('contracts.wizard.opt_measurement')}
      </CheckBox>

      {values.hasOptionalMeasurement && (
        <>
          {values.optionalMeasurements.map((optionalMsmt, index) => {
            if (values.meters) {
              const matchingMeter = values.meters[index];

              return (
                <OptionalMeasurement
                  key={optionalMsmt.meterId}
                  index={index}
                  optionalMeasurement={optionalMsmt}
                  meter={matchingMeter}
                />
              );
            }

            return null;
          })}
        </>
      )}
    </>
  );
};

export default observer(OptionalMeasurementSectionBody);
