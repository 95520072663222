import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { observer } from 'mobx-react';
import React from 'react';
import Dropdown from '../../../../../components/Lang/Dropdown';
import { useStore } from '../../../../../hooks/useStore';
import css from '../../social-tariff.module.scss';
import { State } from '../create-social-tariff-dialog';

type Props = {
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

type YearType = {
  id: '2022' | '2023';
};

const ProtectedCustomerSection = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { state, setState } = props;

  const dropdownValues: DropdownValuesType<YearType>[] = [
    {
      text: '2022',
      value: {
        id: '2022'
      }
    },
    {
      text: '2023',
      value: {
        id: '2023'
      }
    }
  ];

  dropdownValues.reverse();
  return (
    <>
      <Label className={css['label-export-tariff-range']}>{getTranslation('country_specifics.choose_year')}</Label>
      <Dropdown
        id={`protected-customer-dropdown`}
        className={css['protected-customer-dropdown']}
        selectedValues={[state.year]}
        values={dropdownValues}
        onChange={(val) => setState({ year: val[0].id })}
      />
    </>
  );
};

export default observer(ProtectedCustomerSection);
