import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';

import css from './input-container.module.scss';

type Props = {
  children: ReactNode;
  grid?: boolean;
  style?: CSSProperties;
  className?: string;
  hasSections?: boolean;
  noDense?: boolean;
};

export default function InputContainer(props: Props) {
  const { children, grid = true, style, className, hasSections = false, noDense = false } = props;

  return (
    <div
      style={style}
      className={classNames(
        {
          [css['input-container-grid']]: grid,
          [css['input-container']]: !grid,
          [css['without-sections']]: !hasSections,
          [css['dense']]: !noDense
        },
        className
      )}
    >
      {children}
    </div>
  );
}
