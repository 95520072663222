import React from 'react';
import { DeleteIcon } from '../../../components/Icon';

import MoreActionsMenu from '../../../components/Menus/MoreActionsMenu';
import { Button } from '../../Components';
import css from './actions.module.scss';

export type ActionsProps = {
  id: string;
  addFunc?: Function;
  editFunc?: Function;
  deleteFunc?: Function;
  otherActions?: JSX.Element[];
};

export default function Actions(props: ActionsProps) {
  const { id, addFunc, editFunc, deleteFunc, otherActions } = props;

  return (
    <div className={css['actions']}>
      {addFunc && <Button id={`${id}-add`} type="text" size="small" icon="plus" onClick={addFunc} />}
      {editFunc && <Button id={`${id}-edit`} type="text" size="small" icon="sign" onClick={editFunc} />}
      {deleteFunc && (
        <DeleteIcon id={`${id}-delete`} tooltipFor="attributes-table" key={`${id}-delete`} onClick={deleteFunc} />
      )}
      {otherActions && otherActions.length > 0 && (
        <MoreActionsMenu id={`${id}-more-actions`} actions={otherActions} dotsOrientation="vertical" />
      )}
    </div>
  );
}
