import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import GenericDialog from '../../../../../components/units/Dialog/GenericDialog';
import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  startDate: string;
  endDate: string;
  selectedBillingItemId: string;
  selectedProductId: string;
  periodNodeId: string;
  initTariffs?: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

export default function DeleteTariffPeriodListItem(props: Props) {
  const { selectedBillingItemId, selectedProductId, periodNodeId } = props;

  const { applicationStore, productConfigStore } = useStore();
  const { productsStore } = productConfigStore;
  const { getTranslation } = applicationStore;
  const { deleteTariffPeriod, getTree } = productsStore;
  const { onSubmit, clickRef } = useDialog();

  return (
    <DropdownAction
      id="tariff-period.delete"
      icon="trashcan"
      dialogTitle={getTranslation('general.delete')}
      dialogContent={
        <GenericDialog
          ref={clickRef}
          info={getTranslation('billing_tariff.delete_tariff_period_info')}
          successMsg={getTranslation('billing_tariff.delete_tariff_success')}
          errorMsg={getTranslation('billing_tariff.delete_tariff_fail')}
          submitFunction={() =>
            deleteTariffPeriod(periodNodeId).then(() => {
              //reset the tree
              getTree(selectedBillingItemId, selectedProductId);
            })
          }
        />
      }
      dialogType="danger"
      onSubmit={onSubmit}
    />
  );
}
