import React, { useEffect } from 'react';

import { useAppContext } from '../../app-context';
import RouteWithHeader from '../../components/Header/RouteWithHeader';
import { OrgPicker } from '../../components/organization';
import css from './organizations.module.scss';

export default function Organizations() {
  const { tenantReducer } = useAppContext();

  useEffect(() => {
    if (tenantReducer.state.organization) {
      tenantReducer.dispatch({ organization: undefined });
    }
  }, []);

  return (
    <RouteWithHeader>
      <div className={css['org-picker-wrapper']}>
        <OrgPicker />
      </div>
    </RouteWithHeader>
  );
}
