import React from 'react';

import { Icon } from '@zf/heat-erp/src/components/Icon';
import { BaseButton } from '@zf/heat-erp/src/design-system/Components';
import useDebounce from '@zf/hooks/src/useDebounce';
import { colors } from '@zf/utils/src/color';
import StellaInputField from '@zf/stella-react/src/atoms/InputField/StellaInputField';
import { useStore } from '../../hooks/useStore';

export type SearchBarProps = {
  id: string;
  value?: string;
  placeholder?: string;
  className?: string;
  debounceTime?: number;
  style?: React.CSSProperties;
  error?: boolean;
  autoFocus?: boolean;
  hideLabel?: boolean;
  darkMode?: boolean;
  onChange: (value: string) => void;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: (step: string) => void;
  disabled?: boolean;
};

export default function SearchBar(props: SearchBarProps) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    id,
    className,
    value,
    style,
    debounceTime = 100,
    placeholder = getTranslation('general.search'),
    error = false,
    autoFocus = false,
    hideLabel = false,
    darkMode = false,
    onChange,
    onKeyDown,
    onClick,
    onFocus,
    disabled,
    ...otherProps
  } = props;

  const [faceValue, setFaceValue] = React.useState(value);

  React.useEffect(() => {
    setFaceValue(value);
  }, [value]);

  const setDebounceCallback = useDebounce(debounceTime);

  const onKeyPress = (searchString: string) => {
    setFaceValue(searchString);

    setDebounceCallback(() => {
      onChange(searchString);
    });
  };

  return (
    <StellaInputField
      {...otherProps}
      id={id}
      className={className}
      style={style}
      value={debounceTime ? faceValue : value}
      onChange={debounceTime ? onKeyPress : onChange}
      onKeyDown={onKeyDown}
      onClick={onClick}
      onFocus={onFocus}
      type="text"
      placeholder={placeholder}
      error={error}
      hideLabel={hideLabel}
      tabIndex={0}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      iconLeft={<Icon type="search" />}
      darkMode={darkMode}
      iconRight={
        <BaseButton
          id="clear-search"
          onClick={() => {
            setFaceValue('');
            onChange('');
          }}
        >
          <Icon
            type="cross"
            style={{ visibility: faceValue !== '' ? 'visible' : 'hidden' }}
            color={colors['silver-600']}
          />
        </BaseButton>
      }
    />
  );
}
