import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { TopUpType } from '@zf/api-types/metering/top-up';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';

import CreateRequestService from '../../../../components/units/List/logic/createRequestService';
import FilterStore from '../../../../components/units/List/logic/FilterStore';
import InfiniAPIService from '../../../../components/units/List/logic/infiniAPIService';
import PrepaymentDeviceStore from './PrepaymentDeviceStore';

export type BalanceQueryParams = {
  startDateTime?: string;
  endDateTime?: string;
};

export enum balanceDataType {
  topUp = 'top_up',
  balance = 'balance'
}

export type TopUptypeRowType = {
  __id: string;
  __entity: TopUpType;
  processedDateTimeOffset: string;
  origin: string;
  amountPaid: string;
};

export default class BalanceStore {
  public prepaymentDeviceStore: PrepaymentDeviceStore;

  public dataType: balanceDataType = balanceDataType.topUp;
  public chartData: ChartDataType[] | undefined;

  public filterStore: FilterStore<BalanceQueryParams>;
  public createRequestService: CreateRequestService;
  public infiniAPIService: InfiniAPIService<TopUpType, TopUptypeRowType> | undefined;

  constructor(prepaymentDeviceStore: PrepaymentDeviceStore) {
    this.prepaymentDeviceStore = prepaymentDeviceStore;

    this.filterStore = new FilterStore({
      startDateTime: undefined,
      endDateTime: undefined
    } as BalanceQueryParams);
    this.createRequestService = new CreateRequestService();

    makeObservable(this, {
      filterStore: observable,
      createRequestService: observable,
      infiniAPIService: observable,

      dataType: observable,
      chartData: observable,

      setDataType: action,
      setChartData: action,
      setInfiniAPIService: action,
      loadTopUpTable: action,
      loadBalanceGraph: action,
      resetStore: action
    });
  }

  setDataType = (type: balanceDataType) => {
    this.dataType = type;
  };

  setChartData = (data: ChartDataType[] | undefined) => {
    this.chartData = data;
  };

  setInfiniAPIService = (newInfiniAPIService: InfiniAPIService<TopUpType, TopUptypeRowType>) => {
    this.infiniAPIService = newInfiniAPIService;
  };

  loadTopUpTable = async (processRecord: (val: TopUpType) => TopUptypeRowType, deviceId: string) => {
    const request = this.createRequestService.createRequest({
      endpoint: '/me/TopUps',
      query: {
        meterId: deviceId,
        ...this.filterStore.queryParams
      }
    });

    if (!this.infiniAPIService) {
      const infiniAPIService = new InfiniAPIService(this.prepaymentDeviceStore.rootStore, processRecord);
      this.setInfiniAPIService(infiniAPIService);
    }

    await this.infiniAPIService?.fetchRows(request);
  };

  loadBalanceGraph = async (channelId: string) => {
    const measurements = await this.prepaymentDeviceStore.measurementService.getFlatMeasurements(channelId, {
      ...this.filterStore.queryParams
    });

    this.setChartData(
      measurements.map((msmt) => {
        return {
          value: msmt.value,
          time: parseInt(moment(msmt.endDateTime).format('x'))
        };
      })
    );
  };

  resetStore = () => {
    this.filterStore.resetAll();
    this.createRequestService.resetStore();
    this.infiniAPIService = undefined;
    this.dataType = balanceDataType.topUp;
  };
}
