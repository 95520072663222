import React, { CSSProperties, memo, useMemo } from 'react';
import { Legend, LineChart, ResponsiveContainer } from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';

import css from './chart-style.module.scss';
import CustomLegend from './CustomLegend';
import { IntervalType, SharedChartProps } from './types';
import { ZFChartLayout } from './ZFChartLayout';

type Props = SharedChartProps & {
  lines: JSX.Element[];
  interval?: IntervalType;
  height?: string;
  showLegend?: boolean;
  yDomain?: AxisDomain | undefined;
  xAxisTickFormatter?: (value: string) => string;
  locale: string;
};

const ZFLineChart = memo((props: Props) => {
  const {
    chartData,
    subject,
    interval,
    lines,
    XdataKey,
    height = '35rem',
    yDomain,
    locale,
    xAxisTickFormatter,
    showLegend = true
  } = props;

  const layout = ZFChartLayout(subject, XdataKey, locale, interval, yDomain, false, xAxisTickFormatter);

  const wrapperStyle: CSSProperties = useMemo(() => {
    return { height };
  }, [height]);

  const lineChartMargin = useMemo(() => {
    return { top: 24, left: -16, right: 40, bottom: 0 };
  }, []);

  const legendMargin = useMemo(() => {
    return { top: -16, left: 40 };
  }, []);

  return (
    <div style={wrapperStyle}>
      <ResponsiveContainer className={css['chart']}>
        <LineChart data={chartData} margin={lineChartMargin}>
          {layout}
          {lines}
          {showLegend && <Legend content={<CustomLegend locale={locale} />} wrapperStyle={legendMargin} />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});

export default ZFLineChart;
