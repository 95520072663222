import {
  EntityAttachment,
  EntityAttachmentFile,
  MultipleFileType,
  ProductReferenceParametersType,
  PropertyGroupReferenceParametersType,
  SingleFileType
} from '@zf/api-types/entity-attachments/entity-attachments';
import { entitySubjectType } from '@zf/api-types/enums';
import { action, makeObservable, observable } from 'mobx';
import FilesStore from './FilesStore';

class EntityAttachmentBase {
  public entityId?: string;
  public fileStore?: FilesStore<EntityAttachment>;
  constructor() {
    makeObservable(this, {
      fileStore: observable,
      entityId: observable,
      setFileStore: action,
      setProductId: action,
      setPermissions: action
    });
  }

  setPermissions = () => {
    if (this.fileStore?.createRequestService && this.fileStore.infiniAPIService) {
      const { selectedIds } = this.fileStore.createRequestService;
      const { rows } = this.fileStore.infiniAPIService;

      this.fileStore.actionPermissions = {
        mayAddEntityAttachment: true,
        mayEditEntityAttachment: selectedIds.length === 1 && this.fileStore.portalEnabled,
        mayDeleteEntityAttachment:
          selectedIds.length > 0 &&
          selectedIds.every((id) => {
            const match = rows.find((r) => r.__id === id);
            const entity = match?.__entity as unknown as EntityAttachment;
            return !!match && entity.origin === this.fileStore?.selectedEntitySubjectType;
          }),
        mayDownloadEntityAttachment: selectedIds.length > 0
      };
    }
  };

  setFileStore = (fileStore?: FilesStore<EntityAttachment>) => {
    this.fileStore = fileStore;
  };

  setProductId = (val: EntityAttachment) => {
    if (val.origin === entitySubjectType.product) {
      const params = val.parameters as ProductReferenceParametersType;
      this.entityId = params.product.productId;
    } else if (val.origin === entitySubjectType.entityattachmentgroup) {
      const params = val.parameters as PropertyGroupReferenceParametersType;
      this.entityId = params.entitySubjectId;
    }
  };

  listFiles = (entityAttachment?: EntityAttachment): EntityAttachmentFile[] => {
    let files: EntityAttachmentFile[] = [];
    if (entityAttachment) {
      if (
        entityAttachment.origin === entitySubjectType.product ||
        entityAttachment.origin === entitySubjectType.entityattachmentgroup
      ) {
        const params = entityAttachment.parameters as MultipleFileType;
        files = params.files;
      } else {
        const params = entityAttachment.parameters as SingleFileType;
        files.push(params.file);
      }

      this.setProductId(entityAttachment);
    }

    return files;
  };
}

export default EntityAttachmentBase;
