import React from 'react';
import moment from 'moment';

const useLocalRefresh = () => {
  const [timeStamp, setTimeStamp] = React.useState('');

  const refresh = React.useCallback(() => {
    setTimeStamp(moment().toISOString());
  }, []);

  return { refresh, timeStamp };
};

export default useLocalRefresh;
