import React from 'react';
import classNames from 'classnames';

import css from './input-container.module.scss';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

export default function InputSection(props: Props) {
  const { children, style, className } = props;

  return (
    <div style={style} className={classNames(css['input-section'], className)}>
      {children}
    </div>
  );
}
