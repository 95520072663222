import React from 'react';

import { MeterRowType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import MeasurementDialogContent from '../../../actions/meters/add-measurement/measurement-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { SuspenseSpinner } from '../../suspense';

export default function AddMeasurementListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('meter');
  const selectedRows: MeterRowType[] = myCurrent.listPage.selectedRows;
  const { refresh } = useRefresh();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled = selectedRows.find((meter) => !meter || (meter && meter.__meterStatus === 'removed')) !== undefined;

  const actionText =
    selectedRows.length !== 1
      ? i18n.getTranslation('actions.add_measurements')
      : i18n.getTranslation('actions.add_measurement');

  return (
    <DropdownAction
      id="meter.add_measurement"
      icon="measurement-add"
      dialogTitle={actionText}
      dialogContent={
        <SuspenseSpinner size="xsmall">
          <MeasurementDialogContent
            onComplete={refresh}
            selectedRows={selectedRows}
            ref={clickRef}
            validationRef={validationRef}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      disabled={disabled}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.add_measurement')}
    />
  );
}
