import React from 'react';

import { ActivityProvider } from './detail/activity-context/context';
import Task from './Task';

type Props = {
  urlParams: { [key: string]: string };
  location: Location;
  navigate: (url: string) => void;
};

export default function TaskWrapper(props: Props) {
  return (
    <ActivityProvider>
      <Task {...props} />
    </ActivityProvider>
  );
}
