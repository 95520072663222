import React from 'react';

import { incrementationType } from '@zf/api-types/enums';
import { MeterType } from '@zf/api-types/master-data/meter';

import { OutputCtxState, OutputType, useTracked } from '../context/output-context';

export default function useOutput(timeStamp: string, meter: MeterType) {
  const [state, dispatch] = useTracked();

  const setState = (newState: Partial<OutputCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const setOutputType = (outputType: OutputType) => {
    setState({ outputType: outputType });
  };

  const changeViewMode = () => {
    if (state.viewMode === 'graph') {
      // Compare mode is graph only
      // Inherit "measurements/consumption-mode" from graph view
      setState({
        viewMode: 'list',
        isCompareMode: false,
        outputType: state.graphHeaderSelection[0]
      });
    } else {
      // Inherit "measurements/consumption-mode" from list view
      setState({ viewMode: 'graph', graphHeaderSelection: [state.outputType] });
    }
  };

  React.useEffect(() => {
    const channels = meter.channels;

    // Split out channels & select first channel by default in table & graph
    if (channels && channels.length > 0) {
      const consumptionChanns = channels.filter((c) => {
        return c.incrementationType !== incrementationType.na;
      });

      const selectedListModeChannel = channels[0].externalIdentifier;

      if (typeof timeStamp !== 'undefined') {
        setState({
          timeStamp: timeStamp,
          channels: channels,
          selectedListModeChannel: selectedListModeChannel,
          graphHeaderSelection: ['measurements'],
          consumptionChannels: [...consumptionChanns],
          selectedGraphChannels: [selectedListModeChannel]
        });
      } else {
        setState({
          channels: channels,
          selectedListModeChannel: selectedListModeChannel,
          graphHeaderSelection: ['measurements'],
          consumptionChannels: [...consumptionChanns],
          selectedGraphChannels: [selectedListModeChannel]
        });
      }
    }
  }, [meter.id]);

  return {
    viewMode: state.viewMode,
    outputType: state.outputType,
    selectedListModeChannel: state.selectedListModeChannel,
    channels: state.channels,
    consumptionChannels: state.consumptionChannels,
    isCompareMode: state.isCompareMode,
    timeStamp: timeStamp,
    setState,
    setOutputType,
    changeViewMode
  };
}
