import { useAppContext } from 'app-context';
import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import CommentCard from '../../../../../cards/Comments/CommentCard';
import FeedCard from '../../../../../cards/Feeds/FeedCard';
import IntegrationLoggingCard from '../../../../../cards/Integration/IntegrationLoggingCard';
import { useStore } from '../../../../../hooks/useStore';
import css from './invoice-detail.module.scss';
import InvoiceDetailsCard from './ui/cards/InvoiceDetailsCard';
import RelatedDetailsCard from './ui/cards/related-details/RelatedDetailsCard';
import InvoicePreview from './ui/preview/InvoicePreview';

const InvoiceDetail = () => {
  const { authReducer } = useAppContext();
  const { isSuperUser } = authReducer;
  const { invoiceStore } = useStore();
  const { invoice } = invoiceStore.selectedInvoice;

  return (
    <>
      <FlexElements className={css['wrapper']} gap="32">
        <InvoicePreview />

        <FlexElements className={css['cards']} flexDirection="column" gap="32">
          <InvoiceDetailsCard invoice={invoice} />
          <RelatedDetailsCard invoice={invoice} />
          <FeedCard subjectType={entitySubjectType.invoice} subjectId={invoice.id} />
          <CommentCard subjectType={entitySubjectType.invoice} subjectId={invoice.id} />
        </FlexElements>
      </FlexElements>

      {isSuperUser() && (
        <div className={css['integration-logging']}>
          <IntegrationLoggingCard subjectId={invoice.id} subjectType="Invoice" />
        </div>
      )}
    </>
  );
};

export default observer(InvoiceDetail);
