import BaseFormVjf, { Fields } from '../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../events/notification-events';
import ContractStore from '../../../app-context/stores/master-data/contracts/detail-page/ContractStore';

export enum contractDetailFieldTypes {
  externalReferenceId = 'externalReferenceId',
  paymentTerm = 'paymentTerm'
}

export type ContractDetailFieldTypes = {
  externalReferenceId: string;
  paymentTerm: string;
};

class ContractDetailsForm extends BaseFormVjf<ContractDetailFieldTypes> {
  public contractStore: ContractStore;
  constructor(values: ContractDetailFieldTypes, contractStore: ContractStore) {
    super(values);
    this.contractStore = contractStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: contractDetailFieldTypes.externalReferenceId,
          label: 'external reference id'
        },
        {
          name: contractDetailFieldTypes.paymentTerm,
          label: 'Payment term'
        }
      ]
    };
  }

  async save() {
    try {
      await this.contractStore.updateContractDetails(this._get('externalReferenceId'), this._get('paymentTerm'));

      notify.success({
        content: this.contractStore.rootStore.applicationStore.getTranslation('contracts.term_update_success')
      });
      //backup
      this.contractStore.setContracDetailsForm(this._values);
    } catch (e) {
      notify.error({
        content: this.contractStore.rootStore.applicationStore.getTranslation('contracts.term_update_failed'),
        error: e
      });
    }
  }
}

export default ContractDetailsForm;
