import { observer } from 'mobx-react';
import React from 'react';

import { contractStatus } from '@zf/api-types/enums';
import CardSpinner from '@zf/stella-react/src/atoms/Spinner/CardSpinner';

import { useStore } from '../../../../../../hooks/useStore';
import BillingDetailsNoRelation from '../components/BillingDetailsNoRelation';
import BillingDetailsWRelation from '../components/BillingDetailsWRelation';

type Props = {
  setActiveTab: (tabId: string) => void;
};

const BillingDetailsCard = (props: Props) => {
  const { setActiveTab } = props;
  const { contractStore, applicationStore } = useStore();
  const contract = contractStore.selectedContract.contract;
  const billingRelation = contractStore.billingRelation_;
  const { billingInsights } = contractStore;

  if (billingRelation && billingInsights) {
    return <BillingDetailsWRelation billingInsights={billingInsights} setActiveTab={setActiveTab} />;
  } else if (
    contract.currentContractStatus === contractStatus.draft ||
    contract.currentContractStatus === contractStatus.cancelled
  ) {
    return <BillingDetailsNoRelation />;
  }

  return <CardSpinner title={applicationStore.getTranslation('contracts.billing_details')} />;
};

export default observer(BillingDetailsCard);
