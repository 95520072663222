import React from 'react';

import { CollectionCaseType } from '@zf/api-types/collection-case';
import useDialog from '@zf/hooks/src/useDialog';

import ProcessCollectionCaseDialog from '../../../actions/collection-cases/process-collection-case-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { SuspenseSpinner } from '../../suspense';

type Props = {
  collectionCase: CollectionCaseType;
  refresh: () => void;
};

export default function ProcessCollectionCaseListItem(props: Props) {
  const { collectionCase, refresh } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="collection_case.process"
      icon="process"
      dialogTitle={i18n.getTranslation('actions.collection_case.process')}
      dialogContent={
        <SuspenseSpinner size="xsmall">
          <ProcessCollectionCaseDialog
            collectionCase={collectionCase}
            ref={clickRef}
            validationRef={validationRef}
            onComplete={refresh}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.collection_case.process')}
    />
  );
}
