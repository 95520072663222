import { Info } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useReducer } from 'react';

import { utilityType } from '@zf/api-types/enums';
import { ConsumerGroupType } from '@zf/api-types/forecasting';
import { RequiredEstimationsType } from '@zf/api-types/product';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { colors } from '@zf/utils/src/color';

import css from './required-estimation.module.scss';
import RequiredEstimationsSectionBody from './RequiredEstimationsSectionBody';

type Props = {
  consumerGroups: ConsumerGroupType[];
};

type State = {
  utilityTypes: utilityType[] | undefined;
  requiredEstimations: RequiredEstimationsType[] | undefined;
};

const RequiredEstimationsSection = (props: Props) => {
  const { consumerGroups } = props;
  const { applicationStore, contractStore, productConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getRequiredEstimationsForProduct } = productConfigStore.productConfigService;
  const { regularMoveInWizardValues, locationSectionStore } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { productId } = regularMoveInWizardValues.values;
  const { selectedServices } = locationSectionStore;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    utilityTypes: undefined,
    requiredEstimations: undefined
  });

  //dear maintainer
  //
  //when we wrote this code, only we and god knew what it was
  //now only god knows!

  //so if you are trying to 'optimize' this (and failed)
  //please increment the following counter as a warning to the next guy

  //total_hours_wasted_here = 69 + 16

  useEffect(() => {
    if (productId) {
      getRequiredEstimationsForProduct(productId).then((requiredEstimations) => {
        if (requiredEstimations) {
          // We only need the requiredEstimations for the selected utilityTypes (move in wizard)
          const filteredEstimations = requiredEstimations.filter((re) => selectedServices.includes(re.utilityType));

          setState({
            utilityTypes: selectedServices,
            requiredEstimations: filteredEstimations
          });
        } else {
          setState({
            utilityTypes: [],
            requiredEstimations: []
          });
        }
      });
    }
  }, [selectedServices, productId]);

  if (!productId) {
    return <Info color={colors['silver-600']}>{getTranslation('contracts.wizard.consumption_groups_info')}</Info>;
  }

  if (!state.utilityTypes) return null;

  return (
    <div className={css['consumer-groups-section']}>
      {state.requiredEstimations?.length === 0 && (
        <Info color={colors['orange-600']}>{getTranslation('contracts.wizard.no_groups_found')}</Info>
      )}

      <RequiredEstimationsSectionBody
        requiredEstimations={state.requiredEstimations || []}
        consumerGroups={consumerGroups}
      />
    </div>
  );
};

export default observer(RequiredEstimationsSection);
