import { observer } from 'mobx-react';
import React from 'react';

import FilterInputs from '../../../../components/Filters/filter-inputs';
import FilterTags from '../../../../components/Filters/filter-tags';
import useProductsFilter from '../../hooks/use-products-filter';

type Props = {
  productName?: string;
};

const ProductFilters = (props: Props) => {
  const { filterInputs, tags, resetFilters } = useProductsFilter(props.productName);

  return (
    <>
      <FilterTags tags={tags} />
      <FilterInputs filterInputs={filterInputs} removeAllFilters={resetFilters} />
    </>
  );
};

export default observer(ProductFilters);
