import React from 'react';

import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';

export type Props = {
  format: Record<string, string | number | boolean | object>;
};

export default function FormatDetailGenerator(props: Props) {
  const { format } = props;
  const { i18n, enumReducer } = useAppContext();

  const checkParameterContent = (parameter: string | number | boolean) => {
    switch (typeof parameter) {
      case 'string':
        return parameter ? parameter : i18n.getTranslation('general.none');
      case 'boolean':
        return parameter ? <Icon type="check" color /> : <Icon type="cancel" color />;
      default:
        return parameter;
    }
  };

  return (
    <>
      {Object.keys(format).map((key, index) => {
        const formatKey = format[key];

        if (key !== 'type' && typeof formatKey !== 'object') {
          return (
            <React.Fragment key={`${key}-${index}`}>
              <CardItem width="6">
                <Label>{i18n.getTranslation(`parameters.${key}`)}</Label>
              </CardItem>
              <CardItem width="6">
                <Paragraph textAlign="right">
                  {key !== 'invoiceDateHandling'
                    ? checkParameterContent(formatKey)
                    : enumReducer.getTranslation('importContractInvoiceDateHandling', format[key] as string)}
                </Paragraph>
              </CardItem>
            </React.Fragment>
          );
        }

        return null;
      })}
    </>
  );
}
