import moment, { Moment } from 'moment';
import React from 'react';

import { isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { createStateReducer } from './stateReducer';

type State = {
  startDateTime: Moment;
  endDateTime: Moment;
};

type ValidationRef = {
  setIsError: (error: boolean) => void;
};

export default function useValidatePeriod(validationRef?: React.MutableRefObject<ValidationRef | undefined>) {
  const stateReducer = createStateReducer<State, State>();
  const [dates, setDates] = React.useReducer(stateReducer, {
    startDateTime: moment(MIN_DATE),
    endDateTime: moment(MAX_DATE)
  });

  const validate = () => {
    if (validationRef && validationRef.current) {
      validationRef.current.setIsError(!dates.startDateTime || isMinDate(dates.startDateTime) || !dates.endDateTime);
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(dates)]);

  return { dates, setDates };
}
