import { AddressType } from '@zf/api-types/general';
import { CoordinateType, GeoCoderResponseType } from '@zf/api-types/maps';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import { formatAddressForQuery } from '../../../../utils/address';
import { sendRequest } from '../../../../utils/request';

export function initMap(divContainer: string, coordinates: CoordinateType) {
  const url =
    'https://adtokens.azurewebsites.net/api/HttpTrigger1?code=dv9Xz4tZQthdufbocOV9RLaaUhQoegXQJSeQQckm6DZyG/1ymppSoQ==';

  // @ts-ignore
  const map = new atlas.Map(divContainer, {
    center: [coordinates.lon, coordinates.lat],
    zoom: 17,
    language: 'en-US',
    view: 'Auto',
    //Add your Azure Maps subscription keyclient ID to the map SDK.
    authOptions: {
      authType: 'subscriptionKey',
      subscriptionKey: 'uNL8-QUWLk-sNdiuP0mGU5IRiHcVKPjA79kxMZEdgrc',
      // @ts-ignore
      getToken: function (resolve) {
        fetch(url)
          .then(function (response) {
            return response.text();
          })
          .then(function (token) {
            resolve(token);
          });
      }
    }
  });

  /*Construct a style control*/
  // @ts-ignore
  const styleControl = new atlas.control.StyleControl({
    mapStyles: ['road', 'night']
  });

  /*Add the Style Control to the map*/
  map.controls.add(styleControl, {
    position: 'top-right'
  });

  return map;
}

// @ts-ignore
export const addMarkerToMap = (map, coords: CoordinateType, locationData: ServiceLocationType) => {
  //Wait until the map resources are ready.
  map.events.add('ready', function () {
    //Create a HTML marker and add it to the map.
    // @ts-ignore
    const marker = new atlas.HtmlMarker({
      htmlContent: '<div style="font-size: 3.2rem; color:#dc3545;" class=\'icon icon-marker-house\'></div>',
      position: [coords.lon, coords.lat],
      // @ts-ignore
      popup: new atlas.Popup({
        content: `<div style="padding:1.5rem">${locationData.address.localizedDisplay}</div>`,
        pixelOffset: [0, -30]
      })
    });

    map.markers.add(marker);

    //Add a click event to toggle the popup.
    map.events.add('click', marker, () => {
      marker.togglePopup();
    });
  });
};

export async function findCoordsForAddress(address: AddressType): Promise<CoordinateType> {
  const result = (
    await sendRequest<GeoCoderResponseType>(
      {
        request: {
          method: 'GET',
          endpoint: `https://atlas.microsoft.com/search/fuzzy/json?api-version=1.0&subscription-key=uNL8-QUWLk-sNdiuP0mGU5IRiHcVKPjA79kxMZEdgrc&query=${formatAddressForQuery(
            address
          )}`
        }
      },
      true
    )
  ).data.results[0];

  return result ? result.position : { lat: 0, lon: 0 };
}
