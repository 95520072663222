import { entitySubjectType } from '@zf/api-types/enums';
import React from 'react';

import { useAppContext } from '../../../app-context/app-context';
import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import useCustomEntityPropertyTypes from '../../../app-context/hooks/useCustomEntityPropertyTypes';
import ListPage from '../../../components/listPage';
import CollectionCaseFilterBar from './collection-case-filter-bar';
import CollectionCaseList from './collection-case-list';

type Props = {
  location: Location;
};

export default function CollectionCases(props: Props) {
  const { i18n } = useAppContext();
  useBrowserTitle(i18n.getTranslation('collection_case.collection_cases'));

  const { customEntityPropertyTypes, extraColumns, extraColumnTitles } = useCustomEntityPropertyTypes(
    entitySubjectType.collectioncase
  );

  if (!customEntityPropertyTypes) return null;

  return (
    <ListPage
      entitySubjectType={entitySubjectType.collectioncase}
      subjectType={i18n.getTranslation('nav.collection_cases')}
      topBar={<CollectionCaseFilterBar search={props.location.search} extraColumnTitles={extraColumnTitles} />}
      list={<CollectionCaseList customEntityPropertyTypes={customEntityPropertyTypes} extraColumns={extraColumns} />}
    />
  );
}
