import Interweave from 'interweave';
import React from 'react';

import { CoachMark } from '@zf/stella-react/src/atoms/CoachMark';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import css from './style.module.scss';

type Props = {
  title?: string;
  content?: Array<string>;
  warning?: boolean;
  className?: string;
  usePopup?: boolean;
};

export default function ConfigHelp(props: Props) {
  const { className, title, content, warning = false, usePopup } = props;

  return (
    <CoachMark warning={warning} className={className} usePopup={usePopup}>
      <Heading headingType="h3" className={css['header']}>
        <Interweave content={title} />
      </Heading>
      {content &&
        content.map((item: string, index: number) => {
          return (
            <Paragraph key={`${index}-paragraph`} className={css['paragraph']}>
              <Interweave content={item} />
            </Paragraph>
          );
        })}
    </CoachMark>
  );
}
