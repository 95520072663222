import React from 'react';

import { DataExportType } from '@zf/api-types/export-job';

import { useAppContext } from '../../../app-context';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import useExportJobsListPage from '../hooks/useExportJobsListPage';

type Props = {
  dataExports: Array<DataExportType>;
};
export default function ExportJobsList(props: Props) {
  const { dataExports } = props;
  const { i18n } = useAppContext();

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    handleSort,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    setStopIndex,
    setSelectedIds,
    localTimeStamp,
    timeStamp
  } = useExportJobsListPage(dataExports);

  return React.useMemo(() => {
    return (
      <DynamicVirtualTable
        id="export-jobs-table"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={[
          {
            width: 160,
            label: i18n.getTranslation('export_jobs.identification'),
            dataKey: 'identification',
            noClick: true
          },
          {
            width: 200,
            label: i18n.getTranslation('export_jobs.export_type'),
            dataKey: 'exportType'
          },
          {
            width: 220,
            label: i18n.getTranslation('general.name'),
            dataKey: 'dataExportName'
          },
          {
            width: 180,
            label: i18n.getTranslation('general.status'),
            dataKey: 'status'
          },
          {
            width: 200,
            label: i18n.getTranslation('export_jobs.communicationType'),
            dataKey: 'communicationType'
          },
          {
            width: 200,
            label: i18n.getTranslation('export_jobs.created'),
            dataKey: 'createdDateTime'
          }
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, i18n.lang]);
}
