import React from 'react';

import { AddMeasurementRequestType, MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';
import { MIN_DATE } from '@zf/utils/src/date';

import MeasurementDialogContent from '../../../actions/meters/add-measurement/measurement-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { SuspenseSpinner } from '../../suspense';

type Props = {
  meter?: MeterType;
  locationId?: string;
  onComplete?: () => void;
};

export default function AddMeasurementListItem(props: Props) {
  const { meter, locationId, onComplete } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const meterToHandle: AddMeasurementRequestType = {
    meter: meter ? meter : null,
    channelId: '',
    endDateTime: MIN_DATE,
    value: undefined,
    hour: '0',
    minute: '0'
  };

  return (
    <DropdownAction
      id="meter.add_measurement"
      icon="measurement-add"
      dialogTitle={i18n.getTranslation('actions.add_measurement')}
      dialogContent={
        <SuspenseSpinner size="xsmall">
          <MeasurementDialogContent
            locationId={locationId}
            meterToHandle={meterToHandle}
            ref={clickRef}
            onComplete={onComplete}
            validationRef={validationRef}
          />
        </SuspenseSpinner>
      }
      onSubmit={onSubmit}
      dialogLocation="right"
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.add_measurements')}
    />
  );
}
