import React from 'react';

import StellaColorInput, { StellaColorInputProps } from '@zf/stella-react/src/atoms/InputField/stella-color-input';

import ResetIcon from '../Icon/ResetIcon';
import AccessControl from '../Permissions/AccessControl';

export type ColorInputProps = {
  resetValue?: string | null;
  reset?: () => void;
} & StellaColorInputProps;

export default function ColorInput(props: ColorInputProps) {
  const { id, value, resetValue, reset } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<StellaColorInput {...props} readonly />}>
      <>
        <StellaColorInput {...props} />
        {reset && resetValue !== value && <ResetIcon id={`${id}-reset`} onClick={reset} />}
      </>
    </AccessControl>
  );
}
