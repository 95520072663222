import clone from 'clone';
import React from 'react';

import { paymentRetryAction } from '@zf/api-types/enums';
import { UpdatePaymentParametersType } from '@zf/api-types/parameters';
import { PaymentRetryType } from '@zf/api-types/payments';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import Button from '../../../components/Button/Button';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import { DeleteIcon } from '../../../components/Icon';
import { InlineNumberInput } from '../../../components/input/NumberInput';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';

type Props = {
  paymentRetries: PaymentRetryType[];
  setValue: (value: Partial<UpdatePaymentParametersType>, initialApiSet?: boolean, backup_?: boolean) => void;
};

const InlinePaymentRetryDropdown = InlineInputField<SimpleDropdownProps<paymentRetryAction>>(SimpleDropdown);

export default function PaymentRetries(props: Props) {
  const { paymentRetries, setValue } = props;
  const { i18n, enumReducer } = useAppContext();

  const [selectedPaymentRetry, setSelectedPaymentRetry] = React.useState(-1);
  const [scrollToIndex, setScrollToIndex] = React.useState(-1);

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 1,
      label: i18n.getTranslation('payment_retry.attempt'),
      dataKey: 'retryAttempt'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('payment_retry.retryAction'),
      dataKey: 'retryAction'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('payment_delay.value'),
      dataKey: 'value'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const setPaymentRetry = (
    index: number,
    value: PaymentRetryType[keyof PaymentRetryType],
    dataKey: keyof PaymentRetryType
  ) => {
    const clonedArray = clone(paymentRetries) as Record<string, any>[];
    clonedArray[index][dataKey] = value;
    setValue({ retryParameters: clonedArray as PaymentRetryType[] });
  };

  const addPaymentRetry = () => {
    const clonedArray = clone(paymentRetries);
    clonedArray.push({
      retryAction: '' as paymentRetryAction,
      retryAttempt: 0,
      value: 0
    });
    setSelectedPaymentRetry(clonedArray.length - 1);
    setValue({ retryParameters: clonedArray });
    setScrollToIndex(clonedArray.length - 1);
  };

  const deletePaymentRetry = (index: number) => {
    const clonedArray = clone(paymentRetries);
    clonedArray.splice(index, 1);
    setSelectedPaymentRetry(-1);
    setValue({ retryParameters: clonedArray });
  };

  const retryRows = paymentRetries.map((paymentRetry, index) => {
    return {
      retryAction: (
        <InlinePaymentRetryDropdown
          id={`retryAction.index-${index}`}
          values={enumReducer.getEnum('paymentRetryAction')}
          selectedValues={[paymentRetry.retryAction]}
          onChange={(val) => setPaymentRetry(index, val[0], 'retryAction')}
          error={!paymentRetry.retryAction}
        />
      ),
      value: (
        <InlineNumberInput
          id={`values.index-${index}`}
          value={paymentRetry.value}
          onChange={(val) => setPaymentRetry(index, val, 'value')}
          error={!paymentRetry.value}
        />
      ),
      retryAttempt: (
        <InlineNumberInput
          id={`attempt.index-${index}`}
          value={paymentRetry.retryAttempt}
          onChange={(val) => setPaymentRetry(index, val, 'retryAttempt')}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!paymentRetry.retryAttempt}
          error={!paymentRetry.retryAttempt}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`payment-retry.delete.index-${index}`}
          tooltipFor="payment-retries-table"
          onClick={() => deletePaymentRetry(index)}
        />
      )
    };
  });

  return (
    <Card id="payment-retry-card" width="2">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={i18n.getTranslation('coachmark.payment_retry.title')}
            content={[i18n.getTranslation('coachmark.payment_retry.paragraph')]}
          />
        }
        extraRight={
          <Button id="payment_retry.add" type="text" icon="plus" onClick={addPaymentRetry}>
            {i18n.getTranslation('general.add')}
          </Button>
        }
      >
        {i18n.getTranslation('parameters.payment_retry')}
      </CardHeader>
      <CardBody type="indexTable" fixedHeight>
        <DynamicIndexTable
          tooltipId="payment-retries-table"
          rows={retryRows}
          columns={tableColumns}
          selectedRow={selectedPaymentRetry}
          scrollToIndex={scrollToIndex}
          setSelectedRow={setSelectedPaymentRetry}
        />
      </CardBody>
    </Card>
  );
}
