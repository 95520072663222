import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { CommentCard, FeedCard } from '../../../../cards';
import CustomEntityPropertiesCard from '../../../../cards/CustomEntityProperties/CustomEntityPropertiesCard';
import IntegrationLoggingCard from '../../../../cards/Integration/IntegrationLoggingCard';
import LocationsCard from './locations-card';
import css from './property-group-billing-content.module.scss';
import PropertyDetailsCard from './property-group-details-card';

type Props = {
  propertyGroup: PropertyGroupType;
  timeStamp: string;
  setActiveTab: (tabId: string) => void;
  setPropertyGroup: React.Dispatch<React.SetStateAction<PropertyGroupType | null>>;
};

export default function PropertyGroupDetail(props: Props) {
  const { propertyGroup, timeStamp, setPropertyGroup } = props;

  return (
    <CardsContainer className={css['property-group-detail']}>
      <PropertyDetailsCard propertyGroup={propertyGroup} setPropertyGroup={setPropertyGroup} />
      <CustomEntityPropertiesCard
        entityType="PropertyGroups"
        entity={propertyGroup}
        entitySubjectType={entitySubjectType.propertygroup}
      />
      <LocationsCard propertyGroup={propertyGroup} />
      <FeedCard subjectType={entitySubjectType.propertygroup} subjectId={propertyGroup.id} timeStamp={timeStamp} />
      <CommentCard subjectType={entitySubjectType.propertygroup} subjectId={propertyGroup.id} />
      <IntegrationLoggingCard subjectId={propertyGroup.id} subjectType={'Propertygroup'} />
    </CardsContainer>
  );
}
