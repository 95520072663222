import React from 'react';

import AccessControl from '../../../components/Permissions/AccessControl';
import HomerCheckBox, { CheckBoxProps } from './HomerCheckBox';

export default function CheckBox(props: CheckBoxProps) {
  const { id } = props;

  return (
    <AccessControl permissionsKey={id} fallBack={<HomerCheckBox {...props} disabled />}>
      <HomerCheckBox {...props} />
    </AccessControl>
  );
}
