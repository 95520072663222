import React from 'react';

import { importJobStatus } from '@zf/api-types/enums';
import { ImportJobRowType, ImportJobType } from '@zf/api-types/import-job';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteImportJobDialog from '../../../actions/import-jobs/delete-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function DeleteImportJobsListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('importjob');
  const selectedRows: ImportJobRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<ImportJobType>('importjob');
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.some((r) => {
      return (
        r.__importJobEntity.status === importJobStatus.processing ||
        r.__importJobEntity.status === importJobStatus.reading ||
        r.__importJobEntity.status === importJobStatus.storing ||
        r.__importJobEntity.status === importJobStatus.validating ||
        r.__importJobEntity.status === importJobStatus.postprocessing
      );
    });

  return (
    <DropdownAction
      id="import_jobs.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={
        <DeleteImportJobDialog
          onComplete={setDeletedRows}
          ref={clickRef}
          selectedRows={selectedRows}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      dialogType="danger"
      buttonPositive={i18n.getTranslation('general.delete')}
    />
  );
}
