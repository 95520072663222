import { observer } from 'mobx-react';
import React from 'react';

import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import AddContractForCustomerListItem from '../../../components/list-items/customers/AddContractForCustomerListItem';
import AddInvoiceForCustomerListItem from '../../../components/list-items/customers/AddInvoiceForCustomerListItem';
import AddMeasurementForCustomerListItem from '../../../components/list-items/customers/AddMeasurementForCustomerListItem';
import AddPaymentToCustomerListItem from '../../../components/list-items/customers/AddPaymentToCustomerListItem';
import FilesActions from '../../../components/units/EntityAttachments/ui/FilesActions';
import { useStore } from '../../../hooks/useStore';
import { attachmentType } from '../../../components/units/EntityAttachments/logic/FilesStore';
import { entitySubjectType } from '@zf/api-types/enums';
import AddCustomerToGroupListItem from '../list-page/Actions/AddCustomerToGroupListItem';
import { CustomerType } from '@zf/api-types/master-data/customer';
import ExportCustomerTransactions from 'components/list-items/customers/ExportCustomerTransactions';

type Props = {
  refresh: () => void;
  customer: CustomerType;
};

const CustomerActions = (props: Props) => {
  const { refresh, customer } = props;
  const { customerStore } = useStore();
  const { filesStore, entityAttachmentFeatures } = customerStore;
  const { entityId, listFiles } = entityAttachmentFeatures;

  return (
    <>
      <FilesActions
        listFiles={listFiles}
        attachmentType={attachmentType.entityattachments}
        filesStore={filesStore}
        culture={filesStore.culture}
        entityId={entityId}
        subjectType={entitySubjectType.customer}
      >
        <AddContractForCustomerListItem />
        <AddPaymentToCustomerListItem onComplete={refresh} />
        <AddCustomerToGroupListItem onComplete={refresh} customer={customer} />
        <HorizontalDivider />
        <AddInvoiceForCustomerListItem />
        <AddMeasurementForCustomerListItem />
      </FilesActions>
      <HorizontalDivider />
      <ExportCustomerTransactions customer={customer} />
    </>
  );
};

export default observer(CustomerActions);
