import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import css from './left-column.module.scss';

type Props = {
  task: MoveRequestType;
};

export default function Comment(props: Props) {
  const { task } = props;
  const { i18n } = useAppContext();

  return (
    <div className={css['comment']}>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('tasks.customer_comment')}
      </Heading>
      <Paragraph>{task.customMessage}</Paragraph>
    </div>
  );
}
