import React from 'react';

import { ExportJobRowType, ExportJobType } from '@zf/api-types/export-job';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteExportJobDialog from '../../../actions/export-jobs/delete-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function DeleteExportJobsListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('exportjob');
  const selectedRows: ExportJobRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<ExportJobType>('exportjob');
  const { clickRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="export_jobs.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={
        <DeleteExportJobDialog
          onComplete={setDeletedRows}
          ref={clickRef}
          selectedRows={selectedRows}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0}
      dialogType="danger"
      buttonPositive={i18n.getTranslation('general.delete')}
    />
  );
}
