import { customerType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';

import NewContractorInvoiceAddress from './NewContractorInvoiceAddress';
import NewCustomer from './NewCustomer';
import NewOrExistingCustomer from './NewOrExistingCustomer';

const CustomerSectionBody = () => {
  const { contractStore } = useStore();

  const { locationSectionStore, regularMoveInWizardValues } =
    contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { selectedLocation, selectedLocations } = locationSectionStore;
  const { values, setValue } = regularMoveInWizardValues;
  const { contractor, customerType: customerType_ } = values;

  // New is referring to the created entity here => set customerType to existingcustomer
  const setNewCustomer = (c: CustomerType) => setValue({ contractor: c, customerType: customerType.existingcustomer });

  return (
    <>
      <NewOrExistingCustomer
        customer={contractor}
        customerType_={customerType_}
        newCustomer={<NewCustomer setNewCustomer={setNewCustomer} />}
        setCustomer={(contractor: CustomerType | null) => setValue({ contractor })}
        setCustomerType={(customerType: customerType) => setValue({ customerType })}
      />

      {(selectedLocation || selectedLocations.size !== 0) && (
        <NewContractorInvoiceAddress id="invoice-address-new-contractor" />
      )}
    </>
  );
};

export default observer(CustomerSectionBody);
