import { Moment } from 'moment';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { onlyUnique } from '@zf/utils/src/array';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';
import { uiCulture } from '@zf/api-types/enums';

export const createOrRecalculateInvoice = async (
  checkAdvances: boolean,
  recalculateIfNeeded: boolean,
  advanceUntilDateTime: Moment,
  completenessId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<BillingCompletenessType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/BillingCompletenesses/${completenessId}`,
        data: {
          onlyAdvances: checkAdvances,
          recalculateIfNeeded: recalculateIfNeeded,
          advanceUntilDateTime: advanceUntilDateTime.toISOString()
        }
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const billIntermediately = async (
  endDateTime: Moment,
  completenessId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<BillingCompletenessType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/BillingCompletenesses/${completenessId}/billintermediately`,
        data: {
          endDateTime: endDateTime.toISOString()
        }
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const closeManually = async (completenessId: string, tenantReducer: TenantReturnValue, lang: uiCulture) => {
  return (
    await sendRequest<BillingCompletenessType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/BillingCompletenesses/${completenessId}/manuallyclose`
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const billWithPreviousTariffs = async (
  propertyGroupId: string,
  billingRelationIds: string[],
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  const uniqueIds = billingRelationIds.filter(onlyUnique);

  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroupId}/costallocation/billwithprevioustariffs`,
        data: {
          billingRelationIds: uniqueIds
        }
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const reopen = async (completenessId: string, tenantReducer: TenantReturnValue, lang: uiCulture) => {
  return (
    await sendRequest<BillingCompletenessType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/BillingCompletenesses/${completenessId}/reopen`
      },
      tenantReducer,
      lang
    })
  ).data;
};
