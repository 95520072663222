import moment from 'moment';

import {
  CustomerBankAccountType,
  UpdateCustomerBankAccountType,
  UpdateCustomerBillingDetailsRequestType
} from '@zf/api-types/billing/customer-bank-account';
import { BankAccountRequestType } from '@zf/api-types/general';
import { CustomerType, MandateForCustomerType } from '@zf/api-types/master-data/customer';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';
import { startOfDay } from '@zf/utils/src/date';
import { uiCulture } from '@zf/api-types/enums';

export async function createSignedMandate(
  signedDate: string,
  iban: string,
  customer: CustomerType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<MandateForCustomerType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/customermandates/c/${customer.id}/signed`,
        data: {
          accountHolder: customer.shortDisplayName,
          iban: iban,
          mandateType: 'core',
          signedDateTime: signedDate,
          bic: ''
        }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function confirmIban(customerBankAccountId: string, tenantReducer: TenantReturnValue, lang: uiCulture) {
  return (
    await sendRequest<CustomerBankAccountType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/CustomerBankAccounts/${customerBankAccountId}/confirm`
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function rejectIban(customerBankAccountId: string, tenantReducer: TenantReturnValue, lang: uiCulture) {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/bill/CustomerBankAccounts/${customerBankAccountId}/delete`
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function changeCustomerBankingDetails(
  newDetails: UpdateCustomerBillingDetailsRequestType,
  customerId: string | undefined,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<CustomerType>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/Customers/${customerId}/billingdetails`,
        data: newDetails
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function updateCustomerBankAccount(
  updatedAccount: UpdateCustomerBankAccountType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<CustomerBankAccountType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/CustomerBankAccounts/${updatedAccount.id}/update`,
        data: updatedAccount
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function signMandate(
  mandate: MandateForCustomerType,
  customer: CustomerType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<MandateForCustomerType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/customermandates/${mandate.id}/sign`,
        data: {
          signedDateTime: startOfDay(moment()).toISOString(),
          iban: mandate.iban,
          bic: 'bic',
          accountHolder: customer.shortDisplayName
        }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function cancelMandate(
  id: string,
  mutationDateTime: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<MandateForCustomerType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/customermandates/${id}/cancel`,
        data: {
          mutationDateTime
        }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function createMandate(
  bankAccount: BankAccountRequestType,
  customer: CustomerType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) {
  return (
    await sendRequest<MandateForCustomerType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/customermandates/c/${customer.id}`,
        data: {
          accountHolder: customer.shortDisplayName,
          iban: bankAccount.iban,
          mandateType: 'core',
          bic: ''
        }
      },
      tenantReducer,
      lang
    })
  ).data;
}
