import { observer } from 'mobx-react';
import React from 'react';

import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { ListDivider } from '@zf/stella-react/src/atoms/List';
import { colors } from '@zf/utils/src/color';
import { convertMomentToISOString, convertToMoment } from '@zf/utils/src/date';

import useRefresh from '../../../../../app-context/hooks/use-refresh';
import OutgoingBankingTransactionExport from '../../../../../components/Button/Excel/OutgoingBankingTransactionExport';
import AddOutgoingBankingTransactionListPageButton from '../../../../../components/Button/OutgoingBankingTransaction/AddOutgoingBankingTransactionListPageButton';
import CancelOutgoingBankingTransactionListPageButton from '../../../../../components/Button/OutgoingBankingTransaction/CancelOutgoingBankingTransactionListPageButton';
import DeleteOutgoingBankingTransactionListPageButton from '../../../../../components/Button/OutgoingBankingTransaction/DeleteOutgoingBankingTransactionListPageButton';
import DownloadOutgoingBankingTransactionListPageButton from '../../../../../components/Button/OutgoingBankingTransaction/DownloadOutgoingBankingTransactionListPageButton';
import OverwriteBankConfirmationOutgoingBankingTransactionListPageButton from '../../../../../components/Button/OutgoingBankingTransaction/OverwriteBankConfirmationOutgoingBankingTransactionListPageButton';
import BankAccountsDropdown from '../../../../../components/Dropdown/bankaccounts-dropdown/BankAccountsDropdown';
import FilterBar from '../../../../../components/Filters/FilterBar';
import { Icon } from '../../../../../components/Icon';
import DateRangePicker from '../../../../../components/input/DateRangePicker';
import EnumFilterSelect from '../../../../../components/input/EnumFilterSelect';
import { useStore } from '../../../../../hooks/useStore';
import usePaymentTabs from '../../../hooks/usePaymentTabs';
import useOutgoingBankingTransactionsFilter from '../hooks/useOutgoingBankingTransactionsFilter';

type Props = {
  search: string;
};

const OutgoingBankingtransactionsFilterBar = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;
  const {
    overviewCount,
    quickFilter,
    searchValue,
    filterTags,
    queryParams,
    onFilter,
    setQueryParams,
    onSearch,
    clearQueryParams,
    refreshCounts
  } = useOutgoingBankingTransactionsFilter(props.search);
  const { refresh } = useRefresh();

  const tabItems = usePaymentTabs();

  const refreshList = () => {
    refresh();
    refreshCounts();
  };

  return (
    <FilterBar
      refreshPage={refreshList}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      tabItems={tabItems}
      defaultTab="payments"
      actions={[
        <AddOutgoingBankingTransactionListPageButton key="add" refresh={refreshList} />,
        <DownloadOutgoingBankingTransactionListPageButton key="download" refresh={refreshList} />,
        <OutgoingBankingTransactionExport key="export" />,
        <OverwriteBankConfirmationOutgoingBankingTransactionListPageButton
          key="confirm"
          refreshCounts={refreshCounts}
        />,
        <CancelOutgoingBankingTransactionListPageButton key="cancel" refreshCounts={refreshCounts} />,
        <DeleteOutgoingBankingTransactionListPageButton key="delete" refreshCounts={refreshCounts} />
      ]}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="batch" />}
          count={overviewCount.all}
          title={getTranslation('banking_transaction.all')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          id="readyToSend"
          key="readyToSend"
          onTrigger={() => onFilter('readytosend')}
          icon={<Icon type="batch" />}
          count={overviewCount.readyToSend}
          title={getTranslation('banking_transaction.ready_to_send')}
          activeColor={colors['blue-700']}
          active={quickFilter === 'readytosend'}
        />,
        <FilterButton
          id="to_confirm"
          key="to_confirm"
          onTrigger={() => onFilter('toconfirm')}
          icon={<Icon type="batch" />}
          count={overviewCount.toConfirm}
          title={getTranslation('banking_transaction.to_confirm')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'toconfirm'}
        />,
        <FilterButton
          id="closed"
          key="closed"
          onTrigger={() => onFilter('closed')}
          icon={<Icon type="batch" />}
          count={overviewCount.closed}
          title={getTranslation('banking_transaction.closed')}
          activeColor={colors['green-600']}
          active={quickFilter === 'closed'}
        />,
        <FilterButton
          id="date_expired"
          key="date_expired"
          onTrigger={() => onFilter('dateexpired')}
          icon={<Icon type="batch" />}
          count={overviewCount.dateExpired}
          title={getTranslation('banking_transaction.date_expired')}
          activeColor={colors['orange-600']}
          active={quickFilter === 'dateexpired'}
        />,
        <FilterButton
          id="resolve_issues"
          key="resolve_issues"
          onTrigger={() => onFilter('resolveissues')}
          icon={<Icon type="batch" />}
          count={overviewCount.resolveIssues}
          title={getTranslation('banking_transaction.resolve_issues')}
          activeColor={colors['red-600']}
          active={quickFilter === 'resolveissues'}
        />
      ]}
      filterInputs={[
        <Label key="bank-account">{getTranslation('banking_transaction.bank_account')}</Label>,
        <BankAccountsDropdown
          id="bank_account_dropdown"
          key="iban-filter"
          onChange={(val) =>
            setQueryParams({
              companyBankAccountIBAN: val[0]?.iban || '',
              companyBankAccountId: val[0]?.id || ''
            })
          }
          selectedValue={queryParams.companyBankAccountId}
          isInline
          renderTopLvl={false}
        />,
        <ListDivider key="divider-0" />,
        <EnumFilterSelect
          id="type-filter"
          key="type-filter"
          title={getTranslation('general.type')}
          options={getEnum<outgoingBankingTransactionType>('outgoingBankingTransactionType')}
          values={queryParams.outgoingBankingTransactionType || []}
          setValue={(value) => setQueryParams({ outgoingBankingTransactionType: value })}
        />,
        <ListDivider key="divider-1" />,
        <Label key="mutation_date">{getTranslation('banking_transaction.mutationDate')}</Label>,
        <DateRangePicker
          id="mutation-date-filter"
          key="mutation-date-filter"
          startDate={convertToMoment(queryParams.mutationDateTimeStart)}
          endDate={convertToMoment(queryParams.mutationDateTimeEnd)}
          setDates={(dates) =>
            setQueryParams({
              mutationDateTimeStart: convertMomentToISOString(dates[0]) as string,
              mutationDateTimeEnd: convertMomentToISOString(dates[1]) as string
            })
          }
          placeholderStart={getTranslation('general.start')}
          placeholderEnd={getTranslation('general.end')}
          renderTopLvl={false}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('banking_transaction.search')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
};

export default observer(OutgoingBankingtransactionsFilterBar);
