import { observer } from 'mobx-react';
import React from 'react';

import { SocialTariffExportRowType, SocialTariffExportType } from '@zf/api-types/regionals/regionals';
import useDialog from '@zf/hooks/src/useDialog';

import useCurrent from '../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import GenericDialog from '../../../../components/units/Dialog/GenericDialog';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import { METHODS } from '../../../../utils/request';

const DeleteSocialTariffPageButton = () => {
  const { applicationStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;
  const { myCurrent } = useCurrent('country_specifics');
  const selectedRows: SocialTariffExportRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<SocialTariffExportType>('country_specifics');
  const { clickRef, onSubmit } = useDialog();

  const deleteText = getTranslation('general.delete');

  const deleteFunction = () => {
    selectedRows.forEach(async (row) => {
      await sendRequest<SocialTariffExportType>({
        request: {
          method: METHODS.DELETE,
          endpoint: `/reg/socialtariff/exports/${row.__id}`
        }
      });
    });

    const deletedRows = selectedRows.map((r) => r.__entity);

    setDeletedRows([], deletedRows);
  };

  return (
    <DropdownAction
      id="country_specifics.social_tarif.delete"
      icon="trashcan"
      dialogTitle={deleteText}
      dialogContent={
        <GenericDialog
          ref={clickRef}
          info={getTranslation('country_specifics.delete_desc', { amount: selectedRows.length })}
          successMsg={getTranslation('country_specifics.deleted_success')}
          errorMsg={getTranslation('country_specifics.deleted_failed')}
          submitFunction={deleteFunction}
        />
      }
      dialogType="danger"
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0}
      buttonPositive={deleteText}
    />
  );
};

export default observer(DeleteSocialTariffPageButton);
