import clone from 'clone';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { searchInObjectsArray } from '@zf/utils/src/object';

export type ValidatorCtxState<T> = {
  values: T;
  backup: T;
  isDirty: boolean;
  isLocked: boolean;
  selectedIndex: number;
  scrollToIndex: number;
  searchValue: string;
};

export type ValidatorCtxAction<T> =
  | { type: 'SET_VALUE'; value: Partial<ValidatorCtxState<T>> }
  | { type: 'SET_SEARCH'; searchValue: string }
  | { type: 'SET_SELECTED_INDEX'; index: number }
  | { type: 'BACKUP'; updatedValues?: T }
  | { type: 'RESTORE' };

function reducer<T>(state: ValidatorCtxState<T>, action: ValidatorCtxAction<T>) {
  switch (action.type) {
    case 'SET_VALUE':
      return {
        ...state,
        isDirty: state.isLocked ? false : true,
        ...action.value
      };

    case 'BACKUP': {
      const valuesToSet = action.updatedValues ? action.updatedValues : state.values;

      return {
        ...state,
        values: clone(valuesToSet),
        backup: clone(valuesToSet),
        isDirty: false,
        isLocked: false
      };
    }

    case 'RESTORE':
      return {
        ...state,
        values: clone(state.backup),
        selectedIndex: -1,
        scrollToIndex: -1,
        searchValue: '',
        isDirty: false
      };

    case 'SET_SEARCH': {
      if (Array.isArray(state.values)) {
        const foundIndex = searchInObjectsArray<T>(action.searchValue, state.values);

        return {
          ...state,
          searchValue: action.searchValue,
          scrollToIndex: foundIndex,
          selectedIndex: foundIndex
        };
      }

      break;
    }

    case 'SET_SELECTED_INDEX':
      return {
        ...state,
        selectedIndex: action.index
      };

    default:
      return state;
  }
}

export default function useCreateContext<T>(values: T) {
  const initialState: ValidatorCtxState<T> = {
    values: clone(values),
    backup: clone(values),
    isDirty: false,
    isLocked: false,
    selectedIndex: -1,
    scrollToIndex: -1,
    searchValue: ''
  };

  const useValue = (): [ValidatorCtxState<T>, React.Dispatch<ValidatorCtxAction<T>>] =>
    // @ts-ignore
    useReducer(reducer, initialState);

  const { Provider, useTracked } = createContainer(useValue);

  return {
    Provider,
    useTracked
  };
}
