import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';
import { colors } from '@zf/utils/src/color';

import HorizontalDivider from '../Divider/HorizontalDivider';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import css from './collapsable.module.scss';

type Props = {
  title: string;
  children: JSX.Element;
  className?: string;
  titleColor?: string;
  defaultClosed?: boolean;
  extraLeft?: JSX.Element;
};

export default function Collapsable(props: Props) {
  const { title, className, titleColor, extraLeft, children, defaultClosed = true } = props;

  const [isCollapsed, setIsCollapsed] = React.useState(defaultClosed);

  return (
    <div className={classNames(css['wrapper'], className)}>
      <div className={css['header']}>
        {extraLeft}
        <BaseButton id={`${title}`} className={css['title']} onClick={() => setIsCollapsed(!isCollapsed)}>
          <Paragraph style={titleColor ? { color: titleColor } : {}}>{title}</Paragraph>
          <Icon name={isCollapsed ? 'caret-down' : 'caret-up'} />
        </BaseButton>
      </div>

      <div className={css['collapsable']}>
        {!isCollapsed && <div className={css['collapsable-content']}>{children}</div>}
      </div>
      <HorizontalDivider color={colors['silver-200']} />
    </div>
  );
}
