import classNames from 'classnames';
import React, { useCallback } from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import Icon from '../Icon/Icon';
import css from './add-btn.module.scss';

type Props = {
  id: string;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
};

export default function AddButton(props: Props) {
  const { id, className, disabled = false, onClick } = props;

  const trigger = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <BaseButton
      id={id}
      className={classNames(css['add-btn'], css[disabled ? 'disabled' : 'active'], className)}
      onClick={trigger}
    >
      <Icon name="plus" />
    </BaseButton>
  );
}
