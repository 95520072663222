import { observer } from 'mobx-react';
import React from 'react';

import { ConfigHelp } from '../../../../components/CoachMarks';
import ConfigColumn from '../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../components/Column/EmptyColumn';
import { Spinner } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import css from '../products-tab.module.scss';
import ProductBillingItemActions from './ProductBillingItemActions';
import ProductBillingItemsContent from './ProductBillingItemsContent';

const ProductBillingItems = () => {
  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { isPanelViewMode, onlyShowTariffs, productsStore } = productConfigStore;
  const {
    selectedProduct,
    productBillingItems_,
    taxCodes_,
    calculationTypes_,
    cultureTable_,
    consumptionUnitTypes_,
    customEntityPropertyTypes_
  } = productsStore;

  const renderItemsContent = () => {
    if (selectedProduct) {
      return !taxCodes_ ||
        !calculationTypes_ ||
        !consumptionUnitTypes_ ||
        !customEntityPropertyTypes_ ||
        !cultureTable_ ||
        !productBillingItems_ ? (
        <Spinner centered />
      ) : (
        <ProductBillingItemsContent />
      );
    }

    return (
      <EmptyColumn
        image="attributes2"
        title={getTranslation('billing_items.billing_items')}
        text={getTranslation('billing_items.no_billing_item_templates')}
      />
    );
  };

  return (
    <ConfigColumn
      className={onlyShowTariffs ? css['hidden'] : undefined}
      title={getTranslation('billing_items.billing_items')}
      extraRight={<ProductBillingItemActions />}
      disabled={!selectedProduct}
      coachMark={
        <ConfigHelp
          title={getTranslation('coachmark.billing_items.title')}
          content={[getTranslation('coachmark.billing_items.paragraph')]}
        />
      }
      borderRight={!isPanelViewMode}
    >
      {renderItemsContent()}
    </ConfigColumn>
  );
};

export default observer(ProductBillingItems);
