import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { invoiceStatus, paymentProcessStatus, sentStatus } from '@zf/api-types/enums';
import { InvoiceType, MarkAsSentInvoiceType } from '@zf/api-types/invoice';

import RootStore from '../../../app-context/stores';
import { statusLabel } from '../../../app-context/stores/domain/app-store-types';

export default class Invoice {
  public invoice: InvoiceType;
  public flowId: string;

  public rootStore: RootStore;

  constructor(rootStore: RootStore, invoice: InvoiceType) {
    this.rootStore = rootStore;
    this.invoice = invoice;

    makeObservable(this, {
      invoice: observable,
      flowId: observable,

      isOverdue: computed,
      invoiceStatus: computed,
      isPaid: computed,
      credit: computed,
      paymentStatus: computed,

      setFlowId: action
    });
  }

  setFlowId(id: string) {
    this.flowId = id;
  }

  get invoiceToHandle(): MarkAsSentInvoiceType {
    return {
      id: this.invoice.id,
      etag: this.invoice._etag
    };
  }

  get invoiceStatus(): statusLabel {
    if (this.invoice.sent === sentStatus.sent || this.invoice.sent === sentStatus.skipped) {
      return {
        status: this.invoice.sent,
        translation: this.rootStore.applicationStore.getEnumTranslation('sentStatus', this.invoice.sent)
      };
    }

    return {
      status: this.invoice.status,
      translation: this.rootStore.applicationStore.getEnumTranslation('invoiceStatus', this.invoice.status)
    };
  }

  get paymentStatus(): statusLabel {
    if (this.invoice.status === invoiceStatus.created) {
      return { status: '', translation: '' };
    }

    if (this.isOverdue) {
      return {
        status: 'overdue',
        translation: this.rootStore.applicationStore.getTranslation('invoice.overdue')
      };
    }

    if (this.invoice.creditedByInvoiceId) {
      return {
        status: 'credited',
        translation: this.rootStore.applicationStore.getTranslation('invoice.credited')
      };
    }

    return {
      status: this.invoice.paymentDetails.currentPaymentStatus,
      translation: this.rootStore.applicationStore.getEnumTranslation(
        'paymentProcessStatus',
        this.invoice.paymentDetails.currentPaymentStatus
      )
    };
  }

  get isPaid() {
    return this.invoice.paymentDetails.currentPaymentStatus === paymentProcessStatus.paid;
  }

  get isOverdue() {
    return !this.isPaid && moment().isAfter(this.invoice.dueDate) && this.invoice.status === invoiceStatus.generated;
  }

  get credit() {
    return this.invoice.status !== invoiceStatus.created;
  }
}
