import { observer } from 'mobx-react';
import React from 'react';

import { useAppContext } from '../../app-context';
import BaseFormVjf from '../../app-context/stores/forms/BaseFormVjf';
import Button from '../Button/Button';
import css from './commit-section.module.scss';

type Props<F> = {
  handleCancel?: () => void;
  handleSave?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDirty?: boolean;
  form?: F;
  secondary?: string;
  primary?: string;
};

const CommitSection = <F extends BaseFormVjf<any>>(props: Props<F>) => {
  const { i18n } = useAppContext();
  let {
    handleCancel = () => {},
    handleSave = () => {},
    isDirty = false,
    form,
    secondary = i18n.getTranslation('general.cancel'),
    primary = i18n.getTranslation('general.save')
  } = props;

  const secondaryIsAlwaysPresent = !!secondary;

  if (form) {
    handleCancel = form._onReset;
    handleSave = form.onSubmit;
    isDirty = form._isDirty;
  }

  return (
    <div className={css['commit-wrapper']}>
      <div className={css['commit-actions']}>
        {isDirty || secondaryIsAlwaysPresent ? (
          <div className={css['action']}>
            <Button id="btn-cancel" onClick={handleCancel} type="text" danger>
              {secondary}
            </Button>
          </div>
        ) : null}
        <div className={css['action']}>
          <Button id="btn-save" onClick={handleSave} type="primary" disabled={!isDirty}>
            {primary}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(CommitSection);
