import clone from 'clone';
import React from 'react';

import { AddressType } from '@zf/api-types/general';
import { ContactDetailType, OrganizationConfigType } from '@zf/api-types/settings-config';
import useDialog from '@zf/hooks/src/useDialog';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardBody, CardHeader, CardItem, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';

import ResetOrganisationDialog from '../../../actions/configuration/reset-org-dialog';
import { useAppContext } from '../../../app-context';
import ContactDetailsCard from '../../../cards/Contacts/ContactDetailsCard';
import ExtraCard, { ExtrasType } from '../../../cards/ExtrasCard/ExtraCard';
import ResetAllButton from '../../../components/Button/ResetAllButton/ResetAllButton';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import CommitSection from '../../../components/config/commit-section';
import CountryInput, { CountryInputProps } from '../../../components/input/country-input';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import { dialog } from '../../../events/dialog-events';
import { notify } from '../../../events/notification-events';
import { createHeader, METHODS, sendRequest } from '../../../utils/request';
import GeneralCard from './GeneralCard';

type Props = {
  organizationConfig: OrganizationConfigType;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineCountryInput = InlineInputField<CountryInputProps>(CountryInput);

export default function Organization(props: Props) {
  const { organizationConfig } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  if (!organizationConfig.contactDetails) organizationConfig.contactDetails = {} as ContactDetailType;
  if (!organizationConfig.address) organizationConfig.address = {} as AddressType;

  const { values, isDirty, backup, setValue, restoreValues, submitFactory } = useValidator<OrganizationConfigType>({
    initialValues: { ...clone(organizationConfig) }
  });

  const handleSave = submitFactory(async () => {
    try {
      const organizationResponse = await sendRequest<OrganizationConfigType>({
        request: {
          method: METHODS.POST,
          endpoint: `/cfg/Organizations/${organizationConfig.id}`,
          data: {
            ...values
          }
        },
        customHeaders: createHeader({
          'If-Match': organizationConfig._etag
        }),
        tenantReducer,
        lang: i18n.lang
      });

      notify.success({
        content: i18n.getTranslation('organization.success_update')
      });

      // When culture changes, refresh whole application to apply changes
      if (backup.culture !== values.culture) {
        window.location.reload();
      } else {
        setValue(organizationResponse.data, false, true);
      }
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('organization.error_update'),
        error: e
      });
    }
  });

  const openDialogConfirm = () => {
    dialog.normal({
      title: i18n.getTranslation('organization.reset_organization_config'),
      content: <ResetOrganisationDialog ref={clickRef} values={values} setValue={setValue} />,
      buttonPositive: i18n.getTranslation('general.confirm'),
      type: 'danger',
      onSubmit
    });
  };

  const handleCancel = () => {
    restoreValues();
  };

  const setObjectValue = (
    objectKey: keyof OrganizationConfigType,
    value: string,
    dataKey: keyof ContactDetailType | keyof AddressType
  ) => {
    const copy = { ...values } as Record<string, any>;
    copy[objectKey][dataKey] = value;
    setValue(copy);
  };

  const setContactDetailsValue = (value: Partial<ContactDetailType>) => {
    setValue({ contactDetails: { ...clone(values.contactDetails), ...value } });
  };

  // Remove this when data model updated
  const extras: ExtrasType = {
    logo: values.logo,
    primaryColor: values.primaryColor,
    secondaryColor: values.secondaryColor
  };

  return (
    <>
      <CommitSection handleCancel={handleCancel} handleSave={handleSave} isDirty={isDirty} />
      <CardsContainer>
        <GeneralCard values={values} setValue={setValue} />

        <Card id="address-card" width="1">
          <CardHeader>{i18n.getTranslation('location.address')}</CardHeader>
          <CardBody type="grid" noMaxHeight>
            <CardItem width="6">
              <Label>{i18n.getTranslation('location.country')}</Label>
            </CardItem>
            <CardItem width="6">
              <InlineCountryInput
                id="organisation.country"
                selectedValues={[values.address?.country || '']}
                onChange={(val) => setObjectValue('address', val[0], 'country')}
                organisationScoped
                error={!values.address?.country}
              />
            </CardItem>

            <CardItem width="6">
              <Label>{i18n.getTranslation('organization.streetName')}</Label>
            </CardItem>
            <CardItem width="6">
              <InlineInputFieldInput
                id="organisation.streetname"
                onChange={(val) => setObjectValue('address', val, 'streetName')}
                value={values.address?.streetName}
                error={!values.address?.streetName}
              />
            </CardItem>

            <CardItem width="6">
              <Label>{i18n.getTranslation('organization.streetNumber')}</Label>
            </CardItem>
            <CardItem width="6">
              <InlineInputFieldInput
                id="organisation.streetnumber"
                onChange={(val) => setObjectValue('address', val, 'streetNumber')}
                value={values.address?.streetNumber}
                error={!values.address?.streetNumber}
              />
            </CardItem>

            <CardItem width="6">
              <Label>{i18n.getTranslation('organization.streetNumberAddition')}</Label>
            </CardItem>
            <CardItem width="6">
              <InlineInputFieldInput
                id="organisation.numberaddition"
                onChange={(val) => setObjectValue('address', val, 'streetNumberAddition')}
                value={values.address?.streetNumberAddition}
              />
            </CardItem>

            <CardItem width="6">
              <Label>{i18n.getTranslation('organization.postalCode')}</Label>
            </CardItem>
            <CardItem width="6">
              <InlineInputFieldInput
                id="organisation.postalcode"
                onChange={(val) => setObjectValue('address', val, 'postalCode')}
                value={values.address?.postalCode}
                error={!values.address?.postalCode}
              />
            </CardItem>

            <CardItem width="6">
              <Label>{i18n.getTranslation('location.city')}</Label>
            </CardItem>
            <CardItem width="6">
              <InlineInputFieldInput
                id="organisation.city"
                onChange={(val) => setObjectValue('address', val, 'city')}
                value={values.address?.city}
                error={!values.address?.city}
              />
            </CardItem>
          </CardBody>
        </Card>

        <ContactDetailsCard contactDetails={values.contactDetails} setContactDetailsValue={setContactDetailsValue} />

        <ExtraCard extras={extras} setExtrasValue={(value: Partial<ExtrasType>) => setValue(value)} />

        <Card id="number-card" width="1">
          <CardHeader
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.organization.number')}
                content={[i18n.getTranslation('coachmark.organization.number-paragraph')]}
              />
            }
          >
            {i18n.getTranslation('organization.number_generation')}
          </CardHeader>
          <CardBody type="grid" noMaxHeight>
            <CardItem width="6">
              <Label>{i18n.getTranslation('organization.customer')}</Label>
            </CardItem>

            <CardItem width="6">
              <CheckBox
                id="organisation.customer_nr"
                onChange={(val) => setValue({ manuallySetCustomerNumber: val })}
                checked={values.manuallySetCustomerNumber}
              />
            </CardItem>

            <CardItem width="6">
              <Label>{i18n.getTranslation('organization.contract')}</Label>
            </CardItem>

            <CardItem width="6">
              <CheckBox
                id="organisation.contract_nr"
                onChange={(val) => setValue({ manuallySetContractNumber: val })}
                checked={values.manuallySetContractNumber}
              />
            </CardItem>
          </CardBody>
        </Card>
      </CardsContainer>

      <ResetAllButton
        id="reset_all"
        title={i18n.getTranslation('organization.reset_organization_config')}
        onClick={openDialogConfirm}
      />
    </>
  );
}
