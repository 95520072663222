import React from 'react';

import { useTracked } from '../app-state';

type UseRefreshReturnType = {
  refresh: () => void;
  timeStamp: string;
};

const useRefresh = (): UseRefreshReturnType => {
  const [state, dispatch] = useTracked();

  const refresh = React.useCallback(() => {
    dispatch({ type: 'refresh' });
  }, []);

  return { refresh, timeStamp: state.utils.timeStamp };
};

export default useRefresh;
