import Interweave from 'interweave';
import React, { ReactNode } from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';
import { colors } from '@zf/utils/src/color';

import { Icon } from '../../../Foundation';
import css from './coachmark.module.scss';

type Props = {
  children: ReactNode;
  title?: string;
  alert?: boolean;
};

/**
 * Behaves similar as QuickTip but is able to render an entire component as tooltip
 */
export default function CoachMark(props: Props) {
  const { children, title = '', alert = false } = props;

  return (
    <TooltipTrigger
      tooltip={
        <div className={css['tooltip-content']}>
          <Heading headingType="h3">{title}</Heading>
          {typeof children === 'string' ? <Interweave content={children} /> : children}
        </div>
      }
    >
      <Icon
        className={css['icon']}
        name={alert ? 'alert-circle' : 'info-circle'}
        color={colors[alert ? 'red-600' : 'blue-600']}
      />
    </TooltipTrigger>
  );
}
