import React from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import ChangeProductDialog from '../../dialogs/change-product/change-product-dialog';

type Props = {
  contract: ContractType;
};

export default function ChangeProductListItem(props: Props) {
  const { contract } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { onSubmit, clickRef, validationRef } = useDialog();

  return (
    <DropdownAction
      id="contract.change_product"
      icon="change-project"
      dialogTitle={getTranslation('contracts.change_product')}
      dialogContent={<ChangeProductDialog validationRef={validationRef} contract={contract} ref={clickRef} />}
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={getTranslation('general.save')}
    />
  );
}
