import { observer } from 'mobx-react';
import React from 'react';

import EmptyColumn from '../../../components/Column/EmptyColumn';
import { WrappableVirtualizedList } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import VirtualBillingItem from '../shared/billing-item/VirtualBillingItem';

const BillingItemsContent = () => {
  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    billingItems,
    taxCodes,
    customEntityPropertyTypes,
    calculationTypes,
    selectedBillingItem,
    cultureTable_,
    setSelectedBillingItem,
    updateBillingItemInList,
    initTariffs
  } = productConfigStore.billingItemsStore;

  const items = billingItems.map((b, index) => {
    return {
      initTariffs,
      key: `${b.id}-${index}`,
      index,
      billingItem: b,
      taxCodes,
      cultureTable_,
      customEntityPropertyTypes,
      calculationTypes,
      onClick: () => setSelectedBillingItem(b),
      isSelected: selectedBillingItem?.id === b.id,
      updateBillingItemInList,
      showActions: true
    };
  });

  return billingItems.length > 0 ? (
    <WrappableVirtualizedList items={items} ItemComponent={VirtualBillingItem} columnWidth={400} />
  ) : (
    <EmptyColumn
      image="attributes2"
      title="Billing items"
      text={getTranslation('billing_items.no_billing_item_templates')}
    />
  );
};

export default observer(BillingItemsContent);
