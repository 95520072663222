import moment from 'moment';

import {
  AdvancePeriodType,
  BillingCompletenessType,
  RequiredQuantityType
} from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';

export const isRefreshStatus = (status: billingCompletenessStatus) => {
  return (
    status === 'calculating' ||
    status === 'gatheringinput' ||
    status === 'pendingcalculation' ||
    status === 'waitingfornextestimation'
  );
};

export const generateAdvanceBtnTitle = (amountOfPeriods: number, advancePeriod: AdvancePeriodType, index: number) => {
  switch (amountOfPeriods) {
    case 12:
      return moment(advancePeriod.startDateTime).add(15, 'days').format('MMM');
    case 4:
      return `Q${index + 1}\u00A0\u00A0\u00A0${moment(advancePeriod.startDateTime).format('MMM')} - ${moment(
        advancePeriod.endDateTime
      ).format('MMM')}`;
    case 2:
      return `${moment(advancePeriod.startDateTime).format('MMM')} - ${moment(advancePeriod.endDateTime).format(
        'MMM'
      )}`;
    default:
      return moment(advancePeriod.startDateTime).format('MMM');
  }
};

export const getAdvanceStatus = (ap: AdvancePeriodType) => {
  const today = moment();

  if (!ap.invoiceNum && ap.invoiceId) {
    return 'created';
  } else if (moment(ap.startDateTime).isAfter(today)) {
    return 'inFuture';
  } else if (ap.manuallyDeleted) {
    return 'manuallyDeleted';
  } else if (ap.automaticallyDeleted) {
    return 'automaticallyDeleted';
  } else if (ap.paid) {
    return 'paid';
  } else if (!ap.invoiceId && !ap.automaticallyDeleted && moment(ap.startDateTime).isBefore(today)) {
    return 'missing';
  } else if (ap.invoiceNum && ap.sentStatus === 'notsent') {
    return 'generated';
  } else if (ap.sentStatus === 'skipped') {
    return 'advSkipped';
  } else if (ap.sentStatus === 'sent') {
    return 'sent';
  }
  return '';
};

export const checkInvoiceButton = (currentCompleteness: BillingCompletenessType) => {
  return (
    currentCompleteness.status === 'gatheringinput' ||
    currentCompleteness.status === 'pendingcalculation' ||
    currentCompleteness.status === 'calculating' ||
    currentCompleteness.status === 'previousnotclosed' ||
    currentCompleteness.blocked
  );
};

export const checkAdvanceButton = (currentCompleteness: BillingCompletenessType) => {
  return currentCompleteness.advancePeriods.findIndex((ap) => !ap.automaticallyDeleted && !ap.invoiceId) !== -1;
};

export const checkIntermediateInvoiceButton = (currentCompleteness: BillingCompletenessType) => {
  return (
    currentCompleteness.status === billingCompletenessStatus.waiting ||
    currentCompleteness.status === billingCompletenessStatus.previousnotclosed ||
    currentCompleteness.status === billingCompletenessStatus.manuallycorrected ||
    currentCompleteness.status === billingCompletenessStatus.inputmissing
  );
};

export const getAllRequiredQuantitiesForCompleteness = (completeness: BillingCompletenessType) => {
  const allRequiredQuantities: RequiredQuantityType[] = [];

  const { contractRequiredQuantities, locationRequiredQuantities } = completeness.requiredQuantitiesGrouped;

  if (contractRequiredQuantities) {
    allRequiredQuantities.push(
      ...contractRequiredQuantities.reduce((acc: RequiredQuantityType[], crq) => {
        if (crq.requiredQuantities) {
          acc.push(...crq.requiredQuantities);
        }

        return acc;
      }, [])
    );
  }

  if (locationRequiredQuantities) {
    allRequiredQuantities.push(
      ...locationRequiredQuantities.reduce((acc: RequiredQuantityType[], lrq) => {
        if (lrq.requiredQuantities) {
          acc.push(...lrq.requiredQuantities);
        }

        return acc;
      }, [])
    );
  }

  return allRequiredQuantities;
};
