import { moveOutWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-out/MoveOutWizardStore';
import { WizardStep } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

const MoveOutWizardNavigation = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { moveOutWizardNavigator } = contractStore.moveOutWizardBaseStore.moveOutWizardStore;
  const { activeStep, stepValidations, setActiveStep } = moveOutWizardNavigator;

  return (
    <>
      <WizardStep
        headerId={moveOutWizardSteps.moveout}
        text={getTranslation('contracts.wizard.terminate.steps.move_out')}
        isActive={activeStep === moveOutWizardSteps.moveout}
        stepValidation={stepValidations[moveOutWizardSteps.moveout]}
        setIsActive={() => setActiveStep(moveOutWizardSteps.moveout)}
      />
      <WizardStep
        headerId={moveOutWizardSteps.movein}
        text={getTranslation('contracts.wizard.terminate.steps.move_in')}
        isActive={activeStep === moveOutWizardSteps.movein}
        stepValidation={stepValidations[moveOutWizardSteps.movein]}
        setIsActive={() => setActiveStep(moveOutWizardSteps.movein)}
      />
    </>
  );
};

export default observer(MoveOutWizardNavigation);
