import classNames from 'classnames';
import React from 'react';

import { OutputChannelType } from '@zf/api-types/property-metering-configuration';
import useDialog from '@zf/hooks/src/useDialog';
import { colors } from '@zf/utils/src/color';

import DeleteOutputChannelDialog from '../../../../../../actions/property-group/delete-output-channel-dialog';
import { useAppContext } from '../../../../../../app-context/app-context';
import { DeleteIcon, Icon } from '../../../../../../components/Icon';
import { BaseButton } from '../../../../../../design-system/Components';
import { dialog } from '../../../../../../events/dialog-events';
import { notify } from '../../../../../../events/notification-events';
import useMeteringData from '../../hooks/use-metering-data';
import css from './calculation-output.module.scss';

type Props = {
  outputChannel: OutputChannelType;
  isDirty: boolean;
  enableDelete: boolean;
};

export default function CalculationOutput(props: Props) {
  const { outputChannel, isDirty, enableDelete } = props;

  const { i18n } = useAppContext();
  const {
    selectedOutputChannel,
    propertyMeteringConfiguration,
    setPropertyMeteringConfiguration,
    setSelectedOutputChannel
  } = useMeteringData();
  const { clickRef, onSubmit } = useDialog();

  const openDeleteDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.metering.delete_calculation_header'),
      icon: 'trashcan',
      content: (
        <DeleteOutputChannelDialog
          ref={clickRef}
          outputChannel={outputChannel}
          propertyMeteringConfiguration={propertyMeteringConfiguration}
          setPropertyMeteringConfiguration={setPropertyMeteringConfiguration}
        />
      ),
      buttonPositive: i18n.getTranslation('general.delete'),
      type: 'danger',
      onSubmit
    });
  };

  const hasError =
    outputChannel.validationResult && outputChannel.validationResult?.functionValidationErrors.length > 0;

  return (
    <div className={css['output-channel-wrapper']}>
      <BaseButton
        id={`select-outputchannel-${outputChannel.id}`}
        className={classNames(css['button-output-channel'], {
          [css['selected']]: selectedOutputChannel && selectedOutputChannel.id === outputChannel.id,
          [css['error']]: hasError
        })}
        onClick={() => {
          if (!isDirty) {
            setSelectedOutputChannel(outputChannel);
          } else {
            notify.warning({
              content: i18n.getTranslation('property_groups.tabs.billing.validate_first')
            });
          }
        }}
      >
        {outputChannel.name}
      </BaseButton>
      {hasError && <Icon type="exclamation" color={colors['red-600']} />}
      {enableDelete && (
        <div className={css['delete-icon']}>
          <DeleteIcon
            id={`property_group_metering_config.delete.index-${outputChannel.id}`}
            onClick={openDeleteDialog}
          />
        </div>
      )}
    </div>
  );
}
