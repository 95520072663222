import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import { areEqual, VariableSizeList } from 'react-window';

import useSessionStorage from '@zf/hooks/src/useSessionStorage';
import { InternalColumnType, RowProps, RowTypeBase } from '@zf/stella-react/src/atoms/Table';
import {
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_ROW_HEIGHT_NEW,
  DEFAULT_ROW_OVERSCAN,
  DEFAULT_SCROLLBAR_WIDTH
} from '@zf/stella-react/src/atoms/Table/constants';
import useDynamicRowHeightCache from '@zf/stella-react/src/atoms/Table/hooks/useDynamicRowHeightCache';
import useTableRowHandlers from '@zf/stella-react/src/atoms/Table/hooks/useTableRowHandlersNew';
import TableOverlay from '@zf/stella-react/src/atoms/Table/table-overlay';
import { getTooltipContent, rebuildTooltip } from '@zf/utils/src/tooltip';

import { useStore } from '../../../hooks/useStore';
import css from './fixed-virtual-table.module.scss';
import HeaderCell from './HeaderCell';
import TableRow from './TableRow';
import { DynamicVirtualTablePropsCommon } from './DynamicVirtualTablePropsCommon';

type Props = {
  height: number;
  width: number;
  scrollBarWidth?: number;
};

const FixedVirtualTable = <T extends RowTypeBase>(props: DynamicVirtualTablePropsCommon<T> & Props) => {
  const {
    store,
    setListPage,
    columns,
    rows,
    hideHeader = false,
    singleSelect = false,
    error,
    loading,
    noData,
    selectedRows = [],
    id,
    sortBy,
    sortDirection,
    invert = false,
    dynamicRowHeight = false,
    noSelect = false,
    noDeselect = false,
    height,
    clearOnChange = false,
    singleSort,
    totalAmountOfRows = 0,
    selectAllBusy,
    showCheckBoxes = true,
    sortableFields = [],
    leftIndent,
    LoadingOverlay,
    ErrorOverlay,
    NoDataOverlay,
    sort,
    onSelectRow,
    onRowsRendered = () => {},
    enableRowActivation = true,
    localTimeStamp,
    timeStamp,
    enableCaching = false,
    cursorPointer = false,
    hideCounter = false,
    tooltipId
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  //TODO - create hook for useActivateRows()

  const [activatedRows, setActivatedRows] = useState<string[]>([]);

  const handleActivateRows = (ids: string[]) => {
    setActivatedRows(ids);

    const newListPage = {
      ...store?.listPage,
      activatedIds: ids,
      activatedRows: rows.filter(({ __id }) => ids.includes(__id) === true),
      showOnActivate: rows.filter(({ __id }) => ids.includes(__id) === true).length > 0
    };

    const newCurrent = { ...store, listPage: newListPage };
    if (store && setListPage) {
      setListPage(newCurrent.listPage as never);
    }
  };

  let {
    width,
    overscanRowCount = DEFAULT_ROW_OVERSCAN,
    rowHeight = DEFAULT_ROW_HEIGHT_NEW,
    scrollBarWidth = DEFAULT_SCROLLBAR_WIDTH,
    headerHeight = 40
  } = props;

  const { listRef, scaleTranslator, dynamicRowHeightCache, dispatchDynamicHeight } = useDynamicRowHeightCache(
    rows,
    dynamicRowHeight,
    clearOnChange
  );

  const { handleSelectRow, handleActivateRow, handleItemsRendered, handleSelectAll } = useTableRowHandlers(
    rows,
    selectedRows,
    activatedRows,
    totalAmountOfRows,
    noSelect,
    noDeselect,
    singleSelect,
    onSelectRow,
    onRowsRendered,
    handleActivateRows
  );

  const { setItem, getItem, removeItem } = useSessionStorage();

  // Compensate for border
  width = width - 1;

  overscanRowCount = overscanRowCount * scaleTranslator;
  rowHeight = rowHeight * scaleTranslator;
  headerHeight = headerHeight * scaleTranslator;
  scrollBarWidth = scrollBarWidth * scaleTranslator;

  let counterColumnWidth = DEFAULT_COLUMN_WIDTH;

  if (hideHeader) {
    counterColumnWidth = 40 * scaleTranslator;
  } else if (leftIndent) {
    counterColumnWidth = DEFAULT_COLUMN_WIDTH + leftIndent * 5;
  }
  const renderedColumns: InternalColumnType[] = (
    !noSelect && !hideCounter && showCheckBoxes
      ? [
          {
            label: selectAllBusy ? (
              getTranslation('virtual-table.loading')
            ) : (
              <div style={{ display: 'inline' }} className={classNames(css['sortable-fields'])}>{`${
                selectedRows.length
              } ${getTranslation('virtual-table.of')} ${totalAmountOfRows}`}</div>
            ),

            dataKey: `__checkbox`,
            width: counterColumnWidth
          },
          ...columns
        ]
      : !showCheckBoxes
      ? [
          {
            label: '',
            dataKey: '__empty',
            width: 20 // Sorting needs some margin to work here
          },
          ...columns
        ]
      : columns
  ).map((column) => {
    return { ...column, width: column.width ? column.width * scaleTranslator : DEFAULT_COLUMN_WIDTH * scaleTranslator };
  });

  const totalWidth = renderedColumns.reduce((acc, column) => acc + column.width * scaleTranslator, 0);

  let tableWidth = totalWidth;
  if (totalWidth < width - scrollBarWidth) {
    renderedColumns[renderedColumns.length - 1].width += width - scrollBarWidth - totalWidth;
    tableWidth = width;
  }

  const handleSort = (params: { dataKey: string; sortBy: string; sortEnabled?: boolean }) => {
    if (!sortBy || !sortDirection || !params.sortEnabled) return;

    let sortByClone = [...sortBy];
    let sortDirectionClone = { ...sortDirection };

    if (singleSort && !sortByClone.includes(params.dataKey) && sortByClone.length === 1) {
      sortDirectionClone = {};
      sortByClone = [];
    }

    if (sortByClone.includes(params.dataKey)) {
      if (sortDirectionClone[params.sortBy] === 'ASC') {
        sortDirectionClone[params.sortBy] = 'DESC';
      } else {
        sortByClone.splice(sortByClone.indexOf(params.dataKey), 1);
        delete sortDirectionClone[params.sortBy];
      }
    } else {
      sortByClone.push(params.dataKey);
      sortDirectionClone[params.sortBy] = 'ASC';
    }

    if (sort) {
      sort({
        sortBy: sortByClone,
        sortDirection: sortDirectionClone
      });
    }
  };

  const innerElementType = forwardRef<HTMLDivElement>((props_, ref) => {
    const { children, ...otherProps } = props_;

    let indent = 0;
    const styleObject = { height: headerHeight };

    const header = !hideHeader ? (
      <div
        className={classNames(css['virtual-table-header'], { [css['invert']]: invert })}
        style={styleObject}
        role="row"
      >
        {renderedColumns.map((column, index) => {
          const HeaderComponent = HeaderCell({
            id,
            index,
            column,
            sortableFields,
            sortDirection,
            sortBy,
            noSelect,
            handleSelectAll,
            handleSort,
            scaleTranslator,
            indent,
            leftIndent,
            dynamicRowHeight,
            tooltipId
          });
          indent += column.width * scaleTranslator;

          return HeaderComponent;
        })}
      </div>
    ) : null;

    return (
      <div ref={ref} {...otherProps}>
        {header}
        {children}
      </div>
    );
  });

  const renderRow = (props_: RowProps) => {
    const { index, style } = props_;

    const rowData = rows[index];
    if (!rowData.__id) throw new Error('row.__id should be defined!');

    const isSelected = noSelect ? false : selectedRows.includes(rowData.__id);

    const isMatched =
      typeof rowData?.matched !== 'undefined' && rowData.matched.length > 0
        ? rowData.matched?.includes(activatedRows[0])
        : false;
    const processedStyle = {
      ...style,
      top: !hideHeader && typeof style.top === 'number' ? style.top + headerHeight : style.top
    };

    return (
      <TableRow
        {...props}
        id={id}
        key={id}
        index={index}
        hideHeader={hideHeader}
        dynamicRowHeight={dynamicRowHeight}
        tooltipId={tooltipId}
        leftIndent={leftIndent}
        rowData={rowData}
        isSelected={isSelected}
        isActivated={isSelected}
        isMatched={isMatched}
        style={processedStyle}
        renderedColumns={renderedColumns}
        invert={invert}
        cursorPointer={cursorPointer}
        scaleTranslator={scaleTranslator}
        handleSelectRow={handleSelectRow}
        handleActivateRow={enableRowActivation ? handleActivateRow : () => {}}
      />
    );
  };

  const adjustHeightToAllChildren = (node: Element, baseHeight: number, id_: string) => {
    //@ts-ignore
    for (const child of node.children) {
      adjustHeightToAllChildren(child, baseHeight, id_);

      if (child.clientHeight > baseHeight) {
        // Just overriding baseHeight doesn't work which is gay, only way got it to work was using sessionstorage
        setItem(`rowHeight-${id_}`, child.clientHeight);
        baseHeight = child.clientHeight;
      }
    }
  };

  const getItemSize = (index: number) => {
    if (!dynamicRowHeight) {
      return rowHeight;
    }

    const id_ = rows[index].__id;
    if (!id_) {
      throw new Error('__id is a required prop for rows and breaks the virtualTable if not defined!');
    }
    if (dynamicRowHeightCache[id_]) {
      return dynamicRowHeightCache[id_];
    } else {
      // Calculate rowHeight and cache it...
      const wrapperNode = document.createElement('div');
      wrapperNode.style.position = 'fixed';
      wrapperNode.style.top = '-10rem';

      const node = document.createElement('div');
      node.style.position = 'relative';
      node.style.display = 'block';

      wrapperNode.append(node);

      document.body.append(wrapperNode);
      const rowNode = renderRow({ index, style: {}, calcMode: true });
      ReactDOM.render(rowNode, node, () => {
        // Go through all children recursively and set rowheight to the highest one found
        adjustHeightToAllChildren(node, rowHeight, id_);
        const offsetHeight = getItem(`rowHeight-${id_}`, rowHeight);

        document.body.removeChild(wrapperNode);

        dispatchDynamicHeight({
          type: 'update',
          index: id_,
          height: offsetHeight
        });
      });

      return rowHeight;
    }
  };

  // Cleanup session storage on unmount
  useEffect(() => {
    return () => rows.forEach((r) => removeItem(`rowHeight-${r.__id}`));
  }, []);

  useEffect(() => {
    rebuildTooltip();
  });

  const table = (
    <>
      <TableOverlay
        LoadingOverlay={LoadingOverlay}
        ErrorOverlay={ErrorOverlay}
        NoDataOverlay={NoDataOverlay}
        error={error}
        loading={loading}
        noData={rows.length === 0 || noData}
        width={width}
        height={height}
      />
      <VariableSizeList
        width={width}
        height={height}
        innerElementType={innerElementType}
        itemCount={rows.length}
        estimatedItemSize={rowHeight}
        itemSize={getItemSize}
        overscanCount={overscanRowCount}
        onItemsRendered={handleItemsRendered}
        onScroll={rebuildTooltip}
        ref={listRef}
      >
        {memo(renderRow, areEqual)}
      </VariableSizeList>

      {rows.length > 0 && tooltipId && (
        <ReactTooltip
          id={tooltipId}
          className={css['simple-tooltip']}
          type="light"
          place="left"
          getContent={getTooltipContent}
        />
      )}
    </>
  );

  if (enableCaching) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      return table;
    }, [
      error,
      loading,
      width,
      tableWidth,
      height,
      rowHeight,
      overscanRowCount,
      listRef,
      rows.length,
      selectedRows,
      scaleTranslator,
      sortBy,
      sortDirection,
      localTimeStamp,
      timeStamp
    ]);
  } else {
    return table;
  }
};

export default observer(FixedVirtualTable);
