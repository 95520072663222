import { AddressType, LocalAddressType } from '@zf/api-types/general';

import { EnumKeyType } from '../app-context/hooks/use-enum';

export function formatAddress(
  address: AddressType | LocalAddressType | null,
  getEnumTranslation: (enumKey: EnumKeyType, symbol: string) => string
) {
  let formatted = '';

  if (address) {
    // If only country is provided
    if (!address.city && !address.postalCode && !address.streetName && !address.streetNumber && !!address.country) {
      return getEnumTranslation('countryCode', address.country);
    } else {
      //ADD logic to detect Country and chose a format
      //Future proof: add more cases
      switch (address.country) {
        case 'fra':
          formatted = frenchAddressFormat(address);
          break;

        default:
          formatted = basicAddressFormat(address);
          break;
      }
    }
  }

  return formatted;
}

export function formatAddressForQuery(address: AddressType | LocalAddressType) {
  return `${address.streetNumber}${address.streetNumberAddition || ''} ${address.streetName}, ${
    address.city
  } ${address.country.toUpperCase()}`;
}

export const addressIsValid = (address: AddressType | LocalAddressType | undefined) => {
  if (address) {
    return address.streetName && address.streetNumber && address.city && address.postalCode && address.country;
  }

  return false;
};

//FUTURE PROOF (In case any other countries have different address formats)

const basicAddressFormat = (address: AddressType | LocalAddressType) => {
  let concatAddress = '';

  if (address.streetName) {
    concatAddress += address.streetName;
  }

  if (address.streetNumber) {
    concatAddress += ` ${address.streetNumber}`;
  }

  if (address.streetNumberAddition) {
    concatAddress += `-${address.streetNumberAddition}`;
  }

  // If we have a first part and a second part follows
  if (!!concatAddress && (address.postalCode || address.city || address.country)) {
    concatAddress += `,`;
  }

  if (address.postalCode) {
    concatAddress += ` ${address.postalCode}`;
  }

  if (address.city) {
    concatAddress += ` ${address.city}`;
  }

  concatAddress += ` (${address.country?.toUpperCase()})`;

  return concatAddress;
};
const frenchAddressFormat = (address: AddressType | LocalAddressType) => {
  let concatAddress = '';

  if (address.streetNumber) {
    concatAddress += `${address.streetNumber}`;
  }

  if (address.streetName) {
    concatAddress += ` ${address.streetName}`;
  }

  if (address.streetNumberAddition) {
    concatAddress += `-${address.streetNumberAddition}`;
  }

  // If we have a first part and a second part follows
  if (!!concatAddress && (address.postalCode || address.city || address.country)) {
    concatAddress += `,`;
  }

  if (address.postalCode) {
    concatAddress += ` ${address.postalCode}`;
  }

  if (address.city) {
    concatAddress += ` ${address.city}`;
  }

  concatAddress += ` (${address.country?.toUpperCase()})`;

  return concatAddress;
};
