import clone from 'clone';
import React from 'react';

import {
  dataFrequency,
  direction,
  incrementationType,
  meteringType,
  unitOfMeasure,
  utilityType
} from '@zf/api-types/enums';
import { ChannelTemplateType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { StepAnchor, WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../../app-context';
import ChannelMultiValue from '../../../../components/meters/channel-multivalue';
import { CreateMeterType, STEP_NAMES } from './MeterWizard';
import css from './channel-section.module.scss';

export type Props = {
  index?: number;
  models: ModelType[];
  values: CreateMeterType;
  setValue: (
    value: Partial<CreateMeterType>,
    initialApiSet?: boolean | undefined,
    backup_?: boolean | undefined
  ) => void;
  handleFocus: (step: string) => () => void;
};

export const initialChannel: ChannelTemplateType = {
  dataFrequency: '' as dataFrequency,
  incrementationType: '' as incrementationType,
  meteringType: 'none' as meteringType,
  utilityType: 'none' as utilityType,
  direction: '' as direction,
  unitOfMeasure: 'none' as unitOfMeasure,
  timeOfUse: '',
  description: '',
  externalIdentifier: '',
  relationExternalReference: ''
};

const enrichTemplatesWithReference = (channelTemplates: ChannelTemplateType[]) => {
  const templatesClone = clone(channelTemplates);

  templatesClone.forEach((t) => {
    t.relationExternalReference = '';
  });

  return templatesClone;
};

export default function ChannelsSection(props: Props) {
  const { index, models, values, setValue, handleFocus } = props;
  const { i18n } = useAppContext();

  const foundModel = models.find((m) => m.id === values.modelId);

  const channelTemplates =
    foundModel && foundModel.channelTemplates
      ? enrichTemplatesWithReference(foundModel.channelTemplates)
      : [initialChannel];

  return (
    <WizardSection id={`channel-section-${index}`}>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[1]} />
        {i18n.getTranslation('meter.steps.channels')}
      </WizardHeader>
      <InputContainer className={css['channel-section-input-container']} grid={false}>
        {values.modelId ? (
          <ChannelMultiValue
            title={i18n.getTranslation('meter.channels_wizard')}
            initialValues={channelTemplates}
            onChange={(channels) => setValue({ channels: channels })}
            onFocus={handleFocus(STEP_NAMES[1])}
          />
        ) : (
          <>
            <Heading headingType="h3" style="bold">
              {i18n.getTranslation('meter.channels_wizard')}
            </Heading>
            <InputContainer grid={false}>
              <Paragraph>{i18n.getTranslation('meter.wizard_channel_select')}</Paragraph>
            </InputContainer>
          </>
        )}
      </InputContainer>
    </WizardSection>
  );
}
