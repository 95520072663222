import { TabSlider } from 'design-system/Components';
import { SelectionTabItemType } from 'design-system/Components/TabSlider/Tab';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { meteringIssueStatus } from '@zf/api-types/enums';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import GroupbyFilter from '../Components/Filter/GroupbyFilter';
import { useIssuesContext } from '../Context/Context';
import { Query } from './types';
import css from './left-filters.module.scss';

type Props = {
  getValues: UseFormGetValues<Partial<Query>>;
  setValue: UseFormSetValue<Partial<Query>>;
  overviewCount: Record<string, number>;
};

const LeftFilters: FC<Props> = ({ getValues, setValue, overviewCount }) => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const ctx = useIssuesContext();
  const { quickFilter } = ctx.store.filter;

  const tabItems: SelectionTabItemType<{ all: number; unresolved: number }>[] = [
    {
      id: 'unresolved',
      count: overviewCount.unresolved,
      title: getEnumTranslation('meteringIssueStatus', meteringIssueStatus.unresolved)
    },
    { id: 'all', count: overviewCount.all, title: getTranslation('general.all') }
  ];

  return (
    <FlexElements gap="16">
      <TabSlider
        id="meter-issues-quick-filter"
        tabItems={tabItems}
        // @ts-ignore
        selectedTabItem={quickFilter}
        setSelectedItem={(itemId) => {
          if (ctx.store.filter.quickFilter !== itemId) setValue('quickFilter', itemId);
        }}
      />
      <div className={css['left-filter-bare-dropdowns']}>
        <GroupbyFilter getValues={getValues} setValue={setValue} />
      </div>
    </FlexElements>
  );
};

export default observer(LeftFilters);
