import clone from 'clone';
import moment from 'moment';
import React, { useState } from 'react';

import { CustomEntityPropertyValue } from '@zf/api-types/config/custom-entity-property-types';
import { CardBody, CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { DeleteIcon } from '../../components/Icon';
import { InlineDatePicker } from '../../components/Lang/DatePicker';
import DynamicIndexTable from '../../components/Lang/DynamicIndexTable';
import { useStore } from '../../hooks/useStore';
import { ValidatorType } from './CustomEntityPropertiesCard';
import Footer from './Footer';
import css from './style.module.scss';
import TableValueInput from './TableValueInput';

type Props = {
  isAdding: boolean;
  values: ValidatorType;
  setValue: (value: Partial<ValidatorType>, initialApiSet?: boolean, backup?: boolean) => void;
  handleSave: () => void;
};

export function BodyContent(props: Props) {
  const { values, isAdding, setValue, handleSave } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [tableColumns] = useState<ColumnType[]>([
    {
      label: getTranslation('general.name'),
      dataKey: 'name',
      flexWidth: 2
    },
    {
      label: getTranslation('general.start'),
      dataKey: 'startDateTime',
      flexWidth: 2
    },
    {
      label: getTranslation('general.end'),
      dataKey: 'endDateTime',
      flexWidth: 2
    },
    {
      label: getTranslation('general.value'),
      dataKey: 'value',
      flexWidth: 1
    },
    {
      width: 50,
      dataKey: 'deleteIcon'
    }
  ]);

  const handleDelete = (customPropertyIndex: number, valueIndex: number) => {
    return () => {
      const cloned = clone(values);

      if (cloned.customEntityProperties[customPropertyIndex].values) {
        cloned.customEntityProperties[customPropertyIndex].values.splice(valueIndex, 1);

        if (cloned.customEntityProperties[customPropertyIndex].values.length === 0) {
          cloned.customEntityProperties.splice(customPropertyIndex, 1);
        }

        setValue({ ...cloned });
      }
    };
  };

  const setCustomProperty = (
    customPropertyIndex: number,
    valueIndex: number,
    value: Partial<CustomEntityPropertyValue>
  ) => {
    const clonedArray = clone(values.customEntityProperties);
    clonedArray[customPropertyIndex].values[valueIndex] = {
      ...clonedArray[customPropertyIndex].values[valueIndex],
      ...value
    };
    setValue({ customEntityProperties: clonedArray });
  };

  const content: JSX.Element[] = [];

  if (values.customEntityProperties.length === 0) {
    content.push(
      <CardEmptyBody
        key="no-custom-properties"
        image={
          <img
            src="https://cdn.zerofriction.co/shared/assets/emptyStates/attributes2.png"
            alt="Custom properties"
            title="Custom properties"
            width="70"
            height="70"
          />
        }
        title={getTranslation('settings.no_custom_entity_properties')}
      >
        {getTranslation('general.no_items_desc')}
      </CardEmptyBody>
    );
  } else {
    const rows = [];
    for (let index = 0; index < values.customEntityProperties.length; index++) {
      const entityProperty = values.customEntityProperties[index];

      rows.push(
        ...entityProperty.values.map((value, valueIndex) => {
          return {
            name: valueIndex === 0 && <Paragraph className={css['name']}>{entityProperty.type?.name}</Paragraph>,
            startDateTime: (
              <InlineDatePicker
                id={`entity_property.startDateTime.index-${index}-${valueIndex}`}
                value={moment(value.startDateTime)}
                onChange={(val) =>
                  setCustomProperty(index, valueIndex, { startDateTime: val ? val.toISOString() : MIN_DATE })
                }
              />
            ),
            endDateTime: (
              <InlineDatePicker
                id={`entity_property.endDateTime.index-${index}-${valueIndex}`}
                value={moment(value.endDateTime)}
                onChange={(val) =>
                  setCustomProperty(index, valueIndex, { endDateTime: val ? val.toISOString() : MAX_DATE })
                }
              />
            ),
            value: (
              <TableValueInput
                index={index}
                valueIndex={valueIndex}
                entityProperty={entityProperty}
                value={value}
                setCustomProperty={setCustomProperty}
                handleSave={handleSave}
              />
            ),
            deleteIcon: (
              <DeleteIcon
                id={`entity_property.delete.index-${index}`}
                tooltipFor="entity-properties-table"
                onClick={handleDelete(index, valueIndex)}
              />
            )
          };
        })
      );
    }

    content.push(
      <DynamicIndexTable
        key="entity-properties-table"
        tooltipId="entity-properties-table"
        rows={rows}
        columns={tableColumns}
      />
    );
  }

  return (
    <>
      {content ? (
        <CardBody type="indexTable" fixedHeight>
          {content}
        </CardBody>
      ) : null}
      {isAdding && <Footer values={values} setValue={setValue} handleSave={handleSave} />}
    </>
  );
}
