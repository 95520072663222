import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import EmptyChartState from '@zf/stella-react/src/atoms/Chart/EmptyChartState';

import { useAppContext } from '../../app-context';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { SuspenseCardBody } from '../../components/suspense';
import { useStore } from '../../hooks/useStore';
import useIntegrationLogging from './hooks/use-integration-logging';
import IntegrationLoggingCardExtra from './IntegrationLoggingCardExtra';

type Props = {
  subjectId: string;
  refresh?: boolean;
  subjectType: string;
};

const IntegrationLoggingCard = observer((props: Props) => {
  const { subjectId, refresh = true, subjectType } = props;
  const { authReducer } = useAppContext();
  const { isSuperUser } = authReducer;
  const { integrationStore, applicationStore } = useStore();
  const { selectedIds, setSelectedIds } = integrationStore.integrationLoggingStore;

  const {
    loading,
    error,
    rows,
    sortableFields,
    sortBy,
    sortDirection,
    columns,
    filterInputs,
    dropdownFormats,
    totalAmountOfRows,
    resetFilter,
    handleSort
  } = useIntegrationLogging(subjectType, subjectId, refresh);

  if (!isSuperUser()) return null;

  return (
    <Card id="integration-logging-card" width="3" className='card' role='dialog'>
      <CardHeader
        extraRight={
          <IntegrationLoggingCardExtra
            filterInputs={filterInputs}
            dropdownFormats={dropdownFormats}
            refresh={refresh}
            resetFilter={resetFilter}
          />
        }
      >
        {applicationStore.getTranslation('cards.integration_logging.integration_logging')}
      </CardHeader>
      <SuspenseCardBody fixedHeight>
        <DynamicVirtualTable
          id="plugintrigger-table"
          tooltipId="integration_logging-table-tip"
          sortBy={sortBy}
          sortDirection={sortDirection}
          sortableFields={sortableFields}
          sort={handleSort}
          singleSelect
          rows={rows || []}
          totalAmountOfRows={totalAmountOfRows}
          loading={loading}
          onSelectRow={setSelectedIds}
          selectedRows={selectedIds}
          error={error}
          columns={columns}
          NoDataOverlay={() => (
            <EmptyChartState title={applicationStore.getTranslation('cards.integration_logging.no_logs')} />
          )}
        />
      </SuspenseCardBody>
    </Card>
  );
});

export default IntegrationLoggingCard;
