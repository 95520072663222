import { isMinDate, isMaxDate } from '@zf/utils/src/date';
import moment, { Moment } from 'moment';

export function getPeriodicFileNameFormat(
  startDate: Moment | string | undefined | null,
  endDate: Moment | string | undefined | null
) {
  //Cases for Time Period Selection
  let fileNameStartTag = '';
  let fileNameEndTag = '';
  let isStartTagTranslatable = false;

  //Start date not defined
  if (isMinDate(startDate)) {
    //End date not defined
    if (isMaxDate(endDate)) {
      fileNameStartTag = 'general.total';
      fileNameEndTag = moment().toISOString();
      isStartTagTranslatable = true;
    } else {
      fileNameStartTag = 'general.end';
      fileNameEndTag = moment(endDate).toISOString();
      isStartTagTranslatable = true;
    }
  } else {
    if (isMaxDate(endDate)) {
      fileNameStartTag = 'general.start';
      fileNameEndTag = moment(startDate).toISOString();
      isStartTagTranslatable = true;
    } else {
      fileNameStartTag = moment(startDate).format('DDMMYY');
      fileNameEndTag = moment(endDate).toISOString();
      isStartTagTranslatable = false;
    }
  }

  return { fileNameStartTag, fileNameEndTag, isStartTagTranslatable };
}
