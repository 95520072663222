import moment from 'moment';
import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';
import { isMinDate } from '@zf/utils/src/date';

import DatePicker from '../../../../../../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../../../events/notification-events';
import { useStore } from '../../../../../../../hooks/useStore';
import css from './create-invoice-dialog.module.scss';

type Props = {
  completeness: BillingCompletenessType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, completeness } = props;

  const { applicationStore, contractStore } = useStore();
  const { createOrRecalculate } = contractStore.billingCompletenessStore;

  const [advanceUntilDateTime, setAdvanceUntilDateTime] = React.useState(moment());

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(isMinDate(advanceUntilDateTime));
    }
  };

  React.useEffect(() => {
    validate();
  }, [advanceUntilDateTime]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        createOrRecalculate(completeness.id, true, false, advanceUntilDateTime);
        notify.success({
          content: applicationStore.getTranslation('invoice.advance_successful')
        });
      } catch (e) {
        notify.error({
          content: applicationStore.getTranslation('invoice.advance_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>{applicationStore.getTranslation('invoice.generate_adv_desc')}</Paragraph>
      <InputContainer grid={false}>
        <Center>
          <DatePicker
            id="advance-until-date"
            className={css['advance-until']}
            placeholder={applicationStore.getTranslation('invoice.advance_until')}
            onChange={setAdvanceUntilDateTime}
            value={advanceUntilDateTime}
          />
        </Center>
      </InputContainer>
    </>
  );
});
