import { Button } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MeteringIssueError } from '@zf/api-types/enums';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { useMeterIssuesContext } from '../../../Context/Context';

type Props = {
  issueId: string;
  errorType: MeteringIssueError;
};

const ResolveSingleIssuePanelHeader = (props: Props) => {
  const { issueId, errorType } = props;

  const Context = useMeterIssuesContext();
  const { goToPreviousRow, goToNextRow, closeSidePanel, store } = Context;
  const { rows } = store.listPage;

  const { applicationStore } = useStore();
  const { getEnumTranslation } = applicationStore;

  return (
    <FlexElements justifyContent="space-between">
      <Heading headingType="h3">{getEnumTranslation('meteringIssueError', errorType)}</Heading>
      <FlexElements gap="16">
        {/* Hide navigation arrows if just a single row is listed */}
        {rows.length > 1 && (
          <FlexElements>
            <Button
              id="issues.previous"
              icon="chevron-left"
              onClick={() => goToPreviousRow(issueId)}
              type="text"
              noSidePadding
            />
            <Button
              id="issues.next"
              icon="chevron-right"
              onClick={() => goToNextRow(issueId)}
              type="text"
              noSidePadding
            />
          </FlexElements>
        )}
        <Button id="issues.close_panel" icon="cross" onClick={closeSidePanel} type="text" danger noSidePadding />
      </FlexElements>
    </FlexElements>
  );
};

export default observer(ResolveSingleIssuePanelHeader);
