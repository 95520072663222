import clone from 'clone';
import React from 'react';

import { formulaFunctionCategory, formulaFunctionInputParameterReferenceType } from '@zf/api-types/enums';
import {
  CreateFormulaFunctionType,
  FormulaFunctionInputParameterType,
  FunctionType
} from '@zf/api-types/property-group-billing-configuration';
import {
  FormulaFunctionExceptionType,
  FormulaValidationResultFunctionValidationError
} from '@zf/api-types/property-metering-configuration';

import { useAppContext } from '../../../../app-context';
import Formula from './formula';
import css from './formula-builder.module.scss';
import Functions from './functions';
import ConfigColumn from '../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../components/Column/EmptyColumn';

type Props = {
  formulaFunctions: CreateFormulaFunctionType[];
  itemName: string;
  hasSelection: boolean;
  emptyStateMessage: string;
  functions: FunctionType[];
  actions: JSX.Element | undefined;
  runtimeParameters: string[];
  functionValidationErrors: FormulaValidationResultFunctionValidationError[];
  calculationErrors?: FormulaFunctionExceptionType[];
  setFormulaFunctions: (formulaFunctions: CreateFormulaFunctionType[]) => void;
};

export default function FormulaBuilder(props: Props) {
  const { i18n } = useAppContext();
  const {
    itemName,
    hasSelection,
    formulaFunctions,
    emptyStateMessage,
    functions,
    runtimeParameters,
    functionValidationErrors,
    actions,
    calculationErrors,
    setFormulaFunctions
  } = props;

  const addFormulaFunction = (category: formulaFunctionCategory, function_: FunctionType) => {
    const newFormulaFunction: CreateFormulaFunctionType = {
      functionCategory: category,
      functionType: function_.functionType,
      inputParameters: Object.keys(function_.inputParameters).map((p) => {
        return {
          name: p,
          referenceType: '' as formulaFunctionInputParameterReferenceType,
          referenceTypeParameters: { type: '' as formulaFunctionInputParameterReferenceType, value: '' }
        };
      }),
      outputParameterName: ''
    };

    setFormulaFunctions([...formulaFunctions, newFormulaFunction]);
  };

  const setOutputName = (formulaFunctionIndex: number, newName: string) => {
    const formulaFunctionsClone = clone(formulaFunctions) as Record<string, any>[];

    // Also adjust all references to this output name
    formulaFunctionsClone.forEach((func, funcIndex) => {
      //@ts-ignore
      func.inputParameters.forEach((param, paramIndex) => {
        if (
          param.referenceTypeParameters.outputParameterName ===
          formulaFunctionsClone[formulaFunctionIndex].outputParameterName
        ) {
          formulaFunctionsClone[funcIndex].inputParameters[paramIndex].referenceTypeParameters.outputParameterName =
            newName;
        }
      });
    });

    formulaFunctionsClone[formulaFunctionIndex].outputParameterName = newName;
    setFormulaFunctions(formulaFunctionsClone as CreateFormulaFunctionType[]);
  };

  const setReferenceType = (
    formulaFunctionIndex: number,
    inputParameterIndex: number,
    type: formulaFunctionInputParameterReferenceType
  ) => {
    const formulaFunctionsClone = clone(formulaFunctions) as Record<string, any>[];
    const inputParameters = formulaFunctionsClone[formulaFunctionIndex].inputParameters;
    inputParameters[inputParameterIndex].referenceType = type;
    inputParameters[inputParameterIndex].referenceTypeParameters.type = type;
    setFormulaFunctions(formulaFunctionsClone as CreateFormulaFunctionType[]);
  };

  const setFormulaFunctionValue = (
    formulaFunctionIndex: number,
    inputParameterIndex: number,
    key: keyof FormulaFunctionInputParameterType,
    value: FormulaFunctionInputParameterType[keyof FormulaFunctionInputParameterType]
  ) => {
    const formulaFunctionsClone = clone(formulaFunctions) as Record<string, any>[];
    formulaFunctionsClone[formulaFunctionIndex]['inputParameters'][inputParameterIndex][key] = value;
    setFormulaFunctions(formulaFunctionsClone as CreateFormulaFunctionType[]);
  };

  const removeFormulaFunction = (index: number) => {
    const formulaFunctionsClone = clone(formulaFunctions);
    formulaFunctionsClone.splice(index, 1);
    setFormulaFunctions(formulaFunctionsClone);
  };

  return (
    <ConfigColumn
      title={
        hasSelection
          ? i18n.getTranslation('property_groups.tabs.billing.formula_builder_w_item', {
              item: itemName
            })
          : i18n.getTranslation('property_groups.tabs.billing.formula_builder')
      }
      extraRight={actions}
      borderRight={false}
    >
      {hasSelection ? (
        <div className={css['formula-builder-wrapper']}>
          <Formula
            formulaFunctions={formulaFunctions}
            functions={functions}
            functionValidationErrors={functionValidationErrors || []}
            calculationErrors={calculationErrors || []}
            runtimeParameters={runtimeParameters}
            removeFormulaFunction={removeFormulaFunction}
            setFormulaFunctionValue={setFormulaFunctionValue}
            setReferenceType={setReferenceType}
            setOutputName={setOutputName}
          />
          <Functions functions={functions} addFormulaFunction={addFormulaFunction} />
        </div>
      ) : (
        <EmptyColumn image="cost-allocation2" title="Formula builder" text={emptyStateMessage} />
      )}
    </ConfigColumn>
  );
}
