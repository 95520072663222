import { Link } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { stringify } from 'query-string';
import React from 'react';

import css from '../accuracy-indicator.module.scss';
import { UPDATE } from '../types';

interface WrapperComponentProps {
  item: UPDATE;
  formData?: any;
  entityName: string;
  entityId: string;
  extraUrl?: string;
  onNavigate?: Function;
}

type ComponentProps = React.PropsWithChildren<WrapperComponentProps>;

export const Links = ({ item, formData, entityName, entityId, extraUrl, onNavigate }: ComponentProps) => {
  const { applicationStore } = useStore();
  const { getTranslation, rootUrl } = applicationStore;

  if (entityId === '' || entityName === '') {
    throw 'Entity ID or Entity Name not speficifed';
  }

  //Does a util function exist to "translate" this sort of entities?
  if (entityName === 'servicelocation') {
    entityName = 'locations';
  }
  if (entityName === 'propertygroup') {
    entityName = 'property-groups';
  }
  if (entityName === 'customer') {
    entityName = 'customers';
  }
  if (entityName === 'contract') {
    entityName = 'contracts';
  }
  if (entityName === 'meter') {
    entityName = 'devices/meters';
  }

  let pathToCustomEntityPropertyWidget = `/${entityName}/${entityId}`;

  if (extraUrl) {
    pathToCustomEntityPropertyWidget = `${pathToCustomEntityPropertyWidget}/${extraUrl}`;
  }

  if (formData) {
    pathToCustomEntityPropertyWidget = `${pathToCustomEntityPropertyWidget}?${stringify(formData)}`;
  }

  switch (item) {
    case UPDATE.TARIFF:
      return (
        <Link
          className={css['checkmark-link']}
          id="active-case"
          url={`${rootUrl}${pathToCustomEntityPropertyWidget}`}
          bold
          onNavigate={onNavigate}
        >
          {getTranslation('contracts.estimation_accuracy_update_tariffs')}
        </Link>
      );

    case UPDATE.TAX_RATES:
      return (
        <Link
          className={css['checkmark-link']}
          id="active-case"
          url={`${rootUrl}${pathToCustomEntityPropertyWidget}`}
          bold
          onNavigate={onNavigate}
        >
          {getTranslation('contracts.estimation_accuracy_update_tax_rates')}
        </Link>
      );

    case UPDATE.CEP_VALUE:
      return (
        <Link
          className={css['checkmark-link']}
          id="active-case"
          url={`${rootUrl}${pathToCustomEntityPropertyWidget}`}
          bold
          onNavigate={onNavigate}
        >
          {getTranslation('contracts.estimation_accuracy_update_property_value')}
        </Link>
      );
    case UPDATE.ATTRIBUTE:
      return (
        <Link
          className={css['checkmark-link']}
          id="active-case"
          url={`${rootUrl}${pathToCustomEntityPropertyWidget}`}
          bold
          onNavigate={onNavigate}
        >
          {getTranslation('contracts.estimation_accuracy_update_property_value')}
        </Link>
      );
    case UPDATE.CONSUMPTION_UNIT:
      return (
        <Link
          className={css['checkmark-link']}
          id="active-case"
          url={`${rootUrl}${pathToCustomEntityPropertyWidget}`}
          bold
          onNavigate={onNavigate}
        >
          {getTranslation('contracts.estimation_accuracy_update_property_value')}
        </Link>
      );

    default:
      return null;
  }
};
