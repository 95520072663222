import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { Card, CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { CommentCard, FeedCard } from '../../../../cards';
import ContractsServiceLocationCard from '../../../../cards/Contracts/contracts-service-location-card';
import CustomEntityPropertiesCard from '../../../../cards/CustomEntityProperties/CustomEntityPropertiesCard';
import IntegrationLoggingCard from '../../../../cards/Integration/IntegrationLoggingCard';
import LocationDetailCard from './location-detail-card';
import css from './location-detail.module.scss';
import MapLocationCard from './map-location-card';
import MeterCard from './meter-card/MeterCard';
import ServiceCard from './service-card/ServiceCard';

type Props = {
  serviceLocation: ServiceLocationType;
  setLocation: React.Dispatch<React.SetStateAction<ServiceLocationType | null>>;
};

export default function LocationDetail(props: Props) {
  const { serviceLocation, setLocation } = props;

  return (
    <CardsContainer>
      <Card id="location-detail-img" type="transparent" className={css['no-border']}>
        <img
          className={css['house-img']}
          src="https://cdn.zerofriction.co/shared/assets/house.svg"
          alt="house"
          title="house"
        />
      </Card>
      <LocationDetailCard location={serviceLocation} setLocation={setLocation} />
      <ContractsServiceLocationCard serviceLocationId={serviceLocation.id} />
      <ServiceCard locationId={serviceLocation.id} services={serviceLocation.services} />
      <MeterCard serviceLocationId={serviceLocation.id} address={serviceLocation.address} />
      <CustomEntityPropertiesCard
        entityType="ServiceLocations"
        entity={serviceLocation}
        entitySubjectType={entitySubjectType.servicelocation}
      />
      <CommentCard subjectType={entitySubjectType.servicelocation} subjectId={serviceLocation.id} />
      <FeedCard subjectType={entitySubjectType.servicelocation} subjectId={serviceLocation.id} />
      <IntegrationLoggingCard subjectId={serviceLocation.id} subjectType={'ServiceLocation'} />
      <MapLocationCard locationData={serviceLocation} />
    </CardsContainer>
  );
}
