import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import clone from 'clone';
import { notify } from 'events/notification-events';
import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';
import { calcNextInvoiceDate } from 'utils/contract';

import {
  InvoiceBillingConfigurationSuggestions,
  InvoiceProductConfigurationSuggestions
} from '@zf/api-types/billing/property-group-billing-configuration';
import { invoiceFrequency, utilityType } from '@zf/api-types/enums';
import { AddressType, LocalAddressType } from '@zf/api-types/general';
import {
  ContractedServiceEstimatedConsumption,
  ContractedServiceLocationType
} from '@zf/api-types/master-data/contract';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { ServiceLocationType, ShortContractType } from '@zf/api-types/master-data/servicelocation';
import { onlyUnique } from '@zf/utils/src/array';
import { MIN_DATE } from '@zf/utils/src/date';

import MoveInWizardBaseStore from './MoveInWizardBaseStore';

export enum locationSelection {
  single = 'single',
  multiple = 'multiple'
}

export type AlreadySuppliedServicesType = ShortContractType & {
  utilityType: utilityType;
};

export type OldContractorInvoiceAddress = {
  contractorId: string;
  shortDisplayName: string;
  invoiceAddress: AddressType;
  isDirty: boolean;
};

type EmbeddedLocationListPageQuery = {
  //excludeServiceLocationIds: string[]
  excludeServiceLocationsSuppliedInContractId: string;
  referenceDateTime: string | undefined;
};

export default class LocationSectionStore {
  public moveInWizardBaseStore: MoveInWizardBaseStore;
  private applicationStore: ApplicationStore;

  // Location section
  public initialLocationSelectionType: locationSelection;
  public locationSelectionType: locationSelection;

  // Single select
  public selectedLocationBackup: ServiceLocationType | undefined; // We use this for restoring deselected services
  public selectedLocation: ServiceLocationType | undefined;
  public oldContractorInvoiceAddress: OldContractorInvoiceAddress | undefined;
  public oldContractorInvoiceAddressIsDirty = false;

  // Multi select
  public locationSearch = '';
  public selectedLocationsBackup = new Map<string, ServiceLocationType>(); // We use this for restoring deselected services
  public selectedLocations = new Map<string, ServiceLocationType>();
  public oldContractorInvoiceAddresses = new Map<string, OldContractorInvoiceAddress>();
  public embeddedLocationListPageQuery = {
    excludeServiceLocationsSuppliedInContractId: '' as string,
    referenceDateTime: '' as string | undefined
  };

  // Billing section
  public invoiceBillingConfigurationSuggestions: InvoiceBillingConfigurationSuggestions | undefined;

  // Contract data section
  public invoiceProductConfigurationSuggestions: InvoiceProductConfigurationSuggestions | undefined;

  // Shared for suggestions API
  public contractId: string | undefined;

  constructor(
    moveInWizardBaseStore: MoveInWizardBaseStore,
    applicationStore: ApplicationStore,
    defaultLocationSelectionType: locationSelection
  ) {
    this.moveInWizardBaseStore = moveInWizardBaseStore;
    this.applicationStore = applicationStore;
    this.initialLocationSelectionType = defaultLocationSelectionType;
    this.locationSelectionType = defaultLocationSelectionType;

    makeObservable(this, {
      // Location section
      locationSelectionType: observable,

      selectedServices: computed,

      setLocationSelectionType: action,
      resetStore: action,
      mapLocationsToApiFriendlyValues: action,
      updateInvoiceAddresses: action,
      wizardCalcNextInvoiceDate: action,

      // Single select
      selectedLocation: observable,
      oldContractorInvoiceAddress: observable,
      oldContractorInvoiceAddressIsDirty: observable,

      setSelectedLocation: action,
      handleSelectServiceSingleLocation: action,
      removeSelectedLocation: action,
      setOldContractorInvoiceAddress: action,

      setSingleLocationInvoiceAddressValue: action,
      updateOldContractorInvoiceAddressCall: action,

      // Multi select
      locationSearch: observable,
      selectedLocations: observable,
      oldContractorInvoiceAddresses: observable,
      embeddedLocationListPageQuery: observable,

      updateMultiLocationInputSuggestions: action,
      addToSelectedLocations: action,
      removeLocationFromMap: action,
      addOldContractorInvoiceAddressToMap: action,
      handleSelectServiceInMap: action,
      updateOldContractorInvoiceAddressInMap: action,
      updateOldContractorInvoiceAddressesCall: action,
      setEmbeddedLocationListPageQuery: action,

      // Billing section
      invoiceBillingConfigurationSuggestions: observable,

      setInvoiceBillingConfigurationSuggestions: action,

      // Contract data section
      invoiceProductConfigurationSuggestions: observable,

      setInvoiceProductConfigurationSuggestions: action,

      // Shared
      contractId: observable,

      selectedPropertyGroupIds: computed,

      setContractId: action
    });
  }

  /**
   * Location section
   */
  setLocationSelectionType = (locationSelectionType: locationSelection) => {
    this.locationSelectionType = locationSelectionType;
  };

  getAlreadySuppliedServices = (location: ServiceLocationType, services: utilityType[]) => {
    return location.services.reduce((acc: AlreadySuppliedServicesType[], s) => {
      if (services.includes(s.utilityType)) {
        const matchingContract = s.contracts.find((c) =>
          moment().isBetween(c.supplyStartDateTime, c.supplyEndDateTime, undefined, '[]')
        );

        if (matchingContract) {
          acc.push({
            utilityType: s.utilityType,
            ...matchingContract
          });
        }
      }

      return acc;
    }, []);
  };

  getActiveContractForLocation = (location: ServiceLocationType) => {
    let contract: ShortContractType | undefined;

    for (let i = 0; i < location.services.length; ++i) {
      const s = location.services[i];

      contract = s.contracts.find((c) =>
        moment().isBetween(c.supplyStartDateTime, c.supplyEndDateTime, undefined, '[]')
      );

      if (!!contract) {
        break;
      }
    }

    return contract;
  };

  updateLocationServices = (locationToUpdate: ServiceLocationType, services: utilityType[]) => {
    locationToUpdate.services = services.map((s) => {
      const contracts = locationToUpdate.services.find((us) => us.utilityType === s)?.contracts;

      // We only really need utilityType & contracts here, other defaults are for compatibility, we don't use them
      return {
        utilityType: s,
        externalIdentifier: '',
        statusHistory: [],
        contracts: contracts || []
      };
    });

    return locationToUpdate;
  };

  get selectedServices() {
    let services: utilityType[] = [];

    if (this.locationSelectionType === locationSelection.single) {
      if (this.selectedLocation) {
        services = this.selectedLocation.services.map((s) => s.utilityType);
      }
    } else {
      const allServices = Array.from(this.selectedLocations.values()).flatMap((l) =>
        l.services.map((s) => s.utilityType)
      );
      services = allServices.filter(onlyUnique);
    }

    return services;
  }

  get selectedPropertyGroupIds() {
    if (this.locationSelectionType === locationSelection.single) {
      return this.selectedLocation?.propertyGroup?.id ? [this.selectedLocation.propertyGroup.id] : [];
    } else {
      return Array.from(this.selectedLocations.values()).reduce((acc: string[], l) => {
        if (l.propertyGroup?.id) {
          acc.push(l.propertyGroup.id);
        }

        return acc;
      }, []);
    }
  }

  mapLocationToApiFriendlyValues = (
    location: ServiceLocationType,
    estimatedConsumptions: ContractedServiceEstimatedConsumption[]
  ): ContractedServiceLocationType[] => {
    return location.services.map((s) => {
      return {
        serviceLocationId: location.id,
        utilityType: s.utilityType,
        externalIdentifier: s.externalIdentifier,
        estimatedConsumptions: estimatedConsumptions.filter((ec) => ec.utilityType === s.utilityType)
      };
    });
  };

  mapLocationsToApiFriendlyValues = (estimatedConsumptions: ContractedServiceEstimatedConsumption[]) => {
    let servicedLocations: ContractedServiceLocationType[] = [];

    if (this.locationSelectionType === locationSelection.single) {
      if (this.selectedLocation) {
        servicedLocations = this.mapLocationToApiFriendlyValues(this.selectedLocation, estimatedConsumptions);
      }
    } else {
      if (this.selectedLocations && this.selectedLocations.size !== 0) {
        servicedLocations = Array.from(this.selectedLocations.values()).flatMap((l) =>
          this.mapLocationToApiFriendlyValues(l, estimatedConsumptions)
        );
      }
    }

    return servicedLocations;
  };

  updateInvoiceAddresses = async () => {
    if (this.locationSelectionType === locationSelection.single) {
      await this.updateOldContractorInvoiceAddressCall();
    } else {
      await this.updateOldContractorInvoiceAddressesCall();
    }
  };

  wizardCalcNextInvoiceDate = (
    supplyStartDate: Moment | null | undefined,
    invoiceFrequency_: invoiceFrequency | null // Overrules suggestion
  ) => {
    if (this.invoiceBillingConfigurationSuggestions) {
      return calcNextInvoiceDate(
        supplyStartDate,
        this.invoiceBillingConfigurationSuggestions.invoiceMonth,
        this.invoiceBillingConfigurationSuggestions.invoiceDay,
        invoiceFrequency_ || this.invoiceBillingConfigurationSuggestions.invoiceFrequency
      );
    }

    return moment(MIN_DATE);
  };

  /**
   * Error handling
   */
  showProductSuggestionErrors = (error: Error) => {
    notify.error({
      content: this.applicationStore.getTranslation('contracts.wizard.product_sugg_fail'),
      error
    });
  };

  showBillingSuggestionErrors = (error: Error) => {
    notify.error({
      content: this.applicationStore.getTranslation('contracts.wizard.bill_sugg_fail'),
      error
    });
  };

  showOldContractorInvoiceAddressSuccess = () => {
    notify.success({
      content: this.applicationStore.getTranslation('contracts.update_old_contractor_success')
    });
  };

  showOldContractorInvoiceAddressErrors = (error: Error) => {
    notify.error({
      content: this.applicationStore.getTranslation('contracts.update_old_contractor_fail'),
      error
    });
  };

  /**
   * Single select
   */
  setSelectedLocation = async (selectedLocations: ServiceLocationType[]) => {
    if (selectedLocations.length > 0) {
      const { getInvoiceProductConfigurationSuggestions, getInvoiceBillingConfigurationSuggestions } =
        this.moveInWizardBaseStore.contractStore.rootStore.propertyGroupStore.propertyGroupBillingConfigurationService;
      this.selectedLocation = selectedLocations[0];
      this.selectedLocationBackup = selectedLocations[0];

      let productSuggestion: InvoiceProductConfigurationSuggestions | undefined;

      try {
        // Product suggestion
        productSuggestion = await getInvoiceProductConfigurationSuggestions([this.selectedLocation.id]);
        this.setInvoiceProductConfigurationSuggestions(productSuggestion);
      } catch (error) {
        this.showProductSuggestionErrors(error);
      }

      // Billing inputs suggestions
      let billingSuggestions: InvoiceBillingConfigurationSuggestions | undefined;

      try {
        billingSuggestions = await getInvoiceBillingConfigurationSuggestions([this.selectedLocation.id]);
        this.setInvoiceBillingConfigurationSuggestions(billingSuggestions);
      } catch (error) {
        this.showBillingSuggestionErrors(error);
      }
    }
  };

  setOldContractorInvoiceAddress = (c: CustomerType) => {
    this.oldContractorInvoiceAddress = {
      contractorId: c.id,
      shortDisplayName: c.shortDisplayName,
      invoiceAddress: c.invoiceAddress,
      isDirty: false
    };
  };

  setSingleLocationInvoiceAddressValue = (val: Partial<LocalAddressType>) => {
    if (this.oldContractorInvoiceAddress) {
      this.oldContractorInvoiceAddress = {
        ...this.oldContractorInvoiceAddress,
        invoiceAddress: { ...this.oldContractorInvoiceAddress.invoiceAddress, ...val }
      };
      this.oldContractorInvoiceAddressIsDirty = true;
    }
  };

  handleSelectServiceSingleLocation = (services: utilityType[]) => {
    const locationToUpdate = clone(this.selectedLocationBackup);

    if (locationToUpdate) {
      this.selectedLocation = this.updateLocationServices(locationToUpdate, services);
    }
  };

  removeSelectedLocation = () => {
    this.selectedLocation = undefined;
    this.invoiceBillingConfigurationSuggestions = undefined;
    this.invoiceProductConfigurationSuggestions = undefined;
    this.oldContractorInvoiceAddressIsDirty = false;
  };

  updateOldContractorInvoiceAddressCall = async () => {
    if (this.selectedLocation && this.oldContractorInvoiceAddress && this.oldContractorInvoiceAddressIsDirty) {
      try {
        await this.moveInWizardBaseStore.contractStore.rootStore.customerStore.customerService.updateContractorInvoiceAddress(
          this.oldContractorInvoiceAddress.contractorId,
          this.oldContractorInvoiceAddress.invoiceAddress
        );

        this.showOldContractorInvoiceAddressSuccess();
      } catch (error) {
        this.showOldContractorInvoiceAddressErrors(error);
      }
    }
  };

  /**
   *  Multi select
   */
  setEmbeddedLocationListPageQuery = (embeddedLocationListPageQuery: EmbeddedLocationListPageQuery) => {
    this.embeddedLocationListPageQuery = embeddedLocationListPageQuery;
  };

  setLocationSearch = (searchTerm: string) => {
    this.locationSearch = searchTerm;
  };

  setContractId = (id: string | undefined) => {
    this.contractId = id;
  };

  updateMultiLocationInputSuggestions = async (locationIds: string[]) => {
    // Product suggestion
    let productSuggestion: InvoiceProductConfigurationSuggestions | undefined;

    const { getInvoiceProductConfigurationSuggestions, getInvoiceBillingConfigurationSuggestions } =
      this.moveInWizardBaseStore.contractStore.rootStore.propertyGroupStore.propertyGroupBillingConfigurationService;

    try {
      if (locationIds.length !== 0) {
        productSuggestion = await getInvoiceProductConfigurationSuggestions(locationIds, this.contractId);

        this.setInvoiceProductConfigurationSuggestions(productSuggestion);
      } else {
        this.setInvoiceProductConfigurationSuggestions(undefined);
      }
    } catch (error) {
      this.showProductSuggestionErrors(error);
    }

    // Billing inputs suggestions
    let billingSuggestions: InvoiceBillingConfigurationSuggestions | undefined;

    try {
      if (locationIds.length !== 0) {
        billingSuggestions = await getInvoiceBillingConfigurationSuggestions(locationIds, this.contractId);
        this.setInvoiceBillingConfigurationSuggestions(billingSuggestions);
      } else {
        this.setInvoiceBillingConfigurationSuggestions(undefined);
      }
    } catch (error) {
      this.showBillingSuggestionErrors(error);
    }
  };

  addToSelectedLocations = async (selectedLocations: [string, ServiceLocationType][]) => {
    const selectedLocationsAsArray = Array.from(this.selectedLocations);
    selectedLocationsAsArray.unshift(...selectedLocations);
    this.selectedLocations = new Map(selectedLocationsAsArray);

    const backupLocationsAsArray = Array.from(this.selectedLocationsBackup);
    backupLocationsAsArray.unshift(...selectedLocations);
    this.selectedLocationsBackup = new Map(backupLocationsAsArray);

    // this.setEmbeddedLocationListPageQuery({
    //   excludeServiceLocationIds: [
    //     ...this.embeddedLocationListPageQuery.excludeServiceLocationIds,
    //     ...Array.from(this.selectedLocations.keys())
    //   ]
    // });
    this.updateMultiLocationInputSuggestions(Array.from(this.selectedLocations.keys()));
  };

  handleSelectServiceInMap = (locationId: string, services: utilityType[]) => {
    let locationToUpdate = clone(this.selectedLocationsBackup.get(locationId));

    if (locationToUpdate) {
      locationToUpdate = this.updateLocationServices(locationToUpdate, services);
      this.selectedLocations.set(locationId, locationToUpdate);
    }
  };

  removeLocationFromMap = (locationId: string) => {
    this.selectedLocations.delete(locationId);
    // this.setEmbeddedLocationListPageQuery({
    //   excludeServiceLocationIds: this.embeddedLocationListPageQuery.excludeServiceLocationIds.filter(
    //     (id) => id !== locationId
    //   )
    // });
    this.updateMultiLocationInputSuggestions(Array.from(this.selectedLocations.keys()));
  };

  addOldContractorInvoiceAddressToMap = (locationId: string, oldContractor: CustomerType) => {
    this.oldContractorInvoiceAddresses.set(locationId, {
      contractorId: oldContractor.id,
      shortDisplayName: oldContractor.shortDisplayName,
      invoiceAddress: oldContractor.invoiceAddress,
      isDirty: false
    });
  };

  updateOldContractorInvoiceAddressInMap = (locationId: string, val: Partial<AddressType>) => {
    const addressToUpdate = this.oldContractorInvoiceAddresses.get(locationId);

    if (addressToUpdate) {
      this.oldContractorInvoiceAddresses.set(locationId, {
        ...addressToUpdate,
        invoiceAddress: { ...addressToUpdate.invoiceAddress, ...val },
        isDirty: true
      });
    }
  };

  updateOldContractorInvoiceAddressesCall = async () => {
    const promises = Array.from(this.oldContractorInvoiceAddresses.values()).reduce((acc: Promise<void>[], ocia) => {
      if (ocia.isDirty) {
        acc.push(
          this.moveInWizardBaseStore.contractStore.rootStore.customerStore.customerService.updateContractorInvoiceAddress(
            ocia.contractorId,
            ocia.invoiceAddress
          )
        );
      }

      return acc;
    }, []);

    await Promise.all(promises)
      .then(() => {
        if (promises.length > 0) {
          this.showOldContractorInvoiceAddressSuccess();
        }
      })
      .catch((error) => {
        this.showOldContractorInvoiceAddressErrors(error);
      });
  };

  /**
   * Billing section
   */
  setInvoiceBillingConfigurationSuggestions = (
    invoiceBillingConfigurationSuggestions: InvoiceBillingConfigurationSuggestions | undefined
  ) => {
    this.invoiceBillingConfigurationSuggestions = invoiceBillingConfigurationSuggestions;
  };

  /**
   * Contract data section
   */
  setInvoiceProductConfigurationSuggestions = (
    invoiceProductConfigurationSuggestions: InvoiceProductConfigurationSuggestions | undefined
  ) => {
    this.invoiceProductConfigurationSuggestions = invoiceProductConfigurationSuggestions;
  };

  /**
   * General
   */
  resetStore = () => {
    this.locationSelectionType = this.initialLocationSelectionType;

    this.selectedLocation = undefined;
    this.selectedLocationBackup = undefined;
    this.oldContractorInvoiceAddress = undefined;
    this.oldContractorInvoiceAddressIsDirty = false;

    this.locationSearch = '';
    this.selectedLocations = new Map<string, ServiceLocationType>();
    this.selectedLocationsBackup = new Map<string, ServiceLocationType>();
    this.oldContractorInvoiceAddresses = new Map<string, OldContractorInvoiceAddress>();
    this.embeddedLocationListPageQuery = {
      excludeServiceLocationsSuppliedInContractId: '',
      referenceDateTime: undefined
    };

    this.invoiceBillingConfigurationSuggestions = undefined;
    this.invoiceProductConfigurationSuggestions = undefined;

    this.contractId = undefined;
  };
}
