import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';

import { BillingCompletenessInsightResponseType } from '@zf/api-types/billing/billing-completeness';
import { advanceFrequency, contractStatus } from '@zf/api-types/enums';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { formatPeriodWMonth, isMaxDate, isMinDate } from '@zf/utils/src/date';

import DaysOverdueBadge from '../../../../../../components/analytics/days-overdue-badge';
import Button from '../../../../../../components/Button/Button';
import { ICON_COLORS } from '../../../../../../constants/icons';
import { Link } from '../../../../../../design-system/Components';
import { useStore } from '../../../../../../hooks/useStore';
import AdvanceInfo from './AdvanceInfo';
import EstimatedInvoiceInfo from './EstimatedInvoiceInfo';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

type Props = {
  billingInsights: BillingCompletenessInsightResponseType;
  setActiveTab: (tabId: string) => void;
};

type State = {
  organisationCfg: OrganizationConfigType | null;
};

const BillingDetailsWRelation = (props: Props) => {
  const { billingInsights, setActiveTab } = props;
  const { contractStore, applicationStore, organisationStore } = useStore();
  const { getOrganisationConfig } = organisationStore.organisationService;
  const { rootUrl, getTranslation, getEnumTranslation, tenantReducer } = applicationStore;
  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    organisationCfg: null
  });
  const { selectedContract } = contractStore;

  const contract = selectedContract.contract;

  const initState = async () => {
    const organisationCfg = await getOrganisationConfig(
      tenantReducer.organization?.organizationId || '',
      tenantReducer.tenant?.id || ''
    );
    const newState: State = {
      organisationCfg
    };

    return newState;
  };

  useEffect(() => {
    initState().then((res) => setState(res));
  }, []);

  const propertyGroupId = contract.propertyGroups?.[0]?.id; // only one can be present in case of cost allocation

  return (
    <Card id="billing-details-card" width="1" className="card" role="dialog">
      <CardHeader
        extraRight={
          billingInsights.activeStatus && (
            <FlexElements alignItems="center">
              <NewStatusBadge
                status_={getEnumTranslation('billingCompletenessStatus', billingInsights.activeStatus)}
                color={ICON_COLORS[billingInsights.activeStatus]}
              />
              <Button
                id="show-insights"
                icon="angle-right"
                onClick={() => setActiveTab('billing-insights')}
                type="text"
                size="small"
              />
            </FlexElements>
          )
        }
      >
        {getTranslation('contracts.billing_details')}
      </CardHeader>
      <CardBody type="grid">
        {contract.currentContractStatus !== contractStatus.terminated &&
          !isMinDate(billingInsights.activeInvoiceStartDateTime) &&
          !isMaxDate(billingInsights.activeInvoiceEndDateTime) && (
            <>
              <CardItem width="6">
                <Label>{getTranslation('contracts.current_invoice_period')}</Label>
              </CardItem>
              <CardItem width="6">
                <Paragraph textAlign="right">
                  {formatPeriodWMonth(
                    billingInsights.activeInvoiceStartDateTime,
                    billingInsights.activeInvoiceEndDateTime
                  )}
                </Paragraph>
              </CardItem>
              <CardItem width="6">
                <Label>{getTranslation('invoice.invoice_due')}</Label>
              </CardItem>
              {contract.currentContractStatus === contractStatus.signed &&
              moment(contract.supplyEndDate).isSameOrAfter(billingInsights.activeInvoiceEndDateTime) &&
              billingInsights.activeInvoiceEndDateTime !== billingInsights.activeInvoiceStartDateTime ? (
                <CardItem justifyContent="end" width="6">
                  <DaysOverdueBadge
                    tooltipFor="invoices-table-tip" // Reusing the one from the invoices table here to avoid complexity
                    referenceDate={billingInsights.activeInvoiceEndDateTime}
                  />
                </CardItem>
              ) : (
                <CardItem width="6" />
              )}

              <CardItem width="12">
                <HorizontalDivider />
              </CardItem>
            </>
          )}

        <CardItem width="6">
          <Label>{getTranslation('contracts.advance_freq')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right">
            {getEnumTranslation('advanceFrequency', billingInsights.advanceFrequency)}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{getTranslation('contracts.invoice_freq')}</Label>
        </CardItem>
        <CardItem width="6" justifyContent="end">
          {billingInsights.invoiceFrequency ? (
            <Paragraph textAlign="right">
              {getEnumTranslation('invoiceFrequency', billingInsights.invoiceFrequency)}
            </Paragraph>
          ) : (
            <Link
              id={`property-${propertyGroupId}`}
              url={`${rootUrl}/property-groups/${propertyGroupId}/billing_overview`}
              bold
            >
              {getTranslation('contracts.invoice_freq_by_cost_alloc')}
            </Link>
          )}
        </CardItem>

        {billingInsights.advanceFrequency !== advanceFrequency.none && <AdvanceInfo />}

        {state.organisationCfg?.features.contractInvoiceEstimationEnabled && (
          <EstimatedInvoiceInfo billingInsights={billingInsights} />
        )}
      </CardBody>
    </Card>
  );
};

export default observer(BillingDetailsWRelation);
