import React from 'react';

import { CollectionCaseStepType, CollectionCaseType } from '@zf/api-types/collection-case';
import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import GenericDialog from '../../units/Dialog/GenericDialog';

type Props = {
  collectionCase: CollectionCaseType;
  step: CollectionCaseStepType;
  refresh: () => void;
};

export default function SendViaPostalListItem(props: Props) {
  const { collectionCase, refresh, step } = props;
  const { applicationStore, collectionCaseStore } = useStore();
  const { getTranslation } = applicationStore;
  const { sendViaPostal } = collectionCaseStore.collectionCaseService;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const submitFunction = async () => {
    await sendViaPostal(collectionCase.id, step.id);
    refresh();
  };

  return (
    <DropdownAction
      id="collection_case.send_via_post"
      icon="paper-plane"
      dialogTitle={getTranslation('actions.resend_postal')}
      dialogContent={
        <GenericDialog
          ref={clickRef}
          info={getTranslation('actions.post.descr')}
          successMsg={getTranslation('actions.post.send_success')}
          errorMsg={getTranslation('actions.post.send_failed')}
          submitFunction={submitFunction}
        />
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={getTranslation('communication.send')}
    />
  );
}
