import React, { memo } from 'react';

import css from './wizard-section-header.module.scss';

type Props = {
  // This id is linked to the corresponding WizardStep headerId
  id: string;
  text: string;
};

const WizardSectionHeader = (props: Props) => {
  const { id, text } = props;

  return (
    <h1 id={id} className={css['wizard-step']}>
      {text}
    </h1>
  );
};

export default memo(WizardSectionHeader);
