import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';

import { Icon } from '../../Foundation';
import { IconPosition } from '../../Foundation/Icon/Icon';
import SimpleTooltip from '../Tooltip/SimpleTooltip/SimpleTooltip';
import BaseButton, { ButtonType, SubmitType } from './BaseButton';
import css from './button.module.scss';

export type ButtonSize = 'small' | 'standard';

export type ButtonProps = {
  id: string;
  children?: ReactNode;
  className?: string;
  submitType?: SubmitType;
  type?: ButtonType;
  size?: ButtonSize;
  icon?: string;
  iconPosition?: IconPosition;
  danger?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  tooltipText?: string;
  tooltipFor?: string;
  rounded?: boolean;
  noSidePadding?: boolean;
  onClick: Function;
};

export default function Button(props: ButtonProps) {
  const {
    id,
    children,
    className,
    icon,
    style,
    tooltipText,
    tooltipFor,
    type = 'primary',
    size = 'standard',
    submitType,
    iconPosition = 'left',
    danger = false,
    disabled = false,
    rounded = false,
    noSidePadding = false,
    onClick
  } = props;

  const iconComponent = icon ? <Icon name={icon} /> : null;
  const iconOnly = !children && !!icon;

  const buttonBody = iconOnly ? (
    iconComponent
  ) : (
    <>
      {iconPosition === 'left' && iconComponent}
      {children}
      {iconPosition === 'right' && iconComponent}
    </>
  );

  const buttonComponent = (
    <BaseButton
      SubmitType={submitType}
      id={id}
      className={classNames(
        css['button'],
        css[type],
        css[size],
        {
          [css[`${type}-danger`]]: danger,
          [css[`${type}-disabled`]]: disabled,
          [css[`${type}-${size}-icon-only`]]: iconOnly && !disabled,
          [css[`${type}-${size}-icon-only-danger`]]: iconOnly && danger && !disabled,
          [css[`${type}-${size}-icon-only-disabled`]]: iconOnly && disabled,
          [css[`${type}-${size}-icon-only-danger-disabled`]]: iconOnly && danger && disabled,
          [css['icon-only-rounded']]: iconOnly && rounded,
          [css['no-side-padding']]: noSidePadding
        },
        className
      )}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {buttonBody}
    </BaseButton>
  );

  if (tooltipText && tooltipFor) {
    return (
      <div className={classNames(css['tooltip'], className)} data-for={tooltipFor} data-tip={tooltipText}>
        {buttonComponent}
      </div>
    );
  } else if (tooltipText) {
    return (
      <SimpleTooltip id={`${id}-tip`} className={className} tooltip={tooltipText} renderInGateway={false}>
        {buttonComponent}
      </SimpleTooltip>
    );
  }

  return buttonComponent;
}
