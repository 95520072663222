import classNames from 'classnames';
import clone from 'clone';
import React from 'react';

import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import css from './enum-filter-select.module.scss';
import FilterInputSelect from './FilterInputSelect';

type Props<T> = {
  id: string;
  title: string;
  iconType?: string;
  options: DropdownValuesType<T>[];
  values: T[];
  className?: string;
  multiSelect?: boolean;
  showAllCheckboxes?: boolean;
  blueTextWhenSelected?: boolean;
  noDeselect?: boolean;
  setValue: (val: T[]) => void;
};

export default function EnumFilterSelect<T>(props: Props<T>) {
  const {
    id,
    title,
    className,
    iconType,
    options,
    values = [],
    multiSelect = false,
    showAllCheckboxes = true,
    blueTextWhenSelected = true,
    noDeselect = false,
    setValue
  } = props;

  let isChecked = false;

  const list = options.map((option, index) => {
    if (multiSelect && typeof values === 'object') {
      isChecked = values.includes(option.value);
    } else if (typeof values === 'string') {
      let dirty: T[] = [];
      dirty[0] = values;
      isChecked = dirty[0] === option.value;
    } else {
      isChecked = values[0] === option.value;
    }

    let showCheckbox = true;

    if (!showAllCheckboxes && !isChecked) {
      showCheckbox = false;
    }

    return (
      <div
        id={`${option.text}-${index}`}
        key={`${option.text}-${index}`}
        className={classNames(css['list-item'], { [css['selected']]: values.includes(option.value) })}
        onClick={() => {
          if (values.includes(option.value)) {
            if (multiSelect) {
              // Remove deselect (remove) toggled item
              const newValues = values.filter((v) => v !== option.value);
              setValue(newValues);
            } else if (!noDeselect) {
              // reset
              setValue([]);
            }
          } else if (multiSelect) {
            // Add option to our selection
            if (!values.includes(option.value)) {
              const valuesclone = clone(values);
              valuesclone.push(option.value);
              setValue(valuesclone);
            }
          } else {
            setValue([option.value]);
          }
        }}
      >
        {
          <div className={css['option-item']}>
            {option.text}
            {showCheckbox && <CheckBox id="typeCheckbox" checked={isChecked} preventDefault />}
          </div>
        }
      </div>
    );
  });

  return (
    <FilterInputSelect
      id={id}
      className={className}
      list={list}
      hasSelection={values.length > 0}
      iconType={iconType}
      title={title}
      blueTextWhenSelected={blueTextWhenSelected}
    />
  );
}
