import Interweave from 'interweave';
import React from 'react';

import { CollectionCaseRowType, CollectionCaseType } from '@zf/api-types/collection-case';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { useAppContext } from '../../../app-context';
import useListPage from '../../../app-context/hooks/use-listpage';
import Debtor from '../../../components/Debtor/Debtor';
import EmptyLink from '../../../components/Link/EmptyLink';
import { ICON_COLORS } from '../../../constants/icons';
import { Icon } from '../../../design-system/Foundation';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import css from './use-collection-case-listpage.module.scss';

const useCollectionCaseListPage = (customEntityPropertyTypes: CustomEntityPropertyType[]) => {
  const { tenantReducer, enumReducer, i18n } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const processRecord = (colCase: CollectionCaseType): CollectionCaseRowType => {
    return {
      __id: colCase.id,
      __etag: colCase._etag,
      __entity: colCase,
      __customEntityPropertyTypes: customEntityPropertyTypes,
      actionRequired: colCase.manualInterventionRequired ? (
        <div
          data-tip={createTooltipContent(
            <Interweave
              className={css['action-required']}
              content={colCase.steps
                .reduce((acc: string[], s) => {
                  if (s.failureReasonCode && !acc.includes(s.failureReasonCode.message)) {
                    acc.push(s.failureReasonCode.message);
                  }

                  return acc;
                }, [])
                .join('<br />')}
            />
          )}
          data-for="collection-cases-table-tip"
        >
          <Icon name="alert-circle" color={colors['red-600']} />
        </div>
      ) : null,
      collectionId: (
        <EmptyLink
          id={`${routedEntitySubjectType.collectioncase}-${colCase.id}`}
          url={`${rootUrl}/collection-cases/${colCase.id}`}
        >
          {colCase.sequenceNum}
        </EmptyLink>
      ),
      workflowName: colCase.workflowName,
      debtor: <Debtor debtor={colCase.debtor} />,
      status: (
        <StatusBadge color={ICON_COLORS[colCase.status]} type="bare">
          {enumReducer.getTranslation('collectionFlowStatus', colCase.status)}
        </StatusBadge>
      ),
      lastStep: colCase.lastStep?.name || '',
      nextStep: colCase.nextStep?.name || '',
      paymentReference: colCase.paymentReference,
      openAmountInclVAT: formatMoney(colCase.totalOpenAmountInclVAT, i18n.culture),
      nextActionDue: formatDate(colCase.nextActionDue),
      ...getExtraCustomEntityColumns(colCase.customProperties, customEntityPropertyTypes, i18n.getTranslation)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<CollectionCaseType, CollectionCaseRowType>({
    endpoint: '/bill/CollectionCases',
    processRecord,
    domain: 'collection_case'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useCollectionCaseListPage;
