import React from 'react';

import { useAppContext } from '../../app-context/app-context';
import Button, { ButtonProps } from '../../design-system/Components/Button/Button';

export default function CopyIcon(props: ButtonProps) {
  const { i18n } = useAppContext();

  return <Button {...props} icon="copy" size="small" type="text" tooltipText={i18n.getTranslation('general.copy')} />;
}
