import { observer } from 'mobx-react';
import React from 'react';

import { attachmentVisibility } from '@zf/api-types/enums';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import DateRangePicker from '../../../../../components/input/DateRangePicker';
import { Disabled } from '../../../../../design-system/Components';
import { SettingDescription } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import { AttachmentState } from '../../logic/AttachmentGroupBase';

type Props = {
  hasPortal: boolean;
  state: AttachmentState;
  setState: React.Dispatch<Partial<AttachmentState>>;
  alwaysShowInPortal?: boolean;
};

const Options = (props: Props) => {
  const { hasPortal, state, setState, alwaysShowInPortal } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const isVisibleInPortal =
    state.visibility === attachmentVisibility.portal || state.visibility === attachmentVisibility.portalandmovein;
  const shownInMoveIn = state.visibility === attachmentVisibility.portalandmovein;

  return (
    <>
      <Heading headingType="h3">{getTranslation('general.options')}</Heading>

      {hasPortal && (
        <>
          <SettingDescription
            title={getTranslation('entity_attachment.show_in_portal')}
            description={getTranslation('entity_attachment.show_in_portal_desc')}
            setting={
              <CheckBox
                disabled={!alwaysShowInPortal}
                id="show-in-portal"
                checked={isVisibleInPortal}
                onChange={() => {
                  setState(
                    isVisibleInPortal
                      ? {
                          visibility: attachmentVisibility.internal,
                          approvalRequired: false
                        }
                      : {
                          visibility: attachmentVisibility.portal,
                          approvalRequired: false
                        }
                  );
                }}
              />
            }
          />
          <Disabled disabled={!isVisibleInPortal}>
            <SettingDescription
              title={getTranslation('entity_attachment.validity')}
              description={getTranslation('entity_attachment.validity_desc')}
              setting={
                <DateRangePicker
                  id="validity-period"
                  startDate={state.validFrom}
                  endDate={state.validUntil}
                  setDates={(dates) =>
                    setState({
                      validFrom: dates[0],
                      validUntil: dates[1]
                    })
                  }
                />
              }
              indentLvl={1}
            />
          </Disabled>
          <Disabled disabled={!isVisibleInPortal}>
            <SettingDescription
              title={getTranslation('entity_attachment.show_on_move_in')}
              description={getTranslation('entity_attachment.show_on_move_in_desc')}
              setting={
                <CheckBox
                  id="show-during.movein"
                  checked={shownInMoveIn}
                  onChange={(shownInMoveIn) => {
                    setState(
                      shownInMoveIn
                        ? {
                            visibility: attachmentVisibility.portalandmovein,
                            approvalRequired: false
                          }
                        : {
                            visibility: attachmentVisibility.portal,
                            approvalRequired: false
                          }
                    );
                  }}
                />
              }
              indentLvl={1}
            />
          </Disabled>
          <Disabled disabled={!shownInMoveIn}>
            <SettingDescription
              title={getTranslation('entity_attachment.approval')}
              description={getTranslation('entity_attachment.approval_desc')}
              setting={
                <CheckBox
                  id="approval-required"
                  checked={state.approvalRequired}
                  onChange={(approvalRequired) => setState({ approvalRequired })}
                />
              }
              indentLvl={2}
            />
          </Disabled>
        </>
      )}

      <SettingDescription
        title={getTranslation('entity_attachment.include_in_welcome_mail')}
        description={getTranslation('entity_attachment.include_in_welcome_mail_desc')}
        setting={
          <CheckBox
            id="include-in-welcome-email"
            checked={state.includeInWelcomeEmail}
            onChange={(includeInWelcomeEmail) => setState({ includeInWelcomeEmail })}
          />
        }
      />
    </>
  );
};

export default observer(Options);
