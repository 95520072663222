import BaseFormVjf, { Fields } from '../../../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../../../events/notification-events';
import PaymentConfigStore from '../../../stores/PaymentConfigStore';

export enum automaticPaymentsFormFields {
  email = 'email',
  enableForPaymentRequest = 'enableForPaymentRequest',
  enableForPaymentRefund = 'enableForPaymentRefund'
}

interface AutomaticPaymentsFormFieldTypes {
  email: string;
  enableForPaymentRequest: boolean;
  enableForPaymentRefund: boolean;
}

export default class AutomaticPaymentsForm extends BaseFormVjf<AutomaticPaymentsFormFieldTypes> {
  private paymentConfigStore: PaymentConfigStore;

  constructor(paymentConfigStore: PaymentConfigStore, initialValues: AutomaticPaymentsFormFieldTypes) {
    super(initialValues);

    this.paymentConfigStore = paymentConfigStore;
  }

  setup(): Fields {
    return {
      fields: [
        {
          name: automaticPaymentsFormFields.email,
          label: automaticPaymentsFormFields.email,
          validators: [this.isEmail]
        },
        {
          name: automaticPaymentsFormFields.enableForPaymentRequest,
          label: automaticPaymentsFormFields.enableForPaymentRequest,
          type: 'checkbox'
        },
        {
          name: automaticPaymentsFormFields.enableForPaymentRefund,
          label: automaticPaymentsFormFields.enableForPaymentRefund,
          type: 'checkbox'
        }
      ]
    };
  }

  save = async () => {
    const { updateTwikeyConfigurationInfo } = this.paymentConfigStore;
    const { getTranslation } = this.paymentConfigStore.rootStore.applicationStore;

    try {
      await updateTwikeyConfigurationInfo(this._values);

      notify.success({
        content: getTranslation('parameters.update_automatic_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('parameters.update_automatic_fail'),
        error: e
      });
    }
  };
}
