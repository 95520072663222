import classNames from 'classnames';
import React, { CSSProperties, ReactNode, useMemo } from 'react';

import { GLOBAL_VARS } from '@zf/utils/src/global';

import css from './flex-elements.module.scss';

export type FlexDirection = 'row' | 'column';
export type JustifyContent = 'start' | 'center' | 'end' | 'space-between';
export type AlignItems = 'center' | 'flex-start' | 'flex-end';
export type Gap = '1' | '2' | '4' | '8' | '12' | '16' | '24' | '32' | '40' | '48';

type Props = {
  children: ReactNode;
  className?: string;
  flexDirection?: FlexDirection;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  gap?: Gap;
};

export default function FlexElements(props: Props) {
  const { className, flexDirection, justifyContent, alignItems, children, gap = '8' } = props;

  const style = useMemo(() => {
    let res: CSSProperties = {};

    if (flexDirection) {
      res.flexDirection = flexDirection;
    }

    if (justifyContent) {
      res.justifyContent = justifyContent;
    }

    if (alignItems) {
      res.alignItems = alignItems;
    }

    if (gap) {
      res.gap = GLOBAL_VARS[`spacing-${gap}`];
    }

    return res;
  }, [flexDirection, justifyContent, alignItems, gap]);

  return (
    <div className={classNames(css['wrapper'], className)} style={style}>
      {children}
    </div>
  );
}
