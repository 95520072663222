import { KnownTenantType } from '@zf/api-types/auth';

import { METHODS } from '../../utils/request';
import ApplicationStore from '../stores/domain/ApplicationStore';
import BaseService from './BaseService';

export default class SystemAdminService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/sys/SystemAdmin/', applicationStore);
  }

  async getTenants(searchValue: string) {
    return (
      await this.applicationStore.sendRequest<KnownTenantType[]>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}tenants`,
          query: {
            flexSearch: searchValue
          }
        }
      })
    ).data;
  }
}
