import moment, { Moment } from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { aggregationFrequency, incrementationType } from '@zf/api-types/enums';
import { CompareGraphType } from '@zf/api-types/graph';
import { ConsumptionFlatValueType, ExternalChannelType } from '@zf/api-types/master-data/meter';

import { useAppContext } from '../../../../../../../../app-context';
import { METHODS, sendRequest } from '../../../../../../../../utils/request';
import { OutputCtxState, useTracked } from '../../../context/output-context';
import useGraph from '../../../shared-hooks/useGraph';
import useQueryParams from '../../../shared-hooks/useQueryParams';

export default function useCompareGraph() {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer } = useAppContext();

  const setState = (newState: Partial<OutputCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const { queryParams } = useQueryParams();
  const { setIsLoading, isCompareMode, selectedGraphChannels } = useGraph();

  const setCompareGraphValues = (newCompareValues: CompareGraphType[]) => {
    setState({ compareGraphValues: newCompareValues, graphIsLoading: false });
  };

  // Used for grouping our data on the X-axis
  const getPeriodKey = (dateTime: string) => {
    const date = moment(dateTime);

    if (queryParams.groupByPeriod === aggregationFrequency.yearly) {
      return date.format('MMMM');
    } else {
      // Monthly
      return `${i18n.getTranslation('measurement.day')} ${date.date().toString()}`;
    }
  };

  const getCompareFlatValues = async (
    channel: ExternalChannelType,
    startDateTime: Moment,
    endDateTime: Moment
  ): Promise<CompareGraphType[]> => {
    // We want the bars of a frequency higher
    let groupByPeriod = aggregationFrequency.monthly;

    if (queryParams.groupByPeriod === aggregationFrequency.monthly) {
      groupByPeriod = aggregationFrequency.daily;
    }

    const result = await sendRequest<PagedResponseType<ConsumptionFlatValueType>>({
      request: {
        method: METHODS.GET,
        endpoint: `/me/consumptions/${channel.externalIdentifier}/flat`,
        query: {
          groupByPeriod: groupByPeriod,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString()
        }
      },
      tenantReducer,
      lang: i18n.lang
    });

    return result.data.results.map((d) => {
      return {
        ...d,
        externalIdentifier: channel.externalIdentifier,
        meteringType: channel.meteringType,
        description: channel.description ? channel.description : channel.externalIdentifier,
        utilityType: channel.utilityType,
        uom: channel.unitOfMeasure,
        groupByKey: getPeriodKey(d.startDateTime)
      };
    });
  };

  const getCompareConsumptions = async (
    selectedConsumptionChannels: ExternalChannelType[],
    tmpStartDate: Moment,
    tmpEndDate: Moment
  ) => {
    let resultConsumption: CompareGraphType[][] = [];

    if (selectedConsumptionChannels.length > 0) {
      resultConsumption = await Promise.all(
        selectedConsumptionChannels.map((consChan) => {
          return getCompareFlatValues(consChan, tmpStartDate, tmpEndDate);
        })
      );
    }

    return resultConsumption;
  };

  const getCompareGraphValues = async () => {
    setIsLoading(true);

    const selectedConsumptionChannels: ExternalChannelType[] = [];

    selectedGraphChannels.forEach((extChannId) => {
      const matchingChann = state.channels.find((c) => {
        return c.externalIdentifier === extChannId;
      });

      if (matchingChann && matchingChann.incrementationType !== incrementationType.na) {
        selectedConsumptionChannels.push(matchingChann);
      }
    });

    const resultCompareConsumptions = await Promise.all(
      queryParams.groupByPeriod === aggregationFrequency.yearly
        ? state.compareYears.map((year) => {
            return getCompareConsumptions(
              selectedConsumptionChannels,
              moment([year]).startOf('year'),
              moment([year]).add(1, 'year').startOf('year')
            );
          })
        : state.compareMonths.map((compareMonth) => {
            return getCompareConsumptions(
              selectedConsumptionChannels,
              moment().year(compareMonth.year).month(compareMonth.month).startOf('month'),
              moment().year(compareMonth.year).month(compareMonth.month).add(1, 'month').startOf('month')
            );
          })
    );

    // Loading: false is done in graphValues setter
    setCompareGraphValues([...resultCompareConsumptions.flat().flat()]);
  };

  React.useEffect(() => {
    if (isCompareMode) {
      getCompareGraphValues();
    }
  }, [
    queryParams,
    isCompareMode,
    state.timeStamp,
    state.compareYears,
    state.compareMonths,
    state.selectedGraphChannels,
    state.graphHeaderSelection
  ]);

  return {
    compareGraphValues: state.compareGraphValues
  };
}
