import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import OverflowParagraph from '@zf/stella-react/src/atoms/Paragraph/OverflowParagraph';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';
import Center from '@zf/stella-react/src/helpers/Center';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../app-context';
import useBrowserTitle from '../../app-context/hooks/use-browser-title';
import SearchBar from '../../components/input/SearchBar';
import DynamicIndexTable from '../../components/Lang/DynamicIndexTable';
import { Link } from '../../design-system/Components';
import { useStore } from '../../hooks/useStore';
import TenantFooter from './TenantFooter';
import css from './tenants.module.scss';

export type Props = {
  currentName?: string;
  refresh: () => void;
};

const Tenants = (props: Props) => {
  const { refresh } = props;

  const {
    authReducer: { isSuperUser, state }
  } = useAppContext();

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('tenant.tenant_overview'));

  const [tableColumns] = useState<ColumnType[]>([
    {
      width: 120,
      label: getTranslation('tenant_picker.category'),
      dataKey: 'Category'
    },
    {
      flexWidth: 1,
      label: getTranslation('tenant_picker.tenant'),
      dataKey: 'Tenant'
    },
    {
      flexWidth: 1,
      label: getTranslation('tenant_picker.organisation'),
      dataKey: 'Organization'
    },
    {
      flexWidth: 1,
      label: getTranslation('tenant_picker.code'),
      dataKey: 'Code'
    },
    {
      flexWidth: 1,
      label: getTranslation('tenant_picker.country'),
      dataKey: 'Country'
    },
    {
      flexWidth: 1,
      label: getTranslation('tenant_picker.status'),
      dataKey: 'Status'
    }
  ]);

  useEffect(() => {
    if (isSuperUser()) {
      applicationStore.getTenants();
    }
  }, [state.isSuperAdmin]);

  const renderStatus = (Ap: boolean, migrationMode: boolean) => {
    if (migrationMode) {
      return (
        <StatusBadge type="bare" color={colors['orange-600']}>
          {getTranslation('tenant_picker.migrating')}
        </StatusBadge>
      );
    } else if (!Ap) {
      return (
        <StatusBadge type="bare" color={colors['red-600']}>
          {getTranslation('tenant_picker.processing_disabled')}
        </StatusBadge>
      );
    } else if (Ap) {
      return (
        <StatusBadge type="bare" color={colors['green-600']}>
          {getTranslation('tenant_picker.online')}
        </StatusBadge>
      );
    }

    return '-';
  };

  const rows = applicationStore.tenants?.map((e, index) => {
    return {
      Organization: (
        <Link
          id={`org-${e.organisationName}`}
          url={`${e.tenantCode.toUpperCase()}/${e.organisationCode.toUpperCase()}`}
        >
          {e.organisationName}
        </Link>
      ),
      Code: (
        <OverflowParagraph usedInTable id={e.organisationCode} tooltipFor="tenants-picker-table-tip">
          {e.organisationCode.toUpperCase()}
        </OverflowParagraph>
      ),
      Category: (
        <OverflowParagraph usedInTable id={e.organisationCode} tooltipFor="tenants-picker-table-tip">
          {e.tenantCategory.toUpperCase()}
        </OverflowParagraph>
      ),
      Tenant: (
        <Link id={`tenant-${e.tenantName}`} url={`${e.tenantCode.toUpperCase()}`}>{`${
          e.tenantName
        } (${e.tenantCode.toUpperCase()})`}</Link>
      ),
      Country: (
        <OverflowParagraph usedInTable id={`country-${index}`} tooltipFor="tenants-picker-table-tip">
          {e.organisationCountry?.toUpperCase()}
        </OverflowParagraph>
      ),
      Status: renderStatus(e.organisationAutomaticProcessingEnabled, e.organisationMigrationMode)
    };
  });

  return (
    <div className={css['tenant-container-wrapper']}>
      <div className={css['container']}>
        <div className={css['tenant-container-searchbar-wrapper']}>
          <SearchBar
            id="filter-searchbar"
            className={css['searchbar']}
            onChange={(val) => applicationStore.searchTenant(val)}
            value={applicationStore.searchTenantValue}
            placeholder={getTranslation('tenant.organisation_search')}
            debounceTime={500}
            darkMode
          />
        </div>

        <Center>
          <div className={css['list']}>
            <DynamicIndexTable
              tooltipId="tenants-picker-table-tip"
              scrollToIndex={applicationStore.searchIndex}
              rows={rows || []}
              columns={tableColumns}
              isLoading={applicationStore.tenantsIsLoading}
              selectedRow={applicationStore.searchIndex}
            />
          </div>
        </Center>

        {isSuperUser() && <TenantFooter refresh={refresh} />}
      </div>
    </div>
  );
};

export default observer(Tenants);
