import classNames from 'classnames';
import React from 'react';

import { ICONS } from '../../../constants/icons';
import SelectionButton, { SelectionButtonType } from '../../Components/Button/SelectionButton';
import css from './select.module.scss';

export type SelectValueType<T> = {
  icon?: string;
  value: T;
  text: string;
};

export type SelectProps<T> = {
  id: string;
  type?: SelectionButtonType;
  values: SelectValueType<T>[];
  selectedValues: T[];
  mayDeselect?: boolean; // This implies multiselect
  disabled?: boolean;
  className?: string;
  onChange: (selectedValues: T[]) => void;
};

export default function Select<T>(props: SelectProps<T>) {
  const { id, type, values, selectedValues, className, mayDeselect = false, disabled = false, onChange } = props;

  const handleChange = (value: T, isSelected: boolean) => {
    let newValues = [...selectedValues];

    // Multiselect
    if (mayDeselect) {
      if (isSelected) {
        const indexToRemove = newValues.findIndex((nv) => nv === value);
        newValues.splice(indexToRemove, 1);
      } else {
        newValues.push(value);
      }
    } else {
      // Single select
      if (!isSelected) {
        newValues = [value];
      }
    }

    onChange(newValues);
  };

  return (
    <div id={id} className={classNames(css['select'], className)}>
      {values.map((v, index) => {
        const isSelected = selectedValues.includes(v.value);
        let icon = v.icon;

        if (v.icon) {
          // Check if 'icon' is a mapped value of ICONS
          const mappedIcon = ICONS[v.icon];

          if (mappedIcon) {
            icon = mappedIcon;
          }
        }

        return (
          <SelectionButton
            key={`select-${v.value}-${index}`}
            type={type}
            icon={icon}
            value={v}
            selected={isSelected}
            disabled={disabled}
            onSelect={() => handleChange(v.value, isSelected)}
          />
        );
      })}
    </div>
  );
}
