import React from 'react';

import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './alert-circle.module.scss';

type Props = {
  children: React.ReactNode;
};

export default function AlertCircle(props: Props) {
  const { children } = props;

  return (
    <div>
      <TooltipTrigger tooltip={children}>
        <div className={css['alert-circle']}>
          <div className="icon icon-alert-circle" />
        </div>
      </TooltipTrigger>
    </div>
  );
}
