import moment from 'moment';
import React from 'react';

import { invoiceStatus, sentStatus } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatDate, formatPeriod } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { useAppContext } from '../../../app-context';
import useListPage from '../../../app-context/hooks/use-listpage';
import Debtor from '../../../components/Debtor/Debtor';
import EmptyLink from '../../../components/Link/EmptyLink';
import { ICON_COLORS } from '../../../constants/icons';
import { Link } from '../../../design-system/Components';
import { Icon, Spinner } from '../../../design-system/Foundation';
import { getInvoiceOverdueDescription } from '../../../utils/invoice';
import { LocalInvoiceStatus, LocalPaymentStatus } from '../stores/InvoiceStore';

const useInvoiceListPage = (
  getInvoiceStatus: (invoice: InvoiceType) => LocalInvoiceStatus,
  getPaymentStatus: (invoice: InvoiceType) => LocalPaymentStatus
) => {
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const renderRequireAttention = (value: InvoiceType) => {
    if (value.status === invoiceStatus.generationfailed || value.sent === sentStatus.failed) {
      return (
        <div
          data-tip={createTooltipContent(<strong>{value.failureReasonCode?.message}</strong>)}
          data-for="invoices-table-tip"
        >
          <Icon name="alert-circle" color={colors['red-600']} />
        </div>
      );
    } else if (value.sent === sentStatus.printing) {
      return (
        <div
          data-tip={createTooltipContent(<>{i18n.getTranslation('invoice.printing_progress')}</>)}
          data-for="invoices-table-tip"
        >
          <Spinner size="xsmall" />
        </div>
      );
    } else {
      return null;
    }
  };

  const processRecord = (value: InvoiceType) => {
    const amountOfDays = moment().diff(moment(value.dueDate), 'days');

    const invoiceStatus_ = getInvoiceStatus(value);
    const paymentStatus = getPaymentStatus(value);

    const paymentStatusJSX = (
      <StatusBadge color={ICON_COLORS[paymentStatus.status]} type="bare">
        {paymentStatus.translation}
      </StatusBadge>
    );

    return {
      __id: value.id,
      __entity: value,
      __etag: value._etag,
      __invoiceStatus: invoiceStatus_.translation,
      __paymentStatus: paymentStatus.translation,
      invoiceNum: (
        <EmptyLink id={`${routedEntitySubjectType.invoice}-${value.id}`} url={`${rootUrl}/invoices/${value.id}`}>
          {value.invoiceNum}
        </EmptyLink>
      ),
      status: (
        <StatusBadge color={ICON_COLORS[invoiceStatus_.status]} type="bare">
          {invoiceStatus_.translation}
        </StatusBadge>
      ),
      currentPaymentStatus:
        paymentStatus.status === 'overdue' ? (
          <div data-tip={getInvoiceOverdueDescription(i18n, amountOfDays)} data-for="invoices-table-tip">
            {paymentStatusJSX}
          </div>
        ) : (
          paymentStatusJSX
        ),
      customerAccountNumber: <Debtor debtor={value.debtor} />,
      contractNumber: value.contractId ? (
        <Link
          id={`contract-${value.contractId}`}
          url={`${rootUrl}/contracts/${value.contractId}`}
        >{`${value.contractNumber}`}</Link>
      ) : (
        <Paragraph>{value.contractNumber}</Paragraph>
      ),
      totalAmountInclVAT: formatMoney(value.totalAmountInclVAT, i18n.culture),
      openAmount: formatMoney(value.remainingInvoiceAmount, i18n.culture),
      invoiceDate: formatDate(value.invoiceDate),
      period: formatPeriod(value.periodStartDateTime, value.periodEndDateTime),
      cycle: (
        <Paragraph>
          {value.collectionDetails && !value.collectionDetails.closed ? value.collectionDetails.workflowName : ''}
        </Paragraph>
      ),
      nextStep: <Paragraph>{value.collectionDetails ? value.collectionDetails.nextStepName : ''}</Paragraph>,
      type: enumReducer.getTranslation('invoiceType', value.type),
      requireAttention: renderRequireAttention(value)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    setSelectedIds,
    setStopIndex,
    handleSort
  } = useListPage<InvoiceType, InvoiceRowType>({ endpoint: '/bill/invoices', processRecord, domain: 'invoice' });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useInvoiceListPage;
