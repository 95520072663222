import { makeObservable } from 'mobx';

import RootStore from '../../../app-context/stores';
import PaymentsService from '../services/PaymentsService';

export default class PaymentsStore {
  public rootStore: RootStore;
  public paymentsService: PaymentsService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.paymentsService = new PaymentsService(this);

    makeObservable(this, {});
  }
}
