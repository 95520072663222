import { observer } from 'mobx-react';
import React from 'react';

import { templateUsecase } from '@zf/api-types/enums';
import { Card, CardBody } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../../hooks/useStore';
import EmailCardBody from './email-card-body';
import css from './email-card.module.scss';
import CardHeaderUseCase from '../components/CardHeaderUseCase';

const EmailCard = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="email-card" className={css['email-card']}>
      <CardHeaderUseCase useCase={templateUsecase.email}>{getTranslation('communication.email')}</CardHeaderUseCase>
      <CardBody type="grid">
        <EmailCardBody />
      </CardBody>
    </Card>
  );
};

export default observer(EmailCard);
