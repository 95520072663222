import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';

import usePrevious from '@zf/hooks/src/usePrevious';

import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { useStore } from '../../hooks/useStore';
import useUnbilled from './hooks/useUnbilled';
import NoUnbilled from './no-unbilled';
import { UnbilledListQueryParams } from './unbilled-list-card';

export type Props = {
  queryParams: UnbilledListQueryParams;
};

export type SetState = (newState: { currentId?: string; timeStamp?: number }) => void;

const UnbilledList = (props: Props) => {
  const { queryParams } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const {
    rows,
    sortableFields,
    loading,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectedItems,
    error,
    setState,
    handleSort,
    setStopIndex
  } = useUnbilled(queryParams);

  const [columns] = useState([
    {
      width: 160,
      label: getTranslation('contracts.list.contract_number'),
      dataKey: 'contractId'
    },
    {
      width: 300,
      label: getTranslation('customer.customer'),
      dataKey: 'customer'
    },
    {
      width: 180,
      label: getTranslation('invoice.invoicetype'),
      dataKey: 'invoiceType'
    },
    {
      width: 280,
      label: getTranslation('general.period'),
      dataKey: 'PeriodStartDateTime'
    },
    {
      width: 140,
      label: getTranslation('invoice.invoice_due'),
      dataKey: 'overdue'
    },
    {
      width: 340,
      label: getTranslation('contracts.missing_advances'),
      dataKey: 'missingAdvances'
    },
    {
      width: 160,
      label: getTranslation('analytics.missing_for'),
      dataKey: 'missingFor'
    },
    {
      width: 240,
      label: getTranslation('general.status'),
      dataKey: 'status'
    }
  ]);

  const previousRows = usePrevious(rows);

  useEffect(() => {
    // When our table refreshes and some ids are gone because of status change, update our selection
    if (previousRows && previousRows.length !== rows.length) {
      const checkedSelection = selectedItems.filter((item) => {
        return rows.some((r) => r.__entity.id === item);
      });
      setState({ selectedItems: checkedSelection });
    }
  }, [rows]);

  const onSelectRow = useCallback((ids: string[]) => setState({ selectedItems: ids }), [setState]);

  return (
    <DynamicVirtualTable
      id="unbilled-table"
      tooltipId="unbilled-table-tip"
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      error={error}
      rows={rows}
      rowHeight={45}
      sortableFields={sortableFields}
      loading={loading}
      NoDataOverlay={NoUnbilled}
      singleSort
      sort={handleSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      totalAmountOfRows={totalAmountOfRows}
      onSelectRow={onSelectRow}
      selectedRows={selectedItems}
      columns={columns}
    />
  );
};

export default observer(UnbilledList);
