import React from 'react';

export default function IeDetect() {
  return (
    <div>
      <h1>Internet explorer is not supported.</h1>
      <p>
        This is due to Internet Explorer being discontinued by Microsoft and vulnerable to security attacks, we value
        your security and therefore do not support this.
      </p>
      <p>
        To run this application please use <a href="https://www.google.com/intl/nl_ALL/chrome/">Chrome</a>,{' '}
        <a href="https://www.mozilla.org/nl/firefox/new/">Firefox</a> or{' '}
        <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>
      </p>
    </div>
  );
}
