import classNames from 'classnames';
import { Moment } from 'moment';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import css from './days-container.module.scss';

type Props = {
  focusedDate: Moment;
  selectedDays: Array<number>;
  onSelect: (day: number) => void;
  today?: number;
  startRange?: number;
  stopRange?: number;
};

export default function DaysContainer(props: Props) {
  const { onSelect, selectedDays, today, focusedDate, startRange = 0, stopRange = 0 } = props;

  let i = 1;
  const days = new Array(focusedDate.endOf('month').date() + focusedDate.startOf('month').day())
    .fill('')
    .map((val, index) => (index >= focusedDate.startOf('month').day() ? i++ : -1));

  return (
    <div className={classNames(css['calendar-container'], css['days-container'])}>
      {days.map((day, j) => (
        <BaseButton
          id={`${day}-${j}`}
          className={classNames(css['calendar-item'], css['day'], {
            [css['selected']]: selectedDays.includes(day),
            [css['range']]: day > -1 && day > startRange && day < stopRange,
            [css['highlight']]: day === today,
            [css['disabled']]: day === -1
          })}
          onClick={() => (day === -1 ? null : onSelect(day))}
          key={`day-${j}`}
        >
          {day > -1 ? day : null}
        </BaseButton>
      ))}
    </div>
  );
}
