import React from 'react';

import { ErrorPage } from '../Error';
import { ErrorOverlayProps } from '@zf/stella-react/src/atoms/Table/table-overlay';

export default function TableError(props: ErrorOverlayProps) {
  const { error } = props;

  return <ErrorPage error={error} />;
}
