import { observer } from 'mobx-react';
import React from 'react';

import ConfigColumn from '../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../components/Column/EmptyColumn';
import { useStore } from '../../../../hooks/useStore';
import useActivities from './hooks/useActivities';
import RightPart from './RightPart';
import css from './wizard-column.module.scss';

const WizardColumn = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { task, selectedActivity, existingEntities, getActivityStatus } = useActivities();

  if (!task) return null;

  return (
    <ConfigColumn className={css['wizard-column']} bodyClassName={css['wizard-column-body']} borderRight={false}>
      {selectedActivity ? (
        <RightPart
          task={task}
          selectedActivity={selectedActivity}
          existingEntities={existingEntities}
          getActivityStatus={getActivityStatus}
        />
      ) : (
        <EmptyColumn image="tasks" text={getTranslation('tasks.wizard_column_empty_state')} />
      )}
    </ConfigColumn>
  );
};

export default observer(WizardColumn);
