import { observer } from 'mobx-react';
import React from 'react';

import { navigate } from '@reach/router';
import { MeterPropertiesPrepayment, MeterType } from '@zf/api-types/master-data/meter';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { Button, CardBody } from '../../../../../../design-system/Components';
import { DetailLine } from '../../../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';

export type PropsType = {
  prepaymentDevice: MeterType | null;
};

const PrepaymentDeviceCard = (props: PropsType) => {
  const { prepaymentDevice } = props;
  const { applicationStore } = useStore();
  const { culture, rootUrl, getTranslation } = applicationStore;

  if (!prepaymentDevice) return null;

  const properties = prepaymentDevice.meterProperties as MeterPropertiesPrepayment;

  return (
    <Card id="prepayment-device-card" className='card' role='dialog'>
      <CardHeader
        extraRight={
          <Button
            id="to_device_btn"
            onClick={() => navigate(`${rootUrl}/devices/prepayment-devices/${prepaymentDevice.id}`)}
            type="text"
          >
            {getTranslation('prepayment_device.to_device')}
          </Button>
        }
      >
        {getTranslation('prepayment_device.prepayment_device_name', {
          prepaymentDeviceName: prepaymentDevice.serialNumber
        })}
      </CardHeader>
      <CardBody>
        <DetailLine label={getTranslation('prepayment_device.balance')}>
          <Paragraph color={properties.currentBalance === 0 ? colors['red-600'] : undefined} bold>
            {formatMoney(properties.currentBalance, culture)}
          </Paragraph>
        </DetailLine>

        <DetailLine label={getTranslation('meter.last_top_up')}>
          <Paragraph>{formatDate(properties.lastTopUpDate)}</Paragraph>
        </DetailLine>

        <HorizontalDivider />

        <DetailLine label={getTranslation('prepayment_device.emergency_credit')}>
          <Paragraph color={properties.currentBalance === 0 ? colors['red-600'] : undefined}>
            {formatMoney(properties.configuredEmergencyCredit, culture)}
          </Paragraph>
        </DetailLine>
      </CardBody>
    </Card>
  );
};

export default observer(PrepaymentDeviceCard);
