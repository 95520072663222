import {
  InvoiceBillingConfigurationSuggestions,
  InvoiceProductConfigurationSuggestions
} from '@zf/api-types/billing/property-group-billing-configuration';

import { METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';
import { invoiceFrequency } from '@zf/api-types/enums';
import { Moment } from 'moment';

export default class PropertyGroupBillingConfigurationService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/bill/PropertyGroupBillingConfiguration/', applicationStore);
  }

  getInvoiceProductConfigurationSuggestions = async (serviceLocationIds: string[], contractId?: string) => {
    return (
      await this.applicationStore.sendRequest<InvoiceProductConfigurationSuggestions>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}suggestions/product`,
          query: contractId
            ? {
                serviceLocationIds,
                contractId
              }
            : {
                serviceLocationIds
              }
        }
      })
    ).data;
  };

  getInvoiceBillingConfigurationSuggestions = async (serviceLocationIds: string[], contractId?: string) => {
    return (
      await this.applicationStore.sendRequest<InvoiceBillingConfigurationSuggestions>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}suggestions/invoicebillingconfiguration`,
          query: contractId
            ? {
                serviceLocationIds,
                contractId
              }
            : {
                serviceLocationIds
              }
        }
      })
    ).data;
  };

  getFirstInvoiceEndDateTime = async (
    serviceLocationIds: string[],
    invoiceFrequency: invoiceFrequency,
    invoiceStartDateTimeUTC: Moment
  ) => {
    return (
      await this.applicationStore.sendRequest<any>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}suggestions/firstInvoiceEndDateTime`,
          query: {
            serviceLocationIds,
            invoiceFrequency,
            invoiceStartDateTimeUTC: invoiceStartDateTimeUTC.toISOString()
          }
        }
      })
    ).data;
  };
}
