import { Spinner, SpinnerWrapper } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { MeteringValidationParameters } from '@zf/api-types/meter-config';

import ValidationTab from './validation-tab';

const ValidationTabWrapper = () => {
  const { configStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getMeteringValidationParameters } = configStore.configService;

  const [meteringValidationParameters, setMeteringValidationParameters] = useState<MeteringValidationParameters>();

  useEffect(() => {
    getMeteringValidationParameters()
      .then((res) => {
        // @ts-ignore REMOVE WHEN API UPDATED
        delete res.validations;
        setMeteringValidationParameters(res);
      })
      .catch((error) => {
        notify.error({
          content: getTranslation('general.get_cfg_fail'),
          error
        });
      });
  }, []);

  if (!meteringValidationParameters)
    return (
      <SpinnerWrapper hasTabHeight>
        <Spinner centered />
      </SpinnerWrapper>
    );

  return <ValidationTab meteringValidationParameters={meteringValidationParameters} />;
};

export default observer(ValidationTabWrapper);
