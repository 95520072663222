import React from 'react';

import { importJobStatus } from '@zf/api-types/enums';
import { ImportJobRowType, ImportJobType } from '@zf/api-types/import-job';
import useDialog from '@zf/hooks/src/useDialog';

import TriggerImportJobDialog from '../../../actions/import-jobs/trigger-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function TriggerImportJobsListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('importjob');
  const selectedRows: ImportJobRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<ImportJobType>('importjob');
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.some(
      (row) =>
        row.__importJobEntity.status === importJobStatus.storing ||
        row.__importJobEntity.status === importJobStatus.processing
    );

  return (
    <DropdownAction
      id="import_jobs.trigger"
      icon="export"
      dialogTitle={i18n.getTranslation('actions.trigger_import_job')}
      dialogContent={
        <TriggerImportJobDialog
          onComplete={setUpdatedRows}
          ref={clickRef}
          selectedRows={selectedRows}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={i18n.getTranslation('actions.trigger_import_job')}
    />
  );
}
