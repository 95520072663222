import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { contactType, customerType } from '@zf/api-types/enums';
import { ContactEntryType } from '@zf/api-types/general';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { CustomerRowType, CustomerType } from '@zf/api-types/master-data/customer';
import { formatPhoneNumber } from '@zf/utils/src/number';

import useListPage from '../../../app-context/hooks/use-listpage';
import IconParagraph from '../../../components/Icon/IconParagraph';
import EmptyLink from '../../../components/Link/EmptyLink';
import { useStore } from '../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';

const useCustomerListPage = (
  customEntityPropertyTypes: CustomEntityPropertyType[],
  querParams?: { customerGroupId: string; searchValue: string },
  isEmbedded?: boolean
) => {
  const { applicationStore } = useStore();
  const { rootUrl, getEnumTranslation, getTranslation } = applicationStore;

  const processRecord = (value: CustomerType): any => {
    value.contactDetails.sort((cd1: ContactEntryType, cd2: ContactEntryType) => {
      // Sort by type
      if (cd1.contactType > cd2.contactType) return 1;
      else return -1;
    });

    const iconName = 'customer';

    return {
      __id: value.id,
      __etag: value._etag,
      __customerEntity: value,
      __customEntityPropertyTypes: customEntityPropertyTypes,
      AccountNumber: (
        <>
          {isEmbedded ? (
            value.accountNumber && value.shortDisplayName && `${value.accountNumber} - ${value.shortDisplayName}`
          ) : (
            <EmptyLink
              id={`${routedEntitySubjectType.customer}-${value.id}`}
              url={`${rootUrl}/customers/${value.id}`}
              icon={value.customerType === customerType.organization ? 'organisation' : iconName}
            >
              {value.accountNumber && value.shortDisplayName && `${value.accountNumber} - ${value.shortDisplayName}`}
            </EmptyLink>
          )}
        </>
      ),
      streetName: value.invoiceAddress.localizedDisplay,
      defaultPaymentMethod: getEnumTranslation('paymentMethod', value.defaultPaymentMethod),
      customerGroup: value.customerGroup ? value.customerGroup.name : ' ',

      ...getExtraCustomEntityColumns(value.customProperties, customEntityPropertyTypes, getTranslation),

      email: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.email) {
          return (
            <div key={`contact-email-${value.id}-${e.value}-${index}`}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {e.value}
              </IconParagraph>
            </div>
          );
        }
        return null;
      }),
      telephone: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.telephone) {
          return (
            <div key={`contact-telephone-${e.value}-${e.value}-${index}`} data-tip={e.value}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {formatPhoneNumber(e.value)}
              </IconParagraph>
            </div>
          );
        }
        return null;
      }),
      mobilePhone: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.mobiletelephone) {
          return (
            <div key={`contact-mobile-${e.value}-${e.value}-${index}`} data-tip={e.value}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {formatPhoneNumber(e.value)}
              </IconParagraph>
            </div>
          );
        }
        return null;
      }),
      website: value.contactDetails.map((e, index) => {
        if (e.contactType === contactType.website) {
          return (
            <div key={`contact-website-${value.id}-${e.value}-${index}`} data-tip={value.id}>
              <IconParagraph
                tooltipFor="customers-table-tip"
                title={getEnumTranslation('contactType', e.contactType)}
                iconType={e.contactType}
                usedInTable
              >
                {e.value}
              </IconParagraph>
            </div>
          );
        }
        return null;
      })
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<CustomerType, CustomerRowType>({
    endpoint: `/md/customers`,
    processRecord,
    domain: 'customer',
    defaultQueryParams: {
      ...querParams
    }
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useCustomerListPage;
