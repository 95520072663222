import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useReducer, useState } from 'react';

import { GetBankAccountsResponseType } from '@zf/api-types/general';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { CreatePaymentType, Reference } from '@zf/api-types/payments';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { startOfDay } from '@zf/utils/src/date';
import { formatIBAN } from '@zf/utils/src/iban';

import { useAppContext } from '../../app-context';
import InputField from '../../components/input/InputField';
import MoneyInput from '../../components/input/MoneyInput';
import MultiLineInput from '../../components/input/MultiLineInput';
import DatePicker from '../../components/Lang/DatePicker';
import Dropdown from '../../components/Lang/Dropdown';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { notify } from '../../events/notification-events';
import useSingleAPI from '../../hooks/useSingleAPI';
import { createPayment } from './payments';
import { SettleState } from './settle';
import SettleHelper from './settle-helper';
import { useStore } from '../../hooks/useStore';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import { countryCode } from '@zf/api-types/enums';

type Props = {
  onComplete: () => void;
  customer: CustomerType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

type StatePaymentValues = {
  iban: string | null;
  customerId: string;
  amount: number;
  paymentDateTime: string;
  companyBankAccountId: string;
  paymentReference: string;
  remittanceMessage: string;
  references: Reference[];
  remainingAmount: number;
  selectedIds: string[];
  refreshTrigger: string;
  structuredMessage: boolean;
};

type DispatchPaymentValues = Partial<StatePaymentValues>;

const AddPaymentDialog = forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, customer, validationRef } = props;
  const { tenantReducer } = useAppContext();
  const { customerStore, applicationStore } = useStore();
  const { userStore, getTranslation } = applicationStore;
  const { lang } = userStore;

  const stateReducer = createStateReducer<StatePaymentValues, DispatchPaymentValues>();
  const [paymentValues, dispatchPaymentValue] = useReducer(stateReducer, {
    iban: null,
    customerId: customer.id,
    amount: 0,
    paymentDateTime: startOfDay(moment().subtract(1, 'days')).toISOString(),
    companyBankAccountId: '',
    paymentReference: '',
    remittanceMessage: '',
    references: [],
    remainingAmount: 0,
    selectedIds: [],
    refreshTrigger: '',
    structuredMessage: true
  });

  const organizationApi = useSuspenseSingleAPI<OrganizationConfigType>(
    {
      request: {
        endpoint: '/cfg/Organizations/',
        selector: tenantReducer.state.organization?.organizationId
      }
    },
    false,
    false
  );

  //this will have to be removed to a format payment reference util when different countries are introduced.
  const paymentReferenceChars = organizationApi.result?.data.address?.country === countryCode.bel ? '+++' : '';

  const initialCustomerBankAccount = customerStore.confirmedAccounts.map((e) => {
    return {
      value: e.iban,
      text: `${formatIBAN(e.iban)}`
    };
  });

  const [bankAccountsCustomerDropdown] = useState(initialCustomerBankAccount);

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(
        !paymentValues.amount ||
          (paymentValues.paymentReference.length > 140 && !paymentValues.structuredMessage) ||
          !paymentValues.companyBankAccountId
      );
    }
  };

  //this function will eventually be reworked because of internationalization
  const formatPaymentReference = (reference: string) => {
    if (paymentValues.structuredMessage) {
      return paymentReferenceChars + reference + paymentReferenceChars;
    } else {
      return reference;
    }
  };

  useEffect(() => {
    validate();
  }, [
    paymentValues.amount,
    paymentValues.references,
    paymentValues.paymentReference,
    paymentValues.companyBankAccountId
  ]);

  useEffect(() => {
    if (paymentValues.paymentReference.length > 140 && !paymentValues.structuredMessage) {
      notify.warning({
        content: getTranslation('payments.remittance_msg_warning')
      });
    }
  }, [paymentValues.paymentReference]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const paymentRequest: CreatePaymentType = {
          amount: paymentValues.amount,
          companyBankAccountId: paymentValues.companyBankAccountId,
          customerId: paymentValues.customerId,
          iban: paymentValues.iban,
          paymentDateTime: paymentValues.paymentDateTime,
          paymentReference: formatPaymentReference(paymentValues.paymentReference),
          references: paymentValues.references
        };

        await createPayment(paymentRequest, tenantReducer, lang);

        if (onComplete) onComplete();

        notify.success({
          content: getTranslation('actions.payments.add_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('actions.payments.add_failed'),
          error: e
        });
      }
    }
  }));

  const responseBankAccounts = useSingleAPI<GetBankAccountsResponseType>({
    request: {
      endpoint: '/cfg/BankAccounts/'
    }
  });

  useEffect(() => {
    if (responseBankAccounts.result && !(responseBankAccounts.result instanceof Promise)) {
      if (responseBankAccounts.result.data.results.length === 1) {
        dispatchPaymentValue({
          companyBankAccountId: responseBankAccounts.result.data.results[0].id
        });
      }
    }
  }, [responseBankAccounts.result]);

  // Making TS happy here
  const setStateAdapter = (value: Partial<SettleState>) => {
    dispatchPaymentValue(value);
  };

  const setReferences = (newRefs: Reference[]) => {
    dispatchPaymentValue({ references: newRefs });
  };

  // Making TS happy here
  const compatibleState: SettleState = useMemo(() => {
    return {
      amount: paymentValues.amount,
      remainingAmount: paymentValues.remainingAmount,
      selectedIds: paymentValues.selectedIds,
      refreshTrigger: paymentValues.refreshTrigger
    };
  }, [paymentValues.amount, paymentValues.remainingAmount, paymentValues.selectedIds, paymentValues.refreshTrigger]);

  if (!responseBankAccounts.result || responseBankAccounts.result instanceof Promise) return null;

  const bankAccountsDropdown = responseBankAccounts.result.data.results.map((ba) => ({
    value: ba,
    text: `${formatIBAN(ba.iban)} - ${ba.accountHolder}`
  }));

  return (
    <>
      <Paragraph>
        {getTranslation('actions.payments.create_payment_customer', {
          customer: `${customer.accountNumber} - ${customer.shortDisplayName}`
        })}
      </Paragraph>
      <InputContainer>
        <MoneyInput
          id="money-input"
          onChange={(val) => dispatchPaymentValue({ amount: val, remainingAmount: val })}
          value={paymentValues.amount}
          placeholder={getTranslation('general.amount_valuta')}
          error={paymentValues.amount === 0}
        />
        <DatePicker
          id="start-date"
          onChange={(value) => dispatchPaymentValue({ paymentDateTime: value.toISOString() })}
          value={moment(paymentValues.paymentDateTime)}
        />
        <SimpleDropdown
          id="iban-input"
          onChange={(val) => dispatchPaymentValue({ iban: val[0] })}
          values={bankAccountsCustomerDropdown}
          selectedValues={[paymentValues.iban]}
          placeholder={getTranslation('payments.customer_iban')}
        />

        <Dropdown
          id="organization-bank-input"
          onChange={(val) =>
            dispatchPaymentValue({
              companyBankAccountId: val[0] ? val[0].id : ''
            })
          }
          values={bankAccountsDropdown}
          selectedValues={[paymentValues.companyBankAccountId]}
          error={!paymentValues.companyBankAccountId}
          placeholder={getTranslation('payments.iban_organization')}
        />
        <div>
          <ZFToggle
            onChange={(val) => dispatchPaymentValue({ structuredMessage: val })}
            checked={paymentValues.structuredMessage}
            title={getTranslation('payments.structured')}
            description={getTranslation('payments.structured_info')}
          />
        </div>
        <div>
          <Label>{getTranslation('payments.reference')}</Label>
          {paymentValues.structuredMessage ? (
            <InputField
              centerValue
              prefix={paymentReferenceChars}
              id="reference-input-structured"
              onChange={(val) => dispatchPaymentValue({ paymentReference: val })}
              value={paymentValues.paymentReference}
              postfix={paymentReferenceChars}
            />
          ) : (
            <MultiLineInput
              id="payments.refund_transaction"
              value={paymentValues.paymentReference}
              onChange={(val) => dispatchPaymentValue({ paymentReference: val })}
            />
          )}
        </div>
      </InputContainer>

      <SettleHelper
        customerId={customer.id}
        state={compatibleState}
        setState={setStateAdapter}
        setReferences={setReferences}
      />
    </>
  );
});

export default AddPaymentDialog;
