import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';

import css from './paragraph.module.scss';

export type TextAlignment = 'left' | 'center' | 'right';

export type ParagraphProps = {
  id?:string;
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
  color?: string;
  info?: string;
  textAlign?: TextAlignment;
  style?: CSSProperties;
};

export default function Paragraph(props: ParagraphProps) {
  const { id, className, color, children, textAlign, style, bold = false } = props;

  const joinedStyle = useMemo(() => {
    let res: CSSProperties = { ...style };

    if (color) {
      res.color = color;
    }

    if (textAlign) {
      res.textAlign = textAlign;
    }

    return res;
  }, [color, textAlign, style]);

  return (
    <p
      className={classNames(
        css['paragraph'],
        {
          [css['text-bold']]: bold
        },
        className
      )}
      id={id}
      style={joinedStyle}
    >
      {children || typeof children === 'number' ? children : '-'}
    </p>
  );
}
