import { ICON_COLORS } from 'constants/icons';
import { RadioInput } from 'design-system/Components';
import { DetailLine, InfoGrid } from 'design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { invoiceStatus, invoiceType } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { formatPeriod } from '@zf/utils/src/date';

import css from './keep-existing-invoices-section.module.scss';

type Props = {
  customerId: string;
  contractId: string;
  mutationDate: Moment | null;
  keepExistingInvoiceOnEndDate: boolean;
  setKeepExistingInvoiceOnEndDate: (val: boolean) => void;
};

const KeepExistingInvoiceSection = (props: Props) => {
  const { customerId, contractId, mutationDate, keepExistingInvoiceOnEndDate, setKeepExistingInvoiceOnEndDate } = props;

  const { applicationStore, invoiceStore } = useStore();
  const { getTranslation } = applicationStore;
  const { invoiceApiService, getInvoiceStatus, getPaymentStatus } = invoiceStore;
  const { getInvoicesForCustomerAndContract } = invoiceApiService;

  const [invoice, setInvoice] = useState<InvoiceType>();

  useEffect(() => {
    if (mutationDate) {
      getInvoicesForCustomerAndContract(customerId, contractId).then((res) => {
        const existingInvoice = res.find(
          (i) =>
            i.status !== invoiceStatus.created &&
            i.type === invoiceType.invoice &&
            mutationDate.isBetween(i.periodStartDateTime, i.periodEndDateTime, undefined, '[]')
        );

        setInvoice(existingInvoice);
      });
    }
  }, [customerId, contractId, mutationDate]);

  if (!invoice) return null;

  const invoiceStatus_ = getInvoiceStatus(invoice);
  const paymentStatus = getPaymentStatus(invoice);

  return (
    <>
      <Paragraph className={css['choose-option']} bold>
        {getTranslation('general.choose_option')}
      </Paragraph>
      <RadioInput
        onChange={(option) => setKeepExistingInvoiceOnEndDate(option === 'true')}
        value="true"
        name="keep-invoice"
        checked={keepExistingInvoiceOnEndDate}
      >
        {getTranslation('actions.contract.keep_existing_invoice')}
      </RadioInput>

      <InfoGrid className={css['existing-invoice']} nCols={1}>
        <DetailLine label={getTranslation('invoice.invoice_num')}>
          <Paragraph>{invoice.invoiceNum || getTranslation('general.empty')}</Paragraph>
        </DetailLine>

        <DetailLine label={getTranslation('general.status')}>
          <NewStatusBadge status_={invoiceStatus_.translation} color={ICON_COLORS[invoiceStatus_.status]} />
        </DetailLine>

        <DetailLine label={getTranslation('invoices_list.labels.payment_status')}>
          <NewStatusBadge status_={paymentStatus.translation} color={ICON_COLORS[paymentStatus.status]} />
        </DetailLine>

        <DetailLine label={getTranslation('invoice.invoice_period')}>
          <Paragraph>{formatPeriod(invoice.periodStartDateTime, invoice.periodEndDateTime)}</Paragraph>
        </DetailLine>
      </InfoGrid>

      <RadioInput
        onChange={(option) => setKeepExistingInvoiceOnEndDate(option === 'true')}
        value="false"
        name="dont-keep-invoice"
        checked={!keepExistingInvoiceOnEndDate}
      >
        {getTranslation('actions.contract.dont_keep_existing_invoice')}
      </RadioInput>
    </>
  );
};

export default observer(KeepExistingInvoiceSection);
