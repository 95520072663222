import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { MeterPaginatedResult, MeterType } from '@zf/api-types/master-data/meter';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import useSuspenseSingleAPI from '../../../../../../../hooks/useSuspenseSingleAPI';

type UseMetersState = {
  individualMeters: MeterType[];
  rootMeters: MeterType[];
  aggregatingMeters: MeterType[];
  communalMeters: MeterType[];
};

type UseMetersReturnType = {
  individualMeters: MeterType[];
  rootMeters: MeterType[];
  aggregatingMeters: MeterType[];
  communalMeters: MeterType[];
  addMetersToState: (newMeters: MeterType[]) => void;
} | null;

export const useMeters = (propertyGroupId: string): UseMetersReturnType => {
  const stateReducer = createStateReducer<UseMetersState, Partial<UseMetersState>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    individualMeters: [],
    rootMeters: [],
    aggregatingMeters: [],
    communalMeters: []
  });

  const response = useSuspenseSingleAPI<MeterPaginatedResult>({
    request: {
      endpoint: '/md/Meters',
      query: { propertyGroupIds: [propertyGroupId] }
    }
  });

  React.useEffect(() => {
    if (response.result) {
      const meters = response.result.data.results;

      const individualMeters: MeterType[] = [];
      const rootMeters: MeterType[] = [];
      const aggregatingMeters: MeterType[] = [];
      const communalMeters: MeterType[] = [];

      meters.forEach((m) => {
        switch (m.meterType) {
          case meterType.individual:
            individualMeters.push(m);
            break;
          case meterType.root:
            rootMeters.push(m);
            break;
          case meterType.aggregating:
            aggregatingMeters.push(m);
            break;
          case meterType.communal:
            communalMeters.push(m);
            break;
        }
      });

      setValue({
        individualMeters: individualMeters,
        rootMeters: rootMeters,
        aggregatingMeters: aggregatingMeters,
        communalMeters: communalMeters
      });
    }
  }, [response.result]);

  if (!response.result) return null;

  const addMetersToState = (newMeters: MeterType[]) => {
    if (newMeters[0]) {
      switch (newMeters[0].meterType) {
        case meterType.individual:
          setValue({ individualMeters: [...values.individualMeters, ...newMeters] });
          break;
        case meterType.root:
          setValue({ rootMeters: [...values.rootMeters, ...newMeters] });
          break;
        case meterType.aggregating:
          setValue({ aggregatingMeters: [...values.aggregatingMeters, ...newMeters] });
          break;
        case meterType.communal:
          setValue({ communalMeters: [...values.communalMeters, ...newMeters] });
          break;
      }
    }
  };

  return {
    individualMeters: values.individualMeters,
    rootMeters: values.rootMeters,
    aggregatingMeters: values.aggregatingMeters,
    communalMeters: values.communalMeters,
    addMetersToState
  };
};
