import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';
import { randomInt } from '@zf/utils/src/number';

import { Popup, usePopupTrigger } from '../Popup';
import css from './coachmark.module.scss';

type PropsType = {
  className?: string;
  children?: React.ReactNode;
  warning?: boolean;
  info?: boolean;
  usePopup?: boolean;
  size?: 'default' | 'small';
};

export default function CoachMark(props: PropsType) {
  const { children, warning = false, info = false, usePopup = true, size = 'default', className } = props;

  const { showPopup, popupRef, togglePopup } = usePopupTrigger();

  const switchCharacter = () => {
    if (warning) {
      return '!';
    } else if (info) {
      return 'i';
    } else {
      return '?';
    }
  };

  const coackmarkContent = (
    <>
      <div className={classNames(css['coachmark-inner-circle'], { [css['warning']]: warning })}>
        <span className={classNames(css['coachmark-icon'], { [css['warning']]: warning })}>{switchCharacter()}</span>
      </div>
      <div
        className={classNames(info ? css['coachmark-outer-circle-static'] : css['coachmark-outer-circle'], {
          [css['warning']]: warning
        })}
      />
    </>
  );

  return (
    <div className={classNames(css['coachmark-container'], { [css['warning']]: warning }, className)}>
      {usePopup ? (
        <BaseButton
          id={randomInt(0, 100).toString()}
          className={classNames(css['coachmark-circle'], css[size])}
          onClick={() => {
            if (usePopup) togglePopup();
          }}
        >
          {coackmarkContent}
        </BaseButton>
      ) : (
        <div className={classNames(css['coachmark-circle'], css[size])}>{coackmarkContent}</div>
      )}

      {usePopup && (
        <Popup
          className={classNames(css['coachmark-popup'], { [css['warning']]: warning })}
          popupRef={popupRef}
          show={showPopup}
        >
          {children}
        </Popup>
      )}
    </div>
  );
}
