import classNames from 'classnames';
import React from 'react';

import SearchBar from '@zf/heat-erp/src/components/input/SearchBar';

import Label from '../Label/Label';
import StellaLoader from '../Loader/StellaLoader';
import OutsideComponentClickDetector from '../Tooltip/OutsideComponentClickDetector';
import css from './dropdown.module.scss';
import DropdownClear from './DropdownClear';
import DropdownListMultiple from './DropdownListMultiple';
import { DropdownGenericBaseType, DropdownProps, DropdownValuesType } from './StellaDropdown';

export type shownValueType<T> = { value: DropdownValuesType<T>; index: number };

type Props<T> = DropdownProps<T> & {
  viewType: 'search' | 'dropdown';
  searchValue: string | undefined;
  focusedIndex: number | undefined;
  showTooltip: boolean;
  colorized: boolean;
  filters?: JSX.Element | null;
  details?: JSX.Element | null;
  totalAmountOfRecords?: number;
  setSearchValue: (searchValue: string) => void;
  setShowTooltip: (value: boolean) => void;
  setFocusedIndex: (value: number | undefined) => void;
  onClickItem: (i: number) => void;
  getShownValues: (values: DropdownValuesType<T>[], searchValue?: string) => shownValueType<T>[];
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export default function DropdownContentMultiple<T extends DropdownGenericBaseType>(props: Props<T>) {
  const {
    values,
    isMultipleSelected,
    showTooltip,
    searchValue,
    filters,
    details,
    selectedValues,
    focusedIndex,
    viewType,
    conditionMessage,
    totalAmountOfRecords,
    colorized,
    clear = false,
    loading = false,
    emptyText = 'No matches',
    filterText = 'Filter',
    detailsText = 'Details',
    clearText = 'Clear',
    extraRight,
    onChange,
    setShowTooltip,
    setSearchValue,
    setFocusedIndex,
    handleKeyDown,
    onClickItem,
    onClear
  } = props;

  const clearDropDown = () => {
    setFocusedIndex(-1);
    onChange([]);
    setSearchValue('');
    if (onClear) onClear();
  };

  const areListItems = values && values.length > 0;
  const showDetails = areListItems && details;

  return (
    <OutsideComponentClickDetector
      shouldTrigger={showTooltip || typeof showTooltip === 'undefined'}
      action={setShowTooltip}
    >
      <div
        key="dropdownWrapper"
        className={classNames(css['dropdown-popup'], { [css['dropdown-popup-grid']]: !!filters })}
      >
        {filters ? (
          <div className={css['filters-wrapper']}>
            <Label className={css['lbl-filters']}>{filterText}</Label>
            <div className={css['filters']}>{filters}</div>
          </div>
        ) : (
          <div className={css['searchbar']}>
            <SearchBar
              id="test-dropdown-searchbar"
              value={searchValue}
              debounceTime={500}
              onKeyDown={handleKeyDown}
              onChange={(val) => {
                setSearchValue(val);
                if (!val) onChange([]);
              }}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={showTooltip}
            />
          </div>
        )}

        {values || !loading ? (
          <>
            <div className={classNames({ [css['list-and-details']]: showDetails })}>
              <DropdownListMultiple
                extraRight={extraRight}
                values={values}
                totalAmountOfRecords={totalAmountOfRecords}
                selectedValues={selectedValues}
                focusedIndex={focusedIndex}
                emptyText={conditionMessage || emptyText}
                viewType={viewType}
                searchValue={searchValue}
                colorized={colorized}
                setFocusedIndex={setFocusedIndex}
                onClickItem={onClickItem}
                isMultipleSelected={isMultipleSelected}
              />

              {showDetails && (
                <div className={css['details']}>
                  <Label className={css['lbl-details']}>{detailsText}</Label>
                  {details}
                </div>
              )}
            </div>

            {clear && <DropdownClear clearText={clearText} clearDropDown={clearDropDown} />}
          </>
        ) : (
          <StellaLoader />
        )}
      </div>
    </OutsideComponentClickDetector>
  );
}
