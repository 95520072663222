import React from 'react';

import { invoiceStatus, sentStatus } from '@zf/api-types/enums';
import { InvoiceRowType } from '@zf/api-types/invoice';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useDownload from '../../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../../design-system/ComponentSets';

export default function DownloadInvoiceListPageButton() {
  const { myCurrent } = useCurrent('invoice');
  const { tenantReducer } = useAppContext();
  const { downloadFiles, downloadSingleFile } = useDownload();
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;

  const { i18n } = useAppContext();

  const disabled =
    selectedRows.length === 0 ||
    selectedRows.find(
      (invoice) => invoice.__entity.status !== invoiceStatus.generated && invoice.__entity.sent !== sentStatus.sent
    ) !== undefined;

  return (
    <DropdownAction
      id="invoice.download"
      icon="pdf"
      onClick={() => {
        const selectedIds = selectedRows.map((row) => {
          return row.__entity.attachmentId;
        });

        if (selectedIds.length === 1) {
          downloadSingleFile(`/att/attachments/${selectedIds[0]}`, tenantReducer);
        } else {
          downloadFiles(selectedIds, '/att/attachments/downloadzip', '/att/attachments/', 'attachmentIds');
        }
      }}
      disabled={disabled}
      actionType="direct"
    >
      {i18n.getTranslation('actions.download')}
    </DropdownAction>
  );
}
