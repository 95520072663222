import { observer } from 'mobx-react';
import React from 'react';

import { ProductType } from '@zf/api-types/product';
import ConfigCard from '@zf/stella-react/src/atoms/Card/ConfigCard';

import { useStore } from '../../../../../hooks/useStore';
import css from './product.module.scss';
import ProductBody from './ProductBody';

export type ProductProps = {
  index: number;
  product: ProductType;
  billingItemId?: string;
};

const Product = (props: ProductProps) => {
  const { product, billingItemId } = props;

  const { productConfigStore } = useStore();
  const { selectedProduct, setSelectedProduct } = productConfigStore.productsStore;

  return (
    <ConfigCard
      id={product.id}
      action={() => {
        setSelectedProduct(product, billingItemId);
      }}
      isActive={selectedProduct && selectedProduct.id === product.id}
      className={css['product-container-card']}
    >
      <div className={css['product-container-child']}>
        <ProductBody {...props} />
      </div>
    </ConfigCard>
  );
};

export default observer(Product);
