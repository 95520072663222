import Interweave from 'interweave';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../../design-system/Foundation';
import { notify } from '../../../events/notification-events';

type Props = {
  info: string;
  successMsg: string;
  errorMsg: string;
  submitFunction: Function;
};

const GenericDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { info, successMsg, errorMsg, submitFunction } = props;

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await submitFunction();

        notify.success({
          content: successMsg
        });
      } catch (error) {
        notify.error({
          content: errorMsg,
          error
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave content={info} />
    </Paragraph>
  );
});

export default GenericDialog;
