import useDialog from '@zf/hooks/src/useDialog';
import ConfirmationActionButton from 'components/Button/ConfirmationActionButton';
import { useStore } from 'hooks/useStore';
import React from 'react';
import { Button } from 'design-system/Components';
import Dialog from './Dialog';

export default function AddSettingDialog({
  submitFunction,
  hasCustomers
}: {
  submitFunction: Function;
  hasCustomers: boolean;
}) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { clickRef, onSubmit } = useDialog();

  return (
    <>
      {hasCustomers && (
        <ConfirmationActionButton
          id="customer.groups.add.settings"
          type="text"
          icon="plus"
          dialogTitle={getTranslation('customer_groups.settings.add')}
          dialogContent={
            <Dialog
              ref={clickRef}
              info={getTranslation('customer_groups.settings.add_desc')}
              successMsg={getTranslation('customer_groups.settings.added_success')}
              errorMsg={getTranslation('customer_groups.settings.added_fail')}
              submitFunction={() => submitFunction(false)}
            />
          }
          onSubmit={onSubmit}
          dialogType="default"
          size="small"
          buttonPositive={getTranslation('general.add')}
        />
      )}

      {!hasCustomers && (
        <Button id="customer.groups.add.settings" onClick={() => submitFunction(false)} type="text" icon="plus">
          {getTranslation('customer_groups.settings.add')}
        </Button>
      )}
    </>
  );
}
