import React, { useState } from 'react';
import { GLOBAL_VARS } from '@zf/utils/src/global';
import classNames from 'classnames';
import css from './fixed-virtual-table.module.scss';
import { createTooltipContent } from '@zf/utils/src/tooltip';
import { GatewayProvider } from 'react-gateway';
import { Provider } from '../../../app-context';
import { Icon } from '@zf/stella-react/src/atoms/Icon';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';

export type DynamicVirtualTableProps = {
  id: string;
  sortBy?: string[];
  sortDirection?: Record<string, SortDirection>;
  dynamicRowHeight?: boolean;
  noSelect?: boolean;
  singleSort?: boolean;
  sortableFields?: string[];
  leftIndent?: number;
  tooltipId?: string;
  handleSelectAll: () => void;
  handleSort: any;
  index: number;
  column: any;
  scaleTranslator: number;
  indent: number;
};

const HeaderCell = ({
  id,
  index,
  column,
  sortableFields,
  sortDirection,
  sortBy,
  noSelect,
  handleSelectAll,
  handleSort,
  scaleTranslator,
  indent,
  leftIndent,
  dynamicRowHeight,
  tooltipId
}: DynamicVirtualTableProps) => {
  const cellKey = `${id}-header-${index.toString()}`;
  const [overFlow, setOverFlow] = useState(false);
  let indicatorIcon = 'sort';

  const sortableField = sortableFields
    ? sortableFields.find((sortField: string) => sortField.toLowerCase().includes(column.dataKey.toLowerCase()))
    : '';

  if (sortableField && sortDirection && sortBy && sortBy.includes(column.dataKey)) {
    indicatorIcon = sortDirection[sortableField] === 'DESC' ? 'sort-down' : 'sort-up';
  }

  const handleTrigger =
    index === 0 && !noSelect
      ? () => {
          handleSelectAll();
        }
      : () =>
          handleSort({
            dataKey: column.dataKey,
            sortBy: sortableField ? sortableField : '',
            sortEnabled: sortableField !== undefined
          });

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleTrigger();
    }
  };

  const styleObjectHeader: React.CSSProperties = {
    position: 'absolute',
    height: '100%',
    width: column.width * scaleTranslator,
    left: indent,
    paddingLeft: `${leftIndent}rem`,
    color: column.color
  };

  // If text is right aligned, do this for header as well
  if (column.contentAlignRight) {
    column.headerAlignRight = true;
  }

  const styleObjectInnerHeader: React.CSSProperties = {
    display: 'flex',
    flexDirection: column.headerAlignRight ? 'row-reverse' : 'unset',
    marginRight: column.headerAlignRight ? GLOBAL_VARS['spacing-16'] : '0'
  };

  return (
    <div
      key={cellKey}
      className={classNames(
        css['virtual-table-cell'],
        css['virtual-table-header-cell'],
        sortableField && css['sortable-fields']
      )}
      role="button"
      aria-label={index === 0 ? 'Select all rows' : 'Sort column'}
      onClick={handleTrigger}
      onKeyDown={onKeyDown}
      style={styleObjectHeader}
      tabIndex={0}
    >
      <div className={css['virtual-table-header-cell-content-wrapper']} style={styleObjectInnerHeader}>
        <div
          className={classNames(
            css['virtual-table-cell-content'],
            {
              [css['fixed-height']]: !dynamicRowHeight
            },
            css['header-ellipsis']
          )}
          id={`header-${id}-${index}`}
          data-for={overFlow ? tooltipId : null}
          data-tip={
            overFlow
              ? createTooltipContent(
                  <GatewayProvider>
                    <Provider>{column.label}</Provider>
                  </GatewayProvider>
                )
              : null
          }
          ref={(element) => {
            if (element) {
              if (element.scrollWidth > element.offsetWidth) {
                setOverFlow(true);
              }
            }
          }}
        >
          {column.label}
        </div>
        {sortableField && <Icon name={indicatorIcon} className={css['virtual-table-header-cell-sort-icon']} />}
      </div>
    </div>
  );
};

export default HeaderCell;
