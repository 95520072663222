import { ExportIssuesListItem } from 'components/list-items/meters/ExportIssuesListItem';
import React, { Dispatch, SetStateAction } from 'react';

import { meterStatus } from '@zf/api-types/enums';
import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';

import InstallMeterListItem from '../../../../components/list-items/locations/InstallMeterListItem';
import AddMeasurementListItem from '../../../../components/list-items/meters/AddMeasurementListItem';
import ExportMeasurementsListItem from '../../../../components/list-items/meters/ExportMeasurementsListItem';
import ExportMeterConsumptionsListItem from '../../../../components/list-items/meters/ExportMeterConsumptionsListItem';
import LinkMeterListItem from '../../../../components/list-items/meters/LinkMeterListItem';
import ReconfigMeterListItem from '../../../../components/list-items/meters/ReconfigMeterListItem';
import UninstallMeterListItem from '../../../../components/list-items/meters/UninstallMeterListItem';
import { CreateMutingRuleListPageButton } from 'components/Button/Meter/CreateMutingRuleListPageButton';

type Props = {
  meter: MeterType;
  activeState: MeterStatusHistoryType | undefined;
  setMeter: Dispatch<SetStateAction<MeterType | null>>;
  refresh: () => void;
};

const MeterActions = (props: Props) => {
  const { meter, activeState, setMeter, refresh } = props;

  return (
    <>
      <AddMeasurementListItem meter={meter} onComplete={refresh} />
      {activeState && activeState.meterStatus !== meterStatus.installed && (
        <InstallMeterListItem meter={meter} type="forMeter" />
      )}
      {activeState && (
        <>
          <UninstallMeterListItem meter={meter} setMeter={setMeter} refresh={refresh} />
          <LinkMeterListItem
            meter={meter}
            setMeter={setMeter}
            refresh={refresh}
            linkChild={!meter.parentMeterRelation}
          />
          <ReconfigMeterListItem meter={meter} onComplete={refresh} />
          <CreateMutingRuleListPageButton useCase="detail-page" meters={[meter]} refresh={refresh}/>
        </>
      )}
      <ExportMeasurementsListItem meter={meter} />
      <ExportMeterConsumptionsListItem meter={meter} />

      <ExportIssuesListItem />
    </>
  );
};

export default MeterActions;
