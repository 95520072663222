import React from 'react';

import { colors } from '@zf/utils/src/color';
import { formatDecimal, roundNumber } from '@zf/utils/src/number';

import { Paragraph } from '../Paragraph';
import css from './custom-legend.module.scss';

export type LegendValuesType = { [key: string]: number };

type PayloadEntryType = {
  color: string;
  dataKey: string;
  inactive: boolean;
  type: string;
  value: string;
};

type Props = {
  payload?: PayloadEntryType[];
  legendValues?: LegendValuesType;
  locale: string;
};

export default function CustomLegend(props: Props) {
  const { payload = [], legendValues, locale } = props;

  // The order of the Bar components determines the legend order
  return (
    <div className={css['wrapper']}>
      {payload.map((entry, index) => {
        return (
          <div key={`item-${index}`}>
            <div className={css['legend-entry']}>
              <div className={css['color-div']} style={{ backgroundColor: entry.color }} />
              <div className={css['data-key-values']}>
                <Paragraph>{entry.value}</Paragraph>
                <Paragraph className={css['data-key-value-paragraph']}>
                  {legendValues ? formatDecimal(roundNumber(legendValues[entry.dataKey]), locale) : ''}
                </Paragraph>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
