import clone from 'clone';
import React from 'react';

import { UpdateIncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { CultureTableType } from '@zf/api-types/language';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useTranslations from '../../../app-context/hooks/use-translations';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import CommitSection from '../../../components/config/commit-section';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import LanguageIcon from '../../../components/Icon/LanguageIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import useCultureTable from '../../../app-context/hooks/use-culture-table';

type Props = {
  cultureTable: CultureTableType;
  config: UseConfigReturnType<UpdateIncomingInvoiceComponentType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdateIncomingInvoiceComponentType[]>,
    React.Dispatch<ValidatorCtxAction<UpdateIncomingInvoiceComponentType[]>>
  ];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

const createApiFriendlyValues = (component: UpdateIncomingInvoiceComponentType) => {
  return {
    name: component.name,
    description: component.description,
    id: component.id,
    optional: component.optional
  };
};

export default function IncomingInvoiceComponentsContent(props: Props) {
  const { cultureTable, config } = props;
  const { sort } = config;

  const { i18n } = useAppContext();
  const { openTranslationsDialog } = useTranslations<UpdateIncomingInvoiceComponentType>('IncomingInvoiceComponents');

  const { hasMultipleLanguages } = useCultureTable();

  const tableColumns: ColumnType[] = [
    {
      flexWidth: 2,
      label: i18n.getTranslation('general.name'),
      dataKey: 'name'
    },
    {
      flexWidth: 2,
      label: i18n.getTranslation('general.description'),
      dataKey: 'description'
    }
  ];

  if (hasMultipleLanguages) {
    tableColumns.push({
      width: 50,
      dataKey: 'languageAction'
    });
  }

  tableColumns.push({
    width: 50,
    dataKey: 'deleteAction'
  });

  const { values, isDirty, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator<UpdateIncomingInvoiceComponentType[]>(props.useTracked);

  const { addEntity, deleteEntity, handleSave, onCompleteLangUpdate } = useContextCRUD(props.useTracked);

  const addComponent = () => {
    addEntity({ name: '', description: '', id: '', optional: false });
  };

  const handleComponentsSave = async () => {
    return handleSave(
      createApiFriendlyValues,
      'name',
      '/cfg/IncomingInvoiceComponents',
      i18n.getTranslation('cost_allocation_config.update_invoice_componets_success'),
      i18n.getTranslation('cost_allocation_config.update_invoice_componets_fail')
    );
  };

  const setComponentValue = (
    index: number,
    value: UpdateIncomingInvoiceComponentType[keyof UpdateIncomingInvoiceComponentType],
    dataKey: keyof UpdateIncomingInvoiceComponentType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdateIncomingInvoiceComponentType[]
    });
  };

  const rows = values.map((component, index) => {
    return {
      name: (
        <InlineInputFieldInput
          id={`incoming_invoice_component.name.index-${index}`}
          value={component.name}
          onChange={(val) => setComponentValue(index, val, 'name')}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!component.name}
          error={!component.name}
        />
      ),
      description: (
        <InlineInputFieldInput
          id={`incoming_invoice_component.description.index-${index}`}
          value={component.description}
          onChange={(val) => setComponentValue(index, val, 'description')}
        />
      ),
      languageAction: cultureTable.supportedCultures.length > 1 && (
        <LanguageIcon
          id={`costcomponent.update_language.index-${index}`}
          tooltipFor="incoming-invoice-component-table"
          localisationLevel_={component.localisationLevel}
          hasMultipleLanguages={hasMultipleLanguages}
          action={() => openTranslationsDialog(index, component, onCompleteLangUpdate)}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`incoming_invoice_component.delete.index-${index}`}
          tooltipFor="incoming-invoice-component-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleComponentsSave} isDirty={isDirty} />
      <CardsContainer>
        <Card width="3" id="incoming-invoice-component-card">
          <CardHeader
            extraRight={
              <Button id="incoming_invoice_component.add" type="text" icon="plus" onClick={addComponent}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('cost_allocation_config.incoming_invoice_components')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="incoming-invoice-component-table"
              rows={rows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
              sortableFields={config.currentConfig?.sortableFields}
            />
          </CardBody>
        </Card>
      </CardsContainer>
    </>
  );
}
