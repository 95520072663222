import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';

export default function NoAttachmentsOverlay() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/attachments.png"
          alt={i18n.getTranslation('attachment.attachments')}
          title={i18n.getTranslation('attachment.attachments')}
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('attachment.no_attachments')}
    >
      {i18n.getTranslation('attachment.no_attachments_description')}
    </CardEmptyBody>
  );
}
