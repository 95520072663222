import { Moment } from 'moment';
import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';

import DateRangePicker from '../../input/DateRangePicker';
import YearRangePicker from './YearRangePicker';

type Props = {
  groupByPeriod: aggregationFrequency;
  startDateTime: Moment;
  endDateTime: Moment;
  setDates: (dates: Array<Moment | null | undefined>) => void;
  setYears: (years: number[]) => void;
};

export default function GraphDateFilters(props: Props) {
  const { groupByPeriod, startDateTime, endDateTime, setDates, setYears } = props;

  switch (groupByPeriod) {
    case aggregationFrequency.none:
    case aggregationFrequency.daily:
      return <DateRangePicker id="period" startDate={startDateTime} endDate={endDateTime} setDates={setDates} />;
    case aggregationFrequency.yearly:
    case aggregationFrequency.monthly:
      return <YearRangePicker startDateTime={startDateTime} endDateTime={endDateTime} setYears={setYears} />;
  }
}
