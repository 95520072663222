import React from 'react';

import { ServiceLocationRowType, ShortContractType } from '@zf/api-types/master-data/servicelocation';
import { formatDateForExport, isMaxDate } from '@zf/utils/src/date';

import useCurrent from '../../../app-context/hooks/use-current';
import { useStore } from '../../../hooks/useStore';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import ExportToExcel from './ExportToExcel';
import moment from 'moment';

type Props = {
  extraColumnTitles: string[];
};

export default function LocationExport(props: Props) {
  const { extraColumnTitles } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const { myCurrent } = useCurrent('location');
  const selectedRows: ServiceLocationRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = () => {
    const exportArray: string[][] = [];

    exportArray.push([
      getTranslation('location.street_name'),
      getTranslation('location.streetnr'),
      getTranslation('location.streetnraddition'),
      getTranslation('location.postal'),
      getTranslation('location.city'),
      getTranslation('location.country'),
      getTranslation('locations_list.labels.services'),
      getTranslation('locations_list.labels.supplied_services'),
      getTranslation('customer.customers'),
      getTranslation('property_groups.name'),
      getTranslation('location.external_identifier'),
      getTranslation('location.location_id'),
      getTranslation('actions.contract.contract_supplyEndDate'),
      getTranslation('banking_transaction.created'), //Needs own term on lang>en
      ...extraColumnTitles
    ]);

    selectedRows.forEach((r) => {
      const location = r.__entity;

      let customers = '';
      let contract: ShortContractType | undefined;
      let utilityTypes: string[] = [];

      r.__shortContracts.forEach((sc, index) => {
        customers += `${index !== r.__shortContracts.length - 1 ? ', ' : ''}${sc.contractorDisplayName}`;
      });

      contract = r.__terminatedContracts.reverse()[0];

      location.services.forEach((s) => {
        utilityTypes.push(getEnumTranslation('utilityType', s.utilityType));
        return utilityTypes;
      });

      const extraCustomEntityColumns = getExtraCustomEntityColumns(
        location.customProperties,
        r.__customEntityPropertyTypes,
        getTranslation
      );

      const extraCustomEntityColumnValues = Object.keys(extraCustomEntityColumns).map((key) => {
        return extraCustomEntityColumns[key];
      });

      exportArray.push([
        location.address.streetName,
        location.address.streetNumber,
        location.address.streetNumberAddition || '',
        location.address.postalCode,
        location.address.city,
        location.address.country.toUpperCase(),
        location.services.length.toString(),
        utilityTypes.join(', '),
        customers,
        location.propertyGroup?.name || '',
        location.externalId,
        location.id,
        !isMaxDate(contract?.supplyEndDateTime) ? formatDateForExport(contract?.supplyEndDateTime) : '',
        moment(location.createdDateTime).format('DD/MM/YY') +
          ', ' +
          moment(location.createdDateTime).format('HH:mm:ss'),

        ...extraCustomEntityColumnValues
      ]);
    });

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_locations')}
      ws_name={getTranslation('location.serviced_locations')}
    />
  );
}
