import { MeteringIssueError, MutingRuleTimePeriod, entitySubjectType } from '@zf/api-types/enums';
import ListPage from 'components/listPage';
import MeterList from 'features/devices/meter/list-page/MeterList';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useState } from 'react';
import { notify } from 'events/notification-events';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import css from './embedded-list-page-dialog.module.scss';

type Props = {
  muteFromWithNumericValue: number;
  muteUntilWithNumericValue: number;
  errorType: MeteringIssueError;
  message?: string;
  timePeriod: MutingRuleTimePeriod;
  userId: string;
  includeActive: boolean;
};

export const EmbeddedListPageDialogContent = (props: Props) => {
  const { muteFromWithNumericValue, muteUntilWithNumericValue, errorType, message, timePeriod, userId } = props;

  const { meterStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { mutingRuleService } = meterStore;
  const { getMetersIdsUnderRule } = mutingRuleService;

  const [meterIds, setMeterIds] = useState<string[]>([]);

  useEffect(() => {
    getMetersIdsUnderRule(
      muteFromWithNumericValue,
      muteUntilWithNumericValue,
      errorType,
      message || '',
      timePeriod,
      userId
    )
      .then((res) => {
        setMeterIds(res);
      })
      .catch((e) => {
        notify.error({
          content: getTranslation('meters_issues.meters.failed_to_load'),
          error: e
        });
      });
  }, []);

  return (
    <FlexElements flexDirection="column" className={css['meter-dialog']}>
      <ListPage
        css={{ width: '50rem' }}
        entitySubjectType={entitySubjectType.meter}
        isEmbedded
        subjectType={getTranslation('nav.devices')}
        topBar={null}
        list={
          meterIds.length > 0 && (
            <MeterList
              models={[]}
              customEntityPropertyTypes={[]}
              extraColumns={[]}
              meterIds={meterIds}
              showCheckBoxes={false}
            />
          )
        }
      />
    </FlexElements>
  );
};
