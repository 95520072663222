import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { Paragraph } from '../Paragraph';
import { Spinner } from '../Spinner';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './accessibledivbtn.module.scss';

type Props = {
  id?: string;
  ariaLabel?: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  href?: string;
  divRef?: React.RefObject<HTMLDivElement>;
  skipTab?: boolean;
  disabledInfo?: string;
  disabled?: boolean;
  showLoading?: boolean;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  onTrigger: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
};

/**
 * Deprecated, to be removed when we redesign the input fields
 */
export default function AccessibleDivButton(props: Props) {
  const {
    id,
    className,
    children,
    style,
    ariaLabel,
    divRef,
    disabledInfo,
    skipTab = false,
    disabled = false,
    showLoading = false,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onTrigger
  } = props;

  const [isLoading, setIsloading] = useState(false);

  const handleOnClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onTrigger) {
        if (showLoading) setIsloading(true);
        await onTrigger(e);
        if (showLoading) setIsloading(false);
      }
    },
    [setIsloading, onTrigger]
  );

  const btn = (
    <div
      id={id}
      onClick={disabled ? undefined : handleOnClick}
      className={classNames(css['accessible-div-btn'], { [css['disabled']]: disabled }, className)}
      style={{ outline: 'none', cursor: 'pointer', ...style }}
      onKeyDown={() => {}}
      tabIndex={skipTab ? -1 : 0}
      role="button"
      aria-label={ariaLabel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={divRef}
    >
      {isLoading && showLoading ? <Spinner size="xsmall" /> : children}
    </div>
  );

  return disabledInfo ? (
    <TooltipTrigger tooltip={<Paragraph className={css['disabled-info']}>{disabledInfo}</Paragraph>}>
      {btn}
    </TooltipTrigger>
  ) : (
    btn
  );
}
