import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../../../hooks/useStore';

export default function NoInvoicePeriods() {
  const { applicationStore } = useStore();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/invoices.png"
          alt="invoice"
          title="invoice"
          width="70"
          height="70"
        />
      }
      title={applicationStore.getTranslation('contracts.no_invoice_periods')}
    >
      {applicationStore.getTranslation('contracts.no_invoice_periods_descr')}
    </CardEmptyBody>
  );
}
