import React, { forwardRef, Ref, useImperativeHandle } from 'react';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Interweave from 'interweave';

type Props = {
  info: string;
  successMsg: string;
  errorMsg: string;
  submitFunction: Function;
};

export default forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { info, successMsg, errorMsg, submitFunction } = props;

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await submitFunction();

        notify.success({
          content: successMsg
        });
      } catch (error) {
        notify.error({
          content: errorMsg,
          error
        });
      }
    }
  }));

  return (
    <>
      <div>
        <Paragraph>
          <Interweave content={info} />
        </Paragraph>
      </div>
    </>
  );
});
