import Interweave from 'interweave';
import React from 'react';

import {
  AssignInvoicesToOutgoingBankingTransactionRequestType,
  OutgoingBankingTransactionRequestPreviewInsightsType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import IconParagraph from '../../../../components/Icon/IconParagraph';
import { DANGER } from '../../../../constants/color';
import css from './css/assign-insights-preview.module.scss';

import { colors } from '@zf/utils/src/color';

type Props = {
  noneValid: boolean | undefined;
  inValidInvoices: number;
  requestDataBody: AssignInvoicesToOutgoingBankingTransactionRequestType;
  insights: OutgoingBankingTransactionRequestPreviewInsightsType;
};

export default function InvalidInsights(props: Props) {
  const { noneValid, inValidInvoices, requestDataBody } = props;
  const { i18n } = useAppContext();

  const reasons = (
    <div className={css['invalid-msges']}>
      <Paragraph>{i18n.getTranslation('actions.invoice.invalid_invoice_reasons')}</Paragraph>
      <Paragraph>{`- ${i18n.getTranslation('actions.invoice.wrong_status')}`}</Paragraph>
      <Paragraph>{`- ${i18n.getTranslation('actions.invoice.already_paid')}`}</Paragraph>
    </div>
  );

  if (noneValid) {
    return (
      <>
        <IconParagraph className={css['invalid-warning']} iconType="alert-circle" color={DANGER}>
          {i18n.getTranslation('actions.invoice.no_valid_invoices_msg')}
        </IconParagraph>
        {reasons}
      </>
    );
  } else if (inValidInvoices > 0) {
    return (
      <>
        <IconParagraph className={css['invalid-warning']} iconType="alert-circle" color={colors['orange-600']}>
          <Interweave
            content={i18n.getTranslation('actions.invoice.invalid_invoices_msg', {
              amountInvalid: inValidInvoices,
              amount: requestDataBody.invoiceIds.length
            })}
          />
        </IconParagraph>
        {reasons}
      </>
    );
  }

  return null;
}
