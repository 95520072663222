import React, { useEffect } from 'react';

import {
  CollectionChargeParametersType,
  CollectionFlowStepType,
  FixedCollectionChargeParametersType,
  PercentageCollectionChargeParametersType,
  TieredPercentageCollectionChargeParametersType,
  UpdateCollectionFlowStepType
} from '@zf/api-types/collection-cycles';
import { TemplateAttachment } from '@zf/api-types/config/scenarios_new';
import { collectionChargeType, collectionStepLevel, collectionStepType, communicationType } from '@zf/api-types/enums';
import useSimpleValidator, { SimpleValidatorReturnType } from '@zf/hooks/src/useSimpleValidator';

import StepWizard, { StepValidatorType } from '../step-wizard/step-wizard';

type Props = {
  stepNumber: number;
  setStep: (step: Partial<StepValidatorType>) => void;
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  updateDialogSteps: (newStep: CollectionFlowStepType) => void;
  setCycleStep?: (step: StepValidatorType, index: number) => void;
};

export const validateStepFields = (values: StepValidatorType) => {
  const feedback: boolean[] = [];

  // Essentials
  feedback.push(!values.stepName);

  // Charge parameters
  if (values.chargeType !== collectionChargeType.none) {
    feedback.push(!!values.chargeParameters && !values.chargeParameters.taxCodeId);

    switch (values.chargeType) {
      case collectionChargeType.fixed:
        {
          const parameters = values.chargeParameters as FixedCollectionChargeParametersType;
          feedback.push(parameters && !parameters.amountExclVAT);
        }
        break;
      case collectionChargeType.percentageOfOpenAmount:
        {
          const parameters = values.chargeParameters as PercentageCollectionChargeParametersType;
          feedback.push(parameters && !parameters.percentage);
        }
        break;
      case collectionChargeType.tieredPercentageOfOpenAmount:
        {
          const parameters = values.chargeParameters as TieredPercentageCollectionChargeParametersType;
          if (parameters && parameters.tiers) {
            parameters.tiers.forEach((tier) => {
              feedback.push(!tier.from || !tier.maxAmount || !tier.minAmount || !tier.percentage);
            });
          }
        }
        break;
    }
  }

  if (values.stepType !== collectionStepType.manualIntervention) {
    feedback.push(!values.communicationSubject || !values.communicationLevel);
  }

  return feedback;
};

export const generateStepInitialValues = () => {
  return {
    id: '',
    stepName: '',
    months: 0,
    days: 0,
    stepType: collectionStepType.communication,
    chargeType: collectionChargeType.none,
    communicationType: communicationType.manual,
    chargeParameters: {} as CollectionChargeParametersType,
    communicationSubject: '',
    communicationLevel: '' as collectionStepLevel,
    communicationAttachments: [] as TemplateAttachment[]
  };
};

const setChargeParametersType = (val: collectionChargeType) => {
  switch (val) {
    case collectionChargeType.fixed:
      return 'FixedCollectionChargeParametersRequestDTO';
    case collectionChargeType.percentageOfOpenAmount:
      return 'PercentageCollectionChargeParametersRequestDTO';
    case collectionChargeType.tieredPercentageOfOpenAmount:
      return 'TieredPercentageCollectionChargeParametersRequestDTO';
  }
};

export const generateApiFriendlyValues = (values: StepValidatorType) => {
  const apiFriendlyValues: UpdateCollectionFlowStepType = {
    id: values.id,
    name: values.stepName,
    triggerDays: values.triggerDays,
    stepType: values.stepType,
    chargeType: values.chargeType,
    communicationType: values.communicationType,
    chargeParameters: { ...values.chargeParameters, type: setChargeParametersType(values.chargeType) } as any,
    communicationSubject: values.communicationSubject,
    communicationLevel: values.communicationLevel,
    communicationAttachments: values.communicationAttachments
  };

  if (values.chargeType === collectionChargeType.none) {
    apiFriendlyValues.chargeParameters = null;
  }

  return apiFriendlyValues;
};

// Launched when adding a new cycle
export default function AddStep(props: Props) {
  const { stepNumber, setStep, addStep, updateDialogSteps, setCycleStep } = props;

  const stepValidatorTools: SimpleValidatorReturnType<StepValidatorType> = useSimpleValidator<StepValidatorType>({
    initialValues: generateStepInitialValues(),
    validate: () => validateStepFields(stepValidatorTools.values)
  });

  const { values } = stepValidatorTools;

  // When used inside add cycle dialog, update the cycle dialog values
  useEffect(() => {
    if (setCycleStep) {
      setCycleStep(values, stepNumber - 1);
    }
  }, [JSON.stringify(values)]);

  return (
    <StepWizard
      stepNumber={stepNumber}
      stepValidatorTools={stepValidatorTools}
      setStep={setStep}
      addStep={addStep}
      updateDialogSteps={updateDialogSteps}
    />
  );
}
