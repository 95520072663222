import { observer } from 'mobx-react';
import React from 'react';

import { BlockUnblockContractType, ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import BlockDialog from '../../dialogs/BlockDialog';

type Props = {
  contract: ContractType;
};

const BlockUnblockListItem = (props: Props) => {
  const { contract } = props;
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { contractStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const contractToHandle: BlockUnblockContractType = {
    etag: contract._etag,
    contract
  };

  if (contractStore.pageActionPermissions.mayBlockUnblock) {
    return (
      <DropdownAction
        id="contract.unblock"
        icon="lock"
        dialogTitle={getTranslation('contracts.block_unblock_title')}
        dialogContent={<BlockDialog ref={clickRef} contractToHandle={contractToHandle} validationRef={validationRef} />}
        onSubmit={onSubmit}
        dialogType="danger"
        validationRef={validationRef}
        buttonPositive={getTranslation('actions.confirm')}
      />
    );
  }

  return null;
};

export default observer(BlockUnblockListItem);
