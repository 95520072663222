import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '../../design-system/Components';
import { Icon } from '../../design-system/Foundation';
import css from './quickfilters.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  refreshPage: () => void;
};

export default function QuickFilters(props: Props) {
  const { children, className, refreshPage } = props;

  return (
    <div className={classNames(css['filter-buttons-grid'], className)}>
      <div />
      <div className={css['filter-buttons']}>{children}</div>
      <div className={css['reference-div']}>
        <BaseButton id="refresh" className={css['refresh']} onClick={refreshPage}>
          <Icon name="refresh" className={css['refresh-icon']} />
        </BaseButton>
      </div>
    </div>
  );
}
