import RootStore from '../../../../../app-context/stores';
import IncomingBankingTransactionsService from '../services/IncomingBankingTransactionsService';

export default class IncomingBankingTransactionsStore {
  public rootStore: RootStore;
  public incomingBankingTransactionsService: IncomingBankingTransactionsService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.incomingBankingTransactionsService = new IncomingBankingTransactionsService(this, rootStore.applicationStore);
  }
}
