import React from 'react';

import { tariffType } from '@zf/api-types/enums';
import { BillingItemType, BillingTariffRequestType, BillingTariffType, ProductType } from '@zf/api-types/product';
import useDialog from '@zf/hooks/src/useDialog';

import { Button } from '../../../../../../design-system/Components';
import { treeCrud } from '../../../../../../design-system/ComponentSets/Tree/TreeItem';
import { Icon } from '../../../../../../design-system/Foundation';
import { dialog } from '../../../../../../events/dialog-events';
import { useStore } from '../../../../../../hooks/useStore';
import DeleteTariffPeriodListItem from '../../actions/DeleteTariffPeriodListItem';
import EditTariffPeriodListItem from '../../actions/EditTariffPeriodListItem';
import GoToEditorListItem from '../../actions/GoToEditorListItem';
import AddConditionDialog from '../../dialogs/add-conditions-dialog/AddConditionDialog';
import AddEditTariffDialog from '../../dialogs/AddEditTariffDialog';
import TreeNode, { actions } from '../../models/TreeNode';
import css from '../traverse-tree.module.scss';

type renderTreeActionsProps = {
  isProductPage: boolean;
  selectedBillingItem?: BillingItemType;
  tree?: TreeNode;
  selectedProduct?: ProductType;
  tariffs_: BillingTariffType[];
  startDate?: string | null;
  endDate?: string | null;
  addTariff: (tariffToAdd: BillingTariffRequestType) => Promise<BillingTariffType>;
  addTariffToList: (newTariff: BillingTariffType) => void;
  updateTariff: (id: string, tariffToUpdate: BillingTariffRequestType) => Promise<BillingTariffType>;
  updateTariffInList: (updatedTariff: BillingTariffType) => void;
  initTariffs?: (billingItemId: string, selectedProductId?: string) => Promise<void>;
};

const useRenderTreeActions = (props: renderTreeActionsProps) => {
  const {
    tree,
    selectedBillingItem,
    selectedProduct,
    tariffs_,
    isProductPage,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    initTariffs
  } = props;
  const { validationRef, clickRef, onSubmit } = useDialog();
  const { productConfigStore, applicationStore } = useStore();
  const { onlyShowTariffs } = productConfigStore;
  const { getTranslation } = applicationStore;

  const openConditionsDialog = (tree: TreeNode, parentTree?: TreeNode, index?: number) => {
    dialog.normal({
      title: tree.conditionGroup
        ? getTranslation('billing_tariff.edit_condition')
        : getTranslation('billing_tariff.add_condition'),
      icon: tree.conditionGroup ? 'sign' : 'plus',
      content: <AddConditionDialog treeNode={tree} ref={clickRef} parentNode={parentTree} index={index} />,
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  const opentariffDialog = (tree: TreeNode, parentNode?: TreeNode, index?: number) => {
    dialog.normal({
      title: parentNode ? getTranslation('billing_tariff.edit_tariff') : getTranslation('billing_tariff.tariff_add'),
      icon: parentNode ? 'sign' : 'plus',
      content: (
        <AddEditTariffDialog
          parentNode={parentNode}
          index={index}
          treeNode={tree}
          editOnlyTariff={true}
          ref={clickRef}
          validationRef={validationRef}
          tariffs={tariffs_ || []}
          selectedBillingItem={selectedBillingItem}
          selectedProduct={selectedProduct}
          isProductPage={isProductPage}
          addTariff={addTariff}
          addTariffToList={addTariffToList}
          updateTariff={updateTariff}
          initTariffs={initTariffs}
          updateTariffInList={updateTariffInList}
          canAddInstantly={false}
        />
      ),
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  const renderAddActions = () => {
    let buttons: JSX.Element[] = [];

    if (tree?.nodes.length === 0) {
      //for now only do addconditions on tarifflevel
      if (tree.allowedActions.includes(actions.addcondition) && tree.type === tariffType.tariffperiod) {
        buttons.push(
          <Button
            tooltipText={getTranslation('billing_tariff.add_condition')}
            id={`${tree.id}-add-condition`}
            key={`${tree.id}-add-condition`}
            className={css['add-button']}
            type="primary"
            size="small"
            onClick={() => openConditionsDialog(tree)}
          >
            <Icon name="condition" />
          </Button>
        );
      }

      if (tree.allowedActions.includes(actions.addtariff)) {
        buttons.push(
          <Button
            tooltipText={getTranslation('billing_tariff.add_tariff')}
            id={`${tree.id}-add-tariff`}
            key={`${tree.id}-add-tariff`}
            className={css['add-button']}
            type="primary"
            size="small"
            onClick={() => opentariffDialog(tree)}
          >
            <Icon name="coin" />
          </Button>
        );
      }
    }

    if (tree?.type === tariffType.tariffcalculation) {
      buttons = [];
    }

    return buttons;
  };

  const getTreeCrudActionsForLevel = () => {
    const crudactions: treeCrud[] = [];
    if (tree?.allowedActions.includes(actions.edittariff)) {
      crudactions.push(treeCrud.update, treeCrud.delete);
    }
    if (tree?.allowedActions.includes(actions.editcondition)) {
      crudactions.push(treeCrud.update, treeCrud.delete);
    }

    return crudactions;
  };

  const renderMoreActions = () => {
    const listItems: JSX.Element[] = [];

    if (tree && tree.type === tariffType.tariffperiod) {
      if (!onlyShowTariffs) {
        listItems.push(
          <GoToEditorListItem
            key={`${tree.id}-go-to-editor`}
            nodeId={tree.id}
            billingId={selectedBillingItem?.id || ''}
            productId={selectedProduct?.id || ''}
          />,
          <DeleteTariffPeriodListItem
            periodNodeId={tree.id}
            initTariffs={initTariffs}
            key={`${tree.id}-delete`}
            startDate={tree.initialPeriodStart}
            endDate={tree.initialPeriodEnd}
            selectedBillingItemId={selectedBillingItem?.id || ''}
            selectedProductId={selectedProduct?.id || ''}
          />
        );
      } else {
        listItems.push(<EditTariffPeriodListItem key={`${tree.id}-edit-tariff-period`} treeNode={tree} />);
      }
    }

    return listItems;
  };

  return {
    moreActions: renderMoreActions(),
    crudActions: onlyShowTariffs ? getTreeCrudActionsForLevel() : [],
    tariffTypeActions: onlyShowTariffs ? renderAddActions() : [],
    opentariffDialog,
    openConditionsDialog
  };
};

export default useRenderTreeActions;
