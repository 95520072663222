import classNames from 'classnames';
import React from 'react';

import { useAppContext } from '../../../app-context/app-context';
import Button from '../Button';
import css from './reset-all-button.module.scss';

type Props = {
  id: string;
  title?: string;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
};

export default function ResetAllButton(props: Props) {
  const { i18n } = useAppContext();
  const { className, title = i18n.getTranslation('general.reset_all') } = props;

  return (
    <Button
      {...props}
      className={classNames(css['reset-all-button'], className)}
      type="text"
      noSidePadding
      icon="reset"
    >
      {title}
    </Button>
  );
}
