import { Paragraph } from 'design-system/Foundation';
import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import Collapsable from '@zf/stella-react/src/atoms/Collapsable/Collapsable';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import Button from '../../../../../../components/Button/Button';
import { SuspenseSpinner } from '../../../../../../components/suspense';
import { dialog } from '../../../../../../events/dialog-events';
import { useStore } from '../../../../../../hooks/useStore';
import EstimatedInvoiceInfo from '../../detail/components/EstimatedInvoiceInfo';
import Advances from './Advances';
import BillingItem from './BillingItem';
import GenerateAdvanceDialog from './completeness-dialogs/GenerateAdvancesDialog';
import ContractRequiredQuantity from './ContractRequiredQuantity';
import InvoiceTable from './InvoiceTable';
import LocationRQGroupedExtraLeft from './LocationRQGroupedExtraLeft';
import css from './right-body.module.scss';

const RightBody = () => {
  const { applicationStore, contractStore } = useStore();
  const { billingInsights, billingCompletenessStore } = contractStore;
  const { getTranslation } = applicationStore;
  const { clickRef, onSubmit, validationRef } = useDialog();
  const { selectedCompleteness, productForSelectedCompleteness, latestOpenCompleteness } = billingCompletenessStore;
  const { contractRequiredQuantities, locationRequiredQuantities } = selectedCompleteness.requiredQuantitiesGrouped;

  const openGenerateAdvancesDialog = () => {
    dialog.normal({
      title: getTranslation('invoice.generate_advance'),
      content: (
        <GenerateAdvanceDialog validationRef={validationRef} completeness={selectedCompleteness} ref={clickRef} />
      ),
      buttonPositive: getTranslation('invoice.generate_advance'),
      onSubmit
    });
  };

  return (
    <>
      <div className={css['right-body']}>
        <AsyncVarSpinner variables={[!billingCompletenessStore.loadingAction]}>
          <div>
            {contractRequiredQuantities && contractRequiredQuantities?.length > 0 && (
              <>
                <Paragraph className={css['cost-type-title']}>{getTranslation('invoice.contractual_costs')}</Paragraph>
                {contractRequiredQuantities?.map((crq, index) => {
                  return (
                    crq.requiredQuantities && (
                      <ContractRequiredQuantity
                        key={`${crq.billingItemId}-${index}`}
                        requiredQuantityContractGrouped={crq}
                      />
                    )
                  );
                })}
              </>
            )}

            {locationRequiredQuantities && locationRequiredQuantities.length > 0 && (
              <>
                <Paragraph className={css['cost-type-title']}>
                  {getTranslation('location.serviced_locations')}
                </Paragraph>
                {locationRequiredQuantities?.map((lrq, index) => {
                  return (
                    lrq.requiredQuantities && (
                      <Collapsable
                        key={`${lrq.serviceLocationId}-${index}`}
                        className={css['collapsable']}
                        title=" "
                        defaultClosed={false}
                        extraLeft={<LocationRQGroupedExtraLeft lrq={lrq} />}
                      >
                        <div className={css['billing-items']}>
                          {lrq.requiredQuantities.map((rq, index) => {
                            return (
                              <SuspenseSpinner key={rq.billingItemId}>
                                <BillingItem
                                  productId={productForSelectedCompleteness?.productName || ''}
                                  requiredQuantity={rq}
                                  currentCompletenessStatus={selectedCompleteness.status}
                                  defaultClosed={index !== 0}
                                />
                              </SuspenseSpinner>
                            );
                          })}
                        </div>
                      </Collapsable>
                    )
                  );
                })}
              </>
            )}

            {selectedCompleteness.advancePeriods.length > 0 && (
              <Collapsable className={css['collapsable']} title={getTranslation('analytics.advances')}>
                <>
                  <Advances />
                  {billingCompletenessStore.actionPermissions.mayCreateAdvances && (
                    <div className={css['generate-advance-button']}>
                      <Button id="generate_advances" icon="config" type="text" onClick={openGenerateAdvancesDialog}>
                        {getTranslation('invoice.generate_advance')}
                      </Button>
                    </div>
                  )}
                </>
              </Collapsable>
            )}

            <InvoiceTable />
          </div>
        </AsyncVarSpinner>
      </div>

      {!billingCompletenessStore.loadingAction &&
        billingInsights &&
        latestOpenCompleteness?.id === selectedCompleteness.id && (
          <div className={css['estimated-invoice-amount']}>
            <EstimatedInvoiceInfo billingInsights={billingInsights} />
          </div>
        )}
    </>
  );
};

export default observer(RightBody);
