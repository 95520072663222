import LocationSectionStore from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import SearchBar from 'components/input/SearchBar';
import { UppercaseText } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import AddLocationsBtn from './AddLocationsBtn';
import LocationSummary from './LocationSummary';
import css from './multiple-locations.module.scss';
import NoLocationsYet from './NoLocationsYet';

type Props = {
  title: string;
  locationSectionStore: LocationSectionStore;
};

const MultipleLocations = (props: Props) => {
  const { title, locationSectionStore } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { selectedLocations, locationSearch, setLocationSearch } = locationSectionStore;

  let filteredLocations = Array.from(selectedLocations.values());

  if (locationSearch) {
    filteredLocations = filteredLocations.filter((l) =>
      l.address.localizedDisplay?.toLowerCase().includes(locationSearch.toLowerCase())
    );
  }

  return selectedLocations.size === 0 ? (
    <NoLocationsYet locationSectionStore={locationSectionStore} />
  ) : (
    <div>
      <FlexElements justifyContent="space-between" alignItems="center">
        <UppercaseText>{title}</UppercaseText>
        <AddLocationsBtn locationSectionStore={locationSectionStore} />
      </FlexElements>

      <div className={css['searchbar']}>
        <SearchBar
          id="contracts.locations-searchbar"
          onChange={setLocationSearch}
          value={locationSearch}
          placeholder={getTranslation('location.search')}
          debounceTime={500}
        />
      </div>

      <FlexElements flexDirection="column" gap="24">
        {filteredLocations.map((sl) => (
          <LocationSummary key={`location-${sl.id}`} location={sl} locationSectionStore={locationSectionStore} />
        ))}
      </FlexElements>
    </div>
  );
};

export default observer(MultipleLocations);
