import { incomingMutationPaymentMethod, incomingMutationType, transactionReferenceType } from '@zf/api-types/enums';
import {
  CollectionStepReferenceParametersType,
  InvoiceReferenceParametersType,
  PaymentReferenceParametersType,
  TransactionReferenceDetailsType,
  TransactionType
} from '@zf/api-types/transactions';
import { colors } from '@zf/utils/src/color';
import { formatMoney } from '@zf/utils/src/number';

import { DANGER } from '../constants/color';

export const formatTransactionAmount = (
  amount: number,
  type: incomingMutationPaymentMethod | incomingMutationType,
  culture?: string
) => {
  if (
    type === incomingMutationPaymentMethod.reversals ||
    type === incomingMutationType.paymentrequestreversal ||
    type === incomingMutationType.refundtransactionconfirmation ||
    type === incomingMutationType.refundmutationconfirmation ||
    type === incomingMutationType.refundconfirmation
  ) {
    amount = amount * -1;
  }

  if (culture) {
    return formatMoney(amount, culture);
  } else {
    return amount;
  }
};

export const getOpenAmountColor = (transaction: TransactionType) => {
  if (transaction.transactionOpenAmount !== 0) {
    return DANGER;
  } else {
    return colors['graphite'];
  }
};

export const getMainDetail = (referenceDetails: TransactionReferenceDetailsType | null) => {
  if (referenceDetails) {
    switch (referenceDetails.transactionReferenceType) {
      case transactionReferenceType.invoice: {
        const parameters = referenceDetails.parameters as InvoiceReferenceParametersType;
        return parameters?.invoiceNum || '';
      }
      case transactionReferenceType.payment: {
        const parameters = referenceDetails.parameters as PaymentReferenceParametersType;
        return parameters?.paymentReference || '';
      }
      case transactionReferenceType.collectionstep: {
        const parameters = referenceDetails.parameters as CollectionStepReferenceParametersType;
        return parameters.stepName && parameters.workflowName
          ? `${parameters.workflowName}: ${parameters.stepName}`
          : '';
      }
    }
  }
  return '';
};
