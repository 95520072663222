import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';
import { SuspenseCardBody } from '../../components/suspense';
import ContractsServiceLocationBody from './contracts-service-location-body';
import css from './contract-card.module.scss';

type Props = {
  serviceLocationId: string;
};

export default function ContractsServiceLocationCard(props: Props) {
  const { i18n } = useAppContext();
  return (
    <Card id="location-contracts" width="2">
      <CardHeader>{i18n.getTranslation('contracts.contracts')}</CardHeader>
      <SuspenseCardBody className={css['contract-list']} fixedHeight>
        <ContractsServiceLocationBody {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
