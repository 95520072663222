import React, { useMemo } from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import { useStore } from '../../../hooks/useStore';
import useLocationListPage from '../hooks/useLocationListPage';

type Props<Q> = {
  customEntityPropertyTypes?: CustomEntityPropertyType[];
  extraColumns?: ColumnType[];
  defaultQueryParams?: Q;
  noNavigation?: boolean;
};

export default function LocationsList<Q>(props: Props<Q>) {
  const { defaultQueryParams, customEntityPropertyTypes = [], extraColumns = [], noNavigation = false } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    handleSort,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    setStopIndex,
    setSelectedIds,
    localTimeStamp,
    timeStamp
  } = useLocationListPage(customEntityPropertyTypes, defaultQueryParams, noNavigation);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="locations-table"
        tooltipId="locations-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={[
          {
            width: 550,
            label: getTranslation('location.location'),
            dataKey: 'DisplayAddress',
            noClick: true
          },
          {
            width: 100,
            label: getTranslation('locations_list.labels.services'),
            dataKey: 'services'
          },
          {
            width: 200,
            label: getTranslation('locations_list.labels.supplied_services'),
            dataKey: 'suppliedServices'
          },
          {
            width: 300,
            label: getTranslation('customer.customers'),
            dataKey: 'customer'
          },
          {
            width: 300,
            label: getTranslation('locations_list.labels.property_group'),
            dataKey: 'propertyGroupName'
          },
          {
            width: 400,
            label: getTranslation('location.external_identifier'),
            dataKey: 'externalId'
          },
          ...extraColumns
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
}
