import clone from 'clone';
import React from 'react';

import { localisationLevel } from '@zf/api-types/enums';
import {
  CostComponentType,
  CostComponentValueType,
  UpdateCostComponentType,
  UpdateCostComponentValueType
} from '@zf/api-types/product';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardBody, CardEmptyBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';
import { groupBy } from '@zf/utils/src/array';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useCultureTable from '../../../app-context/hooks/use-culture-table';
import useTranslations from '../../../app-context/hooks/use-translations';
import Button from '../../../components/Button/Button';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import CommitSection from '../../../components/config/commit-section';
import { DeleteIcon, Icon } from '../../../components/Icon';
import LanguageIcon from '../../../components/Icon/LanguageIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import { notify } from '../../../events/notification-events';
import usePeriodValidator from '../../../hooks/usePeriodValidator';
import { RequestType } from '../../../types/Request';
import { createHeader, METHODS, sendRequest } from '../../../utils/request';
import CostComponentValues from './cost-component-values';

type Props = {
  costComponents: Array<CostComponentType>;
};

type ValidatorType = {
  costComponents: UpdateCostComponentType[];
  selectedCostComponent: number;
};

export type ValidatorTypeV = {
  costComponentValues: UpdateCostComponentValueType[];
  selectedCostComponentValue: number;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);

export default function CostComponents(props: Props) {
  const { costComponents } = props;
  const { i18n, tenantReducer } = useAppContext();

  const { cultureTable, hasMultipleLanguages } = useCultureTable();
  const { openTranslationsDialog } = useTranslations<UpdateCostComponentType>('CostComponents');

  const {
    values: valuesC,
    setValue: setValueC,
    backup: backupC,
    restoreValues: restoreValuesC,
    isDirty: isDirtyC,
    submitFactory: submitFactoryC
  } = useValidator<ValidatorType>({
    initialValues: {
      costComponents: clone(costComponents),
      selectedCostComponent: -1
    }
  });

  const {
    values: valuesV,
    setValue: setValuesV,
    backup: backupV,
    backupValues: backupValuesV,
    restoreValues: restoreValuesV,
    isDirty: isDirtyV,
    submitFactory: submitFactoryV
  } = useValidator<ValidatorTypeV>({
    initialValues: { costComponentValues: [], selectedCostComponentValue: -1 }
  });

  const { checkForOverlapAndGaps, notifyForOverlapOrGaps } = usePeriodValidator();

  if (!valuesC.costComponents || !cultureTable) return null;

  const handleSaveC = submitFactoryC(async () => {
    try {
      const componentsToHandle = clone(valuesC.costComponents);
      backupC.costComponents.forEach((initialModel) => {
        if (!valuesC.costComponents.find((costComponent) => initialModel.id === costComponent.id)) {
          initialModel.delete = true;
          componentsToHandle.push(initialModel);
        }
      });

      await Promise.all(
        componentsToHandle.map(async (val, index) => {
          const apiFriendlyValues: UpdateCostComponentType = {
            id: val.id,
            name: val.name,
            description: val.description,
            localisationLevel: val.localisationLevel,
            localisedFields: val.localisedFields,
            translatedFields: val.translatedFields
          };
          let request: RequestType | undefined = undefined;

          if (val.id && val.delete) {
            request = {
              method: METHODS.DELETE,
              endpoint: `/cfg/costComponents/${val.id}`
            };
          } else if (!val.id) {
            request = {
              method: METHODS.POST,
              endpoint: '/cfg/costComponents',
              data: {
                ...apiFriendlyValues
              }
            };
          } else {
            const initialCostComponent = costComponents.find((initial) => initial.id === val.id);
            if (JSON.stringify(initialCostComponent) !== JSON.stringify(val)) {
              request = {
                method: METHODS.POST,
                endpoint: `/cfg/costComponents/${val.id}`,
                data: {
                  ...apiFriendlyValues
                }
              };
            }
          }

          if (request) {
            const newData = await sendRequest<CostComponentType>({
              request,
              customHeaders: createHeader({
                'if-match': val._etag
              }),
              tenantReducer,
              lang: i18n.lang
            });

            if (!val.delete) {
              valuesC.costComponents[index] = newData.data;
            }
          }
        })
      );

      notify.success({
        content: i18n.getTranslation('cost_component.success_update')
      });
      setValueC(valuesC, false, true);
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('cost_component.error_update'),
        error: e
      });
    }
  });

  const handleSaveV = submitFactoryV(async () => {
    try {
      const valuesToHandle = clone(valuesV.costComponentValues);

      backupV.costComponentValues.forEach((initialCcv) => {
        if (!valuesV.costComponentValues.find((costComponentValue) => initialCcv.id === costComponentValue.id)) {
          initialCcv.delete = true;
          valuesToHandle.push(initialCcv);
        }
      });

      // Group values by cost component id
      const valuesGroupedByComponentId = groupBy(valuesToHandle, 'costComponentId');

      // Stores the validation for each component
      const valuesAreValid: boolean[] = [];

      // Iterate over our grouped data
      Object.keys(valuesGroupedByComponentId).forEach((id) => {
        // Filter the deleted ones, we don't want to check those
        const valuesToCheck = valuesGroupedByComponentId[id].filter((v: UpdateCostComponentValueType) => !v.delete);

        const result = checkForOverlapAndGaps(valuesToCheck, id);

        let name = '';

        // If overlap is detected find the name of the item where this occurs and pass the name to the notify function
        if (result.overlap) {
          const inValidItem = valuesC.costComponents.find((c) => {
            return c.id === result.ranges[0].id;
          });

          if (inValidItem) {
            name = inValidItem.name;
            notifyForOverlapOrGaps(result, name);
          }
        }

        // If gaps are detected find the name of the item where this occurs and pass the name to the notify function
        if (result.gap) {
          const inValidItem = valuesC.costComponents.find((c) => {
            return c.id === result.gaps[0].id;
          });

          if (inValidItem) {
            name = inValidItem.name;
            notifyForOverlapOrGaps(result, name);
          }
        }

        valuesAreValid.push(!result.overlap && !result.gap);
      });

      if (!valuesAreValid.includes(false)) {
        // user is notified inside the hook
        await Promise.all(
          valuesToHandle.map(async (val, index) => {
            const apiFriendlyValues: UpdateCostComponentValueType = {
              value: val.value,
              startDateTime: val.startDateTime ? val.startDateTime : MIN_DATE,
              endDateTime: val.endDateTime ? val.endDateTime : MAX_DATE
            };

            let request: RequestType | undefined = undefined;

            if (val.id && val.delete) {
              request = {
                method: METHODS.DELETE,
                endpoint: `/cfg/CostComponentValues/${val.id}`
              };
            } else if (!val.id) {
              request = {
                method: METHODS.POST,
                endpoint: '/cfg/CostComponentValues',
                data: {
                  ...apiFriendlyValues,
                  costComponentId: valuesC.costComponents[valuesC.selectedCostComponent].id
                }
              };
            } else {
              const initialCcv = backupV.costComponentValues
                ? backupV.costComponentValues.find((initialCcv_) => initialCcv_.id === val.id)
                : val;
              if (JSON.stringify(initialCcv) !== JSON.stringify(val)) {
                request = {
                  method: METHODS.POST,
                  endpoint: `/cfg/CostComponentValues/${val.id}`,
                  data: {
                    ...apiFriendlyValues
                  }
                };
              }
            }

            if (request) {
              const newData = await sendRequest<CostComponentValueType>({
                request,
                customHeaders: createHeader({
                  'if-match': val._etag
                }),
                tenantReducer,
                lang: i18n.lang
              });

              if (!val.delete) {
                valuesV.costComponentValues[index] = newData.data;
              }
            }
          })
        );

        setValuesV(valuesV, false, true);
        notify.success({
          content: i18n.getTranslation('cost_component_value.success_update')
        });
      }
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('cost_component_value.error_update'),
        error: e
      });
    }
  });

  const handleCancel = () => {
    setValueC({ selectedCostComponent: -1 });
    setValuesV({ selectedCostComponentValue: -1 });
    restoreValuesC();
    restoreValuesV();
  };

  const setCostComponent = (index: number, value: Partial<UpdateCostComponentType>) => {
    const clonedArray = [...valuesC.costComponents];
    clonedArray[index] = { ...clonedArray[index], ...value };
    setValueC({
      costComponents: clonedArray
    });
  };

  const handleCostComponentSelect = (index: number) => {
    if (isDirtyV && index !== valuesC.selectedCostComponent) {
      notify.warning({
        content: i18n.getTranslation('cost_component.values_first')
      });
    } else {
      setValueC({ selectedCostComponent: index });
    }
  };

  const addCostComponent = () => {
    if (isDirtyV) {
      notify.warning({
        content: i18n.getTranslation('cost_component.values_first')
      });
    } else {
      const clonedArray = [...valuesC.costComponents];
      clonedArray.push({
        id: '',
        name: '',
        description: '',
        localisationLevel: localisationLevel.none,
        localisedFields: [],
        translatedFields: {}
      });
      setValueC({
        costComponents: clonedArray,
        selectedCostComponent: clonedArray.length - 1
      });
    }
  };

  const deleteCostComponent = (index: number) => {
    const clonedArray = [...valuesC.costComponents];
    clonedArray.splice(index, 1);

    setValuesV({ selectedCostComponentValue: -1 });

    setValueC({
      costComponents: clonedArray,
      selectedCostComponent: -1
    });
  };

  const onCompleteLangUpdate = (index: number, updated: UpdateCostComponentType) => {
    valuesC.costComponents[index] = updated;
    setValueC(valuesC, false, true);
  };

  const costComponentRows =
    valuesC.costComponents &&
    valuesC.costComponents.map((costComponent, index) => {
      return {
        name: (
          <InlineInputFieldInput
            id={`costcomponent.name.index-${index}`}
            value={costComponent.name}
            onChange={(val) => setCostComponent(index, { name: val })}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={!costComponent.name}
            error={!costComponent.name}
          />
        ),
        description: (
          <InlineInputFieldInput
            id={`costcomponent.description.index-${index}`}
            value={costComponent.description}
            onChange={(val) => setCostComponent(index, { description: val })}
          />
        ),
        languageAction: cultureTable.supportedCultures.length > 1 && (
          <LanguageIcon
            id={`costcomponent.update_language.index-${index}`}
            localisationLevel_={costComponent.localisationLevel}
            hasMultipleLanguages={hasMultipleLanguages}
            action={() => openTranslationsDialog(index, costComponent, onCompleteLangUpdate)}
          />
        ),
        deleteAction: (
          <DeleteIcon
            id={`costcomponent.delete.index-${index}`}
            tooltipFor="cost-components-table"
            onClick={() => deleteCostComponent(index)}
          />
        )
      };
    });

  const handleSave = async () => {
    if (isDirtyC && isDirtyV) {
      await handleSaveC();
      await handleSaveV();
    } else if (isDirtyC) {
      await handleSaveC();
    } else {
      await handleSaveV();
    }
  };

  return (
    <>
      <CommitSection handleCancel={handleCancel} handleSave={handleSave} isDirty={isDirtyC || isDirtyV} />
      <CardsContainer>
        <Card id="cost-components-card">
          <CardHeader
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.costComponents.title')}
                content={[i18n.getTranslation('coachmark.costComponents.paragraph')]}
              />
            }
            extraRight={
              <Button id="costcomponent.add" type="text" icon="plus" onClick={addCostComponent}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('cost_component.cost_components')}
          </CardHeader>
          {valuesC.costComponents.length >= 1 ? (
            <CardBody fixedHeight>
              <DeprecatedStaticTable
                tooltipId="cost-components-table"
                rows={costComponentRows}
                onRowSelect={handleCostComponentSelect}
                selectedIndex={valuesC.selectedCostComponent}
              >
                <DeprecatedStaticColumn flexWidth="1" dataKey="name" label={i18n.getTranslation('general.name')} />
                <DeprecatedStaticColumn
                  flexWidth="2"
                  dataKey="description"
                  label={i18n.getTranslation('general.description')}
                />
                {hasMultipleLanguages ? <DeprecatedStaticColumn dataKey="languageAction" /> : null}
                <DeprecatedStaticColumn dataKey="deleteAction" showOnHover />
              </DeprecatedStaticTable>
            </CardBody>
          ) : (
            <CardEmptyBody
              //TODO: add proper icon
              icon={<Icon type="" />}
              title={i18n.getTranslation('cost_component.noCostComponents')}
            />
          )}
        </Card>

        <CostComponentValues
          selectedCostId={
            valuesC.selectedCostComponent >= 0 && valuesC.costComponents[valuesC.selectedCostComponent]
              ? valuesC.costComponents[valuesC.selectedCostComponent].id
              : ''
          }
          selectedCostName={
            valuesC.selectedCostComponent >= 0 && valuesC.costComponents[valuesC.selectedCostComponent]
              ? valuesC.costComponents[valuesC.selectedCostComponent].name
              : ''
          }
          selectedCostComponent={valuesC.selectedCostComponent}
          selectedCostComponentId={
            valuesC.selectedCostComponent > -1 ? valuesC.costComponents[valuesC.selectedCostComponent].id : ''
          }
          selectedCostComponentValue={valuesV.selectedCostComponentValue}
          valuesV={valuesV}
          setValuesV={setValuesV}
          backupValuesV={backupValuesV}
        />
      </CardsContainer>
    </>
  );
}
