import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { InvoicePaginatedResult, InvoiceType } from '@zf/api-types/invoice';
import { ContractType } from '@zf/api-types/master-data/contract';
import DropDownDetails from '@zf/stella-react/src/atoms/Dropdown/DropDownDetails';
import { Label } from '@zf/stella-react/src/atoms/Label';
import StellaLoader from '@zf/stella-react/src/atoms/Loader/StellaLoader';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';
import { capitalize } from '@zf/utils/src/string';

import { ICONS } from '../../../../constants/icons';
import { Link } from '../../../../design-system/Components';
import useSingleAPI from '../../../../hooks/useSingleAPI';
import IconLabel from '../../../Icon/IconLabel';

type Props = {
  contract: ContractType;
};

const ContractDropdownDetails = (props: Props) => {
  const { contract } = props;

  const { applicationStore, contractStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { getProductText } = contractStore;

  const invoicesResponse = useSingleAPI<InvoicePaginatedResult>({
    request: {
      endpoint: `/bill/invoices/c/${contract.contractor.customerId}`
    }
  });

  if (!invoicesResponse || !invoicesResponse.result || invoicesResponse.result instanceof Promise) {
    return <StellaLoader />;
  }

  const latestInvoice: InvoiceType | undefined = invoicesResponse.result.data.results[0];

  return (
    <DropDownDetails>
      <div>
        <IconLabel labelText={getTranslation('contracts.list.contract_number')} iconType="contract" />
        <Link id={`contract-${contract.id}`} url={`${rootUrl}/contracts/${contract.id}`} newTab>
          {contract.contractNumber}
        </Link>
      </div>
      <>
        {contract.externalContractReference && (
          <div>
            <Label>{getTranslation('contracts.external_ref')}</Label>
            <Paragraph>{contract.externalContractReference}</Paragraph>
          </div>
        )}
      </>
      <div>
        <IconLabel
          labelText={getTranslation('contracts.contractor')}
          iconType={ICONS[contract.contractor.customerType]}
        />
        <Link
          id={`customer-${contract.contractor.customerId}`}
          url={`${rootUrl}/customers/${contract.contractor.customerId}`}
          newTab
        >
          {contract.contractor.shortDisplayName}
        </Link>
      </div>
      <div>
        <Label>{getTranslation('contracts.product')}</Label>
        <Paragraph>{capitalize(getProductText(contract))}</Paragraph>
      </div>
      <>
        {latestInvoice && (
          <div>
            <IconLabel labelText={getTranslation('contracts.latest_invoice')} iconType="invoice" />
            <Link id={`invoice-${latestInvoice.id}`} url={`${rootUrl}/invoices/${latestInvoice.id}`} newTab>
              {`${latestInvoice.invoiceNum || getTranslation('general.empty')} - ${formatDate(
                latestInvoice.invoiceDate
              )}`}
            </Link>
          </div>
        )}
      </>
      <div>
        <IconLabel labelText={getTranslation('location.serviced_locations')} iconType="location" />
        {contract.serviceLocations.map((l, index) => {
          const id = `${l.id}-${index}`;

          return (
            <Link id={id} key={id} url={`${rootUrl}/locations/${l.id}`} newTab>
              {l.address?.localizedDisplay || ''}
            </Link>
          );
        })}
      </div>
    </DropDownDetails>
  );
};

export default observer(ContractDropdownDetails);
