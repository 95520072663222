import React from 'react';

import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import DataGridCardTab from '@zf/stella-react/src/atoms/Tabs/GraphTab/DataGridCardTab';

import { useAppContext } from '../../../../app-context/app-context';
import { ErrorBoundary } from '../../../../components/Error';
import GraphCompareFilters from '../../../../components/graph/filters/GraphCompareFilters';
import ConsumptionsChannelSelection from './ConsumptionsChannelSelection';
import { ConsumptionsGraph } from './ConsumptionsGraph';
import GraphHeader from './GraphHeader';
import useCompareFilters from './hooks/useCompareFilters';
import useFetchFilterTypes from './hooks/useFetchFilterTypes';
import useServiceConsumptions from './hooks/useServiceConsumptions';
import css from './service-consumptions-content.module.scss';

type Props = {
  serviceLocation: ServiceLocationType;
  timeStamp: string;
};

export default function ServiceConsumptionsContent(props: Props) {
  const { serviceLocation, timeStamp } = props;
  const { i18n } = useAppContext();

  useFetchFilterTypes(serviceLocation.id, timeStamp);

  const { filterTypesAreLoading, filterTypes, isCompareMode, groupByPeriod, toggleCompareMode } =
    useServiceConsumptions();

  const {
    compareYears,
    compareMonths,
    setCompareYears,
    addComparisonYear,
    removeComparisonYear,
    setCompareMonths,
    addComparisonMonth,
    removeComparisonMonth
  } = useCompareFilters();

  return filterTypesAreLoading ? (
    <div className={css['spinner-wrapper']}>
      <Spinner size="medium" />
    </div>
  ) : (
    <DataGridCardTab
      cardId="output-card"
      hasData={filterTypes.length > 0}
      noDataIcon="channels"
      noDataTitle={i18n.getTranslation('meter.no_channels')}
      noDataMsg={i18n.getTranslation('meter.no_channels_meter_values')}
    >
      <GraphHeader />
      <ConsumptionsChannelSelection />
      {isCompareMode && (
        <GraphCompareFilters
          groupByPeriod={groupByPeriod}
          compareYears={compareYears}
          compareMonths={compareMonths}
          setCompareYears={setCompareYears}
          addComparisonYear={addComparisonYear}
          removeComparisonYear={removeComparisonYear}
          setCompareMonths={setCompareMonths}
          addComparisonMonth={addComparisonMonth}
          removeComparisonMonth={removeComparisonMonth}
          toggleCompareMode={toggleCompareMode}
        />
      )}
      <ErrorBoundary>
        <ConsumptionsGraph />
      </ErrorBoundary>
    </DataGridCardTab>
  );
}
