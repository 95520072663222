import React, { MutableRefObject } from 'react';

import { OutgoingMutationEntryType } from '@zf/api-types/billing/outgoing-mutations';
import {
  invoiceStatus,
  outgoingBankingTransactionType,
  paymentMethod,
  paymentProcessStatus
} from '@zf/api-types/enums';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../app-context';
import CustomerAutoFill from '../../components/Autofills/CustomerAutoFill';
import InvoiceAutoFill from '../../components/Autofills/InvoiceAutoFill';
import IconParagraph from '../../components/Icon/IconParagraph';
import MoneyInput from '../../components/input/MoneyInput';
import MultiLineInput from '../../components/input/MultiLineInput';
import { ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import BankAccountRefundSection from '../outgoing-mutation/BankAccountRefundSection';
import BankAccountRequestSection from '../outgoing-mutation/BankAccountRequestSection';
import { StateOutgoingBankingTransaction } from './AddOutgoingBankingTransactionDialog';
import css from './AddOutgoingBankingTransactionWizard.module.scss';

type Props = {
  value: OutgoingMutationEntryType;
  dispatchValue: (value: Partial<OutgoingMutationEntryType>) => void;
  state: StateOutgoingBankingTransaction;
  validationRef: MutableRefObject<ValidationRef | undefined>;
  entryType: string;
  index: number;
};

const AddOutgoingBankingTransactionWizard = (props: Props) => {
  const { dispatchValue, value, state, entryType, index } = props;
  const { i18n } = useAppContext();

  return (
    <div className={css['wizard-item']}>
      {entryType === 'manual' ? (
        <>
          <InputContainer>
            <CustomerAutoFill
              id={`add-outgoing-customer-${index}`}
              onChange={(value) => {
                dispatchValue({
                  customerId: value[0]?.id || '',
                  iban: ''
                });
              }}
              selectedValues={[value.customerId || '']}
              placeholder={i18n.getTranslation('contracts.wizard.search_customer')}
              error={!value.customerId}
            />
            <MoneyInput
              id={`add-outgoing-amount-${index}`}
              onChange={(val) => dispatchValue({ amount: val })}
              value={value.amount}
              placeholder={i18n.getTranslation('general.amount_valuta')}
              error={typeof value.amount !== 'number' || value.amount <= 0}
            />
          </InputContainer>

          {value.customerId && (
            <>
              <Heading headingType="h3" style="bold">
                {i18n.getTranslation('actions.outgoing_mutation.heading_iban')}
              </Heading>
              <InputContainer>
                {state.type === outgoingBankingTransactionType.paymentrequests ? (
                  <BankAccountRequestSection
                    customerId={value.customerId}
                    iban={value.iban as string}
                    setIban={(val) => dispatchValue({ iban: val[0] || '' })}
                  />
                ) : (
                  <BankAccountRefundSection
                    customerId={value.customerId}
                    iban={value.iban as string}
                    setIban={(val) => dispatchValue({ iban: val[0] || '' })}
                  />
                )}
              </InputContainer>
            </>
          )}

          <Label>{i18n.getTranslation('invoice.paymentreference')}:</Label>
          <InputContainer>
            <MultiLineInput
              id={`payment-reference-${index}`}
              onChange={(val) => dispatchValue({ paymentReference: val })}
              value={value.paymentReference}
              error={!value.paymentReference}
            />
          </InputContainer>
        </>
      ) : (
        <>
          <div>
            <InvoiceAutoFill
              id={`add-outgoing-${index}`}
              className={css['invoice-autofill-outgoing-wizard']}
              onChange={(val) => dispatchValue({ invoiceId: val[0].id })}
              query={{
                invoiceStatus: [invoiceStatus.approved, invoiceStatus.generated, invoiceStatus.settled],
                paymentProcessStatus: [
                  paymentProcessStatus.paymentrejected,
                  paymentProcessStatus.readyforpaymentcollection
                ],
                paymentMethod:
                  state.type === outgoingBankingTransactionType.paymentrefunds ? paymentMethod.sct : paymentMethod.sdd
              }}
              selectedValues={[value.invoiceId || '']}
              error={!value.invoiceId}
            />
          </div>
          <IconParagraph iconType="alert-circle" color={colors['orange-600']}>
            {i18n.getTranslation(
              `actions.outgoing_mutation.collection_date_warning_${
                state.type === outgoingBankingTransactionType.paymentrequests ? 'request' : 'refund'
              }`
            )}
          </IconParagraph>
        </>
      )}
    </div>
  );
};

export default AddOutgoingBankingTransactionWizard;
