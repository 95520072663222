import ExportToExcel from 'components/Button/Excel/ExportToExcel';
import { useStore } from 'hooks/useStore';
import React from 'react';
import { getMeteringIssuesExportArray } from 'utils/metering-issues';

import { MeteringIssue } from '@zf/api-types/metering/metering-issues';

import { notify } from 'events/notification-events';
import { useMeterIssuesContext } from 'features/devices/meter/detail-page/Context/Context';

export const ExportIssuesListItem = () => {

  const { applicationStore } = useStore();
  const { getTranslation, userStore } = applicationStore;
 const { getUserById } = userStore;

  const {
    store: { listPage }
  } = useMeterIssuesContext();

  let issuesToExport: MeteringIssue[] = [];

  if (listPage.selectedRows) {
    issuesToExport = listPage.selectedRows.map((r) => r.__entity);
  }

 

  Promise.all(
    issuesToExport.map((i) => {
      return getUserById(i.resolution?.details?.resolvedByUserId);
    })
  ).catch((error) => {
    notify.error({
      content: getTranslation('meter_issues.get_issues_fail'),
      error
    });
  });

  return (
    <ExportToExcel
      id="btn-export-excel"
      disabled={listPage.selectedRows.length === 0}
      toExportArray={() => getMeteringIssuesExportArray(issuesToExport, applicationStore)}
      title={getTranslation('export_excel.export_metering_issues')}
      ws_name={getTranslation('meter_issues.metering_issues')}
    />
  );
};
