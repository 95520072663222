import { notify } from 'events/notification-events';
import { observer } from 'mobx-react';
import React, { forwardRef, Fragment, MutableRefObject, Ref, useEffect, useState } from 'react';

import { EstimatedInvoice, EstimatedInvoiceLine } from '@zf/api-types/billing/estimated-invoices';
import { ContractType } from '@zf/api-types/master-data/contract';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { groupBy } from '@zf/utils/src/array';
import { formatDateTime, formatPeriod } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { DialogClickRef, ValidationRef } from '../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph, Spinner } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import EstimatedInvoiceAccuracy from './accuracy/EstimatedInvoiceAccuracy';
import BillingItemsListHeader from './BillingItemsListHeader';
import css from './estimated-invoice-dialog.module.scss';
import EstimatedBillingItems from './EstimatedBillingItems';

type Props = {
  contract: ContractType;
  estimatedInvoiceId: string;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const EstimatedInvoiceDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { contract, estimatedInvoiceId, validationRef } = props;
  const { applicationStore, contractStore } = useStore();
  const { culture, getTranslation } = applicationStore;
  const { getEstimatedInvoiceForId } = contractStore.estimatedInvoiceService;

  const [estimatedInvoice, setEstimatedInvoice] = useState<EstimatedInvoice>();

  useEffect(() => {
    getEstimatedInvoiceForId(estimatedInvoiceId)
      .then((res) => setEstimatedInvoice(res))
      .catch((error) => {
        notify.error({ content: getTranslation('contracts.get_estimated_invoice_fail'), error });
      });
  }, [estimatedInvoiceId]);

  if (!estimatedInvoice) return <Spinner centered />;

  const groupedByLocationId: Record<string, EstimatedInvoiceLine[]> = groupBy(
    estimatedInvoice.lines,
    'serviceLocationId'
  );

  const contractLines = [...(groupedByLocationId['null'] || [])];
  const contractLinesTotal = contractLines.reduce((acc: number, line) => acc + line.amountInclVAT, 0);

  // We extracted the contractLines, removing this key results in the locationLines only grouped by locationId
  delete groupedByLocationId['null'];
  const locationsTotal = Object.values(groupedByLocationId)
    .map((lines) => lines.reduce((acc: number, line) => acc + line.amountInclVAT, 0))
    .reduce((acc: number, amount) => acc + amount, 0);

  return (
    <>
      <div className={css['total']}>
        <Heading headingType="h3">
          {formatPeriod(estimatedInvoice.periodStartDateTime, estimatedInvoice.periodEndDateTime)}
        </Heading>
        <Heading headingType="h3">{formatMoney(estimatedInvoice.totalAmountInclVAT, culture)}</Heading>
      </div>

      <div className={css['estimation-calculation']}>
        <Paragraph>
          {`${getTranslation('contracts.estimation_accuracy_calculated_on')}: ${formatDateTime(
            estimatedInvoice.calculationDate
          )}`}
        </Paragraph>
        <div className={css['accuracy-label']}>
          <Paragraph>{getTranslation('contracts.estimation_accuracy_accuracy')}:</Paragraph>
          <EstimatedInvoiceAccuracy
            id="passed-down-id"
            accuracy={estimatedInvoice.accuracy}
            size="large"
            usage="dialog-main"
          />
        </div>
      </div>

      <div className={css['billing-items']}>
        {contractLines.length > 0 && (
          <>
            <FlexElements alignItems="center">
              <Paragraph className={css['cost-type-title']}>{getTranslation('invoice.contractual_costs')}</Paragraph>
              <HorizontalDivider />
              <Paragraph className={css['cost-type-title']}>{formatMoney(contractLinesTotal, culture)}</Paragraph>
            </FlexElements>

            <BillingItemsListHeader />
            <EstimatedBillingItems
              lines={contractLines}
              productName={estimatedInvoice.productName}
              validationRef={validationRef}
            />
          </>
        )}

        {Object.keys(groupedByLocationId).length > 0 && (
          <div className={css['locations-block']}>
            <FlexElements alignItems="center">
              <Paragraph className={css['cost-type-title']}>
                {getTranslation('contracts.amount_locations', { amount: Object.keys(groupedByLocationId).length })}
              </Paragraph>
              <HorizontalDivider />
              <Paragraph className={css['cost-type-title']}>{formatMoney(locationsTotal, culture)}</Paragraph>
            </FlexElements>

            <BillingItemsListHeader />
            {Object.keys(groupedByLocationId).map((locationId) => {
              const locationAddress =
                contract.serviceLocations.find((l) => l.id === locationId)?.address?.localizedDisplay || '';
              const locationLines = groupedByLocationId[locationId];

              return (
                <Fragment key={locationId}>
                  <Paragraph className={css['address']} bold>
                    {locationAddress}
                  </Paragraph>
                  <EstimatedBillingItems
                    lines={locationLines}
                    productName={estimatedInvoice.productName}
                    validationRef={validationRef}
                  />
                </Fragment>
              );
            })}
          </div>
        )}

        <HorizontalDivider />

        <div className={css['total']}>
          <Heading headingType="h3">{getTranslation('general.total')}</Heading>
          <Heading headingType="h3">{formatMoney(estimatedInvoice.totalAmountInclVAT, culture)}</Heading>
        </div>
      </div>
    </>
  );
});

export default observer(EstimatedInvoiceDialog);
