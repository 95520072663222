import {
  AssignInvoicesToOutgoingBankingTransactionRequestType,
  OutgoingBankingTransactionRequestType,
  OutgoingBankingTransactionsExportResponse,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import {
  exportOutgoingBankingTransactionPaymentRefundFormatType,
  outgoingBankingTransactionType
} from '@zf/api-types/enums';

import BaseService from '../../../../../app-context/services/BaseService';
import ApplicationStore from '../../../../../app-context/stores/domain/ApplicationStore';
import { METHODS } from '../../../../../utils/request';
import OutgoingBankingTransactionsStore from '../stores/OutgoingBankingTransactionsStore';

export default class OutgoingBankingTransactionsService extends BaseService {
  private outgoingBankingTransactionsStore: OutgoingBankingTransactionsStore;

  constructor(outgoingBankingTransactionsStore: OutgoingBankingTransactionsStore, applicationStore: ApplicationStore) {
    super('/bill/outgoingbankingtransactions/', applicationStore);
    this.outgoingBankingTransactionsStore = outgoingBankingTransactionsStore;
    this.applicationStore = applicationStore;
  }

  createOutgoingBankingTransaction = async (outgoingBankingTransaction: OutgoingBankingTransactionRequestType) => {
    return (
      await this.applicationStore.sendRequest<OutgoingBankingTransactionsExportResponse[]>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}create`,
          data: outgoingBankingTransaction
        }
      })
    ).data;
  };

  assignToTransaction = async (data: AssignInvoicesToOutgoingBankingTransactionRequestType) => {
    await this.applicationStore.sendRequest<OutgoingBankingTransactionType>({
      request: {
        method: METHODS.POST,
        endpoint: this.endpoint,
        data
      }
    });
  };

  getOutgoingBankingTransactionsExport = async (outgoingBankingTransactionIds: string[]) => {
    return (
      await this.applicationStore.sendRequest<OutgoingBankingTransactionsExportResponse[]>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}export`,
          data: {
            outgoingBankingTransactionIds
          }
        }
      })
    ).data;
  };

  downloadOutgoingBankingTransaction = async (
    outgoingbankingtransaction: OutgoingBankingTransactionType,
    formatType: exportOutgoingBankingTransactionPaymentRefundFormatType
  ) => {
    return this.applicationStore.sendRequest<BlobPart>(
      {
        request: {
          method: METHODS.POST,
          endpoint: `/bill/integration/export/outgoingbankingtransaction/${
            outgoingbankingtransaction.type === outgoingBankingTransactionType.paymentrefunds
              ? 'paymentrefund'
              : 'paymentrequest'
          }/${outgoingbankingtransaction.id}`,
          data: {
            formatType
          }
        }
      },
      true
    );
  };
}
