import React from 'react';

import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../hooks/useStore';
import TranslationTable from './components/translationTable';
import TranslationFilters from './TranslationFilters';
import css from './translations-card.module.scss';

export default function TranslationsCard() {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card className={css['translations-container']} width="2" id="communication-config-templates-card-transaltions">
      <CardHeader extraRight={<TranslationFilters />}>{getTranslation('communication.translations')}</CardHeader>
      <CardBody className={css['cardbody-translations']} type="indexTable" noMaxHeight>
        <TranslationTable />
      </CardBody>
    </Card>
  );
}
