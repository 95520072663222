import { observer } from 'mobx-react';
import React from 'react';

import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { colors } from '@zf/utils/src/color';

import useRefresh from '../../../app-context/hooks/use-refresh';
import CustomerAutoFill, { CustomerAutofillProps } from '../../../components/Autofills/CustomerAutoFill';
import PropertyGroupAutoFill, { PropertyGroupAutofillProps } from '../../../components/Autofills/PropertyGroupAutoFill';
import LocationExport from '../../../components/Button/Excel/LocationExport';
import ChangeProductListPageButton from '../../../components/Button/Location/ChangeProductListPageButton';
import ExportServiceConsumptionsListPageButton from '../../../components/Button/Location/ExportServiceConsumptionsListPageButton';
import InstallMeterListPageButton from '../../../components/Button/Location/InstallMeterListPageButton';
import RecalculateEavListPageButton from '../../../components/Button/Location/RecalculateEavListPageButton';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import { useStore } from '../../../hooks/useStore';
import useLocationFilter from '../hooks/useLocationFilter';

type Props = {
  search?: string;
  showActions?: boolean;
  showQuickFilters?: boolean;
  showFilterInputs?: boolean;
  extraColumnTitles?: string[];
};

const InlineCustomerAutoFill = InlineInputField<CustomerAutofillProps>(CustomerAutoFill);
const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);

const LocationsFilterBar = (props: Props) => {
  const { extraColumnTitles = [], showActions = true, showFilterInputs = true, showQuickFilters = true } = props;
  const { applicationStore, serviceLocationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { forecastingService } = serviceLocationStore;

  const {
    overviewCount,
    quickFilter,
    searchValue,
    filterTags,
    queryParams,
    onFilter,
    onSearch,
    clearQueryParams,
    setQueryParams
  } = useLocationFilter(props.search);
  const { refresh } = useRefresh();

  return (
    <FilterBar
      subject={getTranslation('location.serviced_locations')}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      refreshPage={refresh}
      actions={
        showActions
          ? [
              <InstallMeterListPageButton key="installMeter" />,
              <ChangeProductListPageButton key="changeProduct" />,
              <LocationExport key="locationExport" extraColumnTitles={extraColumnTitles} />,
              <ExportServiceConsumptionsListPageButton key="serviceconsumptions-export" />,
              <RecalculateEavListPageButton
                key="recalculateEav"
                entity="location"
                forecastingService={forecastingService}
              />
            ]
          : []
      }
      filterButtons={
        showQuickFilters && [
          <FilterButton
            id="all"
            key="all"
            onTrigger={() => onFilter('all')}
            icon={<Icon type="location" />}
            count={overviewCount.all}
            title={getTranslation('locations_list.filter.all_locations')}
            activeColor={colors['blue-400']}
            active={quickFilter === 'all'}
          />,
          <FilterButton
            id="supplied"
            key="supplied"
            onTrigger={() => onFilter('supplied')}
            icon={<Icon type="location" />}
            count={overviewCount.supplied}
            title={getTranslation('locations_list.filter.supplied_locations')}
            activeColor={colors['green-600']}
            active={quickFilter === 'supplied'}
          />,
          <FilterButton
            id="unsupplied"
            key="unsupplied"
            onTrigger={() => onFilter('unsupplied')}
            icon={<Icon type="location" />}
            count={overviewCount.unsupplied}
            title={getTranslation('locations_list.filter.unsupplied_locations')}
            activeColor={colors['yellow-600']}
            active={quickFilter === 'unsupplied'}
          />
        ]
      }
      filterInputs={
        showFilterInputs && [
          <Label key="label-property-group">{getTranslation('property_groups.property_group')}</Label>,
          <InlinePropertyGroupAutoFill
            id="by_property_group"
            key="by_property_group"
            onChange={(val) =>
              setQueryParams({
                propertyGroupId: val[0]?.id || '',
                propertyGroupName: val[0]?.name || ''
              })
            }
            selectedValues={[queryParams.propertyGroupId || '']}
            renderTopLvl={false}
            showDetails={false}
          />,

          <Label key="label-customer">{getTranslation('customer.customer')}</Label>,
          <InlineCustomerAutoFill
            id="customer-filter"
            key="customer-filter"
            onChange={(val) =>
              setQueryParams({
                customerId: val[0]?.id || '',
                customerName: val[0]?.shortDisplayName || ''
              })
            }
            selectedValues={[queryParams.customerId || '']}
            renderTopLvl={false}
            showDetails={false}
          />
        ]
      }
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('locations_list.filter.search_placeholder')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
};

export default observer(LocationsFilterBar);
