import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { MeterType } from '@zf/api-types/master-data/meter';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../../app-context';
import MeterAutoFill from '../../../components/Autofills/MeterAutoFill';
import Select from '../../../components/input/Select';
import { SuspenseSpinner } from '../../../components/suspense';
import MeterWizard from '../../devices/meter/wizard/MeterWizard';
import css from './meter-add-to-location.module.scss';

type Props = {
  index: number;
  usedInInstallWiz?: boolean;
  meter: MeterType | null;
  createNewMeter: boolean;
  meterType_?: meterType;
  setMeter: (meter: MeterType | null) => void;
  onFocusStep: (step: string) => void;
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>;
  setCreateNewMeter: (value: boolean) => void;
};

export default function MeterAddToLocation(props: Props) {
  const {
    index,
    meter,
    createNewMeter,
    meterType_,
    usedInInstallWiz = false,
    setMeter,
    onFocusStep,
    setFeedback,
    setCreateNewMeter
  } = props;

  const { i18n } = useAppContext();

  const onSearchChange = (value: boolean) => {
    if (value !== undefined) {
      setCreateNewMeter(value);
    } else {
      setMeter(null);
    }
  };

  const handleFocus = () => {
    return () => onFocusStep('meters');
  };

  const query: Record<string, any> = {
    quickFilter: 'inactive'
  };

  if (meterType_) {
    query.meterType = meterType_;
  }

  return (
    <>
      {!meter && (
        <InputContainer key="inputContainerMeter" grid={false}>
          <Select
            id={`meterCreationSelect-${index}`}
            onChange={(val) => onSearchChange(val[0])}
            selectedValues={[createNewMeter]}
            values={[
              {
                icon: 'meter',
                value: false,
                text: i18n.getTranslation('location.existing_meter')
              },
              {
                icon: 'meter-add',
                value: true,
                text: i18n.getTranslation('location.new_meter')
              }
            ]}
          />
        </InputContainer>
      )}
      {
        <SuspenseSpinner>
          {createNewMeter ? (
            <MeterWizard
              id={`meter-wizard-${index}`}
              index={index}
              meterType_={meterType_}
              feedback={[]}
              onFocusStep={onFocusStep}
              setNewMeter={(value) => setMeter(value)}
              setCreateNewMeter={setCreateNewMeter}
              setFeedback={setFeedback}
              startingIndex={usedInInstallWiz ? 12 : 4}
            />
          ) : (
            <MeterAutoFill
              id={`selectMeter-${index}`}
              className={css['meter-autofill']}
              onChange={(value) => setMeter(value[0])}
              selectedValues={[meter ? meter.id : '']}
              placeholder={i18n.getTranslation('location.search_meter')}
              onFocus={handleFocus}
              query={query}
            />
          )}
        </SuspenseSpinner>
      }
    </>
  );
}
