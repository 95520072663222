import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';

import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { CrumbHeader } from '../../components/Header';
import ZFRouter from '../../components/Router/ZFRouter';
import { SuspenseSpinner } from '../../components/suspense';
import { useStore } from '../../hooks/useStore';
import Route from '../route';
import BankAccountsTab from './bank-accounts/bank-accounts-tab';
import CollectionCyclesTab from './collection-cycles/collection-cycles-tab';
import ParametersTab from './parameters/parameters-tab';
import PaymentTermsTab from './payment-terms/payment-terms-tab';

type Props = {
  navigate: (href: string) => void;
};

const PaymentsConfig = (props: Props) => {
  const { navigate } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [tabItems] = useState([
    {
      id: 'parameters',
      title: getTranslation('payment_config.parameters'),
      isDefault: true
    },
    {
      id: 'payment_terms',
      title: getTranslation('payment_terms.payment_terms')
    },
    {
      id: 'collection_flows',
      title: getTranslation('collection_flows.collection_flows')
    },
    {
      id: 'bank_accounts',
      title: getTranslation('organization.bank_accounts')
    }
  ]);

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  const splitUrl = window.location.href.split('/');

  const paymentTermsTab = () => (
    <SuspenseSpinner>
      <PaymentTermsTab />
    </SuspenseSpinner>
  );

  const collectionCyclesTab = () => (
    <SuspenseSpinner>
      <CollectionCyclesTab />
    </SuspenseSpinner>
  );

  const bankAccountsTab = () => (
    <SuspenseSpinner>
      <BankAccountsTab />
    </SuspenseSpinner>
  );

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="cog" text={getTranslation('payment_config.crumb_title')} />
          </BreadCrumb>
        }
      />
      <TabContainer
        onSelectTab={setActiveTab}
        entityId={getTranslation('nav.config_payment')}
        selectedTab={splitUrl[splitUrl.length - 1]}
        tabItems={tabItems}
      >
        <ZFRouter>
          <Route Component={ParametersTab} path="parameters" default />
          <Route Component={paymentTermsTab} path="payment_terms" />
          <Route Component={collectionCyclesTab} path="collection_flows" />
          <Route Component={bankAccountsTab} path="bank_accounts" />
        </ZFRouter>
      </TabContainer>
    </>
  );
};

export default observer(PaymentsConfig);
