import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction } from 'react';

import { CardItem } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../../app-context';
import CountryInput, { CountryInputProps } from '../../../../components/input/country-input';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import { BaseButton } from '../../../../design-system/Components';
import { Paragraph } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import css from './customer-details-card.module.scss';
import { LocalAddressType } from './CustomerDetailsCard';
import { formatAddress } from 'utils/address';

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineCountryInput = InlineInputField<CountryInputProps>(CountryInput);

type Props = {
  address: LocalAddressType;
  isAddressEditing: boolean;
  setIsAddressEditing: Dispatch<SetStateAction<boolean>>;
  setAddress: (val: Partial<LocalAddressType>) => void;
  handleCancel: () => void;
};

const CustomerAddress = (props: Props) => {
  const { address, isAddressEditing, setIsAddressEditing, setAddress, handleCancel } = props;
  const { authReducer } = useAppContext();
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const { hasPermission } = authReducer;

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      handleCancel();
    } else if (event.key === 'Enter') {
      const elem = document.activeElement as HTMLElement;
      if (elem) elem.blur();
    }
  };

  const { streetName, streetNumber, streetNumberAddition, postalCode, city, country } = address;

  return (
    <>
      <CardItem width={isAddressEditing ? '5' : '6'}>
        <Label>{getTranslation('customer.invoice_address')}</Label>
      </CardItem>
      <CardItem width={isAddressEditing ? '7' : '6'}>
        {hasPermission('customer.update_invoiceaddress') ? (
          <>
            {isAddressEditing ? (
              <div className={css['invoice-address']}>
                <div className={css['street-name']}>
                  <InlineInputFieldInput
                    onKeyDown={keyHandler}
                    value={streetName}
                    onChange={(streetName) => setAddress({ streetName })}
                    placeholder={getTranslation('location.street_name')}
                    id="customer-detail-streetname"
                    error={!streetName}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                </div>
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={streetNumber}
                  onChange={(streetNumber) => setAddress({ streetNumber })}
                  placeholder={getTranslation('location.streetnr')}
                  id="customer-detail-streetNumber"
                  error={!streetNumber}
                />
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={streetNumberAddition}
                  onChange={(streetNumberAddition) => setAddress({ streetNumberAddition })}
                  placeholder={getTranslation('location.streetnraddition')}
                  id="customer-detail-streetNumberAddition"
                />
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={postalCode}
                  onChange={(postalCode) => setAddress({ postalCode })}
                  placeholder={getTranslation('location.postal')}
                  id="customer-detail-postalCode"
                  error={!postalCode}
                />
                <InlineInputFieldInput
                  onKeyDown={keyHandler}
                  value={city}
                  onChange={(city) => setAddress({ city })}
                  placeholder={getTranslation('location.city')}
                  id="customer-detail-city"
                  error={!city}
                />
                <InlineCountryInput
                  selectedValues={[country]}
                  onChange={(val) => setAddress({ country: val[0] })}
                  placeholder={getTranslation('location.country')}
                  id="customer-detail-country"
                  error={!country}
                />
              </div>
            ) : (
              <BaseButton
                id="address-edit-trigger"
                onClick={() => setIsAddressEditing(true)}
                className={css['address-input']}
              >
                <Paragraph textAlign="right">{formatAddress(address, getEnumTranslation)}</Paragraph>
              </BaseButton>
            )}
          </>
        ) : (
          <Paragraph textAlign="right">{formatAddress(address, getEnumTranslation)}</Paragraph>
        )}
      </CardItem>
    </>
  );
};

export default observer(CustomerAddress);
