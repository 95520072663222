import { useStore } from 'hooks/useStore';
import React from 'react';

import BaseBareDropdown from './BaseBareDropdown';
import { DropdownGenericBaseType, DropdownProps } from './types';

const BareDropdown = <T extends DropdownGenericBaseType>(props: DropdownProps<T>) => {
  const {
    id,
    dropdownTools,
    details,
    selectedValues,
    values,
    clearText,
    emptyText,
    clear,
    setDropdownTools,
    onChange,
    onClear,
    isMultipleSelected
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <BaseBareDropdown
      {...props}
      id={id}
      dropdownTools={dropdownTools}
      details={details}
      values={values}
      selectedValues={selectedValues}
      clearText={clearText || getTranslation('dropdown.clear')}
      emptyText={emptyText || getTranslation('dropdown.empty')}
      onChange={onChange}
      setDropdownTools={setDropdownTools}
      onClear={onClear}
      clear={clear}
      isMultipleSelected={isMultipleSelected}
    />
  );
};

export default BareDropdown;
