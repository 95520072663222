import clone from 'clone';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';
import { EstimatedAnnualVolumeType } from '@zf/api-types/forecasting';
import { ConsumptionBarDataType, ServiceConsumptionGraphValueType } from '@zf/api-types/graph';
import { ConsumptionFlatValueType, FilterType } from '@zf/api-types/master-data/meter';
import { groupBy } from '@zf/utils/src/array';

import { useAppContext } from '../../../../../app-context';
import { METHODS, sendRequest } from '../../../../../utils/request';
import { useTracked } from '../context/consumptions-context';
import useServiceConsumptions from './useServiceConsumptions';

export default function useRegularGraph() {
  const [state] = useTracked();
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const {
    locationId,
    timeStamp,
    filterTypes,
    queryParams,
    selectedFilterTypeIds,
    isCompareMode,
    setIsLoading,
    setState
  } = useServiceConsumptions();

  const getFlatValues = async (
    filterType: FilterType,
    startDateTime: Moment,
    endDateTime: Moment
  ): Promise<ServiceConsumptionGraphValueType> => {
    const query = {
      ...queryParams,
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
      utilityType: filterType.utilityType,
      unitOfMeasure: filterType.unitOfMeasure
    };

    const consumptionValues = (
      await sendRequest<ConsumptionFlatValueType[]>({
        request: {
          method: METHODS.GET,
          endpoint: `/me/ServiceConsumptions/${locationId}/flat`,
          query
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;

    let eavValues: EstimatedAnnualVolumeType[] = [];

    if (queryParams.groupByPeriod !== aggregationFrequency.none) {
      eavValues = (
        await sendRequest<EstimatedAnnualVolumeType[]>({
          request: {
            method: METHODS.GET,
            endpoint: `/fct/estimations/servicelocations/${locationId}/consumptions`,
            query
          },
          tenantReducer,
          lang: i18n.lang
        })
      ).data;
    }

    let groupedByStartDateTime: any = {};

    if (queryParams.groupByPeriod !== aggregationFrequency.none) {
      // Rename value variable to be able to merge data
      // Take a clone before deleting attributes because this causes memory issues
      const cloned = clone(eavValues);
      cloned.forEach((eavv) => {
        // @ts-ignore
        eavv.estimatedAnnualVolume = eavv.value;
        // @ts-ignore
        delete eavv.value;
      });

      const merged = [...consumptionValues, ...cloned];
      groupedByStartDateTime = groupBy(merged, 'startDateTime') as Record<string, ConsumptionBarDataType[]>;

      Object.keys(groupedByStartDateTime).forEach((key) => {
        const entry = groupedByStartDateTime[key];
        groupedByStartDateTime[key] = entry.reduce((result: any, current: any) => Object.assign(result, current), {});
      });
    }

    return {
      type: 'consumptions',
      groupByPeriod: queryParams.groupByPeriod,
      externalIdentifier: filterType.utilityType + filterType.unitOfMeasure,
      description: enumReducer.getTranslation('utilityType', filterType.utilityType),
      utilityType: filterType.utilityType,
      uom: filterType.unitOfMeasure,
      rawData: consumptionValues || ([] as ConsumptionFlatValueType[]),
      groupedData: groupedByStartDateTime as Record<string, ConsumptionBarDataType>
    };
  };

  const getConsumptions = async (selectedTypes: FilterType[], tmpStartDate: Moment, tmpEndDate: Moment) => {
    let resultConsumption: ServiceConsumptionGraphValueType[] = [];

    if (selectedTypes.length > 0) {
      resultConsumption = await Promise.all(
        selectedTypes.map((filterType) => getFlatValues(filterType, tmpStartDate, tmpEndDate))
      );
    }

    if (queryParams.groupByPeriod === aggregationFrequency.none) {
      resultConsumption.forEach((series) => {
        series.rawData.forEach((dataObject) => {
          const startDate = moment(dataObject.startDateTime);
          const unixTime = parseInt(
            startDate.year() === 1 ? moment(dataObject.endDateTime).format('x') : startDate.format('x')
          );
          dataObject.time = unixTime;
        });
      });
    }

    return resultConsumption;
  };

  const getGraphValues = async () => {
    setIsLoading(true);

    let tmpStartDate = queryParams.startDateTime;
    let tmpEndDate = queryParams.endDateTime;

    if (
      queryParams.groupByPeriod === aggregationFrequency.monthly ||
      queryParams.groupByPeriod === aggregationFrequency.yearly
    ) {
      if (tmpStartDate && tmpEndDate) {
        tmpStartDate = clone(tmpStartDate).startOf('year');
        tmpEndDate = clone(tmpEndDate).add(1, 'year').startOf('year');
      }
    }

    const selectedTypes = filterTypes.filter((ft) => {
      return selectedFilterTypeIds.includes(ft.utilityType + ft.unitOfMeasure);
    });

    const resultConsumptions = await getConsumptions(selectedTypes, tmpStartDate, tmpEndDate);

    setState({
      graphValues: resultConsumptions,
      selectedFilterTypes: selectedTypes,
      graphIsLoading: false
    });
  };

  useEffect(() => {
    getGraphValues();
  }, [queryParams, isCompareMode, state.selectedFilterTypeIds, timeStamp]);

  return {
    graphValues: state.graphValues,
    groupByPeriod: state.queryParams.groupByPeriod,
    selectedFilterTypes: state.selectedFilterTypes
  };
}
