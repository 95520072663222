import React from 'react';

import { useAppContext } from '../../../../app-context';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../../components/BreadCrumb';
import { CrumbHeader } from '../../../../components/Header';
import { MeterProvider } from '../context/meter-state';
import useMeterDetail from '../hooks/useMeterDetail';
import MeterTabContainer from './MeterTabContainer';

type Props = {
  navigate: (href: string) => void;
  urlParams: { [key: string]: string };
};

export default function Meter(props: Props) {
  const { navigate, urlParams } = props;
  const { meter, status, navigator, activeState, timeStamp, refresh, setMeter } = useMeterDetail(urlParams.meterID);
  const { i18n, tenantReducer } = useAppContext();

  if (!meter) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              icon="list"
              text={i18n.getTranslation('meter.meters')}
              url={`${tenantReducer.state.rootUrl}/devices/meters`}
            />
            <CrumbDivider />
            <Crumb
              icon={!meter.parentMeterRelation ? 'meter' : 'meter-linked'}
              text={Object.keys(meter).length > 0 ? meter.serialNumber : ''}
            />
          </BreadCrumb>
        }
      />
      <MeterProvider>
        <MeterTabContainer
          meter={meter}
          status={status}
          activeState={activeState}
          timeStamp={timeStamp}
          refresh={refresh}
          navigate={navigate}
          navigator={navigator}
          setMeter={setMeter}
        />
      </MeterProvider>
    </>
  );
}
