import { EntityAttachment } from '@zf/api-types/entity-attachments/entity-attachments';
import { attachmentVisibility } from '@zf/api-types/enums';
import { createFormData } from '@zf/auth/src/utils';

import DownloadService from '../../../../app-context/services/DownloadService';
import { METHODS } from '../../../../utils/request';
import FilesStore from './FilesStore';

export default class FilesService<T> extends DownloadService {
  public filesStore: FilesStore<T>;

  constructor(filesStore: FilesStore<T>) {
    super(filesStore.rootStore);
    this.filesStore = filesStore;
  }

  async addEntityAttachment<AddEntityAttachmentValuesType>(apiFriendlyValues: AddEntityAttachmentValuesType) {
    return (
      await this.rootStore.applicationStore.sendRequest<EntityAttachment>({
        request: {
          method: METHODS.POST,
          endpoint: `/att/EntityAttachments/${this.filesStore.selectedEntitySubjectType}`,
          data: createFormData({
            customerId: this.filesStore.customerId,
            ...this.filesStore.entitySpecificVariables,
            ...apiFriendlyValues
          })
        }
      })
    ).data;
  }

  async addEntityAttachmentGroups<AddEntityAttachmentValuesType>(apiFriendlyValues: AddEntityAttachmentValuesType) {
    return (
      await this.rootStore.applicationStore.sendRequest<EntityAttachment>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.filesStore.endpointUrl}`,
          data: createFormData({
            ...this.filesStore.entitySpecificVariables,
            ...apiFriendlyValues
          })
        }
      })
    ).data;
  }

  async updateEntityAttachmentGroups<AddEntityAttachmentValuesType>(
    apiFriendlyValues: AddEntityAttachmentValuesType,
    id: string
  ) {
    return (
      await this.rootStore.applicationStore.sendRequest<EntityAttachment>({
        request: {
          method: METHODS.PATCH,
          endpoint: `${this.filesStore.endpointUrl}/${id}`,
          data: { ...(apiFriendlyValues || null) }
        }
      })
    ).data;
  }

  async updateEntityAttachment(attachmentId: string, visibility: attachmentVisibility) {
    return (
      await this.rootStore.applicationStore.sendRequest<EntityAttachment>({
        request: {
          method: METHODS.PATCH,
          endpoint: `/att/EntityAttachments/${attachmentId}/visibility`,
          data: {
            customerId: this.filesStore.customerId,
            visibility
          }
        }
      })
    ).data;
  }

  async deleteEntityAttachment(entityAttachmentId: string) {
    return (
      await this.rootStore.applicationStore.sendRequest<boolean>({
        request: {
          method: METHODS.DELETE,
          endpoint: `${this.filesStore.endpointUrl}/${entityAttachmentId}`,
          data: this.filesStore.customerId
            ? {
                customerId: this.filesStore.customerId
              }
            : undefined
        }
      })
    ).data;
  }
}
