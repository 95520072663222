import React from 'react';

import { ImportJobType } from '@zf/api-types/import-job';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import MessagesCard from '../../../../cards/Messages/MessagesCard';
import ImportJobDetailCard from './import-job-detail-card';
import ImportJobFileFormatCard from './import-job-file-format-card';
import ImportJobValuesPieChart from './import-job-values-pie-chart';
import ValuesSummaryPieChart from './values-summary-pie-chart';
import css from './pie-chart.module.scss';

type Props = {
  importJob: ImportJobType;
  navigate: (url: string) => void;
};

export default function ImportJobDetail(props: Props) {
  const { importJob } = props;

  return (
    <CardsContainer className={css['import-job-detail-card']}>
      <ImportJobDetailCard importJob={importJob} />
      <ImportJobFileFormatCard importJob={importJob} />
      {importJob.importType === 'migration' ? (
        <ValuesSummaryPieChart importJob={importJob} />
      ) : (
        <ImportJobValuesPieChart importJob={importJob} />
      )}
      <MessagesCard job={importJob} />
    </CardsContainer>
  );
}
