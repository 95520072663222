import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { unitOfMeasure, utilityType } from '@zf/api-types/enums';
import { Card } from '@zf/stella-react/src/atoms/Card';

import { DeleteIcon } from '../../../components/Icon';
import { InlineFloatInput } from '../../../components/input/FloatInput';
import { InlineInputFieldInput } from '../../../components/input/InputField';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../hooks/useStore';
import ConsumptionGroupCardBody from './containers/consumption-groups-card-body';
import ConsumptionGroupsCardHeader from './containers/consumption-groups-card-header';

const CustomGroupCard = () => {
  const { applicationStore, meterConfigStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;
  const { entities, initialentities, sortableFields, isLoading, updateEntity, deleteEntity, sort, loadEntities } =
    meterConfigStore.customCrudService;

  useEffect(() => {
    if (!initialentities) {
      loadEntities();
    }
  }, []);

  const editablerows = entities?.map((entity, index) => {
    return {
      code: (
        <InlineInputFieldInput
          disabled={!!entity.id}
          id={`metering.consumption-groups.name-${index}`}
          value={entity.code}
          onChange={(val) => updateEntity({ code: val }, index)}
          error={!entity.code}
        />
      ),
      description: (
        <InlineInputFieldInput
          id={`metering.consumption-groups.description-${index}`}
          value={entity.description}
          onChange={(val) => updateEntity({ description: val }, index)}
          error={!entity.description}
        />
      ),
      utilityType: (
        <SimpleDropdown
          disabled={!!entity.id}
          id={`metering.consumption-groups.utilityType-${index}`}
          onChange={(val) => updateEntity({ utilityType: val[0] }, index)}
          placeholder={getTranslation('meter.utility_type')}
          values={getEnum<utilityType>('utilityType', false)}
          selectedValues={[entity.utilityType]}
          error={!entity.utilityType}
        />
      ),
      unitOfMeasure: (
        <SimpleDropdown
          disabled={!!entity.id}
          id={`metering.consumption-groups.unitOfmeasure-${index}`}
          onChange={(val) => updateEntity({ unitOfMeasure: val[0] }, index)}
          placeholder={getTranslation('meter.unit_of_measure')}
          values={getEnum<unitOfMeasure>('unitOfMeasure', false)}
          selectedValues={[entity.unitOfMeasure]}
          error={!entity.unitOfMeasure}
        />
      ),
      amount: (
        <InlineFloatInput
          id={`metering.consumption-groups.amount-${index}`}
          value={entity.estimatedAnnualVolume}
          onChange={(val) => updateEntity({ estimatedAnnualVolume: val }, index)}
          error={!entity.estimatedAnnualVolume}
        />
      ),
      deleteAction: (
        <DeleteIcon
          id={`model.delete.index-${index}`}
          tooltipFor="consumption-group-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  return (
    <Card width="3" id="custom-groups-card">
      <ConsumptionGroupsCardHeader title={getTranslation('custom_groups')} help canEdit />
      <ConsumptionGroupCardBody
        emptyState={getTranslation('consumption_groups.custom_emptystate')}
        isLoading={isLoading}
        sort={sort}
        sortableFields={sortableFields}
        rows={editablerows}
      />
    </Card>
  );
};

export default observer(CustomGroupCard);
