import { observer } from 'mobx-react';
import React from 'react';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  nodeId: string;
  billingId: string;
  productId: string;
};

const GoToEditorListItem = (props: Props) => {
  const { nodeId, productId, billingId } = props;

  const { applicationStore, productConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { productsStore } = productConfigStore;
  const { switchToEditor } = productsStore;

  return (
    <DropdownAction
      id="go-to-editor"
      icon="sign"
      actionType="direct"
      onClick={() => switchToEditor(nodeId, billingId, productId)}
    >
      {getTranslation('billing_tariff.goto_editor')}
    </DropdownAction>
  );
};

export default observer(GoToEditorListItem);
