import classNames from 'classnames';
import React from 'react';

import css from './detail-line-wrapper.module.scss';

type Props = {
  children: React.ReactNode;
  borderLeft?: boolean;
};

export default function DetailLineWrapper(props: Props) {
  const { children, borderLeft = false } = props;

  return <div className={classNames(css['detail-line-wrapper'], { [css['border-left']]: borderLeft })}>{children}</div>;
}
