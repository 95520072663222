import ProductAutoFill from 'components/Autofills/ProductAutoFill';
import InputField, { InputFieldProps } from 'components/input/InputField';
import { Info, SettingDescription } from 'design-system/ComponentSets';
import { Spinner } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { invoiceFrequency, utilityType } from '@zf/api-types/enums';
import { ConsumerGroupType, GetServiceLocationMatchingConsumerGroupsType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption } from '@zf/api-types/master-data/contract';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { ProductType, RequiredEstimationsType } from '@zf/api-types/product';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';

import RequiredEstimationsSection from './RequiredEstimationsSection';

// Local data format used by this component to make it reusable
export type ContractDataType = {
  productId: string;
  externalContractReference: string;
  propertyBillingConfig: PropertyGroupBillingConfigurationType | null | undefined;
  invoiceFrequency: invoiceFrequency | null;
  usePropertyGroupProduct: boolean;
  hasCustomProductConfig: boolean;
  estimatedConsumptions: ContractedServiceEstimatedConsumption[];
  selectedLocations: ServiceLocationType[];
  invoiceFrequency_control?: invoiceFrequency | null;
  nextInvoiceDate?: Moment | null;
  contractNumber?: string;
  invoiceUpfront?: boolean;
};

type Props = {
  values: ContractDataType;
  organizationConfig?: OrganizationConfigType;
  showExternalReference?: boolean;
  utilityTypes: utilityType[];
  setContractData: (val: Partial<ContractDataType>) => void;
  handleCustomProdConfigChange?: (val: boolean) => void;
  handleProductChange: (val: ProductType[]) => void;
  handleEstimatedConsumptionChange: (index: number, val: Partial<ContractedServiceEstimatedConsumption>) => void;
  handleExternalContractReferenceChange: (id: string) => void;
  initEstimatedConsumptions: (
    matchingGroups: GetServiceLocationMatchingConsumerGroupsType[][],
    requiredEstimations: RequiredEstimationsType[]
  ) => Promise<void>;
  onFocusStep?: (step: string) => void;
};

export const hasProductError = (
  productId: string,
  propertyBillingConfig?: PropertyGroupBillingConfigurationType | null
) => {
  if (propertyBillingConfig) {
    return !propertyBillingConfig.costAllocationEnabled && !productId;
  }

  return !productId;
};

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);

/**
 * @deprecated This is the old shared UI between move in wizard and tasks, is now tasks only
 */
const ContractDataForm = (props: Props) => {
  const {
    values,
    utilityTypes,
    organizationConfig,
    showExternalReference = true,
    setContractData,
    handleCustomProdConfigChange,
    handleProductChange,
    handleEstimatedConsumptionChange,
    handleExternalContractReferenceChange,
    initEstimatedConsumptions,
    onFocusStep
  } = props;

  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getConsumerGroups } = contractStore.contractApiService;

  const [consumerGroups, setConsumerGroups] = useState<ConsumerGroupType[]>();

  useEffect(() => {
    if (!consumerGroups) {
      getConsumerGroups().then((groups) => setConsumerGroups(groups));
    }
  }, []);

  if (!consumerGroups) return <Spinner centered />;

  const handleFocus = () => {
    if (onFocusStep) onFocusStep('contract-data');
  };

  const showProductDropdown =
    !values.usePropertyGroupProduct || (values.hasCustomProductConfig && values.usePropertyGroupProduct);

  return (
    <>
      {(organizationConfig?.manuallySetContractNumber || organizationConfig?.migrationMode) && (
        <SettingDescription
          title={getTranslation('contracts.wizard.number_title')}
          description={getTranslation('contracts.wizard.number_descr')}
          setting={
            <WizardInputField
              id="contractId"
              onChange={(contractNumber) => setContractData({ contractNumber })}
              value={values.contractNumber}
              placeholder={getTranslation('contracts.contract_id')}
              error={!values.contractNumber}
              onFocus={handleFocus}
            />
          }
        />
      )}

      <SettingDescription
        title={getTranslation('contracts.wizard.product_title')}
        description={getTranslation('contracts.wizard.product_descr')}
        setting={
          showProductDropdown && (
            <ProductAutoFill
              id="product"
              onChange={handleProductChange}
              selectedValues={[values.productId]}
              error={hasProductError(values.productId, values.propertyBillingConfig)}
              onFocus={handleFocus}
            />
          )
        }
        isMandatory
      />

      {values.usePropertyGroupProduct && (
        <>
          {!values.hasCustomProductConfig && <Info>{getTranslation('contracts.wizard.custom_prod_config_info')}</Info>}
          <CheckBox
            id="deviatingProdCfg"
            checked={values.hasCustomProductConfig}
            onChange={handleCustomProdConfigChange}
          >
            {getTranslation('contracts.wizard.custom_prod_config_checkbox')}
          </CheckBox>
        </>
      )}

      <SettingDescription
        title={getTranslation('contracts.wizard.consumption_group_title')}
        description={getTranslation('contracts.wizard.consumption_group_descr')}
        setting={<div />}
        fullWidth
        isMandatory
      />

      <RequiredEstimationsSection
        values={values}
        utilityTypes={utilityTypes}
        consumerGroups={consumerGroups}
        handleEstimatedConsumptionChange={handleEstimatedConsumptionChange}
        initEstimatedConsumptions={initEstimatedConsumptions}
      />

      {showExternalReference && (
        <SettingDescription
          title={getTranslation('contracts.wizard.external_ref_title')}
          description={getTranslation('contracts.wizard.external_ref_descr')}
          setting={
            <WizardInputField
              id="externalId"
              onChange={handleExternalContractReferenceChange}
              value={values.externalContractReference}
              placeholder={getTranslation('contracts.wizard.external_ref_title')}
              onFocus={handleFocus}
            />
          }
        />
      )}
    </>
  );
};

export default observer(ContractDataForm);
