import React from 'react';

import { GeneratedColorAvatar } from '.';

type Props = {
  children: string;
  size?: string;
  isClickable?: boolean;
};

export default function UserAvatar(props: Props) {
  const { children, size = 'small', ...otherProps } = props;

  const splitted = children.split(' ');

  return (
    <GeneratedColorAvatar {...otherProps} circle size={size}>
      {`${splitted?.[0]?.[0] || ''}${splitted?.[1]?.[0] || ''}`}
    </GeneratedColorAvatar>
  );
}
