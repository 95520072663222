import React, { CSSProperties, useMemo, useState } from 'react';

import { transactionReferenceType, transactionType } from '@zf/api-types/enums';
import {
  CollectionStepReferenceParametersType,
  InvoiceReferenceParametersType,
  SettlementRowType,
  TransactionType
} from '@zf/api-types/transactions';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { DEFAULT_ROW_HEIGHT } from '@zf/stella-react/src/atoms/Table/constants';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/types';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { Link } from '../../design-system/Components';
import useCreateRequest from '../../hooks/useCreateRequest';
import useInfiniAPI from '../../hooks/useInfiniAPI';
import { getValutaColor } from '../../utils/money';
import css from './settlement-details-card.module.scss';

type Props = {
  selectedTransaction: TransactionType;
  timestamp?: string;
  dynamicTableHeight?: boolean;
};

function NoDetails() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      icon={<Icon type="ajust-euro" />}
      title={i18n.getTranslation('cards.settlement_details.no-details')}
    />
  );
}

export default function SettlementDetailsTable(props: Props) {
  const { selectedTransaction, timestamp, dynamicTableHeight = false } = props;
  const { tenantReducer, i18n, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const [columns] = useState<ColumnType[]>([
    {
      width: 300,
      label: i18n.getTranslation('general.type'),
      dataKey: 'transactionType'
    },
    {
      width: 180,
      label: i18n.getTranslation('general.date'),
      dataKey: 'transactionDateTime'
    },
    {
      width: 120,
      label: i18n.getTranslation('general.amount_valuta'),
      dataKey: 'amount'
    },
    {
      width: 100,
      label: i18n.getTranslation('cards.transactions.settled'),
      dataKey: 'settledAmount'
    },
    {
      width: 100,
      label: i18n.getTranslation('general.open'),
      dataKey: 'openAmount'
    }
  ]);

  const { request, sortBy, sortDirection, handleSort } = useCreateRequest(
    `/bill/Transactions/${selectedTransaction.id}/settledwith`,
    { timestamp }
  );

  const getReferenceText = (settled: TransactionType) => {
    if (settled.transactionType === transactionType.invoice) {
      const parameters = settled.referenceDetails?.parameters as InvoiceReferenceParametersType;
      return parameters.invoiceNum;
    } else {
      return '';
    }
  };

  const getReferenceUrl = (settled: TransactionType) => {
    switch (settled.referenceDetails?.transactionReferenceType) {
      case transactionReferenceType.invoice:
        return 'invoices';
      case transactionReferenceType.payment:
        return 'payments/payments';
      case transactionReferenceType.collectionstep:
        return 'collection-cases';
    }
  };

  const getReferenceId = (settled: TransactionType) => {
    if (settled.referenceDetails?.transactionReferenceType === transactionReferenceType.collectionstep) {
      const parameters = settled.referenceDetails.parameters as CollectionStepReferenceParametersType;
      return parameters.collectionCaseId;
    } else {
      return settled.referenceDetails?.transactionReferenceId;
    }
  };

  const processRecord = (settled: TransactionType) => {
    const settlementDetails = settled.settlementDetails?.settledTransactionsWithAmounts.find((st) => {
      return st.transactionId === selectedTransaction.id;
    });

    return {
      __id: settled.id,
      transactionType:
        settled.referenceDetails && settled.referenceDetails.transactionReferenceId ? (
          <Link
            id={`transactionType-${settled.id}`}
            url={`${rootUrl}/${getReferenceUrl(settled)}/${getReferenceId(settled)}`}
          >
            {`${
              settled.transactionType ? enumReducer.getTranslation('transactionType', settled.transactionType) : ''
            } ${getReferenceText(settled)}`}
          </Link>
        ) : (
          <Paragraph id={settled.id}>
            {`${
              settled.transactionType ? enumReducer.getTranslation('transactionType', settled.transactionType) : ''
            } ${getReferenceText(settled)}`}
          </Paragraph>
        ),
      transactionDateTime: formatDate(settled.transactionDateTime),
      amount: (
        <Paragraph className={css[getValutaColor(settled.transactionAmount)]}>
          {formatMoney(settled.transactionAmount, i18n.culture)}
        </Paragraph>
      ),
      settledAmount: (
        <Paragraph className={css[settlementDetails ? getValutaColor(settlementDetails.settledAmount) : '']}>
          {settlementDetails ? formatMoney(settlementDetails.settledAmount, i18n.culture) : ''}
        </Paragraph>
      ),
      openAmount: (
        <Paragraph className={css[getValutaColor(settled.transactionOpenAmount)]}>
          {formatMoney(settled.transactionOpenAmount, i18n.culture)}
        </Paragraph>
      )
    };
  };

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<TransactionType, SettlementRowType>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  const style: CSSProperties | undefined = useMemo(() => {
    if (dynamicTableHeight) {
      const base = 2 * (DEFAULT_ROW_HEIGHT / 10);
      const totalHeight = base + rows.length * (DEFAULT_ROW_HEIGHT / 10);
      return { height: `${totalHeight}rem` };
    }
  }, [rows, dynamicTableHeight]);

  const table = (
    <DynamicVirtualTable
      id="transactions-table"
      tooltipId="settlement-details-table-tip"
      rows={rows}
      singleSort
      sort={handleSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      sortableFields={sortableFields}
      loading={loading}
      error={error}
      NoDataOverlay={NoDetails}
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      noSelect
      totalAmountOfRows={rows.length}
      columns={columns}
    />
  );

  return dynamicTableHeight ? <div style={style}>{table}</div> : table;
}
