import moment from 'moment';

import { BillingItemType, ProductBillingItemType } from '@zf/api-types/product';
import { culture } from '@zf/api-types/enums';

export const billingItemSortFunc = <T extends { order: number | null }>(a: T, b: T) => {
  if (a.order && b.order) {
    return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
  }
  return 0;
};

export const getBillingItemIsActive = (
  billingItem: BillingItemType,
  productBillingItems: ProductBillingItemType[] | undefined
) => {
  const matchingProductBillingItem = productBillingItems?.find((pb) => pb.billingItemId === billingItem.id);

  return moment().isBetween(
    matchingProductBillingItem?.startDateTime,
    matchingProductBillingItem?.endDateTime,
    's',
    '[]'
  );
};

export const createEmptyAttachmentFile = (culture: culture, isDefault: boolean) => {
  return {
    culture,
    fileName: '',
    internalFileName: '',
    attachmentId: '',
    file: undefined,
    isDefault
  };
};
