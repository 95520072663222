import React from 'react';

import { useAppContext } from '../../../app-context/app-context';
import Button from '../../Button/Button';

type Props = {
  toggleCompareMode: () => void;
};

export default function CompareButton(props: Props) {
  const { i18n } = useAppContext();

  return (
    <Button id="compare" icon="compare" type="text" size="small" onClick={props.toggleCompareMode}>
      {i18n.getTranslation('general.compare')}
    </Button>
  );
}
