import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useReducer, useState } from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { TabSlider } from '../../../../../../../design-system/Components';
import { Info } from '../../../../../../../design-system/ComponentSets';
import { DialogClickRef, ValidationRef } from '../../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../../../events/notification-events';
import { useStore } from '../../../../../../../hooks/useStore';
import InputsSection from './InputsSection';
import css from './intermediate-invoice-dialog.module.scss';

type Props = {
  completeness: BillingCompletenessType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

type ActionType = 'shorten' | 'lengthen';

export type InvoicePeriodState = {
  type: ActionType;
  fromFirstOpenPeriod: boolean;
  endDateTime: Moment;
};

const AdjustInvoicePeriodDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { completeness, validationRef } = props;
  const { contractStore, applicationStore } = useStore();
  const { billingRelation, billingCompletenessStore } = contractStore;
  const { selectedCompleteness, createIntermediateInvoice, changeNextInvoiceEndDate, checkInvoicePeriodLengthening } =
    billingCompletenessStore;
  const { getTranslation } = applicationStore;

  const stateReducer = createStateReducer<InvoicePeriodState, Partial<InvoicePeriodState>>();
  const [state, setState] = useReducer(stateReducer, {
    type: 'shorten',
    fromFirstOpenPeriod: false,
    endDateTime: moment(MIN_DATE)
  });

  const [tabItems] = useState([
    { id: 'shorten', title: getTranslation('general.shorten') },
    {
      id: 'lengthen',
      title: getTranslation('general.lengthen')
    }
  ]);

  const dateDoesNotExist = isMinDate(state.endDateTime) || !state.endDateTime;

  const { hasDifferentProductInFuture, lengthenNotPossible } = checkInvoicePeriodLengthening(
    dateDoesNotExist ? MAX_DATE : state.endDateTime
  );

  const validate = () => {
    if (validationRef && validationRef.current) {
      let dateOutsideRange = false;

      if (
        state.type === 'shorten' &&
        !dateDoesNotExist &&
        (state.endDateTime.isAfter(selectedCompleteness.periodEndDateTime) ||
          state.endDateTime.isBefore(selectedCompleteness.periodStartDateTime))
      ) {
        dateOutsideRange = true;
        notify.warning({
          content: getTranslation('invoice.adjust_invoice_period_shorten_validation')
        });
      }

      validationRef.current.setIsError(
        dateDoesNotExist || lengthenNotPossible || hasDifferentProductInFuture || dateOutsideRange
      );
    }
  };

  useEffect(() => {
    validate();
  }, [state.endDateTime, hasDifferentProductInFuture, lengthenNotPossible]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (state.type === 'shorten') {
          await createIntermediateInvoice(completeness.id, state.endDateTime);
        } else {
          await changeNextInvoiceEndDate(billingRelation.id, state.fromFirstOpenPeriod, state.endDateTime);
        }

        notify.success({
          content: getTranslation('invoice.adjust_invoice_period_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('invoice.adjust_invoice_period_fail'),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Paragraph>{getTranslation('invoice.adjust_invoice_period_desc')}</Paragraph>

      <div className={css['input-wrapper']}>
        <TabSlider
          id="entry-type"
          tabItems={tabItems}
          selectedTabItem={state.type}
          setSelectedItem={(val) => setState({ type: val as ActionType })}
        />
      </div>

      {state.type === 'lengthen' && lengthenNotPossible ? (
        <Info color={colors['orange-600']}>
          {getTranslation('contracts.billing_action_adjust_invoice_period_not_possible')}
        </Info>
      ) : (
        <InputsSection state={state} setState={setState} hasDifferentProductInFuture={hasDifferentProductInFuture} />
      )}
    </>
  );
});

export default observer(AdjustInvoicePeriodDialog);
