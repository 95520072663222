import { useAppContext } from 'app-context';
import { MultiValue } from 'design-system/ComponentSets';
import { Moment } from 'moment';
import React, { Dispatch, SetStateAction } from 'react';

import {
  dataFrequency,
  direction,
  incrementationType,
  meteringType,
  unitOfMeasure,
  utilityType
} from '@zf/api-types/enums';
import { MeterType } from '@zf/api-types/master-data/meter';

import { formatActiveMeterText } from '../../../utils/meter';
import ReconfigurationNode from './ReconfigurationNode';
import { NodeValueType } from './reconfigure-dialog';

type Props = {
  mutationDateTime: Moment;
  nodeValues: NodeValueType[];
  meter?: MeterType;
  setNodeValues: Dispatch<SetStateAction<NodeValueType[]>>;
};

export default function ReconfigureMultiValue(props: Props) {
  const { nodeValues, meter, mutationDateTime, setNodeValues } = props;

  const { i18n } = useAppContext();

  return (
    <MultiValue
      id="reconfigure-multi-value"
      title={i18n.getTranslation('actions.meter.edit_channels')}
      initialValues={nodeValues}
      intialNodeValue={{
        meterId: meter?.id || '',
        meterName: meter ? formatActiveMeterText(meter) : '',
        mutationDateTime,
        description: '',
        dataFrequency: '' as dataFrequency,
        incrementationType: '' as incrementationType,
        meteringType: meteringType.none,
        utilityType: utilityType.none,
        direction: '' as direction,
        unitOfMeasure: unitOfMeasure.none,
        relationExternalReference: '',
        timeOfUse: '',
        value: '0',
        channelId: '',
        hour: '0',
        minute: '0',
        addingMeasurement: false
      }}
      onChange={(value) => setNodeValues(value)}
    >
      {({ index, value, dispatchValue }) => {
        return (
          <ReconfigurationNode
            index={index}
            value={value}
            mutationDateTime={mutationDateTime}
            dispatchValue={dispatchValue}
          />
        );
      }}
    </MultiValue>
  );
}
