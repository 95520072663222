import React from 'react';

import { InvoiceType, MarkAsSentInvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import SendInvoicePostalDialog from '../dialogs/resend-dialog-postal';

type Props = {
  invoice: InvoiceType;
};

export default function SendInvoicePostalListItem(props: Props) {
  const { invoice } = props;

  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();
  const { invoiceStore } = useStore();

  const invoiceToHandle: MarkAsSentInvoiceType = {
    etag: invoice._etag,
    id: invoice.id
  };

  if (invoiceStore.pageActionPermissions.maySendPostal) {
    return (
      <DropdownAction
        id="invoice.send-postal"
        icon="paper-plane"
        dialogTitle={i18n.getTranslation('actions.resend_postal')}
        dialogContent={<SendInvoicePostalDialog invoiceToHandle={invoiceToHandle} ref={clickRef} />}
        onSubmit={onSubmit}
        buttonPositive={i18n.getTranslation('communication.send')}
      />
    );
  }

  return null;
}
