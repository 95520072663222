import React from 'react';
import { Rectangle } from 'recharts';

type Props = {
  color?: string;
};

export default function CustomBar(props: Props) {
  const { color } = props;

  return <Rectangle {...props} fill={color} />;
}
