import React from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import { ExternalChannelType, MeasurementType, MeterType, MeasurementPair } from '@zf/api-types/master-data/meter';
import { MeasurementDetailsType } from '@zf/api-types/master-data/move-request';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDecimal } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../../app-context';
import { InlineFloatInput } from '../../../../../../components/input/FloatInput';
import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import { getActiveChannels } from '../../../../../../utils/meter';
import css from './measurement.module.scss';

type Props = {
  meterIndex: number;
  existingMeter: MeterType | undefined;
  measurementIndex: number;
  existingContract: ContractType | undefined;
  mutationDateTime: string;
  measurement: MeasurementDetailsType;
  pairs?: MeasurementPair[];
  dispatchValue: (value: Partial<MeasurementDetailsType>) => void;
};

export default function Measurement(props: Props) {
  const {
    meterIndex,
    existingMeter,
    measurementIndex,
    mutationDateTime,
    measurement,
    pairs,
    existingContract,
    dispatchValue
  } = props;
  const { i18n, enumReducer } = useAppContext();

  let dropdownChannels: DropdownValuesType<string>[] = [];
  let matchingPrevious: MeasurementType | null | undefined;
  let matchingNext: MeasurementType | null | undefined;
  let channels: ExternalChannelType[] = [];

  if (existingMeter) {
    channels = existingMeter.channels;
    dropdownChannels = getActiveChannels(channels, existingContract?.createdDateTime || mutationDateTime).map((c) => {
      return {
        text: c.description || c.externalIdentifier,
        value: c.externalIdentifier
      };
    });
  }

  if (pairs && measurement.externalChannelIdentifier) {
    const matchedChannel = pairs.find((p) => p.externalChannelId === measurement.externalChannelIdentifier);

    if (matchedChannel) {
      matchingPrevious = matchedChannel.previousMeasurement;
      matchingNext = matchedChannel.nextMeasurement;
    }
  }

  const UoM = measurement.unitOfMeasure ? enumReducer.getTranslation('unitOfMeasure', measurement.unitOfMeasure) : '';

  const showValueWarning = matchingNext && measurement.value > matchingNext.value;

  return (
    <div className={css['measurement-wrapper']}>
      <div className={css['channel']}>
        <div>
          <Label>{i18n.getTranslation('meter.channel')}</Label>
          <SimpleDropdown
            id={`channel-${meterIndex}-${measurementIndex}`}
            selectedValues={[measurement.externalChannelIdentifier]}
            values={dropdownChannels}
            onChange={(val) => {
              const matchingChannel = channels.find((chann) => {
                return chann.externalIdentifier === val[0];
              });

              if (matchingChannel && existingMeter) {
                dispatchValue({
                  meterSerialNumber: existingMeter.serialNumber,
                  externalChannelIdentifier: matchingChannel.externalIdentifier,
                  timeOfUse: matchingChannel.timeOfUse,
                  unitOfMeasure: matchingChannel.unitOfMeasure,
                  utilityType: matchingChannel.utilityType
                });
              }
            }}
            error={!measurement.externalChannelIdentifier}
            darkMode
          />
        </div>
        <div>
          <Label>{i18n.getTranslation('meter.utility_type')}</Label>
          <Paragraph>
            {measurement.utilityType ? enumReducer.getTranslation('utilityType', measurement.utilityType) : ''}
          </Paragraph>
        </div>
        <div>
          <Label>{i18n.getTranslation('parameters.unitOfMeasure')}</Label>
          <Paragraph>{UoM}</Paragraph>
        </div>
        <div>
          <Label>{i18n.getTranslation('meter.time_of_use_full')}</Label>
          <Paragraph>{measurement.timeOfUse}</Paragraph>
        </div>
      </div>
      <div className={css['previous-current-next']}>
        <div>
          <Label>{i18n.getTranslation('meter.previous_value')}</Label>
          <Paragraph>
            {matchingPrevious
              ? `${formatDecimal(matchingPrevious.value, i18n.culture)} ${measurement.unitOfMeasure ? UoM : ''}`
              : '-'}
          </Paragraph>
        </div>
        <div>
          <Label>{i18n.getTranslation('meter.current_value')}</Label>
          <InlineFloatInput
            id={`current-value-${meterIndex}-${measurementIndex}`}
            className={showValueWarning ? css['current-value-warning'] : undefined}
            value={measurement.value}
            onChange={(val) => dispatchValue({ value: val })}
            darkMode
          />
          {showValueWarning && (
            <Paragraph className={css['warning']}>{i18n.getTranslation('meter.value_warning')}</Paragraph>
          )}
        </div>
        <div>
          <Label>{i18n.getTranslation('meter.next_value')}</Label>
          <Paragraph>
            {matchingNext
              ? `${formatDecimal(matchingNext.value, i18n.culture)} ${measurement.unitOfMeasure ? UoM : ''}`
              : '-'}
          </Paragraph>
        </div>
      </div>
    </div>
  );
}
