import classNames from 'classnames';
import React from 'react';

import css from './progress-bar.module.scss';

type Props = {
  size: 'small' | 'medium' | 'large';
  progress: number;
  className?: string;
  showLabel?: boolean;
};

export default function ProgressBar(props: Props) {
  const { size, className, showLabel = false } = props;
  let { progress } = props;

  if (Number.isNaN(progress)) progress = 100;

  const progressStyle = {
    width: `${progress}%`
  };

  return (
    <div className={classNames(css['progress-bar-container'], className)}>
      <div className={classNames(css['progress-bar'], css[size])}>
        <div
          className={classNames(css['progress'], {
            [css['progress-25']]: progress >= 25 && progress < 50,
            [css['progress-50']]: progress >= 50 && progress < 75,
            [css['progress-75']]: progress >= 75
          })}
          style={progressStyle}
        />
      </div>
      {showLabel && <div className={classNames(css['progress-label'], css[size])}>{progress.toFixed(2)}%</div>}
    </div>
  );
}
