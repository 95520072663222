import moment from 'moment';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { aggregationFrequency } from '@zf/api-types/enums';
import {
  OutputQueryParamsType,
  ServiceConsumptionCompareGraphValueType,
  ServiceConsumptionGraphValueType
} from '@zf/api-types/graph';
import { FilterType } from '@zf/api-types/master-data/meter';
import { CompareMonthType } from '@zf/stella-react/src/atoms/Date/DateInput/month-input';

export type ConsumptionsCtxState = {
  locationId: string;
  filterTypes: FilterType[];
  filterTypesAreLoading: boolean;
  selectedFilterTypes: FilterType[];
  selectedFilterTypeIds: string[];
  queryParams: OutputQueryParamsType;
  graphIsLoading: boolean;
  graphValues: ServiceConsumptionGraphValueType[];
  isCompareMode: boolean;
  compareYears: number[];
  compareMonths: CompareMonthType[];
  compareGraphValues: ServiceConsumptionCompareGraphValueType[];
  timeStamp: string;
};

type Action = { type: 'UPDATE'; newState: Partial<ConsumptionsCtxState> };

const initialState: ConsumptionsCtxState = {
  locationId: '',
  filterTypes: [],
  filterTypesAreLoading: true,
  selectedFilterTypes: [],
  selectedFilterTypeIds: [],
  queryParams: {
    groupByPeriod: aggregationFrequency.none,
    startDateTime: moment().subtract(1, 'year'),
    endDateTime: moment()
  },
  graphValues: [],
  graphIsLoading: false,
  isCompareMode: false,
  compareYears: [moment().subtract(1, 'year').year(), moment().year()],
  compareMonths: [
    { year: moment().subtract(1, 'year').year(), month: 1 },
    { year: moment().year(), month: 1 }
  ],
  compareGraphValues: [],
  timeStamp: ''
};

const reducer = (state: ConsumptionsCtxState, action: Action): ConsumptionsCtxState => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.newState };

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider: ServiceConsumptionsProvider, useTracked, useUpdate: useDispatch } = createContainer(useValue);
