import React from 'react';

import { paymentProcessStatus } from '@zf/api-types/enums';
import { InvoiceOverviewCountType } from '@zf/api-types/invoice';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context';
import useBillingParameters from '../../../../app-context/hooks/use-billing-parameters';
import { Icon } from '../../../../components/Icon';

type Props = {
  overviewCount: InvoiceOverviewCountType;
  quickFilter: string;
  onFilter: (quickFilter: string) => void;
};

export default function InvoiceFilterButtons(props: Props) {
  const { overviewCount, quickFilter, onFilter } = props;
  const { i18n, enumReducer } = useAppContext();
  const billingParameters = useBillingParameters();
  const paymentsDisabled = billingParameters && billingParameters.skipPayment;

  const exportButton = (
    <FilterButton
      id="to-export"
      key="to-export"
      onTrigger={() => onFilter('toexport')}
      icon={<Icon type="export" />}
      count={overviewCount.toExport}
      title={i18n.getTranslation('general.to_export')}
      activeColor={colors['orange-600']}
      active={quickFilter === 'toexport'}
    />
  );

  const standardFilterbuttons = [
    <FilterButton
      id="all"
      key="all"
      onTrigger={() => onFilter('all')}
      icon={<Icon type="invoice" />}
      count={overviewCount.all}
      title={i18n.getTranslation('invoice.all_invoices')}
      activeColor={colors['blue-400']}
      active={quickFilter === 'all'}
    />,
    <FilterButton
      id="to-approve"
      key="to-approve"
      onTrigger={() => onFilter('toapprove')}
      icon={<Icon type="invoice" />}
      count={overviewCount.toApprove}
      title={i18n.getTranslation('invoice.to_approve')}
      activeColor={colors['blue-700']}
      active={quickFilter === 'toapprove'}
    />,
    <FilterButton
      id="to-send"
      key="to-send"
      onTrigger={() => onFilter('tosend')}
      icon={<Icon type="invoice" />}
      count={overviewCount.toSend}
      title={i18n.getTranslation('invoice.to_send')}
      activeColor={colors['green-600']}
      active={quickFilter === 'tosend'}
    />,
    paymentsDisabled && exportButton
  ];

  const filterButtonsPaymentsOnly = [
    <FilterButton
      id="ready-forpayment-collection"
      key="ready-forpayment-collection"
      onTrigger={() => onFilter('readyforpaymentcollection')}
      icon={<Icon type="money" />}
      count={overviewCount.readyForPaymentCollection}
      title={enumReducer.getTranslation('paymentProcessStatus', paymentProcessStatus.readyforpaymentcollection)}
      activeColor={colors['green-700']}
      active={quickFilter === 'readyforpaymentcollection'}
    />,
    exportButton,
    <FilterButton
      id="unpaid"
      key="unpaid"
      onTrigger={() => onFilter('unpaid')}
      icon={<Icon type="invoice" />}
      count={overviewCount.unpaid}
      title={i18n.getTranslation('invoice.unpaid_invoices')}
      activeColor={colors['yellow-600']}
      active={quickFilter === 'unpaid'}
    />,
    <FilterButton
      id="overdue"
      key="overdue"
      onTrigger={() => onFilter('overdue')}
      icon={<Icon type="exclamation" />}
      count={overviewCount.overdue}
      title={i18n.getTranslation('invoice.overdue')}
      activeColor={colors['red-400']}
      active={quickFilter === 'overdue'}
    />
  ];

  return <>{paymentsDisabled ? standardFilterbuttons : standardFilterbuttons.concat(filterButtonsPaymentsOnly)}</>;
}
