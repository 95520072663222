import { ContractReferenceType } from '@zf/api-types/master-data/contract';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { observer } from 'mobx-react';
import React, { forwardRef, Ref } from 'react';
import { Link } from '../../../../../design-system/Components';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../hooks/useStore';
import DynamicVirtualTable from '../../../../Lang/dynamic-virtual-table/DynamicVirtualTable';
import css from './multiple-origin.module.scss';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  contracts: ContractReferenceType[];
};

const MultipleOriginDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { contracts } = props;
  const { applicationStore } = useStore();
  const { getTranslation, rootUrl } = applicationStore;

  const [columns] = React.useState([
    {
      width: 160,
      label: getTranslation('contracts.contract'),
      dataKey: 'contractNumber'
    },
    {
      width: 160,
      label: getTranslation('contracts.sign_date'),
      dataKey: 'signDate'
    }
  ]);

  const rows = contracts.map((c) => {
    return {
      __id: c.contractId,
      contractNumber: (
        <Link id={`contract-${c.contractId}`} bold url={`${rootUrl}/contracts/${c.contractId}`}>
          {c.contractNumber}
        </Link>
      ),
      signDate: c.signedFile ? <NewStatusBadge status_={formatDate(c.signedFile)} color={colors['green-600']} /> : '-'
    };
  });

  return (
    <div className={css['contracts-table']}>
      <DynamicVirtualTable noSelect id="contracts-table" rows={rows} columns={columns} />
    </div>
  );
});

export default observer(MultipleOriginDialog);
