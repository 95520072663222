import { action, makeObservable, observable } from 'mobx';

import { ConfiguredPluginType } from '@zf/api-types/integration/plugin';

import RootStore from '../';
import ConfiguredPluginsService from '../../services/integration/ConfiguredPluginsService';
import CustomPluginActionsService from '../../services/integration/CustomPluginActionsService';
import PluginTriggerService from '../../services/integration/PluginTriggerService';
import CustomerPortalStore from './customer-portal/CustomerPortalStore';
import IntegrationLoggingStore from './logging/IntegrationLoggingStore';

export default class IntegrationStore {
  public rootStore: RootStore;

  public customerPortalStore: CustomerPortalStore;
  public integrationLoggingStore: IntegrationLoggingStore;

  public configuredPluginsService: ConfiguredPluginsService;
  public customPluginActionsService: CustomPluginActionsService;
  public pluginTriggerService: PluginTriggerService;

  public _configuredPlugins: ConfiguredPluginType[] | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.customerPortalStore = new CustomerPortalStore(rootStore, this);
    this.integrationLoggingStore = new IntegrationLoggingStore(rootStore, this);

    this.configuredPluginsService = new ConfiguredPluginsService(rootStore, this);
    this.customPluginActionsService = new CustomPluginActionsService(rootStore.applicationStore);
    this.pluginTriggerService = new PluginTriggerService(this.integrationLoggingStore, rootStore.applicationStore);

    makeObservable(this, {
      _configuredPlugins: observable,

      setConfiguredPlugins: action
    });
  }

  setConfiguredPlugins = (newPlugins: ConfiguredPluginType[]) => {
    this._configuredPlugins = newPlugins;
  };
}
