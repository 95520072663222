import classNames from 'classnames';
import React from 'react';

import Icon from '../Icon/Icon';
import css from './icon-label.module.scss';
import Label from './Label';

export type IconLabelProps = {
  labelText: string;
  color?: string | boolean;
  icon?: React.ReactNode;
  iconType?: string;
  className?: string;
};

export default function IconLabel(props: IconLabelProps) {
  const { labelText, className, color = false, icon = null, iconType = '' } = props;

  const style: React.CSSProperties = {};

  if (typeof color === 'string') {
    style['color'] = color;
  }

  return (
    <div className={classNames(css['icon-label'], className)} style={style}>
      {icon ? icon : <Icon className={css['icon']} name={iconType} />}
      <Label className={css['label']} style={style}>
        {labelText}
      </Label>
    </div>
  );
}
