import React, { useMemo, useState } from 'react';

import { ModelType } from '@zf/api-types/model';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../../components/placeholder';
import { useStore } from '../../../../hooks/useStore';
import usePrepaymentDeviceListPage from './hooks/usePrepaymentDeviceListPage';

type Props = {
  models: ModelType[];
};

export default function PrepaymentDeviceList(props: Props) {
  const { models } = props;
  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;
  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = usePrepaymentDeviceListPage(models);

  const [columns] = useState<ColumnType[]>([
    {
      width: 400,
      label: getTranslation('metering_list.labels.serial'),
      dataKey: 'serialNumber',
      noClick: true
    },
    {
      width: 300,
      label: getTranslation('meter.brand_model'),
      dataKey: 'model'
    },
    {
      width: 300,
      label: getTranslation('meter.last_top_up'),
      dataKey: 'lastTopUpDate'
    },
    {
      width: 400,
      label: getTranslation('prepayment_device.emergency_credit'),
      dataKey: 'configuredEmergencyCredit',
      contentAlignRight: true
    },
    {
      width: 200,
      label: getTranslation('prepayment_device.debt'),
      dataKey: 'debt',
      contentAlignRight: true
    },
    {
      width: 200,
      label: getTranslation('prepayment_device.balance'),
      dataKey: 'currentBalance',
      contentAlignRight: true
    }
  ]);

  return useMemo(() => {
    return (
      <DynamicVirtualTable
        id="meters-table"
        tooltipId="meters-table-id"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={columns}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
}
