import classNames from 'classnames';
import clone from 'clone';
import { Disabled, RadioInput } from 'design-system/Components';
import RadioInputWithDescription from 'design-system/Components/Inputs/RadioInputWithDescription';
import { SettingDescription } from 'design-system/ComponentSets';
import { CalculationTypeParametersEnrichment } from 'features/product-config/stores/BillingItemsDialogStore';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { billingCalculationType, billingScope, unitOfMeasure } from '@zf/api-types/enums';
import {
  CustomEntityPropertyCalculationTypeParameters,
  SubscriptionCalculationTypeParametersType
} from '@zf/api-types/product';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import SimpleDropdown from '../../../../../../components/Lang/SimpleDropdown';
import css from '../add-edit-billing-item-dialog.module.scss';
import { BillingItemValidatorType } from '../AddEditBillingItemDialog';

type Props = {
  billingCalculationType: billingCalculationType;
  calcParams: Map<
    billingCalculationType,
    (SubscriptionCalculationTypeParametersType | CustomEntityPropertyCalculationTypeParameters) &
      CalculationTypeParametersEnrichment
  >;
  uomDropDown: DropdownValuesType<unitOfMeasure>[];
  showContractLvlOption?: boolean;
  setValue: (value: Partial<BillingItemValidatorType>) => void;
  setParametersValue: (
    key: billingCalculationType,
    value: Partial<
      (SubscriptionCalculationTypeParametersType | CustomEntityPropertyCalculationTypeParameters) &
        CalculationTypeParametersEnrichment
    >
  ) => void;
};

const SharedParameters = (props: Props) => {
  const {
    billingCalculationType,
    calcParams,
    uomDropDown,
    showContractLvlOption = true,
    setValue,
    setParametersValue
  } = props;
  const { applicationStore, productConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { billingItemsStore } = productConfigStore;
  const { services } = billingItemsStore.billingItemsDialogStore;

  const setBillingScope = (scope: billingScope) => {
    const clonedCalcParams = clone(calcParams);
    const entryToUpdate = clonedCalcParams.get(billingCalculationType);

    if (entryToUpdate) {
      clonedCalcParams.set(billingCalculationType, {
        ...entryToUpdate,
        scope,
        onlyForSpecificService: false,
        // @ts-ignore
        utilityType: null
      });
      setValue({ calculationParameters: clonedCalcParams });
    }
  };

  const parameters = calcParams.get(billingCalculationType);
  const isOnlyForSpecificService = parameters?.onlyForSpecificService;

  return (
    <>
      {showContractLvlOption && (
        <FlexElements flexDirection="column" gap="16">
          <RadioInputWithDescription
            title={getTranslation('parameters.bill_on_contract')}
            description={getTranslation('parameters.bill_on_contract_descr')}
            value={billingScope.contract}
            onChange={(option) => setBillingScope(option as billingScope)}
            checked={parameters?.scope === billingScope.contract}
          />

          <RadioInputWithDescription
            title={getTranslation('parameters.bill_per_location')}
            description={getTranslation('parameters.bill_per_location_descr')}
            value={billingScope.location}
            onChange={(option) => setBillingScope(option as billingScope)}
            checked={parameters?.scope === billingScope.location}
          />
        </FlexElements>
      )}

      <Disabled disabled={parameters?.scope === billingScope.contract}>
        <div
          className={classNames(css['specific-service'], { [css['show-contract-lvl-option']]: showContractLvlOption })}
        >
          <SettingDescription
            title={getTranslation('parameters.only_specific_service')}
            description={getTranslation('parameters.only_specific_service_descr')}
            indentLvl={showContractLvlOption ? 1 : 0}
            setting={
              <ZFToggle
                onChange={(val) =>
                  setParametersValue(billingCalculationType, {
                    onlyForSpecificService: val,
                    // @ts-ignore
                    utilityType: null
                  })
                }
                checked={isOnlyForSpecificService === true}
              />
            }
          />

          <SimpleDropdown
            id="utility-type"
            className={classNames(css['service-dropdown'], { [css['service-dropdown-border']]: showContractLvlOption })}
            onChange={(val) => setParametersValue(billingCalculationType, { utilityType: val[0] })}
            values={services}
            selectedValues={[parameters?.utilityType]}
            placeholder={getTranslation('parameters.utilityType')}
            error={isOnlyForSpecificService && parameters?.scope === billingScope.location && !parameters?.utilityType}
            disabled={!isOnlyForSpecificService}
            clear
          />
        </div>
      </Disabled>

      <SettingDescription
        title={getTranslation('parameters.charge_frequency')}
        description={getTranslation('parameters.charge_frequency_descr')}
        setting={
          <FlexElements gap="24">
            {uomDropDown.map((uomval) => (
              <RadioInput
                key={uomval.value}
                onChange={(option) =>
                  setParametersValue(billingCalculationType, { unitOfMeasure: option as unitOfMeasure })
                }
                value={uomval.value}
                name={uomval.text}
                checked={parameters?.unitOfMeasure === uomval.value}
              >
                {uomval.text}
              </RadioInput>
            ))}
          </FlexElements>
        }
      />
    </>
  );
};

export default observer(SharedParameters);
