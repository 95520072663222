import React from 'react';

import { ImportJobType, TriggerImportJobType } from '@zf/api-types/import-job';
import useDialog from '@zf/hooks/src/useDialog';

import TriggerImportJobDialog from '../../../actions/import-jobs/trigger-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  onComplete: (updatedRecords: ImportJobType) => void;
  importJob: ImportJobType;
};

export default function TriggerImportJobListItem(props: Props) {
  const { importJob } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const importJobToHandle: TriggerImportJobType = {
    etag: importJob._etag,
    id: importJob.id
  };

  return (
    <DropdownAction
      id="import_jobs.trigger"
      icon="export"
      dialogTitle={i18n.getTranslation('actions.trigger_import_job')}
      dialogContent={
        <TriggerImportJobDialog
          ref={clickRef}
          //   onComplete={(val: Array<ImportJobType>) => onComplete(val[0])}
          importJobToHandle={importJobToHandle}
        />
      }
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('actions.trigger_import_job')}
    />
  );
}
