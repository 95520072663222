import { observer } from 'mobx-react';
import React, { forwardRef, useImperativeHandle } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import { DialogClickRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  index: number;
  productId: string;
};

const DeleteProductDialog = forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { index, productId } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { deleteProduct } = productConfigStore.productsStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteProduct(index, productId);

        notify.success({
          content: getTranslation('product_config.delete_product_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('product_config.delete_product_fail'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{getTranslation('product_config.delete_product_paragraph')}</Paragraph>;
});

export default observer(DeleteProductDialog);
