import clone from 'clone';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { CommunicationParameterType, DomainAuthenticationParametersType } from '@zf/api-types/communication';
import { culture, dnsRecordStatus } from '@zf/api-types/enums';
import { CultureTableType } from '@zf/api-types/language';
import { deepEqual } from '@zf/utils/src/object';

import { notify } from '../../../events/notification-events';
import CommunicationParametersForm from '../forms/CommunicationParametersForm';
import CommunicationStore from './CommunicationStore';

export enum domainAuthActionPermissions {
  mayVerify = 'mayVerify',
  maySendTestMail = 'maySendTestMail'
}

export default class SettingsStore {
  private communicationStore: CommunicationStore;

  public domainAuthParameters_: DomainAuthenticationParametersType | null | undefined;
  public cultureTableBackup: CultureTableType | undefined;
  public localCultureTable: CultureTableType | undefined;
  public selectedRowIndex = -1;
  public communicationParametersForm_: CommunicationParametersForm | undefined;

  constructor(communicationStore: CommunicationStore) {
    this.communicationStore = communicationStore;

    makeAutoObservable(this, {
      domainAuthParameters_: observable,

      initCommunicationParametersForm: action,
      initCommunicationParameters: action,
      addLanguage: action,
      setLanguage: action,
      deleteLanguage: action,
      resetLanguages: action,
      handleSubmitSettings: action,
      handleCancelSettings: action,
      setDomainAuthParameters: action,
      initLanguages: action,

      actionPermissions: computed,
      dnsRecordsAreValid: computed,
      languagesAreDirty: computed,
      communicationParametersForm: computed,
      domainAuthParameters: computed
    });
  }

  get actionPermissions() {
    return {
      mayVerify: !this.dnsRecordsAreValid,
      maySendTestMail: this.dnsRecordsAreValid
    };
  }

  get dnsRecordsAreValid() {
    if (this.domainAuthParameters_) {
      const validRecords = this.domainAuthParameters_.records.filter((r) => r.status === dnsRecordStatus.valid);
      return this.domainAuthParameters_.records.length === validRecords.length;
    }
    return false;
  }

  get languagesAreDirty() {
    if (this.localCultureTable && this.cultureTableBackup) {
      return !deepEqual(this.localCultureTable, this.cultureTableBackup);
    }
    return false;
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get communicationParametersForm() {
    return this.communicationParametersForm_ as CommunicationParametersForm;
  }

  // Only use this getter in components that render post fetch to prevent null checks, use the underscore variable otherwise!
  get domainAuthParameters() {
    return this.domainAuthParameters_ as DomainAuthenticationParametersType;
  }

  initCommunicationParametersForm = (
    newCommunicationParams: CommunicationParameterType,
    newDomainAuthParams: DomainAuthenticationParametersType | null
  ) => {
    this.communicationStore.rootStore.configStore.setCommunicationParameters(newCommunicationParams);
    this.domainAuthParameters_ = newDomainAuthParams;

    this.communicationParametersForm_ = new CommunicationParametersForm(this.communicationStore, {
      ...newCommunicationParams,

      senderEmail: {
        name: newCommunicationParams.senderEmail?.name || '',
        address: newCommunicationParams.senderEmail?.address || ''
      },
      replyTo: {
        name: newCommunicationParams.replyTo?.name || '',
        address: newCommunicationParams.replyTo?.address || ''
      },
      domainName: newDomainAuthParams?.domainName || ''
    });
  };

  setCultureTable = (cultureTable: CultureTableType | undefined) => {
    this.cultureTableBackup = clone(cultureTable);
    this.localCultureTable = clone(cultureTable);
    this.communicationStore.rootStore.configStore.setCultureTable(clone(cultureTable));
  };

  initLanguages = async () => {
    const cultureTable = await this.communicationStore.rootStore.configStore.configService.getCultureTable();
    this.setCultureTable(cultureTable);
  };

  initCommunicationParameters = async () => {
    const communicationParams =
      await this.communicationStore.rootStore.configStore.configService.getCommunicationParameters();
    const domainAuthParams = await this.communicationStore.communicationService.getDomainAuthParameters();
    this.initCommunicationParametersForm(communicationParams, domainAuthParams);
  };

  setDomainAuthParameters = (newParams: DomainAuthenticationParametersType) => {
    this.domainAuthParameters_ = newParams;
  };

  addLanguage = () => {
    if (this.localCultureTable) {
      this.localCultureTable.supportedCultures.push('' as culture);
      this.selectedRowIndex = this.localCultureTable.supportedCultures.length - 1;
    }
  };

  setLanguage = (index: number, value: boolean | culture) => {
    if (this.localCultureTable) {
      const cloned = clone(this.localCultureTable);
      let matchingCulture = cloned.supportedCultures[index];

      if (typeof value === 'boolean') {
        this.localCultureTable.defaultCulture = matchingCulture;
      } else {
        cloned.supportedCultures[index] = value;
      }

      this.localCultureTable = cloned;
    }
  };

  // Once used to change default language at the org level

  // setDefaultLanguage = (value: culture) => {
  //   if (this.localCultureTable) {
  //     this.localCultureTable.defaultCulture = value;
  //   }
  // };

  deleteLanguage = (index: number) => {
    this.localCultureTable?.supportedCultures.splice(index, 1);
    this.selectedRowIndex = -1;
  };

  resetLanguages = () => {
    this.initLanguages();
    this.localCultureTable = this.cultureTableBackup;
  };

  setSelectedRowIndex = (index: number) => {
    this.selectedRowIndex = index;
  };

  handleSubmitSettings = async () => {
    const { getTranslation } = this.communicationStore.rootStore.applicationStore;

    let containsNewCultures = false;

    try {
      if (this.languagesAreDirty && this.localCultureTable) {
        const newCultures = this.localCultureTable?.supportedCultures.filter((c) => {
          return !this.cultureTableBackup?.supportedCultures.includes(c);
        });

        containsNewCultures = newCultures && newCultures.length > 0;

        const response = await this.communicationStore.communicationService.updateCultureTable({
          defaultCultureName: this.localCultureTable.defaultCulture,
          supportedCultureNames: this.localCultureTable.supportedCultures
        });

        this.setCultureTable(response);
      }

      if (this.communicationParametersForm_ && this.communicationParametersForm_._isDirty) {
        await this.communicationParametersForm_.save();
      }

      notify.success({
        content: getTranslation('communication.update_settings_success')
      });

      if (containsNewCultures) {
        notify.info({
          content: getTranslation('communication.update_culture_table_info'),
          isSticky: true
        });
      }
    } catch (e) {
      notify.error({
        content: getTranslation('communication.update_settings_fail'),
        error: e
      });
    }
  };

  handleCancelSettings = () => {
    if (this.languagesAreDirty) {
      this.resetLanguages();
    }
    if (this.communicationParametersForm_ && this.communicationParametersForm_._isDirty) {
      this.communicationParametersForm_._reset();
    }
  };
}
