import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import { useMeters } from './hooks/useMeters';
import MeterColumn from './meter-column';

type Props = {
  propertyGroup: PropertyGroupType;
};

export default function Meters(props: Props) {
  const { propertyGroup } = props;
  const useMeters_ = useMeters(propertyGroup.id);

  if (!useMeters_) return null;

  const { individualMeters, rootMeters, aggregatingMeters, communalMeters, addMetersToState } = useMeters_;

  return (
    <ColumnsContainer extraPadding={false}>
      <MeterColumn {...props} meterType_={meterType.root} meters={rootMeters} addMetersToState={addMetersToState} />
      <MeterColumn
        {...props}
        meterType_={meterType.aggregating}
        meters={aggregatingMeters}
        addMetersToState={addMetersToState}
      />
      <MeterColumn
        {...props}
        meterType_={meterType.communal}
        meters={communalMeters}
        addMetersToState={addMetersToState}
      />
      <MeterColumn
        {...props}
        meterType_={meterType.individual}
        meters={individualMeters}
        addMetersToState={addMetersToState}
      />
    </ColumnsContainer>
  );
}
