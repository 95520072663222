import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { customerType } from '@zf/api-types/enums';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import SubTitle from '@zf/stella-react/src/atoms/Tabs/SubTitle';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import useRefresh from '../../../app-context/hooks/use-refresh';
import { NavigatorType } from '../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../components/RouteWrapper';
import GenericTabContainer from '../../../components/Tab/GenericTabContainer';
import { attachmentType } from '../../../components/units/EntityAttachments/logic/FilesStore';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import Route from '../../route';
import css from './customer-tab-container.module.scss';
import CustomerActions from './CustomerActions';
import CustomerDetail from './detail/CustomerDetail';
import CustomerAttachments from './files/CustomerAttachments';
import TransactionsTab from './transactions/Tab/TransactionsTab';
import { Icon } from 'design-system/Foundation';

type Props = {
  customer: CustomerType;
  status: JSX.Element;
  navigator: NavigatorType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType | null>>;
  navigate: (url: string) => void;
};

const CustomerTabContainer = (props: Props) => {
  const { navigate, setCustomer, customer, status, navigator } = props;
  const { refresh, timeStamp } = useRefresh();

  const { applicationStore, customerStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { filesStore } = customerStore;
  const { initFiles } = filesStore;

  const [tabItems] = useState([
    {
      id: 'detail',
      title: getTranslation('nav_detail.detail'),
      isDefault: true
    },
    {
      id: 'transactions',
      title: getTranslation('nav_detail.transactions')
    },
    {
      id: 'files',
      title: getTranslation('attachment.files')
    }
  ]);

  useBrowserTitle(`${getTranslation('customer.customer')} - ${customer.accountNumber} - ${customer.shortDisplayName}`);

  let subTitle: React.ReactNode = '';
  let subTitle2: React.ReactNode = '';

  if (customer.propertyGroups && customer.propertyGroups.length > 0) {
    subTitle2 = (
      <SubTitle>
        {getTranslation('customer.resident_of')}
        {<Icon name="property-groups" className={css['sub-icon-pg']}></Icon>}
        {customer.propertyGroups.map((pg, index) => {
          const links: React.ReactNode[] = [];

          if (index !== 0) {
            links.push(', ');
          }

          links.push(
            <Link
              id={`property-${pg?.id}`}
              key={pg?.id}
              className={css['prop_group_link']}
              url={`${rootUrl}/property-groups/${pg?.id}`}
              sub
            >
              {pg?.name}
            </Link>
          );

          return links;
        })}
      </SubTitle>
    );
  } else {
    subTitle2 = '';
  }

  if (customer.customerGroup && customer.customerGroup.id) {
    subTitle = (
      <SubTitle>
        {getTranslation('customer_groups.member_of')}
        {<Icon name="customer-group" className={css['sub-icon-cg']}></Icon>}
        <Link
          id={`customergroup-${customer.customerGroup.id}`}
          key={`${customer.customerGroup.id}`}
          className={css['prop_group_link']}
          url={`${rootUrl}/customers/customer-groups/${customer.customerGroup.id}`}
        >
          {customer.customerGroup.name}
        </Link>
      </SubTitle>
    );
  } else {
    subTitle = '';
  }

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  useEffect(() => {
    initFiles(customer.id, attachmentType.entityattachments, customer.id);
  }, [customer.id]);

  return (
    <GenericTabContainer
      actions={<CustomerActions refresh={refresh} customer={customer} />}
      navigator={navigator}
      entityId={`${customer.accountNumber} - ${customer.shortDisplayName}`}
      status={status}
      entityType={routedEntitySubjectType.customer}
      iconName={customer.customerType === customerType.person ? 'customer' : 'organisation'}
      subTitle={subTitle ? subTitle : subTitle2}
      subTitle2={subTitle2 && subTitle ? subTitle2 : ''}
      navigate={navigate}
      tabItems={tabItems}
      routes={
        <RouteWrapper default>
          <Route
            Component={CustomerDetail}
            path="detail"
            customer={customer}
            timeStamp={timeStamp}
            setActiveTab={setActiveTab}
            setCustomer={setCustomer}
            default
          />
          <Route Component={TransactionsTab} path="transactions" customer={customer} />
          <Route Component={CustomerAttachments} path="files" entityId={customer.id} />
        </RouteWrapper>
      }
    />
  );
};

export default observer(CustomerTabContainer);
