import classNames from 'classnames';
import React from 'react';

import useFocus from '@zf/hooks/src/useFocus';
import eventChain from '@zf/utils/src/eventChain';
import { inputGuidGenerator } from '@zf/utils/src/general';
import { formatIBAN, isValidIBAN } from '@zf/utils/src/iban';

import css from './input-field.module.scss';
import { StellaInputFieldProps } from './StellaInputField';

export default function StellaIBANInput(props: StellaInputFieldProps) {
  const {
    id,
    className,
    placeholder,
    value = '',
    type = 'text',
    disabled = false,
    readonly = false,
    autoFocus = false,
    style = {},
    hideLabel,
    onChange,
    onFocus,
    onBlur,
    onSelect,
    onClick,
    onKeyDown = () => {}
  } = props;

  let { error = false } = props;

  const [focus, setFocus] = React.useState(autoFocus);
  const [textValue, setTextValue] = React.useState(formatIBAN(value));
  const [cursorPosition, setCursorPosition] = React.useState(0);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useFocus(focus, inputRef);

  React.useEffect(() => {
    if (value) {
      setTextValue(formatIBAN(value));
    }

    if (!value && textValue) {
      setTextValue('');
    }
  }, [value]);

  React.useEffect(() => {
    // When our value is formatted the cursorposition is restored using our state
    if (inputRef.current) inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatIBAN(e.target.value);
    const splitted = formatted.split(' ');

    onChange(formatted);

    // Get our cursor position and keep it in our state
    const position = e.target.selectionStart;

    if (position !== null) {
      // Compensate for the extra space when typing
      if (splitted[splitted.length - 1].length === 1 && formatted.length > textValue.length) {
        setCursorPosition(position + 1);
      } else {
        setCursorPosition(position);
      }
    }
  };

  // Get the index of this inputfield-container in the array of all tabbable elements
  const keyboardfocusableElements = Array.prototype.slice.call(
    document.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')
  );

  const index = keyboardfocusableElements.findIndex((e) => {
    return e.id === `tab-div-${id}`;
  });

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  error = error && !isValidIBAN(value);

  const inputState = error ? 'error' : 'success';

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.shiftKey && e.key === 'Tab') {
      // When shift-tab is pressed focus the previous element in the tabbable elements array
      if (keyboardfocusableElements[index - 1]) {
        keyboardfocusableElements[index - 1].focus();
      }
    } else if (e.key === 'Escape') {
      handleBlur();
    }
    onKeyDown(e);
  };

  return (
    <div
      className={classNames(
        css['inputfield-wrapper'],
        {
          [css['border-bottom']]: !disabled,
          [css['disabled']]: disabled,
          [css['focused']]: focus
        },
        css[inputState],
        className
      )}
      style={style}
    >
      {placeholder && (
        <label
          htmlFor={id}
          className={classNames(css['inputfield-label'], {
            [css['inputfield-floating-label']]: focus || (value !== undefined && value !== ''),
            [css['inputfield-label-hidden']]: !!hideLabel
          })}
        >
          {placeholder}
        </label>
      )}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        id={`tab-div-${id}`}
        className={classNames(css['inputfield-container'])}
        onFocus={handleFocus}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <input
          id={id}
          key={`${id}-inputfield`}
          ref={inputRef}
          className={classNames(css['input-field'], className)}
          name={inputGuidGenerator()} // This attribute fixed the autoComplete
          autoComplete="nope"
          autoCorrect="off"
          autoCapitalize="none"
          value={value ? value : ''}
          type={type}
          disabled={disabled}
          readOnly={readonly}
          onChange={handleChange}
          onFocus={eventChain(handleFocus, onFocus)}
          onBlur={eventChain(handleBlur, onBlur)}
          onSelect={onSelect}
          tabIndex={-1}
          onKeyDown={handleKeyDown}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
