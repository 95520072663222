import ContractDropdownDetails from 'components/Dropdown/contract-dropdown/wizard/ContractDropdownDetails';
import { observer } from 'mobx-react';
import React from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';

import { useStore } from '../../hooks/useStore';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Props = {
  showDetails?: boolean;
  className?: string;
};

export type ContractAutofillProps = Props & CustomAutoFillProps<ContractType>;

const processRecord = (c: ContractType) => {
  return { value: c, text: `${c.contractNumber} - ${c.contractor.shortDisplayName}` };
};

const ContractAutoFill = (props: ContractAutofillProps) => {
  const {
    id,
    selectedValues,
    initialValue,
    query,
    queryField,
    placeholder,
    showDetails = true,
    onChange,
    onFocus
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const autoFillTools = useAutoFill({
    endpoint: '/md/contracts',
    processRecord,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, shownValues, values } = autoFillTools;

  const contract: ContractType | undefined =
    typeof focusedIndex !== 'undefined' && shownValues[focusedIndex]
      ? shownValues[focusedIndex].value.value
      : undefined;

  const details = contract && showDetails ? <ContractDropdownDetails contract={contract} /> : undefined;

  return (
    <SearchAutoFill
      {...props}
      id={id}
      details={details}
      values={values}
      selectedValues={selectedValues}
      placeholder={placeholder || getTranslation('contracts.list.search_placeholder')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processRecord}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export default observer(ContractAutoFill);
