import { CalculationTypeParametersEnrichment } from 'features/product-config/stores/BillingItemsDialogStore';
import React from 'react';

import { billingCalculationType, unitOfMeasure } from '@zf/api-types/enums';
import { SubscriptionCalculationTypeParametersType } from '@zf/api-types/product';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import { BillingItemValidatorType } from '../AddEditBillingItemDialog';
import SharedParameters from './SharedParameters';

type Props = {
  calcParams: Map<
    billingCalculationType,
    SubscriptionCalculationTypeParametersType & CalculationTypeParametersEnrichment
  >;
  uomDropDown: DropdownValuesType<unitOfMeasure>[];
  setValue: (value: Partial<BillingItemValidatorType>) => void;
  setParametersValue: (
    key: billingCalculationType,
    value: Partial<SubscriptionCalculationTypeParametersType & CalculationTypeParametersEnrichment>
  ) => void;
};

export function SubscriptionParameters(props: Props) {
  return <SharedParameters billingCalculationType={billingCalculationType.subscription} {...props} />;
}
