import React, { useEffect, useState } from 'react';

import { invoiceStatus, pluginType, sentStatus } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import SendInvoicePostalDialog from '../dialogs/resend-dialog-postal';

export default function SendInvoicePostalListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<InvoiceType>('invoice');
  const { clickRef, onSubmit } = useDialog();
  const { integrationStore } = useStore();
  const { getConfiguredPlugins } = integrationStore.configuredPluginsService;

  const [pluginEnabled, setPluginEnabled] = useState(false);

  const fetchConfiguredPlugins = async () => {
    const configuredPlugins = await getConfiguredPlugins();
    const printingConfiguredPlugin = configuredPlugins.find((e) => e.pluginType === pluginType.pingendocumentprinting);
    printingConfiguredPlugin ? setPluginEnabled(printingConfiguredPlugin.enabled) : setPluginEnabled(false);
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      fetchConfiguredPlugins();
    }
  }, []);

  const enabled =
    pluginEnabled &&
    selectedRows.length > 0 &&
    selectedRows.every(
      (invoice) =>
        invoice.__entity.supportsExternalPrinting &&
        invoice.__entity.status === invoiceStatus.generated &&
        invoice.__entity.sent !== sentStatus.printing
    );

  return (
    <DropdownAction
      id="invoice.sendinvoice"
      icon="paper-plane"
      dialogTitle={i18n.getTranslation('actions.resend_postal')}
      dialogContent={<SendInvoicePostalDialog selectedRows={selectedRows} ref={clickRef} onComplete={setUpdatedRows} />}
      onSubmit={onSubmit}
      disabled={!enabled}
      buttonPositive={i18n.getTranslation('communication.send')}
    />
  );
}
