import React from 'react';

import { TabSlider } from '../../../design-system/Components';
import { SelectionTabItemType } from '../../../design-system/Components/TabSlider/Tab';
import DataGridCardHeaderSection from '../../DataGridCard/DataGridCardHeaderSection';

type Props = {
  id: string;
  tabItems: SelectionTabItemType<Record<string, any>>[];
  selectedGraphChannels: string[] | undefined;
  setSelectedGraphChannel: (itemId: string) => void;
};

export default function ChannelSelectionSection(props: Props) {
  const { id, tabItems, selectedGraphChannels, setSelectedGraphChannel } = props;

  return (
    <DataGridCardHeaderSection>
      <TabSlider
        id={`${id}-channel-tab`}
        type="white"
        tabItems={tabItems}
        selectedTabItems={selectedGraphChannels}
        setSelectedItem={setSelectedGraphChannel}
        isMultiSelect
        showCheckBoxes
      />
    </DataGridCardHeaderSection>
  );
}
