import React from 'react';

import { PropertyGroupRowType, PropertyGroupType } from '@zf/api-types/master-data/property-group';
import useDialog from '@zf/hooks/src/useDialog';

import DeletePropertyDialog from '../../../actions/property-group/delete-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function DeletePropertyGroupListPageButton(props: Props) {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('propertygroup');
  const selectedRows: PropertyGroupRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<PropertyGroupType>('propertygroup');
  const { clickRef, onSubmit } = useDialog();

  const title = i18n.getTranslation('general.delete');

  return (
    <DropdownAction
      id="propertygroup.delete"
      icon="trashcan"
      dialogTitle={title}
      buttonPositive={title}
      dialogContent={
        <DeletePropertyDialog {...props} onComplete={setDeletedRows} ref={clickRef} selectedRows={selectedRows} />
      }
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0}
      dialogType="danger"
    />
  );
}
