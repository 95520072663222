import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { EntityAttachmentFile } from '@zf/api-types/entity-attachments/entity-attachments';
import { attachmentVisibility } from '@zf/api-types/enums';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatDate, formatPeriod, isMinDate } from '@zf/utils/src/date';

import NoAttachmentsOverlay from '../../../../cards/Attachments/NoAttachmentsOverlay';
import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { Icon, Spinner } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import QuickFilterStore from '../../List/logic/QuickFilterStore';
import FilesStore, {
  attachmentType,
  EntityAttachmentQuickFilters,
  EntityAttachmentRowBaseType
} from '../logic/FilesStore';
import { AttachmentGenericBaseType } from './Files';
import css from './files-table.module.scss';

type Props<T> = {
  filesStore: FilesStore<T>;
  quickFilterStore: QuickFilterStore<EntityAttachmentQuickFilters>;
  specificCols?: ColumnType[];
  attachmentType: attachmentType;
  processSpecificCols?: (entityAttachment: T) => Record<string, React.ReactNode>;
  listFiles: (attachment?: T) => EntityAttachmentFile[];
};

const FilesTable = <T extends AttachmentGenericBaseType>(props: Props<T>) => {
  const { filesStore, quickFilterStore, specificCols, processSpecificCols, listFiles } = props;
  const { applicationStore } = useStore();
  const { createRequestService, infiniAPIService, filterStore, entityId, loadFiles, getAttachmentForCurrentCulture } =
    filesStore;
  const { activeQuickFilter } = quickFilterStore;
  const { getTranslation } = applicationStore;
  const { selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = createRequestService;

  const columns = useMemo(() => {
    return [
      {
        width: 200,
        label: getTranslation('general.name'),
        dataKey: 'name'
      },
      {
        width: 280,
        label: getTranslation('entity_attachment.entity_attachment'),
        dataKey: 'file'
      },
      {
        width: 200,
        label: getTranslation('general.creation_date'),
        dataKey: 'createdDateTime'
      },
      {
        width: 280,
        label: getTranslation('entity_attachment.visibility'),
        dataKey: 'visibility'
      },
      ...(specificCols || [])
    ];
  }, [specificCols]);

  const processRecord = (entityAttachment: T): EntityAttachmentRowBaseType<AttachmentGenericBaseType> => {
    const attachmentForCurrentCulture = getAttachmentForCurrentCulture(entityAttachment, listFiles);

    let processedSpecificCols = {};

    if (processSpecificCols) {
      processedSpecificCols = processSpecificCols(entityAttachment);
    }

    const renderValidityEntityAttachments = (startDate?: string, endDate?: string) => {
      return (
        <Icon
          id={`${entityAttachment.id}-validity`}
          name="clock"
          title={`${getTranslation('entity_attachment.validity')}: ${formatPeriod(startDate, endDate)}`}
          color={colors[startDate && endDate ? 'graphite' : 'silver-400']}
        />
      );
    };

    const formatVisibilityTooltip = (entityAttachment: T) => {
      //@ts-ignore
      if (entityAttachment.parameters) {
        //@ts-ignore
        if (entityAttachment.parameters.validity) {
          //@ts-ignore
          if (isMinDate(entityAttachment.parameters.validity.startDateTime)) {
            return getTranslation('entity_attachment.shown_in_portal');
          } else {
            return (
              getTranslation('entity_attachment.shown_in_portal') +
              ' ' +
              getTranslation('collection_flows.from').toLowerCase() +
              ' ' +
              //@ts-ignore
              formatDate(entityAttachment.parameters.validity.startDateTime) +
              ' ' +
              getTranslation('entity_attachment.and_onwards')
            );
          }
        } else {
          return getTranslation('entity_attachment.shown_in_portal');
        }
      } else if (entityAttachment.validity) {
        if (isMinDate(entityAttachment.validity.startDateTime)) {
          return getTranslation('entity_attachment.shown_in_portal');
        } else {
          return `${getTranslation('entity_attachment.shown_in_portal')} ${getTranslation(
            'collection_flows.from'
          ).toLowerCase()} ${formatDate(entityAttachment.validity.startDateTime)} ${getTranslation(
            'entity_attachment.and_onwards'
          )}`;
        }
      } else {
        return getTranslation('entity_attachment.shown_in_portal');
      }
    };

    return {
      __id: entityAttachment.id,
      __entity: entityAttachment,
      name: attachmentForCurrentCulture?.localisedFileName,
      file: attachmentForCurrentCulture?.fileName || '',
      createdDateTime: formatDate(entityAttachment.createdDateTime),
      visibility: (
        <FlexElements gap="16">
          {filesStore.portalEnabled && (
            <>
              <Icon
                id={`${entityAttachment.id}-show-in-portal`}
                name="desktop 2"
                title={
                  entityAttachment.visibility === 'portal' || entityAttachment.visibility === 'portalandmovein'
                    ? formatVisibilityTooltip(entityAttachment)
                    : getTranslation('entity_attachment.not_shown_in_portal')
                }
                color={
                  colors[
                    entityAttachment.visibility === attachmentVisibility.portal ||
                    entityAttachment.visibility === attachmentVisibility.portalandmovein
                      ? 'graphite'
                      : 'silver-400'
                  ]
                }
              />
              <Icon
                id={`${entityAttachment.id}-approval-needed`}
                name="check"
                title={
                  entityAttachment.approvalRequired
                    ? getTranslation('entity_attachment.approval_needed')
                    : getTranslation('entity_attachment.no_approval_needed')
                }
                color={colors[entityAttachment.approvalRequired ? 'graphite' : 'silver-400']}
              />
              <Icon
                id={`${entityAttachment.id}-show-during-move-in`}
                name="Move-in2"
                title={
                  entityAttachment.visibility === attachmentVisibility.portalandmovein
                    ? getTranslation('entity_attachment.show_during_move_in')
                    : getTranslation('entity_attachment.dont_show_during_move_in')
                }
                color={
                  colors[
                    entityAttachment.visibility === attachmentVisibility.portalandmovein ? 'graphite' : 'silver-400'
                  ]
                }
              />

              {entityAttachment.validity
                ? renderValidityEntityAttachments(
                    entityAttachment.validity?.startDateTime,
                    entityAttachment.validity?.endDateTime
                  )
                : renderValidityEntityAttachments(
                    //@ts-ignore for generic non generic bullshit
                    entityAttachment.parameters?.validity?.startDateTime,
                    //@ts-ignore for generic non generic bullshit
                    entityAttachment.parameters?.validity?.endDateTime
                  )}
            </>
          )}

          <Icon
            id={`${entityAttachment.id}-show-in-welcome`}
            name="enveloppe"
            title={
              entityAttachment.includeInWelcomeEmail
                ? getTranslation('entity_attachment.show_in_welcome')
                : getTranslation('entity_attachment.not_show_in_welcome')
            }
            color={colors[entityAttachment.includeInWelcomeEmail ? 'graphite' : 'silver-400']}
          />
        </FlexElements>
      ),
      ...processedSpecificCols
    };
  };

  // Fetch table data
  useEffect(() => {
    loadFiles(processRecord);
  }, [filterStore.queryParams, activeQuickFilter, sortBy, entityId]);

  if (!infiniAPIService)
    return (
      <div className={css['table-wrapper']}>
        <Spinner centered />
      </div>
    );

  const { loading, error, sortableFields, rows, totalAmountOfRows } = infiniAPIService;

  return (
    <div className={css['table-wrapper']}>
      <DynamicVirtualTable
        id="entity-attachments-table"
        tooltipId="entity-attachments-table-tip"
        rows={toJS(rows)}
        onSelectRow={setSelectedIds}
        selectedRows={selectedIds}
        loading={loading}
        error={error}
        sortBy={sortBy}
        rowHeight={60}
        sortDirection={sortDirection}
        sortableFields={sortableFields}
        NoDataOverlay={NoAttachmentsOverlay}
        totalAmountOfRows={totalAmountOfRows}
        sort={handleSort}
        columns={columns}
      />
    </div>
  );
};

export default observer(FilesTable);
