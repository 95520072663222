import OrganisationStore from '../../components/organization/logic/OrganisationStore';
import CollectionCaseStore from '../../features/collection-case/stores/CollectionCaseStore';
import CommunicationStore from '../../features/communication-config/stores/CommunicationStore';
import CountrySpecificStore from '../../features/country-specifics/stores/CountrySpecificStore';
import CustomerStore from '../../features/customer/stores/CustomerStore';
import AgingReportStore from '../../features/dashboard/customer-aging/stores/AgingReportStore';
import MeterStore from './master-data/MeterStore';
import PrepaymentDeviceStore from '../../features/devices/prepayment-device/stores/PrepaymentDeviceStore';
import ImportJobStore from '../../features/import-jobs/stores/ImportJobStore';
import InvoiceStore from '../../features/invoice/stores/InvoiceStore';
import MeterConfigStore from '../../features/meter-config/stores/MeterConfigStore';
import IncomingBankingTransactionsStore from '../../features/payment/banking-transactions/incoming-banking-transactions/stores/IncomingBankingTransactionsStore';
import OutgoingBankingTransactionsStore from '../../features/payment/banking-transactions/outgoing-banking-transactions/stores/OutgoingBankingTransactionsStore';
import PaymentsStore from '../../features/payment/stores/PaymentsStore';
import PaymentConfigStore from '../../features/payments-config/stores/PaymentConfigStore';
import ProductConfigStore from '../../features/product-config/stores/ProductConfigStore';
import ActivityStore from './activity/ActivityStore';
import ConfigStore from './ConfigStore';
import ApplicationStore from './domain/ApplicationStore';
import ErrorStore from './ErrorStore';
import IntegrationStore from './integration/IntegrationStore';
import ContractStore from './master-data/contracts/detail-page/ContractStore';
import PropertyGroupStore from './master-data/property-groups/PropertyGroupStore';
import ServiceLocationStore from './master-data/service-locations/ServiceLocationStore';
import UiStore from './ui-stores/UiStore';

export default class RootStore {
  // General stores
  public applicationStore: ApplicationStore;
  public configStore: ConfigStore;
  public uiStore: UiStore;
  public errorStore: ErrorStore;

  // Organisation
  public organisationStore: OrganisationStore;

  // Integration
  public integrationStore: IntegrationStore;

  // Activity
  public activityStore: ActivityStore;

  // Entity stores
  public propertyGroupStore: PropertyGroupStore;
  public customerStore: CustomerStore;
  public contractStore: ContractStore;
  public invoiceStore: InvoiceStore;
  public collectionCaseStore: CollectionCaseStore;
  public meterStore: MeterStore;
  public prepaymentDeviceStore: PrepaymentDeviceStore;
  public incomingBankingTransactionsStore: IncomingBankingTransactionsStore;
  public outgoingBankingTransactionsStore: OutgoingBankingTransactionsStore;
  public paymentsStore: PaymentsStore;
  public agingReportStore: AgingReportStore;

  // Config stores
  public communicationStore: CommunicationStore;
  public productConfigStore: ProductConfigStore;
  public paymentConfigStore: PaymentConfigStore;
  public meterConfigStore: MeterConfigStore;
  public countrySpecificStore: CountrySpecificStore;
  public serviceLocationStore: ServiceLocationStore;
  public importJobStore: ImportJobStore;

  constructor() {
    this.applicationStore = new ApplicationStore(this);
    this.configStore = new ConfigStore(this);
    this.uiStore = new UiStore(this);
    this.errorStore = new ErrorStore(this);

    // Init config stores first, entity stores might depend on them
    this.communicationStore = new CommunicationStore(this);
    this.productConfigStore = new ProductConfigStore(this);
    this.paymentConfigStore = new PaymentConfigStore(this);
    this.meterConfigStore = new MeterConfigStore(this);
    this.serviceLocationStore = new ServiceLocationStore(this);
    this.countrySpecificStore = new CountrySpecificStore(this);
    this.importJobStore = new ImportJobStore(this);

    this.organisationStore = new OrganisationStore(this);

    this.integrationStore = new IntegrationStore(this);

    this.activityStore = new ActivityStore(this);

    this.propertyGroupStore = new PropertyGroupStore(this);
    this.customerStore = new CustomerStore(this);
    this.contractStore = new ContractStore(this);
    this.invoiceStore = new InvoiceStore(this);
    this.collectionCaseStore = new CollectionCaseStore(this);
    this.meterStore = new MeterStore(this);
    this.prepaymentDeviceStore = new PrepaymentDeviceStore(this);
    this.incomingBankingTransactionsStore = new IncomingBankingTransactionsStore(this);
    this.outgoingBankingTransactionsStore = new OutgoingBankingTransactionsStore(this);
    this.paymentsStore = new PaymentsStore(this);
    this.agingReportStore = new AgingReportStore(this);
  }
}
