import React from 'react';

import { UpdatePaymentParametersType } from '@zf/api-types/parameters';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../app-context';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import CollectionFlowsDropdown from '../../../components/Dropdown/collection-flows-dropdown/CollectionFlowsDropdown';
import PaymentTermsDropdown from '../../../components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';

type Props = {
  paymentParameters: UpdatePaymentParametersType;
  setValue: (value: Partial<UpdatePaymentParametersType>, initialApiSet?: boolean, backup_?: boolean) => void;
};

export default function PaymentParameters(props: Props) {
  const { paymentParameters, setValue } = props;
  const { i18n } = useAppContext();

  return (
    <Card id="payment-card" width="2">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={i18n.getTranslation('coachmark.paymentparameters.title')}
            content={[i18n.getTranslation('coachmark.paymentparameters.paragraph')]}
          />
        }
      >
        {i18n.getTranslation('parameters.payment')}
      </CardHeader>
      <CardBody type="grid" noMaxHeight>
        <CardItem width="6">
          <Label>{i18n.getTranslation('parameters.onlyUseInvoiceNumberInPaymentReferences')}</Label>
        </CardItem>
        <CardItem width="6">
          <CheckBox
            id="parameters.only_use_inv_num"
            onChange={(val) => setValue({ onlyUseInvoiceNumberInPaymentReferences: val })}
            checked={paymentParameters.onlyUseInvoiceNumberInPaymentReferences}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('payment_terms.payment_term')}</Label>
        </CardItem>
        <CardItem width="6">
          <PaymentTermsDropdown
            id="parameters.payment_term"
            onChange={(val) => setValue({ paymentTermsId: val[0] ? val[0].id : '' })}
            selectedValue={paymentParameters.paymentTermsId}
            isInline
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('parameters.default_flow')}</Label>
        </CardItem>
        <CardItem width="6">
          <CollectionFlowsDropdown
            id="parameters.collection_flow"
            onChange={(val) => setValue({ defaultCollectionFlowId: val[0] ? val[0].id : '' })}
            selectedValue={paymentParameters.defaultCollectionFlowId ? paymentParameters.defaultCollectionFlowId : ''}
            isInline
          />
        </CardItem>
      </CardBody>
    </Card>
  );
}
