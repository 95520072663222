import React from 'react';

import { UpdateTaxCodeType } from '@zf/api-types/tax-codes';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import TaxCodesContent from './tax-codes-content';

type Props = {
  config: UseConfigReturnType<UpdateTaxCodeType[]>;
};

export default function TaxCodes(props: Props) {
  const { config } = props;

  const { Provider, useTracked } = useCreateContext<UpdateTaxCodeType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <TaxCodesContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
