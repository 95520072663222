import clone from 'clone';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ApproveInvoiceType, InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { isMinDate, MIN_DATE, startOfDay } from '@zf/utils/src/date';

import css from '../../../../actions/style.module.scss';
import { useAppContext } from '../../../../app-context';
import DatePicker from '../../../../components/Lang/DatePicker';
import { NotificationError } from '../../../../design-system/Components';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { OrganizationConfigType } from '@zf/api-types/settings-config';

type Props = {
  selectedRows?: Array<InvoiceRowType>;
  invoiceToHandle?: ApproveInvoiceType;
  created?: number | string;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  onComplete?: (updatedRows: InvoiceType[]) => void;
  refreshCounts?: () => void;

  // UC completeness
  tryRefresh?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, invoiceToHandle, created, validationRef, tryRefresh, refreshCounts } = props;
  const [invoiceDate, setInvoiceDate] = React.useState(startOfDay(moment()));
  const { i18n } = useAppContext();
  const { invoiceStore, applicationStore, organisationStore } = useStore();
  const { tenantReducer } = applicationStore;
  const { getOrganisationConfig } = organisationStore.organisationService;

  let invoicesToHandle: Array<ApproveInvoiceType> = [];

  const [organizationFeatureFlags, setOrganizationFeatureFlags] = useState<OrganizationConfigType>();

  useEffect(() => {
    if (tenantReducer.organization?.organizationId && tenantReducer.tenant?.id) {
      getOrganisationConfig(tenantReducer.organization?.organizationId, tenantReducer.tenant?.id)
        .then((res) => {
          setOrganizationFeatureFlags(res);
        })
        .catch((error) => {
          notify.error({
            content: i18n.getTranslation('general.get_cfg_fail'),
            error
          });
        });
    }
  }, []);

  //reverted to old code, unstable to be on master

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(JSON.stringify(invoiceDate) === JSON.stringify(moment(MIN_DATE)));
    }
  };

  useEffect(() => {
    validate();
  }, [invoiceDate]);

  if (selectedRows) {
    invoicesToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (invoiceToHandle) {
    invoicesToHandle = [invoiceToHandle];
  }

  const refreshCounts_ = () => {
    if (refreshCounts) refreshCounts();
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (!invoiceDate) {
        notify.warning({
          content: i18n.getTranslation('invoice.approve_date_mandatory')
        });
        return;
      }

      try {
        if (invoicesToHandle.length === 0) {
          await invoiceStore.approveInvoice(
            {
              id: '',
              etag: ''
            },
            clone(invoiceDate).toISOString()
          );
          refreshCounts_();

          if (organizationFeatureFlags?.features.easyPaymentLink) {
            notify.info({
              content: i18n.getTranslation('actions.invoice.approve_with_pay_link_multi')
            });
          } else {
            notify.success({
              content: `${i18n.getTranslation('actions.invoice.approve_all_success')}\n ${i18n.getTranslation(
                'actions.invoice.approve_all_success_desc'
              )}`
            });
          }
        } else {
          const newInvoices = await Promise.all(
            invoicesToHandle.map((invoice) =>
              invoiceStore.approveInvoice(invoice, invoiceDate.toISOString(), !selectedRows)
            )
          );

          if (onComplete) onComplete(newInvoices);
          refreshCounts_();

          if (organizationFeatureFlags?.features.easyPaymentLink) {
            notify.info({
              content: i18n.getTranslation('actions.invoice.approve_with_pay_link')
            });
          } else {
            notify.success({
              content: i18n.getTranslation('actions.invoice.approve_success')
            });
          }
        }
      } catch (e) {
        let message = '';
        //@ts-ignore
        if (e.message) message = e.message.split('/')[0];

        if (
          message === 'MISSING_ACTIVE_MANDATE' ||
          //@ts-ignore
          (e.data && e.data.errors && e.data.errors[0] && e.data.errors[0].key === 'MISSING_ACTIVE_MANDATE')
        ) {
          notify.warning({
            //@ts-ignore
            content: e.data && e.data.errors ? <NotificationError errors={e.data.errors} /> : e.message
          });

          if (onComplete && selectedRows) {
            onComplete(
              selectedRows.map((s) => {
                return s.__entity;
              })
            );
          }

          refreshCounts_();
        } else {
          // UC completeness
          if (tryRefresh) {
            tryRefresh();
          } else {
            notify.error({
              content: i18n.getTranslation('actions.invoice.approve_failed'),
              error: e
            });
          }
        }
      }
    }
  }));

  return (
    <>
      <Paragraph>
        {invoicesToHandle.length === 0
          ? i18n.getTranslation('actions.invoice.approve_all', { created: created ? created : '' })
          : i18n.getTranslation('actions.invoice.approve', { amount: invoicesToHandle.length.toString() })}
      </Paragraph>
      <div className={css['input-wrapper']}>
        <DatePicker
          id="approve-date-time"
          onChange={(val) => setInvoiceDate(val)}
          value={invoiceDate}
          placeholder={i18n.getTranslation('actions.invoice.approve_date')}
          error={isMinDate(invoiceDate)}
        />
      </div>
    </>
  );
});
