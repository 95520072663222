import { observer } from 'mobx-react';
import React from 'react';

import { ContractServiceType } from '@zf/api-types/forecasting';
import { ContractServiceLocation } from '@zf/api-types/master-data/contract';

import ActiveService from './ActiveService';
import css from './single-location-card-body.module.scss';

type Props = {
  serviceLocation: ContractServiceLocation;
  contractServices: ContractServiceType[];
};

const SingleLocationCardBody = (props: Props) => {
  const { serviceLocation, contractServices } = props;

  return (
    <div className={css['single-location-card-body']}>
      <ActiveService serviceLocation={serviceLocation} contractServicesForLocation={contractServices} />
    </div>
  );
};

export default observer(SingleLocationCardBody);
