import { observer } from 'mobx-react';
import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';

import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import { meterType } from '@zf/api-types/enums';

type Props = {
  device: MeterType;
};

const DeviceLink = (props: Props) => {
  const { device } = props;

  const { applicationStore } = useStore();
  const { rootUrl } = applicationStore;

  return (
    <Link
      id={`device-${device.id}`}
      url={`${rootUrl}/devices/${device.meterType === meterType.prepayment ? 'prepayment-devices' : 'meters'}/${
        device.id
      }`}
    >
      {device.serialNumber}
    </Link>
  );
};

export default observer(DeviceLink);
