import { observer } from 'mobx-react';
import React from 'react';

import { BillingTariffInputType, UnitPriceTariffCalculationTypeParametersType } from '@zf/api-types/product';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import FormulaBuilder from '../../../../../../components/formula-builder/formula-builder';
import MoneyInput from '../../../../../../components/input/MoneyInput';
import { Description } from '../../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../../hooks/useStore';
import css from './calculation-parameters.module.scss';

type Props = {
  calcParams: UnitPriceTariffCalculationTypeParametersType;
  setParametersValue: (key: any, value: any) => void;
  toggleIsFormula: (formulaBased: boolean) => void;
  setFormula: (expression: string, inputParameters: BillingTariffInputType[]) => void;
};

const UnitPriceCalculationParameters = (props: Props) => {
  const { calcParams, setParametersValue, toggleIsFormula, setFormula } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      <div className={css['unit-price-wrapper']}>
        {!calcParams.formulaBased && (
          <>
            <MoneyInput
              id="unit-tariff"
              value={calcParams.unitTariff}
              onChange={(val) => setParametersValue('unitTariff', val)}
              placeholder={getTranslation('billing_tariff.unit_price')}
              error={typeof calcParams.unitTariff === 'undefined'}
            />
          </>
        )}
        <CheckBox id="is-formula" checked={calcParams.formulaBased} onChange={toggleIsFormula}>
          <Description
            title={getTranslation('billing_tariff.is_formula')}
            description={getTranslation('billing_tariff.is_formula_description')}
          />
        </CheckBox>
      </div>
      {calcParams.formulaBased && (
        <>
          <Heading headingType="h3" style="bold">
            {getTranslation('billing_tariff.formula_builder_heading')}
          </Heading>
          <FormulaBuilder
            expression={calcParams.expression}
            inputParameters={calcParams.inputParameters}
            // @ts-ignore
            setFormula={setFormula}
          />
        </>
      )}
    </>
  );
};

export default observer(UnitPriceCalculationParameters);
