import { observer } from 'mobx-react';
import React from 'react';

import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import ExportServiceConsumptionsListItem from '../../../components/list-items/locations/ExportServiceConsumptionsListItem';
import InstallMeterListItem from '../../../components/list-items/locations/InstallMeterListItem';
import RecalculateEavListItem from '../../../components/list-items/locations/RecalculateEavListItem';
import AddMeasurementListItem from '../../../components/list-items/meters/AddMeasurementListItem';
import { useStore } from '../../../hooks/useStore';
import { getCurrentContract } from '../../../utils/location';

type Props = {
  serviceLocation: ServiceLocationType;
};

const LocationActions = (props: Props) => {
  const { serviceLocation } = props;
  const { serviceLocationStore } = useStore();
  const { forecastingService } = serviceLocationStore;

  return (
    <>
      <InstallMeterListItem location={serviceLocation} type="forLocation" />
      <AddMeasurementListItem locationId={serviceLocation.id} />
      <RecalculateEavListItem
        forecastingService={forecastingService}
        enabled={serviceLocation.services.some((s) => !!getCurrentContract(s))}
        entityId={serviceLocation.id}
        entity="location"
      />
      <ExportServiceConsumptionsListItem serviceLocationId={serviceLocation.id} />
    </>
  );
};

export default observer(LocationActions);
