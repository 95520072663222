import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import useCustomEntityPropertyTypes from '../../../../app-context/hooks/useCustomEntityPropertyTypes';
import ListPage from '../../../../components/listPage';
import { useStore } from '../../../../hooks/useStore';
import ContractsFilterBar from './contracts-filter-bar';
import ContractsList from './contracts-list';

type Props = {
  location: Location;
};

export default function Contracts(props: Props) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('contracts.contracts'));

  const { customEntityPropertyTypes, extraColumns, extraColumnTitles } = useCustomEntityPropertyTypes(
    entitySubjectType.contract
  );

  if (!customEntityPropertyTypes) return null;

  return (
    <ListPage
      entitySubjectType={entitySubjectType.contract}
      subjectType={getTranslation('contracts.contracts')}
      topBar={<ContractsFilterBar search={props.location.search} extraColumnTitles={extraColumnTitles} />}
      list={<ContractsList customEntityPropertyTypes={customEntityPropertyTypes} extraColumns={extraColumns} />}
    />
  );
}
