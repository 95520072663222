import { observer } from 'mobx-react';
import React from 'react';

import { navigate } from '@reach/router';
import { List } from '@zf/stella-react/src/atoms/List';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { DropdownAction } from '../../design-system/ComponentSets';
import { useStore } from '../../hooks/useStore';
import css from './action-item.module.scss';
import NavList from './nav-list';
import NavListItem from './nav-list-item';

const ActionItemList = () => {
  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const clickItem = (url: string) => navigate(`${rootUrl}${url}`);

  return (
    <TooltipTrigger
      tooltip={
        <List>
          <div className={css['action-group']}>
            <div className={css['actions']}>
              <DropdownAction
                id="customer.add"
                key="create-customer"
                icon="customer-add"
                actionType="direct"
                onClick={() => clickItem('/customers/add')}
              >
                {getTranslation('customer.customer')}
              </DropdownAction>

              <DropdownAction
                id="location.add"
                key="create-location"
                icon="servicelocation-add"
                actionType="direct"
                onClick={() => clickItem('/locations/add')}
              >
                {getTranslation('actions.create_location')}
              </DropdownAction>

              <DropdownAction
                id="propertygroup.add"
                key="create-property-group"
                icon="propertygroup-add"
                actionType="direct"
                onClick={() => clickItem('/property-groups/add')}
              >
                {getTranslation('property_groups.property_group')}
              </DropdownAction>
            </div>
            <div className={css['actions']}>
              <DropdownAction
                id="meter.add"
                key="create-meter"
                icon="meter-add"
                actionType="direct"
                onClick={() => clickItem('/devices/add')}
              >
                {getTranslation('actions.create_meter')}
              </DropdownAction>

              <DropdownAction
                id="contract.add"
                key="create-contract"
                icon="contract-add"
                actionType="direct"
                onClick={() => clickItem('/contracts/add')}
              >
                {getTranslation('actions.create_contract')}
              </DropdownAction>

              <DropdownAction
                id="invoice.add"
                key="create-invoice"
                icon="invoice-add"
                actionType="direct"
                onClick={() => clickItem('/invoices/add')}
              >
                {getTranslation('actions.create_invoice')}
              </DropdownAction>
            </div>
          </div>
        </List>
      }
      placement="right-end"
      trigger="click"
    >
      <NavList>
        <NavListItem id="action-item" key="action-item" href="" className={css['action-btn']} iconType="add" />
      </NavList>
    </TooltipTrigger>
  );
};

export default observer(ActionItemList);
