import { AddCustomerBankAccountRequestType, CustomerMandateType } from '@zf/api-types/billing/customer-bank-account';
import { mandateType } from '@zf/api-types/enums';

import RootStore from '../../../app-context/stores';
import BaseFormVjf, { Fields } from '../../../app-context/stores/forms/BaseFormVjf';
import { notify } from '../../../events/notification-events';

export enum mandateFormFields {
  iban = 'iban',
  isDefault = 'isDefault',
  mandate = 'mandate'
}

export type MandateFieldTypes = {
  iban: string;
  isDefault: boolean;
  mandate: CustomerMandateType;
};

class MandateForm extends BaseFormVjf<MandateFieldTypes> {
  public index: number;
  public rootStore: RootStore;

  constructor(rootStore: RootStore, initialValues: MandateFieldTypes, index: number) {
    super(initialValues);
    this.rootStore = rootStore;
    this.index = index;
  }

  setup(): Fields {
    return {
      fields: [
        {
          label: 'Iban',
          name: mandateFormFields.iban,
          validators: [this.isRequired, this.isIbanValid],
          type: 'text'
        },
        {
          label: 'mandate',
          name: mandateFormFields.mandate,
          type: 'text',
          fields: [
            {
              label: 'number',
              name: 'number',
              type: 'text'
            },
            {
              label: 'Signed date',
              name: 'signedDateTime',
              type: 'text'
            },
            {
              label: 'cancelled date',
              name: 'cancelledDateTime',
              type: 'text'
            },
            {
              label: 'Type',
              name: 'type',
              type: 'text'
            },
            {
              label: 'Status',
              name: 'status',
              type: 'text'
            }
          ]
        }
      ]
    };
  }

  async onSuccess() {
    let accountToSubmit = this.rootStore.customerStore.getBankAccount(this.index);
    const apiFriendlyValues: AddCustomerBankAccountRequestType = {
      iban: this._get('iban'),
      bic: accountToSubmit.bic,
      isDefault: accountToSubmit.isDefault,
      activeMandate: accountToSubmit.mandateForm?.fieldIsEmpty('mandate')
        ? null
        : {
            type: mandateType.core,
            signedDateTime: this._getNested('mandate', 'signedDateTime')
          }
    };

    try {
      if (!accountToSubmit.id) {
        this.rootStore.customerStore.bankAccounts.results[this.index] =
          await this.rootStore.customerStore.customerService.addBankAccountRequest(
            apiFriendlyValues,
            this.rootStore.customerStore.selectedCustomer.customer.id
          );
      } else {
        this.rootStore.customerStore.bankAccounts.results[this.index] =
          await this.rootStore.customerStore.customerService.updateBankAccountRequest(
            apiFriendlyValues,
            this.rootStore.customerStore.bankAccounts.results[this.index].id
          );
      }

      if (this.rootStore.customerStore.bankAccounts.results[this.index]) {
        notify.success({
          content: this.rootStore.applicationStore.getTranslation('customer.update_bank_account_success')
        });

        //backup
        this._set(
          'mandate',
          this.rootStore.customerStore.bankAccounts.results[this.index].activeMandate as CustomerMandateType
        );
        this.rootStore.customerStore.initBankAccount(
          this.index,
          new MandateForm(this.rootStore, this._values, this.index)
        );
      }
    } catch (e) {
      notify.error({
        content: this.rootStore.applicationStore.getTranslation('customer.update_bank_account_fail'),
        error: e
      });
    }
  }

  hooks() {
    return {
      onSuccess: this.onSuccess
    };
  }
}

export default MandateForm;
