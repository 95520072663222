import clone from 'clone';
import React from 'react';

import { DataProviderTypesType, ParametersType, UpdateImportDataProviderType } from '@zf/api-types/data-provider';
import { FileFormatType } from '@zf/api-types/file-format';
import useContextValidator from '@zf/hooks/src/useContextValidator';
import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';
import { Card, CardBody, CardHeader, CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { useAppContext } from '../../../app-context';
import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import useContextCRUD from '../../../app-context/hooks/useContextCRUD';
import Button from '../../../components/Button/Button';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import CommitSection from '../../../components/config/commit-section';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import Dropdown from '../../../components/Lang/Dropdown';
import DynamicIndexTable from '../../../components/Lang/DynamicIndexTable';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import ParameterGenerator from '../../../components/parameter-generator/parameter-generator';
import { notify } from '../../../events/notification-events';

type Props = {
  dataTypes: DataProviderTypesType[];
  fileformats: FileFormatType[];
  config: UseConfigReturnType<UpdateImportDataProviderType[]>;
  useTracked: () => [
    ValidatorCtxState<UpdateImportDataProviderType[]>,
    React.Dispatch<ValidatorCtxAction<UpdateImportDataProviderType[]>>
  ];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineInputFieldDropdown = InlineInputField<SimpleDropdownProps<string>>(SimpleDropdown);
const InlineFileFormatDropdown = InlineInputField<DropdownProps<FileFormatType>>(Dropdown);

const createApiFriendlyValues = (dataProvider: UpdateImportDataProviderType) => {
  // Defaults when undefined
  const disabled = dataProvider.disabled ? dataProvider.disabled : false;
  const autoProcessing = dataProvider.automaticProcessing ? dataProvider.automaticProcessing : false;

  return {
    name: dataProvider.name,
    parameters: { ...dataProvider.parameters },
    fileFormatId: dataProvider.fileFormatId,
    automaticProcessing: autoProcessing,
    disabled: disabled
  };
};

export default function DataProvidersContent(props: Props) {
  const { dataTypes, fileformats, config } = props;
  const { sort } = config;
  const { i18n, enumReducer } = useAppContext();

  const [communicationTypes] = React.useState(
    dataTypes.map((dt) => {
      return {
        value: dt.classType.toLowerCase(),
        text: i18n.getTranslation(`dataProviderCommunicationType.${dt.classType.toLowerCase()}`)
      };
    })
  );

  const [fileformatsDropdown] = React.useState(
    fileformats.map((format) => {
      return { value: format, text: format.description ? `${format.name} - ${format.description}` : format.name };
    })
  );

  const [tableColumns] = React.useState<ColumnType[]>([
    {
      flexWidth: 2,
      label: i18n.getTranslation('general.name'),
      dataKey: 'name'
    },
    {
      flexWidth: 3,
      label: i18n.getTranslation('data_provider.file_format'),
      dataKey: 'fileFormat'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('data_provider.communication_type'),
      dataKey: 'communicationType'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('data_provider.disabled'),
      dataKey: 'providerDisabled'
    },
    {
      flexWidth: 1,
      label: i18n.getTranslation('data_provider.automaticProcessing'),
      dataKey: 'automaticProcessing'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  const { values, isDirty, selectedIndex, scrollToIndex, setValue, restoreValues, setSelectedIndex } =
    useContextValidator<UpdateImportDataProviderType[]>(props.useTracked);

  const { addEntity, deleteEntity, handleSave } = useContextCRUD(props.useTracked);

  const addDataProvider = () => {
    addEntity({ automaticProcessing: false, disabled: false, fileFormatId: '', parameters: { type: '' } });
  };

  const checkMandatoryFields = () => {
    const hasWarning = values.some((v) => {
      return !v.name || !v.fileFormatId || (v.parameters && !v.parameters.type);
    });

    if (hasWarning) {
      notify.warning({
        content: i18n.getTranslation('errors.config.mandatory_fields_warning')
      });
    }

    return hasWarning;
  };

  const handleProvidersSave = async () => {
    const hasWarning = checkMandatoryFields();

    if (!hasWarning) {
      return handleSave(
        createApiFriendlyValues,
        'name',
        '/cfg/DataImports',
        i18n.getTranslation('data_provider.success_update'),
        i18n.getTranslation('data_provider.error_update')
      );
    }
  };

  const setDataProviders = (
    index: number,
    value: UpdateImportDataProviderType[keyof UpdateImportDataProviderType],
    dataKey: keyof UpdateImportDataProviderType
  ) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[index][dataKey] = value || '';

    setValue({
      values: clonedArray as UpdateImportDataProviderType[]
    });
  };

  const setParameters = (value: ParametersType[keyof ParametersType], dataKey: keyof ParametersType) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[selectedIndex].parameters[dataKey] = value;
    setValue({
      values: clonedArray
    });
  };

  const dataProviderRows = values.map((dataProvider, index) => {
    return {
      name: dataProvider.id ? (
        <Paragraph>{dataProvider.name}</Paragraph>
      ) : (
        <InlineInputFieldInput
          id={`data_import.name.index-${index}`}
          value={dataProvider.name}
          onChange={(val) => setDataProviders(index, val, 'name')}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!dataProvider.name}
          error={!dataProvider.name}
        />
      ),
      automaticProcessing: (
        <CheckBox
          id={`data_import.auto_processing.index-${index}`}
          checked={dataProvider.automaticProcessing}
          onChange={(val) => setDataProviders(index, val, 'automaticProcessing')}
        />
      ),
      providerDisabled: (
        <CheckBox
          id={`data_import.disabled.index-${index}`}
          checked={dataProvider.disabled}
          onChange={(val) => setDataProviders(index, val, 'disabled')}
        />
      ),
      fileFormat: (
        <InlineFileFormatDropdown
          id={`data_import.fileformat.index-${index}`}
          values={fileformatsDropdown}
          selectedValues={[dataProvider.fileFormatId ? dataProvider.fileFormatId : '']}
          onChange={(val) => setDataProviders(index, val[0] ? val[0].id : '', 'fileFormatId')}
          clearText={i18n.getTranslation('dropdown.clear')}
          emptyText={i18n.getTranslation('dropdown.empty')}
          error={!dataProvider.fileFormatId}
        />
      ),
      communicationType:
        dataProvider.id && dataProvider.parameters ? (
          <Paragraph>
            {enumReducer.getTranslation('dataImportCommunicationType', dataProvider.parameters.type)}
          </Paragraph>
        ) : (
          <InlineInputFieldDropdown
            id={`data_import.communication_type.index-${index}`}
            values={communicationTypes}
            selectedValues={[dataProvider.parameters ? dataProvider.parameters.type : '']}
            onChange={(val) => setParameters(val[0], 'type')}
            clearText={i18n.getTranslation('dropdown.clear')}
            emptyText={i18n.getTranslation('dropdown.empty')}
            error={dataProvider.parameters && !dataProvider.parameters.type}
          />
        ),
      deleteAction: (
        <DeleteIcon
          id={`data_import.delete.index-${index}`}
          tooltipFor="data-providers-table"
          onClick={() => deleteEntity(index)}
        />
      )
    };
  });

  const dataType = dataTypes.find((dType) => {
    const selectedProvider = values[selectedIndex];

    return (
      selectedProvider &&
      selectedProvider.parameters &&
      dType.classType.toLowerCase() === selectedProvider.parameters.type
    );
  });

  const parameters = (
    <ParameterGenerator
      key="generator"
      tooltipFor="data-providers-table"
      selection={selectedIndex}
      parameters={values[selectedIndex] ? values[selectedIndex].parameters : undefined}
      genericType={dataType}
      setParameters={setParameters}
    />
  );

  return (
    <>
      <CommitSection handleCancel={restoreValues} handleSave={handleProvidersSave} isDirty={isDirty} />
      <CardsContainer>
        <Card id="dataproviders-card" width="3">
          <CardHeader
            extraLeft={
              <ConfigHelp
                title={i18n.getTranslation('coachmark.data_provider.title')}
                content={[i18n.getTranslation('coachmark.data_provider.paragraph')]}
              />
            }
            extraRight={
              <Button id="data_import.add" type="text" icon="plus" onClick={addDataProvider}>
                {i18n.getTranslation('general.add')}
              </Button>
            }
          >
            {i18n.getTranslation('data_provider.data_providers')}
          </CardHeader>
          <CardBody type="indexTable" fixedHeight>
            <DynamicIndexTable
              tooltipId="data-providers-table"
              rows={dataProviderRows}
              columns={tableColumns}
              selectedRow={selectedIndex}
              scrollToIndex={scrollToIndex}
              isDirty={isDirty}
              isLoading={config.isFetching}
              setSelectedRow={setSelectedIndex}
              sort={sort}
            />
          </CardBody>
        </Card>
        {parameters}
      </CardsContainer>
    </>
  );
}
