import useBrowserTitle from 'app-context/hooks/use-browser-title';
import { moveOutType } from 'app-context/stores/master-data/contracts/wizard/move-out/MoveOutWizardBaseStore';
import WizardContainer from 'components/Wizard/WizardContainer';
import { WizardNav } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { navigate } from '@reach/router';
import { Wizard } from '@zf/stella-react/src/atoms/Wizard';

import RemoveLocationsWizard from './remove-locations-contract-wizard/RemoveLocationsWizard';
import RemoveLocationsWizardNavigation from './remove-locations-contract-wizard/RemoveLocationsWizardNavigation';
import MoveOutSection from './shared/move-out-section/MoveOutSection';
import MoveOutWizard from './terminate-contract-wizard/MoveOutWizard';
import MoveOutWizardNavigation from './terminate-contract-wizard/MoveOutWizardNavigation';

const MoveOutWizardBase = () => {
  const { applicationStore, contractStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { contract, moveOutType: moveOutType_, resetStore } = contractStore.moveOutWizardBaseStore;

  const moveOutText = getTranslation('actions.move_out');

  useBrowserTitle(moveOutText);

  useEffect(() => {
    // Prevent navigation to move out wizard without a contract (go back in browser)
    if (!contract) {
      navigate(`${rootUrl}/contracts`);
    }

    return () => {
      // Reset MobX wizard store
      resetStore();
    };
  }, []);

  const isTerminate = moveOutType_ === moveOutType.terminatecontract;

  return (
    <WizardContainer icon="contract" text={moveOutText} isDesignSystem>
      <Wizard>
        <MoveOutSection />

        {isTerminate ? <MoveOutWizard /> : <RemoveLocationsWizard />}
      </Wizard>

      <WizardNav>{isTerminate ? <MoveOutWizardNavigation /> : <RemoveLocationsWizardNavigation />}</WizardNav>
    </WizardContainer>
  );
};

export default observer(MoveOutWizardBase);
