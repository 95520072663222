import moment from 'moment';

import { DeletePropertyGroupType, PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { startOfDay } from '@zf/utils/src/date';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';
import { uiCulture } from '@zf/api-types/enums';

export async function updateServiceLocations(
  newLocationIds: string[],
  propertyGroupId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<PropertyGroupType> {
  return (
    await sendRequest<PropertyGroupType>({
      request: {
        method: METHODS.POST,
        endpoint: `/md/PropertyGroups/${propertyGroupId}/servicelocations`,
        data: {
          serviceLocations: newLocationIds
        }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteProperty(
  value: DeletePropertyGroupType | PropertyGroupType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<PropertyGroupType> {
  return (
    await sendRequest<PropertyGroupType>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/md/propertyGroups/${value.id}`,
        data: { mutationDateTime: startOfDay(moment()) }
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function getPropertyGroupById(
  id: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<PropertyGroupType> {
  return (
    await sendRequest<PropertyGroupType>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/md/propertyGroups/${id}`
      },
      tenantReducer,
      lang
    })
  ).data;
}
