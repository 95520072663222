import Interweave from 'interweave';
import React, { forwardRef, Ref, useImperativeHandle } from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';

import { useAppContext } from '../../app-context';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../design-system/Foundation';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;

  // Detail page
  task?: MoveRequestType;
  setTask?: (updatedTask: MoveRequestType) => void;

  // List page
  selectedRows?: TaskRowType[];
  setUpdatedRows?: (updatedRows: MoveRequestType[]) => void;
  refreshCounts?: () => void;
};

const ResolveWaitingDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { task, selectedRows, setTask, setUpdatedRows, refreshCounts } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();

  const setWaiting = async (taskId: string) => {
    return (
      await sendRequest<MoveRequestType>({
        request: {
          method: METHODS.POST,
          endpoint: `/md/MoveRequests/${taskId}/setnew`
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      const newStatus = enumReducer.getTranslation('moveRequestStatus', moveRequestStatus.new);

      try {
        if (task && setTask) {
          const updatedTask = await setWaiting(task.id);
          setTask(updatedTask);
        } else if (selectedRows && setUpdatedRows && refreshCounts) {
          const updatedTasks = await Promise.all(selectedRows.map((r) => setWaiting(r.__entity.id)));
          setUpdatedRows(updatedTasks);
          refreshCounts();
        }

        notify.success({
          content: i18n.getTranslation('tasks.change_status_success', {
            newStatus
          })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tasks.change_status_fail', {
            newStatus
          }),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave content={i18n.getTranslation('tasks.resolve_waiting_paragraph')} />
    </Paragraph>
  );
});

export default ResolveWaitingDialog;
