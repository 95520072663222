import { TooltipTrigger } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import React from 'react';

import Heading from '@zf/stella-react/src/atoms/Heading/Heading';
import Paragraph from '@zf/stella-react/src/atoms/Paragraph/Paragraph';

import css from '../accuracy-indicator.module.scss';

export const DialogMain: React.FC<{ accuracyToolTipTitle: string; onClose?: Function }> = ({
  accuracyToolTipTitle,
  children,
  onClose
}) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <TooltipTrigger
      tooltip={
        <div className={css['tooltip-dialog-main']}>
          <Heading headingType="h3">{accuracyToolTipTitle}</Heading>
          <Paragraph>
            <Interweave content={getTranslation('contracts.estimation_accuracy_how_to_improve')} />
          </Paragraph>
        </div>
      }
      placement="left"
      offset={[0, 0]}
    >
      {children}
    </TooltipTrigger>
  );
};
