import React from 'react';
import { isEmptyObject } from '@zf/utils/src/object';

import { BreadCrumb, Crumb, CrumbDivider } from '../../../../components/BreadCrumb';
import { CrumbHeader } from '../../../../components/Header';
import { useStore } from '../../../../hooks/useStore';
import CustomerGroupDetailTabContainer from './CustomerGroupDetailTabContainer';
import useBrowserTitle from 'app-context/hooks/use-browser-title';
import useCustomerGroupDetail from '../hooks/useCustomerGroupDetail';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
  location: Location;
};

export default function CustomerGroupDetail(props: Props) {
  const { navigate, urlParams, location } = props;
  const { customerGroupDetail, navigator, setCustomerGroupDetail } = useCustomerGroupDetail(urlParams.customerGroupId);

  const { applicationStore } = useStore();

  const { rootUrl, getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('customer_groups.customer_groups'));

  if (!customerGroupDetail || isEmptyObject(customerGroupDetail)) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              icon="list"
              text={getTranslation('customer_groups.customer_groups')}
              url={`${rootUrl}/customers/customer-groups`}
            />
            <CrumbDivider />
            <Crumb icon={'customer-group'} text={`${customerGroupDetail.name}`} />
          </BreadCrumb>
        }
      />
      {customerGroupDetail && (
        <CustomerGroupDetailTabContainer
          customerGroupDetail={customerGroupDetail}
          setCustomerGroupDetail={setCustomerGroupDetail}
          navigator={navigator}
          urlParams={urlParams}
          navigate={navigate}
          location={location}
        />
      )}
    </>
  );
}
