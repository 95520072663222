import React from 'react';

import { BaseButton } from '../../design-system/Components';
import { Icon } from '../Icon';
import css from './summary.module.scss';

type Props = {
  children: React.ReactNode;
  onRemove?: () => void;
  style?: React.CSSProperties;
};

export default function Summary(props: Props) {
  const { children, onRemove, ...otherProps } = props;

  return (
    <div {...otherProps} className={css['summary']}>
      <div className={css['content']}>{children}</div>
      {onRemove && (
        <BaseButton id="remove" className={css['remove']} onClick={onRemove}>
          <Icon type="cancel" />
        </BaseButton>
      )}
    </div>
  );
}
