import { addLocationsMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/AddLocationsMoveInWizardStore';
import { moveInType } from 'app-context/stores/master-data/contracts/wizard/move-in/MoveInWizardBaseStore';
import { regularMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { TabSlider, WizardSectionHeader } from 'design-system/Components';
import { SelectionTabItemType } from 'design-system/Components/TabSlider/Tab';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import ExistingContract from './ExistingContract';
import css from './move-in-section.module.scss';
import NewContract from './NewContract';

/**
 * This part is shared in the regular move-in flow and the add locations to existing contract flow
 */
const MoveInSection = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    moveInType: moveInType_,
    regularMoveInWizardStore,
    addLocationsMoveInWizardStore,
    setMoveInType
  } = contractStore.moveInWizardBaseStore;
  const { regularMoveInWizardNavigator } = regularMoveInWizardStore;
  const { addLocationsMoveInWizardNavigator } = addLocationsMoveInWizardStore;

  const tabItems = useRef<SelectionTabItemType<any>[]>([
    { id: moveInType.newcontract, icon: 'plus', title: getTranslation('contracts.wizard.new_contract') },
    {
      id: moveInType.existingcontract,
      icon: 'contract',
      title: getTranslation('contracts.wizard.existing_contract')
    }
  ]);

  const isNewContract = moveInType_ === moveInType.newcontract;

  const handleSetActiveStep = () => {
    if (isNewContract) {
      regularMoveInWizardNavigator.setActiveStep(regularMoveInWizardSteps.movein);
    } else {
      addLocationsMoveInWizardNavigator.setActiveStep(addLocationsMoveInWizardSteps.movein);
    }
  };

  return (
    <WizardSection id="move-in-section" setActiveStep={handleSetActiveStep}>
      <WizardSectionHeader id={regularMoveInWizardSteps.movein} text={getTranslation('contracts.steps.move_in')} />

      <TabSlider
        id="move-in-type"
        className={css['move-in-type']}
        tabItems={tabItems.current}
        selectedTabItem={moveInType_}
        setSelectedItem={(moveInType_) => setMoveInType(moveInType_ as moveInType)}
        textAlign="left"
        fullWidth
      />

      {isNewContract ? <NewContract /> : <ExistingContract />}
    </WizardSection>
  );
};

export default observer(MoveInSection);
