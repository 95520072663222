import React from 'react';

import css from './nav-list.module.scss';

export type PropsType = {
  children: React.ReactNode;
};

export default function NavList(props: PropsType) {
  const { children } = props;

  return (
    <ul className={css['nav-list']} role="navigation" aria-label="Side Navigation">
      {children}
    </ul>
  );
}
