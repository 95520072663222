import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { IncomingBankingTransactionInsightsResponse } from '@zf/api-types/billing/incoming-banking-transaction';
import { incomingMutationPaymentMethod, incomingMutationStatus, incomingMutationType } from '@zf/api-types/enums';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { DetailLine, InfoGrid } from '../../design-system/ComponentSets';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph, Spinner } from '../../design-system/Foundation';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';
import { formatTransactionAmount } from '../../utils/transaction';
import css from './show-details-dialog.module.scss';

type Props = {
  incomingBankingTransactionId: string;
};

const ShowDetailsDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { incomingBankingTransactionId } = props;
  const { applicationStore, incomingBankingTransactionsStore } = useStore();
  const { culture, getTranslation, getEnumTranslation } = applicationStore;
  const { getIncomingBankingTransactionInsights } = incomingBankingTransactionsStore.incomingBankingTransactionsService;

  const [incomingBankingTransactionDetails, setIncomingBankingTransactionDetails] =
    useState<IncomingBankingTransactionInsightsResponse>();

  useEffect(() => {
    getIncomingBankingTransactionInsights(incomingBankingTransactionId)
      .then((details) => setIncomingBankingTransactionDetails(details))
      .catch((error) => {
        notify.error({
          content: getTranslation('incoming_banking_transactions.mutation_details_fetch_fail'),
          error
        });
      });
  }, []);

  useImperativeHandle(ref, () => ({
    async onClick() {
      // Is dismiss dialog
    }
  }));

  if (!incomingBankingTransactionDetails) return <Spinner centered />;

  return (
    <div className={css['details-wrapper']}>
      <InfoGrid type="grey" nCols={1}>
        <DetailLine label={getTranslation('banking_transaction.opening_balance')}>
          <div className={css['balance-date']}>
            <Paragraph>{formatMoney(incomingBankingTransactionDetails.openingBalance, culture) + ' - '}</Paragraph>
            <Paragraph>{formatDate(incomingBankingTransactionDetails.openingBalanceDate)}</Paragraph>
          </div>
        </DetailLine>

        <DetailLine label={getTranslation('banking_transaction.closing_balance')}>
          <div className={css['balance-date']}>
            <Paragraph>{formatMoney(incomingBankingTransactionDetails.closingBalance, culture) + ' - '}</Paragraph>
            <Paragraph>{formatDate(incomingBankingTransactionDetails.closingBalanceDate)}</Paragraph>
          </div>
        </DetailLine>

        {incomingBankingTransactionDetails.balanceDifference !== null && (
          <DetailLine label={getTranslation('general.difference')}>
            <Paragraph>{formatMoney(incomingBankingTransactionDetails.balanceDifference, culture)}</Paragraph>
          </DetailLine>
        )}
      </InfoGrid>

      <div className={css['enum-blocks']}>
        <div className={css['enum-block']}>
          <Paragraph bold>{getTranslation('general.by_status')}</Paragraph>

          {Object.keys(incomingBankingTransactionDetails.statusDetails).map((status) => {
            const details = incomingBankingTransactionDetails.statusDetails[status as incomingMutationStatus];

            return (
              <DetailLine
                key={`incomingMutationStatus-${status}`}
                label={`${getEnumTranslation('incomingMutationStatus', status)} (${details.count})`}
              >
                <Paragraph bold>{formatMoney(details.amount, culture)}</Paragraph>
              </DetailLine>
            );
          })}
        </div>

        <HorizontalDivider className={css['divider']} />

        <div className={css['enum-block']}>
          <Paragraph bold>{getTranslation('general.by_payment_method')}</Paragraph>

          {Object.keys(incomingBankingTransactionDetails.paymentMethodDetails).map((method) => {
            const castedMethod = method as incomingMutationPaymentMethod;
            const details = incomingBankingTransactionDetails.paymentMethodDetails[castedMethod];

            return (
              <DetailLine
                key={`incomingMutationPaymentMethod-${method}`}
                label={`${getEnumTranslation('incomingMutationPaymentMethod', method)} (${details.count})`}
              >
                <Paragraph bold> {formatTransactionAmount(details.amount, castedMethod, culture)}</Paragraph>
              </DetailLine>
            );
          })}
        </div>

        <HorizontalDivider className={css['divider']} />

        <div className={css['enum-block']}>
          <Paragraph bold>{getTranslation('general.by_type')}</Paragraph>

          {Object.keys(incomingBankingTransactionDetails.mutationTypeDetails).map((type) => {
            const castedType = type as incomingMutationType;
            const details = incomingBankingTransactionDetails.mutationTypeDetails[castedType];

            return (
              <DetailLine
                key={`incomingMutationType-${type}`}
                label={`${getEnumTranslation('incomingMutationType', type)} (${details.count})`}
              >
                <Paragraph bold> {formatTransactionAmount(details.amount, castedType, culture)}</Paragraph>
              </DetailLine>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default observer(ShowDetailsDialog);
