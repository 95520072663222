import { observer } from 'mobx-react';
import React from 'react';

import { IncomingBankingTransactionsOverviewCountType } from '@zf/api-types/billing/incoming-banking-transaction';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import { colors } from '@zf/utils/src/color';

import { Icon } from '../../../../../components/Icon';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  overviewCount: IncomingBankingTransactionsOverviewCountType;
  quickFilter: string;
  onFilter: (quickFilter: string) => void;
};

const IncomingBankingTransactionsQuickFilters = (props: Props) => {
  const { quickFilter, overviewCount, onFilter } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      <FilterButton
        id="all"
        onTrigger={() => onFilter('all')}
        icon={<Icon type="batch" />}
        count={overviewCount.all}
        title={getTranslation('banking_transaction.all')}
        activeColor={colors['blue-400']}
        active={quickFilter === 'all'}
      />
      <FilterButton
        id="ready_to_process"
        onTrigger={() => onFilter('ready')}
        icon={<Icon type="batch" />}
        count={overviewCount.ready}
        title={getTranslation('banking_transaction.ready_to_process')}
        activeColor={colors['blue-700']}
        active={quickFilter === 'ready'}
      />
      <FilterButton
        id="closed"
        onTrigger={() => onFilter('closed')}
        icon={<Icon type="batch" />}
        count={overviewCount.closed}
        title={getTranslation('banking_transaction.closed')}
        activeColor={colors['green-600']}
        active={quickFilter === 'closed'}
      />
      <FilterButton
        id="attention"
        onTrigger={() => onFilter('attention')}
        icon={<Icon type="batch" />}
        count={overviewCount.attention}
        title={getTranslation('general.attention')}
        activeColor={colors['yellow-600']}
        active={quickFilter === 'attention'}
      />
      <FilterButton
        id="resolve_issues"
        onTrigger={() => onFilter('resolveissues')}
        icon={<Icon type="batch" />}
        count={overviewCount.resolveIssues}
        title={getTranslation('banking_transaction.resolve_issues')}
        activeColor={colors['red-600']}
        active={quickFilter === 'resolveissues'}
      />
    </>
  );
};

export default observer(IncomingBankingTransactionsQuickFilters);
