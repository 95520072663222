import React, { useCallback } from 'react';

import { navigate } from '@reach/router';

export type LinkProps = {
  id?:string;
  dataTestId?:string;
  url: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  /**
   * Open page in new tab
   */
  newTab?: boolean;
  /**
   * Function to execute while navigating e.g. state update
   */
  onNavigate?: Function;
};

/**
 * Do not use this component in "non design system components!"
 */
export default function BaseLink(props: LinkProps) {
  const { id, url, children, className, newTab = false, onNavigate, dataTestId } = props;

  const handleNavigate = useCallback(
    (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
      // Allow command-click and control-click to open new tab
      if (e.metaKey || e.ctrlKey) {
        return;
      } else if (!newTab) {
        // Otherwise preventDefault
        e.preventDefault();
        if (onNavigate) onNavigate();
        navigate(url);
      }
    },
    [url]
  );

  return (
    <a id={id} data-testid={dataTestId} className={className} href={url} onClick={handleNavigate} target={newTab ? '_blank' : '_self'} rel="noreferrer">
      {children}
    </a>
  );
}
