import Interweave from 'interweave';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { CustomerBankAccountType } from '@zf/api-types/billing/customer-bank-account';
import Label from '@zf/stella-react/src/atoms/Label/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';
import { MIN_DATE } from '@zf/utils/src/date';
import { formatIBAN } from '@zf/utils/src/iban';

import { useAppContext } from '../../../app-context';
import DatePicker from '../../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../hooks/useStore';
import css from './add-mandate-dialog.module.scss';

type Props = {
  defaultBankAccount: CustomerBankAccountType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

const AddMandateDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { defaultBankAccount } = props;
  const { i18n } = useAppContext();
  const { customerStore } = useStore();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      customerStore.bankDetailsForm._submit();
    }
  }));

  return (
    <Observer>
      {() => (
        <>
          <Paragraph textAlign="center">
            <Interweave content={i18n.getTranslation('mandates.create-desc')} />
          </Paragraph>
          <Center type="horizontal">
            <div className={css['inputs']}>
              <div>
                <Label>{i18n.getTranslation('mandates.iban')}</Label>
                <Paragraph>{formatIBAN(defaultBankAccount.iban)}</Paragraph>
              </div>
              <div>
                <Label>{i18n.getTranslation('mandates.sign-date')}</Label>
                <DatePicker
                  id="signed-date"
                  value={moment(defaultBankAccount.mandateForm?._getNested('mandate', 'signedDateTime') || MIN_DATE)}
                  onChange={(val) => {
                    defaultBankAccount.mandateForm?._setNested('mandate', 'signedDateTime', val.toISOString());
                  }}
                />
              </div>
            </div>
          </Center>
        </>
      )}
    </Observer>
  );
});

export default AddMandateDialog;
