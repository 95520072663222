import React from 'react';

import {
  IncomingBankingTransactionRowType,
  IncomingBankingTransactionType
} from '@zf/api-types/billing/incoming-banking-transaction';

import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { notify } from '../../../events/notification-events';

export default function DownloadIncomingBankingTransactionListPageButton() {
  const { i18n, tenantReducer } = useAppContext();
  const { myCurrent } = useCurrent('incoming_banking_transaction');
  const selectedRows: IncomingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const { downloadFiles, downloadSingleFile } = useDownload();

  const isDisabled = selectedRows.length === 0;
  let incomingTransaction: IncomingBankingTransactionType | undefined = undefined;

  if (selectedRows[0]) {
    incomingTransaction = selectedRows[0].__entity;
  }

  return incomingTransaction ? (
    <DropdownAction
      id="invoice.download"
      icon="download-cloud"
      actionType="direct"
      onClick={() => {
        const selectedIds = selectedRows.map((row) => {
          return row.__entity.id;
        });

        try {
          if (selectedIds.length === 1) {
            downloadSingleFile(`/bill/IncomingBankingTransactions/${selectedIds[0]}/download`, tenantReducer);
          } else {
            downloadFiles(
              selectedIds,
              '/bill/IncomingBankingTransactions/downloadzip',
              '/bill/IncomingBankingTransactions/',
              'incomingBankingTransactionIds'
            );
          }
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.payments.download_failed'),
            error: e
          });
        }
      }}
      disabled={isDisabled}
    >
      {i18n.getTranslation('actions.download')}
    </DropdownAction>
  ) : null;
}
