import React, { useCallback, useEffect } from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import {
  IncomingMutationRowType,
  IncomingMutationType,
  IncomingOutgoingTransferDetailsType,
  IncomingPaymentRequestMutationConfirmationDetailsType,
  IncomingPaymentRequestRefusalDetailsType,
  IncomingPaymentRequestTransactionConfirmationDetailsDetailsType,
  IncomingRefundMutationConfirmationDetailsType,
  IncomingRefundRefusalDetailsType,
  IncomingRefundTransactionConfirmationDetailsType,
  IncomingTransferDetailsType,
  IncomingUnknownDetails
} from '@zf/api-types/billing/incoming-mutations';
import { ibanConfirmationStatus, incomingMutationStatus, incomingMutationType } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';
import { NewStatusBadge, StatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { useAppContext } from '../../../../../../../app-context';
import { ICON_COLORS } from '../../../../../../../constants/icons';
import { Link } from '../../../../../../../design-system/Components';
import { dialog } from '../../../../../../../events/dialog-events';
import useCreateRequest from '../../../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../../../hooks/useInfiniAPI';
import useLocalRefresh from '../../../../../../../hooks/useLocalRefresh';
import { getCustomerBankAccountFromIncomingMutation } from '../../../../../../../utils/incoming-mutation';
import { METHODS, sendRequest } from '../../../../../../../utils/request';
import { formatTransactionAmount } from '../../../../../../../utils/transaction';
import useMutations from '../../../../mutations-shared/useMutations';
import { IncomingMutationTypeOverviewCountType, useTracked } from '../../context/incoming-banking-transaction-context';
import IncomingMutationDetailsDialog from '../IncomingMutationDetailsDialog';
import css from './incoming-mutations-table.module.scss';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import Interweave from 'interweave';
import { colors } from '@zf/utils/src/color';

type RefType = {
  text: string;
  id?: string;
};

export type MatchDialogState = {
  selectedIds: string[];
  customerId: string;
  customerName: string;
  amount: number;
  remainingAmount: number;
  isBooked: boolean;
  refreshTrigger: string;
  saveIbanOnCustomer: boolean;
  ibanConfirmationStatus: string;
  isIbanConfirmedOnTransaction: boolean;
};

export default function useIncomingMutationsTable(incomingBankingTransaction: IncomingBankingTransactionType) {
  const [state, dispatch] = useTracked();

  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { refresh, timeStamp } = useLocalRefresh();
  const { rootUrl } = tenantReducer.state;

  const { clickRef, validationRef, onSubmit } = useDialog();

  const { renderErrors } = useMutations();

  const fetchOverviewCounts = useCallback(async () => {
    return (
      await sendRequest<IncomingMutationTypeOverviewCountType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/IncomingBankingTransactions/${incomingBankingTransaction.id}/mutations/overviewcount`,
          timeStamp: timeStamp
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data;
  }, [incomingBankingTransaction, timeStamp]);

  const setOverviewCounts = useCallback((newCounts: IncomingMutationTypeOverviewCountType) => {
    dispatch({ type: 'SET_OVERVIEW_COUNTS', newCounts });
  }, []);

  const { request, selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = useCreateRequest(
    `/bill/IncomingBankingTransactions/${incomingBankingTransaction.id}/mutations`,
    {
      ...state.queryParams,
      transactionDateStart: state.queryParams.transactionDateStart.toISOString(),
      transactionDateEnd: state.queryParams.transactionDateEnd.toISOString(),
      timeStamp,
      refreshStatus: incomingBankingTransaction.status // If status changes => refetch
    }
  );

  const renderStatus = useCallback((mutation: IncomingMutationType) => {
    if (mutation.status === incomingMutationStatus.resolveissues || mutation.status === incomingMutationStatus.failed) {
      return mutation.errors.length === 1 ? (
        <StatusBadge className={css['status-single']} color={ICON_COLORS[mutation.status]} type="bare">
          {renderErrors(mutation.errors)}
        </StatusBadge>
      ) : (
        <div className={css['status']}>
          <StatusBadge color={ICON_COLORS[mutation.status]} type="bare">
            {mutation.errors[0].message}
          </StatusBadge>
          <div
            // @ts-ignore
            data-tip={createTooltipContent(
              <>
                {mutation.errors.slice(1).map((e, index) => {
                  return <div key={index}>{e.message}</div>;
                })}
              </>
            )}
            data-for="mutations-table-tip"
          >
            {renderErrors(mutation.errors)}
          </div>
        </div>
      );
    }

    return (
      <StatusBadge color={ICON_COLORS[mutation.status]} type="bare">
        {enumReducer.getTranslation('incomingMutationStatus', mutation.status)}
      </StatusBadge>
    );
  }, []);

  const getRefOrDescription = useCallback((mutation: IncomingMutationType): RefType => {
    switch (mutation.type) {
      case incomingMutationType.incomingtransfer: {
        const castedDetails = mutation.details as IncomingTransferDetailsType;
        return { text: castedDetails.description };
      }
      case incomingMutationType.outgoingtransfer: {
        const castedDetails = mutation.details as IncomingOutgoingTransferDetailsType;
        return { text: castedDetails.description };
      }
      case incomingMutationType.paymentrequestreversal: {
        const castedDetails = mutation.details as IncomingPaymentRequestRefusalDetailsType;
        return {
          text: castedDetails.matchingOutgoingBankingTransactionIdentification,
          id: castedDetails.matchingOutgoingBankingTransactionId
        };
      }
      case incomingMutationType.paymentrequestmutationconfirmation: {
        const castedDetails = mutation.details as IncomingPaymentRequestMutationConfirmationDetailsType;
        return {
          text: castedDetails.matchingOutgoingBankingTransactionIdentification,
          id: castedDetails.matchingOutgoingBankingTransactionId
        };
      }
      case incomingMutationType.refundmutationconfirmation: {
        const castedDetails = mutation.details as IncomingRefundMutationConfirmationDetailsType;
        return {
          text: castedDetails.matchingOutgoingBankingTransactionIdentification,
          id: castedDetails.matchingOutgoingBankingTransactionId
        };
      }
      case incomingMutationType.refundreversal: {
        const castedDetails = mutation.details as IncomingRefundRefusalDetailsType;
        return {
          text: castedDetails.matchingOutgoingBankingTransactionIdentification,
          id: castedDetails.matchingOutgoingBankingTransactionId
        };
      }
      case incomingMutationType.refundtransactionconfirmation: {
        const castedDetails = mutation.details as IncomingRefundTransactionConfirmationDetailsType;
        return {
          text: castedDetails.matchingOutgoingBankingTransactionIdentification,
          id: castedDetails.refundOutgoingBankingTransactionId
        };
      }
      case incomingMutationType.paymentrequesttransactionconfirmation: {
        const castedDetails = mutation.details as IncomingPaymentRequestTransactionConfirmationDetailsDetailsType;
        return {
          text: castedDetails.matchingOutgoingBankingTransactionIdentification,
          id: castedDetails.paymentRequestOutgoingBankingTransactionId
        };
      }
      default: {
        const castedDetails = mutation.details as IncomingUnknownDetails;
        return {
          text: castedDetails.description
        };
      }
    }
  }, []);

  const openDialog = useCallback((type: string, incomingMutation: IncomingMutationType) => {
    dialog.right({
      title: `${type} - ${i18n.getTranslation('general.details')}`,
      icon: 'info-circle',
      content: (
        <IncomingMutationDetailsDialog
          ref={clickRef}
          incomingMutation={incomingMutation}
          validationRef={validationRef}
        />
      ),
      status: (
        <NewStatusBadge
          color={ICON_COLORS[incomingMutation.status]}
          status_={enumReducer.getTranslation('incomingMutationStatus', incomingMutation.status)}
        />
      ),
      onSubmit: () => {
        onSubmit();
        refresh();
      },
      isDismissDialog: false,
      ref: validationRef,
      buttonPositive: i18n.getTranslation('general.save'),
      buttonNegative: i18n.getTranslation('general.cancel')
    });
  }, []);

  const processRecord = (mutation: IncomingMutationType) => {
    const refOrDescriptionIntel = getRefOrDescription(mutation);
    const type = enumReducer.getTranslation('incomingMutationType', mutation.type);
    const customerBankAccount = getCustomerBankAccountFromIncomingMutation(mutation);

    const CustomerIban = ({ onClick }: { onClick: () => void }) => {
      if (mutation.details.iban && mutation.status === incomingMutationStatus.processed) {
        return <Paragraph>{customerBankAccount}</Paragraph>;
      } else if (mutation.details.iban && mutation.details.isIbanConfirmedOnTransaction) {
        return (
          <div onClick={onClick} className={css['iban-text']}>
            <Paragraph color={colors['green-400']} info={i18n.getTranslation('customer.save_iban_after_processing')}>
              {customerBankAccount}
            </Paragraph>
          </div>
        );
      } else if (
        mutation.details.iban &&
        mutation.details.ibanConfirmationStatus === ibanConfirmationStatus.UnknownSecond
      ) {
        return (
          <div onClick={onClick} className={css['iban-text']}>
            <FlexElements justifyContent="start" flexDirection="row">
              <Paragraph color={colors['blue-400']}>{customerBankAccount}</Paragraph>
              <Paragraph color={colors['blue-700']}>
                <Interweave content={i18n.getTranslation('general.new')} />
              </Paragraph>
            </FlexElements>
          </div>
        );
      } else if (
        mutation.details.iban &&
        mutation.details.ibanConfirmationStatus === ibanConfirmationStatus.UnknownFirst
      ) {
        return (
          <div onClick={onClick} className={css['iban-text']}>
            <FlexElements justifyContent="start" flexDirection="row">
              <Paragraph color={colors['orange-400']}>{customerBankAccount}</Paragraph>
              <Paragraph color={colors['orange-700']}>
                <Interweave content={i18n.getTranslation('customer.1st_iban')} />
              </Paragraph>
            </FlexElements>
          </div>
        );
      } else {
        return <Paragraph>{customerBankAccount}</Paragraph>;
      }
    };

    return {
      __id: mutation.id,
      __entity: mutation,
      __etag: mutation._etag,
      type: (
        <div
          id={`${mutation.id}-details`}
          role="button"
          className={css['details-btn']}
          tabIndex={0}
          onClick={() => openDialog(type, mutation)}
          onKeyDown={() => {}}
        >
          {enumReducer.getTranslation('incomingMutationType', mutation.type)}
        </div>
      ),
      amount: formatTransactionAmount(mutation.amount, mutation.type, i18n.culture).toString(),
      status: renderStatus(mutation),
      transactionDate: formatDate(mutation.transactionDate),
      customerBankAccount: <CustomerIban onClick={() => openDialog(type, mutation)} />,
      description:
        refOrDescriptionIntel && refOrDescriptionIntel.id && refOrDescriptionIntel.text ? (
          <Link
            id={`outgoing-${refOrDescriptionIntel.id}`}
            url={`${rootUrl}/payments/outgoing-banking-transactions/${refOrDescriptionIntel.id}`}
          >
            {refOrDescriptionIntel.text}
          </Link>
        ) : (
          <Paragraph id={`ref_description-${mutation.id}`}>{refOrDescriptionIntel?.text}</Paragraph>
        )
    };
  };

  useEffect(() => {
    fetchOverviewCounts()
      .then((data) => {
        setOverviewCounts(data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [incomingBankingTransaction, timeStamp]);

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<
    IncomingMutationType,
    IncomingMutationRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  return {
    selectedIds,
    sortBy,
    sortDirection,
    sortableFields,
    loading,
    error,
    rows,
    queryParams: state.queryParams,
    overviewCounts: state.overviewCounts,
    setSelectedIds,
    handleSort,
    setStopIndex,
    refresh
  };
}
