import React from 'react';

import {
  IncomingBankingTransactionRowType,
  IncomingBankingTransactionType
} from '@zf/api-types/billing/incoming-banking-transaction';
import { incomingBankingTransactionStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteIncomingBankingTransactionsDialog from '../../../actions/incoming-banking-transactions/DeleteIncomingBankingTransactionsDialog';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

type Props = {
  refreshCounts: () => void;
};

export default function DeleteIncomingBankingTransactionListPageButton(props: Props) {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { myCurrent } = useCurrent('incoming_banking_transaction');
  const selectedRows: IncomingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<IncomingBankingTransactionType>('incoming_banking_transaction');
  const { clickRef, onSubmit } = useDialog();

  const isDisabled = selectedRows.some((r) => {
    return (
      r.__entity.status === incomingBankingTransactionStatus.created ||
      r.__entity.status === incomingBankingTransactionStatus.processing ||
      r.__entity.status === incomingBankingTransactionStatus.matching ||
      r.__entity.status === incomingBankingTransactionStatus.closed // Remove this status when backend match reopen feature is implemented: #13165
    );
  });

  return (
    <DropdownAction
      id="incoming_banking_transaction.delete"
      icon="trashcan"
      dialogTitle={getTranslation('general.delete')}
      dialogContent={
        <DeleteIncomingBankingTransactionsDialog
          {...props}
          ref={clickRef}
          selectedRows={selectedRows}
          setDeletedRows={setDeletedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={isDisabled || selectedRows.length === 0}
      buttonPositive={getTranslation('general.delete')}
      dialogType="danger"
    />
  );
}
