import clone from 'clone';
import React from 'react';

import { communicationType, culture, invoiceType } from '@zf/api-types/enums';
import { CultureTableType } from '@zf/api-types/language';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../../app-context';
import Select from '../../../../components/input/Select';
import SimpleDropdown from '../../../../components/Lang/SimpleDropdown';
import { getSelectedValue, setSelectedValue } from '../../../../utils/communicationPrefs';
import { CreateCustomerType, STEP_NAMES } from '../CustomerWizard';
import css from './communication-section.module.scss';
import ContactDetailsMultiValue from './ContactDetailsMultiValue';
import PrimaryContactDetails from './PrimaryContactDetails';

export type Props = {
  values: CreateCustomerType;
  cultureTable: CultureTableType;
  setValue: (value: Partial<CreateCustomerType>, initialApiSet?: boolean, backup?: boolean) => void;
  handleFocus: (step: string) => () => void;
  customerName: {
    name: string;
  };
};
export type ContactValue = { contactType: string; value: string };

const WizardLangDropdown = WizardInputWrapper<SimpleDropdownProps<culture>>(SimpleDropdown);

export default function CommunicationSection(props: Props) {
  const { values, cultureTable, setValue, handleFocus, customerName } = props;
  const { i18n, enumReducer } = useAppContext();

  const communicationTypes = enumReducer
    .getEnum<communicationType>('communicationType')
    .filter((e) => e.value !== communicationType.none);

  const setLanguagePreference = (culture_: culture | null) => {
    const commPrefsClone = clone(values.communicationPreferences);
    commPrefsClone.culture = culture_;
    setValue({ communicationPreferences: commPrefsClone });
  };

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[2]} />
        {i18n.getTranslation('customer.steps.communication')}
      </WizardHeader>

      {/* Contact details */}
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('customer.primary_contact_details_for', customerName)}
      </Heading>
      <PrimaryContactDetails values={values} setValue={setValue} handleFocus={handleFocus} />

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('customer.extra_contact_details')}
      </Heading>

      <ContactDetailsMultiValue setValue={setValue} onFocus={() => handleFocus(STEP_NAMES[2])} />

      {/* Language */}
      <div className={css['deviating-language']}>
        <Heading headingType="h3" style="bold">
          {i18n.getTranslation('customer.deviating_lang')}
        </Heading>
        <InputContainer grid={false}>
          <WizardLangDropdown
            id="dropdown-culture"
            onChange={(val) => setLanguagePreference(val[0])}
            values={cultureTable.supportedCultures.map((c) => {
              return {
                value: c,
                text:
                  c === cultureTable.defaultCulture
                    ? `${enumReducer.getTranslation('culture', c)} (${i18n.getTranslation('general.default')})`
                    : enumReducer.getTranslation('culture', c)
              };
            })}
            placeholder={i18n.getTranslation('general.language')}
            selectedValues={[
              values.communicationPreferences.culture ? values.communicationPreferences.culture : ('' as culture)
            ]}
          />
        </InputContainer>
      </div>

      <div className={css['check-wrapper']}>
        <CheckBox
          id="deviating-comm"
          checked={values.deviatingCommunicationType}
          onChange={(val) => setValue({ deviatingCommunicationType: val })}
        >
          {i18n.getTranslation('customer.deviating_communication')}
        </CheckBox>
      </div>

      {values.deviatingCommunicationType && (
        <>
          <InputContainer grid={false}>
            <Select
              id="commPref"
              onChange={(val) => setValue({ commPreference: val[0] as communicationType | 'custom' })}
              selectedValues={[values.commPreference]}
              values={[
                ...communicationTypes.map((comType) => {
                  return {
                    icon: `com${comType.value}`,
                    value: comType.value,
                    text: comType.text
                  };
                }),
                {
                  icon: 'shuffle',
                  value: 'custom',
                  text: i18n.getTranslation('customer.custom')
                }
              ]}
            />
          </InputContainer>
          {values.commPreference === 'custom' && (
            <InputContainer>
              {enumReducer.getEnum<invoiceType>('invoiceType').map((type) => {
                return (
                  <SimpleDropdown
                    id={`dropdown-${type.value}`}
                    key={`dropdown-${type.value}`}
                    onChange={(val) =>
                      setSelectedValue(type.value, val[0] as communicationType | 'custom', values, setValue)
                    }
                    placeholder={type.text}
                    values={communicationTypes}
                    selectedValues={getSelectedValue(type.value, values)}
                  />
                );
              })}
            </InputContainer>
          )}
        </>
      )}
    </WizardSection>
  );
}
