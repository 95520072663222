import React, { ReactNode } from 'react';

import css from './spinner-wrapper.module.scss';
import classNames from 'classnames';

export type SpinnerSize = 'xsmall' | 'small' | 'medium' | 'large';

type Props = {
  children: ReactNode;
  hasTabHeight?: boolean;
};

/**
 * Use this component when the parent div doesn't use the full viewport
 */
export default function SpinnerWrapper(props: Props) {
  const { children, hasTabHeight = false } = props;

  return <div className={classNames(css['spinner-wrapper'], { [css['tab-height']]: hasTabHeight })}>{children}</div>;
}
