import moment from 'moment';
import React from 'react';

import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { MIN_DATE } from '@zf/utils/src/date';

import { DialogClickRef } from '../../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../../../events/notification-events';
import { useStore } from '../../../../../../../hooks/useStore';

type Props = {
  completeness: BillingCompletenessType;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { completeness } = props;
  const { contractStore, applicationStore } = useStore();
  const { createOrRecalculate } = contractStore.billingCompletenessStore;

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await createOrRecalculate(completeness.id, false, false, moment(MIN_DATE));
        notify.success({
          content: applicationStore.getTranslation('invoice.generate_successful')
        });
      } catch (e) {
        notify.error({
          content: applicationStore.getTranslation('invoice.generate_fail'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{applicationStore.getTranslation('invoice.generate_inv_desc')}</Paragraph>;
});
