import KeepExistingInvoiceSection from 'components/Contract/KeepExistingInvoiceSection';
import DatePicker from 'components/Lang/DatePicker';
import { Paragraph } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatPeriod, isMinDate } from '@zf/utils/src/date';

import InvoiceAddress from '../../shared/InvoiceAddress/InvoiceAddress';
import OptionalMeasurement from './OptionalMeasurement';
import css from './terminate-contract.module.scss';

const TerminateContract = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { moveOutWizardStore, contract } = contractStore.moveOutWizardBaseStore;
  const { moveOutWizardValues, setInvoiceAddressValue } = moveOutWizardStore;
  const { values, setValue } = moveOutWizardValues;

  if (!contract) return null;

  return (
    <>
      <FlexElements className={css['move-out-date-wrapper']} flexDirection="column" gap="16">
        <Paragraph bold>{getTranslation('actions.contract.termination_date')}</Paragraph>
        <div className={css['move-out-date']}>
          <DatePicker
            id="contracts.move-out-date"
            onChange={(moveOutDate) => {
              const updatedMeasurements = [...values.optionalMeasurements];
              updatedMeasurements.forEach((om) => (om.endDateTime = moveOutDate.toISOString()));
              setValue({
                moveOutDate,
                optionalMeasurements: updatedMeasurements
              });
            }}
            value={values.moveOutDate}
            placeholder={getTranslation('general.choose_date')}
            error={!values.moveOutDate || isMinDate(values.moveOutDate)}
          />
          {values.moveOutDate &&
            !values.moveOutDate.isBetween(contract.supplyStartDate, contract.supplyEndDate, undefined, '[]') && (
              <Paragraph color={colors['red-600']}>
                {getTranslation('contracts.wizard.choose_date_within_supply_period', {
                  supplyPeriod: formatPeriod(contract.supplyStartDate, contract.supplyEndDate)
                })}
              </Paragraph>
            )}
        </div>
      </FlexElements>

      <KeepExistingInvoiceSection
        customerId={contract.contractor.customerId}
        contractId={contract.id}
        mutationDate={values.moveOutDate}
        keepExistingInvoiceOnEndDate={values.keepExistingInvoiceOnEndDate}
        setKeepExistingInvoiceOnEndDate={(keepExistingInvoiceOnEndDate) => setValue({ keepExistingInvoiceOnEndDate })}
      />

      <InvoiceAddress
        id="contract.move-out-invoice-address"
        useCase="old_contractor"
        contractorDisplayName={contract.contractor.shortDisplayName}
        invoiceAddress={values.oldContractorInvoiceAddress}
        setInvoiceAddressValue={setInvoiceAddressValue}
      />

      <OptionalMeasurement />
    </>
  );
};

export default observer(TerminateContract);
