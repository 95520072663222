import clone from 'clone';
import { MultiValue } from 'design-system/ComponentSets';
import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';

import {
  AddMeasurementRequestType,
  ExternalChannelType,
  MeasurementType,
  MeterType
} from '@zf/api-types/master-data/meter';

import InstallWizardMeasurement from '../../../features/meter-install/meter-multivalue/InstallWizardMeasurement';
import Measurement from './Measurement';

type Props = {
  meterIndex?: number;
  locationId?: string;
  titleText: string;
  mutationDateTime: Moment;
  meter?: MeterType;
  usedInWizard?: boolean;
  measurements: AddMeasurementRequestType[];
  singleMeasurement?: boolean;
  metersQuery?: Record<string, any>;
  oneEntryRequired?: boolean;
  useCase?: 'add' | 'edit';
  isValidating?: boolean;
  measurement?: MeasurementType | undefined;
  validateChannel?: (channel: ExternalChannelType) => boolean;
  setCreateNewMeasurement?: Dispatch<SetStateAction<boolean>>;
  setMeasurements: (measurements: AddMeasurementRequestType[]) => void;
  setIsValidating?: (isValidating: boolean) => void;
  closeDialog?: () => void | undefined;
};

export default function AddMeasurementMultiValue(props: Props) {
  const {
    meterIndex,
    locationId,
    measurements,
    usedInWizard = false,
    meter,
    titleText,
    metersQuery = {},
    mutationDateTime,
    singleMeasurement = false,
    isValidating = false,
    oneEntryRequired = true,
    useCase,
    measurement,
    validateChannel,
    setMeasurements,
    setCreateNewMeasurement,
    setIsValidating,
    closeDialog
  } = props;

  const [today] = useState(moment());

  let initialMeterValue: MeterType | null = null;

  if (measurements.length > 0) {
    // when + is pressed fill in the previous selected meter
    if (measurements[0]) {
      initialMeterValue = clone(measurements[0].meter);
    }
  } else if (meter) {
    initialMeterValue = meter;
  }

  return (
    <MultiValue
      id="install_meter.wizard.measurements"
      title={titleText || ''}
      initialValues={measurements}
      intialNodeValue={{
        meter: initialMeterValue,
        value: undefined,
        channelId: '',
        hour: '0',
        minute: '0',
        endDateTime: clone(today).toISOString()
      }}
      onChange={(value) => {
        setMeasurements(value);
        if (setCreateNewMeasurement) {
          setCreateNewMeasurement(value.length !== 0);
        }
      }}
      enableAddNode={!singleMeasurement}
      oneEntryRequired={oneEntryRequired}
    >
      {({ value, index, dispatchValue }) => {
        return usedInWizard ? (
          <InstallWizardMeasurement
            measurementIndex={index}
            meterIndex={meterIndex}
            locationId={locationId}
            mutationDateTime={mutationDateTime}
            // @ts-ignore
            value={value}
            dispatchValue={dispatchValue}
          />
        ) : (
          <Measurement
            measurementIndex={index}
            meterIndex={meterIndex}
            locationId={locationId}
            mutationDateTime={mutationDateTime}
            value={value}
            metersQuery={metersQuery}
            meter={initialMeterValue}
            isValidating={isValidating}
            validateChannel={validateChannel}
            dispatchValue={dispatchValue}
            useCase={useCase}
            setIsValidating={setIsValidating}
            closeDialog={closeDialog}
            measurement={measurement}
          />
        );
      }}
    </MultiValue>
  );
}
