import { BaseButton } from 'design-system/Components';
import AccuracyIndicator, {
  AccuracyIndicatorProps
} from 'design-system/Components/AccuracyIndicator/AccuracyIndicator';
import { dialog } from 'events/dialog-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { EstimatedInvoiceLine } from '@zf/api-types/billing/estimated-invoices';
import { ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import EstimatedInvoiceDialog from '../EstimatedInvoiceDialog';
import css from './accuracy-indicator.module.scss';
import * as ToolTips from './ToolTips';

type Props = {
  id: string;
  usage: 'detail' | 'dialog-main' | 'dialog-line' | 'generic';
  invoiceId?: string;
  contract?: ContractType;
  estimatedInvoiceLine?: EstimatedInvoiceLine;
  productName?: string;
};

const EstimatedInvoiceAccuracy = (props: AccuracyIndicatorProps & Props) => {
  const { id, invoiceId, contract, accuracy, size, usage, estimatedInvoiceLine, productName } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { clickRef, validationRef } = useDialog();

  const accuracyToolTipTitle = getTranslation(
    `contracts.estimation_accuracy_${accuracy}${
      estimatedInvoiceLine?.calculationType === 'consumption' ? '_based_on' : ''
    }`
  );

  const onClose = () => {
    if (validationRef.current) {
      validationRef.current.onCancel();
    }
  };

  const openDialog = () => {
    if (contract && invoiceId) {
      dialog.right({
        title: getTranslation('estimated_invoice.estimated_amount'),
        icon: 'info-circle',
        content: (
          <EstimatedInvoiceDialog
            ref={clickRef}
            validationRef={validationRef}
            contract={contract}
            estimatedInvoiceId={invoiceId}
          />
        ),
        onSubmit: () => {
          return;
        },
        ref: validationRef,
        isDismissDialog: true
      });
    }
  };

  const AccuracyIndicatorComponent = () => (
    <BaseButton id={id} className={css['accuracy-indicator-btn']} onClick={openDialog}>
      <AccuracyIndicator accuracy={accuracy} size={size} />
    </BaseButton>
  );

  if (usage === 'dialog-main') {
    return (
      <ToolTips.DialogMain accuracyToolTipTitle={accuracyToolTipTitle} onClose={onClose}>
        <AccuracyIndicatorComponent />
      </ToolTips.DialogMain>
    );
  }

  if (usage === 'dialog-line') {
    return (
      <ToolTips.DialogLine
        accuracyToolTipTitle={accuracyToolTipTitle}
        estimatedInvoiceLine={estimatedInvoiceLine}
        productName={productName}
        onClose={onClose}
      >
        <AccuracyIndicatorComponent />
      </ToolTips.DialogLine>
    );
  }

  // Detail
  return (
    <ToolTips.Detail accuracyToolTipTitle={accuracyToolTipTitle} onClick={openDialog}>
      <AccuracyIndicatorComponent />
    </ToolTips.Detail>
  );
};

export default observer(EstimatedInvoiceAccuracy);
