import FilterBarNew from 'components/Filters/FilterBarNew';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { StringParam, useQueryParams } from 'use-query-params';

import { useStore } from '../../../../../hooks/useStore';
import { useTransactionsContext } from '../Context/Context';
import LeftFilters from './LeftFilters';
import RightFilters from './RightFilters';
import { Query } from './types';
import FilterTag from 'components/Filters/filter-tag';

const TransactionsFilterBar = () => {
  const {
    setFilter,
    store: { filter }
  } = useTransactionsContext();

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const paramTypes = {
    createdStartDate: StringParam,
    createdEndDate: StringParam,
    flexSearch: StringParam,
    quickFilter: StringParam
  };

  const [, setQuery] = useQueryParams(paramTypes);

  const { setValue, watch, getValues } = useForm<Query>({
    defaultValues: {
      createdStartDate: filter.queryParams.createdStartDate || undefined,
      createdEndDate: filter.queryParams.createdEndDate || undefined,
      flexSearch: filter.search || undefined,
      quickFilter: filter.quickFilter
    }
  });

  /**
   * @description update context with query params
   */
  useEffect(() => {
    setQuery({
      createdStartDate: filter.queryParams.createdStartDate || undefined,
      createdEndDate: filter.queryParams.createdEndDate || undefined,
      flexSearch: filter.search,
      quickFilter: filter.quickFilter
    });
  }, [filter]);

  /**
   * @description update query params as well as context when you are changing filter components
   */
  useEffect(() => {
    const subscription = watch((query) => {
      const { quickFilter, flexSearch, ...queryParams } = query;
      setFilter({
        search: flexSearch,
        queryParams,
        quickFilter
      });
      setQuery(query);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const initialOverviewCount = {
    all: 0,
    toBeProcessed: 0
  };

  const filterTags: JSX.Element[] = [];

  if (filter.queryParams.createdStartDate || filter.queryParams.createdEndDate) {
    filterTags.push(
      <FilterTag
        type="period"
        value={[filter.queryParams.createdStartDate, filter.queryParams.createdEndDate]}
        removeFilter={() => {
          setValue('createdStartDate', undefined);
          setValue('createdEndDate', undefined);
        }}
        key="period-between.tag"
        label={getTranslation('general.period')}
      />
    );
  }

  return (
    <form>
      <FilterBarNew
        tags={[filterTags]}
        leftFilters={<LeftFilters setValue={setValue} getValues={getValues} overviewCount={initialOverviewCount} />}
        rightFilters={<RightFilters setValue={setValue} getValues={getValues} />}
        searchPlaceHolder={getTranslation('meter.search_placeholder')}
        searchDebounce={500}
      />
    </form>
  );
};

export default observer(TransactionsFilterBar);
