import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import CommitSection from '../../components/config/commit-section';
import { CrumbHeader } from '../../components/Header';
import ZFRouter from '../../components/Router/ZFRouter';
import { SuspenseSpinner } from '../../components/suspense';
import { useStore } from '../../hooks/useStore';
import Route from '../route';
import AdvanceAmountCard from './cards/AdvanceAmountCard';
import CustomerPortalLink from './cards/CustomerPortalLink';
import EmailCommunicationCard from './cards/EmailCommunicationCard';
import InvoicingCard from './cards/InvoicingCard';

type Props = {
  location: Location;
  navigate: (href: string) => void;
};

const CustomerPortalConfig = (props: Props) => {
  const { navigate, location } = props;
  const { integrationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { portalSettingsForm, initPortalConfig } = integrationStore.customerPortalStore;

  const [tabItems] = useState<TabItemType[]>([
    {
      title: getTranslation('general.general'),
      id: 'portal-general-tab',
      isDefault: true
    }
  ]);

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  useEffect(() => {
    initPortalConfig();
  }, []);

  const splitUrl = location.href.split('/');

  const GeneralTab = observer(() => (
    <SuspenseSpinner>
      <CommitSection
        handleCancel={portalSettingsForm?._reset}
        handleSave={portalSettingsForm?.save}
        isDirty={portalSettingsForm?._isDirty}
      />
      <AsyncVarSpinner variables={[portalSettingsForm]}>
        <CardsContainer>
          <CustomerPortalLink />
          <AdvanceAmountCard />
          <EmailCommunicationCard />
          <InvoicingCard />
        </CardsContainer>
      </AsyncVarSpinner>
    </SuspenseSpinner>
  ));

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="cog" text={getTranslation('portal_config')} />
          </BreadCrumb>
        }
      />
      <TabContainer
        onSelectTab={setActiveTab}
        entityId={getTranslation('portal.portal')}
        selectedTab={splitUrl[splitUrl.length - 1]}
        tabItems={tabItems}
      >
        <ZFRouter>
          <Route Component={GeneralTab} path="portal-general-tab" default />
        </ZFRouter>
      </TabContainer>
    </>
  );
};

export default observer(CustomerPortalConfig);
