import { observer } from 'mobx-react-lite';
import React from 'react';

import { billingItemTariffCalculationType } from '@zf/api-types/enums';
import {
  BillingTariffType,
  TieredTariffCalculationTypeParametersType,
  UnitPriceTariffCalculationTypeParametersType,
  VolumeTariffCalculationTypeParametersType
} from '@zf/api-types/product';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatExpression, formatMoney } from '@zf/utils/src/number';

import { useStore } from '../../../../hooks/useStore';
import css from './billing-tariff.module.scss';

type Props = {
  tariff: BillingTariffType;
};

const CalculationTypeParameters = (props: Props) => {
  const { tariff } = props;

  const { applicationStore } = useStore();
  const { culture } = applicationStore;

  switch (tariff.calculationParameters.type) {
    case billingItemTariffCalculationType.unitprice: {
      const parameters = tariff.calculationParameters as UnitPriceTariffCalculationTypeParametersType;

      return parameters.formulaBased ? (
        <div className={css['formula']}>
          <Paragraph bold>{formatExpression(parameters.expression, culture)}</Paragraph>
        </div>
      ) : (
        <div className={css['unit-tariff']}>
          <Paragraph bold>{formatMoney(parameters.unitTariff, culture)}</Paragraph>
        </div>
      );
    }
    case billingItemTariffCalculationType.volume: {
      const parameters = tariff.calculationParameters as VolumeTariffCalculationTypeParametersType;

      return (
        <>
          {parameters.slices.map((s, index) => {
            const next = parameters.slices[index + 1];

            return (
              <div key={`${tariff.id}-${parameters.type}-${index}`} className={css['tier']}>
                <Paragraph bold>{next ? `${s.from} - ${next.from - 1}` : `≥ ${s.from}`}</Paragraph>
                <Paragraph bold>
                  {s.formulaBased ? formatExpression(s.expression, culture) : formatMoney(s.unitTariff, culture)}
                </Paragraph>
              </div>
            );
          })}
        </>
      );
    }
    case billingItemTariffCalculationType.tiered:
    case billingItemTariffCalculationType.stairstep: {
      const parameters = tariff.calculationParameters as TieredTariffCalculationTypeParametersType;

      return (
        <>
          {parameters.slices.map((s, index) => {
            const next = parameters.slices[index + 1];

            return (
              <div key={`${tariff.id}-${parameters.type}-${index}`} className={css['tier']}>
                <Paragraph bold>{next ? `${s.from} - ${next.from - 1}` : `≥ ${s.from}`}</Paragraph>
                <Paragraph bold>
                  {s.formulaBased ? formatExpression(s.expression, culture) : formatMoney(s.unitTariff, culture)}
                </Paragraph>
              </div>
            );
          })}
        </>
      );
    }
  }
};

export default observer(CalculationTypeParameters);
