import React, { useEffect, useState } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { ContractType } from '@zf/api-types/master-data/contract';
import { DropdownProps, DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useStore } from '../../../../hooks/useStore';
import { METHODS } from '../../../../utils/request';
import Dropdown from '../../../Lang/Dropdown';

export type Props<Q> = {
  id: string;
  selectedValue: string;
  queryParams?: Q;
  isInline?: boolean;
  renderTopLvl?: boolean;
  onFocus?: (step: string) => void;
  onChange: (values: ContractType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<ContractType>>(Dropdown);

export default function ContractsDropdown<Q>(props: Props<Q>) {
  const { id, selectedValue, queryParams, isInline = false, renderTopLvl = true, onFocus, onChange } = props;
  const { applicationStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;

  const [dropdownItems, setDropdownItems] = useState<DropdownValuesType<ContractType>[]>();

  useEffect(() => {
    sendRequest<PagedResponseType<ContractType>>({
      request: {
        method: METHODS.GET,
        endpoint: '/md/contracts/',
        query: {
          ...queryParams
        }
      }
    }).then((res) => {
      setDropdownItems(
        res.data.results.map((c) => {
          return {
            value: c,
            text: c.contractNumber
          };
        })
      );
    });
  }, [queryParams]);

  if (!dropdownItems) {
    return isInline ? (
      <InlineDropdown
        id={id}
        onChange={onChange}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    ) : (
      <Dropdown
        id={id}
        onChange={onChange}
        placeholder={getTranslation('contracts.contract')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
        renderTopLvl={renderTopLvl}
      />
    );
  }

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={dropdownItems}
      selectedValues={[selectedValue || '']}
      onFocus={onFocus}
      renderTopLvl={renderTopLvl}
    />
  ) : (
    <Dropdown
      id={id}
      onChange={onChange}
      placeholder={getTranslation('contracts.contract')}
      values={dropdownItems}
      selectedValues={[selectedValue || '']}
      onFocus={onFocus}
      renderTopLvl={renderTopLvl}
    />
  );
}
