import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { utilityType } from '@zf/api-types/enums';
import { ParameterTypesType } from '@zf/api-types/general';

import { EnumKeyType } from '../../../../app-context/hooks/use-enum';
import { ICON_COLORS } from '../../../../constants/icons';
import { DetailLine } from '../../../../design-system/ComponentSets';
import { Icon, Paragraph } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  parameterTypes: ParameterTypesType[];
  parameters: Record<string, any>;
};

// Not compatible with arrays yet!
const TextGenerator = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { parameterTypes, parameters } = props;

  const matchedType = parameterTypes.find((t) => t.classType.toLowerCase() === parameters.type.toLowerCase());

  const displayValue = (propertyName: string, propertyType: string, value: string | number | boolean) => {
    if (value !== null && typeof value !== 'undefined') {
      if (propertyName === 'customEntityPropertyTypeId' || propertyName === 'consumptionUnitTypeId') {
        return null;
      }

      if (typeof value === 'boolean') {
        return <Icon name={value ? 'check' : 'cancel'} color />;
      }

      if (propertyName === 'utilityType' && typeof value === 'string') {
        return value !== utilityType.none ? (
          <Icon
            id={`${propertyName}-${propertyType}-${value}-${new Date().getMilliseconds()}`}
            name={value}
            title={getEnumTranslation('utilityType', value)}
            color={ICON_COLORS[value]}
          />
        ) : (
          <Paragraph>{getTranslation('general.none')}</Paragraph>
        );
      }

      if (propertyType.startsWith('enum')) {
        const splitted = propertyType.split(':');
        return <Paragraph>{getEnumTranslation(splitted[1] as EnumKeyType, value as string)}</Paragraph>;
      }
      return value;
    }

    return '';
  };

  if (matchedType) {
    return (
      <>
        {matchedType.properties.map((p, index) => {
          if (p.name === 'customEntityPropertyTypeId' || p.name === 'consumptionUnitTypeId') {
            return null;
          }

          if (parameters[p.name] !== null) {
            return (
              <Fragment key={`${matchedType.classType}-${p.name}-${index}`}>
                <DetailLine label={getTranslation(`parameters.${p.name}`)}>
                  {displayValue(p.name, p.propertyType, parameters[p.name])}
                </DetailLine>
              </Fragment>
            );
          }
          return null;
        })}
      </>
    );
  }

  return null;
};

export default observer(TextGenerator);
