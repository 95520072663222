import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '../../design-system/Components';
import { Icon } from '../Icon';
import css from './transparent-btn.module.scss';

type Props = {
  id: string;
  title: string;
  iconType: string;
  className?: string;
  color?: string;
  size?: 'big' | 'small';
  onTrigger: (() => void) | undefined;
};

/**
 * Use case for completeness only, do not use elsewhere!
 */
export default function TransparentButton(props: Props) {
  const { id, className, title, iconType, color, onTrigger, size = 'big' } = props;

  return (
    <BaseButton
      id={id}
      style={color ? { color: color, borderColor: color } : {}}
      className={classNames(
        css['tenant-btn'],
        {
          [css['small']]: size === 'small',
          [css['no-trigger']]: !onTrigger,
          [css['grid-view']]: !!iconType
        },
        className
      )}
      onClick={() => {
        if (onTrigger) onTrigger();
      }}
    >
      {iconType && <Icon type={iconType} color={color} />}
      <div>{title}</div>
    </BaseButton>
  );
}
