import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import EmptyColumn from '../../../../components/Column/EmptyColumn';
import { WrappableVirtualizedList } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import VirtualProduct from './product/VirtualProduct';

type Props = {
  billingItemId?: string;
};

const ProductsColumnContent = (props: Props) => {
  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { products, initProducts } = productConfigStore.productsStore;
  const { billingItemId } = props;

  useEffect(() => {
    initProducts();
  }, []);

  const items = products.map((p, index) => {
    return {
      key: `${p.id}-${index}`,
      index,
      product: p,
      billingItemId: billingItemId
    };
  });

  return products.length > 0 ? (
    <WrappableVirtualizedList items={items} ItemComponent={VirtualProduct} columnCount={1} />
  ) : (
    <EmptyColumn image="attributes2" title="Products" text={getTranslation('product_config.empty_products')} />
  );
};

export default observer(ProductsColumnContent);
