import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { forwardRef, Ref, useCallback, useImperativeHandle, useReducer } from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { MIN_DATE } from '@zf/utils/src/date';

import CheckBox from '../../../../../../../components/input/CheckBox';
import { DialogClickRef } from '../../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../../../events/notification-events';
import { useStore } from '../../../../../../../hooks/useStore';
import AssignToTransactionSection, {
  OutgoingTransactionType_
} from '../../../../../actions/dialogs/assign-to-transaction-section';
import RelatedDetailsForm from '../../logic/RelatedDetailsForm';
import css from './change-payment-method-dialog.module.scss';

export type TransactionDialogUCType = 'request' | 'refund';

type Props = {
  invoiceId: string;
  relatedDetailsForm: RelatedDetailsForm;
};

type State = {
  assignTotransaction: boolean;
  transactionType: OutgoingTransactionType_;
  collectionDate: Moment;
  overrideCollectionDate: boolean;
};

const ChangePaymentMethodDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { invoiceId, relatedDetailsForm } = props;
  const { invoiceStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { assignToOutgoingBankingTransaction } = invoiceStore;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    assignTotransaction: false,
    transactionType: 'existing',
    collectionDate: moment(MIN_DATE),
    overrideCollectionDate: false
  });

  const assignInvoiceToTransaction = async () => {
    try {
      await assignToOutgoingBankingTransaction(
        {
          addToNewOutgoingBankingTransactions: state.transactionType === 'new',
          overriddenCollectionDate: state.collectionDate ? state.collectionDate.toISOString() : null,
          invoiceIds: [invoiceId],
          allApplicable: false
        },
        invoiceId
      );

      notify.success({
        content: getTranslation('actions.invoice.add_transaction_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('actions.invoice.add_transaction_fail'),
        error: e
      });
    }
  };

  useImperativeHandle(ref, () => ({
    async onClick() {
      await relatedDetailsForm.save();
      if (state.assignTotransaction) {
        await assignInvoiceToTransaction();
      }
    }
  }));

  const setAssignTotransaction = useCallback(() => {
    setState({ assignTotransaction: !state.assignTotransaction });
  }, [state.assignTotransaction, setState]);

  const setTransactionType = useCallback(
    (newType: string) => {
      setState({ transactionType: newType as OutgoingTransactionType_ });
    },
    [setState]
  );

  const setCollectionDate = useCallback((newDate: Moment) => {
    setState({ collectionDate: newDate });
  }, []);

  const setOverrideCollectionDate = useCallback((val: boolean) => {
    setState({ overrideCollectionDate: val });
  }, []);

  return (
    <div className={css['change-payment-method-dialog']}>
      <Paragraph>{getTranslation('actions.invoice.add_to_transaction_descr')}</Paragraph>
      <InputContainer className={css['add-to-transaction-checkbox']}>
        <CheckBox
          id="add_to_transaction_checkbox"
          checked={state.assignTotransaction}
          onChange={setAssignTotransaction}
        >
          {getTranslation('invoice.payment_method_changed_paragraph')}
        </CheckBox>
      </InputContainer>
      {state.assignTotransaction && (
        <AssignToTransactionSection
          selectedType={state.transactionType}
          collectionDate={state.collectionDate}
          overrideCollectionDate={state.overrideCollectionDate}
          setOverrideCollectionDate={setOverrideCollectionDate}
          setSelectedType={setTransactionType}
          setCollectionDate={setCollectionDate}
          border
        />
      )}
    </div>
  );
});

export default observer(ChangePaymentMethodDialog);
