import { consumerGroupOrigin } from '@zf/api-types/enums';
import { ConsumptionGroupType } from '@zf/api-types/metering/consumptions';

import RootStore from '../../../app-context/stores';
import CrudService from '../../../components/units/List/logic/CrudService';

export default class MeterConfigStore {
  public rootStore: RootStore;

  public customCrudService: CrudService<ConsumptionGroupType>;
  public builtInApiService: CrudService<ConsumptionGroupType>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.customCrudService = new CrudService('/fct/consumerGroups', rootStore, {
      origin: consumerGroupOrigin.manual
    });
    this.builtInApiService = new CrudService('/fct/consumerGroups', rootStore, {
      origin: consumerGroupOrigin.systemgenerated
    });
  }
}
