import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import AddOutgoingBankingTransactionDialog from '../../../actions/outgoing-banking-transactions/AddOutgoingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refresh: () => void;
};

export default function AddOutgoingBankingTransactionListPageButton(props: Props) {
  const { refresh } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit, validationRef } = useDialog();

  return (
    <DropdownAction
      id="outgoing_banking_transaction.add"
      icon="add"
      dialogTitle={i18n.getTranslation('actions.banking_transaction.add_outgoing')}
      dialogContent={
        <AddOutgoingBankingTransactionDialog refresh={refresh} ref={clickRef} validationRef={validationRef} />
      }
      validationRef={validationRef}
      dialogLocation="right"
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('general.add')}
    />
  );
}
