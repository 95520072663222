import { observer } from 'mobx-react';
import React from 'react';

import { incomingMutationType } from '@zf/api-types/enums';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { colors } from '@zf/utils/src/color';

import { Description } from '../../../../../../design-system/ComponentSets';
import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import css from './mutations-coachmark.module.scss';

const MutationsCoachMark = () => {
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  return (
    <div className={css['mutations-coachmark']}>
      <Paragraph color={colors['silver-600']} bold>
        {getTranslation('incoming_mutations.bank_transfers')}
      </Paragraph>
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.incomingtransfer)}
        description={getTranslation('coachmark.incomingtransfer')}
      />
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.outgoingtransfer)}
        description={getTranslation('coachmark.outgoingtransfer')}
      />

      <HorizontalDivider />

      <Paragraph color={colors['silver-600']} bold>
        {getTranslation('incoming_mutations.requests')}
      </Paragraph>
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.paymentrequesttransactionconfirmation)}
        description={getTranslation('coachmark.paymentrequesttransactionconfirmation')}
      />
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.paymentrequestmutationconfirmation)}
        description={getTranslation('coachmark.paymentrequestmutationconfirmation')}
      />
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.paymentrequestreversal)}
        description={getTranslation('coachmark.paymentrequestreversal')}
      />

      <HorizontalDivider />

      <Paragraph color={colors['silver-600']} bold>
        {getTranslation('payments.refunds')}
      </Paragraph>
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.refundtransactionconfirmation)}
        description={getTranslation('coachmark.refundtransactionconfirmation')}
      />
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.refundmutationconfirmation)}
        description={getTranslation('coachmark.refundmutationconfirmation')}
      />
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.refundreversal)}
        description={getTranslation('coachmark.refundreversal')}
      />

      <HorizontalDivider />

      <Paragraph color={colors['silver-600']} bold>
        {getTranslation('general.other')}
      </Paragraph>
      <Description
        title={getEnumTranslation('incomingMutationType', incomingMutationType.unknown)}
        description={getTranslation('coachmark.unknown')}
      />
    </div>
  );
};

export default observer(MutationsCoachMark);
