import moment from 'moment';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { aggregationFrequency } from '@zf/api-types/enums';
import { ExternalChannelType, ViewModeType } from '@zf/api-types/master-data/meter';
import { CompareMonthType } from '@zf/stella-react/src/atoms/Date/DateInput/month-input';
import { CompareGraphType, ConsumptionGraphType, OutputQueryParamsType } from '@zf/api-types/graph';

export type OutputType = 'measurements' | 'consumptions' | string;

export type OutputCtxState = {
  viewMode: ViewModeType;
  outputType: OutputType;
  channels: ExternalChannelType[];
  consumptionChannels: ExternalChannelType[];
  selectedListModeChannel: string;
  selectedGraphChannels: string[];
  graphHeaderSelection: string[];
  queryParams: OutputQueryParamsType;
  graphIsLoading: boolean;
  graphValues: ConsumptionGraphType[];
  isCompareMode: boolean;
  compareYears: number[];
  compareMonths: CompareMonthType[];
  compareGraphValues: CompareGraphType[];
  timeStamp: string;
  minDomain: number;
  maxDomain: number;
};

type Action = { type: 'UPDATE'; newState: Partial<OutputCtxState> };

const initialState: OutputCtxState = {
  viewMode: 'list',
  outputType: 'measurements',
  channels: [],
  selectedListModeChannel: '',
  consumptionChannels: [],
  selectedGraphChannels: [],
  graphHeaderSelection: [],
  queryParams: {
    groupByPeriod: aggregationFrequency.none,
    startDateTime: moment().subtract(1, 'year'),
    endDateTime: moment()
  },
  graphValues: [],
  graphIsLoading: false,
  isCompareMode: false,
  compareYears: [moment().subtract(1, 'year').year(), moment().year()],
  compareMonths: [
    { year: moment().subtract(1, 'year').year(), month: 1 },
    { year: moment().year(), month: 1 }
  ],
  compareGraphValues: [],
  timeStamp: '',
  minDomain: 0,
  maxDomain: 0
};

const reducer = (state: OutputCtxState, action: Action): OutputCtxState => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.newState };

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider: OutputProvider, useTracked, useUpdate: useDispatch } = createContainer(useValue);
