import React from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import SetWaitingDialog from '../../../actions/tasks/SetWaitingDialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function SetWaitingListPageButton(props: Props) {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('moverequest');
  const selectedRows: TaskRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<MoveRequestType>('moverequest');
  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled = selectedRows.length === 0 || !selectedRows.every((r) => r.__entity.status === moveRequestStatus.new);

  return (
    <DropdownAction
      id="tasks.setwaiting"
      icon="clock"
      dialogTitle={i18n.getTranslation('tasks.set_waiting')}
      dialogContent={
        <SetWaitingDialog
          {...props}
          ref={clickRef}
          validationRef={validationRef}
          selectedRows={selectedRows}
          setUpdatedRows={setUpdatedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.submit')}
    />
  );
}
