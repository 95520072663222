export function abbreviateNumber(value: number) {
  let newValue = value.toString();

  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor(('' + value).length / 3);
    let shortValue = '';

    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision)
      ).toString();
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }

    const floatValue = parseFloat(shortValue);
    if (floatValue % 1 !== 0) shortValue = floatValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

export const roundNumber = (number: number, decimalPlaces?: number) => {
  if (!decimalPlaces) {
    decimalPlaces = 2;
  }

  return Number(Math.round(Number(number + 'e' + decimalPlaces)) + 'e' + decimalPlaces * -1);
};

export const makePositive = (num: number) => {
  return num < 0 ? -1 * num : num;
};

export const onlyDigits = (num: string | null) => {
  if (!num) return '';

  return num.replace(/\D/g, '');
};
