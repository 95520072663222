import React from 'react';
import ExportJobTabContainer from './export-job-tab-container';
import { useAppContext } from '../../../app-context';
import { CrumbHeader } from '../../../components/Header';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../components/BreadCrumb';
import useExportJobDetail from '../hooks/useExportJobDetail';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

export default function ExportJob(props: Props) {
  const { urlParams, navigate } = props;
  const { exportJob, status, navigator } = useExportJobDetail(urlParams.exportJobId);
  const { tenantReducer, i18n } = useAppContext();

  if (!exportJob) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb
              icon="list"
              text={i18n.getTranslation('export_jobs.export_jobs')}
              url={`${tenantReducer.state.rootUrl}/export-jobs`}
            />
            <CrumbDivider />
            <Crumb icon="export-job" text={exportJob.identification} />
          </BreadCrumb>
        }
      />
      <ExportJobTabContainer navigate={navigate} status={status} navigator={navigator} exportJob={exportJob} />
    </>
  );
}
