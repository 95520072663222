import React from 'react';

import { CollectionCaseType } from '@zf/api-types/collection-case';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../../app-context';
import IconParagraph from '../../../../components/Icon/IconParagraph';
import { ICON_COLORS } from '../../../../constants/icons';
import { Link } from '../../../../design-system/Components';

type Props = {
  collCase: CollectionCaseType;
};

export default function CollectionCaseDetailsCard(props: Props) {
  const { collCase } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  return (
    <Card id="collection-case-details">
      <CardHeader>{i18n.getTranslation('general.details')}</CardHeader>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{i18n.getTranslation('customer.customer_id')}</Label>
        </CardItem>
        <CardItem width="6" justifyContent="end">
          <Link
            id={`customer-${collCase.debtor.customerId}`}
            url={`${rootUrl}/customers/${collCase.debtor.customerId}`}
          >
            {collCase.debtor.customerAccountNumber}
          </Link>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('customer.customer_name')}</Label>
        </CardItem>
        <CardItem width="6" justifyContent="end">
          <Link
            id={`customer-name-${collCase.debtor.customerId}`}
            url={`${rootUrl}/customers/${collCase.debtor.customerId}`}
          >
            {collCase.debtor.displayName}
          </Link>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('collection_case.labels.manual_intervention_required')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          {collCase.manualInterventionRequired ? (
            <IconParagraph iconType="exclamation" color={ICON_COLORS['exclamation']}>
              {i18n.getTranslation('general.yes')}
            </IconParagraph>
          ) : (
            <Paragraph textAlign="right">{i18n.getTranslation('general.no')}</Paragraph>
          )}
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('invoice.invoices')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right">{collCase.referencedTransactions.length}</Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('collection_case.labels.open_amount_incl_vat')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right">
            {formatMoney(Math.abs(collCase.totalOpenAmountInclVAT), i18n.culture)}
          </Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('collection_case.labels.payment_reference')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph textAlign="right">{collCase.paymentReference}</Paragraph>
        </CardItem>
      </CardBody>
    </Card>
  );
}
