import { observer } from 'mobx-react';
import React from 'react';

import { TabSlider } from '../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../design-system/Components/TabSlider/Tab';
import { useStore } from '../../../../hooks/useStore';
import QuickFilterStore from '../../List/logic/QuickFilterStore';
import FilesStore, { EntityAttachmentQuickFilters } from '../logic/FilesStore';

type Props<T> = {
  filesStore: FilesStore<T>;
  quickFilterStore: QuickFilterStore<EntityAttachmentQuickFilters>;
  customerSpecific: boolean;
};

const OverviewCounts = <T,>(props: Props<T>) => {
  const { filesStore, quickFilterStore, customerSpecific } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { portalEnabled } = filesStore;
  const { overviewCounts, activeQuickFilter, setActiveQuickFilter } = quickFilterStore;

  const tabItems: SelectionTabItemType<EntityAttachmentQuickFilters>[] = [
    { id: 'all', count: overviewCounts?.all, title: getTranslation('general.all') }
  ];

  if (portalEnabled && customerSpecific) {
    tabItems.push({
      id: 'visibleForCustomer',
      count: overviewCounts?.visibleForCustomer,
      title: getTranslation('entity_attachment.visible_to_customer')
    });
  }

  return (
    <TabSlider
      id="attachments-quick-filter"
      tabItems={tabItems}
      selectedTabItem={activeQuickFilter}
      setSelectedItem={setActiveQuickFilter}
    />
  );
};

export default observer(OverviewCounts);
