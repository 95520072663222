import FloatInput from 'components/input/FloatInput';
import SimpleDropdown from 'components/Lang/SimpleDropdown';
import { Paragraph } from 'design-system/Foundation';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { AdvanceAmountLimitType } from '@zf/api-types/enums';
import { AdvanceCalculationParameters } from '@zf/api-types/parameters';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { useStore } from '../../../hooks/useStore';
import css from './margin-inputs.module.scss';

type Props = {
  advanceCalculationParameters: AdvanceCalculationParameters;
  automaticAdvanceCalculationEnabled: boolean;
  setAdvanceCalculationParam: (value: Partial<AdvanceCalculationParameters>) => void;
};

const MarginInputs = (props: Props) => {
  const { advanceCalculationParameters, automaticAdvanceCalculationEnabled, setAdvanceCalculationParam } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  const limitTypeValues = useRef(
    getEnum<AdvanceAmountLimitType>('advanceAmountLimitType').filter(
      (val) => val.value !== AdvanceAmountLimitType.fixed // Not supported atm
    )
  );

  const { advanceLimitLowerThreshold, advanceLimitUpperThreshold, advanceLimitType } = advanceCalculationParameters;

  return (
    <FlexElements className={css['margin-inputs']} gap="8">
      <SimpleDropdown
        id="limit-type"
        onChange={(val) => setAdvanceCalculationParam({ advanceLimitType: val[0] || null })}
        values={limitTypeValues.current}
        selectedValues={[advanceLimitType]}
        placeholder={getTranslation('billing_parameters.limit_type')}
        error={automaticAdvanceCalculationEnabled && !advanceLimitType}
      />

      <Paragraph>-</Paragraph>

      <FlexElements gap="8">
        <FloatInput
          id="min-value"
          value={advanceLimitLowerThreshold}
          onChange={(advanceLimitLowerThreshold) => setAdvanceCalculationParam({ advanceLimitLowerThreshold })}
          postfix="%"
          placeholder={getTranslation('billing_parameters.lower')}
          error={
            (automaticAdvanceCalculationEnabled &&
              (typeof advanceLimitLowerThreshold === 'undefined' || isNaN(advanceLimitLowerThreshold))) ||
            advanceLimitLowerThreshold > 100 ||
            advanceLimitLowerThreshold <= 0
          }
        />

        <FloatInput
          id="max-value"
          value={advanceLimitUpperThreshold}
          onChange={(advanceLimitUpperThreshold) => setAdvanceCalculationParam({ advanceLimitUpperThreshold })}
          postfix="%"
          placeholder={getTranslation('billing_parameters.higher')}
          error={
            (automaticAdvanceCalculationEnabled &&
              (typeof advanceLimitUpperThreshold === 'undefined' || isNaN(advanceLimitUpperThreshold))) ||
            advanceLimitUpperThreshold > 100 ||
            advanceLimitUpperThreshold <= 0
          }
        />
      </FlexElements>
    </FlexElements>
  );
};

export default observer(MarginInputs);
