import {
  FormulaFunctionExceptionType,
  OutputChannelType,
  PropertyMeteringConfigurationType
} from '@zf/api-types/property-metering-configuration';

import { useTracked } from '../metering-context/context';

export default function useMeteringData() {
  const [state, dispatch] = useTracked();

  // Set actions
  const setSelectedOutputChannel = (chann: OutputChannelType) => {
    dispatch({ type: 'SET_SELECTED_OUTPUT_CHANNEL', channel: chann });
  };

  const setCalculationErrors = (calculationErrors: FormulaFunctionExceptionType[]) => {
    dispatch({ type: 'SET_CALC_ERRORS', calculationErrors });
  };

  const setPropertyMeteringConfiguration = (newConfig: PropertyMeteringConfigurationType) => {
    dispatch({ type: 'SET_PROPERTY_METERING_CONFIG', config: newConfig, isFetch: false });
  };

  return {
    propertyMeteringConfiguration: state.propertyMeteringConfiguration,
    selectedOutputChannel: state.selectedOutputChannel,
    calculationErrors: state.calculationErrors,
    setSelectedOutputChannel,
    setCalculationErrors,
    setPropertyMeteringConfiguration
  };
}
