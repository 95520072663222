import React from 'react';

import CardEmptyBody from '../Card/CardEmptyBody';
import EmptyStateImg from '../Image/EmptyState/EmptyStateImg';
import css from './chart-style.module.scss';

type Props = {
  title: string;
};

export default function EmptyChartState(props: Props) {
  const { title } = props;

  return (
    <CardEmptyBody className={css['empty-chart']} image={<EmptyStateImg image="graph" title={title} />} title={title} />
  );
}
