import React from 'react';

import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteTaskDialog from '../../../actions/tasks/delete-task-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  task: MoveRequestType;
};

export default function DeleteTaskListItem(props: Props) {
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const deleteText = i18n.getTranslation('general.delete');

  return (
    <DropdownAction
      id="tasks.delete"
      icon="trashcan"
      dialogTitle={deleteText}
      dialogContent={<DeleteTaskDialog {...props} ref={clickRef} />}
      onSubmit={onSubmit}
      buttonPositive={deleteText}
      dialogType="danger"
    />
  );
}
