import { StepValidationType } from 'app-context/stores/util/WizardNavigator';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import css from './wizard-step.module.scss';

type Props = {
  // This headerId is linked to the corresponding WizardSectionHeader id
  headerId: string;
  text: string;
  stepValidation: StepValidationType | undefined;
  isActive: boolean;
  setIsActive: () => void;
};

const WizardStep = (props: Props) => {
  const { text, headerId, isActive, stepValidation, setIsActive } = props;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setIsActive();
  };

  return (
    <FlexElements flexDirection="column" gap="12">
      <a
        className={classNames(css['wizard-step'], { [css['active']]: isActive })}
        href={`#${headerId}`}
        onClick={handleClick}
      >
        {text}
      </a>

      <ul className={css['errors']}>
        {stepValidation &&
          Object.keys(stepValidation).map((field, index) => {
            const validation = stepValidation[field];

            if (!validation.isValid) {
              return <li key={`${field}-${index}`}>{validation.validationMessage}</li>;
            }

            return null;
          })}
      </ul>
    </FlexElements>
  );
};

export default observer(WizardStep);
