import React from 'react';

import { useAppContext } from '../../../app-context';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../components/BreadCrumb';
import { CrumbHeader } from '../../../components/Header';
import { formatCustomerName } from '../../../utils/customer';
import { isEmptyObject } from '@zf/utils/src/object';
import useTaskDetail from '../hooks/useTaskDetail';
import TaskTabContainer from './TaskTabContainer';

type Props = {
  urlParams: { [key: string]: string };
  location: Location;
  navigate: (url: string) => void;
};

export default function Task(props: Props) {
  const { navigate, urlParams } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { task, status, navigator, setTask } = useTaskDetail(urlParams.taskId);

  if (!task || isEmptyObject(task)) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="list" text={i18n.getTranslation('nav.tasks')} url={`${tenantReducer.state.rootUrl}/tasks`} />
            <CrumbDivider />
            <Crumb
              icon="task"
              text={
                Object.keys(task).length > 0
                  ? task.customerDetails.firstName || task.customerDetails.lastName
                    ? formatCustomerName(task.customerDetails.lastName, task.customerDetails.firstName)
                    : i18n.getTranslation(`tasks.${task.moveRequestType}_for_existing`)
                  : ''
              }
            />
          </BreadCrumb>
        }
      />
      <TaskTabContainer task={task} status={status} navigator={navigator} navigate={navigate} setTask={setTask} />
    </>
  );
}
