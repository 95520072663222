import { DANGER, INFO, SUCCESS, WARNING } from './color';

import { colors } from '@zf/utils/src/color';

export const ICONS: Record<string, string> = {
  // Utility types
  heat: 'service-heat',
  hottapwater: 'service-hottapwater',
  cooling: 'service-cooling',
  services: 'service-services',
  electricity: 'service-electricity',
  gas: 'service-gas',
  water: 'service-water',
  rainwater: 'service-rainwater',
  /////////////////

  comskipifsdd: 'skip',
  servicelocation: 'location',
  person: 'customer',
  customergroup: 'customer-group',
  organization: 'organisation',
  draft: 'draft',
  created: 'invoice',
  approved: 'approve',
  sent: 'paper-plane',
  paid: 'check',
  unpaid: 'cancel',
  generated: 'generated',
  finished: 'check',
  notfinished: 'cancel',
  signed: 'approve',
  cancelled: 'skip',
  terminated: 'reject',
  email: 'mail',
  telephone: 'phone',
  mobiletelephone: 'mobile',
  sct: 'money',
  sdd: 'credit-card',
  importjob: 'import-job',
  ra_todo: 'exclamation',
  ra_warning: 'exclamation',
  attention: 'warning',
  comnone: 'not-send',
  commanual: 'enveloppe',
  comemail: 'at',
  exportjob: 'package',
  billingitem: 'invoice',
  dataexport: 'box-up',
  customermandate: 'invoice', // TODO
  organisationscenario: 'cog', // TODO
  communicationentry: 'text-boxes',
  organisation: 'organisation',
  dataimport: 'box-down',
  asset: 'leaf',
  bankaccount: 'money', // TODO
  billingtariff: 'invoice',
  costcomponent: 'cube', // TODO
  costcomponentvalue: 'cube', // TODO
  model: 'channels',
  numbersequence: 'cube',
  billingparameters: 'invoice', // TODO
  product: 'invoice',
  projectlocation: 'location',
  taxcode: 'money',
  template: 'leaf',
  billingrelation: 'invoice', // TODO
  dataimportfileformat: 'cubes',
  dataexportfileformat: 'cubes',
  notsent: 'cancel',
  skipped: 'skip',
  advskipped: 'skip',
  waiting: 'clock',
  inputmissing: 'exclamation',
  closed: 'check',
  manuallycorrected: 'exclamation',
  inFuture: 'clock',
  collectioncase: 'dunning',
  missing: 'questionmark',
  propertygroup: 'property-groups',
  generationfailed: 'generated',
  tosend: 'generated',
  meteringissue: 'alert-circle',
  mutingrule: 'alert-x'
};

export const ICON_COLORS: Record<string, string> = {
  // Utility types
  heat: colors['red-600'],
  hottapwater: colors['red-700'],
  cooling: colors['blue-400'],
  electricity: colors['yellow-600'],
  gas: colors['green-600'],
  services: colors['graphite'],
  water: colors['blue-600'],
  rainwater: colors['blue-700'],
  /////////////////

  draft: colors['blue-700'],
  sent: SUCCESS,
  generated: SUCCESS,
  generationfailed: DANGER,
  paid: SUCCESS,
  unpaid: DANGER,
  finished: SUCCESS,
  notfinished: DANGER,
  approved: SUCCESS,
  signed: SUCCESS,
  cancelled: WARNING,
  terminated: DANGER,
  exclamation: DANGER,
  todo: INFO,
  warning: WARNING,
  supplied: SUCCESS,
  unsupplied: WARNING,
  removed: DANGER,
  requested: WARNING,
  installed: SUCCESS,
  trash: DANGER,
  check: SUCCESS,
  cancel: DANGER,
  attention: DANGER,
  ra_todo: INFO,
  ra_warning: WARNING,
  created: colors['blue-700'],
  waitingforexport: colors['blue-400'],
  processed: SUCCESS,
  error: DANGER,
  unverified: WARNING,
  valid: SUCCESS,
  processing: WARNING,
  failed: DANGER,
  gatheringdata: WARNING,
  reversed: WARNING,
  logo: DANGER,
  briefcase: DANGER,
  waiting: colors['blue-400'],
  gatheringinput: colors['blue-400'],
  inputmissing: DANGER,
  pendingcalculation: colors['blue-400'],
  calculating: colors['blue-400'],
  closed: SUCCESS,
  previousnotclosed: WARNING,
  manuallycorrected: DANGER,
  notsent: DANGER,
  skipped: WARNING,
  skip: DANGER,
  overdue: DANGER,
  advSkipped: SUCCESS,
  missing: WARNING,
  questionmark: WARNING,
  'meter-info': INFO,
  clock: colors['silver-600'],
  'supplied-services': INFO,
  meter: DANGER,
  active: SUCCESS,
  inactive: colors['red-600'],
  manuallyclosed: WARNING,
  automaticallyclosed: colors['orange-600'],
  invoice: INFO,
  allocated: SUCCESS,
  unallocated: DANGER,
  money: colors['silver-700'],
  none: colors['silver-700'],
  new: colors['silver-700'],
  pendingatcustomer: colors['yellow-600'],
  pendingatsupplier: colors['yellow-600'],
  rejected: colors['orange-600'],
  complete: SUCCESS,
  automaticallyDeleted: colors['silver-600'],
  manuallyDeleted: colors['silver-600'],
  'paper-plane': SUCCESS,
  toConfirm: DANGER,
  credited: SUCCESS,
  readytosend: colors['blue-700'],
  resolveissues: DANGER,
  dateexpired: colors['orange-600'],
  toconfirmbybank: colors['yellow-600'],
  readytoprocess: colors['blue-700'],
  matching: colors['orange-600'],
  ignored: colors['silver-600'],
  invalid: DANGER,
  failedtoclose: DANGER,
  failedtoreverse: DANGER,
  missingtranslation: DANGER,
  readyforpaymentcollection: colors['green-700'],
  stored: colors['blue-700'],
  resolved: colors['green-600'],
  unresolved: colors['orange-600'],
  muted: colors['silver-600']
};
