import { InfoBanner } from 'design-system/ComponentSets';
import { InfoBannerColor } from 'design-system/ComponentSets/InfoBanner/InfoBanner';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import React, { useEffect, useReducer } from 'react';
import { getDropDownTimeVals } from 'utils/meter';

import { dataFrequency, unitOfMeasure, utilityType } from '@zf/api-types/enums';
import { AddMeasurementRequestType, MeasurementPair, MeasurementType } from '@zf/api-types/master-data/meter';
import { MeteringValidationParameters } from '@zf/api-types/meter-config';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useDebounce from '@zf/hooks/src/useDebounce';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate, formatDateTime, HOURS } from '@zf/utils/src/date';
import { formatDecimal } from '@zf/utils/src/number';

import FloatInput from '../../../components/input/FloatInput';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import css from '../../style.module.scss';
import IssueLink from './IssueLink';
import { setChannel, setNextDate, setPreviousDate } from './measurement-timeline-util';

type Props = {
  mutationDateTime: Moment;
  currentFreq: dataFrequency;
  hoursDisabled: boolean;
  minutesDisabled: boolean;
  measurementPair: MeasurementPair | null | undefined;
  value: AddMeasurementRequestType;
  measurementIndex: number;
  meterIndex?: number;
  minutes?: string[];
  useCase?: 'add' | 'edit';
  isValidating?: boolean;
  measurement?: MeasurementType | undefined;
  dispatchValue: (value: Partial<AddMeasurementRequestType>) => void;
  setIsValidating?: (isValidating: boolean) => void;
  closeDialog?: () => void | undefined;
};

type BannerInfo = {
  visible: boolean;
  color: InfoBannerColor;
  info: string;
  invalid: boolean;
};

type State = {
  bannerInfo: BannerInfo;
  meteringValidationParameters: MeteringValidationParameters | null;
  organizationCfg: OrganizationConfigType | undefined;
  measurements: MeasurementType[] | undefined;
};

const MeasurementTimeLine = (props: Props) => {
  const {
    measurementPair,
    measurementIndex,
    hoursDisabled,
    minutesDisabled,
    minutes,
    currentFreq,
    mutationDateTime,
    meterIndex = 0,
    useCase = 'add',
    isValidating = false,
    measurement,
    dispatchValue,
    closeDialog = () => {},
    setIsValidating = () => {}
  } = props;

  const request = props.value;
  const { value, minute, hour, meter } = request;

  const { applicationStore, configStore, meterStore, organisationStore } = useStore();
  const { culture, tenantReducer, getTranslation, getEnumTranslation } = applicationStore;
  const { getOrganisationConfig } = organisationStore.organisationService;
  const { configService, getIsMeteringValidationRuleEnabled } = configStore;
  const { getMeteringValidationParameters } = configService;
  const { measurementService } = meterStore;
  const { validateMeasurement } = measurementService;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    bannerInfo: {
      visible: false,
      color: 'orange',
      info: '',
      invalid: false
    },
    meteringValidationParameters: null,
    organizationCfg: undefined,
    measurements: undefined
  });

  const setBannerInfo = (val: Partial<BannerInfo>) => setState({ bannerInfo: { ...state.bannerInfo, ...val } });

  let previousDate = setPreviousDate(measurementPair);
  let nextDate = setNextDate(measurementPair);
  let channel = setChannel(request);

  let UoM = '';

  if (channel && channel.unitOfMeasure !== unitOfMeasure.none) {
    UoM = getEnumTranslation('unitOfMeasure', channel.unitOfMeasure);
  }

  const isEditing = useCase === 'edit';

  useEffect(() => {
    Promise.all([
      getOrganisationConfig(tenantReducer.organization?.organizationId || '', tenantReducer.tenant?.id || ''),
      getMeteringValidationParameters()
    ])
      .then((res) => setState({ organizationCfg: res?.[0], meteringValidationParameters: res?.[1] }))
      .catch((error) => {
        notify.error({
          content: getTranslation('general.get_cfg_fail'),
          error
        });
      });
  }, []);

  useEffect(() => {
    validateTypedMeasurement(value);
  }, [measurementPair, meter, channel]);

  //VALIDATION

  const setDebounceCallback = useDebounce(500);

  const validateTypedMeasurement = (val: number | undefined | null) => {
    setBannerInfo({
      visible: true,
      color: 'blue',
      info: 'measurement_validation.validating',
      invalid: true
    });

    dispatchValue({ value: val });

    setIsValidating(true);

    if (
      typeof val === 'number' &&
      !isNaN(val) &&
      state.organizationCfg?.features.meteringValidationEnabled &&
      getIsMeteringValidationRuleEnabled('BaseMeteringValidationRuleDTO:ConsumptionNegative') &&
      meter &&
      channel &&
      channel?.utilityType !== utilityType.none &&
      measurementPair?.previousMeasurement?.value
    ) {
      setDebounceCallback(() => {

        let date;

        if(request.hour)
        {
          date = mutationDateTime.clone().hour(parseInt(request.hour));
        }
        else
        {
          date = mutationDateTime.clone();
        }

        validateMeasurement(date, request.meter?.id, request.channelId, val)
          .then((res) => {
            dispatchValue({ value: val, skipValidation: false, resolveIssuesManually: isEditing });

            setIsValidating(false);

            setBannerInfo({
              visible: true,
              color: res.isValid ? 'green' : 'orange',
              info: res.isValid ? 'measurement_validation.valid' : `meter_issues.error_${res.error}`,
              invalid: !res.isValid
            });
          })
          .catch((e) => {
            notify.error({
              content: 'meter.validation.validate_measurement_fail',
              error: e
            });
          });
      });
    } else {
      setIsValidating(false);

      dispatchValue({ value: val, skipValidation: false, resolveIssuesManually: isEditing });

      setBannerInfo({
        visible: false,
        color: 'orange',
        info: 'measurement_validation.valid',
        invalid: false
      });
    }
  };

  //////

  return (
    <div className={css['measurement-wrapper']}>
      <div className={css['box']}>
        <Heading headingType="h4" className={css['date']}>
          {getTranslation('meter.previous_value')}
        </Heading>
        <div className={css['datePick']}>
          <Paragraph>{formatDate(mutationDateTime)}</Paragraph>
        </div>
        <Heading headingType="h4" className={css['dateTwo']}>
          {getTranslation('meter.next_value')}
        </Heading>
        <div className={css['smallBall']}>
          <div className={css['smallBallShape']}></div>
        </div>
        <div className={css['line']}></div>
        <div className={css['ball']}>
          <div className={css['ballCenter']}></div>
        </div>
        <div className={css['line2']}></div>
        <div className={css['smallBall2']}>
          <div className={css['smallBallShape']}></div>
        </div>
        <Paragraph className={css['time']}>{previousDate ? formatDateTime(previousDate) : ''}</Paragraph>
        <div className={css['timePick']}>
          {!(hoursDisabled && minutesDisabled) && (
            <div className={!minutesDisabled ? css['hour-minutes'] : undefined}>
              {!hoursDisabled && (
                <SimpleDropdown
                  id={`hours-dropdown-${meterIndex}-${measurementIndex}`}
                  selectedValues={[hour]}
                  values={getDropDownTimeVals(HOURS, currentFreq)}
                  onChange={(val) => dispatchValue({ hour: val[0] })}
                  placeholder={getTranslation('meter.hour')}
                />
              )}

              {!minutesDisabled && (
                <SimpleDropdown
                  id={`minutes-dropdown-${meterIndex}-${measurementIndex}`}
                  selectedValues={[minute]}
                  values={minutes ? getDropDownTimeVals(minutes, currentFreq) : []}
                  onChange={(val) => dispatchValue({ minute: val[0] })}
                  placeholder={getTranslation('meter.minute')}
                />
              )}
            </div>
          )}
        </div>
        <Paragraph className={css['time2']}>{nextDate ? formatDateTime(nextDate) : ''}</Paragraph>
        <Paragraph className={css['value']}>
          {measurementPair && measurementPair.previousMeasurement
            ? `${formatDecimal(measurementPair.previousMeasurement.value, culture)} ${UoM}`
            : getTranslation('meter.no_previous_value')}
        </Paragraph>
        <div className={css['valuePick']}>
          <FloatInput
            id={`value-measurement-${meterIndex}-${measurementIndex}`}
            onChange={(val) => {
              validateTypedMeasurement(val);
            }}
            value={value}
            placeholder={getTranslation('general.value')}
            error={typeof value !== 'number' || state.bannerInfo.invalid || isNaN(value)}
            postfix={UoM}
          />
        </div>
        <Paragraph className={css['value2']}>
          {measurementPair && measurementPair.nextMeasurement
            ? `${formatDecimal(measurementPair.nextMeasurement.value, culture)} ${UoM}`
            : getTranslation('meter.no_next_value')}
        </Paragraph>
      </div>
      {state.bannerInfo.visible && (
        <InfoBanner
          color={state.bannerInfo.color}
          info={getTranslation(state.bannerInfo.info)}
          extraRight={
            meter &&
            measurement?.issue && (
              <IssueLink
                meterId={meter.id}
                meterIndex={meterIndex}
                measurementIndex={measurementIndex}
                isValidating={isValidating}
                isEditing={isEditing}
                isInValid={state.bannerInfo.invalid}
                issueStatus={measurement.issue?.status}
                closeDialog={closeDialog}
              />
            )
          }
        />
      )}
    </div>
  );
};

export default observer(MeasurementTimeLine);
