import React, { ReactNode } from 'react';

import css from './filters-new.module.scss';

type FilterBarProps = {
  leftFilters?: ReactNode;
  rightFilters?: ReactNode;
  className?: string;
  removeAllFilters: () => void;
};

export default function FiltersNew(props: FilterBarProps) {
  const { leftFilters, rightFilters } = props;

  return (
    <div className={css['filter-bar']}>
      {leftFilters}
      {rightFilters}
    </div>
  );
}
