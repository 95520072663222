import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { attachmentVisibility } from '@zf/api-types/enums';
import { CultureTableType } from '@zf/api-types/language';
import { ProductAttachmentFileType, ProductAttachmentType } from '@zf/api-types/product';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatPeriod } from '@zf/utils/src/date';

import MoreActionsMenu from '../../../../../components/Menus/MoreActionsMenu';
import { Icon } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import EditListItem from './product-attachment-actions/EditListItem';
import css from './product-attachment.module.scss';

type Props = {
  productAttachment: ProductAttachmentType;
  defaultFileName: ProductAttachmentFileType | undefined;
  cultureTable: CultureTableType;
  hasPortal: boolean;
  updateLocalProductAttachment: (newAttachment: ProductAttachmentType) => void;
  deleteLocalProductAttachment: () => void;
};

const ProductAttachment = (props: Props) => {
  const { productAttachment, cultureTable, defaultFileName, hasPortal, updateLocalProductAttachment } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <div
      className={classNames(css['product-attachment'], {
        [css['greyed-out']]: !moment().isBetween(
          productAttachment.validFrom,
          moment(productAttachment.validUntil).add(1, 'day'),
          undefined,
          '[]'
        )
      })}
    >
      <Paragraph bold>{defaultFileName?.fileName}</Paragraph>

      <FlexElements gap="16">
        <Paragraph>{formatPeriod(productAttachment.validFrom, productAttachment.validUntil)}</Paragraph>
        <Icon
          id={`${productAttachment.productAttachmentId}-approval-needed`}
          name="check"
          title={
            productAttachment.approvalRequired
              ? getTranslation('entity_attachment.approval_needed')
              : getTranslation('entity_attachment.no_approval_needed')
          }
          color={colors[productAttachment.approvalRequired ? 'graphite' : 'silver-400']}
        />
        <Icon
          id={`${productAttachment.productAttachmentId}-show-in-welcome`}
          name="enveloppe"
          title={
            productAttachment.includeInWelcomeEmail
              ? getTranslation('entity_attachment.show_in_welcome')
              : getTranslation('entity_attachment.not_show_in_welcome')
          }
          color={colors[productAttachment.includeInWelcomeEmail ? 'graphite' : 'silver-400']}
        />
        <Icon
          id={`${productAttachment.productAttachmentId}-show-during-move-in`}
          name="Move-in2"
          title={
            productAttachment.visibility === attachmentVisibility.portalandmovein
              ? getTranslation('entity_attachment.show_during_move_in')
              : getTranslation('entity_attachment.dont_show_during_move_in')
          }
          color={
            colors[productAttachment.visibility === attachmentVisibility.portalandmovein ? 'graphite' : 'silver-400']
          }
        />
      </FlexElements>

      <MoreActionsMenu
        id={`more-actions-${productAttachment.productAttachmentId}`}
        className={css['actions']}
        actions={[
          <EditListItem
            key={`edit-${productAttachment.productAttachmentId}`}
            productAttachment={productAttachment}
            cultureTable={cultureTable}
            hasPortal={hasPortal}
            updateLocalProductAttachment={updateLocalProductAttachment}
          />
          /*           ,<DeleteListItem
            key={`delete-${productAttachment.productAttachmentId}`}
            productAttachment={productAttachment}
            deleteLocalProductAttachment={deleteLocalProductAttachment}
          /> */
        ]}
      />
    </div>
  );
};

export default observer(ProductAttachment);
