import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, useEffect, useImperativeHandle } from 'react';

import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import InputField from '../../../../../components/input/InputField';
import {
    DialogClickRef, ValidationRef
} from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const SendTestEmailDialog = forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef } = props;
  const { applicationStore, communicationStore, configStore } = useStore();
  const { getTranslation } = applicationStore;
  const { templatesStore, communicationService } = communicationStore;
  const { testEmailForm, selectedEntitySubjectType, selectedEntitySubjectSubtype, initTestEmailForm, } = templatesStore;

  const error = testEmailForm?._hasError();

  useEffect(() => {
    initTestEmailForm();
  }, []);

  useEffect(() => {
    if (validationRef.current && testEmailForm) {
      validationRef.current.setIsError(error);
    }
  }, [error, testEmailForm]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      const culturetable =     await configStore.configService.getCultureTable();
      if (testEmailForm && culturetable) {
        await communicationService.sendTestEmail(
          selectedEntitySubjectType,
          selectedEntitySubjectSubtype,
          testEmailForm._get('emailReceiver'),
          culturetable.defaultCulture
        );
      }
    }
  }));

  if (!testEmailForm) return null;

  return (
    <>
      <Paragraph>{getTranslation('communication.send_test_descr')}</Paragraph>
      <InputContainer>
        <InputField
          id="test-address"
          value={testEmailForm._get('emailReceiver')}
          onChange={(val) => testEmailForm._set('emailReceiver', val)}
          placeholder={getTranslation('communication.mail_placeholder')}
          error={!!testEmailForm?._error('emailReceiver')}
          singleError={testEmailForm._error('emailReceiver')}
        />
      </InputContainer>
    </>
  );
});

export default observer(SendTestEmailDialog);
