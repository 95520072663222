import React from 'react';

import WizardNavItem from './WizardNavItem';
import css from './wizard-nav.module.scss';

export type WizardNavStep = {
  header: React.ReactNode;
  content?: React.ReactNode;
  name: string;
  subStep?: boolean;
  subSteps?: Array<string>;
  feedback: Array<React.ReactNode>;
  error?: boolean;
};

type Props = {
  steps: Array<WizardNavStep>;
  activeStep: string;
  onNavigate: (step: string) => void;
};

export default function WizardNav(props: Props) {
  const { steps, activeStep, onNavigate } = props;

  return (
    <ul className={css['wizard-nav']}>
      {steps.map((step, index) => {
        return (
          <WizardNavItem
            key={`wizard-nav-${index}`}
            header={step.header}
            content={step.content}
            active={step.name === activeStep || (step.subSteps && step.subSteps.includes(activeStep))}
            onTrigger={() => onNavigate(step.name)}
            name={step.name}
            subStep={step.subStep}
            feedback={step.feedback}
            error={step.error}
          />
        );
      })}
    </ul>
  );
}
