import { addLocationsMoveInWizardSteps } from 'app-context/stores/master-data/contracts/wizard/move-in/AddLocationsMoveInWizardStore';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import BillingSection from '../../shared/BillingSection/BillingSection';

/**
 * Adapter component
 */
const BillingSectionAdapter = () => {
  const { contractStore } = useStore();
  const {
    locationSectionStore,
    addLocationsMoveInWizardValues,
    addLocationsMoveInWizardNavigator,
    showBillingSection
  } = contractStore.moveInWizardBaseStore.addLocationsMoveInWizardStore;
  const { selectedLocations } = locationSectionStore;
  const { values, setValue } = addLocationsMoveInWizardValues;
  const { advanceAmount } = values;
  const { setActiveStep } = addLocationsMoveInWizardNavigator;

  return (
    <BillingSection
      showBillingSection={showBillingSection}
      showInfo={selectedLocations.size !== 0}
      advanceAmount={advanceAmount}
      setAdvanceAmount={(advanceAmount: number) => setValue({ advanceAmount })}
      setActiveStep={() => setActiveStep(addLocationsMoveInWizardSteps.billing)}
    />
  );
};

export default observer(BillingSectionAdapter);
