import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../hooks/useStore';
import css from './feed.module.scss';
import FeedCardBody from './FeedCardBody';

type Props = {
  subjectType: entitySubjectType;
  subjectId: string;
  timeStamp?: string;
};

const FeedCard = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="feed-card" className='card' role='dialog'>
      <CardHeader>{getTranslation('feed.activity_feed')}</CardHeader>
      <CardBody className={css['activity-feed-card-body']} fixedHeight>
        <FeedCardBody {...props} />
      </CardBody>
    </Card>
  );
};

export default observer(FeedCard);
