import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { ModelType } from '@zf/api-types/model';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import css from './meter-summary.module.scss';
import Summary from './Summary';

type Props = {
  meter: MeterType;
  compact?: boolean;
  onRemove?: () => void;
};

export default function MeterSummary(props: Props) {
  const { meter, onRemove, ...otherProps } = props;
  const { i18n } = useAppContext();

  const response = useSuspenseSingleAPI<ModelType>({
    request: {
      endpoint: '/cfg/models/',
      selector: meter.modelId
    }
  });

  if (!response.result) return null;

  const model = response.result.data;
  const brand = `${model.brandName}-${model.name}`;

  return (
    <Summary onRemove={onRemove} {...otherProps}>
      <Paragraph className={css['content']}>
        {i18n.getTranslation('meter.summary_text', {
          serialNumber: meter.serialNumber,
          brand,
          channelAmount: meter.channels ? meter.channels.length : 0
        })}
      </Paragraph>
    </Summary>
  );
}
