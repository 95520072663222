import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardInputWrapper, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../app-context/app-context';
import CountryInput from '../../../components/input/country-input';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import { CreateLocationType, STEP_NAMES } from './LocationWizard';

type Props = {
  values: CreateLocationType;
  setValue: (value: Partial<CreateLocationType>, initialApiSet?: boolean, backup_?: boolean) => void;
  handleFocus: (step: string) => () => void;
};

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);
const WizardCountryPicker = WizardInputWrapper(CountryInput);

export default function AddressSection(props: Props) {
  const { values, setValue, handleFocus } = props;

  const { i18n } = useAppContext();

  return (
    <WizardSection disabled={!!values.location}>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[0]} />
        {i18n.getTranslation('location.steps.address')}
      </WizardHeader>

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('location.address_wizard')}
      </Heading>

      <InputContainer>
        <WizardInputField
          id="streetName"
          onChange={(value) => setValue({ streetName: value })}
          value={values.streetName}
          placeholder={i18n.getTranslation('location.street')}
          error={!values.streetName}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="streetNumber"
          onChange={(value) => setValue({ streetNumber: value })}
          value={values.streetNumber}
          placeholder={i18n.getTranslation('location.streetnr')}
          error={!values.streetNumber}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="streetNumberAddition"
          onChange={(value) => setValue({ streetNumberAddition: value })}
          value={values['streetNumberAddition']}
          placeholder={i18n.getTranslation('location.streetnraddition')}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="postalCode"
          onChange={(value) => setValue({ postalCode: value })}
          value={values.postalCode}
          placeholder={i18n.getTranslation('location.postal')}
          error={!values.postalCode}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardInputField
          id="city"
          onChange={(value) => setValue({ city: value })}
          value={values.city}
          placeholder={i18n.getTranslation('location.city')}
          error={!values.city}
          onFocus={handleFocus(STEP_NAMES[0])}
        />
        <WizardCountryPicker
          id="country"
          onChange={(value) => setValue({ country: value[0] })}
          selectedValues={[values.country]}
          placeholder={i18n.getTranslation('location.country')}
          error={!values.country}
          onFocus={handleFocus(STEP_NAMES[0])}
          organisationScoped
        />
      </InputContainer>
    </WizardSection>
  );
}
