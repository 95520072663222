import 'react-virtualized/styles.css';

import classNames from 'classnames';
import React from 'react';
import { TableHeaderRowProps } from 'react-virtualized';

import css from './stella-dynamic-index-table.module.scss';

// This is a custom header row rendered
export const headerRowRenderer = ({ className, columns, style }: TableHeaderRowProps) => {
  return (
    <div className={classNames(className, css['header-row'])} role="row" style={style}>
      {columns}
    </div>
  );
};
