import { customerType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { ContractType } from '@zf/api-types/master-data/contract';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { MeterType } from '@zf/api-types/master-data/meter';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { deepEqual } from '@zf/utils/src/object';

export type SelectedActivityType =
  | 'confirm_contract'
  | 'confirm_measurements'
  | 'confirm_location'
  | 'confirm_customer'
  | '';

export type ActivityCompletedType =
  | 'contract_confirmed'
  | 'measurements_confirmed'
  | 'location_confirmed'
  | 'customer_confirmed'
  | 'movein_complete'
  | 'moveout_complete'
  | '';

export type ExistingEntitiesType = {
  existingCustomer: CustomerType | undefined;
  existingLocation: ServiceLocationType | undefined;
  existingPropertyGroupBillingConfig: PropertyGroupBillingConfigurationType | undefined;
  existingMeters: MeterType[] | undefined;
  existingContract: ContractType | undefined;
  locationIsDerived: boolean;
};

type State = {
  selectedActivity: SelectedActivityType;
  customer: CustomerType | null;
  customerType: customerType;
  deviatingLanguage: boolean;
  existingEntities: ExistingEntitiesType;
  isLoadingExistingEntities: boolean;
  internalMeterIds: string[];
  task: MoveRequestType | undefined;
  initialTask: MoveRequestType | undefined;
  locationIsDerived: boolean;
  hasCustomProductConfig: boolean;
  initialCustomerType: customerType;
};

type Action =
  | { type: 'SET_SELECTED_ACTIVITY'; newSelectedActivity: SelectedActivityType }
  | { type: 'SET_TASK'; updatedTask: MoveRequestType; updateInitial?: boolean; initialCustomerType?: customerType }
  | { type: 'SET_CUSTOMER'; customer: CustomerType | null }
  | { type: 'SET_CUSTOMER_TYPE'; customerType: customerType }
  | { type: 'SET_DEVIATING_LANG'; isDeviating: boolean }
  | { type: 'SET_EXISTING_ENTITIES'; entities: ExistingEntitiesType }
  | { type: 'SET_LOADING_EXISTING_ENTITIES'; isLoading: boolean }
  | { type: 'SET_CUSTOM_PROD_CONFIG'; isCustom: boolean };

const initialState: State = {
  selectedActivity: '',
  customer: null,
  customerType: customerType.existingcustomer,
  initialCustomerType: customerType.existingcustomer,
  deviatingLanguage: false,
  existingEntities: {
    existingCustomer: undefined,
    existingLocation: undefined,
    existingPropertyGroupBillingConfig: undefined,
    existingMeters: undefined,
    existingContract: undefined,
    locationIsDerived: false
  },
  isLoadingExistingEntities: true,
  internalMeterIds: [],
  task: undefined,
  locationIsDerived: false,
  hasCustomProductConfig: false,
  initialTask: undefined
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SELECTED_ACTIVITY': {
      return {
        ...state,
        selectedActivity: action.newSelectedActivity
      };
    }

    case 'SET_CUSTOMER': {
      return {
        ...state,
        customer: action.customer
      };
    }

    case 'SET_CUSTOMER_TYPE': {
      return {
        ...state,
        customerType: action.customerType
      };
    }

    case 'SET_DEVIATING_LANG': {
      return {
        ...state,
        deviatingLanguage: action.isDeviating
      };
    }

    case 'SET_TASK': {
      let internalMeterIds = state.internalMeterIds;

      if (state.task && !deepEqual(state.task.measurementDetails, action.updatedTask.measurementDetails)) {
        internalMeterIds = action.updatedTask.measurementDetails.reduce((acc: string[], md) => {
          if (md.internalMeterId) acc.push(md.internalMeterId);
          return acc;
        }, []);
      }

      return action.updateInitial
        ? {
            ...state,
            task: action.updatedTask,
            initialTask: action.updatedTask,
            initialCustomerType:
              typeof action.initialCustomerType === 'undefined'
                ? state.initialCustomerType
                : action.initialCustomerType,
            internalMeterIds
          }
        : {
            ...state,
            task: action.updatedTask,
            internalMeterIds
          };
    }

    case 'SET_EXISTING_ENTITIES': {
      return {
        ...state,
        existingEntities: action.entities,
        isLoadingExistingEntities: false,
        customerType: action.entities.existingCustomer ? customerType.existingcustomer : customerType.newcustomer,
        initialCustomerType: action.entities.existingCustomer ? customerType.existingcustomer : customerType.newcustomer
      };
    }

    case 'SET_LOADING_EXISTING_ENTITIES': {
      return {
        ...state,
        isLoadingExistingEntities: action.isLoading
      };
    }

    case 'SET_CUSTOM_PROD_CONFIG': {
      return {
        ...state,
        hasCustomProductConfig: action.isCustom
      };
    }

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider: ActivityProvider, useTracked, useUpdate: useDispatch } = createContainer(useValue);
