import React from 'react';

import { CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import DnsRecordsTable from './DnsRecordsTable';
import DomainName from './DomainName';
import EmailAddresses from './EmailAddresses';

export default function EmailConfiguartionCardBody() {
  return (
    <>
      <DomainName />

      <DnsRecordsTable />

      <CardItem width="12">
        <HorizontalDivider />
      </CardItem>

      <EmailAddresses />
    </>
  );
}
