import useUser from 'hooks/useUser';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { CommentType } from '@zf/api-types/comment';

import { UserAvatar } from '../../components/Avatar';
import { Button } from '../../design-system/Components';
import { Paragraph } from '../../design-system/Foundation';
import { useStore } from '../../hooks/useStore';
import css from './comment.module.scss';

export type CommentProps = {
  comment: CommentType;
  onDelete?: () => void;
  deleteLabel?: string;
};

const Comment = (props: CommentProps) => {
  const { comment, onDelete = () => {} } = props;

  const { applicationStore } = useStore();
  const { culture, userStore } = applicationStore;

  const timeSince = moment(comment.createdDateTime).locale(culture).from(moment());
  const userName = useUser(comment.userId, userStore);

  return (
    <div className={css['comment']}>
      <div className={css['avatar']}>
        <UserAvatar>{userName}</UserAvatar>
      </div>

      <div className={css['comment-bubble']}>
        <div className={css['first-row']}>
          <div className={css['name']}>{userName}</div>
          <div className={css['time']}>{timeSince}</div>
          <Button id="comment.delete" type="text" size="small" icon="cross" onClick={onDelete} danger />
        </div>

        <div className={css['message']}>
          <Paragraph>{comment.memo}</Paragraph>
        </div>
      </div>
    </div>
  );
};

export default observer(Comment);
