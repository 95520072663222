import { Dispatch, useEffect, useReducer } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { getShownValues } from '@zf/stella-react/src/atoms/Dropdown/dropdown';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import { useAppContext } from '../../app-context/app-context';
import { synchronouslyMapArray } from '../../utils/arrays';
import { METHODS, sendRequest } from '../../utils/request';

export type UseAutoFillProps<V> = {
  endpoint: string;
  selectedValues: string[];
  initialValue?: string;
  query?: Record<string, any>;
  queryField?: string;
  excludedIds?: string[];
  processRecord: (value: V) => DropdownValuesType<V> | undefined;
};

export type AutoFillToolsType<V> = {
  values: DropdownValuesType<V>[];
  searchValue: string | undefined;
  loading: boolean;
  showTooltip: boolean;
  focusedIndex: number | undefined;
  shownValues: {
    value: DropdownValuesType<V>;
    index: number;
  }[];
  totalAmountOfRecords: number;
  setState: Dispatch<SetState<V>>;
  setSearchValue: (searchValue: string) => void;
};

type State<V> = {
  filterValue: string | undefined;
  values: DropdownValuesType<V>[];
  isLoading: boolean;
  showTooltip: boolean;
  focusedIndex: number | undefined;
  totalAmountOfRecords: number;
};

type SetState<V> = Partial<State<V>>;

export default function useAutoFill<V extends { id: string }>(props: UseAutoFillProps<V>): AutoFillToolsType<V> {
  const {
    endpoint,
    initialValue,
    queryField = 'flexsearch',
    selectedValues,
    query,
    excludedIds,
    processRecord
  } = props;
  const { i18n, tenantReducer } = useAppContext();

  const stateReducer = createStateReducer<State<V>, SetState<V>>();
  const [state, setState] = useReducer(stateReducer, {
    values: [],
    filterValue: initialValue,
    isLoading: false,
    showTooltip: false,
    focusedIndex: undefined,
    totalAmountOfRecords: 0
  });

  const fetchResults = async (searchValue: string | undefined) => {
    const searchQuery = {
      [queryField]: searchValue
    };

    const queryObject = { ...searchQuery, ...query };

    setState({ isLoading: true });

    sendRequest<PagedResponseType<V>>({
      request: {
        method: METHODS.GET,
        endpoint: endpoint,
        query: queryObject
      },
      tenantReducer,
      lang: i18n.lang
    })
      .then((res) => {
        const totalAmountOfRecords = res.data?.totalRecords;

        // Identity only
        if (res && res.data && !res.data.results)
          setState({
            // @ts-ignore
            values: synchronouslyMapArray([res.data], processRecord),
            // @ts-ignore
            totalAmountOfRecordsres: totalAmountOfRecords,
            isLoading: false
          });

        // All other cases
        if (res && res.data && res.data.results) {
          if (excludedIds) {
            const filtered = res.data.results.filter((r) => !excludedIds.some((id) => r.id === id));

            if (selectedValues[0]) {
              const matchedValue = filtered.find((r) => r.id === selectedValues[0]);

              if (matchedValue) {
                setState({
                  values: synchronouslyMapArray(res.data.results, processRecord),
                  filterValue: processRecord(matchedValue)?.text,
                  totalAmountOfRecords,
                  isLoading: false
                });
              }
            } else {
              setState({
                values: synchronouslyMapArray(filtered, processRecord),
                totalAmountOfRecords,
                isLoading: false
              });
            }
          } else {
            if (selectedValues[0]) {
              const matchedValue = res.data.results.find((r) => r.id === selectedValues[0]);

              if (matchedValue) {
                setState({
                  values: synchronouslyMapArray(res.data.results, processRecord),
                  filterValue: processRecord(matchedValue)?.text,
                  totalAmountOfRecords,
                  isLoading: false
                });
              }
            } else {
              setState({
                values: synchronouslyMapArray(res.data.results, processRecord),
                totalAmountOfRecords,
                isLoading: false
              });
            }
          }
        }
      })
      .catch((e) => {
        throw e;
      });
  };

  const setSearchValue = (searchValue: string) => setState({ filterValue: searchValue });

  useEffect(() => {
    // Fetch everything on initial render
    fetchResults('');
  }, [query]);

  useEffect(() => {
    let blockFetch = -1;

    if (state.values) {
      // Do not fetch if an item is selected ( = values contains exactly the filtertext)
      blockFetch = state.values.findIndex((v) => v.text === state.filterValue);
    }

    if (blockFetch === -1) {
      // Do not fetch if an initial value is set
      if (state.filterValue !== initialValue) {
        fetchResults(state.filterValue);
      } else if (state.values && state.values.length < 2) {
        // If an item was selected and we adjust the filtervalue or clear the input
        fetchResults('');
      }
    } else {
      setState({ focusedIndex: blockFetch });
    }
  }, [state.filterValue]);

  return {
    values: state.values,
    searchValue: state.filterValue,
    loading: state.isLoading,
    showTooltip: state.showTooltip,
    focusedIndex: state.focusedIndex,
    shownValues: getShownValues<V>(state.values),
    totalAmountOfRecords: state.totalAmountOfRecords,
    setState,
    setSearchValue
  };
}
