import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import { ModelType } from '@zf/api-types/model';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import useCustomEntityPropertyTypes from '../../../../app-context/hooks/useCustomEntityPropertyTypes';
import ListPage from '../../../../components/listPage';
import { useStore } from '../../../../hooks/useStore';
import MeterList from './MeterList';
import MetersFilterBar from './MetersFilterBar';

type Props = {
  location: Location;
  models: ModelType[];
};

const MeterListpage = (props: Props) => {
  const { location, models } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('meter.meters'));

  const { customEntityPropertyTypes, extraColumns, extraColumnTitles } = useCustomEntityPropertyTypes(
    entitySubjectType.meter
  );

  if (!customEntityPropertyTypes) return null;

  return (
    <ListPage
      entitySubjectType={entitySubjectType.meter}
      subjectType={getTranslation('nav.devices')}
      topBar={<MetersFilterBar search={location.search} extraColumnTitles={extraColumnTitles} />}
      list={
        <MeterList
          meterIds={undefined}
          models={models}
          customEntityPropertyTypes={customEntityPropertyTypes}
          extraColumns={extraColumns}
        />
      }
    />
  );
};

export default observer(MeterListpage);
