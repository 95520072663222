import classNames from 'classnames';
import { Moment } from 'moment';
import React from 'react';

import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';
import { isMinDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import DatePicker from '../../../../components/Lang/DatePicker';
import { TabSlider } from '../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../design-system/Components/TabSlider/Tab';
import css from './css/assign-to-transaction-section.module.scss';

export type OutgoingTransactionType_ = 'existing' | 'new';

type Props = {
  className?: string;
  selectedType: OutgoingTransactionType_;
  collectionDate: Moment;
  overrideCollectionDate: boolean;
  border?: boolean;
  setSelectedType: (newType: string) => void;
  setCollectionDate: (newDate: Moment) => void;
  setOverrideCollectionDate: (val: boolean) => void;
};

export default function AssignToTransactionSection(props: Props) {
  const {
    selectedType,
    collectionDate,
    overrideCollectionDate,
    border = false,
    setSelectedType,
    setCollectionDate,
    setOverrideCollectionDate
  } = props;
  const { i18n } = useAppContext();

  const [outputTypeTabItems] = React.useState<SelectionTabItemType<Record<string, any>>[]>([
    { id: 'existing', title: i18n.getTranslation('invoice.existing_transaction') },
    { id: 'new', title: i18n.getTranslation('invoice.new_transaction') }
  ]);

  return (
    <div className={classNames(css['assign-to-transaction-section'], { [css['with-border']]: border })}>
      <TabSlider
        id="transaction-type"
        tabItems={outputTypeTabItems}
        selectedTabItem={selectedType}
        setSelectedItem={setSelectedType}
        type="grey"
      />
      {selectedType === 'existing' ? (
        <Paragraph className={css['existing-paragraph']}>{i18n.getTranslation('invoice.existing_paragraph')}</Paragraph>
      ) : (
        <>
          <CheckBox
            id="override-collection-date"
            className={css['override-checkbox']}
            checked={overrideCollectionDate}
            onChange={setOverrideCollectionDate}
          >
            {i18n.getTranslation('actions.invoice.select_deviating_date')}
          </CheckBox>
          {overrideCollectionDate && (
            <InputContainer grid={false}>
              <Center type="both">
                <DatePicker
                  id="collection-date"
                  className={css['collection-date']}
                  onChange={setCollectionDate}
                  value={collectionDate}
                  placeholder={i18n.getTranslation('invoice.collection_date')}
                  error={isMinDate(collectionDate)}
                />
              </Center>
            </InputContainer>
          )}
        </>
      )}
    </div>
  );
}
