import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { ServiceType } from '@zf/api-types/master-data/servicelocation';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import { getServiceStatus } from '../../../../../utils/serviceState';
import ActiveService from './ActiveService';

type Props = {
  locationId: string;
  services: ServiceType[];
};

const ServiceCardBody = (props: Props) => {
  const { locationId, services } = props;
  const activeServices = services.filter((s) => !!getServiceStatus(s.statusHistory));

  return (
    <Fragment>
      {activeServices.map((service, i) => {
        return (
          <Fragment key={`active-service-${service.utilityType}-${i}`}>
            <ActiveService locationId={locationId} service={service} />
            {activeServices.length - 1 !== i && (
              <CardItem width="12">
                <HorizontalDivider />
              </CardItem>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default observer(ServiceCardBody);
