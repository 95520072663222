import { Link } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { ContractServiceLocation } from '@zf/api-types/master-data/contract';

type Props = {
  serviceLocations: ContractServiceLocation[];
};

const ContractLocationsColumn = (props: Props) => {
  const { serviceLocations } = props;

  const { applicationStore } = useStore();
  const { rootUrl } = applicationStore;

  const firstLocation = serviceLocations[0];

  return serviceLocations.length > 1 ? (
    <>{serviceLocations.length}</>
  ) : (
    <Link id={firstLocation?.id || ''} url={`${rootUrl}/locations/${firstLocation?.id}`}>
      {firstLocation?.address?.localizedDisplay || ''}
    </Link>
  );
};

export default observer(ContractLocationsColumn);
