import React from 'react';

import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import { colors } from '@zf/utils/src/color';

import useRefresh from '../../../app-context/hooks/use-refresh';
import PropertyExport from '../../../components/Button/Excel/PropertyExport';
import AddLocationToGroupListPageButton from '../../../components/Button/Property-group/AddLocationToGroupListPageButton';
import DeletePropertyGroupListPageButton from '../../../components/Button/Property-group/DeletePropertyGroupListPageButton';
import InstallMeterListPageButton from '../../../components/Button/Property-group/InstallMeterListPageButton';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import { useStore } from '../../../hooks/useStore';
import usePropertyGroupFilter from '../hooks/usePropertyGroupFilter';

type Props = {
  search: string;
  extraColumnTitles: string[];
};

export default function PropertyGroupsFilterBar(props: Props) {
  const { search, extraColumnTitles } = props;
  const { overviewCount, searchValue, quickFilter, onSearch, onFilter, refreshCounts } = usePropertyGroupFilter(search);
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { refresh } = useRefresh();

  return (
    <FilterBar
      refreshPage={refresh}
      subject={getTranslation('property_groups.property_groups')}
      actions={[
        <AddLocationToGroupListPageButton key="addlocation" />,
        <DeletePropertyGroupListPageButton key="deleteproperty" refreshCounts={refreshCounts} />,
        <InstallMeterListPageButton key="installMeter" />,
        <PropertyExport key="propertyExport" extraColumnTitles={extraColumnTitles} />
      ]}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="property-groups" />}
          count={overviewCount.all}
          title={getTranslation('property_groups.all')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('property_groups.search')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
}
