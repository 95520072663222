import React from 'react';

import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { IncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../../app-context';
import useBillingData from '../../hooks/use-billing-data';
import useIncomingInvoices from '../../hooks/useIncomingInvoices';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';

type Props = {
  selectedInvoice: string;
  propertyGroupId: string;
  invoices: IncomingInvoiceType[];
  components: IncomingInvoiceComponentType[];
  setSelectedInvoice: (id: string) => void;
};

function NoIncomingInvoices() {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/invoices.png"
          alt="invoice"
          title="invoice"
          width="70"
          height="70"
        />
      }
      title={i18n.getTranslation('property_groups.tabs.billing.no_incoming_invoices')}
    >
      {i18n.getTranslation('property_groups.tabs.billing.no_incoming_invoices_descr')}
    </CardEmptyBody>
  );
}

export default function IncomingInvoicesTable(props: Props) {
  const { selectedInvoice, propertyGroupId, components, setSelectedInvoice } = props;
  const { i18n } = useAppContext();
  const { propertyGroupBillingConfiguration } = useBillingData();

  const [columns] = React.useState([
    {
      width: 200,
      label: i18n.getTranslation('invoices_list.labels.invoiceNum'),
      dataKey: 'invoiceNumber'
    },
    {
      width: 110,
      label: i18n.getTranslation('general.start_date'),
      dataKey: 'startDate'
    },
    {
      width: 110,
      label: i18n.getTranslation('general.end_date'),
      dataKey: 'endDate'
    },
    {
      width: 50,
      label: '',
      dataKey: 'edit'
    },
    {
      width: 50,
      label: '',
      dataKey: 'delete'
    }
  ]);

  const { loading, error, rows, sortableFields, sortBy, sortDirection, handleSort, setStopIndex } = useIncomingInvoices(
    propertyGroupId,
    components
  );

  if (!propertyGroupBillingConfiguration) return null;

  return (
    <DynamicVirtualTable
      id="invoice-period-table"
      tooltipId="invoice-period-table-tip"
      rows={rows}
      onSelectRow={(ids) => setSelectedInvoice(ids[0])}
      selectedRows={[selectedInvoice]}
      NoDataOverlay={NoIncomingInvoices}
      totalAmountOfRows={rows.length}
      showCheckBoxes={false}
      cursorPointer
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      loading={loading}
      error={error}
      sortableFields={sortableFields}
      sort={handleSort}
      sortBy={sortBy}
      singleSort
      sortDirection={sortDirection}
      columns={columns}
      singleSelect
      invert
    />
  );
}
