import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';

import DynamicVirtualTableNew from '../../../../../components/Lang/dynamic-virtual-table-new/DynamicVirtualTable';
import { TableError, TableLoading, TableNoDataTransparent } from '../../../../../components/placeholder';
import { useStore } from '../../../../../hooks/useStore';
import { ListPageFilter } from '../../../issues/Context/types';
import useMeterIssuesListPage from '../hooks/useMeterIssuesListPage';

type Props = {
  meter: MeterType;
  filter: ListPageFilter;
  setFilter: Function;
};

const DetailsIssueList = (props: Props) => {
  const { meter, filter, setFilter } = props;

  const { applicationStore } = useStore();
  const { culture, getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useMeterIssuesListPage(meter);

  return useMemo(() => {
    return (
      <DynamicVirtualTableNew
        cursorPointer
        id="meters-table"
        tooltipId="meters-table-id"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={() => <TableNoDataTransparent isALL={filter.quickFilter === 'all'} callback={setFilter} />}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={[
          {
            width: 250,
            label: getTranslation('meter_issues.issue'),
            dataKey: 'issue'
          },
          {
            width: 250,
            label: getTranslation('meter_issues.export_detected_on'),
            dataKey: 'noticedDateTime'
          },
          {
            width: 250,
            label: getTranslation('general.status'),
            dataKey: 'status'
          },
          {
            width: 250,
            label: getTranslation('general.detail'),
            dataKey: 'detail'
          }
        ]}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, culture]);
};

export default observer(DetailsIssueList);
