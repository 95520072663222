import { observer } from 'mobx-react';
import React from 'react';

import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';

const AddContractForCustomerListItem = () => {
  const { applicationStore, customerStore } = useStore();
  const { pageActionPermissions, selectedCustomer } = customerStore;

  if (!pageActionPermissions.mayAddContract) return null;

  const { rootUrl, getTranslation } = applicationStore;
  const customer = selectedCustomer.customer;

  return (
    <DropdownAction
      id="contract.add"
      key="move-in-list-item"
      actionType="link"
      icon="move-in2"
      url={`${rootUrl}/contracts/add/${customer.id}`}
    >
      {getTranslation('actions.move_in_for_customer')}
    </DropdownAction>
  );
};

export default observer(AddContractForCustomerListItem);
