import React from 'react';

import { PaymentParametersType } from '@zf/api-types/parameters';
import { UpdatePaymentDelayType } from '@zf/api-types/payments';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import ParametersContent from './parameters-content';

type Props = {
  paymentParameters: PaymentParametersType;
  paymentDelaysConfig: UseConfigReturnType<UpdatePaymentDelayType[]>;
};

export default function Parameters(props: Props) {
  const { paymentDelaysConfig } = props;
  const { Provider, useTracked } = useCreateContext<UpdatePaymentDelayType[]>(
    paymentDelaysConfig.currentConfig ? paymentDelaysConfig.currentConfig.responseData : []
  );

  return (
    <Provider>
      <ParametersContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
