import Interweave from 'interweave';
import React from 'react';

import { translationStatus } from '@zf/api-types/enums';
import { colors } from '@zf/utils/src/color';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { Icon } from '../../../../../components/Icon';
import AccessControl from '../../../../../components/Permissions/AccessControl';
import { useStore } from '../../../../../hooks/useStore';
import css from './template-language-icon.module.scss';

type IconPropsType = {
  id: string;
  tooltipFor?: string;
  status: translationStatus;
  className?: string;
};

export default function TemplateLanguageIcon(props: IconPropsType) {
  const { id, tooltipFor, status } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const getIconSpecs = () => {
    if (status === translationStatus.translated) {
      return {
        status: getTranslation('translated_fields.all'),
        iconColor: colors['blue-600'],
        iconType: 'lang-full'
      };
    } else if (status === translationStatus.missingtranslation) {
      return {
        status: getTranslation('translated_fields.missing'),
        iconColor: colors['red-600'],
        iconType: 'lang-none'
      };
    }

    return {
      status: getTranslation('translated_fields.none'),
      iconColor: colors['silver-600'],
      iconType: 'lang-none'
    };
  };

  const specs = getIconSpecs();

  return (
    <AccessControl permissionsKey={id} fallBack={null}>
      <div
        data-tip={createTooltipContent(
          <Interweave content={getTranslation('translated_fields.translation_status', { status: specs.status })} />
        )}
        data-for={tooltipFor}
      >
        <Icon className={css['language-icon']} type={specs.iconType} color={specs.iconColor} />
      </div>
    </AccessControl>
  );
}
