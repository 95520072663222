import clone from 'clone';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { unitOfMeasure } from '@zf/api-types/enums';
import { ConsumerGroupType, GetServiceLocationMatchingConsumerGroupsType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption } from '@zf/api-types/master-data/contract';
import { RequiredEstimationsType } from '@zf/api-types/product';

import css from './required-estimation.module.scss';
import RequiredEstimation from './RequiredEstimation';

type Props = {
  requiredEstimations: RequiredEstimationsType[];
  consumerGroups: ConsumerGroupType[];
};

const RequiredEstimationsSectionBody = (props: Props) => {
  const { requiredEstimations, consumerGroups } = props;

  const [matchingGroups, setMatchingGroups] = useState<GetServiceLocationMatchingConsumerGroupsType[][]>([]);

  const { contractStore } = useStore();
  const { getMatchingConGroups } = contractStore.contractApiService;
  const { regularMoveInWizardStore } = contractStore.moveInWizardBaseStore;
  const { regularMoveInWizardValues } = regularMoveInWizardStore;
  const { selectedPropertyGroupIds } = regularMoveInWizardStore.locationSectionStore;
  const { values, setValue } = regularMoveInWizardValues;

  const handleEstimatedConsumptionChange = (index: number, value: Partial<ContractedServiceEstimatedConsumption>) => {
    const cloned = clone(values.estimatedConsumptions);
    cloned[index] = { ...cloned[index], ...value };
    setValue({ estimatedConsumptions: cloned });
  };

  const initEstimatedConsumptions = async (
    matchingGroups: GetServiceLocationMatchingConsumerGroupsType[][],
    requiredEstimations: RequiredEstimationsType[]
  ) => {
    const initialValues = requiredEstimations.map((re, index) => {
      const initialGroup = matchingGroups[index]?.[0]?.consumerGroup;
      return {
        consumerGroupId: initialGroup?.id || '',
        value: initialGroup?.estimatedAnnualVolume,
        unitOfMeasure: initialGroup?.unitOfMeasure || unitOfMeasure.none,
        meteringType: re.meteringType,
        utilityType: initialGroup?.utilityType,
        isManualEntry: false
      };
    });

    setValue({ estimatedConsumptions: initialValues });
  };

  const getMatching = async (re: RequiredEstimationsType) => {
    const res = await getMatchingConGroups({
      productId: values.productId,
      propertyGroupIds: selectedPropertyGroupIds,
      utilityType: re.utilityType
    });

    return res.matchingConsumerGroups[re.unitOfMeasure];
  };

  useEffect(() => {
    Promise.all(requiredEstimations.map((re) => getMatching(re))).then((matching) => {
      setMatchingGroups(matching);
      initEstimatedConsumptions(matching, requiredEstimations);
    });
  }, [values.productId, consumerGroups, requiredEstimations.length]);

  // Wait for the initialization
  if (values.estimatedConsumptions.length === 0) return null;

  return (
    <div className={css['input-wrapper']}>
      {requiredEstimations.map((re, index) => {
        const estimatedConsumption = values.estimatedConsumptions[index];

        return (
          <RequiredEstimation
            key={`consumption-group-${index}`}
            index={index}
            requiredEstimation={re}
            matchingGroups={matchingGroups[index] || []}
            estimatedConsumption={estimatedConsumption}
            handleEstimatedConsumptionChange={handleEstimatedConsumptionChange}
          />
        );
      })}
    </div>
  );
};

export default observer(RequiredEstimationsSectionBody);
