import React from 'react';

import { consumerGroupOrigin } from '@zf/api-types/enums';
import { CardHeader } from '@zf/stella-react/src/atoms/Card';

import { ConfigHelp } from '../../../../components/CoachMarks';
import { Button } from '../../../../design-system/Components';
import { useStore } from '../../../../hooks/useStore';
import { observer } from 'mobx-react-lite';

type Props = {
  canEdit?: boolean;
  help?: boolean;
  title: string;
};

const ConsumptionGroupsCardHeader = (props: Props) => {
  const { canEdit, title, help } = props;

  const { applicationStore, meterConfigStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addEntity } = meterConfigStore.customCrudService;

  return (
    <CardHeader
      extraLeft={
        help ? <ConfigHelp content={[getTranslation('meter.consumption_groups.custom_groups.quicktip')]} /> : undefined
      }
      extraRight={
        canEdit && (
          <Button
            id="custom_groups.add"
            type="text"
            icon="plus"
            onClick={() => addEntity({ origin: consumerGroupOrigin.manual })}
          >
            {getTranslation('general.add')}
          </Button>
        )
      }
    >
      {title}
    </CardHeader>
  );
};

export default observer(ConsumptionGroupsCardHeader);
