import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import css from './dropdown-clear.module.scss';

type Props = {
  clearDropDown: () => void;
  clearText?: string;
};

export default function DropdownClear(props: Props) {
  const { clearText = 'Clear', clearDropDown } = props;

  return (
    <BaseButton id="clear" className={css['dropdown-clear']} onClick={clearDropDown}>
      {clearText}
    </BaseButton>
  );
}
