import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import MeterStore from 'app-context/stores/master-data/MeterStore';
import React, { useEffect, useMemo } from 'react';

import { countryCode } from '@zf/api-types/enums';
import NewStatusBadge from '@zf/stella-react/src/atoms/Badge/NewStatusBadge';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';
import { colors } from '@zf/utils/src/color';

export default function useDeviceTabs(applicationStore: ApplicationStore, meterStore: MeterStore) {
  const { getTranslation } = applicationStore;
  const { organisationCfg, meteringIssueStore } = meterStore;
  const { overviewCounts, initMeteringIssuesStore } = meteringIssueStore;

  useEffect(() => {
    if (organisationCfg && organisationCfg.features.meteringValidationEnabled) {
      initMeteringIssuesStore();
    }
  }, []);

  const tabItems = useMemo(() => {
    const res: TabItemType[] = [
      {
        id: 'meters',
        title: getTranslation('meter.meters')
      }
    ];

    if (organisationCfg && organisationCfg.features.meteringValidationEnabled) {
      res.push({
        id: 'issues',
        title: getTranslation('meter_issues.issues'),
        badge:
          typeof overviewCounts?.unresolved !== 'undefined' ? (
            <NewStatusBadge
              color={colors[overviewCounts.unresolved > 0 ? 'orange-600' : 'green-600']}
              type="numeric"
              status_={String(overviewCounts.unresolved)}
            />
          ) : undefined
      });
    }

    if (organisationCfg && organisationCfg?.address?.country === countryCode.gbr) {
      res.push({
        id: 'prepayment-devices',
        title: getTranslation('meter.prepayment_devices')
      });
    }

    return res;
  }, [organisationCfg, overviewCounts]);

  return tabItems;
}
