import React from 'react';

import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { InvoiceOverviewCountType, InvoiceRowType } from '@zf/api-types/invoice';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useRefresh from '../../../../app-context/hooks/use-refresh';
import FilterBar from '../../../../components/Filters/FilterBar';
import AddAllSpecificListPageButton from '../../actions/list-page/AddAllSpecificListPageButton';
import AddSelectedToBankingTransactionListPageButton from '../../actions/list-page/AddSelectedToBankingTransactionListPageButton';
import ApproveInvoiceListPageButton from '../../actions/list-page/ApproveInvoiceListPageButton';
import AssignToCaseListPageButton from '../../actions/list-page/AssignToCaseListPageButton';
import DeleteInvoiceListPageButton from '../../actions/list-page/DeleteInvoiceListPageButton';
import DownloadInvoiceListPageButton from '../../actions/list-page/DownloadInvoiceListPageButton';
import ExportListPageButton from '../../actions/list-page/ExportListPageButton';
import MarkAsNotExportedListPageButton from '../../actions/list-page/MarkAsNotExportedListPageButton';
import MarkAsSentListPageButton from '../../actions/list-page/MarkAsSentListPageButton';
import MergedPDFInvoiceListPageButton from '../../actions/list-page/MergedPDFInvoiceListPageButton';
import RegenerateListPageButton from '../../actions/list-page/RegenerateListPageButton';
import SendInvoiceListPageButton from '../../actions/list-page/SendInvoiceListPageButton';
import SendInvoicePostalListPageButton from '../../actions/list-page/SendInvoicePostalListPageButton';
import useInvoiceFilter from '../../hooks/useInvoiceFilter';
import InvoiceFilterButtons from './InvoiceFilterButtons';
import InvoiceFilterInputs from './InvoiceFilterInputs';

type Props = {
  search?: string;
};

export default function InvoicesFilterBar(props: Props) {
  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    filterTags,
    onSearch,
    onFilter,
    setQueryParams,
    clearQueryParams,
    refreshCounts
  } = useInvoiceFilter(props.search);
  const { refresh } = useRefresh();
  const { i18n } = useAppContext();

  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;

  const refreshAndRefreshCounts = () => {
    refresh();
    refreshCounts();
  };

  const actions = [
    <ApproveInvoiceListPageButton
      key="approveAction"
      refreshCounts={refreshCounts}
      toApprove={overviewCount.toApprove}
    />,
    <DownloadInvoiceListPageButton key="downloadAction" />,
    <MergedPDFInvoiceListPageButton key="MergePDFAction" />,
    <DeleteInvoiceListPageButton key="deleteAction" refreshCounts={refreshCounts} />,
    <MarkAsSentListPageButton key="markAsSentAction" />,
    <SendInvoiceListPageButton key="sendAction" />,
    <SendInvoicePostalListPageButton key="sendPostalAction" />,
    <RegenerateListPageButton key="regenerateAction" />,
    <AssignToCaseListPageButton key="assignToCaseAction" refresh={refresh} />
  ];

  if (selectedRows.length > 0) {
    actions.push(
      <AddSelectedToBankingTransactionListPageButton key="addToRequestTransaction" refresh={refreshAndRefreshCounts} />
    );
  } else {
    actions.push(
      <AddAllSpecificListPageButton
        key="addAllReadyRequest"
        type={outgoingBankingTransactionType.paymentrequests}
        refresh={refreshAndRefreshCounts}
      />,
      <AddAllSpecificListPageButton
        key="addAllReadyRefund"
        type={outgoingBankingTransactionType.paymentrefunds}
        refresh={refreshAndRefreshCounts}
      />
    );
  }

  actions.push(...[<MarkAsNotExportedListPageButton key="markAsNotExported" />, <ExportListPageButton key="export" />]);

  return (
    <FilterBar
      subject={i18n.getTranslation('invoice.invoices')}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      refreshPage={refresh}
      actions={actions}
      filterButtons={
        <InvoiceFilterButtons
          overviewCount={overviewCount as InvoiceOverviewCountType}
          quickFilter={quickFilter}
          onFilter={onFilter}
        />
      }
      filterInputs={<InvoiceFilterInputs queryParams={queryParams} setQueryParams={setQueryParams} />}
      onSearchChange={onSearch}
      searchPlaceHolder={i18n.getTranslation('invoice.search_placeholder')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
}
