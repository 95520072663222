import React from 'react';

import { CollectionFlowStepType, CollectionFlowType } from '@zf/api-types/collection-cycles';
import useDialog from '@zf/hooks/src/useDialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';

import { useAppContext } from '../../../app-context/app-context';
import { DeleteIcon } from '../../../components/Icon';
import EditIcon from '../../../components/Icon/EditIcon';
import { dialog } from '../../../events/dialog-events';
import css from './collection-flow.module.scss';
import CollectionFlowSteps from './CollectionFlowSteps';
import DeleteCycleDialog from './delete-cycle-dialog';
import EditCycleDialog from './edit-cycle-dialog/edit-cycle-dialog';

export type CollectionFlowProps = {
  flowIndex: number;
  flow: CollectionFlowType;
  steps: CollectionFlowStepType[];
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  updateStep: (step: CollectionFlowStepType) => void;
  removeStep: (stepId: string, flowIndex: number) => void;
  updateFlow: (flow: CollectionFlowType) => void;
  removeFlow: (flowId: string) => void;
};

export default function CollectionFlow(props: CollectionFlowProps) {
  const { flowIndex, flow, steps, addStep, updateStep, removeStep, updateFlow, removeFlow } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  const openDeleteDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('collection_flows.remove_flow', {
        flowName: flow.name
      }),
      icon: 'trashcan',
      content: <DeleteCycleDialog ref={clickRef} flow={flow} removeFlow={removeFlow} />,
      buttonPositive: i18n.getTranslation('general.delete'),
      type: 'danger',
      onSubmit
    });
  };

  const openEditDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('collection_flows.edit_flow', {
        flowName: flow.name
      }),
      icon: 'sign',
      content: <EditCycleDialog ref={clickRef} validationRef={validationRef} flow={flow} updateFlow={updateFlow} />,
      buttonPositive: i18n.getTranslation('general.edit'),
      onSubmit
    });
  };

  return (
    <div className={css['wrapper']}>
      <div className={css['name-delete']}>
        <Heading headingType="h3" style="bold">
          {flow.name}
        </Heading>

        <div className={css['icons']}>
          <EditIcon id={`edit-flow-${flow.name}`} onClick={openEditDialog} />
          <DeleteIcon id={`delete-flow-${flow.name}`} onClick={openDeleteDialog} />
        </div>
      </div>

      <div className={css['collection-flow-steps']}>
        <CollectionFlowSteps
          flowIndex={flowIndex}
          flow={flow}
          steps={steps}
          addStep={addStep}
          updateStep={updateStep}
          removeStep={removeStep}
        />
      </div>
    </div>
  );
}
