import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { IncomingBankingTransactionProvider } from '../context/incoming-banking-transaction-context';
import IncomingBankingTransactionDetailsCard from './IncomingBankingTransactionDetailsCard';
import MutationsCard from './MutationsCard';
import css from './incoming-transaction-details.module.scss';

type Props = {
  incomingBankingTransaction: IncomingBankingTransactionType;
  timeStamp: string;
  refresh: () => void;
};

export default function IncomingBankingTransaction(props: Props) {
  const { incomingBankingTransaction } = props;

  return (
    <CardsContainer className={css['incoming-banking-transaction-card-container']}>
      <IncomingBankingTransactionProvider>
        <IncomingBankingTransactionDetailsCard incomingBankingTransaction={incomingBankingTransaction} />
        <MutationsCard {...props} />
      </IncomingBankingTransactionProvider>
    </CardsContainer>
  );
}
