import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { OrganisationOverviewCountType } from '@zf/api-types/analytics/analytics';

import { Link } from '../../../design-system/Components';
import { Icon, Paragraph, Spinner } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import css from './org-card.module.scss';

type OrgCardBodyProps = {
  organizationId: string;
  tenantId: string;
  mutationDateTime: string;
  baseUrl: string;
  isResetting: boolean;
};

const OrgCardBody = (props: OrgCardBodyProps) => {
  const { organizationId, tenantId, mutationDateTime, baseUrl, isResetting } = props;

  const { applicationStore, organisationStore } = useStore();
  const { getOrganisationInsights } = organisationStore.organisationService;
  const { getTranslation } = applicationStore;

  const [analytics, setAnalytics] = useState<OrganisationOverviewCountType>();

  useEffect(() => {
    if (!isResetting) {
      getOrganisationInsights(organizationId, tenantId, mutationDateTime).then((insights) => setAnalytics(insights));
    }
  }, [isResetting, organizationId, tenantId, mutationDateTime]);

  if (!analytics) {
    return (
      <div className={css['empty-div']}>
        <Spinner className={css['spinner']} size="small" />
      </div>
    );
  }

  return (
    <>
      <div className={css['quick-numbers']}>
        <div title={getTranslation('analytics.supplied_locations')} className={css['quick-number']}>
          <Icon name="location" className={css['supplied']} />
          <Link id={`${organizationId}-supplied`} url={`${baseUrl}/locations?quickFilter=supplied`}>
            {analytics.serviceLocationsSupplied}
          </Link>
        </div>
        <div title={getTranslation('analytics.unsupplied_locations')} className={css['quick-number']}>
          <Icon name="location" className={css['unsupplied']} />
          <Link id={`${organizationId}-unsupplied`} url={`${baseUrl}/locations?quickFilter=unsupplied`}>
            {analytics.serviceLocationsUnsupplied}
          </Link>
        </div>
        <div title={getTranslation('analytics.created_invoices')} className={css['quick-number']}>
          <Icon name="created" className={css['created']} />
          <Link id={`${organizationId}-toapprove`} url={`${baseUrl}/invoices?quickFilter=created`}>
            {analytics.invoicesToApprove}
          </Link>
        </div>
        <div title={getTranslation('analytics.invoices_to_send')} className={css['quick-number']}>
          <Icon name="tosend" className={css['tosend']} />
          <Link id={`${organizationId}-tosend`} url={`${baseUrl}/invoices?&quickFilter=tosend`}>
            {analytics.invoicesToSend}
          </Link>
        </div>
        <div title={getTranslation('analytics.unpaid_invoices')} className={css['quick-number']}>
          <Icon name="unpaid" className={css['unpaid']} />
          <Link id={`${organizationId}-unpaid`} url={`${baseUrl}/invoices?quickFilter=unpaid`}>
            {analytics.invoicesUnpaid}
          </Link>
        </div>
      </div>

      <div className={css['intel-container']}>
        <div className={css['workload']}></div>
        <div className={css['invoicestatus']}>
          <div title={getTranslation('analytics.invoice_status')} className={css['invoicenumber']}>
            <div className={css['number']}>
              <Icon name="sent" color />
              <Paragraph>{`${Math.floor(analytics.percentageBilled * 100)}${getTranslation(
                'analytics.percentage_sent'
              )}`}</Paragraph>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(OrgCardBody);
