import moment, { Moment } from 'moment';
import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';
import { OutputQueryParamsType } from '@zf/api-types/graph';

import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import { ConsumptionsCtxState, useTracked } from '../context/consumptions-context';

export default function useServiceConsumptions() {
  const [state, dispatch] = useTracked();

  const setState = (newState: Partial<ConsumptionsCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const setIsLoading = (newValue: boolean) => {
    setState({ graphIsLoading: newValue });
  };

  const toggleCompareMode = () => {
    setState({ isCompareMode: !state.isCompareMode });
  };

  const setQueryParams = React.useCallback(
    (newParams: Partial<OutputQueryParamsType>) => {
      setState({ queryParams: { ...state.queryParams, ...newParams } });
    },
    [state.queryParams]
  );

  const setGroupByPeriod = React.useCallback(
    (newGroupBy: string) => {
      setQueryParams({ groupByPeriod: newGroupBy as aggregationFrequency });
    },
    [setQueryParams]
  );

  const setDates = React.useCallback(
    (newDates: Array<Moment | null | undefined>) => {
      setQueryParams({
        startDateTime: newDates[0] ? newDates[0] : moment(MIN_DATE),
        endDateTime: newDates[1] ? newDates[1] : moment(MAX_DATE)
      });
    },
    [setQueryParams]
  );

  const setSelectedFilterTypeIds = (typeId: string) => {
    if (
      state.selectedFilterTypeIds.some((sftId) => {
        return sftId === typeId;
      })
    ) {
      const newSelection = state.selectedFilterTypeIds.filter((sftId) => {
        return sftId !== typeId;
      });

      setState({ selectedFilterTypeIds: newSelection });
    } else {
      setState({ selectedFilterTypeIds: [...state.selectedFilterTypeIds, typeId] });
    }
  };

  const setYears = (newYears: number[]) => {
    setDates([moment([newYears[0]]).startOf('year'), moment([newYears[1]]).endOf('year')]);
  };

  return {
    filterTypesAreLoading: state.filterTypesAreLoading,
    filterTypes: state.filterTypes,
    timeStamp: state.timeStamp,
    locationId: state.locationId,
    queryParams: state.queryParams,
    isLoading: state.graphIsLoading,
    isCompareMode: state.isCompareMode,
    selectedFilterTypes: state.selectedFilterTypes,
    selectedFilterTypeIds: state.selectedFilterTypeIds,
    startDateTime: state.queryParams.startDateTime,
    endDateTime: state.queryParams.endDateTime,
    groupByPeriod: state.queryParams.groupByPeriod,
    setIsLoading,
    toggleCompareMode,
    setGroupByPeriod,
    setSelectedFilterTypeIds,
    setDates,
    setYears,
    setState
  };
}
