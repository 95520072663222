import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { useAppContext } from '../../../../../../app-context';
import Button from '../../../../../../components/Button/Button';
import DataGridCardHeaderSection from '../../../../../../components/DataGridCard/DataGridCardHeaderSection';
import DateRangePicker from '../../../../../../components/input/DateRangePicker';
import { TabSlider } from '../../../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../../../design-system/Components/TabSlider/Tab';
import useQueryParams from '../shared-hooks/useQueryParams';
import css from './list-output-header.module.scss';

type Props = {
  outputType: string;
  setOutputType: (outputType: string) => void;
  changeViewMode: () => void;
};

export default function ListOutputHeader(props: Props) {
  const { outputType, setOutputType, changeViewMode } = props;
  const { i18n } = useAppContext();

  const { startDateTime, endDateTime, setDates } = useQueryParams();

  const [outputTypeTabItems] = React.useState<SelectionTabItemType<Record<string, any>>[]>([
    {
      id: 'measurements',
      title: i18n.getTranslation('meter.measurements'),
      icon: 'barchart'
    },
    {
      id: 'consumptions',
      title: i18n.getTranslation('meter.consumptions'),
      icon: 'meter2'
    }
  ]);

  return (
    <DataGridCardHeaderSection className={css['list-header']}>
      <TabSlider
        id="output-type"
        tabItems={outputTypeTabItems}
        selectedTabItem={outputType}
        setSelectedItem={setOutputType}
        type="grey"
      />

      <FlexElements>
        <DateRangePicker id="period" startDate={startDateTime} endDate={endDateTime} setDates={setDates} />
      </FlexElements>

      <Button id="viewmode" type="text" icon="lineGraph" onClick={changeViewMode}>
        {i18n.getTranslation('measurement.viewmode_graph')}
      </Button>
    </DataGridCardHeaderSection>
  );
}
