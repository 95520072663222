import LocationAutoFill from 'components/Autofills/LocationAutoFill';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import LocationSummary from '../../shared/location-section/LocationSummary';

const SingleLocation = () => {
  const { contractStore } = useStore();
  const { locationSectionStore } = contractStore.moveInWizardBaseStore.regularMoveInWizardStore;
  const { selectedLocation, setSelectedLocation } = locationSectionStore;

  return (
    <>
      {selectedLocation ? (
        <LocationSummary location={selectedLocation} locationSectionStore={locationSectionStore} />
      ) : (
        <LocationAutoFill
          id="single-location-search"
          onChange={(val) => setSelectedLocation(val)}
          selectedValues={['']} // We don't show this, the summary does
          error
        />
      )}
    </>
  );
};

export default observer(SingleLocation);
