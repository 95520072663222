import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import GenericDialog from '../../../Dialog/GenericDialog';
import FilesStore from '../../logic/FilesStore';

type Props<T> = {
  filesStore: FilesStore<T>;
};

const DeleteAttachmentListItem = <T,>(props: Props<T>) => {
  const { filesStore } = props;

  const { clickRef, onSubmit } = useDialog();
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { actionPermissions, createRequestService, deleteEntityAttachments } = filesStore;

  if (!actionPermissions.mayDeleteEntityAttachment) return null;

  const { selectedIds } = createRequestService;
  const isMulti = selectedIds.length > 1;

  return (
    <DropdownAction
      id="entity_attachment.delete"
      icon="trashcan"
      dialogTitle={getTranslation('entity_attachment.delete_dialog_title')}
      dialogContent={
        <GenericDialog
          ref={clickRef}
          info={getTranslation(`entity_attachment.delete_paragraph${isMulti ? '_multi' : ''}`)}
          successMsg={getTranslation(`entity_attachment.delete_success${isMulti ? '_multi' : ''}`)}
          errorMsg={getTranslation(`entity_attachment.delete_fail${isMulti ? '_multi' : ''}`)}
          submitFunction={deleteEntityAttachments}
        />
      }
      onSubmit={onSubmit}
      buttonPositive={getTranslation('general.delete')}
      dialogType="danger"
    />
  );
};

export default observer(DeleteAttachmentListItem);
