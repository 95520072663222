import React from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';
import {
  DropdownTools,
  DropdownValuesType,
  SetDropdownTools
} from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

export type UseDropdownReturnType<T> = {
  tools: DropdownTools<T>;
  values: DropdownValuesType<T>[];
  selectedValue: DropdownValuesType<T> | undefined;
  setDropdownTools: React.Dispatch<Partial<DropdownTools<T>>>;
};

export default function useDropdown<T>(values: DropdownValuesType<T>[]) {
  const stateReducer = createStateReducer<DropdownTools<T>, SetDropdownTools<T>>();
  const [state, setState] = React.useReducer(stateReducer, {
    values: values,
    selectedValue: undefined,
    showTooltip: false,
    searchValue: '',
    focusedIndex: undefined
  });

  React.useEffect(() => {
    const selectedValue =
      state.values && typeof state.focusedIndex !== 'undefined' ? state.values[state.focusedIndex] : undefined;

    setState({ selectedValue: selectedValue });
  }, [state.values, state.focusedIndex]);

  return {
    tools: state,
    values,
    selectedValue: state.selectedValue,
    setDropdownTools: setState
  };
}
