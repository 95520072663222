import React from 'react';
import css from './icon-paragraph.module.scss';

import StellaIconLabel, { IconLabelProps } from '@zf/stella-react/src/atoms/Label/StellaIconLabel';

import { Icon } from '../Icon';

export default function IconLabel(props: IconLabelProps) {
  const { iconType = '', color } = props;

  return <StellaIconLabel icon={<Icon className={css['icon-label']} type={iconType} color={color} />} {...props} />;
}
