import React from 'react';

import { SortType } from '@zf/api-types/general';
import { formatDateTime } from '@zf/utils/src/date';

import { FilterType } from '../app-context/app-state';
import useRefresh from '../app-context/hooks/use-refresh';
import { RequestType } from '../types/Request';

export default function useListPageCreateRequest<T>(
  endpoint: string,
  filter: FilterType,
  sort: SortType,
  defaultQueryParams?: T
) {
  const [request, setRequest] = React.useState<RequestType | null>(null);
  const { timeStamp } = useRefresh();
  let orderBy = '';

  if (sort.sortDirection) {
    orderBy = Object.keys(sort.sortDirection).reduce((acc, key) => {
      if (sort.sortDirection && sort.sortDirection[key]) {
        acc += `${sort.sortDirection[key] === 'ASC' ? '' : '-'}${key}`;
      }

      return acc;
    }, '');
  }

  React.useEffect(() => {
    const query = {
      ...defaultQueryParams,
      ...filter.queryParams,
      quickFilter: filter.quickFilter,
      flexSearch: filter.search,
      orderBy
    };

    setRequest({
      endpoint,
      query,
      timeStamp: timeStamp ? timeStamp : filter.timeStamp ? formatDateTime(filter.timeStamp) : ''
    });
  }, [JSON.stringify(filter), JSON.stringify(sort), timeStamp, JSON.stringify(defaultQueryParams)]); //TODO will need to remove this in TD.

  return {
    request,
    timeStamp
  };
}
