import SearchBar from 'components/input/SearchBar';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { forwardRef, Fragment, MutableRefObject, Ref, useState } from 'react';

import { ContractServiceType } from '@zf/api-types/forecasting';
import { ContractServiceLocation } from '@zf/api-types/master-data/contract';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import ActiveService from './ActiveService';
import css from './all-locations-dialog.module.scss';
import EarlyEndingLocationsPerRemovedDate from './EarlyEndingLocationsPerRemovedDate';
import FutureLocationsPerAddedDate from './FutureLocationsPerAddedDate';

type Props = {
  serviceLocations: ContractServiceLocation[];
  contractServices: ContractServiceType[];
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const AllLocationsDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { serviceLocations, contractServices, validationRef } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const [searchValue, setSearchValue] = useState('');

  let filteredLocations = [...serviceLocations];

  if (searchValue) {
    filteredLocations = serviceLocations.filter((l) =>
      l.address?.localizedDisplay?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  const closeDialog = () => validationRef.current?.onCancel();

  return (
    <div className={css['all-locations-dialog']}>
      <SearchBar
        id="locations-searchbar"
        onChange={setSearchValue}
        value={searchValue}
        placeholder={getTranslation('location.search')}
        debounceTime={500}
      />

      <FutureLocationsPerAddedDate serviceLocations={serviceLocations} />

      <EarlyEndingLocationsPerRemovedDate serviceLocations={serviceLocations} />

      {filteredLocations.map((l) => {
        const contractServicesForLocation = contractServices.filter((cs) => cs.serviceLocationId === l.id);

        return (
          <Fragment key={l.id}>
            <ActiveService
              serviceLocation={l}
              contractServicesForLocation={contractServicesForLocation}
              closeDialog={closeDialog}
            />
            <HorizontalDivider />
          </Fragment>
        );
      })}
    </div>
  );
});

export default observer(AllLocationsDialog);
