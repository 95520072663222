import { useEffect, useState } from 'react';

import { CultureTableType } from '@zf/api-types/language';

import { useStore } from '../../hooks/useStore';

export default function useCultureTable() {
  const { configStore } = useStore();

  const [cultureTable, setCultureTable] = useState<CultureTableType | null>(null);

  useEffect(() => {
    configStore.configService.getCultureTable().then((table) => setCultureTable(table));
  }, []);

  const hasMultipleLanguages = cultureTable ? cultureTable.supportedCultures.length > 1 : false;

  return { cultureTable, hasMultipleLanguages };
}
