import React from 'react';

import { OutgoingBankingTransactionsOverviewCountType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionType } from '@zf/api-types/enums';

import useFilter from '../../../../../app-context/hooks/use-filter';
import FilterTag from '../../../../../components/Filters/filter-tag';
import FilterTags from '../../../../../components/Filters/filter-tags';
import { ArrayParam, StringParam } from 'serialize-query-params';

const initialOverviewCount: OutgoingBankingTransactionsOverviewCountType = {
  all: 0,
  readyToSend: 0,
  toConfirm: 0,
  closed: 0,
  dateExpired: 0,
  resolveIssues: 0
};

type QueryParam = {
  mutationDateTimeStart?: string;
  mutationDateTimeEnd?: string;
  companyBankAccountIBAN: string;
  companyBankAccountId: string;
  outgoingBankingTransactionType?: outgoingBankingTransactionType[];
};

const initialQueryParams = {
  mutationDateTimeStart: undefined,
  mutationDateTimeEnd: undefined,
  companyBankAccountIBAN: '',
  companyBankAccountId: ''
};

const paramTypes = {
  mutationDateTimeStart: StringParam,
  mutationDateTimeEnd: StringParam,
  companyBankAccountIBAN: StringParam,
  companyBankAccountId: StringParam,
  outgoingBankingTransactionType: ArrayParam
};

const useBatchesFilter = (search?: string) => {
  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams
  } = useFilter<QueryParam>({
    endpoint: '/bill/outgoingbankingtransactions/overviewcount',
    initialCount: initialOverviewCount,
    domain: 'outgoing_banking_transaction',
    search,
    initialQueryParams,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.mutationDateTimeStart !== undefined || queryParams.mutationDateTimeEnd !== undefined) {
    tags.push(
      <FilterTag
        key="period"
        type="period"
        value={[queryParams.mutationDateTimeStart, queryParams.mutationDateTimeEnd]}
        removeFilter={() => setQueryParams({ mutationDateTimeStart: undefined, mutationDateTimeEnd: undefined })}
      />
    );
  }

  if (queryParams.companyBankAccountIBAN && queryParams.companyBankAccountId) {
    tags.push(
      <FilterTag
        key="iban"
        value={queryParams.companyBankAccountIBAN}
        removeFilter={() => setQueryParams({ companyBankAccountIBAN: '', companyBankAccountId: '' })}
      />
    );
  }

  if (queryParams.outgoingBankingTransactionType && queryParams.outgoingBankingTransactionType.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="outgoingBankingTransactionType"
        value={queryParams.outgoingBankingTransactionType[0]}
        removeFilter={() => setQueryParams({ outgoingBankingTransactionType: [] as outgoingBankingTransactionType[] })}
        key="type"
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    filterTags,
    quickFilter,
    overviewCount,
    searchValue,
    queryParams,
    refreshCounts: refresh,
    onFilter,
    onSearch,
    setQueryParams,
    clearQueryParams
  };
};

export default useBatchesFilter;
