import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { CollectionFlowType } from '@zf/api-types/collection-cycles';
import { PaymentParametersType } from '@zf/api-types/parameters';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../app-context/app-context';
import useSingleAPI from '../../../hooks/useSingleAPI';
import Dropdown from '../../Lang/Dropdown';

export type CollectionFlowDropdownProps = {
  id: string;
  isInline?: boolean;
  selectedValue: string;
  onFocus?: (step: string) => void;
  onChange: (values: CollectionFlowType[]) => void;
};

const InlineDropdown = InlineInputField<DropdownProps<CollectionFlowType>>(Dropdown);

export default function CollectionFlowsDropdown(props: CollectionFlowDropdownProps) {
  const { id, isInline = false, onFocus, onChange } = props;
  let { selectedValue } = props;
  const { i18n } = useAppContext();

  const paymentParameters = useSingleAPI<PaymentParametersType>({
    request: {
      endpoint: '/cfg/Parameters/payment'
    }
  });

  const collectionflowResponse = useSingleAPI<PagedResponseType<CollectionFlowType>>({
    request: {
      endpoint: '/cfg/CollectionFlows/'
    }
  });

  if (
    !paymentParameters ||
    !paymentParameters.result ||
    paymentParameters.result instanceof Promise ||
    !collectionflowResponse ||
    !collectionflowResponse.result ||
    collectionflowResponse.result instanceof Promise
  ) {
    return isInline ? (
      <InlineDropdown id={id} onChange={onChange} values={[]} selectedValues={[selectedValue]} onFocus={onFocus} />
    ) : (
      <Dropdown
        id={id}
        onChange={onChange}
        placeholder={i18n.getTranslation('collection_flows.collection_flow')}
        values={[]}
        selectedValues={[selectedValue]}
        onFocus={onFocus}
      />
    );
  }

  const defaultFlowId = paymentParameters.result.data.defaultCollectionFlowId;

  const dropdownFlows = collectionflowResponse.result.data.results.map((flow) => {
    return {
      value: flow,
      text: flow.id === defaultFlowId ? `${flow.name} (${i18n.getTranslation('general.default')})` : flow.name
    };
  });

  if (!selectedValue && defaultFlowId) {
    selectedValue = defaultFlowId;
  }

  return isInline ? (
    <InlineDropdown
      id={id}
      onChange={onChange}
      values={dropdownFlows}
      selectedValues={[selectedValue ? selectedValue : '']}
      onFocus={onFocus}
    />
  ) : (
    <Dropdown
      id={id}
      onChange={onChange}
      placeholder={i18n.getTranslation('collection_flows.collection_flow')}
      values={dropdownFlows}
      selectedValues={[selectedValue ? selectedValue : '']}
      onFocus={onFocus}
    />
  );
}
