import React from 'react';

import { PercentageCollectionChargeParametersType } from '@zf/api-types/collection-cycles';

import FloatInput from '../../../../../components/input/FloatInput';
import { useAppContext } from '../../../../../app-context';

type Props = {
  stepNumber: string | number;
  chargeParams: PercentageCollectionChargeParametersType;
  setChargeParametersValue: (key: any, value: any) => void;
};

export default function PercentageCollectionChargeParameters(props: Props) {
  const { stepNumber, chargeParams, setChargeParametersValue } = props;
  const { i18n } = useAppContext();

  return (
    <FloatInput
      id={`percentage-${stepNumber}`}
      value={chargeParams.percentage}
      onChange={(val) => setChargeParametersValue('percentage', val)}
      placeholder={i18n.getTranslation('collection_flows.percentage')}
      postfix="%"
      error={!chargeParams.percentage}
    />
  );
}
