import { Link } from 'design-system/Components';
import { Icon } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { Fragment, ReactNode } from 'react';

import { PropertyGroupAttributeType } from '@zf/api-types/master-data/property-group';

import css from './propertygroup-links.module.scss';

type Props = {
  propertyGroups: PropertyGroupAttributeType[];
};

const PropertyGroupLinks = (props: Props) => {
  const { propertyGroups } = props;

  const { applicationStore } = useStore();
  const { rootUrl } = applicationStore;

  return (
    <>
      {<Icon name="property-groups" className={css['sub-icon-pg']} />}
      {propertyGroups.map<ReactNode>((pg, index) => {
        return (
          <Fragment key={`property-${pg.id}`}>
            <Link id={`property-${pg.id}`} url={`${rootUrl}/property-groups/${pg.id}`}>
              {pg.name}
            </Link>
            {index !== propertyGroups.length - 1 && <span>{`, `}</span>}
          </Fragment>
        );
      })}
    </>
  );
};

export default observer(PropertyGroupLinks);
