import { sendRequest, createHeader, METHODS } from '../../utils/request';
import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { ExportJobType, APIExportJobType } from '@zf/api-types/export-job';
import { uiCulture } from '@zf/api-types/enums';

export async function deleteExporttJob(
  value: APIExportJobType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<ExportJobType> {
  return (
    await sendRequest<ExportJobType>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/logs/exportjob/${value.id}`
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function deleteAllExportJobs(tenantReducer: TenantReturnValue, lang: uiCulture): Promise<boolean> {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: '/logs/exportjob/all'
      },
      tenantReducer,
      lang
    })
  ).data;
}

export async function generateExportJob(
  value: APIExportJobType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<ExportJobType> {
  return (
    await sendRequest<ExportJobType>({
      request: {
        method: METHODS.POST,
        endpoint: `/logs/exportjob/${value.id}/generate`
      },
      customHeaders: createHeader({
        'If-Match': value.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}

export async function transferExportJob(
  exportJob: APIExportJobType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
): Promise<ExportJobType> {
  return (
    await sendRequest<ExportJobType>({
      request: {
        method: METHODS.POST,
        endpoint: `/logs/ExportJob/${exportJob.id}/transfer`
      },
      customHeaders: createHeader({
        'If-Match': exportJob.etag
      }),
      tenantReducer,
      lang
    })
  ).data;
}
