import React from 'react';

import StellaSimpleDropdown from './SimpleDropdown/StellaSimpleDropdown';
import { DropdownValuesType } from './StellaDropdown';

export type CultureCodeProps = {
  id: string;
  placeholder?: string;
  selectedValues: string[];
  disabled?: boolean;
  multiSelect?: boolean;
  searchPlaceholder?: string;
  search?: boolean;
  clear?: boolean;
  error?: boolean;
  className?: string;
  autoFocus?: boolean;
  cultureCodes: DropdownValuesType<string>[];
  onFocus?: (step: string) => void;
  onChange: (values: string[]) => void;
};

export default function CultureCodeDropDown(props: CultureCodeProps) {
  return <StellaSimpleDropdown search clear values={props.cultureCodes} {...props} />;
}
