import classNames from 'classnames';
import React from 'react';

import AddButton from '@zf/stella-react/src/atoms/Button/AddButton';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { TooltipTrigger } from '../../design-system/Components';
import ColumnHeader from './ColumnHeader';
import css from './config-column.module.scss';

type Props = {
  title?: string;
  subTitleBelow?: string;
  children?: React.ReactNode;
  className?: string;
  bodyClassName?: string;
  style?: React.CSSProperties;
  borderRight?: boolean;
  borderStyle?: 'solid' | 'dashed';
  coachMark?: JSX.Element;
  extraRight?: JSX.Element;
  disabled?: boolean;
  addLocation?: 'header' | 'border';
  addTooltipText?: string;
  add?: () => void;
};

export default function ConfigColumn(props: Props) {
  const {
    title,
    children,
    className,
    coachMark,
    extraRight,
    subTitleBelow,
    addTooltipText,
    bodyClassName,
    style = {},
    borderStyle = 'solid',
    addLocation = 'header',
    borderRight = true,
    disabled = false,
    add
  } = props;

  return (
    <div
      className={classNames(
        css['config-column'],
        { [css['border']]: borderRight, [css['border-dashed']]: borderRight && borderStyle === 'dashed' },
        className
      )}
      style={style}
    >
      {title && (
        <ColumnHeader
          disabled={disabled}
          title={title}
          subTitleBelow={subTitleBelow}
          coachMark={coachMark}
          extraRight={extraRight}
          add={addLocation === 'header' ? add : undefined}
        />
      )}

      {addLocation === 'border' && add && (
        <div className={css['border-btn']}>
          {addTooltipText ? (
            <TooltipTrigger tooltip={<Paragraph>{addTooltipText}</Paragraph>} trigger="hover">
              <AddButton id={`${title}-add-btn`} onClick={add} disabled={disabled} />
            </TooltipTrigger>
          ) : (
            <AddButton id={`${title}-add-btn`} onClick={add} disabled={disabled} />
          )}
        </div>
      )}
      <div
        className={classNames(
          css['column-body'],
          { [css['column-body-w-subtitle']]: !!subTitleBelow, [css['column-body-no-header']]: !title },
          bodyClassName
        )}
      >
        {children}
      </div>
    </div>
  );
}
