import React from 'react';

import { PaymentRowType, PaymentType } from '@zf/api-types/payments';
import useDialog from '@zf/hooks/src/useDialog';

import ManuallyReverseDialog from '../../../actions/payments/manually-reverse-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function ManuallyReversePaymentListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('payment');
  const selectedRows: PaymentRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<PaymentType>('payment');
  const { clickRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="payment.reverse"
      icon="revert"
      dialogTitle={i18n.getTranslation('payments.manually_reverse')}
      dialogContent={
        <ManuallyReverseDialog
          onComplete={setUpdatedRows}
          ref={clickRef}
          selectedRows={selectedRows}
          refreshCounts={refreshCounts}
        />
      }
      disabled={
        selectedRows.length === 0 ||
        selectedRows.some((r) => {
          return r.__paymentEntity.isReversed;
        })
      }
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('actions.reverse')}
    />
  );
}
