import FloatInput from 'components/input/FloatInput';
import SimpleDropdown from 'components/Lang/SimpleDropdown';
import { ICONS } from 'constants/icons';
import { Icon } from 'design-system/Foundation';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import { observer } from 'mobx-react';
import React from 'react';
import { getColorByUtilityType } from 'utils/meter';

import { consumerGroupSuggestion } from '@zf/api-types/enums';
import { GetServiceLocationMatchingConsumerGroupsType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption } from '@zf/api-types/master-data/contract';
import { RequiredEstimationsType } from '@zf/api-types/product';
import { FloatInputFieldProps } from '@zf/stella-react/src/atoms/InputField/stella-float-input';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';
import { colors } from '@zf/utils/src/color';

import css from './required-estimation.module.scss';

type Props = {
  index: number;
  requiredEstimation: RequiredEstimationsType;
  matchingGroups: GetServiceLocationMatchingConsumerGroupsType[];
  estimatedConsumption?: ContractedServiceEstimatedConsumption;
  isOptional?: boolean;
  handleEstimatedConsumptionChange: (index: number, value: Partial<ContractedServiceEstimatedConsumption>) => void;
};

const WizardFloatInput = WizardInputWrapper<FloatInputFieldProps>(FloatInput);

const RequiredEstimation = (props: Props) => {
  const {
    index,
    requiredEstimation,
    isOptional = false,
    matchingGroups,
    estimatedConsumption,
    handleEstimatedConsumptionChange
  } = props;

  const { applicationStore } = useStore();
  const { getEnumTranslation, getTranslation } = applicationStore;

  const origin = matchingGroups.find((mg) => mg.consumerGroup.id === estimatedConsumption?.consumerGroupId)?.suggestion;

  const consumerGroupDropdownValues = matchingGroups.map((mg) => {
    return {
      value: mg.consumerGroup.id,
      text: mg.consumerGroup.description
    };
  });

  return (
    <div className={css['consumption-group-item']}>
      <Icon
        className={css['consumption-group-item-icon']}
        name={ICONS[requiredEstimation.utilityType]}
        color={getColorByUtilityType(requiredEstimation.utilityType)}
      />
      <div>
        <SimpleDropdown
          id={`estimated-anual-dropdown-${index}`}
          onChange={(mgIds) => {
            const matchingGroup = matchingGroups.find((mg) => mg.consumerGroup.id === mgIds[0]);

            // The index of the requiredEstimation matches the one of the estimatedConsumption
            if (matchingGroup) {
              handleEstimatedConsumptionChange(index, {
                consumerGroupId: matchingGroup.consumerGroup.id,
                value: matchingGroup.consumerGroup.estimatedAnnualVolume,
                unitOfMeasure: matchingGroup.consumerGroup.unitOfMeasure,
                utilityType: matchingGroup.consumerGroup.utilityType,
                meteringType: estimatedConsumption?.meteringType,
                isManualEntry: false
              });
            }
          }}
          values={consumerGroupDropdownValues}
          selectedValues={[estimatedConsumption?.consumerGroupId]}
        />
        {origin === consumerGroupSuggestion.frompropertygroup && (
          <Paragraph className={css['inherited-from']} color={colors['blue-600']}>
            <Interweave content={getTranslation('contracts.estimated_inherited_from')} />
          </Paragraph>
        )}
      </div>
      <WizardFloatInput
        className={css['estimated-anual-value']}
        id={`estimated-anual-value-${index}`}
        postfix={getEnumTranslation('unitOfMeasure', requiredEstimation.unitOfMeasure)}
        value={Number(estimatedConsumption?.value) || null}
        error={
          (typeof estimatedConsumption?.value !== 'number' ||
            isNaN(estimatedConsumption?.value) ||
            estimatedConsumption?.value < 0) &&
          !isOptional
        }
        onChange={(value) => handleEstimatedConsumptionChange(index, { value, isManualEntry: true })}
        hideLabel
      />
    </div>
  );
};

export default observer(RequiredEstimation);
