import classNames from 'classnames';
import { Button } from 'design-system/Components';
import React, { ReactNode, useEffect } from 'react';

import useRemovableValuesArray from '@zf/hooks/src/useRemovableValuesArray';

import { useStore } from '../../../hooks/useStore';
import css from './multi-value.module.scss';
import MultiValueItem from './MultiValueItem';

export type MultiValueProps<T> = {
  id: string;
  title: string;
  initialValues: T[];
  intialNodeValue: T;
  disabledNodes?: boolean[];
  className?: string;
  enableAddNode?: boolean;
  enableCopyNode?: boolean;
  enableRemoveNode?: boolean;
  revertOrder?: boolean;
  withBorder?: boolean;
  oneEntryRequired?: boolean;
  children: (childProps: { index: number; value: T; dispatchValue: (value: Partial<T>) => void }) => ReactNode;
  onChange: (values: T[]) => void;
  setDisabledNodes?: (values: boolean[]) => void;
};

export default function MultiValue<T>(props: MultiValueProps<T>) {
  const {
    id,
    title,
    initialValues = [],
    intialNodeValue = {} as T,
    className,
    enableCopyNode = false,
    enableAddNode = true,
    enableRemoveNode = true,
    disabledNodes,
    revertOrder = false,
    withBorder = false,
    oneEntryRequired = true,
    children,
    onChange,
    setDisabledNodes
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { values, dispatchValue, removeNode, addNode, copyNode } = useRemovableValuesArray(
    initialValues,
    intialNodeValue,
    revertOrder,
    enableCopyNode,
    disabledNodes,
    setDisabledNodes
  );

  useEffect(() => {
    if (onChange) onChange(values);
  }, [JSON.stringify(values)]);

  return (
    <div id={id} className={classNames(css['multivalue'], className)}>
      <div className={css['multivalue-header']}>
        <div>{title}</div>
        {(enableAddNode || values.length === 0) && (
          <Button id={`${id}-add`} icon="plus" type="text" onClick={addNode}>
            {getTranslation('general.add')}
          </Button>
        )}
      </div>
      <div className={css['items']}>
        {values.map((value, index) => {
          const itemId = `multi-value-item-${index}`;

          return (
            <MultiValueItem
              id={itemId}
              key={itemId}
              index={index}
              onRemove={removeNode(index)}
              onCopy={copyNode(index)}
              enableCopyNode={enableCopyNode}
              enableRemoveNode={enableRemoveNode && !(oneEntryRequired && values.length === 1)}
              disabled={typeof disabledNodes !== 'undefined' ? disabledNodes[index] : false}
              withBorder={withBorder}
            >
              {children({
                index,
                value,
                dispatchValue: (value) => dispatchValue(index, value)
              })}
            </MultiValueItem>
          );
        })}
      </div>
    </div>
  );
}
