import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../app-context';
import AllLocationsDialog from 'cards/ServicedLocations/AllLocationsDialog';
import { METHODS, sendRequest } from 'utils/request';
import { ContractServiceLocation, ContractType } from '@zf/api-types/master-data/contract';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { DialogClickRef, ValidationRef } from 'design-system/ComponentSets/Dialog/Dialog';
import { ContractServiceType } from '@zf/api-types/forecasting';

export type LProps = {
  id: string;
  clickRef: React.MutableRefObject<DialogClickRef | undefined>;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

const Locations: React.FC<LProps> = (props) => {
  const { contractStore } = useStore();

  const { forecastingService } = contractStore;
  const { getEavsForContract } = forecastingService;
  const { id, clickRef, validationRef } = props;
  const { i18n, tenantReducer } = useAppContext();
  const [data, setData] = useState<{
    serviceLocations: ContractServiceLocation[];
    contractServices: ContractServiceType[];
  }>({ serviceLocations: [], contractServices: [] });
  useEffect(() => {
    Promise.all([
      getEavsForContract(id),
      sendRequest<ContractType>({
        request: {
          method: METHODS.GET,
          endpoint: `/md/Contracts/${id}`
        },
        lang: i18n.lang,
        tenantReducer
      })
    ]).then((data) =>
      setData({ serviceLocations: data[1].data.serviceLocations, contractServices: data[0].contractServices })
    );
  }, [id]);

  return (
    <>
      {data.serviceLocations.length > 0 && data?.contractServices.length > 0 && (
        <AllLocationsDialog
          ref={clickRef}
          validationRef={validationRef}
          serviceLocations={data.serviceLocations}
          contractServices={data?.contractServices}
        />
      )}
    </>
  );
};
export default observer(Locations);
