import { CurrentState, ListPageState, DomainType } from '../app-state';
import useCurrent from './use-current';

export default function useUpdateListPage<D>(domain: DomainType) {
  const { myCurrent, setMyCurrent } = useCurrent(domain);

  const setUpdatedRows = (updatedRows: D[]) => {
    const newListpage: ListPageState = { ...myCurrent.listPage, updatedRows, deletedRows: [] };
    const newCurrent: CurrentState = { ...myCurrent, listPage: newListpage };
    setMyCurrent(newCurrent);
  };

  const setDeletedRows = (updatedRows: D[], deletedRows: Partial<D>[]) => {
    const newListpage: ListPageState = { ...myCurrent.listPage, updatedRows: updatedRows, deletedRows: deletedRows };
    const newCurrent: CurrentState = { ...myCurrent, listPage: newListpage };
    setMyCurrent(newCurrent);
  };

  return {
    setUpdatedRows,
    setDeletedRows
  };
}
