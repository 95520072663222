import { Card, CardBody } from '@zf/stella-react/src/atoms/Card';
import React from 'react';
import IssuesCardBody from './IssuesCardBody';
import css from './issues-card.module.scss';
import { IssuesInsightsType } from '@zf/api-types/analytics/metering';

type Props = {
  issuesInsights: IssuesInsightsType;
};

function IssuesCard(props: Props) {
  const { issuesInsights } = props;
  return (
    <Card id={'metering-issues-card'} className={css['metering-issues-card']}>
      <CardBody>
        <IssuesCardBody issuesInsights={issuesInsights} />
      </CardBody>
    </Card>
  );
}

export default IssuesCard;
