import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import WizardNavigator from 'app-context/stores/util/WizardNavigator';
import WizardValues from 'app-context/stores/util/WizardValues';
import clone from 'clone';
import { InfoBannerColor } from 'design-system/ComponentSets/InfoBanner/InfoBanner';
import { notify } from 'events/notification-events';
import { action, autorun, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';
import { calcNextInvoiceDate } from 'utils/contract';

import { navigate } from '@reach/router';
import { advanceFrequency, contractBillingMethod, dataFrequency, invoiceFrequency } from '@zf/api-types/enums';
import { ConsumerGroupType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption } from '@zf/api-types/master-data/contract';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { MeterType } from '@zf/api-types/master-data/meter';
import { PaymentTermType } from '@zf/api-types/product';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';

import LocationSectionStore, { locationSelection } from './LocationSectionStore';
import MoveInWizardBaseStore from './MoveInWizardBaseStore';
import {
  InvoiceBillingConfigurationSuggestions,
  InvoiceProductConfigurationSuggestions
} from '@zf/api-types/billing/property-group-billing-configuration';

export enum regularMoveInWizardSteps {
  movein = 'movein',
  locations = 'locations',
  customer = 'customer',
  contractdata = 'contractdata',
  billing = 'billing',
  optionalmeasurement = 'optionalmeasurement'
}

export enum customerType {
  newcustomer = 'newcustomer',
  existingcustomer = 'existingcustomer'
}

export type OptionalChannelType = {
  hour: string;
  minute: string;
  value: number | undefined;
  externalChannelIdentifier: string;
  dataFrequency: dataFrequency;
  description: string | null;
};

export type OptionalMeasurementType = {
  meterId: string;
  optionalChannels: OptionalChannelType[];
};

export type MappedOptionalMeasurementType = OptionalChannelType & {
  meterId: string;
};

type RegularMoveInWizardValues = {
  supplyStartDate: Moment | null;
  supplyEndDate: Moment | null;

  customerType: customerType;
  contractor: CustomerType | null;

  productId: string;
  hasCustomProductConfig: boolean;
  usePropertyGroupProduct: boolean;
  contractNumber: string;
  estimatedConsumptions: ContractedServiceEstimatedConsumption[];
  externalContractReference: string;

  billingMethod: contractBillingMethod;
  invoiceFrequency: invoiceFrequency | null;
  advanceFrequency: advanceFrequency | null;
  advanceAmount: number | undefined;
  invoiceUpfront: boolean;
  paymentTermsId: string;
  firstInvoiceStartDateTime: string;
  nextInvoiceDate: Moment | null;
  expectAdvancesToBeImportedPeriodically: boolean;

  hasOptionalMeasurement: boolean;
  meters: MeterType[] | undefined;
  optionalMeasurements: OptionalMeasurementType[];
};

export default class RegularMoveInWizardStore {
  public moveInWizardBaseStore: MoveInWizardBaseStore;
  private applicationStore: ApplicationStore;

  public locationSectionStore: LocationSectionStore;

  public suggestionMsgColorMap: Record<string, InfoBannerColor> = {
    BILLING_SETTINGS_COPIED_FROM_PROPERTY_GROUP: 'blue',
    PRODUCT_COPIED_FROM_SERVICE_LOCATION: 'blue',
    BILLING_SETTINGS_PROPERTY_GROUP_MIXED: 'orange',
    SERVICE_LOCATIONS_PRODUCTS_MIXED: 'orange'
  };

  public organizationConfig: OrganizationConfigType | undefined;
  public consumerGroups: ConsumerGroupType[] | undefined;
  public paymentTerms: PaymentTermType[] | undefined;

  public regularMoveInWizardValues = new WizardValues<RegularMoveInWizardValues>({
    supplyStartDate: null,
    supplyEndDate: null,

    customerType: customerType.existingcustomer,
    contractor: null,
    contractNumber: '',
    productId: '',
    hasCustomProductConfig: false,
    usePropertyGroupProduct: false,
    estimatedConsumptions: [],
    externalContractReference: '',

    billingMethod: contractBillingMethod.credit,
    invoiceFrequency: invoiceFrequency.yearly,
    advanceFrequency: advanceFrequency.monthly,
    advanceAmount: undefined,
    invoiceUpfront: false,
    paymentTermsId: '',
    firstInvoiceStartDateTime: MIN_DATE,
    nextInvoiceDate: null, // Only used if deviating
    expectAdvancesToBeImportedPeriodically: false,

    hasOptionalMeasurement: false,
    meters: undefined,
    optionalMeasurements: []
  });

  public regularMoveInWizardNavigator = new WizardNavigator<regularMoveInWizardSteps>(regularMoveInWizardSteps.movein);

  constructor(moveInWizardBaseStore: MoveInWizardBaseStore, applicationStore: ApplicationStore) {
    this.moveInWizardBaseStore = moveInWizardBaseStore;
    this.applicationStore = applicationStore;

    this.locationSectionStore = new LocationSectionStore(
      moveInWizardBaseStore,
      applicationStore,
      locationSelection.single
    );

    makeObservable(this, {
      locationSectionStore: observable,

      regularMoveInWizardValues: observable,

      organizationConfig: observable,
      consumerGroups: observable,
      paymentTerms: observable,

      updateValuesWithSuggestions: action,
      setCfgDependencies: action,
      initWizard: action,
      resetStore: action
    });

    autorun(() => {
      // Listen to API suggestion changes
      const { invoiceProductConfigurationSuggestions, invoiceBillingConfigurationSuggestions } =
        this.locationSectionStore;

      this.updateValuesWithSuggestions(invoiceProductConfigurationSuggestions, invoiceBillingConfigurationSuggestions);
    });

    autorun(() => {
      // Wait for cfg dependencies before triggering autorun
      if (this.organizationConfig) {
        const { values } = this.regularMoveInWizardValues;
        const { getTranslation } = this.applicationStore;
        const { setStepValidation } = this.regularMoveInWizardNavigator;

        // Move in section
        setStepValidation(
          regularMoveInWizardSteps.movein,
          'supplyPeriod',
          !isMinDate(values.supplyStartDate) && values.supplyStartDate !== null,
          getTranslation('contracts.validation.supply_start_date')
        );

        // Customer section
        setStepValidation(
          regularMoveInWizardSteps.customer,
          'contractor',
          !!values.contractor,
          getTranslation('customer.validation.customer_contract')
        );

        // Locations section
        const { locationSelectionType, selectedLocation, selectedLocations } = this.locationSectionStore;
        setStepValidation(
          regularMoveInWizardSteps.locations,
          'locations',
          (locationSelectionType === locationSelection.single && !!selectedLocation) ||
            (locationSelectionType === locationSelection.multiple && selectedLocations.size !== 0),
          getTranslation('contracts.validation.location')
        );

        // Contract data section
        setStepValidation(
          regularMoveInWizardSteps.contractdata,
          'contractId',
          this.organizationConfig.manuallySetContractNumber === false ||
            (this.organizationConfig.manuallySetContractNumber === true && !!values.contractNumber),
          getTranslation('contracts.validation.contract_id')
        );

        setStepValidation(
          regularMoveInWizardSteps.contractdata,
          'product',
          !!values.productId,
          getTranslation('contracts.validation.product')
        );

        setStepValidation(
          regularMoveInWizardSteps.contractdata,
          'eav',
          !values.estimatedConsumptions.some((ec) => typeof ec.value !== 'number' || isNaN(ec.value) || ec.value < 0),
          getTranslation('contracts.validation.eav')
        );

        // Billing section
        setStepValidation(
          regularMoveInWizardSteps.billing,
          'advanceAmount',
          values.advanceFrequency === advanceFrequency.none ||
            (values.billingMethod === contractBillingMethod.credit &&
              !values.invoiceUpfront &&
              !!values.advanceFrequency &&
              typeof values.advanceAmount !== 'undefined' &&
              !isNaN(values.advanceAmount)),
          getTranslation('contracts.validation.advance_amount')
        );

        // Optional measurement section
        if (values.hasOptionalMeasurement) {
          setStepValidation(
            regularMoveInWizardSteps.optionalmeasurement,
            'measurement',
            !values.optionalMeasurements.some((om) =>
              om.optionalChannels.some((oc) => !oc.externalChannelIdentifier && typeof oc.value !== 'undefined')
            ),
            getTranslation('contracts.validation.channel')
          );
        }
      }
    });
  }

  updateValuesWithSuggestions = (
    invoiceProductConfigurationSuggestions: InvoiceProductConfigurationSuggestions | undefined,
    invoiceBillingConfigurationSuggestions: InvoiceBillingConfigurationSuggestions | undefined
  ) => {
    const { values, setValue } = this.regularMoveInWizardValues;
    const { supplyStartDate } = values;

    if (invoiceProductConfigurationSuggestions || invoiceBillingConfigurationSuggestions) {
      const suggestions = { ...invoiceProductConfigurationSuggestions, ...invoiceBillingConfigurationSuggestions };

      const { productId, costAllocationEnabled = false } = suggestions;

      if (invoiceBillingConfigurationSuggestions?.message?.key === 'BILLING_SETTINGS_PROPERTY_GROUP_MIXED') {
        setValue({
          productId: productId || undefined,
          usePropertyGroupProduct: costAllocationEnabled,
          advanceFrequency: null,
          invoiceFrequency: null,
          paymentTermsId: undefined,
          nextInvoiceDate: calcNextInvoiceDate(supplyStartDate, null, null, null)
        });
      } else {
        let {
          advanceFrequency: advanceFrequency_,
          invoiceFrequency: invoiceFrequency_,
          paymentTermsId,
          invoiceMonth = null,
          invoiceDay = null
        } = suggestions;

        invoiceFrequency_ = invoiceFrequency_ || invoiceFrequency.yearly;

        setValue({
          productId: productId || undefined,
          usePropertyGroupProduct: costAllocationEnabled,
          advanceFrequency: advanceFrequency_ || advanceFrequency.monthly,
          invoiceFrequency: invoiceFrequency_,
          paymentTermsId: paymentTermsId || undefined,
          nextInvoiceDate: calcNextInvoiceDate(supplyStartDate, invoiceMonth, invoiceDay, invoiceFrequency_)
        });
      }
    }
  };

  setCfgDependencies = (
    orgCfg: OrganizationConfigType,
    consumerGroups: ConsumerGroupType[],
    terms: PaymentTermType[],
    prefilledCustomer?: CustomerType
  ) => {
    this.organizationConfig = orgCfg;
    this.consumerGroups = consumerGroups;
    this.paymentTerms = terms;

    if (prefilledCustomer) {
      this.regularMoveInWizardValues.setValue({ contractor: prefilledCustomer });
    }
  };

  initWizard = async (customerId?: string) => {
    const { contractStore } = this.moveInWizardBaseStore;
    const { getConsumerGroups } = contractStore.contractApiService;
    const { organisationStore, configStore, customerStore } = contractStore.rootStore;
    const { tenantReducer, getTranslation } = this.applicationStore;
    const { getOrganisationConfig } = organisationStore.organisationService;
    const { getPaymentTerms } = configStore.configService;
    const { getCustomerById } = customerStore.customerService;

    const promises: Promise<any>[] = [
      getOrganisationConfig(tenantReducer.organization?.organizationId || '', tenantReducer.tenant?.id || ''),
      getConsumerGroups(),
      getPaymentTerms()
    ];

    if (customerId) {
      promises.push(getCustomerById(customerId));
    }

    Promise.all(promises)
      // @ts-ignore
      .then((res) => this.setCfgDependencies(...res))
      .catch((error) => {
        notify.error({
          content: getTranslation('general.get_cfg_fail'),
          error
        });
      });
  };

  submitOptionalChannelMeasurement = async (optionalMsmt: MappedOptionalMeasurementType) => {
    const { addMeasurement } = this.moveInWizardBaseStore.contractStore.rootStore.meterStore.measurementService;
    const { values } = this.regularMoveInWizardValues;

    const endDateTime = clone(values.supplyStartDate);

    if (endDateTime) {
      endDateTime.set('hours', parseInt(optionalMsmt.hour));
      endDateTime.set('minutes', parseInt(optionalMsmt.minute));

      await addMeasurement({
        endDateTime: endDateTime.toISOString(),
        value: optionalMsmt.value || 0,
        externalChannelIdentifier: optionalMsmt.externalChannelIdentifier,
        meterId: optionalMsmt.meterId
      });
    }
  };

  submitOptionalMeasurements = async () => {
    const { getTranslation } = this.applicationStore;
    const { values } = this.regularMoveInWizardValues;

    const promises = values.optionalMeasurements.reduce((acc1: Promise<void>[], optionalMsmt) => {
      const mapped = optionalMsmt.optionalChannels.reduce((acc2: Promise<void>[], oChann) => {
        // Only submit the ones filled in
        if (typeof oChann.value !== 'undefined')
          acc2.push(
            this.submitOptionalChannelMeasurement({
              meterId: optionalMsmt.meterId,
              ...oChann
            })
          );

        return acc2;
      }, []);

      acc1.push(...mapped);

      return acc1;
    }, []);

    await Promise.all(promises).catch((error) => {
      notify.error({
        content: getTranslation('actions.meter.add_measurement_error'),
        error
      });
    });

    notify.success({
      content: getTranslation(
        `actions.meter.add_measurement${values.optionalMeasurements.length > 0 ? 's' : ''}_success`
      )
    });
  };

  handleSubmit = async () => {
    const { rootUrl, getTranslation } = this.applicationStore;

    try {
      const { createContract } = this.moveInWizardBaseStore.contractStore.contractApiService;
      const { values } = this.regularMoveInWizardValues;
      const { mapLocationsToApiFriendlyValues, wizardCalcNextInvoiceDate, updateInvoiceAddresses } =
        this.moveInWizardBaseStore.regularMoveInWizardStore.locationSectionStore;

      // Submit new contract
      const result = await createContract({
        contractorId: values.contractor?.id || '',
        contractNumber: values.contractNumber,
        supplyStartDate: values.supplyStartDate?.toISOString() || MIN_DATE,
        supplyEndDate: values.supplyEndDate?.toISOString() || null,
        contractedServiceLocations: mapLocationsToApiFriendlyValues(values.estimatedConsumptions),
        invoiceFrequency: values.invoiceFrequency,
        advanceFrequency:
          values.advanceFrequency && !values.invoiceUpfront ? values.advanceFrequency : advanceFrequency.none,
        contractualAdvanceAmount:
          values.advanceAmount && values.advanceFrequency !== advanceFrequency.none && !values.invoiceUpfront
            ? values.advanceAmount
            : 0,
        productId: values.productId,
        usePropertyGroupProduct: values.usePropertyGroupProduct,
        firstInvoiceStartDateTime: values.firstInvoiceStartDateTime,
        firstInvoiceEndDateTime:
          values.nextInvoiceDate?.toISOString() ||
          wizardCalcNextInvoiceDate(values.supplyStartDate, values.invoiceFrequency).toISOString() ||
          MIN_DATE,
        externalContractReference: values.externalContractReference,
        paymentTermsId: values.paymentTermsId,
        expectAdvancesToBeImportedPeriodically: values.expectAdvancesToBeImportedPeriodically,
        attachmentSignatures: null,
        invoiceAddress: values.contractor?.invoiceAddress || null,
        billingMethod: values.billingMethod
      });

      notify.success({
        content: getTranslation('contracts.wizard.notify_created')
      });

      // Update old contractor invoice addresses
      await updateInvoiceAddresses();

      // Send optional measurements
      if (values.hasOptionalMeasurement && values.supplyStartDate) {
        await this.submitOptionalMeasurements();
      }

      // Go to detail page of the newly created contract
      navigate(`${rootUrl}/contracts/${result.id}`);
    } catch (error) {
      notify.error({
        content: getTranslation('contracts.wizard.created_fail'),
        error
      });
    }
  };

  /**
   * General
   */
  resetStore = () => {
    this.regularMoveInWizardValues.reset();
    this.regularMoveInWizardNavigator.reset();
    this.locationSectionStore.resetStore();
  };
}
