import { PagedResponseType } from '@zf/api-types/api';
import { CommentType } from '@zf/api-types/comment';
import { entitySubjectType } from '@zf/api-types/enums';

import { METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';

export default class CommentsService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/activity/comments/', applicationStore);
  }

  getComments = async (subjectType: entitySubjectType, subjectId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<CommentType>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}${subjectType}/${subjectId}`
        }
      })
    ).data.results;
  };

  addComment = async (subjectType: entitySubjectType, subjectId: string, memo: string) => {
    return (
      await this.applicationStore.sendRequest<CommentType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${subjectType}/${subjectId}`,
          data: { memo }
        }
      })
    ).data;
  };

  deleteComment = async (subjectType: entitySubjectType, subjectId: string, id: string) => {
    return (
      await this.applicationStore.sendRequest<boolean>({
        request: {
          method: METHODS.DELETE,
          endpoint: `${this.endpoint}${subjectType}/${subjectId}/${id}`
        }
      })
    ).data;
  };
}
