import clone from 'clone';
import React from 'react';

import { billingItemTariffCalculationType } from '@zf/api-types/enums';
import { tariffCalculationType } from '@zf/api-types/local-enums';
import {
  BillingTariffInputType,
  StairStepTariffCalculationTypeParametersType,
  TariffCalculationTypeParametersType,
  TieredTariffCalculationTypeParametersType,
  UnitPriceTariffCalculationTypeParametersType,
  VolumeTariffCalculationTypeParametersType
} from '@zf/api-types/product';

import css from '../add-edit-tariff-dialog.module.scss';
import { TariffValidatorType } from '../AddEditTariffDialog';
import StairStepCalculationParameters from './stair-step-calculation-parameters';
import TieredCalculationParameters from './tiered-calculation-parameters';
import UnitPriceCalculationParameters from './unitprice-calculation-parameters';
import VolumeCalculationParameters from './volume-calculation-parameters';

type Props = {
  values: TariffValidatorType;
  setValue: (value: Partial<TariffValidatorType>, initialApiSet?: boolean, backup_?: boolean) => void;
};

export function CalculationParameters(props: Props) {
  const { values, setValue } = props;

  const setParametersValue = (key: any, value: any) => {
    const parametersClone = clone(values.calculationParameters) as Record<string, any>;
    parametersClone[key] = value;
    setValue({ calculationParameters: parametersClone as TariffCalculationTypeParametersType });
  };

  const setFormula = (expression: string, inputParameters: BillingTariffInputType[]) => {
    const parametersClone = clone(values.calculationParameters) as Record<string, any>;
    parametersClone.expression = expression;
    parametersClone.inputParameters = inputParameters;
    setValue({ calculationParameters: parametersClone as TariffCalculationTypeParametersType });
  };

  const toggleIsFormula = (formulaBased: boolean) => {
    const parametersClone = clone(values.calculationParameters) as Record<string, any>;

    if (formulaBased) {
      // Remove unit tariff
      delete parametersClone.unitTariff;
    } else {
      // Reset expression and remove input parameters
      parametersClone.expression = '';
      delete parametersClone.inputParameters;
    }

    parametersClone.formulaBased = formulaBased;

    setValue({ calculationParameters: parametersClone as TariffCalculationTypeParametersType });
  };

  const generateParameters = () => {
    switch (values.calculationParameters.type) {
      case billingItemTariffCalculationType.unitprice: {
        const params = values.calculationParameters as UnitPriceTariffCalculationTypeParametersType;
        return (
          <UnitPriceCalculationParameters
            calcParams={params}
            setParametersValue={setParametersValue}
            setFormula={setFormula}
            toggleIsFormula={toggleIsFormula}
          />
        );
      }
      case billingItemTariffCalculationType.volume: {
        const tariff = values.calculationParameters as VolumeTariffCalculationTypeParametersType;
        return (
          <VolumeCalculationParameters
            initialSlices={tariff ? tariff.slices : undefined}
            setParametersValue={setParametersValue}
          />
        );
      }
      case billingItemTariffCalculationType.tiered: {
        const tariff = values.calculationParameters as TieredTariffCalculationTypeParametersType;
        return (
          <TieredCalculationParameters
            initialSlices={tariff ? tariff.slices : undefined}
            setParametersValue={setParametersValue}
          />
        );
      }
      case billingItemTariffCalculationType.stairstep: {
        const tariff = values.calculationParameters as StairStepTariffCalculationTypeParametersType;

        return (
          <StairStepCalculationParameters
            initialSlices={tariff ? tariff.slices : undefined}
            setParametersValue={setParametersValue}
          />
        );
      }
    }
  };

  return (
    <div className={values.tariffCalculationType !== tariffCalculationType.unitprice ? css['parameters'] : undefined}>
      {generateParameters()}
    </div>
  );
}
