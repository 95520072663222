import SearchBar from '@zf/heat-erp/src/components/input/SearchBar';
import classNames from 'classnames';
import React, { useState } from 'react';

import { Icon } from '../../Icon';
import StellaInputField from '../../InputField/StellaInputField';
import TooltipTrigger from '../../Tooltip/TooltipTrigger/TooltipTrigger';
import css from '../dropdown.module.scss';
import useDropdownFunctions from '../hooks/useDropdownFunctions';
import SimpleDropdownContent from './SimpleDropdownContent';
import { SimpleDropdownProps } from './StellaSimpleDropdown';

type BodyProps = {
  dropdownWidth?: number;
  showTooltip: boolean;
  viewType?: 'search' | 'dropdown';
  filters?: JSX.Element | null;
  details?: JSX.Element | null;
  selectSingleItemByDefault?: boolean;
  setShowTooltip: (value: boolean) => void;
  handleOpen: () => void;
};

export default function SimpleDropdownBody<T>(props: SimpleDropdownProps<T> & BodyProps) {
  const {
    id,
    placeholder,
    values,
    selectedValues,
    disabled = false,
    selectSingleItemByDefault = false,
    viewType = 'dropdown',
    error,
    errors,
    singleError,
    extraRight,
    autoFocus,
    showTooltip,
    dropdownWidth,
    darkMode,
    onChange,
    onFocus,
    setShowTooltip,
    handleOpen
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(undefined);

  const { onClickItem, onBlur, handleKeyDown, getShownValues } = useDropdownFunctions({
    id,
    values,
    focusedIndex,
    searchValue,
    selectedValues,
    selectSingleItemByDefault,
    onChange,
    setShowTooltip,
    setFocusedIndex,
    setSearchValue,
    idBased: false
  });

  return (
    <TooltipTrigger
      tooltip={
        <SimpleDropdownContent
          {...props}
          extraRight={extraRight}
          searchValue={searchValue}
          focusedIndex={focusedIndex}
          viewType={viewType}
          setSearchValue={setSearchValue}
          setFocusedIndex={setFocusedIndex}
          onClickItem={onClickItem}
          getShownValues={getShownValues}
          handleKeyDown={handleKeyDown}
        />
      }
      placement="bottom-start"
      trigger="click"
      showTooltip={showTooltip}
      width={dropdownWidth}
      disabled={disabled}
    >
      {viewType === 'dropdown' ? (
        <>
          <StellaInputField
            singleError={singleError}
            id={id}
            className={css['dropdown']}
            value={searchValue}
            placeholder={placeholder}
            disabled={disabled}
            error={error}
            errors={errors}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            iconRight={disabled ? null : <Icon name="caret-down" className={css['dropdown-icon']} />}
            darkMode={darkMode}
            onChange={setSearchValue}
            onClick={handleOpen}
            onFocus={onFocus}
            onBlur={onBlur}
            readonly
          />
        </>
      ) : (
        <SearchBar
          id={id}
          value={searchValue}
          placeholder={placeholder}
          error={error}
          onChange={(val) => {
            setSearchValue(val);
            if (!val) onChange([]);
          }}
          onClick={handleOpen}
          onFocus={onFocus}
        />
      )}
    </TooltipTrigger>
  );
}
