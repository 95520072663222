import React from 'react';

import { UpdateConsumptionUnitTypeType } from '@zf/api-types/master-data/consumption-unit';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import ConsumptionUnitTypesContent from './consumption-unit-types-content';

type Props = {
  config: UseConfigReturnType<UpdateConsumptionUnitTypeType[]>;
};

export default function ConsumptionUnitTypes(props: Props) {
  const { config } = props;

  const { Provider, useTracked } = useCreateContext<UpdateConsumptionUnitTypeType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <ConsumptionUnitTypesContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
