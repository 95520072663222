import classNames from 'classnames';
import React from 'react';

import { BaseLink } from '../../design-system/Components';
import css from './nav-list-sub-item.module.scss';

export type PropsType = {
  id: string;
  title: string;
  className?: string;
  active?: boolean;
  accent?: boolean;
  href?: string;
};

export default function NavListSubItem(props: PropsType) {
  const { id, title, active, accent = false, href = '', className } = props;

  const listItemLink = (
    <li className={css['list-item']}>
      <BaseLink
        id={id}
        url={href}
        className={classNames(css['setting-list-item'], className, {
          [css['active']]: active,
          [css['accent']]: accent
        })}
      >
        <p className={css['setting-list-item-text']}>{title}</p>
      </BaseLink>
    </li>
  );

  return listItemLink;
}
