import React from 'react';

import { UserDetails } from '@zf/api-types/auth';

import { AUTH_BASE_URL } from '../../constants/authentication';
import { useStore } from '../../hooks/useStore';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Props = {
  className?: string;
};

export default function UserAutoFill(props: Props & CustomAutoFillProps<UserDetails>) {
  const { id, selectedValues, placeholder, initialValue, query, queryField, onChange, onFocus } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const processAutoFillItem = (value: UserDetails) => {
    if (value) {
      return { value, text: value.userName || `${value.email} - ${getTranslation('user.not_yet_accepted')}` };
    }
    return;
  };

  const autoFillTools = useAutoFill({
    endpoint: `${AUTH_BASE_URL}/api/UserAuth/users`,
    processRecord: processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, values } = autoFillTools;

  return (
    <SearchAutoFill
      {...props}
      id={id}
      values={values}
      selectedValues={selectedValues}
      placeholder={placeholder}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
