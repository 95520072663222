import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import WizardNavigator from 'app-context/stores/util/WizardNavigator';
import WizardValues from 'app-context/stores/util/WizardValues';
import { InfoBannerColor } from 'design-system/ComponentSets/InfoBanner/InfoBanner';
import { notify } from 'events/notification-events';
import { supplyDateType } from 'features/contract/src/wizard/shared/SupplyDateInputs/SupplyDateInputs';
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { navigate } from '@reach/router';
import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { advanceFrequency, unitOfMeasure } from '@zf/api-types/enums';
import { GetServiceLocationMatchingConsumerGroupsType } from '@zf/api-types/forecasting';
import { ContractedServiceEstimatedConsumption, ContractType } from '@zf/api-types/master-data/contract';
import { RequiredEstimationsType } from '@zf/api-types/product';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';

import LocationSectionStore, { locationSelection } from './LocationSectionStore';
import MoveInWizardBaseStore from './MoveInWizardBaseStore';

export enum addLocationsMoveInWizardSteps {
  movein = 'movein',
  locations = 'locations',
  billing = 'billing'
}

type AddLocationsMoveInWizardStoreValues = {
  existingContract: ContractType | null;
  productId: string | undefined;
  addedEstimatedConsumptions: ContractedServiceEstimatedConsumption[];
  supplyDateType_: supplyDateType;
  supplyDate: Moment | null;
  billingCompleteness: BillingCompletenessType | undefined;
  advanceAmount: number | undefined;
};

export default class AddLocationsMoveInWizardStore {
  public moveInWizardBaseStore: MoveInWizardBaseStore;
  private applicationStore: ApplicationStore;

  public locationSectionStore: LocationSectionStore;

  public suggestionMsgColorMap: Record<string, InfoBannerColor> = {
    SERVICE_LOCATIONS_PRODUCTS_MIXED_EXISTING_CONTRACT: 'orange',
    BILLING_SETTINGS_PROPERTY_GROUP_MIXED_EXISTING_CONTRACT: 'orange'
  };

  public addLocationsMoveInWizardValues = new WizardValues<AddLocationsMoveInWizardStoreValues>({
    existingContract: null,
    productId: undefined,
    addedEstimatedConsumptions: [],
    supplyDateType_: supplyDateType.specific,
    supplyDate: null,
    billingCompleteness: undefined,
    advanceAmount: undefined
  });

  public addLocationsMoveInWizardNavigator = new WizardNavigator<addLocationsMoveInWizardSteps>(
    addLocationsMoveInWizardSteps.movein
  );

  constructor(moveInWizardBaseStore: MoveInWizardBaseStore, applicationStore: ApplicationStore) {
    this.moveInWizardBaseStore = moveInWizardBaseStore;
    this.applicationStore = applicationStore;

    this.locationSectionStore = new LocationSectionStore(
      moveInWizardBaseStore,
      applicationStore,
      locationSelection.multiple
    );

    makeObservable(this, {
      locationSectionStore: observable,
      addLocationsMoveInWizardValues: observable,

      showBillingSection: computed,

      resetStore: action
    });

    autorun(() => {
      const { values, setValue } = this.addLocationsMoveInWizardValues;
      const { existingContract, productId } = values;
      const { selectedServices } = this.locationSectionStore;
      const { getRequiredEstimationsForProduct } =
        this.moveInWizardBaseStore.contractStore.rootStore.productConfigStore.productConfigService;
      const { getTranslation } = this.applicationStore;

      if (existingContract && productId) {
        getRequiredEstimationsForProduct(productId)
          .then((requiredEstimations) => {
            // We only need the requiredEstimations for the selected utilityTypes
            const filteredEstimations =
              requiredEstimations?.filter((re) => selectedServices.includes(re.utilityType)) || [];

            const propertyGroupIds = existingContract.propertyGroups?.map((p) => p.id) || [];

            Promise.all(filteredEstimations.map((re) => this.getMatching(productId, propertyGroupIds, re)))
              .then((matching) =>
                setValue({ addedEstimatedConsumptions: this.initEstimatedConsumptions(matching, filteredEstimations) })
              )
              .catch((e) => {
                throw e;
              });
          })
          .catch((error) => {
            notify.error({
              content: getTranslation('contracts.get_estimations_fail'),
              error
            });
          });
      }
    });

    autorun(() => {
      const { values } = this.addLocationsMoveInWizardValues;
      const { selectedLocations } = this.locationSectionStore;
      const { getTranslation } = this.applicationStore;
      const { setStepValidation } = this.addLocationsMoveInWizardNavigator;

      // Move in section
      setStepValidation(
        addLocationsMoveInWizardSteps.movein,
        'contract',
        !!values.existingContract,
        getTranslation('contracts.wizard.add.validation.contract')
      );

      // Locations section
      setStepValidation(
        addLocationsMoveInWizardSteps.locations,
        'locations',
        selectedLocations.size !== 0,
        getTranslation('contracts.validation.location')
      );

      setStepValidation(
        addLocationsMoveInWizardSteps.locations,
        'startDate',
        !!values.supplyDate && !isMinDate(values.supplyDate),
        getTranslation('contracts.wizard.add.validation.start_date')
      );

      setStepValidation(
        addLocationsMoveInWizardSteps.locations,
        'startDateWithinContractPeriod',
        !!values.existingContract &&
          !!values.supplyDate &&
          values.supplyDate.isBetween(
            values.existingContract.supplyStartDate,
            values.existingContract.supplyEndDate,
            undefined,
            '[]'
          ),
        getTranslation('contracts.wizard.validation.date_within_contract_period')
      );

      if (this.showBillingSection) {
        // Billing section
        setStepValidation(
          addLocationsMoveInWizardSteps.billing,
          'advanceAmount',
          typeof values.advanceAmount !== 'undefined' && !isNaN(values.advanceAmount),
          getTranslation('contracts.validation.advance_amount')
        );
      }
    });
  }

  get showBillingSection() {
    const { selectedLocations } = this.locationSectionStore;
    const { values } = this.addLocationsMoveInWizardValues;
    const { existingContract } = values;

    // Advance amount is only known after selecting a contract and locations should be selected
    if (!existingContract || selectedLocations.size === 0) {
      return false;
    }

    // Only render this section when advanceFrequency is useful
    if (
      existingContract.billingDetails.advanceFrequency === null ||
      existingContract.billingDetails.advanceFrequency === advanceFrequency.none
    ) {
      return false;
    }

    return true;
  }

  getMatching = async (productId: string, propertyGroupIds: string[], re: RequiredEstimationsType) => {
    const { getMatchingConGroups } = this.moveInWizardBaseStore.contractStore.contractApiService;

    return (
      await getMatchingConGroups({
        productId,
        propertyGroupIds,
        utilityType: re.utilityType
      })
    ).matchingConsumerGroups[re.unitOfMeasure];
  };

  initEstimatedConsumptions = (
    matchingGroups: GetServiceLocationMatchingConsumerGroupsType[][],
    requiredEstimations: RequiredEstimationsType[]
  ): ContractedServiceEstimatedConsumption[] => {
    return requiredEstimations.map((re, index) => {
      const {
        id = '',
        estimatedAnnualVolume,
        unitOfMeasure: unitOfMeasure_ = unitOfMeasure.none,
        utilityType
      } = matchingGroups[index]?.[0]?.consumerGroup;

      return {
        consumerGroupId: id,
        value: estimatedAnnualVolume,
        unitOfMeasure: unitOfMeasure_,
        meteringType: re.meteringType,
        utilityType,
        isManualEntry: false
      };
    });
  };

  handleSubmit = async () => {
    const { rootUrl, getTranslation } = this.applicationStore;

    try {
      const { values, backup } = this.addLocationsMoveInWizardValues;
      const { existingContract, supplyDate, advanceAmount, addedEstimatedConsumptions } = values;

      if (existingContract) {
        const { contractApiService, getEstimatedConsumptionsForSelectedContract, updateAdvanceAmount } =
          this.moveInWizardBaseStore.contractStore;
        const { addLocationsToContract } = contractApiService;
        const { mapLocationsToApiFriendlyValues, updateInvoiceAddresses } = this.locationSectionStore;

        const estimatedConsumptions = getEstimatedConsumptionsForSelectedContract(existingContract);
        const services = mapLocationsToApiFriendlyValues([...estimatedConsumptions, ...addedEstimatedConsumptions]);

        // Update contract request
        let updatedContract = await addLocationsToContract(existingContract.id, {
          supplyStartDate: supplyDate?.toISOString() || MIN_DATE,
          services
        });

        notify.success({
          content: getTranslation('contracts.wizard.add_locations_success')
        });

        // Update advance amount
        if (advanceAmount !== backup.advanceAmount) {
          await updateAdvanceAmount(existingContract, advanceAmount || 0);
        }

        // Update old contractor invoice addresses
        await updateInvoiceAddresses();

        // Go to detail page of updated contract
        navigate(`${rootUrl}/contracts/${updatedContract.id}`);
      }
    } catch (error) {
      notify.error({
        content: getTranslation('contracts.wizard.add_locations_fail'),
        error
      });
    }
  };

  /**
   * General
   */
  resetStore = () => {
    this.addLocationsMoveInWizardValues.reset();
    this.addLocationsMoveInWizardNavigator.reset();
    this.locationSectionStore.resetStore();
  };
}
