import { observer } from 'mobx-react';
import React, { useEffect, useReducer } from 'react';

import { countryCode, pluginType } from '@zf/api-types/enums';
import { configPage } from '@zf/api-types/local-enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { useAppContext } from '../../app-context';
import { useStore } from '../../hooks/useStore';
import NavList from './nav-list';
import NavListSubItem from './nav-list-sub-item';
import css from './side-bar.module.scss';

type Props = {
  activePage: string;
};

type State = {
  isSuperUser: boolean;
  portalEnabled: boolean;
  country: countryCode | undefined;
};

const ConfigSideBar = (props: Props) => {
  const { activePage } = props;

  const { authReducer } = useAppContext();
  const { configStore, applicationStore, organisationStore } = useStore();
  const { rootUrl, tenantReducer, getTranslation } = applicationStore;
  const { isPluginEnabled } = configStore;
  const { getOrganisationConfig } = organisationStore.organisationService;
  const { getConfiguredPlugins } = configStore.configService;
  const { isSuperUser } = authReducer;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    isSuperUser: false,
    portalEnabled: false,
    country: undefined
  });

  useEffect(() => {
    Promise.all([
      getConfiguredPlugins(),
      getOrganisationConfig(tenantReducer.organization?.organizationId || '', tenantReducer.tenant?.id || '')
    ]).then((res) => {
      setState({
        isSuperUser: isSuperUser(),
        portalEnabled: isPluginEnabled(pluginType.enduserportal),
        country: res?.[1].address?.country as countryCode
      });
    });
  }, []);

  return (
    <div className={css['side-bar-config']}>
      <div className={css['config-nav-list']}>
        <NavList>
          <NavListSubItem
            id={`${configPage.settings}-cfg`}
            href={`${rootUrl}/configuration`}
            title={getTranslation('nav.configuration')}
            active={
              !activePage ||
              activePage === 'general' ||
              activePage === 'organisation' ||
              activePage === 'parameters' ||
              activePage === 'bank_accounts' ||
              activePage === 'number_sequences' ||
              activePage === 'custom_entity_properties'
            }
          />
          <NavListSubItem
            id={`${configPage.products}-cfg`}
            href={`${rootUrl}/configuration/product`}
            title={getTranslation('nav.products')}
            active={activePage === 'product'}
          />
          <NavListSubItem
            id={`${configPage.payments}-cfg`}
            href={`${rootUrl}/configuration/payments`}
            title={getTranslation('nav.config_payment')}
            active={activePage === 'payments'}
          />
          <NavListSubItem
            id={`${configPage.costallocations}-cfg`}
            href={`${rootUrl}/configuration/cost-allocation`}
            title={getTranslation('nav.cost_allocation')}
            active={activePage === 'cost-allocation'}
          />
          <NavListSubItem
            id={`${configPage.metering}-cfg`}
            href={`${rootUrl}/configuration/meter`}
            title={getTranslation('nav.config_metering')}
            active={activePage === 'meter'}
          />
          <NavListSubItem
            id={`${configPage.importjobs}-cfg`}
            href={`${rootUrl}/configuration/import-jobs`}
            title={getTranslation('nav.import-jobs')}
            active={activePage === 'import-jobs'}
          />
          <NavListSubItem
            id={`${configPage.exportjobs}-cfg`}
            href={`${rootUrl}/configuration/export-jobs`}
            title={getTranslation('nav.export-jobs')}
            active={activePage === 'export-jobs'}
          />
          <NavListSubItem
            id={`${configPage.communication}-cfg`}
            href={`${rootUrl}/configuration/communication`}
            title={getTranslation('nav.communication')}
            active={activePage === 'communication'}
          />
          {state.isSuperUser && (
            <NavListSubItem
              id={`${configPage.integrationlogging}-cfg`}
              href={`${rootUrl}/configuration/integration-logging`}
              title={getTranslation('nav.integration_logging')}
              active={activePage === 'integration-logging'}
            />
          )}
          {state.portalEnabled && (
            <NavListSubItem
              id={`${configPage.customerportal}-cfg`}
              href={`${rootUrl}/configuration/portal-config`}
              title={getTranslation('portal.portal')}
              active={activePage === 'portal-config'}
            />
          )}
          {state.country === countryCode.bel && (
            <NavListSubItem
              id={`${configPage.countryspecifics}-cfg`}
              href={`${rootUrl}/configuration/country-specifics`}
              title={getTranslation('country_specifics.country_specifics')}
              active={activePage === 'country-specifics'}
            />
          )}
        </NavList>
      </div>
    </div>
  );
};

export default observer(ConfigSideBar);
