import React from 'react';

import {
  IncomingBankingTransactionRowType,
  IncomingBankingTransactionType
} from '@zf/api-types/billing/incoming-banking-transaction';
import { incomingBankingTransactionStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import ProcessIncomingBankingTransactionDialog from '../../../actions/incoming-banking-transactions/ProcessIncomingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function ProcessIncomingBankingTransactionListPageButton(props: Props) {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('incoming_banking_transaction');
  const selectedRows: IncomingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<IncomingBankingTransactionType>('incoming_banking_transaction');
  const { clickRef, onSubmit } = useDialog();

  const isDisabled =
    selectedRows.some((r) => {
      return r.__entity.status !== incomingBankingTransactionStatus.readytoprocess;
    }) || selectedRows.length === 0;

  return (
    <DropdownAction
      id="incoming_banking_transaction.process"
      icon="process"
      dialogTitle={i18n.getTranslation('general.process')}
      dialogContent={
        <ProcessIncomingBankingTransactionDialog
          {...props}
          ref={clickRef}
          selectedRows={selectedRows}
          setUpdatedRows={setUpdatedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={isDisabled}
      buttonPositive={i18n.getTranslation('general.process')}
    />
  );
}
