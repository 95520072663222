import React from 'react';

import { Card, CardBody, CardHeader } from '../Card';
import { CardWidthType } from '../Card/Card';
import { Spinner } from './';
import css from './card-spinner.module.scss';

type Props<T> = {
  title: string;
  width?: CardWidthType;
  height?: string;
};

export default function CardSpinner<T>(props: Props<T>) {
  const { title, width = '1', height } = props;

  return (
    <Card width={width}>
      <CardHeader>{title}</CardHeader>
      <CardBody fixedHeight={!height} height={height}>
        <div className={css['spinner-wrapper']}>
          <Spinner />
        </div>
      </CardBody>
    </Card>
  );
}
