import { CollectionCaseType } from '@zf/api-types/collection-case';

import { METHODS } from '../../../utils/request';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';

export default class CollectionCaseService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/bill/CollectionCases/', applicationStore);
  }

  sendViaPostal = async (collectionCaseId: string, stepId: string) => {
    return (
      await this.applicationStore.sendRequest<CollectionCaseType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${collectionCaseId}/${stepId}/sendviapostal`
        }
      })
    ).data;
  };

  sendViaMail = async (collectionCaseId: string, stepId: string) => {
    return (
      await this.applicationStore.sendRequest<CollectionCaseType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${collectionCaseId}/${stepId}/sendviamail`
        }
      })
    ).data;
  };

  executeCollectionStep = async (collCaseId: string, stepId: string) => {
    return (
      await this.applicationStore.sendRequest<CollectionCaseType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${collCaseId}/${stepId}/execute`
        }
      })
    ).data;
  };

  retryCollectionStep = async (collCaseId: string, stepId: string) => {
    return (
      await this.applicationStore.sendRequest<CollectionCaseType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${collCaseId}/${stepId}/retry`
        }
      })
    ).data;
  };

  skipCollectionStep = async (collCaseId: string, stepId: string) => {
    return (
      await this.applicationStore.sendRequest<CollectionCaseType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}${collCaseId}/${stepId}/skip`
        }
      })
    ).data;
  };

  closeCollectionStep = async (collCaseId: string, stepId: string) => {
    return (
      await this.applicationStore.sendRequest<CollectionCaseType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/CollectionCases/${collCaseId}/${stepId}/close`
        }
      })
    ).data;
  };
}
