import React from 'react';

import { ContractOverviewCountType } from '@zf/api-types/master-data/contract';

import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { customerType } from '@zf/api-types/enums';
import { ArrayParam, StringParam } from 'serialize-query-params';

const initialContractOverviewCount: ContractOverviewCountType = {
  all: 0,
  requireAttention: 0,
  draft: 0,
  blocked: 0,
  ongoing: 0
};

type QueryParam = {
  propertyGroupId: string;
  propertyGroupName: string;
  customerGroupName: string;
  customerGroupId: string;
  customerType?: customerType[];
  customerId: string;
  customerName: string;
  productId: string;
  productName: string;
};

const initialQueryParams = {
  propertyGroupId: '',
  propertyGroupName: '',
  customerGroupName: '',
  customerGroupId: '',
  customerId: '',
  customerName: '',
  productId: '',
  productName: ''
};

const paramTypes = {
  propertyGroupId: StringParam,
  propertyGroupName: StringParam,
  customerGroupName: StringParam,
  customerGroupId: StringParam,
  customerType: ArrayParam,
  customerId: StringParam,
  customerName: StringParam,
  productId: StringParam,
  productName: StringParam
};

const useContractFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams
  } = useFilter<QueryParam>({
    endpoint: '/md/contracts/overviewcount',
    initialCount: initialContractOverviewCount,
    domain: 'contract',
    search,
    initialQueryParams,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.customerType && queryParams.customerType.length > 0) {
    tags.push(
      <FilterTag
        type="enum"
        enumType="customerType"
        value={queryParams.customerType[0]}
        removeFilter={() => setQueryParams({ customerType: [] as customerType[] })}
        key="customer-type"
      />
    );
  }

  if (queryParams.propertyGroupId && queryParams.propertyGroupName) {
    tags.push(
      <FilterTag
        value={queryParams.propertyGroupName}
        removeFilter={() => setQueryParams({ propertyGroupId: '', propertyGroupName: '' })}
        key="property-group"
      />
    );
  }
  if (queryParams.customerGroupId && queryParams.customerGroupName) {
    tags.push(
      <FilterTag
        value={queryParams.customerGroupName}
        removeFilter={() => setQueryParams({ customerGroupId: '', customerGroupName: '' })}
        key="customer-group"
      />
    );
  }

  if (queryParams.customerId && queryParams.customerName) {
    tags.push(
      <FilterTag
        value={queryParams.customerName}
        removeFilter={() => setQueryParams({ customerId: '', customerName: '' })}
        key="customer"
      />
    );
  }

  if (queryParams.productId && queryParams.productName) {
    tags.push(
      <FilterTag
        value={queryParams.productName}
        removeFilter={() => setQueryParams({ productId: '', productName: '' })}
        key="product"
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams,
    quickFilter,
    overviewCount,
    searchValue,
    initialQueryParams,
    filterTags,
    queryParams
  };
};

export default useContractFilter;
