import { observer } from 'mobx-react';
import React from 'react';

import { StepAnchor, WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useStore } from '../../../../hooks/useStore';
import { CreateCustomerType, STEP_NAMES } from '../CustomerWizard';
import CustomerBankingDetails, { BankingDetailsBaseType } from './CustomerBankingDetails';

type Props = {
  values: CreateCustomerType;
  customerName: {
    name: string;
  };
  setBankingDetail: (
    value: BankingDetailsBaseType[keyof BankingDetailsBaseType],
    key: keyof BankingDetailsBaseType
  ) => void;
  handleFocus: (step: string) => () => void;
};

const BankingDetailsSection = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[1]} />
        {getTranslation('customer.steps.banking')}
      </WizardHeader>
      <CustomerBankingDetails {...props} />
    </WizardSection>
  );
};

export default observer(BankingDetailsSection);
