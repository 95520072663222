import classNames from 'classnames';
import React from 'react';

import { ZFErrorType } from '@zf/api-types/general';

import Heading from '../Heading/Heading';
import Paragraph from '../Paragraph/Paragraph';
import Spinner from '../Spinner/Spinner';
import css from './table-overlay.module.scss';

export type ErrorOverlayProps = {
  error: ZFErrorType | null;
  errorText?: string;
};

export type LoadingOverlayType = (props: {}) => JSX.Element;
export type ErrorOverlayType = (props: ErrorOverlayProps) => JSX.Element;
export type NoDataOverlayType = (props: {}) => JSX.Element;

export type Props = {
  LoadingOverlay?: LoadingOverlayType;
  ErrorOverlay?: ErrorOverlayType;
  NoDataOverlay?: NoDataOverlayType;
  NoDataOverlayWithAdd?:  (props: any) => JSX.Element | undefined;
  withAddCustomer?: boolean;
  error?: ZFErrorType | null;
  loading?: boolean;
  noData?: boolean;
  width: string | number;
  height: string | number;
  noDataText?: string;
  errorText?: string;
  backGroundColor?: string;
};

type NoDataProps = {
  noDataText?: string;
};

function ErrorOverlayDefault(props: ErrorOverlayProps) {
  const { errorText } = props;
  return (
    <div className={css['error-overlay-default']}>
      <Heading headingType="h2" className={css['heading']}>
        {errorText}
      </Heading>
      <Paragraph>{props.error && props.error.message}</Paragraph>
    </div>
  );
}

function NoDataOverlayDefault(props: NoDataProps) {
  const { noDataText } = props;
  return (
    <>
      <Heading headingType="h2">{noDataText}</Heading>
    </>
  );
}

export default function TableOverlay(props: Props) {
  const {
    LoadingOverlay = Spinner,
    ErrorOverlay = ErrorOverlayDefault,
    NoDataOverlay = NoDataOverlayDefault,
    error,
    loading,
    noData,
    width,
    height,
    noDataText,
    errorText
  } = props;

  const style: React.CSSProperties = { width, height };

  const renderOverlay = () => {
    if (error) {
      return <ErrorOverlay error={error} errorText={errorText} />;
    }

    if (loading) {
      return <LoadingOverlay />;
    }

    if (noData) {
      return <NoDataOverlay noDataText={noDataText} />;
    }

    return null;
  };

  const overlay = renderOverlay();
  return (
    <div className={css['overlay-wrapper']}>
      <div
        className={classNames(css['table-overlay'], {
          [css['table-overlay-hidden']]: !overlay
        })}
        style={style}
      >
        {overlay}
      </div>
    </div>
  );
}
