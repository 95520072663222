import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { ImportJobType, OverviewType } from '@zf/api-types/import-job';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../app-context';
import { SuspenseCardBody } from '../../../../components/suspense';
import { DISABLED, INFO, SUCCESS, WARNING } from '../../../../constants/color';
import css from './pie-chart.module.scss';

import { colors } from '@zf/utils/src/color';
import { importJobStatus } from '@zf/api-types/enums';

export type PropsType = {
  overview: OverviewType;
  importJob: ImportJobType;
};

type OverviewCellType = {
  name: string;
  value: number;
  color: string;
};

function CardContent(props: PropsType) {
  const { overview, importJob } = props;
  const { i18n } = useAppContext();
  let data: OverviewCellType[] = [];

  if (
    importJob.status === importJobStatus.processed ||
    importJob.status === importJobStatus.processing ||
    importJob.status === importJobStatus.finished ||
    importJob.status === importJobStatus.postprocessing
  ) {
    data = [
      {
        name: i18n.getTranslation('import_jobs.processed'),
        value: overview.processed,
        color: SUCCESS
      },
      {
        name: i18n.getTranslation('import_jobs.unprocessed'),
        value: overview.unprocessed,
        color: DISABLED
      },
      {
        name: i18n.getTranslation('import_jobs.failed'),
        value: overview.failed,
        color: WARNING
      }
    ];
  } else if (
    importJob.status === importJobStatus.created ||
    importJob.status === importJobStatus.reading ||
    importJob.status === importJobStatus.storing ||
    importJob.status === importJobStatus.stored
  ) {
    data = [
      {
        name: i18n.getTranslation('import_jobs.unprocessed'),
        value: overview.unprocessed,
        color: DISABLED
      }
    ];
  }

  if (importJob.status === importJobStatus.failed) {
    if (overview.processed === 0) {
      data.push(
        {
          name: i18n.getTranslation('import_jobs.failed'),
          value: overview.failed,
          color: WARNING
        },
        {
          name: i18n.getTranslation('import_jobs.invalid'),
          value: overview.invalid,
          color: colors['blue-600']
        },
        {
          name: i18n.getTranslation('import_jobs.validated'),
          value: overview.validated,
          color: colors['blue-400']
        }
      );
    }
    if (overview.processed > 0) {
      data.push(
        {
          name: i18n.getTranslation('import_jobs.unprocessed'),
          value: overview.unprocessed,
          color: DISABLED
        },
        {
          name: i18n.getTranslation('import_jobs.processed'),
          value: overview.processed,
          color: SUCCESS
        },
        {
          name: i18n.getTranslation('import_jobs.failed'),
          value: overview.failed,
          color: WARNING
        }
      );
    }
  }

  const totalValuesData = [
    {
      name: i18n.getTranslation('general.total'),
      value: overview.total
    }
  ];

  return (
    <ResponsiveContainer width="95%" height="95%" className={css['graph']}>
      <PieChart>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Pie data={totalValuesData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill={INFO} />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill={INFO}
          label
        >
          {data.map((entry, index) => (
            <Cell fill={entry.color} key={index} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default function OverviewPieChart(props: PropsType) {
  const { i18n } = useAppContext();

  return (
    <Card className={css['overview-pie-chart-card']} id="overview-pie-chart">
      <CardHeader>{i18n.getTranslation('import_jobs.overview')}</CardHeader>
      <SuspenseCardBody noMaxHeight>
        <CardContent {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
