import classNames from 'classnames';
import { Observer } from 'mobx-react';
import React from 'react';

import {
  AddCustomerBankAccountActiveMandateRequestType,
  CustomerMandateType,
  UpdateCustomerBankAccountType
} from '@zf/api-types/billing/customer-bank-account';
import { mandateStatus } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context';
import Button from '../../../../components/Button/Button';
import { DeleteIcon } from '../../../../components/Icon';
import IconParagraph from '../../../../components/Icon/IconParagraph';
import InputField from '../../../../components/input/InputField';
import { useStore } from '../../../../hooks/useStore';
import css from './customer-bank-account.module.scss';
import Mandate from './Mandate';
import NewMandate from './NewMandate';
import { Spinner } from '../../../../design-system/Foundation';

type Props = {
  bankAccountIndex: number;
  bankAccount: UpdateCustomerBankAccountType;
  customer: CustomerType;
};

export default function CustomerBankAccount(props: Props) {
  const { bankAccount, bankAccountIndex } = props;
  const { i18n } = useAppContext();
  const { customerStore } = useStore();

  const [isDeleting, setIsDeleting] = React.useState(false);

  return isDeleting ? (
    <Observer>
      {() => (
        <div className={css['inline-delete-confirmation']}>
          <Paragraph bold>{i18n.getTranslation('customer.delete_bank_account_paragraph')}</Paragraph>
          <div>
            <Button
              id={`cancel-${bankAccountIndex}`}
              onClick={() => {
                setIsDeleting(false);
                customerStore.cancelChanges(bankAccountIndex);
              }}
              type="text"
              size="small"
              danger
            >
              {i18n.getTranslation('general.cancel')}
            </Button>

            <Button
              id={`yes-${bankAccountIndex}`}
              onClick={() => {
                customerStore.deleteBankAccount(bankAccountIndex);
              }}
              type="text"
              size="small"
            >
              {i18n.getTranslation('general.yes')}
            </Button>
          </div>
        </div>
      )}
    </Observer>
  ) : (
    <Observer>
      {() => (
        <div
          className={
            bankAccount.isDefault ? css['customer-bank-account-wrapper-default'] : css['customer-bank-account-wrapper']
          }
        >
          <div className={css['customer-bank-account']}>
            <InputField
              className={css['input-field-bankaccounts']}
              onChange={(val) => bankAccount.mandateForm?._set('iban', val)}
              value={bankAccount.mandateForm?._get('iban')}
              id={`iban-${bankAccountIndex}`}
              placeholder={i18n.getTranslation('mandates.iban')}
              error={!!bankAccount.mandateForm?._error('iban')}
              singleError={bankAccount.mandateForm?._error('iban')}
              darkMode
            />

            {bankAccount.mandateForm?._getNested('mandate', 'status') === mandateStatus.requested && (
              <NewMandate
                mandateForm={bankAccount.mandateForm}
                bankAccountIndex={bankAccountIndex}
                mandate={bankAccount.activeMandate as AddCustomerBankAccountActiveMandateRequestType}
              />
            )}

            {bankAccount.mandateForm?._getNested('mandate', 'number') && (
              <Mandate bankAccountIndex={bankAccountIndex} mandate={bankAccount.activeMandate as CustomerMandateType} />
            )}
            <div className={css['bottom-actions']}>
              {!customerStore.hasNewMandate(bankAccount) && (
                <Button
                  id={`add-mandate-${bankAccountIndex}`}
                  icon="plus"
                  type="text"
                  size="small"
                  className={classNames({ [css['border-red']]: customerStore.needsMandate(bankAccount) })}
                  onClick={() => customerStore.addMandate(bankAccountIndex)}
                >
                  {i18n.getTranslation('mandates.add_mandate')}
                </Button>
              )}

              <div>
                {!bankAccount.mandateForm?._isSubmitting && bankAccount.mandateForm?._isDirty && bankAccount.id && (
                  <Button
                    id={`cancel-${bankAccountIndex}`}
                    onClick={() => customerStore.cancelChanges(bankAccountIndex)}
                    type="text"
                    size="small"
                    danger
                  >
                    {i18n.getTranslation('general.cancel')}
                  </Button>
                )}
                {bankAccount.mandateForm?._isSubmitting ? (
                  <Spinner size="xsmall" />
                ) : (
                  <Button
                    id={`save-${bankAccountIndex}`}
                    onClick={() => {
                      bankAccount.mandateForm?._submit();
                    }}
                    type="text"
                    size="small"
                    disabled={!bankAccount.mandateForm?.isDirty || customerStore.needsMandate(bankAccount)}
                  >
                    {i18n.getTranslation('general.save')}
                  </Button>
                )}
              </div>
            </div>

            {customerStore.needsMandate(bankAccount) && (
              <IconParagraph iconType="alert-circle" color={colors['red-600']}>
                {i18n.getTranslation('mandates.no_mandate_warning')}
              </IconParagraph>
            )}
          </div>

          <DeleteIcon
            id={`remove-${bankAccountIndex}`}
            iconType="cross"
            onClick={() => {
              if (bankAccount.id) {
                setIsDeleting(!isDeleting);
              } else {
                customerStore.deleteBankAccount(bankAccountIndex);
              }
            }}
          />
        </div>
      )}
    </Observer>
  );
}
