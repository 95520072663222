import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import WizardNavigator from 'app-context/stores/util/WizardNavigator';
import WizardValues from 'app-context/stores/util/WizardValues';
import { notify } from 'events/notification-events';
import { action, autorun, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { navigate } from '@reach/router';
import { AddressType, LocalAddressType } from '@zf/api-types/general';
import { AddMeasurementRequestType } from '@zf/api-types/master-data/meter';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';
import { deepEqual } from '@zf/utils/src/object';

import MoveOutWizardBaseStore from './MoveOutWizardBaseStore';

export enum moveOutWizardSteps {
  moveout = 'moveout',
  movein = 'movein'
}

type MoveOutWizardStoreValues = {
  moveOutDate: Moment | null;
  oldContractorInvoiceAddress: AddressType;
  hasOptionalMeasurement: boolean;
  optionalMeasurements: AddMeasurementRequestType[];
  keepExistingInvoiceOnEndDate: boolean;
};

export default class MoveOutWizardStore {
  public moveOutWizardBaseStore: MoveOutWizardBaseStore;
  private applicationStore: ApplicationStore;

  public moveOutWizardValues = new WizardValues<MoveOutWizardStoreValues>({
    moveOutDate: null,
    oldContractorInvoiceAddress: {} as AddressType,
    hasOptionalMeasurement: false,
    optionalMeasurements: [],
    keepExistingInvoiceOnEndDate: false
  });

  public moveOutWizardNavigator = new WizardNavigator<moveOutWizardSteps>(moveOutWizardSteps.moveout);

  constructor(moveOutWizardBaseStore: MoveOutWizardBaseStore, applicationStore: ApplicationStore) {
    this.moveOutWizardBaseStore = moveOutWizardBaseStore;
    this.applicationStore = applicationStore;

    makeObservable(this, {
      moveOutWizardValues: observable,
      moveOutWizardNavigator: observable,

      setInvoiceAddressValue: action,
      setOptionalMeasurementValue: action,
      resetStore: action
    });

    autorun(() => {
      const { values } = this.moveOutWizardValues;
      const { getTranslation } = this.applicationStore;
      const { setStepValidation } = this.moveOutWizardNavigator;

      // Move out section
      setStepValidation(
        moveOutWizardSteps.moveout,
        'moveOutDate',
        !!values.moveOutDate && !isMinDate(values.moveOutDate),
        getTranslation('contracts.wizard.terminate.validation.move_out_date')
      );

      setStepValidation(
        moveOutWizardSteps.moveout,
        'meter',
        !values.hasOptionalMeasurement ||
          (values.hasOptionalMeasurement && values.optionalMeasurements.every((om) => !!om.meter)),
        getTranslation('contracts.wizard.terminate.validation.meter')
      );

      setStepValidation(
        moveOutWizardSteps.moveout,
        'channel',
        !values.hasOptionalMeasurement ||
          (values.hasOptionalMeasurement && values.optionalMeasurements.every((om) => !!om.channelId)),
        getTranslation('contracts.wizard.terminate.validation.channel')
      );

      setStepValidation(
        moveOutWizardSteps.moveout,
        'measurementValue',
        !values.hasOptionalMeasurement ||
          (values.hasOptionalMeasurement &&
            values.optionalMeasurements.every((om) => typeof om.value === 'number' && !isNaN(om.value))),
        getTranslation('contracts.wizard.terminate.validation.measurement')
      );
    });
  }

  setInvoiceAddressValue = (val: Partial<LocalAddressType>) => {
    const { values, setValue } = this.moveOutWizardValues;
    setValue({ oldContractorInvoiceAddress: { ...values.oldContractorInvoiceAddress, ...val } });
  };

  setOptionalMeasurementValue = (index: number, val: Partial<AddMeasurementRequestType>) => {
    const { values, setValue } = this.moveOutWizardValues;
    const updatedMeasurements = [...values.optionalMeasurements];
    updatedMeasurements[index] = { ...updatedMeasurements[index], ...val };
    setValue({ optionalMeasurements: updatedMeasurements });
  };

  handleSubmit = async (withNewMoveIn = false) => {
    const { rootUrl, getTranslation } = this.applicationStore;
    const { contract, contractStore } = this.moveOutWizardBaseStore;
    const { updateContractorInvoiceAddress } = contractStore.rootStore.customerStore.customerService;
    const { addMeasurementMeter } = contractStore.rootStore.meterStore.measurementService;
    const { terminateContract } = contractStore.contractApiService;
    const { values, backup } = this.moveOutWizardValues;
    const {
      moveOutDate,
      oldContractorInvoiceAddress,
      hasOptionalMeasurement,
      optionalMeasurements,
      keepExistingInvoiceOnEndDate
    } = values;

    if (contract) {
      // Terminate contract
      try {
        await terminateContract(contract, moveOutDate?.toISOString() || MIN_DATE, keepExistingInvoiceOnEndDate);
        notify.success({
          content: getTranslation('actions.contract.terminate_success')
        });
      } catch (error) {
        notify.error({
          content: getTranslation('actions.contract.terminate_failed'),
          error
        });
      }

      // Update old contractor invoice address
      try {
        if (!deepEqual(values.oldContractorInvoiceAddress, backup.oldContractorInvoiceAddress)) {
          await updateContractorInvoiceAddress(contract.contractor.customerId, oldContractorInvoiceAddress);
          notify.success({
            content: getTranslation('contracts.update_old_contractor_success')
          });
        }
      } catch (error) {
        notify.error({
          content: getTranslation('contracts.update_old_contractor_fail'),
          error
        });
      }

      // Add optional measurement
      try {
        if (hasOptionalMeasurement) {
          await Promise.all(optionalMeasurements.map((om) => addMeasurementMeter(om)));

          notify.success({
            content: getTranslation(`actions.meter.add_measurements_success`)
          });
        }
      } catch (error) {
        notify.error({
          content: getTranslation('actions.meter.add_measurements_error'),
          error
        });
      }

      if (withNewMoveIn) {
        // Optional new move in for current contractor
        navigate(`${rootUrl}/contracts/add/${contract.contractor.customerId}`);
      } else {
        // Go to detail page of terminated contract
        navigate(`${rootUrl}/contracts/${contract.id}`);
      }
    }
  };

  /**
   * General
   */
  resetStore = () => {
    this.moveOutWizardValues.reset();
    this.moveOutWizardNavigator.reset();
  };
}
