import React from 'react';
import { TransactionType } from '@zf/api-types/transactions';
import { useStore } from 'hooks/useStore';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Interweave from 'interweave';
import { formatMoney } from '@zf/utils/src/number';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { colors } from '@zf/utils/src/color';
import { Link, TooltipTrigger } from 'design-system/Components';
import { Icon, Subtext } from 'design-system/Foundation';
import { EnumReturnValue } from 'app-context/hooks/use-enum';
import css from './components.module.scss';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import { NoDataOverlayType } from '@zf/stella-react/src/atoms/Table/table-overlay';
import EmptyStateImg from '@zf/stella-react/src/atoms/Image/EmptyState/EmptyStateImg';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import * as enums from '@zf/api-types/enums';
import { transactionReferenceType } from '@zf/api-types/enums';

export const OpenTotalAmounts = ({
  transaction,
  withStyle,
  withTotal = true
}: {
  transaction: TransactionType;
  withStyle: 'linethrough' | 'normal';
  withTotal?: boolean;
}) => {
  const {
    applicationStore: { culture }
  } = useStore();

  const getTotalAmount = (): { total: number | null; open: number | null } => {
    const { payment } = enums.transactionType;

    const defaultValue = {
      total: transaction.transactionAmount,
      open: transaction.transactionOpenAmount
    };

    const positiveValues = {
      total: Math.abs(transaction.transactionAmount),
      open: Math.abs(transaction.transactionOpenAmount)
    };

    if (transaction.transactionType === payment && transaction.incomingMutationId !== null) {
      return positiveValues;
    } else {
      return defaultValue;
    }
  };

  const { total, open } = getTotalAmount();

  return (
    <Paragraph textAlign="right">
      <Interweave
        content={
          {
            normal: `<b>${formatMoney(open, culture)}</b> ${withTotal ? ' / ' : ''} ${
              withTotal ? formatMoney(total, culture) : ''
            }`,
            linethrough: `<b style="text-decoration: line-through;">${formatMoney(open, culture)}</b> ${
              withTotal ? ' / ' : ''
            } ${withTotal ? formatMoney(total, culture) : ''}`
          }[withStyle]
        }
      />
    </Paragraph>
  );
};

export const settlementState = (transaction: TransactionType) => {
  if (transaction) {
    if (transaction?.reversed) {
      return 'reversed';
    }
    if (transaction?.transactionOpenAmount === 0) {
      return 'settled';
    } else if (transaction?.transactionOpenAmount === transaction.transactionAmount) {
      return 'not-settled';
    } else {
      return 'partially-settled';
    }
  }

  return 'not-settled';
};

export const TransactionStatus = ({ transaction }: { transaction: TransactionType }) => {
  const {
    applicationStore: { getTranslation }
  } = useStore();

  const status = settlementState(transaction);

  const color = {
    reversed: 'orange-600',
    settled: 'green-600',
    'not-settled': 'blue-600',
    'partially-settled': 'blue-600'
  }[status];

  return <NewStatusBadge color={colors[color]} status_={getTranslation(`customer.transactions.${status}`)} />;
};

const generateUrl = (transaction: TransactionType) => {
  const { transactionType, referenceDetails, incomingBankingTransactionId, outgoingBankingTransactionId } = transaction;

  const query = `customerId=${transaction.debtor?.customerId}&searchValue=${Math.abs(transaction.transactionAmount)}`;

  if (
    transactionType === enums.transactionType.writeoff &&
    referenceDetails?.transactionReferenceType === transactionReferenceType.collectionstep
  )
    return `collection-cases/${referenceDetails?.parameters?.collectionCaseId}`;
  else if (
    transactionType === enums.transactionType.collectionfee &&
    referenceDetails?.transactionReferenceType === transactionReferenceType.collectionstep
  )
    return `collection-cases/${referenceDetails?.parameters?.collectionCaseId}`;
  else if (incomingBankingTransactionId && transactionType === enums.transactionType.payment)
    return `payments/payments?${query}`;
  else if (outgoingBankingTransactionId && transactionType === enums.transactionType.payment)
    return `payments/payments?${query}`;
  else if (referenceDetails?.transactionReferenceId && transactionType === enums.transactionType.invoice)
    return `invoices/${referenceDetails?.transactionReferenceId}`;
  else if (transaction?.incomingMutationId === null && transactionType === enums.transactionType.payment)
    return `payments/payments?${query}`;
  else if (transactionType === enums.transactionType.reversal) return `payments/payments?${query}`;
};

export const TransactionTypeColumn = ({
  transaction,
  enumReducer,
  getTranslation
}: {
  transaction: TransactionType;
  enumReducer: EnumReturnValue;
  getTranslation: Function;
}) => {
  const { transactionType, referenceDetails } = transaction;

  switch (transactionType) {
    case enums.transactionType.invoice:
      return {
        icon: 'invoice',
        title: enumReducer.getTranslation('invoiceType', String(referenceDetails?.parameters?.invoiceType)),
        subtext: referenceDetails?.parameters?.invoiceNum,
        tooltip: 'invoice',
        url: generateUrl(transaction)
      };
    case enums.transactionType.payment:
      return {
        icon:
          transaction?.incomingBankingTransactionId === null && transaction?.outgoingBankingTransactionId === null
            ? 'ajust-balance'
            : referenceDetails?.parameters?.paymentType === enums.paymentType.incomingtransfer ||
              referenceDetails?.parameters?.paymentType === enums.paymentType.directdebit
            ? 'transaction-incoming'
            : 'transaction-outgoing',
        title:
          transaction?.incomingBankingTransactionId === null && transaction?.outgoingBankingTransactionId === null
            ? getTranslation('customer.transactions.title.adjust-balance')
            : referenceDetails?.parameters?.paymentType === enums.paymentType.incomingtransfer ||
              referenceDetails?.parameters?.paymentType === enums.paymentType.directdebit
            ? getTranslation('customer.transactions.title.incoming-transaction')
            : getTranslation('customer.transactions.title.outgoing-transaction'),

        subtext: `${referenceDetails?.parameters?.paymentReference} ${
          referenceDetails?.parameters?.paymentMethod
            ? '(' + referenceDetails?.parameters?.paymentMethod.toUpperCase() + ')'
            : ''
        } `,
        tooltip:
          transaction?.incomingMutationId === null
            ? 'adjust-balance'
            : referenceDetails?.parameters?.paymentType === enums.paymentType.incomingtransfer
            ? 'incoming-transaction'
            : 'outgoing-transaction',
        url: generateUrl(transaction)
      };
    case enums.transactionType.reversal:
      return {
        icon: 'revert',
        title: enumReducer.getTranslation('transactionType', String(transactionType)),
        subtext: `${referenceDetails?.parameters?.paymentReference} ${
          referenceDetails?.parameters?.paymentMethod ? '(' + referenceDetails?.parameters?.paymentMethod + ')' : ''
        }`,
        tooltip: 'reversal',
        url: generateUrl(transaction)
      };
    case enums.transactionType.collectionfee:
      return {
        icon: 'dunning',
        title: enumReducer.getTranslation('transactionType', String(transactionType)),
        subtext: `${getTranslation('collection_case.step')}: ${referenceDetails?.parameters?.stepName}`,
        tooltip: 'collection-fee',
        url: generateUrl(transaction)
      };
    case enums.transactionType.writeoff:
      return {
        icon: 'write-off',
        title: enumReducer.getTranslation('transactionType', String(transactionType)),
        subtext: `${getTranslation('collection_case.step')}: ${referenceDetails?.parameters?.stepName}`,
        tooltip: 'write-off',
        url: generateUrl(transaction)
      };
    default: {
      return {
        icon: undefined,
        title: undefined,
        subtext: undefined,
        tooltip: undefined,
        url: ''
      };
    }
  }
};

export const TransactionTypeLink = ({
  transaction,
  enumReducer,
  getTranslation,
  offStatus = false,
  showIcon = true
}: {
  transaction: TransactionType;
  enumReducer: EnumReturnValue;
  offStatus?: boolean;
  getTranslation: Function;
  showIcon?: boolean;
}) => {
  const { icon, title, subtext, tooltip, url } = TransactionTypeColumn({ transaction, enumReducer, getTranslation });
  const {
    applicationStore: { rootUrl }
  } = useStore();

  return (
    <TooltipTrigger
      tooltip={<Paragraph>{getTranslation(`customer.transactions.title.${tooltip}`)}</Paragraph>}
      trigger="hover"
    >
      <div className={css['invoice-detail']}>
        <Link url={`${rootUrl}/${url}`} icon={showIcon ? icon : undefined} className={css['transaction-link']}>
          <Paragraph bold>
            <span>
              {title}
              <sup>
                {offStatus && settlementState(transaction) !== 'settled' && (
                  <Icon name="dot" className={css['dot-large-unsettled']} />
                )}
                {offStatus && settlementState(transaction) === 'settled' && (
                  <Icon name="dot" className={css['dot-large-settled']} />
                )}
              </sup>
            </span>
          </Paragraph>
          {subtext && <Subtext>{subtext}</Subtext>}
        </Link>
      </div>
    </TooltipTrigger>
  );
};

export const EmptyState: NoDataOverlayType = () => {
  const {
    applicationStore: { getTranslation }
  } = useStore();

  return (
    <CardEmptyBody
      icon={<EmptyStateImg title="transaction" image="transaction" />}
      title={getTranslation('cards.cash_position.no_open_transactions')}
    >
      <Paragraph>
        <Interweave content={getTranslation('cards.cash_position.no_open_transactions_des')} />
      </Paragraph>
    </CardEmptyBody>
  );
};

export const EmptyStateSettled: NoDataOverlayType = () => {
  const {
    applicationStore: { getTranslation }
  } = useStore();

  return (
    <CardEmptyBody
      icon={<EmptyStateImg title="transaction" image="transaction" />}
      title={getTranslation('cards.cash_position.no_closed_transactions')}
    ></CardEmptyBody>
  );
};

export const EmptyStateUnsettled: NoDataOverlayType = () => {
  const {
    applicationStore: { getTranslation }
  } = useStore();

  return (
    <CardEmptyBody
      icon={<EmptyStateImg title="transaction" image="transaction" />}
      title={getTranslation('cards.cash_position.no_open_transactions')}
    ></CardEmptyBody>
  );
};

export const LockedInfo = ({
  awaitingBankConfirmationTransactionId,
  awaitingBankConfirmationTransactionIdentification,
  awaitingBankConfirmationTransactionType
}: {
  awaitingBankConfirmationTransactionId: string;
  awaitingBankConfirmationTransactionIdentification: string;
  awaitingBankConfirmationTransactionType: string;
}) => {
  const {
    applicationStore: { getTranslation, rootUrl }
  } = useStore();
  return (
    <Paragraph className={css['locked']}>
      <FlexElements flexDirection="column">
        <FlexElements flexDirection="row">
          <Interweave
            content={
              awaitingBankConfirmationTransactionType === 'paymentrequests'
                ? getTranslation('customer.transaction.locked_payment_request')
                : getTranslation('customer.transaction.locked_payment_refund')
            }
          />
          <Link
            id="payments.id"
            url={`${rootUrl}/payments/outgoing-banking-transactions/${awaitingBankConfirmationTransactionId}`}
          >
            {awaitingBankConfirmationTransactionIdentification}
          </Link>
        </FlexElements>
        <Subtext>{getTranslation('customer.transaction.locked_des')}</Subtext>
      </FlexElements>
    </Paragraph>
  );
};
