import React from 'react';

import { ExportJobRowType } from '@zf/api-types/export-job';

import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';

export default function DownloadExportJobsListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('exportjob');
  const { downloadFiles } = useDownload();
  const selectedRows: ExportJobRowType[] = myCurrent.listPage.selectedRows;

  const filteredBad = selectedRows.filter((r) => {
    return r.__exportJobEntity.status !== 'generated' && r.__exportJobEntity.status !== 'transferred';
  });

  const disabled = selectedRows.length === 0 || filteredBad.length > 0;

  const download = () => {
    const ids = selectedRows.map((r) => {
      return r.__exportJobEntity.id;
    });

    downloadFiles(ids, '/logs/ExportJob/downloadzip', '/logs/ExportJob/', 'exportJobIds');
  };

  return (
    <DropdownAction
      actionType="direct"
      id="export_jobs.download"
      icon="download-cloud"
      onClick={() => download()}
      disabled={disabled}
    >
      {i18n.getTranslation('actions.download')}
    </DropdownAction>
  );
}
