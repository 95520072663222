import moment from 'moment';
import React from 'react';

import { billingCompletenessStatus } from '@zf/api-types/enums';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import useQueryParam from '../../../app-context/hooks/use-query-param';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { UnbilledListQueryParams } from '../unbilled-list-card';
import css from '../unbilled-list.module.scss';

const initialQueryParams: UnbilledListQueryParams = {
  periodEndDateTime: moment().endOf('month').toISOString(),
  periodStartDateTime: MIN_DATE,
  selectedStatus: [],
  onlyOpen: true,
  searchValue: '',
  invoicingCheckpointEndDateTime: MAX_DATE,
  invoicingCheckpointStartDateTime: MIN_DATE,
  invoiceType: undefined,
  quickfilter: ''
};

export default function useCompletenessFilter(propertyGroupId?: string) {
  initialQueryParams.propertyGroupId = propertyGroupId || '';

  const { queryParams, setQueryParams, clearQueryParams } = useQueryParam<UnbilledListQueryParams>(
    'completeness',
    initialQueryParams
  );

  const { i18n, enumReducer } = useAppContext();

  const tags: React.ReactNode[] = [];

  if (queryParams.selectedStatus && queryParams.selectedStatus.length > 0) {
    tags.push(
      <FilterTag
        key="completeness-status"
        enumType="billingCompletenessStatus"
        type="enum"
        value={queryParams.selectedStatus[0]}
        removeFilter={() => setQueryParams({ selectedStatus: [] as billingCompletenessStatus[] })}
      />
    );
  }

  if (queryParams.propertyGroupId && queryParams.propertyGroupName) {
    tags.push(
      <FilterTag
        value={queryParams.propertyGroupName}
        removeFilter={() => setQueryParams({ propertyGroupId: '', propertyGroupName: '' })}
        key="property-group"
      />
    );
  }

  if (queryParams.quickfilter) {
    tags.push(
      <FilterTag
        key="completeness-status"
        enumType="billingCompletenessStatus"
        type="enum"
        value={i18n.getTranslation('dashboard.general_tab.missing_advances')}
        removeFilter={() => setQueryParams({ quickfilter: '' })}
      />
    );
  }

  if (queryParams.onlyOpen) {
    tags.push(
      <FilterTag
        key="completeness-only-open"
        value={i18n.getTranslation('analytics.only_open')}
        removeFilter={() => setQueryParams({ onlyOpen: false })}
      />
    );
  }

  if (queryParams.periodEndDateTime !== MAX_DATE || queryParams.periodStartDateTime !== MIN_DATE) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.periodStartDateTime, queryParams.periodEndDateTime]}
        removeFilter={() => setQueryParams({ periodEndDateTime: MAX_DATE, periodStartDateTime: MIN_DATE })}
        key="period"
      />
    );
  }

  if (queryParams.invoiceType) {
    tags.push(
      <FilterTag
        className={css['tag']}
        key="completeness-invoice-type"
        value={enumReducer.getTranslation('invoiceType', queryParams.invoiceType)}
        removeFilter={() => setQueryParams({ invoiceType: undefined })}
      />
    );
  }

  if (
    queryParams.invoicingCheckpointEndDateTime !== MAX_DATE ||
    queryParams.invoicingCheckpointStartDateTime !== MIN_DATE
  ) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.invoicingCheckpointStartDateTime, queryParams.invoicingCheckpointEndDateTime]}
        removeFilter={() =>
          setQueryParams({ invoicingCheckpointEndDateTime: MAX_DATE, invoicingCheckpointStartDateTime: MIN_DATE })
        }
        key="invoice-period"
      />
    );
  }

  const filterTags = <FilterTags key="filter-tags" tags={tags} />;

  return {
    filterTags,
    queryParams,
    setQueryParams,
    clearQueryParams
  };
}
