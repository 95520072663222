import React from 'react';

import { ExportJobType } from '@zf/api-types/export-job';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import MessagesCard from '../../../cards/Messages/MessagesCard';
import ContentListCard from './content-list-card';
import ExportJobFileFormatCard from './export-job-file-format-card';
import css from './style.module.scss';

type Props = {
  exportJob: ExportJobType;
};

export default function ExportJobDetail(props: Props) {
  const { exportJob } = props;

  return (
    <CardsContainer className={css['export-jobs-detail-card']}>
      <ContentListCard exportJob={exportJob} />
      <ExportJobFileFormatCard exportJob={exportJob} />
      <MessagesCard job={exportJob} />
    </CardsContainer>
  );
}
