import clone from 'clone';
import React, { useEffect } from 'react';

import {
  CollectionChargeParametersType,
  FixedCollectionChargeParametersType,
  PercentageCollectionChargeParametersType,
  TieredPercentageCollectionChargeParametersType,
  TierType
} from '@zf/api-types/collection-cycles';
import { collectionChargeType } from '@zf/api-types/enums';
import { GetTaxCodeResponse } from '@zf/api-types/tax-codes';
import { SimpleValidatorReturnType } from '@zf/hooks/src/useSimpleValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../../../../app-context';
import Dropdown from '../../../../../components/Lang/Dropdown';
import useSuspenseSingleAPI from '../../../../../hooks/useSuspenseSingleAPI';
import { formatTaxCode } from '../../../../../utils/taxCode';
import { StepValidatorType } from '../step-wizard';
import FixedCollectionChargeParameters from './FixedCollectionChargeParameters';
import PercentageCollectionChargeParameters from './PercentageCollectionChargeParameters';
import TiersMultivalue from './tiered/tiers-multivalue';

type Props = {
  stepValidatorTools: SimpleValidatorReturnType<StepValidatorType>;
  stepNumber: string | number;
};

export default function CollectionChargeParameters(props: Props) {
  const { stepValidatorTools, stepNumber } = props;
  const { i18n } = useAppContext();

  const { values, setValue } = stepValidatorTools;

  const taxCodesResponse = useSuspenseSingleAPI<GetTaxCodeResponse>({
    request: {
      endpoint: '/cfg/taxcodes'
    }
  });

  // When we edit a step which had charge type none the parameters will be null and we have to initialize these
  useEffect(() => {
    if (!values.chargeParameters && values.chargeType !== collectionChargeType.none) {
      switch (values.chargeType) {
        case collectionChargeType.fixed:
          {
            const initialParamValues = {
              amountExclVAT: 0
            };
            setValue({ chargeParameters: initialParamValues as FixedCollectionChargeParametersType });
          }
          break;
        case collectionChargeType.percentageOfOpenAmount:
          {
            const initialParamValues = {
              percentage: 0
            };
            setValue({ chargeParameters: initialParamValues as PercentageCollectionChargeParametersType });
          }
          break;
        case collectionChargeType.tieredPercentageOfOpenAmount:
          {
            const initialParamValues = {
              tiers: [] as TierType[]
            };
            setValue({ chargeParameters: initialParamValues as TieredPercentageCollectionChargeParametersType });
          }
          break;
      }
    }
  }, [values.chargeParameters]);

  if (!taxCodesResponse.result) return null;

  const taxCodeDropdownVals = taxCodesResponse.result.data.results.map((tc) => {
    return {
      text: formatTaxCode(tc),
      value: tc
    };
  });

  const setTieredChargeParameters = (parameterArray: TierType[]) => {
    const chargeParamsClone = clone(values.chargeParameters) as TieredPercentageCollectionChargeParametersType;
    if (chargeParamsClone) {
      chargeParamsClone.tiers = parameterArray;
      stepValidatorTools.setValue({ chargeParameters: chargeParamsClone });
    }
  };

  const setChargeParametersValue = (key: any, value: any) => {
    let chargeParametersClone = clone(values.chargeParameters) as Record<string, any>;
    if (!chargeParametersClone) {
      chargeParametersClone = {};
    }
    chargeParametersClone[key] = value;
    setValue({ chargeParameters: chargeParametersClone as CollectionChargeParametersType });
  };

  const generateOtherChargeParameters = () => {
    if (values.chargeType !== collectionChargeType.tieredPercentageOfOpenAmount) {
      if (values.chargeType === collectionChargeType.fixed) {
        return (
          <FixedCollectionChargeParameters
            stepNumber={stepNumber}
            chargeParams={values.chargeParameters as FixedCollectionChargeParametersType}
            setChargeParametersValue={setChargeParametersValue}
          />
        );
      } else {
        return (
          <PercentageCollectionChargeParameters
            stepNumber={stepNumber}
            chargeParams={values.chargeParameters as PercentageCollectionChargeParametersType}
            setChargeParametersValue={setChargeParametersValue}
          />
        );
      }
    }
  };

  return (
    <>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('collection_flows.charge_parameters')}
      </Heading>
      <InputContainer>
        <Dropdown
          id={`taxcode-${stepNumber}`}
          onChange={(val) => setChargeParametersValue('taxCodeId', val[0] ? val[0].id : '')}
          placeholder={i18n.getTranslation('tax_code.tax_code')}
          values={taxCodeDropdownVals}
          selectedValues={[values.chargeParameters ? values.chargeParameters.taxCodeId : '']}
          error={values.chargeParameters ? !values.chargeParameters.taxCodeId : false}
        />
        {generateOtherChargeParameters()}
      </InputContainer>
      {values.chargeType === collectionChargeType.tieredPercentageOfOpenAmount && (
        <TiersMultivalue
          chargeParams={values.chargeParameters as TieredPercentageCollectionChargeParametersType}
          setTieredChargeParameters={setTieredChargeParameters}
        />
      )}
    </>
  );
}
