import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { DialogClickRef, ValidationRef } from '../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../events/notification-events';
import css from '../../style.module.scss';

type Props = {
  extChannelId: string;
  measurements: string[];
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  onComplete: () => void;
};

const DeleteMeasurementDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { extChannelId, measurements, validationRef, onComplete } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { deleteMeasurement } = meterStore.measurementService;

  const validate = () => {
    if (validationRef && validationRef.current) {
      validationRef.current.setIsError(measurements.length === 0);
    }
  };

  React.useEffect(() => {
    validate();
  }, [measurements]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (measurements && measurements.length > 0) {
        try {
          await deleteMeasurement(extChannelId, measurements);

          notify.success({
            content:
              measurements.length > 1
                ? getTranslation('actions.meter.delete_measurements_success')
                : getTranslation('actions.meter.delete_measurement_success')
          });
          onComplete();
        } catch (e) {
          notify.error({
            content: getTranslation('actions.meter.delete_measurement_error'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <div className={css['multivalue-wrapper']}>
      <Paragraph>{getTranslation('actions.meter.delete_selected_measurements')}</Paragraph>
    </div>
  );
});

export default observer(DeleteMeasurementDialog);
