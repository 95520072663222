import { convertHexToRGBA } from '@zf/utils/src/color';
import { formatMoney, roundNumber } from '@zf/utils/src/number';
import classNames from 'classnames';
import React from 'react';
import { Paragraph } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import css from './stacked-bar-chart.module.scss';

type Props = {
  color: string;
  percentage: number;
  label: string;
  price: number;
  className?: string;
  isSelected?: boolean;
  onClick?: () => void;
};

const BarStack = (props: Props) => {
  const { color, price, label, percentage, className, isSelected, onClick } = props;
  const { applicationStore } = useStore();
  const { culture } = applicationStore;
  const style: React.CSSProperties = React.useMemo(() => {
    const backgroundColor = convertHexToRGBA(color, 20);
    return { backgroundColor, color, width: `${percentage < 10 ? 10 : percentage}%` };
  }, [color, percentage]);

  return (
    <div
      onClick={onClick}
      className={classNames(
        css['bar-stack'],
        className,
        { [css['can-click']]: onClick !== undefined },
        { [css['isSelected']]: isSelected }
      )}
      style={style}
    >
      <Paragraph textAlign="left" color={color} bold>
        {label} <br />
        {formatMoney(roundNumber(price), culture)}
      </Paragraph>
    </div>
  );
};

export default BarStack;
