import React from 'react';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../components/placeholder';
import { useStore } from '../../../hooks/useStore';
import useCountrySpecificListPage from './hooks/useSocialTariffListPage';

export default function SocialTariffList() {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useCountrySpecificListPage();

  const [columns] = React.useState([
    {
      width: 500,
      label: getTranslation('attachment.file_name'),
      dataKey: 'fileName'
    },
    {
      width: 200,
      label: getTranslation('general.start_date'),
      dataKey: 'startDateTime'
    },
    {
      width: 200,
      label: getTranslation('general.end_date'),
      dataKey: 'endDateTime'
    },
    {
      width: 180,
      label: getTranslation('country_specifics.delta'),
      dataKey: 'onlyMutations'
    },
    {
      width: 180,
      label: getTranslation('invoice.generated'),
      dataKey: 'generated'
    }
  ]);

  return React.useMemo(() => {
    return (
      <DynamicVirtualTable
        id="social-tariff-table"
        tooltipId="social-tariff-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={rows.length}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={columns}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, applicationStore.culture]);
}
