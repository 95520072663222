import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import { action, makeObservable, observable } from 'mobx';

import ContractStore from '../../detail-page/ContractStore';
import AddLocationsMoveInWizardStore from './AddLocationsMoveInWizardStore';
import RegularMoveInWizardStore from './RegularMoveInWizardStore';

export enum moveInType {
  newcontract = 'newcontract',
  existingcontract = 'existingcontract'
}

export default class MoveInWizardBaseStore {
  public contractStore: ContractStore;

  // A store for each of our move in wizard types
  public addLocationsMoveInWizardStore: AddLocationsMoveInWizardStore;
  public regularMoveInWizardStore: RegularMoveInWizardStore;

  public moveInType = moveInType.newcontract;

  constructor(contractStore: ContractStore, applicationStore: ApplicationStore) {
    this.contractStore = contractStore;

    this.addLocationsMoveInWizardStore = new AddLocationsMoveInWizardStore(this, applicationStore);
    this.regularMoveInWizardStore = new RegularMoveInWizardStore(this, applicationStore);

    makeObservable(this, {
      addLocationsMoveInWizardStore: observable,
      regularMoveInWizardStore: observable,
      moveInType: observable,

      setMoveInType: action,
      resetStore: action
    });
  }

  setMoveInType = (moveInType: moveInType) => {
    this.moveInType = moveInType;
  };

  /**
   * General
   */
  resetStore = () => {
    this.regularMoveInWizardStore.resetStore();
    this.addLocationsMoveInWizardStore.resetStore();
    this.moveInType = moveInType.newcontract;
  };
}
