import React from 'react';

import { BaseButton } from '@zf/heat-erp/src/design-system/Components';

import { Icon } from '../Icon';
import css from './calendar-header.module.scss';

type Props = {
  children: React.ReactNode;
  onNext: () => void;
  onPrevious: () => void;
};

export default function CalendarHeader(props: Props) {
  const { children, onNext, onPrevious } = props;

  return (
    <div className={css['calendar-header']}>
      <BaseButton id="previous" type="primary" onClick={onPrevious}>
        <Icon name="chevron-left" />
      </BaseButton>
      {children}
      <BaseButton id="next" type="primary" onClick={onNext}>
        <Icon name="chevron-right" />
      </BaseButton>
    </div>
  );
}
