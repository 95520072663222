import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';
import queryString from 'query-string';
import { uiCulture } from '@zf/api-types/enums';
import { DomainNotificationType, ZFErrorType } from '@zf/api-types/general';
import { TenantReturnValue } from '../app-context/hooks/use-tenant-reducer';
import { getApiURL } from '../constants/api';
import { RequestHeadersType, RequestType } from '../types/Request';
import { API } from 'app-context/stores/domain/Axios';

export type ErrorDataType = {
  errors: DomainNotificationType[];
  status: number;
  statusText: string;
  title: string;
  type: string;
};

export type ErrorResponse = {
  config: AxiosRequestConfig;
  status: number;
  data: ErrorDataType;
  headers: Headers;
};

export type CustomErrorType = {
  response: ErrorResponse;
  message: string;
};

type sendRequestParamsType = {
  responseType?: ResponseType;
  request: RequestType;
  customHeaders?: RequestHeadersType;
  forceRefreshAuthToken?: boolean;
  donotCache?: boolean;
  tenantReducer?: TenantReturnValue;
  lang?: uiCulture;
};

export type CreateHeaderParams = {
  tenant?: string;
  organization?: string;
} & { [key: string]: string | number | null | undefined };

export type RequestHeaderType = Headers & {
  'content-disposition': string;
  'content-type': string;
  'Accept-Language': string;
};

export type RequestResultType<T> = {
  data: T;
  headers: RequestHeaderType;
};

export type TwoLvlRequestResultType<T> = {
  data: { data: T };
  headers: RequestHeaderType;
};

export const METHODS: Record<string, Method> = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
};

export type HeaderType = { [key: string]: string | number | null | undefined };

export function createHeader({ organization, tenant, ...otherHeaders }: CreateHeaderParams) {
  const headers = {} as HeaderType;
  if (organization) headers['zf-ouuid'] = organization;
  if (tenant) headers['zf-tuuid'] = tenant;
  return { ...headers, ...otherHeaders };
}

export function handleError(e: CustomErrorType) {
  if (!e) {
    return { key: 'unknown_error' };
  }

  if (!e.response) {
    return { key: 'request_could_not_be_sent' };
  }

  const response: ErrorResponse = e.response;
  const error = new Error(e.message ? e.message : `${response.status}: Response error`) as ZFErrorType;
  error.status = response.status;
  error.data = response.data;
  error.headers = response.headers;
  return error;
}

export function generateCancelToken() {
  return axios.CancelToken.source();
}

export async function sendRequest<T>(
  params: sendRequestParamsType,
  hasOneDataLevel?: boolean,
  mock?: boolean
): // @ts-ignore
Promise<RequestResultType<T>> {
  const { request, customHeaders = {}, responseType, lang, tenantReducer } = params;
  let { query } = request;
  const { endpoint, selector = '', method = METHODS.GET, data = null } = request;

  // Add default order by to GET requests
  if (method === METHODS.GET && !query?.orderBy) {
    query = { ...query, orderBy: '-CreatedDateTime' };
  }  

  query = { ...query, ['api-version']: '0.0' }

  const url = getApiURL(endpoint, mock) + endpoint + selector + (query ? '?' + queryString.stringify(query) : '');

  if (request.timeStamp) {
    customHeaders.timestamp = request.timeStamp;
  }

  customHeaders['apim-sub'] = 'cc40c85514c342d5a08e53e535e48836';

  const api = new API<T>({
    method,
    url,
    customHeaders,
    responseType,
    data,
    hasOneDataLevel,
    tenantReducer: tenantReducer?.state,
    lang
  });
  return api.execute();
}

export const countRequestSuccesses = (array: boolean[]) => {
  let count = 0;
  array.forEach((el) => {
    if (el === true) {
      count++;
    }
  });
  return count;
};
