import React, { useEffect, useReducer } from 'react';

import * as dialogEvents from '../../../events/dialog-events';
import Dialog, { DialogProps } from './Dialog';

type DialogValueType = {
  content: DialogProps;
  options: {
    id: string;
    locationType?: dialogEvents.DialogLocationsType;
  };
};

type State = {
  [id: string]: DialogValueType;
};

type Action = {
  action: 'delete' | 'add';
  dialogId: string;
  dialog?: DialogValueType;
};

const dialogReducer = (state: State, action: Action) => {
  const stateClone = { ...state };

  switch (action.action) {
    case 'delete':
      delete stateClone[action.dialogId];
      break;
    case 'add':
      if (!action.dialog) throw new Error('Add action in dialogReducer requires a dialog!');

      stateClone[action.dialogId] = action.dialog;
      break;
  }

  return stateClone;
};

export default function DialogCenter() {
  const [dialogs, dispatch]: [State, (action: Action) => void] = useReducer(dialogReducer, {});

  useEffect(() => {
    const removeDialog = (id: string) => {
      dispatch({
        action: 'delete',
        dialogId: id
      });
    };

    const createDialog = ({ options, dialog }: dialogEvents.DialogEmitValue) => {
      const onCancel = () => {
        if (dialog.onCancel) {
          dialog.onCancel();
        }

        removeDialog(options.id);
      };

      dispatch({
        action: 'add',
        dialogId: options.id,
        dialog: {
          content: {
            title: dialog.title,
            icon: dialog.icon,
            content: dialog.content,
            buttonPositive: dialog.buttonPositive,
            buttonNegative: dialog.buttonNegative,
            onSubmit: dialog.onSubmit,
            onCancel,
            type: dialog.type,
            ref: dialog.ref,
            isDismissDialog: dialog.isDismissDialog,
            extraRight: dialog.extraRight,
            withPadding: dialog.withPadding,
            stackOrder: dialog.stackOrder
          },
          options: {
            id: options.id,
            locationType: options.locationType
          }
        }
      });
    };

    dialogEvents.on(dialogEvents.ACTION.SHOW, createDialog);

    return () => {
      dialogEvents.removeListener(dialogEvents.ACTION.SHOW, createDialog);
    };
  }, [true]);

  return (
    <div>
      {Object.values(dialogs).map((value: DialogValueType) => {
        return <Dialog locationType={value.options.locationType} key={value.options.id} {...value.content} />;
      })}
    </div>
  );
}
