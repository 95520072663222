import { randomInt } from './number';

export const CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

/**
 * Makes the first letter of each word uppercase
 * @param string sentence 
 * @returns capitalized string
 */
export const capitalize = (sentence: string | null | undefined) => {
  return sentence
    ? sentence
        .split(' ')
        .map((word) => {
          if (word.length > 0) {
            word = word[0].toUpperCase() + word.toLowerCase().slice(1);
          }

          return word;
        })
        .join(' ')
    : '';
};

/**
 * Makes the first letter of the fist word uppercase
 * @param string sentence 
 * @returns capitalized string
 */
export const capitalizeFirstWord = (sentence: string | null | undefined) => {
  if (sentence && typeof sentence === 'string') {
    const splitted = sentence.split(' ');

    let firstWord = splitted[0];

    if (firstWord && firstWord.length > 0) {
      firstWord = firstWord[0].toUpperCase() + firstWord.toLowerCase().slice(1);
    }

    const rest = splitted
      .map((word, index) => {
        return index !== 0 ? word : '';
      })
      .join(' ');

    return firstWord + (rest ? ' ' + rest.trim() : '');
  }

  return sentence || '';
};

/**
 * Generates a random string with #characters between min & max
 * @param number min 
 * @param number max 
 * @returns a random string
 */
export const generateString = (min: number, max: number) => {
  const length = randomInt(min, max);

  return new Array(length)
    .fill('')
    .map(() => CHARS.charAt(randomInt(0, CHARS.length - 1)))
    .join('');
};
