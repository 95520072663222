import clone from 'clone';
import moment, { Moment } from 'moment';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { ExternalChannelType } from '@zf/api-types/master-data/meter';

export type ActionTypes = ResetAction | UpdateAction;

export type queryParam = {
  [key: string]: Moment | null;
};

export type ResetAction = {
  type: 'reset';
};

export type UpdateAction = {
  type: 'update';
  update: {
    filter?: FilterType;
    selectedMeasurementIds?: string[];
    selectedChannel?: number;
    measurementChannels?: ExternalChannelType[];
    consumptionChannels?: ExternalChannelType[];
    selectedMeasurementChannels?: number[];
    selectedConsumptionChannels?: number[];
  };
};

export type FilterType = {
  queryParams: queryParam;
};

export type MeterValuesState = {
  filter: FilterType;
  selectedMeasurementIds: string[];
  selectedChannel: number;
  measurementChannels: ExternalChannelType[];
  consumptionChannels: ExternalChannelType[];
  selectedMeasurementChannels: number[];
  selectedConsumptionChannels: number[];
};

export const initialFilter = {
  queryParams: { startDate: moment().add(-1, 'week'), endDate: moment(), timeStamp: moment() }
};

const initialState: MeterValuesState = {
  filter: clone(initialFilter),
  selectedMeasurementIds: [],
  selectedChannel: -1,
  measurementChannels: [],
  consumptionChannels: [],
  selectedMeasurementChannels: [],
  selectedConsumptionChannels: []
};

const meterReducer = (state: MeterValuesState, action: ActionTypes) => {
  if (action.type === 'reset') {
    const resetClone = clone(initialState);
    // Do not reset the meter channels given on init
    if (state.measurementChannels.length > 0) {
      resetClone.measurementChannels = state.measurementChannels;
    }
    if (state.consumptionChannels.length > 0) {
      resetClone.consumptionChannels = state.consumptionChannels;
    }
    return { ...resetClone };
  } else {
    return { ...clone(state), ...action.update };
  }
};

const useValue = () => useReducer(meterReducer, initialState);

export const {
  Provider: MeterProvider,
  useUpdate: useDispatch,
  useTracked,
  useTrackedState
} = createContainer(useValue);
