import { observer } from 'mobx-react';
import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';
import { DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import { useAppContext } from '../../app-context';
import Button from '../../components/Button/Button';
import ConfirmationActionButton from '../../components/Button/ConfirmationActionButton';
import FilterInputs from '../../components/Filters/filter-inputs';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { useStore } from '../../hooks/useStore';
import MigrateDialog from './dialogs/MigrateDialog';
import RetriggerDialog from './dialogs/RetriggerDialog';
import css from './integration-logging.module.scss';
import { ConfiguredPluginType } from '@zf/api-types/integration/plugin';

type Props = {
  refresh: boolean;
  filterInputs: JSX.Element[];
  dropdownFormats: DropdownValuesType<ConfiguredPluginType>[];
  resetFilter: () => void;
};

const IntegrationLoggingCardExtra = (props: Props) => {
  const { refresh, filterInputs, dropdownFormats, resetFilter } = props;
  const { authReducer } = useAppContext();
  const { isSuperUser } = authReducer;
  const { integrationStore, applicationStore } = useStore();
  const { filter, selectedIds, selectedPlugin_, fetchRows, setFilters, plugins } =
    integrationStore.integrationLoggingStore;

  const { clickRef, onSubmit } = useDialog();

  if (!isSuperUser()) return null;

  return (
    <div className={css['extra']}>
      <FilterInputs filterInputs={filterInputs} removeAllFilters={resetFilter} />
      <SimpleDropdown
        className={css['dropdown-filter']}
        id="plugin-type"
        onChange={(val) => setFilters({ configuredPlugin: val[0]?.id })}
        values={dropdownFormats}
        selectedValues={[plugins?.find((p) => p.id === filter.configuredPlugin)]}
        placeholder={filter.configuredPlugin ? '' : applicationStore.getTranslation('general.plugin')}
      />
      <ConfirmationActionButton
        id="integrationlogging.retrigger"
        dialogTitle={applicationStore.getTranslation('general.retrigger')}
        dialogContent={<RetriggerDialog ref={clickRef} />}
        buttonPositive={applicationStore.getTranslation('general.retrigger')}
        onSubmit={onSubmit}
        size="small"
        type="text"
        disabled={selectedIds.length === 0 || (!selectedPlugin_?.erroneous && !selectedPlugin_?.finished)}
      >
        {applicationStore.getTranslation('general.retrigger')}
      </ConfirmationActionButton>
      <ConfirmationActionButton
        id="integrationlogging.retrigger"
        dialogTitle="Migration"
        dialogContent={<MigrateDialog ref={clickRef} />}
        buttonPositive={applicationStore.getTranslation('general.migrate')}
        size="small"
        type="text"
        onSubmit={onSubmit}
      >
        {applicationStore.getTranslation('general.migrate')}
      </ConfirmationActionButton>
      {!refresh && (
        <Button
          className={css['iconButton']}
          id="refresh-btn"
          icon="refresh"
          type="text"
          size="small"
          onClick={fetchRows}
        />
      )}
    </div>
  );
};

export default observer(IntegrationLoggingCardExtra);
