import { Moment } from 'moment';
import React from 'react';
import { getDateTimeClass, getDropDownTimeVals } from 'utils/meter';

import { dataFrequency } from '@zf/api-types/enums';
import { HOURS, MINUTESMAP } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import Button from '../../../components/Button/Button';
import RemoveIconButton from '../../../components/Icon/RemoveIconButton';
import InputField from '../../../components/input/InputField';
import DatePicker from '../../../components/Lang/DatePicker';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import css from '../../style.module.scss';
import { NodeValueType } from './reconfigure-dialog';

type Props = {
  mutationDateTime: Moment;
  value: NodeValueType;
  index: number;
  dispatchValue: (value: Partial<NodeValueType>) => void;
};

export default function AddMeasurementPart(props: Props) {
  const { index, value, mutationDateTime, dispatchValue } = props;

  const { i18n } = useAppContext();

  const frequency = value.dataFrequency || dataFrequency.na;

  const hoursDisabled = value.dataFrequency === dataFrequency.p1d || value.dataFrequency === dataFrequency.na;
  const minutesDisabled =
    value.dataFrequency === dataFrequency.pt1h ||
    value.dataFrequency === dataFrequency.p1d ||
    value.dataFrequency === dataFrequency.na;

  const minutes = MINUTESMAP.get(frequency);

  const dateTimeClass = getDateTimeClass(hoursDisabled, minutesDisabled);

  const hoursAndMinutesPart = !(hoursDisabled && minutesDisabled) ? (
    <>
      {!hoursDisabled ? (
        <SimpleDropdown
          id={`hours-dropdown-${index}`}
          selectedValues={[value.hour]}
          values={getDropDownTimeVals(HOURS, frequency)}
          onChange={(val) => dispatchValue({ hour: val[0] })}
          placeholder={i18n.getTranslation('meter.hour')}
        />
      ) : null}

      {!minutesDisabled ? (
        <SimpleDropdown
          id={`minutes-dropdown-${index}`}
          selectedValues={[value.minute]}
          values={minutes ? getDropDownTimeVals(minutes, frequency) : []}
          onChange={(val) => dispatchValue({ minute: val[0] })}
          placeholder={i18n.getTranslation('meter.minute')}
        />
      ) : null}
    </>
  ) : null;

  return value.addingMeasurement ? (
    <div id="wrapper" className={css['rcnf-opt-msmt-wrapper']}>
      <RemoveIconButton
        id="remove-measurement"
        className={css['remove-msmt-btn']}
        onClick={() => dispatchValue({ addingMeasurement: false })}
      />

      <div className={css[dateTimeClass]}>
        <DatePicker
          id={`end-date-time-${index}`}
          onChange={() => {
            // Do nothing
          }}
          value={mutationDateTime}
          placeholder={i18n.getTranslation('actions.meter.new_reading')}
          disabled
        />
        {hoursAndMinutesPart}

        <InputField
          id={`value-measurement${index}`}
          onChange={(value) => dispatchValue({ value })}
          value={value.value || ''}
          placeholder={i18n.getTranslation('general.value')}
        />
      </div>
    </div>
  ) : (
    <div className={css['rcnf-add-msmt-btn']}>
      <Button
        type="primary"
        size="small"
        id="btn-add-measurement"
        onClick={() => dispatchValue({ addingMeasurement: true })}
      >
        {i18n.getTranslation('actions.add_measurement')}
      </Button>
    </div>
  );
}
