import React from 'react';

import { UpdateIncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';

import useConfig from '../../../app-context/hooks/use-config';
import useCultureTable from '../../../app-context/hooks/use-culture-table';
import IncomingInvoiceComponents from './incoming-invoice-components';

export default function IncomingInvoiceComponentsTab() {
  const config = useConfig<UpdateIncomingInvoiceComponentType[]>(
    'incomingInvoiceComponents',
    '/cfg/IncomingInvoiceComponents'
  );

  const { cultureTable } = useCultureTable();

  if (!cultureTable) return null;

  return <IncomingInvoiceComponents cultureTable={cultureTable} config={config} />;
}
