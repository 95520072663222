import { action, computed, makeObservable, observable } from 'mobx';
import queryString from 'query-string';
import { convertAnyDates } from 'utils/feed';

import { KnownTenantType, TenantOrganisationType } from '@zf/api-types/auth';
import { dataFrequency, meterType, uiCulture } from '@zf/api-types/enums';
import { DEFAULT_CULTURE } from '@zf/utils/src/internationalisation';
import { capitalizeFirstWord } from '@zf/utils/src/string';

import RootStore from '../';
import { EnumKeyType } from '../../../../src/app-context/hooks/use-enum';
import { templateStrings } from '../../../../src/utils/lang';
import { METHODS, RequestResultType } from '../../../../src/utils/request';
import { getApiURL } from '../../../constants/api';
import SystemAdminService from '../../services/SystemAdminService';
import TenantService from '../../services/TenantService';
import {
  EnumerationSymbolType,
  EnumRequestType,
  EnumsType,
  EnumType,
  sendRequestParamsType,
  TenantReducer
} from './app-store-types';
import { API } from './Axios';
import UserStore from './UserStore';

export default class ApplicationStore {
  public rootStore: RootStore;
  public userStore: UserStore;
  public toggleLables: boolean = sessionStorage.getItem('toggleLables') === 'true';

  public langFiles: Map<uiCulture, Record<string, string>> | undefined;

  public culture = DEFAULT_CULTURE;

  public tenantReducer: TenantReducer;

  public enums: EnumsType;
  public systemAdminService: SystemAdminService;
  public tenantService: TenantService;
  public tenants: KnownTenantType[] = [];
  public filteredTenants: KnownTenantType[] = [];
  public searchTenantValue: string = '';
  public searchIndex: number | undefined;
  public tenantsIsLoading: boolean;
  public hasTenantPermissions: boolean | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.userStore = new UserStore(this);

    this.systemAdminService = new SystemAdminService(this);
    this.tenantService = new TenantService(this);

    this.setLangFiles();

    makeObservable(this, {
      userStore: observable,

      culture: observable,
      tenantReducer: observable,
      tenants: observable,
      searchTenantValue: observable,
      filteredTenants: observable,
      searchIndex: observable,
      tenantsIsLoading: observable,
      toggleLables: observable,

      rootUrl: computed,

      fetchEnums: action,
      getEnum: action,
      getEnumTranslation: action,
      getTranslation: action,
      getTranslationInAnyVersion: action,
      getTenants: action,

      setLangFiles: action,
      setCulture: action,
      setTenantValue: action,
      setOrganization: action,
      searchTenant: action,
      setFilteredTenants: action,
      setToggleLables: action,
      // API call functions
      sendRequest: action
    });
  }

  setLangFiles = () => {
    if (process.env.REACT_APP_API_ENDPOINT !== 'test') {
      this.langFiles = new Map<uiCulture, Record<string, string>>();

      const context = require.context('lang', true, /.json$/);

      context.keys().forEach((key) => {
        if (key.charAt(0) === 'l') {
          return;
        }

        const fileName = key.replace('./', '');
        const resource = require(`lang/${fileName}`);
        const namespace = fileName.replace('.json', '') as uiCulture;
        this.langFiles?.set(namespace, resource);
      });
    }
  };

  setCulture = (newCulture: string) => {
    this.culture = newCulture;
  };

  getLanguageData = (newLang: uiCulture) => {
    return { ...this.langFiles?.get(newLang) };
  };

  setToggleLables = (value: boolean) => {
    sessionStorage.setItem('toggleLables', value.toString());
    this.toggleLables = value;
  };

  // Store version of get translation, mobx solved a lot of translation related bugs such as wrong translation shown in tooltips, en instead of dutch language.
  getTranslation = (key: string, params?: Record<string, string | number>) => {
    if (process.env.REACT_APP_API_ENDPOINT !== 'test') {
      if (sessionStorage.getItem('toggleLables') === 'true') return key;
      let languageData = this.getLanguageData(this.userStore.lang);

      if (!languageData[key]) {
        // Check if language data doesn't exist in english
        languageData = this.getLanguageData(uiCulture.en);

        if (!languageData[key]) {
          // If it doesn't exist throw error
          return key;
        }
      }

      let localised = languageData[key];

      if (params) localised = templateStrings(localised, params);
      return localised;
    }

    return '';
  };

  getMatchTranslationsKeys = (key: string, languageData: any): Array<string> => {
    try {
      const matcher = new RegExp(key, 'g');
      return Object.keys(languageData).filter((K) => matcher.test(K));
    } catch (error) {
      return [];
    }
  };

  getTranslationInAnyVersion = (section: string, version: string, feedItem: any) => {
    try {
      let languageData = this.getLanguageData(this.userStore.lang);

      // Format any dates
      feedItem.payLoad = convertAnyDates(feedItem.payLoad);

      /**
       * @description check the key where version exits
       * @returns translated key with params
       */
      let key = `${section}.${version}.${feedItem.actionType}`;
      if (languageData[key]) {
        return this.getTranslation(key, feedItem.payLoad);
      }

      /**
       * @description if key with original version doesn't exits use regex to find latest one
       * @returns translated key with params
       */
      key = `${section}.\\d.${feedItem.actionType}`;
      const keys = this.getMatchTranslationsKeys(key, languageData);
      if (keys.length > 0) {
        const lastKey = keys.at(-1);
        return lastKey && this.getTranslation(lastKey, feedItem.payLoad);
      }

      /**
       * @description return empty string otherwise
       */
      // eslint-disable-next-line
      console.error(key, 'does not exist');
      return '';
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      return '';
    }
  };

  getTenants = async () => {
    this.tenantsIsLoading = true;
    this.searchTenantValue = '';
    const res: KnownTenantType[] = await this.systemAdminService.getTenants(this.searchTenantValue);
    this.tenants = res.sort((a: KnownTenantType, b: KnownTenantType) => {
      if (a.organisationName < b.organisationName) {
        return -1;
      }
      if (a.organisationName > b.organisationName) {
        return 1;
      }
      return 0;
    });
    this.setFilteredTenants(res);
    this.tenantsIsLoading = false;
  };

  setFilteredTenants = (filteredTenants: KnownTenantType[]) => {
    this.filteredTenants = filteredTenants;
  };

  getEnum = <T>(name: EnumKeyType, capitalize = true) => {
    if (!this.enums) return [];

    const foundEnum = this.enums[name];
    if (!foundEnum) return [];

    const returnValue: EnumType<T>[] = foundEnum.map((val) => {
      return {
        value: val.symbol as any,
        text: capitalize ? capitalizeFirstWord(val.label) : val.label
      };
    });

    // Exceptions
    if (name === 'dataFrequency') {
      return returnValue.filter((e) => {
        const casted = e.value as unknown as dataFrequency;
        return (
          casted !== dataFrequency.pt1m &&
          casted !== dataFrequency.pt5m &&
          casted !== dataFrequency.pt15m &&
          casted !== dataFrequency.pt30m
        );
      });
    }

    if (name === 'meterType') {
      return returnValue.filter((e) => {
        const casted = e.value as unknown as meterType;
        return casted !== meterType.prepayment;
      });
    }

    return returnValue;
  };

  getEnumTranslation = (enumKey: EnumKeyType, symbol: string) => {
    if (!this.enums) return symbol;

    if (enumKey && (typeof symbol === 'undefined' || symbol === null || symbol === '')) {
      // eslint-disable-next-line
      console.error(`Tried to get an enum translation for ${enumKey} without an enum value`);
    }

    const found = this.enums[enumKey]?.find((e) => e.symbol === symbol);

    if (!found) {
      // eslint-disable-next-line
      console.error(`Translation not found for ${enumKey}: ${symbol}`);
    }

    const text = (found?.label || symbol)?.trim();

    if (enumKey === 'unitOfMeasure') {
      return text;
    }

    return capitalizeFirstWord(text);
  };

  searchTenant = async (searchValue: string) => {
    this.searchTenantValue = searchValue;
    this.tenantsIsLoading = true;
    const res: KnownTenantType[] = await this.systemAdminService.getTenants(this.searchTenantValue);
    this.tenants = res.sort((a: KnownTenantType, b: KnownTenantType) => {
      if (a.organisationName < b.organisationName) {
        return -1;
      }
      if (a.organisationName > b.organisationName) {
        return 1;
      }
      return 0;
    });
    this.setFilteredTenants(res);
    this.tenantsIsLoading = false;
  };

  fetchEnums = async () => {
    await this.sendRequest<EnumRequestType[]>({
      request: {
        endpoint: '/sys/enumerations'
      },
      donotCache: true
    })
      .then((result) => {
        try {
          const res = result.data.reduce((acc: Record<string, EnumerationSymbolType[]>, val) => {
            acc[val.type] = val.symbols;
            return acc;
          }, {});

          this.enums = res;
        } catch (e) {
          this.rootStore.errorStore.pushError(e);
        }
      })
      .catch((e) => {
        this.rootStore.errorStore.pushError(e);
      });
  };

  setTenantValue = async (tenantValue: Partial<TenantReducer>, sid?: string) => {
    this.tenantReducer = { ...this.tenantReducer, ...tenantValue };
    await this.userStore.getAuthInfo(sid);
    await this.fetchEnums();
  };

  get rootUrl() {
    return `/${this.tenantReducer.tenant?.name}/${
      this.tenantReducer.organization?.shortCode
        ? this.tenantReducer.organization.shortCode
        : this.tenantReducer.organization?.code
    }`;
  }

  setOrganization = (org: TenantOrganisationType | null) => {
    this.tenantReducer.organization = org;
  };

  sendRequest = async <T>(
    params: sendRequestParamsType,
    hasOneDataLevel?: boolean,
    mock?: boolean
  ): Promise<RequestResultType<T>> => {
    const { request, customHeaders = {}, responseType } = params;
    let { query } = request;
    const { endpoint, selector = '', method = METHODS.GET, data = null } = request;

    // Add default order by to GET requests
    if (method === METHODS.GET && !query?.orderBy) {
      query = { ...query, orderBy: '-CreatedDateTime' };
    }

    query = { ...query, 'api-version': '0.0' };

    const url = getApiURL(endpoint, mock) + endpoint + selector + (query ? '?' + queryString.stringify(query) : '');

    if (request.timeStamp) {
      customHeaders.timestamp = request.timeStamp;
    }

    customHeaders['apim-sub'] = 'cc40c85514c342d5a08e53e535e48836';

    const api = new API<T>({
      method,
      url,
      customHeaders,
      responseType,
      data,
      hasOneDataLevel,
      tenantReducer: this.tenantReducer,
      lang: this.userStore.lang
    });
    return api.execute();
  };
}
