import classNames from 'classnames';
import React, { ForwardedRef, forwardRef, memo } from 'react';

import css from './tree-item.module.scss';

type Props = {
  id: string;
  indentLevel: number;
  className?: string;
};

const Trail = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { id, indentLevel, className } = props;

  const indents: JSX.Element[] = [];

  for (let i = 1; i <= indentLevel; i++) {
    indents.push(
      <div
        key={`part-trail-${id}-${i}`}
        className={classNames(css['trail'], { [css['rounded']]: i === indentLevel })}
      />
    );
  }

  return (
    <div id={`trail-${id}`} ref={ref} className={classNames(css['trail-wrapper'], className)}>
      {indents}
    </div>
  );
});

export default memo(Trail);
