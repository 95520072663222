import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { uiCulture } from '@zf/api-types/enums';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';

export const manuallyConfirmOutgoingBankingTransaction = async (
  outgoingTransactionId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<OutgoingBankingTransactionType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/OutgoingBankingTransactions/${outgoingTransactionId}/manuallyconfirm`
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const cancelOutgoingBankingTransaction = async (
  outgoingBankingTransaction: OutgoingBankingTransactionType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<OutgoingBankingTransactionType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/OutgoingBankingTransactions/${outgoingBankingTransaction.id}/manuallycancel`
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const deleteOutgoingBankingTransaction = async (
  bankingTransactionId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/bill/OutgoingBankingTransactions/${bankingTransactionId}`
      },
      tenantReducer,
      lang
    })
  ).data;
};
