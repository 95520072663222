import React from 'react';

import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import ConfigCard from '@zf/stella-react/src/atoms/Card/ConfigCard';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context/app-context';
import { ICON_COLORS } from '../../../../constants/icons';
import { SelectedActivityType } from './activity-context/context';
import css from './activity.module.scss';
import useActivities from './hooks/useActivities';

export type ActivityStatusType = 'new' | 'complete' | 'error';

type Props = {
  subject: string;
  status: ActivityStatusType;
  activity: SelectedActivityType;
  isActive: boolean;
  onClick: () => void;
};

export default function Activity(props: Props) {
  const { subject, status, isActive, activity, onClick } = props;
  const { task } = useActivities();
  const { i18n } = useAppContext();

  if (!task) return null;

  return (
    <ConfigCard id={subject} className={css['activity']} action={onClick} isActive={isActive}>
      <div>
        <Paragraph color={colors['silver-600']}>
          {i18n.getTranslation(`tasks.${activity}_descr_${task.moveRequestType}`)}
        </Paragraph>
        <Heading headingType="h3">{subject}</Heading>
      </div>

      <Center type="vertical">
        <NewStatusBadge color={ICON_COLORS[status]} status_={i18n.getTranslation(`tasks.${status}`)} />
      </Center>
    </ConfigCard>
  );
}
