import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useCallback, useImperativeHandle } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { IncomingMutationType, IncomingOutgoingTransferDetailsType } from '@zf/api-types/billing/incoming-mutations';
import {
  ibanConfirmationStatus,
  incomingMutationStatus,
  incomingMutationType,
  transactionReferenceType
} from '@zf/api-types/enums';
import { TransactionType } from '@zf/api-types/transactions';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { List } from '@zf/stella-react/src/atoms/List';

import { ErrorBoundary } from '../../../../../../components/Error';
import { Link } from '../../../../../../design-system/Components';
import { DialogClickRef, ValidationRef } from '../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../../hooks/useStore';
import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import css from './incoming-transaction-details.module.scss';
import IncomingTransferDetails from './mutation-details/IncomingTransferDetails';
import OutgoingTransferDetails from './mutation-details/OutgoingTransferDetails';
import RefundConfirmationDetails from './mutation-details/RefundConfirmationDetails';
import RefundMutationConfirmationDetails from './mutation-details/RefundMutationConfirmationDetails';
import RefundReversalDetails from './mutation-details/RefundReversalDetails';
import RequestConfirmationDetails from './mutation-details/RequestConfirmationDetails';
import RequestMutationConfirmationDetails from './mutation-details/RequestMutationConfirmationDetails';
import RequestReversalDetails from './mutation-details/RequestReversalDetails';
import UnknownDetails from './mutation-details/UnknownDetails';
import TransactionDetailsWrapper from './transaction-details/TransactionDetailsWrapper';
import { IbanUnknown } from './IbanUnknown';
import { UpdateIbanConfirmation } from 'actions/incoming-banking-transactions/incoming-banking-transactions';
import { notify } from 'events/notification-events';
import { MatchDialogState } from 'actions/incoming-mutation/ManualMatchDialog';
import { useAppContext } from 'app-context';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

type Props = {
  incomingMutation: IncomingMutationType;
  state?: MatchDialogState;
  dispatch?: React.Dispatch<Partial<MatchDialogState>>;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refresh?: () => void;
  matchScreen?: boolean;
};

const IncomingMutationDetailsDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { validationRef, incomingMutation, matchScreen } = props;
  const { applicationStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { i18n, tenantReducer } = useAppContext();

  const stateReducer = createStateReducer<MatchDialogState, Partial<MatchDialogState>>();
  const [state, dispatchCustomer] = React.useReducer(stateReducer, {
    selectedIds: [],
    customerId: incomingMutation?.details?.matchingCustomer?.customerId,
    customerName: incomingMutation?.details?.matchingCustomer?.shortDisplayName,
    amount: 0,
    remainingAmount: 0,
    isBooked: false,
    refreshTrigger: '',
    saveIbanOnCustomer: false,
    ibanConfirmationStatus: incomingMutation?.details?.ibanConfirmationStatus,
    isIbanConfirmedOnTransaction: incomingMutation?.details?.isIbanConfirmedOnTransaction
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(
        incomingMutation.details.ibanConfirmationStatus !== ibanConfirmationStatus.UnknownSecond &&
          incomingMutation.details.ibanConfirmationStatus !== ibanConfirmationStatus.UnknownFirst
      );
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(state)]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await UpdateIbanConfirmation(
          incomingMutation.incomingBankingTransactionId,
          state.customerId,
          incomingMutation.details.iban,
          state.isIbanConfirmedOnTransaction,
          tenantReducer,
          i18n.lang
        );

        if (state.isIbanConfirmedOnTransaction && incomingMutation?.details?.isIbanConfirmedOnTransaction === false) {
          notify.success({
            content: i18n.getTranslation('customer.saved_iban_to_customer', {
              customer: state.customerName ? state.customerName : ''
            })
          });
        } else if (state.isIbanConfirmedOnTransaction) {
          notify.success({
            content: i18n.getTranslation('customer.saved_iban_to_customer', {
              customer: state.customerName ? state.customerName : ''
            })
          });
        }

        if (incomingMutation?.details?.isIbanConfirmedOnTransaction && state.isIbanConfirmedOnTransaction === false) {
          notify.success({
            content: i18n.getTranslation('customer.removed_iban_to_customer', {
              customer: state.customerName ? state.customerName : ''
            })
          });
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`customer.save_iban_to_customer_fail`),
          error: e
        });
      }
    }
  }));

  const getReferenceUrl = useCallback((settled: string) => {
    switch (settled) {
      case transactionReferenceType.invoice:
        return 'invoices';
      case transactionReferenceType.payment:
        return 'payments/payments';
      case transactionReferenceType.collectionstep:
        return 'collection-cases';
    }
  }, []);

  const detailSwitcher = () => {
    switch (incomingMutation.type) {
      case incomingMutationType.incomingtransfer: {
        return (
          <>
            <IncomingTransferDetails {...props} />
            {incomingMutation.status !== incomingMutationStatus.processed && !matchScreen && (
              <IbanUnknown incomingMutation={incomingMutation} state={state} dispatch={dispatchCustomer} />
            )}
          </>
        );
      }
      case incomingMutationType.outgoingtransfer: {
        return <OutgoingTransferDetails {...props} />;
      }
      case incomingMutationType.refundreversal: {
        return <RefundReversalDetails {...props} />;
      }
      case incomingMutationType.refundtransactionconfirmation: {
        return <RefundConfirmationDetails {...props} />;
      }
      case incomingMutationType.paymentrequestreversal: {
        return <RequestReversalDetails {...props} />;
      }
      case incomingMutationType.paymentrequestmutationconfirmation: {
        return <RequestMutationConfirmationDetails {...props} />;
      }
      case incomingMutationType.refundmutationconfirmation: {
        return <RefundMutationConfirmationDetails {...props} />;
      }
      case incomingMutationType.paymentrequesttransactionconfirmation: {
        return <RequestConfirmationDetails {...props} />;
      }
      case incomingMutationType.unknown: {
        return <UnknownDetails {...props} />;
      }
    }
  };

  const response = useSuspenseSingleAPI<PagedResponseType<TransactionType>>({
    request: {
      endpoint: `/bill/Transactions/incomingMutation/${incomingMutation.id}`
    }
  });

  const TransactionSwitcher = () => {
    switch (incomingMutation.type) {
      case incomingMutationType.incomingtransfer:
      case incomingMutationType.outgoingtransfer: {
        const casted = incomingMutation.details as IncomingOutgoingTransferDetailsType;

        return (
          <>
            {casted.matchingTransactions.length > 0 ? (
              <>
                <Heading style="bold" headingType="h3">
                  {getTranslation('nav_detail.transactions')}
                </Heading>
                <List>
                  {casted.matchingTransactions.map((e, index) => {
                    return (
                      <li className={css['matching-transaction-list-item']} key={e.referenceId}>
                        <Link
                          id={`transaction-${e.referenceId}`}
                          url={`${rootUrl}/${getReferenceUrl(e.transactionReferenceType)}/${e.referenceId}`}
                        >
                          {`${index + 1}) ${e.transactionReferenceType}-${e.referenceDisplayName}`}
                        </Link>
                      </li>
                    );
                  })}
                </List>
              </>
            ) : null}
          </>
        );
      }
    }
  };

  const hasResults = response.result ? response.result.data.totalRecords > 0 : null;

  return (
    <>
      <div className={css['detail-switcher']}>{detailSwitcher()}</div>
      {hasResults ? (
        <ErrorBoundary>
          <TransactionDetailsWrapper {...props} />
        </ErrorBoundary>
      ) : (
        TransactionSwitcher()
      )}
    </>
  );
});

export default observer(IncomingMutationDetailsDialog);
