import React from 'react';

import StellaSimpleDropdown from './SimpleDropdown/StellaSimpleDropdown';
import timeZonesJson from './time-zones.json';

export type TimeZoneProps = {
  id: string;
  placeholder?: string;
  selectedValues: string[];
  disabled?: boolean;
  multiSelect?: boolean;
  searchPlaceholder?: string;
  error?: boolean;
  className?: string;
  autoFocus?: boolean;
  clear?: boolean;
  onFocus?: (step: string) => void;
  onChange: (values: string[]) => void;
};

export const getTimeZoneDropDown = () => {
  return timeZonesJson.timezones.map((tz) => ({
    value: tz.time_zone_id,
    text: `${tz.time_zone_id} ${tz.base_utc_offset}`
  }));
};

export default function StellaTimeZoneDropDown(props: TimeZoneProps) {
  return <StellaSimpleDropdown clear values={getTimeZoneDropDown()} {...props} />;
}
