import React from 'react';

import { InvoiceRowType, InvoiceType, MarkAsSentInvoiceType, SendInvoiceType } from '@zf/api-types/invoice';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  selectedRows?: Array<InvoiceRowType>;
  invoiceToHandle?: SendInvoiceType;
  onComplete?: (updatedRows: InvoiceType[]) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { selectedRows, invoiceToHandle, onComplete } = props;
  const { invoiceStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  let invoicesToHandle: Array<MarkAsSentInvoiceType> = [];

  if (selectedRows) {
    invoicesToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (invoiceToHandle) {
    invoicesToHandle = [invoiceToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const updatedInvoices = await Promise.all(
          invoicesToHandle.map((invoice) => invoiceStore.sendPostal(invoice, true))
        );

        if (onComplete) onComplete(updatedInvoices);

        notify.success({
          content: getTranslation('actions.invoice.send_success')
        });
      } catch (error) {
        notify.error({
          content: getTranslation('actions.invoice.send_failed'),
          error
        });
      }
    }
  }));

  return <Paragraph>{getTranslation('actions.post.descr')}</Paragraph>;
});
