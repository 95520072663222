import React from 'react';

import FilterTag from '../../../../../components/Filters/filter-tag';
import FilterTags from '../../../../../components/Filters/filter-tags';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  onlyActive: boolean;
  removeFilter: () => void;
};

export default function ChannelTags(props: Props) {
  const { onlyActive, removeFilter } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <FilterTags
      key="filter-tags"
      tags={[
        onlyActive && (
          <FilterTag
            key="only-active"
            value={getTranslation('meter.only_active_channels')}
            removeFilter={removeFilter}
          />
        )
      ]}
    />
  );
}
