import React from 'react';

import { useAppContext } from '../../../../../app-context';
import FilterTag from '../../../../../components/Filters/filter-tag';
import FilterTags from '../../../../../components/Filters/filter-tags';
import css from './use-processing-filter.module.scss';

const useProcessingFilter = () => {
  const { i18n } = useAppContext();

  const [onlyErrors, setOnlyErrors] = React.useState(false);

  const tags: React.ReactNode[] = [];

  if (onlyErrors) {
    tags.push(
      <FilterTag
        key="only-errors"
        className={css['tag']}
        value={i18n.getTranslation('import_jobs.only_error')}
        removeFilter={() => setOnlyErrors(false)}
      />
    );
  }

  const filterTags = [<FilterTags key="filter-tags" tags={tags} />];

  return {
    filterTags,
    onlyErrors,
    setOnlyErrors
  };
};

export default useProcessingFilter;
