import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { PagedResponseType } from '@zf/api-types/api';
import {
  CustomerBankAccountType,
  CustomerMandateType,
  UpdateCustomerBankAccountType
} from '@zf/api-types/billing/customer-bank-account';
import { culture, entitySubjectType, mandateStatus, mandateType, paymentMethod } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { MIN_DATE } from '@zf/utils/src/date';
import { formatIBAN } from '@zf/utils/src/iban';

import RootStore from '../../../app-context/stores';
import FilesStore from '../../../components/units/EntityAttachments/logic/FilesStore';
import { notify } from '../../../events/notification-events';
import BankDetailsForm from '../forms/BankDetailsForm';
import MandateForm from '../forms/MandateForm';
import Customer from '../models/Customer';
import CustomerService from '../services/CustomerService';
import { EntityAttachment } from '@zf/api-types/entity-attachments/entity-attachments';
import EntityAttachmentBase from '../../../components/units/EntityAttachments/logic/EntityAttachmentBase';
import { EntityAttachmentSpecificFeatures } from '../../../components/units/EntityAttachments/logic/entity-attachments';
import moment from 'moment';

enum customerActionPermissions {
  mayAddContract = 'mayAddContract',
  mayAddPayment = 'mayAddPayment',
  mayAddInvoice = 'mayAddInvoice',
  mayAddMeasurement = 'mayAddMeasurement'
}

export default class CustomerStore implements EntityAttachmentSpecificFeatures<EntityAttachmentBase> {
  public rootStore: RootStore;
  public filesStore: FilesStore<EntityAttachment>;

  public pageActionPermissions: Record<customerActionPermissions, boolean>;

  public bankDetailsForm: BankDetailsForm;

  public customerService: CustomerService;

  public selectedCustomer: Customer;
  public selectedCustomerCulturePref: culture | undefined;
  public bankAccounts: PagedResponseType<CustomerBankAccountType>;
  public customerLocationId = '';
  public bankAccountsIsLoading = true;
  public entityAttachmentFeatures = new EntityAttachmentBase();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.filesStore = new FilesStore(
      this.rootStore,
      entitySubjectType.customer,
      this.entityAttachmentFeatures.setPermissions
    );
    this.customerService = new CustomerService('/md/customers/', rootStore.applicationStore);
    this.generateActionPermissions();
    this.entityAttachmentFeatures.setFileStore(this.filesStore);

    makeObservable(this, {
      filesStore: observable,

      pageActionPermissions: observable,

      selectedCustomer: observable,
      selectedCustomerCulturePref: observable,
      bankAccountsIsLoading: observable,
      bankDetailsForm: observable,
      bankAccounts: observable,
      customerLocationId: observable,

      accountsToApprove: computed,
      confirmedAccounts: computed,
      dropdownBankAccounts: computed,
      defaultBankAccount: computed,

      loadCustomerDetailData: action,
      setExistingCustomer: action,
      setNewCustomer: action,
      setbankDetailsForm: action,
      loadBankAccounts: action,
      cancelChanges: action,
      setBankAccounts: action,
      initBankAccount: action,
      AddBankAccount: action,
      deleteBankAccount: action,
      onRejectComplete: action,
      onConfirmComplete: action,
      needsMandate: action,
      addMandate: action,
      cancelMandate: action,
      initBankDetails: action,
      cancelAllForms: action,
      setCustomerLocationId: action,
      setCustomerPrefCulture: action,
      onLeaveDetailPage: action,
      hasNewMandate: action,
      generateActionPermissions: action,
      getActiveCustomerLocation: action
    });
  }

  setNewCustomer(customer: CustomerType | null) {
    this.selectedCustomer = new Customer(this.rootStore);
    if (customer) {
      this.selectedCustomer.customer = customer;
    }
  }

  setExistingCustomer(customer: CustomerType | null) {
    if (customer) {
      this.selectedCustomer.customer = customer;
    }
  }

  setCustomerLocationId = (newId: string) => {
    this.customerLocationId = newId;
  };

  setBankAccounts(bankAccount: PagedResponseType<CustomerBankAccountType>) {
    this.bankAccounts = bankAccount;
  }

  setCustomerPrefCulture = (newCulture: culture) => {
    this.selectedCustomerCulturePref = newCulture;
  };

  getActiveCustomerLocation = async (customerId: string) => {
    const customerLocations = await this.rootStore.serviceLocationStore.serviceLocationService.getLocationsForQuery({
      customerId
    });

    return customerLocations.find((cl) =>
      cl.services.some((s) =>
        s.contracts.some((c) => moment().isBetween(c.supplyStartDateTime, c.supplyEndDateTime, undefined, '[]'))
      )
    );
  };

  async loadCustomerDetailData(id: string) {
    const customer = await this.customerService.getCustomerById(id);
    this.setNewCustomer(customer);

    // Customer language preference
    if (!customer.communicationPreferences.culture) {
      const cultureTable = await this.rootStore.configStore.configService.getCultureTable();
      this.setCustomerPrefCulture(cultureTable.defaultCulture);
    } else {
      this.setCustomerPrefCulture(customer.communicationPreferences.culture);
    }

    this.setbankDetailsForm(id);

    const customerLocation = await this.getActiveCustomerLocation(customer.id);

    if (customerLocation) {
      this.setCustomerLocationId(customerLocation.id);
    }

    this.rootStore.uiStore.setBrowserTitle(this.selectedCustomer.title);
  }

  async loadBankAccounts(customerId: string) {
    this.bankAccounts = await this.customerService.getBankAccounts(customerId);
    this.bankAccounts.results.forEach((e, index) => {
      e.mandateForm = new MandateForm(
        this.rootStore,
        {
          iban: e.iban,
          isDefault: e.isDefault,
          mandate: e.activeMandate as CustomerMandateType
        },
        index
      );
    });

    runInAction(() => {
      this.bankAccountsIsLoading = false;
    });
  }

  async onRejectComplete(idToRemove: string, bankAccounts: CustomerBankAccountType[]) {
    const indexToRemove = bankAccounts.findIndex((acc) => {
      return acc.id === idToRemove;
    });
    if (indexToRemove !== -1) {
      bankAccounts.splice(indexToRemove, 1);
    }
  }

  async onConfirmComplete(updatedAccount: CustomerBankAccountType, bankAccounts: CustomerBankAccountType[]) {
    const indexToReplace = bankAccounts.findIndex((acc) => {
      return acc.id === updatedAccount.id;
    });

    if (indexToReplace !== -1) {
      bankAccounts[indexToReplace] = updatedAccount;
    }
  }

  get accountsToApprove() {
    return this.bankAccounts.results.filter((acc) => {
      return acc.addedFromIncomingBankingTransaction === true;
    });
  }

  get confirmedAccounts() {
    return this.bankAccounts.results.filter((acc) => {
      return acc.addedFromIncomingBankingTransaction === false;
    });
  }

  get dropdownBankAccounts() {
    return this.confirmedAccounts.map((acc) => {
      return { text: formatIBAN(acc.iban), value: acc.id };
    });
  }

  AddBankAccount() {
    const initialValues: UpdateCustomerBankAccountType = {
      iban: '',
      bic: '',
      isDefault: false,
      activeMandate: null
    };
    const bankAccount: UpdateCustomerBankAccountType = {
      ...initialValues,
      addedFromIncomingBankingTransaction: false,
      mandateForm: new MandateForm(
        this.rootStore,
        {
          iban: initialValues.iban as string,
          isDefault: initialValues.isDefault as boolean,
          mandate: initialValues.activeMandate as CustomerMandateType
        },
        this.bankAccounts.results.length
      )
    };
    this.bankAccounts.results.push(bankAccount as CustomerBankAccountType);
  }

  hasNewMandate = (bankAccount: UpdateCustomerBankAccountType) => {
    return bankAccount.activeMandate && !bankAccount.activeMandate.number;
  };

  needsMandate = (bankAccount: UpdateCustomerBankAccountType) => {
    return (
      this.bankDetailsForm._get('paymentMethod') === paymentMethod.sdd &&
      bankAccount.mandateForm?._get('isDefault') &&
      bankAccount.mandateForm?.fieldIsEmpty('mandate')
    );
  };

  async deleteBankAccount(index: number) {
    if (this.bankAccounts.results[index].isDefault) {
      notify.warning({
        content: this.rootStore.applicationStore.getTranslation('general.default_remove_warning')
      });
    } else if (this.bankAccounts.results[index].id) {
      try {
        const res = await this.customerService.deleteBankAccountRequest(this.bankAccounts.results[index].id);
        if (res) {
          this.bankAccounts.results.splice(index, 1);
          notify.success({
            content: this.rootStore.applicationStore.getTranslation('customer.delete_bank_account_success')
          });
        }
      } catch (e) {
        notify.error({
          content: this.rootStore.applicationStore.getTranslation('customer.delete_bank_account_fail'),
          error: e
        });
      }
    } else {
      this.bankAccounts.results.splice(index, 1);
    }
  }

  addMandate(index: number) {
    this.bankAccounts.results[index].mandateForm?._set('mandate', {
      number: '',
      signedDateTime: MIN_DATE,
      cancelledDateTime: MIN_DATE,
      type: mandateType.core,
      status: mandateStatus.requested
    });
  }

  cancelMandate(index: number) {
    this.bankAccounts.results[index].mandateForm?._clearField('mandate');
  }

  cancelAllForms() {
    this.bankAccounts.results.forEach((e) => {
      e.mandateForm?._reset();
    });
  }

  get defaultBankAccount() {
    return this.bankAccounts.results.find((acc) => acc.isDefault);
  }

  initBankDetails(form: BankDetailsForm) {
    this.bankDetailsForm = form;
  }

  initBankAccount(index: number, mandateForm: MandateForm) {
    this.bankAccounts.results[index].mandateForm = mandateForm;
  }

  getBankAccount(index: number) {
    return this.bankAccounts.results[index];
  }

  cancelChanges(index: number) {
    this.bankAccounts.results[index].mandateForm?._reset();
  }

  async setbankDetailsForm(customerId: string) {
    await this.loadBankAccounts(customerId);
    const paymentParameters = await this.customerService.paymentParameters();
    if (this.bankAccounts) {
      runInAction(() => {
        //initialize default bankaccount in global state
        const defaultPaymentTermId = paymentParameters.paymentTermsId;
        this.initBankDetails(
          new BankDetailsForm(this, {
            paymentMethod: this.selectedCustomer.customer.defaultPaymentMethod,
            paymentTerm: this.selectedCustomer.customer.paymentTermsId
              ? this.selectedCustomer.customer.paymentTermsId
              : defaultPaymentTermId,
            defaultBankAccount: this.defaultBankAccount?.id
          })
        );
      });
    }
  }

  generateActionPermissions = () => {
    this.pageActionPermissions = {
      mayAddContract: true,
      mayAddPayment: true,
      mayAddInvoice: true,
      mayAddMeasurement: !!this.customerLocationId
    };
  };

  onLeaveDetailPage = () => {
    this.filesStore.resetStore();
  };
}
