import React from 'react';

import { DataProviderPropertiesType } from '@zf/api-types/data-provider';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';

import { useAppContext } from '../../app-context';
import { DeleteIcon, Icon } from '../Icon';
import InputGenerator from './input-generator';

type Props = {
  tooltipFor: string;
  id?: string;
  property: DataProviderPropertiesType;
  parameters: Record<string, any> | undefined;
  parentSelection: number;
  setParameters: (values: any) => void;
};

export default function ArrayGenerator(props: Props) {
  const { property, parameters, parentSelection, id, tooltipFor, setParameters } = props;
  const { i18n } = useAppContext();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const setValue = (value: any, dataKey?: string) => {
    // @ts-ignore
    const copyValue = [...parameters];
    const obj = copyValue[selectedIndex];
    if (dataKey) obj[dataKey] = value;
    setParameters(copyValue);
  };

  const addValue = () => {
    // @ts-ignore
    const clonedArray = parameters ? [...parameters] : [];

    let newEmptyObject: any = {};

    if (property.properties) {
      for (const prop of property.properties) {
        if (prop.defaultValue) {
          newEmptyObject = prop.defaultValue;
        } else if (prop.propertyType.startsWith('array')) {
          newEmptyObject[prop.name] = [];
        } else if (prop.propertyType === 'boolean') {
          newEmptyObject[prop.name] = false;
        } else {
          newEmptyObject[prop.name] = '';
        }
      }
    }

    clonedArray.push(newEmptyObject);
    setParameters(clonedArray);
  };

  const deleteValue = (index: number) => {
    // @ts-ignore
    const clonedArray = parameters ? [...parameters] : [];
    clonedArray.splice(index, 1);

    setSelectedIndex(-1);
    setParameters(clonedArray);
  };

  const cardWrapper = (content: React.ReactNode) => {
    return (
      <Card id="dataprovider-parameters-card" width="1">
        <CardHeader primaryText={i18n.getTranslation('general.add')} onPrimaryClick={addValue}>
          {i18n.getTranslation(`file_format.${property.name}`)}
        </CardHeader>
        {content}
      </Card>
    );
  };

  if (parentSelection === -1) {
    return (
      <>
        {cardWrapper(
          <CardEmptyBody icon={<Icon type="options" />} title={i18n.getTranslation('data_provider.no_selection')}>
            {i18n.getTranslation('data_provider.no_selection_description')}
          </CardEmptyBody>
        )}
      </>
    );
  } else if (!parameters || parameters.length === 0) {
    return (
      <>
        {cardWrapper(
          <CardEmptyBody
            icon={<Icon type="options" />}
            title={i18n.getTranslation('data_provider.no_parameters')}
            error={property.required}
          >
            {i18n.getTranslation('data_provider.no_parameters_description')}
          </CardEmptyBody>
        )}
      </>
    );
  } else {
    const rows = parameters.map((parameter: any, index: number) => {
      let returnObject: any = {};

      if (property.properties) {
        for (let i = 0; i < property.properties.filter((prop) => !prop.propertyType.startsWith('array')).length; i++) {
          const prop = property.properties[i];
          const complainingParameter: any = parameter;
          const value = complainingParameter[prop.name];

          if (!value && prop.defaultValue) {
            setParameters(prop.defaultValue);
          }

          returnObject[prop.name] = (
            <InputGenerator
              value={value}
              parameterIndex={index}
              propertyIndex={i}
              property={prop}
              setParameters={setValue}
            />
          );
        }
      }

      returnObject = {
        ...returnObject,
        deleteAction: (
          <DeleteIcon
            id={`delete-${property.name}-${index}`}
            tooltipFor={tooltipFor}
            onClick={() => deleteValue(index)}
          />
        )
      };

      return returnObject;
    });

    const columns = property.properties
      ? property.properties
          .filter((prop) => !prop.propertyType.startsWith('array'))
          .map((p, index) => {
            return (
              <DeprecatedStaticColumn
                key={`${p.name}-${index}`}
                flexWidth="1"
                dataKey={p.name}
                label={i18n.getTranslation(`file_format.${p.name}`)}
              />
            );
          })
      : [];

    columns.push(<DeprecatedStaticColumn key="delete" dataKey="deleteAction" showOnHover />);

    return (
      <>
        {cardWrapper(
          <CardBody type="table">
            <DeprecatedStaticTable
              rows={rows}
              onRowSelect={(index) => setSelectedIndex(index)}
              selectedIndex={selectedIndex}
            >
              {columns}
            </DeprecatedStaticTable>
          </CardBody>
        )}
        {property.properties &&
          property.properties
            .filter((prop_) => prop_.propertyType.startsWith('array'))
            .map((p_) => {
              const complainingParameter: any = parameters;

              return (
                <ArrayGenerator
                  id={id}
                  key={p_.name}
                  tooltipFor={tooltipFor}
                  property={p_}
                  parameters={
                    selectedIndex > -1 && complainingParameter[selectedIndex]
                      ? complainingParameter[selectedIndex][p_.name]
                      : []
                  }
                  parentSelection={selectedIndex}
                  setParameters={(value) => setValue(value, p_.name)}
                />
              );
            })}
      </>
    );
  }
}
