import classNames from 'classnames';
import React from 'react';

import { List } from '@zf/stella-react/src/atoms/List';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { Icon } from '../Icon';
import css from './filter-input-select.module.scss';

type Props = {
  id: string;
  title: string;
  iconType?: string;
  hasSelection: boolean;
  list: JSX.Element[];
  className?: string;
  blueTextWhenSelected?: boolean;
};

export default function FilterInputSelect(props: Props) {
  const { id, title, hasSelection, iconType, list, className, blueTextWhenSelected = true } = props;

  return (
    <TooltipTrigger
      tooltip={<List>{list}</List>}
      placement="right-start"
      // This fixes a mouse leave/close issue
      offset={[0, 0]}
      // This fixes a mouse leave/close issue
      renderTopLvl={false}
    >
      <div
        id={id}
        className={classNames(
          css['title-wrapper'],
          { [css['has-selected-item']]: hasSelection && blueTextWhenSelected },
          className
        )}
      >
        <Icon type={iconType} />
        <div>{title}</div>
        <Icon type="chevron-right" />
      </div>
    </TooltipTrigger>
  );
}
