import { MultiValue } from 'design-system/ComponentSets';
import moment from 'moment';
import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { StepAnchor, WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../app-context';
import LocationAutoFill from '../../../components/Autofills/LocationAutoFill';
import { CreatePropertyType, NewServiceLocationsType, STEP_NAMES } from './property-group-wizard';
import css from './property-group-wizard.module.scss';

type Props = {
  locationsToAdd: NewServiceLocationsType[];
  handleFocus: (step: string) => () => void;
  setValue: (value: Partial<CreatePropertyType>, initialApiSet?: boolean, backup?: boolean) => void;
};

const initialValues: NewServiceLocationsType = {
  serviceLocation: null
};

export default function LocationsSection(props: Props) {
  const { locationsToAdd, setValue, handleFocus } = props;
  const { i18n } = useAppContext();

  const excludedIds = locationsToAdd.reduce((acc: string[], loc) => {
    if (loc.serviceLocation) {
      acc.push(loc.serviceLocation.id);
    }
    return acc;
  }, []);

  return (
    <WizardSection>
      <WizardHeader>
        <StepAnchor name={STEP_NAMES[3]} />
        {i18n.getTranslation('property_groups.locations')}
      </WizardHeader>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('property_groups.location_heading')}
      </Heading>
      <InputContainer grid={false}>
        <MultiValue
          id="location_multivalue"
          title={i18n.getTranslation('property_groups.add_location_property')}
          initialValues={[]}
          intialNodeValue={initialValues}
          onChange={(serviceLocations) => setValue({ serviceLocations })}
          oneEntryRequired={false}
        >
          {({ value, dispatchValue, index }) => {
            return (
              <LocationAutoFill
                id={`selectLocation-${index}`}
                className={css['location-dropdown']}
                onChange={(val) => dispatchValue({ serviceLocation: val[0] || null })}
                query={{
                  availableForPropertySelectionFromDateTime: moment().toISOString()
                }}
                selectedValues={[value.serviceLocation?.id || '']}
                excludedIds={excludedIds}
                onFocus={handleFocus(STEP_NAMES[3])}
              />
            );
          }}
        </MultiValue>
      </InputContainer>
    </WizardSection>
  );
}
