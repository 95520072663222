import React from 'react';

import css from './two-column-section.module.scss';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export default function TwoColumnSection(props: Props) {
  const { children, style = {} } = props;

  return (
    <div className={css['two-column']} style={style}>
      {children}
    </div>
  );
}
