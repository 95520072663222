export function synchronouslyMapArray<E, R>(array: E[], mappingFunction: (entity: E) => R | undefined) {
  if (mappingFunction && Array.isArray(array)) {
    const processedRecords = array.reduce((acc: R[], entity: E) => {
      const processed = mappingFunction(entity);
      if (processed) acc.push(processed);
      return acc;
    }, []);

    return processedRecords;
  }
  return [];
}

export function extendMultiValue<T>(array: T[][], index: number, value: T): T[][] {
  const container = array[index] ? [...array[index]] : [];
  container.push(value);
  array[index] = container;
  return array;
}
