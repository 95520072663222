import React, { forwardRef, useImperativeHandle } from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  index: number;
  billingItemId: string;
};

export default forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { index, billingItemId } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { deleteBillingItem, deleteBillingItemInList } = productConfigStore.billingItemsStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteBillingItem(billingItemId);
        deleteBillingItemInList(index);

        notify.success({
          content: getTranslation('billing_items.delete_item_success')
        });
      } catch (e) {
        notify.error({
          content: getTranslation('billing_items.delete_item_fail'),
          error: e
        });
      }
    }
  }));

  return <Paragraph>{getTranslation('billing_items.delete_item_paragraph')}</Paragraph>;
});
