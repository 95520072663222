import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../hooks/useStore';
import EnumTranslationCardBody from './EnumTranslationCardBody';
import EnumTranslationFilters from './EnumTranslationFilters';

const EnumTranslationCard = () => {
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getEnumTranslations } = communicationStore.translationStore;

  useEffect(() => {
    getEnumTranslations(undefined, true);
  }, []);

  return (
    <Card width="3" id="communication-preferences-card">
      <CardHeader extraRight={<EnumTranslationFilters />}>{getTranslation('communication.translations')}</CardHeader>
      <CardBody type="indexTable" fixedHeight>
        <EnumTranslationCardBody />
      </CardBody>
    </Card>
  );
};

export default observer(EnumTranslationCard);
