import classNames from 'classnames';
import React from 'react';

import { List } from '@zf/stella-react/src/atoms/List';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import css from './tag-group.module.scss';

export type Props = {
  tags: (React.ReactNode | undefined)[];
};

export default function TagGroup(props: Props) {
  const { tags } = props;

  return (
    <div className={classNames(css['tag'], css['tag-group'])}>
      <TooltipTrigger tooltip={<List className={css['tag-group-list']}>{tags}</List>} placement="bottom-start">
        {`+ ${tags.length}`}
      </TooltipTrigger>
    </div>
  );
}
