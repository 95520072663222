import React, { useEffect, useState } from 'react';

import { useStore } from '../../../../../../hooks/useStore';
import { CustomerType } from '@zf/api-types/master-data/customer';
import DynamicIndexTable from 'components/Lang/DynamicIndexTable';
import { TransactionType, TransactionsRowType } from '@zf/api-types/transactions';
import { EmptyState, OpenTotalAmounts, TransactionTypeLink, settlementState } from '../Components';
import { useAppContext } from 'app-context';
import { formatDate } from '@zf/utils/src/date';
import { Button, TooltipTrigger } from 'design-system/Components';
import { Icon, Paragraph } from 'design-system/Foundation';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { settleSingleTransaction, undoSingleTransaction } from 'actions/payments/payments';
import { notify } from 'events/notification-events';
import { useRefreshListPage } from '../../Context/Context';
import { METHODS, sendRequest } from 'utils/request';
import { PagedResponseType } from '@zf/api-types/api';
import { formatMoney } from '@zf/utils/src/number';

type Props = {
  customer: CustomerType;
  settledTransaction: TransactionType;
};

export default function TransactionsListUnSettled(props: Props) {
  const { customer, settledTransaction } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const {
    tenantReducer,
    i18n: { lang, culture },
    enumReducer
  } = useAppContext();
  const { setRefresh } = useRefreshListPage();
  const [transactions, setAllTransactions] = useState<{ settledWith: TransactionType[]; all: TransactionType[] }>({
    settledWith: [],
    all: []
  });

  const getSettledWithAndAllTransactions = async () => {
    return await Promise.all([
      (
        await sendRequest<PagedResponseType<TransactionType>>({
          request: {
            method: METHODS.GET,
            endpoint: `/bill/Transactions/${settledTransaction.id}/settledwith`
          },
          tenantReducer,
          lang
        })
      ).data.results,
      (
        await sendRequest<PagedResponseType<TransactionType>>({
          request: {
            method: METHODS.GET,
            endpoint: `/bill/transactions`,
            query: {
              onlyOpen: true,
              customeruuid: customer.id
            }
          },
          tenantReducer,
          lang
        })
      ).data.results
    ]);
  };

  const getSidePanelData = () => {
    getSettledWithAndAllTransactions().then(([settledWith, all]) =>
      setAllTransactions({
        settledWith,
        all
      })
    );
  };

  useEffect(() => {
    getSidePanelData();
  }, [settledTransaction.id]);

  const settleTransaction = async (transactionId: string) => {
    try {
      if (transactionId) {
        await settleSingleTransaction(settledTransaction, transactionId, tenantReducer, lang);
      }
    } catch (e) {
      notify.error({
        content: getTranslation('payments.settle_failed'),
        error: e
      });
    }

    setRefresh(Date.now().toString());

    getSidePanelData();
  };

  const undoTransaction = async (transactionId: string) => {
    try {
      if (transactionId) {
        await undoSingleTransaction(settledTransaction, transactionId, tenantReducer, lang);
      }
    } catch (e) {
      notify.error({
        content: getTranslation('payments.undo_failed'),
        error: e
      });
    }
    setRefresh(Date.now().toString());

    getSidePanelData();
  };

  const processRecordSettled = (transaction: TransactionType): TransactionsRowType => {
    const isLocked = transaction.awaitingBankConfirmation || settledTransaction.awaitingBankConfirmation;

    return {
      __id: transaction.id,
      __entity: transaction,
      type: (
        <TransactionTypeLink
          transaction={transaction}
          enumReducer={enumReducer}
          offStatus={true}
          getTranslation={getTranslation}
          showIcon={false}
        />
      ),
      settledAmount: formatMoney(
        Math.abs(
          settledTransaction?.settlementDetails?.settledTransactionsWithAmounts.filter(
            ({ transactionId }) => transactionId === transaction.id
          )[0]?.settledAmount
        ),
        culture
      ),
      TransactionAmount: (
        <OpenTotalAmounts
          transaction={transaction}
          withStyle={transaction?.transactionOpenAmount === 0 ? 'linethrough' : 'normal'}
          withTotal={true}
        />
      ),
      CreatedDateTime: formatDate(transaction.createdDateTime),
      paymentReference: (
        <TooltipTrigger tooltip={transaction.paymentReference} placement="top">
          <Paragraph style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '12rem' }}>
            {transaction.paymentReference}
          </Paragraph>
        </TooltipTrigger>
      ),
      action: (
        <FlexElements justifyContent="space-between">
          {isLocked && <Icon name="lock" />}
          <Button
            id={`${transaction.id}-cancel`}
            type="text"
            size="small"
            onClick={async () => {
              await undoTransaction(transaction.id);
            }}
            disabled={isLocked}
          >
            {getTranslation('general.cancel')}
          </Button>
        </FlexElements>
      )
    };
  };

  const processRecordAll = (transaction: TransactionType): TransactionsRowType => {
    const isLocked = transaction.awaitingBankConfirmation || settledTransaction.awaitingBankConfirmation;

    return {
      __id: transaction.id,
      __entity: transaction,
      type: (
        <TransactionTypeLink
          transaction={transaction}
          enumReducer={enumReducer}
          offStatus={true}
          getTranslation={getTranslation}
          showIcon={false}
        />
      ),
      settledAmount: formatMoney(0, culture),
      TransactionAmount: (
        <OpenTotalAmounts
          transaction={transaction}
          withStyle={transaction?.transactionOpenAmount === 0 ? 'linethrough' : 'normal'}
          withTotal={true}
        />
      ),
      CreatedDateTime: formatDate(transaction.createdDateTime),
      paymentReference: (
        <TooltipTrigger tooltip={transaction.paymentReference} placement="top">
          <Paragraph style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '12rem' }}>
            {transaction.paymentReference}
          </Paragraph>
        </TooltipTrigger>
      ),
      action: (
        <FlexElements justifyContent="space-between">
          {isLocked && <Icon name="lock" />}
          <Button
            id={`${transaction.id}-settle`}
            type="secondary"
            size="small"
            onClick={async () => {
              await settleTransaction(transaction.id);
            }}
            disabled={isLocked}
          >
            {getTranslation('payments.settle')}
          </Button>
        </FlexElements>
      )
    };
  };

  const settledTransactions = transactions.settledWith.map((transaction) => processRecordSettled(transaction));

  /**
   * @description exlude the selected transaction from the all transactions
   */
  const allTransactionsWithUnSettled = transactions.all
    /**
     * @description open amount should be zero, to they can be used to settled
     */
    .map((transaction) => processRecordAll(transaction))
    .filter(
      ({ __entity }) =>
        __entity.transactionOpenAmount !== 0 &&
        __entity.id !== settledTransaction.id &&
        Math.sign(__entity.transactionAmount) !== Math.sign(settledTransaction.transactionAmount) &&
        settlementState(settledTransaction) !== 'settled' &&
        settlementState(settledTransaction) !== 'reversed'
    )
    .filter(
      ({ __entity }) =>
        transactions.settledWith.flatMap((transaction) => transaction.id).includes(__entity.id) === false
    );

  const sidePanelData = settledTransactions.concat(allTransactionsWithUnSettled.flat());

  return (
    <>
      {sidePanelData.length > 0 ? (
        <DynamicIndexTable
          cursorPointer
          rows={sidePanelData}
          columns={[
            {
              flexWidth: 3,
              label: getTranslation('general.detail'),
              dataKey: 'type'
            },
            {
              flexWidth: 1,
              label: getTranslation('cards.transactions.settled'),
              dataKey: 'settledAmount'
            },
            {
              flexWidth: 2,
              label: getTranslation('customer.transactions.to-settle-total'),
              dataKey: 'TransactionAmount'
            },
            {
              flexWidth: 1,
              label: getTranslation('general.date'),
              dataKey: 'CreatedDateTime'
            },
            {
              flexWidth: 1,
              label: getTranslation('transactions.reference_number'),
              dataKey: 'paymentReference'
            },
            {
              flexWidth: 2,
              label: '',
              dataKey: 'action'
            }
          ]}
        />
      ) : (
        <EmptyState />
      )}
    </>
  );
}
