import clone from 'clone';
import { Dispatch, useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { aggregationFrequency } from '@zf/api-types/enums';
import { SortType } from '@zf/api-types/general';
import { ConfigState } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import { queryParam } from '../hooks/useCreateRequest';
import { AggregatedDetailType } from '@zf/api-types/api';

export type ActionTypes = ResetAction | RefreshAction | UpdateAction | SetActionProgressAction | UpdateConfigAction;

export type RefreshAction = {
  type: 'refresh';
};

export type ResetAction = {
  type: 'reset';
  domain: DomainType;
};

export type UpdateAction = {
  type: 'update';
  update: {
    utils?: UtilStateType;
    current?: Map<DomainType, CurrentState>;
    config?: Map<ConfigType, ConfigState<any>>;
  };
};

export type SetActionProgressAction = {
  type: 'set_action_progress';
  inProgress: boolean;
};

export type UpdateConfigAction = {
  type: 'update_config';
  newConfig: ConfigTablesState;
};

export type DomainType =
  | 'contract'
  | 'customer'
  | 'customersgroup'
  | 'payment'
  | 'invoice'
  | 'importjob'
  | 'exportjob'
  | 'location'
  | 'propertygroup'
  | 'meter'
  | 'meter-issues'
  | 'prepayment_device'
  | 'completeness'
  | 'import-processing'
  | 'collection_case'
  | 'moverequest'
  | 'incoming_banking_transaction'
  | 'outgoing_banking_transaction'
  | 'country_specifics';

export type ConfigType =
  | 'assets'
  | 'bankAccounts'
  | 'numberSequences'
  | 'consumptionUnitTypes'
  | 'taxCodes'
  | 'paymentDelays'
  | 'paymentTerms'
  | 'incomingInvoiceComponents'
  | 'models'
  | 'importFileFormats'
  | 'dataImports'
  | 'exportFileFormats'
  | 'dataExports'
  | 'templates'
  | 'customEntityPropertyTypes';

const configTypes: ConfigType[] = [
  'assets',
  'bankAccounts',
  'numberSequences',
  'consumptionUnitTypes',
  'taxCodes',
  'paymentDelays',
  'paymentTerms',
  'incomingInvoiceComponents',
  'models',
  'importFileFormats',
  'dataImports',
  'exportFileFormats',
  'dataExports',
  'templates',
  'customEntityPropertyTypes'
];

export type GraphState = {
  groupByPeriod: aggregationFrequency;
};

export type ConfigTablesState = Map<ConfigType, ConfigState<any>>;

export type CurrentState = {
  listPage: ListPageState;
  filter: FilterType;
  graph: GraphState;
  id: string;
  entity: any | null;
  endpoint?: string;
};

type UtilStateType = {
  timeStamp: string;
  pageLoading: boolean;
  actionInProgress: boolean;
};

export type FilterType = {
  quickFilter: string;
  search: string;
  timeStamp: string;
  queryParams: queryParam;
};

export type ListPageState = {
  selectedIds: string[];
  allIds: string[];
  selectedRows: any[];
  updatedRows: any[];
  activatedRows: any[];
  deletedRows: any[];
  sort: SortType;
  rows: any[];
  overviewCounts: Record<string, number>;
  showSidePanel: boolean;
  showOnActivate: boolean;
  aggregateDetails: AggregatedDetailType[];
};

export type DispatchListPageState = Partial<ListPageState>;

export type ApplicationState = {
  utils: UtilStateType;
  current: Map<DomainType, CurrentState>;
  config: ConfigTablesState;
};

export type ApplicationDispatch = (newState: { type: string; update: Map<DomainType, CurrentState> }) => void;

const initialSortObject: SortType = {
  sortBy: [],
  sortDirection: {}
};

export const initialFilter = {
  quickFilter: 'all',
  search: '',
  timeStamp: '',
  queryParams: {}
};

export const listPageInitialState: ListPageState = {
  selectedIds: [],
  allIds: [],
  selectedRows: [],
  updatedRows: [],
  deletedRows: [],
  activatedRows: [],
  sort: initialSortObject,
  overviewCounts: {},
  rows: [],
  showSidePanel: false,
  showOnActivate: false,
  aggregateDetails: []
};

export const initialUtilsState: UtilStateType = {
  timeStamp: '',
  pageLoading: true,
  actionInProgress: false
};

export const graphInitialState: GraphState = {
  groupByPeriod: aggregationFrequency.none
};

const initialConfig: Map<ConfigType, ConfigState<any>> = new Map();

configTypes.forEach((type) => {
  initialConfig.set(type, undefined);
});

const initialState: ApplicationState = {
  utils: initialUtilsState,
  current: new Map<DomainType, CurrentState>(),
  config: initialConfig
};

export const initialCurrent: CurrentState = {
  listPage: clone(listPageInitialState),
  filter: clone(initialFilter),
  graph: clone(graphInitialState),
  id: '',
  entity: null
};

export type StateField = 'utils' | 'current';

const appReducer = (state: ApplicationState, action: ActionTypes): ApplicationState => {
  switch (action.type) {
    case 'refresh': {
      const utils = { ...state.utils, timeStamp: ((Date.now() / 1000) | 0).toString() };
      return { ...state, utils };
    }
    case 'reset': {
      const current: Map<DomainType, CurrentState> = state.current.set(action.domain, initialCurrent);
      return { ...state, current };
    }
    case 'set_action_progress': {
      return { ...state, utils: { ...state.utils, actionInProgress: action.inProgress } };
    }
    case 'update_config': {
      return { ...state, config: action.newConfig };
    }
    default:
      return { ...state, ...action.update };
  }
};

const useValue = (): readonly [ApplicationState, Dispatch<ActionTypes>] => {
  return useReducer(appReducer, initialState);
};

export const {
  Provider: AppProvider,
  useUpdate: useDispatch,
  useTracked,
  useTrackedState
} = createContainer<ApplicationState, Dispatch<ActionTypes>, {}>(useValue);
