import { observer } from 'mobx-react';
import React from 'react';

import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';

import { Disabled } from '../../../design-system/Components';
import { SettingDescription } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import css from './portal-cards.module.scss';
import RangeInputs from './RangeInputs';

const AdvanceAmountCardBody = () => {
  const { integrationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { portalSettingsForm } = integrationStore.customerPortalStore;

  if (!portalSettingsForm) return null;

  const allowCustomerToChangeAdvanceAmount = portalSettingsForm._get('allowCustomerToChangeAdvanceAmount');

  return (
    <>
      <SettingDescription
        title={getTranslation('portal_config.allow_changing_adv_amount')}
        description={getTranslation('portal_config.allow_changing_adv_amount_desc')}
        setting={
          <ZFToggle
            onChange={(val) => portalSettingsForm._set('allowCustomerToChangeAdvanceAmount', val)}
            checked={allowCustomerToChangeAdvanceAmount}
          />
        }
      />
      <Disabled disabled={!allowCustomerToChangeAdvanceAmount}>
        <SettingDescription
          title={getTranslation('portal_config.range')}
          description={getTranslation('portal_config.range_extra_info')}
          setting={
            <div className={css['adjustable-range-inputs']}>
              <RangeInputs />
            </div>
          }
          indentLvl={1}
        />
      </Disabled>
    </>
  );
};

export default observer(AdvanceAmountCardBody);
