import React from 'react';

import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import AssignAllReadyToTransactionDialog from '../dialogs/assign-to-transaction-dialog';
import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';

type Props = {
  type: outgoingBankingTransactionType;
  refresh: () => void;
};

export default function AddAllSpecificListPageButton(props: Props) {
  const { type } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="invoice.add_to_transaction"
      icon="assign"
      dialogTitle={i18n.getTranslation(
        `actions.invoice.add_all_ready_${
          type === outgoingBankingTransactionType.paymentrequests ? 'request' : 'refund'
        }`
      )}
      dialogContent={<AssignAllReadyToTransactionDialog {...props} validationRef={validationRef} ref={clickRef} />}
      validationRef={validationRef}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('general.add')}
    />
  );
}
