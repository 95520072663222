import React from 'react';
import { BarChart, Legend, ResponsiveContainer } from 'recharts';

import css from './chart-style.module.scss';
import CustomLegend from './CustomLegend';
import { IntervalType, SharedChartProps } from './types';
import { ZFChartLayout } from './ZFChartLayout';

type Props = SharedChartProps & {
  bars: JSX.Element[];
  height?: string;
  interval?: IntervalType;
  locale: string;
};

const ZFBarChart = React.memo((props: Props) => {
  const { chartData, height = '29vh', subject, interval, bars, XdataKey, locale } = props;

  const layout = ZFChartLayout(subject, XdataKey, locale, interval);

  return (
    <div style={{ height: height }}>
      <ResponsiveContainer className={css['chart']}>
        <BarChart data={chartData} margin={{ top: 40, bottom: 10 }}>
          {layout}
          {bars}
          <Legend content={<CustomLegend locale={locale} />} wrapperStyle={{ top: 0, left: 40 }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
});

export default ZFBarChart;
