import { useState } from 'react';

import { MeteringIssue } from '@zf/api-types/metering/metering-issues';

import { ListPage, ListPageFilter, ListPageStore } from './types';

export function useListPageProvider(store: ListPageStore) {
  const [listPageStore, setListPageStore] = useState(store);

  const { listPage, filter } = listPageStore;

  const setFilter = (newFilter: Partial<ListPageFilter>) =>
    setListPageStore({
      ...listPageStore,
      filter: { ...filter, ...newFilter },
      // Close side panel when changing filters
      listPage: {
        ...listPage,
        selectedIds: [],
        selectedRows: [],
        showSidePanel: false
      }
    });

  const setListPage = (newListPage: Partial<ListPage>) => {
    setListPageStore({ ...listPageStore, listPage: { ...listPage, ...newListPage } });
  };

  const updateRows = (updatedRowEntities: MeteringIssue[]) => {
    setListPage({ ...listPage, updatedRows: updatedRowEntities });
  }
    

  const getSidePanelData = (): MeteringIssue[] => {
    // Multi
    if (listPage.selectedRows.length > 1) {
      return listPage.selectedRows.map((r) => r.__entity);
    }

    // Single
    if (listPage.selectedRows.length === 1) {
      return [listPage.selectedRows[0].__entity];
    }

    return [];
  };

  const goToPreviousRow = (currentRowId: string) => {
    const currentlySelectedRowIndex = listPage.allIds.findIndex((id) => id === currentRowId);
    let nextSelectedRowIndex = listPage.allIds.length - 1;

    if (currentlySelectedRowIndex !== 0) {
      nextSelectedRowIndex = currentlySelectedRowIndex - 1;
    }

    setListPage({
      ...listPage,
      selectedIds: [listPage.allIds[nextSelectedRowIndex]],
      selectedRows: [listPage.rows[nextSelectedRowIndex]]
    });
  };

  const goToNextRow = (currentRowId: string) => {
    const currentlySelectedRowIndex = listPage.allIds.findIndex((id) => id === currentRowId);
    let nextSelectedRowIndex = 0;

    if (currentlySelectedRowIndex !== listPage.allIds.length - 1) {
      nextSelectedRowIndex = currentlySelectedRowIndex + 1;
    }

    setListPage({
      ...listPage,
      selectedIds: [listPage.allIds[nextSelectedRowIndex]],
      selectedRows: [listPage.rows[nextSelectedRowIndex]]
    });
  };

  const closeSidePanel = () =>
    setListPage({
      ...listPage,
      selectedIds: [],
      selectedRows: [],
      showSidePanel: false
    });

  return {
    store: { filter, listPage },
    getSidePanelData,
    setListPageStore,
    setFilter,
    setListPage,
    updateRows,
    goToPreviousRow,
    goToNextRow,
    closeSidePanel
  };
}
