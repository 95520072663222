import moment from 'moment';
import React from 'react';

import { CardFooter } from '@zf/stella-react/src/atoms/Card';

import DatePicker from '../../components/Lang/DatePicker';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { useStore } from '../../hooks/useStore';
import { CreateCustomEntityProperty, ValidatorType } from './CustomEntityPropertiesCard';
import NewValueInput from './NewValueInput';
import css from './style.module.scss';

type Props = {
  values: ValidatorType;
  setValue: (value: Partial<ValidatorType>, initialApiSet?: boolean, backup?: boolean) => void;
  handleSave: () => void;
};

export default function Footer(props: Props) {
  const { values, setValue, handleSave } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const dropdownValues = values.customEntityPropertyTypes.map((type) => {
    return { value: type, text: type.name };
  });

  const setNewCustomProperty = (value: Partial<CreateCustomEntityProperty>) => {
    setValue({ newCustomEntityProperty: { ...values.newCustomEntityProperty, ...value } });
  };

  return (
    <CardFooter className={css['card-footer']}>
      <SimpleDropdown
        id="attributeType"
        values={dropdownValues}
        selectedValues={[values.newCustomEntityProperty.customEntityPropertyType]}
        placeholder={getTranslation('general.type')}
        onChange={(val) =>
          setNewCustomProperty({ customEntityPropertyTypeId: val[0]?.id || '', customEntityPropertyType: val[0] })
        }
        error={!values.newCustomEntityProperty.customEntityPropertyTypeId}
      />
      <DatePicker
        id="startDateTime-picker"
        placeholder={getTranslation('general.start')}
        value={moment(values.newCustomEntityProperty.startDateTime)}
        onChange={(startDateTime) => setNewCustomProperty({ startDateTime })}
        clearText={getTranslation('datepicker.clear')}
      />
      <DatePicker
        id="endDateTime-picker"
        placeholder={getTranslation('general.end')}
        value={moment(values.newCustomEntityProperty.endDateTime)}
        onChange={(endDateTime) => setNewCustomProperty({ endDateTime })}
        clearText={getTranslation('datepicker.clear')}
      />
      <NewValueInput
        newCustomEntityProperty={values.newCustomEntityProperty}
        handleSave={handleSave}
        setNewCustomProperty={setNewCustomProperty}
      />
    </CardFooter>
  );
}
