import { transactionReferenceType, transactionType } from '@zf/api-types/enums';
import {
  CollectionStepReferenceParametersType,
  PaymentReferenceParametersType,
  TransActionsForCustomerType,
  TransactionType
} from '@zf/api-types/transactions';
import { formatDate } from '@zf/utils/src/date';
import React from 'react';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { getMainDetail, getOpenAmountColor } from '../../../utils/transaction';
import CollectionStepReferenceParameters from '../parameters/CollectionStepReferenceParameters';
import InvoiceReferenceParameters from '../parameters/InvoiceReferenceParameters';
import PaymentReferenceParameters from '../parameters/PaymentReferenceParameters';
import { formatMoney, roundNumber } from '@zf/utils/src/number';
import { createTooltipContent } from '@zf/utils/src/tooltip';
import { Icon } from '../../../components/Icon';
import DynamicVirtualTable from '../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import css from '../cash-position-body.module.scss';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { DANGER } from '../../../constants/color';
import { TransactionTypeColumn } from 'features/customer/detail-page/transactions/Tab/Components';
import { useAppContext } from 'app-context';

type Props = {
  transactions?: TransActionsForCustomerType;
  ShowMoreBtn: JSX.Element;
};

type LocalTransactionRowType = {
  type: JSX.Element;
  reference: JSX.Element;
  date: JSX.Element;
  amount: JSX.Element;
  awaitingBankConfirmation: React.ReactNode;
  __id: string;
};

const CashPosition = (props: Props) => {
  const { transactions, ShowMoreBtn } = props;
  const { applicationStore } = useStore();
  const { rootUrl, culture, getTranslation } = applicationStore;
  const { enumReducer } = useAppContext();

  if (!transactions) return null;
  const areClosedTransactions = transactions.latestClosedTransactions.length !== 0;
  const areOpenTransactions = transactions.latestOpenTransactions.length !== 0;

  const generateInfo = (transaction: TransactionType) => {
    if (transaction.referenceDetails) {
      switch (transaction.referenceDetails.transactionReferenceType) {
        case transactionReferenceType.invoice:
          return <InvoiceReferenceParameters referenceDetails={transaction.referenceDetails} />;

        case transactionReferenceType.payment: {
          const parameters = transaction.referenceDetails.parameters as PaymentReferenceParametersType;
          return <PaymentReferenceParameters params={parameters} />;
        }

        case transactionReferenceType.collectionstep: {
          const parameters = transaction.referenceDetails.parameters as CollectionStepReferenceParametersType;
          return <CollectionStepReferenceParameters parameters={parameters} />;
        }
      }
    }
  };

  const getTableCols = (openTransactions?: boolean) => {
    return [
      {
        width: 30,
        dataKey: 'awaitingBankConfirmation'
      },
      {
        width: 120,
        label: getTranslation('general.type'),
        dataKey: 'type'
      },
      {
        width: 100,
        label: getTranslation(openTransactions ? 'cards.cash_position.open' : 'general.amount_valuta'),
        dataKey: 'amount'
      },
      {
        width: 120,
        label: getTranslation('general.date'),
        dataKey: 'date'
      },
      {
        width: 100,
        label: getTranslation('cards.cash_position.reference'),
        dataKey: 'reference'
      }
    ];
  };

  const transactionToRow = (transaction: TransactionType, openTransaction?: boolean): LocalTransactionRowType => {
    if (
      transaction.transactionAmount > 0 &&
      transaction.referenceDetails?.transactionReferenceType === transactionReferenceType.payment
    ) {
    } else if (transaction.transactionType === transactionType.invoice) {
    } else {
    }

    const { url, title } = TransactionTypeColumn({ transaction, enumReducer, getTranslation });

    return {
      type: (
        <div className={css['type']}>
          {generateInfo(transaction)}
          {url && (
            <Link id={`transactionReferenceType-${transaction.id}`} url={`${rootUrl}/${url}`}>
              {title}
            </Link>
          )}
        </div>
      ),
      reference: <Paragraph id={transaction.id}>{getMainDetail(transaction.referenceDetails)}</Paragraph>,
      date: <Paragraph>{formatDate(transaction.transactionDateTime)}</Paragraph>,
      amount: (
        <Paragraph color={getOpenAmountColor(transaction)}>
          {formatMoney(openTransaction ? transaction.transactionOpenAmount : transaction.transactionAmount, culture)}
        </Paragraph>
      ),
      __id: 'x',
      awaitingBankConfirmation: transaction.awaitingBankConfirmation && (
        <div
          data-tip={createTooltipContent(
            <>{getTranslation('incoming_mutations.incoming_matching_bankConfirmation')}</>
          )}
          data-for="open-transactions-table-tip"
        >
          <Icon type="exclamation" color />
        </div>
      )
    };
  };

  let openTransactionRows: LocalTransactionRowType[] = [];
  if (areOpenTransactions) {
    openTransactionRows = transactions.latestOpenTransactions.map((t) => transactionToRow(t, true));
  }

  let closedTransactionRows: LocalTransactionRowType[] = [];
  if (areClosedTransactions) {
    closedTransactionRows = transactions.latestClosedTransactions.map((t) => transactionToRow(t));
  }

  const style: React.CSSProperties = {};

  if (transactions.cashPosition !== 0) {
    style.color = DANGER;
  }

  let infoText = '';

  if (transactions.cashPosition > 0 || transactions.cashPosition < 0) {
    infoText = getTranslation(`cards.cash_position.${transactions.cashPosition > 0 ? 'cust_debt' : 'org_debt'}`);
  }

  return (
    <>
      <div className={css['cash-position']}>
        <div className={css['icon-amount']} style={style}>
          <Icon type="money-bag" />
          <Paragraph style={style}>{formatMoney(roundNumber(transactions.cashPosition), culture)}</Paragraph>
        </div>
        {infoText && <Paragraph>{infoText}</Paragraph>}
      </div>
      <div className={css['table-labels']}>
        <Label>{getTranslation('cards.cash_position.open_transactions')}</Label>
        {ShowMoreBtn}
        <Label>{getTranslation('cards.cash_position.closed_transactions')}</Label>
      </div>
      <div className={css['transactions']}>
        {openTransactionRows.length > 0 ? (
          <div className={css['table']}>
            <DynamicVirtualTable
              id="open-transactions-table"
              tooltipId="open-transactions-table-tip"
              rowHeight={50}
              rows={openTransactionRows}
              columns={getTableCols(true)}
              noSelect
            />
          </div>
        ) : (
          <Paragraph className={css['no-transactions-left']}>
            {getTranslation('cards.cash_position.no_open_transactions')}
          </Paragraph>
        )}

        <div className={css['divider']} />

        {closedTransactionRows.length > 0 ? (
          <div className={css['table']}>
            <DynamicVirtualTable
              id="closed-transactions-table"
              tooltipId="closed-transactions-table-tip"
              rowHeight={50}
              rows={closedTransactionRows}
              columns={getTableCols()}
              noSelect
            />
          </div>
        ) : (
          <Paragraph className={css['no-transactions-right']}>
            {getTranslation('cards.cash_position.no_closed_transactions')}
          </Paragraph>
        )}
      </div>
    </>
  );
};

export default CashPosition;
