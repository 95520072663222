import { ListPageStore } from '../../../../../components/listPageV2/types';

export const createListPageStore = (
  queryParams: Record<string, any>,
  defaultQuickFilter: string | null | undefined,
  overviewCounts: Record<string, number>,
  state: {
    refreshKey: string | null;
  }
): ListPageStore => {
  return {
    filter: {
      queryParams,
      search: undefined,
      quickFilter: defaultQuickFilter
    },
    listPage: {
      sort: {
        sortDirection: {},
        sortBy: []
      },
      overviewCounts,
      selectedIds: [],
      selectedRows: [],
      activatedRows: [],
      deletedRows: [],
      updatedRows: [],
      allIds: [],
      rows: [],
      showSidePanel: false,
      showOnActivate: false,
      aggregateDetails: [],
      state: { refreshKey: state.refreshKey }
    }
  };
};
