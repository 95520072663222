import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { getAdvanceAmountFromContract } from 'utils/contract';

import { contractStatus } from '@zf/api-types/enums';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDateWMonth, isMinDate } from '@zf/utils/src/date';

import { useStore } from '../../../../../../hooks/useStore';
import AdvanceAmountCardItem from './AdvanceAmountCardItem';
import css from './billing-details-card.module.scss';

const AdvanceInfo = () => {
  const { contractStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const { selectedContract, billingInsights, billingRelation_, getChangedBy } = contractStore;

  const contract = selectedContract.contract;

  let completeness = 0;

  if (billingInsights) {
    completeness = (billingInsights.advancesCreated / billingInsights.advancesNeeded) * 100;
  }

  return (
    <>
      <CardItem width="12">
        <HorizontalDivider />
      </CardItem>

      <AdvanceAmountCardItem
        advanceAmount={getAdvanceAmountFromContract(billingRelation_, contract)}
        contractStatus_={contract.currentContractStatus}
        contract={contract}
      />

      {billingRelation_ && (
        <>
          {billingRelation_.advanceDetails.changedBy && !isMinDate(billingRelation_.advanceDetails.changedDate) && (
            <>
              <CardItem width="6">
                <Label>{getTranslation('contracts.changed_on')}</Label>
              </CardItem>
              <CardItem width="6">
                <Paragraph textAlign="right">
                  {formatDateWMonth(billingRelation_.advanceDetails.changedDate)}
                  <span className={css['contracts_changed_on_type']}>-{getChangedBy(billingRelation_)}</span>
                </Paragraph>
              </CardItem>
            </>
          )}

          {billingInsights && (
            <>
              <CardItem width="6">
                <Label>{getTranslation('analytics.advances')}</Label>
              </CardItem>
              <CardItem width="6">
                {billingInsights.advancesNeeded !== 0 ? (
                  <Paragraph
                    textAlign="right"
                    className={classNames(css['completeness'], {
                      [css['completeness-25']]: completeness < 50,
                      [css['completeness-50']]: completeness >= 50,
                      [css['completeness-100']]: completeness === 100
                    })}
                  >
                    {`${billingInsights.advancesCreated}/${billingInsights.advancesNeeded}`}
                  </Paragraph>
                ) : (
                  <Paragraph textAlign="right">{getTranslation('general.na')}</Paragraph>
                )}
              </CardItem>

              {contract.currentContractStatus !== contractStatus.terminated && (
                <>
                  <CardItem width="6">
                    <Label>{getTranslation('contracts.next_advance')}</Label>
                  </CardItem>
                  <CardItem width="6">
                    <Paragraph textAlign="right">
                      {formatDateWMonth(billingInsights.nextAdvanceStartDateTime)}
                    </Paragraph>
                  </CardItem>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default observer(AdvanceInfo);
