import { MeterRowType, MeterType } from '@zf/api-types/master-data/meter';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { InfoBanner } from 'design-system/ComponentSets';
import { DialogClickRef, ValidationRef } from 'design-system/ComponentSets/Dialog/Dialog';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RadioInputWithHookForm, MultiLineInputWithHooksForm, FormField, SummaryField } from './FormFields';
import { MeteringIssueError, MutingRuleTimePeriod } from '@zf/api-types/enums';
import { notify } from 'events/notification-events';
import { useIssuesContext } from 'features/devices/issues/Context/Context';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { formatDate } from '@zf/utils/src/date';
import moment from 'moment';

type Props = {
  selectedRows?: Array<MeterRowType>;
  meters?: MeterType[];
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  onComplete?: () => void;
  withIgnore?: boolean;
  issues?: MeteringIssue[];
  ignoreMessage?: string;
};

export type MuteRule = {
  errorType: MeteringIssueError;
  muteFor: MutingRuleTimePeriod;
  reason: string;
};

export const ExitingMutingRuleAlert = ({
  errorType,
  meterIds
}: {
  errorType: MeteringIssueError;
  meterIds: string[];
}) => {
  const {
    applicationStore: { getTranslation },
    meterStore: {
      mutingRuleService: { getAllRelated }
    }
  } = useStore();
  const [enable, setEnable] = useState<boolean>(false);

  useEffect(() => {
    getAllRelated(errorType, meterIds).then((data) => setEnable(data.length > 0));
  }, [meterIds]);

  return enable ? (
    <InfoBanner info={<Interweave content={getTranslation('meter_issues.mute_rule.alert')} />} color={'orange'} />
  ) : null;
};

export const CreateMutingRuleDialogContent = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { meters, withIgnore, ignoreMessage, issues, onComplete } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { meteringIssueService, mutingRuleService } = meterStore;
  const { ignoreMeterIssues, ignoreMeterIssue } = meteringIssueService;
  const { createMutingRule } = mutingRuleService;
  const ctx = useIssuesContext();
  const { updateRows } = ctx;

  const values: MuteRule = {
    errorType: MeteringIssueError.consumptionnegative,
    muteFor: MutingRuleTimePeriod.week,
    reason: ''
  };

  const { control, watch } = useForm<MuteRule>({
    defaultValues: values
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (meters) {
          await createMutingRule(
            watch('errorType'),
            watch('reason'),
            watch('muteFor'),
            meters.flatMap(({ id }) => id)
          );

          if (withIgnore && issues) {
            if (issues.length === 1) {
              await ignoreMeterIssue(issues[0]?.id, ignoreMessage ? ignoreMessage : '').then((res) => {
                updateRows([res]);
              });
            } else {
              await ignoreMeterIssues(
                issues?.flatMap(({ id }) => id),
                ignoreMessage ? ignoreMessage : ''
              ).then((res) => {
                updateRows(res);
              });
            }
          }
        }

        onComplete && onComplete();

        notify.success({
          content: getTranslation(
            withIgnore ? 'meter_issues.issues_ignored_and_created_rule' : 'meter_issues.rule_created'
          )
        });
      } catch (error) {
        notify.error({
          content: getTranslation(
            withIgnore ? 'meter_issues.issues_ignored_and_created_rule_fail' : 'meter_issues.rule_created_fail'
          ),
          error
        });
      }
    }
  }));

  const getMuteUntilDate = (muteFor: string) => {
    const today = new Date();
    switch (muteFor) {
      case MutingRuleTimePeriod.day:
        today.setDate(today.getDate() + 1);
        return formatDate(moment(today));
      case MutingRuleTimePeriod.week:
        today.setDate(today.getDate() + 7);
        return formatDate(moment(today));
      case MutingRuleTimePeriod.month:
        today.setMonth(today.getMonth() + 1);
        return formatDate(moment(today));
      default:
        return formatDate(moment(today));
    }
  };

  return (
    <FlexElements flexDirection="column" gap="16">
      <FormField label="meter_issues.choose_an_issue_type">
        <RadioInputWithHookForm
          control={control}
          value={MeteringIssueError.consumptionnegative}
          name="errorType"
          label={'meter_issues.negative_consumption'}
        />
      </FormField>

      <ExitingMutingRuleAlert
        errorType={MeteringIssueError.consumptionnegative}
        meterIds={meters?.map(({ id }) => id) || []}
      />

      <FormField label="meter_issues.mute_for">
        {Object.values(MutingRuleTimePeriod).map((muteFor) => (
          <RadioInputWithHookForm
            control={control}
            value={muteFor}
            name="muteFor"
            key={muteFor}
            label={`meter_issues.mute_for.one_${muteFor}`}
          />
        ))}
      </FormField>

      <FormField label="meter_issues.muted_reason" optionalLabel="meter_issues.muted_reason_optional">
        <MultiLineInputWithHooksForm control={control} name="reason" />
      </FormField>

      <SummaryField
        summary={'meter_issues.mute.summary'}
        params={{
          issueType: getEnumTranslation('meteringIssueError', watch('errorType')).toLowerCase(),
          until: getMuteUntilDate(watch('muteFor')),
          ending:
            meters?.length === 1
              ? `<b>${getTranslation('meter.meter').toLowerCase()} ${meters[0].serialNumber}</b>`
              : `<b>${meters?.length} ${getTranslation('meter.meters').toLowerCase()}</b>`
        }}
      />
    </FlexElements>
  );
});
