import clone from 'clone';
import moment, { Moment } from 'moment';

import { BillingRelationType } from '@zf/api-types/billing-relation';
import { invoiceFrequency, utilityType } from '@zf/api-types/enums';
import { ContractServiceLocation, ContractType } from '@zf/api-types/master-data/contract';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { onlyUnique } from '@zf/utils/src/array';
import { betweenDates, MIN_DATE } from '@zf/utils/src/date';

const invoiceFrequencyMonths: Record<invoiceFrequency, number> = {
  monthly: 1,
  quarterly: 3,
  halfyearly: 6,
  yearly: 12
};

const getMonths = (invoiceFrequency: invoiceFrequency | null) => {
  if (!invoiceFrequency) return invoiceFrequencyMonths.yearly;

  return invoiceFrequencyMonths[invoiceFrequency];
};

export const getNextInvoiceDate = (invoiceFrequency: invoiceFrequency, refDate: Moment | null | undefined) => {
  refDate = clone(refDate);

  if (refDate) {
    return refDate.add(invoiceFrequencyMonths[invoiceFrequency], 'months');
  }

  return moment(MIN_DATE);
};

export const calcNextInvoiceDate = (
  contractStartDate: Moment | null | undefined,
  invoiceMonth: number | null,
  invoiceDay: number | null,
  invoiceFrequency_: invoiceFrequency | null
) => {
  if (!contractStartDate || invoiceFrequency_ === null) {
    return moment(MIN_DATE);
  }

  // We have a date to work with, create a clone to prevent reference issues
  const resDate = clone(contractStartDate);

  // Add invoiceFrequency
  resDate.add(getMonths(invoiceFrequency_), 'months');

  // If invoiceMonth is defined
  if (invoiceMonth !== null && invoiceMonth !== 0) {
    resDate.set('month', invoiceMonth - 1); // zero based
  }

  // If invoiceDay is defined
  if (invoiceDay !== null && invoiceDay !== 0) {
    resDate.set('date', invoiceDay);
  }

  return resDate;
};

export const getMoveInProduct = (
  billingConfig?: PropertyGroupBillingConfigurationType | null,
  location?: ServiceLocationType | null
) => {
  if (billingConfig) {
    if (location && location.product) {
      return location.product?.productId || '';
    }
    if (!billingConfig.costAllocationEnabled) {
      return billingConfig.productId || '';
    }
  } else {
    return location?.product?.productId || '';
  }

  return '';
};

export const getServicesForContractServiceLocation = (serviceLocation: ContractServiceLocation) => {
  return (
    serviceLocation.services?.reduce((acc: utilityType[], s) => {
      if (s.utilityType) {
        acc.push(s.utilityType);
      }

      return acc;
    }, []) || []
  );
};

export const getContractServiceLocationSupplyPeriod = (serviceLocation: ContractServiceLocation) => {
  const allDates =
    serviceLocation.services?.flatMap((s) => {
      return [moment(s.startDateTime), moment(s.endDateTime)];
    }) || [];

  return { periodStart: moment.min(allDates), periodEnd: moment.max(allDates) };
};

export const getActiveServicesForContract = (contract: ContractType) => {
  return contract.serviceLocations
    .flatMap((l) => l.services?.map((s) => s) || [])
    .filter((s) => betweenDates(s.startDateTime, s.endDateTime, moment()));
};

export const getUniqueServicesForContract = (contract: ContractType) => {
  const allServices = contract.serviceLocations.flatMap((l) => getServicesForContractServiceLocation(l));

  return allServices.filter(onlyUnique);
};

export const getAdvanceAmountFromContract = (billingRelation: BillingRelationType | null, contract: ContractType) => {
  return billingRelation
    ? billingRelation.advanceDetails.advanceAmount
    : contract.billingDetails.contractualAdvanceAmount;
};
