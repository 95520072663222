import clone from 'clone';
import moment from 'moment';
import React from 'react';

import { CollectionCaseType } from '@zf/api-types/collection-case';
import { collectionStepStatus } from '@zf/api-types/enums';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate, isMaxDate, isMinDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../../app-context';
import { sortSteps } from '../../../../../utils/collection';
import css from './collection-case-timeline.module.scss';
import TimelineItem from './TimelineItem';

type Props = {
  collCase: CollectionCaseType;
  refresh: () => void;
};

export default function CollectionCaseTimeline(props: Props) {
  const { collCase, refresh } = props;
  const { i18n, enumReducer } = useAppContext();

  const steps = sortSteps(collCase.steps);
  const stepsClone = clone(steps);

  return (
    <Card id="collection-case-timeline-card" width="2">
      <CardHeader>{i18n.getTranslation('collection_case.timeline')}</CardHeader>
      <CardBody>
        <div className={css['time-line-heading']}>
          <Paragraph bold>
            {i18n.getTranslation('collection_case.start_case', { date: formatDate(collCase.createdDateTime) })}
          </Paragraph>
          <HorizontalDivider className={css['h-line']} thickness="0.2rem" />
        </div>
        <div className={css['timeline-wrapper']}>
          {steps.map((s, index) => {
            const previousStep = stepsClone[index - 1];

            let dateText = '';

            // If we have an executed date and it is closed. It might have different status when it gets resend.
            if (!isMinDate(s.executedAt) && s.status === collectionStepStatus.closed) {
              dateText = i18n.getTranslation('collection_case.executed_at', {
                date: formatDate(s.executedAt)
              });
            }
            // In future
            else if (!isMaxDate(s.executeAt)) {
              if (
                s.status === collectionStepStatus.generatingdocuments ||
                s.status === collectionStepStatus.inprogress
              ) {
                dateText = enumReducer.getTranslation('collectionStepStatus', s.status);
              } else {
                dateText = i18n.getTranslation(
                  `collection_case.${
                    s.status === collectionStepStatus.waitingformanualsend
                      ? 'action_req_since'
                      : s.status === collectionStepStatus.printing
                      ? 'printing'
                      : 'will_be_executed'
                  }`,
                  {
                    date: formatDate(s.executeAt)
                  }
                );
              }
            }
            // If no future execution date, calculate this date based on the previous step
            else {
              if (previousStep) {
                const newExecuteDate = moment(previousStep.executeAt).add(s.triggerDays, 'days');
                stepsClone[index].executeAt = newExecuteDate.toISOString();

                if (s.status === collectionStepStatus.printing) {
                  dateText = i18n.getTranslation('collection_case.printing');
                } else {
                  dateText = i18n.getTranslation('collection_case.will_be_executed', {
                    date: formatDate(newExecuteDate)
                  });
                }
              }
            }

            return (
              <TimelineItem
                key={index}
                stepNumber={index + 1}
                dateText={dateText}
                collCase={collCase}
                step={s}
                refresh={refresh}
              />
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
}
