import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../hooks/useStore';
import CommunicationPreferencesCardBody from './CommunicationPreferencesCardBody';
import AsyncCardSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncCardSpinner';
import css from './communication-preferences-card.module.scss';

const CommunicationPreferencesCard = observer(() => {
  const { applicationStore, communicationStore } = useStore();
  const { communicationParametersForm_ } = communicationStore.settingsStore;
  const { getTranslation } = applicationStore;

  const title = getTranslation('communication.communication_preferences');

  return (
    <AsyncCardSpinner title={title} variables={[communicationParametersForm_]}>
      <Card width="2" id="communication-preferences-card">
        <CardHeader>{title}</CardHeader>
        <CardBody className={css['card-body']}>
          <CommunicationPreferencesCardBody />
        </CardBody>
      </Card>
    </AsyncCardSpinner>
  );
});

export default CommunicationPreferencesCard;
