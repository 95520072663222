import React from 'react';

import {
  AddManualEntryToOutgoingBankingTransactionRequestType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { OutgoingMutationType } from '@zf/api-types/billing/outgoing-mutations';
import { outgoingBankingTransactionType } from '@zf/api-types/enums';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { ValidationRef, DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../app-context';
import CustomerAutoFill from '../../components/Autofills/CustomerAutoFill';
import MoneyInput from '../../components/input/MoneyInput';
import MultiLineInput from '../../components/input/MultiLineInput';
import { notify } from '../../events/notification-events';
import BankAccountRefundSection from './BankAccountRefundSection';
import BankAccountRequestSection from './BankAccountRequestSection';
import { addManualEntry, editManualEntry } from './outgoing-mutations';

type Props = {
  outgoingBankingTransaction: OutgoingBankingTransactionType;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  entry?: OutgoingMutationType; // edit
  refresh: () => void;
};

type State = {
  amount: number | null;
  paymentReference: string;
  customerId: string;
  iban: string;
};

const ManualEntry = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { entry, validationRef, outgoingBankingTransaction, refresh } = props;
  const { i18n, tenantReducer } = useAppContext();

  const initialState = entry
    ? {
        amount: entry.amount,
        paymentReference: entry.description,
        customerId: entry.referenceDetails.referenceId,
        iban: entry.customerBankAccount.iban
      }
    : {
        amount: null,
        paymentReference: '',
        customerId: '',
        iban: ''
      };

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = React.useReducer(stateReducer, initialState);

  const validate = () => {
    if (validationRef.current) {
      const commonCheck =
        typeof state.amount !== 'number' ||
        !state.paymentReference ||
        !state.customerId ||
        state.amount <= 0 ||
        !state.iban;
      validationRef.current.setIsError(commonCheck);
    }
  };

  const renderBankAccountSection = () => {
    if (outgoingBankingTransaction.type === outgoingBankingTransactionType.paymentrequests) {
      return (
        <BankAccountRequestSection
          customerId={state.customerId}
          iban={state.iban}
          setIban={(val) => setState({ iban: val[0] ? val[0] : '' })}
        />
      );
    } else {
      return (
        <BankAccountRefundSection
          customerId={state.customerId}
          iban={state.iban}
          setIban={(val) => setState({ iban: val[0] ? val[0] : '' })}
        />
      );
    }
  };

  React.useEffect(() => {
    validate();
  }, [JSON.stringify(state)]);

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (state.amount) {
          const apiFriendlyValues: AddManualEntryToOutgoingBankingTransactionRequestType = {
            amount: state.amount,
            paymentReference: state.paymentReference,
            iban: state.iban,
            customerId: state.customerId
          };

          entry
            ? await editManualEntry(entry.id, apiFriendlyValues, tenantReducer, i18n.lang)
            : await addManualEntry(outgoingBankingTransaction.id, apiFriendlyValues, tenantReducer, i18n.lang);

          refresh();

          notify.success({
            content: i18n.getTranslation(`actions.outgoing_mutation.${entry ? 'edit' : 'add'}_manual_entry_success`)
          });
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation(`actions.outgoing_mutation.${entry ? 'edit' : 'add'}_manual_entry_fail`),
          error: e
        });
      }
    }
  }));

  return (
    <>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation(`actions.outgoing_mutation.heading_customer_${outgoingBankingTransaction.type}`)}
      </Heading>
      <InputContainer>
        <CustomerAutoFill
          id="customer"
          onChange={(value) => setState({ customerId: value[0] ? value[0].id : '', iban: '' })}
          selectedValues={[state.customerId]}
          error={!state.customerId}
        />
      </InputContainer>

      {state.customerId && (
        <>
          <Heading headingType="h3" style="bold">
            {i18n.getTranslation('actions.outgoing_mutation.heading_iban')}
          </Heading>
          <InputContainer>{renderBankAccountSection()}</InputContainer>
        </>
      )}

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation(
          `actions.outgoing_mutation.${
            outgoingBankingTransaction.type === outgoingBankingTransactionType.paymentrequests
              ? 'heading_amount_payment'
              : 'heading_amount_refund'
          }`
        )}
      </Heading>
      <InputContainer>
        <MoneyInput
          id="amount"
          onChange={(val) => setState({ amount: val })}
          value={state.amount}
          placeholder={i18n.getTranslation('general.amount_valuta')}
          error={typeof state.amount !== 'number' || state.amount <= 0}
        />
      </InputContainer>

      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('actions.outgoing_mutation.heading_payment_ref')}
      </Heading>
      <InputContainer>
        <MultiLineInput
          id="payment_reference"
          onChange={(val) => setState({ paymentReference: val })}
          value={state.paymentReference}
          error={!state.paymentReference}
        />
      </InputContainer>
    </>
  );
});

export default ManualEntry;
