import { observer } from 'mobx-react';
import { Moment } from 'moment';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import { OutgoingBankingTransactionRequestType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionType, outgoingMutationType } from '@zf/api-types/enums';
import { TransactionType } from '@zf/api-types/transactions';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';
import { isMinDate, today } from '@zf/utils/src/date';

import BankAccountRefundSection from '../../../../../actions/outgoing-mutation/BankAccountRefundSection';
import BankAccountsDropdown from '../../../../../components/Dropdown/bankaccounts-dropdown/BankAccountsDropdown';
import MultiLineInput from '../../../../../components/input/MultiLineInput';
import DatePicker from '../../../../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import css from './transaction-card.module.scss';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatMoney } from '@zf/utils/src/number';
import { Paragraph } from 'design-system/Foundation';
import Interweave from 'interweave';
import IconParagraph from 'components/Icon/IconParagraph';
import { colors } from '@zf/utils/src/color';
import { useRefreshListPage } from '../Context/Context';

type Props = {
  transaction: TransactionType;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

type State = {
  refundDate: Moment | null;
  paymentReference: string;
  customerIban: string;
  companyBankAccountId: string;
};

const RefundDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { configStore, applicationStore, outgoingBankingTransactionsStore } = useStore();
  const { getTranslation, culture } = applicationStore;
  const { createOutgoingBankingTransaction } = outgoingBankingTransactionsStore.outgoingBankingTransactionsService;
  const { getPaymentParameters } = configStore.configService;
  const { setRefresh } = useRefreshListPage();

  const { transaction, validationRef } = props;

  const stateReducer = createStateReducer<State, Partial<State>>();

  const [state, setState] = useReducer(stateReducer, {
    refundDate: null,
    paymentReference: '',
    customerIban: '',
    companyBankAccountId: ''
  });

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(
        !state.paymentReference ||
          !state.customerIban ||
          !state.companyBankAccountId ||
          (!!state.refundDate && isMinDate(state.refundDate))
      );
    }
  };

  useEffect(() => {
    // Set refundDate based on config
    if (state.refundDate === null) {
      getPaymentParameters()
        .then((params) => {
          const refundDelay = params.refundPaymentDelayInDays;
          setState({ refundDate: today().add(refundDelay, 'days') });
        })
        .catch((error) => {
          notify.error({
            content: getTranslation('general.get_cfg_fail'),
            error
          });
        });
    }
  }, [state.refundDate]);

  useEffect(() => {
    validate();
  }, [state]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const apiFriendlyValues: OutgoingBankingTransactionRequestType = {
          outgoingBankingTransactionType: outgoingBankingTransactionType.paymentrefunds,
          collectionDate: state.refundDate?.toISOString() || today().toISOString(),
          entries: [
            {
              invoiceId: '',
              amount: Math.abs(transaction.transactionOpenAmount),
              paymentReference: state.paymentReference,
              iban: state.customerIban,
              customerId: transaction.debtor?.customerId || null,
              entryType: outgoingMutationType.customer,
              transactionId: transaction.id
            }
          ],
          companyBankAccountId: state.companyBankAccountId
        };

        await createOutgoingBankingTransaction(apiFriendlyValues);

        setRefresh(Date.now().toString());

        notify.success({
          content: getTranslation('actions.customer.refund_success', {
            customer: transaction.debtor?.displayName || '',
            amount: formatMoney(Math.abs(transaction.transactionOpenAmount), culture)
          })
        });
      } catch (e) {
        notify.error({
          content: getTranslation('actions.customer.refund_fail'),
          error: e
        });
      }
    }
  }));
  return (
    <div className={css['wrapper']}>
      <Paragraph className={css['refund-title']}>
        <Interweave
          content={`${getTranslation('payments.refund')} <b>${formatMoney(
            Math.abs(transaction.transactionOpenAmount),
            culture
          )}</b> ${getTranslation('general.to')} <b>${transaction.debtor?.displayName}</b>`}
        />
      </Paragraph>
      <FlexElements flexDirection="column">
        <InputContainer hasSections grid={true} className={css['input-container']}>
          <Label> {getTranslation('payments.refund_date_label')}</Label>
          <DatePicker
            id="refund-date"
            onChange={(val) => setState({ refundDate: val })}
            value={state.refundDate}
            error={!!state.refundDate && isMinDate(state.refundDate)}
          />
        </InputContainer>

        <InputContainer hasSections grid={true} className={css['input-container']}>
          <Label> {getTranslation('payments.refund_from_label')}</Label>
          <BankAccountsDropdown
            id="company-bank-account"
            onChange={(val) => setState({ companyBankAccountId: val[0] ? val[0].id : '' })}
            selectedValue={state.companyBankAccountId || ''}
            error={!state.companyBankAccountId}
            isInline
          />
        </InputContainer>

        {transaction.debtor && (
          <InputContainer hasSections grid={true} className={css['input-container']}>
            <Label> {getTranslation('payments.refund_to_label')}</Label>
            <BankAccountRefundSection
              customerId={transaction.debtor?.customerId}
              iban={state.customerIban}
              setIban={(val) => setState({ customerIban: val[0] || '' })}
              infoAlert={null}
              infoAlertV2={
                <IconParagraph iconType="alert-circle" color={colors['orange-600']}>
                  <Interweave
                    content={`<b>${transaction.debtor?.displayName}</b> ${getTranslation('customer.no_bank_accounts', {
                      customer: transaction.debtor?.displayName
                    })}`}
                  />
                </IconParagraph>
              }
            />
          </InputContainer>
        )}

        <InputContainer hasSections grid={false} className={css['input-container']}>
          <Label> {getTranslation('payments.reference')}</Label>
          <MultiLineInput
            id="payment-reference"
            onChange={(val) => setState({ paymentReference: val })}
            value={state.paymentReference}
            error={!state.paymentReference}
          />
        </InputContainer>
      </FlexElements>
    </div>
  );
});

export default observer(RefundDialog);
