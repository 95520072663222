import clone from 'clone';
import React from 'react';

import {
  CreateFormulaFunctionType,
  FunctionType,
  PropertyGroupBillingConfigurationType
} from '@zf/api-types/property-group-billing-configuration';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import { useAppContext } from '../../../../../../app-context/app-context';
import Button from '../../../../../../components/Button/Button';
import ConfigColumn from '../../../../../../components/Column/ConfigColumn';
import EmptyColumn from '../../../../../../components/Column/EmptyColumn';
import Toggle from '../../../../../../components/Slider/Toggle';
import { notify } from '../../../../../../events/notification-events';
import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import { METHODS, sendRequest } from '../../../../../../utils/request';
import FormulaBuilder from '../../../formula-builder/formula-builder';
import useBillingData from '../../hooks/use-billing-data';
import useBillingItemDetails from '../../hooks/useBillingItemDetails';
import BillingItems from './billing-items';
import css from './cost-calculator.module.scss';

type State = {
  formulaFunctions: CreateFormulaFunctionType[] | null;
  isDirty: boolean;
  isCustom: boolean;
};

const runtimeParameters = [
  'StartDateTime',
  'EndDateTime',
  'PropertyGroup',
  'InvoicePeriodId',
  'CalculationConfigurationName'
];

export default function CostCalculatorTab() {
  const { i18n, tenantReducer } = useAppContext();
  const {
    propertyGroupBillingConfiguration,
    selectedBillingItem,
    setSelectedBillingItem,
    setPropertyGroupBillingConfiguration
  } = useBillingData();

  const { itemName } = useBillingItemDetails(selectedBillingItem ? selectedBillingItem.billingItemId : '', true);

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    formulaFunctions: null,
    isDirty: false,
    isCustom: selectedBillingItem ? selectedBillingItem.deviatingTariffCalculation : true
  });

  const response = useSuspenseSingleAPI<FunctionType[]>({
    request: {
      endpoint: '/bill/PropertyGroupBillingConfiguration/formulas'
    }
  });

  React.useEffect(() => {
    if (selectedBillingItem && selectedBillingItem.tariffFormula) {
      const initialFormulaFunctions: CreateFormulaFunctionType[] = Object.keys(
        selectedBillingItem.tariffFormula.functions
      ).reduce((acc: CreateFormulaFunctionType[], key) => {
        if (selectedBillingItem.tariffFormula) {
          acc.push(selectedBillingItem.tariffFormula.functions[parseInt(key)]);
        }
        return acc;
      }, []);

      setValue({ formulaFunctions: initialFormulaFunctions });
    } else {
      setValue({ formulaFunctions: [] });
    }

    const isCustom = selectedBillingItem ? selectedBillingItem.deviatingTariffCalculation : true;
    setValue({ isCustom: isCustom });
  }, [selectedBillingItem]);

  if (!propertyGroupBillingConfiguration || !propertyGroupBillingConfiguration.propertyGroup) return null;

  if (!response.result || values.formulaFunctions === null) return null;

  const functions = response.result.data;

  const updateBillingConfig = async (apiFriendlyFunctions?: Record<number, CreateFormulaFunctionType>) => {
    const propertyBillingconfigClone = clone(propertyGroupBillingConfiguration);

    let indexToUpdate = -1;

    const groupToUpdate = propertyBillingconfigClone.calculationConfigurations?.find((group) => {
      return group.billingItems.some((b, index) => {
        const match = b.billingItemId === selectedBillingItem?.billingItemId;
        if (match) {
          indexToUpdate = index;
        }
        return match;
      });
    });

    if (groupToUpdate && indexToUpdate !== -1) {
      groupToUpdate.billingItems[indexToUpdate].tariffFormula = apiFriendlyFunctions
        ? { functions: apiFriendlyFunctions }
        : { functions: {} };
      groupToUpdate.billingItems[indexToUpdate].deviatingTariffCalculation = !!apiFriendlyFunctions;
    }

    return (
      await sendRequest<PropertyGroupBillingConfigurationType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroupBillingConfiguration.propertyGroup?.id}/costallocation`,
          data: propertyBillingconfigClone
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      })
    ).data;
  };

  const submitFormula = async () => {
    try {
      const apiFriendlyFunctions: Record<number, CreateFormulaFunctionType> = {};

      if (values.formulaFunctions) {
        values.formulaFunctions.forEach((ff, index) => {
          apiFriendlyFunctions[index] = ff;
        });
      }

      const newBillingConfig = await updateBillingConfig(apiFriendlyFunctions);

      setPropertyGroupBillingConfiguration(newBillingConfig);

      notify.success({
        content: i18n.getTranslation('property_groups.tabs.billing.update_formula_success')
      });
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.update_formula_fail'),
        error: e
      });
    }
  };

  const validate = async () => {
    try {
      const newConfig = (
        await sendRequest<PropertyGroupBillingConfigurationType>({
          request: {
            method: METHODS.POST,
            endpoint: `/bill/PropertyGroupBillingConfiguration/${propertyGroupBillingConfiguration.propertyGroup?.id}/costallocation/validate`
          },
          tenantReducer: tenantReducer,
          lang: i18n.lang
        })
      ).data;

      setPropertyGroupBillingConfiguration(newConfig);
      setValue({ isDirty: false });

      const errors: any[] = [];
      let hasError = false;

      newConfig.calculationConfigurations?.forEach((group) => {
        group.billingItems.forEach((item) => {
          if (item.tariffValidationResult && !item.tariffValidationResult.valid) {
            hasError = true;
            item.tariffValidationResult.generalValidationErrors.forEach((e) => {
              errors.push(e);
            });
          }
        });
      });

      if (hasError) {
        const err = {
          data: {
            errors: errors
          }
        };

        throw err;
      }

      notify.success({
        content: i18n.getTranslation('property_groups.tabs.billing.validate_formula_success')
      });
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.validate_formula_fail'),
        error: e
      });
    }
  };

  const validateDefault = async () => {
    try {
      const newConfig = await updateBillingConfig();
      setPropertyGroupBillingConfiguration(newConfig);
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.billing_config_update_fail'),
        error: e
      });
    }

    await validate();
  };

  return (
    <ColumnsContainer className={css['wrapper']} extraPadding={false}>
      {propertyGroupBillingConfiguration && (
        <>
          <BillingItems
            isDirty={values.isDirty}
            calculationConfigurations={propertyGroupBillingConfiguration.calculationConfigurations || []}
            setSelectedBillingItem={setSelectedBillingItem}
          />

          <div className={css['formula-builder-wrapper']}>
            <Toggle
              id="preview-mode-slider"
              className={css['formula-toggle']}
              optionLeft={i18n.getTranslation('property_groups.tabs.billing.custom_formula')}
              optionRight={i18n.getTranslation('general.default')}
              isChecked={values.isCustom}
              action={() => setValue({ isCustom: !values.isCustom })}
            />
            {values.isCustom ? (
              <FormulaBuilder
                actions={
                  selectedBillingItem ? (
                    <Button
                      id="property_group_billing_config.validate"
                      className={css['action-btn']}
                      onClick={async () => {
                        await submitFormula();
                        await validate();
                      }}
                      disabled={values.formulaFunctions.length === 0 || !values.isDirty}
                    >
                      {i18n.getTranslation('general.validate')}
                    </Button>
                  ) : undefined
                }
                itemName={itemName}
                formulaFunctions={values.formulaFunctions}
                functions={functions}
                runtimeParameters={runtimeParameters}
                hasSelection={!!selectedBillingItem}
                functionValidationErrors={selectedBillingItem?.tariffValidationResult?.functionValidationErrors || []}
                emptyStateMessage={i18n.getTranslation('property_groups.tabs.billing.formula_builder_empty_state')}
                setFormulaFunctions={(val) => {
                  setValue({ formulaFunctions: val, isDirty: true });
                }}
              />
            ) : (
              <ConfigColumn
                extraRight={
                  <Button
                    id="property_group_billing_config.validate"
                    className={css['action-btn']}
                    onClick={async () => await validateDefault()}
                  >
                    {i18n.getTranslation('general.validate')}
                  </Button>
                }
                borderRight={false}
              >
                <EmptyColumn
                  image="cost-allocation2"
                  title="Formula builder"
                  text={i18n.getTranslation('property_groups.tabs.billing.default_empty_state')}
                />
              </ConfigColumn>
            )}
          </div>
        </>
      )}
    </ColumnsContainer>
  );
}
