import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { ProductType } from '@zf/api-types/product';

import { useStore } from '../../hooks/useStore';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Props = {
  className?: string;
};

export type ProductAutofillProps = Props & CustomAutoFillProps<ProductType>;

const ProductAutoFill = (props: ProductAutofillProps) => {
  const { id, selectedValues, initialValue, query, queryField, placeholder, onChange, onFocus } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const processAutoFillItem = useCallback((p: ProductType) => {
    return { value: p, text: p.name };
  }, []);

  const autoFillTools = useAutoFill({
    endpoint: '/cfg/products',
    processRecord: processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, values } = autoFillTools;

  return (
    <SearchAutoFill
      {...props}
      id={id}
      values={values}
      selectedValues={selectedValues}
      placeholder={placeholder || getTranslation('product.product_search')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export default observer(ProductAutoFill);
