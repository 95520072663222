import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { BreadCrumb, Crumb, CrumbDivider } from '../../../components/BreadCrumb';
import { CrumbHeader } from '../../../components/Header';
import { useStore } from '../../../hooks/useStore';
import usePrepaymentDeviceDetail from './detail-page/hooks/useMeterDetail';
import PrepaymentDeviceTabContainer from './detail-page/PrepaymentDeviceTabContainer';

type Props = {
  navigate: (href: string) => void;
  urlParams: { [key: string]: string };
};

const PrepaymentDevice = (props: Props) => {
  const { navigate, urlParams } = props;
  const { meter, navigator, timeStamp, activeState, setMeter, refresh } = usePrepaymentDeviceDetail(
    urlParams.prepaymentDeviceID
  );
  const { applicationStore, prepaymentDeviceStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { resetStore } = prepaymentDeviceStore;

  useEffect(() => {
    // Reset detail page when leaving
    return () => resetStore();
  }, []);

  if (!meter) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="list" text={getTranslation('meter.meters')} url={`${rootUrl}/devices/meters`} />
            <CrumbDivider />
            <Crumb
              icon={!meter.parentMeterRelation ? 'meter' : 'meter-linked'}
              text={Object.keys(meter).length > 0 ? meter.serialNumber : ''}
            />
          </BreadCrumb>
        }
      />

      <PrepaymentDeviceTabContainer
        activeState={activeState}
        meter={meter}
        navigator={navigator}
        timeStamp={timeStamp}
        navigate={navigate}
        setMeter={setMeter}
        refresh={refresh}
      />
    </>
  );
};

export default observer(PrepaymentDevice);
