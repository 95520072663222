import classNames from 'classnames';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React from 'react';

import { navigate } from '@reach/router';
import { MeterRowType, MeterType } from '@zf/api-types/master-data/meter';
import useSessionStorage from '@zf/hooks/src/useSessionStorage';
import { ExclamationBadge } from '@zf/stella-react/src/atoms/Badge';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';
import { formatDate, isMinDate, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import CheckBox from '../../components/input/CheckBox';
import DatePicker from '../../components/Lang/DatePicker';
import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import { getCurrentMeterStatus } from '../../utils/meter';
import css from '../style.module.scss';

type Props = {
  meter?: MeterType;
  submeters?: MeterType[];
  selectedRows?: Array<MeterRowType>;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  refreshCounts?: () => void;
  onComplete?: (updatedRows: MeterType[]) => void;
  setMeter?: React.Dispatch<React.SetStateAction<MeterType | null>>;
  refresh?: () => void;
};

const UninstallDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { meter, submeters, selectedRows, validationRef, onComplete, refreshCounts, setMeter, refresh } = props;
  const [uninstallDateTime, setUninstallDateTime] = React.useState(moment(MIN_DATE));
  const [replaceMeter, setReplaceMeter] = React.useState(false);

  const { i18n } = useAppContext();

  const { applicationStore, meterStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;
  const { uninstallMeter, unlinkMeter } = meterStore.deviceService;

  const { setItem } = useSessionStorage();

  let listPageSingleMeter: MeterType | null = null;

  if (selectedRows) {
    if (selectedRows.length === 1 && selectedRows[0]) {
      listPageSingleMeter = selectedRows[0].__meterEntity;
    }
  }

  const isSingleMeterParent = submeters && submeters.length > 0;
  const isSingleMeterChild =
    (meter && meter.parentMeterRelation) || (listPageSingleMeter && listPageSingleMeter.parentMeterRelation);

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(isMinDate(uninstallDateTime));
    }
  };

  const formatSuccessDescription = (multipleMeters: boolean, date: Moment) => {
    const inFuture = moment().isBefore(uninstallDateTime);

    if (inFuture) {
      const formattedDate = formatDate(date);

      return (
        getTranslation(
          `actions.meter.${multipleMeters ? 'uninstall_meters_future_descr' : 'uninstall_meter_future_descr'}`
        ) + formattedDate
      );
    } else if (
      (submeters && submeters.length > 0 && replaceMeter) ||
      (meter && meter.parentMeterRelation && replaceMeter) ||
      (isSingleMeterChild && !!listPageSingleMeter)
    ) {
      return getTranslation('actions.meter.uninstall_linked');
    } else {
      return '';
    }
  };

  const unlinkMeters = async (uninstalledMeter: MeterType) => {
    // This is a parent meter
    if (submeters && submeters.length > 0) {
      await Promise.all(submeters.map((m) => unlinkMeter(m.id))).catch((e) => {
        notify.error({
          content: getTranslation('actions.meter.unlink_fails'),
          error: e
        });
      });

      if (uninstalledMeter && replaceMeter) {
        setItem('submeters', submeters);
        const currentStatus = getCurrentMeterStatus(uninstalledMeter, i18n);

        navigate(
          currentStatus.propertyGroup && currentStatus.propertyGroup.id
            ? `${rootUrl}/devices/install/forPropertyGroup/${currentStatus.propertyGroup.id}`
            : `${rootUrl}/devices/install/forLocation/${currentStatus.serviceLocationId}`
        );
      }
    }

    // This is a child meter
    else if (uninstalledMeter && uninstalledMeter.parentMeterRelation) {
      try {
        await unlinkMeter(uninstalledMeter.id);
        if (replaceMeter) {
          setItem('parentMeterRelation', uninstalledMeter.parentMeterRelation);
          const currentStatus = getCurrentMeterStatus(uninstalledMeter, i18n);

          navigate(
            currentStatus.propertyGroup && currentStatus.propertyGroup.id
              ? `${rootUrl}/devices/install/forPropertyGroup/${currentStatus.propertyGroup.id}`
              : `${rootUrl}/devices/install/forLocation/${currentStatus.serviceLocationId}`
          );
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.meter.unlink_fail'),
          error: e
        });
      }
    }
  };

  React.useEffect(() => {
    validate();
  }, [uninstallDateTime]);

  const uninstallDetailPageMeter = async (meter_: MeterType) => {
    // Date from the picker can be undefined
    const date = uninstallDateTime ? uninstallDateTime : moment(MIN_DATE);

    await unlinkMeters(meter_);

    const newMeter = await uninstallMeter(meter_.id, date);

    // Only refresh entire detail page when meters were unlinked
    if (refresh && submeters && submeters.length > 0) {
      refresh();
    } else if (setMeter) setMeter(newMeter);

    notify.success({
      content: `${i18n.getTranslation('actions.meter.uninstall_success')}\n ${formatSuccessDescription(false, date)}`
    });
  };

  const uninstallListPageMeters = async (selectedRows_: MeterRowType[]) => {
    // Date from the picker can be undefined
    const date = uninstallDateTime ? uninstallDateTime : moment(MIN_DATE);

    const newMeters = await Promise.all(selectedRows_.map((m) => uninstallMeter(m.__id, date)));
    const multipleMeters = selectedRows_.length > 1;

    if (!multipleMeters) {
      await unlinkMeters(selectedRows_[0].__meterEntity);
    }

    if (onComplete) onComplete(newMeters);
    if (refreshCounts) refreshCounts();

    notify.success({
      content: `${i18n.getTranslation(
        `actions.meter.${multipleMeters ? 'uninstall_successes' : 'uninstall_success'}`
      )}\n ${formatSuccessDescription(multipleMeters, date)}`
    });
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        // If we are in Meter Details
        if (meter) {
          await uninstallDetailPageMeter(meter);
        }
        // If we are at the Meter List Page
        else if (selectedRows) {
          await uninstallListPageMeters(selectedRows);
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.meter.uninstall_error'),
          error: e
        });
      }
    }
  }));

  const replaceCheckbox = (
    <Center type="horizontal">
      <CheckBox id="meter.replace" checked={replaceMeter} onChange={setReplaceMeter}>
        {i18n.getTranslation('actions.meter.replace_meter')}
      </CheckBox>
    </Center>
  );

  return (
    <>
      <div className={classNames(css['input-wrapper2'])}>
        <Paragraph>{i18n.getTranslation('actions.meter.uninstall')}</Paragraph>
        <div className={css['add-margin-top']}>
          <DatePicker
            id="uninstall-date-time"
            onChange={(val) => setUninstallDateTime(val)}
            value={uninstallDateTime}
            placeholder={i18n.getTranslation('actions.meter.uninstall_date')}
            error={!uninstallDateTime}
          />
        </div>
      </div>

      {isSingleMeterParent && submeters && (
        <>
          <br />
          <ExclamationBadge title={i18n.getTranslation('general.watch_out')} type="warning">
            {i18n.getTranslation('actions.meter.submeter_warning', { amount: submeters.length })}
          </ExclamationBadge>
          <br />
          {replaceCheckbox}
        </>
      )}

      {isSingleMeterChild && (
        <>
          <br />
          <ExclamationBadge title={i18n.getTranslation('general.watch_out')} type="warning">
            {i18n.getTranslation('actions.meter.parent_warning', {
              parent:
                listPageSingleMeter && listPageSingleMeter.parentMeterRelation
                  ? listPageSingleMeter.parentMeterRelation.serialNumber
                  : meter && meter.parentMeterRelation
                  ? meter.parentMeterRelation.serialNumber
                  : '',
              reference:
                listPageSingleMeter && listPageSingleMeter.parentMeterRelation
                  ? listPageSingleMeter.parentMeterRelation.externalReference
                  : meter && meter.parentMeterRelation
                  ? meter.parentMeterRelation.externalReference
                  : ''
            })}
          </ExclamationBadge>
          <br />
          {replaceCheckbox}
        </>
      )}

      {!listPageSingleMeter && !meter && (
        <>
          <br />
          <ExclamationBadge title={i18n.getTranslation('general.watch_out')} type="warning">
            <Interweave content={i18n.getTranslation('actions.meter.unlink_warning_multi')} />
          </ExclamationBadge>
        </>
      )}
    </>
  );
});

export default observer(UninstallDialog);
