import { observer } from 'mobx-react';
import React from 'react';

import { ExternalChannelType, MeterType } from '@zf/api-types/master-data/meter';
import ConfigCard from '@zf/stella-react/src/atoms/Card/ConfigCard';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import IconBox from '../../../../../../components/IconBox/IconBox';
import { useStore } from '../../../../../../hooks/useStore';
import DeviceLink from '../../../../../devices/shared/DeviceLink';
import AddMeasurementBtn from '../../../billing/tabs/cost-allocations/add-measurement-btn';
import css from './meter.module.scss';

type Props = {
  index: number;
  meter: MeterType;
  channel: ExternalChannelType;
};

const Meter = (props: Props) => {
  const { index, meter, channel } = props;

  const { applicationStore } = useStore();
  const { getEnumTranslation } = applicationStore;

  return (
    <ConfigCard id={meter.id} className={css['wrapper']} hoverable={false}>
      <div>
        <IconBox iconType={channel.utilityType} />
      </div>

      <div>
        <div className={css['heading']}>
          <Heading headingType="h4" style="bold" color={colors['graphite']}>
            <DeviceLink device={meter} />
          </Heading>
          {meter.meterTag && <Heading headingType="h4">{`(${meter.meterTag})`}</Heading>}
        </div>
        <div className={css['value-msmt-btn']}>
          <Paragraph>{getEnumTranslation('meteringType', channel.meteringType)}</Paragraph>
          <AddMeasurementBtn index={index} meter={meter} />
        </div>
      </div>
    </ConfigCard>
  );
};

export default observer(Meter);
