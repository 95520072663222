import clone from 'clone';
import React from 'react';

import { DomainNotificationType } from '@zf/api-types/general';
import {
  AddMeasurementRequestType,
  ExternalChannelType,
  InstallMeterRequestType,
  MeasurementType,
  MeterRelation,
  MeterType
} from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import useSessionStorage from '@zf/hooks/src/useSessionStorage';
import { MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context/app-context';
import { notify } from '../../../events/notification-events';
import { channelsAreEqual } from '../../../utils/meter';
import { countRequestSuccesses } from '../../../utils/request';
import { InstallationType } from './InstallMeterWizard';

type SharedValuesType = {
  didSubmitBefore: boolean;
  installations: InstallationType[];
  installSuccesses: boolean[];
  numberInstallSuccess: number;
  location?: ServiceLocationType | null;
  propertyGroup?: PropertyGroupType;
};

type SharedDispatchType = {
  didSubmitBefore: boolean;
  installSuccesses: boolean[];
  areMetersValid: boolean;
  measurementSuccesses: boolean[];
  numberMeasurementsSuccess: number;
  areMeasurementsValid: boolean;
  numberInstallSuccess: number;
};

export const useSubmitFunctions = (
  addMeasurementMeter: (value: AddMeasurementRequestType) => Promise<MeasurementType>,
  installMeter: (apiFriendlyValues: InstallMeterRequestType, meterId: string) => Promise<MeterType>,
  updateParentRelation: (meterId: string, parentId: string, externalRef: string) => Promise<MeterType>,
  setApiErrors: React.Dispatch<React.SetStateAction<DomainNotificationType[]>>,
  handleApiErrors: (fdbk: string[][], errors_: DomainNotificationType[]) => void
) => {
  const { i18n } = useAppContext();

  const { getItem, removeItem } = useSessionStorage();

  const submitInstall = async <V extends SharedValuesType>(installation: InstallationType, values: V) => {
    if ((values.location || values.propertyGroup) && installation.meter) {
      // Backend fills the external id's in
      const channelsWithoutExtId = installation.meter.channels.reduce((acc: ExternalChannelType[], chann) => {
        // Do not push duplicate channels
        if (
          !acc.find((channel) => {
            return channelsAreEqual(chann, channel);
          })
        ) {
          acc.push({ ...chann, externalIdentifier: '' });
        }

        return acc;
      }, []);

      const meterInstallApiFriendlyValues: InstallMeterRequestType = {
        mutationDateTime: installation.mutationDateTime ? clone(installation.mutationDateTime).toISOString() : MIN_DATE,
        serviceLocationId: values.location ? values.location.id : '',
        propertyGroupId: values.propertyGroup ? values.propertyGroup.id : '',
        addressInstalled: values.location
          ? values.location.address
          : values.propertyGroup
          ? values.propertyGroup.address
          : null,
        channelTemplates: channelsWithoutExtId
      };

      try {
        const installedMeter = await installMeter(meterInstallApiFriendlyValues, installation.meter.id);
        return installedMeter.id;
      } catch (e) {
        //@ts-ignore
        const apiErrors_ = e.data && e.data.errors ? e.data.errors : [];
        handleApiErrors([], apiErrors_);
        setApiErrors(apiErrors_);

        return '';
      }
    }

    return '';
  };

  const relinkMeters = async (
    installedMeterId: string,
    submeters: MeterType[],
    parentMeterRelation?: MeterRelation | null
  ) => {
    if (submeters.length > 0) {
      // When we are swapping a parent meter by a fresh install
      await Promise.all(
        submeters.map((m) =>
          updateParentRelation(
            m.id,
            installedMeterId,
            m.parentMeterRelation ? m.parentMeterRelation.externalReference : ''
          )
        )
      )
        .then(() => {
          notify.success({
            content: i18n.getTranslation('actions.meter.relink_submeters_success')
          });
        })
        .catch((e) => {
          notify.error({
            content: i18n.getTranslation('actions.meter.relink_submeters_failed'),
            error: e
          });
        });
    } else if (parentMeterRelation) {
      try {
        updateParentRelation(installedMeterId, parentMeterRelation.meterId, parentMeterRelation.externalReference);

        notify.success({
          content: i18n.getTranslation('actions.meter.relink_parent_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.meter.relink_parent_failed'),
          error: e
        });
      }
    }
  };

  const submitMeasurements = async (measurements: AddMeasurementRequestType[]) => {
    let returnVal: MeasurementType | undefined = undefined;
    try {
      await Promise.all(
        measurements.map(async (measurement) => {
          returnVal = await addMeasurementMeter({
            channelId: measurement.channelId,
            endDateTime: measurement.endDateTime,
            meter: measurement.meter,
            value: measurement.value,
            hour: measurement.hour,
            minute: measurement.minute
          });
        })
      );

      return [!!returnVal];
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('actions.meter.add_measurement_error'),
        error: e
      });

      //@ts-ignore
      const apiErrors_ = e.data && e.data.errors ? e.data.errors : [];
      handleApiErrors([], apiErrors_);
      setApiErrors(apiErrors_);
      return [false];
    }
  };

  const submitInstallationsAndMeasurements = async <V extends SharedValuesType>(
    values: V,
    setValue: (value: Partial<SharedDispatchType>, initialApiSet?: boolean, backup_?: boolean) => void
  ) => {
    /**
     * Submit installs
     * If the submitInstall function returns an id we have a success
     */
    const successes: boolean[] = [];
    const installedMeterIds: string[] = [];
    let installSuccess = false;

    // If this is our first submit
    if (!values.didSubmitBefore) {
      setValue({ didSubmitBefore: true });
      for (const installation of values.installations) {
        const installedMeterId = await submitInstall(installation, values);
        installedMeterIds.push(installedMeterId);
        successes.push(!!installedMeterId);
      }
    } else {
      for (let i = 0; i < values.installations.length; i++) {
        // If this install was a fail during previous submit, try again
        if (values.installSuccesses[i] === false) {
          const installedMeterId = await submitInstall(values.installations[i], values);
          installedMeterIds.push(installedMeterId);
          successes.push(!!installedMeterId);
        } else {
          // Install was a success during previous submit, but we need to keep our array length
          successes.push(true);
        }
      }
    }

    if (
      countRequestSuccesses(values.installSuccesses) !== successes.length &&
      successes.filter((el) => el === true).length === values.installations.length
    ) {
      notify.success({
        content:
          values.numberInstallSuccess > 1
            ? i18n.getTranslation('install_meter.install_meter_success')
            : i18n.getTranslation('install_meter.install_meters_success')
      });
    }

    setValue({ installSuccesses: successes });
    installSuccess = successes.filter((el) => el === true).length === values.installations.length;

    const submeters = getItem('submeters', [] as MeterType[]);
    const parentMeterRelation = getItem<MeterRelation | null>('parentMeterRelation', null);

    // Reset our storage to avoid linked meter detection in other UC's
    removeItem('submeters');
    removeItem('parentMeterRelation');

    // If we are swapping linked meters by a new installation
    if (installSuccess && installedMeterIds.length === 1 && (submeters.length > 0 || !!parentMeterRelation)) {
      await relinkMeters(installedMeterIds[0], submeters, parentMeterRelation ? parentMeterRelation : undefined);
    }

    const allMeasurements = [];

    if (installSuccess) {
      setValue({ numberInstallSuccess: countRequestSuccesses(successes), areMetersValid: installSuccess });

      /**
       * Submit measurements
       */
      let measurementsValid = false;
      const addMeasurementsSuccesses: boolean[] = [];
      let addMeasurementsSuccess: boolean[] = [];
      const measurementsForInstallationSuccesses: boolean[] = [];

      // Count all measurements to be submitted
      values.installations.forEach((installation) => {
        installation.measurements.forEach((msmt) => {
          allMeasurements.push(msmt);
        });
      });

      for (const installation of values.installations) {
        addMeasurementsSuccess = await submitMeasurements(installation.measurements);
        if (addMeasurementsSuccess) {
          addMeasurementsSuccesses.push(...addMeasurementsSuccess);
          if (addMeasurementsSuccess.includes(false)) {
            measurementsForInstallationSuccesses.push(false);
          } else {
            measurementsForInstallationSuccesses.push(true);
          }
        }
      }
      setValue({ measurementSuccesses: measurementsForInstallationSuccesses });

      measurementsValid = !addMeasurementsSuccesses.includes(false);

      setValue({
        numberMeasurementsSuccess: countRequestSuccesses(addMeasurementsSuccesses),
        areMeasurementsValid: measurementsValid
      });
    }
    // In case we only install meters without any measurements
    if (allMeasurements.length === 0 && installSuccess) {
      setValue({ areMeasurementsValid: true });
    }
  };

  return {
    submitInstall,
    submitMeasurements,
    submitInstallationsAndMeasurements
  };
};
