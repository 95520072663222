import classNames from 'classnames';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { AttachmentType } from '@zf/api-types/attachment';
import { entitySubjectType } from '@zf/api-types/enums';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context/app-context';
import SingleUploadInput from '../../components/input/SingleUploadInput';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import css from './incoming-invoice-attachment.module.scss';
import IncomingInvoicePreview from './incoming-invoice-preview';

type Props = {
  file: any;
  incomingInvoiceId: string | undefined;
  setFile: (file: any) => void;
};

export default function IncomingInvoiceAttachment(props: Props) {
  const { file, incomingInvoiceId, setFile } = props;
  const { i18n } = useAppContext();
  let preview: JSX.Element | null = null;
  let attachment: AttachmentType | undefined;

  const attachmentResponse = useSuspenseSingleAPI<PagedResponseType<AttachmentType>>({
    request: {
      endpoint: `/att/attachments/${entitySubjectType.incomingInvoice}/${incomingInvoiceId}`
    },
    mayExecute: !!incomingInvoiceId
  });

  if (incomingInvoiceId) {
    if (!attachmentResponse.result) return null;

    attachment = attachmentResponse.result.data.results[0];
  }

  if (file) {
    preview = <IncomingInvoicePreview file={file} attachment={attachment} />;
  }

  return (
    <div className={classNames({ [css['attachment-wrapper']]: !!preview })}>
      {preview}

      <Center type="both">
        <SingleUploadInput
          iconType="invoice"
          heading={i18n.getTranslation('property_groups.tabs.billing.drop_invoice')}
          file={file}
          setFile={setFile}
        />
      </Center>
    </div>
  );
}
