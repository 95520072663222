import React from 'react';

import { OutgoingBankingTransactionType } from '@zf/api-types/billing/outgoing-banking-transaction';
import useDialog from '@zf/hooks/src/useDialog';

import CancelOutgoingBankingTransactionDialog from '../../../actions/outgoing-banking-transactions/CancelOutgoingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  bankingTransaction: OutgoingBankingTransactionType;
  setOutgoingBankingTransaction: React.Dispatch<React.SetStateAction<OutgoingBankingTransactionType | null>>;
};

export default function CancelDetailButton(props: Props) {
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="outgoing_banking_transaction.cancel"
      icon="stop"
      dialogTitle={i18n.getTranslation('general.cancel')}
      dialogContent={<CancelOutgoingBankingTransactionDialog {...props} ref={clickRef} />}
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.cancel')}
      dialogType="danger"
    />
  );
}
