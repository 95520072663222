import React from 'react';

import { APISettingsType, UpdateFileFormatType } from '@zf/api-types/file-format';

import useConfig from '../../../app-context/hooks/use-config';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import FileFormats from './file-formats';

export default function FileFormatsTab() {
  const config = useConfig<UpdateFileFormatType[]>('importFileFormats', '/cfg/DataImportFileFormats');

  const formatSettingsResponse = useSuspenseSingleAPI<APISettingsType[]>(
    {
      request: {
        endpoint: '/cfg/DataImportFileFormats/settings'
      }
    },
    false,
    false
  );

  if (!formatSettingsResponse.result) return null;

  return <FileFormats config={config} settings={formatSettingsResponse.result.data} />;
}
