import React from 'react';

import { DataPartitionType, OrganisationPurgeRequestType } from '@zf/api-types/auth';
import { ProgressBar } from '@zf/stella-react/src/atoms/ProgressBar';

import { useAppContext } from '../../../app-context';
import { Button } from '../../../design-system/Components';
import { Paragraph } from '../../../design-system/Foundation';
import { dialog } from '../../../events/dialog-events';
import useSuspenseSingleAPI from '../../../hooks/useSuspenseSingleAPI';
import ResetInfoDialog from './dialogs/ResetInfoDialog';
import css from './reset-card.module.scss';

type Props = {
  orgId: string;
  setIsResetting?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleting?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function Progress(props: Props) {
  const { orgId, setIsResetting, setIsDeleting } = props;
  const { i18n, tenantReducer } = useAppContext();

  const response = useSuspenseSingleAPI<OrganisationPurgeRequestType>({
    request: {
      endpoint: '/sys/purging/current'
    },
    updateInterval: 5000,
    organization: orgId,
    tenant: tenantReducer.state.tenant?.id
  });

  React.useEffect(() => {
    if (response.result && !response.result.data) {
      if (setIsResetting) {
        setIsResetting(false);
      }
      if (setIsDeleting) {
        setIsDeleting(false);
      }
    }
  }, [response.result]);

  if (!response.result || !response.result.data) return null;

  const progressInfo = response.result.data;

  const getPrecentage = () => {
    if (progressInfo.dataPartitions.length > 0) {
      const total = progressInfo.dataPartitions.reduce((acc: number, part: DataPartitionType) => {
        let partPercentage = 0;

        partPercentage = part.amountOfDocuments
          ? part.amountDeleted / part.amountOfDocuments // Do not divide by zero
          : 1;

        acc += partPercentage;

        return acc;
      }, 0);

      return Math.round((total / progressInfo.dataPartitions.length) * 100);
    }

    return 0;
  };

  const getProgress = () => {
    if (progressInfo.dataPartitions.length > 0) {
      if (!progressInfo.isStarted) {
        return i18n.getTranslation('tenant.queued');
      } else {
        return `${getPrecentage()}%`;
      }
    }

    return '0%';
  };

  const progressString = getProgress();

  const openDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('general.details'),
      content: <ResetInfoDialog progressInfo={progressInfo} orgId={orgId} />,
      buttonPositive: i18n.getTranslation('general.ok'),
      isDismissDialog: true,
      onSubmit: () => {}
    });
  };

  return (
    <>
      {progressString !== i18n.getTranslation('tenant.queued') && (
        <ProgressBar className={css['progress-bar']} size="medium" progress={getPrecentage()} />
      )}
      <Paragraph className={css['progress']}>{progressString}</Paragraph>

      {progressInfo.dataPartitions.length > 0 && (
        <Button id="clearing.info" className={css['details']} type="text" onClick={openDialog}>
          {i18n.getTranslation('general.details')}
        </Button>
      )}
    </>
  );
}
