import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import useDialog from '@zf/hooks/src/useDialog';
import AsyncVarSpinner from '@zf/stella-react/src/atoms/Spinner/AsyncVarSpinner';

import ConfigColumn from '../../../components/Column/ConfigColumn';
import { dialog } from '../../../events/dialog-events';
import { useStore } from '../../../hooks/useStore';
import useBillingItemsFilter from '../hooks/use-billing-items-filter';
import BillingItemsContent from './BillingItemsContent';
import BillingItemsFilters from './BillingItemsFilters';
import AddEditBillingItemDialog from './dialogs/add-edit-dialog/AddEditBillingItemDialog';

const BillingItems = () => {
  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { isPanelViewMode, billingItemsStore } = productConfigStore;
  const {
    billingItems_,
    taxCodes_,
    consumptionUnitTypes_,
    customEntityPropertyTypes_,
    calculationTypes_,
    initBillingItems,
    setBillingItems
  } = billingItemsStore;

  const [query] = useQueryParams({
    billingItemName: StringParam
  });

  const { filterInputs, tags, resetFilters } = useBillingItemsFilter(setBillingItems, query.billingItemName || '');

  const { clickRef, validationRef, onSubmit } = useDialog();

  useEffect(() => {
    initBillingItems();
  }, []);

  const openAddDialog = () => {
    dialog.normal({
      title: getTranslation('billing_items.add_item'),
      icon: 'plus',
      content: <AddEditBillingItemDialog validationRef={validationRef} ref={clickRef} />,
      buttonPositive: getTranslation('general.add'),
      ref: validationRef,
      onSubmit
    });
  };

  return (
    <ConfigColumn
      title={getTranslation('billing_items.billing_items')}
      extraRight={
        billingItems_ && <BillingItemsFilters tags={tags} filterInputs={filterInputs} resetFilters={resetFilters} />
      }
      add={taxCodes_ ? openAddDialog : undefined}
      borderRight={!isPanelViewMode}
    >
      <AsyncVarSpinner
        variables={[billingItems_, taxCodes_, consumptionUnitTypes_, customEntityPropertyTypes_, calculationTypes_]}
      >
        <BillingItemsContent />
      </AsyncVarSpinner>
    </ConfigColumn>
  );
};

export default observer(BillingItems);
