import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';

import { ConfigHelp } from '../../../../../components/CoachMarks';
import { CardBody, DetailLineWrapper } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import Bankaccounts from './Bankaccounts';

const AutomaticPaymentsCard = () => {
  const { applicationStore, paymentConfigStore } = useStore();
  const { getTranslation } = applicationStore;

  const { twikeyEnabled } = paymentConfigStore;

  return (
    <Card id="payment-card" width="2">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={getTranslation('coachmark.automatic_payments.title')}
            content={[getTranslation('coachmark.automatic_payments.paragraph')]}
          />
        }
        extraRight={
          <ZFToggle
            onChange={() => {
              // Disabled
            }}
            checked={twikeyEnabled}
            disabled
          />
        }
      >
        {getTranslation('parameters.automatic_payments')}
      </CardHeader>
      {twikeyEnabled && (
        <CardBody>
          <DetailLineWrapper>
            <Bankaccounts />
            {/*
          <HorizontalDivider />
          <Settings />
           */}
          </DetailLineWrapper>
        </CardBody>
      )}
    </Card>
  );
};

export default observer(AutomaticPaymentsCard);
