import React from 'react';

import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import { useAppContext } from 'app-context';

export default function useCustomerTabs() {
  const { i18n } = useAppContext();

  const [tabItems] = React.useState<TabItemType[]>([
    {
      id: 'customers',
      title: i18n.getTranslation('customer.customers')
    },
    {
      id: 'customer-groups',
      title: i18n.getTranslation('customer_groups.customer_groups')
    }
  ]);

  return tabItems;
}
