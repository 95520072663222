import { SuspenseCardBody } from 'components/suspense';
import { useStore } from 'hooks/useStore';
import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { colors } from '@zf/utils/src/color';

import css from './meter-issues-card.module.scss';
import MeterIssuesCardBody from './MeterIssuesCardBody';

type Props = {
  meter: MeterType;
  issues: MeteringIssue[];
  issuesCount: number;
  refresh?:() => void
};

const MeterIssuesCard = (props: Props) => {
  const { meter, issues, issuesCount, refresh } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="meter-issues-card">
      <CardHeader
        className={css['meter-issues-card-header']}
        extraRight={
          <NewStatusBadge
            color={colors[issuesCount > 0 ? 'orange-600' : 'green-600']}
            type="numeric"
            status_={String(issuesCount)}
          />
        }
      >
        {getTranslation('meter_issues.issues')}
      </CardHeader>
      <SuspenseCardBody type="grid" fixedHeight>
        <MeterIssuesCardBody meter={meter} issues={issues} refresh={refresh}/>
      </SuspenseCardBody>
    </Card>
  );
};

export default MeterIssuesCard;
