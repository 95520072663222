import React from 'react';

import { MeteringIssue, MeteringIssueGrouped } from '@zf/api-types/metering/metering-issues';

import { useIssuesContext } from '../../Context/Context';
import ResolveGroupedIssuePanel from './multi-issue/ResolveGroupedIssuePanel';
import ResolveMultiIssuePanel from './multi-issue/ResolveMultiIssuePanel';
import ResolveSingleIssuePanel from './single-issue/ResolveSingleIssuePanel';
import { MeterType } from '@zf/api-types/master-data/meter';

export type IssueActionType = 'resolve' | 'ignore' | 'export' | 'ignore-and-mute';

type Props = {
  isGrouped: boolean;
  refresh: () => void;
  meter?: MeterType;
};

const IssueSidePanel = (props: Props) => {
  const { isGrouped, refresh } = props;

  const ctx = useIssuesContext();
  const { getSidePanelData } = ctx;

  const issues = getSidePanelData();

  if (issues.length === 0) return null;

  if (isGrouped && issues.length === 1) {
    return <ResolveGroupedIssuePanel issues={issues as MeteringIssueGrouped[]} />;
  }

  return issues.length === 1 ? (
    <ResolveSingleIssuePanel issue={issues[0] as MeteringIssue} refresh={refresh} />
  ) : (
    <ResolveMultiIssuePanel issues={issues as MeteringIssue[]} refresh={refresh} />
  );
};

export default IssueSidePanel;
