import { observer } from 'mobx-react';
import React from 'react';

import { useMsal } from '@azure/msal-react';
import { uiCulture } from '@zf/api-types/enums';
import { ErrorDataType } from '@zf/api-types/general';
import { List, ListDivider, ListHeading } from '@zf/stella-react/src/atoms/List';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { logoutRequest } from '../../../constants/authentication';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import { UserAvatar } from '../../Avatar';
import { Icon } from '../../Icon';
import EnumFilterSelect from '../../input/EnumFilterSelect';
import css from './header.module.scss';

export type demoEnabled = {
  data: boolean;
  errors: ErrorDataType;
  success: boolean;
};

const UserActions = () => {
  const { applicationStore } = useStore();
  const { userStore, getTranslation, getEnum } = applicationStore;
  const { user, lang, changeUserLanguage } = userStore;

  const { instance } = useMsal();

  return (
    <TooltipTrigger
      tooltip={
        <List className={css['account-list']}>
          <ListHeading>
            <Icon type="customer-filled" />
            {user.userName}
          </ListHeading>

          <ListDivider noMargin />

          <EnumFilterSelect
            id="culture"
            className={css['language-item']}
            iconType="language"
            title={getTranslation(`language.${lang}`)}
            options={getEnum<uiCulture>('uiCulture')}
            values={[lang]}
            setValue={(value) => {
              // This function sets the cookie which is used in i18n as well,
              // so this function suffices for both state management systems
              changeUserLanguage(value[0]);

              // We should avoid page reload in the future, but memoization issues on the virtual table force us
              window.location.reload();
            }}
            showAllCheckboxes={false}
            blueTextWhenSelected={false}
            noDeselect
          />

          <DropdownAction actionType="link" url="https://support.zerofriction.co/" icon="questionmark" newTab>
            {getTranslation('general.help')}
          </DropdownAction>
          <ListDivider className={css['divider']} />

          <DropdownAction
            id="logout"
            actionType="direct"
            icon="log-out"
            onClick={() => {
              instance.logoutRedirect(logoutRequest);
            }}
          >
            {getTranslation('general.logout')}
          </DropdownAction>
        </List>
      }
      trigger="click"
      placement="bottom-end"
    >
      <div className={css['user-container']}>
        <UserAvatar isClickable>{user.userName}</UserAvatar>
      </div>
    </TooltipTrigger>
  );
};

export default observer(UserActions);
