import React, { memo } from 'react';

import { billingCalculationType } from '@zf/api-types/enums';
import { CultureTableType } from '@zf/api-types/language';
import { BillingItemType, ProductType } from '@zf/api-types/product';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import useTranslations from '../../../../app-context/hooks/use-translations';
import ActivePeriod from '../../../../components/Date/ActivePeriod';
import LanguageIcon from '../../../../components/Icon/LanguageIcon';
import css from './billing-item.module.scss';

type Props = {
  billingItem: BillingItemType;

  // Used for the active period
  product: ProductType | undefined;
  isActive: boolean | undefined;

  // Used for translations
  index?: number;
  cultureTable_?: CultureTableType;
  updateBillingItemInList?: (updatedItem: BillingItemType) => void;
};

const BillingItemHeader = memo((props: Props) => {
  const { index, product, isActive, billingItem, cultureTable_, updateBillingItemInList } = props;

  const { openTranslationsDialog } = useTranslations<BillingItemType>('BillingItems');

  const onCompleteLangUpdate = (index: number, updated: BillingItemType) => {
    if (updateBillingItemInList) updateBillingItemInList(updated);
  };

  const matchingProductBillingItem = product?.billingItems.find((pb) => pb.billingItemId === billingItem.id);

  return (
    <>
      <img
        src={`https://cdn.zerofriction.co/shared/assets/emptyStates/${
          billingItem.calculationParameters.type === billingCalculationType.consumption ||
          billingItem.calculationParameters.type === billingCalculationType.consumptionunit
            ? 'services2'
            : 'payment'
        }.png`}
        alt={billingItem.name}
      />
      <div className={css['lang-icon-wrapper']}>
        {matchingProductBillingItem && typeof isActive !== 'undefined' && (
          <ActivePeriod
            startDateTime={matchingProductBillingItem.startDateTime}
            endDateTime={matchingProductBillingItem.endDateTime}
            isActive={isActive}
          />
        )}

        <div className={css['name-lang-icon']}>
          <Paragraph color={colors['graphite']} bold>
            {billingItem.name}
          </Paragraph>
          {typeof index !== 'undefined' && cultureTable_ && cultureTable_.supportedCultures.length > 1 && (
            <LanguageIcon
              id={`${billingItem.name}-translations`}
              localisationLevel_={billingItem.localisationLevel}
              action={() => openTranslationsDialog(index, billingItem, onCompleteLangUpdate)}
              hasMultipleLanguages
            />
          )}
        </div>
        <Paragraph>{billingItem.description}</Paragraph>
      </div>
    </>
  );
});

export default BillingItemHeader;
