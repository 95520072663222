import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';

import { navigate } from '@reach/router';
import { entitySubjectType, meterType } from '@zf/api-types/enums';
import { MeterPropertiesPrepayment, MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import { CommentCard, FeedCard } from '../../../../../cards';
import CustomEntityPropertiesCard from '../../../../../cards/CustomEntityProperties/CustomEntityPropertiesCard';
import IntegrationLoggingCard from '../../../../../cards/Integration/IntegrationLoggingCard';
import BalanceCard from '../../../prepayment-device/detail-page/detail/BalanceCard';
import MeterDetailCard from '../MeterDetailCard';
import ChannelCard from './ChannelCard';
import HistoryCard from './HistoryCard';
import MeterIssuesCard from './MeterIssuesCard';
import SubmetersCard from './SubmetersCard';

type Props = {
  timeStamp: string;
  meter: MeterType;
  activeState: MeterStatusHistoryType | undefined;
  prePayment: boolean;
  issues: MeteringIssue[] | undefined;
  issuesCount: number;
  navigate: (url: string) => void;
  setMeter: Dispatch<SetStateAction<MeterType | null>>;
  refresh: () => void;
};

const MeterDetail = (props: Props) => {
  const { meter, activeState, timeStamp, issues, issuesCount, setMeter, refresh } = props;
  const { meterStore } = useStore();
  const { organisationCfg } = meterStore;

  return (
    <CardsContainer key={meter.id}>
      <MeterDetailCard meter={meter} activeState={activeState} />
      {meter.meterType === meterType.prepayment ? (
        <BalanceCard
          meterId={meter.id}
          navigate={navigate}
          meterProperties={meter.meterProperties as MeterPropertiesPrepayment}
        />
      ) : (
        <FeedCard subjectType={entitySubjectType.meter} subjectId={meter.id} timeStamp={timeStamp} />
      )}

      {organisationCfg?.features.meteringValidationEnabled && issues && (
        <MeterIssuesCard meter={meter} issues={issues} issuesCount={issuesCount} refresh={refresh}/>
      )}

      <HistoryCard statusHistory={meter.statusHistory} meterType_={meter.meterType} />
      <CommentCard subjectType={entitySubjectType.meter} subjectId={meter.id} />
      {meter.meterType !== meterType.prepayment && <ChannelCard meterId={meter.id} channels={meter.channels} />}
      {!meter.parentMeterRelation && (
        <SubmetersCard meter={meter} timeStamp={timeStamp} setMeter={setMeter} refresh={refresh} />
      )}
      <CustomEntityPropertiesCard entityType="Meters" entity={meter} entitySubjectType={entitySubjectType.meter} />
      <IntegrationLoggingCard subjectId={meter.id} subjectType="Meter" />
    </CardsContainer>
  );
};

export default observer(MeterDetail);
