import React from 'react';

import { ContractRowType, ContractType } from '@zf/api-types/master-data/contract';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../../app-context';
import useCurrent from '../../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../../design-system/ComponentSets';
import ChangeProductDialog from '../../dialogs/change-product/change-product-dialog';

type Props = {
  refreshCounts: () => void;
};

export default function ChangeProductListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('contract');
  const selectedRows: ContractRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<ContractType>('contract');
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="contract.change_product"
      icon="change-project"
      dialogTitle={i18n.getTranslation('contracts.change_product')}
      dialogContent={
        <ChangeProductDialog
          validationRef={validationRef}
          selectedRows={selectedRows}
          ref={clickRef}
          setUpdatedRows={setUpdatedRows}
          refreshCounts={refreshCounts}
        />
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      disabled={selectedRows.length === 0}
      buttonPositive={i18n.getTranslation('general.save')}
    />
  );
}
