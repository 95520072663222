import React from 'react';
import moment from 'moment';

import css from './weekdays-header.module.scss';

export default function WeekDaysHeader() {
  const weekdays = moment.weekdays();

  return (
    <div className={css['weekdays']}>
      {weekdays.map((day) => (
        <div className={css['weekday']} key={`weekday-${day}`}>
          {day.substr(0, 3)}
        </div>
      ))}
    </div>
  );
}
