import React from 'react';

import { DeleteOrCreditInvoiceType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import DeleteInvoiceDialog from '../dialogs/delete-dialog';

type Props = {
  invoice: InvoiceType;
};

export default function DeleteInvoiceListItem(props: Props) {
  const { invoice } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  const invoiceToHandle: DeleteOrCreditInvoiceType = {
    etag: invoice._etag,
    id: invoice.id,
    invoiceNum: invoice.invoiceNum
  };
  const { invoiceStore } = useStore();

  if (invoiceStore.pageActionPermissions.mayDelete) {
    return (
      <DropdownAction
        id="invoice.delete"
        icon={invoiceStore.selectedInvoice.credit ? 'credit-note' : 'trashcan'}
        dialogTitle={
          invoiceStore.selectedInvoice.credit
            ? i18n.getTranslation('actions.credit')
            : i18n.getTranslation('general.delete')
        }
        dialogContent={
          <DeleteInvoiceDialog
            ref={clickRef}
            invoiceToHandle={invoiceToHandle}
            credit={invoiceStore.selectedInvoice.credit}
          />
        }
        onSubmit={onSubmit}
        dialogType={invoiceStore.selectedInvoice.credit ? 'default' : 'danger'}
        buttonPositive={
          invoiceStore.selectedInvoice.credit
            ? i18n.getTranslation('actions.credit')
            : i18n.getTranslation('general.delete')
        }
      />
    );
  }

  return null;
}
