import React from 'react';

import { DialogClickRef, DialogProps, ValidationRef } from '@zf/heat-erp/src/design-system/ComponentSets/Dialog/Dialog';
import { dialog } from '@zf/heat-erp/src/events/dialog-events';

export default function useDialog() {
  const clickRef = React.useRef<DialogClickRef>();
  const validationRef = React.useRef<ValidationRef>();

  const onSubmit = async () => {
    if (!clickRef.current) return;
    await clickRef.current.onClick();
  };

  const openDialog = (dialogObject: DialogProps) => {
    switch (dialogObject.locationType) {
      case 'right':
        dialog.right(dialogObject);
        break;
      case 'normal-big':
        dialog.normalBig(dialogObject);
        break;

      default:
        dialog.normal(dialogObject);
        break;
    }
  };

  return { clickRef, validationRef, onSubmit, openDialog };
}
