import React, { useState } from 'react';

import { List } from '@zf/stella-react/src/atoms/List';
import { colors } from '@zf/utils/src/color';

import { TooltipTrigger } from '../../../design-system/Components';
import BaseButton from '../../../design-system/Components/Button/BaseButton';
import { Icon, Paragraph } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';
import css from './aging-report-card.module.scss';
import CustomerExport from './exports/CustomerExport';
import TransactionExport from './exports/TransactionExport';
import { State } from './hooks/useCustomerAgingFilter';

type Props = {
  filterState: State;
};

const ExportToExcel = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const [showTooltip, setShowTooltip] = useState(false);
  const { filterState } = props;

  return (
    <div onClick={() => setShowTooltip(false)} tabIndex={0} role="button">
      <TooltipTrigger
        tooltip={
          <List>
            <CustomerExport filterState={filterState} />
            <TransactionExport filterState={filterState} />
          </List>
        }
        placement="bottom-end"
        trigger="click"
        showTooltip={showTooltip}
        setShowTooltip={setShowTooltip}
      >
        <BaseButton
          className={css['export-to-csv-aging-reports-button']}
          id="export-to-csv-aging-reports"
          onClick={() => {}}
        >
          <Icon className={css['export-icon']} name="export" color={colors['silver-600']} />
          <Paragraph bold color={colors['silver-600']}>
            {getTranslation('general.export_csv')}
          </Paragraph>
          <Icon name="caret-down" color={colors['silver-600']} />
        </BaseButton>
      </TooltipTrigger>
    </div>
  );
};

export default ExportToExcel;
