import React from 'react';

import {
  OutgoingBankingTransactionRowType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import DeleteOutgoingBankingTransactionsDialog from '../../../actions/outgoing-banking-transactions/DeleteOutgoingBankingTransactionsDialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function DeleteOutgoingBankingTransactionListPageButton(props: Props) {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('outgoing_banking_transaction');
  const selectedRows: OutgoingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const { setDeletedRows } = useUpdateListPage<OutgoingBankingTransactionType>('outgoing_banking_transaction');
  const { clickRef, onSubmit } = useDialog();

  const isDisabled = selectedRows.some((r) => {
    return (
      r.__entity.status === outgoingBankingTransactionStatus.toconfirmbybank ||
      r.__entity.status === outgoingBankingTransactionStatus.closed ||
      r.__entity.status === outgoingBankingTransactionStatus.cancelled
    );
  });

  return (
    <DropdownAction
      id="outgoing_banking_transaction.delete"
      icon="trashcan"
      dialogTitle={i18n.getTranslation('general.delete')}
      dialogContent={
        <DeleteOutgoingBankingTransactionsDialog
          {...props}
          ref={clickRef}
          selectedRows={selectedRows}
          setDeletedRows={setDeletedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={isDisabled || selectedRows.length === 0}
      buttonPositive={i18n.getTranslation('general.delete')}
      dialogType="danger"
    />
  );
}
