import { Observer } from 'mobx-react';
import React from 'react';

import { CustomerBankAccountType } from '@zf/api-types/billing/customer-bank-account';
import { paymentMethod } from '@zf/api-types/enums';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import Button from '../../components/Button/Button';
import PaymentTermsDropdown from '../../components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';
import Select from '../../components/input/Select';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { dialog } from '../../events/dialog-events';
import { useStore } from '../../hooks/useStore';
import AutoGeneratedBankAccount from './AutoGeneratedBankAccount';
import css from './bank-details-card.module.scss';
import HistoryDialog from './dialogs/HistoryDialog';

export default function BankDetailsCardContentBody() {
  const { i18n, enumReducer } = useAppContext();
  const { customerStore } = useStore();

  const [paymentMethods] = React.useState(
    enumReducer.getEnum<paymentMethod>('paymentMethod').map((pm) => {
      return {
        icon: pm.value,
        value: pm.value,
        text: pm.text
      };
    })
  );

  const openHistoryDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('mandates.mandate_history'),
      icon: 'info-circle',
      content: customerStore.defaultBankAccount && <HistoryDialog bankAccount={customerStore.defaultBankAccount} />,
      onSubmit: () => {},
      isDismissDialog: true
    });
  };

  const showHistory = customerStore.defaultBankAccount && customerStore.defaultBankAccount.customerMandates?.length > 0;

  return (
    <Observer>
      {() => (
        <>
          {customerStore.accountsToApprove.map((acc) => {
            return <AutoGeneratedBankAccount key={acc.id} customerBankAccount={acc as CustomerBankAccountType} />;
          })}

          <div className={css['inputs']}>
            <div className={css['input-entry']}>
              <Label>{i18n.getTranslation('customer.payment_method')}</Label>
              <br />
              <Select
                id="customer.payment_method"
                onChange={(val) => customerStore.bankDetailsForm._set('paymentMethod', val[0])}
                selectedValues={[customerStore.bankDetailsForm._get('paymentMethod')]}
                values={paymentMethods}
              />
            </div>

            <div className={css['input-entry']}>
              <Label>{i18n.getTranslation('payment_terms.payment_term')}</Label>
              <br />
              <PaymentTermsDropdown
                id="customer.paymant_term"
                onChange={(val) => customerStore.bankDetailsForm._set('paymentTerm', val[0].id)}
                selectedValue={customerStore.bankDetailsForm._get('paymentTerm')}
                isInline
              />
            </div>

            <div className={css['input-entry']}>
              <Label>{i18n.getTranslation('customer.default_bank_account')}</Label>
              <br />
              <SimpleDropdown
                id="customer.default_bank_account"
                onChange={(val) => customerStore.bankDetailsForm._set('defaultBankAccount', val[0])}
                values={customerStore.dropdownBankAccounts}
                selectedValues={[
                  customerStore.defaultBankAccount ? customerStore.bankDetailsForm._get('defaultBankAccount') : ''
                ]}
                error={!!customerStore.bankDetailsForm._error('defaultBankAccount')}
                singleError={customerStore.bankDetailsForm._error('defaultBankAccount')}
              />
            </div>

            <div className={showHistory ? css['mandate-and-history'] : undefined}>
              <Label>{i18n.getTranslation('mandates.mandate')}</Label>
              {showHistory && (
                <Button id="mandate-history-btn" type="text" icon="eye" size="small" onClick={openHistoryDialog}>
                  {i18n.getTranslation('mandates.show_history')}
                </Button>
              )}
            </div>

            <Paragraph
              info={
                customerStore.defaultBankAccount && customerStore.defaultBankAccount.activeMandate
                  ? i18n.getTranslation('customer.since', {
                      date: formatDate(customerStore.defaultBankAccount.activeMandate.signedDateTime)
                    })
                  : ''
              }
            >
              {customerStore.defaultBankAccount?.activeMandate?.number}
            </Paragraph>
          </div>
        </>
      )}
    </Observer>
  );
}
