import React from 'react';

import css from './subtitle.module.scss';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export default function SubTitle(props: Props) {
  return <div className={css['subtitle']}>{props.children}</div>;
}
