import React from 'react';

import { CustomerMandateType } from '@zf/api-types/billing/customer-bank-account';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context';
import RemoveIconButton from '../../../../components/Icon/RemoveIconButton';
import { useStore } from '../../../../hooks/useStore';
import css from './mandate.module.scss';

type Props = {
  bankAccountIndex: number;
  mandate: CustomerMandateType;
};

export default function Mandate(props: Props) {
  const { mandate, bankAccountIndex } = props;
  const { i18n } = useAppContext();
  const { customerStore } = useStore();

  return (
    <div className={css['mandate']}>
      <div className={css['mandate-top']}>
        <Label color={colors['silver-700']}>{i18n.getTranslation('mandates.mandate')}</Label>
        <RemoveIconButton
          id={`cancel-${bankAccountIndex}`}
          onClick={() => customerStore.cancelMandate(bankAccountIndex)}
        />
      </div>

      <div className={css['mandate-details']}>
        <div>
          <Label>{i18n.getTranslation('mandates.number')}</Label>
          <Paragraph>{mandate.number}</Paragraph>
        </div>

        <div>
          <Label>{i18n.getTranslation('mandates.active_since')}</Label>
          <Paragraph>{formatDate(mandate.signedDateTime)}</Paragraph>
        </div>
      </div>
    </div>
  );
}
