import React from 'react';

import { ImportJobType, OverviewType } from '@zf/api-types/import-job';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';

import ErrorsCard from './errors-card';
import OverviewPieChart from './overview-pie-chart';
import TableCard from './table-card';
import css from './processing-details.module.scss';

type Props = {
  importJob: ImportJobType;
  overview: OverviewType;
  itemType: string;
};

export default function ProcessingDetailsTab(props: Props) {
  return (
    <CardsContainer className={css['processing-details-card']}>
      <OverviewPieChart {...props} />
      <ErrorsCard {...props} />
      <TableCard {...props} />
    </CardsContainer>
  );
}
