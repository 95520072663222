import { CollapsableInfoBanner } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { ContractServiceLocation } from '@zf/api-types/master-data/contract';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';

import css from './deviating-locations-per-date.module.scss';

type Props = {
  serviceLocations: ContractServiceLocation[];
};

const EarlyEndingLocationsPerRemovedDate = (props: Props) => {
  const { serviceLocations } = props;

  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { selectedContract, getEarlyEndingContractedLocationsPerRemovedDate } = contractStore;

  if (!selectedContract) return null;

  const earlyEndingLocationsPerRemovedDate = getEarlyEndingContractedLocationsPerRemovedDate(
    serviceLocations,
    selectedContract?.contract.supplyEndDate
  );
  const removalDates = Array.from(earlyEndingLocationsPerRemovedDate.keys());

  if (removalDates.length === 0) return null;

  return (
    <FlexElements className={css['locations']} flexDirection="column" gap="16">
      {removalDates
        .sort((a, b) => moment(b).unix() - moment(a).unix())
        .map((date) => {
          const locations = earlyEndingLocationsPerRemovedDate.get(date);

          return (
            locations && (
              <CollapsableInfoBanner
                key={date}
                infoText={getTranslation('contracts.removed_locations_for_date', {
                  amount: locations.length,
                  date: formatDate(date)
                })}
                btnText={getTranslation('contracts.show_locations')}
                color={colors['blue-600']}
              >
                <ul>
                  {locations.map((l) => (
                    <li key={l.id}>{l.address?.localizedDisplay}</li>
                  ))}
                </ul>
              </CollapsableInfoBanner>
            )
          );
        })}
    </FlexElements>
  );
};

export default observer(EarlyEndingLocationsPerRemovedDate);
