import clone from 'clone';
import React from 'react';

import { UpdatedOrganisationType } from '@zf/api-types/auth';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import InputContainer from '@zf/stella-react/src/atoms/InputContainer/InputContainer';

import { useAppContext } from '../../../../app-context';
import { AUTH_BASE_URL } from '../../../../constants/authentication';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { createHeader, METHODS, sendRequest } from '../../../../utils/request';
import InputField from '../../../input/InputField';
import { culture } from '@zf/api-types/enums';
import CultureDropdown from 'components/Dropdown/bankaccounts-dropdown/CultureDropdown';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
};

type StateValues = {
  organizationShortCode: string;
  organizationDescription: string;
  defaultLanguageCode: culture;
};

type DispatchValues = {
  organizationShortCode?: string;
  organizationDescription?: string;
  defaultLanguageCode?: culture | undefined;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { tenant } = tenantReducer.state;

  const stateReducer = createStateReducer<StateValues, DispatchValues>();
  const [values, setValue] = React.useReducer(stateReducer, {
    organizationShortCode: '',
    organizationDescription: '',
    defaultLanguageCode: culture.en
  });

  const validate = () => {
    if (validationRef.current) {
      if (!values.organizationShortCode || !values.organizationDescription || values.organizationShortCode.length > 4) {
        validationRef.current.setIsError(true);
      } else {
        validationRef.current.setIsError(false);
      }
    }
  };

  React.useEffect(() => {
    validate();
  }, [values]);

  React.useEffect(() => {
    matchCountryWithLang(tenant?.countryISOCode || 'bel');
  }, []);

  const addOrganisation = async () => {
    return (
      await sendRequest<UpdatedOrganisationType>({
        request: {
          method: METHODS.POST,
          endpoint: `${AUTH_BASE_URL}/api/Organization`,
          data: {
            tenantId: tenant?.id,
            organizationShortCode: values.organizationShortCode,
            organizationDescription: values.organizationDescription,
            defaultLanguageCode: values.defaultLanguageCode
          }
        },
        customHeaders: createHeader({
          tenant: tenant?.id
        }),
        lang: i18n.lang
      })
    ).data;
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newOrganisation = await addOrganisation();

        const tenantAuthClone = clone(tenantReducer.state.tenantAuth);
        tenantAuthClone?.organizations.push({
          description: newOrganisation.description,
          organizationId: newOrganisation.id,
          roleId: null,
          shortCode: newOrganisation.code,
          status: newOrganisation.status
        });
        tenantReducer.dispatch({ tenantAuth: tenantAuthClone });

        notify.success({
          content: i18n.getTranslation('tenant.add_success', { shortCode: values.organizationShortCode })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('tenant.add_fail', { shortCode: values.organizationShortCode }),
          error: e
        });
      }
    }
  }));

  const matchCountryWithLang = (countryCode: string) => {
    const defaultLanguageCode = {
      bel: culture['nl-BE'],
      fin: culture.fi,
      nld: culture['nl-NL'],
      aut: culture.de,
      deu: culture.de,
      che: culture.de,
      lie: culture.de,
      fra: culture['fr-FR'],
      mco: culture['fr-FR']
    }[countryCode];

    setValue({ defaultLanguageCode });
  };

  return (
    <InputContainer>
      <InputField
        id="short-code"
        onChange={(shortCode) => setValue({ organizationShortCode: shortCode })}
        value={values.organizationShortCode}
        placeholder={i18n.getTranslation('tenant.short_code')}
        error={!values.organizationShortCode || values.organizationShortCode.length > 4}
      />
      <InputField
        id="description"
        onChange={(description) => setValue({ organizationDescription: description })}
        value={values.organizationDescription}
        placeholder={i18n.getTranslation('general.description')}
        error={!values.organizationDescription}
      />
      <CultureDropdown
        id="org-default-language"
        onChange={(val) => setValue({ defaultLanguageCode: val[0] })}
        selectedValue={values.defaultLanguageCode}
        placeholder={i18n.getTranslation('general.default_language')}
      />
    </InputContainer>
  );
});
