import React from 'react';

import { Icon } from '@zf/heat-erp/src/design-system/Foundation';

import css from './closed-dropdown.module.scss';

type Props = {
  label: string;
  handleOpen?: () => void;
  onBlur?: () => void;
};

export default function ClosedDropdown(props: Props) {
  const { label, onBlur = () => {}, handleOpen = () => {} } = props;

  return (
    <div onClick={handleOpen} onBlur={onBlur}>
      <div className={css['closed-dropdown']}>
        <div>{label}</div>
        <Icon name="caret-down" />
      </div>
    </div>
  );
}
