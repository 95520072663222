import React from 'react';

import { IncomingBankingTransactionRowType } from '@zf/api-types/billing/incoming-banking-transaction';
import useDialog from '@zf/hooks/src/useDialog';

import UploadIncomingBankingTransactionDialog from '../../../actions/incoming-banking-transactions/UploadIncomingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refresh: () => void;
};

export default function UploadIncomingBankingTransactionListPageButton(props: Props) {
  const { refresh } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('incoming_banking_transaction');
  const selectedRows: IncomingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="incoming_banking_transaction.upload"
      icon="upload-cloud"
      dialogTitle={i18n.getTranslation('general.upload')}
      dialogContent={
        <UploadIncomingBankingTransactionDialog refresh={refresh} ref={clickRef} validationRef={validationRef} />
      }
      onSubmit={onSubmit}
      validationRef={validationRef}
      disabled={selectedRows.length !== 0}
      buttonPositive={i18n.getTranslation('general.upload')}
    />
  );
}
