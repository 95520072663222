import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';
import { AddEditType } from '../../../Dialog/types';
import FilesStore, { attachmentType } from '../../logic/FilesStore';
import AddEditEntityAttachmentDialog from '../dialogs/AddEditEntityAttachmentDialog';
import EditProductAttachmentDialog from '../dialogs/EditProductAttachmentDialog';
import { EntityAttachmentFile } from '@zf/api-types/entity-attachments/entity-attachments';
import { AttachmentState } from '../../logic/AttachmentGroupBase';
import { entitySubjectType } from '@zf/api-types/enums';

type Props<T> = {
  useCase: AddEditType;
  filesStore: FilesStore<T>;
  showLangPref?: boolean;
  enableTranslations?: boolean;
  attachmentType: attachmentType;
  listFiles: (attachment?: T) => EntityAttachmentFile[];
  setState?: (value: Partial<AttachmentState>) => void;
  state?: AttachmentState;
  entityId?: string;
  subjectType: entitySubjectType;
};

const AddEditEntityAttachmentListItem = <T,>(props: Props<T>) => {
  const { useCase, filesStore, enableTranslations, entityId, listFiles, setState, state } = props;
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { actionPermissions, getEditedEntityAttachment, initAddEditForm, infiniAPIService, createRequestService } =
    filesStore;
  const { selectedIds } = createRequestService;

  const [origin, setOrigin] = useState<entitySubjectType | null>();

  React.useEffect(() => {
    filesStore.setPermissions();
    setOrigin(infiniAPIService?.rows.find((r) => selectedIds.includes(r.__id))?.__entity.origin);
  }, []);

  const isAdd = useCase === 'add';

  useEffect(() => {
    initAddEditForm(useCase, listFiles);
  }, [useCase]);

  if (isAdd && !actionPermissions.mayAddEntityAttachment) return null;
  if (!isAdd && !actionPermissions.mayEditEntityAttachment) return null;

  const editedEntityAttachment = getEditedEntityAttachment();
  const isEditProductAttachment =
    (origin === entitySubjectType.product || origin === entitySubjectType.entityattachmentgroup) && useCase === 'edit';

  let buttonText = getTranslation(`general.${isAdd ? 'add' : 'save'}`);

  if (isEditProductAttachment) {
    buttonText = `${getTranslation('entity_attachment.edit_on_product')} ${getEnumTranslation(
      'entitySubjectType',
      origin === entitySubjectType.entityattachmentgroup ? entitySubjectType.propertygroup : entitySubjectType.product
    )}`;
  }

  const dialog = isEditProductAttachment ? (
    <EditProductAttachmentDialog
      entityType={
        origin === entitySubjectType.entityattachmentgroup ? entitySubjectType.propertygroup : entitySubjectType.product
      }
      ref={clickRef}
      entityId={entityId || ''}
    />
  ) : (
    <AddEditEntityAttachmentDialog
      {...props}
      state={state}
      setState={setState}
      attachment={editedEntityAttachment}
      //@ts-ignore
      filesStore={filesStore}
      ref={clickRef}
      validationRef={validationRef}
      enableTranslations={enableTranslations}
    />
  );

  return (
    <>
      <DropdownAction
        id={`entity_attachment.${useCase}`}
        icon={isAdd ? 'file-add' : 'sign'}
        dialogTitle={getTranslation(`entity_attachment.${useCase}_dialog_title`)}
        dialogContent={dialog}
        onSubmit={onSubmit}
        buttonPositive={buttonText}
        validationRef={validationRef}
      />
    </>
  );
};

export default observer(AddEditEntityAttachmentListItem);
