import { BillingItemType, BillingTariffRequestType, BillingTariffType, ProductType } from '@zf/api-types/product';
import { observer } from 'mobx-react-lite';
import React from 'react';
import EmptyColumn from '../../../../components/Column/EmptyColumn';
import { Spinner } from '../../../../design-system/Foundation';
import { useStore } from '../../../../hooks/useStore';
import BillingTariffsContent from './BillingTariffsContent';

type Props = {
  selectedBillingItem: BillingItemType | undefined;
  tariffs_: BillingTariffType[] | undefined;
  selectedProduct?: ProductType;
  showActionsOnDefault?: boolean;
  isProductPage: boolean;
  initTariffs: (billingItemId: string, selectedProductId?: string) => Promise<void>;
  addTariff: (tariffToAdd: BillingTariffRequestType) => Promise<BillingTariffType>;
  addTariffToList: (newTariff: BillingTariffType) => void;
  updateTariff: (id: string, tariffToUpdate: BillingTariffRequestType) => Promise<BillingTariffType>;
  updateTariffInList: (updatedTariff: BillingTariffType) => void;
  deleteTariff: (tariffId: string) => Promise<void>;
  deleteTariffInlist: (index: number) => void;
};

const TariffContent = (props: Props) => {
  const {
    selectedBillingItem,
    tariffs_,
    selectedProduct,
    showActionsOnDefault,
    isProductPage,
    addTariff,
    addTariffToList,
    updateTariff,
    updateTariffInList,
    deleteTariff,
    deleteTariffInlist,
    initTariffs
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  if (selectedBillingItem) {
    return !tariffs_ ? (
      <Spinner centered />
    ) : (
      <BillingTariffsContent
        isProductPage={isProductPage}
        tariffs={tariffs_}
        selectedBillingItem={selectedBillingItem}
        selectedProduct={selectedProduct}
        showActionsOnDefault={showActionsOnDefault}
        addTariff={addTariff}
        addTariffToList={addTariffToList}
        updateTariff={updateTariff}
        updateTariffInList={updateTariffInList}
        deleteTariff={deleteTariff}
        deleteTariffInlist={deleteTariffInlist}
        initTariffs={initTariffs}
      />
    );
  }

  return (
    <EmptyColumn
      image="attributes2"
      title="Billing items"
      text={getTranslation('billing_tariff.no_billing_tariff_templates')}
    />
  );
};

export default observer(TariffContent);
