import { observer } from 'mobx-react';
import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useStore } from '../../../../../hooks/useStore';

const NoMetersOverlay = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/meter.png"
          alt="meter"
          title="meter"
          width="70"
          height="70"
        />
      }
      title={getTranslation('cards.meters.no_meters')}
    >
      {getTranslation('cards.meters.no_meters_description')}
    </CardEmptyBody>
  );
};

export default observer(NoMetersOverlay);
