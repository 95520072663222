import useUser from 'hooks/useUser';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { BillingRelationType } from '@zf/api-types/billing-relation';
import { Card, CardBody, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatDateTime } from '@zf/utils/src/date';

import CardHeaderWarning from '../../../../../../components/card/card-header-warning';
import { Icon } from '../../../../../../components/Icon';
import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import css from '../contract-blocked.module.scss';

export type PropsType = {
  billingRelation: BillingRelationType;
};

const ContractBlocked = (props: PropsType) => {
  const { billingRelation } = props;
  const { applicationStore } = useStore();
  const { userStore, getTranslation } = applicationStore;

  const userName = useUser(billingRelation.blockDetails.blockedBy, userStore);

  return (
    <Card id="contract-blocked-card">
      <CardHeaderWarning
        coachMarkTitle={getTranslation('coachmark.billing_block.title')}
        coachMarkContent={[getTranslation('coachmark.billing_block.paragraph')]}
      >
        {getTranslation('contracts.billing_block')}
      </CardHeaderWarning>
      <CardBody type="grid">
        <CardItem width="6">
          <Label>{getTranslation('contracts.blocked_by')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>{userName}</Paragraph>
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('contracts.blocked_on')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>{formatDateTime(moment(billingRelation.blockDetails.blockedOn))}</Paragraph>
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('contracts.comment')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>{billingRelation.blockDetails.blockedComment}</Paragraph>
        </CardItem>
        <CardItem width="12">
          <Icon className={css['blocked-icon']} title={getTranslation('contracts.blocked')} type="lock" color />
        </CardItem>
      </CardBody>
    </Card>
  );
};

export default observer(ContractBlocked);
