import React from 'react';

import { DomainNotificationType } from '@zf/api-types/general';

import { extendMultiValue } from '../../utils/arrays';
import { getErrorText } from '../../utils/errors';

export default function useWizardAPIErrors(
  index: number,
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>
) {
  const [apiErrors, setApiErrors] = React.useState<DomainNotificationType[]>([]);

  const handleApiErrors = (fdbk: string[][], errors_: DomainNotificationType[]) => {
    const feedback: string[][] = [...fdbk];

    errors_.forEach((error) => {
      extendMultiValue(feedback, index, getErrorText(error));
    });

    setFeedback(feedback);
  };

  return {
    apiErrors,
    setApiErrors,
    handleApiErrors
  };
}
