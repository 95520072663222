import React from 'react';

import { ImportJobType } from '@zf/api-types/import-job';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { useAppContext } from '../../../app-context/app-context';
import useDetail from '../../../app-context/hooks/use-detail';
import { ICON_COLORS } from '../../../constants/icons';

const useImportJobDetail = (entityId: string) => {
  const { entity: importJob, navigator } = useDetail<ImportJobType>({
    entityId: entityId,
    endpoint: '/logs/importjob',
    domain: 'importjob'
  });
  const { enumReducer } = useAppContext();

  const status = (
    <NewStatusBadge
      color={importJob ? ICON_COLORS[importJob.status] : ''}
      status_={importJob ? ` ${enumReducer.getTranslation('importJobStatus', importJob.status)} ` : ''}
    />
  );

  return { importJob, status, navigator };
};

export default useImportJobDetail;
