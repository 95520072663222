import React, { ReactNode, useMemo } from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';
import { isGuid } from '@zf/utils/src/regex';

import { useAppContext } from '../../app-context/app-context';
import { NavigatorType } from '../../app-context/hooks/use-selection-navigator';
import { entityMap } from '../../utils/general';
import Navigator from '../navigator/navigator';
import ZFRouter from '../Router/ZFRouter';

type Props = {
  actions?: ReactNode;
  tabItems: TabItemType[];
  routes: ReactNode;
  entityId: string;
  status?: JSX.Element;
  entityType: routedEntitySubjectType;
  navigator?: NavigatorType;
  isScrollable?: boolean;
  iconName?: string;
  subTitle?: ReactNode;
  subTitle2?: ReactNode;
  navigate: (href: string) => void;
};

export default function GenericTabContainer(props: Props) {
  const {
    actions,
    tabItems,
    routes,
    entityId,
    status,
    entityType,
    navigator,
    isScrollable,
    iconName,
    subTitle,
    subTitle2,
    navigate
  } = props;
  const { i18n } = useAppContext();

  const setActiveTab = (tabId: string) => navigate(tabId);

  const splitUrl = window.location.href.split('/');

  const navigateComponent = useMemo(() => {
    if (navigator) {
      return <Navigator {...navigator} entityRoute={entityMap[entityType]} />;
    }
    return null;
  }, [navigator]);

  return (
    <TabContainer
      id={`${entityType}-detail-page`}
      actions={actions}
      entityId={entityId || i18n.getTranslation('general.empty')}
      status={status}
      onSelectTab={setActiveTab}
      selectedTab={!isGuid(splitUrl[splitUrl.length - 1]) ? splitUrl[splitUrl.length - 1] : 'detail'}
      tabItems={tabItems}
      navigateComponent={navigateComponent}
      isScrollable={isScrollable}
      iconName={iconName}
      subTitle={subTitle}
      subTitle2={subTitle2}
      actionsText={i18n.getTranslation('nav.actions')}
    >
      <ZFRouter>{routes}</ZFRouter>
    </TabContainer>
  );
}
