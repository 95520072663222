import React from 'react';

import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { IncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';

import { SuspenseSpinner } from '../../../../../../components/suspense';
import IncomingInvoiceDetails from './incoming-invoice-details';
import css from './incoming-invoices-tab.module.scss';
import IncomingInvoicesTable from './incoming-invoices-table';

type Props = {
  propertyGroupId: string;
  selectedInvoice: string;
  propertyGroup: PropertyGroupType;
  invoices: IncomingInvoiceType[];
  components: IncomingInvoiceComponentType[];
  billingPeriods: PropertyGroupBillingPeriodType[];
  setSelectedInvoice: (id: string) => void;
};

export default function IncomingInvoices(props: Props) {
  const { selectedInvoice, propertyGroupId, components, invoices, propertyGroup, setSelectedInvoice } = props;

  const selectedInvoiceEntity = invoices.find((i) => {
    return i.id === selectedInvoice;
  });

  return (
    <div className={css['incoming-invoices-wrapper']}>
      <div>
        <IncomingInvoicesTable
          propertyGroupId={propertyGroupId}
          invoices={invoices}
          selectedInvoice={selectedInvoice}
          components={components}
          setSelectedInvoice={setSelectedInvoice}
        />
      </div>
      <div>
        {selectedInvoiceEntity && (
          <SuspenseSpinner>
            <IncomingInvoiceDetails
              invoice={selectedInvoiceEntity}
              components={components}
              propertyGroupId={propertyGroup.id}
            />
          </SuspenseSpinner>
        )}
      </div>
    </div>
  );
}
