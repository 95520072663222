import Interweave from 'interweave';
import React from 'react';

import { OutputChannelType, PropertyMeteringConfigurationType } from '@zf/api-types/property-metering-configuration';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { METHODS, sendRequest } from '../../utils/request';

type Props = {
  outputChannel: OutputChannelType;
  propertyMeteringConfiguration: PropertyMeteringConfigurationType | undefined;
  setPropertyMeteringConfiguration: (newConfig: PropertyMeteringConfigurationType) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { outputChannel, propertyMeteringConfiguration, setPropertyMeteringConfiguration } = props;

  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (propertyMeteringConfiguration) {
          const data = {
            outputChannels: propertyMeteringConfiguration.outputChannels.filter((oChann) => {
              return oChann.id !== outputChannel.id;
            })
          };

          const result = (
            await sendRequest<PropertyMeteringConfigurationType>({
              request: {
                method: METHODS.POST,
                endpoint: `/me/PropertyGroupMeteringConfiguration/${propertyMeteringConfiguration.propertyGroup?.id}`,
                data: data
              },
              tenantReducer,
              lang: i18n.lang
            })
          ).data;

          setPropertyMeteringConfiguration(result);

          notify.success({
            content: i18n.getTranslation('property_groups.tabs.metering.delete_channel_success', {
              outputChannel: outputChannel.name
            })
          });
        } else {
          throw new Error(
            i18n.getTranslation('property_groups.tabs.metering.delete_channel_fail', {
              outputChannel: outputChannel.name
            })
          );
        }
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.metering.delete_channel_fail', {
            outputChannel: outputChannel.name
          }),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={i18n.getTranslation('property_groups.tabs.metering.delete_calculation_paragraph', {
          outputChannel: outputChannel.name
        })}
      />
    </Paragraph>
  );
});
