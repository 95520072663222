import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';
import useDialog from '@zf/hooks/src/useDialog';

import ProcessIncomingBankingTransactionDialog from '../../../actions/incoming-banking-transactions/ProcessIncomingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  bankingTransaction: IncomingBankingTransactionType;
  setIncomingBankingTransaction: React.Dispatch<React.SetStateAction<IncomingBankingTransactionType | null>>;
};

export default function ProcessDetailButton(props: Props) {
  const { i18n } = useAppContext();

  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="incoming_banking_transaction.process"
      icon="process"
      dialogTitle={i18n.getTranslation('general.process')}
      dialogContent={<ProcessIncomingBankingTransactionDialog {...props} ref={clickRef} />}
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('general.process')}
    />
  );
}
