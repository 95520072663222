import React from 'react';

import { UpdateNumberSequenceType } from '@zf/api-types/settings-config';

import useConfig from '../../../app-context/hooks/use-config';
import NumberSequences from './number-sequences';

export default function NumberSequenceTab() {
  const config = useConfig<UpdateNumberSequenceType[]>('numberSequences', '/cfg/numbersequences');

  return <NumberSequences config={config} />;
}
