import { InlineNumberInput } from 'components/input/NumberInput';
import { DetailLine } from 'design-system/ComponentSets';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { writeOffHandlingType } from '@zf/api-types/enums';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { MoneyInputProps } from '@zf/stella-react/src/atoms/InputField/stella-money-input';

import ConfigHelp from '../../../components/CoachMarks/config-help';
import TaxCodeDropDown from '../../../components/Dropdown/taxcodes-dropdown/TaxCodeDropDown';
import MoneyInput from '../../../components/input/MoneyInput';
import DatePicker from '../../../components/Lang/DatePicker';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../hooks/useStore';
import AdvanceCalculationParams from './AdvanceCalculationParams';
import { BillingCfgValues } from './BillingTabBody';

type Props = {
  values: BillingCfgValues;
  contractInvoiceEstimationEnabled: boolean;
  setValue: (value: Partial<BillingCfgValues>) => void;
};

const InlineInputFieldMoney = InlineInputField<MoneyInputProps>(MoneyInput);

const CreditBilling = (props: Props) => {
  const { values, contractInvoiceEstimationEnabled, setValue } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnum } = applicationStore;

  return (
    <Card id="billing-card" width="2">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={getTranslation('coachmark.billingparameters.title')}
            content={[getTranslation('coachmark.billingparameters.paragraph')]}
          />
        }
      >
        {getTranslation('prepayment_config.credit_billing')}
      </CardHeader>
      <CardBody type="detail-line" noMaxHeight>
        <DetailLine label={getTranslation('parameters.skip_approval')}>
          <CheckBox
            id="parameters.skip_approval"
            onChange={(val) => setValue({ skipApproval: val })}
            checked={values.skipApproval}
          />
        </DetailLine>

        <DetailLine label={getTranslation('parameters.skip_payment')}>
          <CheckBox
            id="parameters.skip_payment"
            onChange={(val) => setValue({ skipPayment: val })}
            checked={values.skipPayment}
          />
        </DetailLine>

        <DetailLine label={getTranslation('parameters.disable_automatic_billing_processes')}>
          <CheckBox
            id="parameters.parameters.disable_automatic_billing_processes"
            onChange={(val) => setValue({ disableAutomaticBillingProcesses: val })}
            checked={values.disableAutomaticBillingProcesses}
          />
        </DetailLine>
        <DetailLine label={getTranslation('parameters.ubl_export')}>
          <CheckBox
            id="parameters.parameters.enable_ubl_export"
            onChange={(val) => setValue({ enableUBL: val })}
            checked={values.enableUBL}
          />
        </DetailLine>

        <DetailLine label={getTranslation('parameters.days_after_invoice_checkpoint_date_to_create_invoice')}>
          <InlineNumberInput
            id="parameters.days_after_invoice_checkpoint_date_to_create_invoice"
            onChange={(daysAfterInvoiceCheckpointDateToCreateInvoice) =>
              setValue({ daysAfterInvoiceCheckpointDateToCreateInvoice })
            }
            value={values.daysAfterInvoiceCheckpointDateToCreateInvoice}
            error={
              isNaN(values.daysAfterInvoiceCheckpointDateToCreateInvoice) ||
              values.daysAfterInvoiceCheckpointDateToCreateInvoice < 0
            }
          />
        </DetailLine>

        <DetailLine label={getTranslation('parameters.days_before_advance_start_date_to_create_advance')}>
          <InlineNumberInput
            id="parameters.days_before_advance_start_date_to_create_advance"
            onChange={(daysBeforeAdvanceStartDateToCreateAdvance) =>
              setValue({ daysBeforeAdvanceStartDateToCreateAdvance })
            }
            value={values.daysBeforeAdvanceStartDateToCreateAdvance}
            error={
              isNaN(values.daysBeforeAdvanceStartDateToCreateAdvance) ||
              values.daysBeforeAdvanceStartDateToCreateAdvance < 0
            }
          />
        </DetailLine>

        <DetailLine label={getTranslation('parameters.enableInvoiceCollection')}>
          <CheckBox
            id="parameters.enable_invoicecollection"
            onChange={(val) => setValue({ enableInvoiceCollection: val })}
            checked={values.enableInvoiceCollection}
          />
        </DetailLine>

        <DetailLine label={getTranslation('parameters.doNotCreditAdvancesWhenUnpaid')}>
          <CheckBox
            id="parameters.do_not_credit_adv_when_unpaid"
            onChange={(val) => setValue({ doNotCreditAdvancesWhenUnpaid: val })}
            checked={values.doNotCreditAdvancesWhenUnpaid}
          />
        </DetailLine>

        {values.enableInvoiceCollection && (
          <>
            <DetailLine label={getTranslation('parameters.collectionBorderInvoiceCreatedDateTime')}>
              <DatePicker
                id="parameters.collection_border_invoice_createdDateTime"
                value={moment(values.collectionBorderInvoiceCreatedDateTime)}
                onChange={(val) => setValue({ collectionBorderInvoiceCreatedDateTime: val.toISOString() })}
              />
            </DetailLine>

            <DetailLine label={getTranslation('parameters.collectionWriteOffHandling')}>
              <SimpleDropdown
                id="parameters.collection_writeoff_handling"
                onChange={(val) => setValue({ collectionWriteOffHandling: val[0] })}
                values={getEnum<writeOffHandlingType>('writeOffHandlingType')}
                selectedValues={[values.collectionWriteOffHandling]}
                error={!values.collectionWriteOffHandling}
                clear
              />
            </DetailLine>

            <DetailLine label={getTranslation('parameters.groupInvoiceCollectionByCustomer')}>
              <CheckBox
                id="parameters.group_invoice_collection_by_customer"
                onChange={(val) => setValue({ groupInvoiceCollectionByCustomer: val })}
                checked={values.groupInvoiceCollectionByCustomer}
              />
            </DetailLine>
          </>
        )}

        <DetailLine label={getTranslation('parameters.advanceAmountLowerThreshold')}>
          <InlineInputFieldMoney
            id="parameters.advance_amount_lower_threshold"
            onChange={(val) => setValue({ advanceAmountLowerThreshold: val })}
            value={values.advanceAmountLowerThreshold}
          />
        </DetailLine>

        <DetailLine label={getTranslation('billing_items.organisation_tax')}>
          <TaxCodeDropDown
            id={'organisation-dropdown-id'}
            selectedValue={values.defaultTaxCodeForOrganisationAdvances}
            isInline
            onChange={(val) => setValue({ defaultTaxCodeForOrganisationAdvances: val[0]?.id || '' })}
          />
        </DetailLine>

        <DetailLine label={getTranslation('billing_items.private_customer_tax')}>
          <TaxCodeDropDown
            selectedValue={values.defaultTaxCodeForPersonAdvances}
            id={'person-dropdown-id'}
            isInline
            onChange={(val) => setValue({ defaultTaxCodeForPersonAdvances: val[0]?.id || '' })}
          />
        </DetailLine>

        <AdvanceCalculationParams
          values={values}
          contractInvoiceEstimationEnabled={contractInvoiceEstimationEnabled}
          setValue={setValue}
        />
      </CardBody>
    </Card>
  );
};

export default observer(CreditBilling);
