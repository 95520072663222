import React from 'react';

import { moveRequestStatus } from '@zf/api-types/enums';
import { MoveRequestType, TaskRowType } from '@zf/api-types/master-data/move-request';
import useDialog from '@zf/hooks/src/useDialog';

import RejectDialog from '../../../actions/tasks/reject-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function RejectListPageButton(props: Props) {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('moverequest');
  const selectedRows: TaskRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<MoveRequestType>('moverequest');
  const { clickRef, onSubmit } = useDialog();

  const disabled =
    selectedRows.length === 0 ||
    !!selectedRows.find((r) => {
      return r.__entity.status === moveRequestStatus.processed;
    });

  const rejectText = i18n.getTranslation('general.reject');

  return (
    <DropdownAction
      id="tasks.reject"
      icon="reject"
      dialogTitle={rejectText}
      dialogContent={
        <RejectDialog {...props} ref={clickRef} selectedRows={selectedRows} setUpdatedRows={setUpdatedRows} />
      }
      onSubmit={onSubmit}
      disabled={disabled}
      buttonPositive={rejectText}
    />
  );
}
