import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Gateway } from 'react-gateway';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import css from './simple-tooltip.module.scss';

type Props = TooltipProps & {
  id: string;
  tooltip: React.ReactNode;
  children: JSX.Element;
  renderInGateway?: boolean;
};

export default React.memo(function SimpleTooltip(props: Props) {
  const { id, tooltip, children, className, renderInGateway = true, ...other } = props;

  // Fixes sticky tip on button click
  const handleClick = useCallback(() => ReactTooltip.hide(), []);

  const reactTooltip = <ReactTooltip id={id} className={css['simple-tooltip']} type="light" {...other} />;

  return (
    <>
      {renderInGateway ? <Gateway into="tooltip">{reactTooltip}</Gateway> : reactTooltip}

      <div
        data-tip={tooltip}
        data-for={id}
        className={classNames([css['wrapper-div'], className])}
        onClick={handleClick}
      >
        {children}
      </div>
    </>
  );
});
