import React from 'react';

import { ZFErrorType } from '@zf/api-types/general';
import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../app-context';
import ErrorBody from './error-body';
import css from './error-page.module.scss';

type Props = {
  error: ZFErrorType | null;
  fullPage?: boolean;
  cardError?: boolean;
};

const ERROR_CODES = [401, 403, 404, 405, 500];

export default function ErrorPage(props: Props) {
  const { error, fullPage = false, cardError } = props;
  const { i18n } = useAppContext();

  let errorImage = null;
  let status = '';

  if (error && error.status && ERROR_CODES.includes(error.status)) {
    status = error.status.toString();

    errorImage = (
      <img src={`https://cdn.zerofriction.co/shared/assets/errorCodes/${status}.svg`} alt={status} title={status} />
    );
  } else {
    errorImage = <img src={'https://cdn.zerofriction.co/shared/assets/general_error.svg'} alt="error" title="error" />;
  }

  if (cardError) {
    return <CardEmptyBody title={i18n.getTranslation('errors.general_card_error')} icon={errorImage} />;
  }

  return (
    <React.Fragment>
      <Center className={css['error-container']} type="both">
        <ErrorBody error={error} showButton={fullPage} />
      </Center>

      {fullPage && <div className={css['error-image']}>{errorImage}</div>}
    </React.Fragment>
  );
}
