import classNames from 'classnames';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { entityAttributeType } from '@zf/api-types/enums';
import { CostComponentType, SliceType, TariffFormulaType } from '@zf/api-types/product';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';
import Center from '@zf/stella-react/src/helpers/Center';
import { formatExpression } from '@zf/utils/src/number';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';
import { notify } from '../../events/notification-events';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';
import CostComponent from './components/cost-component';
import Operator from './components/operator';
import VariableComponent from './components/variable-component';
import css from './formula-builder.module.scss';

export type VariableType = {
  inputType: 'costcomponent' | 'operator' | 'entityAttributeType' | 'fixedValue' | 'variable' | '';
  index: number;
  fixedValue?: string;
  reference: string;
  identifier: string;
};

type State = {
  variables: VariableType[];
  costComponents: CostComponentType[] | null;
  indexBeingDragged: number | null;
  referenceBeingDragged: string;
  identifierBeingDragged: string;
  typeBeingDragged: 'costcomponent' | 'operator' | 'entityAttributeType' | 'fixedValue' | 'variable' | '';
  fixedValue?: number;
};

export type Props = {
  inputParameters?: TariffFormulaType[];
  expression: string;
  index: number;
  setFormula?: (expression: string, inputParameters: TariffFormulaType[]) => void;
  dispatchValue?: (value: Partial<SliceType>) => void;
};

const ARITHMETIC_OPERATORS = ['+', '-', '/', '*', '(', ')'];

export default function FormulaBuilder(props: Props) {
  const { inputParameters = [], index, expression, setFormula, dispatchValue } = props;
  const { i18n, enumReducer } = useAppContext();
  const [deleting, setDeleting] = React.useState(false);

  // Because we continuously dispatch our values the passed expression & inputParameters might get lost
  // so when mounting the component we store these props
  const initialExpression = React.useRef(expression);
  const initialInputParameters = React.useRef(inputParameters);

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, dispatch] = React.useReducer(stateReducer, {
    variables: [],
    costComponents: null,
    indexBeingDragged: null,
    referenceBeingDragged: '',
    identifierBeingDragged: '',
    typeBeingDragged: '',
    fixedValue: undefined
  });

  const { variables, fixedValue, referenceBeingDragged, indexBeingDragged, typeBeingDragged, identifierBeingDragged } =
    state;

  let { costComponents } = state;

  const costComponentResponse = useSuspenseSingleAPI<PagedResponseType<CostComponentType>>({
    request: {
      endpoint: '/cfg/CostComponents'
    }
  });

  const entityAttributes = enumReducer.getEnum<entityAttributeType>('entityAttributeType');

  const expressionPartToParameter = (expressionPart: string) => {
    let variable: VariableType;

    // Avoids typescript complaining, this is already checked in the useEffect but rules of hooks force us to check again here
    if (!costComponents) costComponents = [];

    const parameter = initialInputParameters.current.find((p) => p.identifier === expressionPart);

    if (parameter) {
      // Cost component
      const variableIndex =
        parameter.inputType === 'costcomponent'
          ? costComponents.findIndex((costComponent) => costComponent.id === parameter.inputReference)
          : entityAttributes.findIndex((attribute) => attribute.value === parameter.inputReference);

      if (variableIndex === -1) {
        notify.warning({
          content: i18n.getTranslation('billing_tariff.missing_cost_component', parameter)
        });
      }

      variable = {
        index: variableIndex,
        inputType: parameter.inputType,
        reference: parameter.inputReference,
        identifier: parameter.identifier
      };
    } else {
      // Fixed value
      variable = {
        index: 0,
        fixedValue: expressionPart,
        inputType: 'fixedValue',
        reference: '',
        identifier: ''
      };
    }

    return variable;
  };

  React.useEffect(() => {
    if (
      !costComponents ||
      costComponents.length === 0 ||
      initialExpression.current === '' ||
      !initialExpression.current
    )
      return;

    // If an expression was passed we convert it here
    const separators = ['\\+', '-', '\\(', '\\)', '\\*', '/'];
    const expressionList = initialExpression.current.split(new RegExp(separators.join('|'), 'g'));

    const vars: VariableType[] = [];

    const expressionOperators = initialExpression.current.split(' ').filter((c) => {
      return ARITHMETIC_OPERATORS.includes(c);
    });

    let operatorCounter = 0;

    for (let index = 0; index < expressionList.length; index++) {
      const expressionPart = expressionList[index].trim();

      // If the next item in the array starts with a space we know we have to put the next operator in front
      if (expressionList[index + 1]) {
        if (expressionList[index + 1].startsWith(' ')) {
          if (expressionList[index] !== '' && expressionList[index] !== ' ') {
            // If our current item is a parameter, convert it and push it to the array
            const variable = expressionPartToParameter(expressionPart);
            vars.push(variable);
          }

          // Then we push our corresponding operator
          const operatorIndexToAdd = ARITHMETIC_OPERATORS.findIndex((o) => {
            return o === expressionOperators[operatorCounter];
          });

          vars.push({
            index: operatorIndexToAdd,
            inputType: 'operator',
            reference: '',
            identifier: ''
          });

          // And we increment our operator array index
          operatorCounter++;
        }
      } else if (expressionList[index] !== '') {
        // If our last part is an empty string we are at the end of our array and we know there is still an operator we need to add
        const variable = expressionPartToParameter(expressionPart);
        vars.push(variable);
      } else {
        const operatorIndexToAdd = ARITHMETIC_OPERATORS.findIndex((o) => {
          return o === expressionOperators[operatorCounter];
        });

        vars.push({
          index: operatorIndexToAdd,
          inputType: 'operator',
          reference: '',
          identifier: ''
        });
      }
    }

    dispatch({ variables: vars });
  }, [costComponents]);

  React.useEffect(() => {
    if (!costComponentResponse || !costComponentResponse.result || costComponentResponse instanceof Promise) return;
    dispatch({
      costComponents: [...costComponentResponse.result.data.results]
    });
  }, [costComponentResponse.result]);

  const getVariableName = (variable: VariableType) => {
    let name = '';

    if (costComponents && variable.index !== -1) {
      switch (variable.inputType) {
        case 'costcomponent':
          name = costComponents[variable.index].name;
          break;

        case 'entityAttributeType':
          name = i18n.getTranslation(entityAttributes[variable.index].text);
          break;

        case 'operator':
          name = ARITHMETIC_OPERATORS[variable.index];
          break;

        case 'fixedValue':
          name = variable.fixedValue ? variable.fixedValue : '';
          break;
      }
    }

    return name;
  };

  const getFormaleExpression = () => {
    let formula = '';
    for (let index = 0; index < variables.length; index++) {
      const variable = variables[index];
      const extension = getVariableName(variable);
      formula = index === 0 ? extension : `${formula} ${extension}`;
    }
    return formula;
  };

  const getInputParameters = () => {
    // filtering the bad ones out
    const inputParams: TariffFormulaType[] = variables
      .filter((variable) => variable.inputType === 'costcomponent' || variable.inputType === 'entityAttributeType')
      .map((variable) => {
        return {
          inputType: variable.inputType,
          inputReference: variable.reference,
          identifier: variable.identifier
        };
      });

    return inputParams;
  };

  React.useEffect(() => {
    if (setFormula) {
      setFormula(getFormaleExpression(), getInputParameters());
    } else if (dispatchValue) {
      // @ts-ignore
      dispatchValue({ expression: getFormaleExpression(), inputParameters: getInputParameters() });
    }
  }, [state.variables]);

  if (!costComponents) return <Spinner size="small" />;

  const addToVariables = () => {
    if (typeBeingDragged === 'fixedValue' && fixedValue === undefined) {
      dispatch({ indexBeingDragged: null, typeBeingDragged: '' });
      notify.warning({
        content: i18n.getTranslation('product_config.first_add_value')
      });
      return;
    }

    if (indexBeingDragged !== null && typeBeingDragged !== 'variable' && typeBeingDragged !== '') {
      const clone = [...variables];
      clone.push({
        inputType: typeBeingDragged,
        index: indexBeingDragged,
        fixedValue: typeBeingDragged === 'fixedValue' ? fixedValue?.toString() : undefined,
        reference: referenceBeingDragged,
        identifier: identifierBeingDragged
      });
      dispatch({
        variables: clone,
        typeBeingDragged: 'variable',
        indexBeingDragged: clone.length - 1
      });
    }
  };

  const addToVariablesClick = (
    inputType: 'costcomponent' | 'operator' | 'entityAttributeType' | 'fixedValue',
    index: number,
    reference?: string,
    identifier?: string
  ) => {
    if (!reference) reference = '';
    if (!identifier) identifier = '';

    if (inputType === 'fixedValue' && fixedValue === undefined) {
      notify.warning({
        content: i18n.getTranslation('product_config.first_add_value')
      });
      return;
    }

    const clone = [...variables];
    clone.push({
      inputType: inputType,
      index: index,
      fixedValue: inputType === 'fixedValue' ? fixedValue?.toString() : '',
      reference,
      identifier
    });
    dispatch({ variables: clone, indexBeingDragged: null });
  };

  const onDragEnter = (index: number) => {
    if (typeBeingDragged === 'variable' && indexBeingDragged !== null && index !== indexBeingDragged) {
      const clone = [...variables];
      const repositionedVariable = variables[indexBeingDragged];
      clone.splice(indexBeingDragged, 1);
      clone.splice(index, 0, repositionedVariable);
      dispatch({ variables: clone, indexBeingDragged: index });
    }
  };

  const deleteVariable = () => {
    if (typeBeingDragged === 'variable' && indexBeingDragged !== null) {
      const clone = [...variables];
      clone.splice(indexBeingDragged, 1);
      dispatch({
        variables: clone,
        indexBeingDragged: null,
        typeBeingDragged: ''
      });
    }
  };

  return (
    <div className={css['formulae-builder']}>
      <div
        id="formula-wrapper"
        className={css['formula-wrapper']}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={addToVariables}
        role="button"
        tabIndex={-1}
      >
        <Paragraph className={css['formula-text']}>{formatExpression(getFormaleExpression(), i18n.culture)}</Paragraph>
        <div id="droppeable" className={css['droppeable']}>
          {variables.map((variable, index) => {
            return (
              <VariableComponent
                onDragEnter={() => onDragEnter(index)}
                onDragStart={() =>
                  dispatch({
                    indexBeingDragged: index,
                    typeBeingDragged: 'variable'
                  })
                }
                onDragEnd={() => dispatch({ indexBeingDragged: null, typeBeingDragged: '' })}
                variable={variable}
                text={getVariableName(variable)}
                className={variable.inputType === 'operator' ? css['operator'] : css['cost-component']}
                key={`variable-${index}`}
              />
            );
          })}
        </div>
      </div>
      <div className={css['operators']}>
        {ARITHMETIC_OPERATORS.map((operator, index) => {
          return (
            <Operator
              key={`operator-${index}`}
              operator={operator}
              onDragStart={() =>
                dispatch({
                  indexBeingDragged: index,
                  typeBeingDragged: 'operator'
                })
              }
              onDragEnd={() => dispatch({ indexBeingDragged: null, typeBeingDragged: '' })}
              onClick={() => addToVariablesClick('operator', index)}
            />
          );
        })}
        <div
          className={classNames(css['delete'], css['operator'], {
            [css['active']]: deleting
          })}
          onDragOver={(e) => e.preventDefault()}
          onDrop={deleteVariable}
          onDragEnter={() => setDeleting(true)}
          onDragLeave={() => setDeleting(false)}
          onClick={() => dispatch({ variables: [] })}
          onMouseEnter={() => setDeleting(true)}
          onMouseLeave={() => setDeleting(false)}
          role="button"
          tabIndex={-1}
          onKeyDown={() => dispatch({ variables: [] })}
        >
          <Center type="both">
            <Icon type="trashcan" color={typeBeingDragged === 'variable' || deleting ? true : false} />
          </Center>
        </div>
      </div>
      <div className={css['cost-components']}>
        <CostComponent
          index={index}
          costComponent={{
            name: i18n.getTranslation('product_config.fixed_value'),
            id: 'fixed_value',
            description: i18n.getTranslation('product_config.fixed_value_desc')
          }}
          fixedValue={fixedValue}
          setFixedValue={(val) => dispatch({ fixedValue: val as number })}
          onDragStart={() => dispatch({ typeBeingDragged: 'fixedValue', indexBeingDragged: 0 })}
          onDragEnd={() => dispatch({ typeBeingDragged: '', indexBeingDragged: null })}
          onClick={() => addToVariablesClick('fixedValue', 0)}
        />
        {costComponents.map((costComponent, index) => {
          return (
            <CostComponent
              index={index}
              key={`costComponent${index}`}
              costComponent={costComponent}
              onDragStart={() =>
                dispatch({
                  indexBeingDragged: index,
                  typeBeingDragged: 'costcomponent',
                  referenceBeingDragged: costComponent.id,
                  identifierBeingDragged: costComponent.name
                })
              }
              onDragEnd={() =>
                dispatch({
                  indexBeingDragged: null,
                  typeBeingDragged: '',
                  referenceBeingDragged: '',
                  identifierBeingDragged: ''
                })
              }
              onClick={() => addToVariablesClick('costcomponent', index, costComponent.id, costComponent.name)}
            />
          );
        })}
      </div>
    </div>
  );
}
