if (!process.env.REACT_APP_API_ENDPOINT) {
  throw new Error('No API_ENDPOINT environment variable present!');
}

if (!process.env.REACT_APP_IDENTITY_ENDPOINT) {
  throw new Error('No IDENTITY_ENDPOINT environment variable present!');
}

export const API_ENDPOINT: string = sessionStorage.getItem('LOCAL_ENDPOINT')
  ? sessionStorage.getItem('LOCAL_ENDPOINT') + '/api'
  : process.env.REACT_APP_API_ENDPOINT + '/api';

export const IDENTITY_ENDPOINT: string = process.env.REACT_APP_IDENTITY_ENDPOINT + '/api';

// Cleanup of expired cache items in ms
export const CACHE_CLEANUP_INTERVAL: number = 60 * 1000;

// Default Cache TTL in seconds
export const DEFAULT_CACHE_TTL = 10;

// TTL of cache items in seconds
// Should only be defined for endpoints that require instant responses or long cache times
export const CACHE_TTL: Record<string, number> = {
  '/tenant/known': 604800, // 1 week
  '/tenant/users': 604800, // 1 week
  '/sys/enumerations': 3600, // 1 hour
  '/activity/comments': 3 // 1 hour
};

const IDENTITY_PATHS = ['/tenant', '/userauth'];
const API_PATHS = [
  '/md',
  '/odata',
  '/sys/enumerations',
  '/cfg',
  '/activity',
  '/bill',
  '/att',
  '/logs',
  '/me',
  '/sys',
  '/insights',
  '/int',
  '/fct',
  '/reg'
];

export function getApiURL(endpoint: string, mock?: boolean): string {
  endpoint = endpoint.toLowerCase();

  if (mock) return 'http://localhost:3000';

  if (endpoint.startsWith('http')) return '';

  for (const apiPath of API_PATHS) {
    if (endpoint.startsWith(apiPath)) return API_ENDPOINT;
  }

  for (const identityPath of IDENTITY_PATHS) {
    if (endpoint.startsWith(identityPath)) return IDENTITY_ENDPOINT;
  }

  throw new Error('Unknown API Endpoint!');
}
