import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer } from 'recharts';

import { aggregationFrequency } from '@zf/api-types/enums';
import { ServiceConsumptionGraphValueType } from '@zf/api-types/graph';
import { ChartDataType } from '@zf/stella-react/src/atoms/Chart/types';
import { groupBy } from '@zf/utils/src/array';
import { colors } from '@zf/utils/src/color';
import { formatDateTime } from '@zf/utils/src/date';
import { formatDecimal, roundNumber } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../app-context/app-context';
import useAxis from '../../../../../app-context/hooks/use-axis';
import { ZFGraphTooltip } from '../../../../../components/graph/ZFGraphTooltip';
import { determineAxisId, sortLongestRangeFirst } from '../../../../../utils/graph';
import { getColorByUtilityType } from '../../../../../utils/meter';
import useRegularGraph from '../hooks/useRegularGraph';
import useServiceConsumptions from '../hooks/useServiceConsumptions';
import css from './graph.module.scss';

export type GraphProps = {
  domainStart: number;
  domainEnd: number;
};

export function Graph(props: GraphProps) {
  const { domainStart, domainEnd } = props;
  const { i18n, enumReducer } = useAppContext();

  moment.locale(i18n.lang);

  const { graphValues, groupByPeriod, selectedFilterTypes } = useRegularGraph();
  const { isLoading } = useServiceConsumptions();

  // This fixes the bar chart range scaling, first array length defines the range slicing
  graphValues.sort(sortLongestRangeFirst);

  const groupedByUom = groupBy(graphValues, 'uom');

  // (Same units for the compared values)
  const selectedUnits: string[] = Object.keys(groupedByUom).map((key) => {
    return groupedByUom[key][0].uom;
  });

  // Grouped only
  const getChartData = () => {
    if (isLoading) {
      return [];
    }

    // When switching from "none" groupByPeriod has changed but our new values are still awaited from the api,
    // the groupByPeriod on our value object can be used to prevent render blocking
    if (graphValues[0] && graphValues[0].groupByPeriod === aggregationFrequency.none) {
      return [];
    }

    const chartData: ChartDataType[] = [];

    if (graphValues.length > 0 && groupByPeriod !== aggregationFrequency.none) {
      graphValues.forEach((gv) => {
        Object.keys(gv.groupedData).forEach((key) => {
          const data = gv.groupedData[key];

          // Avoid duplicate time entries
          if (chartData.some((be) => be.time === key)) {
            const matchingEntry = chartData.find((be) => be.time === key);

            if (matchingEntry) {
              if (typeof data.value !== 'undefined') {
                matchingEntry[gv.externalIdentifier] = data.value;
              }

              if (typeof data.estimatedAnnualVolume !== 'undefined') {
                matchingEntry[gv.externalIdentifier + i18n.getTranslation('location.estimation')] = roundNumber(
                  data.estimatedAnnualVolume
                );
              }
            }
          } else {
            // Create new entry
            let barEntry: Record<string, number> = {};

            // @ts-ignore
            barEntry.time = key;

            if (typeof data.value !== 'undefined') {
              barEntry[gv.externalIdentifier] = data.value;
            }

            if (typeof data.estimatedAnnualVolume !== 'undefined') {
              barEntry[gv.externalIdentifier + i18n.getTranslation('location.estimation')] = roundNumber(
                data.estimatedAnnualVolume
              );
            }
            chartData.push(barEntry);
          }
        });
      });
    }

    return chartData;
  };

  const renderChartContent = () => {
    const charts: JSX.Element[] = [];

    if (!isLoading) {
      // Consumptions
      if (groupByPeriod !== aggregationFrequency.none) {
        selectedFilterTypes.forEach((selectedFilterType) => {
          const unit = enumReducer.getTranslation('unitOfMeasure', selectedFilterType.unitOfMeasure);
          const externalId = selectedFilterType.externalIdentifier;

          charts.push(
            <Bar
              key={externalId}
              name={`${enumReducer.getTranslation('utilityType', selectedFilterType.utilityType)}`}
              stackId={externalId}
              yAxisId={groupByPeriod}
              dataKey={externalId}
              barSize={50}
              fill={getColorByUtilityType(selectedFilterType.utilityType)}
              radius={[5, 5, 0, 0]}
              unit={` ${unit}`}
            />
          );

          charts.push(
            <Bar
              key={externalId + i18n.getTranslation('location.estimation')}
              name={`${enumReducer.getTranslation('utilityType', selectedFilterType.utilityType)} ${i18n.getTranslation(
                'location.estimation'
              )}`}
              yAxisId={groupByPeriod}
              dataKey={externalId + i18n.getTranslation('location.estimation')}
              barSize={50}
              fill={getColorByUtilityType(selectedFilterType.utilityType, true)}
              radius={[5, 5, 0, 0]}
              unit={` ${unit}`}
            />
          );
        });
      } else {
        // Measurements
        selectedUnits.forEach((uom) => {
          groupedByUom[uom].forEach((filter: ServiceConsumptionGraphValueType) => {
            const unit = enumReducer.getTranslation('unitOfMeasure', filter.uom);

            charts.push(
              <Line
                key={determineAxisId(filter.uom, filter.description, filter.type)}
                yAxisId={uom}
                type="linear"
                dataKey="value"
                data={filter.rawData}
                name={filter.description}
                dot={false}
                unit={`${unit}`}
                stroke={getColorByUtilityType(filter.utilityType)}
              />
            );
          });
        });
      }
    }

    return charts;
  };

  const axises = useAxis(groupByPeriod, domainStart, domainEnd, selectedUnits, 'time');

  const tooltipLabelFormatter = (value: string) => {
    switch (groupByPeriod) {
      case aggregationFrequency.yearly:
        return `${moment(value).year()}`;
      case aggregationFrequency.monthly:
        return moment(value).format('MMM/YYYY');
      case aggregationFrequency.none:
        return formatDateTime(value);
      case aggregationFrequency.daily:
        return moment(value).format('DD/MMM/YYYY');
    }
  };

  const valueFormatter = (value: string) => {
    return formatDecimal(parseFloat(value), i18n.culture);
  };

  return (
    <div key="graph-wrapper" className={classNames(css['graph-wrapper'])}>
      <ResponsiveContainer key="responsive-container" className={css['graph-container']} width="99%" height="100%">
        <ComposedChart key="composed-chart" data={getChartData()} margin={{ top: 10, left: 25, right: 25 }}>
          <CartesianGrid key="grid-lines" strokeDasharray="3 3" vertical={false} stroke={colors['silver-400']} />
          {axises}
          {renderChartContent()}
          <ZFGraphTooltip key="tooltip" labelFormatter={tooltipLabelFormatter} formatter={valueFormatter} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
