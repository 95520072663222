import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { AttachmentType } from '@zf/api-types/attachment';
import { entitySubjectType } from '@zf/api-types/enums';
import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { IncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { PropertyGroupBillingPeriodPagedResponseType } from '@zf/api-types/property-group-billing-period';
import useDialog from '@zf/hooks/src/useDialog';

import AddEditIncomingInvoiceDialog from '../../../../../actions/property-group/add-edit-incoming-invoice-dialog';
import DeleteIncomingInvoiceDialog from '../../../../../actions/property-group/delete-incoming-invoice-dialog';
import { useAppContext } from '../../../../../app-context/app-context';
import DeleteIcon from '../../../../../components/Icon/DeleteIcon';
import EditIcon from '../../../../../components/Icon/EditIcon';
import { dialog } from '../../../../../events/dialog-events';
import useCreateRequest from '../../../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../../../hooks/useInfiniAPI';
import useLocalRefresh from '../../../../../hooks/useLocalRefresh';
import { formatDate } from '@zf/utils/src/date';
import { METHODS, sendRequest } from '../../../../../utils/request';
import useBillingData from './use-billing-data';

type IncomingInvoiceRowType = {
  __id: string;
  invoiceNumber: string;
  startDate: string;
  endDate: string;
  edit: JSX.Element;
  delete: JSX.Element;
};

export default function useIncomingInvoices(propertyGroupId: string, components: IncomingInvoiceComponentType[]) {
  const { i18n, tenantReducer } = useAppContext();
  const { timeStamp, refresh } = useLocalRefresh();
  const { onSubmit, clickRef, validationRef } = useDialog();
  const {
    incomingInvoices,
    billingPeriods,
    updateBillingPeriodsInState,
    deleteIncomingInvoice,
    updateIncomingInvoice
  } = useBillingData();

  React.useEffect(() => {
    refresh();
  }, [incomingInvoices]);

  const { request, selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = useCreateRequest(
    '/bill/IncomingInvoices',
    {
      propertyGroupId: propertyGroupId,
      timeStamp: timeStamp
    }
  );

  const openEditDialog = async (invoice: IncomingInvoiceType) => {
    const periods = (
      await sendRequest<PropertyGroupBillingPeriodPagedResponseType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/PropertyGroupBillingPeriods/${propertyGroupId}`,
          query: {
            incomingInvoiceId: invoice.id
          }
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      })
    ).data.results;

    const matchedPeriod = periods[0] ? periods[0] : null;

    const attachments = (
      await sendRequest<PagedResponseType<AttachmentType>>({
        request: {
          method: METHODS.GET,
          endpoint: `/att/attachments/${entitySubjectType.incomingInvoice}/${invoice.id}`
        },
        tenantReducer: tenantReducer,
        lang: i18n.lang
      })
    ).data.results;

    const attachment = attachments[0] ? attachments[0] : undefined;

    dialog.right({
      title: i18n.getTranslation('property_groups.tabs.billing.edit_incoming_invoice', {
        invoiceNum: invoice.invoiceNumber
      }),
      icon: 'sign',
      content: (
        <AddEditIncomingInvoiceDialog
          ref={clickRef}
          incomingInvoice={invoice}
          matchedPeriod={matchedPeriod}
          propertyGroupId={propertyGroupId}
          billingPeriods={billingPeriods.filter((bp) => {
            return !bp.locked;
          })}
          components={components}
          attachment={attachment}
          validationRef={validationRef}
          updateBillingPeriodsInState={updateBillingPeriodsInState}
          updateIncomingInvoice={updateIncomingInvoice}
        />
      ),
      buttonPositive: i18n.getTranslation('general.update'),
      onSubmit
    });
  };

  const openDeleteDialog = (invoice: IncomingInvoiceType) => {
    dialog.normal({
      title: i18n.getTranslation('property_groups.tabs.billing.delete_billing_period_header'),
      icon: 'trashcan',
      content: (
        <DeleteIncomingInvoiceDialog
          ref={clickRef}
          incomingInvoice={invoice}
          deleteIncomingInvoiceInState={deleteIncomingInvoice}
        />
      ),
      buttonPositive: i18n.getTranslation('general.delete'),
      onSubmit,
      type: 'danger'
    });
  };

  const processRecord = (invoice: IncomingInvoiceType): IncomingInvoiceRowType => {
    return {
      __id: invoice.id,
      invoiceNumber: invoice.invoiceNumber,
      startDate: formatDate(invoice.startDate),
      endDate: formatDate(invoice.endDate),
      edit: (
        <EditIcon
          id={`incoming_invoice.edit.index-${invoice.id}`}
          tooltipFor="invoice-period-table-tip"
          onClick={() => openEditDialog(invoice)}
        />
      ),
      delete: (
        <DeleteIcon
          id={`incoming_invoice.delete.index-${invoice.id}`}
          tooltipFor="invoice-period-table-tip"
          onClick={() => openDeleteDialog(invoice)}
        />
      )
    };
  };

  const { loading, error, rows, sortableFields, setStopIndex } = useInfiniAPI<
    IncomingInvoiceType,
    IncomingInvoiceRowType
  >({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord
  });

  return {
    selectedIds,
    loading,
    error,
    rows,
    sortableFields,
    sortBy,
    sortDirection,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
}
