import React, { useEffect, useReducer } from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { billingCalculationType, utilityType } from '@zf/api-types/enums';
import { BillingItemType } from '@zf/api-types/product';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useDebounce from '@zf/hooks/src/useDebounce';
import { Label } from '@zf/stella-react/src/atoms/Label';

import FilterTag from '../../../components/Filters/filter-tag';
import EnumFilterSelect from '../../../components/input/EnumFilterSelect';
import InputField from '../../../components/input/InputField';
import { useStore } from '../../../hooks/useStore';
import { METHODS } from '../../../utils/request';
import css from './use-billing-items-filter.module.scss';

type State = {
  name: string;
  type: billingCalculationType;
  utilityType: utilityType;
};

export default function useBillingItemsFilter(
  setFilteredItems: (newFilteredItems: BillingItemType[]) => void,
  billingItemName?: string
) {
  const { applicationStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation, sendRequest } = applicationStore;

  const reducer = createStateReducer<State, Partial<State>>();
  const [filters, setFilters] = useReducer(reducer, {
    name: billingItemName || '',
    type: '' as billingCalculationType,
    utilityType: '' as utilityType
  });

  const setDebounceCallback = useDebounce(200);

  const getFilteredItems = async () => {
    return (
      await sendRequest<PagedResponseType<BillingItemType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/BillingItems',
          query: {
            flexSearch: filters.name,
            calculationType: filters.type,
            utilityType: filters.utilityType
          }
        }
      })
    ).data.results;
  };

  useEffect(() => {
    setDebounceCallback(() => getFilteredItems().then((filtered) => setFilteredItems(filtered)));
  }, [filters]);

  const filterInputs = [
    <Label key="lbl_name">{getTranslation('general.name')}</Label>,
    <InputField
      id="name-filter"
      key="name-filter"
      value={filters.name}
      onChange={(val) => setFilters({ name: val })}
    />,
    <EnumFilterSelect
      id="type-filter"
      key="type-filter"
      title={getTranslation('general.type')}
      options={getEnum<billingCalculationType>('billingCalculationType')}
      values={filters.type ? [filters.type] : []}
      setValue={(value) => setFilters({ type: (value[0] as billingCalculationType) || '' })}
    />,
    <EnumFilterSelect
      id="utility-type-filter"
      key="utility-type-filter"
      title={getTranslation('meter.utility_type')}
      options={getEnum<utilityType>('utilityType')}
      values={filters.utilityType ? [filters.utilityType] : []}
      setValue={(value) => setFilters({ utilityType: (value[0] as utilityType) || '' })}
    />
  ];

  const tags: React.ReactNode[] = [];

  if (filters.name) {
    tags.push(
      <FilterTag key="name" className={css['tag']} value={filters.name} removeFilter={() => setFilters({ name: '' })} />
    );
  }

  if (filters.type) {
    tags.push(
      <FilterTag
        key="type"
        className={css['tag']}
        value={getEnumTranslation('billingCalculationType', filters.type)}
        removeFilter={() => setFilters({ type: '' as billingCalculationType })}
      />
    );
  }

  if (filters.utilityType) {
    tags.push(
      <FilterTag
        key="utility-type"
        className={css['tag']}
        value={getEnumTranslation('utilityType', filters.utilityType)}
        removeFilter={() => setFilters({ utilityType: '' as utilityType })}
      />
    );
  }

  const resetFilters = () => {
    setFilters({ name: '', type: '' as billingCalculationType, utilityType: '' as utilityType });
  };

  return {
    filterInputs,
    tags,
    resetFilters
  };
}
