import { observer } from 'mobx-react';
import React from 'react';

import BreadCrumb from '../../../../../components/BreadCrumb/BreadCrumb';
import Crumb from '../../../../../components/BreadCrumb/Crumb';
import CrumbDivider from '../../../../../components/BreadCrumb/CrumbDivider';
import CrumbHeader from '../../../../../components/Header/CrumbHeader/CrumbHeader';
import { useStore } from '../../../../../hooks/useStore';

const InvoiceCrumbHeader = observer(() => {
  const { invoiceStore, applicationStore } = useStore();

  const invoice = invoiceStore.selectedInvoice.invoice;

  return (
    <CrumbHeader
      breadcrumb={
        <BreadCrumb>
          <Crumb
            icon="list"
            text={applicationStore.getTranslation('invoice.invoices')}
            url={`${applicationStore.rootUrl}/invoices`}
          />

          <CrumbDivider />

          <Crumb icon="invoice" text={invoice.invoiceNum} />
        </BreadCrumb>
      }
    />
  );
});

export default InvoiceCrumbHeader;
