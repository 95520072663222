import { MultiValue } from 'design-system/ComponentSets';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { BillingTariffInputType, SliceType } from '@zf/api-types/product';

import { useStore } from '../../../../../../hooks/useStore';
import Slice from './slice';

type Props = {
  initialSlices?: SliceType[];
  setParametersValue: (key: any, value: any) => void;
};

const VolumeCalculationParameters = (props: Props) => {
  const { setParametersValue, initialSlices } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const initialValue = {
    from: 0,
    formulaBased: false,
    inputParameters: [] as BillingTariffInputType[],
    unitTariff: 0,
    expression: ''
  };

  return (
    <MultiValue
      id="volume-tiers"
      title={getTranslation('billing_tariff.add_tier')}
      initialValues={initialSlices || [{ ...initialValue, from: 0 }]}
      intialNodeValue={initialValue}
      onChange={(slices) => setParametersValue('slices', slices)}
    >
      {({ index, value, dispatchValue }) => {
        return <Slice key={index} index={index} value={value} dispatchValue={dispatchValue} />;
      }}
    </MultiValue>
  );
};

export default observer(VolumeCalculationParameters);
