import Interweave from 'interweave';
import React from 'react';

import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatPeriod } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { lockBillingPeriod, triggerBilling } from './billing-periods';

type Props = {
  selectedBillingPeriod: PropertyGroupBillingPeriodType;
  propertyGroupId: string;
  updateBillingPeriod: (period: PropertyGroupBillingPeriodType) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { propertyGroupId, selectedBillingPeriod, updateBillingPeriod } = props;

  const { i18n, tenantReducer } = useAppContext();

  const lock = async () => {
    try {
      const updatedBillingPeriod = await lockBillingPeriod(
        propertyGroupId,
        selectedBillingPeriod.id,
        tenantReducer,
        i18n.lang
      );

      updateBillingPeriod(updatedBillingPeriod);

      notify.success({
        content: i18n.getTranslation('property_groups.tabs.billing.lock_success')
      });
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.lock_fail'),
        error: e
      });
    }
  };

  const createInvoices = async () => {
    try {
      const updatedBillingPeriod = await triggerBilling(
        propertyGroupId,
        selectedBillingPeriod.id,
        tenantReducer,
        i18n.lang
      );

      updateBillingPeriod(updatedBillingPeriod);

      notify.success({
        content: i18n.getTranslation('property_groups.tabs.billing.create_invoices_success')
      });
    } catch (e) {
      notify.error({
        content: i18n.getTranslation('property_groups.tabs.billing.create_invoices_fail'),
        error: e
      });
    }
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (!selectedBillingPeriod.locked) {
        await lock();
      }

      await createInvoices();
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={i18n.getTranslation('property_groups.tabs.billing.create_invoices_paragraph', {
          period: formatPeriod(selectedBillingPeriod.startDate, selectedBillingPeriod.endDate)
        })}
      />
    </Paragraph>
  );
});
