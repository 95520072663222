import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { countryCode } from '@zf/api-types/enums';

import useBrowserTitle from '../../app-context/hooks/use-browser-title';
import ZFRouter from '../../components/Router/ZFRouter';
import { useStore } from '../../hooks/useStore';
import UrlParamsResponseInterface from '../meter-install/wizard/UrlParamsResponseInterface';
import Route from '../route';
import IssuesListPage from './issues/list-page/IssuesListPage';
import Meter from './meter/detail-page/Meter';
import MeterListpage from './meter/list-page/MeterListpage';
import AddMeter from './meter/wizard/AddMeter';
import PrepaymentDeviceListPage from './prepayment-device/list-page/PrepaymentDeviceListPage';
import PrepaymentDevice from './prepayment-device/PrepaymentDevice';

type Props = {
  location: Location;
};

const Devices = (props: Props) => {
  const { location } = props;
  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { organisationCfg, models, initMeterStore } = meterStore;

  useBrowserTitle(getTranslation('meter.meters'));

  useEffect(() => {
    initMeterStore();
  }, []);

  if (!models || !organisationCfg) return null;

  return (
    <ZFRouter>
      <Route path="meters" Component={MeterListpage} location={location} models={models} />
      <Route path="meters/:meterID/*" Component={Meter} />
      <Route path="issues" Component={IssuesListPage} />

      {organisationCfg.address?.country === countryCode.gbr && (
        <>
          <Route path="prepayment-devices" Component={PrepaymentDeviceListPage} location={location} models={models} />
          <Route path="prepayment-devices/:prepaymentDeviceID/*" Component={PrepaymentDevice} />
        </>
      )}

      <Route path="add" Component={AddMeter} />
      <Route path="install/:useCase/:meterOrlocationOrpropertyID" Component={UrlParamsResponseInterface} />
    </ZFRouter>
  );
};

export default observer(Devices);
