import { observer } from 'mobx-react';
import React from 'react';

import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { MoveRequestType } from '@zf/api-types/master-data/move-request';

import useBrowserTitle from '../../../app-context/hooks/use-browser-title';
import { NavigatorType } from '../../../app-context/hooks/use-selection-navigator';
import RouteWrapper from '../../../components/RouteWrapper';
import GenericTabContainer from '../../../components/Tab/GenericTabContainer';
import { useStore } from '../../../hooks/useStore';
import { getTaskText } from '../../../utils/task';
import Route from '../../route';
import TaskDetail from './detail/TaskDetail';
import TaskActions from './TaskActions';

type Props = {
  task: MoveRequestType;
  status?: JSX.Element;
  navigator?: NavigatorType;
  navigate: (url: string) => void;
  setTask: (updatedTask: MoveRequestType) => void;
};

const TaskTabContainer = (props: Props) => {
  const { status, task, navigator, setTask, navigate } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const headerText = getTaskText(getTranslation, getEnumTranslation, task);

  useBrowserTitle(`${getTranslation('tasks.task')} - ${headerText}`);

  return (
    <GenericTabContainer
      actions={<TaskActions task={task} setTask={setTask} />}
      navigator={navigator}
      entityId={headerText}
      status={status}
      entityType={routedEntitySubjectType.tasks}
      navigate={navigate}
      iconName="task"
      tabItems={[
        {
          id: 'detail',
          title: getTranslation('nav_detail.detail'),
          isDefault: true,
          isScrollable: false
        }
      ]}
      routes={
        <RouteWrapper default>
          <Route Component={TaskDetail} path="detail" task={task} default />
        </RouteWrapper>
      }
    />
  );
};

export default observer(TaskTabContainer);
