import classNames from 'classnames';
import React from 'react';

import css from './columns-container.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  extraPadding?: boolean;
};

export default function ColumnsContainer(props: Props) {
  const { children, className, style, extraPadding = true } = props;

  return (
    <div
      className={classNames(css['columns-container'], { [css['extra-padding']]: extraPadding }, className)}
      style={style}
    >
      {children}
    </div>
  );
}
