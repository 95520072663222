import React, { useEffect } from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';
import Center from '@zf/stella-react/src/helpers/Center';
import { formatIBAN } from '@zf/utils/src/iban';

import { useAppContext } from '../../app-context/app-context';
import IconParagraph from '../../components/Icon/IconParagraph';
import SimpleDropdown from '../../components/Lang/SimpleDropdown';
import { DANGER } from '../../constants/color';
import useSuspenseSingleAPI from '../../hooks/useSuspenseSingleAPI';

type Props = {
  customerId: string;
  iban: string;
  setIban: (val: string[]) => void;
  infoAlert?: string | null;
  infoAlertV2?: JSX.Element
};

export default function BankAccountRefundSection(props: Props) {
  const { i18n } = useAppContext();
  const {
    customerId,
    iban,
    setIban,
    infoAlertV2,
    infoAlert = i18n.getTranslation('actions.outgoing_mutation.no_bank_accounts')
  } = props;

  const customerResponse = useSuspenseSingleAPI<CustomerType>({
    request: {
      endpoint: `/md/Customers/${customerId}`
    }
  });

  if (!customerResponse.result) return null;

  const customerBankAccounts = customerResponse.result.data.bankAccountIbans.map((m) => {
    return { value: m.iban, text: formatIBAN(m.iban) };
  });

  return (
    <>
      {customerBankAccounts.length > 0 && (
        <SimpleDropdown
          id="iban"
          onChange={setIban}
          values={customerBankAccounts}
          selectedValues={[iban]}
          error={!iban}
          clear
        />
      )}

      {customerBankAccounts.length === 0 && infoAlert && (
        <Center type="horizontal">
          <IconParagraph iconType="alert-circle" color={DANGER}>
            {infoAlert}
          </IconParagraph>
        </Center>
      )}

      {customerBankAccounts.length === 0 && !infoAlert && infoAlertV2 && (
        <Center type="horizontal" className='info-alert'>{infoAlertV2}</Center>
      )}
    </>
  );
}
