import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';
import { TabItemType } from '@zf/stella-react/src/atoms/Tabs/types';

import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { CrumbHeader } from '../../components/Header';
import ZFRouter from '../../components/Router/ZFRouter';
import { SuspenseSpinner } from '../../components/suspense';
import Route from '../route';
import ConsumptionGroupsTab from './consumption-groups/consumption-groups-tab';
import ModelsTab from './models/models-tab';
import ValidationTabWrapper from './validation/ValidationTabWrapper';

type Props = {
  navigate: (href: string) => void;
};

const MeterConfig = (props: Props) => {
  const { navigate } = props;

  const { applicationStore, organisationStore } = useStore();
  const { getOrganisationConfig } = organisationStore.organisationService;
  const { getTranslation, tenantReducer } = applicationStore;

  const [organisationCfg, setOrganisationCfg] = useState<OrganizationConfigType | null>(null);

  const initState = async () => {
    const organisationCfg = await getOrganisationConfig(
      tenantReducer.organization?.organizationId || '',
      tenantReducer.tenant?.id || ''
    );

    return organisationCfg;
  };

  useEffect(() => {
    initState().then((res) => setOrganisationCfg(res));
  }, []);

  const setActiveTab = (tabId: string) => navigate(tabId);

  const splitUrl = window.location.href.split('/');

  const tabItems: TabItemType[] = [
    {
      title: getTranslation('nav.devices'),
      id: 'models',
      isDefault: true
    },
    {
      title: getTranslation('consumption_groups'),
      id: 'consumptionGroups'
    }
  ];

  if (organisationCfg?.features.meteringValidationEnabled) {
    tabItems.push({
      title: getTranslation('meter_config.validation'),
      id: 'validation'
    });
  }

  const modelsTab = () => (
    <SuspenseSpinner>
      <ModelsTab />
    </SuspenseSpinner>
  );

  const consumptionGroupsTab = () => (
    <SuspenseSpinner>
      <ConsumptionGroupsTab />
    </SuspenseSpinner>
  );

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="settings" text={getTranslation('meter_config.meter_config')} />
          </BreadCrumb>
        }
      />
      <TabContainer
        onSelectTab={setActiveTab}
        entityId={getTranslation('nav.config_metering')}
        selectedTab={splitUrl[splitUrl.length - 1]}
        tabItems={tabItems}
      >
        <ZFRouter>
          <Route Component={modelsTab} path="models" default />
          <Route Component={consumptionGroupsTab} path="consumptionGroups" />
          <Route Component={ValidationTabWrapper} path="validation" />
        </ZFRouter>
      </TabContainer>
    </>
  );
};

export default observer(MeterConfig);
