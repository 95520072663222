import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from '../../../hooks/useStore';
import AddMeasurementListItem from '../meters/AddMeasurementListItem';

const AddMeasurementForCustomerListItem = () => {
  const { customerStore } = useStore();
  const { pageActionPermissions, customerLocationId } = customerStore;

  if (!pageActionPermissions.mayAddMeasurement) return null;

  return <AddMeasurementListItem locationId={customerLocationId} />;
};

export default observer(AddMeasurementForCustomerListItem);
