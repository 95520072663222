import clone from 'clone';
import CommitSection from 'components/config/commit-section';
import { Paragraph } from 'design-system/Foundation';
import { notify } from 'events/notification-events';
import { useStore } from 'hooks/useStore';
import Interweave from 'interweave';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { METHODS } from 'utils/request';

import {
  BaseMeteringValidationRule,
  MeteringValidationParameters,
  MeteringValidationRuleType
} from '@zf/api-types/meter-config';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardsContainer } from '@zf/stella-react/src/atoms/Card';

import ValidationRulesCard from './validation-rules-card';
import css from './validation-tab.module.scss';

type Props = {
  meteringValidationParameters: MeteringValidationParameters;
};

const ValidationTab = (props: Props) => {
  const { meteringValidationParameters } = props;
  const { applicationStore, configStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;
  const { setMeteringValidationParameters } = configStore;

  const { values, backup, isDirty, setValue, restoreValues, submitFactory } =
    useValidator<MeteringValidationParameters>({
      initialValues: {
        ...meteringValidationParameters
      }
    });

  const handleSave = submitFactory(async () => {
    try {
      if (JSON.stringify(values) !== JSON.stringify(backup)) {
        const updatedMeteringValidationParameters = (
          await sendRequest<MeteringValidationParameters>({
            request: {
              method: METHODS.POST,
              endpoint: '/cfg/Parameters/metering',
              data: values
            }
          })
        ).data;

        // @ts-ignore REMOVE WHEN API UPDATED
        delete updatedMeteringValidationParameters.validations;
        setValue(updatedMeteringValidationParameters, false, true);
        setMeteringValidationParameters(updatedMeteringValidationParameters);

        notify.success({
          content: getTranslation('meter_config.update_validations_success')
        });
      }
    } catch (error) {
      notify.error({
        content: getTranslation('meter_config.update_validations_fail'),
        error
      });
    }
  });

  const handleCancel = () => restoreValues();

  const setValidationRule = (type: MeteringValidationRuleType, val: Partial<BaseMeteringValidationRule>) => {
    if (values.validationRules) {
      const matchingRuleIndex = values.validationRules.findIndex((r) => r.type === type);

      if (matchingRuleIndex && matchingRuleIndex !== -1) {
        let clonedRules = clone(values.validationRules);
        clonedRules[matchingRuleIndex] = { ...clonedRules[matchingRuleIndex], ...val };
        setValue({ validationRules: clonedRules });
      }
    }
  };

  return (
    <>
      <CommitSection handleCancel={handleCancel} handleSave={handleSave} isDirty={isDirty} />
      <CardsContainer>
        <Card width="3" id="validation-description-card" className={css['validation-description-card']}>
          <Paragraph>
            <Interweave content={getTranslation('meter_config.validation_recommendation')} />
          </Paragraph>
        </Card>

        <ValidationRulesCard validationRules={values.validationRules || []} setValidationRule={setValidationRule} />
      </CardsContainer>
    </>
  );
};

export default observer(ValidationTab);
