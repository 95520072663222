import { useMsal } from '@azure/msal-react';
import { useAppContext } from 'app-context';
import React, { useEffect } from 'react';
import { METHODS, sendRequest } from 'utils/request';

const BootInterCom: React.FC = ({ children }) => {
  const { instance } = useMsal();
  const user = instance.getActiveAccount();
  const { i18n } = useAppContext();

  /**
   * @link https://www.intercom.com/help/en/articles/183-set-up-identity-verification-for-web-and-mobile
   * @returns user hash
   */
  const getIdentityVerification = async () => {
    const data = (
      await sendRequest<{ data: string }>({
        request: {
          method: METHODS.GET,
          endpoint: `/userauth/idv`
        },
        lang: i18n.lang
      })
    ).data;
    return data;
  };

  useEffect(() => {
    getIdentityVerification().then((user_hash) => {
      if (user && user['username'] && user['localAccountId']) {
        //@ts-ignore
        window.Intercom('boot', {
          app_id: 'kmh2jlee',
          user_id: user['localAccountId'],
          email: user['username'],
          name: typeof user['name'] === 'undefined' ? '' : user['name'],
          user_hash,
          language_override: i18n.lang
        });
      }
    });
  }, [user]);

  return <>{children}</>;
};

export default BootInterCom;
