import React from 'react';

import { CardWidthType } from '../Card/Card';
import CardSpinner from './CardSpinner';

type Props<T> = {
  title: string;
  variables: Array<T | null | undefined>;
  children: React.ReactNode;
  width?: CardWidthType;
  height?: string;
};

export default function AsyncCardSpinner<T>(props: Props<T>) {
  const { variables, children, title, width = '1', height } = props;

  const allDefined = variables.every((v) => {
    return v !== null && typeof v !== 'undefined';
  });

  return allDefined ? <>{children}</> : <CardSpinner title={title} width={width} height={height} />;
}
