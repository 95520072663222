import { action, makeObservable, observable } from 'mobx';

import { LoggedInUserType, UserDetails } from '@zf/api-types/auth';
import { uiCulture } from '@zf/api-types/enums';
import { cookies, getCookie, setCookie } from '@zf/utils/src/cookie';
import UserAuthService from '../../services/UserAuthService';
import ApplicationStore from './ApplicationStore';

export const SYSTEM_USER = 'system';

export default class UserStore {
  public applicationStore: ApplicationStore;

  public lang: uiCulture;
  public user: LoggedInUserType;
  public users: Map<string, UserDetails | null> = new Map();
  public userAuthService: UserAuthService;

  constructor(applicationStore: ApplicationStore) {
    this.lang =
      (getCookie(cookies.userLanguage) as uiCulture) ||
      (navigator.language.split('-')?.[0] as uiCulture) ||
      uiCulture.en;

    this.applicationStore = applicationStore;

    this.userAuthService = new UserAuthService(applicationStore);

    makeObservable(this, {
      lang: observable,
      user: observable,
      users: observable,
      cacheMatchingUser: action,
      changeUserLanguage: action,
      setUserData: action,
      getAuthInfo: action,
      getUserById: action
    });
  }

  cacheMatchingUser = async (userId: string) => {
    if (userId === 'system') return;

    let matchingUser = this.users.get(userId);

    if (typeof matchingUser === 'undefined') {
      // Add null as a placeholder to avoid creating another request for the same id while a previous is ongoing
      this.users.set(userId, null);
      const foundUser = await this.userAuthService.getUserForId(userId);
      this.users.set(userId, foundUser);
    }
  };

  getUserById = async (userId: string | undefined) => {
    if (userId) {
      await this.cacheMatchingUser(userId);
      return this.users.get(userId)?.userName;
    } else {
      return '';
    }
  };

  changeUserLanguage = (newLang: uiCulture) => {
    setCookie(cookies.userLanguage, newLang, 14);
  };

  setUserData = (newUser: LoggedInUserType) => {
    this.user = newUser;
  };

  getAuthInfo = async (subId?: string) => {
    try {
      let user: LoggedInUserType | undefined;

      const res = await this.userAuthService.getUserForId(subId || null);

      if (res) {
        user = {
          id: res.id,
          culture: uiCulture.en,
          email: res.email,
          userName: res.userName || res.email
        };
      }

      if (!user) {
        throw new Error('Invalid user');
      }

      this.setUserData(user);
    } catch (e) {
      this.applicationStore.rootStore.errorStore.pushError(e);
    }
  };
}
