import { observer } from 'mobx-react-lite';
import React from 'react';

import { culture } from '@zf/api-types/enums';
import Flag from '@zf/stella-react/src/atoms/Flag/Flag';
import Label from '@zf/stella-react/src/atoms/Label/Label';

import { useStore } from '../../../../../../hooks/useStore';
import { getCultureFlag } from '../../../../../../utils/lang';
import css from './translations-inputfield.module.scss';
import TextArea from 'components/input/TextArea';

type Props = {
  culture: culture;
  translation: string;
};

const TranslationInputField = (props: Props) => {
  const { translation, culture } = props;
  const { applicationStore, communicationStore } = useStore();
  const { getEnumTranslation, getTranslation } = applicationStore;
  const { setTranslation } = communicationStore.translationStore;

  const cultureTranslation = getEnumTranslation('culture', culture);

  return (
    <div className={css['translation-wrapper-input-label']}>
      <div className={css['culture-flag']}>
        <Flag url={getCultureFlag(culture)} />
        <Label className={css['translation-label']}>{cultureTranslation}</Label>
      </div>

      <TextArea
        id={`translation-${culture}`}
        value={translation}
        onChange={(value) => setTranslation(culture, value)}
        placeholder={getTranslation('communication.translation_in', { cultureTranslation })}
        error={!translation}
        hideLabel
        max={20}
      />
      <br />
    </div>
  );
};

export default observer(TranslationInputField);
