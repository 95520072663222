import { UserAvatar } from 'components/Avatar';
import React from 'react';

import css from './comment.module.scss';

type Props = {
  user?: string;
  comment: string;
};

export default function Comment(props: Props) {
  const { user, comment } = props;

  if (user) {
    return (
      <div className={css['comment']}>
        <div>
          <UserAvatar size="xsmall">{user}</UserAvatar>
        </div>
        <div>{comment}</div>
      </div>
    );
  } else {
    return (
      <div className={css['comment']}>
        <div>{comment}</div>
      </div>
    );
  }
}
