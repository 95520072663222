import React from 'react';

import { useAppContext } from '../../app-context';
import { NavigatorType } from '../../app-context/hooks/use-selection-navigator';
import { BaseButton } from '../../design-system/Components';
import { Link } from '../../design-system/Components';
import { Icon } from '../Icon';
import css from './style.module.scss';

type Props = NavigatorType & {
  entityRoute: string;
  children?: JSX.Element;
};

export default function Navigator(props: Props) {
  const { entityRoute } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;
  const { next, previous, currentPosition, totalItems } = props;
  return currentPosition > 0 ? (
    <div className={css['navigator']}>
      <Link id="back-to-list" icon="list" className={css['back-to-list']} url={`${rootUrl}/${entityRoute}`} bold>
        {i18n.getTranslation('general.back')}
      </Link>

      <div className={css['dot']}>
        <Icon type="dot" />
      </div>

      <div className={css['wrapper']}>
        <div className={css['position']}>
          {currentPosition} {i18n.getTranslation('general.of')} {totalItems}
        </div>
        <div className={css['navigate']}>
          <BaseButton id="previous" className={css['navigator-btn']} onClick={previous}>
            <Icon type="chevron-left" />
          </BaseButton>
          <BaseButton id="next" className={css['navigator-btn']} onClick={next}>
            <Icon type="chevron-right" />
          </BaseButton>
        </div>
      </div>
    </div>
  ) : null;
}
