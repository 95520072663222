import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';
import { betweenDates } from '@zf/utils/src/date';

import { useAppContext } from '../../../../app-context/app-context';
import useDetail from '../../../../app-context/hooks/use-detail';
import useRefresh from '../../../../app-context/hooks/use-refresh';
import { ICON_COLORS } from '../../../../constants/icons';

const useMeterDetail = (entityId: string) => {
  const { entity: initialMeter, navigator } = useDetail<MeterType>({
    entityId,
    endpoint: '/md/meters',
    domain: 'meter'
  });
  const { enumReducer } = useAppContext();
  const { timeStamp, refresh } = useRefresh();

  const [meter, setMeter] = useState(initialMeter);

  useEffect(() => {
    if (initialMeter) setMeter(initialMeter);
  }, [initialMeter]);

  const today = useMemo(() => moment(), []);

  let activeState: MeterStatusHistoryType | undefined = undefined;

  if (meter) {
    activeState = meter.statusHistory.find((sh) => {
      return (
        betweenDates(sh.startDateTime, sh.endDateTime, today) ||
        (moment(sh.endDateTime).year() === 10000 && today.isBefore(sh.startDateTime)) // install date in future
      );
    });
  }

  const status = (
    <NewStatusBadge
      color={activeState ? ICON_COLORS[activeState.meterStatus] : ''}
      status_={activeState ? ` ${enumReducer.getTranslation('meterStatus', activeState.meterStatus)} ` : ''}
    />
  );

  return {
    meter,
    status,
    activeState,
    navigator,
    timeStamp,
    refresh,
    setMeter
  };
};

export default useMeterDetail;
