import { autorun } from 'mobx';
import { Observer } from 'mobx-react';
import React from 'react';

import { paymentMethod } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../../app-context';
import Button from '../../../../components/Button/Button';
import IconParagraph from '../../../../components/Icon/IconParagraph';
import { SimpleTooltip } from '../../../../design-system/Components';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../hooks/useStore';
import CustomerBankAccount from './CustomerBankAccount';
import css from './edit-bank-accounts-dialog.module.scss';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  customer: CustomerType;
  defaultPaymentMethod: paymentMethod;
};

const EditBankAccountsDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, customer, defaultPaymentMethod } = props;
  const { customerStore } = useStore();
  const { i18n, enumReducer } = useAppContext();

  const validate = () => {
    if (validationRef.current) {
      validationRef.current.setIsError(
        customerStore.bankAccounts.results.some((acc) => {
          return acc.mandateForm?._isDirty;
        })
      );
    }
  };

  React.useEffect(() => {
    autorun(() => {
      validate();
    });
  });

  return (
    <Observer>
      {() => (
        <div className={css['edit-bank-accounts-dialog']}>
          <div className={css['payment-method']}>
            <Label>{`${i18n.getTranslation('customer.payment_method')}: `}</Label>
            <IconParagraph iconType={defaultPaymentMethod}>
              {enumReducer.getTranslation('paymentMethod', defaultPaymentMethod)}
            </IconParagraph>
          </div>

          <div className={css['heading-plus-action']}>
            <Label>{i18n.getTranslation('customer.bank_accounts')}</Label>
            <SimpleTooltip id="add-account-tip" tooltip={i18n.getTranslation('general.add')}>
              <Button
                id="add-account"
                icon="plus"
                type="text"
                size="small"
                onClick={() => customerStore.AddBankAccount()}
              />
            </SimpleTooltip>
          </div>

          <div className={css['bankaccounts-container']}>
            {customerStore.confirmedAccounts.map((acc, bankAccountIndex) => {
              return (
                <CustomerBankAccount
                  customer={customer}
                  key={`${acc.id}-${bankAccountIndex}`}
                  bankAccount={acc}
                  bankAccountIndex={bankAccountIndex}
                />
              );
            })}
          </div>
        </div>
      )}
    </Observer>
  );
});

export default EditBankAccountsDialog;
