import React from 'react';

import { convertHexToRGBA } from '@zf/utils/src/color';

import { ICON_COLORS } from '../../constants/icons';
import { Icon } from '../Icon';
import css from './icon-box.module.scss';

type Props = {
  iconType: string;
};

export default function IconBox(props: Props) {
  const { iconType } = props;

  const color = ICON_COLORS[iconType];

  const bgColor = convertHexToRGBA(color, 20);

  return (
    <div className={css['wrapper']} style={{ color: color, backgroundColor: bgColor }}>
      <div className={css['icon-wrapper']}>
        <Icon type={iconType} color={color} />
      </div>
    </div>
  );
}
