import React from 'react';

import StellaTimeZoneDropDown, {
  getTimeZoneDropDown,
  TimeZoneProps
} from '@zf/stella-react/src/atoms/Dropdown/stella-time-zone-dropdown';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import AccessControl from '../Permissions/AccessControl';
import useDefaultTimeZone from './hooks/useDefaultTimeZone';

export default function TimeZoneDropdown(props: TimeZoneProps) {
  const { id, selectedValues, onChange } = props;

  const hasSelection = !!selectedValues[0];

  const defaultTimeZone = useDefaultTimeZone(selectedValues[0]);

  const values = getTimeZoneDropDown();

  let selectedText = '';

  if (hasSelection) {
    const selected = values.find((v) => v.value === selectedValues[0]);
    if (selected) selectedText = selected.text;
  } else if (defaultTimeZone) {
    selectedText = defaultTimeZone;
  }

  React.useEffect(() => {
    if (defaultTimeZone) {
      onChange([defaultTimeZone]);
    }
  }, [defaultTimeZone]);

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{selectedText}</Paragraph>}>
      <StellaTimeZoneDropDown {...props} />
    </AccessControl>
  );
}
