import React from 'react';

import { useAppContext } from '../../app-context';
import { ButtonProps } from '../../design-system/Components/Button/Button';
import Button from '../Button/Button';

type Props = ButtonProps & {
  iconType?: 'trashcan' | 'cross';
  tooltipText?: 'remove' | 'delete';
};

export default function DeleteIcon(props: Props) {
  const { iconType = 'trashcan', tooltipText = 'delete' } = props;
  const { i18n } = useAppContext();

  return (
    <Button
      {...props}
      type="text"
      size="small"
      icon={iconType}
      tooltipText={i18n.getTranslation(`general.${tooltipText}`)}
      danger
    />
  );
}
