import classNames from 'classnames';
import { Paragraph } from 'design-system/Foundation';
import React, { CSSProperties, ReactNode, useMemo } from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { colors, convertHexToRGBA } from '@zf/utils/src/color';

import css from './info-banner.module.scss';
import Interweave from 'interweave';
import { CheckBox } from 'design-system/Inputs';

export type InfoBannerColor = 'blue' | 'orange' | 'green';

type Props = {
  info: string;
  color: InfoBannerColor;
  className?: string;
  saveIbanTitle?: string;
  saveIbanDesc?: string;
  saveIbanOnCustomer: boolean;
  roundedCorners?: boolean;
  setValue: (checked: boolean) => void;
  bold?: boolean;
};

const switchColor = (color: InfoBannerColor) => {
  return {
    blue: colors['blue-600'],
    orange: colors['orange-600'],
    green: colors['green-600']
  }[color];
};

export default function IBANInfoBanner(props: Props) {
  const {
    info,
    color,
    saveIbanTitle,
    saveIbanDesc,
    saveIbanOnCustomer,
    roundedCorners,
    className,
    bold = false,
    setValue
  } = props;

  const colorHex = switchColor(color);

  const style: CSSProperties = useMemo(() => {
    const backgroundColor = convertHexToRGBA(colorHex, 5);
    return { backgroundColor, color: `${color}` };
  }, [colorHex]);

  return (
    <div className={classNames(css['info-banner'], { [css['rounded']]: roundedCorners }, className)} style={style}>
      <FlexElements flexDirection='column' gap='4'>
        <Paragraph color={colorHex} bold={bold}>
          <Interweave content={`${info}`} />
        </Paragraph>
        <CheckBox
          id="payments.save-iban-on-customer"
          title={saveIbanTitle}
          description={saveIbanDesc}
          checked={saveIbanOnCustomer}
          onChange={setValue}
        />
      </FlexElements>
    </div>
  );
}
