import React, { memo, useMemo } from 'react';
import { AreaChart, Legend, ResponsiveContainer } from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';

import css from './chart-style.module.scss';
import CustomLegend from './CustomLegend';
import { IntervalType, SharedChartProps } from './types';
import { ZFChartLayout } from './ZFChartLayout';

type Props = SharedChartProps & {
  lines: JSX.Element[];
  interval?: IntervalType;
  height?: string;
  showLegend?: boolean;
  yDomain?: AxisDomain | undefined;
  xAxisTickFormatter?: (value: string) => string;
  locale: string;
  showXTicks?: boolean;
};

const ZFAreaChart = memo((props: Props) => {
  const {
    chartData,
    subject,
    interval,
    lines,
    XdataKey,
    yDomain,
    locale,
    xAxisTickFormatter,
    showLegend = true,
    showXTicks = true
  } = props;

  const layout = ZFChartLayout(subject, XdataKey, locale, interval, yDomain, false, xAxisTickFormatter, showXTicks);

  const lineChartMargin = useMemo(() => {
    return { top: 24, left: -16, right: 40, bottom: 0 };
  }, []);

  const legendMargin = useMemo(() => {
    return { top: -16, left: 40 };
  }, []);

  return (
    <ResponsiveContainer className={css['chart']}>
      <AreaChart data={chartData} margin={lineChartMargin}>
        {layout}
        {lines}
        {showLegend && <Legend content={<CustomLegend locale={locale} />} wrapperStyle={legendMargin} />}
      </AreaChart>
    </ResponsiveContainer>
  );
});

export default ZFAreaChart;
