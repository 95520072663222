import classNames from 'classnames';
import React from 'react';

import { capitalize } from '@zf/utils/src/string';

import css from './tab-list.module.scss';

type Props = {
  selectedItem: string;
  tabItems: string[];
  extraRight?: JSX.Element;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
};

export default function TabList(props: Props) {
  const { selectedItem, tabItems, extraRight, setSelectedItem } = props;

  return (
    <div className={css['tab-items']}>
      {tabItems.map((item, index) => {
        return (
          <div
            key={index}
            role="button"
            className={classNames(css['tab-item'], { [css['active']]: item === selectedItem })}
            onClick={() => setSelectedItem(item)}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            {capitalize(item)}
          </div>
        );
      })}
      {extraRight && <div className={css['extra-right']}>{extraRight}</div>}
    </div>
  );
}
