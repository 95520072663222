import { DropdownAction } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import React from 'react';
import AddCustomerGroupDialog from '../../dialogs/AddCustomerGroupDialog';
import useDialog from '@zf/hooks/src/useDialog';
import useRefresh from 'app-context/hooks/use-refresh';
import { SuspenseSpinner } from 'components/suspense';

export default function AddCustomerGroupListPageButton() {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { onSubmit, clickRef, validationRef } = useDialog();
  const { refresh } = useRefresh();

  return (
    <DropdownAction
      id="customergroup.add"
      icon="plus"
      dialogTitle={getTranslation('customer_groups.add_customer_group')}
      dialogContent={
        <SuspenseSpinner>
          <AddCustomerGroupDialog ref={clickRef} onComplete={refresh} validationRef={validationRef} />
        </SuspenseSpinner>
      }
      buttonPositive={getTranslation('general.add')}
      onSubmit={onSubmit}
      validationRef={validationRef}
    />
  );
}
