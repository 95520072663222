import React from 'react';

import { MeasurementType, MyChannType } from '@zf/api-types/master-data/meter';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatDate } from '@zf/utils/src/date';
import { formatDecimal } from '@zf/utils/src/number';

import { useAppContext } from '../../../../../app-context';
import FloatInput from '../../../../../components/input/FloatInput';
import css from './terminate-style.module.scss';

type Props = {
  index: number;
  chanIndex: number;
  channel: MyChannType;
  valueToCheck: string | number;
  matchingPrevious: MeasurementType | null | undefined;
  setMeasurementValue: (index: number, channelIndex: number, val: number) => void;
};

export default function Channel(props: Props) {
  const { index, chanIndex, channel, valueToCheck, matchingPrevious, setMeasurementValue } = props;
  const { i18n, enumReducer } = useAppContext();

  let newValue = 0;

  if (typeof valueToCheck === 'string') {
    newValue = parseFloat(valueToCheck);
  } else {
    newValue = valueToCheck;
  }

  return (
    <div className={css['channel']} key={`${index}-${chanIndex}`}>
      <Paragraph>
        {channel.channel.description
          ? `${i18n.getTranslation('meter.channel')}: ${channel.channel.description}`
          : `${i18n.getTranslation('meter.channel')}: ${channel.channel.externalIdentifier}`}
      </Paragraph>
      {matchingPrevious ? (
        <>
          <Paragraph>{`${i18n.getTranslation('meter.last-time-received')}: ${formatDate(
            matchingPrevious.endDateTime
          )}`}</Paragraph>
          <Paragraph>
            {`${i18n.getTranslation('meter.previous_value')}: ${formatDecimal(
              matchingPrevious.value,
              i18n.culture
            )} ${enumReducer.getTranslation('unitOfMeasure', channel.channel.unitOfMeasure)}`}
          </Paragraph>
        </>
      ) : (
        <Paragraph>{i18n.getTranslation('meter.no_previous_value')}</Paragraph>
      )}

      <FloatInput
        id={`measurement-value-${index}-${chanIndex}`}
        className={css['msmt-value']}
        onChange={(val) => setMeasurementValue(index, chanIndex, val)}
        value={newValue}
        placeholder={i18n.getTranslation('actions.meter.closing_value')}
        error={!newValue}
      />
    </div>
  );
}
