import clone from 'clone';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';

import {
  CustomEntityProperty,
  CustomEntityPropertyType,
  EntityType
} from '@zf/api-types/config/custom-entity-property-types';
import { CustomEntityProperties } from '@zf/api-types/entity';
import { entitySubjectType } from '@zf/api-types/enums';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import Button from '../../components/Button/Button';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';
import { METHODS } from '../../utils/request';
import { BodyContent } from './BodyContent';
import { BooleanParam, DateParam, StringParam, useQueryParams } from 'use-query-params';

type Props<T> = {
  entityType: EntityType;
  entity: T;
  entitySubjectType: entitySubjectType;
  microservice?: string;
};

export type AggregatedCustomEntityProperty = CustomEntityProperty & {
  type: CustomEntityPropertyType | undefined;
};

export type CreateCustomEntityProperty = {
  customEntityPropertyTypeId: string;
  customEntityPropertyType: CustomEntityPropertyType | undefined;
  startDateTime: Moment;
  endDateTime: Moment;
  valueDecimal: number;
  valueString: string;
  valueBoolean: boolean;
  valueNumber: number;
  valueDateTime: string;
};

export type ValidatorType = {
  customEntityPropertyTypes: CustomEntityPropertyType[];
  customEntityProperties: AggregatedCustomEntityProperty[];
  newCustomEntityProperty: CreateCustomEntityProperty;
  isAdding: boolean;
};

const isCardFocus = (query: any): boolean => {
  return (
    query &&
    query.hasOwnProperty('isFocus') &&
    query.hasOwnProperty('customEntityPropertyTypeId') &&
    query.customEntityPropertyTypeId &&
    query.isFocus
  );
};

export default function CustomEntityPropertiesCard<T extends CustomEntityProperties>(props: Props<T>) {
  const { entityType, entity, entitySubjectType, microservice = 'md' } = props;
  const { id: entityId, customProperties: customEntityProperties } = entity;
  const ref = React.createRef<HTMLDivElement>();

  const { applicationStore, configStore } = useStore();
  const { getTranslation, sendRequest } = applicationStore;

  /**
   * @description use query param to focus this widget
   */

  const [query] = useQueryParams({
    startDateTime: DateParam,
    endDateTime: DateParam,
    isFocus: BooleanParam,
    customEntityPropertyTypeId: StringParam
  });

  const emptyCustomEntityProperty: CreateCustomEntityProperty = {
    customEntityPropertyTypeId: '',
    customEntityPropertyType: undefined,
    startDateTime: moment(MIN_DATE),
    endDateTime: moment(MAX_DATE),
    valueDecimal: 0,
    valueString: '',
    valueBoolean: false,
    valueNumber: 0,
    valueDateTime: MIN_DATE
  };

  const { values, backup, isDirty, setValue, submitFactory } = useValidator<ValidatorType>({
    initialValues: {
      customEntityPropertyTypes: [],
      customEntityProperties: [],
      newCustomEntityProperty: clone(emptyCustomEntityProperty),
      isAdding: isCardFocus(query) || false
    }
  });

  const aggregateCustomEntityProperties = (
    customEntityProperties: CustomEntityProperty[],
    customEntityPropertyTypes: CustomEntityPropertyType[]
  ) => {
    return customEntityProperties.map((cep) => {
      const matchingType = customEntityPropertyTypes.find((type) => type.id === cep.customEntityPropertyTypeId);

      return {
        ...cep,
        type: matchingType
      };
    });
  };

  useEffect(() => {
    configStore.configService.getCustomEntityPropertyTypes().then((res) => {
      setValue(
        {
          customEntityPropertyTypes: res.filter((cep) => cep.entitySubjectType === entitySubjectType),
          customEntityProperties: aggregateCustomEntityProperties(customEntityProperties, res),
          newCustomEntityProperty: {
            ...values.newCustomEntityProperty,
            startDateTime: (isCardFocus(query) && moment(query.startDateTime)) || moment(MIN_DATE),
            endDateTime: (isCardFocus(query) && moment(query.endDateTime)) || moment(MAX_DATE),
            customEntityPropertyType:
              isCardFocus(query) && res.filter((e) => e.id === query.customEntityPropertyTypeId)[0]
          } as any
        },
        true,
        true
      );
    });
  }, [customEntityProperties]);

  useEffect(() => {
    /**
     * @description focus the view
     */

    if (ref.current && isCardFocus(query)) {
      ref.current.scrollIntoView();
    }
  }, [query, ref]);

  const newCustomEntityPropertyToApiValue = (newCustomEntityProperty: CreateCustomEntityProperty) => {
    return {
      startDateTime: newCustomEntityProperty.startDateTime.toISOString(),
      endDateTime: newCustomEntityProperty.endDateTime.toISOString(),
      valueDecimal: newCustomEntityProperty.valueDecimal,
      valueString: newCustomEntityProperty.valueString,
      valueBoolean: newCustomEntityProperty.valueBoolean,
      valueNumber: newCustomEntityProperty.valueNumber,
      valueDateTime: newCustomEntityProperty.valueDateTime
    };
  };

  const saveCustomProperties = async () => {
    let apiFriendlyValues = clone(values.customEntityProperties);

    if (values.isAdding) {
      const matchingIndex = apiFriendlyValues.findIndex(
        (p) => p.customEntityPropertyTypeId === values.newCustomEntityProperty.customEntityPropertyTypeId
      );

      // If an entry for this type already exists, add it to its values
      if (matchingIndex > -1) {
        apiFriendlyValues[matchingIndex].values = [
          ...apiFriendlyValues[matchingIndex].values,
          newCustomEntityPropertyToApiValue(values.newCustomEntityProperty)
        ];
      } else {
        // Otherwise create new entry
        apiFriendlyValues = [
          ...apiFriendlyValues,
          {
            type: values.newCustomEntityProperty.customEntityPropertyType,
            customEntityPropertyTypeId: values.newCustomEntityProperty.customEntityPropertyTypeId,
            values: [newCustomEntityPropertyToApiValue(values.newCustomEntityProperty)]
          }
        ];
      }
    }

    return (
      await sendRequest<T>({
        request: {
          method: METHODS.POST,
          endpoint: `/${microservice}/${entityType}/${entityId}/customproperties`,
          data: { properties: apiFriendlyValues }
        }
      })
    ).data;
  };

  const handleSave = submitFactory(async () => {
    try {
      const response = await saveCustomProperties();

      setValue(
        {
          customEntityProperties: aggregateCustomEntityProperties(
            response.customProperties,
            values.customEntityPropertyTypes
          ),
          newCustomEntityProperty: clone(emptyCustomEntityProperty),
          isAdding: false
        },
        false,
        true
      );

      notify.success({
        content: getTranslation('settings.update_entity_properties_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('settings.update_entity_properties_fail'),
        error: e
      });
    }
  });

  const handleCancel = () => setValue({ ...backup, isAdding: false }, false, true);

  return (
    <Card id="custom-entity-properties-card" width="2" className="card" role="dialog" ref={ref}>
      <CardHeader
        primaryText={isDirty || values.isAdding ? getTranslation('general.save') : undefined}
        onPrimaryClick={isDirty || isCardFocus(query) ? handleSave : undefined}
        secondaryText={isDirty || values.isAdding ? getTranslation('general.cancel') : undefined}
        onSecondaryClick={handleCancel}
        extraRight={
          !(isDirty || values.isAdding) && (
            <Button id="entity_properties.add" icon="plus" type="text" onClick={() => setValue({ isAdding: true })}>
              {getTranslation('general.add')}
            </Button>
          )
        }
      >
        {getTranslation('settings.custom_entity_properties')}
      </CardHeader>
      <BodyContent {...props} values={values} isAdding={values.isAdding} setValue={setValue} handleSave={handleSave} />
    </Card>
  );
}
