import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import MarkAsSentDialog from '../dialogs/marksent-dialog';

export default function MarkAsSentListItem() {
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();
  const { invoiceStore } = useStore();

  if (invoiceStore.pageActionPermissions.mayMarkAsSend) {
    return (
      <DropdownAction
        id="invoice.marksent"
        icon="paper-plane"
        dialogTitle={i18n.getTranslation('actions.mark_sent')}
        dialogContent={
          <MarkAsSentDialog invoiceToHandle={invoiceStore.selectedInvoice.invoiceToHandle} ref={clickRef} />
        }
        onSubmit={onSubmit}
      />
    );
  }

  return null;
}
