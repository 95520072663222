import React from 'react';

import {
  RequiredAttributeQuantityType,
  RequiredConsumptionQuantityType,
  RequiredConsumptionUnitQuantityType,
  RequiredCustomEntityPropertyQuantity,
  RequiredQuantityType
} from '@zf/api-types/billing/billing-completeness';
import { billingCompletenessStatus } from '@zf/api-types/enums';
import { BillingItemType } from '@zf/api-types/product';

import useSuspenseSingleAPI from '../../../../../../hooks/useSuspenseSingleAPI';
import RequiredAttribute from './quantity-types/RequiredAttribute';
import RequiredConsumption from './quantity-types/RequiredConsumption';
import RequiredConsumptionUnit from './quantity-types/RequiredConsumptionUnit';
import RequiredCustomEntityProperty from './quantity-types/RequiredCustomEntityProperty';

type Props = {
  requiredQuantity: RequiredQuantityType;
  currentCompletenessStatus: billingCompletenessStatus;
  defaultClosed: boolean;
  productId: string;
};

export default function BillingItem(props: Props) {
  const { requiredQuantity, defaultClosed, productId } = props;

  const billingItemResponse = useSuspenseSingleAPI<BillingItemType>({
    request: {
      endpoint: `/cfg/BillingItems/${requiredQuantity.billingItemId}`
    }
  });

  if (!billingItemResponse.result) return null;

  const billingItem = billingItemResponse.result.data;

  switch (requiredQuantity.type) {
    case 'RequiredAttributeQuantityDTO': {
      return (
        <RequiredAttribute
          productName={productId}
          billingItem={billingItem}
          defaultClosed={defaultClosed}
          requiredQuantity={requiredQuantity as RequiredAttributeQuantityType}
        />
      );
    }
    case 'RequiredConsumptionQuantityDTO': {
      return (
        <RequiredConsumption
          productName={productId}
          billingItem={billingItem}
          defaultClosed={defaultClosed}
          requiredQuantity={requiredQuantity as RequiredConsumptionQuantityType}
        />
      );
    }
    case 'RequiredConsumptionUnitQuantityDTO': {
      return (
        <RequiredConsumptionUnit
          productName={productId}
          billingItem={billingItem}
          defaultClosed={defaultClosed}
          requiredQuantity={requiredQuantity as RequiredConsumptionUnitQuantityType}
        />
      );
    }
    case 'RequiredCustomEntityPropertyQuantityDTO': {
      return (
        <RequiredCustomEntityProperty
          productName={productId}
          billingItem={billingItem}
          defaultClosed={defaultClosed}
          requiredQuantity={requiredQuantity as RequiredCustomEntityPropertyQuantity}
        />
      );
    }
    default:
      return null;
  }
}
