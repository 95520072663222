import CardHeaderWarning from 'components/card/card-header-warning';
import { SuspenseCardBody } from 'components/suspense';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React from 'react';

import { invoiceStatus } from '@zf/api-types/enums';
import { InvoiceType } from '@zf/api-types/invoice';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import InvoiceDetailsCardBody from './InvoiceDetailsCardBody';

export type PropsType = {
  invoice: InvoiceType;
};

const InvoiceDetailsCard = (props: PropsType) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="invoice-details-card">
      {props.invoice.status === invoiceStatus.generationfailed ? (
        <CardHeaderWarning coachMarkTitle={getTranslation('coachmark.invoice_generation_failed.title')}>
          {getTranslation('general.details')}
        </CardHeaderWarning>
      ) : (
        <CardHeader>{getTranslation('general.details')}</CardHeader>
      )}
      <SuspenseCardBody type="grid" noMaxHeight>
        <InvoiceDetailsCardBody {...props} />
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(InvoiceDetailsCard);
