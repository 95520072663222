import clone from 'clone';
import { useEffect, useState } from 'react';

export default function useRemovableValuesArray<T>(
  initialValues: T[] = [],
  intialNodeValue: T = {} as T,
  revertOrder: boolean,
  enableCopyNode?: boolean,
  disabledNodes?: boolean[],
  setDisabledNodes?: (values: boolean[]) => void
) {
  const [values, setValues] = useState(clone(initialValues));

  useEffect(() => {
    const newInitialValues = clone(initialValues);
    setValues(newInitialValues);
  }, [JSON.stringify(initialValues)]);

  function dispatchValue(index: number, value: Partial<T>) {
    const valuesClone = [...values];
    valuesClone[index] = { ...valuesClone[index], ...value };
    setValues(valuesClone);
  }

  const removeNode = (index: number) => {
    return () => {
      const valuesClone = [...values];
      valuesClone.splice(index, 1);
      setValues(valuesClone);

      if (disabledNodes && setDisabledNodes) {
        const clone_ = [...disabledNodes];
        clone_.splice(index, 1);
        setDisabledNodes(clone_);
      }
    };
  };

  const copyNode = (index: number) => {
    return () => {
      if (enableCopyNode && values.length >= 1) {
        setValues([...values, values[index]]);
      }

      return;
    };
  };

  const addNode = () => {
    if (revertOrder) {
      const copyArray = [...values];
      copyArray.unshift(intialNodeValue);
      setValues(copyArray);

      if (disabledNodes && setDisabledNodes) {
        const cloned = [...disabledNodes];
        cloned.unshift(false);
        setDisabledNodes(cloned);
      }
    } else {
      if (disabledNodes && setDisabledNodes) {
        setDisabledNodes([...disabledNodes, false]);
      }
      const copyArray = [...values];
      copyArray.push(intialNodeValue);
      setValues([...copyArray]);
    }
  };

  return { values, dispatchValue, removeNode, addNode, copyNode, setValues };
}
