import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { WizardHeader, WizardSection } from '@zf/stella-react/src/atoms/Wizard';

import { useAppContext } from '../../../app-context';
import InstallMeterSection from './InstallMeterSection';
import { InstallationType, InstallMeterValidatorValuesType } from './InstallMeterWizard';

type Props = {
  locationType: 'property_group' | 'location';
  measurementSuccesses: boolean[];
  meter: MeterType | null;
  didSubmitBefore: boolean;
  createNewMeter: boolean;
  propertyGroup?: PropertyGroupType;
  onFocusStep: React.Dispatch<React.SetStateAction<string>>;
  setValue: (
    value: Partial<InstallMeterValidatorValuesType>,
    initialApiSet?: boolean | undefined,
    backup?: boolean | undefined
  ) => void;
  setCreateNewMeter: React.Dispatch<React.SetStateAction<boolean>>;
  setInstallations: (installations: InstallationType[]) => void;
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>;
  setCreateNewMeasurement: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MeterSection(props: Props) {
  const {
    locationType,
    measurementSuccesses,
    meter,
    didSubmitBefore,
    createNewMeter,
    propertyGroup,
    setValue,
    setInstallations,
    onFocusStep,
    setFeedback,
    setCreateNewMeter,
    setCreateNewMeasurement
  } = props;

  const { i18n } = useAppContext();

  const setMeasurementSuccesses = (successes: boolean[]) => {
    setValue({ measurementSuccesses: successes });
  };

  return (
    <WizardSection disabled={didSubmitBefore}>
      <WizardHeader>{i18n.getTranslation('install_meter.steps.select_meter')}</WizardHeader>
      {meter && <Heading headingType="h3">{i18n.getTranslation('install_meter.wizard.meter_meter')}</Heading>}
      <InstallMeterSection
        locationType={locationType}
        measurementSuccesses={measurementSuccesses}
        meter={meter}
        propertyGroup={propertyGroup}
        onFocusStep={onFocusStep}
        setInstallations={setInstallations}
        setFeedback={setFeedback}
        createNewMeter={createNewMeter}
        setMeasurementSuccesses={setMeasurementSuccesses}
        setCreateNewMeter={setCreateNewMeter}
        setCreateNewMeasurement={setCreateNewMeasurement}
      />
    </WizardSection>
  );
}
