import { action, makeObservable, observable } from 'mobx';

import RootStore from './';

export default class ErrorStore {
  public rootStore: RootStore;

  public errors: Error[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      errors: observable,

      pushError: action,
      reset: action
    });
  }

  pushError = (e: Error) => {
    this.errors.unshift(e);
  };

  reset = () => {
    this.errors = [];
  };
}
