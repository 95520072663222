import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { BillingParametersType } from '@zf/api-types/parameters';

import { useStore } from '../../hooks/useStore';
import NavListItem from './nav-list-item';

export type Props = {
  activePage: string;
  isConfiguration: boolean;
};

export const WHITE_LIST_ACTION = ['add', 'install'];

const APIDependentLinks = (props: Props) => {
  const { activePage, isConfiguration } = props;

  const { applicationStore, configStore } = useStore();
  const { getBillingParameters } = configStore.configService;
  const { rootUrl, getTranslation } = applicationStore;

  const [billingParameters, setBillingParameters] = useState<BillingParametersType>();

  useEffect(() => {
    getBillingParameters().then((params) => setBillingParameters(params));
  }, []);

  if (!billingParameters) return null;

  return (
    <>
      {billingParameters.enableInvoiceCollection && (
        <NavListItem
          id="nav-collection-case"
          title={getTranslation('nav.collection_cases')}
          href={`${rootUrl}/collection-cases`}
          active={activePage === 'collection-cases'}
          iconType="dunning"
        />
      )}
      {!billingParameters.skipPayment && (
        <NavListItem
          id="nav-payments"
          title={getTranslation('payments.payments')}
          href={`${rootUrl}/payments/incoming-banking-transactions`}
          active={activePage === 'payments' && !isConfiguration} // Avoids double highlight in main & config
          iconType="batch"
        />
      )}
    </>
  );
};

export default observer(APIDependentLinks);
