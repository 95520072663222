import React from 'react';

import { APISettingsType, UpdateFileFormatType } from '@zf/api-types/file-format';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import FileFormatsContent from './file-formats-content';

type Props = {
  config: UseConfigReturnType<UpdateFileFormatType[]>;
  settings: APISettingsType[];
};

export default function FileFormats(props: Props) {
  const { config } = props;
  const { Provider, useTracked } = useCreateContext<UpdateFileFormatType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <FileFormatsContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
