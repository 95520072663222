import React from 'react';

import { Router } from '@reach/router';

import { ErrorBoundary } from '../Error';
import SuspenseFallback from '../Loader/SuspenseFallback';

type Props = {
  children: React.ReactNode;
};

export default function ZFRouter(props: Props) {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<SuspenseFallback />}>
        <Router primary={false}>{props.children}</Router>
      </React.Suspense>
    </ErrorBoundary>
  );
}
