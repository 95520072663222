import React from 'react';

import { moveRequestType } from '@zf/api-types/enums';
import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../app-context';
import useRefresh from '../../../app-context/hooks/use-refresh';
import DeleteTaskListPageButton from '../../../components/Button/Tasks/DeleteTaskListPageButton';
import RejectListPageButton from '../../../components/Button/Tasks/RejectListPageButton';
import ResolveWaitingListPageButton from '../../../components/Button/Tasks/ResolveWaitingListPageButton';
import SetWaitingListPageButton from '../../../components/Button/Tasks/SetWaitingListPageButton';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import EnumFilterSelect from '../../../components/input/EnumFilterSelect';
import { SUCCESS } from '../../../constants/color';
import useTasksFilter from '../hooks/useTasksFilter';
import LocationAutoFill from 'components/Autofills/LocationAutoFill';
import { Label } from '@zf/stella-react/src/atoms/Label';

type Props = {
  search?: string;
};

const TasksFilterBar = (props: Props) => {
  const {
    overviewCount,
    quickFilter,
    searchValue,
    filterTags,
    queryParams,
    onFilter,
    onSearch,
    clearQueryParams,
    setQueryParams,
    refreshCounts
  } = useTasksFilter(props.search);
  const { refresh } = useRefresh();
  const { i18n, enumReducer } = useAppContext();

  const actions = () => {
    return [
      <RejectListPageButton key="reject" refreshCounts={refreshCounts} />,
      <SetWaitingListPageButton key="setwaiting" refreshCounts={refreshCounts} />,
      <ResolveWaitingListPageButton key="setnew" refreshCounts={refreshCounts} />,
      <DeleteTaskListPageButton key="delete" refreshCounts={refreshCounts} />
    ];
  };

  return (
    <FilterBar
      actions={actions()}
      subject={i18n.getTranslation('tasks.tasks')}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      refreshPage={refresh}
      filterButtons={[
        <FilterButton
          key="ToBeProcessed"
          id="ToBeProcessed"
          onTrigger={() => onFilter('toBeProcessed')}
          icon={<Icon type="task" />}
          count={overviewCount.toBeProcessed}
          title={i18n.getTranslation('tasks.list.filter.to_be_processed')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'toBeProcessed'}
        />,
        <FilterButton
          id="processed"
          key="processed"
          onTrigger={() => onFilter('processed')}
          icon={<Icon type="task" />}
          count={overviewCount.processed}
          title={i18n.getTranslation('tasks.list.filter.processed')}
          activeColor={SUCCESS}
          active={quickFilter === 'processed'}
        />,
        <FilterButton
          id="rejected"
          key="rejected"
          onTrigger={() => onFilter('rejected')}
          icon={<Icon type="task" />}
          count={overviewCount.rejected}
          title={i18n.getTranslation('tasks.list.filter.rejected')}
          activeColor={colors['orange-600']}
          active={quickFilter === 'rejected'}
        />,
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="task" />}
          count={overviewCount.all}
          title={i18n.getTranslation('tasks.list.filter.all')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />
      ]}
      filterInputs={[
        <EnumFilterSelect
          id="task-type-filter"
          key="task-type-filter"
          title={i18n.getTranslation('general.type')}
          options={enumReducer.getEnum<moveRequestType>('moveRequestType')}
          values={queryParams.moveRequestType ? queryParams.moveRequestType : []}
          setValue={(value) => setQueryParams({ moveRequestType: value })}
        />,
        <>
          <Label>{i18n.getTranslation('location.serviced_location')}</Label>
          <LocationAutoFill
            id="task-location-filter"
            key="task-location-filter"
            onChange={(val) =>
              setQueryParams({ serviceLocationId: val[0]?.id, serviceLocationName: val[0]?.address.localizedDisplay })
            }
            selectedValues={queryParams.serviceLocationId ? [queryParams.serviceLocationId] : []}
            renderTopLvl={false}
          />
        </>
      ]}
      onSearchChange={(searchVal) => onSearch(searchVal)}
      searchPlaceHolder={i18n.getTranslation('tasks.list.filter.search')}
      searchValue={searchValue}
      searchDebounce={500}
    />
  );
};

export default TasksFilterBar;
