import React from 'react';

import { ContractType } from '@zf/api-types/master-data/contract';
import { DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import useDropdown from '../../useDropdown';
import ContractsDropdownContent from './ContractsDropdownContent';
import ContractsQueryDropdown from './ContractsQueryDropdown';

export type ContractsDropdownProps = DropdownProps<ContractType> & {
  contracts?: ContractType[];
  customerId?: string;
  isInline?: boolean;
};

export default function ContractsDropdown(props: ContractsDropdownProps) {
  const { contracts = [], customerId } = props;
  const dropdownToolsObject = useDropdown<ContractType>([]);

  return customerId ? (
    <ContractsQueryDropdown {...props} dropdownToolsObject={dropdownToolsObject} />
  ) : (
    <ContractsDropdownContent {...props} contracts={contracts} dropdownToolsObject={dropdownToolsObject} />
  );
}
