import classNames from 'classnames';
import React from 'react';

import { navigate } from '@reach/router';
import { BaseButton } from '@zf/heat-erp/src/design-system/Components';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { Icon } from '../Icon';
import { List } from '../List';
import TabButton from '../Tabs/TabButton';
import { TabItemType } from '../Tabs/types';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './list-page-header.module.scss';

type Props = {
  title?: string;
  defaultTab?: string;
  tabItems?: TabItemType[];
  actions?: React.ReactNode[];
  className?: string;
};

type State = {
  selectedTabId: string;
};

export default function ListPageHeader(props: Props) {
  const { title, actions, tabItems, className, defaultTab = '' } = props;

  const urlWithoutQuery = location.href.split('?')[0];
  const splitUrl = urlWithoutQuery.split('/');

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = React.useReducer(stateReducer, {
    selectedTabId: splitUrl[splitUrl.length - 1] || defaultTab
  });

  const onSelectTab = (newId: string) => {
    setState({ selectedTabId: newId });

    // Deriving the path from location avoids the need to prop drill navigate from the route component
    navigate(`${splitUrl.slice(0, 6).join('/')}/${newId}`);
  };

  const renderTabItems = React.useCallback(() => {
    if (tabItems) {
      return tabItems.map((tabItem, index) => {
        return (
          <TabButton
            key={index}
            type="listpage"
            tabItem={tabItem}
            selectedTabId={state.selectedTabId}
            selected={!!state.selectedTabId}
            onSelectTab={onSelectTab}
          />
        );
      });
    }

    return null;
  }, [tabItems, state.selectedTabId, setState]);

  return (
    <div className={classNames(css['page-header'], className)}>
      {title ? <h1>{title}</h1> : renderTabItems()}

      {actions && actions.length > 0 && (
        <TooltipTrigger tooltip={<List>{actions}</List>} trigger="click" placement="bottom-start">
          <BaseButton id="actions-btn" className={css['actions']} type="primary" onClick={() => {}}>
            <Icon name="actions" />
          </BaseButton>
        </TooltipTrigger>
      )}
    </div>
  );
}
