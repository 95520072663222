import classNames from 'classnames';
import React, { memo, useMemo } from 'react';

import css from './horizontal-divider.module.scss';

type Props = {
  className?: string;
  color?: string;
  thickness?: string;
};

const HorizontalDivider = (props: Props) => {
  const { className, color, thickness = '0.1rem' } = props;

  const style = useMemo(() => {
    return { backgroundColor: color, height: thickness };
  }, [color, thickness]);

  return <div className={classNames(css['horizontal-divider'], className)} style={style} />;
};

export default memo(HorizontalDivider);
