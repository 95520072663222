import clone from 'clone';
import moment from 'moment';
import React from 'react';

import { OrganisationPurgeRequestType } from '@zf/api-types/auth';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { capitalize } from '@zf/utils/src/string';

import { useAppContext } from '../../../../app-context';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import css from './reset-info-dialog.module.scss';

type Props = {
  progressInfo: OrganisationPurgeRequestType;
  orgId: string;
};

export default function ResetInfoDialog(props: Props) {
  const { orgId } = props;
  let { progressInfo } = props;
  const { i18n, tenantReducer } = useAppContext();

  const [updateActive, setUpdateActive] = React.useState(false);

  const timeout = setTimeout(() => setUpdateActive(true), 5000);

  const response = useSuspenseSingleAPI<OrganisationPurgeRequestType>({
    request: {
      endpoint: '/sys/purging/current'
    },
    updateInterval: 5000,
    mayExecute: updateActive,
    organization: orgId,
    tenant: tenantReducer.state.tenant?.id
  });

  if (updateActive && response.result && response.result.data) {
    progressInfo = clone(response.result.data);
  }

  // Reset complete
  if (updateActive && response.result && !response.result.data) {
    setUpdateActive(false);

    // Last update isn't received (response.result.data === null) set remaining deletedAllDocuments to true
    progressInfo.dataPartitions.forEach((d) => {
      d.deletedAllDocuments = true;
    });

    clearTimeout(timeout);
  }

  React.useEffect(() => {
    return clearTimeout(timeout);
  }, []);

  return (
    <div className={css['info-grid']}>
      <Paragraph className={css['started']}>
        {i18n.getTranslation('tenant.started_at', {
          time: moment(progressInfo.started).format('YYYY-MM-DD HH:mm:ss')
        })}
      </Paragraph>
      {progressInfo.dataPartitions.map((p) => {
        let progressString = '0%';

        if (!progressInfo.isStarted) {
          progressString = i18n.getTranslation('tenant.queued');
        } else if (p.deletedAllDocuments) {
          progressString = i18n.getTranslation('general.finished');
        } else {
          const percentage = Math.round((p.amountOfDocuments ? p.amountDeleted / p.amountOfDocuments : 1) * 100);

          progressString = `${percentage}%`;
        }

        return (
          <div key={`${p.containerId}-${p.databaseId}`}>
            <div className={css['part-label']}>
              <Label className={css['domain-name']}>{capitalize(p.databaseId)}</Label>
              <Label className={css['percentage']}>{progressString}</Label>
            </div>
            <Paragraph>{capitalize(p.containerId)}</Paragraph>
          </div>
        );
      })}
    </div>
  );
}
