import { observer } from 'mobx-react-lite';
import React from 'react';

import { CalculationConfigurationBillingItemType } from '@zf/api-types/property-group-billing-configuration';
import ConfigCard from '@zf/stella-react/src/atoms/Card/ConfigCard';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { colors } from '@zf/utils/src/color';

import IconBox from '../../../../../../components/IconBox/IconBox';
import { useStore } from '../../../../../../hooks/useStore';
import useBillingItemDetails from '../../hooks/useBillingItemDetails';
import css from './billing-item.module.scss';

type Props = {
  billingItem: CalculationConfigurationBillingItemType;
};

const BillingItem = (props: Props) => {
  const { billingItem } = props;

  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;

  const { billingItemMatch, itemName, utilityType } = useBillingItemDetails(billingItem.billingItemId);

  return (
    <ConfigCard id={`${billingItem.billingItemId}-card`} className={css['billing-item']} hoverable={false}>
      <div>
        <IconBox iconType={utilityType ? utilityType : 'money'} />
      </div>

      <div>
        <Heading headingType="h3" style="bold" color={colors['graphite']}>
          {itemName}
        </Heading>

        <div className={css['row-light']}>
          <Label bold>{getTranslation('property_groups.tabs.billing.q')}</Label>
          <Paragraph>
            {billingItemMatch
              ? getEnumTranslation('billingCalculationType', billingItemMatch.calculationParameters.type)
              : ''}
          </Paragraph>
        </div>

        <div className={css['row-dark']}>
          <Label bold>{getTranslation('property_groups.tabs.billing.p')}</Label>
          <Paragraph bold={billingItem.deviatingTariffCalculation}>
            {billingItem.deviatingTariffCalculation
              ? getTranslation('property_groups.tabs.billing.formula')
              : getTranslation('general.default')}
          </Paragraph>
        </div>
      </div>
    </ConfigCard>
  );
};

export default observer(BillingItem);
