import React, { useEffect, useReducer } from 'react';

import { MeterPropertiesPrepayment } from '@zf/api-types/master-data/meter';
import { TopUpType } from '@zf/api-types/metering/top-up';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Card, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import EmptyStateImg from '@zf/stella-react/src/atoms/Image/EmptyState/EmptyStateImg';

import { Button, CardBody } from '../../../../../design-system/Components';
import { Spinner } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import BalanceCardHeader from './BalanceCardHeader';
import TopUp from './TopUp';
import css from './balance-card.module.scss';

type Props = {
  meterProperties: MeterPropertiesPrepayment;
  meterId: string;
  navigate: (url: string) => void;
};

type State = {
  topups?: TopUpType[];
  loading: boolean;
};

const BalanceCard = (props: Props) => {
  const { meterProperties, meterId, navigate } = props;

  const { applicationStore, meterStore } = useStore();
  const { rootUrl, getTranslation } = applicationStore;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    topups: undefined,
    loading: false
  });

  useEffect(() => {
    setState({ loading: true });
    meterStore.topUpService
      .getTopUps(meterId)
      .then((res) => {
        setState({ loading: false, topups: res });
      })
      .catch((e) => {
        setState({ loading: false });
      });
  }, [meterId]);

  return (
    <Card>
      <CardHeader
        extraRight={
          <Button
            id="show_more"
            type="text"
            onClick={() => navigate(`${rootUrl}/devices/prepayment-devices/${meterId}/balance`)}
          >
            {getTranslation('cards.shared.show_more')}
          </Button>
        }
      >
        {getTranslation('prepayment_device.balance')}
      </CardHeader>
      <CardBody>
        <BalanceCardHeader meterProperties={meterProperties} />
        <HorizontalDivider />
        {state.loading ? (
          <Spinner centered />
        ) : state.topups?.length === 0 ? (
          <CardEmptyBody
            icon={<EmptyStateImg title="contact" image="meter" />}
            title={getTranslation('prepayment_device.topups_empty')}
          >
            {getTranslation('prepayment_device.topups_empty_info')}
          </CardEmptyBody>
        ) : (
          <div className={css['balance-card-top-ups-wrapper']}>
            {state.topups?.map((t) => (
              <TopUp key={t.id} topUp={t} />
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default BalanceCard;
