import React from 'react';

import SearchBar from '@zf/heat-erp/src/components/input/SearchBar';

import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import ClosedDropdown from './ClosedDropdown';
import DropdownContentMultiple from './DropdownContentMultiple';
import useDropdownFunctions from './hooks/useDropdownFunctions';
import { DropdownGenericBaseType, DropdownProps } from './StellaDropdown';

type BodyProps = {
  label: string;
  showTooltip: boolean;
  searchValue: string | undefined;
  focusedIndex: number | undefined;
  totalAmountOfRecords?: number;
  viewType?: 'search' | 'dropdown';
  filters?: JSX.Element | null;
  details?: JSX.Element | null;
  selectSingleItemByDefault?: boolean;
  colorized: boolean;
  setSearchValue: (searchValue: string) => void;
  setShowTooltip: (value: boolean) => void;
  setFocusedIndex: (value: number | undefined) => void;
  handleOpen: () => void;
};

export default function DropdownBodyWithoutInput<T extends DropdownGenericBaseType>(
  props: DropdownProps<T> & BodyProps
) {
  const {
    id,
    label,
    placeholder,
    values,
    selectedValues,
    error,
    showTooltip,
    searchValue,
    filters,
    details,
    focusedIndex,
    totalAmountOfRecords,
    viewType = 'dropdown',
    colorized,
    renderTopLvl = true,
    disabled = false,
    hideLabel = false,
    selectSingleItemByDefault = false,
    extraRight,
    setFocusedIndex,
    onChange,
    onFocus,
    setShowTooltip,
    setSearchValue,
    handleOpen,
    isMultipleSelected
  } = props;

  const { onClickItem, onBlur, handleKeyDown, getShownValues } = useDropdownFunctions({
    id,
    values,
    focusedIndex,
    searchValue,
    selectedValues,
    selectSingleItemByDefault,
    onChange,
    setShowTooltip,
    setFocusedIndex,
    setSearchValue,
    idBased: true,
    isMultipleSelected
  });

  return (
    <TooltipTrigger
      tooltip={
        <DropdownContentMultiple
          {...props}
          totalAmountOfRecords={totalAmountOfRecords}
          filters={filters}
          details={details}
          extraRight={extraRight}
          searchValue={searchValue}
          focusedIndex={focusedIndex}
          viewType={viewType}
          setSearchValue={setSearchValue}
          setFocusedIndex={setFocusedIndex}
          onClickItem={onClickItem}
          getShownValues={getShownValues}
          handleKeyDown={handleKeyDown}
          colorized={colorized}
          isMultipleSelected
        />
      }
      placement="bottom-start"
      trigger="click"
      renderTopLvl={renderTopLvl}
      showTooltip={showTooltip}
      disabled={disabled}
    >
      {viewType === 'dropdown' ? (
        <ClosedDropdown label={label} handleOpen={handleOpen} onBlur={onBlur} />
      ) : (
        <SearchBar
          id={id}
          value={searchValue}
          placeholder={placeholder}
          error={error}
          hideLabel={hideLabel}
          onChange={(val) => {
            setSearchValue(val);
            if (!val) onChange([]);
          }}
          onClick={handleOpen}
          onFocus={onFocus}
        />
      )}
    </TooltipTrigger>
  );
}
