import { QuickTip } from 'design-system/ComponentSets';
import { Paragraph } from 'design-system/Foundation';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { estimatedAnnualVolumeOrigin } from '@zf/api-types/enums';
import { ContractServiceType } from '@zf/api-types/forecasting';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatDateWMonth } from '@zf/utils/src/date';
import { formatDecimal, roundNumberToDecimals } from '@zf/utils/src/number';

import { useStore } from '../../hooks/useStore';
import css from './yearly-estimates.module.scss';

type Props = {
  matchingContractServices: ContractServiceType[];
};

const YearlyEstimates = (props: Props) => {
  const { matchingContractServices } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation, culture } = applicationStore;

  const translateOrigin = (origin: estimatedAnnualVolumeOrigin) => {
    return origin === estimatedAnnualVolumeOrigin.frompreviousyear
      ? getTranslation('contracts.last_year')
      : origin === estimatedAnnualVolumeOrigin.manual
      ? getTranslation('contracts.manual_entered')
      : origin === estimatedAnnualVolumeOrigin.fromconsumergroup
      ? getTranslation('contracts.current_consumer_groups')
      : '';
  };

  return (
    <>
      {matchingContractServices.map((cs, index) => {
        const currentEstimate = cs.estimatedAnnualVolumes.find((eav) => eav.year === moment().year());

        return (
          currentEstimate && (
            <div className={css['yearly-estimate']} key={index}>
              <CardItem width="8">
                <Label>{getTranslation('contracts.estimated_consumption')}</Label>
              </CardItem>
              <CardItem className={css['estimated-consumptions-card-item']} width="4">
                <Paragraph className={css['estimated-yearly-consumption-value']} textAlign="right">
                  {`${formatDecimal(roundNumberToDecimals(currentEstimate.value, 2), culture)} ${getEnumTranslation(
                    'unitOfMeasure',
                    cs.unitOfMeasure
                  )}`}
                </Paragraph>
                <QuickTip
                  id={`last-recalculated-${index}`}
                  text={`${getTranslation('contracts.last_recalculated')} ${formatDateWMonth(
                    currentEstimate.lastRecalculated
                  )} ${
                    currentEstimate.origin &&
                    `${getTranslation('contracts.based_on')} ${translateOrigin(currentEstimate.origin)}`
                  }`}
                />
              </CardItem>
            </div>
          )
        );
      })}
    </>
  );
};

export default observer(YearlyEstimates);
