import React from 'react';

import { UpdateCustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import CustomEntityPropertiesContent from './custom-entity-properties-content';

type Props = {
  config: UseConfigReturnType<UpdateCustomEntityPropertyType[]>;
};

export default function CustomEntityProperties(props: Props) {
  const { config } = props;

  const { Provider, useTracked } = useCreateContext<UpdateCustomEntityPropertyType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <CustomEntityPropertiesContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
