import React from 'react';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { routedEntitySubjectType } from '@zf/api-types/local-enums';
import { PropertyGroupRowType, PropertyGroupType } from '@zf/api-types/master-data/property-group';

import useListPage from '../../../app-context/hooks/use-listpage';
import IconParagraph from '../../../components/Icon/IconParagraph';
import EmptyLink from '../../../components/Link/EmptyLink';
import { Link } from '../../../design-system/Components';
import { useStore } from '../../../hooks/useStore';
import { formatAddress } from '../../../utils/address';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';

const usePropertyListPage = (customEntityPropertyTypes: CustomEntityPropertyType[]) => {
  const { applicationStore } = useStore();
  const { rootUrl, getEnumTranslation, getTranslation } = applicationStore;

  const processRecord = (value: PropertyGroupType): PropertyGroupRowType => {
    return {
      __id: value.id,
      __etag: value._etag,
      __propertyEntity: value,
      __customEntityPropertyTypes: customEntityPropertyTypes,
      name: (
        <EmptyLink
          id={`${routedEntitySubjectType.propertygroup}-${value.id}`}
          url={`${rootUrl}/property-groups/${value.id}`}
        >
          {value.name}
        </EmptyLink>
      ),
      type: (
        <IconParagraph
          iconType={value.type}
          title={getEnumTranslation('propertyGroupType', value.type)}
          tooltipFor="property-groups-table-tip"
          color
          usedInTable
        >
          {getEnumTranslation('propertyGroupType', value.type)}
        </IconParagraph>
      ),
      amountOfServiceLocations: `#${value.serviceLocationIds.length}`,
      address: (
        <Link id={`property-address-${value.id}`} url={`${rootUrl}/property-groups/${value.id}`} alignEmptyDash="left">
          {formatAddress(value.address, getEnumTranslation)}
        </Link>
      ),
      ...getExtraCustomEntityColumns(value.customProperties, customEntityPropertyTypes, getTranslation)
    };
  };

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  } = useListPage<PropertyGroupType, PropertyGroupRowType>({
    endpoint: '/md/propertyGroups',
    processRecord,
    domain: 'propertygroup'
  });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default usePropertyListPage;
