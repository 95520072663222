import React, { ChangeEvent } from 'react';
import { Icon } from '../../Foundation';

import css from './homer-checkbox.module.scss';

export type CheckBoxProps = {
  id: string;
  checked: boolean;
  title?: string;
  description?: string;
  disabled?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
};

export default function HomerCheckBox(props: CheckBoxProps) {
  const { id, checked, title, description, className, disabled = false, onChange } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);

  return (
    <div className={className}>
      <div className={css['checkbox-wrapper']}>
        <div className={css['stack']}>
          {checked && (
            <div className={css['overlay']} onClick={() => onChange(false)}>
              <Icon name="check" />
            </div>
          )}
          <div>
            <input
              id={id}
              className={checked ? css['checked'] : ''}
              type="checkbox"
              checked={checked}
              disabled={disabled}
              onChange={handleChange}
            />
          </div>
        </div>

        <label htmlFor={id}>{title}</label>
      </div>

      <p className={css['description']}>{description}</p>
    </div>
  );
}
