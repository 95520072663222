import React from 'react';

import { CollectionCaseRowType, CollectionCaseType } from '@zf/api-types/collection-case';
import { collectionFlowStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import ProcessCollectionCaseDialog from '../../../actions/collection-cases/process-collection-case-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function ProcessCollectionCaseListPageButton(props: Props) {
  const { refreshCounts } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('collection_case');
  const selectedRows: CollectionCaseRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<CollectionCaseType>('collection_case');

  const { clickRef, validationRef, onSubmit } = useDialog();

  const filteredRows = selectedRows.filter((r) => {
    return r.__entity.status === collectionFlowStatus.active;
  });

  const disabled = selectedRows.length === 0 || filteredRows.length === 0;

  return (
    <DropdownAction
      id="collection_case.process_workflow"
      icon="process"
      dialogTitle={i18n.getTranslation('actions.collection_case.process')}
      dialogContent={
        <ProcessCollectionCaseDialog
          ref={clickRef}
          selectedRows={filteredRows}
          validationRef={validationRef}
          onComplete={setUpdatedRows}
          refreshCounts={refreshCounts}
        />
      }
      disabled={disabled}
      buttonPositive={i18n.getTranslation('actions.collection_case.process')}
      validationRef={validationRef}
      onSubmit={onSubmit}
    />
  );
}
