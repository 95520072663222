import React from 'react';

import { InvoiceType } from '@zf/api-types/invoice';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../../../app-context';
import EmptyLink from '../../../../../components/Link/EmptyLink';
import useSuspenseSingleAPI from '../../../../../hooks/useSuspenseSingleAPI';

export type PropsType = {
  invoiceId: string;
  label: string;
};

export default function QueriedInvoice(props: PropsType) {
  const { invoiceId, label } = props;
  const { tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const response = useSuspenseSingleAPI<InvoiceType>({
    request: {
      endpoint: `/bill/invoices/${invoiceId}`
    }
  });

  if (!response.result) return null;

  const invoiceNum = response.result.data.invoiceNum;

  return (
    <>
      <CardItem width="6">
        <Label>{label}</Label>
      </CardItem>
      <CardItem width="6" justifyContent="end">
        <EmptyLink id={`invoice-${invoiceId}`} url={`${rootUrl}/invoices/${invoiceId}`}>
          {invoiceNum}
        </EmptyLink>
      </CardItem>
    </>
  );
}
