import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';

import css from './tree.module.scss';
import { TreeItemProps } from './TreeItem';

type Props = {
  children: ReactElement<TreeItemProps> | ReactElement<TreeItemProps>[];
  className?: string;
};

function Tree(props: Props) {
  const { children, className } = props;

  return (
    <div className={classNames(css['tree'], className)}>
      <div>{children}</div>
    </div>
  );
}

export default observer(Tree);


