import React from 'react';

import { useAppContext } from '../../app-context';
import { BaseLink } from '../../design-system/Components';
import Icon from '../Icon/Icon';
import css from './header-logo.module.scss';

const dashboardTabs = ['general', 'billing_overview', 'customer_aging'];

export default function HeaderLogo() {
  const { tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const splitted = window.location.pathname.split('/');

  const isDashboard = splitted.length === 3 || (splitted.length === 4 && dashboardTabs.includes(splitted[3]));

  return (
    <div className={css['logo']}>
      <BaseLink id="logo" url={rootUrl}>
        <Icon type="logo" className={css[isDashboard ? 'active-icon' : 'icon']} />
      </BaseLink>
    </div>
  );
}
