import ApplicationStore from 'app-context/stores/domain/ApplicationStore';
import WizardNavigator from 'app-context/stores/util/WizardNavigator';
import WizardValues from 'app-context/stores/util/WizardValues';
import { notify } from 'events/notification-events';
import { supplyDateType } from 'features/contract/src/wizard/shared/SupplyDateInputs/SupplyDateInputs';
import { action, autorun, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { navigate } from '@reach/router';
import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { advanceFrequency, utilityType } from '@zf/api-types/enums';
import {
  ContractedServiceEstimatedConsumption,
  ContractedServiceLocationType,
  ContractServiceLocation
} from '@zf/api-types/master-data/contract';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';

import MoveOutWizardBaseStore from './MoveOutWizardBaseStore';

export enum removeLocationsWizardSteps {
  moveout = 'moveout',
  locations = 'locations',
  billing = 'billing'
}

type RemoveLocationsWizardValues = {
  supplyDateType_: supplyDateType;
  supplyDate: Moment | null;
  removableLocations: ContractServiceLocation[];
  billingCompleteness: BillingCompletenessType | undefined;
  advanceAmount: number | undefined;
};

export default class RemoveLocationsWizardStore {
  public moveOutWizardBaseStore: MoveOutWizardBaseStore;
  private applicationStore: ApplicationStore;

  public locationSearch = '';
  public selectedIds: string[] = [];

  public removeLocationsWizardValues = new WizardValues<RemoveLocationsWizardValues>({
    supplyDateType_: supplyDateType.specific,
    supplyDate: null,
    removableLocations: [],
    billingCompleteness: undefined,
    advanceAmount: undefined
  });

  public removeLocationsWizardNavigator = new WizardNavigator<removeLocationsWizardSteps>(
    removeLocationsWizardSteps.moveout
  );

  constructor(moveOutWizardBaseStore: MoveOutWizardBaseStore, applicationStore: ApplicationStore) {
    this.moveOutWizardBaseStore = moveOutWizardBaseStore;
    this.applicationStore = applicationStore;

    makeObservable(this, {
      locationSearch: observable,
      selectedIds: observable,
      removeLocationsWizardValues: observable,
      removeLocationsWizardNavigator: observable,

      showBillingSection: action,
      setLocationSearch: action,
      setSelectedIds: action,
      resetStore: action
    });

    autorun(() => {
      const { contract } = this.moveOutWizardBaseStore;
      const { values } = this.removeLocationsWizardValues;
      const { getTranslation } = this.applicationStore;
      const { setStepValidation } = this.removeLocationsWizardNavigator;

      // Move out section
      setStepValidation(
        removeLocationsWizardSteps.moveout,
        'removeDate',
        !!values.supplyDate && !isMinDate(values.supplyDate),
        getTranslation('contracts.wizard.terminate.validation.remove_date')
      );

      setStepValidation(
        removeLocationsWizardSteps.moveout,
        'removeDateWithinContractPeriod',
        !!contract &&
          !!values.supplyDate &&
          values.supplyDate.isBetween(contract.supplyStartDate, contract.supplyEndDate, undefined, '[]'),
        getTranslation('contracts.wizard.validation.date_within_contract_period')
      );

      // Locations section
      setStepValidation(
        removeLocationsWizardSteps.locations,
        'locations',
        this.selectedIds.length > 0,
        getTranslation('contracts.wizard.terminate.select_locations')
      );

      setStepValidation(
        removeLocationsWizardSteps.locations,
        'emptyLocations',
        !(!!contract && this.selectedIds.length === values.removableLocations.length) ||
          this.selectedIds.length !== values.removableLocations.length,
        getTranslation('contracts.wizard.terminate.select_locations_no_empty')
      );

      // Billing section
      if (this.showBillingSection()) {
        setStepValidation(
          removeLocationsWizardSteps.billing,
          'advanceAmount',
          typeof values.advanceAmount !== 'undefined' && !isNaN(values.advanceAmount),
          getTranslation('contracts.validation.advance_amount')
        );
      }
    });
  }

  setLocationSearch = (searchTerm: string) => {
    this.locationSearch = searchTerm;
  };

  setSelectedIds = (ids: string[]) => {
    this.selectedIds = ids;
  };

  mapLocationsToApiFriendlyValues = (
    location: ContractServiceLocation,
    estimatedConsumptions: ContractedServiceEstimatedConsumption[]
  ): ContractedServiceLocationType[] => {
    return (
      location.services?.map((s) => {
        return {
          serviceLocationId: location.id,
          utilityType: s.utilityType || utilityType.none,
          externalIdentifier: s.externalIdentifier || '',
          estimatedConsumptions: estimatedConsumptions.filter((ec) => ec.utilityType === s.utilityType)
        };
      }) || []
    );
  };

  // This is an action instead of a computed because it depends on an external observable (contract)
  // Computed values should depend on observables inside the class of definition
  showBillingSection = () => {
    const { contract } = this.moveOutWizardBaseStore;
    const { removableLocations } = this.removeLocationsWizardValues.values;

    // Locations should be selected
    if (!contract || this.selectedIds.length === 0) {
      return false;
    }

    // Contracts without locations aren't supported
    if (contract.serviceLocations.length === 1 || this.selectedIds.length === removableLocations.length) {
      return false;
    }

    // Only render this section when advanceFrequency is useful
    if (
      contract.billingDetails.advanceFrequency === null ||
      contract.billingDetails.advanceFrequency === advanceFrequency.none
    ) {
      return false;
    }

    return true;
  };

  handleSubmit = async () => {
    const { rootUrl, getTranslation } = this.applicationStore;
    const { contract, contractStore } = this.moveOutWizardBaseStore;
    const { contractApiService, getEstimatedConsumptionsForSelectedContract, updateAdvanceAmount } = contractStore;
    const { removeLocationsFromContract } = contractApiService;
    const { values, backup } = this.removeLocationsWizardValues;
    const { supplyDate, removableLocations, advanceAmount } = values;

    if (contract) {
      const toBeRemoved = removableLocations.filter((sl) => this.selectedIds.includes(sl.id));
      const estimatedConsumptions = getEstimatedConsumptionsForSelectedContract(contract);
      const services = toBeRemoved.flatMap((tbr) => this.mapLocationsToApiFriendlyValues(tbr, estimatedConsumptions));

      // Remove locations from contract
      try {
        await removeLocationsFromContract(contract.id, {
          supplyEndDate: supplyDate?.toISOString() || MIN_DATE,
          services
        });
        notify.success({
          content: getTranslation('contracts.wizard.terminate.remove_locations_success', {
            amount: toBeRemoved.length,
            contractNumber: contract.contractNumber
          })
        });
      } catch (error) {
        notify.error({
          content: getTranslation('contracts.wizard.terminate.remove_locations_fail'),
          error
        });
      }

      // Update advance amount
      if (advanceAmount !== backup.advanceAmount) {
        await updateAdvanceAmount(contract, advanceAmount || 0);
      }

      // Go to detail page of updated contract
      navigate(`${rootUrl}/contracts/${contract.id}`);
    }
  };

  /**
   * General
   */
  resetStore = () => {
    this.locationSearch = '';
    this.selectedIds = [];
    this.removeLocationsWizardValues.reset();
    this.removeLocationsWizardNavigator.reset();
  };
}
