import { PagedResponseType } from '@zf/api-types/api';
import { AttachmentType } from '@zf/api-types/attachment';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { billingCalculationType, entitySubjectType, meteringType, unitOfMeasure } from '@zf/api-types/enums';
import {
  BillingItemRequestType,
  BillingItemType,
  BillingTariffRequestType,
  BillingTariffTreeType,
  BillingTariffType,
  ProductAttachmentFileType,
  ProductType,
  RequiredEstimationsType,
  UpdateProductType
} from '@zf/api-types/product';
import { createFormData } from '@zf/auth/src/utils';

import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';
import { notify } from '../../../events/notification-events';
import { METHODS } from '../../../utils/request';

export default class ProductConfigService {
  private applicationStore: ApplicationStore;

  constructor(applicationStore: ApplicationStore) {
    this.applicationStore = applicationStore;
  }

  getProductForId = async (productId: string) => {
    return (
      await this.applicationStore.sendRequest<ProductType>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/Products/${productId}`
        }
      })
    ).data;
  };

  getProducts = async (flexSearch = '') => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<ProductType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/Products',
          query: { flexSearch: flexSearch }
        }
      })
    ).data.results;
  };

  getBillingItemsForSelectedProduct = async (productId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<BillingItemType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/BillingItems',
          query: { productId }
        }
      })
    ).data.results;
  };

  getRequiredEstimationsForProduct = async (productId: string) => {
    try {
      return (
        await this.applicationStore.sendRequest<RequiredEstimationsType[]>({
          request: {
            method: METHODS.GET,
            endpoint: `/cfg/requiredestimations/${productId}`
          }
        })
      ).data;
    } catch (error) {
      notify.error({
        content: this.applicationStore.getTranslation('contracts.wizard.get_consumption_groups_for_prod_fail'),
        error
      });
    }
  };

  getTariffsForSelectedBillingItem = async (billingItemId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<BillingTariffType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/BillingTariffs',
          query: {
            billingItemId,
            orderBy: '-StartDateTime'
          }
        }
      })
    ).data.results;
  };

  getBillingItemForId = async (billingItemId: string) => {
    return (
      await this.applicationStore.sendRequest<BillingItemType>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/BillingItems/${billingItemId}`
        }
      })
    ).data;
  };

  getSupportedUomForBillingItem = async (calculationType: billingCalculationType, meteringType?: meteringType) => {
    return (
      await this.applicationStore.sendRequest<unitOfMeasure[]>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/BillingItems/calculationtypes/${calculationType}/supportedunitofmeasures`,
          query: meteringType ? { meteringType } : {}
        }
      })
    ).data;
  };

  getTariffTree = async (billingItemId: string, showOnlyActive: boolean, productId?: string) => {
    return (
      await this.applicationStore.sendRequest<BillingTariffTreeType>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/BillingTariffs/b/${billingItemId}`,
          query: {
            productId,
            showOnlyActive
          }
        }
      })
    ).data;
  };

  addProduct = async (productName: string, invoiceUpfront: boolean) => {
    return (
      await this.applicationStore.sendRequest<ProductType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/Products',
          data: {
            name: productName,
            invoiceUpfront
          }
        }
      })
    ).data;
  };

  updateProduct = async (newProduct: UpdateProductType) => {
    return (
      await this.applicationStore.sendRequest<ProductType>({
        request: {
          method: METHODS.POST,
          endpoint: `/cfg/Products/${newProduct.id}`,
          data: {
            ...newProduct
          }
        }
      })
    ).data;
  };

  deleteProduct = async (productId: string) => {
    await this.applicationStore.sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/cfg/Products/${productId}`
      }
    });
  };

  postAttachment = async (productId: string, fileData: ProductAttachmentFileType) => {
    const response = (
      await this.applicationStore.sendRequest<AttachmentType>({
        request: {
          method: METHODS.POST,
          endpoint: `/att/attachments/${entitySubjectType.product}/${productId}`,
          data: createFormData({
            File: fileData.file && fileData.file.file ? fileData.file.file : ({} as File)
          })
        }
      })
    ).data;

    return {
      culture: fileData.culture,
      fileName: fileData.fileName,
      internalFileName: fileData.internalFileName,
      attachmentId: response.id
    };
  };

  addBillingItem = async (newItem: BillingItemRequestType) => {
    return (
      await this.applicationStore.sendRequest<BillingItemType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/BillingItems',
          data: newItem
        }
      })
    ).data;
  };

  updateBillingItem = async (id: string, updatedItem: BillingItemRequestType) => {
    return (
      await this.applicationStore.sendRequest<BillingItemType>({
        request: {
          method: METHODS.POST,
          endpoint: `/cfg/BillingItems/${id}`,
          data: updatedItem
        }
      })
    ).data;
  };

  addTariff = async (newTariff: BillingTariffRequestType) => {
    return (
      await this.applicationStore.sendRequest<BillingTariffType>({
        request: {
          method: METHODS.POST,
          endpoint: '/cfg/BillingTariffs',
          data: newTariff
        }
      })
    ).data;
  };

  updateTariff = async (id: string, newTariff: BillingTariffRequestType) => {
    return (
      await this.applicationStore.sendRequest<BillingTariffType>({
        request: {
          method: METHODS.POST,
          endpoint: `/cfg/BillingTariffs/${id}`,
          data: newTariff
        }
      })
    ).data;
  };

  deleteTariff = async (tariffId: string) => {
    await this.applicationStore.sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/cfg/BillingTariffs/${tariffId}`
      }
    });
  };

  deleteBillingItem = async (billingItemId: string) => {
    await this.applicationStore.sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `/cfg/BillingItems/${billingItemId}`
      }
    });
  };

  async getCustomEntityPropertyType(id: string) {
    return (
      await this.applicationStore.sendRequest<CustomEntityPropertyType>({
        request: {
          method: METHODS.GET,
          endpoint: `/cfg/CustomEntityPropertyTypes/${id}`
        }
      })
    ).data;
  }
}
