import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ColumnType } from '@zf/stella-react/src/atoms/Table/types';

import useCurrent from '../../../../app-context/hooks/use-current';
import DynamicVirtualTable from '../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import { TableError, TableLoading, TableNoData } from '../../../../components/placeholder';
import useInvoiceListPage from '../../hooks/useInvoiceListPage';

const InvoiceList = () => {
  const { applicationStore, invoiceStore } = useStore();
  const { userStore, getTranslation } = applicationStore;
  const { getInvoiceStatus, getPaymentStatus } = invoiceStore;

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy: selectAllBusy_,
    refresh,
    localTimeStamp,
    timeStamp,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useInvoiceListPage(getInvoiceStatus, getPaymentStatus);

  const { myCurrent } = useCurrent('invoice');

  const columns: ColumnType[] = [
    {
      width: 40,
      dataKey: 'requireAttention'
    },
    {
      width: 170,
      label: getTranslation('invoices_list.labels.invoiceNum'),
      dataKey: 'invoiceNum',
      noClick: true
    },
    {
      width: 110,
      label: getTranslation('general.status'),
      dataKey: 'status'
    }
  ];

  columns.push({
    width: 240,
    label: getTranslation('invoices_list.labels.payment_status'),
    dataKey: 'currentPaymentStatus'
  });

  columns.push(
    {
      width: 300,
      label: getTranslation('contracts.contractor'),
      dataKey: 'customerAccountNumber'
    },
    {
      width: 140,
      label: getTranslation('invoices_list.labels.contract_num'),
      dataKey: 'contractNumber'
    },
    {
      width: 130,
      label: getTranslation('general.total'),
      dataKey: 'totalAmountInclVAT',
      contentAlignRight: true
    },
    {
      width: 130,
      label: getTranslation('invoice.open_amount'),
      dataKey: 'openAmount',
      contentAlignRight: true
    },
    {
      width: 170,
      label: getTranslation('general.type'),
      dataKey: 'type'
    },
    {
      width: 160,
      label: getTranslation('invoices_list.labels.invoice_date'),
      dataKey: 'invoiceDate'
    },
    {
      width: 210,
      label: getTranslation('invoices_list.labels.invoice_period'),
      dataKey: 'period'
    }
  );

  if (myCurrent.filter.quickFilter === 'overdue') {
    columns.push(
      {
        width: 120,
        label: getTranslation('invoices_list.labels.flow'),
        dataKey: 'cycle'
      },
      {
        width: 110,
        label: getTranslation('invoices_list.labels.next_step'),
        dataKey: 'nextStep'
      }
    );
  }

  return React.useMemo(() => {
    return (
      <DynamicVirtualTable
        id="invoices-table"
        tooltipId="invoices-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        error={error}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        selectedRows={selectedIds}
        onSelectRow={setSelectedIds}
        singleSort
        sortableFields={sortableFields}
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        columns={columns}
      />
    );
  }, [rows, selectedIds, selectAllBusy_, userStore.lang, error, loading]);
};

export default observer(InvoiceList);
