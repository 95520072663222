import classNames from 'classnames';
import { Disabled } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import React, { ReactNode } from 'react';

import CopyIcon from '@zf/heat-erp/src/components/Icon/CopyIcon';
import RemoveIconButton from '@zf/heat-erp/src/components/Icon/RemoveIconButton';

import css from './multi-value-item.module.scss';

type Props = {
  id: string;
  index: number;
  children: ReactNode;
  enableCopyNode: boolean;
  enableRemoveNode: boolean;
  disabled?: boolean;
  withBorder?: boolean;
  onRemove: () => void;
  onCopy: () => void;
};

export default function MultiValueItem(props: Props) {
  const {
    children,
    index,
    enableCopyNode,
    enableRemoveNode,
    id,
    disabled = false,
    withBorder = false,
    onCopy,
    onRemove
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Disabled disabled={disabled}>
      <div id={id} className={classNames(css['multivalue-item'], { [css['with-border']]: withBorder })}>
        <div className={css['actions']}>
          {enableCopyNode && (
            <CopyIcon
              id={`btn-copy-${index}`}
              tooltipText={getTranslation('general.copy')}
              icon="copy"
              onClick={onCopy}
            />
          )}

          {enableRemoveNode && (
            <RemoveIconButton
              id={`btn-remove-${index}`}
              tooltipText={getTranslation('general.remove')}
              icon="cross"
              onClick={onRemove}
            />
          )}
        </div>

        {children}
      </div>
    </Disabled>
  );
}
