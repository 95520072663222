import { action, computed, makeObservable, observable } from 'mobx';

import { AdvanceAmountLimitType, pluginType } from '@zf/api-types/enums';
import { AdvanceLimit, BillingSettings } from '@zf/api-types/integration/plugin';
import { roundNumberToDecimals } from '@zf/utils/src/number';

import RootStore from '../..';
import IntegrationStore from '../IntegrationStore';
import PortalSettingsForm from './forms/PortalSettingsForm';

export default class CustomerPortalStore {
  public rootStore: RootStore;
  public integrationStore: IntegrationStore;

  public portalSettingsForm: PortalSettingsForm | undefined;
  public commandName = '';
  public commandType = '';
  public portalUrl = '';

  constructor(rootStore: RootStore, integrationStore: IntegrationStore) {
    this.rootStore = rootStore;
    this.integrationStore = integrationStore;

    makeObservable(this, {
      portalUrl: observable,
      portalSettingsForm: observable,

      rangeSymbol: computed,

      initPortalConfig: action
    });
  }

  get rangeSymbol() {
    switch (this.portalSettingsForm?._get('type')) {
      case AdvanceAmountLimitType.fixed: {
        return '€';
      }
      case AdvanceAmountLimitType.percentage: {
        return '%';
      }
      default: {
        return '';
      }
    }
  }

  initPortalConfig = async () => {
    const results = await Promise.all([
      this.integrationStore.customPluginActionsService.getConfigRequestsForPlugin<AdvanceLimit | BillingSettings>(
        pluginType.enduserportal
      ),
      this.rootStore.configStore.configService.getConfiguredPlugins()
    ]);

    // Extract data
    const commandInfo = results[0][0];
    this.commandName = commandInfo.commandName;
    this.commandType = commandInfo._type;

    const plugin = results[1].find((p) => p.pluginType === pluginType.enduserportal);
    if (plugin) {
      const advanceLimit = plugin.settings.advanceChangeLimit;
      const billingSettings = plugin.settings.billingSettings;
      let rangeMin = advanceLimit.rangeMin;
      let rangeMax = advanceLimit.rangeMax;

      // Decimal percentage conversion
      if (advanceLimit.type === AdvanceAmountLimitType.percentage) {
        rangeMin = roundNumberToDecimals(rangeMin * 100, 2);
        rangeMax = roundNumberToDecimals(rangeMax * 100, 2);
      }

      this.portalUrl = plugin.settings.portalPublicUrl;

      // Initialize forms
      this.portalSettingsForm = new PortalSettingsForm(
        {
          ...advanceLimit,
          rangeMin,
          rangeMax,
          allowCustomerToChangeAdvanceAmount: billingSettings.allowCustomerToChangeAdvanceAmount,
          hideInvoiceSection: !billingSettings.hideInvoiceSection
        },
        this.integrationStore
      );
    }
  };
}
