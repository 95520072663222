import React, { useState } from 'react';

import MoreActionsMenu from '../../../../components/Menus/MoreActionsMenu';
import { Button } from '../../../../design-system/Components';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  openTariffDialog: () => void;
  billingItemId: string;
  productId: string;
};

const BillingTariffMoreActions = (props: Props) => {
  const { openTariffDialog, billingItemId, productId } = props;

  const { productConfigStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getTree } = productConfigStore.productsStore;

  const [showInactiveTariffs, setShowInactiveTariffs] = useState(false);

  const toggleInactiveTariffs = () => {
    setShowInactiveTariffs(!showInactiveTariffs);
    getTree(billingItemId, productId, !showInactiveTariffs);
  };

  return (
    <>
      <Button id="tariff-add-btn" icon="plus" type="text" onClick={openTariffDialog} />
      <MoreActionsMenu
        dotsOrientation="vertical"
        id="more-actions-add-billing-item"
        actions={[
          <DropdownAction
            id="show-active-billing-items"
            key="show-active-billing-items"
            icon={showInactiveTariffs ? 'eye' : 'eye-crossed'}
            actionType="direct"
            onClick={toggleInactiveTariffs}
          >
            {showInactiveTariffs ? getTranslation('general.show_active') : getTranslation('general.hide_inactive')}
          </DropdownAction>
        ]}
      />
    </>
  );
};

export default BillingTariffMoreActions;
