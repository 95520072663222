import React from 'react';

type Props = {
  styleEnter: string;
  styleExit?: string;
  toggle?: boolean;
  destructTimer?: number;
};

export default function useTransition(props: Props) {
  const { styleEnter, styleExit = undefined, toggle = true, destructTimer = 200 } = props;

  const [toggledStyle, setToggledStyle] = React.useState<string | undefined>(undefined);
  const [showElement, setShowElement] = React.useState(false);

  React.useLayoutEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (toggle) {
      setShowElement(true);
      timerId = setTimeout(() => setToggledStyle(styleEnter), 10); // call the into animation
    } else {
      setToggledStyle(styleExit); // call the out animation
      timerId = setTimeout(() => setShowElement(false), destructTimer); //remove element from DOM/html for the user of this hook
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [toggle]);

  return { toggledStyle, showElement };
}
