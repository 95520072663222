import { observer } from 'mobx-react';
import React from 'react';

import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { CardBody } from '../../../design-system/ComponentSets';
import { useStore } from '../../../hooks/useStore';
import InvoicingCardBody from './InvoicingCardBody';

const InvoicingCard = () => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <Card id="invoicing-card" width="3">
      <CardHeader>{getTranslation('portal_config.invoicing')}</CardHeader>
      <CardBody>
        <InvoicingCardBody />
      </CardBody>
    </Card>
  );
};

export default observer(InvoicingCard);
