import React from 'react';

import { PropertyGroupType } from '@zf/api-types/master-data/property-group';

import useDetail from '../../../app-context/hooks/use-detail';

const usePropertyGroupDetail = (id: string) => {
  const {
    entity: initialPropertyGroup,
    navigator,
    refresh
  } = useDetail<PropertyGroupType>({
    entityId: id,
    endpoint: '/md/propertyGroups',
    domain: 'propertygroup'
  });

  const [propertyGroup, setPropertyGroup] = React.useState(initialPropertyGroup);

  React.useEffect(() => {
    if (initialPropertyGroup) setPropertyGroup(initialPropertyGroup);
  }, [initialPropertyGroup]);

  return { setPropertyGroup, refresh, propertyGroup, navigator };
};

export default usePropertyGroupDetail;
