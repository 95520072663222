import classNames from 'classnames';
import React from 'react';

import css from './color-input.module.scss';
import StellaInputField from './StellaInputField';

export type StellaColorInputProps = {
  onChange: (value: string) => void;
  value: string | null | undefined;
  id: string;
  className?: string;
  autoFocus?: boolean;
  readonly?: boolean;
};

export default function StellaColorInput(props: StellaColorInputProps) {
  const { onChange, value, id, className, autoFocus = false, readonly = false } = props;

  return (
    <StellaInputField
      id={id}
      className={classNames(css['color-input'], className)}
      type="color"
      onChange={onChange}
      value={value}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      readonly={readonly}
    />
  );
}
