import classNames from 'classnames';
import React from 'react';

import { FileState, LocalTemplateAttachmentType } from '@zf/api-types/config/scenarios_new';
import { Icon } from '@zf/stella-react/src/atoms/Icon';
import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

import { BaseButton } from '../../design-system/Components';
import css from './file.module.scss';

type Props = {
  children: React.ReactNode;
  file: LocalTemplateAttachmentType;
  canDelete?: boolean;
  download: () => void;
  onCancel?: () => void;
};

export default function ScenarioFile(props: Props) {
  const { children, file, canDelete = true, download, onCancel } = props;

  let icon = null;

  const state = file.state || '';

  switch (state) {
    case FileState.loading:
      icon = <Spinner size="xsmall" />;
      break;
    case FileState.error:
      icon = <Icon name="cancel" />;
      break;
    case FileState.success:
      icon = <Icon name="check" />;
      break;
    case FileState.none:
      icon = <Icon name="cancel" />;
      break;
    default:
      icon = <Icon name="cancel" />;
      break;
  }

  return (
    <div className={css['file']}>
      <div className={css['file-icon']}>
        <Icon name="attachment" />
      </div>
      <BaseButton id="download" onClick={() => download()} className={css['file-name']}>
        {children}
      </BaseButton>
      {icon && onCancel && (state === FileState.none || file.internalFilePath) && canDelete && (
        <BaseButton id="cancel" onClick={onCancel} className={classNames(css['file-validation-icon'], css['none'])}>
          {icon}
        </BaseButton>
      )}
      {icon && state !== FileState.none && !file.internalFilePath && (
        <div
          className={classNames(css['file-validation-icon'], {
            [css['is-valid']]: state === FileState.success,
            [css['is-invalid']]: state === FileState.error
          })}
        >
          {icon}
        </div>
      )}
    </div>
  );
}
