import { Moment } from 'moment';

import { PagedResponseType } from '@zf/api-types/api';
import { BillingCompletenessType } from '@zf/api-types/billing/billing-completeness';
import { invoiceType } from '@zf/api-types/enums';
import { InvoicePaginatedResult } from '@zf/api-types/invoice';
import { MeterType } from '@zf/api-types/master-data/meter';

import BaseService from '../BaseService';
import ApplicationStore from '../../stores/domain/ApplicationStore';
import { METHODS } from '../../../utils/request';
import { CompletenessFiltersType } from '../../stores/master-data/contracts/detail-page/billing-insights/BillingCompletenessStore';
import { ErrorCode } from '@zf/api-types/general';

export default class BillingCompletenessService extends BaseService {
  constructor(endpoint: string, applicationStore: ApplicationStore) {
    super(endpoint, applicationStore);
  }

  getBillingCompletenessesForBillingRelation = async (billingRelationId: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<BillingCompletenessType>>({
        request: {
          method: METHODS.GET,
          endpoint: this.endpoint,
          query: {
            billingRelationId: billingRelationId,
            orderBy: '-PeriodEndDateTime'
          }
        }
      })
    ).data.results;
  };

  getBillingCompleteness = async (billingRelationId: string, filters: CompletenessFiltersType) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<BillingCompletenessType>>({
        request: {
          endpoint: '/bill/BillingCompletenesses',
          query: {
            billingRelationId: billingRelationId,
            periodStartDateTime: filters.periodStartDate?.toISOString(),
            periodEndDateTime: filters.periodEndDate?.toISOString(),
            orderBy: '-PeriodEndDateTime'
          }
        }
      })
    ).data.results;
  };

  getInvoiceEstimationErrors = async (billingCompletenessId: string) => {
    return (
      await this.applicationStore.sendRequest<ErrorCode[]>({
        request: {
          endpoint: `/bill/BillingCompletenesses/${billingCompletenessId}/estimations/errors`
        }
      })
    ).data;
  };

  closeManually = async (completenessId: string) => {
    return (
      await this.applicationStore.sendRequest<BillingCompletenessType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/BillingCompletenesses/${completenessId}/manuallyclose`
        }
      })
    ).data;
  };

  getInvoicesForCompleteness = async (currentCompleteness: BillingCompletenessType) => {
    return (
      await this.applicationStore.sendRequest<InvoicePaginatedResult>({
        request: {
          method: METHODS.GET,
          endpoint: '/bill/Invoices/',
          query: {
            billingCompletenessId: currentCompleteness.id,
            orderBy: '-CreatedDateTime',
            invoiceType: [invoiceType.invoice, invoiceType.creditnote, invoiceType.endnote]
          }
        }
      })
    ).data.results;
  };

  billIntermediately = async (completenessId: string, endDateTime: Moment) => {
    return (
      await this.applicationStore.sendRequest<BillingCompletenessType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/BillingCompletenesses/${completenessId}/billintermediately`,
          data: {
            endDateTime: endDateTime.toISOString()
          }
        }
      })
    ).data;
  };

  changeNextInvoiceEndDate = async (billingRelationId: string, fromFirstOpenPeriod: boolean, endDateTime: Moment) => {
    return (
      await this.applicationStore.sendRequest<BillingCompletenessType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/BillingCompletenesses/${billingRelationId}/changenextinvoiceenddate`,
          data: {
            fromFirstOpenPeriod,
            endDateTime: endDateTime.toISOString()
          }
        }
      })
    ).data;
  };

  getBillingCompletenessSingle = async (completenessId: string) => {
    return (
      await this.applicationStore.sendRequest<BillingCompletenessType>({
        request: {
          method: METHODS.GET,
          endpoint: `/bill/BillingCompletenesses/${completenessId}`
        }
      })
    ).data;
  };

  createOrRecalculate = async (
    completenessId: string,
    checkAdvances: boolean,
    recalculateIfNeeded: boolean,
    advanceUntilDateTime: Moment
  ) => {
    return (
      await this.applicationStore.sendRequest<BillingCompletenessType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/BillingCompletenesses/${completenessId}`,
          data: {
            onlyAdvances: checkAdvances,
            recalculateIfNeeded: recalculateIfNeeded,
            advanceUntilDateTime: advanceUntilDateTime.toISOString()
          }
        }
      })
    ).data;
  };

  reopen = async (completenessId: string) => {
    return (
      await this.applicationStore.sendRequest<BillingCompletenessType>({
        request: {
          method: METHODS.POST,
          endpoint: `/bill/BillingCompletenesses/${completenessId}/reopen`
        }
      })
    ).data;
  };

  getPotentialMeters = async (sluuids: string) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeterType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/md/meters',
          query: {
            sluuids
          }
        }
      })
    ).data.results;
  };
}
