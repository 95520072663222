import ContractLocationsColumn from 'components/Contract/ContractLocationsColumn/ContractLocationsColumn';
import React, { ReactNode, useRef } from 'react';
import { getUniqueServicesForContract } from 'utils/contract';

import { ContractType } from '@zf/api-types/master-data/contract';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { formatPeriod } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context';
import { Icon } from '../../components/Icon';
import DynamicVirtualTable from '../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import TableServices from '../../components/Services/TableServices';
import { SuspenseCardBody } from '../../components/suspense';
import { Link } from '../../design-system/Components';
import useCreateRequest from '../../hooks/useCreateRequest';
import useInfiniAPI from '../../hooks/useInfiniAPI';
import css from './contract-card.module.scss';
import NoContractsOverlay from './NoContractsOverlay';

type Props = {
  customerId: string;
};

type ContractRowType = {
  __id: string;
  contractNumber: JSX.Element;
  supplyStartDate: string;
  services: ReactNode;
  locations: ReactNode;
  currentContractStatus: JSX.Element;
};

function BodyContent(props: Props) {
  const { customerId } = props;
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const columns = useRef([
    {
      width: 150,
      label: i18n.getTranslation('contracts.contract'),
      dataKey: 'contractNumber'
    },
    {
      width: 230,
      label: i18n.getTranslation('general.period'),
      dataKey: 'supplyStartDate'
    },
    {
      width: 200,
      label: i18n.getTranslation('location.services'),
      dataKey: 'services'
    },
    {
      width: 300,
      label: i18n.getTranslation('contracts.location'),
      dataKey: 'locations'
    },
    {
      width: 80,
      label: i18n.getTranslation('general.status'),
      dataKey: 'currentContractStatus'
    }
  ]);

  const { request, setSelectedIds, handleSort, sortBy, sortDirection } = useCreateRequest('/md/contracts', {
    customerId: customerId
  });

  const processRecord = (contract: ContractType): ContractRowType => {
    return {
      __id: contract.id,
      contractNumber: (
        <Link id={`contract-${contract.contractNumber}`} url={`${rootUrl}/contracts/${contract.id}`} icon="contract">
          {contract.contractNumber}
        </Link>
      ),
      supplyStartDate: formatPeriod(contract.supplyStartDate, contract.supplyEndDate),
      services: <TableServices utilityTypes={getUniqueServicesForContract(contract)} />,
      locations: <ContractLocationsColumn serviceLocations={contract.serviceLocations} />,
      currentContractStatus: (
        <Icon
          id={`contractStatusIcon-${contract.id}`}
          tooltipFor="contracts-table-tip"
          title={enumReducer.getTranslation('contractStatus', contract.currentContractStatus)}
          type={contract.currentContractStatus}
          color
        />
      )
    };
  };

  const { loading, error, rows, setStopIndex, sortableFields } = useInfiniAPI<ContractType, ContractRowType>({
    request,
    tenantReducer,
    lang: i18n.lang,
    processRecord,
    onSelectRow: setSelectedIds
  });

  return (
    <DynamicVirtualTable
      id="contracts-table"
      tooltipId="contracts-table-tip"
      onRowsRendered={({ stopIndex }) => setStopIndex(stopIndex)}
      rows={rows}
      loading={loading}
      error={error}
      rowHeight={40}
      NoDataOverlay={NoContractsOverlay}
      noSelect
      sortableFields={sortableFields}
      sort={handleSort}
      sortBy={sortBy}
      singleSort
      sortDirection={sortDirection}
      totalAmountOfRows={rows.length}
      columns={columns.current}
    />
  );
}

export default function ContractCard(props: Props) {
  const { i18n } = useAppContext();

  return (
    <Card id="contract-card" width="2">
      <CardHeader>{i18n.getTranslation('contracts.contracts')}</CardHeader>
      <SuspenseCardBody className={css['contract-card-body']} fixedHeight>
        <BodyContent {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
