import SearchBar from '@zf/heat-erp/src/components/input/SearchBar';
import classNames from 'classnames';
import React from 'react';

import StellaLoader from '../../Loader/StellaLoader';
import OutsideComponentClickDetector from '../../Tooltip/OutsideComponentClickDetector';
import css from '../dropdown.module.scss';
import DropdownClear from '../DropdownClear';
import { DropdownValuesType } from '../StellaDropdown';
import SimpleDropdownList from './SimpleDropdownList';
import { SimpleDropdownProps } from './StellaSimpleDropdown';

export type shownValueType<T> = { value: DropdownValuesType<T>; index: number };

type Props<T> = SimpleDropdownProps<T> & {
  viewType: 'search' | 'dropdown';
  searchValue: string | undefined;
  focusedIndex: number | undefined;
  showTooltip: boolean;
  setSearchValue: (searchValue: string) => void;
  setShowTooltip: (value: boolean) => void;
  setFocusedIndex: (value: number | undefined) => void;
  onClickItem: (i: number) => void;
  getShownValues: (values: DropdownValuesType<T>[], searchValue?: string) => shownValueType<T>[];
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export default function SimpleDropdownContent<T>(props: Props<T>) {
  const {
    values,
    showTooltip,
    searchValue,
    selectedValues,
    focusedIndex,
    viewType,
    conditionMessage,
    clear = false,
    loading = false,
    emptyText = 'No matches',
    clearText = 'Clear',
    onChange,
    setShowTooltip,
    setSearchValue,
    setFocusedIndex,
    handleKeyDown,
    onClickItem,
    onClear
  } = props;

  const clearDropDown = () => {
    setFocusedIndex(-1);
    onChange([]);
    setSearchValue('');
    if (onClear) onClear();
  };

  return (
    <OutsideComponentClickDetector shouldTrigger={showTooltip ? showTooltip : false} action={setShowTooltip}>
      <div key="dropdownWrapper" className={classNames(css['dropdown-popup'])}>
        <div className={css['searchbar']}>
          <SearchBar
            id="test-dropdown-searchbar"
            value={searchValue}
            debounceTime={500}
            onKeyDown={handleKeyDown}
            onChange={(val) => {
              setSearchValue(val);
              if (!val) onChange([]);
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={showTooltip}
          />
        </div>
        {values || !loading ? (
          <>
            <div>
              <SimpleDropdownList
                values={values}
                selectedValues={selectedValues}
                focusedIndex={focusedIndex}
                emptyText={conditionMessage || emptyText}
                viewType={viewType}
                searchValue={searchValue}
                setFocusedIndex={setFocusedIndex}
                onClickItem={onClickItem}
              />
            </div>

            {clear && <DropdownClear clearText={clearText} clearDropDown={clearDropDown} />}
          </>
        ) : (
          <StellaLoader />
        )}
      </div>
    </OutsideComponentClickDetector>
  );
}
