import React, { ReactNode } from 'react';

import css from './card-body.module.scss';

type Props = {
  children: ReactNode;
};

export default function CardBody(props: Props) {
  const { children } = props;

  return <div className={css['card-body']}>{children}</div>;
}
