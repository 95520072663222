import { action, makeObservable, observable } from 'mobx';

import RootStore from '../../../../app-context/stores';
import { METHODS } from '../../../../utils/request';

export default class QuickFilterStore<Q extends Record<string, number>> {
  private rootStore: RootStore;
  private endpoint: string;

  public overviewCounts: Q | undefined;
  public activeQuickFilter: keyof Q | undefined;
  public defaultQueryParams: any;

  constructor(rootStore: RootStore, endpoint: string, defaultQuickFilter: keyof Q, defaultQueryParams?: any) {
    this.rootStore = rootStore;
    this.endpoint = endpoint;
    this.activeQuickFilter = defaultQuickFilter;
    this.defaultQueryParams = defaultQueryParams;

    makeObservable(this, {
      overviewCounts: observable,
      activeQuickFilter: observable,

      setOverviewCounts: action,
      setActiveQuickFilter: action,
      fetchCounts: action
    });
  }

  setOverviewCounts = (newOverviewCounts: Q) => {
    this.overviewCounts = newOverviewCounts;
  };

  setActiveQuickFilter = (newActiveFilter: keyof Q) => {
    this.activeQuickFilter = newActiveFilter;
  };

  fetchCounts = async <P>(queryParams: P) => {
    try {
      const overviewCounts = (
        await this.rootStore.applicationStore.sendRequest<Q>({
          request: {
            method: METHODS.GET,
            endpoint: `${this.endpoint}/overviewcount`,
            query: { ...queryParams, ...this.defaultQueryParams }
          }
        })
      ).data;

      this.setOverviewCounts(overviewCounts);
    } catch (e) {
      this.rootStore.errorStore.pushError(
        new Error(this.rootStore.applicationStore.getTranslation('error.overview_counts_fail'))
      );
    }
  };
}
