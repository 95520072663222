import { Spinner } from 'design-system/Foundation';
import React from 'react';

import InvoicePreviewWrapper from './InvoicePreviewWrapper';

export default function InvoicePreviewLoader() {
  return (
    <InvoicePreviewWrapper>
      <Spinner size="medium" centered />
    </InvoicePreviewWrapper>
  );
}
