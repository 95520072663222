import React from 'react';

import { Card } from '@zf/stella-react/src/atoms/Card';
import SuspenseSpinner from './suspense-spinner';
import { ErrorBoundary } from '../Error';

type Props = {
  id: string;
  children: React.ReactNode;
  width?: '1' | '2' | '3';
  className?: string;
  type?: 'transparent' | 'dark' | 'default';
};

export default function SuspenseCard(props: Props) {
  const { id, children, width, className, type } = props;

  return (
    <Card id={id} width={width} className={className} type={type}>
      <ErrorBoundary>
        <SuspenseSpinner>{children}</SuspenseSpinner>
      </ErrorBoundary>
    </Card>
  );
}
