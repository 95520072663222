import React from 'react';

import { AgingReportType } from '@zf/api-types/aging-report';
import useDialog from '@zf/hooks/src/useDialog';
import { formatDateForExport } from '@zf/utils/src/date';

import { exportArrayToExcel } from '../../../../components/Button/Excel/ExportToExcel';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import { State } from '../hooks/useCustomerAgingFilter';
import ExportDialog, { StateValues } from './dialogs/export-dialog';

type Props = {
  filterState: State;
};

const CustomerExport = (props: Props) => {
  const { applicationStore, agingReportStore } = useStore();
  const { filterState } = props;
  const { getTranslation } = applicationStore;
  const { clickRef, validationRef, onSubmit } = useDialog();

  const fetchAllRows = async (array: AgingReportType[], filters: StateValues, continuationToken?: string) => {
    await agingReportStore.agingReportService.getCustomerReport(filters, continuationToken).then(async (res) => {
      if (!res.nextPageToken) {
        array.push(...res.results);
      } else if (res.nextPageToken) {
        await fetchAllRows(array, filters, res.nextPageToken);
        array.push(...res.results);
      }
    });
  };

  const getExportArray = async (filters: StateValues) => {
    const exportArray = [];

    exportArray[0] = [
      getTranslation('customer.customer_id'),
      getTranslation('customer.customer'),
      getTranslation('property_groups.property_groups'),
      getTranslation('dashboard.customer_aging.oldest_open'),
      getTranslation('collection_case.active_case'),
      getTranslation('dashboard.customer_aging.collection_costs'),
      getTranslation('dashboard.customer_aging.last_payment'),
      getTranslation('dashboard.customer_aging.open_credits'),
      getTranslation('dashboard.customer_aging.current_not_overdue'),
      '0-30',
      '31-60',
      '61-90',
      '>90',
      getTranslation('dashboard.customer_aging.total_open_amount'),
      getTranslation('dashboard.custoemr_aging.total_transactions')
    ];

    let selectedRows: AgingReportType[] = [];
    await fetchAllRows(selectedRows, filters);

    selectedRows.forEach((r) => {
      exportArray.push([
        r.debtor.customerAccountNumber,
        r.debtor.displayName,
        r.propertyGroups?.map((p) => p?.name).join(','),
        r.oldestOpenDebitTransactionReference,
        r.flowActive,
        r.totalCollectionCostsOpen,
        //can't use the const cause this is an other min date then the other const variable sadly
        r.lastPaymentDate !== '0001-01-01T00:00:00Z' ? formatDateForExport(r.lastPaymentDate) : undefined,
        r.openCredits,
        r.totalOpenNotOverdue,
        r.totalOverdue30D,
        r.totalOverdue60D,
        r.totalOverdue90D,
        r.totalOverdueOver90D,
        r.totalOpen,
        r.totalOpenTx
      ]);
    });

    return exportArray;
  };

  const arrayToExcel = async (filters: StateValues) => {
    await exportArrayToExcel('Customers', async () => await getExportArray(filters));
  };

  return (
    <DropdownAction
      dialogTitle={getTranslation('general.export_csv')}
      dialogContent={
        <ExportDialog
          filterState={filterState}
          extraInfo={getTranslation('dashboard.customer_aging.export_customers')}
          propertyGroupExtraInfo={getTranslation('dashboard.customer_aging.property_group_extra_info_customers')}
          exportToExcel={arrayToExcel}
          validationRef={validationRef}
          ref={clickRef}
        />
      }
      id="aging_reports_export_customers"
      icon="customer"
      actionType="dialog"
      onSubmit={onSubmit}
      buttonPositive={getTranslation('general.export')}
    >
      {getTranslation('dashboard.customer_aging.aging_report')}
    </DropdownAction>
  );
};

export default CustomerExport;
