import React from 'react';

import { ProductPeriodReference } from '@zf/api-types/master-data/contract';
import Collapsable from '@zf/stella-react/src/atoms/Collapsable/Collapsable';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { formatPeriod } from '@zf/utils/src/date';

import Paragraph from '../../../../../design-system/Foundation/Paragraph/Paragraph';
import { useStore } from '../../../../../hooks/useStore';
import css from './change-product-dialog.module.scss';

type Props = {
  products: ProductPeriodReference[];
};

const ProductHistory = (props: Props) => {
  const { products } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <>
      <Collapsable title={getTranslation('contracts.product_history')}>
        <div className={css['current-products']}>
          {products.map((p, index) => {
            return (
              <div key={`${p.productId}-${index}`} className={css['current-products-item']}>
                <Label>{formatPeriod(p.startDateTime, p.endDateTime)}</Label>
                <div className={css['current-products-product-name-wrapper']}>
                  <Paragraph className={css['current-products-product-name']}>{p.productName}</Paragraph>
                </div>
              </div>
            );
          })}
        </div>
      </Collapsable>
    </>
  );
};

export default ProductHistory;
