import clone from 'clone';
import moment, { Moment } from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { aggregationFrequency, incrementationType } from '@zf/api-types/enums';
import { ConsumptionGraphType } from '@zf/api-types/graph';
import { ConsumptionFlatValueType, ExternalChannelType } from '@zf/api-types/master-data/meter';

import { useAppContext } from '../../../../../../../../app-context';
import { METHODS, sendRequest } from '../../../../../../../../utils/request';
import { OutputCtxState, useTracked } from '../../../context/output-context';
import useGraph from '../../../shared-hooks/useGraph';
import useQueryParams from '../../../shared-hooks/useQueryParams';

export default function useRegularGraph() {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer } = useAppContext();

  const setState = (newState: Partial<OutputCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const { queryParams } = useQueryParams();
  const { selectedGraphChannels, isCompareMode, setIsLoading } = useGraph();

  const setGraphValues = (newValues: ConsumptionGraphType[]) => {
    setState({ graphValues: newValues, graphIsLoading: false });
  };

  const getFlatValues = async (
    channel: ExternalChannelType,
    type: 'measurements' | 'consumptions',
    startDateTime: Moment,
    endDateTime: Moment
  ): Promise<ConsumptionGraphType> => {
    const result = await sendRequest<ConsumptionFlatValueType[] | PagedResponseType<ConsumptionFlatValueType>>({
      request: {
        method: METHODS.GET,
        endpoint: `/me/${type}/${channel.externalIdentifier}/flat`,
        query: {
          ...queryParams,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString()
        }
      },
      tenantReducer,
      lang: i18n.lang
    });

    let data: ConsumptionFlatValueType[] = [];

    if (type === 'consumptions') {
      const castedResult = result.data as PagedResponseType<ConsumptionFlatValueType>;
      data = castedResult.results;
    } else {
      const castedResult = result.data as ConsumptionFlatValueType[];
      data = castedResult;
    }

    return {
      type: type,
      groupByPeriod: queryParams.groupByPeriod, // Use this to define a graph type switch for consumptions (getChartData needs to be blocked when awaiting the new data)
      externalIdentifier: channel.externalIdentifier,
      description: channel.description ? channel.description : channel.externalIdentifier,
      utilityType: channel.utilityType,
      uom: channel.unitOfMeasure,
      rawData: data
    };
  };

  const getMeasurements = async (
    selectedMeasurementChannels: ExternalChannelType[],
    tmpStartDate: Moment,
    tmpEndDate: Moment
  ) => {
    let resultMeasurement: ConsumptionGraphType[] = [];

    if (selectedMeasurementChannels.length > 0) {
      resultMeasurement = await Promise.all(
        selectedMeasurementChannels.map((msmtChann) =>
          getFlatValues(msmtChann, 'measurements', tmpStartDate, tmpEndDate)
        )
      );
    }

    resultMeasurement.forEach((series) => {
      series.rawData.forEach((dataObject) => {
        const unixTime = parseInt(moment(dataObject.endDateTime).format('x'));
        dataObject.time = unixTime;
      });
    });

    return resultMeasurement;
  };

  const getConsumptions = async (
    selectedConsumptionChannels: ExternalChannelType[],
    tmpStartDate: Moment,
    tmpEndDate: Moment
  ) => {
    let resultConsumption: ConsumptionGraphType[] = [];

    if (selectedConsumptionChannels.length > 0) {
      resultConsumption = await Promise.all(
        selectedConsumptionChannels.map((consChan) => getFlatValues(consChan, 'consumptions', tmpStartDate, tmpEndDate))
      );
    }

    if (queryParams.groupByPeriod === aggregationFrequency.none) {
      resultConsumption.forEach((series) => {
        series.rawData.forEach((dataObject) => {
          const unixTime = parseInt(moment(dataObject.endDateTime).format('x'));
          dataObject.time = unixTime;
        });
      });
    }

    return resultConsumption;
  };

  const getGraphValues = async () => {
    setIsLoading(true);

    let tmpStartDate = queryParams.startDateTime;
    let tmpEndDate = queryParams.endDateTime;

    if (
      queryParams.groupByPeriod === aggregationFrequency.monthly ||
      queryParams.groupByPeriod === aggregationFrequency.yearly
    ) {
      if (tmpStartDate && tmpEndDate) {
        tmpStartDate = clone(tmpStartDate).startOf('year');
        tmpEndDate = clone(tmpEndDate).add(1, 'year').startOf('year');
      }
    }

    const selectedMeasurementChannels: ExternalChannelType[] = [];
    const selectedConsumptionChannels: ExternalChannelType[] = [];

    selectedGraphChannels.forEach((extChannId) => {
      const matchingChann = state.channels.find((c) => {
        return c.externalIdentifier === extChannId;
      });

      if (matchingChann) {
        if (matchingChann.incrementationType !== incrementationType.na) {
          selectedConsumptionChannels.push(matchingChann);
          selectedMeasurementChannels.push(matchingChann);
        } else {
          selectedMeasurementChannels.push(matchingChann);
        }
      }
    });

    let resultConsumptions: ConsumptionGraphType[] = [];
    let resultMeasurements: ConsumptionGraphType[] = [];

    if (state.graphHeaderSelection.includes('consumptions')) {
      resultConsumptions = await getConsumptions(selectedConsumptionChannels, tmpStartDate, tmpEndDate);
    }

    if (state.graphHeaderSelection.includes('measurements')) {
      resultMeasurements = await getMeasurements(selectedMeasurementChannels, tmpStartDate, tmpEndDate);
    }

    // Loading: false is done in graphValues setter
    setGraphValues([...resultMeasurements, ...resultConsumptions]);
  };

  React.useEffect(() => {
    getGraphValues();
  }, [queryParams, isCompareMode, state.selectedGraphChannels, state.graphHeaderSelection, state.timeStamp]);

  return {
    graphValues: state.graphValues
  };
}
