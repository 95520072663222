import classNames from 'classnames';
import React, { useMemo } from 'react';

import Description from './Description';
import css from './setting-description.module.scss';

type Props = {
  title: string;
  description?: string;
  setting: React.ReactNode;
  helpUrl?: string;
  indentLvl?: 0 | 1 | 2;
  invert?: boolean;
  className?: string;
  fullWidth?: boolean;
  isMandatory?: boolean;
};

export default function SettingDescription(props: Props) {
  const {
    title,
    description,
    setting,
    helpUrl,
    indentLvl = 0,
    invert = false,
    fullWidth = false,
    isMandatory = false,
    className
  } = props;

  const descriptivePart = useMemo(() => {
    return (
      <Description
        description={description || ''}
        title={title}
        helpUrl={helpUrl}
        fullWidth={fullWidth}
        isMandatory={isMandatory}
      />
    );
  }, [title, description, helpUrl]);

  return (
    <div
      className={classNames(
        css['setting-description'],
        {
          [css['sub']]: indentLvl === 1,
          [classNames(css['double-sub'], css['sub'])]: indentLvl === 2,
          [css['invert']]: invert
        },
        className
      )}
    >
      {descriptivePart}
      <div>{setting}</div>
    </div>
  );
}
