import React from 'react';
import { Text } from 'recharts';

import { colors } from '@zf/utils/src/color';
import { nFormatter } from '@zf/utils/src/number';

type PayloadType = {
  coordinate: number;
  index: number;
  isShow: boolean;
  offset: number;
  tickCoord: number;
  value: string;
};

type Props = {
  x?: number;
  y?: number;
  locale: string;
  payload?: PayloadType;
};

export default function CustomYAxisTick(props: Props) {
  const { x, y, payload, locale } = props;

  return (
    <Text x={x} y={y} style={{ fill: colors['silver-600'] }} textAnchor="end" verticalAnchor="middle">
      {payload ? nFormatter(parseFloat(payload.value), locale) : ''}
    </Text>
  );
}
