export default function useSessionStorage() {
  function getItem<T>(key: string, default_: T) {
    const stored = sessionStorage.getItem(key);

    if (stored !== null && stored !== 'undefined') {
      return JSON.parse(stored);
    } else {
      return default_;
    }
  }

  function setItem<T>(key: string, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  function removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  return {
    getItem,
    setItem,
    removeItem
  };
}
