import { action, makeObservable, observable } from 'mobx';

import { CommunicationParameterType } from '@zf/api-types/communication';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ExportDataProviderType } from '@zf/api-types/data-provider';
import { pluginType } from '@zf/api-types/enums';
import { APISettingsType } from '@zf/api-types/file-format';
import { BankAccountType, ParameterTypesType } from '@zf/api-types/general';
import { AdvanceLimit, ConfiguredPluginType } from '@zf/api-types/integration/plugin';
import { CultureTableType } from '@zf/api-types/language';
import { MeteringValidationParameters, MeteringValidationRuleType } from '@zf/api-types/meter-config';
import { ModelType } from '@zf/api-types/model';
import { BillingParametersType, PaymentParametersType } from '@zf/api-types/parameters';
import { BillingItemType, ConsumptionUnitType, PaymentTermType, ProductType } from '@zf/api-types/product';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { TaxCodeType } from '@zf/api-types/tax-codes';

import ConfigApiService from '../services/configService';
import RootStore from './';

/**
 * Setting and resetting cfg variables should become generic
 */
export default class ConfigStore {
  public rootStore: RootStore;
  public configService: ConfigApiService;

  public _paymentParameters: PaymentParametersType | undefined;
  public _billingParameters: BillingParametersType | undefined;
  public _meteringValidationParemeters: MeteringValidationParameters | undefined;
  public _communicationParameters: CommunicationParameterType | undefined;
  public _organisationConfig: OrganizationConfigType | undefined;
  public _advanceLimit: AdvanceLimit | undefined;
  public _cultureTable: CultureTableType | undefined;

  public _bankAccounts: BankAccountType[] | undefined;
  public _taxCodes: TaxCodeType[] | undefined;
  public _products: ProductType[] | undefined;
  public _billingItems: BillingItemType[] | undefined;
  public _paymentTerms: PaymentTermType[] | undefined;
  public _calculationTypes: ParameterTypesType[] | undefined;
  public _consumptionUnitTypes: ConsumptionUnitType[] | undefined;
  public _configuredPlugins: ConfiguredPluginType[] | undefined;
  public _customEntityPropertyTypes: CustomEntityPropertyType[] | undefined;
  public _meterModels: ModelType[] | undefined;
  public _exportFileFormats: APISettingsType[] | undefined;
  public _dataExports: ExportDataProviderType[] | undefined;

  public refetchTaxCodes = false;
  public refetchConsumptionUnitTypes = false;
  public refetchCustomEntityPropertyTypes = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.configService = new ConfigApiService(rootStore, this);

    makeObservable(this, {
      _bankAccounts: observable,
      _paymentParameters: observable,
      _billingParameters: observable,
      _communicationParameters: observable,
      _organisationConfig: observable,
      _cultureTable: observable,
      _advanceLimit: observable,
      _taxCodes: observable,
      _products: observable,
      _billingItems: observable,
      _paymentTerms: observable,
      _calculationTypes: observable,
      _consumptionUnitTypes: observable,
      _configuredPlugins: observable,
      _meterModels: observable,
      _meteringValidationParemeters: observable,
      _exportFileFormats: observable,
      _dataExports: observable,

      refetchTaxCodes: observable,
      refetchConsumptionUnitTypes: observable,
      refetchCustomEntityPropertyTypes: observable,

      setCommunicationParameters: action,
      setOrganisationConfig: action,
      setBillingParameters: action,
      resetBillingParameters: action,
      setPaymentParameters: action,
      setBillingItems: action,
      setPaymentTerms: action,
      setCultureTable: action,
      setTaxCodes: action,
      setRefetchTaxCodes: action,
      setBankAccounts: action,
      setProducts: action,
      setCalculationTypes: action,
      setConfiguredPlugins: action,
      setMeterModels: action,
      setMeteringValidationParameters: action,
      setConsumptionUnitTypes: action,
      setRefetchConsumptionUnitTypes: action,
      setCustomEntityPropertyTypes: action,
      isPluginEnabled: action,
      getIsMeteringValidationRuleEnabled: action
    });
  }

  isPluginEnabled = (type: pluginType) => {
    return !!this._configuredPlugins && this._configuredPlugins.some((p) => p.pluginType === type && p.enabled);
  };

  setCommunicationParameters = (newParams: CommunicationParameterType) => {
    this._communicationParameters = newParams;
  };

  setOrganisationConfig = (newCfg: OrganizationConfigType) => {
    this._organisationConfig = newCfg;
  };

  setCultureTable = (newTable: CultureTableType | undefined) => {
    this._cultureTable = newTable;
  };

  setAdvanceLimit = (newAdvanceLimit: AdvanceLimit) => {
    this._advanceLimit = newAdvanceLimit;
  };

  setBillingParameters = (newParams: BillingParametersType) => {
    this._billingParameters = newParams;
  };

  resetBillingParameters = () => {
    this._billingParameters = undefined;
  };

  setPaymentParameters = (newParams: PaymentParametersType) => {
    this._paymentParameters = newParams;
  };

  setMeteringValidationParameters = (newParams: MeteringValidationParameters | undefined) => {
    this._meteringValidationParemeters = newParams;
  };

  getIsMeteringValidationRuleEnabled = (type: MeteringValidationRuleType) => {
    return (
      (!!this._meteringValidationParemeters &&
        this._meteringValidationParemeters.validationRules?.some((r) => r.type === type && r.enabled)) ||
      false
    );
  };

  setTaxCodes = (newCodes: TaxCodeType[]) => {
    this._taxCodes = newCodes;
  };

  setBankAccounts = (newAccounts: BankAccountType[]) => {
    this._bankAccounts = newAccounts;
  };

  setProducts = (newProducts: ProductType[]) => {
    this._products = newProducts;
  };

  setBillingItems = (newBillingItems: BillingItemType[]) => {
    this._billingItems = newBillingItems;
  };

  setPaymentTerms = (newTerms: PaymentTermType[]) => {
    this._paymentTerms = newTerms;
  };

  setCalculationTypes = (newTypes: ParameterTypesType[]) => {
    this._calculationTypes = newTypes;
  };

  setConsumptionUnitTypes = (newTypes: ConsumptionUnitType[]) => {
    this._consumptionUnitTypes = newTypes;
  };

  setConfiguredPlugins = (newPlugins: ConfiguredPluginType[]) => {
    this._configuredPlugins = newPlugins;
  };

  setMeterModels = (newModels: ModelType[]) => {
    this._meterModels = newModels;
  };

  setExportFileFormats = (newFormats: APISettingsType[]) => {
    this._exportFileFormats = newFormats;
  };

  setDataExports = (newExports: ExportDataProviderType[]) => {
    this._dataExports = newExports;
  };

  setCustomEntityPropertyTypes = (newTypes: CustomEntityPropertyType[]) => {
    this._customEntityPropertyTypes = newTypes;
  };

  // Make clear to all users of this data that the api has to fetch again because updates happened outside of MobX
  setRefetchTaxCodes = (val: boolean) => {
    this.refetchTaxCodes = val;
  };

  // Make clear to all users of this data that the api has to fetch again because updates happened outside of MobX
  setRefetchConsumptionUnitTypes = (val: boolean) => {
    this.refetchConsumptionUnitTypes = val;
  };

  // Make clear to all users of this data that the api has to fetch again because updates happened outside of MobX
  setRefetchCustomEntityPropertyTypes = (val: boolean) => {
    this.refetchCustomEntityPropertyTypes = val;
  };
}
