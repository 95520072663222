import classNames from 'classnames';
import React from 'react';

import {
  CollectionChargeParametersType,
  CollectionFlowStepType,
  UpdateCollectionFlowStepType
} from '@zf/api-types/collection-cycles';
import { TemplateAttachment } from '@zf/api-types/config/scenarios_new';
import { collectionChargeType, collectionStepLevel, collectionStepType, communicationType } from '@zf/api-types/enums';
import { SimpleValidatorReturnType } from '@zf/hooks/src/useSimpleValidator';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { InputContainer } from '@zf/stella-react/src/atoms/InputContainer';

import { useAppContext } from '../../../../app-context';
import { InlineInputFieldInput } from '../../../../components/input/InputField';
import NumberInput from '../../../../components/input/NumberInput';
import Select from '../../../../components/input/Select';
import CollectionChargeParameters from './charge-parameters/CollectionChargeParameters';
import CommunicationSection from './CommunicationSection';
import css from './step-wizard.module.scss';

export type StepValidatorType = {
  id?: string;
  triggerDays?: number;
  stepName: string;
  months: number;
  days: number;
  stepType: collectionStepType;
  chargeType: collectionChargeType;
  communicationType: communicationType;
  chargeParameters: CollectionChargeParametersType;
  communicationSubject: string;
  communicationLevel: collectionStepLevel;
  communicationAttachments: TemplateAttachment[];
};

type Props = {
  stepNumber: number | undefined;
  flowIndex?: number;
  step?: UpdateCollectionFlowStepType;
  stepValidatorTools: SimpleValidatorReturnType<StepValidatorType>;
  setStep?: (step: Partial<StepValidatorType>) => void;
  addStep: (step: CollectionFlowStepType, flowIndex?: number) => void;
  updateDialogSteps?: (newStep: CollectionFlowStepType) => void;
};

export default function StepWizard(props: Props) {
  const { stepValidatorTools, stepNumber = '', setStep } = props;
  const { i18n, enumReducer } = useAppContext();

  const { values, setValue } = stepValidatorTools;

  const setStepType = (value: collectionStepType[]) => {
    if (value[0] === collectionStepType.manualIntervention) {
      setValue({ stepType: collectionStepType.manualIntervention, communicationType: communicationType.manual });
    } else {
      setValue({ stepType: value[0] });
    }
  };

  return (
    <div>
      {!!setStep && (
        <>
          <Heading headingType="h2" style="bold">
            {i18n.getTranslation('collection_flows.step', { stepNumber: stepNumber })}
          </Heading>
          <br />
        </>
      )}
      <div className={css['two-column']}>
        <div>
          <Heading headingType="h3" style="bold">
            {i18n.getTranslation('collection_flows.choose_a_name')}
          </Heading>
          <InputContainer>
            <InlineInputFieldInput
              id={`step-name-${stepNumber}`}
              value={values.stepName}
              onChange={(val) => setValue({ stepName: val })}
              placeholder={i18n.getTranslation('collection_flows.step_name_placeholder')}
              error={!values.stepName}
            />
          </InputContainer>
        </div>

        <div>
          <Heading headingType="h3" style="bold">
            {i18n.getTranslation('collection_flows.activated_after')}
          </Heading>
          <div className={css['month-day']}>
            <NumberInput
              id={`days-${stepNumber}`}
              value={values.triggerDays}
              onChange={(val) => setValue({ triggerDays: val })}
              placeholder={i18n.getTranslation('collection_flows.days')}
            />
          </div>
        </div>
      </div>

      <div className={classNames(css['two-column'], css['padding-top'])}>
        <div>
          <Heading headingType="h3" style="bold">
            {i18n.getTranslation('collection_flows.step_type_heading')}
          </Heading>
          <InputContainer>
            <Select
              id={`step_type-${stepNumber}`}
              onChange={setStepType}
              selectedValues={[values.stepType]}
              values={enumReducer.getEnum<collectionStepType>('collectionStepType').map((cst) => {
                return {
                  value: cst.value,
                  text: enumReducer.getTranslation('collectionStepType', cst.value)
                };
              })}
            />
          </InputContainer>
        </div>
        <div>
          <Heading headingType="h3" style="bold">
            {i18n.getTranslation('collection_flows.charge_type_heading')}
          </Heading>
          <InputContainer>
            <Select
              id={`charge_type-${stepNumber}`}
              onChange={(val) => setValue({ chargeType: val[0] })}
              selectedValues={[values.chargeType]}
              values={enumReducer.getEnum<collectionChargeType>('collectionChargeType').map((ct) => {
                return {
                  value: ct.value,
                  text: enumReducer.getTranslation('collectionChargeType', ct.value)
                };
              })}
            />
          </InputContainer>
        </div>
      </div>

      {values.chargeType !== collectionChargeType.none && (
        <CollectionChargeParameters stepValidatorTools={stepValidatorTools} stepNumber={stepNumber} />
      )}

      {values.stepType !== collectionStepType.manualIntervention && (
        <CommunicationSection stepValidatorTools={stepValidatorTools} stepNumber={stepNumber} />
      )}
    </div>
  );
}
