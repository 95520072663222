import { SuspenseSpinner } from 'components/suspense';
import OrgAndScenRespInterface from 'features/customer/wizard/OrgAndScenRespInterface';
import React, { Dispatch, SetStateAction } from 'react';

import { CustomerType } from '@zf/api-types/master-data/customer';

type Props = {
  setNewCustomer: (c: CustomerType) => void;
  onFocusStep?: Dispatch<SetStateAction<string>>;
  setFeedback?: Dispatch<SetStateAction<string[][]>>;
};

export default function NewCustomer(props: Props) {
  const { setNewCustomer, onFocusStep, setFeedback } = props;

  const handleFocus = () => {
    if (onFocusStep) onFocusStep('customer');
  };

  const handleSetFeedback = (value: SetStateAction<string[][]>) => {
    if (setFeedback) setFeedback(value);
  };

  return (
    <SuspenseSpinner>
      <OrgAndScenRespInterface
        feedback={[]}
        onFocusStep={handleFocus}
        setNewCustomer={setNewCustomer}
        setFeedback={handleSetFeedback}
      />
    </SuspenseSpinner>
  );
}
