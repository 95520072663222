import debounce from 'lodash.debounce';
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';
import ReactTooltip from 'react-tooltip';

export const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

export const createTooltipContent = (component: JSX.Element) => {
  return ReactDOMServer.renderToString(component);
};

export const getTooltipContent = (JSXAsText: string) => {
  if (JSXAsText === null) {
    return;
  }

  return ReactHtmlParser(JSXAsText);
};
