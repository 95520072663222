import React from 'react';

import { ExportJobsOverviewCountType } from '@zf/api-types/export-job';

import useFilter from '../../../app-context/hooks/use-filter';

import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { StringParam } from 'use-query-params';

const initialExportJobOverviewCount: ExportJobsOverviewCountType = {
  all: 0,
  requireAttention: 0,
  generated: 0,
  exported: 0,
  created: 0,
  failed: 0
};

type QueryParam = {
  dataExportId?: string;
  dataExportName?: string;
};

const paramTypes = {
  dataExportId: StringParam,
  dataExportName: StringParam
};

const initialQueryParams = {};

const useExportJobsFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    overviewCount,
    quickFilter,
    searchValue,
    clearQueryParams,
    queryParams,
    setQueryParams
  } = useFilter<QueryParam>({
    endpoint: '/logs/exportjob/overviewcount',
    initialCount: initialExportJobOverviewCount,
    domain: 'exportjob',
    search: search,
    initialQueryParams,
    paramTypes
  });

  const tags: React.ReactNode[] = [];

  if (queryParams.dataExportId) {
    tags.push(
      <FilterTag
        key="data-export-type"
        value={queryParams.dataExportName}
        removeFilter={() => setQueryParams({ dataExportId: '', dataExportName: '' })}
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams,
    queryParams,
    filterTags,
    quickFilter,
    overviewCount,
    searchValue
  };
};

export default useExportJobsFilter;
