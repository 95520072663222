import React from 'react';

import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { CheckBox } from '@zf/stella-react/src/atoms/CheckBox';
import { TimeZoneProps } from '@zf/stella-react/src/atoms/Dropdown/stella-time-zone-dropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../app-context';
import TimeZoneDropDown from '../../../components/Dropdown/TimeZoneDropdown';
import InputField, { InputFieldProps } from '../../../components/input/InputField';

type Props = {
  values: OrganizationConfigType;
  setValue: (value: Partial<OrganizationConfigType>, initialApiSet?: boolean, backup_?: boolean) => void;
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineInputFieldTimeZoneDropdown = InlineInputField<TimeZoneProps>(TimeZoneDropDown);

export default function GeneralCard(props: Props) {
  const { values, setValue } = props;
  const { i18n, authReducer } = useAppContext();

  return (
    <Card id="general-card" width="1">
      <CardHeader>{i18n.getTranslation('organization.general')}</CardHeader>
      <CardBody type="grid" noMaxHeight>
        {authReducer.isSuperUser() && (
          <>
            <CardItem width="6">
              <Label>{i18n.getTranslation('organization.migration_mode')}</Label>
            </CardItem>
            <CardItem width="6">
              <CheckBox
                id="migration_mode"
                onChange={(val) => setValue({ migrationMode: val })}
                checked={values.migrationMode}
              />
            </CardItem>
          </>
        )}

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.automatic_processing')}</Label>
        </CardItem>
        <CardItem width="6">
          <CheckBox
            id="automatic_processing"
            onChange={(val) => setValue({ automaticProcessingEnabled: val })}
            checked={values.automaticProcessingEnabled}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.vatAccountNumber')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="vat_nr"
            onChange={(val) => setValue({ vatAccountNumber: val })}
            value={values.vatAccountNumber}
            error={!values.vatAccountNumber}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.timeZone')}</Label>
        </CardItem>

        <CardItem width="6">
          <InlineInputFieldTimeZoneDropdown
            id="timezone"
            onChange={(val) => setValue({ timeZone: val[0] })}
            selectedValues={[values.timeZone ? values.timeZone : '']}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.cultureCode')}</Label>
        </CardItem>

        <CardItem width="6">
          <InlineInputFieldInput
            id="culture"
            onChange={(culture) => setValue({ culture })}
            value={values.culture}
            error={!values.culture}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.companyAccountNumber')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="accountnumber"
            onChange={(val) => setValue({ companyAccountNumber: val })}
            value={values.companyAccountNumber}
            error={!values.companyAccountNumber}
          />
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('organization.code')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>{values.code}</Paragraph>
        </CardItem>

        <CardItem width="6">
          <Label>{i18n.getTranslation('general.description')}</Label>
        </CardItem>
        <CardItem width="6">
          <Paragraph>{values.description}</Paragraph>
        </CardItem>
      </CardBody>
    </Card>
  );
}
