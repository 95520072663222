import { observer } from 'mobx-react';
import React, { MutableRefObject, useEffect, useImperativeHandle } from 'react';

import { templateUsecase } from '@zf/api-types/enums';

import SingleUploadInput from '../../../../../components/input/SingleUploadInput';
import {
    DialogClickRef, ValidationRef
} from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  templateUseCase: templateUsecase;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const AddCustomHtmlDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { validationRef, templateUseCase } = props;
  const { communicationStore } = useStore();
  const { customHTMLForm, initCustomHTMLForm } = communicationStore.templatesStore;
  const { initTemplateTranslations, previewFilters } = communicationStore.translationStore;

  const error = customHTMLForm?._hasError();

  useEffect(() => {
    initCustomHTMLForm();
  }, []);

  useEffect(() => {
    if (validationRef.current && customHTMLForm) {
      validationRef.current.setIsError(error);
    }
  }, [error, customHTMLForm]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      await customHTMLForm?.save(templateUseCase);
      if (previewFilters.entitySubjectType && previewFilters.entitySubjectSubType && previewFilters.templateUseCase) {
        initTemplateTranslations({
          subType: previewFilters.entitySubjectSubType,
          type: previewFilters.entitySubjectType,
          useCase: previewFilters.templateUseCase
        });
      }
    }
  }));

  if (!customHTMLForm) return null;

  return (
    <SingleUploadInput
      file={customHTMLForm._get('file')}
      setFile={(file) => customHTMLForm._set('file', file)}
      extensions={['html']}
      error={!!customHTMLForm._error('file')}
    />
  );
});

export default observer(AddCustomHtmlDialog);
