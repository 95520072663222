import React from 'react';

import { MeterRowType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import ExportMeasurementsDialog from '../../../actions/meters/exports/measurements-export/export-measurements-dialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import { DropdownAction } from '../../../design-system/ComponentSets';

export default function ExportMeasurementsListPageButton() {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('meter');
  const { clickRef, onSubmit } = useDialog();

  const selectedRows: MeterRowType[] = myCurrent.listPage.selectedRows;

  return (
    <DropdownAction
      id="meter.measurements_export"
      dialogTitle={i18n.getTranslation('actions.meter.export_measurements')}
      icon="exel"
      dialogContent={<ExportMeasurementsDialog ref={clickRef} selectedRows={selectedRows} />}
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0}
      buttonPositive={i18n.getTranslation('export_jobs.export')}
    />
  );
}
