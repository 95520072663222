import React from 'react';

import { invoiceStatus } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import useCurrent from '../../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import ApproveInvoiceDialog from '../dialogs/approve-dialog';

type Props = {
  toApprove: number;
  refreshCounts: () => void;
};

export default function ApproveInvoiceListPageButton(props: Props) {
  const { toApprove } = props;
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<InvoiceType>('invoice');
  const { clickRef, validationRef, onSubmit } = useDialog();

  const disabled = selectedRows.find((invoice) => invoice.__entity.status !== invoiceStatus.created) !== undefined;

  return (
    <DropdownAction
      id="invoice.approve"
      icon="approve"
      dialogTitle={
        selectedRows.length === 0 ? i18n.getTranslation('actions.approve_all') : i18n.getTranslation('actions.approve')
      }
      dialogContent={
        <ApproveInvoiceDialog
          {...props}
          onComplete={setUpdatedRows}
          validationRef={validationRef}
          ref={clickRef}
          selectedRows={selectedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={toApprove === 0 || disabled}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.approve')}
    />
  );
}
