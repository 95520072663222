import moment, { Moment } from 'moment';
import React, { Dispatch, forwardRef, ReactNode, Ref, useCallback, useReducer } from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';
import useDebounce from '@zf/hooks/src/useDebounce';
import useDidMountEffect from '@zf/hooks/src/useDidMountEffect';
import { MAX_DATE, MIN_DATE, startOfDay } from '@zf/utils/src/date';

import StellaInputField from '../InputField/StellaInputField';

export type DateInputProps = {
  value: Moment | null;
  id: string;
  warnings: string[];
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  hideLabel?: boolean;
  error?: boolean;
  singleError?: string;
  errors?: {};
  iconRight?: ReactNode;
  className?: string;
  clear?: boolean;
  maxDate?: boolean;
  darkMode?: boolean;
  startOfDay_?: boolean;
  warnUser: (content: ReactNode) => void;
  handleClear: () => void;
  onFocus?: (step: string) => void;
  onClick?: () => void;
  onBlur?: () => void;
  onChange: (value: Moment) => void;
};

type MatchType = {
  day: string;
  month: string;
  year: string;
};

type State = {
  textValue: string;
  isValidInput: boolean;
};

const DATE_REGEX = /(\d{0,2})(\/|-)?(\d{0,2})(\/|-)?(\d{0,4})/;

export default forwardRef((props: DateInputProps, ref: Ref<HTMLDivElement>) => {
  const {
    value,
    id,
    placeholder,
    disabled,
    readonly,
    hideLabel,
    error,
    errors,
    iconRight,
    className,
    warnings,
    singleError,
    maxDate,
    darkMode = false,
    startOfDay_ = true,
    warnUser,
    onChange,
    onFocus,
    onClick,
    onBlur,
    handleClear
  } = props;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    textValue: value ? value.format('DD/MM/YYYY') : '',
    isValidInput: true
  });

  const setDebounceCallback = useDebounce(1000);

  const setTextValue = useCallback(
    (val: string) => {
      setState({ textValue: val });
    },
    [setState]
  );

  const getDate = useCallback((val: string): MatchType | undefined => {
    const match = val.match(DATE_REGEX);
    if (match) {
      return {
        day: match[1],
        month: match[3],
        year: match[5]
      };
    }
  }, []);

  const validateInput = useCallback((match: MatchType) => {
    // Validate input
    const day = parseInt(match.day);
    const dayIsValid = day > 0 && day <= 31;

    const month = parseInt(match.month);
    const monthIsValid = month > 0 && month <= 12;

    const year = parseInt(match.year);
    const yearIsValid = year >= 1900;

    return { dayIsValid, monthIsValid, yearIsValid };
  }, []);

  const warn = useCallback(
    (match: MatchType) => {
      const { dayIsValid, monthIsValid, yearIsValid } = validateInput(match);

      if ((!dayIsValid || !monthIsValid || !yearIsValid) && state.textValue.length >= 7) {
        onChange(moment(moment(maxDate ? MAX_DATE : MIN_DATE), 'DD/MM/YYYY'));
        warnUser(
          <div>
            {!dayIsValid && <div>{warnings[0]}</div>}
            {!monthIsValid && <div>{warnings[1]}</div>}
            {!yearIsValid && <div>{warnings[2]}</div>}
          </div>
        );
      }
    },
    [state.textValue]
  );

  useDidMountEffect(() => {
    if (value !== null && value !== undefined) {
      if (value.year() === undefined) {
        setTextValue('');
      }

      if (
        (value.format('DD/MM/YYYY') === '01/01/0001' && state.textValue !== '01/01/1') ||
        value.format('DD/MM/YYYY') === '31/12/9999' ||
        value.format('DD/MM/YYYY') === '01/01/10000'
      ) {
        setTextValue('');
      } else if (value && value.unix() !== moment(state.textValue, 'DD/MM/YYYY').unix()) {
        setTextValue(value.format('DD/MM/YYYY'));
      }
    }

    if (!value && state.textValue) {
      setTextValue('');
    }
  }, [value]);

  const handleInputChange = (val: string) => {
    // Remove non numeric or not slash
    val = val.replace(/[^0-9\/]/g, '');

    if (val.length === 0) {
      handleClear();
      setTextValue(val);
    } else if (val.substr(val.length - 1, 1) === '/') {
      val = val.slice(0, -1);
      if (val.length === 2) val = val.substring(0, 2) + '/';
      if (val.length === 5) val = val.substring(0, 4) + val.substring(4, 5) + '/';

      setTextValue(val);
    } else {
      const match = getDate(val);

      if (match) {
        setDebounceCallback(() => {
          warn(match);
        });

        if (val.length === 2) val += '/';
        if (val.length === 5) val += '/';

        if (
          match.day &&
          (match.day.length === 2 || match.day.length === 1) &&
          match.day !== '0' &&
          match.month &&
          (match.month.length === 2 || match.month.length === 1) &&
          match.month !== '0' &&
          match.year &&
          match.year.length === 4
        ) {
          if (startOfDay_) {
            onChange(startOfDay(moment(val, 'DD/MM/YYYY')));
          } else {
            onChange(moment(val, 'DD/MM/YYYY'));
          }
        }

        const { dayIsValid, monthIsValid, yearIsValid } = validateInput(match);
        const inputIsOk = dayIsValid && monthIsValid && yearIsValid;

        setState({ textValue: val, isValidInput: inputIsOk });
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && state.textValue.substr(state.textValue.length - 1, 1) === '/') {
      setTextValue(state.textValue.slice(0, -1));
    }
  };

  return (
    <StellaInputField
      id={id}
      errors={errors}
      singleError={singleError}
      ref={ref}
      value={state.textValue}
      placeholder={placeholder}
      disabled={disabled}
      readonly={readonly}
      hideLabel={hideLabel}
      error={error}
      iconRight={iconRight}
      className={className}
      darkMode={darkMode}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      onClick={onClick}
      onBlur={onBlur}
    />
  );
});
