import moment, { Moment } from 'moment';
import React from 'react';

import YearInput from '@zf/stella-react/src/atoms/Date/DateInput/year-input';
import { Label } from '@zf/stella-react/src/atoms/Label';

import css from './year-range-picker.module.scss';

type Props = {
  startDateTime: Moment;
  endDateTime: Moment;
  setYears: (years: number[]) => void;
};

export default function YearRangePicker(props: Props) {
  const { startDateTime, endDateTime, setYears } = props;

  return (
    <div className={css['year-range-picker']}>
      <YearInput
        id="start-year"
        selectedYear={moment(startDateTime).year()}
        onSelect={(val) => setYears([val, moment(endDateTime).year()])}
      />
      <Label key="dash" className={css['dash']}>
        -
      </Label>
      <YearInput
        id="end-year"
        selectedYear={moment(endDateTime).year()}
        onSelect={(val) => setYears([moment(startDateTime).year(), val])}
      />
    </div>
  );
}
