import { customerType } from 'app-context/stores/master-data/contracts/wizard/move-in/RegularMoveInWizardStore';
import clone from 'clone';
import React from 'react';

import { advanceFrequency, contactType, customerType as apiCustomerType, invoiceFrequency } from '@zf/api-types/enums';
import { MoveRequestType, UpdateMoveRequestCustomerDetailsRequest } from '@zf/api-types/master-data/move-request';
import useSubmitFactory from '@zf/hooks/src/useSubmitFactory';

import { useAppContext } from '../../../../../app-context/app-context';
import Button from '../../../../../components/Button/Button';
import { notify } from '../../../../../events/notification-events';
import { METHODS, sendRequest } from '../../../../../utils/request';
import { ExistingEntitiesType, SelectedActivityType } from '../activity-context/context';
import useActivities from './useActivities';

export default function useSaveButton(selectedActivity: SelectedActivityType, existingEntities: ExistingEntitiesType) {
  const { i18n, tenantReducer } = useAppContext();
  const btnText = i18n.getTranslation('general.save');
  const { task, initialTask, customerType_, initialCustomerType, setTask } = useActivities();
  const { submitFactory } = useSubmitFactory();

  if (!task) return null;

  const initialClone = clone(initialTask);
  const taskClone = clone(task);

  delete taskClone.mutationDateTime;
  delete initialClone?.mutationDateTime;

  let disableSave = true;

  let saveBtn: JSX.Element | null = null;

  switch (selectedActivity) {
    case 'confirm_customer':
      {
        disableSave =
          JSON.stringify(taskClone.customerDetails) === JSON.stringify(initialClone?.customerDetails) &&
          customerType_ === initialCustomerType;
        const submitCustomerDetails = submitFactory(async () => {
          const existingCustomer = existingEntities.existingCustomer;
          const customerDetails = task.customerDetails;

          let apiFriendlyValues = {} as UpdateMoveRequestCustomerDetailsRequest;

          if (existingCustomer && customerType_ === customerType.existingcustomer) {
            const primaryContactDetails = existingCustomer?.contactDetails.filter((cd) => cd.primaryForType);

            apiFriendlyValues = {
              accountNumber: existingCustomer.accountNumber,
              salutation: existingCustomer.salutation,
              initials: existingCustomer.initials,
              firstName: existingCustomer.firstName,
              lastName: existingCustomer.lastName,
              birthDate: existingCustomer.birthDate,
              ssin: existingCustomer.ssin,
              ssinCountry: existingCustomer.ssinCountry,
              customerType: existingCustomer.customerType,
              customerGroupId: existingCustomer.customerGroup?.id || null,
              companyName: existingCustomer.companyName,
              organizationNumber: existingCustomer.organizationNumber,
              vatNumber: existingCustomer.vatNumber || null,
              defaultPaymentMethod: existingCustomer.defaultPaymentMethod,
              invoiceAddress: existingCustomer.invoiceAddress,
              bankAccount: existingCustomer.bankAccountIbans.find((acc) => acc.isDefault)?.iban || null,
              emailAddress: primaryContactDetails.find((pcd) => pcd.contactType === contactType.email)?.value || null,
              telephoneNumber:
                primaryContactDetails.find((pcd) => pcd.contactType === contactType.telephone)?.value || null,
              mobileTelephoneNumber:
                primaryContactDetails.find((pcd) => pcd.contactType === contactType.mobiletelephone)?.value || null,
              website: primaryContactDetails.find((pcd) => pcd.contactType === contactType.website)?.value || null,
              culture: existingCustomer.communicationPreferences.culture,
              internalId: existingCustomer.id
            };
          } else {
            apiFriendlyValues = {
              accountNumber: customerDetails.accountNumber,
              salutation: customerDetails.salutation,
              initials: customerDetails.initials,
              firstName: customerDetails.firstName,
              lastName: customerDetails.lastName,
              birthDate: customerDetails.birthDate,
              ssin: customerDetails.customerType === apiCustomerType.person ? customerDetails.ssin : null,
              ssinCountry: customerDetails.customerType === apiCustomerType.person ? customerDetails.ssinCountry : null,
              customerType: customerDetails.customerType,
              customerGroupId: customerDetails.customerGroupId,
              companyName:
                customerDetails.customerType === apiCustomerType.organization ? customerDetails.companyName : '',
              organizationNumber:
                customerDetails.customerType === apiCustomerType.organization ? customerDetails.organizationNumber : '',
              vatNumber: customerDetails.customerType === apiCustomerType.organization ? customerDetails.vatNumber : '',
              defaultPaymentMethod: customerDetails.defaultPaymentMethod,
              invoiceAddress: customerDetails.invoiceAddress,
              bankAccount: customerDetails.bankAccount,
              emailAddress: customerDetails.emailAddress,
              telephoneNumber: customerDetails.telephoneNumber,
              mobileTelephoneNumber: customerDetails.mobileTelephoneNumber,
              website: customerDetails.website,
              culture: customerDetails.culture,
              internalId: null
            };
          }

          try {
            const updatedTask = (
              await sendRequest<MoveRequestType>({
                request: {
                  method: METHODS.POST,
                  endpoint: `/md/MoveRequests/${task.id}/updatecustomerdetails`,
                  data: apiFriendlyValues
                },
                tenantReducer: tenantReducer,
                lang: i18n.lang
              })
            ).data;

            setTask(updatedTask, true, customerType_);

            notify.success({
              content: i18n.getTranslation('tasks.update_customer_success')
            });
          } catch (e) {
            notify.error({
              content: i18n.getTranslation('tasks.update_customer_fail'),
              error: e
            });
          }
        });

        saveBtn = (
          <Button
            id="tasks.update_customer_details"
            type="primary"
            size="small"
            disabled={disableSave}
            onClick={submitCustomerDetails}
          >
            {btnText}
          </Button>
        );
      }
      break;

    case 'confirm_location':
      {
        disableSave =
          JSON.stringify(taskClone.serviceLocationDetails) === JSON.stringify(initialClone?.serviceLocationDetails);
        const submitLocationDetails = submitFactory(async () => {
          try {
            const updatedTask = (
              await sendRequest<MoveRequestType>({
                request: {
                  method: METHODS.POST,
                  endpoint: `/md/MoveRequests/${task.id}/updateservicelocationdetails`,
                  data: task.serviceLocationDetails
                },
                tenantReducer: tenantReducer,
                lang: i18n.lang
              })
            ).data;

            setTask(updatedTask, true);

            notify.success({
              content: i18n.getTranslation('tasks.update_location_success')
            });
          } catch (e) {
            notify.error({
              content: i18n.getTranslation('tasks.update_location_fail'),
              error: e
            });
          }
        });

        saveBtn = (
          <Button
            id="tasks.update_location_details"
            type="primary"
            size="small"
            disabled={disableSave}
            onClick={submitLocationDetails}
          >
            {btnText}
          </Button>
        );
      }
      break;

    case 'confirm_contract':
      {
        disableSave = JSON.stringify(taskClone.contractDetails) === JSON.stringify(initialClone?.contractDetails);
        const submitContract = submitFactory(async () => {
          try {
            const contractDetails = task.contractDetails;

            const apiFriendlyValues = {
              externalContractReference: contractDetails.externalContractId,
              invoiceFrequency: contractDetails.invoiceFrequency
                ? contractDetails.invoiceFrequency
                : existingEntities.existingPropertyGroupBillingConfig &&
                  existingEntities.existingPropertyGroupBillingConfig.invoiceFrequency
                ? existingEntities.existingPropertyGroupBillingConfig.invoiceFrequency
                : invoiceFrequency.yearly,
              productId: contractDetails.productId,
              estimations: contractDetails.estimations,
              advanceFrequency: contractDetails.advanceFrequency
                ? contractDetails.advanceFrequency
                : existingEntities.existingPropertyGroupBillingConfig &&
                  existingEntities.existingPropertyGroupBillingConfig.advanceFrequency
                ? existingEntities.existingPropertyGroupBillingConfig.advanceFrequency
                : advanceFrequency.monthly,
              advanceAmount: contractDetails.advanceAmount,
              usePropertyGroupProduct: contractDetails.usePropertyGroupProduct,
              paymentTermsId: contractDetails.paymentTermsId,
              contractNumber: contractDetails.contractNumber
            };

            const updatedTask = (
              await sendRequest<MoveRequestType>({
                request: {
                  method: METHODS.POST,
                  endpoint: `/md/MoveRequests/${task.id}/updatedetails`,
                  data: apiFriendlyValues
                },
                tenantReducer: tenantReducer,
                lang: i18n.lang
              })
            ).data;

            updatedTask.contractDetails = {
              ...updatedTask.contractDetails,
              billingItems: task.contractDetails.billingItems,
              matchingGroups: task.contractDetails.matchingGroups
            };

            setTask(updatedTask, true);

            notify.success({
              content: i18n.getTranslation('tasks.update_contract_success')
            });
          } catch (e) {
            notify.error({
              content: i18n.getTranslation('tasks.update_contract_fail'),
              error: e
            });
          }
        });

        saveBtn = (
          <Button
            id="tasks.update_contract"
            type="primary"
            size="small"
            disabled={disableSave}
            onClick={submitContract}
          >
            {btnText}
          </Button>
        );
      }
      break;

    case 'confirm_measurements':
      {
        disableSave = JSON.stringify(taskClone.measurementDetails) === JSON.stringify(initialClone?.measurementDetails);
        const submitMeasurements = submitFactory(async () => {
          try {
            const apiFriendlyValues = task.measurementDetails.map((md) => {
              return {
                internalMeterId: md.internalMeterId,
                meterSerialNumber: md.meterSerialNumber,
                externalChannelIdentifier: md.externalChannelIdentifier,
                utilityType: md.utilityType,
                unitOfMeasure: md.unitOfMeasure,
                timeOfUse: md.timeOfUse,
                value: md.value
              };
            });

            const updatedTask = (
              await sendRequest<MoveRequestType>({
                request: {
                  method: METHODS.POST,
                  endpoint: `/md/MoveRequests/${task.id}/updatemeasurementdetails`,
                  data: { measurements: apiFriendlyValues }
                },
                tenantReducer: tenantReducer,
                lang: i18n.lang
              })
            ).data;

            setTask(updatedTask, true);

            notify.success({
              content: i18n.getTranslation('tasks.update_measurements_success')
            });
          } catch (e) {
            notify.error({
              content: i18n.getTranslation('tasks.update_measurements_fail'),
              error: e
            });
          }
        });

        saveBtn = (
          <Button
            id="tasks.update_measurements"
            type="primary"
            size="small"
            disabled={disableSave}
            onClick={submitMeasurements}
          >
            {btnText}
          </Button>
        );
      }
      break;
  }

  return <div>{saveBtn}</div>;
}
