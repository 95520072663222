import React from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../app-context';
import { notify } from '../../../events/notification-events';
import { deleteCollectionStep } from './collection-api-functions';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  stepNumber: number;
  flowIndex: number;
  stepId: string;
  removeStep: (stepId: string, flowIndex: number) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { stepNumber, stepId, flowIndex, removeStep } = props;
  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteCollectionStep(stepId, tenantReducer, i18n.lang);
        // Update state
        removeStep(stepId, flowIndex);

        notify.success({
          content: i18n.getTranslation('collection_flows.delete_step_success', { stepNumber: stepNumber })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('collection_flows.delete_step_fail', { stepNumber: stepNumber }),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>{i18n.getTranslation('collection_flows.delete_step_paragraph', { stepNumber: stepNumber })}</Paragraph>
  );
});
