import { entitySubjectType } from '@zf/api-types/enums';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useBrowserTitle from '../../../../../app-context/hooks/use-browser-title';
import ListPage from '../../../../../components/listPage';
import { useStore } from '../../../../../hooks/useStore';
import IncomingBankingTransactionsFilterBar from './IncomingBankingTransactionsFilterBar';
import IncomingBankingTransactionsListPage from './IncomingBankingTransactionsList';

type Props = {
  location: Location;
};

const IncomingBankingTransactionListPage = (props: Props) => {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  useBrowserTitle(getTranslation('incoming_banking_transactions.incoming'));

  return (
    <ListPage
      entitySubjectType={entitySubjectType.incomingbankingtransaction}
      subjectType={getTranslation('incoming_banking_transactions.incoming_banking_transactions')}
      topBar={<IncomingBankingTransactionsFilterBar search={props.location.search} />}
      list={<IncomingBankingTransactionsListPage />}
    />
  );
};

export default observer(IncomingBankingTransactionListPage);
