import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { useAppContext } from '../../app-context/app-context';
import { Link } from '../../design-system/Components';
import IconParagraph from '../Icon/IconParagraph';
import css from './crumb.module.scss';

export type CrumbProps = {
  /**
   * url is defined when active
   */
  url?: string;
  className?: string;
  icon?: string;
  text?: string;
  extraLeft?: ReactNode;
};

export default function Crumb(props: CrumbProps) {
  const { icon, text, className, extraLeft = null, url = '' } = props;
  const { i18n } = useAppContext();

  const textValue = text || i18n.getTranslation('general.empty');
  const mainComponent = url ? (
    <Link id="crumb-link" icon={icon} url={url} bold>
      {textValue}
    </Link>
  ) : (
    <IconParagraph className={css['active']} iconType={icon || ''}>
      {textValue}
    </IconParagraph>
  );

  return (
    <div className={classNames(css['crumb'], className)}>
      {extraLeft}
      {mainComponent}
    </div>
  );
}
