import { CompareMonthType } from '@zf/stella-react/src/atoms/Date/DateInput/month-input';

import { useTracked } from '../context/consumptions-context';
import useServiceConsumptions from './useServiceConsumptions';

export default function useCompareFilters() {
  const [state] = useTracked();

  const { setState } = useServiceConsumptions();

  const addComparisonYear = () => {
    // Subtract 1 from the first year by default
    const min = Math.min.apply(null, state.compareYears);
    setState({ compareYears: [...state.compareYears, min - 1] });
  };

  const removeComparisonYear = (index: number) => {
    const yearsClone = [...state.compareYears];
    yearsClone.splice(index, 1);
    setState({ compareYears: yearsClone });
  };

  const addComparisonMonth = () => {
    // Subtract 1 from the first year by default
    const years = state.compareMonths.map((cm) => {
      return cm.year;
    });

    // Use January as default & overwrite when another month is selected
    let defaultMonth = 1;

    if (state.compareMonths.length > 0) {
      defaultMonth = state.compareMonths[0].month;
    }

    const min = Math.min.apply(null, years);
    setState({ compareMonths: [...state.compareMonths, { year: min - 1, month: defaultMonth }] });
  };

  const removeComparisonMonth = (index: number) => {
    const monthsClone = [...state.compareMonths];
    monthsClone.splice(index, 1);
    setState({ compareMonths: monthsClone });
  };

  const setCompareMonths = (index: number, newMonth: CompareMonthType) => {
    const arrayClone = [...state.compareMonths];
    arrayClone[index] = newMonth;
    setState({
      compareMonths: arrayClone
    });
  };

  const setCompareYears = (index: number, newYear: number) => {
    const arrayClone = [...state.compareYears];
    arrayClone[index] = newYear;

    setState({
      compareYears: arrayClone
    });
  };

  return {
    compareYears: state.compareYears,
    compareMonths: state.compareMonths,
    addComparisonYear,
    removeComparisonYear,
    addComparisonMonth,
    removeComparisonMonth,
    setCompareMonths,
    setCompareYears
  };
}
