import classNames from 'classnames';
import Interweave from 'interweave';
import React from 'react';

import EmptyStateImg from '@zf/stella-react/src/atoms/Image/EmptyState/EmptyStateImg';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import css from './empty-column.module.scss';

type Props = {
  image: string;
  text: string;
  className?: string;
  title?: string;
  style?: React.CSSProperties;
  extra?: React.ReactNode;
};

export default function EmptyColumn(props: Props) {
  const { title, image, text, className, extra, style = {} } = props;

  return (
    <div className={classNames(css['empty-column'], className)} style={style}>
      <EmptyStateImg image={image} title={title ? title : text} />
      <Paragraph>
        <Interweave content={text} />
      </Paragraph>
      {extra && <div className={css['extra']}>{extra}</div>}
    </div>
  );
}
