import Interweave from 'interweave';
import React from 'react';

import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteIncomingInvoice } from './incoming-invoice';

type Props = {
  incomingInvoice: IncomingInvoiceType;
  deleteIncomingInvoiceInState: (id: string) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { incomingInvoice, deleteIncomingInvoiceInState } = props;

  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteIncomingInvoice(incomingInvoice.id, tenantReducer, i18n.lang);

        deleteIncomingInvoiceInState(incomingInvoice.id);

        notify.success({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_incoming_invoice_success', {
            invoiceNumber: incomingInvoice.invoiceNumber
          })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('property_groups.tabs.billing.delete_incoming_invoice_fail', {
            invoiceNumber: incomingInvoice.invoiceNumber
          }),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      <Interweave
        content={i18n.getTranslation('property_groups.tabs.billing.delete_incoming_invoice_paragraph', {
          invoiceNumber: incomingInvoice.invoiceNumber
        })}
      />
    </Paragraph>
  );
});
