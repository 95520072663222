import { notify } from 'events/notification-events';
import NoTenants from 'features/tenants/NoTenants';
import { observer } from 'mobx-react';
import { instance } from 'PublicClientMsal';
import React, { useEffect, useMemo, useReducer } from 'react';

// import Zendesk from 'react-zendesk';
import { navigate } from '@reach/router';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { useAppContext } from '../app-context';
import { Loader } from '../components/Loader';
import useLocalRefresh from '../hooks/useLocalRefresh';
import { useStore } from '../hooks/useStore';
import OrganizationRouter from './OrganizationRouter';
import Tenants from './tenants/tenants';

// const ZENDESK_KEY = 'd856a33c-b5ea-4978-b2a3-43d7828cf15a';

type Props = {
  location: Location;
  tenantName?: string; // URL parameter
  path?: string;
  default?: boolean;
};

interface AppState {
  status: AppStatusType;
}

type AppStatusType =
  | 'initialize'
  | 'tenants_loading'
  | 'tenants_available'
  | 'no_tenants'
  | 'tenant_not_exist'
  | 'redirect_to_org';

const TenantRouter = (props: Props) => {
  const { tenantName, location }: any = props;
  const { tenantReducer, authReducer } = useAppContext();

  const stateReducer = createStateReducer<AppState, Partial<AppState>>();
  const [state, dispatch] = useReducer(stateReducer, {
    status: 'initialize'
  });

  const { refresh, timeStamp } = useLocalRefresh();
  const { applicationStore } = useStore();
  const { userStore, tenantService, setTenantValue, getTranslation } = applicationStore;
  const { user } = userStore;

  const guidRe = new RegExp(`([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})`);

  useEffect(() => {
    dispatch({ status: 'tenants_loading' });
    tenantService
      .getKnownTenants()
      .then((tenantsResponse) => {
        const tenants = tenantsResponse.tenants?.map(({ name }: { name: string }) => name.toUpperCase()) || [];
        if (!guidRe.test(tenantName)) {
          if (tenantName && !tenants.includes(tenantName.toUpperCase())) {
            dispatch({ status: 'tenant_not_exist' });
          } else if (tenants.length > 0 && !tenantName) {
            dispatch({ status: 'tenants_available' });
          } else if (tenants.length === 0) {
            dispatch({ status: 'no_tenants' });
          }
        }

        if (tenantName) {
          // Find matching tenant based on URL guid or shortCode ("tenantName")

          let selectedTenant = null;

          if (guidRe.test(tenantName)) {
            selectedTenant = tenantsResponse.tenants?.find((tenant) => tenant.id === tenantName);
          } else {
            selectedTenant = tenantsResponse.tenants?.find((tenant) => {
              return tenant.name.toUpperCase() === tenantName.toUpperCase();
            });
          }

          if (selectedTenant) {
            tenantReducer.dispatch({ tenant: selectedTenant });
            const account = instance.getActiveAccount();
            //mobx integration tenantvalue
            setTenantValue({ tenant: selectedTenant }, account?.idTokenClaims?.sub);
            dispatch({ status: 'redirect_to_org' });
          }
        } else if (tenantsResponse.tenants?.length === 2) {
          const selectedTenant = tenantsResponse.tenants.filter((e) => e.id !== user?.id);

          tenantReducer.dispatch({ tenant: selectedTenant[0] });
          navigate(`/${selectedTenant[0].name}`);
        } else if (tenantsResponse.tenants?.length === 1) {
          tenantReducer.dispatch({ tenant: tenantsResponse.tenants[0] });
          navigate(`/${tenantsResponse.tenants[0].name}`);
        }

        // I think this causes a rerender but not sure how to quickly solve this in the old state management
        authReducer.dispatch({ isSuperAdmin: tenantsResponse.isSuperAdmin });
      })
      .catch((error) => {
        notify.error({
          content: getTranslation('tenant.get_fail'),
          error
        });
      });
  }, [tenantName, timeStamp]);

  const organisationRouter = useMemo(() => {
    return <OrganizationRouter />;
  }, [location]);

  if (state.status === 'no_tenants' || state.status === 'tenant_not_exist') {
    return <NoTenants state={state.status} />;
  }

  if (state.status === 'tenants_available') {
    return <Tenants refresh={refresh} />;
  }

  if (!user || !tenantReducer.state.tenant || state.status === 'tenants_loading' || state.status === 'initialize') {
    return <Loader />;
  }

  return <>{organisationRouter}</>;
};

export default observer(TenantRouter);
