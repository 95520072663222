import Interweave from 'interweave';
import moment from 'moment';
import React, { forwardRef, MutableRefObject, Ref, useImperativeHandle, useReducer } from 'react';

import { createStateReducer } from '@zf/hooks/src/stateReducer';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import ZFToggle from '@zf/stella-react/src/atoms/Toggle/ZFToggle';
import { colors } from '@zf/utils/src/color';
import { MIN_DATE } from '@zf/utils/src/date';

import PropertyGroupAutoFill, {
  PropertyGroupAutofillProps
} from '../../../../../components/Autofills/PropertyGroupAutoFill';
import DatePicker, { DatePickerProps } from '../../../../../components/Lang/DatePicker';
import { Info, SettingDescription } from '../../../../../design-system/ComponentSets';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '../../../../../design-system/Foundation';
import { notify } from '../../../../../events/notification-events';
import { useStore } from '../../../../../hooks/useStore';
import css from '../../aging-report-card.module.scss';
import { State } from '../../hooks/useCustomerAgingFilter';

type Props = {
  validationRef?: MutableRefObject<ValidationRef | undefined>;
  exportToExcel: (filters: StateValues) => void;
  extraInfo: string;
  propertyGroupExtraInfo: string;
  showDate?: boolean;
  filterState: State;
};

export type StateValues = {
  date: string;
  creditAmounts: boolean;
  propertyGroupId: string;
};

const InlineDatePicker = InlineInputField<DatePickerProps>(DatePicker);
const InlinePropertyGroupAutoFill = InlineInputField<PropertyGroupAutofillProps>(PropertyGroupAutoFill);

function ExportDialog(props: Props, ref: Ref<DialogClickRef | undefined>) {
  const { exportToExcel, extraInfo, propertyGroupExtraInfo, showDate, filterState } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const stateReducer = createStateReducer<StateValues, Partial<StateValues>>();
  const [state, dispatch] = useReducer(stateReducer, {
    date: MIN_DATE,
    creditAmounts: filterState.creditAmounts,
    propertyGroupId: filterState.propertyGroupId
  });

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await exportToExcel(state);
      } catch (e) {
        notify.error({
          content: getTranslation('problem_codes.DATA_EXPORT_FAILED'),
          error: e
        });
      }
    }
  }));

  return (
    <div className={css['export-dialog']}>
      <Paragraph>
        <Interweave content={extraInfo} />
      </Paragraph>

      {showDate && (
        <>
          <SettingDescription
            title={getTranslation('dashboard.customer_aging.select_a_date')}
            description={getTranslation('dashboard.customer_aging.date_extra_info_transactions')}
            setting={
              <InlineDatePicker
                className={css['export-dialog-date-input']}
                id="start-date"
                onChange={(value) => dispatch({ date: value.toISOString() })}
                value={moment(state.date)}
                placeholder={getTranslation('general.date')}
              />
            }
          />

          <HorizontalDivider />
        </>
      )}

      <SettingDescription
        title={getTranslation('dashboard.customer_aging.credit_amounts')}
        setting={<ZFToggle onChange={(val) => dispatch({ creditAmounts: val })} checked={state.creditAmounts} />}
      />

      <HorizontalDivider />

      <SettingDescription
        title={getTranslation('property_groups.property_group')}
        description={propertyGroupExtraInfo}
        setting={
          <InlinePropertyGroupAutoFill
            id="propertyGroup"
            className={css['export-dialog-date-input']}
            onChange={(value) => dispatch({ propertyGroupId: value[0]?.id })}
            selectedValues={[state.propertyGroupId || '']}
            renderTopLvl={false}
          />
        }
      />
      <Info color={colors['silver-600']}>{getTranslation('dashboard.customer_aging.property_info')}</Info>
    </div>
  );
}

export default forwardRef(ExportDialog);
