import clone from 'clone';
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { ParameterTypesType } from '@zf/api-types/general';
import { IncomingInvoiceType } from '@zf/api-types/incoming-invoice';
import { IncomingInvoiceComponentType } from '@zf/api-types/incoming-invoice-component';
import { BillingItemType, ConsumptionUnitType } from '@zf/api-types/product';
import {
  CalculationConfigurationBillingItemType,
  PropertyGroupBillingConfigurationType
} from '@zf/api-types/property-group-billing-configuration';
import { PropertyGroupBillingPeriodType } from '@zf/api-types/property-group-billing-period';
import { PropertyMeteringConfigurationType } from '@zf/api-types/property-metering-configuration';
import { TaxCodeType } from '@zf/api-types/tax-codes';

type State = {
  propertyGroupBillingConfiguration: PropertyGroupBillingConfigurationType | undefined;
  propertyMeteringConfiguration: PropertyMeteringConfigurationType | undefined;
  billingItems: BillingItemType[];
  calculationTypes: ParameterTypesType[];
  taxCodes: TaxCodeType[];
  consumptionUnitTypes: ConsumptionUnitType[];
  customEntityPropertyTypes: CustomEntityPropertyType[];
  billingPeriods: PropertyGroupBillingPeriodType[];
  incomingInvoices: IncomingInvoiceType[];
  incomingInvoiceComponents: IncomingInvoiceComponentType[];
  selectedBillingItem: CalculationConfigurationBillingItemType | undefined;
  selectedBillingItemFormulaType: 'quantity' | 'price';
  selectedBillingPeriod: PropertyGroupBillingPeriodType | undefined;
  selectedIncomingInvoice: string;
  didFetch: boolean[]; // Boolean array of our executed API calls
};

type Action =
  | { type: 'SET_PROPERTY_BILLING_CONFIG'; config: PropertyGroupBillingConfigurationType; isFetch: boolean }
  | { type: 'SET_PROPERTY_METERING_CONFIG'; config: PropertyMeteringConfigurationType; isFetch: boolean }
  | { type: 'SET_BILLING_ITEMS'; billingItems: BillingItemType[] }
  | { type: 'SET_TAX_CODES'; taxCodes: TaxCodeType[] }
  | { type: 'SET_CONSUMPTION_UNIT_TYPES'; consumptionUnitTypes: ConsumptionUnitType[] }
  | { type: 'SET_CUSTOM_ENTITY_PROPERTY_TYPES'; customEntityPropertyTypes: CustomEntityPropertyType[] }
  | { type: 'SET_CALC_TYPES'; calculationTypes: ParameterTypesType[] }
  | { type: 'SET_BILL_PERIODS'; billingPeriods: PropertyGroupBillingPeriodType[]; isFetch: boolean }
  | { type: 'SET_INC_INV_COMPONENTS'; components: IncomingInvoiceComponentType[]; isFetch: boolean }
  | { type: 'SET_INC_INVOICES'; incomingInvoices: IncomingInvoiceType[]; isFetch: boolean }
  | { type: 'SET_SELECTED_BILLING_ITEM'; billingItem: CalculationConfigurationBillingItemType }
  | { type: 'SET_SELECTED_BILLING_PERIOD'; period: PropertyGroupBillingPeriodType | undefined }
  | { type: 'UPDATE_INC_INVOICE'; incomingInvoice: IncomingInvoiceType }
  | { type: 'UPDATE_BILLING_PERIOD'; period: PropertyGroupBillingPeriodType }
  | { type: 'SET_SELECTED_INC_INVOICE'; id: string }
  | { type: 'DELETE_INC_INVOICE'; id: string }
  | { type: 'DELETE_BILLING_PERIOD'; id: string }
  | { type: 'SET_SELECTED_BILLING_ITEM_FORMULA_TYPE'; selectedBillingItemFormulaType: 'quantity' | 'price' };

const initialState: State = {
  propertyGroupBillingConfiguration: undefined,
  propertyMeteringConfiguration: undefined,
  billingItems: [],
  calculationTypes: [],
  taxCodes: [],
  consumptionUnitTypes: [],
  customEntityPropertyTypes: [],
  billingPeriods: [],
  incomingInvoices: [],
  incomingInvoiceComponents: [],
  selectedBillingItem: undefined,
  selectedBillingItemFormulaType: 'price',
  selectedBillingPeriod: undefined,
  selectedIncomingInvoice: '',
  didFetch: []
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // Fetch actions
    case 'SET_PROPERTY_BILLING_CONFIG': {
      return action.isFetch
        ? {
            ...state,
            propertyGroupBillingConfiguration: action.config,
            didFetch: [...state.didFetch, true]
          }
        : {
            ...state,
            propertyGroupBillingConfiguration: action.config
          };
    }
    case 'SET_PROPERTY_METERING_CONFIG': {
      return action.isFetch
        ? {
            ...state,
            propertyMeteringConfiguration: action.config,
            didFetch: [...state.didFetch, true]
          }
        : {
            ...state,
            propertyMeteringConfiguration: action.config
          };
    }
    case 'SET_BILLING_ITEMS':
      return {
        ...state,
        billingItems: [...action.billingItems],
        didFetch: [...state.didFetch, true]
      };

    case 'SET_TAX_CODES':
      return {
        ...state,
        taxCodes: [...action.taxCodes],
        didFetch: [...state.didFetch, true]
      };

    case 'SET_CONSUMPTION_UNIT_TYPES':
      return {
        ...state,
        consumptionUnitTypes: [...action.consumptionUnitTypes],
        didFetch: [...state.didFetch, true]
      };

    case 'SET_CUSTOM_ENTITY_PROPERTY_TYPES':
      return {
        ...state,
        customEntityPropertyTypes: [...action.customEntityPropertyTypes],
        didFetch: [...state.didFetch, true]
      };

    case 'SET_CALC_TYPES':
      return {
        ...state,
        calculationTypes: [...action.calculationTypes],
        didFetch: [...state.didFetch, true]
      };

    case 'SET_INC_INVOICES':
      return action.isFetch
        ? {
            ...state,
            incomingInvoices: [...action.incomingInvoices],
            didFetch: [...state.didFetch, true]
          }
        : {
            ...state,
            incomingInvoices: [...action.incomingInvoices]
          };

    case 'SET_INC_INV_COMPONENTS':
      return action.isFetch
        ? {
            ...state,
            incomingInvoiceComponents: [...action.components],
            didFetch: [...state.didFetch, true]
          }
        : {
            ...state,
            incomingInvoiceComponents: [...action.components]
          };

    case 'SET_BILL_PERIODS':
      return action.isFetch
        ? {
            ...state,
            billingPeriods: [...action.billingPeriods],
            didFetch: [...state.didFetch, true]
          }
        : {
            ...state,
            billingPeriods: [...action.billingPeriods]
          };

    // Set actions
    case 'SET_SELECTED_BILLING_ITEM':
      return {
        ...state,
        selectedBillingItem: action.billingItem
      };
    case 'SET_SELECTED_BILLING_ITEM_FORMULA_TYPE':
      return {
        ...state,
        selectedBillingItemFormulaType: action.selectedBillingItemFormulaType
      };
    case 'SET_SELECTED_BILLING_PERIOD':
      return {
        ...state,
        selectedBillingPeriod: action.period
      };
    case 'SET_SELECTED_INC_INVOICE':
      return {
        ...state,
        selectedIncomingInvoice: action.id
      };

    // Update actions
    case 'UPDATE_BILLING_PERIOD': {
      const periodsClone = clone(state.billingPeriods);
      const periodToUpdateIndex = state.billingPeriods.findIndex((bp) => {
        return bp.id === action.period.id;
      });

      if (periodToUpdateIndex !== -1) {
        periodsClone[periodToUpdateIndex] = action.period;

        return {
          ...state,
          billingPeriods: [...periodsClone]
        };
      }
      return state;
    }
    case 'UPDATE_INC_INVOICE': {
      const invoicesClone = clone(state.incomingInvoices);
      const invoiceToUpdateIndex = state.incomingInvoices.findIndex((i) => {
        return i.id === action.incomingInvoice.id;
      });

      if (invoiceToUpdateIndex !== -1) {
        invoicesClone[invoiceToUpdateIndex] = action.incomingInvoice;

        return {
          ...state,
          incomingInvoices: [...invoicesClone]
        };
      }
      return state;
    }

    // Delete actions
    case 'DELETE_INC_INVOICE':
      return {
        ...state,
        incomingInvoices: state.incomingInvoices.filter((ii) => {
          return ii.id !== action.id;
        })
      };
    case 'DELETE_BILLING_PERIOD':
      return {
        ...state,
        billingPeriods: state.billingPeriods.filter((bp) => {
          return bp.id !== action.id;
        })
      };

    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider: BillingProvider, useTracked, useUpdate: useDispatch } = createContainer(useValue);
