import clone from 'clone';
import React from 'react';

import { numberSequenceSegmentType } from '@zf/api-types/enums';
import { UpdateNumberSequenceType } from '@zf/api-types/settings-config';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { SimpleDropdownProps } from '@zf/stella-react/src/atoms/Dropdown/SimpleDropdown/StellaSimpleDropdown';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';
import { Tree, TreeItem } from '@zf/stella-react/src/atoms/Tree';

import { useAppContext } from '../../../app-context';
import Button from '../../../components/Button/Button';
import ConfigHelp from '../../../components/CoachMarks/config-help';
import { Icon } from '../../../components/Icon';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import InputField, { InputFieldProps } from '../../../components/input/InputField';
import SimpleDropdown from '../../../components/Lang/SimpleDropdown';
import { notify } from '../../../events/notification-events';
import css from './number-sequences.module.scss';

type Props = {
  values: UpdateNumberSequenceType[];
  selectedNumberSequence: number;
  setValue: <T>(value: Partial<T>) => void;
};

export type NumberSequenceValidatorType = {
  numberSequences: UpdateNumberSequenceType[];
};

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineSeqSegmentTypeDropdown = InlineInputField<SimpleDropdownProps<numberSequenceSegmentType>>(SimpleDropdown);

export default function SegmentsCard(props: Props) {
  const { values, selectedNumberSequence, setValue } = props;
  const { i18n, enumReducer } = useAppContext();

  const [selectedSegments, setSelectedSegments] = React.useState<number[]>([]);

  const setSegmentType = (index: number, value: numberSequenceSegmentType) => {
    const clonedArray = clone(values);
    clonedArray[selectedNumberSequence].segments[index].segmentType = value;
    setValue({
      values: clonedArray
    });
  };

  const setSegmentLookupValue = (indexSegment: number, index: number, value: string, position: number) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[selectedNumberSequence].segments[indexSegment].segmentLookupValues[index][position] = value;
    setValue({
      values: clonedArray as UpdateNumberSequenceType[]
    });
  };

  const addSegment = () => {
    const clonedArray = clone(values);
    clonedArray[selectedNumberSequence].segments.push({
      segmentType: numberSequenceSegmentType.constant,
      segmentLookupValues: []
    });
    setValue({
      values: clonedArray
    });
  };

  const addSegmentLookupValue = (index: number) => {
    // Constant can only have one lookup value
    if (
      values[selectedNumberSequence].segments[index].segmentType === numberSequenceSegmentType.constant &&
      values[selectedNumberSequence].segments[index].segmentLookupValues.length === 1
    ) {
      notify.warning({
        content: i18n.getTranslation('number_sequence.constant_segment_warning')
      });
    } else {
      const clonedArray = clone(values);
      clonedArray[selectedNumberSequence].segments[index].segmentLookupValues.push(['', '']);
      setValue({
        values: clonedArray
      });
    }
  };

  const addSelectedSegment = (index: number) => {
    const cloned = clone(selectedSegments);
    cloned.push(index);
    setSelectedSegments(cloned);
  };

  const deleteSelectedSegment = (index: number) => {
    const cloned = clone(selectedSegments);
    const toRemove = selectedSegments.findIndex((i) => {
      return i === index;
    });
    cloned.splice(toRemove, 1);
    setSelectedSegments(cloned);
  };

  const deleteSegment = (index: number) => {
    const clonedArray = clone(values);
    clonedArray[selectedNumberSequence].segments.splice(index, 1);

    deleteSelectedSegment(index);

    setValue({
      values: clonedArray
    });
  };

  const deleteSegmentLookupValue = (indexSegment: number, index: number) => {
    const clonedArray = clone(values);
    clonedArray[selectedNumberSequence].segments[indexSegment].segmentLookupValues.splice(index, 1);
    setValue({
      values: clonedArray
    });
  };

  const segmentsContent =
    selectedNumberSequence > -1 && values[selectedNumberSequence].segments
      ? values[selectedNumberSequence].segments.map((segment, segmentIndex) => {
          const segmentLookupRows = segment.segmentLookupValues
            ? segment.segmentLookupValues.map((value, index) => {
                return {
                  searchType: (
                    <InlineInputFieldInput
                      id={`numbersequences.search_value.index-${index}`}
                      value={value[0]}
                      onChange={(val) => setSegmentLookupValue(segmentIndex, index, val, 0)}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus={value[0] ? false : true}
                    />
                  ),
                  value: (
                    <InlineInputFieldInput
                      id={`numbersequences.value.index-${index}`}
                      value={value[1]}
                      onChange={(val) => setSegmentLookupValue(segmentIndex, index, val, 1)}
                    />
                  ),
                  deleteAction: (
                    <DeleteIcon
                      id={`numbersequences.delete_segment.index-${index}`}
                      tooltipFor="segments-table"
                      onClick={() => deleteSegmentLookupValue(segmentIndex, index)}
                    />
                  )
                };
              })
            : [];

          const segmentIsSelected = selectedSegments.includes(segmentIndex);

          return (
            <Tree
              id={`segment-tree-${segmentIndex}`}
              className={css['tree']}
              key={`${segmentIndex}-tree`}
              onClick={() => {
                if (!segmentIsSelected) {
                  addSelectedSegment(segmentIndex);
                }
              }}
              title={
                <div className={css['segment-title']}>
                  <Paragraph>{segmentIndex}</Paragraph>

                  <InlineSeqSegmentTypeDropdown
                    id={`numbersequences.segment_type.index-${segmentIndex}`}
                    onChange={(val) => setSegmentType(segmentIndex, val[0])}
                    values={enumReducer.getEnum('numberSequenceSegmentType')}
                    selectedValues={[segment.segmentType]}
                    placeholder={i18n.getTranslation('general.type')}
                    className={css['tree-input']}
                  />

                  <DeleteIcon
                    id={`numbersequences.delete_segment_type.index-${segmentIndex}`}
                    tooltipFor="segments-table"
                    onClick={() => deleteSegment(segmentIndex)}
                  />

                  <Button
                    id={`numbersequences.add_segment.index-${segmentIndex}`}
                    onClick={() => addSegmentLookupValue(segmentIndex)}
                    type="text"
                    icon="plus"
                    size="small"
                  >
                    {i18n.getTranslation('general.add')}
                  </Button>
                </div>
              }
              clickeableContent={false}
              isOpen={segmentIsSelected}
            >
              <TreeItem id="segment-tree" className={css['tree-item-lookup']}>
                <DeprecatedStaticTable tooltipId="segments-table" rows={segmentLookupRows} accent>
                  <DeprecatedStaticColumn
                    flexWidth="3"
                    label={i18n.getTranslation('number_sequence.search_type')}
                    dataKey="searchType"
                  />
                  <DeprecatedStaticColumn flexWidth="2" label={i18n.getTranslation('general.value')} dataKey="value" />
                  <DeprecatedStaticColumn label="" dataKey="deleteAction" showOnHover />
                </DeprecatedStaticTable>
              </TreeItem>
            </Tree>
          );
        })
      : [];

  return (
    <Card id="segments-card">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={i18n.getTranslation('coachmark.segments.title')}
            content={[i18n.getTranslation('coachmark.segments.paragraph')]}
          />
        }
        extraRight={
          <Button id="add-segment" type="text" icon="plus" onClick={addSegment}>
            {i18n.getTranslation('general.add')}
          </Button>
        }
      >
        {selectedNumberSequence === -1
          ? i18n.getTranslation('number_sequence.segments')
          : `${i18n.getTranslation('number_sequence.segments_selected')} ${enumReducer.getTranslation(
              'numberSequenceReferenceEntity',
              values[selectedNumberSequence].referenceEntity
            )}`}
      </CardHeader>
      {selectedNumberSequence >= 0 ? (
        <CardBody className={css['segments-card-body']} fixedHeight>
          {segmentsContent}
        </CardBody>
      ) : (
        <CardEmptyBody icon={<Icon type="segment" />} title={i18n.getTranslation('number_sequence.no_segments')} />
      )}
    </Card>
  );
}
