import { observer } from 'mobx-react';
import React from 'react';

import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import { ErrorBoundary } from '../../../../design-system/Components';
import FilesStore, { attachmentType } from '../logic/FilesStore';
import FilesCard from './FilesCard';
import { attachmentVisibility, entitySubjectType } from '@zf/api-types/enums';
import { EntityAttachmentFile, Validity } from '@zf/api-types/entity-attachments/entity-attachments';

export type AttachmentGenericBaseType = {
  id: string;
  createdDateTime: string;
  visibility: attachmentVisibility;
  approvalRequired: boolean;
  includeInWelcomeEmail: boolean;
  validity: Validity;
  origin: entitySubjectType;
};

type Props<T> = {
  filesStore: FilesStore<T>;
  entityId: string;
  specificCols?: ColumnType[];
  customerId?: string;
  includeContractFilter?: boolean;
  attachmentType: attachmentType;
  processSpecificCols?: (entityAttachment: T) => Record<string, React.ReactNode>;
  listFiles: (attachment?: T) => EntityAttachmentFile[];
};

const Files = <T extends AttachmentGenericBaseType>(props: Props<T>) => {
  const {
    customerId,
    filesStore,
    specificCols,
    includeContractFilter,
    processSpecificCols,
    listFiles,
    attachmentType
  } = props;
  const { isInitialized } = filesStore;

  return (
    <ErrorBoundary>
      <CardsContainer isLoading={!isInitialized} fullHeight>
        <FilesCard
          listFiles={listFiles}
          attachmentType={attachmentType}
          filesStore={filesStore}
          specificCols={specificCols}
          customerId={customerId}
          includeContractFilter={includeContractFilter}
          processSpecificCols={processSpecificCols}
        />
      </CardsContainer>
    </ErrorBoundary>
  );
};

export default observer(Files);
