import classNames from 'classnames';
import React from 'react';

import { AuthRoleResponse, UserRolesType } from '@zf/api-types/auth';
import useDialog from '@zf/hooks/src/useDialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import Center from '@zf/stella-react/src/helpers/Center';
import { generateColor } from '@zf/utils/src/color';
import { capitalize } from '@zf/utils/src/string';

import { useAppContext } from '../../app-context';
import { getAdmins } from '../../features/organizations/UserManagerContent';
import { UserAvatar } from '../Avatar';
import { DeleteIcon } from '../Icon';
import DeleteUserDialog from './DeleteUserDialog';
import css from './user-card.module.scss';

type Props = {
  index: number;
  type: 'admin' | 'user';
  userRole: UserRolesType;
  userRoles: UserRolesType[];
  roles: AuthRoleResponse[];
  refresh: () => void;
};

export default function UserCard(props: Props) {
  const { index, userRole, userRoles, type, roles, refresh } = props;
  const { i18n, tenantReducer, authReducer } = useAppContext();
  const { tenantAuth } = tenantReducer.state;
  const { isSuperUser, isAdmin } = authReducer;
  const { clickRef, validationRef, onSubmit, openDialog } = useDialog();

  const adminIds = getAdmins(userRoles).map((a) => {
    return a.userDetails.id;
  });

  const openDeleteDialog = () => {
    openDialog({
      title: i18n.getTranslation(`user.delete_heading_${type}`),
      icon: 'cancel',
      content: (
        <DeleteUserDialog
          userRole={userRole}
          ref={clickRef}
          adminIds={adminIds}
          type={type}
          userRoles={userRoles}
          onComplete={refresh}
        />
      ),
      type: 'danger',
      ref: validationRef,
      onSubmit,
      onCancel: () => {},
      buttonPositive: i18n.getTranslation('general.remove')
    });
  };

  return (
    <div className={classNames(css['user-card'], { [css['height-style']]: type === 'admin' })}>
      <div className={css['actions']}>
        {(isSuperUser() || (isAdmin() && type === 'user')) && (
          <DeleteIcon id={`delete-${userRole.userDetails.userName}`} onClick={openDeleteDialog} />
        )}
      </div>
      <UserAvatar>{userRole.userDetails.userName || userRole.userDetails.email}</UserAvatar>
      <Center>
        <div className={css['user-name']}>{userRole.userDetails.userName}</div>
      </Center>
      <Center>
        {type === 'admin' ? (
          <Paragraph className={css['role']}>{i18n.getTranslation('user.administrator')}</Paragraph>
        ) : (
          <div className={css['org-roles']}>
            {userRole.organizationRoles.map((or, index2) => {
              const organisation = tenantAuth?.organizations.find((o) => o.organizationId === or.organizationId);
              const role = roles.find((r) => r.id === or.roleId);

              return (
                organisation &&
                role && (
                  <div key={`org-role-${index}-${index2}`} className={css['org-role-grid']}>
                    <div className={css['org-code']} style={{ backgroundColor: generateColor(organisation.shortCode) }}>
                      {organisation.shortCode?.toUpperCase() || ''}
                    </div>
                    <Paragraph>{capitalize(role.name)}</Paragraph>
                  </div>
                )
              );
            })}
          </div>
        )}
      </Center>
    </div>
  );
}
