import { aggregationFrequency } from '@zf/api-types/enums';

import { useAppContext } from '../../../../../../app-context/app-context';
import { notify } from '../../../../../../events/notification-events';
import { OutputCtxState, useTracked } from '../context/output-context';
import useQueryParams from './useQueryParams';

export default function useGraph() {
  const [state, dispatch] = useTracked();

  const { i18n } = useAppContext();
  const { queryParams, groupByPeriod } = useQueryParams();

  const setState = (newState: Partial<OutputCtxState>) => {
    dispatch({ type: 'UPDATE', newState: newState });
  };

  const setIsLoading = (newValue: boolean) => {
    setState({ graphIsLoading: newValue });
  };

  const toggleCompareMode = () => {
    setState({ isCompareMode: !state.isCompareMode });
  };

  const setSelectedHeaderItem = (itemId: string) => {
    if (state.graphHeaderSelection.includes(itemId)) {
      const newSelection = state.graphHeaderSelection.filter((id) => {
        return id !== itemId;
      });

      // When viewing bar charts and disable consumptions, jump back to line graph
      if (itemId === 'consumptions' && groupByPeriod !== aggregationFrequency.none) {
        setState({
          graphHeaderSelection: newSelection,
          queryParams: { ...queryParams, groupByPeriod: aggregationFrequency.none }
        });
      } else {
        setState({ graphHeaderSelection: newSelection });
      }
    } else if (itemId === 'measurements' && groupByPeriod !== aggregationFrequency.none) {
      notify.warning({
        content: i18n.getTranslation('measurement.group_by_warning')
      });
    } else {
      setState({ graphHeaderSelection: [...state.graphHeaderSelection, itemId] });
    }
  };

  return {
    isLoading: state.graphIsLoading,
    isCompareMode: state.isCompareMode,
    selectedGraphChannels: state.selectedGraphChannels,
    graphHeaderSelection: state.graphHeaderSelection,
    setIsLoading,
    toggleCompareMode,
    setSelectedHeaderItem
  };
}
