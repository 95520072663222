import React from 'react';

import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../../app-context';
import useRefresh from '../../../app-context/hooks/use-refresh';
import DataExportImportAutoFill, {
  DataExportImportAutoFillProps
} from '../../../components/Autofills/DataExportImportAutoFill';
import DeleteAllExportJobsListPageButton from '../../../components/Button/ExportJobs/DeleteAllExportJobsListPageButton';
import DeleteExportJobsListPageButton from '../../../components/Button/ExportJobs/DeleteExportJobsListPageButton';
import DownloadExportJobsListPageButton from '../../../components/Button/ExportJobs/DownloadExportJobsListPageButton';
import GenerateExportJobsListPageButton from '../../../components/Button/ExportJobs/GenerateExportJobsListPageButton';
import TransferListPageButton from '../../../components/Button/ExportJobs/TransferListPageButton';
import FilterBar from '../../../components/Filters/FilterBar';
import { Icon } from '../../../components/Icon';
import useExportJobsFilter from '../hooks/useExportJobsFilter';

type Props = {
  search?: string;
};

const InlineDataExportAutofill = InlineInputField<DataExportImportAutoFillProps>(DataExportImportAutoFill);

export default function ExportJobsFilterBar(props: Props) {
  const { i18n } = useAppContext();
  const {
    overviewCount,
    quickFilter,
    searchValue,
    queryParams,
    filterTags,
    refreshCounts,
    setQueryParams,
    clearQueryParams,
    onFilter,
    onSearch
  } = useExportJobsFilter(props.search);
  const { refresh } = useRefresh();

  return (
    <FilterBar
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      subject={i18n.getTranslation('nav.export-jobs')}
      refreshPage={refresh}
      actions={[
        <GenerateExportJobsListPageButton key="generateExportJob" refreshCounts={refreshCounts} />,
        <TransferListPageButton key="transfer" refreshCounts={refreshCounts} />,
        <DownloadExportJobsListPageButton key="downloadAction" />,
        <DeleteExportJobsListPageButton key="deleteExportJob" refreshCounts={refreshCounts} />,
        <DeleteAllExportJobsListPageButton key="deleteAllExportJobs" onComplete={refresh} />
      ]}
      filterButtons={[
        <FilterButton
          key="all"
          id="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="move-out" />}
          count={overviewCount.all}
          title={i18n.getTranslation('export_jobs.all_export_jobs')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          key="created"
          id="created"
          onTrigger={() => onFilter('created')}
          icon={<Icon type="move-out" />}
          count={overviewCount.created}
          title={i18n.getTranslation('export_jobs.created')}
          activeColor={colors['green-600']}
          active={quickFilter === 'created'}
        />,
        <FilterButton
          key="failed"
          id="failed"
          onTrigger={() => onFilter('failed')}
          icon={<Icon type="move-out" />}
          count={overviewCount.failed}
          title={i18n.getTranslation('export_jobs.failed')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'failed'}
        />
      ]}
      filterInputs={[
        <Label key="label-search-data-import">{i18n.getTranslation('export_jobs.search_data_exports')}</Label>,
        <InlineDataExportAutofill
          id="search-data-exprt"
          key="search-data-exprt"
          onChange={(value) =>
            setQueryParams({
              dataExportId: value[0] ? value[0].id : '',
              dataExportName: value[0] ? value[0].name : ''
            })
          }
          selectedValues={[queryParams.dataExportId ? queryParams.dataExportId : '']}
          initialValue={queryParams.dataExportName ? queryParams.dataExportName : ''}
          endpoint={'/cfg/DataExports'}
          renderTopLvl={false}
        />
      ]}
      onSearchChange={onSearch}
      searchPlaceHolder={i18n.getTranslation('export_jobs.search')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
}
