import { observer } from 'mobx-react';
import React from 'react';

import { Router } from '@reach/router';
import { TabContainer } from '@zf/stella-react/src/atoms/Tabs';

import { ErrorBoundary } from '../../components/Error';
import RouteWrapper from '../../components/RouteWrapper';
import { useStore } from '../../hooks/useStore';
import Route from '../route';
import SettingsTab from './settings/SettingsTab';
import TemplatesTab from './templates/TemplatesTab';
import TranslationsTab from './translations/TranslationsTab';

type Props = {
  location: Location;
  navigate: (href: string) => void;
};

const CommunicationConfigTabContainer = observer((props: Props) => {
  const { location, navigate } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const tabItems = [
    {
      id: 'templates',
      title: getTranslation('communication.templates'),
      isDefault: true
    },
    {
      id: 'global_translations',
      title: getTranslation('communication.global_translations'),
      isDefault: false
    },
    {
      id: 'settings',
      title: getTranslation('nav.settings'),
      isDefault: false
    }
  ];

  const setActiveTab = (tabId: string) => {
    navigate(tabId);
  };

  const splitUrl = location.href.split('/');

  return (
    <TabContainer
      onSelectTab={setActiveTab}
      entityId={getTranslation('nav.communication')}
      selectedTab={splitUrl[splitUrl.length - 1]}
      tabItems={tabItems}
    >
      <ErrorBoundary>
        <Router>
          <RouteWrapper default>
            <Route Component={TemplatesTab} path="templates" default />
            <Route Component={TranslationsTab} path="global_translations" />
            <Route Component={SettingsTab} path="settings" />
          </RouteWrapper>
        </Router>
      </ErrorBoundary>
    </TabContainer>
  );
});

export default CommunicationConfigTabContainer;
