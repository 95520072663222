import moment, { Moment } from 'moment';
import React from 'react';

import { PagedResponseType } from '@zf/api-types/api';
import { customerType } from '@zf/api-types/enums';
import { InvoiceLineTemplateType } from '@zf/api-types/invoice';
import { CustomerType } from '@zf/api-types/master-data/customer';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import {
  BillingItemType,
  BillingTariffType,
  SubscriptionCalculationTypeParametersType,
  UnitPriceTariffCalculationTypeParametersType
} from '@zf/api-types/product';
import { TaxCodeType } from '@zf/api-types/tax-codes';
import { DropdownProps, DropdownValuesType } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';
import { FloatInputFieldProps } from '@zf/stella-react/src/atoms/InputField/stella-float-input';
import { MoneyInputProps } from '@zf/stella-react/src/atoms/InputField/stella-money-input';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';

import { getCurrentRate } from '../../../../../src/utils/taxCode';
import { useAppContext } from '../../../../app-context';
import FloatInput from '../../../../components/input/FloatInput';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import MoneyInput from '../../../../components/input/MoneyInput';
import UnitPriceWrapper from '../../../../components/input/UnitPriceInput';
import DatePicker, { DatePickerProps } from '../../../../components/Lang/DatePicker';
import Dropdown from '../../../../components/Lang/Dropdown';
import { checkDropdownValues } from '../../../../utils/dropdown';
import { calculateNewAmounts } from '../../../../utils/invoice';
import { roundNumber } from '../../../../utils/number';
import { METHODS, sendRequest } from '../../../../utils/request';
import { formatTaxCode, getTaxCodesForInvoiceDate } from '../../../../utils/taxCode';
import css from './invoice-line-multivalue.module.scss';

type Props = {
  showLocationDropdown: boolean;
  value: InvoiceLineTemplateType;
  index: number;
  title: string;
  taxCodes: TaxCodeType[];
  servicedLocations: ServiceLocationType[];
  billingItems: BillingItemType[];
  invoiceDate: Moment;
  customer: CustomerType | null;
  onFocus: (step: string) => void;
  dispatchValue: (value: Partial<InvoiceLineTemplateType>) => void;
};

const WizardInputField = WizardInputWrapper<InputFieldProps>(InputField);
const WizardFloatInput = WizardInputWrapper<FloatInputFieldProps>(FloatInput);
const BillingItemDropdownField = WizardInputWrapper<DropdownProps<BillingItemType>>(Dropdown);
const ContractedServiceDropdownField = WizardInputWrapper<DropdownProps<ServiceLocationType>>(Dropdown);
const TaxCodeDropdownField = WizardInputWrapper<DropdownProps<TaxCodeType>>(Dropdown);
const WizardMoneyInput = WizardInputWrapper<MoneyInputProps>(MoneyInput);
const UnitPriceInput = UnitPriceWrapper(WizardMoneyInput);
const WizardDatePicker = WizardInputWrapper<DatePickerProps>(DatePicker);

export default function InvoiceLine(props: Props) {
  const {
    showLocationDropdown,
    value,
    index,
    taxCodes,
    servicedLocations,
    billingItems,
    invoiceDate,
    customer,
    onFocus,
    dispatchValue
  } = props;

  const { i18n, tenantReducer } = useAppContext();

  const taxCodesDropdown = getTaxCodesForInvoiceDate(taxCodes, invoiceDate).map((taxCode) => ({
    value: taxCode,
    text: formatTaxCode(taxCode, moment(value.startDateTime))
  }));

  const servicedLocationsDropdown: DropdownValuesType<ServiceLocationType>[] = servicedLocations.map((l) => {
    return {
      value: l,
      text: l.address?.localizedDisplay || ''
    };
  });

  const billingItemsDropdown = billingItems.map((billingItem) => {
    let text = billingItem.description;

    const params = billingItem.calculationParameters as SubscriptionCalculationTypeParametersType;

    if (params.unitOfMeasure) {
      text = `${billingItem.description} - UoM: ${params.unitOfMeasure}`;
    }

    return {
      value: billingItem,
      text: text
    };
  });

  const handleQuantityChange = (newQuantity: number) => {
    const newAmounts = calculateNewAmounts(newQuantity, value.unitPrice, value.taxRate);
    dispatchValue({
      quantity: newQuantity,
      amountExclVAT: newAmounts.exclVAT,
      amountInclVAT: newAmounts.inclVAT
    });
  };

  const handleUnitPriceChange = (newUnitPrice: number) => {
    const newAmounts = calculateNewAmounts(value.quantity, newUnitPrice, value.taxRate);

    dispatchValue({
      unitPrice: newUnitPrice,
      amountExclVAT: newAmounts.exclVAT,
      amountInclVAT: newAmounts.inclVAT
    });
  };

  const handleTaxCodeChange = (newTaxCode: TaxCodeType[]) => {
    const rewRate = getCurrentRate(newTaxCode[0]?.rates, moment(value.startDateTime))?.rate;
    const newAmounts = calculateNewAmounts(value.quantity, value.unitPrice, rewRate);

    dispatchValue({
      taxCode: newTaxCode[0],
      taxRate: rewRate,
      amountExclVAT: newAmounts.exclVAT,
      amountInclVAT: newAmounts.inclVAT
    });
  };

  const handleDateChange = (date: Moment, type: 'start' | 'end') => {
    let rewRate: number | undefined = 1;

    if (type === 'start') {
      rewRate = getCurrentRate(value.taxCode?.rates || [], date)?.rate;

      const newAmounts = calculateNewAmounts(value.quantity, value.unitPrice, rewRate);

      dispatchValue({
        taxRate: rewRate,
        startDateTime: date,
        amountExclVAT: newAmounts.exclVAT,
        amountInclVAT: newAmounts.inclVAT
      });
    }

    if (type === 'end') {
      rewRate = getCurrentRate(value.taxCode?.rates || [], moment(value.startDateTime))?.rate;

      const newAmounts = calculateNewAmounts(value.quantity, value.unitPrice, rewRate);

      dispatchValue({
        taxRate: rewRate,
        endDateTime: date,
        amountExclVAT: newAmounts.exclVAT,
        amountInclVAT: newAmounts.inclVAT
      });
    }
  };

  const handleBillingItemChange = async (item: BillingItemType[]) => {
    const billingItem = item[0];
    let taxCode: TaxCodeType | undefined = undefined;
    let currentTariff: BillingTariffType | undefined = undefined;

    if (customer?.customerType === customerType.person) {
      taxCode = taxCodes.find((tc) => tc.id === billingItem.personTaxCodeId);
    } else {
      taxCode = taxCodes.find((tc) => tc.id === billingItem.organisationTaxCodeId);
    }

    const tariffs = (
      await sendRequest<PagedResponseType<BillingTariffType>>({
        request: {
          method: METHODS.GET,
          endpoint: '/cfg/BillingTariffs/',
          query: {
            billingItemId: billingItem.id
          }
        },
        tenantReducer,
        lang: i18n.lang
      })
    ).data.results;

    // Get the tariff dat overlaps with the invoice line period
    currentTariff = tariffs.find((t) => {
      return !(
        (moment(t.startDateTime).isBefore(value.startDateTime) &&
          moment(t.endDateTime).isBefore(value.startDateTime)) ||
        (moment(t.startDateTime).isAfter(value.endDateTime) && moment(t.endDateTime).isAfter(value.endDateTime))
      );
    });

    if (currentTariff) {
      const parameters = currentTariff.calculationParameters as UnitPriceTariffCalculationTypeParametersType;

      dispatchValue({
        taxCode,
        billingItem,
        description: billingItem.description,
        unitPrice: parameters.unitTariff
      });
    } else {
      dispatchValue({
        taxCode,
        billingItem,
        description: billingItem.description
      });
    }
  };

  return (
    <div id={`invoiceLine-${index}`} className={css['type-input-wrapper']}>
      <BillingItemDropdownField
        id={`billingItem-${index}`}
        className={css['takesTwoColums']}
        onChange={handleBillingItemChange}
        placeholder={i18n.getTranslation('invoice.lines.billingItem')}
        onFocus={onFocus}
        values={checkDropdownValues(billingItemsDropdown, i18n.getTranslation('invoice.validation.no_billingItems'))}
        selectedValues={[value.billingItem ? value.billingItem.id : '']}
      />
      <WizardInputField
        id={`description-${index}`}
        onChange={(val) => dispatchValue({ description: val })}
        value={value.description}
        placeholder={i18n.getTranslation('general.description')}
        onFocus={onFocus}
        error={!value.description}
      />
      <WizardFloatInput
        id={`quantity-${index}`}
        onChange={handleQuantityChange}
        value={value.quantity}
        placeholder={i18n.getTranslation('invoice.lines.quantity')}
        onFocus={onFocus}
        error={!value.quantity}
      />
      <UnitPriceInput
        id={`unitPrice-${index}`}
        placeholder={i18n.getTranslation('invoice.lines.unitPrice')}
        value={value.unitPrice}
        roundNumberIndicator={4}
        onChange={handleUnitPriceChange}
        onFocus={onFocus}
        error={value.unitPrice === 0}
      />
      <TaxCodeDropdownField
        id={`taxCode-${index}`}
        onChange={handleTaxCodeChange}
        placeholder={i18n.getTranslation('invoice.lines.taxCode')}
        onFocus={onFocus}
        values={checkDropdownValues(taxCodesDropdown, i18n.getTranslation('invoice.validation.no_taxCodes'))}
        selectedValues={[value.taxCode ? value.taxCode.id : '']}
        error={!value.taxCode}
      />
      <WizardMoneyInput
        id={`amountExclVAT-${index}`}
        placeholder={i18n.getTranslation('invoice.lines.lineAmountExclVAT')}
        value={roundNumber(value.amountExclVAT, 2)}
        error={value.amountExclVAT === 0}
        disabled
        onChange={() => {}}
      />
      <UnitPriceInput
        id={`amountInclVAT-${index}`}
        placeholder={i18n.getTranslation('invoice.lines.lineAmountInclVAT')}
        value={roundNumber(value.amountInclVAT, 2)}
        error={value.amountInclVAT === 0}
        disabled
        onChange={() => {}}
      />
      {showLocationDropdown ? (
        <ContractedServiceDropdownField
          id={`serviceLocation-${index}`}
          className={css['takesTwoColums']}
          onChange={(val) =>
            dispatchValue({
              serviceLocationId: val[0]?.id || ''
            })
          }
          placeholder={i18n.getTranslation('invoice.lines.serviceLocation')}
          conditionMessage={i18n.getTranslation('invoice.validation.no_customer_selected')}
          onFocus={onFocus}
          values={servicedLocationsDropdown}
          selectedValues={[value.serviceLocationId]}
          clear
        />
      ) : null}

      <WizardDatePicker
        id={`start-date-${index}`}
        onChange={(value) => handleDateChange(value, 'start')}
        value={value.startDateTime}
        placeholder={i18n.getTranslation('general.start_date')}
      />
      <WizardDatePicker
        id={`end-date-${index}`}
        onChange={(value) => handleDateChange(value, 'end')}
        value={value.endDateTime}
        placeholder={i18n.getTranslation('general.end_date')}
      />
    </div>
  );
}
