import classNames from 'classnames';
import React, { useEffect } from 'react';

import { useElementDimensions } from '@zf/hooks/src/useElementDimensions';
import DropdownBody from '@zf/stella-react/src/atoms/Dropdown/DropdownBody';
import { DropdownGenericBaseType, DropdownProps } from '@zf/stella-react/src/atoms/Dropdown/StellaDropdown';

import css from './search-auto-fill.module.scss';
import { AutoFillToolsType } from './useAutoFill';

export type CustomAutoFillProps<V> = {
  id: string;
  selectedValues: string[];
  placeholder?: string;
  initialValue?: string;
  query?: Record<string, any>;
  queryField?: string;
  excludedIds?: string[];
  fetchOnInit?: boolean;
  disabled?: boolean;
  error?: boolean;
  renderTopLvl?: boolean;
  onChange: (value: V[]) => void;
  onFocus?: () => void;
  customProcessAutoFillItem?: (entity: V) =>
    | {
        value: V;
        text: string;
      }
    | undefined;
};

export type AutoFillProps<V> = DropdownProps<V> & {
  autoFillTools: AutoFillToolsType<V>;
  focusedIndex: number | undefined;
  processAutoFillItem: (entity: V) =>
    | {
        value: V;
        text: string;
      }
    | undefined;
  filters?: JSX.Element | null;
  details?: JSX.Element | null;
  selectSingleItemByDefault?: boolean;
};

export default function SearchAutoFill<V extends DropdownGenericBaseType>(props: AutoFillProps<V>) {
  const { filters, details, autoFillTools, className } = props;
  const { values, loading, searchValue, showTooltip, focusedIndex, totalAmountOfRecords, setSearchValue, setState } =
    autoFillTools;

  const handleOpen = () => setState({ showTooltip: true });

  const [ref, sizes] = useElementDimensions<HTMLDivElement>();

  useEffect(() => {
    setState({ focusedIndex: 0 });
  }, [searchValue]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      ref={ref}
      className={classNames(css['search-autofill-container'], className)}
      onFocus={handleOpen}
      role="tooltip"
    >
      <DropdownBody
        {...props}
        totalAmountOfRecords={totalAmountOfRecords}
        values={values}
        loading={loading}
        dropdownWidth={sizes.width}
        showTooltip={showTooltip}
        searchValue={searchValue}
        filters={filters}
        details={details}
        focusedIndex={focusedIndex}
        setSearchValue={setSearchValue}
        handleOpen={handleOpen}
        setShowTooltip={(value) => setState({ showTooltip: value })}
        setFocusedIndex={(value) => setState({ focusedIndex: value })}
        viewType="search"
      />
    </div>
  );
}
