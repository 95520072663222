import React, { useCallback } from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { ButtonProps } from '../../design-system/Components/Button/Button';
import { ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { DialogLocationsType, DialogType } from '../../events/dialog-events';
import Button from './Button';

export type ConfirmationActionButtonProps = Partial<ButtonProps> & {
  id: string;
  actionTitle?: JSX.Element | null;
  dialogTitle: string;
  dialogContent: React.ReactNode;
  dialogType?: DialogType;
  dialogLocation?: DialogLocationsType;
  validationRef?: React.MutableRefObject<ValidationRef | undefined>;
  buttonNegative?: string;
  buttonPositive?: string;
  isDismissDialog?: boolean;
  extraRight?: JSX.Element;
  stackOrder?: number | undefined;
  showBtnIcon?: boolean;
  type?: string;
  onSubmit: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  warn?: () => void;
};

export default function ConfirmationActionButton(props: ConfirmationActionButtonProps) {
  const {
    id,
    children,
    icon,
    dialogTitle,
    actionTitle,
    dialogContent,
    extraRight,
    validationRef,
    stackOrder,
    buttonNegative,
    buttonPositive,
    dialogType = 'default',
    dialogLocation = 'normal',
    isDismissDialog = false,
    disabled = false,
    showBtnIcon = true,
    type = 'primary',
    warn,
    onSubmit,
    onCancel = () => {}
  } = props;

  const { openDialog } = useDialog();

  const handleTrigger = useCallback(() => {
    if (warn) {
      warn();
    } else if (!disabled) {
      openDialog({
        title: dialogTitle,
        icon: icon,
        content: dialogContent,
        type: dialogType,
        ref: validationRef,
        locationType: dialogLocation,
        buttonNegative,
        buttonPositive,
        onSubmit,
        onCancel,
        stackOrder,
        extraRight,
        isDismissDialog
      });
    }
  }, [warn, openDialog]);

  return (
    <Button
      {...props}
      type={type}
      id={id}
      icon={showBtnIcon ? icon : undefined}
      danger={dialogType === 'danger'}
      onClick={handleTrigger}
    >
      {children || actionTitle || dialogTitle}
    </Button>
  );
}
