import { AgingReportTransactionType } from '@zf/api-types/aging-report';
import { action, makeObservable, observable } from 'mobx';
import RootStore from '../../../../app-context/stores';
import CreateRequestService from '../../../../components/units/List/logic/createRequestService';
import InfiniAPIService from '../../../../components/units/List/logic/infiniAPIService';
import { CustomerAgingTransactionsRowType } from '../overdue-transactions-card';
import AgingReportService from '../services/AgingReportService';

class AgingReportStore {
  public rootStore: RootStore;
  public agingReportService: AgingReportService;
  public createRequestService: CreateRequestService;
  public infiniApiService: InfiniAPIService<AgingReportTransactionType, CustomerAgingTransactionsRowType> | null;
  public transactionsLoading: boolean = false;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.agingReportService = new AgingReportService(this);
    this.createRequestService = new CreateRequestService();

    makeObservable(this, {
      createRequestService: observable,
      infiniApiService: observable,
      transactionsLoading: observable,
      setInfiniAPIService: action,
      getTransactionsForCustomer: action
    });
  }

  setInfiniAPIService = async (
    processRecord: (record: AgingReportTransactionType) => CustomerAgingTransactionsRowType,
    customerId?: string
  ) => {
    if (customerId) {
      this.transactionsLoading = true;
      this.infiniApiService = new InfiniAPIService(this.rootStore, processRecord);
      await this.getTransactionsForCustomer(customerId);
      this.transactionsLoading = false;
    } else {
      this.infiniApiService = null;
    }
  };

  getTransactionsForCustomer = async (customerId: string) => {
    const request = await this.createRequestService.createRequest({
      endpoint: `/bill/custAging/${customerId}`
    });
    await this.infiniApiService?.fetchRows(request);
  };
}

export default AgingReportStore;
