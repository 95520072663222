import React from 'react';

import { aggregationFrequency } from '@zf/api-types/enums';
import Center from '@zf/stella-react/src/helpers/Center';

import { useAppContext } from '../../../../../../app-context/app-context';
import Button from '../../../../../../components/Button/Button';
import DataGridCardHeaderSection from '../../../../../../components/DataGridCard/DataGridCardHeaderSection';
import CompareButton from '../../../../../../components/graph/compare/CompareButton';
import GraphDateFilters from '../../../../../../components/graph/filters/GraphDateFilters';
import GroupByTabs from '../../../../../../components/graph/filters/GroupByTabs';
import { TabSlider } from '../../../../../../design-system/Components';
import { SelectionTabItemType } from '../../../../../../design-system/Components/TabSlider/Tab';
import useGraph from '../shared-hooks/useGraph';
import useQueryParams from '../shared-hooks/useQueryParams';
import css from './graph-output-header.module.scss';
import useRegularGraphFilters from './regular-mode/hooks/useRegularGraphFilters';

type Props = {
  changeViewMode: () => void;
};

export default function GraphOutputHeader(props: Props) {
  const { changeViewMode } = props;
  const { i18n } = useAppContext();

  const { isCompareMode, graphHeaderSelection, setSelectedHeaderItem, toggleCompareMode } = useGraph();
  const { groupByPeriod, startDateTime, endDateTime, setGroupByPeriod, setDates } = useQueryParams();
  const { setYears } = useRegularGraphFilters();

  const [outputTypeTabItems] = React.useState<SelectionTabItemType<Record<string, any>>[]>([
    {
      id: 'measurements',
      title: i18n.getTranslation('meter.measurements'),
      icon: 'barchart'
    },
    {
      id: 'consumptions',
      title: i18n.getTranslation('meter.consumptions'),
      icon: 'meter2'
    }
  ]);

  const consumptionsSelected = graphHeaderSelection.includes('consumptions');

  return (
    <DataGridCardHeaderSection className={css['graph-header']}>
      <TabSlider
        id="output-type"
        tabItems={outputTypeTabItems}
        selectedTabItems={graphHeaderSelection}
        setSelectedItem={setSelectedHeaderItem}
        type="white"
        showCheckBoxes
        isMultiSelect
      />

      <>
        <div className={css['time-filters']}>
          {consumptionsSelected && (
            <>
              <GroupByTabs
                isCompareMode={isCompareMode}
                groupByPeriod={groupByPeriod}
                setGroupByPeriod={setGroupByPeriod}
              />

              {!isCompareMode && (
                <Center type="vertical">
                  <div className={css['line']} />
                </Center>
              )}
            </>
          )}

          {!isCompareMode && (
            <GraphDateFilters
              groupByPeriod={groupByPeriod}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              setDates={setDates}
              setYears={setYears}
            />
          )}
        </div>

        {!isCompareMode &&
          consumptionsSelected &&
          groupByPeriod !== aggregationFrequency.none &&
          groupByPeriod !== aggregationFrequency.daily && <CompareButton toggleCompareMode={toggleCompareMode} />}
      </>

      <Button id="viewmode" icon="list" type="text" onClick={changeViewMode}>
        {i18n.getTranslation('measurement.viewmode_list')}
      </Button>
    </DataGridCardHeaderSection>
  );
}
