import React from 'react';

import css from './breadcrumb.module.scss';

type Props = {
  children: React.ReactNode;
};

export default function BreadCrumb(props: Props) {
  const { children } = props;

  return <div className={css['breadcrumb']}>{children}</div>;
}
