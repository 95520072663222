import classNames from 'classnames';
import { exportArrayToExcel } from 'components/Button/Excel/ExportToExcel';
import { Button, TooltipTrigger } from 'design-system/Components';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { getMeteringIssuesExportArray } from 'utils/metering-issues';

import { MeteringIssue } from '@zf/api-types/metering/metering-issues';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import { IssueActionType } from '../IssueSidePanel';
import css from './resolve-actions.module.scss';
import { List } from '@zf/stella-react/src/atoms/List';
import { Icon } from 'components/Icon';
import { CreateMutingRuleListPageButton } from 'components/Button/Meter/CreateMutingRuleListPageButton';
import { MeterType } from '@zf/api-types/master-data/meter';
import { colors } from '@zf/utils/src/color';

type Props = {
  isUnresolved?: boolean;
  hasUniqueIssueType?: boolean;
  onSubmit: (type: IssueActionType) => Promise<void>;
  isValidating?: boolean;
  issues: MeteringIssue[];
  refresh?: () => void;
  ignoreMessage?: string;
  meters?: MeterType[];
};

const ResolveActions = (props: Props) => {
  const {
    isUnresolved = false,
    hasUniqueIssueType = true,
    onSubmit,
    isValidating,
    issues,
    ignoreMessage,
    meters
  } = props;

  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  //EXPORT LOGIC INDEPENDENT OF SUBMITTING THE FORM
  const exportToExcel = () => {
    const exportArray = getMeteringIssuesExportArray(issues, applicationStore);

    exportArrayToExcel(
      issues.length > 1
        ? getTranslation('meter_issues.metering_issues')
        : getTranslation('meter_issues.metering_issue'),
      () => exportArray
    );
  };

  return (
    <FlexElements className={classNames(css['actions'])} justifyContent="center">
      {isUnresolved && (
        <>
          <Button
            id="issues.resolve"
            icon="check"
            onClick={() => onSubmit('resolve')}
            type="primary"
            disabled={isValidating}
          >
            {getTranslation('meter_issues.accept_issue')}
          </Button>
          {hasUniqueIssueType ? (
            <div role="button">
              <TooltipTrigger
                placement="bottom-start"
                trigger="click"
                tooltip={
                  <List>
                    {[
                      <Button
                        id="issues.ignore"
                        icon="ignore"
                        key="ignore"
                        onClick={() => onSubmit('ignore')}
                        type="text"
                        style={{ color: colors['graphite'] }}
                      >
                        {getTranslation('general.ignore')}
                      </Button>,
                      <CreateMutingRuleListPageButton
                        useCase="issue-panel"
                        issues={issues}
                        meters={meters}
                        key={'ignore-and-create'}
                        ignoreMessage={ignoreMessage}
                        withIgnore
                        refresh={() => onSubmit('ignore-and-mute')}
                      />
                    ]}
                  </List>
                }
              >
                <Button
                  id="issues.ignore.dummy"
                  icon="ignore"
                  style={{ alignItems: 'center' }}
                  onClick={() => {}}
                  type="secondary"
                >
                  {getTranslation('general.ignore')} <Icon type="caret-down"></Icon>
                </Button>
              </TooltipTrigger>
            </div>
          ) : (
            <Button id="issues.ignore" icon="ignore" onClick={() => onSubmit('ignore')} type="secondary">
              {getTranslation('general.ignore')}
            </Button>
          )}
        </>
      )}
      <Button id="issues.export" icon="export" onClick={exportToExcel} type="text">
        {getTranslation('general.export')}
      </Button>
    </FlexElements>
  );
};

export default observer(ResolveActions);
