import classNames from 'classnames';
import React, { useState } from 'react';

import { List } from '@zf/stella-react/src/atoms/List';

import { Icon } from '../../Foundation';
import OutsideComponentClickDetector from '../Tooltip/OutsideComponentClickDetector/OutsideComponentClickDetector';
import TooltipTrigger from '../Tooltip/TooltipTrigger/TooltipTrigger';
import css from './action-button.module.scss';
import BaseButton, { ButtonType } from './BaseButton';

export type ActionButtonProps = {
  children: string;
  type?: ButtonType;
  className?: string;
  icon?: string;
  actions?: React.ReactNode;
};

export default function ActionButton(props: ActionButtonProps) {
  const { children, icon, className, actions, type = 'primary' } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const iconComponent = icon ? <Icon name={icon} /> : null;

  return (
    <TooltipTrigger
      tooltip={
        <OutsideComponentClickDetector shouldTrigger={showTooltip} action={setShowTooltip}>
          <List onClick={() => setShowTooltip(false)}>{actions}</List>
        </OutsideComponentClickDetector>
      }
      trigger="click"
      placement="bottom-end"
      showTooltip={showTooltip}
      hasPadding={false}
    >
      <BaseButton
        id="actions"
        className={classNames(css['action-button'], css[type], className)}
        onClick={() => setShowTooltip(true)}
      >
        {iconComponent}
        <div className={css[`center-part-${type}`]}>{children}</div>
        <Icon name="caret-down" />
      </BaseButton>
    </TooltipTrigger>
  );
}
