import React from 'react';

import { OrganisationOverviewCountType } from '@zf/api-types/analytics/analytics';
import { TenantOrganisationType } from '@zf/api-types/auth';

import { createHeader, METHODS, sendRequest } from '../../utils/request';
import { useAppContext } from '../app-context';

const useInsights = () => {
  const { i18n, tenantReducer } = useAppContext();
  const { tenant } = tenantReducer.state;
  const { tenantAuth } = tenantReducer.state;
  const [insights, setInsights] = React.useState<Array<OrganisationOverviewCountType | undefined>>();

  const getOrganisationInsights = async (organisation: TenantOrganisationType) => {
    try {
      return (
        await sendRequest<OrganisationOverviewCountType>({
          request: {
            method: METHODS.GET,
            endpoint: '/insights/organisations'
          },
          customHeaders: createHeader({
            tenant: tenant?.id,
            organization: organisation?.organizationId
          }),
          lang: i18n.lang
        })
      ).data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getAllOrganisationInsights = async () => {
    if (tenantAuth) {
      return Promise.all(tenantAuth.organizations.map((org) => getOrganisationInsights(org)));
    }
  };

  return { insights, setInsights, getOrganisationInsights, getAllOrganisationInsights };
};

export default useInsights;
