import React, { ReactNode } from 'react';

import css from './disabled.module.scss';

type Props = {
  disabled: boolean;
  children: ReactNode;
};

export default function Disabled(props: Props) {
  const { disabled, children } = props;

  return disabled ? (
    <div className={css['wrapper']}>
      <div className={css['overlay']} />
      <div>{children}</div>
    </div>
  ) : (
    <>{children}</>
  );
}
