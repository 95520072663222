import Measurement from 'actions/meters/add-measurement/Measurement';
import { Button } from 'design-system/Components';
import { SettingDescription } from 'design-system/ComponentSets';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { MIN_DATE } from '@zf/utils/src/date';

import css from './optional-measurement.module.scss';

const OptionalMeasurement = () => {
  const { applicationStore, contractStore } = useStore();
  const { getTranslation } = applicationStore;
  const { moveOutWizardStore, contract } = contractStore.moveOutWizardBaseStore;
  const { moveOutWizardValues, setOptionalMeasurementValue } = moveOutWizardStore;
  const { values, setValue } = moveOutWizardValues;
  const { hasOptionalMeasurement, optionalMeasurements, moveOutDate } = values;

  return (
    <>
      <SettingDescription
        className={css['add-msmt']}
        title={getTranslation('contracts.wizard.terminate.add_a_measurement')}
        description={getTranslation('contracts.wizard.terminate.add_a_measurement_descr')}
        setting={
          <Button
            id="contract.btn-add-msmt"
            icon={hasOptionalMeasurement ? 'cross' : 'plus'}
            onClick={() => setValue({ hasOptionalMeasurement: !hasOptionalMeasurement })}
            type="text"
            danger={hasOptionalMeasurement}
            noSidePadding
          >
            {hasOptionalMeasurement ? '' : getTranslation('general.add')}
          </Button>
        }
      />

      {contract && hasOptionalMeasurement && (
        <FlexElements flexDirection="column" gap="16">
          {contract.serviceLocations.map((l, index) => {
            const optionalMeasurement = optionalMeasurements[index];

            return (
              <div key={l.id} className={css['measurement']}>
                <Measurement
                  measurementIndex={index}
                  mutationDateTime={moveOutDate || moment(MIN_DATE)}
                  value={optionalMeasurement}
                  dispatchValue={(val) => setOptionalMeasurementValue(index, val)}
                  metersQuery={{ sluuids: [l.id] }}
                />
              </div>
            );
          })}
        </FlexElements>
      )}
    </>
  );
};

export default observer(OptionalMeasurement);
