import useDeviceTabs from 'features/devices/shared/hooks/useDeviceTabs';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { FilterButton } from '@zf/stella-react/src/atoms/Button';
import { colors } from '@zf/utils/src/color';

import useRefresh from '../../../../app-context/hooks/use-refresh';
import FilterBar from '../../../../components/Filters/FilterBar';
import { Icon } from '../../../../components/Icon';
import { useStore } from '../../../../hooks/useStore';
import usePrepaymentDeviceFilter from './hooks/usePrepaymentDeviceFilter';

type Props = {
  search?: string;
};

const PrepaymentDevicesFilterBar = (props: Props) => {
  const { search } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;

  const tabItems = useDeviceTabs(applicationStore, meterStore);

  const { overviewCount, quickFilter, searchValue, filterTags, onSearch, onFilter, clearQueryParams } =
    usePrepaymentDeviceFilter(search);

  const { refresh } = useRefresh();

  return (
    <FilterBar
      refreshPage={refresh}
      removeAllFilters={clearQueryParams}
      tags={filterTags}
      tabItems={tabItems}
      defaultTab="meters"
      actions={[]}
      filterButtons={[
        <FilterButton
          id="all"
          key="all"
          onTrigger={() => onFilter('all')}
          icon={<Icon type="meter2" />}
          count={overviewCount.all}
          title={getTranslation('metering_list.filter.all_meters')}
          activeColor={colors['blue-400']}
          active={quickFilter === 'all'}
        />,
        <FilterButton
          id="installed"
          key="installed"
          onTrigger={() => onFilter('installed')}
          icon={<Icon type="meter2" />}
          count={overviewCount.installed}
          title={getTranslation('metering_list.filter.installed_meters')}
          activeColor={colors['green-600']}
          active={quickFilter === 'installed'}
        />,
        <FilterButton
          id="inactive"
          key="inactive"
          onTrigger={() => onFilter('inactive')}
          icon={<Icon type="meter2" />}
          count={overviewCount.inactive}
          title={getTranslation('metering_list.filter.inactive_meters')}
          activeColor={colors['yellow-600']}
          active={quickFilter === 'inactive'}
        />
      ]}
      filterInputs={undefined}
      onSearchChange={onSearch}
      searchPlaceHolder={getTranslation('meter.search_placeholder')}
      searchDebounce={500}
      searchValue={searchValue}
    />
  );
};

export default observer(PrepaymentDevicesFilterBar);
