import ConfirmationActionButton from 'components/Button/ConfirmationActionButton';
import React from 'react';
import { CancelMutingRuleDialogContent } from './CancelMutingRuleDialogContent';
import { useStore } from 'hooks/useStore';
import { MeteringIssueError } from '@zf/api-types/enums';

const CancelMutingRuleActionButton = ({
  errorType,
  onCancel
}: {
  errorType: MeteringIssueError;
  onCancel: () => void;
}) => {
  const {
    applicationStore: { getTranslation, getEnumTranslation }
  } = useStore();

  return (
    <ConfirmationActionButton
      id="issues.ignore"
      dialogTitle={getTranslation('meter_issues.rule_cancel')}
      dialogType="danger"
      dialogContent={<CancelMutingRuleDialogContent errorType={getEnumTranslation('meteringIssueError', errorType)} />}
      icon="cross"
      onSubmit={onCancel}
      type="text"
      buttonPositive={getTranslation('meter_issues.rule_cancel')}
    >
      {getTranslation('general.cancel')}
    </ConfirmationActionButton>
  );
};

export default CancelMutingRuleActionButton;
