import LocationSectionStore, {
  locationSelection
} from 'app-context/stores/master-data/contracts/wizard/move-in/LocationSectionStore';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';

import InvoiceAddress from '../../../shared/InvoiceAddress/InvoiceAddress';

type Props = {
  id: string;
  locationSectionStore: LocationSectionStore;
  location: ServiceLocationType;
  handleFocus?: () => void;
};

const OldContractorInvoiceAddress = (props: Props) => {
  const { id, locationSectionStore, location, handleFocus } = props;

  const { customerStore } = useStore();
  const { getCustomerById } = customerStore.customerService;
  const {
    locationSelectionType,
    oldContractorInvoiceAddress,
    oldContractorInvoiceAddresses,
    getActiveContractForLocation,
    setOldContractorInvoiceAddress,
    addOldContractorInvoiceAddressToMap,
    setSingleLocationInvoiceAddressValue,
    updateOldContractorInvoiceAddressInMap
  } = locationSectionStore;

  const activeContract = getActiveContractForLocation(location);

  useEffect(() => {
    const executeFetch =
      locationSelectionType === locationSelection.single
        ? !oldContractorInvoiceAddress
        : !oldContractorInvoiceAddresses.get(location.id);

    if (activeContract && executeFetch) {
      getCustomerById(activeContract.contractorId).then((c) => {
        locationSelectionType === locationSelection.single
          ? setOldContractorInvoiceAddress(c)
          : addOldContractorInvoiceAddressToMap(location.id, c);
      });
    }
  }, [locationSelectionType, activeContract?.contractorId, location.id]);

  const oldContractorInvoiceAddressInfo =
    locationSelectionType === locationSelection.single
      ? oldContractorInvoiceAddress
      : oldContractorInvoiceAddresses.get(location.id);

  if (!oldContractorInvoiceAddressInfo) return null;

  return (
    <InvoiceAddress
      id={id}
      useCase="old_contractor"
      contractorDisplayName={oldContractorInvoiceAddressInfo.shortDisplayName}
      invoiceAddress={oldContractorInvoiceAddressInfo.invoiceAddress}
      setInvoiceAddressValue={(val) => {
        locationSelectionType === locationSelection.single
          ? setSingleLocationInvoiceAddressValue(val)
          : updateOldContractorInvoiceAddressInMap(location.id, val);
      }}
      handleFocus={handleFocus}
    />
  );
};

export default observer(OldContractorInvoiceAddress);
