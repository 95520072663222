import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Spinner } from '@zf/stella-react/src/atoms/Spinner';

import { useStore } from '../../../../hooks/useStore';
import css from './template-translations-preview.module.scss';
import useDialog from '@zf/hooks/src/useDialog';
import { dialog } from '../../../../events/dialog-events';
import UpdateTemplateTranslationsDialog from '../scenario/dialogs/update-template-translations-dialog';
import { ScenarioTranslation } from '@zf/api-types/config/Communication/templates';
import { translationStatus } from '@zf/api-types/enums';
import { colors, convertHexToRGBA } from '@zf/utils/src/color';

const TemplateTranslationsPreview = () => {
  const { communicationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { html, sethtml, translations, setSelectedTranslation } = communicationStore.translationStore;
  const { clickRef, onSubmit } = useDialog();

  useEffect(() => {
    sethtml('');
  }, []);

  const openTranslationsDialog = (rowIndex: number, scenarioTranslation: ScenarioTranslation) => {
    setSelectedTranslation(scenarioTranslation);
    dialog.right({
      stackOrder: 2,
      title: `Translation for ${scenarioTranslation.name}`,
      content: <UpdateTemplateTranslationsDialog ref={clickRef} rowIndex={rowIndex} />,
      buttonPositive: getTranslation('general.save'),
      onSubmit
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (html) {
        const iframe = document.getElementById('email-preview') as HTMLIFrameElement | null;
        const content = iframe?.contentWindow || null;
        const elmnt = content?.document;

        if (iframe) {
          translations?.forEach((t) => {
            const htmlSelector = elmnt?.getElementsByClassName(t.name);
            if (htmlSelector) {
              const arr = Array.from(htmlSelector) as HTMLElement[];
              arr.forEach((e) => {
                e.addEventListener('mouseover', () => {
                  e.style.cursor = 'pointer';
                  if (t.status === translationStatus.translated) {
                    e.style.backgroundColor = convertHexToRGBA(colors['blue-600'], 40);
                  } else if (t.status === translationStatus.missingtranslation) {
                    e.style.backgroundColor = convertHexToRGBA(colors['orange-600'], 40);
                  } else {
                    e.style.backgroundColor = convertHexToRGBA(colors['green-600'], 40);
                  }
                });

                e.addEventListener('mouseout', () => {
                  arr.forEach((e) => {
                    e.style.backgroundColor = 'transparent';
                  });
                });
              });
            }
          });
          translations?.forEach((t, i) => {
            const htmlSelector = elmnt?.getElementsByClassName(t.name);
            if (htmlSelector) {
              const arr = Array.from(htmlSelector) as HTMLElement[];
              arr.forEach((e) => {
                e.style.backgroundColor = 'transparent';
                e.onclick = () => {
                  openTranslationsDialog(i, t);
                };
              });
            }
          });
        }
      }
    }, 100);
  });

  const handleHover = () => {};

  return html ? (
    <iframe
      onMouseOver={handleHover}
      title="email-preview"
      id="email-preview"
      className={css['pdf-iframe-preview']}
      srcDoc={html}
    />
  ) : (
    <div className={css['spinner-wrapper']}>
      <Spinner />
    </div>
  );
};

export default observer(TemplateTranslationsPreview);
