import clone from 'clone';
import React from 'react';

import { RowTypeBase } from '../';

export default function useTableRowHandlers<T extends RowTypeBase>(
  rows: T[],
  selectedRows: string[],
  activatedRows: string[],
  totalAmountOfRows: number,
  noSelect: boolean,
  noDeselect: boolean,
  singleSelect: boolean,
  onSelectRow: ((rowIds: string[]) => void) | undefined,
  onRowsRendered: (renderParams: { startIndex?: number; stopIndex: number; selectAllBusy?: boolean }) => void,
  handleActivateRows: (rowIds: string[]) => void
) {
  const handleSelectRow = (rowIndex: number, event?: React.MouseEvent<HTMLDivElement>) => {
    if (noSelect || !onSelectRow) return;

    const selectedId = rows[rowIndex].__id;
    let selectedClone: string[] = [];

    if (!singleSelect) {
      selectedClone = [...selectedRows];

      /**
       * @description clear activatedRows
       */

      handleActivateRows([]);

      const indexOfSelected = selectedClone.indexOf(selectedId);

      /**
       * @description unSelect selected checkbox
       */

      if (indexOfSelected > -1) {
        selectedClone.splice(indexOfSelected, 1);
      } else {
        if (event && event.shiftKey) {
          selectedClone.push(rows[rowIndex].__id);
        }

        if (!selectedRows.includes(selectedId)) {
          selectedClone.push(selectedId);
        }
      }
    } else if (!selectedRows.includes(selectedId)) {
      selectedClone = [selectedId];
      if (noDeselect) {
        onSelectRow(selectedClone);
      }
    }

    if (!noDeselect) {
      onSelectRow(selectedClone);
    }
  };

  const handleSelectAll = () => {
    if (noSelect || singleSelect || !onSelectRow) return;
    if (selectedRows.length > 0) {
      return onSelectRow([]);
    }

    if (rows.length === totalAmountOfRows) {
      onSelectRow(rows.map((row) => row.__id));
    } else {
      onRowsRendered({ startIndex: 0, stopIndex: totalAmountOfRows, selectAllBusy: true });
    }
  };

  const handleActivateRow = (rowIndex: number, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (noSelect || !onSelectRow) return;

    /**
     * @description get activated id
     */
    const activatedId = rows[rowIndex].__id;

    if (event) {
      /**
       * @description click + shift hold
       */
      if (event.shiftKey) {
        let selectedClone: string[] = [];

        /**
         * @description store already selected activated rows and currenly selected activated id.
         */
        const activatedRowsClone = clone(activatedRows);

        /**
         * @description find is there any existing activated id exists.
         */
        if (!activatedRows.includes(activatedId)) {
          activatedRowsClone.push(activatedId);

          /**
           * @description set activated rows. This lead to find the isActivated var
           */
          handleActivateRows(activatedRowsClone);

          /**
           * @description store all selected and activatedRows. This lead to selected rows when activated tow rows with SHIFT
           */
          selectedClone = [...new Set([...selectedRows, ...activatedRowsClone])];
          onSelectRow(selectedClone);
        }
      } else {
        /**
         * @description click only
         */
        onSelectRow([activatedId]);
      }
    } else {
      onSelectRow([activatedId]);
    }
  };

  const handleItemsRendered = ({
    overscanStartIndex,
    overscanStopIndex,
    visibleStartIndex,
    visibleStopIndex
  }: {
    overscanStartIndex?: number;
    overscanStopIndex?: number;
    visibleStartIndex: number;
    visibleStopIndex: number;
  }) => {
    const start = overscanStartIndex || visibleStartIndex;
    const stop = overscanStopIndex || visibleStopIndex;

    onRowsRendered({ startIndex: start, stopIndex: stop });
  };

  return { handleSelectRow, handleActivateRow, handleSelectAll, handleItemsRendered };}