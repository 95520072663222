import { observer } from 'mobx-react';
import React from 'react';

import { NewStatusBadge } from '@zf/stella-react/src/atoms/Badge';

import { ICON_COLORS } from '../../../../../constants/icons';
import { DetailLine } from '../../../../../design-system/ComponentSets';
import { useStore } from '../../../../../hooks/useStore';

const Bankaccounts = () => {
  const { paymentConfigStore } = useStore();

  const companyBankAccounts = paymentConfigStore.twikeyConfigurationInfo?.companyBankAccounts;

  if (!companyBankAccounts) return null;

  return (
    <>
      {companyBankAccounts.map((cba, index) => (
        <DetailLine key={`${cba.iban}-${index}`} label={cba.iban}>
          <NewStatusBadge status_={cba.active} color={ICON_COLORS[cba.active]} type="bare" />
        </DetailLine>
      ))}
    </>
  );
};

export default observer(Bankaccounts);
