import React from 'react';

import { IncomingBankingTransactionType } from '@zf/api-types/billing/incoming-banking-transaction';

import { useAppContext } from '../../../app-context';
import useDownload from '../../../app-context/hooks/use-download';
import { DropdownAction } from '../../../design-system/ComponentSets';
import { notify } from '../../../events/notification-events';

type Props = {
  incomingTransaction: IncomingBankingTransactionType | undefined;
};

export default function DownloadDetailButton(props: Props) {
  const { i18n, tenantReducer } = useAppContext();
  const { incomingTransaction } = props;
  const { downloadSingleFile } = useDownload();

  return incomingTransaction ? (
    <DropdownAction
      id="invoice.download"
      icon="download-cloud"
      actionType="direct"
      onClick={() => {
        try {
          downloadSingleFile(`/bill/IncomingBankingTransactions/${incomingTransaction.id}/download`, tenantReducer);
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.payments.download_failed'),
            error: e
          });
        }
      }}
    >
      {i18n.getTranslation('actions.download')}
    </DropdownAction>
  ) : null;
}
