import React from 'react';

import { BreadCrumb, Crumb } from '../../components/BreadCrumb';
import { CrumbHeader } from '../../components/Header';
import { useStore } from '../../hooks/useStore';

export default function CommunicationConfigHeader() {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  return (
    <CrumbHeader
      breadcrumb={
        <BreadCrumb>
          <Crumb icon="cog" text={getTranslation('communication.communication_config')} />
        </BreadCrumb>
      }
    />
  );
}
