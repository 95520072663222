import React from 'react';

import { navigate } from '@reach/router';
import { advanceFrequency, invoiceFrequency, managementRelationType, propertyGroupType } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import {
  CreatePropertyGroupRequestType,
  PropertyGroupType,
  UpdateManagementRelation
} from '@zf/api-types/master-data/property-group';
import { ServiceLocationType } from '@zf/api-types/master-data/servicelocation';
import {
  CreatePropertyGroupBillingConfigurationType,
  PropertyGroupBillingConfigurationType
} from '@zf/api-types/property-group-billing-configuration';
import useValidator from '@zf/hooks/src/useValidator';

import { deleteProperty } from '../../../actions/property-group/property-group';
import { useAppContext } from '../../../app-context';
import useWizardAPIErrors from '../../../app-context/hooks/useWizardAPIErrors';
import WizardSubmitButton from '../../../components/Button/WizardSubmitButton';
import { notify } from '../../../events/notification-events';
import { extendMultiValue } from '../../../utils/arrays';
import { METHODS, sendRequest } from '../../../utils/request';
import ContractDefaultsSection from './contract-defaults-section';
import EssentialsSection from './essentials-section';
import LocationsSection from './LocationsSection';
import RelationsSection from './relations-section';

export type Props = {
  feedback: string[][];
  onFocusStep: React.Dispatch<React.SetStateAction<string>>;
  setFeedback: React.Dispatch<React.SetStateAction<string[][]>>;
};

export type NewServiceLocationsType = {
  serviceLocation: ServiceLocationType | null;
};

export type CreatePropertyType = {
  type: propertyGroupType;
  name: string;
  country: string;
  streetName: string;
  streetNumber: string;
  streetNumberAddition: string;
  postalCode: string;
  city: string;
  advanceFrequency: advanceFrequency | null;
  invoiceFrequency: invoiceFrequency | null;
  invoiceDay: number;
  invoiceMonth: number;
  productId: string | null;
  cancellationItemId: string;
  owner: CustomerType | null;
  manager: CustomerType | null;
  paymentTermsId: string | null;
  serviceLocations: NewServiceLocationsType[];
  companyBankAccountId: string | null;
};

export const STEP_NAMES = [
  'property-essentials',
  'property-default-contract',
  'property-relations',
  'property-locations',
  'property-errors'
];

export default function PropertyWizard(props: Props) {
  const { feedback, onFocusStep, setFeedback } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  const { setApiErrors, handleApiErrors } = useWizardAPIErrors(4, setFeedback);

  const { values, errors, setValue, submitFactory } = useValidator<CreatePropertyType>({
    initialValues: {
      type: propertyGroupType.building,
      name: '',
      country: '',
      streetName: '',
      streetNumber: '',
      streetNumberAddition: '',
      postalCode: '',
      city: '',
      advanceFrequency: null,
      invoiceFrequency: null,
      invoiceDay: 0,
      invoiceMonth: 0,
      productId: null,
      cancellationItemId: '',
      owner: null,
      manager: null,
      paymentTermsId: null,
      serviceLocations: [],
      companyBankAccountId: null
    },
    validate: () => {
      const feedback: string[][] = [];

      if (!values.name) {
        extendMultiValue(feedback, 0, i18n.getTranslation('property_groups.validation.name'));
      }

      if (!values.country) {
        extendMultiValue(feedback, 0, i18n.getTranslation('property_groups.validation.country'));
      }

      handleApiErrors(feedback, []);
      return { feedback };
    }
  });

  const handleFocus = (step: string) => {
    return () => onFocusStep(step);
  };

  const handleSubmit = submitFactory(async () => {
    const managementRelations: UpdateManagementRelation[] = [];

    if (values.owner) {
      managementRelations.push({
        customerId: values.owner.id,
        managementRelationType: managementRelationType.owner
      });
    }

    if (values.manager) {
      managementRelations.push({
        customerId: values.manager.id,
        managementRelationType: managementRelationType.propertymanager
      });
    }

    const apiLocationsToAdd = values.serviceLocations.map((l) => {
      return l.serviceLocation ? l.serviceLocation.id : '';
    });

    const apiFriendlyValues: CreatePropertyGroupRequestType = {
      type: values.type,
      name: values.name,
      managementRelations,
      address: {
        streetName: values.streetName,
        streetNumber: values.streetNumber,
        city: values.city,
        postalCode: values.postalCode,
        country: values.country,
        streetNumberAddition: values.streetNumberAddition
      },
      serviceLocations: apiLocationsToAdd
    };

    try {
      const newPropertyGroup = (
        await sendRequest<PropertyGroupType>({
          request: {
            method: METHODS.POST,
            endpoint: '/md/propertyGroups',
            data: apiFriendlyValues
          },
          tenantReducer,
          lang: i18n.lang
        })
      ).data;

      navigate(`${rootUrl}/property-groups/${newPropertyGroup.id}`);
    } catch (e) {
      handleErrors(e);
    }
  });

  const handleErrors = (e: any) => {
    const apiErrors_ = e.data && e.data.errors ? e.data.errors : [];
    handleApiErrors([], apiErrors_);
    setApiErrors(apiErrors_);
  };

  return (
    <>
      <EssentialsSection values={values} setValue={setValue} handleFocus={handleFocus} />
      <ContractDefaultsSection values={values} setValue={setValue} handleFocus={handleFocus} />
      <RelationsSection owner={values.owner} manager={values.manager} handleFocus={handleFocus} setValue={setValue} />
      <LocationsSection locationsToAdd={values.serviceLocations} setValue={setValue} handleFocus={handleFocus} />
      <WizardSubmitButton
        id="property-submit"
        onClick={handleSubmit}
        disabled={errors.feedback.length > 0 && feedback.length > 0}
      >
        {i18n.getTranslation('general.submit')}
      </WizardSubmitButton>
    </>
  );
}
