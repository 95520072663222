import useFilter from 'app-context/hooks/use-filter';
import { ExportIssuesListPageButton } from 'components/Button/MeteringIssues/ExportIssuesListPageButton';
import FilterTag from 'components/Filters/filter-tag';
import FilterBarNew from 'components/Filters/FilterBarNew';
import useDeviceTabs from 'features/devices/shared/hooks/useDeviceTabs';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

import { useStore } from '../../../../hooks/useStore';
import { IssuesContext } from '../Context/Context';
import { ListPageContext } from '../Context/types';
import LeftFilters from './LeftFilters';
import RightFilters from './RightFilters';
import { Query } from './types';
import { ShowMutingRulesListPageButton } from 'components/Button/MutingRules/ShowMutingRulesListPageButton';

const IssuesFilterBar = () => {
  const {
    setFilter,
    store: { filter, listPage }
  } = useContext(IssuesContext);

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;

  const tabItems = useDeviceTabs(applicationStore, meterStore);

  const paramTypes = {
    noticedStartDateTime: StringParam,
    noticedEndDateTime: StringParam,
    quickFilter: StringParam,
    groupBy: ArrayParam,
    propertyGroupIds: ArrayParam,
    propertyGroupNames: ArrayParam,
    meterIds: ArrayParam,
    meterNames: ArrayParam,
    model: ArrayParam,
    refreshTimestamp: StringParam
  };

  const [query, setQuery] = useQueryParams(paramTypes);

  /**
   * @description READ URL for queryParams, if undefined, fallback to defaults and set them to the form
   */
  //

  const { setValue, watch, getValues } = useForm<Partial<Query>>({
    defaultValues: {
      noticedStartDateTime: query.noticedStartDateTime,
      noticedEndDateTime: query.noticedEndDateTime,
      quickFilter: query.quickFilter || 'unresolved',
      groupBy: query.groupBy || ['none'],
      propertyGroupIds: query.propertyGroupIds || [],
      propertyGroupNames: query.propertyGroupNames || [],
      meterIds: query.meterIds || [],
      meterNames: query.meterNames || [],
      refreshTimestamp: query.refreshTimestamp || undefined
    }
  });

  /**
   * @description align the filter context with whatever is on the useForm (ergo the URL)
   */
  useEffect(() => {
    /**
     * @description Time out to avoid weird overwrite
     */
    setTimeout(() => {
      setFilter({
        quickFilter: getValues('quickFilter'),
        queryParams: {
          noticedStartDateTime: getValues('noticedStartDateTime'),
          noticedEndDateTime: getValues('noticedEndDateTime'),
          groupBy: getValues('groupBy'),
          propertyGroupIds: getValues('propertyGroupIds'),
          propertyGroupNames: getValues('propertyGroupNames'),
          meterIds: getValues('meterIds'),
          meterNames: getValues('meterNames'),
          refreshTimestamp: getValues('refreshTimestamp')
        }
      });
    }, 100);
  }, []);

  /**
   * @description update query params as well as context when you are changing filter components
   */
  useEffect(() => {
    const subscription = watch((query) => {
      const { quickFilter, ...queryParams } = query;
      setFilter({ queryParams, quickFilter });
      setQuery(query as any);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const initialOverviewCount = {
    all: 0,
    unresolved: 0
  };

  const { overviewCount } = useFilter<ListPageContext['store']['filter']['queryParams']>({
    endpoint: '/me/MeteringIssues/overviewcount',
    initialCount: initialOverviewCount,
    domain: 'meter-issues',
    search: '',
    initialQueryParams: filter.queryParams,
    paramTypes
  });

  const filterTags: JSX.Element[] = [];
  const meterFilterTags: JSX.Element[] = [];
  const propertyGroupFilterTags: JSX.Element[] = [];

  if (filter.queryParams.noticedStartDateTime || filter.queryParams.noticedEndDateTime) {
    filterTags.push(
      <FilterTag
        type="period"
        value={[filter.queryParams.noticedStartDateTime, filter.queryParams.noticedEndDateTime]}
        removeFilter={() => {
          setValue('noticedStartDateTime', undefined);
          setValue('noticedEndDateTime', undefined);
        }}
        key="detected-between.tag"
        label={getTranslation('general.period')}
      />
    );
  }

  if (filter.queryParams.propertyGroupNames && filter.queryParams.propertyGroupNames.length > 0) {
    for (let i = 0; i < filter.queryParams.propertyGroupNames.length; i++) {
      let pgName = filter.queryParams.propertyGroupNames[i];
      let pgId = filter.queryParams.propertyGroupIds[i];

      propertyGroupFilterTags.push(
        <FilterTag
          type="array"
          value={[pgName]}
          removeFilter={() => {
            setValue(
              'propertyGroupNames',
              filter.queryParams.propertyGroupNames.filter((pgn: any) => {
                return pgn !== pgName;
              })
            );
            setValue(
              'propertyGroupIds',
              filter.queryParams.propertyGroupIds.filter((pgid: any) => {
                return pgid !== pgId;
              })
            );
          }}
          key={`property-groups.tag.${i}`}
          label={getTranslation('property_groups.property_group')}
        />
      );
    }
  }

  if (filter.queryParams.meterNames && filter.queryParams.meterNames.length > 0) {
    for (let i = 0; i < filter.queryParams.meterNames.length; i++) {
      let mName = filter.queryParams.meterNames[i];
      let mId = filter.queryParams.meterIds[i];

      meterFilterTags.push(
        <FilterTag
          type="array"
          value={[mName]}
          removeFilter={() => {
            setValue(
              'meterNames',
              filter.queryParams.meterNames.filter((mn: any) => {
                return mn !== mName;
              })
            );
            setValue(
              'meterIds',
              filter.queryParams.meterIds.filter((mid: any) => {
                return mid !== mId;
              })
            );
          }}
          key={`meter.tag.${i}`}
          label={getTranslation('meter.meter')}
        />
      );
    }
  }

  const issuesToExport = listPage.selectedRows.map((r) => r.__entity);

  return (
    <form>
      <FilterBarNew
        actions={[<ExportIssuesListPageButton key="issues.export" issues={issuesToExport} />]}
        actionsExtraLeft={<ShowMutingRulesListPageButton />}
        tags={
          <>
            {propertyGroupFilterTags}
            {meterFilterTags}
            {filterTags}
          </>
        }
        tabItems={tabItems}
        defaultTab="meters"
        leftFilters={<LeftFilters setValue={setValue} getValues={getValues} overviewCount={overviewCount} />}
        rightFilters={<RightFilters setValue={setValue} getValues={getValues} />}
        searchPlaceHolder={getTranslation('meter.search_placeholder')}
        searchDebounce={500}
      />
    </form>
  );
};

export default observer(IssuesFilterBar);
