import React from 'react';

import css from './step-link.module.scss';

type Props = {
  name: string;
  children?: React.ReactNode;
};

export default function StepLink(props: Props) {
  const { name, children } = props;

  const stepName = '#wizard-step-' + name;

  return (
    <a href={stepName} className={css['step-link']}>
      {children}
    </a>
  );
}
