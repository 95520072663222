import { ImportJobType } from '@zf/api-types/import-job';
import { createFormData } from '@zf/auth/src/utils';
import BaseService from '../../../app-context/services/BaseService';
import ApplicationStore from '../../../app-context/stores/domain/ApplicationStore';

import { MappedFilesType } from '../../../components/input/UploadInput';
import { METHODS } from '../../../utils/request';
import ImportJobStore from '../stores/ImportJobStore';

export default class ImportJobService extends BaseService {
  private importJobStore: ImportJobStore;

  constructor(importJobStore: ImportJobStore, applicationStore: ApplicationStore) {
    super('/logs/ImportJob/', applicationStore);
    this.importJobStore = importJobStore;
  }

  uploadImportJob = async (fileData: MappedFilesType[], fileFormat: string, automaticProcessing: boolean) => {
    const formData = createFormData({ FileFormatId: fileFormat, AutomaticProcessing: automaticProcessing });

    if (fileData.length === 1) {
      formData.append('File', fileData[0].file);
    } else {
      fileData.forEach((f) => formData.append('Files', f.file));
    }

    return (
      await this.applicationStore.sendRequest<ImportJobType>({
        request: {
          method: METHODS.POST,
          endpoint: this.endpoint,
          data: formData
        }
      })
    ).data;
  };
}
