import { Button } from 'design-system/Components';
import React from 'react';

import { CardEmptyBody } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../app-context';
import css from './table-no-data-transparent.module.scss';

export default function TableNoDataTransparent({ isALL, callback }: { isALL: boolean; callback: Function }) {
  const { i18n } = useAppContext();

  return (
    <CardEmptyBody
      image={
        <img
          src="https://cdn.zerofriction.co/shared/assets/emptyStates/meter.png"
          alt="transaction"
          title="transaction"
          width="70"
          height="70"
        />
      }
      className={css['no-issues-yet-label']}
      title={
        isALL
          ? i18n.getTranslation(`meter_issues.no_all_issues`)
          : i18n.getTranslation(`meter_issues.no_unresolved_issues`)
      }
    >
      {i18n.getTranslation('meter_issues.no_unresolved_issues_desc')}
      <Button onClick={() => callback('all')} id="see-all-issues" type="text" className={css['footer']}>
        {i18n.getTranslation('meter_issues.see_all_issues')}
      </Button>
    </CardEmptyBody>
  );
}
