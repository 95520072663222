import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { BillingParametersType } from '@zf/api-types/parameters';

import { BaseLink } from '../../../design-system/Components';
import { Icon } from '../../../design-system/Foundation';
import { useStore } from '../../../hooks/useStore';

export type OrgCardProps = {
  organisationId: string;
  tenantId: string;
  baseUrl: string;
  isResetting: boolean;
};

const APIDependentLinks = (props: OrgCardProps) => {
  const { organisationId, tenantId, baseUrl, isResetting } = props;

  const { organisationStore, applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { getBillingParameters } = organisationStore.organisationService;

  const [billingParameters, setBillingParameters] = useState<BillingParametersType>();

  useEffect(() => {
    if (!isResetting) {
      getBillingParameters(organisationId, tenantId).then((params) => setBillingParameters(params));
    }
  }, [isResetting]);

  if (!billingParameters) return null;

  return (
    <>
      {billingParameters.enableInvoiceCollection && (
        <BaseLink id={`link-collection-cases-${organisationId}`} url={`${baseUrl}/collection-cases`}>
          <Icon
            id={`collection-cases-${organisationId}`}
            name="dunning"
            title={getTranslation('nav.collection_cases')}
          />
        </BaseLink>
      )}
      {!billingParameters.skipPayment && (
        <BaseLink id={`link-payments-${organisationId}`} url={`${baseUrl}/payments/incoming-banking-transactions`}>
          <Icon id={`payments-${organisationId}`} name="batch" title={getTranslation('payments.payments')} />
        </BaseLink>
      )}
    </>
  );
};

export default observer(APIDependentLinks);
