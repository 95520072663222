import React from 'react';

import { ExternalChannelType } from '@zf/api-types/master-data/meter';

import { useAppContext } from '../../../../../../../app-context';
import ChannelSelectionSection from '../../../../../../../components/graph/filters/ChannelSelectionSection';
import { ICON_COLORS, ICONS } from '../../../../../../../constants/icons';
import useGraph from '../../shared-hooks/useGraph';
import useRegularGraphFilters from './hooks/useRegularGraphFilters';

type Props = {
  channels: ExternalChannelType[];
};

export default function GraphChannelSelection(props: Props) {
  const { channels } = props;
  const { enumReducer } = useAppContext();

  const { selectedGraphChannels } = useGraph();
  const { setSelectedGraphChannel } = useRegularGraphFilters();

  const tabItems = channels.map((chann) => {
    return {
      id: chann.externalIdentifier,
      title: chann.description
        ? `${chann.description} (${enumReducer.getTranslation('unitOfMeasure', chann.unitOfMeasure)})`
        : '',
      icon: ICONS[chann.utilityType],
      selectedColor: ICON_COLORS[chann.utilityType]
    };
  });

  return (
    <ChannelSelectionSection
      id="channel-selection"
      tabItems={tabItems}
      selectedGraphChannels={selectedGraphChannels}
      setSelectedGraphChannel={setSelectedGraphChannel}
    />
  );
}
