import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { meterType } from '@zf/api-types/enums';
import { InstallMeterRequestType, MeterType } from '@zf/api-types/master-data/meter';
import { PropertyGroupType } from '@zf/api-types/master-data/property-group';
import { createStateReducer } from '@zf/hooks/src/stateReducer';

import { DialogClickRef, ValidationRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../events/notification-events';
import InstallMeterMultiValue from '../../features/meter-install/meter-multivalue/InstallMeterMultiValue';
import { InstallationType } from '../../features/meter-install/wizard/InstallMeterWizard';

type Props = {
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  propertyGroup: PropertyGroupType;
  meterType_: meterType;
  addMetersToState: (newMeters: MeterType[]) => void;
};

type State = {
  createNewMeter: boolean;
  installations: InstallationType[];
};

const InstallMeterDialog = React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { propertyGroup, validationRef, meterType_, addMetersToState } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { installMeter } = meterStore.deviceService;
  const { addMeasurementMeter } = meterStore.measurementService;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [values, setValue] = React.useReducer(stateReducer, {
    createNewMeter: false,
    installations: []
  });

  const setInstallations = (newInstallations: InstallationType[]) => setValue({ installations: newInstallations });
  const setCreateNewMeter = (val: boolean) => setValue({ createNewMeter: val });

  const validate = () => {
    if (validationRef.current) {
      const errors: boolean[] = [];

      values.installations.forEach((installation) => {
        errors.push(installation.meter === null);

        if (installation.measurements && installation.measurements.length > 0) {
          installation.measurements.forEach((measurement) => {
            errors.push(!measurement.channelId);
            errors.push(measurement.value === undefined);

            if (measurement.endDateTime) {
              errors.push(moment(measurement.endDateTime).isBefore(installation.mutationDateTime));
            }
          });
        }
      });

      validationRef.current.setIsError(errors.includes(true));
    }
  };

  React.useEffect(() => {
    validate();
  }, [values.installations]);

  const submitMeasurements = async () => {
    const newMeasurements = await Promise.all(
      values.installations.map((i) => i.measurements.map((msmt) => addMeasurementMeter(msmt)))
    );

    return newMeasurements;
  };

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      let installsSuccessfull = false;

      // Submit installations
      try {
        const promises = values.installations.reduce((acc: Promise<MeterType>[], i) => {
          if (i.meter) {
            // Backend fills in the external id's
            const channelsWithoutExtId = i.meter.channels.map((chann) => {
              return { ...chann, externalIdentifier: '' };
            });

            const apiFriendlyValues: InstallMeterRequestType = {
              mutationDateTime: i.mutationDateTime.toISOString(),
              serviceLocationId: '',
              propertyGroupId: i.propertyGroupId,
              addressInstalled: i.addressInstalled,
              channelTemplates: channelsWithoutExtId
            };

            acc.push(installMeter(apiFriendlyValues, i.meter?.id || ''));
          }

          return acc;
        }, []);

        const newInstallations = await Promise.all(promises);

        const filtered = newInstallations.reduce((acc: MeterType[], i) => {
          if (typeof i !== 'undefined') {
            acc.push(i);
          }

          return acc;
        }, []);

        addMetersToState(filtered);

        installsSuccessfull = true;

        notify.success({
          content: getTranslation('install_meter.install_meter_success')
        });
      } catch (error) {
        notify.error({
          content: getTranslation('install_meter.install_meter_fail'),
          error
        });
      }

      if (installsSuccessfull) {
        // Submit measurements
        try {
          const newMeasurements = await submitMeasurements();

          // Do not notify if installations didn't contain any measurements
          if (newMeasurements[0] && newMeasurements[0].length > 0) {
            notify.success({
              content:
                newMeasurements.length > 1
                  ? getTranslation('actions.meter.add_measurements_success')
                  : getTranslation('actions.meter.add_measurement_success')
            });
          }
        } catch (error) {
          notify.error({
            content: getTranslation('actions.meter.add_measurement_error'),
            error
          });
        }
      }
    }
  }));

  return (
    <InstallMeterMultiValue
      locationType="property_group"
      propertyGroup={propertyGroup}
      createNewMeter={values.createNewMeter}
      meterType_={meterType_}
      setInstallations={setInstallations}
      setCreateNewMeter={setCreateNewMeter}
    />
  );
});

export default observer(InstallMeterDialog);
