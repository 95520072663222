import { ZFErrorType } from '@zf/api-types/general';

export default function handleError(error: ZFErrorType, info?: object) {
  // Don't handle 404's
  if (error && error.status === 404) return;

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error);

    if (info) {
      // eslint-disable-next-line no-console
      console.error(info);
    }
  }

  if (window.appInsights) {
    window.appInsights.trackException(
      error,
      null,
      {
        location: window.location.href,
        userAgent: window.navigator.userAgent,
        browserVendor: window.navigator.vendor,
        os: window.navigator.platform,
        plugins: window.navigator.plugins,
        additionalInfo: info,
        stage: process.env.STAGE || 'DEVELOPMENT'
      },
      {},
      3
    );
  }
}
