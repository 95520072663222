import { PagedResponseType } from '@zf/api-types/api';

import { METHODS } from '../../utils/request';
import ApplicationStore from '../stores/domain/ApplicationStore';

export default class BaseService {
  public endpoint: string;
  public applicationStore: ApplicationStore;

  constructor(endpoint: string, applicationStore: ApplicationStore) {
    this.endpoint = endpoint;
    this.applicationStore = applicationStore;
  }

  // Generic function to support singleton pattern
  getSingleVariable = async <V>(endpoint: string, variable: V | undefined, setter: (newVariable: V) => void) => {
    if (!variable) {
      const res = (
        await this.applicationStore.sendRequest<V>({
          request: {
            method: METHODS.GET,
            endpoint
          }
        })
      ).data;

      setter(res);

      return res;
    } else {
      return variable;
    }
  };

  // Generic function to support singleton pattern
  getPagedVariable = async <V>(endpoint: string, variable: V[] | undefined, setter: (newVariable: V[]) => void) => {
    if (!variable) {
      const res = (
        await this.applicationStore.sendRequest<PagedResponseType<V>>({
          request: {
            method: METHODS.GET,
            endpoint
          }
        })
      ).data.results;

      setter(res);

      return res;
    } else {
      return variable;
    }
  };

  async getEntities<T>() {
    return (
      await this.applicationStore.sendRequest<T>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}`
        }
      })
    ).data;
  }

  async updateEntity<RequestType, OutputType>(id: string, body: RequestType) {
    await this.applicationStore.sendRequest<OutputType>({
      request: {
        method: METHODS.POST,
        endpoint: `${this.endpoint}${id}`,
        data: {
          body
        }
      }
    });
  }

  async deleteEntity(id: string) {
    await this.applicationStore.sendRequest<boolean>({
      request: {
        method: METHODS.DELETE,
        endpoint: `${this.endpoint}${id}`
      }
    });
  }
}
