import React from 'react';

import { navigate } from '@reach/router';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../app-context';
import { dialog } from '../../../events/dialog-events';
import Button from '../../Button/Button';
import AddOrganisationDialog from './dialogs/AddOrganisationDialog';
import css from './org-picker-footer.module.scss';

const OrgPickerFooter = React.memo(() => {
  const { i18n, tenantReducer, authReducer } = useAppContext();
  const { tenant } = tenantReducer.state;
  const { isSuperUser } = authReducer;

  const { clickRef, validationRef, onSubmit } = useDialog();

  const baseUrl = `/${tenant?.name}`;

  const openAddOrgDialog = () => {
    dialog.normal({
      title: i18n.getTranslation('tenant.add_organisation'),
      icon: 'organisation',
      content: <AddOrganisationDialog validationRef={validationRef} ref={clickRef} />,
      buttonPositive: i18n.getTranslation('general.add'),
      onSubmit,
      ref: validationRef
    });
  };

  const isSuper = isSuperUser();

  return (
    <div className={css['footer']}>
      <div className={css['btns']}>
        {isSuper && (
          <>
            <Button id="organisation.add" icon="plus" type="secondary" onClick={openAddOrgDialog}>
              {i18n.getTranslation('tenant.add_organisation')}
            </Button>
            <Button
              id="tenant.user_manager"
              icon="customer"
              type="secondary"
              onClick={() => navigate(`${baseUrl}/user-manager`)}
            >
              {i18n.getTranslation('tenant.user_manager')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

export default OrgPickerFooter;
