import React from 'react';

import {
  OutgoingBankingTransactionRowType,
  OutgoingBankingTransactionType
} from '@zf/api-types/billing/outgoing-banking-transaction';
import { outgoingBankingTransactionStatus } from '@zf/api-types/enums';
import useDialog from '@zf/hooks/src/useDialog';

import ManuallyConfirmOutgoingBankingTransactionDialog from '../../../actions/outgoing-banking-transactions/ManuallyConfirmOutgoingBankingTransactionDialog';
import { useAppContext } from '../../../app-context';
import useCurrent from '../../../app-context/hooks/use-current';
import useUpdateListPage from '../../../app-context/hooks/use-update-listpage';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  refreshCounts: () => void;
};

export default function OverwriteBankConfirmationOutgoingBankingTransactionListPageButton(props: Props) {
  const { i18n } = useAppContext();
  const { myCurrent } = useCurrent('outgoing_banking_transaction');
  const selectedRows: OutgoingBankingTransactionRowType[] = myCurrent.listPage.selectedRows;
  const { setUpdatedRows } = useUpdateListPage<OutgoingBankingTransactionType>('outgoing_banking_transaction');
  const { clickRef, onSubmit } = useDialog();

  const isDisabled =
    selectedRows.some((r) => {
      return r.__entity.status !== outgoingBankingTransactionStatus.toconfirmbybank;
    }) || selectedRows.length === 0;

  return (
    <DropdownAction
      id="outgoing_banking_transaction.manually_confirm"
      icon="check"
      dialogTitle={i18n.getTranslation('actions.banking_transaction.manually_confirm')}
      dialogContent={
        <ManuallyConfirmOutgoingBankingTransactionDialog
          {...props}
          ref={clickRef}
          selectedRows={selectedRows}
          setUpdatedRows={setUpdatedRows}
        />
      }
      onSubmit={onSubmit}
      disabled={isDisabled}
      buttonPositive={i18n.getTranslation('actions.banking_transaction.overwrite')}
    />
  );
}
