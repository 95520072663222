import classNames from 'classnames';
import React from 'react';

import { utilityType } from '@zf/api-types/enums';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import TooltipTrigger from '@zf/stella-react/src/atoms/Tooltip/TooltipTrigger/TooltipTrigger';

import { useAppContext } from '../../app-context/app-context';
import { ICON_COLORS } from '../../constants/icons';
import { BaseButton } from '../../design-system/Components';
import Icon from '../Icon/Icon';
import css from './utility-type-button.module.scss';

type Props = {
  utilityType_: utilityType;
  isActive: boolean;
  onTrigger: () => void;
};

export default function UtilityTypeButton(props: Props) {
  const { utilityType_, isActive = false, onTrigger } = props;
  const { enumReducer } = useAppContext();

  const color = ICON_COLORS[utilityType_];

  const style: React.CSSProperties = { border: `0.2rem solid ${color}` };

  return (
    <TooltipTrigger
      tooltip={<Paragraph>{enumReducer.getTranslation('utilityType', utilityType_)}</Paragraph>}
      trigger="hover"
    >
      <BaseButton
        id={utilityType_}
        className={classNames(css['utility-type-btn'], { [css['disabled']]: !isActive })}
        onClick={onTrigger}
        style={style}
      >
        <Icon type={utilityType_} color />
      </BaseButton>
    </TooltipTrigger>
  );
}
