import React from 'react';

import { ColumnType } from '@zf/stella-react/src/atoms/Table';

import { useAppContext } from '../../../../../app-context';
import { TableError, TableLoading, TableNoData } from '../../../../../components/placeholder';
import useOutgoingBankingTransactionsListPage from '../hooks/useOutgoingBankingTransactionsListPage';
import DynamicVirtualTable from '../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';

export default function OutgoingBankingTransactionsListPage() {
  const { i18n } = useAppContext();

  const {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    refresh,
    localTimeStamp,
    timeStamp,
    selectAllBusy: selectAllBusy_,
    handleSort,
    setStopIndex,
    setSelectedIds
  } = useOutgoingBankingTransactionsListPage();

  const [columns] = React.useState<ColumnType[]>([
    {
      width: 200,
      label: i18n.getTranslation('banking_transaction.identification'),
      dataKey: 'identification',
      noClick: true
    },
    {
      width: 200,
      label: i18n.getTranslation('general.type'),
      dataKey: 'type'
    },
    {
      width: 200,
      label: i18n.getTranslation('incoming_banking_transactions.collection_date'),
      dataKey: 'mutationDateTime'
    },
    {
      width: 200,
      label: i18n.getTranslation('general.amount_valuta'),
      dataKey: 'totalAmount'
    },
    {
      width: 300,
      label: i18n.getTranslation('banking_transaction.org_bank_account'),
      dataKey: 'companyBankAccount'
    },
    {
      width: 200,
      label: i18n.getTranslation('outgoing_banking_transactions.number_of_mutations'),
      dataKey: 'numberOfMutations'
    },
    {
      width: 200,
      label: i18n.getTranslation('general.status'),
      dataKey: 'status'
    }
  ]);

  return React.useMemo(() => {
    return (
      <DynamicVirtualTable
        id="outgoing-banking-transactions-table"
        tooltipId="outgoing-banking-transactions-table-tip"
        onRowsRendered={({ stopIndex, selectAllBusy }) => setStopIndex(stopIndex, selectAllBusy)}
        rows={rows}
        loading={loading}
        sortableFields={sortableFields}
        error={error}
        selectedRows={selectedIds}
        sortBy={sortBy}
        sortDirection={sortDirection}
        totalAmountOfRows={totalAmountOfRows}
        selectAllBusy={selectAllBusy_}
        refresh={refresh}
        timeStamp={timeStamp}
        localTimeStamp={localTimeStamp}
        NoDataOverlay={TableNoData}
        LoadingOverlay={TableLoading}
        ErrorOverlay={TableError}
        onSelectRow={setSelectedIds}
        sort={handleSort}
        columns={columns}
        singleSort
      />
    );
  }, [rows, selectedIds, selectAllBusy_, error, i18n.lang]);
}
