import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';

import { OutputProvider } from './context/output-context';
import OutputContent from './OutputContent';

export type Props = {
  meter: MeterType;
  timeStamp: string;
};

export default function Output(props: Props) {
  return (
    <OutputProvider>
      <OutputContent {...props} />
    </OutputProvider>
  );
}
