import React, { memo, useMemo, useState } from 'react';

import { TenantOrganisationType, TenantType } from '@zf/api-types/auth';
import Center from '@zf/stella-react/src/helpers/Center';
import { generateColor } from '@zf/utils/src/color';

import { useAppContext } from '../../../app-context';
import { BaseLink } from '../../../design-system/Components';
import { OrgAvatar } from '../../Avatar';
import css from './org-card.module.scss';
import OrgActions from './OrgActions';
import OrgCardBody from './OrgCardBody';
import OrgCardFooter from './OrgCardFooter';
import OrgDescription from './OrgDescription';
import { ResetCard } from './ResetCard';

export type OrgCardProps = {
  index: number;
  organization: TenantOrganisationType;
  tenant: TenantType;
  mutationDateTime: string;
};

const OrgCard = memo((props: OrgCardProps) => {
  const { index, organization, tenant, mutationDateTime } = props;
  const { authReducer } = useAppContext();
  const { isSuperUser } = authReducer;

  // ShortCode is named code in an add organisation response
  const shortCode = organization.shortCode || organization.code;
  const orgId = organization.organizationId || organization.id;
  const baseUrl = `/${tenant.name}/${shortCode}`;

  const [isResetting, setIsResetting] = useState(organization.status === 1);

  const borderStyle = useMemo(() => {
    return {
      borderTop: `0 $spacing-8 solid ${generateColor(shortCode)}`
    };
  }, [shortCode]);

  const avatarCode = shortCode || '';

  if (!orgId) return null;

  return (
    <div className={css['org-card']} style={borderStyle}>
      {isResetting && <ResetCard orgId={orgId} shortCode={shortCode} setIsResetting={setIsResetting} />}

      {!isResetting && (
        <>
          <BaseLink id={`link-${orgId}`} url={baseUrl} className={css['header']}>
            <div className={css['avatar']}>
              <Center type="both">
                <OrgAvatar>{avatarCode}</OrgAvatar>
              </Center>
            </div>
            <OrgDescription id={orgId} description={organization.description} />
          </BaseLink>

          {isSuperUser() && <OrgActions orgIndex={index} setIsResetting={setIsResetting} orgCode={shortCode} />}

          <OrgCardBody
            mutationDateTime={mutationDateTime}
            organizationId={orgId}
            tenantId={tenant.id}
            baseUrl={baseUrl}
            isResetting={isResetting}
          />

          <div className={css['links']}>
            <OrgCardFooter organisationId={orgId} tenantId={tenant.id} baseUrl={baseUrl} isResetting={isResetting} />
          </div>
        </>
      )}
    </div>
  );
});

export default OrgCard;
