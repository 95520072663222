import Interweave from 'interweave';
import React from 'react';

import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { convertHexToRGBA } from '@zf/utils/src/color';

import { useAppContext } from '../../../../app-context/app-context';
import { formatDate } from '@zf/utils/src/date';
import { ActivityCompletedType } from './activity-context/context';
import css from './activity.module.scss';

type Props = {
  activityType: ActivityCompletedType;
  color: string;
  mutationDate?: string;
};

export default function ActivityCompleted(props: Props) {
  const { activityType, mutationDate, color } = props;
  const { i18n } = useAppContext();
  const [bgColor] = React.useState(convertHexToRGBA(color, 20));

  return (
    <div className={css['activity-completed']} style={{ color: color, backgroundColor: bgColor }}>
      <Interweave content={i18n.getTranslation(`tasks.${activityType}`)} />
      {mutationDate && <Paragraph color={color}>{formatDate(mutationDate)}</Paragraph>}
    </div>
  );
}
