import classNames from 'classnames';
import React, { CSSProperties, ReactNode, useMemo } from 'react';

import Icon from './Icon';
import css from './icon-paragraph.module.scss';

type Props = {
  id?: string;
  title?: string;
  iconType: string;
  children: ReactNode;
  color?: string | boolean;
  iconPosition?: 'left' | 'right';
  className?: string;
  usedInTable?: boolean;
  tooltipFor?: string;
  bold?: boolean;
  action?: () => void;
};

export default function IconParagraph(props: Props) {
  const {
    id,
    iconType,
    children,
    title,
    className,
    tooltipFor,
    color = false,
    usedInTable = false,
    bold = false,
    iconPosition = 'left',
    action
  } = props;

  const styleObject = useMemo(() => {
    const style: CSSProperties = {
      textAlign: 'left'
    };

    if (typeof color === 'string') {
      style['color'] = color;
    }

    if (bold) {
      style['fontWeight'] = 'bold';
    }

    return style;
  }, [color, bold]);

  const icon = (
    <Icon id={id} tooltipFor={tooltipFor} title={title} type={iconType} color={color} usedInTable={usedInTable} />
  );

  return (
    <div
      className={classNames(
        css['icon-paragraph'],
        { [css['icon-paragraph-right']]: iconPosition === 'right' },
        className
      )}
      onClick={action}
    >
      {iconPosition === 'left' ? (
        <>
          <div className={css['icon-wrapper']}>{icon}</div>
          <div style={styleObject}>{children}</div>
        </>
      ) : (
        <>
          <div style={styleObject}>{children}</div>
          <div className={css['icon-wrapper']}>{icon}</div>
        </>
      )}
    </div>
  );
}
