import { observer } from 'mobx-react';
import React, { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';

import { EntityAttachmentFile } from '@zf/api-types/entity-attachments/entity-attachments';
import { Heading } from '@zf/stella-react/src/atoms/Heading';
import { isMaxDate, isMinDate } from '@zf/utils/src/date';

import { SettingDescription } from '../../../../../design-system/ComponentSets';
import { DialogClickRef, ValidationRef } from '../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../hooks/useStore';
import CheckBox from '../../../../input/CheckBox';
import { AddEditType } from '../../../Dialog/types';
import { AttachmentState } from '../../logic/AttachmentGroupBase';
import FilesStore, { attachmentType } from '../../logic/FilesStore';
import { AttachmentGenericBaseType } from '../Files';
import css from './add-edit-entity-attachment-dialog.module.scss';
import AttachmentsAdapter from './AttachmentsAdapter';
import CustomerLangPrefInfo from './CustomerLangPrefInfo';
import SingleFile from './SingleFile';

type Props<T> = {
  useCase: AddEditType;
  filesStore: FilesStore<T>;
  validationRef: React.MutableRefObject<ValidationRef | undefined>;
  showLangPref?: boolean;
  enableTranslations?: boolean;
  attachment?: AttachmentGenericBaseType;
  attachmentType: attachmentType;
  listFiles: (attachment?: T) => EntityAttachmentFile[];
  setState?: (value: Partial<AttachmentState>) => void;
  state?: AttachmentState;
};

const AddEditEntityAttachmentDialog = forwardRef(<T,>(props: Props<T>, ref: Ref<DialogClickRef | undefined>) => {
  const {
    filesStore,
    useCase,
    showLangPref = false,
    enableTranslations = false,
    attachmentType,
    state,
    validationRef,
    setState,
    listFiles
  } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { addEditEntityAttachmentForm, portalEnabled, initAddEditForm } = filesStore;

  useImperativeHandle(ref, () => ({
    async onClick() {
      await addEditEntityAttachmentForm.save(
        useCase,
        attachmentType,
        //rework this to be generic in the future. because dialog mobx state can't be passed sadly
        filesStore.rootStore.propertyGroupStore.entityAttachmentFeatures.attachmentState
      );
    }
  }));

  const { visibleInPortal, attachmentFiles } = addEditEntityAttachmentForm._values;

  const validate = () => {
    if (validationRef.current) {
      const translationsInvalid = attachmentFiles.some((af) => {
        const { fileName, isDefault, file } = af.attachmentFileForm._values;

        //@ts-ignore
        const defaultIsInvalid = isDefault && (!fileName || !file || file === 'value');
        //@ts-ignore
        const extraIsInvalid = !isDefault && !!file && !fileName && file !== 'value';
        /**
         * @description if filename exits, file should be uploaded.
         */
        //@ts-ignore
        const cultureIsInvalid = !isDefault && fileName && (!file || file === 'value');

        return defaultIsInvalid || extraIsInvalid || cultureIsInvalid;
      });

      validationRef.current.setIsError(
        translationsInvalid ||
          (!!state?.validFrom && isMinDate(state.validFrom) && !!state.validUntil && isMaxDate(state.validUntil))
      );
    }
  };

  useEffect(() => {
    initAddEditForm(useCase, listFiles);
  }, []);

  useEffect(() => {
    validate();
  }, [addEditEntityAttachmentForm._values.attachmentFiles.map((a) => a.attachmentFileForm._values), state]);

  return (
    <>
      {showLangPref && <CustomerLangPrefInfo />}

      <div className={css['file-name-and-file']}>
        {enableTranslations ? (
          <AttachmentsAdapter
            portalEnabled={portalEnabled}
            useCase={useCase}
            state={state}
            setState={setState}
            filesStore={filesStore}
          />
        ) : (
          <SingleFile filesStore={filesStore} useCase={useCase} />
        )}
      </div>

      {portalEnabled && !enableTranslations && (
        <>
          <Heading className={css['heading']} headingType="h3">
            {getTranslation('entity_attachment.options')}
          </Heading>
          <SettingDescription
            title={getTranslation('entity_attachment.show_in_portal')}
            description={getTranslation('entity_attachment.show_in_portal_desc')}
            setting={
              <CheckBox
                id="show-in-portal"
                checked={visibleInPortal}
                onChange={(visible) => addEditEntityAttachmentForm._set('visibleInPortal', visible)}
              />
            }
          />
        </>
      )}
    </>
  );
});

export default observer(AddEditEntityAttachmentDialog);
