import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MeterStatusHistoryType, MeterType } from '@zf/api-types/master-data/meter';
import useValidator from '@zf/hooks/src/useValidator';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { SuspenseCardBody } from '../../../../components/suspense';
import { notify } from '../../../../events/notification-events';
import MeterDetailBodyCard from './MeterDetailBodyCard';

type Props = {
  meter: MeterType;
  activeState: MeterStatusHistoryType | undefined;
};

type ValidatorType = {
  meterTag: string;
};

const MeterDetailCard = (props: Props) => {
  const { meter } = props;

  const { applicationStore, meterStore } = useStore();
  const { getTranslation } = applicationStore;
  const { updateMeterTag } = meterStore.deviceService;

  const { values, isDirty, setValue, restoreValues, submitFactory } = useValidator<ValidatorType>({
    initialValues: {
      meterTag: meter.meterTag
    },
    refreshTrigger: meter.id
  });

  const handleSave = submitFactory(async () => {
    try {
      const newMeter = await updateMeterTag(values.meterTag, meter.id);

      setValue({ meterTag: newMeter.meterTag }, false, true);

      notify.success({
        content: getTranslation('meter.update_meter_success')
      });
    } catch (e) {
      notify.error({
        content: getTranslation('meter.update_meter_fail'),
        error: e
      });
    }
  });

  const setMeterTag = (newTag: string) => setValue({ meterTag: newTag });

  return (
    <Card id="meter-detail-card">
      <CardHeader
        primaryText={getTranslation('general.save')}
        onPrimaryClick={isDirty ? handleSave : undefined}
        secondaryText={getTranslation('general.cancel')}
        onSecondaryClick={isDirty ? () => restoreValues() : undefined}
      >
        {getTranslation('general.details')}
      </CardHeader>
      <SuspenseCardBody type="grid">
        <MeterDetailBodyCard {...props} meterTag={values.meterTag} setMeterTag={setMeterTag} />
      </SuspenseCardBody>
    </Card>
  );
};

export default observer(MeterDetailCard);
