import { PagedResponseType } from '@zf/api-types/api';
import { CustomEntityPropertyType } from '@zf/api-types/config/custom-entity-property-types';
import { Card, CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { InlineInputFieldInput } from '../../../../../components/input/InputField';
import SimpleDropdown from '../../../../../components/Lang/SimpleDropdown';
import { useStore } from '../../../../../hooks/useStore';
import useSuspenseSingleAPI from '../../../../../hooks/useSuspenseSingleAPI';

const GeneralCard = () => {
  const { applicationStore, countrySpecificStore } = useStore();
  const { getTranslation } = applicationStore;
  const { socialTariffStore } = countrySpecificStore;
  const { socialTariffConfigForm } = socialTariffStore;

  const customEntityPropertyTypeResponse = useSuspenseSingleAPI<PagedResponseType<CustomEntityPropertyType>>({
    request: {
      endpoint: '/cfg/customEntityPropertyTypes'
    }
  });

  if (!customEntityPropertyTypeResponse.result) return null;

  const customEntities = customEntityPropertyTypeResponse.result.data.results;

  const customEntitiesDropdown = customEntities.map((ce) => ({
    value: ce.id,
    text: ce.description
  }));

  return (
    <Card width="1">
      <CardHeader>{getTranslation('general.general')}</CardHeader>;
      <CardBody noMaxHeight type="grid">
        <CardItem width="6">
          <Label info={getTranslation('country_specifics.reg_params_kbo_desc')}>
            {getTranslation('country_specifics.reg_params_kbo')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="kbo"
            onChange={(val) => socialTariffConfigForm._set('kbo', val)}
            type="text"
            singleError={socialTariffConfigForm._error('kbo')}
            error={!!socialTariffConfigForm._error('kbo')}
            value={socialTariffConfigForm._get('kbo')}
            placeholder={'xxxxxxxxxx'}
          />
        </CardItem>
        <CardItem width="6">
          <Label info={getTranslation('country_specifics.reg_params_gln_desc')}>
            {getTranslation('country_specifics.reg_params_gln')}
          </Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="gln"
            onChange={(val) => socialTariffConfigForm._set('gln', val)}
            type="text"
            singleError={socialTariffConfigForm._error('gln')}
            error={!!socialTariffConfigForm._error('gln')}
            value={socialTariffConfigForm._get('gln')}
            placeholder={'xxxxxxxxxxxxx'}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('country_specifics.indicator')}</Label>
        </CardItem>
        <CardItem width="6">
          <SimpleDropdown
            id="social_tariffs.visibility-government"
            onChange={(val) => socialTariffConfigForm._set('socialTariffIndicator', val[0])}
            values={customEntitiesDropdown}
            selectedValues={[socialTariffConfigForm._get('socialTariffIndicator')]}
            singleError={socialTariffConfigForm._error('socialTariffIndicator')}
            error={!!socialTariffConfigForm._error('socialTariffIndicator')}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('country_specifics.visibility_government')}</Label>
        </CardItem>
        <CardItem width="6">
          <SimpleDropdown
            id="social_tariffs.social-tariff-indicator"
            onChange={(val) => socialTariffConfigForm._set('visibilityGovernment', val[0])}
            values={customEntitiesDropdown}
            selectedValues={[socialTariffConfigForm._get('visibilityGovernment')]}
            singleError={socialTariffConfigForm._error('visibilityGovernment')}
            error={!!socialTariffConfigForm._error('visibilityGovernment')}
          />
        </CardItem>
        <CardItem width="6">
          <Label info={getTranslation('country_specifics.bim_info')}>{getTranslation('country_specifics.bim')}</Label>
        </CardItem>
        <CardItem width="6">
          <SimpleDropdown
            id="bim-customer"
            onChange={(val) => socialTariffConfigForm._set('bimCustomer', val[0])}
            values={customEntitiesDropdown}
            selectedValues={[socialTariffConfigForm._get('bimCustomer')]}
            singleError={socialTariffConfigForm._error('bimCustomer')}
            error={!!socialTariffConfigForm._error('bimCustomer')}
          />
        </CardItem>
      </CardBody>
    </Card>
  );
};

export default observer(GeneralCard);
