import React from 'react';

import { InvoiceRowType, InvoiceType, MarkAsSentInvoiceType } from '@zf/api-types/invoice';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../../app-context';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { DialogClickRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  onComplete?: (updatedRows: InvoiceType[]) => void;
  selectedRows?: Array<InvoiceRowType>;
  invoiceToHandle?: MarkAsSentInvoiceType;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, invoiceToHandle } = props;
  const { i18n } = useAppContext();
  const { invoiceStore } = useStore();

  let invoicesToHandle: Array<MarkAsSentInvoiceType> = [];

  if (selectedRows) {
    invoicesToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (invoiceToHandle) {
    invoicesToHandle = [invoiceToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        const newInvoices: Array<InvoiceType> = await Promise.all(
          invoicesToHandle.map((invoice) => invoiceStore.markAsSent(invoice, !selectedRows))
        );
        if (onComplete) onComplete(newInvoices);
        notify.success({
          content: i18n.getTranslation('actions.invoice.mark_send_success')
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('actions.invoice.mark_send_failed'),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation('actions.invoice.mark_send_detail', {
        amount: invoicesToHandle.length.toString()
      })}
    </Paragraph>
  );
});
