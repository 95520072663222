import { action, makeObservable } from 'mobx';

import { ZFErrorType } from '@zf/api-types/general';

import { notify } from '../../events/notification-events';
import { createHeader, METHODS, RequestResultType } from '../../utils/request';
import RootStore from '../stores';

type BodyType = Record<string, string[] | string>;

export default class DownloadService {
  public rootStore: RootStore;

  public zipEndpoint?: string;

  constructor(rootStore: RootStore, zipEndpoint?: string) {
    this.rootStore = rootStore;
    this.zipEndpoint = zipEndpoint;

    makeObservable(this, {
      notifyError: action,
      download: action,
      downloadBlob: action,
      processDownloadedData: action,
      downloadSingleFile: action,
      downloadFiles: action,
      setDownloadZipEndpoint: action
    });
  }

  notifyError = (e: ZFErrorType) => {
    notify.error({
      content: this.rootStore.applicationStore.getTranslation('general.download_fail'),
      error: e
    });
  };

  setDownloadZipEndpoint = (endpoint: string) => {
    this.zipEndpoint = endpoint;
  };

  download = (fileName: string, content: string) => {
    const linkElement = document.createElement('a');
    linkElement.href = content;
    linkElement.target = '_blank';

    linkElement.download = fileName;

    linkElement.dispatchEvent(new MouseEvent('click'));
  };

  downloadBlob = (fileName: string, blob: Blob) => {
    this.download(fileName, URL.createObjectURL(blob));
  };

  processDownloadedData = (res: RequestResultType<BlobPart>) => {
    const splittedByComma = res.headers['content-disposition'].split(';');
    let fileName = '';

    if (splittedByComma.length > 1) {
      const fileComponents = splittedByComma[1].split('=');
      if (fileComponents.length > 1) fileName = fileComponents[1].replace(/['"]+/g, '');
    }

    this.downloadBlob(fileName, new Blob([res.data], { type: res.headers['content-type'] }));
  };

  downloadSingleFile = async (endpoint: string, data?: any) => {
    try {
      const res = await this.rootStore.applicationStore.sendRequest<BlobPart>(
        {
          responseType: 'arraybuffer',
          request: {
            method: METHODS.POST,
            endpoint: endpoint,
            data: data || null
          },
          customHeaders: createHeader({
            Accept: 'application/octet-stream'
          }),
          tenantReducer: this.rootStore.applicationStore.tenantReducer,
          lang: this.rootStore.applicationStore.userStore.lang
        },
        true
      );

      this.processDownloadedData(res);
    } catch (e) {
      // @ts-ignore
      this.notifyError(e);
    }
  };

  downloadFiles = async (ids: string[], idKey: string, otherParams?: any, fileName?: string) => {
    try {
      let body: BodyType = {};
      body[idKey] = ids;

      if (otherParams) {
        body = { ...body, ...otherParams };
      }

      if (fileName) body.resultingFileName = fileName;

      const res = await this.rootStore.applicationStore.sendRequest<BlobPart>(
        {
          responseType: 'arraybuffer',
          request: {
            method: METHODS.POST,
            endpoint: this.zipEndpoint || '',
            data: body
          },
          customHeaders: createHeader({
            Accept: 'application/octet-stream'
          }),
          tenantReducer: this.rootStore.applicationStore.tenantReducer,
          lang: this.rootStore.applicationStore.userStore.lang
        },
        true
      );

      fileName = fileName
        ? fileName
        : res.headers['content-disposition']
            .split(';')
            .find((el) => el.includes('filename='))
            ?.split('=')[1]
            .trim()
            .replace(/"/g, '');

      this.downloadBlob(fileName || 'file', new Blob([res.data], { type: res.headers['content-type'] }));
    } catch (e) {
      // @ts-ignore
      this.notifyError(e);
    }
  };
}
