import moment, { Moment } from 'moment';
import React from 'react';

import { contractStatus } from '@zf/api-types/enums';
import { ContractRowType } from '@zf/api-types/master-data/contract';
import { formatDateForExport, formatPeriod } from '@zf/utils/src/date';

import useCurrent from '../../../app-context/hooks/use-current';
import { useStore } from '../../../hooks/useStore';
import { getContractServiceLocationSupplyPeriod, getServicesForContractServiceLocation } from '../../../utils/contract';
import { getExtraCustomEntityColumns } from '../../../utils/custom-entity-properties';
import ExportToExcel from './ExportToExcel';

type Props = {
  extraColumnTitles: string[];
};

export default function ContractExport(props: Props) {
  const { extraColumnTitles } = props;

  const { myCurrent } = useCurrent('contract');
  const { applicationStore, contractStore } = useStore();
  const { getTranslation, getEnumTranslation } = applicationStore;
  const { getProductText } = contractStore;

  const selectedRows: ContractRowType[] = myCurrent.listPage.selectedRows;

  const getExportArray = () => {
    const exportArray: string[][] = [];

    exportArray.push([
      getTranslation('contracts.list.contract_number'),
      getTranslation('contracts.contractor'),
      getTranslation('customer.customer_id'),
      getTranslation('contracts.list.product'),
      getTranslation('contracts.list.supply_period'),
      getTranslation('general.status'),
      getTranslation('location.street_name'),
      getTranslation('location.streetnr'),
      getTranslation('location.streetnraddition'),
      getTranslation('location.postal'),
      getTranslation('location.city'),
      getTranslation('location.country'),
      getTranslation('contracts.service'),
      getTranslation('contracts.list.external'),
      getTranslation('tasks.creation_date'),
      getTranslation('contracts.sign_date'),
      getTranslation('property_groups.name'),
      getTranslation('customer_groups.customer_group'),
      ...extraColumnTitles
    ]);

    selectedRows.forEach((r) => {
      const contract = r.__contractEntity;

      contract.serviceLocations.forEach((l) => {
        const { periodStart, periodEnd } = getContractServiceLocationSupplyPeriod(l);

        let signDate: Moment | null = null;
        const utilityTypes = getServicesForContractServiceLocation(l).map((u) => getEnumTranslation('utilityType', u));

        for (const history of contract.statusHistory) {
          if (history.contractStatus === contractStatus.signed) {
            signDate = moment(history.mutationDateTime);
          }
        }

        const extraCustomEntityColumns = getExtraCustomEntityColumns(
          contract.customProperties,
          r.__customEntityPropertyTypes,
          getTranslation
        );

        const extraCustomEntityColumnValues = Object.keys(extraCustomEntityColumns).map(
          (key) => extraCustomEntityColumns[key]
        );

        const propertyGroupNames = contract.propertyGroups?.map((pg) => pg.name) || [];

        exportArray.push([
          contract.contractNumber,
          contract.contractor.shortDisplayName,
          contract.contractor.accountNumber,
          getProductText(contract),
          formatPeriod(periodStart, periodEnd),
          r.CurrentContractStatus?.props.children[0].props.type || '',
          l.address?.streetName || '',
          l.address?.streetNumber || '',
          l.address?.streetNumberAddition || '',
          l.address?.postalCode || '',
          l.address?.city || '',
          l.address?.country.toUpperCase() || '',
          utilityTypes.join(', '),
          r.externalReference,
          formatDateForExport(contract.createdDateTime),
          formatDateForExport(signDate?.toISOString()),
          //contract.propertyGroup?.name || '',
          propertyGroupNames.join(','),
          contract.contractor.customerGroup?.name || '',
          ...extraCustomEntityColumnValues
        ]);
      });
    });

    return exportArray;
  };

  return (
    <ExportToExcel
      id="btn-export-excel"
      key="contractExport"
      disabled={selectedRows.length === 0}
      toExportArray={getExportArray}
      title={getTranslation('export_excel.export_contracts')}
      ws_name={getTranslation('contracts.contracts')}
    />
  );
}
