import clone from 'clone';
import { Form } from 'mobx-react-form';
import vjf from 'mobx-react-form/lib/validators/VJF';
import validator from 'validator';

import { isMinDate } from '@zf/utils/src/date';

import { store } from '../../storeContext';

// We can use this Exact type to prevent type inference on our objects
export type Exact<T> = { [K in keyof T]: T[K] };

export type Field = {
  name: string;
  label: string;
  placeholder?: string;
  validators?: { ({ field }: FieldPropertiesType<any>): any }[];
  value?: any;
  checked?: boolean;
  related?: any;
  observers?: any;
  type?: 'checkbox' | 'text';
  fields?: Field[];
};

export type Fields = {
  fields: Field[];
};

export type FieldPropertiesType<T> = {
  field: Field;
  form: T;
};

export type FormOptions = {
  showErrorsOnInit?: boolean;
};

type Observer<I> = {
  path: keyof I;
  key: keyof Field;
  call: (...args: any[]) => void;
};

export default class BaseFormVjf<I> extends Form {
  public form: any;
  public initialValues: I;
  public _backup: I;

  constructor(values: Exact<I>, options?: FormOptions) {
    super({ values }, { options: { ...options, validateOnChange: true } });
    this.initialValues = values;
    this._backup = clone(values);
  }

  plugins() {
    return {
      vjf: vjf()
    };
  }

  // Validation functions

  isRequired({ field }: FieldPropertiesType<any>) {
    // Empty fields are sometimes filled in by 'value'
    const isValid =
      field.value !== null && typeof field.value !== 'undefined' && field.value !== '' && field.value !== 'value';
    return [isValid, store.applicationStore.getTranslation('mandates.mandate_required')];
  }

  validateNumeric({ field }: FieldPropertiesType<any>) {
    const isValid = typeof field.value !== 'undefined' && !isNaN(field.value) && field.value !== null;
    return [isValid, store.applicationStore.getTranslation('validation.money')];
  }

  validateMoney({ field }: FieldPropertiesType<any>) {
    const isValid = typeof field.value !== 'undefined' && !isNaN(field.value);
    return [isValid, store.applicationStore.getTranslation('validation.money')];
  }

  validateMoneyNotZero({ field }: FieldPropertiesType<any>) {
    const isValid = !!field.value && !isNaN(field.value);
    return [isValid, store.applicationStore.getTranslation('validation.money_not_zero')];
  }

  isDateRequired({ field }: FieldPropertiesType<any>) {
    const isValid = !isMinDate(field.value);
    return [isValid, store.applicationStore.getTranslation('mandates.mandate_required')];
  }

  isEmail({ field }: FieldPropertiesType<any>) {
    const isValid = validator.isEmail(field.value);
    return [isValid, store.applicationStore.getTranslation('communication.invalid_email')];
  }

  isIbanValid({ field }: FieldPropertiesType<any>) {
    const isValid = validator.isIBAN(field.value);
    return [isValid, store.applicationStore.getTranslation('mandates.iban_invalid')];
  }

  maxLength(number: number) {
    return ({ field }: FieldPropertiesType<any>) => {
      const isValid = field.value.length > number;
      return [isValid, ['lenth must be greater then']];
    };
  }

  // Customized typed functions

  get _values(): I {
    return this.values();
  }

  get _isDirty(): boolean {
    return this.isDirty;
  }

  get _onSubmit(): () => void {
    return this.onSubmit;
  }

  get _onReset(): () => void {
    return this.onReset;
  }

  get _isSubmitting() {
    return this.submitting;
  }

  // Checks a blank object
  fieldIsEmpty<K extends keyof I>(key: K) {
    const field = this._get(key);
    return Object.keys(field).every((key) => {
      //@ts-ignore
      const value = field[key];
      if (typeof value === 'string') {
        return value === '';
      } else if (typeof value === 'boolean') {
        return value === false;
      }
      return false;
    });
  }

  initializeFormFields(initialValues: I) {
    this.init(initialValues);
  }

  hasFormErrors() {
    const errorObj = this.errors();
    return Object.keys(errorObj).some((key) => {
      return errorObj[key] !== null;
    });
  }

  _observers(observers: Observer<I>[]) {
    observers.forEach((e) => {
      this.observe(e);
    });
  }

  _setup(fields: Field[]) {
    this.init(fields);
  }

  _error(key: keyof I): string {
    return this.$(key).error;
  }

  _nestedError<K extends keyof I, L extends keyof I[K]>(key1: K, key2: L): string {
    return this.$(`${key1}.${key2}`).error;
  }

  _errors() {
    return this.errors();
  }

  _hasError() {
    return this.hasError;
  }

  _get<K extends keyof I>(key: K) {
    return this.$(key).value as I[K];
  }

  _getNested<K extends keyof I, L extends keyof I[K]>(key1: K, key2: L) {
    const firstget = this.$(key1).value as I[K];
    return firstget[key2] as I[K][L];
  }

  _clearField<K extends keyof I>(key: K) {
    this.$(key).clear();
  }

  _setRules<K extends keyof I>(key: K, newRules: string) {
    this.$(key).set('rules', newRules);
  }

  _setValidators<K extends keyof I>(
    key: K,
    newValidators: (({ field }: FieldPropertiesType<any>) => any)[] | undefined | null
  ) {
    this.$(key).set('validators', newValidators);
  }

  _setValidatorsNested<K extends keyof I, L extends keyof I[K]>(
    key1: K,
    key2: L,
    newValidators: (({ field }: FieldPropertiesType<any>) => any)[] | undefined | null
  ) {
    this.$(`${key1}.${key2}`).set('validators', newValidators);
  }

  _set<K extends keyof I>(key: K, val: I[K]) {
    this.$(key).set(val);
  }

  _setNested<K extends keyof I, L extends keyof I[K]>(key1: K, key2: L, val: I[K][L]) {
    this.$(`${key1}.${key2}`).set(val);
  }

  _submit = () => {
    this.submitCount++;
    this.submit();
  };

  _reset = () => {
    this.reset();
  };
}
