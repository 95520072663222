import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { ExternalMeasurementsOverviewOverviewCountType, ImportJobType } from '@zf/api-types/import-job';
import { Card, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../app-context';
import { SuspenseCardBody } from '../../../../components/suspense';
import { DANGER, INFO, SUCCESS, WARNING } from '../../../../constants/color';
import useSuspenseSingleAPI from '../../../../hooks/useSuspenseSingleAPI';
import css from './pie-chart.module.scss';
import { colors } from '@zf/utils/src/color';

export type PropsType = {
  importJob: ImportJobType;
};

function CardContent(props: PropsType) {
  const { importJob } = props;
  const { i18n } = useAppContext();

  const overviewCount = useSuspenseSingleAPI<ExternalMeasurementsOverviewOverviewCountType>({
    request: {
      endpoint: `/logs/ImportJob/${importJob.id}/externalmeasurements/overviewcount`
    }
  });

  if (!overviewCount.result) return null;

  let data = [];

  if (overviewCount.result.data.unlinked > 0) {
    data.push({
      name: i18n.getTranslation('import_jobs.unlinked'),
      value: overviewCount.result.data.unlinked,
      color: WARNING
    });
  }

  if (overviewCount.result.data.linked > 0) {
    data.push({
      name: i18n.getTranslation('import_jobs.linked'),
      value: overviewCount.result.data.linked,
      color: SUCCESS
    });
  }

  if (overviewCount.result.data.unprocessed > 0) {
    data.push({
      name: i18n.getTranslation('import_jobs.unprocessed'),
      value: overviewCount.result.data.unprocessed,
      color: colors['orange-400']
    });
  }

  if (overviewCount.result.data.contextInvalid > 0) {
    data.push({
      name: i18n.getTranslation('errors.errors'),
      value: overviewCount.result.data.contextInvalid,
      color: DANGER
    });
  }

  const totalValuesData = [
    {
      name: i18n.getTranslation('import_jobs.total_values'),
      value: overviewCount.result.data.all
    }
  ];

  return (
    <ResponsiveContainer width="95%" height="95%" className={css['graph']}>
      <PieChart>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Pie data={totalValuesData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill={INFO} />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill={INFO}
          label
        >
          {data.map((entry, index) => (
            <Cell fill={entry.color} key={index} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default function ImportJobValuesPieChart(props: PropsType) {
  const { i18n } = useAppContext();
  return (
    <Card className={css['import-job-values-card']} id="import-job-values-card">
      <CardHeader>{i18n.getTranslation('general.values')}</CardHeader>
      <SuspenseCardBody noMaxHeight>
        <CardContent {...props} />
      </SuspenseCardBody>
    </Card>
  );
}
