import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';

import { CardBody, CardEmptyBody } from '@zf/stella-react/src/atoms/Card';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';
import { ColumnType } from '@zf/stella-react/src/atoms/Table/dynamic-index-table/StellaDynamicIndexTable';

import DynamicIndexTable from '../../../../components/Lang/DynamicIndexTable';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  rows?: RowType[];
  sortableFields: Record<string, '' | SortDirection>;
  sort: (dataKey: string, sortDirection: '' | SortDirection) => Promise<void>;
  isLoading: boolean;
  emptyState: string;
};

type RowType = {
  code: ReactNode;
  description: ReactNode;
  utilityType: ReactNode;
  unitOfMeasure: ReactNode;
  amount: ReactNode;
  deleteAction?: ReactNode;
};

const ConsumptionGroupCardBody = (props: Props) => {
  const { applicationStore } = useStore();
  const { rows, sort, sortableFields, isLoading, emptyState } = props;
  const { getTranslation } = applicationStore;

  const [tableColumns] = useState<ColumnType[]>([
    {
      flexWidth: 1,
      label: getTranslation('consumption_groups.code'),
      dataKey: 'code'
    },
    {
      flexWidth: 1,
      label: getTranslation('general.description'),
      dataKey: 'description'
    },
    {
      flexWidth: 1,
      label: getTranslation('meter.utility_type'),
      dataKey: 'utilityType'
    },
    {
      flexWidth: 1,
      label: getTranslation('parameters.unitOfMeasure'),
      dataKey: 'unitOfMeasure'
    },
    {
      flexWidth: 1,
      label: getTranslation('general.amount_int'),
      dataKey: 'amount'
    },
    {
      width: 50,
      dataKey: 'deleteAction'
    }
  ]);

  if (rows && rows.length === 0) {
    return (
      <CardEmptyBody
        image={
          <img
            src="https://cdn.zerofriction.co/shared/assets/emptyStates/graph.png"
            alt="empty_state_consumption_groups"
            title="empty_state_consumption_groups"
            width="70"
            height="70"
          />
        }
        title={emptyState}
      />
    );
  } else {
    return (
      <CardBody type="indexTable" fixedHeight>
        <DynamicIndexTable
          tooltipId="consumption-group-table"
          rows={rows || []}
          columns={tableColumns}
          isLoading={isLoading}
          sortableFields={sortableFields}
          sort={sort}
        />
      </CardBody>
    );
  }
};

export default observer(ConsumptionGroupCardBody);
