import React from 'react';

import { ImportDataProviderType } from '@zf/api-types/data-provider';
import { DataExportType } from '@zf/api-types/export-job';

import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Endpoint = {
  endpoint: string;
};

export type DataExportImportAutoFillProps = CustomAutoFillProps<DataExportType | ImportDataProviderType> & Endpoint;

const processAutoFillItem = (d: DataExportType | ImportDataProviderType) => {
  return { value: d, text: d.name };
};

export default function DataExportImportAutoFill(props: DataExportImportAutoFillProps) {
  const { id, selectedValues, placeholder, initialValue, query, queryField, onChange, onFocus, endpoint } = props;

  const autoFillTools = useAutoFill({
    endpoint: endpoint,
    processRecord: processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, values } = autoFillTools;

  return (
    <SearchAutoFill
      {...props}
      id={id}
      values={values}
      selectedValues={selectedValues}
      placeholder={placeholder}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
