import React from 'react';

import { SharedLanguageFields } from '@zf/api-types/entity';
import useDialog from '@zf/hooks/src/useDialog';

import UpdateTranslationsDialog from '../../actions/configuration/update-translations-dialog';
import SuspenseSpinner from '../../components/suspense/suspense-spinner';
import { dialog } from '../../events/dialog-events';
import { notify } from '../../events/notification-events';
import { useStore } from '../../hooks/useStore';

type EndPointNameType =
  | 'BillingItems'
  | 'CostComponents'
  | 'Products'
  | 'Assets'
  | 'ConsumptionUnitTypes'
  | 'IncomingInvoiceComponents'
  | 'CustomEntityPropertyTypes';

export type LangReturnType<E> = {
  openTranslationsDialog: (
    index: number,
    entity: E,
    onCompleteLangUpdate: (index: number, updated: E) => void,
    dialogType?: 'normal' | 'big'
  ) => void;
};

export default function useTranslations<E extends Partial<SharedLanguageFields>>(
  endPointName: EndPointNameType
): LangReturnType<E> {
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { clickRef, onSubmit } = useDialog();

  const openTranslationsDialog = (
    index: number,
    entity: E,
    onCompleteLangUpdate: (index: number, updated: E) => void,
    dialogType: 'normal' | 'big' = 'normal'
  ) => {
    if (entity.localisedFields) {
      const dialogContent = (
        <SuspenseSpinner>
          <UpdateTranslationsDialog
            index={index}
            ref={clickRef}
            entity={entity}
            endPointName={endPointName}
            onCompleteLangUpdate={onCompleteLangUpdate}
          />
        </SuspenseSpinner>
      );

      if (dialogType === 'normal') {
        dialog.normal({
          title: getTranslation('general.language'),
          icon: 'lang-open',
          content: dialogContent,
          buttonPositive: getTranslation('general.update'),
          onSubmit
        });
      } else {
        dialog.right({
          title: getTranslation('general.language'),
          icon: 'lang-open',
          content: dialogContent,
          buttonPositive: getTranslation('general.update'),
          onSubmit
        });
      }
    } else {
      notify.warning({
        content: getTranslation('translated_fields.first_save_new')
      });
    }
  };

  return { openTranslationsDialog };
}
