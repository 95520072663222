import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { InteractionType, EventType } from '@azure/msal-browser';
import { InteractionRequiredAuthError, AccountInfo } from '@azure/msal-browser';
import { loginRequest } from './constants/authentication';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { QueryParamProvider } from 'use-query-params';
import { globalHistory } from '@reach/router';
import { instance } from './PublicClientMsal';
// Uncomment this to enable 'Why Did You Render' to trace the cause of any unnecessary renders in console
//import './wdyr';

/**
 * @description get current active account
 */
type Account = Pick<AccountInfo, 'homeAccountId' | 'username' | 'localAccountId'>;
const activeAccount = instance.getActiveAccount() as Account;

const accounts: AccountInfo[] = instance.getAllAccounts();
if (activeAccount && accounts.length > 0) {
  instance.setActiveAccount(accounts[0]);
}

/**
 * @description enable SSO between tabs
 */
instance.enableAccountStorageEvents();

/**
 * @description loginRedirect async
 */
const loginRedirect = async () => {
  instance.handleRedirectPromise().then(async () => {
    const account = instance.getActiveAccount();
    if (account) instance.setActiveAccount(account);

    if (!account) {
      await instance.loginRedirect(loginRequest);
    }
  });
};

loginRedirect();

try {
  instance.addEventCallback((event: any) => {
    // set active account after redirect
    if (event.payload?.account) {
      switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          if (event.interactionType === InteractionType.Redirect) {
            instance.setActiveAccount(event.payload.account);
          }
          break;
        default:
          break;
      }
    }
  });
} catch (err) {
  if (err instanceof InteractionRequiredAuthError) {
    loginRedirect();
    throw err;
  }
}

ReactDOM.render(
  <MsalProvider instance={instance}>
    <QueryParamProvider {...{ path: '/' }} reachHistory={globalHistory}>
      <App default />
    </QueryParamProvider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
