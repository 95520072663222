import React, { useEffect, useState } from 'react';

import { AgingReportOverviewCountType, AgingReportRowType, AgingReportType } from '@zf/api-types/aging-report';
import { CardsContainer } from '@zf/stella-react/src/atoms/Card';
import { colors } from '@zf/utils/src/color';
import { formatDate } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../app-context';
import { Link } from '../../../design-system/Components';
import { Paragraph } from '../../../design-system/Foundation';
import useCreateRequest from '../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../hooks/useInfiniAPI';
import useLocalRefresh from '../../../hooks/useLocalRefresh';
import { useStore } from '../../../hooks/useStore';
import AgingReportCard from './aging-report-card';
import BucketContent from './BucketContent';
import AgingReportChart from './components/AgingReportChart';
import useCustomerAgingFilter from './hooks/useCustomerAgingFilter';
import OverdueTransactionsCard from './overdue-transactions-card';

export default function CustomerAgingTab() {
  const { tenantReducer } = useAppContext();
  const { applicationStore, agingReportStore } = useStore();
  const { agingReportService } = agingReportStore;
  const { culture, userStore, getTranslation } = applicationStore;
  const { lang } = userStore;
  const { rootUrl } = tenantReducer.state;
  const { timeStamp } = useLocalRefresh();
  const { filterState, filters, tags, clearAllFilters, setFilter } = useCustomerAgingFilter();
  const [overviewCounts, setOverviewCounts] = useState<AgingReportOverviewCountType>();

  useEffect(() => {
    agingReportService.getCustAgingOverviewCounts(filterState.propertyGroupId, filterState.search).then((res) => {
      setOverviewCounts(res);
    });
  }, [filterState]);

  const processRecord = (report: AgingReportType): AgingReportRowType => {
    return {
      __id: report.debtor.customerId,
      __entity: report,
      customer: (
        <Link id={`report-${report.id}`} bold url={`${rootUrl}/customers/${report.debtor.customerId}`}>
          {report.debtor.displayName}
        </Link>
      ),
      oldestOpenInvoice: <Paragraph>{report.oldestOpenDebitTransactionReference}</Paragraph>,
      lastPayment: formatDate(report.lastPaymentDate),
      openCredits: <BucketContent amount={report.openCredits} transactionAmount={report.totalCreditTxOpen} />,
      current: (
        <BucketContent
          color={colors['blue-600']}
          amount={report.totalOpenNotOverdue}
          transactionAmount={report.totalOpenNotOverdueTx}
        />
      ),
      '0-30': (
        <BucketContent
          color={colors['yellow-600']}
          amount={report.totalOverdue30D}
          transactionAmount={report.totalOverdue30DTx}
        />
      ),
      '31-60': (
        <BucketContent
          color={colors['orange-600']}
          amount={report.totalOverdue60D}
          transactionAmount={report.totalOverdue60DTx}
        />
      ),
      '61-90': (
        <BucketContent
          color={colors['red-600']}
          amount={report.totalOverdue90D}
          transactionAmount={report.totalOverdue90DTx}
        />
      ),
      moreThan90: (
        <BucketContent
          color={colors['red-700']}
          amount={report.totalOverdueOver90D}
          transactionAmount={report.totalOverdueOver90DTx}
        />
      ),
      collectionCosts: (
        <div>
          <Paragraph>
            {report.flowActive ? getTranslation('general.active') : getTranslation('general.inactive')}
          </Paragraph>
          <Paragraph color={colors['silver-600']}>
            {report.totalCollectionCostsOpen
              ? `${getTranslation('general.fee')}: ${formatMoney(report.totalCollectionCostsOpen, culture)}`
              : '-'}
          </Paragraph>
        </div>
      ),
      totalOpen: <BucketContent amount={report.totalOpen} transactionAmount={report.totalOpenTx} />
    };
  };

  const requestTools = useCreateRequest('/bill/CustAging', {
    timeStamp: timeStamp,
    hideCredits: !filterState.creditAmounts,
    bucketType: filterState.daysOutstanding,
    propertyGroupId: filterState.propertyGroupId,
    flexSearch: filterState.search
  });

  const { request, selectedIds } = requestTools;

  const rowTools = useInfiniAPI<AgingReportType, AgingReportRowType>({
    request,
    tenantReducer,
    lang: lang,
    processRecord
  });

  const { rows } = rowTools;

  let selectedRow: AgingReportRowType | undefined;

  if (selectedIds[0]) {
    selectedRow = rows.find((r) => {
      return r.__id === selectedIds[0];
    });
  }

  return (
    <>
      {overviewCounts && (
        <AgingReportChart filter={filterState} setFilter={setFilter} overviewCounts={overviewCounts} />
      )}
      <CardsContainer>
        <AgingReportCard
          tags={tags}
          clearAllFilters={clearAllFilters}
          filters={filters}
          filterState={filterState}
          requestTools={requestTools}
          rowTools={rowTools}
        />
        <OverdueTransactionsCard report={selectedRow ? selectedRow.__entity : undefined} />
      </CardsContainer>
    </>
  );
}
