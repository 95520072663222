import React from 'react';

import { ApproveInvoiceType, InvoiceType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import { useAppContext } from '../../../../app-context';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import ApproveInvoiceDialog from '../dialogs/approve-dialog';

type Props = {
  invoice: InvoiceType;
};

export default function ApproveInvoiceListItem(props: Props) {
  const { invoice } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();
  const { invoiceStore } = useStore();

  const invoiceToHandle: ApproveInvoiceType = {
    etag: invoice._etag,
    id: invoice.id
  };

  if (invoiceStore.pageActionPermissions.mayApprove) {
    return (
      <DropdownAction
        id="invoice.approve"
        icon="done"
        dialogTitle={i18n.getTranslation('actions.approve')}
        dialogContent={
          <ApproveInvoiceDialog ref={clickRef} invoiceToHandle={invoiceToHandle} validationRef={validationRef} />
        }
        onSubmit={onSubmit}
        validationRef={validationRef}
        buttonPositive={i18n.getTranslation('actions.approve')}
      />
    );
  }

  return null;
}
