import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { RowTypeBase } from '../../../hooks/useInfiniAPI';
import FixedVirtualTable from './fixed-virtual-table';
import css from './fixed-virtual-table.module.scss';
import { DynamicVirtualTablePropsCommon } from './DynamicVirtualTablePropsCommon';

export default function DynamicVirtualTable<T extends RowTypeBase>(props: DynamicVirtualTablePropsCommon<T>) {
  const { id, selectedRows = [] } = props;

  const { width = 0, height = 0, ref } = useResizeDetector();

  return (
    <div id={id} className={css['wrapper']} ref={ref}>
      <FixedVirtualTable {...props} selectedRows={selectedRows} height={height} width={width} />
    </div>
  );
}
