import { AddManualEntryToOutgoingBankingTransactionRequestType } from '@zf/api-types/billing/outgoing-banking-transaction';
import { OutgoingMutationType } from '@zf/api-types/billing/outgoing-mutations';
import { uiCulture } from '@zf/api-types/enums';

import { TenantReturnValue } from '../../app-context/hooks/use-tenant-reducer';
import { METHODS, sendRequest } from '../../utils/request';

export const addInvoiceEntry = async (
  outgoingBankingTransactionId: string,
  invoiceId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<OutgoingMutationType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/OutgoingBankingTransactions/${outgoingBankingTransactionId}/mutations`,
        data: {
          invoiceId: invoiceId
        }
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const addManualEntry = async (
  outgoingBankingTransactionId: string,
  requestBody: AddManualEntryToOutgoingBankingTransactionRequestType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<OutgoingMutationType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/OutgoingBankingTransactions/${outgoingBankingTransactionId}/mutations`,
        data: requestBody
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const editManualEntry = async (
  outgoingMutationId: string,
  requestBody: AddManualEntryToOutgoingBankingTransactionRequestType,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  return (
    await sendRequest<OutgoingMutationType>({
      request: {
        method: METHODS.POST,
        endpoint: `/bill/OutgoingMutations/${outgoingMutationId}`,
        data: requestBody
      },
      tenantReducer,
      lang
    })
  ).data;
};

export const deleteOutgoingMutation = async (
  outgoingMutationId: string,
  tenantReducer: TenantReturnValue,
  lang: uiCulture
) => {
  await sendRequest<boolean>({
    request: {
      method: METHODS.DELETE,
      endpoint: `/bill/OutgoingMutations/${outgoingMutationId}`
    },
    tenantReducer,
    lang
  });
};
