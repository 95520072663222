import { observer } from 'mobx-react';
import React, { useEffect, useReducer, useState } from 'react';
import { METHODS } from 'utils/request';

import { BillingCompletenessInsightResponseType } from '@zf/api-types/billing/billing-completeness';
import { EstimatedYearlyConsumptionType } from '@zf/api-types/forecasting';
import { ErrorCode } from '@zf/api-types/general';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { CardItem } from '@zf/stella-react/src/atoms/Card';
import AlertCircle from '@zf/stella-react/src/atoms/Info/AlertCircle';
import { Label } from '@zf/stella-react/src/atoms/Label';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { formatMoney } from '@zf/utils/src/number';

import { Paragraph } from '../../../../../../design-system/Foundation';
import { useStore } from '../../../../../../hooks/useStore';
import ErrorsTip from '../../billing-insights/right-part/ErrorsTip';
import EstimatedInvoiceAccuracy from './accuracy/EstimatedInvoiceAccuracy';
import css from './billing-details-card.module.scss';

type State = {
  organisationCfg: OrganizationConfigType | null;
  errors: ErrorCode[] | null;
};

type Props = {
  billingInsights: BillingCompletenessInsightResponseType;
};

const EstimatedInvoiceInfo = (props: Props) => {
  const { billingInsights } = props;

  const { applicationStore, organisationStore, contractStore } = useStore();
  const { getOrganisationConfig } = organisationStore.organisationService;
  const { selectedContract, billingCompletenessStore } = contractStore;
  const { completenessApiService } = billingCompletenessStore;
  const { tenantReducer, culture, getTranslation, sendRequest } = applicationStore;
  const { contract } = selectedContract;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    organisationCfg: null,
    errors: null
  });

  const [estimationProgress, setestimationProgress] = useState<EstimatedYearlyConsumptionType>();

  const initState = async () => {
    const organisationCfg = await getOrganisationConfig(
      tenantReducer.organization?.organizationId || '',
      tenantReducer.tenant?.id || ''
    );
    let newState: State = {
      organisationCfg,
      errors: null
    };

    if (organisationCfg.features.contractInvoiceEstimationEnabled && !billingInsights.estimatedInvoiceId) {
      //call billing completeness api to check for errors when no estimatedInvoice is available
      if (billingInsights.activeBillingCompletenessId) {
        newState.errors = await completenessApiService.getInvoiceEstimationErrors(
          billingInsights.activeBillingCompletenessId
        );
      }
    }

    return newState;
  };

  useEffect(() => {
    initState().then((res) => setState(res));
  }, []);

  useEffect(() => {
    //API call to check if the Estimation is in Progress or Not

    const getInvoiceProgress = async () => {
      if (contract) {
        const data = await getEstimationProgress(contract?.id);
        setestimationProgress(data);
      }
    };

    getInvoiceProgress();
  }, [contract, billingInsights]);

  const getEstimationProgress = async (id: string) => {
    return (
      await sendRequest<EstimatedYearlyConsumptionType>({
        request: {
          method: METHODS.GET,
          endpoint: `/fct/estimablecontracts/${id}`
        }
      })
    ).data;
  };

  /**
   * @description on/off invoice estimation feature
   */
  if (!state.organisationCfg?.features.contractInvoiceEstimationEnabled) {
    return null;
  }

  if (
    !billingInsights.draftInvoiceId &&
    !billingInsights.estimatedInvoiceId &&
    typeof estimationProgress?.isInvoiceEstimationInProgress === 'undefined' &&
    typeof estimationProgress?.isEstimationInProgress === 'undefined' &&
    estimationProgress?.isInvoiceEstimationInProgress &&
    estimationProgress?.isEstimationInProgress
  )
    return (
      <>
        <CardItem width="6">
          <Label>{getTranslation('contracts.estimated_invoice_amount')}</Label>
        </CardItem>
        <CardItem width="6" style={{ marginLeft: 'auto' }}>
          <Paragraph>{getTranslation('contracts.estimation_ongoing')}</Paragraph>
        </CardItem>
      </>
    );

  return (
    <>
      {state.organisationCfg?.features?.contractInvoiceEstimationEnabled && (
        <>
          {state.errors !== null && state.errors.length > 0 && (
            <>
              <CardItem width="6">
                <Label>{getTranslation('contracts.estimated_invoice_amount')}</Label>
              </CardItem>
              <CardItem width="6" justifyContent="end">
                <FlexElements alignItems="center">
                  <Paragraph className={css['billing-insights-label']}>-</Paragraph>
                  <AlertCircle>
                    <ErrorsTip errors={state.errors} />
                  </AlertCircle>
                </FlexElements>
              </CardItem>
            </>
          )}

          {state.errors === null && billingInsights.estimatedInvoiceId && !billingInsights.draftInvoiceId && (
            <>
              <CardItem width="6">
                <Label>{getTranslation('contracts.estimated_invoice_amount')}</Label>
              </CardItem>
              <CardItem width="6">
                <Paragraph className={css['billing-insights-label']} textAlign="right">
                  {formatMoney(billingInsights.estimatedInvoiceAmount, culture)}
                </Paragraph>
                <EstimatedInvoiceAccuracy
                  id="invoice.estimation.accuracy"
                  invoiceId={billingInsights.estimatedInvoiceId}
                  contract={contract}
                  accuracy={billingInsights.accuracyState}
                  size="small"
                  usage="detail"
                />
              </CardItem>
            </>
          )}

          {state.errors === null && billingInsights.draftInvoiceId && !billingInsights.estimatedInvoiceId && (
            <>
              <CardItem width="6">
                <Label>{getTranslation('contracts.draft_invoice_amount')}</Label>
              </CardItem>
              <CardItem width="6">
                <Paragraph className={css['billing-insights-label']} textAlign="right">
                  {formatMoney(billingInsights.draftInvoiceAmount, culture)}
                </Paragraph>
              </CardItem>
            </>
          )}
        </>
      )}
    </>
  );
};

export default observer(EstimatedInvoiceInfo);
