import React from 'react';

import { DataProviderTypesType, UpdateImportDataProviderType } from '@zf/api-types/data-provider';
import { FileFormatType } from '@zf/api-types/file-format';
import useCreateContext from '@zf/hooks/src/useCreateContext';

import { UseConfigReturnType } from '../../../app-context/hooks/use-config';
import DataProvidersContent from './data-providers-content';

type Props = {
  dataTypes: DataProviderTypesType[];
  fileformats: FileFormatType[];
  config: UseConfigReturnType<UpdateImportDataProviderType[]>;
};

export default function DataProviders(props: Props) {
  const { config } = props;
  const { Provider, useTracked } = useCreateContext<UpdateImportDataProviderType[]>(
    config.currentConfig ? config.currentConfig.responseData : []
  );

  return (
    <Provider>
      <DataProvidersContent {...props} useTracked={useTracked} />
    </Provider>
  );
}
