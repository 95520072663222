import React from 'react';

import { CollectionFlowType } from '@zf/api-types/collection-cycles';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../../app-context';
import { notify } from '../../../events/notification-events';
import { deleteCollectionFlow } from './collection-api-functions';
import { DialogClickRef } from '../../../design-system/ComponentSets/Dialog/Dialog';

type Props = {
  flow: CollectionFlowType;
  removeFlow: (flowId: string) => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { flow, removeFlow } = props;
  const { i18n, tenantReducer } = useAppContext();

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        await deleteCollectionFlow(flow.id, tenantReducer, i18n.lang);
        // Update state
        removeFlow(flow.id);

        notify.success({
          content: i18n.getTranslation('collection_flows.delete_flow_success', { flowName: flow.name })
        });
      } catch (e) {
        notify.error({
          content: i18n.getTranslation('collection_flows.delete_flow_fail', { flowName: flow.name }),
          error: e
        });
      }
    }
  }));

  return (
    <Paragraph>{i18n.getTranslation('collection_flows.remove_flow_paragraph', { flowName: flow.name })}</Paragraph>
  );
});
