import React from 'react';

import { useAppContext } from '../../../app-context';
import { BreadCrumb, Crumb, CrumbDivider } from '../../../components/BreadCrumb';
import { CrumbHeader } from '../../../components/Header';
import useLocationDetail from '../hooks/useLocationDetail';
import LocationTabContainer from './LocationTabContainer';

type Props = {
  urlParams: { [key: string]: string };
  navigate: (url: string) => void;
};

export default function LocationDetailPage(props: Props) {
  const { urlParams, navigate } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { location, status, navigator, timeStamp, setLocation } = useLocationDetail(urlParams.locationID);
  const { rootUrl } = tenantReducer.state;

  if (!location) return null;

  return (
    <>
      <CrumbHeader
        breadcrumb={
          <BreadCrumb>
            <Crumb icon="list" text={i18n.getTranslation('nav.locations')} url={`${rootUrl}/locations`} />
            <CrumbDivider />
            <Crumb icon="location" text={location.address.localizedDisplay || ''} />
          </BreadCrumb>
        }
      />
      <LocationTabContainer
        location={location}
        status={status}
        navigate={navigate}
        navigator={navigator}
        timeStamp={timeStamp}
        setLocation={setLocation}
      />
    </>
  );
}
