import React from 'react';

import { CustomerGroupType } from '@zf/api-types/master-data/customer-group';

import { useAppContext } from '../../app-context/app-context';
import SearchAutoFill, { CustomAutoFillProps } from '../input/SearchAutoFill';
import useAutoFill from '../input/useAutoFill';

type Props = {
  showDetails?: boolean;
  className?: string;
};

const processAutoFillItem = (cg: CustomerGroupType) => {
  return { value: cg, text: `${cg.name}` };
};

export type CustomerGroupAutofillProps = Props & CustomAutoFillProps<CustomerGroupType>;

export default function CustomerGroupAutoFill(props: CustomerGroupAutofillProps) {
  const { id, selectedValues, initialValue, query, queryField, onChange, onFocus } = props;
  const { i18n } = useAppContext();

  const autoFillTools = useAutoFill({
    endpoint: '/md/customerGroups',
    processRecord: processAutoFillItem,
    initialValue,
    selectedValues,
    query,
    queryField
  });

  const { focusedIndex, values } = autoFillTools;

  return (
    <SearchAutoFill
      {...props}
      id={id}
      values={values}
      selectedValues={selectedValues}
      placeholder={i18n.getTranslation('customer_groups.search')}
      focusedIndex={focusedIndex}
      autoFillTools={autoFillTools}
      processAutoFillItem={processAutoFillItem}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}
