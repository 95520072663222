import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import LinkMeterDialog from '../../../actions/meters/link-meter/link-meter-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  meter: MeterType;
  linkChild: boolean;
  setMeter: React.Dispatch<React.SetStateAction<MeterType | null>>;
  refresh?: () => void;
};

export default function LinkMeterListItem(props: Props) {
  const { linkChild } = props;
  const { i18n } = useAppContext();
  const { clickRef, validationRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="meter.updateparentrelation"
      icon="meter-linked"
      dialogTitle={i18n.getTranslation(`actions.meter.${linkChild ? 'add_submeter' : 'link_to_parent'}`)}
      dialogContent={<LinkMeterDialog {...props} ref={clickRef} validationRef={validationRef} />}
      onSubmit={onSubmit}
      validationRef={validationRef}
      buttonPositive={i18n.getTranslation('actions.meter.link_meter')}
    />
  );
}
