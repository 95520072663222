import { entitySubjectType } from '@zf/api-types/enums';
import React from 'react';
import css from './embedded.module.scss';

import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { useStore } from 'hooks/useStore';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import SearchBar, { SearchBarProps } from 'components/input/SearchBar';
import ConfirmationActionButton from 'components/Button/ConfirmationActionButton';
import useDialog from '@zf/hooks/src/useDialog';
import CustomerDeleteDialog from 'features/customer/customer-group/dialogs/CustomerDeleteDialog';
import useRefresh from 'app-context/hooks/use-refresh';

type Props = {
  entitySubjectType: entitySubjectType;
  list: React.ReactNode;
  subjectType: string;
  infoGrid?: React.ReactNode;
  selectedIds: Array<string>;
  setQueryParams?: Function;
  onSearchChange?: (query: string) => void;
  searchValue?: string;
  searchPlaceHolder?: string;
  searchDebounce?: number;
  queryParams: { customerGroupId: string; searchValue?: string };
};

const InlineInputFieldSearchBar = InlineInputField<SearchBarProps>(SearchBar);

export default function Embedded(props: Props) {
  const { list, selectedIds, searchPlaceHolder, searchDebounce, queryParams, onSearchChange } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;
  const { clickRef, onSubmit } = useDialog();
  const { refresh } = useRefresh();
  const { customerGroupId } = queryParams;

  const onSearchChangeWrapper = (searchValue: string) => {
    onSearchChange && onSearchChange(searchValue);
  };

  return (
    <>
      <Card id="customer-details" width="3" className={css['card']}>
        <CardHeader
          primaryText={getTranslation('general.delete')}
          onPrimaryClick={undefined}
          extraRight={
            <>
              {
                <InlineInputFieldSearchBar
                  id="filter-searchbar"
                  className={css['search-bar']}
                  onChange={onSearchChangeWrapper}
                  value={queryParams.searchValue}
                  placeholder={searchPlaceHolder}
                  debounceTime={searchDebounce}
                />
              }
              <ConfirmationActionButton
                id="customer-groups.customer.delete"
                dialogTitle={getTranslation('general.remove')}
                dialogContent={
                  <CustomerDeleteDialog
                    ref={clickRef}
                    customerGroupId={customerGroupId}
                    selectedIds={selectedIds}
                    onComplete={refresh}
                  />
                }
                buttonPositive={getTranslation('general.remove')}
                onSubmit={onSubmit}
                icon="trashcan"
                type="text"
                dialogType="danger"
                disabled={selectedIds.length === 0}
              >
                {getTranslation('general.remove')}
              </ConfirmationActionButton>
            </>
          }
        >
          {getTranslation('customer.customers')}
        </CardHeader>
        <CardBody className={css['card-body']}>{list}</CardBody>
      </Card>
    </>
  );
}
