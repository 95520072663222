import { Observer } from 'mobx-react';
import React from 'react';

import { CustomerBankAccountType } from '@zf/api-types/billing/customer-bank-account';
import useDialog from '@zf/hooks/src/useDialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { formatIBAN } from '@zf/utils/src/iban';

import { useAppContext } from '../../app-context';
import Button from '../../components/Button/Button';
import { dialog } from '../../events/dialog-events';
import { useStore } from '../../hooks/useStore';
import css from './autogenerated-bank-account.module.scss';
import BankDetailsConfirmIbanDialog from './dialogs/BankDetailsConfirmIbanDialog';
import BankDetailsRejectIbanDialog from './dialogs/BankDetailsRejectIbanDialog';

type Props = {
  customerBankAccount: CustomerBankAccountType;
};

export default function AutoGeneratedBankAccount(props: Props) {
  const { customerBankAccount } = props;
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();
  const { customerStore } = useStore();

  const openDialogConfirm = () => {
    dialog.normal({
      title: i18n.getTranslation('customer.confirm_iban'),
      icon: 'check',
      content: (
        <BankDetailsConfirmIbanDialog
          ref={clickRef}
          customerBankAccountId={customerBankAccount.id}
          onConfirmComplete={customerStore.onConfirmComplete}
        />
      ),
      buttonPositive: i18n.getTranslation('general.confirm'),
      onSubmit
    });
  };

  const openDialogReject = () => {
    dialog.normal({
      title: i18n.getTranslation('customer.reject_iban'),
      icon: 'cancel',
      content: (
        <BankDetailsRejectIbanDialog
          ref={clickRef}
          customerBankAccountId={customerBankAccount.id}
          onRejectComplete={customerStore.onRejectComplete}
        />
      ),
      buttonPositive: i18n.getTranslation('general.reject'),
      type: 'danger',
      onSubmit
    });
  };

  return (
    <Observer>
      {() => (
        <div className={css['autogenerated-bank-account']}>
          <Paragraph info={i18n.getTranslation('customer.approve_bank_account_info')}>
            {formatIBAN(customerBankAccount.iban)}
          </Paragraph>
          <div>
            <Button id={`${customerBankAccount.id}-confirm`} type="text" onClick={openDialogConfirm}>
              {i18n.getTranslation('general.confirm')}
            </Button>
            <Button id={`${customerBankAccount.id}-reject`} type="text" onClick={openDialogReject} danger>
              {i18n.getTranslation('general.reject')}
            </Button>
          </div>
        </div>
      )}
    </Observer>
  );
}
