import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useReducer, useState } from 'react';

import { navigate } from '@reach/router';
import { onlyMutationsOptions } from '@zf/api-types/enums';
import { SocialTariffExportType } from '@zf/api-types/regionals/regionals';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { isMaxDate, isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { TabSlider } from '../../../../design-system/Components';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { METHODS } from '../../../../utils/request';
import css from '../social-tariff.module.scss';
import CustomerPortfolio from './containers/customer-portfolio-section';
import ProtectedCustomerSection from './containers/protected-customer-section';
import { Paragraph } from '../../../../design-system/Foundation';

export type State = {
  startDateTime: Moment;
  endDateTime: Moment;
  type: onlyMutationsOptions;
  isMovedCustomer: boolean;
  year: '2022' | '2023';
};

export type QuarterSocialTariffHeatingReferencesType = {
  fixedInclTax?: number;
  consumptionInclTax?: number;
};

type Props = {
  validationRef: MutableRefObject<ValidationRef | undefined>;
  setUpdatedRows: (updatedRows: SocialTariffExportType[]) => void;
  refresh: () => void;
};

const CreateSocialTariffDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { validationRef, refresh } = props;

  const { applicationStore } = useStore();
  const { getTranslation, sendRequest, rootUrl } = applicationStore;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    startDateTime: moment(MIN_DATE),
    endDateTime: moment(MAX_DATE),
    type: onlyMutationsOptions.completecustomerportfolio,
    isMovedCustomer: false,
    year: '2023'
  });

  const validate = () => {
    if (validationRef.current) {
      if (state.type === onlyMutationsOptions.completecustomerportfolio) {
        validationRef.current.setIsError(isMinDate(state.startDateTime) || isMaxDate(state.endDateTime));
      } else {
        validationRef.current?.setIsError(false);
      }
    }
  };

  useEffect(() => {
    validate();
  }, [JSON.stringify(state)]);

  useImperativeHandle(ref, () => ({
    async onClick() {
      try {
        if (state.type === onlyMutationsOptions.completecustomerportfolio) {
          await sendRequest<SocialTariffExportType>({
            request: {
              method: METHODS.POST,
              endpoint: `/reg/socialtariff/exports/customerPortfolio`,
              data: {
                startDateTime: state.startDateTime?.toISOString(),
                endDateTime: state.endDateTime?.toISOString(),
                onlyMutations: state.isMovedCustomer
              }
            }
          });
        } else {
          const apiFriendlyValues = {};

          await sendRequest<SocialTariffExportType>({
            request: {
              method: METHODS.POST,
              endpoint: `/reg/socialtariff/exports/claimsProtectedCustomers`,
              data: {
                year: Number(state.year),
                quarterHeatingReferences: apiFriendlyValues
              }
            }
          });
        }

        notify.success({
          content: getTranslation('country_specifics.export_tariff_success')
        });

        refresh();
      } catch (e) {
        if (
          e.data.errors[0].key === 'NOT_CONFIGURED' ||
          e.data.errors[0].key === 'INVALID_REGIONAL_REGULATIONS_CONFIG'
        ) {
          navigate(`${rootUrl}/configuration/country-specifics`);
        }

        notify.error({
          content: getTranslation('country_specifics.export_tariff_failed'),
          error: e
        });
      }
    }
  }));

  const [tabItems] = useState([
    {
      id: onlyMutationsOptions.completecustomerportfolio,
      title: getTranslation('country_specifics.export_all_customers')
    },
    {
      id: onlyMutationsOptions.protectedcustomer,
      title: getTranslation('country_specifics.export_only_mutations')
    }
  ]);

  return (
    <div className={css['export-social-tariff']}>
      <Paragraph className={css['tab-slider-label']}>{getTranslation('country_specifics.which_export')}</Paragraph>
      <TabSlider
        id="only-mutations"
        tabItems={tabItems}
        selectedTabItem={state.type}
        setSelectedItem={(e) => setState({ type: e as onlyMutationsOptions })}
        className={css['tab-slider-only-mutations']}
      />

      {state.type === onlyMutationsOptions.completecustomerportfolio ? (
        <CustomerPortfolio state={state} setState={setState} />
      ) : (
        <ProtectedCustomerSection state={state} setState={setState} />
      )}
    </div>
  );
});

export default observer(CreateSocialTariffDialog);
