import React from 'react';

import useDebounce from '@zf/hooks/src/useDebounce';
import { SortDirection } from '@zf/stella-react/src/atoms/Table';

import { RequestType } from '../types/Request';

export type queryParam = {
  [key: string]: any;
};

export type SortStateType = {
  sortBy: string[];
  sortDirection: { [key: string]: SortDirection };
};

export type CreateRequestReturnType = {
  request: RequestType | null;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  handleSort: (sortParams: SortStateType) => void;
  sortBy: string[];
  sortDirection: {
    [key: string]: SortDirection;
  };
};

export default function useCreateRequest(
  endpoint: string,
  queryParams?: queryParam,
  withDebounce = true
): CreateRequestReturnType {
  const [request, setRequest] = React.useState<RequestType | null>(null);
  const [selectedIds, setSelectedIds] = React.useState<Array<string>>([]);
  const [sortState, setSortState] = React.useState<SortStateType>({
    sortBy: [],
    sortDirection: {}
  });

  const setDebounceCallback = useDebounce(withDebounce ? 500 : 0);

  React.useEffect(() => {
    const keys = Object.keys(sortState.sortDirection);

    const query = {
      ...queryParams,
      orderBy:
        keys.length > 0
          ? keys.reduce((acc, key) => {
              if (sortState.sortDirection[key]) {
                acc += `${sortState.sortDirection[key] === 'ASC' ? '' : '-'}${key}`;
              }

              return acc;
            }, '')
          : queryParams
          ? queryParams.orderBy
          : ''
    };

    setDebounceCallback(() => {
      setRequest({
        endpoint,
        query
      });
    });
  }, [sortState, JSON.stringify(queryParams), endpoint]);

  const handleSort = (sortParams: SortStateType) => {
    const { sortBy, sortDirection } = sortParams;
    setSortState({ sortBy, sortDirection });
  };

  return {
    request,
    selectedIds,
    setSelectedIds,
    handleSort,
    sortBy: sortState.sortBy,
    sortDirection: sortState.sortDirection
  };
}
