import React from 'react';

import { Heading } from '@zf/stella-react/src/atoms/Heading';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';

import { useAppContext } from '../../../../../../app-context';
import DataGridCardHeaderSection from '../../../../../../components/DataGridCard/DataGridCardHeaderSection';
import css from './filter-header.module.scss';
import useOutgoingMutationsFilter from './hooks/useOutgoingMutationsFilter';
import SearchBar, { SearchBarProps } from '../../../../../../components/input/SearchBar';

const InlineSearchBar = InlineInputField<SearchBarProps>(SearchBar);

export default function FirstFilterHeader() {
  const { i18n } = useAppContext();

  const { queryParams, filterTags, filters, setFlexSearch } = useOutgoingMutationsFilter();

  const { flexSearch } = queryParams;

  return (
    <DataGridCardHeaderSection className={css['header-section']}>
      <Heading headingType="h3" style="bold">
        {i18n.getTranslation('mutations.mutations')}
      </Heading>
      <div className={css['filters']}>
        {filterTags}
        {filters}
        <InlineSearchBar
          id="query-search"
          onChange={setFlexSearch}
          value={flexSearch}
          placeholder={i18n.getTranslation('general.search')}
          debounceTime={500}
        />
      </div>
    </DataGridCardHeaderSection>
  );
}
