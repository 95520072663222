import clone from 'clone';
import moment, { Moment } from 'moment';
import React from 'react';

import { RateType, UpdateTaxCodeType } from '@zf/api-types/tax-codes';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import { Card, CardBody, CardEmptyBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { DeprecatedStaticColumn, DeprecatedStaticTable } from '@zf/stella-react/src/atoms/Table';
import { MAX_DATE, MIN_DATE } from '@zf/utils/src/date';
import { roundNumberToDecimals } from '@zf/utils/src/number';

import { useAppContext } from '../../../app-context';
import Button from '../../../components/Button/Button';
import { ConfigHelp } from '../../../components/CoachMarks';
import { DeleteIcon, Icon } from '../../../components/Icon';
import DateRangePicker from '../../../components/input/DateRangePicker';
import { InlineFloatInput } from '../../../components/input/FloatInput';

type Props = {
  values: UpdateTaxCodeType[];
  selectedTaxCodeIndex: number;
  setValue: <V>(value: Partial<V>) => void;
};

type RateIndexes = {
  selectedTaxRate: number;
  scrollToRateIndex: number;
};

export default function TaxRatesCard(props: Props) {
  const { values, selectedTaxCodeIndex, setValue } = props;

  const { i18n } = useAppContext();

  const stateReducer = createStateReducer<RateIndexes, Partial<RateIndexes>>();
  const [rateIndexes, setRateIndexes] = React.useReducer(stateReducer, {
    selectedTaxRate: -1,
    scrollToRateIndex: -1
  });

  const handleSetSelectedTaxRate = (index: number) => {
    setRateIndexes({ selectedTaxRate: index });
  };

  const handleSetScrollToIndex = (index: number) => {
    setRateIndexes({ scrollToRateIndex: index });
  };

  const setRates = (index: number, value: RateType[keyof RateType], dataKey: keyof RateType) => {
    const clonedArray = clone(values) as Record<string, any>[];
    clonedArray[selectedTaxCodeIndex].rates[index][dataKey] = value;
    setValue({
      values: clonedArray
    });
  };

  const setDates = (index: number, dates: (Moment | null | undefined)[]) => {
    const clonedArray = clone(values);
    clonedArray[selectedTaxCodeIndex].rates[index].startDateTime = dates[0]
      ? dates[0].toISOString()
      : moment().toISOString();
    clonedArray[selectedTaxCodeIndex].rates[index].endDateTime = dates[1] ? dates[1].toISOString() : MAX_DATE;

    setValue({
      values: clonedArray
    });
  };

  const addRate = () => {
    const clonedArray = clone(values);
    clonedArray[selectedTaxCodeIndex].rates.push({
      startDateTime: MIN_DATE,
      endDateTime: MAX_DATE,
      rate: 0
    });
    setValue({
      values: clonedArray
    });
    handleSetScrollToIndex(clonedArray.length - 1);
  };

  const deleteRate = (index: number) => {
    const clonedArray = clone(values);
    clonedArray[selectedTaxCodeIndex].rates.splice(index, 1);
    setRateIndexes({ selectedTaxRate: -1 });
    setValue({
      values: clonedArray
    });
  };

  const rateRows =
    values[selectedTaxCodeIndex] && values[selectedTaxCodeIndex].rates
      ? values[selectedTaxCodeIndex].rates.map((rate, index) => {
          return {
            period: (
              <DateRangePicker
                id={`taxcode.period.index-${index}`}
                startDate={moment(rate.startDateTime)}
                endDate={moment(rate.endDateTime)}
                setDates={(dates) => setDates(index, dates)}
              />
            ),
            rate: (
              <InlineFloatInput
                id={`taxcode.rate.index-${index}`}
                value={roundNumberToDecimals(rate.rate * 100, 14)}
                onChange={(val) => setRates(index, val / 100, 'rate')}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!rate.rate}
                postfix="%"
              />
            ),
            deleteAction: (
              <DeleteIcon
                id={`taxcode.delete.index-${index}`}
                tooltipFor="tax-rates-table"
                onClick={() => deleteRate(index)}
              />
            )
          };
        })
      : [];

  const taxCodeSelected: UpdateTaxCodeType | undefined = values[selectedTaxCodeIndex];

  return (
    <Card id="tax-rates-card">
      <CardHeader
        extraLeft={
          <ConfigHelp
            title={i18n.getTranslation('coachmark.tc_rates.title')}
            content={[i18n.getTranslation('coachmark.tc_rates.paragraph')]}
          />
        }
        extraRight={
          selectedTaxCodeIndex !== -1 && (
            <Button id="taxcode.rate" type="text" icon="plus" onClick={addRate}>
              {i18n.getTranslation('general.add')}
            </Button>
          )
        }
      >
        {!taxCodeSelected
          ? i18n.getTranslation('tax_code.rates')
          : i18n.getTranslation('tax_code.rates_selected', { name: values[selectedTaxCodeIndex].name })}
      </CardHeader>
      {taxCodeSelected ? (
        <CardBody type="table" fixedHeight>
          <DeprecatedStaticTable
            tooltipId="tax-rates-table"
            rows={rateRows}
            onRowSelect={handleSetSelectedTaxRate}
            selectedIndex={rateIndexes.selectedTaxRate}
          >
            <DeprecatedStaticColumn flexWidth="4" dataKey="period" label={i18n.getTranslation('general.period')} />
            <DeprecatedStaticColumn flexWidth="1" dataKey="rate" label={i18n.getTranslation('tax_code.rate')} />
            <DeprecatedStaticColumn dataKey="deleteAction" />
          </DeprecatedStaticTable>
        </CardBody>
      ) : (
        <CardEmptyBody icon={<Icon type="money" />} title={i18n.getTranslation('tax_code.no_tax_codes')} />
      )}
    </Card>
  );
}
