import { observer } from 'mobx-react';
import React from 'react';

import { MeterStatusHistoryType } from '@zf/api-types/master-data/meter';

import { Link } from '../../../../../design-system/Components';
import { useStore } from '../../../../../hooks/useStore';
import { formatMeterAddress } from '../../../../../utils/meter';

type Props = {
  id?: string;
  activeState?: MeterStatusHistoryType;
  tooltipFor?: string;
};

const MeterAddress = (props: Props) => {
  let { activeState } = props;
  const { rootUrl } = useStore().applicationStore;

  if (activeState) {
    if (activeState.serviceLocationId) {
      return (
        <Link
          id={`location-${activeState.serviceLocationId}`}
          url={`${rootUrl}/locations/${activeState.serviceLocationId}`}
          icon="location"
        >
          {formatMeterAddress(activeState)}
        </Link>
      );
    }

    if (activeState.propertyGroup) {
      return (
        <Link
          id={`property-${activeState.propertyGroup.id}`}
          url={`${rootUrl}/property-groups/${activeState.propertyGroup.id}`}
          icon="propertygroup"
        >
          {formatMeterAddress(activeState) || activeState.propertyGroup.name}
        </Link>
      );
    }

    return <>{formatMeterAddress(activeState)}</>;
  }

  return null;
};

export default observer(MeterAddress);
