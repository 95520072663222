import React from 'react';

import StellaDropzone, { DropzoneProps } from '@zf/stella-react/src/atoms/Dropzone/StellaDropzone';
import { useAppContext } from '../../app-context/app-context';
import AccessControl from '../Permissions/AccessControl';

export default function Dropzone(props: DropzoneProps) {
  const { id, className, onDrop, onTrigger, heading, content } = props;
  const { i18n } = useAppContext();

  return (
    <AccessControl permissionsKey={id}>
      <StellaDropzone
        {...props}
        id={id}
        className={className}
        heading={heading || i18n.getTranslation('dropzone.heading')}
        content={content || i18n.getTranslation('dropzone.content')}
        onTrigger={onTrigger}
        onDrop={onDrop}
      />
    </AccessControl>
  );
}
