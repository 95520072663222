import { observer } from 'mobx-react';
import React from 'react';

import { entitySubjectType } from '@zf/api-types/enums';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';

import RecalculateEavListItem from '../../../../components/list-items/locations/RecalculateEavListItem';
import { attachmentType } from '../../../../components/units/EntityAttachments/logic/FilesStore';
import FilesActions from '../../../../components/units/EntityAttachments/ui/FilesActions';
import { useStore } from '../../../../hooks/useStore';
import BlockUnblockListItem from '../actions/detail-page/BlockUnblockListItem';
import ChangeProductListItem from '../actions/detail-page/ChangeProductListItem';
import DeleteContractListItem from '../actions/detail-page/DeleteContractListItem';
import SignContractListItem from '../actions/detail-page/SignContractListItem';
import TerminateContractListItem from '../actions/detail-page/TerminateContractListItem';

const ContractActions = () => {
  const { contractStore } = useStore();
  const { selectedContract, filesStore, pageActionPermissions, forecastingService, billingRelation_ } = contractStore;
  const { entityId, listFiles } = contractStore.entityAttachmentFeatures;

  const contract = selectedContract.contract;

  return (
    <FilesActions
      listFiles={listFiles}
      attachmentType={attachmentType.entityattachments}
      filesStore={filesStore}
      entityId={entityId}
      subjectType={entitySubjectType.contract}
    >
      <SignContractListItem contract={contract} />
      <DeleteContractListItem contract={contract} />
      <TerminateContractListItem
        contract={contract}
        billingRelation={billingRelation_}
        mayTerminateContract={contractStore.pageActionPermissions.mayTerminateContract}
      />
      <BlockUnblockListItem contract={contract} />
      <ChangeProductListItem contract={contract} />
      <RecalculateEavListItem
        enabled={pageActionPermissions.mayRecalculateEav}
        entityId={contract.id}
        entity="contract"
        forecastingService={forecastingService}
      />

      <HorizontalDivider />
    </FilesActions>
  );
};

export default observer(ContractActions);
