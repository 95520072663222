import moment from 'moment';
import React from 'react';

import { contractStatus, serviceStatus, utilityType } from '@zf/api-types/enums';
import { ServiceLocationType, ServiceType, ShortContractType } from '@zf/api-types/master-data/servicelocation';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { betweenDates } from '@zf/utils/src/date';

import { useAppContext } from '../../../app-context';
import { Icon } from '../../../components/Icon';
import useCreateRequest from '../../../hooks/useCreateRequest';
import useInfiniAPI from '../../../hooks/useInfiniAPI';
import { getServiceStatus } from '../../../utils/serviceState';
import css from './style.module.scss';

type RowType = {
  __id: string;
  __etag: string;
  DisplayAddress: React.ReactNode;
  services: number;
  suppliedServices?: JSX.Element;
  customer?: JSX.Element[];
};

const useLocationList = (
  searchValue: string,
  useCase: 'add_location' | 'add_allocation_group',
  queryParameters?: Record<string, any>
) => {
  const { i18n, tenantReducer, enumReducer } = useAppContext();
  const [today] = React.useState(moment());

  const processRecord = (value: ServiceLocationType) => {
    const servicesAll = value.services.filter((service) => {
      return getServiceStatus(service.statusHistory);
    });

    let record: RowType = {
      __id: value.id,
      __etag: value._etag,
      DisplayAddress: value.address.localizedDisplay,
      services: servicesAll.length
    };

    if (useCase === 'add_allocation_group') {
      const servicesSupplied = value.services.filter((service) => {
        const activeStatus = getServiceStatus(service.statusHistory);
        return activeStatus && activeStatus.serviceStatus === serviceStatus.supplied;
      });

      const shortContracts = servicesSupplied.reduce((acc: ShortContractType[], service: ServiceType) => {
        const contract = service.contracts.find(
          (c) =>
            betweenDates(c.supplyStartDateTime, c.supplyEndDateTime, today) &&
            c.currentContractStatus === contractStatus.signed
        );

        if (
          contract &&
          !acc.some((sc) => {
            return sc.contractorDisplayName === contract.contractorDisplayName;
          })
        ) {
          acc.push(contract);
        }

        return acc;
      }, []);

      record = {
        ...record,
        suppliedServices: (
          <FlexElements>
            {servicesSupplied
              .reduce((acc: utilityType[], service) => {
                if (!acc.includes(service.utilityType)) {
                  acc.push(service.utilityType);
                }

                return acc;
              }, [])
              .map((utilityType_, i) => {
                return (
                  <Icon
                    id={i.toString()}
                    tooltipFor="locations-table-tip"
                    title={enumReducer.getTranslation('utilityType', utilityType_)}
                    className={css['utlity-type-icon']}
                    type={utilityType_}
                    key={i}
                    color
                    usedInTable
                  />
                );
              })}
          </FlexElements>
        ),
        customer: shortContracts.map((sc) => {
          return <Paragraph key={`${sc.contractorId}-${sc.contractId}`}> {sc.contractorDisplayName}</Paragraph>;
        })
      };
    }

    return record;
  };

  let query = {
    flexSearch: searchValue,
    availableForPropertySelectionFromDateTime: today.toISOString()
  };

  if (queryParameters) {
    query = { ...query, ...queryParameters };
  }

  const { request, selectedIds, sortBy, sortDirection, setSelectedIds, handleSort } = useCreateRequest(
    '/md/servicelocations',
    query
  );

  const { loading, error, rows, totalAmountOfRows, selectAllBusy, refresh, sortableFields, setStopIndex } =
    useInfiniAPI<ServiceLocationType, RowType>({
      request,
      tenantReducer,
      lang: i18n.lang,
      processRecord,
      onSelectRow: setSelectedIds
    });

  return {
    rows,
    loading,
    sortableFields,
    error,
    selectedIds,
    sortBy,
    sortDirection,
    totalAmountOfRows,
    selectAllBusy,
    refresh,
    handleSort,
    setSelectedIds,
    setStopIndex
  };
};

export default useLocationList;
