import React from 'react';

import { UpdateBankAccountType } from '@zf/api-types/general';

import useConfig from '../../../app-context/hooks/use-config';
import Bankaccounts from './bankaccounts';

export default function BankAccountsTab() {
  const config = useConfig<UpdateBankAccountType[]>('bankAccounts', '/cfg/BankAccounts');

  return <Bankaccounts config={config} />;
}
