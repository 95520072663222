import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { EntityAttachment } from '@zf/api-types/entity-attachments/entity-attachments';

import useSpecificFileColumns from '../../../../../../components/units/EntityAttachments/logic/useSpecificFileColumns';
import Files from '../../../../../../components/units/EntityAttachments/ui/Files';
import { useStore } from '../../../../../../hooks/useStore';
import { attachmentType } from '../../../../../../components/units/EntityAttachments/logic/FilesStore';
import OriginColumn from '../../../../../../components/units/EntityAttachments/ui/OriginColumn';

type Props = {
  entityId: string;
  customerId: string;
};

const ContractAttachments = (props: Props) => {
  const { entityId, customerId } = props;
  const { applicationStore, contractStore } = useStore();
  const { filesStore } = contractStore;
  const { rootUrl, getTranslation, getEnumTranslation } = applicationStore;
  const { listFiles } = contractStore.entityAttachmentFeatures;

  const { getOrigin, getSeen, getSigned } = useSpecificFileColumns(rootUrl, getTranslation, getEnumTranslation);

  const [specificFileCols] = useState([
    {
      width: 120,
      label: getTranslation('entity_attachment.seen'),
      dataKey: 'seen'
    },
    {
      width: 130,
      label: getTranslation('entity_attachment.signed_date'),
      dataKey: 'signedDateTime'
    },
    {
      width: 300,
      label: getTranslation('entity_attachment.origin'),
      noClick: true,
      dataKey: 'origin'
    }
  ]);

  const processSpecificCols = (entityAttachment: EntityAttachment) => {
    const { originUrl, origin, contract, contractUrl, originDisplayName, contracts } = getOrigin(entityAttachment);

    return {
      origin: originUrl && origin && (
        <OriginColumn
          contracts={contracts || []}
          origin={origin}
          contractUrl={contractUrl}
          contractDisplayName={contract}
          originurl={originUrl}
          originDisplayName={originDisplayName}
        />
      ),
      seen: getSeen(entityAttachment),
      signedDateTime: getSigned(entityAttachment)
    };
  };

  return (
    <Files
      listFiles={listFiles}
      attachmentType={attachmentType.entityattachments}
      customerId={customerId}
      filesStore={filesStore}
      entityId={entityId}
      specificCols={specificFileCols}
      processSpecificCols={processSpecificCols}
    />
  );
};

export default observer(ContractAttachments);
