import classNames from 'classnames';
import React from 'react';

import { FileUploadType } from '@zf/api-types/general';
import { colors } from '@zf/utils/src/color';

import { useAppContext } from '../../app-context';
import useDownload from '../../app-context/hooks/use-download';
import { Icon, Paragraph } from '../../design-system/Foundation';
import File from './File';
import css from './upload-input.module.scss';

type Props = {
  file: FileUploadType;
  setFile: (file?: FileUploadType) => void;
  disabled?: boolean;
};

export default function FileNamePlaceHolder(props: Props) {
  const { file, disabled = false, setFile } = props;
  const { downloadSingleFile } = useDownload();
  const { tenantReducer } = useAppContext();

  const removeFile = () => {
    setFile(undefined);
  };

  const fileName = file.file ? file.file.name : file.fileName ? file.fileName : file.internalFileName;

  return disabled ? (
    <div className={classNames(css['file-list'], css['single-file'], css['disabled'])}>
      <Icon name="attachment" />
      <Paragraph color={colors['silver-400']}>{fileName}</Paragraph>
    </div>
  ) : (
    <div className={classNames(css['file-list'], css['single-file'])}>
      <File
        file={file}
        onCancel={() => removeFile()}
        download={() => {
          if (file.id) downloadSingleFile(`/att/attachments/${file.id}`, tenantReducer);
        }}
      >
        {fileName}
      </File>
    </div>
  );
}
