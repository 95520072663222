import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { countryCode, customerType as customerTypeEnum } from '@zf/api-types/enums';
import { CustomerType } from '@zf/api-types/master-data/customer';
import useValidator from '@zf/hooks/src/useValidator';
import { CardBody, CardHeader, CardItem } from '@zf/stella-react/src/atoms/Card';
import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Label } from '@zf/stella-react/src/atoms/Label';
import { isMinDate, MIN_DATE } from '@zf/utils/src/date';

import CountryInput, { CountryInputProps } from '../../../../components/input/country-input';
import InputField, { InputFieldProps } from '../../../../components/input/InputField';
import DatePicker, { DatePickerProps } from '../../../../components/Lang/DatePicker';
import { SuspenseCard } from '../../../../components/suspense';
import Select from '../../../../design-system/ComponentSets/Select/Select';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import { formatSsin, validateIdentificationNumber } from '../../../../utils/customer';
import { createHeader, METHODS } from '../../../../utils/request';
import css from './customer-details-card.module.scss';
import CustomerAddress from './CustomerAddress';

const InlineInputFieldInput = InlineInputField<InputFieldProps>(InputField);
const InlineInputFieldDatePicker = InlineInputField<DatePickerProps>(DatePicker);
const InlineCountryInput = InlineInputField<CountryInputProps>(CountryInput);

type Props = {
  customer: CustomerType;
  setCustomer: Dispatch<SetStateAction<CustomerType | null>>;
};

type DetailsType = {
  customerType: customerTypeEnum;
  accountNumber: string;
  initials: string;
  lastName: string;
  firstName: string;
  salutation: string;
  birthDate: Moment;
  companyName: string;
  organizationNumber: string;
  vatNumber: string | undefined;
  ssin: string | null;
  ssinCountry: countryCode;
};

export type LocalAddressType = {
  streetName: string;
  streetNumber: string;
  streetNumberAddition?: string;
  postalCode: string;
  city: string;
  country: string;
};

export type CustomerDetailsValuesType = {
  details: DetailsType;
  address: LocalAddressType;
};

const CustomerDetailsCard = (props: Props) => {
  const { customer, setCustomer } = props;
  const { applicationStore } = useStore();
  const { getTranslation, getEnumTranslation, sendRequest } = applicationStore;

  const [isAddressEditing, setIsAddressEditing] = useState(false);

  const { values, backup, isDirty, setValue, backupValues, restoreValues, submitFactory } =
    useValidator<CustomerDetailsValuesType>({
      initialValues: {
        details: {
          customerType: customer.customerType,
          accountNumber: customer.accountNumber,
          initials: customer.initials,
          lastName: customer.lastName,
          firstName: customer.firstName,
          salutation: customer.salutation,
          birthDate: moment(customer.birthDate),
          companyName: customer.companyName,
          organizationNumber: customer.organizationNumber,
          vatNumber: customer.vatNumber,
          ssin: formatSsin(customer.ssin, customer.ssinCountry),
          ssinCountry: customer.ssinCountry
        },

        address: {
          streetName: customer.invoiceAddress.streetName || '',
          streetNumber: customer.invoiceAddress.streetNumber || '',
          streetNumberAddition: customer.invoiceAddress.streetNumberAddition || '',
          postalCode: customer.invoiceAddress.postalCode || '',
          city: customer.invoiceAddress.city || '',
          country: customer.invoiceAddress.country || ''
        }
      },
      refreshTrigger: customer.id
    });

  const setDetail = (val: Partial<DetailsType>) => {
    setValue({ details: { ...values.details, ...val } });
  };

  const setAddress = (val: Partial<LocalAddressType>) => {
    setValue({ address: { ...values.address, ...val } });
  };

  const handleSaveDetails = submitFactory(async () => {
    if (JSON.stringify(backup.address) !== JSON.stringify(values.address)) {
      try {
        const updatedCustomer = (
          await sendRequest<CustomerType>({
            request: {
              method: METHODS.POST,
              endpoint: `/md/customers/${customer.id}/invoiceaddress`,
              data: {
                ...values.address
              }
            },
            customHeaders: createHeader({
              'If-Match': customer._etag
            })
          })
        ).data;

        notify.success({
          content: getTranslation('customer.address_update_success')
        });

        setCustomer(updatedCustomer);
        backupValues();
      } catch (e) {
        notify.error({
          content: getTranslation('customer.address_update_failed'),
          error: e
        });
      }
    }

    if (JSON.stringify(backup.details) !== JSON.stringify(values.details)) {
      try {
        const {
          accountNumber,
          salutation,
          initials,
          firstName,
          lastName,
          birthDate,
          customerType,
          companyName,
          organizationNumber,
          vatNumber,
          ssinCountry,
          ssin
        } = values.details;

        const updatedCustomer = (
          await sendRequest<CustomerType>({
            request: {
              method: METHODS.POST,
              endpoint: `/md/customers/${customer.id}/details`,
              data: {
                accountNumber,
                salutation,
                initials,
                firstName,
                lastName,
                birthDate: isMinDate(birthDate) ? MIN_DATE : birthDate.toISOString(),
                companyName: customerType === customerTypeEnum.organization ? companyName : '',
                organizationNumber: customerType === customerTypeEnum.organization ? organizationNumber : '',
                vatNumber: customerType === customerTypeEnum.organization ? vatNumber : '',
                ssinCountry: customerType === customerTypeEnum.person ? ssinCountry : '',
                ssin: customerType === customerTypeEnum.person ? ssin : '',
                customerType
              }
            },
            customHeaders: createHeader({
              'If-Match': customer._etag
            })
          })
        ).data;

        notify.success({
          content: getTranslation('customer.details_update_success')
        });

        setCustomer(updatedCustomer);
        backupValues();
      } catch (e) {
        notify.error({
          content: getTranslation('customer.details_update_failed'),
          error: e
        });
      }
    }

    setIsAddressEditing(false);
  });

  const handleCancel = () => {
    if (isDirty) {
      restoreValues();
    }

    setIsAddressEditing(false);
  };

  const handleSave = () => {
    if (isDirty) {
      handleSaveDetails();
    }
  };

  const keyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      handleCancel();
    } else if (event.key === 'Enter') {
      const elem = document.activeElement as HTMLElement;
      if (elem) elem.blur();
    }
  };

  return (
    <SuspenseCard id="customer-details">
      <CardHeader
        primaryText={isDirty ? getTranslation('general.save') : undefined}
        onPrimaryClick={handleSave}
        secondaryText={isDirty || isAddressEditing ? getTranslation('general.cancel') : undefined}
        onSecondaryClick={handleCancel}
      >
        {getTranslation('general.details')}
      </CardHeader>
      <CardBody type="grid" className={css['customer-details-card']}>
        <CardItem width="6">
          <Label>{getTranslation('customer.customer_id')}</Label>
        </CardItem>
        <CardItem width="6">
          <InlineInputFieldInput
            id="customer.account_number"
            onKeyDown={keyHandler}
            value={values.details.accountNumber}
            onChange={(accountNumber) => setDetail({ accountNumber })}
            error={!values.details.accountNumber}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('general.type')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <Select
            id="customer"
            type="small"
            onChange={(val) => setDetail({ customerType: val[0] })}
            selectedValues={[values.details.customerType]}
            values={[
              {
                icon: 'organization',
                value: customerTypeEnum.organization,
                text: getEnumTranslation('customerType', customerTypeEnum.organization)
              },
              {
                icon: 'customer',
                value: customerTypeEnum.person,
                text: getEnumTranslation('customerType', customerTypeEnum.person)
              }
            ]}
          />
        </CardItem>
        {values.details.customerType === customerTypeEnum.organization && (
          <>
            <CardItem width="6">
              <Label>{getTranslation('customer.company_name')}</Label>
            </CardItem>
            <CardItem justifyContent="end" width="6">
              <InlineInputFieldInput
                id="customer.company_name"
                onKeyDown={keyHandler}
                value={values.details.companyName}
                onChange={(companyName) => setDetail({ companyName })}
                error={!values.details.companyName}
              />
            </CardItem>
            <CardItem width="6">
              <Label>{getTranslation('customer.org_number')}</Label>
            </CardItem>
            <CardItem justifyContent="end" width="6">
              <InlineInputFieldInput
                id="customer.organizationnumber"
                onKeyDown={keyHandler}
                value={values.details.organizationNumber}
                onChange={(organizationNumber) => setDetail({ organizationNumber })}
              />
            </CardItem>
            <CardItem width="6">
              <Label>{getTranslation('customer.vat_number')}</Label>
            </CardItem>
            <CardItem justifyContent="end" width="6">
              <InlineInputFieldInput
                id="customer.vat_nr"
                onKeyDown={keyHandler}
                value={values.details.vatNumber}
                onChange={(vatNumber) => setDetail({ vatNumber })}
              />
            </CardItem>
          </>
        )}

        <CardItem width="6">
          <Label>{getTranslation('customer.title')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <InlineInputFieldInput
            id="customer.salutation"
            value={values.details.salutation}
            onKeyDown={keyHandler}
            onChange={(salutation) => setDetail({ salutation })}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('customer.initials')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <InlineInputFieldInput
            id="customer.initials"
            onKeyDown={keyHandler}
            value={values.details.initials}
            onChange={(initials) => setDetail({ initials })}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('customer.first_name')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <InlineInputFieldInput
            id="customer.firstname"
            onKeyDown={keyHandler}
            value={values.details.firstName}
            onChange={(firstName) => setDetail({ firstName })}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('customer.last_name')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <InlineInputFieldInput
            id="customer.lastname"
            onKeyDown={keyHandler}
            value={values.details.lastName}
            onChange={(lastName) => setDetail({ lastName })}
            error={values.details.customerType === customerTypeEnum.person && !values.details.lastName}
          />
        </CardItem>
        <CardItem width="6">
          <Label>{getTranslation('customer.birth_date')}</Label>
        </CardItem>
        <CardItem justifyContent="end" width="6">
          <InlineInputFieldDatePicker
            id="customer.birthdate"
            value={values.details.birthDate}
            onChange={(birthDate) => setDetail({ birthDate })}
          />
        </CardItem>

        <CustomerAddress
          address={values.address}
          isAddressEditing={isAddressEditing}
          setIsAddressEditing={setIsAddressEditing}
          setAddress={setAddress}
          handleCancel={handleCancel}
        />

        {values.details.customerType === customerTypeEnum.person && (
          <>
            <CardItem width="6">
              <Label>
                {getTranslation('customer.country_of_residence')} ({getTranslation('customer.ssin')})
              </Label>
            </CardItem>
            <CardItem justifyContent="end" width="6">
              <InlineCountryInput
                id="country-ssin"
                onChange={(value) => {
                  setDetail({
                    ssinCountry: value[0] as countryCode,
                    ssin: formatSsin(values.details.ssin, value[0] as countryCode)
                  });
                }}
                selectedValues={values.details.ssinCountry ? [values.details.ssinCountry] : []}
                acceptedCountries={[countryCode.bel, countryCode.nld]}
              />
            </CardItem>
            {values.details.ssinCountry && (
              <>
                <CardItem width="6">
                  <Label>
                    {values.details.ssinCountry === countryCode.bel
                      ? getTranslation('customer.rno')
                      : getTranslation('customer.bsn')}
                  </Label>
                </CardItem>
                <CardItem justifyContent="end" width="6">
                  <InlineInputFieldInput
                    id="social-security-number"
                    onChange={(value) => setDetail({ ssin: value })}
                    maxLength={values.details.ssinCountry === countryCode.nld ? 9 : undefined}
                    value={values.details.ssin || ''}
                    onBlur={() => setDetail({ ssin: formatSsin(values.details.ssin, values.details.ssinCountry) })}
                    error={
                      !values.details.ssin ||
                      !validateIdentificationNumber(values.details.ssin, values.details.ssinCountry as countryCode)
                    }
                  />
                </CardItem>
              </>
            )}
          </>
        )}
      </CardBody>
    </SuspenseCard>
  );
};

export default observer(CustomerDetailsCard);
