import { BalanceQueryParams } from 'features/devices/prepayment-device/stores/BalanceStore';
import moment, { Moment } from 'moment';
import { METHODS } from 'utils/request';

import {
  AddMeasurementRequestType,
  AdjacentMeasurementPairs,
  MeasurementFlatValueType,
  MeasurementType,
  MeasurementValidationResponseType,
  MeterType
} from '@zf/api-types/master-data/meter';

import ApplicationStore from '../../stores/domain/ApplicationStore';
import BaseService from '../BaseService';
import { AddMeasurementRequest } from '@zf/api-types/metering/measurements';
import { PagedResponseType } from '@zf/api-types/api';

export default class MeasurementService extends BaseService {
  constructor(applicationStore: ApplicationStore) {
    super('/me/Measurements', applicationStore);
  }

  getFlatMeasurements = async (channelId: string, query?: BalanceQueryParams) => {
    return (
      await this.applicationStore.sendRequest<MeasurementFlatValueType[]>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/${channelId}/flat`,
          query
        }
      })
    ).data;
  };
  getMeasurements = async (channelId: string, query?: BalanceQueryParams) => {
    return (
      await this.applicationStore.sendRequest<PagedResponseType<MeasurementType>>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/${channelId}`,
          query
        }
      })
    ).data.results;
  };

  getPreviousMeasurements = async (meterId: string, measurementDate: Moment, limit = 1) => {
    return (
      await this.applicationStore.sendRequest<AdjacentMeasurementPairs>({
        request: {
          method: METHODS.GET,
          endpoint: `${this.endpoint}/m/${meterId}`,
          query: { measurementDate: measurementDate.toISOString(), limit }
        }
      })
    ).data;
  };

  addMeasurement = async (values: AddMeasurementRequest) => {
    return (
      await this.applicationStore.sendRequest<MeasurementType>({
        request: {
          method: METHODS.POST,
          endpoint: this.endpoint,
          data: values
        }
      })
    ).data;
  };

  addMeasurementMeter = async (value: AddMeasurementRequestType) => {
    const date = moment(value.endDateTime)
      .set({
        hour: value.hour ? parseInt(value.hour) : 0,
        minute: value.minute ? parseInt(value.minute) : 0,
        second: 0,
        millisecond: 0
      })
      .utc();

    return (
      await this.applicationStore.sendRequest<MeasurementType>({
        request: {
          method: METHODS.POST,
          endpoint: this.endpoint,
          data: {
            endDateTime: date.toISOString(),
            value: value.value,
            externalChannelIdentifier: value.channelId,
            meterId: value.meter?.id || '',
            skipValidation: value.skipValidation,
            resolveIssuesManually: value.resolveIssuesManually
          }
        }
      })
    ).data;
  };

  deleteMeasurement = async (extChannelId: string, measurementIds: string[]) => {
    return (
      await this.applicationStore.sendRequest<MeterType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/${extChannelId}/bulkdelete`,
          data: {
            measurementIds
          }
        }
      })
    ).data;
  };

  validateMeasurement = async (
    endDateTime: Moment,
    meterId: string | undefined,
    externalChannelId: string | null,
    val: number | undefined
  ) => {
    return (
      await this.applicationStore.sendRequest<MeasurementValidationResponseType>({
        request: {
          method: METHODS.POST,
          endpoint: `${this.endpoint}/validate`,
          data: {
            endDateTime: endDateTime.toISOString(),
            value: val,
            meterId,
            externalChannelId
          }
        }
      })
    ).data;
  };
}
