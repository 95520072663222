import React from 'react';

import { MeterType } from '@zf/api-types/master-data/meter';
import useDialog from '@zf/hooks/src/useDialog';

import ExportMeasurementsDialog from '../../../actions/meters/exports/measurements-export/export-measurements-dialog';
import { useAppContext } from '../../../app-context';
import { DropdownAction } from '../../../design-system/ComponentSets';

type Props = {
  meter: MeterType;
};

export default function ExportMeasurementsListItem(props: Props) {
  const { i18n } = useAppContext();
  const { clickRef, onSubmit } = useDialog();

  return (
    <DropdownAction
      id="meter.measurements_export"
      icon="exel"
      dialogTitle={i18n.getTranslation('actions.meter.export_measurements')}
      dialogContent={<ExportMeasurementsDialog ref={clickRef} {...props} />}
      onSubmit={onSubmit}
      buttonPositive={i18n.getTranslation('export_jobs.export')}
    />
  );
}
