import { observer } from 'mobx-react';
import React, { MutableRefObject } from 'react';

import { EstimatedInvoiceLine } from '@zf/api-types/billing/estimated-invoices';
import { groupBySecondLevel } from '@zf/utils/src/array';

import { Link } from '../../../../../../design-system/Components';
import { ValidationRef } from '../../../../../../design-system/ComponentSets/Dialog/Dialog';
import { useStore } from '../../../../../../hooks/useStore';
import css from './estimated-billing-items.module.scss';
import EstimatedLine from './EstimatedLine';

type Props = {
  lines: EstimatedInvoiceLine[];
  productName: string;
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const EstimatedBillingItems = (props: Props) => {
  const { lines, productName, validationRef } = props;
  const { applicationStore } = useStore();
  const { rootUrl } = applicationStore;

  const groupedByBillingItem: Record<string, EstimatedInvoiceLine[]> = groupBySecondLevel(
    lines,
    'billingItemReference',
    'billingItemName'
  );

  return (
    <>
      {Object.keys(groupedByBillingItem).map((key) => {
        const estimatedInvoiceLines = groupedByBillingItem[key];
        const billingItem = estimatedInvoiceLines[0]?.billingItemReference;

        return (
          <div key={billingItem.billingItemId} className={css['billing-item']}>
            <Link
              id={billingItem.billingItemId}
              url={`${rootUrl}/configuration/product/products?billingItemId=${
                billingItem.billingItemId
              }&productName=${encodeURIComponent(productName)}`}
              onNavigate={() => {
                if (validationRef.current) {
                  validationRef.current.onCancel();
                }
              }}
              bold
            >
              {billingItem.billingItemName}
            </Link>
            {estimatedInvoiceLines.map((line, index) => (
              <EstimatedLine
                key={`${billingItem.billingItemId}-${index}`}
                estimatedInvoiceLine={line}
                productName={productName}
              />
            ))}
          </div>
        );
      })}
    </>
  );
};

export default observer(EstimatedBillingItems);
