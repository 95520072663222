import { makeObservable, observable } from 'mobx';

import RootStore from '../..';
import ForecastingService from '../../../services/ForecastingService';
import ServiceLocationService from '../../../services/master-data/ServiceLocationService';

export default class ServiceLocationStore {
  public rootStore: RootStore;

  public forecastingService: ForecastingService;
  public serviceLocationService: ServiceLocationService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.serviceLocationService = new ServiceLocationService(rootStore.applicationStore);
    this.forecastingService = new ForecastingService(rootStore.applicationStore);

    makeObservable(this, {
      forecastingService: observable,
      serviceLocationService: observable
    });
  }
}
