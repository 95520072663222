import React from 'react';

import { CollectionCaseOverviewCountType } from '@zf/api-types/collection-case';
import { formatMoney } from '@zf/utils/src/number';

import { useAppContext } from '../../../app-context/app-context';
import useFilter from '../../../app-context/hooks/use-filter';
import FilterTag from '../../../components/Filters/filter-tag';
import FilterTags from '../../../components/Filters/filter-tags';
import { NumberParam, StringParam } from 'serialize-query-params';

const initialOverviewCount: CollectionCaseOverviewCountType = {
  all: 0,
  active: 0,
  requiresManualIntervention: 0,
  closed: 0
};

type QueryParam = {
  propertyGroupId: string;
  propertyGroupName: string;
  customerGroupId: string;
  customerGroupName: string;
  workFlowName?: string;
  workflowId?: string;
  nextActionDueFrom?: string;
  nextActionDueTo?: string;
  openAmountLowerLimit?: number;
  customerId?: string;
  customerName?: string;
};

const paramTypes = {
  propertyGroupId: StringParam,
  propertyGroupName: StringParam,
  customerGroupId: StringParam,
  customerGroupName: StringParam,
  nextActionDueFrom: StringParam,
  nextActionDueTo: StringParam,
  customerId: StringParam,
  customerName: StringParam,
  openAmountLowerLimit: NumberParam,
  workFlowName: StringParam,
  workflowId: StringParam
};

const initialQueryParams: QueryParam = {
  propertyGroupId: '',
  propertyGroupName: '',
  customerGroupId: '',
  customerGroupName: '',
  nextActionDueFrom: undefined,
  nextActionDueTo: undefined,
  openAmountLowerLimit: undefined
};

const useCollectionCaseFilter = (search?: string) => {
  const {
    onFilter,
    onSearch,
    refresh,
    setQueryParams,
    clearQueryParams,
    overviewCount,
    quickFilter,
    searchValue,
    queryParams
  } = useFilter<QueryParam>({
    endpoint: '/bill/CollectionCases/overviewcount',
    initialCount: initialOverviewCount,
    domain: 'collection_case',
    search: search,
    initialQueryParams,
    paramTypes
  });

  const { i18n } = useAppContext();

  const tags: React.ReactNode[] = [];

  if (queryParams.propertyGroupId) {
    tags.push(
      <FilterTag
        value={queryParams.propertyGroupName}
        removeFilter={() => setQueryParams({ propertyGroupId: '', propertyGroupName: '' })}
        key="property-group"
      />
    );
  }
  if (queryParams.customerGroupId && queryParams.customerGroupName) {
    tags.push(
      <FilterTag
        value={queryParams.customerGroupName}
        removeFilter={() => setQueryParams({ customerGroupId: '', customerGroupName: '' })}
        key="customer-group"
      />
    );
  }

  if (queryParams.workflowId) {
    tags.push(
      <FilterTag
        value={queryParams.workFlowName}
        removeFilter={() => setQueryParams({ workFlowName: '', workflowId: '' })}
        key="cycle"
      />
    );
  }

  if (queryParams.openAmountLowerLimit) {
    tags.push(
      <FilterTag
        value={`${i18n.getTranslation('collection_case.list.filter.open_amount_lower')}: ${formatMoney(
          queryParams.openAmountLowerLimit,
          i18n.culture
        )}`}
        removeFilter={() => setQueryParams({ openAmountLowerLimit: undefined })}
        key="open-amount-lower-limit"
      />
    );
  }

  if (queryParams.nextActionDueFrom !== undefined || queryParams.nextActionDueTo !== undefined) {
    tags.push(
      <FilterTag
        type="period"
        value={[queryParams.nextActionDueFrom, queryParams.nextActionDueTo]}
        removeFilter={() => setQueryParams({ nextActionDueFrom: undefined, nextActionDueTo: undefined })}
        key="next-action-due-period"
      />
    );
  }

  if (queryParams.customerId) {
    tags.push(
      <FilterTag
        value={queryParams.customerName}
        removeFilter={() => setQueryParams({ customerId: '', customerName: '' })}
        key="customer"
      />
    );
  }

  const filterTags = <FilterTags tags={tags} />;

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    setQueryParams,
    clearQueryParams,
    quickFilter,
    overviewCount,
    searchValue,
    initialQueryParams,
    filterTags,
    queryParams
  };
};

export default useCollectionCaseFilter;
