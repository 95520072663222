import React from 'react';

import useDialog from '@zf/hooks/src/useDialog';

import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';
import RemoveCustomerGroupDialog from '../Dialogs/RemoveCustomerGroupDialog';
import { CustomerRowType } from '@zf/api-types/master-data/customer';
import useCurrent from 'app-context/hooks/use-current';

type Props = { onComplete: Function };

export default function RemoveCustomerGroupListItem({ onComplete }: Props) {
  const { clickRef, onSubmit } = useDialog();
  const { applicationStore } = useStore();
  const { validationRef } = useDialog();
  const { getTranslation } = applicationStore;
  const { myCurrent } = useCurrent('customer');

  const selectedRows: CustomerRowType[] = myCurrent.listPage.selectedRows;

  return (
    <DropdownAction
      id="customer_groups.remove"
      dialogTitle={getTranslation('customer_groups.remove_from_group')}
      dialogContent={
        <RemoveCustomerGroupDialog
          ref={clickRef}
          validationRef={validationRef}
          selectedRows={selectedRows}
          onComplete={onComplete}
          withCustomerList
        />
      }
      onSubmit={onSubmit}
      icon="trashcan"
      dialogType="danger"
      validationRef={validationRef}
      disabled={
        selectedRows.length === 0 || selectedRows.filter(({ customerGroup }) => customerGroup !== ' ').length === 0
      }
      buttonPositive={applicationStore.getTranslation('general.remove')}
    />
  );
}
