import React from 'react';

import { navigate } from '@reach/router';
import { APIExportJobType, ExportJobRowType, ExportJobType } from '@zf/api-types/export-job';
import { DialogClickRef } from '../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';

import { useAppContext } from '../../app-context';
import { notify } from '../../events/notification-events';
import { deleteExporttJob } from './export-job';

type Props = {
  onComplete?: (updatedRows: ExportJobType[], deletedRows: APIExportJobType[]) => void;
  selectedRows?: Array<ExportJobRowType>;
  exportJobToHandle?: APIExportJobType;
  refreshCounts?: () => void;
};

export default React.forwardRef((props: Props, ref: React.Ref<DialogClickRef | undefined>) => {
  const { onComplete, selectedRows, exportJobToHandle, refreshCounts } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { tenant, organization } = tenantReducer.state;

  let exportJobsToHandle: Array<APIExportJobType> = [];

  if (selectedRows) {
    exportJobsToHandle = selectedRows.map((row) => {
      return {
        id: row.__id,
        etag: row.__etag
      };
    });
  } else if (exportJobToHandle) {
    exportJobsToHandle = [exportJobToHandle];
  }

  React.useImperativeHandle(ref, () => ({
    async onClick() {
      if (exportJobsToHandle.length > 0) {
        try {
          await Promise.all(
            exportJobsToHandle.map((importJob) => deleteExporttJob(importJob, tenantReducer, i18n.lang))
          );

          if (onComplete) {
            onComplete([], exportJobsToHandle);
            if (refreshCounts) refreshCounts();
          } else {
            navigate(`/${tenant?.name}/${organization?.shortCode}/export-jobs`);
          }
          notify.success({
            content: i18n.getTranslation('actions.export_jobs.delete_success')
          });
        } catch (e) {
          notify.error({
            content: i18n.getTranslation('actions.export_jobs.delete_failed'),
            error: e
          });
        }
      }
    }
  }));

  return (
    <Paragraph>
      {i18n.getTranslation('actions.export_jobs.delete', { amount: exportJobsToHandle.length.toString() })}
    </Paragraph>
  );
});
