import React from 'react';

import { exportStatus } from '@zf/api-types/enums';
import { InvoiceRowType } from '@zf/api-types/invoice';
import useDialog from '@zf/hooks/src/useDialog';

import useCurrent from '../../../../app-context/hooks/use-current';
import GenericDialog from '../../../../components/units/Dialog/GenericDialog';
import { DropdownAction } from '../../../../design-system/ComponentSets';
import { useStore } from '../../../../hooks/useStore';

export default function MarkAsNotExportedListPageButton() {
  const { applicationStore, invoiceStore } = useStore();
  const { getTranslation } = applicationStore;
  const { changeExportStatus, bulkExportStatus } = invoiceStore.invoiceApiService;
  const { myCurrent } = useCurrent('invoice');
  const selectedRows: InvoiceRowType[] = myCurrent.listPage.selectedRows;
  const { clickRef, onSubmit } = useDialog();

  const invoiceCount = selectedRows.length || 1;
  const ids = selectedRows.map((r) => r.__id);
  const isSingleExport = ids.length === 1 && !!ids[0];
  const isBulkExport = ids.length > 1 && ids.some((id) => !!id);

  async function batchMarkNotExportedInvoices(arr: string[]) {
    let init = 0;
    for (let i = 0; i < arr.length / 1000; i++) {
      await bulkExportStatus(arr.slice(init, (init += 1000)), exportStatus.notexported);
    }
  }

  const submitFunction = async () => {
    /**
     * @description use bulk API endpoint if user select more than one invoice.
     */

    if (isSingleExport) {
      await changeExportStatus(ids[0], exportStatus.notexported);
    } else if (isBulkExport) {
      await batchMarkNotExportedInvoices(ids);
    }
  };

  return (
    <DropdownAction
      id="invoice.change_export_status"
      icon="stop"
      dialogTitle={getTranslation('invoice.mark_as_not_exported')}
      dialogContent={
        <GenericDialog
          ref={clickRef}
          info={getTranslation(`invoice.mark_as_not_exported_info${isBulkExport ? '_multi' : ''}`, {
            number: invoiceCount
          })}
          successMsg={getTranslation(`invoice.mark_as_not_exported_success${isBulkExport ? '_multi' : ''}`, {
            number: invoiceCount
          })}
          errorMsg={getTranslation(`invoice.mark_as_not_exported_fail${isBulkExport ? '_multi' : ''}`, {
            number: invoiceCount
          })}
          submitFunction={submitFunction}
        />
      }
      onSubmit={onSubmit}
      disabled={selectedRows.length === 0}
      buttonPositive={getTranslation('general.ok')}
    />
  );
}
