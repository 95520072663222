import clone from 'clone';
import React from 'react';

import { ValidatorCtxAction, ValidatorCtxState } from '@zf/hooks/src/useCreateContext';

import { useAppContext } from '../';
import { notify } from '../../events/notification-events';
import { RequestType } from '../../types/Request';
import { createHeader, METHODS, sendRequest } from '../../utils/request';

export default function useContextCRUD<T extends { id?: string; delete?: boolean; _etag?: string }>(
  useTracked: () => [ValidatorCtxState<T[]>, React.Dispatch<ValidatorCtxAction<T[]>>]
) {
  const [state, dispatch] = useTracked();
  const { i18n, tenantReducer } = useAppContext();

  const setValue = function <V>(value: Partial<V>) {
    if (state.isLocked) return;
    dispatch({ type: 'SET_VALUE', value: value });
  };

  const addEntity = (newEntity: T) => {
    const clonedArray = clone(state.values);
    clonedArray.unshift(newEntity);
    setValue({
      selectedIndex: 0,
      scrollToIndex: 0,
      values: clonedArray
    });
  };

  const deleteEntity = (index: number) => {
    const clonedArray = clone(state.values);
    clonedArray.splice(index, 1);
    setValue({ selectedIndex: -1, values: clonedArray });
  };

  const backupValues = (updatedValues?: T[]) => {
    dispatch({ type: 'BACKUP', updatedValues: updatedValues });
  };

  const onCompleteLangUpdate = (index: number, updated: T) => {
    const clonedArray = clone(state.values);
    clonedArray[index] = updated;
    setValue({ values: clonedArray });
    backupValues();
  };

  const saveEntities = async (
    createApiFriendlyValues: (entity: T) => Record<string, any>,
    secondIdKey: keyof T,
    endpoint: string,
    successMsg: string,
    failMsg: string
  ) => {
    try {
      if (state.isLocked || !state.isDirty) return;

      setValue({ isLocked: true });

      const entitiesToHandle = clone(state.values);
      state.backup.forEach((initialEntity) => {
        if (!state.values.find((e) => initialEntity.id === e.id)) {
          initialEntity.delete = true;
          entitiesToHandle.push(initialEntity);
        }
      });

      const indexesAffected: number[] = [];
      const newEntities: T[] = [];

      await Promise.all(
        entitiesToHandle.map(async (entity) => {
          let request: RequestType | undefined = undefined;

          const apiFriendlyValues = createApiFriendlyValues(entity);

          // Delete
          if (entity.id && entity.delete) {
            request = {
              method: METHODS.DELETE,
              endpoint: `${endpoint}/${entity.id}`
            };
          } else if (!entity.id) {
            // Create
            request = {
              method: METHODS.POST,
              endpoint: endpoint,
              data: {
                ...apiFriendlyValues
              }
            };
          } else {
            // Update
            const initialEntity = state.backup.find((initial) => initial.id === entity.id);

            if (JSON.stringify(initialEntity) !== JSON.stringify(entity))
              request = {
                method: METHODS.POST,
                endpoint: `${endpoint}/${entity.id}`,
                data: {
                  ...apiFriendlyValues
                }
              };
          }

          if (request) {
            const newEntity = (
              await sendRequest<T>({
                request,
                customHeaders: createHeader({
                  'If-Match': entity._etag
                }),
                tenantReducer,
                lang: i18n.lang
              })
            ).data;

            if (!entity.delete) {
              const indexToReplace = state.values.findIndex((v) => {
                if (entity.id) {
                  return v.id === entity.id;
                } else {
                  return v[secondIdKey] === entity[secondIdKey];
                }
              });

              indexesAffected.push(indexToReplace);
              newEntities.push(newEntity);
            }
          }
        })
      );

      notify.success({
        content: successMsg
      });

      return { indexesAffected, newEntities };
    } catch (e) {
      notify.error({
        content: failMsg,
        error: e
      });
      setValue({ isLocked: false });
    }
  };

  const handleSave = async (
    createApiFriendlyValues: (entity: T) => Record<string, any>,
    secondIdKey: keyof T,
    endpoint: string,
    successMsg: string,
    failMsg: string
  ) => {
    const updateInfo = await saveEntities(createApiFriendlyValues, secondIdKey, endpoint, successMsg, failMsg);

    const valuesClone = clone(state.values);

    if (updateInfo) {
      const { indexesAffected, newEntities } = updateInfo;

      indexesAffected.forEach((i, entityIndex) => {
        valuesClone[i] = newEntities[entityIndex];
      });
    }

    // IsLocked: false is included in backup
    backupValues(valuesClone);
  };

  return {
    addEntity,
    deleteEntity,
    handleSave,
    onCompleteLangUpdate,
    backupValues
  };
}
