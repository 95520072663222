import React from 'react';

import { InvoiceType } from '@zf/api-types/invoice';
import Collapsable from '@zf/stella-react/src/atoms/Collapsable/Collapsable';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import { formatDateWMonth, formatPeriodWMonth } from '@zf/utils/src/date';
import { formatMoney } from '@zf/utils/src/number';

import { Icon } from '../../../../../../components/Icon';
import DynamicVirtualTable from '../../../../../../components/Lang/dynamic-virtual-table/DynamicVirtualTable';
import EmptyLink from '../../../../../../components/Link/EmptyLink';
import { useStore } from '../../../../../../hooks/useStore';
import NoInvoices from '../empty-table-overlays/NoInvoices';
import css from './invoice-table.module.scss';

type InvoiceRowType = {
  __id: string;
  invoice: JSX.Element;
  amount: string;
  status: JSX.Element;
  invoiceDate: string;
  period: string;
};

export default function InvoiceTable() {
  const { contractStore, applicationStore } = useStore();
  const { rootUrl, culture, getTranslation, getEnumTranslation } = applicationStore;
  const { invoicesForCompleteness } = contractStore.billingCompletenessStore;

  const invoiceRows = invoicesForCompleteness.reduce((acc: InvoiceRowType[], invoice: InvoiceType) => {
    const row = {
      __id: invoice.id,
      invoice: (
        <EmptyLink id={`invoice-${invoice.id}`} url={`${rootUrl}/invoices/${invoice.id}`} icon="invoice">
          {invoice.invoiceNum}
        </EmptyLink>
      ),
      amount: formatMoney(invoice.totalAmountInclVAT, culture),
      status: (
        <FlexElements>
          {invoice.status && (
            <Icon
              tooltipFor="invoices-table-tip"
              title={getEnumTranslation('invoiceStatus', invoice.status)}
              type={invoice.status}
              color
              className={css['invoice-status-icon']}
              usedInTable
            />
          )}

          {invoice.paymentDetails.paid ? (
            <Icon
              tooltipFor="invoices-table-tip"
              title={getTranslation('invoice.paid')}
              type="paid"
              color
              usedInTable
            />
          ) : (
            <Icon
              tooltipFor="invoices-table-tip"
              title={getTranslation('invoice.unpaid')}
              type="unpaid"
              color
              usedInTable
            />
          )}
        </FlexElements>
      ),
      invoiceDate: formatDateWMonth(invoice.invoiceDate),
      period: formatPeriodWMonth(invoice.periodStartDateTime, invoice.periodEndDateTime)
    };

    acc.push(row);

    return acc;
  }, []);

  return (
    <Collapsable className={css['collapsable']} title={getTranslation('invoice.invoices')}>
      <div className={css['table']}>
        <DynamicVirtualTable
          id="invoices-table"
          tooltipId="invoices-table-tip"
          rows={invoiceRows}
          noSelect
          totalAmountOfRows={invoiceRows.length}
          NoDataOverlay={NoInvoices}
          columns={[
            {
              width: 200,
              label: getTranslation('invoice.invoice'),
              dataKey: 'invoice'
            },
            {
              width: 120,
              label: getTranslation('general.amount_int'),
              dataKey: 'amount',
              contentAlignRight: true
            },
            {
              width: 160,
              label: getTranslation('invoice.date'),
              dataKey: 'invoiceDate'
            },
            {
              width: 300,
              label: getTranslation('general.period'),
              dataKey: 'period'
            },
            {
              width: 120,
              label: getTranslation('general.status'),
              dataKey: 'status'
            }
          ]}
        />
      </div>
    </Collapsable>
  );
}
