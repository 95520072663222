import { PropertyGroupOverviewCountType } from '@zf/api-types/master-data/property-group';
import useFilter from '../../../app-context/hooks/use-filter';

const initialPropertyOverviewCount: PropertyGroupOverviewCountType = {
  all: 0,
  requireAttention: 0
};

const usePropertyGroupFilter = (search: string) => {
  const { onFilter, onSearch, refresh, overviewCount, quickFilter, searchValue } =
    useFilter<PropertyGroupOverviewCountType>({
      endpoint: '/md/propertyGroups/overviewcount',
      initialCount: initialPropertyOverviewCount,
      domain: 'propertygroup',
      search,
      paramTypes: {}
    });

  return {
    onFilter,
    onSearch,
    refreshCounts: refresh,
    quickFilter,
    overviewCount,
    searchValue
  };
};

export default usePropertyGroupFilter;
