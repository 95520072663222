import moment, { Moment } from 'moment';
import React from 'react';

import InlineInputField from '@zf/stella-react/src/atoms/InputField/inline-input-field';
import { Paragraph } from '@zf/stella-react/src/atoms/Paragraph';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';
import { formatPeriod, isMaxDate, isMinDate, MAX_DATE, MIN_DATE } from '@zf/utils/src/date';

import { useAppContext } from '../../app-context/app-context';
import { notify } from '../../events/notification-events';
import DatePicker, { DatePickerProps } from '../Lang/DatePicker';
import AccessControl from '../Permissions/AccessControl';
import css from './date-range-picker.module.scss';

export type Props = {
  id: string;
  className?: string;
  error?: boolean;
  placeholderStart?: string;
  placeholderEnd?: string;
  startDate: Moment | null;
  endDate: Moment | null;
  clearStart?: boolean;
  startOfDay_?: boolean;
  onFocus?: (step: string) => void;
  isWizardRangePicker?: boolean;
  setDates: (dates: Array<Moment | null | undefined>) => void;
  darkMode?: boolean;
  renderTopLvl?: boolean;
};

const InlineInputFieldDatePicker = InlineInputField<DatePickerProps>(DatePicker);
const WizardDatePicker = WizardInputWrapper<DatePickerProps>(DatePicker);

export default function DateRangePicker(props: Props) {
  const { i18n } = useAppContext();
  const {
    id,
    className,
    startDate,
    setDates,
    endDate,
    placeholderStart = i18n.getTranslation('general.start'),
    placeholderEnd = i18n.getTranslation('general.end'),
    clearStart = true,
    isWizardRangePicker = false,
    error = false,
    darkMode = false,
    startOfDay_ = true,
    onFocus
  } = props;

  const warnEndIsBeforeStart = () => {
    notify.warning({
      content: i18n.getTranslation('datepicker.end_before_start')
    });
  };

  const onChangeStart = (newStartDate: Moment) => {
    if (endDate && newStartDate.isAfter(endDate)) {
      warnEndIsBeforeStart();
    } else {
      setDates([newStartDate, !endDate || (endDate && isMaxDate(endDate)) ? moment(MAX_DATE) : endDate]);
    }
  };

  const onChangeEnd = (newEndDate: Moment) => {
    if (startDate && newEndDate.isBefore(startDate)) {
      warnEndIsBeforeStart();
    } else {
      setDates([!startDate || (startDate && isMinDate(startDate)) ? moment(MIN_DATE) : startDate, newEndDate]);
    }
  };

  const renderDatePickers = () => {
    if (isWizardRangePicker) {
      return (
        <>
          <WizardDatePicker
            {...props}
            id={`${id}-start`}
            onChange={(val) => onChangeStart(val)}
            value={startDate}
            placeholder={placeholderStart}
            onFocus={onFocus}
            error={error}
            darkMode={darkMode}
            startOfDay_={startOfDay_}
            clear={clearStart}
          />
          <div className={css['dash']}>-</div>
          <WizardDatePicker
            {...props}
            id={`${id}-end`}
            onChange={(val) => onChangeEnd(val)}
            value={endDate}
            placeholder={placeholderEnd}
            onFocus={onFocus}
            error={error}
            darkMode={darkMode}
            startOfDay_={startOfDay_}
            maxDate
          />
        </>
      );
    } else {
      return (
        <>
          <InlineInputFieldDatePicker
            {...props}
            id={`${id}-start`}
            onChange={(val) => onChangeStart(val)}
            value={startDate}
            placeholder={placeholderStart}
            onFocus={onFocus}
            error={error}
            darkMode={darkMode}
            hideLabel
          />
          <div className={css['dash']}>-</div>
          <InlineInputFieldDatePicker
            {...props}
            id={`${id}-end`}
            onChange={(val) => onChangeEnd(val)}
            value={endDate}
            placeholder={placeholderEnd}
            onFocus={onFocus}
            error={error}
            darkMode={darkMode}
            hideLabel
            maxDate
          />
        </>
      );
    }
  };

  return (
    <AccessControl permissionsKey={id} fallBack={<Paragraph>{formatPeriod(startDate, endDate)}</Paragraph>}>
      <div id={id} className={className}>
        <div className={css['period-filter']}>{renderDatePickers()}</div>
      </div>
    </AccessControl>
  );
}
