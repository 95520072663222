import React from 'react';

import ZFRouter from 'components/Router/ZFRouter';
import Route from 'features/route';
import Customers from './Customers';
import CustomerGroupListPage from '../customer-group/list-page/CustomerGroupListPage';
import CustomerGroupDetailPage from '../customer-group/detail-page/CustomerGroupDetail';

import Customer from '../detail-page/Customer';
import AddCustomer from '../wizard/AddCustomer';

export default function CustomersCustomerGroups() {
  return (
    <ZFRouter>
      <Route path=":customerID/*" Component={Customer} />
      <Route path="add" Component={AddCustomer} />
      <Route path="customer-groups/:customerGroupId/*" Component={CustomerGroupDetailPage} />
      <Route path="customer-groups" Component={CustomerGroupListPage} />
      <Route path="customer/add" Component={AddCustomer} />
      <Route path=":customerID/*" Component={Customer} />
      <Route path="customers" Component={Customers} />
      <Route path="" Component={Customers} default />
    </ZFRouter>
  );
}
