import { EntityAttachmentFile, EntityAttachmentGroup } from '@zf/api-types/entity-attachments/entity-attachments';
import { attachmentVisibility } from '@zf/api-types/enums';
import { ProductAttachmentFileType } from '@zf/api-types/product';
import { MAX_DATE } from '@zf/utils/src/date';
import { action, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

import FilesStore from './FilesStore';

export type AttachmentState = {
  productAttachmentId: string;
  includeInWelcomeEmail: boolean;
  visibility: attachmentVisibility;
  approvalRequired: boolean;
  validFrom: Moment | null;
  validUntil: Moment | null;
  attachmentFiles: ProductAttachmentFileType[];
};

class AttachmentGroupBase {
  public fileStore?: FilesStore<EntityAttachmentGroup>;
  public attachmentState: AttachmentState;

  constructor() {
    makeObservable(this, {
      attachmentState: observable,
      fileStore: observable,
      setFileStore: action,
      setAttachmentState: action,
      setPermissions: action
    });
  }
  setFileStore = (fileStore?: FilesStore<EntityAttachmentGroup>) => {
    this.fileStore = fileStore;
  };

  setPermissions = () => {
    if (this.fileStore?.createRequestService && this.fileStore.infiniAPIService) {
      const { selectedIds } = this.fileStore.createRequestService;

      this.fileStore.actionPermissions = {
        mayAddEntityAttachment: true,
        mayEditEntityAttachment: selectedIds.length === 1,
        mayDeleteEntityAttachment: selectedIds.length > 0,
        mayDownloadEntityAttachment: selectedIds.length > 0
      };
    }
  };

  listFiles = (entityAttachment?: EntityAttachmentGroup): EntityAttachmentFile[] => {
    //still in progress when I get the right stuff
    if (entityAttachment) {
      this.setAttachmentState({
        productAttachmentId: entityAttachment.id,
        approvalRequired: entityAttachment.approvalRequired,
        visibility: entityAttachment.visibility,
        includeInWelcomeEmail: entityAttachment.includeInWelcomeEmail,
        validFrom: moment(entityAttachment.validity.startDateTime),
        validUntil: moment(entityAttachment.validity.endDateTime)
      });
      return entityAttachment.localisedAttachmentFiles;
    } else {
      this.setAttachmentState({
        productAttachmentId: undefined,
        includeInWelcomeEmail: false,
        visibility: attachmentVisibility.internal,
        approvalRequired: false,
        validFrom: moment(),
        validUntil: moment(MAX_DATE),
        attachmentFiles: []
      });
      return [];
    }
  };

  setAttachmentState = (value: Partial<AttachmentState>) => {
    this.attachmentState = { ...this.attachmentState, ...value };
  };
}

export default AttachmentGroupBase;
