import React from 'react';

import ColumnsContainer from '@zf/stella-react/src/atoms/Card/ColumnsContainer';

import { useAppContext } from '../../../../../../app-context/app-context';
import useMeteringData from '../../hooks/use-metering-data';
import CalculationOutputs from '../calculation-configuration/calculation-outputs';
import css from './calculation-output.module.scss';
import OutputGraph from './output-graph';

export default function CalculationOutputsTab() {
  const { i18n } = useAppContext();
  const { propertyMeteringConfiguration } = useMeteringData();

  return (
    <ColumnsContainer className={css['wrapper']} extraPadding={false}>
      {propertyMeteringConfiguration && (
        <>
          <CalculationOutputs
            outputChannels={propertyMeteringConfiguration.outputChannels}
            subTitle={i18n.getTranslation('property_groups.tabs.metering.select_output_channel')}
          />

          {propertyMeteringConfiguration.propertyGroup && (
            <OutputGraph propertyGroupId={propertyMeteringConfiguration.propertyGroup.id} />
          )}
        </>
      )}
    </ColumnsContainer>
  );
}
