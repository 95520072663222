import classNames from 'classnames';
import Interweave from 'interweave';
import React, { useCallback } from 'react';
import ReactTooltip from 'react-tooltip';

import { localisationLevel } from '@zf/api-types/enums';
import { colors } from '@zf/utils/src/color';
import { createTooltipContent } from '@zf/utils/src/tooltip';

import { useAppContext } from '../../app-context/app-context';
import { BaseButton } from '../../design-system/Components';
import AccessControl from '../Permissions/AccessControl';
import Icon from './Icon';
import css from './language-icon.module.scss';

type IconPropsType = {
  id: string;
  hasMultipleLanguages: boolean;
  tooltipFor?: string;
  localisationLevel_?: localisationLevel;
  className?: string;
  action: () => void;
};

export default function LanguageIcon(props: IconPropsType) {
  const { id, hasMultipleLanguages, tooltipFor } = props;
  const { i18n } = useAppContext();
  const { localisationLevel_, className, action } = props;

  // Fixes sticky tip on button click
  const handleClick = useCallback(() => ReactTooltip.hide(), []);

  if (!hasMultipleLanguages) return null;

  const getIconSpecs = () => {
    if (localisationLevel_ === 'all') {
      return {
        status: i18n.getTranslation('translated_fields.all'),
        iconColor: colors['green-600'],
        iconType: 'lang-full'
      };
    } else if (localisationLevel_ === 'partial') {
      return {
        status: i18n.getTranslation('translated_fields.partial'),
        iconColor: colors['blue-600'],
        iconType: 'lang-open'
      };
    } else {
      return {
        status: i18n.getTranslation('translated_fields.none'),
        iconColor: colors['silver-600'],
        iconType: 'lang-none'
      };
    }
  };

  const specs = getIconSpecs();

  return (
    <AccessControl permissionsKey={id} fallBack={null}>
      <div
        data-tip={createTooltipContent(
          <Interweave content={i18n.getTranslation('translated_fields.edit_translations', { status: specs.status })} />
        )}
        data-for={tooltipFor}
        onClick={handleClick}
      >
        <BaseButton id={id} className={classNames(css['lang-icon-btn'], className)} onClick={action}>
          <Icon type={specs.iconType} color={specs.iconColor} />
        </BaseButton>
      </div>
    </AccessControl>
  );
}
