import { useAppContext } from 'app-context';
import clone from 'clone';
import PaymentTermsDropdown from 'components/Dropdown/paymentterms-dropdown/PaymentTermsDropdown';
import MoneyInput from 'components/input/MoneyInput';
import DatePicker, { DatePickerProps } from 'components/Lang/DatePicker';
import { Disabled, RadioInput, TabSlider } from 'design-system/Components';
import { Info, SettingDescription } from 'design-system/ComponentSets';
import { Spinner } from 'design-system/Foundation';
import { CheckBox } from 'design-system/Inputs';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { calcNextInvoiceDate } from 'utils/contract';

import { advanceFrequency, contractBillingMethod, countryCode, invoiceFrequency } from '@zf/api-types/enums';
import { PaymentTermType } from '@zf/api-types/product';
import { PropertyGroupBillingConfigurationType } from '@zf/api-types/property-group-billing-configuration';
import { OrganizationConfigType } from '@zf/api-types/settings-config';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { WizardInputWrapper } from '@zf/stella-react/src/atoms/Wizard';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';

import css from './billing-form.module.scss';

// Local data format used by this component to make it reusable
export type BillingDataType = {
  billingMethod: contractBillingMethod;
  hasCustomProductConfig: boolean;
  usePropertyGroupProduct: boolean;
  invoiceFrequency: invoiceFrequency | null;
  advanceFrequency: advanceFrequency | null;
  paymentTermsId: string;
  propertyBillingConfig: PropertyGroupBillingConfigurationType | null | undefined;
  advanceAmount: number | undefined;

  invoiceUpfront?: boolean;
  expectAdvancesToBeImportedPeriodically?: boolean;
  supplyStartDate?: Moment | null;
  invoiceFrequency_control?: invoiceFrequency | null;
  nextInvoiceDate?: Moment | null;
  firstInvoiceStartDateTime?: string;
};

type Props = {
  values: BillingDataType;
  organizationConfig?: OrganizationConfigType;
  nextInvoiceDate?: Moment | null;
  advanceAmountFromPreviousContract?: boolean;
  setBillingData: (val: Partial<BillingDataType>) => void;
  onFocusStep?: (step: string) => void;
};

const WizardDatePicker = WizardInputWrapper<DatePickerProps>(DatePicker);

/**
 * @deprecated This is the old shared UI between move in wizard and tasks, is now tasks only
 */
const BillingForm = (props: Props) => {
  const {
    values,
    organizationConfig,
    nextInvoiceDate,
    advanceAmountFromPreviousContract,
    setBillingData,
    onFocusStep
  } = props;

  const { applicationStore, configStore } = useStore();
  const { getTranslation, getEnum, getEnumTranslation } = applicationStore;
  const { getPaymentTerms } = configStore.configService;

  const { authReducer } = useAppContext();
  const { isAdmin, isSuperUser } = authReducer;
  const showAdminInputs = isAdmin() || isSuperUser();

  // This state doesn't change
  const [invoiceFrequencyValues] = useState(getEnum<invoiceFrequency>('invoiceFrequency'));
  const [advanceFrequencyValues] = useState(getEnum<advanceFrequency>('advanceFrequency'));
  const [tabItems] = useState([
    {
      id: contractBillingMethod.credit,
      icon: 'credit-card',
      title: getEnumTranslation('contractBillingMethod', contractBillingMethod.credit)
    },
    {
      id: contractBillingMethod.prepayment,
      icon: 'revert',
      title: getEnumTranslation('contractBillingMethod', contractBillingMethod.prepayment)
    }
  ]);

  const [paymentTerms, setPaymentTerms] = useState<PaymentTermType[]>();

  useEffect(() => {
    getPaymentTerms().then((terms) => setPaymentTerms(terms));
  }, []);

  if (!paymentTerms) return <Spinner centered />;

  const handleFocus = () => {
    if (onFocusStep) onFocusStep('billing');
  };

  let paymentTermsId = values.paymentTermsId;

  if (!values.paymentTermsId && values.propertyBillingConfig) {
    paymentTermsId = values.propertyBillingConfig.paymentTermsId || '';
  }

  return (
    <>
      {organizationConfig?.address?.country === countryCode.gbr && (
        <TabSlider
          id="billing-method"
          tabItems={tabItems}
          selectedTabItem={values.billingMethod}
          // @ts-ignore
          setSelectedItem={(billingMethod) => setBillingData({ billingMethod })}
          onFocus={handleFocus}
          fullWidth
        />
      )}

      {values.billingMethod === contractBillingMethod.credit && (
        <>
          {!values.hasCustomProductConfig && values.usePropertyGroupProduct && (
            <Info>{getTranslation('contracts.wizard.invoice_settings_disabled_info')}</Info>
          )}
          <>
            <Disabled disabled={!values.hasCustomProductConfig && values.usePropertyGroupProduct}>
              <SettingDescription
                title={getTranslation('contracts.wizard.invoice_frequency_title')}
                description={getTranslation('contracts.wizard.invoice_frequency_descr')}
                setting={
                  <FlexElements gap="24">
                    {invoiceFrequencyValues.map((freq) => (
                      <RadioInput
                        key={freq.value}
                        onChange={(option) => {
                          const castedFrequency = option as invoiceFrequency;

                          setBillingData({
                            invoiceFrequency: castedFrequency,
                            invoiceFrequency_control: castedFrequency,
                            nextInvoiceDate: calcNextInvoiceDate(
                              clone(values.supplyStartDate),
                              values.propertyBillingConfig?.invoiceMonth || null,
                              values.propertyBillingConfig?.invoiceDay || null,
                              castedFrequency
                            )
                          });
                        }}
                        value={freq.value}
                        name={`invoice-freq-${freq.text}`}
                        checked={values.invoiceFrequency === freq.value}
                      >
                        {freq.text}
                      </RadioInput>
                    ))}
                  </FlexElements>
                }
                isMandatory
              />
            </Disabled>

            <div className={css['invoice-specs']}>
              {showAdminInputs && typeof values.firstInvoiceStartDateTime !== 'undefined' && (
                <Disabled disabled={!values.hasCustomProductConfig && values.usePropertyGroupProduct}>
                  <SettingDescription
                    title={getTranslation('contracts.wizard.first_invoice_start_date_title')}
                    description={getTranslation('contracts.wizard.first_invoice_start_date_descr')}
                    setting={
                      <DatePicker
                        id="first-invoice-start-date"
                        onChange={(val) => setBillingData({ firstInvoiceStartDateTime: val.toISOString() })}
                        value={moment(values.firstInvoiceStartDateTime)}
                        placeholder={getTranslation('contracts.wizard.first_invoice_start_date_title')}
                      />
                    }
                  />
                </Disabled>
              )}

              {nextInvoiceDate && (
                <Disabled disabled={!values.hasCustomProductConfig && values.usePropertyGroupProduct}>
                  <SettingDescription
                    title={getTranslation('contracts.wizard.next_invoice_date_title')}
                    description={getTranslation('contracts.wizard.next_invoice_date_descr')}
                    setting={
                      <WizardDatePicker
                        id="nextInvoiceDate"
                        value={values.nextInvoiceDate || null}
                        placeholder={getTranslation('contracts.wizard.next_invoice_date_title')}
                        onChange={(nextInvoiceDate) => setBillingData({ nextInvoiceDate })}
                        onFocus={handleFocus}
                      />
                    }
                  />
                </Disabled>
              )}
            </div>
          </>

          {!values.invoiceUpfront && (
            <>
              <SettingDescription
                title={getTranslation('contracts.wizard.advance_frequency_title')}
                description={getTranslation('contracts.wizard.advance_frequency_descr')}
                setting={
                  <FlexElements gap="24">
                    {advanceFrequencyValues.map((freq) => (
                      <RadioInput
                        key={freq.value}
                        onChange={(option) => setBillingData({ advanceFrequency: option as advanceFrequency })}
                        value={freq.value}
                        name={`advance-freq-${freq.text}`}
                        checked={values.advanceFrequency === freq.value}
                      >
                        {freq.text}
                      </RadioInput>
                    ))}
                  </FlexElements>
                }
                isMandatory
              />

              <Disabled disabled={!values.advanceFrequency || values.advanceFrequency === advanceFrequency.none}>
                <SettingDescription
                  title={getTranslation('contracts.advance_amount')}
                  description={getTranslation('contracts.advance_amount_descr')}
                  setting={
                    <MoneyInput
                      id="advanceAmount"
                      onChange={(value) => setBillingData({ advanceAmount: value })}
                      value={typeof values.advanceAmount !== 'undefined' ? values.advanceAmount : null}
                      placeholder={getTranslation('contracts.advance_amount')}
                      error={
                        values.advanceFrequency !== null &&
                        values.advanceFrequency !== advanceFrequency.none &&
                        (typeof values.advanceAmount === 'undefined' || isNaN(values.advanceAmount))
                      }
                      onFocus={handleFocus}
                    />
                  }
                  indentLvl={1}
                  isMandatory
                />
                {advanceAmountFromPreviousContract && (
                  <Info>{getTranslation('contracts.wizard.advance_amount_info')}</Info>
                )}
              </Disabled>

              {typeof values.expectAdvancesToBeImportedPeriodically !== 'undefined' && showAdminInputs && (
                <CheckBox
                  id="expect_advances_imported_periodically"
                  className={css['dont-generate-adv-checkbox']}
                  title={getTranslation('contracts.wizard.expect_advances_imported_periodically_title')}
                  description={getTranslation('contracts.wizard.expect_advances_imported_periodically_descr')}
                  checked={values.expectAdvancesToBeImportedPeriodically}
                  onChange={(val) => setBillingData({ expectAdvancesToBeImportedPeriodically: val })}
                />
              )}
            </>
          )}

          <HorizontalDivider />

          {(values.hasCustomProductConfig || !values.usePropertyGroupProduct) && (
            <SettingDescription
              title={getTranslation('contracts.wizard.payment_term_title')}
              description={getTranslation('contracts.wizard.payment_term_descr')}
              setting={
                <PaymentTermsDropdown
                  id="paymentTermsId"
                  onChange={(value) => setBillingData({ paymentTermsId: value[0]?.id || '' })}
                  selectedValue={paymentTermsId}
                  onFocus={handleFocus}
                />
              }
              isMandatory
            />
          )}
        </>
      )}
    </>
  );
};

export default observer(BillingForm);
