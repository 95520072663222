import classNames from 'classnames';
import React from 'react';

import Tab, { SelectionTabItemType } from './Tab';
import css from './tab-slider.module.scss';

export type TabSliderType = 'grey' | 'white';

type Props<Q> = {
  id: string;
  selectedTabItem?: keyof Q;
  selectedTabItems?: (keyof Q)[];
  tabItems?: SelectionTabItemType<Q>[];
  className?: string;
  type?: TabSliderType;
  isMultiSelect?: boolean;
  showCheckBoxes?: boolean;
  fullWidth?: boolean;
  textAlign?: 'left' | 'center';
  setSelectedItem: (itemId: keyof Q) => void;
  onFocus?: (args: any) => void;
};

export default function TabSlider<Q>(props: Props<Q>) {
  const {
    id,
    className,
    type = 'grey',
    textAlign = 'center',
    showCheckBoxes = false,
    isMultiSelect = false,
    fullWidth = false,
    selectedTabItem,
    selectedTabItems = [],
    tabItems,
    setSelectedItem,
    onFocus
  } = props;

  return (
    <div
      id={id}
      className={classNames(css['tab-slider'], css[type], { [css['full-width']]: fullWidth }, className)}
      onFocus={onFocus}
    >
      {tabItems?.map((item) => {
        const id_ = `${id}-${String(item.id)}`;

        return (
          <Tab
            id={id_}
            key={id_}
            item={item}
            type={type}
            textAlign={textAlign}
            showCheckBoxes={showCheckBoxes}
            isMultiSelect={isMultiSelect}
            selectedTabItem={selectedTabItem || ('' as keyof Q)}
            selectedTabItems={selectedTabItems}
            setSelectedItem={setSelectedItem}
            disabled={item.disabled}
          />
        );
      })}
    </div>
  );
}
