import React, { useEffect, useState } from 'react';
import { SimpleTooltip } from '../../../design-system/Components';

import css from './org-description.module.scss';

export type OrgCardProps = {
  id: string;
  description: string;
};

export default function OrgDescription(props: OrgCardProps) {
  const { id, description } = props;

  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => {
    const elm = document.getElementById(id);
    if (elm) {
      setIsClamped(elm.scrollHeight > elm.clientHeight);
    }
  }, [id]);

  const title = (
    <div id={id} className={css['title']}>
      {description}
    </div>
  );

  return (
    <div className={css['text']}>
      {isClamped ? (
        <SimpleTooltip id={id} tooltip={description}>
          <>{title}</>
        </SimpleTooltip>
      ) : (
        title
      )}
    </div>
  );
}
