import { observer } from 'mobx-react';
import React from 'react';

import { ModelType } from '@zf/api-types/model';

import useBrowserTitle from '../../../../app-context/hooks/use-browser-title';
import ListPage from '../../../../components/listPage';
import { useStore } from '../../../../hooks/useStore';
import PrepaymentDeviceList from './PrepaymentDeviceList';
import PrepaymentDevicesFilterBar from './PrepaymentDevicesFilterBar';
import { entitySubjectType } from '@zf/api-types/enums';

type Props = {
  location: Location;
  models: ModelType[];
};

const PrepaymentDeviceListPage = (props: Props) => {
  const { location, models } = props;
  const { applicationStore } = useStore();
  const { getTranslation } = applicationStore;

  const title = getTranslation('meter.prepayment_devices');

  useBrowserTitle(title);

  return (
    <ListPage
      entitySubjectType={entitySubjectType.meter}
      subjectType={title}
      topBar={<PrepaymentDevicesFilterBar search={location.search} />}
      list={<PrepaymentDeviceList models={models} />}
    />
  );
};

export default observer(PrepaymentDeviceListPage);
